prismanoteApp.controller('adminAskToMergeCompanyModalController', ['$scope', '$uibModalInstance', '$uibModal', '$rootScope', 'data', '$state', '$brand', '$api',
    function ($scope, $uibModalInstance, $uibModal, $rootScope, data, $state, $brand, $api) {
        $scope.localdata = {
            searchValue: '',
            searchMode: 'exact'
        }
        $scope.company = data;

        // console.log("adminAskToMergeCompanyModalController", $scope.company);

        $scope.closeModal = function () {
            $uibModalInstance.close($scope.wholesaler)
        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel')
        }

        $scope.findCompanies = function () {
            $scope.hits = [];
            // console.log($scope.localdata.searchValue);
            if ($scope.localdata.searchValue) {
                if ($scope.localdata.searchValue.length < 3) return;

                var body = {
                    selectedCompanyId: $scope.company._id,
                    searchMode: $scope.localdata.searchMode,
                    searchValue: $scope.localdata.searchValue
                }
                $brand.getCompanies(body).then(function (response) {
                    // console.log('getCompanies: ', response.data);
                    if (response && response.data && response.data.data && response.data.data.length) {
                        $scope.hits = response.data.data;
                    }
                }).catch(function (error) {
                    console.log('error: ', error);
                });
            }
        }

        // In Old functionality, have to ask to admin that merge the company but now onwards retailer can do it self.
        $scope.MergeCompany = function (company) {
            $uibModalInstance.dismiss('cancel')
            if (!$scope.company) return
            console.log('selectedCompany: ', $scope.company);
            console.log('searchedCompany: ', company);
            $scope.selectedCompany = $scope.company
            $scope.searchedCompany = company
            // console.log('isSearchedCompanyAlreadyInShop(): ', isSearchedCompanyAlreadyInShop());
            var data = {
                selectedCompany: {
                    _id: $scope.company._id,
                    name: $scope.company.name,
                    nameSlug: $scope.company.nameSlug
                },
                searchedCompany: {
                    _id: company._id,
                    name: company.name,
                    nameSlug: company.nameSlug
                }
            }

            var modalInstance = $uibModal.open({
                templateUrl: '../views/modal/warning-merge-company-modal.html',
                controller: 'warningMergeCompanyModalController',
                size: 'lg',
                resolve: {
                    data: function () {
                        return data
                    }
                }
            })

            modalInstance.result.then(function (result) {
                console.log('mergeCompany called: ', result);
                if (result == 'mergeCompany') MergeCompanTheCompany()
            }, function (err) {
                console.log('something went wrong in modal closed')
            })
        }

        function MergeCompanTheCompany() {
            try {
                console.log('MergeCompanTheCompany callled:')
                var body = {
                    selectedCompany: $scope.selectedCompany,
                    searchedCompany: $scope.searchedCompany,
                    // shop: {
                    //     _id: $rootScope.currentShop._id,
                    //     nameSlug: $rootScope.currentShop.nameSlug,
                    //     email: $rootScope.currentShop.email,
                    //     name: $rootScope.currentShop.name,
                    //     companies: $scope.companies
                    // }
                }
                if (!body.selectedCompany || !body.searchedCompany || !body.selectedCompany._id || !body.selectedCompany.nameSlug || !body.searchedCompany._id || !body.searchedCompany.nameSlug) {
                    console.log('Invalid data')
                    return;
                }

                $api.post('admin-merge-company/', body)
                    .then(function (response) {
                        console.log('merged successfully: ', response);
                        // $state.go('retailer.suppliers')
                    }).catch(function (reason) {
                        console.log(reason);
                    })
            } catch (e) {
                console.log('admin merge company error: ', e);
            }
        }

        function getCompanies() {
            try {
                $scope.companies = []
                $shop.getOwnShopCompanies($rootScope.currentShop._id)
                    .then(function (companies) {
                        $scope.companies = companies
                    })
                    .catch(function (error) {
                        console.error('Error! While getting Shop Companies')
                        console.error(error)
                    })
            } catch (catcherr) {
                console.log('catch error: ', catcherr)
            }
        }

        // getCompanies()

        // When so companies found then user can create new company with that
        $scope.addNewCompany = function () {
            console.log('addnew Company called')
            $scope.cancel()
            $rootScope.companyNameFromAddCompModal = $scope.localdata.searchValue;
            $scope.localdata.searchValue = '';
            $state.go('retailer.add-company')
        }
    }])
