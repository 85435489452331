prismanoteApp.controller('retailerBrandCampaignDetailController', ['$scope', '$rootScope', '$stateParams', '$state', '$retailer', '$api', '$campaign',
  function ($scope, $rootScope, $stateParams, $state, $retailer, $api, $campaign) {
    console.log('retailerBrandCampaignDetailController called: ', $stateParams)
    $scope.awsUrl = $rootScope.awsUrl
    console.log('awsUrl: ', $scope.awsUrl)

    $scope.getCampaignById = function () {
      $scope.loading = true
      var obj = {};
      if($rootScope.user.role == 'retailer'){
        obj = { shopId: $rootScope.currentShop._id };
      }else if($rootScope.user.role == 'wholesaler'){
        obj = { companyId : $rootScope.currentCompany._id }
      }
      $campaign.getCampaignById($stateParams.campaignId, obj).then(function (campaign) {
        $scope.loading = false
        $scope.showError = false
        // console.log(campaign);
        $scope.campaign = campaign
        $scope.campaign.startDate = new Date($scope.campaign.startDate)
        if ($scope.campaign && $scope.campaign.tasks && $scope.campaign.tasks.length && $scope.campaign.tasks[0]) {
          if ($scope.campaign.tasks[0].firstInvitationEmail && $scope.campaign.tasks[0].firstInvitationEmail.date) {
            $scope.campaign.tasks[0].firstInvitationEmail.date = new Date($scope.campaign.tasks[0].firstInvitationEmail.date)
          }
          if ($scope.campaign.tasks[0].secondInvitationEmail && $scope.campaign.tasks[0].secondInvitationEmail.date) {
            $scope.campaign.tasks[0].secondInvitationEmail.date = new Date($scope.campaign.tasks[0].secondInvitationEmail.date)
          }
        }
      }).catch(function (error) {
        console.log('error: ', error)
        $scope.loading = false
        $scope.showError = true
        $scope.errorMessage = 'Something went wrong while fetching the campaign Detail'
      })
    }
  }])
