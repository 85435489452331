prismanoteApp.controller('brandRecommendationOrderController',
  ['$scope', '$rootScope', '$stateParams', '$recommendationOrder', 'prompt', '$state', 'cookieService', '$productConfig', '$api', '$shop', '$brand',
    function ($scope, $rootScope, $stateParams, $recommendationOrder, prompt, $state, cookieService, $productConfig, $api, $shop, $brand) {
      $scope.data = {
        offsetForPagination: 1,
        searchshopString: '',
        showLoadMore: false,
        isShowMoreFilter: false,
        products: [], // Products which we are selected and going to add in purchase order
        totalSelectedQuantity: 0,
        totalProductsPrice: 0,
        loading: false,
        searchMode: 'exact',
        search_value: '',
        searchedProducts: {
          isSearchResponse: false,
          products: [],
          count: 0
        },
        purchaseOrderNumber: $stateParams.orderNo || '',
        hasShop: false,
        recommendationShop: $rootScope.recommendationShop,
        isSearchingProducts: false,
        isSearchLoader: false
      }
      $scope.totalProductsData = 0;
      $scope.totalProductsDataShowFlag = false
      $scope.searchData = null;
      $scope.filterDataVal = false
      $scope.enableFilterButtons = false
      $scope.currentPage = 1
      $rootScope.recommendationShop = undefined; // If available then doing undefind (COMING FROM PURCHASE ORDER)
      $scope.filter = JSON.parse(JSON.stringify($recommendationOrder.getDefaultFilterValue()));
      $scope.customFilter = JSON.parse(JSON.stringify($recommendationOrder.getFiltersForCustomFilter()));
      $scope.filter = JSON.parse(JSON.stringify(Object.assign($scope.filter, $scope.customFilter)));
      $scope.pageSize = '30'
      $scope.filter.limit = 30;
      $scope.filter.offset = 0;
     // $scope.data.offsetForPagination = 1
      
      $scope.getAllShopForPurchaseOrder = function () {
        $shop.getAllShopForPurchaseOrder({ companyId: $rootScope.currentCompany._id }).then(function (shopresponse) {
          $scope.shops = [];
          if (shopresponse && shopresponse.data && shopresponse.data.data) {
            $scope.shops = shopresponse.data.data;
          }
        }).catch(function (shoperr) {
          console.error('shoperr: ', shoperr);
        });
      }

      $scope.recommendationCustomGroupFilter = function(item) {
        return item.orderFilterCategoryName !== 'NO_GROUP' && item.orderFilterCategoryName !== ''
      }

      $scope.orderFilterCustomNameFilter = function(item) {
        return item.orderFilterCategoryName === 'NO_GROUP' || !item.orderFilterCategoryName || item.orderFilterCategoryName === ''
      }

      // Selecting the shop
      $scope.selectTheShop = function (shop, isFromView) {
        console.log('selectTheShop called: ', shop);
        $scope.foundShops = [];
        $scope.data.searchshopString = '';
        $scope.data.hasShop = true;
        $scope.data.recommendationShop = shop; // BECAUSE USER NOT COMING FROM THE PURCHASE ORDER BUT COMING FROM THE DASHBOARD
        if (!$scope.recommendationOrder) $scope.recommendationOrder = {};
        $scope.recommendationOrder.purchase_order = {
          billingDetails: {
            address: [shop.address],
          },
          companyDetail: {
            _id: $rootScope.currentCompany._id,
            nameSlug: $rootScope.currentCompany.nameSlug,
            name: $rootScope.currentCompany.name,
          }
        }

        // If Company edited address from the brand/shopDetail page then we have to show that
        if (shop && shop.companyShop) {
          $scope.recommendationOrder.purchase_order.companyShopDetail = shop.companyShop;
        }
        $scope.recommendationOrder.purchase_order.shop = {
          _id: shop._id,
          name: shop.name,
          nameSlug: shop.nameSlug
        }
        $scope.getCollectionsFilterForRecommendation(shop._id);
        if (isFromView) {
          if ($scope.data.searchedProducts.isSearchResponse) {
            $scope.searchProductsForCompanyRecommnedation()
          } else {
            $scope.getBrandRetailerRecommendationPurchaseOrder()
          }
        }
      }

      $scope.savePageSize = function (pageSize) {
        cookieService.setCookie('transactionsPageSize=' + pageSize, 31536000)
      }

      $scope.getBrandRetailerRecommendationPurchaseOrder = function () {
        $scope.totalProductsData = 0;
        if (!$scope.filter || !$scope.filter.suppliers.length) {
          $scope.filter.suppliers = [
            {
              _id: $rootScope.currentCompany._id,
              name: $rootScope.currentCompany.name,
              nameSlug: $rootScope.currentCompany.nameSlug,
              isSelected: true,
            }
          ]
        }
        var localStorageFilter = localStorage.getItem('recommendationFilter');
        var parsedLocalStorageFilter;
        if (localStorageFilter) {
          parsedLocalStorageFilter = JSON.parse(localStorageFilter);

          $scope.filter = JSON.parse(JSON.stringify(parsedLocalStorageFilter));
        }
        if ($scope.data.offsetForPagination === 1) $scope.filter.offset = 0; // If Refreshing the page then we taking 1st page always
        var selelctedShop = $scope.recommendationOrder && $scope.recommendationOrder.purchase_order && $scope.recommendationOrder.purchase_order.shop && $scope.recommendationOrder.purchase_order.shop._id ? $scope.recommendationOrder.purchase_order.shop : $scope.data.recommendationShop && $scope.data.recommendationShop._id ? $scope.data.recommendationShop : undefined;
        var body = {
          purchaseOrderNumber: $stateParams.orderNo,
          companyId: $rootScope.currentCompany._id,
          shopId: selelctedShop && selelctedShop._id ? selelctedShop._id : undefined,
          filter: JSON.parse(JSON.stringify($scope.filter)),
          language: $rootScope.language,
          sortBasedOn: $rootScope.currentCompany.sortBasedOn
        }
        body.filter.strapWidths = body.filter.strapWidths.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.width });
        body.filter.jewelTypes = body.filter.jewelTypes.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
       // body.filter.jewelMaterials = body.filter.jewelMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
       // body.filter.diamondMaterials = body.filter.diamondMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
       // body.filter.watchMaterials = body.filter.watchMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
        body.filter.strapModels = body.filter.strapModels.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.model });
        if (body.filter.jewelMaterials != undefined) {
          body.filter.jewelMaterials = body.filter.jewelMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
          }
          if (body.filter.diamondMaterials != undefined) {
            body.filter.diamondMaterials = body.filter.diamondMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
          }
          if (body.filter.watchMaterials) {
            body.filter.watchMaterials = body.filter.watchMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
          }
        
        if (body.filter.watchCaseMaterials) {
          body.filter.watchCaseMaterials = body.filter.watchCaseMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.material });
        }
        if (body.filter.watchStrapMaterails) {
          body.filter.watchStrapMaterails = body.filter.watchStrapMaterails.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.material });
        }
        if (body.filter.watchTypes) {
          body.filter.watchTypes = body.filter.watchTypes.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
        }
        if (body.filter.watchIndications) {
          body.filter.watchIndications = body.filter.watchIndications.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.indication });
        }

        body.filter.suppliers = undefined;
        $scope.data.loading = true;
        $recommendationOrder.getBrandRetailerRecommendationPurchaseOrder(body).then(function (response) {
          $scope.data.loading = false;
          if (response && response.data && response.data.data) {
            if (response.data.data.productDetail) {
              $scope.recommendationOrder = { products: response.data.data.productDetail.products, purchase_order: response.data.data.purchase_order };
              console.log('$scope.recommendationOrder',$scope.recommendationOrder)
              console.log('$rootScope.currentCompany.sortBasedOnSortingNumber',$rootScope.currentCompany.sortBasedOnSortingNumber);
              console.log('$scope.recommendationOrder.products.products',$scope.recommendationOrder.products.products);
              /* if ($scope.recommendationOrder.purchase_order && $scope.recommendationOrder.purchase_order.companyDetail && $scope.recommendationOrder.purchase_order.companyDetail.recommendationOrderFilters && $scope.recommendationOrder.purchase_order.companyDetail.recommendationOrderFilters.length > 0 && 
               !$scope.enableFilterButtons ) {
                $scope.enableFilterButtons = true
                $scope.mainFilterButtons = $scope.recommendationOrder.purchase_order.companyDetail.recommendationOrderFilters
                console.log('$scope.mainFilterButtons::',$scope.mainFilterButtons);
              } */
              if($rootScope.currentCompany.sortBasedOnSortingNumber == "productSortingNumber") {
              for (var k = 0; k < $scope.recommendationOrder.products.products.length; k++) {
                var soldAmount = 0;
                soldAmount = $scope.recommendationOrder.products.products[k].products.productDetail.sortingNumber ? $scope.recommendationOrder.products.products[k].products.productDetail.sortingNumber - 500000000000 : 0
                var topMostSold = "soldAmount";
                if (soldAmount >= 10) {
                  $scope.recommendationOrder.products.products[k].products[topMostSold] = "Zeer goed verkocht";
                } else if (soldAmount > 3 && soldAmount < 10) {
                  $scope.recommendationOrder.products.products[k].products[topMostSold] = "Goed verkocht"
                } else {
                  $scope.recommendationOrder.products.products[k].products[topMostSold] = null
                }
              }
            } else if($rootScope.currentCompany.sortBasedOnSortingNumber == "companyProductSortingNumber") {
              console.log('get companyProductSortingNumber');
              for (var k = 0; k < $scope.recommendationOrder.products.products.length; k++) {
                var soldAmount = 0;
                soldAmount = $scope.recommendationOrder.products.products[k].products.sortingNumber ? $scope.recommendationOrder.products.products[k].products.sortingNumber - 500000000000 : 0
                var topMostSold = "soldAmount";
                if (soldAmount >= 10) {
                  $scope.recommendationOrder.products.products[k].products[topMostSold] = "Zeer goed verkocht";
                } else if (soldAmount > 3 && soldAmount < 10) {
                  $scope.recommendationOrder.products.products[k].products[topMostSold] = "Goed verkocht"
                } else {
                  $scope.recommendationOrder.products.products[k].products[topMostSold] = null
                }
              }
            }
              $scope.recommendationOrder.recommendationOrderFilters = response.data.data.recommendationOrderFilters.map(function(f) {
                if(f.orderFilterCategoryName === '' || !f.orderFilterCategoryName) {
                  f.orderFilterCategoryName = 'NO_GROUP'
                }
                return f
              }); // When purchase order already not created

              // When creating purchase order from recommendation (COMING FROM BRAND PURCHASE ORDER) 
              if ($scope.data.recommendationShop) {
                $scope.getAllShopForPurchaseOrder();
                $scope.selectTheShop($scope.data.recommendationShop);
              }

              if ($scope.recommendationOrder.purchase_order) {
                if ($scope.recommendationOrder.purchase_order.shop) {
                  $scope.data.hasShop = true;
                }

                // When purchase order already created then we are removing the shop which we got from the PURCHASE ORDER
                if ($scope.recommendationOrder.purchase_order.number) $scope.data.recommendationShop = undefined;
              }

              var purchaseOrder = $scope.recommendationOrder.purchase_order;
              if (purchaseOrder && purchaseOrder.shop && purchaseOrder.shop._id) {
                $scope.getCollectionsFilterForRecommendation(purchaseOrder.shop._id);
              } else {
                $scope.getCollectionsFilterForRecommendation();
              }

              var totalProducts = $scope.recommendationOrder.products.metadata[0].total;
              if ($scope.filter.limit < totalProducts && (($scope.filter.offset * $scope.filter.limit) + $scope.filter.limit) < totalProducts) {
                $scope.data.showLoadMore = true;
              } else {
                $scope.data.showLoadMore = false;
              }
              cookieService.getCookie('servicesCurrentPage')
                .then(function (currentPage) {
                  $scope.currentPage = currentPage
                })
                .catch(function () {
                  $scope.currentPage = 1
                })
              window.scrollTo(0, 0);
            }

            if (response.data.data.fitlerDetail) {
              // BRANDS
              var brands = response.data.data.fitlerDetail.brands;
              if (!$scope.filter.brands.length) $scope.filter.brands = brands && brands.length ? brands : [];
            }

            // IF PURCHASE ORDER CREATED AND SEARCHING AT THAT TIME, DUE TO THAT SEACH FOCUS LOST
            if ($rootScope.recommendationOrderSearchValue) {
              $scope.data.search_value = $rootScope.recommendationOrderSearchValue;
              $rootScope.recommendationOrderSearchValue = undefined;
              $scope.searchProductsForCompanyRecommnedation();
            }
          }
        if($scope.searchData != null) {
          $scope.data.search_value = $scope.searchData;
          $scope.searchProductsForCompanyRecommnedation('filter');
        } else {
          $scope.searchData = null
        }
        }).catch(function (error) {
          $scope.data.loading = false;
          console.error('error: ', error);
        })

      }

      // It's not needed to fetch when fetching products, but we can fetch this in background
      $scope.getCollectionsFilterForRecommendation = function (shopId, isMoreFilter) {

        if ($rootScope.currentCompany._id) {
          var body = {
            companyId: $rootScope.currentCompany._id,
            shopId: shopId ? shopId : undefined
          }

          $recommendationOrder.getCollectionsFilterForRecommendation(body).then(function (response) {
            if (response && response.data && response.data.data) {
              var collectionFilters = response.data.data;

              // When user do refresh at time we need to keep this filter as it is
              var localStorageFilter = localStorage.getItem('recommendationFilter');
              var parsedLocalStorageFilter;
              if (localStorageFilter) {
                parsedLocalStorageFilter = JSON.parse(localStorageFilter);
                $scope.filter = JSON.parse(JSON.stringify(parsedLocalStorageFilter));
              }

              // ARTICLE GROUP (PRIVATE LABLE) START
              var privateLabelCollections = collectionFilters.privateLabelCollections;
              $scope.filter.privateLabelCollections = privateLabelCollections && privateLabelCollections.length ? privateLabelCollections : [];

              // For If already selected collection then we need to make it cheked marked true
              if (parsedLocalStorageFilter && parsedLocalStorageFilter.selectedPrivateLabelCollections && parsedLocalStorageFilter.selectedPrivateLabelCollections.length) {
                var articleGroups = $scope.filter.privateLabelCollections;
                var articleGroupsLength = articleGroups.length;
                var selectedColl = parsedLocalStorageFilter.selectedPrivateLabelCollections;
                for (i = 0; i < selectedColl.length; i++) {
                  for (j = 0; j < articleGroupsLength; j++) {
                    if (selectedColl[i] && articleGroups[j] && articleGroups[j]._id &&
                      selectedColl[i] === articleGroups[j]._id) {
                      articleGroups[j].isSelected = true;
                    }
                  }
                }

                // $scope.filter.privateLabelCollections = articleGroups;
              }
              // ARTICLE GROUP (PRIVATE LABLE)  END

              // CLIENT GROUP COLLECTIONS START
              var clientGroupCollections = response.data.data.clientGroupCollections;
              $scope.filter.clientGroupCollections = clientGroupCollections && clientGroupCollections.length ? clientGroupCollections : [];

              // For If already selected collection then we need to make it cheked marked true
              if (parsedLocalStorageFilter && parsedLocalStorageFilter.selectedclientGroupCollections && parsedLocalStorageFilter.selectedclientGroupCollections.length) {
                var clientGroups = $scope.filter.clientGroupCollections;
                var clientGroupsLength = clientGroups.length;
                var selectedColl = parsedLocalStorageFilter.selectedclientGroupCollections;
                for (i = 0; i < selectedColl.length; i++) {
                  for (j = 0; j < clientGroupsLength; j++) {
                    if (selectedColl[i] && clientGroups[j] && clientGroups[j]._id &&
                      selectedColl[i] === clientGroups[j]._id) {
                      clientGroups[j].isSelected = true;
                    }
                  }
                }

                // $scope.filter.privateLabelCollections = articleGroups;
              }
              // CLIENT GROUP COLLECTIONS END
            }
          }).catch(function (error) {
            console.error('getCollectionsFilterForRecommendatidn error: ', error)
          });
        }
      }

      $scope.loadMore = function () {
        $scope.filter.offset += 1;
        if ($scope.data.searchedProducts.isSearchResponse) {
          $scope.searchProductsForCompanyRecommnedation()
        } else {
          $scope.getBrandRetailerRecommendationPurchaseOrder()
        }
      }

      $scope.addTheProduct = function (item) {
        if ($scope.data.purchaseOrderNumber) {
          addProductToExistingPurchaseOrder(item);
        } else {
          createNewPurchaseOrder(item);
        }
      }

      $scope.getThumbnailUrl = function(url){
        if(url){
            var index = url.indexOf('/');
            var folder = url.substring(0, index + 1);
            var fileName = url.replace(folder, '');
            return folder + 'prismathumbnail-' + fileName;
        }
      }

      function addProductToExistingPurchaseOrder(item) {
        item.products.selected = true;
        item.products.quantity = 1;

        var isAlreadyAdded = false;
        for (var i = 0; i < $scope.data.products.length; i++) {
          if (item && item.products && item.products._id && $scope.data && $scope.data.products && $scope.data.products[i] &&
            $scope.data.products[i]._id && (item.products._id).toString() === ($scope.data.products[i]._id).toString()) {
            isAlreadyAdded = true;
            break;
          }
        }

        if (isAlreadyAdded) return;

        $scope.data.products.push(item.products);

        var body = {
          companyId: $rootScope.currentCompany._id,
          purchaseOrder: {
            _id: $scope.recommendationOrder.purchase_order._id
          },
          shop: {
            _id: $scope.recommendationOrder.purchase_order.shop._id
          },
          company: {
            _id: $scope.recommendationOrder.purchase_order.company._id
          },
          isFrom: 'company',
          products: [{
            _id: item.products.productDetail._id,
            quantity: item.products.quantity
          }]
        }

        $scope.data.loading = true;
        $recommendationOrder.addProductToPurchaseOrderFromRecommendation(body).then(function (response) {
          $scope.data.loading = false;
          if (response && response.data && response.data.data) {
            $scope.recommendationOrder.purchase_order = response.data.data;
          } else {
            console.error('Something went wrong while adding product')
          }
        }).catch(function (error) {
          $scope.data.loading = false;
          console.error('error: ', error)
        });
      }

      function createNewPurchaseOrder(item) {
        var shopDetails = $scope.recommendationOrder.purchase_order.shop;
        var billingDetails = $scope.recommendationOrder.purchase_order.billingDetails;
        var purchaseOrder = {
          company: {
            _id: $rootScope.currentCompany._id,
            name: $rootScope.currentCompany.name,
            nameSlug: $rootScope.currentCompany.nameSlug,
          },
          sendDetails: {
            address: [$rootScope.currentCompany.address],
            email: $rootScope.currentCompany.email
          },
          shop: {
            _id: shopDetails._id,
            name: shopDetails.name,
            nameSlug: shopDetails.nameSlug,
          },
          billingDetails: billingDetails,
          isFrom: 'company',
          products: [{
            _id: item.products.productDetail._id,
            quantity: 1
          }]
        }
        $recommendationOrder.createNewPurchaseOrderUsingRecommendation(purchaseOrder).then(function (response) {
          if (response && response.data && response.data.data) {
            $state.go('brand.recommendation-order', { orderNo: response.data.data });
          }
        }).catch(function (error) {
          console.error('error: ', error);
        });
      }

      $scope.changeSelectedProductQuantity = function (product) {
        // if (product.quantity <= 0 || !product.quantity) product.quantity = 1;
        // if (product.quantity) product.selected = true;
        // else product.selected = false;
        calculateTheQunatityAndPrice();
      }

      function calculateTheQunatityAndPrice() {
        $scope.data.totalSelectedQuantity = 0
        $scope.data.totalProductsPrice = 0
        for (var i = 0; i < $scope.data.products.length; i++) {
          $scope.data.totalSelectedQuantity += $scope.data.products[i].quantity || 0
          $scope.data.totalProductsPrice += (($scope.data.products[i].productDetail.suggestedRetailPriceIncVat) * $scope.data.products[i].quantity) || 0
        }
      }

      $scope.addProductToPurchaseOrder = function () {
        $scope.data.products.map(function (elm, index) {
          if (!elm.quantity) {
            $scope.data.products[index].selected = false
            $scope.data.products.splice(index, 1)
          }
        })

        var body = {
          purchaseOrder: {
            _id: $scope.recommendationOrder.purchase_order._id
          },
          shop: {
            _id: $scope.recommendationOrder.purchase_order.shop._id
          },
          company: {
            _id: $scope.recommendationOrder.purchase_order.company._id
          },
          companyId: $rootScope.currentCompany._id,
          isFrom: 'company',
          products: $scope.data.products.map(function (el) {
            return {
              _id: el.productDetail._id,
              quantity: el.quantity
            }
          })
        }

        $scope.data.loading = true;
        $recommendationOrder.addProductToPurchaseOrderFromRecommendation(body).then(function (response) {
          $scope.data.loading = false;
          emptyTheSelectedProduct();
          if (response && response.data && response.data.data) {
            $scope.recommendationOrder.purchase_order = response.data.data
          } else {
            console.error('Something went wrong while adding product')
          }
        }).catch(function (error) {
          $scope.data.loading = false;
          emptyTheSelectedProduct();
          console.error('error: ', error)
        })
      }

      $scope.deletePurchaseOrderProduct = function (index) {
        var purchaseOrder = $scope.recommendationOrder.purchase_order
        if (purchaseOrder.items.length === 1) {
          prompt({
            title: 'Warning!',
            message: 'You have only one purchase order product. If you want to delete then go the purchase order page',
            buttons: [{ label: 'Go to the Purchase order', primary: true }, { label: 'CANCEL', primary: false, cancel: true }]
          }).then(function (response) {
            if (response.primary) {
              $state.go('retailer.purchase-order', { orderNo: $stateParams.orderNo })
            }
          }).catch(function (error) {
            console.error('error: inprompnt: ', error)
          })
        } else {
          console.error('Hey Delete Purchase Order Product')
          var body = {
            purchaseOrderId: purchaseOrder._id,
            status: purchaseOrder.status[0].status,
            itemId: purchaseOrder.items[index]._id,
            companyId: $rootScope.currentCompany._id
          }
          $scope.data.loading = true;
          $recommendationOrder.deletePurchaseOrderProduct(body).then(function (response) {
            $scope.data.loading = false;
            // $scope.recommendationOrder.purchase_order.items.splice(index, 1);
            $scope.getBrandRetailerRecommendationPurchaseOrder()
          }).catch(function (error) {
            $scope.data.loading = true;
            console.error('error: ', error)
          })
        }
      }

      $scope.changePurchaseOrderProductQunatity = function (index) {
        var item = $scope.recommendationOrder.purchase_order.items[index]
        var body = {
          purchaseOrderId: $scope.recommendationOrder.purchase_order._id,
          purchaseOrderItemId: item._id,
          newQuantity: item.quantity,
          lastQuantity: item.lastQuantity,
          companyId: $rootScope.currentCompany._id
        }
        $scope.data.loading = true;
        $recommendationOrder.changeQuantityOfPurchaseOrder(body).then(function (response) {
          $scope.data.loading = false;
          item.quantity = body.newQuantity
          item.lastQuantity = body.newQuantity
        }).catch(function (error) {
          $scope.data.loading = false;
          console.error('error: ', error)
        })
      }

      $scope.saveCurrentPage = function (currentPage) {
        
        cookieService.setCookie('servicesCurrentPage=' + currentPage, 1800)
      }

      $scope.setSearchMode = function (mode) {
        $scope.data.searchMode = mode
      }

      $scope.removeSearchResults = function () {
        if (!$scope.data.isSearchingProducts) return;
        $scope.data.isSearchingProducts = false;
        $scope.data.search_value = '';
        $scope.searchData = undefined
        $rootScope.recommendationOrderSearchValue = undefined; // To prevent focus losing when purchase order created
        $scope.data.searchedProducts.isSearchResponse = false
        $scope.filter.limit = 30;
        $scope.filter.offset = 0;
        $scope.currentPage = 1;
        $scope.data.offsetForPagination = 1;
        $scope.getBrandRetailerRecommendationPurchaseOrder()
      }

   /*    $scope.searchVal = function(val) {  
        if (val.length == 0) { 
          $scope.data.search_value = '';
          $scope.getBrandRetailerRecommendationPurchaseOrder()
          }
    } */

      $scope.searchProductsForCompanyRecommnedation = function (data) {

          $scope.filterDataVal = false
          $scope.data.isSearchingProducts = true;
          // document.getElementById('search_field_purchaseOrder').focus();
         
            $rootScope.recommendationOrderSearchValue = $scope.data.search_value;
            if ($rootScope.currentCompany._id) {
              if ($scope.data.search_value) {
                $scope.data.search_value = $productConfig.retrunValidProductNumber($scope.data.search_value)
                // value = $scope.data.search_value;
                if ($scope.data.search_value.length < 3) return
    
                if (!$scope.data.searchedProducts.isSearchResponse) {
                  
                  $scope.filter.limit = 30
                  $scope.data.offsetForPagination = 1;
                  $scope.filter.offset = 0
                  $scope.currentPage = 1;
                }
                var selelctedShop = $scope.recommendationOrder && $scope.recommendationOrder.purchase_order && $scope.recommendationOrder.purchase_order.shop && $scope.recommendationOrder.purchase_order.shop._id ? $scope.recommendationOrder.purchase_order.shop : $scope.data.recommendationShop && $scope.data.recommendationShop._id ? $scope.data.recommendationShop : undefined;
                var data = {
                  searchText: $scope.data.search_value,
                  companyId: $rootScope.currentCompany._id,
                  shopId: selelctedShop && selelctedShop._id ? selelctedShop._id : undefined,
                  limit: $scope.filter.limit,
                  offset: $scope.filter.offset,
                  language: $rootScope.language,
                  sortBasedOn: $rootScope.currentCompany.sortBasedOn,
                  dataLimit: data == 'filter' ? 'filter' : null
                }
    
                $scope.data.isSearchLoader = true;
                // document.getElementById('search_field_purchaseOrder').focus();
                $recommendationOrder.searchProductsForCompanyRecommnedation(data).then(function (response) {
                  $scope.data.isSearchLoader = false;

                  // document.getElementById('search_field_purchaseOrder').focus();
                  $scope.data.searchedProducts.isSearchResponse = true
                  if (response && response.data && response.data.data && response.data.data.products && response.data.data.products.length > 0) {
                    if($rootScope.currentCompany.sortBasedOnSortingNumber == "productSortingNumber") {
                      for (var k = 0; k < response.data.data.products.length; k++) {
                        var soldAmount = 0;
                        soldAmount = response.data.data.products[k].products.productDetail.sortingNumber - 500000000000
                        var topMostSold = "soldAmount";
                        if (soldAmount >= 10) {
                          response.data.data.products[k].products[topMostSold] = "Zeer goed verkocht";
                        } else if (soldAmount > 3 && soldAmount < 10) {
                          response.data.data.products[k].products[topMostSold] = "Goed verkocht"
                        } else {
                          response.data.data.products[k].products[topMostSold] = null
                        }
                      }
                    } else if ($rootScope.currentCompany.sortBasedOnSortingNumber == "companyProductSortingNumber") {
                      for (var k = 0; k < response.data.data.products.length; k++) {
                        var soldAmount = 0;
                        soldAmount = response.data.data.products[k].products.sortingNumber ? response.data.data.products[k].products.sortingNumber - 500000000000 : 0
                        var topMostSold = "soldAmount";
                        if (soldAmount >= 10) {
                          response.data.data.products[k].products[topMostSold] = "Zeer goed verkocht";
                        } else if (soldAmount > 3 && soldAmount < 10) {
                          response.data.data.products[k].products[topMostSold] = "Goed verkocht"
                        } else {
                          response.data.data.products[k].products[topMostSold] = null
                        }
                      }
                    }
                    
                    var resData = response.data.data
                    var mainData = resData
                    var filterValues = localStorage.getItem('recommendationFilter');
                    var filData = JSON.parse(filterValues)
                   var categaoryFilter = false;

                    if(data.dataLimit == 'filter') {
                    if(filData && filData.category != null){
                      categaoryFilter = true
                      if (filData.category && (filData.category.watch == true || filData.category.jewel == true || filData.category.strap == true || filData.category.others == true)) {
                        $scope.filterDataVal = true
                      }

                      if ($scope.filterDataVal == true) {
                        var filtered_data = []

                        if (filData.category.watch == true) {
                          filtered_data = resData.products.filter(function (data) {
                            return ((data.products.productDetail.category == 'WATCH'));
                          });
                        }
                        if (filData.category.jewel == true) {
                          if(filtered_data.length == 0) {
                            filtered_data = resData.products.filter(function (data) {
                              return ((data.products.productDetail.category == 'JEWEL'));
                            });
                          }
                         
                        }
                        if (filData.category.strap == true) {
                          if(filtered_data.length == 0) {
                            filtered_data = resData.products.filter(function (data) {
                              return ((data.products.productDetail.category == 'STRAP'));
                            });
                          }
                          
                        }
                        if (filData.category.others == true) {
                          if(filtered_data.length == 0) {
                            filtered_data = resData.products.filter(function (data) {
                              return ((data.products.productDetail.category == 'OTHERS'));
                            });
                          }
                        }
                        $scope.recommendationOrder.products = filtered_data
                      }
                    }
                    }
                                     
                    $scope.totalProductsDataShowFlag = false;
                    if ($scope.filterDataVal == false && categaoryFilter == false) {

                      $scope.recommendationOrder.products = resData
                      var totalProducts = $scope.recommendationOrder.products.metadata[0].total
                      $scope.filter.limit = 30
                    } else if(categaoryFilter == true && $scope.filterDataVal == true){
                      $scope.recommendationOrder.products.products = $scope.recommendationOrder.products
                      $scope.totalProductsDataShowFlag = true
                      $scope.totalProductsData = $scope.recommendationOrder.products.length
                    //  $scope.filter.limit = $scope.recommendationOrder.products.length
                    }
    
                    if ($scope.filter.limit < totalProducts && (($scope.filter.offset * $scope.filter.limit) + $scope.filter.limit) < totalProducts) {
                      $scope.data.showLoadMore = true
                    } else {
                      $scope.data.showLoadMore = false
                    }
                    window.scrollTo(0, 0)
                  } else {
                    $scope.data.showLoadMore = false;
                    $scope.recommendationOrder.products = {
                      products: [],
                      metadata: [{
                        total: 0
                      }]
                    };
                  }
                }).catch(function (error) {
                  $scope.data.isSearchLoader = false;
                  console.error('error: ', error);
                })
              }
            }
      }

      function emptyTheSelectedProduct() {
        $scope.data.products = []
        var products = $scope.recommendationOrder.products.products
        products.map(function (el) {
          if (el && el.products && el.products.selected) {
            el.products.selected = false
          }
        })
      }

      function setFilter(filter) {        
        $scope.data.search_value = '';
        filter.clientGroupCollections = [];
        filter.privateLabelCollections = [];
        localStorage.setItem('recommendationFilter', JSON.stringify(filter));

        $scope.getBrandRetailerRecommendationPurchaseOrder();
      }

      $scope.$on('recommendation_filter_directive', function (event, filter) {
        setFilter(filter);
      });

      $scope.resetFilter = function () {
        localStorage.removeItem('recommendationFilter');
        $scope.filter = JSON.parse(JSON.stringify($recommendationOrder.getDefaultFilterValue()));
        $scope.getBrandRetailerRecommendationPurchaseOrder();
      }

      $scope.changePage = function () {
        emptyTheSelectedProduct();
        $scope.filter.offset = $scope.data.offsetForPagination - 1;
       // $scope.filter.offset = $scope.currentPage - 1; // Pagiantion count from the 0 (CURRENT PAGE) but In query we are doing from 1(CURRENT PAGE)
        getTheData();
      }

      function getTheData() {
        if ($scope.data.searchedProducts.isSearchResponse) {
          $scope.searchProductsForCompanyRecommnedation();
        } else {
          $scope.filter.clientGroupCollections = [];
          $scope.filter.privateLabelCollections = [];
          localStorage.setItem('recommendationFilter', JSON.stringify($scope.filter));
          $scope.getBrandRetailerRecommendationPurchaseOrder();
        }
      }

      $scope.searchShops = function (str) {
        $scope.foundShops = [];
        $brand.searchShopForBrandPurchaseOrder({ companyId: $rootScope.currentCompany._id, searchText: str }).then(function (shopresponse) {
            if (shopresponse && shopresponse.data && shopresponse.data.data && shopresponse.data.data.data  && shopresponse.data.data.data.length) {
                console.log('shoresponse: ', shopresponse.data.data.data);
                $scope.foundShops = shopresponse.data.data.data;
            }
        }).catch(function (shoperr) {
            console.log('shoperr: ', shoperr);
        });
    }

      $scope.changeShop = function () {
        $scope.data.hasShop = false;
        $scope.recommendationOrder.purchase_order.billingDetails = undefined;
        $scope.recommendationOrder.purchase_order.shop = undefined;
        $scope.recommendationOrder.purchase_order.companyShopDetail = undefined;
        $scope.recommendationOrder.purchase_order.companyDetail = undefined;

        // For Collections Filter
        $scope.filter.privateLabelCollections = [];
        $scope.filter.clientGroupCollections = [];
        $scope.getCollectionsFilterForRecommendation();
      }


      $scope.removeFilter = function (filtertype, key, index) {
        $scope.filter = $recommendationOrder.removeFilter($scope.filter, filtertype, key, index);
        $scope.$emit('recommendation_filter_directive', $scope.filter);
      }

      $scope.addFilter = function (filtertype, key, index) {
        console.info('filtertype:',filtertype,'key:',key,'index',index);
        
      }

      $scope.setCustomFilter = function (item) {
        if (item && item.searchQuery && item.searchQuery.isSearch) {
          $scope.data.search_value = item.searchQuery.searchValue;
          $scope.searchProductsForCompanyRecommnedation();
        } else {
          $scope.searchData = item.searchQuery.searchValue;
          item.filter._id = item._id
          if(item.orderFilterCategoryName) {
            item.filter.orderFilterCategoryName = item.orderFilterCategoryName
          }
          setFilter(item.filter);
        }
      }

    }
  ])
