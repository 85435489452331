prismanoteApp.controller(
  'shopBrandController',
  ['$scope', '$rootScope', '$api', '$state', '$shop', '$brand', '$company', '$uibModal', 'prompt', '$sce',
    function ($scope, $rootScope, $api, $state, $shop, $brand, $company, $uibModal, prompt, $sce) {
      $scope.loading = false
      $scope.shops = []
      $scope.allShops = []
      $scope.originalData = []

      $scope.fetchCompanies = function () {
        if ($rootScope.currentCompany && $rootScope.currentCompany._id) {
          console.log('fetchCompanies called', $rootScope.currentCompany._id)
          // Now we need to fetch all the shops which contains the this loggedin id
          getAllShopOfCompany()
        } else {
          // console.log('no company selected')
          $company.getCompany()
            .then(function (company) {
              $scope.currentCompany = company
              $rootScope.currentCompany = company
              getAllShopOfCompany()
            })
            .catch(function (reason) {
              console.log('Internal Server Error while fetching shop detail')
            })
        }
      }

      function getAllShopOfCompany () {
        $api.get('shops/comapnies/' + $rootScope.currentCompany._id)
          .then(function (response) {
            $scope.loading = false
            // console.log('response: ', response);
            var ResponseData = response.data && response.data.data ? response.data.data : []
            $scope.originalData = ResponseData
            // console.log('FINAL DATA: ====> ', ResponseData);
            $scope.shops = ResponseData
          })
          .catch(function (reason) {
            console.log(reason)
          })
      }

      $scope.searchData = function (searchedValue) {
        if (!searchedValue || !$scope.shops.length) $scope.shops = $scope.originalData

        $scope.shops = []
        // console.log('serachdata called: ', searchedValue);
        $scope.originalData.forEach(function (item) {
          // console.log('item: ', item);
          if ((item && item.shopDetail && item.shopDetail.name && item.shopDetail.name.toLowerCase().indexOf(searchedValue.toString().toLowerCase())) >= 0 || searchedInBrandArray(item.shopDetail && item.shopDetail.brands ? item.shopDetail.brands : [], searchedValue)) {
            $scope.shops.push(item)
          }
        })
      }

      $scope.openShopDetail = function (nameSlug) {
        // console.log(nameSlug);
        $state.go('brand.shop-detail', { nameSlug: nameSlug })
      }

      // Searching in all shop.brands
      function searchedInBrandArray (brands, searchedValue) {
        for (var i = 0; i < brands.length; i++) {
          if (brands[i].name && brands[i].name.toLowerCase().indexOf(searchedValue.toString().toLowerCase()) >= 0) {
            return true
          }

          if (brands.length == (i + 1)) {
            return false
          }
        }
      }

      // Searching shops to add in the database, but its exact match on email
      $scope.searchShops = function (str) {
        $scope.retailerEmail = str
        var matches = []
        $scope.allShops.forEach(function (item) {
          if (item && (
            (item.email && item.email.toString().toLowerCase() === str.toString().toLowerCase())
          )) {
            matches.push(item)
          }
        })
        return matches
      }

      // Select the shop add to the company.shops array
      $scope.selectTheShop = function (shop) {
        // console.log('shop: ', shop);
        $scope.retailerEmail = ''
        if (shop && shop.originalObject && shop.originalObject.email) {
          // console.log('selectTheShop: ', shop.originalObject.email);
          var body = {
            companyId: $rootScope.currentCompany._id,
            shop: {
              _id: shop.originalObject._id,
              email: shop.originalObject.email
            }
          }
          $scope.loading = true
          $brand.addShopInCompany(body).then(function (response) {
            // console.log('response: ', response);
            $scope.loading = false
          }).catch(function (error) {
            console.log('error: ', error)
            $scope.loading = false
          })
        }
      }

      // In case, if shop not found then we can send invitation if needed(Like Campaign, not in scope for now)
      $scope.sendEmail = function () {
        $scope.alert = null
        // console.log('retailerEmail email: ', $scope.retailerEmail);
        if ($scope.retailerEmail) {
          $campaign.validateEmail($scope.retailerEmail).then(function (isValidEmail) {
            console.log('isvallid email: ', $scope.retailerEmail, isValidEmail)
            if (!isValidEmail) {
              $scope.alert = {
                type: 'danger',
                msg: 'Invalid email address'
              }
            } else {
              // If have to send email then do code here
            }
          })
        } else {
          $scope.alert = {
            type: 'danger',
            msg: 'Enter email address'
          }
        }
      }

      // Remove shop from company
      $scope.removeShopFromTheCompany = function (index) {
        try {
          prompt({
            title: 'Delete the shop from the company',
            message: 'Are you sure you want to delete the shop from the company?',
            buttons: [
              { label: $language.translate('YES'), primary: true, id: 0 },
              { label: $language.translate('CANCEL'), primary: false, cancel: true, id: 1 }
            ]
          }).then(function (value) {
            if (value.id === 0) {
              console.log('$Index: ', index, $scope.shops[index])
              $scope.shops[index].isDisabled = true

              var body = {
                shopId: $scope.shops[index].shopDetail._id,
                companyId: $rootScope.currentCompany._id
              }
              $scope.loading = true
              $brand.removeShopFromTheCompany(body).then(function (response) {
                console.log('response: ', response)
                $scope.shops.splice(index, 1)
                $scope.loading = false
              }).catch(function (error) {
                $scope.shops[index].isDisabled = false
                console.log('error: ', error)
                $scope.loading = false
              })
            }
          }).catch(function (catcherror) {
            console.log('catcherror: ', catcherror)
          })
        } catch (catcherr) {
          console.log('catcherrr: ', catcherr)
        }
      }

      $scope.openAddShopModal = function () {
        try {
          console.log('openAddShopModal called: ')
          var modalInstance = $uibModal.open({
            templateUrl: '../views/modal/company-add-shop-modal.html',
            controller: 'companyAddShopModalController',
            size: 'lg'
          })

          modalInstance.result.then(function (result) {
            console.log('result**************************', result)
            if (result.isShop) {
              console.log('Add shop', result.shop)
              var body = {
                companyId: $rootScope.currentCompany._id,
                shop: {
                  _id: result.shop._id,
                  email: result.shop.email
                }
              }
              $scope.loading = true
              $brand.addShopInCompany(body).then(function (response) {
                console.log('response: ', response)
                // $scope.shops.push(result.shop);
                getAllShopOfCompany()
                $scope.loading = false
              }).catch(function (error) {
                console.log('error: ', error)
                $scope.loading = false
              })
            } else {
              console.log('No need to add shop')
            }
          }, function (error) {
            console.log('modal open error: ', error)
          })
        } catch (error) {
          console.log('error: ', error)
        }
      }

      $scope.ToolTipRows = function (rows, shopDetail) {
        if (rows) {
          var table = "<html><body style='border: 1px solid #D3D3D3; '><div class='row'> <div class='col-md-6'><p>" + toolTipNote(shopDetail) + '</p><p>' + isUserAvailable() + '</p><p>' + stockAndRevenueValue(shopDetail) + "</p></div> <div class='col-md-6'> <table class='tooltips_table' cellpadding='20' border='1'>  <tr> <th class='custom_tooltips'>Monday</th> <th >Time</th></tr>  " + rows + '</table> </div> </div> </body></html>'
          return $sce.trustAsHtml(table)
        } else {
          return '<html> <body> <p> <b> No record found </b> </p> </body> </html>'
        }
      }

      $scope.stockValueAndRevenueUibToolTip = function (shopDetail) {
        var rows = ''
        var item = shopDetail.openingHours
        if (item && Object.keys(item).length) {
          // For showing list from the today
          var days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
          var todayIndex = new Date().getDay() - 1
          if (todayIndex === -1) todayIndex = 6
          if (todayIndex > 0) {
            var array1 = days.slice(0, todayIndex)
            var array2 = days.slice(todayIndex)
            days = JSON.parse(JSON.stringify(array2.concat(array1)))
          }
          var result = []
          for (var i = 0; i < days.length; i++) {
            var sequenceobject = {}
            sequenceobject[days[i]] = item[days[i]]
            result.push(sequenceobject)
          }

          for (var i = 0; i < result.length; i++) {
            var keys = Object.keys(result[i])
            if (keys.length && keys[0]) {
              rows += '<tr><td>' + keys[0] + '</td><td>' + result[i][keys[0]] + '</td></tr>'
            }
          }
        }

        return $scope.ToolTipRows(rows, shopDetail)
      }

      $scope.showStockRevenueMessage = function (stockandrevenuedetail) {
        // console.log('stockandrevenuedetail: ', stockandrevenuedetail);
        var message = '-'
        if (stockandrevenuedetail && stockandrevenuedetail.stockvalue && stockandrevenuedetail.revenue) {
          var stockvalue = stockandrevenuedetail.stockvalue
          var revenue = stockandrevenuedetail.revenue
          if (stockvalue.sign === 'uparrow' && revenue.sign === 'uparrow') {
            message = 'Going well'
          } else if (stockvalue.sign === 'uparrow' && revenue.sign === 'downarrow') {
            message = 'Marketing required?'
          } else if (stockvalue.sign === 'downarrow' && revenue.sign === 'uparrow') {
            message = 'Refiling required!'
          } else if (stockvalue.sign === 'downarrow' && revenue.sign === 'downarrow') {
            message = 'Probably marketing required?'
          }
        }
        return message
      }

      function toolTipNote (shopDetail) {
        // console.log('stockandrevenuedetail: ', stockandrevenuedetail);
        var stockandrevenuedetail = shopDetail.stockandrevenuedetail
        var message = '-'
        if (stockandrevenuedetail && stockandrevenuedetail.stockvalue && stockandrevenuedetail.revenue) {
          var stockvalue = stockandrevenuedetail.stockvalue
          var revenue = stockandrevenuedetail.revenue
          if (stockvalue.sign === 'uparrow' && revenue.sign === 'uparrow') {
            message = 'Sales are increased above the average over the last 4 months. There seems to be enough stock for this retailer in the coming two/three weeks.'
          } else if (stockvalue.sign === 'uparrow' && revenue.sign === 'downarrow') {
            message = 'Revenue decreased below average of last 4 months but stock availability seems ok.'
          } else if (stockvalue.sign === 'downarrow' && revenue.sign === 'uparrow') {
            message = 'Stock over last 4 months is going down and also sales are increased above the average. This retailer is probably in need of a refill of your companies products in a short term visit!'
          } else if (stockvalue.sign === 'downarrow' && revenue.sign === 'downarrow') {
            message = 'Revenue decreased below average of last 4 months. Stock level seems to decrease aswell.'
          }
        }
        return message
      }

      function isUserAvailable () {
        message = 'Probably available!'
        return message
      }

      function stockAndRevenueValue (shopDetail) {
        var stockandrevenuedetail = shopDetail.stockandrevenuedetail
        var message = '-'
        if (stockandrevenuedetail && stockandrevenuedetail.stockvalue && stockandrevenuedetail.revenue) {
          var stockvalue = stockandrevenuedetail.stockvalue
          var revenue = stockandrevenuedetail.revenue
          message = 'Stock is ' + (stockvalue.value ? (stockvalue.value).toFixed(2) : 0) + ' and revenue is ' + (revenue.value ? (revenue.value).toFixed(2) : 0)
        }

        return message
      }

      // Checking that today Purchase user available of particular shop
      $scope.shopUserAvailable = function (shopSlug, employees) {
        // console.log('shopUserAvailable called: ', shopSlug, employees);
        var message = '-'
        if (employees && employees.length) {
          var employeeLength = employees.length
          var days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
          var today = days[new Date().getDay()]
          // console.log('employees: ', today, shopSlug, employees);
          for (var i = 0; i < employeeLength; i++) {
            if (employees[i] && employees[i].availability && employees[i].availability.length) {
              var availability = employees[i].availability
              for (var j = 0; j < availability.length; j++) {
                if (availability[j].day === today && (availability[j].morning || availability[j].afternoon)) {
                  if (availability[j].morning && availability[j].afternoon) {
                    message = employees[i].fullName + ' is available today.'
                  } else if (availability[j].morning) {
                    message = employees[i].fullName + ' is available in morning.'
                  } else if (availability[j].afternoon) {
                    message = employees[i].fullName + ' is available in afternoon.'
                  }
                }
              }
            }
          }
        }

        return message
      }
    }
  ]
)
