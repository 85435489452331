prismanoteApp.controller('retailerPaymentModalController', ['$scope', '$rootScope', 'transaction', 'payment', 'amount', 'manual', '$api', '$uibModalInstance', '$timeout', '$card', '$language', '$http', 'payNlService', '$interval',
  function ($scope, $rootScope, transaction, payment, amount, manual, $api, $uibModalInstance, $timeout, $card, $language, $http, payNlService, $interval) {
    $scope.transaction = transaction
    $scope.payment = payment
    $scope.amount = amount
    $scope.manual = manual

    $scope.progress = 0
    $scope.progressType = 'primary'

    $scope.paymentErrored = false
    $scope.partialPayment = {
      amount: null,
      payment: false
    }

    $scope.cardTypes = $card.getCards()
    $scope.defaultCard = $rootScope.currentShop.cashRegister.defaultCard
    $scope.paymentFinal = false

    $scope.iProgressTimeout = 150
    $scope.iStatusTimeout = 1500
    $scope.iFinish = (Date.now() + 45000)
    $scope.rStatusTimeout = null
    $scope.rProgressTimeout = null
    $scope.strTerminalStatus = 'start'
    $scope.strStatusPayment = ''
    $scope.paymentButtonDisabled = true

    var tteCounter = null

    $scope.setProgress = function () {
      var percPerIteration = 2.222 / (1000 / $scope.iProgressTimeout)
      $scope.progress += percPerIteration

      $scope.progressType = $scope.progress > 80 ? 'danger' : $scope.progress > 60 ? 'warning' : 'primary'

      clearTimeout($scope.rProgressTimeout)
      $scope.rProgressTimeout = $timeout(function () {
        if ($scope.strTerminalStatus === 'start') {
          $scope.setProgress()
        }
      }, $scope.iProgressTimeout)
    }

    $scope.close = function (data) {
      $uibModalInstance.close(data)
    }

    $scope.manualClose = function (success, card, partial) {
      $uibModalInstance.close({ success: success, card: card, partialPayment: partial, paidAmount: partial ? $scope.partialPayment.amount : null })
    }

    $scope.processStatusResponse = function (result) {
      $scope.strTerminalStatus = result.status

      if (result.approved === '1') {
        $scope.close({ result: result, payment: $scope.payment })
        return
      } else if (result.incidentcodetext === 'TIMEOUT_EXPIRATION') {
        $scope.strStatusPayment = 'expired'
        $scope.paymentErrored = true
      } else if (result.cancelled === '1') {
        $scope.strStatusPayment = 'cancelled'
        $scope.progressType = 'danger'
        $scope.paymentErrored = true
      } else if (result.error === '1') {
        $scope.strStatusPayment = 'error'
        $scope.progressType = 'danger'
        $scope.paymentErrored = true
      } else if (result.status === 'start') {
        $scope.strStatusPayment = 'init'
      } else if (result.status === 'final' && result.approved !== '1') {
        $scope.strStatusPayment = 'expired'
        $scope.progressType = 'danger'
        $scope.paymentErrored = true
      }

      if($scope.strStatusPayment !== 'init') {
        console.error('$scope.strStatusPayment', $scope.strStatusPayment)
        console.error('terminal result', result)
        $scope.tte = 5
        tteCounter = $interval(function () {
          $scope.tte -= 1
          if ($scope.tte <= 0) {
            $scope.paymentButtonDisabled = false
            $interval.cancel(tteCounter)
          }
        }, 1000)
      }

      if (result.incidentcodetext) {
        $scope.paymentFinal = true
        $scope.paymentStatus = 'PAYMENT_' + result.incidentcodetext
        $scope.progress = 100
        //     //All possible statuses:
        //     //APPROVED
        //     //HOST_CANCELLATION
        //     //DEVICE_CANCELLATION
        //     //USER_CANCELLATION
        //     //MERCHANT_CANCELLATION
        //     //TIMEOUT_EXPIRATION
        //     //For translations: Add PAYMENT_ before these strings
      }
    }

    $scope.checkPaymentStatus = function () {
      $scope.alert = null
      $scope.paymentStatus = 'PAYMENT_WAITING_ON_CUSTOMER'

      payNlService.getTerminalStatus($scope.payment.terminalStatusUrl)
        .then(function (response) {
          $scope.processStatusResponse(response.data)
          clearTimeout($scope.rStatusTimeout)
          if ($scope.strTerminalStatus === 'start') {
            $scope.rStatusTimeout = $timeout(function () {
              $scope.checkPaymentStatus()
            }, $scope.iStatusTimeout)
          }
        })
        .catch(function (response) {
          if (Date.now() < $scope.iFinish) {
            clearTimeout($scope.rStatusTimeout)
            $scope.rStatusTimeout = $timeout(function () {
              $scope.checkPaymentStatus()
            }, $scope.iStatusTimeout)
          }
        })
    }

    if (!$scope.manual) {
      $scope.checkPaymentStatus()
      $scope.setProgress()
    }

    $scope.restartPayment = function () {
      $scope.paymentButtonDisabled = true

      $scope.alert = null
      $card.restartPayNlPayment($scope.transaction, $scope.amount)
        .then(function (payment) {
            $scope.paymentFinal = false
            $scope.paymentErrored = false
            $scope.payment = payment
            $scope.progress = 0
            $scope.iFinish = (Date.now() + 45000)
            $scope.strTerminalStatus = 'start'
            $scope.strStatusPayment = ''

            $scope.checkPaymentStatus()
            $scope.setProgress()
        })
        .catch(function (reason) {
          console.error(reason)
          $scope.alert = {
            type: 'danger',
            msg: reason
          }
          setTimeout(function () {
            $scope.paymentButtonDisabled = false
          }, 5000)
        })
    }

    $scope.proceedtoManual = function () {
      $scope.manual = true
    }

    $scope.payWithDefaultCard = function () {
      $scope.manualClose(true, $scope.defaultCard, false)
    }
  }])
