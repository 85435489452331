prismanoteApp.controller('customerSendEmailModalController', ['$scope', '$api', '$uibModalInstance', '$language', 'customer', 'email', '$rootScope', '$defaultEmail',
  function ($scope, $api, $uibModalInstance, $language, customer, email, $rootScope, $defaultEmail) {
    $scope.customer = customer
    $scope.email = typeof(email) != 'undefined' ? email :{}
    $scope.confirmedMail = true
    if($scope.email && $scope.email.isFrom){
      $scope.isFrom = $scope.email.isFrom;
      $scope.productName = $scope.email.productName;
      if($scope.isFrom == 'reservation'){
        $scope.confirmMail = true;
        $scope.email.subject = $language.translate('RESERVATION_MAIL_SUBJECT');
        $scope.email.body = $language.translate('TEXT_DEAR') + ' ' + $scope.customer.firstName + ' '+ $scope.customer.lastName + ',<br /><br />'
        + $language.translate('THANKS_FOR_RESERVE_PRODUCT') + ' '+ $scope.productName +'.<br />'
        + $language.translate('MESSAGE_CONFIRM_RESERVE_PRODUCT') + ' ..'+ $language.translate('TEXT_TIME_AT') +'..<br /><br />'
        + $language.translate('BEST_REGARDS') + ',<br />'
        + $rootScope.currentShop.name;
      }
      delete($scope.email.isFrom);
      delete($scope.email.productName);
    }

    $scope.getDefaultEmails = function () {
      $defaultEmail.getEmail()
        .then( function (emails) {
          if(emails && emails.length > 0) {
            $scope.defaultEmails = emails;
          }
        })
        .catch( function (reason) {
          console.error('Error fetching default emails', reason)
          toastr.error(reason)
        })
    }

    $scope.getDefaultEmails()


    $scope.alert = null
    $scope.sending = false

    $scope.confirmMail = function () {
      $scope.email.body = $language.translate('TEXT_DEAR') + ' ' + $scope.customer.firstName + ' '+ $scope.customer.lastName + ',<br /><br />'
        + $language.translate('THANKS_FOR_RESERVE_PRODUCT') + ' '+ $scope.productName +'.<br />'
        + $language.translate('MESSAGE_CONFIRM_RESERVE_PRODUCT') + ' ..'+ $language.translate('TEXT_TIME_AT') +'..<br /><br />'
        + $language.translate('BEST_REGARDS') + ',<br />'
        + $rootScope.currentShop.name;
      $scope.confirmedMail = true;
    }
    $scope.cancelMail = function () {
      $scope.email.body = $language.translate('TEXT_DEAR') + ' ' + $scope.customer.firstName + ' '+ $scope.customer.lastName + ',<br /><br />'
        + $language.translate('THANKS_FOR_RESERVE_PRODUCT') + ' '+ $scope.productName + '.<br />'
        + $language.translate('MESSAGE_CANCEL_RESERVE_PRODUCT') + '<br /><br />'
        + $language.translate('BEST_REGARDS') + ',<br />'
        + $rootScope.currentShop.name;
      $scope.confirmedMail = false;
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    $scope.send = function () {
      $scope.alert = null
      $scope.sending = true
      $api.post('customers/email', {
        customer: $scope.customer._id,
        email: $scope.email,
        shopId: $rootScope.currentShop._id
      })
        .then(function (result) {
          $uibModalInstance.close($scope.confirmedMail)
        })
        .catch(function (reason) {
          $scope.sending = false
          $scope.alert = {
            type: 'danger',
            msg: (reason === 'NOT_VERIFIED' ? $language.translate('YOU_CANT_SEND_EMAILS_BEFORE_VERIFYING_YOUR_ADDRESS') : reason)
          }
        })
    }

    $scope.prefillEmail = function (email) {
      var text = $defaultEmail.replacePlaceholder(email.text, customer)

      $scope.email.body = text

    }
  }])
