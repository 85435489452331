prismanoteApp.controller('chooseBrandForPurchaseOrderProductModalController',
  ['$scope', '$uibModalInstance', '$rootScope', '$purchaseOrder', 'purchaseOrderDetail', '$state',
    function ($scope, $uibModalInstance, $rootScope, $purchaseOrder, purchaseOrderDetail, $state) {
      console.log('chooseBrandForPurchaseOrderProductModalController called: ', purchaseOrderDetail)

      $scope.data = {
        loading: false,
        purchaseOrders: purchaseOrderDetail.purchaseOrders,
        brands: purchaseOrderDetail.brands,
        language: $rootScope.language || 'en',
        isCalculationPrice: true,
        isAnySellingPriceZero: purchaseOrderDetail.isAnySellingPriceZero
      }

      $scope.changeTheBrand = function (purchaseOrderIndex, purchaseOrderItemIndex, brand) {
        console.log('------------ changeTheBrand!!');
        console.log('purchaseOrderIndex ', purchaseOrderIndex);
        console.log('purchaseOrderItemIndex ', purchaseOrderItemIndex);
        console.log('brand ', brand);

        if($scope.data.purchaseOrders[purchaseOrderIndex] && $scope.data.purchaseOrders[purchaseOrderIndex].order && $scope.data.purchaseOrders[purchaseOrderIndex].order.items){
          for(i = purchaseOrderItemIndex; i < $scope.data.purchaseOrders[purchaseOrderIndex].order.items.length; i++){
            $scope.data.purchaseOrders[purchaseOrderIndex].order.items[i].brand = brand;
          }
        }
        // This line isn't required i guess.
        // $scope.data.purchaseOrderDetail[purchaseOrderIndex].items[purchaseOrderItemIndex].brand = brand
      }

      $scope.changeTheMargin = function (purchaseOrderIndex, purchaseOrderItemIndex, add_margin) {
        if($scope.data.purchaseOrders[purchaseOrderIndex] && $scope.data.purchaseOrders[purchaseOrderIndex].order && $scope.data.purchaseOrders[purchaseOrderIndex].order.items && $scope.data.purchaseOrders[purchaseOrderIndex].order.items.length){
          for(i = purchaseOrderItemIndex; i < $scope.data.purchaseOrders[purchaseOrderIndex].order.items.length; i++){
            $scope.data.purchaseOrders[purchaseOrderIndex].order.items[i].add_margin = add_margin;
          }
        }
      }

      $scope.closeModal = function (option) {
        $uibModalInstance.close(option)
      }

      $scope.createPurchaseOrder = function () {
        console.log('---------- createPurchaseOrder!!');
        var body = {
          purchaseOrders: $scope.data.purchaseOrders,
          shopId: $rootScope.currentShop._id,
          isCalculationPrice: $scope.data.isCalculationPrice
        }
        $scope.data.loading = true;
        $purchaseOrder.createPurchaseOrderWithSupplierBrand(body).then(function (response) {
          // console.log('Purchase order created successfully: ', response)
          $scope.data.loading = false;
          // $scope.$emit('refreshThePurchaseOrderList');
          $scope.closeModal({ status: 'success' })
          // $state.go('retailer.purchase-orders');
        }).catch(function (error) {
          $scope.data.loading = false;
          console.log('error: ', error)
        })
      }
    }])
