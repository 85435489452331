prismanoteApp.controller('retailerInventoryController', ['$scope', '$shop','$rootScope', '$language', 'prompt', '$inventory', '$uibModal','scanner',
  function ($scope, $shop, $rootScope, $language, prompt, $inventory, $uibModal, scanner) {
    $scope.scannerEnabled = false
    $scope.autoAddProducts = true
    $scope.lastCountedArticle = {}
    $scope.isPerformingSearch = false
    $scope.localdata = {
      showTipsList : false
    }
    
    //check if the scanner supports automatic scanning. If true, then a message will be shown to the user
    $scope.$on('scanner-started', function () {
      $scope.scannerEnabled = true
    })

    $scope.disableScanner = function () {
      $scope.autoAddProducts = !$scope.autoAddProducts
    }

    $scope.$on('scanner-ready', function (event, args) {
      var searchVal = String(args.parsedCode)

      if(searchVal && searchVal.length > 2) {
        //check if the input consists of 9 digits
        //if(searchVal.length === 9 && /^\d+$/.test(searchVal)) {
          $scope.isPerformingSearch = true
          $shop.searchStockProductsInventory($rootScope.currentShop._id, searchVal).then(function (products) {
            
            if(products.length > 0) {
              if(products.length === 1 && $scope.autoAddProducts) { //add to inventory immediately
                $scope.isPerformingSearch = false
                $scope.addToInventory(products[0]);
              } else { //show results first
                $scope.isPerformingSearch = false
                $scope.stockProducts = products
                $scope.totalProducts = $scope.stockProducts.length
                $scope.stockLoad = false
                $scope.showProducts = true
              }
            } else {
              toastr.error($language.translate('NO_RESULTS_FOR') + ': ' + searchVal )
            }
          }).catch(function (error) {
            console.error('Error search stock products inventory', error)
          })
        // } else { //it's probably not an articlenumber, so do a normal search
        //   $scope.findProductBasedSearchValue(searchVal)
        // }
      }
    })

    $scope.renderPage = function (shopId) {
      $scope.loading = true;
      getShopCollectionsForInventory(shopId)
    }

    $scope.showFilter = function() {
      if($scope.localdata.showTipsList){
        $scope.localdata.showTipsList = false
      }else{
        $scope.localdata.showTipsList = true
      }
    }

    function getShopCollectionsForInventory(shopId) {
      $inventory.getInventoryRecords(shopId)
        .then(function (response) {
          $scope.loading = false;
          $scope.collections = response.data;
        })
        .catch(function (reason) {
          $scope.loading = false;
          console.error('Error while get shop collections for inventory', reason);
        })
    }

    $scope.findProductBasedSearchValue = function (searchValue) {
      $scope.stockLoad = true
      $scope.isPerformingSearch = true
      $scope.stockProducts = []
      if (searchValue && searchValue.length > 2) {
        $scope.searchMode = true
        $shop.searchStockProductsInventory($rootScope.currentShop._id, searchValue)
          .then(function (products) {
            $scope.isPerformingSearch = false
            if(products.length > 0) {
              $scope.stockProducts = products
              $scope.totalProducts = $scope.stockProducts.length
              $scope.stockLoad = false
              $scope.showProducts = true
            } else {
              toastr.error('Geen resultaten voor ' + searchValue);
            }
          })
          .catch(function (error) {
            $scope.isPerformingSearch = false
            toastr.error('Error find product based search value', error)
          })
      } else {
        $scope.isPerformingSearch = false
        $scope.searchMode = false
        if ($scope.oldProducts && $scope.oldProducts.length) {
          $scope.stockProducts = $scope.oldProducts
          $scope.stockLoad = false
        }
      }
    }

    $scope.closeModal = function () {
      $scope.showProducts = false;
    }

    $scope.addToInventory = function (product) {
      $scope.showProducts = false;

      var productBody = {
        productId: product.product._id,
        privateLableCollections: product.collections,
        productNumber: product.product.variants[0].productNumber,
        articleNumber: product.shopProduct.articleNumber,
        labelDescription: product.shopProduct.labelDescription,
        purchasePrice: product.shopProduct.purchasePrice,
        sellingPrice: product.shopProduct.price ? Number(product.shopProduct.price * ((product.shopProduct.priceVat / 100) + 1)).toFixed(2) : 0,
        count: 1,
        stock: product.shopProduct.stock
      }
      var body = {
        productBody: productBody,
        countedValue: product.shopProduct.purchasePrice * 1,
        totalCount: 1,
        shopId: $rootScope.currentShop._id
      }

      $inventory.addToInventory(body)
        .then(function (products) {
          toastr.success('Artikel geteld:  ' + productBody.productNumber);
          $scope.lastCountedArticle = body
          getShopCollectionsForInventory($rootScope.currentShop._id);
        })
        .catch(function (error) {
          toastr.error(error);
          console.error('Error adding to inventory', error)
        })
    }

    $scope.resetAllCollectionUnChecked = function (shopId) {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/inventory-processing-modal.html',
        controller: 'inventoryProcessingModalController',
        keyboard: false,
        backdrop: 'static',
        resolve: { }
      })

      modalInstance.result.then(function (result) {
        if (result) {
          if(result == 'continue'){
            $inventory.resetAllCollectionsUnchecked(shopId)
              .then(function (response) {
                $scope.loading = false;
                getShopCollectionsForInventory(shopId)
              })
              .catch(function (reason) {
                $scope.loading = false;
                console.error('Error reset All collections unchecked', reason);
              })
          }else if(result == 'restart'){
            $inventory.deleteAllInventoryRecords(shopId)
              .then(function (response) {
                $scope.loading = false;
                getShopCollectionsForInventory(shopId)
              })
              .catch(function (reason) {
                $scope.loading = false;
                console.error('Error delete all inventory records', reason);
              })
          }
        }
      }, function () {
        //dismissed
      })
    }

    $scope.removeInventoryRecords = function (shopId) {
      prompt({
        title: $language.translate('REMOVE_INVENTORY_RECORDS'),
        message: $language.translate('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_INVENTORY_RECORDS'),
        buttons: [
            {
                label: 'Ok',
                primary: true
            },
            {
                label: 'Cancel',
                primary: false,
                cancel: true
            }
        ]
      }).then(function (response) {
        if(response.primary){
            $inventory.removeInventoryRecords(shopId)
              .then(function (response) {
                $scope.loading = false;
                getShopCollectionsForInventory($rootScope.currentShop._id);
              })
              .catch(function (reason) {
                $scope.loading = false;
                console.error(" ERROR :::: ", reason);
              })
          }
      }).catch(function (error) {
          console.error(error);
      });
    }
  }
])