prismanoteApp.controller('retailerShopController', ['$scope', '$q', '$rootScope', '$uibModal', '$api', 'prompt', '$retailer', '$window', '$state', '$language', 'dialogs', '$email', 'Upload', '$shop', '$user', '$stateParams', '$label', '$jspm', '$device', '$thermalPrint', '$card', '$printer', '$freshdesk', '$defaultEmail',
	function ($scope, $q, $rootScope, $uibModal, $api, prompt, $retailer, $window, $state, $language, dialogs, $email, Upload, $shop, $user, $stateParams, $label, $jspm, $device, $thermalPrint, $card, $printer, $freshdesk, $defaultEmail) {
		$scope.addTax = false;
		$scope.syncResult = null;
		$scope.obj = {
			deviceName: ''
		}
		$scope.brandLoader = false;
		$scope.collectionsSynced = false;
		$scope.savingDocument = false;
		$scope.savingLabel = false;
		$scope.extraDetails = {};
		$scope.newLogo = {};
		$scope.repairPhoto = {}
		$scope.specialPhoto = {}
		$scope.ep = {};
		$scope.dates = {};
		$scope.terminalSelectMode = false;
		$scope.localdata = {
			showAdvisoryPriceForRecommendation: false
		}
		$scope.activeTab = $stateParams.activeTab ? $stateParams.activeTab : 0

		$scope.dateOptions = {
			minDate: new Date(),
		}
		$scope.urlPattern = /^(https?:\/\/)?(www\.)?[a-z0-9-]*\.?[a-z0-9-]+\.[a-z0-9-]+(\/[^<>]*)?$/;
		$scope.sampleOtherOptions = [];
		$scope.sampleObj = {};
		$scope.shippingOptions = ['ExpressShipping', 'RegisteredShipping', 'Pick-upInStore', 'Neighborhood']
		$scope.extraServices = [];
		$scope.selectedLanguage = $rootScope.language;
		$scope.otherDeviceTranslation = $language.translate('USE_OTHER_DEVICE')
		$scope.otherDevicePrinting = false
		$scope.giftcardLabelFound = false;


		if (!$rootScope.currentShop) {
			$rootScope.currentShop = {};
		} else {
			$scope.oldShop = JSON.parse(JSON.stringify($rootScope.currentShop));
		}

		function getDefaultShopOpeningHours() {
			return ({
				monday: [new Date(1970, 0, 1, 08, 00, 0), new Date(1970, 0, 1, 17, 00, 0)],
				tuesday: [new Date(1970, 0, 1, 08, 00, 0), new Date(1970, 0, 1, 17, 00, 0)],
				wednesday: [new Date(1970, 0, 1, 08, 00, 0), new Date(1970, 0, 1, 17, 00, 0)],
				thursday: [new Date(1970, 0, 1, 08, 00, 0), new Date(1970, 0, 1, 17, 00, 0)],
				friday: [new Date(1970, 0, 1, 08, 00, 0), new Date(1970, 0, 1, 17, 00, 0)],
				saturday: [new Date(1970, 0, 1, 08, 00, 0), new Date(1970, 0, 1, 17, 00, 0)],
				sunday: [new Date(1970, 0, 1, 08, 00, 0), new Date(1970, 0, 1, 17, 00, 0)]
			})
		}

		$scope.shopOpeningHours = {};

		$scope.getShop = function() {
			return $q(function(resolve, reject){
				if (!$rootScope.currentShop || Object.keys($rootScope.currentShop).length < 10) {
					return $retailer.getShop()
					.then(function (shop) {
						$rootScope.currentShop = shop;
						$scope.sampleOtherOptions = shop.shippingOptions ? shop.shippingOptions : [];
						$scope.sampleOtherOptions.forEach(function(element) {
							var isContains = $scope.shippingOptions.includes(element.type)
							if(isContains) {
								$scope.shippingOptions.splice($scope.shippingOptions.indexOf(element.type), 1);
							}
						});
						$scope.jspm = shop.printNode || shop.printNode.enabled === false
						return resolve($rootScope.currentShop)
					})
					.catch(function (reason) {
						reject(reason)
					})
				}else{
					$scope.sampleOtherOptions = $rootScope.currentShop.shippingOptions ? $rootScope.currentShop.shippingOptions : [];
					$scope.sampleOtherOptions.forEach(function(element) {
						var isContains = $scope.shippingOptions.includes(element.type)
						if(isContains) {
							$scope.shippingOptions.splice($scope.shippingOptions.indexOf(element.type), 1);
						}
					});
					$scope.jspm = !$rootScope.currentShop.printNode || $rootScope.currentShop.printNode.enabled === false
					return resolve($rootScope.currentShop)
				}
			})
		}

		//- submenu
		$scope.subMenu = {
			menWatch: false,
			womenWatch: true,
			menJewel: true,
			womenJewel: true,
			boysJewel: true,
			girlsJewel: true,
			Other: true,
			Strap: true,
			boysWatch: true,
			girlsWatch: true,
			watchStrap: true,
		}

		$scope.resetSelected = function () {
			$scope.currentSocialMedia = null;
			$scope.currentHome = null;
			$scope.currentSlide = null;
		}
		$scope.openHome = function (index) {
			$scope.resetSelected();
			$scope.currentHome = $scope.shop.home[index];
		}
		// end submenu

		$scope.backupEmail = function () {
			$scope.emailBackup = angular.copy($rootScope.currentShop.email);
		}

		$scope.openTab = function(tab) {
			$scope.activeTab = tab
		}

		$scope.changeForBg = function () {
			if ($rootScope.isCheckSettings) {
				document.getElementById('checkSettigns').style.border = "medium solid #ffd457";
				$rootScope.isCheckSettings = false;
			}
		}

		function updateShopDetails(currentShop, alertType) {
			$scope.saveAlert=null;
			$scope.noChange = false;
			currentShop._id = (currentShop._id) ? currentShop._id : $rootScope.currentShop._id;

			$shop.updateShopDetails(currentShop, alertType)
				.then(function (updated) {
					if ($rootScope.currentShop.points && !$rootScope.currentShop.points.noEndDate && $rootScope.currentShop.points.endDate) {
						$scope.dates.pointsEnddate = new Date($rootScope.currentShop.points.endDate)
					}

					$scope.sampleOtherOptions = updated.updated.shippingOptions;
					$scope.sampleOtherOptions.forEach(function(element) {
						var isContains = $scope.shippingOptions.includes(element.type)
						if(isContains) {
							$scope.shippingOptions.splice($scope.shippingOptions.indexOf(element.type), 1);
						}
					});
					toastr.success($language.translate('AWESOME_YOUR_CHANGES_SAVED'))
					$scope.checkPaymentProvider()
					$scope.getShop().then(function (shop) {
						if (shop) {
							$scope.oldShop = JSON.parse(JSON.stringify(shop))
						}
						insertIsPoolArticleBrand()
					})
				})
				.catch(function (error) {
					console.error("Error In Update Shop Setting", error);
					$scope.saveAlert = {
						type: 'danger',
						msg: $language.translate(error)
					}
					if (alert) {
						$scope[alert] = {
							type: 'danger',
							msg: $language.translate(error)
						}
					}
				})
		}
		$scope.closeAlert = function () {
			$scope.saveAlert = null
		}

		// Done
		$scope.updateShopBasicData = function (currentShop, editShop) {
			$scope.updateShopBasicDataSubmitted = true;
			if (editShop) {
				$scope.noChange = true
				var newShopData = {}

				if (editShop.name && !editShop.name.$pristine) newShopData['name'] = currentShop.name

				newShopData['email'] = currentShop.email

				if (editShop.website && !editShop.website.$pristine) newShopData['proShopUrl'] = currentShop.proShopUrl
				if (editShop.phonenumber && !editShop.phonenumber.$pristine) newShopData['phone.landLine'] = currentShop.phone.landLine
				if (editShop.mobile_number && !editShop.mobile_number.$pristine) newShopData['phone.mobilePhone'] = currentShop.phone.mobilePhone
				if (editShop.whatsappEnabled && !editShop.whatsappEnabled.$pristine) newShopData['whatsappEnabled'] = currentShop.whatsappEnabled
				if (editShop.inv_postal && (!editShop.inv_postal.$pristine || !editShop.inv_number.$pristine || !editShop.inv_suffix.$pristine || !editShop.inv_street.$pristine || !editShop.inv_city.$pristine)) {
					newShopData['address.country'] = currentShop.address.country
					newShopData['address.postalCode'] = currentShop.address.postalCode
					newShopData['address.houseNumber'] = currentShop.address.houseNumber
					newShopData['address.houseNumberSuffix'] = currentShop.address.houseNumberSuffix
					newShopData['address.street'] = currentShop.address.street
					newShopData['address.street'] = currentShop.address.street
					newShopData['address.city'] = currentShop.address.city
				}
				if (editShop.attn_firstname && !editShop.attn_firstname.$pristine) newShopData['address.attn.firstName'] = currentShop.address.attn.firstName
				if (editShop.attn_middlename && !editShop.attn_middlename.$pristine) newShopData['address.attn.lastNamePrefix'] = currentShop.address.attn.lastNamePrefix
				if (editShop.attn_lastname && !editShop.attn_lastname.$pristine) newShopData['address.attn.lastName'] = currentShop.address.attn.lastName
				if (editShop.employeeLockTime && !editShop.employeeLockTime.$pristine) newShopData['employeeLockTime'] = currentShop.employeeLockTime

				if(editShop.mollieApiKey && !editShop.mollieApiKey.$pristine) {
					newShopData['editShop.mollieApiKey'] = currentShop.editshop.stripeApiKey
				}
				if(editShop.stripeApiKey && !editShop.stripeApiKey.$pristine) {
					newShopData['editShop.stripeApiKey'] = currentShop.editshop.stripeApiKey
				}
				if(editShop.stripeApiKeySignature && !editShop.stripeApiKeySignature.$pristine) {
					newShopData['editShop.stripeApiKeySignature'] = currentShop.editshop.stripeApiKeySignature
				}

				if (JSON.stringify(newShopData).length > 2) updateShopDetails(newShopData, 'saveAlert');
				else $scope.noChange = true;
			}
		}

		$scope.changeInstagramFeedRows = function (number, currentShopId) {
			var tempStore = {
				feedRows: {
					instagram: number
				},
				_id: currentShopId
			}

			$shop.updateShopDetails(tempStore)
				.then(function (updated) {
					$rootScope.currentShop.feedRows.instagram = number
				})
				.catch(function (error) {
					console.error("Error In Update Shop Setting", error)
				})
		};

		// Done
		$scope.updateWebShopDetails = function (currentShop, webShop) {
			if (webShop) {
				$scope.noChange = true;
				var newShopData = {};
				if (webShop.active && !webShop.active.$pristine) newShopData['isShopActive'] = currentShop.isShopActive;
				if (webShop.sendproducts && !webShop.sendproducts.$pristine) newShopData['sendProducts'] = currentShop.sendProducts;
				if (webShop.askWristSize && !webShop.askWristSize.$pristine) newShopData['askWristSize'] = currentShop.askWristSize;
				if (webShop.askFingerSize && !webShop.askFingerSize.$pristine) newShopData['askFingerSize'] = currentShop.askFingerSize;
				if (webShop.reserveProducts && !webShop.reserveProducts.$pristine) newShopData['reserveProducts'] = currentShop.reserveProducts;
				if (webShop.showproducts && !webShop.showproducts.$pristine) newShopData['showAllProducts'] = currentShop.showAllProducts;
				if (webShop.socialMediaPixels_facebook && !webShop.socialMediaPixels_facebook.$pristine) newShopData['socialMediaPixels.facebook'] = currentShop.socialMediaPixels.facebook;
				if (webShop.socialMediaPixels_google && !webShop.socialMediaPixels_google.$pristine) newShopData['socialMediaPixels.google'] = currentShop.socialMediaPixels.google;
				if (webShop.shippingCostsDomastic && !webShop.shippingCostsDomastic.$pristine) newShopData['shippingCostsDomastic'] = currentShop.shippingCostsDomastic;
				if (webShop.shippingCostsEurope && !webShop.shippingCostsEurope.$pristine) newShopData['shippingCostsEurope'] = currentShop.shippingCostsEurope;
				if (webShop.abroadShippingCosts && !webShop.abroadShippingCosts.$pristine) newShopData['abroadShippingCosts'] = currentShop.abroadShippingCosts;
				if (webShop.freeShippingCostsDomastic && !webShop.freeShippingCostsDomastic.$pristine) newShopData['freeShippingCostsDomastic'] = currentShop.freeShippingCostsDomastic;
				if (webShop.freeShippingCostsEurope && !webShop.freeShippingCostsEurope.$pristine) newShopData['freeShippingCostsEurope'] = currentShop.freeShippingCostsEurope;
				if (webShop.freeShippingCostsAbroad && !webShop.freeShippingCostsAbroad.$pristine) newShopData['freeShippingCostsAbroad'] = currentShop.freeShippingCostsAbroad;
				if (webShop.deliveryAndReturnSection && !webShop.deliveryAndReturnSection.$pristine) newShopData['deliveryAndReturn'] = currentShop.deliveryAndReturn;
				if (webShop.uniqueSellingPoint1 && !webShop.uniqueSellingPoint1.$pristine) newShopData['uniqueSellingPoints'] = currentShop.uniqueSellingPoints;
				if (webShop.uniqueSellingPoint2 && !webShop.uniqueSellingPoint2.$pristine) newShopData['uniqueSellingPoints'] = currentShop.uniqueSellingPoints;
				if (webShop.uniqueSellingPoint3 && !webShop.uniqueSellingPoint3.$pristine) newShopData['uniqueSellingPoints'] = currentShop.uniqueSellingPoints;
				if (webShop.uniqueSellingPoint4 && !webShop.uniqueSellingPoint4.$pristine) newShopData['uniqueSellingPoints'] = currentShop.uniqueSellingPoints;
				if (webShop.showProducts && !webShop.showProducts.$pristine) {
					newShopData['showProducts'] = currentShop.showProducts;
					$rootScope.currentShop.showProducts = currentShop.showProducts;
				}
				if (!webShop.paymentpay.$pristine || !webShop.paymentmollie.$pristine || !webShop.paymentstripe.$pristine) {
					newShopData['webshop.paymentProvider'] = currentShop.webshop.paymentProvider;
				}

				if(currentShop.webshop.paymentProvider === 'pay') {
					if (webShop.serviceIdWebshop && !webShop.serviceIdWebshop.$pristine) {
						newShopData['webshop.serviceId'] = currentShop.webshop.serviceId
					}
					if (webShop.apiTokenWebshop && !webShop.apiTokenWebshop.$pristine) {
						newShopData['webshop.apiToken'] = currentShop.webshop.apiToken
					}
					if (webShop.serviceIdWebshop && !webShop.serviceIdWebshop.$pristine) {
						newShopData['webshop.apiCode'] = currentShop.webshop.apiCode
					}
				}

				if(webShop.mollieApiKey && !webShop.mollieApiKey.$pristine) {
					newShopData['webshop.mollieApiKey'] = currentShop.webshop.mollieApiKey
				}
				if(webShop.stripeApiKey && !webShop.stripeApiKey.$pristine) {
					newShopData['webshop.stripeApiKey'] = currentShop.webshop.stripeApiKey
				}
				if(webShop.stripeApiKeySignature && !webShop.stripeApiKeySignature.$pristine) {
					newShopData['webshop.stripeApiKeySignature'] = currentShop.webshop.stripeApiKeySignature
				}

				if (JSON.stringify(newShopData).length > 2) updateShopDetails(newShopData,'webShopAlert');

				else $scope.noChange = true;
			}
		}

		$scope.copyPayDetailsToWebshop = function (webShop) {
			if ($rootScope.currentDevice.pinTerminal) {
				if ($rootScope.currentDevice.pinTerminal.serviceId) {
					$rootScope.currentShop.webshop.serviceId = $rootScope.currentDevice.pinTerminal.serviceId;
					webShop.serviceIdWebshop.$setDirty()
				}
				if ($rootScope.currentDevice.pinTerminal.apiToken) {
					$rootScope.currentShop.webshop.apiToken = $rootScope.currentDevice.pinTerminal.apiToken;
					webShop.apiTokenWebshop.$setDirty()
				}
				if ($rootScope.currentDevice.pinTerminal.apiCode) {
					$rootScope.currentShop.webshop.apiCode = $rootScope.currentDevice.pinTerminal.apiCode;
					webShop.apiCodeWebshop.$setDirty()
				}
				toastr.success('Instellingen gekopieerd, vergeet niet op te slaan')
			}
		}

		$scope.closewebAlert = function () {
			$scope.webShopAlert = null
		}

		// Done
		$scope.updateCashRegister = function (currentShop, cashRegister) {
			if (cashRegister) {
				$scope.noChange = true;
				var newShopData = {}
				//General Settings
				if (cashRegister.confirmPayments && !cashRegister.confirmPayments.$pristine) newShopData['cashRegister.confirmPayments'] = currentShop.cashRegister.confirmPayments
				if (cashRegister.redirect && !cashRegister.redirect.$pristine) newShopData['cashRegister.redirect'] = currentShop.cashRegister.redirect
				if(cashRegister.showLockModal && !cashRegister.showLockModal.$pristine) newShopData['cashRegister.showLockModal'] = currentShop.cashRegister.showLockModal
				if (cashRegister.emailRepairReceipt && !cashRegister.emailRepairReceipt.$pristine) newShopData['cashRegister.emailRepairReceipt'] = currentShop.cashRegister.emailRepairReceipt
				if (cashRegister.emailPurchaseReceipt && !cashRegister.emailPurchaseReceipt.$pristine) newShopData['cashRegister.emailPurchaseReceipt'] = currentShop.cashRegister.emailPurchaseReceipt
				if (cashRegister.useCustomEntry && !cashRegister.useCustomEntry.$pristine) newShopData['cashRegister.useCustomEntry'] = currentShop.cashRegister.useCustomEntry
				if (cashRegister.hideDiscount && !cashRegister.hideDiscount.$pristine) newShopData['cashRegister.hideDiscount'] = currentShop.cashRegister.hideDiscount
				if (cashRegister.useInsuranceOption && !cashRegister.useInsuranceOption.$pristine) newShopData['cashRegister.useInsuranceOption'] = currentShop.cashRegister.useInsuranceOption
				if (cashRegister.autoRepairBagNumber && !cashRegister.autoRepairBagNumber.$pristine) newShopData['cashRegister.autoRepairBagNumber'] = currentShop.cashRegister.autoRepairBagNumber
				if(currentShop.cashRegister.autoRepairBagNumber === true) {
					if (!cashRegister.lastRepairBagNumber.$pristine) newShopData['cashRegister.lastRepairBagNumber'] = currentShop.cashRegister.lastRepairBagNumber
				}
				if (cashRegister.disableEstimateDate && !cashRegister.disableEstimateDate.$pristine) newShopData['cashRegister.disableEstimateDate'] = currentShop.cashRegister.disableEstimateDate
				if (cashRegister.enableEstimateDateSpecial && !cashRegister.enableEstimateDateSpecial.$pristine) newShopData['cashRegister.enableEstimateDateSpecial'] = currentShop.cashRegister.enableEstimateDateSpecial
				if (cashRegister.estimateDateSpecialEndDay && !cashRegister.estimateDateSpecialEndDay.$pristine) newShopData['cashRegister.estimateDateSpecialEndDay'] = currentShop.cashRegister.estimateDateSpecialEndDay
				if (cashRegister.estimateDateSpecialReadyInWeeks && !cashRegister.estimateDateSpecialReadyInWeeks.$pristine) newShopData['cashRegister.estimateDateSpecialReadyInWeeks'] = currentShop.cashRegister.estimateDateSpecialReadyInWeeks
				if (cashRegister.estimateDateSpecialReadyInWeeksAlt && !cashRegister.estimateDateSpecialReadyInWeeksAlt.$pristine) newShopData['cashRegister.estimateDateSpecialReadyInWeeksAlt'] = currentShop.cashRegister.estimateDateSpecialReadyInWeeksAlt

				//Printing
				if (cashRegister.autoDownloadReceipt && !cashRegister.autoDownloadReceipt.$pristine) newShopData['cashRegister.autoDownloadReceipt'] = currentShop.cashRegister.autoDownloadReceipt
				if (cashRegister.printFullProductNameThermal && !cashRegister.printFullProductNameThermal.$pristine) newShopData['cashRegister.printFullProductNameThermal'] = currentShop.cashRegister.printFullProductNameThermal
				if (cashRegister.autoDownloadRepairDocument && !cashRegister.autoDownloadRepairDocument.$pristine) newShopData['cashRegister.autoDownloadRepairDocument'] = currentShop.cashRegister.autoDownloadRepairDocument
				if (cashRegister.printMerchantReceipt && !cashRegister.printMerchantReceipt.$pristine) newShopData['cashRegister.printMerchantReceipt'] = currentShop.cashRegister.printMerchantReceipt
				if (cashRegister.printGiftcard && !cashRegister.printGiftcard.$pristine) newShopData['cashRegister.printGiftcard'] = currentShop.cashRegister.printGiftcard
				if (cashRegister.rePrintGiftcard && !cashRegister.rePrintGiftcard.$pristine) newShopData['cashRegister.rePrintGiftcard'] = currentShop.cashRegister.rePrintGiftcard
				if (cashRegister.openDrawer && !cashRegister.openDrawer.$pristine) newShopData['cashRegister.openDrawer'] = currentShop.cashRegister.openDrawer
				if (cashRegister.openDrawerWithoutReceipt && !cashRegister.openDrawerWithoutReceipt.$pristine) newShopData['cashRegister.openDrawerWithoutReceipt'] = currentShop.cashRegister.openDrawerWithoutReceipt
				if (cashRegister.askReasonOpeningDrawer && !cashRegister.askReasonOpeningDrawer.$pristine) newShopData['cashRegister.askReasonOpeningDrawer'] = currentShop.cashRegister.askReasonOpeningDrawer
				if (cashRegister.drawerConnector && !cashRegister.drawerConnector.$pristine) newShopData['cashRegister.drawerConnector'] = currentShop.cashRegister.drawerConnector
				if (cashRegister.bagNumber && !cashRegister.bagNumber.$pristine) newShopData['cashRegister.bagNumber'] = currentShop.cashRegister.bagNumber
				if (cashRegister.productMaterial && !cashRegister.productMaterial.$pristine) newShopData['cashRegister.productMaterial'] = currentShop.cashRegister.productMaterial
				if (cashRegister.whatsappDefault && !cashRegister.whatsappDefault.$pristine) newShopData['cashRegister.whatsappDefault'] = currentShop.cashRegister.whatsappDefault

				if (cashRegister.printRepairReceiptThermal && !cashRegister.printRepairReceiptThermal.$pristine) {
					newShopData['cashRegister.printRepairReceiptThermal'] = currentShop.cashRegister.printRepairReceiptThermal
				}
				if (cashRegister.printSpecialReceiptThermal && !cashRegister.printSpecialReceiptThermal.$pristine) {
					newShopData['cashRegister.printSpecialReceiptThermal'] = currentShop.cashRegister.printSpecialReceiptThermal
				}
				if (cashRegister.printDeviceNameThermal && !cashRegister.printDeviceNameThermal.$pristine) {
					newShopData['cashRegister.printDeviceNameThermal'] = currentShop.cashRegister.printDeviceNameThermal
				}
				// Payment terminal
				if (cashRegister.terminalProvider && currentShop.cashRegister && currentShop.cashRegister.terminal && currentShop.cashRegister.terminal.provider && !cashRegister.terminalProvider.$pristine) newShopData['cashRegister.terminal.provider'] = currentShop.cashRegister.terminal.provider
				// Terminal CCV
				if (cashRegister.ccvApiCode && !cashRegister.ccvApiCode.$pristine) newShopData['cashRegister.terminal.apiToken'] = currentShop.cashRegister.terminal.apiToken
				if (cashRegister.ccvManagementId && !cashRegister.ccvManagementId.$pristine) newShopData['cashRegister.terminal.apiCode'] = currentShop.cashRegister.terminal.apiCode
				if (cashRegister.ccvTerminalId && !cashRegister.ccvTerminalId.$pristine) newShopData['cashRegister.terminal.terminalId'] = currentShop.cashRegister.terminal.terminalId

				if (cashRegister.defaultCard && !cashRegister.defaultCard.$pristine) newShopData['cashRegister.defaultCard'] = currentShop.cashRegister.defaultCard

				//Receipt footer
				if (cashRegister.footerTextReceipt && !cashRegister.footerTextReceipt.$pristine) newShopData['cashRegister.footerTextReceipt'] = currentShop.cashRegister.footerTextReceipt

				//Product Quick buttons
				if (cashRegister.quickButtons && !cashRegister.quickButtons.$pristine) {
					newShopData['cashRegister.quickButtons.enabled'] = currentShop.cashRegister.quickButtons.enabled
				}

				if (JSON.stringify(newShopData).length > 2) updateShopDetails(newShopData,'cashRegisterAlert')
				else $scope.noChange = true;
			}
		}
		$scope.closecashAlert = function() {
			$scope.cashRegisterAlert = null
		}

		// Done
		$scope.updatefinancialShopDetails = function (currentShop, financialForm) {
			if (financialForm) {
				$scope.noChange = true

				var newShopData = {	}
				if (!financialForm.bankAccountNumber.$pristine) newShopData['bankAccountNumber'] = currentShop.bankAccountNumber
				if (!financialForm.accountHolder.$pristine) newShopData['accountHolder'] = currentShop.accountHolder
				if (!financialForm.pvc.$pristine) newShopData['pvc'] = currentShop.pvc
				if (!financialForm.vatNumber.$pristine) newShopData['vatNumber'] = currentShop.vatNumber
				if (!financialForm.invoiceNumbering.$pristine) newShopData['financial.invoiceNumber'] = currentShop.financial.invoiceNumber
				if (!financialForm.transactionInvoiceNumber.$pristine) newShopData['financial.transactionInvoiceNumber'] = currentShop.financial.transactionInvoiceNumber
				//Day closure
				if (!financialForm.dcweek.$pristine || !financialForm.dcday.$pristine) {
					newShopData['dayClosure'] = currentShop.dayClosure
				}
				//Profit method
				if (financialForm.valuationProfitMethod && !financialForm.valuationProfitMethod.$pristine) newShopData['valuationProfitMethod'] = currentShop.valuationProfitMethod

				//Taxgiftcard
				if (!financialForm.giftTax1.$pristine || !financialForm.giftTax0.$pristine) {
					newShopData['financial.taxGiftcard'] = currentShop.financial.taxGiftcard
				}
				//taxgold
				if (!financialForm.goldTax1.$pristine || !financialForm.goldTax0.$pristine) {
					newShopData['financial.taxGold'] = currentShop.financial.taxGold
				}
				if (JSON.stringify(newShopData).length > 2) {
					updateShopDetails(newShopData, 'financialFormAlert')
				}
				else $scope.noChange = true
			}
		}

		$scope.closefinancialAlert = function() {
			$scope.financialFormAlert = null
		}

		$scope.updateShopPointSettings = function (currentShop, shopPointsForm) {
			if (shopPointsForm) {
				$scope.noChange = true
				var newShopData = {}
				if (!shopPointsForm.points.$pristine) {
					newShopData['points.enabled'] = currentShop.points.enabled
					newShopData['points.pointsOnWebshop'] = currentShop.points.enabled
				}
				if (shopPointsForm.discountPoints && !shopPointsForm.discountPoints.$pristine) newShopData['points.discountPoints'] = currentShop.points.discountPoints
				if (shopPointsForm.repairPoints && !shopPointsForm.repairPoints.$pristine) newShopData['points.repairPoints'] = currentShop.points.repairPoints
				if (shopPointsForm.pointWithCoupon && !shopPointsForm.pointWithCoupon.$pristine) newShopData['points.usePointWithCoupon'] = currentShop.points.usePointWithCoupon
				if (shopPointsForm.pointWithSaleProduct && !shopPointsForm.pointWithSaleProduct.$pristine) newShopData['points.usePointWithSale'] = currentShop.points.usePointWithSale
				if (shopPointsForm.showOnPdf && !shopPointsForm.showOnPdf.$pristine) newShopData['points.showOnPdf'] = currentShop.points.showOnPdf

				if (shopPointsForm.perEuro1 && !shopPointsForm.perEuro1.$pristine) {
					newShopData['points.perEuro'] = currentShop.points.perEuro
					newShopData['points.perEuro1'] = currentShop.points.perEuro1
				}
				if (shopPointsForm.perEuro2 && !shopPointsForm.perEuro2.$pristine) {
					newShopData['points.perEuro'] = currentShop.points.perEuro
					newShopData['points.perEuro2'] = currentShop.points.perEuro2
				}
				if (shopPointsForm.perPoint1 && !shopPointsForm.perPoint1.$pristine) {
					newShopData['points.discountPerPoint'] = currentShop.points.discountPerPoint
					newShopData['points.perPoint1'] = currentShop.points.perPoint1
				}
				if (shopPointsForm.perPoint1 && !shopPointsForm.perPoint2.$pristine) {
					newShopData['points.discountPerPoint'] = currentShop.points.discountPerPoint
					newShopData['points.perPoint2'] = currentShop.points.perPoint2
				}
				if (shopPointsForm.pointsOnWebshop && !shopPointsForm.pointsOnWebshop.$pristine) {
					newShopData['points.pointsOnWebshop'] = currentShop.points.pointsOnWebshop
				}
				//This field can be hidden
				if (shopPointsForm.value && !shopPointsForm.value.$pristine) newShopData['points.validity.value'] = currentShop.points.validity.value
				if (shopPointsForm.validity && !shopPointsForm.validity.$pristine) newShopData['points.validity.entity'] = currentShop.points.validity.entity
				if (shopPointsForm.endDate && !shopPointsForm.endDate.$pristine && $scope.dates.pointsEnddate) {
					newShopData['points.endDate'] = new Date($scope.dates.pointsEnddate)
				}
				if (shopPointsForm.noEndDate && !shopPointsForm.noEndDate.$pristine) {
					newShopData['points.noEndDate'] = currentShop.points.noEndDate
					newShopData['points.endDate'] = null
				}

				if (JSON.stringify(newShopData).length > 2) updateShopDetails(newShopData,'shopPointsAlert');
				else $scope.noChange = true
			}

		}
		$scope.closeshopPointAlert = function() {
			$scope.shopPointsAlert = null
		}

		// Done
		$scope.updateShopDescription = function (currentShop, description) {
			$scope.noChange = true
			var newShopData = {}
			if (!description.$pristine) newShopData['description'] = currentShop.description

			if (JSON.stringify(newShopData).length > 2) updateShopDetails(newShopData)
			else $scope.noChange = true
		}

		$scope.updateShopGeneralSetting = function (currentShop, generalSetting) {
			$scope.noChange = true
			var newShopData = {}
			if (generalSetting) {
				if (generalSetting.articlenumberstart && !generalSetting.articlenumberstart.$pristine) newShopData['articleNumberStart'] = currentShop.articleNumberStart
				if (generalSetting.colorPicker_backgroundColor && !generalSetting.colorPicker_backgroundColor.$pristine) newShopData['identity.email.backgroundColor'] = currentShop.identity.email.backgroundColor
				if (generalSetting.colorPicker_accentColor && !generalSetting.colorPicker_accentColor.$pristine) newShopData['identity.email.accentColor'] = currentShop.identity.email.accentColor

				if (generalSetting.purchaseOrder && !generalSetting.purchaseOrder.$pristine) {
					newShopData['searchMethods.purchaseOrder'] = currentShop.searchMethods.purchaseOrder
				}
				if (generalSetting.webshop && !generalSetting.webshop.$pristine) {
					newShopData['searchMethods.webshop'] = currentShop.searchMethods.webshop
				}
				if (generalSetting.catalog && !generalSetting.catalog.$pristine) {
					newShopData['searchMethods.catalog'] = currentShop.searchMethods.catalog
				}

				if (JSON.stringify(newShopData).length > 2) updateShopDetails(newShopData,'generalSettingAlert')
				else $scope.noChange = true;
			}
		}

		$scope.closegeneralAlert = function() {
			$scope.generalSettingAlert = null
		}

		$scope.$watch('currentShop.address.country', function () {
			$scope.getAddressInfo()
		}, true)

		$scope.getAddressInfo = function () {
			if (!$rootScope.currentShop || !$rootScope.currentShop.address) return
			var address = $rootScope.currentShop.address

			if (!address || address.country !== 'NL' || !address.postalCode || !address.houseNumber || address.postalCode.length < 6) {
				delete address.zipcode
				return
			} else {
				address.zipcode = true
				return;

				// $api.post('get-address-info', { zipcode: address.postalCode, number: address.houseNumber })
				// 	.then(function (result) {
				// 		$rootScope.currentShop.address.street = result.data.address.street;
				// 		$rootScope.currentShop.address.city = result.data.address.city;
				// 		$retailer.setShop($rootScope.selectedShop, $rootScope.currentShop);
				// 	})
				// 	.catch(function (reason) {
				// 		address.zipcode = false
				// 	})
			}
		}

		$scope.parsePointsEndDate = function () {
			if ($rootScope.currentShop.points && $rootScope.currentShop.points.endDate) {
				$scope.dates = {
					pointsEnddate: new Date($rootScope.currentShop.points.endDate)
				}
			}
		}

		$scope.addBrands = function (data) {
			var modalInstance = $uibModal.open({
				templateUrl: '../views/modal/add-brands-modal.html',
				controller: 'addBrandsModalController',
				size: 'lg',
				resolve: {
					data: function () {
						return data
					}
				}
			})

			modalInstance.result.then(function (result) {
				if (result) {
					if (result && result.status === 'addBrandToShop') {
						var brand = {
							originalObject: result.brand
						}
						$scope.brandForAdd(brand)
					}
				}
			}, function (err) {
				//dismissed
			})
		}

		// $scope.getBrands = function () {
		// 	$api.get('brands')
		// 		.then(function (response) {
		// 			$scope.brands = response.data.brands
		// 			$scope.brands.forEach(function (brand) {
		// 				brand.name = brand[$rootScope.language].name
		// 			})
		// 			insertIsPoolArticleBrand()
		// 		})
		// 		.catch(function (reason) {
		// 			console.error("Error while getting brands", reason)
		// 		})
		// }

		// If fetching data from api then it will affect to all that's why will get isPoolArticleBrand From Here
		function insertIsPoolArticleBrand() {
			if ($scope.brands && $scope.brands.length) {
				$scope.brands.forEach(function (brand) {
					brand.name = brand[$rootScope.language].name
					if ($rootScope.currentShop && $rootScope.currentShop.brands && $rootScope.currentShop.brands.length) {
						for (var i = 0; i < $rootScope.currentShop.brands.length; i++) {
							if (($rootScope.currentShop.brands[i]._id).toString() === (brand._id).toString()) {
								$rootScope.currentShop.brands[i].isPoolArticleBrand = brand.isPoolArticleBrand
							}
						}
					}
				})
			} else {
				// $scope.getBrands()
			}
		}

		$scope.searchBrands = function (str) {
			matches = []
			$scope.brands.forEach(function (brand) {
				if ((brand[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
					(brand[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
					matches.push(brand)
				}
			})
			return matches
		}

		$scope.searchShopsAndCompanies = function (str) {
			matches = []
			$scope.shopsAndCompanies.forEach(function (single) {
				if ((single.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
					(single.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
					matches.push(single)
				}
			})
			return matches
		}

		$scope.brandForAdd = function (selected) {
			var isBrandAlreadyExist = false
			var BreakForEach = {}
			try {
				angular.forEach($rootScope.currentShop.brands, function (val) {
					if (val._id === selected.originalObject._id) {
						isBrandAlreadyExist = true
						throw BreakForEach
					}
				});
			} catch (e) {
				if (e !== BreakForEach) {
					console.error(e)
				}
			}


			if (isBrandAlreadyExist) {
				prompt({
					title: $language.translate('BRAND_ALREADY_EXISTS'),
					message: $language.translate('THIS_BRAND_IS_ALREADY_AT_YOUR_SHOP_YOU_CAN_EDIT_THAT_ONE')
				}).then(function (success) {
					$scope.brandLoader = false
				}).catch(function (err) {
					console.error('Something went wrong')
				})
			} else {
				var brand = {
					_id: selected.originalObject._id,
					name: selected.title || (selected.originalObject[$rootScope.language] && selected.originalObject[$rootScope.language].name ? selected.originalObject[$rootScope.language].name : ''),
					nameSlug: selected.originalObject.nameSlug || (selected.originalObject[$rootScope.language] && selected.originalObject[$rootScope.language].nameSlug ? selected.originalObject[$rootScope.language].nameSlug : ''),
					description: selected.originalObject.description || (selected.originalObject[$rootScope.language] && selected.originalObject[$rootScope.language].description ? selected.originalObject[$rootScope.language].description : ''),
					images: selected.originalObject.images,
					restricted: selected.originalObject.restricted,
					privateLabel: selected.originalObject.privateLabel,
					isPoolArticleBrand: selected.originalObject.isPoolArticleBrand
				}
				$scope.getCompaniesAndOpenTheModal(brand)
			}
		}

		$scope.brandSelected = function (selected) {
			// show loader that brand is being added
			$scope.brandLoader = true
			var brand = {
				_id: selected.originalObject._id,
				name: selected.title,
				nameSlug: selected.originalObject.nameSlug,
				description: selected.originalObject.description,
				images: selected.originalObject.images,
				restricted: selected.originalObject.restricted,
				privateLabel: selected.originalObject.privateLabel,
				isPoolArticleBrand: selected.originalObject.isPoolArticleBrand
			}
			var existing = false
			angular.forEach($rootScope.currentShop.brands, function (val) {
				if (val._id === brand._id) {
					existing = true
				}
			})
			if (existing === true) {
				prompt({
					title: $language.translate('BRAND_ALREADY_EXISTS'),
					message: $language.translate('THIS_BRAND_ALREADY_EXISTS_AT_YOUR_SHOP')
				}).then(function () {
					//do nothing its just a notification
					$scope.brandLoader = false
				})
				return
			}

			if (($rootScope.currentShop.isPremium || (!$rootScope.currentShop.isPremium && !brand.restricted)) && $rootScope.currentShop.webshopActive || true) {
				$api.post('add-brand-to-shop', {
					brand: brand,
					shopId: $rootScope.currentShop,
					language: $rootScope.language
				}).then(function (success) {
					$scope.brandLoader = false
					$rootScope.currentShop.brands.push(brand);
					$scope.getShop().then(function (shop) {
						if (shop) $scope.oldShop = JSON.parse(JSON.stringify(shop))
						insertIsPoolArticleBrand()
					})

				}, function (err) {
					$scope.brandLoader = false
				})
			}
		}

		$scope.removeBrandProducts = function (brand, options) {
			var defer = $q.defer()
			if (brand != null && typeof brand === 'object' && brand !== {}) {
				$api.post('remove-brand-from-shop', {
					brand: brand._id,
					shopId: $rootScope.currentShop._id,
					language: $rootScope.language
				})
					.then(function (success) {
						defer.resolve()
					}, function (error) {
						defer.reject()
					})
			}
			else {
				defer.reject()
			}
			return defer.promise
		}

		$scope.deleteBrand = function (index) {
			$scope.brandLoader = true
			var brand = $rootScope.currentShop.brands[index]

			var modalInstance = $uibModal.open({
				templateUrl: '../views/modal/remove-products-modal.html',
				controller: 'removeProductsModalController',
				resolve: {
					brand: function () {
						return brand._id
					}
				}
			})

			modalInstance.result
				.then(function (options) {
					$scope.removeBrandProducts(brand, options).then(function (success) {
						$scope.brandLoader = false;
						$rootScope.currentShop.brands.splice(index, 1)

						$scope.getShop().then(function (shop) {
							insertIsPoolArticleBrand()
						})
					}, function (err) {

					})
				})
				.catch(function (reason) {
					$scope.brandLoader = false
				})
		}

		$scope.logo = function (type) {
			if ($rootScope.currentShop && $rootScope.currentShop[type]) {
				if ($rootScope.currentShop[type].src && $rootScope.currentShop[type].src.indexOf('images') !== -1) {
					return $rootScope.currentShop[type].src;
				} else {
					return $rootScope.awsUrl + $rootScope.currentShop[type].src;
				}
			}
		}

		$scope.$on('changeSelectedShop', function () {
			$scope.getShop()
				.then(function (shop) {
					insertIsPoolArticleBrand()
				})
		})

		$scope.redirectToCompany = function (nameSlug) {
			$rootScope.redirectflag = true
			$state.go('retailer.supplier', { 'nameSlug': nameSlug })
		}

		$scope.getCompaniesAndOpenTheModal = function (brand, isEdited) {
			if ($rootScope.redirectflag) {
				return
			}

			$scope.companies = [];
			$shop.getOwnShopCompanies($rootScope.currentShop._id)
				.then(function (companies) {
					$scope.companies = companies
					var alreadyAddedCompaniesInShop = companies

					$api.get('company/' + brand.nameSlug + '/' + $rootScope.currentShop._id)
						.then(function (response) {
							$scope.companiesAsPerBrand = response.data.data
							for (var i = 0; i < $scope.companiesAsPerBrand.length; i++) {
								$scope.companiesAsPerBrand[i].isSelected = { c: false }
								for (var j = 0; j < alreadyAddedCompaniesInShop.length; j++) {
									if (alreadyAddedCompaniesInShop[j]._id === $scope.companiesAsPerBrand[i]._id) {
										$scope.companiesAsPerBrand[i].isSelected = { c: true }
									}
								}
							}

							var mydata = {
								brand: brand,
								companies: $scope.companiesAsPerBrand,
								isEditingBrnad: (isEdited ? isEdited : false)
							}
							var modalInstance = $uibModal.open({
								templateUrl: '../views/modal/retailer-add-company-as-per-brand-modal.html',
								controller: 'retailerAddCompanyAsPerBrandModalController',
								size: 'lg',
								resolve: {
									data: function () {
										return mydata
									}
								}
							})

							modalInstance.result
								.then(function (response) {
									if (response === 'brandadded') {
										$rootScope.$emit('$translateChangeSuccess')
									}
									if (response === "addcompany") {
										$window.location.href = "retailer/add-company"
									}
									resolve(response)
								})

								.catch(function (reason) {
									reject(reason)
								})
							return true
						})
						.catch(function (reason) {
							console.error("Error while getting company", reason)
							return false
						})
				})
				.catch(function (error) {
					console.error("Error! While getting Shop Companies")
					console.error(error)
				})
		};

		$scope.loadPrintDocuments = function () {
			$scope.doc = {}
			$scope.getShop('shippingOptions').then(function (shop) {
				$scope.sampleOtherOptions = typeof(shop.shippingOptions) != 'undefined' ? shop.shippingOptions : [];
				$scope.sampleOtherOptions.forEach(function(element) {
					var isContains = $scope.shippingOptions.includes(element.type)
					if(isContains) {
						$scope.shippingOptions.splice($scope.shippingOptions.indexOf(element.type), 1);
					}
				});
				if (shop) $scope.oldShop = JSON.parse(JSON.stringify(shop))
				insertIsPoolArticleBrand()
				var pageTypes = [
					'TRANSACTION',
					'REPAIR/SPECIAL',
					'OFFER',
					'PURCHASE_ORDER',
					'DAYSTATE',
					'GIFTCARD'
				]

				$scope.pageSizes = [
					'A4',
					'A5',
					'A6',
					'Letter'
				]

				$scope.pageTypes = pageTypes;
				for (var i = 0; i < $rootScope.currentShop.pageFormats.length; i++) {
					var page = $rootScope.currentShop.pageFormats[i];
					var index = _.indexOf($scope.pageTypes, page.name);
					if (index >= 0) {
						$scope.pageTypes.splice(index, 1);
					}
				}
			})
		}

		$scope.saveDocument = function () {
			$scope.doc.margins = {
				top: 50,
				bottom: 50,
				left: 50,
				right: 50
			}
			$scope.savingDocument = true
			if (!$rootScope.currentShop.pageFormats) {
				$rootScope.currentShop.pageFormats = []
			}
			$rootScope.currentShop.pageFormats.push($scope.doc);

			$api.post('shop/' + $rootScope.currentShop._id + "/pageFormats", { page: $scope.doc }, null, 2)
				.then(function (addRes) {
					$scope.loadPrintDocuments()
					$scope.savingDocument = false;
				})
				.catch(function (reason) {
					console.error("Error while adding pageFormat", reason)
				})
		}

		$scope.editPageFormat = function (doc, index) {
			var modalInstance = $uibModal.open({
				templateUrl: '../views/modal/edit-document-modal.html',
				controller: 'editDocumentModalController',
				keyboard: false,
				backdrop: 'static',
				size: 'lg',
				resolve: {
					doc: function () {
						return doc
					}
				}
			})

			modalInstance.result
				.then(function (result) {
					$api.put('shop/' + $rootScope.currentShop._id + "/pageFormats/" + result._id, { page: result }, null, 2)
						.then(function (updateRes) {
							$rootScope.currentShop.pageFormats[index] = result
						})
						.catch(function (reason) {
							console.error("Error while updating pageFormats", reason)
						})
				}, function () {
					//dismissed
				})
		}

		$scope.loadLabels = function () {
			if(!$rootScope.currentShop) {
				return
			}
			if($rootScope.currentShop.newLabel) {
				$scope.labels = []
				$label.getLabels($rootScope.currentShop._id, null, false)
					.then(function (templates) {
						var data = $label.getExampleDataObject()
						_.forEach(templates, function (template) {

							if(!$scope.giftcardLabelFound && template.layout_name && template.layout_name === 'LAYOUTGL') {
								$scope.giftcardLabelFound = true
							}

							$label.makeLabel(template, data)
								.then(function (label) {
									template.previewUrl = label.previewUrl
									template.printCommand = label.printCommand
									$scope.labels.push(template)
								})
						})
					})
					.catch(function (reason) {
						console.error('error when fetching labels', reason)
					})
			} else {
				$scope.label = {}
				$scope.layouts = [
					'LAYOUT1',
					'LAYOUT2',
					'LAYOUT3',
					'LAYOUT4'
				];
			}
		}

		$scope.getLabelPreview = function(label) {
			return '<img src="' + label.previewUrl + '">'
		}

		$scope.saveLabel = function () {
			$scope.savingLabel = true
			if (!$rootScope.currentShop.labels) {
				$rootScope.currentShop.labels = []
			}

			$api.post('shop/' + $rootScope.currentShop._id + "/labels", { label: $scope.label }, null, 2)
				.then(function (result) {
					$rootScope.currentShop.labels.push(result.data.label)
					$scope.loadLabels()
					$scope.savingLabel = false
				})
				.catch(function (reason) {
					console.error("Error while adding label", reason)
				})
		}

		$scope.printPreviewLabel = function (template) {
			$label.printLabel(template.printCommand, 1)
				.then(function() {})
				.catch(function(reason) {
					console.error('Error while printing example label', reason)
					toastr.error(reason)
				})
		}

		$scope.calibratePrinter = function() {
			$label.printRAW('~jc').then(function () {
				toastr.success($language.translate('CALIBRATION_SUCCESSFUL'))
			}).catch(function (reason){
				toastr.error($language.translate('CALIBRATION_NOT_SUCCESSFUL'))
				console.error(reason)
			})
		}

		$scope.saveToPrinter = function (template) {
			$label.saveLabelToPrinterMemory(template)
				.then(function(res) {
					toastr.success($language.translate('LAYOUT_SAVED_IN_PRINTER_MEMORY'))
				})
				.catch(function(reason){
					console.error('Error while sending layout to printer memory', reason)
					toastr.error($language.translate(reason))
				})
		}

		$scope.getPrinterName = function (mode) {
			if (!$rootScope.currentDevice) {
				return $language.translate('NOT_SET')
			}
			var printerName = ''

			if ($scope.jspm === false) {
				var value = mode === 'label' ? 'labelPrinterSettings' : 'thermalPrinter'
				if(!$rootScope.currentDevice[value] || !$rootScope.currentDevice[value].printNode || !$rootScope.currentDevice[value].printNode.printerName || !$rootScope.currentDevice[value].printNode.deviceName) {
					return $language.translate('NOT_SET')
				}
				printerName = $rootScope.currentDevice[value].printNode.printerName + ' (' + $rootScope.currentDevice[value].printNode.deviceName + ')'
			} else {
				if(mode === 'label') {
					if (!$rootScope.currentDevice.labelPrinterSettings) {
						if($rootScope.currentDevice && $rootScope.currentDevice.labelPrinter) {
							printerName = $rootScope.currentDevice.labelPrinter
						} else {
							return $language.translate('NOT_SET')
						}
					}
					if ($rootScope.currentDevice.labelPrinterSettings && $rootScope.currentDevice.labelPrinterSettings.name) {
						printerName = $rootScope.currentDevice.labelPrinterSettings.name

						if ($rootScope.currentDevice.labelPrinterSettings.printDevice) {
							printerName += ' (' + $rootScope.currentDevice.labelPrinterSettings.printDevice.name + ')'
						}
					} else {
						printerName = $rootScope.currentDevice.labelPrinter
					}
				}
				else if (mode === 'thermal' && $rootScope.currentDevice.thermalPrinter) {
					printerName = $rootScope.currentDevice.thermalPrinter.name
					if ($rootScope.currentDevice.thermalPrinter.printDevice) {
						printerName += ' (' + $rootScope.currentDevice.thermalPrinter.printDevice.name + ')'
					}
				} else {
					return $language.translate('NOT_SET')
				}
			}
			return printerName
		}

		$scope.changePrinter = function (mode) {
			var data = {}
			if($scope.jspm === false) {
				if(mode === 'label' && $rootScope.currentDevice.labelPrinterSettings && $rootScope.currentDevice.labelPrinterSettings.printNode){
					data = $rootScope.currentDevice.labelPrinterSettings.printNode
				}
				if(mode === 'thermal' && $rootScope.currentDevice.thermalPrinter.printNode){
					data = $rootScope.currentDevice.thermalPrinter.printNode
				}
			}
			$printer.openSelectPrinterModal(data)
				.then(function (result) {
					if(result) {
						if($scope.jspm) {
							 var printer = result.selectedPrinter

							if(result.selectedDevice && result.devicePrinter) {
								printer = result.devicePrinter
							}
							if(mode === 'label') {
								$rootScope.currentDevice.labelPrinterSettings = {
									name: printer,
									printDevice: result.selectedDevice ? result.selectedDevice : null
								}
								$rootScope.currentDevice.labelPrinter = null
							}
							if(mode === 'thermal') {
								if(!$rootScope.currentDevice.thermalPrinter) {
									$rootScope.currentDevice.thermalPrinter = {}
								}
								$rootScope.currentDevice.thermalPrinter.name = printer
								$rootScope.currentDevice.thermalPrinter.printDevice = result.selectedDevice ? result.selectedDevice : null
							}

						} else {
							if(mode === 'label') {
								$rootScope.currentDevice.labelPrinterSettings = {
									name: null,
									printDevice: null,
									printNode: result.printNode
								}
								$rootScope.currentDevice.labelPrinter = null
							}
							if(mode === 'thermal') {
								$rootScope.currentDevice.thermalPrinter.name = null
								$rootScope.currentDevice.thermalPrinter.printDevice = null
								$rootScope.currentDevice.thermalPrinter.printNode = result.printNode
							}
						}
						$scope.updateDevice()
					}
				})
				.catch(function (reason){
					$log.error('Error selecting printer', reason)
				})
		}

		$scope.editLabel = function (label, index) {
			var modalInstance = $uibModal.open({
				templateUrl: '../views/modal/edit-label-modal.html',
				controller: 'labelModalController',
				size: 'lg',
				keyboard: false,
				backdrop: 'static',
				resolve: {
					label: function () {
						return label
					},
					layouts: function () {
						return $scope.layouts
					}
				}
			})

			modalInstance.result
				.then(function (result) {
					$api.put('shop/' + $rootScope.currentShop._id + "/labels/" + result._id, { label: result }, null, 2)
						.then(function (updateRes) {
							$rootScope.currentShop.labels[index] = result;
						})
						.catch(function (reason) {
							console.error("Error while updating label", reason)
						})
				}, function (reason) {
					if (reason === 'delete') {
						$api.delete('shop/' + $rootScope.currentShop._id + "/labels/" + $rootScope.currentShop.labels[index]._id, null, null, 2)
							.then(function (deleteRes) {
								$rootScope.currentShop.labels.splice(index, 1)
								$scope.loadLabels()
								insertIsPoolArticleBrand()
							})
							.catch(function (reason) {
								console.error("Error while deleting label", reason)
							})
					}
				})
		}

		function validateEmail(email) {
			var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			return re.test(email)
		}

		$scope.changeShopEmail = function (newEmail) {
			if (newEmail === $scope.emailBackup) {
				return
			}

			if (validateEmail(newEmail)) {
				var dlg = dialogs.confirm($language.translate('CHANGE_EMAILADDRESS'), $language.translate("YOU_MUST_VERIFY_YOUR_NEW_EMAILADDRESS"), { size: 'sm' })
				
				dlg.result.then(function (btn) {
					$email.confirmEmail(newEmail, $scope.emailBackup)
						.then(function () {
							$scope.emailBackup = angular.copy(newEmail)
							$rootScope.currentShop.email = newEmail
							$scope.updateShopBasicData($rootScope.currentShop, true)
							dialogs.notify(undefined, $language.translate('AN_EMAIL_WITH_INSTRUCTIONS_IS_SEND'), { size: 'sm' })
						})
						.catch(function (reason) {
							console.error("Error while changing e-mailaddress", reason)
							dialogs.error(undefined, reason, { size: 'sm' })
						})
				}, function (btn) {
					$rootScope.currentShop.email = $scope.emailBackup
				})
			}
		}

		$scope.initPrinter = function () {
			if (!$rootScope.currentShop.devices) {
				$rootScope.currentShop.devices = {}
			}

			if (!$rootScope.currentShop.devices.thermalPrinter) {
				$rootScope.currentShop.devices.thermalPrinter = {
					enabled: false
				}
			}
		}

		$scope.savePrinter = function (currentDevice) {
			if($rootScope.currentShop.newPrinter === true && (!currentDevice.thermalPrinter.linelength_l || !currentDevice.thermalPrinter.linelength_n)) {
				toastr.error($language.translate('PLEASE_ADD_LINELENGTH'))
				return
			}
			$rootScope.currentDevice.thermalPrinter = currentDevice.thermalPrinter
			$scope.updateDevice()
		}

		$scope.measureReceipt = function () {
			$thermalPrint.printMeasuringReceipt()
		}

		$scope.saveShop = function () {
			$retailer.setShop($rootScope.selectedShop, $rootScope.currentShop)
				.then(function (shop) {
					$rootScope.currentShop = shop
					insertIsPoolArticleBrand()
				})
		}

		$scope.photoUpload = function (mode) {
			try {
				$scope.uploadError = {}
				Upload.upload({
					url: 'api/shop/upload-repair-photo',
					data: {
						shopId: $rootScope.currentShop._id,
						file: mode === 'repair' ? $scope.repairPhoto.photo : $scope.specialPhoto.photo
					}
				})
					.then(function (res) {
						var newShopData = {}
						if(mode === 'repair') {
							$rootScope.currentShop.repairPhoto = res.data.file
							$scope.repairPhoto = {}
							newShopData = {
								repairPhoto: res.data.file
							}
						} else {
							$rootScope.currentShop.specialPhoto = res.data.file
							$scope.specialPhoto = {}
							newShopData = {
								specialPhoto: res.data.file
							}
						}

						updateShopDetails(newShopData)
					}, function (res) {
						toastr.error($language.translate(res.data.message))
					}, function (evt) {
						var progressPercentage = parseInt(100.0 * evt.loaded / evt.total)
						$scope.uploadProgress = progressPercentage
					})
			} catch(e) {
				console.error('Catch error: ', e)
			}
		}

		$scope.logoUpload = function () {
			try {
				$scope.uploadError = {}
				Upload.upload({
					url: 'api/shop/uploadlogo',
					data: {
						shopId: $rootScope.currentShop._id,
						file: $scope.newLogo.logo
					}
				})
					.then(function (res) {
						$rootScope.currentShop.logoDark = {
							src: res.data.file
						}
						$rootScope.currentShop.logoLight = {
							src: res.data.file
						}

						$scope.newLogo = {}
						var newShopData = {
							logoDark: {
								src: res.data.file
							},
							logoLight: {
								src: res.data.file
							}
						}

						updateShopDetails(newShopData)
					}, function (res) {
						toastr.error($language.translate(res.data.message))
					}, function (evt) {
						var progressPercentage = parseInt(100.0 * evt.loaded / evt.total)
						$scope.uploadProgress = progressPercentage
					})
			} catch(e) {
				console.error('Catch error: ', e)
			}
		}

		$scope.saveCCVSettings = function () {
			if($rootScope.currentDevice.pinTerminal &&
				$rootScope.currentDevice.pinTerminal.apiToken &&
				$rootScope.currentDevice.pinTerminal.apiCode &&
				$rootScope.currentDevice.pinTerminal.terminalId
			) {
				$scope.updateDevice(true)
			} else {
				toastr.error($language.translate('PLEASE_FILL_IN_ALL_DETAILS'))
			}
		}

		$scope.copySettings = function () {
			//Copy the settings for the payment to the webshop (only PAY) at this moment
			if ($rootScope.currentShop &&
				$rootScope.currentDevice.pinTerminal &&
				$rootScope.currentDevice.pinTerminal.apiCode &&
				$rootScope.currentDevice.pinTerminal.serviceId &&
				$rootScope.currentDevice.pinTerminal.apiToken
			) {
				$scope.updateDevice(true)
			}
		}

		$scope.getTerminals = function () {
			$scope.terminalAlert = null
			$scope.terminalSelectMode = false
			if ($rootScope.currentShop &&
				$rootScope.currentDevice.pinTerminal &&
				$rootScope.currentDevice.pinTerminal.apiCode &&
				$rootScope.currentDevice.pinTerminal.serviceId &&
				$rootScope.currentDevice.pinTerminal.apiToken
			) {
				$scope.updateDevice()
				$api.get('pay/get-terminals', {
					shopId: $rootScope.currentShop._id,
					apiToken: $rootScope.currentDevice.pinTerminal.apiToken,
					serviceId: $rootScope.currentDevice.pinTerminal.serviceId
				}).then(function (terminals) {
					$scope.terminals = []

					if(!terminals.data || terminals.data.length === 0) {
						$scope.terminalAlert = {
							type: 'warning',
							msg: $language.translate('NO_PINTERMINALS_FOUND')
						}
						$scope.resetTerminal()
						return
					}
					$scope.terminalSelectMode = true
					$scope.terminals = terminals.data
				})
					.catch(function (reason) {
						console.error('Error while fetching pin terminals', reason)
						$scope.terminalAlert = {
							type: 'danger',
							msg: reason
						}
						$scope.resetTerminal()
					})
			}
		}

		$scope.setTerminal = function (terminal) {
			$rootScope.currentDevice.pinTerminal.terminalId = terminal.id
			$scope.terminalSelectMode = false
			$scope.updateDevice()
		}

		$scope.resetTerminal = function () {
			$scope.currentDevice.pinTerminal.terminalId = null
			$scope.updateDevice()
		}

		$scope.updateDevice = function (updateShop) {
			$device.updateDevice(null, updateShop)
				.then(function(){
					toastr.success($language.translate('DEVICE_UPDATED'))
					$scope.getCurrentDevice()
				})
				.catch(function(reason){
					console.error('Error while updating device', reason)
					toastr.error(reason.message)
				})
		}

		$scope.checkIBAN = function (form, bank) {
			var valid = IBAN.isValid(bank)
			$scope.invalidIBAN = !valid
			form.bankAccountNumber.$setValidity('bankAccountNumber', valid)
		}

		$scope.calculatePointsPerEuro = function (field1, field2) {
			if (!field1 || !field2) return
			$rootScope.currentShop.points.perEuro = Math.round((field2 / field1) * 100) / 100
		}

		$scope.calculateDiscountPerPoint = function (field1, field2) {
			if (!field1 || !field2) return
			$rootScope.currentShop.points.discountPerPoint = Math.round((field2 / field1) * 100) / 100
		}

		$scope.resetAllCustomerPoints = function () {
			var dlg = dialogs.confirm($language.translate('RESET_POINTS'), $language.translate("ARE_YOU_SURE_TO_RESET_ALL_POINTS_OF_YOUR_CUSTOMERS"), { size: 'md' })
			dlg.result.then(function (btn) {
				$api.post('customers/reset-points', {
					shopId: $rootScope.currentShop._id,
					language: $rootScope.language
				}).then(function (res) {
					$scope.resetAlert = {
						type: 'success',
						msg: $language.translate(res.data.message)
					}
				}).catch(function (reason) {
					console.error("Error while resetting points", reason)
					$scope.resetAlert = {
						type: 'danger',
						msg: $language.translate(reason)
					}
				})

			}, function (btn) {

			})
		}

		$scope.getShopsAndCompanies = function (currentShopId) {

			$shop.getShopsAndCompaniesForSearchForDropShipper(currentShopId)
				.then(function (shopsAndCompanies) {
					$scope.shopsAndCompanies = shopsAndCompanies
				})
				.catch(function (error) {
					console.error("Error While Featching shops and brands for dropshiper", error)
				})
		}

		$scope.checkPointsValidity = function (value) {
			if (value === 'null') {
				$rootScope.currentShop.points.noEndDate = true
			}
		}

		// $scope.addInMyShop = function (newMine) {
		// 	if (!$scope.dropshippers) $scope.dropshippers = []

		// 	var data = {
		// 		name: newMine.originalObject.name,
		// 		isActive: true,
		// 		brands: []
		// 	}

		// 	data['companyId'] = (newMine.originalObject.type === 'company') ? newMine.originalObject._id : null
		// 	data['shopId'] = (newMine.originalObject.type === 'shop') ? newMine.originalObject._id : null

		// 	$scope.dropshippers.push(data);
		// 	updateDropShippersInShop($scope.dropshippers, $rootScope.currentShop._id)
		// }

		$scope.getDropShippers = function () {
			$scope.dropshippers = $rootScope.currentShop.dropShippers || []
		}

		// $scope.activeInActive = function () {
		// 	updateDropShippersInShop($scope.dropshippers, $rootScope.currentShop._id)
		// }

		function updateDropShippersInShop(dropshippers, shopId) {
			$shop.updateDropShippersInShop(dropshippers, shopId)
				.then(function (success) {
				})
				.catch(function (error) {
					console.error("Error!", error)
				})
		}

		$scope.getCurrentUserAllShops = function () {
			$user.getCurrentUserShops($rootScope.user._id, $rootScope.currentShop._id)
				.then(function (response) {
					$scope.currentUserAllShops = response
				})
				.catch(function (error) {
					console.error("Error !", error)
				})
		}

		$scope.addToConnectedShops = function (newMine) { 
			var connectStore = {
				shopId: newMine.originalObject._id,
				name: newMine.originalObject.name,
				address: newMine.originalObject.address,
				status: 'add',
				webShop: false,
				customers: false
			}

			if ($scope.connectedShops && $scope.connectedShops.length) {
				var index = _.findIndex($scope.connectedShops, function (o) { return o.shopId === connectStore.shopId; })
				if (index < 0) openModalForStore(connectStore)
				else $scope.editConnectedShop($scope.connectedShops[index])
			} else {
				openModalForStore(connectStore)
			}
		}


		$scope.editConnectedShop = function (connectStore) {
			connectStore.status = 'edit'
			openModalForStore(connectStore)
		}


		function openModalForStore(connectStore) {
			return $q(function (resolve, reject) {
				var modalInstance = $uibModal.open({
					templateUrl: '../views/modal/connect-new-store-with-shop-modal.html',
					controller: 'connectNewStoreWithShopModalController',
					size: 'lg',
					resolve: {
						connectStore: function () {
							return connectStore
						}
					}
				});

				modalInstance.result
					.then(function (response) {
						if (response.status === 'add') {
							if (!$scope.connectedShops) $scope.connectedShops = [];
							$scope.connectedShops.push(connectStore);
							updateMutiShop($scope.connectedShops, $rootScope.currentShop._id);
						}else if (response.status === 'update') {
							updateMutiShop($scope.connectedShops, $rootScope.currentShop._id, 'update');
						}
						else {
							var index = _.findIndex($scope.connectedShops, function (o) { return o.shopId === response.store.shopId; })
							if (index > -1) $scope.connectedShops.splice(index, 1)
							updateMutiShop($scope.connectedShops, $rootScope.currentShop._id)
						}
					})
					.catch(function (reason) {
						reject(reason)
					})
			})
		}

		$scope.searchCurrentUserShops = function (str) {
			matches = []
			$scope.currentUserAllShops.forEach(function (single) {
				if ((single.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
					(single.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
					matches.push(single)
				}
			})

			return matches
		}

		$scope.searchBrandsForOtherShops = function (str) {
			matches = []
			$rootScope.currentShop.brands.forEach(function (single) {
				if (
					single && single._id && !single._id.isPoolArticleBrand &&
					(single._id[$rootScope.language].name &&
						(single._id[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
						(single._id[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0))) {
					matches.push(single)
				}
			})
			return matches
		}

		$scope.saveShopInitials = function (initials) {
			if(initials.length > 3) {
				toastr.error($language.translate('NO_MORE_THEN_3_CHARACTERS_ARE_ALLOWED'))
				return
			}
			var tempStore = {
				initials: initials,
				_id: $rootScope.currentShop._id
			}

			$shop.updateShopDetails(tempStore)
				.then(function (updated) {
					toastr.success($language.translate('SHOP_UPDATED'))
				})
				.catch(function (error) {
					console.error("Error In Update Shop Setting", error);
					toastr.error(error)
				})
		}

		$scope.addToConnectedShopBrands = function (newMine) {
			if (!$scope.connectedShopBrands) $scope.connectedShopBrands = []
			var connectBrand = {
				brandId: newMine.originalObject._id._id,
				name: newMine.originalObject._id.name,
				showProducts: true,
				canReserve: true,
				canDeliver: true,
				defaultShipper: false,
				company: {
					_id: newMine.originalObject.favoriteSupplier._id._id,
					name: newMine.originalObject.favoriteSupplier._id.name,
					address: newMine.originalObject.favoriteSupplier._id.address
				},
				status: 'add'
			}
			// Have to open modal
			if ($scope.connectedShopBrands && $scope.connectedShopBrands.length) {
				var index = _.findIndex($scope.connectedShopBrands, function (o) { return o.brandId === connectBrand.brandId; })
				if (index < 0) {
					openModalForBrandCompany(connectBrand)
				}
				else {
					$scope.editConnectedCompany($scope.connectedShopBrands[index])
				}
			} else {
				openModalForBrandCompany(connectBrand)
			}
		}


		function openModalForBrandCompany(connectBrand) {
			return $q(function (resolve, reject) {
				var modalInstance = $uibModal.open({
					templateUrl: '../views/modal/connect-company-from-brand-with-shop-modal.html',
					controller: 'connectCompanyFromBrandWithShopModalController',
					size: 'lg',
					resolve: {
						connectBrand: function () {
							return connectBrand
						}
					}
				})

				modalInstance.result
					.then(function (response) {
						if (response.status === 'add') {
							if (!$scope.connectedShopBrands) $scope.connectedShopBrands = []
							$scope.connectedShopBrands.push(response.store)
							updateDropShippers($scope.connectedShopBrands, $rootScope.currentShop._id)
						}
						else if (response.status === 'remove') {
							var index = _.findIndex($scope.connectedShopBrands, function (o) { return o.brandId === response.store.brandId; })
							if (index > -1)
								$scope.connectedShopBrands.splice(index, 1)
								updateDropShippers($scope.connectedShopBrands, $rootScope.currentShop._id)
						}
						else if (response.status === 'edit') {
							var index = _.findIndex($scope.connectedShopBrands, function (o) { return o.brandId === response.store.brandId; })
							if (index > -1) 
								$scope.connectedShopBrands[index] = response.store
								updateDropShippers($scope.connectedShopBrands, $rootScope.currentShop._id)
						}
					})
					.catch(function (reason) {
						reject(reason)
					})
			})
		}

		$scope.editConnectedCompany = function (connectStore) {
			connectStore.status = 'edit'
			openModalForBrandCompany(connectStore)
		}

		$scope.reArrangeMultiShop = function (connectedShops) {
			return $q(function (resolve, reject) {
				var modalInstance = $uibModal.open({
					templateUrl: '../views/modal/re-arrange-multi-shops-modal.html',
					controller: 'reArrangeMultiShopsModalController',
					size: 'lg',
					resolve: {
						connectedShops: function () {
							return connectedShops
						}
					}
				})

				modalInstance.result
					.then(function (response) {
						if (response.status === 'edit') {
							$scope.connectedShops = JSON.parse(JSON.stringify(response.stores))
							updateMutiShop($scope.connectedShops, $rootScope.currentShop._id)
						}
						else if (response.status === 'remove') {
							$scope.connectedShops = []
							updateMutiShop($scope.connectedShops, $rootScope.currentShop._id)
						}
					})
					.catch(function (reason) {
						reject(reason)
					})
			})
		}

		$scope.changeMutiShopStatus = function (mutiShopStatus, currentShopId) {
			var tempStore = {
				canConnectOtherShops: mutiShopStatus,
				_id: currentShopId
			}

			$shop.updateShopDetails(tempStore)
				.then(function (updated) {
				})
				.catch(function (error) {
					console.error("Error In Update Shop Setting", error)

				})
		}

		$scope.changeDropShipperStatus = function (dropShipperStatus, currentShopId) {
			var tempStore = {
				isDropShipper: dropShipperStatus,
				_id: currentShopId
			}

			$shop.updateShopDetails(tempStore)
				.then(function (updated) {
				})
				.catch(function (error) {
					console.error("Error In Update Shop Setting", error)
				})
		}

		function updateMutiShop(tempMutiShops, currentShopId, status) {
			var mutiShops = [];
			var tempMutiShopsLength = tempMutiShops.length
			for (var i = 0; i < tempMutiShopsLength; i++) {
				mutiShops.push({ _id: tempMutiShops[i].shopId || tempMutiShops[i]._id, webShop: tempMutiShops[i].webShop || false, customers: tempMutiShops[i].customers || false })
			}

			var tempStore = {
				multiShops: mutiShops,
				_id: currentShopId
			}

			$shop.updateShopDetails(tempStore)
				.then(function (updated) {
				})
				.catch(function (error) {
					console.error("Error In Update Shop Setting", error);

				})
		}

		$scope.getMutiShops = function (shopId) {
			$shop.getMutiShops(shopId)
				.then(function (mutiShops) {
					$scope.connectedShops = mutiShops
				})
				.catch(function (error) {

				})
		}


		$scope.getDropShippers = function (shopId) {
			$shop.getDropShippers(shopId)
				.then(function (dropShippers) {
					$scope.connectedShopBrands = dropShippers
				})
				.catch(function (error) {

				})
		}

		function updateDropShippers(dropShippers, shopId) {
			$shop.updateDropShippers(dropShippers, shopId)
				.then(function (success) {
				})
				.catch(function (error) {
					console.error("Error", error)
				})
		}


		 $scope.openSetCollections = function () {
            $scope.showLoader = true
            $shop.getShopsCollections($rootScope.currentShop.nameSlug)
                .then(function (success) {
                    $scope.showLoader = false
                    delete $rootScope.currentShop.collections
                    $rootScope.currentShop.collections = success.shopCollections
                    if ($rootScope.currentShop.collections.length > 0) {
                        var modalInstance = $uibModal.open({
                            templateUrl: '../views/modal/retailer-collections-show-options-modal.html',
                            controller: ['$scope', '$uibModalInstance', '$rootScope', 'prompt', '$collection',
                                function ($scope, $uibModalInstance, $rootScope, prompt, $collection) {
                                    $scope.editCollection = false;
                                    $scope.saveCollectionsOptions = function () {
                                        $shop.changeShopCollectionsShowOptions($rootScope.currentShop._id, $rootScope.currentShop.collections)
                                            .then(function (success) {
                                                $scope.cancel()
                                            })
                                            .catch(function (err) {
                                                console.error("Error in the modify shop collections");
                                            })
                                    }

                                    $scope.editPrivateLableCollection = function (shopId, collection, singleIndex) {
                                        $scope.errorLog = {}
                                        var tempCollection = $rootScope.currentShop.collections[singleIndex]
                                        $scope.collectionTemp = JSON.parse(JSON.stringify(tempCollection))
                                        $rootScope.currentShop.collections[singleIndex].edit = true
                                        $scope.editCollection = true
                                    }

                                    $scope.mergePrivateLableCollection = function (shopId, collection, singleIndex) {
                                        $rootScope.currentShop.collections[singleIndex].merge = true
                                        $scope.mergeCollection = true
                                    }

                                    $scope.cancleUpdate = function (singleIndex) {
                                        delete $rootScope.currentShop.collections[singleIndex].edit
                                        $scope.editCollection = false
                                        $rootScope.currentShop.collections[singleIndex] = $scope.collectionTemp
                                    }

                                    $scope.cancelMergeCollections = function (singleIndex) {
                                        delete $rootScope.currentShop.collections[singleIndex].merge
                                        $scope.mergeCollection = false
                                        $scope.haveToMerge = null
                                    }
                                    $scope.getCanMergeCollections = function (collectionId) {

                                        $shop.getCollectionsForMergeCollections(collectionId, $rootScope.currentShop._id)
                                            .then(function (mergeCollections) {
                                                $scope.mergeCollections = mergeCollections
                                            })
                                            .catch(function (error) {
                                                console.error("Error", error)
                                            })
                                    }

                                    var searchItems = function (str) {

                                        $scope.tempName = str

                                        var matches = []

                                        var collections = JSON.parse(JSON.stringify($scope.mergeCollections))

                                        var collcetionLength = collections.length

                                        if (collections !== undefined && collcetionLength > 0) {
                                            collections.forEach(function (item) {
                                                if ((item[$rootScope.language] !== undefined && item[$rootScope.language].name != undefined) &&
																									((item[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
																										(item[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0))) {
                                                	matches.push(item)
																								}
                                            })
                                        }

                                        return matches
                                    }

                                    $scope.searchPrivateLableCollcetion = function (str) {
                                        return searchItems(str, 'privateLable')
                                    }

                                    $scope.privateLableCollectionSelected = function (selected) {
                                        $scope.haveToMerge = JSON.parse(JSON.stringify(selected.originalObject))
                                    }

                                    $scope.mergeTwoCollections = function (keepCollection, replaceCollection) {
                                        $shop.mergeTwoShopCollections(keepCollection._id, replaceCollection._id, $rootScope.currentShop._id)
                                            .then(function (response) {
                                                $shop.getShopsCollections($rootScope.currentShop.nameSlug)
                                                    .then(function (success) {
                                                        $rootScope.currentShop.collections = success.shopCollections
                                                    })
                                                    .then(function (error) {

                                                    })
                                            })
                                            .catch(function (error) {
                                                console.error("Error", error)
                                            })
                                    }

                                    $scope.updateCollection = function (shopId, index) {
                                        $collection.updateOwnPtivateLableCollection(shopId, $rootScope.currentShop.collections[index])
                                            .then(function (error, updatedCollection) {
                                                delete $rootScope.currentShop.collections[index].edit
                                                $scope.editCollection = false
                                            })
                                            .catch(function (error) {
                                                if (error.status === 409) {
                                                    $scope.errorLog = error.data.error
                                                } else {
                                                    console.error("Error In ", error)
                                                }
                                            })
                                    }

                                    $scope.checkCollectionName = function (name, language) {
                                        name = name.toLowerCase()
                                        if (name === 'services') {
                                            $scope.errorLog[language] = true
                                        } else {
                                            $scope.errorLog[language] = false
                                        }
                                    }

                                    // Delete Own Privbate Lable Collection
                                    $scope.deleteOwnPtivateLableCollection = function (shopId, collectionId) {
                                        $collection.deleteOwnPtivateLableCollection(shopId, collectionId)
                                            .then(function (success) {
                                                // Get All Shop Collections
                                                $shop.getShopsCollections($rootScope.currentShop.nameSlug)
                                                    .then(function (success) {
                                                        delete $rootScope.currentShop.collections
                                                        $rootScope.currentShop.collections = success.shopCollections
                                                    })
                                                    .catch(function (error) {
                                                        console.error("error in finding shop collection", error)
                                                    })
                                            })
                                            .catch(function (err) {
                                                if (err.status === 400 && err.data.status === true) {
                                                    prompt({
                                                        title: "Sorry! You can not delete this Collection",
                                                        message: "This Collection is used in " + err.data.data + ' products! Click on OK and we will redirect you to all the products of this collection. In this way you can replace the product collections to another collection'
                                                    }).then(function () {
                                                        $rootScope.selectCollectionFromModal(collectionId)
                                                        $uibModalInstance.dismiss('cancel')
                                                    }, function () {
                                                        reject("Cancel");
                                                    })
                                                } else {
                                                    console.error("Error in remove Private Lable Collection", err)
                                                }
                                            })
                                    }

                                    $scope.cancel = function () {
                                        $uibModalInstance.dismiss('cancel')
                                    }
                                }],
                            size: 'lg'
                        })
                    }
                })
                .catch(function (err) {
                    console.error("Error In Finding the Shop Collections", err)
                })
		}
		
		$scope.checkPaymentProvider = function(){
			if(!$rootScope.currentShop.cashRegister.terminal) {
				$rootScope.currentShop.cashRegister = {
					terminal: {}
				}
			}
			if(!$rootScope.currentShop.webshop) {
				$rootScope.currentShop.webshop = {}
			}
			if($rootScope.currentShop.cashRegister.terminal.provider !== 'paynl' && $rootScope.currentShop.webshop && $rootScope.currentShop.webshop.paymentProvider === 'pay'){
				$rootScope.currentShop.webshop.paymentProvider = 'mollie'
			}
		}

		$scope.changePaymentProvider = function(provider){
				if (provider === 'pay' && $rootScope.currentShop.webshop.mollieApiKey !== '') {
					$rootScope.currentShop.webshop.mollieApiKey = ''
				}
		}

		$scope.changeQuickbuttonOrder = function () {
			return $q(function (resolve, reject) {
				var modalInstance = $uibModal.open({
					templateUrl: '../views/modal/retailer-change-quickbutton-order-modal.html',
					controller: 'retailerChangeQuickbuttonOrderModalController',
					size: 'lg',
					resolve: {
						quickButtons: function () {
							return $rootScope.currentShop.cashRegister.quickButtons.controls
						}
					}
				})

				modalInstance.result
					.then(function (response) {
						var newShopData = {}
						newShopData['cashRegister.quickButtons.controls'] = response.buttons
						updateShopDetails(newShopData,'cashRegisterAlert')
					})
					.catch(function (reason) {
						console.error('Error while changing quickbuttons order', reason)
					})
			})
		}

		$scope.openQuickButtonModal = function(mode, button) {
			return $q(function (resolve, reject) {
				var quickModalButtonModal = $uibModal.open({
					templateUrl: '../views/modal/retailer-quickbutton-modal.html',
					controller: 'retailerQuickButtonModalController',
					size: 'lg',
					keyboard: false,
					backdrop: 'static',
					resolve: {
						button: function () {
							return button;
						},
						mode: function () {
							return mode
						}
					}
				})
				quickModalButtonModal.result.then(function (result) {
					return resolve(result)
				}, function (err) {
					//dismissed
				})
			})
		}

		$scope.setQuickButton = function (button) {
			var buttonData = {}
			if (!$rootScope.currentShop.cashRegister.quickButtons.controls) {
				$rootScope.currentShop.cashRegister.quickButtons.controls = []
			}
			if ($rootScope.currentShop.cashRegister.quickButtons.controls && $rootScope.currentShop.cashRegister.quickButtons.controls[button]) {
				buttonData = $rootScope.currentShop.cashRegister.quickButtons.controls[button]
			}

			$scope.openQuickButtonModal('update', buttonData)
				.then(function(result) {
					if (typeof result === 'object') {
						if (result.empty) {
							$rootScope.currentShop.cashRegister.quickButtons.controls[button] = {
								empty: true,
								name: null,
								price: null,
								shopProduct: null
							}
						} else {
							$rootScope.currentShop.cashRegister.quickButtons.controls[button] = result
						}
						var newShopData = {}
						newShopData['cashRegister.quickButtons'] = {
							enabled: true,
							converted: true,
							controls: $rootScope.currentShop.cashRegister.quickButtons.controls
						}
						updateShopDetails(newShopData,'cashRegisterAlert')
					} else if(result === 'delete') {
						$scope.deleteQuickButton(button)
					}
				})
				.catch(function (reason) {
					console.error('Error while updating quickbutton', reason)
				})
		}

		$scope.createNewQuickButton = function () {
			if (!$rootScope.currentShop.cashRegister.quickButtons.controls) {
				$rootScope.currentShop.cashRegister.quickButtons.controls = []
			}
			$scope.openQuickButtonModal('new', { })
				.then(function(result){
					if (result.empty) {
						$rootScope.currentShop.cashRegister.quickButtons.controls.push({
							empty: true,
							name: null,
							price: null,
							shopProduct: null
						})
					} else {
						$rootScope.currentShop.cashRegister.quickButtons.controls.push(result)
					}
					var newShopData = {}
					newShopData['cashRegister.quickButtons'] = {
						enabled: true,
						converted: true,
						controls: $rootScope.currentShop.cashRegister.quickButtons.controls
					}
					updateShopDetails(newShopData,'cashRegisterAlert')
				})
				.catch(function (reason) {
					console.error('Error while creating new quickbutton', reason)
				})
		}

		$scope.deleteQuickButton = function (button) {
			$rootScope.currentShop.cashRegister.quickButtons.controls.splice(button, 1)

			var newShopData = {}
			newShopData['cashRegister.quickButtons'] = {
				enabled: true,
				controls: $rootScope.currentShop.cashRegister.quickButtons.controls
			}
			updateShopDetails(newShopData,'cashRegisterAlert')
		}

		$scope.empty = $language.translate('EMPTY_BUTTON')
		$scope.getQuickButtonText = function(button) {
			if ($rootScope.currentShop.cashRegister.quickButtons.controls && $rootScope.currentShop.cashRegister.quickButtons.controls.length > 0 && $rootScope.currentShop.cashRegister.quickButtons.controls[button]) {
				var buttonData = $rootScope.currentShop.cashRegister.quickButtons.controls[button]
				if (buttonData && buttonData.name && !buttonData.empty) {
					return buttonData.name + ' €' + (buttonData.price ? buttonData.price : 0)
				} else {
					return $scope.empty
				}
			}
		}

		$scope.fetchShowAdvisoryPrice = function() {
			var showAdvisoryPriceForRecommendation = localStorage.getItem('showAdvisoryPriceForRecommendation');
			if (showAdvisoryPriceForRecommendation === true || showAdvisoryPriceForRecommendation === 'true') {
				showAdvisoryPriceForRecommendation = true;
			} else {
				showAdvisoryPriceForRecommendation = false;
			}

			$scope.localdata.showAdvisoryPriceForRecommendation = showAdvisoryPriceForRecommendation;
			$rootScope.showAdvisoryPriceForRecommendation = showAdvisoryPriceForRecommendation;
		}

		$scope.changeAdvisoryPrice = function() {
			localStorage.setItem('showAdvisoryPriceForRecommendation', $scope.localdata.showAdvisoryPriceForRecommendation);
		}

		$scope.addCoupon = function(obj) {
			var modalInstance = $uibModal.open({
				templateUrl: '../views/modal/coupon-modal.html',
				controller: 'addCouponModalController',
				size: 'lg',
				resolve: {
					couponDetail: function() {
						return obj
					}
				}
			});

			modalInstance.result.then(function (result) {
				if (result === 'created') {
					$api.get('coupons/get-per-shop?shopId='+$rootScope.currentShop._id)
						.then(function (response) {
							$scope.shopCoupons = response.data;
						})
						.catch(function (reason) {
							console.error("Error while getting coupons", reason)
						})
					}
			}, function (err) {
				//dismissed
			})
		}

		$scope.deleteShopCoupons = function(couponId) {
			$api.delete('coupons/delete-coupon/'+ couponId + '/' + $rootScope.currentShop._id)
				.then(function (response) {
					if(response)
					$scope.shopCoupons = $scope.shopCoupons.filter(function(el) { return el._id != couponId; }); 
				})
				.catch(function (reason) {
					console.error("Error while deleting coupons", reason)
				})
		}

		$scope.getShopCoupons = function() {
			$api.get('coupons/get-per-shop?shopId='+$rootScope.currentShop._id)
				.then(function (response) {
					$scope.shopCoupons = response.data;
				})
				.catch(function (reason) {
					console.error("Error while getting coupons", reason)
				})
		}

		$scope.loadExtraService = function(){
			$api.get('v2/extra-shop-services?shopId='+ $rootScope.currentShop._id)
				.then(function (response) {
					if(response.status === 200 && response.data && response.data.data && response.data.data.extraService) {
						$scope.extraServices = response.data.data.extraService;
					} else {
						$scope.extraServices = []
					}
				}).catch(function(error) {
					console.error(error)
				})
		}

		$scope.deleteShopService = function(service){
			var index = _.findIndex($scope.extraServices, function (o) { return o._id === service._id; });
			$scope.extraServices.splice(index, 1);
			$api.delete('v2/extra-shop-services?shopId=' + $rootScope.currentShop._id +'&serviceId=' +service._id )
				.then(function (response) {
					if(response.status === 200) {
						$scope.loadExtraService();
					} 
				}).catch(function(error) {
					console.error(error)
				})
		}
		
		$scope.addNewShopService = function(obj) {
			var modalInstance = $uibModal.open({
				templateUrl: '../views/modal/webshop-service-modal.html',
				controller: 'addEditServiceModalController',
				size: 'lg',
				resolve: {
					serviceDetail: function() {
						return obj;
					},
					serviceList: function(){
						return $scope.extraServices.map(function(s) {return s.option;});
					}
				}
			});

			modalInstance.result.then(function (result) {
				if (result === 'created' || result === 'updated') {
					$scope.loadExtraService();
					// $api.get('coupons/get-per-shop?shopId='+$rootScope.currentShop._id)
					// 	.then(function (response) {
					// 		$scope.shopCoupons = response.data;
					// 	})
					// 	.catch(function (reason) {
					// 		console.error("Error while getting coupons", reason)
					// 	})
				}
			}, function (err) {
				//dismissed
			})
		}

		$scope.checkAddedService = function(){
			if($scope.extraServices.length > 0 && $scope.extraServices.length <= 2){
				var services = $scope.extraServices.filter(function(s) {return s.option == 'giftWrap' || s.option == 'engraving';})
				if(services.length == 2 && services.length == $scope.extraServices.length){
					return true;
				} else {
					return false
				}
			}
			return false;
		}

		$scope.addOtherOptions = function(deliveryType, domesticoptionvalue, europeoptionvalue, abroadoptionvalue) {
			var newShopData = {};
			$scope.sampleObj.type = deliveryType;
			$scope.sampleObj.domestic = domesticoptionvalue;
			$scope.sampleObj.europe = europeoptionvalue;
			$scope.sampleObj.abroad = abroadoptionvalue;
			$scope.sampleOtherOptions.push(angular.copy($scope.sampleObj));
			$scope.shippingOptions.splice($scope.shippingOptions.indexOf(deliveryType), 1);
			newShopData['shippingOptions'] = $scope.sampleOtherOptions;			
			updateShopDetails(newShopData,'webShopAlert');			
		}

		$scope.removeShippingOptions = function(index) {
			var newShopData = {};
			$scope.shippingOptions.push($scope.sampleOtherOptions[index].type);
			$scope.sampleOtherOptions.splice(index, 1);
			newShopData['shippingOptions'] = $scope.sampleOtherOptions;
			updateShopDetails(newShopData,'webShopAlert');
		}

		$scope.getDevices = function () {
			$device.getDevices($rootScope.currentShop._id, null)
				.then(function(devices) {
					$scope.getCurrentDevice()
					$scope.devices = devices
				})
				.catch(function(reason){
					console.error('Error while fetching devices', reason)
				})
		}

		$scope.getDevicesForPrinting = function() {
			return $q(function (resolve, reject){
				$device.getDevices($rootScope.currentShop._id, null)
					.then(function (devices){
						var printingDevices = devices.filter(function (d) {
							return d.type === 'PC' && d.printServer === true
						})
						return resolve(printingDevices)
					})
					.catch(function (reason){
						return reject(reason)
					})
			})
		}

		$scope.createNewDevice = function(name) {
			if($scope.deviceUpdate) {
				$scope.deviceToUpdate.name = name
				$scope.obj.deviceName = ''
				$device.updateDevice($scope.deviceToUpdate)
					.then(function() {
						$scope.getDevices()
						$scope.deviceUpdate = false
					})
					.catch(function(reason){
						console.error('Error while updating device', reason)
						toastr.error($language.translate(reason))
					})

				return
			}

			$device.createDevice(name, $rootScope.currentShop._id)
				.then(function(res) {
					$scope.obj.deviceName = ''
					$scope.getDevices()
				})
				.catch(function(reason){
					toastr.error($language.translate(reason))
					console.error('Error while creating device', reason)
				})
		}

		$scope.downloadCert = function (name, id) {
			$device.downloadCertificate(name, id)
		}

		$scope.changeDevice = function (device, idx) {
			var modalInstance = $uibModal.open({
				templateUrl: '../views/modal/edit-device-modal.html',
				controller: 'editDeviceModalController',
				size: 'lg',
				keyboard: false,
				backdrop: 'static',
				resolve: {
					device: function () {
						return device
					}
				}
			})
			modalInstance.result.then(function (result) {
				$scope.getDevices()
				$scope.getCurrentDevice()
			}, function (err) {
				//dismissed
			})
		}

		$scope.copyDevice = function(device) {
			$scope.deviceToCopy = angular.copy(device)
			$scope.deviceToCopy.name = $scope.deviceToCopy.name + ' ' + $language.translate('_COPY_')
			delete $scope.deviceToCopy._id
			$device.updateDevice($scope.deviceToCopy)
				.then(function() {
					$scope.getDevices()
					$scope.deviceToCopy = null
				})
				.catch(function(reason){
					console.error('Error while copying device', reason)
					toastr.error($language.translate(reason))
				})
		}

		$scope.deleteDevice = function(id) {
			if($scope.devices.length === 1 ) {
				//The last device can't be removed
				return
			}
			var dlg = dialogs.confirm($language.translate('DELETE'), $language.translate('ARE_YOU_SURE_TO_DELETE_THIS_DEVICE'), { size: 'sm' })
			dlg.result.then(function (btn) {
				if (btn === 'yes') {
					$device.deleteDevice(id)
						.then(function(){
							$scope.getDevices()
						})
						.catch(function(reason){
							console.error('Error while deleting device', reason)
						})
				}
			})
			.catch(function (reason) {
			})
		}

		$scope.setDevice = function(id) {
			$device.setCurrentDevice(id)
		}

		$scope.getCurrentDevice = function(){
			$scope.getShop().then(function () {
				$device.getCurrentDevice()
			})
		}

		$scope.getCards = function () {
			$scope.cards = $card.getCards()
		}

		$scope.cardSelected = function(value) {
			if(value === "NONE") {
				$scope.currentShop.cashRegister.defaultCard = null
			} else {
				$scope.currentShop.cashRegister.defaultCard = value
			}
		}

		$scope.openChat = function() {
			$freshdesk.openWindow({id: 101000096958 })
		}

		$scope.openSendRawCommandsModel = function () {
			var rawCommandsModal = $uibModal.open({
				templateUrl: '../views/modal/send-raw-commands-modal.html',
				controller: 'sendRawCommandsModalController',
				size: 'lg',
				resolve: {
					printer: function () {
						return $rootScope.currentDevice.labelPrinterSettings
					}
				}
			})

			rawCommandsModal.result.then(function (result) {

			}, function (err) {
				//dismissed
			})
		}
		
		$scope.openGiftcardLabelsModal = function () {
			var GiftcardLabelsModal = $uibModal.open({
				templateUrl: '../views/modal/giftcard-labels-modal.html',
				controller: 'giftcardLabelsModalController',
				size: 'lg',
				resolve: {
					printer: function () {
						return $rootScope.currentDevice.labelPrinterSettings
					},
					labels: function () {
						return $rootScope.currentShop.labels
					}
				}
			})

			GiftcardLabelsModal.result.then(function (result) {}, function (err) {
				//dismissed
			})
		}
		
		$scope.getEmails = function() {
			$scope.toolbar = [
				['p', 'h3', 'h4', 'h5'],
				['justifyLeft', 'justifyCenter', 'justifyRight'],
				['bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol'],
				['undo', 'redo', 'clear'],
				['placeholder_salutation', 'placeholder_firstName', 'placeholder_prefix', 'placeholder_lastName', 'placeholder_fullName', 'placeholder_address']
			]
			$defaultEmail.getEmail()
				.then( function (emails) {
					$scope.defaultEmails = emails
				})
				.catch( function (reason){
					toastr.error(reason)
				})
		}

		$scope.createEmail = function (name) {
			$defaultEmail.createOrUpdateEmail({name: name})
				.then(function () {
					$scope.newEmail = null
					toastr.success($language.translate('EMAIL_CREATED'))
					$scope.getEmails()
				})
				.catch(function (reason) {
					toastr.error(reason)
				})
		}

		$scope.openEmail = function (id) {
			$scope.currentEmail = null
			var idx = _.findIndex($scope.defaultEmails, {_id: id})

			if(idx >= 0) {
				$scope.currentEmail = $scope.defaultEmails[idx]
				if(!$scope.currentEmail.text) {
					$scope.currentEmail.text = ''
				}
			}
		}

		$scope.saveEmail = function (email) {

			if(!email || !email.name || !email.text) {
				toastr.error($language.translate('EMAIL_DOES_NOT_HAVE_ALL_FIELDS'))
				return
			}

			$defaultEmail.createOrUpdateEmail(email)
				.then(function () {
					toastr.success($language.translate('EMAIL_SAVED'))
					$scope.currentEmail = null
					$scope.getEmails()
				})
				.catch(function(reason){
					toastr.error(reason)
				})
		}

		$scope.getCustomerGroups = function () {
			if($rootScope.currentShop && $rootScope.currentShop.customerGroups) {
				$scope.customerGroups = $rootScope.currentShop.customerGroups
			} else {
				$scope.customerGroups = ['VIP evenement', 'Goud', 'Diamant', 'Horloge', 'kaart/post', 'Groep A', 'Groep B', 'Groep C']
				$scope.saveCustomerGroups()
			}
		}

		$scope.deleteCustomerGroup = function (name, index) {
			prompt({
				title: $language.translate('DELETE_GROUP'),
				message: $language.translate('ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_GROUP')
			  }).then(function (res) {
				  if(res.primary && res.primary === true) {
					$scope.customerGroups.splice(index, 1)
					$scope.saveCustomerGroups()
				  }
			})
		}

		$scope.addCustomerGroup = function (name) {
			
			if(!name) {
				toastr.error($language.translate('NO_GROUPNAME_PROVIDED'))
				return
			}

			if($scope.customerGroups.indexOf(name) >= 0) {
				toastr.warning($language.translate('GROUP_ALREADY_EXISTS'))
				$scope.customerGroup = {}
				return
			}

			$scope.customerGroups.push(name)
			$scope.customerGroup = {}
			$scope.saveCustomerGroups()
		}

		$scope.saveCustomerGroups = function () {
			var shopData = {}
			shopData.customerGroups = $scope.customerGroups
			updateShopDetails(shopData)
		}


}]);
