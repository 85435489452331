prismanoteApp.controller('createWholesalerModalController', ['$scope', '$uibModalInstance', '$api',
  function ($scope, $uibModalInstance, $api) {
    $scope.wholesaler = {}

    $scope.closeModal = function () {
      $uibModalInstance.close($scope.wholesaler)
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }
  }])
