prismanoteApp.controller('brandActivityItemController', ['$scope', '$rootScope', '$api', '$company', '$stateParams', '$state', '$language',
  function ($scope, $rootScope, $api, $company, $stateParams, $state, $language) {
    $scope.isEsitmatedCostChanged = false
    $scope.isDateIsNotReachedByCompany = false
    $scope.loading = false
    $scope.activities = []
    $scope.filter = {};
    // $scope.filter.startDate = new Date();
    /* try {
      console.log('company:' + $stateParams.id + ':shop:added:comment:serviceitem')
      SocketService.on('company:' + $stateParams.id + ':shop:added:comment:serviceitem', function (data) {
        console.log('shop comment', data)
        if ($scope.transaction && $scope.transaction.remarks && $scope.transaction.remarks.remarks && $scope.transaction.remarks.remarks.length && $scope.transaction.remarks.remarks[0]) {
          $scope.transaction.remarks.remarks.push(data)
          $scope.autoScrollcomment()
        } else {
          $scope.transaction = {
            remarks: {
              remarks: [data]
            }
          }
        }
      })
    } catch (catcherr) {
      console.log('catcherr: ', catcherr)
    } */

    $scope.removeEmployee = function (){
      $scope.selectedEmployee = false;
    }

    $scope.selectEmployee = function (employee) {
      $scope.selectedEmployee = employee;
    }

    $scope.searchEmployee = function (str) {
      var matches = []
      if(str) {
        $scope.employees.forEach(function (employee) {
          if (employee) {
            if (employee.firstName && employee.firstName.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0 ||
                employee.lastName && employee.lastName.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) { 
                  employee.name = employee.firstName + ' ' + employee.lastName;
                  matches.push(employee)
            }
          }
        })
        return matches
      }
    }

    $scope.getShopEmployees = function (){
      var shopId = $scope.activities.shopId;
      var companyId = $rootScope.currentCompany._id;

      $api.get('v2/company/get-shopEmployee?shopId=' + shopId + '&companyId=' + companyId)
      .then(function (response) {
        $scope.employees = response.data.data;
      })
      .catch(function (reason) {
        console.log('reason ', reason);
        $scope.loading = false
        console.error(reason)
      })
    }


    $scope.removeUser = function () {
      $scope.selectedUser = false;
    }

    $scope.searchUser = function (str) {
      var matches = []
      if(str) {
        $scope.users.forEach(function (user) {
          if (user) {
            if (user.firstName && user.firstName.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0 ||
                  user.lastName && user.lastName.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) { 
              user.name = user.firstName + ' ' + user.lastName;
              matches.push(user)
            }
          }
        })
        return matches
      }
    }

    $scope.selectUser = function (user) {
      $scope.selectedUser = user;
    }

    $scope.getEmployees = function (){
      console.log('--- getEmployees!!');
      $company.getCompany()
        .then(function (company) {
          $api.get('brand/company-employees/' + company._id)
            .then(function (response) {
              $scope.users = [];
              var userData = response.data.data.employeeDetail;
              var loopTill = userData.length;
              for(var i = 0; i < loopTill; i++){
                if(userData[i] && userData[i].firstName && userData[i].lastName){
                  userData[i].enabled = false;
                  $scope.users.push(userData[i]);
                }
                if(i == (loopTill - 1)){
                  $rootScope.user.enabled = false;
                  $scope.users.push($rootScope.user);
                }
              }
            })
            .catch(function (reason) {
              $scope.loading = false
              console.error(reason)
            })
        })
    }

    $scope.getActivity = function () {
      $scope.loading = true
      var data = {
        id: $stateParams.id,
        currentCompanyId: $rootScope.currentCompany._id
      }
      $api.post('activity-data', data)
        .then(function (response) {
          $scope.loading = false
          console.log('response: ', response);
          $scope.activities = response.data[0]
          // console.log('$scope.activities.date ', $scope.activities.date);
          $scope.filter.startDate = $scope.activities.date;
          // console.log('$scope.filter.startDate ', $scope.filter.startDate);
          $scope.selectedUser = $scope.activities.assignee;
          $scope.selectedUser.title = $scope.selectedUser.firstName + ' ' + $scope.selectedUser.lastName;
          $scope.selectedEmployee = $scope.activities.targetUser;
          $scope.selectedEmployee.title = $scope.selectedEmployee.firstName + ' ' + $scope.selectedEmployee.lastName;
          $scope.getShopEmployees();
         /* if ($scope.transaction.company && $scope.transaction.company.estimatedDate) {
            $scope.transaction.company.estimatedDate = new Date($scope.transaction.company.estimatedDate)
            $scope.currentCompanyExpectedDeliveryDate = $scope.transaction.company.estimatedDate
          }
         
          $scope.shop = response.data.shop */
        })
        .catch(function (reason) {
          $scope.loading = false
          if (reason.status === 404 || reason === 'NO_TRANSACTION_FOUND') {
            // console.log("reason----------------------->", reason);
            $state.go('brand.services')
          }
        })
    }

    $scope.updateActivity = function () {
      console.log('save',$scope.activities)
      $scope.activities.assignee = $scope.selectedUser.originalObject || $scope.selectedUser;
      $scope.activities.targetUser = $scope.selectedEmployee.originalObject || $scope.selectedEmployee;
      $scope.activities.date = $scope.filter.startDate;
      $api.post('update-activity', {
        activity: $scope.activities,
        companyId: $rootScope.currentCompany._id
        /* ,
        sendMailToShop: true */
      })
        .then(function (response) {
          console.log('response', response)
          $state.go('brand.activity')
        })
        .catch(function (reason) {
          console.error('Error while updating transaction', reason)
        })
    }

    $scope.openShopDetail = function (nameSlug) {
      console.log(nameSlug)
      $state.go('brand.shop-detail', { nameSlug: nameSlug })
    }

    $scope.getName = function (creator) {
      return creator.firstName + ' ' + creator.lastName;
    }

  }])
