prismanoteApp.controller('retailerAddCompanyModalController', ['$scope', '$uibModalInstance', '$uibModal', '$api', '$rootScope', '$q', '$state', 'searchValue',
  function ($scope, $uibModalInstance, $uibModal, $api, $rootScope, $q, $state, searchValue) {
    $scope.wholesaler = {}
    $scope.searchValue = ''
    $scope.searchMode = 'exact'
    // console.log('MODAL OPEN ADD COMPANY');
    // console.log('searcvlaue: ', searchValue);
    $scope.findCompanies = function (value, searchMode) {
      //console.log('findCOmanies: ', value, searchMode);
      $scope.hits = [];
      $scope.userCountryCompanies = [];
      $scope.otherCountriesCompanies = [];
      if (value) {
        if (value.length < 3) return
        if ($rootScope.currentShop) {
          //console.log('Hello findCompanies: ', value, searchMode);
          var body = {
            searchText: value,
            searchMode: searchMode,
            shopId: $rootScope.currentShop._id,
            type: ''
          }
          $api.get('webshop-companies-search-by-suggestion', body)
            .then(function (response) {
              if (response && response.data && response.data.data) {
                sortByCountry(response.data.data);
              }
              // console.log('ebshop-companies-search-by-suggestio: ', response.data.data);
              // $scope.hits = response.data.data
            })
            .catch(function (reason) {
              console.error(reason)
            })
        }
      }
    }


    /**
     * Here, we are, The country of the user language should be automatically sorted on top of the search results!
     *  Suppliers in other countries should only be shown after clicking on the 'Load more search results' button
     */
    function sortByCountry(companies) {
      var companiesLength = companies.length;
      var currentLanguage = ($rootScope.language ? $rootScope.language.toUpperCase() : undefined) || 'NL';
      setCountryName(currentLanguage);
      for (var i = 0; i < companiesLength; i++) {
        if (companies[i] && companies[i].address && companies[i].address.country === currentLanguage) {
          $scope.userCountryCompanies.push(companies[i]);
        } else {
          $scope.otherCountriesCompanies.push(companies[i]);
        }
      }
      //console.log('sortByCountry: ', $scope.userCountryCompanies, $scope.otherCountriesCompanies);
    }

    function setCountryName(lang) {
      if (lang === 'NL') {
        $scope.countryName = 'Netherlands';
      } else if (lang === 'ES') {
        $scope.countryName = 'Spain';
      }  else if (lang === 'FR') {
        $scope.countryName = 'France';
      }  else if (lang === 'DE') {
        $scope.countryName = 'Germany';
      }  else {
        $scope.countryName = 'English';
      }
    }

    if (searchValue && searchValue.length > 3) {
      try {
        $scope.searchValue = searchValue
        $scope.findCompanies(searchValue, $scope.searchMode)
      } catch (error) {
        console.log('error: ', error)
      }
    }
    $scope.closeModal = function () {
      $uibModalInstance.close($scope.wholesaler)
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    // Deprecated
    // $scope.findCompanies = function (value, searchMode) {
    // 	console.log('findCompanies: ', value, searchMode);
    // 	$scope.hits = [];

    // 	if (value) {
    // 		if (value.length < 3) return;
    // 		if ($rootScope.currentShop != undefined) {
    // 			$api.get('webshop-companies-search/' + $rootScope.currentShop._id + "/" + searchMode + "/" + value, { "private": true }, { reset: true })
    // 				.then(function (response) {
    // 					$scope.hits = response.data.companies;
    // 				})

    // 				.catch(function (reason) {
    // 					console.error(reason);
    // 				});
    // 		}
    // 	}
    // };

    $scope.addCompanyToShop = function (company) {
      console.log('--> addCompanyToShop: called')
      $uibModalInstance.dismiss('cancel')
      return $q(function (resolve, reject) {
        if (company.brands && company.brands.length > 0 && company.type == 'supplier') {
          var modalInstance = $uibModal.open({
            templateUrl: '../views/modal/retailer-add-company-brand-confirmation.html',
            controller: 'retailerAddCompanyBrandConfirmationModalController',
            size: 'lg',
            resolve: {
              data: function () {
                return company
              }
            }
          })

          modalInstance.result
            .then(function (response) {
              resolve(response)
            })

            .catch(function (reason) {
              reject(reason)
            })
        } else {
          var body = {
            shopId: $rootScope.currentShop._id,
            companyId: company._id,
            brands: company.brands
          }

          $api.post('shop-companies', body)
            .then(function (response) {
              $rootScope.$emit('updateCompanyList', 'done')
            })
            .catch(function (reason) { 
              console.error(reason)
            })
        }
      })
    }

    // When so companies found then user can create new company with that
    $scope.addNewCompany = function () {
      //console.log('addnew Company called')
      $scope.cancel()
      $rootScope.companyNameFromAddCompModal = $scope.searchValue
      $scope.searchValue = ''
      $state.go('retailer.add-company')
    }
  }])
