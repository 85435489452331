prismanoteApp.controller('searchCustomerModalController', ['$scope', '$uibModalInstance', '$data', '$customer', 'allowOpen', 'serviceApp', '$api', '$log', '$rootScope', '$filter', '$language',
  function ($scope, $uibModalInstance, $data, $customer, allowOpen, serviceApp, $api, $log, $rootScope, $filter, $language) {
    $scope.allowOpen = allowOpen
    $scope.customers = []
    $scope.hits = []
    $scope.searching = false
    $scope.searchedMainData = []

    $scope.customerName = function (customer) {
      return $customer.makeCustomerName(customer)
    }

    $scope.searchCustomers = function (value) {
      $scope.hits = []
      $scope.transactions = undefined
      $scope.searching = false
      if (value) {

        $api.post('customers/search', {
          search: value,
          shopId: $rootScope.currentShop._id
        })
          .then(function (response) {
            if(response && response.data.customers) {
              $scope.searching = false
              $scope.hits = response.data.customers
              $scope.searchedMainData = $scope.hits
            } else {
              $scope.searchedMainData = [];
            }
            
          })
          .catch(function (reason) {
            $log.error('Error while searching customers', reason)
            $scope.searching = true
          })
      }
    }

    $scope.showSearchBySerialNumber = function (){
      var shopArr = [
        '5a0af3d4f6bdc126443d159d',
        '5a0af3fbf6bdc126443d159f',
        '5a0af41ef6bdc126443d15a1',
        '5a0af444f6bdc126443d15a3',
        '5a0af52af6bdc126443d3eaf'
      ];
      if(shopArr.indexOf($rootScope.currentShop._id) > -1) return true;
      else return false;
    }

    $scope.searchBySerialNumber = function (value) {
      if(!value) return;
      $scope.hits = []
      $scope.transactions = undefined
      $scope.searching = false

      $api.post('customers/searchBySerialNumber', {
        search: value,
        shopId: $rootScope.currentShop._id
      })
      .then(function (response) {
        if(response && response.data.customers) {
          $scope.searching = false
          $scope.hits = response.data.customers
          $scope.searchedMainData = $scope.hits
        } else {
          $scope.searching = true;
        }
      })
      .catch(function (reason) {
        $log.error('Error while searching customers', reason)
      })
    }

    $scope.searchVal = function(val) {
      if(val.length == 0) {
        $scope.hits = [];
        $scope.searchqueryAngular = undefined
        $scope.searchedMainData = []
        $scope.searching = false
      }
    }

    $scope.searchCustomerDetail = function (value) {
      if (!value) $scope.hits = JSON.parse(JSON.stringify($scope.searchedMainData))
      $scope.hits = []
      $scope.searchedMainData.forEach(function (customer) {
        var invoicePostal = (customer.invoiceAddress && customer.invoiceAddress.postalCode ? customer.invoiceAddress.postalCode : '')
        var invoiceHouseNumber = (customer.invoiceAddress && customer.invoiceAddress.houseNumber ? customer.invoiceAddress.houseNumber : '')
        var invoiceHouseNumberSuffix = (customer.invoiceAddress && customer.invoiceAddress.houseNumberSuffix ? customer.invoiceAddress.houseNumberSuffix : '')
        var invoiceSearch = invoicePostal + ' ' + invoiceHouseNumber + invoiceHouseNumberSuffix

        var shippingPostal = (customer.shippingAddress && customer.shippingAddress.postalCode ? customer.shippingAddress.postalCode : '')
        var shippingHouseNumber = (customer.shippingAddress && customer.shippingAddress.houseNumber ? customer.shippingAddress.houseNumber : '')
        var shippingHouseNumberSuffix = (customer.shippingAddress && customer.shippingAddress.houseNumberSuffix ? customer.shippingAddress.houseNumberSuffix : '')
        var shippingSearch = shippingPostal + ' ' + shippingHouseNumber + shippingHouseNumberSuffix
        
        if (
          (customer.firstName && customer.firstName.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.lastName && customer.lastName.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.email && customer.email.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.searchZip1 && customer.searchZip1.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.searchZip2 && customer.searchZip2.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.searchAddress1 && customer.searchAddress1.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.searchAddress2 && customer.searchAddress2.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.invoiceAddress && customer.invoiceAddress.attn && customer.invoiceAddress.attn.firstName && customer.invoiceAddress.attn.firstName.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.invoiceAddress && customer.invoiceAddress.attn && customer.invoiceAddress.attn.lastNamePrefix && customer.invoiceAddress.attn.lastNamePrefix !== '' && customer.invoiceAddress.attn.lastNamePrefix.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.invoiceAddress && customer.invoiceAddress.attn && customer.invoiceAddress.attn.lastName && customer.invoiceAddress.attn.lastName.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.invoiceAddress && customer.invoiceAddress.street && customer.invoiceAddress.street.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.invoiceAddress && customer.invoiceAddress.postalCode && customer.invoiceAddress.postalCode.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.invoiceAddress && customer.invoiceAddress.city && customer.invoiceAddress.city.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.invoiceAddress && customer.invoiceAddress.country && customer.invoiceAddress.country.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.invoiceAddress && customer.invoiceAddress.state && customer.invoiceAddress.state.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.shippingAddress && customer.shippingAddress.attn && customer.shippingAddress.attn.firstName && customer.shippingAddress.attn.firstName.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.shippingAddress && customer.shippingAddress.attn && customer.shippingAddress.attn.lastNamePrefix && customer.shippingAddress.attn.lastNamePrefix.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.shippingAddress && customer.shippingAddress.attn && customer.shippingAddress.attn.lastName && customer.shippingAddress.attn.lastName.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.shippingAddress && customer.shippingAddress.street && customer.shippingAddress.street.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.shippingAddress && customer.shippingAddress.postalCode && customer.shippingAddress.postalCode.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.shippingAddress && customer.shippingAddress.city && customer.shippingAddress.city.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.shippingAddress && customer.shippingAddress.country && customer.shippingAddress.country.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.shippingAddress && customer.shippingAddress.state && customer.shippingAddress.state.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
          // (customer.clientId && customer.clientId.toString().indexOf(value.toString()) >= 0) ||
					(invoiceSearch.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(shippingSearch.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
          (customer.companyName && customer.companyName.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0)
        ) {
          $scope.hits.push(customer)
        }
      })
    }

    $scope.selectCustomer = function (customer, selected) {
      if(selected === true || serviceApp === false) {
        $scope.close(customer)
        return
      }
      $customer.getPayLaterTransactionForCustomer(customer._id)
        .then(function (transactions) {
          if(transactions.data.transactions && transactions.data.transactions.length > 0) {
            //Outstanding transactions found
            $scope.selectedCustomer = angular.copy(customer)
            $scope.hits = undefined
            $scope.transactions = angular.copy(transactions.data.transactions)
            for(var i = 0; i < $scope.transactions.length; i++) {
              for(var p = 0; p < $scope.transactions[i].receipt.payments.length; p++) {
                if($scope.transactions[i].receipt.payments[p].method === 'payLater') {
                  $scope.transactions[i].outstanding = $scope.transactions[i].receipt.payments[p].amount
                }
              }
            }
            $scope.transactions = $scope.transactions.filter(function (t) {
              var items = t.details.filter( function (d) {
                var total = 0
                if(d.total < 0) {
                  // total is negative, so do a different calculation for the rest amount
                  total = d.originalTotal + ( (d.total * -1) + (d.paidLaterAmount ? d.paidLaterAmount : 0))
                } else {
                  total = d.originalTotal - (d.total + (d.paidLaterAmount ? d.paidLaterAmount : 0))
                }
                total = (Math.round(total * 100) / 100).toFixed(2)
                if(d.total < 0 && total > 0) {
                  // The total of the detail is negative but the calculated total is positive, so convert that to negative again
                  total = total * -1
                }
                return parseFloat(total) !== 0 && parseFloat(total) !== 0.00
              })
              return items.length > 0
            })
            if($scope.transactions.length === 0) {
              $scope.close(customer)
            }
          } else {
            $scope.close(customer)
          }
        })
        .catch(function (reason){
          console.error('Error while fetching outstanding transactions', reason)
          $scope.close(customer)
        })
    }

    $scope.openCustomer = function (customer) {
      $customer.openCustomerModal(customer, false, serviceApp)
        .then(function (result) {
          $scope.close(result.customer)
        })
        .catch(function (reason) {
          console.error('Error when opening customer', reason)
        })
    }

    $scope.newCustomer = function () {
      $customer.openCustomerModal(null, true, serviceApp)
        .then(function (result) {
          if (result.action === 'open') {
            $customer.getCustomer(result.customer._id, false)
              .then(function (customer) {
                $scope.openCustomer(customer.customer)
              })
              .catch(function (reason) {
                console.error('Error while fetching customer', reason)
              })
          } else {
            $scope.close(result.customer)
          }
        })
        .catch(function (reason) {
          console.error('Error when creating new customer', reason)
        })
    }

    $scope.showInfo = function (customer) {
      var result = customer.email
      if (customer.phone) {
        if (customer.phone) {
          if (customer.phone.landLine) {
            result = result + ' | ' + customer.phone.landLine
          }
          if (customer.phone.mobilePhone) {
            result = result + ' | ' + customer.phone.mobilePhone
          }
        }
      }

      if (customer.invoiceAddress && Object.keys(customer.invoiceAddress).length > 2) {
        result = result + ' | ' + (customer.invoiceAddress.postalCode ? $filter('zipcode')(customer.invoiceAddress.postalCode) + ' | ' : '') + (customer.invoiceAddress.street ? customer.invoiceAddress.street : '') + ' ' + (customer.invoiceAddress.houseNumber ? customer.invoiceAddress.houseNumber : '') + (customer.invoiceAddress.houseNumberSuffix ? customer.invoiceAddress.houseNumberSuffix : '')
      } else if (customer.shippingAddress && Object.keys(customer.shippingAddress).length > 2) {
        result = result + ' | ' + (customer.shippingAddress.postalCode ? $filter('zipcode')(customer.shippingAddress.postalCode) + ' | ' : '') + (customer.shippingAddress.street ? customer.shippingAddress.street : '') + ' ' + (customer.shippingAddress.houseNumber ? customer.shippingAddress.houseNumber : '') + (customer.shippingAddress.houseNumberSuffix ? customer.invoiceAddress.houseNumberSuffix : '')
      }
      return result
    }

    $scope.selectTransaction = function (transaction) {
      var items = transaction.details.map(function (d) {
        var total = 0
        if(d.total < 0) {
          // total is negative, so do a different calculation for the rest amount
          total = d.originalTotal + ( (d.total * -1) + (d.paidLaterAmount ? d.paidLaterAmount : 0))
        } else {
          total = d.originalTotal - (d.total + (d.paidLaterAmount ? d.paidLaterAmount : 0))
        }

        total = (Math.round(total * 100) / 100).toFixed(2)
        if(d.total < 0 && total > 0) {
          // The total of the detail is negative but the calculated total is positive, so convert that to negative again
          total = total * -1
        }
        var extraTransaction = null
        var transactionId = transaction.parentTransactionId && transaction.parentTransactionId._id ? transaction.parentTransactionId._id : transaction._id
        if(d.type === 'repair' || d.type === 'special' || d.type === 'order') {
          transactionId = d.transaction && d.transaction._id ? d.transaction._id : d.transaction
          if(d.extraTransaction) {
            extraTransaction = d.extraTransaction
          } else {
            extraTransaction = transaction._id
          }
        }
        var parentTransactionDetailId = d.parentTransactionDetailId ? d.parentTransactionDetailId : d._id ? d._id : null

        return {
          repairPayment: d.type === 'repair',
          specialPayment: d.type === 'special' || d.type === 'order',
          restPayment: true,
          type: d.type,
          transaction: transactionId, // transactionId of the original transaction, this can be an repair/order
          extraTransaction: extraTransaction, // in case of a repair/order we need this _id also
          quantity: 1,
          price: parseFloat(total),
          total: parseFloat(total),
          priceVat: d.priceVat,
          name: $language.translate('REST_PAYMENT') + ' ' + d.name,
          productId: d.productId && d.productId._id ? d.productId._id : null,
          parentTransactionDetailId: parentTransactionDetailId,
          comment: d.comment ? d.comment : null,
          productCollection: d.productCollection ? d.productCollection: null
        }
      })

      items = items.filter(function (i) {
        return parseFloat(i.total) !== 0 && parseFloat(i.total) !== 0.00
      })

      if(!items || items.length === 0) {
        toastr.error($language.translate('THERE_ARE_NO_ITEMS_LEFT_TO_PAY'))
        return
      }

      var transId = transaction.parentTransactionId && transaction.parentTransactionId._id ? transaction.parentTransactionId._id : transaction._id

      $scope.close({
        customer: $scope.selectedCustomer,
        items: items,
        originalTransaction: transaction,
        transactionId: transId,
        transactionNumber: transaction.number,
        refund: false,
        checkout: true,
        payLater: true
      })
    }

    $scope.close = function (data) {
      $uibModalInstance.close(data)
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }
  }])
