prismanoteApp.controller('employeesRetailerController', ['$scope', '$rootScope', '$state', '$retailer', '$shop', '$api', '$employee', '$uibModal',
  function ($scope, $rootScope, $state, $retailer, $shop, $api, $employee, $uibModal) {
    // console.log('retailer employee controller called');
    $scope.localdata = {
      canShowEmployee: false,
      employeesAreLoaded:false
    }

    $scope.getShop = function () {
      $scope.checkOptions()
      $shop.getEmployeesOfShop($scope.currentShop._id).then(function (response) {
        console.log('response data: ', response.data)
        if (response.data && response.data.employeeDetail && response.data.employeeDetail.length) {
          $scope.employees = response.data.employeeDetail
          $scope.localdata.employeesAreLoaded = true
        }
        else {
          $scope.employees = []
          $scope.localdata.employeesAreLoaded = true
        }
      }).catch(function (error) {
        console.log('Internal Server Error while fetching the employees detail', error)
      })
    }

    $scope.$on('changeSelectedShop', function () {
      $scope.getShop()
    })

    $scope.addEmployee = function () {
      if ($scope.currentEmployee && $scope.currentEmployee.isEmployee && !$scope.currentEmployee.admin) return
      $state.go('retailer.addemployee')
    }

    // Deprecated
    $scope.openEmployeeModal = function (isAddEmployee, index) {
      $scope.employee = {}
      if (!isAddEmployee) $scope.employee = $scope.employees[index]

      if (!$rootScope.user) {
        return
      }

      if (!$rootScope.user.initials || !$rootScope.user.pincode) {
        $scope.alertdanger = true
        $scope.erromessage = 'Stel eerst uw eigen pin-code in / First enter your own pincode'
        return
      }

      $retailer.openEmployeeModal(angular.copy($scope.employee), isAddEmployee)
        .then(function (result) {
          if (result) {
            // console.log('result', result);
            if (result.type == 'added') {
              // Is email existed in differnt shop at time open modal
              console.log('data: ', result.data)
              if (result.data.isOpenModal) {
                console.log('open modal')
                var modalInstance = $uibModal.open({
                  templateUrl: '../views/modal/employee-confirm-initials-modal.html',
                  controller: 'employeeConfirmInitialsModalController',
                  size: 'lg',
                  resolve: {
                    employee: function () {
                      return result.data
                    }
                  }
                })

                modalInstance.result.then(function (res) {
                  console.log('after closed: res: ', res)
                }, function (reason) {
                  if (!reason) reason = 'dismissed'
                  console.log('error: ', reason)
                })
              } else {
                console.log('not open modal')
                $shop.getEmployeesOfShop($scope.currentShop._id).then(function (response) {
                  if (response.data && response.data.employeeDetail && response.data.employeeDetail.length) {
                    $scope.employees = response.data.employeeDetail
                    $scope.localdata.employeesAreLoaded = true
                  } else {
                    $scope.employees = []
                  }
                }).catch(function (error) {
                  console.log('Internal Server Error while fetching the employees detail')
                })
              }
            } else if (result.type == 'edited') {
              $scope.employees[index] = result.data
            }
          }
        })
        .catch(function (reason) {
          console.error('REASON', reason)
        })
    }

    $scope.inviteUser = function (index) {
      var body = {
        _id: $scope.employees[index]._id,
        employeeCanBeAFirstUser: $scope.employees[index].employeeCanBeAFirstUser,
        enabled: $scope.employees[index].employeeCanBeAFirstUser,
        shopName: $rootScope.currentShop.name,
        shopId: $rootScope.currentShop._id,
        language: $rootScope.language
      }
      $scope.alert = undefined
      console.log('inviteUser called: ', $scope.employees[index]);
      $api.put('shop/employee-invite', body)
        .then(function (response) {
          $scope.employees[index].enabled = $scope.employees[index].employeeCanBeAFirstUser
          var message = 'Access revoked from the employee';
          if (body.employeeCanBeAFirstUser) {
            message = 'Email sent on ' + $scope.employees[index].email;
          }
          $scope.alert = {
            type: 'success',
            msg: message
          }
          setTimeout(function () {
            $scope.alert = undefined
          }, 2000)
        })
        .catch(function (error) {
          console.log('error: ', error);
          var message = 'Internal Server Error';
          if (error && error.data && error.data.message) message = error.data.message;
          $scope.alert = {
            type: 'danger',
            msg: message
          }
        })
    }

    $scope.changeEmployeeLockingInAllDevice = function (isEmployeeLoginEnable) {
      if (isEmployeeLoginEnable) $rootScope.startIdleTimer()
      else $rootScope.stopIdleTimer()
      $employee.changeEmployeeLockingInAllDevice({ shopId: $rootScope.currentShop._id, isEmployeeLoginEnable: isEmployeeLoginEnable }).then(function (response) {
        $rootScope.currentShop.isEmployeeLoginEnable = isEmployeeLoginEnable
      }).catch(function (error) {
        console.log('error: ', error)
      })
    }

    $scope.checkOptions = function () {
      try {
        var isEnableEmployeeLogin = (localStorage.getItem('isEnableEmployeeLogin') == 'true')
        console.log('check 1', isEnableEmployeeLogin)
        if (isEnableEmployeeLogin) {
          $scope.istoggleEnableLockingThisDevice = false
          $scope.istoggleDisableLockingThisDevice = true
        } else {
          $scope.istoggleDisableLockingThisDevice = false
          $scope.istoggleEnableLockingThisDevice = true
        }

        var isEmployeeLoginEnableForAllDevice = $rootScope.currentShop.isEmployeeLoginEnable

        if (isEmployeeLoginEnableForAllDevice) {
          $scope.istoggleEnableLockingAllDevice = false
          $scope.istoggleDisableLockingAllDevice = true
        } else {
          $scope.istoggleEnableLockingAllDevice = true
          $scope.istoggleDisableLockingAllDevice = false
        }
      } catch (catcherr) {
        console.log('catherrr: ', catcherr)
      }
    }

    $scope.toggleDisableLockingAllDevice = function () {
      // console.log('toggleDisableLockingAllDevice 1');
      $employee.lockEmployee()
      localStorage.setItem('isEnableEmployeeLogin', false)
      $scope.changeEmployeeLockingInAllDevice(false)
      $scope.istoggleDisableLockingAllDevice = false
      $scope.istoggleEnableLockingAllDevice = true
      $scope.istoggleDisableLockingThisDevice = false
      $scope.istoggleEnableLockingThisDevice = true
    }

    $scope.toggleEnableLockingAllDevice = function () {
      // console.log('toggleEnableLockingAllDevice 2');
      $rootScope.checkEmployee()
      localStorage.setItem('isEnableEmployeeLogin', true)
      $scope.changeEmployeeLockingInAllDevice(true)
      $scope.istoggleDisableLockingAllDevice = true
      $scope.istoggleEnableLockingAllDevice = false
      $scope.istoggleDisableLockingThisDevice = true
      $scope.istoggleEnableLockingThisDevice = false
    }

    $scope.toggleDisableLockingThisDevice = function () {
      console.log('toggleDisableLockingThisDevice 3')
      $employee.setSession($rootScope.currentEmployee, false).then(function (response) {
        $employee.lockEmployee()
        localStorage.setItem('isEnableEmployeeLogin', false)
        $scope.istoggleDisableLockingAllDevice = true
        $scope.istoggleEnableLockingAllDevice = true
        $scope.istoggleDisableLockingThisDevice = false
        $scope.istoggleEnableLockingThisDevice = true
      }).catch(function (error) {
        console.log('error: ', error)
      })
    }

    $scope.toggleEnableLockingThisDevice = function () {
      // console.log('toggleEnableLockingThisDevice 4');
      $rootScope.checkEmployee()
      localStorage.setItem('isEnableEmployeeLogin', true)
      $scope.istoggleDisableLockingAllDevice = true
      $scope.istoggleEnableLockingAllDevice = true
      $scope.istoggleDisableLockingThisDevice = true
      $scope.istoggleEnableLockingThisDevice = false
    }

    $scope.editProfilePage = function (employee) {
      // console.log(employee, $rootScope.currentEmployee);
      if ($scope.isEditableProfile(employee)) $state.go('retailer.myuser', { userId: employee._id })
    }

    $scope.isEditableProfile = function (employee) {
      var isEditable = false
      if ((employee && employee._id && $rootScope.currentEmployee && $rootScope.currentEmployee._id && (employee._id).toString() === ($rootScope.currentEmployee._id).toString()) ||
                !$rootScope.currentEmployee || !$rootScope.currentEmployee.isEmployee || $rootScope.currentEmployee.admin) {
        isEditable = true
      }
      return isEditable
      // $state.go('retailer.myuser', { userId: employee._id });
    }

    $scope.deleteRedoEmployee = function (isDeleted, employee, index) {
      console.log(employee, $scope.employees, index);
      var body = { userId: employee._id, isDeleted: isDeleted, shopId: $rootScope.currentShop._id }
      $employee.deleteRedoEmployee(body).then(function (response) {
        console.log('Employee deleted: ', response);
        $scope.employees[index].isDeleted = isDeleted;  
        $employee.removeEmployeeFromLocalStorage($scope.employees[index])
      }).catch(function (error) {
        console.error('deleteEmployee error: ', error);
      });
    }

    $scope.showDeletedEmployees = function (canShowEmployee) {
      $scope.localdata.canShowEmployee = canShowEmployee;
    }

    function checkAllFunction () {
      $scope.toggleDisableLockingAllDevice()
      $scope.toggleEnableLockingAllDevice()
      $scope.toggleEnableLockingThisDevice()
      $scope.toggleDisableLockingThisDevice()
    }

    $scope.disbaledTheAutoLock = function () {
      console.log('DISABLING: ')
      try {
        // if ($rootScope.currentEmployee) {
        console.log('disbaledTheAutoLock', $rootScope.currentEmployee)
        $employee.setSession($rootScope.currentEmployee, false).then(function (response) {
          console.log('INSIDE COME HERE')
          $employee.lockEmployee()
          if ($rootScope.currentEmployee) $rootScope.currentEmployee = null
          localStorage.setItem('isEnableEmployeeLogin', false)

          $api.post('logout', { id: $rootScope.user._id }).then(function () {
            console.log('logout successfully')
            $scope.alert = null
            $rootScope.user = null
            $rootScope.openChats = []
            $rootScope.currentEmployee = undefined
            localStorage.removeItem('currentShop')
            localStorage.removeItem('currentShopName');
            localStorage.removeItem('isEnableEmployeeLogin')
            localStorage.removeItem('lastLoggedInEmployees')
            $state.reload();
            $state.go('layout.login')
          }).catch(function (reason) {
            console.log(reason)
          })
        }).catch(function (error) {
          console.log('somethng went wrong while disabling autolock: ', error)
        })
      } catch (catherr) {
        console.log('somethng went wrong while disabling autolock: ', catherr)
      }
    }

    // Here, when they click on employee we are redirecting to the employee unlock modal and focus that particular employee
    $scope.changeEmployee = function (employee) {
      // console.log('employee: ', employee);
      $rootScope.focusEmployeeId = employee._id
      localStorage.setItem('isEnableEmployeeLogin', true)
      $rootScope.stopIdleTimer()
      localStorage.removeItem('currentEmployee')
      $employee.openUnlockModal()
    }

    // If mutiple super admin is there then one super admin can not change other super admin
    $scope.hasAccessToChangeAdmin = function (employee) {
      // console.log('hasAccessToChangeAdmin called: ', employee, $rootScope.currentEmployee, $rootScope.user);
      var hasAccess = true

      // if ($rootScope.currentEmployee && $rootScope.currentEmployee.isEmployee) return hasAccess = false;

      if (employee && employee._id) {
        if ($rootScope.currentEmployee && !employee.isEmployee && $rootScope.currentEmployee._id &&
                    ($rootScope.currentEmployee._id).toString() !== (employee._id).toString()) {
          hasAccess = false
        }

        if (!$rootScope.currentEmployee && $rootScope.user && $rootScope.user._id && !employee.isEmployee &&
                    ($rootScope.user._id).toString() !== (employee._id).toString()) {
          hasAccess = false
        }
      }

      return hasAccess
    }

    $scope.inviteUserTooTip = function (employee) {
      var message = ''
      if (!employee.email) message = 'Add an emailadres and invite this user to be able to log in via PrismaNote.com. Emailadres won’t be showed to other users'
      return message ? '<html> <body> <p> ' + message + '</p> </body> </html>' : ''
    }
  }])
