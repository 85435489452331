prismanoteApp.controller('brandNotificationController', ['$scope', '$rootScope', '$state', '$brand', '$company', '$stateParams', '$uibModal', '$language', '$notification', '$sce',
  function ($scope, $rootScope, $state, $brand, $company, $stateParams, $uibModal, $language, $notification, $sce) {
    $rootScope.pageTitle = 'PrismaNote Brand Portal | ' + $language.translate('NOTIFICATIONS')

    $scope.notificationTime = function (time) {
      return moment(time).fromNow()
    }

    $scope.unreadCount = function () {
      var unread = 0
      if ($rootScope.currentCompany.notifications && $rootScope.currentCompany.notifications.length > 0) {
        var notifications = $rootScope.currentCompany.notifications.filter(function (n) {
          return !n.read
        })
        unread = notifications.length
      }
      $rootScope.unreadNotifications = unread

      $rootScope.changeTitle()
    }

    $scope.delete = function (id) {
      var index = _.findIndex($rootScope.currentCompany.notifications, { _id: id })
      if (index >= 0) {
        $rootScope.currentCompany.notifications.splice(index, 1)
      }
      $notification.deleteNotificationForCompany(id)
      $scope.unreadCount()
    }

    $scope.deleteAll = function () {
      $rootScope.currentCompany.notifications = []
      $notification.deleteAllNotificationsForCompany()
      $scope.unreadCount()
    }

    $scope.markAsRead = function (id) {
      var index = _.findIndex($rootScope.currentCompany.notifications, { _id: id })
      if (index >= 0) {
        $rootScope.currentCompany.notifications[index].read = true
      }
      $notification.updateNotificationStatusForCompany(id)
      $scope.unreadCount()
    }

    $scope.notificationText = function (message) {
      return $sce.trustAsHtml(message)
    }

    function parseParams (params) {
      if (typeof params !== 'object') {
        return parseParams(JSON.parse(params))
      } else {
        return params
      }
    }

    $scope.openNotification = function (message) {
      if (message.data && message.data.state) {
        if (message.data.params) {
          $state.go(message.data.state, parseParams(message.data.params))
        } else {
          $state.go(message.data.state)
        }
      }
    }
  }])
