prismanoteApp.controller('retailerTransactionImportController', ['$scope', '$rootScope', '$api', 'Upload', '$q', '$timeout', '$retailer',
  function ($scope, $rootScope, $api, Upload, $q, $timeout, $retailer) {
    $scope.currentShop = $rootScope.currentShop
    $scope.options = {
      email: $rootScope.user.email,
      language: $rootScope.language.toUpperCase(),
      shopId: $scope.currentShop._id
    }

    $scope.importTransaction = function () {
      $scope.alert = null
      if(!$scope.options.email || $scope.options.email === '') {
        $scope.alert = {
          type: 'danger',
          msg: 'Waar is dat veld e-mail voor denk je?'
        }
        return
      }
      if(!$scope.options.language || $scope.options.language === '') {
        $scope.alert = {
          type: 'danger',
          msg: 'Выберите язык'
        }
        return
      }
      uploadFile({
        importFile: $scope.importFile,
        email: $scope.options.email,
        language: $scope.options.language
      },
      'api/import/transactions')
        .then(function (response) {
          $scope.alert = {
            type: 'success',
            msg: response.data.message
          }
        })
        .catch(function (reason) {
          console.error('Import error', reason)
          $scope.alert = {
            type: 'danger',
            msg: 'Fout: ' + reason.status + ' : ' + reason.data.message
          }
        })
    }

    $scope.closeAlert = function () {
      $scope.alert = null
    }

    function uploadFile (data, url) {
      var params = {
        url: url,
        data: {
          file: data.importFile,
          currentShopId: $rootScope.currentShop._id,
          email: data.email,
          language: data.language
        }
      }
      return $q(function (resolve, reject) {
        Upload.upload(params)
          .then(function (res) {
            $timeout(function () {
              resolve(res)
            })
          }, function (res) {
            reject(res)
          }, function (evt) {
          })
      })
    }
  }])
