prismanoteApp.controller('selectPrinterModalController', ['$scope', '$uibModalInstance', '$rootScope', 'data', '$jspm', '$language', '$device', '$log', '$printnode',
  function ($scope, $uibModalInstance, $rootScope, data, $jspm, $language, $device, $log, $printnode) {
    $scope.otherDeviceTranslation = $language.translate('USE_OTHER_DEVICE')
    $scope.jspmLoading = false
    $scope.devicePrinter = false
    $scope.deviceLoading = false
    $scope.devices = []
    $scope.devicePrintersLoading = false
    $scope.devicePrinters = []
    if($rootScope.currentShop) {
      $scope.jspm = !$rootScope.currentShop.printNode || $rootScope.currentShop.printNode.enabled === false
      $scope.company = false
    } else {
      $scope.jspm = !$rootScope.currentCompany.printNode || $rootScope.currentCompany.printNode.enabled === false
      $scope.company = true
    }

    if(data) {
      $scope.data = data
      if($scope.jspm === false) {
        $scope.data.selectedPrinter = data.printerId
      }

    } else {
      $scope.data = {
        selectedPrinter: '',
        selectedDevice: null,
        devicePrinter: '',
        selectedPrintNodeDevice: null,
      }
    }

    $scope.getPrinters = function () {
      $scope.alert = null
      $scope.printers = []
      if($rootScope.currentDevice.type === 'MOBILE') {
        $scope.printers.push({
          name: $scope.otherDeviceTranslation
        })
        $scope.data.selectedPrinter = $scope.otherDeviceTranslation
        $scope.printerSelected($scope.data.selectedPrinter)
      } else {
        $scope.jspmLoading = true
        $jspm.getInstalledPrinters(true)
          .then(function (printers) {
            $scope.printers = printers

            if ($rootScope.currentDevice.printServer === false) {
              $scope.printers.push({
                name: $scope.otherDeviceTranslation,
              })
            }
            $scope.jspmLoading = false
          })
          .catch(function (reason) {
            $scope.alert = {
              type: 'danger',
              msg: $language.translate(reason)
            }
            $scope.jspmLoading = false
          })
      }
    }

    $scope.getPrintNodePrinters = function (id) {
      if(!id || id === '' || id === 0) {
        return
      }
      $printnode.getPrinters(id, null, $scope.company)
        .then( function (printers) {
          $scope.printNodePrinters = printers
          $scope.originalPrintNodePrinters = printers
        })
        .catch( function (reason) {
          $log.error('Error getting printers', reason)
          toastr.error(reason)
        })
    }

    $scope.printNodeDeviceChanged = function (deviceId) {
      $scope.getPrintNodePrinters(deviceId)
    }

    $scope.getPrintNodeDevices = function (initial) {
      $printnode.getConnectedDevices($scope.company)
        .then(function (devices) {
          $scope.printNodeDevices = devices
          var id
          if(initial && $scope.data.deviceId) {
            id = $scope.data.deviceId
          } else {
            id = $scope.printNodeDevices[0].id
          }
          $scope.data.selectedPrintNodeDevice = id
          $scope.printNodeDeviceChanged(id)
        })
        .catch(function (reason) {
          $log.error('Error while getting PrintNode connected devices', reason)
          toastr.error(reason.data.message)
        })
    }

    //Condition to check what to load (runs on modal open)
    if($scope.jspm) {
      $scope.getPrinters()
    } else {
      $scope.getPrintNodeDevices(true)
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    $scope.getNameForValue = function(device, value) {
      var idx = -1

      idx = _.findIndex(device ? $scope.printNodeDevices : $scope.originalPrintNodePrinters, { id: value })

      if(idx >= 0) {
        if(device) {
          return $scope.printNodeDevices[idx].name
        } else {
          return $scope.originalPrintNodePrinters[idx].name
        }
      } else {
        return null
      }
    }

    $scope.save = function () {
      if($scope.jspm) {
        if ((!$scope.data.selectedPrinter || $scope.data.selectedPrinter === '') || ($scope.data.selectedDevice && (!$scope.data.devicePrinter || $scope.data.devicePrinter === ''))) {
          $scope.alert = {
            type: 'danger',
            msg: $language.translate('YOU_MUST_SELECT_AN_PRINTER')
          }
          return
        }
        if (($scope.data.selectedPrinter === $scope.otherDeviceTranslation) && (!$scope.data.selectedDevice || $scope.data.selectedDevice === '')) {
          $scope.alert = {
            type: 'danger',
            msg: $language.translate('YOU_MUST_SELECT_AN_DEVICE')
          }
          return
        }
        if($scope.data.selectedDevice && $scope.data.selectedDevice._id) {
          $scope.data.selectedDevice = $scope.data.selectedDevice._id
        }
        $uibModalInstance.close($scope.data)
      } else {
        if (!$scope.data.selectedPrintNodeDevice) {
          $scope.alert = {
            type: 'danger',
            msg: $language.translate('YOU_MUST_SELECT_AN_DEVICE')
          }
          return
        }
        if (!$scope.data.selectedPrinter) {
          $scope.alert = {
            type: 'danger',
            msg: $language.translate('YOU_MUST_SELECT_AN_PRINTER')
          }
          return
        }
      }

      $scope.data.printNode = {
        deviceId: $scope.data.selectedPrintNodeDevice,
        deviceName: $scope.getNameForValue(true, $scope.data.selectedPrintNodeDevice),
        printerId: $scope.data.selectedPrinter,
        printerName: $scope.getNameForValue(false, $scope.data.selectedPrinter)
      }
      $uibModalInstance.close($scope.data)
    }

    $scope.printerSelected = function (printerName) {
      if(printerName === $scope.otherDeviceTranslation) {
        $scope.devicePrinter = true

        $device.getDevices($rootScope.currentShop._id, null)
          .then(function (devices) {
            var printingDevices = devices.filter(function (d) {
              return d.type === 'PC' && d.printServer === true
            })
            $scope.devices = printingDevices
          })
          .catch(function (reason){
            $log.error('Error getting devices', reason)
            $scope.alert = {
              type: 'danger',
              msg: $language.translate(reason)
            }
          })
      } else {
        $scope.devicePrinter = false
        $scope.devices = []
      }
    }

    $scope.deviceSelected = function (device) {
      $scope.alert = null
      $scope.devicePrinters = []
      if(device && device.localIp) {
        $scope.devicePrintersLoading = true
        $jspm.getInstalledPrinters(true, device.localIp)
          .then(function (devicePrinters) {
            $scope.devicePrinters = devicePrinters
            $scope.devicePrintersLoading = false
          })
          .catch(function (reason) {
            $log.error('Error while fetching printers for device', reason)
            $scope.alert = {
              type: 'danger',
              msg: $language.translate(reason)
            }
            $scope.devicePrintersLoading = false
          })
      } else {
        $scope.alert = {
          type: 'danger',
          msg: $language.translate('SELECTED_DEVICE_IS_NOT_VALID')
        }
      }
    }
  }])
