prismanoteApp.controller('homeRegisterController', ['$scope', '$rootScope', '$api', '$location', '$language', '$state', '$auth', '$stateParams', '$user', '$general', 'prompt',
  function ($scope, $rootScope, $api, $location, $language, $state, $auth, $stateParams, $user, $general, prompt) {

    $scope.initialize = function () {
      $scope.registerDetails = {
        form: {},
        email: $stateParams.email ? $stateParams.email : null
      }
      $scope.activateDetails = {
        form: {}
      }
      $scope.formSubmitted = false
    }

    $scope.redirectUser = function (isNewUser) {
      var role = $rootScope.user.role == 'wholesaler' ? 'brand' : $rootScope.user.role
      if (isNewUser) {
        $state.go('retailer.registration')
      } else {
        $state.go(role + '.home')
      }
    }

    if ($rootScope.user) {
      $scope.redirectUser()
    } else {
      $scope.initialize()
      if ($stateParams.email) {
        document.getElementById('pnPassword1').focus()
      }
    }

    $scope.togglePassword = function () {
      $scope.togglePassword = function () {
        var e1 = document.getElementById('pnPassword1')
        var e2 = document.getElementById('pnPassword2')
        if (!e1 || !e2) {
          return
        }
        if (e1.type === 'password') {
          e1.type = 'text'
          e2.type = 'text'
          $('.fa-eye').show()
          $('.fa-eye-slash').hide()
        } else {
          e1.type = 'password'
          e2.type = 'password'
          $('.fa-eye').hide()
          $('.fa-eye-slash').show()
        }
      }
    }

    $scope.register = function () {
      var response = grecaptcha.getResponse();
      if (!response || !response.length) {
        prompt({
          title: 'Warning',
          message: 'Please complete the captcha challenge'
        })
        return;
      }
      var body = {
        userRole: 'retailer',
        extraData: {
          shop: $scope.registerDetails.shop
        },
        user: {
          password: $scope.registerDetails.password,
          passwordCompare: $scope.registerDetails.password2,
          email: $scope.registerDetails.email.toLowerCase()
        },
        language: $rootScope.language,
        searchlink: $stateParams.searchlink
      }

      $api.post('register', body)
        .then(function (response) {
          $scope.formSubmitted = true
          $scope.alert = {
            type: 'success',
            msg: $language.translate(response.data.message)
          }
        })
        .catch(function (reason) {
          console.error(reason)
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(reason.data ? reason.data.message : reason)
          }
        })
    }

    $scope.registerOLD = function () {
      $scope.alert = null
      $scope.formSubmitted = false
      if ($scope.registerDetails.form.$invalid) {
        return
      }

      if (!$scope.pwdNormal() || !$scope.pwdCapital() || !$scope.pwdSpecial() || !$scope.pwdNumber() || !$scope.pwdLength()) {
        $scope.alert = {
          type: 'danger',
          msg: $language.translate('PASSWORD_DOES_NOT_MEET_REQUIREMENTS')
        }
        return
      }
      if ($scope.registerDetails.password != $scope.registerDetails.password2) {
        $scope.alert = {
          type: 'danger',
          msg: $language.translate('MESSAGE_NOTMATCHINGPASSWORD_REGISTER_FORM')
        }
        return
      }

      if ($rootScope.purchaseOrderNumberFromEmailLink) {
        registerUserWhenComingFromPurchaseOrderInvitationLink() // If its coming from the invitation link (Purchase order)
      } else if ($rootScope.isFromRecommendationLink) {
        // $state.go('retailer.recommendation-order', { orderNo: '' });
        registerUserWhenComingFromRecommendationInvitationLink();
      } else if ($rootScope.campaignIdFromEmailLink) {
        registerUserWhenComingFromCampaignInvitationLink() // If its coming from the invitation link (Campaign)
      } else {
        var body = {
          userRole: 'retailer',
          extraData: {
            shop: $scope.registerDetails.shop
          },
          user: {
            password: $scope.registerDetails.password,
            passwordCompare: $scope.registerDetails.password2,
            email: $scope.registerDetails.email.toLowerCase()
          },
          language: $rootScope.language
        }

        console.log('BODY: ', body);
        console.log('SIMPLE LOGIN');
        $api.post('register', body)
          .then(function (response) {
            $scope.formSubmitted = true
            $scope.alert = {
              type: 'success',
              msg: $language.translate(response.data.message)
            }
          })
          .catch(function (reason) {
            console.error(reason)
            $scope.alert = {
              type: 'danger',
              msg: $language.translate(reason.data ? reason.data.message : reason)
            }
          })
      }
    }

    // When LoggedIn with the invitaiton link (Purchaser order)
    function registerUserWhenComingFromPurchaseOrderInvitationLink() {
      var body = {
        user: {
          password: $scope.registerDetails.password,
          passwordCompare: $scope.registerDetails.password2,
          email: $scope.registerDetails.email.toLowerCase(),
          role: 'retailer'
        },
        extraData: {
          shop: $scope.registerDetails.shop
        },
        language: $rootScope.language || 'en',
        invitationLink: {
          isFromPurchaseOrder: true,
          purchaseOrderNumber: $rootScope.purchaseOrderNumberFromEmailLink,
          email: $scope.registerDetails.email.toLowerCase()
        }
      }

      $user.registerUserWhenComingFromPurchaseOrderInvitationLink(body).then(function (userres) {
        $rootScope.user = userres.data
        localStorage.setItem('currentUserName', $rootScope.user.firstName + ($rootScope.user.lastNamePrefix ? ' ' + $rootScope.user.lastNamePrefix : '') + ' ' + $rootScope.user.lastName)
        $scope.formSubmitted = true
        $scope.alert = {
          type: 'success',
          msg: $language.translate(userres.data.message)
        }
        $state.go('retailer.purchase-order', { orderNo: $rootScope.purchaseOrderNumberFromEmailLink })
      }).catch(function (usererr) {
        console.error(usererr)
        if (usererr.status === 409) {
          $scope.alert = {
            type: 'success',
            msg: 'You are already registered with the given email. Reset your password'
          }
          setTimeout(function () {
            $state.go('layout.request-password')
          }, 3000)
        } else {
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(usererr.data ? usererr.data.message : 'INTERNAL_SERVER_ERROR')
          }
        }
      })
    }

    // When LoggedIn with the invitaiton link (Campaign)
    function registerUserWhenComingFromCampaignInvitationLink() {
      var body = {
        user: {
          password: $scope.registerDetails.password,
          passwordCompare: $scope.registerDetails.password2,
          email: $scope.registerDetails.email.toLowerCase(),
          role: 'retailer'
        },
        extraData: {
          shop: $scope.registerDetails.shop
        },
        language: $rootScope.language || 'en',
        invitationLink: {
          isFromCampaign: true,
          campaignIdFromEmailLink: $rootScope.campaignIdFromEmailLink,
          email: $rootScope.invitationEmail.toLowerCase()
        }
      }

      $user.registerUserWhenComingFromCampaignInvitationLink(body).then(function (userres) {
        console.log('Hello USERES: ', userres, $rootScope.campaignIdFromEmailLink, userres)
        $rootScope.user = userres.data
        localStorage.setItem('currentUserName', $rootScope.user.firstName + ($rootScope.user.lastNamePrefix ? ' ' + $rootScope.user.lastNamePrefix : '') + ' ' + $rootScope.user.lastName)
        $scope.formSubmitted = true
        $scope.alert = {
          type: 'success',
          msg: $language.translate(userres.data.message)
        }
        $state.go('retailer.paidcampaign', { isCampaignStep: 'join', campaignId: $rootScope.campaignIdFromEmailLink })
      }).catch(function (usererr) {
        console.error(usererr)
        if (usererr.status === 409) {
          $scope.alert = {
            type: 'success',
            msg: 'You are already registered with the given email. Reset your password'
          }
          setTimeout(function () {
            $state.go('layout.request-password')
          }, 3000)
        } else {
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(usererr.data && usererr.data.message ? usererr.data.message : 'INTERNAL_SERVER_ERROR')
          }
        }
      })
    }

    // When LoggedIn with the Recommendation link (Invite Web shop - Recommendation)
    function registerUserWhenComingFromRecommendationInvitationLink() {
      var body = {
        user: {
          password: $scope.registerDetails.password,
          passwordCompare: $scope.registerDetails.password2,
          email: $scope.registerDetails.email.toLowerCase(),
          role: 'retailer'
        },
        extraData: {
          shop: $scope.registerDetails.shop
        },
        language: $rootScope.language || 'en',
        invitationLink: {
          isFromRecommendationLink: true,
          shopId: $rootScope.recommendationShopId,
          companyId: $rootScope.recommendationCompanyId,
          email: $rootScope.recommendationEmail.toLowerCase()
        }
      }

      console.log('SEND BODY: ', body);
      $user.registerUserWhenComingFromRecommendationInvitationLink(body).then(function (userres) {
        $rootScope.isFromRecommendationLink = undefined;
        console.log('Hello USERES: ', userres);
        if (userres.status === 207) {
          $scope.formSubmitted = true
          $scope.alert = {
            type: 'success',
            msg: $language.translate(userres.message)
          }
        } else {
          $rootScope.user = userres.data
          localStorage.setItem('currentUserName', $rootScope.user.firstName + ($rootScope.user.lastNamePrefix ? ' ' + $rootScope.user.lastNamePrefix : '') + ' ' + $rootScope.user.lastName)
          $scope.formSubmitted = true
          $scope.alert = {
            type: 'success',
            msg: $language.translate(userres.data.message)
          }
          // $state.go('retailer.home');
          $state.go('retailer.recommendation-order', { orderNo: '' });
        }
      }).catch(function (usererr) {
        console.error(usererr)
        if (usererr.status === 409) {
          $scope.alert = {
            type: 'success',
            msg: 'You are already registered with the given email. Reset your password'
          }
          setTimeout(function () {
            $state.go('layout.request-password')
          }, 3000)
        } else {
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(usererr.data && usererr.data.message ? usererr.data.message : 'INTERNAL_SERVER_ERROR')
          }
        }
      })
    }

    $scope.pwdNormal = function () {
      if (!$scope.registerDetails.password) return
      return $scope.registerDetails.password.match(/[a-z]/m)
    }
    $scope.pwdCapital = function () {
      if (!$scope.registerDetails.password) return
      return $scope.registerDetails.password.match(/[A-Z]/m)
    }
    $scope.pwdSpecial = function () {
      if (!$scope.registerDetails.password) return
      return $scope.registerDetails.password.match(/[^a-zA-Z0-9]/gm)
    }
    $scope.pwdNumber = function () {
      if (!$scope.registerDetails.password) return
      return $scope.registerDetails.password.match(/\d/gm)
    }
    $scope.pwdLength = function () {
      if (!$scope.registerDetails.password) return
      return $scope.registerDetails.password.length > 7
    }

    $scope.activateAccount = function () {
      console.log('activateAccount', $scope.activateDetails.form)
      if ($scope.activateDetails.form.$invalid) {
        return
      }

      console.log('$location.search()', $location.search())
      if ($location.search() && $location.search().shopname) {
        console.log('-----------------------Shop name available -----------------');
        $rootScope.isAPIAlreadyCalled = true
        var shopName = $location.search().shopname

        var body = {
          // userId: $stateParams.id,
          code: $stateParams.code,
          shopname: shopName,
          user: {
            firstName: $scope.activateDetails.firstName,
            lastNamePrefix: $scope.activateDetails.lastNamePrefix,
            lastName: $scope.activateDetails.lastName,
            sex: $scope.activateDetails.sex
          }
        }

        $auth.isEmailExistThenLoggedIn(body).then(function (response) {
          console.log('response: ', response);
          $rootScope.user = response.data
          localStorage.setItem('currentUserName', $rootScope.user.firstName + ($rootScope.user.lastNamePrefix ? ' ' + $rootScope.user.lastNamePrefix : '') + ' ' + $rootScope.user.lastName)
          if ($stateParams.searchlink) {
            fetchWelcomeInvitationData();
          } else {
            $scope.redirectUser();
          }
        }).catch(function (reason) {
          $scope.alert = {
            type: 'danger',
            msg: reason
          }
        })
      } else if (!$rootScope.isAPIAlreadyCalled) {
        console.log('-----------------------Shop name not available -----------------');
        $api.post('activate-user', {
          // id: $stateParams.id,
          code: $stateParams.code,
          user: {
            firstName: $scope.activateDetails.firstName,
            lastNamePrefix: $scope.activateDetails.lastNamePrefix,
            lastName: $scope.activateDetails.lastName,
            sex: $scope.activateDetails.sex
          }
        }).then(function (response) {
          // console.log('SUCCESS!', response)
          console.log('Successfull verified: ', $stateParams.searchlink, $stateParams);
          $rootScope.user = response.data.user;
          localStorage.setItem('currentUserName', $rootScope.user.firstName + ($rootScope.user.lastNamePrefix ? ' ' + $rootScope.user.lastNamePrefix : '') + ' ' + $rootScope.user.lastName)
          if ($stateParams.searchlink) {
            fetchWelcomeInvitationData();
          } else {
            $scope.redirectUser(true);
          }
        }).catch(function (reason) {
          $scope.alert = { type: 'danger', msg: reason }
        })
      }
    }

    function fetchWelcomeInvitationData() {
      console.log('YOU R HERE FINALLY');
      var shop = { _id: $rootScope.user.shops[0]._id }
      var body = { searchlink: $stateParams.searchlink, isFromVerificationLink: true, selectedShopId: shop._id }
      $general.welcomeInvitationLink(body).then(function (response) {
        console.log('==========welcomeInvitationLink============>>>>>>> response: ', response);
        if (response && response.data && response.data.data) {
          $scope.linkdata = response.data.data;
          redirectBasedOnLink(shop._id);
        } else {
          $scope.redirectUser();
        }
      }).catch(function (error) {
        console.log('error:redirectBasedOnLink           6767 ', error);
        $scope.redirectUser();
      })
    }

    function redirectBasedOnLink(selectedShopId) {
      console.log('redirectBasedOnLink called: ', $scope.linkdata);
      if ($scope.linkdata && $scope.linkdata.isForRetailerRecommendation &&
        $scope.linkdata.companyDetail && $scope.linkdata.companyDetail._id) {
        $rootScope.recommendationCompany = $scope.linkdata.companyDetail;
        // $rootScope.recommendationCompanyId = $scope.linkdata.companyDetail._id;
        $state.go('retailer.recommendation-order', { orderNo: '' });
      } else if ($scope.linkdata.isForRetailerPurchaseOrder) {
        console.log('This is for the Purchase order: ', $scope.linkdata, selectedShopId);
        if ($scope.linkdata.shop._id === selectedShopId) {
          $state.go('retailer.purchase-order', { orderNo: $scope.linkdata.purchaseOrderNumber })
        } else {
          $rootScope.showAlertMsgOfPurchaseOrder = true
          $state.go('retailer.purchase-orders')
        }
      } else if ($scope.linkdata.isForRetailerCampaign && $scope.linkdata.campaignId) {
        $state.go('retailer.paidcampaign', { isCampaignStep: 'join', campaignId: $scope.linkdata.campaignId })
      } else {
        $scope.redirectUser();
      }
    }


    $scope.termsText = function () {
      var trans = {
        shop_terms: {
          url: $language.translate('SHOP_TERMS_URL'),
          text: $language.translate('JEWELLERS').toLowerCase()
        },
        brand_terms: {
          url: $language.translate('BRAND_TERMS_URL'),
          text: $language.translate('BRANDS').toLowerCase()
        },
        privacypolicy: {
          url: $language.translate('PRIVACY_POLICY_URL'),
          text: $language.translate('PRIVACY_POLICY').toLowerCase()
        },
        cookiepolicy: {
          url: $language.translate('COOKIE_POLICY_URL'),
          text: $language.translate('COOKIE_POLICY').toLowerCase()
        },
      }

      console.log('trans', trans)

      return $language.translate('REGISTER_TERMS', {
        shop_terms: '<a href="'+ trans.shop_terms.url +'">'+ trans.shop_terms.text +'</a> ',
        brand_terms: '<a href="'+ trans.brand_terms.url +'">'+ trans.brand_terms.text +'</a> ',
        privacypolicy: '<a href="'+ trans.privacypolicy.url +'">'+ trans.privacypolicy.text +'</a> ',
        cookiepolicy: '<a href="'+ trans.cookiepolicy.url +'">'+ trans.cookiepolicy.text +'</a> '
      })
    }


  }])
