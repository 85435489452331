prismanoteApp.controller('employeeConfirmInitialsModalController', ['$rootScope', '$scope', '$api', '$uibModalInstance', 'employee',
  function ($rootScope, $scope, $api, $uibModalInstance, employee) {
    console.log('employeeConfirmInitialsModalController called ', employee)
    $scope.employee = employee

    $scope.saveEmployee = function () {
      console.log('hello: ', $scope.employee)
      if ($scope.employee.pincode !== $scope.confirmPin) {
        $scope.alert = {
          type: 'danger',
          msg: 'Pin and confirm pin does not match'
        }
      } else {
        $scope.alert = null
        var body = {
          shopId: $rootScope.currentShop._id,
          _id: $scope.employee._id,
          initials: $scope.employee.initials,
          pincode: $scope.employee.pincode
        }
        console.log('saving yes: ', body)
        $api.put('shop/update-initials-pin', body).then(function (response) {
          console.log('response: ', response)
          $uibModalInstance.close()
        }).catch(function (error) {
          $scope.alert = {
            type: 'danger',
            msg: 'Something went wrong'
          }
        })
      }
    }

    $scope.dismiss = function () {
      $uibModalInstance.dismiss()
    }
  }
])
