prismanoteApp.controller('retailerExtraCustomerDetailsModalController', ['$scope', '$uibModalInstance', '$rootScope', 'data',
  function ($scope, $uibModalInstance, $rootScope, data) {
    if (data.helpedBy == null && $rootScope.user) $scope.helpedBy = $rootScope.user.firstName
    else $scope.helpedBy = data.helpedBy

    if (data.address) $scope.address = data.address

    if (data.dateOfBirth) $scope.dateOfBirth = data.dateOfBirth

    $scope.save = function () {
      var data = {
        dateOfBirth: $scope.dateOfBirth,
        helpedBy: $scope.helpedBy,
        address: $scope.address
      }
      $uibModalInstance.close(data)
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }
  }])
