prismanoteApp.controller('companyActivateUserController',
    ['$scope', '$rootScope', '$stateParams', '$user', '$language', '$state', '$companyInvitation',
        function ($scope, $rootScope, $stateParams, $user, $language, $state, $companyInvitation) {

            // When USER Click on the verification link from email
            if ($stateParams && $stateParams.hashedparam && $stateParams) {
                console.log('StateParams: ', $stateParams);
                $user.verifyActivationLink($stateParams).then(function (response) {
                    if (response && response.data && response.data.data) {
                        console.log('User verified successfully: ', response.data.data);
                        if (response.data.data.activationData) {
                            var activationData = response.data.data.activationData;
                            var isLoggedIn = response.data.data.isLoggedIn;
                            $scope.parsedData = activationData;
                            /* if (isLoggedIn) {
                                if (activationData.isEmailVerification === true || activationData.isEmailVerification === 'true') {
                                    // $scope.redirectUser();
                                } else if (activationData.isShopDetail === true || activationData.isShopDetail === 'true') {
                                    $state.go('brand.shop-detail', { nameSlug: activationData.shopSlug })
                                } else if (activationData.isPurchaseOrder === true || activationData.isPurchaseOrder === 'true') {
                                    $state.go('brand.purchase-order', { orderNo: activationData.purchaseOrderNumber })
                                } else {
                                    console.log('No valid data provided');
                                    $state.go('layout.login');
                                }
                            } else if (activationData) {
                                if (activationData.isEmailVerification === true || activationData.isEmailVerification === 'true') {
                                    console.log('Email verification link called');
                                } else if (activationData.isShopDetail === true || activationData.isShopDetail === 'true') {
                                    console.log('$rootScope.parsedData: 1');
                                    $rootScope.invitationEmail = activationData.email;
                                    $rootScope.parsedData = activationData;
                                    $state.go('layout.company-register');
                                } else if (activationData.isPurchaseOrder === true || activationData.isPurchaseOrder === 'true') {
                                    console.log('$rootScope.parsedData: 2');
                                    $rootScope.invitationEmail = activationData.email;
                                    $rootScope.parsedData = activationData;
                                    $state.go('layout.company-register');
                                } else {
                                    $state.go('layout.login');
                                }
                            } else {
                                console.log('$rootScope.parsedData: 3');
                                $rootScope.invitationEmail = activationData.email;
                                $rootScope.parsedData = activationData;
                                $state.go('layout.company-register');
                            } */
                        } else {
                            console.log('No valid data');
                            $state.go('layout.login');
                        }
                    } else {
                        console.log('No valid data 1');
                        $state.go('layout.login');
                    }
                }).catch(function (error) {
                    console.log('User is not verified: ', error);
                    $state.go('layout.login');
                });
            }

            $scope.redirectUser = function () {
                var role = $rootScope.user.role == 'wholesaler' ? 'brand' : $rootScope.user.role;
                $state.go(role + '.home');
            }

            $scope.activateAccount = function () {
                console.log('activateAccount', $scope.activateDetails.form)
                if ($scope.activateDetails.form.$invalid) return;
                console.log('$scope.parsedData called: ', $scope.parsedData);
                if ($scope.parsedData) {
                    if ($scope.parsedData.isEmailVerification === true || $scope.parsedData.isEmailVerification === 'true') {
                        if ($scope.parsedData.userId && $scope.parsedData.verificationCode) activateUser();
                        else console.log('No valid data of email verification'); $state.go('layout.login');
                    } else {
                        console.log('No valid data of email verification 3');
                        $state.go('layout.login');
                    }
                } else {
                    console.log('No valid data of email verification 1');
                    $state.go('layout.login');
                }
            }

            function activateUser() {
                var body = {
                    id: $scope.parsedData.userId,
                    code: $scope.parsedData.verificationCode,
                    user: {
                        firstName: $scope.activateDetails.firstName,
                        lastNamePrefix: $scope.activateDetails.lastNamePrefix,
                        lastName: $scope.activateDetails.lastName,
                        sex: $scope.activateDetails.sex
                    }
                }
                $user.activateUser(body).then(function (response) {
                    $rootScope.user = response.data.user;
                    console.log('response: ==>', response);
                    console.log('$scopre.parsed: ', $scope.parsedData);
                    if ($scope.parsedData.searchlink && $scope.parsedData.searchlink.shop && $scope.parsedData.searchlink.shop.nameSlug &&
                        $scope.parsedData.searchlink.shop._id) {
                        if ($scope.parsedData.searchlink.isForCompany_ShopStatistics) {
                            console.log('inside if');
                            addShopAndCompanyViceVersa();
                        } else if ($scope.parsedData.searchlink.isForCompanyPurchaseorder) {
                            console.log('inside else if');
                            addShopAndCompanyViceVersa();
                        } else {
                            console.log('inside else');
                            $scope.redirectUser();
                        }
                    } else {
                        console.log('inside else');
                        $scope.redirectUser();
                    }
                }).catch(function (error) {
                    $scope.alert = { type: 'danger', msg: reason }
                });
            }

            function addShopAndCompanyViceVersa() {
                console.log('its hree: ', $scope.parsedData.searchlink.shop, $rootScope.user.companies);
                var body = {
                    shop: $scope.parsedData.searchlink.shop,
                    company: $rootScope.user.companies[0],
                    isForCompanyPurchaseorder: $scope.parsedData.searchlink.isForCompanyPurchaseorder ? true : false,
                    purchaseOrderNumber: $scope.parsedData.searchlink.purchaseOrderNumber
                }
                console.log('body man', body);
                $companyInvitation.addShopAndCompanyViceVersa(body).then(function (response) {
                    console.log('its redirecting');
                    redirectBasedOnLink();
                }).catch(function (error) {
                    console.log('error: ', error);
                    $scope.redirectUser();
                });
            }

            function redirectBasedOnLink() {
                console.log('redirectBasedOnLink called: ', $scope.parsedData);
                if ($scope.parsedData.searchlink.isForCompany_ShopStatistics) {
                    $state.go('brand.shop-detail', { nameSlug: $scope.parsedData.searchlink.shop.nameSlug })
                } else if ($scope.parsedData.searchlink.isForCompanyPurchaseorder && $scope.parsedData.searchlink.purchaseOrderNumber) {
                    $state.go('brand.purchase-order', { orderNo: $scope.parsedData.searchlink.purchaseOrderNumber });
                } else {
                    $scope.redirectUser();
                }
            }
        }
    ])
