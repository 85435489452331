prismanoteApp.controller('purchaseOrderProductDetailsUpdateController', ['$scope', '$uibModalInstance', 'product',
  function ($scope, $uibModalInstance, product) {

    $scope.product = product;

    $scope.close = function (data) {
      $uibModalInstance.close(data)
    }
  }
])
