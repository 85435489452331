prismanoteApp.controller('retailerWebordersController', ['$scope', '$rootScope', '$api', '$retailer', '$state', '$language',
  function ($scope, $rootScope, $api, $retailer, $state, $language) {
    $scope.loading = true
    $scope.transactions = []

    // Pagination
    $scope.pageLimit = 10
    $scope.currentPage = 1
    $scope.totalRecords = 0
    $scope.pagePerLimitOptions = [
      {
        value: 10,
        text: '10'
      },
      {
        value: 25,
        text: '25'
      },
      {
        value: 50,
        text: '50'
      },
      {
        value: 100,
        text: '100'
      }
    ]

    // filter
    $scope.activeTransactionStatus = 'all'
    $scope.transactionsStatusOption = [
      {
        value: 'all',
        text: $language.translate('ALL_ITEMS')
      },
      {
        value: 'pending_payment',
        text: $language.translate('PENDING_PAYMENTS')
      },
      {
        value: 'new',
        text: $language.translate('NEW')
      },
      {
        value: 'canceled',
        text: $language.translate('CANCELLED')
      },
      {
        value: 'failed',
        text: $language.translate('FAILED')
      },
      {
        value: 'expired',
        text: $language.translate('EXPIRED')
      },
      {
        value: 'completed',
        text: $language.translate('COMPLETED')
      },
      {
        value: 'refund',
        text: $language.translate('REFUNDED')
      }
      /* {
				value: "delivered ",
				text: "Delivered "
			},
			{
				value: "return_request",
				text: "Return Request"
			},
			{
				value: "return",
				text: "Return"
			},
			{
				value: "return_denined ",
				text: "Return Denied"
			} */
    ]

    $scope.filter = {
      status: 'all',
      search: '',
      limit: $scope.pageLimit,
      skip: ($scope.currentPage - 1) * $scope.pageLimit
    }

    $scope.searchString = ''
    $scope.isCallForDetails = ''
    $scope.multiStoreDetails = [];

    $scope.shopList = $rootScope.user && $rootScope.user.shops ? $rootScope.user.shops : [];

    // Load all initial and filtered transactions
    $scope.loadAllTransactions = function (filter) {
      $scope.loading = true
      $scope.transactions = []
      $scope.getShopDetailsAndTransactionTotal(filter)
      $api.get('retailer/webshop-orders/' + $rootScope.currentShop._id, filter)
        .then(function (response) {
          if (response && response.data && response.data.result && response.data.result.transactions && response.data.result.transactions.length) {
            $scope.transactions = response.data.result.transactions
          }
          $scope.loading = false
        })
        .catch(function (reason) {
          console.error('Error while getting the webshop transactions list', reason)
          $scope.loading = false
        })
    }

    $scope.getShopDetailsAndTransactionTotal = function(filter){
      if($scope.isCallForDetails == '' || $scope.isCallForDetails == 'total'){
        $api.get('retailer/webshop-orders-count/' + $rootScope.currentShop._id, filter)
          .then(function (response) {
            if(response && response.data && response.data.count){
              $scope.totalRecords = response.data.count
            }
          })
          .catch(function (reason) {
            console.error('Error while getting the webshop transactions list', reason)
            $scope.loading = false
          })
      }
      if($scope.isCallForDetails == ''){
        $api.get('v2/shop/multi-store-details/' + $rootScope.currentShop._id)
          .then(function (response) {
            if(response && response.data && response.data.length > 0){
              $scope.multiStoreDetails = response.data[0];
            }
          })
          .catch(function (reason) {
            console.error('Error while getting the webshop transactions list', reason)
            $scope.loading = false
          })
      }
      $scope.isCallForDetails = 'all'
    }
    // Refresh transactions while user will click on refresh button
    $scope.refreshTransaction = function () {
      $scope.pageLimit = 10
      $scope.currentPage = 1
      $scope.activeTransactionStatus = 'all'
      $scope.isCallForDetails = 'total'

      $scope.filter = {
        status: 'all',
        search: '',
        limit: $scope.pageLimit,
        skip: ($scope.currentPage - 1) * $scope.pageLimit
      }

      $scope.loadAllTransactions(JSON.parse(JSON.stringify($scope.filter)))
    }

    // Filter transaction based on status
 		$scope.filterTransactionStatus = function (status) {
      $scope.activeTransactionStatus = status
      $scope.pageLimit = 10
      $scope.currentPage = 1
      $scope.isCallForDetails = 'total'

      $scope.filter = {
        status: status,
        search: '',
        limit: $scope.pageLimit,
        skip: ($scope.currentPage - 1) * $scope.pageLimit
      }

      $scope.loadAllTransactions(JSON.parse(JSON.stringify($scope.filter)))
    }

    // Filter transaction based on search some words
    $scope.searchTransaction = function (searchString) {
      $scope.pageLimit = 10
      $scope.currentPage = 1
      $scope.activeTransactionStatus = 'all'
      $scope.isCallForDetails = 'total'

      $scope.filter = {
        status: 'all',
        search: searchString,
        limit: $scope.pageLimit,
        skip: ($scope.currentPage - 1) * $scope.pageLimit
      }

      $scope.loadAllTransactions(JSON.parse(JSON.stringify($scope.filter)))
    }

    // Change page limit
    $scope.changePagelimit = function (limit) {
      $scope.filter.limit = limit
      $scope.filter.skip = 0

      $scope.pageLimit = limit
      $scope.currentPage = 1

      $scope.loadAllTransactions(JSON.parse(JSON.stringify($scope.filter)))
    }

    $scope.pageChanged = function (currentPage) {
      $scope.currentPage = currentPage
      $scope.filter.skip = ($scope.currentPage - 1) * $scope.pageLimit

      $scope.loadAllTransactions($scope.filter)
    }

    $scope.redirectToDetails = function (transactionId) {
      $state.go('retailer.orderDetails', { transactionId: transactionId })
    }

    $scope.$on('changeSelectedShop', function () {
      $retailer.getShop()
        .then(function (shop) {
          $rootScope.currentShop = shop
          $scope.pageLimit = 10
          $scope.currentPage = 1
          $scope.totalRecords = 0
          $scope.isCallForDetails = 'total'

          $scope.filter = {
            status: 'all',
            search: '',
            limit: $scope.pageLimit,
            skip: ($scope.currentPage - 1) * $scope.pageLimit
          }
          $scope.loadAllTransactions($scope.filter)
        })
    })

    $scope.getMultiStoreDetails = function(transaction, type){
      if (!$scope.multiStoreDetails || !$scope.multiStoreDetails.multiShops || !$scope.multiStoreDetails.filter) {
        return ''
      }
      var details = []
      if(type === 'repair'){
        details = $scope.multiStoreDetails.dropShippers.filter(function(detail){ return detail._id == transaction.repair});
      }else{
        details = $scope.multiStoreDetails.multiShops.filter(function(detail){ return detail._id == transaction.shopId});
      }
      return details.length > 0 && details[0].name ? details[0].name : '';
    }

    /* $scope.downloadPackageSlip = function (order) {
			//Dit doen we even met $htpp omdat de $api service vooralsnog geen responseType ondersteund.
			$http({
				method: 'POST',
				url: '/api/package-slip',
				data: {
					orderId: order._id
				},
				responseType: 'arraybuffer'
			})
				.then(function (response) {
					var file = new Blob([response.data], { type: 'application/pdf' });
					FileSaver.saveAs(file, "Order " + order.number + ".pdf");
				}, function (response) {
					console.error("Error while generating package slip", response);
				})
		}

		$scope.openReturnOrderModal = function (order) {
			var modalInstance = $uibModal.open({
				templateUrl: '../views/modal/return-order-modal.html',
				controller: 'returnOrder-modalController',
				resolve: {
					order: function () {
						return order;
					}
				}
			});

			modalInstance.result.then(function (result) {
				if (result) {
					//ready
				}
			}, function () {
				//dismissed
			})
		};

		$scope.openSendOrderModal = function (order) {
			var modalInstance = $uibModal.open({
				templateUrl: '../views/modal/send-order-modal.html',
				controller: 'sendOrder-modalController',
				resolve: {
					order: function () {
						return order;
					}
				}
			});

			modalInstance.result.then(function (result) {
				if (result) {
					// $scope.changeOrderFilter();
				}
			}, function () {
				//Modal is dismissed
			});
		}

		$retailer.getShop()
			.then(function (shop) {
				$scope.currentShop = shop;
				$scope.loadOrders('Paid');
				$scope.activeTab = 0;

			})
			.catch(function (reason) {
				console.error("Error while getting the shop", reason);
			});

		$scope.$on('changeSelectedShop', function () {
			$retailer.getShop()
				.then(function (shop) {
					$scope.currentShop = shop;
					$scope.loadOrders('Paid');
					$scope.activeTab = 0;

				})
				.catch(function (reason) {
					console.error("Error while getting the shop", reason);
				});
		});

		$scope.cancelOrder = function (order) {
			//TODO: Create a model where the retailer should enter an reason for the cancellation
			$scope.alert = null;
			prompt({
				title: 'Order annuleren',
				message: 'Weet u zeker dat u deze order wilt annuleren?'
			}).then(function () {
				$api.post('cancel-order', { orderNumber: order.number, shopRequest: true })
					.then(function (response) {
						$scope.alert = {
							type: 'success',
							msg: "Order cancelled"
						}
					})
					.catch(function (reason) {
						console.error("Error when cancelling the order", reason);
						$scope.alert = {
							type: 'danger',
							msg: "Error while cancelling the order: " + reason
						}
					})
			});
		}

		$scope.closeAlert = function () {
			$scope.alert = null;
		} */
  }])
