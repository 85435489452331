prismanoteApp.controller('retailerCustomerImportController', ['$scope', '$rootScope', '$api', 'Upload', '$q', '$timeout', '$retailer',
  function ($scope, $rootScope, $api, Upload, $q, $timeout, $retailer) {
    $scope.currentShop = $rootScope.currentShop
    $scope.email = $rootScope.user.email

    $scope.step = []
    $scope.step[1] = true
    $scope.percent = 0
    $scope.back = function (step) {
      goToStep(step)
    }

    function goToStep (step) {
      $scope.alert = null

      angular.forEach($scope.step, function (value, key) {
        $scope.step[key] = false
      })

      $scope.step[step] = true

      if (step == 1) {
        $scope.percent = 0
      } else if (step == 2) {
        $scope.percent = 33
      } else if (step == 3) {
        $scope.percent = 66
      } else if (step == 4) {
        $scope.percent = 100
      }
    }

    $scope.completeStep1 = function () {
      if ($scope.fileForm.importFile.$error.pattern || !$scope.importFile) {
        $scope.alert = {
          type: 'danger',
          msg: 'Geen of onjuist bestand gekozen'
        }
      }else if(!$scope.email || $scope.email === '') {
        $scope.alert = {
          type: 'danger',
          msg: 'Email is verplicht'
        }
      } else {
        $scope.loading = true

        uploadFile({
          category: $scope.category,
          importFile: $scope.importFile,
          delimiter: $scope.delimiter,
          publish: $scope.publish
        }, 'api/import/parse')
          .then(function (response) {
            $scope.s3_location = response.data.uploadedResponse.Location

            $scope.loading = false
            goToStep(2)

            $scope.headers = []
            $scope.user = {
              invoice: {},
              sending: {}
            }
            angular.forEach(response.data.headers, function (value, key) {
              if (value) {
                var header = {
                  name: value
                }

                $scope.headers.push(header)
              }
            })
            $scope.lineCount = response.data.data.lines
            $scope.productProperties = response.data.product
          })
          .catch(function (reason) {
            console.error('Error while parsing', reason)
            $scope.alert = {
              type: 'danger',
              msg: 'upload error: ' + reason.data.message
            }
          })
      }
    }

    $scope.completeStep2 = function () {
      $scope.isError = false
      if ($scope.user.lastName) {
        $scope.isError = false
        goToStep(3)
        $scope.percent = 66
        $scope.step3()
      } else {
        $scope.isError = true
        console.error('LAST NAME IS REQUIRED');
      }
    }

    $scope.completeStep3 = function () {
      var params = {
        importFile: $scope.importFile,
        delimiter: $scope.delimiter,
        userData: $scope.user,
        currentShopNameSlug: $rootScope.currentShop.nameSlug,
        currentShopAddress: $rootScope.currentShop.address,
        customData: {
          genders: $scope.genders ? $scope.genders : null,
          invoice_countries: $scope.invoice_countries ? $scope.invoice_countries : null,
          sending_countries: $scope.sending_countries ? $scope.sending_countries : null,
          newsletters: $scope.newsletters ? $scope.newsletters : null,
          salutations: $scope.salutations ? $scope.salutations : null
        },
        lines: $scope.lineCount,
        s3FileLocation: $scope.s3_location
      }

      uploadFile(params, 'api/import/customer-import-handle')
        .then(function (response) {
          goToStep(4)
        })
        .catch(function (reason) {
          $scope.alert = {
            type: 'danger',
            msg: 'upload error: ' + reason.status
          }
        })
    }

    $scope.step3 = function () {
      if ($scope.user.gender) {
        getValues('gender', function (err, result) {
          $scope.genders = result
        })
      }

      if ($scope.user.salutation) {
        getValues('salutation', function (err, result) {
          $scope.salutations = result
        })
      }

      if ($scope.user.invoice.country) {
        getValues('invoice.country', function (err, result) {
          $scope.invoice_countries = result
        })
      }

      if ($scope.user.sending.country) {
        getValues('sending.country', function (err, result) {
          $scope.sending_countries = result
        })
      }

      if ($scope.user.newsletter) {
        getValues('newsletter', function (err, result) {
          $scope.newsletters = result
        })
      }
    }

    function getValues (value, callback) {
      var property = JSON.parse(_.get($scope.user, value))
      var columnName = property.name

      uploadFile({
        importFile: $scope.importFile,
        delimiter: $scope.delimiter,
        column: columnName
      }, 'api/import/value')
        .then(function (response) {
          return callback(null, response.data.result)
        })
        .catch(function (reason) {
          return callback(reason)
        })
    }

    $scope.completeImport = function () {
      uploadFile({
        importFile: $scope.importFile,
        delimiter: $scope.delimiter,
        product: $scope.product
      },
      'api/import/handle')
        .then(function (response) {
        })
        .catch(function (reason) {
          $scope.alert = {
            type: 'danger',
            msg: 'upload error: ' + reason.status
          }
          $scope.error = true
        })
    }

    $scope.closeAlert = function () {
      $scope.alert = null
    }

    function uploadFile (data, url) {
      var params = {
        url: url,
        data: {
          delimiter: data.delimiter,
          cat: data.category,
          file: data.importFile,
          currentShopId: $rootScope.currentShop._id,
          currentShopEmail: $rootScope.currentShop.email ? $rootScope.currentShop.email : null,
          userData: data.userData ? data.userData : null,
          currentShopNameSlug: data.currentShopNameSlug ? data.currentShopNameSlug : null,
          currentShopAddress: data.currentShopAddress ? data.currentShopAddress : null,
          column: data.column ? data.column : null,
          customData: data.customData ? data.customData : null,
          lines: data.lines ? data.lines : null,
          email: $scope.email
        }
      }
      return $q(function (resolve, reject) {
        Upload.upload(params)
          .then(function (res) {
            $timeout(function () {
              resolve(res)
            })
          }, function (res) {
            // wanneer uploaden mislukt
            console.error('Error: ', res.status)
            reject(res)
          }, function (evt) {
            // tijdens upload
            // var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            // $scope.uploadProgress = progressPercentage;
          })
      })
    }
  }])
