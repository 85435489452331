prismanoteApp.controller("shopBrandPurchaseOrderController", ['$scope', '$rootScope', 'FileUploader', '$company', '$api', '$stateParams', '$state', '$shop', '$product', '$http', '$brand',
    'FileSaver', 'Upload', 'dialogs', '$language', '$purchaseOrder', '$clientGroup', 'prompt', '$company', '$interval', '$language', '$prefillCalculationForPurchasOrderAndCashRegister', '$productConfig', '$q', '$uibModal', '$pdf',
    function ($scope, $rootScope, FileUploader, $company, $api, $stateParams, $state, $shop, $product, $http, $brand,
        FileSaver, Upload, dialogs, $language, $purchaseOrder, $clientGroup, prompt, $company, $interval, $language, $prefillCalculationForPurchasOrderAndCashRegister, $productConfig, $q, $uibModal, $pdf) {

        // console.log('shopBrandPurchaseOrderController called');
        $rootScope.pageTitle = 'PrismaNote Brand Portal | ' + $language.translate('PURCHASE_ORDERS');

        $scope.localdata = {
            searchshopString: '',
            currentremark: '',
            loading: false,
            currentPage: 1,
            pageSize: 5,
            showCalculationMargin: true,
            search_value: '',
            isSharedButtonClicked: false,
            isOriginalOrderSort: true,
            isMayEdit: true,
            isEmailChosen: false,
            isCustomEmailAdded: false,
            isShowDeletedItem: false,
            itemIndex: 0,
            isAnyPurchaseOrderItemDeleted: false,
            numberpattern: /^-?[0-9]+([\.,][0-9]+)?/,
            findProductsLoader: false,
            isPurchaseOrderLoaded: false
        }
        $scope.searchStarted = false;
        $scope.mystatus = 6;
        $scope.canCompanyEdit = true;
        $scope.prefillPurchaseOrderItemData = {};
        $scope.searchMode = 'exact';
        $scope.showSearchModal = false;
        $scope.purchase_order = {
            companyRemarks: {
                status: 'New'
            },
            order_date: new Date()
        }
        $scope.emailSelected = false

        // try {
        //     console.log('$rootScope:::##%%%%',$rootScope);
        //     // io.sendEvent('company:' + body.purchaseOrderNumber + ":shop:added:comment:purchaseorder", shopcommentbody);
        //     // console.log('company:' + $stateParams.orderNo + ':shop:added:comment:purchaseorder');
        //     SocketService.on('company:' + $stateParams.orderNo + ':shop:added:comment:purchaseorder', function (data) {
        //         // console.log('company:' + $stateParams.orderNo + ':shop:added:comment:purchaseorder', data);
        //         if ($scope.purchase_order && $scope.purchase_order.remarks && $scope.purchase_order.remarks.remarks && $scope.purchase_order.remarks.remarks.length && $scope.purchase_order.remarks.remarks[0]) {
        //             $scope.purchase_order.remarks.remarks.push(data);
        //         } else {
        //             $scope.purchase_order = {
        //                 remarks: {
        //                     remarks: [data]
        //                 }
        //             }
        //         }
        //         $scope.autoScrollcomment();
        //     })
        // } catch (catcherr) {
        //     console.log('catcherr: ', catcherr);
        // }

        $scope.getTaxForCountry = function () {
            // purchase_order.companyShopDetail.address.country
            // $scope.purchase_order.companyShopDetail = $scope.selectedShop.companyShop;
            var country = 'NL';
            if (($scope.purchase_order && $scope.purchase_order.shopDetail && $scope.purchase_order.shopDetail.address &&
                $scope.purchase_order.shopDetail.address.country) || ($scope.selectedShop && $scope.selectedShop.address &&
                    $scope.selectedShop.address.country)) {
                country = $scope.purchase_order.shopDetail && $scope.purchase_order.shopDetail.address && $scope.purchase_order.shopDetail.address.country ? $scope.purchase_order.shopDetail.address.country : $scope.selectedShop.address.country;
            } else if (($scope.purchase_order && $scope.purchase_order.companyShopDetail && $scope.purchase_order.companyShopDetail.address &&
                $scope.purchase_order.companyShopDetail.address.country) || ($scope.selectedShop && $scope.selectedShop.companyShop &&
                    $scope.selectedShop.companyShop.address && $scope.selectedShop.companyShop.address.country)) {
                country = $scope.purchase_order.companyShopDetail && $scope.purchase_order.companyShopDetail.address && $scope.purchase_order.companyShopDetail.address.country ? $scope.purchase_order.companyShopDetail.address.country : $scope.selectedShop.companyShop.address.country;
            }
            console.log('getTaxForCountry called: ', country);
            var body = {
                country: country
            }
            $brand.getTaxForCountry(body).then(function (taxRes) {
                console.log('taxRes: ', taxRes.data);
                if (taxRes && taxRes.data && taxRes.data.taxes && taxRes.data.taxes.rates &&
                    taxRes.data.taxes.rates.length) {
                    $scope.taxes = taxRes.data.taxes.rates;
                    $scope.firstPriceVat = $scope.taxes[0].rate;
                }
                console.log('getTaxForCountry: ', $scope.firstPriceVat, $scope.taxes);
            }).catch(function (taxErr) {
                console.error("Error while getting taxes for this shop", taxErr);
            });
        }

        $scope.getShopClientGroups = function (shop_id, companyId) {
            console.log('------ $scope.getShopClientGroups!!');
            // console.log(" ---------- getShopClientGroups ----------- ", shop_id);
            $clientGroup.getShopClientGroupsByCompany(shop_id, companyId)
                .then(function (response) {
                    if (response && response.data) {
                        // console.log("get shop client groups", response.data);
                        // Respose as a shop blocking client Groups
                        $scope.shopsClientGroups = response.data;
                    }
                })
                .catch(function (error) {
                    console.error("Error in getting shop blocking client groups", error);
                })
        }

        // Getting purchaser order(If already there with the purchase order)
        $scope.GetExistingPurchaseOrder = function (isOriginalOrderSort) {
            console.log('-------------- GetExistingPurchaseOrder!!');
            // When we have alredy purchase order
            $scope.localdata.isOriginalOrderSort = isOriginalOrderSort;

            if ($rootScope.currentCompany && $rootScope.currentCompany._id && $rootScope.currentCompany.nameSlug) {
                FnGetExistingPurchaseOrder();
            } else {
                // $scope.localdata.loading = true;
                $company.getCompany().then(function (company) {
                    $rootScope.currentCompany = company;
                    $scope.localdata.loading = false;
                    FnGetExistingPurchaseOrder();
                }).catch(function (error) {
                    $scope.localdata.loading = false;
                    console.log('error while fetching company');
                });
            }

            function FnGetExistingPurchaseOrder() {
                console.log('--------- FnGetExistingPurchaseOrder!!');
                $scope.localdata.loading = true;
                var body = {
                    purchaseOrderNumber: $stateParams.orderNo,
                    companyId: $rootScope.currentCompany._id,
                    companySlug: $rootScope.currentCompany.nameSlug,
                    isOriginalOrderSort: isOriginalOrderSort
                }
                console.log('isOriginalSrot: ', isOriginalOrderSort, body.isOriginalOrderSort);
                $scope.localdata.isPurchaseOrderLoaded = false;
                $api.get('purchase-order-of-company', body) // Here passed this id because in params that needed so we can use same api for that
                    .then(function (response) {
                        $scope.localdata.loading = false;
                        console.log("response.data:=====>", JSON.parse(JSON.stringify(response.data.items)));
                        console.log("response.data:=====>", response);
                        if (!response || !response.data) {
                            $scope.erromessage = 'Oops! We could not find your purchase order on this purchase number';
                            $scope.alertdanger = true;
                            return;
                        } else {
                            $scope.alertdanger = false;
                        }
                        var originalData = JSON.parse(JSON.stringify(response.data));
                        $scope.logToDisplay = originalData.log.filter(function (el) {
                            return el.isFor == 'supplierPortal';
                        });
                        $scope.hasShop = true;
                        $scope.purchase_order = response.data;
                        $scope.hasCompanySharedOrderWithShop = $scope.purchase_order.hasCompanyClickedFirstTime;
                        if ($scope.purchase_order.items.length == 1 && !$scope.purchase_order.items[0]._id) {
                            console.log('COME INSIDE  ----------------->');
                            $scope.purchase_order.items = [];
                            // console.log('items []: ', $scope.purchase_order.items);
                        }

                        $scope.AlloriginalEmails = JSON.parse(JSON.stringify($scope.purchase_order.emails));
                        $scope.purchase_order.lastInvoiceNumber = $scope.purchase_order.invoiceNumber || '';
                        // console.log('fetchd items: ', originalData.items[0]);
                        if ($scope.purchase_order && $scope.purchase_order.shop && $scope.purchase_order.shop._id) { $scope.getShopClientGroups($scope.purchase_order.shop._id, $scope.purchase_order.company._id); } // Getting clien groups of particular shop
                        $scope.purchaseOrderId = $scope.purchase_order._id;
                        $scope.purchase_order.order_date = new Date($scope.purchase_order.order_date);

                        // console.log('COMpany remakrs: ', JSON.stringify($scope.purchase_order.companyRemarks));
                        if (!$scope.purchase_order.companyRemarks) {
                            $scope.purchase_order.companyRemarks = {
                                delivery_date: new Date(angular.copy($scope.purchase_order.delivery_date)),
                                status: $scope.purchase_order.status[0].status,
                                comment: ''
                            }
                        } else if (!$scope.purchase_order.companyRemarks.delivery_date) {
                            // console.log('delivery_date not there: ', $scope.purchase_order.delivery_date);
                            $scope.purchase_order.companyRemarks.delivery_date = new Date(angular.copy($scope.purchase_order.delivery_date));
                            // console.log('delivery_date not there: ', $scope.purchase_order.companyRemarks.delivery_date);
                        } else if (!$scope.purchase_order.companyRemarks.status) {
                            $scope.purchase_order.companyRemarks.status = $scope.purchase_order.status[0].status;
                        }

                        if ($scope.purchase_order.companyRemarks && $scope.purchase_order.companyRemarks.delivery_date) {
                            $scope.purchase_order.companyRemarks.delivery_date = new Date($scope.purchase_order.companyRemarks.delivery_date);
                        }

                        // console.log($scope.purchase_order.companyRemarks.backorder_delivery_date);

                        if ($scope.purchase_order.companyRemarks && $scope.purchase_order.companyRemarks.backorder_delivery_date) {
                            $scope.isBackOrderDate = true;
                            // console.log($scope.purchase_order.companyRemarks.backorder_delivery_date, 'inside');
                            $scope.purchase_order.companyRemarks.backorder_delivery_date = new Date(angular.copy($scope.purchase_order.companyRemarks.backorder_delivery_date));
                        } else if ($scope.purchase_order.backorder_delivery_date) {
                            $scope.purchase_order.companyRemarks.backorder_delivery_date = new Date(angular.copy($scope.purchase_order.backorder_delivery_date));
                        } else {
                            $scope.purchase_order.companyRemarks.backorder_delivery_date = new Date();
                        }

                        if ($scope.purchase_order.companyRemarks && $scope.purchase_order.companyRemarks.invoice) {
                            var invoiceDetail = $scope.purchase_order.companyRemarks.invoice.split('/');
                            $scope.invoiceFileName = invoiceDetail[1];
                        }

                        console.log('hello: ', $scope.purchase_order.companyRemarks);

                        $scope.focusIndex = undefined;
                        // $scope.getTaxes();
                        $scope.getTaxForCountry();
                        timerForInvoice();
                        checkhasNotSameSendQntAsOrderQnt();
                        findShopEntryCustomerValue(); // Finding customer value method from the shop.companies
                        isExpandSerialNumberOrNot(); // To do calculation of the serial number expandable
                        lastEntryCustomerValueOfPurchaseOrderItems();
                        enableDisableFieldOfInput(false);
                        $scope.sendAllLoading = false;
                        $scope.localdata.isPurchaseOrderLoaded = true;
                    })
                    .catch(function (reason) {
                        $scope.localdata.loading = false;
                        $scope.localdata.isPurchaseOrderLoaded = true;
                        $scope.sendAllLoading = false;
                        enableDisableFieldOfInput(false);
                        // console.log('--------------------> ', reason);
                        if (reason == 'No purchase order found with that order no') {
                            $state.go('brand.purchase-orders');
                            $scope.erromessage = 'Oops! We could not find your purchase order on this purchase number';
                            $scope.alertdanger = true;
                        } else {
                            console.error(reason);
                        }
                    })
            }
        }

        $scope.sharePurchaseOrderWithRetailer = function () {
            var sendFirstMailToShop = false;
            if (!$scope.purchase_order.hasCompanyClickedFirstTime) sendFirstMailToShop = true;

            $scope.localdata.isSharedButtonClicked = true;

            var body = {
                _id: $scope.purchase_order._id,
                language: $rootScope.language || 'en',
                sendFirstMailToShop: sendFirstMailToShop,
                startCountDownTime: $scope.purchase_order.invoiceCountDown.startCountDownTime || new Date(),
                //isFirstPurchaseOrder: $scope.purchase_order.shopDetail.isFirstPurchaseOrder,
                companyId: $rootScope.currentCompany._id
            }

            $purchaseOrder.sharePurchaseOrderWithRetailer(body).then(function (response) {
                $scope.localdata.loading = false;
                // console.log('after update: ', response);
                $scope.hasCompanySharedOrderWithShop = true;
                $scope.localdata.isSharedButtonClicked = true;
                // $scope.isMessage = true;
                // $scope.showMessage = response.data.message;
                $scope.GetExistingPurchaseOrder();
            }).catch(function (error) {
                $scope.localdata.loading = false;
            });
        }

        // DEPRECATED
        function UpdateThePurchaseOrder(isSaveAndEmailSentButtonPress) {
            if (!$scope.purchase_order.log) $scope.purchase_order.log = [];
            if (isSaveAndEmailSentButtonPress) {
                $scope.purchase_order.sendEmailToShop = true;
                $scope.purchase_order.isCompanyRepliedAfterProcessingMode = true;
                $scope.purchase_order.language = $rootScope.language;

                $scope.localdata.isSharedButtonClicked = true;
                if (!$scope.purchase_order.hasCompanyClickedFirstTime) {
                    // console.log('shopDetail: ', $scope.purchase_order.shopDetail.email);
                    $scope.purchase_order.sendFirstMailToShop = true;
                }
                $scope.purchase_order.hasCompanyClickedFirstTime = true;
            }
            // console.log('$scope.purcahse order before udpate api: ', $scope.purchase_order);
            $api.put('company-purchase-orders/', $scope.purchase_order)
                .then(function (response) {
                    // console.log('after update: ', response);
                    if (isSaveAndEmailSentButtonPress) {
                        $scope.hasCompanySharedOrderWithShop = true;
                    }
                    $scope.localdata.isSharedButtonClicked = true;
                    $scope.isMessage = true;
                    $scope.showMessage = response.data.message;
                    $scope.localdata.loading = false;
                    $scope.GetExistingPurchaseOrder();
                })
                .catch(function (reason) {
                    $scope.localdata.loading = false;
                    $scope.isMessage = true;
                    $scope.showMessage = response.data.message;
                })
        }

        $scope.changeTheStaus = function (isChangingFromViewPage) {
            var oldStatus = 'New';
            // console.log($scope.mystatus, $scope.purchase_order.companyRemarks.status);
            if ($scope.mystatus == 1) oldStatus = 'Info';
            else if ($scope.mystatus == 2) oldStatus = 'Processing';
            else if ($scope.mystatus == 3) oldStatus = 'Inspection';
            else if ($scope.mystatus == 4) oldStatus = 'Backorder';
            else if ($scope.mystatus == 5) oldStatus = 'Completed';
            else if ($scope.mystatus == 6) oldStatus = 'New';
            else if ($scope.mystatus == 7) oldStatus = 'Cancelled';
            if (oldStatus == $scope.purchase_order.companyRemarks.status) return;


            if (isChangingFromViewPage) {
                var logs = getLogOfChangingStatus(oldStatus);
                var isChangeTheStatus = false;
                if ($scope.purchase_order.hasCompanyClickedFirstTime) isChangeTheStatus = true;

                var statusbody = {
                    purchaseOrderId: $scope.purchase_order._id,
                    status: $scope.purchase_order.companyRemarks.status,
                    oldStatus: oldStatus,
                    log: logs,
                    from: 'company',
                    isChangeTheStatus: isChangeTheStatus,
                    companyId: $rootScope.currentCompany._id
                }
                $scope.localdata.loading = true;
                $purchaseOrder.changeTheStatus(statusbody).then(function (statusChanged) {
                    $scope.localdata.loading = false;
                    // console.log('status changed successfully');
                    $scope.GetExistingPurchaseOrder();
                }).catch(function (error) {
                    $scope.localdata.loading = false;
                    console.log('error while changing the status: ', error);
                });
            }
        }

        function getLogOfChangingStatus(oldStatus) {
            // Its for supplierPortal Display
            var logs = [];
            logs.push(GetTheLog("Status changed from " + oldStatus + " to " + $scope.purchase_order.companyRemarks.status, 'supplierPortal'));

            // Its for retailerPortal Display
            if ($scope.purchase_order.companyRemarks.status == 'Info') {
                logs.push(GetTheLog("Request for more information", 'retailerPortal'));
                logs.push(GetTheLog("Request for more information", 'supplierPortal'));
            } else if ($scope.purchase_order.companyRemarks.status == 'Backorder') {
                logs.push(GetTheLog("Notification of Backorder", 'retailerPortal'));
            } else if ($scope.purchase_order.companyRemarks.status == 'Completed') {
                logs.push(GetTheLog("Notification that order is shipped", 'retailerPortal'));
            }

            if ($scope.purchase_order.companyRemarks.status == "Info") {
                $scope.mystatus = 1;
            } else if ($scope.purchase_order.companyRemarks.status == "Processing") {
                $scope.mystatus = 2;
            } else if ($scope.purchase_order.companyRemarks.status == "Inspection") {
                $scope.mystatus = 3;
            } else if ($scope.purchase_order.companyRemarks.status == "Backorder") {
                $scope.mystatus = 4;
            } else if ($scope.purchase_order.companyRemarks.status == "Completed") {
                $scope.mystatus = 5;
            } else if ($scope.purchase_order.companyRemarks.status == "New") {
                $scope.mystatus = 6;
            } else if ($scope.purchase_order.companyRemarks.status == "Cancelled") {
                $scope.mystatus = 7;
            }

            return logs;
        }

        function validateEmail(email) {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        }

        $scope.getEmails = function (search) {
            if (search) {
                // console.log('Emails: ', $scope.purchase_order.emails);
                var isFound = false;
                $scope.localdata.isCustomEmailAdded = false;
                for (var i = 0; i < $scope.AlloriginalEmails.length; i++) {
                    if ($scope.purchase_order.emails[i] && $scope.purchase_order.emails[i].originalEmail && $scope.purchase_order.emails[i].originalEmail.toLowerCase().indexOf(search.toString().toLowerCase()) >= 0) {
                        isFound = true;
                        break;
                    }
                }

                if (!isFound && validateEmail(search)) {
                    $scope.localdata.isCustomEmailAdded = true;
                    $scope.purchase_order.emails = JSON.parse(JSON.stringify($scope.AlloriginalEmails));
                    $scope.purchase_order.emails.unshift({ email: search, originalEmail: search, isShopEmail: false, isCustomEmail: true });
                }
            }
            return $scope.purchase_order.emails;
        }

        $scope.sendEmailToShop = function () {
            $scope.purchase_order.shopEmailAddress = $scope.purchase_order.shopDetail.email;
            // console.log('sendEmailToShop: ', $scope.localdata.isMayEdit, $scope.purchase_order.shopEmailAddress);
            $scope.localdata.loading = true;
            var body = {
                language: $rootScope.language,
                email: $scope.purchase_order.shopDetail.email,
                isMayEdit: $scope.localdata.isMayEdit,
                purchaseOrder: {
                    _id: $scope.purchase_order._id,
                    shop: $scope.purchase_order.shop,
                    company: $scope.purchase_order.company,
                    number: $scope.purchase_order.number,
                    date: $scope.purchase_order.date,
                    delivery_date: $scope.purchase_order.delivery_date,
                    companyRemarks: $scope.purchase_order.companyRemarks
                },
                conversationData: $scope.purchase_order.remarks.remarks ? $scope.purchase_order.remarks.remarks : '',
                companyId: $rootScope.currentCompany._id
            }
            if ($scope.purchase_order.shopDetail.lastemail !== $scope.purchase_order.shopDetail.email) body.isEmailChanged = true;
            $purchaseOrder.sendEmailToShopAboutSavedOrder(body).then(function (response) {
                $scope.localdata.isSharedButtonClicked = false;
                $scope.localdata.loading = false;
                if ($scope.localdata.isMayEdit) {
                    $scope.ExportToPDF(true, true); // Pro Forma Invoice
                } else {
                    $scope.ExportToPDF(true, false); // Invoice
                }
                $scope.localdata.isEmailChosen = false;
                $scope.emailSelected = false
            }).catch(function (error) {
                $scope.localdata.loading = false;
                console.log('error: ', error);
            });;
        }

        /**
         * NEW MADE FUNCTION
         * @param isPurchaseOrderNew: when you clicked 'NEW PURCHASE ORDER' at time we set it in rootscope to true
         */
        // First come here and check that purchase order is NEW or already existed call
        if ($rootScope.isPurchaseOrderNew) {
            $scope.hasShop = false;
            $scope.hasCompanySharedOrderWithShop = false;
            // hasCompanyClickedFirstTime
            $scope.changeTheStaus(false);
        } else {
            $scope.GetExistingPurchaseOrder();
        }

        $scope.back = function () {
            if (!$scope.hasCompanySharedOrderWithShop && $scope.purchase_order._id) {
                askUserToShareOrderWithShop();
            } else {
                $state.go('brand.purchase-orders');
            }
        }

        function askUserToShareOrderWithShop() {
            prompt({
                title: 'ALERT',
                message: $language.translate('PLEASE_SHARE_YOUR_ORDER'),
                buttons: [
                    // { label: 'SHARE', primary: true, id: 0 },
                    { label: $language.translate('CANCEL'), cancel: true, id: 2 },
                    { label: $language.translate('DONT_SHARE'), primary: true, id: 1 }
                ]
            }).then(function (response) {
                // console.log('response: ', response);
                if (response.id === 1) $state.go('brand.purchase-orders');
            }).catch(function (error) {

            });
        }

        // While we are creating new purchase order (Fresh purchase order)
        $scope.addThePurchaseOrder = function (item) {
            console.log('----- addThePurchaseOrder!!1 : ', item);
            // Creating new purchase order and adding product into that
            if (!$scope.offerItem) $scope.offerItem = {
                quantity: 1
            };
            var purchaseOrderItem = {
                price: $scope.prefillPurchaseOrderItemData.price || 0,
                purchasePrice: $scope.prefillPurchaseOrderItemData.purchasePrice || 0,
                purchasePriceCompany: ($scope.prefillPurchaseOrderItemData && $scope.prefillPurchaseOrderItemData.companyProduct && $scope.prefillPurchaseOrderItemData.companyProduct.purchasePrice) ? $scope.prefillPurchaseOrderItemData.companyProduct.purchasePrice : 0,
                entryMethodCustomerValue: $scope.prefillPurchaseOrderItemData.entryMethodCustomerValue,
                storageFactor: $scope.prefillPurchaseOrderItemData.storageFactor || 0,
                discount: {
                    value: $scope.prefillPurchaseOrderItemData.margin || 0 // first caluclate using the suggestedRetailerPrice and company.product.price if not there then get from clientgroup and if clientgroup not there then leave it blank
                },
                product_id: item.product_id,
                quantity: item.quantity,
                stock: 0,
                receivedQuantity: 0,
                name: item.comment,
                number: item.number ? item.number : $scope.localdata.search_value,
                createdAt: new Date(),
                priceVat: $scope.firstPriceVat,
                ean: $scope.localdata.ean ? $scope.localdata.ean : undefined,
                prefillCollectionName: capitalizeFirstLetter(item.collectionString),
            }

            console.log('----- purchaseOrderItem ', purchaseOrderItem);

            if ($scope.unofficialProduct) {
                // console.log('unofficialProduct: ', $scope.unofficialProduct);
                purchaseOrderItem.unofficialProduct = {
                    brand: {
                        _id: $scope.unofficialProduct.brand && $scope.unofficialProduct.brand._id ? $scope.unofficialProduct.brand._id : undefined,
                        appliedStartingDigits: $scope.unofficialProduct.brand && $scope.unofficialProduct.brand.startingDigits ? $scope.unofficialProduct.brand.startingDigits : '',
                    },
                    companyAppliedStartingDigits: $scope.unofficialProduct.companyAppliedStartingDigits || '',
                    insertedProductNumber: $scope.unofficialProduct.insertedProductNumber
                }
                purchaseOrderItem.number = $scope.unofficialProduct.productNumber;
            }

            // console.log('purchaseOrderItem: ', purchaseOrderItem);
            // return;
            $scope.purchase_order.items = [purchaseOrderItem];
            $scope.purchase_order.number = $stateParams.orderNo;
            // console.log("purchaseOrder", $scope.purchase_order);
            $scope.purchase_order.isFirstPurchaseOrder = $scope.isFirstPurchaseOrder;
            $scope.localdata.loading = true;
            console.log('$scope.purchase_order: ', $scope.purchase_order);
            $api.post('company-purchase-order', $scope.purchase_order)
                .then(function (response) {
                    console.log('respnose: ', response.data.data);
                    $scope.localdata.loading = false;
                    $rootScope.isPurchaseOrderNew = false;
                    $scope.hasCompanySharedOrderWithShop = response.data.data.hasCompanyClickedFirstTime;
                    var newPurcahseOrderNo = response.data.data.number;
                    $scope.cancelItem(); // For removing input field
                    if ($stateParams.orderNo != newPurcahseOrderNo) { // Sometimes mutiple user accessing the portal at time they have same orderno so we are checking at backend and changed if needed
                        $state.go('brand.purchase-order', {
                            orderNo: newPurcahseOrderNo
                        });
                    } else {
                        $scope.GetExistingPurchaseOrder();
                        $scope.purchase_order = response.data.data;
                        // console.log('$scopurc a: ', $scope.purchase_order);
                        $scope.purchaseOrderid = $scope.purchase_order._id;
                    }
                    lastEntryCustomerValueOfPurchaseOrderItems();
                })
                .catch(function (reason) {
                    $scope.localdata.loading = false;
                    console.error("Error when saving the order", reason);
                    $scope.isErrorOccured = true;
                    $scope.errorMsg = reason.data.data.message;
                    $scope.alert = {
                        type: 'danger',
                        msg: reason.data.message
                    }
                })
        }

        // Purchaser order already created but now we are adding more product into it
        $scope.addProductToExistingPurchaseOrder = function (item) {
            console.log('-----------------------------> addProductToExistingPurchaseOrder');
            var itemnumber = item.number ? item.number : $scope.localdata.productNumber;
            var purchaseOrderItem = {
                price: $scope.prefillPurchaseOrderItemData.price || 0,
                purchasePrice: $scope.prefillPurchaseOrderItemData.purchasePrice || 0,
                purchasePriceCompany: ($scope.prefillPurchaseOrderItemData && $scope.prefillPurchaseOrderItemData.companyProduct && $scope.prefillPurchaseOrderItemData.companyProduct.purchasePrice) ? $scope.prefillPurchaseOrderItemData.companyProduct.purchasePrice : 0,
                entryMethodCustomerValue: $scope.prefillPurchaseOrderItemData.entryMethodCustomerValue,
                storageFactor: $scope.prefillPurchaseOrderItemData.storageFactor || 0,
                discount: {
                    value: $scope.prefillPurchaseOrderItemData.margin || 0 // first caluclate using the suggestedRetailerPrice and company.product.price if not there then get from clientgroup and if clientgroup not there then leave it blank
                },
                product_id: item.product_id,
                quantity: item.quantity,
                stock: 0,
                receivedQuantity: 0,
                name: item.comment,
                number: itemnumber,
                ean: $scope.localdata.ean ? $scope.localdata.ean : undefined,
                createdAt: new Date(),
                priceVat: $scope.firstPriceVat,
                prefillCollectionName: capitalizeFirstLetter(item.collectionString),
            }

            console.log('purchaseOrderItem: ', purchaseOrderItem);

            if ($scope.unofficialProduct) {
                // console.log('unofficialProduct: ', $scope.unofficialProduct);
                purchaseOrderItem.unofficialProduct = {
                    brand: {
                        _id: $scope.unofficialProduct.brand && $scope.unofficialProduct.brand._id ? $scope.unofficialProduct.brand._id : undefined,
                        appliedStartingDigits: $scope.unofficialProduct.brand && $scope.unofficialProduct.brand.startingDigits ? $scope.unofficialProduct.brand.startingDigits : '',
                    },
                    companyAppliedStartingDigits: $scope.unofficialProduct.companyAppliedStartingDigits || '',
                    insertedProductNumber: $scope.unofficialProduct.insertedProductNumber
                }
                purchaseOrderItem.number = $scope.unofficialProduct.productNumber;
            }

            // Adding new product to existing purchase order
            // Checking if product is already exist or not, if yes then increased quantity of the existing purchase order quantity
            var isProudctAlreadyExist = false;
            for (var i = 0; i < $scope.purchase_order.items.length; i++) {
                // console.log('$scope.purchase_order.items[i].number, itemnumber: ', $scope.purchase_order.items[i].number, itemnumber);
                if (item.product_id) {
                    if ($scope.purchase_order.items[i].product_id && $scope.purchase_order.items[i].product_id._id &&
                        ($scope.purchase_order.items[i].product_id._id).toString() === (item.product_id).toString()) {
                        console.log('$scope.purchase_order.items[i].isDeleted', $scope.purchase_order.items[i].isDeleted);
                        console.log('$scope.purchase_order.items[i]', $scope.purchase_order.items[i]);

                        if ($scope.purchase_order.items[i].isDeleted == true) {
                            isProudctAlreadyExist = false;
                        } else {
                            console.log('inside main if');

                            isProudctAlreadyExist = true;
                            indexOfAlreadyAddedProduct = i;
                            break;
                        }

                    }
                }

                if ($scope.purchase_order.items[i].number == itemnumber) {
                    console.log('inside second if');
                    if ($scope.purchase_order.items[i].isDeleted == true) {
                        isProudctAlreadyExist = false;
                    } else {
                        isProudctAlreadyExist = true;
                        indexOfAlreadyAddedProduct = i;
                        break;
                    }
                }
            }

            if (isProudctAlreadyExist) {
                $scope.errorMsg = 'This product is already on your purchase order';
                $scope.isErrorOccured = true;
                return;
            }

            if ($scope.purchase_order && $scope.purchase_order.shop && $scope.purchase_order.shop._id && $scope.purchase_order.items.length) {
                $scope.localdata.loading = true;
                var body = {
                    _id: $scope.purchase_order._id,
                    item: purchaseOrderItem,
                    status: $scope.purchase_order.status[0].status,
                    isCompanyRepliedAfterProcessingMode: $scope.purchase_order.isCompanyRepliedAfterProcessingMode ? true : false,
                    companyId: $rootScope.currentCompany._id
                }
                console.log('body ', body);
                $purchaseOrder.addProductInCompanyPurchaseOrder(body).then(function (respnose) {
                    $scope.localdata.loading = false;
                    $scope.cancelItem(); // For removing input field
                    console.log('response after updating purchase order: ==========================================>', $scope.localdata.search_value);
                    $scope.GetExistingPurchaseOrder(); // For getiing private label and all the things
                }).catch(function (error) {
                    $scope.localdata.loading = false;
                    console.error("Error when saving the order", reason);
                    $scope.isErrorOccured = true;
                    $scope.errorMsg = (reason.data.message ? reason.data.message : 'Something went wrong, Please try again later');
                    $scope.alert = {
                        type: 'danger',
                        msg: reason.data.message
                    }
                });
            } else {
                $scope.isErrorOccured = true;
                if (!$scope.purchase_order.shop) $scope.errorMsg = 'Select the shop first';
                if (!$scope.purchase_order.items.length) $scope.errorMsg = 'You need to add product first';
            }
        }

        $scope.eanIsExistsOrNot = function (ean) {
            console.log('--------- ean!', ean);
            console.log('ean.length ',ean.length);
            // Enterd EAN number is valid or not
            if (ean.length) {
                console.log('--------- if!');
                console.log(!$purchaseOrder.isLikeEANNumber(ean));
                if (!$purchaseOrder.isLikeEANNumber(ean)) {
                    $scope.eanValid = false;
                    return;
                }
                $product.eanIsExistsOrNot(ean, undefined, $rootScope.currentCompany._id).then(function (result) {
                    console.log('eanIsExistsOrNot result:', result);
                    if (result.status == true) $scope.eanValid = true;
                }).catch(function (error) {
                    console.error("Error =>>> ", error);
                })
            } else {
                $scope.eanValid = true;
            }
        }

        // When supplier select particular row of product and clicked on pencil icon
        $scope.updateExistingProductOfPurchaseOrder = function (item) {
            var itemnumber = item.number ? item.number : $scope.localdata.search_value;
            var productId = item.product_id || ($scope.purchase_order.items[$scope.offerIndex].product_id && $scope.purchase_order.items[$scope.offerIndex].product_id._id);

            var itemDetail = $scope.purchase_order.items[$scope.offerIndex];
            itemDetail.product_id = productId;
            itemDetail.number = itemnumber;
            itemDetail.name = item.comment;
            itemDetail.quantity = item.quantity;

            var body = {
                _id: $scope.purchase_order._id,
                item: itemDetail,
                companyId: $rootScope.currentCompany._id
            }

            // if ($scope.unofficialProduct) {
            //     // console.log('unofficialProduct: ', $scope.unofficialProduct);
            //     $scope.purchase_order.items[$scope.offerIndex].unofficialProduct = {
            //         brand: {
            //             _id: $scope.unofficialProduct.brand && $scope.unofficialProduct.brand._id ? $scope.unofficialProduct.brand._id : undefined,
            //             appliedStartingDigits: $scope.unofficialProduct.brand && $scope.unofficialProduct.brand.startingDigits ? $scope.unofficialProduct.brand.startingDigits : '',
            //         },
            //         companyAppliedStartingDigits: $scope.unofficialProduct.companyAppliedStartingDigits || '',
            //         insertedProductNumber: $scope.unofficialProduct.insertedProductNumber
            //     }
            //     $scope.purchase_order.items[$scope.offerIndex].number = $scope.unofficialProduct.productNumber;
            // }

            // console.log('body: ==> ', body);
            $purchaseOrder.updateExistingProductInCompanyPurchaseOrder(body).then(function (respnose) {
                $scope.localdata.loading = false;
                $scope.cancelItem(); // For removing input field
                // console.log('response after updating purchase order: ==========================================>', respnose);
                $scope.GetExistingPurchaseOrder(); // For getiing private label and all the things
            }).catch(function (error) {
                console.log('soemthing went wrong: ', error);
                $scope.localdata.loading = false;
                $scope.isErrorOccured = true;
                $scope.errorMsg = (reason.data.message ? reason.data.message : 'Something went wrong, Please try again later');
                $scope.alert = {
                    type: 'danger',
                    msg: reason.data.message
                }
            });
        }

        // When cllcking on confirm date then we copy the retailer delivery_date
        $scope.copyDate = function (isBackOrder) {
            if (!$scope.purchase_order.companyRemarks) {
                $scope.purchase_order.companyRemarks = {};
            }
            if (isBackOrder) {
                $scope.purchase_order.companyRemarks['backorder_delivery_date'] = new Date(angular.copy($scope.purchase_order.backorder_delivery_date));
            } else {
                // console.log('purchsro order copyDate: ', !$scope.purchase_order.companyRemarks, $scope.purchase_order);
                $scope.purchase_order.companyRemarks['delivery_date'] = new Date(angular.copy($scope.purchase_order.delivery_date));
            }
        }

        $scope.uploadFiles = function (file) {
            // console.log('file: ', file);
            $scope.invoiceFile = file;
        }

        $scope.popover = function (item) {
            if (item.product_id) {
                if (item.product_id.images && item.product_id.images.length && item.product_id.images[0].src) {
                    var image = $rootScope.awsUrl + item.product_id.images[0].src;
                    var alttext = (item.product_id && item.product_id.category) ? item.product_id.category : "No Image found";
                    return "<img src=" + image + " alt=" + alttext + ">";
                } else if (item.product_id.category) {
                    return item.product_id.category;
                }
            }
        }

        $scope.getThumbnailUrl = function(url){
            if(url){
                var index = url.indexOf('/');
                var folder = url.substring(0, index + 1);
                var fileName = url.replace(folder, '');
                return folder + 'prismathumbnail-' + fileName;
            }
        }

        /**
         * @param {Object}  item purchase order item
         */
        $scope.showProductPopup = function (item, index) {
            $scope.purchase_order.items[index].popoverIsVisible = true;
        }

        $scope.hideProductPopup = function (item, index) {
            // console.log('hideProductPopup hiding');
            // $scope.popoverIsVisible = false;
            $scope.purchase_order.items[index].popoverIsVisible = false;
        }

        // Print the given data
        $scope.ExportToPDF = function (isInvoicePDF, isProFormaInvoice, canHaveImage) {
            if (canHaveImage !== false) canHaveImage = true;
            //console.log('isInvoicePDF: ', isInvoicePDF, isProFormaInvoice);
            $scope.printerr = false;
            if (isProFormaInvoice) {
                generateThePdf();
            } else {
                prompt({
                    title: $language.translate('CONFIRMATION'),
                    message: $language.translate('SURE_YOU_WANT_TO_PUT_IN_INVOICE_MODE'),
                    buttons: [{
                        label: $language.translate('YES'),
                        primary: true,
                        id: 0
                    },
                    {
                        label: $language.translate('CANCEL'),
                        cancel: true,
                        id: 1
                    }
                    ]
                }).then(function (response) {
                    generateThePdf();
                }).catch(function (error) {
                    console.log('error: inprompnt: ', error);
                });
            }

            function generateThePdf() {
                $scope.isVatNumberError = false;
                if (!isProFormaInvoice && isInvoicePDF) $scope.canCompanyEdit = false;
                console.log('isProFormaInvoice', isProFormaInvoice);
                console.log('isInvoicePDF', isInvoicePDF);
                $scope.localdata.loading = true;
                var conversarionData
                /* html2canvas(document.getElementById('exportthis'), {
                    onrendered: function (canvas) {
                        var data = canvas.toDataURL();
                        var docDefinition = {
                            content: [{
                                image: data,
                                width: 500,
                            }]
                        };
                        conversarionData = docDefinition
                    }
                }); */
                // console.log('$rootScope:::@@@@',$rootScope);
                var fileName = 'purchaseorder_' + $scope.purchase_order.number + '.pdf';
                $pdf.downloadPdf('generate-pdf-purchase-order', {
                    shop: $scope.purchase_order.shop,
                    purchaseOrderId: $scope.purchase_order._id,
                    typeDocument: 'document',
                    isInvoicePDF: isInvoicePDF,
                    isProFormaInvoice: isProFormaInvoice,
                    canHaveImage: canHaveImage,
                    isOriginalOrderSort: $scope.localdata.isOriginalOrderSort,
                    conversationData: $scope.purchase_order.remarks.remarks ? $scope.purchase_order.remarks.remarks : '',
                    language: $rootScope.language,
                    showMarginPriceFactor: $rootScope.currentCompany.showMarginPriceFactor,
                    companyId: $rootScope.currentCompany._id
                //-}, 'arraybuffer', true, false)
                }, fileName, 'arraybuffer', true, false)

                // $http({
                //     method: "POST",
                //     url: '/api/generate-pdf-purchase-order',
                //     data: {
                //         shop: $scope.purchase_order.shop,
                //         purchaseOrderId: $scope.purchase_order._id,
                //         typeDocument: 'document',
                //         isInvoicePDF: isInvoicePDF,
                //         isProFormaInvoice: isProFormaInvoice,
                //         isOriginalOrderSort: $scope.localdata.isOriginalOrderSort,
                //         conversationData: $scope.purchase_order.remarks.remarks ? $scope.purchase_order.remarks.remarks : '',
                //         language: $rootScope.language,
                //         showMarginPriceFactor: $rootScope.currentCompany.showMarginPriceFactor
                //     },
                //     responseType: 'arraybuffer'
                // }) 
                .then(function (response) {
                    console.log('file response: ', response);
                    $scope.localdata.loading = false;
                    if (!isProFormaInvoice && isInvoicePDF) $scope.purchase_order.companyRemarks.status = 'Completed';
                    var file = new Blob([response.data], { type: 'application/pdf' });
                    var fileName = 'purchaseorder_' + $scope.purchase_order.number + '.pdf';
                    // FileSaver.saveAs(file, fileName);
                    $scope.GetExistingPurchaseOrder();
                }).catch(function (reason) {
                    $scope.localdata.loading = false;
                    $scope.printerr = true;
                    if (!isProFormaInvoice) $scope.canCompanyEdit = true;
                    if (reason && reason.status === 400) {
                        $scope.isVatNumberError = true;
                        $scope.printerrmessage = $language.translate('VAT_NUMBER_IS_NOT_CORRECTLY_ADDED_ADD_ADMIN');
                    } else {
                        $scope.printerrmessage = 'Something went wrong';
                    }
                    console.error("Error while generating PDF", reason);
                })
            }
        }

        $scope.AddComment = function (comment) {
            if (comment) {
                var body = {
                    purchaseOrderId: $scope.purchase_order._id,
                    shop: $scope.purchase_order.shop,
                    company: $scope.purchase_order.company,
                    purchaseOrderNumber: $scope.purchase_order.number,
                    hasCompanyClickedFirstTime: $scope.purchase_order.hasCompanyClickedFirstTime ? $scope.purchase_order.hasCompanyClickedFirstTime : false,
                    remarks: {
                        eType: 'company',
                        remark: comment,
                        companyId: $scope.purchase_order.company._id
                    }
                }

                $purchaseOrder.addComment(body).then(function (response) {
                    // console.log('addComment: called');
                    $scope.localdata.currentremark = '';
                    $('#currentremark').val('');
                    $scope.GetExistingPurchaseOrder();
                }).catch(function (err) {
                    console.log(err);
                    $('#currentremark').val('');
                    $scope.localdata.currentremark = '';
                });
            }
        }

        $scope.EditCommentMode = function (remark, index) {
            for (var i = 0; i < $scope.purchase_order.remarks.remarks.length; i++) {
                $scope.purchase_order.remarks.remarks[i].isEditMode = false;
            }
            $scope.purchase_order.remarks.remarks[index].isEditMode = true;
            $scope.editRemarkDetail = remark;
        }

        $scope.UpdateComment = function (comment, index) {
            if (comment) {
                $scope.editRemarkDetail.remark = comment;
                $scope.purchase_order.remarks.remarks[index].isEditMode = false;
                // console.log('$scope.editRemarkDetail: ', $scope.editRemarkDetail);
                var body = {
                    purchaseOrderId: $scope.purchase_order._id,
                    commentId: $scope.editRemarkDetail._id,
                    remark: comment
                }

                $api.post('edit-comment-retailer-and-company', body)
                    .then(function (response) {
                        $scope.editRemarkDetail = '';
                        $scope.GetExistingPurchaseOrder();
                    })
                    .catch(function (err) {
                        $scope.editRemarkDetail = '';
                        console.log(err);
                    });
            } else {
                $scope.editRemarkDetail = '';
                $scope.purchase_order.remarks.remarks[index].isEditMode = false;
            }
        }

        $scope.getCompany = function () {
            console.log('-------- get company!!');
            $company.getCompany().then(function (company) {
                $rootScope.currentCompany = company;
                if ($rootScope.isPurchaseOrderNew) {
                    $scope.purchase_order.sendDetails = {
                        address: [$rootScope.currentCompany.address]
                    };
                    $scope.purchase_order.company = {
                        _id: $rootScope.currentCompany._id,
                        name: $rootScope.currentCompany.name,
                        nameSlug: $rootScope.currentCompany.nameSlug
                    }
                }
            }).catch(function (error) {
                console.log('error: ', error);
            });
        }

        $scope.selectTheShopByDefault = function () {
            // If coming from the shop-detail page to create the purchase order then we have to call this
            if ($rootScope.shopDetails && $rootScope.shopDetails._id) {

                $brand.searchShopForBrandPurchaseOrder({ companyId: $rootScope.currentCompany._id, searchText: ' ', shopId: $rootScope.shopDetails._id }).then(function (shopresponse) {
                    if (shopresponse && shopresponse.data && shopresponse.data.data && shopresponse.data.data.data && shopresponse.data.data.data.length) {
                        console.log('shoresponse: ', shopresponse.data.data.data);
                        $scope.selectTheShop(shopresponse.data.data.data[0]);
                    }
                    $rootScope.shopDetails = undefined;
                }).catch(function (shoperr) {
                    console.log('shoperr: ', shoperr);
                    $rootScope.shopDetails = undefined;
                });
            }
        }

        // Searching the shops
        $scope.searchShops = function (str) {
            $scope.foundShops = [];
            $brand.searchShopForBrandPurchaseOrder({ companyId: $rootScope.currentCompany._id, searchText: str }).then(function (shopresponse) {
                if (shopresponse && shopresponse.data && shopresponse.data.data && shopresponse.data.data.data  && shopresponse.data.data.data.length) {
                    console.log('shoresponse: ', shopresponse.data.data.data);
                    $scope.foundShops = shopresponse.data.data.data;
                }
            }).catch(function (shoperr) {
                console.log('shoperr: ', shoperr);
            });

            // $scope.shops.forEach(function (item) {
            //     if (item && (
            //         (item.companyShop && item.companyShop.name && item.companyShop.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
            //         (item.companyShop && item.companyShop.nameSlug && item.companyShop.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0) ||
            //         (item.companyShop && item.companyShop.email && item.companyShop.email.toLowerCase().indexOf(str.toString().toLowerCase()) > 0) ||

            //         (item.name && item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
            //         (item.nameSlug && item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0) ||
            //         (item.email && item.email.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)
            //     )) {
            //         $scope.foundShops.push(item);
            //     }
            // });
        }

        // Selecting the shop
        $scope.selectTheShop = function (shop) {
            $scope.foundShops = [];
            $scope.localdata.searchshopString = '';
            // console.log('hello shop selected: ', shop);
            $scope.selectedShop = shop;
            $scope.hasShop = true;
            $scope.purchase_order.billingDetails = {
                address: [$scope.selectedShop.address],
                firstName: $scope.selectedShop.name,
                phone: $scope.selectedShop.phone,
                email: $scope.selectedShop.email
            }

            // If Company edited address from the brand/shopDetail page then we have to show that
            if ($scope.selectedShop && $scope.selectedShop.companyShop) {
                $scope.purchase_order.companyShopDetail = $scope.selectedShop.companyShop;
            }
            $scope.purchase_order.shop = {
                _id: $scope.selectedShop._id,
                name: $scope.selectedShop.name,
                nameSlug: $scope.selectedShop.nameSlug
            }
            $scope.isFirstPurchaseOrder = $scope.selectedShop.isFirstPurchaseOrder;
            // $scope.getTaxes();
            $scope.getTaxForCountry();
            findShopCompaniesEntryMethodValue($scope.selectedShop.companies);
            if ($scope.purchase_order && $scope.purchase_order.shop && $scope.purchase_order.shop._id) $scope.getShopClientGroups($scope.purchase_order.shop._id, $scope.purchase_order.company._id); // Getting clien groups of particular shop
            if ($scope.purchase_order && $scope.purchase_order.items &&
                $scope.purchase_order.items.length && $scope.purchase_order.items[0]._id) {

                var body = {
                    purchaseOrderId: $scope.purchase_order._id,
                    type: 'billingDetailsAndShop',
                    key: 'billingDetails',
                    value: {
                        billingDetails: $scope.purchase_order.billingDetails,
                        shop: {
                            _id: $scope.selectedShop._id,
                            name: $scope.selectedShop.name,
                            nameSlug: $scope.selectedShop.nameSlug
                        }
                    },
                    companyId: $rootScope.currentCompany._id
                }
                $purchaseOrder.updatePurchaseOrderByKey(body).then(function (addresres) {
                    // console.log('send details and Company updated successfully');
                    $scope.GetExistingPurchaseOrder();
                }).catch(function (error) {
                    console.log('updatePurchaseOrderByKey address error: ', error);
                });
            }
        }

        $scope.changeShop = function () {
            $scope.hasShop = false;
            delete $scope.purchase_order.billingDetails;
            delete $scope.purchase_order.shop;
            delete $scope.purchase_order.companyShopDetail;
            $scope.selectedShop = '';
        }

        // In tabular form, Uibtooltip of purchase order
        $scope.purchaserOrderHistoryUibToolTip = function (index) {
            var item = $scope.purchase_order.items[index];
            return $purchaseOrder.purchaserOrderHistoryUibToolTip(item);
        }

        // In tabular form, Uibtooltip of serial number
        $scope.serialNumberUibToolTip = function (index, childIndex) {
            var item = $scope.purchase_order.items[index].serialNumbers[childIndex];
            return $purchaseOrder.serialNumberUibToolTip(item);
        }

        // In tabular form, Uibtooltip of Price
        $scope.priceUibToolTip = function (index) {
            var item = $scope.purchase_order.items[index];
            return $purchaseOrder.priceUibToolTip(item);
        }

        // In tabular form, Uibtooltip of PriceVat
        $scope.priceVatUibToolTip = function (index) {
            var item = $scope.purchase_order.items[index];
            return $purchaseOrder.priceVatUibToolTip(item);
        }

        // In tabular form, Uibtooltip of Discount
        $scope.discountUibToolTip = function (index) {
            var item = $scope.purchase_order.items[index];
            return $purchaseOrder.discountUibToolTip(item);
        }

        // In tabular form, Uibtooltip of Invoice
        $scope.invoiceNumberHistoryUibToolTip = function () {
            var invoiceHistory = $scope.purchase_order.invoiceNumberHistory;
            return $purchaseOrder.invoiceNumberHistoryUibToolTip(invoiceHistory);
        }

        // In tabular form, Uibtooltip of purchase Price
        $scope.purchasePriceUibToolTip = function (index) {
            var item = $scope.purchase_order.items[index];
            // console.log('purchasePriceUibToolTip called: ', index, item);
            return $purchaseOrder.purchasePriceUibToolTip(item);
        }

        $scope.storageFactorUibToolTip = function (index) {
            var item = $scope.purchase_order.items[index];
            return $purchaseOrder.storageFactorUibToolTip(item);
        }

        $scope.sendQuantityUibToolTip = function (index) {
            var item = $scope.purchase_order.items[index];
            return $purchaseOrder.sendQuantityUibToolTip(item);
        }

        // Finding company products
        $scope.isLikeEANNumber = function (value) {
            console.log('-------------- isLikeEANNumber!!');
            $scope.gotProductsResponse = false;
            $scope.productExactMatch = false;
            $scope.showSearchModal = true;
            console.log('value ', value);
            // $scope.searchStarted = true;
            if ($purchaseOrder.isLikeEANNumber(value)) $scope.localdata.isLikeEANNumber = true;
            if (!$purchaseOrder.isLikeEANNumber(value)) $scope.localdata.isLikeEANNumber = false;
        }

        $scope.findProducts = function (value, searchMode, isProductNumber) {
            $scope.productExactMatch = false;
            $scope.gotProductsResponse = false;
            $scope.searchStarted = true;
            $scope.findProducts2(value, searchMode, isProductNumber);
            $scope.isLikeEANNumber(value);
            delete $scope.hideHits;
            // $scope.hits = [];
            if (isProductNumber) {
                console.log('isProductNumber ', isProductNumber);
                $scope.localdata.productNumber = $productConfig.retrunValidProductNumber(value);
                $scope.localdata.productNumberSearch = true;
            } else {
                console.log('isProductNumber ', isProductNumber);
                $scope.localdata.ean = $productConfig.retrunValidProductNumber(value);
                $scope.localdata.eanNumberSearch = true; $scope.localdata.productNumberSearch = false;
            }
            $scope.unofficialProduct = undefined;
            if ($scope.offerItem && $scope.offerItem.product_id) $scope.offerItem.product_id = undefined;
            // setTimeout(function () { document.getElementById('findproductId').focus(); }, 1000);
            if (value && value.length > 0 && $rootScope.currentCompany && $rootScope.currentCompany._id) {
                // console.log('DOCUEMT: ', document.getElementById('findproductId'));
                // setTimeout(function () { document.getElementById('findproductId').focus(); }, 1000);
                $scope.localdata.search_value = $productConfig.retrunValidProductNumber(value);
                value = $scope.localdata.search_value;
                if (value.length < 3) return;

                $scope.cancleSearch = false;
                $scope.searchingProduct = true;

                var data = {
                    companyId: $rootScope.currentCompany._id,
                    searchMode: searchMode,
                    searchText: value,
                    isProductNumber: isProductNumber,
                    language: $rootScope.language
                };
                $scope.productNumberStatus = 'default';
                // $scope.localdata.loading = true;
                $scope.localdata.findProductsLoader = true;
                // document.getElementById('findproductId').focus();
                $company.searchProductForCompany(data)
                    .then(function (response) {
                        console.log('--------- searchProductForCompany 1!');
                        $scope.productExactMatch = response.productExactMatch
                        // console.log("Response ::::: ", response);
                        // document.getElementById('findproductId').focus();
                        $scope.localdata.findProductsLoader = false;
                        $scope.gotProductsResponse = true;
                        if (!$scope.cancleSearch) {
                            // this might even not required, We'll test with current code and then we'll use it if required
                            // removeDuplicateProduct(angular.copy($scope.hits), response.data);
                            // $scope.hits = response.data;
                            removeDuplicateProduct($scope.hits, response.data);
                            console.log(" ******************* ")
                            // $scope.countOfProducts = response.count || 0;
                            // console.log(" countOfProducts ", $scope.countOfProducts)

                            // $scope.localdata.loading = false;
                            $scope.searchingProduct = false;
                            // console.log("Hey product handling guy Check this here")
                            // $scope.hideHits = true;
                            if (response.status != null && response.status != undefined) $scope.productNumberStatus = parseInt(response.status);
                        } else {
                            $scope.localdata.loading = false;
                            $scope.searchingProduct = false;
                        }

                    }).catch(function (error) {
                        // document.getElementById('findproductId').focus();
                        // $scope.localdata.loading = false;
                        $scope.localdata.findProductsLoader = true;
                        $scope.searchingProduct = false;
                        console.error("error", error);
                    })
            } else {
                // document.getElementById('findproductId').focus();
            }
        };

        $scope.findProducts2 = function (value, searchMode, isProductNumber) {
            $scope.productExactMatch = false;
            console.log('value ', value);
            delete $scope.hideHits
            $scope.hits = [];
            $scope.unofficialProduct = undefined;
            $scope.isLikeEANNumber(value);

            if(isProductNumber){ 
                console.log('isProductNumber ', isProductNumber);
                $scope.localdata.productNumber = (value).toUpperCase();
                $scope.localdata.productNumberSearch = true;
            } else {
                console.log('isProductNumber ', isProductNumber);
                $scope.localdata.ean = $productConfig.retrunValidProductNumber(value);
                $scope.localdata.eanNumberSearch = true; $scope.localdata.productNumberSearch = false;
            }
            if ($scope.offerItem && $scope.offerItem.product_id) $scope.offerItem.product_id = undefined;
            // setTimeout(function () { document.getElementById('findproductId').focus(); }, 1000);
            if (value && value.length > 0 && $rootScope.currentCompany && $rootScope.currentCompany._id) {
                // console.log('DOCUEMT: ', document.getElementById('findproductId'));
                // setTimeout(function () { document.getElementById('findproductId').focus(); }, 1000);
                $scope.localdata.search_value = $productConfig.retrunValidProductNumber(value);
                value = $scope.localdata.search_value;
                if (value.length < 3) return;

                $scope.cancleSearch = false;
                $scope.searchingProduct = true;

                var data = {
                    companyId: $rootScope.currentCompany._id,
                    searchMode: searchMode,
                    searchText: value,
                    isProductNumber: isProductNumber,
                    language: $rootScope.language
                };
                $scope.productNumberStatus = 'default';
                // $scope.localdata.loading = true;
                $scope.localdata.findProductsLoader = true;
                // document.getElementById('findproductId').focus();
                $company.searchProductForCompany2(data)
                    .then(function (response) {
                        console.log('--------- searchProductForCompany 2!');
                        // console.log("Response ::::: ", response);
                        // document.getElementById('findproductId').focus();
                        $scope.localdata.findProductsLoader = false;
                        if (!$scope.cancleSearch) {
                            // this might even not required, We'll test with current code and then we'll use it if required
                            // removeDuplicateProduct(angular.copy($scope.hits), response.data);
                            $scope.hits = response.data;
                            $scope.productExactMatch = response.productExactMatch;
                            // console.log(" ******************* ")
                            // $scope.countOfProducts = response.count || 0;
                            // console.log(" countOfProducts ", $scope.countOfProducts)

                            // $scope.localdata.loading = false;
                            $scope.searchingProduct = false;
                            // console.log("Hey product handling guy Check this here")
                            // $scope.hideHits = true;
                            if (response.status != null && response.status != undefined) $scope.productNumberStatus = parseInt(response.status);
                        } else {
                            $scope.localdata.loading = false;
                            $scope.searchingProduct = false;
                        }

                    }).catch(function (error) {
                        // document.getElementById('findproductId').focus();
                        // $scope.localdata.loading = false;
                        $scope.localdata.findProductsLoader = true;
                        $scope.searchingProduct = false;
                        console.error("error", error);
                    })
            } else {
                // document.getElementById('findproductId').focus();
            }
        };

         // here hits means firstAPI call and data means seconds API call
         function removeDuplicateProduct(hits, data) {
            console.log('--------- removeDuplicateProduct!!');
            console.log('hits ', hits.length);
            console.log('data ', data.length);
            
           if (hits && hits.length && data && data.length) {
                for (var i = 0; i < data.length; i++) {
                    for (var j = 0; j < hits.length; j++) {
                        if(hits[j]._id && data[i]._id && hits[j]._id == data[i]._id){ break; }
                        if(j == hits.length - 1){  $scope.hits.push(data[i]); }
                    }
                }
                $scope.countOfProducts = hits.length; 
            } else { if(!hits.length) $scope.hits = data; }
        }

        $scope.changeEAN = function (index, ean) {
            console.log('----------------- $scope.changeEAN!!');
            console.log('----------------- index', index);
            console.log('----------------- ean', ean);
            console.log('--- $scope.purchase_order.items[index]', $scope.purchase_order.items[index]);
            // console.log('----------------- ean', ean);
            if (!ean) { $scope.purchase_order.items[index].isInvalidEAN = false; return; }
            $scope.purchase_order.items[index].isInvalidEAN = true;
            if ($purchaseOrder.isValidEAN(ean, $scope.purchase_order.items)) {
                $scope.purchase_order.items[index].isInvalidEAN = false;
                var body = {
                    purchaseOrderId: $scope.purchase_order._id,
                    itemId: $scope.purchase_order.items[index]._id,
                    ean: ean,
                    companyId: $rootScope.currentCompany._id
                }
                console.log('changeEAN: ', body);
                $scope.localdata.itemIndex = index;
                enableDisableFieldOfInput(true);
                $purchaseOrder.changeEAN(body).then(function (res) {
                    enableDisableFieldOfInput(false);
                }).catch(function (err) {
                    enableDisableFieldOfInput(false);
                    console.log('error in chagne EAN: ', err);
                })
            }
        }

        $scope.closeLoader = function () {
            $scope.cancleSearch = true;
            $scope.localdata.loading = false;
            delete $scope.localdata.search_value;
        }
        // Setting search mode for finding product e.g. 'exact' or 'contains'
        $scope.setSearchMode = function (value, mode) {
            $scope.searchMode = mode;
            $scope.findProducts(value, mode);
        }

        $scope.useAsNew = function () {
            console.log('----------- useAsNew!');
            $scope.hideHits = false;
        }

        // While we are finding product when click on product from list at time this will call
        $scope.addItem = function (product) {
            console.log('------------- addItem!!');
            if (product && product.variants && product.variants.length) {
                if (product.variants[0].productNumber) $scope.localdata.productNumber = $scope.localdata.search_value = product.variants[0].productNumber;
                if (product.variants[0].ean) $scope.localdata.ean = product.variants[0].ean;
            }
            $scope.hideHits = false;
            $scope.hits = [];
            $scope.offerItem = {
                quantity: 1
            };
            $scope.offerItem.quantity = 1;
            if (product && product._id) {
                $scope.localdata.search_value = product.variants[0].productNumber;
                $scope.offerItem.comment = product[$rootScope.language].name || product.en.name;
                $scope.offerItem.product_id = product._id;
                $scope.offerItem.number = product.variants[0].productNumber;
                $scope.offerItem.name = product.en.name;

                $scope.localdata.loading = true;
                // Finding product details if its in the company.product to prefill data
                $company.getCompanyProduct($rootScope.currentCompany._id, product._id).then(function (response) {
                    $scope.localdata.loading = false;
                    // console.log('response: ', response);
                    if (response && response.data && response.data.product) {
                        $scope.prefillPurchaseOrderItemData.suggestedRetailPrice = response.data.product.suggestedRetailPrice;
                        $scope.prefillPurchaseOrderItemData.suggestedRetailPriceVat = product.suggestedRetailPriceVat;
                    }
                    if (response && response.data && response.data.companyProduct) {
                        $scope.prefillPurchaseOrderItemData.isCompanyProduct = true;
                        $scope.prefillPurchaseOrderItemData.companyProduct = response.data.companyProduct;
                    } else {
                        $scope.prefillPurchaseOrderItemData.isCompanyProduct = false;
                    }
                    // console.log('$scope.prefillPurchaseOrderItemData: ', $scope.prefillPurchaseOrderItemData);
                    prefillTheValueWhenAddingProduct();
                }).catch(function (error) {
                    $scope.localdata.loading = false;
                    console.log('error: ', error);
                });
            } else {
                // This else will not but sometimes not getting proper data from then this will call
                $scope.prefillPurchaseOrderItemData.isShopProduct = false;
                $scope.prefillPurchaseOrderItemData.isCompanyProduct = false;
                prefillTheValueWhenAddingProduct();
            }
        }

        // When editing or adding product at time this will call
        $scope.saveItem = function (item, search_value) {
            // if($scope.localdata.ean) $scope.eanIsExistsOrNot($scope.localdata.ean);
            if (!item.product_id) prefillTheValueWhenAddingProduct();
            $scope.localdata.search_value = search_value;
            if ($scope.purchase_order.shop && $scope.purchase_order.shop._id) {
                var collectionString = '';
                if (!$scope.purchaseOrderId) { // When fresh purchase order (means creating new purchase order)
                    console.log('$scope.purchase_order.shop: ', $scope.purchase_order.shop);
                    if (item.product_id) {
                        var bodyToSend = {
                            productId: item.product_id,
                            shopId: $scope.purchase_order.shop._id,
                            language: $rootScope.language || 'EN',
                            companyId: $rootScope.currentCompany._id
                        }
                        console.log('bodyToSend: ', bodyToSend);
                        $purchaseOrder.getCollectionStringForPurchaseOrderProduct(bodyToSend).then(function (collectinStrRes) {
                            // console.log('collectinStrRes: ', collectinStrRes);
                            if (collectinStrRes && collectinStrRes.data && collectinStrRes.data.data) {
                                collectionString = collectinStrRes.data.data;
                            }
                            // console.log('collectionString: =========>', collectionString);
                            item.collectionString = collectionString;
                            $scope.addThePurchaseOrder(item);
                        }).catch(function (error) {
                            console.log('ERROR IN FETCHING COLLETION STRING: ', error);
                            item.collectionString = collectionString;
                            $scope.addThePurchaseOrder(item);
                        });
                    } else {
                        item.collectionString = collectionString;
                        $scope.addThePurchaseOrder(item);
                    }
                } else {
                    if (!$scope.editItem) {
                        if (item.product_id) {
                            var bodyToSend = {
                                productId: item.product_id,
                                shopId: $scope.purchase_order.shop._id,
                                language: $rootScope.language || 'EN',
                                companyId: $rootScope.currentCompany._id
                            }
                            $purchaseOrder.getCollectionStringForPurchaseOrderProduct(bodyToSend).then(function (collectinStrRes) {
                                if (collectinStrRes && collectinStrRes.data && collectinStrRes.data.data) {
                                    collectionString = collectinStrRes.data.data;
                                }
                                // console.log('collectinStrRes: ', collectionString);
                                item.collectionString = collectionString;
                                $scope.addProductToExistingPurchaseOrder(item);
                            }).catch(function (error) {
                                console.log('ERROR IN FETCHING COLLETION STRING: ', error);
                                item.collectionString = collectionString;
                                $scope.addProductToExistingPurchaseOrder(item);
                            });
                        } else {
                            item.collectionString = collectionString;
                            $scope.addProductToExistingPurchaseOrder(item);
                        }
                    } else {
                        if ($scope.purchase_order.isCompanyRepliedAfterProcessingMode == true) {
                            $scope.isErrorOccured = true;
                            $scope.errorMsg = 'You can not update the product in this way. Use the pencils to change the ordered/received quanitity or delete the product';
                        } else {
                            $scope.updateExistingProductOfPurchaseOrder(item);
                        }
                    }
                }
            } else {
                $scope.isErrorOccured = true;
                $scope.errorMsg = 'Select the shop first';
            }
        }

        // input field will be remove
        $scope.cancelItem = function () {
            console.log('-----------cancelItem!!');
            $scope.offerIndex = null;
            $scope.prefillPurchaseOrderItemData = {};
            $('#search_field_purchaseOrder').val('');
            $('#offercomment').val('');
            $scope.localdata.search_value = '';
            $scope.localdata.productNumber = '';
            $scope.localdata.ean = '';
            $scope.searchStarted = false;
            $scope.offerItem = { quantity: 1 };
            $scope.offerItem.quantity = 1;
            $scope.editItem = false;
            $scope.isErrorOccured = false;
            $scope.errorMsg = '';
            $scope.hits = [];
            $scope.gotProductsResponse = false;
            $scope.offerItem.quantity = '';
            $scope.offerItem.comment = '';
            $scope.showSearchModal = false;
        }

        $scope.cancelPurchaseOrder = function () {
            $api.delete('purchase-order/' + $stateParams.orderNo + '/' + $scope.purchase_order.shop._id)
                .then(function (response) {
                    // console.log('response: ', response);
                    $state.go('brand.purchase-orders');
                }).catch(function (error) {
                    console.log('error: ', error);
                    $scope.isErrorOccured = true;
                    $scope.errorMsg = 'Internal Server Error while deleting order';
                });
        }

        $scope.myFunct = function (keyEvent, isProductNumber) {
            console.log('----------------- $scope.myFunct!!');
            console.log('keyEvent.target ', keyEvent.target);
            // $scope.productExactMatch = false;
            if (keyEvent.target && keyEvent.target.value) $scope.localdata.productNumber = (keyEvent.target.value).toUpperCase();
            // if (keyEvent.which === 13) {
            //     $scope.searchquery = keyEvent.target.value;
            //     $scope.findProducts($scope.searchquery, $scope.searchMode, isProductNumber)
            // }
        }

        // // When we clicked on row
        $scope.openItem = function (item, index) {
            console.log('------- openItem!!');
            console.log('item ', item);
            console.log('index ', index);
            $scope.localdata.search_value = item.number;
            $scope.localdata.productNumber = item.number;
            $scope.localdata.ean = item.ean;
            $('#search_field_purchaseOrder').val(item.number);
            $scope.offerItem = {
                quantity: 1
            };
            $scope.offerItem.comment = item.name;
            $scope.offerItem.quantity = item.quantity;
            $scope.editItem = true;
            $scope.offerIndex = index;
        }

        $scope.deleteItem = function (index) {
            prompt({
                title: $language.translate('DELETE_RECEIVED_QUANTITY'),
                message: $language.translate('YOUR_ABOUT_TO_DELETE_ARE_YOU_SURE_TO_DELETE'),
                buttons: [
                    {
                        label: $language.translate('DELETE'),
                        primary: true,
                        id: 0
                    },
                    {
                        label: $language.translate('CANCEL'),
                        primary: false,
                        cancel: true,
                        id: 2
                    }
                ]
            }).then(function (response) {
                console.log('------------ inside if!!: ', response);
                if (response.primary) {
                    // DELETING PRODUCT
                    var item = $scope.purchase_order.items[index];
                    if ($scope.purchase_order.status[0].status == 'Inspection' || $scope.purchase_order.status[0].status == 'Backorder') {
                        item.ProdChangesWhenInspectionActive.isProductChanged = true;
                    } else if ($scope.purchase_order.status[0].status == 'New') {
                        item.isProductDeletedInNewStatus = true;
                    }
                    item.quantity = 0;
                    item.isDeleted = true;
                    $scope.purchase_order.items[index].isDeleted = true;
                    var deleteBody = {
                        _id: $scope.purchase_order._id,
                        item: item,
                        status: $scope.purchase_order.status[0].status,
                        shopId: $scope.purchase_order.shop._id,
                        companyId: $rootScope.currentCompany._id
                    }
                    $scope.localdata.loading = true;
                    $scope.priceCollLoading = true;
                    console.log('deleteBody ', deleteBody);
                    $purchaseOrder.deleteProductFromPurchaseOrder(deleteBody).then(function (updres) {
                        console.log('Updating existing product done');
                        $scope.localdata.loading = false;
                        $scope.localdata.isShowDeletedItem = true;
                        $scope.localdata.isAnyPurchaseOrderItemDeleted = true;
                        $scope.cancelItem(); // For removing input field
                        $scope.GetExistingPurchaseOrder($scope.limit); // For getiing private label and all the things
                    }).catch(function (upderr) {
                        console.log('---------- upderr!! ', upderr);
                        $scope.localdata.loading = false;
                        enablePriceCollBtn();
                        console.log('Error while updating product: ', upderr);
                    });
                }
            }).catch(function (error) {
                console.log('error: inprompnt: ', error);
            });
        }

        // $scope.deleteItem = function (index) {
        //     // console.log('index: ', index, $scope.purchase_order.items[index], $scope.purchase_order);
        //     if ($scope.purchase_order.items.length === 1 && !$scope.purchase_order.isCompanyRepliedAfterProcessingMode) {
        //         $scope.cancelPurchaseOrder();
        //     } else {
        //         var item = $scope.purchase_order.items[index];
        //         if ($scope.purchase_order.isCompanyRepliedAfterProcessingMode) {
        //             if ($scope.purchase_order.status[0].status == 'Inspection' || $scope.purchase_order.status[0].status == 'Backorder') {
        //                 item.ProdChangesWhenInspectionActive.isProductChanged = true;
        //             } else if ($scope.purchase_order.status[0].status == 'New') {
        //                 item.isProductDeletedInNewStatus = true;
        //             }
        //             item.isDeleted = true;
        //             deleteProduct(item, false);
        //         } else {
        //             $scope.purchase_order.items.splice(index, 1);
        //             deleteProduct(item, true);
        //         }
        //     }
        // }

        function deleteProduct(item, isRemoved) {
            var deleteBody = {
                _id: $scope.purchase_order._id,
                item: item,
                status: $scope.purchase_order.status[0].status,
                isRemoved: isRemoved,
                companyId: $rootScope.currentCompany._id
            }
            // console.log('deleteBody: ', deleteBody);
            $scope.localdata.loading = true;
            $purchaseOrder.deleteProductFromCompanyPurchaseOrder(deleteBody).then(function (updres) {
                // console.log('Updating existing product done');
                $scope.localdata.loading = false;
                $scope.cancelItem(); // For removing input field
                $scope.GetExistingPurchaseOrder(); // For getiing private label and all the things
            }).catch(function (upderr) {
                $scope.localdata.loading = false;
                console.log('Error while updating product: ', upderr);
            });
        }

        $scope.editQuantity = function (item, index) {
            $scope.purchase_order.items[index].isQuantityEditable = true;
        }

        $scope.calculationsOfOrdered = function (index) {
            var item = $scope.purchase_order.items[index];
            // console.log('---------> calculationsOfOrdered: ', item);
            if (item.isDeleted) {
                item.quantity = 0;
                item.addQuantity = Math.floor(item.addQuantity);
            } else {
                item.addQuantity = (item.addQuantity - item.quantity);
                item.addQuantity = Math.floor(item.addQuantity);
            }
            if (item.isDeleted) {
                item.isDeleted = false;
                item.quantity = item.receivedQuantity;
                item.ProdChangesWhenInspectionActive.isEdited = true;
                item.ProdChangesWhenInspectionActive.updatedAt = new Date();
                item.ProdChangesWhenInspectionActive.updatedQuantity = item.addQuantity;
            }

            $scope.purchase_order.items[index].isQuantityEditable = false;

            if (item.addQuantity) {
                var totalQuantity = (+item.quantity) + (+item.addQuantity);
                if (totalQuantity >= 0) {
                    if (totalQuantity >= item.receivedQuantity) {
                        UpdateTheQuantity(item, index);
                    } else {
                        // console.log('Ordered should be more than the recieved');
                        $scope.isErrorOccured = true;
                        $scope.errorMsg = 'Received should not be more than the ordered quantity. Raise the received quantity first';
                    }
                } else {
                    // $scope.isErrorOccured = true;
                    // $scope.errorMsg = 'Enter proper value';
                    if (totalQuantity <= item.receivedQuantity) {
                        UpdateTheQuantity(item, index);
                    } else {
                        $scope.isErrorOccured = true;
                        $scope.errorMsg = 'Received should not be less than the ordered quantity. Decrease the received quantity first';
                    }
                }
            }
        }

        function UpdateTheQuantity(item, index) {
            var totalQuantity = (+ item.quantity) + (+ item.addQuantity);
            $scope.isErrorOccured = false;
            item.oldQuantity = item.quantity; // For Backend Old quantity
            $scope.purchase_order.items[index].quantity = totalQuantity; // Updating view
            item.quantity = totalQuantity;

            $scope.offerItem.quantity = item.quantity;
            item.addedQuantity = item.addQuantity; // Which we are adding right now
            item.addQuantity = 0;

            // $scope.purchase_order.isCalculationUpdation = true; // For backend to check (For maintaining history of it)
            // $scope.purchase_order.isQuantityUpdated = isQuantityUpdated; // is quantity(true) updated or receviedqunatity(false)
            // $scope.purchase_order.indexToUpdate = index;

            checkhasNotSameSendQntAsOrderQnt();

            var updBody = {
                _id: $scope.purchase_order._id,
                item: item,
                status: $scope.purchase_order.status[0].status,
                companyId: $rootScope.currentCompany._id
            }
            $scope.localdata.itemIndex = index;
            // enableDisableFieldOfInput(true);
            // console.log('changeTheQuantityFromCompanyPurchaseOrder item: ', item);
            $purchaseOrder.changeTheQuantityFromCompanyPurchaseOrder(updBody).then(function (updres) {
                $scope.GetExistingPurchaseOrder();
            }).catch(function (upderr) {
                enableDisableFieldOfInput(false);
                console.log('Error while updating product: ', upderr);
            });
        }

        $scope.UpdateTheOrderAndDeliveryDate = function (date, type) {
            if ($scope.purchase_order && $scope.purchase_order.items &&
                $scope.purchase_order.items.length && $scope.purchase_order.items[0]._id) {
                var isDateIsNotReachedByCompany = false;
                var delayDays = 0;
                if (type == 'companydeliverydate') {
                    $scope.purchase_order.companyRemarks.delivery_date = date;
                    // console.log('Update the order and dleiey date brand', date, type, $scope.purchase_order.companyRemarks);
                    if (!$scope.purchase_order.isCreatedByCompany) {
                        // var lastDeliveryDate = new Date($scope.purchase_order.companyRemarks.last_delivery_date).getTime();
                        var retailerDeliveryDate = new Date($scope.purchase_order.delivery_date).getTime();
                        var companyDeliveryDate = new Date($scope.purchase_order.companyRemarks.delivery_date).getTime();
                        var diffSec = companyDeliveryDate - retailerDeliveryDate;
                        if (diffSec > 0) {
                            isDateIsNotReachedByCompany = true;
                            delayDays = Math.round(diffSec / 86400000); // 86400000 -> 1 day
                        }
                    }
                }

                var body = {
                    purchaseOrderId: $scope.purchase_order._id,
                    type: 'companydate',
                    key: type,
                    value: date,
                    isDateIsNotReachedByCompany: isDateIsNotReachedByCompany,
                    delayDays: delayDays,
                    companyId: $rootScope.currentCompany._id
                }
                $scope.localdata.loading = true;
                $purchaseOrder.updatePurchaseOrderDate(body).then(function (response) {
                    $scope.localdata.loading = false;
                    // console.log(' date updated successfully');
                }).catch(function (error) {
                    $scope.localdata.loading = false;
                    console.log('something went wrong while updateing  date: ', error);
                });
            }
        }

        function capitalizeFirstLetter(value) {
            if (value) {
                return value.charAt(0).toUpperCase() + value.slice(1)
            } else {
                return '';
            }
        }

        var uploader = $scope.uploader = new FileUploader({
            url: 'api/purchase-order-invoice-upload?companyId=' + $rootScope.currentCompany._id
        });

        uploader.filters.push({
            name: 'duplicateFilesFilter',
            fn: function (item) {
                var index = _.findIndex($scope.uploader.queue, function (q) {
                    return q.file.name == item.name
                })
                return index < 0;
            }
        });

        $scope.addCommentToFile = function (index) {
            $scope.editComment = index;
        }

        $scope.saveFileComment = function () {
            $scope.editComment = null;
        }

        uploader.onProgressAll = function (progress) {
            console.log('------- onProgressAll!! ', progress);
            if (progress < 100) {
                $rootScope.$broadcast('dialogs.wait.progress', {
                    'progress': progress
                })
            } else {
                $rootScope.$broadcast('dialogs.wait.complete');
            }
        }

        uploader.onSuccessItem = function (item, response, status, headers) {
            //Push every single file in an array to store them later on the transaction
            console.log('------- onSuccessItem!!');
            $scope.allFiles.push({
                src: response.file,
                comment: item.formData.comment,
                created_at: new Date()
            })
        }

        uploader.onCompleteAll = function () {
            console.log('------- onCompleteAll!!');
            var logs = [];
            logs.push(GetTheLog('Notification that attachement is uploaded', 'retailerPortal'));
            logs.push(GetTheLog('Notification that attachement is uploaded', 'supplierPortal'));
            var fileBody = {
                _id: $scope.purchase_order._id,
                invoices: $scope.allFiles,
                log: logs,
                companyId: $rootScope.currentCompany._id
            }

            // console.log('all files uploaded: ', fileBody);
            $purchaseOrder.updateTheInvoicesDeatils(fileBody).then(function (respnose) {
                uploader.clearQueue();
                $scope.localdata.loading = false;
                $scope.allFiles = [];
                $scope.GetExistingPurchaseOrder();
            }).catch(function (error) {
                console.log('error there in file: ', error);
                uploader.clearQueue();
                $scope.localdata.loading = false;
                $scope.allFiles = [];
            })

        }

        uploader.onErrorItem = function (fileItem, response, status, headers) {
            $scope.localdata.loading = false;
            $scope.alert = {
                type: 'danger',
                msg: response.message
            }
        }

        $scope.uploadFiles = function () {
            console.log('----------- uploadFiles!!');
            $scope.localdata.loading = true;
            $scope.allFiles = [];
            if (uploader.queue.length) {
                uploader.uploadAll();
                dialogs.wait($language.translate('UPLOADING'), $language.translate('PLEASE_WAIT_WHILE_THE_PHOTOS_ARE_UPLOADED'), 0)
                // See the uploader.onCompleteAll callback function for further code
            }
        }

        $scope.openShopDetail = function (nameSlug) {
            $state.go('brand.shop-detail', {
                'nameSlug': nameSlug
            });
        }

        $scope.sortOrder = function (isOriginalOrderSort) {
            console.log('isOriginalOrderSort: ', isOriginalOrderSort);
            $scope.GetExistingPurchaseOrder(isOriginalOrderSort);
        }


        $scope.getTaxes = function () {
            var shopId = $scope.purchase_order.shop._id;
            // console.log('getTaxes called: ', shopId);
            $api.get('tax/shop', {
                shopId: shopId
            }).then(function (res) {
                $scope.taxes = res.data.taxes.rates;
                // console.log('taxes: ', $scope.taxes);
                if ($scope.taxes && $scope.taxes.length) {
                    $scope.firstPriceVat = $scope.taxes[0].rate;
                }
            }).catch(function (reason) {
                console.error("Error while getting taxes for this shop", reason)
            })
        }

        // SERIAL NUMBER START

        $scope.refreshSerialNumber = function () {
            // console.log('$scope.index: ', $scope.itemIndex);
            if ($scope.itemIndex !== undefined) {
                $scope.purchase_order.items[$scope.itemIndex].isEdit = false;
                $scope.purchase_order.items[$scope.itemIndex].inputSerialNumber = '';
            }
        }

        $scope.addSerialNumber = function (index) {
            // console.log('add Serial number: ', index);
            var addedSerialNumber = $scope.purchase_order.items[index].inputSerialNumber;
            if (addedSerialNumber) {
                var body = {
                    purchaseOrderId: $scope.purchase_order._id,
                    purchaseOrderItemId: $scope.purchase_order.items[index]._id,
                    productId: $scope.purchase_order.items[index].product_id ? $scope.purchase_order.items[index].product_id._id : undefined,
                    shopId: ($scope.purchase_order && $scope.purchase_order.shop && $scope.purchase_order.shop._id ? $scope.purchase_order.shop._id : undefined),
                    lastSerialNumber: '-',
                    addedSerialNumber: addedSerialNumber,
                    companyId: $scope.purchase_order.company._id,
                    isShow: true,
                    action: 'Added',
                    status: $scope.purchase_order.status[0].status,
                    eType: 'company',
                }
                // console.log('index value: ', body);
                // $scope.localdata.loading = true;
                $scope.localdata.itemIndex = index;
                enableDisableFieldOfInput(true);
                $api.post('v2/shop/serialnumber-of-purchaseorder', body)
                    .then(function (res) {
                        $scope.refreshSerialNumber();
                        $scope.GetExistingPurchaseOrder();
                    })
                    .catch(function (reason) {
                        enableDisableFieldOfInput(false);
                        $scope.localdata.loading = false;
                        console.error("Error while changing for this shop", reason)
                    })
            }
        }

        $scope.updateSerialNumber = function (index) {
            var addedSerialNumber = $scope.purchase_order.items[$scope.itemIndex].inputSerialNumber;
            var lastSerialNumberDetail = $scope.purchase_order.items[$scope.itemIndex].serialNumbers[$scope.childIndex];
            if (addedSerialNumber) {
                var body = {
                    purchaseOrderId: $scope.purchase_order._id,
                    purchaseOrderItemId: $scope.purchase_order.items[index]._id,
                    productId: $scope.purchase_order.items[index].product_id ? $scope.purchase_order.items[index].product_id._id : undefined,
                    shopId: ($scope.purchase_order && $scope.purchase_order.shop && $scope.purchase_order.shop._id ? $scope.purchase_order.shop._id : undefined),
                    lastSerialNumber: lastSerialNumberDetail.serialNumber,
                    serialNumberId: lastSerialNumberDetail.serialNumberId,
                    addedSerialNumber: addedSerialNumber,
                    companyId: $scope.purchase_order.company._id,
                    isShow: true,
                    action: 'Edited',
                    status: $scope.purchase_order.status[0].status,
                    eType: 'company',
                }
                // console.log('index value: ', body);
                // $scope.localdata.loading = true;
                $scope.localdata.itemIndex = index;
                enableDisableFieldOfInput(true);
                $api.put('v2/shop/serialnumber-of-purchaseorder', body)
                    .then(function (res) {
                        $scope.refreshSerialNumber();
                        $scope.GetExistingPurchaseOrder();
                    })
                    .catch(function (reason) {
                        enableDisableFieldOfInput(false);
                        console.error("Error while changing for this shop", reason)
                    })
            }
        }

        $scope.willUpdateSerialNumber = function (index, childIndex) {
            // console.log('willUpdateSerialNumber called', index, childIndex);
            // console.log('$scope.purchase_order.items[index].serialNumbers: ', $scope.purchase_order.items[index].serialNumbers[childIndex]);
            $scope.itemIndex = index;
            $scope.childIndex = childIndex;
            $scope.purchase_order.items[index].isEdit = true;
            $scope.purchase_order.items[index].inputSerialNumber = $scope.purchase_order.items[index].serialNumbers[childIndex].serialNumber;
        }

        $scope.deleteSerialNumber = function (index) {
            var serialNumber = $scope.purchase_order.items[index].serialNumbers[$scope.childIndex];
            var body = {
                purchaseOrderId: $scope.purchase_order._id,
                purchaseOrderItemId: $scope.purchase_order.items[index]._id,
                productId: $scope.purchase_order.items[index].product_id ? $scope.purchase_order.items[index].product_id._id : undefined,
                shopId: ($scope.purchase_order && $scope.purchase_order.shop && $scope.purchase_order.shop._id ? $scope.purchase_order.shop._id : undefined),
                lastSerialNumber: serialNumber.serialNumber,
                serialNumberId: serialNumber.serialNumberId,
                addedSerialNumber: serialNumber.serialNumber,
                companyId: $scope.purchase_order.company._id,
                isShow: false,
                action: 'Deleted',
                status: $scope.purchase_order.status[0].status,
                eType: 'company',
            }
            // console.log('index value: ----------------------', body);
            // $scope.localdata.loading = true;
            $scope.localdata.itemIndex = index;
            enableDisableFieldOfInput(true);
            $api.post('v2/shop/delete-serialnumber-of-purchaseorder', body)
                .then(function (res) {
                    $scope.refreshSerialNumber();
                    $scope.GetExistingPurchaseOrder();
                })
                .catch(function (reason) {
                    enableDisableFieldOfInput(false);
                    console.error("Error while changing for this shop", reason)
                })
        }

        $scope.expandSerialNumber = function (index) {
            console.log('-- expandSerialNumber ', index);
            $scope.purchase_order.items[index].isExpandSerialNumber = true;
            console.log('$scope.purchase_order.items[index].isExpandSerialNumber ', $scope.purchase_order.items[index].isExpandSerialNumber);
        }

        // SERIAL NUMBER END

        $scope.addSendQunatityButton = function (item, index, valueToAdd, isClickedInNewStatus) {
            var status = $scope.purchase_order.companyRemarks.status;
            // console.log('status: ', status);
            if (status === 'New' || status === 'Info' || status === 'Processing') {
                // console.log('inside');
                $scope.purchase_order.companyRemarks.status = "Inspection";
                $scope.mystatus = 3;
            }
            $scope.focusIndex = index;
            $scope.purchase_order.items[index].isSendQuantityEditable = true;
            $scope.purchase_order.items[index].isReadOnly = true;
            $scope.purchase_order.items[index].addSendQuantity = valueToAdd;
            focusPriceInput();
        }

        $scope.savePrice = function (product, index) {
            var productToSend = JSON.parse(JSON.stringify(product));
            productToSend.suggestedRetailerPriceForProduct = productToSend.suggestedRetailerPriceForProduct / (1 + (productToSend.suggestedRetailerPriceVatForProduct / 100)); // As we have to store ex vat in DB
            // console.log('saveprice ', index, productToSend);
            var companyId = $scope.purchase_order.company._id;
            $purchaseOrder.updateCompanyProduct(companyId, productToSend).then(function (response) {
                // console.log('response: ', response);
                $scope.purchase_order.items[index].popoverIsVisible = false;
            }).catch(function (error) {
                console.log('error: ', error);
            });
        }

        $scope.isSendQuantityEditable = function (index) {
            $scope.purchase_order.items[index].isSendQuantityEditable = true;
        }

        $scope.acceptTheRetailerChanges = function () {
            var body = {
                purchaseOrderId: $scope.purchase_order._id,
                companyId: $rootScope.currentCompany._id
            }
            $scope.localdata.loading = true;
            $purchaseOrder.acceptTheRetailerChanges(body).then(function (res) {
                $scope.localdata.loading = false;
                $scope.GetExistingPurchaseOrder();
            }).catch(function (err) {
                $scope.localdata.loading = false;
                $scope.isErrorOccured = true;
                $scope.errorMsg = 'Something went wrong';
            })
        }

        /**
         * ShopId to get Client group
         * Its for prefilling discount of client group
         */

        // CHANGE THE VALUE START
        $scope.changePrice = function (index, priceIncVat, lastPrice, item, isChangingFromViewPage) {
            console.log('---------------------- inside changePrice changePrice!', lastPrice, item);
            $scope.localdata.itemIndex = index;


            // enableDisableFieldOfInput(true);
            if (!priceIncVat) priceIncVat = 0;
            var temppriceIncVat = priceIncVat;
            console.log('temppriceIncVat ', temppriceIncVat);
            console.log(typeof(temppriceIncVat));
            if (typeof priceIncVat === 'string') priceIncVat = parseFloat(priceIncVat.replace(',', '.'));
            var price = priceIncVat;

            // if (typeof priceIncVat === 'string') price = parseFloat(priceIncVat.replace(',', '.'));
            // item.priceIncVat = priceIncVat = price;

            item = $purchaseOrder.convertAllIntegerToDecimal(item, 'pricechange');
            var body = {
                index: index,
                priceIncVat: priceIncVat,
                lastPrice: lastPrice,
                purchase_order: $scope.purchase_order,
                eType: 'company',
                isChangingFromViewPage: isChangingFromViewPage ? true : false,
                lastEntryMethodCustomerValue: $scope.lastEntryMethodCustomerValue ? true : false,
                item: $scope.purchase_order.items[index],
                isSendQuantityChangedAfterFocusingPrice: ($scope.focusIndex !== undefined ? true : false) // Its for focus after send qnt to price
            }

            // console.log('changeSendQuantity: ', index, item);
            if (body.isSendQuantityChangedAfterFocusingPrice) {
                if ($scope.purchase_order.items[index].isDeleted) item.sendQuantity = 0;
                $scope.purchase_order.items[index].oldSendQuantity = item.sendQuantity; // For Backend Old quantity
                $scope.purchase_order.items[index].addSendQuantity = item.addSendQuantity; // Which we are adding right now
                var totalQuantity = (+item.sendQuantity) + (+item.addSendQuantity);
                $scope.purchase_order.items[index].sendQuantity = totalQuantity; // Updating view,
                if ($scope.purchase_order.hasCompanyClickedFirstTime) $scope.purchase_order.isChangeTheStatus = true;
            }

            var purchase_order = body.purchase_order
            var item = JSON.parse(JSON.stringify(purchase_order.items[index]))
            var lastEntryMethodCustomerValue = body.lastEntryMethodCustomerValue
            var priceIncVat = body.priceIncVat
            var lastPrice = body.lastPrice // Ex Vat
            var isChangingFromViewPage = body.isChangingFromViewPage
            var price = priceIncVat / ((item.priceVat / 100) + 1) // To save price ex vat
            // console.log('price ', price);
            if (price != undefined || price != null && price != lastPrice) {
                var bodyPrice = $purchaseOrder.getBodyOfChangePrice(body)
                // console.log('bodyPrice ', bodyPrice);
                var bodySendQuantity = $purchaseOrder.getBodyOfChangeSendQuantity(body) // For the SEND QNT FROM THE COMPANY
                // console.log('bodySendQuantity ', bodySendQuantity);
                if (isChangingFromViewPage) {
                    body.isChangingFromViewPage = false // as only price change not purchasePrice, disccoutn and factor
                    var objectPriceChange = $purchaseOrder.calculateAllValueOnPriceChange(lastEntryMethodCustomerValue, priceIncVat, item) // calculate all changes
                    // console.log('objectPriceChange ', objectPriceChange);
                    body.addedPurchasePrice = objectPriceChange.updatedPurchasePrice
                    body.lastPurchasePrice = item.purchasePrice
                    var bodyPurchaePrice = $purchaseOrder.getBodyOfChangePurchasePrice(body)
                    // console.log('bodyPurchaePrice ', bodyPurchaePrice);

                    body.AddedDiscount = objectPriceChange.updatedMargin
                    body.lastAddedDiscount = item.discount.value
                    var bodyMargin = $purchaseOrder.getBodyOfChangeMargin(body)
                    // console.log('bodyMargin ', bodyMargin);

                    body.addedStorageFactor = objectPriceChange.updatedStorageFactor
                    body.lastStorageFactor = item.storageFactor
                    var bodyStorageFactor = $purchaseOrder.getBodyOfChangeStorageFactor(body)
                    // console.log('bodyStorageFactor ', bodyStorageFactor);
                }

                var bodyReceivedQuantity = {
                    isReceivedQuantityChangedAfterFocusingPrice: false
                }
                if (body.bodyReceivedQuantity) bodyReceivedQuantity = body.bodyReceivedQuantity

                var finalbody = {
                    bodyPrice: bodyPrice,
                    bodyPurchaePrice: bodyPurchaePrice,
                    bodyMargin: bodyMargin,
                    bodyStorageFactor: bodyStorageFactor,
                    bodySendQuantity: bodySendQuantity, // For Focus price updatd from company(DEFAULT FALSE)
                    bodyReceivedQuantity: bodyReceivedQuantity, // For Focus price updatd from Retailer(DEFAULT FALSE)
                    companyId : $rootScope.currentCompany._id
                }

                item.lastAddedPrice = angular.copy(item.price);
                item.lastPriceIncVat = angular.copy(item.priceIncVat);
                item.purchasePrice = parseFloat(parseFloat(finalbody.bodyPurchaePrice.addedPurchasePrice).toFixed(3));
                item.storageFactor = parseFloat(parseFloat(finalbody.bodyStorageFactor.addedStorageFactor).toFixed(3));
                item.priceIncVat = temppriceIncVat;
                item.price = (priceIncVat) / (1 + ($scope.purchase_order.items[index].priceVat / 100));
                item.discount.value = parseFloat(parseFloat(finalbody.bodyMargin.AddedDiscount.value).toFixed(3));
                $scope.purchase_order.items[index] = JSON.parse(JSON.stringify(item));
                // totalValuesCalculation();
                var delayInMilliseconds = 2000; //2 second
                // convertToFloat(index);
                $scope.changePriceBody = finalbody;
                $scope.changePriceBody.lastEntryMethodCustomerValue = $scope.lastEntryMethodCustomerValue;
                setTimeout(function () {
                    $purchaseOrder.changePriceForBrandPortal($scope.changePriceBody).then(function (res) {
                        enableDisableFieldOfInput(false);
                        // emptyAllUpdatedData();
                        // $scope.GetExistingPurchaseOrder();
                    }).catch(function (err) {
                        enableDisableFieldOfInput(false);
                        console.log('error in chagne price: ', err);
                        $scope.localdata.loading = false;
                        $scope.isErrorOccured = true;
                        $scope.errorMsg = 'Something went wrong';
                    })
                }, delayInMilliseconds);
            }
        }

        // If number is more than some 2 digit then ng-value not working so we did this
        $scope.convertToDecimal = function (item, type, lastType, convertType) {
            if (item[type]) {
                if (convertType === 'PRICE') {
                    item.lastPrice = item.price;
                    if (item['priceIncVat']) item['priceIncVat'] = (+item['priceIncVat'].toFixed(2));
                } else {
                    item[lastType] = item[type];
                    // console.log('hello convertDecinal:', type,lastType, item[type], typeof item[type], typeof item[type].toFixed(2))
                    if (item[type]) {
                        item[type] = (+item[type].toFixed(2));
                    }
                }
            }
        }

        $scope.getRoundValue = function (value) {
            return parseFloat(parseFloat(value).toFixed(2));
        }

        $scope.changePurchasePrice = function (index, item, addedPurchasePrice, lastPurchasePrice, isChangingFromViewPage) {
            $scope.localdata.itemIndex = index;
            // enableDisableFieldOfInput(true);
            if (!addedPurchasePrice) addedPurchasePrice = 0;
            var tempAddedPurchasePrice = angular.copy(addedPurchasePrice);
            // if (typeof tempAddedPurchasePrice === 'string') tempAddedPurchasePrice = parseFloat(tempAddedPurchasePrice.replace(',', '.'));
            if (!lastPurchasePrice) lastPurchasePrice = 0;
            if (typeof lastPurchasePrice === 'string') lastPurchasePrice = parseFloat(lastPurchasePrice.replace(',', '.'));
            console.log('addedPurchasePrice ', angular.copy(addedPurchasePrice));
            var purchasePrice = addedPurchasePrice;
            console.log('purchasePrice ', angular.copy(purchasePrice));
            item.purchasePrice = purchasePrice;
            console.log('item.purchasePrice ', angular.copy(item.purchasePrice));
            if (typeof addedPurchasePrice === 'string') purchasePrice = parseFloat(addedPurchasePrice.replace(',', '.'));
            addedPurchasePrice = purchasePrice;
            console.log('addedPurchasePrice ', angular.copy(addedPurchasePrice));

            item = $purchaseOrder.convertAllIntegerToDecimal(item, 'purchasepricechange');
            console.log($scope.lastEntryMethodCustomerValue);
            var body = {
                index: index,
                addedPurchasePrice: addedPurchasePrice,
                lastPurchasePrice: lastPurchasePrice,
                purchase_order: $scope.purchase_order,
                eType: 'company',
                isChangingFromViewPage: isChangingFromViewPage,
                lastEntryMethodCustomerValue: $scope.lastEntryMethodCustomerValue
            }

            // addedPurchasePrice = addedPurchasePrice / ((purchase_order.items[index].priceVat / 100) + 1); // To save addedPurchasePrice ex vat
            var index = body.index
            var purchase_order = body.purchase_order
            var item = purchase_order.items[index]
            var lastEntryMethodCustomerValue = body.lastEntryMethodCustomerValue
            var isChangingFromViewPage = body.isChangingFromViewPage
            var addedPurchasePrice = body.addedPurchasePrice
            var lastPurchasePrice = body.lastPurchasePrice
            var eType = body.eType
            // addedPurchasePrice = addedPurchasePrice / ((purchase_order.items[index].priceVat / 100) + 1); // To save addedPurchasePrice ex vat

            if (addedPurchasePrice != undefined || addedPurchasePrice != null && addedPurchasePrice != lastPurchasePrice) {
                var bodyPurchaePrice = $purchaseOrder.getBodyOfChangePurchasePrice(body)

                if (isChangingFromViewPage) {
                    body.isChangingFromViewPage = false // as only price change not purchasePrice, disccoutn and factor
                    var objectPurchasePriceChange = $purchaseOrder.calculateAllValueOnPurchasePriceChange(lastEntryMethodCustomerValue, addedPurchasePrice, item) // calculate all changes
                    body.priceIncVat = objectPurchasePriceChange.updatedPrice
                    body.lastPrice = item.price
                    var bodyPrice = $purchaseOrder.getBodyOfChangePrice(body)
                    body.AddedDiscount = objectPurchasePriceChange.updatedMargin
                    body.lastAddedDiscount = item.discount.value
                    var bodyMargin = $purchaseOrder.getBodyOfChangeMargin(body)
                    body.addedStorageFactor = objectPurchasePriceChange.updatedStorageFactor
                    body.lastStorageFactor = item.storageFactor
                    var bodyStorageFactor = $purchaseOrder.getBodyOfChangeStorageFactor(body)
                }

                var finalbody = {
                    bodyPurchaePrice: bodyPurchaePrice,
                    bodyPrice: bodyPrice,
                    bodyMargin: bodyMargin,
                    bodyStorageFactor: bodyStorageFactor
                }

                item.lastPurchasePrice = angular.copy(item.purchasePrice);
                item.storageFactor = parseFloat(parseFloat(finalbody.bodyStorageFactor.addedStorageFactor).toFixed(3));
                item.discount.value = parseFloat(parseFloat(finalbody.bodyMargin.AddedDiscount.value).toFixed(3));
                item.priceIncVat = parseFloat(parseFloat(finalbody.bodyPrice.priceIncVat.toFixed(3)));
                item.price = parseFloat(parseFloat((finalbody.bodyPrice.priceIncVat / (1 + ($scope.purchase_order.items[index].priceVat / 100))).toFixed(3)));
                item.purchasePrice = tempAddedPurchasePrice

                $scope.purchase_order.items[index] = JSON.parse(JSON.stringify(item));

                var delayInMilliseconds = 2000; //2 second
                $scope.changePurchasePriceBody = finalbody;
                $scope.changePurchasePriceBody.lastEntryMethodCustomerValue = $scope.lastEntryMethodCustomerValue;
                setTimeout(function () {
                    $purchaseOrder.changePurchasePriceForBrandPortal($scope.changePurchasePriceBody).then(function (res) {
                        enableDisableFieldOfInput(false);
                        // $scope.GetExistingPurchaseOrder();
                    }).catch(function (err) {
                        enableDisableFieldOfInput(false);
                        console.log('erroors: ', err);
                        $scope.isErrorOccured = true;
                        $scope.errorMsg = 'Something went wrong';
                    })
                }, delayInMilliseconds);
            }
        }

        $scope.changeDiscount = function (index, item, value, lastValue, isSwitch, isChangingFromViewPage) {
            $scope.localdata.itemIndex = index;
            if (!value) value = 0;
            var tempValue = angular.copy(value);
            // enableDisableFieldOfInput(true);
            $scope.purchase_order.items[index].lastAddedDiscount = angular.copy(item.discount);
            item.lastAddedDiscount = angular.copy(item.discount);
            var margin = value;
            if (margin && margin.trim() == "") return;
            if (margin && margin.indexOf(',') !== -1) margin = margin.replace(',', '.');
            if (margin && margin.indexOf('.') != margin.lastIndexOf('.')) {
                var tempVal = margin.split(/\.(?=[^\.]+$)/);
                margin = tempVal[0];
            }
            if (typeof margin === 'string') margin = parseFloat(margin);
            value = margin;
            item.discount.value = margin;

            item = $purchaseOrder.convertAllIntegerToDecimal(item, 'marginchange');
            var body = {
                index: index,
                purchase_order: $scope.purchase_order,
                AddedDiscount: value,
                lastAddedDiscount: lastValue,
                eType: 'company',
                isChangingFromViewPage: isChangingFromViewPage ? true : false,
                lastEntryMethodCustomerValue: $scope.lastEntryMethodCustomerValue ? true : false
            }

            var purchase_order = body.purchase_order
            var item = purchase_order.items[index]
            var lastEntryMethodCustomerValue = $scope.lastEntryMethodCustomerValue ? true : false
            var isChangingFromViewPage = isChangingFromViewPage ? true : false
            var eType = 'retailer'
            var AddedDiscount = value
            var lastAddedDiscount = lastValue

            if (AddedDiscount != undefined || AddedDiscount != null && AddedDiscount != lastAddedDiscount) {
                var bodyMargin = $purchaseOrder.getBodyOfChangeMargin(body)
                if (isChangingFromViewPage) {
                    body.isChangingFromViewPage = false // as only MARGIN WILL CHANGE SO OTHER ITS NEEDS TO BE FALSE
                    var objectDiscountChange = $purchaseOrder.calculateAllValueOnMarginChange(lastEntryMethodCustomerValue, AddedDiscount, item) // calculate all changes
                    if (lastEntryMethodCustomerValue) {
                        body.addedPurchasePrice = objectDiscountChange.updatedPurchasePrice
                        body.lastPurchasePrice = item.purchasePrice
                        var bodyPurchaePrice = $purchaseOrder.getBodyOfChangePurchasePrice(body)
                    } else {
                        body.priceIncVat = objectDiscountChange.updatedPrice
                        body.lastPrice = item.price
                        var bodyPrice = $purchaseOrder.getBodyOfChangePrice(body)
                    }

                    body.addedStorageFactor = objectDiscountChange.updatedStorageFactor
                    body.lastStorageFactor = item.storageFactor
                    var bodyStorageFactor = $purchaseOrder.getBodyOfChangeStorageFactor(body)
                }

                var finalbody = {
                    bodyMargin: bodyMargin,
                    bodyStorageFactor: bodyStorageFactor,
                    companyId: $rootScope.currentCompany._id
                }
                if (lastEntryMethodCustomerValue) {
                    finalbody.bodyPurchaePrice = bodyPurchaePrice
                    finalbody.bodyPrice = { lastEntryMethodCustomerValueFromDiscount: false }
                } else {
                    finalbody.bodyPrice = bodyPrice
                    finalbody.bodyPurchaePrice = { lastEntryMethodCustomerValueFromDiscount: false }
                }

                item.purchasePrice = parseFloat(parseFloat(finalbody.bodyPurchaePrice.addedPurchasePrice).toFixed(3));
                item.storageFactor = parseFloat(parseFloat(finalbody.bodyStorageFactor.addedStorageFactor).toFixed(3));
                item.discount.value = tempValue;
                $scope.purchase_order.items[index] = JSON.parse(JSON.stringify(item));
                // totalValuesCalculation();
                var delayInMilliseconds = 2000; //2 second
                $scope.changeDiscountBody = finalbody;
                $scope.changeDiscountBody.lastEntryMethodCustomerValue = $scope.lastEntryMethodCustomerValue;
                if(finalbody.bodyMargin.AddedDiscount.value == finalbody.bodyMargin.lastAddedDiscount.value) return;
                setTimeout(function () {
                    $purchaseOrder.changeDiscountForBrandPortal($scope.changeDiscountBody).then(function (res) {
                        enableDisableFieldOfInput(false);
                        // $scope.GetExistingPurchaseOrder();
                    }).catch(function (err) {
                        enableDisableFieldOfInput(false);
                        console.log('error in chagne marign: ', err);
                        $scope.localdata.loading = false;
                        $scope.isErrorOccured = true;
                        $scope.errorMsg = 'Something went wrong';
                    })
                }, delayInMilliseconds);
            }
        }

        $scope.changePriceVat = function (index, item, priceVat, lastPriceVat, isChangingFromViewPage) {
            item = $purchaseOrder.convertAllIntegerToDecimal(item, 'pricevatchange');
            $scope.localdata.itemIndex = index;
            // enableDisableFieldOfInput(true);
            var body = {
                index: index,
                purchase_order: $scope.purchase_order,
                lastPriceVat: lastPriceVat || 0,
                addedPriceVat: priceVat,
                eType: 'company',
                isChangingFromViewPage: isChangingFromViewPage ? true : false,
                lastEntryMethodCustomerValue: $scope.lastEntryMethodCustomerValue ? true : false
            }

            var purchase_order = body.purchase_order
            var item = purchase_order.items[index]
            var lastEntryMethodCustomerValue = body.lastEntryMethodCustomerValue
            var isChangingFromViewPage = body.isChangingFromViewPage
            var eType = body.eType
            var lastPriceVat = body.lastPriceVat || 0
            var addedPriceVat = body.addedPriceVat

            if (addedPriceVat != undefined || addedPriceVat != null && addedPriceVat != lastPriceVat) {
                var bodyPriceVat = $purchaseOrder.getBodyOfChangePriceVat(body)

                if (isChangingFromViewPage) {
                    body.isChangingFromViewPage = false // as only PRICEVAT WILL CHANGE SO OTHER ITS NEEDS TO BE FALSE
                    var objectPriceVatChange = $purchaseOrder.calculateAllValueOnPriceVatChange(lastEntryMethodCustomerValue, addedPriceVat, lastPriceVat, item) // calculate all changes

                    if (lastEntryMethodCustomerValue) {
                        body.priceIncVat = objectPriceVatChange.updatedPrice
                        body.lastPrice = item.price
                        var bodyPrice = $purchaseOrder.getBodyOfChangePrice(body)

                        body.addedStorageFactor = objectPriceVatChange.updatedStorageFactor || 0;
                        body.lastStorageFactor = item.storageFactor || 0;
                        var bodyStorageFactor = $purchaseOrder.getBodyOfChangeStorageFactor(body)
                    } else {
                        body.AddedDiscount = objectPriceVatChange.updatedMargin
                        body.lastAddedDiscount = item.discount.value
                        var bodyMargin = $purchaseOrder.getBodyOfChangeMargin(body)

                        body.priceIncVat = objectPriceVatChange.updatedPriceIncVat
                        body.lastPrice = item.price
                        var bodyPrice = $purchaseOrder.getBodyOfChangePrice(body)

                        body.addedStorageFactor = objectPriceVatChange.updatedStorageFactor
                        body.lastStorageFactor = item.storageFactor
                        var bodyStorageFactor = $purchaseOrder.getBodyOfChangeStorageFactor(body)
                    }
                }

                var finalbody = {
                    bodyPriceVat: bodyPriceVat,
                    companyId: $rootScope.currentCompany._id
                }
                if (lastEntryMethodCustomerValue) {
                    finalbody.bodyPrice = bodyPrice
                    finalbody.bodyStorageFactor = bodyStorageFactor
                    finalbody.bodyMargin = { lastEntryMethodCustomerValueFromPriceVat: false }
                } else {
                    finalbody.bodyMargin = bodyMargin
                    finalbody.bodyPrice = bodyPrice
                    finalbody.bodyStorageFactor = bodyStorageFactor
                }

                item.storageFactor = bodyStorageFactor.addedStorageFactor;
                // return;
                $scope.purchase_order.items[index] = JSON.parse(JSON.stringify(item));
                var delayInMilliseconds = 2000; //2 second
                $scope.changePriceVatBody = finalbody;
                $scope.changePriceVatBody.lastEntryMethodCustomerValue = $scope.lastEntryMethodCustomerValue;
                setTimeout(function () {
                    $purchaseOrder.changePriceVat($scope.changePriceVatBody).then(function (res) {
                        // $scope.localdata.loading = false;
                        enableDisableFieldOfInput(false);
                        // $scope.GetExistingPurchaseOrder($scope.limit);
                        // $scope.GetExistingPurchaseOrderItemHistory(10, 0, 0);
                    }).catch(function (err) {
                        enableDisableFieldOfInput(false);
                        console.log('error in chagne pricevat: ', err);
                        $scope.localdata.loading = false;
                        $scope.isErrorOccured = true;
                        $scope.errorMsg = 'Something went wrong';
                    })
                }, delayInMilliseconds);
            }
        }

        $scope.changeStorageFactor = function (index, item, addedStorageFactor, lastStorageFactor, isChangingFromViewPage) {
            $scope.localdata.itemIndex = index;
            if (!addedStorageFactor) addedStorageFactor = 0;
            var tempStorageFactor = angular.copy(addedStorageFactor);
            // console.log('tempStorageFactor ', angular.copy(tempStorageFactor));
            var storageFactor = addedStorageFactor;
            if (typeof addedStorageFactor === 'string') storageFactor = parseFloat(addedStorageFactor.replace(',', '.'));
            addedStorageFactor = storageFactor;
            item.storageFactor = storageFactor;

            item = $purchaseOrder.convertAllIntegerToDecimal(item, 'storagefactorchange');
            var body = {
                index: index,
                addedStorageFactor: addedStorageFactor,
                lastStorageFactor: lastStorageFactor,
                purchase_order: $scope.purchase_order,
                eType: 'company',
                lastEntryMethodCustomerValue: $scope.lastEntryMethodCustomerValue,
                isChangingFromViewPage: isChangingFromViewPage,
                companyId: $rootScope.currentCompany._id
            }
            var index = body.index
            var purchase_order = body.purchase_order
            var item = purchase_order.items[index]
            var lastEntryMethodCustomerValue = body.lastEntryMethodCustomerValue
            var isChangingFromViewPage = body.isChangingFromViewPage
            var addedStorageFactor = body.addedStorageFactor
            var lastStorageFactor = body.lastStorageFactor
            var eType = body.eType

            if (addedStorageFactor != undefined || addedStorageFactor != null && addedStorageFactor != lastStorageFactor) {
                var bodyStorageFactor = $purchaseOrder.getBodyOfChangeStorageFactor(body)

                if (isChangingFromViewPage) {
                    body.isChangingFromViewPage = false // as only price change not purchasePrice, disccoutn and factor
                    var objectStorageFactorChange = $purchaseOrder.calculateAllValueOnStorageFactorChange(lastEntryMethodCustomerValue, addedStorageFactor, item) // calculate all changes
                    if (lastEntryMethodCustomerValue) {
                        body.addedPurchasePrice = objectStorageFactorChange.updatedPurchasePrice
                        body.lastPurchasePrice = item.purchasePrice
                        var bodyPurchaePrice = $purchaseOrder.getBodyOfChangePurchasePrice(body)
                    } else {
                        body.priceIncVat = objectStorageFactorChange.updatedPrice
                        body.lastPrice = item.price
                        var bodyPrice = $purchaseOrder.getBodyOfChangePrice(body)
                    }

                    body.AddedDiscount = objectStorageFactorChange.updatedMargin
                    body.lastAddedDiscount = item.discount.value
                    var bodyMargin = $purchaseOrder.getBodyOfChangeMargin(body)
                }

                var finalbody = {
                    bodyStorageFactor: bodyStorageFactor,
                    bodyMargin: bodyMargin
                }
                if (lastEntryMethodCustomerValue) {
                    finalbody.bodyPurchaePrice = bodyPurchaePrice
                    finalbody.bodyPrice = { lastEntryMethodCustomerValueFromStorageFactor: false }
                } else {
                    finalbody.bodyPrice = bodyPrice
                    finalbody.bodyPurchaePrice = { lastEntryMethodCustomerValueFromStorageFactor: false }
                }

                item.lastStorageFactor = angular.copy(item.storageFactor);
                item.storageFactor = tempStorageFactor;
                item.discount.value = parseFloat(parseFloat(finalbody.bodyMargin.AddedDiscount.value).toFixed(3));
                item.priceIncVat = parseFloat(parseFloat(finalbody.bodyPrice.priceIncVat.toFixed(3)));
                item.price = parseFloat(parseFloat((finalbody.bodyPrice.priceIncVat / (1 + ($scope.purchase_order.items[index].priceVat / 100))).toFixed(3)));
                $scope.purchase_order.items[index] = JSON.parse(JSON.stringify(item));
                var delayInMilliseconds = 2000; //2 second
                $scope.changeStorageFactorBody = finalbody;
                $scope.changeStorageFactorBody.lastEntryMethodCustomerValue = $scope.lastEntryMethodCustomerValue;
                setTimeout(function () {
                    $purchaseOrder.changeStorageFactor($scope.changeStorageFactorBody).then(function (res) {
                        // $scope.GetExistingPurchaseOrder();
                    }).catch(function (err) {
                        enableDisableFieldOfInput(false);
                        $scope.isErrorOccured = true;
                        $scope.errorMsg = 'Something went wrong';
                    })
                }, delayInMilliseconds);
            }
        }

        $scope.changeInvoiceNumber = function () {
            // console.log('lastInvoiceNumber: ', $scope.purchase_order.lastInvoiceNumber);
            $scope.inverr = false;
            var body = {
                purchaseOrderId: $scope.purchase_order._id,
                lastInvoiceNumber: $scope.purchase_order.lastInvoiceNumber || '',
                newInvoiceNumber: $scope.purchase_order.invoiceNumber,
                eType: 'supplier',
                status: $scope.purchase_order.status[0].status,
                isShow: true
            }
            // console.log('changeInvoiceNumber body: ', body);
            $purchaseOrder.changeInvoiceNumber(body, undefined, $rootScope.currentCompany._id).then(function (res) {
                $scope.localdata.loading = false;
                $scope.GetExistingPurchaseOrder();
            }).catch(function (err) {
                $scope.localdata.loading = false;
                $scope.inverr = true;
                $scope.invmessage = 'Something went wrong';
                if (err && err.data && err.data.message) {
                    $scope.invmessage = err.data.message;
                }
            })
        }

        $scope.changeSendQuantity = function (item, index) {
            console.log('changeSendQuantity: ', index, item);
            if (item.isDeleted) {
                item.sendQuantity = 0;
                item.addSendQuantity = Math.floor(item.addSendQuantity);
            } else {
                item.addSendQuantity = (item.addSendQuantity - item.sendQuantity);
                item.addSendQuantity = Math.floor(item.addSendQuantity);
            }
            $scope.purchase_order.items[index].isSendQuantityEditable = false;
            if (item.addSendQuantity) {
                console.log('----------- if!');
                console.log('item.addSendQuantity ', item.addSendQuantity);
                console.log('item.sendQuantity ', item.sendQuantity);

                var totalQuantity = (+item.sendQuantity) + (+item.addSendQuantity);
                console.log('totalQuantity ', totalQuantity);
                if (item.quantity >= 0) {
                    console.log('item quantity: in plus: ', item.addSendQuantity);
                    if (item.addSendQuantity) {
                        // console.log(totalQuantity, item.quantity);
                        // This condition implies that ORDERED quantity must be a greater than the send quantity.(As Brand can send what customer(Retailer) needs)
                        if (item.quantity >= totalQuantity) {
                            UpdateTheSendQunatity();
                        } else {
                            $scope.isErrorOccured = true;
                            $scope.errorMsg = 'New quantity cannot be greater than current quantity.';
                        }
                    }
                } else {
                    // var totalQuantity = (+ item.sendQuantity) + (+ item.addSendQuantity);
                    console.log('item quantity: in minus', item.quantity, item.addSendQuantity);
                    if (item.addSendQuantity && item.addSendQuantity <= 0 && item.quantity <= item.addSendQuantity) {
                        UpdateTheSendQunatity();
                    } else {
                        $scope.isErrorOccured = true;
                        $scope.errorMsg = 'Enter proper value';
                    }
                }

                function UpdateTheSendQunatity() {
                    console.log('-------------- UpdateTheSendQunatity!!');
                    console.log('item.sendQuantity ', item.sendQuantity);
                    console.log('totalQuantity ', totalQuantity);
                    $scope.isErrorOccured = false;
                    $scope.purchase_order.items[index].oldSendQuantity = item.sendQuantity; // For Backend Old quantity
                    $scope.purchase_order.items[index].sendQuantity = totalQuantity; // Updating view
                    // $scope.purchase_order.isChangeTheStatus = true; // For sending notification to Retaier
                    if ($scope.purchase_order.hasCompanyClickedFirstTime) $scope.purchase_order.isChangeTheStatus = true;

                    $scope.purchase_order.items[index].addSendQuantity = item.addSendQuantity; // Which we are adding right now
                    $scope.purchase_order.indexToUpdate = index;
                    // $scope.localdata.loading = true;
                    checkhasNotSameSendQntAsOrderQnt();
                    $scope.localdata.itemIndex = index;
                    // enableDisableFieldOfInput(true);
                    $purchaseOrder.changeSendQuantity($scope.purchase_order, index).then(function (res) {
                        // $scope.GetExistingPurchaseOrder();
                    }).catch(function (err) {
                        enableDisableFieldOfInput(false);
                        $scope.isErrorOccured = true;
                        $scope.errorMsg = 'Something went wrong';
                    })
                    item.addSendQuantity = 0;
                }
            } else {
                $scope.localdata.loading = false;
                $scope.isErrorOccured = true;
                $scope.errorMsg = 'Enter proper value';
            }
        }
        // CHANGE THE VALUE END

        $scope.sentAllProductsQuantity = function () {
            console.log('---------- sentAllProductsQuantity!!');
            var body = {
                _id: $scope.purchase_order._id,
                companyId: $rootScope.currentCompany._id
            }
            $scope.sendAllLoading = true;
            $purchaseOrder.allProductsSentInBrandPurchaseOrder(body).then(function (response) {
                // console.log('Updated Successfully');
                $scope.GetExistingPurchaseOrder();
            }).catch(function (error) {
                $scope.sendAllLoading = false;
                console.log('error while changing all sent qnt', error);
            });
        }

        // Prefilling the Price, PurchasePrice, Margin, storageFactor and Entrycustomervalue when adding new product into purchase order
        // suggestedRetailPrice → purchaseOrderItem.price
        // company.product.price → purchaseOrderItem.purchasePrice
        function prefillTheValueWhenAddingProduct() {
            //console.log('PREFILL PRODUCT EXIST IN DB', $scope.prefillPurchaseOrderItemData);
            // If product.suggestedRetailerPrice and company.product.price are available
            if ($scope.prefillPurchaseOrderItemData && $scope.prefillPurchaseOrderItemData.companyProduct && $scope.prefillPurchaseOrderItemData.companyProduct.price && $scope.prefillPurchaseOrderItemData.suggestedRetailPrice) {
                $scope.prefillPurchaseOrderItemData = $prefillCalculationForPurchasOrderAndCashRegister.calculateWhenBothPriceAvailableInCompanyProduct($scope.prefillPurchaseOrderItemData, $scope.firstPriceVat);
                //console.log('IF:  prefillPurchaseOrderItemData called: ', $scope.prefillPurchaseOrderItemData);
                fetchProductDiscountUsingClientGroup();
            } else if ($scope.shopsClientGroups) { // If client group is available then calculation based on that
                $scope.prefillPurchaseOrderItemData = $prefillCalculationForPurchasOrderAndCashRegister.calculateWhenClientGroupAvailable($scope.prefillPurchaseOrderItemData, $scope.firstPriceVat, $scope.shopsClientGroups);
                //console.log('ELSE IF:  prefillPurchaseOrderItemData called: ', $scope.prefillPurchaseOrderItemData);
                fetchProductDiscountUsingClientGroup();
            } else { // Take default value if nothing there
                //console.log('ELSE: prefillPurchaseOrderItemData called: ', $scope.prefillPurchaseOrderItemData);
                $scope.prefillPurchaseOrderItemData = $prefillCalculationForPurchasOrderAndCashRegister.calculateWithDefaultValue($scope.prefillPurchaseOrderItemData);
                // calculateWithDefaultValue();
            }
        }

        function fetchProductDiscountUsingClientGroup() {
            return $q(function (resolve, reject) {

                try {
                    $scope.loading = true;
                    var body = {
                        productId: $scope.offerItem.product_id,
                        companyId: $scope.purchase_order.company._id,
                        purchasePrice: $scope.prefillPurchaseOrderItemData.purchasePrice,
                        clientGroupId: $scope.shopsClientGroups && $scope.shopsClientGroups._id ? $scope.shopsClientGroups._id : undefined
                    }

                    //console.log('fetchProductDiscountUsingClientGroup called: ', body);
                    $purchaseOrder.fetchProductDiscountUsingClientGroup(body).then(function (response) {
                        //console.log('Controller response: ', response);
                        $scope.loading = false;
                        if (response > 0) $scope.prefillPurchaseOrderItemData.purchasePrice = response;
                    }).catch(function (error) {
                        $scope.loading = false;
                    });
                } catch (e) {
                    $scope.loading = false;
                }
            })
        }

        // Used for which logs to be stored and checking for valid log

        // It's adding log to purchase order
        function AddTheLog(text, isFor) {
            if (!$scope.purchase_order.log || !$scope.purchase_order.log.length) $scope.purchase_order.log = [];
            $scope.purchase_order.log.push({
                text: text,
                userId: $rootScope.user._id,
                eType: 'supplier',
                isFor: isFor,
                createdAt: new Date()
            })
        }

        function GetTheLog(text, isFor) {
            if (!$scope.purchase_order.log || !$scope.purchase_order.log.length) $scope.purchase_order.log = [];
            var log = {
                text: text,
                userId: $rootScope.user._id,
                eType: 'supplier',
                isFor: isFor,
                createdAt: new Date()
            }
            $scope.purchase_order.log.push(log);
            return log;
        }

        function focusPriceInput() {
            if ($scope.focusIndex !== undefined) {
                angular.element(document).ready(function () {
                    // console.log('call focus: ', $scope.focusIndex, "priceId" + $scope.focusIndex);
                    var focusPriceelement = document.getElementById("priceId" + $scope.focusIndex);
                    // console.log('focusPriceelement: ', focusPriceelement);
                    if (focusPriceelement) {
                        focusPriceelement.focus();
                        focusPriceelement.select();
                    }
                })
            }
        }

        /**
         * Its for the have to expandable view or not
         * If serial number length more than one then it will not expandable otherwise its expandable if no serialnumber
         */
        function isExpandSerialNumberOrNot() {
            console.log('isExpandSerialNumberOrNot called');
            if ($scope.purchase_order && $scope.purchase_order.items && $scope.purchase_order.items.length) {
                var purchaseOrderItems = $scope.purchase_order.items;
                var purchaseOrderItemsLength = $scope.purchase_order.items.length;

                // Its for total Of Ex vat and Inc vat
                $scope.purchasePriceExVat = 0;
                $scope.purchasePriceIncVat = 0;

                $scope.localdata.isAnyPurchaseOrderItemDeleted = false;
                // console.log('isAnyPurchaseOrderItemDeleted: 1', $scope.localdata.isAnyPurchaseOrderItemDeleted);
                for (var j = 0; j < purchaseOrderItemsLength; j++) {
                    // console.log(j, purchaseOrderItems[j].serialNumbers);
                    purchaseOrderItems[j].isExpandSerialNumber = false;
                    if (purchaseOrderItems[j].serialNumbers && purchaseOrderItems[j].serialNumbers.length &&
                        purchaseOrderItems[j].serialNumbers[0].serialNumber) {
                        purchaseOrderItems[j].isExpandSerialNumber = true;
                    }

                    purchaseOrderItems[j].priceIncVat = (purchaseOrderItems[j].price * (1 + (purchaseOrderItems[j].priceVat / 100))) // To show inc vat in purchase order rows

                    if (!purchaseOrderItems[j].isDeleted) {
                        // Its for total Of Ex vat and Inc vat
                        $scope.purchasePriceExVat += purchaseOrderItems[j].purchasePrice * purchaseOrderItems[j].quantity;
                        $scope.purchasePriceIncVat += (purchaseOrderItems[j].purchasePrice * purchaseOrderItems[j].quantity * (((purchaseOrderItems[j].priceVat / 100) + 1)));
                    }
                    // console.log('isAnyPurchaseOrderItemDeleted: 1.5 ', i, $scope.localdata.isAnyPurchaseOrderItemDeleted, purchaseOrderItems[j].isDeleted);
                    // CHECKING IF ANY OF ONE ITEM IS DELETED OR NOT
                    if (!$scope.localdata.isAnyPurchaseOrderItemDeleted && purchaseOrderItems[j].isDeleted) {
                        // console.log('isAnyPurchaseOrderItemDeleted: 2', $scope.localdata.isAnyPurchaseOrderItemDeleted);
                        $scope.localdata.isAnyPurchaseOrderItemDeleted = true;
                    }
                }

                // console.log('isAnyPurchaseOrderItemDeleted: 3', $scope.localdata.isAnyPurchaseOrderItemDeleted);
            }

            // Its for total Of Ex vat and Inc vat
            if ($scope.purchasePriceExVat) $scope.purchasePriceExVat = $scope.purchasePriceExVat.toFixed(2);
            if ($scope.purchasePriceIncVat) $scope.purchasePriceIncVat = $scope.purchasePriceIncVat.toFixed(2);

        }

        function timerForInvoice() {
            if ($scope.purchase_order && $scope.purchase_order.invoiceCountDown && !$scope.purchase_order.invoiceCountDown.isProFormaInvoice &&
                $scope.purchase_order.invoiceCountDown.endCountDownTime) {
                var milliseconds = (new Date($scope.purchase_order.invoiceCountDown.endCountDownTime).getTime()) - new Date().getTime();
                // console.log('milliseconds: ', milliseconds);
                $scope.isCountDownFinished = false;
                if (milliseconds >= 0) {
                    $interval(countDown, 1000);
                } else {
                    $scope.isCountDownFinished = true;
                    $scope.timer = 'completed';
                }
            }
        }

        function countDown() {
            // console.log('countDown: ', $scope.purchase_order.invoiceCountDown.endCountDownTime);
            var milliseconds = (new Date($scope.purchase_order.invoiceCountDown.endCountDownTime).getTime()) - new Date().getTime();
            if (milliseconds >= 0) {
                // console.log('if milliseconds: ', milliseconds);
                var seconds = milliseconds / 1000;
                var days = Math.floor(seconds / (60 * 60 * 24));
                var hoursLeft = Math.floor((seconds) - (days * 86400));
                var hours = Math.floor(hoursLeft / 3600);
                var minutesLeft = Math.floor((hoursLeft) - (hours * 3600));
                var minutes = Math.floor(minutesLeft / 60);
                var remainingSeconds = seconds % 60;

                function pad(n) {
                    return (n < 10 ? "0" + n : n);
                }
                // var curretnTimer = days + " days, " + pad(hours) + ":" + pad(minutes) + ":" + pad(remainingSeconds.toFixed(0));
                var curretnTimer = pad(hours) + ":" + pad(minutes) + ":" + pad(remainingSeconds.toFixed(0));
                // console.log('timer: ', curretnTimer);
                $scope.timer = curretnTimer;
            } else {
                $scope.isCountDownFinished = true;
                $scope.timer = 'completed';
            }
        }

        // Finding shop.companies value of the margin etc.
        function findShopEntryCustomerValue() {
            console.log('findCurrentCompaniesEntryCustomerValue: ');
            if ($scope.purchase_order && $scope.purchase_order.shopDetail &&
                $scope.purchase_order.shopDetail.companies && $scope.purchase_order.shopDetail.companies.length) {
                var shopCompanies = $scope.purchase_order.shopDetail.companies;
                // console.log('companis: ', shopCompanies);
                findShopCompaniesEntryMethodValue(shopCompanies);
            }
        }

        function findShopCompaniesEntryMethodValue(shopCompanies) {
            console.log('-- findShopCompaniesEntryMethodValue!!');
            var purchaseOrderCompany = $scope.purchase_order.company;
            for (var i = 0; i < shopCompanies.length; i++) {
                // console.log('checking company: ', shopCompanies[i], company._id);
                if (shopCompanies[i] && shopCompanies[i]._id && purchaseOrderCompany._id &&
                    (shopCompanies[i]._id).toString() == (purchaseOrderCompany._id).toString()) {
                    $scope.entryMethodCustomerValue = shopCompanies[i].entryMethodCustomerValue ? true : false;
                    $scope.margin = shopCompanies[i].purchaseMargin ? shopCompanies[i].purchaseMargin : 0;
                    // console.log('found: findShopCompaniesEntryMethodValue ', $scope.entryMethodCustomerValue, $scope.margin);
                    if (shopCompanies[i].entryMethodCustomerValue !== undefined) {
                        // console.log('helelo there: ', shopCompanies[i].entryMethodCustomerValue, typeof shopCompanies[i].entryMethodCustomerValue);
                        if (!$scope.offerItem) $scope.offerItem = {
                            quantity: 1
                        };
                        $scope.offerItem.entryMethodCustomerValue = shopCompanies[i].entryMethodCustomerValue;
                    }
                }
            }
        }

        function lastEntryCustomerValueOfPurchaseOrderItems() {
            var purchaseOrderItems = $scope.purchase_order.items;
            var purchaseOrderItemsLength = purchaseOrderItems.length;
            if (purchaseOrderItemsLength) {
                $scope.lastEntryMethodCustomerValue = purchaseOrderItems[purchaseOrderItemsLength - 1].entryMethodCustomerValue || false;
            }
            // console.log('$scope.lastEntryMethodCustomerValue: ', $scope.lastEntryMethodCustomerValue);
            if (!$scope.lastEntryMethodCustomerValue) {
                $scope.localdata.showCalculationMargin = false;
            } else {
                $scope.localdata.showCalculationMargin = true;
            }
        }

        // If all are same then only user can put status into completed
        function checkhasNotSameSendQntAsOrderQnt() {
            // If the send quantity is same as the ordered quantity then user can put status in completed othewise can't
            // console.log('hasnot same: ')
            $scope.localdata.hasNotSameSendQntAsOrderQnt = false;
            if ($scope.purchase_order && $scope.purchase_order.items && $scope.purchase_order.items.length) {
                for (var i = 0; i < $scope.purchase_order.items.length; i++) {
                    if ($scope.purchase_order.items[i].isDeleted) continue;
                    if ((($scope.purchase_order.items[i].quantity) - ($scope.purchase_order.items[i].sendQuantity) !== 0)) {
                        $scope.localdata.hasNotSameSendQntAsOrderQnt = true;
                        break;
                    }
                }
            }
        }

        // Whenever adding comment to the then we have add new line when pressed (SHIFT + ENTER) and if only enter then add Comment
        $scope.addCommentKeyUp = function (event, currentremark) {
            if (event.keyCode === 13) {
                if (event.shiftKey) return false;
                else $scope.AddComment(currentremark);
            }
        }

        $scope.purchaseOrderPaid = function (isCompanyConfirmed) {
            var body = {
                purchaseOrderId: $scope.purchase_order._id,
                confirmPayment: {
                    isCompanyConfirmed: isCompanyConfirmed,
                    isRetailerConfirmed: $scope.purchase_order.confirmPayment.isRetailerConfirmed || false
                },
                isFromRetailer: false,
                companyId: $rootScope.currentCompany._id
            }
            $scope.localdata.loading = true;
            $purchaseOrder.purchaseOrderPaid(body).then(function (response) {
                $scope.localdata.loading = false;
                // console.log('Purchase Order paid successfully updated');
            }).catch(function (error) {
                $scope.localdata.loading = false;
                console.log('error: ', error);
            })
        }

        // Using this function we can update any date so just need to pass and key to update and its value and its type as well for retailer or company
        $scope.updatePurchaseOrderDate = function (date, type, key) {
            var body = {
                purchaseOrderId: $scope.purchase_order._id,
                type: type,
                key: key,
                value: date,
                companyId: $rootScope.currentCompany._id
            }
            $scope.localdata.loading = true;
            $purchaseOrder.updatePurchaseOrderDate(body).then(function (response) {
                $scope.localdata.loading = false;
                // console.log('company backorder delivery date updated successfully');
            }).catch(function (error) {
                $scope.localdata.loading = false;
                console.log('something went wrong while updateing company backorder delivery date: ', error);
            });
        }

        $scope.autoScrollcomment = function () {
            setTimeout(function () {
                $('#messagewindow').animate({
                    scrollTop: $('#messagewindow')[0].scrollHeight
                }, 0);
            }, 200)
        }

        $scope.changeSendEmail = function (selected) {
            setTimeout(function () {
                $scope.$apply(function () {
                    $scope.localdata.isEmailChosen = true;
                    $scope.purchase_order.shopDetail.email = selected;
                    $scope.emailSelected = true
                    $scope.ngModel = selected.code;
                });
            }, 50);
        }

        $scope.hideEmployeeEmailAddress = function (email) {
            if (email.email) {
                var last4Char = email.email.slice(-4);
                var first3Char = email.email.substring(0, 3);
                email.email = first3Char + last4Char.padStart(email.email.length - 3, '*');
            } else {
                email.email = '';
            }
            return (email.fullName || ' ') + ' (' + (email.email || ' ') + ')' + (email.isCustomEmail ? ' Custom Email ' : '');
        }

        $scope.recommendationOrderPage = function () {
            if ($rootScope.isPurchaseOrderNew && $scope.selectedShop) {
                $rootScope.recommendationShop = $scope.selectedShop;
                console.log('recommendationOrderPage called: ', $stateParams.orderNo, $scope.selectedShop);
                $state.go('brand.recommendation-order', { orderNo: '' });
            } else if ($scope.purchase_order.number) {
                $state.go('brand.recommendation-order', { orderNo: $scope.purchase_order.number });
            }
        }

        function enableDisableFieldOfInput(isUpdating) {
            console.log('---------------------- enableDisableFieldOfInput!!', isUpdating);
            if ($scope.purchase_order && $scope.purchase_order.items && $scope.purchase_order.items.length) {
                $scope.purchase_order.items[$scope.localdata.itemIndex].isUpdating = isUpdating;
            }
        }

        $scope.openChooseBrandForNewProduct = function (search_value) {
            // console.log("Open Brand selection modal");
            return $q(function (resolve, reject) {
                // console.log("search_value", search_value);
                var modalInstance = $uibModal.open({
                    controller: 'purchaseOrderProductBrandSelectionModalController',
                    templateUrl: '../views/modal/purchaseOrder-product-brand-selection-modal.html',
                    size: 'lg',
                    resolve: {
                        search_value: function () {
                            return search_value;
                        }
                    }
                });

                modalInstance.result
                    .then(function (response) {
                        response.product.companyAppliedStartingDigits = $rootScope.currentCompany.startingDigits;
                        console.log("product handling guy Check here!", response);
                        $scope.hits = [];

                        if ($scope.offerItem) $scope.offerItem.quantity = 1;
                        else $scope.offerItem = { quantity: 1 };

                        $scope.hideHits = false;
                        $scope.localdata.search_value = response.product.productNumber

                        $scope.unofficialProduct = response.product;
                        $('#offercomment').focus();
                        $scope.offerItem.comment = (response.product.brand && response.product.brand.name) ? response.product.brand.name + ' ' + response.product.insertedProductNumber : response.product.productNumber
                        // if ($scope.unofficialProduct.productNumber) $scope.localdata.search_value = $scope.unofficialProduct.productNumber;
                    })
                    .catch(function (reason) {
                        console.log('error:   ', reason);
                        reject(reason);
                    });
            });
        }

        $scope.openSignModal = function () {
            // console.log('openSignModal: ', $rootScope.awsUrl, $scope.purchase_order.signatureImage);
            var url = $scope.purchase_order.signatureImage ? $rootScope.awsUrl + $scope.purchase_order.signatureImage : '';
            try {
                var modalInstance = $uibModal.open({
                    templateUrl: '../views/modal/signature-modal.html',
                    controller: 'SignatureModalController',
                    size: 'lg',
                    resolve: {
                        url: function () {
                            return url || '';
                        }
                    }
                });

                // console.log('modalInstance: ', modalInstance);
                // we will get the value here
                modalInstance.result.then(function (result) {
                    if (result && result.type === 'image') {
                        // console.log('Yes, Image: ', result);
                        $scope.uploadSignature(result.data);
                    } else {
                        // console.log('No Image: ', result);
                    }
                }, function (error) {
                    console.log('Something went wrong while saving signature: ', error);
                });
            } catch (e) {
                console.log('Something went wrong while saving signature: ', e);
            }
        }

        $scope.uibProductEditPage = function (item) {
            if (item.unofficialProduct && item.unofficialProduct.shopAppliedStartingDigits) {
                return 'Can not add/Edit this product';
            } else {
                return '';
            }
        }

        $scope.uploadSignature = function (file) {
            var body = {
                _id: $scope.purchase_order._id,
                file: file,
                companyId: $rootScope.currentCompany._id
            }
            Upload.upload({
                url: 'api/v2/brand/upload-signature',
                data: body
            }).then(function (res) {
                if (res && res.data && res.data.data) {
                    // console.log('res: ', res.data.data);
                    $scope.purchase_order.signatureImage = res.data.data;
                }
            }, function (res) {
                $scope.data.uploadError = {
                    type: 'danger',
                    msg: res.data || res.data.message
                }
            }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total)
                $scope.uploadProgress = progressPercentage
            })
        }

        $scope.addVatNumber = function () {
            $state.go('brand.company-info');
        }

    }]);
