prismanoteApp.controller('adminProductImportController', ['$scope', '$rootScope', '$api', 'Upload', '$q', '$timeout', '$diamond', '$productConfig',
  function ($scope, $rootScope, $api, Upload, $q, $timeout, $diamond, $productConfig) {
    $scope.step = []
    $scope.step[1] = true
    $scope.percent = 0

    $scope.back = function (step) {
      goToStep(step)
    }

    function goToStep (step) {
      $scope.alert = null

      angular.forEach($scope.step, function (value, key) {
        $scope.step[key] = false
      })

      $scope.step[step] = true

      if (step == 1) {
        $scope.percent = 0
      } else if (step == 2) {
        $scope.percent = 33
      } else if (step == 3) {
        $scope.percent = 66
      } else if (step == 4) {
        $scope.percent = 100
      }
    }
    $scope.gemDKinds = $diamond.getGemKinds()
    $scope.gemDPurities = $diamond.getGemPurities()
    $scope.gemDColors = $diamond.getGemColors()
    $scope.gemDCuts = $diamond.getGemCuts()

    $scope.completeStep1 = function () {
      if ($scope.fileForm.importFile.$error.pattern || !$scope.importFile) {
        $scope.alert = {
          type: 'danger',
          msg: 'Geen of onjuist bestand gekozen'
        }
      } else if ($scope.fileForm.category.$error.$invalid || !$scope.category) {
        $scope.alert = {
          type: 'danger',
          msg: 'Geen productcategorie gekozen'
        }
      } else {
        $scope.loading = true
        uploadFile({
          category: $scope.category,
          importFile: $scope.importFile,
          delimiter: $scope.delimiter,
          publish: $scope.publish
        }, 'api/import/parse')
          .then(function (response) {
            $scope.loading = false
            goToStep(2)

            $scope.headers = []
            $scope.product = {
              category: $scope.category
            }

            angular.forEach(response.data.headers, function (value, key) {
              if (value) {
                var header = {
                  name: value
                }

                $scope.headers.push(header)
              }
            })
            $scope.lineCount = response.data.data.lines
            $scope.productProperties = response.data.product
          })
          .catch(function (reason) {
            console.log(reason)
            $scope.alert = {
              type: 'danger',
              msg: 'upload error: ' + reason.data.message
            }
          })
      }
    }

    $scope.completeStep2 = function () {
      goToStep(3)
      $scope.percent = 66
      $scope.step3()
    }

    var getItems = function (type) {
      $api.get(type)
        .then(function (response) {
          $scope[type] = response.data[type]
        }).catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.getBrands = function () {
      return getItems('brands')
    }

    var searchItems = function (str, type) {
      var matches = []
      $scope[type].forEach(function (item) {
        if ((item.en.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) || (item.en.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
          matches.push(item)
        }
      })
      return matches
    }

    $scope.searchBrands = function (str) {
      return searchItems(str, 'brands')
    }

    $scope.brandSelected = function (selected) {
      $scope.mySelectedBrandTitle = selected.title

      console.log('Selectd Brand', selected)
      var brand = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug,
        description: selected.originalObject.description,
        images: selected.originalObject.images
      }
      // console.log(this.$parent);
      $scope.brandnames[this.$parent.$index].value = brand
      console.log($scope.brandnames)
    }

    $scope.step3 = function () {
      // Compulsory brand selection

      if ($scope.product.brand) {
        getValues('brand', function (err, result) {
          console.log(result)
          $scope.brandnames = result
        })
      }

      // General
      if ($scope.product.gender) {
        getValues('gender', function (err, result) {
          $scope.genders = result
        })
      }

      if ($scope.product.diamonds) {
        // GemStone gem Kind A
        if ($scope.product.diamonds.gemKindA) {
          getValues('diamonds.gemKindA', function (err, result) {
            $scope.gemKindsA = result
          })
        }

        // GemStone gem Color A
        if ($scope.product.diamonds.gemColorA) {
          getValues('diamonds.gemColorA', function (err, result) {
            $scope.gemColorsA = result
          })
        }

        // GemStone gemPurity A
        if ($scope.product.diamonds.gemPurityA) {
          getValues('diamonds.gemPurityA', function (err, result) {
            $scope.gemPuritiesA = result
          })
        }

        // GemStone gemCut A
        if ($scope.product.diamonds.gemCutA) {
          getValues('diamonds.gemCutA', function (err, result) {
            $scope.gemCutsA = result
          })
        }

        // GemStone gem Kind B
        if ($scope.product.diamonds.gemKindB) {
          getValues('diamonds.gemKindB', function (err, result) {
            $scope.gemKindsB = result
          })
        }
        // GemStone gem Color B
        if ($scope.product.diamonds.gemColorB) {
          getValues('diamonds.gemColorB', function (err, result) {
            $scope.gemColorsB = result
          })
        }

        // GemStone gemPurity B
        if ($scope.product.diamonds.gemPurityB) {
          getValues('diamonds.gemPurityB', function (err, result) {
            $scope.gemPuritiesB = result
          })
        }

        // GemStone gemCut B
        if ($scope.product.diamonds.gemCutB) {
          getValues('diamonds.gemCutB', function (err, result) {
            $scope.gemCutsB = result
          })
        }

        // GemStone gem Kind C
        if ($scope.product.diamonds.gemKindC) {
          getValues('diamonds.gemKindC', function (err, result) {
            $scope.gemKindsC = result
          })
        }

        // GemStone gem Color C
        if ($scope.product.diamonds.gemColorC) {
          getValues('diamonds.gemColorC', function (err, result) {
            $scope.gemColorsC = result
          })
        }

        // GemStone gemPurity C
        if ($scope.product.diamonds.gemPurityC) {
          getValues('diamonds.gemPurityC', function (err, result) {
            $scope.gemPuritiesC = result
          })
        }

        // GemStone gemCut C
        if ($scope.product.diamonds.gemCutC) {
          getValues('diamonds.gemCutC', function (err, result) {
            $scope.gemCutsC = result
          })
        }
      }
      // Jewel
      if ($scope.product.jewel && $scope.product.jewel.type) {
        getValues('jewel.type', function (err, result) {
          $scope.jewelTypes = result
        })
      }

      // Jewel
      if ($scope.product.jewel && $scope.product.jewel.type) {
        getValues('jewel.type', function (err, result) {
          $scope.jewelTypes = result
        })
      }

      // Watch
      if ($scope.product.watch) {
        if ($scope.product.watch.type) {
          getValues('watch.type', function (err, result) {
            $scope.types = result
          })
        }
        if ($scope.product.watch.indication) {
          getValues('watch.indication', function (err, result) {
            $scope.indications = result
          })
        }

        if ($scope.product.watch.case) {
          if ($scope.product.watch.case.material) {
            getValues('watch.case.material', function (err, result) {
              $scope.materials = result
            })
          }
          if ($scope.product.watch.case.shape) {
            getValues('watch.case.shape', function (err, result) {
              $scope.shapes = result
            })
          }
          if ($scope.product.watch.case.glassMaterial) {
            getValues('watch.case.glassMaterial', function (err, result) {
              $scope.glassMaterial = result
            })
          }
        }

        if ($scope.product.watch.dial) {
          if ($scope.product.watch.dial.pattern) {
            getValues('watch.dial.pattern', function (err, result) {
              $scope.patterns = result
            })
          }
          if ($scope.product.watch.dial.index) {
            getValues('watch.dial.index', function (err, result) {
              $scope.indexes = result
            })
          }
        }

        if ($scope.product.watch.strap) {
          if ($scope.product.watch.strap.model) {
            getValues('watch.strap.model', function (err, result) {
              $scope.strapModel = result
            })
          }

          if ($scope.product.watch.strap.pattern) {
            getValues('watch.strap.pattern', function (err, result) {
              $scope.strapPattern = result
            })
          }

          if ($scope.product.watch.strap.material) {
            getValues('watch.strap.material', function (err, result) {
              $scope.strapMaterial = result
            })
          }
        }
      }
      // TODO: Add more properties which not can be used directly
    }

    $scope.completeStep3 = function () {
      console.log('strapModel', $scope.strapModel)
      uploadFile({
        category: $scope.category,
        importFile: $scope.importFile,
        delimiter: $scope.delimiter,
        product: $scope.product,
        customData: {
          brands: $scope.brandnames ? $scope.brandnames : null,
          jewelTypes: $scope.jewelTypes ? $scope.jewelTypes : null,
          genders: $scope.genders ? $scope.genders : null,
          types: $scope.types ? $scope.types : null,
          indications: $scope.indications ? $scope.indications : null,
          materials: $scope.materials ? $scope.materials : null,
          shapes: $scope.shapes ? $scope.shapes : null,
          glassMaterial: $scope.glassMaterial ? $scope.glassMaterial : null,
          patterns: $scope.patterns ? $scope.patterns : null,
          indexes: $scope.indexes ? $scope.indexes : null,
          strapModel: $scope.strapModel ? $scope.strapModel : null,
          strapPattern: $scope.strapPattern ? $scope.strapPattern : null,
          strapMaterial: $scope.strapMaterial ? $scope.strapMaterial : null,
          gemPurityA: $scope.gemPuritiesA ? $scope.gemPuritiesA : null,
          gemPurityB: $scope.gemPuritiesB ? $scope.gemPuritiesB : null,
          gemPurityC: $scope.gemPuritiesC ? $scope.gemPuritiesC : null,
          gemColorA: $scope.gemColorsA ? $scope.gemColorsA : null,
          gemColorB: $scope.gemColorsB ? $scope.gemColorsB : null,
          gemColorC: $scope.gemColorsC ? $scope.gemColorsC : null,
          gemKindA: $scope.gemKindsA ? $scope.gemKindsA : null,
          gemKindB: $scope.gemKindsB ? $scope.gemKindsB : null,
          gemKindC: $scope.gemKindsC ? $scope.gemKindsC : null,
          gemCutA: $scope.gemCutsA ? $scope.gemCutsA : null,
          gemCutB: $scope.gemCutsB ? $scope.gemCutsB : null,
          gemCutC: $scope.gemCutsC ? $scope.gemCutsC : null
        },
        lines: $scope.lineCount
      }, 'api/import/handle')
        .then(function (response) {
          goToStep(4)
        })
        .catch(function (reason) {
          console.log(reason)
          $scope.alert = {
            type: 'danger',
            msg: 'upload error: ' + reason.status
          }
        })
    }

    function getValues (value, callback) {
      var property = JSON.parse(_.get($scope.product, value))
      var columnName = property.name

      uploadFile({
        importFile: $scope.importFile,
        delimiter: $scope.delimiter,
        column: columnName
      }, 'api/import/value')
        .then(function (response) {
          return callback(null, response.data.result)
        })
        .catch(function (reason) {
          console.log(reason)
          return callback(reason)
        })
    }

    $scope.completeImport = function () {
      console.log('handleImport')

      uploadFile({
        importFile: $scope.importFile,
        delimiter: $scope.delimiter,
        product: $scope.product
      },
      'api/import/handle')
        .then(function (response) {
          console.log(response.data)
        })
        .catch(function (reason) {
          $scope.alert = {
            type: 'danger',
            msg: 'upload error: ' + reason.status
          }
          $scope.error = true
        })
    }

    $scope.closeAlert = function () {
      $scope.alert = null
    }

    function uploadFile (data, url) {
      return $q(function (resolve, reject) {
        Upload.upload({
          url: url,
          data: {
            delimiter: data.delimiter,
            cat: data.category,
            file: data.importFile,
            product: data.product ? data.product : null,
            column: data.column ? data.column : null,
            customData: data.customData ? data.customData : null,
            lines: data.lines ? data.lines : null

          }
        })
          .then(function (res) {
            $timeout(function () {
              resolve(res)
            })
          }, function (res) {
            // wanneer uploaden mislukt
            console.error('Error: ', res.status)
            reject(res)
          }, function (evt) {
            // tijdens upload
            // var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            // $scope.uploadProgress = progressPercentage;
            // console.log("Progress: " + progressPercentage + '%' + evt.config.data);
          })
      })
    }
  }])
