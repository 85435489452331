prismanoteApp.controller('inviteRetailerForRecommendationModalController', ['$scope', '$uibModalInstance', '$brand', 'shopId', '$rootScope',
    function ($scope, $uibModalInstance, $brand, shopId, $rootScope) {
        console.log('shopId: ', shopId);
        $scope.emailSelected = false
        $scope.localdata = {
            email: '',
            isValidEmail: false
        }

        $scope.close = function (data) {
            var message = 'cancel';
            if (!data) data = message;
            $uibModalInstance.close(data);
        }

        function validateEmail(email) {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        }

        $scope.isValidEmail = function () {
            if (validateEmail($scope.localdata.email.originalEmail)) {
                $scope.localdata.isValidEmail = true;
            } else {
                $scope.localdata.isValidEmail = false;
            }
        }

        $scope.changeSendEmail = function (selected) {
            setTimeout(function () {
                $scope.$apply(function () {
                    $scope.emailSelected = true
                    $scope.localdata.isValidEmail = true;
                });
            }, 50);
        }

        $scope.sendInvitation = function () {
            console.log('sendInvitation called: ', $scope.localdata.email.originalEmail);
            $scope.isValidEmail();
            if ($scope.localdata.isValidEmail) {
                console.log('sendInvitation called: ', $scope.localdata.email.originalEmail);
                $scope.close($scope.localdata.email.originalEmail);
            } else {
                console.log('Invalid Email');
            }
        }

        $scope.getShopEmails = function () {
            var body = {
                shopId: shopId,
                companyId: $rootScope.currentCompany._id
            }
            $brand.getShopEmails(body).then(function (response) {
                console.log('getShopEmails: ', response);
                $scope.shopEmails = [];
                if (response && response.data && response.data.data && response.data.data.length) $scope.shopEmails = response.data.data;
                $scope.AlloriginalEmails = JSON.parse(JSON.stringify($scope.shopEmails));
            }).catch(function (error) {
                console.log('error while getting shop emails: ', error);
            });
        }

        function validateEmail(email) {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        }

        $scope.getEmails = function (search) {
            if (search) {
                // console.log('Emails: ', $scope.shopEmails);
                var isFound = false;
                $scope.localdata.isCustomEmailAdded = false;
                for (var i = 0; i < $scope.AlloriginalEmails.length; i++) {
                    if ($scope.shopEmails[i] && $scope.shopEmails[i].originalEmail && $scope.shopEmails[i].originalEmail.toLowerCase().indexOf(search.toString().toLowerCase()) >= 0) {
                        isFound = true;
                        break;
                    }
                }

                if (!isFound && validateEmail(search)) {
                    $scope.localdata.isCustomEmailAdded = true;
                    $scope.shopEmails = JSON.parse(JSON.stringify($scope.AlloriginalEmails));
                    $scope.shopEmails.unshift({ email: search, originalEmail: search, isShopEmail: false, isCustomEmail: true });
                    console.log('$scope.shopEmails: ', $scope.shopEmails);
                }
            }
            return $scope.shopEmails;
        }

        $scope.hideEmployeeEmailAddress = function (email) {
            if (email.email) {
                var last4Char = email.email.slice(-4);
                var first3Char = email.email.substring(0, 3);
                email.email = first3Char + last4Char.padStart(email.email.length - 3, '*');
            } else {
                email.email = '';
            }
            return (email.fullName || ' ') + ' (' + (email.email || ' ') + ')' + (email.isCustomEmail ? ' Custom Email ' : '');
        }

    }]);
