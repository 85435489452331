prismanoteApp.controller("retailerUpgradeController", ['$scope', '$rootScope', '$retailer', '$language', '$uibModal',
function($scope, $rootScope, $retailer, $language, $uibModal) {
    
    $scope.package = {};

    $scope.packages = [
        {
            name: $language.translate("CASH_REGISTER_AND_CRM"),
            price: 45.00,
            suggested: false,
            contents: [
                $language.translate("CUSTOMER_MANAGMENT"),
                $language.translate("CHECKOUT_QUOTATION_CREATION"),
                $language.translate("PROCESSING_REPAIRS"),
                $language.translate("PRINT_RECEIPT_OPTION"),
                $language.translate("TURNOVER_STATISTICS"),
                $language.translate("MARKETING_WITH_SUPPLIERS_OPTION"),
            ],
            totalPrice: 0.00,
            code: 'FREE',
        }, {
            name: $language.translate("COMPLETE_SHOPPING_SYSTEM"),
            price: 105,
            suggested: false,
            contents: [
                $language.translate("ALL_FUNCTIONS_OF_CHECKOUT_AND_CRM"),
                $language.translate("INVENTORY_MANAGEMENT_OPTION"),
                $language.translate("PRODUCT_LINK_WITH_DATABASE"),
                $language.translate("SUPPLIER_MANAGEMENT_OPTION"),
                $language.translate("STATISTICS_OPTION"),
                $language.translate("PRINTING_LABELS_OPTION"),
                $language.translate("CREATING_AND_SENDING_ORDERS"),
            ],
            totalPrice: 0.00,
            code: 'STOREB',
        }, {
            name: $language.translate("OMNI_CHANNEL_SYSTEM"),
            price: 159,
            suggested: true,
            contents: [
                $language.translate("ALL_FUNCTIONS_OF_SHOPPING_SYSTEM"),
                '',
                $language.translate("SHOW_STOCK_ON_THE_WEBSITE"),
                $language.translate("BEING_ABLE_TO_MODIFY_WEBSITE_YOURSELF"),
                $language.translate("WEBSHOP_TO_SELL_DIRECTLY"),
                $language.translate("BLOGS_AND_PHOTO_SHARING_WITH_ONE_CLICK"),
                $language.translate("AUTOMATIC_UPDATES_FOR_FREE"),
            ],
            totalPrice: 0.00,
            code: 'STOREP',
        }, {
            name: $language.translate("SALES_PACKAGE"),
            price: 275,
            suggested: false,
            contents: [
                $language.translate("ALL_FUNCTIONS_OF_OMNICHANNEL"),
                '',
                $language.translate("SEND_NEWSLETTER_EVERY_MONTH"),
                $language.translate("UPDATE_WEBSITE_TEXT_PHOTOS"),
                $language.translate("KEEP_TRACK_OF_SOCIAL_MEDIA_CHANNELS"),
                $language.translate("THINKING_ALONG_WITH_DIGITIZING_WEBSHOP"),
            ],
            totalPrice: 0.00,
            code: 'STOREE',
        }
    ]

    $scope.upgrade = {};
    $scope.initial = true;

    $scope.$on('changeSelectedShop', function() {
        $scope.alert = null;
        $retailer.getShop()
        .then(function(shop) {		
            $rootScope.currentShop = shop;    
        })
    });
   
    $scope.getCurrentValues = function(){
        angular.forEach($scope.upgrade, function(value, key){
            if(key == "webshop"){
                key = key + value;
                value = value == 1 || value == 2;
            }
            $scope.calculate(value, $scope.prices[key]);
        })
    }  

    $scope.checkIfModuleIsOnShop = function(mod){
        if(!$rootScope.currentShop || !$rootScope.currentShop.package || !$rootScope.currentShop.package.modules || $rootScope.currentShop.package.modules.length == 0){
            return false;
        }

        var activeModules = $rootScope.currentShop.package.modules.filter(function (v){
            return v.active == true;
        })
        return _.findIndex(activeModules, {name: mod}) >= 0;
    }

    $scope.startUpgrade = function(package){
        var userPackage = angular.copy($scope.packages[package]), counter =0;

        if(!userPackage.choosen || typeof userPackage.choosen == 'undefined' || Object.keys(userPackage.choosen).length < 1){
            $scope.openUpgradeModal(userPackage);
            return;
        }

        for(var key in userPackage.choosen){
            if(!userPackage.choosen.hasOwnProperty(key)) continue;

            var option = userPackage.choosen[key];
            try{
                option = JSON.parse(option);
                if(option && option.price)userPackage.choosen[key] = option;
            }catch(err){
                //Use try catch to prevent errors for already parsed object
            }
            counter++;
            if(counter == Object.keys(userPackage.choosen).length){
                $scope.openUpgradeModal(userPackage);
            }
        }
    }

    $scope.openUpgradeModal = function(upgrade){
		var modalInstance = $uibModal.open({
			templateUrl: '../views/modal/upgrade-modal.html',
			controller: 'upgradeModalController',
			resolve: {
				upgrade: function(){
					return upgrade;
                }
			}
		});

		modalInstance.result.then(function(result){
			if(result){
				
			}
		}, function(){
			//dismissed
		})
    }
}]);