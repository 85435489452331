prismanoteApp.controller('adminWholesalerController', ['$scope', '$rootScope', '$api', '$stateParams', '$uibModal', '$state', 'prompt', 'Upload',
  function ($scope, $rootScope, $api, $stateParams, $uibModal, $state, prompt, Upload) {
    $scope.loading = true
    $scope.totalDisplayed = 20
    $scope.isVerified = true
    $scope.isNotVerified = true
    $scope.wholesalerFilter = ''

    // $scope.wholesalerFilter = function(item){
    //     console.log(item);
    //     if(item.isVerified == $scope.isVerified) return item;
    // }

    $scope.verificationsChanged = function () {
      var status = false

      if ($scope.isNotVerified == $scope.isVerified) {
        console.log('is not verified', $scope.isNotVerified)
        console.log('is verified', $scope.isVerified)
        status = 'showAll'
      } else {
        if ($scope.isVerified == false && $scope.isNotVerified == true) {
          status = false
        } else {
          status = true
        }
      }

      var params = {
        filter: {
          isVerified: status
        }
      }
      console.log('passing params', params)
      $scope.getWholesalerList(params)
    }

    $scope.getWholesalerList = function (params) {
      $api.get('wholesalers', params)

        .then(function (response) {
          $scope.loading = false
          console.log('Get Wholesalers', response.data)
          $scope.wholesalers = response.data.wholesalers
          console.log('Wholesalers = ', $scope.wholesalers)
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.closeAlert = function () {
      $scope.alert = null
    }

    $scope.resetSelected = function () {
      $scope.currentSocialMedia = null
      $scope.currentHome = null
      $scope.currentSlide = null
    }

    $scope.loadMore = function () {
      $scope.totalDisplayed += 20
    }

    $scope.loadWholesaler = function () {
      $api.get('wholesalers/' + $stateParams.nameSlug)

        .then(function (response) {
          $scope.wholesaler = response.data.wholesaler
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.updateWholesaler = function (redirect) {
      $api.put('wholesalers/' + $stateParams.nameSlug, { wholesaler: $scope.wholesaler })

        .then(function (response) {
          if (redirect) {
            $state.go('admin.wholesalers')
          } else {
            $scope.alert = {
              type: 'success',
              msg: response.data.message
            }
          }
        })

        .catch(function (reason) {
          console.log(reason)
          $scope.alert = {
            type: 'danger',
            msg: reason
          }
        })
    }

    $scope.getBrands = function () {
      // $api.get('brands')
      $api.get('brands?getAll=true')
        .then(function (response) {
          $scope.brands = response.data.brands
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.searchBrands = function (str) {
      var matches = []
      $scope.brands.forEach(function (brand) {
        if ((brand.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                (brand.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
          matches.push(brand)
        }
      })
      return matches
    }

    $scope.brandSelected = function (selected) {
      var brand = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug,
        description: selected.originalObject.description,
        images: selected.originalObject.images
      }
      $scope.wholesaler.brands.push(brand)
    }

    $scope.deleteBrand = function (index) {
      prompt({
        title: 'Merk verwijderen?',
        message: 'Weet u zeker dat u dit merk van deze winkel wilt verwijderen?'
      }).then(function () {
        $scope.wholesaler.brands.splice(index, 1)
        $scope.resetSelected()
      })
    }

    $scope.openCreateWholeSalerModal = function () {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/create-wholesaler-modal.html',
        controller: 'createWholesalerModalController',
        size: 'lg'
      })

      modalInstance.result.then(function (result) {
        $api.post('wholesalers', { wholesaler: result })
          .then(function (response) {
            $scope.alert = null
            $scope.getWholesalerList()
          })
          .catch(function (reason) {
            console.log(reason)
            $scope.alert = {
              type: 'danger',
              msg: reason
            }
          })
      }, function () {

      })
    }

    $scope.removeWholesaler = function () {
      prompt({
        title: 'Distribiteur verwijderen?',
        message: 'Weet u zeker dat u deze distribiteur wilt verwijderen?'
      }).then(function () {
        $api.delete('wholesalers/' + $stateParams.nameSlug)
          .then(function (response) {

          })
          .catch(function (reason) {
            $scope.alert = {
              type: 'danger',
              msg: reason
            }
          })
        if (!$scope.alert) {
          $state.go('admin.wholesalers')
        }
      })
    }
  }])
