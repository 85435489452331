prismanoteApp.controller('retailerEmployeeModalController', ['$rootScope', '$scope', '$uibModalInstance', '$api', 'employee', 'isAddEmployee',
  function ($rootScope, $scope, $uibModalInstance, $api, employee, isAddEmployee) {
    $scope.employee = employee
    $scope.isAddEmployee = isAddEmployee
    if (!isAddEmployee) $scope.employee.pin = $scope.employee.confirmpin = $scope.employee.pincode

    // $scope.shopRights = angular.copy($rootScope.userRights);
    $scope.saveemployee = function () {
      if (isAddEmployee) {
        var isRightsSelected = false
        for (var i = 0; i < $scope.employee.shopRights.length; i++) {
          if ($scope.employee.shopRights[i].enabled) {
            isRightsSelected = true
            break
          }
        }
        if (isRightsSelected) {
          if ($scope.employee.pin && $scope.employee.confirmpin && $scope.employee.confirmpin === $scope.employee.pin) {
            $scope.employee.pincode = $scope.employee.pin
            addEmployee($scope.employee)
          } else {
            $scope.epAlert = {
              type: 'danger',
              msg: 'Both pin should be the same'
            }
          }
        } else {
          $scope.epAlert = {
            type: 'danger',
            msg: 'Please select rights'
          }
        }
      } else {
        editEmployee($scope.employee)
      }
    }

    $scope.cancel = function () {
      $uibModalInstance.close()
    }

    function addEmployee (employee) {
      employee.isEmployee = true
      employee.username = employee.email
      employee.role = 'retailer'
      employee.shopId = $rootScope.currentShop._id
      employee.shopName = $rootScope.currentShop.name
      // console.log('add employee: ', employee);
      $api.post('shop/employee', employee)
        .then(function (response) {
          $uibModalInstance.close({ type: 'added', data: response.data.data })
        })
        .catch(function (error) {
          if (error.status === 400) {
            $scope.epAlert = {
              type: 'danger',
              msg: error.data.message
            }
          } else {
            $scope.epAlert = {
              type: 'danger',
              msg: 'Internal Server Error'
            }
          }
        })
    }

    function editEmployee (employee) {
      if (!employee.enabled) employee.employeeCanBeAFirstUser = false
      employee.shopId = $rootScope.currentShop._id
      if (employee.pin !== employee.confirmpin) {
        $scope.epAlert = {
          type: 'danger',
          msg: 'Both pin should be the same'
        }
        return
      } else {
        employee.pincode = employee.pin
      }
      $api.put('shop/employee', employee)
        .then(function (response) {
          $uibModalInstance.close({ type: 'edited', data: response.data.data })
        })
        .catch(function (error) {
          if (error.status === 400) {
            $scope.epAlert = {
              type: 'danger',
              msg: error.data.message
            }
          } else {
            $scope.epAlert = {
              type: 'danger',
              msg: 'Internal Server Error'
            }
          }
        })
    }
  }])
