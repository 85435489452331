prismanoteApp.controller('activityNewServiceItemController', ['$scope', '$api', '$company', '$stateParams', '$state', 'FileUploader', '$language', '$rootScope', '$productConfig', 'dialogs',
  function ($scope, $api, $company, $stateParams, $state, FileUploader, $language, $rootScope, $productConfig, dialogs) {
    $scope.searchMode = 'exact'
    $scope.filter = {};
    // $scope.filter.startDate = new Date();
    // $scope.projectStartDate = new Date();
    $scope.connectedFutureActivity;
    $scope.selectedUser = {
      title: $rootScope.user.firstName + ' ' + $rootScope.user.lastName,
      originalObject: $rootScope.user
    }
     
    $scope.initalize = function () {
        $scope.activities = {
          type: 'general_activity',
          activityName : 'visit',
          selectedShopCountry: '',
          selectedShopPostalCode: '',
          selectedShopHouseNumber: '',
          selectedShopStreet: '',
          selectedShopCity: '',
          language: $rootScope.language
      }
      $scope.search_value = {
        text: ''
      }
    }
    $scope.preFillData = function () {
      console.log('--------- preFillData!!');
      console.log('$rootScope.activities ', $rootScope.activities);
      if($rootScope.activities){
        $scope.filter = {};
        if($rootScope.activities.projectStartDate){ $scope.filter.startDate = $rootScope.activities.projectStartDate; }else{
          $scope.filter.startDate = new Date();
        }
        if($rootScope.activities.connectedFutureActivity){ $scope.activities.activityName = $rootScope.activities.connectedFutureActivity; }
        if($rootScope.activities.shop){ $scope.activities.shop = $rootScope.activities.shop; }
        if($rootScope.activities.selectedShopCity){ $scope.activities.selectedShopCity = $rootScope.activities.selectedShopCity; }
        if($rootScope.activities.selectedShopCountry){ $scope.activities.selectedShopCountry = $rootScope.activities.selectedShopCountry; }
        if($rootScope.activities.selectedShopHouseNumber){ $scope.activities.selectedShopHouseNumber = $rootScope.activities.selectedShopHouseNumber; }
        if($rootScope.activities.selectedShopPostalCode){ $scope.activities.selectedShopPostalCode = $rootScope.activities.selectedShopPostalCode; }
        if($rootScope.activities.selectedShopStreet){ $scope.activities.selectedShopStreet = $rootScope.activities.selectedShopStreet; }
        $rootScope.activities = {};
      }else{
        $scope.filter.startDate = new Date();
      }
    }
    $scope.initalize()
    $scope.loader = false
    $scope.creatingActivityThroughShop = false
/*     $scope.activities.selectedShopCountry = ''
    $scope.activities.selectedShopPostalCode = ''
    $scope.activities.selectedShopHouseNumber = ''
    $scope.activities.selectedShopStreet = ''
    $scope.activities.selectedShopCity = '' */
    if ($stateParams.name) {
      $scope.creatingActivityThroughShop = true
    } else {
      $scope.creatingActivityThroughShop = false
    }
    var uploader = $scope.uploader = new FileUploader({
      url: 'api/transactions/upload'
    })

    // $scope.initalize = function () {
    //   $scope.activities = {
    //     language: $rootScope.language
    //   }
    //   $scope.search_value = {
    //     text: ''
    //   }
    // }
    $scope.initalize()

    uploader.filters.push({
      name: 'duplicateFilesFilter',
      fn: function (item) {
        var index = _.findIndex($scope.uploader.queue, function (q) {
          return q.file.name == item.name
        })
        return index < 0
      }
    })

    uploader.onProgressAll = function (progress) {
      if (progress < 100) {
        $rootScope.$broadcast('dialogs.wait.progress', { progress: progress })
      } else {
        $rootScope.$broadcast('dialogs.wait.complete')
      }
    }

    uploader.onSuccessItem = function (item, response, status, headers) {
      // Push every single file in an array to store them later on the transaction
      $scope.allFiles.push({
        src: response.file,
        comment: item.formData.comment
      })
    }

    uploader.onCompleteAll = function () {
      // All files are uploaded, now we can merge all details and create the transaction
      complete()
    }

    uploader.onErrorItem = function (fileItem, response, status, headers) {
      $scope.loading = false
      console.info('onErrorItem', fileItem, response, status, headers)
      $scope.alert = {
        type: 'danger',
        msg: response.message
      }
    }

    function complete () {
      console.log('------- complete!!');
      // uploader.clearQueue()
      // $scope.alert = null
      // $scope.transaction.repairer = {
      //   _id: $rootScope.currentCompany._id,
      //   name: $rootScope.currentCompany.name
      // }
    $scope.activities.companyId = $rootScope.currentCompany._id
    if($scope.selectedUser && $scope.selectedUser.originalObject && $scope.selectedUser.originalObject._id) {
      // console.log($rootScope.user);
      // console.log($scope.selectedUser);
      $scope.activities.assignee = $scope.selectedUser.originalObject._id;
    }
    if($scope.selectedEmployee && $scope.selectedEmployee.originalObject && $scope.selectedEmployee.originalObject._id){
      $scope.activities.targetUser = $scope.selectedEmployee.originalObject._id;
    }
    // console.log($scope.filter.startDate);
    if($scope.filter && $scope.filter.startDate){
      $scope.activities.date = $scope.filter.startDate;
    }

    // console.log('$scope.filter.projectStartDate ', $scope.filter.projectStartDate);
    // console.log('$scope.filter.startDate ', $scope.filter.startDate);
    // console.log('$scope.connectedFutureActivity ', $scope.connectedFutureActivity);
    // console.log('$scope.activities ', $scope.activities);
    
    $scope.activities.selectedUser = $scope.selectedUser;
    $scope.activities.selectedEmployee = $scope.selectedEmployee;
    $scope.activities.projectStartDate = $scope.filter.projectStartDate;
    $scope.activities.connectedFutureActivity = $scope.connectedFutureActivity;

    console.log($scope.activities);
      $api.post('activity/create-activity', {
        activity: $scope.activities
       // files: $scope.allFiles
      })
        .then(function (response) {
          $scope.alert = {
            type: 'success',
            msg: $language.translate('ACTIVITY_CREATED')
          }
          if($scope.connectedFutureActivity == 'call'){ 
            $rootScope.activities = $scope.activities;
            $state.transitionTo($state.current, $stateParams, { reload: true, inherit: false, notify: true });
          }else{
            if ($stateParams.name) {
              $state.go('brand.shops')
            } else {
              $state.go('brand.activity')
            }
          }
          
        })
        .catch(function (reason) {
          console.error(reason)
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(reason)
          }
        })
    }

    $scope.getCompanyShops = function () {   
      // console.log('------ getCompanyShops!!');
      // console.log('$rootScope.currentCompany && $rootScope.currentCompany._id::',$rootScope.currentCompany && $rootScope.currentCompany._id);
      if ($rootScope.currentCompany && $rootScope.currentCompany._id) {
        return getCompanyShops()
      } else {
        $company.getCompany()
          .then(function (company) {
            $scope.currentCompany = company
            $rootScope.currentCompany = company
            getCompanyShops()
          })
          .catch(function (reason) {
            console.error('Internal Server Error while fetching company details from rootScope', reason)
          })
      }
    }

    function getAllShops() {
      console.log('inside getAllShops function');
      $api.get('shops/all-shops/' + $rootScope.currentCompany._id)
        .then(function (response) {
          console.log('getAllShops res:::',response);
        })
    }

    function getCompanyShops() {
      // console.log('$rootScope.currentCompany._i::', $rootScope.currentCompany._id);
      $scope.loader = true
      $scope.brands = $rootScope.currentCompany.brands
      $api.get('shops/all-shops/' + $rootScope.currentCompany._id)
      .then(function (response) {
        // console.log('getAllShops res:::',response);
        $scope.loader = false
        $scope.shops = response.data.data.map(function (s) {
          delete s.shopDetail.brands
          return s.shopDetail
        })
        var shopData = []
          // console.log('$scope.shops:::', $scope.shops);
          if ($scope.shops.length > 0 && $stateParams.name) {
            shopData = $scope.shops.filter(function (item) {
              // console.log('item.name:::', item.name);
              return (item.name == $stateParams.name);
            });
            if (shopData.length > 0) {
              $scope.activities.companyStartingDigit = ''
              $api.get('comapnies-starting-digit/' + $rootScope.currentCompany._id)
                .then(function (response) {
                  // console.log('response:::::', response);
                  $scope.activities.companyStartingDigit = response.data.companyStartingDigit[0].startingDigits
                  // console.log('$scope.activities.companyStartingDigit', $scope.activities.companyStartingDigit);
                })
              $scope.activities.shop = shopData[0]
              $scope.activities.selectedShopCountry = $scope.activities.shop.address.country
              $scope.activities.selectedShopPostalCode = $scope.activities.shop.address.postalCode
              $scope.activities.selectedShopHouseNumber = $scope.activities.shop.address.houseNumber
              $scope.activities.selectedShopStreet = $scope.activities.shop.address.street
              $scope.activities.selectedShopCity = $scope.activities.shop.address.city
              // console.log('$scope.activities.shop:::', $scope.activities.shop)
              // console.log('name:::', $scope.activities.shop.name);
            }

          }
      })
      .catch(function (reason) {
        console.error('Error while getting shop companies', reason)
      })
     /*  $api.get('shops/comapnies/' + $rootScope.currentCompany._id)
        .then(function (response) {
          console.log('main res::###',response);
          $scope.loader = false
          $scope.shops = response.data.data.map(function (s) {
            delete s.shopDetail.brands
            return s.shopDetail
          })
          var shopData = []
          console.log('$scope.shops:::', $scope.shops);
          if ($scope.shops.length > 0 && $stateParams.name) {
            shopData = $scope.shops.filter(function (item) {
              console.log('item.name:::', item.name);
              return (item.name == $stateParams.name);
            });
            if (shopData.length > 0) {
              $scope.activities.companyStartingDigit = ''
              $api.get('comapnies-starting-digit/' + $rootScope.currentCompany._id)
                .then(function (response) {
                  console.log('response:::::', response);
                  $scope.activities.companyStartingDigit = response.data.companyStartingDigit[0].startingDigits
                  console.log('$scope.activities.companyStartingDigit', $scope.activities.companyStartingDigit);
                })
              $scope.activities.shop = shopData[0]
              $scope.activities.selectedShopCountry = $scope.activities.shop.address.country
              $scope.activities.selectedShopPostalCode = $scope.activities.shop.address.postalCode
              $scope.activities.selectedShopHouseNumber = $scope.activities.shop.address.houseNumber
              $scope.activities.selectedShopStreet = $scope.activities.shop.address.street
              $scope.activities.selectedShopCity = $scope.activities.shop.address.city
              console.log('$scope.activities.shop:::', $scope.activities.shop)
              console.log('name:::', $scope.activities.shop.name);
            }

          }
        })
        .catch(function (reason) {
          console.error('Error while getting shop companies', reason)
        }) */
    }

    $scope.selectUser = function (user) {
      $scope.selectedUser = user;
    }

    $scope.selectEmployee = function (employee) {
      $scope.selectedEmployee = employee;
    }

    $scope.selectedShop = function (shop) {
      console.log('inside:::::', shop);
      $scope.activities.companyStartingDigit = ''
      $api.get('comapnies-starting-digit/' + $rootScope.currentCompany._id)
        .then(function (response) {
          console.log('response:::::', response);
          $scope.activities.companyStartingDigit = response.data.companyStartingDigit[0].startingDigits
          console.log('$scope.activities.companyStartingDigit', $scope.activities.companyStartingDigit);
        })
      console.log('shop.originalObject:::', shop.originalObject);
      $scope.activities.shop = shop.originalObject
      $scope.activities.selectedShopCountry = $scope.activities.shop.address.country
      $scope.activities.selectedShopPostalCode = $scope.activities.shop.address.postalCode
      $scope.activities.selectedShopHouseNumber = $scope.activities.shop.address.houseNumber
      $scope.activities.selectedShopStreet = $scope.activities.shop.address.street
      $scope.activities.selectedShopCity = $scope.activities.shop.address.city
      console.log('$scope.selectedShopCountry::', $scope.activities.selectedShopCountry);
      console.log('$scope.activities.shop :::', $scope.activities.shop);
    }

    $scope.removeShop = function () {
      $scope.activities.shop = null
    }

    $scope.removeUser = function () {
      $scope.selectedUser = false;
    }

    $scope.removeEmployee = function (){
      $scope.selectedEmployee = false;
    }
    
    $scope.getEmployees = function (){
      console.log('--- getEmployees!!');
      $company.getCompany()
        .then(function (company) {
          $scope.currentCompany = company
          $rootScope.currentCompany = company
          $api.get('brand/company-employees/' + $rootScope.currentCompany._id)
          .then(function (response) {
            $scope.users = [];
            var userData = response.data.data.employeeDetail;
            var loopTill = userData.length;
            for(var i = 0; i < loopTill; i++){
              if(userData[i] && userData[i].firstName && userData[i].lastName){
                userData[i].enabled = false;
                $scope.users.push(userData[i]);
              }
              if(i == (loopTill - 1)){
                $rootScope.user.enabled = false;
                $scope.users.push($rootScope.user);
              }
            }
          })
          .catch(function (reason) {
            $scope.loading = false
            console.error(reason)
          })
        })
        .catch(function (reason) {
          console.error('Internal Server Error while fetching company details from rootScope', reason)
        })
    }

    $scope.getShopEmployees = function (){
      var shopId = $scope.activities.shop._id;
      var companyId = $rootScope.currentCompany._id;

      $api.get('v2/company/get-shopEmployee?shopId=' + shopId + '&companyId=' + companyId)
      .then(function (response) {
        $scope.employees = response.data.data;
      })
      .catch(function (reason) {
        $scope.loading = false
        console.error(reason)
      })
    }

    $scope.searchUser = function (str) {
      var matches = []
      if(str) {
        $scope.users.forEach(function (user) {
          if (user) {
            if (user.firstName && user.firstName.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0 ||
                  user.lastName && user.lastName.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) { 
              user.name = user.firstName + ' ' + user.lastName;
              matches.push(user)
            }
          }
        })
        return matches
      }
    }

    $scope.searchEmployee = function (str) {
      var matches = []
      if(str) {
        $scope.employees.forEach(function (employee) {
          if (employee) {
            if (employee.firstName && employee.firstName.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0 ||
                employee.lastName && employee.lastName.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) { 
                  employee.name = employee.firstName + ' ' + employee.lastName;
              matches.push(employee)
            }
          }
        })
        return matches
      }
    }

    $scope.searchShops = function (str) {
      var matches = []
      if(str) {
        $scope.shops.forEach(function (item) {
          if (item) {
            if (item.name && item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0 ||
                          item.nameSlug && item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) {
              matches.push(item)
            }
          }
        })
        return matches
      }
     
    }

    $scope.findProducts = function (value, searchMode) {
      $scope.hits = []
      if (value && value.length > 0 && $rootScope.currentCompany && $rootScope.currentCompany._id) {
        $scope.search_value.text = $productConfig.retrunValidProductNumber(value)
        value = $scope.search_value.text

        if (value.length < 3) return

        var data = {
          companyId: $rootScope.currentCompany._id,
          searchMode: searchMode,
          searchText: value
        }
        $scope.productNumberStatus = 'default'
        $scope.loading = true
        $company.searchProductForCompany(data)
          .then(function (response) {
            if (!$scope.cancleSearch) {
              $scope.hits = response.data
              $scope.countOfProducts = response.count
              if (response.status != null && response.status != undefined) $scope.productNumberStatus = parseInt(response.status)
            } else {
              $scope.loading = false
              $scope.searchingProduct = false
            }
          })
          .catch(function (error) {
            $scope.loading = false
            $scope.searchingProduct = false
            console.error('Error while getting product from company', error)
          })
      }
    }

    $scope.setSearchMode = function (value, mode) {
      $scope.searchMode = mode
      $scope.findProducts(value, mode)
    }

  /*   $scope.addItem = function (product) {
      $scope.transaction.product = product
      if (product.brand) {
        $scope.selectedBrand(product.brand)
      }

      $scope.hits = []
    } */

  /*   $scope.selectedBrand = function (brand) {
      if (brand.originalObject) {
        brand = brand.originalObject
      } */

     /*  $scope.transaction.brand = {
        name: brand.name,
        nameSlug: brand.nameSlug,
        _id: typeof brand._id === 'string' ? brand._id : brand._id._id
      }
    } */

   /*  $scope.removeBrand = function () {
      $scope.transaction.brand = null
    } */
/* 
    $scope.searchBrands = function (str) {
      var matches = []
      $scope.brands.forEach(function (item) {
        if (item) {
          if (item.name && item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0 ||
                        item.nameSlug && item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) {
            matches.push(item)
          }
        }
      })
      return matches
    } */

    $scope.create = function () {
      $scope.allFiles = []
      if (uploader.queue.length > 0) {
        uploader.uploadAll()
        dialogs.wait($language.translate('UPLOADING'), $language.translate('PLEASE_WAIT_WHILE_THE_PHOTOS_ARE_UPLOADED'), 0)
      } else {
        complete()
      }
    }

    $scope.addCommentToPhoto = function (index) {
      $scope.editComment = index
    }
    $scope.savePhotoComment = function () {
      $scope.editComment = null
    }
  }])
