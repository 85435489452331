prismanoteApp.controller('brandPerformanceController', ['$scope', '$rootScope', '$api', '$stateParams', '$uibModal', '$state', 'prompt', '$wholesaler', 'campaignFact',
  function ($scope, $rootScope, $api, $stateParams, $uibModal, $state, prompt, $wholesaler, campaignFact) {
    $scope.getLikeArray = ['today', 'yesterday', 'last_3d', 'last_7d', 'last_14d', 'last_28d', 'last_30d', 'last_90d', 'this_month', 'last_month', 'last_year', 'this_year']
    $scope.getLikeModel = $scope.getLikeArray[0]
    $scope.salerBrand = []
    campaignFact.getCompanies().then(function (res) {
      console.log('company data', res)
      res.data.forEach(function (val) {
        val.brands.forEach(function (value) {
          $scope.salerBrand.push(value.name)
        })
      })
      console.log('$scope.salerBrand', $scope.salerBrand)
      $scope.totalBrands = $scope.salerBrand.length
    })
    console.log('current wholealer', $rootScope.user.companies[0].name)

    campaignFact.getDBinsight().then(function (res) {
      var arrChart = res.data
      console.log('>>>>>>>>', arrChart)
      // $rootScope.prevPAgeLike = arrChart[arrChart.length - 1].value;
      var chartDataImpres = []
      var chartDataAmount = []
      var chartDataReach = []
      for (var i = 0; i < arrChart.length; i++) {
        chartDataImpres.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].impressions])
        chartDataAmount.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].amount_spent])
        chartDataReach.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].reach])
      }
      $rootScope.graphImpres = chartDataImpres
      $rootScope.graphAmount = chartDataAmount
      $rootScope.graphReach = chartDataReach

      brandFacebookGraph()
    })

    $scope.storeCampaign = []
    $scope.otherCampaign = []
    $scope.campaignSocialPortal = []
    $scope.completedTasks = []
    $scope.completedPortal = []
    $scope.inCompletedPortal = []
    $scope.fbPromotionPortal = []
    var icount = null
    var jcount = null
    var kcount = null
    campaignFact.getsocialPortal().then(function (res) {
      $scope.social_portal = res.data.filter(function (val) {
        return val.campaigns.length > 0
      })
      for (var i = 0; i < $scope.social_portal.length; i++) {
        for (var j = 0; j < $scope.social_portal[i].campaigns.length; j++) {
          if ($scope.social_portal[i].campaigns[j].update == false) {
            if ($scope.social_portal[i].campaigns[j].strategy == 'store-promotion') {
              // $scope.storeCamp = $scope.social_portal[i].campaigns[0].name;
              $scope.storeCampaign.push($scope.social_portal[i].campaigns[j])
            } else {
              $scope.otherCampaign.push($scope.social_portal[i].campaigns[j])
            }
            for (var k = 0; k < $scope.social_portal[i].campaigns[j].tasks.length; k++) {
              if ($scope.social_portal[i].campaigns[j].tasks[k].completed == true) {
                if (icount != i) {
                  $scope.completedPortal.push($scope.social_portal[i])
                  icount = i
                }
                if ($scope.social_portal[i].campaigns[j].tasks[k].fbPromotionSettings) {
                  if (jcount != i) {
                    $scope.fbPromotionPortal.push($scope.social_portal[i])
                    jcount = i
                  }
                }
              } else {
                if (icount != i) {
                  if (kcount != i) {
                    $scope.inCompletedPortal.push($scope.social_portal[i])
                    kcount = i
                  }
                }
              }
            }
          }
        }
      }
      $scope.campwpList = []
      $scope.campSetting = []
      $scope.campSettingDate = []
      $scope.dateDiff = []
      $scope.dealerArray = []
      $scope.delearActivity = []
      $scope.fbPromotionPortal.forEach(function (val, index) {
        $scope.campwpList.push(val.campaigns)
      })
      $scope.campwpList.forEach(function (item) {
        $scope.localArray = []
        item.forEach(function (item2) {
          item2.tasks.forEach(function (item3) {
            if (item3.fbPromotionSettings != undefined) {
              $scope.localArray.push(item3.fbPromotionSettings)
            }
          })
        })
        $scope.campSetting.push($scope.localArray)
      })
      $scope.campSetting.forEach(function (item) {
        var localArray = []
        item.forEach(function (item2) {
          localArray.push({ startDate: item2.promotionStartDate, endDate: item2.promotionEndDate })
        })
        $scope.campSettingDate.push(localArray)
      })
      var datee = new Date()
      for (var i = 0; i < $scope.campSettingDate.length; i++) {
        var localArray = []
        for (var j = 0; j < $scope.campSettingDate[i].length; j++) {
          if ($scope.campSettingDate[i][j].startDate != null) {
            var dateStart = new Date($scope.campSettingDate[i][j].startDate)
            var timeDiff = Math.abs(datee.getTime() - dateStart.getTime())
            var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
            localArray.push(diffDays)
          }
        }
        $scope.dateDiff.push(localArray)
      }
      $scope.dateDiff.forEach(function (item) {
        var less30 = []
        var less50 = []
        var great50 = []
        item.forEach(function (item2) {
          if (item2 <= 30) {
            less30.push(item2)
          } else if (item2 < 50) {
            less50.push(item2)
          } else {
            great50.push(item2)
          }
        })
        $scope.dealerArray.push({ less30: less30, less50: less50, great50: great50 })
      })
      // console.log('hiiii', $scope.dealerArray);
      $scope.dealerArray.forEach(function (item) {
        //   console.log('in for each', item);
        var highlyActive = false
        var active = false
        var inactive = false
        //   console.log('item3..', item.less30, item.less30.length);
        if (item.less30.length > 0) {
          highlyActive = true
        } else if (item.less50.length > 0) {
          active = true
        } else {
          inactive = true
        }
        $scope.delearActivity.push({ highlyActive: highlyActive, active: active, inactive: inactive })
      })
      $scope.highlyActiveDealerNum = 0
      $scope.activeDealerNum = 0
      $scope.inactiveDealerNum = 0
      for (var i = 0; i < $scope.delearActivity.length; i++) {
        if ($scope.delearActivity[i].highlyActive == true) {
          $scope.highlyActiveDealerNum++
        } else if ($scope.delearActivity[i].active == true) {
          $scope.activeDealerNum++
        } else {
          $scope.inactiveDealerNum++
        }
      }
      pieChart()
    })

    campaignFact.getAdminData().then(function (res) {
      console.log('res', res)
      $rootScope.adminmetaData = res
      var actId = 'act_555006124612900'
      var access_ToOken = 'EAACnXTGSrFEBACkRaEyynag1k9glXyFyepqPMmSXqhdqQPciMcHbLXEhT3IU9hE49BvPNFsqjOZCzoE4kGHpafiM8oJVYrjCVTIyUWqxvdgin98hFG5pBO60VZAh3GjoUVJspdZCFKl0OFSZBucZCZAgGv6bcQ4ZBESIVLWDgKziwZDZD'
      // campaignFact.getCampaignExecute(res.accountId, res.access_token, 'last_90d').then(function (viewRes) {
      //     console.log('viewress', viewRes);
      // });
      campaignFact.readAdsets(res.accountId, res.access_token).then(function (campaign) {
        $scope.fbCampaign = []
        $scope.otherUniqCamp = []
        $scope.storeUniqCamp = []
        campaign.data.forEach(function (val) {
          campaignFact.getCampaignName(val.campaign.id, res.access_token).then(function (camp) {
            console.log('get campaign from adset', camp)
            $scope.fbCampaign.push({ id: camp.data.id, campaign_name: camp.data.name, lifetime_budget: val.lifetime_budget, start_time: camp.data.start_time, stop_time: camp.data.stop_time })
          })
        })
        console.log('$scope.fbCampaign', $scope.fbCampaign)
        for (var i = 0; i < $scope.otherCampaign.length; i++) {
          console.log('$scope.fbCampaignotherCampaign', $scope.otherCampaign)
          if ($scope.otherCampaign[i].company != null && $scope.otherCampaign[i].tasks[0]) {
            if ($scope.otherCampaign[i].company.name == $rootScope.user.companies[0].name && $scope.otherCampaign[i].tasks[0].defaultChecked == true) {
              for (var j = 0; j < $scope.fbCampaign.length; j++) {
                if ($scope.otherCampaign[i].name == $scope.fbCampaign[j].campaign_name) {
                  $scope.otherUniqCamp.push($scope.otherCampaign[i])
                }
              }
            }
          }
        }
        for (var i = 0; i < $scope.storeCampaign.length; i++) {
          if ($scope.storeCampaign[i].company != null && $scope.storeCampaign[i].tasks[0]) {
            if ($scope.storeCampaign[i].company.name == $rootScope.user.companies[0].name && $scope.storeCampaign[i].tasks[0].defaultChecked == true) {
              for (var j = 0; j < $scope.fbCampaign.length; j++) {
                if ($scope.storeCampaign[i].name == $scope.fbCampaign[j].campaign_name) {
                  $scope.storeUniqCamp.push($scope.storeCampaign[i])
                }
              }
            }
          }
        }
        $scope.otherUniqCamp = unique($scope.otherUniqCamp)
        $scope.storeUniqCamp = unique($scope.storeUniqCamp)
        console.log('$scope.otherUniqCamp', $scope.otherUniqCamp)
        console.log('$scope.storeUniqCamp', $scope.storeUniqCamp)
      })

      campaignFact.totalSpent(actId, access_ToOken).then(function (amount) {
        console.log('total spent', amount.data)
        $scope.total_spend = amount.data.amount_spent
        $scope.currency_ad = amount.data.currency
      })
      campaignFact.adAccountInsights(actId, access_ToOken, 'today').then(function (resp) {
        if (resp.status == 200) {
          console.log('resp', resp.data)
          if (resp.data.data.length == 0) {
            $scope.dealerContribution = 0
            $scope.impressionYear = 0
            $scope.costClick = 0
            if (hasOneDayPassed() == true) {
              console.log('has one day called')
              campaignFact.fbInsightsDB(resp.data).then(function (response) {
                console.log('saved insights', response)
              })
            }
          } else {
            $scope.dealerContribution = resp.data.data[0].spend
            $scope.impressionYear = resp.data.data[0].impressions
            $scope.amountSpents = resp.data.data[0].spend
            $scope.impressionsView = resp.data.data[0].impressions
            $scope.impressionsDate = resp.data.data[0].date_stop
            $scope.reachData = resp.data.data[0].reach
            $scope.costClick = resp.data.data[0].cpc
            if (hasOneDayPassed() == true) {
              console.log('has one day called')
              campaignFact.fbInsightsDB(resp.data).then(function (response) {
                console.log('saved insights', response)
              })
            }
          }
        }
      })
      campaignFact.getcampaignDB().then(function (camp) {
        var contribution = camp.data.filter(function (val) {
          return val.contribution != undefined
        })
        $scope.amountContri = 0
        // console.log('camp filter', contribution.length);
        if (contribution.length > 0) {
          angular.forEach(contribution, function (data) {
            $scope.amountContri += data.maxAmount
          })
        } else {

        }
      })
    })

    function hasOneDayPassed () {
      var date = new Date().toLocaleDateString()
      if (localStorage.getItem('todays_date') == date) {
        //  console.log('datehas', date, localStorage.getItem("todays_date"));
        return false
      } else {
        // console.log('datehasnot', date);
        localStorage.setItem('todays_date', date)
        return true
      }
    }
    // brandFacebookGraph();
    $scope.getInsightFunc = function (data) {
      var actId = 'act_555006124612900'
      var access_ToOken = 'EAACnXTGSrFEBAA9fa77JdIurQIkMgkmq0yZBvnbprZC0sjx8F0NtU9aO5fAnr12Sx4ZCh4X91wpz0ZCPSRiU6I3dbUX0E6uKUZBTfw7MnKpjbD8ZAVXaZAAatHEtlTznO3niZC45KuzFKkhv7Mja2hNW5xnodT2udXEZBTOPTSkXS9AZDZD'

      // campaignFact.getGraphReach(actId, access_ToOken, data).then(function (reach) {
      //     console.log('reach data', reach);
      // })
      // campaignFact.readInsight($scope.pageID, $scope.pageAccessToken,data).then(function (res) {
      //     console.log('res insight', res);
      //     var arrChart = res.data[0].values.filter(function (val) {
      //         return val.value != undefined;
      //     });
      //     console.log(">>>>>>>>", arrChart, arrChart.length);
      //     $rootScope.prevPAgeLikes = arrChart[arrChart.length - 1].value;
      //     var chartDataArray = [];
      //     for (var i = 0; i < arrChart.length; i++) {
      //         chartDataArray.push([Date.UTC(new Date(arrChart[i].end_time).getYear(), new Date(arrChart[i].end_time).getMonth(), new Date(arrChart[i].end_time).getDate()), arrChart[i].value])
      //     }
      //     $rootScope.arrrChart = chartDataArray;
      //     console.log(">>>>>>>>", chartDataArray);
      //     brandFacebookGraph();
      // })
    }
    function brandFacebookGraph () {
      Highcharts.chart('marketingContributionLinechart', {
        chart: {
          type: 'areaspline',
          zoomType: 'xy'
        },
        title: {
          verticalAlign: 'top',
          align: 'left',
          x: 10,
          y: 20,
          text: 'Marketing contributions'
        },
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },

        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            month: '%e. %b',
            year: '%b'
          }
        },
        yAxis: [{
          title: {
            text: 'Views & Reach'
          },
          labels: { enabled: true }
        }, {
          title: {
            text: 'Contribution'
          },
          labels: { enabled: true },
          opposite: true
        }
        ],
        tooltip: {
          formatter: function () {
            var locale = 'UTC'
            var s = '<b>' + new Date(this.x).getDate() + ' ' + new Date(this.x).toLocaleString(locale, { month: 'short' }) + '</b>'
            $.each(this.points, function () {
              s += '<br/>' + '<span style="color: ' + this.series.color + ' " > \u25CF </span>' + this.series.name + ' ' + this.y + '<br/>'
            })
            return s
          },

          shared: true
        },

        credits: {
          enabled: false
        },
        plotOptions: {
          areaspline: {
            fillOpacity: 0.5
          },
          series: {
            label: {
              connectorAllowed: false
            }
          }
        },
        series: [
          {
            name: 'Views in (k)',
            type: 'spline',
            data: $rootScope.graphImpres,
            color: '#22b14c',
            marker: {
              enabled: true,
              fillColor: '#22b14c',
              lineWidth: 2,
              lineColor: null // inherit from series
            }
          },
          {
            name: 'Campaign Reach',
            data: $rootScope.graphReach,
            color: '#b9bfc8',
            marker: {
              enabled: true,
              fillColor: '#FFFFFF',
              lineWidth: 2,
              lineColor: null // inherit from series
            }

          },
          {
            name: 'Contribution',
            data: $rootScope.graphAmount,
            color: '#6ca8e3',
            yAxis: 1,
            marker: {
              enabled: true,
              fillColor: '#FFFFFF',
              lineWidth: 2,
              lineColor: null // inherit from series
            }

          }
        ]
      })
    }
    function pieChart () {
      Highcharts.chart('dealerDonut', {
        chart: {
          type: 'pie'
        },
        title: {
          verticalAlign: 'top',
          align: 'left',
          x: 10,
          y: 20,
          text: 'Activity Dealernetwork'
        },
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        plotOptions: {
          pie: {
            shadow: false
          }
        },
        tooltip: {
          formatter: function () {
            return '<b>' + this.point.name + '</b>: ' + this.y + ' .00'
          }
        },
        series: [{
          // name: 'Browsers',
          size: '100%',
          innerSize: '80%',
          showInLegend: false,
          dataLabels: {
            enabled: false
          },
          data: [
            {
              y: $scope.highlyActiveDealerNum,
              name: 'Highly active dealers (1 campaign p. month)',
              color: '#000331'
            },
            {
              y: $scope.activeDealerNum,
              name: 'Active dealers (1 p. 50 days)',
              color: '#6094c2'
            },
            {
              y: $scope.inactiveDealerNum,
              name: 'Inactive dealers (1 p > 50 days)',
              color: '#e76700'
            },
            {
              y: $scope.inCompletedPortal.length,
              name: 'Potential Inactive dealers',
              color: '#fa9c43'
            }
          ]

        }]
      })
    }
    var unique = function (arr) {
      var newArr = []
      angular.forEach(arr, function (value, key) {
        var exists = false
        angular.forEach(newArr, function (val2, key) {
          if (angular.equals(value._id, val2._id)) { exists = true };
        })
        if (exists == false && value._id != '') { newArr.push(value) }
      })
      return newArr
    }
    // dealerDonut chart ends
    $scope.brandSelectArray = []
    $scope.storeSelected = []
    $scope.otherSelected = []
    $scope.isBrandSelect = function (data, ischecked, index) {
      if (ischecked == true) {
        $scope.brandSelectArray.push(angular.copy(data))
        console.log('$scope.brandSelectArray', $scope.brandSelectArray)
        for (var i = 0; i < $scope.brandSelectArray.length; i++) {
          for (var j = 0; j < $scope.storeUniqCamp.length; j++) {
            if ($scope.storeUniqCamp[j].brand.name == $scope.brandSelectArray[i]) {
              $scope.storeSelected.push($scope.storeUniqCamp[j])
            }
          }
          for (var j = 0; j < $scope.otherUniqCamp.length; j++) {
            if ($scope.otherUniqCamp[j].brand.name == $scope.brandSelectArray[i]) {
              $scope.otherSelected.push($scope.otherUniqCamp[j])
            }
          }
        }
        $scope.storeSelected = unique($scope.storeSelected)
        $scope.otherSelected = unique($scope.otherSelected)
        console.log('$scope.storeSelected', $scope.storeSelected)
      } else {
        $scope.storeSelected = []
        $scope.otherSelected = []
        $scope.brandSelectArray.splice($scope.brandSelectArray.indexOf(data), 1)
        if ($scope.brandSelectArray.length > 0) {
          for (var i = 0; i < $scope.brandSelectArray.length; i++) {
            for (var j = 0; j < $scope.storeUniqCamp.length; j++) {
              if ($scope.storeUniqCamp[j].brand.name == $scope.brandSelectArray[i]) {
                $scope.storeSelected.push($scope.storeUniqCamp[j])
              }
            }
            for (var j = 0; j < $scope.otherUniqCamp.length; j++) {
              if ($scope.otherUniqCamp[j].brand.name == $scope.brandSelectArray[i]) {
                $scope.otherSelected.push($scope.otherUniqCamp[j])
              }
            }
          }
          $scope.storeSelected = unique($scope.storeSelected)
          $scope.otherSelected = unique($scope.otherSelected)
        } else {
          $scope.storeSelected = []
          $scope.otherSelected = []
        }
      }
    }

    $scope.timeRangeFunc = function (rangeVal) {
      var actId = 'act_555006124612900'
      var access_ToOken = 'EAACnXTGSrFEBACkRaEyynag1k9glXyFyepqPMmSXqhdqQPciMcHbLXEhT3IU9hE49BvPNFsqjOZCzoE4kGHpafiM8oJVYrjCVTIyUWqxvdgin98hFG5pBO60VZAh3GjoUVJspdZCFKl0OFSZBucZCZAgGv6bcQ4ZBESIVLWDgKziwZDZD'
      campaignFact.adAccountInsights(actId, access_ToOken, rangeVal).then(function (resp) {
        if (resp.status == 200) {
          console.log('resp', resp.data)
          if (resp.data.data.length == 0) {
            console.log('if  called time range', resp)

            $scope.dealerContribution = 0
            $scope.impressionYear = 0
            $scope.amountSpents = 0
            $scope.impressionsView = 0
            $scope.impressionsDate = 0
            $scope.reachData = 0
            $scope.costClick = 0
          } else {
            console.log('else called time range', resp)
            $scope.dealerContribution = resp.data.data[0].spend
            $scope.amountSpents = resp.data.data[0].spend
            $scope.impressionYear = resp.data.data[0].impressions
            $scope.impressionsView = resp.data.data[0].impressions
            $scope.impressionsDate = resp.data.data[0].date_start
            $scope.reachData = resp.data.data[0].reach
            $scope.costClick = resp.data.data[0].cpc
          }
        }
      })
      // $scope.rangeStoreData = [];
      $scope.rangeOtherData = []
      $scope.storeSelected = []
      $scope.otherSelected = []
      for (var i = 0; i < $scope.storeUniqCamp.length; i++) {
        if ($scope.storeUniqCamp[i].tasks[0].fbPromotionSettings) {
          var date1 = new Date()
          var date2 = new Date($scope.storeUniqCamp[i].tasks[0].fbPromotionSettings.promotionStartDate)
          var timeDiff = Math.abs(date1.getTime() - date2.getTime())
          var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
          if (diffDays == 0 && rangeVal == 'today') {
            $scope.storeSelected.push($scope.storeUniqCamp[i])
          } else if (diffDays < 2 && rangeVal == 'yesterday') {
            $scope.storeSelected.push($scope.storeUniqCamp[i])
          } else if (diffDays < 4 && rangeVal == 'last_3d') {
            $scope.storeSelected.push($scope.storeUniqCamp[i])
          } else if (diffDays < 8 && rangeVal == 'last_7d') {
            $scope.storeSelected.push($scope.storeUniqCamp[i])
          } else if (diffDays < 15 && rangeVal == 'last_14d') {
            $scope.storeSelected.push($scope.storeUniqCamp[i])
          } else if (diffDays < 29 && rangeVal == 'last_28d') {
            $scope.storeSelected.push($scope.storeUniqCamp[i])
          } else if (diffDays < 31 && rangeVal == 'last_30d') {
            $scope.storeSelected.push($scope.storeUniqCamp[i])
          } else if (diffDays < 91 && rangeVal == 'last_90d') {
            $scope.storeSelected.push($scope.storeUniqCamp[i])
          } else if (rangeVal == 'last_month') {
            var dateNew = new Date()
            dateNew.setMonth(dateNew.getMonth(), 0)
            var timeDiffer = Math.abs(dateNew.getTime() - date2.getTime())
            var diffDayss = Math.ceil(timeDiffer / (1000 * 3600 * 24))
            if (diffDayss < 30) {
              $scope.storeSelected.push($scope.storeUniqCamp[i])
            }
          } else if (rangeVal == 'this_month') {
            var dateNew = new Date()
            var dateNeww = new Date()
            dateNew.setMonth(dateNew.getMonth(), 1)
            var timeDiffere = Math.abs(dateNeww.getTime() - dateNew.getTime())
            var diffDaysss = Math.ceil(timeDiffere / (1000 * 3600 * 24))
            var timeDiffer = Math.abs(dateNeww.getTime() - date2.getTime())
            var diffDayss = Math.ceil(timeDiffer / (1000 * 3600 * 24))
            if (diffDaysss >= diffDayss) {
              $scope.storeSelected.push($scope.storeUniqCamp[i])
            }
          } else if (rangeVal == 'last_year') {
            var dateNew = new Date()
            var yrs = dateNew.getFullYear() - 1
            var campyear = date2.getFullYear()
            if (yrs == campyear) {
              $scope.storeSelected.push($scope.storeUniqCamp[i])
            }
          } else if (rangeVal == 'this_year') {
            var dateNew = new Date()
            var yrs = dateNew.getFullYear()
            var campyear = date2.getFullYear()
            if (yrs == campyear) {
              $scope.storeSelected.push($scope.storeUniqCamp[i])
            }
          }
        }
      }

      for (var i = 0; i < $scope.otherUniqCamp.length; i++) {
        if ($scope.otherUniqCamp[i].tasks[0].fbPromotionSettings) {
          var date1 = new Date()
          var date2 = new Date($scope.otherUniqCamp[i].tasks[0].fbPromotionSettings.promotionStartDate)
          var timeDiff = Math.abs(date1.getTime() - date2.getTime())
          var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
          if (diffDays == 0 && rangeVal == 'today') {
            $scope.otherSelected.push($scope.otherUniqCamp[i])
          } else if (diffDays < 2 && rangeVal == 'yesterday') {
            $scope.otherSelected.push($scope.otherUniqCamp[i])
          } else if (diffDays < 4 && rangeVal == 'last_3d') {
            $scope.otherSelected.push($scope.otherUniqCamp[i])
          } else if (diffDays < 8 && rangeVal == 'last_7d') {
            $scope.otherSelected.push($scope.otherUniqCamp[i])
          } else if (diffDays < 15 && rangeVal == 'last_14d') {
            $scope.otherSelected.push($scope.otherUniqCamp[i])
          } else if (diffDays < 29 && rangeVal == 'last_28d') {
            $scope.otherSelected.push($scope.otherUniqCamp[i])
          } else if (diffDays < 31 && rangeVal == 'last_30d') {
            $scope.otherSelected.push($scope.otherUniqCamp[i])
          } else if (diffDays < 91 && rangeVal == 'last_90d') {
            $scope.otherSelected.push($scope.otherUniqCamp[i])
          } else if (rangeVal == 'last_month') {
            var dateNew = new Date()
            dateNew.setMonth(dateNew.getMonth(), 0)
            var timeDiffer = Math.abs(dateNew.getTime() - date2.getTime())
            var diffDayss = Math.ceil(timeDiffer / (1000 * 3600 * 24))
            if (diffDayss < 30) {
              $scope.otherSelected.push($scope.otherUniqCamp[i])
            }
          } else if (rangeVal == 'this_month') {
            var dateNew = new Date()
            var dateNeww = new Date()
            dateNew.setMonth(dateNew.getMonth(), 1)
            var timeDiffere = Math.abs(dateNeww.getTime() - dateNew.getTime())
            var diffDaysss = Math.ceil(timeDiffere / (1000 * 3600 * 24))
            var timeDiffer = Math.abs(dateNeww.getTime() - date2.getTime())
            var diffDayss = Math.ceil(timeDiffer / (1000 * 3600 * 24))
            if (diffDaysss >= diffDayss) {
              $scope.otherSelected.push($scope.otherUniqCamp[i])
            }
          } else if (rangeVal == 'last_year') {
            var dateNew = new Date()
            var yrs = dateNew.getFullYear() - 1
            var campyear = date2.getFullYear()
            if (yrs == campyear) {
              $scope.otherSelected.push($scope.otherUniqCamp[i])
            }
          } else if (rangeVal == 'this_year') {
            var dateNew = new Date()
            var yrs = dateNew.getFullYear()
            var campyear = date2.getFullYear()
            if (yrs == campyear) {
              $scope.otherSelected.push($scope.otherUniqCamp[i])
            }
          }
        }
      }
      $scope.storeSelected = unique($scope.storeSelected)
      $scope.otherSelected = unique($scope.otherSelected)
      campaignFact.getDBinsight().then(function (res) {
        var arrChart = res.data
        console.log('>>>>>>>>', arrChart)
        var date1 = new Date()
        var chartDataImpres = []
        var chartDataAmount = []
        var chartDataReach = []
        arrChart.forEach(function (val, i) {
          var date2 = new Date(val.date)
          var timeDiff = Math.abs(date1.getTime() - date2.getTime())
          var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
          if (diffDays == 0 && rangeVal == 'today') {
            chartDataImpres.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].impressions])
            chartDataAmount.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].amount_spent])
            chartDataReach.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].reach])
          } else if (diffDays < 2 && rangeVal == 'yesterday') {
            chartDataImpres.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].impressions])
            chartDataAmount.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].amount_spent])
            chartDataReach.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].reach])
          } else if (diffDays < 4 && rangeVal == 'last_3d') {
            chartDataImpres.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].impressions])
            chartDataAmount.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].amount_spent])
            chartDataReach.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].reach])
          } else if (diffDays < 8 && rangeVal == 'last_7d') {
            chartDataImpres.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].impressions])
            chartDataAmount.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].amount_spent])
            chartDataReach.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].reach])
          } else if (diffDays < 15 && rangeVal == 'last_14d') {
            chartDataImpres.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].impressions])
            chartDataAmount.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].amount_spent])
            chartDataReach.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].reach])
          } else if (diffDays < 29 && rangeVal == 'last_28d') {
            chartDataImpres.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].impressions])
            chartDataAmount.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].amount_spent])
            chartDataReach.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].reach])
          } else if (diffDays < 31 && rangeVal == 'last_30d') {
            chartDataImpres.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].impressions])
            chartDataAmount.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].amount_spent])
            chartDataReach.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].reach])
          } else if (diffDays < 91 && rangeVal == 'last_90d') {
            chartDataImpres.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].impressions])
            chartDataAmount.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].amount_spent])
            chartDataReach.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].reach])
          } else if (rangeVal == 'last_month') {
            var dateNew = new Date()
            dateNew.setMonth(dateNew.getMonth(), 0)
            var timeDiffer = Math.abs(dateNew.getTime() - date2.getTime())
            var diffDayss = Math.ceil(timeDiffer / (1000 * 3600 * 24))
            if (diffDayss < 30) {
              chartDataImpres.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].impressions])
              chartDataAmount.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].amount_spent])
              chartDataReach.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].reach])
            }
          } else if (rangeVal == 'this_month') {
            var dateNew = new Date()
            var dateNeww = new Date()
            dateNew.setMonth(dateNew.getMonth(), 1)
            var timeDiffere = Math.abs(dateNeww.getTime() - dateNew.getTime())
            var diffDaysss = Math.ceil(timeDiffere / (1000 * 3600 * 24))
            var timeDiffer = Math.abs(dateNeww.getTime() - date2.getTime())
            var diffDayss = Math.ceil(timeDiffer / (1000 * 3600 * 24))
            if (diffDaysss >= diffDayss) {
              chartDataImpres.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].impressions])
              chartDataAmount.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].amount_spent])
              chartDataReach.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].reach])
            }
          } else if (rangeVal == 'last_year') {
            var dateNew = new Date()
            var yrs = dateNew.getFullYear() - 1
            var campyear = date2.getFullYear()
            if (yrs == campyear) {
              chartDataImpres.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].impressions])
              chartDataAmount.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].amount_spent])
              chartDataReach.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].reach])
            }
          } else if (rangeVal == 'this_year') {
            var dateNew = new Date()
            var yrs = dateNew.getFullYear()
            var campyear = date2.getFullYear()
            if (yrs == campyear) {
              chartDataImpres.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].impressions])
              chartDataAmount.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].amount_spent])
              chartDataReach.push([Date.UTC(new Date(arrChart[i].date).getYear(), new Date(arrChart[i].date).getMonth(), new Date(arrChart[i].date).getDate()), arrChart[i].reach])
            }
          }
        })

        $rootScope.graphImpres = chartDataImpres
        $rootScope.graphAmount = chartDataAmount
        $rootScope.graphReach = chartDataReach
        // $rootScope.prevPAgeLike = arrChart[arrChart.length - 1].value;
        brandFacebookGraph()
      })
    }
    function formatDate (date) {
      var d = new Date(date)
      var month = '' + (d.getMonth() + 1)
      var day = '' + d.getDate()
      var year = d.getFullYear()

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      return [year, month, day].join('-')
    }
    $scope.campaignSelect = []
    $scope.isCampSelect = function (data, checked) {
      console.log('ddddddddd>>>>', data, checked)
      var checkedCampArray = []
      var totalBudget = 0
      var totalAmount = 0
      $scope.totalVIews = 0
      if (checked == true) {
        $scope.campaignSelect.push(data)
        for (var i = 0; i < $scope.campaignSelect.length; i++) {
          for (var j = 0; j < $scope.fbCampaign.length; j++) {
            if ($scope.campaignSelect[i] == $scope.fbCampaign[j]) {
              checkedCampArray.push($scope.fbCampaign[j])
              totalBudget += Number($scope.fbCampaign[j].lifetime_budget)
              var startDate = formatDate($scope.fbCampaign[j].start_time)
              var endDate = formatDate($scope.fbCampaign[j].stop_time)
              campaignFact.campaignInsights($scope.fbCampaign[j].id, $rootScope.adminmetaData.access_token, startDate, endDate).then(function (res) {
                if (res.data.data) {
                  $scope.totalVIews += Number(res.data.data[0].impressions)
                } else {
                  $scope.totalVIews = 0
                }
              })
            }
            for (var k = 0; k < $scope.storeUniqCamp.length; k++) {
              if ($scope.storeUniqCamp[k].name == $scope.fbCampaign[j].campaign_name) {
                if ($scope.storeUniqCamp[k].tasks[0].contribution && $scope.storeUniqCamp[k].tasks[0].contribution.maxAmount > 0) {
                  totalAmount += Number($scope.storeUniqCamp[k].tasks[0].contribution.maxAmount) * Number($scope.fbCampaign[j].lifetime_budget)
                }
              }
            }
            for (var k = 0; k < $scope.otherUniqCamp.length; k++) {
              if ($scope.otherUniqCamp[k].name == $scope.fbCampaign[j].campaign_name) {
                if ($scope.otherUniqCamp[k].tasks[0].contribution && $scope.otherUniqCamp[k].tasks[0].contribution.maxAmount > 0) {
                  totalAmount += Number($scope.otherUniqCamp[k].tasks[0].contribution.maxAmount) * Number($scope.fbCampaign[j].lifetime_budget)
                }
              }
            }
          }
        }
        $scope.contributionCompany = totalAmount - totalBudget
        $scope.contributionDealer = totalBudget
        console.log('budgetArray', checkedCampArray, totalBudget, totalAmount)
      } else {
        $scope.campaignSelect.splice($scope.campaignSelect.indexOf(data), 1)
        if ($scope.campaignSelect.length > 0) {
          for (var i = 0; i < $scope.campaignSelect.length; i++) {
            for (var j = 0; j < $scope.fbCampaign.length; j++) {
              if ($scope.campaignSelect[i] == $scope.fbCampaign[j]) {
                checkedCampArray.push($scope.fbCampaign[j])
                totalBudget += Number($scope.fbCampaign[j].lifetime_budget)
              }
              for (var k = 0; k < $scope.storeUniqCamp.length; k++) {
                if ($scope.storeUniqCamp[k].name == $scope.fbCampaign[j].campaign_name) {
                  if ($scope.storeUniqCamp[k].tasks[0].contribution && $scope.storeUniqCamp[k].tasks[0].contribution.maxAmount > 0) {
                    totalAmount += Number($scope.storeUniqCamp[k].tasks[0].contribution.maxAmount) * Number($scope.fbCampaign[j].lifetime_budget)
                  }
                }
              }
              for (var k = 0; k < $scope.otherUniqCamp.length; k++) {
                if ($scope.otherUniqCamp[k].name == $scope.fbCampaign[j].campaign_name) {
                  if ($scope.otherUniqCamp[k].tasks[0].contribution && $scope.otherUniqCamp[k].tasks[0].contribution.maxAmount > 0) {
                    totalAmount += Number($scope.otherUniqCamp[k].tasks[0].contribution.maxAmount) * Number($scope.fbCampaign[j].lifetime_budget)
                  }
                }
              }
            }
          }
          $scope.contributionCompany = totalAmount - totalBudget
          $scope.contributionDealer = totalBudget
          console.log('budgetArray', checkedCampArray, totalBudget)
        }
      }
    }
  }])
