prismanoteApp.controller('previewNewsItemModalController', ['$scope', '$uibModalInstance', 'newsItem', '$api', '$rootScope',
  function ($scope, $uibModalInstance, newsItem, $api, $rootScope) {
    $scope.error = ''
    $scope.newsItem = newsItem
    $scope.validNewsToAddInShop = false

    $api.get('check-news/status/' + $scope.newsItem.nameSlug + '/' + $rootScope.currentShop._id)
      .then(function (result) {
        $scope.validNewsToAddInShop = true
      })
      .catch(function (reason) {
        console.error('Error while add newsitem into shop', reason)
        $scope.validNewsToAddInShop = false
        $scope.error = reason.status === 401 ? "This news item is already in your shop. You can't add into again." : reason.data.message
      })

    $scope.confirm = function (status) {
      // Check first news item is exist into shop or not
      $uibModalInstance.close(status)
    }
  }])
