prismanoteApp.controller('brandCampaignController', ['$scope', '$rootScope', '$brandCampaign', '$company', '$state', '$news',
  function ($scope, $rootScope, $brandCampaign, $company, $state, $news) {
    $scope.activeSelectedCampaignType = 'carousel'
    $scope.news = {
      shopButtonText: 'view at jewelry store nearby',
      brandButtonText: 'visit the brand website'
    }
    $scope.filterSex = 'all'
    $scope.awsUrl = $rootScope.awsUrl
    $scope.activeTab = 0
    $scope.media = []
    $scope.landingpageimages = []

    $scope.urlPattern = /^(https?:\/\/)?(www\.)?[a-z0-9-]*\.?[a-z0-9-]+\.[a-z0-9-]+(\/[^<>]*)?$/
    // $scope.urlPattern = /^(https?:\/\/)?[www]*\.[a-z0-9-]*\.?[a-z0-9-]+\.[a-z0-9-]+(\/[^<>]*)?$/;
    // $scope.urlPattern = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi
    // console.log('new Date: ', new Date());
    $scope.campaign = {
      // startDate: new Date('2019-08-19T05:30:00.000Z'),
      tasks: [
        {
          fbPromotionSettings: {
            campaignType: $scope.activeSelectedCampaignType,
            sex: $scope.filterSex,
            isRetailerPaying: false,
            budgetPerRetailer: 0,
            goalReachingAmountRetailers: 0,
            facebook: {
              feeds: true,
              instantArticle: true,
              inStreamVideos: true,
              rightColumn: true,
              suggestedVideos: true,
              marketPlace: true,
              stories: false
            },
            instagram: {
              feeds: true,
              stories: false
            },
            audienceNetwork: {
              nativeBannerInterstitial: true,
              inStreamVideos: true,
              rewardedVideos: false
            },
            messenger: {
              inbox: true,
              stories: false
            }
          }
        }
      ]
    }
    $scope.todayPlusTenDays = new Date((+new Date() + 1210000000))
    $scope.todayPlusHunderdDays = new Date((+new Date() + 8640000000))

    function campaignInvitationEmail () {
      $scope.defaultDateInvitationEmail = ($scope.campaign && $scope.campaign.startDate) ? new Date($scope.campaign.startDate) : new Date()
      console.log('$scope.default invit: ', $scope.defaultDateInvitationEmail)
      $scope.defaultNineDate = new Date($scope.defaultDateInvitationEmail).setDate(($scope.defaultDateInvitationEmail).getDate() - 9)
      $scope.defaultTwoDate = new Date($scope.defaultDateInvitationEmail).setDate(($scope.defaultDateInvitationEmail).getDate() - 2)
      console.log('date: ', $scope.defaultDateInvitationEmail, new Date($scope.defaultNineDate), new Date($scope.defaultTwoDate))
      $scope.campaign.tasks[0].firstInvitationEmail = {
        date: new Date($scope.defaultNineDate)
      }
      $scope.campaign.tasks[0].secondInvitationEmail = {
        date: new Date($scope.defaultTwoDate)
      }

      $scope.startDateOptionsFirstEmail = {
        minDate: new Date((+new Date() + 345600000)),
        maxDate: new Date($scope.campaign.startDate)
      }

      $scope.startDateOptionsSecondEmail = {
        minDate: new Date((+new Date() + 432000000)),
        maxDate: new Date($scope.campaign.startDate)
      }
    }

    campaignInvitationEmail()

    $scope.startDateOptions = {
      minDate: $scope.todayPlusTenDays,
      maxDate: $scope.campaign.endDate,
      dateDisabled: disabled
    }

    // if ($scope.campaign && $scope.campaign.startDate) {
    //     $scope.startDateOptionsFirstEmail = {
    //         maxDate: $scope.campaign.startDate,
    //         dateDisabled: disabled
    //     }
    // }

    // Calling from ng-init
    $scope.getCompany = function () {
      $scope.loading = true
      $company.getCompany().then(function (company) {
        $scope.loading = false
        $scope.company = company
        console.log('company: ', $scope.company)
        if ($scope.company && $scope.company.phone) $scope.companyphone = $scope.company.phone.mobilePhone || $scope.company.phone.landLine || ''
      }).catch(function (error) {
        $scope.loading = false
        $scope.showError = true
        $scope.errorMessage = 'Something went wrong while fetching company detail'
      })
    }

    $scope.toTab0 = function () {
      $scope.campaign.currentStep = 0
      $scope.activeTab = 0
      $scope.showError = false
    }

    $scope.toTab1 = function () {
      $scope.campaign.currentStep = 1
      $scope.activeTab = 1
      $scope.showError = false
    }

    $scope.toTab2 = function () {
      $scope.campaign.currentStep = 2
      $scope.activeTab = 2
      $scope.showError = false
    }

    $scope.toTab3 = function () {
      $scope.campaign.currentStep = 3
      $scope.activeTab = 3
      $scope.showError = false
    }

    $scope.toTab4 = function () {
      $scope.campaign.currentStep = 4
      $scope.activeTab = 4
      $scope.showError = false
    }

    $scope.toTab5 = function () {
      $scope.campaign.currentStep = 5
      $scope.activeTab = 5
      $scope.showError = false
    }

    $scope.toTab6 = function () {
      $scope.campaign.currentStep = 6
      $scope.activeTab = 6
      $scope.showError = false
    }

    // First Step - Creating campaing
    $scope.createCampaign = function () {
      if ($scope.campaign && $scope.campaign.brand && $scope.campaign.brand._id) {
        $scope.loading = true
        $brandCampaign.createCampaign($scope.campaign, $scope.company._id).then(function (response) {
          $scope.loading = false
          $scope.showError = false
          console.log('createCampaign: ', response)
          $scope.campaign = response.data
          $scope.toTab1()
        }).catch(function (error) {
          $scope.loading = false
          $scope.showError = true
          $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
        })
      } else {
        $scope.showError = true
        $scope.errorMessage = 'Please select company properly from the search list'
      }
    }

    // Second Step - Content for consumer
    $scope.contentForConsumer = function () {
      // $scope.campaign = $scope.campaignRes;
      // console.log('campaigng: ', $scope.campaign);
      if ($scope.campaign && $scope.campaign.tasks && $scope.campaign.tasks.length && $scope.campaign.tasks[0].resultsBrand && $scope.campaign.tasks[0].resultsBrand.redirectLink) {
        var link = $scope.campaign.tasks[0].resultsBrand.redirectLink
        if (link.substring(0, 7) !== 'http://' && link.substring(0, 8) !== 'https://') {
          $scope.campaign.tasks[0].resultsBrand.redirectLink = 'http://' + link
        }
      }
      console.log('link: ', link, $scope.campaign.tasks[0].resultsBrand.redirectLink)
      var isValidMedia = true
      if ($scope.activeSelectedCampaignType == 'carousel') {
        if ($scope.media.length < 2 || $scope.media.length > 8) {
          isValidMedia = false
          $scope.errorMessage = 'When you choose the carousel option, you should upload more than 1 and less than 9 images'
          $scope.showError = true
        }
      } else if ($scope.activeSelectedCampaignType == 'single-image') {
        if ($scope.media.length !== 1) {
          isValidMedia = false
          $scope.errorMessage = 'In single-image type, image must be equal to one'
          $scope.showError = true
        }
      } else {
        if ($scope.media.length !== 1) {
          isValidMedia = false
          $scope.errorMessage = 'In single-video type, video length must be equal to one'
          $scope.showError = true
        }
      }

      if (isValidMedia) {
        $scope.loading = true
        $brandCampaign.contentForConsumerUploadImage($scope.media, $scope.campaign.name).then(function (media) {
          var body = {
            campaignStep: 'contentForConsumer',
            campaignId: $scope.campaign._id,
            tasks: $scope.campaign.tasks
          }
          body.tasks[0].media = media
          console.log('mde: ', media)
          console.log('mdbody.tasks[0].mediae: ', body.tasks[0].media)
          $brandCampaign.contentForConsumer(body).then(function (response) {
            console.log('contentForConsumer: ', response)
            $scope.showError = false
            $scope.loading = false
            $scope.campaign = response.data.data
            $scope.toTab2()
          }).catch(function (error) {
            $scope.loading = false
            $scope.showError = true
            $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
          })
        }).catch(function (error) {
          $scope.loading = false
          $scope.showError = true
          $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
        })
      }
    }

    // Third Step - Creating News using this
    $scope.createLandingPage = function () {
      if (!$scope.news.name) {
        $scope.showError = true
        $scope.errorMessage = 'News name is required'
      } else {
        if ($scope.landingpageimages && $scope.landingpageimages.length) {
          $scope.loading = true
          console.log('$scope.landingpageimages: ', $scope.landingpageimages)
          $brandCampaign.LandingPageUploadImage($scope.landingpageimages, $scope.news.name).then(function (images) {
            console.log('$scope.images: ', images)
            CreateNews(images)
            $scope.loading = false
          }).catch(function (error) {
            console.log('error: ', error)
            $scope.loading = false
            $scope.showError = true
            $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
          })
        } else {
          CreateNews()
        }
      }

      function CreateNews (images) {
        var news = {
          name: $scope.news.name,
          content: $scope.news.content,
          summary: ($scope.campaign.tasks[0].media[0] && $scope.campaign.tasks[0].media[0].description ? $scope.campaign.tasks[0].media[0].description : $scope.campaign.name),
          isPublished: false,
          language: $rootScope.language,
          brand: {
            _id: $scope.brand._id,
            name: $scope.brand.name
          },
          shopButtonText: $scope.news.shopButtonText,
          brandButtonText: $scope.news.brandButtonText,
          socialMediaPixels: {
            facebook: $scope.news.socialMediaPixels && $scope.news.socialMediaPixels.facebook ? $scope.news.socialMediaPixels.facebook : undefined,
            google: $scope.news.socialMediaPixels && $scope.news.socialMediaPixels.google ? $scope.news.socialMediaPixels.google : undefined
          },
          images: (images && images.length ? images : []),
          isCampaignNews: true,
          campaign: {
            isCampaign: true,
            campaignId: $scope.campaign._id,
            taskId: $scope.campaign.tasks[0]._id
          }
        }
        console.log('news: ', news)
        $scope.loading = true
        $brandCampaign.AddNewsUsingLandignPage(news).then(function (response) {
          console.log('neww repsonse', response)
          $scope.loading = false
          $scope.showError = false
          $scope.campaign.tasks[0].newsId = response.newsItem._id
          $scope.toTab3()
        }).catch(function (error) {
          console.log('final error: ', error)
          $scope.loading = false
          $scope.showError = true
          $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
        })
      }
      if ($scope.landingpageimages[0] || $scope.landingpageimages[1] || !$scope.news.name) {

      } else {
        $scope.showError = true
        $scope.errorMessage = 'Please upload image first'
      }
    }

    // Fourth Step - Creating Target Group Settings
    $scope.createTargetGroupSettings = function () {
      $scope.loading = true
      var body = {
        campaignId: $scope.campaign._id,
        taskId: $scope.campaign.tasks[0]._id,
        tasks: $scope.campaign.tasks
      }
      $brandCampaign.addTargetGroupSettings(body).then(function (response) {
        console.log('addTargetGroupSettings: ', response)
        $scope.loading = false
        $scope.showError = false
        $scope.campaign = response.data.data
        $scope.toTab4()
      }).catch(function (error) {
        console.log('errorrrr : ', error)
        $scope.loading = false
        $scope.showError = true
        $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
      })
    }

    // Fifth Step - Save Retaile Email Address
    $scope.saveRetailerEmail = function () {
      if ($scope.campaign._id && $scope.campaign.emailReceivers.added) {
        var body = {
          _id: $scope.campaign._id,
          emailReceivers: $scope.campaign.emailReceivers.added
        }
        $scope.loading = true
        $brandCampaign.saveRetailerEmail(body).then(function (response) {
          $scope.loading = false
          $scope.showError = false
          $scope.toTab5()
          console.log('response: ', response)
          $scope.campaign = response.data.data
          campaignInvitationEmail()
        }).catch(function (error) {
          console.log('errorrrr 123 : ', error)
          $scope.loading = false
          $scope.showError = true
          $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
        })
      } else {
        $scope.showError = true
        $scope.errorMessage = 'Invalid Data'
      }
    }

    // Sixth Step - Showing summary
    $scope.showSummary = function () {
      // $scope.toTab6();
      if ($scope.campaign && $scope.campaign.tasks.length && $scope.campaign.tasks[0] &&
                $scope.campaign.tasks[0].firstInvitationEmail && $scope.campaign.tasks[0].firstInvitationEmail.date &&
                $scope.campaign.tasks[0].secondInvitationEmail && $scope.campaign.tasks[0].secondInvitationEmail.date) {
        $scope.loading = true
        var body = {
          campaignId: $scope.campaign._id,
          taskId: $scope.campaign.tasks[0]._id,
          firstInvitationEmaildate: $scope.campaign.tasks[0].firstInvitationEmail.date,
          secondInvitationEmaildate: $scope.campaign.tasks[0].secondInvitationEmail.date
        }
        $brandCampaign.saveInvitationEmailDate(body).then(function (response) {
          $scope.loading = false
          $scope.showError = false
          $scope.toTab6()
          // console.log('response: ', response);
          // $scope.campaign = response.data.data;
        }).catch(function (error) {
          console.log('errorrrr 123 : ', error)
          $scope.loading = false
          $scope.showError = true
          $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
        })
      } else {
        $scope.showError = true
        $scope.errorMessage = 'Prefill the date properly'
      }
    }

    // Seventh Step - Send Mail to Admin
    $scope.sentEmailToAdmin = function () {
      // $scope.campaign._id = '5c993befd6f0840634c4ac64'; // Testing purpose
      if ($scope.campaign._id) {
        var body = {
          campaignId: $scope.campaign._id,
          campaignStep: 'sentEmailToAdmin',
          isEmailSentToAdmin: true,
          currentStep: 6,
          language: $rootScope.language
        }
        $brandCampaign.sentEmailToAdmin(body).then(function (response) {
          $scope.loading = false
          $scope.isSentToAdmin = true
          setTimeout(function () {
            $state.go('brand.campaigns')
          }, 3000)
        }).catch(function (error) {
          console.log('errorrrr 123 : ', error)
          $scope.loading = false
          $scope.showError = true
          $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
        })
      } else {
        $scope.showError = true
        $scope.errorMessage = 'Invalid Data'
      }
    }

    $scope.getWholesalerBrands = function () {
      if ($scope.currentWholesaler && $scope.currentWholesaler.brands) {
        $scope.campaign.brand = null
        $scope.brands = $scope.currentWholesaler.brands
      } else {
        getItems('brands')
      }
    }

    $scope.searchBrands = function (str) {
      return $brandCampaign.searchItems(str, $scope.brands)
    }

    $scope.brandSelected = function (selected) {
      $scope.brand = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug
      }
      $scope.campaign.brand = $scope.brand
    }

    // Changing the class of the campaigntype
    $scope.changeActiveSelectedCampaignType = function (activeSelectedCampaignType) {
      $scope.activeSelectedCampaignType = activeSelectedCampaignType
      $scope.campaign.tasks[0].fbPromotionSettings.campaignType = activeSelectedCampaignType
      $scope.addMedia(true) // Have to change the image and all the stuff
    }

    // Start Media Functions
    $scope.addMedia = function (isChangeCampaignType) {
      if (!isChangeCampaignType) $scope.media.push({})
      $scope.isInActiveAddMediaButton = false
      $scope.carouselMediaButton = false
      if ($scope.activeSelectedCampaignType == 'carousel') {
        // if ($scope.media.length >= 8) $scope.isInActiveAddMediaButton = true;
        console.log($scope.media.length)
        if ($scope.media.length > 1 || $scope.media.length < 9) {
          console.log('insise')
          $scope.isInActiveAddMediaButton = true
        }
        if ($scope.media.length >= 8) $scope.carouselMediaButton = true
      } else if ($scope.activeSelectedCampaignType == 'single-image') {
        $scope.media.splice(1)
        if ($scope.media.length) $scope.isInActiveAddMediaButton = true
      } else if ($scope.activeSelectedCampaignType == 'single-video') {
        $scope.media.splice(1)
        if ($scope.media.length) $scope.isInActiveAddMediaButton = true
      }
      console.log('flag: ', $scope.isInActiveAddMediaButton)
    }

    $scope.removeMedia = function (mode, index) {
      // For remove the image form Images
      $scope.isInActiveAddMediaButton = false
      $scope.carouselMediaButton = false
      if (mode == 'scope') $scope.media.splice(index, 1)
      else $scope.media.media.splice(index, 1)
    }
    // End Media Functions

    $scope.uploadFiles = function (file, index) {
      $scope.landingpageimages[index] = file[0]
    }

    $scope.removeLandingImage = function (index) {
      $scope.landingpageimages.splice(index, 1)
    }

    $scope.setSexFilter = function (filterSex) {
      $scope.filterSex = filterSex
      $scope.campaign.tasks[0].fbPromotionSettings.sex = filterSex
    }

    // It will fire an api and get the data
    function getItems (type) {
      $scope.loading = true
      $brandCampaign.getItems(type).then(function (response) {
        $scope.loading = false
        $scope[type] = response
      }).catch(function (error) {
        $scope.loading = false
        $scope.showError = true
        $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
      })
    };

    // Function is for the weekdays can be selected for the campaign
    function disabled (data) {
      var date = data.date
      var mode = data.mode
      return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6)
    }

    $scope.checkDefaultPromotionSettings = function () {
      var facebook = $scope.campaign.tasks[0].fbPromotionSettings.facebook
      var instagram = $scope.campaign.tasks[0].fbPromotionSettings.instagram
      var audienceNetwork = $scope.campaign.tasks[0].fbPromotionSettings.audienceNetwork
      var messenger = $scope.campaign.tasks[0].fbPromotionSettings.messenger
      $scope.facebook = true
      $scope.instagram = true
      $scope.audienceNetwork = true
      $scope.messenger = true
      if (!facebook.feeds || !facebook.instantArticle || !facebook.rightColumn || !facebook.suggestedVideos || !facebook.marketPlace || !facebook.inStreamVideos) {
        $('#facebook').prop('checked', false)
      } else {
        $('#facebook').prop('checked', true)
      }
      if (!instagram.feeds) {
        $('#instagram').prop('checked', false)
      } else {
        $('#instagram').prop('checked', true)
      }
      if (!audienceNetwork.nativeBannerInterstitial || !audienceNetwork.inStreamVideos) {
        $('#audienceNetwork').prop('checked', false)
      } else {
        $('#audienceNetwork').prop('checked', true)
      }
      if (!messenger.inbox) {
        $('#messenger').prop('checked', false)
      } else {
        $('#messenger').prop('checked', true)
      }
    }

    $scope.checkDefaultPromotionSettings() // Default value of checkbox for the step number 4th Target Group Settings

    $scope.facebookCheckBox = function (isChecked) {
      var facebook = $scope.campaign.tasks[0].fbPromotionSettings.facebook
      if (isChecked) facebook.feeds = facebook.instantArticle = facebook.rightColumn = facebook.suggestedVideos = facebook.marketPlace = facebook.inStreamVideos = true
      else facebook.feeds = facebook.instantArticle = facebook.rightColumn = facebook.suggestedVideos = facebook.marketPlace = facebook.inStreamVideos = false
    }

    $scope.instagramCheckBox = function (isChecked) {
      var instagram = $scope.campaign.tasks[0].fbPromotionSettings.instagram
      if (isChecked) instagram.feeds = true
      else instagram.feeds = false
    }

    $scope.audienceNetworkCheckBox = function (isChecked) {
      var audienceNetwork = $scope.campaign.tasks[0].fbPromotionSettings.audienceNetwork
      if (isChecked) audienceNetwork.nativeBannerInterstitial = audienceNetwork.inStreamVideos = true
      else audienceNetwork.nativeBannerInterstitial = audienceNetwork.inStreamVideos = false
    }

    $scope.messengerCheckBox = function (isChecked) {
      var messenger = $scope.campaign.tasks[0].fbPromotionSettings.messenger
      if (isChecked) messenger.inbox = true
      else messenger.inbox = false
    }

    // ALERT
    // BELOW CODE NEEDED FOR FUTURE. DONT REMOVED IT AS IT NOT IN SCOPE FOR NOW
    // $scope.changeRetailerPayingSwitch = function () {
    //     if (!$scope.campaign.tasks[0].fbPromotionSettings.isRetailerPaying) $scope.campaign.tasks[0].fbPromotionSettings.budgetPerRetailer = 0;
    // }

    // $scope.changeRetailerBudget = function () {
    //     if (!$scope.campaign.tasks[0].fbPromotionSettings.budgetPerRetailer) {
    //         $scope.campaign.tasks[0].fbPromotionSettings.isRetailerPaying = false;
    //         // $scope.campaign.tasks[0].fbPromotionSettings.budgetPerRetailer = 0;
    //     }
    // }
  }])
