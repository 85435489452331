prismanoteApp.controller('printLabelModalController', ['$scope', '$uibModalInstance', '$rootScope', 'product', 'loadProduct', '$api', 'FileSaver', '$http', '$shop', '$label', '$jspm', '$language', 'labelDescription', '$log', 'empty',
  function ($scope, $uibModalInstance, $rootScope, product, loadProduct, $api, FileSaver, $http, $shop, $label, $jspm, $language, labelDescription, $log, empty) {

  $scope.calibrateCollapse = true
  $scope.fieldsCollapse = true
  $scope.empty = empty

  //$scope.jspm = $rootScope.currentShop.printNode || $rootScope.currentShop.printNode.enabled === false

  loadProduct = !$scope.empty

  $scope.getNewLabelData = function () {
    //Determine if modal is called as shop or company
    if($rootScope.currentShop && $rootScope.currentShop._id) {
      if (!$rootScope.currentShop.newLabel || $rootScope.currentShop.newLabel === false) {
        return
      }
      $label.getLabels($rootScope.currentShop._id, null, false)
        .then(function (templates) {
          $scope.labels = templates
          if($scope.labels && $scope.labels.length > 0) {
            $scope.selectedLabel = $scope.labels[0]
            $scope.labelChanged($scope.selectedLabel)
          }
        })
        .catch(function (reason){
          $log.error('error when fetching labels for shop', reason)
        })
    } else if( $rootScope.currentCompany && $rootScope.currentCompany._id) {
      $label.getCompanyLabels($rootScope.currentCompany._id, null, false)
        .then(function (templates) {
          $scope.labels = templates
          if($scope.labels && $scope.labels.length > 0) {
            $scope.selectedLabel = $scope.labels[0]
            $scope.labelChanged($scope.selectedLabel)

          }
        })
        .catch(function (reason){
          $log.error('error when fetching labels for company 111', reason)
        })
    }
  }

    if (loadProduct) {
      if($rootScope.currentShop && $rootScope.currentShop.nameSlug) {
        $api.get('shop/own-shop-product-by-nameSlug/' + product._id + '/' + $rootScope.currentShop.nameSlug)
          .then(function (response) {
            $scope.product = Object.assign({}, response.data.product, response.data.shopProduct, response.data.collections)
            $scope.product.sellingPrice = response.data.shopProduct.price * ((response.data.shopProduct.priceVat / 100) + 1)
            if (response.data.brand) {
              $scope.product.brand = response.data.brand
            }
            $scope.product.supplier = response.data.supplier
            $scope.product.lastPurchaseOrderDeliveryDate = response.data.lastPurchaseOrderDeliveryDate
            $scope.product.collections = response.data.collections
            if (labelDescription) {
              $scope.product.labelDescription = labelDescription
            }

            $scope.getNewLabelData()
          })
          .catch(function (reason) {
            $log.error('ERROR_WHILE_FETCHING_PRODUCT', reason)
          })
      } else if($rootScope.currentCompany && $rootScope.currentCompany._id) {
        $api.get('company/product/' + $rootScope.currentCompany._id + '/' + product._id, null, null, 2)
          .then(function(response) {
            $scope.product = Object.assign({}, response.data.data.product, response.data.data.companyProduct, response.data.data.collections)
            $scope.product.sellingPrice = response.data.data.companyProduct.price * ((response.data.data.companyProduct.priceVat / 100) + 1)
            if (response.data.brand) {
              $scope.product.brand = response.data.data.brand
            }
            $scope.product.supplier = response.data.data.supplier
            $scope.product.lastPurchaseOrderDeliveryDate = response.data.data.lastPurchaseOrderDeliveryDate
            $scope.product.collections = response.data.data.collections
            if (labelDescription) {
              $scope.product.labelDescription = labelDescription
            }
            $scope.getNewLabelData()
          })
          .catch(function (reason) {
            $log.error('ERROR_WHILE_FETCHING_PRODUCT', reason)
          })
      }
    } else {
      if(!$scope.empty) {
        $scope.product = product
        $scope.getNewLabelData()
        if (labelDescription) {
          $scope.product.labelDescription = labelDescription
        }
      } else {
        //Create empty product with some fields
        $scope.product = {
          _id: 'empty_product',
          name: '',
          brand: '',
          description: '',
          price: 0,
          productNumber: ''
        }
        $scope.getNewLabelData()
        $scope.fieldsCollapse = false
      }

    }

    $scope.gemKinds = [
      { key: 'AM', value: 'amethyst' },
      { key: 'AQ', value: 'aquamarine' },
      { key: 'AV', value: 'aventurine' },
      { key: 'CA', value: 'carnelean' },
      { key: 'CI', value: 'citrine' },
      { key: 'MC', value: 'madeira citrine' },
      { key: 'DI', value: 'diamond' },
      { key: 'EM', value: 'emerald' },
      { key: 'GR', value: 'garnet' },
      { key: 'HE', value: 'hematite' },
      { key: 'JA', value: 'jade' },
      { key: 'LB', value: 'labradorite' },
      { key: 'LA', value: 'lapis' },
      { key: 'LZ', value: 'lazuli' },
      { key: 'MS', value: 'moonstone' },
      { key: 'ON', value: 'onyx' },
      { key: 'OP', value: 'opal' },
      { key: 'PL', value: 'pearl' },
      { key: 'PA', value: 'pearl akoya' },
      { key: 'PT', value: 'pearl tahiti' },
      { key: 'PW', value: 'pearl sweet water' },
      { key: 'PS', value: 'pearl south sea' },
      { key: 'PY', value: 'pearl synthetical' },
      { key: 'PC', value: 'pearl cultive' },
      { key: 'PE', value: 'peridote' },
      { key: 'QU', value: 'quartz' },
      { key: 'RC', value: 'rockcrystal' },
      { key: 'RQ', value: 'rosequartz' },
      { key: 'RH', value: 'ruby' },
      { key: 'SA', value: 'sapphire' },
      { key: 'SQ', value: 'smoky quartz' },
      { key: 'SP', value: 'spinel' },
      { key: 'TP', value: 'topaz' },
      { key: 'TR', value: 'tourmaline' },
      { key: 'TQ', value: 'turquoise' },
      { key: 'ZI', value: 'zirconia' },
      { key: 'CR', value: 'crystal' },
      { key: 'ST', value: 'stratum stone' },
      { key: 'SC', value: 'swarovski crystal' },
      { key: 'SY', value: 'synthetic stone' },
      { key: 'EN', value: 'enamel' },
      { key: 'CM', value: 'cameo' },
      { key: 'BC', value: 'blood coral' },
      { key: 'AG', value: 'agate' },
      { key: 'AZ', value: 'amazone' },
      { key: 'AZN', value: 'amazonite' },
      { key: 'AB', value: 'amber' },
      { key: 'RS', value: 'rhinestone' },
      { key: 'BR', value: 'beryll' },
      { key: 'CC', value: 'calcite' },
      { key: 'CE', value: 'cat eay' },
      { key: 'CH', value: 'chalcedony' },
      { key: 'LQ', value: 'lemon quartz' },
      { key: 'EP', value: 'epidote' },
      { key: 'FL', value: 'fluorite' },
      { key: 'GL', value: 'glass' },
      { key: 'HT', value: 'heliotrope' },
      { key: 'IQ', value: 'iris quartz' },
      { key: 'JP', value: 'jasper' },
      { key: 'CS', value: 'ceramics' },
      { key: 'CD', value: 'corundum' },
      { key: 'LT', value: 'lazurite' },
      { key: 'ML', value: 'malachite' },
      { key: 'MR', value: 'murano glass' },
      { key: 'NS', value: 'natural stone' },
      { key: 'NP', value: 'nephrite' },
      { key: 'SD', value: 'sodalite' },
      { key: 'TE', value: 'tiger eye' },
      { key: 'UV', value: 'uvarovite' },
      { key: 'WV', value: 'wildvinite' },
      { key: 'XS', value: 'xylopile' },
      { key: 'JG', value: 'jargon' },
      { key: 'FP', value: 'fire post' },
      { key: 'SL', value: 'slate' },
      { key: 'PH', value: 'phosphorite' }
    ]

    function getShortNameFromName (name) {
      var result = ''
      for (var i = 0; i < $scope.gemKinds.length; i++) {
        if ($scope.gemKinds[i].value === name) {
          result = $scope.gemKinds[i].key
          break
        }
      }
      return result
    }

    function getDiamondInfo () {
      if ($scope.product.diamonds && $scope.product.diamonds.length > 0 && $scope.product.diamonds[0]) {
        var diamond = $scope.product.diamonds[0]
        var gemKindSN = getShortNameFromName(diamond.gemKind)
        var gemKindSN = getShortNameFromName(diamond.gemKind)
        var diamondInfo = ''
        if (gemKindSN) {
          diamondInfo += gemKindSN + ','
        }
        if (diamond.gemPurity && diamond.gemPurity.code) {
          diamondInfo += diamond.gemPurity.code + ','
        }
        if (diamond.gemColor && diamond.gemColor.code) {
          if(String(diamond.gemColor.code).length === 1) {
            diamondInfo += diamond.gemColor.code + ','
          }
        }
        if (diamond.caratWeight) {
          diamondInfo += diamond.caratWeight
        }
        return diamondInfo
      } else {
        return null
      }
    }

    function getCollectionInfo (type, index) {
      if (!index) {
        index = 0
      }

      if (!type || !$scope.product || !$scope.product.collections ||
        !$scope.product.collections[type] || $scope.product.collections[type].length === 0 ||
        (index > 0 && $scope.product.collections[type].length < index)) {
        return null
      }

      return $scope.product.collections[type][index]
    }

    function getProductValue (value) {
      var name = false
      if (value === 'name') {
        name = true
        value = $rootScope.language + '.' + value
      } else if (value === 'brand.name') {
        // We need to check if their is an alias
        if (!$scope.product.brand) {
          return
        }
        var brand = _.get($scope.product, 'brand')
        if (brand.alias) {
          return brand.alias
        }
        if (brand.name) {
          return brand.name
        }
        return ''
      }
      var productValue = _.get($scope.product, value)
      if (value === 'lastPurchaseOrderDeliveryDate' && productValue !== null && typeof productValue !== 'undefined') {
        return new Date(productValue).toISOString()
      }
      if (productValue) {
        return productValue
      } else {
        if (name) {
          return _.get($scope.product, 'name')
        }
        return ''
      }
    }

    function getReplaceValue (name) {
      var placeholder = _.find($rootScope.labelPlaceholders, { name: name })
      if (placeholder) {
        if (placeholder.value === 'diamondinfo') {
          var value = getDiamondInfo()
        } else if (placeholder.value === 'ownCollection') {
          var collection = getCollectionInfo('privateLabelCollections')
          if (collection) {
            var value = collection[$rootScope.language].name
          }
        } else if (placeholder.value === 'variantsCollection') {
          var collection = getCollectionInfo('variantsCollection')
          if (collection) {
            var value = collection[$rootScope.language].name
          }
        } else if (placeholder.value === 'brandCollection1') {
          var collection = getCollectionInfo('brandCollections')

          if (collection) {
            var value = collection[$rootScope.language].name
          }
        } else if (placeholder.value === 'brandCollection2') {
          var collection = getCollectionInfo('brandCollections', 1)

          if (collection) {
            var value = collection[$rootScope.language].name
          }
        } else if (placeholder.value === 'weight') {
          var value = getProductValue(placeholder.value)
          if(value) {
            value += 'g'
          }
        } else if (placeholder.value === 'lastDeliveryDate') {
          var value = getProductValue('lastPurchaseOrderDeliveryDate')
        } else {
          var value = getProductValue(placeholder.value)
        }

        if (!value) {
          return ''
        }
        return value
      } else {
        return ''
      }
    }

    function replaceValuesOnArray (array) {
      var counter = 0
      for (var i = 0; i < array.length; i++) {
        if (!array[i] || array[i].length === 0 || array[i] === '') {
          array[i] = ' '
          counter++
        } else {
          var results = array[i].match(/%%\w*%%/ig)

          if (results && results.length > 0) {
            for (var r = 0; r < results.length; r++) {
              var value = getReplaceValue(results[r])
              if (value !== '') {
                if (results[r] === '%%SELLING_PRICE%%') {
                  var price = parseFloat(value.toString().replace(',', '.'))
                  priceValue = '\u20AC ' + price.toFixed(2).replace('.', ',')
                  var newValue = array[i].replace(new RegExp(results[r], 'g'), priceValue)
                } else {
                  var newValue = array[i].replace(new RegExp(results[r], 'g'), value)
                }
                var collectionValue = results[r] === '%%MY_OWN_COLLECTION%%' || results[r] === '%%VARIANTS_COLLECTION%%' || results[r] === '%%BRAND_COLLECTION1%%' || results[r] === '%%BRAND_COLLECTION2%%'
                if (results[r] !== '%%EAN%%') {
                  newValue = $scope.calculateLabelLength(newValue, collectionValue ? 1800 : results[r] === '%%DESCRIPTION%%' ? 2300 : 1850)
                }
                array[i] = newValue
              } else {
                array[i] = ''
              }
            }
          }
          counter++
        }
        if (counter === array.length) {
          return array
        }
      }
    }

    $scope.changeLabelType = function (label) {
      $scope.label = angular.copy(label)
      $scope.label.quantity = 1
      $scope.label.fields.left = replaceValuesOnArray($scope.label.fields.left)
      $scope.label.fields.right = replaceValuesOnArray($scope.label.fields.right)
      if ($scope.label.dataField) {
        $scope.label.fields.data = getReplaceValue($scope.label.fields.data)
      }
    }

    $scope.print = function () {
      $http({
        method: 'POST',
        url: '/api/download-label',
        data: {
          label: $scope.label
        },
        responseType: 'arraybuffer'
      })
        .then(function (response) {
          var file = new Blob([response.data], { type: 'text/plain' })
          var filename = new Date().getTime()
          FileSaver.saveAs(file, filename + '.pne')
          $scope.close(true)
        }, function (response) {
          $log.error('Error while generating label', response)
        })
    }

    if ($scope.product && $scope.product.stock > 0) {
      $scope.inStock = true
    } else {
      $scope.inStock = false
    }

    $scope.close = function (data) {
      if (data == true) {
        $uibModalInstance.close(true)
      } else {
        $uibModalInstance.close(false)
      }
    }

    $scope.getLabels = function () {
      $shop.getShopLables($rootScope.currentShop._id)
        .then(function (labels) {
          if(labels) {
            $scope.availableLabels = labels.data
            $scope.selectedLabel = $scope.availableLabels[0]
            $scope.changeLabelType($scope.availableLabels[0])
          }
        })
        .catch(function (error) {
          $log.error('Error! While Getting Shop Labels', error)
        })
    }

    $scope.getNumber = function (number) {
      return new Array(number)
    }

    $scope.calculateLabelLength = function (text, points) {
      var capitalPoint = 138.4615
      var smallPoint = 100
      var capitalSpecial = ['@', '#', '$', '^', '€', '&', '%', '<', '>', '?']
      var smallSpecial = ['!', '*', '(', ')', '|', '\\', '/', '{', '}', '-', '+', '_', '.', ',', ':', ';', '[', ']']

      if (!points) {
        points = 1850
      }
      var totalPoints = 0
      var resultString = ''

      for (var i = 0; i < text.length; i++) {
        var letter = text.charAt(i)

        // Test if we still can add some chars
        if ((totalPoints + capitalPoint > points) && (totalPoints + smallPoint > points)) {
          return resultString
        }
        if (capitalSpecial.indexOf(letter) >= 0 && letter !== ' ') {
          // special chars which counts as capital
          totalPoints += capitalPoint
          resultString += letter
        } else if (smallSpecial.indexOf(letter) >= 0 && letter !== ' ') {
          // special chars which counts as small
          totalPoints += smallPoint
          resultString += letter
        } else if (letter === letter.toUpperCase() && letter !== ' ') {
          // Capital letters
          totalPoints += capitalPoint
          resultString += letter
        } else if (letter === letter.toLowerCase() || letter === ' ') {
          // Small letters and spaces
          totalPoints += smallPoint
          resultString += letter
        } else if (!isNaN(letter) && letter !== ' ') {
          // Numbers
          totalPoints += smallPoint
          resultString += letter
        }

        if (i + 1 === text.length) {
          // Text doesn't need to be shorten
          return resultString
        }
      }
    }

    $scope.labelChanged = function (index) {
      if(!$scope.product || Object.keys($scope.product).length < 5) {
        return
      }

      $scope.currentLabel = index
      var count = 0
      $scope.textFields = _.filter(index.elements, function(e) {
        if ($scope.empty) {
          if(e.type !== 'circle' && e.type !== 'rectangle') {
            if(e.type !== 'barcode' && e.type !== 'datamatrix') {
              count++
            }
            var value = e.type === 'barcode' ? 'Barcode' : e.type === 'datamatrix' ? 'Datamatrix' : 'Veld ' + count
            e.placeholder = value

            if(e.pnfield.includes('%%')) {
              e.old_pnfield = $language.translate(e.pnfield.toUpperCase().replace(/[^a-zA-Z_]/g, ''))
            } else {
              e.old_pnfield = e.pnfield
            }
           
            e.pnfield = value
            $scope.product[count] = value

            return e
          }
        }
        return e.type === 'text' || e.type === 'scalabletext' || e.override === true
      })

      $scope.label = null
      $label.makeLabel(index, $label.makeDataObjectForProduct($scope.product))
        .then(function (label) {
          $scope.label = label
          $scope.label.sendToPrinter = index.sendToPrinter
          $scope.previewUrl = label.previewUrl
          $scope.label.quantity = 1
        })
        .catch(function (reason) {
          $log.error('Error while creating label', reason)
        })
    }

    $scope.printLabel = function() {
      $label.printLabel($scope.label.printCommand, $scope.label.quantity, $scope.label.sendToPrinter ? $scope.currentLabel : null)
        .then(function(){
          $log.info('Label sended to printer')
        })
        .catch(function(reason){
          $log.error('Error while printing label', reason)
        })
    }

    $scope.calibrate = function () {
      $scope.calibrateError = null

      $label.printRAW('~jc')
        .then(function(){
          $scope.calibrateError = {
            msg: $language.translate('CALIBRATION_SUCCESSFUL'),
            type: 'success'
          }
        })
        .catch(function(reason){
          $log.error('Error while sending calibrate command to printer', reason)
          $scope.calibrateError = {
            msg: $language.translate('ERROR_WHILE_CALIBRATING') + ': ' + reason,
            type: 'danger'
          }
        })
    }

    $scope.fieldChanged = function (field) {
      $label.makeLabel($scope.currentLabel, $label.makeDataObjectForProduct($scope.product))
        .then(function (label) {
          $scope.label = label
          $scope.label.quantity = 1
        })
        .catch(function (reason) {
          $log.error('Error while creating label', reason)
        })
    }

    $scope.updatePreview = function () {
      $scope.previewUrl = $scope.label.previewUrl
    }

    $scope.collapseFields = function () {
      if($scope.empty) {
        return
      }
      $scope.fieldsCollapse = !$scope.fieldsCollapse
    }

  }])