prismanoteApp.controller('companyAddShopModalController', ['$scope', '$uibModalInstance', '$rootScope', '$state', '$brand',
  function ($scope, $uibModalInstance, $rootScope, $state, $brand) {
    console.log('Hello there: ')
    $scope.searchMode = 'exact'

    $scope.searchShopWithSearchMode = function (searchText, searchMode) {
      console.log('searchShop: ', searchText, searchMode)
      $scope.hits = []
      if (searchText) {
        if (searchText.length < 3) return
        // console.log('Hello findCompanies: ', searchText, searchMode);
        var body = {
          searchText: searchText,
          searchMode: searchMode
        }
        $brand.searchShopWithSearchMode(body, $rootScope.currentCompany._id).then(function (response) {
          console.log('RESPONSE COME: ', response)
          $scope.hits = response.data.data
        }).catch(function (error) {
          console.log('error: ', error)
        })
      }
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    // When so companies found then user can create new company with that
    $scope.addNewShop = function () {
      $scope.cancel()
      $rootScope.shopNameFromAddShopModal = $scope.searchText
      console.log('addnew Company called: ', $rootScope.shopNameFromAddShopModal)
      $scope.searchText = ''
      $state.go('brand.add-shop')
    }

    $scope.addShopToCompany = function (shop) {
      $uibModalInstance.close({ isShop: true, shop: shop })
    }
  }])
