prismanoteApp.controller('adminTransactionController', ['$scope', '$rootScope', '$api', '$stateParams', '$transaction', '$state',
  function ($scope, $rootScope, $api, $stateParams, $transaction, $state) {

    $scope.getTransactions = function () {
      $scope.alert = null
      $scope.loading = true
      $scope.transactions = []

      $api.get('admin/transactions')
        .then(function (result) {
          $scope.transactions = result.data.transactions
          if ($scope.transactions.length === 0) {
            $scope.alert = {
              type: 'success',
              msg: 'No transactions found to be checked'
            }
          }
          $scope.loading = false
        })
        .catch(function (reason){
          $scope.loading = false
          $scope.alert = {
            type: 'danger',
            msg: reason
          }
        })
    }

    $scope.openTransaction = function (id) {
      var url = $state.href('admin.shoppurchase', {transactionId: id})
      window.open(url, '_blank')
    }

    $scope.deleteTransaction = function (id) {
      $transaction.deleteTransaction(id, true, true)
        .then(function (res) {
          $scope.alert = {
            type: 'success',
            msg: 'Transaction deleted'
          }
          $scope.getTransactions()
        })
        .catch(function (reason) {
          console.error(reason)
        })
    }

}])