prismanoteApp.controller('retailerNewsItemController', ['$scope', '$rootScope', '$api', '$state', 'Upload', '$stateParams', '$retailer', 'prompt', '$news', '$q', '$language',
  function ($scope, $rootScope, $api, $state, Upload, $stateParams, $retailer, prompt, $news, $q, $language) {
    $scope.edit = false
    $scope.alert = null
    $scope.newsItem = {
      isPublished: true
    }
    $scope.titleChanged = false
    var date = new Date()
    date.setDate(date.getDate() + 1)
    $scope.dateOptions = {
      minDate: date,
      initDate: date
    }
    $scope.noItem = false

    $scope.getShop = function () {
      return $q(function (resolve, reject) {
        if ($rootScope.currentShop && $rootScope.currentShop._id) {
          return resolve($rootScope.currentShop)
        } else {
          $retailer.getShop()
            .then(function (shop) {
              $rootScope.currentShop = shop
              return resolve(shop)
            })
        }
      })
    }

    $scope.loadItem = function () {
      $scope.alert = null
      $scope.noItem = false
      if ($stateParams.id && $stateParams.id != '') {
        $scope.edit = true
        $scope.loading = true
        $scope.getShop()
          .then(function (shop) {
            $api.get('shop/' + shop._id + '/news/' + $stateParams.id, null, null, 2)
              .then(function (response) {
                console.log('RESPONSE', response)
                if (response.status != 200) {
                  $scope.alert = {
                    type: 'danger',
                    msg: $language.translate('NO_ITEMS_FOUND')
                  }
                  $scope.loading = false
                  $scope.noItem = true
                  return
                }
                $scope.newsItem = response.data.news
                $scope.newsItem.changeSlug = false
                $scope.originalTitle = angular.copy($scope.newsItem.name)

                if ($scope.newsItem.publicationDate) {
                  $scope.newsItem.publicationDate = new Date($scope.newsItem.publicationDate)
                }

                $scope.loading = false
              })
              .catch(function (reason) {
                console.error('Error while fetching newsitem', reason)
                $scope.alert = {
                  type: 'danger',
                  msg: $language.translate(response.data.message)
                }
                $scope.noItem = true
                $scope.loading = false
              })
          })
      }
    }

    $scope.loadItem()

    $scope.checkTitle = function () {
      $scope.titleChanged = $scope.edit && $scope.newsItem.name != $scope.originalTitle
    }

    $scope.setDate = function () {
      if ($scope.edit && $scope.newsItem.isPublished === false) {
        $scope.newsItem.publicationDate = null
      }
    }

    $scope.clearDate = function () {
      $scope.newsItem.publicationDate = null
    }

    $scope.getBrands = function () {
      $api.get('brands?getAll=true')
        .then(function (response) {
          $scope.brands = response.data.brands
          if ($scope.newsItem.brand && $scope.newsItem.brand.name) {
            for (var i = 0; i < $scope.brands.length; i++) {
              if ($scope.brands[i] && $scope.brands[i].en && $scope.brands[i].en.name == $scope.newsItem.brand.name ||
							$scope.brands && $scope.brands[i] && $scope.brands[i].name == $scope.newsItem.brand.name ||
							$scope.brands[i]._id && ($scope.brands[i]._id).toString() == ($scope.newsItem.brand._id || '').toString()) {
                $scope.currentBrand = $scope.brands[i][$rootScope.language].name || $scope.newsItem.brand.name
              }
            }
          }
        })
        .catch(function (reason) {
          console.error('Error while fetching brands', reason)
          $scope.alert = {
            type: 'warning',
            msg: $language.translate('ERROR_FETCHING_BRANDS_BUT_YOU_CAN_CONTINUE_EDITING') + ': ' + reason
          }
        })
    }

    var searchItems = function (str, type) {
      var matches = []
      $scope[type].forEach(function (item) {
        if (type === 'brands') {
          if ((item[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
						(item[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)
          ) {
            matches.push(item)
          }
        } else {
          if ((item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
						(item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)
          ) {
            matches.push(item)
          }
        }
      })
      return matches
    }

    $scope.searchBrands = function (str) {
      return searchItems(str, 'brands')
    }

    $scope.brandSelected = function (selected) {
      $scope.newsItem.brand = {
        _id: selected.originalObject._id,
        name: selected.title
      }
      $scope.currentBrand = selected.originalObject[$rootScope.language].name
    }

    $scope.updateNewsItem = function (skipImage) {
      $scope.alert = null
      $scope.uploadAlert = null

      if (!$scope.newsItem.name || !$scope.newsItem.content || $scope.newsItem.content.length < 10) {
        $scope.alert = {
          type: 'danger',
          msg: $language.translate('ITEM_DOES_NOT_HAVE_AN_TITLE_CONTENT_OR_CONTENT_IS_TOO_SHORT')
        }
        return
      }

      if ($scope.newsItem.photo && skipImage === false) {
        Upload.upload({
          url: '/api/v2/shop/' + $rootScope.currentShop._id + '/news/image',
          data: {
            file: $scope.newsItem.photo
          }
        })
          .then(function (result) {
            if (!$scope.newsItem.images) {
              $scope.newsItem.images = []
            }
            $scope.newsItem.images[0] = {
              src: result.data.src,
              alt: $scope.newsItem.imageAlt ? $scope.newsItem.imageAlt : null
            }

            saveNewsItem()
          }, function (result) {
            console.error('Upload error', result)
            $scope.uploadAlert = {
              type: 'danger',
              msg: result.data.message
            }
          }, function (evt) {
            // progress
          })
          .catch(function (reason) {
            console.log('Error in upload request', reason)
          })
      } else {
        saveNewsItem()
      }

      function saveNewsItem () {
        if ($scope.edit) {
          $api.put('shop/' + $rootScope.currentShop._id + '/news/' + $scope.newsItem._id, {
            newsItem: $scope.newsItem
          }, null, 2)
            .then(function (response) {
              $state.go('retailer.news')
            })
            .catch(function (reason) {
              console.error('Error while saving newsItem', reason)
              $scope.alert = {
                type: 'danger',
                msg: reason
              }
            })
        } else {
          $api.post('shop/' + $rootScope.currentShop._id + '/news', {
            newsItem: $scope.newsItem
          }, null, 2)
            .then(function (response) {
              $state.go('retailer.news')
            })
            .catch(function (reason) {
              console.error('Error while saving newsItem', reason)
              $scope.alert = {
                type: 'danger',
                msg: reason.message
              }
            })
        }
      }
    }

    $scope.$on('changeSelectedShop', function () {
      $scope.loadItem()
    })

    $scope.removeNewsItem = function () {
      prompt({
        title: 'Bericht verwijderen',
        message: 'Weet u zeker dat u dit bericht wilt verwijderen?'
      }).then(function () {
        $api.remove('shop/' + $rootScope.currentShop._id + '/news/' + $scope.newsItem._id, null, null, 2)
          .then(function (response) {
            $state.go('retailer.news')
          }).catch(function (reason) {
            console.error('Error while deleting newsitem', reason)
            $scope.alert = {
              type: 'danger',
              msg: reason.message
            }
          })
      })
    }
  }])
