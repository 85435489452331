prismanoteApp.controller('brandServicesController', ['$scope', '$api', '$company', '$state', '$q', '$language', '$rootScope',
  function ($scope, $api, $company, $state, $q, $language, $rootScope) {
    $scope.loading = false
    $scope.repairs = []
    $scope.currentPage = 1
    $scope.pageSize = '10'
    $scope.statusFilter = 'ALL_ITEMS' // Default show all transactions

    // $scope.getCompany()

    function getCompany () {
      return $q(function (resolve, reject) {
        $company.getCompany()
          .then(function (company) {
            return resolve(company)
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    $scope.$on('changeSelectedCompany', function () {
      $scope.getRepairs()
    })

    $scope.getRepairs = function () {
      getCompany()
        .then(function (company) {
          $scope.loading = true
          $api.get('repairs-for-wholesaler-with-shopname', {
            wholesaler: company._id
          })
            .then(function (response) {
              $scope.loading = false
              $scope.repairs = response.data.repairs
            })
            .catch(function (reason) {
              $scope.loading = false
              console.error(reason)
            })
        })
        .catch(function (reason) {
          console.error(reason)
          $scope.loading = false
        })
    }

    $scope.openRepair = function (id) {
      $state.go('brand.service-item', { id: id })
    }

    $scope.setStatusFilter = function (filter) {
      $scope.statusFilter = filter
    }

    $scope.filterItemStatus = function (item) {
      var filter = $scope.statusFilter.toLowerCase()

      if (filter == 'all_items') {
        return true
      }
      return item.status == filter
    }
  }])
