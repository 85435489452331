prismanoteApp.controller('viewTaskModalController', ['$scope', '$uibModalInstance', 'task', '$api', '$window', '$http', 'FileSaver', 'prompt',
  function ($scope, $uibModalInstance, task, $api, $window, $http, FileSaver, prompt) {
    $scope.todoTask = task

    $scope.getTaskDetails = function () {
      $api.get('task-details', { campaignId: task.campaignId, taskId: task.taskId })
        	.then(function (response) {
          $scope.task = response.data.task
          $scope.campaign = response.data.campaign
        })
        .catch(function (reason) {
          console.error(reason)
        })
    }

    $scope.addLogItem = function (item, save) {
      var logItem = {
        title: item.title,
        date: new Date(),
        type: item.type,
        taskId: item.taskId,
        user: item.user
      }
      $scope.user.socialPortal.log.push(logItem)
    }

    $scope.saveUser = function () {
      $api.put('user/' + $scope.user._id, { user: $scope.user })
        .then(function () {

        })

        .catch(function (reason) {
          console.error(reason)
        })
    }

    $scope.orderTask = function () {
      $window.open($scope.task.orderLink)

      prompt({
        title: 'Is het bestellen gelukt?',
        message: 'Is het bestellen van ' + $scope.task.nl.name + ' gelukt ?'
      }).then(function () {
        $scope.completeTask()
        $scope.addLogItem({
          title: 'Bestelling succesvol geplaatst: ' + $scope.task.nl.name,
          type: 'task',
          taskId: task._id,
          user: $scope.user.firstName + ' ' + $scope.user.lastNamePrefix + ' ' + $scope.user.lastName
        }, true)
      })
    }

    $scope.completeTask = function () {
      $scope.todoTask.completed = true
      $scope.closeModal()
    }

    $scope.executeTaskFacebook = function () {
      $scope.completeTask()
      $scope.addLogItem({
        title: 'Taak uitgevoerd: ' + $scope.task.nl.name,
        type: 'facebook',
        taskId: task._id,
        user: $scope.user.firstName + ' ' + $scope.user.lastNamePrefix + ' ' + $scope.user.lastName
      }, true)
    }

    $scope.executeTaskInstagram = function () {
      $scope.completeTask()
      $scope.addLogItem({
        title: 'Taak uitgevoerd: ' + $scope.task.nl.name,
        type: 'instagram',
        taskId: task._id,
        user: $scope.user.firstName + ' ' + $scope.user.lastNamePrefix + ' ' + $scope.user.lastName
      }, true)
    }

    $scope.executeTaskPinterest = function () {
      $scope.completeTask()
      $scope.addLogItem({
        title: 'Taak uitgevoerd: ' + $scope.task.nl.name,
        type: 'pinterest',
        taskId: task._id,
        user: $scope.user.firstName + ' ' + $scope.user.lastNamePrefix + ' ' + $scope.user.lastName
      }, true)
    }

    $scope.downloadFiles = function () {
      $http({
        method: 'GET',
        url: '/api/download-task-files',
        params: {
          campaignId: $scope.campaign._id,
          taskId: $scope.task._id,
          portalId: $scope.user.socialPortal._id
        },
        responseType: 'arraybuffer'
      })
        .then(function (response) {
          $scope.loading = false
          var file = new Blob([response.data], { type: 'application/zip' })

          FileSaver.saveAs(file, $scope.slugify($scope.task.nl.name) + '.zip')
          $scope.addLogItem({
            title: 'Bestanden gedownload voor ' + $scope.task.nl.name,
            type: 'task',
            taskId: task._id,
            user: $scope.user.firstName + ' ' + $scope.user.lastNamePrefix + ' ' + $scope.user.lastName
          }, true)
        }, function (response) {
          console.error(response)
        })
    }

    $scope.closeModal = function () {
      $uibModalInstance.close()
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    $scope.slugify = function (text) {
      return text.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w\-]+/g, '') // Remove all non-word chars
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
    }
  }])
