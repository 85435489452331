prismanoteApp.controller('retailerGiftcardController', ['$scope', '$q', '$rootScope', '$api', '$stateParams', '$uibModal', '$state', '$printer', '$pdf', '$language', '$retailer', 'prompt', 'Lightbox', '$transaction', 'dialogs', '$filter', '$customer', '$employee', '$shop',
  function ($scope, $q, $rootScope, $api, $stateParams, $uibModal, $state, $printer, $pdf, $language, $retailer, prompt, Lightbox, $transaction, dialogs, $filter, $customer, $employee, $shop) {
    $scope.currentPage = 1
    $scope.intakeDateChanged = false
    $scope.repairDateChanged = false
    $scope.printerEnabled = false
    $scope.pageSize = 5
    $scope.maxSize = 10
    if ($rootScope.tablet || $rootScope.phone) {
      $scope.pageSize = 3
      $scope.maxSize = 9
    }

    $scope.loading = true

    $scope.brands = []
    $scope.openImage = false
    $scope.searchMode = 'exact'
    $scope.offerItem = {}
    $scope.editItem = false
    $scope.offerIndex = null
    $scope.outstanding = 0
    $scope.disableRefund = false

    $scope.intakeDateOptions = {
      maxDate: new Date()
    }
    $scope.endDateOptions = {
      minDate: new Date()
    }

    // try {
    //   // console.log('shop:' +$stateParams.transactionId + ":company:added:comment:serviceitem");
    //   SocketService.on('shop:' + $stateParams.transactionId + ':company:added:comment:serviceitem', function (data) {
    //     if ($scope.transaction && $scope.transaction.remarks && $scope.transaction.remarks.remarks && $scope.transaction.remarks.remarks.length && $scope.transaction.remarks.remarks[0]) {
    //       $scope.transaction.remarks.remarks.push(data)
    //     } else {
    //       $scope.transaction = {
    //         remarks: {
    //           remarks: [data]
    //         }
    //       }
    //     }
    //     $scope.autoScrollcomment()
    //   })
    // } catch (catcherr) {
    //   console.log('catcherr: ', catcherr)
    // }

    $scope.getServiceStatus = function (status) {
      if (status == 'new' || status == 'offer' || status == 'to-repair') {
        $scope.serviceStatus = 0
      }
      else if (status == 'processing') {
        $scope.serviceStatus = 1
      }
      else if (status == 'inspection') $scope.serviceStatus = 2
      else if (status == 'completed') $scope.serviceStatus = 3
      else if (status == 'delivered') $scope.serviceStatus = 4
      else if (status == 'cancelled') $scope.serviceStatus = 5
    }

    $scope.getTransaction = function () {
      console.group('Get Transaction')
      $transaction.getTransaction($stateParams.transactionId, true, false, $rootScope.currentShop._id)
        .then(function (transaction) {
          console.log('Transaction is loaded', transaction)
          if (transaction.length && transaction.length > 0) {
            $scope.transaction = transaction[0]
          } else {
            $scope.transaction = transaction
          }
          if ($scope.transaction) {
            $scope.originalTransaction = JSON.parse(JSON.stringify($scope.transaction))
          }

          $scope.intakeDateChanged = false
          $scope.repairDateChanged = false

          $rootScope.transactionType = $scope.transaction.type
          // Check the ID of this transaction with the stateParams,
          // if they are not the same, look into the transaction to get the right transaction and modify the object
          if ($scope.transaction._id == $stateParams.transactionId) {
            console.log('$scope.transaction._id && $stateParams.transactionId are same')
            loadTransaction()
          } else {
            console.log('$scope.transaction._id && $stateParams.transactionId are not same')
            // Loop through the transaction.transactions to get the right transaction and to rebuild the object
            var originalTransaction = angular.copy($scope.transaction)
            for (var i = 0; i < originalTransaction.transactions.length; i++) {
              if (originalTransaction.transactions[i]._id == $stateParams.transactionId) {
                // Found the right transaction
                var newTransaction = angular.copy(originalTransaction.transactions[i])
                delete originalTransaction.transactions[i]
                newTransaction.transactions = new Array(originalTransaction)
                $scope.transaction = newTransaction
                return loadTransaction()
              }
            }
          }

          if ($scope.transaction) {
            $scope.originalTransaction = JSON.parse(JSON.stringify($scope.transaction))
          }
          console.groupEnd()

          function loadTransaction () {
            $scope.receiptNumbers = []
            // Parse the date again as date to correctly prefil the datepickers
            $scope.transaction.dateCreated = new Date($scope.transaction.dateCreated)
            if ($scope.transaction[$scope.transaction.type] && $scope.transaction[$scope.transaction.type].estimatedDate) {
              $scope.transaction[$scope.transaction.type].estimatedDate = new Date($scope.transaction[$scope.transaction.type].estimatedDate)
            }
            if ($scope.transaction.type == 'offer') {
              $scope.transaction.offer.expireDate = new Date($scope.transaction.offer.expireDate)
            }

            if ($scope.transaction.status == 'processing') {
              console.log('When Status is :', $scope.transaction.status)
              if ($scope.transaction.repair.repairerType == 'in-house') {
                console.log('Repair Type: If ', $scope.transaction.repair)
                if (!$scope.transaction.repair.repairer) {
                  console.log('Repair is in transaction')
                  $scope.isRepairMySelf = true
                } else {
                  console.log('Repair is not in transaction')
                }
              } else {
                console.log('Repair Type: ELSE ', $scope.transaction.repair.repairerType)
              }
            }

            if ($scope.transaction.type == 'refund') {
              $scope.totalChange = 0
              var totalPaidByPaymentMethods = 0
              $scope.payMethods = []
              for (var i = 0; i < $scope.transaction.receipt.payments.length; i++) {
                $scope.payMethods.push({
                  method: $language.translate($scope.transaction.receipt.payments[i].method.toUpperCase()),
                  originalMethod: $scope.transaction.receipt.payments[i].method.toUpperCase(),
                  amount: $scope.transaction.receipt.payments[i].amount,
                  change: $scope.transaction.receipt.payments[i].change,
                  giftcardNumber: $scope.transaction.receipt.payments[i].giftcardNumber,
                  card: $scope.transaction.receipt.payments[i].method.toUpperCase() == 'CARD',
                  cardName: $scope.transaction.receipt.payments[i].cardName ? $scope.transaction.receipt.payments[i].cardName : ''
                })
                totalPaidByPaymentMethods += $scope.transaction.receipt.payments[i].amount
              }

              $scope.totalChange = totalPaidByPaymentMethods - $scope.transaction.transactionTotal
            }

            if ($scope.transaction.type == 'shop-purchase') {
              if ($scope.transaction.details && $scope.transaction.details.length > 0) {
                $scope.transaction.totalAmount = 0
                for (var i = 0; i < $scope.transaction.details.length; i++) {
                  if ($scope.transaction.details[i].total) {
                    $scope.transaction.totalAmount += $scope.transaction.details[i].total
                  } else {
                    $scope.transaction.totalAmount += $scope.transaction.details[i].quantity * $scope.transaction.details[i].price
                  }
                }
              }

              $scope.payMethods = []
              $scope.totalChange = 0
              if (!$scope.transaction.receipt || !$scope.transaction.receipt.payments) {
                return
              }

              var totalPaidByPaymentMethods = 0

              for (var i = 0; i < $scope.transaction.receipt.payments.length; i++) {
                $scope.payMethods.push({
                  method: $language.translate($scope.transaction.receipt.payments[i].method.toUpperCase()),
                  originalMethod: $scope.transaction.receipt.payments[i].method.toUpperCase(),
                  amount: $scope.transaction.receipt.payments[i].amount,
                  change: $scope.transaction.receipt.payments[i].change,
                  giftcardNumber: $scope.transaction.receipt.payments[i].giftcardNumber,
                  card: $scope.transaction.receipt.payments[i].method.toUpperCase() == 'CARD',
                  cardName: $scope.transaction.receipt.payments[i].cardName ? $scope.transaction.receipt.payments[i].cardName : ''
                })
                totalPaidByPaymentMethods += $scope.transaction.receipt.payments[i].amount
              }
              var totalAmount = $scope.transaction.transactionTotal < 0 ? $scope.transaction.transactionTotal * -1 : $scope.transaction.transactionTotal
              $scope.totalChange = totalPaidByPaymentMethods - totalAmount

              if ($scope.transaction.transactions && $scope.transaction.transactions.length > 0) {
                for (var i = 0; i < $scope.transaction.transactions.length; i++) {
                  if ($scope.transaction.transactions[i].type == 'refund') {
                    $scope.refund = $scope.transaction.transactions[i]
                    $scope.disableRefund = true
                  }
                }
              }
            }

            if ($scope.transaction.type == 'shop-purchase' || $scope.transaction.type == 'offer' || $scope.transaction.type == 'refund') {
              $scope.totalTax = 0
              $scope.taxes = {}
              var detailsLength = $scope.transaction.details.length
              for (var i = 0; i < detailsLength; i++) {
                var detail = $scope.transaction.details[i]
                console.log('detail ---------->', detail)

                $scope.totalTax += $scope.taxPrice(detail)
                if (!$scope.taxes[detail.priceVat]) {
                  $scope.taxes[detail.priceVat] = detail.total
                } else {
                  $scope.taxes[detail.priceVat] += detail.total
                }

                // Now lower those values with the total discount on the offer (if applied)
                if ((i + 1) == detailsLength && $scope.transaction.discount) {
                  var totalDiscount = $scope.getDiscount()
                  var totalPrice = $scope.getTotalPrice(false)

                  // Now loop through the taxes table and edit the values based on the value, sow more discount will be applied to a rule with an higher amount
                  for (var t in $scope.taxes) {
                    var originalRate = $scope.taxes[t]
                    var ratePriceWithDiscount = $scope.taxes[t] * (totalDiscount / totalPrice)
                    $scope.taxes[t] = originalRate - ratePriceWithDiscount
                  }
                }
              }
            }

            if ($scope.transaction.type != 'shop-purchase' || $scope.transaction.type != 'warranty' || $scope.transaction.type == 'webshop') {
              if ($scope.transaction.transactions) {
                $scope.payments = []
                $scope.prepaid = 0
                for (var a = 0; a < $scope.transaction.transactions.length; a++) {
                  $scope.receiptNumbers.push({
                    number: $scope.transaction.transactions[a].number,
                    _id: $scope.transaction.transactions[a]._id,
                    date: $scope.transaction.transactions[a].dateCreated
                  })

                  for (b = 0; b < $scope.transaction.transactions[a].receipt.payments.length; b++) {
                    var payment = $scope.transaction.transactions[a].receipt.payments[b]
                    $scope.payments.push({
                      amount: payment.amount,
                      method: payment.method,
                      date: payment.date,
                      deposit: payment.deposit,
                      change: payment.change
                    })
                    if (payment.deposit) {
                      if (!payment.change) {
                        payment.change = 0
                      }
                      $scope.prepaid += (payment.amount - payment.change)
                    }
                  }
                }
              }
            }

            if ($scope.transaction.type == 'refund') {
              if ($scope.transaction.details && $scope.transaction.details.length > 0) { $scope.refund = $scope.transaction.details[0].transaction }
              $scope.disableRefund = true
            }

            $scope.statusBackup = angular.copy($scope.transaction.status)
            $scope.getServiceStatus($scope.transaction.status)
            $scope.getOutstandingAmount()
          }

          // if ($scope.transaction.totalRefundAmount === $scope.transaction.transactionTotal && ($scope.transaction.status !== "deliverd" && $scope.transaction.status !== "cancelled")) {
          //     prompt({
          //         title: $language.translate('ALERT'),
          //         message: $language.translate('THIS_TRANSACTION_IS_FULLY_REFUNDED_DO_YOU_WANT_OT_MARK_AS_CANCELLED_TO_THIS_TRANSACTION')
          //     })
          //         .then(function (data) {
          //             if (data && data.cancel) {
          //             } else {
          //                 var status = "cancelled";
          //                 $scope.transaction.status = status;

          //                 $scope.transaction.log.push({
          //                     date: new Date(),
          //                     user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
          //                     text: $language.translate("CHANGED_STATUS_FROM_TO", { oldStatus: $language.translate($scope.statusBackup.toUpperCase()), newStatus: $language.translate(status.toUpperCase()) }),
          //                     isFor: 'retailerPortal',
          //                     eType: 'retailer'
          //                 })

          //                 $scope.statusBackup = angular.copy(status);

          //                 $scope.updateTransactionStatus();
          //             }
          //         })
          // }

          $scope.loading = false
        })
        .catch(function (reason) {
          console.error('reason', reason)
          if (typeof reason === 'object') {
            $scope.alert = {
              type: 'danger',
              msg: $language.translate('UNKOWN_ERROR_OCCURED')
            }
            return
          }
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(reason)
          }

          $scope.loading = false
        })
    }

    $scope.prefillActualCosts = function () {
      if (!$scope.transaction[$scope.transaction.type].actualCosts) {
        $scope.transaction[$scope.transaction.type].actualCosts = $scope.transaction[$scope.transaction.type].estimatedPrice
      }
    }

    $scope.removeBrand = function () {
      $scope.transaction.brand = null
    }

    $scope.removeSupplier = function () {
      $scope.transaction.supplier = null
    }

    $scope.removeRepairer = function () {
      $scope.transaction.repair.repairer = null
      $scope.transaction.isRepairerChanged = false
    }

    $scope.removeAssitedBy = function () {
      $scope.transaction.createdBy.name = null
      $scope.isRemovedAssitedBy = true
    }

    $scope.changeStatus = function (status) {
      console.group(' ********* Change Status')
      console.log('New Status', status)

      $scope.getServiceStatus(status)
      function updateStatus () {
        if (status == 'completed') {
          if (!$scope.transaction[$scope.transaction.type].actualFinishDate) {
            $scope.transaction[$scope.transaction.type].actualFinishDate = new Date()
          }
          if (!$scope.transaction[$scope.transaction.type].actualCosts) {
            $scope.transaction[$scope.transaction.type].actualCosts = $scope.transaction[$scope.transaction.type].estimatedPrice
          }
        }

        if (status == 'processing') {
          console.log('When New Status is : ', status)
          $scope.transaction.isCompanyRepliedAfterProcessingMode = false
        }

        $scope.transaction.status = status

        $scope.transaction.log.push({
          date: new Date(),
          user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
          text: $language.translate('CHANGED_STATUS_FROM_TO', { oldStatus: $language.translate($scope.statusBackup.toUpperCase()), newStatus: $language.translate(status.toUpperCase()) }),
          isFor: 'retailerPortal',
          eType: 'retailer'
        })

        if (status == 'inspection' || status == 'inspection') {
          $scope.transaction.log.push({
            date: new Date(),
            user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
            text: 'Notification that order is arrived',
            isFor: 'supplierPortal',
            eType: 'retailer'
          })
        }
        $scope.statusBackup = angular.copy(status)

        $scope.updateTransactionStatus()
      }

      if (status === 'delivered' && ($scope.transaction.transactionTotal && $scope.transaction.transactionTotal.toFixed(2) !== $scope.transaction.totalEarning.toFixed(2))) {
        prompt({
          title: $language.translate('ALERT'),
          message: $language.translate('YOU_CAN_NOT_MARK_AS_COMPLETE_BECAUSE_NOT_PAID_FULL_AMOUNT_BY_CUSTOMER'),
          buttons: [
            {
              label: 'Ok',
              primary: true
            }
          ]
        })

        $scope.transaction.status = $scope.statusBackup
        $scope.getServiceStatus($scope.transaction.status)
      } else if (status === 'cancelled') {
        prompt({
          title: $language.translate('ALERT'),
          message: $language.translate('YOU_CAN_NOT_CHANGE_ANYTHING_IN_THIS_TRANSACTION_ONCE_MARK_AS_CANCELLED_OR_DELIVERED')
        })
          .then(function (data) {
            if (data && data.cancel) {
              $scope.transaction.status = $scope.statusBackup
              $scope.getServiceStatus($scope.transaction.status)
            } else {
              if ($scope.transaction.totalEarning > 0) {
                $scope.checkout(true)
              } else {
                $scope.transaction[$scope.transaction.type].actualCosts = 0
                updateStatus()
              }
            }
          })
      } else if (status === 'delivered') {
        prompt({
          title: $language.translate('ALERT'),
          message: $language.translate('YOU_CAN_NOT_CHANGE_ANYTHING_IN_THIS_TRANSACTION_ONCE_MARK_AS_CANCELLED_OR_DELIVERED')
        })
          .then(function (data) {
            if (data && data.cancel) {
              $scope.transaction.status = $scope.statusBackup
              $scope.getServiceStatus($scope.transaction.status)
            } else {
              updateStatus()
            }
          })
      } else {
        updateStatus()
      }

      console.groupEnd()
    }

    $scope.updateTransactionStatus = function () {
      var data = {
        shopId: $rootScope.currentShop._id,
        user: ($rootScope.currentEmployee ? $rootScope.currentEmployee : null)
      }

      $transaction.updateTransactionStatus($scope.transaction._id, $scope.transaction.status, data)
        .then(function () {
          $scope.loading = false
          $scope.getTransaction()
        })
        .catch(function (reason) {
          $scope.loading = false
          dialogs.error($language.translate('ERROR'), $language.translate('AN_ERROR_OCCURED') + ': ' + reason, { size: 'sm' })
        })
    }

    $scope.changedDate = function (type) {
      if (type == 'intakeDate') {
        $scope.intakeDate = !$scope.intakeDate
        $scope.intakeDateChanged = true
      }

      if (type == 'repairDate') {
        $scope.repairDate = !$scope.repairDate
        $scope.intakeDateChanged = true
      }
    }

    $scope.openCustomer = function () {
      $state.go('retailer.customer', {
        id: $scope.transaction.customer._id
      })
    }

    $scope.printRepairDocument = function (mode) {
      $api.get('shops/' + $rootScope.user.shops['0'].nameSlug)
        .then(function (response) {
          $scope.shopAdd = response.data.shop

          $pdf.downloadPdf('generate-repair-document', {
            transaction: $scope.transaction,
            mode: mode,
            language: $rootScope.language,
            showComment: true,
            documentName: 'REPAIR/SPECIAL',
            deviceId: $rootScope.currentDevice._id,
            shopId: $rootScope.currentShop._id
          }, ($scope.transaction.number ? $scope.transaction.number + '_' : '') + mode + '_' + 'receipt.pdf', null)
            .then(function (data) {
              if (type != 'document') {
                $scope.mailAlert = {
                  type: 'success',
                  msg: $language.translate('MAIL_SEND')
                }
              }
            })
            .catch(function (reason) {
              console.error(reason)
              if (type != 'document') {
                $scope.alert = {
                  type: 'danger',
                  msg: reason
                }
              }
            })
        }).catch(function (reason) {
          console.error('Error while getting shop', reason)
        })
    }

    $scope.openEmailCompanyEmail = function () {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/email-company-modal.html',
        controller: 'emailCompanyController',
        keyboard: false,
        backdrop: 'static',
        size: 'lg',
        resolve: {
          transaction: function () {
            return $scope.transaction
          }
        }
      })
      modalInstance.result.then(function (result) {
        $scope.transaction = result

        $scope.updateTransaction()
      }, function () {

      })
    }

    $scope.updateTransaction = function () {
      if ((!$scope.transaction.createdBy || !$scope.transaction.createdBy.name) && $scope.isRemovedAssitedBy) return
      $scope.loading = true
      if (!$scope.transaction.log) $scope.transaction.log = []
      if ($scope.intakeDateChanged) {
        var log = {
          date: new Date(),
          user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
          text: 'Intake date changed',
          isFor: 'retailerPortal',
          eType: 'retailer'
        }
        $scope.transaction.log.push(log)
      }

      if ($scope.repairDateChanged) {
        var log = {
          date: new Date(),
          user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
          text: 'Repair date changed',
          isFor: 'retailerPortal',
          eType: 'retailer'
        }
        $scope.transaction.log.push(log)
      }

      $scope.transaction.shopId = { _id: $scope.transaction.shopId._id }

      $transaction.updateTransaction($scope.transaction, null, true)
        .then(function (result) {
          $scope.loading = false
          $scope.getTransaction()
        })
        .catch(function (reason) {
          $scope.loading = false
          dialogs.error($language.translate('ERROR'), $language.translate('AN_ERROR_OCCURED') + ': ' + reason, { size: 'sm' })
        })
    }

    $scope.sendRepairCompletedMail = function () {
      $scope.statusAlert = null
      $api.post('transactions/send-completed-email', {
        transaction: $scope.transaction,
        shop: { _id: $rootScope.currentShop._id, name: $rootScope.currentShop.name, nameSlug: $rootScope.currentShop.nameSlug, identity: $rootScope.currentShop.identity },
      }).then(function (res) {
        $scope.statusAlert = {
          type: 'success',
          msg: $language.translate('MAIL_SEND')
        }
      }).catch(function (reason) {
        dialogs.error($language.translate('ERROR'), reason, { size: 'sm' })
        console.error(reason)
      })
    }

    $scope.priceChanged = function () {
      $scope.transaction.transactionTotal = $scope.transaction[$scope.transaction.type].actualCosts
      $scope.transaction.details[0].total = $scope.transaction.transactionTotal
      var singleProductPriceWithVat = $scope.transaction.details[0].total / $scope.transaction.details[0].quantity
      $scope.transaction.details[0].price = singleProductPriceWithVat / (1 + ($scope.transaction.details[0].priceVat / 100))

      $scope.transaction.details[0].purchasePrice = $scope.transaction[$scope.transaction.type].externalPrice

      $scope.updateTransaction()
    }

    $scope.hideLightBox = function () {
      $scope.openImage = false
    }

    $scope.back = function () {
      window.history.back()
    }

    $scope.getBrands = function () {
      $api.get('brands')
        .then(function (response) {
          $scope.brands = response.data.brands
          $scope.brands.forEach(function (brand) {
            brand.name = brand[$rootScope.language].name
          })
        })
        .catch(function (reason) {
          console.error('Error while getting brands', reason)
        })
    }

    $scope.closeAlert = function (alert) {
      $scope[alert] = null
    }

    $scope.getCompanies = function () {
      $scope.companies = []
      $shop.getOwnShopCompanies($rootScope.currentShop._id)
        .then(function (companies) {
          $scope.companies = companies
          console.log('$scope.companies', $scope.companies)
        })
        .catch(function (error) {
          console.error('Error! While getting Shop Companies')
          console.error(error)
        })
    }

    $scope.getShopBrands = function () {
      $scope.shopBrands = []
      $shop.getBrandsByShop($rootScope.currentShop.nameSlug)
        .then(function (brands) {
          $scope.shopBrands = brands.brands
          console.log('Current Shop Brands', $scope.shopBrands)
        })
        .catch(function (error) {
          console.error('Error! While getting Shop Brands')
          console.error(error)
        })
    }

    $scope.selectedCompany = function (selected) {
      if (selected) {
        if (selected.originalObject) {
          selected = selected.originalObject
        }

        $scope.transaction.supplier = {
          name: selected.name,
          _id: selected._id
        }
      }
      if ($scope.transaction.type == 'repair') $scope.updateTransaction()
    }

    $scope.selectedBrand = function (selected) {
      if (selected) {
        if (selected.originalObject) {
          selected = selected.originalObject
        }

        $scope.transaction.brand = {
          name: selected[$rootScope.language].name,
          _id: selected._id
        }
      }
      if (!$scope.shopBrands || $scope.shopBrands.length == 0) {
        return
      }

      var shopBrandsLength = $scope.shopBrands.length
      for (var i = 0; i < shopBrandsLength; i++) {
        var brand = $scope.shopBrands[i]

        if (brand._id == $scope.transaction.brand._id) {
          $scope.selectedRepairer(brand.favoriteRepairer._id)
          $scope.selectedCompany(brand.favoriteSupplier._id)
        }
      }
      if ($scope.transaction.type == 'repair') $scope.updateTransaction()
    }

    $scope.selectedRepairer = function (selected) {
      if ($scope.transaction.status == 'new') {
        $scope.transaction.status = 'processing'
        $scope.transaction.repair.repairerType = 'external'
      }

      if (selected) {
        if (selected.originalObject) {
          selected = selected.originalObject
        }

        $scope.transaction.repair.repairer = {
          name: selected.name,
          _id: selected._id
        }
      }

      // For sending the email notification to the company
      if ($scope.transaction.type && $scope.transaction.type === 'repair') {
        if ($scope.originalTransaction && $scope.originalTransaction.repair && $scope.originalTransaction.repair.repairer && $scope.originalTransaction.repair.repairer._id) {
          if ($scope.transaction.repair && $scope.transaction.repair.repairer && $scope.transaction.repair.repairer._id) {
            if (($scope.transaction.repair.repairer._id).toString() !== ($scope.originalTransaction.repair.repairer._id).toString()) {
              $scope.transaction.isRepairerChanged = true
            } else {
              $scope.transaction.isRepairerChanged = false
            }
          }
        } else {
          $scope.transaction.isRepairerChanged = true
        }
      }
      if ($scope.transaction.type == 'repair') $scope.updateTransaction()
    }

    $scope.getTotalQuantity = function () {
      var total = 0; var counter = 0
      for (var i = 0; i < $scope.transaction.details.length; i++) {
        total = total + $scope.transaction.details[i].quantity
        counter++
        if (counter == $scope.transaction.details.length) {
          return total
        }
      }
    }

    $scope.getItemDiscount = function (item) {
      if (item.discount) {
        if (item.discountPercent) {
          return item.discountValue + '%'
        } else {
          return $filter('currency')(item.discountValue, '\u20AC')
        }
      } else {

      }
    }

    $scope.getTotalDiscount = function () {
      var total = 0; var counter = 0
      for (var i = 0; i < $scope.offerItems.length; i++) {
        var item = $scope.offerItems[i]
        var price = 0
        if (item.discount) {
          if (item.discountPercent) {
            price = (item.quantity * item.price) - (item.quantity * item.price * (item.discountValue / 100))
          } else {
            price = item.quantity * item.price - (item.quantity * item.discountValue)
          }
        } else {
          price = item.quantity * item.price
        }

        total += price
        counter++
        if (counter == $scope.offerItems.length) {
          return total
        }
      }
    }

    $scope.getTotalPrice = function (discount) {
      var total = 0; var counter = 0
      for (var i = 0; i < $scope.transaction.details.length; i++) {
        total += $scope.transaction.details[i].total
        counter++
        if (counter == $scope.transaction.details.length) {
          if (discount) {
            return total - $scope.getDiscount()
          } else {
            return total
          }
        }
      }
    }

    $scope.taxPrice = function (product) {
      var total = angular.copy(product.total)
      if (total < 0) {
        total = total * -1
      }

      return total - (total / (1 + (product.priceVat / 100)))
    }

    $scope.printOffer = function (type) {
      $scope.mailAlert = null

      if (type == 'string' && $scope.transaction.customer && $scope.transaction.customer.counter) {
        return
      }

      $pdf.downloadPdf('generate-offer', {
        transaction: $scope.transaction,
        shopId: $rootScope.currentShop._id,
        typeDocument: type,
        documentName: 'OFFER',
        deviceId: $rootScope.currentDevice._id
      }, $language.translate('OFFER') + ' ' + $scope.transaction.number + '.pdf',
      type == 'document' ? 'arraybuffer' : 'string')
        .then(function (data) {
          if (type != 'document') {
            $scope.mailAlert = {
              type: 'success',
              msg: $language.translate('MAIL_SEND')
            }
          }
        })
        .catch(function (reason) {
          console.error(reason)
          if (type != 'document') {
            $scope.alert = {
              type: 'danger',
              msg: reason
            }
          }
        })
    }

    $scope.updateOfferStatus = function (status) {
      if (status == 'cancelled') {
        prompt({
          title: $language.translate('CANCEL_OFFER'),
          message: $language.translate('ARE_YOU_SURE_TO_CANCEL_THIS_OFFER')
        }).then(function () {
          return updateStatus()
        })
      } else {
        return updateStatus()
      }

      function updateStatus () {
        $scope.transaction.status = status

        if(status === 'paid') {
          if($scope.transaction.totalEarning === 0) {
            $scope.transaction.totalEarning = $scope.transaction.total;
          }
        }

        $scope.transaction.log.push({
          date: new Date(),
          user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
          text: $language.translate('CHANGED_STATUS_FROM_TO', { oldStatus: $language.translate($scope.statusBackup.toUpperCase()), newStatus: $language.translate(status.toUpperCase()) }),
          isFor: 'retailerPortal',
          eType: 'retailer'
        })
        $scope.statusBackup = angular.copy(status)
        $scope.updateTransaction()
      }
    }

    $scope.saveItem = function (item) {
      if ($scope.transaction.type == 'offer') {
        // First use the including price for the calculations
        item.price = item.priceWithTax
      }

      if (item.discount) {
        if (item.discountPercent) {
          item.total = (item.quantity * item.price) - (item.quantity * item.price * (item.discountValue / 100))
        } else {
          item.total = item.quantity * item.price - (item.quantity * item.discountValue)
        }
      } else {
        item.total = item.quantity * item.price
      }

      if (!$scope.editItem) {
        $scope.transaction.details.push(angular.copy(item))
      }
      if ($scope.transaction.type == 'offer') {
        // Now strip off the taxes to save the correct price
        item.price = parseFloat(item.priceWithTax) / (1 + (item.priceVat / 100))
      }
      $scope.offerItem = {}
      $scope.transaction.details[$scope.offerIndex] = item
      $scope.editItem = false
      $scope.offerIndex = null
      $scope.updateTransaction()
    }

    $scope.openItem = function (item, index) {
      if (!item.discount) {
        item.discount = 0
      }
      if ($scope.transaction.type == 'offer') {
        item.priceWithTax = item.price + (item.price * (item.priceVat / 100))
      }
      $scope.offerItem = angular.copy(item)
      $scope.editItem = true
      $scope.offerIndex = index
    }

    $scope.deleteItem = function (index) {
      $scope.cancelItem()
      $scope.transaction.details.splice(index, 1)
      $scope.updateTransaction()
    }

    $scope.cancelItem = function () {
      $scope.offerIndex = null
      $scope.offerItem = null
      $scope.editItem = false
    }

    $scope.findItems = function (value, callback) {
      $scope.hits = []
      if (!value || value.length < 3) {
        return
      }

      if ($scope.searchMode == 'transactions') {
        $transaction.findTransactions(value)
          .then(function (results) {
            $scope.hits = results
          })
          .catch(function (reason) {
            console.error(reason)
          })
        return
      }

      if ($rootScope.currentShop) {
        var data = {
          nameSlug: $rootScope.currentShop.nameSlug,
          searchMode: $scope.searchMode,
          searchText: value,
          shopId: $rootScope.currentShop._id,
          shopProducts: true
        }

        $api.post('webshop-products-search/', data)
          .then(function (response) {
            if (response.status != 200) {
              return
            }

            if (callback && response.data.products.length == 1) {
              return callback(response.data.products[0])
            } else {
              $scope.hits = response.data.products
            }
          })

          .catch(function (reason) {
            console.error(reason)
          })
      }
    }

    $scope.addItem = function (product) {
      $scope.hits = []
      if ($scope.searchMode == 'transactions') {
        $scope.offerItem = {
          name: $language.translate(product.type.toUpperCase()) + ' ' + product.number,
          quantity: 1,
          price: product.price,
          transaction: product._id,
          discount: 0,
          photo: null,
          taxRate: '21',
          comment: '',
          discount: false
        }
      } else {
        var productPrice = product.price ? product.price * 1.21 : product.suggestedRetailPrice * 1.21
        $scope.offerItem = {
          name: product.brand.name + ' ' + product.variants[0].productNumber,
          ean: product.variants[0].ean,
          quantity: 1,
          price: Math.round(productPrice * 100) / 100,
          productId: product._id,
          discount: product.discount ? product.discount : 0,
          photos: product.images && product.images.length > 0 ? [{
            src: product.images[0].src,
            alt: product.images[0].alt
          }] : null,
          taxRate: '21',
          comment: '',
          discount: false
        }
      }
    }

    $scope.calculateTotalTax = function () {
      var counter = 0; var totalTax = 0
      for (var i = 0; i < $scope.transaction.details.length; i++) {
        var item = $scope.transaction.details[i]
        var tax = item.total * (parseInt(item.priceVat) / 100)

        totalTax += tax
        counter++
        if (counter == $scope.transaction.details.length) {
          return totalTax
        }
      }
    }

    $scope.openLightbox = function (images) {
      Lightbox.openModal(images, 0)
    }

    $scope.addDiscount = function () {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/retailer-add-discount-modal.html',
        controller: 'retailerAddDiscountModalController',
        keyboard: false,
        resolve: {
          discount: function () {
            return $scope.transaction.discount ? $scope.transaction.discount : null
          }
        }
      })
      modalInstance.result.then(function (result) {
        $scope.transaction.discount = result
        $scope.updateTransaction()
      }, function () {
        $scope.updateTransaction()
      })
    }

    $scope.getDiscount = function () {
      if (!$scope.transaction.discount) {
        return 0
      }

      if ($scope.transaction.discount.percent) {
        return $scope.getTotalPrice(false) * ($scope.transaction.discount.value / 100)
      } else {
        return $scope.transaction.discount.value
      }
    }

    $scope.getDiscountPercent = function () {
      if ($scope.transaction.discount && $scope.transaction.discount.percent) {
        return '(' + $scope.transaction.discount.value + '%)'
      } else {

      }
    }

    $scope.openChangeCustomerModal = function () {
      $customer.openSearchCustomerModal(false, false)
        .then(function (result) {
          $scope.transaction.customer = result
          if (!$scope.transaction.log) $scope.transaction.log = []
          $scope.transaction.log.push({
            date: new Date(),
            user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
            text: 'Customer Changed',
            isFor: 'retailerPortal',
            eType: 'retailer'
          })
          $scope.updateTransaction()
        })
        .catch(function (reason) {
          console.error('REASON', reason)
        })
    }

    $scope.refundTransaction = function () {
      console.log('click on refund from service/:id route')
      $scope.checkout(true)
    }

    $scope.openSupplier = function () {
      $state.go('retailer.supplier', { nameSlug: $scope.transaction.supplier.nameSlug })
    }

    $scope.openRepairer = function () {
      $state.go('retailer.supplier', { nameSlug: $scope.transaction.repair.repairer.nameSlug })
    }

    $scope.acceptOffer = function () {
      var items = []; var counter = 0

      for (var i = 0; i < $scope.transaction.details.length; i++) {
        items.push($scope.transaction.details[i])

        counter++
        if (counter === $scope.transaction.details.length) {
          // $scope.updateOfferStatus('approved');
          $state.go('retailer.till', {items: items, customer: $scope.transaction.customer})
        }
      }
    }

    $scope.deposit = function (percent) {
      $scope.checkout(false, percent)
    }

    $scope.getTransactionPrice = function () {
      var counter = 0; var totalAmount = 0
      for (var i = 0; i < $scope.transaction.details.length; i++) {
        totalAmount += $scope.transaction.details[i].total
        counter++
        if (counter == $scope.transaction.details.length) {
          return totalAmount
        }
      }
    }

    $scope.getPrice = function (checkout, callback) {
      if (!$scope.transaction) {
        return
      }
      if (checkout && $scope.outstanding > 0) {
        return callback($scope.outstanding)
      }
      if ($scope.transaction.type == 'repair' || $scope.transaction.type == 'special') {
        if ($scope.transaction[$scope.transaction.type].actualCosts) {
          return callback($scope.transaction[$scope.transaction.type].actualCosts)
        } else if ($scope.transaction[$scope.transaction.type].estimatedPrice) {
          return callback($scope.transaction[$scope.transaction.type].estimatedPrice)
        } else {
          return callback(0)
        }
      } else if ($scope.transaction.type == 'shop-purchase') {
        return callback($scope.getTransactionPrice() * (-1))
      }
    }

    $scope.getOutstandingAmount = function () {
      $scope.outstanding = 0
      $scope.getPrice(false, function (price) {
        if (!$scope.payments || $scope.payments.length == 0) {
          $scope.outstanding = price
          return
        }
        var counter = 0; var totalPaid = 0
        for (var i = 0; i < $scope.payments.length; i++) {
          totalPaid += $scope.payments[i].amount - $scope.payments[i].change
          counter++
          if (counter == $scope.payments.length) {
            $scope.outstanding = price - totalPaid
          }
        }
      })
    }

    $scope.fetchPreviousPaidQuantity = function () {
      return $q(function (resolve, reject) {
        var totalPaidQuantity = 0
        if ($scope.transaction.details && $scope.transaction.details.length && $scope.transaction.details[0].productId) {
          var currentSingleOrderProductId = $scope.transaction.details[0].productId._id

          for (var i = 0; i < $scope.transaction.transactions.length; i++) {
            var firstDetail = $scope.transaction.transactions[i].details[0]
            totalPaidQuantity += firstDetail.quantity
          }
        }
        console.log('totalPaidQuantity ------------>', totalPaidQuantity)
        return resolve(totalPaidQuantity)
      })
    }

    $scope.checkout = function (refund, deposit, finalCheckout) {
      console.log('refund', refund)
      console.log('deposit', deposit)
      console.log('finalCheckout', finalCheckout)

      var items = []
      if (refund) {
        console.log('$scope.transaction refunding something', $scope.transaction)

        // We gonna refund the whole order, so we take the items of the current transaction and placed them with an negative amount on a new transaction
        var counter = 0
        for (var i = 0; i < $scope.transaction.details.length; i++) {
          var detail = $scope.transaction.details[i]
          console.log('detail while refund shop-purchase transaction', detail)

          var quantity

          if ($scope.transaction.totalEarning > $scope.transaction.transactionTotal) {
            quantity = -($scope.transaction.transactionTotal - $scope.transaction.totalEarning) / ($scope.transaction.details[0].price * (1 + ($scope.transaction.details[0].priceVat / 100)))
          }

          items.push({
            name: $language.translate('REFUND') + ' ' + detail.name,
            quantity: $scope.transaction.totalEarning > $scope.transaction.transactionTotal ? quantity : detail.quantity - detail.stockUpQuantity,
            price: detail.price,
            total: detail.total,
            productId: detail.productId && detail.productId._id ? detail.productId._id : null,
            transaction: $scope.transaction._id,
            discount: false,
            discountValue: 0,
            discountPercent: null,
            photos: detail.photos,
            priceVat: detail.priceVat ? detail.priceVat : 21,
            comment: detail.comment,
            purchasePrice: detail.purchasePrice,
            refunded: detail.refundAmount === detail.total,
            productForRefund: true,
            isRestictForEdit: $scope.transaction.totalEarning > $scope.transaction.transactionTotal,
            refundQuantity: $scope.transaction.totalEarning > $scope.transaction.transactionTotal ? quantity : detail.quantity - detail.stockUpQuantity,
            refundAmount: $scope.transaction.totalEarning > $scope.transaction.transactionTotal ? $scope.transaction.totalEarning - $scope.transaction.transactionTotal : detail.total - detail.refundAmount,
            refundedAmount: detail.refundAmount,
            parentTransactionDetailId: detail._id,
            stockUpQuantity: detail.stockUpQuantity,
            supplier: detail.supplier
          })
          counter++
          if (counter == $scope.transaction.details.length) {
            console.log('Final item for refund shop-purchase transaction', items)

            $state.go('retailer.till', {
              items: items,
              transaction: $scope.transaction._id,
              customer: $scope.transaction.customer
            })
          }
        }
      } else {
        console.log('$scope.transaction', $scope.transaction)
        var depositAmount = ($scope.transaction.transactionTotal - $scope.transaction.totalEarning)

        if (deposit) {
          depositAmount = depositAmount * (deposit / 100)
        }

        // $scope.getPrice(true, function (price) {
        if (finalCheckout && $scope.transaction.type === 'special') {
          console.log('Inside the finalCheckout condition')
          // var items;
          // Need to fetch sum of all shop-purchase order quantity which is done for this transaction
          $scope.fetchPreviousPaidQuantity()
            .then(function (paidQuantity) {
              console.log("$scope.transaction.details[0]['quantity'] in final checkout", $scope.transaction.details[0].quantity)
              console.log('paidQuantity in final checkout', paidQuantity)
              console.log('depositAmount in final checkout', depositAmount)

              var itemsData = [{
                name: $scope.transaction.details[0].name,
                quantity: $scope.transaction.details[0].quantity - paidQuantity, // depositAmount / ( $scope.transaction.details[0]['price'] * ( 1 + ($scope.transaction.details[0]['priceVat'] / 100 ) ) ),
                isRestictForEdit: true,
                price: $scope.transaction.details[0].price * (1 + ($scope.transaction.details[0].priceVat / 100)), // deposit ? ($scope.transaction.transactionTotal - $scope.transaction.totalEarning) * (deposit / 100) : ($scope.transaction.transactionTotal - $scope.transaction.totalEarning),
                transaction: $scope.transaction._id,
                discountValue: 0,
                total: depositAmount,
                photo: null,
                priceVat: 21,
                comment: $language.translate('DEPOSIT') + ' ' + $language.translate($scope.transaction.type.toUpperCase()) + ' ' + $scope.transaction.number + '. ' + '\n' + $language.translate('TOTAL_AMOUNT_TO_PAY') + ' \u20AC' + $scope.transaction.details[0].total, // (deposit ? $language.translate('TOTAL_AMOUNT_TO_PAY') + ' \u20AC' + $scope.transaction.transactionTotal : $scope.transaction.comment),
                discount: false,
                parentTransactionDetailId: $scope.transaction.details && $scope.transaction.details[0] && $scope.transaction.details[0]._id ? $scope.transaction.details[0]._id : null,
                productId: $scope.transaction.details && $scope.transaction.details[0] && $scope.transaction.details[0].productId ? $scope.transaction.details[0].productId._id : null,
                // purchasePrice: $scope.transaction.type == 'repair' && $scope.transaction.repair.externalPrice ? $scope.transaction.repair.externalPrice : $scope.transaction.type == 'special' ? 12345 : null,
                purchasePrice: $scope.transaction.details && $scope.transaction.details[0] && $scope.transaction.details[0].purchasePrice ? $scope.transaction.details[0].purchasePrice : null,
                noNeedToReCalculateOfTotal: true
              }]

              console.log('depositAmount after item creation in final checkout', depositAmount)

              console.log('items in final checkout -==-----------------><', itemsData)
              $state.go('retailer.till', {
                items: JSON.parse(JSON.stringify(itemsData)),
                transaction: $scope.transaction._id,
                customer: $scope.transaction.customer,
              })

              console.log('After redirect to another page')
            })
            .catch(function (error) {
              console.log('error ---------->?', error)
            })
        } else {
          items = [{
            name: $scope.transaction.details[0].name, // (refund ? $language.translate('REFUND') + ' ' : (deposit ? $language.translate('DEPOSIT') + ' ' : '')) + $language.translate($scope.transaction.type.toUpperCase()) + " " + $scope.transaction.number,
            quantity: depositAmount / ($scope.transaction.details[0].price * (1 + ($scope.transaction.details[0].priceVat / 100))), // 1,
            isRestictForEdit: true,
            price: $scope.transaction.details[0].price * (1 + ($scope.transaction.details[0].priceVat / 100)), // deposit ? ($scope.transaction.transactionTotal - $scope.transaction.totalEarning) * (deposit / 100) : ($scope.transaction.transactionTotal - $scope.transaction.totalEarning),
            transaction: $scope.transaction._id,
            discountValue: 0,
            total: depositAmount,
            photo: null,
            priceVat: 21,
            comment: $language.translate('DEPOSIT') + ' ' + $language.translate($scope.transaction.type.toUpperCase()) + ' ' + $scope.transaction.number + '. ' + '\n' + $language.translate('TOTAL_AMOUNT_TO_PAY') + ' \u20AC' + $scope.transaction.details[0].total, // (deposit ? $language.translate('TOTAL_AMOUNT_TO_PAY') + ' \u20AC' + $scope.transaction.transactionTotal : $scope.transaction.comment),
            discount: false,
            parentTransactionDetailId: $scope.transaction.details && $scope.transaction.details[0] && $scope.transaction.details[0]._id ? $scope.transaction.details[0]._id : null,
            productId: $scope.transaction.details && $scope.transaction.details[0] && $scope.transaction.details[0].productId ? $scope.transaction.details[0].productId._id : null,
            // purchasePrice: $scope.transaction.type == 'repair' && $scope.transaction.repair.externalPrice ? $scope.transaction.repair.externalPrice : $scope.transaction.type == 'special' ? 12345 : null,
            purchasePrice: $scope.transaction.type == 'repair' && $scope.transaction.repair.externalPrice ? $scope.transaction.repair.externalPrice : null
          }]

          $state.go('retailer.till', {
            items: items,
            transaction: $scope.transaction._id,
            customer: $scope.transaction.customer
          })
        }
      }
    }

    $scope.continueTransaction = function () {
      $state.go('retailer.till', {
        items: $scope.transaction.details,
        transaction: $scope.transaction._id,
        customer: $scope.transaction.customer
      })
    }

    $scope.cancelTransaction = function () {
      var dlg = dialogs.confirm($language.translate('CANCEL'), $language.translate('ARE_YOU_SURE_TO_CANCEL_THIS_TRANSACTION'), { size: 'sm' })
      dlg.result.then(function (btn) {
        if (btn == 'yes') {
          $scope.changeStatus('cancelled')
        }
      })
        .catch(function (reason) {
          console.error(reason)
        })
    }

    $scope.AddComment = function (comment) {
      if ($scope.transaction && comment) {
        var body = {
          transactionId: $scope.transaction._id,
          shopId: $rootScope.currentShop._id,
          shopName: $rootScope.currentShop.name,
          // shopSlug: $rootScope.currentShop.nameSlug,
          // companySlug: $scope.transaction.supplier.nameSlug,
          transactionType: $scope.transaction.type,
          repairer: ($scope.transaction.repair && $scope.transaction.repair.repairer && $scope.transaction.repair.repairer._id ? $scope.transaction.repair.repairer._id : ''),
          remarks: {
            eType: 'retailer',
            remark: comment,
            companyId: $scope.transaction.supplier
          }
        }

        $transaction.addComment(body).then(function (response) {
          $scope.currentremark = ''
          $('#currentremark').val('')
          $scope.getTransaction()
        }).catch(function (err) {
          $scope.currentremark = ''
          $('#currentremark').val('')
          console.error(err)
        })
      }
    }

    $scope.changeRepairer = function (repairer) {
      $scope.transaction.repair.repairerType = repairer

      if (repairer === 'in-house') {
        $scope.isRepairMySelf = true
        $scope.removeRepairer()
      } else {
        console.log('$scope.transaction.brand', $scope.transaction.brand)

        if ($scope.transaction.brand && $scope.transaction.brand._id) {
          if (!$scope.shopBrands || $scope.shopBrands.length === 0) return

          var shopBrandsLength = $scope.shopBrands.length
          for (var i = 0; i < shopBrandsLength; i++) {
            var brand = $scope.shopBrands[i]
            console.log('$scope.transaction.brand._id', $scope.transaction.brand._id)
            console.log('brand._id', brand._id)
            if (brand._id._id === $scope.transaction.brand._id) {
              $scope.isRepairMySelf = false
              console.log('brand.favoriteRepairer._id', brand)
              $scope.selectedRepairer(brand.favoriteRepairer._id)
              i = shopBrandsLength
            }
          }
        } else {
          console.log('No Brand Selected')
        }
      }

      if ($scope.transaction.type == 'repair') $scope.updateTransaction()
    }

    $scope.repairMySelf = function (status) {
      console.group(' repairMySelf : ')
      console.log('Status', status)
      if (status == true) {
        $scope.transaction.status = 'processing'
        $scope.transaction.repair.repairerType = 'in-house'
        $scope.isRepairMySelf = true
        $scope.removeRepairer()
      } else {
        $scope.transaction.status = 'processing'
        if ($scope.transaction.brand && $scope.transaction.brand._id) {
          console.log('shopBrands', $scope.shopBrands)
          var brand = _.find($scope.shopBrands, function (o) { return o._id._id == $scope.transaction.brand._id })
          console.log('Brand Founded', brand)
          if (brand) {
            if (brand.favoriteRepairer._id) {
              $scope.selectedRepairer(brand.favoriteRepairer._id)
              $scope.isRepairMySelf = false
              $scope.transaction.repair.repairerType = 'external'
              $('#procent-1').prop('checked', false)
            } else {
              $scope.transaction.repair.repairerType = 'in-house'
              $scope.isRepairMySelf = true
              $('#procent-1').prop('checked', true)
            }
          } else {
            $scope.transaction.repair.repairerType = 'in-house'
            $scope.isRepairMySelf = true
            $('#procent-1').prop('checked', true)
          }
        } else {
          $scope.transaction.repair.repairerType = 'in-house'
          $scope.isRepairMySelf = true
          $('#procent-1').prop('checked', true)
        }
      }
      console.groupEnd()
      if ($scope.transaction.type == 'repair') $scope.updateTransaction()
    }

    $scope.EditCommentMode = function (remark, index) {
      for (var i = 0; i < $scope.transaction.remarks.remarks.length; i++) {
        $scope.transaction.remarks.remarks[i].isEditMode = false
      }
      $scope.transaction.remarks.remarks[index].isEditMode = true
      $scope.editRemarkDetail = remark
    }

    $scope.UpdateComment = function (comment, index) {
      if (comment) {
        $scope.editRemarkDetail.remark = comment
        $scope.transaction.remarks.remarks[index].isEditMode = false
        var body = {
          transactionId: $scope.transaction._id,
          commentId: $scope.editRemarkDetail._id,
          remark: comment,
          shopId: $rootScope.currentShop._id
        }

        $api.post('edit-comment-service-retailer-and-company', body)
          .then(function (response) {
            $scope.editRemarkDetail = ''
            $scope.getTransaction()
          })
          .catch(function (err) {
            $scope.editRemarkDetail = ''
            console.error(err)
          })
      } else {
        $scope.editRemarkDetail = ''
        $scope.purchase_order.remarks.remarks[index].isEditMode = false
      }
    }

    $scope.openTransaction = function (id) {
      $state.go('retailer.service-item', {
        transactionId: id
      })
    }

    $scope.copyTransaction = function () {
      $scope.transaction.shopId = $scope.transaction.shopId._id

      if ($scope.transaction.type == 'repair') {
        if ($scope.transaction.repair.estimatedPrice) {
          $scope.transaction.estimatedPrice = $scope.transaction.repair.estimatedPrice.toString()
        }
        $scope.transaction.estimatedDate = $scope.transaction.repair.estimatedDate
        $scope.transaction.category = $scope.transaction.repair.category
        $scope.transaction.material = $scope.transaction.repair.material
        $scope.transaction.email = $scope.transaction.repair.email
        $scope.transaction.actualCosts = $scope.transaction.repair.actualCosts
        $scope.transaction.externalPrice = $scope.transaction.repair.externalPrice
        if ($scope.transaction.repair.repairer) {
          $scope.transaction.repairer = $scope.transaction.repair.repairer._id
        }
      }
      if ($scope.transaction.type == 'special') {
        if ($scope.transaction.special.estimatedPrice) {
          $scope.transaction.estimatedPrice = $scope.transaction.special.estimatedPrice.toString()
        }
        $scope.transaction.estimatedDate = $scope.transaction.special.estimatedDate
        $scope.transaction.actualCosts = $scope.transaction.special.actualCosts
      }
      if ($scope.transaction.supplier) {
        $scope.transaction.company = $scope.transaction.supplier
      }

      $transaction.createTransaction($scope.transaction, null, $scope.transaction.number, true)
        .then(function (trans) {
          $scope.openTransaction(trans._id)
        })
        .catch(function (reason) {
          console.error(reason)
        })
    }

    $scope.getTaxes = function () {
      $api.get('tax/shop', { shopId: $rootScope.currentShop._id })
        .then(function (res) {
          $scope.taxRates = res.data.taxes.rates

          $scope.offerItem.priceVat = $scope.taxRates[0].rate
        })
        .catch(function (reason) {
          console.error('Error while getting taxes for this shop', reason)
        })
    }

    $scope.emailReceipt = function () {
      if (!$scope.transaction.customer || !$scope.transaction.customer.email || $scope.transaction.customer.counter === true) {
        return
      }
      $scope.makeReceipt('string')
    }

    $scope.makeReceipt = function (type, payment) {
      if (type == 'string') {
        $scope.sendingMail = true
        $scope.mailSent = false
      }

      $pdf.downloadPdf('generate-shop-receipt', {
        transactionId: $scope.transaction._id,
        email: $scope.transaction.customer.email,
        shopId: $rootScope.currentShop._id,
        payMethods: $scope.payMethods,
        typeDocument: type,
        documentName: 'TRANSACTION',
        deviceId: $rootScope.currentDevice._id
      }, $scope.transaction.number + '.pdf',
      type == 'document' ? 'arraybuffer' : 'string')
        .then(function (data) {
          if (type != 'document') {
            $scope.mailAlert = {
              type: 'success',
              msg: $language.translate('MAIL_SEND')
            }
          }
        })
        .catch(function (reason) {
          console.error(reason)
          $scope.alert = {
            type: 'danger',
            msg: reason
          }
        })
    }

    $scope.printStorePurchaseReceipt = function () {
      $scope.makeReceipt('document')
    }

    $scope.getEmployees = function () {
      $employee.getEmployees().then(function (response) {
        $scope.employees = response
      }).catch(function (error) {
      })
    }

    $scope.searchUsers = function (str) {
      var matches = []
      $scope.employees.forEach(function (item) {
        if ((item.firstName && item.firstName.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                    (item.lastName && item.lastName.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                    (item.lastNamePrefix && item.lastNamePrefix.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                    (item.username && item.username.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                    (item.initials && item.initials.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                    (item.email && item.email.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)
        ) {
          matches.push(item)
        }
      })
      return matches
    }

    $scope.userSelected = function (selected) {
      $scope.isRemovedAssitedBy = false
      $scope.transaction.createdBy = {
        _id: selected.originalObject._id,
        name: selected.originalObject.firstName + ' ' + (selected.originalObject.lastNamePrefix ? selected.originalObject.lastNamePrefix + ' ' : '') + selected.originalObject.lastName
      }
      if ($scope.transaction.type == 'repair') $scope.updateTransaction()
    }

    // Whenever adding comment to the then we have add new line when pressed (SHIFT + ENTER) and if only enter then add Comment
    $scope.addCommentKeyUp = function (event, currentremark) {
      if (event.keyCode === 13) {
        if (event.shiftKey) return false
        else $scope.AddComment(currentremark)
      }
    }

    $scope.autoScrollcomment = function () {
      setTimeout(function () {
        $('#messagewindow').animate({ scrollTop: $('#messagewindow')[0].scrollHeight }, 0)
      }, 200)
    }

    $scope.autoScrollPurchasOrdercomment = function () {
      setTimeout(function () {
        $('#purchaseordermessagewindow').animate({ scrollTop: $('#purchaseordermessagewindow')[0].scrollHeight }, 0)
      }, 200)
    }

    // Update purchasePrice for a particular transactions and it's shop purchase transaction
    $scope.updatedPurchasePrice = function (detailsIndex) {
      console.log($scope.transaction[$scope.transaction.type].externalPrice)
      $api.put('v2/transaction/product/purchase-price', {
        detailsId: $scope.transaction.details[detailsIndex]._id,
        transactionId: $scope.transaction._id,
        purchasePrice: $scope.transaction[$scope.transaction.type].externalPrice,
        shopId: $rootScope.currentShop._id
      })
        .then(function (response) {
          console.log('response from api', response)
        }).catch(function (reason) {
          console.error('Error while getting shop', reason)
        })
    }

    function getShop () {
      return $q(function (resolve, reject) {
        if (!$rootScope.currentShop) {
          $retailer.getShop()
            .then(function (shop) {
              $rootScope.currentShop = shop
              return resolve($rootScope.currentShop)
            })
        } else {
          return resolve($rootScope.currentShop)
        }
      })
    }

    $scope.checkPrinter = function () {
      $printer.checkEnabled($rootScope.currentShop)
        .then(function () {
          return $printer.checkEnabled(shop)
            .then(function () {
              $scope.printerEnabled = true
            })
            .catch(function (reason) {
              console.info(reason)

              $scope.printerEnabled = false

              // $scope.printerError = {
              //     type: 'warning',
              //     msg: $language.translate(reason)
              // }
              return false
            })
        })
    }

    $scope.printThermal = function () {
      $printer.checkEnabled($rootScope.currentShop)
        .then(function () {
          $printer.printShopReceipt({
            shop: $rootScope.currentShop,
            transaction: $scope.transaction
          })
            .then(function (result) {

            })
            .catch(function (reason) {
              console.error('Something went wrong in $printer', reason)
              dialogs.error($language.translate('ERROR'), $language.translate('AN_ERROR_OCCURED') + ': ' + reason, { size: 'sm' })
            })
        })
        .catch(function (reason) {
          console.info(reason)
          dialogs.notify(undefined, $language.translate('PRINTER_IS_NOT_ENABLED'), { size: 'sm' })
        })
    }

    $scope.customerCanBeMailed = function () {
      if (!$scope.transaction.customer) return false
      if (
        $scope.transaction.status === 'completed' && !$scope.transaction.customer.counter &&
                $scope.transaction.customer.email !== '' && $scope.transaction.customer.email.indexOf('autocreated') < 0
      ) {
        return true
      } else {
        return false
      }
    }
    $scope.changeCustomer = function () {
      $customer.openSearchCustomerModal(false, false)
        .then(function (result) {
          $scope.transaction.customer = result
          $scope.updateTransaction()
        })
        .catch(function (reason) {
          console.error('Error while changing customer on transaction', reason)
        })
    }

    $scope.changePayment = function () {
      $state.go('retailer.checkout.start', {transactionId: $scope.transaction._id, payLater: true})
    }

  }])
