prismanoteApp.controller('retailerNameAndDescriptionsBulkChange', ['$scope', '$rootScope', '$api',
  function ($scope, $rootScope, $api) {

    $scope.languageHere = $rootScope.language;
    $scope.shopProducts = [];
    $scope.bulkUpdateDiscount = false;
    $scope.bulkUpdatePrice = false;
    $scope.showApplyButton = false;
    $scope.loading = false;
    $scope.pageLimit = 100;
    $scope.pageNumber = 1;
    $scope.onApplySuggestedName = false;
    $scope.viewOptions = 'name';
    $scope.productsToUpdate = [];
    $scope.blockBrowser = false;

    function blockTheBrowser(multiplier){
      $scope.blockBrowser = true;
      setTimeout(function () {
        $scope.blockBrowser = false;
        $scope.getShopProductsForBulkUpdate($scope.pageNumber);
      }, (3000 * multiplier))
    }

    $scope.$on('privateLabelCollectionsForBulkPage', function (event, data) {
      console.log('-------- privateLabelCollectionsForBulkPage!!');
      // var articleGroupId = data.collection.privateLabelCollections[0]._id;
      var articleGroupId = (data && data.collection[0] && data.collection[0]._id) ? data.collection[0]._id : undefined;
      $scope.articleGroupId = articleGroupId;
      $scope.pageNumber = 1;
      $scope.getShopProductsForBulkUpdate();
    })

    $scope.changePageLimit = function(number){
      console.log('------- changePageLimit!!');
      console.log('number ', number);
      $scope.pageLimit = number;
      $scope.loading = true;
      $scope.shopProducts = [];
      $scope.getShopProductsForBulkUpdate($scope.pageNumber);
    }

    // $scope.changePageLimitSample = function(){
    //   console.log('------- changePageLimitSample!!');
    //   $scope.loading = true;
    //   $scope.shopProducts = [];
    // }

    $scope.changePage = function(flag){
      console.log('------- changePage!!');
      console.log('flag ', flag);
      if(flag == 'previous'){ $scope.pageNumber = $scope.pageNumber - 1; }
      if(flag == 'next'){ $scope.pageNumber = $scope.pageNumber + 1; }
      $scope.getShopProductsForBulkUpdate($scope.pageNumber);
    }

    $scope.getShopProductsForBulkUpdate = function(pageNumber){
      console.log('---- getShopProductsForBulkUpdate!!');
      if(!pageNumber){ pageNumber = 1; }
      console.log('pageNumber ', pageNumber);
      $scope.shopProducts = [];
      $scope.loading = true;
      $api.get('shop/get-productname-and-description-for-bulk-update/' + $rootScope.currentShop._id + '?pageNumber=' + pageNumber + '&pageLimit=' + $scope.pageLimit + '&articleGroupId=' + $scope.articleGroupId)
        .then(function (response) {
          $scope.shopProducts = response.data.products;
          $scope.loading = false;
        })
        .catch(function (reason) {
          $scope.loading = false;
          console.log('-- reason ', reason);
        })
    }

    $scope.applyScopeChange = function (){
      console.log('--------------- applyScopeChange!!');
      if($scope.onApplySuggestedName){ $scope.onApplySuggestedName = []; }
      for(var i = 0; i < $scope.shopProducts.length; i++){
        if($scope.onApplySuggestedName){
          $scope.shopProducts[i].product.en = $scope.shopProducts[i].mainProduct.en;
          $scope.shopProducts[i].product.es = $scope.shopProducts[i].mainProduct.es;
          $scope.shopProducts[i].product.fr = $scope.shopProducts[i].mainProduct.fr;
          $scope.shopProducts[i].product.de = $scope.shopProducts[i].mainProduct.de;
          $scope.shopProducts[i].product.nl = $scope.shopProducts[i].mainProduct.nl;
          $scope.productsToUpdate.push($scope.shopProducts[i]);
        }
      }
      $scope.showApplyButton = false;
      $scope.showButton = true;
    }

    $scope.useSuggestedName = function(){
      console.log('--------------- useSuggestedName!!');
      $scope.showApplyButton = true;
    }

    $scope.changeName = function(viewOptions, value, index){
      console.log('------- changeName!!');
      console.log('viewOptions ', viewOptions, index);
      var flag = false;
      var updateIndex = $scope.productsToUpdate.length;
      for(var i = 0; i < $scope.productsToUpdate.length; i++){
        console.log($scope.productsToUpdate[i]._id, ' ', $scope.shopProducts[index]._id)
        if($scope.productsToUpdate[i]._id == $scope.shopProducts[index]._id){
          flag = true;
          updateIndex = i;
          // $scope.productsToUpdate.push($scope.shopProducts[index]);
        }
      }
      $scope.productsToUpdate[updateIndex] = $scope.shopProducts[index];
      // if(viewOptions == 'name'){
      //   console.log('value ', value);
      //   console.log('index ', index);
      //   $scope.shopProducts[index].product[$scope.languageHere].name = value;
      // }
      $scope.showApplyButton = true;
    }


    $scope.bulkUpdate = function(){
      console.log('---------- bulkUpdate!!');

      for(var i = 0; i < $scope.productsToUpdate.length; i++){
        delete $scope.productsToUpdate[i].mainProduct;
        delete $scope.productsToUpdate[i].product.variants;
        delete $scope.productsToUpdate[i].product.articleNumber;
        delete $scope.productsToUpdate[i].product.labelDescription;
      }
      var tempArr = angular.copy($scope.productsToUpdate);
      $scope.shopProducts = [];
      $scope.productsToUpdate = [];
      $scope.loading = true;
      console.log(tempArr.length, ' tempArr.length');
      console.log(tempArr, ' tempArr');
      blockTheBrowser(tempArr.length);
      $api.post('shop/name-and-description-bulk-update/' + $rootScope.currentShop._id + '?shopSlug=' + $rootScope.currentShop.nameSlug, tempArr)
      .then(function (response) {
        // $scope.getShopProductsForBulkUpdate();
        $scope.showButton = false;
        $scope.loading = false;
      })
      .catch(function (reason) {
        $scope.loading = false;
        console.error('Error while updating the products', reason)
      })
    }
  }
])
