prismanoteApp.controller('retailerImportsController', ['$scope', '$rootScope', '$api', '$state', '$stateParams',
  function ($scope, $rootScope, $api, $state, $stateParams) {
    if ($stateParams.id != null && $stateParams.id != undefined) {
      console.log($stateParams.id)
      getSingleImportsRecord($stateParams.id)
    }

    $scope.getImportsRecords = function () {
      var page = $scope.currentPage + 1
      $api.get('import-records-for-retailer/' + '?limit=' + $scope.itemsPerPage + '&pageNumber=' + page)
        .then(function (response) {
          $scope.importRecords = response.data.ImportRecord
          $scope.total = response.data.total
        })
        .catch(function (reason) {
          console.error('Error when getting import records', reason)
        })
    }

    function getSingleImportsRecord (id) {
      $api.get('import-record/' + id)
        .then(function (response) {
          response.data.ImportRecord.importer.dateOfCreation = new Date(response.data.ImportRecord.importer.dateOfCreation).toUTCString()
          $scope.importRecord = response.data.ImportRecord
        })
        .catch(function (reason) {
          console.error('Error while getting record', reason)
        })
    }

    $scope.selectUser = function (userId) {
      // console.log("get the user selected", userId);
    }

    $scope.getSingleImportsRecordRoute = function (id) {
      $state.go('retailer.import', { id: id })
    }

    $scope.deleteImport = function (importId) {
      // console.log("ImportId For DELETED All Import Record", importId);
    }

    $scope.reverseImport = function (id) {
      $api.post('reverseImport/' + id)
        .then(function (response) {
        })
        .catch(function (reason) {
          console.error('Error while reverse import', reason)
        })
    }

    $scope.startDate = new Date()
    $scope.endDate = new Date()
    $scope.openTheStartCalendar = false
    $scope.openTheEndCalendar = false

    $scope.dateOptions = {
    }

    // pagination
    $scope.viewby = 5

    $scope.setItemsPerPage = function (num) {
      if (!num)num = $scope.viewby
      $scope.itemsPerPage = parseInt(num)
      $scope.currentPage = 0 // reset to first page
      $scope.getImportsRecords()
    }

    $scope.changeThePage = function (direction) {
      var totalPages = Math.ceil($scope.total / $scope.itemsPerPage)
      if (direction == 'next') {
        var page = $scope.currentPage + 1
        if ((page + 1) <= totalPages) {
          $scope.currentPage = page
          $scope.getImportsRecords()
        }
      } else if (direction == 'previous') {
        var page = $scope.currentPage - 1
        if (page < 0) {
          // do nothing but just refresh the page
          // $scope.getImportsRecords();
        } else {
          $scope.currentPage = page
          $scope.getImportsRecords()
        }
      }
    }

    $scope.setItemsPerPage($scope.viewby)

    $scope.formatDate = function (date) {
      var mydate = new Date(date)
      var monthNames = [
        'January', 'February', 'March',
        'April', 'May', 'June', 'July',
        'August', 'September', 'October',
        'November', 'December'
      ]

      var day = mydate.getDate()
      var monthIndex = mydate.getMonth()
      var year = mydate.getFullYear()

      return day + ' ' + monthNames[monthIndex] + ' ' + year
    }
  }])
