prismanoteApp.controller("retailerProduct1Controller", ['$scope', '$rootScope', '$stateParams', '$diamond', '$productConfig', '$product', 'item', '$purchaseOrder', '$uibModal', 'Upload', '$shop', '$state', '$q', '$notification', '$language', 'prompt', '$timeout', '$user', '$uibModalInstance', '$brand', '$recommendationOrder',
    function ($scope, $rootScope, $stateParams, $diamond, $productConfig, $product, item, $purchaseOrder, $uibModal, Upload, $shop, $state, $q, $notification, $language, prompt, $timeout, $user, $uibModalInstance, $brand, $recommendationOrder) {
        $scope.localdata = { 
            language: $rootScope.language,
            showerror: false,
            errormessage: '',
            isProductNumberAlredyExist: false,
            currentProductDetailForMerge: {},
            productNumberWithBrand: '',
            labelDescription: '',
            shopSelected: '',
            isDescriptionExist: false // For Short description
        }
        $scope.editSupForMultiShop = true;
        $scope.isBrandExist = false;
        $scope.seletectedMultishopStock = 0;
        //console.log('------------------------------- retailerProduct1Controller!!', (JSON.parse(JSON.stringify(item))));
        $scope.temp = angular.copy(item);
        //console.log('$scope.temp  ', $scope.temp);
        // console.log('$scope.temp.index ', $scope.temp.index);
        // if(item.extraData._id) { $stateParams._id = item.extraData._id };
        // if(item.extraData.itemId) { $stateParams.itemId = item.extraData.itemId }
        var localItemId = '';
        if (item && item.extraData && item.extraData.tempItemId) localItemId = item.extraData.tempItemId;
        //console.log('------------------------------- $stateParams.orderNumber 1:', $stateParams.orderNumber, item.extraData);
        if(item.extraData.orderNumber) { $stateParams.orderNumber = item.extraData.orderNumber } else { $stateParams.orderNumber = null; }
        //console.log('------------------------------- $stateParams.orderNumber 2:', $stateParams.orderNumber);
        $stateParams._id = item.extraData._id;
        $stateParams.itemId = item.extraData.itemId;
        console.log(item.extraData);
        // For hiding and showing the Merge button
        if ($scope.temp && $scope.temp.isFromPurchaseOrder) {
            $scope.localdata.isFromPurchaseOrder = true;
        }
        function getShopProductSerialNumbers(shopId, productId) {
            $shop.getShopProductSerialNumbers(shopId, productId)
            .then(function (serialNumbers) {
                $scope.serialNumbers = serialNumbers;
            })
            .catch(function (error) {
                console.error("Error In serialNumbers");
            })
        }

        function sortArrayByKey(arr,key) {
            return arr.sort(function(a, b){
                if(a[key] < b[key]) { return -1; }
                if(a[key] > b[key]) { return 1; }
                return 0;
            })
        }

        $scope.purchaseOrderNumber = $stateParams.orderNumber;
        $scope.loading = true;
        $scope.unofficialProduct = false;
        $scope.unofficialProductBrandFound = true;
        $scope.displaySwitch = false;
        $scope.generatedArticalNumber = false;
        $scope.product = {};
        $scope.product.variants = [];
        $scope.shop = {
            product: {}
        };
        $scope.exOpen = false;
        $scope.dataStatus = 'bad'; // bad , alright , good
        $scope.notInShop = false;
        // $scope.shop = { product: { 'minStock': 0, 'stock': 0, 'discount': 0 } };
        $scope.PNvalid = true;
        $scope.eanValid = true;
        $scope.oldCollections = [];
        $scope.editProduct = false;
        $scope.editPVC = false;
        $scope.editBrand = false;
        $scope.gemKinds = $diamond.getGemKinds();
        $scope.gemPurities = $diamond.getGemPurities();
        $scope.gemCuts = $diamond.getGemCuts();
        $scope.gemColors = $diamond.getGemColors();
        $scope.strapColors = $productConfig.getStarpColors();
        $scope.dialColors = $productConfig.getDialColors();
        $scope.ringSizeList = $productConfig.getJewelRingSize();
        $scope.useRingsize = 'key';
        $scope.braceletSizeList = range(8, 31, 0.5);
        $scope.necklaceSizeList = range(35, 135, 1);
        $scope.caseColors = $scope.jewelColors = Object.assign($scope.dialColors, $scope.strapColors);

        $scope.getThumbnailUrl = function(url){
            if(url){
                var index = url.indexOf('/');
                var folder = url.substring(0, index + 1);
                var fileName = url.replace(folder, '');
                return folder + 'prismathumbnail-' + fileName;
            }
        }
        var rawGemKinds = $diamond.getGemKinds();

        //translate gemKinds
        rawGemKinds = rawGemKinds.map(function(gemKind) {
            gemKind.label = $language.translate(gemKind.key.split(' ').join('').toUpperCase())
            return gemKind
        });

        //sort gemKinds by translation (label)
        $scope.gemKinds = sortArrayByKey(rawGemKinds,'label');

        $scope.getPurchaseOrdersForSpecificProduct = function (){
            $shop.getPurchaseOrdersForSpecificProduct($scope.product._id, $rootScope.currentShop._id)
            .then(function (result) {
                $scope.product.orderCount = result;
            })
            .catch(function (err) {
                console.error("err", err);
            })
        }

        $scope.getSupplierDetaisFromCurrentShop = function (_id) {
            console.log('-------------------------------------------------------------------------- getSupplierDetaisFromCurrentShop!!');
            $shop.getSupplierDetaisFromCurrentShop($rootScope.currentShop.nameSlug, _id)
            .then(function (result) {
                $scope.shop.product.supplier = {
                    _id: result.data._id._id,
                    name: result.data._id.name
                }

                if ($scope.shop.product.price != undefined && $scope.shop.product.price != NaN && $scope.shop.product.price != 'NaN' && $scope.shop.product.purchasePrice >= 0) {
                    console.log('--- if!!');
                    if ($scope.shop.product.entryMethodCustomerValue != undefined && (result.data.storageFactor || result.data.purchaseMargin)) {
                        console.log('--- if if!!');
                        //console.log('$scope.shop.product ', $scope.shop.product);
                        //console.log('--------------------------------------------------------------------------------------------------------------------------------1-----------------ABC---------------------: ', $scope.shop.product.labelDescription, $scope.localdata.labelDescription, !$scope.localdata.labelDescription && !$scope.shop.product.labelDescription);
                        if (!$scope.localdata.labelDescription && !$scope.shop.product.labelDescription) {
                            $scope.shop.product.labelDescription = $scope.shop.product[$rootScope.language].name;
                        } else if ($scope.localdata.labelDescription) {
                            $scope.shop.product.labelDescription = $scope.localdata.labelDescription;
                        }
                        $scope.calculations = {
                            storageFactor: result.data.storageFactor,
                            margin: result.data.purchaseMargin,
                            discount: (result.data.discount) ? result.data.discount : 0
                        };
                        //-console.log('$scope.calculations ', $scope.calculations);
                        //-console.log('$scope.temp  ', $scope.temp);
                        $scope.changeInputMethod($scope.shop.product.entryMethodCustomerValue);
                    } else if (result.data.entryMethodCustomerValue != undefined && (result.data.storageFactor || result.data.purchaseMargin)) {
                        console.log('--- if else if!!');
                        $scope.calculations = {
                            storageFactor: result.data.storageFactor,
                            margin: result.data.purchaseMargin,
                            discount: (result.data.discount) ? result.data.discount : 0
                        };
                        $scope.shop.product.entryMethodCustomerValue = result.data.entryMethodCustomerValue;
                        $scope.changeInputMethod($scope.shop.product.entryMethodCustomerValue);
                    } else if ($scope.shop.product.entryMethodCustomerValue != undefined) {
                        console.log('--- if else if else if!!');
                        $scope.changeInputMethod($scope.shop.product.entryMethodCustomerValue);
                    } else {
                        console.log('--- if else if else if else!!');
                        if ($scope.shop.product.entryMethodCustomerValue != undefined != null) $scope.displaySwitch = true;
                        $scope.calculations = {
                            storageFactor: result.data.storageFactor,
                            margin: result.data.purchaseMargin,
                            discount: (result.data.discount) ? result.data.discount : 0
                        };
                    }
                } else {
                    console.log('--- else!!');
                    console.log('$scope.calculations.margin ', $scope.calculations.margin);
                    console.log('$scope.calculations.storageFactor ', $scope.calculations.storageFactor);
                    if ($scope.shop.product.entryMethodCustomerValue == undefined && $scope.shop.product.entryMethodCustomerValue == null) $scope.displaySwitch = true;
                    $scope.calculations = {
                        storageFactor: result.data.storageFactor,
                        margin: result.data.purchaseMargin,
                        discount: (result.data.discount) ? result.data.discount : 0
                    };

                    if ($scope.calculations.margin) $scope.calculatePurchasePriceFromMargin($scope.calculations.margin);
                    else if ($scope.calculations.storageFactor) $scope.calculateSellingPriceFromStorageFactor($scope.calculations.storageFactor);
                }

                if (!$scope.supplier_storageFactor) $scope.supplier_storageFactor = (result.data.storageFactor) ? result.data.storageFactor : 0;
                if (!$scope.supplier_margin) $scope.supplier_margin = (result.data.margin) ? result.data.margin : 0;
                if (!$scope.supplier_discount) $scope.supplier_discount = (result.data.discount) ? result.data.discount : 0;

                console.log("$scope.shop.product.hasStock", $scope.shop.product.hasStock);
                if($scope.shop.product.hasStock != true || $scope.shop.product.hasStock != false){
                    $scope.shop.product.hasStock = false;
                }else{
                    $scope.shop.product.hasStock = !$scope.shop.product.hasStock;
                }
                console.log("$scope.shop.product.hasStock", $scope.shop.product.hasStock);
            })
            .catch(function (err) {
                console.log('getSupplierDetaisFromCurrentShop error');
                console.error("err", err);
            })
        }


        $scope.selectMethod = function (status) {
            console.log("Select Method");

            $scope.shop.product.entryMethodCustomerValue = status;

            if (!$scope.calculations) $scope.calculations = {};

            if (status == false && $scope.shop.product.price && $scope.shop.product.purchasePrice) $scope.calculations['storageFactor'] = $scope.shop.product.price / $scope.shop.product.purchasePrice;

            $scope.displaySwitch = false;
            $scope.changeInputMethod(status);
        }


        $scope.getLatestArticalNumberForShop = function (shopNameSlug) {
            console.log('------------------------ getLatestArticalNumberForShop!');
            $shop.getLatestArticalNumberForShop(shopNameSlug)
            .then(function (success) {
                console.log(shopNameSlug);
                console.log(success);
                $scope.shop.product.articleNumber = success;
                $scope.generatedArticalNumber = true;
            })
            .catch(function (error) {
                console.log("Error", error);
            })
        }

        function getTheLabelDescription() {
            var body = {
                purchaseOrderNumber: $stateParams.orderNumber,
                itemId: localItemId,
                shopId: $rootScope.currentShop._id
            }
            $purchaseOrder.getLabelDescription(body).then(function (success) {
                console.log("-------------------------------------------------------------------------------------------------------------------------------getLabelDescription", success.data)
                if (success && success.data && success.data.labelDescription) {
                    console.log('Hey labeldescription: ', success.data.labelDescription);
                    $scope.localdata.labelDescription = success.data.labelDescription;
                    if ($scope.shop && $scope.shop.product && $scope.localdata.labelDescription) {
                        $scope.shop.product.labelDescription = $scope.localdata.labelDescription
                    }
                }
            }).catch(function (error) {
                console.error("Error :", error);
            })
        }

        $scope.renderProduct = function (currentShop) {
            console.log('--------------------------------------------------------- Render Product!!');
            console.log($scope.product);
            // $scope.shop.product.articleNumber = '12332323'
            if ($rootScope.user && $rootScope.user.canViewProductDetails) {
                $scope.editProduct = false;
            } else {
                $scope.editProduct = true;
            }

            // console.log('$stateParams.orderNumber ', $stateParams.orderNumber);
            console.log('$stateParams.itemId ', $stateParams.itemId);
            // console.log('$rootScope.currentShop ', $rootScope.currentShop);

            // console.log('$stateParams._id ', $stateParams._id);
            if ($stateParams.orderNumber) getTheLabelDescription();

            if (( $stateParams.orderNumber || $stateParams.orderNumber == null) && $stateParams.itemId && $rootScope.currentShop) {
                console.log('------------------------------- if!!');
                $scope.mode = 'add'; // Default Mode is add Product
                $purchaseOrder.getProductDetailsFromPurchaseOrder($stateParams.orderNumber, $stateParams.itemId, $rootScope.currentShop._id)
                .then(function (success) {
                    console.log('------------------------------- ====================>if!!', success);
 
                    $scope.product[$rootScope.language] = {
                        'name': success.data.purchase.name
                    };

                    $scope.oldStock = 0;

                    $scope.product.variants = [{}];

                    if (!success.data.purchase.unofficialProduct) {
                        $scope.product.variants[0]['productNumberAuto'] = false
                        $scope.product.variants[0]['productNumber'] = success.data.purchase.number
                        $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
                    }

                    if (success.data.purchase.productFile && success.data.purchase.productFile.EAN) {
                        $scope.product.variants[0].ean = success.data.purchase.productFile.EAN;
                        $scope.eanIsExistsOrNot($scope.product.variants[0].ean);
                    }

                    $scope.shop.product.priceVat = success.data.purchase.priceVat || 21;
                    if (success.data.purchase && success.data.purchase.ean) $scope.product.variants[0].ean = success.data.purchase.ean;
                    $scope.shop.product.stock = success.data.purchase.receivedQuantity;
                    if ($scope.shop.product.stock < 0) $scope.shop.product.stock = 0;
                    $scope.localdata.labelDescription = success.data.purchase.name;
                    $scope.shop.product.labelDescription = success.data.purchase.name;
                    $scope.purchaseOrderEntryMethod = $scope.shop.product['entryMethodCustomerValue'] = success.data.purchase.entryMethodCustomerValue;
                    $scope.purchaseOrderPrice = Number(success.data.purchase.purchasePrice.toFixed(2));

                    if (success.data.purchase.unofficialProduct) {
                        $scope.product.variants[0].productNumberAuto = true;
                        $scope.unofficialProduct = true;

                            // First have to select brand based on the purcahseOrder
                            // Then after have to save the brand staing digit in varints details.current-shop
                            var unofficialProductData = success.data.purchase.unofficialProduct || null;

                            if (unofficialProductData) {
                                $scope.getBrandsByShop($rootScope.currentShop.nameSlug, unofficialProductData);
                            }
                        } else if (success.data.AutoMaticOrManualOrder === 'EDI' && success.data.purchase.brand &&
                        success.data.purchase.brand.brandId && success.data.purchase.brand.brandId._id) {
                            var brandData = {
                                brand: {
                                    _id: success.data.purchase.brand.brandId
                                }
                            }
                            $scope.getBrandsByShop($rootScope.currentShop.nameSlug, brandData);
                        } else {
                        $scope.getBrandsByShop($rootScope.currentShop.nameSlug, null);
                    }

                    $scope.changePVC();

                    $scope.shop.product.purchasePrice = $scope.purchaseOrderPrice;
                    console.log('$scope.shop.product.purchasePrice: ', $scope.shop.product.purchasePrice, 1111);
                    $scope.shop.product['purchaseOrders'] = [];

                        if (success && success.data && success.data._id) $scope.shop.product.purchaseOrders.push({
                            _id: success.data._id
                        });
                            $scope.shop.product.articleNumber = $scope.getLatestArticalNumberForShop($rootScope.currentShop.nameSlug);
                            $scope.shop.product.ownership = 'possession';
                            $scope.shop.product.price = Number(success.data.purchase.price);
                            $scope.shop.product.sellingPrice = Number(($scope.shop.product.price * (($scope.shop.product.priceVat / 100) + 1)).toFixed(2));
                            $scope.shop.product.discount = 0;
                            $scope.shop.product.discountOnPercentage = false;
                            $scope.shop.product.hasStock = true;
                            $scope.shop.product.minStock = 0;
                            $scope.shop.product.show = false;
                            $scope.shop.product.isBestseller = false;
                            $scope.shop.product.isFeatured = false;
                            $scope.shop.product.reference = '-';
                            $scope.shop.product.showSuggestion = false;
                            $scope.shop.product.dropshippingPrice = 0;
                            $scope.shop.product._id = $scope.product._id;
                            if(!$scope.shop.product.purchasePrice) $scope.shop.product.purchasePrice = 0;
                            console.log('$scope.shop.product.purchasePrice: ', $scope.shop.product.purchasePrice, 2112);
                            $scope.getSupplierDetaisFromCurrentShop(success.data.supplier._id);
                            $scope.loading = false;
                            $scope.changeInputMethod($scope.shop.product.entryMethodCustomerValue);
                            setNotification($notification.productEditPageNotification());
                        })
                        .catch(function (error) {
                            console.error("Error :!", error);
                        })

                        $scope.images = [];
                        $scope.calculations = {
                            invoiceAmount: 0,
                            storageFactor: 0,
                            margin: 0
                        };

                        // $scope.shop.product.hasStock = !$scope.shop.product.hasStock;

            } else if ($stateParams._id && $rootScope.currentShop && ( $stateParams.orderNumber || $stateParams.orderNumber == null)) {
                console.log('----------------------------------------------------------------> ELSE');;
                if ($rootScope.currentShop.nameSlug) {
                    $scope.images = [];
                    $scope.mode = 'edit';
                    $scope.getBrandsByShop($rootScope.currentShop.nameSlug, null);
                    getStockProducts();

                                // if($stateParams.status == 'edit'){
                                //     $scope.editProduct = true;
                                // }
                                // else{
                                //     $scope.editProduct = false;
                                // }
                } else {
                    $mode = 'shopNotFound';
                }
            } else {
                console.error("Sorry To Say That! Please, Contact To Prisma Admin!");
            }
        }

        function setNotification(notification) {
            console.log("Send Notification to Shop", notification);
            var newNotification = {
                shopId: $rootScope.currentShop._id,
                message: notification
            }

            $notification.createShopNotification(newNotification)
            .then(function (success) {
                console.log("Successfully send Notification", success)
            })
            .catch(function (error) {
                console.error("Error :", error);
            })

        }


        function getStockProducts() {
            console.log('---------- getStockProducts!!');
            // console.log('$stateParams._id ', $stateParams._id);
            // console.log('$rootScope.currentShop ', $rootScope.currentShop);
            $shop.getOwnShopProduct($stateParams._id, $rootScope.currentShop.nameSlug)
            .then(function (success) {
                if (success && success.shopProduct && success.shopProduct.stock) $scope.OldShopProductStock = success.shopProduct.stock;
                console.log('getStockProducts success ', JSON.parse(JSON.stringify(success)));
                // console.log('success.shopProduct ', success.shopProduct);
                var articleNumber = null;
                if(success && success.shopProduct && success.shopProduct.articleNumber){ articleNumber = success.shopProduct.articleNumber; }else{
                    if(success.shopProduct && success.shopProduct.product_id && success.shopProduct.product_id.shopProduct &&
                        success.shopProduct.product_id.shopProduct.articleNumber){ 
                            // console.log(articleNumber);
                            articleNumber = success.shopProduct.product_id.shopProduct.articleNumber;
                        }
                }
                // console.log(success);
                if (success && success.product && success.product.productFromSuggestion) {
                    console.log("Hey hi moi", success.product);
                    $scope.productFromSuggestion = true;
                    delete success.product.productFromSuggestion;
                }

                if($stateParams.orderNumber && $scope.temp ){ 
                    if(success && success.shopProduct && success.shopProduct.stock){
                        var stock = success.shopProduct.stock;
                        if($scope.temp && $scope.temp.product_id && $scope.temp.product_id.shopProduct){
                            success.shopProduct = $scope.temp.product_id.shopProduct;
                            success.shopProduct.prefillCollectionName = $scope.temp.prefillCollectionName;
                        }else{
                            success.shopProduct = $scope.temp;
                        }
                        if(stock) { success.shopProduct.stock = stock; }
                    }
                }
                $scope.product = success.product;
                $scope.productForMultiShop = JSON.parse(JSON.stringify($scope.product));

                if (success.collections && (!success.privateLabelCollections || !success.privateLabelCollections.length)) {
                    $scope.editPVC = true;
                }

                if (success.brand && success.brand.name && success.brand.isPoolArticleBrand) {
                    $scope.disabled = $scope.PNandBrandDisabled = true;
                    $('#remove_product_image').hide();
                } else if (success.brand && success.brand.name) {
                    $scope.PNandBrandDisabled = true;
                }

                if (success.brand) $scope.product.brand = success.brand;

                $scope.originalProduct = JSON.parse(JSON.stringify($scope.product));

                if ($scope.product.suggestedRetailPrice) $scope.product.suggestedRetailPrice = (typeof ($scope.product.suggestedRetailPrice) == 'string') ? Number(Number($scope.product.suggestedRetailPrice) * (($scope.product.suggestedRetailPriceVat / 100) + 1)).toFixed(2) : Number($scope.product.suggestedRetailPrice * (($scope.product.suggestedRetailPriceVat / 100) + 1)).toFixed(2);


                if ($scope.product.category && $scope.product.category === 'WATCH') {
                    $scope.productCategory = 'WATCH';
                    if ($scope.product.watch && $scope.product.watch.dial && $scope.product.watch.dial.color) $scope.dialColor = $scope.dialColors.find(function (color) {
                        return color.key == $scope.product.watch.dial.color
                    });
                        if ($scope.product.watch && $scope.product.watch.case && $scope.product.watch.case.color) $scope.caseColor = $scope.caseColors.find(function (color) {
                            return color.key == $scope.product.watch.case.color
                        });
                            if ($scope.product.watch && $scope.product.watch.strap && $scope.product.watch.strap.color) $scope.strapColor = $scope.strapColors.find(function (color) {
                                return color.key == $scope.product.watch.strap.color
                            });
                        } else if ($scope.product.category && $scope.product.category === 'STRAP') {
                            $scope.productCategory = success.product.category;

                            if ($scope.product.strap && $scope.product.strap.color) $scope.strapColor = $scope.strapColors.find(function (color) {
                                return color.key == $scope.product.strap.color
                            });

                        } else if ($scope.product.category && $scope.product.category === 'JEWEL') {
                            $scope.productCategory = success.product.category;

                            if ($scope.product.jewel && $scope.product.jewel.color) $scope.jewelColor = $scope.jewelColors.find(function (color) {
                                return color.key == $scope.product.jewel.color
                            });

                            if($scope.checkProductCategoryAndType('JEWEL','RING')){
                                $scope.ringSizeList.filter( function(size){
                                    if(parseFloat(size.value) == parseFloat($scope.product.size)){
                                        $scope.useRingsize = 'value'
                                    } else if(parseFloat(size.key) == parseFloat($scope.product.size)){
                                        $scope.useRingsize = 'key'
                                    }
                                    return size
                                })
                            }
                            if($scope.checkProductCategoryAndType('JEWEL','NECKLACE') && $scope.product.size){
                                var data = $scope.necklaceSizeList.reduce(function(prev, curr) {
                                    return (Math.abs(curr.value - parseFloat($scope.product.size)) < Math.abs(prev.value - parseFloat($scope.product.size)) ? curr : prev)
                                })
                                $scope.product.size = data.value;
                            }
                            if($scope.checkProductCategoryAndType('JEWEL','BRACELET') && $scope.product.size){
                                var data = $scope.braceletSizeList.reduce(function(prev, curr) {
                                    return (Math.abs(curr.value - parseFloat($scope.product.size)) < Math.abs(prev.value - parseFloat($scope.product.size)) ? curr : prev)
                                })
                                $scope.product.size = data.value;
                            }
                        } else {
                            $scope.productCategory = 'OTHER';
                        }
                        if (success.shopProduct) {
                            $scope.shop.product = success.shopProduct;
                            console.log('------------------------------> $scope.shop.product -----------> : ', $scope.shop.product);
                            $scope.oldStock = success.shopProduct.stock;
                            $scope.shop.product.useSupplierDetails = typeof($scope.shop.product.useSupplierDetails) != "boolean" ? true : $scope.shop.product.useSupplierDetails;
                            if($scope.shop.product.useSupplierDetails){
                                $scope.shop.product.nl = JSON.parse(JSON.stringify($scope.product.nl));
                                $scope.shop.product.en = JSON.parse(JSON.stringify($scope.product.en));
                                $scope.shop.product.de = JSON.parse(JSON.stringify($scope.product.de));
                                $scope.shop.product.fr = JSON.parse(JSON.stringify($scope.product.fr));
                                $scope.shop.product.es = JSON.parse(JSON.stringify($scope.product.es));
                            }
                            if ($scope.shop.product.purchasePrice != null && $scope.shop.product.purchasePrice != undefined) $scope.shop.product.purchasePrice = Number(parseFloat($scope.shop.product.purchasePrice).toFixed(2));
                            console.log('$scope.shop.product.purchasePrice: ', $scope.shop.product.purchasePrice, 1112);

                            if ($scope.shop.product.price > -1) {

                                $scope.shop.product.sellingPrice = $scope.shop.product.price * (($scope.shop.product.priceVat / 100) + 1);
                                $scope.shop.product.price = $scope.shop.product.price * (($scope.shop.product.priceVat / 100) + 1);
                            }

                        if ($scope.shop.product.discount > 0) {
                            if ($scope.shop.product.discountOnPercentage == true) $scope.usePercentage = true;
                            $scope.onSale = true;
                            $scope.setDiscount($scope.shop.product.discount, $scope.shop.product.discountOnPercentage);
                        } else {
                            $scope.onSale = false;
                        }

                        if(articleNumber){
                            $scope.shop.product.articleNumber = articleNumber
                        } else {
                            $scope.shop.product.articleNumber = $scope.getLatestArticalNumberForShop($rootScope.currentShop.nameSlug);
                        }

                        if(!$scope.shop.product.stock){ 
                            $scope.shop.product.stock = 0;
                        }

                    } else {
                        $scope.notInShop = true;
                        $scope.shop.product['articleNumber'] = $scope.getLatestArticalNumberForShop($rootScope.currentShop.nameSlug);
                        $scope.shop.product['ownership'] = 'possession';
                        $scope.shop.product['discount'] = 0;
                        $scope.shop.product['discountOnPercentage'] = false;
                        $scope.shop.product['show'] = false;
                        $scope.shop.product['isBestseller'] = false;
                        $scope.shop.product['isFeatured'] = false;
                        $scope.shop.product['reference'] = '-';
                        $scope.shop.product['showSuggestion'] = false;
                        $scope.shop.product['dropshippingPrice'] = 0;
                        $scope.shop.product['_id'] = $scope.product._id;
                        $scope.oldStock = 0;
                        $scope.shop.product.prefillCollectionName = $scope.temp.prefillCollectionName;
                        $scope.shop.product.prefillCollectionName = $scope.temp.prefillCollectionName;
                    }

                    console.log('--------------------------------------------------------------------------------------------------------------------------------3-----------------ABC---------------------: ', $scope.shop.product.labelDescription, $scope.localdata.labelDescription, !$scope.shop.product.labelDescription && !$scope.localdata.labelDescription);
                    if (!$scope.shop.product.labelDescription && !$scope.localdata.labelDescription) {
                        $scope.shop.product.labelDescription = success.product[$rootScope.language].name;
                    } else if ($scope.localdata.labelDescription) {
                        $scope.shop.product.labelDescription = $scope.localdata.labelDescription;
                    }

                if (success.collections) {
                    $scope.oldCollections = success.collections;
                    if (success.collections.privateLabelCollections) {
                        $scope.product.privateLabelCollections = success.collections.privateLabelCollections.filter(
                            function (data) {
                                console.log('---------------------------------------4-----------------------------------------------------------------------------------------4-----------------ABC------: ', data.uploader.shopId, $rootScope.currentShop._id, data);
                                return data.uploader.shopId == $rootScope.currentShop._id
                            }
                        );
                    }

                    if (success.collections.brandCollections) $scope.product.brandCollections = success.collections.brandCollections;

                    if (success.collections.variantsCollection) $scope.product.variantCollections = success.collections.variantsCollection;

                }

                    $scope.setTargetAudience($scope.product.male, $scope.product.female, $scope.product.kids);

                    console.log('------------------------------------------------------------------------------ check supplier!!');
                    // console.log($stateParams.orderNumber);
                    // console.log(success.supplier);
                    if(!$scope.shop.product.stock){ $scope.shop.product.stock = 0; }
                    console.log('$scope.shop.product.stock  ', $scope.shop.product.stock);
                    if ($stateParams.orderNumber) {
                        console.log('------------------------------------------------------------------------------ supplier if!!');;
                        console.log("Here Have to Check the Shop.products details", $scope.shop.product);
                        getSupllierIdFromPurchaseOrder($stateParams.orderNumber);
                    } else if (success.supplier && success.supplier._id) {
                        console.log('------------------------------------------------------------------------------ supplier else if!!');;
                        $scope.getSupplierDetaisFromCurrentShop(success.supplier._id);
                    } else {
                        console.log('------------------------------------------------------------------------------ supplier else!!');;
                        // $scope.changeInputMethod(true);
                    }

                    $scope.loading = false;
                    getShopProductSerialNumbers($rootScope.currentShop._id, $scope.product._id);

                    console.log("product handling guy Now Have to send Notification");
                    if (!($scope.product.containsFilterInfo.nl || $scope.product.containsFilterInfo.en || $scope.product.containsFilterInfo.fr || $scope.product.containsFilterInfo.es || $scope.product.containsFilterInfo.de)) setNotification($notification.productEditPageNotification());
                    getAveragePrice($rootScope.currentShop._id, $scope.product._id);

                    if($scope.temp.ean) $scope.product.variants[0].ean = $scope.temp.ean;
                })
                .catch(function (error) {
                    console.error("Got the error from getting single product", error);
                    console.log(error);
                })
}


        // function 



        $scope.setCategory = function (productCategory) {
            // When Changes in the product category value then using this.
            $scope.product.category = productCategory;

            switch (productCategory) {
                case 'WATCH':
                $scope.product.strap = ($scope.product.strap) ? $scope.product.strap : {};
                $scope.product.watch = ($scope.product.watch) ? $scope.product.watch : {};
                $scope.product.jewel = ($scope.product.jewel) ? $scope.product.jewel : {};
                if ($scope.product.watch.dial && $scope.product.watch.dial.color) $scope.dialColor = $scope.dialColors.find(function (color) {
                    return color.key == $scope.product.watch.dial.color
                });

                    if ($scope.product.watch.case && $scope.product.watch.case.color) $scope.caseColor = $scope.caseColors.find(function (color) {
                        return color.key == $scope.product.watch.case.color
                    });

                        if ($scope.product.watch.strap && $scope.product.watch.strap.color) $scope.strapColor = $scope.strapColors.find(function (color) {
                            return color.key == $scope.product.watch.strap.color
                        });
                            break;
                            case 'STRAP':
                            $scope.product.strap = ($scope.product.strap) ? $scope.product.strap : {};
                            $scope.product.watch = ($scope.product.watch) ? $scope.product.watch : {};
                            $scope.product.jewel = ($scope.product.jewel) ? $scope.product.jewel : {};
                            if ($scope.product.strap && $scope.product.strap.color) $scope.strapColor = $scope.strapColors.find(function (color) {
                                return color.key == $scope.product.strap.color
                            });

                                break;
                                case 'JEWEL':
                                $scope.product.strap = ($scope.product.strap) ? $scope.product.strap : {};
                                $scope.product.watch = ($scope.product.watch) ? $scope.product.watch : {};
                                $scope.product.jewel = ($scope.product.jewel) ? $scope.product.jewel : {};
                                if ($scope.product.jewel && $scope.product.jewel.color) $scope.jewelColor = $scope.jewelColors.find(function (color) {
                                    return color.key == $scope.product.jewel.color
                                });

                                    break;
                                    case 'OTHER':
                                    break;
                                }
                            }

                            $scope.setGender = function (targetAudience) {
                                switch (targetAudience) {
                                    case 'GENTS':
                                    $scope.product.male = true;
                                    $scope.product.female = false;
                                    $scope.product.kids = false;
                                    break;
                                    case 'LADIES':
                                    $scope.product.male = false;
                                    $scope.product.female = true;
                                    $scope.product.kids = false;
                                    break;
                                    case 'UNISEX':
                                    $scope.product.male = true;
                                    $scope.product.female = true;
                                    $scope.product.kids = false;
                                    break;
                                    case 'BOYS':
                                    $scope.product.male = true;
                                    $scope.product.female = false;
                                    $scope.product.kids = true;
                                    break;
                                    case 'GIRLS':
                                    $scope.product.male = false;
                                    $scope.product.female = true;
                                    $scope.product.kids = true;
                                    break;
                                    case 'KIDS':
                                    $scope.product.male = true;
                                    $scope.product.female = true;
                                    $scope.product.kids = true;
                                    break;
                                }
                            }

        $scope.getBrandsByShop = function (shopNameSlug, unofficialProductData) {
            // Get Shops All the Brands
            $shop.getshopbrandsForProduct(shopNameSlug)
            .then(function (response) {
                // console.log('response ', response);
                if (response.brands.length > 0) {
                    $scope.shopBrands = response.brands;
                    let = i;
                    for(i = 0; i < $scope.shopBrands.length; i++){
                        if(!$scope.product.brand || !$scope.product.brand.name || !$scope.shopBrands[i].name){
                          //do nothing
                        } else if($scope.product.brand.name == $scope.shopBrands[i].name){
                                $scope.product.brand.alias = $scope.shopBrands[i].alias;
                        }
                    };
                } else {
                    $scope.shopBrands = [];
                }

                // console.log(" Tata ::: " + JSON.stringify(unofficialProductData, null, 2));
                if ($scope.shopBrands.length && unofficialProductData &&  unofficialProductData.brand && unofficialProductData.brand._id) {
                    var index = _.findIndex($scope.shopBrands, function (o) {
                        return o._id._id.toString() == unofficialProductData.brand._id._id.toString();
                    });

                    if (index > -1) {
                        $scope.unofficialProductBrandFound = true;
                        $scope.product.brand = {
                            _id: $scope.shopBrands[index]._id._id,
                            name: $scope.shopBrands[index]._id[$rootScope.language].name,
                            nameSlug: $scope.shopBrands[index]._id[$rootScope.language].nameSlug,
                            images: $scope.shopBrands[index]._id.images,
                            startingDigits: ($scope.shopBrands[index]._id.startingDigits && $scope.shopBrands[index]._id.startingDigits != 'null' && $scope.shopBrands[index]._id.startingDigits != "undefined" && $scope.shopBrands[index]._id.startingDigits != "NULL") ? $scope.shopBrands[index]._id.startingDigits : '',
                        }



                        // console.log("unofficialProductData.brand.appliedStartingDigits", unofficialProductData.brand.appliedStartingDigits);

                        if (unofficialProductData.brand.appliedStartingDigits) {
                                // Statrting digit is already applied

                                if ($scope.product.brand.startingDigits) {
                                    // console.log("Check product brand currentStarting Digit", $scope.product.brand.startingDigits);
                                    if ($scope.product.brand.startingDigits == unofficialProductData.appliedStartingDigits) {
                                        // Keep As it is the product number
                                        // console.log("Keep As it is");
                                    } else {
                                        // Here have to remove old brand Staring digit and have to keep new in productNumber
                                        // console.log("product handling guy Here Please Do Some Work");

                                        $scope.product.variants[0].productNumber = applyCurrentBrandStartingDigitInProductNumber(unofficialProductData.insertedProductNumber, $scope.product.brand.startingDigits);
                                        $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
                                        // console.log("product handling guy Here Please Do Some Work", $scope.product.variants);

                                    }
                                } else {
                                    // console.log('calling else condition!!');
                                    // console.log("When Product was use for the create then brand have starting digis but now it's not");
                                    // Have to remove staring digit form the productNumber
                                    $scope.product.variants[0].productNumber = removeBrandStaringDigitFormProductNumber($scope.product.variants[0].productNumber, unofficialProductData.appliedStartingDigits);
                                    $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);

                                }

                            } else if ($scope.product.brand.startingDigits) {
                                // console.log("product handling guy Check this Here new staring digit of the brand");
                                // console.log("Have to add this staring digit in productNumber");
                                // $scope.product.variants[0].productNumber = applyCurrentBrandStartingDigitInProductNumber($scope.product.variants[0].productNumber, $scope.product.brand.startingDigits);
                            } else if (unofficialProductData.insertedProductNumber)  {
                                // console.log("product handling guy Check this is not");
                                $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($rootScope.currentShop.startingDigits + '-' + unofficialProductData.insertedProductNumber));
                                $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
                            }
                        } else {
                            $scope.unofficialProductBrand = unofficialProductData;
                            $scope.changeBrand();
                            $scope.unofficialProductBrandFound = false;
                            $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($rootScope.currentShop.startingDigits + '-' + unofficialProductData.insertedProductNumber));
                            $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);

                            // console.log("This Brand now not in your shop");
                            // console.log("You can't create product with this brand");
                        }
                    } else {
                        // console.log("No Brand Then Have to Work");
                        // console.log(" Ha ha ha ha ja ja ja ");
                        if(unofficialProductData){
                            $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($rootScope.currentShop.startingDigits + '-' + unofficialProductData.insertedProductNumber));
                            $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);                            
                        }


                    }

                })
.catch(function (err) {
    console.error("Error while getting products", err);
});
}

$scope.searchBrands = function (str) {
    var matches = [];
    $scope.shopBrands.forEach(function (brand) {
        if (brand._id) {
            if ((brand._id[$rootScope.language] != undefined && brand._id[$rootScope.language].name != undefined) && ((brand._id[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) || (brand._id[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0))) matches.push(brand);
        }
    });
    return matches;
}

        function isProductExistCheckByProductNumber(productNumber) {
            var body = {
                shopId: $rootScope.currentShop._id,
                currentProductId: $scope.product._id,
                productNumber: productNumber
            }
            console.log('isProductExistCheckByProductNumber -------------------- called: ', body);
            $scope.localdata.productNumberWithBrand = productNumber;
            $product.isProductExistCheckByProductNumber(body).then(function (response) {
                console.log('isProductExistCheckByProductNumber response: ', response.data.data);
                if (response && response.data && response.data.data && response.data.data.isProductExist) {
                    var product = response.data.data.product;
                    console.log('Oops, Product Number already exist: ', product);
                    $scope.localdata.isProductNumberAlredyExist = true;
                    $scope.localdata.currentProductDetailForMerge = response.data.data.currentProductDetail[0];
                } else {
                    $scope.product.variants[0].productNumber = productNumber;
                }
            }).catch(function (error) {
                console.log('isProductExistCheckByProductNumber: ', error);
            });
        }

$scope.brandSelected = function (selected) {
    console.log('------------------- brandSelected!! 1');

    if($scope.oldProduct){ $scope.product = angular.copy($scope.oldProduct); $scope.oldProduct = undefined; }
    if(!$scope.oldProduct){ $scope.oldProduct = angular.copy($scope.product); }
    console.log('angular.copy($scope.oldProduct) ', angular.copy($scope.oldProduct));
    console.log('angular.copy($scope.product) ', angular.copy($scope.product));
    //console.log(selected);
    var images = []
    //console.log("selected brand ===>", selected.originalObject); 

    if (selected.originalObject._id.images) {
        var brandImagesLength = selected.originalObject._id.images.length;
        for (var i = 0; i < brandImagesLength; i++) {
            var singleImage = {
                src: selected.originalObject._id.images[i].src,
                alt: selected.originalObject._id.images[i].alt
            }
            images.push(singleImage);
        }
    }
    if ($scope.product.brand) $scope.productOldBrand = JSON.parse(JSON.stringify($scope.product.brand));

    $scope.product.brand = {
        _id: selected.originalObject._id._id,
        name: selected.originalObject._id[$rootScope.language].name,
        nameSlug: selected.originalObject._id[$rootScope.language].nameSlug,
        images: images,
        startingDigits: (selected.originalObject._id.startingDigits && selected.originalObject._id.startingDigits != "null" && selected.originalObject._id.startingDigits != "undefined" && selected.originalObject._id.startingDigits != "NULL") ? selected.originalObject._id.startingDigits : '',
    }

    for(i = 0; i < $scope.shopBrands.length; i++){
        if(!$scope.product.brand || !$scope.product.brand.name || !$scope.shopBrands[i].name){
            // Do nothing
        }else if($scope.product.brand.name == $scope.shopBrands[i].name){
            $scope.product.brand.alias = $scope.shopBrands[i].alias;
        }
    };
    console.log('---------------------------------------------------------------------------- 2');
    console.log(JSON.parse(JSON.stringify($scope.product.variants[0])));

    var index = $scope.product.variants[0].productNumber.indexOf('-');
    console.log(JSON.parse(JSON.stringify($scope.product.variants[0], null, 2)));
    //console.log("Index of the divider", index);

    if ($scope.product.variants[0].productNumberAuto) {
        console.log(" ***** Unoffical Product :::: ", index);
        if (index == 4) {
            console.log('---------------------------------------------------------------------------- 3');
            console.log(JSON.parse(JSON.stringify($scope.product.variants[0], null, 2)));

            //if($scope.product.variants[0].productNumber.substring(0, index ) == $scope.product.variants[0].brandStratingDigits){
            var insertedProductNumberWithBrand = $scope.product.variants[0].productNumber.slice(index + 1);
            //}else{  insertedProductNumberWithBrand = $scope.product.variants[0].productNumber; }

            var index2 = insertedProductNumberWithBrand.indexOf('-');
            var index3 = insertedProductNumberWithBrand.indexOf($scope.oldProduct.variants[0].brandStratingDigits);
            console.log('index2 ', index2);
            console.log('index3 ', index3);
            if (index2 > 0 && index2 < 4) {
                if ($scope.product.brand.startingDigits){
                    var productNumber = JSON.parse(JSON.stringify($rootScope.currentShop.startingDigits + '-' + $scope.product.brand.startingDigits + '-' + insertedProductNumberWithBrand.slice(index2 + 1)));                    
                    isProductExistCheckByProductNumber(productNumber);
                    $scope.product.variants[0].brandStratingDigits = JSON.parse(JSON.stringify($scope.product.brand.startingDigits))
                }
                else{                    
                    var productNumber = JSON.parse(JSON.stringify($rootScope.currentShop.startingDigits + '-' + insertedProductNumberWithBrand.slice(index2 + 1)));
                    isProductExistCheckByProductNumber(productNumber);
                } 

                $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);

            }else if(index3 == 0){
                console.log('index3 ', index3);
                console.log('insertedProductNumberWithBrand.slice(index3) ', insertedProductNumberWithBrand.slice(index3));
                console.log('$scope.product.brand.startingDigits ', $scope.product.brand.startingDigits);
                if ($scope.product.brand.startingDigits){
                    var productNumber = JSON.parse(JSON.stringify($rootScope.currentShop.startingDigits + '-' + insertedProductNumberWithBrand.replace($scope.oldProduct.variants[0].brandStratingDigits, $scope.product.brand.startingDigits)));                    
                    isProductExistCheckByProductNumber(productNumber);
                    $scope.product.variants[0].brandStratingDigits = JSON.parse(JSON.stringify($scope.product.brand.startingDigits))
                }
                else{                    
                    var productNumber = JSON.parse(JSON.stringify($rootScope.currentShop.startingDigits + '-' + insertedProductNumberWithBrand.replace($scope.oldProduct.variants[0].brandStratingDigits, $scope.product.brand.startingDigits)));
                    isProductExistCheckByProductNumber(productNumber);
                } 

                $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
            } else {
                console.log("Hey product handling guy Check this here have issues in the brand selection portal");
                console.log("insertedProductNumberWithBrand", insertedProductNumberWithBrand);
                console.log('$scope.product.brand.startingDigits ', $scope.product.brand.startingDigits);
                if ($scope.product.brand.startingDigits){     
                    // var brandStartingDigits;
                    // console.log('$scope.product.brand.startingDigits.search('-') ', $scope.product.brand.startingDigits.search('-'));
                    // if($scope.product.brand.startingDigits.search('-') > -1){ brandStartingDigits = $scope.product.brand.startingDigits; }else{
                    //     brandStartingDigits = $scope.product.brand.startingDigits + '-';
                    // }    
                    // console.log('brandStartingDigits ', brandStartingDigits);
                    var productNumber = JSON.parse(JSON.stringify($rootScope.currentShop.startingDigits + '-' + $scope.product.brand.startingDigits + insertedProductNumberWithBrand));
                    isProductExistCheckByProductNumber(productNumber);
                    $scope.product.variants[0].brandStratingDigits = JSON.parse(JSON.stringify($scope.product.brand.startingDigits)) 
                } 
                else{
                    var productNumber = JSON.parse(JSON.stringify($rootScope.currentShop.startingDigits + '-' + insertedProductNumberWithBrand));
                    isProductExistCheckByProductNumber(productNumber);
                } 

                $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);

            }

        }
    } else {
        console.log(" ***** Offical Product :::: ");
        if (index > -1) {
            console.log('---------------------------------------------------------------------------- 3');
            console.log(JSON.parse(JSON.stringify($scope.product.variants[0], null, 2)));

            if(angular.copy($scope.product.variants[0].productNumber.substring(0, index )) == angular.copy($scope.product.variants[0].brandStratingDigits)){
                $scope.product.variants[0].insertedProductNumber = angular.copy($scope.product.variants[0].productNumber).slice(index + 1);
            }else{  $scope.product.variants[0].insertedProductNumber =angular.copy($scope.product.variants[0].productNumber); }
            if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                var productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].insertedProductNumber));
                isProductExistCheckByProductNumber(productNumber);
                $scope.product.variants[0].brandStratingDigits = JSON.parse(JSON.stringify($scope.product.brand.startingDigits))
                $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
            } else {
                console.log("No new Brands");
                var productNumber = JSON.parse(JSON.stringify(($scope.product.variants[0].productNumber || $scope.product.variants[0].insertedProductNumber)));
                isProductExistCheckByProductNumber(productNumber);
                $scope.product.variants[0].brandStratingDigits = ''
                $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
            }
        } else {
            console.log('------- if no - available in product number')
            console.log('$scope.product.variants[0] ', $scope.product.variants[0]);
            console.log('$scope.product.brand.startingDigits ', $scope.product.brand.startingDigits);

            if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                // $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].productNumber));
                var brandIndex = 1;
                var productNumber;
                if($scope.product.variants[0].productNumber){ brandIndex = $scope.product.variants[0].productNumber.search($scope.product.brand.startingDigits); }
                if(!$scope.product.variants[0].productNumber && $scope.product.variants[0].insertedProductNumber){
                    brandIndex = $scope.product.variants[0].insertedProductNumber.search($scope.product.brand.startingDigits);
                }
                if(brandIndex === 0){
                    productNumber = JSON.parse(JSON.stringify($scope.product.variants[0].productNumber || $scope.product.variants[0].insertedProductNumber));
                }else { productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + ($scope.product.variants[0].productNumber || $scope.product.variants[0].insertedProductNumber))); }
                isProductExistCheckByProductNumber(productNumber);
                $scope.product.variants[0].brandStratingDigits = JSON.parse(JSON.stringify($scope.product.brand.startingDigits))
                $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);

            } else {
                console.log('------------- else!!');
                var productNumber = JSON.parse(JSON.stringify(($scope.product.variants[0].productNumber || $scope.product.variants[0].insertedProductNumber)));
                isProductExistCheckByProductNumber(productNumber);
                $scope.product.variants[0].brandStratingDigits = ''   
                console.log("No new Brands");
            }
        }
    }
    // if($scope.product.variants[0].productNumber){
    //     $scope.product.variants[0].productNumberArray = $scope.product.variants[0].productNumber.split('-');
    // }
};

$scope.addProductImage = function () {
    console.log('--------- addProductImage!');
    console.log($scope.images);
    $scope.images.push({});
    console.log($scope.images);
}

$scope.cropProductImage = function(index){
    var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/image-crop-modal.html',
        controller: 'cropImageModalController',
        keyboard: false,
        backdrop: 'static',
        resolve: {
          defaultImage: function () {
            return true;
          },
          loadImage: function(){
            return $scope.images[index];
          },
          areaType: function () {
            return 'rectangle';
          }
        }
      })
      modalInstance.result.then(function (result) {
        if(result && result.fileName && result.imageBlob){
            var file = new File ([result.imageBlob], result.fileName , { lastModified: new Date().getTime(), type: result.imageBlob.type });
            $scope.images[index] = file;
            $scope.selectFiles(file, {}, index);
        }
      }, function () {
        // dismissed
      })
}

function isAnyImageAdded() {
    var isImageAdded = false;
    if ($scope.images && $scope.images.length) {
        for (var i = 0; i < $scope.images.length; i++) {
            if ($scope.images[i] && $scope.images[i].name) {
                isImageAdded = true;
                break;
            }
        }
    }

    return isImageAdded;
}

$scope.selectFiles = function(files, event, index) {
    // console.log('Here it is');
    // console.log('selectedFiles: ', files, event, $scope.images.length, $scope.images);
    $scope.scopeImageAdded = isAnyImageAdded();
    $scope.isAnyImageThere = false;
    if ($scope.scopeImageAdded || ($scope.product && $scope.product.images && $scope.product.images.length)) {
        $scope.isAnyImageThere = true;
    }

    if(index > -1){
        scaleDataURL($scope.images[index], 1250, 1250, function(imageURL){
            var file = new File ([imageURL], $scope.images[index].name , { lastModified: new Date().getTime(), type: imageURL.type });
            $scope.images[index] = file;
        });
    }
    fnproductIsValidForUpdate();
}

function scaleDataURL(dataURL, maxWidth, maxHeight, callback){
    var reader = new FileReader()
    reader.onload = function (evt) {
        var img = new Image();
        img.onload = function(){
            var ratio, scale, newWidth, newHeight, canvas, ctx, dx, dy;
            if(img.width < maxWidth){
                scale = maxWidth / img.width;
            }else{
                scale = maxHeight / img.height;
            }
            newWidth = img.width * scale;
            newHeight = img.height * scale;
            canvas = document.createElement('canvas');
            canvas.height = maxWidth;
            canvas.width = maxHeight;
            if(newWidth > maxWidth){
                ratio = maxWidth / newWidth;
                newHeight = newHeight * ratio;
                newWidth = newWidth * ratio;
            }

            if(newHeight > maxHeight){
                ratio = maxHeight / newHeight;
                newHeight = newHeight * ratio;
                newWidth = newWidth * ratio;
            }
            ctx = canvas.getContext('2d');
            dx = (maxWidth - newWidth) / 2;
            dy = (maxHeight - newHeight) / 2;
            ctx.fillStyle = 'white';
            ctx.fillRect(0,0,canvas.width, canvas.height);
            ctx.drawImage(img, 0, 0, img.width, img.height, dx, dy, newWidth, newHeight);
            canvas.toBlob(function (blob) {
                callback(blob)
            }, dataURL.type, 0.7);
        };
        img.src = evt.target.result
    }
    reader.readAsDataURL(dataURL)
}

$scope.removeProductImage = function (mode, index) {
    console.log('its calling inside removeProductImage');
    console.log($scope.images);
    console.log($scope.product.images);
    console.log(mode);
    if (mode == 'scope') $scope.images.splice(index, 1);
    else $scope.product.images.splice(index, 1);
    $scope.selectFiles();
}
$scope.addNewDiamondForm = function () {
    $scope.editDiamond = false;
    $scope.addDiamond = true;
    if ($scope.product.diamonds != undefined) $scope.diamondIndex = $scope.product.diamonds.length;
    $scope.diamond = {};
}

$scope.pushDiamond = function () {
    if ($scope.product.diamonds == undefined) $scope.product.diamonds = [];
    $scope.product.diamonds.push($scope.diamond);
    $scope.diamond = {};
    $scope.addDiamond = false;
}

$scope.cancelNewDiamond = function () {
    $scope.editDiamond = false;
    $scope.addDiamond = false;
    $scope.diamond = {};
}

$scope.deleteDiamond = function (index) {
    $scope.product.diamonds.splice(index, 1);
}

$scope.openDiamond = function (index) {
    $scope.diamond = $scope.product.diamonds[index];
    $scope.diamondIndex = index;
    $scope.addDiamond = false;
    $scope.editDiamond = true;
}

$scope.updateDiamond = function (index) {
    $scope.product.diamonds[index] = $scope.diamond;
    $scope.editDiamond = false;
}

$scope.saveProductInDatabase = function () {
    console.log(" --------- Saving Product ---------", $scope.product);
    $rootScope.creatingItem = true;
    var variant = $scope.product.variants[0];
    $scope.product.variants = [];
    $scope.product.variants.push(variant);

    if (!$scope.product.male) $scope.product.male = false;

    var newCollections = {
        privateLable: $scope.product.privateLabelCollections,
        brand: $scope.product.brandCollections,
        variant: $scope.product.variantCollections
    }

    $scope.product.suggestedRetailPriceVat = $scope.shop.product.priceVat;

    if ($scope.product.suggestedRetailPrice) $scope.product.suggestedRetailPrice = $scope.product.suggestedRetailPrice / (($scope.shop.product.priceVat / 100) + 1);

    delete $scope.product.privateLabelCollections;
    delete $scope.product.brandCollections;
    delete $scope.product.variantCollections;

    var readyProduct = $scope.product;

    if ($scope.unofficialProduct) {
        readyProduct.variants[0].shopStratingDigits = $rootScope.currentShop.startingDigits;
        if (readyProduct.brand && readyProduct.brand.startingDigits) {
            readyProduct.variants[0].brandStratingDigits = readyProduct.brand.startingDigits;
            readyProduct.variants[0].insertedProductNumber = readyProduct.variants[0].productNumber.slice(8, readyProduct.variants[0].productNumber.length - 1);
            readyProduct.variants[0].productNumberAuto = true;
        } else {
            readyProduct.variants[0].insertedProductNumber = readyProduct.variants[0].productNumber.slice(5, readyProduct.variants[0].productNumber.length - 1);
            readyProduct.variants[0].productNumberAuto = true;
        }
    }

    var data = {
        product: readyProduct,
        newCollections: newCollections,
        user: $rootScope.user,
        purchaseOrderNumber: $stateParams.orderNumber,
        itemId: $stateParams.itemId,
        shopId: $rootScope.currentShop._id
    }

    if (data.product && data.product.brand) {
        if (data.product.brand.alias == "") {
            delete data.product.brand.alias
        }
    }

    if (data.product && data.product.variants && data.product.variants[0]) {
        if (data.product.variants[0].ean == "") {
            delete data.product.variants[0].ean
        }
    }

    console.log("Data", data);

    $product.addNewProductInMainDatabase(data)
        .then(function (result) {
            console.log('------------------------------- addNewProductInMainDatabase result!!');
            console.log(result);
            console.log('------- result!!')
            var productId = result.data._id;

            if (result.data.containsFilterInfo) {
                console.log('------- first if!!')
                if (result.data.containsFilterInfo.nl) $scope.shop.product.show = true;
                if (result.data.containsFilterInfo.de) $scope.shop.product.show = true;
                if (result.data.containsFilterInfo.es) $scope.shop.product.show = true;
                if (result.data.containsFilterInfo.fr) $scope.shop.product.show = true;
                if (result.data.containsFilterInfo.en) $scope.shop.product.show = true;
            }

            console.log('$scope.shop.product.hasStock  ', $scope.shop.product.hasStock);
            $scope.shop.product.hasStock = ($scope.shop.product.hasStock) ? false : true;
            console.log('$scope.shop.product.hasStock  ', $scope.shop.product.hasStock);
            $scope.product.hasStock = $scope.shop.product.hasStock;
            var tempCollections = [];
            var shopCollections = [];
            if (newCollections.privateLable) {
                console.log('------- second if!!')
                for (var i = 0; i < newCollections.privateLable.length; i++) {
                    tempCollections.push({
                        '_id': newCollections.privateLable[i]._id
                    });
                    shopCollections.push({
                        '_id': newCollections.privateLable[i]._id,
                        'showOnWebsite': true
                    });
                }
            }

            if (newCollections.brand) {
                console.log('------- third if!!')
                for (var i = 0; i < newCollections.brand.length; i++) {
                    tempCollections.push({
                        '_id': newCollections.brand[i]._id
                    });
                    shopCollections.push({
                        '_id': newCollections.brand[i]._id
                    });
                }
            }

            if (newCollections.variant) {
                console.log('------- fourth if!!')
                for (var i = 0; i < newCollections.variant.length; i++) {
                    tempCollections.push({
                        '_id': newCollections.variant[i]._id
                    });
                    shopCollections.push({
                        '_id': newCollections.variant[i]._id
                    });
                }
            }

            if ($scope.images.length > 0 && $scope.images[0].name) {
                console.log('------- fifth if!!', $scope.temp);
                $scope.upload = true;


                Upload.upload({
                    url: 'api/product-image-upload',
                    data: {
                        productId: productId,
                        files: $scope.images, //file input field
                        shopId: $rootScope.currentShop._id
                    }
                })
                    .then(function (res) {
                        $scope.shop.product._id = productId;
                        var data = { prodShopId: $scope.temp._id, prodId: productId, index: $scope.temp.index, create: true };
                        console.log('data.itemId', res.data, res.data.files);
                        // data.index = $scope.temp.index;
                        console.log('$scope.shop.product ', $scope.shop.product);

                        if (res && res.data && res.data.files && res.data.files.containsFilterInfo) {
                            var containsInfo = res.data.files.containsFilterInfo;
                            console.log('------- first if!!: ', containsInfo);
                            
                            if (containsInfo.nl) $scope.shop.product.show = true;
                            if (containsInfo.de) $scope.shop.product.show = true;
                            if (containsInfo.es) $scope.shop.product.show = true;
                            if (containsInfo.fr) $scope.shop.product.show = true;
                            if (containsInfo.en) $scope.shop.product.show = true;
                        }
                        console.log('------------------------------------------------------------$scope.purchaseOrderNumber------------------------ addProductInCurrentShop!!: ', $scope.purchaseOrderNumber);
                        $shop.addProductInCurrentShop($rootScope.currentShop.nameSlug, $scope.shop.product, shopCollections, $scope.purchaseOrderNumber)
                            .then(function (savedShopProduct) {
                                if ($scope.temp && $scope.temp.isFromPurchaseOrder) $rootScope.$emit('callPurchaseOrder', data); // We should not call this API always but only when coming from the purchase order
                                $uibModalInstance.close();
                                $scope.alert = {
                                    type: "success",
                                    msg: "Uw gegevens zijn verzonden!"
                                }
                            })
                            .catch(function (saveShopErr) {
                                console.log('Upload image saveShopErr: ', saveShopErr);
                                $scope.alert = {
                                    type: "danger",
                                    msg: "upload error: " + saveShopErr
                                }
                            })
                    })
                    .catch(function (err) {
                        console.log('Upload image error: ', err);
                        $scope.alert = {
                            type: "danger",
                            msg: "upload error: " + err
                        }
                    })
            } else {
                $scope.shop.product._id = productId;
                var data = { prodShopId: productId, prodId: $scope.temp._id, index: $scope.temp.index, create: true };
                console.log('------- first if else!!: ', data);
                $shop.addProductInCurrentShop($rootScope.currentShop.nameSlug, $scope.shop.product, shopCollections, $scope.purchaseOrderNumber)
                    .then(function (savedShopProduct) {
                        if ($scope.temp && $scope.temp.isFromPurchaseOrder) $rootScope.$emit('callPurchaseOrder', data); // We should not call this API always but only when coming from the purchase order
                        console.log('savedShopProduct  ', savedShopProduct);
                        // console.log('data.itemId', data.itemId);
                        // $state.go('retailer.purchase-order', {
                        //     'orderNo': $stateParams.orderNumber
                        // });
                        $uibModalInstance.close();
                        $scope.alert = {
                            type: "success",
                            msg: "Uw gegevens zijn verzonden!"
                        }
                    })
                    .catch(function (saveShopErr) {
                        $scope.alert = {
                            type: "danger",
                            msg: "upload error: " + saveShopErr
                        }
                        console.error("Error : in saveing shop producut details");
                        console.log(saveShopErr);
                    })
            }
        })
        .catch(function (reason) {
            console.error(" --------------------------------------> ", reason);
            $scope.localdata.showerror = true;
            $scope.localdata.errormessage = reason;
            setTimeout(function(){ $scope.localdata.showerror = false; }, 10000);
            $scope.alert = {
                type: "danger",
                msg: "add product error: " + reason
            }
        })
}

$scope.inStock = false;
$scope.onSale = false;

$scope.stockStatusChange = function (inStock) {
    console.debug('------------ stockStatusChange!');
            // Stock Management
            console.log("$scope.product", $scope.shop.product);
            if (!$scope.shop.product) {
                $scope.shop.product = {};
            }

            if (inStock == false) $scope.shop.product.stock = 0;
            else $scope.shop.product.stock = 1;
        }

        $scope.setDiscount = function (discount, usePercentage) {
            // Set Discount 
            $scope.shop.product.discountOnPercentage = usePercentage;
            $scope.shop.product.discount = discount;

            if (usePercentage === true) {
                $scope.calculatedDiscount = ($scope.shop.product.sellingPrice * $scope.shop.product.discount) / 100;
            } else {
                $scope.calculatedDiscount = $scope.shop.product.discount;
            }
        }

        $scope.onSaleChange = function (change) {
            // onSale Changes
            $scope.onSale = change;
            if(change === false) {
                $scope.shop.product.discount = null
                $scope.shop.product.discountOnPercentage = false
            }
        };


        // Calucation tabs

        $scope.changeInputMethod = function (method) {
            console.log("Method", method, $scope.shop.product.purchasePrice);

            if ($scope.shop.product) {
                $scope.shop.product.sellingPrice = $scope.shop.product.sellingPrice || 0;
                if (typeof ($scope.shop.product.sellingPrice) == 'string') Number($scope.shop.product.sellingPrice);
                $scope.shop.product.price = ($scope.shop.product.sellingPrice / (($scope.shop.product.priceVat / 100) + 1));
                if ($scope.shop.product.sellingPrice > 0.1) $scope.shop.product.sellingPrice = Number($scope.shop.product.sellingPrice.toFixed(2));
                if ($scope.shop.product.purchasePrice && $scope.shop.product.purchasePrice > 0.1) $scope.shop.product.purchasePrice = Number($scope.shop.product.purchasePrice.toFixed(2));
                console.log('$scope.shop.product.purchasePrice: ', $scope.shop.product.purchasePrice, 1113);
            }

            if (!$scope.calculations) $scope.calculations = {};

            console.log(JSON.parse(JSON.stringify($scope.shop.product)));

            if (!method) {
                console.log("------- Purchase Method ---------");
                // var storageFactor = ($scope.shop.product.price / $scope.shop.product.purchasePrice) || 0;
                // storageFactor = Number(storageFactor.toFixed(2));
                // var storageFactor = Number($scope.temp.storageFactor.toFixed(3));
                //-console.log('storageFactor ', storageFactor);
                //-console.log("$scope.calculations['storageFactor']", $scope.calculations['storageFactor']);
                //-console.log('$scope.temp  ', $scope.temp);
                var storageFactor;
                if($scope.temp && $scope.temp.storageFactor){ 
                    if (typeof $scope.temp.storageFactor === 'string') $scope.temp.storageFactor = parseFloat($scope.temp.storageFactor.replace(',', '.'));
                    storageFactor = parseFloat(parseFloat($scope.temp.storageFactor).toFixed(3));
                } else storageFactor = Number(($scope.shop.product.price / $scope.shop.product.purchasePrice).toFixed(3)) || 0;
                if (!$scope.$$phase) {
                    //-console.log('---------- if!!');
                    $scope.$apply(function () {
                        //-console.log('$scope.calculations.storageFactor ', $scope.calculations.storageFactor);
                        $scope.calculations.storageFactor = storageFactor;
                    })
                } else {
                    //-console.log('---------- else!!');
                    //-console.log('$scope.calculations.storageFactor ', $scope.calculations.storageFactor);
                    $scope.calculations.storageFactor = storageFactor;
                }

            } else {
                console.log("------- Selling Method ---------");
                //-console.log("$scope.shop.product", $scope.shop.product);
                //-console.log("$scope.shop.product.price", $scope.shop.product.price);
                //-console.log("$scope.shop.product.purchasePrice", $scope.shop.product.purchasePrice);
                //-console.log('$scope.temp  ', $scope.temp);
                // var margin = $scope.shop.product.sellingPrice / $scope.shop.product.purchasePrice;
                // margin = Number(margin.toFixed(2));

                if($scope.temp && $scope.temp.discount && $scope.temp.discount.value) {
                    if (typeof $scope.temp.discount.value === 'string') $scope.temp.discount.value = parseFloat($scope.temp.discount.value.replace(',', '.'));
                    margin = parseFloat(parseFloat($scope.temp.discount.value).toFixed(3));
                }else margin = Number(($scope.shop.product.sellingPrice / $scope.shop.product.purchasePrice).toFixed(3));
                // var margin = Number($scope.temp.discount.value.toFixed(3));
                if (!$scope.$$phase) {
                    $scope.$apply(function () {
                        $scope.calculations.margin = margin;
                        $scope.shop.product.productpurchasePrice = Number($scope.shop.product.purchasePrice.toFixed(2));
                    });
                } else {
                    $scope.calculations.margin = margin;
                    $scope.shop.product.purchasePrice = Number($scope.shop.product.purchasePrice.toFixed(2));
                    console.log('$scope.shop.product.purchasePrice: ', $scope.shop.product.purchasePrice, 1114);
                }

            }
        }

        $scope.calculatePurchasePriceFromMargin = function (margin) {
            console.log("Margin 1", margin);
            if ($scope.open) $scope.open = false;
            if (typeof ($scope.shop.product.sellingPrice) == 'string') $scope.shop.product.sellingPrice = Number($scope.shop.product.sellingPrice);
            $scope.shop.product.price = Number($scope.shop.product.sellingPrice / (($scope.shop.product.priceVat / 100) + 1));
            console.log("($scope.shop.product.sellingPrice / margin).toFixed(2)", ($scope.shop.product.sellingPrice / margin).toFixed(2));
        }

        $scope.calculateSellingPriceFromMargin = function (margin) {
            console.log("Margin 2", margin);
            if ($scope.open) $scope.open = false;
            $scope.shop.product.sellingPrice = Number(($scope.shop.product.purchasePrice * margin).toFixed(2));
            $scope.shop.product.price = Number(($scope.shop.product.sellingPrice / (($scope.shop.product.priceVat / 100) + 1)));
        }

        $scope.calculateSellingPriceFromStorageFactor = function (factor) {
            if ($scope.open) $scope.open = false;

            if (!$scope.calculations) {
                $scope.calculations = {};
            }

            console.log("Purchase Price =====>", $scope.shop.product.purchasePrice);
            console.log("Price ====>", $scope.shop.product.price);

            $scope.shop.product.price = Number($scope.shop.product.purchasePrice * factor);
            $scope.shop.product.sellingPrice = Number($scope.shop.product.price * (($scope.shop.product.priceVat / 100) + 1)).toFixed(2);

            console.log("Price2 ====>", $scope.shop.product.price);

        }

        $scope.calculatePurchasePriceFromStorageFactor = function (factor) {
            if ($scope.open) $scope.open = false;

            if (!$scope.calculations) {
                $scope.calculations = {};
            }

            $scope.shop.product.purchasePrice = Number($scope.shop.product.price / factor).toFixed(2);
            console.log('$scope.shop.product.purchasePrice: ', $scope.shop.product.purchasePrice, 1115);

        }

        $scope.changeForBg = function () {
            document.getElementById('stockSetting').style.border = "medium solid #ffd457";
        }

        $scope.calculateStorageFactorOrMargin = function (type) {
            if ($scope.open) $scope.open = false;
            if (!$scope.calculations) $scope.calculations = {};

            $scope.shop.product.sellingPrice = $scope.shop.product.sellingPrice;
            $scope.shop.product.purchasePrice = $scope.shop.product.purchasePrice;
            console.log('$scope.shop.product.purchasePrice: ', $scope.shop.product.purchasePrice, 1116);
            $scope.shop.product.price = Number($scope.shop.product.sellingPrice / (($scope.shop.product.priceVat / 100) + 1));

            if (type == 'storage') {
                console.log("Method Storage Factor");
                var storageFactor = Number($scope.shop.product.price / $scope.shop.product.purchasePrice);
                $scope.calculations['storageFactor'] = Number(storageFactor).toFixed(2);

                if (!$scope.$$phase) {
                    $scope.$apply(function () {
                        $scope.calculations['storageFactor'] = Number(storageFactor).toFixed(2);
                    });
                } else {
                    $scope.calculations['storageFactor'] = Number(storageFactor.toFixed(2));
                }
            } else {
                console.log("Method Margin");
                console.log("$scope.shop.product.sellingPrice", $scope.shop.product.sellingPrice);
                console.log("$scope.shop.product.purchasePrice", $scope.shop.product.purchasePrice);

                var margin = $scope.shop.product.sellingPrice / $scope.shop.product.purchasePrice;
                console.log("margin", margin);
                $scope.calculations.margin = Number(margin.toFixed(2));
                if (!$scope.$$phase) {
                    $scope.$apply(function () {
                        $scope.calculations.margin = Number(margin.toFixed(2));
                    });
                } else {
                    $scope.calculations.margin = Number(margin.toFixed(2));
                }
            }
        }

        // Using for the suppliers
        $scope.getShopCompanies = function () {
            $scope.shopCompanies = [];
            if ($rootScope.currentShop) {
                $shop.getOwnShopCompanies($rootScope.currentShop._id)
                .then(function (response) {
                    $scope.shopCompanies = response;
                })
                .catch(function (reason) {
                    console.error(reason);
                    if(reason.status === 401) {
                        $scope.editSup = false
                    }
                })
            }
        }

        $scope.searchCompany = function (str) {
            var matches = [];
            $scope.shopCompanies.forEach(function (item) {
                if ((item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) || (item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
                    matches.push(item);
                }
            });
            return matches;
        }

        $scope.supplierSelected = function (selected) {
            $scope.shop.product.supplier = {
                _id: selected.originalObject._id,
                name: selected.title
            }
        }

        $scope.searchCompanyForMultiShop = function (str) {
            var matches = [];
            $scope.selectedMultishop.companies.forEach(function (item) {
                if ((item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) || (item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
                    matches.push(item);
                }
            });
            return matches;
        }

        $scope.supplierSelectedForMultiShop = function (selected) {
            $scope.selectedMultishopSupplier = {
                _id: selected.originalObject._id,
                name: selected.title,
                nameSlug: selected.originalObject.nameSlug,
            }
            console.log('supplierSelectedForMultiShop: ', $scope.selectedMultishopSupplier);
            $scope.multishoperror = false;
        }

        $scope.removeSupplier = function () {
            $scope.shop.product.supplier = null;
        }

        $scope.removeSupplierForMultiShop = function () {
            $scope.selectedMultishopSupplier = undefined;
            $scope.checkMultiShopCond();
        }

        $scope.eanIsExistsOrNot = function (ean) {
            console.log('--------- ean!', ean);
            // Enterd EAN number is valid or not
            if (ean.length) {
                if (!$purchaseOrder.isLikeEANNumber(ean)) {
                    $scope.eanValid = false;
                    return;
                }
                $product.eanIsExistsOrNot(ean, $scope.product._id, $rootScope.currentShop._id).then(function (result) {
                    if (result.status == true) $scope.eanValid = true;
                    else $scope.eanValid = false;
                }).catch(function (error) {
                    console.error("Error =>>> ", error);
                })
            } else {
                $scope.eanValid = true;
            }
        }

        $scope.validateEAN = function (evt) {
            var theEvent = evt || window.event;
            // console.log('theEvent.type: ', theEvent.type);
            // Handle paste
            if (theEvent.type === 'paste') {
                key = event.clipboardData.getData('text/plain');
            } else {
                // Handle key press
                var key = theEvent.keyCode || theEvent.which;
                key = String.fromCharCode(key);
            }
            var regex = /[0-9]|\./;
            if (!regex.test(key)) {
                theEvent.returnValue = false;
                if (theEvent.preventDefault) theEvent.preventDefault();
            }
        }

        $scope.productNumberIsExistsOrNot = function (productNumber) {
            console.log('-------------------------- productNumberIsExistsOrNot');
            console.log('--------------------------------------------------------------------------------------------------------------------', productNumber, $scope.product);
            $scope.PNvalid = false;
            $scope.PNsort = true;

            if (productNumber) {
                //console.log('inside first if');
                productNumber = $productConfig.retrunValidProductNumber(productNumber);
                console.log(productNumber);
                //console.log($scope.product.variants[0]);
           
                if($scope.product.variants[0].productNumberAuto){
                    if (productNumber && productNumber.length > 2) {
                        $scope.PNsort = false;
                        $product.productNumberIsExistsOrNot(productNumber, $scope.product._id)
                        .then(function (result) {
                            console.log('productNumberIsExistsOrNot product');
                            console.log(result);
                            if (result.status == true) {
                                $scope.PNvalid = true;
                                $scope.product.variants[0].productNumber = productNumber;
                            } else {
                                $scope.product.variants[0].productNumber = productNumber;
                            }
                        })
                        .catch(function (error) {
                            console.error("Error =>>> ", error);
                        })
                    }
                }
                else{
                    if($scope.product.brand){
                        if($scope.product.variants[0].brandStratingDigits){
                            console.log('$scope.product.variants[0].brandStratingDigits');
                            console.log($scope.product.variants[0].brandStratingDigits);
                            var index = productNumber.indexOf($scope.product.variants[0].brandStratingDigits)
                            console.log('---------------- printing index. ${index}');
                            console.log(index);
                            if(index > -1){
                                var temp = productNumber.slice(index + $scope.product.variants[0].brandStratingDigits.length + 1, productNumber.length + 1);
                                if(temp && temp.length > 2){
                                    $product.productNumberIsExistsOrNot(productNumber, $scope.product._id)
                                    .then(function (result) {
                                        console.log('productNumberIsExistsOrNot product');
                                        console.log(result);
                                        if (result.status == true) {
                                            $scope.PNvalid = true;
                                            $scope.product.variants[0].productNumber = productNumber;
                                        } else {
                                            $scope.product.variants[0].productNumber = productNumber;
                                        }
                                    })
                                    .catch(function (error) {
                                        console.error("Error =>>> ", error);
                                    })
                                }
                                else{
                                    console.log("Product Numvber short ")
                                }
                            }
                            else{
                                $scope.PNvalid = true;
                                console.log(" No brand staring digits ")
                            }
                        }
                        else{
                            $scope.PNvalid = true;
                            console.log(" No Staring ")
                        }
                    }
                    else{
                        console.log(" No Brand in officale product ")
                        if (productNumber && productNumber.length > 2) {
                            $scope.PNsort = false;
                            $product.productNumberIsExistsOrNot(productNumber, $scope.product._id)
                            .then(function (result) {
                                console.log("Response From Server", result);
                                if (result.status == true) {
                                    $scope.PNvalid = true;
                                    $scope.product.variants[0].productNumber = productNumber;

                                } else {
                                    $scope.product.variants[0].productNumber = productNumber;
                                }
                            })
                            .catch(function (error) {
                                console.error("Error =>>> ", error);
                            })
                        }
                    }
                }
            }
        }

        $scope.getTaxForShop = function (currentShopId) {

            $shop.getTaxForShop(currentShopId)
            .then(function (tax) {
                if (tax && tax.taxes && tax.taxes.rates) $scope.taxes = tax.taxes.rates;
            })
            .catch(function (errorInGettingTax) {
                console.error("Error In Getting Tax For Shop", errorInGettingTax);
            })
        }



        $scope.setTargetAudience = function (male, female, kids) {
            if (male) {
                if (female) {
                    if (kids) $scope.targetAudience = 'KIDS';
                    else $scope.targetAudience = 'UNISEX';
                } else {
                    if (kids) $scope.targetAudience = 'BOYS';
                    else $scope.targetAudience = 'GENTS';
                }
            } else {
                if (female) {
                    if (kids) $scope.targetAudience = 'GIRLS';
                    else $scope.targetAudience = 'LADIES';
                }
            }

            $scope.setGender($scope.targetAudience);
        }

        $scope.sendDeleteSuggestionToAdmin = function () {

            if ($scope.product && $scope.product.brand && $scope.product.brand.nameSlug) {
                var brand = $rootScope.currentShop.brands.find(function (brand) {
                    return brand.nameSlug == $scope.product.brand.nameSlug
                }) || null;

                if (brand && brand.restricted) {
                    prompt({
                        title: $language.translate('DELETE_PRODUCT') + '?',
                        message: $language.translate('YOU_ARE_OFFICIAL_DEALER_PRODUCT_STAYS_IN_ASSORTMENT')
                    }).then(function () {
                        // $scope.stockProducts[index].shopProduct.stock = 0;
                        // $scope.stockProducts[index].shopProduct.show = false;
                        $shop.updateShopProductStockAndShow($scope.product._id, $rootScope.currentShop._id)
                        .then(function (success) {
                            // goToWay($stateParams.orderNumber);
                        })
                        .catch(function (error) {
                            console.error("Error in", error);
                        })
                    }, function () {
                        reject("Cancel");
                    })
                } else {
                    prompt({
                        title: $language.translate('DELETE_PRODUCT') + '?',
                        message: $language.translate('ARE_YOU_SURE_TO_DELETE_THIS_PRODUCT'),
                        buttons: [{
                            label: $language.translate('DELETE_PRODUCT'),
                            primary: true,
                            id: 0
                        },
                        {
                            label: $language.translate('STOP_SELLING_STOCK_TO_0'),
                            primary: true,
                            id: 1
                        },
                        {
                            label: $language.translate('CANCEL'),
                            primary: false,
                            cancel: true,
                            id: 2
                        }
                        ]
                    }).then(function (value) {
                        if (value && value.id == 0) {
                            $shop.deleteProductFromShop($scope.product._id, $rootScope.currentShop._id)
                            .then(function (result) {
                                // goToWay($stateParams.orderNumber);
                            })
                            .catch(function (error) {
                                console.error(" Error In delete product from the shop", error);
                            })
                        } else if (value && value.id == 1) {
                            $shop.updateShopProductStockAndShow($scope.product._id, $rootScope.currentShop._id)
                            .then(function (success) {
                                // goToWay($stateParams.orderNumber);
                            })
                            .catch(function (error) {
                                console.error("Error in", error);
                            })
                        } else {
                            console.log("I don't delete this product");
                        }
                    })
                    .catch(function (error) {
                        console.log("Error ====> Delete Product", error);
                    });
                }

            } else {

                prompt({
                    title: $language.translate('DELETE_PRODUCT') + '?',
                    message: $language.translate('ARE_YOU_SURE_TO_DELETE_THIS_PRODUCT'),
                    buttons: [{
                        label: $language.translate('DELETE_PRODUCT'),
                        primary: true,
                        id: 0
                    },
                    {
                        label: $language.translate('STOP_SELLING_STOCK_TO_0'),
                        primary: true,
                        id: 1
                    },
                    {
                        label: $language.translate('CANCEL'),
                        primary: false,
                        cancel: true,
                        id: 2
                    }
                    ]
                }).then(function (value) {
                    if (value && value.id == 0) {
                        $shop.deleteProductFromShop($scope.product._id, $rootScope.currentShop._id)
                        .then(function (result) {
                            // goToWay($stateParams.orderNumber);
                        })
                        .catch(function (error) {
                            console.error(" Error In delete product from the shop", error);
                        })
                    } else if (value && value.id == 1) {
                        $shop.updateShopProductStockAndShow($scope.product._id, $rootScope.currentShop._id)
                        .then(function (success) {
                            // goToWay($stateParams.orderNumber);
                        })
                        .catch(function (error) {
                            console.error("Error in updateShopProductStockAndShow", error);
                        })
                    } else {
                        console.log("I don't delete this product");
                    }
                })
                .catch(function (error) {
                    console.error(error);
                });
            }
        }

        function makeLableProper(labelDescription, privateLabelCollectionsName, productNumber) {
            var str3 = productNumber;
            var str2 = privateLabelCollectionsName;
            var str1 = labelDescription;

            var str2Low = str2.toLowerCase();
            var str3Low = str3.toLowerCase();
            str1 = str1.replace(new RegExp(str2, 'g'), '').replace(new RegExp(str3, 'g'), '').replace(new RegExp(str2Low, 'g'), '').replace(new RegExp(str3Low, 'g'), '');
            str1 = str1;
            var str1Low = str1.toLowerCase();

            console.log("Str 1", str1);
            // console.log(" Str1 Main String", str2Low);
            console.log("sub strign", str1Low);

            var matchesIndex = str1Low.indexOf(str2Low);
            var matchesIndex2 = str1Low.indexOf(str3Low);

            if (matchesIndex > -1 || matchesIndex2 > -1) {
                if (matchesIndex > -1) {
                    var length = str2.length;
                    var lastIndex = matchesIndex + length;
                    var subStringToRemove = str1.slice(matchesIndex, lastIndex);
                    var all = new RegExp(subStringToRemove, 'g');
                    str1 = str1.replace(all, '').replace(/  +/g, ' ').trim();
                }

                if (matchesIndex2 > -1) {
                    var length2 = str3.length;
                    var lastIndex2 = matchesIndex2 + length2;
                    var subStringToRemove2 = str1.slice(matchesIndex2, lastIndex2);
                    var all = new RegExp(subStringToRemove2, 'g');
                    str1 = str1.replace(all, '').replace(/  +/g, ' ').trim();
                }

                return str1;
            } else {
                return str1;
            }
        }

        $scope.updateProduct = function (addProductForm, isFromMultiShop) {
            if (!isFromMultiShop && $scope.multiShopsDetails && $scope.multiShopsDetails.length && $scope.OldShopProductStock >= 0) $scope.shop.product.stock = $scope.OldShopProductStock; // User changed using the multishop but then click regularl save button
            console.log('oldSeletectedMultishopStock: ', $scope.OldShopProductStock);
            // if (isFromMultiShop) return;
            $rootScope.creatingItem = true;
            console.log('------------   updateProduct!!');
            // console.log(addProductForm);
            // console.log($scope.product.suggestedRetailPrice);

            var modifieByUser = false;
            // console.log("$scope.dataStatus", $scope.dataStatus);
            if ($scope.dataStatus == 'bad' && $scope.dataStatus != 'alright' && $scope.dataStatus != 'good' && $scope.dataStatus != 'perfect') {
                $scope.shop.product.show = false
            } else {
                if (addProductForm && addProductForm.show && !addProductForm.show.$pristine) {
                    console.log("Modified", $scope.shop.product.show);
                    console.log("User wants that we have to store");
                    modifieByUser = true;
                } else {
                    if (!$scope.shop.product.show) $rootScope.openRetalierShowModal($stateParams._id);
                }
            }

            // Update Existing Product With new Data
            console.log('--------------------------------------------------------------------------------------------------------------------------------4-----------------ABC---------------------: ');
            if ($scope.product.privateLabelCollections && $scope.product.privateLabelCollections[0] && !$scope.localdata.labelDescription) {
                $scope.shop.product.labelDescription = makeLableProper($scope.shop.product.labelDescription, $scope.product.privateLabelCollections[0][$scope.language].name, $scope.product.variants[0].productNumber);
            } else if ($scope.localdata.labelDescription && !$scope.shop.product.labelDescription) {
                $scope.shop.product.labelDescription = $scope.localdata.labelDescription;
            }

            if ($scope.product.suggestedRetailPriceVat == undefined) $scope.product.suggestedRetailPriceVat = $scope.shop.product.priceVat;

            //var currency = "-$4,400.50";
            // console.log('-----------------------------------------------------------');
            // console.log(JSON.stringify($scope.product.suggestedRetailPrice, null, 2));
            if($scope.product.suggestedRetailPrice){
                $scope.product.suggestedRetailPrice = $scope.product.suggestedRetailPrice.toString().replace(',', '.');
                // console.log(JSON.stringify($scope.product.suggestedRetailPrice, null, 2));
                $scope.product.suggestedRetailPrice = $scope.product.suggestedRetailPrice / ($scope.product.suggestedRetailPriceVat / 100 + 1);
                // console.log(JSON.stringify($scope.product.suggestedRetailPrice, null, 2));
            } else {
                $scope.product.suggestedRetailPrice = undefined;
            }
            var newCollections = {
                privateLable: $scope.product.privateLabelCollections,
                brand: $scope.product.brandCollections,
                variant: $scope.product.variantCollections,
                clientGroup: $scope.product.clientGroupCollections
            }

            delete $scope.product.privateLabelCollections;
            delete $scope.product.brandCollections;
            delete $scope.product.variantCollections;
            delete $scope.product.clientGroupCollections;

            var suggester = {
                id: $rootScope.user._id,
                name: $rootScope.user.firstName + ' ' + $rootScope.user.lastName + ' ' + $rootScope.currentShop.name,
                usertype: $rootScope.user.role,
                currentShopId: $rootScope.currentShop._id
            }

            var tempShop = JSON.parse(JSON.stringify($scope.shop.product));

            if (tempShop.entryMethodCustomerValue == null || tempShop.entryMethodCustomerValue == undefined) {
                var minus = (tempShop.priceVat / 100) + 1;
                tempShop.price = tempShop.sellingPrice / minus;
            }
            // console.log('----------------------------------- tempShop!');
            // console.log(tempShop);
            // console.log('----------------------------------- $scope.product!');
            // console.log($scope.product);
            // console.log('----------------------------------- scope.originalProduct!');
            // console.log($scope.originalProduct);

            console.log('$scope.oldCollections: ', $scope.oldCollections);
            console.log('newCollections: ', newCollections);
            console.log('$scope.OldShopProductStock: ', $scope.OldShopProductStock);
            console.log('$scope.shop.product.stock: ', $scope.shop.product.stock);
            if ($scope.OldShopProductStock != $scope.shop.product.stock) {
                console.log($scope.shop.product.stock.purchasePrice, $scope.shop.product.purchasePrice);
                var reason = "Update from edit product page";
                if (isFromMultiShop) {
                    reason = "Transfer: Update from edit product page";
                } else if ($stateParams.orderNumber) {
                    reason = "Purchase order: Update from edit product page";
                }
                $shop.updateShopProductStock($rootScope.currentShop._id, $scope.product._id, $scope.OldShopProductStock, $scope.shop.product.stock, reason, true, $scope.purchaseOrderNumber, $scope.shop.product.purchasePrice).then(function (respnose) {
                    console.log('Updated the stock');
                    updateTheProduct();
                }).catch(function (error) {
                    console.log('something went wrong while updating stock correction: ', error);
                });
            } else {
                updateTheProduct();
            }

            function updateTheProduct() {
                $shop.updateProduct(suggester, $scope.product, $scope.originalProduct, tempShop, $scope.oldCollections, newCollections, $rootScope.currentShop.nameSlug, $scope.purchaseOrderId, $scope.generatedArticalNumber, 'edit-product', $rootScope.currentShop._id, modifieByUser, $scope.purchaseOrderItemId)
                    .then(function (response) {
                        var data = { prodShopId: tempShop._id, prodId: $scope.product._id, index: $scope.temp.index, create: false };
                        if ($scope.temp && $scope.temp.isFromPurchaseOrder) $rootScope.$emit('callPurchaseOrder', data); // We should not call this API always but only when coming from the purchase order
                        var data2 = { shopProduct: tempShop, product: $scope.product }
                        console.log('catchOnAssortment ', data2);
                        data2.shopProduct.hasStock = !data2.shopProduct.hasStock;
                        $rootScope.$emit('catchOnAssortment', data2)
                        $scope.istransferShopDataLoading = false;
                        if (response.data.suggestionId) {
                            console.log('-------- response.data.suggestionId!');
                            console.log($scope.images);
                            if ($scope.images.length > 0 && $scope.images[0].name) {
                                Upload.upload({
                                    url: 'api/product-image-upload',
                                    data: {
                                        productId: response.data.suggestionId,
                                        files: $scope.images, //file input field
                                        isUpdate: true,
                                        shopId: $rootScope.currentShop._id
                                    }
                                })
                                    .then(function (res) {
                                        console.log('$stateParams.orderNumber 1');
                                        console.log($stateParams.orderNumber);
                                        console.log(res);
                                        // goToWay($stateParams.orderNumber);
                                    })
                                    .catch(function (error) {
                                        console.error("Error In Update Images", error)
                                    })
                            } else {
                                console.log('-------- response.data.suggestionId! else!');
                                console.log('$stateParams.orderNumber 2');
                                console.log($stateParams.orderNumber);
                                // goToWay($stateParams.orderNumber);
                            }
                        } else {
                            console.log('-------- esle response.data.suggestionId!');
                            console.log('$scope.images ', $scope.images);
                            if ($scope.images.length > 0 && $scope.images[0].name) {
                                $scope.upload = true;
                                Upload.upload({
                                    url: 'api/product-image-upload',
                                    data: {
                                        productId: $scope.product._id,
                                        files: $scope.images, //file input field
                                        shopId: $rootScope.currentShop._id
                                    }
                                })
                                    .then(function (res) {
                                        console.log('$stateParams.orderNumber 3');
                                        console.log($stateParams.orderNumber);
                                        console.log(res);
                                        // goToWay($stateParams.orderNumber);

                                    })
                                    .catch(function (error) {
                                        console.error("Error In Update Images", error)
                                    })
                            } else {
                                // goToWay($stateParams.orderNumber);
                            }
                        }

                        $scope.cancelItem();
                    })
                    .catch(function (reason) {
                        $scope.istransferShopDataLoading = false;
                        console.error("Error! : ", reason);
                    })

            }
        }

        // function goToWay(orderNumber) {
        //     console.log('------ inside goToWay!');
        //     if (orderNumber != 'null') {
        //         $state.go('retailer.purchase-order', {
        //             'orderNo': orderNumber
        //         });
        //     } else {
        //         $rootScope.productId = $scope.product._id;
        //         $state.go('retailer.assortment');
        //     }
        // }

        $scope.setCaseColor = function (color) {
            if (!$scope.product.watch.case) $scope.product.watch['case'] = {};

            $scope.product.watch.case['color'] = color.key;
        }
        $scope.setDialColor = function (color) {
            if (!$scope.product.watch.dial) $scope.product.watch['dial'] = {};

            $scope.product.watch.dial['color'] = color.key;
        }

        $scope.setJewelColor = function (color) {
            $scope.product.jewel['color'] = color.key;
        }

        $scope.cancelItem = function (){
            console.log('Hello cancel it: ', $scope.shop.product.stock);
            $uibModalInstance.close({ isFromBackButton: true, stock: $scope.shop.product.stock });
            console.log('------------------------- cancelItem!!');
            $rootScope.$emit('cancelItem');
        }


        function getSupllierIdFromPurchaseOrder(orderNumber) {
            console.log('--------------------------------------------- getSupllierIdFromPurchaseOrder')
            console.log('$scope.received  ', $scope.received);
            console.log('$scope.product._id  ',$scope.product._id);
            $purchaseOrder.getPurchaseOrderDetailsFormOrderNumber(orderNumber, $scope.product._id, localItemId)
            .then(function (success) {
                console.log("Purchase Order Details ====> ", success);
                var product = success.data[0].product;
                $scope.purchaseOrderVat = success.data[0].product.priceVat;
                $scope.purchaseOrderPrice = success.data[0].product.price;
                $scope.purchaseOrderPurchasePrice = success.data[0].product.purchasePrice;
                $scope.purchaseOrderEntryMethod = success.data[0].product.entryMethodCustomerValue;
                $scope.purchaseOrderItemId = success.data[0].product._id;

                console.log("Shop Product details Check's it's Now from the purchase Order", $scope.shop.product);

                if ($scope.shop && $scope.shop.product) {

                    if ($scope.shop.product.priceVat == undefined && $scope.shop.product.priceVat == null) {
                        $scope.shop.product['priceVat'] = success.data[0].product.priceVat;
                        if (!$scope.shop.product.purchasePrice || !$scope.shop.product.sellingPrice) {
                            $scope.displaySwitch = true;
                        }
                    }

                    if ($scope.shop.product.purchasePrice == undefined || $scope.shop.product.purchasePrice == null) $scope.shop.product['purchasePrice'] = (typeof ($scope.purchaseOrderPurchasePrice) == 'string') ? Number(Number($scope.purchaseOrderPurchasePrice).toFixed(2)) : Number($scope.purchaseOrderPurchasePrice.toFixed(2));
                    console.log('$scope.shop.product.purchasePrice: ', $scope.shop.product.purchasePrice, 1117);
                    if ($scope.shop.product.price == undefined || $scope.shop.product.price == null) {
                        $scope.shop.product['price'] = (typeof ($scope.purchaseOrderPrice) == 'string') ? Number($scope.purchaseOrderPrice) : Number($scope.purchaseOrderPrice);
                        $scope.shop.product['sellingPrice'] = Number(($scope.shop.product.price * (($scope.shop.product.priceVat / 100) + 1)).toFixed(2));
                    }

                    if ($scope.shop.product.entryMethodCustomerValue == undefined && $scope.shop.product.entryMethodCustomerValue == null)
                        $scope.shop.product.entryMethodCustomerValue = $scope.purchaseOrderEntryMethod;
                    console.log('calling changeInputMethod!!');
                    $scope.changeInputMethod($scope.shop.product.entryMethodCustomerValue);

                    if ($scope.shop.product.price > -1 && $scope.shop.product.purchasePrice > -1 && ($scope.shop.product.entryMethodCustomerValue != undefined && $scope.shop.product.entryMethodCustomerValue != null)) $scope.displaySwitch = false;
                }

                console.log("Apply the purchaseOrder in shop Products", $scope.shop.product);

                if ($scope.shop && $scope.shop.product && !$scope.product.purchaseOrders) {
                    $scope.shop.product['purchaseOrders'] = [];
                    $scope.shop.product.purchaseOrders.push({
                        '_id': success.data[0]._id
                    });
                }

                if (product.quantity == product.receivedQuantity) $scope.isQuantityAndRecievedQntSame = true;
                $scope.purchaseOrderId = success.data[0]._id;
                $scope.getSupplierDetaisFromCurrentShop(success.data[0].company._id);
                    // else $scope.shop.product["supplier"] = $scope.getSupplierDetaisFromCurrentShop(success.data[0].company._id);


                console.log("Fine", success.data);
                if (success.data[0].received) {
                    console.log("Received");
                    $scope.receivedApply = false;
                    console.log('success.data[0].received');
                    console.log(success.data[0].received);
                    $scope.received = success.data[0].received;
                    if (!$scope.shop.product.stock) $scope.shop.product.stock = 0;

                    if ($scope.received.length) {
                        console.log("Stock: ", $scope.received);
                        console.log('$scope.shop.product.stock ', $scope.shop.product.stock, shopProductStock);
                        var shopProductStock = $scope.shop.product.stock;
                        for (var i = 0; i < $scope.received.length; i++) {
                            console.log('----------------------------------------------------------------------------------------------------------------------------------------$scope.received[i].receivedQuantity ', i, $scope.received[i].isApplied, $scope.received[i].receivedQuantity);
                            // if (!$scope.received[i].isApplied) continue; // If stock not applied in the purchaseOrderHistories then we are not going to increated here
                            // we will increased only if stock already
                            shopProductStock += $scope.received[i].receivedQuantity;
                            $scope.receivedApply = true;
                            console.log(i, 'inside $scope.shop.product.stock ', $scope.shop.product.stock, shopProductStock);
                        }
                        console.log('STOCKED ------------------------> ', shopProductStock, $scope.shop.product.stock);
                        $scope.shop.product.stock = shopProductStock;
                        if (($scope.shop.product.stock < 0) || !$scope.shop.product.stock) $scope.shop.product.stock = 0;
                    }
                    console.log('$scope.shop.product.stock called: ', $scope.shop.product.stock);
                }
            })
            .catch(function (error) {
                console.error("Error In Finding Purchase Order Details", error);
            })
}

$scope.getTheCollectionName = function (collection) {
    var collectionName = "";
    if (collection) {

        if (collection.hasOwnProperty($rootScope.language) && Boolean(collection[$rootScope.language].name)) {
            collectionName = collection[$rootScope.language].name;
        } else if (collection.hasOwnProperty("en.name") && Boolean(collection.en.name)) {
            collectionName = collection.en.name;
        } else if (collection.hasOwnProperty("nl.name") && Boolean(collection.nl.name)) {
            collectionName = collection.nl.name;
        } else if (collection.hasOwnProperty("de.name") && Boolean(collection.de.name)) {
            collectionName = collection.de.name;
        } else if (collection.hasOwnProperty("fr.name") && Boolean(collection.fr.name)) {
            collectionName = collection.fr.name;
        } else if (collection.hasOwnProperty("name") && Boolean(collection.name)) {
            collectionName = collection.name; 
        } else {
            collectionName = "NO NAME";
        }
        
        return collectionName;
    }

}

$scope.setStrapColor = function (color) {
    console.log("color", color);
    if (color) {
        if ($scope.product.category == 'WATCH') {
            if (!$scope.product.watch.strap) $scope.product.watch.strap = {
                'color': color.key
            };
            $scope.product.watch.strap.color = color.key;
            console.log("Check Here the color of the Strap", $scope.product.watch.strap.color);
        } else if ($scope.product.category == 'STRAP') {
            if (!$scope.product.strap) $scope.product.strap = {
                'color': color.key
            };
            $scope.product.strap.color = color.key;
        }
    }
}

        function getAveragePrice(shopId, productId) {
            $shop.getShopProductStockRecord(shopId, productId)
                .then(function (success) {
                    var data = success.purchaseOrder;
                    var tempPP = 0;
                    var quant = 0;
                    for (var i = 0; i < data.length; i++) {
                        for (var j = 0; j < data[i].orders.length; j++) {
                            var order = data[i].orders[j];
                            tempPP = tempPP + (order.purchasePrice * order.quantity);
                            quant = quant + order.quantity;
                        }
                    }
                    if (!tempPP) tempPP = 0;
                    // console.log('$scope.shop.product.purchasePrice: ', $scope.shop.product.purchasePrice, tempPP, quant, tempPP / quant, 2113);
                    if (quant && !$scope.shop.product.purchasePrice) $scope.shop.product.purchasePrice = tempPP / quant;
                })
                .catch(function (error) {
                    console.error('error ===>', error)
                })
        }

            $scope.openStockModal = function (shopId, productId) {
            // console.log("Product", product)
            console.log("Hey product handling guy CHeck this");
            return $q(function (resolve, reject) {
                var data = {
                    'shopId': shopId,
                    'productId': productId,
                    'purchasePrice': $scope.shop.product.purchasePrice
                };
                var modalInstance = $uibModal.open({
                    templateUrl: '../views/modal/retailer-shop-product-stock-modal.html',
                    controller: 'retailerShopProductStockController',
                    size: 'lg',
                    resolve: {
                        product: function () {
                            return data;
                        }
                    }
                });

                modalInstance.result
                .then(function (response) {
                    console.log("Response After Close Modal", response);
                    if (response && response.entryMethodCustomerValue) {
                        console.log("In True Response $scope.shop.product.entryMethodCustomerValue ", $scope.shop.product.entryMethodCustomerValue);
                        $scope.shop.product.entryMethodCustomerValue = true;
                            // $scope.changeInputMethod($scope.shop.product.entryMethodCustomerValue);
                            $scope.shop.product.price = response.priceOfPurchaseOrder;
                            $scope.shop.product.priceVat = response.priceVat;
                            $scope.shop.product.sellingPrice = ($scope.shop.product.price * (($scope.shop.product.priceVat / 100) + 1));
                            $scope.shop.product.purchasePrice = response.purchasePrice;
                            console.log('$scope.shop.product.purchasePrice: ', $scope.shop.product.purchasePrice, 1119);
                            $scope.calculateStorageFactorOrMargin($scope.shop.product.price, 'margin')

                        } else if (response && response.entryMethodCustomerValue != null && response.entryMethodCustomerValue != undefined && response.entryMethodCustomerValue == false) {
                            console.log("In false Response $scope.shop.product.entryMethodCustomerValue ", $scope.shop.product.entryMethodCustomerValue);
                            $scope.shop.product.entryMethodCustomerValue = false;
                            $scope.shop.product.purchasePrice = response.purchasePrice;
                            console.log('$scope.shop.product.purchasePrice: ', $scope.shop.product.purchasePrice, 2111);
                            $scope.shop.product.price = response.priceOfPurchaseOrder;
                            $scope.shop.product.priceVat = response.priceVat;
                            $scope.shop.product.sellingPrice = ($scope.shop.product.price * (($scope.shop.product.priceVat / 100) + 1));
                            $scope.calculateStorageFactorOrMargin($scope.shop.product.price, 'storage')

                            // $scope.changeInputMethod($scope.shop.product.entryMethodCustomerValue);
                        } else {
                            $uibModalInstance.close();
                            console.log("Last");
                        }
                    })
                .catch(function (reason) {
                    console.log("Here", reason);
                    reject(reason);
                });
            });
        }

        $scope.openStockChangesModal = function (shopId, productId, status) {
            // console.log("Product", product)
            console.log("Hey product handling guy CHeck this");
            return $q(function (resolve, reject) {
                var data = {
                    'shopId': shopId,
                    'productId': productId,
                    'status': status
                };
                if ($scope.oldStock) data['oldStock'] = $scope.oldStock = $scope.shop.product.stock;

                var modalInstance = $uibModal.open({
                    templateUrl: '../views/modal/retailer-product-stock-change-modal.html',
                    controller: 'retailerProductStockChangeModalController',
                    size: 'lg',
                    resolve: {
                        data: function () {
                            return data;
                        }
                    }
                });

                modalInstance.result
                .then(function (response) {
                    console.log("Response", response);
                        // if(response) $scope.oldStock = response;
                        // $scope.shop.product. = response;
                        if (response != undefined && response != null) $state.reload();
                        resolve(response);
                    })
                .catch(function (reason) {
                    console.log("Here", reason);
                    reject(reason);
                });
            });
        }

        $scope.getProductStockChanges = function (shopId, productId) {
            // Get Product Stock Changes Records

            $shop.getShopProductStockChanges(shopId, productId)
            .then(function (stocks) {
                    // Product Stock Changes Records assign to productStockChanges 
                    $scope.productStockChanges = stocks;
                    console.log("productStockChanges", $scope.productStockChanges);
                })
            .catch(function (error) {
                console.log("Error =>> getProductStockChanges", error);
            })

        }

        $rootScope.$on('myEvent', function(event, data) {
            console.log('----------------------------------- myEvent!! ');
            console.log('data.newStock ', data.newStock);
            $scope.OldShopProductStock = $scope.shop.product.stock = data.newStock;
        })

        function changeImageIndex(currentIndex, newIndex) {
            var image1 = $scope.product.images[currentIndex];
            var image2 = $scope.product.images[newIndex];
            $scope.product.images[currentIndex] = image2;
            $scope.product.images[newIndex] = image1;
            console.log(image1);
        }


        $scope.previousImage = function (currentIndex) {
            changeImageIndex(currentIndex, (currentIndex - 1));
        }

        $scope.nextImage = function (currentIndex) {
            changeImageIndex(currentIndex, (currentIndex + 1));
        }

        $scope.changeMode = function () {
            $scope.editProduct = ($scope.editProduct == true) ? false : true;
            $rootScope.user.canViewProductDetails = ($rootScope.user.canViewProductDetails == true) ? true : false;


            console.log("$rootScope.user.canViewProductDetails", $rootScope.user.canViewProductDetails);
            var data = {
                _id: $rootScope.user._id,
                canViewProductDetails: JSON.parse(JSON.stringify($rootScope.user.canViewProductDetails))
            }

            $user.updateViewProductDetails(data)
            .then(function (success) {
                console.log("Success", success);
            })
            .catch(function (error) {
                console.error("Error" + JSON.stringify(error));
            })

        }

        $scope.changePVC = function () {
            console.log('---------------------- changePVC!!');
            $scope.editPVC = ($scope.editPVC == true) ? false : true;
            console.log('$scope.editPVC ', $scope.editPVC);

            if ($scope.editPVC) {
                $timeout(function () {
                    var searchInput = document.getElementById('collection-auto-complete-2_value');
                    console.log('searchInput ', searchInput);
                    console.log('searchInput.focus() ', searchInput.focus());
                    searchInput.focus();
                }, 0);
            }

        }

        $scope.changeSupp = function () {
            $scope.editSup = ($scope.editSup == true) ? false : true;
            if ($scope.editSup) {
                $timeout(function () {
                    var searchInput = document.getElementById('supplier_value');
                    searchInput.focus();
                }, 0);

            }
        }

        $scope.changeSuppForMultiShop = function () {
            $scope.editSupForMultiShop = ($scope.editSupForMultiShop == true) ? false : true;
            if ($scope.editSupForMultiShop) {
                $timeout(function () {
                    var searchInput = document.getElementById('supplier_value');
                    searchInput.focus();
                }, 0);

            }
        }

        $scope.changeBrand = function () {
            $scope.editBrand = ($scope.editBrand == true) ? false : true;
            if ($scope.editBrand) {
                $timeout(function () {
                    var searchInput = document.getElementById('ex1_value');
                    searchInput.focus();
                }, 0);
            }

        }

        function fnproductIsValidForUpdate() {
            $productConfig.productIsValidForUpdate($scope.product, $scope.canUpdate, $scope.images).then(function (canUpdate) {

                $scope.product['containsFilterInfo'] = JSON.parse(JSON.stringify(canUpdate));

                if (canUpdate && (canUpdate.EN || canUpdate.NL || canUpdate.ES || canUpdate.DE || canUpdate.FR)) {
                    $scope.dataStatus = 'alright';
                    getColorForIndication($scope.product);
                } else {
                    $scope.dataStatus = 'bad';
                }
            }).catch(function (error) {
                $scope.canUpdate = {
                    'EN': false,
                    'DE': false,
                    'NL': false,
                    'FR': false,
                    'ES': false
                };
                console.error("Error: Please Contact PrismaNote Admin");
                console.log(error);
            })
        }

        $scope.$watch('product', function (newVal, oldVal) {
            //console.log('------------------------------------ watch product!!');
            fnproductIsValidForUpdate();
        }, true);

        function getColorForIndication(product) {
                // console.log('printing product informamtion!!');
                // console.log(JSON.parse(JSON.stringify(product)));
            if (product && product.category) {
                switch (product.category) {
                    case 'WATCH':
                    // console.log("Category is WATCH", product.watch);
                    $scope.dataStatus = checkAllTheWatchField(product.watch, product);
                    break;
                    case 'JEWEL':
                    // console.log("Category is JEWEL", product.jewel);
                    $scope.dataStatus = checkJewelDetails(product.jewel, product);
                    break;
                    case 'STRAP':
                    $scope.dataStatus = checkStrapDetails(product.strap, product);
                    // console.log("Category is STRAP ", product.strap);
                    break;
                    case 'OHER':
                    $scope.dataStatus = 'alright';
                    break;
                }
            } else {
                return 'alright';
            }
        }

        function checkAllTheWatchField(watch, product) {
            // console.log("Hey For the Watch #######################################################");
            // console.log(JSON.parse(JSON.stringify(product)));
            if (watch['type'] &&
                watch['indication'] != (null && undefined) &&
                watch['hasSwissMovement'] != (null && undefined) &&
                watch['hasDateFunction'] != (null && undefined) &&
                watch['waterproofLevel'] != (null && undefined) &&
                watch['isNickelFree'] != (null && undefined) &&
                watch['isAntiAllergy'] != (null && undefined) &&
                watch['hasLightFunction'] != (null && undefined) &&
                watch['isSmartwatch'] != (null && undefined) &&
                watch['smartWatchFunctions'] && watch['smartWatchFunctions'].length &&
                watch['case'] &&
                watch.case['shape'] &&
                watch.case['size'] &&
                watch.case['depth'] &&
                watch.case['material'] &&
                watch.case['glassMaterial'] &&
                watch.case['color'] &&
                watch.case['designItem'] && watch.case['designItem'].length &&
                watch['dial'] &&
                watch.dial['color'] &&
                watch.dial['pattern'] &&
                watch.dial['print'] &&
                watch.dial['index'] &&
                watch['strap'] &&
                watch.strap['model'] &&
                watch.strap['width'] &&
                watch.strap['material'] &&
                watch.strap['color'] &&
                watch.strap['print'] &&
                watch.strap['studs'] &&
                watch.strap['clasp'] &&
                watch.strap['wristPermimeter'] &&
                product && product.en && product.en.shortDescription && product.en.shortDescription.length &&
                product.nl && product.nl.shortDescription && product.nl.shortDescription.length &&
                product.de && product.de.shortDescription && product.de.shortDescription.length &&
                product.fr && product.fr.shortDescription && product.fr.shortDescription.length
                ) {
                console.log("basic watch Details perfect");
                return 'perfect';
            // } else if (!product.en.shortDescription.length || !product.nl.shortDescription.length ||  !product.de.shortDescription.length || !product.fr.shortDescription.length) {
            //     console.log("basic watch Details bad jknvjkdfsnjvnd");
            //     return 'bad';
            } else {
                console.log("WATCH good");
                return 'good';
            }
        }

        function checkStrapDetails(strap, product) {

            if (strap &&
                strap['model'] &&
                strap['width'] &&
                strap['material'] &&
                strap['color'] &&
                strap['print'] &&
                strap['studs'] &&
                strap['clasp'] &&
                strap['wristPermimeter'] &&
                product && product.en && product.en.shortDescription && product.en.shortDescription.length &&
                product.nl && product.nl.shortDescription && product.nl.shortDescription.length &&
                product.de && product.de.shortDescription && product.de.shortDescription.length &&
                product.fr && product.fr.shortDescription && product.fr.shortDescription.length
                ) {
                console.log("basic strap Details perfect");
            return 'perfect';
        // } else if (!product.en.shortDescription.length || !product.nl.shortDescription.length || !product.de.shortDescription.length || !product.fr.shortDescription.length) {
        //     console.log("basic strap Details perfect jknvjkdfsnjvnd");
        //     return 'bad';
        } else {
            console.log("Starp good");
            return 'good';
        }


    }

    function checkJewelDetails(jewel, product) {

        if (jewel &&
            jewel['material'] &&
            jewel['color'] &&
            jewel['type'] &&
            jewel['height'] &&
            jewel['width'] &&
            jewel['depth'] &&
            jewel['diameter'] &&
            jewel['weight'] &&
            jewel['chain'] &&
            jewel['clasp'] &&
            jewel['shape'] &&
            jewel['gloss'] &&
            product && product.en && product.en.shortDescription && product.en.shortDescription.length &&
            product.nl && product.nl.shortDescription && product.nl.shortDescription.length &&
            product.de && product.de.shortDescription && product.de.shortDescription.length &&
            product.fr && product.fr.shortDescription && product.fr.shortDescription.length
            ) {
            console.log("basic jewel Details perfect");
        return 'perfect';
    // } else if (!product.en.shortDescription.length || !product.nl.shortDescription.length || !product.de.shortDescription.length || !product.fr.shortDescription.length) {
    //     console.log("basic jewel Details perfect jknvjkdfsnjvnd");
    //     return 'bad';
    } else {
        console.log("jewel good");
        return 'good';
    }
}


$scope.openPrintLabelModal = function () {
    var product = $scope.product;
    var loadProduct = true;
    var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/print-label-modal.html',
        controller: 'printLabelModalController',
        resolve: {
            loadProduct: function () {
                return true
            },
            product: function () {
                return product;
            },
            labelDescription:  function () {
                return $scope.shop.product.labelDescription
            },
            empty: function () {
                return false
            }
        }
    });
            //we will get the value here
            modalInstance.result.then(function (result) {
                if (result) {
                    //ready
                    $scope.product.labelDescription = result.labelDescription ? result.labelDescription : '';
                }
            }, function () {
                //dismissed
            })

        }

        $scope.openExOPEN = function () {
            $scope.exOpen = ($scope.exOpen) ? false : true;
        }

        $scope.checkProductCategoryAndType = function (category, type) {
            if($scope.product && $scope.product.jewel && $scope.product.jewel.type){
                if($scope.product.category == category && type == 'RING'){
                    var ringTypes= ["RING", "SEAL_RING", "COMBINATION_RING", "RING_WITH_PEARL","RING_WITH_GEM"];
                    return ringTypes.includes($scope.product.jewel.type);
                } else if($scope.product.category == category && type == 'NECKLACE'){
                    var necklaceTypes= ["CHOKER", "NECKLACE", "TENNIS_NECKLACE"];
                    return necklaceTypes.includes($scope.product.jewel.type);
                } else if($scope.product.category == category && type == 'BRACELET'){
                    var braceletTypes= ["BRACELET", "TENNIS_BRACELET", "SLAVE_BRACELET"];
                    return braceletTypes.includes($scope.product.jewel.type);
                } else if(type == 'all' && $scope.product.category == category){
                    return true;
                }
            }
            return false;
        }

        function range(start, end, step) {
            var len = Math.floor((end - start) / step) + 1;
            return Array(len).fill().map(function(a, idx){ return ({key: start + (idx * step), value: (start + (idx * step)) * 10});});
        }
        
        $scope.setProductDetails = function (value) {
            if(value){
                $scope.shop.product.nl = JSON.parse(JSON.stringify($scope.product.nl));
                $scope.shop.product.en = JSON.parse(JSON.stringify($scope.product.en));
                $scope.shop.product.de = JSON.parse(JSON.stringify($scope.product.de));
                $scope.shop.product.fr = JSON.parse(JSON.stringify($scope.product.fr));
                $scope.shop.product.es = JSON.parse(JSON.stringify($scope.product.es));
            }
        }

        $(document).ready(function () {
            $("#product-short-description-en").resizable();
        });


        function removeBrandStaringDigitFormProductNumber(productNumber, startingDigitsOfBrand) {
            console.group(" ***** removeBrandStaringDigitFormProductNumber ***** ");
            console.log("productNumber", productNumber);
            console.log("Here it was applied :::::: ", startingDigitsOfBrand)
            // productNumber.splice(2, );
            var shopStartingDigits = productNumber.slice(0, 4);
            var divider = productNumber.slice(4, 5);
            if (divider == '-') {
                var brandStartingDigits = productNumber.slice(5, 7);
                var divider2 = productNumber.slice(7, 8);

                if (divider2 == '-') {
                    console.log("inserted productNumber have brand staring digit");
                }
            } else {
                console.log("Here in productNumber don't have shop number")
            }

            console.groupEnd();
            var insertedProductNumber = (divider2) ? productNumber.slice(8, productNumber.length - 1) : productNumber.slice(5, productNumber.length - 1);
            return insertedProductNumber;
        }


        function applyCurrentBrandStartingDigitInProductNumber(productNumber, startingDigitsOfBrand) {
            productNumber = (startingDigitsOfBrand) ? $rootScope.currentShop.startingDigits + '-' + startingDigitsOfBrand + '-' + productNumber : $rootScope.currentShop.startingDigits + '-' + productNumber;
            return productNumber;
        }

        $scope.openProductModalForPairing = function () {

            console.log('ADD NEW PRODUCT FROM PURHASE ORDER: ', $scope.product);
            console.log('openProductModalForPairing: called: ', $scope.localdata.currentProductDetailForMerge, $stateParams.orderNumber);
            var product = $scope.localdata.currentProductDetailForMerge;
            
            var isComingFromPurchaseOrder = {
                productNumber: $scope.localdata.productNumberWithBrand,
                isNeedsToCreate: $scope.product && $scope.product._id ? false : true,
                chosenProductId: $scope.product._id,
                isShopProduct: $scope.localdata.currentProductDetailForMerge && $scope.localdata.currentProductDetailForMerge.isShopProduct ? true : false,
                purchaseOrderNumber: $stateParams.orderNumber,
                itemId: localItemId,
                index: item && item.extraData && item.extraData.index >= 0 ? item.extraData.index : undefined
            }
            console.log('isComingFromPurchaseOrder: ', isComingFromPurchaseOrder);
            // When Product is NOT shopProduct or Product is not even exist then we will open this Modal
            if (isComingFromPurchaseOrder.isNeedsToCreate || (!isComingFromPurchaseOrder.isShopProduct && !isComingFromPurchaseOrder.isNeedsToCreate)) {
                console.log('Open the SearchModal Only', $scope.product);
                return $q(function (resolve, reject) {
                    var modalInstance = $uibModal.open({
                        templateUrl: '../views/modal/retailer-create-product-merge-search-modal.html',
                        controller: 'retailerCreateProductMergeSearchModal',
                        size: 'lg',
                        resolve: {
                            product: function () {
                                var product = $scope.product
                                product.isComingFromPurchaseOrder = isComingFromPurchaseOrder
                                return product
                            }
                        }
                    })

                    modalInstance.result.then(function (response) {
                        console.log('MODAL CLOSED: 123: ', $scope.stockProducts);
                        $uibModalInstance.dismiss('cancel')
                        resolve(response);
                    }).catch(function (reason) {
                        console.log('--------------------REAOSON: ', reason);
                        $uibModalInstance.dismiss('cancel')
                        reject(reason)
                    })
                })
            } else {
                // Opening when product is shopProduct
                console.log('Existing One');
                return $q(function (resolve, reject) {
                    var modalInstance = $uibModal.open({
                        templateUrl: '../views/modal/retailer-product-modal-new.html',
                        controller: 'retailerProductModalNewController',
                        size: 'lg',
                        resolve: {
                            product: function () {
                                product.shopProduct.shippingCosts = $rootScope.currentShop.shippingCosts
                                product.shopProduct.sameProducts = $scope.my_duplicate_product
                                product.isComingFromPurchaseOrder = isComingFromPurchaseOrder
                                return product
                            }
                        }
                    })

                    modalInstance.result.then(function (response) {
                        console.log('MODAL CLOSED: 123: ', $scope.stockProducts);
                        resolve(response)
                    }).catch(function (reason) {
                        console.log('--------------------REAOSON: ', reason);
                        reject(reason)
                    })
                })
            }
        }

        $scope.checkMultiShopCond = function () {
            console.log('$scope.selectedMultishop', $scope.selectedMultishop)
            console.log('$scope.selectedMultishop.product._id', $scope.selectedMultishop.product._id)
            console.log('$scope.product.privateLabelCollections', $scope.product.privateLabelCollections)
            console.log('$scope.productForMultiShop.privateLabelCollections', $scope.productForMultiShop.privateLabelCollections)
            var selectedPrivateLableColl = $scope.selectedMultishop && $scope.selectedMultishop.product && $scope.selectedMultishop.product._id ? $scope.product.privateLabelCollections : $scope.productForMultiShop.privateLabelCollections;
            console.log('$scope.oldSeletectedMultishopStock', $scope.oldSeletectedMultishopStock)
            console.log('$scope.seletectedMultishopStock', $scope.seletectedMultishopStock)
            console.log('$scope.OldShopProductStock', $scope.OldShopProductStock)
            console.log('$scope.shop.product.stock', $scope.shop.product.stock)
            if (($scope.oldSeletectedMultishopStock == $scope.seletectedMultishopStock) || ($scope.OldShopProductStock == $scope.shop.product.stock)) {
                $scope.multishoperror = true;
                $scope.multishopmessage = $language.translate('SELECT_STOCK_TO_TRANSFER');
                return;
            } else if ($scope.istransferShopDataLoading || !$scope.selectedMultishopSupplier || ($scope.product.brand._id && !$scope.isBrandExist) || !selectedPrivateLableColl || !selectedPrivateLableColl.length || !selectedPrivateLableColl[0]._id) {
                $scope.multishoperror = true;
                console.log('checkMultiShopCond fill all details 2795')
                console.log('$scope.istransferShopDataLoading', $scope.istransferShopDataLoading)
                console.log('!$scope.selectedMultishopSupplier', !$scope.selectedMultishopSupplier)
                console.log('$scope.product.brand._id', $scope.product.brand._id)
                console.log('AND CONDITION: duct.brand._i', $scope.product.brand._id)
                console.log('AND CONDITION:!$scope.isBrandExist', !$scope.isBrandExist)
                console.log('!selectedPrivateLableColl', !selectedPrivateLableColl)
                console.log('!selectedPrivateLableColl.length', !selectedPrivateLableColl.length)
                console.log('!selectedPrivateLableColl[0]._id', !selectedPrivateLableColl[0]._id)
                $scope.multishopmessage = $language.translate('PLEASE_FILL_ALL_DETAILS');
                return;
            }
            doSelectedDefaultValufOfMultiShop();
        }

        $scope.resetTheTransferStock = function (isCancel) {
            console.info('resetTheTransferStock: ', $scope.seletectedMultishopStock, $scope.oldSeletectedMultishopStock, $scope.shop.product.stock, $scope.OldShopProductStock);
            $scope.shop.product.stock = $scope.OldShopProductStock;
            $scope.seletectedMultishopStock = $scope.oldSeletectedMultishopStock;
            $scope.selectedMultishopName = undefined;
            $scope.selectedMultishop = undefined;
            $scope.selectedMultishopSupplier = undefined;
            $scope.selectedMultishopBrand = undefined;

            if (isCancel) {
                $scope.localdata.shopSelected = undefined;
            }
        }

        // When the Product privateLableCollection matches with the Multishop's privateLable the select it by default;
        function selectAritcleGroupOfMultiShop(shopId) {
            if ($scope.product.privateLabelCollections && $scope.product.privateLabelCollections.length) {
                $shop.getOwnShopsPrivateLableCollections(shopId).then(function (results) {
                    console.log('getOwnShopsPrivateLableCollections result: ', results);
                    var curretShopPVC = $scope.product.privateLabelCollections[0];
                    if (curretShopPVC && curretShopPVC._id && results && results.length) {
                        for (var i = 0; i < results.length; i++) {
                            console.log(i, results[i] && results[i]._id && results[i]._id, curretShopPVC._id);
                            if ((results[i] && results[i]._id && results[i]._id.toString() == curretShopPVC._id.toString())) {
                                $scope.productForMultiShop.privateLabelCollections.push(results[i]);
                                break;
                            }
                        }
                    }
                }).catch(function (reason) {
                    console.error('getOwnShopsPrivateLableCollections: ', reason);
                });
            }
        }

        $scope.changeMultiShop = function (shopSelected) {
            $scope.resetTheTransferStock();
            $scope.selectedMultishopName = shopSelected.name;
            $scope.multishoperror = false;
            $scope.multishopmessage = '';
            $scope.selectedMultishopName = $scope.selectedMultishopName;
            $scope.selectedMultishopSupplier = undefined;
            $scope.selectedMultishop = shopSelected;
            $scope.oldSeletectedMultishopStock = shopSelected && shopSelected.product && shopSelected.product.stock ? shopSelected.product.stock : 0;
            $scope.productForMultiShop.multiShopId = $scope.selectedMultishop._id;
            console.log('selectedMultishop: ', $scope.selectedMultishop);
            var body = {
                productId: $scope.product._id,
                shopId: $scope.selectedMultishop._id
            }

            $product.fetchProductPrivateLableCollectionByShop(body).then(function (privateres) {
                console.log('----------------------------------------> fetchProductPrivateLableCollectionByShop called: ', privateres);
                $scope.productForMultiShop.privateLabelCollections = [];
                if (privateres && privateres.data && privateres.data.data && privateres.data.data.length) {
                    $scope.productForMultiShop.privateLabelCollections = privateres.data.data;
                } else {
                    selectAritcleGroupOfMultiShop(body.shopId);
                }

                if ($scope.selectedMultishop && $scope.selectedMultishop.product && $scope.selectedMultishop.product.stock) {
                    $scope.seletectedMultishopStock = $scope.selectedMultishop.product.stock || 0;
                } else {
                    $scope.seletectedMultishopStock = 0;
                }
                console.log('$scope.product: ', $scope.product);
                console.log('$scope.shop.product: ', $scope.shop.product);
                console.log('selectedMultishopName: ', $scope.selectedMultishopName);
                console.log('$scope.selectedMultishop: ', $scope.selectedMultishop);
                // If brand is not available then we can throw an error message otherwise go for the supplier condition
                if ($scope.product && $scope.product && $scope.product.brand && $scope.product.brand._id &&
                    $scope.selectedMultishop && $scope.selectedMultishop.brands && $scope.selectedMultishop.brands.length) {

                    for (var i = 0; i < $scope.selectedMultishop.brands.length; i++) {
                        if ($scope.selectedMultishop.brands[i]._id && ($scope.selectedMultishop.brands[i]._id).toString() == $scope.product.brand._id.toString()) {
                            console.log($scope.selectedMultishop.brands[i], $scope.product.brand);
                            $scope.selectedMultishopBrand = $scope.selectedMultishop.brands[i];
                            $scope.isBrandExist = true;
                            break;
                        }
                    }
                    if ($scope.isBrandExist) {
                        console.log('Brand Exist called');
                        doSelectedDefaultValufOfMultiShop();
                    } else {
                        console.info('Brand Not Exist: ', $rootScope.currentShop, $scope.product)
                        for (var i = 0; i < $rootScope.currentShop.brands.length; i++) {
                            console.log('$rootScope.currentShop.brands: ', i, $rootScope.currentShop.brands[i].name, $scope.product.brand.name);
                            console.log('$rootScope.currentShop.brands: ', i, $rootScope.currentShop.brands[i]._id._id, $scope.product.brand._id, ($rootScope.currentShop.brands[i]._id).toString() == $scope.product.brand._id.toString());
                            if ($rootScope.currentShop.brands[i]._id && ($rootScope.currentShop.brands[i]._id._id).toString() == $scope.product.brand._id.toString()) {
                               console.log('--------------------------------------------------COMING HERE: ', $rootScope.currentShop.brands[i])
                                $scope.selectedMultishopBrand = $rootScope.currentShop.brands[i];
                                if ($scope.selectedMultishopBrand && $scope.selectedMultishopBrand.favoriteSupplier._id) {
                                    $scope.selectedMultishopSupplier = $scope.selectedMultishopBrand.favoriteSupplier._id
                                }
                                // $scope.isBrandExist = true;
                                break;
                            }
                        }
                        console.log('$scope.selectedMultishopBrand: ', $scope.selectedMultishopBrand, $scope.selectedMultishopSupplier);

                        if (!$scope.selectedMultishopBrand) {
                            $scope.multishoperror = true;
                            $scope.multishopmessage = $language.translate('PLEASE_FIRST_ADD_BRAND_TO_CURRENT_SHOP');
                            return;
                        }
                        // $scope.isBrandExist = false;
                        $scope.isBrandNeedToAdd = true;
                        $scope.brandadded = $language.translate('ADDING_BRAND_TO_SELECTED_SHOP');
                        // $scope.multishoperror = true;
                        // $scope.multishopmessage = $language.translate('PLEASE_FIRST_ADD_BRAND_TO_OTHER_SHOP');
                    }
                    // if (!$scope.productForMultiShop || !$scope.productForMultiShop.privateLabelCollections || !$scope.productForMultiShop.privateLabelCollections.length) {
                    //     $scope.multishoperror = true;
                    //     $scope.multishopmessage = $language.translate('PLEASE_FILL_ALL_DETAILS');
                    //     return;
                    // }
                } else {
                    doSelectedDefaultValufOfMultiShop();
                    return;
                    // $scope.multishoperror = true;
                    // $scope.multishopmessage = 'This shop does not have a brand; try to add it manually';
                    // console.log('This shop does not have the brand');
                }
            }).catch(function (error) {
                console.error("Error In fetchMultiShopDetail: ", error);
            })
        }


        function doSelectedDefaultValufOfMultiShop() {
            if ($scope.selectedMultishop) {
                // If shop.product.supplier is not available in the shopB.companies then ask user to add from the search result
                if ($scope.shop && $scope.shop.product && $scope.shop.product.supplier && $scope.shop.product.supplier._id &&
                    $scope.selectedMultishop.companies && $scope.selectedMultishop.companies.length) {
                    for (var i = 0; i < $scope.selectedMultishop.companies.length; i++) {
                        // console.log(i, $scope.selectedMultishop.companies[i]._id, $scope.shop.product.supplier._id);
                        if ($scope.selectedMultishop.companies[i]._id == $scope.shop.product.supplier._id) {
                            console.log('SELECTED:--->', i, $scope.selectedMultishop.companies[i], $scope.shop.product.supplier._id);
                            $scope.selectedMultishopSupplier = $scope.shop.product.supplier;
                            break;
                        }
                    }

                    // If Shop.product.supplier is not avaialble into the ShopB.companies array (And Brand Exist but with the different Supplier in ShopB)
                    // - Then Show from the Shop.brand.Favourite.supplier
                    // - Push that Favourite Supplier to the Shop.companies array
                    if (!$scope.selectedMultishopSupplier) {
                        console.log('Hello there: ', $scope.selectedMultishopBrand, $scope.isBrandExist);
                        if ($scope.selectedMultishopBrand && $scope.selectedMultishopBrand.favoriteSupplier && $scope.selectedMultishopBrand.favoriteSupplier._id) {
                            var companyBody = { _id: $scope.selectedMultishopBrand.favoriteSupplier._id, shopId: $rootScope.currentShop._id }
                            $recommendationOrder.fetchCompanyDetail(companyBody).then(function (response) {
                                $rootScope.recommendationCompany = undefined;
                                console.info('======================111========================> fetchCompanyDetail: respnse: ', response);
                                if (response && response.data && response.data.data) {
                                    $scope.selectedMultishopSupplier = response.data.data
                                } else {
                                    $scope.multishoperror = true;
                                    $scope.multishopmessage = $language.translate('SELECT_COMPANY_FIRST');
                                }
                            }).catch(function (error) {
                                console.log('Error while getting companies: ', error);
                            });
                        } else if ($scope.shop && $scope.shop.product && $scope.shop.product.supplier && $scope.shop.product.supplier._id) {
                            console.log('You are having proper company detail 1111');
                            var companyBody = { _id: $scope.shop.product.supplier._id, shopId: $rootScope.currentShop._id }
                            $recommendationOrder.fetchCompanyDetail(companyBody).then(function (response) {
                                $rootScope.recommendationCompany = undefined;
                                console.info('======================111========================> fetchCompanyDetail: respnse: ', response);
                                if (response && response.data && response.data.data) {
                                    $scope.selectedMultishopSupplier = response.data.data
                                } else {
                                    $scope.multishoperror = true;
                                    $scope.multishopmessage = $language.translate('SELECT_COMPANY_FIRST');
                                }
                            }).catch(function (error) {
                                console.log('Error while getting companies: ', error);
                            });
                        } else {
                            console.log('You are not having proper company detail 1');
                            $scope.multishoperror = true;
                            $scope.multishopmessage = $language.translate('SELECT_COMPANY_FIRST');
                        }
                    } else {
                        console.log('You are not having proper company detail 2');
                    }
                } else {
                    $scope.multishoperror = true;
                    $scope.multishopmessage = $language.translate('SELECT_COMPANY_FIRST');
                }
            } else {
                $scope.multishoperror = true;
                $scope.multishopmessage = $language.translate('IMPROPER_DATA_ENTERED');
            }
        }

        $scope.fetchMultiShopDetail = function () {
            console.log('fetchMultiShopDetail: ', $scope.shop.product.stock);
            // $scope.OldShopProductStock = $scope.shop.product.stock;

            // if ($rootScope.currentShop && $rootScope.currentShop._id && $scope.product && $scope.product._id && !$scope.localdata.isFromPurchaseOrder) return;
            
            var body = {
                shopId: $rootScope.currentShop._id,
                productId: $scope.product._id
            }
            $shop.fetchMultiShopDetail(body).then(function (multishopres) {
                // console.log('multishopres: ', multishopres);
                // $scope.OldShopProductStock = $scope.shop.product.stock;
                if (multishopres && multishopres.data && multishopres.data.multiShops && multishopres.data.multiShops.length) {
                    $scope.multiShopsDetails = multishopres.data;
                }
            }).catch(function (error) {
                console.error("Error In fetchMultiShopDetail: ", error);
            })
        }

        $scope.stockChange = function (seletectedMultishopStock, shopProductStock, isFromSelectedShop, oldSeletectedMultishopStock, OldShopProductStock, isIncrement) {
            console.log('stockChange: ', seletectedMultishopStock, shopProductStock, isFromSelectedShop, oldSeletectedMultishopStock, OldShopProductStock, isIncrement);

            if (isIncrement) {
                if (isFromSelectedShop) {
                    console.log('If in If');
                    if (shopProductStock <= 0) return;
                    $scope.seletectedMultishopStock += 1;
                    $scope.shop.product.stock -= 1;
                } else {
                    console.log('Else in If');
                    if (seletectedMultishopStock <= 0) return;
                    $scope.shop.product.stock += 1;
                    $scope.seletectedMultishopStock -= 1;
                }
            } else {
                if (isFromSelectedShop) {
                    console.log('If in Else');
                    if (seletectedMultishopStock <= 0) return;
                    $scope.seletectedMultishopStock -= 1;
                    $scope.shop.product.stock += 1;
                } else {
                    console.log('Else in Else');
                    if (shopProductStock <= 0) return;
                    $scope.shop.product.stock -= 1;
                    $scope.seletectedMultishopStock += 1;
                }
            }

            $scope.multishoperror = false;
            $scope.multishopmessage = '';
        }


        function updateTransferProduct(shopProductTemp, tempShop, response) {
            $shop.updateShopProductStock($scope.selectedMultishop._id, $scope.product._id, $scope.oldSeletectedMultishopStock, shopProductTemp.stock, "Transfer: Update from edit product page").then(function (respnose) {
                $scope.updateProduct('', true); // Also calling the Main product save
                var data = { prodShopId: tempShop._id, prodId: $scope.product._id, index: $scope.temp.index, create: false };
                if ($scope.temp && $scope.temp.isFromPurchaseOrder) $rootScope.$emit('callPurchaseOrder', data); // We should not call this API always but only when coming from the purchase order
                var data2 = { shopProduct: tempShop, product: $scope.product }
                console.log('catchOnAssortment ', data2);
                data2.shopProduct.hasStock = !data2.shopProduct.hasStock;
                $rootScope.$emit('catchOnAssortment', data2)
                if (response.data.suggestionId) {
                    console.log('-------- response.data.suggestionId!');
                    console.log($scope.images);
                    if ($scope.images.length > 0 && $scope.images[0].name) {
                        Upload.upload({
                            url: 'api/product-image-upload',
                            data: {
                                productId: response.data.suggestionId,
                                files: $scope.images, //file input field
                                isUpdate: true,
                                shopId: $rootScope.currentShop._id
                            }
                        })
                            .then(function (res) {
                                console.log('$stateParams.orderNumber 1');
                                console.log($stateParams.orderNumber);
                                console.log(res);
                                // goToWay($stateParams.orderNumber);
                            })
                            .catch(function (error) {
                                console.error("Error In Update Images", error)
                            })
                    } else {
                        console.log('-------- response.data.suggestionId! else!');
                        console.log('$stateParams.orderNumber 2');
                        console.log($stateParams.orderNumber);
                        // goToWay($stateParams.orderNumber);
                    }
                } else {
                    console.log('-------- esle response.data.suggestionId!');
                    console.log('$scope.images ', $scope.images);
                    if ($scope.images.length > 0 && $scope.images[0].name) {
                        $scope.upload = true;
                        Upload.upload({
                            url: 'api/product-image-upload',
                            data: {
                                productId: $scope.product._id,
                                files: $scope.images, //file input field
                                shopId: $rootScope.currentShop._id
                            }
                        })
                            .then(function (res) {
                                console.log('$stateParams.orderNumber 3');
                                console.log($stateParams.orderNumber);
                                console.log(res);
                                // goToWay($stateParams.orderNumber);

                            })
                            .catch(function (error) {
                                console.error("Error In Update Images", error)
                            })
                    } else {
                        // goToWay($stateParams.orderNumber);
                    }
                }
            }).catch(function (error) {
                $scope.istransferShopDataLoading = false;
                console.error('error: ', error);
            });
        }

        $scope.transferShopData = function () {
            try {
                console.log('selectedstock: ', $scope.oldSeletectedMultishopStock, $scope.seletectedMultishopStock);
                console.log('currentStock: ', $scope.OldShopProductStock, $scope.shop.product.stock);
                var selectedPrivateLableColl = $scope.selectedMultishop && $scope.selectedMultishop.product && $scope.selectedMultishop.product._id ? $scope.product.privateLabelCollections : $scope.productForMultiShop.privateLabelCollections;
                console.log(($scope.oldSeletectedMultishopStock == $scope.seletectedMultishopStock) || ($scope.OldShopProductStock == $scope.shop.product.stock));
                // console.log('CONDITIONS: ', $scope.istransferShopDataLoading, !$scope.selectedMultishopSupplier, ($scope.product.brand._id && !$scope.isBrandExist), !selectedPrivateLableColl, !selectedPrivateLableColl.length, !selectedPrivateLableColl[0]._id);
                if (($scope.oldSeletectedMultishopStock == $scope.seletectedMultishopStock) || ($scope.OldShopProductStock == $scope.shop.product.stock)) {
                    $scope.multishoperror = true;
                    $scope.multishopmessage = $language.translate('SELECT_STOCK_TO_TRANSFER');
                    return;
                } else if ($scope.isBrandNeedToAdd) {
                    if ($scope.istransferShopDataLoading || !$scope.product.brand._id || !selectedPrivateLableColl || !selectedPrivateLableColl.length || !selectedPrivateLableColl[0]._id) {
                        $scope.multishoperror = true;
                        console.log('transferShopData fill all details 3118')
                        console.log('$scope.istransferShopDataLoading', $scope.istransferShopDataLoading)
                        console.log('$scope.product.brand._id', !$scope.product.brand._id)
                        console.log('!selectedPrivateLableColl', !selectedPrivateLableColl)
                        console.log('!selectedPrivateLableColl.length', !selectedPrivateLableColl.length)
                        console.log('!selectedPrivateLableColl[0]._id', !selectedPrivateLableColl[0]._id)
                        $scope.multishopmessage = $language.translate('PLEASE_FILL_ALL_DETAILS');
                        return;
                    }
                } else if ($scope.istransferShopDataLoading || !$scope.selectedMultishopSupplier || ($scope.product.brand._id && !$scope.isBrandExist) || !selectedPrivateLableColl || !selectedPrivateLableColl.length || !selectedPrivateLableColl[0]._id) {
                    $scope.multishoperror = true;
                    console.log('transferShopData fill all details 3123')
                    console.log('!selectedPrivateLableColl', !selectedPrivateLableColl)
                    console.log('!$scope.isBrandExist', !$scope.isBrandExist)
                    console.log('$scope.product.brand._id', $scope.product.brand._id)
                    console.log('!$scope.selectedMultishopSupplier', !$scope.selectedMultishopSupplier)
                    console.log('$scope.istransferShopDataLoading', $scope.istransferShopDataLoading)
                    $scope.multishopmessage = $language.translate('PLEASE_FILL_ALL_DETAILS');
                    console.log('valeu $scope.selectedMultishopSupplier', $scope.selectedMultishopSupplier)
                    return;
                }

                $scope.istransferShopDataLoading = true;

                $rootScope.creatingItem = true;
                console.log('------------   transferShopData!!');
                var modifieByUser = false;

                if ($scope.dataStatus == 'bad' && $scope.dataStatus != 'alright' && $scope.dataStatus != 'good' && $scope.dataStatus != 'perfect') {
                    $scope.shop.product.show = false
                } else {
                    if (addProductForm && addProductForm.show && !addProductForm.show.$pristine) {
                        console.log("Modified", $scope.shop.product.show);
                        console.log("User wants that we have to store");
                        modifieByUser = true;
                    } else {
                        if (!$scope.shop.product.show) $rootScope.openRetalierShowModal($stateParams._id);
                    }
                }

                // Update Existing Product With new Data
                console.log('--------------------------------------------------------------------------------------------------------------------------------4-----------------ABC---------------------: ');
                if ($scope.product.privateLabelCollections && $scope.product.privateLabelCollections[0] && !$scope.localdata.labelDescription) {
                    $scope.shop.product.labelDescription = makeLableProper($scope.shop.product.labelDescription, $scope.product.privateLabelCollections[0][$scope.language].name, $scope.product.variants[0].productNumber);
                } else if ($scope.localdata.labelDescription && !$scope.shop.product.labelDescription) {
                    $scope.shop.product.labelDescription = $scope.localdata.labelDescription;
                }

                if ($scope.product.suggestedRetailPriceVat == undefined) $scope.product.suggestedRetailPriceVat = $scope.shop.product.priceVat;

                if ($scope.product.suggestedRetailPrice) {
                    $scope.product.suggestedRetailPrice = $scope.product.suggestedRetailPrice.toString().replace(',', '.');
                    $scope.product.suggestedRetailPrice = $scope.product.suggestedRetailPrice / ($scope.product.suggestedRetailPriceVat / 100 + 1);
                } else {
                    $scope.product.suggestedRetailPrice = undefined;
                }
                var newCollections = {
                    privateLable: $scope.productForMultiShop.privateLabelCollections,
                    brand: $scope.product.brandCollections,
                    variant: $scope.product.variantCollections,
                    clientGroup: $scope.product.clientGroupCollections
                }

                var suggester = {
                    id: $rootScope.user._id,
                    name: $rootScope.user.firstName + ' ' + $rootScope.user.lastName + ' ' + $rootScope.currentShop.name,
                    usertype: $rootScope.user.role,
                    currentShopId: $scope.selectedMultishop._id
                }

                var tempShop = JSON.parse(JSON.stringify($scope.shop.product));
                tempShop.stock = $scope.seletectedMultishopStock || 0;

                if (tempShop.entryMethodCustomerValue == null || tempShop.entryMethodCustomerValue == undefined) {
                    var minus = (tempShop.priceVat / 100) + 1;
                    tempShop.price = tempShop.sellingPrice / minus;
                }

                // console.log('productForMultiShop: ', $scope.productForMultiShop);
                // console.log('suggester: ', suggester);
                // console.log('$scope.product: ', $scope.product);
                // console.log('$scope.originalProduct: ', $scope.originalProduct);
                // console.log('tempShop: ', tempShop);
                console.log('$scope.productForMultiShop: ', $scope.productForMultiShop);
                console.log('$scope.product.privateLabelCollections: ', $scope.product.privateLabelCollections);
                console.log('newCollections: ', newCollections);
                console.log('$scope.newCollections: ', $scope.product.privateLabelCollections);
                console.log('isBrandNeedToAdd 2: ', $scope.isBrandNeedToAdd);
                // console.log('$scope.purchaseOrderId: ', $scope.purchaseOrderId);
                // console.log('$scope.generatedArticalNumber: ', $scope.generatedArticalNumber);
                // console.log('$rootScope.currentShop._id: ', $rootScope.currentShop._id);
                // console.log('modifieByUser: ', modifieByUser);
                // console.log('$scope.purchaseOrderItemId: ', $scope.purchaseOrderItemId);
                var shopProductTemp = JSON.parse(JSON.stringify(tempShop));
                shopProductTemp.stock = $scope.seletectedMultishopStock || 0;
                var articleBody = { shopId: $scope.selectedMultishop._id, articleNumber: shopProductTemp.articleNumber, productId: $scope.product._id, currentShopId: $rootScope.currentShop._id }
                $shop.isArticleNumberExistInShop(articleBody).then(function (response) {
                    console.log('isArticleNumberExistInShop: ', shopProductTemp.stock);

                    if (response && response.data && !response.data.isArticleExist) {
                        console.log('isBrandNeedToAdd 1: ', $scope.isBrandNeedToAdd);
                        console.log('isBrandNeedToAdd called: ', $scope.selectedMultishopBrand);
                        if ($scope.selectedMultishopBrand && (!$scope.selectedMultishopBrand.favoriteSupplier || !$scope.selectedMultishopBrand.favoriteSupplier._id)) {
                            return;
                        }
                        console.log('$scope.selectedMultishopBrand: ', $scope.selectedMultishopBrand);
                        // if ($scope.selectedMultishopBrand && $scope.selectedMultishopBrand.favoriteSupplier._id) {
                        //     $scope.selectedMultishopSupplier = {
                        //         _id: $scope.selectedMultishopBrand.favoriteSupplier._id
                        //     }
                        // }
                        // console.log('Compbody: ', $scope.selectedMultishop._id, $scope.selectedMultishopSupplier, $rootScope.currentShop._id);
                        console.log('selectedMultishopSupplier: ', $scope.selectedMultishopSupplier);
                        $shop.updateProduct(suggester, $scope.product, $scope.originalProduct, shopProductTemp, $scope.oldCollections, newCollections, $scope.selectedMultishop.nameSlug, $scope.purchaseOrderId, $scope.generatedArticalNumber, 'edit-product', $scope.selectedMultishop._id, modifieByUser, $scope.purchaseOrderItemId).then(function (response) {

                            var compbody = {
                                shop: { _id: $scope.selectedMultishop._id },
                                company: { _id: $scope.selectedMultishopSupplier._id },
                                shopId: $rootScope.currentShop._id
                            }
                            console.log('add compbody: ', compbody);
                            $brand.addCompanyInShop(compbody).then(function (response) {
                                var brand = $scope.selectedMultishopBrand;
                                if (brand) {
                                    console.info('brand._id: ', brand._id);
                                    brand._id = brand._id && brand._id._id ? brand._id._id : brand._id;
                                    brand.favoriteRepairerId = brand.favoriteRepairer._id && brand.favoriteRepairer._id._id ? brand.favoriteRepairer._id._id : brand.favoriteRepairer._id;
                                    brand.favoriteSupplierId = brand.favoriteSupplier._id && brand.favoriteSupplier._id._id ? brand.favoriteSupplier._id._id : brand.favoriteSupplier._id;
                                }
                                var brandbody = {
                                    shop: { _id: $scope.selectedMultishop._id },
                                    brand: $scope.selectedMultishopBrand
                                }
                                if (!$scope.product.brand || !$scope.product.brand._id) {
                                    updateTransferProduct(shopProductTemp, tempShop, response);
                                    return;
                                }
                                console.log('AddCompaniesAndBrandInShop called: ', brandbody, brand);
                                $shop.AddCompaniesAndBrandInShop(brandbody).then(function (response) {
                                    updateTransferProduct(shopProductTemp, tempShop, response);
                                }).catch(function (error) {
                                    $scope.istransferShopDataLoading = false;
                                    console.error('error: ', error);
                                });

                            }).catch(function (error) {
                                $scope.istransferShopDataLoading = false;
                                console.error('error: ', error);
                            });
                        }).catch(function (reason) {
                            $scope.istransferShopDataLoading = false;
                            console.error("Error! : ", reason);
                        })
                    } else {
                        $scope.multishoperror = true;
                        $scope.multishopmessage = $language.translate('SAME_ARTICLENUMBER_ALREADY_EXIST_IN_OTHER_SHOP');
                    }

                }).catch(function (error) {
                    $scope.istransferShopDataLoading = false;
                    console.error("Error in ArticlenUmber! : ", reason);
                });
            } catch (e) {
                console.log('Internal Server Error: ', e);
            }
        }

        $scope.resetMultiShop = function () {
            $scope.multishoperror = false;
            $scope.multishopmessage = undefined;
            $scope.$scope.multiShopsDetails = undefined;
            $scope.selectedMultishopName = undefined;
            $scope.selectedMultishop = undefined;
            $scope.selectedMultishopSupplier = undefined;
            $scope.seletectedMultishopStock = 0;
            $scope.editSupForMultiShop = true;
        }

        function IsValidDescription(description) {
            if (!description) {
                console.log('No data');
                $scope.localdata.isDescriptionExist = false;
                return;
            }
            description = description.toLowerCase();
            console.log('----------------------> IsValidDescription description: ', description, description.length);
            var filter = ["retourneren", "gratis", "verzendkosten", "op=op", "op=", "bij ons ", "wij hebben", "wij helpen",
                " wij ", " levering", "levertijd", "op voorraad", "zichttermijn", "termijn", "wij ook", "kosteloos",
                " leveren", "We zorgen", "Wij zorgen", "cadeaubon", "kadobon", "tegoed", "kom langs", "onze service", "verpakking",
                "0 dagen ", "reparatie"];
            var shopname = $rootScope.currentShop.name;
            if (shopname) {
                filter.push(shopname);
                shopnamearrays = shopname.toLowerCase().split(' ');
                if (shopnamearrays.length > 1) {
                    for (var i = 0; i < shopnamearrays.length; i++) {
                        console.log('shopnamearrays called: ', shopnamearrays[i]);
                        if (shopnamearrays[i].length > 3) {
                            console.log('shopnamearrays[i]: ', shopnamearrays[i], shopnamearrays[i].length);
                            var name = shopnamearrays[i];
                            filter.push(name);
                        }
                    }
                }
            }
            $scope.localdata.isDescriptionExist = false;
            for (var i = 0; i < filter.length; i++) {
                // if (filter[i] == " wij ") {
                console.log('searched: ', description, filter[i], description.indexOf(filter[i]), description.length, filter[i].length);
                // }
                if (description.indexOf(filter[i]) >= 0) {
                    $scope.localdata.isDescriptionExist = true;
                    break;
                }
            }
            console.log('shortDescriptionFiltered: ', $scope.localdata.isDescriptionExist);
        }

        $scope.shortDescriptionFiltered = function (description) {
            // console.log('shortDescriptionFiltered called: ', $scope.product);
            if (!$scope.product || !$scope.product._id) {
                IsValidDescription(description);
                return;
            }
            var prodbody = {
                productId: $scope.product._id,
                shopId: $rootScope.currentShop._id
            }
            // IsValidDescription(description);
            // return;
            console.log('shortDescriptionFiltered ---------------------> body: ', prodbody);
            $shop.productIsForUpdateOrAddNewSuggestion(prodbody).then(function (prdres) {
                console.log('shortDescriptionFiltered prdres: ', prdres);
                if (prdres && prdres.data && prdres.data.data == false) return;
                IsValidDescription(description);
            }).catch(function (prderr) {
                console.log('shortDescriptionFiltered called: ', prderr);
            });
        }
    }
    ]);