prismanoteApp.controller('returnOrder-modalController', ['$scope', '$uibModalInstance', 'order', '$api',
  function ($scope, $uibModalInstance, order, $api) {
    $scope.order = order
    $scope.refund = false
    $scope.amountAccepted = true
    $scope.alert = null
    $scope.refunds = null
    $scope.return = false

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    $scope.getPayment = function () {
      if ($scope.order.billingDetails[0].paymentId) {
        $api.post('get-paymentinfo', { paymentId: $scope.order.billingDetails[0].paymentId })
          .then(function (response) {
            $scope.refund = true
            $scope.return = false
            $scope.payment = response.data.payment

            var amount = parseFloat($scope.payment.amount.replace(',', '.'))
            if (!$scope.payment.amountRemaining) $scope.payment.amountRemaining = $scope.payment.amount

            var amountRemaining = parseFloat($scope.payment.amountRemaining.replace(',', '.'))

            $scope.refundAmount = amountRemaining <= amount ? amountRemaining : amount
            $scope.checkRefundAmount()
          })
          .catch(function (reason) {
            console.warn('error getting payment', reason)
            $scope.alert = {
              type: 'danger',
              msg: reason
            }
          })
      } else {
        $scope.alert = {
          type: 'danger',
          msg: 'Fout bij ophalen betalingsgegevens van order'
        }
      }
    }

    $scope.checkRefundAmount = function () {
      if ($scope.refundAmount == 0 || $scope.refundAmount > (parseFloat($scope.payment.amountRemaining.replace(',', '.')))) $scope.amountAccepted = false
      else $scope.amountAccepted = true
    }

    $scope.refundOrder = function () {
      $api.post('refund-payment', { paymentId: $scope.order.billingDetails[0].paymentId, amount: $scope.refundAmount })
        .then(function (response) {
          $scope.alert = {
            type: 'success',
            msg: 'Terugbetaalverzoek is ingediend!'
          }
          $scope.getRefunds()
          $scope.getPayment()
        })
        .catch(function (reason) {
          console.warn('error refunding error', reason)
          $scope.alert = {
            type: 'danger',
            msg: reason
          }
        })
    }

    $scope.getRefunds = function () {
      $scope.refunds = null
      $api.post('get-refunds', { paymentId: $scope.order.billingDetails[0].paymentId })
        .then(function (response) {
          $scope.refunds = response.data.refunds
        })
        .catch(function (reason) {
          $scope.alert = {
            type: 'danger',
            msg: reason
          }
        })
    }

    $scope.returnOrder = function (replacement) {
      if (!replacement || replacement == 'undefined') {
        $scope.return = true
        $scope.refund = false
      } else {
        $api.post('return-order', { orderNumber: $scope.order.number })
          .then(function (response) {
            $scope.alert = {
              type: 'success',
              msg: response.data.message
            }
          })
          .catch(function (reason) {
            console.error(reason)
            $scope.alert = {
              type: 'danger',
              msg: reason
            }
          })
      }
    }

    $scope.closeAlert = function (alert) {
      $scope.alert = null
    }
  }
])
