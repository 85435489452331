prismanoteApp.controller('adminNewsController', ['$scope', '$api', '$stateParams', '$uibModal', '$state', 'prompt', 'Upload', '$state', '$news',
	function ($scope, $api, $stateParams, $uibModal, $state, prompt, Upload, $state, $news) {

		// Default options for fetching new items: 12 items with featured items first, sorted by views.
		var defaultApiOptions = { 'sort': { 'startDate': 'desc' } };

		// currently active API request params/options. Set to default options initially, may be changed or reset to default options later.
		var apiOptions = defaultApiOptions;

		// General setter to change the Api HTTP request options/params. Some wrapper functions are available to make the code more intuitive.
		var setApiOptions = function (options, type, append) {
			if (typeof options === 'object') {
				if (typeof append !== 'boolean' || append === false) {
					apiOptions[type] = options;
				} else {
					apiOptions[type] = _.extend(apiOptions[type], options);
				}
			} else if (options == null) {
				apiOptions = options;
			}
			$scope.products = new Array;
			$scope.getProducts();
		};

		$scope.news = new Array;

		$scope.getNewsItems = function () {
			var numberOfNewsPosts = $scope.news.length;
			apiOptions.offset = numberOfNewsPosts;
			apiOptions.limit = 100;
			$api.get('news', apiOptions)
				.then(function (response) {
					// Add newsPosts to the $scope.
					console.log('getnewsitemss: ', response);

					var results = (response && response.data && response.data.result &&
						response.data.result.newsItems && response.data.result.newsItems.length) ? response.data.result.newsItems : [];

					if (!results.length) $scope.news = [];
					for (var i = 0; i < results.length; i++) {
						$scope.news.push(results[i]);
					}
					// OLD CODE
					// for (var i = 0; i < response.data.newsItems.length; i++) {
					// 	var newsPost = response.data.newsItems[i];
					// 	//console.log(newsPost);
					// 	//if(newsPost.update == true) {
					// 	$scope.news.push(newsPost);
					// 	//} else {
					// 	//	console.log("er ging iets niet goed met de aanvraag naar de backend");
					// 	//}
					// }
				})
				.catch(function (reason) {
					console.log(reason);
				})
		};

		$scope.addNewsItem = function () {
			$scope.alert = null;
			$news.newsValidation($scope.newsItem).then(function (validNews) {
				$api.post('news/', { newsItem: $scope.newsItem })
					.then(function (response) {
						$scope.upload = true;
						Upload.upload({
							url: 'api/news/news-photo-upload',
							data: {
								newsItemId: response.data.newsItem._id,
								file: $scope.newsItem.photo //file input field
							}
						})
							.then(function (res) {
								//wanneer uploaden gelukt
								console.log("klaar!");
								$state.go('admin.news');
							}, function (res) {
								//wanneer uploaden mislukt
								console.log("Error: ", res);
								$scope.alert = {
									type: "danger",
									msg: "Fout bij uploaden: " + res.status
								}

							}, function (evt) {
								//tijdens upload
								var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
								$scope.uploadProgress = progressPercentage;
								console.log("Progress: " + progressPercentage + '%' + evt.config.data);
							});
						$scope.upload = false;

					})
					.catch(function (reason) {
						console.log(reason);
						$scope.alert = {
							type: "danger",
							msg: "Het lukte niet om uw gegevens op te slaan " + reason
						}
						$scope.upload = false;
					});
			}).catch(function (InvalidNewsErr) {
				console.log('InvalidNewsErr: ', InvalidNewsErr);
				$scope.alert = {
					type: 'danger',
					msg: InvalidNewsErr.message
				}
			});
		}

		$scope.closeAlert = function () {
			$scope.alert = 0
		}

		var getItems = function (type) {
			$api.get(type)

				.then(function (response) {
					$scope[type] = response.data[type];
				})

				.catch(function (reason) {
					console.log(reason);
				});
		};

		// merken
		$scope.getBrands = function () {
			return getItems('brands');
		};

		$scope.getCompanies = function () {
			return getItems('companies');
		};

		var searchItems = function (str, type) {
			var matches = [];

			$scope[type].forEach(function (item) {
				if (
					(item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
					(item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
					matches.push(item);
				}
			});

			return matches;
		};

		$scope.searchBrands = function (str) {
			return searchItems(str, 'brands');
		}

		$scope.searchCompanies = function (str) {
			return searchItems(str, 'companies');
		}

		$scope.brandSelected = function (selected) {
			var brand = {
				_id: selected.originalObject._id,
				name: selected.title,
				nameSlug: selected.originalObject.nameSlug,
				description: selected.originalObject.description,
				images: selected.originalObject.images
			}

			$scope.newsItem.brand = brand;
		};

		$scope.companySelected = function (selected) {
			var company = {
				_id: selected.originalObject._id,
				name: selected.title,
				nameSlug: selected.originalObject.nameSlug,
				description: selected.originalObject.description,
				images: selected.originalObject.images
			}

			$scope.newsItem.company = company;
		};


	}]);




