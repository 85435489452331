prismanoteApp.controller('searchTransactionsModal', ['$scope', '$uibModalInstance', 'customer', '$rootScope', '$transaction', '$language', '$api', '$log', '$customer',
  function ($scope, $uibModalInstance, customer, $rootScope, $transaction, $language, $api, $log, $customer) {
    if (customer) {
      $scope.customer = customer
    }

    $scope.customerName = function(customer) {
      return $customer.makeCustomerName(customer)
    }


    $scope.search = function (value) {
      $scope.transactions = []
      if(!value || value.length < 3) {
        return
      }

      value = String(value)

      if(value.startsWith('R0000')) {
        var realVal = value.split('R').filter(function(a) { return a; });
        if(realVal.length !== 0) {
          value = String(parseInt(realVal))
          console.info('Jupa repair code detected, value parsed to',value)
        }
      }

      $api.post('transaction/search-transaction', {
        search: value,
        shopId: $rootScope.currentShop._id,
        customer: $scope.customer ? $scope.customer._id : null,
        till: true
      })
        .then(function (response) {
          if(response.data.customers.transactions && response.data.customers.transactions.length > 0) {
            $scope.transactions = response.data.customers.transactions
          }
        })
        .catch(function (reason) {
          $log.error('Error while searching transactions', reason)
        })
    }

    $scope.selectTransaction = function (transaction) {
      $uibModalInstance.close({ transaction: transaction })
    }



    $scope.cancel = function () {
      $uibModalInstance.dismiss()
    }
  }])
