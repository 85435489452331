prismanoteApp.controller('brandCompanyInfoController', ['$scope', '$rootScope', '$email', '$brand', 'Upload', 'dialogs', '$language', '$recommendationOrder', '$jspm', '$label', 'prompt', '$printnode', '$printer',
  function ($scope, $rootScope, $email, $brand, Upload, dialogs, $language, $recommendationOrder, $jspm, $label, prompt, $printnode, $printer) {
    $rootScope.pageTitle = 'PrismaNote Brand Portal | ' + $language.translate('COMPANY_AND_BRAND_INFO');

    $scope.customFilters = $recommendationOrder.getDefaultFilterValue() // For adding custom filter
    $scope.extraCustomFilters = $recommendationOrder.getFiltersForCustomFilter() // which are not in the recommendation order page
    $scope.customFilters = Object.assign($scope.customFilters, $scope.extraCustomFilters)
    $scope.emailBackup
    $scope.data = {}

    $scope.getCompanyInfoForCompanyUser = function () {
      var body = {
        companyId: $rootScope.currentCompany._id
      }
      $brand.getCompanyInfoForCompanyUser(body, body.companyId).then(function (response) {
        if (response && response.data && response.data.data) {
          $scope.company = response.data.data;
          $scope.company.documentSettings = $rootScope.currentCompany.documentSettings
          $scope.emailBackup = $scope.company.email
          if ($scope.company.emailNotificationlanguage == undefined) {
            $scope.company.emailNotificationlanguage = "en"
          }
        }
      }).catch(function (error) {
        console.error('error: ', error);
      });
    }

    $scope.getPrintersforLabels = function () {
      $jspm.getInstalledPrinters(true)
        .then(function (printers) {
          $scope.printers = printers
        })
        .catch(function (reason) {
          $jspm.closeConnection()
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(reason)
          }
        })
    }

    $scope.loadLabels = function () {
      $scope.labels = []
      $label.getCompanyLabels($rootScope.currentCompany._id, null, false)
        .then(function (templates) {
          var data = $label.getExampleDataObject()
          _.forEach(templates, function (template) {

            $label.makeLabel(template, data)
              .then(function (label) {
                template.previewUrl = label.previewUrl
                template.printCommand = label.printCommand
                $scope.labels.push(template)
              })
          })

          //$scope.printers = [{ name: $rootScope.currentCompany.labelPrinter }]
          //$scope.getPrintersforLabels()
        })
        .catch(function (reason) {
          console.error('error when fetching labels', reason)
        })
    }

    $scope.selectedPrinter = function (printer) {

      if (!printer) {
        return
      }
      $scope.company.labelPrinter = printer
      $rootScope.currentCompany.labelPrinter = printer
      $scope.updateCompany('saveAlert')
    }

    $scope.newLogo = {}
    $scope.openBank = function (index) {
      if ($rootScope.currentCompany && $rootScope.currentCompany.bankdetails &&
        $rootScope.currentCompany.bankdetails.length && $rootScope.currentCompany.bankdetails[index]) {
        $scope.bankdetail = $rootScope.currentCompany.bankdetails[index]
        $scope.bankIndex = index
        $scope.showBankDetails = true
      }
    }

    $scope.changeShopEmail = function (newEmail) {
      if (newEmail === $scope.emailBackup) {
        return
      }

      if (validateEmail(newEmail)) {
        var dlg = dialogs.confirm($language.translate('CHANGE_EMAILADDRESS'), $language.translate("YOU_MUST_VERIFY_YOUR_NEW_EMAILADDRESS"), { size: 'sm' })

        dlg.result.then(function (btn) {
          $email.confirmEmail(newEmail, $scope.emailBackup)
            .then(function () {
              $scope.company.email = newEmail
              //	$rootScope.currentShop.email = newEmail
              //	$scope.updateShopBasicData($rootScope.currentShop, true)
              dialogs.notify(undefined, $language.translate('AN_EMAIL_WITH_INSTRUCTIONS_IS_SEND'), { size: 'sm' })
            })
            .catch(function (reason) {
              console.error("Error while changing e-mailaddress", reason)
              dialogs.error(undefined, reason, { size: 'sm' })
            })
        }, function (btn) {
          $scope.company.email = $scope.emailBackup
        })
      }
    }

    function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }

    $scope.cancelBank = function () {
      $scope.bankIndex = null
      $scope.showBankDetails = false
    }

    $scope.logoUpload = function (type) {
      $scope.uploadError = {}
      if ($rootScope.currentCompany && $rootScope.currentCompany._id && $scope.newLogo) {
        Upload.upload({
          url: 'api/v2/company/uploadlogo',
          data: {
            companyId: $rootScope.currentCompany._id,
            file: $scope.newLogo[type]
          }
        })
          .then(function (res) {
            if (!$rootScope.currentCompany[type]) {
              $rootScope.currentCompany[type] = {
                src: res.data.file
              }
            } else {
              $rootScope.currentCompany[type].src = res.data.file
            }
            $scope.newLogo = {}
          }, function (res) {
            $scope.uploadError[type] = {
              type: 'danger',
              msg: res.data || res.data.message
            }
          }, function (evt) {
            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total)
            $scope.uploadProgress = progressPercentage
          })
      }
    }

    $scope.saveToPrinter = function (template) {
      $label.saveLabelToPrinterMemory(template)
        .then(function (res) {
          toastr.success($language.translate('LAYOUT_SAVED_IN_PRINTER_MEMORY'))
        })
        .catch(function (reason) {
          console.error('Error while sending layout to printer memory', reason)
          toastr.error($language.translate(reason))
        })
    }

    $scope.printPreviewLabel = function (template) {
      var data = $label.getExampleDataObject()

      $label.printLabel(template.printCommand, data)
        .then(function () {

        })
        .catch(function (reason) {
          console.error('Error while printing example label', reason)
        })
    }

    $scope.updateCompany = function (alertType, editCompany) {
      $scope.company.companyId = $rootScope.currentCompany._id
      $brand.updateCompanyInfo(angular.copy($scope.company), alertType).then(function (result) {
        $rootScope.pdfFooterRemark = $scope.company.pdfFooterRemark;
        $rootScope.showStockProducts = $scope.company.showStockProducts;
        $rootScope.showMarginPriceFactor = $scope.company.showMarginPriceFactor;
        //if (editCompany.$dirty) {
        // $scope[alertType] = {
        //   type: 'success',
        //   msg: $language.translate("AWESOME_YOUR_CHANGES_SAVED")
        // }
        toastr.success($language.translate('AWESOME_YOUR_CHANGES_SAVED'))
        //}
      }).catch(function (error) {
        console.error('error: ', error);
      });
    }

    $scope.closeAlert = function () {
      $scope.saveAlert = null;
    }

    $scope.getCustomFilterInCompany = function () {
      var body = {
        companyId: $rootScope.currentCompany._id
      }

      $scope.filterLoading = true;
      $brand.getCustomFilterInCompany(body).then(function (response) {
        $scope.filterLoading = false;
        $scope.company.recommendationOrderFilters = [];
        if (response && response.data && response.data.data) {
          var data = response.data.data;
          if (data.companyDetail) {
            $scope.company.recommendationOrderFilters = data.companyDetail.recommendationOrderFilters.map(function (f) {
              if (!f.orderFilterCategoryName || f.orderFilterCategoryName === '') {
                f.orderFilterCategoryName = 'NO_GROUP'
              }
              return f
            });

          }
          if (data.companyDetail) {
            $scope.company.recommendationOrderFiltersCategories = data.companyDetail.recommendationOrderFiltersCategories;
          }
          if (data.companyFilters) {
            $scope.companyFilters = data.companyFilters;
            // BRANDS
            var brands = $scope.companyFilters.brands;
            $scope.customFilters.brands = brands && brands.length ? brands : [];
          }
          // Collections Filters
          if (data.collectionsFilters) {
            var clientGroupCollections = data.collectionsFilters.clientGroupCollections;
            $scope.clientGroupCollections = clientGroupCollections;
            $scope.customFilters.clientGroupCollections = clientGroupCollections;
          }
        }
      }).catch(function (error) {
        $scope.filterLoading = false;
        console.error('getCustomFilterInCompany error: ', error);
      });
    }

    // Start CUSTOM FILTER Functions
    $scope.addNewCustomFilterForm = function () {
      try {
        // Open the form for the add new custom filter
        $scope.editCustomFilter = false
        $scope.addCustomFilter = true
        if ($scope.company.recommendationOrderFilters) $scope.customFilterIndex = $scope.company.recommendationOrderFilters.length
        $scope.customfilterdetail = {}
        var filter = JSON.parse(JSON.stringify($scope.customFilters));
        $scope.customfilterdetail = {
          filterName: '',
          orderFilterCategoryName: '',
          searchQuery: {
            isSearch: false,
            searchValue: ''
          },
          filter: filter
        }
      } catch (e) {
        console.error('e - catch: ', e);
      }
    }

    $scope.addCategory = function (name) {
      $brand.addCustomFilterCategory({
        companyId: $rootScope.currentCompany._id,
        customFilterGroupName: name
      })
        .then(function () {
          if (!$scope.company.recommendationOrderFiltersCategories) {
            $scope.company.recommendationOrderFiltersCategories = []
          }
          $scope.company.recommendationOrderFiltersCategories.push({ name: name })
          delete $scope.newCategoryName
        })
        .catch(function (reason) {
          console.error('Error while adding category', reason)
        })
    }

    $scope.addCustomFilterfn = function () {
      // Add New BANK in company recommendationOrderFilters
      // $scope.customfilterdetail = {}
      $scope.addCustomFilter = false;

      if (!$scope.customfilterdetail.orderFilterCategoryName || $scope.customfilterdetail.orderFilterCategoryName === '') {
        $scope.customfilterdetail.orderFilterCategoryName = 'NO_GROUP'
      }

      var body = {
        companyId: $rootScope.currentCompany._id,
        customFilter: $scope.customfilterdetail
      }
      body.customFilter.filter.clientGroupCollections = [];
      if (body.customFilter.searchQuery.isSearch) {
        body.customFilter.filter = undefined;
      } else if (body.customFilter.searchQuery.searchValue == '' || body.customFilter.searchQuery.searchValue == null || body.customFilter.searchQuery.searchValue == undefined) {
        body.customFilter.searchQuery.searchValue = undefined;
      }
      $brand.addCustomFilter(body).then(function (response) {
        if (!$scope.company.recommendationOrderFilters) $scope.company.recommendationOrderFilters = []
        $scope.company.recommendationOrderFilters.push($scope.customfilterdetail)
      }).catch(function (error) {
        console.error('addCustomFilter error: ', error);
      })
    }

    $scope.cancelCustomFilter = function () {
      $scope.editCustomFilter = false
      $scope.addCustomFilter = false
      $scope.customfilterdetail = {}
    }

    $scope.deleteCustomFilter = function (id) {
      var index = _.findIndex($scope.company.recommendationOrderFilters, { id: id })
      $scope.cancelCustomFilter();
      var body = {
        companyId: $scope.company._id,
        filterId: id
      }

      $brand.deleteCustomFilterInCompany(body).then(function (response) {
        $scope.company.recommendationOrderFilters.splice(index, 1);
        $scope.cancelCustomFilter();
      }).catch(function (error) {
        console.error('addCustomFilter error: ', error);
      })
    }

    $scope.deleteCustomFilterCategory = function (index) {
      $brand.deleteCustomFilterCategoryInCompany({
        companyId: $rootScope.currentCompany._id,
        categoryId: $scope.company.recommendationOrderFiltersCategories[index]._id
      }).then(function (response) {
        $scope.company.recommendationOrderFiltersCategories.splice(index, 1);
      })
    }

    $scope.openCustomFilter = function (id) {
      var index = _.findIndex($scope.company.recommendationOrderFilters, { _id: id })
      var filter = $scope.company.recommendationOrderFilters[index]
      if (!filter.searchQuery.isSearch) {
        var companyFilter = filter.filter;
        var originalFilter = {
          clientGroupCollections: $scope.clientGroupCollections,
          brands: $scope.customFilters.brands
        }
        companyFilter = $recommendationOrder.getTheSelectedFilterForTheCompanySettings(originalFilter, companyFilter);
      }

      $scope.customfilterdetail = $scope.company.recommendationOrderFilters[index]
      $scope.customFilterIndex = index
      $scope.addCustomFilter = false
      $scope.editCustomFilter = true
    }

    $scope.updateCustomFilter = function (id) {
      var index = _.findIndex($scope.company.recommendationOrderFilters, { _id: id })
      $scope.company.recommendationOrderFilters[index] = angular.copy($scope.customfilterdetail);
      $scope.editCustomFilter = false;
      var body = {
        companyId: $scope.company._id,
        filterId: $scope.company.recommendationOrderFilters[index]._id,
        filter: $scope.company.recommendationOrderFilters[index]
      }
      body.filter = $recommendationOrder.getFilterForUpdateCompanySettings(body.filter);
      $brand.updateCustomFilterInCompany(body).then(function (response) {
      }).catch(function (error) {
        console.error('addCustomFilter error: ', error);
      })
    }

    $scope.changeFillterForSearchQuery = function () {

    }

    $scope.deleteBrand = function (index) {
      if ($scope.company && $scope.company.brands && $scope.company.brands.length) {
        prompt({
          title: $language.translate('DELETE_BRANDS_PRODUCTS'),
          message: $language.translate('YOUR_ABOUT_TO_ARE_YOU_SURE_TO_DELETE_BRANDPRODUCT'),
          buttons: [
            {
              label: $language.translate('DELETE'),
              primary: true
            },
            {
              label: $language.translate('CANCEL'),
              primary: false,
              cancel: true
            }
          ]
        }).then(function (response) {
          if (response && response.primary) {

            var body = {
              companyId: $rootScope.currentCompany._id,
              brandId: $scope.company.brands[index]._id
            }
            $brand.deleteCompanyBrandAndItsProduct(body).then(function (response) {
              console.info('deleteCompanyBrandAndItsProduct called: ', response);
              $scope.company.brands.splice(index, 1);
            }).catch(function (error) {
              console.error('addCustomFilter error: ', error);
            })
          }
        }).catch(function (error) {
        });
      }
    }
    // End CUSTOM FILTER Functions

    $scope.updateLabelPrinter = function () {
      if(!$scope.company.printers)  {
        $scope.company.printers = {
          labelPrinter: {}
        }
      }
      if(!$scope.company.printers.labelPrinter) {
        $scope.company.printers.labelPrinter = {}
      }
      $printer.openSelectPrinterModal($scope.company.printers.labelPrinter)
        .then(function (result) {
          $scope.company.printers.labelPrinter = {
            printerId: result.printNode.printerId,
            printerName: result.printNode.printerName,
            deviceId: result.printNode.deviceId,
            deviceName: result.printNode.deviceName
          }

          if(!$rootScope.currentCompany.printers) {
            $rootScope.currentCompany.printers = {
              labelPrinter: {}
            }
          }
          if(!$rootScope.currentCompany.printers.labelPrinter) {
            $rootScope.currentCompany.printers.labelPrinter = {}
          }

          $rootScope.currentCompany.printers = $scope.company.printers

          $scope.updateCompany('saveAlert')
        })
        .catch(function (reason){
          console.error(reason)
        })
    }
  }])
