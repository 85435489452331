prismanoteApp.controller('shopAnalysisModalController', ['$uibModalInstance', '$scope', '$rootScope', 'currentShopId', '$shop',
  function ($uibModalInstance, $scope, $rootScope, currentShopId, $shop) {
    console.log(' shop-analysis-modal-controller inside ', currentShopId)

    $scope.analysis = []

    $scope.renderModalData = function (shopId) {
      console.log(' ---- renderModalData ---- ')

      $shop.getShopAnalysis(shopId)
        .then(function (analysisResult) {
          console.log(' +++++  analysis ++++++ ', analysisResult)

          var analysisData = JSON.parse(JSON.stringify(analysisResult))

          var analysisLength = analysisData.length || 0
          var currentShopBrands = $rootScope.currentShop.brands

          for (var i = 0; i < analysisLength; i++) {
            var count = 0
            for (var j = 0; j < analysisData[i].companyBrands.length; j++) {
              if (analysisData[i].companyBrands[j] && analysisData[i].companyBrands[j]._id) {
                count = count + analysisData[i].companyBrands[j].totalBrandProductsInShop
                var index = _.findIndex(currentShopBrands, function (o) {
                  if (o && o._id && o._id._id) {
                    return o._id._id.toString() == analysisData[i].companyBrands[j]._id.toString()
                  }
                })
                if (index > -1) analysisData[i].companyBrands[j].isShopBrand = true
                else analysisData[i].companyBrands[j].isShopBrand = false
              }

              if (j === (analysisData[i].companyBrands.length - 1)) analysisData[i].companyBrands[j].total = count
            }

            if (i === (analysisLength - 1)) $scope.analysis = JSON.parse(JSON.stringify(analysisData))
          }

          $scope.count = 0
          if ($scope.analysis) {
            for (var i = 0; i < analysisLength; i++) {
              if ($scope.analysis[i].autoCreated) $scope.count++
            }
          }
        })
        .catch(function (errorShopAnalysis) {
          console.error('Error in get Shop Analysis Merge : ', errorShopAnalysis)
        })
    }

    $scope.cancel = function () {
      $uibModalInstance.close(false)
    }
  }
])
