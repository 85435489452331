prismanoteApp.controller('retailerOtherGiftcardModalController', ['$scope', '$uibModalInstance', '$rootScope', '$transaction', '$language', '$api', 'totalAmount',
  function ($scope, $uibModalInstance, $rootScope, $transaction, $language, $api, totalAmount) {

    $scope.cardData = {
      pincode: null
    }
    $scope.needPin = false
    $scope.amountError = false

    $scope.makeCardName = function (cardName) {
      if (cardName.toLowerCase() === 'vvv cadeaubon') {
        return 'VVV'
      } else {
        return cardName.toUpperCase().replace(' ', '_')
      }
    }

    $scope.getDetails = function (cardNumber, pincode) {
      if (!cardNumber) { return }
      $scope.needPin = false
      $scope.loading = true
      $scope.alert = null
      $api.get('pay/giftcard/info', {
        number: cardNumber,
        shopId: $rootScope.currentShop._id,
        deviceId: $rootScope.currentDevice._id,
        pincode: pincode
      })
        .then(function (result) {
          if (result.data.result && result.data.result.normalizedType === 'fashioncheque' || result.data.result.normalizedType === 'fashion-giftcard') {
            $scope.needPin = true
            if (result.data.result.balance === '-1') {
              $scope.loading = false
              if(pincode) {
                $scope.alert = {
                  type: 'danger',
                  msg: $language.translate('THIS_CARD_IS_NO_LONGER_VALID_PLEASE_CHECK_IT_AT_THE_WEBSITE_OF_THE_CARD_PROVIDER')
                }
              }
              return
            }
          }

          $scope.card = result.data.result

          if($scope.card.balance === '-1') {
            $scope.alert = {
              type: 'danger',
              msg: $language.translate('THIS_CARD_IS_NO_LONGER_VALID_PLEASE_CHECK_IT_AT_THE_WEBSITE_OF_THE_CARD_PROVIDER')
            }
            return
          }
          if (totalAmount <= (parseFloat($scope.card.balance) / 100)) {
            $scope.cardData.amount = parseFloat(totalAmount)
          } else {
            $scope.cardData.amount = parseFloat($scope.card.balance) / 100
          }
          $scope.cardData.type = $scope.makeCardName($scope.card.type)
          if ($scope.cardData.amount > 0) {
            document.getElementById('amount').focus()
          }
          $scope.loading = false
          $scope.checkCardAmount($scope.cardData.amount)
        })
        .catch(function (reason) {
          console.error('Error while fetching giftcard info', reason)
          $scope.loading = false
          $scope.alert = {
            type: 'danger',
            msg: reason
          }
        })
    }

    $scope.useCard = function () {
      $scope.cardData.amount = parseFloat($scope.cardData.amount)
      $scope.cardData.icon = $scope.card.profileIconUrl
      if($scope.needPin && (!$scope.cardData || !$scope.cardData.pincode)) {
        $scope.alert = {
          type: 'danger',
          msg: $language.translate('PLEASE_ENTER_THE_PINCODE_FOR_THIS_CARD')
        }
        return
      }
      if($scope.cardData.amount > (parseFloat($scope.card.balance) / 100)){
        $scope.alert = {
          type: 'danger',
          msg: $language.translate('AMOUNT_ENTERED_IS_HIGHER_THAN_AVAILABLE_BALANCE')
        }
        return
      } else {
        $uibModalInstance.close($scope.cardData)
      }
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss()
    }

    $scope.checkCardAmount = function (amount) {
      $scope.amountError = false
      $scope.alert = null
      $scope.wwftError = false

      if(parseFloat(amount) < 0) {
        $scope.amountError = true
        $scope.alert = {
          type: 'danger',
          msg: $language.translate('NEGATIVE_AMOUNT_IS_NOT_POSSIBLE')
        }
        return
      }

      if(parseFloat(amount) === 0) {
        $scope.amountError = true
        $scope.alert = {
          type: 'warning',
          msg: $language.translate('YOU_CANNOT_ENTER_AMOUNT_ZERO')
        }
        return
      }

      if(parseFloat(amount) > 50) {
        $scope.amountError = true
        $scope.alert = {
          type: 'danger',
          msg: $language.translate('THE_MAXIMUM_REDEEM_AMOUNT_AT_ONCE_IS_50')
        }
        $scope.wwftError = true
        return
      }

      if (parseFloat(amount) > $scope.card.balance / 100) {
        $scope.amountError = true
        $scope.alert = {
          type: 'danger',
          msg: $language.translate('AMOUNT_ENTERED_IS_HIGHER_THAN_AVAILABLE_BALANCE')
        }
      }

    }
  }])
