prismanoteApp.controller('retailerCheckoutController', ['$scope', '$rootScope', '$api', '$stateParams', '$pdf', '$language', '$customer', 'dialogs', '$transaction', '$retailer', '$state', '$printer', '$uibModal', '$q', '$log', '$interval', '$jspm', '$thermalPrint', '$printnode',
  function ($scope, $rootScope, $api, $stateParams, $pdf, $language, $customer, dialogs, $transaction, $retailer, $state, $printer, $uibModal, $q, $log, $interval, $jspm, $thermalPrint, $printnode) {
    $scope.afterSaveOrder = !!($stateParams && $stateParams.saveOrder)
    $scope.payLater = $stateParams.payLater
    $scope.payLaterAmount = $stateParams.payLaterAmount
    $scope.sendingMail = false
    $scope.mailSent = false
    $scope.isValidForCheckout = true
    $scope.totalPaying = 0
    $scope.giftcardUsedUp = false
    $scope.otherGiftCards = []
    $scope.payment = {
      cashAmount: 0,
      cardAmount: 0,
      giftCardAmount: 0,
      payLaterAmount: 0,
      bankAmount: 0,
      insurance: 0
    }
    $scope.giftCard = {
      giftcardNumber: '',
      isValid: false,
      maxAmountCanReedem: 0,
      giftCardErrorMessage: '',
      successMessage: ''
    }
    $scope.cashAmountSuggesions = []
    $scope.cardAmountSuggesions = []
    $scope.giftCardAmountSuggesions = []
    $scope.payLaterAmountSuggesions = []
    $scope.bankAmountSuggestions = []
    $scope.cardPaymentResult = null
    $scope.cardTypeForCardPayment = null
    $scope.transactions = {
      types: [],
      typesId: []
    }
    $scope.initialCountdownValue = 10
    var repairEmailIntervalCanceller = null
    var shopEmailIntervalCanceller = null

    $scope.resetPayment = function () {
      // Reset amount suggestions
      $scope.cashAmountSuggesions = []
      $scope.cardAmountSuggesions = []
      $scope.giftCardAmountSuggesions = []
      $scope.payLaterAmountSuggesions = []
      $scope.bankAmountSuggestions = []

      // Reset paid amount
      $scope.payment = {
        cashAmount: 0,
        cardAmount: 0,
        giftCardAmount: 0,
        payLaterAmount: 0,
        bankAmount: 0
      }

      // Fetch new suggestion based on transaction total amount
      var priceSuggestions = $scope.getSuggestedAmounts($scope.totalAmount)

      // Assign price suggestion to all payment method
      $scope.cashAmountSuggesions = JSON.parse(JSON.stringify(priceSuggestions))
      $scope.cardAmountSuggesions = JSON.parse(JSON.stringify([$scope.totalAmount]))
      // $scope.giftCardAmountSuggesions = JSON.parse(JSON.stringify(priceSuggestions));
      $scope.payLaterAmountSuggesions = JSON.parse(JSON.stringify(priceSuggestions))
      $scope.bankAmountSuggestions = JSON.parse(JSON.stringify(priceSuggestions))

      // Disable next button
      $scope.isValidForCheckout = true

      // Reset 0 to total paiying amount
      $scope.totalPaying = 0
      // Reset giftcard
      $scope.giftCard = {
        giftcardNumber: '',
        isValid: false,
        maxAmountCanReedem: 0,
        giftCardErrorMessage: '',
        successMessage: ''
      }
    }

    function getShop () {
      return $q(function (resolve, reject) {
        if (!$rootScope.currentShop) {
          $retailer.getShop()
            .then(function (shop) {
              $rootScope.currentShop = shop
              return resolve($rootScope.currentShop)
            })
        } else {
          return resolve($rootScope.currentShop)
        }
      })
    }

    $scope.$on('$destroy', function() {
      //Cancel/remove the intervals on scope destroy, otherwise they will live ever for nothing
      if (repairEmailIntervalCanceller) {
        $log.info('repairEmailInterval cancelled')
        $interval.cancel(repairEmailIntervalCanceller);
      }

      if(shopEmailIntervalCanceller) {
        $log.info('shopEmailInterval cancelled')
        $interval.cancel(shopEmailIntervalCanceller)
      }
    })

    $scope.autoRepairEmail = function () {
      var repairs = getTransactionBasedOnType('REPAIR')
      if(!repairs || repairs.length === 0 || $scope.transaction.customer.counter === true) {
        return
      }
      if($rootScope.currentShop.cashRegister.redirect) {
        // No countdown since user will be redirect to main screen directly, send email direct
        $log.info('Repair/Special email send')
        $scope.generateDocument('REPAIR', 'email')
      } else {
        //Start countdown for repair/special
        $log.info('Repair/Special email countdown started')
        $scope.repairEmailCountdownValue = angular.copy($scope.initialCountdownValue)
        repairEmailIntervalCanceller = $interval(function(){
          $scope.repairEmailCountdownValue -= 1
          if($scope.repairEmailCountdownValue === 0) {
            $scope.generateDocument('REPAIR', 'email')
            $interval.cancel(repairEmailIntervalCanceller)
          }
        }, 1000, 11)
      }

    }

    $scope.autoShopPurchaseEmail = function () {
      var purchases = getTransactionBasedOnType('SHOP-PURCHASE')
      if(!purchases || purchases.length === 0 || $scope.transaction.customer.counter === true || $scope.transaction.customer.email.indexOf('autocreated') >= 0) {
        return
      }
      if ($rootScope.currentShop.cashRegister.redirect) {
        // No countdown since user will be redirect to main screen directly, send email direct
        $log.info('shop-purchase/refund email send')
        $scope.generateDocument('SHOP-PURCHASE', 'email')
      } else {
        // Start countdown for shop purchase
        $log.info('shop-purchase/refund countdown started')
        $scope.shopEmailCountdownValue = angular.copy($scope.initialCountdownValue)
        shopEmailIntervalCanceller = $interval(function(){
          $scope.shopEmailCountdownValue -= 1
          if($scope.shopEmailCountdownValue === 0) {
            $scope.generateDocument('SHOP-PURCHASE', 'email')
            $interval.cancel(shopEmailIntervalCanceller)
          }
        }, 1000, 11)
      }
    }

    $scope.mailSendingText = $language.translate('MAIL_SENDING_IN')
    $scope.mailSendText = $language.translate('MAIL_SEND')
    $scope.clickCancelText = $language.translate('CANCEL')
    $scope.secondText = $language.translate('SECOND')
    $scope.secondsext = $language.translate('SECONDS')
    $scope.noCountDown = $language.translate('EMAIL')

    $scope.getEmailButtonText = function (type) {
      if(type === 'SHOP-PURCHASE' || type === 'REFUND') {
        if (shopEmailIntervalCanceller) {
          if($scope.shopEmailCountdownValue > 0) {
            return $scope.mailSendingText + ' ' + $scope.shopEmailCountdownValue + ' ' + ($scope.shopEmailCountdownValue === 1 ? $scope.secondText : $scope.secondsext) + ' - ' + $scope.clickCancelText
          } else {
            return $scope.mailSendText
          }
        } else {
          return $scope.noCountDown
        }
      } else if (type === 'REPAIR' || type === 'SPECIAL' || type === 'ORDER') {
        if (repairEmailIntervalCanceller) {
          if ($scope.repairEmailCountdownValue > 0) {
            return $scope.mailSendingText + ' ' + $scope.repairEmailCountdownValue + ' ' + ($scope.repairEmailCountdownValue === 1 ? $scope.secondText : $scope.secondsext) + ' - ' + $scope.clickCancelText
          } else {
            return $scope.mailSendText
          }
        } else {
          return $scope.noCountDown
        }
      } else {
        return $language.translate('EMAIL')
      }
    }

    $scope.cancelOrSendEmail = function(type){
      if (repairEmailIntervalCanceller || shopEmailIntervalCanceller) {
        if (repairEmailIntervalCanceller && (type === 'REPAIR' || type === 'SPECIAL' || type === 'ORDER')) {
          $interval.cancel(repairEmailIntervalCanceller);
          repairEmailIntervalCanceller = null
        } else {
          $interval.cancel(shopEmailIntervalCanceller)
          shopEmailIntervalCanceller = null
        }
      } else {
        $scope.generateDocument(type, 'email')
      }
    }

    $scope.getTransaction = function () {
      if (!$stateParams || !$stateParams.transactionId) {
        $log.error('No transaction found')
        return
      }

      getShop()
        .then(function (shop) {
          $scope.jspm = !shop.printNode || shop.printNode.enabled === false
          $transaction
            .getTransaction($stateParams.transactionId, false, true, shop._id)
            .then(function (transaction) {
              $scope.transaction = transaction
              $scope.transaction.shopId = transaction.shopId
              $scope.getTotalAmount(true)
              $scope.getTransactionTypes()
              if($stateParams.printReceipt || $stateParams.printReceipt === 'true' || $stateParams.print || $stateParams.print === 'true') {
                $scope.checkThermalPrint($stateParams.merchantReceipt)
                if ($rootScope.currentShop.cashRegister.autoDownloadReceipt && ($scope.transaction.type === 'shop-purchase' || $scope.transaction.type === 'refund')) {
                  $log.info('Generating PDF receipt...')
                  $scope.makeReceipt('document')
                }
                $scope.checkRepairAndSpecialSubTransactions()
                if ($rootScope.currentShop.cashRegister.emailRepairReceipt && $stateParams.payLater === false) {
                  //start repair email countdown
                  $scope.autoRepairEmail()
                }
                if($rootScope.currentShop.cashRegister.emailPurchaseReceipt) {
                  // start purchase email countdown
                  $scope.autoShopPurchaseEmail()
                }
              }
              if($rootScope.currentShop.cashRegister.openDrawerWithoutReceipt && !$rootScope.currentShop.cashRegister.printMerchantReceipt && transaction.receipt && transaction.receipt.payments && transaction.receipt.payments.length > 0) {
                $log.info('Check if drawer needs to be openend')
                var cashPayments = transaction.receipt.payments.filter( function (p) {
                  return p.method.toUpperCase() === 'CASH'
                })
                if(cashPayments && cashPayments.length > 0) {
                  $scope.openCashDrawer()
                }
              }

              $scope.transaction.needToDirectDeductStock = $stateParams.needToDirectDeductStock

              if ($scope.transaction.customer) {
                $scope.emailBackup = $scope.transaction.customer.email
              }

              //$scope.getTotalAmount(true)

              if (transaction && transaction.receipt && transaction.receipt.payments && transaction.receipt.payments.length && $stateParams.payLater === false) {
                var ccvPayments = _.find(transaction.receipt.payments, function (p) {
                  return p.hasOwnProperty('ccvTransactionPending') && !p.hasOwnProperty('amount')
                })
                if(!ccvPayments || ccvPayments.length === 0) {
                  $state.go('retailer.checkout.completed', {
                    transactionId: transaction._id,
                    paymentStatus: null
                  })
                }
              }

              if ($rootScope.currentShop.cashRegister.printGiftcard || $rootScope.currentShop.cashRegister.rePrintGiftcard) {
                if ($scope.orderIsPaidWithGiftcard()) {
                  $log.info('Generating giftcard...')
                  $scope.rePrintGiftcard()
                } else {
                  $scope.downloadGiftcard()
                }
              }
              if ($rootScope.currentShop.cashRegister.redirect && ($scope.transaction.type === 'shop-purchase' || $scope.transaction.type === 'refund') && !$stateParams.payLater) {
                $state.go('retailer.till')
              }
            })
            .catch(function (reason) {
              $log.error(reason)
            })
        })
    }

    $scope.getTotalAmount = function (getSuggestedAmounts) {
      if(!$scope.transaction.oldTill && $stateParams.payLater === false) {
        $scope.totalAmount = $scope.transaction.transactionTotal
        return
      }
      if($scope.payLaterAmount && $scope.payLaterAmount > 0) {
        $scope.totalAmount = $scope.payLaterAmount
        return
      }
      $scope.totalAmount = 0
      var priceSuggestions = $scope.getSuggestedAmounts($scope.totalAmount)
      var counter = 0
      if ($scope.transaction.type === 'refund' && $stateParams.details) {

        for (var i = 0; i < $stateParams.details.length; i++) {
          $scope.totalAmount = $scope.totalAmount + ($stateParams.details[i].productForRefund ? -$stateParams.details[i].refundAmount : $stateParams.details[i].total)
          if (getSuggestedAmounts) {
            counter++
            if (counter === $stateParams.details.length) {
              $scope.cashAmountSuggesions = JSON.parse(JSON.stringify(priceSuggestions))
              $scope.cardAmountSuggesions = JSON.parse(JSON.stringify([$scope.totalAmount]))
              // $scope.giftCardAmountSuggesions = JSON.parse(JSON.stringify(priceSuggestions));
              $scope.payLaterAmountSuggesions = JSON.parse(JSON.stringify(priceSuggestions))
              $scope.bankAmountSuggestions = JSON.parse(JSON.stringify(priceSuggestions))
            }
          }
        }
      } else {
        for (var i = 0; i < $scope.transaction.details.length; i++) {
          $scope.totalAmount += $scope.transaction.details[i].originalTotal
          if (getSuggestedAmounts) {
            counter++
            if (counter === $scope.transaction.details.length) {
              $scope.cashAmountSuggesions = JSON.parse(JSON.stringify(priceSuggestions))
              $scope.cardAmountSuggesions = JSON.parse(JSON.stringify([$scope.totalAmount]))
              $scope.payLaterAmountSuggesions = JSON.parse(JSON.stringify(priceSuggestions))
              $scope.bankAmountSuggestions = JSON.parse(JSON.stringify(priceSuggestions))
              // if ($rootScope.currentShop.cashRegister.paymentMethods && $rootScope.currentShop.cashRegister.paymentMethods.card) {
              //   $scope.setAmount($scope.totalAmount, 'cardAmount')
              //   $scope.resetAmount = true
              // }
            }
          }
        }
      }
    }

    $scope.checkToReset = function () {
      if ($scope.resetAmount) {
        $scope.resetPayment()
        $scope.resetAmount = false
      }
    }

    $scope.getSuggestedAmounts = function (totalAmount) {
      var amounts = 2
      var priceSuggestions = [totalAmount]

      var roundedAmount = $scope.roundAmount(totalAmount, 10)
      for (var i = 0; i < amounts; i++) {
        var plusOrMinus = $scope.transaction.type === 'shop-purchase' ? 1 : -1
        var amount = roundedAmount + (plusOrMinus * (i * 5))

        if (((plusOrMinus === 1 && amount < totalAmount) || (plusOrMinus === -1 && amount > totalAmount)) || amount === totalAmount) {
          amounts += 1
          continue
        } else {
          priceSuggestions.push(amount)
        }
      }

      return priceSuggestions
    }

    $scope.roundAmount = function (amount, round) {
      return Math.round(amount / round) * round
    }

    $scope.back = function () {
      $state.go('retailer.till')
    }

    $scope.selectedAmountIndex = null

    $scope.setAmount = function (amount, payMethod) {
      // $scope.payment[payMethod] = Math.round(amount * 100) / 100
      $scope.payment[payMethod] = amount

      $scope.totalPaying = $scope.payment.cashAmount + $scope.payment.cardAmount + $scope.payment.giftCardAmount + $scope.payment.payLaterAmount + $scope.payment.bankAmount

      if ($scope.totalPaying >= $scope.totalAmount) {
        $scope.isValidForCheckout = false

        if (!$scope.payment.cashAmount) {
          $scope.cashAmountSuggesions = []
        }

        if (!$scope.payment.cardAmount) {
          $scope.cardAmountSuggesions = []
        }

        if (!$scope.payment.giftCardAmount) {
          $scope.giftCardAmountSuggesions = []
        }

        if (!$scope.payment.payLaterAmount) {
          $scope.payLaterAmountSuggesions = []
        }

        if (!$scope.payment.bankAmount) {
          $scope.bankAmountSuggestions = []

        }
      } else {
        $scope.isValidForCheckout = true
        var priceSuggestions = $scope.getSuggestedAmounts($scope.totalAmount - $scope.totalPaying)

        if (!$scope.payment.cashAmount) {
          $scope.cashAmountSuggesions = priceSuggestions
        }

        if (!$scope.payment.cardAmount) {
          $scope.cardAmountSuggesions = [$scope.totalAmount - $scope.totalPaying]
        }

        if (!$scope.payment.giftCardAmount) {
          // $scope.giftCardAmountSuggesions = priceSuggestions;
        }

        if (!$scope.payment.payLaterAmount) {
          $scope.payLaterAmountSuggesions = priceSuggestions
        }

        if (!$scope.payment.bankAmount) {
          $scope.bankAmountSuggestions = priceSuggestions
        }
      }
    }

    $scope.checkout = function () {
      if (!$scope.transaction.receipt) {
        $scope.transaction.receipt = {}
      }
      if (!$scope.transaction.receipt.payments) {
        $scope.transaction.receipt.payments = []
      }
      if (!$scope.transaction.receipt.date) {
        $scope.transaction.receipt.date = new Date()
      }
      if ($stateParams.payLater) {
        // Transaction is paid from the service-item page and the transaction is created with payLater payment
        // So we gonna reset the amount which is 'paid', and add a new payment to its
        if (!$scope.payLaterAmount || $scope.payLaterAmount === 0) {
          $scope.transaction.receipt.payments[0].amount = 0
        } else {
          // We don't know which payLater transaction is paid, so we gonna set all those payments to 0
          for (var i = 0; i < $scope.transaction.receipt.payments.length; i++) {
            if($scope.transaction.receipt.payments[i].method.toUpperCase() === 'PAYLATER' && $scope.transaction.receipt.payments[i].amount > 0) {
              $scope.transaction.receipt.payments[i].amount = 0
            }
          }
        }
      }

      if ($scope.payment.cardAmount && $scope.payment.cardAmount > 0) {
        var terminal = $rootScope.currentShop.cashRegister.terminal
        if (terminal.provider === 'paynl' && terminal.serviceId && terminal.apiToken && terminal.apiCode && terminal.terminalId) {
          $scope.handlePaynlPayment()
        } else if (terminal.provider === 'ccv' && terminal.apiToken && terminal.apiCode && terminal.terminalId && terminal.ip) {
          $scope.handleCCVPayment()
        } else {
          $scope.openManualPinModal()
        }
      } else {
        $scope.completePayment()
      }
    }

    $scope.openManualPinModal = function () {
      var pinModal = $uibModal.open({
        templateUrl: '../views/modal/payment-modal.html',
        controller: 'retailerPaymentModalController',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          transaction: function () {
            return $scope.transaction
          },
          payment: function () {
            return null
          },
          amount: function () {
            return $scope.payment.cardAmount
          },
          manual: function () {
            return true
          }
        }
      })

      pinModal.result.then(function (result) {
        if (result.success) {
          if (result.partialPayment) {
            $scope.transaction.receipt.payments.push({
              date: new Date(),
              amount: result.paidAmount,
              method: 'card',
              cardName: result.card,
              deposit: $stateParams.deposit,
              change: 0,
              paymentHash: null
            })

            $scope.totalAmount -= result.paidAmount
            $scope.resetPayment()
          } else {
            $scope.cardTypeForCardPayment = result.card
            // $scope.completePayment('card', null, result.card);
            $scope.completePayment()
          }
        }
      }, function () {

      })
    }

    $scope.handlePaynlPayment = function () {
      $log.info('Cart payment choosen')
      var pinTest = window.localStorage.getItem('pinTestMode')
      var testMode = function () {
        if (!pinTest) {
          return false
        }
        if (pinTest) {
          return pinTest === 'false'
        } else {
          return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === 'goedenoot.nl' || window.location.hostname === 'prismanote.it'
        }
      }

      $log.info('Creating instore transaction for pay')
      $log.info('TestMode is', pinTest)
      $api.post('pay/instore-transaction', {
        shopId: $rootScope.currentShop._id,
        amount: $scope.payment.cardAmount,
        transactionInfo: {
          useExchangeUrl: true,
          transactionId: $scope.transaction._id,
          description: $language.translate('TRANSACTION') + ' ' + $scope.transaction.number,
          enduser: $scope.transaction.customer && !$scope.transaction.customer.counter ? {
            lastName: ($scope.transaction.customer.prefix ? $scope.transaction.customer.prefix + ' ' : '') + ($scope.transaction.customer.lastName ? $scope.transaction.customer.lastName : 'Klant'),
            dob: $scope.transaction.customer.dateOfBirth ? $scope.transaction.customer.dateOfBirth : null,
            emailAddress: $scope.transaction.customer.email ? $scope.transaction.customer.email : null,
            gender: $scope.transaction.customer.gender ? $scope.transaction.customer.gender.charAt(0).toUpperCase() : null,
            phoneNumber: $scope.transaction.customer.phone && ($scope.transaction.customer.phone.mobilePhone || $scope.transaction.customer.phone.landLine) ? ($scope.transaction.customer.phone.mobilePhone ? $scope.transaction.customer.phone.mobilePhone : $scope.transaction.customer.phone.landLine) : null
          } : null,
          product: $scope.transaction.details.map(function (p) {
            return {
              id: p._id,
              name: p.name,
              price: p.price * 100,
              qty: p.quantity,
              tax: p.priceVat
            }
          }),
          testMode: testMode(),
          language: $rootScope.language
        }
      })
        .then(function (payment) {
          $log.info('payment which is returned from pay.nl', payment)
          $log.info('Now opening the payment modal')
          var paymentModal = $uibModal.open({
            templateUrl: '../views/modal/payment-modal.html',
            controller: 'retailerPaymentModalController',
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
            resolve: {
              transaction: function () {
                return $scope.transaction
              },
              payment: function () {
                return payment.data
              },
              amount: function () {
                return $scope.payment.cardAmount
              },
              manual: function () {
                return false
              }
            }
          })

          paymentModal.result.then(function (result) {
            $log.info('payment modal is closed with this as result', result)
            if (result.result.success) {
              // Changed to manual pin
              $scope.cardTypeForCardPayment = result.card
              $scope.completePayment()
            } else if (result.result.approved === '1') {
              $scope.cardPaymentResult = result.result
              $scope.paymentData = result.payment

              $scope.completePayment()
            }
          }, function () {
            $log.warning('payment modal is dismissed')
          })
        })
        .catch(function (reason) {
          $log.error('Error while creating instore payment', reason)
          $log.error('REASON', reason)
          dialogs.error(undefined, reason, { size: 'sm' })
        })
    }

    $scope.handleCCVPayment = function () {
      $log.info('Creating instore transaction for ccv')

      $api.post('ccv/instore', {
        shopId: $rootScope.currentShop._id,
        transactionId: $scope.transaction._id,
        data: {
          amount: $scope.payment.cardAmount,
          reference: $scope.transaction.number,
          description: $language.translate('TRANSACTION') + ' ' + $scope.transaction.number,
          language: $rootScope.language
        }
      }, null, 2)
        .then(function (payment) {
          window.location = payment.data.payment.payUrl
        })
        .catch(function (reason) {
          $log.error('Error while creating instore payment', reason)
          dialogs.error(undefined, reason.message + ': ' + reason.failureCode, { size: 'sm' })
        })
    }

    $scope.checkThermalPrint = function (merchantReceipt) {
      if(merchantReceipt) {
        $scope.printMerchantReceipt(merchantReceipt)
      }
      if (!$stateParams.skipPrint &&
        ($scope.transaction.type === 'shop-purchase' && $rootScope.currentShop.cashRegister.printMerchantReceipt) ||
        ($scope.transaction.type === 'repair' && $rootScope.currentShop.cashRegister.printRepairReceiptThermal) ||
        ($scope.transaction.type === 'special' && $rootScope.currentShop.cashRegister.printSpecialReceiptThermal)) {
        $log.info('Printing thermal receipt...')
        $scope.printThermal(null)
      }
    }

    $scope.checkRepairAndSpecialSubTransactions = function () {

      //possible fix for unneccesary receipts being printed when repair/special is paid
      var receiptNeeded = true
      if($scope.transaction.status && ($scope.transaction.type === 'repair' || $scope.transaction.type === 'special')) {
        if($transaction.getStatus($scope.transaction.status) > 2) {
          receiptNeeded = false
        }
      }

      if(receiptNeeded && $rootScope.currentShop.cashRegister.autoDownloadRepairDocument && (($stateParams.printReceipt || $stateParams.printReceipt === 'true') || ($stateParams.print || $stateParams.print === 'true') ) ) {
        if($scope.transaction.type === 'repair' || $scope.transaction.type === 'special') {
          $scope.downloadRepairSpecialDocument($scope.transaction, $scope.transaction.type === 'special')
        } else {
          var repairs = getTransactionBasedOnType('REPAIR')
          var specials = getTransactionBasedOnType('SPECIAL')
          var transactions = repairs.concat(specials)
          if (!transactions || transactions.length === 0) {
            return
          }

          $scope.bulkPrintRepairSpecialDocuments(transactions, true, 'both', repairs, specials)
        }
      }
    }

    $scope.bulkPrintRepairSpecialDocuments = function (transactions, checkSave, mode, repairs, specials) {
      var documents = []
      var counter = 0

      if(!mode || mode === '') {
        mode = 'both'
      }

      $scope.getPageDefinition('REPAIR/SPECIAL')
        .then(function(pageDef) {
          var skipSave = true

          if(checkSave) {
            skipSave = pageDef.autoPrint
          }

          for(var i = 0; i < transactions.length; i++) {
            var transaction = transactions[i]
            //Don't print transactions once they fully paid and are are no other transactions
            if(transaction.totalEarning >= transaction.transactionTotal && (((repairs && repairs.length > 0 ? repairs.length : 0) + (specials && specials.length > 0 ? specials.length : 0)) === ($scope.transactions.typesId.length - ((repairs ? repairs.length : 0) + (specials ? specials.length : 0))))) {
              continue
            }
            var fileName = (transaction.number ? transaction.number + '_both_' : 'repair_') + 'receipt.pdf'
            if(transaction.type !== 'repair') {
              fileName = 'order_receipt.pdf'
            }
            if( typeof transaction === 'string') {
              transaction = {
                _id: transaction
              }
            }

            $pdf.downloadPdf((pageDef.useRepairBagReceipt && pageDef.useRepairBagReceipt === true ? 'generate-repair-bag-document' : 'generate-repair-document'), {
              transaction: transaction._id,
              mode: mode,
              language: $rootScope.language,
              showComment: true,
              documentName: 'REPAIR/SPECIAL',
              repairBagSkipPrint: false,
              deviceId: $rootScope.currentDevice._id,
              shopId: $rootScope.currentShop._id,
              documentId: pageDef.documentId
            }, fileName, 'arraybuffer', true, skipSave)
              .then(function (file) {
                if($scope.jspm) {
                  documents.push(file)
                  counter++
                  send()
                } else {
                  var reader = new FileReader()
                  reader.readAsDataURL(file)
                  reader.onloadend = function () {
                    documents.push(reader.result)
                    counter++
                    send()
                  }
                }

                function send() {
                  if (counter === transactions.length) {

                    if ($scope.jspm) {
                      $jspm.bulkPrintPDF(documents, pageDef)
                        .then(function () {
                        })
                        .catch(function (reason) {
                          $log.error('Error while bulk print PDF', reason)
                        })
                    } else {
                      for(var i = 0; i < documents.length; i++) {
                        $printnode.printPdfFile(documents[i], pageDef.printNode.printerId, pageDef.printNode.deviceId, 1, {
                          title: 'Bulk reparatie',
                          tray: pageDef.paperTray,
                          paper: pageDef.printerPageFormat,
                          rotate: pageDef.rotation
                        })
                          .then(function (result) {
                            console.log('result', result)
                          })
                          .catch(function (reason) {
                            console.error(reason)
                          })
                      }
                    }
                  }
                }
              })
              .catch(function (reason) {
                $log.error('Error while generating Repair/Special document', reason)
                $scope.alert = {
                  type: 'danger',
                  msg: reason
                }
              })
          }
        })
        .catch(function(reason) {
          $log.error('Error while getting page definition for repair/special', reason)
        })
    }

    $scope.downloadRepairSpecialDocument = function(transaction, special, skipPrint, mode) {
      if(!mode || mode === '') {
        mode = 'both'
      }

      if(typeof transaction === 'string') {
        transaction = {
          _id: transaction
        }
      }

      $printer.getPageDefinition('REPAIR/SPECIAL')
        .then(function (doc) {
          if(doc.autoPrint && !skipPrint) {
            skipPrint = false
          } else if (skipPrint === null || typeof skipPrint === 'undefined') {
            skipPrint = true
          }
          var fileName = (transaction.number ? transaction.number + '_both_' : 'repair_') + 'receipt.pdf'
          if(special) {
            fileName = 'order_receipt.pdf'
          }

          $pdf.downloadPdf((doc.useRepairBagReceipt && doc.useRepairBagReceipt === true ? 'generate-repair-bag-document' : 'generate-repair-document'), {
            transaction: transaction._id,
            mode: mode,
            language: $rootScope.language,
            showComment: true,
            documentName: 'REPAIR/SPECIAL',
            repairBagSkipPrint: skipPrint,
            deviceId: $rootScope.currentDevice._id,
            shopId: $rootScope.currentShop._id,
            documentId: doc.documentId
          }, fileName, 'arraybuffer', skipPrint, !skipPrint)
            .then(function () {
            })
            .catch(function (reason) {
              $log.error('Error while generating Repair/Special document', reason)
              $scope.alert = {
                type: 'danger',
                msg: reason
              }
            })

        })
        .catch( function (reason) {
          console.error('ERROR_WHILE_FETCHING_DOCUMENT', reason)
        })



    }

    $scope.redirectAfterTransaction = function () {
      if ($rootScope.currentShop.cashRegister.autoDownloadReceipt) {
        $log.info('Generating PDF receipt...')
        $scope.makeReceipt('document')
      }
      $scope.checkThermalPrint()
      if ($rootScope.currentShop.cashRegister.redirect && ($scope.transaction.type === 'shop-purchase' || $scope.transaction.type === 'refund') && !$stateParams.payLater) {
        $state.go('retailer.till')
      } else {
        $state.go('retailer.checkout.completed', { transactionId: $scope.transaction._id, paymentStatus: $scope.cardPaymentResult ? $scope.cardPaymentResult : null })
      }
    }

    $scope.completePayment = function () {
      // Add cash object into receipt.payments array if cashamount is more than 0
      if ($scope.payment.cashAmount) {
        $scope.transaction.receipt.payments.push({
          date: new Date(),
          amount: $scope.payment.cashAmount,
          method: 'cash',
          cardName: null,
          deposit: $stateParams.deposit,
          change: 0,
          paymentHash: null
        })
      }

      // Add giftcard object into receipt.payments array if giftcard is more than 0
      if (
        $scope.giftCard.isValid &&
        $scope.giftCard.maxAmountCanReedem &&
        $scope.giftCard.giftcardNumber &&
        $scope.payment.giftCardAmount
      ) {
        if ($scope.payment.giftCardAmount > $scope.giftCard.maxAmountCanReedem) {
          $scope.giftCard.giftCardErrorMessage = "Sorry you can't redeem more than: " + $scope.giftCard.maxAmountCanReedem
        } else {
          $scope.transaction.isGiftCardAvailabel = true
          $scope.transaction.giftcardNumber = $scope.giftCard.giftcardNumber
          $scope.transaction.giftcardAmount = $scope.payment.giftCardAmount

          $scope.transaction.receipt.payments.push({
            date: new Date(),
            amount: $scope.payment.giftCardAmount,
            method: 'GIFTCARD',
            giftcardNumber: $scope.giftCard.giftcardNumber,
            cardName: null,
            deposit: $stateParams.deposit,
            change: 0,
            paymentHash: null
          })
        }
      }

      // Add paylater object into receipt.payments array if paylater is more than 0
      if ($scope.payment.payLaterAmount) {
        
        //only apply this solution to tierelantijn
        if($rootScope.currentShop.nameSlug === 'tierelantijn') {
          var restPaymentToPositiveVal = Math.abs($scope.payment.payLaterAmount)
          var restPaymentIsLessThanACent = (restPaymentToPositiveVal > 0 && restPaymentToPositiveVal < 1) ? Math.floor(Math.log10(restPaymentToPositiveVal)) < -2 : false
          if(!restPaymentIsLessThanACent) {
            $scope.transaction.receipt.payments.push({
              date: new Date(),
              amount: $scope.payment.payLaterAmount,
              method: 'payLater',
              cardName: null,
              deposit: $stateParams.deposit,
              change: 0,
              paymentHash: null
            })
          }
        } else {
          $scope.transaction.receipt.payments.push({
            date: new Date(),
            amount: $scope.payment.payLaterAmount,
            method: 'payLater',
            cardName: null,
            deposit: $stateParams.deposit,
            change: 0,
            paymentHash: null
          })
        }
      }

      if ($scope.payment.bankAmount) {
        $scope.transaction.receipt.payments.push({
          date: new Date(),
          amount: $scope.payment.bankAmount,
          method: 'bankAmount',
          cardName: null,
          deposit: $stateParams.deposit,
          change: 0,
          paymentHash: null,
          bankPaymentAmount: $scope.payment.bankAmount,
          bankPaymentAcknowledgement: false,
          bankPaymentConfirmDate: null
        })
      }

      // Add card object into receipt.payments array if cardAmount is more than 0
      if ($scope.payment.cardAmount) {
        $scope.transaction.receipt.payments.push({
          date: new Date(),
          amount: $scope.payment.cardAmount,
          method: 'card',
          cardName: $scope.cardTypeForCardPayment ? $scope.cardTypeForCardPayment : ($scope.cardPaymentResult && $scope.cardPaymentResult.cardbrandlabelname) ? $scope.cardPaymentResult.cardbrandlabelname : null,
          deposit: $stateParams.deposit,
          change: 0,
          paymentHash: $scope.cardPaymentResult && $scope.cardPaymentResult.ssai ? $scope.cardPaymentResult.ssai : null,
          payOrderId: $scope.paymentData ? $scope.paymentData.transactionId : null
        })
      }

      $scope.transaction.receipt.status = 'paid'
      $scope.transaction.concept = false
      $scope.transaction.shopId = { _id: $scope.transaction.shopId._id }

      $transaction
        .updateTransaction($scope.transaction, true)
        .then(function () {

          if ($scope.otherGiftCards && $scope.otherGiftCards.length > 0) {
            function checkLoop (counter) {
              if ($scope.giftcardsErrors && $scope.giftcardsErrors.length > 0) {
                $scope.otherGiftCards = []
                var otherGiftcardErrorModal = $uibModal.open({
                  templateUrl: '../views/modal/retailer-other-giftcard-error-modal.html',
                  controller: 'retailerOtherGiftcardErrorModalController',
                  size: 'lg',
                  backdrop: 'static',
                  keyboard: false,
                  resolve: {
                    errors: function () {
                      return $scope.giftcardsErrors
                    }
                  }
                })
                otherGiftcardErrorModal.result.then(function (result) {
                }, function () {

                })
              } else {
                $transaction
                  .updateTransaction($scope.transaction, true)
                  .then(function () {
                    $scope.redirectAfterTransaction()
                  })

              }

            }
            var counter = 0
            $scope.giftcardsErrors = []

            $api.post('pay/giftcard/charge', {
              shopId: $rootScope.currentShop._id,
              cards: $scope.otherGiftCards
            })
              .then(function (response) {
                var successCounter = 0
                var success = response.data.success

                for(var i = 0; i < success.length; i++){
                  var res = success[i]
                  $log.info(res.result.cardName + ' (' + res.card.cardNumber + ') is charged with ' + res.card.amount)

                  $scope.transaction.receipt.payments.push({
                    date: new Date(),
                    amount: res.card.amount,
                    method: 'GIFTCARD',
                    cardName: res.card.type,
                    deposit: $stateParams.deposit,
                    change: 0,
                    paymentHash: null
                  })

                  successCounter++
                  if (successCounter === success.length) {
                    counter++
                    checkLoop(counter)
                  }
                }
              })
              .catch(function (reason) {
                var errorCounter = 0
                var error = reason.data.error
                for (var i = 0; i < error.length; i++) {
                  var res = error[i]
                  $scope.giftcardsErrors.push({
                    cardNumber: res.card.cardNumber,
                    amount: res.card.amount,
                    reason: res.reason
                  })
                  errorCounter++
                  if (errorCounter === error.length) {
                    counter++
                    checkLoop(counter)
                  }
                }
              })
          } else {
            $scope.redirectAfterTransaction()
          }
        })
        .catch(function (reason) {
          $log.error('Error while updating transaction', reason)
        })
    }

    $scope.checkCCVPayment = function () {
      $scope.paymentChecking = true
      $scope.actionNeeded = false
      getShop()
        .then(function (shop) {
          $api.get('ccv/handle/' + $stateParams.transactionId + '/' + shop._id, null, null, 2)
            .then(function (response) {
              $scope.paymentChecking = false
              if(response.status === 206) {
                $scope.paymentError = {
                  type: 'warning',
                  msg: $language.translate(response.data.message)
                }
                $scope.showRepay = true
                $scope.transaction = response.data.transaction

                return
              }

              if (response.data.askCustomerSignature || response.data.askCustomerIdentification) {
                $scope.actionNeeded = true
                $scope.paymentError = {
                  type: 'warning',
                  msg: $language.translate(response.data.result)
                }
                $printer.printShopReceipt({
                  shop: $rootScope.currentShop,
                  transaction: response.data.transaction
                })
                  .then(function (result) {
                    $printer.printShopReceipt({
                      shop: $rootScope.currentShop,
                      transaction: response.data.transaction
                    })
                      .then(function (result2) {
                      })
                      .catch(function (reason2) {
                        $log.error('Something went wrong in $printer', reason2)
                      })
                  })
                  .catch(function (reason) {
                    $log.error('Something went wrong in $printer', reason)
                  })
              }else {
                $state.go('retailer.checkout.completed', { transactionId: $stateParams.transactionId })
              }
            })
            .catch(function (reason) {
              $log.error('Error while checking payment', reason)
              $scope.paymentChecking = false
              $scope.paymentFailed = reason.reason === 'PAYMENT_FAILED'
              $scope.paymentError = {
                type: 'danger',
                msg: $language.translate(reason.reason)
              }
            })
        })
        .catch(function (reason) {
          $log.error('error while fetching shop', reason)
        })
    }

    $scope.completeCCVPayment = function () {
      $state.go('retailer.checkout.completed', { transactionId: $stateParams.transactionId, skipPrint: true })
    }

    $scope.ccvPayAgain = function () {
      console.log("$SCOPE.TRANSACTION", $scope.transaction)
      $state.go('retailer.till', {
        transactionId: $scope.transaction._id,
        concept:true
      })
    }

    $scope.backToTill = function () {
      $state.go('retailer.till')
    }

    $scope.newSale = function () {
      $state.go('retailer.till')
    }

    $scope.openTransaction = function (id) {
      var type = $scope.transaction.type
      var old = $scope.transaction.oldTill
      $transaction.openTransaction(id, type, old)
    }

    $scope.getChange = function () {
      if (!$scope.transaction || !$scope.totalAmount) {
        return
      }
      var totalPaid = 0; var counter = 0
      var totalAmount = $scope.totalAmount < 0 ? $scope.totalAmount * -1 : $scope.totalAmount
      for (var i = 0; i < $scope.transaction.receipt.payments.length; i++) {
        //if($scope.transaction.receipt.payments[i].method !== 'payLater'){
        if($scope.transaction.receipt.payments[i].amount >= 0) {
          totalPaid += $scope.transaction.receipt.payments[i].amount
        }
        //}
        counter++
        if (counter === $scope.transaction.receipt.payments.length) {
          totalPaid = totalPaid < 0 ? totalPaid * -1 : totalPaid
          return totalPaid - totalAmount
        }
      }
    }

    $scope.mailReceipt = function () {
      $scope.makeReceipt('string')
    }

    $scope.saveCustomer = function () {
      $scope.transaction.customer.email = $scope.emailBackup
      $customer.updateCustomer($scope.transaction.customer)
        .then(function (result) {
        })
        .catch(function (reason) {
          $log.error('Error while updating customer', reason)
          dialogs.error(undefined, $language.translate('ERROR_WHILE_UPDATING_CUSTOMER'), { size: 'sm' })
        })
    }

    $scope.openCashDrawer = function () {
      if($rootScope.currentShop.newPrinter === true) {
        $thermalPrint.openDrawer(true)
          .then(function () {
            toastr.success($language.translate('CASH_DRAWER_OPENEND'))
          })
          .catch(function (reason) {
            $log.error('Error while opening cash drawer', reason)
            toastr.error($language.translate('ERROR_WHILE_OPENING_DRAWER'))
          })
      } else {
        $printer.openCashDrawer()
          .then(function () {
            toastr.success($language.translate('CASH_DRAWER_OPENEND'))
          })
          .catch(function (reason) {
            $log.error('Error while opening cash drawer', reason)
            toastr.error($language.translate('ERROR_WHILE_OPENING_DRAWER'))
          })
      }
    }

    $scope.printMerchantReceipt = function () {
      $printer.checkEnabled($rootScope.currentShop)
        .then(function() {
          //Get latest payment
          var payment = $scope.transaction.receipt.payments[$scope.transaction.receipt.payments.length -1]
          if(payment.merchantTicket) {
            $thermalPrint.printMerchantPinReceipt(payment.merchantTicket)
          }
        })
        .catch(function(reason) {
          $log.error(reason)
        })

    }

    $scope.printThermal = function (transaction, quantity) {
      if (!transaction) {
        transaction = $scope.transaction
      }
      if(!quantity) {
        quantity = 1
      }
      $printer.checkEnabled($rootScope.currentShop)
        .then(function () {
          if(transaction.type === 'repair' || transaction.type === 'special') {
            $printer.printRepairSpecialDouble(transaction, transaction.type === 'repair')
              .then(function () {
                toastr.success($language.translate('PRINT_SUCCESSFUL'))
              })
              .catch(function (reason) {
                $log.error('Something went wrong while printing repair receipt', reason)
                toastr.error($language.translate('ERROR_WHILE_PRINTING'))
              })
          }else if(transaction.type === 'giftcard') {
            $printer.printGiftcard(transaction.transactionTotal - transaction.giftCardReedemAmount, transaction.special.repairNumber, transaction.dateCreated)
              .then(function() {
                toastr.success($language.translate('PRINT_SUCCESSFUL'))
              })
              .catch(function(reason){
                $log.error('Something went wrong while printing repair receipt', reason)
                toastr.error($language.translate('ERROR_WHILE_PRINTING'))
              })
          }else {
            $printer.printShopReceipt({
              shop: $rootScope.currentShop,
              transaction: transaction,
              quantity: quantity
            })
              .then(function (result) {
                toastr.success($language.translate('PRINT_SUCCESSFUL'))
              })
              .catch(function (reason) {
                $log.error('Something went wrong while printing shop receipt', reason)
                toastr.error($language.translate('ERROR_WHILE_PRINTING'))
              })
          }
        })
        .catch(function (reason) {
          $log.error(reason)
        })
    }

    $scope.makeReceipt = function (type, skipPrint) {
      // type string == mail, type document == pdf
      if (type === 'string') {
        $scope.sendingMail = true
        $scope.mailSent = false
      }
      // if(typeof skipPrint === 'undefined') {
      //   skipPrint = true
      // }
      var paymethods = $scope.transaction.receipt.payments.map(function (item) {
        return {
          method: $language.translate(item.method.toUpperCase()),
          amount: item.amount,
          deposit: $stateParams.deposit,
          change: $scope.amount - $scope.totalAmount,
          date: new Date()
        }
      })
      $printer.getPageDefinition('TRANSACTION')
        .then(function(doc) {

          if (doc.autoPrint && !skipPrint) {
            skipPrint = false
          } else if(typeof skipPrint === 'undefined') {
            skipPrint = true
          }
          var refundCount = $scope.transaction.details.filter( function(d) {
            return d.type === 'refund'
          }).length

          $pdf.downloadPdf('generate-shop-receipt', {
            transactionId: $scope.transaction.parentTransactionId && $scope.transaction.parentTransactionId.type === 'shop-purchase' && refundCount === 0 ? $scope.transaction.parentTransactionId._id : $scope.transaction._id,
            email: $scope.transaction.customer.email,
            shopId: $rootScope.currentShop._id,
            payMethods: paymethods,
            typeDocument: type,
            documentName: 'TRANSACTION',
            deviceId: $rootScope.currentDevice._id,
            combine: refundCount === 0,
            documentId: doc.documentId
          }, $scope.transaction.number + '.pdf', type === 'document' ? 'arraybuffer' : 'string', skipPrint, type === 'string')
            .then(function (data) {
              if (type !== 'document') {
                $scope.sendingMail = false
                $scope.mailSent = true
                toastr.success($language.translate('MAIL_SENT'))
              }
            })
            .catch(function (reason) {
              $log.error('Error while generating receipt', reason)
              toastr.error($language.translate('ERROR_WHILE_PRINTING_OR_SENDING'))
              $scope.sendingMail = false
              $scope.mailSent = false
            })
        })
        .catch(function (reason) {
          $log.error('Error while fetching transaction definition for printing', reason)
        })
    }

    $scope.downloadGiftcard = function (_id) {
      if ((!$scope.transaction.parentTransactionId || $scope.transaction.parentTransactionId.type !== 'giftcard' || !$scope.transaction.parentTransactionId._id) && !_id) {
        return
      }

      $printer.getPageDefinition('GIFTCARD')
        .then(function(doc) {
          var skipPrint = true
          if(doc.autoPrint) {
            skipPrint = false
          }
          $pdf.downloadPdf('generate-giftcard', {
            shopId: $rootScope.currentShop._id,
            transactionId: _id || $scope.transaction.parentTransactionId._id,
            documentName: 'GIFTCARD',
            deviceId: $rootScope.currentDevice._id,
            documentId: doc.documentId
          }, $language.translate('GIFTCARD') + '.pdf', null, skipPrint)
            .then(function () {

            })
            .catch(function (reason) {
              $log.error('Error while generating giftcard', reason)
            })
        })
        .catch(function (reason) {
          $log.error('Error while fetching giftcard page definition for printing', reason)
        })
    }

    $scope.transactionPointsText = function () {
      var result = ''
      if ($scope.transaction.pointsEarned > 0) {
        result = $scope.transaction.pointsEarned + ' ' + $language.translate('POINTS_EARNED') + ' - '
      }
      if ($scope.transaction.customer.points.spendable > 0 && $scope.transaction.customer.points.spendable !== $scope.transaction.pointsEarned) {
        result = result + ' ' + $language.translate('POINTS_BALANCE') + ': ' + $scope.transaction.customer.points.spendable
      }
      return result
    }

    // Check about giftCard number is valid or not
    $scope.checkForGiftCard = function (event) {
      event.preventDefault()
      if ($scope.giftCard.isValid) {
        // Giftcard already applied. User clicked on remove button
        $scope.giftCard = {
          giftcardNumber: '',
          isValid: false,
          maxAmountCanReedem: 0,
          giftCardErrorMessage: '',
          successMessage: ''
        }

        $scope.setAmount(0, 'giftCardAmount')
      } else {
        $scope.giftCard.giftcardNumber = $scope.giftCard.giftcardNumber.replace('G-', '')
        $scope.giftCard.giftCardErrorMessage = ''
        $scope.giftCard.isValid = false
        $scope.giftCard.maxAmountCanReedem = ''

        // First we will check giftcard number is exist or not
        if (!$scope.giftCard.giftcardNumber) {
          $scope.giftCard.giftCardErrorMessage = 'Please enter giftcard number'
        } else {
          $api.get('v2/valid/giftcard/' + $scope.giftCard.giftcardNumber + '/' + $rootScope.currentShop._id)
            .then(function (response) {
              $scope.giftCard.successMessage = response.data.message
              $scope.giftCard.isValid = true
              $scope.giftCard.maxAmountCanReedem = response.data.result.canMaxReedem
              var existingPayByOtherMethods = $scope.payment.cashAmount + $scope.payment.cardAmount + $scope.payment.payLaterAmount

              $scope.setAmount(
                ($scope.totalAmount - existingPayByOtherMethods) > response.data.result.canMaxReedem ? response.data.result.canMaxReedem : ($scope.totalAmount - existingPayByOtherMethods),
                'giftCardAmount'
              )
            })
            .catch(function (error) {
              $log.error('Error when validating giftcard', error)
              $scope.giftCard.giftCardErrorMessage = error.data.message || 'Something went wrong'
            })
        }
      }
    }

    $scope.openGenerateGiftcardModal = function () {
      var giftcardModal = $uibModal.open({
        templateUrl: '../views/modal/retailer-generate-giftcard-modal.html',
        controller: 'generateGiftcardModalController',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          transaction: function () {
            $scope.transaction.totalAmount = $scope.totalAmount
            return $scope.transaction
          },
          taxes: function () {
            return null
          },
          printGiftcard: function () {
            return false
          },
          amount: function () {
            return null
          }
        }
      })

      giftcardModal.result.then(function (result) {
        if (result.amount && result.amount < ($scope.totalAmount * -1)) {
          $scope.transaction.receipt.payments.push({
            date: new Date(),
            amount: result.amount,
            method: 'GIFTCARD',
            giftcardNumber: result.number,
            cardName: null,
            deposit: $stateParams.deposit,
            change: 0,
            paymentHash: null
          })
          $scope.downloadGiftcard(result.giftCardId)
          $scope.totalAmount += result.amount
          $scope.resetPayment()
        } else {
          $scope.payment = {
            cashAmount: 0,
            cardAmount: 0,
            giftCardAmount: 0,
            payLaterAmount: 0,
            bankAmount: 0
          }
          $scope.transaction.receipt.payments.push({
            date: new Date(),
            amount: result.amount,
            method: 'GIFTCARD',
            giftcardNumber: result.number,
            cardName: null,
            deposit: $stateParams.deposit,
            change: 0,
            paymentHash: null
          })
          $scope.downloadGiftcard(result.giftCardId)
          $scope.completePayment()
        }
      }, function () {

      })
    }

    $scope.orderIsPaidWithGiftcard = function () {
      // Return true to enable the reprint giftcard button
      if ($scope.transaction && $scope.transaction.receipt && $scope.transaction.receipt.payments && $scope.transaction.receipt.payments.length > 0) {
        var index = _.findIndex($scope.transaction.receipt.payments, function(p){
          return p.method.toLowerCase() === 'giftcard' && p.method.cardName === ''
        })
        if (index >= 0) {
          $scope.giftCardNumber = $scope.transaction.receipt.payments[index].giftcardNumber
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }

    $scope.rePrintGiftcard = function () {
      $scope.alert = null
      $api.get('valid/giftcard/' + $scope.giftCardNumber + '/' + $rootScope.currentShop._id, null, null, 2)
        .then(function (giftcardRes) {
          if (giftcardRes.data.result.canMaxReedem > 0) {
            $scope.downloadGiftcard(giftcardRes.data.result._id)
          }
        })
        .catch(function (reason) {
          $log.error('Error while fetching giftcard', reason)
          if (reason.status !== 422) {
            $scope.alert = {
              type: 'danger',
              msg: $language.translate(reason.data.message)
            }
          }
        })
    }

    $scope.openOtherGiftcardModal = function () {
      $scope.resetPayment()
      var otherGiftcardModal = $uibModal.open({
        templateUrl: '../views/modal/retailer-other-giftcard-modal.html',
        controller: 'retailerOtherGiftcardModalController',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          totalAmount: function () {
            return Math.round($scope.totalAmount * 100) / 100
          }
        }
      })
      otherGiftcardModal.result.then(function (result) {
        $scope.totalAmount -= result.amount
        if($scope.totalAmount > 0) {
          $scope.otherGiftCards.push(result)
          $scope.resetPayment()
        } else {
          $scope.cardTypeForCardPayment = result.type
          $scope.otherGiftCards.push(result)
          $scope.completePayment()
        }

      }, function () {

      })
    }

    $scope.checkTypesPages = function (types) {
      var autoPrint = false
      var printerSet = false
      if($scope.transactions.types.length === types.length) {
        return
      }

      for(var i = 0; i < types.length; i++) {
        $scope.getPageDefinition(types[i].type)
          .then(function (doc){
            if($scope.jspm) {
              if(doc.printerName && doc.printerName !== '') {
                printerSet = true
              }
            } else {
              if(doc.printNode && doc.printNode.printerId) {
                printerSet = true
              }
            }
            autoPrint = doc.autoPrint
            $scope.transactions.types.push({type: doc.originalType, printer: autoPrint, printerSet: printerSet})
          })
          .catch(function (reason) {
            $log.error("Whoah, something goes wrong when getting page definition for", types[i], reason)
          })
      }
    }

    $scope.getTransactionTypes = function () {
      $scope.transactions = {
        types: [],
        typesId: []
      }
      var types = []

      //loop through all transaction details
      for(var i = 0; i < $scope.transaction.details.length; i++) {
        var detail = $scope.transaction.details[i]
        if(detail.type === 'order') {
          detail.type = 'special'
        }
        if(detail.type === 'product' ||
          detail.type === 'collection' ||
          detail.type === 'stock' ||
          detail.type === 'pool' ||
          detail.type === 'gold-purchase' ||
          detail.type === 'gold-sell' ||
          detail.type === 'points' ||
          detail.type === 'refund-points' ||
          detail.type === $scope.transaction.type) {
          continue
        }
        types.push({type: detail.type.toUpperCase(), printer: false})
        $scope.transactions.typesId.push({type: detail.type.toUpperCase(), id: detail.transaction})
      }
      types.push({type: $scope.transaction.type.toUpperCase(), printer: false})
      $scope.transactions.typesId.push({type: $scope.transaction.type.toUpperCase(), id: $scope.transaction._id})
      var uniqueTypes = _.uniqBy(types, 'type')
      $scope.checkTypesPages(uniqueTypes)
    }

    $scope.getPageDefinition = function (type) {
      return $q(function (resolve, reject){
        if(!type) {
          return reject('NO_TYPE_DEFINED')
        }
        var originalType = type
        if(type === 'REPAIR' || type === 'ORDER' || type === 'SPECIAL') {
          type = 'REPAIR/SPECIAL'
        }
        if(type === 'SHOP-PURCHASE' || type === 'REFUND' || type === 'REFUND-POINTS') {
          type = 'TRANSACTION'
        }
        if(type === 'GIFTCARD-SELL') {
          type = 'GIFTCARD'
        }
        $printer.getPageDefinition(type)
          .then(function(doc) {
            doc.originalType = originalType
            return resolve(doc)
          })
          .catch(function (reason) {
            console.error('Error while fetching page definition for: ' + type + '. reason: ', reason)
            return reject(reason)
          })

      })
    }

    function getTransactionBasedOnType (type) {
      return $scope.transactions.typesId.reduce(function(filtered, item) {
        if(item.type === type.toUpperCase()) {
          filtered.push(item.id)
        }
        return filtered
      }, [])
    }

    $scope.getOfferDocument = function(transaction, typeDocument) {
      return $q(function(resolve, reject){
        $scope.getPageDefinition('OFFER')
          .then(function(pageDef) {
            $pdf.downloadPdf('generate-offer', {
              transaction: transaction,
              shopId: $rootScope.currentShop._id,
              typeDocument: typeDocument === 'pdf' ? 'document' : 'string',
              documentName: 'OFFER',
              deviceId: $rootScope.currentDevice._id,
              documentId: pageDef.documentId
            }, $language.translate('OFFER') + ' ' + transaction.number + '.pdf', typeDocument === 'pdf' ? 'arraybuffer' : 'string', typeDocument === 'email', typeDocument === 'email')
              .then(function (data) {
                if (typeDocument === 'email') {
                  $scope.sendingMail = false
                  $scope.mailSent = true
                  toastr.success($language.translate('MAIL_SENT'))
                }
                return resolve('SUCCESS')
              })
              .catch(function (reason) {
                $log.error('Error while generating offer', reason)
                toastr.error($language.translate('ERROR_WHILE_PRINTING_OR_SENDING'))
                $scope.sendingMail = false
                $scope.mailSent = false
                return reject(reason)
              })
          })
          .catch(function(reason) {
            $log.error('Error getting offerDoc definition',reason)
            return reject(reason)
          })

      })
    }

    $scope.makeOfferDocument = function (transaction, type) {
      return $q(function (resolve, reject) {
        if (typeof transaction === 'string') {
          $transaction
            .getTransaction(transaction, false, true, $rootScope.currentShop._id)
            .then(function (transaction) {
              return $scope.getOfferDocument(transaction, type)
            })
            .catch(function (reason){
              return reject(reason)
            })

        } else {
          return $scope.getOfferDocument(transaction, type)
        }

      })
    }

    $scope.generateDocument = function (type, typeDocument, mode) {
      //typeDocument: pdf / email, print
      if(!mode || mode === '') {
        mode = 'both'
      }
      if (type === 'SHOP-PURCHASE' || type === 'REFUND' || type === 'REFUND-POINTS') {
        if (typeDocument === 'pdf' || typeDocument === 'print') {
          $scope.makeReceipt('document', typeDocument === 'pdf' )
        } else {
          $scope.makeReceipt('string')
        }
      }

      if (type === 'REPAIR' || type === 'ORDER' || type === 'SPECIAL') {
        var reduced = getTransactionBasedOnType(type)

        if(!reduced || reduced.length === 0) {
          return
        }

        if(typeDocument === 'print') {
          $scope.bulkPrintRepairSpecialDocuments(reduced, false, mode)
          return
        }

        for(var i =0; i < reduced.length; i++) {
          var id = typeof reduced[i] === 'string' ? reduced[i] : reduced[i]._id
          if(typeDocument === 'pdf' || typeDocument === 'print') {
            $scope.downloadRepairSpecialDocument(reduced[i], type === 'SPECIAL' || type === 'ORDER', typeDocument === 'pdf', mode)
          } else {
            $api.post('till/transaction/email', {
              transactionId: id,
              email: $scope.transaction.customer.email,
              shopId: $rootScope.currentShop._id
            }, null, 2)
              .then(function(res) {
                toastr.success($language.translate('MAIL_SENT'))
              })
              .catch(function(reason){
                toastr.error($language.translate('ERROR_WHILE_PRINTING_OR_SENDING'))
                $log.error('reason', reason)
              })
          }
        }
      }
      if (type === 'GIFTCARD-SELL') {
        var reduced = getTransactionBasedOnType(type)
        if (!reduced || reduced.length === 0) {
          return
        }

        $scope.getPageDefinition('GIFTCARD')
          .then(function(pageDef) {
            if(typeDocument === 'pdf') {
              for (var i = 0; i < reduced.length; i++) {
                $pdf.downloadPdf('generate-giftcard', {
                  shopId: $rootScope.currentShop._id,
                  transactionId: reduced[i]._id,
                  documentName: 'GIFTCARD',
                  deviceId: $rootScope.currentDevice._id,
                  documentId: pageDef.documentId
                }, $language.translate('GIFTCARD') + '.pdf', 'arraybuffer', true)
              }
            } else {
              for (var i = 0; i < reduced.length; i++) {
                $pdf.downloadPdf('generate-giftcard', {
                  shopId: $rootScope.currentShop._id,
                  transactionId: reduced[i]._id,
                  documentName: 'GIFTCARD',
                  deviceId: $rootScope.currentDevice._id,
                  documentId: pageDef.documentId
                }, $language.translate('GIFTCARD') + '.pdf', 'arraybuffer', false, true)
                .then(function () {})
              }
            }
          })
          .catch(function (reason) {
            $log.error('Error getting giftcard definition', reason)
            toastr.error($language.translate('ERROR_WHILE_PRINTING_OR_SENDING'))
          })
      }
      if(type === 'OFFER') {
        var reduced = getTransactionBasedOnType(type)
        if (!reduced || reduced.length === 0) {
          return
        }
        for (var i = 0; i < reduced.length; i++) {
          if(!reduced[i]) {
            continue
          }
          $scope.makeOfferDocument(reduced[i], typeDocument)
        }
      }
    }

    $scope.thermalPrintDocument = function (type, mode) {
      if(!mode || mode === '') {
        mode = 'both'
      }
      if(type === 'SHOP-PURCHASE') {
        $scope.printThermal()
      }else {
        var transactions = getTransactionBasedOnType(type)
        if (!transactions || transactions.length === 0) {
          return
        }

        $printer.bulkPrintTransactions(transactions, mode)
      }
    }

    $scope.printProductLabels = function () {
      //We need to fetch all products from all refund transactions
      var ids = $scope.transactions.typesId.filter(function (type) {
        return type.type === 'REFUND'
      }).map(function(type) {
        return type.id
      })

      $api.post('till/transaction/products', {
        transactionsIds: ids,
        shopId: $rootScope.currentShop._id
      }, null, 2)
        .then(function(res) {
          var modalInstance = $uibModal.open({
            templateUrl: '../views/modal/print-all-label-modal.html',
            controller: 'printAllLabelModalController',
            resolve: {
              data: function () {
                return null
              },
              products: function () {
                return res.data.products
              }
            }
          })

          modalInstance.result.then(function (result) {
          }, function (error) {
          })
        })
        .catch(function (reason){
          console.error('Error while fetching products for all refund transactions', reason)
        })
    }
  }])
