prismanoteApp.controller('retailer1ProductImportController', ['$scope', '$rootScope', '$api', 'Upload', '$q', '$timeout', '$retailer', '$diamond', '$productConfig', '$shop',
  function ($scope, $rootScope, $api, Upload, $q, $timeout, $retailer, $diamond, $productConfig, $shop) {
    // $scope.currentShop = 123;
    $scope.s3_location = ''
    $scope.access = false
    $scope.autoGenerated = false

    $scope.checkRetailerIsValidOrNot = function (currentShop, user) {
      console.log('$rootScope', $rootScope)
      console.log('Current User', user)
      var hasAcces = localStorage.getItem('retailerImport') == 'true'
      if (user && user.email && (user.email == 'teus@excellent-electronics.nl' || user.email == 'geek@prismanote.com' || user.email == 'teus@lichtdirect.nl' || user.email == 'retailer1@prismanote.com' || user.email == 'jolmer@excellent-electronics.nl' || user.email == 'caitlin@excellentwebshop.nl' || user.email == 'jolmer1@excellent-electronics.nl' || user.email == 'niek@juwelierbos.nl' || user.email == 'erik@prismanote.com' || user.email == 'info@nvo.media' ||
        hasAcces)) {
        $scope.access = true
        $scope.currentShop = $rootScope.currentShop
      } else {
        $scope.access = false
      }
    }

    $scope.changeAutoGenerateStatus = function (autoGenerated) {
      console.log(' Auto Generated ', autoGenerated)
      $scope.product.autoGenerated = autoGenerated
    }

    $scope.step = []
    $scope.step[1] = true
    $scope.percent = 0
    $scope.back = function (step) {
      goToStep(step)
    }

    $scope.gemDKinds = $diamond.getGemKinds()
    $scope.gemDPurities = $diamond.getGemPurities()
    $scope.gemDColors = $diamond.getGemColors()
    $scope.gemDCuts = $diamond.getGemCuts()

    $scope.dialSColors = $productConfig.getDialColors()
    $scope.strapSColors = $productConfig.getStarpColors()
    $scope.caseSColors = $scope.jewelColors = Object.assign($scope.dialSColors, $scope.strapSColors)

    $scope.variantBases = $productConfig.variantBases()

    function goToStep (step) {
      $scope.alert = null

      angular.forEach($scope.step, function (value, key) {
        $scope.step[key] = false
      })

      $scope.step[step] = true

      if (step == 1) {
        $scope.percent = 0
      } else if (step == 2) {
        $scope.percent = 33
      } else if (step == 3) {
        $scope.percent = 66
      } else if (step == 4) {
        $scope.percent = 100
      }
    }

    $scope.completeStep1 = function (importFile, delimiter) {
      console.log('product handling guy Please CHeck her this', $scope.delimiter)
      $scope.importFile = importFile
      $scope.delimiter = delimiter

      if (!importFile) {
        $scope.alert = {
          type: 'danger',
          msg: 'Geen of onjuist bestand gekozen'
        }
      } else {
        $scope.loading = true

        uploadFile({
          importFile: importFile,
          delimiter: $scope.delimiter,
          publish: $scope.publish
        }, 'api/import/parse')
          .then(function (response) {
            $scope.s3_location = response.data.uploadedResponse.Location

            $scope.loading = false

            $scope.headers = []
            $scope.product = {

            }

            $scope.product.watch = {}
            $scope.product.jewel = {}
            $scope.product.strap = {}

            var i = 0
            angular.forEach(response.data.headers, function (value, key) {
              if (value) {
                var format = /[!@#$%^&*()+\=\[\]{};:"\\|,<>\/?]/
                console.log('format.test(value) ==>' + value, format.test(value))
                value = value.trim()
                if (format.test(value) == false) {
                  var header = {
                    name: value
                  }
                  $scope.headers.push(header)
                } else {
                // value = value.replace(/'/g, "\\'");
                  value = value.replace(/[!@#$%^&*.-_()+\=\[\]{};:"\\|,<>\/?]/g, '').replace(/ /g, '').trim()

                  var header = {
                    name: value
                  }
                  $scope.headers.push(header)
                }
              }

              if (i == (response.data.headers.length - 1)) {
                $scope.lineCount = response.data.data.lines
                $scope.productProperties = response.data.product
                goToStep(2)
              }

              i++
            })
          })
          .catch(function (reason) {
            console.error('Error while parsing', reason.data)
            $scope.alert = {
              type: 'danger',
              msg: 'upload error: ' + reason.data.message
            }
            $scope.loading = false
          })
      }
    }

    $scope.completeStep2 = function () {
      goToStep(3)
      $scope.percent = 66
      $scope.step3()
    }

    var getItems = function (type) {
      $api.get(type, { getAll: true })
        .then(function (response) {
          $scope[type] = response.data[type]
          console.log('Brands ========>', $scope[type])
        }).catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.getBrands = function (shopNameSlug) {
      console.log('----------- Get Brands -----------', shopNameSlug)
      $shop.getshopbrandsForProduct($rootScope.currentShop.nameSlug)
        .then(function (response) {
          if (response.brands.length > 0) {
            $scope.brands = response.brands
            console.log('Brands =======>>>>>>>>>>', $scope.brands)
          } else {
            $scope.brands = []
          }
        })
        .catch(function (err) {
          console.error('Error while getting products', err)
        })
    }

    var searchItems = function (str, type) {
      var matches = []
      $scope[type].forEach(function (item) {
        if (item._id) {
          if ((item._id.en.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) || (item._id.en.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
            matches.push(item)
          }
        }
      })
      return matches
    }

    $scope.searchBrands = function (str) {
      return searchItems(str, 'brands')
    }

    $scope.getTaxForShop = function (currentShopId) {
      $shop.getTaxForShop(currentShopId)
        .then(function (tax) {
          console.log('Getting Tax Rat', tax)
          if (tax && tax.taxes && tax.taxes.rates) {
            $scope.taxes = tax.taxes.rates
          } else {

          }
        })
        .catch(function (errorInGettingTax) {
          console.error('Error In Getting Tax For Shop', errorInGettingTax)
        })
    }

    $scope.brandSelected = function (selected) {
      console.log('SELECTED BRAND : ', selected)
      $scope.mySelectedBrandTitle = selected.title
      console.log('$scope.mySelectedBrandTitle', $scope.mySelectedBrandTitle)
      $scope.mySelectedBrandIsPoolArticle = selected.originalObject.isPoolArticleBrand
      console.log('$scope.mySelectedBrandIsPoolArticle', $scope.mySelectedBrandIsPoolArticle)
      var brand = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug,
        description: selected.originalObject.description,
        images: selected.originalObject.images,
        isPoolArticleBrand: selected.originalObject.isPoolArticleBrand,
        startingDigits: (selected.originalObject._id.startingDigits && selected.originalObject._id.startingDigits != 'null' && selected.originalObject._id.startingDigits != 'undefined' && selected.originalObject._id.startingDigits != 'NULL') ? selected.originalObject._id.startingDigits : null
      }
      // console.log(this.$parent);
      console.log('Check the Selected Single Brands', brand)

      $scope.brandnames[this.$parent.$index].value = brand
      console.log('$scope.brandnames[this.$parent.$index].value', $scope.brandnames[this.$parent.$index].value)
      // console.log($scope.brandnames);
    }

    console.log('$scope.jewelColors', $scope.jewelColors)
    $scope.step3 = function () {
      console.log('')
      console.log('')
      console.log('In Step-3')
      console.log('$scope.product', $scope.product)
      console.log('')
      console.log('')

      // Compulsory brand selection
      if ($scope.product.brand) {
        getValues('brand', function (err, result) {
          console.log(result)
          $scope.brandnames = result
        })
      }

      if ($scope.product.vat) {
        getValues('vat', function (err, result) {
          console.log(result)
          $scope.vats = result
          console.log('Vats', $scope.vats)
        })
      }

      if ($scope.product.category) {
        getValues('category', function (err, result) {
          console.log('categories', result)
          $scope.categories = result
        })
      }

      // General
      if ($scope.product.gender) {
        getValues('gender', function (err, result) {
          $scope.genders = result
        })
      }

      // If diamoonds
      if ($scope.product.diamonds) {
        // GemStone gem Kind A
        if ($scope.product.diamonds.gemKindA) {
          getValues('diamonds.gemKindA', function (err, result) {
            $scope.gemKindsA = result
          })
        }

        // GemStone gem Color A
        if ($scope.product.diamonds.gemColorA) {
          getValues('diamonds.gemColorA', function (err, result) {
            $scope.gemColorsA = result
          })
        }

        // GemStone gemPurity A
        if ($scope.product.diamonds.gemPurityA) {
          getValues('diamonds.gemPurityA', function (err, result) {
            $scope.gemPuritiesA = result
          })
        }

        // GemStone gemCut A
        if ($scope.product.diamonds.gemCutA) {
          getValues('diamonds.gemCutA', function (err, result) {
            $scope.gemCutsA = result
          })
        }

        // GemStone gem Kind B
        if ($scope.product.diamonds.gemKindB) {
          getValues('diamonds.gemKindB', function (err, result) {
            $scope.gemKindsB = result
          })
        }
        // GemStone gem Color B
        if ($scope.product.diamonds.gemColorB) {
          getValues('diamonds.gemColorB', function (err, result) {
            $scope.gemColorsB = result
          })
        }

        // GemStone gemPurity B
        if ($scope.product.diamonds.gemPurityB) {
          getValues('diamonds.gemPurityB', function (err, result) {
            $scope.gemPuritiesB = result
          })
        }

        // GemStone gemCut B
        if ($scope.product.diamonds.gemCutB) {
          getValues('diamonds.gemCutB', function (err, result) {
            $scope.gemCutsB = result
          })
        }

        // GemStone gem Kind C
        if ($scope.product.diamonds.gemKindC) {
          getValues('diamonds.gemKindC', function (err, result) {
            $scope.gemKindsC = result
          })
        }

        // GemStone gem Color C
        if ($scope.product.diamonds.gemColorC) {
          getValues('diamonds.gemColorC', function (err, result) {
            $scope.gemColorsC = result
          })
        }

        // GemStone gemPurity C
        if ($scope.product.diamonds.gemPurityC) {
          getValues('diamonds.gemPurityC', function (err, result) {
            $scope.gemPuritiesC = result
          })
        }

        // GemStone gemCut C
        if ($scope.product.diamonds.gemCutC) {
          getValues('diamonds.gemCutC', function (err, result) {
            $scope.gemCutsC = result
          })
        }
      }

      // Jewel
      if ($scope.product.jewel && $scope.product.jewel.type) {
        getValues('jewel.type', function (err, result) {
          $scope.jewelTypes = result
        })
      }

      if ($scope.product.jewel && $scope.product.jewel.material) {
        getValues('jewel.material', function (err, result) {
          $scope.jewelMaterials = result
        })
      }

      if ($scope.product.jewel && $scope.product.jewel.color) {
        getValues('jewel.color', function (err, result) {
          $scope.jewelSColors = result
        })
      }

      if ($scope.product.variantBaseA) {
        getValues('variantBaseA', function (err, result) {
          $scope.variantBasesA = result
          console.log('Vari Base A ====> ', $scope.variantBasesA)
        })
      }

      if ($scope.product.variantBaseB) {
        getValues('variantBaseB', function (err, result) {
          $scope.variantBasesB = result
          console.log('Vari Base B ====> ', $scope.variantBasesB)
        })
      }

      /** FIXME: CODE NEEDS TO BE ADDED FOR THE FIELDS LIKE COLOR AND OTHERS WHICH ARE
      SHOWN ON THE FRONT-END BUT NOT YET SENT TO THE BACK-END. */
      // Watch
      if ($scope.product.watch) {
        if ($scope.product.watch.type) {
          getValues('watch.type', function (err, result) {
            $scope.types = result
          })
        }
        if ($scope.product.watch.indication) {
          getValues('watch.indication', function (err, result) {
            $scope.indications = result
          })
        }

        if ($scope.product.watch.case) {
          if ($scope.product.watch.case.material) {
            getValues('watch.case.material', function (err, result) {
              $scope.materials = result
            })
          }
          if ($scope.product.watch.case.shape) {
            getValues('watch.case.shape', function (err, result) {
              $scope.shapes = result
            })
          }
          if ($scope.product.watch.case.glassMaterial) {
            getValues('watch.case.glassMaterial', function (err, result) {
              $scope.glassMaterial = result
            })
          }

          if ($scope.product.watch.case.color) {
            getValues('watch.case.color', function (err, result) {
              $scope.caseColors = result
            })
          }
        }

        if ($scope.product.watch.dial) {
          if ($scope.product.watch.dial.pattern) {
            getValues('watch.dial.pattern', function (err, result) {
              $scope.patterns = result
            })
          }
          if ($scope.product.watch.dial.index) {
            getValues('watch.dial.index', function (err, result) {
              $scope.indexes = result
            })
          }

          if ($scope.product.watch.dial.color) {
            getValues('watch.dial.color', function (err, result) {
              $scope.dialColors = result
            })
          }
        }

        if ($scope.product.strap) {
          if ($scope.product.strap.model) {
            getValues('strap.model', function (err, result) {
              $scope.strapModel = result
            })
          }

          if ($scope.product.strap.pattern) {
            getValues('strap.pattern', function (err, result) {
              $scope.strapPattern = result
            })
          }

          if ($scope.product.strap.material) {
            getValues('strap.material', function (err, result) {
              $scope.strapMaterial = result
            })
          }

          if ($scope.product.strap.color) {
            getValues('strap.color', function (err, result) {
              $scope.strapColors = result
            })
          }
        }

        if ($scope.product.watch.strap) {
          if ($scope.product.watch.strap.model) {
            getValues('watch.strap.model', function (err, result) {
              $scope.strapModel = result
            })
          }

          if ($scope.product.watch.strap.pattern) {
            getValues('watch.strap.pattern', function (err, result) {
              $scope.strapPattern = result
            })
          }

          if ($scope.product.watch.strap.material) {
            getValues('watch.strap.material', function (err, result) {
              $scope.strapMaterial = result
            })
          }

          if ($scope.product.watch.strap.color) {
            getValues('watch.strap.color', function (err, result) {
              $scope.strapColors = result
            })
          }
        }
      }
      // TODO: Add more properties which not can be used directly
    }

    $scope.completeStep3 = function () {
      console.log('PRODUCT BEING UPLOADED', $scope.product)
      console.log('$scope.$scope.delimiter', $scope.delimiter)
      console.log('Brands Name', $scope.brandnames)
      uploadFile({
        importFile: $scope.importFile,
        delimiter: $scope.delimiter,
        product: $scope.product,
        customData: {
          categories: $scope.categories ? $scope.categories : null,
          brands: $scope.brandnames ? $scope.brandnames : null,
          jewelTypes: $scope.jewelTypes ? $scope.jewelTypes : null,
          jewelColors: $scope.jewelSColors ? $scope.jewelSColors : null,
          dialColors: $scope.dialColors ? $scope.dialColors : null,
          strapColors: $scope.strapColors ? $scope.strapColors : null,
          caseColors: $scope.caseColors ? $scope.caseColors : null,
          genders: $scope.genders ? $scope.genders : null,
          types: $scope.types ? $scope.types : null,
          indications: $scope.indications ? $scope.indications : null,
          materials: $scope.materials ? $scope.materials : null,
          shapes: $scope.shapes ? $scope.shapes : null,
          glassMaterial: $scope.glassMaterial ? $scope.glassMaterial : null,
          patterns: $scope.patterns ? $scope.patterns : null,
          indexes: $scope.indexes ? $scope.indexes : null,
          strapModel: $scope.strapModel ? $scope.strapModel : null,
          strapPattern: $scope.strapPattern ? $scope.strapPattern : null,
          strapMaterial: $scope.strapMaterial ? $scope.strapMaterial : null,
          gemPurityA: $scope.gemPuritiesA ? $scope.gemPuritiesA : null,
          gemPurityB: $scope.gemPuritiesB ? $scope.gemPuritiesB : null,
          gemPurityC: $scope.gemPuritiesC ? $scope.gemPuritiesC : null,
          gemColorA: $scope.gemColorsA ? $scope.gemColorsA : null,
          gemColorB: $scope.gemColorsB ? $scope.gemColorsB : null,
          gemColorC: $scope.gemColorsC ? $scope.gemColorsC : null,
          gemKindA: $scope.gemKindsA ? $scope.gemKindsA : null,
          gemKindB: $scope.gemKindsB ? $scope.gemKindsB : null,
          gemKindC: $scope.gemKindsC ? $scope.gemKindsC : null,
          gemCutA: $scope.gemCutsA ? $scope.gemCutsA : null,
          gemCutB: $scope.gemCutsB ? $scope.gemCutsB : null,
          gemCutC: $scope.gemCutsC ? $scope.gemCutsC : null,
          vats: $scope.vats ? $scope.vats : null,
          jewelTypes: $scope.jewelTypes ? $scope.jewelTypes : null,
          jewelMaterials: $scope.jewelMaterials ? $scope.jewelMaterials : null,
          // variantsCollection: $scope.variantsCollections ? $scope.variantsCollections : null,
          variantBaseA: $scope.variantBasesA ? $scope.variantBasesA : null,
          variantBaseB: $scope.variantBasesB ? $scope.variantBasesB : null
        },
        lines: $scope.lineCount,
        s3FileLocation: $scope.s3_location
      }, 'api/import/handle')
        .then(function (response) {
          goToStep(4)
        })
        .catch(function (reason) {
          console.log(reason)
          $scope.alert = {
            type: 'danger',
            msg: 'upload error: ' + reason.status
          }
        })
    }

    function getValues (value, callback) {
      console.log('Check Value', value, $scope.product)
      var property = JSON.parse(_.get($scope.product, value))
      var columnName = property.name

      uploadFile({
        importFile: $scope.importFile,
        delimiter: $scope.delimiter,
        column: columnName
      }, 'api/import/value')
        .then(function (response) {
          console.log('response', response)
          return callback(null, response.data.result)
        })
        .catch(function (reason) {
          console.log('reason', reason)
          return callback(reason)
        })
    }

    $scope.completeImport = function () {
      console.log('handleImport')

      uploadFile({
        importFile: $scope.importFile,
        delimiter: $scope.delimiter,
        product: $scope.product
      },
      'api/import/handle')
        .then(function (response) {
          console.log(response.data)
        })
        .catch(function (reason) {
          $scope.alert = {
            type: 'danger',
            msg: 'upload error: ' + reason.status
          }
          $scope.error = true
        })
    }

    $scope.closeAlert = function () {
      $scope.alert = null
    }

    function uploadFile (data, url) {
      console.log('$rootScope.currentShop below in the controller', $rootScope.currentShop)

      var params = {
        url: url,
        data: {
          delimiter: data.delimiter,
          cat: data.category,
          file: data.importFile,
          currentShopId: $rootScope.currentShop._id,
          product: data.product ? data.product : null,
          column: data.column ? data.column : null,
          customData: data.customData ? data.customData : null,
          lines: data.lines ? data.lines : null
        }
      }

      console.log('Calling Upload with following params:', params)
      return $q(function (resolve, reject) {
        console.log('Into the upload function')
        Upload.upload(params)
          .then(function (res) {
            console.log('Response from upload ', res)
            $timeout(function () {
              resolve(res)
            })
          }, function (res) {
            // wanneer uploaden mislukt
            console.error('Error: ', res.status)
            reject(res)
          }, function (evt) {
            // tijdens upload
            // var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            // $scope.uploadProgress = progressPercentage;
            // console.log("Progress: " + progressPercentage + '%' + evt.config.data);
          })
      })
    }
  }])
