prismanoteApp.controller('fetchBrandSupplierForCashRegisterController', ['$scope', '$shop', '$rootScope', 'company', 'brand', 'supplierArray', 'shopBrands', '$uibModalInstance', '$language',
  function ($scope, $shop, $rootScope, company, brand, supplierArray, shopBrands, $uibModalInstance, $language) {
    $scope.companies = supplierArray || []
    $scope.shopBrands = shopBrands || []
    $scope.error = ''
    $scope.purchaseDetails = {}

    $scope.selectedBrand = function (selected) {
      if (selected.originalObject) {
        selected = selected.originalObject
      }
      $scope.brand = {
        _id: selected._id._id,
        name: selected.name || selected[$rootScope.language].name
      }

      if (!$scope.company) {
        $scope.shopBrandsLength = $scope.shopBrands.length

        if (
            selected.favoriteSupplier &&
            selected.favoriteSupplier._id &&
            selected.favoriteSupplier._id._id
        ) {
          $scope.fetchFavouriteSupplierDetails(selected.favoriteSupplier._id)
        }
      }
    }

    $scope.fetchFavouriteSupplierDetails = function (favoriteSupplier) {
      $shop.getCompanyByShop($rootScope.currentShop._id, favoriteSupplier.nameSlug)
        .then(function (selectedCompany) {

          if (selectedCompany && selectedCompany.length && selectedCompany[0].shopCompany) {
            $scope.company = {
              _id: favoriteSupplier._id,
              name: favoriteSupplier.name,
              entryMethodCustomerValue: selectedCompany[0].shopCompany.entryMethodCustomerValue ? selectedCompany[0].shopCompany.entryMethodCustomerValue : false,
              isAutoOrderEnable: selectedCompany[0].shopCompany.isAutoOrderEnable ? selectedCompany[0].shopCompany.isAutoOrderEnable : false,
              purchaseMargin: selectedCompany[0].shopCompany.purchaseMargin ? selectedCompany[0].shopCompany.purchaseMargin : 0,
              storageFactor: selectedCompany[0].shopCompany.storageFactor ? selectedCompany[0].shopCompany.storageFactor : 0,
              prefillCompanySettings: !(selectedCompany[0].shopCompany && selectedCompany[0].shopCompany.prefillCompanySettings === false)
            }

            $scope.purchaseDetails = {
              isAutoOrderEnable: selectedCompany[0].shopCompany.isAutoOrderEnable ? selectedCompany[0].shopCompany.isAutoOrderEnable : false,
              tenureTime: selectedCompany[0].shopCompany.tenureTime ? selectedCompany[0].shopCompany.tenureTime : 'day',
              _id: favoriteSupplier._id,
              isShopProduct: false,
              hasSupplier: true,
              entryMethodCustomerValue: selectedCompany[0].shopCompany.entryMethodCustomerValue ? selectedCompany[0].shopCompany.entryMethodCustomerValue : false,
              purchaseMargin: selectedCompany[0].shopCompany.purchaseMargin ? selectedCompany[0].shopCompany.purchaseMargin : 0,
              storageFactor: selectedCompany[0].shopCompany.storageFactor ? selectedCompany[0].shopCompany.storageFactor : 0,

              supplierDetails: {
                entryMethodCustomerValue: selectedCompany[0].shopCompany.entryMethodCustomerValue ? selectedCompany[0].shopCompany.entryMethodCustomerValue : false,
                address: selectedCompany[0].company && selectedCompany[0].company.address ? selectedCompany[0].company.address : {},
                email: selectedCompany[0].company && selectedCompany[0].company.email ? selectedCompany[0].company.email : {},
                name: selectedCompany[0].company && selectedCompany[0].company.name ? selectedCompany[0].company.name : {},
                nameSlug: selectedCompany[0].company && selectedCompany[0].company.nameSlug ? selectedCompany[0].company.nameSlug : {},
                prefillCompanySettings: !(selectedCompany[0].shopCompany && selectedCompany[0].shopCompany.prefillCompanySettings === false),
                _id: favoriteSupplier._id,
                purchaseMargin: selectedCompany[0].shopCompany.purchaseMargin ? selectedCompany[0].shopCompany.purchaseMargin : 0,
                storageFactor: selectedCompany[0].shopCompany.storageFactor ? selectedCompany[0].shopCompany.storageFactor : 0,
                tenureTime: selectedCompany[0].shopCompany.tenureTime ? selectedCompany[0].shopCompany.tenureTime : 'day'
              }
            }
          }
        })
        .catch(function (error) {
          console.error('Error', error)
        })
    }

    $scope.selectedCompany = function (selected) {
      if (selected) {
        if (selected.originalObject) {
          selected = selected.originalObject
        }

        $scope.company = {
          _id: selected._id,
          name: selected.name,
          entryMethodCustomerValue: selected.shopCompany && selected.shopCompany.entryMethodCustomerValue ? selected.shopCompany.entryMethodCustomerValue : false,
          isAutoOrderEnable: selected.shopCompany && selected.shopCompany.isAutoOrderEnable ? selected.shopCompany.isAutoOrderEnable : false,
          purchaseMargin: selected.shopCompany && selected.shopCompany.purchaseMargin ? selected.shopCompany.purchaseMargin : 0,
          storageFactor: selected.shopCompany && selected.shopCompany.storageFactor ? selected.shopCompany.storageFactor : null,
          tenureTime: selected.shopCompany && selected.shopCompany.tenureTime ? selected.shopCompany.tenureTime : 'day',
          prefillCompanySettings: !(selected.shopCompany && selected.shopCompany.prefillCompanySettings === false)
        }

        $scope.purchaseDetails = {
          isAutoOrderEnable: selected.shopCompany && selected.shopCompany.isAutoOrderEnable ? selected.shopCompany.isAutoOrderEnable : false,
          _id: selected._id,
          isShopProduct: false,
          hasSupplier: true,
          supplierDetails: {
            entryMethodCustomerValue: selected.shopCompany && selected.shopCompany.entryMethodCustomerValue ? selected.shopCompany.entryMethodCustomerValue : false,
            address: selected.address ? selected.address : {},
            email: selected.address ? selected.address : '',
            name: selected.name ? selected.name : '',
            nameSlug: selected.nameSlug ? selected.nameSlug : '',
            prefillCompanySettings: !(selected.shopCompany && selected.shopCompany.prefillCompanySettings === false),
            _id: selected._id,
            purchaseMargin: selected.shopCompany && selected.shopCompany.purchaseMargin ? selected.shopCompany.purchaseMargin : 0,
            storageFactor: selected.shopCompany && selected.shopCompany.storageFactor ? selected.shopCompany.storageFactor : null,
            tenureTime: selected.shopCompany && selected.shopCompany.tenureTime ? selected.shopCompany.tenureTime : 'day'
          }
        }
      }
    }

    $scope.searchBrands = function (str) {
      var matches = []

      $scope.shopBrands.forEach(function (item) {
        if (
          item._id &&
          Object.keys(item._id).length > 0 &&
          (item._id[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
          (item._id.nameSlug && item._id.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)
        ) {
          matches.push(item)
        }
      })
      return matches
    }

    $scope.removeBrand = function () {
      $scope.brand = null
    }

    $scope.removeCompany = function () {
      $scope.company = null
    }

    $scope.save = function () {
      if (!$scope.company) {
        $scope.error = $language.translate('PLEASE_SELECT_SUPPLIER')
        return
      }

      $uibModalInstance.close({
        brand: $scope.brand,
        company: $scope.company,
        purchaseDetails: $scope.purchaseDetails
      })
    }

    $scope.cancel = function () {
      $uibModalInstance.close({})
    }


    if (brand) {
      var brandObj = _.find($scope.shopBrands, {name: brand.name})
      if(brandObj) {
        $scope.selectedBrand(brandObj)
      }
    }
    if (company) {
      $scope.selectedCompany(company)
    }
  }
])
