prismanoteApp.controller('adminProductsNewController', ['$scope', '$rootScope', '$q', '$translate', '$product',
  function ($scope, $rootScope, $q, $translate, $product) {
    console.group('New Products Controller Called')
    function renderPage() {
      // Render Page
      console.log(' Render Products in Admin Products Page ')
      $scope.loading = true
      $scope.isVerified = false
      $scope.isNotVerified = true
      $scope.autoCreated = false
      $scope.brands
      $scope.category
      $scope.maxPrice
      $scope.minPrice
      $scope.gender
      $scope.noMoreProducts = true
      $scope.noMoreSearchProducts = true
      $scope.sortBy = 3
      $scope.limit = 32
      $scope.pageNumber = 0
      $scope.loading = false
      $scope.products = []
    }

    renderPage()

    $scope.applyFilter = function () {
      $scope.pageNumber = 0
      $scope.products = []
      getProducts()
    }

    $scope.resetFilters = function () {
      renderPage()
    }

    $scope.loadMore = function () {
      $scope.pageNumber++
      getProducts()
    }

    $scope.loadMoreSearch = function (searchValue) {
      $scope.pageNumber++
      $scope.searchProduct(searchValue)
    }
    function getProducts() {
      // console.log('--------------------Get Products')

      // console.log('Sort By', $scope.sortBy)
      // console.log('Per Page', $scope.limit)

      $scope.loading = true
      // $scope.products = [];
      var data = {
        isVerified: $scope.isVerified,
        isNotVerified: $scope.isNotVerified,
        autoCreated: $scope.autoCreated,
        isFromAdminProduct: true
      }

      if ($scope.category) data.category = { $in: $scope.category.$in }
      if ($scope.brands) data.brand = { $in: $scope.brands.$in }
      if ($scope.maxPrice != null && $scope.maxPrice != undefined) data.maxPrice = $scope.maxPrice
      if ($scope.minPrice != null && $scope.minPrice != undefined) data.minPrice = $scope.minPrice
      if ($scope.gender) data.gender = { $or: $scope.gender }
      if ($scope.sortBy != null && $scope.sortBy != undefined) data.sortBy = $scope.sortBy
      else $scope.sortBy = 3
      if ($scope.limit) data.limit = $scope.limit || 32
      data.pageNumber = $scope.pageNumber || 0

      console.log('----------------------> Request Data', data)

      $scope.getThumbnailUrl = function(url){
        if(url) {
          var index = url.indexOf('/');
          var folder = url.substring(0, index + 1);
          var fileName = url.replace(folder, '');
          return folder + 'prismathumbnail-' + fileName;
        }
      }

      $product.getProducts(data)
        .then(function (products) {
          var newProducts = products.products
          if (newProducts.length) {
            $scope.products = $scope.products.concat(newProducts)
            if (newProducts.length == $scope.limit) $scope.noMoreProducts = false
            else $scope.noMoreProducts = true
          } else {
            $scope.noMoreProducts = true
          }
          $scope.loading = false
          console.groupEnd()
        })
        .catch(function (error) {
          console.error('Error : While Getting Products')
          console.error(error)
          $scope.loading = false
        })
    }

    $scope.convertDate = function (date) {
      var d = new Date(date)
      return d.toLocaleString()
    }

    $scope.$on('category_filter_directive', function (event, params) {
      if (!$scope.category) {
        $scope.category = {}
        $scope.category.$in = []
        $scope.category.$in = params.categories
      } else {
        $scope.category.$in = params.categories
      }
    })

    $scope.$on('brand_filter_directive', function (event, params) {
      var filterBrand = params.filter['brand.nameSlug'].$in
      if (filterBrand && filterBrand.length > 0) {
        $scope.brands = { $in: filterBrand }
      } else {
        $scope.brands = null
      }
    })

    $scope.$on('price_filter_directive', function (event, params) {
      console.log('params', params)
      if (params && params.great != null && params.great != undefined) $scope.minPrice = params.great
      else $scope.minPrice = null

      if (params && params.less != null && params.less != undefined) $scope.maxPrice = params.getProducts
      else $scope.maxPrice = null
    })

    $scope.$on('gender_filter_directive', function (event, params) {
      if (params.filter && params.filter.gender) {
        $scope.gender = []
        $scope.gender = params.filter.gender
      } else {
        $scope.gender = null
      }
    })

    $scope.$on('sort_filter_directive', function (event, params) {
      if (params && params.sort != null && params.sort != undefined) {
        delete $scope.sortBy
        $scope.sortBy = params.sort
        console.log('Sort by in controller', $scope.sortBy)
      }
    })

    $scope.searchProduct = function (searchText) {
      $scope.noMoreProducts = true
      $scope.noMoreSearchProducts = true

      if (searchText && searchText.length > 3) {
        $scope.loading = true
        var data = {
          searchText: searchText,
          mode: 'search',
          pageNumber: $scope.pageNumber,
          limit: $scope.limit
        }
        $product.getProducts(data)
          .then(function (products) {
            if (products.products && products.products.length) {
              var newProducts = products.products
              $scope.products = $scope.products.concat(products.products)
              if (newProducts.length == $scope.limit) $scope.noMoreSearchProducts = false
              else $scope.noMoreSearchProducts = true
            }

            $scope.loading = false
          })
          .catch(function (error) {
            console.error('Error While Getting Products Based Search')
            console.error(error)
            $scope.loading = false
          })
      }
    }

    $scope.verifyAllCurrentProducts = function () {
      // console.log('verifyAllCurrentProducts called', $scope.products.length);
      if ($scope.products && $scope.products.length) {
        var productIds = $scope.products.map(function (el) {
          return el._id;
        })
        console.log('verifyAllCurrentProducts: ', productIds);
        var body = { productIds: productIds }
        $scope.loading = true;
        $product.robotForAdminProductVerification(body).then(function (response) {
          $scope.loading = false;
          console.log('response: ', response);
        }).catch(function (error) {
          console.error('Error While Getting Products Based Search')
          console.error(error)
          $scope.loading = false
        })
      }

    }

  }])
