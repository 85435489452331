prismanoteApp.controller('retailerPaymentsController', ['$scope', '$rootScope', '$retailer', '$api', '$filter', 'FileSaver', '$http',
  function ($scope, $rootScope, $retailer, $api, $filter, FileSaver, $http) {
    $scope.loading = false

    $scope.$on('changeSelectedShop', function () {
      $retailer.getShop()
        .then(function (shop) {
          $scope.currentShop = shop
          $scope.loadOrders()
        })
    })

    $scope.loadOrders = function () {
      console.log('This backend function does not exists anymore')
      return
      $scope.loading = true
      $scope.orders = null
      $api.get('orders-with-payments', { shopSlug: $rootScope.currentShop.nameSlug })
        .then(function (response) {
          $scope.orders = response.data.ordersWithPayment
          $scope.loading = false
        })
        .catch(function (reason) {
          console.error('Error while getting orders with payments', reason)
          $scope.loading = false
        })
    }

    $scope.getInvoiceNumber = function (payment) {
      if (payment) {
        if (!payment.completed && payment.payoutDate) {
          return 'Betaling verwacht op ' + $filter('date')(payment.payoutDate, 'dd-MM-yyyy')
        } else if (payment.completed) {
          return '<a ng-click="downloadPaymentInvoice(order.payment)" href="#">' + payment.invoiceNumber + '</a>'
        } else if (payment.amount == 0) {
          return 'Deze order kan niet worden uitbetaald'
        }
      }
    }

    $scope.downloadPackageSlip = function (order) {
      console.log('This backend function does not exists anymore')
      return
      // Dit doen we even met $htpp omdat de $api service vooralsnog geen responseType ondersteund.
      $http({
        method: 'POST',
        url: '/api/package-slip',
        data: {
          orderId: order._id
        },
        responseType: 'arraybuffer'
      })
        .then(function (response) {
          var file = new Blob([response.data], { type: 'application/pdf' })
          FileSaver.saveAs(file, 'Order ' + order.number + '.pdf')
        }, function (response) {
          console.error('Error while creating package slip', response)
        })
    }

    $scope.downloadPaymentInvoice = function (payment) {
      console.log('This backend function does not exists anymore')
      return
      // Dit doen we even met $htpp omdat de $api service vooralsnog geen responseType ondersteund.
      $http({
        method: 'GET',
        url: '/api/payment-invoice',
        params: {
          paymentId: payment._id
        },
        responseType: 'arraybuffer'
      })
        .then(function (response) {
          var file = new Blob([response.data], { type: 'application/pdf' })
          FileSaver.saveAs(file, 'Factuur ' + payment.invoiceNumber + '.pdf')
        }, function (response) {
          console.log('ERROR', response)
        })
    }
  }])
