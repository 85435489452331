prismanoteApp.controller('shopsController', ['$scope', '$rootScope', '$api', '$stateParams', '$uibModal', '$state', 'prompt', 'Upload', '$language', '$shop', '$purchaseOrder', '$admin', 'dialogs', '$email', '$label', '$thermalPrint', '$device', '$product', '$printnode',
	function ($scope, $rootScope, $api, $stateParams, $uibModal, $state, prompt, Upload, $language, $shop, $purchaseOrder, $admin, dialogs, $email, $label, $thermalPrint, $device, $product, $printnode) {
        console.log("------------------shop detail-----------");
		console.log($scope , $rootScope , $api);
		$scope.totalDisplayed = 50
		$scope.upload = false
		$scope.rawData = false
		$scope.language = $rootScope.language
		$scope.isVerified = true
		$scope.isNotVerified = true
		$scope.templateString = ''
		$scope.template = {}
		$scope.showData = false
		$scope.selectedDevice = null
		$scope.localdata = {
			loading: false,
			shopdeletingProdLoader: false,
			skip: 0,
			limit: 1000,
			currentPage: 0
		}
		$scope.previewUrl = 'https://prismanote.com/public/prismanote.png'
		$scope.labelData = {
			template: '',
			exampleData: $label.getExampleDataObject()
		}
		$scope.thermalPrintData = {
			template: '',
			print: ''
		}

		$scope.datePickerOptions = {
			minDate: new Date().setDate(new Date().getDate() + 1)
		}
		$scope.jsonOptions = {
			mode: 'code',
			modes: ['code', 'tree', 'text'],
		}
		$scope.pageSize = 50
		$scope.currentPage = 1
		$scope.repairBagReceiptIsUsed = false	
		$scope.oMigration = {
			iBusinessId: '6182a52f1949ab0a59ff4e7b',
			sOrganization: 'neworg9',
			sAccessKey: '93edd07de2c3670e8f713863',
			sSecretKey: 'c4e3ef814a324e4e423796cf6f7fee0c9cef943eb3dd3b72b53b8dd03ac34d8dc415e845b0974f68e18c8b2477846203f984e5d02bc10a294b4b126691ec8faa',
			sLocationName: 'Lausanne',
			iLocationId: '623c38f21bc6be308072b103',
			iWorkstationId:'62cfa01063953715a759acbd',
			bChooseDate: true,
			startDate: new Date(new Date().setMonth(new Date().getMonth() - 6)),
			endDate: new Date(),
			migrationProgressBarStatus:0,
			nMigratedItem:0,
			nPendingItemToMigrate:0,
			nInvalidItem: 0,
			nLimit:0,
			skip:0,
			nCurrentLoop:0
		};

		$scope.bIsMigrating = true;
		$scope.bIsProcessing = false;

		$scope.verificationsChanged = function () {
			var status = false
			if ($scope.isNotVerified === $scope.isVerified) {
				status = "showAll"
			} else {
				if ($scope.isVerified === false && $scope.isNotVerified === true) {
					status = false;
				} else {
					status = true;
				}
			}

			var params = {
				filter: {
					isVerified: status,
					isDeleted: 0
				},
				sort: {
					_id: 'desc'
				},
				select: '_id name nameSlug isVerified adminLabel email phone printNode'
			}
			$scope.getShopList(params)
		}

		$scope.getShopList = function (params) {
			$scope.shopsFilter = []
			if (!params) {
				params = {
					sort: {
						_id: 'desc'
					},
					select: '_id name nameSlug isVerified adminLabel'
				}
			}
			$scope.loading = true;
			$scope.shops = {}
			$api.get('shops', params)
				.then(function (response) {
					$scope.loading = false
					$scope.shops = response.data.shops

					$scope.shops.forEach(function (val) {
						val.yearCreated = new Date(parseInt(val._id.toString().substring(0,8),16)*1000).getFullYear()
						$scope.shopsFilter.push({
							'name': val.name,
							'nameSlug': val.nameSlug,
							'email': val.email,
							'phone': val.phone,
							'printNode': val.printNode,
							'isDeleted': false,
							'adminLabel': val.adminLabel,
							'yearCreated': val.yearCreated,
							'isVerified': val.isVerified
						})
					})
				})

				.catch(function (reason) {
					console.error(reason)
				})
		}

		$scope.getDevicesForShop = function () {
			$device.getDevices($scope.shop._id, null)
				.then(function(devices) {
					$scope.shopDevices = devices
				})
				.catch(function (reason){
					console.error('Error when fetching shop devices', reason)
				})
		}

		$scope.selectDevice = function (device) {
			$scope.selectedDevice = device
		}

		$scope.generatePageDefinitions = function () {
			$scope.alert = null
			$api.post('shop/generate-missing-documents', {
				shopId: $scope.shop._id,
				deviceId: $scope.selectedDevice
			}, null, 2)
				.then(function(response) {
					$scope.alert = {
						type: 'success',
						msg: 'Missing documents generated'
					}
				})
				.catch(function(reason) {
					console.error('error', reason)
					$scope.alert = {
						type: 'danger',
						msg: reason
					}
				})
		}

		function parseModulePackages(package) {

			function addModulesToPackage(modules) {
				if (!modules) {
					return package.modules
				}
				var counter = 0
				var pending = []
				for (var i = 0; i < modules.length; i++) {
					if (modules[i] !== '') {
						pending.push({
							name: modules[i],
							active: true,
							changed: new Date,
						})
					}

					counter++;
					if (counter === modules.length) {
						return pending.concat(package.modules)
					}
				}
			}

			for (var i = 0; i < package.modules.length; i++) {
				var mod = package.modules[i]

				//We need to merge the modules from other packages if some specific name exists
				if (mod.name === "PACKAGE_ALL_FUNCTIONS_FROM_CASH_AND_CRM") {
					//This is the complete store package
					package.modules.splice(i, 1)
					//remove this module from the list
					var index1 = _.findIndex($rootScope.packages, {
						name: 'CASH_AND_CRM'
					});
					return addModulesToPackage($rootScope.packages[index1].contents)

				} else if (mod.name === "PACKAGE_ALL_FUNCTIONS_FROM_COMPLETE_STORESYSTEM") {
					//this is the omnichannel package
					package.modules.splice(i, 1)
					//remove this module from the list

					//First, fetch the modules from the cash and crm package
					//And delete the package PACKAGE_ALL_FUNCTIONS_FROM_CASH_AND_CRM from the modules
					var index1 = _.findIndex($rootScope.packages, {
						name: 'CASH_AND_CRM'
					})
					var modules1 = $rootScope.packages[index1].contents;

					var index2 = _.findIndex($rootScope.packages, {
						name: 'COMPLETE_STORESYSTEM'
					})
					var modules2 = $rootScope.packages[index2].contents;

					var mods = modules1.concat(modules2).filter(function (v) {
						return v !== '' && v !== 'PACKAGE_ALL_FUNCTIONS_FROM_CASH_AND_CRM'
					})

					return addModulesToPackage(mods);

				} else if (mod.name === "PACKAGE_ALL_FUNCTIONS_FROM_OMNICHANNEL_SYSTEM") {
					//This is the sell package
					package.modules.splice(i, 1)
					//remove this module from the list
					var index1 = _.findIndex($rootScope.packages, {
						name: 'CASH_AND_CRM'
					})
					var modules1 = $rootScope.packages[index1].contents;

					var index2 = _.findIndex($rootScope.packages, {
						name: 'COMPLETE_STORESYSTEM'
					})
					var modules2 = $rootScope.packages[index2].contents

					var index3 = _.findIndex($rootScope.packages, {
						name: 'OMNICHANNEL_SYSTEM'
					})
					var modules3 = $rootScope.packages[index3].contents

					var mods = modules1.concat(modules2).concat(modules3).filter(function (v) {
						return v !== '' && v !== 'PACKAGE_ALL_FUNCTIONS_FROM_CASH_AND_CRM' && v !== 'PACKAGE_ALL_FUNCTIONS_FROM_COMPLETE_STORESYSTEM'
					})
					return addModulesToPackage(mods)
				} else {
					return addModulesToPackage()
				}
			}
		}

		$scope.loadShop = function () {
			$api.get('v2/admin/shop', {
					nameSlug: $stateParams.nameSlug
				})

				.then(function (response) {
					$scope.shop = response.data.data;
					if ($scope && $scope.shop && $scope.shop.newPlatform && $scope.shop.newPlatform.iBusinessId) $scope.oMigration.iBusinessId = $scope.shop.newPlatform.iBusinessId;
					if ($scope && $scope.shop && $scope.shop.newPlatform && $scope.shop.newPlatform.iLocationId) $scope.oMigration.iLocationId = $scope.shop.newPlatform.iLocationId;
					if ($scope && $scope.shop && $scope.shop.newPlatform && $scope.shop.newPlatform.iWorkstationId) $scope.oMigration.iWorkstationId = $scope.shop.newPlatform.iWorkstationId;
					if ($scope && $scope.shop && $scope.shop.newPlatform && $scope.shop.newPlatform.sLocationName) $scope.oMigration.sLocationName = $scope.shop.newPlatform.sLocationName;
					if ($scope && $scope.shop && $scope.shop.newPlatform && $scope.shop.newPlatform.sOrganization) $scope.oMigration.sOrganization = $scope.shop.newPlatform.sOrganization;
					if($scope.shop.jspmVersion) {
						$scope.shop.jspmVersion = $scope.shop.jspmVersion.toString()
					}
					$scope.getShopLabels()
					$scope.getShopLabels(true)
					$scope.getShopThermalPrints()
					$scope.getShopThermalPrints(true)
					$scope.getAllPrintSettings()

					$scope.emailBackup = angular.copy($scope.shop.email)
					
					$scope.yearCreated = new Date(parseInt($scope.shop._id.toString().substring(0,8),16)*1000);

					// check the packages for this shop
					if ($scope.shop.package && $scope.shop.package.pendingPackages && $scope.shop.package.pendingPackages.modules.length > 0) {
						$scope.pendingPackages = parseModulePackages($scope.shop.package.pendingPackages)
					}

					if($scope.shop.pageFormats) {
						$scope.repairBagReceiptIsUsed = $scope.shop.pageFormats.find(function(o) {
							return o.name && o.name === 'REPAIR/SPECIAL' && o.useRepairBagReceipt === true
						})
					}
					
					$scope.allModules = [];

					$rootScope.packages.forEach(function (p) {
						if (p.contents) {
							p.contents.forEach(function (m) {
								if (m !== '' && m !== 'PACKAGE_ALL_FUNCTIONS_FROM_OMNICHANNEL_SYSTEM' && m !== 'PACKAGE_ALL_FUNCTIONS_FROM_CASH_AND_CRM' && m !== 'PACKAGE_ALL_FUNCTIONS_FROM_COMPLETE_STORESYSTEM') {
									$scope.allModules.push({
										name: $language.translate(m),
										value: m
									})
								}
							})
						}
					})
				})

				.catch(function (reason) {
					console.error(reason);
				});
		}

		$scope.activatePackage = function (package) {
			var pack = {
				date: new Date,
				modules: package.contents.filter(
					function (v) {
						return v !== ''
					}).map(
					function (v) {
						return {
							name: v,
							changed: new Date(),
							active: true
						}
					}
				)
			}

			$scope.shop.package.modules = [];
			$scope.shop.package.modules = parseModulePackages(pack)
		}

		$scope.activatePendingModules = function () {
			if (!$scope.shop.package.modules) {
				$scope.shop.package.modules = [];
			}

			for (var i = 0; i < $scope.pendingPackages.length; i++) {

				var index = _.findIndex($scope.shop.package.modules, {
					name: $scope.pendingPackages[i].name
				});
				if (index < 0) {
					$scope.shop.package.modules.push({
						name: $scope.pendingPackages[i].name,
						changed: new Date(),
						active: $scope.pendingPackages[i].active
					})
				}
			}
			$scope.pendingPackages = null;
			$scope.shop.package.pendingPackages = null;
		}

		$scope.removeModule = function (index) {
			$scope.shop.package.modules.splice(index, 1);
		}

		$scope.disableAll = function () {
			for (var i = 0; i < $scope.shop.package.modules.length; i++) {
				$scope.shop.package.modules[i].active = false;
			}
		}

		$scope.enableAll = function () {
			for (var i = 0; i < $scope.shop.package.modules.length; i++) {
				$scope.shop.package.modules[i].active = true;
				$scope.shop.package.modules[i].changed = new Date();

			}
		}

		$scope.removeAll = function () {
			$scope.shop.package.modules = []
		}

		$scope.toggleActiveModule = function (index) {
			$scope.shop.package.modules[index].active = !$scope.shop.package.modules[index].active;
		}

		$scope.addNewModule = function (mod) {
			$scope.modAlert = null;
			if (!$scope.shop.package) {
				$scope.shop.package = {
					modules: []
				}
			}

			var index = _.findIndex($scope.shop.package.modules, {
				name: mod.name
			});
			if (index >= 0) {
				$scope.modAlert = {
					type: 'danger',
					msg: "THIS_MODULE_ALREADY_EXISTS_ON_THIS_SHOP"
				}
			} else {
				$scope.shop.package.modules.push({
					name: mod.name,
					active: true,
					changed: new Date()
				})
			}
		}

		$scope.saveModules = function () {
			var modalInstance = $uibModal.open({
				templateUrl: '../views/modal/send-module-mail-modal.html',
				controller: 'sendModuleMailController',
				resolve: {
					shopId: function () {
						return $scope.shop._id;
					}
				}
			});

			modalInstance.result.then(function () {
				var newShopData = {};
				if ($scope.shop.package) newShopData['package'] = $scope.shop.package;

				updateShopDetails(newShopData);
			})
		}

		$scope.getBrands = function () {
			// $api.get('brands')
			$api.get('brands?getAll=true')
				.then(function (response) {
					$scope.brands = response.data.brands;
				})

				.catch(function (reason) {
					console.error(reason);
				});
		}

		$scope.searchBrands = function (str) {
			var matches = [];
			$scope.brands.forEach(function (brand) {
				if ((brand[$scope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
					(brand[$scope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
					matches.push(brand);
				}
			})
			return matches;
		}

		$scope.generateInternalLinks = function () {
			var internalLinks = [{
					nlName: 'Home',
					enName: 'Home',
					deName: 'Home',
					frName: 'Home',
					spName: 'Home',
					link: '.home'
				},
				{
					nlName: 'Producten',
					enName: 'Products',
					deName: 'Producten',
					frName: 'Produits',
					spName: 'Productos',
					link: '.products'
				},
				{
					nlName: 'Merken',
					enName: 'Brands',
					deName: 'Marken',
					frName: 'Marques',
					spName: 'Marcas',
					link: '.brands'
				},
				{
					nlName: 'Acties',
					enName: 'Actions',
					deName: 'Aktionen',
					frName: 'Actions',
					spName: 'Acciones',
					link: '.actions'
				},
				{
					nlName: 'Recensies',
					enName: 'Reviews',
					deName: 'Rezensionen',
					frName: 'Commentaires',
					spName: 'Opiniones',
					link: '.reviews'
				},
				{
					nlName: 'Contact',
					enName: 'Contact',
					deName: 'Kontakt',
					frName: 'Contact',
					spName: 'Contacto',
					link: '.contact'
				},
			];
			for (i = 0; i < $scope.shop.brands.length; i++) {
				var newBrand = {
					nlName: $scope.shop.brands[i].name,
					enName: $scope.shop.brands[i].name,
					deName: $scope.shop.brands[i].name,
					frName: $scope.shop.brands[i].name,
					spName: $scope.shop.brands[i].name,
					link: ".brand({brandNameSlug:'" + $scope.shop.brands[i].nameSlug + "',nameSlug:shop.nameSlug})"
				}
				internalLinks.push(newBrand);
			}

			for (i = 0; i < $scope.shop.news.length; i++) {
				var newNewsItem = {
					nlName: $scope.shop.news[i].name,
					enName: $scope.shop.news[i].name,
					deName: $scope.shop.news[i].name,
					frName: $scope.shop.news[i].name,
					spName: $scope.shop.news[i].name,
					link: ".news({newsItemSlug:'" + $scope.shop.news[i].nameSlug + "',nameSlug:shop.nameSlug})"
					//-link: ".news({nameSlug:shop.nameSlug,newsItemSlug:" + $scope.shop.news[i].nameSlug + "})"
				}
				internalLinks.push(newNewsItem);
			}

			$scope.linkOptions = internalLinks;
			//- collecties & producten
		}

		$scope.logoUpload = function (type) {
			if (type === "dark") {
				var shopLogo = $scope.shop.logoDark
			} else {
				var shopLogo = $scope.shop.logoLight
			}
			if (shopLogo) {

				$scope.upload = true;
				Upload.upload({
						url: 'api/shop/uploadlogo',
						data: {
							shopId: $scope.shop._id,
							file: shopLogo
						}
					})
					.then(function (res) {
						shopLogo.src = res.data.file;
						shopLogo = null;
						$scope.upload = false;
						var newShopData = {};
						if (type === 'dark') {
							newShopData['logoDark.src'] = res.data.file;
						} else {
							newShopData['logoLight.src'] = res.data.file;
						}

						updateShopDetails(newShopData);
					}, function (res) {
						console.error("Error: ", res);
					}, function (evt) {
						var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
						$scope.uploadProgress = progressPercentage;
					});
			}
		}

		$scope.closeAlert = function (alert) {
			$scope[alert] = null;
		}

		$scope.deleteShop = function () {
			var modalInstance = $uibModal.open({
				templateUrl: '../views/modal/delete-shop-modal.html',
				controller: 'deleteShopModalController',
				resolve: {
					shopId: function () {
						return $scope.shop._id;
					}
				}
			})

			modalInstance.result.then(function () {
				$state.go('admin.shops')
			})
		}

		function updateShopDetails(shopDetails) {

			if (!shopDetails._id) shopDetails['_id'] = $scope.shop._id;

			$shop.updateShopDetails(shopDetails)
				.then(function (success) {
					$scope.shop = success.updated;
				})
				.catch(function (error) {
					console.error(error);
				})
		}

		$scope.createPurchaseOrderAndShopProductsWithPoolArtileProducts = function () {
			$scope.alert = undefined;
			var body = {
				shop: {
					_id: $scope.shop._id
				}
			}
			$scope.loading = true;
			$scope.alert = {
				type: 'info',
				msg: 'Loading'
			}
			$purchaseOrder.createPurchaseOrderAndShopProductsWithPoolArtileProducts(body).then(function (response) {
				$scope.loading = false;
				$scope.alert = {
					type: 'success',
					msg: 'Purchase order and shop pool article created'
				}
			}).catch(function (error) {
				$scope.loading = false;
				$scope.alert = {
					type: 'danger',
					msg: 'Internal Server Error'
				}
			});
		}

		$scope.replaceTheShopPoolArticle = function () {
			$scope.alert = undefined;
			var body = {
				shop: {
					_id: $scope.shop._id
				}
			}
			$scope.loading = true;
			$scope.alert = {
				type: 'info',
				msg: 'Loading'
			}
			$admin.replaceTheShopPoolArticle(body).then(function (response) {
				$scope.loading = false;
				$scope.alert = {
					type: 'success',
					msg: 'Shop pool article replaced'
				}
			}).catch(function (error) {
				$scope.loading = false;
				$scope.alert = {
					type: 'danger',
					msg: 'Internal Server Error'
				}
			});
		}

		$scope.setShopPrint = function (value) {
			$scope.shop.newPrinter = value
		}

		$scope.saveShopAddress = function () {
			$scope.alert = null

			var jspmVersion = null
			if($scope.shop.jspmVersion) {
				jspmVersion = parseFloat($scope.shop.jspmVersion)
			}

			var updateData = {
				_id: $scope.shop._id,
				email: $scope.shop.email,
				adminLabel: $scope.shop.adminLabel,
				newLabel: $scope.shop.newLabel,
				newPrinter: $scope.shop.newPrinter,
				multiShops: $scope.shop.multiShops,
				isVerified: $scope.shop.isVerified,
				jspmVersion: jspmVersion,
				employeeLockSecurity: $scope.shop.employeeLockSecurity,
				serialNumberManagement: $scope.shop.serialNumberManagement,
				enableGiftcardLabels: $scope.shop.enableGiftcardLabels
			}

			if($scope.shop.printNode && $scope.shop.printNode.enabled === false) {
				updateData.printNode = {
					enabled: false
				}
			}

			if ($scope.shop.address) {
				updateData.address = $scope.shop.address
			}
			if ($scope.shop.phone) {
				updateData.phone = $scope.shop.phone
			}

			if($scope.shop.shopSpecificRepairBagReceiptLayout) {
				updateData.shopSpecificRepairBagReceiptLayout = $scope.shop.shopSpecificRepairBagReceiptLayout
			}

			if($scope.shop.shopSpecificGiftcardLayout) {
				updateData.shopSpecificGiftcardLayout = $scope.shop.shopSpecificGiftcardLayout
			}

			if ($scope.shop.newPlatform) {
				if ($scope.shop.newPlatform.isNewPlatform == true || $scope.shop.newPlatform.isNewPlatform == false ||
					$scope.shop.newPlatform.iBusinessId || $scope.shop.newPlatform.sOrganization || $scope.shop.newPlatform.iOrganizationId || $scope.shop.newPlatform.sDomain) {
					updateData.newPlatform = $scope.shop.newPlatform
				}
			}

			$shop.updateShopDetails(updateData)
				.then(function (updated) {
					$scope.alert = {
						type: 'success',
						msg: 'Updated'
					}
				})
				.catch(function (error) {
					console.error('Error while updating shop details', error);
					$scope.alert = {
						type: 'danger',
						msg: error
					}
				})
		}

		$scope.migrationChange = async function () {
			//$scope.bIsMigrating = true;
			$scope.oMigration.migrationProgressBarStatus =0;
			$scope.oMigration.nPendingItemToMigrate = 0;
			$scope.oMigration.nInvalidItem = 0;
			$scope.oMigration.nMigratedItem = 0;

			switch ($scope.shop.migrationType) {
				case 'businessProductMigration':
					$scope.oMigration.getMigrationDetailUrl = 'migration/getMigratedBusinessProductDetail';
					$scope.oMigration.url = 'migration/business-product';
					$scope.oMigration.sAccessKey = "a62416815fcd3b50a09ac8ec";
					$scope.oMigration.sSecretKey = "8b0c231c8b522a6486f8eb21db0d290761be395b9019ad2cdec8f218c3ebc0c7b433f984646944f65828232261f4142d49fc97925f0eb95d5c0376a210071414";
					break;
				case 'customerMigration':
					$scope.oMigration.getMigrationDetailUrl = 'migration/getCustomerMigrationDetail';
					$scope.oMigration.url = 'migration/customers';
					$scope.oMigration.sAccessKey = "4036ddd28affacbeba7cbdc4";
					$scope.oMigration.sSecretKey = "aecfed0112abf606811516e4e3619b07d747d8e857b395ae90c54dfb040f2db51f0d1acde9d8ac7d80fc9250a8c781a39b2723face45b763703f203de797e956";
					break;
				case 'regularTransactionMigration':
					$scope.oMigration.getMigrationDetailUrl = 'migration/getRegularTransactionMigrationDetail';
					$scope.oMigration.url = 'migration/transaction';
					$scope.oMigration.sAccessKey = "3a6c263554ed094b304a8491";
					$scope.oMigration.sSecretKey = "1f94c254032387e9ccf2b8400d7df24062ef3ddf86eb054170d8eef440ceb28f0a131be4b0855c6c2bdf4723324cfa31561efb186b27aceb928d426623e63991";
					break;
				case 'repairAndOrderTransactionMigration':
					$scope.oMigration.getMigrationDetailUrl = 'migration/getRepairMigrationDetail';
					$scope.oMigration.url = 'migration/repairTransaction';
					$scope.oMigration.sAccessKey = "3a6c263554ed094b304a8499";
					$scope.oMigration.sSecretKey = "1f94c254032387e9ccf2b8400d7df24062ef3ddf86eb054170d8eef440ceb28f0a131be4b0855c6c2bdf4723324cfa31561efb186b27aceb928d426623e63999";
					break;
				case 'deliveredRepairAndOrderMigration':
					$scope.oMigration.getMigrationDetailUrl = 'migration/getDeliveredRepairOrderMigrationDetail';
					$scope.oMigration.url = 'migration/delivered-repair-order';
					$scope.oMigration.sAccessKey = "3eb5293247c311bdc639159e";
					$scope.oMigration.sSecretKey = "c2e56c730533878d4d1051307484f33717ee6bef950221d827d9b2a8a5b644c93cc1c4a7a0d077a8697ba00629dcbc25797aa5d33395477a64cc69a38c800134";
					break;
				case 'giftCardTransactionMigration':
					$scope.oMigration.getMigrationDetailUrl = 'migration/getGiftCardMigrationDetail';
					$scope.oMigration.url = 'migration/giftCardTransaction';
					$scope.oMigration.sAccessKey = "9224606dadbca8d42d6d8a0a";
					$scope.oMigration.sSecretKey = "15445ec8f9ef6be0564a5aef7c0d1974912c20381d1add50362c76349034e52ab45ab043f60730dfa5641ac75c22743fa4e85ffb54a46a212d00ad1231a4c206";
					break;
				case 'webshopTransactionMigration':
					$scope.oMigration.getMigrationDetailUrl = 'migration/getWebshopMigrationDetail';
					$scope.oMigration.url = 'migration/webshopTransaction';
					$scope.oMigration.sAccessKey = "9224606dadbca8d42d6d8a0a";
					$scope.oMigration.sSecretKey = "15445ec8f9ef6be0564a5aef7c0d1974912c20381d1add50362c76349034e52ab45ab043f60730dfa5641ac75c22743fa4e85ffb54a46a212d00ad1231a4c206";
					break;
				case 'quickButtonMigration':
					$scope.oMigration.getMigrationDetailUrl = '';
					$scope.oMigration.url = 'migration/quickButton';
					$scope.oMigration.sAccessKey = "92e0bbad0e7bf560bd3b1b61";
					$scope.oMigration.sSecretKey = "2d9b15f8162419ae47934f53723d847787bad0d24ae41742ff350ac913ffa7515bc9bf876cca9125f5d818048b5c1d8291a7ac4892b98524dfbdb7e6810b1736";
					$scope.bIsMigrating = false;
					break;
				case 'statisticsMigration':
					$scope.oMigration.getMigrationDetailUrl = '';
					$scope.oMigration.url = 'migration/statistics';
					$scope.oMigration.sAccessKey = "92e0bbad0e7bf560bd3b1b61";
					$scope.oMigration.sSecretKey = "2d9b15f8162419ae47934f53723d847787bad0d24ae41742ff350ac913ffa7515bc9bf876cca9125f5d818048b5c1d8291a7ac4892b98524dfbdb7e6810b1736";
					//$scope.oMigration.bChooseDate = $scope.oMigration.bChooseDate;
					$scope.bIsMigrating = false;
					break;
				default:
					$scope.bIsMigrating = true;
					break;
			}
			await getMigratedDetail('init');
		}

		$scope.correctPurchasePrice = function () {
			console.log('correcting---',  $scope.shop._id);
			$scope.bIsProcessing = true;
			$scope.alertMigration = null;

			$api.post('migration/update-purchase-price', {'iShopId': $scope.shop._id}).then(function (result) {
				toastr.success('Purchase price sucessfully corrected!!')
				$scope.alertMigration = { type: 'success', msg: 'Purchase price sucessfully corrected!!' }
				$scope.bIsProcessing = false;
			}).catch(function (error) {
				$scope.bIsProcessing = false;
				console.error('Error while correctPurchasePrice', error);
				$scope.alertMigration = { type: 'danger', msg: 'SOMETHING WENT WRONG!' }
			})
		}

		$scope.correctBusinessProductData = function (){
			console.log('correcting---',  $scope.shop._id);
			$scope.bIsProcessing = true;
			$scope.alertMigration = null;

			$api.post('migration/update-business-product', {'iShopId': $scope.shop._id, 'iBusinessId': $scope.shop.newPlatform.iBusinessId}).then(function (result) {
				if(result){
					toastr.success('Product Data sucessfully corrected!!')
					$scope.alertMigration = { type: 'success', msg: 'Product Data sucessfully corrected in new platform!!' }
				}
				$scope.bIsProcessing = false;
			}).catch(function (error) {
				$scope.bIsProcessing = false;
				console.error('Error while correctPurchasePrice', error);
				$scope.alertMigration = { type: 'danger', msg: 'SOMETHING WENT WRONG!' }
			})
		}
		
	    async function getMigratedDetail(isFrom){
			if ($scope.oMigration.getMigrationDetailUrl) {
				console.log($scope.oMigration.getMigrationDetailUrl);
				$api.post($scope.oMigration.getMigrationDetailUrl, { iShopId: $scope.shop._id }).then((response) => {
					if (response && response.data && response.data.data) {
						$scope.oMigration.nPendingItemToMigrate = response.data.data.nPendingItemToMigrate;
						$scope.oMigration.nInvalidItem = response.data.data.nInvalidItem;
						$scope.oMigration.nMigratedItem = response.data.data.nMigratedItem;
						$scope.oMigration.nLimit = response.data.data.nLimit;
						if(isFrom === 'init' && $scope.oMigration.nPendingItemToMigrate != 0) $scope.bIsMigrating = false;
					} else {
						$scope.oMigration.nPendingItemToMigrate = 0;
						$scope.oMigration.nMigratedItem = 0;
						$scope.oMigration.nLimit = 0;
						$scope.bIsMigrating = false;
					}
				})
			}else{
				$scope.bIsMigrating = false;
			}
			// console.log("Migration " , $scope.oMigration);
		}
		
		$scope.executeMigration = async function () {
			$scope.oMigration.iShopId = $scope.shop._id;

			if($scope.shop.migrationType === 'statisticsMigration'){
				$scope.oMigration.startDate = $scope.oMigration.startDate.toISOString();
				$scope.oMigration.endDate = $scope.oMigration.endDate.toISOString();
			}

			$scope.alert = null;
			$scope.bIsMigrating = true;
            var url = $scope.oMigration.url;
			$scope.alert = { type: 'success', msg: 'Script is running in background. It will take a while' }

			if($scope.oMigration.getMigrationDetailUrl){
				var nTotalLoop = 1;
				$scope.oMigration.migrationProgressBarStatus =0;
				if($scope.oMigration.nPendingItemToMigrate > 0){
					nTotalLoop = Math.ceil(+(($scope.oMigration.nPendingItemToMigrate / $scope.oMigration.nLimit)).toFixed(2));
					// console.log('nTotalLoop before', nTotalLoop , Math.ceil(nTotalLoop));
					// console.log('nTotalLoop after', nTotalLoop)
				}
				var nPerLoopIncreaseWidthPercent = +((100/nTotalLoop).toFixed(2));
	
				// nPerLoopIncreaseWidthPercent = +((100 /nTotalLoop).toFixed(2));
				// console.log("migration: " , nPerLoopIncreaseWidthPercent ," nTotalLoop: ", nTotalLoop , " progress:", $scope.oMigration.migrationProgressBarStatus);
	
				for(var i=0 ; i<nTotalLoop ; i++){
					console.log("Loop: ", i, "of ", nTotalLoop, "--- progress:", $scope.oMigration.migrationProgressBarStatus);
					$scope.oMigration.nCurrentLoop = i;
					await new Promise((resolve , reject)=>{
						$api.post(url, $scope.oMigration).then(function (result) {
							$scope.alert = { type: 'success', msg: 'Script sucessfully done!!' }
							$scope.oMigration.skip = i * $scope.oMigration.nLimit;
							$scope.oMigration.migrationProgressBarStatus = +(($scope.oMigration.migrationProgressBarStatus + nPerLoopIncreaseWidthPercent).toFixed(2));
							if($scope.oMigration.migrationProgressBarStatus > 100) $scope.oMigration.migrationProgressBarStatus = 100;
							getMigratedDetail('execute');
							console.log("Migration progress bar" , $scope.oMigration.migrationProgressBarStatus);
							resolve("Success");
						}).catch(function (error) {
							console.log("858 Error",error);
							$scope.bIsMigrating = false;
							console.error('Error while executeMigration', error);
							$scope.alert = { type: 'danger', msg: error }
							reject("Error");
						})
					})
				}
			}else{
				await new Promise((resolve , reject)=>{
					$api.post(url, $scope.oMigration).then(function (result) {
						$scope.alert = { type: 'success', msg: 'Script sucessfully done!!' }
						resolve("Success");
					}).catch(function (error) {
						console.log("858 Error",error);
						$scope.bIsMigrating = false;
						console.error('Error while executeMigration', error);
						$scope.alert = { type: 'danger', msg: error }
						reject("Error");
					})
				})
			}
			
			$scope.oMigration.migrationProgressBarStatus =0;
			$scope.oMigration.nPendingItemToMigrate = 0;
			$scope.oMigration.nMigratedItem = 0;
			$scope.bIsMigrating = true;

			var updateData = {
				newPlatform: {
					_id: $scope.shop._id,
					email: $scope.shop.email,
					iBusinessId: $scope.oMigration.iBusinessId,
					iLocationId: $scope.oMigration.iLocationId,
					iWorkstationId: $scope.oMigration.iWorkstationId,
					sOrganization: $scope.oMigration.sOrganization,
					sLocationName: $scope.oMigration.sLocationName,
				}
			}
			updateShopDetails(updateData);
		}

		$scope.getBusinessDetail = function(){
			console.log("Get business detail from PN2");
		}

		$scope.changeShopEmail = function (newEmail) {
			if (newEmail === $scope.emailBackup) {
				return;
			}

			if (validateEmail(newEmail)) {
				var dlg = dialogs.confirm($language.translate('CHANGE_EMAILADDRESS'), $language.translate("YOU_MUST_VERIFY_YOUR_NEW_EMAILADDRESS"), {
					size: 'sm'
				})
				dlg.result.then(function (btn) {
					$email.confirmEmail(newEmail, $scope.emailBackup)
						.then(function (result) {
							$scope.emailBackup = angular.copy(newEmail);
							$scope.shop.email = newEmail;
							$scope.saveShopAddress($scope.shop);
							dialogs.notify(undefined, $language.translate('AN_EMAIL_WITH_INSTRUCTIONS_IS_SEND'), {
								size: 'sm'
							});
						})
						.catch(function (reason) {
							console.error("Error while changing e-mailaddress", reason)
							dialogs.error(undefined, reason, {
								size: 'sm'
							});
						})
				}, function (btn) {
					$scope.shop.email = $scope.emailBackup;
				})
			}
		}

		function validateEmail(email) {
			var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		}

		$scope.getShopLabels = function (readOnly) {
			$label.getLabels($scope.shop._id, null, readOnly)
				.then(function(labels){
					if(readOnly) {
						$scope.readOnlyLabels = labels
					} else {
						$scope.labels = labels
					}
				})
				.catch(function (reason){
					console.error('Error while fetching labels for shop', reason)
				})
		}

		$scope.getShopThermalPrints = function(readonly) {
			$thermalPrint.getThermalPrint($scope.shop._id, null, readonly)
				.then(function(prints) {
					if(readonly) {
						$scope.readOnlyPrints = prints
					} else {
						$scope.prints = prints
					}
				})
				.catch(function (reason) {
					console.error('Error while fetching thermal prints for shop', reason)
				})
		}

		$scope.newLabel = function () {
			$scope.labelData.template = ''
		}

		$scope.editLabel = function(index) {
			$scope.labelData.template = angular.copy($scope.labels[index])
			$scope.updateLabelPreview($scope.labelData.template, $scope.labelData.exampleData)
		}

		$scope.editPrint = function(index) {
			$scope.thermalPrintData.print = $scope.prints[index]
		}

		$scope.copyLabel = function (index) {
			var template = angular.copy($scope.readOnlyLabels[index])
			delete template._id
			$scope.saveLabel(template)
		}

		$scope.copyPrint = function (index) {
			var print = angular.copy($scope.readOnlyPrints[index])
			delete print._id
			$scope.saveThermalPrint(print)
		}

		$scope.saveLabel = function(template, string){
			if(string) {
				var label = JSON.parse(template)
			} else {
				var label = angular.copy(template)
			}
			if(!label || label === '') {
				console.error('No label contents')
				return
			}
			if (!label.shopId) {
				label.shopId = $scope.shop._id
			}

			label.readOnly = false

			$label.createLabel(label)
				.then(function (res) {
					$scope.getShopLabels()
					$scope.getShopLabels(true)
					$scope.templateString = ''
					$scope.labelData.template = ''
				})
				.catch(function(reason){
					console.error('Error while creating new label', reason)
					toastr.error($language.translate(reason))
				})
		}

		$scope.saveThermalPrint = function (template, string) {
			var thermalPrint = JSON.parse(JSON.stringify(template))
			if(string) {
				thermalPrint = JSON.parse(template)
			}

			if(!thermalPrint || thermalPrint === '') {
				console.error('No thermalPrint contents')
				toastr.error($language.translate('TEMPLATE_IS_EMPTY'))
				return
			}

			if (!thermalPrint.shopId) {
				thermalPrint.shopId = $scope.shop._id
			}
			thermalPrint.readOnly = false

			$thermalPrint.createThermalPrint(thermalPrint)
				.then(function (res) {
					$scope.getShopThermalPrints()
					$scope.getShopThermalPrints(true)
					$scope.thermalPrintData = {
						print: '',
						template: ''
					}
				})
				.catch(function(reason){
					console.error('Error while creating new thermal print template', reason)
					toastr.error($language.translate(reason))
				})
		}

		$scope.deleteLabel = function(id){
			prompt({
				title: 'Label verwijderen?',
				message: 'Weet u zeker dat u dit label wilt verwijderen?'
			  }).then(function (res) {
				  if(res.primary && res.primary === true) {
					$label.deleteLabel(id)
					.then(function (res) {
						$scope.getShopLabels()
						$scope.getShopLabels(true)
					})
					.catch(function (reason) {
						console.error('Error while deleting label', reason)
					})
				  }
			})
		}

		$scope.deletePrint = function(id){
			prompt({
				title: 'Template verwijderen?',
				message: 'Weet u zeker dat u dit template wilt verwijderen?'
			  }).then(function (res) {
				  if(res.primary && res.primary === true) {
					$thermalPrint.deleteThermalPrint(id).then(function (res) {
						$scope.getShopThermalPrints()
						$scope.getShopThermalPrints(true)
					}).catch(function (reason) {
						console.error('Error while deleting thermal print', reason)
					})

				  }
			})
		}

		$scope.updateLabelPreview = function(template, exampleData) {
			$label.makeLabel(angular.copy(template), angular.copy(exampleData))
				.then(function(label){
					$scope.previewUrl = label.previewUrl
				})
				.catch(function(reason){
					console.error('Error when making label', reason)
				})
		}

		$scope.fetchTheEmptyCollection = function() {
			var body = {
				shopId: $scope.shop._id,
				skip: $scope.localdata.skip,
				limit: $scope.localdata.limit
			}
			$scope.productWithCorruptCollections = [];
			$scope.localdata.loading = true;
			$shop.fetchTheProductWithCorruptCollections(body).then(function (response) {
				$scope.localdata.loading = false;
				if (response && response.data && response.data.data && response.data.data.length) {
					$scope.productWithCorruptCollections = response.data.data;
				}
			}).catch(function (error) {
				console.error(error);
				$scope.localdata.loading = false;
			})
		}

		$scope.fetchedShopDeletedProducts = function () {
			var body = {
				shopId: $scope.shop._id
			}
			$scope.shopdeletedproducts = [];
			$scope.localdata.shopdeletingProdLoader = true;
			$shop.fetchedShopDeletedProducts(body).then(function (response) {
				$scope.localdata.shopdeletingProdLoader = false;
				if (response && response.data && response.data.data && response.data.data.length) {
					$scope.shopdeletedproducts = response.data.data;
				}
			}).catch(function (error) {
				console.error(error);
				$scope.localdata.shopdeletingProdLoader = false;
			})
		}

		$scope.loadmore = function() {
			$scope.localdata.currentPage = $scope.localdata.currentPage + 1;
			$scope.localdata.skip = $scope.localdata.currentPage * 1000;
			$scope.localdata.limit = $scope.localdata.currentPage * $scope.localdata.limit;
			$scope.fetchTheEmptyCollection();
		}

		$scope.fetchAdminShopCollectionsAndShopProductCollections = function () {
			var body = {
				shopId: $scope.shop._id
			}
			$scope.shopUniqueCollections = [];
			$scope.shopProductUniqueCollections = [];
			$shop.fetchAdminShopCollectionsAndShopProductCollections(body).then(function (response) {
				if (response && response.data && response.data.data) {
					var data = response.data.data;
					$scope.shopUniqueCollections = data.shopCollections;
					$scope.shopProductUniqueCollections = data.prodCollection;
				}
			}).catch(function (error) {
				console.error(error);
			})
		}

		$scope.fetchDuplicateCollectionsList = function () {
			$scope.collectionslist = [];
			var bodyColl = {
				shopId: $scope.shop._id,
				pageNumber: $scope.currentPage
			}
			$product.fetchDuplicateCollectionsList(bodyColl).then(function (response) {
				if (response && response.data && response.data.data && response.data.data && response.data.data.products && response.data.data.products.length) {
					$scope.collectionslist = response.data.data.products;
					$scope.totalProducts = response.data.data.totalProducts;
				}
			}).catch(function (error) {
				console.error('Error while fetching duplicated collections: ', error);
			});
		}

		$scope.changePage = function (position, currentPage) {
			$scope.currentPage = currentPage;
			$scope.fetchDuplicateCollectionsList();
		}

		$scope.pageNumbering = function (currentPage) {
			var length = $scope.totalProducts;
			var actualPage = currentPage * $scope.pageSize
			var productCounter = actualPage - $scope.pageSize
			if (actualPage >= length) actualPage = length
			if (length == undefined) {
				length = "Loading.."
			}
			return productCounter + '-' + actualPage + ' (' + length + ')'
		}

		$scope.registerPrintNodeAccount = function () {
			$printnode.createChildAccount({
				shopId: $scope.shop._id,
				email: $scope.shop.email,
				shopName: $scope.shop.name
			})
				.then(function (result) {
					toastr.success(result.data.message)
					$scope.loadShop()
				})
				.catch(function (reason) {
					toastr.error(reason)
				})
		}

		$scope.getAllPrintSettings = function () {
			$api.get('shop/print-settings/all', {
				shopId: $scope.shop._id
			})
				.then(function (printSettings) {
					$scope.printSettings = printSettings.data
				})
				.catch(function (reason){
					toastr.error(reason.data.message)
				})
		}

	}
])