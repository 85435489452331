prismanoteApp.controller('adminShopCompanyDeleteSuggestionsController', ['$uibModal', '$scope', '$rootScope', '$stateParams', '$api', '$q', '$translate', '$http',
  function ($uibModal, $scope, $rootScope, $stateParams, $api, $q, $translate, $http) {
    console.log('Companies suggested to be deleted')
    // Array with all currently displayed items in /products
    $scope.companies = []
    $scope.suggesterlist = []
    $scope.selection = []

    $scope.productCategory = []

    $scope.isVerified = true

    $scope.isNotVerified = true

    $scope.limit = 24

    $scope.setLimit = function (limit) {
      if (limit != null) {
        $scope.limit = limit
      } else {
        $scope.limit = 24
      }
    }

    var getCompanyCount = function () {
      return $q(function (resolve, reject) {
        $api.getCount('products')

          .then(function (productCount) {
            $scope.productCount = productCount

            resolve(productCount)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    // Get new products from the database and add them to the $scope
    $scope.getCompanies = function (params, options) {
      console.log('Get Companies Called')
      return $q(function (resolve, reject) {
        $scope.loading = true

        if (!params) {
          params = {}
        }

        if (!params.filter) {
          params.filter = {}
        }

        var apiRequest = function () {
          params.sort = { dateLastModified: 'desc' }
          params.offset = $scope.companies.length
          params.limit = $scope.limit

          if ($scope.isVerified) {
            params.filter.isVerified = true
          }
          if ($scope.isNotVerified) {
            params.filter.isVerified = false
          }

          if ($scope.isVerified && $scope.isNotVerified) {
            console.log('Is Verified And Not Verified.')
            params.filter.isVerified = 'showAll'
          }
          if ($scope.isVerified == false && $scope.isNotVerified == false) {
            console.log('Is Verified And Not Verified.')
            params.filter.isVerified = 'showAll'
          }

          // if(typeof params.filter.isVerified !== 'boolean') {
          // 	params.filter.isVerified = false;
          // }
          // params.filter.isVerified = $scope.isVerified;

          if (params.filter.category && params.filter.category.$in) {
            $scope.productCategory = params.filter.category.$in
          } else {
            $scope.productCategory = []
          }
          console.log('Params for request', params)
          getCompanyCount()
            .then(function () {
              console.log('Obtained Counts. Now calling products-to-delete')

              $api.get('company/delete-suggestion', params)
                .then(function (response) {
                  console.log('Response obtained: ', response.data)
                  $scope.companies = []
                  $scope.suggesterlist = []
                  for (var i = 0; i < response.data.companies.length; i++) {
                    $scope.companies.push(response.data.companies[i].company)
                    $scope.suggesterlist.push(response.data.companies[i].suggester)
                  }
                })

                .catch(function (reason) {
                  $scope.noProductsFound = true

                  reject(reason)
                })
            })
        }

        // Delete the loaded products and load them again with the new apiParams
        if (options != null && typeof options === 'object') {
          if (options.reset === true) {
            $scope.companies = []
          }
        }

        apiRequest()
      })
    }
    $scope.getCompanies()

    $scope.openCompanyModal = function (company, index) {
      console.log('Opening company Modal', company)
      return $q(function (resolve, reject) {
        console.log('Into Function')
        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/admin-company-modal.html',
          controller: 'adminCompanyModalController',
          size: 'lg',
          resolve: {
            company: function () {
              return company
            },
            suggester: function () {
              return $scope.suggesterlist[index]
            }
          }
        })

        modalInstance.result

          .then(function (response) {
            console.log('Opened')
            resolve(response)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }
  }])
