prismanoteApp.controller('retailerAddCompanyBrandConfirmationModalController', ['$scope', '$uibModalInstance', '$api', '$rootScope', 'data', '$state',
  function ($scope, $uibModalInstance, $api, $rootScope, data, $state) {
    $scope.selectedCompany = data

    for (var i = 0; i < $scope.selectedCompany.brands.length; i++) $scope.selectedCompany.brands[i].isSelected = { c: false }

    $scope.closeModal = function () {
      $uibModalInstance.close($scope.wholesaler)
    }

    $scope.selectedBrands = []

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    $scope.toggle = function (b) {
      b.isSelected.c = !b.isSelected.c
    }

    $scope.AfterSelectingTheBrand = function (isSupplier) {
      // call api to add the brands and company in shop
      var body = {
        shopId: $rootScope.currentShop._id,
        companyId: data._id
      }

      $scope.loading = true
      $api.post('shop-companies', body)
        .then(function (response) {
          $scope.loading = false
          console.log('-------------calling:  -> GOT THE RESPONES')
          $rootScope.$emit('updateCompanyList', 'done ')
          if (isSupplier) {
            console.log('isSUplier: ', isSupplier)
            $state.go('retailer.suppliers')
          }
          $scope.cancel()// closing the modal
        })

        .catch(function (reason) {
          $scope.loading = false
          console.error(reason)
        })
    }

    $scope.addOrRemoveFromArrayChanged = function (brand) {
      var operationPerformed = false
      var currentBrandId = brand._id
      for (var ct = 0; ct < $scope.selectedBrands.length; ct++) {
        if ($scope.selectedBrands[ct]._id == currentBrandId) {
          $scope.selectedBrands.splice(ct, 1)
          operationPerformed = true
        }
      }

      if (!operationPerformed) {
        var flag = $scope.selectedBrands.includes(currentBrandId)
        if (!flag) $scope.selectedBrands.push(brand)
      }
    }
  }])
