prismanoteApp.controller('brandRecommendationOrderControllerNEW',
    ['$scope', '$rootScope', '$stateParams', '$recommendationOrder', 'prompt', '$state', 'cookieService', '$productConfig', '$api', '$purchaseOrder', '$brand', '$shop', '$company',
        function ($scope, $rootScope, $stateParams, $recommendationOrder, prompt, $state, cookieService, $productConfig, $api, $purchaseOrder, $brand, $shop, $company) {

            // console.log('---------------------------$rootScope.recommendationShop-------------------------------------------------------------------------', $rootScope.recommendationShop);

            // General data storing
            $scope.localdata = {
                isCompanyProduct: true, // If true then we are showing the Company product in a view or else we will show the Brand Products
                purchaseOrderNumber: $stateParams.orderNo || '', // Based on this, we are showing in View
                hasShop: false, // Does this catalog already having the shop or not, if not then add it by searching
                purchase_order: {}, // for new purchase order
                searchshopString: '',
                loading: false,
                recommendationShop: $rootScope.recommendationShop, // When coming from the Purchase order
            }

            // For Company View data storing
            $scope.companydata = {
                loading: false,
                search_value: '',  // When searching on the Company Product Search,
                isSearchLoader: false, // When user is searching for the products
                isSearchingProducts: false,
                offsetForPagination: 1,
                showLoadMore: false,
                searchedProducts: {
                    isSearchResponse: false,
                    products: [],
                    count: 0
                },
                products: [] // Products which we are selected and going to add in purchase order
            }

            // For Brand View data storing
            $scope.branddata = {
                loading: false,
                search_value: '',  // When searching on the Company Product Search,
                isSearchLoader: false, // When user is searching for the products
                isSearchingProducts: false,
                offsetForPagination: 1,
                showLoadMore: false,
                searchedProducts: {
                    isSearchResponse: false,
                    products: [],
                    count: 0
                },
                products: [], // Products which we are selected and going to add in purchase order
                brandRecommendationOrder: {}
            }

            $scope.filterDataVal = false
            $rootScope.recommendationShop = undefined; // If available then doing undefind (COMING FROM PURCHASE ORDER)

            // Company Products filter data
            try {
                // $scope.company_filter = JSON.parse(JSON.stringify($recommendationOrder.getDefaultFilterValue()));
                // $scope.company_customFilter = JSON.parse(JSON.stringify($recommendationOrder.getFiltersForCustomFilter()));
                // $scope.company_filter = JSON.parse(JSON.stringify(Object.assign($scope.company_filter, $scope.company_customFilter)));
                $scope.company_searchData = null;
                // $scope.company_filter.limit = 30
                $scope.company_pageSize = '30'
                // $scope.company_filter.offset = 0;
                $scope.company_totalProductsData = 0;
                $scope.company_totalProductsDataShowFlag = false;
                $scope.company_currentPage = 1;
            } catch (e) {
                console.log(e);
            }

            // Brand Products filter data
            try {
                // $scope.brand_filter = {};
                // $scope.brand_filter = JSON.parse(JSON.stringify($recommendationOrder.getDefaultFilterValue()));
                // $scope.brand_customFilter = JSON.parse(JSON.stringify($recommendationOrder.getFiltersForCustomFilter()));
                // $scope.brand_filter = JSON.parse(JSON.stringify(Object.assign($scope.company_filter, $scope.company_customFilter)));
                $scope.brand_searchData = null;
                // $scope.brand_filter.limit = 30
                $scope.brand_pageSize = '30';
                // $scope.brand_filter.offset = 0;
                $scope.brand_totalProductsData = 0;
                $scope.brand_totalProductsDataShowFlag = false;
                $scope.brand_currentPage = 1;
            } catch (e) {
                console.log(e);
            }

            function setTheCompanyVerified(companyDetail, isCompanyProduct) {
                if (companyDetail && companyDetail.processPurchaseOrder && (typeof ($rootScope.isCompanyProduct) == 'undefined' || $rootScope.isCompanyProduct)) {
                    $scope.localdata.isCompanyProduct = true;
                    $rootScope.isCompanyProduct = true;
                } else {
                    $scope.localdata.isCompanyProduct = false;
                    $rootScope.isCompanyProduct = false;
                }
                if (isCompanyProduct) $scope.localdata.isCompanyProduct = isCompanyProduct;
                console.info('Is it coming here?-----------------------------------------------------------------------------------> ', companyDetail, $scope.localdata.isCompanyProduct);
            }

            $scope.getAllShopForPurchaseOrder = function () {
                $shop.getAllShopForPurchaseOrder({ companyId: $rootScope.currentCompany._id }).then(function (shopresponse) {
                    $scope.shops = [];
                    if (shopresponse && shopresponse.data && shopresponse.data.data) {
                        $scope.shops = shopresponse.data.data;
                    }
                }).catch(function (shoperr) {
                    console.error('shoperr: ', shoperr);
                });
            }

            // If Purchase order number is available then we are going to find the purchase order details
            $scope.getThePurchaseOrder = function (purchaseOrderNumber) {
                var body = { purchaseOrderNumber: purchaseOrderNumber }
                $recommendationOrder.getPurchaseOrderDetail(body).then(function (response) {
                    if (response && response.data && response.data.data) {
                        // console.log(' response,: ', response.data.data);
                        if (!$scope.recommendationOrder) {
                            $scope.recommendationOrder = {
                                purchase_order: response.data.data
                            }
                            return;
                        }
                        $scope.recommendationOrder.purchase_order = response.data.data;
                    }
                    // console.log('$scope.recommendationOrder called: ', $scope.recommendationOrder);
                }).catch(function (error) {
                    console.log('getThePurchaseOrder error: ', error);
                });
            }

            $scope.backToPurchaseOrder = function () {
                $rootScope.isBackOfRecommendation = true;
                $state.go('brand.purchase-order', { orderNo: $scope.localdata.purchaseOrderNumber });
            }

            // Deleting the purchase order's item if products is more than one(1) else we will show the warning!
            $scope.deletePurchaseOrderProduct = function (index) {
                var purchaseOrder = $scope.recommendationOrder.purchase_order
                if (purchaseOrder.items.length === 1) {
                    prompt({
                        title: 'Warning!',
                        message: 'You have only one purchase order product. If you want to delete then go the purchase order page',
                        buttons: [{ label: 'Go to the Purchase order', primary: true }, { label: 'CANCEL', primary: false, cancel: true }]
                    }).then(function (response) {
                        console.log('response: ', response)
                        if (response.primary) {
                            $state.go('retailer.purchase-order', { orderNo: $stateParams.orderNo })
                        }
                    }).catch(function (error) {
                        console.log('error: inprompnt: ', error)
                    })
                } else {
                    // console.log('Hey Delete Purchase Order Product')
                    var body = {
                        purchaseOrderId: purchaseOrder._id,
                        status: purchaseOrder.status[0].status,
                        itemId: purchaseOrder.items[index]._id,
                        companyId: $rootScope.currentCompany._id
                    }
                    $scope.companydata.loading = true;
                    $recommendationOrder.deletePurchaseOrderProduct(body).then(function (response) {
                        $scope.companydata.loading = false;
                        // $scope.recommendationOrder.purchase_order.items.splice(index, 1);
                        $scope.getThePurchaseOrder($scope.localdata.purchaseOrderNumber)
                    }).catch(function (error) {
                        $scope.companydata.loading = false;
                        console.log('error: ', error)
                    })
                }
            }

            $scope.saveCurrentPage = function (currentPage) {
                cookieService.setCookie('servicesCurrentPage=' + currentPage, 1800)
            }


            // It's not needed to fetch when fetching products, but we can fetch this in background
            $scope.getCollectionsFilterForRecommendation = function (companyId) {

                return;
                if (companyId || $stateParams.orderNo) {
                    var body = {
                        companyId: companyId,
                        purchaseOrderNumber: $stateParams.orderNo,
                        isBrandProduct: !$scope.localdata.isCompanyProduct
                    }
                    if ($scope.localdata.isCompanyProduct) body.companyId = $rootScope.currentCompany._id;

                    // console.log('-------------------------------------------------5555555555555555555555555555555555555--------------------------getCollectionsFilterForRecommendation called: ', body);
                    $recommendationOrder.getCollectionsFilterForRecommendation(body).then(function (response) {
                        // console.info('--------------------------------8888888888888888888888888------------------: ', response.data);
                        if (response && response.data && response.data.data) {
                            var collectionFilters = response.data.data;

                            // When user do refresh at time we need to keep this filter as it is
                            var localStorageFilter = localStorage.getItem('brandrecommendationFilter');
                            var parsedLocalStorageFilter;
                            // if (localStorageFilter && localStorageFilter != 'undefined') {
                            //     console.log('localStorageFilter4: ', typeof localStorageFilter, JSON.parse(localStorageFilter));
                            //     parsedLocalStorageFilter = JSON.parse(localStorageFilter);
                            //     $scope.brand_filter = JSON.parse(JSON.stringify(parsedLocalStorageFilter));
                            // }

                            // CLIENT GROUP COLLECTIONS START
                            var clientGroupCollections = collectionFilters.clientGroupCollections;
                            if (!$scope.brand_filter) $scope.brand_filter = {};
                            $scope.brand_filter.clientGroupCollections = clientGroupCollections && clientGroupCollections.length ? clientGroupCollections : [];

                            // For If already selected collection then we need to make it cheked marked true
                            if (parsedLocalStorageFilter && parsedLocalStorageFilter.selectedclientGroupCollections && parsedLocalStorageFilter.selectedclientGroupCollections.length) {
                                var clientGroups = $scope.brand_filter.clientGroupCollections;
                                var clientGroupsLength = clientGroups.length;
                                var selectedColl = parsedLocalStorageFilter.selectedclientGroupCollections;
                                // console.log('clientGroups selectedColl called: ', selectedColl, clientGroups);
                                for (i = 0; i < selectedColl.length; i++) {
                                    for (j = 0; j < clientGroupsLength; j++) {
                                        if (selectedColl[i] && clientGroups[j] && clientGroups[j]._id &&
                                            selectedColl[i] === clientGroups[j]._id) {
                                            clientGroups[j].isSelected = true;
                                        }
                                    }
                                }

                            }
                            // CLIENT GROUP COLLECTIONS END
                        }
                    }).catch(function (error) {
                        console.log('getCollectionsFilterForRecommendation error: ', error)
                    });
                }
            }

            $scope.getBrandRetailerRecommendationPurchaseOrder = function () {
                $scope.totalProductsData = 0;
                if (!$scope.company_filter || !$scope.company_filter.suppliers || !$scope.company_filter.suppliers.length) {
                    // $scope.company_filter.suppliers = [
                    //     {
                    //         _id: $rootScope.currentCompany._id,
                    //         name: $rootScope.currentCompany.name,
                    //         nameSlug: $rootScope.currentCompany.nameSlug,
                    //         isSelected: true,
                    //     }
                    // ]
                }
                var localStorageFilter = localStorage.getItem('recommendationFilter');
                var parsedLocalStorageFilter;
                if (localStorageFilter) {
                    parsedLocalStorageFilter = JSON.parse(localStorageFilter);
                    $scope.company_filter = JSON.parse(JSON.stringify(parsedLocalStorageFilter));
                }
                if ($scope.localdata.offsetForPagination === 1) $scope.company_filter.offset = 0; // If Refreshing the page then we taking 1st page always
                var selelctedShop = $scope.recommendationOrder && $scope.recommendationOrder.purchase_order && $scope.recommendationOrder.purchase_order.shop && $scope.recommendationOrder.purchase_order.shop._id ? $scope.recommendationOrder.purchase_order.shop : $scope.localdata.recommendationShop && $scope.localdata.recommendationShop._id ? $scope.localdata.recommendationShop : undefined;

                console.log('company_filter called: ', $scope.company_filter);

                var body = {
                    purchaseOrderNumber: $stateParams.orderNo,
                    companyId: $rootScope.currentCompany._id,
                    shopId: selelctedShop && selelctedShop._id ? selelctedShop._id : undefined,
                    company_filter: JSON.parse(JSON.stringify($scope.company_filter)),
                    language: $rootScope.language,
                    sortBasedOn: $rootScope.currentCompany.sortBasedOn
                }
                if (body.company_filter) {

                    console.info('============================> getTheCompanyProducts called: ', body.company_filter);
                    if (body.company_filter.strapWidths) body.company_filter.strapWidths = body.company_filter.strapWidths.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.width });
                    if (body.company_filter.jewelTypes) body.company_filter.jewelTypes = body.company_filter.jewelTypes.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                    if (body.company_filter.strapModels) body.company_filter.strapModels = body.company_filter.strapModels.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.model });
                    if (body.company_filter.jewelMaterials != undefined) {
                        body.company_filter.jewelMaterials = body.company_filter.jewelMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                    }
                    if (body.company_filter.diamondMaterials != undefined) {
                        body.company_filter.diamondMaterials = body.company_filter.diamondMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                    }
                    if (body.company_filter.watchMaterials) {
                        body.company_filter.watchMaterials = body.company_filter.watchMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                    }

                    if (body.company_filter.watchCaseMaterials) {
                        body.company_filter.watchCaseMaterials = body.company_filter.watchCaseMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.material });
                    }
                    if (body.company_filter && body.company_filter.watchStrapMaterails) {
                        body.company_filter.watchStrapMaterails = body.company_filter.watchStrapMaterails.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.material });
                    }
                    if (body.company_filter.watchTypes) {
                        body.company_filter.watchTypes = body.company_filter.watchTypes.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                    }
                    if (body.company_filter.watchIndications) {
                        body.company_filter.watchIndications = body.company_filter.watchIndications.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.indication });
                    }
                    body.company_filter.suppliers = undefined;
                    // console.log('BODY FILTER: ', body.company_filter);
                    // body.filter.selectedFilters = undefined; // We don't need to send
                    $scope.companydata.loading = true;
                    body.filter = body.company_filter;
                    body.company_filter = undefined;
                }

                console.log('getBrandRetailerRecommendationPurchaseOrder called -----> ', body);

                $recommendationOrder.getBrandRetailerRecommendationPurchaseOrder(body).then(function (response) {
                    $scope.localdata.loading = false;
                    $scope.companydata.loading = false;
                    console.log('getBrandRetailerRecommendationPurchaseOrder body -------7777777777777777777777777777777777777777777777777777777777--------------------> ', response);
                    if (response && response.data && response.data.data) {
                        if (response.data.data.productDetail) {
                            $scope.recommendationOrder = { products: response.data.data.productDetail.products, purchase_order: response.data.data.purchase_order };
                            console.log('$scope.recommendationOrder', $scope.recommendationOrder)
                            console.log('$rootScope.currentCompany.sortBasedOnSortingNumber', $rootScope.currentCompany.sortBasedOnSortingNumber);
                            console.log('$scope.recommendationOrder.products.products', $scope.recommendationOrder.products.products);
                            /* if ($scope.recommendationOrder.purchase_order && $scope.recommendationOrder.purchase_order.companyDetail && $scope.recommendationOrder.purchase_order.companyDetail.recommendationOrderFilters && $scope.recommendationOrder.purchase_order.companyDetail.recommendationOrderFilters.length > 0 && 
                             !$scope.enableFilterButtons ) {
                              $scope.enableFilterButtons = true
                              $scope.mainFilterButtons = $scope.recommendationOrder.purchase_order.companyDetail.recommendationOrderFilters
                              console.log('$scope.mainFilterButtons::',$scope.mainFilterButtons);
                            } */
                            if ($rootScope.currentCompany.sortBasedOnSortingNumber == "productSortingNumber") {
                                for (var k = 0; k < $scope.recommendationOrder.products.products.length; k++) {
                                    var soldAmount = 0;
                                    soldAmount = $scope.recommendationOrder.products.products[k].products.productDetail.sortingNumber ? $scope.recommendationOrder.products.products[k].products.productDetail.sortingNumber - 500000000000 : 0
                                    var topMostSold = "soldAmount";
                                    if (soldAmount >= 10) {
                                        $scope.recommendationOrder.products.products[k].products[topMostSold] = "Zeer goed verkocht";
                                    } else if (soldAmount > 3 && soldAmount < 10) {
                                        $scope.recommendationOrder.products.products[k].products[topMostSold] = "Goed verkocht"
                                    } else {
                                        $scope.recommendationOrder.products.products[k].products[topMostSold] = null
                                    }
                                }
                            } else if ($rootScope.currentCompany.sortBasedOnSortingNumber == "companyProductSortingNumber") {
                                console.log('get companyProductSortingNumber');
                                for (var k = 0; k < $scope.recommendationOrder.products.products.length; k++) {
                                    var soldAmount = 0;
                                    soldAmount = $scope.recommendationOrder.products.products[k].products.sortingNumber ? $scope.recommendationOrder.products.products[k].products.sortingNumber - 500000000000 : 0
                                    var topMostSold = "soldAmount";
                                    if (soldAmount >= 10) {
                                        $scope.recommendationOrder.products.products[k].products[topMostSold] = "Zeer goed verkocht";
                                    } else if (soldAmount > 3 && soldAmount < 10) {
                                        $scope.recommendationOrder.products.products[k].products[topMostSold] = "Goed verkocht"
                                    } else {
                                        $scope.recommendationOrder.products.products[k].products[topMostSold] = null
                                    }
                                }
                            }
                            $scope.recommendationOrder.recommendationOrderFilters = response.data.data.recommendationOrderFilters.map(function (f) {
                                if (f.orderFilterCategoryName === '' || !f.orderFilterCategoryName) {
                                    f.orderFilterCategoryName = 'NO_GROUP'
                                }
                                return f
                            }); // When purchase order already not created

                            // When creating purchase order from recommendation (COMING FROM BRAND PURCHASE ORDER) 
                            if ($scope.localdata.recommendationShop) {
                                $scope.getAllShopForPurchaseOrder();
                                $scope.selectTheShop($scope.localdata.recommendationShop);
                            }

                            if ($scope.recommendationOrder.purchase_order) {
                                if ($scope.recommendationOrder.purchase_order.shop) {
                                    $scope.localdata.hasShop = true;
                                }

                                // When purchase order already created then we are removing the shop which we got from the PURCHASE ORDER
                                if ($scope.recommendationOrder.purchase_order.number) $scope.localdata.recommendationShop = undefined;
                            }

                            var purchaseOrder = $scope.recommendationOrder.purchase_order;
                            if (purchaseOrder && purchaseOrder.shop && purchaseOrder.shop._id) {
                                $scope.getCollectionsFilterForRecommendation(purchaseOrder.shop._id);
                            } else {
                                $scope.getCollectionsFilterForRecommendation();
                            }

                            var totalProducts = $scope.recommendationOrder.products.metadata[0].total;
                            if ($scope.company_filter.limit < totalProducts && (($scope.company_filter.offset * $scope.company_filter.limit) + $scope.company_filter.limit) < totalProducts) {
                                $scope.localdata.showLoadMore = true;
                            } else {
                                $scope.localdata.showLoadMore = false;
                            }
                            cookieService.getCookie('servicesCurrentPage')
                                .then(function (currentPage) {
                                    $scope.currentPage = currentPage
                                })
                                .catch(function () {
                                    $scope.currentPage = 1
                                })
                            window.scrollTo(0, 0);
                        }

                        if (response.data.data.fitlerDetail) {
                            // BRANDS
                            var brands = response.data.data.fitlerDetail.brands;
                            if (!$scope.company_filter.brands || !$scope.company_filter.brands.length) $scope.company_filter.brands = brands && brands.length ? brands : [];
                        }

                        // IF PURCHASE ORDER CREATED AND SEARCHING AT THAT TIME, DUE TO THAT SEACH FOCUS LOST
                        if ($rootScope.recommendationOrderSearchValue) {
                            $scope.localdata.search_value = $rootScope.recommendationOrderSearchValue;
                            $rootScope.recommendationOrderSearchValue = undefined;
                            $scope.searchProductsForCompanyRecommnedation();
                        }
                    }
                    if ($scope.searchData != null) {
                        $scope.localdata.search_value = $scope.searchData;
                        $scope.searchProductsForCompanyRecommnedation('filter');
                    } else {
                        $scope.searchData = null
                    }
                }).catch(function (error) {
                    $scope.localdata.loading = false;
                    console.error('error: ', error);
                })

            }

            $scope.getTheCompanyProducts = function (isCompanyProduct) {
                try {
                    console.info('============================> getTheCompanyProducts called: ', $scope.localdata.recommendationShop);
                    $scope.company_totalProductsData = 0;
                    // When user do refresh at the time we need to keep this filter as it is
                    var localStorageFilter = localStorage.getItem('recommendationFilter');
                    var parsedLocalStorageFilter;
                    // if (localStorageFilter && localStorageFilter != 'undefined') {
                    //     // console.log('localStorageFilter3: ', typeof localStorageFilter, localStorageFilter, localStorageFilter == 'undefined', typeof localStorageFilter == undefined);
                    //     // console.log('localStorageFilter3: ', typeof localStorageFilter, localStorageFilter, JSON.parse(localStorageFilter));
                    //     parsedLocalStorageFilter = JSON.parse(localStorageFilter);
                    //     $scope.company_filter = JSON.parse(JSON.stringify(parsedLocalStorageFilter));
                    // }
                    if (!$scope.company_filter) $scope.company_filter = { offset: 0, limit: 30 };

                    if ($scope.companydata.offsetForPagination === 1) $scope.company_filter.offset = 0; // If Refreshing the page then we taking 1st page always
                    else $scope.company_filter.offset = $scope.companydata.offsetForPagination - 1;

                    console.log('getTheCompanyProducts called 123: ', $scope.recommendationOrder);

                    var selelctedShop = $scope.recommendationOrder && $scope.recommendationOrder.purchase_order && $scope.recommendationOrder.purchase_order.shop && $scope.recommendationOrder.purchase_order.shop._id ? $scope.recommendationOrder.purchase_order.shop : ($scope.data && $scope.localdata.recommendationShop && $scope.localdata.recommendationShop._id ? $scope.localdata.recommendationShop : undefined);

                    var body = {
                        purchaseOrderNumber: $stateParams.orderNo,
                        company_filter: JSON.parse(JSON.stringify($scope.company_filter)),
                        companyId: $rootScope.currentCompany._id,
                        language: $rootScope.language,
                        sortBasedOn: $scope.selectedShop && $scope.selectedShop.sortBasedOn ? $scope.selectedShop.sortBasedOn : undefined
                    }
                    if (body.company_filter) {

                        console.info('============================> getTheCompanyProducts called: ', body.company_filter);
                        if (body.company_filter.strapWidths) body.company_filter.strapWidths = body.company_filter.strapWidths.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.width });
                        if (body.company_filter.jewelTypes) body.company_filter.jewelTypes = body.company_filter.jewelTypes.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                        if (body.company_filter.strapModels) body.company_filter.strapModels = body.company_filter.strapModels.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.model });
                        if (body.company_filter.jewelMaterials != undefined) {
                            body.company_filter.jewelMaterials = body.company_filter.jewelMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                        }
                        if (body.company_filter.diamondMaterials != undefined) {
                            body.company_filter.diamondMaterials = body.company_filter.diamondMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                        }
                        if (body.company_filter.watchMaterials) {
                            body.company_filter.watchMaterials = body.company_filter.watchMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                        }

                        if (body.company_filter.watchCaseMaterials) {
                            body.company_filter.watchCaseMaterials = body.company_filter.watchCaseMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.material });
                        }
                        if (body.company_filter && body.company_filter.watchStrapMaterails) {
                            body.company_filter.watchStrapMaterails = body.company_filter.watchStrapMaterails.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.material });
                        }
                        if (body.company_filter.watchTypes) {
                            body.company_filter.watchTypes = body.company_filter.watchTypes.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                        }
                        if (body.company_filter.watchIndications) {
                            body.company_filter.watchIndications = body.company_filter.watchIndications.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.indication });
                        }
                        body.company_filter.suppliers = undefined;
                        // console.log('BODY FILTER: ', body.company_filter);
                        // body.filter.selectedFilters = undefined; // We don't need to send
                        $scope.companydata.loading = true;
                        body.filter = body.company_filter;
                        body.company_filter = undefined;
                    }

                    console.log('response.data.data.productDetail::::==================================> 123: ', JSON.parse(JSON.stringify(body.filter, null, 2)));
                    console.log('-------------...........................BODY: --------', body);

                    $recommendationOrder.getCompanyProductsRecommendation(body).then(function (response) {
                        $scope.companydata.loading = false;
                        console.log('==================getCompanyProductsRecommendation===11111======================================================================>:', response);
                        if (response && response.data && response.data.data) {

                            // PRODUCTS AND PURCHASE ORDER RESPONSE
                            if (response.data.data.productDetail) {
                                var purchaseorder;
                                if ($scope.recommendationOrder && $scope.recommendationOrder.purchase_order) purchaseorder = $scope.recommendationOrder.purchase_order

                                $scope.recommendationOrder = { products: response.data.data.productDetail.products, purchase_order: response.data.data.purchase_order, sortingData: response.data.data.sortingOrderData };
                                if (!$scope.recommendationOrder.purchase_order) $scope.recommendationOrder.purchase_order = purchaseorder;

                                // console.log('RESPONSE: retailerRecommendationOrderController: ', $scope.recommendationOrder);
                                $scope.sortingOrderValue = $scope.recommendationOrder.sortingData.sortBasedOnSortingNumber
                                if ($scope.recommendationOrder.sortingData.sortBasedOnSortingNumber == "companyProductSortingNumber") {
                                    for (var k = 0; k < $scope.recommendationOrder.products.products.length; k++) {
                                        var soldAmount = 0;
                                        soldAmount = $scope.recommendationOrder.products.products[k].products.sortingNumber ? $scope.recommendationOrder.products.products[k].products.sortingNumber - 500000000000 : 0
                                        var topMostSold = "soldAmount";
                                        if (soldAmount >= 10) {
                                            $scope.recommendationOrder.products.products[k].products[topMostSold] = "Zeer goed verkocht";
                                        } else if (soldAmount > 3 && soldAmount < 10) {
                                            $scope.recommendationOrder.products.products[k].products[topMostSold] = "Goed verkocht"
                                        } else {
                                            $scope.recommendationOrder.products.products[k].products[topMostSold] = null
                                        }
                                        if($scope.recommendationOrder && $scope.recommendationOrder.purchase_order && $scope.recommendationOrder.purchase_order.items && $scope.recommendationOrder.purchase_order.items.length > 0){
                                            $scope.recommendationOrder.purchase_order.items.filter(function (item) {
                                                if(item.product_id == $scope.recommendationOrder.products.products[k].products.productDetail._id){
                                                    $scope.recommendationOrder.products.products[k].products['quantity'] = item.quantity
                                                    $scope.recommendationOrder.products.products[k].products['selected'] = true
                                                }
                                            })
                                        }
                                    }
                                } else if ($scope.recommendationOrder.sortingData.sortBasedOnSortingNumber == "productSortingNumber") {
                                    for (var k = 0; k < $scope.recommendationOrder.products.products.length; k++) {
                                        var soldAmount = 0;
                                        soldAmount = $scope.recommendationOrder.products.products[k].products.productDetail.sortingNumber ? $scope.recommendationOrder.products.products[k].products.productDetail.sortingNumber - 500000000000 : 0
                                        var topMostSold = "soldAmount";
                                        if (soldAmount >= 10) {
                                            $scope.recommendationOrder.products.products[k].products[topMostSold] = "Zeer goed verkocht";
                                        } else if (soldAmount > 3 && soldAmount < 10) {
                                            $scope.recommendationOrder.products.products[k].products[topMostSold] = "Goed verkocht"
                                        } else {
                                            $scope.recommendationOrder.products.products[k].products[topMostSold] = null
                                        }
                                    }
                                }
                                $scope.recommendationOrder.recommendationOrderFilters = response.data.data.recommendationOrderFilters.map(function (f) {
                                    if (f.orderFilterCategoryName === '' || !f.orderFilterCategoryName) {
                                        f.orderFilterCategoryName = 'NO_GROUP'
                                    }
                                    return f
                                }); // When purchase order already not created


                                // WHEN CREATING NEW PURCHASE ORDER
                                if (!$scope.recommendationOrder.purchase_order) {
                                    $scope.recommendationOrder.purchase_order = { companyDetail: $rootScope.currentCompany };
                                    if (isCompanyProduct) $scope.localdata.isCompanyProduct = isCompanyProduct;
                                    setTheCompanyVerified($scope.recommendationOrder.purchase_order.companyDetail, isCompanyProduct);
                                }

                                $scope.recommendationOrder.recommendationOrderFilters = response.data.data.recommendationOrderFilters; // When purchase order already not created
                                // console.log('$scope.recommendationOrder HEYY: ', $scope.recommendationOrder);

                                var purchaseOrder = $scope.recommendationOrder.purchase_order;

                                console.log('---------------------purchaseOrder---------\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\--------------: ', purchaseOrder, $scope.localdata.recommendationShop);

                                if (purchaseOrder) {
                                    if (purchaseOrder.companyDetail && purchaseOrder.companyDetail._id) {
                                        console.log('$scope.localdata.hasSupplier: ', $scope.localdata.hasSupplier);
                                        $scope.localdata.hasSupplier = true;
                                    }

                                    // When purchase order already created then we are removing the shop which we got from the PURCHASE ORDER
                                    if (purchaseOrder.number) $scope.localdata.recommendationShop = undefined;
                                } else if (!purchaseOrder && $scope.localdata.recommendationShop) {
                                    $scope.recommendationOrder = {
                                        purchase_order: {
                                            companyDetail: $scope.localdata.recommendationShop,
                                        }
                                    }
                                }

                                if (purchaseOrder.companyDetail && purchaseOrder.companyDetail._id) {
                                    $scope.getCollectionsFilterForRecommendation(purchaseOrder.companyDetail._id);
                                }
                                var totalProducts = $scope.recommendationOrder.products.metadata[0].total;
                                if ($scope.company_filter.limit < totalProducts && (($scope.company_filter.offset * $scope.company_filter.limit) + $scope.company_filter.limit) < totalProducts) {
                                    $scope.companydata.showLoadMore = true;
                                } else {
                                    $scope.companydata.showLoadMore = false;
                                }
                                cookieService.getCookie('servicesCurrentPage')
                                    .then(function (currentPage) {
                                        $scope.company_currentPage = currentPage
                                    })
                                    .catch(function () {
                                        $scope.company_currentPage = 1
                                    })
                                window.scrollTo(0, 0);
                            }

                            // SHOW SUPPLIER FILTER
                            if ($scope.recommendationOrder && $scope.recommendationOrder.purchase_order &&
                                $scope.recommendationOrder.purchase_order.companyDetail && $scope.recommendationOrder.purchase_order.companyDetail._id) {
                                var companyDetail = $scope.recommendationOrder.purchase_order.companyDetail;
                                setTheCompanyVerified(companyDetail, isCompanyProduct);
                                $scope.company_filter.suppliers = [{
                                    _id: companyDetail._id,
                                    name: companyDetail.name,
                                    nameSlug: companyDetail.nameSlug,
                                    isSelected: true,
                                }]
                            }

                            // if (response.data.data.fitlerDetail) {
                            //     // BRANDS
                            //     var brands = response.data.data.fitlerDetail.brands;
                            //     if (!$scope.company_filter.brands.length) $scope.company_filter.brands = brands && brands.length ? brands : [];
                            // }

                            // IF PURCHASE ORDER CREATED AND SEARCHING AT THAT TIME, DUE TO THAT SEACH FOCUS LOST
                            if ($rootScope.recommendationOrderSearchValue) {
                                $scope.companydata.search_value = $rootScope.recommendationOrderSearchValue;
                                $rootScope.recommendationOrderSearchValue = undefined;
                                $scope.searchProductsForCompanyRecommnedation();
                            }
                        }
                        if ($scope.searchData != null) {
                            $scope.companydata.search_value = $scope.searchData;
                            $scope.searchProductsForCompanyRecommnedation('filter');
                        } else {
                            $scope.searchData = null
                        }
                    }).catch(function (error) {
                        $scope.companydata.loading = false;
                        console.log('error: ', error)
                    });

                } catch (e) {
                    console.log('----------> ', e);
                }
            }

            // code for autocomplete
            $scope.companySelected = function (selected, isSearchRequire) {
                console.log('--company selcetd: 123 ===================111=======================> ', selected);
                var companyObject = selected.originalObject; // because in api we may have any thing of company as input
                var isValidCompany = true;
                var message = '';
                if (!companyObject.address || !companyObject.address.street || !companyObject.address.houseNumber || !companyObject.address.city ||
                    !companyObject.address.country || !companyObject.address.postalCode) {
                    isValidCompany = false;
                    message = 'Company address is not proper. First fill it proper then you can edit it';
                }

                if (!companyObject.phone || !companyObject.phone.landLine) {
                    isValidCompany = false;
                    message = 'Company does not have a phone number (fixed/landline)';
                }

                if (!companyObject.email) {
                    isValidCompany = false;
                    message = 'Company does not have proper email address';
                }

                if (isValidCompany) {
                    $scope.localdata.recommendationShop = companyObject; // BECAUSE USER NOT COMING FROM THE PURCHASE ORDER BUT COMING FROM THE DASHBOARD
                    $scope.invalidSupplier = false;
                    $scope.selectedShop = companyObject;
                    $scope.recommendationOrder = {
                        purchase_order: {
                            companyDetail: companyObject,
                            // shop: {
                            //   _id: $rootScope.currentCompany._id,
                            //   name: $rootScope.currentCompany.name,
                            //   nameSlug: $rootScope.currentCompany.nameSlug,
                            // }
                        }
                    }
                    $scope.localdata.purchase_order.sendDetails = {
                        address: $scope.selectedShop.address,
                        firstName: $scope.selectedShop.name,
                        phone: $scope.selectedShop.phone,
                        email: $scope.selectedShop.email
                    }

                    if ($scope.localdata.purchase_order.sendDetails && $scope.localdata.purchase_order.sendDetails.phone) {
                        if ($scope.localdata.purchase_order.sendDetails.phone.length < 8 || $scope.localdata.purchase_order.sendDetails.phone.length > 12) {
                            $scope.localdata.purchase_order.sendDetails.mobilePhone = ""
                        }
                    }

                    $scope.localdata.hasSupplier = true;
                    if (isSearchRequire) {
                        $scope.getCompanyBRANDProductsRecommendation(true);
                        $scope.getDynamicRecommendationFilter(true);

                        $scope.getTheCompanyProducts(true);
                        $scope.getDynamicRecommendationFilter(false);
                    }
                    // if ($scope.localdata.purchase_order && $scope.localdata.purchase_order.shop && $scope.localdata.purchase_order.shop._id && companyObject && companyObject._id) {
                    //   $scope.getShopClientGroups($scope.localdata.purchase_order.shop._id, companyObject._id); // Getting clien groups of particular shop and company
                    // }
                } else if (companyObject && companyObject.nameSlug) {
                    $scope.invalidSupplierNameSlug = companyObject.nameSlug;
                    $scope.invalidSupplier = true;
                    $scope.invalidSupplierMessage = message;
                } else {
                    $scope.invalidSupplier = true;
                    $scope.invalidSupplierMessage = message;
                }
            };

            // Selecting the shop
            $scope.selectTheShop = function (shop, isFromView) {
                console.log('selectTheShop called: ', shop, isFromView);
                $scope.foundShops = [];
                $scope.localdata.searchshopString = '';
                $scope.localdata.hasShop = true;
                $scope.localdata.recommendationShop = shop; // BECAUSE USER NOT COMING FROM THE PURCHASE ORDER BUT COMING FROM THE DASHBOARD
                if (!$scope.recommendationOrder) $scope.recommendationOrder = {};
                $scope.recommendationOrder.purchase_order = {
                    billingDetails: {
                        address: [shop.address],
                    },
                    companyDetail: {
                        _id: $rootScope.currentCompany._id,
                        nameSlug: $rootScope.currentCompany.nameSlug,
                        name: $rootScope.currentCompany.name,
                    }
                }

                // If Company edited address from the brand/shopDetail page then we have to show that
                if (shop && shop.companyShop) {
                    $scope.recommendationOrder.purchase_order.companyShopDetail = shop.companyShop;
                }
                $scope.recommendationOrder.purchase_order.shop = {
                    _id: shop._id,
                    name: shop.name,
                    nameSlug: shop.nameSlug
                }
                $scope.selectedShop = $scope.recommendationOrder.purchase_order.shop;
                $scope.getCollectionsFilterForRecommendation(shop._id);
                if (isFromView) {
                    if ($scope.localdata && $scope.localdata.searchedProducts && $scope.localdata.searchedProducts.isSearchResponse) {
                        $scope.searchProductsForCompanyRecommnedation()
                    } else {
                        $scope.getBrandRetailerRecommendationPurchaseOrder()
                    }
                }
            }

            $scope.shopSearchedDone = function (selected) {
                $scope.selectTheShop(selected, true);
            }

            // When creating purchase order from recommendation (COMING FROM BRAND PURCHASE ORDER) 
            if ($scope.localdata.recommendationShop) {
                $scope.getAllShopForPurchaseOrder();
                $scope.selectTheShop($scope.localdata.recommendationShop);
            }

            $scope.searchProductsForCompanyRecommnedation = function (data) {
                try {
                    console.log('searchProductsForCompanyRecommnedation called: ');
                    $scope.companydata.isSearchingProducts = true;
                    $scope.filterDataVal = false
                    // document.getElementById('search_field_purchaseOrder').focus();
                    $rootScope.recommendationOrderSearchValue = $scope.companydata.search_value;
                    if ($scope.recommendationOrder && $scope.recommendationOrder.purchase_order) {
                        if ((!$scope.recommendationOrder.purchase_order.company || !$scope.recommendationOrder.purchase_order.company._id) &&
                            ($scope.recommendationOrder.purchase_order.companyDetail && $scope.recommendationOrder.purchase_order.companyDetail._id)) {
                            $scope.recommendationOrder.purchase_order.company = $scope.recommendationOrder.purchase_order.companyDetail;
                            setTheCompanyVerified($scope.recommendationOrder.purchase_order.companyDetail);
                        }
                        console.log('$scope.purchase_order.company: ', $scope.recommendationOrder.purchase_order);
                        if ($scope.recommendationOrder.purchase_order.company && $scope.recommendationOrder.purchase_order.company._id) {
                            if ($scope.companydata.search_value) {
                                $scope.companydata.search_value = $productConfig.retrunValidProductNumber($scope.companydata.search_value)
                                // value = $scope.companydata.search_value;
                                // console.log($scope.companydata.search_value)
                                if ($scope.companydata.search_value.length < 3) return

                                if (!$scope.companydata.searchedProducts.isSearchResponse) {
                                    $scope.company_filter.limit = 30;
                                    $scope.company_filter.offset = 0;
                                    $scope.companydata.offsetForPagination = 1;
                                    $scope.company_currentPage = 1;
                                }
                                var data = {
                                    searchText: $scope.companydata.search_value,
                                    companyId: $scope.recommendationOrder.purchase_order.company._id,
                                    shopId: $scope.recommendationOrder.purchase_order.shop._id,
                                    limit: $scope.company_filter.limit,
                                    offset: $scope.company_filter.offset,
                                    language: $rootScope.language,
                                    sortBasedOn: $scope.recommendationOrder.purchase_order.company.sortBasedOn,
                                    dataLimit: data == 'filter' ? 'filter' : null,
                                }

                                // $scope.companydata.loading = true;
                                $scope.companydata.isSearchLoader = true;
                                // document.getElementById('search_field_purchaseOrder').focus();
                                $recommendationOrder.searchProductsForCompanyRecommnedation(data).then(function (response) {
                                    $scope.companydata.isSearchLoader = false;
                                    $scope.companydata.searchedProducts.isSearchResponse = true;
                                    console.info('----------------->>  searchProductsForCompanyRecommnedation called: ', response.data.data);
                                    if (response && response.data && response.data.data && response.data.data.products && response.data.data.products.length) {
                                        var resData = response.data.data
                                        var filterValues = localStorage.getItem('recommendationFilter');
                                        var filData = JSON.parse(filterValues)
                                        var categaoryFilter = false;

                                        if (data.dataLimit == 'filter') {
                                            if (filData && filData.category != null) {
                                                categaoryFilter = true
                                                if (filData.category && (filData.category.watch == true || filData.category.jewel == true || filData.category.strap == true || filData.category.others == true)) {
                                                    $scope.filterDataVal = true
                                                }

                                                if ($scope.filterDataVal == true) {
                                                    var filtered_data = []

                                                    if (filData.category.watch == true) {
                                                        filtered_data = resData.products.filter(function (data) {
                                                            return ((data.products.productDetail.category == 'WATCH'));
                                                        });
                                                    }
                                                    if (filData.category.jewel == true) {
                                                        if (filtered_data.length == 0) {
                                                            filtered_data = resData.products.filter(function (data) {
                                                                return ((data.products.productDetail.category == 'JEWEL'));
                                                            });
                                                        }

                                                    }
                                                    if (filData.category.strap == true) {
                                                        if (filtered_data.length == 0) {
                                                            filtered_data = resData.products.filter(function (data) {
                                                                return ((data.products.productDetail.category == 'STRAP'));
                                                            });
                                                        }

                                                    }
                                                    if (filData.category.others == true) {
                                                        if (filtered_data.length == 0) {
                                                            filtered_data = resData.products.filter(function (data) {
                                                                return ((data.products.productDetail.category == 'OTHERS'));
                                                            });
                                                        }
                                                    }
                                                    $scope.recommendationOrder.products = filtered_data
                                                }
                                            }
                                        }

                                        if ($scope.recommendationOrder && $scope.recommendationOrder.purchase_order && $scope.recommendationOrder.purchase_order.items && $scope.recommendationOrder.purchase_order.items.length > 0) {
                                            if ($scope.recommendationOrder.products && $scope.recommendationOrder.products.length) {
                                                $scope.recommendationOrder.products.map(function (product) {
                                                    $scope.recommendationOrder.purchase_order.items.filter(function (item) {
                                                        if (item.product_id == product.products.productDetail._id) {
                                                            product.products['quantity'] = item.quantity
                                                            product.products['selected'] = true
                                                        }
                                                    })
                                                    return product
                                                })
                                            }
                                        }
                                        $scope.totalProductsDataShowFlag = false;
                                        if ($scope.filterDataVal == false && categaoryFilter == false) {

                                            $scope.recommendationOrder.products = resData
                                            var totalProducts = $scope.recommendationOrder.products.metadata[0].total
                                            $scope.company_filter.limit = 30
                                        } else if (categaoryFilter == true && $scope.filterDataVal == true) {
                                            $scope.recommendationOrder.products.products = $scope.recommendationOrder.products
                                            $scope.totalProductsDataShowFlag = true
                                            $scope.company_totalProductsData = $scope.recommendationOrder.products.length
                                            //  $scope.company_filter.limit = $scope.recommendationOrder.products.length
                                        }

                                        /* $scope.recommendationOrder.products = resData
                                        var totalProducts = $scope.recommendationOrder.products.metadata[0].total */
                                        if ($scope.sortingOrderValue == "companyProductSortingNumber") {
                                            for (var k = 0; k < $scope.recommendationOrder.products.products.length; k++) {
                                                var soldAmount = 0;
                                                soldAmount = $scope.recommendationOrder.products.products[k].products.sortingNumber ? $scope.recommendationOrder.products.products[k].products.sortingNumber - 500000000000 : 0
                                                var topMostSold = "soldAmount";
                                                if (soldAmount >= 10) {
                                                    $scope.recommendationOrder.products.products[k].products[topMostSold] = "Zeer goed verkocht";
                                                } else if (soldAmount > 3 && soldAmount < 10) {
                                                    $scope.recommendationOrder.products.products[k].products[topMostSold] = "Goed verkocht"
                                                } else {
                                                    $scope.recommendationOrder.products.products[k].products[topMostSold] = null
                                                }
                                            }
                                        } else if ($scope.sortingOrderValue == "productSortingNumber") {
                                            for (var k = 0; k < $scope.recommendationOrder.products.products.length; k++) {
                                                var soldAmount = 0;
                                                soldAmount = $scope.recommendationOrder.products.products[k].products.productDetail.sortingNumber ? $scope.recommendationOrder.products.products[k].products.productDetail.sortingNumber - 500000000000 : 0
                                                var topMostSold = "soldAmount";
                                                if (soldAmount >= 10) {
                                                    $scope.recommendationOrder.products.products[k].products[topMostSold] = "Zeer goed verkocht";
                                                } else if (soldAmount > 3 && soldAmount < 10) {
                                                    $scope.recommendationOrder.products.products[k].products[topMostSold] = "Goed verkocht"
                                                } else {
                                                    $scope.recommendationOrder.products.products[k].products[topMostSold] = null
                                                }
                                            }
                                        }

                                        if ($scope.company_filter.limit < totalProducts && (($scope.company_filter.offset * $scope.company_filter.limit) + $scope.company_filter.limit) < totalProducts) {
                                            $scope.companydata.showLoadMore = true
                                        } else {
                                            $scope.companydata.showLoadMore = false
                                        }
                                        window.scrollTo(0, 0)
                                    } else {
                                        $scope.companydata.showLoadMore = false;
                                        $scope.recommendationOrder.products = {
                                            products: [],
                                            metadata: [{
                                                total: 0
                                            }]
                                        };
                                    }
                                }).catch(function (error) {
                                    $scope.companydata.isSearchLoader = false;
                                    console.log('error: ', error);
                                })
                            } else {
                                $scope.getTheCompanyProducts();
                                console.log('No search 3');
                            }
                        } else {
                            console.log('No search  2');
                        }
                    } else {
                        console.log('No search 1');
                    }
                } catch (e) {
                    console.log('Search Product: ', e);
                }
            }

            $scope.addTheProduct = function (item) {
                console.log('-------------------------------------------------------------- addTheProduct!!');
                console.log('$scope.localdata.purchaseOrderNumber', $scope.localdata.purchaseOrderNumber);
                if ($scope.localdata.purchaseOrderNumber) {
                    addProductToExistingPurchaseOrder(item);
                } else {
                    createNewPurchaseOrder(item);
                }
            }

            $scope.addTheProductFromModal = function (item) {
                console.log('addTheProductFromModal called: ', item);
                item.products = {
                    productDetail: {
                        _id: item._id
                    }
                }
                if ($scope.localdata.purchaseOrderNumber) {
                    addProductToExistingPurchaseOrder(item);
                } else {
                    createNewPurchaseOrder(item);
                }
            }

            function addProductToExistingPurchaseOrder(item) {
                item.products.selected = true;
                item.products.quantity = 1;

                var isAlreadyAdded = false;
                for (var i = 0; i < $scope.companydata.products.length; i++) {
                    if (item && item.products && item.products._id && $scope.localdata && $scope.companydata.products && $scope.companydata.products[i] &&
                        $scope.companydata.products[i]._id && (item.products._id).toString() === ($scope.companydata.products[i]._id).toString()) {
                        isAlreadyAdded = true;
                        break;
                    }
                }

                if (isAlreadyAdded) return;

                $scope.companydata.products.push(item.products);

                var body = {
                    shopId: $scope.recommendationOrder.purchase_order.shop._id,
                    purchaseOrder: {
                        _id: $scope.recommendationOrder.purchase_order._id
                    },
                    shop: {
                        _id: $scope.recommendationOrder.purchase_order.shop._id
                    },
                    company: {
                        _id: $rootScope.currentCompany._id
                    },
                    isFrom: 'retailer',
                    products: [{
                        _id: item.products.productDetail._id,
                        quantity: item.products.quantity
                    }]
                }

                $scope.companydata.loading = true;
                $recommendationOrder.addProductToPurchaseOrderFromRecommendation(body).then(function (response) {
                    $scope.companydata.loading = false;
                    if (response && response.data && response.data.data) {
                        console.log('addProductToPurchaseOrderFromRecommendation created: ', response.data.data)
                        $scope.recommendationOrder.purchase_order = response.data.data;
                        setTheCompanyVerified($scope.recommendationOrder.purchase_order.companyDetail);
                    } else {
                        console.log('Something went wrong while adding product')
                    }
                }).catch(function (error) {
                    $scope.companydata.loading = false;
                    console.log('error: ', error)
                });
            }

            function createNewPurchaseOrder(item) {
                console.log('------------------- createNewPurchaseOrder!!');
                var purchaseOrder = {
                    company: {
                        _id: $rootScope.currentCompany._id,
                        name: $rootScope.currentCompany.name,
                        nameSlug: $rootScope.currentCompany.nameSlug,
                    },
                    shop: {
                        _id: $scope.selectedShop._id,
                        name: $scope.selectedShop.name,
                        nameSlug: $scope.selectedShop.nameSlug,
                    },
                    billingDetails: {
                        address: [$rootScope.currentCompany.address],
                        email: $rootScope.currentCompany.email,
                        phone: [$rootScope.currentCompany.phone]
                    },
                    sendDetails: {
                        address: [$scope.selectedShop.address],
                        email: $scope.selectedShop.email
                    },
                    isFrom: 'wholesaler',
                    products: [{
                        _id: item.products.productDetail._id,
                        quantity: 1
                    }]
                }
                console.log('createNewPurchaseOrder called: ', purchaseOrder);
                $recommendationOrder.createNewPurchaseOrderUsingRecommendation(purchaseOrder).then(function (response) {
                    console.log('response: ', response);
                    if (response && response.data && response.data.data) {
                        $state.go('brand.recommendation-order', { orderNo: response.data.data });
                    }
                }).catch(function (error) {
                    console.log('error: ', error);
                });
            }

            function emptyTheSelectedProduct() {
                $scope.companydata.products = []
                var products = $scope.recommendationOrder.products.products
                products.map(function (el) {
                    if (el && el.products && el.products.selected) {
                        el.products.selected = false
                    }
                })
            }

            $scope.changePage = function () {
                console.log('---------------------changePage------------------------');
                emptyTheSelectedProduct();
                $scope.company_filter.offset = $scope.companydata.offsetForPagination - 1; // Pagiantion count from the 0 (CURRENT PAGE) but In query we are doing from 1(CURRENT PAGE)
                if ($scope.companydata.searchedProducts.isSearchResponse) {
                    $scope.searchProductsForCompanyRecommnedation();
                } else {
                    $scope.company_filter.clientGroupCollections = [];
                    $scope.company_filter.privateLabelCollections = [];
                    localStorage.setItem('recommendationFilter', JSON.stringify($scope.company_filter));
                    $scope.getTheCompanyProducts();
                }
            }

            $scope.removeSearchResults = function () {
                if (!$scope.companydata.isSearchingProducts) return;
                $scope.companydata.isSearchingProducts = false;
                $scope.companydata.searchedProducts.isSearchResponse = false;
                $rootScope.recommendationOrderSearchValue = undefined; // To prevent focus losing when purchase order created
                $scope.companydata.search_value = '';
                $scope.company_filter.limit = 30;
                $scope.company_filter.offset = 0;
                $scope.company_currentPage = 1;
                $scope.companydata.offsetForPagination = 1;
                $scope.searchData = undefined;
                $scope.getTheCompanyProducts();
            }

            // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // ///////////////////////////////////////////////////////////////////////// BRAND PRODUCTS ///////////////////////////////////////////////////////////////////////////////

            function getTheSelectedFilter(filter) {
                if (filter) {
                    console.log('getTheSelectedFilter before:', JSON.parse(JSON.stringify(filter)));
                    if (filter.category && filter.category.length) filter.category = filter.category.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.category });
                    if (filter.category && !filter.category.length) filter.category = undefined;

                    if (filter.gender && filter.gender.length) filter.gender = filter.gender.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.gender });
                    if (filter.gender && !filter.gender.length) filter.gender = undefined;

                    if (filter.strapWidths && filter.strapWidths.length) filter.strapWidths = filter.strapWidths.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.width });
                    if (filter.strapWidths && !filter.strapWidths.length) filter.strapWidths = undefined;

                    if (filter.jewelTypes && filter.jewelTypes.length) filter.jewelTypes = filter.jewelTypes.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                    if (filter.jewelTypes && !filter.jewelTypes.length) filter.jewelTypes = undefined;

                    if (filter.strapModels && filter.strapModels.length) filter.strapModels = filter.strapModels.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.model });
                    if (filter.strapModels && !filter.strapModels.length) filter.strapModels = undefined;

                    if (filter.brands && filter.brands.length) filter.brands = filter.brands.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el._id });
                    if (filter.brands && !filter.brands.length) filter.brands = undefined;


                    if (filter && filter.clientGroupCollections && !filter.clientGroupCollections.length) filter.clientGroupCollections = undefined;
                    if (filter && filter.privateLabelCollections && !filter.privateLabelCollections.length) filter.privateLabelCollections = undefined;

                    // if (filter.jewelMaterials != undefined) {
                    //     filter.jewelMaterials = filter.jewelMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                    // }
                    // if (filter.diamondMaterials != undefined) {
                    //     filter.diamondMaterials = filter.diamondMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                    // }
                    // if (filter.watchMaterials) {
                    //     filter.watchMaterials = filter.watchMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                    // }

                    // if (filter.watchCaseMaterials) {
                    //     filter.watchCaseMaterials = filter.watchCaseMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.material });
                    // }
                    // if (filter && filter.watchStrapMaterails) {
                    //     filter.watchStrapMaterails = filter.watchStrapMaterails.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.material });
                    // }
                    // if (filter.watchTypes) {
                    //     filter.watchTypes = filter.watchTypes.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                    // }
                    // if (filter.watchIndications) {
                    //     filter.watchIndications = filter.watchIndications.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.indication });
                    // }
                    filter.suppliers = undefined;
                }
                console.log('getTheSelectedFilter after:', filter);
                return filter;
            }

            $scope.getDynamicRecommendationFilter = function (isBrandProduct, filter) {
                var shopId = $scope.localdata.recommendationShop && $scope.localdata.recommendationShop._id ? $scope.localdata.recommendationShop._id : undefined;
                if ($scope.selectedShop && $scope.selectedShop._id) shopId = $scope.selectedShop._id;

                var body = {
                    companyId: $rootScope.currentCompany._id,
                    shopId: shopId,
                    purchaseOrderNumber: $stateParams.orderNo,
                    isBrandProduct: isBrandProduct,
                    filter: filter
                }
                console.log('======getDynamicRecommendationFilter===================================HELLO 12333=========================>:', body);
                $scope.branddata.filterloading = true;
                $recommendationOrder.getDynamicRecommendationFilter(body).then(function (response) {
                    $scope.branddata.filterloading = false;
                    console.log('======getDynamicRecommendationFilter===4444556============================================================================>:', response.data);
                    response.data.isDynamicFilter = true;
                    response.data.isBrandProduct = isBrandProduct;
                    if (isBrandProduct) {
                        $scope.brand_filter = response.data;
                        $scope.brand_filter.offset = 0;
                        $scope.brand_filter.limit = 30;
                    } else {
                        console.log('getDynamicRecommendationFilter in ELSE PART');
                        $scope.company_filter = response.data;
                        $scope.company_filter.offset = 0;
                        $scope.company_filter.limit = 30;
                    }
                    // $scope.branddata.brandRecommendationOrder.filters = response.data;
                    // $scope.branddata.loading = false;
                    // response.data.filters.isBrandProduct = true;
                    // $scope.branddata.brandRecommendationOrder = { products: response.data.brandProducts, totalProducts: response.data.totalProducts, filters: response.data.filters }
                }).catch(function (error) {
                    $scope.branddata.filterloading = false;
                    console.log('getDynamicRecommendationFilter called 11: ', error);
                });
            }

            $scope.getCompanyBRANDProductsRecommendation = function (isInitial) {
                // getCompanyBRANDProductsRecommendation
                try {
                    // console.info('============================> getCompanyBRANDProductsRecommendation called: ', $scope.brand_filter);
                    $scope.brand_totalProductsData = 0;
                    // When user do refresh at the time we need to keep this filter as it is
                    var localStorageFilter = localStorage.getItem('brandrecommendationFilter');
                    var parsedLocalStorageFilter;
                    // if (localStorageFilter && localStorageFilter != 'undefined') {
                    //     console.log('localStorageFilter3: ', typeof localStorageFilter, JSON.parse(localStorageFilter));
                    //     parsedLocalStorageFilter = JSON.parse(localStorageFilter);
                    //     $scope.brand_filter = JSON.parse(JSON.stringify(parsedLocalStorageFilter));
                    // }
                    if (!$scope.brand_filter) $scope.brand_filter = { offset: 0, limit: 30 };

                    if ($scope.branddata.offsetForPagination === 1) $scope.brand_filter.offset = 0; // If Refreshing the page then we taking 1st page always
                    else $scope.brand_filter.offset = $scope.branddata.offsetForPagination - 1;
                    var shopId = $scope.localdata.recommendationShop && $scope.localdata.recommendationShop._id ? $scope.localdata.recommendationShop._id : undefined;
                    if ($scope.selectedShop && $scope.selectedShop._id) {
                        shopId = $scope.selectedShop._id
                    }

                    var body = {
                        purchaseOrderNumber: $stateParams.orderNo,
                        shopId: shopId,
                        brand_filter: JSON.parse(JSON.stringify($scope.brand_filter)),
                        companyId: $rootScope.currentCompany._id,
                        language: $rootScope.language,
                        sortBasedOn: $scope.selectedShop && $scope.selectedShop.sortBasedOn ? $scope.selectedShop.sortBasedOn : undefined
                    }
                    // if (isInitial) { // means calling first time
                    //     $scope.getCollectionsFilterForRecommendation(companyId);
                    // }
                    // $scope.getDynamicRecommendationFilter(true, getTheSelectedFilter(JSON.parse(JSON.stringify(body.brand_filter))));
                    if (body.brand_filter) {
                        if (body.brand_filter.strapWidths) body.brand_filter.strapWidths = body.brand_filter.strapWidths.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.width });
                        if (body.brand_filter.jewelTypes) body.brand_filter.jewelTypes = body.brand_filter.jewelTypes.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                        if (body.brand_filter.strapModels) body.brand_filter.strapModels = body.brand_filter.strapModels.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.model });

                        if (body.brand_filter.jewelMaterials != undefined) {
                            body.brand_filter.jewelMaterials = body.brand_filter.jewelMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                        }
                        if (body.brand_filter.diamondMaterials != undefined) {
                            body.brand_filter.diamondMaterials = body.brand_filter.diamondMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                        }
                        if (body.brand_filter.watchMaterials) {
                            body.brand_filter.watchMaterials = body.brand_filter.watchMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                        }

                        if (body.brand_filter.watchCaseMaterials) {
                            body.brand_filter.watchCaseMaterials = body.brand_filter.watchCaseMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.material });
                        }
                        if (body.brand_filter && body.brand_filter.watchStrapMaterails) {
                            body.brand_filter.watchStrapMaterails = body.brand_filter.watchStrapMaterails.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.material });
                        }
                        if (body.brand_filter.watchTypes) {
                            body.brand_filter.watchTypes = body.brand_filter.watchTypes.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                        }
                        if (body.brand_filter.watchIndications) {
                            body.brand_filter.watchIndications = body.brand_filter.watchIndications.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.indication });
                        }
                        body.brand_filter.suppliers = undefined;
                        // body.filter.selectedFilters = undefined; // We don't need to send
                        body.filter = body.brand_filter;
                        body.brand_filter = undefined;
                    }


                    console.log('response.data.data.productDetail::::==================================> 456: ', body);

                    $scope.branddata.loading = true;
                    $recommendationOrder.getCompanyBRANDProductsRecommendation(body).then(function (response) {
                        $scope.branddata.loading = false;
                        // response.data.filters.isBrandProduct = true;
                        $scope.branddata.brandRecommendationOrder.products = response.data.brandProducts;
                        $scope.branddata.brandRecommendationOrder.totalProducts = response.data.totalProducts;

                        if($scope.recommendationOrder && $scope.recommendationOrder.purchase_order && $scope.recommendationOrder.purchase_order.items && $scope.recommendationOrder.purchase_order.items.length > 0){
                            $scope.branddata.brandRecommendationOrder.products.map(function (product) {
                                $scope.recommendationOrder.purchase_order.items.filter(function (item) {
                                    if(item.product_id == product.products.productDetail._id){
                                        product.products['quantity'] = item.quantity
                                        product.products['selected'] = true
                                    }
                                })
                                return product 
                            })
                        }
                        // console.log('======getCompanyBRANDProductsRecommendation===4444556=========================>:');
                        // if (response && response.data && response.data.data) {
                        //     // PRODUCTS AND PURCHASE ORDER RESPONSE
                        //     if (response.data.data.productDetail) {
                        //         $scope.recommendationOrder = { products: response.data.data.productDetail.products, purchase_order: response.data.data.purchase_order, sortingData: response.data.data.sortingOrderData };
                        //         // console.log('RESPONSE: retailerRecommendationOrderController: ', $scope.recommendationOrder);
                        //         $scope.sortingOrderValue = $scope.recommendationOrder.sortingData.sortBasedOnSortingNumber
                        //         if ($scope.recommendationOrder.sortingData.sortBasedOnSortingNumber == "companyProductSortingNumber") {
                        //             for (var k = 0; k < $scope.recommendationOrder.products.products.length; k++) {
                        //                 var soldAmount = 0;
                        //                 soldAmount = $scope.recommendationOrder.products.products[k].products.sortingNumber ? $scope.recommendationOrder.products.products[k].products.sortingNumber - 500000000000 : 0
                        //                 var topMostSold = "soldAmount";
                        //                 if (soldAmount >= 10) {
                        //                     $scope.recommendationOrder.products.products[k].products[topMostSold] = "Zeer goed verkocht";
                        //                 } else if (soldAmount > 3 && soldAmount < 10) {
                        //                     $scope.recommendationOrder.products.products[k].products[topMostSold] = "Goed verkocht"
                        //                 } else {
                        //                     $scope.recommendationOrder.products.products[k].products[topMostSold] = null
                        //                 }
                        //             }
                        //         } else if ($scope.recommendationOrder.sortingData.sortBasedOnSortingNumber == "productSortingNumber") {
                        //             for (var k = 0; k < $scope.recommendationOrder.products.products.length; k++) {
                        //                 var soldAmount = 0;
                        //                 soldAmount = $scope.recommendationOrder.products.products[k].products.productDetail.sortingNumber ? $scope.recommendationOrder.products.products[k].products.productDetail.sortingNumber - 500000000000 : 0
                        //                 var topMostSold = "soldAmount";
                        //                 if (soldAmount >= 10) {
                        //                     $scope.recommendationOrder.products.products[k].products[topMostSold] = "Zeer goed verkocht";
                        //                 } else if (soldAmount > 3 && soldAmount < 10) {
                        //                     $scope.recommendationOrder.products.products[k].products[topMostSold] = "Goed verkocht"
                        //                 } else {
                        //                     $scope.recommendationOrder.products.products[k].products[topMostSold] = null
                        //                 }
                        //             }
                        //         }
                        //         $scope.recommendationOrder.recommendationOrderFilters = response.data.data.recommendationOrderFilters.map(function (f) {
                        //             if (f.orderFilterCategoryName === '' || !f.orderFilterCategoryName) {
                        //                 f.orderFilterCategoryName = 'NO_GROUP'
                        //             }
                        //             return f
                        //         }); // When purchase order already not created

                        //         // WHEN CREATING NEW PURCHASE ORDER
                        //         if (!$scope.recommendationOrder.purchase_order) {
                        //             $scope.recommendationOrder.purchase_order = { companyDetail: $scope.selectedShop };
                        //         }

                        //         $scope.recommendationOrder.recommendationOrderFilters = response.data.data.recommendationOrderFilters; // When purchase order already not created
                        //         // console.log('$scope.recommendationOrder HEYY: ', $scope.recommendationOrder);

                        //         var purchaseOrder = $scope.recommendationOrder.purchase_order;
                        //         if (purchaseOrder) {
                        //             if (purchaseOrder.companyDetail && purchaseOrder.companyDetail._id) {
                        //                 console.log('$scope.localdata.hasSupplier: ', $scope.localdata.hasSupplier);
                        //                 $scope.localdata.hasSupplier = true;
                        //             }

                        //             // When purchase order already created then we are removing the shop which we got from the PURCHASE ORDER
                        //             if (purchaseOrder.number) $scope.localdata.recommendationShop = undefined;
                        //         } else if (!purchaseOrder && $scope.localdata.recommendationShop) {
                        //             $scope.recommendationOrder = {
                        //                 purchase_order: {
                        //                     companyDetail: $scope.localdata.recommendationShop,
                        //                 }
                        //             }
                        //         }

                        //         if (purchaseOrder.companyDetail && purchaseOrder.companyDetail._id) {
                        //             $scope.getCollectionsFilterForRecommendation(purchaseOrder.companyDetail._id);
                        //         }
                        //         var totalProducts = $scope.recommendationOrder.products.metadata[0].total;
                        //         if ($scope.brand_filter.limit < totalProducts && (($scope.brand_filter.offset * $scope.brand_filter.limit) + $scope.brand_filter.limit) < totalProducts) {
                        //             $scope.branddata.showLoadMore = true;
                        //         } else {
                        //             $scope.branddata.showLoadMore = false;
                        //         }
                        //         cookieService.getCookie('servicesCurrentPage')
                        //             .then(function (currentPage) {
                        //                 $scope.company_currentPage = currentPage
                        //             })
                        //             .catch(function () {
                        //                 $scope.company_currentPage = 1
                        //             })
                        //         window.scrollTo(0, 0);
                        //     }

                        //     // SHOW SUPPLIER FILTER
                        //     if ($scope.recommendationOrder && $scope.recommendationOrder.purchase_order &&
                        //         $scope.recommendationOrder.purchase_order.companyDetail && $scope.recommendationOrder.purchase_order.companyDetail._id) {
                        //         var companyDetail = $scope.recommendationOrder.purchase_order.companyDetail;
                        //         console.log('companyDetail: ', companyDetail);
                        //         $scope.brand_filter.suppliers = [{
                        //             _id: companyDetail._id,
                        //             name: companyDetail.name,
                        //             nameSlug: companyDetail.nameSlug,
                        //             isSelected: true,
                        //         }]
                        //     }

                        //     if (response.data.data.fitlerDetail) {
                        //         // BRANDS
                        //         var brands = response.data.data.fitlerDetail.brands;
                        //         if (!$scope.brand_filter.brands.length) $scope.brand_filter.brands = brands && brands.length ? brands : [];
                        //     }

                        //     // IF PURCHASE ORDER CREATED AND SEARCHING AT THAT TIME, DUE TO THAT SEACH FOCUS LOST
                        //     if ($rootScope.recommendationOrderSearchValue) {
                        //         $scope.branddata.search_value = $rootScope.recommendationOrderSearchValue;
                        //         $rootScope.recommendationOrderSearchValue = undefined;
                        //         $scope.searchProductsForCompanyRecommnedation();
                        //     }
                        // }
                        // if ($scope.searchData != null) {
                        //     $scope.branddata.search_value = $scope.searchData;
                        //     $scope.searchProductsForCompanyRecommnedation('filter');
                        // } else {
                        //     $scope.searchData = null
                        // }
                    }).catch(function (error) {
                        $scope.branddata.loading = false;
                        if (error == 'No products found') {
                            $scope.branddata.brandRecommendationOrder.products = [];
                            $scope.branddata.brandRecommendationOrder.totalProducts = 0;
                        } else {
                            console.log('error 123333333: ', error, error.status);
                        }
                    });

                } catch (e) {
                    console.log('----------> ', e);
                }
            }

            $scope.toggleProductMode = function(value){
                console.log('toggleProductMode: ', value);
                if(value && (typeof($rootScope.isCompanyProduct) == 'undefined' || !$scope.localdata.isCompanyProduct)){
                    $rootScope.isCompanyProduct = true;
                } else if($scope.localdata.isCompanyProduct){
                    $rootScope.isCompanyProduct = false;
                }
                $scope.localdata.isCompanyProduct = value;
                console.log('toggleProductMode: ', $scope.localdata.isCompanyProduct);
            }
            
            $scope.getTheProductsDetailForBrand = function () {
                $scope.getCompanyBRANDProductsRecommendation(true);
                $scope.getDynamicRecommendationFilter(true);
            }

            $scope.getTheProductsDetailForCompany = function () {
                $scope.getTheCompanyProducts(true);
                $scope.getDynamicRecommendationFilter(true);
                setTheCompanyVerified($rootScope.currentCompany, true)
            }

            function BrandemptyTheSelectedProduct() {
                $scope.branddata.products = []
                var products = $scope.branddata.brandRecommendationOrder.products
                products.map(function (el) {
                    if (el && el.products && el.products.selected) {
                        el.products.selected = false
                    }
                })
            }

            $scope.BRANDchangePage = function () {
                console.log('---------------------------------------BRANDchangePage------------------------: ', $scope.brand_filter.offset, $scope.branddata.offsetForPagination);
                BrandemptyTheSelectedProduct();
                $scope.brand_filter.offset = $scope.branddata.offsetForPagination - 1; // Pagiantion count from the 0 (CURRENT PAGE) but In query we are doing from 1(CURRENT PAGE)
                if ($scope.branddata.searchedProducts.isSearchResponse) {
                    $scope.searchProductsForCompanyRecommnedation();
                } else {
                    $scope.brand_filter.clientGroupCollections = [];
                    $scope.brand_filter.privateLabelCollections = [];
                    localStorage.setItem('brandrecommendationFilter', JSON.stringify($scope.brand_filter));
                    $scope.getCompanyBRANDProductsRecommendation();
                }
            }

            $scope.resetFilter = function () {
                localStorage.removeItem('recommendationFilter');
                $scope.company_filter = JSON.parse(JSON.stringify($recommendationOrder.getDefaultFilterValue()));
                $scope.getDynamicRecommendationFilter(false);
                $scope.getTheCompanyProducts();
            }

            $scope.brandResetFilter = function () {
                localStorage.removeItem('brandrecommendationFilter');
                $scope.brand_filter = JSON.parse(JSON.stringify($recommendationOrder.getDefaultFilterValue()));
                $scope.getDynamicRecommendationFilter(true);
                $scope.getCompanyBRANDProductsRecommendation(true);
            }

            function setFilter(filter) {
                $scope.companydata.search_value = '';
                filter.clientGroupCollections = [];
                filter.privateLabelCollections = [];
                localStorage.setItem('recommendationFilter', JSON.stringify(filter));
                $scope.getTheCompanyProducts();
            }

            function brandSetFilter(filter) {
                $scope.branddata.search_value = '';
                filter.clientGroupCollections = [];
                filter.privateLabelCollections = [];
                localStorage.setItem('brandrecommendationFilter', JSON.stringify(filter));
                $scope.getCompanyBRANDProductsRecommendation();
            }

            $scope.$on('recommendation_filter_directive', function (event, filter) {
                console.log('recommendation_filter_directive---------------------------> ', filter);
                if (filter.isBrandProduct) {
                    brandSetFilter(filter);
                } else {
                    setFilter(filter);
                }
            });

            $scope.removeFilter = function (isCompanyProduct, filtertype, key, index) {
                if (isCompanyProduct) {
                    $scope.company_filter = $recommendationOrder.brandRemoveFilter($scope.company_filter, filtertype, key, index);
                    $scope.$emit('recommendation_filter_directive', $scope.company_filter);
                } else {
                    $scope.brand_filter = $recommendationOrder.brandRemoveFilter($scope.brand_filter, filtertype, key, index);
                    $scope.$emit('recommendation_filter_directive', $scope.brand_filter);
                }
            }

            $scope.searchShops = function (str) {
                console.log('searchShops called: ', str);
                $scope.foundShops = [];
                $brand.searchShopForBrandPurchaseOrder({ companyId: $rootScope.currentCompany._id, searchText: str }).then(function (shopresponse) {
                    console.log('searchShopForBrandPurchaseOrder response: ', shopresponse);
                    if (shopresponse && shopresponse.data && shopresponse.data.data && shopresponse.data.data.data && shopresponse.data.data.data.length) {
                        console.log('shoresponse: ', shopresponse.data.data.data);
                        $scope.foundShops = shopresponse.data.data.data;
                    }
                }).catch(function (shoperr) {
                    console.log('shoperr: ', shoperr);
                });
            }

            // Finding company products
            $scope.isLikeEANNumber = function (value) {
                // console.log('-------------- isLikeEANNumber!!');
                $scope.gotProductsResponse = false;
                $scope.productExactMatch = false;
                $scope.showSearchModal = true;
                // console.log('value ', value);
                // $scope.searchStarted = true;
                if ($purchaseOrder.isLikeEANNumber(value)) {
                    $scope.localdata.isLikeEANNumber = true;
                } else {
                    $scope.localdata.isLikeEANNumber = false;
                }
            }

                     // here hits means firstAPI call and data means seconds API call
            function removeDuplicateProduct(hits, data) {
                // console.log('--------- removeDuplicateProduct!!');
                // console.log('hits ', hits);
                // console.log('data ', data);

                if (hits && hits.length && data && data.length) {
                    for (var i = 0; i < data.length; i++) {
                        for (var j = 0; j < hits.length; j++) {
                            if (hits[j]._id && data[i]._id && hits[j]._id == data[i]._id) { break; }
                            if (j == hits.length - 1) { $scope.hits.push(data[i]); }
                        }
                    }
                    $scope.countOfProducts = hits.length;
                } else {
                    if (!hits.length) $scope.hits = data;
                }
            }

            $scope.findProducts = function (value, searchMode, isProductNumber) {
                console.log('----------------------------------------------------------------------------------------------- findProducts!!');
                $scope.productExactMatch = false;
                $scope.gotProductsResponse = false;
                $scope.searchStarted = true;
                $scope.hits = [];
                // $scope.findProducts2(value, searchMode, isProductNumber);
                $scope.isLikeEANNumber(value);
                delete $scope.hideHits;
                if (isProductNumber) {
                    // console.log('isProductNumber ', isProductNumber);
                    $scope.localdata.productNumber = $productConfig.retrunValidProductNumber(value);
                    $scope.localdata.productNumberSearch = true;
                } else {
                    // console.log('isProductNumber ', isProductNumber);
                    $scope.localdata.ean = $productConfig.retrunValidProductNumber(value);
                    $scope.localdata.eanNumberSearch = true; $scope.localdata.productNumberSearch = false;
                }
                $scope.unofficialProduct = undefined;
                if (value && value.length > 0 && $rootScope.currentCompany && $rootScope.currentCompany._id) {
                    $scope.branddata.search_value = $productConfig.retrunValidProductNumber(value);
                    value = $scope.branddata.search_value;
                    if (value.length < 3) return;

                    $scope.cancleSearch = false;
                    $scope.searchingProduct = true;

                    var data = {
                        companyId: $rootScope.currentCompany._id,
                        searchMode: searchMode,
                        searchText: value,
                        isProductNumber: isProductNumber,
                        language: $rootScope.language
                    };
                    $scope.productNumberStatus = 'default';
                    // $scope.localdata.loading = true;
                    $scope.localdata.findProductsLoader = true;

                    console.log('-----------------------------------data-----------------------------> ', data);

                    $company.searchProductForCompany(data).then(function (response) {
                        console.log('------------------------------------------ searchProductForCompany 1!: ', response);
                        $scope.productExactMatch = response.productExactMatch
                        $scope.localdata.findProductsLoader = false;
                        $scope.gotProductsResponse = true;
                        if (!$scope.cancleSearch) {
                            removeDuplicateProduct($scope.hits, response.data);
                            console.log(" ******************* ")
                            $scope.searchingProduct = false;
                            if (response.status != null && response.status != undefined) $scope.productNumberStatus = parseInt(response.status);
                        } else {
                            $scope.localdata.loading = false;
                            $scope.searchingProduct = false;
                        }

                    }).catch(function (error) {
                        $scope.localdata.findProductsLoader = true;
                        $scope.searchingProduct = false;
                        console.error("error", error);
                    })
                } else {
                    console.log('Proper data not available');
                }
            };

            $scope.searchProductsForCompanyBRANDRecommnedation = function (data) {
                try {

                    console.log('searchProductsForCompanyBRANDRecommnedation called: ', $scope.branddata.search_value);
                    $scope.findProducts($scope.branddata.search_value, 'exact', true);
                    return;

                    console.log('searchProductsForCompanyBRANDRecommnedation called: ');
                    $scope.branddata.isSearchingProducts = true;
                    $scope.filterDataVal = false;
                    // document.getElementById('search_field_purchaseOrder').focus();
                    $rootScope.recommendationOrderSearchValue = $scope.branddata.search_value;
                    if ($scope.recommendationOrder && $scope.recommendationOrder.purchase_order) {
                        if ((!$scope.recommendationOrder.purchase_order.company || !$scope.recommendationOrder.purchase_order.company._id) &&
                            ($scope.recommendationOrder.purchase_order.companyDetail && $scope.recommendationOrder.purchase_order.companyDetail._id)) {
                            $scope.recommendationOrder.purchase_order.company = $scope.recommendationOrder.purchase_order.companyDetail;
                            setTheCompanyVerified($scope.recommendationOrder.purchase_order.companyDetail);
                        }
                        console.log('$scope.purchase_order.company: ', $scope.recommendationOrder.purchase_order);
                        if ($scope.recommendationOrder.purchase_order.company && $scope.recommendationOrder.purchase_order.company._id) {
                            if ($scope.branddata.search_value) {
                                $scope.branddata.search_value = $productConfig.retrunValidProductNumber($scope.branddata.search_value)
                                // value = $scope.branddata.search_value;
                                // console.log($scope.branddata.search_value)
                                if ($scope.branddata.search_value.length < 3) return

                                if (!$scope.branddata.searchedProducts.isSearchResponse) {
                                    $scope.company_filter.limit = 30
                                    $scope.company_filter.offset = 0
                                    $scope.branddata.offsetForPagination = 1;
                                    $scope.company_currentPage = 1;
                                }
                                var data = {
                                    searchText: $scope.branddata.search_value,
                                    companyId: $scope.recommendationOrder.purchase_order.company._id,
                                    shopId: $scope.recommendationOrder.purchase_order.shop._id,
                                    limit: $scope.company_filter.limit,
                                    offset: $scope.company_filter.offset,
                                    language: $rootScope.language,
                                    sortBasedOn: $scope.recommendationOrder.purchase_order.company.sortBasedOn,
                                    dataLimit: data == 'filter' ? 'filter' : null,
                                }

                                // $scope.branddata.loading = true;
                                $scope.branddata.isSearchLoader = true;
                                // document.getElementById('search_field_purchaseOrder').focus();
                                $recommendationOrder.searchProductsForCompanyRecommnedation(data).then(function (response) {
                                    console.info('searchProductsForCompanyRecommnedation called: ', response.data.data.products);
                                    $scope.branddata.isSearchLoader = false;
                                    $scope.branddata.searchedProducts.isSearchResponse = true;
                                    // console.log('search_field_purchaseOrder: ', document.getElementById('search_field_purchaseOrder'));
                                    // document.getElementById('search_field_purchaseOrder').focus();
                                    if (response && response.data && response.data.data && response.data.data.products && response.data.data.products.length) {
                                        for (var i = 0; i < response.data.data.products.length; i++) {
                                            var prod = response.data.data.products[i];
                                            if (prod && prod.products && prod.products.productDetail && prod.products.productDetail.images &&
                                                prod.products.productDetail.images.length) {
                                                prod.products.productDetail.images = prod.products.productDetail.images[0].src;
                                            }
                                        }
                                        $scope.branddata.brandRecommendationOrder.products = response.data.data.products;
                                        $scope.branddata.brandRecommendationOrder.totalProducts = response.data.data.products.length;
                                        if ($scope.recommendationOrder && $scope.recommendationOrder.purchase_order && $scope.recommendationOrder.purchase_order.items && $scope.recommendationOrder.purchase_order.items.length > 0) {
                                            $scope.branddata.brandRecommendationOrder.products.map(function (product) {
                                                $scope.recommendationOrder.purchase_order.items.filter(function (item) {
                                                    if (item.product_id == product.products.productDetail._id) {
                                                        product.products['quantity'] = item.quantity
                                                        product.products['selected'] = true
                                                    }
                                                })
                                                return product
                                            })
                                        }
                                    } else {
                                        $scope.branddata.showLoadMore = false;
                                        $scope.branddata.brandRecommendationOrder = {
                                            products: [],
                                            totalProducts: 0
                                        };
                                    }
                                }).catch(function (error) {
                                    $scope.branddata.isSearchLoader = false;
                                    console.log('error: ', error);
                                })
                            } else {
                                console.log('No search 3');
                                $scope.brand_filter = undefined;
                                $scope.company_filter = undefined;
                                $scope.getCompanyBRANDProductsRecommendation();
                            }
                        } else {
                            console.log('No search  2');
                        }
                    } else {
                        console.log('No search 1');
                    }
                } catch (e) {
                    console.log('Search Product: ', e);
                }
            }

            $scope.brand_removeSearchResults = function () {
                console.log('branddata: ', $scope.branddata.isSearchingProducts);
                // if (!$scope.branddata.isSearchingProducts) return;
                $scope.branddata.isSearchingProducts = false;
                $scope.branddata.searchedProducts.isSearchResponse = false;
                $rootScope.recommendationOrderSearchValue = undefined; // To prevent focus losing when purchase order created
                $scope.branddata.search_value = '';
                $scope.brand_filter.limit = 30;
                $scope.brand_filter.offset = 0;
                $scope.brand_currentPage = 1;
                $scope.branddata.offsetForPagination = 1;
                $scope.searchData = undefined;
                $scope.searchProductsForCompanyBRANDRecommnedation();
            }

            $scope.changePurchaseOrderProductQunatity = function (index) {
                var item = $scope.recommendationOrder.purchase_order.items[index]
                var body = {
                    purchaseOrderId: $scope.recommendationOrder.purchase_order._id,
                    purchaseOrderItemId: item._id,
                    newQuantity: item.quantity,
                    lastQuantity: item.lastQuantity,
                    shopId: $scope.recommendationOrder.purchase_order.shop._id,
                    companyId: $rootScope.currentCompany._id
                }
                $scope.localdata.loading = true;
                $recommendationOrder.changeQuantityOfPurchaseOrder(body).then(function (response) {
                    $scope.localdata.loading = false;
                    item.quantity = body.newQuantity
                    item.lastQuantity = body.newQuantity
                }).catch(function (error) {
                    $scope.localdata.loading = false;
                    console.log('error: ', error)
                })
            }

        }
    ])
