prismanoteApp.controller('adminProductModalController', ['$scope', '$uibModalInstance', '$state', 'product', '$api', '$stateParams', '$uibModal', '$rootScope',
  function ($scope, $uibModalInstance, $state, product, $api, $stateParams, $uibModal, $rootScope) {
    $scope.product = product
    if ($scope.product.stock > 0) $scope.inStock = true
    else $scope.inStock = false

    $scope.deleteProductFromShop = function (product) {
      $api.post('shops/delete-product-from-shop/', {
        shop: $rootScope.currentShop._id,
        product: product._id
      }).then(function (success) {
        $scope.cancel()
      }, function (err) {
        console.error('Error while deleting product from shop', err)
      })
    }

    // this function is needed because of the blocking of show button to prevent the user switching the show button on while the product has not completed filter info
    $api.get('get-specific-product', { productId: product._id })
      .then(function (response) {
        $scope.productGeneral = response.data.product
        if (!$scope.product.images) $scope.product.images = $scope.productGeneral.images

        if ($scope.product[$rootScope.language] === undefined || !$scope.product[$rootScope.language].name) $scope.product[$rootScope.language] = { name: $scope.productGeneral[$rootScope.language].name }

        if (!$scope.product.variants || $scope.products.variants.length < 0) $scope.product.variants = $scope.productGeneral.variants

        if (!$scope.product.en || !$scope.product.en.nameSlug) $scope.product.en = { nameSlug: $scope.productGeneral.en.nameSlug }
      })

    if ($scope.product.price != null && $scope.product.price > 0) {
      $scope.product.sellingPrice = Math.round(($scope.product.price * 1.21) * 100) / 100
    } else {
      if ($scope.product.suggestedRetailPrice != null && $scope.product.suggestedRetailPrice > 0) {
        $scope.product.sellingPrice = Math.round($scope.product.suggestedRetailPrice * 100) / 100
        $scope.product.price = Math.round(($scope.product.sellingPrice / 1.21) * 100) / 100
      } else {
        $scope.product.sellingPrice = 0
        $scope.product.price = 0
      }
    }

    if ($scope.product.discount != null) {
      if ($scope.product.discount > 0) $scope.onSale = true
      else $scope.onSale = false
    } else {
      $scope.product.discount = 0
    }

    $scope.inWebshop = false

    $scope.discount = $scope.product.discount
    $scope.setDiscount = function () {
      if ($scope.usePercentage) $scope.product.discount = ($scope.discount / 100) * $scope.product.sellingPrice
      else $scope.product.discount = $scope.discount
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    $scope.close = function () {
      if (!$scope.inStock) $scope.product.stock = 0
      if (!$scope.onSale) $scope.product.discount = 0
      $uibModalInstance.close($scope.product)
    }

    $scope.saveProduct = function () {
      delete product.sellingPrice
      $scope.close()
    }

    $scope.openPrintLabelModal = function () {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/print-label-modal.html',
        controller: 'printLabelModalController',
        resolve: {
          product: function () {
            return product
          },
          loadProduct: function () {
            return false
          },
          labelDescription: function () {
            return null
          },
          empty: function () {
            return false
          }
        }
      })

      modalInstance.result.then(function (result) {
        if (result) {
          // ready
        }
      }, function () {
        // dismissed
      })
    }

    var getItems = function (type) {
      $api.get(type)

        .then(function (response) {
          $scope[type] = response.data[type]
        })

        .catch(function (reason) {
          console.error('Error while getting' + type, reason)
        })
    }

    $scope.getCollections = function () {
      return getItems('collections')
    }

    var searchItems = function (str, type) {
      var matches = []
      $scope[type].forEach(function (item) {
        if (
          (item[$rootScope.language] !== undefined) &&
          (
            (item[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
            (item[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)
          )
        ) {
          matches.push(item)
        }
      })

      return matches
    }

    $scope.searchCollections = function (str) {
      return searchItems(str, 'collections')
    }

    $scope.collectionSelected = function (selected) {
      var collection = selected.originalObject

      if (!$scope.product.collections) {
        $scope.product.collections = []
      }
      $scope.product.collections.push(collection)
    }

    $scope.deleteCollection = function (index) {
      $scope.product.collections.splice(index, 1)
    }
  }])
