prismanoteApp.controller('retailerCustomerCSVExportFilterController', ['$scope', '$rootScope', '$uibModalInstance', '$http', 'product', '$uibModal' , '$q', '$shop', '$api', '$csv2json', '$crypto', '$language',
  function ($scope, $rootScope, $uibModalInstance, $http, product, $uibModal, $q, $shop, $api, $csv2json, $crypto, $language) {
    $scope.updatedProducts = []
    $scope.collections = []
    $scope.filter = {}
    $scope.loader = false
    $scope.filter.startDate = new Date(new Date().setHours(0, 0, 0));
    $scope.filter.endDate = new Date(new Date().setHours(23, 59, 59));

    $scope.exportCSV = function (data, separator) {
      $scope.loader = true
      var encrypted = $crypto.encrypt({ shopId: $rootScope.currentShop._id})
      if (data == "ALL_CUSTOMER") {
        $http({
          method: 'POST',
          url: 'api/customers/export',
          data: {
            encrypted: encrypted
          },
          responseType: 'string'
        })
          .then(function (response) {
            $scope.loader = false
            var customers = response.data.customers;
            var arr = [];
            for( var i = 0; i < customers.length; i++){
              var obj = {
                'clientId': customers[i].clientId ? customers[i].clientId : '',
                'salutation': customers[i].salutation ? $language.translate(customers[i].salutation) : '',
                'firstName': customers[i].firstName ? customers[i].firstName : '',
                'prefix': customers[i].prefix ? customers[i].prefix : '',
                'lastName': customers[i].lastName ? customers[i].lastName : '',
                'gender': customers[i].gender ? customers[i].gender : '',
                'dateOfBirth': customers[i].dateOfBirth ? customers[i].dateOfBirth : '',
                'email': customers[i].email ? customers[i].email : '',
                'newsletter': customers[i].newsletter ? customers[i].newsletter : '',
                'shipping_street': customers[i].shipping_street ? customers[i].shipping_street : '',
                'shipping_houseNumber': customers[i].shipping_houseNumber ? customers[i].shipping_houseNumber : '',
                'shipping_houseNumberSuffix': customers[i].shipping_houseNumberSuffix ? customers[i].shipping_houseNumberSuffix : '',
                'shipping_postalCode': customers[i].shipping_postalCode ? customers[i].shipping_postalCode : '',
                'shipping_city': customers[i].shipping_city ? customers[i].shipping_city : '',
                'shipping_country': customers[i].shipping_country ? customers[i].shipping_country : '',
                'invoice_street': customers[i].invoice_street ? customers[i].invoice_street : '',
                'invoice_houseNumber': customers[i].invoice_houseNumber ? customers[i].invoice_houseNumber : '',
                'invoice_houseNumberSuffix': customers[i].invoice_houseNumberSuffix ? customers[i].invoice_houseNumberSuffix : '',
                'invoice_postalCode': customers[i].invoice_postalCode ? customers[i].invoice_postalCode : '',
                'invoice_city': customers[i].invoice_city ? customers[i].invoice_city : '',
                'invoice_country': customers[i].invoice_country ? customers[i].invoice_country : '',
                'landLine': customers[i].landLine ? customers[i].landLine : '',
                'mobilePhone': customers[i].mobilePhone ? customers[i].mobilePhone : '',
                'points': customers[i].points ? customers[i].points : '',
                'created_at': customers[i].created_at ? customers[i].created_at : '',
                'comment': customers[i].comment ? customers[i].comment : '',
                'dateLastModified': customers[i].dateLastModified ? customers[i].dateLastModified : '',
                'groups': customers[i].groups && customers[i].groups.length > 0 ? customers[i].groups.join(',') : ''
              }
              arr.push(obj)

              if( i == customers.length-1){
                  $csv2json.JSONToCSVConvertor(arr, 'customers', 'c', separator)
              }
            }
          }, function (response) {
            $scope.loader = false
            console.error('Error while exporting customers', response)
            dialogs.error(undefined, $language.translate('ERROR_WHILE_DOWNLOADING_DOCUMENT'), { size: 'sm' })
          })
      } else if(data == "TRANSACTION") {
        var encrypted = $crypto.encrypt({
          shopId: $rootScope.currentShop._id,
          startDate: $scope.filter.startDate,
          endDate: $scope.filter.endDate,
          language: $rootScope.language
        })
        $http({
          method: 'POST',
          url: 'api/customers/export-transaction',
          data: { encrypted: encrypted },
          responseType: 'string'
        })
          .then(function (response) {
            $scope.loader = false
            var transactions = response.data.transactions;
            var arr = [];
            for (var i = 0; i < transactions.length; i++) {
              var quantity = transactions[i].quantity ? transactions[i].quantity : 0;
              var revenue = transactions[i].revenue ? transactions[i].revenue : 0;
              var purchasePricePerUnit = transactions[i].purchasePrice ? transactions[i].purchasePrice : 0;

              quantity = parseFloat(quantity).toFixed();
              revenue = parseFloat(revenue).toFixed(2);
              purchasePricePerUnit = parseFloat(purchasePricePerUnit).toFixed(2);

              if (separator == 'Semi-colon') {
                quantity = quantity.replace('.', ',');
                revenue = revenue.replace('.', ',');
                purchasePricePerUnit = purchasePricePerUnit.replace('.', ',');
              }

              var obj = {
                'quantity': transactions[i].quantity ? transactions[i].quantity : 0,
                'articleNumber': transactions[i].articleNumber ? transactions[i].articleNumber : '',
                'articleName': transactions[i].articleName ? transactions[i].articleName : '',
                'productName': transactions[i].productName ? transactions[i].productName : '',
                'variantsProductNumber': transactions[i].variantsProductNumber ? transactions[i].variantsProductNumber : '',
                'clientId': transactions[i].clientId ? transactions[i].clientId : '',
                'salutation': transactions[i].salutation ? $language.translate(transactions[i].salutation) : '',
                'firstName': transactions[i].firstName ? transactions[i].firstName : '',
                'prefix': transactions[i].prefix ? transactions[i].prefix : '',
                'lastName': transactions[i].lastName ? transactions[i].lastName : '',
                'email': transactions[i].email ? transactions[i].email : '',
                'customerPhoneNumber': transactions[i].customerPhoneNumber ? transactions[i].customerPhoneNumber.mobilePhone : '',
                'date': transactions[i].date ? transactions[i].date : '',
                'assistedBy': (transactions[i].createdBy && transactions[i].createdBy.name) ? transactions[i].createdBy.name : '',
                'articleGroup': transactions[i].productCollection ? transactions[i].productCollection : '',
                'productCategory': transactions[i].productCategory ? transactions[i].productCategory : '',
                'categoryType': transactions[i].categoryType ? transactions[i].categoryType : '',
                'brand': transactions[i].brand ? transactions[i].brand : '',
                'genderType': transactions[i].genderType ? transactions[i].genderType : '',
                'revenue': revenue,
                'purchasePricePerUnit': purchasePricePerUnit,
                'supplierName': transactions[i].supplierName ? transactions[i].supplierName : '',
                'number': transactions[i].number ? transactions[i].number : '',
                'receiptNumber': transactions[i].receiptNumber ? transactions[i].receiptNumber : '',
                'transactionId': transactions[i].transactionId ? transactions[i].transactionId : '',
                'savingPoints': transactions[i].savingPoints ? transactions[i].savingPoints : '',
                'newsLetter': transactions[i].newsLetter ? transactions[i].newsLetter : '',
                'soldWithDiscount': transactions[i].soldWithDiscount ? transactions[i].soldWithDiscount : '',
                'transactionDateLastModified': transactions[i].transactionDateLastModified ? transactions[i].transactionDateLastModified : '',
                'customerLastModified': transactions[i].customerLastModified ? transactions[i].customerLastModified : '',
                'street': transactions[i].street ? transactions[i].street : '',
                'house number': transactions[i].houseNumber ? transactions[i].houseNumber : '',
                'city': transactions[i].city ? transactions[i].city : '',
                'postal code': transactions[i].postalCode ? transactions[i].postalCode : '',
                'country': transactions[i].country ? transactions[i].country : '',
              }
              arr.push(obj)
              if (i == transactions.length - 1) {
                $csv2json.JSONToCSVConvertor(arr, 'transactions', 'c', separator)
              }
            }
          }, function (response) {
            $scope.loader = false
            console.error('Error while exporting customers', response)
            dialogs.error(undefined, $language.translate('ERROR_WHILE_DOWNLOADING_DOCUMENT'), { size: 'sm' })
          })
      }
    }
   
    function changeImageIndex(input, currentIndex, newIndex) {
      var temp = input[currentIndex];
      input[currentIndex] = input[newIndex];
      input[newIndex] = temp;
      $scope.updatedProducts = input
  }

  $scope.openCollectionProducts = function (productId) {
    var data = {
      collectionId: productId,
      shopId: $rootScope.currentShop._id
    }
    $api.post('collection-products', data)
      .then(function (response) {
       $scope.productsData = response.data.products
       $scope.openProductModal($scope.productsData)
      })
      .catch(function (reason) {
        $log.error('Error while fetching collection data', reason)
        $scope.loading = false
      })
  }

  $scope.getAllCollections = function () {
    $api.post('all-collections-details')
    .then(function (response) {
     $scope.collections = response.data.collections
    })
    .catch(function (reason) {
      $log.error('Error while fetching collection data', reason)
      $scope.loading = false
    })
  }

  $scope.openProductModal = function (products) {
    return $q(function (resolve, reject) {
      var data = {
        'products': products,
        'modal': 'collections'
      };
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/retailer-shop-product-sorting-modal.html',
        controller: 'retailerShopProductSortingController',
        size: 'lg',
        resolve: {
          product: function () {
            return data;
          }
        }
      });

      modalInstance.result
        .then(function (response) {

        })
        .catch(function (reason) {
          reject(reason);
        });
    });
  }

    $scope.previousImage = function (currentIndex) {
      changeImageIndex($scope.products, currentIndex, (currentIndex - 1));
    }

  $scope.nextImage = function (currentIndex) {
      changeImageIndex($scope.products, currentIndex, (currentIndex + 1));
  }

  $scope.close = function () {
    $uibModalInstance.close(true)
  }
  }])
