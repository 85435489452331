prismanoteApp.controller('retailerCustomerController', ['$scope', '$api', '$rootScope', 'campaignFact', '$state', '$stateParams', '$uibModal', '$retailer', '$language', 'prompt', '$customer', 'dialogs', '$transaction', 'Lightbox', '$filter',
  function ($scope, $api, $rootScope, campaignFact, $state, $stateParams, $uibModal, $retailer, $language, prompt, $customer, dialogs, $transaction, Lightbox, $filter) {
    $scope.alert = null
    $scope.totalPriceRange = 0
    $scope.avgPriceRange = 0
    $scope.totalPrice = 0
    $scope.filterStatus = 'all'
    $scope.getMultiStoreDetails = []
    $scope.loading = {
      customer: false,
      transactions: false
    }

    $scope.customerName = function (customer) {
      return $customer.makeCustomerName(customer)
    }

    $scope.openPreviewMailModal = function(email) {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/preview-email-modal.html',
        controller: 'customerPreviewEmailModalController',
        size: 'lg',
        resolve: {
          email: function () {
            return email
          }
        }
      })

      modalInstance.result.then(function () {
      }, function () {
        // dismissed
      })
    }

    $scope.getCustomer = function () {
      $scope.loading.customer = true
      $scope.loading.transactions = true

      $scope.loading.turnover = true
      $scope.loading.statusses = true
      $scope.loading.multishoplist = true

      $customer.getCustomer($stateParams.id, false)
        .then(function (response) {
          $scope.customer = response.customer


          if($scope.customer.dateOfBirth) {
            $scope.age = moment().diff($scope.customer.dateOfBirth, 'years')
          }
          $scope.transactions = []
          $scope.emails = response.emails
          $scope.shopDetails = response.shopDetails
          $scope.loading.customer = false
          if($scope.customer.counter === false) {
            $customer.getCustomerTransaction($stateParams.id, true)
              .then(function (transactions) {
                $scope.transactions = transactions.transactions
                $scope.loading.transactions = false
                $scope.calculateTurnoverAndAverage()
                $scope.parseRepairAndSpecialStatusses()
                $scope.getMultiShopList()
              })
          }
        })
        .catch(function (reason) {
          console.error('Error while getting customer', reason, typeof reason)
          if (typeof reason === 'object') {
            $scope.alert = {
              type: 'danger',
              msg: $language.translate('UNKOWN_ERROR_OCCURED')
            }
            return
          }
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(reason)
          }
          $scope.customer = null
        })
    }

    $scope.getMultiShopList = function(){
      $api.get('v2/shop/multi-store-details/' + $rootScope.currentShop._id)
      .then(function (response) {
        if(response && response.data && response.data.length > 0){
          $scope.multiStoreDetails = response.data[response.data.length - 1];
        }
        $scope.loading.multishoplist = false
      })
      .catch(function (reason) {
        console.error('Error while getting the webshop transactions list', reason)
        $scope.loading = false
      })
    }

    $scope.parseRepairAndSpecialStatusses = function() {
      for (var i = 0; i < $scope.transactions.length; i++) {
        var t = $scope.transactions[i]
        if(t.type === 'repair' || t.type === 'special') {
          $scope.transactions[i].serviceStatus = $transaction.getStatus(t.status)
        }
      }
      $scope.loading.statusses = false
    }
    
    $scope.getMultiStoreDetails = function(transaction, type){
      if (!$scope.multiStoreDetails || !$scope.multiStoreDetails.multiShops) {
        return ''
      }
      var details = []
      if(type === 'repair'){
        details = $scope.multiStoreDetails.dropShippers.filter(function(detail){ return detail._id == transaction.repair});
      }else{
        details = $scope.multiStoreDetails.multiShops.filter(function(detail){ return detail._id == transaction.shopId});
      }
      return details.length > 0 && details[0].name ? details[0].name : '';
    }

    $scope.calculateTurnoverAndAverage = function () {
      $scope.totalTurnover = 0
      $scope.averageOrderAmount = 0
      var transactionsCount = 0

      function getTransactionAmount (transaction) {
        if (transaction.type === 'repair' || transaction.type === 'special') {
          if (transaction[transaction.type].actualCosts) {
            return parseFloat(transaction[transaction.type].actualCosts)
          } else {
            return 0
          }
        } else {
          var detailsCounter = 0;
          var totalAmount = 0

          if (!transaction.details || transaction.details.length === 0) {
            return 0
          }

          for (var i = 0; i < transaction.details.length; i++) {
            totalAmount += parseFloat(transaction.details[i].total)
            detailsCounter++
            if (detailsCounter === transaction.details.length) {
              return parseFloat(totalAmount)
            }
          }
        }
      }

      var counter = 0
      $scope.totals = {
        repair: {
          total: 0,
          count: 0
        },
        special: {
          total: 0,
          count: 0
        },
        'shop-purchase': {
          total: 0,
          count: 0
        },
        offer: {
          total: 0,
          count: 0
        },
        webshop: {
          total: 0,
          count: 0
        },
        refund: {
          total: 0,
          count: 0
        },
        giftcard: {
          total: 0,
          count: 0
        },
        'gold-purchase': {
          total: 0,
          count: 0
        },
        'product-reserve': {
          total: 0,
          count: 0
        }
      }

      for (var i = 0; i < $scope.transactions.length; i++) {
        var transaction = $scope.transactions[i]
        if ( (transaction.type === 'shop-purchase' || transaction.type === 'refund') && transaction.concept === false) {
          $scope.totalTurnover += getTransactionAmount(transaction)
          transactionsCount += 1
        }
        if (transaction.type) {
          $scope.totals[transaction.type].count += 1
        }
        counter++

        if (counter === $scope.transactions.length && $scope.customer.counter === false) {
          $scope.averageOrderAmount = $scope.totalTurnover / transactionsCount
          pieChart()
        }
      }

      $scope.loading.turnover = false
    }

    $scope.saveCustomer = function () {
      $customer.updateCustomer($scope.customer)
        .then(function (result) {
        })
        .catch(function (reason) {
          console.error('Error while updating customer', reason)
          $scope.alert = {
            type: 'danger',
            msg: reason
          }
        })
    }

    $scope.openWhatsapp = function () {
      $customer.openWhatsapp($scope.customer)
    }

    $scope.openCustomerModal = function () {
      // Copy the object to remove any references to the orginal object
      $customer.openCustomerModal(angular.copy($scope.customer), false, false)
        .then(function (result) {
          $scope.customer = result.customer
          if($scope.customer.dateOfBirth) {
            $scope.age = moment().diff($scope.customer.dateOfBirth, 'years')
          }
        })
        .catch(function (reason) {
          console.error('Error in customer modal', reason)
        })
    }

    $scope.setStatusFilter = function (status) {
      $scope.filterStatus = status
    }

    $scope.statusFilter = function (item) {
      if ($scope.filterStatus === 'all') {
        return true
      } else if ($scope.filterStatus === 'processing') {
        return item.status === 'processing' || item.status === 'inspection'
      } else if ($scope.filterStatus === 'completed') {
        return item.status === 'delivered' || item.status === 'completed'
      } else {
        return item.status === $scope.filterStatus
      }
    }

    $scope.backToList = function () {
      $state.go('retailer.customers')
    }

    $scope.openTransaction = function(id, type, old) {
      $transaction.openTransaction(id, type, old)
    }

    $scope.openTill = function () {
      if(!$stateParams.customer){
        $stateParams = { customer : ''}
        $stateParams.customer = $scope.customer
      }
      $state.go('retailer.till', {customer: $stateParams.customer})
    }

    function pieChart () {
      Highcharts.chart('customerActivities', {
        chart: {
          type: 'pie'
        },
        title: {
          verticalAlign: 'top',
          align: 'center',
          x: 15,
          y: 10,
          text: ''
        },
        credits: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            return '<b>' + this.point.name + '</b>: ' + this.y
          }
        },
        legend: {
          align: 'left',
          layout: 'vertical',
          verticalAlign: 'top',
          labelFormatter: function () {
            return this.name + ' (' + this.y + ')'
          }
        },
        exporting: {
          enabled: false
        },
        plotOptions: {
          pie: {
            shadow: true,
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          }
        },
        series: [{
          dataLabels: {
            enabled: false
          },
          colorByPoint: true,
          data: [
            {
              y: $scope.totals.repair.count,
              name: $language.translate('REPAIRS'),
              color: '#4ab69c'
            },
            {
              y: $scope.totals.special.count,
              name: $language.translate('SPECIALS'),
              color: '#222222'
            },
            {
              y: $scope.totals['shop-purchase'].count,
              name: $language.translate('SHOP-PURCHASE'),
              color: '#182f56'
            },
            {
              y: $scope.totals.offer.count,
              name: $language.translate('OFFER'),
              color: '#82A9DB'
            },
            {
              y: $scope.totals.webshop.count,
              name: $language.translate('WEBSHOP'),
              color: '#b4b5b9'
            }, {
              y: $scope.totals.refund.count,
              name: $language.translate('REFUND'),
              color: '#ffb80f'
            }, {
              y: $scope.totals.giftcard.count,
              name: $language.translate('GIFTCARD'),
              color: '#48797a'
            },
            {
              y: $scope.totals['gold-purchase'].count,
              name: $language.translate('GOLD_PURCHASE'),
              color: '#9C2036'
            },
            {
              y: $scope.totals['product-reserve'].count,
              name: $language.translate('product-reserve'),
              color: '#f56f86'
            }
          ]
        }]
      })
    }

    $scope.updateOfferStatus = function (transaction, status) {
      if (status === 'cancelled') {
        prompt({
          title: $language.translate('CANCEL_OFFER'),
          message: $language.translate('ARE_YOU_SURE_TO_CANCEL_THIS_OFFER')
        }).then(function () {
          return updateStatus()
        })
      } else {
        return updateStatus()
      }

      function updateStatus () {
        transaction.log.push({
          date: new Date(),
          user: $rootScope.user._id,
          text: $language.translate('CHANGED_STATUS_FROM_TO', { oldStatus: $language.translate(transaction.status.toUpperCase()), newStatus: $language.translate(status.toUpperCase()) })
        })
        transaction.status = angular.copy(status)

        $transaction.updateTransaction(transaction)
          .then(function (result) {

          })
          .catch(function (reason) {
            $scope.loading = false
            dialogs.error($language.translate('ERROR'), $language.translate('AN_ERROR_OCCURED') + ': ' + reason, { size: 'sm' })
            console.error('Error while updating status', reason)
          })
      }
    }

    $scope.getTotalQuantity = function (items) {
      var total = 0; var counter = 0
      for (var i = 0; i < items.length; i++) {
        total = total + items[i].quantity
        counter++
        if (counter === items.length) {
          return total
        }
      }
    }

    $scope.getTotalDiscount = function (items) {
      var total = 0; var counter = 0
      for (var i = 0; i < items.length; i++) {
        var item = items[i]
        if(item.discount) {
          if(item.discountPercent) {
            //We need to calculate the discount value in euro's since there is a percent discount given
            var givenDiscount = item.price * item.discountValue / (100 - item.discountValue) * (1 + (item.priceVat / 100))
            total += givenDiscount

          } else {
            total += item.discountValue
          }
        }
        counter++
        if (counter === items.length) {
          return total
        }
      }
    }

    $scope.getTotalPrice = function (items) {
      return _.sumBy(items, 'total')

      // var total = 0; var counter = 0
      // for (var i = 0; i < items.length; i++) {
      //   total = total + (items[i].quantity * (items[i].price * (1+(items[i].priceVat/100)) ) - (items[i].quantity * items[i].discount))
      //   counter++
      //   if (counter === items.length) {
      //     return total
      //   }
      // }
    }

    $scope.getShop = function (nameSlug) {
      $retailer.getShop(nameSlug)
        .then(function (shop) {
          $scope.currentShop = shop
        })

        .catch(function (reason) {
          console.error('Error while getting shop', reason)
        })
    }

    $scope.openLightBox = function (images) {
      Lightbox.openModal(images, 0)
    }

    $scope.openMailModal = function () {
      $customer.openCustomerEmailModal($scope.customer)
        .then(function() {
          toastr.success($language.translate('MAIL_SEND'))
        })
    }

    $scope.changePoints = function (points, expire) {
      $scope.negativeError = false
      if (!$scope.customer.points || !$scope.customer.points.spendable) {
        $scope.customer.points = {
          spendable: 0,
          history: []
        }
      }
      var newPoints = $scope.customer.points.spendable + points
      if (newPoints < 0) {
        $scope.negativeError = true
        $scope.customer.points.change = null
        return
      }

      $scope.customer.points.spendable = angular.copy(newPoints)
      var date = moment(new Date()).format('DD-MM-YYYY')
      var expireDate = null
      if (!expire) {
        var today = new Date()

        if($rootScope.currentShop.points.validity) {
          if ($rootScope.currentShop.points.validity.entity && $rootScope.currentShop.points.validity.entity === 'year' && $rootScope.currentShop.points.validity.value) {
            expireDate = new Date(today.setFullYear(today.getFullYear() + $rootScope.currentShop.points.validity.value))
          }
          if ($rootScope.currentShop.points.validity.entity && $rootScope.currentShop.points.validity.entity === 'month' && $rootScope.currentShop.points.validity.value) {
            expireDate = new Date(today.setMonth(today.getMonth() + $rootScope.currentShop.points.validity.value))
          }
        }
      }

      $scope.customer.points.history.push({
        points: points,
        comment: $language.translate('POINTS_MANUALLY_CORRECTED_AT') + ' ' + date,
        expires: points > 0 && expireDate ? expireDate : null
      })

      $scope.customer.points.change = null
      $scope.saveCustomer()
    }

    $scope.calculatePointsValue = function () {
      if (!$scope.customer || !$rootScope.currentShop || !$scope.customer.points) { return 0 }
      var discountPerPoint = $rootScope.currentShop.points.discountPerPoint
      return discountPerPoint * $scope.customer.points.spendable
    }

    $scope.openHistory = function () {
      var customerPointsModalInstance = $uibModal.open({
        templateUrl: '../views/modal/customer-points-modal.html',
        controller: 'customerPointsModalController',
        size: 'lg',
        resolve: {
          points: function () {
            return {
              spendable: $scope.customer.points.spendable,
              history: $scope.customer.points.history
            }
          },
          customer: function () {
            return $scope.customer._id
          }
        }
      })
      customerPointsModalInstance.result.then(function (points) {
        if(points)
          if(points.spendable) {
            $scope.customer.points.spendable = points.spendable
          }
        if(points.history) {
          $scope.customer.points.history = points.history
        }
      }, function () {
        // dismissed
      })
    }

    $scope.getItemDiscount = function (item) {
      if (item.discount) {
        if (item.discountPercent) {
          return item.discountValue + '%'
        } else {
          return $filter('currency')(item.discountValue, '\u20AC')
        }
      } else {

      }
    }

    $scope.taxPrice = function (product) {
      var total = angular.copy(product.total)
      if (total < 0) {
        total = total * -1
      }

      return total - (total / (1 + (product.priceVat / 100)))
    }

    $scope.getOriginalPrice = function(product) {
      var givenDiscount = 0
      if(product.discount) {
        if(product.discountPercent) {
          givenDiscount = product.price * product.discountValue / (100 - product.discountValue) * (1 + (product.priceVat / 100))
        } else {
          givenDiscount = product.discountValue
        }
      }

      return product.price * (1+(product.priceVat/100)) + givenDiscount
    }

    $scope.getCustomerGroups = function () {
      if($rootScope.currentShop && $rootScope.currentShop.customerGroups) {
        $scope.customerGroups = $rootScope.currentShop.customerGroups
      }
    }

    $scope.addGroupToCustomer = function (name) {
      if(!$scope.customer.groups) {
        $scope.customer.groups = []

      }
      if($scope.customer.groups.indexOf(name) >= 0) {
        toastr.warning($language.translate('THIS_GROUP_ALREADY_EXISTS_ON_THIS_CUSTOMER'))
        $scope.selectedGroup = null
        return
      }
      $scope.customer.groups.push(name)
      $scope.selectedGroup = null
      $scope.saveCustomer()
    }

    $scope.deleteCustomerGroup = function (name, index) {
      $scope.customer.groups.splice(index, 1)
      $scope.saveCustomer()
    }



  }])
