prismanoteApp.controller('adminBrandsController', ['$scope', '$rootScope', '$api', '$stateParams', '$uibModal', '$state', 'prompt', 'Upload', '$brand',
  function ($scope, $rootScope, $api, $stateParams, $uibModal, $state, prompt, Upload, $brand) {
    $scope.loading = true
    $scope.totalDisplayed = 20
    $scope.upload = false
    $scope.selectedStartingDigitTemp = [];
    $scope.jsonOptions = {
			mode: 'code',
			modes: ['code', 'tree', 'text'],
		}

    $scope.getBrandList = function () {
      // $api.get('brands')
      $api.get('brands?getAll=true')
        .then(function (response) {
          $scope.loading = false
          $scope.brands = response.data.brands
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.resetSelected = function () {
      $scope.currentSocialMedia = null
      $scope.currentHome = null
      $scope.currentSlide = null
    }

    $scope.loadMore = function () {
      $scope.totalDisplayed += 20
    }

    $scope.loadBrand = function () {
      $scope.nameSlug = $stateParams.nameSlug
      $api.get('brands/' + $stateParams.nameSlug)
        .then(function (response) {
          console.log('---------> response: ', response)
          $scope.brand = response.data.brand
          $scope.currentStartingDigits = (response.data.brand.startingDigits) ? response.data.brand.startingDigits : ''
          $scope.isApplied = (response.data.brand.startingDigitsIsApplied) ? response.data.brand.startingDigitsIsApplied : false
          // - set hidden search input filter to name of the brand
          $scope.search.brand.name = $scope.brand.name
        }, function (err) {
          console.log('err is', err)
        })

      // .catch(function(reason) {
      //     console.log(reason);
      // });
    }

    $scope.startingDigitsApplied = function (startingDigits) {
      // console.log("Starting Digits" , startingDigits.replace(/[^A-Z0-9+]/gi,'') );
      startingDigits = startingDigits.toUpperCase()
      startingDigits = startingDigits.replace(/[^A-Z0-9+/|-]/gi, '')

      if ($scope.currentStartingDigits != startingDigits) {
        console.log('Change The starting Digits')
        $scope.brand.startingDigits = startingDigits
        $scope.brand.startingDigitsIsApplied = false
      } else {
        console.log('Old And New are the same')
      }
    }

    $scope.updateBrand = function (redirect) {
      if (redirect) {
        delete $scope.brand.startingDigits
        delete $scope.brand.startingDigitsIsApplied

        console.log('Update The Brand', $scope.brand)
        $api.put('brands/' + $stateParams.nameSlug, { brand: $scope.brand })
          .then(function (res) {
            if (res.status == 200 && redirect) {
              $state.go('admin.brands')
            }
          }, function (err) {
            console.log(err)
          })
      } else {
        console.log('New Staring Digits', $scope.brand.startingDigits)
        console.log('Current Staring Digits', $scope.currentStartingDigits)
        $scope.brand.startingDigitsIsApplied = true

        $api.put('brands/' + $stateParams.nameSlug, { brand: $scope.brand })
          .then(function (res) {
            if (res.status == 200) {
              $scope.brand.startingDigitsIsApplied = true
              console.log('Now Apply The New Staring Digits To Product')

              if (!$scope.brand.startingDigits || ($scope.brand.startingDigits == '')) {
                $scope.brand.startingDigits = null
              }

              $brand.updateProductNumberBasedOnBrand($scope.brand._id, $scope.currentStartingDigits, $scope.brand.startingDigits)
                .then(function (respose) {
                  console.log('Respose After Upateing All the Product Number', respose)
                  $state.go('admin.brands')
                })
                .catch(function (error) {
                  console.error('Error In Update Product Number All Based On Brand Staring Digits', error)
                })
            }
          }, function (err) {
            console.log(err)
          })
      }
    }

    $scope.updateOldStartingDigits = function (data) {
      console.log('--------------- updateOldStartingDigits!!');
      console.log(data);
      $scope.selectedStartingDigitTemp.push(data);
      $api.put('brans-update-old-starting-digits/' + $stateParams.nameSlug, { brand: $scope.brand._id, oldStartingDigits: data })
          .then(function (res) {
          }, function (err) {
            console.log(err)
          })
    }

    $scope.removeStartingDigit = function(){
      $scope.selectedStartingDigit = ''
    }

    function getUsers() {
      $api.get('users', {})
        .then(function (response) {
          $scope.users = response.data.users
        })
    }

    $scope.searchUsers = function (str) {
      var matches = []
      $scope.users.forEach(function (user) {
        if (
          (user.firstName && user.firstName.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                    (user.lastName && user.lastName.toLowerCase().indexOf(str.toString().toLowerCase()) > 0) ||
                    (user.email && user.email.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
          matches.push(user)
        }
      })
      return matches
    }

    $scope.userSelected = function (selected) {
      var user = {
        _id: selected.originalObject._id,
        name: selected.originalObject.firstName + (selected.originalObject.lastNamePrefix ? ' ' + selected.originalObject.lastNamePrefix + ' ' : ' ') + selected.originalObject.lastName
      }
      $scope.brand.owners.push(user)
      $scope.updateBrand()
    }

    $scope.deleteOwner = function (index) {
      $scope.brand.owners.splice(index, 1)
      $scope.updateBrand()
    }

    $scope.openCreateBrandModal = function () {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/create-brand-modal.html',
        controller: 'createBrandModalController',
        size: 'lg'
      })

      modalInstance.result.then(function (result) {
        console.log('MODALRESULT', result)

        for (var i = result.length - 1; i >= 0; i--) {
          if (array[i] === tasks) {
            array.splice(i, 1)
          }
        }

        result.nameSlug = $rootScope.slugify(result.name)

        result.isPublished = false

        result.address

        $api.post('brands', { brand: result })

          .then(function (response) {
            $state.go('admin.brands')
          })

          .catch(function (reason) {
            console.log(reason)
          })
      }, function () {

      })
    }

    $scope.imageUpload = function (index) {
      console.log($scope.brand)
      console.log('$scope.brand.imageThird', $scope.brand.imageThird)

      var brandImage = $scope.brand.images[index].imageFirst

      // if(orderNumber == "first"){
      //     var brandImage = $scope.brand.imageFirst
      // }else if(orderNumber == "second"){
      //     var brandImage = $scope.brand.imageSecond
      // }else{
      //     var brandImage = $scope.brand.imageThird
      // }
      console.log('brandImage', brandImage)
      if (brandImage) {
        $scope.upload = true
        Upload.upload({
          url: 'api/brand/uploadimage',
          data: {
            brandId: $scope.brand._id,
            file: brandImage
          }
        })
          .then(function (res) {
            console.log('res.data.file', res.data.file)
            image = {
              src: res.data.file,
              alt: brandImage.name
            }
            console.log('image', image)
            $scope.brand.images.splice(index, 1, image)
            console.log('$scope.brand.images', $scope.brand.images)
            brandImage = null
            $scope.upload = false
            // $scope.updateBrand(false);
          }, function (res) {
            console.log('Error: ', res)
          }, function (evt) {
            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total)
            $scope.uploadProgress = progressPercentage
            console.log('Progress: ' + progressPercentage + '%' + evt.config.data)
          })
      } else {
        $scope.updateBrand(false)
      }
    }

    $scope.addImage = function () {
      var newImage = {
        src: '',
        alt: ''
      }
      $scope.brand.images.push(newImage)
    }

    $scope.deleteImage = function (index) {
      $scope.brand.images.splice(index, 1)
    }

    // Default options for fetching new items: 12 items with featured items first, sorted by views.
    var defaultApiOptions = { sort: { startDate: 'desc' }, limit: 12 }

    // currently active API request params/options. Set to default options initially, may be changed or reset to default options later.
    var apiOptions = defaultApiOptions

    // General setter to change the Api HTTP request options/params. Some wrapper functions are available to make the code more intuitive.
    var setApiOptions = function (options, type, append) {
      if (typeof options === 'object') {
        if (typeof append !== 'boolean' || append === false) {
          apiOptions[type] = options
        } else {
          apiOptions[type] = _.extend(apiOptions[type], options)
        }
      } else if (options == null) {
        apiOptions = options
      }
      $scope.products = new Array()
      $scope.getProducts()
    }

    $scope.campaigns = new Array()
    $scope.updates = new Array()

    $scope.getCampaigns = function () {
      var numberOfCampaigns = $scope.campaigns.length
      apiOptions.offset = numberOfCampaigns
      apiOptions.limit = 100
      $api.get('campaigns', apiOptions)

        .then(function (response) {
          // Add campaigns to the $scope.
          for (var i = 0; i < response.data.campaigns.length; i++) {
            var campaign = response.data.campaigns[i]
            if (campaign.update == true) {
              $scope.updates.push(campaign)
            } else {
              $scope.campaigns.push(campaign)
            }
          }
          getUsers();
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.getNumber = function (num) {
      return new Array(num)
    }
    // paste from retailer portal 1-11-2017
    $scope.facebookId = null

    $scope.orderByDate = function (log) {
      var date = new Date(log.date)
      return date
    }

    $scope.getOpenTasksCount = function () {
      $scope.openTasks = 0
      for (var i = 0; i < $scope.user.socialPortal.taskList.length; i++) {
        if (!$scope.user.socialPortal.taskList[i].completed) {
          $scope.openTasks++
        }
      }
    }

    $scope.openPreviewCampaignModal = function (campaign) {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/preview-campaign-modal.html',
        controller: 'previewCampaignModalController',
        size: 'lg',
        resolve: {
          campaign: function () {
            return campaign
          }
        }
      })

      modalInstance.result.then(function (result) {
        if (result) {
          $scope.user.socialPortal.campaigns.push(campaign)

          $scope.saveSocialPortal()
          $scope.getPortalCampaigns()

          $scope.alert = {
            type: 'success',
            msg: 'De promotie ' + campaign.name + " is toegevoegd bij Mijn promoties. <a href='/retailer/campaigns/" + campaign.nameSlug + "'> Bekijk de campagne</a>"
          }
        }
      }, function () {
        // dismissed
      })
    }

    $scope.completeTask = function (task) {
      if (task.type == 'order') {
        $window.open(task.url)
      }

      prompt({
        title: 'Taak uitvoeren',
        message: 'Is uitvoeren van taak ' + task.title + ' gelukt?'
      }).then(function () {
        task.completed = true
        task.dateCompleted = new Date()

        $scope.addLogItem({
          title: task.title + ' uitgevoerd',
          type: 'task',
          taskId: task._id,
          user: $scope.user.firstName + ' ' + ($scope.user.lastNamePrefix || '') + ' ' + $scope.user.lastName
        }, false)

        $scope.saveSocialPortal()
        $scope.getOpenTasksCount()
      })
    }

    $scope.markOpen = function (task) {
      task.completed = false
      $scope.addLogItem({
        title: task.title + ' als openstaand gemarkeerd',
        type: 'task',
        taskId: task._id,
        user: $scope.user.firstName + ' ' + ($scope.user.lastNamePrefix || '') + ' ' + $scope.user.lastName
      }, false)
      $scope.saveSocialPortal()
    }

    $scope.addLogItem = function (item, save) {
      var logItem = {
        title: item.title,
        date: new Date(),
        type: item.type,
        taskId: item.taskId,
        user: item.user
      }
      $scope.user.socialPortal.log.push(logItem)

      if (save) {
        $scope.saveSocialPortal()
      }
    }

    $scope.openTaskModal = function (task) {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/view-task-modal.html',
        controller: 'viewTaskModalController',
        size: 'lg',
        resolve: {
          task: function () {
            return task
          }
        }
      })

      modalInstance.result.then(function (result) {
        $scope.saveSocialPortal()
        $scope.getOpenTasksCount()
      }, function () {

      })
    }

    $scope.openCreateCampaignModal = function () {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/create-campaign-modal.html',
        controller: 'createCampaignModalController',
        size: 'lg'
      })

      modalInstance.result.then(function (result) {
        console.log('MODALRESULT', result)

        for (var i = result.length - 1; i >= 0; i--) {
          if (array[i] === tasks) {
            array.splice(i, 1)
          }
        }

        // niet invullen maar standaard waarde
        result.strategy = 'store-promotion'
        // result.number = $rootScope.randomNumber(); // edit this because ofcourse this is not logic
        result.nameSlug = $rootScope.slugify(result.name)
        // result.nameSlug = "hoe-genereer-je-een-slug";

        result.update = false
        result.images = []

        var task = {
          type: 'facebook',
          mandatory: true,
          defaultChecked: true,
          images: [],
          fbPromotionSettings: {
            alsoOnInsta: true
          },
          nl: {
            name: result.name
          }
        }
        result.tasks = [task]
        var image = {
          src: 'https://prismanotevoorjuweliers.nl/wp-content/uploads/2017/07/prismanote-vliegtuigje.png',
          alt: 'Standard My-Store-Promotion-campaign'
        }
        task.images.push(image)
        result.images.push(image)

        console.log('Tasks', result)
        $scope.user.socialPortal.campaigns.push(result)

        $scope.saveSocialPortal()
        $scope.getPortalCampaigns()
      }, function () {

      })
    }
  }])
