prismanoteApp
.controller('companyProductController',
  ['$scope', '$rootScope', '$stateParams', '$diamond', '$productConfig', '$product', '$purchaseOrder', '$uibModal', 'Upload', '$brand', '$state', '$tax', '$company', '$q', '$language', 'prompt', '$user',
  function ($scope, $rootScope, $stateParams, $diamond, $productConfig, $product, $purchaseOrder, $uibModal, Upload, $brand, $state, $tax, $company, $q, $language, prompt, $user) {
    console.log('Add New product in company Protal')
    $scope.localdata = {
      previousProductNumber: ''
    }
    $scope.product = {}
    $scope.productNumberStatus = 'default'
    $scope.images = []
    $scope.validWP = true
    $scope.newStock = 0
    $scope.editProduct = false;
    $scope.currentState = $state.current.name
    $scope.saveButtonEnabled = false
        // $scope.displaySwitch = true;
        $scope.company = {
          product: {}
        }
        $scope.gemKinds = $diamond.getGemKinds()
        $scope.gemPurities = $diamond.getGemPurities()
        $scope.gemCuts = $diamond.getGemCuts()
        $scope.gemColors = $diamond.getGemColors()
        $scope.strapColors = $productConfig.getStarpColors()
        $scope.dialColors = $productConfig.getDialColors()
        $scope.caseColors = $scope.jewelColors = Object.assign($scope.dialColors, $scope.strapColors)
        $scope.search_value = {
          text: ''
        }
        $scope.language = $rootScope.language
        console.log('Current Language', $scope.language)

        $scope.getCompanyOwnBrands = function (companyId) {
          //console.log(' ------- Get Company Own Brands -------', companyId)
          $brand.getCompanyOwnBrands(companyId)
          .then(function (companiesBrands) {
            $scope.companyBrands = companiesBrands.data
            console.log('Current Companies Brands', $scope.companyBrands)
          })
          .catch(function (errorInCompaniesBrands) {
            console.error('Error In Companies Brands', errorInCompaniesBrands)
          })
        }

        $scope.brandSelected = function (selected) {
          var images = []


          if ($scope.product.brand) $scope.productOldBrand = JSON.parse(JSON.stringify($scope.product.brand));
          console.log('selected brand ===>', selected.originalObject)

          if (selected.originalObject._id.images) {
            var brandImagesLength = selected.originalObject.images.length
            for (var i = 0; i < brandImagesLength; i++) {
              var singleImage = {
                src: selected.originalObject.images[i].src,
                alt: selected.originalObject.images[i].alt
              }
              images.push(singleImage)
            }
          }

          console.log('Images ====>', images)

          $scope.product.brand = {
            _id: selected.originalObject._id,
            name: selected.originalObject[$rootScope.language].name,
            nameSlug: selected.originalObject[$rootScope.language].nameSlug,
            images: images,
            startingDigits: (selected.originalObject.startingDigits && selected.originalObject.startingDigits != 'null' && selected.originalObject.startingDigits != 'undefined' && selected.originalObject.startingDigits != 'NULL') ? selected.originalObject.startingDigits : ''
          }

          console.log('product handling guy Check here,', $scope.product.brand)


          if ($scope.product.variants && $scope.product.variants.length && $scope.product.variants[0].productNumber) {
            var index = $scope.product.variants[0].productNumber.indexOf('-');
            console.log("Index of the divider", index);

            if ($scope.product.variants[0].productNumberAuto) {
              console.log(" ***** Unoffical Product :::: ", index);
              if (index == 4) {
                var insertedProductNumberWithBrand = $scope.product.variants[0].productNumber.slice(index + 1);
                var index2 = insertedProductNumberWithBrand.indexOf('-');

                if (index2 > 0 && index2 < 4) {
                  if ($scope.product.brand.startingDigits) {
                    $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($rootScope.currentCompany.startingDigits + '-' + $scope.product.brand.startingDigits + '-' + insertedProductNumberWithBrand.slice(index2 + 1)));
                    $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                    $scope.product.variants[0].companyStratingDigits = $rootScope.currentCompany.startingDigits
                  } else {
                    $scope.product.variants[0].companyStratingDigits = $rootScope.currentCompany.startingDigits
                    $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($rootScope.currentCompany.startingDigits + '-' + insertedProductNumberWithBrand.slice(index2 + 1)));
                  }

                  $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);

                } else {
                  console.log("Hey product handling guy Check this here have issues in the brand selection portal");
                  console.log("insertedProductNumberWithBrand", insertedProductNumberWithBrand);
                  if ($scope.product.brand.startingDigits) {
                    $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($rootScope.currentCompany.startingDigits + '-' + $scope.product.brand.startingDigits + '-' + insertedProductNumberWithBrand));
                    $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                    // $scope.search_value.text = JSON.parse(JSON.stringify($scope.product.variants[0].productNumber))
                  } else {
                    $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($rootScope.currentCompany.startingDigits + '-' + insertedProductNumberWithBrand));
                    // $scope.search_value.text = JSON.parse(JSON.stringify($scope.product.variants[0].productNumber))
                  }

                  $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);

                }

              } else {
                console.log(" This product is imported product ")
                // Import Product
              }
            } else {
              console.log(" ***** Offical Product :::: ");
              // Here have only possible the brand stating digit
              if (index > -1) {
                console.log("Here have staring digit applied");
                $scope.product.variants[0].insertedProductNumber = $scope.product.variants[0].productNumber.slice(index + 1);
                console.log("not have staring digit applied");
                if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                  console.log("You can apply new");
                  $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].insertedProductNumber));
                  $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                  $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
                } else {
                  console.log("No new Brands");
                  $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.variants[0].insertedProductNumber));
                  $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
                }
              } else {
                console.log("not have staring digit applied");
                if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                  console.log("You can apply new");
                  $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                  $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].productNumber));
                  $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);

                } else {
                  console.log("No new Brands");
                }
              }
            }
          } else {
            console.log("No productNumber here")
          }
        }

        $scope.searchBrands = function (str) {
          var matches = []
          $scope.companyBrands.forEach(function (brand) {
            if (brand.officialBrands) {
              if ((brand.officialBrands[$rootScope.language] != undefined && brand.officialBrands[$rootScope.language].name != undefined) && ((brand.officialBrands[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) || (brand.officialBrands[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0))) matches.push(brand.officialBrands)
            }
        })
          return matches
        }

        $scope.saveProductInDatabase = function (product, company) {
          console.log(' ---------------- Save Company product ------------ ')
          console.log('product  =====>', product)
          if (product.category == 'WATCH' && (!product.watch || !product.watch.type)) {
            $scope.alert = {
              type: 'danger',
              msg: 'Watch type is required'
            }
            return;
          }
          console.log('company product ======> ', company)
          var variant = $scope.product.variants[0]
          $scope.product.variants = []
          $scope.product.variants.push(variant)

          if (!$scope.product.male) {
            $scope.product.male = false
          }

          var newCollections = {
            clientGroup: $scope.product.clientGroupCollections,
            brand: $scope.product.brandCollections,
            variant: $scope.product.variantCollections
          }

          delete $scope.product.clientGroupCollections
          delete $scope.product.brandCollections
          delete $scope.product.variantCollections

          var readyProduct = $scope.product
          delete company.product.onSale
          delete company.product.calculatedDiscount
          product.hasStock = $scope.company.product.hasStock

          product.suggestedRetailPriceVat = $scope.company.product.priceVat
          product.suggestedRetailPrice = $scope.company.product.suggestedRetailerPriceForProduct
          delete $scope.company.product.suggestedRetailerPriceForProduct

          if (product.suggestedRetailPrice) product.suggestedRetailPrice = Number(product.suggestedRetailPrice / ((product.suggestedRetailPriceVat / 100) + 1))

            console.log('Company Id', $rootScope.currentCompany._id)
          var data = {
            product: readyProduct,
            newCollections: newCollections,
            user: $rootScope.user,
            companyId: $rootScope.currentCompany._id
          }

          $company.addNewProductInDatabase(data)
          .then(function (result) {
            var productId = result.data._id

            if ($scope.images.length > 0 && $scope.images[0].name) {
              $scope.upload = true
              Upload.upload({
                url: 'api/product-image-upload',
                data: {
                  productId: productId,
                      files: $scope.images, // file input field
                      companyId: $rootScope.currentCompany._id
                    }
                  })
              .then(function (res) {
                    // Add Company in Company.products
                    if ($stateParams.number && $stateParams.id) {
                      updateProductDetailsInPurchaseOrder($stateParams.number, $stateParams.id, productId, $scope.product.variants[0].productNumber, $scope.purchaseOrderDetailsForUpdate, $scope.product.suggestedRetailPrice, $scope.company.product.priceVat, $scope.company.product.price)
                      .then(function (update) {
                        $scope.addProductInCompany($rootScope.currentCompany._id, productId, company)
                      })
                      .catch(function (error) {
                        console.error('Error While Update Purchase Order', error)
                      })
                    } else {
                      $scope.addProductInCompany($rootScope.currentCompany._id, productId, company)
                    }
                  })
              .catch(function (err) {
                $scope.alert = {
                  type: 'danger',
                  msg: 'upload error: ' + err
                }
                console.error('Error : in upload images')
              })
            } else {
              console.log('$scope.companyId', $rootScope.currentCompany._id)
              console.log('product Id', productId)
              console.log('company', company)
              if ($stateParams.number && $stateParams.id && !$stateParams._id) {
                updateProductDetailsInPurchaseOrder($stateParams.number, $stateParams.id, productId, $scope.product.variants[0].productNumber, $scope.purchaseOrderDetailsForUpdate, $scope.product.suggestedRetailPrice, $scope.company.product.priceVat, $scope.company.product.price)
                .then(function (update) {
                  $scope.addProductInCompany($rootScope.currentCompany._id, productId, company)
                })
                .catch(function (error) {
                  console.error('Error While Update Purchase Order', error)
                })
              } else {
                $scope.addProductInCompany($rootScope.currentCompany._id, productId, company)
              }
            }
          })
          .catch(function (reason) {
            console.error('Error in main adding Product', reason)
            $scope.alert = {
              type: 'danger',
              msg: 'add product error: ' + reason
            }
          })
        }

        $scope.addProductInCompany = function (companyId, productId, companyProduct) {
          $company.addProductInCompany($rootScope.currentCompany._id, productId, $scope.company.product, true)
          .then(function (savedProductInCompany) {
            console.log('Saved Product In Company')
            $scope.alert = {
              type: 'success',
              msg: 'Uw gegevens zijn verzonden!'
            }
            $rootScope.productId = productId
              // $state.go('brand.assortment');
              if ($stateParams.id && $stateParams.number) {
                $state.go('brand.purchase-order', {
                  orderNo: $stateParams.number
                })
              } else {
                $state.go('brand.assortment')
              }
            })
          .catch(function (error) {
            console.log('')
            $scope.alert = {
              type: 'danger',
              msg: 'upload error: ' + error
            }
          })
        }

        $scope.setCategory = function (productCategory) {
          // When Changes in the product category value then using this.
          $scope.product.category = productCategory

          switch (productCategory) {
            case 'WATCH':
            $scope.product.strap = ($scope.product.strap) ? $scope.product.strap : {}
            $scope.product.watch = ($scope.product.watch) ? $scope.product.watch : {}
            $scope.product.jewel = ($scope.product.jewel) ? $scope.product.jewel : {}
            if ($scope.product.watch.dial && $scope.product.watch.dial.color) $scope.dialColor = $scope.dialColors.find(function (color) {
              return color.key == $scope.product.watch.dial.color
            })

              if ($scope.product.watch.case && $scope.product.watch.case.color) $scope.caseColor = $scope.caseColors.find(function (color) {
                return color.key == $scope.product.watch.case.color
              })

                if ($scope.product.watch.strap && $scope.product.watch.strap.color) $scope.strapColor = $scope.strapColors.find(function (color) {
                  return color.key == $scope.product.watch.strap.color
                })
                  break
                  case 'STRAP':
                  $scope.product.strap = ($scope.product.strap) ? $scope.product.strap : {}
                  $scope.product.watch = ($scope.product.watch) ? $scope.product.watch : {}
                  $scope.product.jewel = ($scope.product.jewel) ? $scope.product.jewel : {}
                  if ($scope.product.strap && $scope.product.strap.color) $scope.strapColor = $scope.strapColors.find(function (color) {
                    return color.key == $scope.product.strap.color
                  })

                    break
                    case 'JEWEL':
                    $scope.product.strap = ($scope.product.strap) ? $scope.product.strap : {}
                    $scope.product.watch = ($scope.product.watch) ? $scope.product.watch : {}
                    $scope.product.jewel = ($scope.product.jewel) ? $scope.product.jewel : {}
                    if ($scope.product.jewel && $scope.product.jewel.color && $scope.product.jewel.color.length && $scope.product.jewel.color) $scope.jewelColor = $scope.jewelColors.find(function (color) {
                      return color.key == $scope.product.jewel.color
                    })

                      break
                      case 'OTHER':
                      break
                    }
                  }

                  $scope.setGender = function (targetAudience) {
                    switch (targetAudience) {
                      case 'GENTS':
                      $scope.product.male = true
                      $scope.product.female = false
                      $scope.product.kids = false
                      break
                      case 'LADIES':
                      $scope.product.male = false
                      $scope.product.female = true
                      $scope.product.kids = false
                      break
                      case 'UNISEX':
                      $scope.product.male = true
                      $scope.product.female = true
                      $scope.product.kids = false
                      break
                      case 'BOYS':
                      $scope.product.male = true
                      $scope.product.female = false
                      $scope.product.kids = true
                      break
                      case 'GIRLS':
                      $scope.product.male = false
                      $scope.product.female = true
                      $scope.product.kids = true
                      break
                      case 'KIDS':
                      $scope.product.male = true
                      $scope.product.female = true
                      $scope.product.kids = true
                      break
                    }
                  }

                  $scope.onSaleChange = function (change) {
                    $scope.company.product.onSale = change
                  }

                  $scope.setDiscount = function (discount, usePercentage) {
          // Set Discount
          $scope.company.product.discountOnPercentage = usePercentage
          $scope.company.product.discount = discount

          if (usePercentage === true && $scope.company.product.sellingPrice && $scope.company.product.discount) {
            $scope.company.product.calculatedDiscount = ($scope.company.product.sellingPrice * $scope.company.product.discount) / 100
          } else {
            $scope.company.product.calculatedDiscount = $scope.company.product.discount
          }
        }

        $scope.setStrapColor = function (color) {
          console.log('color', color)
          if (color) {
            if ($scope.product.category == 'WATCH') {
              if (!$scope.product.watch.strap) $scope.product.watch.strap = {
                color: color.key
              }

              $scope.product.watch.strap.color = color.key
              console.log('Check Here the color of the Strap', $scope.product.watch.strap.color)
            } else if ($scope.product.category == 'STRAP') {
              if (!$scope.product.strap) $scope.product.strap = {
                color: color.key
              }
              $scope.product.strap.color = color.key
            }
          }
        }

        $scope.eanIsExistsOrNot = function (ean) {
          // Enterd EAN number is valid or not

          if (ean && ean.length > 0) {
            console.log('Ean', ean)
            $scope.eanValid = false
            $product.eanIsExistsOrNot(ean, $scope.product._id, $rootScope.currentCompany._id)
            .then(function (result) {
              if (result.status == true) $scope.eanValid = true
            })
            .catch(function (error) {
              console.error('Error =>>> ', error)
            })
          } else {
            $scope.eanValid = true
            if (!$scope.product.variants) $scope.product.variants = [{
              ean: ''
            }]
            $scope.product.variants[0].ean = ''
            delete $scope.product.variants[0].ean
          }
        }

    $scope.openPrompt = function () {
      console.log('Openprompt called');
      prompt({
        title: $language.translate('CHANGE_PRODUCT_NUMBER'),
        message: $language.translate('WARNING_PRODUCT_NUMBER'),
        buttons: [
          {
            label: $language.translate('CONFIRM'),
            primary: true,
            cancel: false,
            id: 1
          },
          {
            label: $language.translate('CANCEL'),
            primary: false,
            cancel: true,
            id: 2
          }
        ]
      }).then(function (value) {
        if (value && value.id == 2) {
          $scope.product.variants[0] .productNumber = $scope.localdata.previousProductNumber;
        }
        console.log('Hello OKAY: ', value, $scope.localdata.previousProductNumber, $scope.product.variants[0].productNumber);
      }, function () {
        reject('Cancel')
      })
    }

    $scope.productNumberIsExistsOrNot = function (productNumber) {
      //console.log('productNumber ', productNumber);
      if (productNumber) {
        console.log('Check for the Product number', productNumber)
        productNumber = $productConfig.retrunValidProductNumber(productNumber)
        //console.log('productNumber ', productNumber);
        $scope.PNvalid = false
        $productConfig.makeValidProductNumber(productNumber)
          .then(function (validProdocutNumber) {
            //console.log(" Hey product handling guy Check this for the products ")
            //console.log(" $scope.product in productNumber ", $scope.product)

            if ($scope.product && $scope.product.variants && $scope.product.variants[0].productNumberAuto) {

              $product.productNumberIsExistsOrNot(validProdocutNumber, $scope.product._id)
                .then(function (result) {
                  if (!$scope.product.variants) $scope.product.variants = [{
                    productNumber: ''
                  }]

                  if (result.status == true) {
                    $scope.PNvalid = true
                    $scope.product.variants[0].productNumber = productNumber
                  } else {
                    $scope.product.variants[0].productNumber = productNumber
                  }

                  if ($state.current.name == 'brand.add-product' || $state.current.name == 'brand.add-purchase-product') $scope.search_value.text = productNumber

                  if (!$scope.$$phase) {
                    $scope.$apply(function () {
                      $scope.product = $scope.product
                      $scope.search_value.text = $scope.search_value.text
                    })
                  } else {
                    $scope.product = $scope.product
                    $scope.search_value.text = $scope.search_value.text
                  }
                })
                .catch(function (error) {
                  console.error(error)
                })
            } else {
              // console.log("****************************")
              // console.log("****************************")
              // console.log("****************************")
              // console.log(" Offical Product ")
              // console.log("****************************")
              // console.log("****************************")
              // console.log("****************************")
              // console.log("****************************")
              if ($scope.product.brand) {
                console.log(" $scope.product ")
                var temp = ''
                if ($scope.product.variants && $scope.product.variants.length > 0 && $scope.product.variants[0].brandStratingDigits) {
                  var index = productNumber.indexOf($scope.product.variants[0].brandStratingDigits)
                  if (index > -1) {
                    console.log(" Hey product handling guy here it is ")
                    temp = productNumber.slice(index + $scope.product.variants[0].brandStratingDigits.length + 1, productNumber.length + 1);
                  }
                }

                temp = temp.length > 2 ? temp : validProdocutNumber

                console.log(" Valid ")
                $scope.PNsort = false;
                $product.productNumberIsExistsOrNot(temp, $scope.product._id)
                  .then(function (result) {
                    if (!$scope.product.variants) $scope.product.variants = [{
                      productNumber: ''
                    }]

                    if (result.status == true) {
                      $scope.PNvalid = true
                      $scope.product.variants[0].productNumber = productNumber
                    } else {
                      $scope.product.variants[0].productNumber = productNumber
                    }

                    console.log("$scope.search_value.text", $scope.search_value.text);
                    console.log("Current State", $state.current.name)

                    if ($state.current.name == 'brand.add-product' || $state.current.name == 'brand.add-purchase-product') $scope.search_value.text = productNumber

                    console.log("$scope.search_value.text", $scope.search_value.text);


                    if (!$scope.$$phase) {
                      $scope.$apply(function () {
                        $scope.product = $scope.product
                        $scope.search_value.text = $scope.search_value.text
                      })
                    } else {
                      $scope.product = $scope.product
                      $scope.search_value.text = $scope.search_value.text
                    }
                  })
                  .catch(function (error) {
                    console.error(error)
                  })
              }
              else {
                if (productNumber && productNumber.length > 2) {
                  $scope.PNsort = false;
                  $product.productNumberIsExistsOrNot(validProdocutNumber, $scope.product._id)
                    .then(function (result) {
                      if (!$scope.product.variants) $scope.product.variants = [{
                        productNumber: ''
                      }]

                      if (result.status == true) {
                        $scope.PNvalid = true
                        $scope.product.variants[0].productNumber = productNumber
                      } else {
                        $scope.product.variants[0].productNumber = productNumber
                      }

                      console.log("$scope.search_value.text", $scope.search_value.text);
                      console.log("Current State", $state.current.name)

                      if ($state.current.name == 'brand.add-product' || $state.current.name == 'brand.add-purchase-product') $scope.search_value.text = productNumber

                      console.log("$scope.search_value.text", $scope.search_value.text);


                      if (!$scope.$$phase) {
                        $scope.$apply(function () {
                          $scope.product = $scope.product
                          $scope.search_value.text = $scope.search_value.text
                        })
                      } else {
                        $scope.product = $scope.product
                        $scope.search_value.text = $scope.search_value.text
                      }
                    })
                    .catch(function (error) {
                      console.error(error)
                    })

                }
                else {

                }
              }
            }

          })
          .catch(function (error) {
            console.error(error)
          })
      } else {
        $scope.PNvalid = false
      }
    }

$scope.addProductImage = function () {
          // Add Product Images
          $scope.images.push({})
        }

        $scope.removeProductImage = function (mode, index) {
          // For remove the image form Images
          if (mode == 'scope') $scope.images.splice(index, 1)
            else $scope.product.images.splice(index, 1)
          }

        $scope.addNewDiamondForm = function () {
          // Open the form for the add new diamond
          $scope.editDiamond = false
          $scope.addDiamond = true
          if ($scope.product.diamonds != undefined) $scope.diamondIndex = $scope.product.diamonds.length
            $scope.diamond = {}
        }

        $scope.pushDiamond = function () {
          // Add New Diamond in prodocuts diamonds
          if ($scope.product.diamonds == undefined) $scope.product.diamonds = []
            $scope.product.diamonds.push($scope.diamond)
          $scope.diamond = {}
          $scope.addDiamond = false
        }

        $scope.cancelNewDiamond = function () {
          $scope.editDiamond = false
          $scope.addDiamond = false
          $scope.diamond = {}
        }

        $scope.deleteDiamond = function (index) {
          $scope.product.diamonds.splice(index, 1)
        }

        $scope.deleteData = function (data) {
          console.log('close button clicked');
          
          $scope.search_value.text = undefined
          $scope.findProducts('', null)
        }

        $scope.openDiamond = function (index) {
          $scope.diamond = $scope.product.diamonds[index]
          $scope.diamondIndex = index
          $scope.addDiamond = false
          $scope.editDiamond = true
        }

        $scope.updateDiamond = function (index) {
          $scope.product.diamonds[index] = $scope.diamond
          $scope.editDiamond = false
        }

        $scope.getTaxForCompany = function (currentCompanyId) {
          $tax.getTaxForCompany(currentCompanyId)
          .then(function (tax) {
            console.log('Getting Tax Rat', tax)
            if (tax && tax.taxes && tax.taxes.rates) {
              $scope.taxes = tax.taxes.rates
              $scope.tax = tax.taxes.rates[0]
              
              $scope.company.product.priceVat = $scope.tax.rate
              $scope.tax.rate = $scope.tax.rate
              $scope.tax.name = $scope.tax.name
              
              console.log('Taxtes ====> ', $scope.tax)
            } else {

            }
          })
          .catch(function (errorInGettingTax) {
            console.error('Error In Getting Tax For Company', errorInGettingTax)
          })
        }

        $scope.updateProduct = function () {
          console.log('Stock', $scope.newStock)
          var newCollection = {
            clientGroup: $scope.product.clientGroupCollections,
            brand: $scope.product.brandCollections,
            variant: $scope.product.variantCollections
          }

          if ($scope.notInCompany) {
            $scope.stock = $scope.company.product.stock
            delete $scope.company.product.stock
          }

          delete $scope.product.clientGroupCollections
          delete $scope.product.variantCollections
          delete $scope.product.brandCollections

          var suggester = {
            id: $rootScope.user._id,
            name: $rootScope.user.firstName + ' ' + $rootScope.user.lastName + ' ' + $rootScope.currentCompany.name,
            usertype: $rootScope.user.role,
            currentCompanyId: $rootScope.currentCompany._id
          }

          // must have to fill all the required data

          console.log('newCollection=================', newCollection)
          console.log('suggester=================', suggester)

          delete $scope.company.product.onSale
          delete $scope.company.product.calculatedDiscount

          $scope.product.suggestedRetailPriceVat = ($scope.product.suggestedRetailPriceVat != undefined && $scope.product.suggestedRetailPriceVat != null) ? $scope.product.suggestedRetailPriceVat : $scope.company.product.priceVat

          // $scope.company.product.price = $scope.company.product.sellingPrice / (($scope.company.product.priceVat / 100) + 1);
          console.log('Company Product', $scope.company.product)
          $scope.product.suggestedRetailPrice = Number($scope.company.product.suggestedRetailerPriceForProduct)

          console.log('$scope.product.suggestedRetailPrice', $scope.product.suggestedRetailPrice)
          console.log('$scope.product.suggestedRetailPriceVat', $scope.product.suggestedRetailPriceVat)
          delete $scope.company.product.suggestedRetailerPriceForProduct

          console.log('Vat')
          var tempVat = ($scope.product.suggestedRetailPriceVat / 100) + 1
          console.log(tempVat)
          if ($scope.product.suggestedRetailPrice) $scope.product.suggestedRetailPrice = $scope.product.suggestedRetailPrice / tempVat

            console.group('Price Check Before Save')
          console.log("Suggest Retailer Price 'product.suggestedRetailerPrice'", $scope.product.suggestedRetailPrice)
          console.log("Company Purchase Price 'company.product.purchasePrice'", $scope.company.product.purchasePrice)
          console.log("Company Price 'company.product.price'", $scope.company.product.price)
          // console.log("Suggest Retailer Price 'product.suggestedRetailerPrice'" , $scope.product.suggestedRetailPrice);

          console.log("$scope.company.product", $scope.company.product)
          console.log("$scope.product", $scope.product);
          console.log("Fina Stock Befor Adding product", $scope.stock);
          console.log('$rootScope.currentCompany._id',$rootScope.currentCompany._id);
          console.log('originalProduct',$scope.originalProduct);
          
          $company.updateProductByCompany($rootScope.currentCompany._id, $scope.product, $scope.originalProduct, newCollection, $scope.oldCollections, $scope.company.product, suggester, $scope.generatedArticalNumber)
          .then(function (updatedProduct) {
            console.log('updatedProduct', updatedProduct)
            if (updatedProduct && updatedProduct.data) {
              if (updatedProduct.data.productId) {
                if ($scope.images.length > 0 && $scope.images[0].name) {
                  $scope.upload = true
                  Upload.upload({
                    url: 'api/product-image-upload',
                    data: {
                      productId: updatedProduct.data.productId,
                          files: $scope.images, // file input field
                          companyId: $rootScope.currentCompany._id
                        }
                      })
                  .then(function (res) {
                    if ($stateParams.number && $stateParams.id && $stateParams._id) {
                      updateProductDetailsInPurchaseOrder($stateParams.number, $stateParams.id, $stateParams._id, $scope.product.variants[0].productNumber, $scope.purchaseOrderDetailsForUpdate, $scope.product.suggestedRetailPrice, $scope.company.product.priceVat, $scope.company.product.price)
                      .then(function (updated) {
                        if ($scope.notInCompany) {
                          updateProductStock($rootScope.currentCompany._id, $stateParams._id, 0, $scope.stock)
                        } else {
                          goToAssortment($stateParams._id)
                        }
                      })
                      .catch(function (error) {
                        console.log('Error', error)
                      })
                    } else {
                      if ($scope.notInCompany) {
                        console.log('Have to add Stock in history', $scope.stock)
                        updateProductStock($rootScope.currentCompany._id, $stateParams._id, 0, $scope.stock)
                      } else {
                        goToAssortment($stateParams._id)
                      }
                    }
                  })
                  .catch(function (error) {
                    console.error('Error In Update Images', error)
                  })
                } else {
                  if ($stateParams.number && $stateParams.id && $stateParams._id) {
                    updateProductDetailsInPurchaseOrder($stateParams.number, $stateParams.id, $stateParams._id, $scope.product.variants[0].productNumber, $scope.purchaseOrderDetailsForUpdate, $scope.product.suggestedRetailPrice, $scope.company.product.priceVat, $scope.company.product.price)
                    .then(function (updated) {
                      if ($scope.notInCompany) {
                        updateProductStock($rootScope.currentCompany._id, $stateParams._id, 0, $scope.stock)
                      } else {
                        goToAssortment($stateParams._id)
                      }
                    })
                    .catch(function (error) {
                      console.log('Error', error)
                    })
                  } else {
                    if ($scope.notInCompany) {
                      updateProductStock($rootScope.currentCompany._id, $stateParams._id, 0, $scope.stock)
                    } else {
                      goToAssortment($stateParams._id)
                    }
                  }
                }
              } else if (updatedProduct.data.suggestionId) {
                if ($scope.images.length > 0 && $scope.images[0].name) {
                  Upload.upload({
                    url: 'api/product-image-upload',
                    data: {
                      productId: updatedProduct.data.suggestionId,
                          files: $scope.images, // file input field
                          isUpdate: true,
                          companyId: $rootScope.currentCompany._id
                        }
                      })
                  .then(function (res) {
                    if ($stateParams.number && $stateParams.id && $stateParams._id) {
                      updateProductDetailsInPurchaseOrder($stateParams.number, $stateParams.id, $stateParams._id, $scope.product.variants[0].productNumber, $scope.purchaseOrderDetailsForUpdate, $scope.product.suggestedRetailPrice, $scope.company.product.priceVat, $scope.company.product.price)
                      .then(function (updated) {
                        if ($scope.notInCompany) {
                          updateProductStock($rootScope.currentCompany._id, $stateParams._id, 0, $scope.stock)
                        } else {
                          goToAssortment($stateParams._id)
                        }
                      })
                      .catch(function (error) {
                        console.log('Error', error)
                      })
                    } else {
                      if ($scope.notInCompany) {
                        updateProductStock($rootScope.currentCompany._id, $stateParams._id, 0, $scope.stock)
                      } else {
                        goToAssortment($stateParams._id)
                      }
                    }
                  })
                  .catch(function (error) {
                    console.error('Error In Update Images', error)
                  })
                } else {
                  if ($stateParams.number && $stateParams.id && $stateParams._id) {
                    updateProductDetailsInPurchaseOrder($stateParams.number, $stateParams.id, $stateParams._id, $scope.product.variants[0].productNumber, $scope.purchaseOrderDetailsForUpdate, $scope.product.suggestedRetailPrice, $scope.company.product.priceVat, $scope.company.product.price)
                    .then(function (updated) {
                      if ($scope.notInCompany) {
                        updateProductStock($rootScope.currentCompany._id, $stateParams._id, 0, $scope.stock)
                      } else {
                        goToAssortment($stateParams._id)
                      }
                    })
                    .catch(function (error) {
                      console.log('Error', error)
                    })
                  } else {
                    if ($scope.notInCompany) {
                      console.log('Update the stock', $scope.stock)
                      updateProductStock($rootScope.currentCompany._id, $stateParams._id, 0, $scope.stock)
                    } else {
                      goToAssortment($stateParams._id)
                    }
                  }
                }
              } else {
                if ($stateParams.number && $stateParams.id && $stateParams._id) {
                  updateProductDetailsInPurchaseOrder($stateParams.number, $stateParams.id, $stateParams._id, $scope.product.variants[0].productNumber, $scope.purchaseOrderDetailsForUpdate, $scope.product.suggestedRetailPrice, $scope.company.product.priceVat, $scope.company.product.price)
                  .then(function (updated) {
                    if ($scope.notInCompany) {
                      updateProductStock($rootScope.currentCompany._id, $stateParams._id, 0, $scope.stock)
                    } else {
                      goToAssortment($stateParams._id)
                    }
                  })
                  .catch(function (error) {
                    console.log('Error', error)
                  })
                } else {
                  if ($scope.notInCompany) {
                    console.log(' update stock in company', $scope.stock)
                    updateProductStock($rootScope.currentCompany._id, $stateParams._id, 0, $scope.stock)
                  } else {
                    goToAssortment($stateParams._id)
                  }
                }
              }
            } else {
              if ($stateParams.number && $stateParams.id && $stateParams._id) {
                updateProductDetailsInPurchaseOrder($stateParams.number, $stateParams.id, $stateParams._id, $scope.product.variants[0].productNumber, $scope.purchaseOrderDetailsForUpdate, $scope.product.suggestedRetailPrice, $scope.company.product.priceVat, $scope.company.product.price)
                .then(function (updated) {
                  if ($scope.notInCompany) {
                    updateProductStock($rootScope.currentCompany._id, $stateParams._id, 0, $scope.stock)
                  } else {
                    goToAssortment($stateParams._id)
                  }
                })
                .catch(function (error) {
                  console.log('Error', error)
                })
              } else {
                if ($scope.notInCompany) {
                  console.log('add stock in prdocyrt', $scope.stock)
                  updateProductStock($rootScope.currentCompany._id, $stateParams._id, 0, $scope.stock)
                } else {
                  goToAssortment($stateParams._id)
                }
              }
            }
          })
.catch(function (err) {
  console.log('err===============', err)
})
}

function goToAssortment(productId) {
  $rootScope.productId = $stateParams._id
  if ($stateParams._id && $stateParams.number && $stateParams.id) {
    $state.go('brand.purchase-order', {
      orderNo: $stateParams.number
    })
  } else {
    $state.go('brand.assortment')
  }
}

$scope.clearScope = function () {
  if ($scope.productNumberStatus != undefined || $scope.productNumberStatus != null || $scope.productNumberStatus != 'default') $scope.hideHits = true
    else $scope.hideHits = false
  }

$scope.checkKeyPressEvent = function(e){
  if(e.keyCode == 13){
    e.preventDefault();
    e.stopPropagation();
    $scope.findProducts($scope.search_value.text, $scope.searchMode)
  }
}

$scope.changeMode = function () {
    console.log('----------- calling here!!');
    $scope.editProduct = ($scope.editProduct == true) ? false : true;
    $rootScope.user.canViewProductDetails = ($rootScope.user.canViewProductDetails == true) ? true : false;


    console.log("$rootScope.user.canViewProductDetails", $rootScope.user.canViewProductDetails);
    var data = {
        _id: $rootScope.user._id,
        canViewProductDetails: JSON.parse(JSON.stringify($rootScope.user.canViewProductDetails))
    }

    console.log(data);

    $user.updateViewProductDetails(data)
    .then(function (success) {
        console.log("Success", success);
    })
    .catch(function (error) {
        console.error("Error" + JSON.stringify(error));
    })

}

$scope.renderProduct = function (companyId) {
  console.log('--------- renderProduct!!');
  $scope.saveButtonEnabled = false
  if ($rootScope.user && $rootScope.user.canViewProductDetails) {
    $scope.editProduct = false;
  } else {
    $scope.editProduct = true;
  }

  if (!$stateParams._id && !$stateParams.id && !$stateParams.number) {
    console.log(" Case 1 ")
            $scope.mode = 'add' // Default Mode is add Product
            $scope.company.product.price = 0
            $scope.company.product.sellingPrice = 0
            $scope.company.product.purchasePrice = 0
            $scope.company.product.stock = 0
            $scope.company.product.minStock = 0
            $scope.company.product.discount = 0
            $scope.company.product.discountOnPercentage = false
            $scope.company.product.hasStock = false
            $scope.company.product.show = false
            $scope.company.product.isBestseller = false
            $scope.company.product.isFeatured = false
            $scope.company.product.reference = '-'
            $scope.company.product.showSuggestion = false
            $scope.company.product.dropshippingPrice = 0
            $scope.loading = false
            $scope.displaySwitch = true
            $scope.eanValid = true
            $scope.getLatestArticleNumberForCompany(companyId)
            $scope.priceSetting = true
          } else if ($stateParams._id && !$stateParams.id && !$stateParams.number && companyId) {
            console.log('Case 2')
            $scope.images = []
            $scope.mode = 'edit'
            $scope.eanValid = true
            $scope.PNvalid = true
            getStockProducts(companyId, $stateParams._id)
          } else if ($stateParams.number && $stateParams.id && !$stateParams._id) {
            console.log(" Case 3 ")
            $scope.mode = 'add' // Default Mode is add Product
            $scope.displaySwitch = true
            $scope.eanValid = true
            console.log("Hey Man now it's from purchase order")
            $scope.getCompanyOwnBrands($rootScope.currentCompany._id)
            getProductDetailsFromPurchaseOrder($stateParams.number, $stateParams.id, $rootScope.currentCompany._id)
          } else if ($stateParams.number && $stateParams.id && $stateParams._id) {
            console.log('Product is using for add in shop using purchase order')
            console.log('Product is alreday prisma note product')
            console.log(" Case 4 ")
            $scope.displaySwitch = true
            $scope.eanValid = true
            $scope.PNvalid = true
            $scope.purchaseOrderNumber = $stateParams.number
            getProductDetailsFromPurchaseOrderEdit($stateParams.number, $stateParams.id, $rootScope.currentCompany._id)
            productIsAlredayInPurchaseOrder($stateParams.number, $stateParams.id, $stateParams._id, companyId)
          } else {
            console.error('Sorry To Say That! Please, Contact To Prisma Admin!')
          }
        }

        function productIsAlredayInPurchaseOrder(number, itemId, productId, companyId) {
          console.log('------------ productIsAlredayInPurchaseOrder!!');
          $purchaseOrder.productIsAlredayInPurchaseOrder(number, itemId, productId, companyId)
          .then(function (response) {
            if (response.data == true) {
              console.error("You can not use this product for purchase Order it's already in purchaseorder")
              $scope.canNotUseForPurchaseOrder = true
            } else {
              getStockProducts($rootScope.currentCompany._id, $stateParams._id)
            }
          })
          .catch(function (error) {
            console.error('While Checking Product is in purchaseOrder or not', error)
          })
        }

        function getProductDetailsFromPurchaseOrder(number, id, companyId) {
          $purchaseOrder.getProductDetailsFromPurchaseOrder(number, id, companyId)
          .then(function (success) {
            console.group('For New Product')
            console.log(success.data)
            $scope.product[$rootScope.language] = {
              name: success.data.purchase.name
            }

            $scope.product['variants'] = [{}];

            $scope.purchaseOrderDetailsForUpdate = {
              _id: success.data._id,
              shopId: success.data.shop._id,
              companyId: success.data.supplier._id,
              price: success.data.purchase.price,
              priceVat: success.data.purchase.priceVat,
              purchasePrice: success.data.purchase.purchasePrice,
              discount: success.data.purchase.discount,
              storageFactor: success.data.purchase.storageFactor,
              status: success.data.status
            }

            if ($stateParams.number && $stateParams._id && $stateParams.id) $scope.purchaseOrderDetailsForUpdate.canPurchaseOrderHistroy = true
              else $scope.purchaseOrderDetailsForUpdate.canPurchaseOrderHistroy = false


                if (success.data.purchase.unofficialProduct && success.data.purchase.unofficialProduct.insertedProductNumber) {
                  var tempProductNumber = (success.data.purchase.unofficialProduct.companyAppliedStartingDigits) ? success.data.purchase.unofficialProduct.companyAppliedStartingDigits + '-' : ''

                  if (success.data.purchase.unofficialProduct.brand._id) {
                    var index = _.findIndex($scope.companyBrands, function (o) {
                      if (o.brands && o.brands._id) return o.brands._id.toString() == success.data.purchase.unofficialProduct.brand._id._id.toString();
                    });

                    if (index > -1) {
                    // console.log("index", index);
                    // console.log($scope.companyBrands[index])
                    $scope.product.brand = {
                      _id: $scope.companyBrands[index].officialBrands._id,
                      name: $scope.companyBrands[index].officialBrands.en.name,
                      nameSlug: $scope.companyBrands[index].officialBrands.en.nameSlug,
                      startingDigits: $scope.companyBrands[index].officialBrands.startingDigits
                    };
                    // console.log("index", $scope.product.brand);
                    if ($scope.product.brand.startingDigits) {
                      $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                      tempProductNumber = tempProductNumber + $scope.product.brand.startingDigits + '-'
                    }
                  } else {
                    console.log("As it is continue brand is not in company")
                  }
                }

                tempProductNumber = tempProductNumber + success.data.purchase.unofficialProduct.insertedProductNumber
                $scope.search_value.text = JSON.parse(JSON.stringify(tempProductNumber))
                $scope.product.variants[0].productNumberAuto = true
                $scope.product.variants[0].companyStratingDigits = $rootScope.currentCompany.startingDigits
                $scope.productNumberIsExistsOrNot(tempProductNumber)

              } else {
                var tempProductNumber = success.data.purchase.number
                $scope.productNumberIsExistsOrNot(tempProductNumber)
              }


              $scope.company.product.priceVat = success.data.purchase.priceVat || 21
              $scope.company.product.entryMethodCustomerValue = success.data.purchase.entryMethodCustomerValue
              $scope.company.product.price = success.data.purchase.purchasePrice || 0
              $scope.company.product.suggestedRetailerPriceForProduct = success.data.purchase.price || 0
              $scope.company.product.stock = 0
              $scope.company.product.minStock = 0
              $scope.company.product.discount = 0
              $scope.company.product.discountOnPercentage = false
              $scope.company.product.hasStock = false
              $scope.company.product.show = false
              $scope.company.product.isBestseller = false
              $scope.company.product.isFeatured = false
              $scope.company.product.reference = '-'
              $scope.company.product.showSuggestion = false
              $scope.company.product.dropshippingPrice = 0
              $scope.getLatestArticleNumberForCompany(companyId)
              $scope.loading = false
              $scope.priceSetting = true
            })
.catch(function (error) {
  console.log('Error :!', error)
})
}

function getProductDetailsFromPurchaseOrderEdit(number, id, companyId) {
  $purchaseOrder.getProductDetailsFromPurchaseOrder(number, id, companyId)
  .then(function (success) {
    console.group('For New Product')
    console.log(success.data)
    $scope.purchaseOrderDetailsForUpdate = {
      _id: success.data._id,
      shopId: success.data.shop._id,
      companyId: success.data.supplier._id,
      price: success.data.purchase.price,
      purchasePrice: success.data.purchase.purchasePrice,
      status: success.data.status,
      discount: success.data.purchase.discount,
      storageFactor: success.data.purchase.storageFactor
    }

    if ($stateParams.number && $stateParams._id && $stateParams.id) $scope.purchaseOrderDetailsForUpdate.canPurchaseOrderHistroy = true
      else $scope.purchaseOrderDetailsForUpdate.canPurchaseOrderHistroy = false

        $scope.loading = false
    })
  .catch(function (error) {
    console.log('Error :!', error)
  })
}

function getStockProducts(companyId, productId) {

  console.log(" getStockProducts ")

  $company.getCompanyProduct(companyId, productId)
    .then(function (successData) {


      var success = successData.data
      console.log(" product handling guy check this respose: -------------------------------------------------: ", success);
      $scope.product = success.product
      if ($scope.product && $scope.product.variants) {
      $scope.localdata.previousProductNumber = $scope.product.variants[0].productNumber;
    }

    if (success.brand) $scope.product.brand = success.brand

      if ($scope.product.brand && $scope.product.brand.name && $scope.product.brand.isPoolArticleBrand && $scope.product.brand.isPoolArticleBrand == true) {
        $scope.disabled = true
        // $scope.PNandBrandDisabled = true
      } else if ($scope.product.brand && $scope.product.brand.name) {
        $scope.disabled = false
        // $scope.PNandBrandDisabled = true
      }

      $scope.originalProduct = JSON.parse(JSON.stringify($scope.product))

      if ($scope.product.suggestedRetailPrice && $scope.product.suggestedRetailPriceVat) $scope.product.suggestedRetailPrice = Number($scope.product.suggestedRetailPrice * (($scope.product.suggestedRetailPriceVat / 100) + 1)).toFixed(2) || 0

        if ($scope.product.category === 'WATCH') {
          $scope.productCategory = 'WATCH'
          if ($scope.product.watch && $scope.product.watch.dial && $scope.product.watch.dial.color) $scope.dialColor = $scope.dialColors.find(function (color) {
            return color.key == $scope.product.watch.dial.color
          })
            if ($scope.product.watch && $scope.product.watch.case && $scope.product.watch.case.color) $scope.caseColor = $scope.caseColors.find(function (color) {
              return color.key == $scope.product.watch.case.color
            })
              if ($scope.product.watch && $scope.product.watch.strap && $scope.product.watch.strap.color) $scope.strapColor = $scope.strapColors.find(function (color) {
                return color.key == $scope.product.watch.strap.color
              })
            } else if ($scope.product.category && $scope.product.category === 'STRAP') {
              $scope.productCategory = success.product.category
              if ($scope.product.strap && $scope.product.strap.color) $scope.strapColor = $scope.strapColors.find(function (color) {
                return color.key == $scope.product.strap.color
              })
            } else if ($scope.product.category && $scope.product.category === 'JEWEL') {
              $scope.productCategory = success.product.category
              if ($scope.product.jewel && $scope.product.jewel.color) $scope.jewelColor = $scope.jewelColors.find(function (color) {
                return color.key == $scope.product.jewel.color
              })
            } else {
              $scope.productCategory = 'OTHER'
            }

            if (success.companyProduct) {
              $scope.company.product = success.companyProduct
              $scope.oldStock = success.companyProduct.stock
              $scope.company.product.suggestedRetailerPriceForProduct = $scope.product.suggestedRetailPrice

              if (success.companyProduct.purchasePrice != null && success.companyProduct.purchasePrice != undefined) {
                $scope.company.product.purchasePrice = Number(success.companyProduct.purchasePrice.toFixed(2));
              } else {
                $scope.company.product.purchasePrice = 0;
              }

              console.log('Company Product Purchase Price', $scope.company.product)
              if ($scope.company.product.price > 0) {
                $scope.company.product.sellingPrice = $scope.company.product.price * (($scope.company.product.priceVat / 100) + 1)
                  // $scope.company.product.price = $scope.company.product.price * (($scope.company.product.priceVat / 100) + 1);
                  $scope.company.product.sellingPrice = Number($scope.company.product.sellingPrice)
                  // $scope.company.product.price = Number($scope.company.product.price);
                } else if ($scope.product.suggestedRetailPrice >= -1) {
                  $scope.company.product.sellingPrice = $scope.product.suggestedRetailPrice
                  $scope.company.product.price = $scope.product.suggestedRetailPrice / (($scope.product.suggestedRetailPriceVat / 100) + 1)
                } else {
                  $scope.company.product.sellingPrice = 0
                  $scope.company.product.price = 0
                }

                $scope.company.product.hasStock = !($scope.company.product.hasStock)

                if ($scope.company.product.discount > 0) {
                  if ($scope.company.product.discountOnPercentage == true) $scope.usePercentage = true
                    $scope.company.product.onSale = true
                  $scope.setDiscount($scope.company.product.discount, $scope.company.product.discountOnPercentage)
                } else {
                  $scope.company.product.onSale = false
                }
                if (!$scope.company.product.articleNumber) $scope.getLatestArticleNumberForCompany(companyId)
              } else {
                $scope.company.product.discount = 0
                $scope.company.product.discountOnPercentage = false
                $scope.company.product.show = false
                $scope.company.product.isBestseller = false
                $scope.company.product.isFeatured = false
                $scope.company.product.reference = '-'
                $scope.company.product.showSuggestion = false
                $scope.company.product.dropshippingPrice = 0
                $scope.company.product._id = $scope.product._id
                $scope.company.product.stock = 0
                $scope.oldStock = 0
                $scope.notInCompany = true
                if ($scope.product.suggestedRetailPrice && $scope.product.suggestedRetailPriceVat) {
                  $scope.company.product.price = $scope.product.suggestedRetailPrice / (($scope.product.suggestedRetailPriceVat / 100) + 1)
                  $scope.company.product.sellingPrice = $scope.product.suggestedRetailPrice
                }
                $scope.getLatestArticleNumberForCompany(companyId)
              }

              if (success.collections) {
                $scope.oldCollections = success.collections
                console.log("success.collections", success.collections)
                if (success.collections.clientGroupCollections) {

                  console.log("Here Have the client Group Collections")

                  $scope.product.clientGroupCollections = success.collections.clientGroupCollections.filter(
                    function (data) {
                      return data.uploader.companyId.toString() == $rootScope.currentCompany._id.toString()
                    }
                    )
                }
                if (success.collections.brandCollections) $scope.product.brandCollections = success.collections.brandCollections
                  if (success.collections.variantsCollection) $scope.product.variantCollections = success.collections.variantsCollection
                }

              console.log('Now have to prefill the setGender')
              $scope.setTargetAudience($scope.product.male, $scope.product.female, $scope.product.kids)

              $scope.loading = false
              $scope.company.product.suggestedRetailerPriceForProduct = Number($scope.product.suggestedRetailPrice).toFixed(2)
              console.log('company Product Details', $scope.company.product)
              $scope.priceSetting = true
            })
.catch(function (error) {
  console.error('Got the error from getting single product', error)
})
}

$scope.setTargetAudience = function (male, female, kids) {
  if (male) {
    if (female) {
      if (kids) $scope.targetAudience = 'KIDS'
        else $scope.targetAudience = 'UNISEX'
      } else {
        if (kids) $scope.targetAudience = 'BOYS'
          else $scope.targetAudience = 'GENTS'
        }
    } else {
      if (female) {
        if (kids) $scope.targetAudience = 'GIRLS'
          else $scope.targetAudience = 'LADIES'
        }
    }

    $scope.setGender($scope.targetAudience)
  }

  $scope.setCaseColor = function (color) {
    if (!$scope.product.watch.case) $scope.product.watch.case = {}
      $scope.product.watch.case.color = color.key
  }
  $scope.setDialColor = function (color) {
    console.log('color watch dial color ==>', color)
    if (!$scope.product.watch.dial) $scope.product.watch.dial = {}
      $scope.product.watch.dial.color = color.key
  }

  $scope.setJewelColor = function (color) {
    if (!$scope.product.jewel) $scope.product.jewel = {}
      $scope.product.jewel.color = color.key
  }

  $scope.openStockChangesModal = function (companyId, productId, status) {
    return $q(function (resolve, reject) {
      var data = {
        companyId: companyId,
        productId: productId,
        status: status
      }
      if ($scope.oldStock != undefined || $scope.oldStock != null) data.oldStock = $scope.oldStock

        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/company-product-stock-change-modal.html',
          controller: 'companyProductStockChangeModalController',
          size: 'lg',
          resolve: {
            data: function () {
              return data
            }
          }
        })

      modalInstance.result
      .then(function (response) {
        console.log('Response', response)
        if (response) $state.reload()
          resolve(response)
      })
      .catch(function (reason) {
        console.log('Here', reason)
        reject(reason)
      })
    })
  }

  $scope.searchMode = 'exact'

  $scope.findProducts = function (value, searchMode) {
    console.debug('------------------- findProducts!');
    console.debug('value !', value);
    console.debug('searchMode !', searchMode);
    $scope.hits = []

    if (value) {
      if (value.length < 3) return

        var data = {
          companyId: $rootScope.currentCompany._id,
          searchMode: searchMode,
          searchText: value
        }

        $scope.productNumberStatus = false
        console.log('---------- before searchProductForCompany!!');
        $company.searchProductForCompany(data)
        .then(function (response) {
          console.log('Response', response)
          $scope.hits = response.data
          $scope.countOfProducts = response.count
          $scope.hideHits = true
          $scope.productExists = false
          if (response.status != null && response.status != undefined) $scope.productNumberStatus = parseInt(response.status)

            if (response.count == 0) {
              $scope.productNumberIsExistsOrNot(value)
            } else if ($scope.hits.length < 1 && response.count > 0) {
              $scope.productExists = true
            }
          })
        .catch(function (error) {
          console.error('error', error)
        })
      }

      $scope.clearScope()
    }

    $scope.openProduct = function (productId) {
      console.debug('editProduct is called')
      console.debug(productId)
      if ($stateParams.number) {
        $state.go('brand.edit-purchase-product', {
          _id: productId,
          number: $stateParams.number,
          id: $stateParams.id
        })
      } else {
        $state.go('brand.edit-product', {
          _id: productId
        })
      }
      $scope.saveButtonEnabled = true
    }

    $scope.getLatestArticleNumberForCompany = function (companyId) {
      $company.getLatestArticleNumberForCompany(companyId)
      .then(function (success) {
        $scope.company.product.articleNumber = success.data
        $scope.generatedArticalNumber = true
      })
      .catch(function (error) {
        console.log('Error', error)
      })
    }

    $scope.validWristPermimeter = function (wristPermimeter) {
      console.log('validWristPermimeter', wristPermimeter)
      var newValidWP = true
      delete $scope.validWP
      if (wristPermimeter && wristPermimeter < 251 && wristPermimeter > 149) newValidWP = true
        else newValidWP = false

          $scope.validWP = newValidWP
          // if (!$scope.$$phase) {
          //     $scope.$apply(function () {
          //         $scope.validWP = newValidWP;
          //         console.log("validWP apply" , $scope.validWP);
          //     })
          // } else {
          //     $scope.validWP = newValidWP;
          //     console.log("validWP" , $scope.validWP);
          // }
        }



        $scope.$watch('product', function (newProduct, oldProduct) {
          // console.log(" *********************************** ")
          // console.log('product', newProduct, oldProduct)
          // console.log(" *********************************** ")
          // $scope.product  = newProduct
          $productConfig.productIsValidForUpdate($scope.product, $scope.canUpdate)
          .then(function (canUpdate) {
            $scope.product['containsFilterInfo'] = JSON.parse(JSON.stringify(canUpdate));

            // console.log("Hey product handling guy check this for the cccccc", $scope.product['containsFilterInfo']);
            if (canUpdate && (canUpdate.EN || canUpdate.NL || canUpdate.ES || canUpdate.DE || canUpdate.FR)) {
              $scope.dataStatus = 'alright';
            } else {
              $scope.dataStatus = 'bad';
            }
          })
          .catch(function (error) {
            $scope.canUpdate = {
              'EN': false,
              'DE': false,
              'NL': false,
              'FR': false,
              'ES': false
            };
            console.error("Error: Please Contact PrismaNote Admin");
          })
        }, true)




        function updateProductStock(companyId, productId, oldStock, newStock) {
          $company.updateCompanyProductStock(companyId, productId, oldStock, newStock)
          .then(function (success) {
            console.log('Success')
            goToAssortment(productId)
          })
          .catch(function (error) {
            console.log('Error ', error)
            goToAssortment(productId)
          })
        }

        $('#hits').mouseover(function () {
          $scope.hideHits = true
        })

        $scope.$watch('stock', function (newStock, oldStock) {
          console.log('$scope.stock when stock changes', newStock, oldStock)
          $scope.newStock = newStock
        }, true)

        $scope.goBack = function (page) {
          if (page == 'purchaseOrder') {
            $state.go('brand.purchase-order', {
              orderNo: $stateParams.number
            })
          } else {
            $state.go('brand.add-purchase-product', {
              number: $stateParams.number,
              id: $stateParams.id
            })
          }
        }

        function updateProductDetailsInPurchaseOrder(number, itemId, productId, productNumber, purchaseOrderDetailsForUpdate, suggestedRetailPrice, priceVat, price) {
          console.log('Update this Product id in purchaseOrder')
          var productData = {
            suggestedRetailPrice: suggestedRetailPrice,
            price: price,
            priceVat: priceVat
          }

          return $q(function (resolve, reject) {
            $purchaseOrder.updateProductDetailsInPurchaseOrder(number, itemId, productId, productNumber, purchaseOrderDetailsForUpdate, productData, $rootScope.currentCompany._id)
            .then(function (update) {
              console.log('update', update)
              return resolve(update)
            })
            .catch(function (error) {
              console.error('While Update Product Details In  Purchase Order')
              return reject(error)
            })
          })
        }

        function changeImageIndex(currentIndex, newIndex) {
          var image1 = $scope.product.images[currentIndex]
          var image2 = $scope.product.images[newIndex]
          $scope.product.images[currentIndex] = image2
          $scope.product.images[newIndex] = image1
        }

        $scope.previousImage = function (currentIndex) {
          changeImageIndex(currentIndex, (currentIndex - 1))
        }

        $scope.nextImage = function (currentIndex) {
          changeImageIndex(currentIndex, (currentIndex + 1))
        }


        $scope.removeBrand = function () {
          // Have to remove starting digit from the productNumber
          console.log("$scope.product", $scope.product)
          if ($scope.product && $scope.product.brand) {
            if ($scope.product.variants && $scope.product.variants.length && $scope.product.variants[0] && $scope.product.variants[0].productNumber) {
              console.log("First check number based on starting digits")
              if ($scope.product.brand) $scope.productOldBrand = JSON.parse(JSON.stringify($scope.product.brand));
              var index = $scope.product.variants[0].productNumber.indexOf('-');
              console.log(" Index ", index)
              console.log($scope.product.variants[0].brandStratingDigits.length)
              if ($scope.product.variants[0].brandStratingDigits && (index == ($scope.product.variants[0].brandStratingDigits.length))) {
                var currentSD = $scope.product.variants[0].productNumber.substring(0, $scope.product.variants[0].brandStratingDigits.length)
                if (currentSD == $scope.product.variants[0].brandStratingDigits) {
                  $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.variants[0].productNumber.slice($scope.product.variants[0].brandStratingDigits.length + 1)));
                  $scope.search_value.text = $scope.product.variants[0].productNumber
                  delete $scope.product.brand
                } else {
                  console.log("False")
                  delete $scope.product.brand
                }
                console.log(" offical Product with starting digit ")
              } else {
                console.log("Else ");
                delete $scope.product.brand
              }
            } else {
              console.log("Product NUmber is not here remove brand")
              delete $scope.product.brand
            }
          } else {

          }
        }

        $scope.openPrintLabelModal = function () {
          var product = $scope.product;

          var printLabelModal = $uibModal.open({
            templateUrl: '../views/modal/print-label-modal.html',
            controller: 'printLabelModalController',
            resolve: {
              loadProduct: function () {
                return true
              },
              product: function () {
                return product;
              },
              labelDescription:  function () {
                return null
              },
              empty: function () {
                return false
              }
            }
          })
          printLabelModal.result.then(function (result) {
            if (result) {
              //ready
            }
            }, function () {
              //dismissed
          })
        }
       
        $scope.openExOPEN = function () {
          $scope.exOpen = ($scope.exOpen) ? false : true;
        }

      }
      ])

