prismanoteApp.controller('retailerServiceController', ['$http', '$scope', '$rootScope', '$pdf', '$retailer', '$state', '$window', '$language', '$data', 'cookieService', '$log', '$api', '$transaction', '$customer', '$employee', '$q', 'scanner',
  function ($http, $scope, $rootScope, $pdf, $retailer, $state, $window, $language, $data, cookieService, $log, $api, $transaction, $customer, $employee, $q, scanner) {
    $scope.currentPage = 1
    $scope.removedFilter = false
    $scope.dateFilter = {
      startDateOpen: false,
      endDateOpen: false
    }
    $scope.dateOptions = {
      startingDay: 1
    }

    $scope.reverse = true
    $scope.orderby = 'estimatedEndDate'
    $scope.statusFilter = 'ALL_ITEMS'
    $scope.filters = false
    $scope.servicesItemFilterData = []
    $scope.materialItemFilterData = []
    $scope.categoryItemFilterData = []
    $scope.companyFilterData = []
    $scope.employeeFilterData = []
    $scope.transactionsCount = 0;
    $scope.pageSize = '10'
    $scope.saveCurrentPageVal = true
    $scope.getDataOnce = false
    $scope.transactionsGet = true
   // $scope.currentPage = 1
    cookieService.getCookie('servicesCurrentPage')
      .then(function (currentPage) {
        $scope.currentPage = currentPage
        $scope.saveCurrentPageVal = false
        $scope.getFilters()
      })
      .catch(function () {
         $scope.currentPage = 1
      })

    cookieService.getCookie('servicesDateFilterStart')
      .then(function (startDate) {
        if (startDate !== '0' && moment(new Date(parseFloat(startDate))).isValid()) {
          $scope.dateFilter.start = new Date(parseFloat(startDate))
          $scope.filteredStartDate = moment($scope.dateFilter.start).startOf('day').format('YYYY-MM-DD HH:mm')
        }
        cookieService.getCookie('servicesDateFilterEnd')
          .then(function (endDate) {
            if (endDate !== '0' && moment(new Date(parseFloat(endDate))).isValid()) {
              $scope.dateFilter.end = new Date(parseFloat(endDate))
              $scope.filteredEndDate = moment($scope.dateFilter.end).endOf('day').format('YYYY-MM-DD HH:mm')
            }
            $scope.DateFilterCalculation = false;
          })
      })
      .catch(function () {
      })

    $scope.filterTypes = {
      serviceitem: [
        {
          name: 'REPAIR',
          enabled: false
        }, {
          name: 'SPECIAL',
          enabled: false
        },
        {
          name: 'GIFTCARD',
          enabled: false
        },
        {
          name: 'GOLD-PURCHASE',
          enabled: false
        },
        {
          name: 'GOLD_SELL',
          enabled: false
        }
      ],
      category: [
        {
          name: 'UNKNOWN',
          enabled: false
        },
        {
          name: 'WATCH',
          enabled: false
        }, {
          name: 'JEWEL',
          enabled: false
        },
        {
          name: 'OTHER',
          enabled: false
        }
      ],
      material: [
        {
          name: 'UNKNOWN',
          enabled: false
        },
        {
          name: 'GOLD',
          enabled: false
        },
        {
          name: 'SILVER',
          enabled: false
        },
        {
          name: 'NONNOBLE',
          enabled: false
        }
      ],
      employees: []
    }

    $scope.getEmployees = function () {
      return $q(function (resolve, reject) {
        $employee.getEmployees().then(function (response) {
          return response.map(function (e) {
            return {
              _id: e._id,
              firstName: e.firstName ? e.firstName : null,
              lastNamePrefix: e.lastNamePrefix ? e.lastNamePrefix : null,
              lastName: e.lastName ? e.lastName : null,
              enabled: false
            }
          })
        })
        .then(function (employees) {
          $scope.employees = employees
          $scope.filterTypes.employees = $scope.employees
          return resolve()
        })
        .catch(function (error) {
          return reject(error)
        })
      })
    }

    $scope.getFilteredTransactions = function () {
      $scope.loading = true
      if (
        $scope.filteredStartDate || $scope.filteredEndDate || $scope.statusFilter !== 'ALL_ITEMS' ||
        $scope.servicesItemFilterData.length > 0 || $scope.materialItemFilterData.length > 0 ||
        $scope.categoryItemFilterData.length > 0 || $scope.companyFilterData.length > 0 || $scope.employeeFilterData.length > 0) {
        var pageNumber = $scope.currentPage
        $retailer.getShop()
          .then(function (shop) {
            if ($scope.currentPage === undefined) {
              $scope.currentPage = 1
            }
            var data = {
              shopId: shop._id,
              servicesItemFilterData: $scope.servicesItemFilterData,
              materialItemFilterData: $scope.materialItemFilterData,
              categoryItemFilterData: $scope.categoryItemFilterData,
              companyFilterData: $scope.companyFilterData,
              employeeFilterData: $scope.employeeFilterData,
              limit: $scope.pageSize,
              pageNumber: pageNumber,
              filteredStartDate: $scope.filteredStartDate ? $scope.filteredStartDate : undefined,
              filteredEndDate: $scope.filteredEndDate ? $scope.filteredEndDate : undefined,
              statusFilter: $scope.statusFilter === 'ALL_ITEMS' ? undefined : $scope.statusFilter
            }
            $api.post('all-filtered-transactions', data)
              .then(function (response) {
                $scope.loading = false
                if(response && response.data && response.data.transactions && response.data.transactions.count === 0) {
                  $scope.currentPage = 1
                  $scope.getDataOnce = true
                  if($scope.transactions && $scope.transactions.length > 0) {
                    $scope.saveCurrentPage($scope.currentPage)
                  }
                  $scope.transactions = []
                  $scope.transactionsCount = 0
                  
                } else if (response && response.data && response.data.transactions && response.data.transactions.count > 0) {
                  $scope.getDataOnce = false
                  $scope.transactions = response.data.transactions.transactions.reverse()
                  $scope.transactionsCount = response.data.transactions.count
                  $scope.prepareFilters()
                  cookieService.getCookie('servicesCurrentPage')
                    .then(function (currentPage) {
                      $scope.currentPage = currentPage
                    })
                    .catch(function () {
                       $scope.currentPage = 1
                    })
                  }
                  if (response && response.data && response.data.transactions && response.data.transactions.count === 0) {
                    $scope.transactions = []
                    $scope.transactionsCount = 0
                  }
              })
              .catch(function (reason) {
                $log.error('Error while fetching transactions', reason)
                toastr.error(reason.value)
                $scope.loading = false
              })
          })
          .catch(function (reason) {
            $scope.loading = false
            $log.error('error while fetching shop', reason)
          })
      } /* else {
        $scope.loading = false
        $scope.getTransactions()
      } */

    }

    $scope.showFilter = function(){
        $('#filterNav').toggle();
    }
    $scope.giftAmount = 0;
    $scope.goldAmount = 0;
    $scope.DateFilterCalculation = false;

    $scope.getFilters = function () {
      $scope.servicesItemFilterData = []
      $scope.materialItemFilterData = []
      $scope.categoryItemFilterData = []
      $scope.companyFilterData = []
      $scope.employeeFilterData = []
      cookieService.getCookie('servicesItemFilter')
      .then(function (itemFilterCookie) {
        var itemFilter = itemFilterCookie.split(',')
        if (itemFilter.length < 0 || itemFilter[0] === '') {
          return
        }
        for (var filter in itemFilter) {
          if (itemFilter[filter] === undefined || itemFilter[filter] === '') {
            continue
          }

          var index = _.findIndex($scope.filterTypes.serviceitem, { name: itemFilter[filter] })
          if (index >= 0) {
            var serviceName = itemFilter[filter]
            if (serviceName == 'GOLD_SELL') {
              $scope.servicesItemFilterData.push(serviceName)
            } else {
              $scope.servicesItemFilterData.push(serviceName.toLowerCase())
            }
            $scope.filterTypes.serviceitem[index].enabled = true
          }

        }
      })
      .then(function (statusCookie2) {
        return cookieService.getCookie('servicesCategoryFilter')
        .then(function (categoryFilterCookie) {

          var itemFilter = categoryFilterCookie.split(',')
          if (itemFilter.length < 0 || itemFilter[0] === '') return
          for (var filter in itemFilter) {
            if (itemFilter[filter] === undefined || itemFilter[filter] === '') continue

            var index = _.findIndex($scope.filterTypes.category, { name: itemFilter[filter] })
            if (index >= 0) {
              $scope.categoryItemFilterData.push(itemFilter[filter])
              $scope.filterTypes.category[index].enabled = true
            }
          }
        })
        .catch(function () {
        })
      })
      .then(function (statusCookie3) {
        return cookieService.getCookie('servicesMaterialFilter')
          .then(function (materialFilterCookie) {
            var itemFilter = materialFilterCookie.split(',')
            if (itemFilter.length < 0 || itemFilter[0] === '') return
            for (var filter in itemFilter) {
              if (itemFilter[filter] === undefined || itemFilter[filter] === '') continue

              var index = _.findIndex($scope.filterTypes.material, { name: itemFilter[filter] })
              if (index >= 0) {
                $scope.materialItemFilterData.push(itemFilter[filter])
                $scope.filterTypes.material[index].enabled = true
              }
            }
        })
        .catch(function () {
        })
      })
      .then(function (statusCookie34) {
        return cookieService.getCookie('servicesCompaniesFilter')
          .then(function (companiesFilterCookie) {
            return $scope.getSuppliersData()
              .then(function() {
                var itemFilter = companiesFilterCookie.split(',')
                if (itemFilter.length < 0 || itemFilter[0] === '') {
                  return
                }
                for (var filter in itemFilter) {
                  if (itemFilter[filter] === undefined || itemFilter[filter] === '') {
                    continue
                  }
                  var index = _.findIndex($scope.companies.length > 0 ? $scope.companies : $rootScope.companiesData, { name: itemFilter[filter] })
                  if (index >= 0) {
                    $scope.companyFilterData.push(itemFilter[filter])
                    $scope.companies[index].enabled = true
                  }
                }
              })
          })
      })
      .then(function (statusCookie4) {
        return cookieService.getCookie('servicesDateFilterStart')
          .then(function (startDate) {
            if (startDate !== '0' && moment(new Date(parseFloat(startDate))).isValid()) {
              $scope.dateFilter.start = new Date(parseFloat(startDate))
              $scope.filteredStartDate = moment($scope.dateFilter.start).startOf('day').toISOString()
            }
            $scope.giftAmount = 0;
            $scope.goldAmount = 0;
            return cookieService.getCookie('servicesDateFilterEnd')
              .then(function (endDate) {
                if (endDate !== '0' && moment(new Date(parseFloat(endDate))).isValid()) {
                  $scope.dateFilter.end = new Date(parseFloat(endDate))
                  $scope.filteredEndDate = moment($scope.dateFilter.end).endOf('day').toISOString()
                }
                $scope.giftAmount = 0;
                $scope.goldAmount = 0;
                $scope.DateFilterCalculation = false;

                if ($scope.transactions && $scope.transactions.length > 0) {
                  $scope.transactions.forEach(function (value) {
                    if (value.receipt && value.receipt.payments) {
                      if (value.parentTransactionId != null && value.parentTransactionId.type == 'gold-purchase' && value.dateCreated && value.dateCreated > $scope.filteredStartDate && value.dateCreated < $scope.filteredEndDate) {
                        value.receipt.payments.forEach(function (element) {
                          $scope.goldAmount += element.amount
                          $scope.DateFilterCalculation = true;
                        })
                      } else {
                        if (value.dateCreated && value.dateCreated > $scope.filteredStartDate && value.dateCreated < $scope.filteredEndDate) {
                          /* value.receipt.payments.forEach(function (element) {
                            if (element.amount > 0 && element.method === 'giftcard') {
                              $scope.giftAmount += element.amount
                              $scope.DateFilterCalculation = true;
                            }
                          }) */
                          if (value.type && value.type == "giftcard") {
                            $scope.giftAmount += value.transactionTotal;
                            $scope.DateFilterCalculation = true;
                          }
                        }
                      }

                    }
                  })
                }

              })
          })
          .catch(function () {
          })
      })
      .then(function (startDate2) {
        return cookieService.getCookie('servicesDateFilterMode')
        .then(function (dateFilterMode) {
          $scope.dateFilter.mode = dateFilterMode
        })
        .catch(function () {
          $scope.dateFilter.mode = 'both'
        })
      })
      .then(function() {
          return cookieService.getCookie('servicesEmployeeFilter')
            .then(function(employeeFilterCookie){
              $scope.getEmployees()
                .then(function(){
                  var itemFilter = employeeFilterCookie.split(',')
                  if (itemFilter.length < 0 || itemFilter[0] === '') {
                    return
                  }
                  for (var filter in itemFilter) {
                    if (itemFilter[filter] === undefined || itemFilter[filter] === '') {
                      continue
                    }

                    var index = _.findIndex($scope.filterTypes.employees, { _id: itemFilter[filter] })
                    if (index >= 0) {
                      $scope.employeeFilterData.push(itemFilter[filter])
                      $scope.filterTypes.employees[index].enabled = true
                    }
                  }
                })
            })
            .catch(function() {

            })
        })
      .then(function (startDate3) {
        return cookieService.getCookie('servicesPageSize')
          .then(function (pageSize) {
            if(pageSize && pageSize !== '' && pageSize !== '0') {
              $scope.pageSize = pageSize
            }else {
              $scope.pageSize = '10'
            }
            //return
          })
          .catch(function () {
            $scope.pageSize = '10'
          })
      })
      .then(function() {
          if ($scope.filteredStartDate || $scope.filteredEndDate || $scope.statusFilter != 'ALL_ITEMS' ||
            ($scope.servicesItemFilterData.length > 0 || $scope.materialItemFilterData.length > 0 ||
            $scope.categoryItemFilterData.length > 0 || $scope.companyFilterData.length > 0 || $scope.employeeFilterData.length > 0)){
            $scope.getFilteredTransactions()
          } else {
            $scope.getTransactions()
          }
      })
      .catch(function () {
        $scope.statusFilter = 'ALL_ITEMS'
      })
      $(".btn_body").click(function() {
        $(this).find('span').toggleClass('fa-caret-up fa-caret-down');
        if ($(".btn_body").not(this).find("i").hasClass("fa-caret-down")) {
          $(".btn_body").not(this).find("i").toggleClass('fa-caret-up fa-caret-down');
        }
      });

    }

    $scope.saveDateFilter = function (date, isEndDate) {
      if(!date || moment(date).isValid() === false) {
        cookieService.eraseCookie((isEndDate ? 'servicesDateFilterEnd' : 'servicesDateFilterStart'))
        return
      }
      if($scope.dateFilter.start && $scope.dateFilter.start !== 0) {
        $scope.filteredStartDate = moment($scope.dateFilter.start).startOf('day').format('YYYY-MM-DD HH:mm')
      }
      if($scope.dateFilter.end && $scope.dateFilter.end !== 0) {
        $scope.filteredEndDate = moment($scope.dateFilter.end).endOf('day').format('YYYY-MM-DD HH:mm')
      }
      cookieService.setCookie((isEndDate ? 'servicesDateFilterEnd=' : 'servicesDateFilterStart=') + moment(date).valueOf(), 1800)
      $scope.giftAmount = 0;
      $scope.goldAmount = 0;
      $scope.DateFilterCalculation = false;
      if ($scope.filteredStartDate || $scope.filteredEndDate) {
        $scope.getFilters()
      }
      if ($scope.transactions && $scope.transactions.length > 0) {
        $scope.transactions.forEach(function (value) {
          if (value.receipt && value.receipt.payments) {
            if (value.parentTransactionId != null && value.parentTransactionId.type == 'gold-purchase' && value.dateCreated && value.dateCreated > $scope.filteredStartDate && value.dateCreated < $scope.filteredEndDate) {
              value.receipt.payments.forEach(function (element) {
                $scope.goldAmount += element.amount
                $scope.DateFilterCalculation = true;
              })
            } else {
              if (value.dateCreated && value.dateCreated > $scope.filteredStartDate && value.dateCreated < $scope.filteredEndDate) {
                if (value.type && value.type == "giftcard") {
                  $scope.giftAmount += value.transactionTotal;
                  $scope.DateFilterCalculation = true;
                }
              }
            }
          }
        })
      }
    }

    $scope.saveDateFilterMode = function (mode) {
      cookieService.setCookie('servicesDateFilterMode=' + mode, 1800)
      if ($scope.searchquery) {
        $scope.searching($scope.searchquery, null)
      } else {
        if ($scope.filteredStartDate && $scope.filteredEndDate) {
          // $scope.currentPage = 1
        //  $scope.saveCurrentPage($scope.currentPage)
         // $scope.getTransactions()
          $scope.getFilters()
        }
      }
    }

    $scope.savePageSize = function (pageSize) {
      cookieService.setCookie('servicesPageSize=' + pageSize, 31536000)
      if($scope.searchquery) {
        $scope.searching($scope.searchquery, null)
      } else {
          // $scope.currentPage = 1
         // $scope.saveCurrentPage($scope.currentPage)
        // $scope.getTransactions()
        $scope.getFilters()
      }

    }

    $scope.saveCurrentPage = function (currentPage) {
      cookieService.setCookie('servicesCurrentPage=' + currentPage, 1800)
      if($scope.searchquery) {
        $scope.searching($scope.searchquery, null)
      } else {
        // $scope.getTransactions()
         $scope.getFilters()
      }
    }

    $scope.filterChecked = function () {
      $scope.getFilters()
    }

    $scope.$watch('filterTypes', function () {
      var activeItemFilter = $scope.filterTypes.serviceitem.filter(function (f) { return f.enabled }).map(function (f) { return f.name }).join(',')
      cookieService.setCookie('servicesItemFilter=' + activeItemFilter, 1800)

      var activeCategoryFilter = $scope.filterTypes.category.filter(function (f) { return f.enabled }).map(function (f) { return f.name }).join(',')
      cookieService.setCookie('servicesCategoryFilter=' + activeCategoryFilter, 1800)

      var activeMaterialFilter = $scope.filterTypes.material.filter(function (f) { return f.enabled }).map(function (f) { return f.name }).join(',')
      cookieService.setCookie('servicesMaterialFilter=' + activeMaterialFilter, 1800)

      var activeEmployeeFilter = $scope.filterTypes.employees.filter(function (f){return f.enabled}).map(function(f){return f._id}).join(',')
      cookieService.setCookie('servicesEmployeeFilter=' + activeEmployeeFilter, 1800)

     // cookieService.setCookie('servicesCurrentPage=' + currentPage, 1800)
     // $scope.getFilters()
     if (activeItemFilter || activeCategoryFilter || activeMaterialFilter) {
      $scope.saveCurrentPageVal = true
     }
     if ($scope.removedFilter == true) {
      $scope.getFilters()
     }
    }, true)

    // $scope.getFilters()
    
    $scope.openDate = function (type) {
      $scope.dateFilter[type] = !$scope.dateFilter[type]
    }

    $scope.sort = function (column) {
      $scope.orderby = column
      $scope.reverse = !$scope.reverse
    }

    $scope.showFilters = function () {
      $scope.filters = !$scope.filters
    }

    $scope.translations = {
      inhouse: $language.translate('IN_HOUSE'),
      unknown: $language.translate('UNKNOWN')
    }

    $scope.companies = []

    $scope.filterServiceItem = function (item) {
      var filters = $scope.filterTypes.serviceitem

      var counter = 0; var enabledFilters = []

      for (var i = 0; i < filters.length; i++) {
        if (filters[i].enabled) {
          enabledFilters.push(filters[i].name.toLowerCase())
        }
        counter++
        if (counter === filters.length) {
          if (enabledFilters.length === 0) {
            return true
          } else {
            return enabledFilters.indexOf(item.type) >= 0
          }
        }
      }
    }

    $scope.filterProductCategory = function (item) {
      if (!item.type || item.type === undefined || (item.type !== 'repair' && item.type !== 'special' && item.type !== 'gold-purchase' && item.type !== 'giftcard')) {
        return false
      }

      var filters = $scope.filterTypes.category
      var counter = 0
      var enabledFilters = []
      for (var i = 0; i < filters.length; i++) {
        if (filters[i].enabled) {
          enabledFilters.push(filters[i].name)
        }

        counter++
        if (counter === filters.length) {
          if (enabledFilters.length === 0) {
            return true
          } else {
            if (item.type === 'giftcard' || item.type === 'gold-purchase') return false
            if (!item[item.type].category || item[item.type].category === undefined || item[item.type].category === '') {
              item[item.type].category = 'UNKNOWN'
            }
            return enabledFilters.indexOf(item[item.type].category) >= 0
          }
        }
      }
    }
    $scope.filterSupplier = function (item) {
      if (item.type !== 'repair' && item.type !== 'special' && item.type !== 'giftcard' && item.type !== 'gold-purchase') {
        return false
      }
      if (!$scope.companies || $scope.companies.length === 0) {
        return true
      }
      var counter = 0; var enabledFilters = []

      for (var i = 0; i < $scope.companies.length; i++) {
        if ($scope.companies[i].enabled) {
          enabledFilters.push($scope.companies[i]._id)
        }

        counter++
        if (counter === $scope.companies.length) {
          if (enabledFilters.length === 0) {
            return true
          } else {
            if (item.type === 'repair') {
              if (item.repair.repairerType === 'in-house') {
                return enabledFilters.indexOf('000') >= 0
              } else if (item.repair.repairer) {
                return enabledFilters.indexOf(item.repair.repairer._id) >= 0
              }
            } else if (item.supplier) {
              return enabledFilters.indexOf(item.supplier._id) >= 0
            }
          }
        }
      }
    }

    $scope.filterProductMaterial = function (item) {
      // This filter works only on repairs, so if there is one or more filter enabled, only filter on repairs
      var filters = $scope.filterTypes.material
      var counter = 0
      var enabledFilters = []

      for (var i = 0; i < filters.length; i++) {
        if (filters[i].enabled) {
          enabledFilters.push(filters[i].name)
        }

        counter++
        if (counter === filters.length) {
          if (enabledFilters.length === 0) {
            // No filters enabled, so return everything
            return true
          } else {
            if (item.type === 'repair') {
              if (!item.repair.material || item.repair.material === '') {
                item.repair.material = 'UNKOWN'
              }
              return enabledFilters.indexOf(item.repair.material) >= 0
            } else {
              return false
            }
          }
        }
      }
    }

    $scope.customerName = function(customer) {
      return $customer.makeCustomerName(customer)
    }


    $rootScope.filterTypes = [];

    $scope.removeFilter = function (filter, items) {
      items.enabled = false;
      $scope.removedFilter = true
    }

    $scope.filterItemStatus = function (item) {
      var filter = $scope.statusFilter.toLowerCase().replace('_', '-')

      if (filter === 'all-items') {
        return true
      }
      return item.status === filter
    }

    $scope.getTransactionsExport = function (exportType) {
      var pageNumber = $scope.currentPage
      $scope.searchquery = ''
      $retailer.getShop()
        .then(function(shop){
              $http({
                method: 'GET',
                url: '/api/all-transactions/' + shop._id + '?limit=' + $scope.pageSize + '&pageNumber=' + pageNumber + '&pageName=' + 'service&export=' + exportType + '&language=' + $rootScope.language,
                data: { },
                responseType: 'arraybuffer',
              })
              .then(function (response) {
                var file = new Blob([response.data], { type: 'application/pdf' })
                saveAs(file, 'transactions')
              })
              .catch(function (reason) {
                $log.error('Error while fetching transactions', reason)
                $scope.loading = false
                toastr.error(reason.message)
              })
        })
        .catch(function(reason){
          $log.error('error while fetching shop', reason)
        })
    }

    $scope.getTransactions = function (force) {
      $scope.loading = true
     // arguments.callee.toString()
      var pageNumber = $scope.currentPage
      $scope.searchquery = ''
      $retailer.getShop()
        .then(function(shop){
          if($scope.transactionsGet) {
            $scope.transactionsGet = false
            if ($scope.currentPage == undefined) {
              $scope.currentPage = 1
            }
            $api.get('all-transactions/' + shop._id + '?limit=' + $scope.pageSize + '&pageNumber=' + pageNumber + '&pageName=' + 'service')
              .then(function (response) {
                $scope.loading = false
                $scope.transactionsGet = true
                if (response && response.data && response.data.transactions && response.data.transactions.count > 0) {
                  $scope.transactions = response.data.transactions.transactions.reverse()
                  $scope.transactions = $scope.transactions.map(function (t) {
                    if (t.type === 'repair' || t.type === 'special') {
                      t.repairNumber = t[t.type].repairNumber
                    } else if (t.type === 'giftcard') {
                      t.repairNumber = t.special.repairNumber
                    }
                    return t
                  })
                  $scope.transactionsCount = response.data.transactions.count
                  $scope.prepareFilters()
                  cookieService.getCookie('servicesCurrentPage')
                    .then(function (currentPage) {
                      $scope.currentPage = currentPage
                    })
                    .catch(function () {
                      $scope.currentPage = 1
                    })
                } else {
                  $scope.transactions = []
                  $scope.transactionsCount = 0
                }
              })
              .catch(function (reason) {
                $log.error('Error while fetching transactions', reason)
                $scope.loading = false
                toastr.error(reason.message)
              })
          }
        })
        .catch(function(reason){
          $log.error('error while fetching shop', reason)
        })
    }

    app.directive('ngEnter', function () { //a directive to 'enter key press' in elements with the "ng-enter" attribute
    return function (scope, element, attrs) {
      element.bind("keydown keypress", function (event) {
        if (event.which === 13) {
          scope.$apply(function () {
            scope.$eval(attrs.ngEnter);
          });
          event.preventDefault();
        }
      });
    };
  })

    $scope.searching = function (value, event) {
      $scope.currentPage = 1
      var searchedData = []
      $scope.hits = []
      $scope.loading = true
      $retailer.getShop()
      .then(function(shop){
      if (value) {
        $api.post('transaction/search-service-data', {
          search: value,
          shopId: shop._id,
          limit: $scope.pageSize,
          pageNumber: $scope.currentPage
        })
          .then(function (response) {
            $scope.loading = false
            if (response && response.data && response.data.transactions && response.data.transactions.count > 0) {
              $scope.transactions = response.data.transactions.transactions.reverse()
            $scope.transactionsCount =  response.data.transactions.count
            $scope.prepareFilters()
            cookieService.getCookie('servicesCurrentPage')
              .then(function (currentPage) {
                $scope.currentPage = currentPage
              })
              .catch(function () {
                 $scope.currentPage = 1
              })
            } else {
              $scope.transactions = []
            $scope.transactionsCount =  0
            }
            
         
          })
          .catch(function (reason) {
            $log.error('Error while searching customers', reason)
          })
      }
    })
    .catch(function(reason){
      $log.error('error while fetching shop', reason)
    })
    }
  

  $scope.searchVal = function (val) {
    if(val.charAt(0).toUpperCase() === 'R' && val.charAt(1) === '-' && val.substring(2) !== '') {
      val = val.replace('R-', '')
      $scope.searchquery = val
    }

    if (val.length == 0) {
      // $scope.transactions = $scope.customersMainData;
      //  $scope.transactions = $scope.mainData
      //  $scope.totalTransactions = $scope.mainTotal   
      $scope.getTransactions(true)
    }
  }

    $scope.openTransaction = function (id, type, old) {
      
      if(old == undefined) {
        old = false
      }
      $transaction.openTransaction(id, type, old)
    }

    $scope.$on('changeSelectedShop', function () {
     // $scope.getTransactions(true)
    })

    $scope.prepareFilters = function () {
      var counter = 0
      for (var i = 0; i < $scope.transactions.length; i++) {
        if ($scope.transactions[i].type === 'repair' && $scope.transactions[i].repair && Object.keys($scope.transactions[i].repair).length > 0) {
          if ($scope.transactions[i].repair.repairerType && $scope.transactions[i].repair.repairerType === 'in-house') {
            $scope.pushIntoArray({
              name: $scope.translations.inhouse,
              _id: '000'
            })
          } else if ($scope.transactions[i].repair && $scope.transactions[i].repair.repairer) {
            $scope.pushIntoArray($scope.transactions[i].repair.repairer)
          } else if ($scope.transactions[i].supplier) {
            $scope.pushIntoArray($scope.transactions[i].supplier)
          }
        }
        counter++
        if (counter === $scope.transactions.length) {
          cookieService.getCookie('servicesCompaniesFilter')
            .then(function (companiesFilterCookie) {
              var itemFilter = companiesFilterCookie.split(',')
              if (itemFilter.length < 0 || itemFilter[0] === '') return
              for (var filter in itemFilter) {
                if (itemFilter[filter] === undefined || itemFilter[filter] === '') continue
                var index = _.findIndex($scope.companies, { name: itemFilter[filter] })
                if (index >= 0) {
                  $scope.companies[index].enabled = true
                }
              }
            })
            .catch(function () {
            })
        }
      }
    }

    $scope.companyChanged = function () {
      var activeCompaniesFilter = $scope.companies.filter(function (f) { return f.enabled }).map(function (f) { return f.name }).join(',')
      cookieService.setCookie('servicesCompaniesFilter=' + activeCompaniesFilter, 1800)
     // $scope.currentPage = 1
      $scope.getFilters()
    }

    $scope.pushIntoArray = function (data) {
      var index = _.findIndex($scope.companies, { _id: data._id })
      if (index >= 0) {
        return
      }
      var item = {
        _id: data._id,
        name: data.name,
        enabled: false
      }

    //  $scope.companies.push(item)
    }

    $scope.getSuppliersData = function() {
      return $q(function (resolve, reject){
        $retailer.getShop()
          .then(function (shop) {
            $api.post('get-supplier-details', {
              shopId: shop._id
            })
            .then(function (response) {
              $scope.companies = response.data.supplierDetails
              $rootScope.companiesData = $scope.companies
              return resolve()
            })
            .catch(function (reason){
              return reject(reason)
            })
          })
      })

    }

    $scope.export = function () {
      $pdf.downloadPdf('transactions/export', {
        transactions: $scope.filterData,
        filterTypes: $scope.filterTypes,
        companies: $scope.companies,
        language: $rootScope.language,
        shopId: $rootScope.currentShop._id
      }, 'transactions.pdf', 'arraybuffer')
        .then(function (data) {
        })
        .catch(function (reason) {
          console.error(reason)
        })
    }

    $scope.setStatusFilter = function (filter) {
      cookieService.setCookie('servicesStatusFilter=' + filter, 1800)
      $scope.statusFilter = filter.replace('-', '_')
      if($scope.statusFilter === 'ALL_ITEMS') {
        $scope.getTransactions()
      } else {
       // $scope.currentPage = 1
       // $scope.saveCurrentPage($scope.currentPage)
        $scope.getFilters()
      }
    }

    $scope.typeFilter = function (item) {
      if ($scope.filterType === 'ALL_ITEMS') {
        return item.type !== 'offer' && item.type !== 'shop-purchase' && item.type !== 'refund'
      } else if ($scope.filterType === 'REPAIRS') {
        return item.type === 'repair'
      } else {
        return item.type === $scope.filterType.toLowerCase()
      }
    }

    $scope.checkActiveFilter = function (type) {
      var counter = 0
      var list

      if (type === 'date') {
        return $scope.dateFilter.start || $scope.dateFilter.end
      }

      if (type === 'companies') {
        list = $scope.companies
      } else {
        list = $scope.filterTypes[type]
      }
      if(!list || list.length === 0) {
        return
      }

      for (var i = 0; i < list.length; i++) {
        if (list[i].enabled) {
          return true
        } else {
          counter++
          if (counter === list.length) {
            return false
          }
        }
      }
    }

    $scope.getTransactionSupplierRepairer = function(transaction) {
      if(transaction[transaction.type] && transaction[transaction.type].employee) {
        transaction.hasEmployee = true
        return transaction[transaction.type].employee.name
      }
      if(transaction.type === 'repair') {
        if (transaction.repair.repairer && transaction.repair.repairer){
          return transaction.repair.repairer.name
        } else if (transaction.supplier && transaction.supplier.name) {
          return transaction.supplier.name
        } else {
          return $scope.translations.inhouse
        }
      } else {
        if(transaction.supplier && transaction.supplier.name) {
          return transaction.supplier.name
        } else {
          return $scope.translations.inhouse
        }
      }
    }

    $scope.getSupplierRepairerContactDetails = function(transaction) {

      var details = []

      if(transaction.type == 'repair' && transaction.repair.repairer) {
        if(transaction.repair.repairer) {
          if(transaction.repair.repairer.email) { details.push(transaction.repair.repairer.email) }
          if(transaction.repair.repairer.phone && transaction.repair.repairer.phone.landLine) { details.push(transaction.repair.repairer.phone.landLine) }
          if(transaction.repair.repairer.phone && transaction.repair.repairer.phone.mobilePhone) { details.push(transaction.repair.repairer.phone.mobilePhone) }
        }
      } else {
        if(transaction.supplier) {
          if(transaction.supplier && transaction.supplier.email) { details.push(transaction.supplier.email) }
          if(transaction.supplier.phone && transaction.supplier.phone.landLine) { details.push(transaction.supplier.phone.landLine) }
          if(transaction.supplier.phone && transaction.supplier.phone.mobilePhone) { details.push(transaction.supplier.phone.mobilePhone) }
        }
      }

      return String(details.join("\n"))
    }

    $scope.getFilterName = function(filter) {
      if(filter.name) {
        return $language.translate(filter.name.toUpperCase())
      } else if( filter.firstName || filter.lastNamePrefix || filter.lastName) {
        return ((filter.firstName ? filter.firstName + ' ' : '') + (filter.lastNamePrefix ? filter.lastNamePrefix + ' ' : '') + (filter.lastName ? filter.lastName : ''))
      } else {
        return filter.name
      }
    }

    $scope.popover = function (item) {
      if(!item._id || !item.comment || item.comment === "") {
        return
      }
      return (item.comment.toString().length <= 145) ? item.comment.toString() : item.comment.toString().substr(0, 145) + '\u2026'
    }
    // #region Barcode support

    $scope.$on('scanner-started', function () {
      $scope.barcode = true
    })

    $scope.$on('scanner-ready', function (event, args) {
      if (args.type === 'repair' || args.type === 'order') {
        $transaction.getTransactionFromBarcode(args.parsedCode)
          .then(function (transaction) {
            $scope.openTransaction(transaction._id, transaction.type, transaction.oldTill)
          })
          .catch(function (reason){
            toastr.error(reason)
          })
      } else {
        toastr.warning($language.translate('SCAN_A_VALID_SHOPPURCHASE_TRANSACTION'))
      }
    })
    // #endregion Barcode support
  }
])
