prismanoteApp.controller('addemployeeBrandController', ['$scope', '$rootScope', '$state', '$brand', '$company', '$uibModal',
  function ($scope, $rootScope, $state, $brand, $company, $uibModal) {
    $scope.employee = {
      enabled: true
    }
    $scope.sendInvitation = function () {
      var isRightSelected = false
      $scope.isErrorOccured = false
      for (var i = 0; i < $scope.employeeRights.length; i++) {
        if ($scope.employeeRights[i].enabled) {
          isRightSelected = true
        }
      }

      if (isRightSelected) {
        // var isValidPassword = passwordValidation($scope.employee.password, $scope.employee.confirmPassword);
        // if (isValidPassword.isValid) {
        $scope.employee.rights = $scope.employeeRights
        $scope.employee.isEmployee = true
        $scope.employee.username = $scope.employee.email
        // $scope.employee.role = 'wholesaler'
        // $scope.employee.phone = [
        //     {
        //         landLine: $scope.employee.landLine
        //     }
        // ]
        // $scope.employee.address = $scope.employee.address;
        $scope.employee.companyId = $rootScope.currentCompany._id
        $scope.employee.companyName = $rootScope.currentCompany.name
        $scope.employee.companyNameSlug = $rootScope.currentCompany.nameSlug;
        $brand.addEmployeeToTheCompany($scope.employee).then(function (response) {
          // console.log('response: ', response);
          $state.go('brand.employees')
        }).catch(function (error) {
          console.log('error: ', error)
          if (error.status == 400) {
            $scope.errorMsg = error.data.message
            $scope.isErrorOccured = true
          } else {
            $scope.isErrorOccured = true
            $scope.errorMsg = 'Internal Server Error'
          }
        })
        // } else {
        //     $scope.errorMsg = isValidPassword.message;
        //     $scope.isErrorOccured = true;
        // }
      } else {
        $scope.isErrorOccured = true
        $scope.errorMsg = 'Please select the user rights'
      }
    }

    $scope.getCompany = function () {
      $company.getCompany()
        .then(function (company) {
          $scope.currentCompany = company
        })
        .catch(function (reason) {
          console.log('Internal Server Error while fetching shop detail')
        })
    }

    // Niek function
    $scope.openCropImageModal = function () {
      if (!$scope.employee.profileImage) $scope.employee.profileImage = ''
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/image-crop-modal.html',
        controller: 'cropImageModalController',
        keyboard: false,
        backdrop: 'static',
        resolve: {
          defaultImage: function () {
            return $scope.employee.profileImage.indexOf('https://ui-avatars.com/api') == 0
          },
          loadImage: function(){
            return undefined;
          },
          areaType: function () {
            return 'circle';
          }
        }
      })
      modalInstance.result.then(function (result) {
        console.log('Hey, result: ', result)
        if (!result) {
          $scope.employee.profileImage = '' // User choosen to delete his photo;
        } else {
          $scope.employee.file = result
        }
      }, function () {
        // dismissed
      })
    }

    // Checking password is strong or not and valid or not
    function passwordValidation (originalPassword, comparedPassword) {
      if (!originalPassword || !comparedPassword) {
        return { isValid: false, message: 'Password and confirm password field is requried' }
      }

      if (originalPassword.length < 8 || originalPassword.length > 12) {
        return { isValid: false, message: 'Your password length should be 8-12 characters long' }
      }

      if (originalPassword !== comparedPassword) {
        return { isValid: false, message: 'Your password and compared password does not match' }
      }

      // Password contains Lower, Uper and Digits or not
      var variations = {
        digits: /\d/.test(originalPassword),
        lower: /[a-z]/.test(originalPassword),
        upper: /[A-Z]/.test(originalPassword),
        nonWords: /\W/.test(originalPassword)
      }
      var isVariation = true

      for (var check in variations) {
        if (!variations[check]) {
          isVariation = false
          break
        }
      }

      if (!isVariation) {
        return { isValid: false, message: 'Your password should mixed with Uppercase, Lowecase, Numbers and special character' }
      } else {
        return { isValid: true, message: 'Valid password' }
      }
    }

    $scope.employeeRights = [
      {
        name: 'Orders',
        value: 'orders',
        enabled: true
      },
      {
        name: 'Assortment',
        value: 'assortment',
        enabled: true
      },
      {
        name: 'Services',
        value: 'services',
        enabled: true
      },
      {
        name: 'Customers',
        value: 'customers',
        enabled: true
      },
      {
        name: 'Social Media',
        value: 'socialmedia',
        enabled: true
      },
      {
        name: 'News',
        value: 'news',
        enabled: true
      },
      {
        name: 'Website Builder',
        value: 'websitebuilder',
        enabled: true
      },
      {
        name: 'Statistics',
        value: 'statistics',
        enabled: true
      },
      {
        name: 'Company Detail',
        value: 'companydetail',
        enabled: true
      },
      {
        name: 'Employee',
        value: 'employee',
        enabled: true
      }
    ]
  }])
