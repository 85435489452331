prismanoteApp.controller('retailerCCVPaymentModalController', ['$scope', '$rootScope', 'transaction', 'payment', 'amount', '$api', '$uibModalInstance', '$timeout', '$card', '$language', '$transaction',
  function ($scope, $rootScope, transaction, payment, amount, $api, $uibModalInstance, $timeout, $card, $language, $transaction) {
    var loadTime = 750
    var errorCount = 0
    var loadPromise

    $scope.transaction = transaction
    $scope.payment = payment
    $scope.amount = amount

    $scope.paymentErrored = false
    $scope.paymentSucceeded = false
    $scope.paymentProgress = true
    $scope.actionNeeded = 0

    $scope.cardTypes = $card.getCards()
    $scope.actionAlert = null

    var getPaymentData = function () {
      $api.get('ccv/payment', {
        transactionId: $scope.transaction._id,
        shopId: $scope.transaction.shopId,
        paymentId: $scope.payment.reference
      }, null, 2)
        .then(function (result) {
          errorCount = 0
          if(result.status === 200) {
            cancelNextLoad()
            $scope.paymentSucceeded = true
            $scope.paymentProgress = false
            $scope.paymentData = result.data.payment
            $scope.paymentData.success = $scope.paymentData.status ===  'success'

            if($scope.paymentData.ccvStatus === 'OK') {
              $scope.close({result: $scope.paymentData, payment: $scope.payment})
            } else {
              if($scope.paymentData.ccvStatus === 'NEED_CUSTOMER_SIGNATURE') {
                $scope.actionNeeded = 1
                $scope.actionAlert = {
                  type: 'warning',
                  msg: $language.translate('NEED_CUSTOMER_SIGNATURE')
                }
              }
              if($scope.paymentData.ccvStatus === 'NEED_CUSTOMER_IDENTIFICATION') {
                $scope.actionNeeded = 2
                $scope.actionAlert = {
                  type: 'warning',
                  msg: $language.translate('NEED_CUSTOMER_IDENTIFICATION')
                }
              }
              if($scope.paymentData.ccvStatus === 'NEED_CUSTOMER_SIGNATURE_AND_IDENTIFICATION') {
                $scope.actionNeeded = 3
                $scope.actionAlert = {
                  type: 'warning',
                  msg: $language.translate('NEED_CUSTOMER_SIGNATURE_AND_IDENTIFICATION')
                }
              }
              if($scope.paymentData.ccvStatus === 'UNKNOWN') {
                $scope.actionNeeded = 4
                $scope.actionAlert = {
                  type: 'warning',
                  msg: $language.translate('PAYMENT_STATUS_UNKNOWN')
                }
              }
            }
          } else {
              nextLoad()
          }
        })
        .catch(function (reason) {
          console.error('Error checking Payment', reason)
          cancelNextLoad()
          $scope.paymentProgress = false
          $scope.paymentErrored = true

          $scope.paymentError = {
            type: 'danger',
            msg: $language.translate(reason)
          }
        })
    }

    $scope.close = function (data) {
      $uibModalInstance.close(data)
    }

    $scope.proceedtoManual = function () {
      $scope.manual = true
    }
    var cancelNextLoad = function () {
      $timeout.cancel(loadPromise)
    }

    var nextLoad = function (mill) {
      mill = mill || loadTime

      cancelNextLoad()
      loadPromise = $timeout(getPaymentData, mill)
    }
    //after 1.5 seconds start polling for the transaction status
   $timeout(function () {
     getPaymentData()
   }, 1500)

    $scope.restartPayment = function() {
      //restart payment
      $card.restartCCVPayment($scope.transaction, $scope.amount)
        .then(function (payment) {
          $scope.paymentErrored = false
          $scope.paymentSucceeded = false
          $scope.paymentProgress = true
          $scope.payment = payment

          $timeout(function () {
           getPaymentData()
          }, 1000)
        })
        .catch(function (reason){
          console.error('Error restarting payment', reason)
          $scope.paymentError = {
            type: 'danger',
            msg: reason
          }
        })
    }

    $scope.printReceiptAndClose = function () {
      $scope.close({result: $scope.paymentData, payment: $scope.payment, merchantReceipt: true})
    }

    $scope.openTransaction = function () {
      $transaction.openTransaction($scope.transaction._id, $scope.transaction.type)
      $uibModalInstance.dismiss()
    }


  }])
