prismanoteApp.controller('retailerShopProductSortingController', ['$scope', '$uibModalInstance', 'product', '$shop', '$api', '$productConfig',
  function ($scope, $uibModalInstance, product, $shop, $api, $productConfig) {
    console.log('********* retailer shop product **********')
    console.log('product sorting called', product)
    $scope.updatedProducts = []

    $scope.products = product.products
    $scope.modal = product.modal
    console.log('$scope.products::::@@@@',$scope.products);
    console.log('$scope.modalmodal::::@@@@',$scope.modal);

    function changeImageIndex(input, currentIndex, newIndex) {
      console.log('currentIndex::',currentIndex,'newIndex',newIndex);
      var temp = input[currentIndex];
      input[currentIndex] = input[newIndex];
      input[newIndex] = temp;
      $scope.updatedProducts = input
  }

  $scope.loadCollectionList = function () {
      var loadCollectionList = {
        loadCollectionList: true
      }
      $uibModalInstance.close(loadCollectionList)
    
  }

  $scope.updateSortedProducts = function (data) {
    if (data.length > 0) {
      var data = {
        sortProducts : data
      }
      $api.post('update-products-sortingnumber', data)
      .then(function (response) {
       console.log('response:::',response);
       $scope.close()
      // $scope.products = response.data.products
      })
    }
  }

    $scope.previousImage = function (currentIndex) {
      console.log('called', currentIndex);
      changeImageIndex($scope.products, currentIndex, (currentIndex - 1));
    }

  $scope.nextImage = function (currentIndex) {
    console.log('called next',currentIndex);
      changeImageIndex($scope.products, currentIndex, (currentIndex + 1));
  }

  $scope.close = function () {
    console.log('cancel clicked');
    $uibModalInstance.close(true)
  }
  }])
