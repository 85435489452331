prismanoteApp.controller('retailerEditSupplierController', ['$scope', '$rootScope', '$stateParams','$pdf', 'FileSaver', 'cookieService', '$state', '$api', '$uibModal', 'prompt', '$window', '$brand', '$shop', '$clientGroup', 'dialogs', '$language', '$csv2json',
  function ($scope, $rootScope, $stateParams, $pdf, FileSaver, cookieService, $state, $api, $uibModal, prompt, $window, $brand, $shop, $clientGroup, dialogs, $language, $csv2json) {
    $rootScope.redirectflag = false
    // $scope.currentShop = $rootScope.currentShop
    $scope.urlPattern = /^(https?:\/\/)?(www\.)?[a-z0-9-]*\.?[a-z0-9-]+\.[a-z0-9-]+(\/[^<>]*)?$/
    $scope.nameSlug = $stateParams.nameSlug
    $scope.showDates = false
    $scope.disableMonthSelection = false
    $scope.localdata = {
      isAutoOrderEnable: false,
      entryMethodCustomerValue: true,
      discount: undefined,
      margin: undefined,
      storageFactor: undefined,
      tenureTime: 'day',
      lastTenureTime: 'day',
      start_date: new Date(),
      prefillCompanySettings: true,
      phoneCountryCode: undefined,
      repairPhoneCountryCode: undefined,
      canCompanySeeShopStatistics: false,
      shopStatisticsNotification: {},
    }
    $scope.internalComment = ''
    $scope.filter = {}
    $scope.year = moment().year()
    $scope.month = moment().month()
    
    $scope.selectedTab = "PURCHASE_ORDER";
    $scope.pageSize = '10'
    $scope.currentPage = 1
    $scope.updateClick = false
    $scope.filterCheck = false
    $scope.shopFavouriteSupplier = false
    $scope.monthList = [
      "JANUARY",
      "FEBRUARY",
      "MARCH",
      "APRIL",
      "MAY",
      "JUNE",
      "JULY",
      "AUGUST",
      "SEPTEMBER",
      "OCTOBER",
      "NOVEMBER",
      "DECEMBER"
    ];
    $scope.stocklistLoading = true;
    $scope.stocklist = [];
    $scope.commonShopBrands = []
    $scope.companyBrandDetails = []
    $scope.filterDataLength = 0
    $scope.mergeDataFlag = false

    $scope.translate = {
      en: {
        product_number: 'PRODUCT NUMBER',
        vat: 'VAT',
        sold_on_off: 'SOLD ON/OFF',
        Article_group: 'ARTICLE GROUP',
        article_number: 'Article Number',
        supplier_number: 'Supplier Number',
        sold: 'Sold',
        stock: 'Stock',
        purchase_price_excl_vat: 'Purchase Price',
        label_description: 'Description',
        ownership: 'Ownership',
        possession: 'Possession',
        sight: 'Sight',
        consignment: 'Consignment',
        sale_price_incl_vat: 'Sales Price incl. VAT',
        total_sold: 'Total Sold',
        total_stock: 'Total Stock',
        total_purchase_price_excl_vat: 'Total Purchase Price',
        total_sale_price_incl_vat: 'Total Price Incl. VAT', 
        min_stock: 'Min Stock'
      },
      nl: {
        product_number: 'PRODUCT NUMBER',
        vat: 'VAT',
        sold_on_off: 'SOLD ON/OFF',
        Article_group: 'ARTICLE GROUP',
        article_number: 'Artikel nummer',
        supplier_number: 'Leveranciernummer',
        sold: 'Verkocht',
        stock: 'Voorraad',
        purchase_price_excl_vat: 'Aankoopprijs',
        label_description: 'Description',
        ownership: 'Eigenaar',
        possession: 'Eigendom',
        sight: 'Zicht',
        consignment: 'Consignatie',
        sale_price_incl_vat: 'Verkoopprijs incl. VAT',
        total_sold: 'Totaal verkocht',
        total_stock: 'Totale voorraad',
        total_purchase_price_excl_vat: 'Totale aankoopprijs',
        total_sale_price_incl_vat: 'Totale prijs incl. VAT',
        min_stock: 'Min voorraad'
      },
      de: {
        product_number: 'PRODUCT NUMBER',
        vat: 'VAT',
        sold_on_off: 'SOLD ON/OFF',
        Article_group: 'ARTICLE GROUP',
        article_number: 'Artikelnummer',
        supplier_number: 'Lieferantennummer',
        sold: 'Verkauft',
        stock: 'Lager',
        purchase_price_excl_vat: 'Kaufpreis',
        label_description: 'Description',
        ownership: 'Eigentum',
        possession: 'Eigentum',
        sight: 'Sicht',
        consignment: 'CONSIGNMENT',
        sale_price_incl_vat: 'Verkaufspreis inkl. Gesetzl. MwSt',
        total_sold: 'Insgesamt verkauft',
        total_stock: 'Gesamtbestand',
        total_purchase_price_excl_vat: 'Gesamtkaufpreis',
        total_sale_price_incl_vat: 'Gesamtpreis Inkl. MwSt', 
        min_stock: 'Min Lager'
      },
      fr: {
        product_number: 'PRODUCT NUMBER',
        vat: 'VAT',
        sold_on_off: 'SOLD ON/OFF',
        Article_group: 'ARTICLE GROUP',
        article_number: 'Numéro de larticle',
        supplier_number: 'Numéro de fournisseur',
        sold: 'Vendu',
        stock: 'Stock',
        purchase_price_excl_vat: 'Prix dachat',
        label_description: 'Description',
        ownership: 'Possession',
        possession: 'Possession',
        sight: 'Vue',
        consignment: 'Consignation',
        sale_price_incl_vat: 'Prix de vente incl. T.V.A.',
        total_sold: 'Total vendu',
        total_stock: 'Stock total',
        total_purchase_price_excl_vat: 'Prix dachat total',
        total_sale_price_incl_vat: 'Prix total TTC T.V.A.', 
        min_stock: 'Stock minimum'
      },
      es: {
        product_number: 'PRODUCT NUMBER',
        vat: 'VAT',
        sold_on_off: 'SOLD ON/OFF',
        Article_group: 'ARTICLE GROUP',
        article_number: 'Número de artículo',
        supplier_number: 'Número de proveedor',
        sold: 'Vendido',
        stock: 'Valores',
        purchase_price_excl_vat: 'Precio de compra',
        label_description: 'Description',
        ownership: 'Propiedad',
        possession: 'Propiedad',
        sight: 'Vista',
        consignment: 'Envío',
        sale_price_incl_vat: 'Precio de venta incl. IVA',
        total_sold: 'Total vendido',
        total_stock: 'Stock total',
        total_purchase_price_excl_vat: 'Precio de compra total',
        total_sale_price_incl_vat: 'Precio Total Incl. IVA', 
        min_stock: 'Stock mínimo'
      }
    }

    $scope.getYears = function () {
      var cYear = moment().year()
      //Define in which year we should start showing years
      var startYear = 2018
      $scope.years = []
    
      for(var i = startYear; i < cYear +1; i++ ){
        $scope.years.push(i)
      }
      }
    $scope.getMonths = function () {
      $scope.months = [
        {
        id: 0,
        name: $language.translate('JANUARY')
        },
        {
        id: 1,
        name: $language.translate('FEBRUARY'),
        },
        {
        id: 2,
        name: $language.translate('MARCH')
        },
        {
        id: 3,
        name: $language.translate('APRIL')
        },
        {
        id: 4,
        name: $language.translate('MAY')
        },
        {
        id: 5,
        name: $language.translate('JUNE')
        },
        {
        id: 6,
        name: $language.translate('JULY')
        },
        {
        id: 7,
        name: $language.translate('AUGUST')
        },
        {
        id: 8,
        name: $language.translate('SEPTEMBER')
        },
        {
        id: 9,
        name: $language.translate('OCTOBER')
        },
        {
        id: 10,
        name: $language.translate('NOVEMBER')
        },
        {
        id: 11,
        name: $language.translate('DECEMBER')
        }
      ]
      }
      $scope.getWeeks = function () {
        console.log('$scope.year::',$scope.year);
        
      var tWeek = $language.translate('WEEK')
      var weeks = moment().year($scope.year).isoWeeksInYear()
    
      $scope.weeks = []
    
      for (var i = 1; i < (weeks +1); i++) {
        $scope.weeks.push(
        {
          id: i,
          name: tWeek + ' ' + i
        }
        )
      }
      }
      $scope.getYears()
      $scope.getMonths()
      $scope.getWeeks()
      
      /* $scope.filter.startDate = moment().startOf('month').format('YYYY-MM-DD')
      $scope.filter.endDate = moment().endOf('month').format('YYYY-MM-DD') */
      $scope.setFilter = function(period){
        console.debug('------------ setFilter!');
        console.log('period ', period)
				$scope.filter.period = period;
				$scope.filter.endDate = new Date(new Date().setHours(23, 59, 59));

				if(period == 'day'){
					$scope.period = "day";
					$scope.filter.startDate = new Date(new Date().setHours(0, 0, 0));
				}
				if(period == 'week'){
					$scope.period = "week";
					$scope.filter.startDate = new Date(new Date(moment().startOf('week')).setHours(0, 0, 0)); 
				}
				if(period == 'month'){
					$scope.period = "month";
					$scope.filter.startDate = new Date(new Date(moment().startOf('month')).setHours(0, 0, 0));
				}

				if(period == 'year'){
					$scope.period = "year";
					$scope.filter.startDate = new Date(new Date(moment().startOf('year')).setHours(0, 0, 0));
				}
				$scope.tabSelected('PRODUCTS_SOLD');
			}

        $scope.filterChanged = function(value, time){
          $scope.timeSelected = time
          $scope.valueReceived = value
				var currentDate = new Date(new Date().setHours(23, 59, 59));

          if (time === 'year') {
            console.log('year: ', $scope.year, value);
            $scope.filter.startDate = moment().year(value).startOf('year').toDate()
            $scope.filter.endDate = moment().year(value).endOf('year').toDate();
            $scope.year = value
          }
          if (time === 'month') {
            console.log('startDate: ', $scope.year, value, moment().year($scope.year).month(value).startOf('month').toDate());
            console.log('endDate 1: ', $scope.year, value, moment().year($scope.year).month(value).endOf('month').toDate());
            $scope.filter.startDate = moment().year($scope.year).month(value).startOf('month').toDate()
            $scope.filter.endDate = moment().year($scope.year).month(value).endOf('month').toDate()
            if ($scope.filter.startDate < currentDate && $scope.filter.endDate > currentDate && $scope.filter.startDate < $scope.filter.endDate) {
              $scope.filter.endDate = new Date(new Date().setHours(23, 59, 59));
            }
          }
          if (time === 'week') {
            $scope.filter.startDate = moment().year($scope.year).week(value).startOf('week').toDate()
            $scope.filter.endDate = moment().year($scope.year).week(value).endOf('week').toDate()
          }

          if(time === 'date') {
            $scope.filter.period = 'custom'
          }
          }

          $scope.showDate = function(data) {
            $scope.showDate = function(data) {
              console.log('data::::',data);
              if (data) {
                $scope.month = undefined
                $scope.disableMonthSelection = true
              } else {
                $scope.disableMonthSelection = false
              }
              
              $scope.showDates = data
              }
          }

          $scope.mergeData = function (data) {
            console.log('inside mergedata',data);
            console.log('$rootScope.currentShop._id::',$rootScope.currentShop._id);
            console.log('$scope.company._id::',$scope.company._id);
            $scope.loading = true
            if (data == true) {
              $scope.mergeDataFlag = true
              var startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
              var endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
              var startDateYMD = moment(startDate).format('YYYY-MM-DD')
              var endDateYMD = moment(endDate).format('YYYY-MM-DD')
              var body = {
                shopId: $rootScope.currentShop._id,
                companyId: $scope.company._id,
                startDate: startDateYMD ? startDateYMD + 'T00:00:00.000Z' : '',
                endDate: endDateYMD ? endDateYMD + 'T23:59:59.000Z' : '',
                isRetailer: true
              }
              $brand.getMergeShopDetailsForBrandPortalWithSelectedTab(body).then(function (response) {
                console.log('response:::!@#$%^',response);
                try {
                  $scope.filterChanged($scope.valueReceived, $scope.timeSelected)
                  if (response.data.data.lastSoldItems && response.data.data.lastSoldItems.trnres && response.data.data.lastSoldItems.trnres.length > 0) {
                    var tempList = [];
                    // tempList = response.data.data.lastSoldItems.trnres
                    var totalAmount = 0
                    response.data.data.lastSoldItems.trnres.forEach(function (item) {
                      totalAmount += item.productDetail.priceIncVat;
                      //(Math.ceil(artikel.details.quantity) * artikel.details.price * (1 + (artikel.details.priceVat/100)))
                    })
                    tempList = [
                      {
                        'data': response.data.data.lastSoldItems.trnres,
                        'total': totalAmount,
                        'open': true
                      }]

                    tempList.forEach(function (item) {
                      item.data.sort(function (a, b) {
                        return new Date(b.dateCreated) - new Date(a.dateCreated);
                      });
                    });
                    $scope.loading = false
                    $scope.lastSoldItems = tempList;
                    $scope.filterDataLength = $scope.lastSoldItems[0].data.length
                    cookieService.getCookie('servicesCurrentPage')
                      .then(function (currentPage) {
                        $scope.currentPage = currentPage
                      })
                      .catch(function () {
                        $scope.currentPage = 1
                      })
                  } else {
                    $scope.updateClick = false
                    $scope.loading = false
                    $scope.lastSoldItems = [];
                  }
                } catch (e) {
                  console.log('------------------------------->', e);
                }
                
              })
            } else {
              var startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
              var endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
              $scope.filter.startDate = moment(startDate).format('YYYY-MM-DD')
              $scope.filter.endDate = moment(endDate).format('YYYY-MM-DD')
              $scope.mergeDataFlag = false
              $scope.tabSelected('PRODUCTS_SOLD');
              $scope.filter.startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
              $scope.filter.endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
            }
          }
        
        $scope.updateByDateRange = function(){
          $scope.updateClick = true
          $scope.stocklistLoading = true;
          $scope.loading = true
          var startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
          var endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
          $scope.filter.startDate = moment(startDate).format('YYYY-MM-DD')
          $scope.filter.endDate = moment(endDate).format('YYYY-MM-DD')
          if($scope.filter.startDate && $scope.filter.endDate) {
            $scope.tabSelected('PRODUCTS_SOLD');
          } else {
            $scope.stocklistLoading = false;
            $scope.loading = false
          }
          $scope.filter.startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
          $scope.filter.endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
        };
      
    // function InitializeSocket() {
    //   try {
    //     SocketService.on('shop:' + $rootScope.currentShop.nameSlug + $scope.nameSlug + ':company:added:comment:detailpage', function (data) {
    //       console.log('company comment', data)
    //       if ($scope.shopcompanyremarks && $scope.shopcompanyremarks.remarks && $scope.shopcompanyremarks.remarks.length && $scope.shopcompanyremarks.remarks[0]) {
    //         $scope.shopcompanyremarks.remarks.push(data)
    //       } else {
    //         $scope.shopcompanyremarks = {
    //           remarks: [data]
    //         }
    //       }
    //       $scope.autoScrollcomment()
    //     })
    //   } catch (catcherr) {
    //     console.log('catcherr: ', catcherr)
    //   }
    // }

    $scope.editProductpage = function (product) {
      // $state.go('retailer.edit-productproduct{ orderNumber : 'null' , _id: product, status: 'view' });
      //console.log('called..', product);
      console.log('---------------------------------  printing item!!');
      var modalInstance = $uibModal.open({
        templateUrl: '../views/retailer1/product.html',
        controller: 'retailerProduct1Controller',
        size: 'lg',
        resolve: {
          item: function () {
            // product.shopProduct.shippingCosts = $rootScope.currentShop.shippingCosts
            // product.shopProduct = product;
            product = product;
            product.extraData = {};
            product.extraData._id = product._id
            product.extraData.itemId = undefined;
            return product
          }
        },
        windowClass: 'sample-pug'
      });
    }

    $scope.saveCurrentPage = function (currentPage) {
      $scope.currentPage = currentPage;
      console.log('currentPage:::::::::',currentPage);
      var startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
      var endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
      $scope.filter.startDate = moment(startDate).format('YYYY-MM-DD')
      $scope.filter.endDate = moment(endDate).format('YYYY-MM-DD')
     // cookieService.setCookie('servicesCurrentPage=' + currentPage, 1800)
      $scope.tabSelected($scope.selectedTab)
      $scope.filter.startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
      $scope.filter.endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
    }

    $scope.saveAngularFilterCurrentPageData = function (currentPage) {
      $scope.currentPage = currentPage;
      console.log('currentPage:@@@@@@@@@@::::::::',currentPage);
      cookieService.setCookie('servicesCurrentPage=' + currentPage, 1800)
    
    }

    $scope.saveAngularFilterPageSize = function (pageSize) {
      console.log('pageSize:::::::::',pageSize);
      $scope.pageSize = pageSize;
      cookieService.setCookie('servicesPageSize=' + pageSize, 31536000) 
    }

    $scope.savePageSize = function (pageSize) {
      console.log('pageSize::!!!!!!!!!!!!:::::::',pageSize);
      $scope.pageSize = pageSize;
      var startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
      var endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
      $scope.filter.startDate = moment(startDate).format('YYYY-MM-DD')
      $scope.filter.endDate = moment(endDate).format('YYYY-MM-DD')
     // cookieService.setCookie('servicesPageSize=' + pageSize, 31536000) 
      $scope.tabSelected($scope.selectedTab)
      $scope.filter.startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
      $scope.filter.endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
    }

    function getCompanyShopBrandDetails() {
      var body = {
        shopId: $rootScope.currentShop._id,
        companyId: $scope.company._id,
      }
      $brand.getCompanyShopBrands(body).then(function (response) {
        $scope.companyBrandDetails = response.data.data.companyBrands

      })
    }
    
    $scope.tabSelected = function (data) {
      //Broadcast or emit your event here.
      console.log('in tabSelected', data);
      console.log('$scope.filter.startDate::',$scope.filter.startDate);
      $scope.selectedTab = data
      var body = {
        shopId: $rootScope.currentShop._id,
        companyId: $scope.company._id,
        selectedTab: $scope.selectedTab,
        limit: $scope.pageSize,
        pageNumber: $scope.currentPage,
        startDate: $scope.filter.startDate ? $scope.filter.startDate + 'T00:00:00.000Z' : '',
        endDate: $scope.filter.endDate ? $scope.filter.endDate + 'T23:59:59.000Z' : '',
      }
      console.log('body:::::::::::::', body);
      if ($scope.selectedTab == "STOCK_LIST" || $scope.selectedTab == "AVAILABILITY" || $scope.selectedTab == "CHAT" || $scope.selectedTab == "PRODUCTS_SOLD") {
        /*  $brand.getShopDetailsForBrandPortalWithSelectedTab(body).then(function (response) {
           console.log('ressssponse:::',response);
         }) */
        $scope.loading = true
        $scope.stocklistLoading = true;
        $brand.getShopDetailsForBrandPortalWithSelectedTab(body).then(function (response) {
          $scope.stocklistLoading = false;
          $scope.loading = false
          $scope.stocklist = []
          $scope.filterDataLength = 0
          $scope.mergeDataFlag = false
          $scope.availability = [];
          $scope.lastSoldItems = [];
          if (response && response.data && response.data.data) {
            var shopDetails = response.data.data

            // SHOP DETAIL
            if (shopDetails.shop) {
              $scope.shop = shopDetails.shop;
            }
            // STOCK LIST
            if (shopDetails.stocklist && shopDetails.stocklist.shopres && shopDetails.stocklist.shopres.length) {
              $scope.stocklist = shopDetails.stocklist.shopres
              $scope.stocklistLength = shopDetails.stocklist.count
              $scope.stocklistTotal = 0, $scope.stocklistWithoutVATTotal = 0
              $scope.stocklist.forEach(function (item) {
                $scope.stocklistTotal += (item.stock * item.priceIncVat)
                $scope.stocklistWithoutVATTotal += (item.stock * item.purchasePrice)
              })
            }
           if ($scope.selectedTab == "STOCK_LIST" || $scope.selectedTab == "PRODUCTS_SOLD") {
              if (shopDetails.shopData == null) {
                $scope.shopFavouriteSupplier = true
              } else {
                $scope.shopFavouriteSupplier = false
              }
            }
            
            if (shopDetails.stocklist && shopDetails.stocklist.length == 0) {
              $scope.commonShopBrands = []
              if (response.data && response.data.brandList && response.data.brandList.shopBrands.length > 0) {
                $scope.commonShopBrands = response.data.brandList.shopBrands
                console.log('$scope.commonShopBrands::',$scope.commonShopBrands);
              }
            }

            if (!shopDetails.stocklist || !shopDetails.stocklist.shopres || shopDetails.stocklist.shopres.length == 0) {
              $scope.stocklistLoading = false;
            }

            // LAST SOLD ITEMS
            if (shopDetails.lastSoldItems && shopDetails.lastSoldItems.trnres && shopDetails.lastSoldItems.trnres.length) {
              var tempList = [];
              $scope.mergeDataFlag = false
              shopDetails.lastSoldItems.trnres.forEach(function (item) {
                var date = new Date(item.dateCreated);
                var year = date.getFullYear();
                var month = date.getMonth();
                if (tempList.length == 0) {
                  tempList.push(
                    {
                      'year': year,
                      'month': month,
                      'data': [item],
                      'total': item.productDetail.priceIncVat,
                      'open': true
                    }
                  );
                } else {
                  var index = tempList.findIndex(function (obj) { return (obj.year === year && obj.month === month) });
                  if (index != -1) {
                    tempList[index].data.push(item);
                    tempList[index].total += item.productDetail.priceIncVat;
                  } else {
                    tempList.push(
                      {
                        'year': year,
                        'month': month,
                        'data': [item],
                        'total': item.productDetail.priceIncVat,
                        'open': false
                      }
                    );
                  }
                }
              })
              tempList.forEach(function (item) {
                item.data.sort(function (a, b) {
                  return new Date(b.dateCreated) - new Date(a.dateCreated);
                });
              });
              $scope.lastSoldItems = tempList;
              if ($scope.lastSoldItems) {
                $scope.dataLength = shopDetails.lastSoldItems.count
                if ($scope.dataLength > 0) {
                  $scope.updateClick = false
                  $scope.filterCheck = true
                } else {
                  $scope.updateClick = true
                  $scope.filterCheck = false
                }
              }


            }

            // AVAILABILITY
            if (shopDetails.availability && shopDetails.availability.length) {
              console.log('shopDetails.availability::::', shopDetails.availability);
              $scope.availability = shopDetails.availability;
              isEmployeeAvailableAnyTime();
            }
            $scope.loading = false
          }
        }).catch(function (error) {
          $scope.stocklistLoading = false;
          $scope.loading = false
          console.log('error: ', error)
        })
      }
    };

    $scope.exportToPdf = function (data) {
      $scope.stockPdflistLoading = true
      $pdf.downloadPdf('v2/company/export-shopdetail-stocklist-for-brand-for-selected-tab', {
        shopId: $rootScope.currentShop._id,
        companyId: $scope.company._id,
        selectedTab: 'STOCK_LIST',
        language: $rootScope.language,
        exportOption: data && data.isExportAll ?  'ALL' : 'COLLECTION',
        filter: data
      }, $scope.company.name + ' Stocklist.pdf')
      .then(function (data) {
        $scope.stockPdflistLoading = false
       // $scope.loading = false
        /* if ($scope.type !== 'document') {
          $scope.sendingMail = false
          $scope.mailSent = true
          toastr.success($language.translate('MAIL_SENT'))
        } */
      }).catch(function (error) {
        $scope.stockPdflistLoading = false
        console.log('error:@@@@@ ', error)
      //  $scope.stocklist = []
       // $scope.loading = false
       // $scope.stocklistLoading = false
      })

    }

    $scope.getSaleRecords = function (data){
      console.log('----- getSaleRecords!!');
      $scope.loading = true
      var startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
      var endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));

      var startDateYMD = moment(startDate).format('YYYY-MM-DD')
      var endDateYMD = moment(endDate).format('YYYY-MM-DD')

      // console.log('language:::',$rootScope.language);
      $api.post('v2/shop/get-sales-transaction-details', {
        shopId: $rootScope.currentShop._id,
        companyId: $scope.company._id,
        selectedTab: 'PRODUCTS_SOLD',
        startDate: startDateYMD ? startDateYMD + 'T00:00:00.000Z' : '',
        endDate: endDateYMD ? endDateYMD + 'T23:59:59.000Z' : '',
        language: $rootScope.language,
        exportOption: data
      })
      .then(function  (result) {
        $scope.loading = false
        if(data == 'COLLECTION'){
          $scope.testcsvCollection(result.data, 'SALES REPORT', 'Test', 'Semi-colon')
        }else{
          $scope.testcsv(result.data, 'SALES REPORT', 'Test', 'Semi-colon')
        }
      }).catch(function (error) {
        console.log('error:@@@@@ ', error)
        $scope.lastSoldItems = []
        $scope.loading = false
        $scope.updateClick = true
      })

    }

    $scope.testcsvCollection = function (arr, b, c, Separator) {
      console.log('---------- testcsvCollection!');
      // console.log(arr);
      var jsonForCsv = [];
      var transObj = $scope.translate;
      for(var j = 0; j < arr.length; j++){
        var data = arr[j];
        var total = 0;
        var totalSold = 0;
        for(var i = 0; i < data.length; i++){

          var purchasePrice = parseFloat(data[i].details.purchasePrice).toFixed(2);
          var priceIncVat = parseFloat(data[i].productDetail.priceIncVat).toFixed(2) || 0;
          var totalPerRecord = parseFloat(data[i].details.purchasePrice * (parseInt(data[i].quantity) || 0));
          total = parseFloat(total) + parseFloat(totalPerRecord);
          totalSold += parseInt(data[i].quantity);

          totalPerRecord = totalPerRecord.toFixed(2);

          // console.log('here ',i, ' ', priceIncVat + ' ', parseInt(data[i].quantity) + ' ' + totalSold + ' ' + totalPerRecord + ' ' + total)

          if($rootScope.language != 'en'){
            purchasePrice = purchasePrice.replace('.', ',');
            priceIncVat = priceIncVat.replace('.', ',');
            totalPerRecord = totalPerRecord.replace('.', ',');
          }
          // console.log(data[i]);
          if(i == 0){
            var firstObj = { };
            firstObj[transObj[$rootScope.language].Article_group] = data[i].productDetail.collection[$rootScope.language].name;
            firstObj[transObj[$rootScope.language].article_number] = '';
            firstObj[transObj[$rootScope.language].product_number] = '';
            firstObj[transObj[$rootScope.language].sold] = '';
            firstObj[transObj[$rootScope.language].purchase_price_excl_vat] = '';
            firstObj[transObj[$rootScope.language].vat] = '';
            firstObj[transObj[$rootScope.language].sale_price_incl_vat] = '';
            firstObj[transObj[$rootScope.language].stock] = '';
            firstObj[transObj[$rootScope.language].label_description] = '';
            firstObj[transObj[$rootScope.language].min_stock] = '';
            firstObj[transObj[$rootScope.language].sold_on_off] = '';
            firstObj[transObj[$rootScope.language].ownership] = '';
            firstObj[transObj[$rootScope.language].total_purchase_price_excl_vat] = '';

            jsonForCsv.push(firstObj);

            // jsonForCsv.push({
            //   'Collection Name': data[i].productDetail.collection[$rootScope.language].name,
            //   'Article Number': '',
            //   'Product Number': '',
            //   'Sold': '',
            //   'Purchase price': '',
            //   'Vat': '',
            //   'Sales Price incl. VAT': '',
            //   'Stock': '',
            //   'labeDescription': '',
            //   'MinStock': '',
            //   'Sold on/off': '',
            //   'Ownership': '',
            //   'Total purchase price': ''
            // })
          }

          var obj = { };
          obj[transObj[$rootScope.language].Article_group] = '';
          obj[transObj[$rootScope.language].article_number] = data[i].productDetail.shopProduct.articleNumber;
          obj[transObj[$rootScope.language].product_number] = data[i].productDetail.productNumber;
          obj[transObj[$rootScope.language].sold] = parseInt(data[i].quantity),
          obj[transObj[$rootScope.language].purchase_price_excl_vat] = purchasePrice;
          obj[transObj[$rootScope.language].vat] = data[i].details.priceVat;
          obj[transObj[$rootScope.language].sale_price_incl_vat] = priceIncVat;
          obj[transObj[$rootScope.language].stock] = data[i].productDetail.shopProduct.stock;
          obj[transObj[$rootScope.language].label_description] = data[i].productDetail.shopProduct.labelDescription;
          obj[transObj[$rootScope.language].min_stock] = data[i].productDetail.shopProduct.minStock;
          obj[transObj[$rootScope.language].sold_on_off] = data[i].dateCreated;
          obj[transObj[$rootScope.language].ownership] = data[i].productDetail.shopProduct.ownership;
          obj[transObj[$rootScope.language].total_purchase_price_excl_vat] = totalPerRecord;

          // var obj = {
          //   'Collection Name': '',
          //   'Article Number': data[i].productDetail.shopProduct.articleNumber,
          //   'Product Number': data[i].productDetail.productNumber,
          //   'Sold': parseInt(data[i].quantity),
          //   'Purchase price': purchasePrice,
          //   'Vat': data[i].details.priceVat,
          //   'Sales Price incl. VAT': priceIncVat,
          //   'Stock': data[i].productDetail.shopProduct.stock,
          //   'labelDescription': data[i].productDetail.shopProduct.labelDescription,
          //   'MinStock': data[i].productDetail.shopProduct.minStock,
          //   'Sold on/off': data[i].dateCreated,
          //   'Ownership': data[i].productDetail.shopProduct.ownership,
          //   'Total purchase price': totalPerRecord
          // }
          jsonForCsv.push(obj);
  
          if (i === (data.length - 1)) {
            // console.log('------------- if! ', total);

            total = total.toFixed(2);
            if($rootScope.language != 'en'){ total = total.replace('.',','); }

            // var total = 0;
            // for(var k = 0; k < data.length; k++){
            //   total += data[k].productDetail.priceIncVat || 0;
            //   // console.log(' ', total, ' ', data[k].productDetail.priceIncVat);
            // }
            // jsonForCsv.push({})
            var lastObj = { };
            lastObj[transObj[$rootScope.language].Article_group] = '';
            lastObj[transObj[$rootScope.language].article_number] = '';
            lastObj[transObj[$rootScope.language].product_number] = '';
            lastObj[transObj[$rootScope.language].sold] = 'Total sold : ' + totalSold;
            lastObj[transObj[$rootScope.language].purchase_price_excl_vat] = '';
            lastObj[transObj[$rootScope.language].vat] = '';
            lastObj[transObj[$rootScope.language].sale_price_incl_vat] = '';
            lastObj[transObj[$rootScope.language].stock] = '';
            lastObj[transObj[$rootScope.language].label_description] = '';
            lastObj[transObj[$rootScope.language].min_stock] = '';
            lastObj[transObj[$rootScope.language].sold_on_off] = '';
            lastObj[transObj[$rootScope.language].ownership] = '';
            lastObj[transObj[$rootScope.language].total_purchase_price_excl_vat] = 'Total : ' + total;
            jsonForCsv.push(lastObj);
            // jsonForCsv.push({
            //   'Collection Name': '',
            //   'Article Number': '',
            //   'Product Number': '',
            //   'Sold': totalSold,
            //   'Purchase price': '',
            //   'Vat': '',
            //   'Sales Price incl. VAT': '',
            //   'Stock': '',
            //   'labeDescription': '',
            //   'MinStock': '',
            //   'Sold on/off': '',
            //   'Ownership': '',
            //   'Total purchase price': total
            // })
            var gapObj = { };
            gapObj[transObj[$rootScope.language].Article_group] = '';
            gapObj[transObj[$rootScope.language].article_number] = '';
            gapObj[transObj[$rootScope.language].product_number] = '';
            gapObj[transObj[$rootScope.language].sold] = '';
            gapObj[transObj[$rootScope.language].purchase_price_excl_vat] = '';
            gapObj[transObj[$rootScope.language].vat] = '';
            gapObj[transObj[$rootScope.language].sale_price_incl_vat] = '';
            gapObj[transObj[$rootScope.language].stock] = '';
            gapObj[transObj[$rootScope.language].label_description] = '';
            gapObj[transObj[$rootScope.language].min_stock] = '';
            gapObj[transObj[$rootScope.language].sold_on_off] = '';
            gapObj[transObj[$rootScope.language].ownership] = '';
            gapObj[transObj[$rootScope.language].total_purchase_price_excl_vat] = '';
            jsonForCsv.push(gapObj);
            // jsonForCsv.push({
            //   'Collection Name': '',
            //   'Article Number': '',
            //   'Product Number': '',
            //   'Sold': '',
            //   'Purchase price': '',
            //   'Vat': '',
            //   'Sales Price incl. VAT': '',
            //   'Stock': '',
            //   'labeDescription': '',
            //   'MinStock': '',
            //   'Sold on/off': '',
            //   'Ownership': '',
            //   'Total purchase price': ''
            // })
            // console.log(jsonForCsv);
            if(j === (arr.length - 1)){
              // console.log('final call ', i, ' ', j);
              $csv2json.JSONToCSVConvertor(jsonForCsv, b, c, Separator)
              $scope.loadingCSV = false
            }
          }
        }
      }
    }

    $scope.testcsv = function (data, b, c, Separator) {
      console.log('---------- testcsv!');
      var transObj = $scope.translate;
      var jsonForCsv = [];
      var total = 0;
      var totalSold = 0;
      for(var i = 0; i < data.length; i++){
        // console.log('-------- i ', i);
        // console.log(data[i]);
        var purchasePrice = parseFloat(data[i].purchasePrice).toFixed(2);
        var priceIncVat = parseFloat(data[i].priceIncVat).toFixed(2) || 0;
        var totalPerRecord = parseFloat(data[i].purchasePrice * (parseInt(data[i].sold) || 0));
        total = parseFloat(total) + parseFloat(totalPerRecord);
        totalSold += parseInt(data[i].sold);

        totalPerRecord = totalPerRecord.toFixed(2);
        total = total.toFixed(2);

        // console.log('here ',i, ' ', priceIncVat + ' ', parseInt(data[i].sold) + ' ' + totalSold + ' ' + totalPerRecord + ' ' + total)

        if($rootScope.language != 'en'){
          purchasePrice = purchasePrice.replace('.', ',');
          priceIncVat = priceIncVat.replace('.', ',');
          totalPerRecord = totalPerRecord.replace('.', ',');
        }
        // var obj = {
        //   'Article Number': data[i].articleNumber,
        //   'Product Number': data[i].productNumber,
        //   'Sold': parseInt(data[i].sold),
        //   'Purchase price': purchasePrice,
        //   'Vat': data[i].priceVat,
        //   'Sales Price incl. VAT': priceIncVat,
        //   'Stock': data[i].stock,
        //   'MinStock': data[i].minStock,
        //   'Ownership': data[i].ownership,
        //   'labelDescription': data[i].labelDescription,
        //   'Sold on/off': data[i].soldOn,
        //   'ArticleGroup': data[i].collection[$rootScope.language].name,
        //   'Total purchase price': totalPerRecord
        // }
        var obj = {  };
        obj[transObj[$rootScope.language].article_number] = data[i].articleNumber;
        obj[transObj[$rootScope.language].product_number] = data[i].productNumber;
        obj[transObj[$rootScope.language].sold] = parseInt(data[i].sold);
        obj[transObj[$rootScope.language].purchase_price_excl_vat] = purchasePrice;
        obj[transObj[$rootScope.language].vat] = data[i].priceVat;
        obj[transObj[$rootScope.language].sale_price_incl_vat] = priceIncVat;
        obj[transObj[$rootScope.language].stock] = data[i].stock;
        obj[transObj[$rootScope.language].min_stock] = data[i].minStock;
        obj[transObj[$rootScope.language].ownership] = data[i].ownership;
        obj[transObj[$rootScope.language].label_description] = data[i].labelDescription;
        obj[transObj[$rootScope.language].sold_on_off] = data[i].soldOn;
        obj[transObj[$rootScope.language].Article_group] = data[i].collection[$rootScope.language].name;
        obj[transObj[$rootScope.language].total_purchase_price_excl_vat] = totalPerRecord;

        jsonForCsv.push(obj);

        if (i === (data.length - 1)) {
          console.log('------------- if!');
          if($rootScope.language != 'en'){ total = total.replace('.', ',') }
          // var total = 0;
          //   for(var k = 0; k < data.length; k++){
          //     total += parseFloat(data[k].priceIncVat) || 0;
          //     // console.log(' ', total, ' ', data[k].productDetail.priceIncVat);
          //   }
          // jsonForCsv.push({})
          var lastObj = { };

          lastObj[transObj[$rootScope.language].article_number] = '';
          lastObj[transObj[$rootScope.language].product_number] = '';
          lastObj[transObj[$rootScope.language].sold] = 'Total sold : ' + totalSold;
          lastObj[transObj[$rootScope.language].purchase_price_excl_vat] = '';
          lastObj[transObj[$rootScope.language].vat] = '';
          lastObj[transObj[$rootScope.language].sale_price_incl_vat] = '';
          lastObj[transObj[$rootScope.language].stock] = '';
          lastObj[transObj[$rootScope.language].min_stock] = '';
          lastObj[transObj[$rootScope.language].ownership] = '';
          lastObj[transObj[$rootScope.language].label_description] = '';
          lastObj[transObj[$rootScope.language].sold_on_off] = '';
          lastObj[transObj[$rootScope.language].Article_group] = '';
          lastObj[transObj[$rootScope.language].total_purchase_price_excl_vat] = 'Total : ' + total;

          jsonForCsv.push(lastObj);

          // jsonForCsv.push({
          //   'Article Number': '',
          //   'Product Number': '',
          //   'Sold': totalSold,
          //   'Purchase price': '',
          //   'Vat': '',
          //   'Sales Price incl. VAT': '',
          //   'Stock': '',
          //   'MinStock': '',
          //   'Ownership': '',
          //   'labelDescription': '',
          //   'Sold on/off': '',
          //   'ArticleGroup': '',
          //   'Total purchase price': total
          // })
          $csv2json.JSONToCSVConvertor(jsonForCsv, b, c, Separator)
          $scope.loadingCSV = false
        }
      }
    }

    $scope.exportSoldProductToPdf = function (data) {
      $scope.loading = true
      var startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
      var endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));

      var startDateYMD = moment(startDate).format('YYYY-MM-DD')
      var endDateYMD = moment(endDate).format('YYYY-MM-DD')
      console.log('language:::',$rootScope.language);
      $pdf.downloadPdf('v2/company/export-shopdetail-sold-products-for-selected-tab', {
        shopId: $rootScope.currentShop._id,
        companyId: $scope.company._id,
        selectedTab: 'PRODUCTS_SOLD',
        startDate: startDateYMD ? startDateYMD + 'T00:00:00.000Z' : '',
        endDate: endDateYMD ? endDateYMD + 'T23:59:59.000Z' : '',
        language: $rootScope.language,
        exportOption: data
      }, $scope.company.name + ' SoldProducts.pdf')
      .then(function  (data) {
        console.log('data:::',data);
        $scope.loading = false
        $scope.updateClick = false
        /* if ($scope.type !== 'document') {
          $scope.sendingMail = false
          $scope.mailSent = true
          toastr.success($language.translate('MAIL_SENT'))
        } */
      }).catch(function (error) {
        console.log('error:@@@@@ ', error)
        $scope.lastSoldItems = []
        $scope.loading = false
        $scope.updateClick = true
      })

    }

    $scope.getStockList = function () {
      console.log('------- getStockList!');
      $api.post('v2/company/export-shopdetail-stocklist-for-brand-for-selected-tab', {
        shopId: $rootScope.currentShop._id,
        companyId: $scope.company._id,
        selectedTab: 'STOCK_LIST',
        language: $rootScope.language,
        forCsv: true,
        isComingFromSupplier: false
      })
      .then(function(result){
        $scope.stockListCsv(result.data.data, 'SALES REPORT', 'Test', 'Semi-colon')
      })
      .catch(function(err){
        console.log('err ', err);
      })
    }

    $scope.stockListCsv = function (data, b, c, Separator) {
      var jsonForCsv = [];
      for(var i = 0; i < data.length; i++){
        var productNumberWithoutStartingDigits = data[i].productDetail.productNumber;
        if(data[i].productDetail.variants.brandStratingDigits){
          var Slength = data[i].productDetail.variants.brandStratingDigits.length;
          productNumberWithoutStartingDigits = productNumberWithoutStartingDigits.substring(Slength, productNumberWithoutStartingDigits.length);
        }
        var obj = {
          'Product Number': data[i].productDetail.productNumber,
          'Product Number Without Starting Digits': productNumberWithoutStartingDigits,
          'Article Number': data[i].articleNumber,
          'Brand': '',
          'Product Name': data[i].productDetail[$rootScope.language].name,
          'Stock': data[i].stock,
          'MinStock': data[i].minStock,
          'ArticleGroup': data[i].productDetail.collection[$rootScope.language].name,
          'Purchase price': (parseFloat(data[i].purchasePrice).toFixed(2)).replace('.', ','),
          'Sales Price incl. VAT': (parseFloat(data[i].priceIncVat).toFixed(2)).replace('.', ','),
          // 'Selling days': data[i].salewrtdays,
          'Ownership': data[i].ownership
        }
        jsonForCsv.push(obj);

        if (i === (data.length - 1)) {
          var filename = 'Stocklist ' + $scope.company.name;
          $csv2json.JSONToCSVConvertor(jsonForCsv, b, c, Separator, filename)
          $scope.loadingCSV = false
        }
      }
    }

    $scope.openPDFModal = function () {
      console.log('openPDFModal called');

      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/statistics-pdf-modal.html',
        controller: 'statisticsPDFModalController',
        size: 'lg',
        resolve: {
          data: function () {
            return { isComingFromSupplier: true }
          }
        }
      })

      modalInstance.result.then(function (result) {
        console.log('openPDFModal called: ', result);
        if (result == 'cancel') return;
        $scope.exportToPdf(result);
      }, function (error) {
        console.log('error: ', error);
      })
    }

    getCompanyDetails($scope.nameSlug)

    var invalidFeilds = []

    function getCompanyDetails(nameSlug) {
      if ($rootScope.currentShop) {
        // if ($rootScope.currentShop.nameSlug) InitializeSocket()
        var nameSlugArray = nameSlug.split('_')
        $scope.isAutoGenerateCompany = false
        if (nameSlugArray.length) {
          var shopId = nameSlugArray[nameSlugArray.length - 1]
          if ($rootScope.currentShop._id == shopId) {
            $scope.isAutoGenerateCompany = true
          }
        }

        getClientGroup($rootScope.currentShop._id, $scope.nameSlug) // As we need for the purchase order prefilling calculation settings
        $scope.notesSaved = false

        console.log('Now Have to Fectch Company Or Own Company form Suggestion based On nameSlug')

        $shop.getCompanyForEditPage($scope.nameSlug, $rootScope.currentShop._id)
          .then(function (company) {
            console.log('Company', company);
            if (company && company.company && company.company.logoLight && company.company.logoLight.src) {
              $rootScope.companyLogo = $rootScope.awsUrl + company.company.logoLight.src;
            }
            $rootScope.currentCompany = company.company
            $rootScope.currentCompany['hideSupplierSelection'] = true;
            $scope.isCompanyActiveFromLastWeek = company.isCompanyActiveFromLastWeek !== false
            $scope.company = company.company

            var shop_company = company.shopCompany
            getServicesOfRetailerAndCompany()
            getCompanyShopBrandDetails()
            if (shop_company.discount) {
              $scope.localdata.discount = shop_company.discount;
            }
            if (shop_company.shopStatisticsNotification) {
              $scope.localdata.shopStatisticsNotification = shop_company.shopStatisticsNotification;
            }
            if (shop_company.canCompanySeeShopStatistics) {
              $scope.localdata.canCompanySeeShopStatistics = shop_company.canCompanySeeShopStatistics
            }
            if (shop_company.entryMethodCustomerValue !== undefined) {
              $scope.localdata.entryMethodCustomerValue = shop_company.entryMethodCustomerValue
            }
            if (shop_company.purchaseMargin) {
              $scope.localdata.margin = shop_company.purchaseMargin
            }
            if (shop_company.storageFactor) {
              $scope.localdata.storageFactor = shop_company.storageFactor
            }
            if (shop_company.tenureTime) {
              $scope.localdata.tenureTime = shop_company.tenureTime
              $scope.localdata.lastTenureTime = $scope.localdata.tenureTime // For updating in last automatic purchase order
            }
            // $scope.localdata.isAutoOrderEnable = false;
            if (shop_company.isAutoOrderEnable) {
              $scope.localdata.isAutoOrderEnable = shop_company.isAutoOrderEnable
            }
            if (shop_company.start_date) {
              $scope.localdata.start_date = new Date(shop_company.start_date)
            }
            if (shop_company.prefillCompanySettings === false) {
              $scope.localdata.prefillCompanySettings = false
            } else {
              $scope.localdata.prefillCompanySettings = true
            }
            var comment = ''
            if (shop_company.retailerComments && shop_company.retailerComments.length) {
              comment = shop_company.retailerComments[0].comment
            }
            if ($scope.company.phone && $scope.company.phone.countryCode) {
              $scope.localdata.phoneCountryCode = $scope.company.phone.countryCode
            }
            if ($scope.company.repairPhone && $scope.company.repairPhone.countryCode) {
              $scope.localdata.repairPhoneCountryCode = $scope.company.repairPhone.countryCode
            }
            $scope.internalComment = comment
          })
          .catch(function (error) {
            console.error('Error ! While fetch the company based on nameSlug', error)
          })

      } else {
        console.error('Select other shop')
      }
    }

    function getServicesOfRetailerAndCompany() {
      var body = {
        shopId: $rootScope.currentShop._id,
        companyId: $scope.company._id
      }
      $brand.getServiceOfRetailerAndCompany(body).then(function (response) {
        console.log('response--------------------------: ', response)
        $scope.services = []
        if (response.data && response.data.data && response.data.data.length) $scope.services = response.data.data
      }).catch(function (error) {
        console.log('error: ', error)
      })
    }

    $scope.popover = function (item) {
      if(item.productDetails && !item.productDetail){ item.productDetail = item.productDetails; }
      if (item.productDetail) {
        if (item.productDetail.images && item.productDetail.images.length && item.productDetail.images[0].src) {
          var image = $rootScope.awsUrl + item.productDetail.images[0].src
          return '<img src=' + image + " alt='No Image found'>"
        }
      }
    }

    $scope.NewPurchaseOrder = function () {
      $state.go('retailer.purchase-orders')
      // $state.go('retailer.purchase-order', { company: $scope.company.nameSlug });
    }

    $scope.deleteShopCompany = function (companyid) {
      prompt({
        title: $language.translate('REPORT_ADMIN_TO_DELETE_THIS_COMPANY'),
        message: $language.translate('ARE_YOU_SURE_TO_SUGGEST_THE_ADMIN_ABOUT_DELETION'),
        buttons: [
          { label: $language.translate('YES'), primary: true, id: 0 },
          { label: $language.translate('CANCEL'), primary: false, cancel: true, id: 1 }
        ]
      }).then(function (value) {
        if (value.id === 0) {
          // call the delete api for the list

          $shop.canRemoveCompanyForShop($rootScope.currentShop._id, companyid)
            .then(function (status) {
              if (status && status.status) {
                $api.post('company/delete-suggestion', { companyId: companyid, shopId: $rootScope.currentShop._id })
                  .then(function (response) {
                    console.log('Response')
                  })
                  .catch(function (reason) {
                    console.error('Error while deleting suggestion', reason)
                  })
              } else {
                prompt({
                  title: $language.translate('CANT_DELETE_A') + "' '" + $scope.company.name + "' '" + $language.translate('CANT_DELETE_B'),
                  message: $language.translate('CANT_DELETE') + "' '" + $scope.company.name + "' '" + $language.translate('FROM_SHOP_BECAUSE_THERE_ARE_STILL_PO_TRANSACTIONS_PRODUCTS_ATTACHED_TO_SUPPLIER'),
                  buttons: [
                    { label: 'Ok', primary: true, id: 0 }
                  ]
                }).then(function (value) {

                })
              }
            })
            .catch(function (error) {
              console.error('Error While canRemoveCompanyForShop', error)
            })
        } else if (value.id === 1) {
          // do nothing
          // just close the prompt dialog box
        }
      })
    }

    $scope.deleteCompanyFromShop = function (companyid) {
      prompt({
        title: $language.translate('DELETE_THIS_COMPANY_MODAL'),
        message: $language.translate('ARE_YOU_SURE_YOU_WANT_TO_DELETE'),
        buttons: [
          { label: $language.translate('YES'), primary: true, id: 0 },
          { label: $language.translate('CANCEL'), primary: false, cancel: true, id: 1 }
        ]
      }).then(function (value) {
        if (value.id === 0) {
          $shop.canRemoveCompanyForShop($rootScope.currentShop._id, companyid)
            .then(function (status) {
              if (status && status.status) {
                $api.post('shops/delete-company-from-shop', { companyid: companyid, shopId: $rootScope.currentShop._id })
                  .then(function (response) {
                    $state.go('retailer.suppliers')
                  })
                  .catch(function (reason) {
                    console.error('Error while deleting company from shop', reason)
                  })
              } else {
                console.log('Can retailer remove this Company Form Shop ? ' + status.status)
                prompt({
                  title: $language.translate('CANT_DELETE_A') + "' '" + $scope.company.name + "' '" + $language.translate('CANT_DELETE_B'),
                  message: $language.translate('CANT_DELETE') + "' '" + $scope.company.name + "' '" + $language.translate('FROM_SHOP_BECAUSE_THERE_ARE_STILL_PO_TRANSACTIONS_PRODUCTS_ATTACHED_TO_SUPPLIER'),
                  buttons: [
                    { label: 'Ok', primary: true, id: 0 }
                  ]
                }).then(function (value) {

                })
              }
            })
            .catch(function (error) {
              console.error('Error While canRemoveCompanyForShop', error)
            })
        }
      })
    }

    $scope.deleteCompanyFromSugggestionToDelete = function (companyid) {
      $shop.canRemoveCompanyForShop($rootScope.currentShop._id, companyid)
        .then(function (status) {
          if (status && status.status) {
            $api.delete('company/delete-suggested-company', { companyId: companyid, shopId: $rootScope.currentShop._id })
              .then(function (response) {
                $state.go('retailer.suppliers')
              })
              .catch(function (reason) {
                console.error('Error while delete suggested company', reason)
              })
          } else {
            prompt({
              title: $language.translate('CANT_DELETE_A') + "' '" + $scope.company.name + "' '" + $language.translate('CANT_DELETE_B'),
              message: $language.translate('CANT_DELETE') + "' '" + $scope.company.name + "' '" + $language.translate('FROM_SHOP_BECAUSE_THERE_ARE_STILL_PO_TRANSACTIONS_PRODUCTS_ATTACHED_TO_SUPPLIER'),
            buttons: [
                { label: 'Ok', primary: true, id: 0 }
              ]
            }).then(function (value) {

            })
          }
        })
        .catch(function (error) {
          console.error('Error While canRemoveCompanyForShop', error)
        })
    }

    $scope.updateCompnay = function (company) {
      if ($scope.company && $scope.company.website) {
        var link = $scope.company.website
        if (link.substring(0, 7) !== 'http://' && link.substring(0, 8) !== 'https://') {
          $scope.company.website = 'http://' + link
        }
      }

      if ($scope.company.product) $scope.company.phone.countryCode = $scope.localdata.phoneCountryCode || '+31'

      if ($scope.comlocaldata && $scope.comlocaldata.pany.repairPhone) $scope.company.repairPhone.countryCode = $scope.repairPhoneCountryCode || '+31'

      // console.log('website: ', $scope.company);
      $api.put('suggest-update-company/', { 
        company: $scope.company, 
        currentShop: { _id: $rootScope.currentShop._id, name: $rootScope.currentShop.name },
        shopId: $rootScope.currentShop._id 
      })
        .then(function (response) {
          $scope.company = response.data.company
          $state.go('retailer.suppliers')
        })
        .catch(function (reason) {
          console.error('Error while saving suggestion', reason)
        })
      // call api to edit the company
    }

    var getItems = function (type) {
      $api.get(type, { getAll: true })
        .then(function (response) {
          $scope[type] = response.data[type]
          // console.log("Brands", $scope[type]);
        }).catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.getBrands = function () {
      return getItems('brands')
    }

    var searchItems = function (str, type) {
      var matches = []
      $scope[type].forEach(function (item) {
        if ((item.en.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) || (item.en.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
          matches.push(item)
        }
      })
      return matches
    }

    $scope.searchBrands = function (str) {
      return searchItems(str, 'brands')
    }

    $scope.addBrands = function (data) {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/add-brands-modal.html',
        controller: 'addBrandsModalController',
        size: 'lg',
        resolve: {
          data: function () {
            return data
          }
        }
      })

      modalInstance.result.then(function (result) {
        if (result) {
        }
      }, function () {
        // dismissed
      })
    }

    $scope.getClassAfterValidation = function (field) {
      // add the feilds to invalidFeilds if invalid and remove if valid
      if (field == 'email') {
        if (ValidateEmail($scope.company.email) == true) {
          findInInvalidFieldsAndPush('email', $scope.company.email)
          enableSubmitButton()
          return ''
        } else {
          findInInvalidFieldsAndPush('email', $scope.company.email)
          enableSubmitButton()
          return 'orangeColorClass'
        }
      } else if (field == 'name') {
        if ($scope.company.name == '') {
          findInInvalidFieldsAndPush('name', $scope.company.name)
          enableSubmitButton()
          return 'orangeColorClass'
        } else {
          enableSubmitButton()
          return ''
        }
      }
    }

    function findInInvalidFieldsAndPush(fieldName, fieldValue) {
      var position = invalidFeilds.indexOf(fieldName)
      if (fieldName != undefined && position < 0 && fieldValue == '') {
        addInErr(position, fieldName)
      } else if (fieldName != undefined && fieldValue.length > 0) {
        removeFromErr(position, fieldName)
      }
    }

    function addInErr(position, fieldName) {
      if (position < 0) {
        invalidFeilds.push(fieldName)
      }
    }

    function removeFromErr(position, fieldName) {
      if (position >= 0) {
        invalidFeilds.splice(position, 1)
      }
    }

    function enableSubmitButton() {
      if (invalidFeilds.length > 0) {
        document.getElementById('edit-company-button').disabled = true
        return
      }
      document.getElementById('edit-company-button').disabled = false
    }

    function ValidateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    }

    // In Old functionality, have to ask to admin that merge the company but now onwards retailer can do it self.
    $scope.MergeTheCompany = function (company) {
      console.log('MergeTheCompany retailer edit company controller ', company)
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/retailer-ask-to-merge-company-modal.html',
        controller: 'retailerAskToMergeCompanyModalController',
        size: 'lg',
        resolve: {
          data: function () {
            return company
          }
        }
      })

      modalInstance.result.then(function (result) {
      }, function () {

      })
    }

    $scope.brandSelected = function (selected) {
      if (selected.originalObject.en && selected.originalObject.en.nameSlug == 'pool-articles-jewellers') {
        dialogs.error(undefined, $language.translate('YOU_CANNOT_ADD_THIS_BRAND_TO_AN_COMPANY'), { size: 'sm' })
        return
      }
      var brand = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug,
        description: selected.originalObject.description,
        images: selected.originalObject.images,
        restricted: selected.originalObject.restricted
      }
      var existing = false

      angular.forEach($scope.company.brands, function (val) {
        if (val._id == brand._id) {
          existing = true
        }
      })
      if (existing == true) {
        dialogs.notify(undefined, $language.translate('THIS_BRAND_ALREADY_EXISTS_ON_THIS_COMPANY'), { size: 'sm' })

        return
      }
      if ($scope.company.brands === undefined) {
        $scope.company.brands = []
        $scope.company.brands.push(brand)
      } else {
        $scope.company.brands.push(brand)
      }
    }

    $scope.$on('changeSelectedShop', function () {
      getCompanyDetails($scope.nameSlug)
      $scope.getThePurchaseOrderList()
    })

    $scope.getPurchaseOrder = function (defaultOrderNo) {
      if ($rootScope.currentShop) {
        $api.get('purchase-order/' + defaultOrderNo + '/' + $rootScope.currentShop._id)
          .then(function (response) {
            $scope.purchase_order = response.data[0]
            if ($scope.purchase_order && $scope.purchase_order.billingDetails && $scope.purchase_order.billingDetails.length > 0) {
              $scope.deliveryAddress = $scope.purchase_order.billingDetails[0].address[0]
            } else {
              $scope.deliveryAddress = $rootScope.currentShop.address
            }

            if ($scope.purchase_order && $scope.purchase_order.status && $scope.purchase_order.status.length && $scope.purchase_order.status[0].status) {
              if ($scope.purchase_order.status[0].status == 'Processing') {
                $scope.mystatus = 1
              } else if ($scope.purchase_order.status[0].status == 'Inspection') {
                $scope.mystatus = 2
              } else if ($scope.purchase_order.status[0].status == 'Completed') {
                $scope.mystatus = 3
              } else if ($scope.purchase_order.status[0].status == 'Backorder') {
                $scope.mystatus = 1
              }
            }
          })
          .catch(function (reason) {
            console.error('Error while getting orders', reason)
          })
      }
    }

    $scope.getThePurchaseOrderList = function () {
      if ($rootScope.currentShop) {
        var body = {
          company: $stateParams.nameSlug,
          isForShop: true
        }
        $api.get('purchase-orders/' + $rootScope.currentShop.nameSlug, body)
          .then(function (response) {
            // console.log('response:: ', response);
            $scope.totalOfPurchasePrice = 0
            $scope.purchase_orders = response.data.purchaseOrders
            var tempList = [];
            $scope.purchase_orders.forEach(function (item) {
              var date = new Date(item.createdDate);
              var year = date.getFullYear();
              var month = date.getMonth();
              if(tempList.length == 0){
                tempList.push(
                  {
                    'year' : year,
                    'month' : month,
                    'data' : [item],
                    'total' : item.purchasePriceExVat,
                    'open' : true
                  }
                );
              }else{
                var index = tempList.findIndex(function(obj){ return (obj.year === year && obj.month === month)});
                if(index != -1){
                  tempList[index].data.push(item);
                  tempList[index].total += item.purchasePriceExVat;
                }else {
                  tempList.push(
                    {
                      'year' : year,
                      'month' : month,
                      'data' : [item],
                      'total' : item.purchasePriceExVat,
                      'open' : false
                    }
                  );
                }
              }
            })
            tempList.forEach(function (item) {
              item.data.sort(function(a,b){
                return new Date(b.createdDate) - new Date(a.createdDate);
              });
            });
            $scope.purchase_orders = tempList;
            // $scope.purchase_orders.forEach(function (item) {
            //   $scope.totalOfPurchasePrice += item.purchasePriceExVat
            // })
            // console.log("$scope.purchase_orders", $scope.purchase_orders);
            // if ($scope.purchase_orders[0] != undefined) {
            //   console.log('------------------------BEFORE CALLING: ', $scope.purchase_orders[0]);
            //   var defaultOrderNo = $scope.purchase_orders[0].number
            //   $scope.getPurchaseOrder(defaultOrderNo)
            // }

            $scope.loading = false
          })
          .catch(function (reason) {
            console.error('Error while getting orders', reason)
            $scope.loading = false
          })
      }
    }



    $scope.addComment = function (comment) {
      $scope.notesSaved = false

      $api.post('shop-add-company-comment', {
        shopId: $rootScope.currentShop._id,
        companyid: $scope.company._id,
        comment: comment
      })
        .then(function (response) {
          $scope.notesSaved = true
        })

        .catch(function (reason) {
          console.error('Error while adding comment', reason)
        })
    }

    $scope.addSetting = function () {
      // console.log($scope.localdata.tenureTime, $scope.localdata.lastTenureTime)
      $scope.settingsSaved = false
      var body = {
        shopId: $rootScope.currentShop._id,
        companyid: $scope.company._id,

        entry_method: $scope.localdata.entryMethodCustomerValue,
        entryMethodCustomerValue: $scope.localdata.entryMethodCustomerValue,
        storage_factor: $scope.localdata.storageFactor,
        purchase_margin: $scope.localdata.margin,
        standard_discount: $scope.localdata.discount,
        tenureTime: $scope.localdata.tenureTime,
        start_date: $scope.localdata.start_date,
        isAutoOrderEnable: $scope.localdata.isAutoOrderEnable,
        prefillCompanySettings: $scope.localdata.prefillCompanySettings,
        canCompanySeeShopStatistics: $scope.localdata.canCompanySeeShopStatistics,
        isTenureTimeChanged: ($scope.localdata.lastTenureTime !== $scope.localdata.tenureTime) // For updating in last automatic purchase order
      }

      // console.log('body: ', body);
      $api.post('shop-add-company-settings', body)
        .then(function (response) {
          $scope.localdata.lastTenureTime = $scope.localdata.tenureTime
          $scope.settingsSaved = true
        })
        .catch(function (reason) {
          console.error('Error while saving settings', reason)
        })
    }

    $scope.companySeeSettings = function (isEnable) {
      if (!isEnable) {
        console.log('if: ', isEnable);
        $scope.addSetting();
      } else {
        console.log('else: ', isEnable);
        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/shop-statistics-for-company-modal.html',
          controller: 'shopStatisticsCompanyModalController',
          size: 'lg',
          resolve: {
            modalData: function () {
              return { companyId: $scope.company._id, shopId: $rootScope.currentShop._id, shopStatisticsNotification: $scope.localdata.shopStatisticsNotification };
            }
          }
        });

        modalInstance.result.then(function (response) {
          console.log('companySeeSettings: ', response);
          if (response === 'cancel') {
            $scope.addSetting();
          }
        }).catch(function (reason) {
          console.log('reason: ', reason, reason == 'backdrop click');
          if (reason == 'backdrop click') {
            $scope.addSetting();
          }
        });
      }
    }

    $scope.getAddressInfo = function () {
      if (!$scope.company.address.houseNumber || !$scope.company.address.postalCode || $scope.company.address.postalCode.length < 6 || $scope.company.address.country != 'NL') {
        return
      }

      $api.post('get-address-info', { zipcode: $scope.company.address.postalCode, number: $scope.company.address.houseNumber })
        .then(function (result) {
          console.log('result', result)
          $scope.company.address.street = result.data.address.street
          $scope.company.address.city = result.data.address.city
        })
        .catch(function (reason) {
          console.error('Error while getting data from postalcode', reason)
        })
    }

    // Start BANK Functions
    $scope.addNewBankForm = function () {
      // Open the form for the add new bank
      $scope.editBank = false
      $scope.addBank = true
      if ($scope.company.bankdetails) $scope.bankIndex = $scope.company.bankdetails.length
      $scope.bankdetail = {}
    }

    $scope.pushBank = function () {
      // Add New BANK in company bankdetails
      if (!$scope.company.bankdetails) $scope.company.bankdetails = []
      $scope.company.bankdetails.push($scope.bankdetail)
      $scope.bankdetail = {}
      $scope.addBank = false
    }

    $scope.cancelBank = function () {
      $scope.editBank = false
      $scope.addBank = false
      $scope.bankdetail = {}
    }

    $scope.deleteBank = function (index) {
      $scope.cancelBank()
      $scope.company.bankdetails.splice(index, 1)
    }

    $scope.openBank = function (index) {
      $scope.bankdetail = $scope.company.bankdetails[index]
      $scope.bankdetail.isVerified = false
      $scope.bankIndex = index
      $scope.addBank = false
      $scope.editBank = true
    }

    $scope.updateBank = function (index) {
      $scope.company.bankdetails[index] = $scope.bankdetail
      $scope.editBank = false
    }
    // End Banks Functions

    $scope.deleteBrand = function (index) {
      $scope.company.brands.splice(index, 1)
    }

    $scope.getComment = function () {
      // console.log('get comment called: ', $rootScope.currentShop._id, $scope.company._id);

      if (!$rootScope.currentShop || !$rootScope.currentShop._id) {
        $scope.loading = true
        $retailer.getShop()
          .then(function (shop) {
            $scope.loading = false
            console.log('retailer edit supplier 1337: ', shop);
            $rootScope.currentShop = shop
            $rootScope.currentShop["portal"] = "retailer"
            getCommentofCompanyOrShop()
          })
          .catch(function (reason) {
            $scope.loading = false
            console.log('Current shop data not found: ', reason)
          })
      } else {
        getCommentofCompanyOrShop()
      }
    }

    function getCommentofCompanyOrShop() {
      if ($rootScope.currentShop && $rootScope.currentShop._id && $scope.company) {
        var body = {
          shopId: $rootScope.currentShop._id,
          companyId: $scope.company._id
        }
        $brand.getCommentofCompanyOrShop(body).then(function (response) {
          // console.log('getComment response: ', response);
          $scope.shopcompanyremarks = response.data
        }).catch(function (error) {
          console.log('error: ', error)
        })
      }
    }

    // Adding a comment so retailer will get notification for the same
    $scope.AddComment = function (comment) {
      if ($rootScope.currentShop && $rootScope.currentShop._id && $scope.company && comment) {
        // $scope.updateThePurchaseOrder($rootScope.currentShop);
        var body = {
          shopId: $rootScope.currentShop._id,
          companyId: $scope.company._id,
          shopSlug: $rootScope.currentShop.nameSlug,
          companySlug: $scope.company.nameSlug,
          eType: 'retailer',
          remark: comment
        }

        // console.log('body: ', body);
        $brand.addCommentofCompanyOrShop(body).then(function (response) {
          console.log('response: ', response)
          $scope.currentremark = ''
          $('#currentremark').val('')
          $scope.getComment()
        }).catch(function (error) {
          $scope.currentremark = ''
          $('#currentremark').val('')
          console.log(err)
        })
      }
    }

    $scope.getPurchaseOrderAndRepairWhichHasRemarks = function () {
      var body = {
        companyId: $scope.company._id,
        shopId: $rootScope.currentShop._id
      }
      console.log('getPurchaseOrderAndRepairWhichHasRemarks: ', body)
      $brand.getPurchaseOrderAndRepairWhichHasRemarks(body).then(function (response) {
        console.log('getPurchaseOrderAndRepairWhichHasRemarks: ', response)
        $scope.purchaseOrdersAndRepairs = []
        $scope.repairs = []
        if (response && response.data && response.data.data) {
          var data = response.data.data
          if (data && data.orders && data.orders.length) $scope.purchaseOrdersAndRepairs = data.orders
          if (data && data.repairs && data.repairs.length) $scope.repairs = data.repairs
        }
        if (response && response.data && response.data.data && response.data.data.length) $scope.purchaseOrdersAndRepairs = response.data.data
      }).catch(function (error) {
        console.log('error: ', error)
      })
    }

    $scope.purchaseOrRepairOrder = function (order) {
      if (order.type === 'PURCHASE_ORDER') {
        $state.go('retailer.purchase-order', { orderNo: order.number })
      } else if (order.type === 'REPAIR') {
        $state.go('retailer.service-item', { transactionId: order._id })
      }
    }

    $scope.EditCommentMode = function (remark, index) {
      for (var i = 0; i < $scope.shopcompanyremarks.remarks.length; i++) {
        $scope.shopcompanyremarks.remarks[i].isEditMode = false
      }
      $scope.shopcompanyremarks.remarks[index].isEditMode = true
      $scope.editRemarkDetail = remark
    }

    $scope.UpdateComment = function (comment, index) {
      if (comment) {
        $scope.editRemarkDetail.remark = comment
        $scope.shopcompanyremarks.remarks[index].isEditMode = false
        console.log('$scope.editRemarkDetail: ', $scope.editRemarkDetail)
        var body = {
          shopcompanyRemarkId: $scope.shopcompanyremarks._id,
          remarkId: $scope.shopcompanyremarks.remarks[index]._id,
          remark: comment
        }
        console.log('body: ', body)
        $brand.editCommentofCompanyOrShop(body).then(function (response) {
          console.log('response: ', response)
          $scope.currentremark = ''
          $('#currentremark').val('')
          // $scope.getComment();
        }).catch(function (error) {
          $scope.currentremark = ''
          $('#currentremark').val('')
          console.log(error)
        })
      } else {
        $scope.editRemarkDetail = ''
        $scope.shopcompanyremarks.remarks[index].isEditMode = false
      }
    }

    $scope.submitBankForm = function (index) {
      console.log('submitBankForm', index, $scope.editBank)
      if ($scope.editBank) {
        $scope.updateBank(index)
      } else {
        $scope.pushBank()
      }
    }

    $scope.checkIBAN = function (form, bank) {
      console.log('checkIBAN', form, bank)
      var valid = IBAN.isValid(bank)
      $scope.invalidIBAN = !valid
      form.bankAccountNumber.$setValidity('bankAccountNumber', valid)
    }

    // Whenever adding comment to the then we have add new line when pressed (SHIFT + ENTER) and if only enter then add Comment
    $scope.addCommentKeyUp = function (event, currentremark) {
      if (event.keyCode === 13) {
        if (event.shiftKey) return false
        else $scope.AddComment(currentremark)
      }
    }

    /**
        * ShopId to get Client group
        * Its for prefilling discount of client group
        */
    function getClientGroup(shop_id, companyNameSlug) {
      $clientGroup.getShopClientGroupsByCompanyNameSlug(shop_id, companyNameSlug)
        .then(function (response) {
          console.log('get shop client groups', response)
          if (response && response.data) {
            $scope.shopsClientGroups = response.data
          }
        })
        .catch(function (error) {
          console.error('Error in getting shop blocking client groups', error)
        })
    }

    $scope.getShopDetailsForBrandPortal = function () {
      $scope.stocklistLoading = true;
      console.log('------------------------ getShopDetailsForBrandPortal!!');
      console.log($scope.stocklistLoading);
      console.log($scope.stocklist.length);
      //$scope.loading = true;
      if ($scope.company && $scope.company._id && $rootScope.currentShop && $rootScope.currentShop._id) {
        var body = {
          shopId: $rootScope.currentShop._id,
          companyId: $scope.company._id
        }
        $brand.getShopDetailsForBrandPortal(body).then(function (response) {
          $scope.stocklistLoading = false;
          $scope.stocklist = []
          $scope.availability = [];
          $scope.lastSoldItems = [];
          if (response && response.data && response.data.data) {
            var shopDetails = response.data.data
            console.log('getShopDetailsForBrandPortal response: ', shopDetails)

            // SHOP DETAIL
            if (shopDetails.shop) {
              $scope.shop = shopDetails.shop;
            }

            // STOCK LIST
            if (shopDetails.stocklist && shopDetails.stocklist.length) {
              $scope.stocklist = shopDetails.stocklist.shopres
            }
            if (!shopDetails.stocklist.length || shopDetails.stocklist.length == 0){
              $scope.stocklistLoading = false;
            }

            // LAST SOLD ITEMS
            if (shopDetails.lastSoldItems && shopDetails.lastSoldItems.trnres && shopDetails.lastSoldItems.trnres.length) {
              var tempList = [];
              $scope.filterDataLength = 0
              shopDetails.lastSoldItems.trnres.forEach(function (item) {
                var date = new Date(item.dateCreated);
                var year = date.getFullYear();
                var month = date.getMonth();
                if(tempList.length == 0){
                  tempList.push(
                    {
                      'year' : year,
                      'month' : month,
                      'data' : [item],
                      'total' : item.productDetail.priceIncVat,
                      'open' : true
                    }
                  );
                }else{
                  var index = tempList.findIndex(function(obj){ return (obj.year === year && obj.month === month)});
                  if(index != -1){
                    tempList[index].data.push(item);
                    tempList[index].total += item.productDetail.priceIncVat;
                  }else {
                    tempList.push(
                      {
                        'year' : year,
                        'month' : month,
                        'data' : [item],
                        'total' : item.productDetail.priceIncVat,
                        'open' : false
                      }
                    );
                  }
                }
              })
              tempList.forEach(function (item) {
                item.data.sort(function(a,b){
                  return new Date(b.dateCreated) - new Date(a.dateCreated);
                });
              });
              $scope.lastSoldItems = tempList;
            }

            // AVAILABILITY
            if (shopDetails.availability && shopDetails.availability.length) {
              $scope.availability = shopDetails.availability;
              isEmployeeAvailableAnyTime();
            }
          }
        }).catch(function (error) {
          console.log('error: ', error)
        })
      }
    }

    $scope.getBestSoldItems = function (shop_id) {
      console.log('------------- getBestSoldItems!!');
      var endDate = $scope.filter.endDate; 
      var startDate = $scope.filter.startDate;

      if(!endDate) { endDate = new Date(new Date().setHours(23, 59, 59)); } 
      if(!startDate) { 
        var date = new Date();
        var value = date.getFullYear();
        var startDate = moment().year(value).startOf('year').toDate()
      }
      console.log($scope.filter);
      var filters = {
        suppliers: [],
        // language: $rootScope.language,
        // collectionWiseSwitch: $scope.collectionWiseSwitch,
        endDate: endDate ? endDate : '',
        startDate: startDate ? startDate : '',
      }
      console.log(filters);
      filters.suppliers.push($scope.company._id)
      console.log('$scope.company._id ', $scope.company._id);

      $scope.loading = true;
      return $api.get('v2/shop/sold-productsnew/' + $rootScope.currentShop._id,
        {
          filters: filters,
          // companyId: $rootScope.currentCompany._id,
          nameSlug: $scope.nameSlug
        }
      )
        .then(function (response) {
          $scope.loading = false;
          if (response && response.data && response.data.result && response.data.result.soldProductsBasedOnVariantNumber) {
            $scope.bestSellingProducts = response.data.result.soldProductsBasedOnVariantNumber
            console.log('$scope.bestSellingProducts ', $scope.bestSellingProducts.length);
          }
        })
        .catch(function (error) {
          $scope.loading = false;
          console.log('error: ', error)
        })
    }

    $scope.showSupplierSubTab = function(event, index) {
      if(event.$parent && event.$parent.tabset && event.$parent.tabset.active && event.$parent.tabset.active == index){
        return true;
      }
      return false;
    }

    function isEmployeeAvailableAnyTime() {
      for (var i = 0; i < $scope.availability.length; i++) {
        $scope.availability[i].isEmployeeAvailableAnyTime = false;
        var userAvailability = $scope.availability[i].availability;
        if (userAvailability && userAvailability.length) {
          for (var j = 0; j < userAvailability.length; j++) {
            if (userAvailability[j].morning || userAvailability[j].afternoon) {
              $scope.availability[i].isEmployeeAvailableAnyTime = true;
              break;
            }
          }
        }
      }
    }

    $scope.recommendationOrderPage = function () {
      console.log('recommendationOrderPage called: ', $scope.company);
      $rootScope.recommendationCompany = $scope.company;
      // $rootScope.recommendationCompanyId = $scope.selectedCompany._id;
      console.log('recommendationOrderPage called: ',$scope.company);
      $state.go('retailer.recommendation-order', { orderNo: '' });
    }

    $scope.autoScrollcomment = function () {
      setTimeout(function () {
        $('#messagewindow').animate({
          scrollTop: $('#messagewindow')[0].scrollHeight
        }, 0)
      }, 200)
    }
  }])

prismanoteApp.directive('contenteditable', ['$sce', function ($sce) {
  return {
    restrict: 'A', // only activate on element attribute
    require: '?ngModel', // get a hold of NgModelController
    link: function (scope, element, attrs, ngModel) {
      if (!ngModel) return // do nothing if no ng-model

      // Specify how UI should be updated
      ngModel.$render = function () {
        element.html($sce.getTrustedHtml(ngModel.$viewValue || ''))
        read() // initialize
      }

      // Listen for change events to enable binding
      element.on('blur keyup change', function () {
        scope.$evalAsync(read)
      })

      // Write data to the model
      function read() {
        var html = element.html()
        // When we clear the content editable the browser leaves a <br> behind
        // If strip-br attribute is provided then we strip this out
        if (attrs.stripBr && html == '<br>') {
          html = ''
        }
        ngModel.$setViewValue(html)
      }
    }
  }
}])
