prismanoteApp.controller('changeSpecialOrderProductModalController', ['$scope', '$uibModalInstance', '$rootScope', '$api', '$stateParams', '$uibModal', '$state', 'prompt', 'Upload', '$shop', '$log',
  function ($scope, $uibModalInstance, $rootScope, $api, $stateParams, $uibModal, $state, prompt, Upload, $shop, $log) {

    $scope.save = function () {
      if($scope.allowSave) {
        $uibModalInstance.close($scope.selectedProduct)
      }
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss()
    }

    $scope.nameChange = function (val) {
      if(val.length === 0) {
        $scope.allowSave = false
      } else {
        $scope.allowSave = true
      }
    }

    $scope.searchProducts = function (searchText) {
      $scope.loading = true
      $shop.getShopProducts(searchText, 'stock')
        .then(function (result) {
          $scope.loading = false
          $scope.products = result
        })
        .catch(function (reason) {
          $scope.loading = false
          $log.error('Error when searching products', reason)
        })
    }

    $scope.makeProductPrice = function (price) {
      return Math.round((price + Number.EPSILON) * 100) / 100
    }

    $scope.clearSearchResult = function () {
      $scope.products = []
      $scope.shopProduct = null
      $scope.allowSave = false
    }

    $scope.chooseProduct = function (product) {
      $shop.getProductForCashRegistry($rootScope.currentShop._id, product._id)
        .then(function(product) {
          $scope.selectedProduct = {
            name: product[$rootScope.language].name,
            price: $scope.makeProductPrice(product.price * (1 + ( product.priceVat / 100 ))),
            productId: product._id
          }
          if(product.purchaseDetails && product.purchaseDetails.supplierDetails && product.purchaseDetails.supplierDetails._id) {
            $scope.selectedProduct.supplier = product.purchaseDetails.supplierDetails._id
          }
          if(product.variants && product.variants.length > 0 && product.variants[0].productNumber) {
            $scope.selectedProduct.productNumberSupplier = product.variants[0].productNumber
          }
          $scope.allowSave = true
          $scope.products = []
        })
        .catch(function (reason) {
          $log.error('Error while fetching product details', reason)
        })
    }
  }])
