prismanoteApp.controller('unsubscribeBrandController', ['$scope', '$rootScope', '$uibModal', '$stateParams', '$api', '$http', '$state',
  function ($scope, $rootScope, $uibModal, $stateParams, $api, $http, $state) {
    $scope.brand = $stateParams.brand

    $scope.fetchDetail = function () {
      $api.get('get-brand-blacklist-detail', {
        code: $stateParams.code
      })
        .then(function (response) {
          $scope.brandName = response.data.name
        })
        .catch(function (reason) {
          $scope.alert = {
            type: 'danger',
            msg: reason
          }
        })
    }

    $scope.unsubscribe = function () {
      $api.post('add-brand-to-blacklist', {
        code: $stateParams.code
      })
        .then(function (response) {
          $scope.alert = {
            type: 'success',
            msg: response.data.message
          }
        })
        .catch(function (reason) {
          console.log(reason)
          $scope.alert = {
            type: 'danger',
            msg: reason
          }
        })
    }
  }])
