prismanoteApp.controller('retailerSpecialController', ['$scope', '$q', '$rootScope', '$api', '$stateParams', '$uibModal', '$state', '$printer', '$pdf', '$language', '$retailer', 'prompt', 'Lightbox', '$transaction', 'dialogs', '$filter', '$customer', '$employee', '$shop', '$thermalPrint',
  function ($scope, $q, $rootScope, $api, $stateParams, $uibModal, $state, $printer, $pdf, $language, $retailer, prompt, Lightbox, $transaction, dialogs, $filter, $customer, $employee, $shop, $thermalPrint) {
    $scope.currentPage = 1
    $scope.intakeDateChanged = false
    $scope.repairDateChanged = false
    $scope.pageSize = 5
    $scope.maxSize = 10

    if ($rootScope.tablet || $rootScope.phone) {
      $scope.pageSize = 3
      $scope.maxSize = 9
    }

    $scope.loading = true

    $scope.brands = []
    $scope.openImage = false
    $scope.searchMode = 'exact'
    $scope.offerItem = {}
    $scope.editItem = false
    $scope.offerIndex = null
    $scope.outstanding = 0
    $scope.disableRefund = false

    $scope.intakeDateOptions = {
      maxDate: new Date()
    }
    $scope.endDateOptions = {
      minDate: new Date()
    }

    $scope.customerName = function(customer) {
      return $customer.makeCustomerName(customer)
    }

    $scope.getTransaction = function () {
      $transaction.getTransaction($stateParams.transactionId, true, false, $rootScope.currentShop._id)
        .then(function (transaction) {
          if (transaction.length && transaction.length > 0) {
            $scope.transaction = transaction[0]
          } else {
            $scope.transaction = transaction
          }
          if ($scope.transaction) {
            $scope.originalTransaction = JSON.parse(JSON.stringify($scope.transaction))
          }

          $printer.getPageDefinition('REPAIR/SPECIAL')
            .then(function(doc) {
              $scope.specialDoc = doc
            })
            .catch(function (reason) {
              console.error('Error while fetching repair page definition for printing', reason)
            })

          $scope.intakeDateChanged = false
          $scope.repairDateChanged = false

          $rootScope.transactionType = $scope.transaction.type
          // Check the ID of this transaction with the stateParams,
          // if they are not the same, look into the transaction to get the right transaction and modify the object
          if ($scope.transaction._id === $stateParams.transactionId) {
            loadTransaction()
          } else {
            // Loop through the transaction.transactions to get the right transaction and to rebuild the object
            var originalTransaction = angular.copy($scope.transaction)
            for (var i = 0; i < originalTransaction.transactions.length; i++) {
              if (originalTransaction.transactions[i]._id === $stateParams.transactionId) {
                // Found the right transaction
                var newTransaction = angular.copy(originalTransaction.transactions[i])
                delete originalTransaction.transactions[i]
                newTransaction.transactions = new Array(originalTransaction)
                $scope.transaction = newTransaction
                return loadTransaction()
              }
            }
          }

          if ($scope.transaction) {
            $scope.originalTransaction = JSON.parse(JSON.stringify($scope.transaction))
          }

          function loadTransaction () {
            $scope.receiptNumbers = []
            $scope.transaction.dateCreated = new Date($scope.transaction.dateCreated)
            if ($scope.transaction[$scope.transaction.type] && $scope.transaction[$scope.transaction.type].estimatedDate) {
              $scope.transaction[$scope.transaction.type].estimatedDate = new Date($scope.transaction[$scope.transaction.type].estimatedDate)
            }

            if ($scope.transaction.status === 'processing') {
              if ($scope.transaction.repair.repairerType === 'in-house') {
                if (!$scope.transaction.repair.repairer) {
                  $scope.isRepairMySelf = true
                }
              }
            }

            if ($scope.transaction.transactions) {
              $scope.payments = []
              $scope.prepaid = 0
              for (var a = 0; a < $scope.transaction.transactions.length; a++) {
                $scope.receiptNumbers.push({
                  number: $scope.transaction.transactions[a].number,
                  _id: $scope.transaction.transactions[a]._id,
                  date: $scope.transaction.transactions[a].dateCreated
                })

                for (b = 0; b < $scope.transaction.transactions[a].receipt.payments.length; b++) {
                  var payment = $scope.transaction.transactions[a].receipt.payments[b]
                  $scope.payments.push({
                    amount: payment.amount,
                    method: payment.method,
                    date: payment.date,
                    deposit: payment.deposit,
                    change: payment.change
                  })
                  if (payment.deposit) {
                    if (!payment.change) {
                      payment.change = 0
                    }
                    $scope.prepaid += (payment.amount - payment.change)
                  }
                }
              }
            }

            $scope.statusBackup = angular.copy($scope.transaction.status)
            $scope.serviceStatus = $transaction.getStatus($scope.transaction.status)
            $scope.getOutstandingAmount()
          }
          $scope.loading = false
        })
        .catch(function (reason) {
          console.error('Error while fetching special transaction', reason)
          if (typeof reason === 'object') {
            $scope.alert = {
              type: 'danger',
              msg: $language.translate('UNKOWN_ERROR_OCCURED')
            }
            return
          }
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(reason)
          }
          $scope.loading = false
        })
    }

    $scope.prefillActualCosts = function () {
      if (!$scope.transaction[$scope.transaction.type].actualCosts) {
        $scope.transaction[$scope.transaction.type].actualCosts = $scope.transaction[$scope.transaction.type].estimatedPrice
      }
    }

    $scope.removeBrand = function () {
      $scope.transaction.brand = null
    }

    $scope.removeSupplier = function () {
      $scope.transaction.supplier = null
    }

    $scope.removeRepairer = function () {
      $scope.transaction.repair.repairer = null
      $scope.transaction.isRepairerChanged = false
    }

    $scope.removeAssitedBy = function () {
      $scope.transaction.createdBy.name = null
      $scope.isRemovedAssitedBy = true
    }

    $scope.changeStatus = function (status) {
      $scope.serviceStatus = $transaction.getStatus(status)
      function updateStatus () {
        if (status === 'completed') {
          if (!$scope.transaction[$scope.transaction.type].actualFinishDate) {
            $scope.transaction[$scope.transaction.type].actualFinishDate = new Date()
          }
          if (!$scope.transaction[$scope.transaction.type].actualCosts) {
            $scope.transaction[$scope.transaction.type].actualCosts = $scope.transaction[$scope.transaction.type].estimatedPrice
          }
        }

        if (status === 'processing') {
          $scope.transaction.isCompanyRepliedAfterProcessingMode = false
        }

        $scope.transaction.status = status

        $scope.transaction.log.push({
          date: new Date(),
          user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
          text: $language.translate('CHANGED_STATUS_FROM_TO', { oldStatus: $language.translate($scope.statusBackup.toUpperCase()), newStatus: $language.translate(status.toUpperCase()) }),
          isFor: 'retailerPortal',
          eType: 'retailer'
        })

        if (status === 'inspection') {
          $scope.transaction.log.push({
            date: new Date(),
            user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
            text: 'Notification that order is arrived',
            isFor: 'supplierPortal',
            eType: 'retailer'
          })
        }
        $scope.statusBackup = angular.copy(status)

        $scope.updateTransactionStatus()
      }

      if (status === 'delivered' && ($scope.transaction.transactionTotal && $scope.transaction.transactionTotal.toFixed(2) !== $scope.transaction.totalEarning.toFixed(2))) {
        prompt({
          title: $language.translate('ARE_YOU_SURE'),
          message: $language.translate('YOU_CAN_NOT_MARK_AS_COMPLETE_BECAUSE_NOT_PAID_FULL_AMOUNT_BY_CUSTOMER'),
          buttons: [
            {
              label: $language.translate('YES_IM_SURE'),
              danger: true
            }
          ]
        })

        $scope.transaction.status = $scope.statusBackup
        $scope.serviceStatus = $transaction.getStatus($scope.transaction.status)
      } else if (status === 'cancelled') {
        prompt({
          title: $language.translate('ALERT'),
          message: $language.translate('YOU_CAN_NOT_CHANGE_ANYTHING_IN_THIS_TRANSACTION_ONCE_MARK_AS_CANCELLED_OR_DELIVERED')
        })
          .then(function (data) {
            if (data && data.cancel) {
              $scope.transaction.status = $scope.statusBackup
              $scope.serviceStatus = $transaction.getStatus($scope.transaction.status)
            } else {
              $scope.checkParentTransaction()
                .then(function() {
                  $scope.transaction[$scope.transaction.type].actualCosts = 0
                  updateStatus()
                })
                .catch(function(reason){
                  console.error("Error when removing payLater from parent", reason)
                  toastr.error(reason)
                })
            }
          })
      } else if (status === 'delivered') {
        prompt({
          title: $language.translate('ARE_YOU_SURE'),
          message: $language.translate('YOU_CAN_NOT_CHANGE_ANYTHING_IN_THIS_TRANSACTION_ONCE_MARK_AS_CANCELLED_OR_DELIVERED'),
          buttons: [
            {
              label: $language.translate('YES_IM_SURE'),
              danger: true
            },{
              label: $language.translate('CANCEL'),
              cancel:true
            }
          ]
        })
          .then(function (data) {
            if (data && data.cancel) {
              $scope.transaction.status = $scope.statusBackup
              $scope.serviceStatus = $transaction.getStatus($scope.transaction.status)
            } else {
              updateStatus()
            }
          })
      } else {
        updateStatus()
      }
    }

    $scope.checkParentTransaction = function () {
      return $q(function (resolve, reject) {
        if($scope.transaction.transactions && $scope.transaction.transactions.length > 0) {
          if($scope.transaction.transactions[0].type === 'shop-purchase' && $scope.transaction.transactions[0].receipt &&
            $scope.transaction.transactions[0].receipt.payments && $scope.transaction.transactions[0].receipt.payments.length > 0) {
            var payLaterIdx = _.findIndex($scope.transaction.transactions[0].receipt.payments, {method: 'payLater'})
            if(payLaterIdx >= 0) {
              $transaction.removePayLaterFromTransaction($scope.transaction.transactions[0]._id, 'true')
                .then(function(result) {
                  return resolve()
                })
                .catch(function(reason) {
                  return reject(reason)
                })
            } else {
              return resolve()
            }
          } else {
            return resolve()
          }
        } else {
          return resolve()
        }
      })
    }

    $scope.updateTransactionStatus = function () {
      var data = {
        shopId: $rootScope.currentShop._id,
        user: ($rootScope.currentEmployee ? $rootScope.currentEmployee : null)
      }
      $transaction.updateTransactionStatus($scope.transaction._id, $scope.transaction.status, data)
        .then(function () {
          $scope.loading = false
          $scope.getTransaction()
        })
        .catch(function (reason) {
          $scope.loading = false
          dialogs.error($language.translate('ERROR'), $language.translate('AN_ERROR_OCCURED') + ': ' + reason, { size: 'sm' })
        })
    }

    $scope.changedDate = function (type) {
      if (type === 'intakeDate') {
        $scope.intakeDate = !$scope.intakeDate
        $scope.intakeDateChanged = true
      }
      if (type === 'repairDate') {
        $scope.repairDate = !$scope.repairDate
        $scope.intakeDateChanged = true
      }
    }

    $scope.openCustomer = function () {
      $state.go('retailer.customer', {
        id: $scope.transaction.customer._id
      })
    }

    $scope.openEmailCompanyEmail = function () {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/email-company-modal.html',
        controller: 'emailCompanyController',
        keyboard: false,
        backdrop: 'static',
        size: 'lg',
        resolve: {
          transaction: function () {
            return $scope.transaction
          }
        }
      })
      modalInstance.result.then(function (result) {
        $scope.transaction = result

        $scope.updateTransaction()
      }, function () {

      })
    }

    $scope.updateParentTransactionForSupplier = function (productDetails) {
      return $q(function (resolve, reject) {
        if($scope.transaction.transactions && $scope.transaction.transactions.length > 0) {
          //Find the right transaction for this orderdetail
          var detailIdx = null
          var transaction = _.find($scope.transaction.transactions, function (t) {
            for(var i = 0; i < t.details.length; i++) {
              var check = t.details[i].transaction === $scope.transaction._id
              if(check) {
                detailIdx = i
              }
              return check
            }
          })

          if(transaction && transaction.details[detailIdx] && detailIdx >= 0) {
            var detail = transaction.details[detailIdx]
            if(productDetails.productId) {
              detail.productId = productDetails.productId
            } else {
              detail.productId = null
            }
            if(productDetails.supplier) {
              detail.supplier = productDetails.supplier
            } else {
              detail.supplier = null
            }
            if(productDetails.productNumberSupplier) {
              detail.productNumberSupplier = productDetails.productNumberSupplier
            } else {
              detail.productNumberSupplier = null
            }

            transaction.details[detailIdx] = detail
            transaction.shopId = $rootScope.currentShop._id

            $transaction.updateTransaction(transaction, null, true)
              .then(function () {
                return resolve()
              })
              .catch(function (reason) {
                dialogs.error($language.translate('ERROR'), $language.translate('AN_ERROR_OCCURED') + ': ' + reason, { size: 'sm' })
                reject()
              })
          } else {
            return resolve()
          }
        } else {
          return resolve()
        }
      })
    }

    $scope.updateTransaction = function () {
      if ((!$scope.transaction.createdBy || !$scope.transaction.createdBy.name) && $scope.isRemovedAssitedBy) {
        return
      }
      $scope.loading = true
      if (!$scope.transaction.log) {
        $scope.transaction.log = []
      }
      if ($scope.intakeDateChanged) {
        var log = {
          date: new Date(),
          user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
          text: 'Intake date changed',
          isFor: 'retailerPortal',
          eType: 'retailer'
        }
        $scope.transaction.log.push(log)
      }

      if ($scope.repairDateChanged) {
        var log = {
          date: new Date(),
          user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
          text: 'Repair date changed',
          isFor: 'retailerPortal',
          eType: 'retailer'
        }
        $scope.transaction.log.push(log)
      }

      $scope.transaction.shopId = { _id: $scope.transaction.shopId._id }

      $transaction.updateTransaction($scope.transaction, null, true)
        .then(function (result) {
          $scope.loading = false
          $scope.getTransaction()
        })
        .catch(function (reason) {
          $scope.loading = false
          dialogs.error($language.translate('ERROR'), $language.translate('AN_ERROR_OCCURED') + ': ' + reason, { size: 'sm' })
        })
    }

    $scope.checkEmailSend = function(type) {
      return $q(function(resolve, reject){
        if($scope.transaction[$scope.transaction.type].email && $scope.transaction[$scope.transaction.type].email[type] &&
          $scope.transaction[$scope.transaction.type].email[type].status && $scope.transaction[$scope.transaction.type].email[type].status.send) {
          var dateSend = moment(new Date($scope.transaction[$scope.transaction.type].email[type].status.dateSend)).format('DD-MM-YYYY HH:mm')
          var dlg = dialogs.confirm($language.translate('EMAIL_ALREADY_SEND'), $language.translate('YOU_ALREADY_SEND_AN_MAIL_AT_BELOW_DATE_SEND_AGAIN') + ' ' + dateSend, { size: 'sm' })
          dlg.result.then(function (btn) {
            return resolve()
          }, function() {
            return reject()
          })
        } else {
          return resolve()
        }
      })
    }

    $scope.saveRepairSentStatus = function (again) {
      if(!$scope.transaction[$scope.transaction.type].email){
        $scope.transaction[$scope.transaction.type].email = { customer: { } }
      }

      if(!$scope.transaction[$scope.transaction.type].email.hasOwnProperty('customer')) {
        $scope.transaction[$scope.transaction.type].email = { customer: { } }
      }

      if($scope.transaction[$scope.transaction.type].email && $scope.transaction[$scope.transaction.type].email.hasOwnProperty('customer')){
        $scope.transaction[$scope.transaction.type].email = { customer: { status : {} } }

        $scope.transaction[$scope.transaction.type].email.customer.status = {
          send: true,
          dateSend: new Date()
        }
        $scope.updateTransaction()

        $scope.statusAlert = {
          type: 'success',
          msg: $language.translate('MAIL_SEND')
        }
      }
    }

    $scope.sendRepairCompletedMail = function (again) {
      $scope.statusAlert = null
      $scope.mailSending = true
      if(again !== 'resent'){
        $scope.checkEmailSend('customer')
        .then(function(){
          $api.post('transactions/send-completed-email', {
            transaction: $scope.transaction,
            shopId: $rootScope.currentShop._id,
          }).then(function (res) {
            $scope.saveRepairSentStatus()
          }).catch(function (reason) {
            dialogs.error($language.translate('ERROR'), reason, { size: 'sm' })
            console.error(reason)
            $scope.mailSending = false
          })
        })
        .catch(function(){
          var dlg = dialogs.warning($language.translate('EMAIL_WAS_ALREADY_SENT'), reason, { size: 'sm' })
          dlg.result.then(function (btn) {
            $scope.sendRepairCompletedMail('resent')
          }, function (btn) {
            // dialog modal couldn't be opened
          })
          console.error(reason)
        })
      }else{
        $api.post('transactions/send-completed-email', {
          transaction: $scope.transaction,
          shop: {
            _id: $rootScope.currentShop._id,
            name: $rootScope.currentShop.name,
            nameSlug: $rootScope.currentShop.nameSlug,
            identity: $rootScope.currentShop.identity
          },
        }).then(function (res) {
          $scope.saveRepairSentStatus()
        }).catch(function (reason) {
          dialogs.error($language.translate('ERROR'), reason, { size: 'sm' })
          console.error(reason)
        })
      }
    }

    $scope.priceChanged = function () {
      $scope.transaction.transactionTotal = $scope.transaction[$scope.transaction.type].actualCosts
      $scope.transaction.details[0].total = $scope.transaction.transactionTotal
      var singleProductPriceWithVat = $scope.transaction.details[0].total / $scope.transaction.details[0].quantity
      $scope.transaction.details[0].price = singleProductPriceWithVat / (1 + ($scope.transaction.details[0].priceVat / 100))

      $scope.transaction.details[0].purchasePrice = $scope.transaction[$scope.transaction.type].externalPrice

      $scope.updateTransaction()
    }

    $scope.back = function () {
      window.history.back()
    }

    $scope.getBrands = function () {
      $api.get('brands')
        .then(function (response) {
          $scope.brands = response.data.brands
          $scope.brands.forEach(function (brand) {
            brand.name = brand[$rootScope.language].name
          })
        })
        .catch(function (reason) {
          console.error('Error while getting brands', reason)
        })
    }

    $scope.closeAlert = function (alert) {
      $scope[alert] = null
    }

    $scope.getCompanies = function () {
      $scope.companies = []
      $shop.getOwnShopCompanies($rootScope.currentShop._id, true)
        .then(function (companies) {
          $scope.companies = companies
        })
        .catch(function (error) {
          console.error('Error! While getting Shop Companies')
          console.error(error)
        })
    }

    $scope.getShopBrands = function () {
      $scope.shopBrands = []
      $shop.getBrandsByShop($rootScope.currentShop.nameSlug)
        .then(function (brands) {
          $scope.shopBrands = brands.brands
        })
        .catch(function (error) {
          console.error('Error! While getting Shop Brands')
          console.error(error)
        })
    }

    $scope.selectedCompany = function (selected) {
      if (selected) {
        if (selected.originalObject) {
          selected = selected.originalObject
        }

        $scope.transaction.supplier = {
          name: selected.name,
          _id: selected._id
        }
      }
      if ($scope.transaction.type == 'repair') $scope.updateTransaction()
    }

    $scope.selectedBrand = function (selected) {
      if (selected) {
        if (selected.originalObject) {
          selected = selected.originalObject
        }

        $scope.transaction.brand = {
          name: selected[$rootScope.language].name,
          _id: selected._id
        }
      }
      if (!$scope.shopBrands || $scope.shopBrands.length == 0) {
        return
      }

      var shopBrandsLength = $scope.shopBrands.length
      for (var i = 0; i < shopBrandsLength; i++) {
        var brand = $scope.shopBrands[i]

        if (brand._id == $scope.transaction.brand._id) {
          $scope.selectedRepairer(brand.favoriteRepairer._id)
          $scope.selectedCompany(brand.favoriteSupplier._id)
        }
      }
      if ($scope.transaction.type == 'repair') $scope.updateTransaction()
    }

    $scope.selectedRepairer = function (selected) {
      if ($scope.transaction.status === 'new') {
        $scope.transaction.status = 'processing'
        $scope.transaction.repair.repairerType = 'external'
      }

      if (selected) {
        if (selected.originalObject) {
          selected = selected.originalObject
        }

        $scope.transaction.repair.repairer = {
          name: selected.name,
          _id: selected._id
        }
      }

      // For sending the email notification to the company
      if ($scope.transaction.type && $scope.transaction.type === 'repair') {
        if ($scope.originalTransaction && $scope.originalTransaction.repair && $scope.originalTransaction.repair.repairer && $scope.originalTransaction.repair.repairer._id) {
          if ($scope.transaction.repair && $scope.transaction.repair.repairer && $scope.transaction.repair.repairer._id) {
            if (($scope.transaction.repair.repairer._id).toString() !== ($scope.originalTransaction.repair.repairer._id).toString()) {
              $scope.transaction.isRepairerChanged = true
            } else {
              $scope.transaction.isRepairerChanged = false
            }
          }
        } else {
          $scope.transaction.isRepairerChanged = true
        }
      }
      if ($scope.transaction.type == 'repair') $scope.updateTransaction()
    }

    $scope.openChangeCustomerModal = function () {
      $customer.openSearchCustomerModal(false, false)
        .then(function (result) {
          var user = $rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id
          $scope.transaction.customer = result

          $transaction.updateTransactionCustomer($scope.transaction._id, result._id, user, $scope.transaction.paymentTermDays)
        })
        .catch(function (reason) {
          console.error('REASON', reason)
        })
    }

    $scope.openSupplier = function () {
      $state.go('retailer.supplier', { nameSlug: $scope.transaction.supplier.nameSlug })
    }

    $scope.openRepairer = function () {
      $state.go('retailer.supplier', { nameSlug: $scope.transaction.repair.repairer.nameSlug })
    }

    $scope.deposit = function (percent) {
      if (localStorage.getItem('tillMode')) {
        if(localStorage.getItem('tillMode') === 'current') {
          $scope.checkout(false, percent)
        } else {
          $state.go('retailer.till', {
            customer: $scope.transaction.customer,
            items: $scope.transaction.items,
            transactionId: $scope.transaction._id,
            transactionNumber: $scope.transaction.number,
            refund: false
          })
        }
        return
      }
      var chooseTillModal = $uibModal.open({
        templateUrl: '../views/modal/choose-till-modal.html',
        controller: 'chooseTillModalController',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
      })

      chooseTillModal.result.then(function (result) {
        if(result) {
          $state.go('retailer.till', {
            customer: $scope.transaction.customer,
            items: $scope.transaction.items,
            transactionId: $scope.transaction._id,
            transactionNumber: $scope.transaction.number,
            refund: false
          })
        } else {
          $scope.checkout(false, percent)
        }
      }, function () {
      })
    }

    $scope.changeProduct = function () {
      var changeProductModal = $uibModal.open({
        templateUrl: '../views/modal/change-specialorder-product-modal.html',
        controller: 'changeSpecialOrderProductModalController',
        size: 'lg',
        keyboard: false,
        backdrop: 'static',
        resolve: {

        }
      })
      changeProductModal.result.then(function (result) {
        $scope.transaction.details[0].name = result.name
        $scope.transaction.details[0].total = result.total
        $scope.transaction.details[0].price = result.price
        if(result.productId) {
          $scope.transaction.details[0].productId = result.productId
        } else {
          $scope.transaction.details[0].productId = null
        }
        if(result.supplier) {
          $scope.transaction.details[0].supplier = result.supplier
        } else {
          $scope.transaction.details[0].supplier = null
        }
        if(result.productNumberSupplier) {
          $scope.transaction.details[0].productNumberSupplier = result.productNumberSupplier
        } else {
          $scope.transaction.details[0].productNumberSupplier = null
        }
        $scope.updateParentTransactionForSupplier(result)
          .then(function () {
            $scope.updateTransaction()
          })
          .catch(function () {

          })
      }, function (err) {
        //dismissed
      })
    }

    $scope.askCheckout = function(refund, deposit, finalCheckout) {
      if($scope.transaction.transactions && $scope.transaction.transactions.length > 0) {
        var total = $scope.transaction.transactionTotal - $scope.transaction.totalEarning

        $state.go('retailer.till', {
          customer: $scope.transaction.customer,
          items: [
            {
              repairPayment: false,
              specialPayment: true,
              restPayment: true,
              type: 'order',
              transaction: $scope.transaction._id,
              extraTransaction: $scope.transaction.transactions[0]._id,
              quantity: 1,
              price: total,
              total: total,
              priceVat: $scope.transaction.details[0].priceVat,
              name: $language.translate('REST_PAYMENT') + ' ' + $scope.transaction.details[0].name,
              productId: $scope.transaction.details[0].productId ? $scope.transaction.details[0].productId : null,
              productNumber: $scope.transaction.details[0].productNumber ? $scope.transaction.details[0].productNumber : null,
              parentTransactionDetailId: $scope.transaction.transactions[0].details[0]._id,
              comment: $scope.transaction.details[0].comment,
              productCollection: $scope.transaction.details[0].productCollection ? $scope.transaction.details[0].productCollection : null
            }
          ],
          transactionId: $scope.transaction.transactions[0]._id,
          transactionNumber: $scope.transaction.number,
          refund: false,
          checkout: true,
          payLater: true
        })
      } else {
        var productNumber = $scope.transaction.details[0].productId && $scope.transaction.details[0].productId.variants && $scope.transaction.details[0].productId.variants.length > 0 && $scope.transaction.details[0].productId.variants[0].productNumber ? $scope.transaction.details[0].productId.variants[0].productNumber : null
        
        var generatedComment = []

        generatedComment.push(($scope.transaction.totalEarning !== 0) ? $language.translate('DEPOSIT') : $language.translate('PAYMENT'))
        generatedComment.push($language.translate($scope.transaction.type.toUpperCase()))
        generatedComment.push(String($scope.transaction.number))

        if(productNumber) {
          generatedComment.push(String($language.translate('PRODUCT') + ': ' + productNumber))
        }

        generatedComment.push(String('\n'+$language.translate('TOTAL_AMOUNT_TO_PAY') + ': \u20AC' + ($scope.transaction.details[0].price * (1 + ($scope.transaction.details[0].priceVat / 100)))))

        $state.go('retailer.till', {
          customer: $scope.transaction.customer,
          items: [{
            type: 'order',
            specialPayment: true,
            transaction: $scope.transaction._id,
            quantity: $scope.transaction.details[0].quantity,
            price: $scope.transaction.transactionTotal - $scope.transaction.totalEarning,
            total: $scope.transaction.transactionTotal - $scope.transaction.totalEarning,
            priceVat: $scope.transaction.details[0].priceVat,
            name: $scope.transaction.details[0].name,
            comment: generatedComment.join(" "),
            productId: $scope.transaction.details[0].productId ? $scope.transaction.details[0].productId : null,
            productNumber: $scope.transaction.details[0].productNumber ? $scope.transaction.details[0].productNumber : null,
            productCollection: $scope.transaction.details[0].productCollection && $scope.transaction.details[0].productId ? $scope.transaction.details[0].productCollection : [{
              nl: {
                views: 0,
                name: 'Winkelaankopen zonder productopgave',
                nameSlug: 'winkelaankopen-zonder-productopgave'
              },
              en: {
                views: 0,
                name: 'Store purchases without product specification',
                nameSlug: 'store-purchases-without-product-specification'
              },
              de: {
                views: 0,
                name: 'Ladeneinkäufe ohne Produktspezifikation',
                nameSlug: 'ladeneinkaufe-ohne-produktspezifikation'
              },
              fr: {
                views: 0,
                name: 'Stocker les achats sans spécification de produit',
                nameSlug: 'stocker-les-achats-sans-specification-de-produit'
              },
              es: {
                views: 0,
                name: 'Compras en tienda sin especificación de producto',
                nameSlug: 'compras-en-tienda-sin-especificacion-de-producto'
              },
              da: {
                views: 0,
                name: 'Opbevar køb uden produktspecifikation',
                nameSlug: 'opbevar-kob-uden-produktspecifikation'
              }
            }]
          }],
          refund: false,
          checkout: true,
          payLater: true
        })
      }
    }

    $scope.getTransactionPrice = function () {
      var counter = 0; var totalAmount = 0
      for (var i = 0; i < $scope.transaction.details.length; i++) {
        totalAmount += $scope.transaction.details[i].total
        counter++
        if (counter === $scope.transaction.details.length) {
          return totalAmount
        }
      }
    }

    $scope.getPrice = function (checkout, callback) {
      if (!$scope.transaction) {
        return
      }
      if (checkout && $scope.outstanding > 0) {
        return callback($scope.outstanding)
      }
      if ($scope.transaction[$scope.transaction.type].actualCosts) {
        return callback($scope.transaction[$scope.transaction.type].actualCosts)
      } else if ($scope.transaction[$scope.transaction.type].estimatedPrice) {
        return callback($scope.transaction[$scope.transaction.type].estimatedPrice)
      } else {
        return callback(0)
      }
    }

    $scope.getOutstandingAmount = function () {
      $scope.outstanding = 0
      $scope.getPrice(false, function (price) {
        if (!$scope.payments || $scope.payments.length === 0) {
          $scope.outstanding = price
          return
        }
        var counter = 0; var totalPaid = 0
        for (var i = 0; i < $scope.payments.length; i++) {
          totalPaid += $scope.payments[i].amount - $scope.payments[i].change
          counter++
          if (counter === $scope.payments.length) {
            $scope.outstanding = price - totalPaid
          }
        }
      })
    }

    $scope.fetchPreviousPaidQuantity = function () {
      return $q(function (resolve, reject) {
        var totalPaidQuantity = 0
        if ($scope.transaction.details && $scope.transaction.details.length && $scope.transaction.details[0].productId) {
          for (var i = 0; i < $scope.transaction.transactions.length; i++) {
            var firstDetail = $scope.transaction.transactions[i].details[0]
            totalPaidQuantity += firstDetail.quantity
          }
        }
        return resolve(totalPaidQuantity)
      })
    }

    $scope.checkout = function (refund, deposit, finalCheckout) {
      var items = []
      if (refund) {
        // We gonna refund the whole order, so we take the items of the current transaction and placed them with an negative amount on a new transaction
        var counter = 0
        for (var i = 0; i < $scope.transaction.details.length; i++) {
          var detail = $scope.transaction.details[i]
          var quantity

          if ($scope.transaction.totalEarning > $scope.transaction.transactionTotal) {
            quantity = -($scope.transaction.transactionTotal - $scope.transaction.totalEarning) / ($scope.transaction.details[0].price * (1 + ($scope.transaction.details[0].priceVat / 100)))
          }

          items.push({
            name: $language.translate('REFUND') + ' ' + detail.name,
            quantity: $scope.transaction.totalEarning > $scope.transaction.transactionTotal ? quantity : detail.quantity - detail.stockUpQuantity,
            price: detail.price,
            total: detail.total,
            productId: detail.productId && detail.productId._id ? detail.productId._id : null,
            transaction: $scope.transaction._id,
            discount: false,
            discountValue: 0,
            discountPercent: null,
            photos: detail.photos,
            priceVat: detail.priceVat ? detail.priceVat : 21,
            comment: detail.comment,
            purchasePrice: detail.purchasePrice,
            refunded: detail.refundAmount === detail.total,
            productForRefund: true,
            isRestictForEdit: $scope.transaction.totalEarning > $scope.transaction.transactionTotal,
            refundQuantity: $scope.transaction.totalEarning > $scope.transaction.transactionTotal ? quantity : detail.quantity - detail.stockUpQuantity,
            refundAmount: $scope.transaction.totalEarning > $scope.transaction.transactionTotal ? $scope.transaction.totalEarning - $scope.transaction.transactionTotal : detail.total - detail.refundAmount,
            refundedAmount: detail.refundAmount,
            parentTransactionDetailId: detail._id,
            stockUpQuantity: detail.stockUpQuantity,
            supplier: detail.supplier
          })
          counter++
          if (counter === $scope.transaction.details.length) {
            $state.go('retailer.till', {
              details: $scope.transaction.details,
              items: items,
              transaction: $scope.transaction._id,
              customer: $scope.transaction.customer
            })
          }
        }
      } else {
        var depositAmount = ($scope.transaction.transactionTotal - $scope.transaction.totalEarning)

        if (deposit) {
          depositAmount = depositAmount * (deposit / 100)
        }

        // $scope.getPrice(true, function (price) {
        if (finalCheckout && $scope.transaction.type === 'special') {
          // var items;
          // Need to fetch sum of all shop-purchase order quantity which is done for this transaction
          $scope.fetchPreviousPaidQuantity()
            .then(function (paidQuantity) {
              var itemsData = [{
                name: $scope.transaction.details[0].name,
                quantity: $scope.transaction.details[0].quantity - paidQuantity, // depositAmount / ( $scope.transaction.details[0]['price'] * ( 1 + ($scope.transaction.details[0]['priceVat'] / 100 ) ) ),
                isRestictForEdit: true,
                price: $scope.transaction.details[0].price * (1 + ($scope.transaction.details[0].priceVat / 100)), // deposit ? ($scope.transaction.transactionTotal - $scope.transaction.totalEarning) * (deposit / 100) : ($scope.transaction.transactionTotal - $scope.transaction.totalEarning),
                transaction: $scope.transaction._id,
                discountValue: 0,
                total: depositAmount,
                photo: null,
                priceVat: 21,
                comment: $language.translate('DEPOSIT') + ' ' + $language.translate($scope.transaction.type.toUpperCase()) + ' ' + $scope.transaction.number + '. ' + '\n' + $language.translate('TOTAL_AMOUNT_TO_PAY') + ' \u20AC' + $scope.transaction.details[0].total, // (deposit ? $language.translate('TOTAL_AMOUNT_TO_PAY') + ' \u20AC' + $scope.transaction.transactionTotal : $scope.transaction.comment),
                discount: false,
                parentTransactionDetailId: $scope.transaction.details && $scope.transaction.details[0] && $scope.transaction.details[0]._id ? $scope.transaction.details[0]._id : null,
                productId: $scope.transaction.details && $scope.transaction.details[0] && $scope.transaction.details[0].productId ? $scope.transaction.details[0].productId._id : null,
                // purchasePrice: $scope.transaction.type == 'repair' && $scope.transaction.repair.externalPrice ? $scope.transaction.repair.externalPrice : $scope.transaction.type == 'special' ? 12345 : null,
                purchasePrice: $scope.transaction.details && $scope.transaction.details[0] && $scope.transaction.details[0].purchasePrice ? $scope.transaction.details[0].purchasePrice : null,
                noNeedToReCalculateOfTotal: true,
                productCollection: $scope.transaction.details[0].productCollection && $scope.transaction.details[0].productId ? $scope.transaction.details[0].productCollection : [{
                  nl: {
                    views: 0,
                    name: 'Winkelaankopen zonder productopgave',
                    nameSlug: 'winkelaankopen-zonder-productopgave'
                  },
                  en: {
                    views: 0,
                    name: 'Store purchases without product specification',
                    nameSlug: 'store-purchases-without-product-specification'
                  },
                  de: {
                    views: 0,
                    name: 'Ladeneinkäufe ohne Produktspezifikation',
                    nameSlug: 'ladeneinkaufe-ohne-produktspezifikation'
                  },
                  fr: {
                    views: 0,
                    name: 'Stocker les achats sans spécification de produit',
                    nameSlug: 'stocker-les-achats-sans-specification-de-produit'
                  },
                  es: {
                    views: 0,
                    name: 'Compras en tienda sin especificación de producto',
                    nameSlug: 'compras-en-tienda-sin-especificacion-de-producto'
                  },
                  da: {
                    views: 0,
                    name: 'Opbevar køb uden produktspecifikation',
                    nameSlug: 'opbevar-kob-uden-produktspecifikation'
                  }
                }]
              }]

              $state.go('retailer.till', {
                items: JSON.parse(JSON.stringify(itemsData)),
                transaction: $scope.transaction._id,
                customer: $scope.transaction.customer
              })
            })
            .catch(function (error) {
              console.error('error ---------->?', error)
            })
        } else {
          items = [{
            name: $scope.transaction.details[0].name, // (refund ? $language.translate('REFUND') + ' ' : (deposit ? $language.translate('DEPOSIT') + ' ' : '')) + $language.translate($scope.transaction.type.toUpperCase()) + " " + $scope.transaction.number,
            quantity: depositAmount / ($scope.transaction.details[0].price * (1 + ($scope.transaction.details[0].priceVat / 100))), // 1,
            isRestictForEdit: true,
            price: $scope.transaction.details[0].price * (1 + ($scope.transaction.details[0].priceVat / 100)), // deposit ? ($scope.transaction.transactionTotal - $scope.transaction.totalEarning) * (deposit / 100) : ($scope.transaction.transactionTotal - $scope.transaction.totalEarning),
            transaction: $scope.transaction._id,
            discountValue: 0,
            total: depositAmount,
            photo: null,
            priceVat: 21,
            comment: $language.translate('DEPOSIT') + ' ' + $language.translate($scope.transaction.type.toUpperCase()) + ' ' + $scope.transaction.number + '. ' + '\n' + $language.translate('TOTAL_AMOUNT_TO_PAY') + ' \u20AC' + $scope.transaction.details[0].total, // (deposit ? $language.translate('TOTAL_AMOUNT_TO_PAY') + ' \u20AC' + $scope.transaction.transactionTotal : $scope.transaction.comment),
            discount: false,
            parentTransactionDetailId: $scope.transaction.details && $scope.transaction.details[0] && $scope.transaction.details[0]._id ? $scope.transaction.details[0]._id : null,
            productId: $scope.transaction.details && $scope.transaction.details[0] && $scope.transaction.details[0].productId ? $scope.transaction.details[0].productId._id : null,
            // purchasePrice: $scope.transaction.type == 'repair' && $scope.transaction.repair.externalPrice ? $scope.transaction.repair.externalPrice : $scope.transaction.type == 'special' ? 12345 : null,
            purchasePrice: $scope.transaction.type === 'repair' && $scope.transaction.repair.externalPrice ? $scope.transaction.repair.externalPrice : null,
            productCollection: $scope.transaction.details[0].productCollection && $scope.transaction.details[0].productId ? $scope.transaction.details[0].productCollection : [{
              nl: {
                views: 0,
                name: 'Winkelaankopen zonder productopgave',
                nameSlug: 'winkelaankopen-zonder-productopgave'
              },
              en: {
                views: 0,
                name: 'Store purchases without product specification',
                nameSlug: 'store-purchases-without-product-specification'
              },
              de: {
                views: 0,
                name: 'Ladeneinkäufe ohne Produktspezifikation',
                nameSlug: 'ladeneinkaufe-ohne-produktspezifikation'
              },
              fr: {
                views: 0,
                name: 'Stocker les achats sans spécification de produit',
                nameSlug: 'stocker-les-achats-sans-specification-de-produit'
              },
              es: {
                views: 0,
                name: 'Compras en tienda sin especificación de producto',
                nameSlug: 'compras-en-tienda-sin-especificacion-de-producto'
              },
              da: {
                views: 0,
                name: 'Opbevar køb uden produktspecifikation',
                nameSlug: 'opbevar-kob-uden-produktspecifikation'
              }
            }]
          }]

          $state.go('retailer.till', {
            items: items,
            transaction: $scope.transaction._id,
            customer: $scope.transaction.customer
          })
        }
      }
    }

    $scope.cancelTransaction = function () {
      var dlg = dialogs.confirm($language.translate('CANCEL'), $language.translate('ARE_YOU_SURE_TO_CANCEL_THIS_TRANSACTION'), { size: 'sm' })
      dlg.result.then(function (btn) {
        if (btn == 'yes') {
          $scope.changeStatus('cancelled')
        }
      })
        .catch(function (reason) {
          console.error(reason)
        })
    }

    $scope.addComment = function (comment) {
      if ($scope.transaction && comment) {
        var body = {
          transactionId: $scope.transaction._id,
          shopId: $rootScope.currentShop._id,
          shopName: $rootScope.currentShop.name,
          transactionType: $scope.transaction.type,
          repairer: ($scope.transaction.repair && $scope.transaction.repair.repairer && $scope.transaction.repair.repairer._id ? $scope.transaction.repair.repairer._id : ''),
          remarks: {
            eType: 'retailer',
            remark: comment,
            companyId: $scope.transaction.supplier
          }
        }

        $transaction.addComment(body).then(function (response) {
          $scope.currentremark = ''
          $('#currentremark').val('')
          $scope.getTransaction()
        }).catch(function (err) {
          $scope.currentremark = ''
          $('#currentremark').val('')
          console.error(err)
        })
      }
    }

    $scope.changeRepairer = function (repairer) {
      $scope.transaction.repair.repairerType = repairer

      if (repairer === 'in-house') {
        $scope.isRepairMySelf = true
        $scope.removeRepairer()
      } else {
        if ($scope.transaction.brand && $scope.transaction.brand._id) {
          if (!$scope.shopBrands || $scope.shopBrands.length === 0) return

          var shopBrandsLength = $scope.shopBrands.length
          for (var i = 0; i < shopBrandsLength; i++) {
            var brand = $scope.shopBrands[i]
            if (brand._id._id === $scope.transaction.brand._id) {
              $scope.isRepairMySelf = false
              $scope.selectedRepairer(brand.favoriteRepairer._id)
              i = shopBrandsLength
            }
          }
        }
      }

      if ($scope.transaction.type == 'repair') {
        $scope.updateTransaction()
      }
    }

    $scope.repairMySelf = function (status) {
      if (status === true) {
        $scope.transaction.status = 'processing'
        $scope.transaction.repair.repairerType = 'in-house'
        $scope.isRepairMySelf = true
        $scope.removeRepairer()
      } else {
        $scope.transaction.status = 'processing'
        if ($scope.transaction.brand && $scope.transaction.brand._id) {
          var brand = _.find($scope.shopBrands, function (o) { return o._id._id === $scope.transaction.brand._id })
          if (brand) {
            if (brand.favoriteRepairer._id) {
              $scope.selectedRepairer(brand.favoriteRepairer._id)
              $scope.isRepairMySelf = false
              $scope.transaction.repair.repairerType = 'external'
              $('#procent-1').prop('checked', false)
            } else {
              $scope.transaction.repair.repairerType = 'in-house'
              $scope.isRepairMySelf = true
              $('#procent-1').prop('checked', true)
            }
          } else {
            $scope.transaction.repair.repairerType = 'in-house'
            $scope.isRepairMySelf = true
            $('#procent-1').prop('checked', true)
          }
        } else {
          $scope.transaction.repair.repairerType = 'in-house'
          $scope.isRepairMySelf = true
          $('#procent-1').prop('checked', true)
        }
      }
      if ($scope.transaction.type === 'repair') $scope.updateTransaction()
    }

    $scope.editCommentMode = function (remark, index) {
      for (var i = 0; i < $scope.transaction.remarks.remarks.length; i++) {
        $scope.transaction.remarks.remarks[i].isEditMode = false
      }
      $scope.transaction.remarks.remarks[index].isEditMode = true
      $scope.editRemarkDetail = remark
    }

    $scope.updateComment = function (comment, index) {
      if (comment) {
        $scope.editRemarkDetail.remark = comment
        $scope.transaction.remarks.remarks[index].isEditMode = false
        var body = {
          transactionId: $scope.transaction._id,
          commentId: $scope.editRemarkDetail._id,
          remark: comment,
          shopId: $rootScope.currentShop._id
        }

        $api.post('edit-comment-service-retailer-and-company', body)
          .then(function (response) {
            $scope.editRemarkDetail = ''
            $scope.getTransaction()
          })
          .catch(function (err) {
            $scope.editRemarkDetail = ''
            console.error(err)
          })
      } else {
        $scope.editRemarkDetail = ''
        $scope.purchase_order.remarks.remarks[index].isEditMode = false
      }
    }

    $scope.openTransaction = function (id) {
      $transaction.openTransaction(id, $scope.transaction.type, $scope.transaction.oldTill)
    }

    $scope.copyTransaction = function () {
      $scope.transaction.shopId = $scope.transaction.shopId._id

      if ($scope.transaction.type == 'repair') {
        if ($scope.transaction.repair.estimatedPrice) {
          $scope.transaction.estimatedPrice = $scope.transaction.repair.estimatedPrice.toString()
        }
        $scope.transaction.estimatedDate = $scope.transaction.repair.estimatedDate
        $scope.transaction.category = $scope.transaction.repair.category
        $scope.transaction.material = $scope.transaction.repair.material
        $scope.transaction.email = $scope.transaction.repair.email
        $scope.transaction.actualCosts = $scope.transaction.repair.actualCosts
        $scope.transaction.externalPrice = $scope.transaction.repair.externalPrice
        if ($scope.transaction.repair.repairer) {
          $scope.transaction.repairer = $scope.transaction.repair.repairer._id
        }
      }
      if ($scope.transaction.type == 'special') {
        if ($scope.transaction.special.estimatedPrice) {
          $scope.transaction.estimatedPrice = $scope.transaction.special.estimatedPrice.toString()
        }
        $scope.transaction.estimatedDate = $scope.transaction.special.estimatedDate
        $scope.transaction.actualCosts = $scope.transaction.special.actualCosts
      }
      if ($scope.transaction.supplier) {
        $scope.transaction.company = $scope.transaction.supplier
      }

      $transaction.createTransaction($scope.transaction, null, $scope.transaction.number, true)
        .then(function (trans) {
          $scope.openTransaction(trans._id)
        })
        .catch(function (reason) {
          console.error(reason)
        })
    }

    $scope.getEmployees = function () {
      $employee.getEmployees().then(function (response) {
        $scope.employees = response
      }).catch(function (error) {
      })
    }

    $scope.searchUsers = function (str) {
      var matches = []
      $scope.employees.forEach(function (item) {
        if ((item.firstName && item.firstName.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                    (item.lastName && item.lastName.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                    (item.lastNamePrefix && item.lastNamePrefix.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                    (item.username && item.username.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                    (item.initials && item.initials.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                    (item.email && item.email.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)
        ) {
          matches.push(item)
        }
      })
      return matches
    }

    $scope.userSelected = function (selected) {
      $scope.isRemovedAssitedBy = false
      $scope.transaction.createdBy = {
        _id: selected.originalObject._id,
        name: selected.originalObject.firstName + ' ' + (selected.originalObject.lastNamePrefix ? selected.originalObject.lastNamePrefix + ' ' : '') + selected.originalObject.lastName
      }
      if ($scope.transaction.type == 'repair') $scope.updateTransaction()
    }

    // Whenever adding comment to the then we have add new line when pressed (SHIFT + ENTER) and if only enter then add Comment
    $scope.addCommentKeyUp = function (event, currentremark) {
      if (event.keyCode === 13) {
        if (event.shiftKey) return false
        else $scope.addComment(currentremark)
      }
    }

    $scope.autoScrollcomment = function () {
      setTimeout(function () {
        $('#messagewindow').animate({ scrollTop: $('#messagewindow')[0].scrollHeight }, 0)
      }, 200)
    }

    $scope.autoScrollPurchasOrdercomment = function () {
      setTimeout(function () {
        $('#purchaseordermessagewindow').animate({ scrollTop: $('#purchaseordermessagewindow')[0].scrollHeight }, 0)
      }, 200)
    }

    // Update purchasePrice for a particular transactions and it's shop purchase transaction
    $scope.updatedPurchasePrice = function (detailsIndex) {
      $api.put('v2/transaction/product/purchase-price', {
        detailsId: $scope.transaction.details[detailsIndex]._id,
        transactionId: $scope.transaction._id,
        purchasePrice: $scope.transaction[$scope.transaction.type].externalPrice,
        shopId: $rootScope.currentShop._id
      })
        .then(function () {
        }).catch(function (reason) {
          console.error('Error while getting shop', reason)
        })
    }

    $scope.customerCanBeMailed = function () {
      if (!$scope.transaction) {
        return
      }
      if (!$scope.transaction.customer) {
        return false
      }
      if (
        $scope.transaction.status === 'completed' && !$scope.transaction.customer.counter &&
                $scope.transaction.customer.email !== '' && $scope.transaction.customer.email.indexOf('autocreated') < 0
      ) {
        return true
      } else {
        return false
      }
    }

    $scope.printRepairDocument = function (mode, skipPrint,useBagReceipt) {
      $api.get('shops/' + $rootScope.user.shops['0'].nameSlug)
        .then(function (response) {
          $scope.shopAdd = response.data.shop

          $pdf.downloadPdf((useBagReceipt && useBagReceipt === true ? 'generate-repair-bag-document': 'generate-repair-document'), {
            transaction: $scope.transaction,
            mode: mode,
            language: $rootScope.language,
            showComment: true,
            documentName: 'REPAIR/SPECIAL',
            repairBagSkipPrint: skipPrint,
            deviceId: $rootScope.currentDevice._id,
            shopId: $rootScope.currentShop._id,
            documentId: $scope.specialDoc.documentId
          }, ($scope.transaction.number ? $scope.transaction.number + '_' : '') + mode + '_' + 'receipt.pdf', null, skipPrint)
            .then(function (data) {
              console.info('Document created and', !skipPrint ? 'sended to printer' : 'downloaded')
            })
            .catch(function (reason) {
              console.error(reason)
            })
        }).catch(function (reason) {
        console.error('Error while getting shop', reason)
      })
    }

    // $scope.printRepairBagReceipt = function (skipPrint) {
    //   $api.get('shops/' + $rootScope.user.shops['0'].nameSlug)
    //     .then(function (response) {

    //       $scope.shopAdd = response.data.shop

    //       $pdf.downloadPdf('generate-repair-bag-document', {
    //         transaction: $scope.transaction,
    //         skipPrint:skipPrint,
    //         mode: 'repairbag',
    //         language: $rootScope.language,
    //         documentName: 'REPAIR/SPECIAL',
    //         deviceId: $rootScope.currentDevice._id,
    //         shopId: $rootScope.currentShop._id,
    //         documentId: null
    //       }, ($scope.transaction.number ? $scope.transaction.number + '_' : '') + '_receipt.pdf', null, skipPrint)
    //         .then(function (data) {
    //           console.info('Document created and', !skipPrint ? 'sent to printer' : 'downloaded')
    //         })
    //         .catch(function (reason) {
    //           console.error(reason)
    //         })
    //     }).catch(function (reason) {
    //     console.error('Error while getting shop', reason)
    //   })
    // }

    $scope.printSpecialThermal = function(mode) {
      if($rootScope.currentShop.newPrinter === true) {
        if(mode === 'both') {
          var trans = angular.copy($scope.transaction)
          var transactions = [$scope.transaction]
          trans.notCustomer = true
          transactions.push(trans)
          $thermalPrint.bulkPrintThermalTransactions(transactions)
        } else {
          var type = 'special-receipt'
          if(mode === 'repairer') {
            type += '-partner'
          }
          $thermalPrint.printThermal(type, 1, $scope.transaction)
        }
      } else {
        $printer.printRepairSpecialReceipt($scope.transaction, true, mode === 'repairer')
          .then(function () {
            if (mode === 'both') {
              //print again
              $printer.printRepairSpecialReceipt($scope.transaction, true, true)
            }
          })
      }






      // $printer.printRepairSpecialReceipt($scope.transaction, false, mode === 'repairer')
      //   .then(function () {
      //     if (mode === 'both') {
      //       //print again
      //       $printer.printRepairSpecialReceipt($scope.transaction, false, true)
      //     }
      //   })
    }

    $scope.openTransaction = function (id, type) {
      $transaction.openTransaction(id, type)
    }

    $scope.emailReceipt = function () {
      if (!$scope.transaction.customer || !$scope.transaction.customer.email || $scope.transaction.customer.counter === true) {
        return
      }

      $api.post('till/transaction/email', {
        transactionId: $scope.transaction._id,
        email: $scope.transaction.customer.email,
        shopId: $rootScope.currentShop._id
      }, null, 2)
        .then(function() {
          toastr.success($language.translate('MAIL_SENT'))
        })
        .catch(function(reason) {
          console.error('Error sending repair email', reason)
          toastr.error($language.translate('ERROR_WHILE_PRINTING_OR_SENDING'))
        })
    }

    $scope.mailCustomer = function () {
      $customer.openCustomerEmailModal($scope.transaction.customer)
        .then(function () {
          toastr.success($language.translate('MAIL_SEND'))
        })
    }

    $scope.openWhatsapp = function () {
      $customer.openWhatsapp($scope.transaction.customer)
    }

  }])
