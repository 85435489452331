prismanoteApp.controller('brandRecommendationManagementController', ['$scope', '$rootScope', '$brand', '$clientGroup', '$productConfig', '$company', '$purchaseOrder',
    function ($scope, $rootScope, $brand, $clientGroup, $productConfig, $company, $purchaseOrder) {
        console.log('brandRecommendationManagementController called');

        $scope.localdata = {
            limit: 30,
            currentPage: 1,
            totalBrandProducts: 0,
            products: [],
            pageSize: 30,
            companyBrands: [],
            search_value: '',
            productNumber: '',
            ean: '',
            searchStarted: false, // Use Enter Key to Search
            isLikeEANNumber: false, // If SearchText is like EAN number
            isSearchedData: false, // To check if the data fetched after searching
            productExactMatch: false,
            eanNumberSearch: false,
            productNumberSearch: false
        }

        $scope.fetchRecommendationManagementProducts = function () {
            var body = {
                limit: $scope.localdata.limit,
                offset: $scope.localdata.currentPage,
                companyId: $rootScope.currentCompany._id
            }
            $brand.fetchRecommendationManagementProducts(body).then(function (response) {
                if (response && response.data && response.data.data) {
                    // console.log('fetchRecommendationManagementProducts called: ', JSON.parse(JSON.stringify(response.data.data, null, 2)));
                    console.log('fetchRecommendationManagementProducts PRODUCTS:---------------> ', response.data.data);
                    // return;
                    $scope.localdata.products = response.data.data.totalBrandProducts;
                    $scope.localdata.totalBrandProducts = response.data.data.totalBrandProducts;
                    $scope.localdata.companyBrands = response.data.data.companyBrands;
                }
                console.log('fetchRecommendationManagementProducts response: ', $scope.localdata.companyBrands);
            }).catch(function (error) {
                console.log('fetchRecommendationManagementProducts error: ', error);
            });
        }

        $scope.changePage = function (position, currentPage) {
            $scope.localdata.currentPage = currentPage;
            $scope.fetchRecommendationManagementProducts();
        }

        $scope.fetchBrandProduct = function (item, index) {
            console.log('fetchBrandProduct called: ', item, index);
            var body = {
                limit: 30,
                offset: item.currentPage,
                companyId: $rootScope.currentCompany._id,
                brandId: item._id
            }

            $brand.fetchRecommendationManagementBrandProducts(body).then(function (response) {
                console.log('------------------------------------->>>>> fetchRecommendationManagementBrandProducts response: ', response.data.data);
                $scope.localdata.products[index] = response.data.data;
                $scope.localdata.products[index].isVisible = true;
            }).catch(function (error) {
                console.log('fetchRecommendationManagementBrandProducts error: ', error);
            });
        }

        $scope.addProductToClientGroup = function (grandChildIndex, childIndex, parentIndex, isFromSearch, isSelected) {
            // console.log('addProductToClientGroup called: ', parentIndex, childIndex, grandChildIndex, isFromSearch, isSelected);

            var body;
            console.log('isFromSearch 111 condition', isFromSearch);
            if (!isFromSearch) {
                // console.log('isFromSearch IF condition', isFromSearch);
                // console.log('BrandIndex: ', parentIndex);
                // console.log('productIndex: ', childIndex);
                // console.log('clientGroupIndex: ', grandChildIndex);
                // console.log('isSelected: ', isSelected);
                // console.log('Product: ', $scope.localdata.products[parentIndex].product[childIndex]);
                // console.log('isFromSearch If: ', isFromSearch, $scope.localdata.products[parentIndex].product[childIndex].clientGroups[grandChildIndex].isSelected);
                // $scope.localdata.products[parentIndex].product[childIndex].clientGroups[grandChildIndex].isSelected = !$scope.localdata.products[parentIndex].product[childIndex].clientGroups[grandChildIndex].isSelected;

                body = {
                    productId: $scope.localdata.products[parentIndex].product[childIndex]._id,
                    clientGroupId: $scope.localdata.products[parentIndex].product[childIndex].clientGroups[grandChildIndex]._id,
                    isSelected: $scope.localdata.products[parentIndex].product[childIndex].clientGroups[grandChildIndex].isSelected
                }
            } else {
                // console.log('isFromSearch else: ', isFromSearch);
                // console.log('hitsIndex: ', childIndex);
                // console.log('clientGroupIndex: ', grandChildIndex);
                // console.log('isSelected: ', isSelected);
                // // return;
                // console.info('$scope.hits: ', JSON.parse(JSON.stringify($scope.hits, null, 2)));
                // var clientGroup = $scope.hits[childIndex].clientGroups[grandChildIndex];
                // $scope.hits[childIndex].clientGroups[grandChildIndex].isSelected = !$scope.hits[childIndex].clientGroups[grandChildIndex].isSelected;
                var product = $scope.hits[childIndex];
                // console.info('$scope.hits 1: ', $scope.hits);
                // console.info('product 1: ', product);

                body = {
                    productId: product._id,
                    clientGroupId: $scope.hits[childIndex].clientGroups[grandChildIndex]._id,
                    isSelected: $scope.hits[childIndex].clientGroups[grandChildIndex].isSelected
                }
            }

            console.log('addProductToClientGroup body: ', body);
            $clientGroup.addProductIdToClientGroup(body).then(function (response) {
                console.log('addProductIdToClientGroup post: ', response);
            }).catch(function (error) {
                console.log('addProductIdToClientGroup called: ', error);
            })
        }

        $scope.selectBrand = function (selectedbrand) {
            // console.info('selectedbrand: ', $scope.localdata.selectedbrand, typeof selectedbrand, selectedbrand);
            for (var i = 0; i < $scope.localdata.products.length; i++) {
                if (!selectedbrand) {
                    $scope.localdata.products[i].isVisible = true;
                    continue;
                }
                // console.log('selectBrand: ', $scope.localdata.products[i]._id == selectedbrand, $scope.localdata.products[i]._id, selectedbrand);
                $scope.localdata.products[i].isVisible = false;
                if ($scope.localdata.products[i]._id == selectedbrand) {
                    console.log('--------------------------------: ', $scope.localdata.products[i]._id == selectedbrand);
                    $scope.localdata.products[i].isVisible = true;
                }
            }
        }

        // input field will be remove
        $scope.cancelItem = function () {
            console.log('-----------cancelItem!!');
            $scope.localdata.searchStarted = false;
            $scope.localdata.search_value = '';
            $scope.localdata.productNumber = '';
            $scope.localdata.ean = '';
            $scope.hits = [];
        }

        // Finding company products
        $scope.isLikeEANNumber = function (value) {
            console.log('-------------- isLikeEANNumber!!');
            console.log('value ', value);
            $scope.localdata.isSearchedData = false;
            $scope.localdata.productExactMatch = false;
            if ($purchaseOrder.isLikeEANNumber(value)) $scope.localdata.isLikeEANNumber = true;
            if (!$purchaseOrder.isLikeEANNumber(value)) $scope.localdata.isLikeEANNumber = false;
        }

        $scope.findProducts = function (value, isProductNumber) {
            if (value.length < 3) return;
            $scope.localdata.isSearchedData = false;
            $scope.localdata.productExactMatch = false;
            console.log('-------------------------------------- findProducts!!', value, isProductNumber);
            if (isProductNumber) {
                $scope.localdata.productNumber = $productConfig.retrunValidProductNumber(value);
                $scope.localdata.productNumberSearch = true;
            } else {
                $scope.localdata.ean = $productConfig.retrunValidProductNumber(value);
                $scope.localdata.eanNumberSearch = true; $scope.localdata.productNumberSearch = false;
            }
            if (value && value.length && $rootScope.currentCompany && $rootScope.currentCompany._id) {
                $scope.localdata.search_value = $productConfig.retrunValidProductNumber(value);
                value = $scope.localdata.search_value;

                var data = {
                    companyId: $rootScope.currentCompany._id,
                    searchText: value,
                    isProductNumber: isProductNumber,
                    language: $rootScope.language
                };
                $scope.localdata.searchStarted = true;
                $company.searchProductForCompany(data).then(function (response) {
                    console.log('--------- searchProductForCompany 1!: ', response);
                    $company.getCompanyClientGroup({ companyId: $rootScope.currentCompany._id }).then(function (clientresponse) {
                        var clientGroups = [];
                        if (clientresponse && clientresponse.data && clientresponse.data.data) clientGroups = clientresponse.data.data;
                        console.log('getCompanyClientGroup clientresponse: ', clientGroups);

                        $scope.localdata.searchStarted = false;
                        $scope.localdata.isSearchedData = true;
                        $scope.localdata.productExactMatch = response.productExactMatch;
                        $scope.hits = response.data;
                        for (var i = 0; i < $scope.hits.length; i++) {
                            $scope.hits[i].clientGroups = JSON.parse(JSON.stringify(clientGroups))
                        }

                        for (var i = 0; i < $scope.hits.length; i++) {
                            for (var j = 0; j < $scope.hits[i].clientGroups.length; j++) {
                                var prods = $scope.hits[i].clientGroups[j].products;
                                $scope.hits[i].clientGroups[j].isSelected = false
                                if (prods.includes($scope.hits[i]._id)) {
                                    $scope.hits[i].clientGroups[j].isSelected = true
                                }
                            }
                        }
                        console.log('--------- searchProductForCompany hits!: ', $scope.hits);
                    }).catch(function (clienterror) {
                        console.log('getCompanyClientGroup clienterror: ', clienterror);
                    });
                }).catch(function (error) {
                    $scope.cancelItem();
                    console.error("searchProductForCompany error", error);
                })
            }
        };
    }])
