prismanoteApp.controller('dudaHomeController', ['$scope', '$rootScope', '$state', 'factory', 'grapeFactory', 'dudaService', 'Upload', '$uibModal', function ($scope, $rootScope, $state, factory, grapeFactory, dudaService, Upload, $uibModal) {
    $scope.wildCards = []
    $scope.errorMessage = ''
    $scope.websiteRemark = ''
    $scope.loading = false
    $scope.alert = {
      type: '',
      msg: ''
    }
    $scope.webBuilderURL = 'http://builder.prismanote.com/home'
    $scope.enableLocalBusiness = false;
  
    $scope.apiTemplateList = []
  
    $scope.getWebsites = function (id) {
      $scope.loading = true
      dudaService.getCustomerDetails()
        .then(function (response) {
          console.log(response)
        })
        .catch(function (error) {
          console.log(error)
        })
      return dudaService
        .getSiteList({id : id})
        .then(function (response) {
          if (response && response.data) {
            $scope.availableWebsites = response.data
          } else {
            $scope.availableWebsites = []
          }
          $scope.loading = false
          // $scope.wildCards = response.data;
        })
        .catch(function (error) {
          $scope.loading = false
          $scope.alert.type = 'danger'
          $scope.alert.msg = error.data.message || 'Something went wrong. Please try again'
        })
    }
  
    $scope.closeAlert = function () {
      $scope.alert = {
        type: '',
        msg: ''
      }
    }
  
    // $scope.cruise = cruises;
    $scope.limit = 3
  
    $scope.loadMore = function () {
      var increamented = $scope.limit + 4
      $scope.limit = increamented > $scope.availableWebsites.length ? $scope.availableWebsites.length : increamented
    }
  
    $scope.wildCardViewFunc = function(template){
      var url = $scope.webBuilderURL + '?dm_sso='+ template.token + '&page=site&id=' + template.site_name
      window.open(url, '_blank');
    }
  
    $scope.removeWebsite = function (singleWebsite, index) {
      // Take confirmation from user for delete
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/confirm-modal.html',
        controller: 'confirmModalController',
        resolve: {
          modalHeader: function () {
            return singleWebsite.publish_status == 'NOT_PUBLISHED_YET' ? 'Confirmation' : 'Attention'
          },
          modalMessage: function () {
            return singleWebsite.publish_status == 'NOT_PUBLISHED_YET' ? "Are you sure you want to delete this website? It's not possible to restore it. Click on 'Cancel' if you don't want to delete your website" : "This website is attache with domain so can't delete it."
          },
          okAndCancelBothAction: function () {
            return singleWebsite.publish_status == 'NOT_PUBLISHED_YET'
          }
        }
      })
  
      return modalInstance
        .result
        .then(function (result) {
          if (result && singleWebsite.publish_status  == 'NOT_PUBLISHED_YET') {
            $scope.loading = true
            return dudaService.removeWebsite(singleWebsite)
          } else {
            return ''
          }
        })
        .then(function (response) {
          if (response !== '') {
            $scope.availableWebsites.splice(index, 1)
            $scope.loading = false
            $scope.alert = {
              type: 'success',
              msg: result.data.message
            }
          }
        })
        .catch(function (error) {
          $scope.loading = false
          $scope.alert = {
            type: 'danger',
            msg: error.data.message
          }
        })
    }
  
    // Remove domain from particular website
    $scope.removeDomain = function (wildCard, index) {
      try {
        // Take confirmation from user for delete
        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/confirm-modal.html',
          controller: 'confirmModalController',
          resolve: {
            modalHeader: function () {
              return 'Confirmation'
            },
            modalMessage: function () {
              return 'Are you sure you want to remove domain from this website'
            },
            okAndCancelBothAction: function () {
              return true
            }
          }
        })
  
        modalInstance
          .result
          .then(function (result) {
            if (result) {
              return grapeFactory.removeDomain1($rootScope.currentShop._id, wildCard.config.websiteName, wildCard.config.domainDetails.domain)
            } else {
              return ''
            }
          })
          .then(function (response) {
            if (response !== '') {
              $scope.alert.type = 'success'
              $scope.alert.msg = response.data.message
              $scope.getWebsites($rootScope.currentShop._id)
            }
          })
          .catch(function (e) {
            console.log('error ', e)
          })
      } catch (e) {
        console.log('error in catch', e)
      }
    }
  
    // Copy website
    $scope.copyWebsite = function(template){
      $scope.loading = true
      return dudaService
        .duplicateWebsite(template)
        .then(function (response) {
          if (response && response.data) {
            $scope.getWebsites($rootScope.currentShop._id)
          } else {
            $scope.loading = false
            $scope.availableWebsites = []
          }
          // $scope.wildCards = response.data;
        })
        .catch(function (error) {
          $scope.loading = false
          $scope.alert.type = 'danger'
          $scope.alert.msg = error.data.message || 'Something went wrong. Please try again'
        })
    }

    // 
    $scope.addSEOCollection = function(singleWebsite){
      var data = {
        "site_name" : singleWebsite.site_name,
        "external_uid" : singleWebsite.external_uid
      }
      $scope.loading = true
      return dudaService
        .addSEOCollection(data)
        .then(function (response) {
          $scope.loading = false
          $scope.getWebsites($rootScope.currentShop._id)
          if(response.data != "" && typeof(response.data.error_code) != "undefined"){
            $scope.alert.type = 'danger'
            $scope.alert.msg = 'You have already added a collection.'
          }
        })
        .catch(function (error) {
          $scope.loading = false
          $scope.alert.type = 'danger'
          $scope.alert.msg = error.data.message || 'Something went wrong. Please try again'
        })
    }
  
    // redirect to select template for create new site
    $scope.createNewSite = function(){
      $state.go('retailer.web-template-list')
    }
  
    // Redirect to preview site
    $scope.previewSite = function(template){
      window.open(template.preview_site_url, '_blank');
    }

    $scope.checkSEOCollection = function(siteData){
      var data = siteData.collection.filter(function(c){ return c.name == 'SEO_Collection'})
      return (data.length > 0)
    }

    $scope.viewStats = function(template){
      var url = $scope.webBuilderURL + '?dm_sso='+ template.token + '&page=stats&id=' + template.site_name
      window.open(url, '_blank');
    }

    $scope.redirectToCourse = function(){
      var url = "https://info.prismanote.com/website-cursus";
      window.open(url, '_blank');
    }

    $scope.updateLocalBusinessSetting = function(site){
      $scope.loading = true
      var data = {
        site_name : site.site_name,
        schema : site.schemas
      }
      return dudaService
        .updateSiteData(data)
        .then(function (response) {
          $scope.loading = false
          if(response.data != "" && typeof(response.data.error_code) != "undefined"){
            $scope.alert.type = 'danger'
            $scope.alert.msg = 'Something went wrong. Please try again.'
          }
        })
        .catch(function (error) {
          $scope.loading = false
          $scope.alert.type = 'danger'
          $scope.alert.msg = error.data.message || 'Something went wrong. Please try again'
        })
    }
    
  }])
  prismanoteApp.config(['$qProvider', function ($qProvider) {
    $qProvider.errorOnUnhandledRejections(false)
  }])
  