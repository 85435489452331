prismanoteApp.controller('accountController', ['$scope', '$rootScope', '$api', 'prompt', '$location', '$window',
  function ($scope, $rootScope, $api, prompt, $location, $window) {
    $scope.resetSelected = function () {
      $scope.currentShop = null
      $scope.currentAddress = null
      $scope.currentPhone = null
    }
    $scope.getPhoneNumber = function () {
      console.info('getPhoneNumber')
      if ($rootScope.user.phone) {
        var phone = $rootScope.user.phone

        if (phone.mobilePhone) {
          $rootScope.user.phonenumber = phone.mobilePhone
        } else {
          $rootScope.user.phonenumber = '+' + phone.countryCode + phone.landLine
        }
      }
    }

    $scope.resetSelected()
    $scope.getPhoneNumber()

    $scope.openAddress = function (index) {
      console.log('openAddress', index, $rootScope.user.address)
      $scope.resetSelected()
      $scope.addNewAddress = false
      $scope.currentAddress = $rootScope.user.address[index]
      $scope.currentAddress.index = index
    }

    $scope.newAddress = function () {
      $scope.resetSelected()
      $scope.currentAddress = {}
      $scope.addNewAddress = true
    }

    $scope.deleteAddress = function (index) {
      prompt({
        title: 'Adres verwijderen?',
        message: 'Weet u zeker dat u dit adres wilt verwijderen?'
      }).then(function () {
        $rootScope.user.address.splice(index, 1)
        $scope.resetSelected()
        $scope.saveUser()
      })
    }

    $scope.cancelNewAddress = function () {
      $scope.addNewAddress = false
      $scope.resetSelected()
    }

    $scope.submitAddress = function (valid) {
      $scope.alert = null
      if (!valid) {
        $scope.alert = {
          type: 'danger',
          msg: 'Niet alle verplichte velden zijn ingevuld. Corrigeer deze en probeer het opnieuw.'
        }
        return
      }

      if ($scope.addNewAddress) {
        console.log('addNew')
        if ($scope.currentAddress) {
          $rootScope.user.address.push($scope.currentAddress)
          $scope.addNewAddress = false
          $scope.saveUser()
        } else {
          console.log('save')
          $scope.saveUser()
        }
      }
      $scope.resetSelected()
    }

    $scope.saveUser = function () {
      $scope.alert = null
      var shopSlug = localStorage.getItem('currentShop');
      var companySlug = localStorage.getItem('currentCompany');
      var data = { reload: false, shopSlug: shopSlug, companySlug: companySlug }
      $rootScope.checkLogin(data, function (err, authenticatedUser) {
        if (!err) {
          $api.put('user/' + $rootScope.user._id, { user: $rootScope.user })
            .then(function (response) {
              $scope.alert = {
                type: 'success',
                msg: response.data.message
              }
            })

            .catch(function (reason) {
              $scope.alert = {
                type: 'danger',
                msg: reason
              }
            })
        }
      })
    }

    $scope.loadOrders = function () {
      $scope.orders = null
      $scope.loading = true

      $api.get('orders', { filter: { 'user._id': $rootScope.user._id }, sort: { _id: 'desc' } })

        .then(function (response) {
          if (!response.data.orders) {
            $scope.loading = false
            $scope.orders = {}
            return
          }
          $scope.orders = response.data.orders
          for (var i = 0; i < $scope.orders.length; i++) {
            var total = 0
            for (var t = 0; t < $scope.orders[i].items.length; t++) {
              total = total + ($scope.orders[i].items[t].price * $scope.orders[i].items[t].quantity)
            }
            $scope.orders[i].total = total
            $scope.orders[i].currentStatus = angular.lowercase($scope.orders[i].status[$scope.orders[i].status.length - 1].status)

            $scope.orders[i].paid = false
            for (var p = 0; p < $scope.orders[i].status.length; p++) {
              if (angular.lowercase($scope.orders[i].status[p].status) == 'paid') {
                $scope.orders[i].paid = true
                break
              }
            }

            $scope.loading = false
          }
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.cancelOrder = function (orderNumber, index) {
      prompt({
        title: 'Order annuleren?',
        message: 'Weet u zeker dat u order ' + orderNumber + ' wilt verwijderen?'
      }).then(function () {
        $api.post('cancel-order', { orderNumber: orderNumber })
          .then(function (response) {
            var newStatus = {
              status: response.data.order.status[response.data.order.status.length - 1].status,
              date: response.data.order.status[response.data.order.status.length - 1].date
            }

            $scope.orders[index].status.push(newStatus)
            $scope.orders[index].currentStatus = angular.lowercase(newStatus.status)
          })
          .catch(function (reason) {
            console.log('fout', reason)
          })
      })
    }

    $scope.returnOrder = function (orderNumber, index) {
      prompt({
        title: 'Order retourneren?',
        message: 'Neem contact op met de juwelier en gebruik het formulier in uw pakketje om uw order te retourneren.'
      }).then(function () {

      })
    }

    $scope.initResetForm = function () {
      $scope.passwords = {}
    }

    $scope.changePassword = function (valid) {
      $scope.alert = null
      if (!valid) {
        $scope.alert = {
          type: 'danger',
          msg: 'Niet alle verplichte velden zijn ingevuld. Corrigeer deze en probeer het opnieuw.'
        }
        return
      }

      $api.post('self-change-password', {
        userId: $rootScope.user._id,
        passwords: $scope.passwords
      })
        .then(function (response) {
          console.log('RES', response)
          $scope.alert = {
            type: 'success',
            msg: 'Password changed!'
          }
        })
        .catch(function (reason) {
          console.error(reason)
          $scope.alert = {
            type: 'danger',
            msg: reason.message
          }
        })
    }
  }])
