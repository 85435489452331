prismanoteApp.controller('campaignController', ['$scope', '$rootScope', '$stateParams', '$api',
  function ($scope, $rootScope, $stateParams, $api) {
    $stateParams.campaignNameSlug ? this.nameSlug = $stateParams.campaignNameSlug : this.nameSlug = $stateParams.nameSlug

    $api.get('campaigns/' + this.nameSlug)

      .then(function (response) {
        $scope.campaign = response.data.campaign
        $scope.campaign.description = $scope.campaign.description.trim()
      })

      .catch(function (reason) {
        console.log(reason)
      })
  }])
