prismanoteApp.controller('retailerNotificationsController', ['$scope', '$rootScope', '$notification', '$state', '$language', '$sce', '$compile',
  function ($scope, $rootScope, $notification, $state, $language, $sce, $compile) {
    $scope.notificationTime = function (time) {
      return moment(time).fromNow()
    }

    $scope.unreadCount = function () {
      var unread = 0
      if ($rootScope.currentShop.notifications && $rootScope.currentShop.notifications.length > 0) {
        var notifications = $rootScope.currentShop.notifications.filter(function (n) {
          return !n.read
        })
        unread = notifications.length
      }
      $rootScope.unreadNotifications = unread

      $rootScope.changeTitle()
    }

    $scope.delete = function (id) {
      var index = _.findIndex($rootScope.currentShop.notifications, { _id: id })
      if (index >= 0) {
        $rootScope.currentShop.notifications.splice(index, 1)
      }
      $notification.deleteNotification(id)
      $scope.unreadCount()
    }

    $scope.deleteAll = function () {
      $rootScope.currentShop.notifications = []
      $notification.deleteAllNotifications()
      $scope.unreadCount()
    }

    $scope.markAsRead = function (id) {
      var index = _.findIndex($rootScope.currentShop.notifications, { _id: id })
      if (index >= 0) {
        $rootScope.currentShop.notifications[index].read = true
      }
      $notification.updateNotificationStatus(id)
      $scope.unreadCount()
    }

    $scope.getNotifications = function () {
      $notification.fetchNotifications()
        .then(function (data) {
          // $rootScope.notifications = data.notifications;
        })
        .catch(function (reason) {
          console.error('error fetching notifications', reason)
        })
    }

    $scope.notificationText = function (message) {
      return $sce.trustAsHtml(message)
    }

    function parseParams (params) {
      if (typeof params !== 'object') {
        return parseParams(JSON.parse(params))
      } else {
        return params
      }
    }

    $scope.openNotification = function (message) {
      if (message.data && message.data.state) {
        if (message.data.params) {
          $state.go(message.data.state, parseParams(message.data.params))
        } else {
          $state.go(message.data.state)
        }
      }
    }
  }])
