prismanoteApp.controller('webshopRefundModalController', ['transactionDetails', '$uibModalInstance', '$scope', '$api', '$language', '$rootScope',
  function (transactionDetails, $uibModalInstance, $scope, $api, $language, $rootScope) {
    $scope.isError = false
    $scope.refundClicked = false
    $scope.refundSuccessful = false

    for (var i = 0; i < transactionDetails.details.length; i++) {
      var isEngavingService = false
      if(transactionDetails.details[i].extraService && transactionDetails.details[i].extraService.length > 0){
        isEngavingService = transactionDetails.details[i].extraService.findIndex(function(service) { return service.option == 'engraving'; }) > -1;
      }
      if(isEngavingService){
        transactionDetails.details[i].pendingRefund = 0
        transactionDetails.details[i].pendingStockUp = 0
      } else{
        transactionDetails.details[i].pendingRefund = transactionDetails.details[i].total - transactionDetails.details[i].refundAmount
        transactionDetails.details[i].pendingStockUp = transactionDetails.details[i].quantity - transactionDetails.details[i].stockUpQuantity
      }
    }
    transactionDetails.pendingShippingRefund = transactionDetails.shippingCosts - transactionDetails.refundedShippingCoast

    $scope.transaction = transactionDetails

    $scope.onFieldChange = function (value, minValue, maxValue) {
      if (value === undefined || value === null) {
        $scope.isError = true
      }
      else {
        $scope.isError = false
      }
    }

    $scope.refund = function () {
      if($scope.refundClicked) {
        return
      }
      $scope.refundClicked = true
      $scope.alert = null
      if ($scope.isError === false) {
        var apiData = []
        var refundShippingCost = 0
        var totalRefundAmount = 0

        for (var i = 0; i < $scope.transaction.details.length; i++) {
          apiData.push({
            productId: $scope.transaction.details[i].productId,
            _id: $scope.transaction.details[i]._id,
            pendingRefund: $scope.transaction.details[i].pendingRefund,
            pendingStockUp: $scope.transaction.details[i].pendingStockUp
          })
          totalRefundAmount += $scope.transaction.details[i].pendingRefund
        }

        if ($scope.transaction.type === 'webshop' && !$scope.transaction.isShippingCoastRefunded) {
          refundShippingCost = $scope.transaction.pendingShippingRefund
        }

        if ($scope.transaction.type === 'webshop') {
          $api.put('refund/webshop/' + $scope.transaction._id, {
            refundData: apiData,
            refundShippingCost: refundShippingCost,
            refundAmount: totalRefundAmount,
            shopId: $rootScope.currentShop._id
          })
            .then(function (result) {
              $scope.alert = {
                type: 'success',
                msg: $language.translate(result.data.message)
              }
              $scope.refundSuccessful = true
              $scope.alert = {
                type: 'warning',
                msg: $language.translate('ALWAYS_CHECK_YOUR_PAYMENT_PROVIDER_TO_SEE_REFUND_IS_SUCCESSFULLY_COMPLETED')
              }
            })
            .catch(function (error) {
              console.error('Error while making refund', error)
              $scope.alert = {
                type: 'danger',
                msg: error
              }
              $scope.refundClicked = false
            })
        }
      }
    }

    $scope.close = function () {
      $uibModalInstance.close(true)
    }

    $scope.cancel = function () {
      $uibModalInstance.close(false)
    }
  }])
