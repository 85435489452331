prismanoteApp.controller('companyClientGroupsController', ['$scope', '$rootScope', '$state', '$q', '$newCompany', '$clientGroup', '$collection', '$productConfig', '$brand',
  function ($scope, $rootScope, $state, $q, $newCompany, $clientGroup, $collection, $productConfig, $brand) {
    $scope.newClientGroup = false
    $scope.clientGroup = {}
    $scope.editStatus = false
    $scope.loading = false
    $scope.isClientGroupNameValid = true
    $scope.clientGroupId

    $scope.getOwnClientGroups = function (company_id) {
      $scope.loading = true
      $scope.clientGroups = []
      console.log(' ----------- Get getClientGroups ------------ ')
      $clientGroup.getOwnClientGroups(company_id)
        .then(function (clientGroups) {
          $scope.clientGroups = clientGroups.data;
          $scope.fetchCompnayClientGroupCollection();
          console.log('clientGroups ', $scope.clientGroups)
          $scope.loading = false
        })
        .catch(function (error) {
          console.error('Error ===>', error)
        })
    }

    function fetchTheManipulatedDiscount() {
      // console.log('$scope.companiesClientGroup: ', $scope.companiesClientGroup, $scope.clientGroups);
      if ($scope.clientGroups && $scope.clientGroups.length) {
        for (var j = 0; j < $scope.clientGroups.length; j++) {
          var currentClientGroup = $scope.clientGroups[j];
          // console.log('j: ', j, $scope.companiesClientGroup.length, currentClientGroup.companiesfordiscount);

          if ($scope.companiesClientGroup && $scope.companiesClientGroup.length && currentClientGroup.companiesfordiscount &&
            currentClientGroup.companiesfordiscount.length && currentClientGroup.companiesfordiscount[0]) {
            var givenClientGroupDiscount = currentClientGroup.companiesfordiscount
            if (!givenClientGroupDiscount[0].collections || !givenClientGroupDiscount[0].collections.length) {
              // console.log(j, 'Inside if part');
              currentClientGroup.companies[0].collections = $scope.companiesClientGroup;
            } else {
              // console.log(j, 'Inside else part: ', JSON.parse(JSON.stringify($scope.companiesClientGroup)));
              // Here we go
              var originalClientGroupDiscount = JSON.parse(JSON.stringify($scope.companiesClientGroup));
              var selelcted = currentClientGroup.companiesfordiscount[0].collections;
              // console.log('selelcted: ', j, selelcted.length, originalClientGroupDiscount.length);
              for (var i = 0; i < originalClientGroupDiscount.length; i++) {
                for (var k = 0; k < selelcted.length; k++) {
                  // console.log('---->', i, k, originalClientGroupDiscount[i], selelcted[k]);
                  if (originalClientGroupDiscount[i].collectionId === selelcted[k].collectionId) {
                    originalClientGroupDiscount[i].discount = selelcted[k].discount || 0;
                    // console.log('Matched: ', originalClientGroupDiscount[i].discount);
                  }
                }

              }

              currentClientGroup.companies[0].collections = originalClientGroupDiscount;

            }

          }
        }
      }
    }

    $scope.fetchCompnayClientGroupCollection = function () {
      var body = { companyId: $rootScope.currentCompany._id }
      $scope.loading = true;
      $clientGroup.fetchCompnayClientGroupCollection(body).then(function (companiesClientGroup) {
        $scope.loading = false
        $scope.companiesClientGroup = [];
        if (companiesClientGroup && companiesClientGroup.data && companiesClientGroup.data.data && companiesClientGroup.data.data.length) {
          $scope.companiesClientGroup = companiesClientGroup.data.data;
          // console.log('companiesClientGroup ', $scope.companiesClientGroup);

          $scope.companiesClientGroup = $scope.companiesClientGroup.map(function (el) {
            el.collectionId = el._id;
            el.discount = 0;
            return el;
          });

        }
        fetchTheManipulatedDiscount();
      }).catch(function (error) {
        $scope.loading = false
        console.error('Error: ', error);
      })
    }

    $scope.addNewClientGroup = function () {
      $scope.clientGroup = {}
      $scope.newClientGroup = true
    }

    $scope.getOwnClientGroupsCollections = function (company_id) {
      $collection.getOwnClientGroupsCollections(company_id)
        .then(function (companyClientGroupsCollection) {
          $scope.allClientGroupCollections = companyClientGroupsCollection.data
          // console.log('Current Company collections', $scope.allClientGroupCollections)
        })
        .catch(function (error) {
          console.error('Error in Get Company Brands', error)
        })
    }

    $scope.saveNewClientGroup = function (clientGroup) {
      // save new client-group in database
      // console.log('clientgroup parametere: ', clientGroup);
      clientGroup.uploader = { company_id: $rootScope.currentCompany._id }
      $scope.alertdanger = false
      if (clientGroup && clientGroup.name && clientGroup.description && clientGroup.uploader && clientGroup.uploader.company_id &&
        ((clientGroup.entryMethodCustomerValue && clientGroup.discount) || (!clientGroup.entryMethodCustomerValue && clientGroup.storageFactor))) {
        // if (clientGroup.entryMethodCustomerValue) delete clientGroup.storageFactor;
        // if (!clientGroup.entryMethodCustomerValue) delete clientGroup.discount;
        $scope.loading = true
        var selectedCollections = $scope.companiesClientGroup.filter(function (el) { return el.discount > 0 }).map(function (el) {
          return { collectionId: el._id, discount: el.discount }
        });
        // console.log("--------- saveNewClientGroup ------------------", clientGroup, selectedCollections);
        clientGroup.companiesClientGroup = selectedCollections;
        clientGroup.companyId = $rootScope.currentCompany._id;
        $clientGroup.saveNewClientGroup(clientGroup)
          .then(function (clientGroup) {
            $scope.newClientGroup = false
            $scope.clientGroup = {}
            $scope.getOwnClientGroups($rootScope.currentCompany._id)
          })
          .catch(function (error) {
            console.error('Error', error)
            $scope.loading = true
          })
      } else {
        $scope.alertdanger = true
        $scope.erromessage = 'Please fill proper value'
      }
    }

    $scope.updateClientGroup = function (clientGroup, index) {
      console.log('hello there updateClientGroup called: ', index, $scope.clientGroups[index])

      $scope.loading = true
      var body = {
        clientGroupId: clientGroup._id,
        name: clientGroup.name,
        description: clientGroup.description,
        entryMethodCustomerValue: clientGroup.entryMethodCustomerValue,
        discount: clientGroup.discount,
        storageFactor: clientGroup.storageFactor,
        companies: clientGroup.companies,
        companyId: $rootScope.currentCompany._id
      }
      $clientGroup.updateClientGroup(body)
        .then(function (clientGroup) {
          // $scope.getOwnClientGroups($rootScope.currentCompany._id);
          $scope.loading = false
        })
        .catch(function (error) {
          console.error('Error', error)
          $scope.loading = false
        })
    }

    $scope.removeClientGroups = function (clientGroup_id) {
      console.log('---------------- removeClientGroups -------------------')

      $clientGroup.removeClientGroups(clientGroup_id, $rootScope.currentCompany._id)
        .then(function (success) {
          console.log('removeClientGroups', success)
          $scope.getOwnClientGroups($rootScope.currentCompany._id)
        })
        .catch(function (error) {
          console.error('Error In RemoveClientGroups ', error)
        })
    }

    $scope.copyAsNewClientGroups = function (clientGroup) {
      console.log('Client Groups')
      // It will to implement at last;
    }

    $scope.searchInClientGroupCollection = function (str) {
      return searchItems(str)
    }

    var searchItems = function (str) {
      $scope.tempName = str

      var matches = []

      var addNewCollectionItem = $productConfig.addNewCollection()

      var clientGroups = $scope.allClientGroupCollections

      var clientGroupsLength = clientGroups.length
      console.log('Hey product handling guy', clientGroupsLength)
      if (clientGroups && clientGroupsLength > 0) {
        clientGroups.forEach(function (item) {
          console.log('$rootScope', item[$rootScope.language], item[$rootScope.language].name)
          if ((item && item[$rootScope.language] && item[$rootScope.language].name && item[$rootScope.language].nameSlug) &&
            ((item[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
              (item[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0))) {
            matches.push(item)
          }
        })
      }

      matches.push(addNewCollectionItem)

      return matches
    }

    $scope.clientGroupCollectionSelected = function (selected) {
      if (selected && selected.originalObject && selected.originalObject._id) {
        console.log('selected Client Group collections', JSON.stringify(selected, null, 2))
        $scope.collectionId = selected.originalObject._id
        console.log('collection Id', $scope.collectionId)
        console.log('client-group-Id', $scope.clientGroupId)
        $scope.addClientGroupInCollection($scope.collectionId, $scope.clientGroupId)
      } else {
        console.log('Can Create New collections', selected)
        assignNameToBlank(selected.originalObject, selected.title)
      }
    }

    function assignNameToBlank(collection, value) {
      $scope.addNewCollectionForm = true
      $scope.collection = collection

      $scope.collection.en.name = $scope.tempName
      $scope.collection.de.name = $scope.tempName
      $scope.collection.nl.name = $scope.tempName
      $scope.collection.fr.name = $scope.tempName
      $scope.collection.es.name = $scope.tempName

      $scope.collection.isVerified = false
      $scope.collection.usedByBrand = false
      $scope.collection.privateLabel = false
      $scope.collection.variantsCollection = false
      $scope.collection.isClientGroup = true
      $scope.collection.variantBase = []
      $scope.collection.clientGroups = []
      console.log('product handling guy Please Check here collection', $scope.collection)
    }

    $scope.addClientGroupInCollection = function (collectionId, clientGroupId) {
      console.log(' ----------------- addClientGroupInCollection ----------------- ')
      console.log('collections Id', collectionId)
      console.log('Client GroupId', clientGroupId)
      $collection.addClientGroupsInClientGroupCollections(collectionId, clientGroupId, $rootScope.currentCompany._id)
        .then(function (updatedCollections) {
          console.log('Add This Client group to Client Group collections', updatedCollections)

          $scope.getOwnClientGroups($rootScope.currentCompany._id)
          // Here have to do some logic for not do multipul request
        })
        .catch(function (error) {
          console.error('Error', error)
        })
    }

    $scope.focusIn = function (x) {
      $scope.clientGroupId = x
    }

    $scope.getClientGroupsWiseClientGroupCollections = function (clientGroupId, index) {
      $collection.getClientGroupsWiseClientGroupCollections(clientGroupId, $rootScope.currentCompany._id)
        .then(function (collections) {
          console.log('product handling guy Check this', collections)
          $scope.clientGroups[index].collections = collections.data
        })
        .catch(function (error) {
          console.error('Error ==>', error)
        })
    }

    $scope.checkCollectionName = function (name, language) {
      console.log('Please Check enterd Name', name)
      name = name.trim()
      name = name.toLowerCase()

      if (name == 'services') {
        $scope.errorLog[language] = true
        $scope.errorMessage = true
      } else {
        $scope.errorLog[language] = false
        $scope.errorMessage = false
      }
    }

    $scope.changeCollectionName = function (tempName) {
      if (tempName.toLowerCase() == 'services') {
        $scope.errorMessage = true
        $scope.errorLog = {
          en: true,
          nl: true,
          de: true,
          fr: true,
          es: true
        }
      } else {
        $scope.errorMessage = false
        $scope.errorLog = {
          en: false,
          nl: false,
          de: false,
          fr: false,
          es: false
        }
      }

      $scope.collection.en.name = tempName
      $scope.collection.de.name = tempName
      $scope.collection.nl.name = tempName
      $scope.collection.fr.name = tempName
      $scope.collection.es.name = tempName
    }

    $scope.getTheCollectionName = function (collection) {
      var collectionName = ''
      if (collection.hasOwnProperty($rootScope.language)) { collectionName = collection[$rootScope.language].name } else if (collection.hasOwnProperty('en.name')) { collectionName = collection.en.name } else if (collection.hasOwnProperty('nl.name')) { collectionName = collection.nl.name } else if (collection.hasOwnProperty('de.name')) { collectionName = collection.de.name } else if (collection.hasOwnProperty('fr.name')) { collectionName = collection.fr.name } else if (collection.hasOwnProperty('name') && collection.name != '') { collectionName = collection.name } else { collectionName = 'NO NAME' }
      return collectionName
    }

    $scope.addNewClientGroupCollection = function (collections) {
      console.log('Add New collection ===>', collections)
      collections.companyId = $rootScope.currentCompany._id;
      $collection.checkClientGroupCollectionName(collections)
        .then(function (status) {
          if (status && status.status) {
            $scope.errorMessage = false
            $scope.errorLog = status.data
            $collection.addNewCollection(collections, null, $scope.currentCompanyId)
              .then(function (success) {
                $scope.addNewCollectionForm = false
                console.log('product handling guy Please check this added new collection', success.collection)
                $scope.addClientGroupInCollection(success.collection._id, $scope.clientGroupId)
              })
              .catch(function (error) {
                console.error('Error In adding New Collection in database', error)
              })
          } else {
            $scope.errorMessage = true
            $scope.errorLog = status.error
          }
        })
        .catch(function (error) {
          console.log('Error', error)
        })
    }

    $scope.clientGroupsNameUnique = function (name, clientGroupId) {
      console.log('Client Group Name')
      $clientGroup.clientGroupsNameUnique(name, clientGroupId, $rootScope.currentCompany._id)
        .then(function (status) {
          console.log('status ===>', status)
          if (status) {
            $scope.isClientGroupNameValid = true
          } else {
            $scope.isClientGroupNameValid = false
          }
        })
        .catch(function (error) {
          console.log('Error ====>', error)
        })
    }
  }])
