prismanoteApp.controller('retailerGiftcardDetailController', ['$scope', '$q', '$rootScope', '$api', '$stateParams', '$uibModal', '$state', '$printer', '$pdf', '$language', '$retailer', 'prompt', 'Lightbox', '$transaction', 'dialogs', '$filter', '$customer', '$employee', '$shop', '$jspm',
  function ($scope, $q, $rootScope, $api, $stateParams, $uibModal, $state, $printer, $pdf, $language, $retailer, prompt, Lightbox, $transaction, dialogs, $filter, $customer, $employee, $shop, $jspm) {
    $scope.alert = null
    $scope.totalTax = 0
    $scope.printEnabled = false

    $scope.back = function () {
      window.history.back()
    }

    $scope.printThermal = function () {
      $printer.printGiftcard($scope.transaction.transactionTotal - $scope.transaction.giftCardReedemAmount,
        $scope.transaction.number,
        $scope.transaction.dateCreated,
        $scope.transaction.special.repairNumber)
        .then(function() {
          console.log('Thermal giftcard printed')
          toastr.success($language.translate('PRINT_SUCCESSFUL'))
        })
        .catch(function (reason){
          console.error('Error while printing thermal giftcard', reason)
        })
    }

    if($rootScope.currentShop) {
      $printer.getPageDefinition('GIFTCARD')
        .then(function (doc) {
          $scope.jspm = !$rootScope.currentShop.printNode || $rootScope.currentShop.printNode.enabled === false
          $scope.giftcardDoc = doc
          if($scope.jspm) {
            $scope.printEnabled = doc.printerName && doc.printerName !== ''
          } else {
            $scope.printEnabled = doc.printNode && doc.printNode.printerId
          }
        })
        .catch(function (reason) {
          console.error('Error while fetching giftcard page definition for printing', reason)
        })
    }

    $scope.openCustomer = function () {
      $state.go('retailer.customer', {
        id: $scope.transaction.customer._id
      })
    }

    $scope.downloadGiftcard = function (skipPrint) {
      $scope.alert = null
      $pdf.downloadPdf('generate-giftcard', {
        shopId: $rootScope.currentShop._id,
        transactionId: $scope.transaction._id,
        documentName: 'GIFTCARD',
        deviceId: $rootScope.currentDevice._id,
        documentId: $scope.giftcardDoc.documentId
      }, $language.translate('GIFTCARD') + '.pdf', null, skipPrint)
        .then(function () {

        })
        .catch(function (reason) {
          console.error('Error while generating giftcard', reason)
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(reason)
          }
        })
    }

    $scope.openChangeCustomerModal = function () {
      $customer.openSearchCustomerModal(false, false)
        .then(function (result) {
          $scope.transaction.customer = result
          if (!$scope.transaction.log) $scope.transaction.log = []
          $scope.transaction.log.push({
            date: new Date(),
            user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
            text: 'Customer Changed',
            isFor: 'retailerPortal',
            eType: 'retailer'
          })
          $scope.updateTransaction()
        })
        .catch(function (reason) {
          console.error('REASON', reason)
        })
    }

    $scope.updateTransaction = function () {
      if ((!$scope.transaction.createdBy || !$scope.transaction.createdBy.name) && $scope.isRemovedAssitedBy) return
      $scope.loading = true
      if (!$scope.transaction.log) $scope.transaction.log = []
      if ($scope.intakeDateChanged) {
        var log = {
          date: new Date(),
          user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
          text: 'Intake date changed',
          isFor: 'retailerPortal',
          eType: 'retailer'
        }
        $scope.transaction.log.push(log)
      }

      if ($scope.repairDateChanged) {
        var log = {
          date: new Date(),
          user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
          text: 'Repair date changed',
          isFor: 'retailerPortal',
          eType: 'retailer'
        }
        $scope.transaction.log.push(log)
      }

      $scope.transaction.shopId = { _id: $scope.transaction.shopId._id }

      $transaction.updateTransaction($scope.transaction, null, true)
        .then(function (result) {
          $scope.loading = false
          $scope.getTransaction()
        })
        .catch(function (reason) {
          $scope.loading = false
          dialogs.error($language.translate('ERROR'), $language.translate('AN_ERROR_OCCURED') + ': ' + reason, { size: 'sm' })
        })
    }

    $scope.getTotalPrice = function () {
      var total = 0; var counter = 0; var vat = 0
      if($scope.transaction && $scope.transaction.details && $scope.transaction.details.length){
        for (var i = 0; i < $scope.transaction.details.length; i++) {
          vat += (($scope.transaction.details[i].price * ($scope.transaction.details[i].priceVat / 100)) * $scope.transaction.details[i].quantity)
          total += $scope.transaction.details[i].total
          counter++
          if (counter == $scope.transaction.details.length) {
            $scope.totalTax = vat
            return total
          }
        }
      }
    }

    $scope.payGiftcard = function () {
      var detail = $scope.transaction.details[0]
      $state.go('retailer.till', {
        customer: $scope.transaction.customer,
        items: [
          {
            name: $language.translate('PAYMENT_FOR_GIFTCARD'),
            priceVat: detail.priceVat,
            quantity: 1,
            price: detail.price,
            total: detail.amount,
            margin: 0,
            purchasePrice: 0,
            discount: false,
            giftCardTransaction: $scope.transaction._id,
            type: 'giftcard-sell',
            cardNumber: detail.giftCardNumber
          }
        ],
        transactionId: $scope.transaction._id,
        transactionNumber: $scope.transaction.number,
        refund: false,
        checkout: true
      })
    }

    $scope.updateStatus = function (status) {
      prompt({
        title: $language.translate('ALERT'),
        message: $language.translate('MAKE_SURE_YOU_CAN_NOT_CHANGE_ANYTHING_IN_THIS_TRANSACTION_ONCE_MARK_AS_CANCELLED_OR_COMPLETED_EVEN_CUSTUMER_OR_YOU_CAN_NOT_USE_THIS_GIFTCARD_NUMBER_AGAIN')
      })
        .then(function (data) {
          if (data && !data.cancel) {
            return $transaction.updateGiftCardStatus(status, $scope.transaction._id, $rootScope.currentShop._id)
          } else {

          }
        })
        .then(function (updatedResponse) {
          if (updatedResponse) {
            $scope.alert = {
              type: 'success',
              msg: $language.translate(updatedResponse.data.message)
            }
            $scope.transaction.status = status
          }
        })
        .catch(function (error) {
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(error.data.message)
          }
        })
    }

    $scope.getTransaction = function () {
      $transaction.getTransaction($stateParams.transactionId, true, false, $rootScope.currentShop._id)
        .then(function (transaction) {
          if (transaction.length && transaction.length > 0) {
            $scope.transaction = transaction[0]
          } else {
            $scope.transaction = transaction
          }
          if ($scope.transaction) {
            $scope.originalTransaction = JSON.parse(JSON.stringify($scope.transaction))
          }


          if ($scope.transaction) {
            $scope.originalTransaction = JSON.parse(JSON.stringify($scope.transaction))
          }
          $scope.loading = false
        })
        .catch(function (reason) {
          console.error('reason', reason)
          if (typeof reason === 'object') {
            $scope.alert = {
              type: 'danger',
              msg: $language.translate('UNKOWN_ERROR_OCCURED')
            }
            return
          }
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(reason)
          }

          $scope.loading = false
        })
    }

    $scope.deleteTransaction = function () {
      var dlg = dialogs.confirm($language.translate('CANCEL'), $language.translate('ARE_YOU_SURE_TO_DELETE_THIS_TRANSACTION'), { size: 'sm' })
      dlg.result.then(function (btn) {
        if (btn === 'yes') {
          $transaction.deleteTransaction($scope.transaction._id, true)
            .then(function (res) {
              $state.go('retailer.service')
            })
            .catch(function (reason) {
              console.error('Error deleting transaction', reason)
            })
        }
      })
        .catch(function (reason) {
        })
    }



  }])
