prismanoteApp.controller('retailerTransactionsController', ['$scope', '$rootScope', '$uibModal', '$api', '$retailer','$timeout', '$state', '$window', '$language', '$data', 'cookieService', '$log', '$transaction', '$customer', 'scanner',
  function ($scope, $rootScope, $uibModal, $api, $retailer,$timeout, $state, $window, $language, $data, cookieService, $log, $transaction, $customer, scanner) {
    $scope.newdata = []
    $scope.pageSize = '10'
    $scope.transactionsGet = true;
    $scope.selectedFilter = 'ALL_ITEMS'
    $scope.filterType = 'ALL_ITEMS'
   // $scope.currentPage
    cookieService.getCookie('transactionsPageSize')
      .then(function (pageSize) {
        if(pageSize && pageSize !== '' && pageSize !== '0') {
          $scope.pageSize = pageSize
        }else {
          $scope.pageSize = '10'
        }
      })
      .catch(function () {
        $scope.pageSize = '10'
      })

    cookieService.getCookie('transactionsCurrentPage')
      .then(function (currentPage) {
        $scope.saveCurrentPage(currentPage)
        $scope.currentPage = currentPage
      })
      .catch(function () {
        $scope.currentPage = 1
      })

      cookieService.getCookie('transactionsTypeFilter')
      .then(function (filterType) {
        if (!filterType || filterType === '') {
          $scope.selectedFilter = 'ALL_ITEMS'
          $scope.filterType = 'ALL_ITEMS'
          $scope.setFilter($scope.filterType)
        } else {
          $scope.selectedFilter = filterType
          $scope.filterType = filterType
          $scope.setFilter($scope.filterType)
        }
      })
      .catch(function () {
        $scope.filterType = 'ALL_ITEMS'
        $scope.selectedFilter = 'ALL_ITEMS'
        $scope.setFilter($scope.filterType)
      })

      cookieService.getCookie('transactionsDateFilterEnd')
      .then(function (endDate) {
        if (endDate !== '0' && moment(new Date(parseFloat(endDate))).isValid()) {
          $scope.dateFilter.end = new Date(parseFloat(endDate))
        }
      })
      .catch(function () {
      })

    cookieService.getCookie('transactionsDateFilterStart')
    .then(function (startDate) {
      if (startDate !== '0' && moment(new Date(parseFloat(startDate))).isValid()) {
        $scope.dateFilter.start = new Date(parseFloat(startDate))
      }
    })
    .catch(function () {
    })

    cookieService.getCookie('transactionsPaymentTypeFilter')
      .then(function(paymentFilter) {
        cookieService.getCookie('transactionsPaymentBankTypeFilter')
          .then(function(bankFilter) {
            $scope.filterPaymentType = paymentFilter
            $scope.bankFilterType = bankFilter
          })
      })

    if($scope.pageSize === undefined) {
      $scope.pageSize = '10'
    }
    if($scope.currentPage === undefined) {
      $scope.currentPage = 1
    }
    $scope.totalTransactions = 0;
    $scope.mainData = [];
    $scope.mainTotal = 0;

    
    $scope.startDate = ''
    $scope.endDate = ''
    $scope.selectedPaymentFilter = 'ALL_ITEMS'
    $scope.statusBankFilter = null;
    $scope.paymentData = null
    $scope.paymentBankFilterData = null
    $scope.cashAmount = 0
    $scope.cardAmount = 0
    $scope.giftAmount = 0
    $scope.bankAmount = 0
    $scope.insuranceAmount = 0

    app.directive('ngEnter', function () { //a directive to 'enter key press' in elements with the "ng-enter" attribute
      return function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
          if (event.which === 13) {
            scope.$apply(function () {
              scope.$eval(attrs.ngEnter);
            });

            event.preventDefault();
          }
        });
      };
    })

    $scope.isFilterDataFl = 0

    $scope.dateFilter = {
      startDateOpen: false,
      endDateOpen: false
    }

    $scope.dateOptions = {
      maxDate: new Date(),
      startingDay: 1
    }

    $scope.customerName = function(customer) {
      return $customer.makeCustomerName(customer)
    }

    $scope.popover = function (item) {
      var itemList = [] 
      var alertString = [];

      if(item.createdBy && item.createdBy.name) {
        alertString.push(String($language.translate('EMPLOYEE') + ": "+item.createdBy.name))
      }

      if (item._id) {
        if(item.details && item.details.length > 0) {
          alertString.push($language.translate('PRODUCTS')+":")

          for (var i = 0; i < item.details.length; i++) {
            itemList.push(item.details[i].name)
            alertString.push('- '+item.details[i].name +' | €'+ (item.details[i].originalTotal ? item.details[i].originalTotal : item.details[i].total))
            if(item.details.length === itemList.length) {
              return alertString.join("<br>")
            }
          }
        }
      }
    }
    
    $scope.checkMethod = function(data) {
      if (!data) {
        return
      }
				if(data && data.length > 0) {
          var result = ''
            if(data.length === 1) {
              if(data[0].method === 'bankAmount' || data[0].method === 'insurance') {
                if(!data[0].bankPaymentAcknowledgement) {
                  result += data[0].method + ' (Pending)'
                  //-result += $language.translate(data[0].method.toUpperCase()) + $language.translate('PENDING')
                } else {
                  result += data[0].method + ' (Paid)'
                  //-result += $language.translate(data[0].method.toUpperCase()) + $language.translate('PAID')
                }
              } else {
               // var resultData = $language.translate(data[0].method)
                result += data[0].method.toUpperCase()
              }
              return result
            } else {
              var counter =0;
              for (var i = 0; i < data.length; i++) {
                counter++;
                //result += $language.translate(data[i].method.toUpperCase()) + ' / '
                result += data[i].method.toUpperCase() + ' / '
                if(counter === data.length) {
                  result = result.substring(0, result.length - 1);
                  return result
                }
              }
            }
         
				}
    }

    $scope.getReceiptOrInvoiceNumber = function(transaction){
      if(transaction.isInvoice && transaction.invoiceNumber) {
        return transaction.invoiceNumber
      }

      if(transaction.receipt && transaction.receipt.number) {
        return transaction.receipt.number
      }

      return '-'
    }

    $scope.sortBy = function (propertyName, reverse) {
      $scope.reverse = reverse || (($scope.propertyName === propertyName) ? !$scope.reverse : false)
      $scope.propertyName = propertyName
    }

    $scope.openDate = function (type) {
      $scope.dateFilter[type] = !$scope.dateFilter[type]
    }

    $scope.setPaymentFilter = function (filter, bankFilter) {
     cookieService.setCookie('transactionsPaymentTypeFilter=' + filter, 1800)
     cookieService.setCookie('transactionsPaymentBankTypeFilter=' + bankFilter, 1800)
      $scope.filterPaymentType = filter
      $scope.bankFilterType = bankFilter
      $scope.setMainPaymentFilter($scope.filterPaymentType, $scope.bankFilterType)
    }

    $scope.setFilter = function (filter) {
      $scope.filterType = filter
      $scope.selectedFilter = filter
      if ($scope.filterType == undefined || $scope.filterType == "undefined" || !$scope.filterType) {
        $scope.filterType = 'ALL_ITEMS'
      }
       $scope.setMainFilter($scope.filterType)
       cookieService.setCookie('transactionsTypeFilter=' + filter, 1800)
    }

    $scope.getDateFilteredTransactions = function (startDate, endDate) {
      $api.get('filter-transactions/' + $rootScope.currentShop._id + '?limit=' + $scope.pageSize + '&pageNumber=' + $scope.currentPage + '&startDateFilter=' + startDate + '&endDateFilter=' + endDate)
      .then(function (response) {
        if (response && response.data && response.data.transactions && response.data.transactions.total == 0) {
          $scope.cashAmount = 0
          $scope.cardAmount = 0
          $scope.giftAmount = 0
          $scope.bankAmount = 0
          $scope.insuranceAmount = 0
          $scope.goldAmount = 0
          $scope.transactions = []
          $scope.totalTransactions = 0
        } else {
          $scope.cashAmount = 0
          $scope.cardAmount = 0
          $scope.giftAmount = 0
          $scope.bankAmount = 0
          $scope.insuranceAmount =0
          $scope.goldAmount = 0
          $scope.transactions = response.data.transactions.transactions
          $scope.totalTransactions = response.data.transactions.total
          $scope.filteredData = response.data.transactions.filteredData
          if (response && response.data) {
            for(var i = 0; i < $scope.transactions.length; i++) {
              if($scope.transactions[i].receipt && $scope.transactions[i].receipt.payments) {
                for(var a = 0; a < $scope.transactions[i].receipt.payments.length; a++) {
                  if($scope.transactions[i].receipt.payments[a].amount > 0 && $scope.transactions[i].receipt.payments[a].method.toUpperCase() === 'PAYLATER') {
                    $scope.newdata.push($scope.transactions[i])
                  }
                }
              }
              //Customerdata is an array here, so parse the customerinformation a different way
              if($scope.transactions[i].customer && $scope.transactions[i].customer[0]) {
                $scope.transactions[i].customer = {
                  salutation: $scope.transactions[i].customer[0].salutation,
                  firstName: $scope.transactions[i].customer[0].firstName,
                  prefix: $scope.transactions[i].customer[0].prefix,
                  lastName: $scope.transactions[i].customer[0].lastName,
                  _id: $scope.transactions[i].customer[0]._id,
                }
              }
            }

            $scope.filteredData.forEach(function (value) {
              if (value.receipt && value.receipt.payments) {
                value.receipt.payments.forEach(function (element) {
                  if (element.method && element.method.toLowerCase() === 'cash') {
                    $scope.cashAmount += element.amount
                    $scope.cashAmount = $scope.cashAmount - element.change
                  }
                  if (element.method && element.method.toLowerCase() === 'card') {
                    $scope.cardAmount += element.amount
                  }
                  if (element.method && element.method.toLowerCase() === 'giftcard') {
                    $scope.giftAmount += element.amount
                  }
                  if (element.method === 'bankAmount') {
                    $scope.bankAmount += element.amount
                  }
                  if(element.method === 'insurance') {
                    $scope.insuranceAmount += element.amount
                  }
                  if (element.method && element.method.toLowerCase() === 'gold-purchase') {
                    $scope.goldAmount += element.amount
                  }
                })
              }
            })
          }

          $scope.sortBy('dateCreated', true)
          cookieService.getCookie('transactionsCurrentPage')
            .then(function (currentPage) {
              $scope.currentPage = currentPage
            })
            .catch(function () {
              $scope.currentPage = 1
            })
        }
       
      })
      .catch(function (reason) {
        $log.error('Error while fetching transactions', reason)
        $scope.loading = false
        toastr.error(reason.message)
      })
    }

    $scope.saveDateFilter = function (date, isEndDate) {
      var mainDate = new moment(date).format('YYYY-MM-DD');

      if (isEndDate) {
        $scope.endDate = mainDate
      } else {
        $scope.startDate = mainDate
      }
      if (date == null) {
        $scope.dateFilter.start = undefined
        $scope.dateFilter.end = undefined
        $scope.getTransactions(true)
      }
      else if ($scope.startDate && $scope.endDate) {
        
        $timeout(function () {

          $scope.getDateFilteredTransactions($scope.startDate, $scope.endDate)
          
          }, 500);
      }/*  else {
        $scope.getTransactions(true)
      } */
       cookieService.setCookie((isEndDate ? 'transactionsDateFilterEnd=' : 'transactionsDateFilterStart=') + new Date(date).valueOf(), 1800)
    }

    $scope.savePageSize = function (pageSize) {
      if ($scope.searchquery) {
        $scope.searching($scope.searchquery, null)
      } else if($scope.statusBankFilter) {
        $scope.setPaymentFilter($scope.paymentData, $scope.paymentBankFilterData)
      } 
      else if ($scope.startDate && $scope.endDate) {
          $scope.saveDateFilter($scope.startDate, false)
        } else if ($scope.selectedFilter == 'ALL_ITEMS') {
          $scope.getTransactions(true)
        } else {

          $scope.setFilter($scope.selectedFilter)
        }

       cookieService.setCookie('transactionsPageSize=' + pageSize, 31536000)
    }

    $scope.saveCurrentPage = function (currentPage) {
      if ($scope.searchquery) {
        $scope.searching($scope.searchquery, null)
      } else if($scope.statusBankFilter) {
        $scope.setPaymentFilter($scope.paymentData, $scope.paymentBankFilterData)
      }  else
        if ($scope.startDate && $scope.endDate) {
          $scope.saveDateFilter($scope.startDate, false)
        } else if ($scope.selectedFilter == 'ALL_ITEMS') {
            $scope.getTransactions(true)
          } else {

            $scope.setFilter($scope.selectedFilter)
          }
      // $scope.getTransactions(true)
       cookieService.setCookie('transactionsCurrentPage=' + currentPage, 1800)
    }

    $scope.getInitialFilterData = function (params) {
      var filterTypeCookie = cookieService.getCookie('transactionsTypeFilter')
      var transactionsCurrentPageFilter = cookieService.getCookie('transactionsCurrentPage')
      var filterPageSize = cookieService.getCookie('transactionsPageSize')
      var filterCurrentPage = cookieService.getCookie('transactionsCurrentPage')
      var filterEndDate = cookieService.getCookie('transactionsDateFilterEnd')
      var filterStartDate = cookieService.getCookie('transactionsDateFilterStart')
      if (filterTypeCookie.$$state.value == "NO_COOKIE_FOUND" &&
      transactionsCurrentPageFilter.$$state.value == "NO_COOKIE_FOUND" &&
      filterPageSize.$$state.value == "NO_COOKIE_FOUND" && filterCurrentPage.$$state.value == "NO_COOKIE_FOUND" &&
      filterEndDate.$$state.value == "NO_COOKIE_FOUND" && filterStartDate.$$state.value == "NO_COOKIE_FOUND") {
      
        $scope.selectedFilter = 'ALL_ITEMS'
        $scope.filterType = 'ALL_ITEMS'
        $scope.currentPage = 1
        $scope.pageSize = "10"
        $scope.setFilter($scope.filterType)
        $scope.getTransactions(true)
      }
      cookieService.getCookie('transactionsPageSize')
        .then(function (pageSize) {
          if(!pageSize || pageSize === '') {
            $scope.pageSize = '10'
          } else {
            $scope.pageSize = pageSize
          }
        })
        .catch(function () {
          $scope.pageSize = '10'
        })

      cookieService.getCookie('transactionsCurrentPage')
        .then(function (currentPage) {
          $scope.saveCurrentPage(currentPage)
          $scope.currentPage = currentPage
        })
        .catch(function () {
          $scope.currentPage = 1
        })

      cookieService.getCookie('transactionsTypeFilter')
        .then(function (filterType) {
          $scope.filterType = filterType
          
            $scope.setFilter($scope.filterType)
        })
        .catch(function () {
          $scope.filterType = 'ALL_ITEMS'
        })

      cookieService.getCookie('transactionsDateFilterEnd')
        .then(function (endDate) {
          if (endDate !== '0' && moment(new Date(parseFloat(endDate))).isValid()) {
            $scope.dateFilter.end = new Date(parseFloat(endDate))
            $scope.saveDateFilter($scope.dateFilter.end, true)
          }

        })
        .catch(function () {
        })

      cookieService.getCookie('transactionsDateFilterStart')
        .then(function (startDate) {

          if (startDate !== '0' && moment(new Date(parseFloat(startDate))).isValid()) {
            $scope.dateFilter.start = new Date(parseFloat(startDate))
          }
          $scope.saveDateFilter($scope.dateFilter.start, false)
          /* if ($scope.dateFilter.start && $scope.dateFilter.end) {
            var startDateFilter = new moment($scope.dateFilter.start).format("YYYY-MM-DD");
            var endDateFilter = new moment($scope.dateFilter.end).format("YYYY-MM-DD");
            $scope.getDateFilteredTransactions(startDateFilter, endDateFilter)
          } */
        })
        .catch(function () {
        })

      cookieService.getCookie('transactionsPaymentTypeFilter')
        .then(function (paymentType) {
          $scope.paymentFilterType = paymentType
          cookieService.getCookie('transactionsPaymentBankTypeFilter')
            .then(function (paymentBankType) {
              $scope.bankFilterType = paymentBankType
              if ($scope.paymentFilterType && $scope.bankFilterType) {
                $scope.setMainPaymentFilter($scope.paymentFilterType, $scope.bankFilterType)
              }
            })
            
          })
          .catch(function () {
          })  
    
    }

    $scope.getTransactions = function (force) {
      $scope.loading = true
      $scope.searchquery = ''
      var filterTypeCookie = cookieService.getCookie('transactionsTypeFilter')
      if (filterTypeCookie.$$state.value == "NO_COOKIE_FOUND") {
        $scope.selectedFilter = 'ALL_ITEMS'
        $scope.filterType = 'ALL_ITEMS'
      }
      if ($scope.filterType == undefined) {
        $scope.filterType = 'ALL_ITEMS'
      }
      if ($scope.filterType == undefined) {
        $scope.filterType = 'ALL_ITEMS'
      }

      $retailer.getShop()
        .then(function (shop) {
          if($scope.transactionsGet) {
            $scope.transactionsGet = false;
            $api.get('all-transactions/' + shop._id + '?limit=' + $scope.pageSize + '&pageNumber=' + $scope.currentPage + '&statusFilter=' + $scope.filterType + '&exclude=product-reserve')
              .then(function (response) {
                $scope.transactionsGet = true;
                $scope.loading = false
                $scope.cashAmount = 0
                $scope.cardAmount = 0
                $scope.giftAmount = 0
                $scope.bankAmount = 0
                $scope.insuranceAmount = 0
                if ($scope.transactions && $scope.totalTransactions > 0) {
                  if (response.data.transactions.transactions.length == 0 && response.data.transactions.total == 0) {
                    $scope.pageSize = "10"
                    $scope.currentPage = 1
                    $scope.saveCurrentPage($scope.currentPage)
                    $scope.getTransactions(true)
                  }
                }
                $scope.transactions = response.data.transactions.transactions// .transactions  + '?limit=' + $scope.pageSize + '&pageNumber=' + $scope.currentPage + '&statusFilter=' + $scope.statusFilter
                $scope.totalTransactions = response.data.transactions.total
                $scope.mainData = $scope.transactions;
                $scope.mainTotal = $scope.totalTransactions
                for(var i = 0; i < $scope.transactions.length; i++) {
                  if($scope.transactions[i].receipt && $scope.transactions[i].receipt.payments) {
                    for(var a = 0; a < $scope.transactions[i].receipt.payments.length; a++) {
                      if($scope.transactions[i].receipt.payments[a].amount > 0 && $scope.transactions[i].receipt.payments[a].method.toUpperCase() === 'PAYLATER') {
                        $scope.newdata.push($scope.transactions[i])
                      }
                    }
                  }
                  if($scope.transactions[i].customers) {
                    $scope.transactions[i].customer = {
                      salutation: $scope.transactions[i].customer.salutation,
                      firstName: $scope.transactions[i].customer.firstName,
                      prefix: $scope.transactions[i].customer.prefix,
                      lastName: $scope.transactions[i].customer.lastName,
                      _id: $scope.transactions[i].customer._id,
                    }
                  }
                }

                $scope.sortBy('dateCreated', true)
                cookieService.getCookie('transactionsCurrentPage')
                  .then(function (currentPage) {
                    $scope.currentPage = currentPage
                  })
                  .catch(function () {
                    $scope.currentPage = 1
                  })
              })
              .catch(function (reason) {
                $log.error('Error while fetching transactions', reason)
                $scope.loading = false
                toastr.error(reason.message)
              })
          }
        })
        .catch(function (reason) {
          $log.error('Error while fetching shop', reason)
        })
    }

    $scope.openTransaction = function (id, type, old) {
      $transaction.openTransaction(id, type, old)
    }
    $scope.newFilter = function () {
      $scope.transactions = $scope.newdata
    }

    $scope.setMainPaymentFilter = function (data, bankFilter) {
      $scope.selectedPaymentFilter = data
      $scope.paymentData = data
      $scope.paymentBankFilterData = bankFilter
      $scope.cashAmount = 0
      $scope.cardAmount = 0
      $scope.giftAmount = 0
      $scope.bankAmount = 0
      $scope.insuranceAmount = 0
      if (data === 'ALL_ITEMS') {
        $scope.paymentData = null
      $scope.paymentBankFilterData = null
        $scope.statusBankFilter = null;
        /* $scope.transactions = $scope.mainData
        $scope.totalTransactions = $scope.mainTotal */
        $scope.getTransactions(true)
      } else {
        if(bankFilter != null) {
          $scope.statusBankFilter = bankFilter;
        } else {
          $scope.statusBankFilter = null;
        }
        $api.get('filter-payment-transactions/' + $rootScope.currentShop._id + '?limit=' + $scope.pageSize + '&pageNumber=' + $scope.currentPage + '&filter=' + data + '&statusFilter=' + $scope.statusBankFilter)
          .then(function (response) {
            $scope.transactions = response.data.transactions.transactions
            $scope.totalTransactions = response.data.transactions.total
            for(var i = 0; i < $scope.transactions.length; i++) {
              if($scope.transactions[i].receipt && $scope.transactions[i].receipt.payments) {
                for(var a = 0; a < $scope.transactions[i].receipt.payments.length; a++) {
                  if($scope.transactions[i].receipt.payments[a].amount > 0 && $scope.transactions[i].receipt.payments[a].method.toUpperCase() === 'PAYLATER') {
                    $scope.newdata.push($scope.transactions[i])
                  }
                }
              }
              $scope.transactions[i].customer = {
                salutation: $scope.transactions[i].customer.salutation,
                firstName: $scope.transactions[i].customer.firstName,
                prefix: $scope.transactions[i].customer.prefix,
                lastName: $scope.transactions[i].customer.lastName,
                _id: $scope.transactions[i].customer._id,
              }
            }

            $scope.sortBy('dateCreated', true)
            cookieService.getCookie('transactionsCurrentPage')
              .then(function (currentPage) {
                $scope.currentPage = currentPage
              })
              .catch(function () {
                $scope.currentPage = 1
              })
          })
          .catch(function (reason) {
            $log.error('Error while fetching transactions', reason)
            $scope.loading = false
            toastr.error(reason.message)
          })
      }
    }

    $scope.refreshTransactions = function () {
      $scope.pageSize = "10"
      $scope.currentPage = 1
      $scope.saveCurrentPage($scope.currentPage)
      $scope.getTransactions(true)
    }
    

    $scope.setMainFilter = function (data) {
      $scope.selectedFilter = data
      if (data == 'ALL_ITEMS') {
        /* $scope.transactions = $scope.mainData
        $scope.totalTransactions = $scope.mainTotal */
        $scope.getTransactions(true)
      } else {
        $api.get('filter-transactions/' + $rootScope.currentShop._id + '?limit=' + $scope.pageSize + '&pageNumber=' + $scope.currentPage + '&filter=' + data)
          .then(function (response) {
            $scope.cashAmount = 0
            $scope.cardAmount = 0
            $scope.giftAmount = 0
            $scope.bankAmount = 0
            $scope.insuranceAmount = 0
            if ($scope.transactions && $scope.totalTransactions > 0) {
              if (response.data.transactions.transactions.length == 0 && response.data.transactions.total == 0) {
                $scope.pageSize = '10'
                $scope.currentPage = 1
                $scope.saveCurrentPage($scope.currentPage)
                $scope.setMainFilter(data)
              }
            }
            $scope.transactions = response.data.transactions.transactions
            $scope.totalTransactions = response.data.transactions.total
            for(var i = 0; i < $scope.transactions.length; i++) {
              if($scope.transactions[i].receipt && $scope.transactions[i].receipt.payments) {
                for(var a = 0; a < $scope.transactions[i].receipt.payments.length; a++) {
                  if($scope.transactions[i].receipt.payments[a].amount > 0 && $scope.transactions[i].receipt.payments[a].method.toUpperCase() === 'PAYLATER') {
                    $scope.newdata.push($scope.transactions[i])
                  }
                }
              }
              //Customerdata is an array here, so parse the customerinformation a different way
              if($scope.transactions[i].customer && $scope.transactions[i].customer[0]) {
                $scope.transactions[i].customer = {
                  salutation: $scope.transactions[i].customer[0].salutation,
                  firstName: $scope.transactions[i].customer[0].firstName,
                  prefix: $scope.transactions[i].customer[0].prefix,
                  lastName: $scope.transactions[i].customer[0].lastName,
                  _id: $scope.transactions[i].customer[0]._id,
                }
              }
            }

            $scope.sortBy('dateCreated', true)
            cookieService.getCookie('transactionsCurrentPage')
              .then(function (currentPage) {
                $scope.currentPage = currentPage
              })
              .catch(function () {
                $scope.currentPage = 1
              })
          })
          .catch(function (reason) {
            $log.error('Error while fetching transactions', reason)
            $scope.loading = false
            toastr.error(reason.message)
          })
      }
    }

    $scope.typeFilter = function (item) {
      if ($scope.filterType === 'ALL_ITEMS') {
        return item.type === 'offer' || item.type === 'shop-purchase' || item.type === 'refund'
      } else {
        if ($scope.filterType === 'OFFER') {
          return item.type === 'offer'
        }
        if ($scope.filterType === 'SHOP_PURCHASE') {
          return item.type === 'shop-purchase'
        }
        if ($scope.filterType === 'REFUND') {
          return item.type === 'refund'
        }
      }
    }

    $scope.$on('changeSelectedShop', function () {
      $scope.getTransactions(true)
    })

    $scope.searchVal = function (val) {
      if (val.length == 0) {
        // $scope.transactions = $scope.customersMainData;
        //  $scope.transactions = $scope.mainData
        //  $scope.totalTransactions = $scope.mainTotal 
        $scope.getTransactions(true)
      }
    }

    $scope.searching = function (value,event) {
      var searchedData = []
      if (event && event.charCode && event.charCode == 13) {
        $scope.currentPage = 1
      }
      if (value) {
        $api.post('transaction/search-transaction', {
          search: value,
          shopId: $rootScope.currentShop._id,
          limit: $scope.pageSize,
          pageNumber: $scope.currentPage,
          customer: null,
          till: false
        })
          .then(function (response) {
            $scope.cashAmount = 0
            $scope.cardAmount = 0
            $scope.giftAmount = 0
            $scope.bankAmount = 0
            $scope.insuranceAmount = 0
            $scope.transactions = response.data.customers.transactions
            $scope.totalTransactions = response.data.customers.total
            for(var i = 0; i < $scope.transactions.length; i++) {
              if($scope.transactions[i].receipt && $scope.transactions[i].receipt.payments) {
                for(var a = 0; a < $scope.transactions[i].receipt.payments.length; a++) {
                  if($scope.transactions[i].receipt.payments[a].amount > 0 && $scope.transactions[i].receipt.payments[a].method.toUpperCase() === 'PAYLATER') {
                    $scope.newdata.push($scope.transactions[i])
                  }
                }
              }
              //Customerdata is an array here, so parse the customerinformation a different way
              if($scope.transactions[i].customer && $scope.transactions[i].customer[0]) {
                $scope.transactions[i].customer = {
                  salutation: $scope.transactions[i].customer[0].salutation,
                  firstName: $scope.transactions[i].customer[0].firstName,
                  prefix: $scope.transactions[i].customer[0].prefix,
                  lastName: $scope.transactions[i].customer[0].lastName,
                  _id: $scope.transactions[i].customer[0]._id,
                }
              }
            }
          })
          .catch(function (reason) {
            $log.error('Error while searching transactions', reason)
          })
      }
    }

    // #region Barcode support

    $scope.$on('scanner-started', function () {
      $scope.barcode = true
    })

    $scope.$on('scanner-ready', function (event, args) {
      if (args.type === 'transaction') {
        $transaction.getTransactionFromBarcode(args.parsedCode)
          .then(function (transaction) {
            $scope.openTransaction(transaction._id, transaction.type, transaction.oldTill)
          })
          .catch(function (reason){
            toastr.error(reason)
          })
      } else {
        toastr.warning($language.translate('SCAN_A_VALID_SHOPPURCHASE_TRANSACTION'))
      }
    })
    // #endregion Barcode support

  }])