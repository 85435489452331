prismanoteApp.controller("addEditServiceModalController", ['$scope', '$uibModalInstance', '$rootScope', '$api', '$data', 'serviceDetail', 'serviceList', '$shop',
    function ($scope, $uibModalInstance, $rootScope, $api, $data, serviceDetail, serviceList, $shop) {
		$scope.service = {};
		$scope.serviceType = serviceDetail.type;
		$scope.serviceList = $scope.serviceType ? serviceList : [];
		if(serviceDetail.service){
			$scope.service['option'] = serviceDetail.service.option
			$scope.service['productOptionList'] = serviceDetail.service.serviceOptions
			$scope.service['_id'] = serviceDetail.service._id
		} else{
			$scope.service = {
				option: 'giftWrap',
				productOptionList: [{
					name: 'DEFAULT',
					priceWithVat: '',
					minProductPrice:'',
					excludeSaleProduct: false,
					setCollection: false,
					excludeProduct: false,
					privateLabelCollections:[]
				}]
			};
		}
		$scope.showAddProductOptionSection = false;
		$scope.serviceError = '';
        $scope.isCollectionUpdated = false;
        $scope.optionName = '';
		$scope.selectedOption = $scope.service.productOptionList[0].name;
		$scope.selectedIndex = 0;

        $scope.addNewServiceOption = function(name){
            $scope.service.productOptionList.push({
				name: name,
				priceWithVat: '',
				minProductPrice:'',
				excludeSaleProduct: false,
				setCollection: false,
				excludeProduct: false,
				privateLabelCollections:[]
			});
			$scope.selectedIndex = $scope.service.productOptionList.length - 1;
			$scope.selectedOption = name;
			$scope.optionName = '';
			$scope.showAddProductOptionSection = false;
		}

		$scope.addNewProductOption = function(){
			$scope.showAddProductOptionSection = true;
		}
		
        $scope.addService = function () {
			var serviceData = JSON.parse(JSON.stringify($scope.service));
			serviceData.serviceOptions = serviceData.productOptionList;
			delete serviceData.productOptionList;
			serviceData.serviceOptions.filter(function (option) {
				option['collections'] = option.privateLabelCollections.map(function(col) { return col._id } )
				delete option.privateLabelCollections;
			});
			$api.post('v2/extra-shop-services', {shopId: $rootScope.currentShop._id, details:serviceData })
				.then(function (response) {
					if(response.status === 200) {
						$uibModalInstance.close('created');
					} 
				}).catch(function(error) {
					console.log(error)
					$scope.serviceError = error.data.message
					setTimeout(function(){
						$scope.serviceError = '';
					}, 2000)
				})
		}
		$scope.editService = function(service, serviceForm) {
			var serviceData = JSON.parse(JSON.stringify(service));
			serviceData.serviceOptions = serviceData.productOptionList;
			delete serviceData.productOptionList;
			serviceData.serviceOptions.filter(function (option) {
				if(option.privateLabelCollections && option.privateLabelCollections.length > 0){
					option['collections'] = option.privateLabelCollections.map(function(col) { return col._id } )
					delete option.privateLabelCollections;
				}
			});
			$api.put('v2/extra-shop-services', {shopId: $rootScope.currentShop._id, details:serviceData })
				.then(function (response) {
					if(response.status === 200) {
						$uibModalInstance.close('updated');
					} 
				}).catch(function(error) {
					console.log(error)
					$scope.serviceError = error.data.message
					setTimeout(function(){
						$scope.serviceError = '';
					}, 2000)
				})
		}

        $scope.onSelectOption = function(option){
			$scope.selectedOption = option ? option : '';
			$scope.selectedIndex = $scope.service.productOptionList.findIndex(function(x) {return x.name == $scope.selectedOption})
		}
		
		$scope.removeServiceOption = function(){
			$scope.service.productOptionList.splice($scope.selectedIndex, 1);
			if($scope.service.productOptionList.length == 1){
				$scope.showAddProductOptionSection = false;
			}
			$scope.selectedIndex = 0;
			$scope.selectedOption = '';
		}
		$scope.close = function() {
			$uibModalInstance.close('close');
		}

		$scope.getShopPrivateLabel = function(shopId){
			$shop.getOwnShopsPrivateLableCollections(shopId)
			.then(function (results) {
				$scope.allPrivateLableCollections = results
				if(!$scope.serviceType){
					$scope.service['productOptionList'].map(function(service){
						service['privateLabelCollections'] = [];
						if(service.collections && service.collections.length > 0){
							service.collections.map(function(col) {
								var isExist = $scope.allPrivateLableCollections.filter(function (label) {return label._id == col; })
								if(isExist && isExist.length > 0){
									service['privateLabelCollections'].push(isExist[0]);
								}
							})
						}
					})
				}
			})
				.catch(function (reason) {
				console.error(reason)
			})
		}

		$scope.searchPrivateLableCollcetion = function (str) {
			// console.info(`searchPrivateLableCollcetion: `)
			return searchItems(str, 'privateLable')
		}

		var searchItems = function (str) {
			$scope.noCollection = false
			$scope.tempName = str
	
			var matches = []
	
			var collections = $scope.allPrivateLableCollections
	
			var collcetionLength = collections.length
	
			if (collections != undefined && collcetionLength > 0) {
			  collections.forEach(function (item) {
				if ((item[$rootScope.language] != undefined && item[$rootScope.language].name != undefined) && ((item[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) || (item[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0))) {
				  matches.push(item)
				}
			  })
			}
			return matches
		}

		$scope.privateLableCollectionSelected = function (selected) {
			if (selected.originalObject.privateLabel != undefined && selected.originalObject.en.name != '' && selected.originalObject.nl.name != '' && selected.originalObject.de.name != '' && selected.originalObject.fr.name != '' && selected.originalObject.es.name != '') {
				var selectedPrivateLableCollection = selected.originalObject
				var isExist = $scope.service.productOptionList[$scope.selectedIndex].privateLabelCollections.filter(function(col) { return col._id == selectedPrivateLableCollection._id} )
				if(isExist && isExist.length == 0){
					$scope.service.productOptionList[$scope.selectedIndex].privateLabelCollections.push(selectedPrivateLableCollection)
					$scope.isCollectionUpdated = true;
				}
			}
		}

		$scope.getTheCollectionName = function (collection) {
			var collectionName = ''
			if (collection.hasOwnProperty($rootScope.language)) {
			  collectionName = collection[$rootScope.language].name
			} else if (collection.hasOwnProperty('en.name')) {
			  collectionName = collection.en.name
			} else if (collection.hasOwnProperty('nl.name')) {
			  collectionName = collection.nl.name
			} else if (collection.hasOwnProperty('de.name')) {
			  collectionName = collection.de.name
			} else if (collection.hasOwnProperty('fr.name')) {
			  collectionName = collection.fr.name
			} else if (collection.hasOwnProperty('name') && collection.name != '') {
			  collectionName = collection.name
			} else {
			  collectionName = 'NO NAME'
			}
			return collectionName
		}

		$scope.deletePVLCollection = function (index) {
			$scope.service.productOptionList[$scope.selectedIndex].privateLabelCollections.splice(index, 1);
			$scope.isCollectionUpdated = true;
		}
  }])
