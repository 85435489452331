prismanoteApp.controller('welcomeCampaignController', ['$scope', '$rootScope', '$location', '$api', '$state', '$stateParams', '$uibModal', 'campaignFact',
  function ($scope, $rootScope, $location, $api, $state, $stateParams, $uibModal, campaignFact) {
    $scope.Math = window.Math
    $scope.usercampaigns = []
    $scope.campaignBudget = 0
    $scope.totalCost = 0
    var usermailfortoken = $scope.user.email
    $scope.campaignFound = true
    $scope.redirectToken = btoa(usermailfortoken)
    if ($location.search().token == $scope.redirectToken) {
      $scope.isCredited = false
      $scope.exlusiveCosts = true
      $scope.differentUser = false
      $scope.showCampaigns = true
    } else {
      $scope.exlusiveCosts = true
      $scope.isCredited = true
      $scope.differentUser = true
      $scope.showCampaigns = false
    }
    $scope.socialUser = []
    $scope.brandCampaign = []
    $scope.totalPay = 0
    $scope.totalPayCost = 0
    $scope.nameSlugOfpage = $stateParams.hashedData
    $scope.nameSlugToken = btoa($stateParams.hashedData)

    console.log('$stateparams in welcome page', $stateParams)
    // campaignFact.postCreditInCampaign($stateParams.hashedData,$scope.user.email).then(function(response){
    //     console.log('response freom campaign',response);
    // })
    $api.get('social-portals')
      .then(function (response) {
        var keepmove = true
        $scope.socialPortalData = response.data.socialPortals
        for (var i = 0; i < $scope.socialPortalData.length; i++) {
          if (keepmove == true) {
            if ($scope.user.email == $scope.socialPortalData[i].users[0].email) {
              if ($scope.socialPortalData[i].wholesalers.length > 0) {
                $scope.socialUser.push($scope.socialPortalData[i])
                for (var j = 0; j < $scope.socialPortalData[i].campaigns.length; j++) {
                  $scope.brandCampaign.push($scope.socialPortalData[i].campaigns[j])
                }
                keepmove = false
              }
            }
          }
        }
        $scope.brandCampaign = $scope.brandCampaign.filter(function (val) {
          return val.brand.name == $stateParams.hashedData
        })
        $scope.brandCampaign.forEach(function (element) {
          var dateNew = new Date()
          var dateExist = new Date(element.startDate)
          element.tasks.forEach(function (val) {
            if (val.type == 'facebook') {
              if (val.fbPromotionSettings.budget && val.fbPromotionSettings.budget > 0) {
                $scope.campaignBudget = val.fbPromotionSettings.budget
                if (val.contribution) {
                  $scope.contribution = val.contribution
                } else {
                  $scope.contribution = { percent: 0, maxAmount: 0 }
                }
              } else {
                $scope.campaignBudget = 0
                $scope.contribution = { percent: 0, maxAmount: 0 }
              }
            } else {
              $scope.campaignBudget = 0
            }
          })
          $scope.totalCost += $scope.campaignBudget
          var userdata = {
            name: element.name,
            budget: $scope.campaignBudget,
            startdate: element.startDate,
            contribution: $scope.contribution
          }
          // console.log('$scope.contribution', $scope.contribution);
          if ($scope.contribution && $scope.contribution.percent != 100) {
            $scope.exlusiveCosts = true
            $scope.totalPayCost = Math.ceil(((100 - $scope.contribution.percent) * $scope.campaignBudget) / 100)
          }
          $scope.usercampaigns.push(userdata)
          $scope.totalPay += $scope.totalPayCost
        })
        if ($scope.usercampaigns.length == 0) {
          $scope.campaignFound = false
        } else {
          $scope.campaignFound = true
        }
        campaignFact.campBudgetCheckout = $scope.totalPay
        // console.log('$scope.usercampaigns', $scope.usercampaigns);
      })
      .catch(function (reason) {
        console.log(reason)
      })
    $api.get('users').then(function (response) {
      var marketing = []
      var other = []
      $scope.allUsers = response.data.users
      $scope.allUsers.forEach(function (val) {
        if (val.isMarketingUser == true) {
          marketing.push(val)
        } else {
          other.push(val)
        }
      })
      marketing.forEach(function (value) {
        if (value.brandPortalId == $stateParams.brandId) {
          $scope.uniqMarketingUser = value
        }
      })
      console.log('$scope.uniqMarketingUser', $scope.uniqMarketingUser)
    })

    // campaignFact.getPortalUserData($stateParams.hashedData).then(function(response){
    //     $scope.brandCampaign = response.data;
    //     $scope.brandCampaign.forEach(function(element) {
    //         var dateNew = new Date();
    //         var dateExist = new Date(element.startDate);
    //        //if(dateExist.getTime() > dateNew.getTime()){
    //         element.tasks.forEach(function(val){
    //                 if(val.type == 'facebook'){
    //                     if(val.fbPromotionSettings.budget > 0)
    //                         $scope.campaignBudget = val.fbPromotionSettings.budget;
    //                 }else{
    //                     $scope.campaignBudget = 0;
    //                 }
    //         })
    //         $scope.totalCost += $scope.campaignBudget;
    //         $scope.usercampaigns.push({'name':element.name,'budget':$scope.campaignBudget,'startdate':element.startDate});
    //       // }
    //    });
    //      campaignFact.campBudgetCheckout = Math.ceil(((40 * $scope.totalCost) / 100));
    // });
    // $scope.verified = true;
    var obj3 = {
      _id: $rootScope.user._id,
      check: true,
      verify: false,
      revert: false
    }
    campaignFact.updateMarketingUser(obj3).then(function (response) {
      console.log('response.data.data', response)
      var resp = response.data.data
      if (resp.isVerified == true) {
        console.log('verified')
        $scope.verified = false
      } else {
        console.log('not verified')
        $scope.verified = true
      }
    })
    $scope.executeCampaign = function (ischecked) {
      // campaignFact.welcome_camp = true;
      campaignFact.selectedBrandCampaign = $scope.brandCampaign
      if (ischecked == 'verify') {
        var obj2 = {
          _id: $rootScope.user._id,
          check: false,
          verify: true,
          revert: false
        }
      } else {
        var obj2 = {
          _id: $rootScope.user._id,
          check: false,
          verify: false,
          revert: true
        }
      }

      campaignFact.updateMarketingUser(obj2).then(function (response) {
        console.log('response.data.data', response)
        var resp = response.data.data
        if (resp.isVerified == true) {
          console.log('verified')
          $scope.verified = false
        } else {
          console.log('not verified')
          $scope.verified = true
        }
      })
      var obj = {
        userIDBrand: $rootScope.user.facebook.profileId,
        token: $rootScope.user.facebook.token,
        adAcctId: $rootScope.user.facebook.adAccountDetails[0].id
      }
      // $state.go('retailer.campaigns');
      campaignFact.fbAllowAccess(obj).then(function (response) {
        var data1 = JSON.parse(response.data.data)
        if (data1.error) {
          console.log('error is ...', data1.error.message)
        }
        // console.log('respomse',JSON.parse(response.data.data).error.message);
      })
    }
    $scope.closeAlert = function (index) {
      $scope.alerts.splice(index, 1)
    }
    $scope.paymentFunc = function () {
      if ($scope.user.shops && $scope.user.shops.length > 0) {
        createOrder()
      } else {
        // ask for payment details
        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/payment-details-modal.html',
          controller: 'paymentDetailsModalController',
          size: 'lg'
        })

        modalInstance.result.then(function (result) {
          // add details in localstorage and call createOrder()
          localStorage.setItem('shopDetails', JSON.stringify(result))
          createOrder()
        }, function (err) {
          // dismissed
        })
      }
    }
    function createOrder () {
      campaignFact.createfbOrder(
        {
          shop: JSON.parse(localStorage.getItem('shopDetails'))
        }
      ).then(function (res) {
        localStorage.setItem('payId', res[0].payId)
        $scope.pay_id = res[0].payId

        var hostname = window.location.hostname

        if (hostname == '127.0.0.1' || hostname == 'localhost') {
          hostname = 'prismanote.ngrok.io'
        }

        var dataPAy = {
          facebookOrder: true,
          order: {
            amount: campaignFact.campBudgetCheckout,
            description: 'facebook campaign payment',
            redirectUrl: 'http://localhost:3000/retailer/welcome-campaigns/' + $stateParams.hashedData + '?token=' + $scope.redirectToken,
            webhookUrl: 'http://localhost:3000',
            metadata: {
              orderId: res[0].payId
            }
          },
          shop: JSON.parse(localStorage.getItem('shopDetails'))
        }
        campaignFact.createPayment(dataPAy).then(function (resPay) {
          if (resPay.error) {
            console.log('resa pay error', resPay.error)
          } else {
            console.log('resPay.payment', resPay.payment)
            var payment_id = resPay.payment.id
            $scope.paymentSiteUrl = resPay.payment.links.paymentUrl

            window.location.href = $scope.paymentSiteUrl
            // var win = window.open($scope.paymentSiteUrl, '_blank');
            // win.focus();
            campaignFact.savePaymentID(payment_id, $scope.pay_id, dataPAy.order.amount).then(function (paymntId) {
              console.log('payment res', paymntId)
              campaignFact.saveCreditInBrands(campaignFact.campBudgetCheckout, $stateParams.hashedData).then(function (response) {
                console.log('data frommmmm', response)
              })
              campaignFact.paymentWebhook(payment_id).then(function (webHookRes) {
                console.log('webHookRes', webHookRes)
              })
            })
          }
        })
      })
    }
  }])
