prismanoteApp.controller('editEmployeeModalController', ['$scope', '$uibModalInstance', 'employee', '$brand',
  function ($scope, $uibModalInstance, employee, $brand) {
    $scope.employee = employee

    $scope.saveemployee = function () {
      console.log($scope.employee)
      $brand.editEmployeeByEmployeeId($scope.employee).then(function (employee) {
        $uibModalInstance.close()
      }).catch(function (error) {
        console.error('error: ', error)
        $uibModalInstance.close()
      })
    }

    $scope.cancel = function () {
      $uibModalInstance.close()
    }
  }])
