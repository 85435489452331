prismanoteApp.controller('campaignRetailerJoiningList', ['$scope', '$rootScope', '$stateParams', '$state', '$retailer', '$api', '$campaign', '$brandCampaign', '$shop',
  function ($scope, $rootScope, $stateParams, $state, $retailer, $api, $campaign, $brandCampaign, $shop) {
    console.log('campaignRetailerJoiningList called: ', $stateParams)
    $scope.retailerEmail = ''

    $scope.getJoiningRetailerListByCampaignId = function () {
      $scope.loading = true
      $campaign.getJoiningRetailerListByCampaignId($stateParams.campaignId).then(function (campaign) {
        $scope.loading = false
        $scope.showError = false
        $scope.campaign = campaign
        console.log('campaign: ', campaign)
        $shop.getAllShop().then(function (shops) {
          $scope.shops = shops
          console.log('shops: ', shops)
        }).catch(function (shoperr) {
          console.log('shoperr: ', shoperr)
        })
      }).catch(function (error) {
        console.log('error: ', error)
        $scope.loading = false
        $scope.showError = true
        $scope.errorMessage = error || 'Something went wrong while fetching the campaign Detail'
      })
    }

    $scope.changeStatusofJoinigRetailer = function (index) {
      var body = {
        campaignId: $scope.campaign._id,
        taskId: $scope.campaign.taskId[0],
        shopId: $scope.campaign.retailerEmailStatistics[index].shopId,
        isEnabled: $scope.campaign.retailerEmailStatistics[index].isEnabled
      }
      // console.log('body: ', body);
      $scope.loading = true
      $brandCampaign.changeStatusofJoinigRetailer(body).then(function (respose) {
        $scope.loading = false
        $scope.showError = false
      }).catch(function (error) {
        console.log('error: ', error)
        $scope.loading = false
        $scope.showError = true
        $scope.errorMessage = error || 'Something went wrong while updating the status'
      })
    }

    $scope.searchShops = function (str) {
      $scope.retailerEmail = str
      var matches = []
      $scope.shops.forEach(function (item) {
        if (item && (
          (item.email && item.email.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)
        )) {
          matches.push(item)
        }
      })
      return matches
    }

    // Selecting the shop
    $scope.selectTheShop = function (shop) {
      console.log('shop: ', shop)
      $scope.retailerEmail = ''
      if (shop && shop.originalObject && shop.originalObject.email) {
        console.log('selectTheShop: ', shop.originalObject.email)
        var body = {
          campaignId: $scope.campaign._id,
          email: shop.originalObject.email,
          shop: {
            _id: shop.originalObject._id,
            name: shop.originalObject.name,
            phone: shop.originalObject.phone
          },
          companyId: $rootScope.currentCompany._id
        }
        $scope.loading = true
        $campaign.sendInvitationEmailManually(body).then(function (response) {
          console.log('response: ', response)
          $scope.loading = false
        }).catch(function (error) {
          console.log('error: ', error)
          $scope.loading = false
        })
      }
    }

    $scope.sendEmail = function () {
      $scope.alert = null
      // console.log('retailerEmail email: ', $scope.retailerEmail);
      if ($scope.retailerEmail) {
        $campaign.validateEmail($scope.retailerEmail).then(function (isValidEmail) {
          console.log('isvallid email: ', $scope.retailerEmail, isValidEmail)
          if (!isValidEmail) {
            $scope.alert = {
              type: 'danger',
              msg: 'Invalid email address'
            }
          } else {
            var body = {
              campaignId: $scope.campaign._id,
              email: $scope.retailerEmail,
              companyId: $rootScope.currentCompany._id
            }
            $scope.loading = true
            $campaign.sendInvitationEmailManually(body).then(function (response) {
              console.log('response: ', response)
              // $scope.retailerEmail = '';
              $scope.loading = false
              $scope.alert = {
                type: 'info',
                msg: 'Email sent successfully'
              }
            }).catch(function (error) {
              $scope.loading = false
              $scope.alert = {
                type: 'danger',
                msg: 'Internal server error'
              }
            })
          }
        })
      } else {
        $scope.alert = {
          type: 'danger',
          msg: 'Enter email address'
        }
      }
    }

    $scope.closeAlert = function () {
      $scope.alert = null
    }
  }])
