prismanoteApp.controller('adminProductsController', ['$scope', '$rootScope', '$stateParams', '$api', '$q', '$translate',
  function ($scope, $rootScope, $stateParams, $api, $q, $translate) {
    if ($stateParams.category && typeof $stateParams.category === 'string' && ['WATCH', 'STRAP', 'JEWEL'].includes($stateParams.category)) {
      $scope.category[$stateParams.category.toLowerCase()] = true
    }

    // Array with all currently displayed items in /products
    $scope.products = []

    $scope.selection = []

    $scope.productCategory = []

    $scope.isVerified = true

    $scope.isNotVerified = true

    // Filer On Start

    $scope.filter = {}
    // $scope.filter.includeUnverified = true;
    // $scope.option = { reset: true };

    $scope.$on('sort_filter_directive', function (event, params) {
      $scope.filter.sort = {}
      $scope.filter.sort = params.sort
      console.log('HEY sort_filter_directive: ', params.sort, $scope.filter)
    })

    $scope.$on('gender_filter_directive', function (event, params) {
      $scope.filter.gender = params.filter.gender
    })

    $scope.$on('category_filter_directive', function (event, params) {
      if (!$scope.filter.category) {
        $scope.filter.category = {}
        $scope.filter.category.$in = {}
        $scope.filter.category.$in = params.categories
      } else {
        $scope.filter.category.$in = params.categories
      }

      console.log('category filter directive: ', params, $scope.filter)
    })

    $scope.$on('particulars_filter_directive', function (event, params) {
      $scope.filter['watch.hasSwissMovement'] = params.filter['watch.hasSwissMovement']
      $scope.filter['watch.hasDateFunction'] = params.filter['watch.hasDateFunction']
      $scope.filter['watch.hasLightFunction'] = params.filter['watch.hasLightFunction']
      $scope.filter['watch.isAntiAllergy'] = params.filter['watch.isAntiAllergy']
      $scope.filter['watch.isNickelFree'] = params.filter['watch.isNickelFree']
    })

    $scope.$on('product_filter_directive', function (event, params) {
      $scope.filter.discount = params.filter.discount
      $scope.filter.inStock = params.filter.inStock
      $scope.filter.isBestseller = params.filter.isBestseller
      $scope.filter.show = params.filter.show
    })

    $scope.$on('case_size_filter_directive', function (event, params) {
      $scope.filter['watch.case.size'] = params.filter['watch.case.size']
    })

    $scope.$on('indication_filter_directive', function (event, params) {
      $scope.filter['watch.indication'] = params.filter['watch.indication']
    })

    $scope.$on('strap_model_filter_directive', function (event, params) {
    })

    $scope.$on('strap_width_filter_directive', function (event, params) {
    })

    $scope.$on('strap_material_filter_directive', function (event, params) {
      $scope.filter['watch.strap.material'] = params.filter['watch.strap.material']
      if ($scope.filter['watch.strap.material'].length == 10) {
        delete $scope.filter['watch.strap.material']
      }
    })

    $scope.$on('brand_filter_directive', function (event, params) {
      var filterBrand = params.filter['brand.nameSlug'].$in

      if (filterBrand && filterBrand.length > 0) {
        var temp = angular.merge($scope.filter, params.filter)
        $scope.filter = temp
      } else {
        $scope.filter['brand.nameSlug'] = ''
      }
    })

    $scope.$on('supplier_filter_directive', function (event, params) {
      var filterSupplier = params.filter.supplier.$in

      if (filterSupplier && filterSupplier.length > 0) {
        var temp = angular.merge($scope.filter, params.filter)
        $scope.filter = temp
      } else {
        $scope.filter.supplier = ''
      }
      console.log("$scope.filter['supplier']", $scope.filter.supplier)
    })

 		// Filer On End

    $scope.convertDate = function (date) {
      var d = new Date(date)
      // Set Hours -4 for time differance
      // d.setHours( d.getHours() - 4);

      // return d.toUTCString();
      return d.toLocaleString()
    }

    $scope.verificationsChanged = function () {
      var params = {
        filter: {
          isVerified: false
        },
        offset: 0
      }

      $scope.products = []

      $scope.getProducts(params)
    }

    $scope.changeSuggestion = function (fields) {
      console.log('fields', fields)
    }

    $scope.selectItem = function (nameSlug, index) {
      var indexOf = $scope.selection.indexOf(nameSlug)
      if (indexOf != -1) {
        $scope.selection.splice(indexOf, 1)
      } else {
        $scope.selection.push(nameSlug)
      }
      $scope.products[index].selected = !$scope.products[index].selected
    }

    $scope.limit = 24

    $scope.setLimit = function (limit) {
      if (limit != null) {
        $scope.limit = limit
      } else {
        $scope.limit = 24
      }
    }

    // format kids, male and female booleans into a translated and formatted gender string
    var formatGender = function (male, female, kids) {
      return $q(function (resolve, reject) {
        if (kids == false) {
          if (male == true && female == true) {
            $translate('GENTS').then(function (gents) {
              $translate('LADIES').then(function (ladies) {
                resolve(gents + ' / ' + ladies)
              })
            })
          } else if (male == true && female == false) {
            $translate('GENTS').then(function (gents) {
              resolve(gents)
            })
          } else if (male == false && female == true) {
            $translate('LADIES').then(function (ladies) {
              resolve(ladies)
            })
          }
        } else {
          if (male == true && female == true) {
            $translate('BOYS').then(function (boys) {
              $translate('GIRLS').then(function (girls) {
                resolve(boys + ' / ' + girls)
              })
            })
          } else if (male == true && female == false) {
            $translate('BOYS').then(function (boys) {
              resolve(boys)
            })
          } else if (male == false && female == true) {
            $translate('GIRLS').then(function (girls) {
              resolve(girls)
            })
          }
        }
      })
    }

    // Async function to add/format gender and possibly other additional properties later on
    var formatProduct = function (product) {
      return $q(function (resolve, reject) {
        formatGender(product.male, product.female, product.kids)

          .then(function (formattedGender) {
            product.gender = formattedGender
            resolve(product)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    var getProductCount = function () {
      return $q(function (resolve, reject) {
        $api.getCount('products')

          .then(function (productCount) {
            $scope.productCount = productCount
            resolve(productCount)
          })

          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    $scope.$on('brand_filter_directive', function (event, params) {
      console.log('params', params)

      // console.log("params of brand filter", params);
      // var temp = angular.merge($scope.filter, params.filter);
      // console.log("temp", temp);
      // console.log("$scope.filter", $scope.filter);

      var options = { reset: true }
      $scope.getProducts(params, options)
      // console.log("after color filter", temp);
    })

    // Get new products from the database and add them to the $scope
    $scope.getProducts = function (params, options) {
      console.log('-------------params controller---------------', options)

      if (!options) {
        $scope.products = []
      }

      if (!params) {
        params = {}
      }

      if (!params.filter) {
        params.filter = {}
      }

      return $q(function (resolve, reject) {
        $scope.loading = true

        if (!params) {
          params = {}
        }

        if (!params.filter) {
          params.filter = {}
        }

        var apiRequest = function () {
          console.log('$scoper filter: ', $scope.filter)
          params.sort = { priorityKey: -1 }
          console.log('PARMA SORT: ', params.sort)
          if ($scope.filter && $scope.filter.sort && $scope.filter.sort.productNumber) {
            params.sort.productNumber = 1
          }

          if ($scope.filter.sort) delete $scope.filter.sort

          if (params.offset != 0) {
            console.log('is offsent not zero: ', params.offset)
            params.offset = $scope.products.length
            params.limit = $scope.limit
          }

          // Below if else condition add by akib

          if ($scope.products.length < params.limit && params.offset == 0) {
            console.log('if setting offset to zero: ')
            params.offset = 0
          } else {
            console.log('else:  setting offset to $scope.products.length + params.limit: ', $scope.products.length + params.limit)
            params.offset = $scope.products.length + params.limit
          }

          console.log(params.offset, params.limit)

          if ($scope.isVerified) {
            params.filter.isVerified = true
          }
          if ($scope.isNotVerified) {
            params.filter.isVerified = false
          }

          if ($scope.isVerified && $scope.isNotVerified) {
            // console.log("Is Verified And Not Verified.");
            params.filter.isVerified = 'showAll'
          }
          if ($scope.isVerified == false && $scope.isNotVerified == false) {
            // console.log("Is Verified And Not Verified.");
            params.filter.isVerified = 'showAll'
          }

          // if(typeof params.filter.isVerified !== 'boolean') {
          // 	params.filter.isVerified = false;
          // }
          // params.filter.isVerified = $scope.isVerified;

          if (params.filter.category && params.filter.category.$in) {
            $scope.productCategory = params.filter.category.$in
          } else {
            $scope.productCategory = []
          }

          // if (params.filter && params.filter['brand.nameSlug']) {
          // 	var tempfil = params.filter['brand.nameSlug']['$in'];
          // 	delete params.filter['brand.nameSlug'];
          // 	var currentlang = $rootScope.language;
          // 	var filterbrandkey = "brand." + currentlang + ".nameSlug";
          // 	params.filter[filterbrandkey] = {};
          // 	params.filter[filterbrandkey]['$in'] = tempfil;
          // }
          params.currentlang = $rootScope.language
          console.log('FINAL GET ADMIN PRODUCTS: ', params)

          getProductCount()
            .then(function (res) {
              $api.get('products', params)
                .then(function (response) {
                  // Loop through newly fetched products and add them to the $scope.
                  if (response.data.products) {
                    console.log('length: ', response.data.products)
                  }
                  for (var i = 0; i < response.data.products.length; i++) {
                    console.log('FOR LOOOP: PRODUCTS')
                    $scope.products.push(response.data.products[i])

                    if ($scope.products.length == params.offset + response.data.products.length) {
                      $scope.loading = false
                    }

                    formatProduct(response.data.products[i])
                      .then(function (formattedProduct) {
                        $scope.products.push(formattedProduct)
                        if ($scope.products.length == params.offset + response.data.products.length) {
                          $scope.loading = false
                        }
                      })

                      .catch(function (reason) {
                        reject(reason)
                      })
                  }

                  $scope.noProductsFound = false
                  resolve()
                })
                .catch(function (reason) {
                  $scope.noProductsFound = true

                  reject(reason)
                })
            })
        }

        // Delete the loaded products and load them again with the new apiParams
        if (options != null && typeof options === 'object') {
          if (options.reset === true) {
            $scope.products = []
          }
        }

        apiRequest()
      })
    }
    // $scope.getProducts();
  }])
