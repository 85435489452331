prismanoteApp.controller('oldshopBrandPurchaseOrderController', ['$scope', '$rootScope', '$uibModal', '$api', '$stateParams', '$state', '$q', '$retailer', '$http', 'FileSaver', 'Upload', '$pdf',
  function ($scope, $rootScope, $uibModal, $api, $stateParams, $state, $q, $retailer, $http, FileSaver, Upload, $pdf) {
    $scope.back = function () {
      $state.go('brand.purchase-orders')
    }

    // Getting purchaser order(If already there with the purchase order)
    $scope.GetExistingPurchaseOrder = function () {
      // When we have alredy purchase order
      console.log('----------------------> called brand: ', 'GetExistingPurchaseOrder')
      $scope.loading = true
      $api.get('purchase-order-of-company/' + $stateParams.orderNo) // Here passed this id because in params that needed so we can use same api for that
        .then(function (response) {
          $scope.loading = false
          // console.log("response.data:=====>", response);

          $scope.hasSupplier = true
          $scope.purchase_order = response.data

          if (!$scope.purchase_order.companyRemarks) {
            $scope.purchase_order.companyRemarks = {
              delivery_date: new Date(angular.copy($scope.purchase_order.delivery_date)),
              status: $scope.purchase_order.status[0].status,
              comment: ''
            }
          } else if (!$scope.purchase_order.companyRemarks.delivery_date) {
            $scope.purchase_order.companyRemarks.delivery_date = new Date(angular.copy($scope.purchase_order.delivery_date))
          } else if (!$scope.purchase_order.companyRemarks.status) {
            $scope.purchase_order.companyRemarks.status = $scope.purchase_order.status[0].status
          } else {
            $scope.purchase_order.companyRemarks.delivery_date = new Date($scope.purchase_order.companyRemarks.delivery_date)
          }

          // console.log($scope.purchase_order.companyRemarks.backorder_delivery_date);

          if ($scope.purchase_order.companyRemarks && $scope.purchase_order.companyRemarks.backorder_delivery_date) {
            $scope.isBackOrderDate = true
            // console.log($scope.purchase_order.companyRemarks.backorder_delivery_date, 'inside');
            $scope.purchase_order.companyRemarks.backorder_delivery_date = new Date(angular.copy($scope.purchase_order.companyRemarks.backorder_delivery_date))
          } else if ($scope.purchase_order.backorder_delivery_date) {
            // console.log('Backorder dleivery date');
            $scope.purchase_order.companyRemarks.backorder_delivery_date = new Date(angular.copy($scope.purchase_order.backorder_delivery_date))
          } else {
            // console.log('hello ;;');
            $scope.purchase_order.companyRemarks.backorder_delivery_date = new Date()
          }

          if ($scope.purchase_order.companyRemarks && $scope.purchase_order.companyRemarks.invoice) {
            var invoiceDetail = $scope.purchase_order.companyRemarks.invoice.split('/')
            $scope.invoiceFileName = invoiceDetail[1]
          }
        })
        .catch(function (reason) {
          $scope.loading = false
          console.log('--------------------> ', reason)
          if (reason == 'No purchase order found with that order no') {
            $scope.erromessage = 'Oops! We could not find your purchase order on this purchase number'
            $scope.alertdanger = true
          } else {
            console.error(reason.status)
          }
        })
    }

    // When cllcking on confirm date then we copy the retailer delivery_date
    $scope.copyDate = function (isBackOrder) {
      if (!$scope.purchase_order.companyRemarks) {
        $scope.purchase_order.companyRemarks = {}
      }
      if (isBackOrder) {
        $scope.purchase_order.companyRemarks.backorder_delivery_date = new Date(angular.copy($scope.purchase_order.backorder_delivery_date))
      } else {
        console.log('purchsro order copyDate: ', !$scope.purchase_order.companyRemarks, $scope.purchase_order)
        $scope.purchase_order.companyRemarks.delivery_date = new Date(angular.copy($scope.purchase_order.delivery_date))
      }
    }

    $scope.uploadFiles = function (file) {
      $scope.invoiceFile = file
    }

    $scope.UpdateThePurchaseOrder = function () {
      $scope.loading = true

      if (!$scope.invoiceFile) {
        UpdateThePurchaseOrder()
      } else {
        // console.log('$scope.purchase_order: ', $scope.purchase_order._id);
        Upload.upload({
          url: 'api/purchase-order-invoice-upload',
          data: {
            purchaseOrderId: $scope.purchase_order._id,
            files: $scope.invoiceFile // file input field
          }
        })
          .then(function (res) {
            // console.log('res: ', res);
            $scope.purchase_order.companyRemarks.invoice = res.data
            UpdateThePurchaseOrder()
          }, function (res) {
            $scope.loading = false
            // console.error("Upload error: ", res.status);
            $scope.isMessage = true
            $scope.showMessage = 'Something went wrong while uploading the file'
            // $scope.alert = {
            //     type: "danger",
            //     msg: "upload error: " + res.status
            // }
          }, function (evt) {
            // console.log('evt: ', evt);
            // $scope.loading = false;
          })
      }

      function UpdateThePurchaseOrder () {
        $scope.purchase_order.sendEmailToShop = true
        $scope.purchase_order.isCompanyRepliedAfterProcessingMode = true
        $api.put('company-purchase-orders/', $scope.purchase_order)
          .then(function (response) {
            // console.log('after update: ', response);
            $scope.isMessage = true
            $scope.showMessage = response.data.message
            $scope.loading = false
            $scope.GetExistingPurchaseOrder()
            // $scope.purchase_order = response.data.purchaseRes;
            // if (!$scope.purchase_order.companyRemarks.delivery_date) {
            //     $scope.purchase_order.companyRemarks.delivery_date = new Date(angular.copy($scope.purchase_order.delivery_date));
            // } else {
            //     $scope.purchase_order.companyRemarks.delivery_date = new Date($scope.purchase_order.companyRemarks.delivery_date);
            // }

            // if ($scope.purchase_order.companyRemarks && $scope.purchase_order.companyRemarks.backorder_delivery_date) {
            //     $scope.isBackOrderDate = true;
            //     $scope.purchase_order.companyRemarks.backorder_delivery_date = new Date(angular.copy($scope.purchase_order.companyRemarks.backorder_delivery_date));
            // } else if ($scope.purchase_order.backorder_delivery_date) {
            //     $scope.purchase_order.companyRemarks.backorder_delivery_date = new Date(angular.copy($scope.purchase_order.companyRemarks.backorder_delivery_date));
            // } else {
            //     $scope.purchase_order.companyRemarks.backorder_delivery_date = new Date();
            // }

            // if ($scope.purchase_order.companyRemarks && $scope.purchase_order.companyRemarks.invoice) {
            //     var invoiceDetail = $scope.purchase_order.companyRemarks.invoice.split('/');
            //     // console.log(invoiceDetail);
            //     $scope.invoiceFileName = invoiceDetail[1];
            // }
          })
          .catch(function (reason) {
            $scope.loading = false
            $scope.isMessage = true
            $scope.showMessage = response.data.message
            // $scope.alert = {
            //     type: 'danger',
            //     msg: reason.data.message
            // }
          })
      }
    }

    $scope.GetExistingPurchaseOrder()

    $scope.popover = function (item) {
      if (item.productDetail._id) {
        if (item.productDetail.images && item.productDetail.images.length && item.productDetail.images[0].src) {
          var image = $rootScope.awsUrl + item.productDetail.images[0].src
          return '<img src=' + image + " alt='No Image found'>"
        } else if (item.productDetail.category) {
          return item.productDetail.category
        }
      }
    }

    // // Print the given data
    $scope.ExportToPDF = function () {
      $scope.loading = true
      // $http({
      //   method: 'POST',
      //   url: '/api/generate-pdf-purchase-order',
      //   data: {
      //     shop: $scope.purchase_order.shop,
      //     purchaseOrder: $scope.purchase_order,
      //     typeDocument: 'document',
      //     deviceId: $rootScope.currentDevice._id
      //   },
      //   responseType: 'arraybuffer'
      // })
      $pdf.downloadPdf('generate-pdf-purchase-order', {
        shop: $scope.purchase_order.shop,
        purchaseOrder: $scope.purchase_order,
        typeDocument: 'document',
        deviceId: $rootScope.currentDevice._id,
        companyId: $rootScope.currentCompany._id
      }, 'arraybuffer', true, false)
        .then(function (response) {
          $scope.loading = false
          var file = new Blob([response.data], { type: 'application/pdf' })
          var fileName = 'purchaseorder_' + $scope.purchase_order.number + '.pdf'
          FileSaver.saveAs(file, fileName)
        })
        .catch(function (reason) {
          $scope.loading = false
          console.error('Error while generating PDF', reason)
        })
    }

    $scope.popoverUibTooltip = function (description, date) {
      return description + new Date(date)
    }

    $scope.AddComment = function (comment) {
      if (comment) {
        var body = {
          purchaseOrderId: $scope.purchase_order._id,
          remarks: {
            eType: 'company',
            remark: comment,
            companyId: $scope.purchase_order.company._id
          },
          shopId: $rootScope.currentShop._id,
          companyId: $rootScope.currentCompany._id
        }

        // if (!$scope.purchase_order.remarks) $scope.purchase_order.remarks = {}; $scope.purchase_order.remarks.remarks = [];
        // $scope.purchase_order.remarks.remarks.push(body.remarks);
        // console.log('company: ', company._id);
        $api.post('add-comment-retailer-and-company', body)
          .then(function (response) {
            $scope.currentremark = ''
            $scope.GetExistingPurchaseOrder()
          })
          .catch(function (err) {
            console.log(err)
            $scope.currentremark = ''
          })
      }
    }

    $scope.EditCommentMode = function (remark, index) {
      for (var i = 0; i < $scope.purchase_order.remarks.remarks.length; i++) {
        $scope.purchase_order.remarks.remarks[i].isEditMode = false
      }
      $scope.purchase_order.remarks.remarks[index].isEditMode = true
      $scope.editRemarkDetail = remark
    }

    $scope.UpdateComment = function (comment, index) {
      console.log('UpdateComment: ', comment)
      if (comment) {
        $scope.editRemarkDetail.remark = comment
        $scope.purchase_order.remarks.remarks[index].isEditMode = false
        // console.log('$scope.editRemarkDetail: ', $scope.editRemarkDetail);
        var body = {
          purchaseOrderId: $scope.purchase_order._id,
          commentId: $scope.editRemarkDetail._id,
          remark: comment
        }

        $api.post('edit-comment-retailer-and-company', body)
          .then(function (response) {
            $scope.editRemarkDetail = ''
            $scope.GetExistingPurchaseOrder()
          })
          .catch(function (err) {
            $scope.editRemarkDetail = ''
            console.log(err)
          })
      } else {
        $scope.editRemarkDetail = ''
        $scope.purchase_order.remarks.remarks[index].isEditMode = false
      }
    }

    $scope.openShopDetail = function (nameSlug) {
      console.log(nameSlug)
      $state.go('brand.shop-detail', { nameSlug: nameSlug })
    }
  }])
