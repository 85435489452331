prismanoteApp.controller('companyProductModalController', ['$scope', '$uibModalInstance', '$state', 'product', '$rootScope', 'prompt', '$company', '$language',
  function ($scope, $uibModalInstance, $state, product, $rootScope, prompt, $company, $language) {
    $scope.productId = product._id

    $scope.getOwnCompanyProduct = function (companyId, productId) {
      $company.getCompanyProduct(companyId, productId)
        .then(function (success) {
          $scope.product = success.data.product
          $scope.product._id = success.data.product._id
          $scope.shopProduct = success.data.companyProduct
          $scope.productBrands = success.data.brand
          $scope.productCollections = success.data.collections

          if ($scope.productBrands) {
            if ($scope.productBrands.isPoolArticleBrand) $scope.allowToMerge = false
            else $scope.allowToMerge = true
          } else {
            $scope.allowToMerge = true
          }

          if (!$scope.shopProduct.sellingPrice) {
            $scope.shopProduct.sellingPrice = $scope.shopProduct.price
            $scope.shopProduct.price = $scope.shopProduct.price * (($scope.shopProduct.priceVat / 100) + 1)
          }

          if ($scope.shopProduct.discount != null) {
            if ($scope.shopProduct.discount > 0) {
              $scope.onSale = true
              if ($scope.shopProduct && $scope.shopProduct.discountOnPercentage) $scope.calculatedDiscount = ($scope.shopProduct.sellingPrice * $scope.shopProduct.discount) / 100
              else $scope.calculatedDiscount = $scope.shopProduct.discount
            } else {
              $scope.onSale = false
            }
          } else {
            $scope.product.discount = 0
          }
        })

        .catch(function (error) {
          console.error('Error! While Getting Own Company Product', error)
        })
    }

    $scope.getTheCollectionName = function (collection) {
      var collectionName = ''
      if (collection.hasOwnProperty($rootScope.language)) { collectionName = collection[$rootScope.language].name } else if (collection.hasOwnProperty('en.name')) { collectionName = collection.en.name } else if (collection.hasOwnProperty('nl.name')) { collectionName = collection.nl.name } else if (collection.hasOwnProperty('de.name')) { collectionName = collection.de.name } else if (collection.hasOwnProperty('fr.name')) { collectionName = collection.fr.name } else if (collection.hasOwnProperty('name') && collection.name != '') { collectionName = collection.name } else { collectionName = 'NO NAME' }
      return collectionName
    }

    // $scope.openPrintLabelModal = function () {
    //     var product = $scope.product;
    //     var modalInstance = $uibModal.open({
    //         templateUrl: '../views/modal/print-label-modal.html',
    //         controller: 'printLabelModalController',
    //         resolve: {
    //             product: function () {
    //                 return product;
    //             },
    // loadProduct: function(){
    //     return false
    // }
    //         }
    //     });
    //     //we will get the value here
    //     modalInstance.result.then(function (result) {
    //         if (result) {
    //             //ready
    //             $scope.product.labelDescription = result.labelDescription ? result.labelDescription : '';
    //         }
    //     }, function () {
    //         //dismissed
    //     })

    // }

    $scope.cancel = function () {
      console.log('Close the retailer product modal opening modal')
      $uibModalInstance.dismiss('cancel')
    }

    $rootScope.cancelModal = function () {
      console.log('Close the retailer product modal opening modal')
      $uibModalInstance.dismiss('cancel')
    }

    $scope.edit = function (product) {
      console.log('Product', product)
      $state.go('brand.edit-product', { _id: product._id })
      $scope.cancel()
    }

    $scope.suggested = function (product) {
      console.log('$scope.product ======> ', $scope.productId)
      // console.log("$scope.product" ,$scope.shopProduct);
      console.log('mergeProductnameSlug', product._id)
      $uibModalInstance.dismiss('cancel')
      $state.go('retailer.compare-product', { nameSlug: $scope.productId, mergeProductnameSlug: product._id })
    }

    $scope.deleteProductFromCompany = function (product) {
      console.log(' ------- Delete stock product ---------- ')
      console.log(product)

      if ($scope.productBrands.nameSlug) {
        var brand = $rootScope.currentCompany.brands.find(function (brand) { return brand.nameSlug === $scope.productBrands.nameSlug }) || null

        if (brand && brand.restricted) {
          prompt({
            title: $language.translate('DELETE_PRODUCT') + '?',
            message: $language.translate('YOU_ARE_OFFICIAL_DEALER_PRODUCT_STAYS_IN_ASSORTMENT')
          }).then(function () {

            // $scope.stockProducts[index].items.stock = 0;
            // $scope.stockProducts[index].items.show = false;

          }, function () {
            
          })
        } else {
          prompt({
            title: $language.translate('DELETE_PRODUCT') + '?',
            message: $language.translate('ARE_YOU_SURE_TO_DELETE_THIS_PRODUCT'),
            buttons: [
              { label: $language.translate('DELETE_PRODUCT'), primary: true, id: 0 },
              { label: $language.translate('STOP_SELLING_STOCK_TO_0'), primary: true, id: 1 },
              { label: $language.translate('CANCEL'), primary: false, cancel: true, id: 2 }
            ]
          })
            .then(function (value) {
              if (value && value.id === 0) {
                $company.deleteProductFromCompany(product._id, $rootScope.currentCompany._id)
                  .then(function (result) {
                    $scope.cancel()
                    $state.go('brand.assortment')
                  })
                  .catch(function (error) {
                    console.error(error)
                  })
              } else if (value && value.id === 1) {
                $company.updateCompanyProductStockAndShow($scope.stockProducts[index]._id, $rootScope.currentShop._id)
                  .then(function (success) {
                    $state.go('brand.assortment')
                  })
                  .catch(function (error) {
                    console.error(error)
                  })
              } else {
                console.log("I don't delete this product")
              }
            })
            .catch(function (error) {
              console.log('Error ======> Delete Product', error)
              console.error(error)
            })
        }
      } else {
        prompt({
          title: $language.translate('DELETE_PRODUCT') + '?',
          message: $language.translate('ARE_YOU_SURE_TO_DELETE_THIS_PRODUCT'),
          buttons: [
            { label: $language.translate('DELETE_PRODUCT'), primary: true, id: 0 },
            { label: $language.translate('STOP_SELLING_STOCK_TO_0'), primary: true, id: 1 },
            { label: $language.translate('CANCEL'), primary: false, cancel: true, id: 2 }
          ]
        }).then(function (value) {
          console.log('Success  from the data of the prompt', value)
          if (value && value.id === 0) {
            $company.deleteProductFromCompany(product._id, $rootScope.currentCompany._id)
              .then(function (result) {
                $state.go('brand.assortment')
              })
              .catch(function (error) {
                console.error(' Error In delete product from the shop', error)
              })
          } else if (value && value.id === 1) {
            $company.updateCompanyProductStockAndShow(product._id, $rootScope.currentCompany._id)
              .then(function (success) {
                $state.go('brand.assortment')
              })
              .catch(function (error) {
                console.error('Error in updateShopProductStockAndShow', error)
              })
          } else {
            console.log("I don't delete this product")
          }
        })
          .catch(function (error) {
            console.log('Error ======> Delete Product', error)
            console.error(error)
          })
      }
    }
  }])
