prismanoteApp.controller('changeFaviconController', ['$scope', '$rootScope', 'Upload',
  function ($scope, $rootScope, Upload) {
    $scope.icon = '';
    $scope.faviconOk = function () {
      console.log('New icon', $scope.icon)
      if ($scope.icon && $scope.icon !== null) {
        // Check uploaded file extention
        if ($scope.icon.type === 'image/x-icon') {
          var factoryData = localStorage.getItem('factory') ? JSON.parse(localStorage.getItem('factory')) : {}
          var shopId = $rootScope.currentShop._id

          if (Object.keys(factoryData).length > 0) {
            Upload.upload({
              url: 'api/grape/change-favicon-icon/' + factoryData.retailer + '/' + factoryData.websiteName,
              data: {
                file: $scope.icon
              }
            })
              .then(function (success) {

              })
              .catch(function (error) {
                console.log('reason =-=-=?', error)
              })
          } else {
            console.error('Something went wrong.')
          }
        } else {
          console.error('Upload favicon icon with .ico file only')
        }
      }
    }
  }
])
