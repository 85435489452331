prismanoteApp.controller('editDeliveryAddressModalController', ['$scope', '$uibModalInstance', 'data',
  function ($scope, $uibModalInstance, data) {
    $scope.deliveryAddress = data

    $scope.closeModal = function () {
      $uibModalInstance.close($scope.deliveryAddress)
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss($scope.deliveryAddress)
    }

    $scope.saveTheDeliveryAddress = function () {
      if ($scope.deliveryAddress) {
        $scope.closeModal()
      }
    }
  }])
