prismanoteApp.controller('retailerPurchaseOrderMailToSupplierController', ['$scope', '$rootScope', '$stateParams', '$language', '$uibModal', '$api', '$translate', '$http', 'FileSaver', 'Blob', '$retailer', 'prompt', 'campaignFact', '$state',
  function ($scope, $rootScope, $stateParams, $language, $uibModal, $api, $translate, $http, FileSaver, Blob, $retailer, prompt, campaignFact, $state) {
    $scope.success_message = false
    $scope.$on('changeSelectedShop', function () {
      $scope.getParticularPurchaseOrder()
    })

    $scope.getParticularPurchaseOrder = function () {
      if ($rootScope.currentShop) {
        $scope.loading = true
        $api.get('purchase-order-for-mail/' + $stateParams.orderNo + '/' + $rootScope.currentShop._id)
          .then(function (response) {
            $scope.loading = false
            $scope.currentShopName = $rootScope.currentShop.name

            $scope.purchase_order = response.data;

            if(response.data && response.data.lastCommentofRetailer){
              $scope.purchase_order.email = {
                toemail: response.data.company.email,
                subject: $language.translate('NEW_ORDER_FOR') + ' ' + response.data.company.name,
                body: $language.translate('DEAR_SIR_MADAM_OF_') + response.data.lastCommentofRetailer.remark + $language.translate('WE_WOULD_LIKE_TO_ORDER_THANKS_FOR_SMOOTH_DELIVERY'),
              };
            }else{
              $scope.purchase_order.email = {
                toemail: response.data.company.email,
                subject: $language.translate('NEW_ORDER_FOR') + ' ' + response.data.company.name,
                body: $language.translate('DEAR_SIR_MADAM_OF_') + response.data.company.name + '.<br> ' + $language.translate('WE_WOULD_LIKE_TO_ORDER_THANKS_FOR_SMOOTH_DELIVERY'),
              };
            }
          })
          .catch(function (reason) {
            $scope.loading = false
            if (reason == 'Purchase order not found') {
              $state.go('retailer.purchase-orders')      
              $scope.erromessage = 'Oops! We could not find your purchase order on this purchase number';
              $scope.alertdanger = true;
            }
          })
      } else {
        console.log('currentshop not defined')
      }
    }

    $scope.sendTheMail = function () {
      //console.log('------------ sendTheMail!!2');
      // will call the backend function to send the email
      // var myemailbody = document.getElementById('my-purchase-order-body');
      var sendFeedBackButtonText = $language.translate('CLICK_ON_THIS_LINK_TO_GIVE_US_FEEDBACK')
      // console.log('Hello langauar: ', languaaer);
      var purcahaseOrder = $scope.purchase_order;
      if(purcahaseOrder.status[purcahaseOrder.status.length -1].status == 'New'){
        purcahaseOrder.status[purcahaseOrder.status.length -1].status = 'Processing'
      }
      var sendFeedBack = 'https://prismanote.com/brand/purchase-order/' + $scope.purchase_order.number
      var request_body = {
        tomail: $scope.purchase_order.email.toemail,
        subject: $scope.purchase_order.email.subject,
        message: $scope.purchase_order.email.body ? $scope.purchase_order.email.body : "", 
        purchase_order: purcahaseOrder,
        shop: { _id: $rootScope.currentShop._id, name: $rootScope.currentShop.name, nameSlug: $rootScope.currentShop.nameSlug },
        sendFeedBackLink: sendFeedBack,
        sendFeedBackButtonText: sendFeedBackButtonText,
        language: $scope.language
      }
      $scope.loading = true

      $api.post('send-purchase-orders-in-mail', request_body)
        .then(function (response) {
          $scope.loading = false
          $scope.success_message = true
          $scope.isEmailAlreadySent = true
        })
        .catch(function (reason) {
          $scope.loading = false
          console.error('Error while sending purchase order', reason)
        })
    }

    $scope.setMailLanguage = function (language) {
      $scope.language = language
      $language = language
      console.log(language)
      $rootScope.changeLanguage(language)
    }
  }])
