prismanoteApp.controller('editDocumentModalController', ['$scope', 'doc', '$uibModalInstance', '$pdf', '$rootScope', '$api', '$jspm', '$log', '$language', '$device', '$printer', '$printnode',
  function ($scope, doc, $uibModalInstance, $pdf, $rootScope, $api, $jspm, $log, $language, $device, $printer, $printnode) {
    $scope.doc = doc

    if($scope.doc.logoSizeOverride === 0 || !$scope.doc.logoSizeOverride) {
      $scope.doc.logoSizeOverride = 30
    }

    $scope.originalSizes = [
      'A4',
      'A5',
      'A5LANDSCAPE',
      'A6',
      'Letter'
    ]

    $scope.sizes = angular.copy($scope.originalSizes)
    $scope.printerSizes = [doc.printerPageFormat]
    $scope.printers = [doc.printerName]
    $scope.trays = [doc.paperTray]
    $scope.jspm = !$rootScope.currentShop.printNode || $rootScope.currentShop.printNode.enabled === false
    $scope.noDocError = false
    $scope.showDefaultConditions = false

    $scope.maxMargins = {
      height: null,
      width: null
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss()
    }

    $scope.saveDocument = function () {
      if ($scope.doc.name === 'GIFTCARD') {
        $scope.doc.margins = {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        }
      }
      $device.updateDevice()
      $uibModalInstance.close($scope.doc)
    }

    $scope.checkPageSize = function (size) {
      //disabled for not losing any settings

      // if (size === 'A6') {
      //   $scope.doc.combine = false
      //   if ($scope.doc.rotation === 0 || $scope.doc.rotation === 180) {
      //     $scope.maxMargins.height = 419
      //     $scope.maxMargins.width = 297
      //   } else {
      //     $scope.maxMargins.height = 297
      //     $scope.maxMargins.width = 419
      //   }
      // }
      // if (size === 'A5') {
      //   if ($scope.doc.rotation === 0 || $scope.doc.rotation === 180) {
      //     $scope.maxMargins.height = 595
      //     $scope.maxMargins.width = 419
      //   } else {
      //     $scope.maxMargins.height = 419
      //     $scope.maxMargins.width = 595
      //   }
      // }
      // if (size === 'A4') {
      //   if ($scope.doc.rotation === 0 || $scope.doc.rotation === 180) {
      //     $scope.maxMargins.height = 841
      //     $scope.maxMargins.width = 595
      //   } else {
      //     $scope.maxMargins.height = 595
      //     $scope.maxMargins.width = 841
      //   }
      // }
      // if (size === 'Letter') {
      //   if ($scope.doc.rotation === 0 || $scope.doc.rotation === 180) {
      //     $scope.maxMargins.height = 792
      //     $scope.maxMargins.width = 612
      //   } else {
      //     $scope.maxMargins.height = 612
      //     $scope.maxMargins.width = 792
      //   }
      // }
    }

    $scope.rotationChanged = function (rotation) {
      $scope.doc.rotation = rotation
      $scope.doc.vertical = false
      if (rotation === 0 || rotation === 270) {
        $scope.doc.combine = false
      }
      $scope.checkPageSize($scope.doc.format)
    }

    $scope.orientationChanged = function (orientation) {
      if (orientation) {
        $scope.doc.combine = false
      }
      $scope.checkPageSize($scope.doc.format)
    }

    $scope.checkPageSize($scope.doc.format)

    $scope.printGiftCardExample = function () {
      // First we need to save the current settings
      $scope.doc.margins = {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      }
      $api.put('shop/' + $rootScope.currentShop._id + '/pageFormats/' + $scope.doc._id, { page: $scope.doc }, null, 2)
        .then(function (updateRes) {
          $pdf.downloadPdf('generate-giftcard', {
            shopId: $rootScope.currentShop._id,
            example: true,
            deviceId: $rootScope.currentDevice._id
          }, 'example_giftcard.pdf')
            .then(function (data) {

            })
            .catch(function (reason) {
              console.error(reason)
            })
        })
        .catch(function (reason) {
          console.error('Error while updating pageFormats', reason)
        })
    }

    $scope.getInstalledPrinters = function () {
      $scope.alert = null
      $jspm.getInstalledPrinters(true)
        .then(function (printers) {
          $scope.printers = printers
          if ($scope.doc.printerName) {
            $scope.selectedPrinter($scope.doc.printerName)
          }
        })
        .catch(function (reason) {
          $jspm.closeConnection()
          $log.error(reason)
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(reason)
          }
        })
    }

    $scope.selectedPrinter = function (printerName) {
      var printer = _.find($scope.printers, { name: printerName })
      $scope.printer = printer
      if (!printer) {
        return
      }
      $log.info('Selected printer', printer)
      $scope.trays = printer.trays
      $scope.alert = null
      $scope.printerSizes = printer.papers
      //   .filter(function (p) {
      //   return _.findIndex($scope.originalSizes, function (s) { return s === p }) >= 0
      // })
      $log.info('Supported page sizes for this printer', $scope.sizes)
      if ($scope.sizes.length === 0) {
        $scope.alert = {
          type: 'danger',
          msg: $language.translate('THIS_PRINTER_DOES_NOT_SUPPORT_PAPER_SIZES_WE_USE_IN_PRISMANOTE')
        }
      }
    }

    $scope.removePrinter = function () {
      $rootScope.currentDevice.documents[$scope.documentIdx].printerName = null
      $scope.sizes = angular.copy($scope.originalSizes)
      $rootScope.currentDevice.documents[$scope.documentIdx].pageFormat = $scope.sizes[0]
      $rootScope.currentDevice.documents[$scope.documentIdx].printDevice = null
    }

    $scope.getPrinterDetails = function (printerId) {
      $printnode.getPrinters(null, printerId)
        .then(function (printerDetails) {
          $scope.trays = printerDetails[0].capabilities.bins
          $scope.printerSizes = []

          for (var name in printerDetails[0].capabilities.papers) {
            $scope.printerSizes.push(name)
          }
        })
        .catch(function (reason){
          console.error('Error while printnode fetching printer details', reason)
          toastr.error(reason.data.message)
        })
    }

    $scope.getCurrentDevice = function(){
      $scope.alert = null
      $device.getCurrentDevice()
      $scope.documentIdx = _.findIndex($rootScope.currentDevice.documents, {type: $scope.doc.name})

      if($scope.documentIdx < 0) {
        $scope.alert = {
          type: 'danger',
          msg: $language.translate('DEFINITION_DOES_NOT_EXISTS_FOR_THIS_DEVICE_PLEASE_GENERATE_DOCUMENTS_FOR_DEVICE') + ': '  + $rootScope.currentDevice.name
        }
        $scope.noDocError = true
        return
      }

      var doc = $rootScope.currentDevice.documents[$scope.documentIdx]
      if (doc.paperTray) {
        $scope.trays = [doc.paperTray]
      }

      if (doc.printerName) {
        $scope.printers = [{ name: doc.printerName }]
      }

      if($scope.jspm === false && doc.printNode && doc.printNode.printerId) {
        $scope.getPrinterDetails(doc.printNode.printerId)
      }
    }

    $scope.changePrinter = function () {
      var data  = {}

      if($scope.jspm === false && $rootScope.currentDevice.documents[$scope.documentIdx].printNode) {
        data = $rootScope.currentDevice.documents[$scope.documentIdx].printNode
      }

      $printer.openSelectPrinterModal(data)
        .then(function (result) {
          if(result) {
            if($scope.jspm) {
              var printer = result.selectedPrinter

              if (result.selectedDevice && result.devicePrinter) {
                printer = result.devicePrinter
              }
              $rootScope.currentDevice.documents[$scope.documentIdx].printerName = printer
              $rootScope.currentDevice.documents[$scope.documentIdx].printDevice = result.selectedDevice ? result.selectedDevice : null

            } else {
              $rootScope.currentDevice.documents[$scope.documentIdx].printerName = null
              $rootScope.currentDevice.documents[$scope.documentIdx].printDevice = null
              $rootScope.currentDevice.documents[$scope.documentIdx].printNode = result.printNode

              $scope.getPrinterDetails(result.printNode.printerId)
            }

            $device.updateDevice()
              .then(function() {
                $device.getCurrentDevice()
              })
          }
        })
        .catch(function (reason){
          if(reason !== 'cancel') {
            $log.error('Error selecting printer', reason)
          }
        })
    }

    $scope.getPrinterName = function () {
      var doc = $rootScope.currentDevice.documents[$scope.documentIdx]
      var printerName = ''
      if($scope.jspm) {
        if (!doc || !doc.printerName) {
          return $language.translate('NOT_SET')
        }
        printerName = doc.printerName

        if (doc.printDevice && doc.printDevice.name) {
          printerName += ' (' + doc.printDevice.name + ')'
        }
      } else {
        if(!doc.printNode || !doc.printNode.printerName || !doc.printNode.deviceName) {
          return $language.translate('NOT_SET')
        }
        printerName = doc.printNode.printerName + ' (' + doc.printNode.deviceName + ')'
      }
      return printerName
    }

  }])
