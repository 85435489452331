prismanoteApp.controller('retailerShopProductStockController', ['$scope', '$uibModalInstance', 'product', '$shop', '$productConfig', '$transaction',
  function ($scope, $uibModalInstance, product, $shop, $productConfig, $transaction) {
    console.log('********* retailer shop product **********')
    console.log('product', product)

    $scope.shopId = product.shopId
    $scope.productId = product.productId
    $scope.currentPurchasePrice = product.purchasePrice
    $scope.warringMessage = false
    $scope.isLoading = false
    $scope.purchasePriceNotInPurchaseOrderNotice = $productConfig.purchasePriceNotInPurchaseOrderNotice()
    console.table($scope.purchasePriceNotInPurchaseOrderNotice)

    $scope.getShopProductStockRecord = function (shopId, productId) {
      console.log('----- getShopProductStockRecord!');
      // Get Stock Product stock changes records
      $scope.isLoading = true
      $shop.getShopProductStockRecord(shopId, productId)
        .then(function (success) {
          console.log('Purchase Order Stock sfasfasfasd ', success)
          // Success orders are the web shop orders serve by retailer
          // success purchase Order are the serve by the company
          $scope.isLoading = false
          $scope.orderStock = success.order
          $scope.purchaseOrderStock = success.purchaseOrder
          if (success.lastDatePurchased) {
            var lastDatePurchased = new Date(success.lastDatePurchased)
            $scope.lastDatePurchased = lastDatePurchased.getDate() + '-' + (parseInt(lastDatePurchased.getMonth()) + 1) + '-' + lastDatePurchased.getFullYear()
          }else { $scope.lastDatePurchased = ''; }
          console.log('$scope.lastDatePurchased ', $scope.lastDatePurchased);
          var index = _.find($scope.purchaseOrderStock, function (o) { return o.purchasePrice == $scope.currentPurchasePrice })
          console.table('$scope.purchaseOrderStock', $scope.purchaseOrderStock)
          console.log('index', index)
          if (!index)$scope.warringMessage = true
          else if (index < 0) $scope.warringMessage = true
          else $scope.warringMessage = false
        })
        .catch(function (error) {
          $scope.isLoading = false
          console.error('error ===>', error)
        })
    }

    $scope.changePurchasePrice = function (purchaseOrderDetails) {
      console.log('Purchase Order Details', purchaseOrderDetails)
      $uibModalInstance.close(purchaseOrderDetails)
    }

    $scope.cancel = function () {
      console.log('Close the retailer product modal opening modal')
      // $('.modal-content').hide()
      $uibModalInstance.dismiss('cancel') 
      //$uibModalInstance.close()
    }

    $scope.cancelAndRedirect = function () {
      console.log('Close the retailer product modal opening modal')
      $uibModalInstance.close({ isFromBackButton: true });
    }
    
    $scope.openTransaction = function (id, type, old) {
      if(!type) { type = 'shop-purchase' }
      $transaction.openTransaction(id, type, old)
      $uibModalInstance.close({ isFromBackButton: true });
    }

  }])
