prismanoteApp.controller('retailerAddEmployeeController', ['$scope', '$rootScope', '$uibModal', '$state', '$employee',
  function ($scope, $rootScope, $uibModal, $state, $employee) {
    $scope.employee = {
      enabled: true,
      shopRights: angular.copy($rootScope.userRights),
      function: 'sales',
      availability: [
        {
          day: 'monday',
          morning: false,
          afternoon: false
        },
        {
          day: 'tuesday',
          morning: false,
          afternoon: false
        },
        {
          day: 'wednesday',
          morning: false,
          afternoon: false
        },
        {
          day: 'thursday',
          morning: false,
          afternoon: false
        },
        {
          day: 'friday',
          morning: false,
          afternoon: false
        },
        {
          day: 'saturday',
          morning: false,
          afternoon: false
        },
        {
          day: 'sunday',
          morning: false,
          afternoon: false
        }
      ],
      hasRequiredPin: false
    }

    $scope.addEmployee = function () {
      var isRightsSelected = false
      for (var i = 0; i < $scope.employee.shopRights.length; i++) {
        if ($scope.employee.shopRights[i].enabled) {
          isRightsSelected = true
          break
        }
      }

      if (isRightsSelected) {
        $scope.employee.isEmployee = true
        $scope.employee.username = $scope.employee.email ? $scope.employee.email : $scope.employee.firstName || '' + ' ' + $scope.employee.lastName || ''
        // $scope.employee.role = 'retailer'
        $scope.employee.shopId = $rootScope.currentShop._id
        $scope.employee.shopName = $rootScope.currentShop.name
        if (!$scope.employee.email) $scope.employee.email = ('autocreated-' + $scope.employee.firstName + '-' + $scope.employee.initials + '@' + $rootScope.currentShop.nameSlug + '.nl').toLowerCase()
        // console.log('add employee: ', $scope.employee);
        $employee.addEmployee($scope.employee).then(function (response) {
          // console.log('ADDED SUCCESSFULLY: ', response);
          $state.go('retailer.employees')
        }).catch(function (error) {
          if (error.status == 400) {
            $scope.alert = {
              type: 'danger',
              msg: error.data.message
            }
          } else {
            $scope.alert = {
              type: 'danger',
              msg: 'Internal Server Error'
            }
          }
        })
      } else {
        $scope.alert = {
          type: 'danger',
          msg: 'Select any rights first'
        }
      }
    }

    $scope.pinRequired = function () {
      $scope.employee.pincode = ''
      $scope.employee.pin2 = ''
    }

    // Niek function
    $scope.openCropImageModal = function () {
      if (!$scope.employee.profileImage) $scope.employee.profileImage = ''
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/image-crop-modal.html',
        controller: 'cropImageModalController',
        keyboard: false,
        backdrop: 'static',
        resolve: {
          defaultImage: function () {
            return $scope.employee.profileImage.indexOf('https://ui-avatars.com/api') == 0
          },
          loadImage: function(){
            return undefined;
          },
          areaType: function () {
            return 'circle';
          }
        }
      })
      modalInstance.result.then(function (result) {
        // console.log('Hey, result: ', result);
        if (!result) {
          $scope.employee.profileImage = '' // User choosen to delete his photo;
        } else {
          $scope.employee.file = result
        }
      }, function () {
        // dismissed
      })
    }

    $scope.rightsChanged = function (right) {
      if(!right) {
        return
      }
      var requiredRightsForServiceApp = [
        'MANAGE_CUSTOMERS',
        'MANAGE_SUPPLIERS',
        'MANAGE_TRANSACTIONS_OFFERS',
        'MANAGE_REPAIRS_SPECIAL',
        'MANAGE_WEBSHOP_ORDER',
        'MANAGE_DAYSTATE'
      ]

      if(right.name === 'USE_SERVICE_APP' && right.enabled) {
        for(var i =0; i < requiredRightsForServiceApp.length; i++) {
          var idx = _.findIndex($scope.employee.shopRights, {name: requiredRightsForServiceApp[i]})
          if(idx >= 0) {
            $scope.employee.shopRights[idx].enabled = true
          }
        }
      }else if(requiredRightsForServiceApp.indexOf(right.name) >= 0 && right.enabled === false) {
        var idx = _.findIndex($scope.employee.shopRights, {name: 'USE_SERVICE_APP'})
        if(idx >= 0) {
          $scope.employee.shopRights[idx].enabled = false
        }
      }
    }
  }])
