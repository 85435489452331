prismanoteApp.controller('retailerDeletedTransactionsController', ['$scope', '$rootScope', '$uibModal', '$api', '$retailer','$timeout', '$state', '$window', '$language', '$data', 'cookieService', '$log', '$transaction', '$customer', 'dialogs',
  function ($scope, $rootScope, $uibModal, $api, $retailer,$timeout, $state, $window, $language, $data, cookieService, $log, $transaction, $customer, dialogs) {
    $scope.pageSize = '10'
    $scope.currentPage = 1

    $scope.totalTransactions = 0
    $scope.transaction = []

    $scope.customerName = function(customer) {
      return $customer.makeCustomerName(customer)
    }

    $scope.popover = function (item) {
      var itemList = []
      var alertString = '';
      if (item._id) {
        if(item.details && item.details.length > 0) {
          for (var i = 0; i < item.details.length; i++) {
            itemList.push(item.details[i].name)
            alertString = alertString + 'Product: '+item.details[i].name +' Prijs: €'+ (item.details[i].originalTotal ? item.details[i].originalTotal : item.details[i].total)+ '<br>';
            if(item.details.length === itemList.length) {
              return alertString
            }
          }
        }
      }
    }

    $scope.checkMethod = function(data) {
      if (!data) {
        return
      }
      if(data && data.length > 0) {
        var result = ''
        if(data.length === 1) {
          if(data[0].method === 'bankAmount' || data[0].method === 'insurance') {
            if(!data[0].bankPaymentAcknowledgement) {
              result += data[0].method + ' (Pending)'
              //-result += $language.translate(data[0].method.toUpperCase()) + $language.translate('PENDING')
            } else {
              result += data[0].method + ' (Paid)'
              //-result += $language.translate(data[0].method.toUpperCase()) + $language.translate('PAID')
            }
          } else {
            // var resultData = $language.translate(data[0].method)
            result += data[0].method.toUpperCase()
          }
          return result
        } else {
          var counter =0;
          for (var i = 0; i < data.length; i++) {
            counter++;
            //result += $language.translate(data[i].method.toUpperCase()) + ' / '
            result += data[i].method.toUpperCase() + ' / '
            if(counter === data.length) {
              result = result.substring(0, result.length - 1);
              return result
            }
          }
        }

      }
    }

    $scope.getReceiptOrInvoiceNumber = function(transaction){
      if(transaction.isInvoice && transaction.invoiceNumber) {
        return transaction.invoiceNumber
      }

      if(transaction.receipt && transaction.receipt.number) {
        return transaction.receipt.number
      }

      return '-'
    }

    $scope.getTransactions = function () {
      $scope.loading = true

      $retailer.getShop()
        .then(function (shop) {
          $api.get('deleted-transaction/' + shop._id + '?limit=' + $scope.pageSize + '&pageNumber=' + $scope.currentPage + '&statusFilter=' + $scope.filterType + '&exclude=product-reserve', null, null, 2)
            .then(function (response) {
              $scope.loading = false
              if(response.status === 204) {
                $scope.transactions = []
                return
              }
              $scope.transactions = response.data.transactions
              $scope.totalTransactions = response.data.total
            })
            .catch(function (reason) {
              $log.error('Error while fetching transactions', reason)
              $scope.loading = false
              toastr.error(reason.message)
            })
        })
        .catch(function (reason) {
          $log.error('Error while fetching shop', reason)
        })
    }

    $scope.saveCurrentPage = function (page) {
      $scope.getTransactions()
    }

    $scope.refreshTransactions = function () {
      $scope.pageSize = '10'
      $scope.currentPage = 1
      $scope.saveCurrentPage($scope.currentPage)
      $scope.getTransactions()
    }

    $scope.$on('changeSelectedShop', function () {
      $scope.getTransactions()
    })

    $scope.restoreTransaction = function (id, parent) {
      var dlg = dialogs.confirm(
        $language.translate('CONFIRM'),
        $language.translate('RESTORE_THIS_AND_ALL_CONNECTED_TRANSACTIONS'),
        {
          size: 'md'
        })
      dlg.result.then(function (btn) {
        if(btn === 'yes') {
          var data = {
            transactionId: id,
            shopId: $rootScope.currentShop._id,
            user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
            transactions: []
          }

          if(parent && parent !== '') {
            data.transactions = [parent]
          }

          $api.post('deleted-transaction/restore', data, null, 2)
            .then(function () {
              toastr.success($language.translate('TRANSACTION_RESTORED'))
              $scope.getTransactions()
            })
            .catch(function (reason) {
              console.error('Error while restoring transaction', reason)
              toastr.error($language.translate('ERROR_RESTORING_TRANSACTION'))
            })
        }
      })
    }

    $scope.back = function () {
      window.history.back()
    }

  }])