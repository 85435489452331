prismanoteApp.controller('selfChangePasswordModalController', ['$scope', '$rootScope', '$api', '$uibModalInstance', '$language', 'prompt',
  function ($scope, $rootScope, $api, $uibModalInstance, $language, prompt) {
    $scope.changePassword = function () {
      $scope.alert = {}
      if (!$scope.pwdNormal() || !$scope.pwdCapital() || !$scope.pwdSpecial() || !$scope.pwdNumber() || !$scope.pwdLength()) {
        $scope.alert = {
          type: 'danger',
          msg: $language.translate('PASSWORD_DOES_NOT_MEET_REQUIREMENTS')
        }
        return
      }

      prompt({
        title: $language.translate('CHANGE_PASSWORD'),
        message: $language.translate('IT_WILL_LOGOUT_FROM_OTHER_BROWSER_AS_WELL'),
        buttons: [
          {
            label: $language.translate('YES'),
            primary: true
          },
          {
            label: $language.translate('NO'),
            primary: false,
            cancel: true
          }
        ]
      }).then(function (response) {
        console.log('response: ', response);
        if (response && response.primary) {
          changePassword(true);
        } else {
          console.log('Secondary');
          changePassword(false);
        }
      }).catch(function (error) {
        console.log('error: inprompnt: ', error);
      });
    }

    function changePassword(isClearSession) {
      $api.post('self-change-password', {
        passwords: {
          newPassword: $scope.newPassword1,
          newPassword2: $scope.newPassword2,
          currPassword: $scope.currentPassword,
        },
        userId: $rootScope.user._id,
        isClearSession: true
      }).then(function (response) {
        // $uibModalInstance.close();
        $uibModalInstance.close('success')
      }).catch(function (reason) {
        console.error(reason)
        $scope.alert = {
          type: 'danger',
          msg: reason
        }
      })
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss()
    }

    $scope.pwdNormal = function () {
      if (!$scope.newPassword1) return
      return $scope.newPassword1.match(/[a-z]/m)
    }
    $scope.pwdCapital = function () {
      if (!$scope.newPassword1) return
      return $scope.newPassword1.match(/[A-Z]/m)
    }
    $scope.pwdSpecial = function () {
      if (!$scope.newPassword1) return
      return $scope.newPassword1.match(/[^a-zA-Z0-9]/gm)
    }
    $scope.pwdNumber = function () {
      if (!$scope.newPassword1) return
      return $scope.newPassword1.match(/\d/gm)
    }
    $scope.pwdLength = function () {
      if (!$scope.newPassword1) return
      return $scope.newPassword1.length > 7
    }
  }])
