prismanoteApp.controller('retailerSuppliersController', ['$scope', '$rootScope', '$stateParams', '$state', '$q', '$retailer', '$api', '$uibModal', 'prompt', '$window', '$language', 'campaignFact',
  function ($scope, $rootScope, $stateParams, $state, $q, $retailer, $api, $uibModal, prompt, $window, $language, campaignFact) {
    console.log('Suppliers Controller is called!')

    $scope.awsUrl = $rootScope.awsUrl
    //$scope.facebookId = null
    $scope.activeTab = 0
    $scope.supplierSearch = ''
    $scope.shopCompanies = []
    $scope.language = $rootScope.language
    $scope.defaultColumns = [
      { name: 'Name', isSelected: { c: true } },
      { name: 'Email', isSelected: { c: true } },
      { name: 'Brand', isSelected: { c: true } },
      { name: 'Telephone', isSelected: { c: true } },
      { name: 'Type', isSelected: { c: true } },
      { name: 'Zip code', isSelected: { c: true } },
      { name: 'House number Street', isSelected: { c: true } },
      { name: 'Country', isSelected: { c: true } },
      { name: 'Repair Telephone', isSelected: { c: true } }
    ]
    $scope.viewby = 50
    $scope.sendRequest = false
    $scope.updates = []
    $scope.campaigns = []

    $scope.orderByDate = function (log) {
      var date = new Date(log.date)
      return date
    }

    $scope.closeAlert = function () {
      $scope.alert = null
    }

    $scope.openAddCompanyModal = function (supplierSearch) {
      console.log('called-------------------------------------')
      try {
        // console.log(1, supplierSearch)
        // console.log(2)
        if (!supplierSearch) supplierSearch = ''
        // console.log('openAddCompanyModal called: ', supplierSearch)
        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/retailer-add-company-modal.html',
          controller: 'retailerAddCompanyModalController',
          size: 'lg',
          resolve: {
            searchValue: function () {
              return supplierSearch
            }
          }
        })

        modalInstance.result.then(function (result) {
          console.log('result**************************', result)
          if (result == 'close') {
            $scope.getShopCompanies()
          }
        }, function (error) {
          console.log('modal open error: ', error)
        })
      } catch (error) {
        console.log('error: ', error)
      }
    }

    $scope.$on('changeSelectedShop', function () {
      $scope.getShopCompanies()
    })

    $rootScope.$on('updateCompanyList', function () {
      $scope.getShopCompanies()
    })

    $scope.getShopCompanies = function () {
      var page = $scope.currentPage + 1
      if ($rootScope.currentShop) {
        $api.get('shop-retailer-companies?limit=' + $scope.itemsPerPage + '&pageNumber=' + page, { shopId: $rootScope.currentShop._id })
          .then(function (response) {
            if (response && response.data) {
              $scope.shopCompanies = []
              $scope.total = 0
              var shopCompaniesTemp = _.orderBy(response.data, ['name'],['asc']);
              $scope.shopCompanies = shopCompaniesTemp
              $scope.originalShopCompanies = JSON.parse(JSON.stringify(shopCompaniesTemp))
              // $scope.total = response.data.totalCompanies;
            }
          })
          .catch(function (reason) {
            console.error('Error getting shop-companies', reason)
          })
      }
    }

    $scope.deleteShopCompany = function (companyid) {
      prompt({
        title: $language.translate('DELETE_COMPANY_FROM_SHOP'),
        message: $language.translate('ARE_YOUR_SURE_WANT_TO_REMOVE_COMPANY_FROM_YOUR_SHOP'),
        buttons: [
           { label: $language.translate('YES'), primary: true, id: 0 },
           { label: $language.translate('CANCEL'), primary: false, cancel: true, id: 1 }
        ]
      }).then(function (value) {
        if (value.id === 0) {
          $api.post('company/delete-suggestion', { companyId: companyid, shopId: $rootScope.currentShop._id })
            .then(function (response) {

            })
            .catch(function (reason) {
              console.error('Error deleting suggestion', reason)
            })
        }
      })
    }

    $scope.setItemsPerPage = function (num) {
      $scope.itemsPerPage = parseInt(num)
      $scope.currentPage = 0 // reset to first page
      $scope.getShopCompanies()
    }

    $scope.changeThePage = function (direction) {
      var totalPages = Math.ceil($scope.total / $scope.itemsPerPage)

      if (direction == 'next') {
        var page = $scope.currentPage + 1
        if ((page + 1) <= totalPages) {
          $scope.currentPage = page
          $scope.getShopCompanies()
        } else {
          // do nothing
          // $scope.getShopCompanies();
        }
      } else if (direction == 'previous') {
        var page = $scope.currentPage - 1
        if (page < 0) {
          // do nothing but just refresh the page
          // $scope.getShopCompanies();
        } else {
          $scope.currentPage = page
          $scope.getShopCompanies()
        }
      }
    }

    $scope.setItemsPerPage($scope.viewby)

    $scope.redirectToCompany = function (cmp) {
      $rootScope.isCompanySuggestion = cmp.isSuggestion
      $state.go('retailer.supplier', { nameSlug: cmp.nameSlug })
    }

    $scope.export = function () {
      // console.log("wont be working for now");
    }

    $scope.askToCustomizeColumnns = function () {
      // open the modal
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/retailer-customize-company-columns-modal.html',
        controller: 'retailerCustomizeCompanyColumnsController',
        size: 'lg',
        resolve: {
          columns: function () {
            return ($scope.defaultColumns)
          }
        }
      })

      modalInstance.result.then(function (result) {
      }, function () {

      })
    }

    $scope.getTheTDValue = function (action_name, comp) {
      // console.log("product handling guy check the action Name" , action_name ,comp);
      if (action_name == 'Name') {
        return comp.name
      } else if (action_name == 'Email') {
        return comp.email
      } else if (action_name == 'Brand') {
        var brands = comp.brands
        var stringToReturn = ''
        for (var i = 0; i < brands.length; i++) {
          if (i < brands.length - 1) { stringToReturn = stringToReturn + brands[i].name + '|' } else { stringToReturn = stringToReturn + brands[i].name }
        }
        return stringToReturn
      } else if (action_name == 'Type') {
        if (comp.type == 'supplier') return 'Supplier'
        else if (comp.type == 'company') return 'Repairer'
        else return comp.type
      } else if (action_name == 'Telephone') {
        var result = ''
        if(comp.phone && comp.phone.landLine) {
          result = comp.phone.landLine
        }
        if(comp.phone && comp.phone.landLine && comp.phone.mobilePhone) {
          result += ' | '
        }
        if(comp.phone && comp.phone.mobilePhone) {
          result += comp.phone.mobilePhone
        }
        return result
      } else if (action_name == 'Repair Telephone') {
        if (comp.repairPhone && comp.repairPhone.landLine && comp.repairPhone.mobilePhone) {
          var phoneString = comp.repairPhone.landLine + ' | ' + comp.repairPhone.mobilePhone
          return phoneString
        }
        return ' '
      } else if (action_name == 'Zip code') {
        if (comp.address && comp.address.postalCode) {
          var returnString = comp.address.postalCode
          return returnString
        }
        return ' '
      } else if (action_name == 'House number Street') {
        if (comp.address && comp.address.houseNumber && comp.address.street) {
          var ret_string = comp.address.houseNumber + '  ' + comp.address.street
          return ret_string
        }
        return ' '
      } else if (action_name == 'Country') {
        if (comp.address && comp.address.country) {
          return comp.address.country
        }
        return ' '
      } else {
        return ' '
      }
    }

    $scope.findCompanies = function (value) {
      if (value) {
        if ($scope.originalShopCompanies && $scope.originalShopCompanies.length && value.length < 3) return
        $scope.shopCompanies = []
        var shopCompaniesLength = $scope.originalShopCompanies.length
        for (var i = 0; i < shopCompaniesLength; i++) {
          var company = $scope.originalShopCompanies[i]
          if (company &&
						company.name && company.name.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0 ||
						company.nameSlug && company.nameSlug.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0 ||
						company.email && company.email.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0 ||
						company.name && company.name.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0
          ) {
            $scope.shopCompanies.push(company)
          }
        }
      } else {
        $scope.shopCompanies = JSON.parse(JSON.stringify($scope.originalShopCompanies))
      }
    }
  }])
