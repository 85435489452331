prismanoteApp.controller('adminDuplicateEANController', ['$scope', '$rootScope', '$product',
    function ($scope, $rootScope, $product) {
        console.log('adminDuplicateEANController called: ');
        $scope.eanlist = [];
        $scope.productNumberList = [];
        $scope.localdata = {
            isShowEAN: true  // If true then will show the EAN else will show the Product Number if any list available
        }

        $scope.fetchTheDuplicateEanNumber = function () {
            $product.fetchTheDuplicateEanNumber().then(function (response) {
                if (response && response.data && response.data.data && response.data.data.length) {
                    $scope.eanlist = response.data.data;
                }
                console.log('response: ', $scope.eanlist);
            }).catch(function (error) {
                console.error('Error here: ', error);
            });
        }

        $scope.fetchTheDuplicateProductNumber = function () {
            $product.fetchTheDuplicateProductNumber().then(function (response) {
                if (response && response.data && response.data.data && response.data.data.length) {
                    $scope.productNumberList = response.data.data;
                }
                console.log('response: ', $scope.productNumberList);
            }).catch(function (error) {
                console.error('Error here: ', error);
            });
        }
    }])

