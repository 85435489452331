prismanoteApp.controller("statisticsPDFModalController", ['$scope', '$uibModalInstance', '$rootScope', 'data',
    function ($scope, $uibModalInstance, $rootScope, data) {
        console.log('statisticsPDFModalController called: ', data);
        $scope.localdata = {
            isExportAll: true,
            canShowPurchasePrice: true,
            isSortingBasedOnProductNumber: true
        }

        $scope.exportToPdf = function () {
            console.log('exportToPdf called: ', $scope.localdata);
            $uibModalInstance.close($scope.localdata);
        }

        $scope.close = function (data) {
            $uibModalInstance.close(data);
        }
    }])
