var app = prismanoteApp.controller('retailerAssortment1Controller', ['$stateParams', '$scope', '$rootScope', '$uibModal', '$anchorScroll', '$api', '$q', '$translate', '$retailer', 'prompt', '$language', '$product', '$state', '$shop', '$csv2json', '$collection', '$retailerFilters',
  function ($stateParams, $scope, $rootScope, $uibModal, $anchorScroll, $api, $q, $translate, $retailer, prompt, $language, $product, $state, $shop, $csv2json, $collection, $retailerFilters) {
    // console.log('--------- New Assormtment Controller -------------')

    $scope.filterCounter = 0
    $scope.stockLoad = true
    $rootScope.stockFilter = {}
    $scope.productFilter = {}
    $scope.getDataByPage = false;
    $scope.categoryButton = false;
    $scope.supplierButton = false;
    $scope.brandButton = false;
    $scope.productButton = false;
    $scope.genderButton = false;
    $scope.particularsButton = false;
    $scope.glassMaterialButton = false;
    $scope.timeIndicationButton = false;
    $scope.dialIndexButton = false;
    $scope.caseMaterialButton = false;
    $scope.caseShapeButton = false;
    $scope.strapTypeButton = false;
    $scope.strapMaterialButton = false;
    $scope.jewelleryTypeButton = false;
    $scope.jewelleryMaterialButton = false;
    $scope.colorButton = false;
    $scope.collectionButton = false;
    $scope.loading = false
    $scope.isInfiniteLoad = false
    $scope.loadingCSV = false
    $scope.orderBy = {"sort":{"articleNumber": "asc"}}
    $scope.showSearchOption = false;
    $scope.filterData = '';
    $scope.data = [];
    $scope.errMsg = false
    //$scope.getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter);

    var parentScope = $scope
    setTimeout(function () {
      if(parentScope.stockLoad){
        parentScope.isInfiniteLoad = true;
      }
    }, 15000);
    $scope.separator = 'Semi-colon'
    $scope.selected = true;
    $scope.shopWebSite = ''
    $scope.button1 = function () {
        //do logic for button 1
        $scope.selected = !$scope.selected;
        $scope.separator = 'Comma'
        console.log('btn1 clicked',$scope.selected);
        console.log('$scope.separator',$scope.separator);
    }

    $scope.button2 = function () {
        //do logic for button 2
        $scope.selected = !$scope.selected;
        $scope.separator = 'Semi-colon'
        console.log('$scope.separator:',$scope.separator);
    }

    $scope.toggleSearchOptions = function() {
      $scope.showSearchOption = !$scope.showSearchOption
    }

    $scope.onSearchProducts = function(value){
      if(value && value.isDefaultSearch && typeof(value.searchValue) != 'undefined'){
        $scope.findProductBasedSearchValue(value.searchValue);
      } else if(value && !value.isDefaultSearch && value.searchValue){
        $scope.stockLoad = true
        $scope.stockProducts = []
        $scope.searchMode = true
        $scope.searchValue = value.searchValue;
        $shop.searchStockProducts($rootScope.currentShop._id, value.searchValue, value.params)
          .then(function (products) {
            // console.log('products', products)
            $scope.stockProducts = products
            $scope.totalProducts = $scope.stockProducts.length
            $scope.stockLoad = false
          })
          .catch(function (error) {
            console.error('Error', error)
          })
      }
    }

    $scope.getShopWebsite = function () {
      $api.get('shop-website/' + $rootScope.currentShop._id)
						.then(function (result) {
              //console.log('result:::',result);
              $scope.shopWebSite = result.data.shopDetail.proShopUrl
            })
    }

    $scope.$watch('filterCounter', function () {
      if ($scope.filterCounter > 8) {
        if ($scope.stockFilter && $scope.productFilter) {
          if ($scope.productFilter.watch.hasOwnProperty('strap') && $scope.productFilter.watch.strap.hasOwnProperty('model') && $scope.productFilter.watch.strap.model && Object.keys($scope.productFilter.watch.strap.model) && Object.keys($scope.productFilter.watch.strap.model).length > 0) {
            $scope.productFilter.watch = { strap: { model: $scope.productFilter.watch.strap.model } };
          }
          if ($scope.stockFilter.discount ||
            $scope.stockFilter.inStock ||
            $scope.stockFilter.isBestseller ||
            $scope.stockFilter.notOnWebShop ||
            $scope.stockFilter.show ||
            $scope.stockFilter.supplier ||
            $scope.productFilter.gender ||
            $scope.productFilter.collection ||
            ($scope.productFilter.watch && $scope.productFilter.watch.hasDateFunction ||
              $scope.productFilter.watch.hasLightFunction ||
              $scope.productFilter.watch.hasSwissMovement ||
              $scope.productFilter.watch.indication ||
              $scope.productFilter.watch.isAntiAllergy ||
              ($scope.productFilter.watch.strap && $scope.productFilter.watch.strap.model)) ||
            $scope.productFilter.brand) {
            getTotalCountForPagination($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
            getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
            //getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
            //$scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter)
          } else {
            loadProductsAsDefault()
          }
        } else {
          loadProductsAsDefault()
        }
      }
    })

    function loadProductsAsDefault() {
      //console.log(' ******* Load Default Products ')
      $scope.$emit('setTheStock', {})
      $rootScope.defaultFilter = true
    }

    $scope.changePage = function (position, currentPage) {
      $scope.currentPage = currentPage;
      $scope.getDataByPage = true;
      if (!$scope.searchMode) {
        //getTotalCountForPagination($rootScope.currentShop._id, $scope.stockFilter, $scope.productFilter, $scope.orderBy);
        getStockModeProductsShopWise($rootScope.currentShop._id, $scope.stockFilter, $scope.productFilter, $scope.orderBy);
      }
      if (position == 'bottom') $anchorScroll('paginate-bottom')
    }

    $rootScope.selectdFilter = []

    $scope.removeFilter = function (index) {
      console.log('------------------------------------------------------- removeFilter!!');
      console.log('index ', index);

      var tempSelectedFilter = $scope.selectdFilter[index]
      console.log('tempSelectedFilter  ', tempSelectedFilter);

      if (tempSelectedFilter.filter === 'shop') $scope.$broadcast('shop_filter_remove', {
        removeFilter: tempSelectedFilter
      })
      else if (tempSelectedFilter.filter === 'category') $scope.$broadcast('category_filter_remove', {
        removeFilter: tempSelectedFilter
      })
      else if (tempSelectedFilter.filter === 'indication') $scope.$broadcast('indication_filter_remove', {
        removeFilter: tempSelectedFilter
      })
      else if (tempSelectedFilter.filter === 'Particulars') $scope.$broadcast('particulars_filter_remove', {
        removeFilter: tempSelectedFilter
      })
      else if (tempSelectedFilter.filter === 'strap type') $scope.$broadcast('strap_type_filter_remove', {
        removeFilter: tempSelectedFilter
      })
      else if (tempSelectedFilter.filter === 'Gender') $scope.$broadcast('gender_filter_remove', {
        removeFilter: tempSelectedFilter
      })
      else if (tempSelectedFilter.filter === 'Brand') $scope.$broadcast('brand_filter_remove', {
        removeFilter: tempSelectedFilter
      })
      else if (tempSelectedFilter.filter === 'Supplier') $scope.$broadcast('supplier_filter_remove', {
        removeFilter: tempSelectedFilter
      })
      else if (tempSelectedFilter.filter === 'Collection') $scope.$broadcast('collection_filter_remove', {
        removeFilter: tempSelectedFilter
      })
      //console.log('tempSelectedFilter  ', tempSelectedFilter);
    }

    $scope.$on('sort_filter_directive', function (event, sort) {
      console.log('sort:::',sort);
      $scope.orderBy = sort
      getStockModeProductsShopWise($rootScope.currentShop._id, $scope.stockFilter, null, $scope.orderBy)
    })

    $scope.$on('limit_filter_directive', function (event, pageSize) {
      $scope.pageSize = pageSize
    })

    $scope.loadStockMode = function (currentShopId) {
      //console.log('-----------------------------------------------------------> loadStockMode: ', currentShopId);
      $scope.stockLoad = true
      $scope.filterData = []
      $scope.mode = 'stock'
      $scope.selectdCollections = []
      $scope.pageSize = 12
      $scope.currentPage = 1
      $rootScope.defaultFilter = false
      // console.log('------- $state.params', $state.params);
      // console.log('------- $state.stockFilter', $state.stockFilter);
      if ($state && $state.params && $state.params.mode) {
        getAllFavouriteSupplierFromShop($rootScope.currentShop._id)
      } else if ($scope.stockFilter.length) {
        getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, null);
      }
    }

    function getAllFavouriteSupplierFromShop(currentShopId) {
      console.log(' ------ Get All Favourite Supplier For Shop -----', currentShopId)
      $shop.getAllFavouriteSupplierFromShop(currentShopId)
        .then(function (favouriteSuppliers) {
          // console.log('Favourite Suppliers', favouriteSuppliers)
        })
        .catch(function (errorInGettingFavouriteSupplier) {
          // console.error('Error in getting Favourite Suppliers', errorInGettingFavouriteSupplier)
        })
    }

    $scope.supplierButtonClick = function () {
      // console.log('---------------------------- supplierButtonClick!! ');
      // console.log(JSON.stringify($scope.supplierButton, null, 2));
      $scope.supplierButton = !$scope.supplierButton;
      // console.log(JSON.stringify($scope.supplierButton, null, 2));
      if ($scope.supplierButton) {
        $scope.getCountForSupplierFilter($scope.stockFilter, $scope.productFilter)
      }
    }

    $scope.categoryButtonClick = function () {
      // console.log('---------------------------- categoryButtonClick!! ');
      // console.log(JSON.stringify($scope.categoryButton, null, 2));
      $scope.categoryButton = !$scope.categoryButton;
      // console.log(JSON.stringify($scope.categoryButton, null, 2));
      if ($scope.categoryButton) {
        $scope.getCountForCategoryFilter($scope.stockFilter, $scope.productFilter)
      }
    }

    $scope.brandButtonClick = function () {
      // console.log('---------------------------- brandButtonClick!! ');
      // console.log(JSON.stringify($scope.brandButton, null, 2));
      $scope.brandButton = !$scope.brandButton;
      // console.log(JSON.stringify($scope.brandButton, null, 2));
      if ($scope.brandButton) {
        $scope.getCountForBrandFilter($scope.stockFilter, $scope.productFilter)
      }
    }

    $scope.productButtonClick = function () {
      // console.log('---------------------------- productButtonClick!! ');
      // console.log(JSON.stringify($scope.productButton, null, 2));
      $scope.productButton = !$scope.productButton;
      // console.log(JSON.stringify($scope.productButton, null, 2));
      if ($scope.productButton) {
        $scope.getCountForProductFilter($scope.stockFilter, $scope.productFilter)
      }
    }

    $scope.genderButtonClick = function () {
      // console.log('---------------------------- genderButtonClick!! ');
      // console.log(JSON.stringify($scope.genderButton, null, 2));
      $scope.genderButton = !$scope.genderButton;
      // console.log(JSON.stringify($scope.genderButton, null, 2));
      if ($scope.genderButton) {
        $scope.getCountForGenderFilter($scope.stockFilter, $scope.productFilter)
      }
    }

    $scope.particularsButtonClick = function () {
      // console.log('---------------------------- particularsButtonClick!! ');
      // console.log(JSON.stringify($scope.particularsButton, null, 2));
      $scope.particularsButton = !$scope.particularsButton;
      // console.log(JSON.stringify($scope.particularsButton, null, 2));
      if ($scope.particularsButton) {
        $scope.getCountForParticularsFilter($scope.stockFilter, $scope.productFilter)
      }
    }

    $scope.glassMaterialButtonClick = function () {
      // console.log('---------------------------- glassMaterialButtonClick!! ');
      // console.log(JSON.stringify($scope.glassMaterialButton, null, 2));
      $scope.glassMaterialButton = !$scope.glassMaterialButton;
      // console.log(JSON.stringify($scope.glassMaterialButton, null, 2));
      if ($scope.glassMaterialButton) {
        $scope.getCountForGlassMaterialFilter($scope.stockFilter, $scope.productFilter)
      }
    }

    $scope.timeIndicationButtonClick = function () {
      // console.log('---------------------------- glassMaterialButtonClick!! ');
      // console.log(JSON.stringify($scope.timeIndicationButton, null, 2));
      $scope.timeIndicationButton = !$scope.timeIndicationButton;
      // console.log(JSON.stringify($scope.timeIndicationButton, null, 2));
      if ($scope.timeIndicationButton) {
        $scope.getCountForIndicationFilter($scope.stockFilter, $scope.productFilter)
      }
    }

    $scope.dialIndexButtonClick = function () {
      // console.log('---------------------------- dialIndexButtonClick!! ');
      // console.log(JSON.stringify($scope.dialIndexButton, null, 2));
      $scope.dialIndexButton = !$scope.dialIndexButton;
      // console.log(JSON.stringify($scope.dialIndexButton, null, 2));
      if ($scope.dialIndexButton) {
        $scope.getCountForDialIndexFilter($scope.stockFilter, $scope.productFilter)
      }
    }

    $scope.caseMaterialButtonClick = function () {
      // console.log('---------------------------- dialIndexButtonClick!! ');
      // console.log(JSON.stringify($scope.caseMaterialButton, null, 2));
      $scope.caseMaterialButton = !$scope.caseMaterialButton;
      // console.log(JSON.stringify($scope.caseMaterialButton, null, 2));
      if ($scope.caseMaterialButton) {
        $scope.getCountForCaseMaterialFilter($scope.stockFilter, $scope.productFilter)
      }
    }

    $scope.caseShapeButtonClick = function () {
      // console.log('---------------------------- dialIndexButtonClick!! ');
      // console.log(JSON.stringify($scope.caseShapeButton, null, 2));
      $scope.caseShapeButton = !$scope.caseShapeButton;
      // console.log(JSON.stringify($scope.caseShapeButton, null, 2));
      if ($scope.caseShapeButton) {
        $scope.getCountForCaseShapeFilter($scope.stockFilter, $scope.productFilter)
      }
    }

    $scope.strapTypeButtonClick = function () {
      // console.log('---------------------------- dialIndexButtonClick!! ');
      // console.log(JSON.stringify($scope.strapTypeButton, null, 2));
      $scope.strapTypeButton = !$scope.strapTypeButton;
      // console.log(JSON.stringify($scope.strapTypeButton, null, 2));
      if ($scope.strapTypeButton) {
        $scope.getCountForStrapModelFilter($scope.stockFilter, $scope.productFilter)
      }
    }

    $scope.strapMaterialButtonClick = function () {
      // console.log('---------------------------- dialIndexButtonClick!! ');
      // console.log(JSON.stringify($scope.strapMaterialButton, null, 2));
      $scope.strapMaterialButton = !$scope.strapMaterialButton;
      // console.log(JSON.stringify($scope.strapMaterialButton, null, 2));
      if ($scope.strapMaterialButton) {
        $scope.getCountForStrapMaterialFilter($scope.stockFilter, $scope.productFilter)
      }
    }

    $scope.jewelleryTypeButtonClick = function () {
      // console.log('---------------------------- dialIndexButtonClick!! ');
      // console.log(JSON.stringify($scope.jewelleryTypeButton, null, 2));
      $scope.jewelleryTypeButton = !$scope.jewelleryTypeButton;
      // console.log(JSON.stringify($scope.jewelleryTypeButton, null, 2));
      if ($scope.jewelleryTypeButton) {
        $scope.getCountForJewelleryTypeFilter($scope.stockFilter, $scope.productFilter)
      }
    }

    $scope.jewelleryMaterialButtonClick = function () {
      // console.log('---------------------------- dialIndexButtonClick!! ');
      // console.log(JSON.stringify($scope.jewelleryMaterialButton, null, 2));
      $scope.jewelleryMaterialButton = !$scope.jewelleryMaterialButton;
      // console.log(JSON.stringify($scope.jewelleryMaterialButton, null, 2));
      if ($scope.jewelleryMaterialButton) {
        $scope.getCountForJewelMaterialFilter($scope.stockFilter, $scope.productFilter)
      }
    }

    $scope.colorButtonClick = function () {
      // console.log('---------------------------- colorButtonClick!! ');
      // console.log(JSON.stringify($scope.colorButton, null, 2));
      $scope.colorButton = !$scope.colorButton;
      // console.log(JSON.stringify($scope.colorButton, null, 2));
      if ($scope.colorButton) {
        $scope.getCountForColorFilter($scope.stockFilter, $scope.productFilter)
      }
    }

    $scope.collectionButtonClick = function () {
      // console.log('---------------------------- collectionButtonClick!! ');
      // console.log(JSON.stringify($scope.collectionButton, null, 2));
      $scope.collectionButton = !$scope.collectionButton;
      // console.log(JSON.stringify($scope.collectionButton, null, 2));
      if ($scope.collectionButton) {
        $scope.getCountForCollectionsFilter($scope.stockFilter, $scope.productFilter)
      }
    }

    $scope.filterRecountings = function (stockFilter, productFilter) {
      // console.log('--------- filterRecountings!!');
      if ($scope.colorButton) { $scope.getCountForColorFilter(stockFilter, productFilter) }
      if ($scope.jewelleryMaterialButton) { $scope.getCountForJewelMaterialFilter(stockFilter, productFilter) }
      if ($scope.jewelleryTypeButton) { $scope.getCountForJewelleryTypeFilter(stockFilter, productFilter) }
      if ($scope.strapMaterialButton) { $scope.getCountForStrapMaterialFilter(stockFilter, productFilter) }
      if ($scope.strapTypeButton) { $scope.getCountForStrapModelFilter(stockFilter, productFilter) }
      if ($scope.caseShapeButton) { $scope.getCountForCaseShapeFilter(stockFilter, productFilter) }
      if ($scope.dialIndexButton) { $scope.getCountForDialIndexFilter(stockFilter, productFilter) }
      if ($scope.timeIndicationButton) { $scope.getCountForIndicationFilter(stockFilter, productFilter) }
      if ($scope.glassMaterialButton) { $scope.getCountForGlassMaterialFilter(stockFilter, productFilter) }
      if ($scope.particularsButton) { $scope.getCountForParticularsFilter(stockFilter, productFilter) }
      if ($scope.genderButton) { $scope.getCountForGenderFilter(stockFilter, productFilter) }
      if ($scope.productButton) { $scope.getCountForProductFilter(stockFilter, productFilter) }
      if ($scope.brandButton) { $scope.getCountForBrandFilter(stockFilter, productFilter) }
      if ($scope.categoryButton) { $scope.getCountForCategoryFilter(stockFilter, productFilter) }
      if ($scope.supplierButton) { $scope.getCountForSupplierFilter(stockFilter, productFilter) }
      if ($scope.collectionButton) { $scope.getCountForCollectionsFilter(stockFilter, productFilter) }
      getTotalCountForPagination($rootScope.currentShop.nameSlug, stockFilter, productFilter);
    }

    function getTotalCountForPagination(shopNameSlug, stockFilter, productFilter, isResetStock) {
      if(typeof(isResetStock) == 'undefined'){
        $scope.stockLoad = true
        $scope.stockProducts = []
        $scope.mode = 'stock'
      }

      if (!$scope.getDataByPage && !$scope.currentPage) {
        $scope.currentPage = 1;
      }
      // console.log(JSON.stringify(productFilter, null, 2));
      $shop.getTotalCountForPagination($rootScope.currentShop._id, stockFilter, productFilter, $scope.orderBy, $scope.currentPage)
        .then(function (response) {
          $scope.getDataByPage = false;
          // $scope.stockLoad = false
          $scope.totalProducts = response.totalProducts
        })
        .catch(function (err) {
          $scope.stockLoad = false
        })
      // getCountForCategoryFilter (stockFilter, productFilter)
    }
    function getStockModeProductsShopWise(shopNameSlug, stockFilter, productFilter) {
      //console.log(" **************** This Function Call's *********** 2")
      $scope.stockLoad = true
      // $scope.stockProducts = []
      $scope.mode = 'stock'

      $rootScope.defaultFilter = false
      if (!$scope.getDataByPage && !$scope.currentPage) {
        $scope.currentPage = 1;
      }
      // console.log('$scope.productFilter', productFilter)
      // console.log('stockFilter', stockFilter)
      $scope.stockFilter.singleProduct = false;
      if(!$scope.searchMode){
        $shop.getStockModeProductsShopWise($rootScope.currentShop._id, stockFilter, productFilter, $scope.orderBy, $scope.currentPage)
        .then(function (response) {
          //console.log('After got response: ', response);
          if(response.singleProduct){ 
            mergeSingleProduct(response.products[0]);
            return;
          }
          // if (!$scope.$$phase) {
          //   $scope.$apply(function () {
          //     $scope.shopFilters = response.filters
          //     $rootScope.shopFilters = response.filters
          //   })
          // } else {
          //   $scope.shopFilters = response.filters
          //   $rootScope.shopFilters = response.filters
          // }

          $scope.getDataByPage = false;
          if (!$scope.searchMode) {
            $scope.stockLoad = false;
            $scope.stockProducts = response.products
            //$scope.totalProducts = response.totalProducts
            $scope.oldProducts = response.products

            if(!$rootScope.productViewLoaded){
              $rootScope.productViewLoaded = true;
              $scope.openModal($scope.stockProducts[0], 0);
            }
            if ($rootScope.productId) {
              var index = _.findIndex($scope.stockProducts, function (el) {
                return el._id === $rootScope.productId
              })

              delete $rootScope.productId

              if (index > -1) {
                var activePage = index / 12
                $scope.currentPage = parseInt(activePage) + 1
                $scope.stockProducts[index].active = true
              } else {
                $scope.currentPage = 1
              }
            } else {
              if(!$rootScope.productId && !$scope.currentPage) $scope.currentPage = 1;
            }
          } else {
            $scope.oldProducts = JSON.parse(JSON.stringify(response.products))
            $scope.stockLoad = false
          }
        })
        .catch(function (err) {
          $scope.stockLoad = false
        })
      } else if($scope.searchMode === true ){
        $scope.findProductBasedSearchValue($scope.searchValue);
      } 
      // getCountForCategoryFilter (stockFilter, productFilter)
    }

    $rootScope.$on('catchOnAssortment', function (event, data) {
      console.log('---------------------------------------------------- catchOnAssortment!! ');
      console.log(angular.copy(data));
      // console.log(angular.copy($scope.stockProducts));
      // var shopProduct = data.shopProduct;
      var product = data.product;
      if ($scope.stockProducts) {
        $scope.stockFilter.singleProduct = true;
        $scope.stockFilter.productId = product._id;
        getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
      }
    })

    function mergeSingleProduct (product) {
      for (var i = 0; i < $scope.stockProducts.length; i++) {
        if ($scope.stockProducts[i]._id == product._id) {
          $scope.stockProducts[i] = product;
          $scope.oldProducts[i] = product;
        }
      }
      $scope.stockLoad = false;
    }

    $scope.getFilterCounts = function (stockFilter, productFilter) {
      // console.log('---------------------------- getFilterCounts controllers!! ');
      $scope.stockLoad = true
      $scope.stockProducts = []
      $scope.mode = 'stock'

      var stockFilter1, productFilter1;
      productFilter1 = angular.copy(productFilter)
      stockFilter1 = angular.copy(stockFilter)

      if (stockFilter1 && stockFilter1.inStock) { delete stockFilter1.inStock; }
      if (stockFilter1 && stockFilter1.isBestseller) { delete stockFilter1.isBestseller; }
      if (stockFilter1 && stockFilter1.show) { delete stockFilter1.show; }
      if (stockFilter1 && stockFilter1.notOnWebShop) { delete stockFilter1.notOnWebShop; }
      if (stockFilter1 && stockFilter1.status) { delete stockFilter1.status; }

      $shop.getFilterCounts($rootScope.currentShop._id, stockFilter, productFilter, $scope.orderBy)
        .then(function (response) {
          console.log('filter counts: ', JSON.parse(JSON.stringify(response)));
          delete response.filters.hasSwissMovement
          delete response.filters.hasDateFunction
          delete response.filters.ishasLightFunctionGirl
          delete response.filters.isAntiAllergy
          delete response.filters.isNickelFree
          //delete response.filters.collections
          // console.log('---------------!!! 111');
          // console.log(JSON.stringify(response, null, 2));
          if (!$scope.$$phase) {
            $scope.$apply(function () {
              $scope.shopFilters = response.filters
              $rootScope.shopFilters = response.filters
            })
          } else {
            $scope.shopFilters = response.filters
            $rootScope.shopFilters = response.filters
          }
          //console.log(JSON.stringify($scope.shopFilters, null, 2));
        })
        .catch(function (err) {
          $scope.stockLoad = false
        })
      //getCountForCategoryFilter(stockFilter, productFilter);
    }

    //  $scope.getAllFilterCounts = function(stockFilter, productFilter){
    //   // console.log('---------------------------- getAllFilterCounts!! ');
    //   // console.log(JSON.stringify(productFilter, null, 2));
    //     $scope.getCountForCategoryFilter(stockFilter, productFilter)
    //     $scope.getCountForSupplierFilter(stockFilter, productFilter)
    //     //$scope.getCountForCollectionsFilter(stockFilter, productFilter)
    //     $scope.getCountForBrandFilter(stockFilter, productFilter)
    //     $scope.getCountForGenderFilter(stockFilter, productFilter)
    //     $scope.getCountForIndicationFilter(stockFilter, productFilter)
    //     $scope.getCountForStrapModelFilter(stockFilter, productFilter)
    //     $scope.getCountForGlassMaterialFilter(stockFilter, productFilter)
    //     $scope.getCountForDialIndexFilter(stockFilter, productFilter)
    //     $scope.getCountForCaseMaterialFilter(stockFilter, productFilter)
    //     $scope.getCountForCaseShapeFilter(stockFilter, productFilter)
    //     $scope.getCountForJewelleryTypeFilter(stockFilter, productFilter)
    //     $scope.getCountForJewelMaterialFilter(stockFilter, productFilter)
    //     $scope.getCountForColorFilter(stockFilter, productFilter)
    //     $scope.getCountForParticularsFilter(stockFilter, productFilter)
    //  }


    $scope.getCountForProductFilter = function (stockFilter, productFilter) {
      // console.log('---------------------------- getFilterCounts controllers!! ');
      // $scope.stockLoad = true
      // $scope.stockProducts = []
      $scope.mode = 'stock'

      var stockFilter1, productFilter1;
      productFilter1 = angular.copy(productFilter)
      stockFilter1 = angular.copy(stockFilter)
      // console.log(JSON.stringify(stockFilter, null, 2));
      // console.log(JSON.stringify(stockFilter1, null, 2));
      if (stockFilter1 && stockFilter1.inStock) { delete stockFilter1.inStock; }
      if (stockFilter1 && stockFilter1.isBestseller) { delete stockFilter1.isBestseller; }
      if (stockFilter1 && stockFilter1.show) { delete stockFilter1.show; }
      if (stockFilter1 && stockFilter1.notOnWebShop) { delete stockFilter1.notOnWebShop; }
      if (stockFilter1 && stockFilter1.status) { delete stockFilter1.status; }

      // console.log(JSON.stringify(stockFilter1, null, 2));

      $shop.getFilterCounts($rootScope.currentShop._id, stockFilter1, productFilter1, $scope.orderBy)
        .then(function (response) {

          delete response.filters.hasSwissMovement
          delete response.filters.hasDateFunction
          delete response.filters.ishasLightFunctionGirl
          delete response.filters.isAntiAllergy
          delete response.filters.isNickelFree
          //delete response.filters.collections
          // console.log('---------------!!! 111');
          // console.log(JSON.stringify(response, null, 2));
          if (!$scope.$$phase) {
            $scope.$apply(function () {
              $scope.shopFilters = response.filters
              $rootScope.shopFilters = response.filters
            })
          } else {
            $scope.shopFilters = response.filters
            $rootScope.shopFilters = response.filters
          }
          // console.log(JSON.stringify($scope.shopFilters, null, 2));
        })
        .catch(function (err) {
          $scope.stockLoad = false
        })
      //getCountForCategoryFilter(stockFilter, productFilter);
    }


    $scope.getCountForCategoryFilter = function (stockFilter, productFilter) {
      var stockFilter1, productFilter1;
      productFilter1 = angular.copy(productFilter)
      if (productFilter1 && productFilter1.category) {
        delete productFilter1.category;
      }
      stockFilter1 = angular.copy(stockFilter)
      $shop.getCountForCategoryFilter($rootScope.currentShop._id, stockFilter1, productFilter1, $scope.orderBy)
        .then(function (response) {
          if (!$scope.$$phase) {
            $scope.$apply(function () {
              $scope.categoryFilterCounts = response.filters.category
              $rootScope.categoryFilterCounts = response.filters.category
            })
          } else {
            $scope.categoryFilterCounts = response.filters.category
            $rootScope.categoryFilterCounts = response.filters.category
          }
        })
        .catch(function (err) {
          $scope.stockLoad = false
        })
    }

    $scope.getCountForBrandFilter = function (stockFilter, productFilter) {
      var stockFilter1, productFilter1;
      productFilter1 = angular.copy(productFilter)
      if (productFilter1 && productFilter1.brand) {
        delete productFilter1.brand;
      }
      stockFilter1 = angular.copy(stockFilter)
      $shop.getCountForBrandFilter($rootScope.currentShop._id, stockFilter1, productFilter1, $scope.orderBy)
        .then(function (response) {
          if (!$scope.$$phase) {
            $scope.$apply(function () {
              $scope.brandsFilterCounts = response.filters.brands
              $rootScope.brandsFilterCounts = response.filters.brands
            })
          } else {
            $scope.brandsFilterCounts = response.filters.brands
            $rootScope.brandsFilterCounts = response.filters.brands
          }
        })
        .catch(function (err) {
          $scope.stockLoad = false
        })
    }

    $scope.getCountForSupplierFilter = function (stockFilter, productFilter) {
      //console.log('---------------------------- getCountForSupplierFilter!! ');
      var stockFilter1, productFilter1;
      productFilter1 = angular.copy(productFilter)
      stockFilter1 = angular.copy(stockFilter)
      if (stockFilter1 && stockFilter1.supplier) {
        delete stockFilter1.supplier;
      }

      $shop.getCountForSupplierFilter($rootScope.currentShop._id, stockFilter1, productFilter1, $scope.orderBy)
        .then(function (response) {
          //console.log('---------------------------- inside getCountForSupplierFilter!! ');
          if (!$scope.$$phase) {
            $scope.$apply(function () {
              $scope.supplierFilterCounts = response.filters.supplier
              $rootScope.supplierFilterCounts = response.filters.supplier
            })
          } else {
            $scope.supplierFilterCounts = response.filters.supplier
            $rootScope.supplierFilterCounts = response.filters.supplier
          }
        })
        .catch(function (err) {
          $scope.stockLoad = false
        })
    }

    $scope.getCountForGenderFilter = function (stockFilter, productFilter) {
      //console.log('--------------------- getCountForGenderFilter!!');   
      var stockFilter1, productFilter1;
      productFilter1 = angular.copy(productFilter)
      stockFilter1 = angular.copy(stockFilter)
      if (productFilter1 && productFilter1.gender) {
        delete productFilter1.gender;
      }

      $shop.getCountForGenderFilter($rootScope.currentShop._id, stockFilter1, productFilter1, $scope.orderBy)
        .then(function (response) {
          $scope.supplierGenderCounts = {};
          $rootScope.supplierGenderCounts = {};
          if (!$scope.$$phase) {
            $scope.$apply(function () {
              $scope.supplierGenderCounts.isBoy = response.filters.isBoy
              $scope.supplierGenderCounts.isMale = response.filters.isMale
              $scope.supplierGenderCounts.isGirl = response.filters.isGirl
              $scope.supplierGenderCounts.isFemale = response.filters.isFemale

              $rootScope.supplierGenderCounts.isBoy = response.filters.isBoy
              $rootScope.supplierGenderCounts.isMale = response.filters.isMale
              $rootScope.supplierGenderCounts.isGirl = response.filters.isGirl
              $rootScope.supplierGenderCounts.isFemale = response.filters.isFemale
            })
          } else {
            $scope.supplierGenderCounts.isBoy = response.filters.isBoy
            $scope.supplierGenderCounts.isMale = response.filters.isMale
            $scope.supplierGenderCounts.isGirl = response.filters.isGirl
            $scope.supplierGenderCounts.isFemale = response.filters.isFemale

            $rootScope.supplierGenderCounts.isBoy = response.filters.isBoy
            $rootScope.supplierGenderCounts.isMale = response.filters.isMale
            $rootScope.supplierGenderCounts.isGirl = response.filters.isGirl
            $rootScope.supplierGenderCounts.isFemale = response.filters.isFemale
          }
        })
        .catch(function (err) {
          $scope.stockLoad = false
        })
    }

    $scope.getCountForCollectionsFilter = function (stockFilter, productFilter) {
      console.log('---------------------------- getCountForCollectionsFilter!! ');
      console.log('---------------!!!');
      console.log(stockFilter);
      console.log(productFilter);

      var stockFilter1, productFilter1;
      productFilter1 = angular.copy(productFilter)
      stockFilter1 = angular.copy(stockFilter)
      if(productFilter1 && productFilter1.collection){
        delete productFilter1.collection;
      }

      $shop.getCountForCollectionsFilter($rootScope.currentShop._id, stockFilter1, productFilter1, $scope.orderBy)
        .then(function (response) {
          // console.log('---------------------------- inside getCountForCollectionsFilter!! ');
          // console.log(JSON.stringify(response.filters, null, 2))  

          if (!$scope.$$phase) {
            $scope.$apply(function () {
              // console.log('---------------------------- inside if!! ');
              $scope.collectionsFilterCounts = response.filters.collections
              $rootScope.collectionsFilterCounts = response.filters.collections
            })
          } else {
            // console.log('---------------------------- inside else!! ');
            $scope.collectionsFilterCounts = response.filters.collections
            $rootScope.collectionsFilterCounts = response.filters.collections
          }
          // console.log(JSON.stringify($scope.collectionsFilterCounts, null, 2))
          // console.log(JSON.stringify($rootScope.categoryFilterCounts, null, 2))
        })
        .catch(function (err) {
          $scope.stockLoad = false
        })
    }

    $scope.getCountForIndicationFilter = function (stockFilter, productFilter) {
      //console.log('---------------------------- getCountForIndicationFilter!! ');
      var stockFilter1, productFilter1;
      productFilter1 = angular.copy(productFilter)
      stockFilter1 = angular.copy(stockFilter)

      if (productFilter1 && productFilter1.watch && productFilter1.watch.indication) {
        delete productFilter1.watch.indication;
      }

      $shop.getCountForIndicationFilter($rootScope.currentShop._id, stockFilter1, productFilter1, $scope.orderBy)
        .then(function (response) {
          //console.log('---------------------------- inside getCountForIndicationFilter!! '); 

          if (!$scope.$$phase) {
            $scope.$apply(function () {
              $scope.indicationFilterCounts = response.filters.indication
              $rootScope.indicationFilterCounts = response.filters.indication
            })
          } else {
            $scope.indicationFilterCounts = response.filters.indication
            $rootScope.indicationFilterCounts = response.filters.indication
          }
        })
        .catch(function (err) {
          $scope.stockLoad = false
        })
    }

    $scope.getCountForStrapModelFilter = function (stockFilter, productFilter) {
      //console.log('---------------------------- getCountForStrapModelFilter!! ');
      var stockFilter1, productFilter1;
      productFilter1 = angular.copy(productFilter)
      stockFilter1 = angular.copy(stockFilter)

      if (productFilter1 && productFilter1.watch && productFilter1.watch.strap && productFilter1.watch.strap.model) {
        delete productFilter1.watch.strap.model;
      }

      $shop.getCountForStrapModelFilter($rootScope.currentShop._id, stockFilter1, productFilter1, $scope.orderBy)
        .then(function (response) {
          //console.log('---------------------------- inside getCountForStrapModelFilter!! '); 

          if (!$scope.$$phase) {
            $scope.$apply(function () {
              $scope.strapModelFilterCounts = response.filters.strapModel
              $rootScope.strapModelFilterCounts = response.filters.strapModel
            })
          } else {
            $scope.strapModelFilterCounts = response.filters.strapModel
            $rootScope.strapModelFilterCounts = response.filters.strapModel
          }
        })
        .catch(function (err) {
          $scope.stockLoad = false
        })
    }

    $scope.getCountForGlassMaterialFilter = function (stockFilter, productFilter) {
      //console.log('---------------------------- getCountForGlassMaterialFilter!! ');

      var stockFilter1, productFilter1;
      productFilter1 = angular.copy(productFilter)
      stockFilter1 = angular.copy(stockFilter)

      if (productFilter1 && productFilter1.watch && productFilter1.watch.case && productFilter1.watch.case.glassMaterial) {
        delete productFilter1.watch.case.glassMaterial;
      }

      $shop.getCountForGlassMaterialFilter($rootScope.currentShop._id, stockFilter1, productFilter1, $scope.orderBy)
        .then(function (response) {
          //console.log('---------------------------- inside getCountForGlassMaterialFilter!! '); 

          if (!$scope.$$phase) {
            $scope.$apply(function () {
              $scope.glassMaterialFilterCounts = response.filters.glassMaterial
              $rootScope.glassMaterialFilterCounts = response.filters.glassMaterial
            })
          } else {
            $scope.glassMaterialFilterCounts = response.filters.glassMaterial
            $rootScope.glassMaterialFilterCounts = response.filters.glassMaterial
          }
        })
        .catch(function (err) {
          $scope.stockLoad = false
        })
    }

    $scope.getCountForCaseMaterialFilter = function (stockFilter, productFilter) {
      //console.log('---------------------------- getCountForCaseMaterialFilter!! ');
      var stockFilter1, productFilter1;
      productFilter1 = angular.copy(productFilter)
      stockFilter1 = angular.copy(stockFilter)

      if (productFilter1 && productFilter1.watch && productFilter1.watch.case && productFilter1.watch.case.material) {
        delete productFilter1.watch.case.material;
      }

      $shop.getCountForCaseMaterialFilter($rootScope.currentShop._id, stockFilter1, productFilter1, $scope.orderBy)
        .then(function (response) {
          //console.log('---------------------------- inside getCountForCaseMaterialFilter!! '); 

          if (!$scope.$$phase) {
            $scope.$apply(function () {
              $scope.caseMaterialFilterCounts = response.filters.caseMaterial
              $rootScope.caseMaterialFilterCounts = response.filters.caseMaterial
            })
          } else {
            $scope.caseMaterialFilterCounts = response.filters.caseMaterial
            $rootScope.caseMaterialFilterCounts = response.filters.caseMaterial
          }
        })
        .catch(function (err) {
          $scope.stockLoad = false
        })
    }

    $scope.getCountForDialIndexFilter = function (stockFilter, productFilter) {
      //console.log('---------------------------- getCountForDialIndexFilter!! ');

      var stockFilter1, productFilter1;
      productFilter1 = angular.copy(productFilter)
      stockFilter1 = angular.copy(stockFilter)

      if (productFilter1 && productFilter1.watch && productFilter1.watch.dial && productFilter1.watch.dial.index) {
        delete productFilter1.watch.dial.index;
      }

      $shop.getCountForDialIndexFilter($rootScope.currentShop._id, stockFilter1, productFilter1, $scope.orderBy)
        .then(function (response) {
          //console.log('---------------------------- inside getCountForDialIndexFilter!! '); 

          if (!$scope.$$phase) {
            $scope.$apply(function () {
              $scope.dialIndexFilterCounts = response.filters.dialIndex
              $rootScope.dialIndexFilterCounts = response.filters.dialIndex
            })
          } else {
            $scope.dialIndexFilterCounts = response.filters.dialIndex
            $rootScope.dialIndexFilterCounts = response.filters.dialIndex
          }
        })
        .catch(function (err) {
          $scope.stockLoad = false
        })
    }

    $scope.getCountForCaseShapeFilter = function (stockFilter, productFilter) {
      //console.log('---------------------------- getCountForCaseShapeFilter!! ');

      var stockFilter1, productFilter1;
      productFilter1 = angular.copy(productFilter)
      stockFilter1 = angular.copy(stockFilter)

      if (productFilter1 && productFilter1.watch && productFilter1.watch.dial && productFilter1.watch.dial.index) {
        delete productFilter1.watch.dial.index;
      }

      $shop.getCountForCaseShapeFilter($rootScope.currentShop._id, stockFilter1, productFilter1, $scope.orderBy)
        .then(function (response) {
          //console.log('---------------------------- inside getCountForCaseShapeFilter!! '); 

          if (!$scope.$$phase) {
            $scope.$apply(function () {
              $scope.caseShapeFilterCounts = response.filters.caseShape
              $rootScope.caseShapeFilterCounts = response.filters.caseShape
            })
          } else {
            $scope.caseShapeFilterCounts = response.filters.caseShape
            $rootScope.caseShapeFilterCounts = response.filters.caseShape
          }
        })
        .catch(function (err) {
          $scope.stockLoad = false
        })
    }

    $scope.getCountForStrapMaterialFilter = function (stockFilter, productFilter) {
      //console.log('---------------------------- getCountForStrapMaterialFilter!! ');

      var stockFilter1, productFilter1;
      productFilter1 = angular.copy(productFilter)
      stockFilter1 = angular.copy(stockFilter)

      if (productFilter1 && productFilter1.watch && productFilter1.watch.dial && productFilter1.watch.dial.index) {
        delete productFilter1.watch.dial.index;
      }

      $shop.getCountForStrapMaterialFilter($rootScope.currentShop._id, stockFilter1, productFilter1, $scope.orderBy)
        .then(function (response) {
          //console.log('---------------------------- inside getCountForStrapMaterialFilter!! '); 

          if (!$scope.$$phase) {
            $scope.$apply(function () {
              $scope.strapMaterialFilterCounts = response.filters.strapMaterial
              $rootScope.strapMaterialFilterCounts = response.filters.strapMaterial
            })
          } else {
            $scope.strapMaterialFilterCounts = response.filters.strapMaterial
            $rootScope.strapMaterialFilterCounts = response.filters.strapMaterial
          }
        })
        .catch(function (err) {
          $scope.stockLoad = false
        })
    }

    $scope.getCountForJewelleryTypeFilter = function (stockFilter, productFilter) {
      //console.log('---------------------------- getCountForJewelleryTypeFilter!! ');

      var stockFilter1, productFilter1;
      productFilter1 = angular.copy(productFilter)
      stockFilter1 = angular.copy(stockFilter)

      if (productFilter1 && productFilter1.jewel && productFilter1.jewel.type) {
        delete productFilter1.jewel.type;
      }

      $shop.getCountForJewelleryTypeFilter($rootScope.currentShop._id, stockFilter1, productFilter1, $scope.orderBy)
        .then(function (response) {
          //console.log('---------------------------- inside getCountForJewelleryTypeFilter!! '); 

          if (!$scope.$$phase) {
            $scope.$apply(function () {
              $scope.jewelTypeFilterCounts = response.filters.jewelType
              $rootScope.jewelTypeFilterCounts = response.filters.jewelType
            })
          } else {
            $scope.jewelTypeFilterCounts = response.filters.jewelType
            $rootScope.jewelTypeFilterCounts = response.filters.jewelType
          }
        })
        .catch(function (err) {
          $scope.stockLoad = false
        })
    }

    $scope.getCountForJewelMaterialFilter = function (stockFilter, productFilter) {
      //console.log('---------------------------- getCountForJewelMaterialFilter!! ');

      var stockFilter1, productFilter1;
      productFilter1 = angular.copy(productFilter)
      stockFilter1 = angular.copy(stockFilter)

      if (productFilter1 && productFilter1.jewel && productFilter1.jewel.material) {
        delete productFilter1.jewel.material;
      }

      $shop.getCountForJewelMaterialFilter($rootScope.currentShop._id, stockFilter1, productFilter1, $scope.orderBy)
        .then(function (response) {
          //console.log('---------------------------- inside getCountForJewelMaterialFilter!! '); 

          if (!$scope.$$phase) {
            $scope.$apply(function () {
              $scope.jewelMaterialFilterCounts = response.filters.jewelMaterial
              $rootScope.jewelMaterialFilterCounts = response.filters.jewelMaterial
            })
          } else {
            $scope.jewelMaterialFilterCounts = response.filters.jewelMaterial
            $rootScope.jewelMaterialFilterCounts = response.filters.jewelMaterial
          }
        })
        .catch(function (err) {
          $scope.stockLoad = false
        })
    }

    $scope.getCountForColorFilter = function (stockFilter, productFilter) {
      //console.log('---------------------------- getCountForColorFilter!! ');
      //console.log(JSON.stringify(productFilter, null, 2));

      var stockFilter1, productFilter1;
      productFilter1 = angular.copy(productFilter)
      stockFilter1 = angular.copy(stockFilter)

      if (productFilter1 && productFilter1.watch && productFilter1.watch.dial && productFilter1.watch.dial.color) {
        delete productFilter1.watch.dial.color;
      }
      if (productFilter1 && productFilter1.watch && productFilter1.watch.strap && productFilter1.watch.strap.color) {
        delete productFilter1.watch.strap.color;
      }
      if (productFilter1 && productFilter1.watch && productFilter1.watch.case && productFilter1.watch.case.color) {
        delete productFilter1.watch.case.color;
      }
      //console.log(JSON.stringify(productFilter, null, 2));

      // watch.case.color = params.filter['watch.case.color']
      //   $scope.productFilter.watch.dial.color = params.filter['watch.dial.color']
      //   $scope.productFilter.watch.strap.color 

      $shop.getCountForColorFilter($rootScope.currentShop._id, stockFilter1, productFilter1, $scope.orderBy)
        .then(function (response) {
          if (!$scope.$$phase) {
            $scope.$apply(function () {
              $scope.shopFilters = response.filters
              $rootScope.shopFilters = response.filters
            })
          } else {
            $scope.shopFilters = response.filters
            $rootScope.shopFilters = response.filters
          }
        })
        .catch(function (err) {
          $scope.stockLoad = false
        })
    }

    $scope.getCountForParticularsFilter = function (stockFilter, productFilter) {
      // console.log('---------------------------- getCountForParticularsFilter!! ');

      var stockFilter1, productFilter1;
      productFilter1 = angular.copy(productFilter)
      stockFilter1 = angular.copy(stockFilter)

      if (productFilter1 && productFilter1.watch && productFilter1.watch.hasDateFunction) {
        delete productFilter1.watch.hasDateFunction;
      }
      if (productFilter1 && productFilter1.watch && productFilter1.watch.hasLightFunction) {
        delete productFilter1.watch.hasLightFunction;
      }
      if (productFilter1 && productFilter1.watch && productFilter1.watch.isAntiAllergy) {
        delete productFilter1.watch.isAntiAllergy;
      }
      if (productFilter1 && productFilter1.watch && productFilter1.watch.isNickelFree) {
        delete productFilter1.watch.isNickelFree;
      }
      if (productFilter1 && productFilter1.watch && productFilter1.watch.hasSwissMovement) {
        delete productFilter1.watch.hasSwissMovement;
      }

      $shop.getCountForParticularsFilter($rootScope.currentShop._id, stockFilter1, productFilter1, $scope.orderBy)
        .then(function (response) {
          //console.log('---------------------------- inside getCountForParticularsFilter!! '); 

          $scope.particularsCounts = {};
          $rootScope.particularsCounts = {};
          if (!$scope.$$phase) {
            $scope.$apply(function () {
              $scope.particularsCounts.hasSwissMovement = response.filters.hasSwissMovement
              $scope.particularsCounts.hasDateFunction = response.filters.hasDateFunction
              $scope.particularsCounts.hasLightFunction = response.filters.hasLightFunction
              $scope.particularsCounts.isAntiAllergy = response.filters.isAntiAllergy
              $scope.particularsCounts.isNickelFree = response.filters.isNickelFree

              $rootScope.particularsCounts.hasSwissMovement = response.filters.hasSwissMovement
              $rootScope.particularsCounts.hasDateFunction = response.filters.hasDateFunction
              $rootScope.particularsCounts.hasLightFunction = response.filters.hasLightFunction
              $rootScope.particularsCounts.isAntiAllergy = response.filters.isAntiAllergy
              $rootScope.particularsCounts.isNickelFree = response.filters.isNickelFree
            })
          } else {
            $scope.particularsCounts.hasSwissMovement = response.filters.hasSwissMovement
            $scope.particularsCounts.hasDateFunction = response.filters.hasDateFunction
            $scope.particularsCounts.hasLightFunction = response.filters.hasLightFunction
            $scope.particularsCounts.isAntiAllergy = response.filters.isAntiAllergy
            $scope.particularsCounts.isNickelFree = response.filters.isNickelFree

            $rootScope.particularsCounts.hasSwissMovement = response.filters.hasSwissMovement
            $rootScope.particularsCounts.hasDateFunction = response.filters.hasDateFunction
            $rootScope.particularsCounts.hasLightFunction = response.filters.hasLightFunction
            $rootScope.particularsCounts.isAntiAllergy = response.filters.isAntiAllergy
            $rootScope.particularsCounts.isNickelFree = response.filters.isNickelFree
          }
        })
        .catch(function (err) {
          $scope.stockLoad = false
        })
    }

    $scope.pageNumbering = function (currentPage) {
      // console.log('curretnpage: ', currentPage);
      var length = $scope.totalProducts;
      var actualPage = currentPage * $scope.pageSize
      var productCounter = actualPage - $scope.pageSize
      if (actualPage >= length) actualPage = length
      if (length == undefined) {
        length = "Loading.."
      }
      return productCounter + '-' + actualPage + ' (' + length + ')'
    }

    $scope.openProductModal = function (product, index) {
      $rootScope.pairingdetails = {};
      $rootScope.pairingdetails = {
        stockProducts: $scope.stockProducts,
        index: index
      }
      console.log('openProductModal INDEX: 111', index, $scope.stockProducts[index]);
      product.selectedIndex = index;
      return $q(function (resolve, reject) {
        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/retailer-product-modal-new.html',
          controller: 'retailerProductModalNewController',
          size: 'lg',
          resolve: {
            product: function () {
              product.shopProduct.shippingCosts = $rootScope.currentShop.shippingCosts
              product.shopProduct.sameProducts = $scope.my_duplicate_product
              return product
            }
          }
        })

        modalInstance.result.then(function (response) {
          console.log('MODAL CLOSED: 123: ', $scope.stockProducts);
          resolve(response)
        }).catch(function (reason) {
          console.log('--------------------REAOSON: ', reason);
          reject(reason)
        })
      })
    }

    function removeDupProductsIfAny(productId, stockProducts) {
      for (var i = 0; i < stockProducts.length; i++) {
        console.log(i, stockProducts[i], stockProducts[i].product._id, productId, stockProducts[i].active);
        if (stockProducts[i].product._id == productId && !stockProducts[i].active) {
          stockProducts[i].isDeleted = true;
        }
      }
      stockProducts = stockProducts.filter(function(el) {
        return !el.isDeleted;
      });
      console.log('removeDupProductsIfAny: ', stockProducts);
      return stockProducts;
    }

    function replaceWithSeachedProduct(response) {
      console.log('product_merged_from_assortment called: 123: ', $scope.stockProducts);
      console.log('product_merged_from_assortment called: ', response);
      var productId = response.product._id;
      $scope.stockLoad = true;
      $shop.searchStockProducts($rootScope.currentShop._id, productId, {}, true).then(function (products) {
        $scope.stockLoad = false;
        if (response && response.isMerge) {
          $scope.stockProducts = $rootScope.pairingdetails.stockProducts;
          $scope.stockProducts[response.selectedIndex] = {};
          $scope.stockProducts[response.selectedIndex] = products[0];
          $scope.stockProducts[response.selectedIndex].active = true;
          $scope.stockProducts = JSON.parse(JSON.stringify(removeDupProductsIfAny(productId, $scope.stockProducts)));
        }
        // $rootScope.pairingdetails = {};
      }).catch(function (error) {
        $scope.stockLoad = false;
        $rootScope.pairingdetails = {};
        console.error('Error', error)
      })
    }

    $rootScope.$on('product_merged_from_assortment', function (event, response) {
      if ($rootScope.pairingdetails && $rootScope.pairingdetails.stockProducts 
        && $rootScope.pairingdetails.stockProducts.length && $rootScope.pairingdetails.stockProducts.length >= response.selectedIndex) {
          replaceWithSeachedProduct(response);
        }
      console.log('called replaceWithSeachedProduct: ', response);
      
    })


    $scope.openModal = function (product, index) {
      // console.log('called..'); 
      //console.log('---------------------------------  retailer assortment controller 1!!', index);
      //console.log(product);
      // console.log($scope.stockProduct.product._id);
      var modalInstance = $uibModal.open({
        templateUrl: '../views/retailer1/product.html',
        controller: 'retailerProduct1Controller',
        size: 'lg',
        resolve: {
          item: function () {
            // product.shopProduct.shippingCosts = $rootScope.currentShop.shippingCosts
            // product.shopProduct = product;
            product.extraData = {};
            product.extraData._id = product.product._id
            product.extraData.itemId = null
            product.extraData.orderNumber = null
            return product
          }
        },
        windowClass: 'sample-pug'
      });

      modalInstance.result.then(function (result) {
        console.log('retailer assortment controller MODAL CLOSED', result, $scope.stockProducts[index]);
        if (result && result.isFromBackButton) {
          $scope.stockProducts[index].shopProduct.stock = result.stock || 0;
        } else {
          var senddata = {
            selectedIndex: selectedIndex,
            product: product,
            isMerge: true
          }
          console.log('retailer assortment controller MODAL CLOSED 1', senddata);
        }
      }, function (error) {
        console.log('openPairingAssortmentModal called: ', error);
      });
    }

    $scope.updateShopProduct = function (singleProduct, flag) {
      $scope.openProductModal(singleProduct)
        .then(function (success) {
          // console.log('Sucess', success)
        })
        .catch(function (error) {
          // console.log('Error', error)
        })
    }

    $scope.edit = function (id, prop) {
      var index = _.findIndex($scope.stockProducts, function (el) {
        return el.items._id === id
      })

      if (index > 0) $scope.stockProducts[index].items.edit = prop
    }

    $scope.editDelivery = function (id, value) {
      var index = _.findIndex($scope.stockProducts, function (el) {
        return el.items._id === id
      })

      if (index < 0) {
        return
      }
      if (value == 'NEXT_DAY') {
        return
      }
      $scope.leaveEdit(id)
    }

    $scope.leaveEdit = function (id) {
      var index = _.findIndex($scope.stockProducts, function (el) {
        return el.items._id === id
      })
      if (index < 0) {
        return
      }

      if ($scope.stockProducts[index].items.edit == 'price') {
        $scope.stockProducts[index].items.price = $scope.stockProducts[index].items.price / 1.21
      }

      $scope.stockProducts[index].items.edit = null
    }

    $scope.updateStockProductShow = function (show, productId) {
      var index = _.findIndex($scope.stockProducts, function (el) {
        return el.product._id === productId
      })
      if ($scope.stockProducts[index].shopProduct.show) {
        $scope.stockProducts[index].shopProduct.show = false;
        // console.log(JSON.stringify($scope.stockProducts[index].shopProduct.show, null, 2));
      } else { $scope.stockProducts[index].shopProduct.show = true; }
      $shop.updateStockProductShow($rootScope.currentShop._id, productId, $scope.stockProducts[index].shopProduct.show)
        .then(function (success) {
          // console.log('updated successfully!');
          // console.log(success);
        })
        .catch(function (error) {
          console.error('Error in', error)
        })
      // $scope.stockProducts[index].shopProduct.stock = 0;
    }

    $scope.deleteStockProduct = function (id) {
      var index = _.findIndex($scope.stockProducts, function (el) {
        return el.product._id === id
      })

      if (index < 0) {
        return
      }
      $scope.stockProducts[index].shopProduct.loading = true

      if ($scope.stockProducts && $scope.stockProducts[index] && $scope.stockProducts[index].product.brand && $scope.stockProducts[index].product.brand.nameSlug) {
        var brand = $rootScope.currentShop.brands.find(function (brand) {
          return brand.nameSlug == $scope.stockProducts[index].product.brand.nameSlug
        }) || null

        if (brand && brand.restricted) {
          prompt({
            title: $language.translate('DELETE_PRODUCT') + '?',
            message: $language.translate('YOU_ARE_OFFICIAL_DEALER_PRODUCT_STAYS_IN_ASSORTMENT')
          }).then(function () {
            // $scope.stockProducts[index].shopProduct.stock = 0;
            // $scope.stockProducts[index].shopProduct.show = false;
            $shop.updateShopProductStockAndShow($scope.stockProducts[index].product._id, $rootScope.currentShop._id)
              .then(function (success) {
                $scope.stockProducts.splice(index, 1)
              })
              .catch(function (error) {
                console.error('Error in', error)
              })
          }, function () {
            reject('Cancel')
          })
        } else {
          prompt({
            title: $language.translate('DELETE_PRODUCT') + '?',
            message: $language.translate('ARE_YOU_SURE_TO_DELETE_THIS_PRODUCT'),
            buttons: [{
              label: $language.translate('DELETE_PRODUCT'),
              primary: true,
              id: 0
            },
            {
              label: $language.translate('STOP_SELLING_STOCK_TO_0'),
              primary: true,
              id: 1
            },
            {
              label: $language.translate('CANCEL'),
              primary: false,
              cancel: true,
              id: 2
            }
            ]
          }).then(function (value) {
            if (value && value.id == 0) {
              $shop.deleteProductFromShop($scope.stockProducts[index].product._id, $rootScope.currentShop._id)
                .then(function (result) {
                  $scope.stockProducts.splice(index, 1)
                })
                .catch(function (error) {
                  console.error(' Error In delete product from the shop', error)
                })
            } else if (value && value.id == 1) {
              $shop.updateShopProductStockAndShow($scope.stockProducts[index].product._id, $rootScope.currentShop._id)
                .then(function (success) {
                  $scope.stockProducts.splice(index, 1)
                })
                .catch(function (error) {
                  console.error('Error in', error)
                })
            } else {
              // console.log("I don't delete this product")
              $scope.stockProducts[index].shopProduct.loading = false
            }
          })
            .catch(function (error) {
              // console.log('Error ====> Delete Product', error)
              $scope.stockProducts[index].shopProduct.loading = false
            })
        }
      } else {
        prompt({
          title: $language.translate('DELETE_PRODUCT') + '?',
          message: $language.translate('ARE_YOU_SURE_TO_DELETE_THIS_PRODUCT'),
          buttons: [{
            label: $language.translate('DELETE_PRODUCT'),
            primary: true,
            id: 0
          },
          {
            label: $language.translate('STOP_SELLING_STOCK_TO_0'),
            primary: true,
            id: 1
          },
          {
            label: $language.translate('CANCEL'),
            primary: false,
            cancel: true,
            id: 2
          }
          ]
        }).then(function (value) {
          if (value && value.id == 0) {
            $shop.deleteProductFromShop($scope.stockProducts[index].product._id, $rootScope.currentShop._id)
              .then(function (result) {
                $scope.stockProducts.splice(index, 1)
              })
              .catch(function (error) {
                console.error(' Error In delete product from the shop', error)
              })
          } else if (value && value.id == 1) {
            $shop.updateShopProductStockAndShow($scope.stockProducts[index].product._id, $rootScope.currentShop._id)
              .then(function (success) {
                $scope.stockProducts.splice(index, 1)
              })
              .catch(function (error) {
                console.error('Error in updateShopProductStockAndShow', error)
              })
          } else {
            // console.log("I don't delete this product")
          }
        })
          .catch(function (error) {
            console.error(error)
            $scope.stockProducts[index].shopProduct.loading = false
          })
      }
    }

    // Shop Product Details
    $scope.$on('product_filter_directive', function (event, params) {
      //console.log('product_filter_directive called', params);
      // return;
      // if($rootScope.defaultFilter) {
      //     $rootScope.defaultFilter = false;
      //     $scope.$emit('removeShow', {});
      // }

      if (params.filter && params.filter.status && params.filter.status == 'localStorage') {
        // console.log('----- if!!');
        $scope.filterCounter++
        $scope.stockFilter = params.filter
        // Assortment page was not working so shubham tried adding these two lines, 
        // Need to rethinkon it.
        // getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
        // getTotalCountForPagination($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter);
      } else if (params.filter) {
        // console.log('----- else if!!');
        $scope.stockFilter = params.filter
        getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
        getTotalCountForPagination($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter);
        // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
        //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter)
      }
    })

    // Particulars
    $scope.$on('particulars_filter_directive', function (event, params) {
      if ($rootScope.defaultFilter) {
        $rootScope.defaultFilter = false
        $scope.$emit('removeShow', {})
      }

      if (!$scope.productFilter) $scope.productFilter = {}
      if (!$scope.productFilter.watch) $scope.productFilter.watch = {}
      $scope.productFilter.watch.hasLightFunction = (params.filter['watch.hasLightFunction'] != null && params.filter['watch.hasLightFunction'] != undefined) ? params.filter['watch.hasLightFunction'] : false
      $scope.productFilter.watch.hasDateFunction = (params.filter['watch.hasDateFunction']) ? params.filter['watch.hasDateFunction'] : false
      $scope.productFilter.watch.hasSwissMovement = (params.filter['watch.hasSwissMovement']) ? params.filter['watch.hasSwissMovement'] : false
      $scope.productFilter.watch.isAntiAllergy = (params.filter['watch.isAntiAllergy']) ? params.filter['watch.isAntiAllergy'] : false
      $scope.productFilter.watch.isNickelFree = (params.filter['watch.isNickelFree']) ? params.filter['watch.isNickelFree'] : false
      if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
      else {
        getTotalCountForPagination($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter);
        getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter);
        // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
        //    $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter)
      }
    })

    // Gender
    $scope.$on('gender_filter_directive', function (event, params) {
      // console.log('gender_filter_directive count', params)
      $scope.productFilter.gender = params.filter.gender
      // console.log(JSON.stringify($scope.productFilter, null, 2));
      // console.log(localStorage.getItem('genderFilter'));
      // $scope.productFilter.gender = localStorage.getItem('genderFilter');
      // console.log(JSON.stringify($scope.productFilter, null, 2));
      if ($rootScope.defaultFilter) {
        $rootScope.defaultFilter = false
        $scope.$emit('removeShow', {})
      }

      if ($scope.productFilter && $scope.productFilter.gender) delete $scope.productFilter.gender

      if (params.filter.gender && params.filter.gender.length) {
        // console.log('------------- if!!');
        // console.log('Gender Filter if: ', typeof params.filter.gender)
        $scope.productFilter.gender = JSON.parse(JSON.stringify(params.filter.gender))
        // console.log(JSON.stringify($scope.productFilter, null, 2));
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getTotalCountForPagination($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      } else {
        // console.log('------------- else!!');
        // console.log('Gender Filter else: ', typeof params.filter.gender)

        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          // console.log('------------- else else!!');
          // console.log(JSON.stringify($scope.productFilter, null, 2));
          if ($scope.productFilter && $scope.productFilter.gender) {
            delete $scope.productFilter.gender;
          }
          // console.log(JSON.stringify($scope.productFilter, null, 2));
          getTotalCountForPagination($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //    $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      }
    })
    // price
    $scope.$on('price_filter_directive', function (event, params) {
      // console.log('price_filter_directive count')
      if ($rootScope.defaultFilter) {
        $rootScope.defaultFilter = false
        $scope.$emit('removeShow', {})
      }

      $scope.stockFilter.price = {}

      if (params.great == 0 && params.less == undefined) {
        $scope.stockFilter.price.gte = params.great
      } else if (params.less != undefined) {
        $scope.stockFilter.price.gte = params.great
        $scope.stockFilter.price.lte = params.less
      }

      if (params.filter && params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
      else {
        getTotalCountForPagination($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
        getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
        //getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
        //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
      }
    })

    // Suppliers
    $scope.$on('supplier_filter_directive', function (event, params) {
      if ($rootScope.defaultFilter) {
        $rootScope.defaultFilter = false
        $scope.$emit('removeShow', {})
      }

      if (params.filter && params.filter.supplier && params.filter.supplier.$in && params.filter.supplier.$in.length) {
        $scope.stockFilter.supplier = params.filter.supplier
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getTotalCountForPagination($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      } else {
        $scope.stockFilter.supplier = undefined
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getTotalCountForPagination($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter);
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          // $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      }
    })

    // Brands
    $scope.$on('brand_filter_directive', function (event, params) {
      // console.log('brand_filter_directive count', params)
      if ($rootScope.defaultFilter) {
        $rootScope.defaultFilter = false
        $scope.$emit('removeShow', {})
      }

      if (params.filter['brand._id'].$in.length > 0) {
        $scope.productFilter.brand = params.filter['brand._id']
        // console.log('brand_filter_directive count if', params)
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getTotalCountForPagination($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter);
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          //getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      } else {
        $scope.productFilter.brand = undefined
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getTotalCountForPagination($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter);
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      }
    })

    // Collections
    $scope.$on('collection_filter_directive', function (event, params) {
      // console.log('collection_filter_directive count', params)

      if ($rootScope.defaultFilter) {
        $rootScope.defaultFilter = false
        $scope.$emit('removeShow', {})
      }

      if (params.filter.collection.$in.length > 0) {
        $scope.productFilter.collection = params.filter.collection.$in
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getTotalCountForPagination($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter);
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
        }
      } else {
        $scope.productFilter.collection = undefined
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getTotalCountForPagination($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter);
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
        }
      }
    })

    // category
    $scope.$on('category_filter_directive', function (event, params) {
      // console.log('category_filter_directive count')
      if ($rootScope.defaultFilter) {
        $rootScope.defaultFilter = false
        $scope.$emit('removeShow', {})
      }

      if (params.filter && params.filter.category && params.filter.category.$in && params.filter.category.$in.length > 0) {
        if (!$scope.productFilter) $scope.productFilter = {}

        $scope.productFilter.category = params.filter.category
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getTotalCountForPagination($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter);
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //    $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      } else {
        if ($scope.productFilter && $scope.productFilter.category) {
          $scope.productFilter.category = undefined
          if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
          else {
            getTotalCountForPagination($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter);
            getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
            $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
            // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
            //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
          }
        } else {
          if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
          else {
            getTotalCountForPagination($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter);
            getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
            $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
            // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
            //    $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
          }
        }
      }
    })

    // Watch Filters
    $scope.$on('glass_material_filter_directive', function (event, params) {
      // console.log('glass_material_filter_directive count')
      if ($rootScope.defaultFilter) {
        $rootScope.defaultFilter = false
        $scope.$emit('removeShow', {})
      }

      if (params.filter['watch.glassMaterial'].$in.length > 0) {
        if (!$scope.productFilter.watch) $scope.productFilter.watch = {
          strap: {}
        }

        if (!$scope.productFilter.watch.strap) $scope.productFilter.watch.glassMaterial = {}

        $scope.productFilter.watch.glassMaterial = params.filter['watch.glassMaterial']
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getTotalCountForPagination($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter);
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      } else {
        if (!$scope.productFilter) $scope.productFilter = {}
        if (!$scope.productFilter.watch) $scope.productFilter.watch = {}
        if (!$scope.productFilter.watch.glassMaterial) $scope.productFilter.watch.glassMaterial = {}

        $scope.productFilter.watch.glassMaterial = undefined

        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getTotalCountForPagination($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter);
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      }
    })

    // waterproofLevel_filter_directive
    $scope.$on('waterproofLevel_filter_directive', function (event, params) {
      // console.log('waterproofLevel_filter_directive count', params)
      if ($rootScope.defaultFilter) {
        $rootScope.defaultFilter = false
        $scope.$emit('removeShow', {})
      }

      if (params.filter['watch.waterproofLevel']) {
        if (!$scope.productFilter.watch) $scope.productFilter.watch = {}

        $scope.productFilter.watch.waterproofLevel = params.filter['watch.waterproofLevel']
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getTotalCountForPagination($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter);
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      } else {
        if (!$scope.productFilter) $scope.productFilter = {}
        if (!$scope.productFilter.watch) $scope.productFilter.watch = {}
        if (!$scope.productFilter.watch.waterproofLevel) $scope.productFilter.watch.waterproofLevel = {}

        $scope.productFilter.watch.waterproofLevel = undefined

        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getTotalCountForPagination($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter);
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      }
    })

    // Dial Index
    $scope.$on('dial_index_filter_directive', function (event, params) {
      // console.log('dial_index_filter_directive count')
      if ($rootScope.defaultFilter) {
        $rootScope.defaultFilter = false
        $scope.$emit('removeShow', {})
      }

      if (params.filter['watch.dial.index'].$in.length > 0) {
        if (!$scope.productFilter.watch) $scope.productFilter.watch = {
          dial: {}
        }

        if (!$scope.productFilter.watch.dial) $scope.productFilter.watch.dial = {}

        $scope.productFilter.watch.dial.index = params.filter['watch.dial.index']
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      } else {
        if (!$scope.productFilter) $scope.productFilter = {}
        if (!$scope.productFilter.watch) $scope.productFilter.watch = {}
        if (!$scope.productFilter.watch.dial) $scope.productFilter.watch.dial = {}

        $scope.productFilter.watch.dial.index = undefined

        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      }
    })

    // Dial Indication
    $scope.$on('indication_filter_directive', function (event, params) {
      // console.log('indication_filter_directive count')
      if ($rootScope.defaultFilter) {
        $rootScope.defaultFilter = false
        $scope.$emit('removeShow', {})
      }

      if (params.filter['watch.indication'].$in.length > 0) {
        $scope.productFilter.watch.indication = params.filter['watch.indication']

        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      } else {
        if ($scope.productFilter && !$scope.productFilter.watch) $scope.productFilter.watch = {}
        else $scope.productFilter = {
          watch: {}
        }
        $scope.productFilter.watch.indication = undefined

        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      }
    })

    // Case Material
    $scope.$on('case_material_filter_directive', function (event, params) {
      // console.log('case_material_filter_directive count', params)
      if ($rootScope.defaultFilter) {
        $rootScope.defaultFilter = false
        $scope.$emit('removeShow', {})
      }

      if (params.filter['watch.case.material'].$in.length > 0) {
        if (!$scope.productFilter.watch) $scope.productFilter.watch = {
          case: {}
        }

        if (!$scope.productFilter.watch.case) $scope.productFilter.watch.case = {}

        $scope.productFilter.watch.case.material = params.filter['watch.case.material']
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      } else {
        if (!$scope.productFilter) $scope.productFilter = {}
        if (!$scope.productFilter.watch) $scope.productFilter.watch = {}
        if (!$scope.productFilter.watch.case) $scope.productFilter.watch.case = {}

        $scope.productFilter.watch.case.material = undefined

        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      }
    })

    // Case Shap
    $scope.$on('case_shape_filter_directive', function (event, params) {
      // console.log('case_shape_filter_directive count', params)
      if ($rootScope.defaultFilter) {
        $rootScope.defaultFilter = false
        $scope.$emit('removeShow', {})
      }

      if (params.filter['watch.case.shape'].$in.length > 0) {
        if (!$scope.productFilter.watch) $scope.productFilter.watch = {
          case: {}
        }

        if (!$scope.productFilter.watch.case) $scope.productFilter.watch.case = {}

        $scope.productFilter.watch.case.shape = params.filter['watch.case.shape']
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      } else {
        if (!$scope.productFilter) $scope.productFilter = {}
        if (!$scope.productFilter.watch) $scope.productFilter.watch = {}
        if (!$scope.productFilter.watch.case) $scope.productFilter.watch.case = {}

        $scope.productFilter.watch.case.shape = undefined
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      }
    })

    // Case Width
    $scope.$on('case_size_filter_directive', function (event, params) {
      // console.log('Hey product handling guy How are You? Are You good??', params)
      if ($rootScope.defaultFilter) {
        $rootScope.defaultFilter = false
        $scope.$emit('removeShow', {})
      }

      // console.log('Hey Man Enjoy', params.filter['watch.case.size'])
      if (params.filter['watch.case.size'].$gte || params.filter['watch.case.size'].$lte) {
        if (!$scope.productFilter.watch) $scope.productFilter.watch = {
          case: {}
        }

        if (!$scope.productFilter.watch.case) $scope.productFilter.watch.case = {}

        $scope.productFilter.watch.case.size = params.filter['watch.case.size']
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      } else {
        if (!$scope.productFilter) $scope.productFilter = {}
        if (!$scope.productFilter.watch) $scope.productFilter.watch = {
          case: {}
        }
        if (!$scope.productFilter.watch.case) $scope.productFilter.watch.case = {}

        $scope.productFilter.watch.strap.size = undefined

        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      }
    })

    // Strap Material
    $scope.$on('strap_material_filter_directive', function (event, params) {
      // console.log('strap_material_filter_directive count', params)
      if ($rootScope.defaultFilter) {
        $rootScope.defaultFilter = false
        $scope.$emit('removeShow', {})
      }

      if (params.filter['watch.strap.material'].$in.length > 0) {
        if (!$scope.productFilter.watch) $scope.productFilter.watch = {
          strap: {}
        }

        if (!$scope.productFilter.watch.strap) $scope.productFilter.watch.strap = {}

        $scope.productFilter.watch.strap.material = params.filter['watch.strap.material']
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      } else {
        if (!$scope.productFilter) $scope.productFilter = {}
        if (!$scope.productFilter.watch) $scope.productFilter.watch = {}
        if (!$scope.productFilter.watch.case) $scope.productFilter.watch.case = {}

        $scope.productFilter.watch.case.shape = undefined
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      }
    })

    // Strap Type
    $scope.$on('strap_model_filter_directive', function (event, params) {
      // console.log('strap_model_filter_directive count')
      if ($rootScope.defaultFilter) {
        $rootScope.defaultFilter = false
        $scope.$emit('removeShow', {})
      }

      if (params.filter['watch.strap.model'].$in.length > 0) {
        if (!$scope.productFilter.watch) $scope.productFilter.watch = {
          strap: {}
        }

        if (!$scope.productFilter.watch.strap) $scope.productFilter.watch.strap = {}

        $scope.productFilter.watch.strap.model = params.filter['watch.strap.model']
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      } else {
        if (!$scope.productFilter) $scope.productFilter = {}
        if (!$scope.productFilter.watch) $scope.productFilter.watch = {}
        if (!$scope.productFilter.watch.strap) $scope.productFilter.watch.strap = {}

        $scope.productFilter.watch.strap.model = undefined

        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      }
    })

    // Strap Width
    $scope.$on('strap_width_filter_directive', function (event, params) {
      // console.log('Hey product handling guy How are You? Are You good??', params)
      if ($rootScope.defaultFilter) {
        $rootScope.defaultFilter = false
        $scope.$emit('removeShow', {})
      }

      if (params.filter['watch.strap.width'].$gte || params.filter['watch.strap.width'].$lte) {
        if (!$scope.productFilter.watch) $scope.productFilter.watch = {
          strap: {}
        }

        if (!$scope.productFilter.watch.strap) $scope.productFilter.watch.strap = {}

        $scope.productFilter.watch.strap.width = params.filter['watch.strap.width']
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      } else {
        if (!$scope.productFilter) $scope.productFilter = {}
        if (!$scope.productFilter.watch) $scope.productFilter.watch = {}
        if (!$scope.productFilter.watch.strap) $scope.productFilter.watch.strap = {}

        $scope.productFilter.watch.strap.width = undefined

        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      }
    })

    // Jewel Type
    $scope.$on('jewel_model_filter_directive', function (event, params) {
      // console.log('jewel_model_filter_directive count', params)
      if ($rootScope.defaultFilter) {
        $rootScope.defaultFilter = false
        $scope.$emit('removeShow', {})
      }

      if (params.filter['jewel.type'].$in.length > 0) {
        if (!$scope.productFilter.jewel) $scope.productFilter.jewel = {}

        $scope.productFilter.jewel.type = params.filter['jewel.type']
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      } else {
        if (!$scope.productFilter) $scope.productFilter = {}
        if (!$scope.productFilter.jewel) $scope.productFilter.jewel = {}

        $scope.productFilter.jewel.type = undefined
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      }
    })

    // Jewel Material
    $scope.$on('jewel_material_filter_directive', function (event, params) {
      // console.log('jewel_material_filter_directive count', params)
      if ($rootScope.defaultFilter) {
        $rootScope.defaultFilter = false
        $scope.$emit('removeShow', {})
      }

      if (params.filter['jewel.material'].$in.length > 0) {
        if (!$scope.productFilter.jewel) $scope.productFilter.jewel = {}

        $scope.productFilter.jewel.material = params.filter['jewel.material']
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      } else {
        if (!$scope.productFilter) $scope.productFilter = {}
        if (!$scope.productFilter.jewel) $scope.productFilter.jewel = {}

        $scope.productFilter.jewel.material = undefined
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      }
    })

    // Color Filters
    $scope.$on('color_filter_directive', function (event, params) {
      // console.log('color_filter_directive count', params)
      if ($rootScope.defaultFilter) {
        $rootScope.defaultFilter = false
        $scope.$emit('removeShow', {})
      }

      if (params.filter['watch.case.color'].$in.length || params.filter['watch.strap.color'].$in.length || params.filter['watch.dial.color'].$in.length) {
        // console.log('Some Color Are Selected')
        if (!$scope.productFilter.watch) $scope.productFilter.watch = {}
        if (!$scope.productFilter.watch.case) $scope.productFilter.watch.case = {}
        if (!$scope.productFilter.watch.dial) $scope.productFilter.watch.dial = {}
        if (!$scope.productFilter.watch.strap) $scope.productFilter.watch.strap = {}

        $scope.productFilter.watch.case.color = params.filter['watch.case.color']
        $scope.productFilter.watch.dial.color = params.filter['watch.dial.color']
        $scope.productFilter.watch.strap.color = params.filter['watch.strap.color']
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      } else {
        if (!$scope.productFilter.watch) $scope.productFilter.watch = {}
        if (!$scope.productFilter.watch.case) $scope.productFilter.watch.case = {}
        if (!$scope.productFilter.watch.dial) $scope.productFilter.watch.dial = {}
        if (!$scope.productFilter.watch.strap) $scope.productFilter.watch.strap = {}
        $scope.productFilter.watch.case.color = undefined
        $scope.productFilter.watch.dial.color = undefined
        $scope.productFilter.watch.strap.color = undefined
        // console.log('No Any color selected')
        if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++
        else {
          getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          $scope.filterRecountings($scope.stockFilter, $scope.productFilter)
          // getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
          //  $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter) 
        }
      }
    })

    $scope.addBulkChange = function (){
      console.log('------------ addBulkChange!!');
      $state.go('retailer.add-bulk-change')
    }

    $scope.nameDescriptionBulkChanges = function (){
      console.log('------------ nameDescriptionBulkChanges!!');
      $state.go('retailer.name-and-descriptions-bulk-change')
    }

    $scope.loadProductsForTheCSV = function (csvType, shopFilters, productFilter, orderBy, Separator) {
      console.log('------------- loadProductsForTheCSV!!');
      var shopId = $rootScope.currentShop._id;
      newReq = {
        shopId: shopId,
        shopFilter: angular.copy($scope.stockFilter),
        productFilter: angular.copy($scope.productFilter),
        orderBy: angular.copy($scope.orderBy),
        pageNumber : $scope.pageNumber ? $scope.pageNumber : 1
      }

      $api.get('shop/total-count-for-csvExport/' + shopId, newReq)
        .then(function (response) {
          $scope.totalForCSV = response.data.totalProducts;
          if($scope.totalProducts > $scope.totalForCSV){
            $scope.totalForCSV = $scope.totalProducts;
          }
          $scope.data = [];
          // console.log('csvType ', csvType);
          $scope.loadingCSV = true
          $scope.errMsg = false;
          console.log('$scope.totalForCSV ', $scope.totalForCSV);
          var limitCount = 500;
          console.log(parseInt($scope.totalForCSV/limitCount));
          $scope.count = parseInt($scope.totalForCSV/limitCount) + 1;
          console.log('$scope.count ', $scope.count);
          sample(shopFilters, productFilter, orderBy, csvType, Separator, 0, limitCount);
        })
        .catch(function (reason) {
          reject(reason)
        })
    }

    $scope.loadProductsForTheCSVWithoutFilters = function (csvType, shopFilters, productFilter, orderBy, Separator) {
      // console.log('--------- loadProductsForTheCSVWithoutFilters!');
      // console.log('csvType ', csvType);
      $scope.loadingCSV = true

      $shop.loadProductsForTheCSVWithoutFilters($rootScope.currentShop._id, shopFilters, productFilter, orderBy)
          .then(function (data) {
            // console.log('data ',data);
              if (csvType == 'mainCSV') {
              $scope.testcsv(data.products, 'STOCK OVER VIEW', 'Test', Separator)
              } else if (csvType == 'facebookCSV') {
                $scope.testFacebookcsv(data.products, 'Facebook STOCK OVER VIEW', 'Test', Separator)
              } else if (csvType == 'BolCSV') {
                $scope.testBolcsv(data.products, 'Bol STOCK OVER VIEW', 'Test', Separator)
              } else if (csvType == 'GoogleCSV') {
                $scope.testGooglecsv(data.products, 'Google STOCK OVER VIEW', 'Test', Separator)
              }
              $scope.loadingCSV = false;
          })
          .catch(function (error) {
            $scope.loadingCSV = false
            console.error('2 Error In CSV product data : ')
            console.error(error)
          })
    }

    $scope.serialNumbersExport = function () {
      $scope.loadingCSV = true

      $shop.serialNumbersExport($rootScope.currentShop._id)
        .then(function (data) {
            $scope.SerialNumberCSV(data, 'Serial number csv', 'Test', ';')
            $scope.loadingCSV = false;
        })
        .catch(function (error) {
          $scope.loadingCSV = false
          console.error(error)
        })
    }

    $scope.SerialNumberCSV = function (a, b, c, Separator) {

      var jsonForCsv = []
      $scope.loadingCSV = true
      for (var i = 0; i < a.length; i++) {
        var singleObject = {
          'Product number': a[i].product && a[i].variants[0] && a[i].variants[0].productNumber ? a[i].variants[0].productNumber : '',
          'Serial number': a[i].serialNumber ? a[i].serialNumber : '',
          'Status': a[i].product && a[i].product.stock ? 'In stock' : 'Sold',
          'Label description': a[i].product && a[i].product.labelDescription ? a[i].product.labelDescription : '',
          'Article number': a[i].product && a[i].product.articleNumber ? a[i].product.articleNumber : '',
          'Brand': a[i].product  && a[i].brand && a[i].brand.name ?a[i].brand.name : '',
          'Article group': a[i].aGroup && a[i].aGroup[$rootScope.language] && a[i].aGroup[$rootScope.language].name ? a[i].aGroup[$rootScope.language].name : '',
          'Shop name': a[i].shopName || ''
        }

        jsonForCsv.push(singleObject)

        if (i === (a.length - 1)) {
          jsonForCsv.sort(function(a, b) {
            var x = a['Article group']; var y = b['Article group'];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
          });
          $csv2json.JSONToCSVConvertor(jsonForCsv, b, c, Separator)
          $scope.loadingCSV = false
        }
      }
    }

    function sample(shopFilters, productFilter, orderBy, csvType, Separator, pageNumber, limitCount) {
      // console.log('sample paneNumber ', pageNumber);
      $shop.getOwnShopProductsForCSV($rootScope.currentShop._id, shopFilters, productFilter, orderBy, pageNumber + 1, limitCount, csvType)
          .then(function (data) {
            // console.log('$scope.data ', angular.copy($scope.data));
            // console.log(data)
            console.log('data.products.length ', data.products.length);
            if(!data.products.length) $scope.count = data.pageNumber;
            $scope.data = $scope.data.concat(data.products);
            // console.log('$scope.data ', angular.copy($scope.data));
            // console.log('i ', pageNumber);
            // console.log('data.pageNumber ', data.pageNumber);
            // console.log('$scope.count ',  $scope.count);
            
            if(data.pageNumber == $scope.count){
              // console.log(angular.copy($scope.data));
              // console.log('csvType ', csvType);
              if (csvType == 'mainCSV') {
              $scope.testcsv(angular.copy($scope.data), 'STOCK OVER VIEW', 'Test', Separator)
              } else if (csvType == 'facebookCSV') {
                $scope.testFacebookcsv(angular.copy($scope.data), 'Facebook STOCK OVER VIEW', 'Test', Separator)
              } else if (csvType == 'BolCSV') {
                $scope.testBolcsv(angular.copy($scope.data), 'Bol STOCK OVER VIEW', 'Test', Separator)
              } else if (csvType == 'GoogleCSV') {
                $scope.testGooglecsv(angular.copy($scope.data), 'Google STOCK OVER VIEW', 'Test', Separator)
              }
               $scope.loadingCSV = false;
            }else{
              sample(shopFilters, productFilter, orderBy, csvType, Separator, pageNumber + 1, limitCount)
            }
          })
          .catch(function (error) {
             $scope.errMsg = true;
            $scope.loadingCSV = false
            console.error('1 Error In CSV product data : ')
            console.error(error)
          })
     }

    $scope.openPrintLabelModal = function (product, empty) {
      if(product && product.product && product.product._id) product._id = product.product._id;
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/print-label-modal.html',
        controller: 'printLabelModalController',
        keyboard: false,
        backdrop: 'static',
        resolve: {
          product: function () {
            return product;
          },
          loadProduct: function(){
            return true;
          },
          labelDescription: function() {
            return null
          },
          empty: function () {
            return empty
          }
        }
      })

      // console.log('$scope.product ', $scope.product);
      // console.log('$rootScope.product ', $rootScope.product);
      // console.log('modalInstance ', modalInstance);
      //we will get the value here
      modalInstance.result.then(function (result) {
        if (result) {
          // console.log('result ', result);
          //ready
          $scope.product.labelDescription = result.labelDescription ? result.labelDescription : '';
        }
      }, function () {
        //dismissed
      })
    }

    $scope.testGooglecsv = function (a, b, c,Separator) {
      var jsonForCsv = []
      var prodLength = a.length
      $scope.loadingCSV = true
      for (var i = 0; i < prodLength; i++) {
        var tempVar = "";
        // console.log('a[i] ', a[i]);
        // console.log('a[i].shopProduct.dateLastPurchased ', a[i].shopProduct.dateLastPurchased);
        if(a[i].product.variants[0].brandStratingDigits){
          tempVar = a[i].product.variants[0].productNumber.replace(a[i].product.variants[0].brandStratingDigits, '');
          if(tempVar != undefined && tempVar.startsWith('-')) { tempVar = tempVar.replace('-', '')}
        }else{
          tempVar = a[i].product.variants[0].insertedProductNumber;
          if(tempVar != undefined && tempVar.startsWith('-')) { tempVar = tempVar.replace('-', '')}
        }
        
        var articleGroup, brandName;
        if(a && a[i] && a[i].collections[0] && a[i].collections[0][$rootScope.language]  && a[i].collections[0][$rootScope.language].name) { 
          for (var j = 0; j < a[i].collections.length; j++) {
            if (a[i].collections[j].privateLabel) {
              articleGroup = a[i].collections[j][$rootScope.language].name
            }
          }
          
        }
        if (a[i].product && a[i].product.brand && a[i].product.brand.name) {
          if (a[i].product && a[i].product.brand && a[i].product.brand.alias) {
            // console.log('a[i].product.brand.alias ', a[i].product.brand.alias);
            brandName = (a[i].product.brand.alias) ? a[i].product.brand.alias : 'Unknown brand'
          } else {
            brandName = (a[i].product.brand.name) ? a[i].product.brand.name : 'Unknown brand'
          }
        } else {
          brandName = 'Unknown brand'
        }

        /* Add the product.gender (one of these types: 
          men (product.male: true, product.female: false, product.kids: false) 
          women (male: false, female: true, kids: false)  */
          var genderType = '', productColor = '', productMaterial = '', inputVal = ''
          if (a[i].product && a[i].product.male && a[i].product.female && a[i].product.kids) {
            if (a[i].product.male && a[i].product.female && a[i].product.kids) {
              genderType = 'boys/girls'
            } else if (!a[i].product.male && a[i].product.female && a[i].product.kids) {
              genderType = 'girls'
            } else if (a[i].product.male && !a[i].product.female && a[i].product.kids) {
              genderType = 'boys'
            } else if (a[i].product.male && a[i].product.female && !a[i].product.kids) {
              genderType = 'men/women'
            } else if (!a[i].product.male && a[i].product.female && !a[i].product.kids) {
              genderType = 'women'
            } else if (a[i].product.male && !a[i].product.female && !a[i].product.kids) {
              genderType = 'men'
            } else if (!a[i].product.male && !a[i].product.female && !a[i].product.kids) {
              genderType = 'not-specified'
            }
          }
          
          if (a[i].product && a[i].product.category) {
            if (a[i].product.category == 'JEWEL') {
              productColor = a[i].product.jewel && a[i].product.jewel.color ? a[i].product.jewel.color : ''
              productMaterial = a[i].product.jewel && a[i].product.jewel.material ? a[i].product.jewel.material: ''
              if(a[i].product.jewel && a[i].product.jewel.type){
                var jewelType = a[i].product.jewel.type;
                if(jewelType == 'PENDANT_EARRINGS' || jewelType == 'STUD_EARRINGS' || jewelType == 'HOOP_EARRINGS' || jewelType == 'CREOLE_EARRINGS'){
                  inputVal = '194';
                }else if(jewelType == 'BRACELET' || jewelType == 'TENNIS_BRACELET' || jewelType == 'SLAVE_BRACELET'){
                  inputVal = '191';
                }else if(jewelType == 'CHARM'){
                  inputVal = '192';
                }else if(jewelType == 'BROOCH'){
                  inputVal = '197';
                }else if(jewelType == 'ANKLETS'){
                  inputVal = '189';
                }else if(jewelType == 'CHOKER' || jewelType == 'NECKLACE' || jewelType == 'TENNIS_NECKLACE'){
                  inputVal = '196';
                }else if(jewelType == 'RING' || jewelType == 'SEAL_RING' || jewelType == 'COMBINATION_RING' || jewelType == 'RING_WITH_PEARL' || jewelType == 'RING_WITH_GEM'){
                  inputVal = '200';
                }else{ inputVal = '188'; }
              }else{ inputVal = '188'; }
            } else if (a[i].product.category == 'WATCH') {
              productColor = a[i].product.watch && a[i].product.watch.strap && a[i].product.watch.strap.color ? a[i].product.watch.strap.color : ''
              productMaterial = a[i].product.watch && a[i].product.watch.strap && a[i].product.watch.strap.material ? a[i].product.watch.strap.material : ''
              inputVal = '201'
            } else if (a[i].product.category == 'STRAP') {
              productColor = a[i].product.strap && a[i].product.strap.color ? a[i].product.strap.color : ''
              productMaterial = a[i].product.strap && a[i].product.strap.material ? a[i].product.strap.material : ''
              inputVal = '5123'
            } else if (a[i].product.category == 'OTHERS') {
              productColor = ''
              productMaterial = ''
              inputVal = '000'
            }
          }
          
        var websitelink = ''
        if ($scope.shopWebSite) {
          if ($scope.shopWebSite.includes("https")) {
            var character = $scope.shopWebSite.charAt($scope.shopWebSite.length - 1)
            if (character == '/') {
              websitelink = $scope.shopWebSite + 'product-details/' + a[i].product[$rootScope.language].nameSlug
            } else {
              websitelink = $scope.shopWebSite + '/product-details/' + a[i].product[$rootScope.language].nameSlug
            }

         
          } else if ($scope.shopWebSite.includes("http")) {
            var newShopWebsite = $scope.shopWebSite.replace("http", "https");
            var character = newShopWebsite.charAt(newShopWebsite.length - 1)
          if (character == '/') {
            websitelink = newShopWebsite + 'product-details/' + a[i].product[$rootScope.language].nameSlug
          } else {
            websitelink = newShopWebsite + '/product-details/' + a[i].product[$rootScope.language].nameSlug
          }
          } else if ($scope.shopWebSite) {
            var newShopWebsite = $scope.shopWebSite
            var character = newShopWebsite.charAt(newShopWebsite.length - 1)
            if (character == '/') {
              websitelink = 'https://'+ newShopWebsite + 'product-details/' + a[i].product[$rootScope.language].nameSlug
            } else {
              websitelink = 'https://'+ newShopWebsite + '/product-details/' + a[i].product[$rootScope.language].nameSlug
            }
          }
          
        }
        // console.log('final websitelink ', websitelink);
        // New updated csv object
        // console.log('a[i].shopProduct.dateLastPurchased ', a[i].shopProduct.dateLastPurchased);

        var price = (a[i].shopProduct.price) ? Number(a[i].shopProduct.price * ((a[i].shopProduct.priceVat / 100) + 1)).toFixed(2) : 0;
        // console.log('$scope.language ', $scope.language);
        // if(price && ( $scope.language == 'nl' || $scope.language == 'fr' || $scope.language == 'de')){
        //   // console.log('--------- ifff!!');
        //   price = price.replace('.', ',');
        // }
        // console.log(a[i]);
        // console.log(a[i].product);
        //console.log('a[i].shopProduct.discount', a[i].shopProduct.discount)

        if(a[i].shopProduct.discount && a[i].shopProduct.discount !== 0 && a[i].sellingPrice){
          if(a[i].shopProduct.discountOnPercentage){
            var discountForSaleColumn = (a[i].sellingPrice.toFixed(2) - (a[i].sellingPrice.toFixed(2) * (a[i].shopProduct.discount/100)))
          }else{
            var discountForSaleColumn = (a[i].sellingPrice.toFixed(2) - a[i].shopProduct.discount)
          }
        }

        var singleObject = {
          'ID':a[i].shopProduct.articleNumber ? a[i].shopProduct.articleNumber : '',
          'Title':(a[i].product[$rootScope.language].name) ? capitalizeFirstLetter(a[i].product[$rootScope.language].name) : '',
          'Description':a[i].product[$rootScope.language].shortDescription ? capitalizeFirstLetter(a[i].product[$rootScope.language].shortDescription) : capitalizeFirstLetter(a[i].product[$rootScope.language].name),
          'Stocklevel': (a[i].shopProduct.stock) ? a[i].shopProduct.stock : 0,
          'Condition': 'New',
          'Price': price,
          'Sale price': (a[i].shopProduct.discount && a[i].shopProduct.discount !== 0 && a[i].sellingPrice) ? discountForSaleColumn.toFixed(2) : '',
          'Current price': (a[i].sellingPrice && a[i].shopProduct.discount && a[i].shopProduct.discount !== 0) ? discountForSaleColumn.toFixed(2) : (a[i].sellingPrice ? a[i].sellingPrice.toFixed(2) : ''),
          'Link': websitelink,
          'Image_link': (a[i].product && a[i].product.images && a[i].product.images.length > 0 && a[i].product.images[0] && a[i].product.images[0].src) ? 'https://prismanote.s3.amazonaws.com/'+a[i].product.images[0].src : '',
          'Brand': brandName,
          'Google_product_category':inputVal,
          'Availability': (a[i].shopProduct.stock && a[i].shopProduct.stock > 0) ? 'In stock' : 'Out of stock',
          'EAN':a[i].product.variants[0].ean ? a[i].product.variants[0].ean : '',
          'Last purchase date': a[i].shopProduct.dateLastPurchased || '',
          'Ownership': a[i].shopProduct.ownership ? a[i].shopProduct.ownership : ''
          /* 'Product number': tempVar == '' || tempVar == null || tempVar == undefined ? '' : tempVar,
          'Article group': articleGroup || '',
          'Adult' : 'No',
          'age_group': genderType,
          'color': productColor,
          'gender': genderType,
          'material': productMaterial */
        }

        // Old csv object
        /* var singleObject = {
          'Product ID':a[i].product._id ? a[i].product._id : '',
          'Product title':(a[i].product.category) ? a[i].product.category : 'OTHER',
          'Product short description':a[i].product[$rootScope.language].shortDescription ? a[i].product[$rootScope.language].shortDescription : '',
          'Product link to website': websitelink,
          'Product Image': a[i].product && a[i].product.images && a[i].product.images[0].src ? 'https://prismanote.s3.amazonaws.com/'+a[i].product.images[0].src : '',
          'Stock': (a[i].shopProduct.stock) ? a[i].shopProduct.stock : 0,
          'Selling price incl VAT': (a[i].shopProduct.price) ? Number(a[i].shopProduct.price * ((a[i].shopProduct.priceVat / 100) + 1)).toFixed(2) : 0,
          'Brand': brandName,
          'EAN':a[i].product.variants[0].ean ? a[i].product.variants[0].ean : '',
          'Product number': tempVar == '' || tempVar == null || tempVar == undefined ? '' : tempVar,
          'Article group': articleGroup || '',
          'Condition': 'New',
          'Adult' : 'No',
          'age_group': genderType,
          'color': productColor,
          'gender': genderType,
          'material': productMaterial
        } */

        jsonForCsv.push(singleObject)
        // console.log('prodLength ', prodLength);
        // console.log('(prodLength - 1 ', (prodLength - 1));
        // console.log('i === (prodLength - 1) ', i === (prodLength - 1));
        if (i === (prodLength - 1)) {
          $csv2json.JSONToCSVConvertor(jsonForCsv, b, c, Separator)
          $scope.loadingCSV = false

        }
      }
    }
    
    $scope.testBolcsv = function (a, b, c,Separator) { 
      var jsonForCsv = []
      var prodLength = a.length
      $scope.loadingCSV = true

      var price = (a[i].shopProduct.price) ? Number(a[i].shopProduct.price * ((a[i].shopProduct.priceVat / 100) + 1)).toFixed(2) : 0;
      // console.log('$scope.language ', $scope.language);
      if(price && ( $scope.language == 'nl' || $scope.language == 'fr' || $scope.language == 'de')){
        // console.log('--------- ifff!!');
        price = price.replace('.', ',');
      }

      for (var i = 0; i < prodLength; i++) {
        var singleObject = {
          'EAN': a[i].product.variants[0].ean ? a[i].product.variants[0].ean : '',
          'Condition': 'New',
          'Stocklevel': (a[i].shopProduct.stock) ? a[i].shopProduct.stock : 0,
          'Price': price,
          'Delivery_Code': '1',
          'For_Sale': a[i].shopProduct.stock > 0 ? 'YES': 'NO'
        }

        if (a[i].product.brand) {
          singleObject.Merk = (a[i].product.brand.name) ? a[i].product.brand.name : ''
        } else {
          singleObject.Merk = ''
        }



        jsonForCsv.push(singleObject)

         // 
         if (i === (prodLength - 1)) {
           $csv2json.JSONToCSVConvertor(jsonForCsv, b, c, Separator)
           $scope.loading = false
        }
      }
    }

    function capitalizeFirstLetter(string) {
      return string[0].toUpperCase() + string.slice(1).toLowerCase();
    }

    $scope.testFacebookcsv = function (a, b, c,Separator) {
      var jsonForCsv = []
      var prodLength = a.length
      $scope.loadingCSV = true
      for (var i = 0; i < prodLength; i++) {
        var brandName = ''
        if (a[i].product && a[i].product.brand && a[i].product.brand.name) {
          if (a[i].product && a[i].product.brand && a[i].product.brand.alias) {
            brandName = (a[i].product.brand.alias) ? a[i].product.brand.alias : ''
          } else {
            brandName = (a[i].product.brand.name) ? a[i].product.brand.name : ''
          }
        } else {
          brandName = ''
        }

        if (a[i].product && a[i].product.category) {
          if (a[i].product.category == 'JEWEL') {
            if(a[i].product.jewel && a[i].product.jewel.type){
              var jewelType = a[i].product.jewel.type;
              if(jewelType == 'PENDANT_EARRINGS' || jewelType == 'STUD_EARRINGS' || jewelType == 'HOOP_EARRINGS' || jewelType == 'CREOLE_EARRINGS'){
                inputVal = '194';
              }else if(jewelType == 'BRACELET' || jewelType == 'TENNIS_BRACELET' || jewelType == 'SLAVE_BRACELET'){
                inputVal = '191';
              }else if(jewelType == 'CHARM'){
                inputVal = '192';
              }else if(jewelType == 'BROOCH'){
                inputVal = '197';
              }else if(jewelType == 'ANKLETS'){
                inputVal = '189';
              }else if(jewelType == 'CHOKER' || jewelType == 'NECKLACE' || jewelType == 'TENNIS_NECKLACE'){
                inputVal = '196';
              }else if(jewelType == 'RING' || jewelType == 'SEAL_RING' || jewelType == 'COMBINATION_RING' || jewelType == 'RING_WITH_PEARL' || jewelType == 'RING_WITH_GEM'){
                inputVal = '200';
              }else{ inputVal = '188'; }
            }else{ inputVal = '188'; }
          } else if (a[i].product.category == 'WATCH') {
            inputVal = '201'
          } else if (a[i].product.category == 'STRAP') {
            inputVal = '5123'
          } else if (a[i].product.category == 'OTHERS') {
            inputVal = '000'
          }
        }
        
      var websitelink = ''
      if ($scope.shopWebSite) {
        if ($scope.shopWebSite.includes("https")) {
          var character = $scope.shopWebSite.charAt($scope.shopWebSite.length - 1)
          if (character == '/') {
            websitelink = $scope.shopWebSite + 'product-details/' + a[i].product[$rootScope.language].nameSlug
          } else {
            websitelink = $scope.shopWebSite + '/product-details/' + a[i].product[$rootScope.language].nameSlug
          }

       
        } else if ($scope.shopWebSite.includes("http")) {
          var newShopWebsite = $scope.shopWebSite.replace("http", "https");
        var character = newShopWebsite.charAt(newShopWebsite.length - 1)
        if (character == '/') {
          websitelink = newShopWebsite + 'product-details/' + a[i].product[$rootScope.language].nameSlug
        } else {
          websitelink = newShopWebsite + '/product-details/' + a[i].product[$rootScope.language].nameSlug
        }
        }  else {
          var character = newShopWebsite.charAt(newShopWebsite.length - 1)
        if (character == '/') {
          websitelink = 'https://'+ newShopWebsite + 'product-details/' + a[i].product[$rootScope.language].nameSlug
        } else {
          websitelink = 'https://'+ newShopWebsite + '/product-details/' + a[i].product[$rootScope.language].nameSlug
        }
        }
        
      }

        var price = (a[i].shopProduct.price) ? Number(a[i].shopProduct.price * ((a[i].shopProduct.priceVat / 100) + 1)).toFixed(2) : 0;
        // console.log('$scope.language ', $scope.language);
        // if(price && ( $scope.language == 'nl' || $scope.language == 'fr' || $scope.language == 'de')){
        //   // console.log('--------- ifff!!');
        //   price = price.replace('.', ',');
        // }

        //New updated csv object
        var singleObject = {
          'ID': a[i].shopProduct.articleNumber ? a[i].shopProduct.articleNumber : '',
          'Title':(a[i].product[$rootScope.language].name) ? capitalizeFirstLetter(a[i].product[$rootScope.language].name) : '',
          'Description':a[i].product[$rootScope.language].shortDescription ? capitalizeFirstLetter(a[i].product[$rootScope.language].shortDescription) : capitalizeFirstLetter(a[i].product[$rootScope.language].name),
          'Stocklevel': (a[i].shopProduct.stock) ? a[i].shopProduct.stock : 0,
          'Condition': 'New',
          'Price': price,
          'link': websitelink,
          'image_link': (a[i].product && a[i].product.images && a[i].product.images.length > 0 && a[i].product.images[0].src) ? 'https://prismanote.s3.amazonaws.com/'+a[i].product.images[0].src : '',
          'Brand': brandName,
          'google_product_category':inputVal,
          'Availability': (a[i].shopProduct.stock && a[i].shopProduct.stock > 0) ? 'In stock' : 'Out of stock',
          'Ownership': a[i].shopProduct.ownership ? a[i].shopProduct.ownership : ''
        }

        // Old csv object
       /*  var singleObject = {
          'Product ID': a[i].product._id ? a[i].product._id : '',
          'Product_Title': (a[i].product.category) ? a[i].product.category : 'OTHER',
          'Product_Short_Description': a[i].product[$rootScope.language].shortDescription ? a[i].product[$rootScope.language].shortDescription : '',
          'Stock': (a[i].shopProduct.stock) ? a[i].shopProduct.stock : 0,
          'Condition': 'New',
          'Selling_Price_Incl_VAT': (a[i].shopProduct.price) ? Number(a[i].shopProduct.price * ((a[i].shopProduct.priceVat / 100) + 1)).toFixed(2) : 0,
          'Product_Link_To_Website': websitelink,
          'Product_Image': a[i].product && a[i].product.images && a[i].product.images[0].src ? 'https://prismanote.s3.amazonaws.com/'+a[i].product.images[0].src : ''
        } */

       


        jsonForCsv.push(singleObject)

        if (i === (prodLength - 1)) {
          $csv2json.JSONToCSVConvertor(jsonForCsv, b, c, Separator)
          $scope.loadingCSV = false
        }
      }
    }

    $scope.testcsv = function (a, b, c, Separator) {

      var jsonForCsv = []
      var prodLength = a.length

      $scope.loadingCSV = true
      for (var i = 0; i < prodLength; i++) {
        var lastPurchaseOrderDate = ''
        var lastOrderDate = ''

        if (a[i].shopProduct && a[i].shopProduct.dateLastPurchased) {

          lastPurchaseOrderDate = new Date(a[i].shopProduct.dateLastPurchased)

          if(a[i].shopProduct.dateLastPurchased.startsWith('+')) { //if it's not an ISODate object, it caught here. Example: "+020010-08-02T22:00:00.000Z"

            var parts = a[i].shopProduct.dateLastPurchased.split('-')

            if(parts[0] && parts[0].length === 7) {

              var extractedYear = parts[0].substr(parts[0].length - 2)
  
              if(!isNaN(extractedYear)) {
                //replacing "+020010" with "2010", and putting it back together
                parts[0] = String("20"+extractedYear)
                lastPurchaseOrderDate = new Date(parts.join("-")) 
                console.error('Invalid date: ', a[i].shopProduct.dateLastPurchased, 'changed to',lastPurchaseOrderDate)
              }
            } else {
              lastPurchaseOrderDate = ''
            }
          }
          lastPurchaseOrderDate = lastPurchaseOrderDate.getDate() + '-' + (parseInt(lastPurchaseOrderDate.getMonth()) + 1) + '-' + lastPurchaseOrderDate.getFullYear()
        } else {
          lastPurchaseOrderDate = ''
        }

        if (a[i].transaction && a[i].transaction.dateCreated) {
          lastOrderDate = new Date(a[i].transaction.dateCreated)
          lastOrderDate = lastOrderDate.getDate() + '-' + (parseInt(lastOrderDate.getMonth()) + 1) + '-' + lastOrderDate.getFullYear()
        } else {
          lastOrderDate = ''
        }
        // console.log('--------------------- printing product !');
        var tempVar = "";
        if(a[i].product.variants[0].brandStratingDigits && a[i].product.variants[0].brandStratingDigits !== "" && a[i].product.variants[0].brandStratingDigits !== a[i].product.variants[0].productNumber){
          tempVar = a[i].product.variants[0].productNumber.replace(a[i].product.variants[0].brandStratingDigits, '');
          if(tempVar != undefined && tempVar.startsWith('-')) { tempVar = tempVar.replace('-', '')}
        }else if(a[i].product.variants[0].insertedProductNumber){
          tempVar = a[i].product.variants[0].insertedProductNumber;
          if(tempVar != undefined && tempVar.startsWith('-')) { tempVar = tempVar.replace('-', '')}
        }else{
          tempVar = a[i].product.variants[0].productNumber;
        }
        var articleGroup;
        if(a && a[i] && a[i].collections && a[i].collections[0] && a[i].collections[0][$rootScope.language]  && a[i].collections[0][$rootScope.language].name) { 
          for (var j = 0; j < a[i].collections.length; j++) {
            if (a[i].collections[j].privateLabel) {
              articleGroup = a[i].collections[j][$rootScope.language].name
            }
          }
          
        }
        var sellingPriceInclVar = (a[i].shopProduct.price) ? Number(a[i].shopProduct.price * ((a[i].shopProduct.priceVat / 100) + 1)).toFixed(2) : 0;
        // console.log('$scope.language ', $scope.language);
        if(sellingPriceInclVar && ( $scope.language == 'nl' || $scope.language == 'fr' || $scope.language == 'de')){
          // console.log('--------- ifff!!');
          sellingPriceInclVar = sellingPriceInclVar.replace('.', ',');
        }

        var sellingAMT = (a[i].shopProduct.stock && a[i].shopProduct.price) ? Number(a[i].shopProduct.stock * a[i].shopProduct.price * ((a[i].shopProduct.priceVat / 100) + 1)).toFixed(2) : 0;
        // console.log('sellingAMT ', sellingAMT);
        // console.log('$scope.language ', $scope.language);
        if(sellingAMT && ( $scope.language == 'nl' || $scope.language == 'fr' || $scope.language == 'de')){
          // console.log('--------- ifff!!');
          sellingAMT = sellingAMT.replace('.', ',');
        }

        var purchaseAMT = (a[i].shopProduct.purchasePrice) ? Number(a[i].shopProduct.purchasePrice).toFixed(2) : 0;
        // console.log('purchaseAMT ', purchaseAMT);
        // console.log('$scope.language ', $scope.language);
        if(purchaseAMT && ( $scope.language == 'nl' || $scope.language == 'fr' || $scope.language == 'de')){
          // console.log('--------- ifff!!');
          purchaseAMT = purchaseAMT.replace('.', ',');
        }

        var singleObject = {
          'Shop ArticleNumber': (a[i].shopProduct.articleNumber) ? a[i].shopProduct.articleNumber : '',
          'Label Description': (a[i].shopProduct.labelDescription) ? capitalizeFirstLetter(a[i].shopProduct.labelDescription) : '',
          'Stocklevel': (a[i].shopProduct.stock) ? a[i].shopProduct.stock : 0,
          'Min stock': (a[i].shopProduct.minStock) ? a[i].shopProduct.minStock : 0,
          'Selling price incl VAT': sellingPriceInclVar,
          'Selling amount': sellingAMT,
          'Purchase amount': purchaseAMT,
          'Product number prisma note': a[i].product.variants[0].productNumber,
          'Product number': tempVar != '' ? tempVar : a[i].product.variants[0].insertedProductNumber,
          'Ean': a[i].product.variants[0].ean || '',
          'Weight gr/st': (a[i].product.weight) ? a[i].product.weight : 0,
          'Edelst. gewicht krt/st': (a[i].product.totalCaratWeight) ? a[i].product.totalCaratWeight : 0,
          'Category': (a[i].product.category) ? a[i].product.category : 'OTHER',
          'Last Purchase Order Date': (lastPurchaseOrderDate) || '', 
          'Last Order Date': (lastOrderDate) || '',
          'Selling days': (a[i].salewrtdays > 1) ? a[i].salewrtdays : '-',
          'Article group': articleGroup || '',
          'Ownership': a[i].shopProduct.ownership ? a[i].shopProduct.ownership : ''
        }

        if (a[i].product.brand) {
          singleObject.Brand = (a[i].product.brand.name) ? a[i].product.brand.name : ''
        } else {
          singleObject.Brand = ''
        }

        if (a[i].supplier) {
          singleObject.Supplier = (a[i].supplier.name) ? a[i].supplier.name : ''
        } else {
          singleObject.Supplier = ''
        }

        if (a[i].product[$rootScope.language]) {
          singleObject['Product Name'] = (a[i].product[$rootScope.language].name) ? a[i].product[$rootScope.language].name : a[i].product.en.name
        } else {
          singleObject['Product Name'] = a[i].product.en.name
        }
        // console.log('singleObject ', singleObject);
        jsonForCsv.push(singleObject)

        if (i === (prodLength - 1)) {
          // console.log('------------- if!');
          $csv2json.JSONToCSVConvertor(jsonForCsv, b, c, Separator)
          $scope.loadingCSV = false
        }
      }
    }

    $scope.reloadPage = function (status) {
      // console.log('--------------------- calling inside reloadPage!!')
      if (status == 'filter') {
        $retailerFilters.removeCategoryFilter()
        $retailerFilters.removeGenderFilter()
        $retailerFilters.removeParticularsFilter()
        $retailerFilters.removeProductFilter()
        $retailerFilters.removeIndicationFilter()
        $retailerFilters.removeStrapTypeFilterFilter()
        $retailerFilters.removeSupplierFilter()
        $retailerFilters.removeCollectionFilter()
        $retailerFilters.removeBrandFilter()
        window.location.reload()
      } else {
        window.location.reload()
      }
    }

    $scope.selectCollection = function (collection) {
      var index = _.findIndex($rootScope.currentShop.collections, function (el) {
        return el._id === collection._id
      })

      if ($scope.selectdCollections.includes(collection._id)) {
        var indexTemp = $scope.selectdCollections.indexOf(collection._id)
        $scope.selectdCollections.splice(indexTemp, 1)
        $rootScope.currentShop.collections[index].applied = false
      } else {
        $rootScope.currentShop.collections[index].applied = true
        $scope.selectdCollections.push(collection._id)
      }

      $scope.productFilter.collection = $scope.selectdCollections
      getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
      //getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
      //    $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter)
    }

    $rootScope.selectCollectionFromModal = function (collectionId) {
      $scope.selectdCollections = []
      $scope.selectdCollections.push(collectionId)
      $scope.productFilter.collection = $scope.selectdCollections
      getStockModeProductsShopWise($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
      //getFilterCounts($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter)
      //    $scope.getAllFilterCounts($scope.stockFilter, $scope.productFilter)
    }

    $scope.openSetCollections = function () {
      // $scope.showLoader = true;
      // console.log('openSetCollections called');
      $shop.getShopsCollections($rootScope.currentShop.nameSlug)
        .then(function (success) {
          $scope.showLoader = false
          delete $rootScope.currentShop.collections
          $rootScope.currentShop.collections = success.shopCollections
          // console.log('currentShop.collections: ', $rootScope.currentShop.collections);
          if ($rootScope.currentShop.collections.length > 0) {
            var modalInstance = $uibModal.open({
              templateUrl: '../views/modal/retailer-collections-show-options-modal.html',
              controller: ['$scope', '$uibModalInstance', '$rootScope', 'prompt', '$collection',
                function ($scope, $uibModalInstance, $rootScope, prompt, $collection) {
                  $scope.editCollection = false
                  $scope.showLoader = false
                  $scope.addNewPVC = false
                  $scope.saveCollectionsOptions = function () {
                    
                    $rootScope.currentShop.collections = $rootScope.currentShop.collections.map(function (el) {
                      if (el.category && el.category.subCategories && el.category.subCategories.length) el.category.subCategories = undefined;
                      if (!el.productType) el.productType = undefined;
                      return el;
                    });
                    var data = JSON.parse(JSON.stringify($rootScope.currentShop.collections));
                    // console.log('saveCollectionsOptions: ', $rootScope.currentShop.collections, data);
                    $shop.changeShopCollectionsShowOptions($rootScope.currentShop._id, $rootScope.currentShop.collections)
                      .then(function (success) {
                        $scope.cancel()
                      })
                      .catch(function (err) {
                        console.error('Error in the modify shop collections')
                      })
                  }

                  $scope.openCollectionProducts = function (productId) {
                    // console.log('productId::::::',productId);
                    $scope.loading = true
                    var data = {
                      collectionId: productId,
                      shopId: $rootScope.currentShop._id
                    }
                    $api.post('collection-products', data)
                      .then(function (response) {
                       // console.log('response:::',response);
                       $scope.productsData = response.data.products
                       $scope.openProductModal($scope.productsData)
                      })
                      .catch(function (reason) {
                        $log.error('Error while fetching collection data', reason)
                        $scope.loading = false
                      })
                  }

                  $scope.openProductModal = function (products) {
                    // console.log("Product", product)
                    // console.log("Hey Product Model CHeck this",products);
                    return $q(function (resolve, reject) {
                      $scope.loading = false
                      var data = {
                        'products': products,
                        'modal': 'collections'
                      };
                      // console.log('data@@@@!!!!!::',data);
                      var modalInstance = $uibModal.open({
                        templateUrl: '../views/modal/retailer-shop-product-sorting-modal.html',
                        controller: 'retailerShopProductSortingController',
                        size: 'lg',
                        resolve: {
                          product: function () {
                            return data;
                          }
                        }
                      });
                
                      modalInstance.result
                        .then(function (response) {
                          // console.log("Response After Close Modal", response);
                          if (response) {
                            // console.log('Model response');
                            /* $api.post('collection-products', data)
                              .then(function (response) {
                                $scope.products = response.data.products
                              }) */
                          }
                        })
                        .catch(function (reason) {
                          // console.log("Here", reason);
                          reject(reason);
                        });
                    });
                  }

                  $scope.editPrivateLableCollection = function (shopId, collection, singleIndex) {
                    // console.log('editPrivateLableCollection called');
                    $scope.errorLog = {}
                    var tempCollection = $rootScope.currentShop.collections[singleIndex]
                    $scope.collectionTemp = JSON.parse(JSON.stringify(tempCollection))
                    /* $scope.collectionTemp.collections.forEach(function (item) {
                      
                    }) */
                    $rootScope.currentShop.collections[singleIndex].edit = true
                    $scope.editCollection = true
                  }

                  $scope.categoryChange = function (collection) {
                    // console.log('categoryChange: ', collection, $scope.allDynamicProductCategories);
                    if (collection && collection.category._id && $scope.allDynamicProductCategories && $scope.allDynamicProductCategories.length) {
                      // console.log('categoryChange 123: ', collection, $scope.allDynamicProductCategories);
                      var selectedCategory;
                      for (var i = 0; i < $scope.allDynamicProductCategories.length; i++) {
                        if ($scope.allDynamicProductCategories[i]._id == collection.category._id) {
                          collection.category.name = $scope.allDynamicProductCategories[i].category.name;
                          collection.category.nameSlug = $scope.allDynamicProductCategories[i].category.nameSlug;
                          collection.category.subCategories = $scope.allDynamicProductCategories[i].subCategories;
                          collection.category._id = $scope.allDynamicProductCategories[i]._id;
                          break;
                        }
                      }
                    } else if (!collection || !collection.category || !collection.category._id) {
                      collection.category = undefined;
                    }
                    // collection.category = {
                    //   _id: collection.category._id,
                    //   name: collection.category.category.name,
                    //   nameSlug: collection.category.category.nameSlug,
                    // }
                    collection.productType = undefined;
                  }

                  $scope.subcategoryChange = function (collection) {
                    // console.log('subcategoryChange: ', collection);
                    // collection.productType = {
                    //   _id: collection.productType._id,
                    //   name: collection.productType.name,
                    //   nameSlug: collection.productType.nameSlug,
                    // }
                  }

                  $scope.$on('add-new-collection', function (event, status) {
                    if (status) {
                      $shop.getShopsCollections($rootScope.currentShop.nameSlug)
                        .then(function (success) {
                          delete $rootScope.currentShop.collections
                          $rootScope.currentShop.collections = success.shopCollections
                        })
                        .catch(function (error) {
                          console.error('error in finding shop collection', error)
                        })
                    }
                  })

                  $scope.$on('edit-new-collection', function (event, collectionId) {
                    // console.log("Collection Id", collectionId)

                    var index = _.findIndex($rootScope.currentShop.collections, function (o) { return o._id.toString() == collectionId._id.toString(); });
                    if (index > -1) {
                      // console.log("Hey product handling guy Check this indxe");
                      $scope.editPrivateLableCollection($rootScope.currentShop._id, $rootScope.currentShop.collections[index], index)
                    }
                    else {
                      // console.log("Collection is not in shop");
                      $shop.addNewCollectionInShop($rootScope.currentShop._id, collectionId._id)
                        .then(function (success) {
                          $shop.getShopsCollections($rootScope.currentShop.nameSlug)
                            .then(function (success) {
                              delete $rootScope.currentShop.collections
                              $rootScope.currentShop.collections = success.shopCollections
                              var index = _.findIndex($rootScope.currentShop.collections, function (o) { return o._id.toString() == collectionId._id.toString(); });
                              if (index > -1) {
                                // console.log("Hey product handling guy Check this indxe");
                                $scope.editPrivateLableCollection($rootScope.currentShop._id, $rootScope.currentShop.collections[index], index)
                              }
                            })
                            .catch(function (error) {
                              console.error('error in finding shop collection', error)
                            })
                        })
                        .catch(function (error) {
                          // console.log("Error", error)
                        })
                    }
                  })

                  $scope.mergePrivateLableCollection = function (shopId, collection, singleIndex) {
                    $rootScope.currentShop.collections[singleIndex].merge = true
                    $scope.mergeCollection = true
                  }

                  $scope.cancleUpdate = function (singleIndex) {
                    delete $rootScope.currentShop.collections[singleIndex].edit
                    $scope.editCollection = false
                    $rootScope.currentShop.collections[singleIndex] = $scope.collectionTemp
                  }

                  $scope.cancelMergeCollections = function (singleIndex) {
                    delete $rootScope.currentShop.collections[singleIndex].merge
                    $scope.mergeCollection = false
                    $scope.haveToMerge = null
                  }
                  $scope.getCanMergeCollections = function (collectionId) {
                    $shop.getCollectionsForMergeCollections(collectionId, $rootScope.currentShop._id)
                      .then(function (mergeCollections) {
                        // console.log('Can merge collections', mergeCollections)
                        $scope.mergeCollections = mergeCollections
                      })
                      .catch(function (error) {
                        console.error('Error', error)
                      })
                  }

                  $scope.fetchTheDynamicCollProdCategory = function () {
                    console.log('fetchTheDynamicCollProdCategory called');
                    try {
                      $shop.fetchProductCategoryByShopId({ shopId: $rootScope.currentShop._id, language: $rootScope.language }).then(function (response) {
                        // console.log('fetchProductCategoryByShopId: ', response);
                        if (response && response.data && response.data.length) {
                          console.log('fetchProductCategoryByShopId response: ', response.data);
                          $scope.allDynamicProductCategories = response.data;
                        }
                      }).catch(function (error) {
                        console.error('Error', error)
                      })
                    } catch (e) {
                      console.log('Went wrong: ', e);
                    }
                  }

                  $scope.editCategory = function (category) {
                    console.log(category);
                    $scope.isEditCategory = true;
                    $scope.addEditCategory = category;
                  }

                  $scope.updateAddDynamicCategory = function () {
                    var body = $scope.addEditCategory;
                    body.isEditCategory = $scope.isEditCategory;
                    body.shopId = $rootScope.currentShop._id;
                    // console.log('updateAddDynamicCategory called: ', body);
                    $shop.addDynamicProductCategory(body).then(function (response) {
                      console.log('addDynamicProductCategory: ', response, $scope.allDynamicProductCategories);
                      // if ($scope.isAddCategory && response && response.data) {
                      //   // $scope.allDynamicProductCategories.push(response.data);

                      // }
                      // $scope.fetchTheDynamicCollProdCategory();

                      // $scope.showLoader = true;
                      // console.log('openSetCollections called');
                      // $shop.getShopsCollections($rootScope.currentShop.nameSlug).then(function (success) {
                      //   $scope.showLoader = false
                      //   delete $rootScope.currentShop.collections
                      //   $rootScope.currentShop.collections = success.shopCollections
    
                      // }).catch(function (error) {
                      //   console.log('went wrong: ', error);
                      // });

                      $scope.isEditCategory = false;
                      $scope.isaddEditCategory = false;
                      $scope.isAddCategory = false;


                    }).catch(function (error) {
                      console.error('Error', error)
                    })
                  }

                  $scope.cancelTheAddEditCategory = function () {
                    $scope.isEditCategory = false;
                    $scope.isaddEditCategory = false;
                    $scope.isAddCategory = false;
                    $scope.addEditCategory = {}
                  }

                  $scope.addCategory = function () {
                    console.log('addCategory called');
                    $scope.addEditCategory = {
                      category: {
                        name: '',
                      },
                      subCategories: []
                    };
                    $scope.isAddCategory = true;
                  }

                  $scope.addNewPVCollection = function () {
                    console.log('addNewPVCollection called');
                    $scope.addNewPVC = true;
                    $scope.product = {};
                  }

                  $scope.addSubCategory = function () {
                    $scope.addEditCategory.subCategories.push({ name: '' })
                  }

                  var searchItems = function (str) {
                    $scope.tempName = str

                    var matches = []

                    var collections = JSON.parse(JSON.stringify($scope.mergeCollections))

                    var collcetionLength = collections.length

                    if (collections != undefined && collcetionLength > 0) {
                      collections.forEach(function (item) {
                        if ((item[$rootScope.language] != undefined && item[$rootScope.language].name != undefined) && ((item[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) || (item[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0))) matches.push(item)
                      })
                    }

                    return matches
                  }

                  $scope.searchPrivateLableCollcetion = function (str) {
                    return searchItems(str, 'privateLable')
                  }

                  $scope.privateLableCollectionSelected = function (selected) {
                    // console.log('COLLECTION SELECTED: ', selected)
                    $scope.haveToMerge = JSON.parse(JSON.stringify(selected.originalObject))
                  }

                  $scope.mergeTwoCollections = function (keepCollection, replaceCollection) {
                    // console.log('Keep Collection ', keepCollection)
                    // console.log('Replace Collection ', replaceCollection)
                    $scope.showLoader = true
                    $shop.mergeTwoShopCollections(keepCollection._id, replaceCollection._id, $rootScope.currentShop._id)
                      .then(function (response) {
                        // console.log('Respose', response)
                        $shop.getShopsCollections($rootScope.currentShop.nameSlug)
                          .then(function (success) {
                            // $rootScope.currentShop.collections = success.shopCollections;
                            $shop.getShopsCollections($rootScope.currentShop.nameSlug)
                              .then(function (success) {
                                // console.log('Success ', success)
                                $scope.showLoader = false
                                $scope.mergeCollection = false
                                delete $rootScope.currentShop.collections
                                $rootScope.currentShop.collections = success.shopCollections
                              })
                              .catch(function (error) {
                                $scope.showLoader = false
                                console.error('Error in gettig Collections after merge', error)
                              })
                          })
                          .then(function (error) {

                          })
                      })
                      .catch(function (error) {
                        // console.log('Error', error)
                      })
                  }

                  $scope.updateCollection = function (shopId, index) {
                    $collection.updateOwnPtivateLableCollection(shopId, $rootScope.currentShop.collections[index])
                      .then(function (error, updatedCollection) {
                        delete $rootScope.currentShop.collections[index].edit
                        $scope.editCollection = false
                      })
                      .catch(function (error) {
                        if (error.status == 409) {
                          $scope.errorLog = error.data.error
                        } else {
                          console.error('Error In ', error)
                        }
                      })
                  }

                  function capitalizeFirstLetter(string, firstLetter) {
                    if (!string) return '';
                    return firstLetter.toUpperCase() + string.slice(1);
                  }

                  $scope.checkCollectionName = function (name, language, index) {
                  console.log('$scope.colllection', $rootScope.currentShop.collections, index);
                    console.log('checkCollectionName called', $rootScope.currentShop.collections[index][language].name);
                    var firstLetter = '';
                    if (name) firstLetter = name.charAt(0);
                    name = name.toLowerCase();
                    $rootScope.currentShop.collections[index][language].name = capitalizeFirstLetter(name, firstLetter);
                    name = $rootScope.currentShop.collections[index][language].name;
                    console.log('name: ', name);
                    if (name == 'services') {
                      $scope.errorLog[language] = true
                    } else {
                      $scope.errorLog[language] = false
                    }
                  }

                  // Delete Own Privbate Lable Collection
                  $scope.deleteOwnPtivateLableCollection = function (shopId, collectionId) {
                    // $scope.productUpdateLoading = true;
                    $collection.deleteOwnPtivateLableCollection(shopId, collectionId)
                      .then(function (success) {
                        // Get All Shop Collections
                        $shop.getShopsCollections($rootScope.currentShop.nameSlug)
                          .then(function (success) {
                            delete $rootScope.currentShop.collections
                            $rootScope.currentShop.collections = success.shopCollections
                          })
                          .catch(function (error) {
                            console.error('error in finding shop collection', error)
                          })
                      })
                      .catch(function (err) {
                        if (err.status == 400 && err.data.status == true) {
                          prompt({
                            title: 'Sorry! You can not delete this Collection',
                            message: 'This Collection is used in ' + err.data.data + ' products! Click on OK and we will redirect you to all the products of this collection. In this way you can replace the product collections to another collection'
                          }).then(function () {
                            $rootScope.selectCollectionFromModal(collectionId)
                            $uibModalInstance.dismiss('cancel')
                          }, function () {
                            reject('Cancel')
                          })
                        } else {
                          console.error('Error in remove Private Lable Collection', err)
                        }
                      })
                  }

                  $scope.cancel = function () {
                    $uibModalInstance.dismiss('cancel')
                  }
                }
              ],
              size: 'lg',
              backdrop: 'static',
              keyboard: false
            })
          }
        })
        .catch(function (err) {
          console.error('Error In Finding the Shop Collections', err)
        })
    }

    $scope.findProductBasedSearchValue = function (searchValue) {
      $scope.stockLoad = true
      $scope.stockProducts = []
      // console.log('Search text', searchValue)
      if (searchValue && searchValue.length > 2) {
        $scope.searchMode = true
        $scope.searchValue = searchValue;
        $shop.searchStockProducts($rootScope.currentShop._id, searchValue, {})
          .then(function (products) {
            $scope.stockProducts = products
            $scope.totalProducts = $scope.stockProducts.length
            $scope.stockLoad = false
          })
          .catch(function (error) {
            console.error('Error', error)
          })
      } else {
        $scope.searchMode = false
        $scope.searchValue = undefined;
        if ($scope.oldProducts && $scope.oldProducts.length) {
          $scope.stockProducts = $scope.oldProducts
          $scope.stockLoad = false
          // delete $scope.oldProducts;
          getTotalCountForPagination($rootScope.currentShop.nameSlug, $scope.stockFilter, $scope.productFilter, $scope.orderBy, false);
        }
      }
    }

    $scope.openMergeAnalysis = function () {
      // console.log('openMergeAnalysis product handling guy ')
      return $q(function (resolve, reject) {
        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/shop-analysis-modal.html',
          controller: 'shopAnalysisModalController',
          size: 'lg',
          resolve: {
            currentShopId: function () {
              return $rootScope.currentShop._id
            }
          }
        })

        modalInstance.result
          .then(function (response) {
            resolve(response)
          })
          .catch(function (reason) {
            console.error('reason', reason)
            reject(reason)
          })
      })
    }

    $scope.isFilterSet = function () {
      if (typeof ($scope.stockFilter) != 'undefined' && typeof ($scope.productFilter) != 'undefined') {
        return true;
      } else {
        return false;
      }
    }

    $scope.showSuggestionsLoop = function () {
      console.log('showSuggestionsLoop called');
      prompt({
        title: $language.translate('CONFIRMATION'),
        message: $language.translate('ARE_YOU_SURE_TO_TO_PUT_EVERYTHING_ONLINE')
      }).then(function (res) {
        console.log('res: ', res);
        if (res && res.cancel) return true;
        var body = {
          shopId: $rootScope.currentShop._id
        }
        $shop.makeShopProudctShowTrue(body).then(function (response) {
          console.log('response', response)
        }).catch(function (error) {
          console.error('makeShopProudctShowTrue Error', error)
        })
      }, function () {
        reject('Cancel')
      })
    }

  }
])