prismanoteApp.controller('labelModalController', ['$scope', 'label', 'layouts', '$uibModalInstance',
  function ($scope, label, layouts, $uibModalInstance) {
    $scope.label = label
    $scope.labelLayouts = angular.copy(layouts)

    $scope.leftFields = $scope.label.fields.left.length
    $scope.rightFields = $scope.label.fields.right.length

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    $scope.saveDocument = function () {
      $uibModalInstance.close($scope.label)
    }

    $scope.delete = function () {
      $uibModalInstance.dismiss('delete')
    }

    $scope.getNumber = function (number) {
      return new Array(number)
    }

    $scope.checkFieldsLenght = function (length, mode) {
      var fields = $scope.label.fields[mode]

      if (fields && fields.length > length) {
        // there are more fields in the array than the specified lenght, so we need to remove the difference from the END (mark the -!!)
        var diff = fields.length - length
        fields.splice(-fields.length - 1, diff)
      }
    }

    $scope.onDropComplete = function (data, id) {
      data = data.data
      if (!id || id == null) return

      var input = document.getElementById(id)
      if (input == undefined) return

      var scrollPos = input.scrollTop
      var pos = 0
      var browser = ((input.selectionStart || input.selectionStart == '0') ? 'ff' : (document.selection ? 'ie' : false))

      if (browser == 'ie') {
        input.focus()
        var range = document.selection.createRange()
        range.moveStart('character', -input.value.length)
        pos = range.text.length
      } else if (browser == 'ff') {
        pos = input.selectionStart
      }

      var front = (input.value).substring(0, pos)
      var back = (input.value).substring(pos, input.value.length)

      input.value = front + data.name + back

      pos = pos + data.name.length

      if (browser == 'ie') {
        input.focus()
        var range = document.selection.createRange()
        range.moveStart('character', -input.value.length)
        range.moveStart('character', pos)
        range.moveEnd('character', 0)
        range.select()
      } else if (browser == 'ff') {
        input.selectionStart = pos
        input.selectionEnd = pos
        input.focus()
      }
      input.scrollTop = scrollPos
      angular.element(input).triggerHandler('change')
    }

    $scope.addFields = function (position) {
      $scope.label.fields[position].push('')
    }

    $scope.removeField = function (index, position) {
      $scope.label.fields[position].splice(index, 1)
    }
  }])
