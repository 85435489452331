prismanoteApp.controller('updateBrandCampaignController', ['$scope', '$rootScope', '$brandCampaign', '$campaign', '$company', '$state', '$stateParams',
  function ($scope, $rootScope, $brandCampaign, $campaign, $company, $state, $stateParams) {
    console.log('updateBrandCampaignController : called', $stateParams.campaignId)

    $scope.activeSelectedCampaignType = 'carousel'
    $scope.awsUrl = $rootScope.awsUrl
    $scope.media = []
    $scope.news = {}
    $scope.landingpageimages = []
    $scope.urlPattern = /^(https?:\/\/)?[www]*\.[a-z0-9-]*\.?[a-z0-9-]+\.[a-z0-9-]+(\/[^<>]*)?$/
    $scope.todayPlusTenDays = new Date((+new Date() + 1210000000))
    $scope.todayPlusHunderdDays = new Date((+new Date() + 8640000000))

    $scope.startDateOptionsFirstEmail = {
      minDate: new Date((+new Date() + 345600000))
      // maxDate: ($scope.campaign.startDate),
    }

    $scope.startDateOptionsSecondEmail = {
      minDate: new Date((+new Date() + 432000000))
      // maxDate: ($scope.campaign.startDate),
    }

    $scope.getCampaignById = function () {
      $scope.loading = true
      var obj;
      if($rootScope.user.role == 'retailer'){
        obj = { shopId: $rootScope.currentShop._id };
      }else if($rootScope.user.role == 'wholesaler'){
        obj = { companyId : $rootScope.currentCompany._id }
      }
      $campaign.getCampaignById($stateParams.campaignId, obj).then(function (campaign) {
        $scope.loading = false
        $scope.showError = false
        $scope.campaign = campaign
        console.log('getCampaignById: ', $scope.campaign)
        $scope.campaign.startDate = new Date($scope.campaign.startDate)
        $scope.campaign.endDate = new Date($scope.campaign.endDate)
        $scope.campaign.tasks[0].firstInvitationEmail = {
          date: ($scope.campaign.tasks[0].firstInvitationEmail && $scope.campaign.tasks[0].firstInvitationEmail.date) ? new Date($scope.campaign.tasks[0].firstInvitationEmail.date) : new Date(),
          isEmailSent: !!(($scope.campaign.tasks[0].firstInvitationEmail && $scope.campaign.tasks[0].firstInvitationEmail.isEmailSent)),
          isEnabled: !(($scope.campaign.tasks[0].firstInvitationEmail && !$scope.campaign.tasks[0].firstInvitationEmail.isEnabled))
        }
        $scope.campaign.tasks[0].secondInvitationEmail = {
          date: ($scope.campaign.tasks[0].secondInvitationEmail && $scope.campaign.tasks[0].secondInvitationEmail.date) ? new Date($scope.campaign.tasks[0].secondInvitationEmail.date) : new Date(),
          isEmailSent: !!(($scope.campaign.tasks[0].secondInvitationEmail && $scope.campaign.tasks[0].secondInvitationEmail.isEmailSent)),
          isEnabled: !(($scope.campaign.tasks[0].secondInvitationEmail && !$scope.campaign.tasks[0].secondInvitationEmail.isEnabled))
        }

        $scope.startDateOptionsFirstEmail.maxDate = $scope.campaign.startDate
        $scope.startDateOptionsSecondEmail.maxDate = $scope.campaign.startDate

        $scope.activeSelectedCampaignType = $scope.campaign.tasks[0].fbPromotionSettings.campaignType
        if ($scope.campaign.tasks[0].newsDetail) $scope.news = $scope.campaign.tasks[0].newsDetail
        $scope.changeActiveSelectedCampaignType($scope.activeSelectedCampaignType)
        $scope.filterSex = $scope.campaign.tasks[0].fbPromotionSettings.sex
        $scope.brandSelected($scope.campaign.brandDetail)
        $scope.startDateOptions()
        $scope.activeTab = $scope.campaign.currentStep
        $scope.checkDefaultPromotionSettings()
      }).catch(function (error) {
        console.log('error: ', error)
        $scope.loading = false
        $scope.showError = true
        $scope.errorMessage = 'Something went wrong while fetching the campaign Detail'
      })
    }

    $scope.getCompany = function () {
      $scope.loading = true
      $company.getCompany().then(function (company) {
        $scope.loading = false
        $scope.company = company
        console.log('company: ', $scope.company)
        if ($scope.company && $scope.company.phone) $scope.companyphone = $scope.company.phone.mobilePhone || $scope.company.phone.landLine || ''
      }).catch(function (error) {
        $scope.loading = false
        $scope.showError = true
        $scope.errorMessage = 'Something went wrong while fetching company detail'
      })
    }
    $scope.getCompany()

    // First
    $scope.updateCampaignFirstStep = function () {
      console.log('campaign: ', $scope.campaign)
      var body = {
        brandId: $scope.campaign.brandId,
        startDate: $scope.campaign.startDate,
        endDate: $scope.campaign.endDate,
        name: $scope.campaign.name,
        currentStep: 1
      }
      $brandCampaign.updateCampaignById($scope.campaign._id, body).then(function (campaign) {
        console.log('updateCampaignById: ', campaign)
        $scope.showError = false
        $scope.loading = false
        $scope.activeTab = 1
      }).catch(function (error) {
        console.log('eror: ', error)
        $scope.loading = false
        $scope.showError = true
        $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
      })
    }

    // Second Step - Content for consumer
    $scope.contentForConsumer = function () {
      console.log('Scope.media: ', $scope.media, $scope.campaign.tasks[0].media)
      if ($scope.media && $scope.media.length) {
        var isValidMedia = true
        var mediaLength = ($scope.media.length) + ($scope.campaign.tasks[0].media.length)
        console.log('mediaLength: ', mediaLength)
        if ($scope.activeSelectedCampaignType == 'carousel') {
          if (mediaLength < 2 || mediaLength > 8) {
            isValidMedia = false
            $scope.errorMessage = 'In carousel, You can upload image in between 2 and 8'
            $scope.showError = true
          }
        } else if ($scope.activeSelectedCampaignType == 'single-image') {
          if (mediaLength !== 1) {
            isValidMedia = false
            $scope.errorMessage = 'In single-image type, You can upload only one image'
            $scope.showError = true
          }
        } else {
          if (mediaLength !== 1) {
            isValidMedia = false
            $scope.errorMessage = 'In single-video type, You can upload only one video'
            $scope.showError = true
          }
        }

        if (isValidMedia) {
          $scope.loading = true
          $brandCampaign.contentForConsumerUploadImage($scope.media, $scope.campaign.name).then(function (media) {
            $scope.campaign.tasks[0].media = $scope.campaign.tasks[0].media.concat(media)
            console.log($scope.campaign.tasks[0].media)
            var body = {
              campaignStep: 'contentForConsumer',
              campaignId: $scope.campaign._id,
              tasks: $scope.campaign.tasks
            }
            console.log('body: ', body)
            $brandCampaign.contentForConsumer(body).then(function (response) {
              console.log('contentForConsumer: ', response)
              $scope.media = []
              $scope.showError = false
              $scope.loading = false
              // $scope.campaign = response.data.data;
              $scope.toTab2()
            }).catch(function (error) {
              $scope.loading = false
              $scope.showError = true
              $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
            })
          }).catch(function (error) {
            console.log('errpr: ', error)
            $scope.loading = false
            $scope.showError = true
            $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
          })
        }
      } else {
        var body = {
          campaignStep: 'contentForConsumer',
          campaignId: $scope.campaign._id,
          tasks: $scope.campaign.tasks
        }
        console.log('body: ', body)
        $brandCampaign.contentForConsumer(body).then(function (response) {
          console.log('contentForConsumer: ', response)
          $scope.showError = false
          $scope.loading = false
          // $scope.campaign = response.data.data;
          $scope.toTab2()
        }).catch(function (error) {
          $scope.loading = false
          $scope.showError = true
          $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
        })
      }
    }

    // Third Step - Update News
    $scope.updateNewsByLandingPage = function () {
      console.log('updateNewsByLandingPage: ', $scope.news)
      if ($scope.landingpageimages && $scope.landingpageimages.length) {
        $scope.loading = true
        console.log('$scope.landingpageimages: ', $scope.landingpageimages)

        $brandCampaign.LandingPageUploadImage($scope.landingpageimages, $scope.news.name).then(function (images) {
          images.forEach(function (image, index) {
            console.log(index, image)
            if (image) {
              $scope.news.images[index] = image
            }
          })

          var body = {
            _id: $scope.news._id,
            name: $scope.news.name,
            content: $scope.news.content,
            // summary: $scope.campaign.tasks[0].media[0].description,
            summary: ($scope.campaign.tasks[0].media[0] && $scope.campaign.tasks[0].media[0].description ? $scope.campaign.tasks[0].media[0].description : $scope.campaign.name),
            language: $rootScope.language,
            images: $scope.news.images,
            shopButtonText: ($scope.news.shopButtonText ? $scope.news.shopButtonText : 'view at jewelry store nearby'),
            brandButtonText: ($scope.news.brandButtonText ? $scope.news.brandButtonText : 'visit the brand website'),
            socialMediaPixels: {
              facebook: $scope.news.socialMediaPixels && $scope.news.socialMediaPixels.facebook ? $scope.news.socialMediaPixels.facebook : undefined,
              google: $scope.news.socialMediaPixels && $scope.news.socialMediaPixels.google ? $scope.news.socialMediaPixels.google : undefined
            }
          }
          console.log('news: ', body)
          $brandCampaign.updateNewsById(body).then(function (response) {
            $scope.loading = false
            $scope.showError = false
            $scope.toTab3()
          }).catch(function (error) {
            console.log('error: ', error)
            $scope.loading = false
            $scope.showError = true
            $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
          })
        }).catch(function (error) {
          console.log('error: ', error)
          $scope.loading = false
          $scope.showError = true
          $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
        })
      } else {
        var body = {
          _id: $scope.news._id,
          name: $scope.news.name,
          content: $scope.news.content,
          shopButtonText: ($scope.news.shopButtonText ? $scope.news.shopButtonText : 'view at jewelry store nearby'),
          brandButtonText: ($scope.news.brandButtonText ? $scope.news.brandButtonText : 'visit the brand website'),
          socialMediaPixels: {
            facebook: $scope.news.socialMediaPixels && $scope.news.socialMediaPixels.facebook ? $scope.news.socialMediaPixels.facebook : undefined,
            google: $scope.news.socialMediaPixels && $scope.news.socialMediaPixels.google ? $scope.news.socialMediaPixels.google : undefined
          }
        }
        $scope.loading = true
        $brandCampaign.updateNewsById(body).then(function (response) {
          $scope.loading = false
          $scope.showError = false
          $scope.toTab3()
        }).catch(function (error) {
          console.log('error: ', error)
          $scope.loading = false
          $scope.showError = true
          $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
        })
      }
    }

    // Fourth Step - Updating Target Group Settings
    $scope.createTargetGroupSettings = function () {
      $scope.loading = true
      var body = {
        campaignId: $scope.campaign._id,
        taskId: $scope.campaign.tasks[0]._id,
        tasks: $scope.campaign.tasks
      }
      $brandCampaign.addTargetGroupSettings(body).then(function (response) {
        console.log('addTargetGroupSettings: ', response)
        $scope.loading = false
        $scope.showError = false
        // $scope.campaign = response.data.data;
        $scope.toTab4()
      }).catch(function (error) {
        console.log('errorrrr : ', error)
        $scope.loading = false
        $scope.showError = true
        $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
      })
    }

    // Fifth Step - Save Retaile Email Address
    $scope.saveRetailerEmail = function () {
      if ($scope.campaign._id && $scope.campaign.emailReceivers.added) {
        console.log('typeof $scope.campaign.emailReceivers.added: ', typeof $scope.campaign.emailReceivers.added)
        if (typeof $scope.campaign.emailReceivers.added !== 'string') {
          $scope.campaign.emailReceivers.added = ($scope.campaign.emailReceivers.added).toString()
        }
        var body = {
          _id: $scope.campaign._id,
          emailReceivers: $scope.campaign.emailReceivers.added
        }
        console.log('$scope.campaong: ', $scope.campaign)
        console.log('body: ', body)
        $scope.loading = true
        $brandCampaign.saveRetailerEmail(body).then(function (response) {
          $scope.loading = false
          $scope.showError = false
          $scope.toTab5()
        }).catch(function (error) {
          console.log('errorrrr 123 : ', error)
          $scope.loading = false
          $scope.showError = true
          $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
        })
      } else {
        $scope.showError = true
        $scope.errorMessage = 'Invalid Data'
      }
    }

    // Sixth Step - Showing summary
    $scope.showSummary = function () {
      // $scope.toTab6();
      if ($scope.campaign && $scope.campaign.tasks.length && $scope.campaign.tasks[0] &&
                $scope.campaign.tasks[0].firstInvitationEmail && $scope.campaign.tasks[0].firstInvitationEmail.date &&
                $scope.campaign.tasks[0].secondInvitationEmail && $scope.campaign.tasks[0].secondInvitationEmail.date) {
        $scope.loading = true
        var body = {
          campaignId: $scope.campaign._id,
          taskId: $scope.campaign.tasks[0]._id,
          firstInvitationEmaildate: $scope.campaign.tasks[0].firstInvitationEmail.date,
          secondInvitationEmaildate: $scope.campaign.tasks[0].secondInvitationEmail.date
        }
        $brandCampaign.saveInvitationEmailDate(body).then(function (response) {
          $scope.loading = false
          $scope.showError = false
          $scope.toTab6()
          // console.log('response: ', response);
          // $scope.campaign = response.data.data;
        }).catch(function (error) {
          console.log('errorrrr 123 : ', error)
          $scope.loading = false
          $scope.showError = true
          $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
        })
      } else {
        $scope.showError = true
        $scope.errorMessage = 'Prefill the date properly'
      }
    }

    // Seventh Step - Send Mail to Admin
    $scope.sentEmailToAdmin = function () {
      // $scope.campaign._id = '5c993befd6f0840634c4ac64'; // Testing purpose
      if ($scope.campaign._id) {
        var body = {
          campaignId: $scope.campaign._id,
          campaignStep: 'sentEmailToAdmin',
          isEmailSentToAdmin: true,
          currentStep: 6,
          language: $rootScope.language
        }
        $brandCampaign.sentEmailToAdmin(body).then(function (response) {
          $scope.loading = false
          $scope.isSentToAdmin = true
          setTimeout(function () {
            $state.go('brand.campaigns')
          }, 3000)
        }).catch(function (error) {
          console.log('errorrrr 123 : ', error)
          $scope.loading = false
          $scope.showError = true
          $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
        })
      } else {
        $scope.showError = true
        $scope.errorMessage = 'Invalid Data'
      }
    }

    $scope.toTab0 = function () {
      $scope.campaign.currentStep = 0
      $scope.activeTab = 0
      $scope.showError = false
    }

    $scope.toTab1 = function () {
      $scope.campaign.currentStep = 1
      $scope.activeTab = 1
      $scope.showError = false
    }

    $scope.toTab2 = function () {
      $scope.campaign.currentStep = 2
      $scope.activeTab = 2
      $scope.showError = false
    }

    $scope.toTab3 = function () {
      $scope.campaign.currentStep = 3
      $scope.activeTab = 3
      $scope.showError = false
    }

    $scope.toTab4 = function () {
      $scope.campaign.currentStep = 4
      $scope.activeTab = 4
      $scope.showError = false
    }

    $scope.toTab5 = function () {
      $scope.campaign.currentStep = 5
      $scope.activeTab = 5
      $scope.showError = false
    }

    $scope.toTab6 = function () {
      $scope.campaign.currentStep = 6
      $scope.activeTab = 6
      $scope.showError = false
    }

    // Changing the class of the campaigntype
    $scope.changeActiveSelectedCampaignType = function (activeSelectedCampaignType) {
      // if (activeSelectedCampaignType == 'single-image' || activeSelectedCampaignType == 'single-video') $scope.maximumMediaLength = 1;
      // if (activeSelectedCampaignType == 'carousel') $scope.maximumMediaLength = 8;
      $scope.activeSelectedCampaignType = activeSelectedCampaignType
      $scope.campaign.tasks[0].fbPromotionSettings.campaignType = activeSelectedCampaignType
      $scope.addMedia(true) // Have to change the image and all the stuff
    }

    $scope.addMedia = function (isChangeCampaignType) {
      if (!isChangeCampaignType) $scope.media.push({})
      $scope.isInActiveAddMediaButton = false
      $scope.carouselMediaButton = false
      if ($scope.activeSelectedCampaignType == 'carousel') {
        if ($scope.media.length >= 8) $scope.isInActiveAddMediaButton = true
        // console.log($scope.media.length);
        // if ($scope.media.length > 1 || $scope.media.length < 9) {
        //     console.log('insise');
        //     $scope.isInActiveAddMediaButton = true;
        // }
        // if ($scope.media.length >= 8) $scope.carouselMediaButton = true;
      } else if ($scope.activeSelectedCampaignType == 'single-image') {
        $scope.media.splice(1)
        if ($scope.media.length) $scope.isInActiveAddMediaButton = true
      } else if ($scope.activeSelectedCampaignType == 'single-video') {
        $scope.media.splice(1)
        if ($scope.media.length) $scope.isInActiveAddMediaButton = true
      }
      console.log('flag: ', $scope.isInActiveAddMediaButton)
    }

    $scope.uploadFiles = function (file, index) {
      $scope.landingpageimages[index] = file[0]
    }

    $scope.removeMedia = function (mode, index) {
      // For remove the image form Images
      $scope.isInActiveAddMediaButton = false
      $scope.carouselMediaButton = false
      if (mode == 'scope') $scope.media.splice(index, 1)
      else if (mode == 'media') $scope.campaign.tasks[0].media.splice(index, 1)
    }

    $scope.getWholesalerBrands = function () {
      getItems('brands')
    }

    $scope.searchBrands = function (str) {
      return $brandCampaign.searchItems(str, $scope.brands)
    }

    $scope.setSexFilter = function (filterSex) {
      $scope.filterSex = filterSex
      $scope.campaign.tasks[0].fbPromotionSettings.sex = filterSex
    }

    $scope.brandSelected = function (selected) {
      $scope.brand = {
        _id: selected._id || selected.originalObject._id,
        name: selected.name || selected.title,
        nameSlug: selected.nameSlug || selected.originalObject.nameSlug
      }
      $scope.campaign.brandDetail = $scope.brand
      $scope.campaign.brandId = selected._id || selected.originalObject._id
    }

    // It will fire an api and get the data
    function getItems (type) {
      $scope.loading = true
      $brandCampaign.getItems(type).then(function (response) {
        $scope.loading = false
        $scope[type] = response
      }).catch(function (error) {
        $scope.loading = false
        $scope.showError = true
        $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
      })
    };

    $scope.startDateOptions = function () {
      $scope.startDateOptions = {
        minDate: $scope.todayPlusTenDays,
        maxDate: $scope.campaign.endDate,
        dateDisabled: disabled
      }
    }

    // Function is for the weekdays can be selected for the campaign
    function disabled (data) {
      var date = data.date
      var mode = data.mode
      return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6)
    }

    $scope.checkDefaultPromotionSettings = function () {
      var facebook = $scope.campaign.tasks[0].fbPromotionSettings.facebook
      var instagram = $scope.campaign.tasks[0].fbPromotionSettings.instagram
      var audienceNetwork = $scope.campaign.tasks[0].fbPromotionSettings.audienceNetwork
      var messenger = $scope.campaign.tasks[0].fbPromotionSettings.messenger
      $scope.facebook = false
      $scope.instagram = false
      $scope.audienceNetwork = false
      $scope.messenger = false
      console.log('facebookfeeds: ', !facebook.feeds)
      if (!facebook.feeds || !facebook.instantArticle || !facebook.rightColumn || !facebook.suggestedVideos || !facebook.marketPlace || !facebook.inStreamVideos) {
        $('#facebook').prop('checked', false)
      } else {
        $('#facebook').prop('checked', true)
      }
      if (!instagram.feeds) {
        $('#instagram').prop('checked', false)
      } else {
        $('#instagram').prop('checked', true)
      }
      if (!audienceNetwork.nativeBannerInterstitial || !audienceNetwork.inStreamVideos) {
        $('#audienceNetwork').prop('checked', false)
      } else {
        $('#audienceNetwork').prop('checked', true)
      }
      if (!messenger.inbox) {
        $('#messenger').prop('checked', false)
      } else {
        $('#messenger').prop('checked', true)
      }
    }

    $scope.facebookCheckBox = function (isChecked) {
      var facebook = $scope.campaign.tasks[0].fbPromotionSettings.facebook
      if (isChecked) facebook.feeds = facebook.instantArticle = facebook.rightColumn = facebook.suggestedVideos = facebook.marketPlace = facebook.inStreamVideos = true
      else facebook.feeds = facebook.instantArticle = facebook.rightColumn = facebook.suggestedVideos = facebook.marketPlace = facebook.inStreamVideos = false
    }

    $scope.instagramCheckBox = function (isChecked) {
      var instagram = $scope.campaign.tasks[0].fbPromotionSettings.instagram
      if (isChecked) instagram.feeds = true
      else instagram.feeds = false
    }

    $scope.audienceNetworkCheckBox = function (isChecked) {
      var audienceNetwork = $scope.campaign.tasks[0].fbPromotionSettings.audienceNetwork
      if (isChecked) audienceNetwork.nativeBannerInterstitial = audienceNetwork.inStreamVideos = true
      else audienceNetwork.nativeBannerInterstitial = audienceNetwork.inStreamVideos = false
    }

    $scope.messengerCheckBox = function (isChecked) {
      var messenger = $scope.campaign.tasks[0].fbPromotionSettings.messenger
      if (isChecked) messenger.inbox = true
      else messenger.inbox = false
    }
  }])
