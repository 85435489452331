prismanoteApp.controller('adminHomeController', ['$scope', '$rootScope', '$retailer', '$api', '$translate', '$filter',
  function ($scope, $rootScope, $retailer, $api, $translate, $filter) {
    $scope.isLoggedIn = false

    getAdminDashBoardCounts()

    function getAdminDashBoardCounts () {
      $api.get('admin-dashboard-count/')
        .then(function (response) {
          $scope.counts = response.data
          console.log('Counts Of the All Recoards', $scope.counts)
        })
        .catch(function (reason) {
          console.log(reason)
        })
    }

  }])

// prismanoteApp.factory('facebookService', ['$http', function ($http) {
//   return {
//     getAdminAccessToken: function (obj) {
//       console.log('data for post', obj)
//       return $http({
//         headers: { 'Content-Type': 'application/json' },
//         url: '/api/get-admin-fbToken',
//         method: 'POST',
//         data: obj
//       })
//         .then(function (res) {
//           console.log('res from long live token', res)
//           return res.data
//         })
//     }
//   }
// }])
