prismanoteApp.controller('generateGiftcardModalController', ['$scope', '$uibModalInstance', 'transaction', '$rootScope', '$transaction', '$language', 'taxes', 'printGiftcard', '$pdf', '$printer', 'amount',
  function ($scope, $uibModalInstance, transaction, $rootScope, $transaction, $language, taxes, printGiftcard, $pdf, $printer, amount) {
    if (transaction) {
      $scope.transaction = transaction
      $scope.amount = $scope.transaction.totalAmount * -1
    }
    if(amount) {
      $scope.amount = amount * -1
    }
    if (
      $rootScope.currentShop.nameSlug !== 'hip-amersfoort-bv' &&
      $rootScope.currentShop.nameSlug !== 'hip-jewels-and-watches-uden' &&
      $rootScope.currentShop.nameSlug !== 'hans-luijer' &&
      $rootScope.currentShop.nameSlug !== 'juwelier-bos' &&
      $rootScope.currentShop.nameSlug !== 'zuyver-s-dronten' &&
      $rootScope.currentShop.nameSlug !== 'zuyver' &&
      $rootScope.currentShop.nameSlug !== 'zuyver-s-deventer'
    ) {
      $scope.number = Date.now()
    }

    $scope.data = {
      taxRate: 0
    }

    if($rootScope.currentShop.financial && typeof $rootScope.currentShop.financial.taxGiftcard === 'boolean') {
      $scope.data.vatPaid = $rootScope.currentShop.financial.taxGiftcard
    }

    $scope.findRate = function (rate) {
      var index = _.findIndex($scope.taxes, { rate: rate })
      if (index >= 0) {
        return index
      } else {
        return 0
      }
    }

    if (taxes) {
      $scope.taxes = taxes
      $scope.data.taxRate = $scope.taxes[$scope.findRate(21)].rate
    }

    $scope.makeValidGiftcardNumber = function (number) {
      $scope.number = number.replace(' ', '').toUpperCase()
      if(number.charAt(0).toUpperCase() === 'G' && number.charAt(1) === '-') {
        //Only replace G- if they occurs at first chars
        $scope.number = number.replace('G-', '').toUpperCase()
      }
    }

    $scope.generate = function () {
      $scope.alert = null

      if($scope.amount < 0) {
        $scope.alert = {
          type: 'danger',
          msg: $language.translate('PLEASE_ENTER_A_POSITIVE_VALUE')
        }
        return
      }

      if($scope.number === undefined || $scope.number === '' || $scope.number === '0') {
        $scope.alert = {
          type: 'danger',
          msg: $language.translate('PLEASE_ENTER_A_GIFTCARD_NUMBER')
        }
        $scope.number = Date.now() //new timestamp when number is undefined
        return
      }

      if($scope.data.vatPaid === undefined) {
        $scope.alert = {
          type: 'danger',
          msg: $language.translate('PLEASE_CHOOSE_HOW_TO_HANDLE_VAT')
        }
        return
      }

      var transaction = {
        shopId: $rootScope.currentShop._id,
        language: $rootScope.language,
        details: [{
          giftcardNumber: $scope.number,
          quantity: 1,
          priceVat: $scope.data.taxRate,
          price: $scope.amount,
          purchasePrice: 0,
          productId: null,
          total: $scope.amount
        }],
        parentTransactionId: $scope.transaction._id,
        customer: $scope.transaction.customer && $scope.transaction.customer.counter === false ? $scope.transaction.customer : null,
        vatPaid: $scope.data.vatPaid
      }

      $transaction.createGiftCardTransaction(transaction)
        .then(function (transactionResult) {
          if (printGiftcard && $rootScope.currentShop.cashRegister.printGiftcard) {
            $printer.getPageDefinition('GIFTCARD')
              .then(function(doc) {
                var skipPrint = true
                if(doc.autoPrint) {
                  skipPrint = false
                }
                $pdf.downloadPdf('generate-giftcard', {
                  shopId: $rootScope.currentShop._id,
                  transactionId: transactionResult.data.result.transaction._id,
                  documentName: 'GIFTCARD',
                  deviceId: $rootScope.currentDevice._id,
                  documentId: doc.documentId
                }, $language.translate('GIFTCARD') + ' ' + $scope.number + '.pdf', null, skipPrint)
                  .then(function () {
                    $uibModalInstance.close({ amount: $scope.amount, number: $scope.number, giftCardId: transactionResult.data.result.transaction._id, taxRate: $scope.data.taxRate })
                  })
                  .catch(function (reason) {
                    console.error('Error while generating giftcard', reason)
                  })
              })
              .catch(function (reason) {
                console.error('Error while fetching giftcard page definition for printing', reason)
              })


          } else {
            $uibModalInstance.close({ amount: $scope.amount, number: $scope.number, giftCardId: transactionResult.data.result.transaction._id, taxRate: $scope.data.taxRate })
          }
        })
        .catch(function (reason) {
          console.error('Error while creating giftcard', reason)
          $scope.alert = {
            type: 'danger',
            msg: reason.data.message
          }
        })
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss()
    }
  }])
