prismanoteApp.controller('adminCampaignController', ['$scope', '$rootScope', '$api', '$state', '$stateParams', '$campaign',
  function ($scope, $rootScope, $api, $state, $stateParams, $campaign) {
    // $scope.tab = $stateParams.tab;

    $scope.getCampaign = function () {
      $api.get('newcampaigns/' + $stateParams._id)
        .then(function (response) {
          console.log('response: ', response)
          $scope.campaign = response.data.data
          if ($scope.campaign.tasks.length) {
            $scope.activeTask = $scope.campaign.tasks[0].name
            $scope.campaign.tasks[0].firstInvitationEmail = {
              date: ($scope.campaign.tasks[0].firstInvitationEmail && $scope.campaign.tasks[0].firstInvitationEmail.date) ? new Date($scope.campaign.tasks[0].firstInvitationEmail.date) : new Date(),
              isEmailSent: !!(($scope.campaign.tasks[0].firstInvitationEmail && $scope.campaign.tasks[0].firstInvitationEmail.isEmailSent)),
              isEnabled: !(($scope.campaign.tasks[0].firstInvitationEmail && !$scope.campaign.tasks[0].firstInvitationEmail.isEnabled))
            }
            $scope.campaign.tasks[0].secondInvitationEmail = {
              date: ($scope.campaign.tasks[0].secondInvitationEmail && $scope.campaign.tasks[0].secondInvitationEmail.date) ? new Date($scope.campaign.tasks[0].secondInvitationEmail.date) : new Date(),
              isEmailSent: !!(($scope.campaign.tasks[0].secondInvitationEmail && $scope.campaign.tasks[0].secondInvitationEmail.isEmailSent)),
              isEnabled: !(($scope.campaign.tasks[0].secondInvitationEmail && !$scope.campaign.tasks[0].secondInvitationEmail.isEnabled))
            }
          }
          $scope.taskLanguage = $rootScope.language
        })
        .catch(function (reason) {
          console.log(reason)
        })
    }

    // Old Deprecated
    // $scope.getCampaign = function() {
    // 	$api.get('campaigns/' + $stateParams.nameSlug)

    // 		.then(function(response) {
    // 			$scope.campaign = response.data.campaign;
    // 			if($scope.campaign.tasks.length > 0) {
    // 				$scope.activeTask = $scope.campaign.tasks[0].name;
    // 			}
    // 			$scope.taskLanguage = $rootScope.language;
    // 		})

    // 		.catch(function(reason) {
    // 			console.log(reason);
    // 		});
    // };

    var getItems = function (type) {
      $api.get(type)

        .then(function (response) {
          $scope[type] = response.data[type]
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.approveCampaign = function () {
      console.log('approveCampaign')
      // For testing purpose,  I did this code commented and calling different function
      /* $api.put('new-approve-campaign/' + $stateParams._id)
        .then(function (response) {
          $scope.campaign = response.data.campaign
        })
        .catch(function (reason) {
          console.log(reason)
        }) */

        $scope.sendTestingEmail();
    }

    $scope.sendTestingEmail = function() {
          var body = {
            campaignId: $scope.campaign._id,
            email: 'naimishkher95@gmail.com'
            /* shop: {
              _id: shop.originalObject._id,
              name: shop.originalObject.name,
              phone: shop.originalObject.phone
            } */
          }
          $scope.loading = true
          $campaign.sendInvitationEmailManually(body).then(function (response) {
            console.log('response: ', response)
            $scope.loading = false
          }).catch(function (error) {
            console.log('error: ', error)
            $scope.loading = false
          })
    }

    $scope.getBrands = function () {
      return getItems('brands')
    }

    $scope.getCompanies = function () {
      return getItems('companies')
    }

    var searchItems = function (str, type) {
      var matches = []

      $scope[type].forEach(function (item) {
        if (
          (item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
					(item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
          matches.push(item)
        }
      })

      return matches
    }

    $scope.searchBrands = function (str) {
      return searchItems(str, 'brands')
    }

    $scope.searchCompanies = function (str) {
      return searchItems(str, 'companies')
    }

    $scope.brandSelected = function (selected) {
      var brand = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug,
        description: selected.originalObject.description,
        images: selected.originalObject.images
      }

      $scope.campaign.brand = brand
    }

    $scope.companySelected = function (selected) {
      var company = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug,
        description: selected.originalObject.description,
        images: selected.originalObject.images
      }

      $scope.campaign.company = company
    }

    $scope.updateCampaign = function (redirect) {
      console.log('updateCampaign: ', $scope.campaign)
      $api.put('newcampaigns/' + $stateParams._id, { campaign: $scope.campaign })
        .then(function () {
          if (redirect === true) {
            $state.go('admin.campaigns')
          }
        })
        .catch(function (reason) {
          console.log(reason)
        })
    }

    //  Old Deprecated
    // $scope.updateCampaign = function (redirect) {
    // 	$api.put('campaigns/' + $stateParams.nameSlug, { campaign: $scope.campaign })

    // 		.then(function () {
    // 			if (redirect === true) {
    // 				$state.go('admin.campaigns');
    // 			}
    // 		})

    // 		.catch(function (reason) {
    // 			console.log(reason);
    // 		});
    // };
  }])
