prismanoteApp.controller('brandmapcontroller', ['$scope', '$rootScope', '$brand',
  function ($scope, $rootScope, $brand) {
    $scope.loadMap = function () {
      $(document).ready(function () {
        // execute
        (function () {
          // map options
          var options = {
            // zoom: 4,
            // center: new google.maps.LatLng(39.909736, -98.522109), // centered US
            // mapTypeId: google.maps.MapTypeId.TERRAIN,
            // mapTypeControl: false
            center: new google.maps.LatLng(52.090737, 5.121420),
            zoom: 10,
            streetViewControlOptions: {
              enableCloseButton: false
            },
            mapTypeControl: false,
            styles: [
              {
                featureType: 'poi',
                elementType: 'labels',
                stylers: [
                  { visibility: 'off' }
                ]
              }
            ]
          }

          // init map
          var map = new google.maps.Map(document.getElementById('map_canvas'), options)
          var body = {
            companyId: $rootScope.currentCompany._id
          }
          // console.log('MAP RESPONSE Body: ', body);
          $brand.getShopMapDetail(body).then(function (response) {
            console.log('MAP RESPONSE: ', response)
            // console.table(response.data.data);
            var shops = response.data.data
            var shoplength = shops.length

            // set multiple marker
            for (var i = 0; i < shoplength; i++) {
              // init markers
              var marker = new google.maps.Marker({
                position: new google.maps.LatLng(shops[i].geoloc.coordinates[0], shops[i].geoloc.coordinates[1]),
                map: map,
                title: shops[i].name
              });

              // process multiple info windows
              (function (marker, i) {
                // add click event
                google.maps.event.addListener(marker, 'click', function () {
                  infowindow = new google.maps.InfoWindow({
                    content: shops[i].name + '<br>' + getAddressMessage(shops[i].address) + '<br> <b>' + getStockRevenueMessage(shops[i].stockandrevenuedetail) + '<br> <b>' + shopUserAvailable(shops[i].employeedetails)
                  })
                  infowindow.open(map, marker)
                })
              })(marker, i)
            }
          }).catch(function (maperr) {
            console.log('MAP ERROR: ', maperr)
          })
        })()
      })
    }

    function getStockRevenueMessage (stockandrevenuedetail) {
      var message = '-'
      if (stockandrevenuedetail && stockandrevenuedetail.stockvalue && stockandrevenuedetail.revenue) {
        var stockvalue = stockandrevenuedetail.stockvalue
        var revenue = stockandrevenuedetail.revenue
        if (stockvalue.sign === 'uparrow' && revenue.sign === 'uparrow') {
          message = 'Going well'
        } else if (stockvalue.sign === 'uparrow' && revenue.sign === 'downarrow') {
          message = 'Marketing required?'
        } else if (stockvalue.sign === 'downarrow' && revenue.sign === 'uparrow') {
          message = 'Refiling required!'
        } else if (stockvalue.sign === 'downarrow' && revenue.sign === 'downarrow') {
          message = 'Probably marketing required?'
        }
      }
      return message
    }

    function getAddressMessage (address) {
      // console.log('getAddressMessage: ', address);
      var message = '-'
      if (address) {
        message = (address.street || '') + ' ' + (address.houseNumber || '') + ' ' + (address.houseNumberSuffix || '') + '<br>' + (address.city || '') + ' ' + (address.postalCode || '') + '<br>' + (address.country || '')
      }
      return message
    }

    function shopUserAvailable (employees) {
      // console.log('shopUserAvailable called: ', shopSlug, employees);
      var message = '-'
      if (employees && employees.length) {
        var employeeLength = employees.length
        var days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
        var today = days[new Date().getDay()]
        // console.log('employees: ', today, shopSlug, employees);
        for (var i = 0; i < employeeLength; i++) {
          if (employees[i] && employees[i].availability && employees[i].availability.length) {
            var availability = employees[i].availability
            for (var j = 0; j < availability.length; j++) {
              if (availability[j].day === today && (availability[j].morning || availability[j].afternoon)) {
                if (availability[j].morning && availability[j].afternoon) {
                  message = employees[i].fullName + ' is available today.'
                } else if (availability[j].morning) {
                  message = employees[i].fullName + ' is available in morning.'
                } else if (availability[j].afternoon) {
                  message = employees[i].fullName + ' is available in afternoon.'
                }
              }
            }
          }
        }
      }

      return message
    }
  }])
