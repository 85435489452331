prismanoteApp.controller('retailerProductStockChangeModalController', ['$scope', '$rootScope', '$uibModalInstance', 'data', '$shop',
  function ($scope, $rootScope, $uibModalInstance, data, $shop) {
    console.log('data: ', data);
    var old_stock = data.oldStock;
    $scope.shopId = data.shopId
    $scope.productId = data.productId
    $scope.status = data.status

    if (data.oldStock != null || data.oldStock != undefined) $scope.oldStock = $scope.newStock = data.oldStock

    $scope.close = function (shouldModalOpen) {
      $uibModalInstance.close(shouldModalOpen)
    }

    $scope.getProductStockChanges = function (shopId, productId) {
      // Get Product Stock Changes Records
      $shop.getShopProductStockChanges(shopId, productId)
        .then(function (stocks) {
          // Product Stock Changes Records assign to productStockChanges
          $scope.productStockChanges = stocks

          if ($scope.status == 'edit' && stocks && stocks.length) {
                	$scope.newStock = stocks[0].currentStock
                	$scope.oldStock = stocks[0].currentStock
          }
        })
        .catch(function (error) {
            	console.log('Error =>> getProductStockChanges', error)
        })
    }

    $scope.saveStock = function (shopId, productId, oldStock, newStock, reason) {
      var data = { shopId: shopId, productId: productId, oldStock: old_stock, newStock: newStock, reason: reason };
      console.log(' ------- Save Stock -----------', data);
      // return;
      if (!reason) reason = "Manual Stock Correction";
      $shop.updateShopProductStock(shopId, productId, old_stock, newStock, reason)
        .then(function (success) {
          console.log('Success')
          $rootScope.$broadcast('myEvent', data);
          // $scope.close(newStock)
        })
        .catch(function (error) {
          console.log('Error ', error)
          // $scope.close(null)
        })
    }
  }])
