prismanoteApp.controller('retailerSupplierController', ['$scope', '$rootScope', '$api', '$uibModal', 'prompt', '$state', '$language', '$q',
  function ($scope, $rootScope, $api, $uibModal, prompt, $state, $language, $q) {
    $scope.updateMode = false
    $scope.currentShop = $rootScope.currentShop
    $scope.company = {}
    // $scope.company.type = 'company'//by default
    $scope.company.private = false
    $scope.urlPattern = /^(https?:\/\/)?(www\.)?[a-z0-9-]*\.?[a-z0-9-]+\.[a-z0-9-]+(\/[^<>]*)?$/
    $scope.namepattern = /^[a-zA-Z0-9. ]+$/
    $scope.company.brands = []
    if ($rootScope.companyNameFromAddCompModal) {
      $scope.company.name = $rootScope.companyNameFromAddCompModal
      delete $rootScope.companyNameFromAddCompModal
    }

    $scope.sendDeleteSuggestion = function () {
      // console.log("Delete");
    }

    $scope.deleteBrand = function (index) {
      prompt({
        title: $language.translate('DELETE_BRAND'),
        message: $language.translate('ARE_YOU_SURE_WANT_TO_REMOVE_BRAND_FROM_SHOP'),
      }).then(function () {
        $scope.company.brands.splice(index, 1)
      })
    }

    $scope.getBrands = function () {
      // $api.get('brands')
      $api.get('brands?getAll=true')
        .then(function (response) {
          $scope.brands = response.data.brands
        })

        .catch(function (reason) {
          console.error('Error while getting brands', reason)
        })
    }

    $scope.searchBrands = function (str) {
      var matches = []
      $scope.brands.forEach(function (brand) {
        if ((brand.en.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
					(brand.en.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
          matches.push(brand)
        }
      })
      return matches
    }

    $scope.addBrands = function (data) {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/add-brands-modal.html',
        controller: 'addBrandsModalController',
        size: 'lg',
        resolve: {
          data: function () {
            return data
          }
        }
      })

      modalInstance.result.then(function (result) {

      }, function (my_return_string) {
        console.log('my string', my_return_string)
        // dismissed
      })
    }

    $scope.brandSelected = function (selected) {
      var brand = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug,
        description: selected.originalObject.description,
        images: selected.originalObject.images,
        restricted: selected.originalObject.restricted
      }

      var existing = false

      angular.forEach($scope.company.brands, function (val) {
        if (val._id == brand._id) {
          existing = true
        }
      })
      if (existing == true) {
        return
      }
      if ($scope.company.brands === undefined) {
        $scope.company.brands = []
        $scope.company.brands.push(brand)
      } else {
        $scope.company.brands.push(brand)
      }
    }

    $scope.closeAlert = function () {
      $scope.alert = null
    }

    $scope.addCompany = function (company) {
      var alertMessage = ''
      if (company.name && company.email && company.website && company.type) {
        $scope.alert = null
      } else {
        if (company.name == undefined || company.name == '') {
          alertMessage = alertMessage + ' ' + 'Please enter company name.'
        }
        if (company.email == undefined || company.email == '') {
          alertMessage = alertMessage + ' ' + 'Please enter company email.'
        }
        if (company.website == undefined || company.website == '') {
          alertMessage = alertMessage + ' ' + 'Please enter company website'
        }
        if (company.type == undefined || company.type == '') {
          alertMessage = alertMessage + ' ' + 'Please enter company type'
        }
        $scope.alert = {
          msg: alertMessage
        }
        // do nothing but notify client to add these required fields
        return
      }

      if (company && company.website) {
        var link = company.website
        if (link.substring(0, 7) !== 'http://' && link.substring(0, 8) !== 'https://') {
          company.website = 'http://' + link
        }
      }

      if (company && company.address) {
        if(company.address.houseNumberSuffix == "") delete company.address.houseNumberSuffix
        if(company.address.houseNumber == "") delete company.address.houseNumber
        if(company.address.postalCode == "") delete company.address.postalCode
        if(company.address.street == "") delete company.address.street
        if(company.address.city == "") delete company.address.city
      }
      if (company && company.phone) {
        if(company.phone.fax == "") delete company.phone.fax
        if(company.phone.mobilePhone == "") delete company.phone.mobilePhone
        if(company.phone.landLine == "") delete company.phone.landLine
      }
      if (company && company.repairPhone) {
        if(company.repairPhone.fax == "") delete company.repairPhone.fax
        if(company.repairPhone.mobilePhone == "") delete company.repairPhone.mobilePhone
        if(company.repairPhone.landLine == "") delete company.repairPhone.landLine
      }

      company.addedBy = {
        shopId: $rootScope.currentShop._id,
        name: $rootScope.currentShop.name
      }

      $api.post('companies', { company: company, shopId: $rootScope.currentShop._id })
        .then(function (response) {
          console.log('company Added and  opent the prompt')
          // need to open the confirmation pop up for adding the company in the shop
          prompt({
            title: $language.translate('ADD_COMPANY_TO_SHOP_CONFIRMATION'),
            message: $language.translate('DO_YOU_WANT_TO_ADD_THIS_COMPANY_TO_YOUR_SHOP') + $rootScope.currentShop.name + '?',
            buttons: [
              { label: $language.translate('YES'), primary: true, id: 0 },
              { label: $language.translate('CANCEL'), primary: false, id: 1 }
            ]
          }).then(function (value) {
            console.log('YES OR NO VALUE: ', value)
            if (value.id === 0) {
              company = response.data.company
              console.log('company: ', company)
              if (response.data.company.private) {
                // call the api to add the company in shop.
                var body = {
                  shopId: $rootScope.currentShop._id,
                  companyId: company._id
                  // brands: company.brands PN-1968
                }

                $api.post('shop-companies', body)
                  .then(function (response) {
                    console.log('success  hello:  ', response)
                    $state.go('retailer.suppliers')
                  })

                  .catch(function (reason) {
                    console.error('Error while saving companies', reason)
                  })
              } else {
                // we need to open the pop up to select the brands as done earlier
                if (company.brands && company.brands.length > 0 && company.type == 'supplier') {
                  var modalInstance = $uibModal.open({
                    templateUrl: '../views/modal/retailer-add-company-brand-confirmation.html',
                    controller: 'retailerAddCompanyBrandConfirmationModalController',
                    size: 'lg',
                    resolve: {
                      data: function () {
                        return response.data.company
                      }
                    }
                  })
                  modalInstance.result
                    .then(function (response) {
                      console.log('-----------------> response: MODAL-------------', response)
                      resolve(response)
                    })
                    .catch(function (reason) {
                      reject(reason)
                    })
                } else {
                  var body = {
                    shopId: $rootScope.currentShop._id,
                    companyId: company._id
                    // brands: company.brands PN-1968
                  }

                  $api.post('shop-companies', body)
                    .then(function (response) {
                      console.log('success 123 hello:  ', response)
                      // $uibModalInstance.close('close'); // closing the modal
                      $state.go('retailer.suppliers')
                    })

                    .catch(function (reason) {
                      console.error('Error while saving companies', reason)
                    })
                }
              }
            } else if (value.id === 1) {
              console.log('You pressed cancel: ', response.data.company.nameSlug)
              // do nothing
              // just close the prompt dialog box
              // $state.go('retailer.supplier', ({nameSlug: response.data.company.nameSlug}))
              $api.post('delete-company-by-companyid', { companyId: response.data.company._id, shopId: $rootScope.currentShop._id })
                .then(function (response) {
                  console.log('Succesfully deleted company by companyId: ', response)
                })
                .catch(function (reason) {
                  console.error('Error while deleting company', reason)
                })
            }
          })
        })
        .catch(function (reason) {
          console.error('Error while saving companies', reason)
          if (reason.data.isAlreayExist && reason.data.findRes) {
            $scope.isAlreayExist = true
            $scope.existingCompany = reason.data.findRes
            $scope.alert = {
              type: 'danger',
              msg: reason.data.message
            }
          } else {
            $scope.alert = {
              type: 'danger',
              msg: reason.data.message
            }
          }
        })
    }

    $scope.getAddressInfo = function () {
      if (!$scope.company.address.houseNumber || !$scope.company.address.postalCode || $scope.company.address.postalCode.length < 6 || $scope.company.address.country != 'NL') {
        return
      }

      $api.post('get-address-info', { zipcode: $scope.company.address.postalCode, number: $scope.company.address.houseNumber })
        .then(function (result) {
          console.log('result', result)
          $scope.company.address.street = result.data.address.street
          $scope.company.address.city = result.data.address.city
        })
        .catch(function (reason) {
          console.error('Error while getting data from postalcode', reason)
        })
    }

    $scope.addCompanyToShop = function (company) {
      console.log('--> addCompanyToShop: called from the add company pug file: ', company)
      return $q(function (resolve, reject) {
        if (company.brands && company.brands.length > 0 && company.type == 'supplier') {
          console.log('-> if')
          var modalInstance = $uibModal.open({
            templateUrl: '../views/modal/retailer-add-company-brand-confirmation.html',
            controller: 'retailerAddCompanyBrandConfirmationModalController',
            size: 'lg',
            resolve: {
              data: function () {
                return company
              }
            }
          })

          modalInstance.result
            .then(function (response) {
              resolve(response)
            })

            .catch(function (reason) {
              reject(reason)
            })
        } else {
          console.log('-> else')
          var body = {
            shopId: $rootScope.currentShop._id,
            companyId: company._id
            // brands: company.brands
          }

          $api.post('shop-companies', body)
            .then(function (response) {
              $rootScope.$emit('updateCompanyList', 'done')
              $scope.isAlreayExist = false
              $scope.alert = {
                type: 'danger',
                msg: response.data.msg
              }
            })
            .catch(function (reason) {
              console.error(reason)
              $scope.isAlreayExist = false
              $scope.alert = {
                type: 'danger',
                msg: reason.data.msg
              }
            })
        }
      })
    }

  }])
