prismanoteApp.controller('homeResetPasswordController', ['$scope', '$rootScope', '$api', '$stateParams', '$language', '$state', '$user', 'prompt',
  function ($scope, $rootScope, $api, $stateParams, $language, $state, $user, prompt) {
    $scope.initialize = function () {
      $scope.requestPasswordDetails = {
        form: {}
      }

      $scope.resetPassword = {
        form: {}
      }
      $scope.formSubmitted = false
      $scope.step1 = true
      $scope.step2 = false
      $scope.count = 0

      console.log('------------------------body-------------> ', $stateParams);
      // if ($stateParams.code) {
      //   var body = {
      //     code: $stateParams.code
      //   }
      //   $user.changeTheResetToken(body).then(function (response) {
      //     if (response && response.data && response.data.data) {
      //       console.log('Token changed: ', response.data.data);
      //       $stateParams.code = response.data.data;
      //     } else {
      //       $state.go('layout.logout');
      //     }
      //   }).catch(function (reason) {
      //     $scope.alert = {
      //       type: 'danger',
      //       msg: $language.translate(reason.data.message)
      //     }
      //     $state.go('layout.logout');
      //   })
      // }
    }

    if ($stateParams.id && $stateParams.code) {
      var body = {
        userId: $stateParams.id,
        verificationCode: $stateParams.code
      }
      console.log('------------------------body-------------> ', $stateParams, body);
      $user.changeTheResetToken(body).then(function (response) {
        if (response && response.data && response.data.data) {
          console.log('Token changed: ', response.data.data);
          $stateParams.code = response.data.data;
        } else {
          $state.go('layout.logout');
        }
      }).catch(function (reason) {
        $scope.alert = {
          type: 'danger',
          msg: $language.translate(reason.data.message)
        }
        $state.go('layout.logout');
      })
    }

    $scope.redirectUser = function () {
      var role = $rootScope.user.role == 'wholesaler' ? 'brand' : $rootScope.user.role
      $state.go(role + '.home')
    }

    if ($rootScope.user) {
      $scope.redirectUser()
    } else {
      $scope.initialize()
    }

    $scope.togglePassword = function () {
      $scope.togglePassword = function () {
        var e1 = document.getElementById('pnPassword1')
        var e2 = document.getElementById('pnPassword2')
        if (!e1 || !e2) {
          return
        }
        if (e1.type === 'password') {
          e1.type = 'text'
          e2.type = 'text'
        } else {
          e1.type = 'password'
          e2.type = 'password'
        }
      }
    }

    $scope.request = function (force) {
      var response = grecaptcha.getResponse();
      if (!response || !response.length) {
        prompt({
          title: 'Warning',
          message: 'Please complete the captcha challenge'
        })
        return;
      }
      if (force) $scope.count++
      $scope.alert = null
      if (!force) {
        force = false
      }
      if ($scope.requestPasswordDetails.form.$invalid && force == false) {
        return
      }

      $api.post('send-password-reset-link', {
        email: $scope.requestPasswordDetails.email,
        language: $scope.language
      })
        .then(function (response) {
          $scope.step1 = false
          $scope.step2 = true
        })
        .catch(function (reason) {
          $scope.alert = {
            type: 'danger',
            msg: reason
          }
        })
    }

    $scope.editPassword = function () {
      console.log('editPassword called: ', $rootScope.user);
      $scope.reset();
    }

    $scope.reset = function () {
      $scope.alert = null
      $scope.formSubmitted = false
      if ($scope.resetPassword.form.$invalid) {
        return
      }

      if (!$scope.pwdNormal() || !$scope.pwdCapital() || !$scope.pwdSpecial() || !$scope.pwdNumber() || !$scope.pwdLength()) {
        $scope.alert = {
          type: 'danger',
          msg: $language.translate('PASSWORD_DOES_NOT_MEET_REQUIREMENTS')
        }
        return
      }
      if ($scope.resetPassword.password != $scope.resetPassword.password2) {
        $scope.alert = {
          type: 'danger',
          msg: $language.translate('MESSAGE_NOTMATCHINGPASSWORD_REGISTER_FORM')
        }
        return
      }

      var data = {
        password: $scope.resetPassword.password,
        confirmPassword: $scope.resetPassword.password2,
        // id: $stateParams.id,
        code: $stateParams.code,
        activate: true,
        force: $stateParams.force
      }

      $api.post('change-password', {
        data: data
      })
        .then(function (response) {
          $scope.formSubmitted = true
          $scope.alert = {
            type: 'success',
            msg: response.data.message
          }
          if ($stateParams.force) {
            $rootScope.user = response.data.user
            $scope.redirectUser()
          }
        })
        .catch(function (reason) {
          $scope.showAgainButton = reason.status == 405 || reason.status == 403
          $scope.formSubmitted = reason.status != 401
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(reason.data.message)
          }
        })
    }

    $scope.pwdNormal = function () {
      if (!$scope.resetPassword.password) return
      return $scope.resetPassword.password.match(/[a-z]/m)
    }

    $scope.pwdCapital = function () {
      if (!$scope.resetPassword.password) return
      return $scope.resetPassword.password.match(/[A-Z]/m)
    }
    $scope.pwdSpecial = function () {
      if (!$scope.resetPassword.password) return
      return $scope.resetPassword.password.match(/[^a-zA-Z0-9]/gm)
    }
    $scope.pwdNumber = function () {
      if (!$scope.resetPassword.password) return
      return $scope.resetPassword.password.match(/\d/gm)
    }
    $scope.pwdLength = function () {
      if (!$scope.resetPassword.password) return
      return $scope.resetPassword.password.length > 7
    }
  }])
