prismanoteApp.controller('brandAutoCreatedPurchaseOrderController', ['$scope', '$rootScope', '$uibModal', '$api', '$stateParams', '$state', '$q', '$translate', '$retailer', '$language', '$purchaseOrder', '$shop', '$brand',
    function ($scope, $rootScope, $uibModal, $api, $stateParams, $state, $q, $translate, $retailer, $language, $purchaseOrder, $shop, $brand) {
        // console.log('brandAutoCreatedPurchaseOrderController called');

        $scope.shopProducts = []
        $scope.purchaserOrdersWithNewStatus = []
        $scope.filter = {}
        $scope.year = moment().year()
        $scope.month = moment().month()
        $scope.bestSellArr = [];
        $scope.pageSize = 10
        $scope.currentPage = 1
        $scope.totalProducts = 0;
        $scope.limit = 10;
        $scope.index = 0;

        $scope.localdata = {
            articleNumberSort: false,
            productNumberSort: true,
            isShowProduct: true,
            isArrowArticle: true,
            isArrowProductNumber: true
        }

        $scope.filterChanged = function(value, time){
            $scope.timeSelected = time
            $scope.valueReceived = value
            var currentDate = new Date(new Date().setHours(23, 59, 59));
            if (time === 'year') {
              $scope.filter.startDate = moment().year(value).startOf('year').toDate()
              $scope.filter.endDate = moment().year(value).endOf('year').toDate();
              $scope.year = value
            }
            if (time === 'month') {
              $scope.filter.startDate = moment().year($scope.year).month(value).startOf('month').toDate()
              $scope.filter.endDate = moment().year($scope.year).month(value).endOf('month').toDate()
              if ($scope.filter.startDate < currentDate && $scope.filter.endDate > currentDate && $scope.filter.startDate < $scope.filter.endDate) {
                $scope.filter.endDate = new Date(new Date().setHours(23, 59, 59));
              }
            }
            if (time === 'week') {
              $scope.filter.startDate = moment().year($scope.year).week(value).startOf('week').toDate()
              $scope.filter.endDate = moment().year($scope.year).week(value).endOf('week').toDate()
            }
            if(time === 'date') { $scope.filter.period = 'custom'; }
        }

        $scope.changeStock = function(stock, flag, index, parentIndex){
            if(flag == 'Add'){
              $scope.shopProducts[parentIndex].Detail[index].products.minStock += 1;
            }else{
              $scope.shopProducts[parentIndex].Detail[index].products.minStock -= 1;
            }
            var minStock = $scope.shopProducts[parentIndex].Detail[index].products.minStock;
            var productId = $scope.shopProducts[parentIndex].Detail[index].productDetail._id;
            var shopId = $scope.shopProducts[parentIndex].shop._id;
            $shop.updateShopProductMinStock(productId, shopId, $rootScope.currentCompany._id, minStock)
              .then(function (success) { })
              .catch(function (error) {
                  console.error('Error in', error)
              })
          }

        $scope.showDate = function(data, index) {
            // console.log('------------ showDate!!');
            // console.log('data::::',data);
            $scope.index = index;
            if (data) {
              $scope.month = undefined
              $scope.disableMonthSelection = true
            } else {
              $scope.disableMonthSelection = false
            }
            $scope.showDates = data
        }

        $scope.hideBestSellingProducts = function (index) {
            $scope.shopProducts[index].hide = !$scope.shopProducts[index].hide;
          }

        $scope.getBestSoldItems = function (shopId ,index) {
            // console.log('------------- getBestSoldItems 1!!');
            var endDate = $scope.filter.endDate; 
            var startDate = $scope.filter.startDate;
      
            if(!endDate) { endDate = new Date(new Date().setHours(23, 59, 59)); } 
            if(!startDate) { 
              var date = new Date();
              var value = date.getFullYear();
              var startDate = moment().year(value).startOf('year').toDate()
            }
            var filters = {
              suppliers: [],
              endDate: endDate ? endDate : '',
              startDate: startDate ? startDate : '',
            }

            var shopId = $scope.shopProducts[index].shop._id;
            var companyId = $scope.currentCompany._id;
            var nameSlug = $scope.currentCompany.nameSlug;
      
            $scope.loading = true;
            return $api.get('v2/shop/sold-productsnew/' + shopId,
              {
                filters: filters,
                companyId: companyId,
                nameSlug: nameSlug,
                exportOption: 'ALL'
              }
            )
                .then(function (response) {
                    $scope.loading = false;
                    if (response && response.data && response.data.result && response.data.result.soldProductsBasedOnVariantNumber) {
                        $scope.bestSellArr[index] = {};
                        if (response.data.result.soldProductsBasedOnVariantNumber.length) {
                            for (var i = 0; i < response.data.result.soldProductsBasedOnVariantNumber.length; i++) {
                                response.data.result.soldProductsBasedOnVariantNumber[i].productDetail = response.data.result.soldProductsBasedOnVariantNumber[i].productDetails;
                                response.data.result.soldProductsBasedOnVariantNumber[i].productDetails = undefined;
                                console.log('response.data.result.soldProductsBasedOnVariantNumber[i]: ', i, response.data.result.soldProductsBasedOnVariantNumber[i]);
                            }
                        }
                        $scope.shopProducts[index].bestSellingProducts = response.data.result.soldProductsBasedOnVariantNumber;
                        $scope.bestSellArr[index].bestSellingProducts = response.data.result.soldProductsBasedOnVariantNumber;
                        $scope.shopProducts[index].hide = false;
                        for (var i = 0; i < $scope.shopProducts[index].bestSellingProducts.length; i++) {
                            $scope.shopProducts[index].bestSellingProducts[i].toPurchaseOrder = false;
                        }
                        removeDuplicatesProductsFromBestSellingProducts(index);
                    }
                })
                .catch(function (error) {
                    $scope.loading = false;
                    console.log('error: ', error)
                })
        }

        function removeDuplicatesProductsFromBestSellingProducts(index) {
            // console.log('-------- removeDuplicatesProductsFromBestSellingProducts!!');
            for(var i = 0; i < $scope.shopProducts[index].Detail.length; i++){
              for(var j = 0; j < $scope.shopProducts[index].bestSellingProducts.length; j++){
                if($scope.shopProducts[index].bestSellingProducts[j] && $scope.shopProducts[index].bestSellingProducts[j].productDetail && $scope.shopProducts[index].bestSellingProducts[j].productDetail._id &&
                  $scope.shopProducts[index].Detail[i] && $scope.shopProducts[index].Detail[i].productDetail && $scope.shopProducts[index].Detail[i].productDetail._id){
                    if($scope.shopProducts[index].Detail[i].productDetail._id.toString() == $scope.shopProducts[index].bestSellingProducts[j].productDetail._id.toString()){
                      $scope.shopProducts[index].bestSellingProducts.splice(j, 1)
                    }
                }
              }
            }
          }

        $scope.RemoveProduct = function (productIndex, supplierIndex, productId, isFromClientGroup) {
            // console.log('shopproducts: ', productIndex, supplierIndex, $scope.shopProducts[supplierIndex], $scope.shopProducts[supplierIndex].Detail[productIndex].products.purchasePrice);
            // console.log('Final: ', $scope.shopProducts[supplierIndex].selectedPurchasePrice, $scope.shopProducts[supplierIndex].Detail[productIndex].products.purchasePrice);
            console.log('Detail: ', productId, productIndex, supplierIndex, $scope.shopProducts[supplierIndex].Detail[productIndex], isFromClientGroup)
            $scope.shopProducts[supplierIndex].selectedPurchasePrice = $scope.shopProducts[supplierIndex].selectedPurchasePrice - ($scope.shopProducts[supplierIndex].Detail[productIndex].products.purchasePrice || 0);
            // var productId = item.productDetail._id;
            if (productId && $scope.shopProducts[supplierIndex].bestSellingProducts && $scope.shopProducts[supplierIndex].bestSellingProducts.length) {
                for (var i = 0; i < $scope.shopProducts[supplierIndex].bestSellingProducts.length; i++) {
                    if ($scope.shopProducts[supplierIndex].bestSellingProducts[i].productId.toString() === productId.toString()) {
                        $scope.shopProducts[supplierIndex].bestSellingProducts[i].toPurchaseOrder = !$scope.shopProducts[supplierIndex].bestSellingProducts[i].toPurchaseOrder;
                    }
                }
            }
            $scope.shopProducts[supplierIndex].Detail.splice(productIndex, 1);

            // $scope.shopProducts[parentIndex].clientProducts[index].toPurchaseOrder

            if (productId && $scope.shopProducts[supplierIndex].clientProducts && $scope.shopProducts[supplierIndex].clientProducts.length) {
                for (var i = 0; i < $scope.shopProducts[supplierIndex].clientProducts.length; i++) {
                    // console.log(i, productId, $scope.shopProducts[supplierIndex].clientProducts[i].productId);
                    if ($scope.shopProducts[supplierIndex].clientProducts[i].productId.toString() === productId.toString()) {
                        $scope.shopProducts[supplierIndex].clientProducts[i].toPurchaseOrder = !$scope.shopProducts[supplierIndex].clientProducts[i].toPurchaseOrder;
                    }
                }
            }
        }

        $scope.addToPurchaseOrderAll = function (parentIndex, index, group) {
            
            $scope.shopProducts[parentIndex].toPurchaseOrder = !$scope.shopProducts[parentIndex].toPurchaseOrder;
            // console.log('addToPurchaseOrderAll: ', $scope.shopProducts[parentIndex].toPurchaseOrder, $scope.shopProducts[parentIndex]);
            var productIds = [];
            if ($scope.shopProducts[parentIndex].toPurchaseOrder) {
                // HERE, when we select

                function getProductIds() {
                    return productIds = $scope.shopProducts[parentIndex].bestSellingProducts.map(function(el) {
                        return el.productId;
                    })
                }

                var body = {
                    shopId: group.shop._id,
                    productIds: getProductIds()
                }
                // console.log('getTheShopProductsPurchasePrice: ', body);
                $shop.getTheShopProductsPurchasePrice(body).then(function (shopdetail) {
                    // console.log('getTheShopProductsPurchasePrice response: ', shopdetail);
                    if (shopdetail && shopdetail.data && shopdetail.data.length) {

                        for (var i = 0; i < $scope.shopProducts[parentIndex].bestSellingProducts.length; i++) {
                            $scope.shopProducts[parentIndex].bestSellingProducts[i].toPurchaseOrder = $scope.shopProducts[parentIndex].toPurchaseOrder;

                            var product = $scope.shopProducts[parentIndex].bestSellingProducts[i];
                            // console.log(i, product.productId, product.productId.toString());
                            for (var j = 0; j < shopdetail.data.length; j++) {
                                var prod = shopdetail.data[j];
                                if (product.productId.toString() == prod.product._id.toString()) {
                                    $scope.shopProducts[parentIndex].bestSellingProducts[i].purchasePrice = prod.product.purchasePrice;
                                    group.selectedPurchasePrice = group.selectedPurchasePrice + prod.product.purchasePrice;
                                }
                            }

                            var obj = {
                                productDetail: {
                                    _id: product.productId,
                                    productNumber: [product.variantNumber],
                                    name: product.en.name,
                                    images: product.productDetail && product.productDetail.images && product.productDetail.images.length ? product.productDetail.images : []
                                },
                                products: {
                                    _id: product.productId,
                                    articleNumber: product.articleNumber,
                                    minStock: product.minStock,
                                    labelDescription: product.labelDescription,
                                    purchasePrice: $scope.shopProducts[parentIndex].bestSellingProducts[i].purchasePrice,
                                }
                            };
    
                            $scope.shopProducts[parentIndex].Detail.push(obj)

                        }
    
                    }
                }).catch(function (error) {
                    console.log('ShopProduct error: ', error)
                });

            } else {
                // HERE, when we de-select
                for (var i = 0; i < $scope.shopProducts[parentIndex].bestSellingProducts.length; i++) {
                    $scope.shopProducts[parentIndex].bestSellingProducts[i].toPurchaseOrder = $scope.shopProducts[parentIndex].toPurchaseOrder;
                    var product = $scope.shopProducts[parentIndex].bestSellingProducts[i];

                    for (var j = 0; j < $scope.shopProducts[parentIndex].Detail.length; j++) {
                        if (($scope.shopProducts[parentIndex].Detail[j].productDetail._id.toString() === product.productId.toString()) ||
                            ($scope.shopProducts[parentIndex].Detail[j].products._id.toString() === product.productId.toString())) {
                            $scope.RemoveProduct(j, parentIndex, product.productId);
                        }
                    }
                }
            }

        }

        $scope.addToPurchaseOrder = function (product, parentIndex, index, flag, group) {
            // console.log('----------------- addToPurchaseOrder!!', parentIndex, index, flag, product, $scope.shopProducts[parentIndex].bestSellingProducts[index].toPurchaseOrder);
            // console.log('----->product ', group.shop._id, product.productId, $scope.shopProducts[parentIndex].bestSellingProducts[index].toPurchaseOrder);
            $scope.shopProducts[parentIndex].bestSellingProducts[index].toPurchaseOrder = !$scope.shopProducts[parentIndex].bestSellingProducts[index].toPurchaseOrder;

            var obj = {
                productDetail: {
                    _id: product.productId,
                    productNumber: [product.variantNumber],
                    name: product.en.name,
                    images: product.productDetail.images ? product.productDetail.images : []
                },
                products: {
                    _id: product.productId,
                    articleNumber: product.articleNumber,
                    minStock: product.minStock,
                    labelDescription: product.labelDescription
                }
            };
            var alreadyExists = false;
            if (flag) {
                for (var i = 0; i < $scope.shopProducts[parentIndex].Detail.length; i++) {
                    if (($scope.shopProducts[parentIndex].Detail[i].productDetail._id.toString() === product.productId.toString()) ||
                        ($scope.shopProducts[parentIndex].Detail[i].products._id.toString() === product.productId.toString())) {
                        $scope.RemoveProduct(i, parentIndex, product.productId);
                        alreadyExists = true;
                    }
                }
            } else {
                var body = {
                    shopId: group.shop._id,
                    productId: product.productId
                }
                $shop.getTheShopProduct(body).then(function (shopdetail) {
                    // console.log('getTheShopProduct response: ', shopdetail);
                    if (shopdetail && shopdetail.data && shopdetail.data.isShopProduct && shopdetail.data.product && shopdetail.data.product.purchasePrice) {
                        group.selectedPurchasePrice = group.selectedPurchasePrice + shopdetail.data.product.purchasePrice;
                    }
                    $scope.shopProducts[parentIndex].Detail.push(obj);
                }).catch(function (error) {
                    console.log('ShopProduct error: ', error)
                });
            }
        }

        $scope.addToPurchaseOrderOfClientGroup = function (product, parentIndex, index, isSelected, group) {
            console.log('addToPurchaseOrderOfClientGroup: ', isSelected, $scope.shopProducts[parentIndex].clientProducts[index].toPurchaseOrder);
            $scope.shopProducts[parentIndex].clientProducts[index].toPurchaseOrder = !$scope.shopProducts[parentIndex].clientProducts[index].toPurchaseOrder;
            isSelected = $scope.shopProducts[parentIndex].clientProducts[index].toPurchaseOrder;

            var obj = {
                productDetail: {
                    _id: product.productId,
                    productNumber: [product.variantNumber],
                    name: product.en.name,
                    images: product.productDetail.images ? product.productDetail.images : []
                },
                products: {
                    _id: product.productId,
                    articleNumber: product.articleNumber
                }
            };

            if (!isSelected) {
                console.log('addToPurchaseOrderOfClientGroup called: ', product, parentIndex, index, isSelected, group);
                // return;
                for (var i = 0; i < $scope.shopProducts[parentIndex].Detail.length; i++) {
                    console.log('clientGroup: ', i, $scope.shopProducts[parentIndex].Detail[i], product.productId)
                    if (($scope.shopProducts[parentIndex].Detail[i].productDetail._id.toString() === product.productId.toString()) ||
                        ($scope.shopProducts[parentIndex].Detail[i].productDetail._id.toString() === product.productId.toString())) {
                        $scope.RemoveProduct(i, parentIndex, product.productId, true);
                    }
                }
            } else {
                console.log('isSelected else: ', isSelected);
                var body = {
                    shopId: group.shop._id,
                    productId: product.productId
                }
                $shop.getTheShopProduct(body).then(function (shopdetail) {
                    console.log('getTheShopProduct response: ', shopdetail);
                    if (shopdetail && shopdetail.data && shopdetail.data.isShopProduct && shopdetail.data.product && shopdetail.data.product.purchasePrice) {
                        group.selectedPurchasePrice = group.selectedPurchasePrice + shopdetail.data.product.purchasePrice;
                    }
                    $scope.shopProducts[parentIndex].Detail.push(obj);
                }).catch(function (error) {
                    console.log('ShopProduct error: ', error)
                });
            }
            var shopproducttemp = JSON.parse(JSON.stringify($scope.shopProducts));
            $scope.shopProducts = [];
            $scope.shopProducts = shopproducttemp;
        }

        $scope.addToPurchaseOrderAllOfClientGroup = function (parentIndex, index, group) {
            
            $scope.shopProducts[parentIndex].toPurchaseOrder = !$scope.shopProducts[parentIndex].toPurchaseOrder;
            // console.log('addToPurchaseOrderAll: ', $scope.shopProducts[parentIndex].toPurchaseOrder, $scope.shopProducts[parentIndex]);
            var productIds = [];
            if ($scope.shopProducts[parentIndex].toPurchaseOrder) {
                // HERE, when we select

                function getProductIds() {
                    return productIds = $scope.shopProducts[parentIndex].clientProducts.map(function(el) {
                        return el.productId;
                    })
                }

                var body = {
                    shopId: group.shop._id,
                    productIds: getProductIds()
                }
                console.log('getTheShopProductsPurchasePrice: ', body);
                // return;
                $shop.getTheShopProductsPurchasePrice(body).then(function (shopdetail) {
                    console.log('getTheShopProductsPurchasePrice response: ', shopdetail);

                    for (var i = 0; i < $scope.shopProducts[parentIndex].clientProducts.length; i++) {
                        if ($scope.shopProducts[parentIndex].clientProducts[i].toPurchaseOrder) continue;
                        $scope.shopProducts[parentIndex].clientProducts[i].toPurchaseOrder = $scope.shopProducts[parentIndex].toPurchaseOrder;

                        var product = $scope.shopProducts[parentIndex].clientProducts[i];
                        // console.log(i, product.productId, product.productId.toString());
                        for (var j = 0; j < shopdetail.data.length; j++) {
                            var prod = shopdetail.data[j];
                            if (product.productId.toString() == prod.product._id.toString()) {
                                $scope.shopProducts[parentIndex].clientProducts[i].purchasePrice = prod.product.purchasePrice;
                                console.log(j, prod.product.purchasePrice);
                                group.selectedPurchasePrice = group.selectedPurchasePrice + prod.product.purchasePrice;
                            }
                        }

                        var obj = {
                            productDetail: {
                                _id: product.productId,
                                productNumber: [product.variantNumber],
                                name: product.en.name,
                                images: product.productDetail && product.productDetail.images && product.productDetail.images.length ? product.productDetail.images : []
                            },
                            products: {
                                _id: product.productId,
                                articleNumber: product.articleNumber,
                                minStock: product.minStock,
                                labelDescription: product.labelDescription,
                                purchasePrice: $scope.shopProducts[parentIndex].clientProducts[i].purchasePrice || 0,
                            }
                        };

                        $scope.shopProducts[parentIndex].Detail.push(obj)

                    }
                }).catch(function (error) {
                    console.log('ShopProduct error: ', error)
                });

            } else {
                // HERE, when we de-select
                for (var i = 0; i < $scope.shopProducts[parentIndex].clientProducts.length; i++) {
                    $scope.shopProducts[parentIndex].clientProducts[i].toPurchaseOrder = $scope.shopProducts[parentIndex].toPurchaseOrder;
                    var product = $scope.shopProducts[parentIndex].clientProducts[i];

                    for (var j = 0; j < $scope.shopProducts[parentIndex].Detail.length; j++) {
                        if (($scope.shopProducts[parentIndex].Detail[j].productDetail._id.toString() === product.productId.toString()) ||
                            ($scope.shopProducts[parentIndex].Detail[j].products._id.toString() === product.productId.toString())) {
                            $scope.RemoveProduct(j, parentIndex, '', true);
                        }
                    }
                }
            }

        }

        $scope.getNewOrderAutoMatically = function () {
            // console.log('getNewOrderAutoMatically called: ');
            if ($rootScope.currentCompany) {
                $scope.loading = true
                var body = {
                    limit: $scope.limit,
                    offset: $scope.currentPage
                }
                $api.get('get-brand-purchase-order-automatically/' + $rootScope.currentCompany._id, body).then(function (response) {
                    $scope.shopProducts = response.data.shopProducts;
                    $scope.totalProducts = response.data.totalProducts;
                    console.log('getNewOrderAutoMatically: ', $scope.shopProducts.length, $scope.shopProducts)
                    $scope.loading = false;
                }).catch(function (reason) {
                    console.error('Error while getting the order list', reason)
                    $scope.loading = false
                })
            }
        }

        $scope.recommendationOrderPage = function (index) {
            $state.go('brand.recommendation-order', { orderNo: $scope.shopProducts[index].number });
        }

        // Creating purchase order using shop.products and purchase order (will create new purchase order with given data and will removed the purchaser orderno with given data)
        $scope.CreatePurchaseOrderFromTheShopProduct = function (indexofShopProudct) {
            var products = $scope.shopProducts[indexofShopProudct]
            // console.log('CreatePurchaseOrderFromTheShopProduct: ', indexofShopProudct, $scope.shopProducts)

            // console.log('----products: ', products) // Its company name
            // console.log(products.purchaseDetail[0].company.name)
            var createPurchaseOrder = {
                shop: {
                    noShop: false,
                    name: products.shop.name,
                    nameSlug: products.shop.nameSlug,
                    _id: products.shop._id
                },
                company: {
                    noCompany: false,
                    name: $rootScope.currentCompany.name,
                    nameSlug: $rootScope.currentCompany.nameSlug,
                    _id: $rootScope.currentCompany._id,
                },
                billingDetails: {
                    address: $rootScope.currentCompany.address,
                    firstName: $rootScope.currentCompany.name,
                    email: $rootScope.currentCompany.email
                },
                status: [
                    {
                        status: 'New'
                    }
                ],
                items: []
            }

            for (var i = 0; i < products.Detail.length; i++) {
                if (products.Detail[i].products) {
                    // This product is from the shop.products
                    var toOrder = 1;
                    if(products.Detail[i].products.calculatedStock){
                       toOrder = (products.Detail[i].products.minStock - products.Detail[i].products.calculatedStock);
                    }else{
                        toOrder = (products.Detail[i].products.minStock - products.Detail[i].products.stock);
                    }
                    createPurchaseOrder.items.push({
                        product_id: products.Detail[i].products._id,
                        number: products.Detail[i].productDetail.productNumber[0],
                        name: products.Detail[i].productDetail.name,
                        price: 0,
                        quantity: toOrder, 
                        receivedQuantity: 0,
                        stock: 0
                    })
                } else {
                    // This product is from the purchase order product
                    createPurchaseOrder.items.push(products.Detail[i].productDetail)
                }
            }

            var body = {
                createPurchaseOrder: createPurchaseOrder,
                removedPurchaseOrderIdArray: products.removedPurchaseOrderIdArray,
                shopId: products.shop._id,
                companyId: $rootScope.currentCompany._id
            }

            // console.log('body: ', body)
            $api.post('create-purchaser-order-automatically/', body)
                .then(function (response) {
                    // console.log($scope.shopProducts[indexofShopProudct].Name)
                    $scope.shopProducts[indexofShopProudct].alreadycreated = true
                    $scope.shopProducts[indexofShopProudct].number = response.data.number;
                    $scope.shopProducts[indexofShopProudct].alertShow = true;
                }).catch(function (reason) {
                    console.error('Error when saving the order', reason)
                })
        }

        $scope.popover = function (item) {
            // console.log('--- popover ', item);
            if (item.productDetails && !item.productDetail) { item.productDetail = item.productDetails; }
            // console.log('item: ', item.productDetail.images);
            if (item.productDetail) {
                if (item.productDetail.images && item.productDetail.images.length && item.productDetail.images[0].src) {
                    var image = $rootScope.awsUrl + item.productDetail.images[0].src
                    return '<img src=' + image + " alt='No Image found'>"
                } else if (item.productDetail.category) {
                    return item.productDetail.category
                }
            }
        }

        $scope.changePage = function (position, currentPage) {
            $scope.currentPage = currentPage;
            $scope.getNewOrderAutoMatically();
            // if (position == 'bottom') $anchorScroll('paginate-bottom')
        }

        $scope.getYears = function () {
            var cYear = moment().year()
            //Define in which year we should start showing years
            var startYear = 2018
            $scope.years = []

            for (var i = startYear; i < cYear + 1; i++) {
                $scope.years.push(i)
            }
        }

        $scope.getMonths = function () {
            $scope.months = [
                {
                    id: 0,
                    name: $language.translate('JANUARY')
                },
                {
                    id: 1,
                    name: $language.translate('FEBRUARY'),
                },
                {
                    id: 2,
                    name: $language.translate('MARCH')
                },
                {
                    id: 3,
                    name: $language.translate('APRIL')
                },
                {
                    id: 4,
                    name: $language.translate('MAY')
                },
                {
                    id: 5,
                    name: $language.translate('JUNE')
                },
                {
                    id: 6,
                    name: $language.translate('JULY')
                },
                {
                    id: 7,
                    name: $language.translate('AUGUST')
                },
                {
                    id: 8,
                    name: $language.translate('SEPTEMBER')
                },
                {
                    id: 9,
                    name: $language.translate('OCTOBER')
                },
                {
                    id: 10,
                    name: $language.translate('NOVEMBER')
                },
                {
                    id: 11,
                    name: $language.translate('DECEMBER')
                }
            ]
        }

        $scope.getWeeks = function () {
            // console.log('$scope.year::', $scope.year);

            var tWeek = $language.translate('WEEK')
            var weeks = moment().year($scope.year).isoWeeksInYear()

            $scope.weeks = []

            for (var i = 1; i < (weeks + 1); i++) {
                $scope.weeks.push(
                    {
                        id: i,
                        name: tWeek + ' ' + i
                    }
                )
            }
        }
        $scope.getYears()
        $scope.getMonths()
        $scope.getWeeks()

        $scope.fetchTheStatistics = function (index) {
            // console.log('fetchTheStatistics', $scope.shopProducts[index], index);
            var body = {
                shopId: $scope.shopProducts[index].shop._id,
                companyId: $rootScope.currentCompany._id
            }
            $purchaseOrder.getBrandStatisticsOfAutomaticPurchaseOrder(body).then(function (response) {
                console.log('getBrandStatisticsOfAutomaticPurchaseOrder response: ', Math.round(response.data.averageStockValue*100)/100);
                if (response && response.data) {
                    $scope.shopProducts[index].actualPurchaseStock = response.data.actualPurchaseStock;
                    $scope.shopProducts[index].averageStockValue = Math.round(response.data.averageStockValue*100)/100;
                }
            }).catch(function (error) {
                console.error('fetchTheStatistics error while changing the status: ', error);
            });
        }

        $scope.sortTheProduct = function (isArticleNumberSort, isArrow, index) {
            var shopdetail = $scope.shopProducts[index];
            console.log('sortTheProduct called: ', isArrow, $scope.localdata.isArrowArticle, isArticleNumberSort, shopdetail.shop);
            if (isArticleNumberSort) {
                $scope.localdata.isArrowArticle = !isArrow;
                // $scope.localdata.productNumberSort = false;
            } else {
                $scope.localdata.isArrowProductNumber = !isArrow;
                // $scope.localdata.articleNumberSort = false;
            }

            var body = {
                shopId: shopdetail.shop._id,
                companyId: $rootScope.currentCompany._id,
                sort: {
                    isArticleNumberSort: isArticleNumberSort,
                    sort: !isArrow
                }
                // articleNumberSort: $scope.localdata.articleNumberSort
            }
            $purchaseOrder.SortShopProductOfAutoMaticOrder(body).then(function (response) {
                if (response && response.data && response.data.data) {
                    console.log('SortShopProductOfAutoMaticOrder response: ', response.data.data);
                    if (response && response.data && response.data.data && response.data.data.Detail && response.data.data.Detail.length) {
                        $scope.shopProducts[index].Detail = response.data.data.Detail;
                    }
                    var shopProdDetail = $scope.shopProducts[index];
                    console.log('$scope.shopProducts[index]: ', JSON.parse(JSON.stringify($scope.shopProducts[index], null, 2)));
                    if (shopProdDetail && shopProdDetail.clientProducts && shopProdDetail.clientProducts.length) {
                        var clientGroupRes = shopProdDetail.clientProducts;
                        console.log('clientGroupRes response: ', clientGroupRes);
                        for (var i = 0; i < clientGroupRes.length; i++) {
                            console.log(i, clientGroupRes[i]);
                            if (clientGroupRes[i].toPurchaseOrder) {
                                var isAlreadyExist = false;
                                for (var j = 0; j < shopProdDetail.Detail.length; j++) {
                                    // console.log(shopProdDetail.Detail[j].productDetail._id, productId, );
                                    if (shopProdDetail.Detail[j].productDetail._id.toString() === clientGroupRes[i].productId.toString()) {
                                        isAlreadyExist = true;
                                        break;
                                    }
                                }
                                if (isAlreadyExist) continue;
                                var obj = {
                                    productDetail: {
                                        _id: clientGroupRes[i].productId,
                                        productNumber: [clientGroupRes[i].variantNumber],
                                        name: clientGroupRes[i].en.name,
                                        images: clientGroupRes[i].productDetail.images ? clientGroupRes[i].productDetail.images : []
                                    },
                                    products: {
                                        _id: clientGroupRes[i].productId,
                                        articleNumber: clientGroupRes[i].articleNumber
                                    }
                                };

                                $scope.shopProducts[index].Detail.push(obj);
                            }
                        }
                    }
                }
            }).catch(function (error) {
                console.error('SortShopProductOfAutoMaticOrder error while changing the status: ', error);
            });
        }

        $scope.fetchTheClientGroupShopProducts = function (index) {
            console.log(index, $scope.shopProducts);
            var shopdetail = $scope.shopProducts[index];
            console.log('fetchTheClientGroupShopProducts called: ', shopdetail);
            $scope.shopProducts[index].isShowProduct = !$scope.shopProducts[index].isShowProduct;
            if ($scope.shopProducts[index].isClientProductsFetched) return;
            var body = {
                shopId: shopdetail.shop._id,
                companyId: $rootScope.currentCompany._id,
            }
            $brand.fetchTheClientGroupShopProducts(body).then(function (response) {
                $scope.shopProducts[index].isClientProductsFetched = true;
                if (response && response.data && response.data.data && response.data.data.length) {
                    console.log('fetchTheClientGroupShopProducts response: ', response.data.data);
                    for (var i = 0; i < response.data.data.length; i++) {
                        response.data.data[i].toPurchaseOrder = false;
                        var productId = response.data.data[i].productDetail._id;
                        for (var j = 0; j < shopdetail.Detail.length; j++) {
                            // console.log(shopdetail.Detail[j].productDetail._id, productId, );
                            if (shopdetail.Detail[j].productDetail._id.toString() === productId.toString()) {
                                response.data.data[i].toPurchaseOrder = true;
                            }
                        }
                    }

                    $scope.shopProducts[index].clientProducts = response.data.data;
                }
            }).catch(function (error) {
                console.error('fetchTheClientGroupShopProducts error while changing the status: ', error);
            });
        }
    }])
