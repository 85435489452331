prismanoteApp.controller('deleteShopModalController', ['$scope', '$uibModalInstance', '$api', 'shopId',
  function ($scope, $uibModalInstance, $api, shopId) {
    $scope.getUsersOnShop = function () {
      console.log('getUsersOnShop', shopId)

      $api.get('shops/users', { shopId: shopId })
        .then(function (res) {
          console.log('res', res)
          $scope.users = res.data.users
        })
        .catch(function (reason) {
          console.error(reason)
          if (reason == 'NO_USERS_FOUND') {
            $scope.users = []
          }
        })
    }

    $scope.getShops = function () {
      $api.get('shops')
        .then(function (response) {
          $scope.shops = response.data.shops
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.shopSelected = function (selected) {
      $scope.newShop = selected.originalObject
    }

    $scope.searchShops = function (str) {
      var matches = []
      $scope.shops.forEach(function (shop) {
        if ((shop.name && shop.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                (shop.nameSlug && shop.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
          matches.push(shop)
        }
      })
      return matches
    }

    $scope.moveUsers = function () {
      $api.post('shops/users', {
        newShop: {
          _id: $scope.newShop._id,
          name: $scope.newShop.name,
          nameSlug: $scope.newShop.nameSlug,
          address: $scope.newShop.address
        },
        oldShopId: shopId,
        users: $scope.users.map(function (u) {
          return u._id
        })
      })
        .then(function (res) {
          $scope.getUsersOnShop()
        })
        .catch(function (reason) {
          console.error(reason)
        })
    }

    $scope.delete = function () {
      $api.delete('shops/' + shopId)
        .then(function (res) {
          $scope.close()
        })
        .catch(function (reason) {
          console.error(reason)
        })
    }

    $scope.close = function () {
      $uibModalInstance.close()
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }
  }])
