prismanoteApp.controller('retailerAddDiscountModalController', ['$scope', '$uibModalInstance', 'discount',
  function ($scope, $uibModalInstance, discount) {
    $scope.discount = angular.copy(discount)
    if ($scope.discount && $scope.discount.value > 0) $scope.showDelete = true
    else $scope.showDelete = false

    $scope.save = function () {
      if (!$scope.discount.value || $scope.discount.value == 0) $scope.discount = null
      $uibModalInstance.close($scope.discount)
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    $scope.delete = function () {
      $scope.discount = null
      $uibModalInstance.close($scope.discount)
    }
  }])
