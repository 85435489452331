prismanoteApp.controller('compareProductNewController', ['$scope', '$rootScope', '$stateParams', '$productConfig', '$diamond', '$product', '$brand', '$parse', '$tax', 'Upload', '$state', '$admin', 'prompt', '$language',
    function ($scope, $rootScope, $stateParams, $productConfig, $diamond, $product, $brand, $parse, $tax, Upload, $state, $admin, prompt, $language) {
        $scope.localdata = {
            currentFirstIndex: 0,
            currentLastIndex: 1, // For now its 1 because we are showing only 2 prodcuts. If 3 prodcuts then it will be a 2 and so on
            totalSuggestions: 0,
            productToShow: 2, // How many suggestions want to show at a time
            changed_targetAudience: ''
        }

        $scope.getClassForDiamons = function (prodIndex, suggIndex, key) {
            if (key != 'gemColor' && key != 'gemPurity') {
                var sug = $scope.showProduct1.diamonds[suggIndex][key]
                var prod = $scope.product.diamonds[prodIndex][key]
            } else {
                var sug = $scope.showProduct1.diamonds[suggIndex][key].code
                var prod = $scope.product.diamonds[prodIndex][key].code
            }
            var colorCode

            if ((sug == undefined || sug == '') && (prod == undefined || prod == '')) colorCode = ''
            else if (sug == prod) colorCode = ''
            else if ((sug == undefined || sug == '') && (prod != undefined && prod != '')) colorCode = 'redColorClass'
            else if ((prod == undefined || prod == '') && (sug != undefined && sug != '')) colorCode = 'greenColorClass'
            else if (sug != prod) colorCode = 'orangeColorClass'
            return colorCode
        }

        $scope.getClassForSuggestion = function (sug, prod, showProductVar) {
            var colorCode
            if ((sug == undefined || sug == '') && (prod == undefined || prod == '')) {
                colorCode = ''
            } else if (sug == prod) {
                colorCode = ''
            } else if ((sug == undefined || sug == '') && (prod != undefined && prod != '')) {
                colorCode = 'redColorClass'
            } else if ((prod == undefined || prod == '') && (sug != undefined && sug != '')) {
                colorCode = 'greenColorClass'
            } else if (sug != prod) {
                colorCode = 'orangeColorClass'
            }
            return colorCode
        }

        $scope.getClassForSuggestionBoolen = function (sug, prod) {
            var colorCode
            if (sug == prod) colorCode = ''
            else if ((sug == null || sug == undefined) && (prod != null && prod != undefined)) colorCode = 'redColorClass'
            else if ((prod == null || prod == undefined) && (sug != null && sug != undefined)) colorCode = 'greenColorClass'
            else if (sug != prod) colorCode = 'orangeColorClass'
            return colorCode
        }

        $scope.getClassForSexAudienceSuggestion = function (suggestedProduct, showProductVar) {
            // Get Color For Target Audiaince
            var sug = $scope.getSexAudience(suggestedProduct)
            var prod = $scope.targetAudience
            var colorCode
            if ((sug == undefined || sug == '') && (prod == undefined || prod == '')) {
                colorCode = ''
            } else if (sug == prod) {
                colorCode = ''
            } else if ((sug == undefined || sug == '') && (prod != undefined && prod != '')) {
                colorCode = 'redColorClass'
            } else if ((prod == undefined || prod == '') && (sug != undefined && sug != '')) {
                colorCode = 'greenColorClass'
            } else if (sug != prod) {
                colorCode = 'orangeColorClass'
            }
            return colorCode
        }

        $scope.getSexAudience = function (prod) {
            var targetAudience = '';
            if (prod) {
                if (prod.male) {
                    if (prod.female) {
                        if (prod.kids) {
                            targetAudience = 'KIDS'
                        } else {
                            targetAudience = 'UNISEX'
                        }
                    } else {
                        if (prod.kids) {
                            targetAudience = 'BOYS'
                        } else {
                            targetAudience = 'GENTS'
                        }
                    }
                } else {
                    if (prod.female) {
                        if (prod.kids) {
                            targetAudience = 'GIRLS'
                        } else {
                            targetAudience = 'LADIES'
                        }
                    }
                }
            }
            return targetAudience
        }

        $scope.setGender = function (targetAudience) {
            switch (targetAudience) {
                case 'GENTS':
                    $scope.product.male = true
                    $scope.product.female = false
                    $scope.product.kids = false
                    break
                case 'LADIES':
                    $scope.product.male = false
                    $scope.product.female = true
                    $scope.product.kids = false
                    break
                case 'UNISEX':
                    $scope.product.male = true
                    $scope.product.female = true
                    $scope.product.kids = false
                    break
                case 'BOYS':
                    $scope.product.male = true
                    $scope.product.female = false
                    $scope.product.kids = true
                    break
                case 'GIRLS':
                    $scope.product.male = false
                    $scope.product.female = true
                    $scope.product.kids = true
                    break
                case 'KIDS':
                    $scope.product.male = true
                    $scope.product.female = true
                    $scope.product.kids = true
                    break
            }
        }

        $scope.getTargetAudience = function (product) {
            var male = product.male;
            var female = product.female;
            var kids = product.kids;
            console.log('getTargetAudience-----> ', product.male, product.female, product.kids);
            if (male) {
                if (female) {
                    if (kids) $scope.localdata.changed_targetAudience = 'KIDS'
                    else $scope.localdata.changed_targetAudience = 'UNISEX'
                } else {
                    if (kids) $scope.localdata.changed_targetAudience = 'BOYS'
                    else $scope.localdata.changed_targetAudience = 'GENTS'
                }
            } else {
                if (female) {
                    if (kids) $scope.localdata.changed_targetAudience = 'GIRLS'
                    else $scope.localdata.changed_targetAudience = 'LADIES'
                }
            }
            $scope.setGender($scope.localdata.changed_targetAudience)
        }

        $scope.setTargetAudience = function (male, female, kids) {
            if (male) {
                if (female) {
                    if (kids) $scope.targetAudience = 'KIDS'
                    else $scope.targetAudience = 'UNISEX'
                } else {
                    if (kids) $scope.targetAudience = 'BOYS'
                    else $scope.targetAudience = 'GENTS'
                }
            } else {
                if (female) {
                    if (kids) $scope.targetAudience = 'GIRLS'
                    else $scope.targetAudience = 'LADIES'
                }
            }
            $scope.setGender($scope.targetAudience)
        }

        // Start Diamond Functions
        $scope.openDiamond = function (index) {
            // Open Main Product Diamond
            $scope.diamond = $scope.product.diamonds[index]
            $scope.diamondIndex = index
            $scope.editDiamond = true
        }

        // $scope.openSuggDiamond = function (index) {
        //     // Open Suggestion Product Diamond
        //     $scope.suggDiamond = $scope.showProduct1.diamonds[index]
        //     $scope.suggDiamondIndex = index
        //     $scope.editSuggDiamond = true
        // }

        $scope.clearTheDiamond = function () {
            // Clear Diamond
            $scope.diamond = {
                quantity: '',
                gemKind: '',
                gemPurity: {
                    code: ''
                },
                gemColor: {
                    code: ''
                },
                gemCut: '',
                caratWeight: '',
                suggestedRetailPriceDiamond: ''
            }
            $scope.editDiamond = false
        }

        $scope.cancelNewDiamond = function (diamondIndex) {
            // Cancel New Diamond
            $scope.product.diamonds.splice(diamondIndex, 1)
            $scope.addDiamond = false
            $scope.clearTheDiamond()
        }

        $scope.addNewDiamondForm = function () {
            // Add New Diamond Form
            $scope.editDiamond = false
            $scope.clearTheDiamond()

            if (!$scope.product.diamonds) $scope.product.diamonds = []

            $scope.diamondIndex = $scope.product.diamonds.length
            $scope.addDiamond = true
        }

        $scope.pushDiamond = function (index) {
            // Add New Diamond In diamons array
            if (!$scope.product.diamonds) $scope.product.diamonds = []
            $scope.product.diamonds[index] = $scope.diamond
            $scope.editDiamond = false
            $scope.addDiamond = false
            $scope.clearTheDiamond()
        }

        $scope.pushTheDiamondInFinalProduct = function (diamond) {
            if (!$scope.product.diamonds || !$scope.product.diamonds.length) $scope.product.diamonds = [];
            $scope.product.diamonds.push(diamond);
        }

        $scope.deleteDiamond = function (index) {
            // Remove Diamond From Diamonds array
            $scope.product.diamonds.splice(index, 1)
        }

        $scope.assignValueForDiamond = function (prodIndex, suggIndex, key) {
            if (key != 'gemColor' && key != 'gemPurity') {
                $scope.product.diamonds[prodIndex][key] = $scope.showProduct1.diamonds[suggIndex][key]
            } else $scope.product.diamonds[prodIndex][key].code = $scope.showProduct1.diamonds[suggIndex][key].code
        }

        // END Diamond Functions

        $scope.getValueChangedIfSuggestedAudienceClick = function (suggestedProduct) {
            var sug = $scope.getSexAudience(suggestedProduct)
            $scope.localdata.changed_targetAudience = sug
            console.log('suggestedProduct: ', suggestedProduct, sug);
            $scope.setGender($scope.localdata.changed_targetAudience)
        }

        $scope.showProduct1Change = function (obj, parentObj) {
            if (obj) {
                $scope.showProduct1 = JSON.parse(JSON.stringify(obj))
                $scope.showProduct1.suggestedRetailPrice = ($scope.showProduct1.suggestedRetailPrice * (($scope.showProduct1.suggestedRetailPriceVat / 100) + 1)) || 0
            }
        }

        $scope.convertDate = function (date) {
            var d = new Date(date)
            return d.toLocaleString()
        }

        $scope.setProductCategory = function (category) {
            $scope.product.category = category
        }

        function renderProduct() {
            // console.group('Render Product')
            $scope.productNameSlug = $stateParams.nameSlug
            $scope.dialColors = $productConfig.getDialColors()
            $scope.strapColors = $productConfig.getStarpColors()
            $scope.gemKinds = $diamond.getGemKinds()
            $scope.gemPurities = $diamond.getGemPurities()
            $scope.gemColors = $diamond.getGemColors()
            $scope.gemCuts = $diamond.getGemCuts()
            $scope.eanValid = true
            $scope.PNvalid = true
            $scope.containsFilterInfoCurrent = false
            $scope.diamond = {
                quantity: '',
                gemKind: '',
                gemPurity: { code: '' },
                gemColor: { code: '' },
                gemCut: '',
                caratWeight: '',
                suggestedRetailPriceDiamond: ''
            }
            $scope.editDiamond = false
            $scope.addDiamond = false
            $scope.caseColors = $scope.jewelColors = Object.assign($scope.dialColors, $scope.strapColors)
            getProduct($scope.productNameSlug)
        }

        function getProduct(nameSlug) {
            var data = {
                nameSlug: nameSlug
            }

            $product.getProductWithSuggestions(data).then(function (response) {
                // console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ ---> Product Based on Name Slug', JSON.parse(JSON.stringify(response)))
                response = response.product[0]
                $scope.product = response.product
                $scope.suggestions = [];

                $scope.product.suggestedRetailPrice = Number($scope.product.suggestedRetailPrice)
                $scope.product.suggestedRetailPrice = ($scope.product.suggestedRetailPrice * (($scope.product.suggestedRetailPriceVat / 100) + 1))

                if (response.suggestions && response.suggestions.length && response.suggestions[0]._id) $scope.suggestions = response.suggestions
                if ($scope.suggestions && $scope.suggestions.length) {
                    $scope.localdata.totalSuggestions = $scope.suggestions.length;
                    var looplength = $scope.localdata.productToShow;
                    if ($scope.localdata.totalSuggestions < 2) looplength = $scope.localdata.totalSuggestions;
                    for (var i = 0; i < looplength; i++) {
                        $scope.suggestions[i].isActive = true;
                        $scope['showProduct' + (i + 1)] = $scope.suggestions[i];
                    }
                }

                if ($scope.product.brand && $scope.product.brand.name && $scope.product.brand.isPoolArticleBrand) $scope.disabled = true
                else if ($scope.product.brand && $scope.product.brand.name && !$scope.product.variants[0].productNumberAuto) $scope.PNandBrandDisabled = true


                if ($scope.product.category === 'WATCH') {
                    if ($scope.product.watch.dial && $scope.product.watch.dial.color) $scope.dialColor = $scope.dialColors.find(function (color) {
                        return color.key == $scope.product.watch.dial.color
                    })
                    if ($scope.product.watch.case && $scope.product.watch.case.color) $scope.caseColor = $scope.caseColors.find(function (color) {
                        return color.key == $scope.product.watch.case.color
                    })
                    if ($scope.product.watch.strap && $scope.product.watch.strap.color) $scope.strapColor = $scope.strapColors.find(function (color) {
                        return color.key == $scope.product.watch.strap.color
                    })
                } else if ($scope.product.category === 'STRAP') {
                    if ($scope.product.strap && $scope.product.strap.color) $scope.strapColor = $scope.strapColors.find(function (color) {
                        return color.key == $scope.product.strap.color
                    })
                } else if ($scope.product.category === 'JEWEL') {
                    if ($scope.product.jewel && $scope.product.jewel.color) $scope.jewelColor = $scope.jewelColors.find(function (color) {
                        return color.key == $scope.product.jewel.color
                    })
                }

                $scope.oldProdct = JSON.parse(JSON.stringify($scope.product))
                $scope.setTargetAudience($scope.product.male, $scope.product.female, $scope.product.kids)

                if ($scope.product.containsFilterInfo && ($scope.product.containsFilterInfo.en || $scope.product.containsFilterInfo.de || $scope.product.containsFilterInfo.fr || $scope.product.containsFilterInfo.nl || $scope.product.containsFilterInfo.es)) $scope.containsFilterInfoCurrent = true

                if (!$scope.containsFilterInfoCurrent && $scope.suggestions && $scope.suggestions.length) {
                    for (var i = 0; i < $scope.suggestions.length; i++) {
                        var suggestion = $scope.suggestions[i]
                        if (suggestion && suggestion.containsFilterInfo && (suggestion.containsFilterInfo.en || suggestion.containsFilterInfo.de || suggestion.containsFilterInfo.nl || suggestion.containsFilterInfo.en || suggestion.containsFilterInfo.fr)) $scope.containsFilterInfoCurrent = true
                    }
                }

                $scope.unChangeProduct = JSON.parse(JSON.stringify($scope.product));
                if ($scope.suggestions && $scope.suggestions.length) {
                    var mainProdImages = JSON.parse(JSON.stringify($scope.product && $scope.product.images && $scope.product.images.length ? $scope.product.images : []));

                    var sendBody = {
                        product: $scope.unChangeProduct,
                        suggestions: $scope.suggestions
                    }
                    $scope.product = JSON.parse(JSON.stringify(ifAnyOtherSuggestionHavingDetailThenUseIt(sendBody)));
                    console.log('WHEN ALL DONE------> ', $scope.product);
                    var suggImages = [];

                    for (var i = 0; i < $scope.suggestions.length; i++) {
                        var tempImage = concateImages(mainProdImages, $scope.suggestions[i].images);
                        suggImages = (JSON.parse(JSON.stringify(suggImages.concat(tempImage))));
                    }
                    mainProdImages = mainProdImages.concat(suggImages);
                    $scope.product.images = $scope.image = mainProdImages;
                }
            }).catch(function (error) {
                console.error('Error! While Getting product', error)
            })
        }

        function getLangInfo(property, mainProd, suggestions) {
            var sendBack = mainProd;
            if (!mainProd.name && suggestions.name) mainProd.name = suggestions.name;
            if (!mainProd.nameSlug && suggestions.nameSlug) mainProd.nameSlug = suggestions.nameSlug;
            if (!mainProd.longDescription && suggestions.longDescription) mainProd.longDescription = suggestions.longDescription;
            if (!mainProd.shortDescription && suggestions.shortDescription) mainProd.shortDescription = suggestions.shortDescription;

            return sendBack;
        }

        function collectionsForAutomaticProduct(body) {
            var product = body.product;
            var suggestions = body.suggestions;
            var prodColl = product.collections;
            var uniqueCollections = []; // Here uniqueCollection means PrivateLable and clientGroup from the mainProduct and all the suggestions collections
            for (var k = 0; k < suggestions.length; k++) {
                for (var j = 0; j < suggestions[k].collections.length; j++) {
                    var suggColl = suggestions[k].collections[j];
                    if (!(suggColl.privateLabel || suggColl.isClientGroup)) continue;
                    var isExist = false;
                    for (var i = 0; i < prodColl.length; i++) {
                        if (prodColl[i]._id && ((prodColl[i]._id).toString() == (suggColl._id).toString())) {
                            isExist = true;
                        }
                        // console.info(j, i, (prodColl[i]._id).toString() == (suggColl._id).toString(), prodColl[i]._id, suggColl._id, isExist)
                        if (!isExist && i == (prodColl.length - 1)) {
                            uniqueCollections.push({ _id: suggColl._id });
                        }
                    }
                }
            }
            // console.info('uniqueCollections called: ', JSON.stringify(uniqueCollections, null, 2));
            return uniqueCollections;
        }

        function isNumber(n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        }

        function ifAnyOtherSuggestionHavingDetailThenUseIt(body) {
            var mainProd = JSON.parse(JSON.stringify(body.product));
            var suggestions = JSON.parse(JSON.stringify(body.suggestions));
            var suggImages = [];
            var mainProdImages = JSON.parse(JSON.stringify(mainProd && mainProd.images && mainProd.images.length ? mainProd.images : []));
            // We are doing this becuase we are not breking the for loop, as may shape is in Suggestion1 but movement is in Suggestion2
            // WATCH
            var isWatchTypeDone = false;
            var isWatchMovementDone = false;
            var isWatchIndicationDone = false;
            var isWatchHasSwissMovementDone = false;
            var isWatchHasDateFunctionDone = false;
            var isWatchWaterproofLevelDone = false;
            var isWatchIsNickelFreeDone = false;
            var isWatchIsHasLightFunctionDone = false;
            var isWatchIsAntiAllergyDone = false;
            var isWatchIsSmartwatchDone = false;
            var isWatchSmartWatchFunctionsDone = false;
            var isWatchCaseShapeDone = false;
            var isWatchCaseSizeDone = false;
            var isWatchCaseDepthDone = false;
            var isWatchCaseMaterialDone = false;
            var isWatchCaseColorDone = false;
            var isWatchCaseGlassMaterialDone = false;
            var isWatchCaseDesignItemDone = false;
            // WATCH-DIAL
            var isWatchDialColorDone = false;
            var isWatchDialPatternDone = false;
            var isWatchDialPrintDone = false;
            var isWatchDialIndexDone = false;
            // WATCH-STRAP
            var isWatchStrapModelDone = false;
            var isWatchStrapWidthDone = false;
            var isWatchStrapMaterialDone = false;
            var isWatchStrapColorDone = false;
            var isWatchStrapPrintDone = false;
            var isWatchStrapStudsDone = false;
            var isWatchStrapClaspDone = false;
            var isWatchStrapWristPermimeterDone = false;
            // JEWEL
            var isJewelMaterialDone = false;
            var isJewelColorDone = false;
            var isJewelTypeDone = false;
            var isJewelHeightDone = false;
            var isJewelWidthDone = false;
            var isJewelDepthDone = false;
            var isJewelDiameterDone = false;
            var isJewelWeightDone = false;
            var isJewelChainDone = false;
            var isJewelClaspDone = false;
            var isJewelShapeDone = false;
            var isJewelGlossDone = false;
            // STRAP
            var isStrapModelDone = false;
            var isStrapWidthDone = false;
            var isStrapMaterialDone = false;
            var isStrapColorDone = false;
            var isStrapPrintDone = false;
            var isStrapStudsDone = false;
            var isStrapClaspDone = false;
            var isStrapWristPermimeterDone = false;

            var productObj = {
                nl: '',
                en: '',
                de: '',
                fr: '',
                es: '',
                male: '',
                female: '',
                kids: '',
                containsFilterInfo: '',
                suggestedRetailPrice: '',
                suggestedRetailPriceVat: '',
                images: '',
                brand: '',
                collections: '',
                category: '',
                watch: '',
                strap: '',
                jewel: '',
                diamonds: '',
                totalCaratWeight: '',
                weight: '',
                type: '',
                size: '',
                variants: '',
                suggestions: '',
                hasStock: '',
            }

            for (var property in productObj) {

                for (var i = 0; i < suggestions.length; i++) {
                    if (property == 'suggestedRetailPriceVat') {
                        console.log('-------------------> suggestedRetailPriceVat: ', i, mainProd[property], suggestions[i][property])
                    }
                    if (property == 'nl' || property == 'es' || property == 'de' || property == 'fr' || property == 'en') {
                        mainProd[property] = getLangInfo(property, mainProd[property], suggestions[i][property]);
                    } else if (property == 'suggestedRetailPriceVat' && suggestions[i][property] >= 0 && isNumber(suggestions[i][property]) && mainProd[property] != suggestions[i][property]) {
                        console.log('Are you coming into the suggestedRetailPriceVat: ');
                        mainProd[property] = suggestions[i][property];
                        break;
                    } else if (property == 'suggestedRetailPrice' && (suggestions[i][property] >= 0) && isNumber(suggestions[i][property]) && (mainProd[property] != suggestions[i][property])) {
                        mainProd[property] = suggestions[i][property];
                        break;
                    } else if (property == 'watch') {
                        if (!mainProd[property]) mainProd.watch = {};
                        if (!isWatchTypeDone && suggestions[i][property] && suggestions[i][property].type && (mainProd[property].type != suggestions[i][property].type)) {
                            isWatchTypeDone = true;
                            mainProd[property].type = suggestions[i][property].type;
                        }

                        if (!isWatchMovementDone && suggestions[i][property] && suggestions[i][property].movement && (mainProd[property].movement != suggestions[i][property].movement)) {
                            isWatchMovementDone = true;
                            mainProd[property].movement = suggestions[i][property].movement;
                        }

                        if (!isWatchIndicationDone && suggestions[i][property] && suggestions[i][property].indication && (mainProd[property].indication != suggestions[i][property].indication)) {
                            isWatchIndicationDone = true;
                            mainProd[property].indication = suggestions[i][property].indication;
                        }

                        if (!isWatchHasSwissMovementDone && suggestions[i][property] && (suggestions[i][property].hasSwissMovement == true || suggestions[i][property].hasSwissMovement == false) && (mainProd[property].hasSwissMovement != suggestions[i][property].hasSwissMovement)) {
                            isWatchHasSwissMovementDone = true;
                            mainProd[property].hasSwissMovement = suggestions[i][property].hasSwissMovement;
                        }

                        if (!isWatchHasDateFunctionDone && suggestions[i][property] && (suggestions[i][property].hasDateFunction == true || suggestions[i][property].hasDateFunction == false) && (mainProd[property].hasDateFunction != suggestions[i][property].hasDateFunction)) {
                            isWatchHasDateFunctionDone = true;
                            mainProd[property].hasDateFunction = suggestions[i][property].hasDateFunction;
                        }

                        if (!isWatchWaterproofLevelDone && suggestions[i][property] && (suggestions[i][property].waterproofLevel >= 0) && (mainProd[property].waterproofLevel != suggestions[i][property].waterproofLevel)) {
                            isWatchWaterproofLevelDone = true;
                            mainProd[property].waterproofLevel = suggestions[i][property].waterproofLevel;
                        }

                        if (!isWatchIsNickelFreeDone && suggestions[i][property] && (suggestions[i][property].isNickelFree == true || suggestions[i][property].isNickelFree == false) && (mainProd[property].isNickelFree != suggestions[i][property].isNickelFree)) {
                            isWatchIsNickelFreeDone = true;
                            mainProd[property].isNickelFree = suggestions[i][property].isNickelFree;
                        }

                        if (!isWatchIsHasLightFunctionDone && suggestions[i][property] && (suggestions[i][property].hasLightFunction == true || suggestions[i][property].hasLightFunction == false) && (mainProd[property].hasLightFunction != suggestions[i][property].hasLightFunction)) {
                            isWatchIsHasLightFunctionDone = true;
                            mainProd[property].hasLightFunction = suggestions[i][property].hasLightFunction;
                        }

                        if (!isWatchIsAntiAllergyDone && suggestions[i][property] && (suggestions[i][property].isAntiAllergy == true || suggestions[i][property].isAntiAllergy == false) && (mainProd[property].isAntiAllergy != suggestions[i][property].isAntiAllergy)) {
                            isWatchIsAntiAllergyDone = true;
                            mainProd[property].isAntiAllergy = suggestions[i][property].isAntiAllergy;
                        }

                        if (!isWatchIsSmartwatchDone && suggestions[i][property] && (suggestions[i][property].isSmartwatch == true || suggestions[i][property].isSmartwatch == false) && (mainProd[property].isSmartwatch != suggestions[i][property].isSmartwatch)) {
                            isWatchIsSmartwatchDone = true;
                            mainProd[property].isSmartwatch = suggestions[i][property].isSmartwatch;
                        }

                        if (!isWatchSmartWatchFunctionsDone && suggestions[i][property] && suggestions[i][property].smartWatchFunctions && (mainProd[property].smartWatchFunctions != suggestions[i][property].smartWatchFunctions)) {
                            isWatchSmartWatchFunctionsDone = true;
                            mainProd[property].smartWatchFunctions = suggestions[i][property].smartWatchFunctions;
                        }

                        // CASE
                        if (!mainProd[property].case) mainProd[property].case = {};
                        if (!isWatchCaseShapeDone && suggestions[i][property] && suggestions[i][property].case && suggestions[i][property].case.shape && (mainProd[property].case.shape != suggestions[i][property].case.shape)) {
                            isWatchCaseShapeDone = true;
                            mainProd[property].case.shape = suggestions[i][property].case.shape;
                        }

                        if (!isWatchCaseSizeDone && suggestions[i][property] && suggestions[i][property].case && suggestions[i][property].case.size && (mainProd[property].case.size != suggestions[i][property].case.size)) {
                            isWatchCaseSizeDone = true;
                            mainProd[property].case.size = suggestions[i][property].case.size;
                        }

                        if (!isWatchCaseDepthDone && suggestions[i][property] && suggestions[i][property].case && suggestions[i][property].case.depth && (mainProd[property].case.depth != suggestions[i][property].case.depth)) {
                            // console.info('isWatchCaseDepthDone: ', mainProd[property].case.depth, suggestions[i][property].case.depth);
                            isWatchCaseDepthDone = true;
                            mainProd[property].case.depth = suggestions[i][property].case.depth;
                        }

                        if (!isWatchCaseMaterialDone && suggestions[i][property] && suggestions[i][property].case && suggestions[i][property].case.material && (mainProd[property].case.material != suggestions[i][property].case.material)) {
                            isWatchCaseMaterialDone = true;
                            mainProd[property].case.material = suggestions[i][property].case.material;
                        }

                        if (!isWatchCaseGlassMaterialDone && suggestions[i][property] && suggestions[i][property].case && suggestions[i][property].case.glassMaterial && (mainProd[property].case.glassMaterial != suggestions[i][property].case.glassMaterial)) {
                            isWatchCaseGlassMaterialDone = true;
                            mainProd[property].case.glassMaterial = suggestions[i][property].case.glassMaterial;
                        }

                        if (!isWatchCaseColorDone && suggestions[i][property] && suggestions[i][property].case && suggestions[i][property].case.color && (mainProd[property].case.color != suggestions[i][property].case.color)) {
                            isWatchCaseColorDone = true;
                            mainProd[property].case.color = suggestions[i][property].case.color;
                        }

                        if (!mainProd[property].case.designItem || !mainProd[property].case.designItem.length) mainProd[property].case.designItem = [];
                        if (!isWatchCaseDesignItemDone && suggestions[i][property] && suggestions[i][property].case && suggestions[i][property].case.designItem && suggestions[i][property].case.designItem.length && (mainProd[property].case.designItem[0] != suggestions[i][property].case.designItem[0])) {
                            isWatchCaseDesignItemDone = true;
                            mainProd[property].case.designItem = suggestions[i][property].case.designItem;
                        }

                        // DIAL
                        if (!mainProd[property].dial) mainProd[property].dial = {};
                        if (!isWatchDialColorDone && suggestions[i][property] && suggestions[i][property].dial && suggestions[i][property].dial.color && (mainProd[property].dial.color != suggestions[i][property].dial.color)) {
                            isWatchDialColorDone = true;
                            mainProd[property].dial.color = suggestions[i][property].dial.color;
                        }
                        if (!isWatchDialPatternDone && suggestions[i][property] && suggestions[i][property].dial && suggestions[i][property].dial.pattern && (mainProd[property].dial.pattern != suggestions[i][property].dial.pattern)) {
                            isWatchDialPatternDone = true;
                            mainProd[property].dial.pattern = suggestions[i][property].dial.pattern;
                        }
                        if (!isWatchDialPrintDone && suggestions[i][property] && suggestions[i][property].dial && suggestions[i][property].dial.print && (mainProd[property].dial.print != suggestions[i][property].dial.print)) {
                            isWatchDialPrintDone = true;
                            mainProd[property].dial.print = suggestions[i][property].dial.print;
                        }
                        if (!isWatchDialIndexDone && suggestions[i][property] && suggestions[i][property].dial && suggestions[i][property].dial.index && (mainProd[property].dial.index != suggestions[i][property].dial.index)) {
                            isWatchDialIndexDone = true;
                            mainProd[property].dial.index = suggestions[i][property].dial.index;
                        }

                        // STRAP
                        if (!mainProd[property].strap) mainProd[property].strap = {};
                        if (!isWatchStrapModelDone && suggestions[i][property] && suggestions[i][property].strap && suggestions[i][property].strap.model && (mainProd[property].strap.model != suggestions[i][property].strap.model)) {
                            isWatchStrapModelDone = true;
                            mainProd[property].strap.model = suggestions[i][property].strap.model;
                        }
                        if (!isWatchStrapWidthDone && suggestions[i][property] && suggestions[i][property].strap && suggestions[i][property].strap.width && (mainProd[property].strap.width != suggestions[i][property].strap.width)) {
                            isWatchStrapWidthDone = true;
                            mainProd[property].strap.width = suggestions[i][property].strap.width;
                        }
                        if (!isWatchStrapMaterialDone && suggestions[i][property] && suggestions[i][property].strap && suggestions[i][property].strap.material && (mainProd[property].strap.material != suggestions[i][property].strap.material)) {
                            isWatchStrapMaterialDone = true;
                            mainProd[property].strap.material = suggestions[i][property].strap.material;
                        }
                        if (!isWatchStrapColorDone && suggestions[i][property] && suggestions[i][property].strap && suggestions[i][property].strap.color && (mainProd[property].strap.color != suggestions[i][property].strap.color)) {
                            isWatchStrapColorDone = true;
                            mainProd[property].strap.color = suggestions[i][property].strap.color;
                        }
                        if (!isWatchStrapPrintDone && suggestions[i][property] && suggestions[i][property].strap && suggestions[i][property].strap.print && (mainProd[property].strap.print != suggestions[i][property].strap.print)) {
                            isWatchStrapPrintDone = true;
                            mainProd[property].strap.print = suggestions[i][property].strap.print;
                        }
                        if (!isWatchStrapStudsDone && suggestions[i][property] && suggestions[i][property].strap && suggestions[i][property].strap.studs && (mainProd[property].strap.studs != suggestions[i][property].strap.studs)) {
                            // console.info('isWatchStrapStudsDone: ', suggestions[i][property].strap.studs)
                            isWatchStrapStudsDone = true;
                            mainProd[property].strap.studs = suggestions[i][property].strap.studs;
                        }
                        if (!isWatchStrapClaspDone && suggestions[i][property] && suggestions[i][property].strap && suggestions[i][property].strap.clasp && (mainProd[property].strap.clasp != suggestions[i][property].strap.clasp)) {
                            isWatchStrapClaspDone = true;
                            mainProd[property].strap.clasp = suggestions[i][property].strap.clasp;
                        }
                        if (!isWatchStrapWristPermimeterDone && suggestions[i][property] && suggestions[i][property].strap && suggestions[i][property].strap.wristPermimeter && (mainProd[property].strap.wristPermimeter != suggestions[i][property].strap.wristPermimeter)) {
                            isWatchStrapWristPermimeterDone = true;
                            mainProd[property].strap.wristPermimeter = suggestions[i][property].strap.wristPermimeter;
                        }

                    } else if (property == 'jewel') {
                        if (!mainProd[property]) mainProd.jewel = {};

                        if (!isJewelMaterialDone && suggestions[i][property] && suggestions[i][property].material && (mainProd[property].material != suggestions[i][property].material)) {
                            isJewelMaterialDone = true;
                            mainProd[property].material = suggestions[i][property].material;
                        }

                        if (!isJewelColorDone && suggestions[i][property] && suggestions[i][property].color && (mainProd[property].color != suggestions[i][property].color)) {
                            isJewelColorDone = true;
                            mainProd[property].color = suggestions[i][property].color;
                        }

                        if (!isJewelTypeDone && suggestions[i][property] && suggestions[i][property].type && (mainProd[property].type != suggestions[i][property].type)) {
                            isJewelTypeDone = true;
                            mainProd[property].type = suggestions[i][property].type;
                        }

                        if (!isJewelHeightDone && suggestions[i][property] && suggestions[i][property].height > 0 && (mainProd[property].height != suggestions[i][property].height)) {
                            isJewelHeightDone = true;
                            mainProd[property].height = suggestions[i][property].height;
                        }

                        if (!isJewelWidthDone && suggestions[i][property] && suggestions[i][property].width > 0 && (mainProd[property].width != suggestions[i][property].width)) {
                            isJewelWidthDone = true;
                            mainProd[property].width = suggestions[i][property].width;
                        }

                        if (!isJewelDepthDone && suggestions[i][property] && suggestions[i][property].depth > 0 && (mainProd[property].depth != suggestions[i][property].depth)) {
                            isJewelDepthDone = true;
                            mainProd[property].depth = suggestions[i][property].depth;
                        }

                        if (!isJewelDiameterDone && suggestions[i][property] && suggestions[i][property].diameter > 0 && (mainProd[property].diameter != suggestions[i][property].diameter)) {
                            isJewelDiameterDone = true;
                            mainProd[property].diameter = suggestions[i][property].diameter;
                        }

                        if (!isJewelWeightDone && suggestions[i][property] && suggestions[i][property].weight > 0 && (mainProd[property].weight != suggestions[i][property].weight)) {
                            isJewelWeightDone = true;
                            mainProd[property].weight = suggestions[i][property].weight;
                        }

                        if (!isJewelChainDone && suggestions[i][property] && suggestions[i][property].chain && (mainProd[property].chain != suggestions[i][property].chain)) {
                            isJewelChainDone = true;
                            mainProd[property].chain = suggestions[i][property].chain;
                        }

                        if (!isJewelClaspDone && suggestions[i][property] && suggestions[i][property].clasp && (mainProd[property].clasp != suggestions[i][property].clasp)) {
                            isJewelClaspDone = true;
                            mainProd[property].clasp = suggestions[i][property].clasp;
                        }

                        if (!isJewelShapeDone && suggestions[i][property] && suggestions[i][property].shape && (mainProd[property].shape != suggestions[i][property].shape)) {
                            isJewelShapeDone = true;
                            mainProd[property].shape = suggestions[i][property].shape;
                        }

                        if (!isJewelGlossDone && suggestions[i][property] && (suggestions[i][property].gloss == true || suggestions[i][property].gloss == false) && (mainProd[property].gloss != suggestions[i][property].gloss)) {
                            isJewelGlossDone = true;
                            mainProd[property].gloss = suggestions[i][property].gloss;
                        }

                    } else if (property == 'strap') {
                        if (!mainProd[property]) mainProd.strap = {};

                        if (!isStrapModelDone && suggestions[i][property] && suggestions[i][property].model && (mainProd[property].model != suggestions[i][property].model)) {
                            isStrapModelDone = true;
                            mainProd[property].model = suggestions[i][property].model;
                        }

                        if (!isStrapWidthDone && suggestions[i][property] && suggestions[i][property].width > 0 && (mainProd[property].width != suggestions[i][property].width)) {
                            isStrapWidthDone = true;
                            mainProd[property].width = suggestions[i][property].width;
                        }

                        if (!isStrapMaterialDone && suggestions[i][property] && suggestions[i][property].material && (mainProd[property].material != suggestions[i][property].material)) {
                            isStrapMaterialDone = true;
                            mainProd[property].material = suggestions[i][property].material;
                        }

                        if (!isStrapColorDone && suggestions[i][property] && suggestions[i][property].color && (mainProd[property].color != suggestions[i][property].color)) {
                            isStrapColorDone = true;
                            mainProd[property].color = suggestions[i][property].color;
                        }

                        if (!isStrapPrintDone && suggestions[i][property] && suggestions[i][property].print && (mainProd[property].print != suggestions[i][property].print)) {
                            isStrapPrintDone = true;
                            mainProd[property].print = suggestions[i][property].print;
                        }

                        if (!isStrapStudsDone && suggestions[i][property] && suggestions[i][property].studs && (mainProd[property].studs != suggestions[i][property].studs)) {
                            isStrapStudsDone = true;
                            mainProd[property].studs = suggestions[i][property].studs;
                        }

                        if (!isStrapClaspDone && suggestions[i][property] && suggestions[i][property].clasp && (mainProd[property].clasp != suggestions[i][property].clasp)) {
                            isStrapClaspDone = true;
                            mainProd[property].clasp = suggestions[i][property].clasp;
                        }

                        if (!isStrapWristPermimeterDone && suggestions[i][property] && (suggestions[i][property].wristPermimeter >= 0) && (mainProd[property].wristPermimeter != suggestions[i][property].wristPermimeter)) {
                            isStrapWristPermimeterDone = true;
                            mainProd[property].wristPermimeter = suggestions[i][property].wristPermimeter;
                        }
                    } else if ((property == 'male' || property == 'female' || property == 'kids') && !mainProd.male && !mainProd.female && !mainProd.kids && (suggestions[i].male || suggestions[i].female || suggestions[i].kids)) {
                        mainProd.male = suggestions[i].male;
                        mainProd.female = suggestions[i].female;
                        mainProd.kids = suggestions[i].kids;
                        break;
                    } else if (property == 'images') {
                        // Already doing in getProduct function
                        var tempImage = concateImages(mainProdImages, $scope.suggestions[i].images);
                        // console.log(i, tempImage);
                        suggImages = (JSON.parse(JSON.stringify(suggImages.concat(tempImage))));
                    } else if (property == 'brand' && (suggestions[i][property] && suggestions[i][property]._id) && (!mainProd[property] || !mainProd[property]._id || ((suggestions[i][property]._id).toString() != (mainProd[property]._id).toString()))) {
                        mainProd[property] = suggestions[i][property];
                        if (suggestions[i]['variants'][0].companyStratingDigits) {
                            mainProd['variants'][0].companyStratingDigits = suggestions[i]['variants'][0].companyStratingDigits;
                        }
                        if (suggestions[i]['variants'][0].sku) {
                            mainProd['variants'][0].sku = suggestions[i]['variants'][0].sku;
                        }
                        if (suggestions[i]['variants'][0].productNumber) {
                            mainProd['variants'][0].productNumber = suggestions[i]['variants'][0].productNumber;
                        }
                        if (!mainProd['variants'][0].brandStratingDigits && suggestions[i]['variants'][0].brandStratingDigits) {
                            mainProd['variants'][0].brandStratingDigits = suggestions[i]['variants'][0].brandStratingDigits;
                        }
                        break;
                    } else if (property == 'category' && suggestions[i][property] !== 'OTHER' && (mainProd[property] != suggestions[i][property])) {
                        mainProd[property] = suggestions[i][property];
                        break;
                    } else if (property == 'totalCaratWeight' && suggestions[i][property] >= 0 && isNumber(suggestions[i][property]) && (mainProd[property] != suggestions[i][property])) {
                        mainProd[property] = suggestions[i][property];
                        break;
                    } else if (property == 'weight' && suggestions[i][property] >= 0 && mainProd[property] != suggestions[i][property]) {
                        mainProd[property] = suggestions[i][property];
                        break;
                    } else if (property == 'size' && suggestions[i][property] >= 0 && isNumber(suggestions[i][property]) && mainProd[property] != suggestions[i][property]) {
                        mainProd[property] = suggestions[i][property];
                        break;
                    } else if (property == 'variants') {
                        if (!mainProd[property][0].ean && suggestions[i][property][0].ean) {
                            mainProd[property][0].ean = suggestions[i][property][0].ean;
                        }
                        break;
                    } else if (property == 'collections') {
                        var uniqueColl = collectionsForAutomaticProduct({ product: mainProd, suggestions: suggestions });
                        mainProd.collections = (JSON.parse(JSON.stringify(mainProd.collections.concat(uniqueColl))));
                    } else if (property == 'hasStock' && (suggestions[i][property] == true || suggestions[i][property] == false) && (mainProd[property] != suggestions[i][property])) {
                        mainProd[property] = suggestions[i][property];
                        break;
                    }
                }
            }
            mainProdImages = mainProdImages.concat(suggImages);
            mainProd.images = $scope.image = mainProdImages;

            console.log('FINALLY UPDATED PRODUCT: ----------------------------> ', JSON.parse(JSON.stringify(mainProd)));
            return mainProd;
        }

        function concateImages(mainProdImages, suggImages) {
            var uniqueImages = [];
            if (!mainProdImages || !mainProdImages.length) uniqueImages = suggImages;
            if (suggImages && suggImages.length) {
                for (var j = 0; j < suggImages.length; j++) {
                    var isExist = false;
                    for (var i = 0; i < mainProdImages.length; i++) {
                        if (mainProdImages[i].src && (mainProdImages[i].src == suggImages[j].src)) {
                            isExist = true;
                        }
                        if (!isExist && i == (mainProdImages.length - 1)) {
                            uniqueImages.push(suggImages[j]);
                        }
                    }
                }
            }

            return uniqueImages;
        }

        renderProduct();

        $scope.convertToUpperAndSpace = function (str) {
            // Convert To Upper and Space
            if (str) {
                str = str.toUpperCase()
                str = str.replace(' ', '_')
            }
            return str
        }

        $scope.getTaxForAdmin = function () {
            $tax.getTaxForAdmin()
                .then(function (tax) {
                    $scope.taxes = tax.data.rates
                })
                .catch(function (error) {
                    console.error('Error in getting Tax Rate')
                    console.error(error)
                })
        }

        $scope.getValueChangedIfSuggestionClick = function (sugvalue, prodfeildname) {

            if (prodfeildname == 'product.watch.strap.color') $scope.setStrapColorInitially(sugvalue)
            if (prodfeildname == 'product.watch.case.color') $scope.setCaseColorInitially(sugvalue)
            if (prodfeildname == 'product.watch.dial.color') $scope.setDialColorInitially(sugvalue)
            if (prodfeildname == 'product.jewel.color') $scope.setJewelColorInitially(sugvalue)
            // Get the model
            var model = $parse(prodfeildname)
            // Assigns a value to it
            model.assign($scope, sugvalue)
            if (prodfeildname == 'product.variants[0].productNumber') {
                if ($scope.product.brand) $scope.productOldBrand = JSON.parse(JSON.stringify($scope.product.brand));
                if ($scope.product.variants && $scope.product.variants.length && $scope.product.variants[0].productNumber) {
                    var index = $scope.product.variants[0].productNumber.indexOf('-');
                    // Here have only possible the brand starting digit
                    if (index > -1) {
                        $scope.product.variants[0].insertedProductNumber = $scope.product.variants[0].productNumber.slice(index + 1);
                        if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                            $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].insertedProductNumber));
                            $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                            $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
                        } else {
                            $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.variants[0].insertedProductNumber));
                            $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
                        }
                    } else {
                        if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                            $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                            $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].productNumber));
                            $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
                        } else {
                            $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
                        }
                    }
                } else {
                    // console.log(" Have in else")
                }
            }

            if (prodfeildname == 'product.variants[0].ean') $scope.eanIsExistsOrNot($scope.product.variants[0].ean)
        }

        $scope.getValueChangedIfSuggestionClickForIndex = function (sugvalue, prodfeildname, index) {
            var finalprodfeildname = prodfeildname.replace('$index', index)
            // Get the model
            var model = $parse(finalprodfeildname)
            // Assigns a value to it
            model.assign($scope, sugvalue)
        }

        // // Set The Values For the Key For Product

        $scope.setDialColor = function (color) {
            $scope.product.watch.dial.color = color.key
        }

        $scope.setCaseColor = function (color) {
            $scope.product.watch.case.color = color.key
        }

        $scope.setCaseColorInitially = function (color) {
            if (color == null || color == undefined || color == '') return

            color = color.toUpperCase()
            for (var i = 0; i < $scope.caseColors.length; i++) {
                if ($scope.caseColors[i].key == color) {
                    $scope.caseColor = $scope.caseColors[i]
                    $scope.setCaseColor(color)
                    break
                }
            }
            if (!$scope.caseColor) console.log('NO CASECOLOR MATCHED TO SET INITIALLY')
        }

        $scope.setDialColorInitially = function (color) {
            if (color == null || color == undefined || color == '') return
            for (var i = 0; i < $scope.dialColors.length; i++) {
                if ($scope.dialColors[i].key == color) {
                    $scope.dialColor = $scope.dialColors[i]
                    $scope.setDialColor(color)
                    break
                }
            }
        }

        $scope.setJewelColorInitially = function (color) {
            if (color == null || color == undefined || color == '') return
            for (var i = 0; i < $scope.jewelColors.length; i++) {
                if ($scope.jewelColors[i].key == color) {
                    $scope.jewelColor = $scope.jewelColors[i]
                    $scope.setJewelColor(color)
                    break
                }
            }
        }

        $scope.setJewelColor = function (color) {
            if ($scope.product.category == 'JEWEL') {
                if (!$scope.product.jewel) $scope.product.jewel = {}
                if (!$scope.product.jewel.color) $scope.product.jewel.color = color.key
            }
        }

        $scope.setStrapColor = function (color) {
            if ($scope.product.category == 'WATCH') {
                if (!$scope.product.watch.strap) $scope.product.watch.strap = {}
                $scope.product.watch.strap.color = color.key
            } else if ($scope.product.category == 'STRAP') {
                $scope.product.strap.color = color.key
            }
        }

        $scope.setStrapColorInitially = function (color) {
            if (color == null || color == undefined || color == '') return

            color = color.toUpperCase()
            for (var i = 0; i < $scope.strapColors.length; i++) {
                if ($scope.strapColors[i].key == color) {
                    $scope.strapColor = $scope.strapColors[i]
                    $scope.setStrapColor(color)
                    break
                }
            }
        }

        $scope.searchBrands = function (str) {
            var matches = []
            $scope.brands.forEach(function (brand) {
                if ((brand[$rootScope.language] != undefined && brand[$rootScope.language].name != undefined) && ((brand[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) || (brand[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0))) matches.push(brand)
            })
            return matches
        }

        $scope.brandSelected = function (selected) {


            if ($scope.product.brand) $scope.productOldBrand = JSON.parse(JSON.stringify($scope.product.brand));

            var brand = {
                _id: selected.originalObject._id,
                name: selected.title,
                nameSlug: selected.originalObject.en.nameSlug,
                description: selected.originalObject.description,
                images: selected.originalObject.images,
                isPoolArticleBrand: selected.originalObject.isPoolArticleBrand,
                startingDigits: (selected.originalObject.startingDigits && selected.originalObject.startingDigits != 'null' && selected.originalObject.startingDigits != 'undefined' && selected.originalObject.startingDigits != 'NULL') ? selected.originalObject.startingDigits : ''
            }

            $scope.product.brand = brand
            if ($scope.product.variants && $scope.product.variants.length && $scope.product.variants[0].productNumber) {
                var index = $scope.product.variants[0].productNumber.indexOf('-');
                // Here have only possible the brand stating digit
                if (index > -1) {
                    $scope.product.variants[0].insertedProductNumber = $scope.product.variants[0].productNumber.slice(index + 1);
                    if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                        $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].insertedProductNumber));
                        $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                        $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
                    } else {
                        $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.variants[0].insertedProductNumber));
                        $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
                    }
                } else {
                    if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                        $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                        $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].productNumber));
                        $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);

                    } else {
                        // console.log("No new Brands")
                    }
                }
            } else {
                // console.log(" Have in else")
            }
        }

        // Tab Selection
        $scope.tabSelected = function (index) {
            $rootScope.activeTabSuggestedProduct = index
            $rootScope.activeTabCurrentProduct = index
        }

        $scope.getClassForImages = function (src) {
            // Get Color For the images
            var p_image_length = $scope.product.images.length || 0
            var exists

            if (src && p_image_length) {
                for (var i = 0; i < p_image_length; i++) {
                    if ($scope.product.images[i].src && ($scope.product.images[i].src == src)) {
                        exists = true
                        i = p_image_length
                    }
                }
                if (exists) return ''
                else return 'orangeColorClass'
            } else if (src) {
                return 'redColorClass'
            } else { }
        }

        function changeImageIndex(currentIndex, newIndex) {
            var image1 = $scope.image[currentIndex]
            var image2 = $scope.image[newIndex]
            $scope.image[currentIndex] = image2
            $scope.image[newIndex] = image1
        }

        $scope.previousImage = function (currentIndex) {
            changeImageIndex(currentIndex, (currentIndex - 1))
        }

        $scope.nextImage = function (currentIndex) {
            changeImageIndex(currentIndex, (currentIndex + 1))
        }

        $scope.getTheValueChangedForImages = function (index) {
            var newImage = $scope.showProduct1.images[index]
            if (_.findIndex($scope.image, function (o) {
                return o.src == newImage.src
            }) < 0) {
                $scope.image.push($scope.showProduct1.images[index])
            }
        }

        $scope.getTheCollectionName = function (collection) {
            var collectionName = ''
            if (collection.hasOwnProperty($rootScope.language)) {
                collectionName = collection[$rootScope.language].name
            } else if (collection.hasOwnProperty('en.name')) {
                collectionName = collection.en.name
            } else if (collection.hasOwnProperty('nl.name')) {
                collectionName = collection.nl.name
            } else if (collection.hasOwnProperty('de.name')) {
                collectionName = collection.de.name
            } else if (collection.hasOwnProperty('fr.name')) {
                collectionName = collection.fr.name
            } else if (collection.hasOwnProperty('name') && collection.name != '') {
                collectionName = collection.name
            } else {
                collectionName = 'NO NAME'
            }
            return collectionName
        }

        $scope.getBrands = function () {
            $brand.getAllBrands()
                .then(function (brands) {
                    $scope.brands = brands.brands
                })
                .catch(function (error) {
                    console.error('Error in Getting Brands', error)
                })
        }

        $scope.updateCollection = function (type, index) {
            if (type == 'variantCollections') {
                var tempCollection = JSON.parse(JSON.stringify($scope.showProduct1.variantCollections[index]))
                $scope.product.variantCollections[index] = tempCollection
            } else {
                var tempCollection = JSON.parse(JSON.stringify($scope.showProduct1.brandCollections[index]))
                $scope.product.brandCollections[index] = tempCollection
            }
        }

        $scope.addProductImage = function () {
            if (!$scope.images) $scope.images = []
            $scope.images.push({})
        }

        $scope.removeProductImage = function (mode, index) {
            if (mode == 'scope') $scope.images.splice(index, 1)
            else $scope.product.images.splice(index, 1)
        }

        $scope.removeProductImage1 = function (index) {
            $scope.image.splice(index, 1);
        }

        $scope.updateProduct = function () {
            $product.updateProductByAdmin($scope.product)
                .then(function (updatedProduct) {
                    if ($scope.images && $scope.images.length > 0 && $scope.images[0].name) {
                        $scope.upload = true
                        Upload.upload({
                            url: 'api/product-image-upload',
                            data: {
                                productId: $scope.product._id,
                                files: $scope.images // file input field
                            }
                        })
                            .then(function (res) {
                                $state.go('admin.products')
                            })
                            .catch(function (err) {
                                $scope.alert = {
                                    type: 'danger',
                                    msg: 'upload error: ' + err
                                }
                            })
                    } else {
                        $state.go('admin.products')
                    }
                })
                .catch(function (error) {
                    console.error('Error! While Update Product By Admin', error)
                })
        }

        $scope.$watch('images', function (newVal, oldVal) {
            $productConfig.productIsValidForUpdate($scope.product, $scope.canUpdate, $scope.images)
                .then(function (canUpdate) {
                    $scope.canUpdate = canUpdate
                    $scope.changedStatus = true
                })
                .catch(function (error) {
                    $scope.canUpdate = {
                        EN: false,
                        DE: false,
                        NL: false,
                        FR: false,
                        ES: false
                    }
                    console.error('Error: Please Contact PrismaNote Admin')
                })
        }, true)

        $scope.$watch('product', function (newVal, oldVal) {

            $productConfig.productIsValidForUpdate($scope.product, $scope.canUpdate, $scope.images)
                .then(function (canUpdate) {
                    $scope.canUpdate = canUpdate
                    $scope.changedStatus = true
                })
                .catch(function (error) {
                    $scope.canUpdate = {
                        EN: false,
                        DE: false,
                        NL: false,
                        FR: false,
                        ES: false
                    }
                    console.error('Error: Please Contact PrismaNote Admin')
                })
        }, true)

        $scope.removeProductBrand = function () {
            if ($scope.product.brand) $scope.productOldBrand = JSON.parse(JSON.stringify($scope.product.brand));

            $scope.product.brand = {}
            if ($scope.product.variants && $scope.product.variants.length && $scope.product.variants[0].productNumber) {
                var index = $scope.product.variants[0].productNumber.indexOf('-');
                // Here have only possible the brand stating digit
                if (index > -1) {
                    $scope.product.variants[0].insertedProductNumber = $scope.product.variants[0].productNumber.slice(index + 1);
                    if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                        $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].insertedProductNumber));
                        $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                        $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
                    } else {
                        $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.variants[0].insertedProductNumber));
                        $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
                    }
                } else {
                    if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                        $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                        $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].productNumber));
                        $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);

                    } else {
                        // console.log("No new Brands")
                    }
                }
            } else {
                // console.log(" Have in else")
            }
        }

        $scope.removeProduct = function (productId) {
            prompt({
                title: $language.translate('DELETE_PRODUCT') + '?',
                message: "Are you sure you want to remove this product from 'PRISMANOTE'? The product will be removed in night time so your change will be effected for all retailers next morning.",
                buttons: [{
                    label: $language.translate('DELETE_PRODUCT'),
                    primary: true,
                    id: 0
                },
                {
                    label: $language.translate('CANCEL'),
                    primary: false,
                    cancel: true,
                    id: 2
                }
                ]
            })
                .then(function (value) {
                    if (value.id === 0) {
                        $admin.removeProductByAdmin({
                            productId: productId
                        })
                            .then(function (reomved) {
                                $state.go('admin.products')
                            })
                            .catch(function (error) {
                                console.error('Error While Delete Product')
                                console.log(error)
                            })
                    }
                })
                .catch(function (error) { })
        }

        $scope.eanIsExistsOrNot = function (ean) {
            console.log('eanIsExistsOrNot: ', ean, ean.length);
            // Enterd EAN number is valid or not
            if (!ean) ean = '';
            ean = ean.trim();
            $scope.product.variants[0].ean = ean;
            if (ean && ean.length < 5) {
                $scope.eanValid = false;
                return;
            }
            if (ean && ean.length) {
                $scope.eanValid = false
                $product.eanIsExistsOrNot(ean, $scope.product._id)
                    .then(function (result) {
                        if (result.status == true) $scope.eanValid = true
                    })
                    .catch(function (error) {
                        console.error('Error =>>> ', error)
                    })
            } else {
                $scope.eanValid = true
            }
        }

        $scope.productNumberIsExistsOrNot = function (productNumber) {
            $scope.PNvalid = false
            $scope.PNsort = true

            if (productNumber) {
                productNumber = $productConfig.retrunValidProductNumber(productNumber)
                if (productNumber && productNumber.length > 2) {
                    $scope.PNsort = false
                    // Product Number is valid Or not
                    $product.productNumberIsExistsOrNot(productNumber, $scope.product._id)
                        .then(function (result) {
                            if (result.status == true) {
                                $scope.PNvalid = true
                                $scope.product.variants[0].productNumber = productNumber
                            } else {
                                $scope.product.variants[0].productNumber = productNumber
                            }
                        })
                        .catch(function (error) {
                            console.error('Error =>>> ', error)
                        })
                }
            }
        }

        $scope.applySuggestionBrand = function () {
            if ($scope.product.brand) $scope.productOldBrand = JSON.parse(JSON.stringify($scope.product.brand));

            if ($scope.showProduct1.brand && $scope.showProduct1.brand._id) $scope.product.brand = JSON.parse(JSON.stringify($scope.showProduct1.brand))

            if ($scope.product.variants && $scope.product.variants.length && $scope.product.variants[0].productNumber) {
                var index = $scope.product.variants[0].productNumber.indexOf('-');
                // Here have only possible the brand stating digit
                if (index > -1) {
                    $scope.product.variants[0].insertedProductNumber = $scope.product.variants[0].productNumber.slice(index + 1);
                    if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                        $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].insertedProductNumber));
                        $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                        $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
                    } else {
                        $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.variants[0].insertedProductNumber));
                        $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
                    }
                } else {
                    if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                        $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                        $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].productNumber));
                        $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);

                    } else {
                        // console.log("No new Brands")
                    }
                }
            } else {
                // console.log(" Have in else")
            }
        }

        // Previous button of the suggestion shown
        $scope.prevButton = function () {
            var nextBtn = $("#next");
            nextBtn.prop("disabled", false);
            var currentFirstIndex = $scope.localdata.currentFirstIndex;
            var currentLastIndex = $scope.localdata.currentLastIndex;

            if (currentFirstIndex > 0) {
                $scope.suggestions[currentFirstIndex].isActive = false;
                currentFirstIndex = currentFirstIndex - $scope.localdata.productToShow;
                $scope.localdata.currentFirstIndex = currentFirstIndex;
                $scope.suggestions[currentFirstIndex].isActive = true;
                $scope.showProduct1 = $scope.suggestions[currentFirstIndex];
            }

            if (currentLastIndex > 1) {
                $scope.suggestions[currentLastIndex].isActive = false;
                currentLastIndex = currentLastIndex - $scope.localdata.productToShow;
                $scope.localdata.currentLastIndex = currentLastIndex;
                $scope.suggestions[currentLastIndex].isActive = true;
                $scope.showProduct2 = $scope.suggestions[currentLastIndex];
            }
            if (currentFirstIndex === 0) {
                $(this).prop("disabled", true);
            }
        }

        // Next button of the suggestion shown
        $scope.nextButton = function () {
            var prevBtn = $("#prev");
            prevBtn.prop("disabled", false);
            var currentFirstIndex = $scope.localdata.currentFirstIndex;
            var currentLastIndex = $scope.localdata.currentLastIndex;
            if (($scope.localdata.totalSuggestions - 1) == (currentLastIndex) + 1) {

                $scope.suggestions[currentFirstIndex].isActive = false;
                $scope.suggestions[currentLastIndex].isActive = false;

                currentFirstIndex = $scope.localdata.totalSuggestions - 2;
                currentLastIndex = $scope.localdata.totalSuggestions - 1;

                $scope.suggestions[currentFirstIndex].isActive = true;
                $scope.suggestions[currentLastIndex].isActive = true;

                $scope.showProduct1 = $scope.suggestions[currentFirstIndex];
                $scope.showProduct2 = $scope.suggestions[currentLastIndex];

            } else {

                if (currentFirstIndex < $scope.localdata.totalSuggestions - 1) {
                    $scope.suggestions[currentFirstIndex].isActive = false;
                    currentFirstIndex = currentFirstIndex + $scope.localdata.productToShow;
                    $scope.localdata.currentFirstIndex = currentFirstIndex;
                    $scope.suggestions[currentFirstIndex].isActive = true;
                    $scope.showProduct1 = $scope.suggestions[currentFirstIndex];
                }

                if (currentFirstIndex < $scope.localdata.totalSuggestions - 1) {
                    $scope.suggestions[currentLastIndex].isActive = false;
                    currentLastIndex = currentLastIndex + $scope.localdata.productToShow;
                    $scope.localdata.currentLastIndex = currentLastIndex;
                    $scope.suggestions[currentLastIndex].isActive = true;
                    $scope.showProduct2 = $scope.suggestions[currentLastIndex];
                }
            }

            if ((currentLastIndex === $scope.localdata.totalSuggestions - 1) || (currentFirstIndex === $scope.localdata.totalSuggestions - 2 && currentLastIndex === $scope.localdata.totalSuggestions - 1)) {
                var nextBtn = $("#next");
                nextBtn.prop("disabled", true);
            }
        }

        function updateAndLoadNextProduct() {
            var previousProductId = $scope.product._id;
            var body = {
                brandId: $scope.product && $scope.product.brand && $scope.product.brand._id ? $scope.product.brand._id : '',
                previousProductId: previousProductId
            }
            $product.fetchAdminProductForVerfiy(body).then(function (result) {
                if (result && result.data && result.data.data && result.data.data.product && result.data.data.product.nameSlug) {
                    var productDetail = result.data.data;
                    $state.go('admin.product-suggestion-verify', { nameSlug: productDetail.product.nameSlug });
                } else {
                    $state.go('admin.products');
                }
            }).catch(function (error) {
                console.error('Error =>>> ', error)
            })
        }

        $scope.updateAndLoadNextProduct = function () {
            var suggeIncVat = parseFloat($scope.product.suggestedRetailerPriceIncVat || 0);
            $scope.product.suggestedRetailPrice = suggeIncVat;
            $scope.product.suggestions = [];
            $scope.product.dateLastModified = new Date();
            if ($scope.product.variants[0].ean) $scope.product.variants[0].ean = $scope.product.variants[0].ean.trim();
            console.log('updateAndLoadNextProduct: ', $scope.product);
            // return;
            // HERE UPDATE PRODUCT CALLED THEN WE ARE GOING TO LOAD THE NEXT PRODUCT
            $product.updateProductByAdmin($scope.product).then(function (updatedProduct) {
                if ($scope.images && $scope.images.length > 0 && $scope.images[0].name) {
                    $scope.upload = true
                    Upload.upload({
                        url: 'api/product-image-upload',
                        data: {
                            productId: $scope.product._id,
                            files: $scope.images // file input field
                        }
                    }).then(function (res) {
                        $state.go('admin.products')
                    }).catch(function (err) {
                        $scope.alert = {
                            type: 'danger',
                            msg: 'upload error: ' + err
                        }
                    })
                } else {
                    $state.go('admin.products')
                }
            }).catch(function (error) {
                console.error('Error! While Update Product By Admin', error)
            })

        }

        $scope.getTheSuggestedIncVat = function (suggPrice, suggPriceVat) {
            // return suggPrice * (1 + (suggPriceVat / 100))
        }

        $scope.initTheSuggestedIncVat = function (suggPrice, suggPriceVat) {
            // $scope.product.suggestedRetailPrice = suggPrice * (1 + (suggPriceVat / 100));
        }
    }])