prismanoteApp.controller('brandServiceItemController', ['$scope', '$rootScope', '$api', '$company', '$stateParams', '$state', '$language',
  function ($scope, $rootScope, $api, $company, $stateParams, $state, $language) {
    $scope.isEsitmatedCostChanged = false
    $scope.isDateIsNotReachedByCompany = false
    $scope.loading = false

    // try {
    //   console.log('company:' + $stateParams.id + ':shop:added:comment:serviceitem')
    //   SocketService.on('company:' + $stateParams.id + ':shop:added:comment:serviceitem', function (data) {
    //     console.log('shop comment', data)
    //     if ($scope.transaction && $scope.transaction.remarks && $scope.transaction.remarks.remarks && $scope.transaction.remarks.remarks.length && $scope.transaction.remarks.remarks[0]) {
    //       $scope.transaction.remarks.remarks.push(data)
    //       $scope.autoScrollcomment()
    //     } else {
    //       $scope.transaction = {
    //         remarks: {
    //           remarks: [data]
    //         }
    //       }
    //     }
    //   })
    // } catch (catcherr) {
    //   console.log('catcherr: ', catcherr)
    // }

    $scope.getRepair = function () {
      $scope.loading = true
      $api.get('transactions', {
        id: $stateParams.id,
        includeShop: true,
        returnFirst: true,
        currentCompanyId: $rootScope.currentCompany._id
      })
        .then(function (response) {
          $scope.loading = false
          // console.log('response: ', response);
          $scope.transaction = response.data.transaction
          if ($scope.transaction.company && $scope.transaction.company.estimatedDate) {
            $scope.transaction.company.estimatedDate = new Date($scope.transaction.company.estimatedDate)
            $scope.currentCompanyExpectedDeliveryDate = $scope.transaction.company.estimatedDate
          }
          // console.log('currentCompanyExpectedDeliveryDate: ', $scope.currentCompanyExpectedDeliveryDate);
          if ($scope.transaction.company && $scope.transaction.company.status) {
            var currentstatus = $scope.transaction.company.status
            if (currentstatus == 'cancelled') {
              $scope.mystatus = 2
            } else if (currentstatus == 'inspection') {
              $scope.mystatus = 3
            } else if (currentstatus == 'completed') {
              $scope.mystatus = 4
            } else if (currentstatus == 'info') {
              $scope.mystatus = 5
            } else {
              currentstatus = 'processing'
              $scope.mystatus = 1
            }
          }
          $scope.shop = response.data.shop
        })
        .catch(function (reason) {
          $scope.loading = false
          if (reason.status === 404 || reason === 'NO_TRANSACTION_FOUND') {
            // console.log("reason----------------------->", reason);
            $state.go('brand.services')
          }
        })
    }

    $scope.copyDate = function () {
      if (!$scope.transaction.company) {
        $scope.transaction.company = {}
      }
      $scope.transaction.company.estimatedDate = new Date(angular.copy($scope.transaction[$scope.transaction.type].estimatedDate))
    }

    $scope.copyPrice = function () {
      if (!$scope.transaction.company) {
        $scope.transaction.company = {}
      }
      $scope.transaction.company.estimatedPrice = angular.copy($scope.transaction[$scope.transaction.type].externalPrice)
    }

    // This function is for logged only. Not required for anything
    $scope.changeTheStaus = function () {
      // cancelled, inspection, completed, processing
      var fromStatus = 'processing'
      if ($scope.mystatus == 1) fromStatus = 'processing'
      else if ($scope.mystatus == 2) fromStatus = 'cancelled'
      else if ($scope.mystatus == 3) fromStatus = 'inspection'
      else if ($scope.mystatus == 4) fromStatus = 'completed'
      else if ($scope.mystatus == 5) fromStatus = 'info'
      console.log('status: -', fromStatus, $scope.transaction.company.status)
      if (fromStatus !== $scope.transaction.company.status) {
        $scope.transaction.isChangedStatus = true
      } else {
        $scope.transaction.isChangedStatus = false
      }
      if (fromStatus == $scope.transaction.company.status) return

      if (!$scope.transaction.log) $scope.transaction.log = []

      var log = {
        date: new Date(),
        user: $rootScope.user._id,
        text: 'Status changed from ' + fromStatus + ' to ' + $scope.transaction.company.status,
        isFor: 'supplierPortal',
        eType: 'supplier'
      }
      $scope.transaction.log.push(log)

      if ($scope.transaction.company.status == 'info') {
        var retailerlog = {
          date: new Date(),
          user: $rootScope.user._id,
          text: 'Request for more information',
          isFor: 'retailerPortal',
          eType: 'supplier'
        }
        $scope.transaction.log.push(retailerlog)
        var supplierlog = {
          date: new Date(),
          user: $rootScope.user._id,
          text: 'Request for more information',
          isFor: 'supplierPortal',
          eType: 'supplier'
        }
        $scope.transaction.log.push(supplierlog)
      }

      if ($scope.transaction.company.status == 'processing') {
        $scope.mystatus = 1
        $scope.transaction.log.push({
          date: new Date(),
          user: $rootScope.user._id,
          text: 'Notification of Processing',
          isFor: 'retailerPortal',
          eType: 'supplier'
        })
      } else if ($scope.transaction.company.status == 'cancelled') {
        $scope.mystatus = 2
      } else if ($scope.transaction.company.status == 'inspection') {
        $scope.mystatus = 3
      } else if ($scope.transaction.company.status == 'completed') {
        $scope.mystatus = 4
        $scope.transaction.log.push({
          date: new Date(),
          user: $rootScope.user._id,
          text: 'Notification that order is shipped',
          isFor: 'retailerPortal',
          eType: 'supplier'
        })
      } else if ($scope.transaction.company.status == 'info') {
        $scope.mystatus = 5
      }
    }

    $scope.save = function () {
      console.log('save')
      sendNotificationToRetailerAboutDueDateAndHighPrice()
      $scope.changeTheStaus()
      $scope.transaction.log.push({
        date: new Date(),
        user: $rootScope.user._id,
        text: 'Updated expected Delivery date',
        isFor: 'supplierPortal',
        eType: 'supplier'
      })
      $scope.transaction.isCompanyRepliedAfterProcessingMode = true
      $scope.transaction.isFeedBackRequiredFromCompany = false
      $scope.transaction.language = $rootScope.language ? $rootScope.language : 'en'
      var body = JSON.parse(JSON.stringify($scope.transaction)) // We have to send notification if price or date changed due JS reference its value not changed
      $scope.isEsitmatedCostChanged = false
      $scope.isDateIsNotReachedByCompany = false
      $scope.transaction.isChangedStatus = false
      $scope.transaction.isDateIsNotReachedByCompany = false
      $scope.transaction.isPriceNotification = false
      // console.log('BODY: ', body);
      $api.put('transactions', {
        transaction: body,
        sendMailToShop: true
      })
        .then(function (response) {
          console.log('response', response)
        })
        .catch(function (reason) {
          console.error('Error while updating transaction', reason)
        })
    }

    $scope.openShopDetail = function (nameSlug) {
      console.log(nameSlug)
      $state.go('brand.shop-detail', { nameSlug: nameSlug })
    }

    $scope.AddComment = function (comment) {
      if (comment) {
        var body = {
          transactionId: $scope.transaction._id,
          // shopSlug: $scope.transaction.shopId.nameSlug,
          // companySlug: $scope.transaction.supplier.nameSlug,
          remarks: {
            eType: 'company',
            remark: comment,
            companyId: $scope.transaction.supplier
          },
          companyId: $rootScope.currentCompany._id
        }

        $api.post('add-comment-service-retailer-and-company', body)
          .then(function (response) {
            $scope.currentremark = ''
            $scope.getRepair()
          })
          .catch(function (err) {
            console.log(err)
            $scope.currentremark = ''
          })
      }
    }

    $scope.EditCommentMode = function (remark, index) {
      for (var i = 0; i < $scope.transaction.remarks.remarks.length; i++) {
        $scope.transaction.remarks.remarks[i].isEditMode = false
      }
      $scope.transaction.remarks.remarks[index].isEditMode = true
      $scope.editRemarkDetail = remark
    }

    $scope.UpdateComment = function (comment, index) {
      console.log('UpdateComment: ', comment)
      if (comment) {
        $scope.editRemarkDetail.remark = comment
        $scope.transaction.remarks.remarks[index].isEditMode = false
        // console.log('$scope.editRemarkDetail: ', $scope.editRemarkDetail);
        var body = {
          transactionId: $scope.transaction._id,
          commentId: $scope.editRemarkDetail._id,
          remark: comment,
          companyId: $rootScope.currentCompany._id
        }

        $api.post('edit-comment-service-retailer-and-company', body)
          .then(function (response) {
            $scope.editRemarkDetail = ''
            $scope.getRepair()
          })
          .catch(function (err) {
            $scope.editRemarkDetail = ''
            console.log(err)
          })
      } else {
        $scope.editRemarkDetail = ''
        $scope.transaction.remarks.remarks[index].isEditMode = false
      }
    }

    // Sending notification to Retailer if expected delivery date and price not satisfied by the company
    function sendNotificationToRetailerAboutDueDateAndHighPrice () {
      try {
        if ($scope.transaction && $scope.transaction.type && $scope.transaction[$scope.transaction.type]) {
          console.log('sendNotificationToRetailerAboutDueDateAndHighPrice called', $scope.transaction)
          console.log($scope.isEsitmatedCostChanged, $scope.isDateIsNotReachedByCompany)
          console.log('-----------------------------START---------------------------------')
          if ($scope.isEsitmatedCostChanged && $scope.transaction[$scope.transaction.type].externalPrice !== undefined && $scope.transaction.company.estimatedPrice !== undefined) {
            console.log('price: ', $scope.transaction[$scope.transaction.type].externalPrice)
            console.log('company estim price: ', $scope.transaction.company)
            var diffPrice = (+$scope.transaction.company.estimatedPrice) - (+$scope.transaction[$scope.transaction.type].externalPrice)
            if (diffPrice > 0) {
              $scope.transaction.isPriceNotification = true
              $scope.transaction.increasedPrice = diffPrice
            }
          }

          if ($scope.isDateIsNotReachedByCompany && $scope.transaction[$scope.transaction.type].estimatedDate !== undefined && $scope.transaction.company.estimatedDate !== undefined) {
            console.log('estimated date: ', $scope.transaction[$scope.transaction.type].estimatedDate)
            console.log('company estim date: ', $scope.transaction.company)

            var retailerDeliveryDate = new Date($scope.transaction[$scope.transaction.type].estimatedDate).getTime()
            var companyDeliveryDate = new Date($scope.transaction.company.estimatedDate).getTime()
            var diffSec = companyDeliveryDate - retailerDeliveryDate
            console.log(diffSec)
            if (diffSec > 0) {
              $scope.transaction.isDateIsNotReachedByCompany = true
              $scope.transaction.delayDays = Math.round(diffSec / 86400000) // 86400000 -> 1 day
            }
          }

          $scope.isEsitmatedCostChanged = false
          $scope.isDateIsNotReachedByCompany = false
          console.log('-----------------------------END---------------------------------')
        }
      } catch (error) {
        console.log('catcherr: ', error)
      }
    }

    // Whenever adding comment to the then we have add new line when pressed (SHIFT + ENTER) and if only enter then add Comment
    $scope.addCommentKeyUp = function (event, currentremark) {
      if (event.keyCode === 13) {
        if (event.shiftKey) return false
        else $scope.AddComment(currentremark)
      }
    }

    $scope.autoScrollcomment = function () {
      setTimeout(function () {
        $('#messagewindow').animate({ scrollTop: $('#messagewindow')[0].scrollHeight }, 0)
      }, 200)
    }
  }])
