prismanoteApp.controller('adminCompanyController', ['$scope', '$rootScope', '$parse', '$api', '$stateParams', '$uibModal', '$state', 'prompt', 'Upload', '$purchaseOrder', '$brand', '$admin', '$label', '$printnode',
  function ($scope, $rootScope, $parse, $api, $stateParams, $uibModal, $state, prompt, Upload, $purchaseOrder, $brand, $admin, $label, $printnode) {
    $scope.totalDisplayed = 20
    $scope.isVerified = true
    $scope.isNotVerified = true
    $scope.companyFilter = ''
    $scope.language = $rootScope.language
    $scope.notEditedCompanyValue = {}
    $scope.suppcodedetail = ''
    $scope.notifyUsers = false
    $scope.exampleData = $label.getExampleDataObject()
    $scope.previewUrl = 'https://prismanote.com/public/prismanote.png'
    $scope.jsonOptions = {
      mode: 'tree',
      modes: ['code', 'tree', 'text']
    }

    $scope.verificationsChanged = function () {
      var status = 'showAll';
      if ($scope.isNotVerified === $scope.isVerified ) {
        status = 'showAll'
      } else {
        if($scope.isNotVerified){ status = false; }
        if($scope.isVerified){ status = true; }
      }

      var params = {
        filter: {
          isVerified: status,
					isDeleted: 0
        }
      }
      $scope.getCompanyList(params)
    }

    $scope.convertDate = function (date) {
      var d = new Date(date)
      return d.toLocaleString()
    }

    $scope.getCompanyList = function (params) {
      $api.get('companies', params)
        .then(function (response) {
          $scope.loading = false
          $scope.notVerifiedCompanyCount = response.data.notVerifiedCompanyCount;
          $scope.companies = response.data.companies;
          // if(params='false'){
          $scope.alert = {
            type: 'danger',
            msg: 'There are '+ $scope.notVerifiedCompanyCount +' companies need to be verified today'
        }
        })

        .catch(function (reason) {
          console.error(reason)
        })
    }

    $scope.closeAlert = function () {
      $scope.alert = null
    }
    
    $scope.resetSelected = function () {
      $scope.currentSocialMedia = null
      $scope.currentHome = null
      $scope.currentSlide = null
    }

    $scope.loadMore = function () {
      $scope.totalDisplayed += 20
    }

    $scope.getCompanyLabels = function (readOnly) {
      $label.getCompanyLabels($scope.company._id, null, readOnly)
        .then(function(labels){
          if(readOnly) {
            $scope.readOnlyLabels = labels
          } else {
            $scope.labels = labels
          }
        })
        .catch(function (reason){
          console.error('Error while fetching labels voor shop', reason)
        })
    }

    $scope.loadCompany = function () {
      $api.get('companies/' + $stateParams.nameSlug)
        .then(function (response) {
          $scope.company = response.data.company

          if($scope.company && $scope.company.printNode) {
            $scope.company.jspmVersion = $scope.company.jspmVersion.toString()
          }

          $scope.notEditedCompanyValue = JSON.parse(JSON.stringify(response.data.company)) // For checking that admin edit company then we should send email to retailer about this

          $scope.getCompanyLabels()
          $scope.getCompanyLabels(true)

        })
        .catch(function (reason) {
          console.error(reason)
        })
    }

    $scope.newLabel = function () {
      $scope.template = {}
    }

    $scope.editLabel = function(index) {
      $scope.template =$scope.labels[index]
      $scope.updateLabelPreview($scope.template, $scope.exampleData)
    }

    $scope.copyLabel = function (index) {
      var template = angular.copy($scope.readOnlyLabels[index])
      delete template._id
      $scope.saveLabel(template)
    }

    $scope.saveLabel = function(template, string){
      if(string) {
        var label = JSON.parse(template)
      } else {
        var label = angular.copy(template)
      }
      if(!label || label === '') {
        console.error('No label contents')
        return
      }
      if (!label.companyId) {
        label.companyId = $scope.company._id
      }
      label.readOnly = false

      $label.createLabel(label)
        .then(function (res) {
          $scope.getCompanyLabels()
          $scope.getCompanyLabels(true)
          $scope.templateString = ''
        })
        .catch(function(reason){
          console.error('Error while creating new label', reason)
        })
    }

    $scope.deleteLabel = function(id){
      $label.deleteLabel(id)
        .then(function (res) {
          $scope.getCompanyLabels()
          $scope.getCompanyLabels(true)
        })
        .catch(function (reason) {
          console.error('Error while deleting label', reason)
        })
    }

    $scope.updateLabelPreview = function(template, exampleData) {
      $label.makeLabel(angular.copy(template), angular.copy(exampleData))
        .then(function(label){
          $scope.previewUrl = label.previewUrl
        })
        .catch(function(reason){
          console.error('Error when making label', reason)
        })
    }

    $scope.updateCompany = function (redirect) {

      if($scope.company && $scope.company.jspmVersion) {
        $scope.company.jspmVersion = parseFloat($scope.company.jspmVersion)
      }

      $api.put('companies/' + $stateParams.nameSlug, { company: $scope.company })
        .then(function (response) {
          if (redirect) {
            $state.go('admin.companies')
          } else {
            $scope.alert = {
              type: 'success',
              msg: response.data.message
            }
          }
        })

        .catch(function (reason) {
          console.error(reason)
          $scope.alert = {
            type: 'danger',
            msg: reason
          }
        })
    }

    $scope.getBrands = function () {
      $api.get('brands?getAll=true')
        .then(function (response) {
          $scope.brands = response.data.brands
        })

        .catch(function (reason) {
          console.error(reason)
        })
    }

    $scope.searchBrands = function (str) {
      var matches = []

      $scope.brands.forEach(function (brand) {
        if (
          (brand[$scope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
          (brand[$scope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)
        ) {
          matches.push(brand)
        }
      })
      return matches
    }

    $scope.brandSelected = function (selected) {
      var brand = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug,
        description: selected.originalObject.description,
        images: selected.originalObject.images
      }
      $scope.company.brands.push(brand)
    }

    $scope.getAllTheCompanies = function() {
      $brand.getAllTheCompanies().then(function(response) {
        $scope.companies = [];
        if (response && response.data && response.data.data && response.data.data.length) {
          $scope.companies = response.data.data;
        }
      }).catch(function(error) {
        console.error('getAllTheCompanies error: ', error);
      });
    }

    $scope.deleteBrand = function(index) {
      $purchaseOrder.openSearchSupplierModal($scope.companies).then(function (selectedCompany) {
        var body = {
          oldSupplierId: $scope.company._id,
          newSupplierId: selectedCompany._id,
          brandId: $scope.company.brands[index]._id._id
        }
        $admin.changeFavSupplierInAllShopBrands(body).then(function(response) {
          $scope.company.brands.splice(index, 1);
        }).catch(function(error) {
          console.error('search supplier error: ', error);
        });
      }).catch(function(error) {
        console.error('search supplier error: ', error);
      });
    }

    // OLD ONE
    // $scope.deleteBrand = function (index) {
    //   prompt({
    //     title: 'Merk verwijderen?',
    //     message: 'Weet u zeker dat u dit merk van deze winkel wilt verwijderen?'
    //   }).then(function () {
    //     $scope.company.brands.splice(index, 1)
    //     $scope.resetSelected()
    //   })
    // }

    $scope.openCreateCompanyModal = function () {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/create-company-modal.html',
        controller: 'createCompanyModalController',
        size: 'lg'
      })

      modalInstance.result.then(function (result) {
        $api.post('companies', { company: result })
          .then(function (response) {
            $scope.alert = null
            $scope.getCompanyList()
          })
          .catch(function (reason) {
            console.error(reason)
            $scope.alert = {
              type: 'danger',
              msg: reason
            }
          })
      }, function () {

      })
    }

    $scope.removeCompany = function () {
      prompt({
        title: 'Distribiteur verwijderen?',
        message: 'Weet u zeker dat u deze distribiteur wilt verwijderen?'
      }).then(function () {
        $api.delete('companies/' + $stateParams.nameSlug)
          .then(function (response) {

          })
          .catch(function (reason) {
            $scope.alert = {
              type: 'danger',
              msg: reason
            }
          })
        if (!$scope.alert) {
          $state.go('admin.companies')
        }
      })
    }

    $scope.showCompanyChange = function (obj) {
      $scope.showCompany = obj
    }

    $scope.getClassForSuggestion = function (sug, prod) {
      var colorCode
      if ((sug == undefined || sug == '') && (prod == undefined || prod == '')) {
        colorCode = ' '
      } else if ((sug == undefined || sug == '') && (prod != undefined || prod != '')) {
        colorCode = 'redColorClass'
      } else if ((prod == undefined || prod == '') && (sug != undefined || sug != '')) {
        colorCode = 'greenColorClass'
      } else if (sug != prod) {
        colorCode = 'orangeColorClass'
      } else if (sug == prod) {
        colorCode = ' '
      }
      return colorCode
    }
    function bothAreDifferent(p, sp) {
      // if (p.length != sp.length) {
      //     return true;
      // }
      $scope.presentInOne = []
      $scope.presentInBoth = []
      var temp = innerCall(p, sp)
      return temp
    }

    function innerCall(p, sp) {
      var indexToBeSpliced = []
      var indexToBeSplicedInSp = []
      for (var i = 0; i < p.length; i++) {
        for (var j = 0; j < sp.length; j++) {
          if (p[i].name == sp[j].name && p[i]._id == sp[j]._id) {
            // remove from p and sp and dd in other array;
            if (!(indexToBeSpliced.includes(i))) {
              indexToBeSpliced.push(i)
            }
            if (!(indexToBeSplicedInSp.includes(j))) {
              indexToBeSplicedInSp.push(j)
            }
            if (!($scope.presentInBoth.includes(p[i]))) {
              $scope.presentInBoth.push(p[i])
            }
          }
        }
      }

      for (var k = 0; k < $scope.presentInBoth.length; k++) {
        var index = p.indexOf($scope.presentInBoth[k])
        p.splice(index, 1)
        var otherIndex = sp.indexOf($scope.presentInBoth[k])
        sp.splice(otherIndex, 1)
      }
      if (p.length == 0 && sp.length == 0) { // after splicing
        return false
      } else {
        for (var count = 0; count < sp.length; count++) {
          if (!($scope.presentInOne.includes(sp[count]))) {
            $scope.presentInOne.push(sp[count])
          }
        }
        for (var count = 0; count < p.length; count++) {
          if (!($scope.presentInOne.includes(p[count]))) {
            $scope.presentInOne.push(p[count])
          }
        }
        return true
      }
    }

    $scope.getClassForSuggestionOfBrand = function () {
      var p = []
      var sp = []

      for (var count = 0; count < $scope.showCompany.brands.length; count++) {
        sp.push($scope.showCompany.brands[count])
      }
      for (var count = 0; count < $scope.company.brands.length; count++) {
        p.push($scope.showCompany.brands[count])
      }

      var colorCode = ' '
      if (p.length == 0 && sp.length == 0) {
        colorCode = ' '
      } else if (p.length == 0) {
        colorCode = 'greenColorClass'
      } else if (sp.length == 0) {
        colorCode = 'redColorClass'
      } else if (bothAreDifferent(p, sp)) {
        colorCode = 'orangeColorClass'
      }

      return colorCode
    }

    $scope.getValueChangedIfBrandSuggestionClick = function () {
      for (var cnt = 0; cnt < $scope.presentInOne.length; cnt++) {
        if (!($scope.company.brands.includes($scope.presentInOne[cnt]))) {
          $scope.company.brands.push($scope.presentInOne[cnt])
        }
      }
    }

    $scope.getValueChangedIfSuggestionClick = function (sugvalue, companyfeildname) {
      // Get the model
      var model = $parse(companyfeildname)
      // Assigns a value to it
      model.assign($scope, sugvalue)
    }

    $scope.updateCompany = function () {
      $scope.loading = true
      $scope.company.sendEmail = checkAdminEditedSpecificValue()
      $scope.company.isVerified = true
      $api.put('companies/', { company: $scope.company, notifyUsers: $scope.notifyUsers })
        .then(function (response) {
          $scope.loading = false
          $scope.changedCompanyData = []
          $scope.isCompanyChnagedByAdmin = false
          $state.go('admin.companies')
        })
        .catch(function (reason) {
          console.error(reason)
          $scope.loading = false
        })
    }

    // Start BANK Functions
    $scope.addNewBankForm = function () {
      // Open the form for the add new bank
      $scope.editBank = false
      $scope.addBank = true
      if ($scope.company.bankdetails) $scope.bankIndex = $scope.company.bankdetails.length
      $scope.bankdetail = {}
      $scope.bankdetail.isVerified = true
    }

    $scope.pushBank = function () {
      // Add New BANK in company bankdetails
      if (!$scope.company.bankdetails) $scope.company.bankdetails = []
      $scope.company.bankdetails.push($scope.bankdetail)
      $scope.bankdetail = {}
      $scope.addBank = false
      $scope.bankSuggestionDetail = {}
      $scope.showSuggestionBank = false
    }

    $scope.cancelBank = function () {
      $scope.editBank = false
      $scope.addBank = false
      $scope.bankdetail = {}
    }

    $scope.deleteBank = function (index) {
      $scope.cancelBank()
      $scope.company.bankdetails.splice(index, 1)
    }

    $scope.openBank = function (index) {
      $scope.bankdetail = $scope.company.bankdetails[index]
      $scope.bankIndex = index
      $scope.addBank = false
      $scope.editBank = true
    }

    $scope.updateBank = function (index) {
      $scope.company.bankdetails[index] = $scope.bankdetail
      $scope.editBank = false
    }

    $scope.openSuggestionsBank = function (index) {
      $scope.bankSuggestionDetail = $scope.showCompany.bankdetails[index]
      $scope.bankSuggestionIndex = index
      $scope.showSuggestionBank = true
    }
    // End Banks Functions

    function checkAdminEditedSpecificValue() {
      // pvc, vatnumber, Name(more than 4 char), Email-address, Website, Telephone, Mobile, Bank (Not there), Bank Account number, bicCode, swiftCode, Address, HouseNumber, No Suffix Changes and ZipCode
      $scope.changedCompanyData = []
      $scope.isCompanyChnagedByAdmin = false

      if ($scope.notEditedCompanyValue.name && checkIfNameChangedWithMoreThan4Char($scope.notEditedCompanyValue.name, $scope.company.name)) {
        $scope.changedCompanyData.push('Your Company Name Changed from ' + $scope.notEditedCompanyValue.name + ' to ' + $scope.company.name)
        $scope.isCompanyChnagedByAdmin = true
      }

      if ($scope.notEditedCompanyValue.pvc && ($scope.notEditedCompanyValue.pvc != $scope.company.pvc)) {
        $scope.changedCompanyData.push('Your Chamber of commerce nr. Changed from ' + $scope.notEditedCompanyValue.pvc + ' to ' + $scope.company.pvc)
        $scope.isCompanyChnagedByAdmin = true
      }

      if ($scope.notEditedCompanyValue.vatNumber && ($scope.notEditedCompanyValue.vatNumber != $scope.company.vatNumber)) {
        $scope.changedCompanyData.push('Your Vat Number Changed from ' + $scope.notEditedCompanyValue.vatNumber + ' to ' + $scope.company.vatNumber)
        $scope.isCompanyChnagedByAdmin = true
      }

      if ($scope.notEditedCompanyValue.email && ($scope.notEditedCompanyValue.email != $scope.company.email)) {
        $scope.changedCompanyData.push('Your Email Changed from ' + $scope.notEditedCompanyValue.email + ' to ' + $scope.company.email)
        $scope.isCompanyChnagedByAdmin = true
      }

      if ($scope.notEditedCompanyValue.website && ($scope.notEditedCompanyValue.website != $scope.company.website)) {
        $scope.changedCompanyData.push('Your Website Changed from ' + $scope.notEditedCompanyValue.website + ' to ' + $scope.company.website)
        $scope.isCompanyChnagedByAdmin = true
      }

      if ($scope.notEditedCompanyValue.phone && $scope.notEditedCompanyValue.phone.mobilePhone && ($scope.notEditedCompanyValue.phone.mobilePhone != $scope.company.phone.mobilePhone)) {
        $scope.changedCompanyData.push('Your Mobile Phone Changed from ' + $scope.notEditedCompanyValue.phone.mobilePhone + ' to ' + $scope.company.phone.mobilePhone)
        $scope.isCompanyChnagedByAdmin = true
      }

      if ($scope.notEditedCompanyValue.phone && $scope.notEditedCompanyValue.phone.landLine && ($scope.notEditedCompanyValue.phone.landLine != $scope.company.phone.landLine)) {
        $scope.changedCompanyData.push('Your LandLine Changed from ' + $scope.notEditedCompanyValue.phone.landLine + ' to ' + $scope.company.phone.landLine)
        $scope.isCompanyChnagedByAdmin = true
      }

      if ($scope.notEditedCompanyValue.repairPhone && $scope.notEditedCompanyValue.repairPhone.mobilePhone && ($scope.notEditedCompanyValue.repairPhone.mobilePhone != $scope.company.repairPhone.mobilePhone)) {
        $scope.changedCompanyData.push('Your Repaired Mobile Phone Changed from ' + $scope.notEditedCompanyValue.repairPhone.mobilePhone + ' to ' + $scope.company.repairPhone.mobilePhone)
        $scope.isCompanyChnagedByAdmin = true
      }

      if ($scope.notEditedCompanyValue.repairPhone && $scope.notEditedCompanyValue.repairPhone.landLine && ($scope.notEditedCompanyValue.repairPhone.landLine != $scope.company.repairPhone.landLine)) {
        $scope.changedCompanyData.push('Your Repaired LandLine Changed from ' + $scope.notEditedCompanyValue.repairPhone.landLine + ' to ' + $scope.company.repairPhone.landLine)
        $scope.isCompanyChnagedByAdmin = true
      }

      if ($scope.notEditedCompanyValue.bankAccountNumber && ($scope.notEditedCompanyValue.bankAccountNumber != $scope.company.bankAccountNumber)) {
        $scope.changedCompanyData.push('Your Bank Account Number Changed from ' + $scope.notEditedCompanyValue.bankAccountNumber + ' to ' + $scope.company.bankAccountNumber)
        $scope.isCompanyChnagedByAdmin = true
      }

      if ($scope.notEditedCompanyValue.bicCode && ($scope.notEditedCompanyValue.bicCode != $scope.company.bicCode)) {
        $scope.changedCompanyData.push('Your BIC Code Changed from ' + $scope.notEditedCompanyValue.bicCode + ' to ' + $scope.company.bicCode)
        $scope.isCompanyChnagedByAdmin = true
      }

      if ($scope.notEditedCompanyValue.swiftCode && ($scope.notEditedCompanyValue.swiftCode != $scope.company.swiftCode)) {
        $scope.changedCompanyData.push('Your Swift Code Changed from ' + $scope.notEditedCompanyValue.swiftCode + ' to ' + $scope.company.swiftCode)
        $scope.isCompanyChnagedByAdmin = true
      }

      if ($scope.notEditedCompanyValue.address && $scope.notEditedCompanyValue.address.houseNumber && ($scope.notEditedCompanyValue.address.houseNumber != $scope.company.address.houseNumber)) {
        $scope.changedCompanyData.push('Your HouseNumber Changed from ' + $scope.notEditedCompanyValue.address.houseNumber + ' to ' + $scope.company.address.houseNumber)
        $scope.isCompanyChnagedByAdmin = true
      }

      if ($scope.notEditedCompanyValue.address && $scope.notEditedCompanyValue.address.postalCode && ($scope.notEditedCompanyValue.address.postalCode != $scope.company.address.postalCode)) {
        $scope.changedCompanyData.push('Your Postal Code Changed from ' + $scope.notEditedCompanyValue.address.postalCode + ' to ' + $scope.company.address.postalCode)
        $scope.isCompanyChnagedByAdmin = true
      }

      if ($scope.notEditedCompanyValue.brands && $scope.notEditedCompanyValue.brands.length) {
        if (!$scope.company.brands.length) {
          var brands = []
          for (var i = 0; i < $scope.notEditedCompanyValue.brands.length; i++) {
            brands.push($scope.notEditedCompanyValue.brands[i].name)
          }
          if (brands.length) {
            $scope.isCompanyChnagedByAdmin = true
            $scope.changedCompanyData.push('The brands which are deleted: ' + brands)
          }
        } else {
          var brands = []
          for (var i = 0; i < $scope.notEditedCompanyValue.brands.length; i++) {
            for (var j = 0; j < $scope.company.brands.length; j++) {
              if ($scope.notEditedCompanyValue.brands[i].nameSlug == $scope.company.brands[j].nameSlug) {
                break
              }

              if ((j + 1) == $scope.company.brands.length) {
                brands.push($scope.notEditedCompanyValue.brands[i].name)
              }
            }
          }
          if (brands.length) {
            $scope.isCompanyChnagedByAdmin = true
            $scope.changedCompanyData.push('The brands which are deleted: ' + brands)
          }
        }
      }

      var oldCompanyDetail = {
        email: $scope.notEditedCompanyValue.email,
        street: ($scope.notEditedCompanyValue.address && $scope.notEditedCompanyValue.address.street) ? $scope.notEditedCompanyValue.address.street : '',
        city: ($scope.notEditedCompanyValue.address && $scope.notEditedCompanyValue.address.city) ? $scope.notEditedCompanyValue.address.city : ''
      }
      return { changedCompanyData: $scope.changedCompanyData, isCompanyChnagedByAdmin: $scope.isCompanyChnagedByAdmin, oldCompanyDetail: oldCompanyDetail }
    }

    function checkIfNameChangedWithMoreThan4Char(originalName, editedName) {
      var originalNameLength = originalName.length
      var editedNameLength = editedName.length
      if ((originalNameLength - editedNameLength) > 4 || (editedNameLength - originalNameLength) > 4) return true
      var ArrayOfOriginalName = originalName.split('')
      var ArrayOfeditedName = editedName.split('')
      var Iterate = originalNameLength
      var counter = 0
      if (editedNameLength > originalNameLength) Iterate = editedNameLength
      for (var i = 0; i < Iterate; i++) {
        if (ArrayOfOriginalName[i] !== ArrayOfeditedName[i]) {
          counter++
        }
      }
      if (counter > 3) return true
      else return false
    }

    // Start Supplier Functions
    $scope.addNewSuppForm = function () {
      // Open the form for the add new bank
      $scope.editSuppCode = false
      $scope.addSuppCode = true
      $scope.suppcodedetail = ''
      if ($scope.company.EdiAbbreviations) $scope.suppcodeIndex = $scope.company.EdiAbbreviations.length
    }

    $scope.pushSuppCode = function (suppcodedetail) {
      // Add New BANK in company EdiAbbreviations
      if (!$scope.company.EdiAbbreviations) $scope.company.EdiAbbreviations = []
      $scope.company.EdiAbbreviations.push(suppcodedetail)
      $scope.suppcodedetail = ''
      $scope.addSuppCode = false
    }

    $scope.cancelSuppCode = function () {
      $scope.editSuppCode = false
      $scope.addSuppCode = false
      $scope.suppcodedetail = ''
    }

    $scope.deleteSuppCode = function (index) {
      $scope.cancelBank()
      $scope.company.EdiAbbreviations.splice(index, 1)
    }

    $scope.openSuppCode = function (index) {
      $scope.suppcodedetail = $scope.company.EdiAbbreviations[index]
      $scope.suppcodeIndex = index
      $scope.addSuppCode = false
      $scope.editSuppCode = true
    }

    $scope.updateSuppCode = function (index, suppcodedetail) {
      $scope.company.EdiAbbreviations[index] = suppcodedetail
      $scope.editSuppCode = false
    }
    // End Supplier Functions

    $scope.MergeTheCompany = function () {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/admin-ask-to-merge-company-modal.html',
        controller: 'adminAskToMergeCompanyModalController',
        size: 'lg',
        resolve: {
          data: function () {
            return $scope.company
          }
        }
      })

      modalInstance.result.then(function (result) {
      }, function () {

      })
    }

    $scope.registerPrintNodeAccount = function () {
      $printnode.createChildAccount({
        companyId: $scope.company._id,
        isCompany: true,
        email: $scope.company.email,
        companyName: $scope.company.name
      })
        .then(function (result) {
          toastr.success(result.data.message)
          $scope.loadCompany()
        })
        .catch(function (reason) {
          toastr.error(reason)
        })
    }

    // }
  }])
