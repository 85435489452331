prismanoteApp.controller('brandController', ['$scope', '$state', '$rootScope', '$company', '$location', 'campaignFact', '$notification', '$chat',
  function ($scope, $state, $rootScope, $company, $location, campaignFact, $notification, $chat) {
    $rootScope.companySaved = false

    $scope.closeAlert = function () {
      $rootScope.companySaved = false
    }

    $scope.getCompany = function (nameSlug) {
      $rootScope.selectedCompany = nameSlug
      
      $company.getCompanyDetail(nameSlug)
        .then(function (shop) {
          $rootScope.currentCompany = shop
          $rootScope.openChats = []
          //$rootScope.socketInit()
          $scope.$broadcast('changeSelectedCompany', nameSlug)
          campaignFact.currentCompany = shop
          localStorage.setItem('currentCompany', $scope.currentCompany.nameSlug)
          $rootScope.unreadMessageNotifications = shop.messageNotification && shop.messageNotification.length ? shop.messageNotification.length : 0
        })
        .catch(function (reason) {
          console.log('reason: getCompany', reason)
        })
    }

    $scope.getCompanyCall = function (user) {
      if ($rootScope.user && $rootScope.user.companies && $rootScope.user.companies.length) {
        $scope.selectedShop = $rootScope.user.companies[0].nameSlug
        $scope.getCompany($rootScope.user.companies[0].nameSlug, { event: false })
      } else {
        console.log('No Company found')
      }
    }

    if ($rootScope.user && $rootScope.user.role == 'wholesaler' && $rootScope.user.companies && $rootScope.user.companies.length > 0) {
      var savedSlug = localStorage.getItem('currentCompany')

      $scope.getCompany(savedSlug || $rootScope.user.companies[0].nameSlug)
    }

    $scope.sendTestNotification = function () {
      $notification.sendTestNotificationToCompany()
    }

    $scope.openChatWindow = function (notification) {
      if ($chat.isChatAlreadyOpen(notification.remarkId)) return

      var body = {
        eType: notification.eType,
        remarkId: notification.remarkId
      }
      $chat.getChatDetail(body).then(function (response) {
        if (response && response.data && response.data.data) {
          var chatresponse = response.data.data
          if (chatresponse.remarks && chatresponse.remarks.length && chatresponse.details) {
            $chat.addChatIntoRootScope(chatresponse)
          }
        }
      }).catch(function (error) {
        console.log('Error while fetching the chat detail ', error)
      })
    }
  }])
