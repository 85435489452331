prismanoteApp.controller('retailerCampaignController', ['$scope', '$rootScope', '$api', '$state', '$stateParams', '$uibModal', 'prompt', '$filter', '$window', 'campaignFact', '$interval', 'constantFact', 'Upload',
  function ($scope, $rootScope, $api, $state, $stateParams, $uibModal, prompt, $filter, $window, campaignFact, $interval, constantFact, Upload) {
    $scope.tasksEnabled = false
    $scope.startDate = false
    $scope.endDate = false

    $scope.pageFirstTimeChanged = false

    $scope.validation = {}
    $scope.urlPattern = /^(https?:\/\/)?[www]*\.[a-z0-9-]*\.?[a-z0-9-]+\.[a-z0-9-]+(\/[^<>]*)?$/
    $scope.todayDate = new Date()
    $scope.validation.customLocation = false
    var dateNow = new Date()
    var dateTomorrow = new Date()
    dateTomorrow.setDate(dateNow.getDate() + 1)
    $scope.task = {
      fbPromotionSettings: {
        promotionStartDate: dateNow,
        promotionEndDate: dateTomorrow
      }

    }
    $scope.fbPageArray = []
    for (var i = 0; i < $rootScope.user.facebook.facebookPages.length; i++) {
      $scope.fbPageArray.push($rootScope.user.facebook.facebookPages[i].name)
    }

    $scope.imgObjectiveArray = ['POST_ENGAGEMENT', 'EVENT_RESPONSES', 'LEAD_GENERATION', 'BRAND_AWARENESS', 'REACH', 'TRAFFIC', 'APP_INSTALLS', 'PAGE_LIKES']
    $scope.billingEventArray = ['APP_INSTALLS', 'CLICKS', 'IMPRESSIONS', 'LINK_CLICKS', 'OFFER_CLAIMS', 'PAGE_LIKES', 'POST_ENGAGEMENT', 'VIDEO_VIEWS', 'TWO_SECOND_CONTINUOUS_VIDEO_VIEWS', 'COMPLETED_VIDEO_VIEWS', 'VIDEO_VIEWS_15S']
    $scope.imgCallToActionArray = ['LEARN_MORE', 'DOWNLOAD', 'CALL_NOW', 'GET_DIRECTIONS', 'CONTACT_US', 'GET_SHOWTIMES', 'NO_BUTTON']
    $scope.withLocationAction = ['LEARN_MORE', 'DOWNLOAD', 'CALL_NOW', 'GET_DIRECTIONS', 'CONTACT_US', 'GET_SHOWTIMES', 'NO_BUTTON']
    $scope.withoutLocationAction = ['LEARN_MORE', 'DOWNLOAD', 'CALL_NOW', 'CONTACT_US', 'GET_SHOWTIMES', 'NO_BUTTON']
    $scope.getLikeArray = ['today', 'yesterday', 'last_3d', 'last_7d', 'last_14d', 'last_28d', 'last_30d', 'last_90d', 'this_month', 'last_month', 'last_year', 'this_year']
    $scope.gender = [{ value: 0, name: 'All' }, { value: 1, name: 'Male' }, { value: 2, name: 'Female' }]
    $scope.optimizationArray = [{ name: 'BRAND_AWARENESS', goal: 'AD_RECALL_LIFT' }, { name: 'APP_INSTALLS', goal: 'APP_INSTALLS' }, { name: 'VIDEO_VIEWS', goal: 'VIDEO_VIEWS' }]
    $scope.userLocation = true
    $scope.myImage = []
    $rootScope.doneImgModel = function (index, cropped) {
      $scope.myImage[index] = cropped
    }

    $scope.toTab0 = function () {
      $scope.activeTab = 0
    }
    $scope.activeTab = 0
    $scope.ImageHashArray = []
    $scope.toTab1 = function (data, isStore) {
      $scope.activeTab = 1
      if (isStore == 'store-promotion') {
        if ($scope.allImgData.length >= 1) {
          $scope.allImgData = $scope.allImgData.filter(function (val) {
            return val._id == undefined
          })
          var iflag = 0
          callAPI($scope.allImgData[iflag], $rootScope.adminmetaData.accountId, function success (res) {
            var nextImg = $scope.allImgData[++iflag]
            if (nextImg) {
              callAPI($scope.allImgData[iflag], $rootScope.adminmetaData.accountId, success)
            }
          })
        }
      }
    }
    function callAPI (imgarr, account, callback) {
      var formdata = new FormData()
      formdata.append('file', imgarr)
      formdata.append('access_token', $rootScope.adminmetaData.access_token)
      campaignFact.uploadImg(formdata, account).then(function (res) {
        $scope.ImageHashArray.push(res.data.images[Object.keys(res.data.images)])
        callback()
      }, function (err) {
        console.error('Error in callAPI', err)
        callback()
      })
    }
    $scope.imgUrlFromCampaign = []
    $scope.targetResHash = []
    function getImgUrlFunc (imgUrl, callback) {
      campaignFact.getImgUrl(imgUrl).then(function (res) {
        $scope.imgUrlFromCampaign.push(res.data.Url)
        callback()
      }, function (err) {
        console.error('Error in getImgUrlFunc', err)
        callback()
      })
    }

    function uploadImgInFb (imgSrc, callback) {
      var imgtargetHash = {
        bytes: imgSrc
      }
      campaignFact.uploadImageByHash(imgtargetHash).then(function (res) {
        if (res.error) {
          callback()
        } else {
          $scope.targetResHash.push(res.images.bytes.hash)
          callback()
        }
      }, function (err) {
        $scope.storeSpinner = false
        console.error('Error while uploading', err)
      })
    }

    $scope.toTab2 = function (data, isStore) {
      $scope.activeTab = 2
    }
    $scope.selectedInterestData = []
    // Deprecated
    $scope.getCampaign = function () {
      return
      $scope.taskLanguage = $rootScope.language
      $api.get('user-social-portal', { userId: $scope.user._id })
        .then(function (response) {
          $scope.user.socialPortal = response.data.result
          $api.get('get-portal-campaign', { portalId: $scope.user.socialPortal._id, campaignSlug: $stateParams.nameSlug })
            .then(function (response) {
              $scope.campaign = response.data.campaign
              $scope.campaign.tasks.forEach(function (val, ind) {
                if (val.type == 'facebook') {
                  if ($scope.campaign.tasks[ind].nl) {
                    if ($scope.campaign.update = true) {
                      $scope.myDescription = $scope.campaign.description
                      $scope.campaign.description = ''
                    } else {
                      $scope.myDescription = $scope.campaign.tasks[ind].nl.description
                      $scope.campaign.tasks[ind].nl.description = ''
                    }
                  }
                }
              })
              if ($scope.campaign && $scope.campaign.tasks && $scope.campaign.tasks[0].fbPromotionSettings && $scope.campaign.tasks[0].fbPromotionSettings.interests && $scope.campaign.tasks[0].fbPromotionSettings.interests.length > 0) {
                $scope.campaign.tasks[0].fbPromotionSettings.interests.forEach(function (val) {
                  $scope.selectedInterestData.push(val.interestName)
                })
              }
              for (var i = 0; i < $scope.campaign.tasks.length; i++) {
                if ($scope.campaign.tasks[i].images.length > 0) {
                  if ($scope.campaign.tasks[i].type == 'facebook') {
                    var fbTaskIndex = i
                  }
                }
              }
              if ($scope.campaign.strategy == 'target-search') {
                var imgflag = 0
                getImgUrlFunc($scope.campaign.tasks[fbTaskIndex].images[imgflag].src, function success () {
                  var nextImgUrl = $scope.campaign.tasks[fbTaskIndex].images[++imgflag]
                  if (nextImgUrl) {
                    getImgUrlFunc($scope.campaign.tasks[fbTaskIndex].images[imgflag].src, success)
                  }
                })
              }

              if (campaignFact.updateCampaign.name != undefined) {
                $scope.campaign = campaignFact.updateCampaign
                // $scope.task = campaignFact.updateCampaign.tasks[0];
              }
            })
            .catch(function (reason) {
              console.error('Error while getting campaign portal', reason)
            })
        })
        .catch(function (reason) {
          console.error('Error while getting user portal', reason)
        })
    }
    $scope.$watch('campaign', function (newValue) {
      if (newValue != undefined) {
        campaignFact.updateCampaign = newValue
      } else {
      }
    })

    campaignFact.getAdminData().then(function (res) {
      $rootScope.adminmetaData = res
    })

    function findTaskById (taskId) {
      for (var i = 0; i < $scope.user.socialPortal.campaigns.length; i++) {
        if ($scope.user.socialPortal.campaigns[i]._id == $scope.campaign._id) {
          for (var t = 0; t < $scope.user.socialPortal.campaigns[i].tasks.length; t++) {
            if ($scope.user.socialPortal.campaigns[i].tasks[t]._id == taskId) {
              return $scope.user.socialPortal.campaigns[i].tasks[t]
            }
          }
        }
      }
    }

    var getItems = function (type) {
      $api.get(type, { shopId: $rootScope.currentShop._id})

        .then(function (response) {
          $scope[type] = response.data[type]
        })

        .catch(function (reason) {
          console.error('Error while getting items', reason)
        })
    }

    $scope.getBrands = function () {
      return getItems('brands')
    }

    $scope.getCompanies = function () {
      return getItems('companies')
    }

    var searchItems = function (str, type) {
      var matches = []
      $scope[type].forEach(function (item) {
        if (
          (item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
					(item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
          matches.push(item)
        }
      })

      return matches
    }

    $scope.searchBrands = function (str) {
      return searchItems(str, 'brands')
    }

    $scope.searchCompanies = function (str) {
      return searchItems(str, 'companies')
    }

    $scope.brandSelected = function (selected) {
      var brand = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug,
        description: selected.originalObject.description,
        images: selected.originalObject.images
      }

      $scope.campaign.brand = brand
    }

    $scope.companySelected = function (selected) {
      var company = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug,
        description: selected.originalObject.description,
        images: selected.originalObject.images
      }

      $scope.campaign.company = company
    }

    $scope.saveSocialPortal = function (mode) {
      if (mode && mode == true && $scope.user.socialPortal) {
        var campaignLength = $scope.user.socialPortal.campaigns.length

        angular.forEach($scope.user.socialPortal.campaigns, function (val, i) {
          if (val._id == $scope.campaign._id) {
            if ($scope.facebookTaskImages.length > 0) {
              Upload.upload({
                url: '/api/upload-promotion-images',
                data: {
                  files: $scope.facebookTaskImages
                }
              }).then(function (response) {
                $scope.campaign.images = []
                response.data.files.forEach(function (data) {
                  $scope.campaign.images.push({ alt: data.name, src: data.path })
                })
                $scope.user.socialPortal.campaigns[i] = $scope.campaign
                $scope.putSocialPortal()
              })
            }
          }
        })
      } else {
        $scope.putSocialPortal()
      }
    }

    // Deprecated
    $scope.putSocialPortal = function () {
      return
      $api.put('user-social-portal', { socialPortal: $scope.user.socialPortal })
        .then(function (response) {

        })
        .catch(function (reason) {
          console.error('Error while saving portal', reason)
        })
    }

    $scope.postToFacebook = function (taskId) {
      $scope.postResult = null
      var data = {
        campaignId: $scope.campaign._id,
        taskId: taskId
      }
      $api.post('facebook/post', { data: data })
        .then(function (response) {
          $scope.postResult = response.data.result

          $scope.postResult = {
            type: 'success',
            msg: "Bericht geplaatst! <a href='https://facebook.com/" + response.data.result.post_id + "' target='_blank'>Bekijk het bericht</a>"
          }
          $scope.index = taskId
        })
        .catch(function (reason) {
          $scope.testResult = {
            type: 'warning',
            msg: 'Fout tijdens plaatsen testbericht: ' + reason
          }
        })
    }
    var f = false
    $scope.perDayOrSpread = function (task) {
      if (task.fbPromotionSettings.budget != null || task.fbPromotionSettings.budget > 0) {
        $scope.budget = task.fbPromotionSettings.budget
        $rootScope.budgetPreview = $scope.budget
        $scope.maxDuration = parseInt($scope.budget / 0.524)
      } else {
        constantFact.alertMsg[8].msg
      }
    }

    $scope.openGlobalExecuteModal = function (campaign) {
      var data = {}
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/execute-campaign-modal.html',
        controller: 'executeCampaignModalController',
        size: 'lg',
        resolve: {
          data: function () {
            campaign.global = true
            data.campaign = campaign
            data.user = $scope.user
            return data
          }
        }
      })

      modalInstance.result.then(function (result) {
        if (result) {
        }
      }, function () {
        // dismissed
      })
    }

    $scope.initPromotionTaskDate = function (task) {
      if (task.fbPromotionSettings != undefined) {
        if (task.fbPromotionSettings.promotionStartDate == 'Invalid Date') {
          var date1 = new Date()
          var date2 = new Date()
          date1.setDate(date1.getDate() + 1)
          date2.setHours(date2.getHours() + 1)

          task.fbPromotionSettings.promotionStartDate = date2
          task.fbPromotionSettings.promotionEndDate = date1
        } else {
          task.fbPromotionSettings.promotionStartDate = new Date(task.fbPromotionSettings.promotionStartDate)
          task.fbPromotionSettings.promotionEndDate = new Date(task.fbPromotionSettings.promotionEndDate)
        }

        var date1 = task.fbPromotionSettings.promotionStartDate
        var date2 = task.fbPromotionSettings.promotionEndDate
        var timeDiff = Math.abs(date2.getTime() - date1.getTime())
        $rootScope.diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
        if ($rootScope.diffDays == 0 && $scope.campaign.update == false || $rootScope.diffDays < 0 && $scope.campaign.update == false) {
        } else {
          if ($scope.maxDuration < $rootScope.diffDays) {
            alert('You can only select ' + $scope.maxDuration + ' days!!!')
          } else {
            if (f == false) {
              $rootScope.budgetPreview = $scope.budget
            }
          }
        }
      } else {
      }
    }

    $scope.initUpdateTaskDate = function (task) {
      if (task.datePlanned != undefined) {
        task.datePlanned = new Date(task.datePlanned)
      } else {
      }
    }

    var imgNameArray = []

    $scope.filterCampaigns = function (campaign) {
      if (campaign.type === 'order' || campaign.type === 'facebook') {
        return false
      } else {
        return true
      }
    }

    $scope.date = new Date()

    $scope.back = function () {
      campaignFact.changeLanguage = {}
      $scope.saveSocialPortal(true)
      $state.go('retailer.campaigns')
    }

    $scope.addLogItem = function (item, save) {
      var logItem = {
        title: item.title,
        date: new Date(),
        type: item.type,
        taskId: item.taskId,
        user: item.user,
        campaignId: item.campaignId
      }
      $scope.user.socialPortal.log.push(logItem)

      if (save) {
        $scope.saveSocialPortal()
      }
    }

    $scope.deleteCampaign = function () {
      prompt({
        title: 'Campagne verwijderen',
        message: 'Weet u zeker dat u deze campagne wilt verwijderen?'
      }).then(function () {
        for (var i = 0; i < $scope.user.socialPortal.campaigns.length; i++) {
          if ($scope.user.socialPortal.campaigns[i]._id == $scope.campaign._id) {
            $scope.addLogItem({
              title: 'Campaign: ' + $scope.campaign.name + " is verwijderd uit 'Mijn promoties/updates'", // removed: + campaign.datePlanned.toString()
              type: 'General',
              // taskId: task._id,
              user: $scope.user.firstName + ' ' + $scope.user.lastNamePrefix + ' ' + $scope.user.lastName
            }, true)

            $scope.user.socialPortal.campaigns.splice(i, 1)
            $scope.putSocialPortal()
            $state.go('retailer.campaigns')
          } else {
            if ($scope.user.socialPortal.campaigns[i] == $scope.user.socialPortal.campaigns[$scope.user.socialPortal.campaigns.length - 1]) {
              $state.go('retailer.campaigns')
            }
          }
        }
      })
    }

    $scope.isLangChange = campaignFact.changeLanguage.pageName
    // ----functions for fb promotion and API-----
    $scope.fbPageFunc = function (data) {
      campaignFact.changeLanguage.pageName = data.fbPromotionSettings.pageName
      $rootScope.campaignPreview = []
      for (var j = 0; j < $rootScope.user.facebook.facebookPages.length; j++) {
        if ($rootScope.user.facebook.facebookPages[j].name == data.fbPromotionSettings.pageName) {
          $rootScope.fbPageId = $rootScope.user.facebook.facebookPages[j].id
        }
      }

      campaignFact.getFbCurrency().then(function (response) {
        $scope.currency = response.data.currency
      })
      if ($rootScope.fbPageId) {
        // if (data.fbPromotionSettings.isCustomLocation == true) {
        campaignFact.getCustomLocation($rootScope.fbPageId).then(function (res) {
          if (res.status == 200) {
            if (res.data.location) {
              $scope.imgCallToActionArray = $scope.withLocationAction

              $scope.userLocation = false
              $scope.customLocation = res.data.location
              data.fbPromotionSettings.isCustomLocation = true
            } else {
              $scope.imgCallToActionArray = $scope.withoutLocationAction
              data.fbPromotionSettings.isCustomLocation = false
              $scope.userLocation = true
              $scope.validation.customLocation = true
              alert(constantFact.alertMsg[11].msg)
            }
          } else {
            $scope.userLocation = true
            $scope.imgCallToActionArray = $scope.withoutLocationAction
            data.fbPromotionSettings.isCustomLocation = false
            alert(constantFact.alertMsg[11].msg)
          }
        }, function (err) {
          console.error('custom location error', err)
        })
      } else {
        alert(constantFact.alertMsg[0].msg)
        data.fbPromotionSettings.isCustomLocation = false
      }
      var acntidd = $rootScope.adminmetaData.accountId
      var accToken = $rootScope.adminmetaData.access_token
      campaignFact.readAdsets(acntidd, accToken).then(function (res) {

      })
      campaignFact.readAdCampaign(acntidd, accToken).then(function (res) {
        $scope.campData = res.data
        if (res.data && $scope.campData.length > 0) {
          $scope.campData.forEach(function (val) {
            campaignFact.campaignInsightss(val.id, $rootScope.adminmetaData.access_token).then(function (insight) {
              if (insight.data && insight.data.length > 0) {
                insight.data[0].name = val.name
                insight.data[0].status = val.effective_status
                $rootScope.campaignPreview.push(insight.data[0])
              } else {
                var obj = {}
                obj = {
                  name: val.name,
                  status: val.effective_status,
                  clicks: 0,
                  cost_per_unique_click: 0,
                  cpm: 0,
                  ctr: 0,
                  frequency: 0,
                  impressions: 0,
                  reach: 0,
                  spend: 0
                }
                $rootScope.campaignPreview.push(obj)
              }
            })
          })
        }
      })
    }
    $scope.getAdminFunc = function () {
      campaignFact.getAdminData().then(function (res) {
        $rootScope.adminmetaData = res
      })
    }
    $scope.getCountry = true
    $scope.purposeFunc = function (data) {
      $scope.validation.age = false
    }

    var searchItems = function (str, type) {
      var matches = []
      $scope.date = false
      $scope[type].forEach(function (con) {
        if (
          (con.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)) {
          matches.push(con)
        }
      })
      return matches
    }
    $scope.searchCountry = function (str, countryList) {
      return searchItems(str, 'countryList')
    }
    $scope.searchRegion = function (str, regionList) {
      return searchItems(str, 'regionList')
    }
    $scope.searchCity = function (str, cityList) {
      var objCity = {
        regionID: $scope.region_key,
        query: str
      }
      campaignFact.getCity(objCity).then(function (res) {
        $scope.getCountry = false
        $scope.cityList = []
        if (res !== '') {
          res.data.forEach(function (val, index) {
            $scope.cityList.push(val)
          })
        }
      })
      return searchItems(str, 'cityList')
    }
    $scope.interestList = []
    $scope.searchInterest = function (str, interestList) {
      var objInterest = {
        query: str
      }
      campaignFact.getAdInterest(objInterest).then(function (res) {
        $scope.getCountry = false
        if (res !== '') {
          $scope.interestList = []
          res.data.forEach(function (val, index) {
            if ($scope.interestList.indexOf(val.name) == -1) {
              $scope.interestList.push(angular.copy(val))
            }
          })
        }
      })
      return searchItems(str, 'interestList')
    }
    $scope.genderFunc = function (val) {
      $scope.disableInterest = false
      $scope.adsetGender = val.fbPromotionSettings.gender
      if ($scope.adsetGender == 0) {
        $rootScope.genderPreview = 'All'
      }
      if ($scope.adsetGender == 1) {
        $rootScope.genderPreview = 'Male'
      }
      if ($scope.adsetGender == 2) {
        $rootScope.genderPreview = 'Female'
      }
      $scope.date = false
      if ($scope.gender == 0) {
        $scope.allgender = true
      }
    }

    $scope.isPixel = function (check) {
      if (check.fbPromotionSettings.isPixel == true) {

      }
    }

    $scope.countrySelected = function (data, err) {
      $scope.desc = data.description
      $rootScope.selectedCountryName = data.description.name
      $scope.supportsRegion = data.description.supports_region
      $scope.supportsCity = data.description.supports_city
      $scope.countryCODE = data.description.country_code
      $scope.date = false
      if ($scope.supportsRegion == true && $scope.supportsCity == true) {
        campaignFact.getRegion($scope.countryCODE).then(function (res) {
          $scope.getCountry = false
          $scope.regionList = []
          if (res !== '') {
            res.data.forEach(function (val, index) {
              $scope.regionList.push(val)
            })
            $scope.disableRegion = false
          }
        }, function (err) {
          console.error('Error in getRegion', err)
        })
      } else if ($scope.supportsRegion == true && $scope.supportsCity == false) {
        campaignFact.getRegion($scope.countryCODE).then(function (res) {
          $scope.getCountry = false
          $scope.regionList = []
          if (res !== '') {
            res.data.forEach(function (val, index) {
              $scope.regionList.push(val)
            })
          }
        }, function (err) {
          console.eror('Error in getRegion', err)
        })
        $scope.disableRegion = false
      } else if ($scope.supportsRegion == false && $scope.supportsCity == true) {

      }
    }
    $scope.regionSelected = function (selected, err) {
      if (selected != undefined) {
        $rootScope.selectedRegionName = selected.description.name
        $scope.region_key = selected.description.key
        $scope.disableCity = false
      }
    }
    $scope.citySelected = function (selected, err) {
      $scope.validation.location = false
      if (selected != undefined) {
        $rootScope.selectedCityName = selected.description.name
        $scope.city_key = selected.description.key
        $scope.validation.bidAmount = false
        $scope.validation.bidType = false
      }
    }
    $scope.deleteChips = function (index) {
      $scope.selectedInterestData.splice(index, 1)
      $rootScope.interestId.splice(index, 1)
      $scope.campaign.tasks[0].fbPromotionSettings.interests.splice(index, 1)
    }

    $rootScope.interestId = []
    $scope.interestSelected = function (selected, err) {
      $scope.disableCountry = false
      if (selected.description != undefined) {
        $scope.interestDesc = {
          interestId: selected.description.id,
          interestName: selected.description.name
        }

        if ($scope.selectedInterestData.indexOf(selected.description.name) == -1) {
          if ($scope.campaign.tasks[0].fbPromotionSettings.interests) {
            $scope.campaign.tasks[0].fbPromotionSettings.interests.push($scope.interestDesc)
          }
          $rootScope.selectedInterestName = selected.description.name
          $rootScope.interestId.push(selected.description.id)
          $scope.selectedInterestData.push(selected.description.name)
        }
      }
    }
    // $scope.task = {};

    $scope.distanceKm = 25
    $scope.distance = function (data) {
      $scope.validation.bidAmount = false
      $scope.validation.bidType = false
      $scope.distanceKm = data.fbPromotionSettings.distance
    }

    $scope.getCountryInit = function () {
      $scope.countryList = []
      $scope.regionList = []
      $scope.cityList = []
      campaignFact.getCountry().then(function (res) {
        if (res !== '') {
          $scope.countryList = []
          res.data.forEach(function (val, index) {
            if ($scope.countryList.indexOf(val.name) == -1) { $scope.countryList.push(angular.copy(val)) }
          })
        }
      }, function () {
        console.error('error in country')
      })
    }

    $scope.getStartDate = function (task) {
      var todayDate = new Date(task.fbPromotionSettings.promotionStartDate)
      todayDate.setDate(todayDate.getDate() + 1)
      $scope.startTime = task.fbPromotionSettings.promotionStartDate
      task.fbPromotionSettings.promotionEndDate = todayDate
      $scope.getEndDate(task)
    }
    $scope.getEndDate = function (endDate) {
      if (endDate.fbPromotionSettings.promotionEndDate && endDate.fbPromotionSettings.promotionEndDate) {
        var date1 = endDate.fbPromotionSettings.promotionStartDate
        var date2 = endDate.fbPromotionSettings.promotionEndDate
        var timeDiff = Math.abs(date2.getTime() - date1.getTime())
        $rootScope.diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
        if ($rootScope.diffDays == 0 && $scope.campaign.update == false || $rootScope.diffDays < 0 && $scope.campaign.update == false) {
          alert(constantFact.alertMsg[1].msg)
          // TODO: Change this
        } else {
          if ($scope.maxDuration < $rootScope.diffDays) {
            alert('You can only select ' + $scope.maxDuration + ' days!!!')
            // TODO: Change this
          } else {
            if (f == false) {
              $rootScope.budgetPreview = $scope.budget
            }
          }
        }
      } else {
        alert(constantFact.alertMsg[2].msg)
        // TODO: Change this
      }
      $scope.submitDisable = false
    }
    $scope.isCallNow = false
    $scope.calltoactionFunc = function (data) {
      if (data == 'CALL_NOW') {
        $scope.isCallNow = true
      } else {
        $scope.isCallNow = false
      }
    }

    $scope.ageMaxFunc = function (age) {
      $rootScope.age_max = age.fbPromotionSettings.ageMax
      if ($rootScope.age_min >= $rootScope.age_max) {
        $scope.minValue = $rootScope.age_min
      }
      $scope.validation.gender = false
    }
    $scope.ageMinFunc = function (age) {
      $rootScope.age_min = age.fbPromotionSettings.ageMin
    }

    $scope.isSelectMoreImg = function (len) {
      if (len == 8) {
        alert('YOu can only select maximum 8 Images')
        // TODO: change this
        return true
      } else {
        return false
      }
    }

    $scope.isCustomLocationFunc = function (data) {
      if (data.fbPromotionSettings.isCustomLocation == true) {
        $scope.userLocation = false
      } else {
        $scope.userLocation = true
      }
    }

    $scope.deleteFile = function (taskId, index) {
      $scope.myImage.splice(index, 1)
      var task = findTaskById(taskId)
      task.files.splice(index, 1)
    }
    // $rootScope.selectImage = true;

    $scope.storeImgArray = []
    $scope.allImgData = []
    $scope.facebookTaskImages = []
    $scope.selectFiles = function (task, files, evt) {
      $rootScope.selectImage = true
      $rootScope.croppedImgHAsh = null
      $scope.allImgData = files
      $scope.facebookTaskImages = files
      var task = findTaskById(task._id)
      task.files = files
      var fileread = new FileReader()
      fileread.onload = function (load) {
        var img = new Image()
        img.src = load.target.result
        $scope.myImage.push(angular.copy(load.target.result))
        // campaignFact.changeLanguage.images = $scope.myImage;
        $scope.$apply()
        img.onload = function () {
          $scope.loadImgHeight = this.height
          $scope.loadImgWidth = this.width
          if ($scope.loadImgWidth >= 500 && $scope.loadImgHeight >= 262) {
            // $scope.myImage = laod.target.result;
          } else {
            task.files.splice($scope.myImage.length - 2, 1)
            $scope.myImage.splice($scope.myImage.length - 1, 1)
            alert(constantFact.alertMsg[3].msg)
          }
        }
      }
      if (evt.target.files == undefined) {
        fileread.readAsDataURL(files[files.length - 1])
      } else {
        fileread.readAsDataURL(evt.target.files[0])
      }
      $scope.fd = new FormData()
      angular.forEach(files, function (file) {
        imgNameArray.push(angular.copy(file.name))
        $scope.fd.append('file', file)
      })
      //	fd.append('filename', files[0].name);
      $scope.fd.append('access_token', $rootScope.adminmetaData.access_token)
    }

    $scope.switchToggle = function (toggle) {
      if (toggle.fbPromotionSettings.alsoOnInstagram == true) {
        if ($rootScope.fbPageId) {
          campaignFact.getPageToken($rootScope.fbPageId, $rootScope.adminmetaData.access_token).then(function (response) {
            $scope.pageToken = response.data.access_token
            campaignFact.getInstaAccount($rootScope.fbPageId, $scope.pageToken).then(function (resp) {
              if (resp.instagram_accounts) {
                $scope.instagramBid = resp.instagram_accounts.data[0].id
                campaignFact.isinstagramPrev = true
              } else {
                campaignFact.isinstagramPrev = false
                toggle.fbPromotionSettings.alsoOnInstagram = false
                alert(constantFact.alertMsg[10].msg)
              }
            })
          }, function (err) {
            console.error('Error while getting instaAccount', err)
          })
        } else {
          alert(constantFact.alertMsg[0].msg)
          toggle.fbPromotionSettings.alsoOnInstagram = false
        }
      }
    }
    function getBase64Image (img) {
      // var img=new Image();
      // img.crossOrigin = 'anonymous';

      var canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      var ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)
      var dataURL = canvas.toDataURL('image/jpg')
      return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')

      // img.src = imgPath;
    }
    $scope.storeSpinner = false
    $scope.storePreview = false
    $scope.postAdsetDataFunc = function (data, flag) {
      if ($rootScope.fbPageId) {
        $scope.fbPromotion = data.fbPromotionSettings
        $scope.camp_flag = flag
        if ($scope.storePreview == true) {
          $scope.storePreview = false
        }
        if ($rootScope.interestId) {
        } else {
          return alert(constantFact.alertMsg[4].msg)
        }
      } else {
        return alert(constantFact.alertMsg[0].msg)
      }

      if (data.fbPromotionSettings.perDayOrSpread == 'perDay') {
        $rootScope.budgetPreview = data.fbPromotionSettings.budget
      }
      if (data.fbPromotionSettings.promotionStartDate < new Date() || data.fbPromotionSettings.promotionEndDate < new Date()) {
        return alert('please select a new date for execute campaign')
      }
      if ($scope.userLocation == true) {
        if ($rootScope.selectedCountryName == undefined) {
          return alert('please fill country field')
        }
        if ($rootScope.selectedRegionName == undefined) {
          return alert('please fill region field')
        }
        if ($rootScope.selectedCityName == undefined) {
          return alert('please fill city field')
        }
      }
      var campData = {}
      $scope.storeSpinner = true
      if (flag == 'target') {
        $scope.imageDesc = data.fbPromotionSettings.description
        if (data.fbPromotionSettings.interestId) {
          $rootScope.interestId = []
          // $rootScope.interestId.push(data.fbPromotionSettings.interestId[0].id);
          var iid = 6003266225248
          $rootScope.interestId.push(iid)
        }
      } else {
        $scope.imageDesc = data.fbPromotionSettings.description
      }
      if (!$scope.campaign.name && !data.fbPromotionSettings.goal) {
        $scope.storeSpinner = false
        return alert(constantFact.alertMsg[9].msg)
      } else {
        var optimizeGoal = ''
        campData = {
          name: $scope.campaign.name,
          status: 'PAUSED',
          objective: data.fbPromotionSettings.goal
        }
        var adSetsData = {
          daily_budget: parseInt(data.fbPromotionSettings.budget * 100),
          start_time: data.fbPromotionSettings.promotionStartDate,
          end_time: data.fbPromotionSettings.promotionEndDate,
          campaign_id: $scope.campaignID,
          bid_amount: 150,
          // 'is_autobid':true,
          billing_event: 'IMPRESSIONS',
          promoted_object: {
            page_id: $rootScope.fbPageId
          },
          optimization_goal: optimizeGoal,
          targeting: {
            age_max: data.fbPromotionSettings.ageMax,
            age_min: data.fbPromotionSettings.ageMin,
            genders: [data.fbPromotionSettings.gender],
            interests: $rootScope.interestId
          },
          status: 'PAUSED'
        }
        if (campaignFact.isinstagramPrev == true) {

        } else {
          adSetsData.targeting.publisher_platforms = ['facebook']
          adSetsData.targeting.device_platforms = ['mobile']
        }

        $scope.optimizationArray.forEach(function (val) {
          if (val.name == data.fbPromotionSettings.goal) {
            optimizeGoal = val.goal
            adSetsData.optimization_goal = val.goal
            if (val.name == 'BRAND_AWARENESS') {
              delete adSetsData.bid_amount
              adSetsData.is_autobid = true
            }
          }
        })
        if (optimizeGoal == '') {
          optimizeGoal = 'IMPRESSIONS'
          adSetsData.optimization_goal = 'IMPRESSIONS'
        }
        if ($scope.allgender == true) {
          delete adSetsData.targeting.gender
        }
        if ($scope.fbPromotion.isCustomLocation == false || $scope.fbPromotion.isCustomLocation == undefined) {
          adSetsData.name = $rootScope.selectedCountryName + '-' + data.fbPromotionSettings.ageMax
          adSetsData.targeting.geo_locations = {
            countries: [],
            regions: [],
            cities: [
              {
                key: $scope.city_key,
                radius: $scope.distanceKm,
                distance_unit: 'mile'
              }
            ]
          }
          // $rootScope.selectedStreetName = $scope.customLocation.street;
          // $rootScope.selectedCountryName = $scope.customLocation.country;
          // $rootScope.selectedCityName = $scope.customLocation.city;
          $rootScope.customLocationView = true
        } else {
          adSetsData.name = $scope.customLocation.country + '-' + data.fbPromotionSettings.ageMax
          adSetsData.targeting.geo_locations = {
            custom_locations: [
              {
                address_string: "'" + Object.values($scope.customLocation) + "'",
                latitude: $scope.customLocation.latitude,
                longitude: $scope.customLocation.longitude,
                radius: '5',
                distance_unit: 'kilometer'
              }
            ],
            location_types: ['recent', 'home']
          }
        }
        $scope.adGeoLocation = adSetsData.targeting.geo_locations
        if ($scope.fd) {
          if ($rootScope.croppedImgHAsh != undefined && $rootScope.croppedImgHAsh != null) {
            var croppedImg = $rootScope.croppedImgHAsh.replace(/^data:image\/(png|jpg);base64,/, '')
            var croppedHash = {
              bytes: croppedImg
            }
            campaignFact.uploadImageByHash(croppedHash).then(function (res) {
              if (res.error) {
                $scope.storeSpinner = false
                alert(res.error.message)
              } else {
                $scope.imgMetaData = res.images.bytes
                if (data.fbPromotionSettings.isPixel == true && data.fbPromotionSettings.isPixel != undefined) {
                  campaignFact.getCustomAudience().then(function (res) {
                    if (res.id) {
                      adSetsData.targeting.custom_audiences = [{ id: res.id }]
                      $scope.campaignCreateFunc(campData, adSetsData)
                    } else {
                      $scope.campaignCreateFunc(campData, adSetsData)
                    }
                  })
                } else {
                  $scope.campaignCreateFunc(campData, adSetsData)
                }
              }
            }, function (err) {
              $scope.storeSpinner = false
              console.error('Error while uploading', err)
            })
          } else {
            $scope.campaignCreateFunc(campData, adSetsData)
          }
        } else {
          if (flag == 'target') {
            $scope.filterImgUrl = $scope.imgUrlFromCampaign.map(function (val) {
              return val.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
            })

            var imgIndex = 0
            uploadImgInFb($scope.filterImgUrl[imgIndex], function success () {
              var updateIndex = $scope.imgUrlFromCampaign[++imgIndex]
              if (updateIndex) {
                uploadImgInFb($scope.imgUrlFromCampaign[imgIndex], success)
              } else {
                $scope.targetResHash.filter(function (val, ind, self) {
                  return self.indexOf(val) === ind
                })
                $scope.campaignCreateFunc(campData, adSetsData)
              }
            })
          } else {
            $scope.storeSpinner = false
            alert(constantFact.alertMsg[6].msg)
          }
        }
      }
    }
    $scope.campaignCreateFunc = function (campData, adSetsData) {
      campaignFact.postCampData(campData).then(function (res) {
        if (res.error) {
          $scope.storeSpinner = false
          $scope.storePreview = false
          alert(res.error.message)
        } else {
          adSetsData.campaign_id = res.id
          $scope.campaignID = res.id
          $scope.validation.minDate = false
          $scope.validation.maxDate = false
          $scope.createAdsetFunc(adSetsData)
        }
      }, function (error) {
        console.error('Error while postCampData', error)
      })
    }
    $scope.createAdsetFunc = function (adSetsData) {
      campaignFact.postAdsetData(adSetsData).then(function (res) {
        if (res.error) {
          $scope.storeSpinner = false
          $scope.storePreview = false
          campaignFact.deleteFbCampaign($scope.campaignID, $rootScope.adminmetaData.access_token).then(function (res) {

          })
          alert(res.error.error_user_msg)
        } else {
          var adSetResponse = res
          $scope.createData(res, adSetsData)
        }
      }, function (err) {
        console.error('Error while post addset data', err)
      })
    }
    $scope.createData = function (adset, adSetsData) {
      var adSet = adset
      var imgHash = ''
      var imgUrl = $scope.fbPromotion.siteUrl
      if ($scope.fbPromotion.headline != undefined && $scope.fbPromotion.headline != '' && $scope.fbPromotion.newsFeed != undefined && $scope.fbPromotion.callToAction != undefined) {
        var creativeData = {
          name: 'Sample Creative',
          instagram_actor_id: $scope.instagramBid,
          object_story_spec: {
            link_data: {
              name: $scope.fbPromotion.headline,
              description: $scope.fbPromotion.newsFeed,
              call_to_action: { type: $scope.fbPromotion.callToAction, value: { link: $scope.fbPromotion.siteUrl } },
              image_hash: imgHash,
              link: imgUrl,
              message: $scope.imageDesc
            },
            page_id: $rootScope.fbPageId
            // "page_id": $rootScope.user.facebook.pageId,
          },
          access_token: $rootScope.adminmetaData.access_token
        }
        if ($scope.fbPromotion.callToAction == 'CALL_NOW') {
          creativeData.object_story_spec.link_data.call_to_action = { type: $scope.fbPromotion.callToAction, value: { link: 'tel:{' + ($scope.fbPromotion.call_now) + '}' } }
        } else if ($scope.fbPromotion.callToAction == 'GET_DIRECTIONS') {
          creativeData.object_story_spec.link_data.call_to_action = { type: $scope.fbPromotion.callToAction, value: { link: 'fbgeo:\/\/' + $scope.customLocation.latitude + ',' + $scope.customLocation.longitude + ',\"' + Object.values($scope.customLocation) + '\"' } }
        }
      } else {
        var creativeData = {
          name: 'Sample Creative',
          instagram_actor_id: $scope.instagramBid,
          object_story_spec: {
            link_data: {
              message: $scope.imageDesc
            },
            page_id: $rootScope.fbPageId
            // "page_id": $rootScope.user.facebook.pageId,
          },
          access_token: $rootScope.adminmetaData.access_token
        }
      }
      if ($scope.ImageHashArray.length > 1) {
        creativeData.object_story_spec.link_data.child_attachments = []
        $scope.ImageHashArray.forEach(function (val) {
          creativeData.object_story_spec.link_data.child_attachments.push({
            image_hash: val.hash,
            link: imgUrl
          })
        })
      } else {
        if ($scope.camp_flag == 'target') {
          if ($scope.targetResHash.length > 1) {
            creativeData.object_story_spec.link_data.child_attachments = []
            $scope.targetResHash.forEach(function (val) {
              creativeData.object_story_spec.link_data.child_attachments.push({
                image_hash: val.hash,
                link: imgUrl
              })
            })
          } else {
            creativeData.object_story_spec.link_data.image_hash = $scope.targetResHash[0]
          }
        } else {
          creativeData.object_story_spec.link_data.image_hash = $scope.ImageHashArray[0].hash
        }
      }
      $scope.adCreativeFunc(creativeData, adSet, adSetsData)
    }
    $scope.adCreativeFunc = function (creativeData, adSet, adSetsData) {
      campaignFact.getAdcreative(creativeData).then(function (res) {
        if (res.error) {
          $scope.storeSpinner = false
          $scope.storePreview = false
          campaignFact.deleteFbCampaign($scope.campaignID, $rootScope.adminmetaData.access_token).then(function (res) {

          }, function (err) {
            console.error('Error while deleting campaign', err)
          })
          alert(res.error.message)
        } else {
          var ad_set_id = adSet.id
          var creative_id = res.id

          $scope.adssDataobj = {
            name: 'My ad campaign',
            adset_id: ad_set_id,
            creative: { creative_id: creative_id },
            status: 'PAUSED'
          }
          localStorage.setItem('adssData', JSON.stringify($scope.adssDataobj))
          $scope.adCreatePrevDesktopFunc(res.id, $rootScope.adminmetaData.access_token)
          $scope.adCreatePrevMobileFunc(res.id, $rootScope.adminmetaData.access_token, adSetsData)
          var gender = adSetsData.targeting.genders[0]
          var dataReach = {
            adGeoLocation: adSetsData.targeting.geo_locations,
            ageMin: adSetsData.targeting.age_min,
            ageMax: adSetsData.targeting.age_max,
            adsetGender: parseInt(gender),
            daily_budget: adSetsData.bid_amount,
            interests: $rootScope.interestId
          }
          $scope.getReachFunc(dataReach)
        }
      }, function (err) {
        console.error('Error while creating ad', err)
      })
    }
    $scope.adCreatePrevDesktopFunc = function (id, accessToken) {
      campaignFact.getadCreativePrevDesktop(id, accessToken).then(function (desktop) {
        if (desktop.error) {
          $scope.storeSpinner = false
          $scope.storePreview = false
          campaignFact.deleteFbCampaign($scope.campaignID, $rootScope.adminmetaData.access_token).then(function (res) {
          })
          alert(desktop.error.message)
        } else {
          $rootScope.desktopPreview = desktop.data['0'].body
        }
      }, function (err) {
        console.error('Error while getting preview', err)
      })
      campaignFact.getadPrevRightColumn(id, accessToken).then(function (column) {
        if (column.error) {
          $scope.storeSpinner = false
          $scope.storePreview = false
          alert(column.error.message)
          campaignFact.deleteFbCampaign($scope.editCampId, $rootScope.adminmetaData.access_token).then(function (res) {

          }, function (err) {
            console.error('Error while deleting campaign', err)
          })
        } else {
          $rootScope.columnPreview = column.data['0'].body
        }
      })
      if ($scope.fbPromotion.alsoOnInstagram == false || $scope.fbPromotion.alsoOnInstagram == undefined) {
        return true
      } else {
        campaignFact.getadPrevInstagram(id, accessToken).then(function (insta) {
          if (insta.error) {
            $scope.storeSpinner = false
            $scope.storePreview = false
            alert(insta.error.message)
            campaignFact.deleteFbCampaign($scope.editCampId, $rootScope.adminmetaData.access_token).then(function (res) {

            })
          } else {
            $rootScope.isInstagramOrNot = campaignFact.isinstagramPrev
            $rootScope.instagramPreview = insta.data['0'].body
          }
        })
      }
    }
    $scope.adCreatePrevMobileFunc = function (id, accessToken, adSetsData) {
      campaignFact.getadCreativePrevMobile(id, accessToken).then(function (mobile) {
        if (mobile.error) {
          $scope.storeSpinner = false
          $scope.storePreview = false
          campaignFact.deleteFbCampaign($scope.campaignID, $rootScope.adminmetaData.access_token).then(function (res) {

          }, function (err) {
            console.error('Error while deleting campaign', err)
          })
          alert(mobile.error.message)
        } else {
          $rootScope.mobilePreview = mobile.data['0'].body
          $scope.storeSpinner = false
          $scope.previewDisabled = false
        }
      }, function (err) {
        console.error('Error while getting preview', err)
      })
    }
    $scope.getReachFunc = function (dataReach) {
      campaignFact.getAdReach(dataReach).then(function (adReach) {
        if (adReach.error) {
          $scope.storeSpinner = false
          $scope.storePreview = false
          campaignFact.deleteFbCampaign($scope.campaignID, $rootScope.adminmetaData.access_token).then(function (res) {
          })
          alert(adReach.error.error_user_msg)
        } else {
          $scope.storeSpinner = false
          $scope.storePreview = true
          $rootScope.reachUsers = adReach.data.users
          campaignFact.getAd($scope.adssDataobj).then(function (res) {
            if (res.error) {
              alert(res.error.error_user_msg)
            }
            localStorage.setItem('adssData', '')
          }, function (err) {
            console.error('Error while getting ad', err)
          })
        }
      }, function (err) {
        console.error('Error while getting reach', err)
      })
    }

    $scope.openImgModal = function (index) {
      $scope.cropImgIndex = index
      $scope.myCropImg = $scope.myImage
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/crop-image-modal.html',
        controller: 'retailerCampaignController',
        size: 'lg',
        scope: $scope
      })
    }
    $scope.backTOPrevFunc = function () {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/preview-new-campaign-modal.html',
        controller: 'retailerCampaignController',
        size: 'lg',
        scope: $scope
      })
    }
    $scope.viewResultFunc = function () {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/preview-result-fb-modal.html',
        controller: 'retailerCampaignController',
        size: 'lg',
        scope: $scope
      })
    }
    $scope.openCreateCampaignModal = function (flag) {
      $rootScope.editFbModel = flag
      if (flag == false) {
        $rootScope.selectedCountryName = 'Netherlands'
        $rootScope.age_min = $scope.minEdt
        $rootScope.age_max = $scope.maxEdt
        $rootScope.genderPreview = $scope.adsetGender == '1' ? 'Male' : 'Female'
        $rootScope.diffDays = $scope.days
        $rootScope.budgetPreview = $scope.edtBudgetLifetime
      }
      $rootScope.campaign = {
        name: $scope.campaign.name,
        _id: $scope.campaign._id
      }
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/preview-new-campaign-modal.html',
        controller: 'retailerCampaignController',
        size: 'lg'
      })
    }
    $scope.callHome = function () {
      $scope.prevCamp.close()
      $state.go('retailer.home')
    }
    $scope.callPaymentMethod = function () {
      if ($scope.user.shops && $scope.user.shops.length > 0) {
        createOrder()
      } else {
        // ask for payment details
        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/payment-details-modal.html',
          controller: 'paymentDetailsModalController',
          size: 'lg'
        })

        modalInstance.result.then(function (result) {
          // add details in localstorage and call createOrder()
          localStorage.setItem('shopDetails', JSON.stringify(result))
          createOrder()
        }, function (err) {
          // dismissed
        })
      }

      function createOrder () {
        campaignFact.createfbOrder(
          {
            shop: JSON.parse(localStorage.getItem('shopDetails')),
            campaign: $rootScope.campaign
          }
        ).then(function (res) {
          localStorage.setItem('payId', res[0].payId)
          $scope.pay_id = res[0].payId

          var hostname = window.location.hostname

          if (hostname == '127.0.0.1' || hostname == 'localhost') {
            hostname = 'prismanote.ngrok.io'
          }

          var dataPAy = {
            facebookOrder: true,
            order: {
              amount: parseInt($rootScope.budgetPreview * $rootScope.diffDays),
              description: 'facebook campaign payment',
              redirectUrl: 'http://' + hostname + '/retailer/campaigns/' + $stateParams.nameSlug,
              metadata: {
                orderId: res[0].payId
              }
            },
            shop: JSON.parse(localStorage.getItem('shopDetails')),
            campaign: $rootScope.campaign

          }
          campaignFact.createPayment(dataPAy).then(function (resPay) {
            if (resPay.error) {
              alert(resPay.error)
            } else {
              var payment_id = resPay.payment.id
              $scope.paymentSiteUrl = resPay.payment.links.paymentUrl
              $window.location.href = $scope.paymentSiteUrl
              campaignFact.savePaymentID(payment_id, $scope.pay_id, dataPAy.order.amount).then(function (paymntId) {

              })
            }
          })
        })
      }
    }
    // localStorage.setItem("payId","");
    $scope.checkPayment = function () {
      var payId = localStorage.getItem('payId')
      if (payId != '' && payId != undefined) {
        $scope.adsssData = JSON.parse(localStorage.getItem('adssData'))
        campaignFact.checkPayment(payId).then(function (res) {
          localStorage.setItem('payId', '')
          alert(res.msg)
          if (res.code == 200) {
            var modalInstance = $uibModal.open({
              template: '<div class="modal-body" style="background-color:darkseagreen;"><button class="close pull-right" ng-click="callHome();$dismiss()", aria-hidden="true">?</button>' +
								'<h4 >Congratulations, you have succesfully uploaded a social media campaign!</h4></div>',
              controller: 'retailerCampaignController',
              size: 'lg',
              scope: $scope
            })
          } else {

          }
        }, function (err) {
          localStorage.setItem('payId', '')
        })
      }
    }

    // function for campaign target
    $scope.edtCampCreate_ = []
    $scope.showSpinner_ = []
    $scope.campaignPaidCreate = function (task, index, row) {
      $scope.prev_index = index
      $scope.row = row
      $scope.edtCampCreate_[$scope.prev_index + '_' + $scope.row] = false
      $scope.showSpinner_[$scope.prev_index + '_' + $scope.row] = true

      var edtFb = task.fbPromotionSettings
      var today = new Date()
      if (task.fbPromotionSettings.promotionStartDate < today && task.fbPromotionSettings.promotionEndDate <= today) {
        // alert('Please Select Start And End Date From Today');
        alert(constantFact.alertMsg[7].msg)
      } else {
        // function getBase64Image(imgPath) {
        // 	var canvas = document.createElement("canvas");
        // 	canvas.width = imgPath.width;
        // 	canvas.height = imgPath.height;
        // 	var ctx = canvas.getContext("2d");
        // 	ctx.drawImage(imgPath, 0, 0);
        // 	var dataURL = canvas.toDataURL("image/jpg");
        // 	return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
        // }
        var base64 = getBase64Image(document.getElementById(task.outerIndex + '' + task.inerIndex))
        var dataimg = {
          bytes: base64
        }
        $scope.editImgDesc = task[$rootScope.language].description
        var edtCampData = {
          name: task[$rootScope.language].name,
          status: 'PAUSED',
          objective: 'POST_ENGAGEMENT'
        }
        $scope.minEdt = task.fbPromotionSettings.ageMin
        $scope.maxEdt = task.fbPromotionSettings.ageMax
        $scope.edtGender = task.fbPromotionSettings.gender
        var date1 = edtFb.promotionStartDate
        var date2 = edtFb.promotionEndDate
        var timeDiff = Math.abs(date2.getTime() - date1.getTime())
        $scope.days = Math.ceil(timeDiff / (1000 * 3600 * 24))

        if (edtFb.perDayOrSpread == 'perDay') {
          $scope.edtBudgetLifetime = parseInt(edtFb.budget * $scope.days)
          $scope.edtBudgetBid = edtFb.budget
        } else {
          $scope.edtBudgetLifetime = edtFb.budget
          $scope.edtBudgetBid = parseInt(edtFb.budget / $scope.days)
        }
        $scope.editCampaignCreateFunc(edtCampData, edtFb, dataimg)
      }
    }

    // zoek1
    $scope.editCampaignCreateFunc = function (edtCampData, edtFb, dataimg) {
      campaignFact.postCampData(edtCampData).then(function (res) {
        if (res.error) {
          $scope.showSpinner_[$scope.prev_index + '_' + $scope.row] = false
          alert(res.error.error_user_msg)
        } else {
          $scope.campaignID = res.id
          $scope.editCampId = res.id
          var editAdSetData = {
            name: 'NL' + '-' + $scope.maxEdt,
            lifetime_budget: $scope.edtBudgetLifetime * 100,
            start_time: edtFb.promotionStartDate,
            end_time: edtFb.promotionEndDate,
            campaign_id: $scope.campaignID,
            bid_amount: $scope.edtBudgetBid * 100,
            billing_event: 'IMPRESSIONS',
            promoted_object: {
              page_id: $rootScope.fbPageId
            },
            optimization_goal: 'REACH',
            targeting: {
              age_max: $scope.maxEdt,
              age_min: $scope.minEdt,
              genders: [$scope.edtGender],
              geo_locations: {
                countries: ['NL']
              }
            },
            status: 'PAUSED'
          }
          $scope.editAdSetFunc(editAdSetData, dataimg, edtFb)
        }
      }, function (err) {
        console.error('Error while posting data', err)
      })
    }
    $scope.editAdSetFunc = function (editAdSetData, dataimg, edtFb) {
      campaignFact.postAdsetData(editAdSetData).then(function (res) {
        if (res.error) {
          $scope.showSpinner_[$scope.prev_index + '_' + $scope.row] = false
          alert(res.error.message)
          campaignFact.deleteFbCampaign($scope.editCampId, $rootScope.adminmetaData.access_token).then(function (res) {

          })
        } else {
          $scope.editImageHashFunc(res, dataimg, edtFb)
        }
      })
    }
    $scope.editImageHashFunc = function (adset, dataimg, edtFb) {
      campaignFact.uploadImageByHash(dataimg).then(function (data) {
        if (data.error) {
          alert(data.error.message)
          campaignFact.deleteFbCampaign($scope.editCampId, $rootScope.adminmetaData.access_token).then(function (res) {

          })
        } else {
          $scope.edtImgHash = data.images.bytes.hash
          $scope.edtImgUrl = data.images.bytes.url
          $scope.editCreateData(adset, data, edtFb)
        }
      })
    }
    $scope.editCreateData = function (adset, data, edtFb) {
      var imgHash = $scope.edtImgHash
      var imgUrl = edtFb.siteUrl
      var creativeData = {
        name: 'Sample Creative',
        object_story_spec: {
          link_data: {
            description: edtFb.newsFeed,
            call_to_action: { type: edtFb.callToAction, value: { link: edtFb.siteUrl } },
            image_hash: imgHash,
            link: imgUrl,
            message: $scope.editImgDesc
          },
          page_id: $rootScope.fbPageId
        },
        access_token: $rootScope.adminmetaData.access_token
      }
      $scope.editCreativeFunc(adset, creativeData)
    }
    $scope.editCreativeFunc = function (adset, creativeData) {
      campaignFact.getAdcreative(creativeData).then(function (res) {
        if (res.error) {
          $scope.showSpinner_[$scope.prev_index + '_' + $scope.row] = false
          alert(res.error.message)
          campaignFact.deleteFbCampaign($scope.editCampId, $rootScope.adminmetaData.access_token).then(function (res) {

          })
        } else {
          var ad_set_id = adset.id
          var creative_id = res.id

          $scope.adssData = {
            name: 'My Ad',
            adset_id: ad_set_id,
            creative: { creative_id: creative_id },
            status: 'PAUSED',
            access_token: $rootScope.adminmetaData.access_token
          }
          localStorage.setItem('adssData', JSON.stringify($scope.adssData))
          campaignFact.getadCreativePrevDesktop(res.id, $rootScope.adminmetaData.access_token).then(function (desktop) {
            if (desktop.error) {
              $scope.showSpinner_[$scope.prev_index + '_' + $scope.row] = false
              alert(desktop.error.message)
              campaignFact.deleteFbCampaign($scope.editCampId, $rootScope.adminmetaData.access_token).then(function (res) {

              })
            } else {
              $rootScope.desktopPreview = desktop.data['0'].body
            }
          })
          campaignFact.getadPrevRightColumn(res.id, $rootScope.adminmetaData.access_token).then(function (desktop) {
            if (desktop.error) {
              $scope.showSpinner_[$scope.prev_index + '_' + $scope.row] = false
              alert(desktop.error.message)
              campaignFact.deleteFbCampaign($scope.editCampId, $rootScope.adminmetaData.access_token).then(function (res) {

              })
            } else {
              $rootScope.desktopPreview = desktop.data['0'].body
            }
          })
          campaignFact.getadPrevInstagram(res.id, $rootScope.adminmetaData.access_token).then(function (desktop) {
            if (desktop.error) {
              $scope.showSpinner_[$scope.prev_index + '_' + $scope.row] = false
              alert(desktop.error.message)
              campaignFact.deleteFbCampaign($scope.editCampId, $rootScope.adminmetaData.access_token).then(function (res) {

              })
            } else {
              $rootScope.desktopPreview = desktop.data['0'].body
            }
          })
          campaignFact.getadCreativePrevMobile(res.id, $rootScope.adminmetaData.access_token).then(function (mobile) {
            if (mobile.error) {
              $scope.showSpinner_[$scope.prev_index + '_' + $scope.row] = false
              alert(mobile.error.message)
              campaignFact.deleteFbCampaign($scope.editCampId, $rootScope.adminmetaData.access_token).then(function (res) {

              })
            } else {
              $rootScope.mobilePreview = mobile.data['0'].body

              var dataReach = {
                adGeoLocation: { countries: ['US'] },
                ageMin: $scope.minEdt,
                ageMax: $scope.maxEdt,
                adsetGender: $scope.edtGender,
                daily_budget: $rootScope.budgetPreview
              }
              $scope.editReachFunc(dataReach)
              $scope.previewDisabled = false
            }
          })
          $scope.showSpinner_[$scope.prev_index + '_' + $scope.row] = false
        }
      })
    }
    $scope.editReachFunc = function (dataReach) {
      campaignFact.getAdReach(dataReach).then(function (adReach) {
        if (adReach.error) {
          $scope.showSpinner_[$scope.prev_index + '_' + $scope.row] = false
          alert(adReach.error.message)
          campaignFact.deleteFbCampaign($scope.editCampId, $rootScope.adminmetaData.access_token).then(function (res) {

          })
        } else {
          $rootScope.reachUsers = adReach.data.users
          $scope.showSpinner_[$scope.prev_index + '_' + $scope.row] = false
          $scope.edtCampCreate_[$scope.prev_index + '_' + $scope.row] = true
        }
      })
    }
    $scope.facebookPixelFunc = function () {
      var actId = $rootScope.adminmetaData.accountId
      var pixelData = {
        name: 'my first pixel',
        access_token: $rootScope.adminmetaData.access_token
      }
      campaignFact.getFacebookPixel(actId, pixelData).then(function (response) {

      })
    }
    $scope.selectLikes = true
    $scope.arrChart = []
    $scope.modalLikeFunc = function (modalData) {
      campaignFact.getPageToken($rootScope.fbPageId, $rootScope.adminmetaData.access_token).then(function (response) {
        if (response.status == 200) {
          $scope.page_token = response.data.access_token
          var objLike = {
            metric: 'page_fans',
            access_token: $scope.page_token,
            date_preset: modalData
          }
          campaignFact.getPageLikesFunc(objLike).then(function (resp) {
            var arrChart = resp.data[0].values.filter(function (val) {
              return val.value != undefined
            })
            $rootScope.prevPAgeLike = arrChart[arrChart.length - 1].value
            var chartDataArray = []
            for (var i = 0; i < arrChart.length; i++) {
              chartDataArray.push([Date.UTC(new Date(arrChart[i].end_time).getYear(), new Date(arrChart[i].end_time).getMonth(), new Date(arrChart[i].end_time).getDate()), arrChart[i].value])
            }
            $rootScope.arrChart = chartDataArray
            forChart()
          })
        } else {
          alert(constantFact.alertMsg[0].msg)
        }
      }, function (err) { console.error('Error while getting pagetoken', err) })
    }
    $scope.initLikeFunc = function () {
      campaignFact.getPageToken($rootScope.fbPageId, $rootScope.adminmetaData.access_token).then(function (response) {
        if (response.status == 200) {
          $rootScope.page_token = response.data.access_token
          var objLike = {
            metric: 'page_fans',
            access_token: $rootScope.page_token,
            date_preset: 'this_month'
          }
          campaignFact.getPageLikesFunc(objLike).then(function (resp) {
            var arrChart = resp.data[0].values.filter(function (val) {
              return val.value != undefined
            })

            $rootScope.prevPAgeLike = arrChart[arrChart.length - 1].value
            var chartDataArray = []
            for (var i = 0; i < arrChart.length; i++) {
              chartDataArray.push([Date.UTC(new Date(arrChart[i].end_time).getYear(), new Date(arrChart[i].end_time).getMonth(), new Date(arrChart[i].end_time).getDate()), arrChart[i].value])
            }
            $rootScope.arrChart = chartDataArray

            forChart()
          })
        } else {
          // alert('Please Select a Facebook Page');
          alert(constantFact.alertMsg[0].msg)
        }
      })
    }
    // setTimeout(forChart, 1000);
    function forChart () {
      Highcharts.chart('chartContriner1', {
        chart: {
          type: 'spline'
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: { // don't display the dummy year
            month: '%e. %b',
            year: '%b'
          }
        },
        yAxis: {
          min: 0
        },
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: '{point.x:%e. %b}: {point.y:.2f} likes'
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: true
            }
          }
        },
        legend: {
          align: 'left',
          verticalAlign: 'top',
          x: 0,
          y: 0
        },
        series: [{
          name: 'Page Likes',
          data: $rootScope.arrChart,
          lineWidth: 1,
          lineColor: '#3b5998',
          marker: {
            fillColor: '#FFFFFF',
            lineColor: '#3b5998',
            lineWidth: 3
          }
        }]
      })
    }
  }])
