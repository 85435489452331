prismanoteApp.controller('adminUpdatesController', ['$scope', 'dialogs', '$api', '$uibModal', '$q', '$http',
  function ($scope, dialogs, $api, $uibModal, $q, $http) {

    $scope.update = {
      title: {
        nl: '',
        en: '',
        de: '',
        fr: '',
        es: '',
        da: ''
      },
      description: {
        nl: '',
        en: '',
        de: '',
        fr: '',
        es: '',
        da: ''
      },
      url: {
        nl: '',
        en: '',
        de: '',
        fr: '',
        es: '',
        da: ''
      }
    }

    $scope.languages = [
      {
        key: 'nl',
        name: 'Nederlands'
      },
      {
        key: 'en',
        name: 'Engels'
      },
      {
        key: 'de',
        name: 'Duits'
      },
      {
        key: 'fr',
        name: 'Frans'
      },
      {
        key: 'es',
        name: 'Spaans'
      },
      {
        key: 'da',
        name: 'Deens'
      }
    ]

    $scope.getUpdates = function () {
      $api.get('updates?user=1 ', null, null, 2)
        .then(function (response) {
          $scope.updates = response.data.updates
        })
        .catch(function (reason) {
          console.error('Error while getting updates', reason)
        })
    }

    $scope.createUpdate = function () {
      $api.post('updates', $scope.update, null, 2)
        .then(function (res) {
          $scope.update = {
            title: {
              nl: '',
              en: '',
              de: '',
              fr: '',
              es: '',
              da: ''
            },
            description: {
              nl: '',
              en: '',
              de: '',
              fr: '',
              es: '',
              da: ''
            },
            url: {
              nl: '',
              en: '',
              de: '',
              fr: '',
              es: '',
              da: ''
            }
          }
          $scope.getUpdates()
          toastr.success('Update aangemaakt')

        })
        .catch(function(reason){
          console.error('Error creating update', reason)
          toastr.error(reason.message)
        })
    }

    $scope.deleteUpdate = function(_id) {
      $api.delete('updates/' + _id, null, null, 2)
        .then(function (res) {
          toastr.success('Update verwijderd')
          $scope.getUpdates()
        })
        .catch(function (reason){
          console.error('Error deleting update', reason)
          toastr.error(reason.message)
        })
    }

    $scope.getTranslation = function(targetLang, text) {
      return $q(function (resolve, reject) {
        return $http({
          method: 'GET',
          url: encodeURI('https://translate.googleapis.com/translate_a/single?client=gtx&sl=nl&tl='+String(targetLang.substring(0,2).toLowerCase())+'&dt=t&q='+text),
          cache: false
        }).then(function (response) {
          if(response.data && response.data[0] && response.data[0].length > 0) {
            //Loop through all results
            var translations = []
            for(var i = 0; i < response.data[0].length; i++) {
              translations.push(response.data[0][i][0])
              if( (i+1) === response.data[0].length) {
                return resolve(translations.join(''))
              }
            }
          }
        })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    $scope.translate = function(lang, field) {
      if(!$scope.update[field].nl) {
        console.info('Nederlandse waarde voor veld niet gevuld', field)
        return
      }

      $scope.getTranslation(lang, $scope.update[field].nl)
        .then(function (translation) {
          $scope.update[field][lang] = translation
        })
        .catch(function (reason){
          console.error('Error fetching translations', reason)
          dialogs.error(reason)
        })
    }

    $scope.checkUrl = function (update, language) {
      if(update.url[language] && update.url[language] !== '') {
        console.log('update.url[language]', update.url[language])
        return true
      }
      return false
    }
  }])
