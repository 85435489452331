prismanoteApp.controller('confirmAdminPinModalController', ['$scope', '$uibModalInstance', 'pincodes', '$state',
  function ($scope, $uibModalInstance, pincodes, $state) {
    // console.log('confirmAdminPinModalController called: ', pincodes);
    $scope.pincodes = pincodes
    $scope.alert = undefined
    $scope.closeModal = function (reason) {
      $uibModalInstance.close(reason)
    }

    $scope.cancel = function () {
      $scope.closeModal('cancel')
      if (pincodes.type === 'retailer') $state.go('retailer.employees')
      else $state.go('brand.employees')
    }

    $scope.confirmPinFun = function () {
      // console.log('confirmPinFun called: ', $scope.pincodes);
      if ($scope.pincodes.confirmPin !== $scope.pincodes.originalPin) {
        $scope.alert = {
          type: 'danger',
          msg: 'Please enter the correct one'
        }
      } else {
        // console.log('CORRECT ONE');
        $scope.closeModal('success')
      }
    }
  }])
