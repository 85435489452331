prismanoteApp.controller('retailerPurchaseOrderStockCorrectionsController', ['$scope', '$rootScope', '$uibModalInstance', 'data', '$purchaseOrder',
  function ($scope, $rootScope, $uibModalInstance, data, $purchaseOrder) {
    console.log('-- retailerPurchaseOrderStockCorrectionsController!!');
    $scope.loading = false;
    $scope.shopId = data.shopId;
    $scope.orderNumber = data.orderNumber;
    $scope.stockcorrections = [];
    $scope.totalReceivedQuantity = 0;
    $scope.totalReceivedPP = 0;
    $scope.sortByValue = 'dateDesc';
    $scope.rowView = true;
    $scope.stockcorrectionsGridView = [];

    $scope.getPurchaseOrderStockCorrections = function () {
      $scope.stockcorrections = [];
      $scope.stockcorrectionsGridView = [];
      $scope.totalReceivedQuantity = 0;
      $scope.totalReceivedPP = 0;
      $scope.loading = true;
      var data = { shopId: $scope.shopId, orderNumber: $scope.orderNumber, sortBy: $scope.sortByValue };
      if($scope.rowView){
        $purchaseOrder.getPurchaseOrderStockCorrections(data)
        .then(function (response) {
          $scope.stockcorrections = response.data;
          for(var i = 0; i < $scope.stockcorrections.length; i++){
            if($scope.stockcorrections[i] && $scope.stockcorrections[i].stockCorrection && $scope.stockcorrections[i].stockCorrection.received){
              $scope.totalReceivedQuantity += $scope.stockcorrections[i].stockCorrection.received;
              $scope.totalReceivedPP += ($scope.stockcorrections[i].stockCorrection.received * $scope.stockcorrections[i].item.purchasePrice)
            }
          }
          $scope.loading = false;
        }).catch(function (error) {
          $scope.loading = false;
          console.error('Error in getPurchaseOrderStockCorrections: ', error);
        });
      }else{
        $purchaseOrder.getPurchaseOrderStockCorrectionsGridView(data)
        .then(function (response) {
          $scope.stockcorrectionsGridView = response.data;
          for(var i = 0; i < $scope.stockcorrectionsGridView.length; i++){
            var pricePercategory = 0;
            var categoryWise = $scope.stockcorrectionsGridView[i].categoryWise;
            for(var j = 0; j < categoryWise.length; j++){
              var pricePerProductType = 0;
              var productWise = categoryWise[j].productWise;
              for(var k = 0; k < productWise.length; k++){
                var pricePerCollection = 0;
                var nameWise = productWise[k].nameWise;
                for(var h = 0; h < nameWise.length; h++){
                  // {{(x.stockCorrection.received * x.item.purchasePrice).toFixed(2)}}
                  var recieved = nameWise[h].stockCorrection.received;
                  var purchasePrice = nameWise[h].item.purchasePrice;
                  var totalPP = recieved * purchasePrice;
                  pricePerCollection += totalPP;
                }
                $scope.stockcorrectionsGridView[i].categoryWise[j].productWise[k].pricePerCollection = pricePerCollection;
                pricePerProductType +=pricePerCollection;
              }
              $scope.stockcorrectionsGridView[i].categoryWise[j].pricePerProductType = pricePerProductType;
              pricePercategory += pricePerProductType;
            }
            $scope.stockcorrectionsGridView[i].pricePercategory = pricePercategory;
          }

          console.log('$scope.stockcorrectionsGridView ', $scope.stockcorrectionsGridView);
          $scope.loading = false;
        }).catch(function (error) {
          $scope.loading = false;
          console.error('Error in getPurchaseOrderStockCorrections: ', error);
        });
      }
    }
    
    $scope.changeSortingOption = function(value){
      $scope.sortByValue = value;
      $scope.getPurchaseOrderStockCorrections();
    }

    $scope.close = function (shouldModalOpen) {
      $uibModalInstance.close(shouldModalOpen)
    }

    $scope.changeView = function(value){
      $scope.getPurchaseOrderStockCorrections();
    }
    
  }])
