prismanoteApp.controller('adminProductNewController', ['$scope', '$rootScope', '$state', 'Upload', '$diamond', '$productConfig', '$product', '$company', '$tax', '$brand',
  function ($scope, $rootScope, $state, Upload, $diamond, $productConfig, $product, $company, $tax, $brand) {
    console.log('Add New Product Via Admin Portal')

    $scope.product = {
      variants: []
    }
    $scope.gemKinds = $diamond.getGemKinds()
    $scope.gemPurities = $diamond.getGemPurities()
    $scope.gemCuts = $diamond.getGemCuts()
    $scope.gemColors = $diamond.getGemColors()
    $scope.strapColors = $productConfig.getStarpColors()
    $scope.dialColors = $productConfig.getDialColors()
    $scope.caseColors = $scope.jewelColors = Object.assign($scope.dialColors, $scope.strapColors)
    $scope.eanValid = true

    $scope.setCategory = function (productCategory) {
      $scope.product.category = productCategory
      switch (productCategory) {
        case 'WATCH':
          $scope.product.strap = ($scope.product.strap) ? $scope.product.strap : {}
          $scope.product.watch = ($scope.product.watch) ? $scope.product.watch : {}
          $scope.product.jewel = ($scope.product.jewel) ? $scope.product.jewel : {}
          if ($scope.product.watch.dial && $scope.product.watch.dial.color) $scope.dialColor = $scope.dialColors.find(function (color) {
            return color.key == $scope.product.watch.dial.color
          })
          if ($scope.product.watch.case && $scope.product.watch.case.color) $scope.caseColor = $scope.caseColors.find(function (color) {
            return color.key == $scope.product.watch.case.color
          })
          if ($scope.product.watch.strap && $scope.product.watch.strap.color) $scope.strapColor = $scope.strapColors.find(function (color) {
            return color.key == $scope.product.watch.strap.color
          })
          break
        case 'STRAP':
          $scope.product.strap = ($scope.product.strap) ? $scope.product.strap : {}
          $scope.product.watch = ($scope.product.watch) ? $scope.product.watch : {}
          $scope.product.jewel = ($scope.product.jewel) ? $scope.product.jewel : {}
          if ($scope.product.strap && $scope.product.strap.color) $scope.strapColor = $scope.strapColors.find(function (color) {
            return color.key == $scope.product.strap.color
          })
          break
        case 'JEWEL':
          $scope.product.strap = ($scope.product.strap) ? $scope.product.strap : {}
          $scope.product.watch = ($scope.product.watch) ? $scope.product.watch : {}
          $scope.product.jewel = ($scope.product.jewel) ? $scope.product.jewel : {}
          if ($scope.product.jewel && $scope.product.jewel.color) $scope.jewelColor = $scope.jewelColors.find(function (color) {
            return color.key == $scope.product.jewel.color
          })
          break
        case 'OTHER':
          break
      }
    }

    $scope.productNumberIsExistsOrNot = function (productNumber) {
      $scope.PNvalid = false
      $scope.PNsort = true
      if (productNumber) {
        console.group('Product Number')
        console.log('Product Number', productNumber)
        // productNumber = productNumber.toUpperCase().replace(/[^A-Z0-9+\/\\|-]/gi, '');
        productNumber = $productConfig.retrunValidProductNumber(productNumber)
        console.log('Valid Product Number', productNumber)
        if (productNumber && productNumber.length > 2) {
          $scope.PNsort = false
          $productConfig.makeValidProductNumber(productNumber)
            .then(function (validProdocutNumber) {
              $product.productNumberIsExistsOrNot(validProdocutNumber, $scope.product._id)
                .then(function (result) {
                  if (result.status == true) {
                    $scope.PNvalid = true
                    $scope.product.variants[0].productNumber = productNumber
                  } else {
                    $scope.product.variants[0].productNumber = productNumber
                  }
                  console.groupEnd()
                })
                .catch(function (error) {
                  console.error('Error =>>> ', error)
                })
            })
        } else {
          $scope.product.variants[0].productNumber = productNumber
        }
      }
    }

    $scope.eanIsExistsOrNot = function (ean) {
      // Enterd EAN number is valid or not
      if (ean && ean.length > 0) {
        $scope.eanValid = false
        $product.eanIsExistsOrNot(ean, $scope.product._id)
          .then(function (result) {
            if (result.status == true) $scope.eanValid = true
          })
          .catch(function (error) {
            console.error('Error =>>> ', error)
          })
      } else {
        $scope.eanValid = true
      }
    }

    $scope.setGender = function (targetAudience) {
      switch (targetAudience) {
        case 'GENTS':
          $scope.product.male = true
          $scope.product.female = false
          $scope.product.kids = false
          break
        case 'LADIES':
          $scope.product.male = false
          $scope.product.female = true
          $scope.product.kids = false
          break
        case 'UNISEX':
          $scope.product.male = true
          $scope.product.female = true
          $scope.product.kids = false
          break
        case 'BOYS':
          $scope.product.male = true
          $scope.product.female = false
          $scope.product.kids = true
          break
        case 'GIRLS':
          $scope.product.male = false
          $scope.product.female = true
          $scope.product.kids = true
          break
        case 'KIDS':
          $scope.product.male = true
          $scope.product.female = true
          $scope.product.kids = true
          break
      }
    }

    // Start Diamons Functions
    $scope.addNewDiamondForm = function () {
      // Open the form for the add new diamond
      $scope.editDiamond = false
      $scope.addDiamond = true
      if ($scope.product.diamonds != undefined) $scope.diamondIndex = $scope.product.diamonds.length
      $scope.diamond = {}
    }

    $scope.pushDiamond = function () {
      // Add New Diamond in prodocuts diamonds
      if ($scope.product.diamonds == undefined) $scope.product.diamonds = []
      $scope.product.diamonds.push($scope.diamond)
      $scope.diamond = {}
      $scope.addDiamond = false
    }

    $scope.cancelNewDiamond = function () {
      $scope.editDiamond = false
      $scope.addDiamond = false
      $scope.diamond = {}
    }

    $scope.deleteDiamond = function (index) {
      $scope.product.diamonds.splice(index, 1)
    }

    $scope.openDiamond = function (index) {
      $scope.diamond = $scope.product.diamonds[index]
      $scope.diamondIndex = index
      $scope.addDiamond = false
      $scope.editDiamond = true
    }

    $scope.updateDiamond = function (index) {
      $scope.product.diamonds[index] = $scope.diamond
      $scope.editDiamond = false
    }
    // End Diamonds Functions

    $scope.setCaseColor = function (color) {
      if (!$scope.product.watch.case) $scope.product.watch.case = {}
      $scope.product.watch.case.color = color.key
    }

    $scope.setStrapColor = function (color) {
      if (color) {
        if ($scope.product.category == 'WATCH') {
          if (!$scope.product.watch.strap) $scope.product.watch.strap = {
            color: color.key
          }
          $scope.product.watch.strap.color = color.key
        } else if ($scope.product.category == 'STRAP') {
          if (!$scope.product.strap) $scope.product.strap = {
            color: color.key
          }
          $scope.product.strap.color = color.key
        }
      }
    }

    $scope.setDialColor = function (color) {
      if (!$scope.product.watch.dial) $scope.product.watch.dial = {}
      $scope.product.watch.dial.color = color.key
    }

    $scope.setJewelColor = function (color) {
      $scope.product.jewel.color = color.key
    }

    $scope.getTaxForAdmin = function () {
      $tax.getTaxForAdmin()
        .then(function (tax) {
          console.log(tax)
          $scope.taxes = tax.data.rates
        })
        .catch(function (error) {
          console.error('Error in getting Tax Rate')
          console.error(error)
        })
    }

    $scope.addProductImage = function () {
      if (!$scope.images) $scope.images = []
      $scope.images.push({})
    }

    $scope.removeProductImage = function (mode, index) {
      if (mode == 'scope') {
        $scope.images.splice(index, 1)
      } else {
        $scope.product.images.splice(index, 1)
      }
    }

    $scope.getBrands = function () {
      $brand.getAllBrands()
        .then(function (brands) {
          console.log('Brands =>', brands)
          $scope.brands = brands.brands
          console.log('Brands =>', $scope.brands)
        })
        .catch(function (error) {
          console.error('Error in Getting Brands', error)
        })
    }

    $scope.searchBrands = function (str) {
      var matches = []
      $scope.brands.forEach(function (brand) {
        if ((brand[$rootScope.language] != undefined && brand[$rootScope.language].name != undefined) && ((brand[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) || (brand[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0))) matches.push(brand)
      })
      return matches
    }

    $scope.brandSelected = function (selected) {


      console.log("selected", selected);

      if ($scope.product.brand) $scope.productOldBrand = JSON.parse(JSON.stringify($scope.product.brand));
      var brand = {
        _id: selected.originalObject._id,
        name: selected.originalObject[$rootScope.language].name,
        nameSlug: selected.originalObject[$rootScope.language].nameSlug,
        description: selected.originalObject.description,
        images: selected.originalObject.images,
        startingDigits: (selected.originalObject.startingDigits && selected.originalObject.startingDigits != 'null' && selected.originalObject.startingDigits != 'undefined' && selected.originalObject.startingDigits != 'NULL' ) ? selected.originalObject.startingDigits : ''
      }
      $scope.product.brand = brand

      console.log(" Hey Ye dekh le ", $scope.product);
      console.log("$scope.product.variants.length", $scope.product.variants.length);
      if ($scope.product.variants && $scope.product.variants.length && $scope.product.variants[0].productNumber) {
        var index = $scope.product.variants[0].productNumber.indexOf('-');
        console.log("Index of the divider", index);
        console.log(" ***** Offical Product :::: ");
        // Here have only possible the brand stating digit
        if (index > -1) {
          console.log("Here have staring digit applied");
          $scope.product.variants[0].insertedProductNumber = $scope.product.variants[0].productNumber.slice(index + 1);
          console.log("not have staring digit applied");
          if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
            console.log("You can apply new");
            $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].insertedProductNumber));
            $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
            $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
          } else {
            console.log("No new Brands");
            $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.variants[0].insertedProductNumber));
            $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
          }
        } else {
          console.log("not have staring digit applied");
          if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
            console.log("You can apply new");
            $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
            $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].productNumber));
            $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);

          } else {
            console.log("No new Brands")
          }
        }
      } else {
        console.log(" Have in else")
      }
    }

    $scope.saveProductInDataBase = function () {
      console.log('$scope.Product')
      console.log($scope.product)
      var variant = $scope.product.variants[0]
      $scope.product.variants = []
      $scope.product.variants.push(variant)

      var newCollections = {
        brand: $scope.product.brandCollections,
        variant: $scope.product.variantCollections
      }

      delete $scope.product.brandCollections
      delete $scope.product.variantCollections

      var readyProduct = $scope.product

      if ($scope.product.suggestedRetailPrice) $scope.product.suggestedRetailPrice = Number($scope.product.suggestedRetailPrice / (($scope.product.suggestedRetailPriceVat / 100) + 1))

      var data = {
        product: readyProduct,
        newCollections: newCollections,
        user: $rootScope.user
      }

      $company.addNewProductInDatabase(data) //depricated
        .then(function (result) {
          var productId = result.data._id
          if ($scope.images && $scope.images.length > 0 && $scope.images[0].name) {
            console.log('New Product Id', productId, $scope.images)
            $scope.upload = true
            Upload.upload({
                url: 'api/product-image-upload',
                data: {
                  productId: productId,
                  files: $scope.images // file input field
                }
              })
              .then(function (res) {
                $state.go('admin.products')
              })
              .catch(function (err) {
                $scope.alert = {
                  type: 'danger',
                  msg: 'upload error: ' + err
                }
              })
          } else {
            $state.go('admin.products')
          }
        })
        .catch(function (error) {
          console.error('Error While Adding New Product')
          console.error(error)
        })
    }
  }
])