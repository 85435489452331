prismanoteApp.controller('retailerCreateProductMergeSearchModal', ['$scope', '$uibModalInstance', '$state', 'product', '$uibModal', '$rootScope', '$shop',
    function ($scope, $uibModalInstance, $state, product, $uibModal, $rootScope, $shop) {
        console.log('-----------retailerProductModalNewControllerretailerProductModalNewControllerretailerProductModalNewController-----------------> ', product);
        $scope.product = product;
        if (product && product.isComingFromPurchaseOrder && product.isComingFromPurchaseOrder) {
            $scope.localdata = {
                isComingFromPurchaseOrder: product.isComingFromPurchaseOrder
            }
        }

        $scope.cancel = function () {
            // console.log('Close the retailer product modal opening modal')
            $uibModalInstance.dismiss('cancel')
        }

        $scope.replaceInPurchaseOrder = function (product) {
            console.log('product: ', product);
            console.log('replaceInPurchaseOrder: ', $scope.localdata.isComingFromPurchaseOrder);
        }

        $rootScope.cancelModal = function () {
            // console.log('Close the retailer product modal opening modal');
            $uibModalInstance.dismiss('cancel')
        }

    }])
