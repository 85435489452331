prismanoteApp.controller('retailerDrawerLogController', ['$scope', '$api', '$rootScope',
  function ($scope, $api, $rootScope) {

  $scope.logLoading = false

  $scope.getDrawerLog = function() {
    $scope.logLoading = true
    $api.get('till/drawer', { shopId: $rootScope.currentShop._id }, null, 2)
      .then(function (data) {
        $scope.logs = data.data.openings
        $scope.logLoading = false
      })
      .catch(function(reason) {
        console.error('Error while getting drawer log', reason)
        toastr.error(reason)
        $scope.logLoading = false
      })
  }
}])
