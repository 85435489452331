prismanoteApp.controller('upgradeModalController', ['$scope', '$uibModalInstance', 'upgrade', '$rootScope', '$api', '$language',
  function ($scope, $uibModalInstance, upgrade, $rootScope, $api, $language) {
    $scope.upgrade = upgrade
    $scope.completed = false

    $scope.startUpgrade = function () {
      $scope.alert = null
      $scope.completed = false

      $api.post('request-upgrade', {
        shop: $rootScope.currentShop._id,
        package: $scope.upgrade
      })
        .then(function (res) {
          console.log(res)
          $scope.completed = true
          $scope.alert = {
            type: 'success',
            msg: $language.translate('UPGRADE_REQUEST_SENDED')
          }
        })
        .catch(function (reason) {
          console.error(reason)
          $scope.alert = {
            type: 'danger',
            msg: reason
          }
        })
    }

    $scope.close = function () {
      $uibModalInstance.close()
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss()
    }
  }])
