prismanoteApp.controller("addCouponModalController", ['$scope', '$uibModalInstance', '$rootScope', '$api', '$data', 'couponDetail', '$shop',
    function ($scope, $uibModalInstance, $rootScope, $api, $data, couponDetail, $shop) {
		$scope.coupon = {};
		$scope.couponType = couponDetail.type;
		$scope.coupon = couponDetail.coupon;
		$scope.today = new Date((+new Date()  + 86400000))
		if($scope.coupon)
			$scope.coupon.dateExpired = new Date($scope.coupon.dateExpired)

		$scope.startDateOptions = {
			minDate: $scope.today
		}

		$scope.couponError = '';
		$scope.isSavingCoupon = false;
		$scope.privateLabelCollections = [];
		$scope.isCollectionUpdated = false;

        $scope.addCoupon = function () {
			$scope.isSavingCoupon = true;
			$scope.coupon.limit = $scope.coupon.limit ? $scope.coupon.limit : 100000;
			$scope.coupon.shopId = $rootScope.currentShop._id;
			var collection = $scope.privateLabelCollections.map(function(col) { return col._id } )
			$scope.coupon.collections = collection;
			$api.post('coupons/create-new', $scope.coupon )
                .then(function (response) {
					$scope.isSavingCoupon = false;
					if(response.status === 200) {
						$uibModalInstance.close('created');
					}
				}).catch(function(error) {
					console.log(error)
					$scope.isSavingCoupon = false;
					$scope.couponError = error.data.message
					setTimeout(function(){
						$scope.couponError = '';
					}, 2000)
				})
		}

		$scope.editCoupon = function(coupon, couponForm) {
			var updateObject = {
				discountType: coupon.discountType,
				amount: coupon.amount,
				dateExpired: coupon.dateExpired,
				limit: coupon.limit,
				minSpend: coupon.minSpend,
				excludeSaleProduct: coupon.excludeSaleProduct,
				setCollection: coupon.setCollection,
				excludeProduct: coupon.excludeProduct
			} 
			var collection = $scope.privateLabelCollections.map(function(col) { return col._id } )
			updateObject.collections = collection;
			if(!couponForm.$pristine || $scope.isCollectionUpdated) {
				$scope.isSavingCoupon = true;
				$api.put('coupons/update-coupon/'+ $scope.coupon._id + '/' +  $rootScope.currentShop._id, updateObject )
				.then(function (response) {
					$scope.isSavingCoupon = false;
					if(response) {
						$uibModalInstance.close('created');
					}

				}).catch(function(error) {
					console.log(error)
					$scope.isSavingCoupon = false;
					$scope.couponError = error.data.message
					setTimeout(function(){
						$scope.couponError = '';
					}, 2000)
				});
			}
		}

		$scope.close = function() {
			$uibModalInstance.close('close');
		}

		$scope.getShopPrivateLabel = function(shopId){
			$shop.getOwnShopsPrivateLableCollections(shopId)
			.then(function (results) {
				$scope.allPrivateLableCollections = results
				$scope.privateLabelCollections = [];
				if($scope.coupon.collections && $scope.coupon.collections.length > 0){
					$scope.coupon.collections.map(function(col) {
						var isExist = $scope.allPrivateLableCollections.filter(function (label) {return label._id == col; })
						if(isExist && isExist.length > 0){
							$scope.privateLabelCollections.push(isExist[0]);
						}
					} )
				}
				
			})
				.catch(function (reason) {
				console.error(reason)
			})
		}

		$scope.searchPrivateLableCollcetion = function (str) {
			// console.info(`searchPrivateLableCollcetion: `)
			return searchItems(str, 'privateLable')
		}

		var searchItems = function (str) {
			$scope.noCollection = false
			$scope.tempName = str
	
			var matches = []
	
			var collections = $scope.allPrivateLableCollections
	
			var collcetionLength = collections.length
	
			if (collections != undefined && collcetionLength > 0) {
			  collections.forEach(function (item) {
				if ((item[$rootScope.language] != undefined && item[$rootScope.language].name != undefined) && ((item[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) || (item[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0))) {
				  matches.push(item)
				}
			  })
			}
			return matches
		}

		$scope.privateLableCollectionSelected = function (selected) {
			if (selected.originalObject.privateLabel != undefined && selected.originalObject.en.name != '' && selected.originalObject.nl.name != '' && selected.originalObject.de.name != '' && selected.originalObject.fr.name != '' && selected.originalObject.es.name != '') {
				var selectedPrivateLableCollection = selected.originalObject
				var isExist = $scope.privateLabelCollections.filter(function(col) { return col._id == selectedPrivateLableCollection._id} )
				if(isExist && isExist.length == 0){
					$scope.privateLabelCollections.push(selectedPrivateLableCollection)
					$scope.isCollectionUpdated = true;
				}
			}
		}

		$scope.getTheCollectionName = function (collection) {
			var collectionName = ''
			if (collection.hasOwnProperty($rootScope.language)) {
			  collectionName = collection[$rootScope.language].name
			} else if (collection.hasOwnProperty('en.name')) {
			  collectionName = collection.en.name
			} else if (collection.hasOwnProperty('nl.name')) {
			  collectionName = collection.nl.name
			} else if (collection.hasOwnProperty('de.name')) {
			  collectionName = collection.de.name
			} else if (collection.hasOwnProperty('fr.name')) {
			  collectionName = collection.fr.name
			} else if (collection.hasOwnProperty('name') && collection.name != '') {
			  collectionName = collection.name
			} else {
			  collectionName = 'NO NAME'
			}
			return collectionName
		}

		$scope.deletePVLCollection = function (index) {
			$scope.privateLabelCollections.splice(index, 1);
			$scope.isCollectionUpdated = true;
		}
  }])
