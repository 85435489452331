prismanoteApp.controller('SignatureModalController',
    ['$scope', '$uibModalInstance', 'FileSaver', 'url',
        function ($scope, $uibModalInstance, FileSaver, url) {
            console.log('SignatureModalController: url: ', url);
            $scope.localdata = {
                url: url || ''
            }
            angular.element(document).ready(function () {
                try {

                    $scope.initialize = function () {

                        $scope.signaturePad = new SignaturePad(document.getElementById('signature-pad'), {
                            backgroundColor: 'rgba(255, 255, 255, 0)',
                            penColor: 'rgb(0, 0, 0)'
                        });

                        /* var wrapper = document.getElementById("signature-pad");
                        console.log('wrapper: ', wrapper);
                        var canvas = document.querySelector(".signature-pad");
                        console.log('canvas: ', canvas);

                        function resizeCanvas(canvas) {
                            if (!canvas) return;
                            // When zoomed out to less than 100%, for some very strange reason,
                            // some browsers report devicePixelRatio as less than 1
                            // and only part of the canvas is cleared then.
                            console.log('window.devicePixelRatio: ', window.devicePixelRatio, canvas.offsetWidth);
                            var ratio = Math.max(window.devicePixelRatio || 1, 1);

                            // This part causes the canvas to be cleared
                            canvas.width = canvas.offsetWidth * ratio;
                            canvas.height = canvas.offsetHeight * ratio;
                            console.log(canvas.width, canvas.height, ratio);
                            canvas.getContext("2d").scale(ratio, ratio);

                            // This library does not listen for canvas changes, so after the canvas is automatically
                            // cleared by the browser, signaturePad#isEmpty might still return false, even though the
                            // canvas looks empty, because the internal data of this library wasn't cleared. To make sure
                            // that the state of this library is consistent with visual state of the canvas, you
                            // have to clear it manually.
                            // $scope.signaturePad.clear();
                        } */

                        // On mobile devices it might make more sense to listen to orientation change,
                        // rather than window resize events.
                        // window.onresize = resizeCanvas;
                        // resizeCanvas(canvas);
                    }

                    $scope.Save = function () {
                        // var data = $scope.signaturePad.toDataURL('image/png');
                        // console.log('Save called: ', data);

                        var canvas = document.getElementById("signature-pad");
                        canvas.toBlob(function (blob) {
                            blob.lastModifiedDate = new Date();
                            blob.name = "my-image.png";
                            console.log('blob: ', blob);
                            $uibModalInstance.close({ type: 'image', data: blob });
                            // saveAs(blob, "pretty image.png");
                        });

                        // var file = new Blob(data, );
                        // var fileName = 'purchaseorder_' + '.pdf';
                        // FileSaver.saveAs(data, 'image.png');

                        // var anchor = angular.element('<a/>');
                        // var blob = new Blob([data]);
                        // console.log('blob: ', blob);
                        // anchor.attr({
                        //     href: window.URL.createObjectURL(blob),
                        //     target: '_blank',
                        //     download: 'fileName.png'
                        // })[0].click();

                        // Send data to server instead...
                        // window.open(data);
                    }

                    $scope.clear = function () {
                        $scope.signaturePad.clear();
                    }

                } catch (e) {
                    console.log('error there: ', e);
                }

                // try {
                //     // Adjust canvas coordinate space taking into account pixel ratio,
                //     // to make it look crisp on mobile devices.
                //     // This also causes canvas to be cleared.
                //     function resizeCanvas(canvas) {
                //         // When zoomed out to less than 100%, for some very strange reason,
                //         // some browsers report devicePixelRatio as less than 1
                //         // and only part of the canvas is cleared then.
                //         var ratio = Math.max(window.devicePixelRatio || 1, 1);

                //         // This part causes the canvas to be cleared
                //         canvas.width = canvas.offsetWidth * ratio;
                //         canvas.height = canvas.offsetHeight * ratio;
                //         canvas.getContext("2d").scale(ratio, ratio);

                //         // This library does not listen for canvas changes, so after the canvas is automatically
                //         // cleared by the browser, signaturePad#isEmpty might still return false, even though the
                //         // canvas looks empty, because the internal data of this library wasn't cleared. To make sure
                //         // that the state of this library is consistent with visual state of the canvas, you
                //         // have to clear it manually.
                //         // $scope.signaturePad.clear();
                //     }

                //     $scope.initialize = function () {
                //         console.log('SignModalController called');
                //         var wrapper = document.getElementById("signature-pad");
                //         console.log('wrapper called: ', wrapper);
                //         // var clearButton = wrapper.querySelector("[data-action=clear]");
                //         // var changeColorButton = wrapper.querySelector("[data-action=change-color]");
                //         // var undoButton = wrapper.querySelector("[data-action=undo]");
                //         // var savePNGButton = wrapper.querySelector("[data-action=save-png]");
                //         // var saveJPGButton = wrapper.querySelector("[data-action=save-jpg]");
                //         // var saveSVGButton = wrapper.querySelector("[data-action=save-svg]");
                //         var canvas = wrapper.querySelector("canvas");
                //         console.log('canvas: ', canvas);
                //         $scope.signaturePad = new signaturePad(canvas, {
                //             // It's Necessary to use an opaque color when saving image as JPEG;
                //             // this option can be omitted if only saving as PNG or SVG
                //             backgroundColor: 'rgb(255, 255, 255)'
                //         });

                //         $scope.signaturePad.onBegin(function (callback) {
                //             console.log('Hello');
                //         });

                //         $scope.signaturePad.onEnd(function (callback) {
                //             console.log('Hello onEnd');
                //         });

                //         // On mobile devices it might make more sense to listen to orientation change,
                //         // rather than window resize events.
                //         window.onresize = resizeCanvas;
                //         resizeCanvas(canvas);


                //         // OTHER-------------
                //         var canvas = document.querySelector("canvas");

                //         $scope.signaturePad = new SignaturePad(canvas);

                //         // Returns signature image as data URL (see https://mdn.io/todataurl for the list of possible parameters)
                //         $scope.signaturePad.toDataURL(); // save image as PNG
                //         $scope.signaturePad.toDataURL("image/jpeg"); // save image as JPEG
                //         $scope.signaturePad.toDataURL("image/svg+xml"); // save image as SVG

                //         // Draws signature image from data URL.
                //         // NOTE: This method does not populate internal data structure that represents drawn signature. Thus, after using #fromDataURL, #toData won't work properly.
                //         $scope.signaturePad.fromDataURL("data:image/png;base64,iVBORw0K...");

                //         // Returns signature image as an array of point groups
                //         var data = $scope.signaturePad.toData();

                //         // Draws signature image from an array of point groups
                //         $scope.signaturePad.fromData(data);

                //         // Clears the canvas
                //         $scope.signaturePad.clear();

                //         // Returns true if canvas is empty, otherwise returns false
                //         $scope.signaturePad.isEmpty();

                //         // Unbinds all event handlers
                //         $scope.signaturePad.off();

                //         // Rebinds all event handlers
                //         $scope.signaturePad.on();
                //     }

                //     function download(dataURL, filename) {
                //         var blob = dataURLToBlob(dataURL);
                //         var url = window.URL.createObjectURL(blob);

                //         var a = document.createElement("a");
                //         a.style = "display: none";
                //         a.href = url;
                //         a.download = filename;

                //         document.body.appendChild(a);
                //         a.click();

                //         window.URL.revokeObjectURL(url);
                //     }

                //     // One could simply use Canvas#toBlob method instead, but it's just to show
                //     // that it can be done using result of signaturePad#toDataURL.
                //     function dataURLToBlob(dataURL) {
                //         // Code taken from https://github.com/ebidel/filer.js
                //         var parts = dataURL.split(';base64,');
                //         var contentType = parts[0].split(":")[1];
                //         var raw = window.atob(parts[1]);
                //         var rawLength = raw.length;
                //         var uInt8Array = new Uint8Array(rawLength);

                //         for (var i = 0; i < rawLength; ++i) {
                //             uInt8Array[i] = raw.charCodeAt(i);
                //         }

                //         return new Blob([uInt8Array], { type: contentType });
                //     }

                //     /* clearButton.addEventListener("click", function (event) {
                //         signaturePad.clear();
                //     });

                //     undoButton.addEventListener("click", function (event) {
                //         var data = signaturePad.toData();

                //         if (data) {
                //             data.pop(); // remove the last dot or line
                //             signaturePad.fromData(data);
                //         }
                //     });

                //     changeColorButton.addEventListener("click", function (event) {
                //         var r = Math.round(Math.random() * 255);
                //         var g = Math.round(Math.random() * 255);
                //         var b = Math.round(Math.random() * 255);
                //         var color = "rgb(" + r + "," + g + "," + b + ")";

                //         signaturePad.penColor = color;
                //     });

                //     savePNGButton.addEventListener("click", function (event) {
                //         if (signaturePad.isEmpty()) {
                //             alert("Please provide a signature first.");
                //         } else {
                //             var dataURL = signaturePad.toDataURL();
                //             download(dataURL, "signature.png");
                //         }
                //     });

                //     saveJPGButton.addEventListener("click", function (event) {
                //         if (signaturePad.isEmpty()) {
                //             alert("Please provide a signature first.");
                //         } else {
                //             var dataURL = signaturePad.toDataURL("image/jpeg");
                //             download(dataURL, "signature.jpg");
                //         }
                //     });

                //     saveSVGButton.addEventListener("click", function (event) {
                //         if (signaturePad.isEmpty()) {
                //             alert("Please provide a signature first.");
                //         } else {
                //             var dataURL = signaturePad.toDataURL('image/svg+xml');
                //             download(dataURL, "signature.svg");
                //         }
                //     }); */

                //     /* try {
                //         angular.element(document).ready(function () {
                //             // Initialize a signature object in a scope variable
                //             // Check if there is a valid signature
                //             $scope.signature = $scope.accept();
                //             if (!$scope.signature.dataUrl) {
                //                 // If not, then clear it to initialize a valid signature object
                //                 $scope.clearMe();
                //             }
                //             console.log('doc ready', $scope.signature);
                //         })
                //     } catch (e) {
                //         console.log('error: ', e);
                //     }
                //     return;

                //     $scope.done = function () {
                //         var signature = $scope.accept();

                //         if (signature.isEmpty) {
                //             $uibModalInstance.dismiss();
                //         } else {
                //             $uibModalInstance.close(signature.dataUrl);
                //         }
                //     }; */
                // } catch (e) {
                //     console.log('signal modal controller called: ', e);
                // }
            });
        }]);
