prismanoteApp.controller('homeController', ['$scope', '$rootScope', '$api', '$uibModal', '$translate', '$state',
  function ($scope, $rootScope, $api, $uibModal, $translate, $state) {
    if ($rootScope.user) {
      if ($rootScope.user.role === 'retailer') {
        $state.go('retailer.home')
      } else if ($rootScope.user.role === 'wholesaler') {
        $state.go('wholesaler.home')
      };
    }

    $scope.openLoginRegisterModal = function (mode) {
      var tab, data
      if ($scope.activeTab) {
        tab = $scope.activeTab
      } else if (!$scope.activeTab && $scope.selectedTestApp !== 'Ontdek oplossingen') {
        // tab 0 is default 'null'
        tab = 5
      }
      var redirect = true
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/login-modal.html',
        controller: 'loginModalController',
        resolve: {
          mode: function () {
            return mode
          },
          tab: function () {
            return tab
          },
          redirect: function () {
            return redirect
          },
          data: function () {
            return data
          }
        }
      })
      modalInstance.result.then(function (result) {
        console.log('result: mode: ', result)
        $scope.initShoppingCart()
      }, function () {

      })
    }

    $scope.homepageSignup = function () {
      $state.go('layout.register', { email: $scope.email })
    }
  }])
