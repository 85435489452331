prismanoteApp.controller('adminTranslationsController', ['$scope', 'dialogs', '$api', '$uibModal',
  function ($scope, dialogs, $api, $uibModal) {
    $scope.filterIncomplete = false
    $scope.currentPage = 1
    $scope.pageSize = '10'

    $scope.getTranslations = function () {
      $api.get('admin/translations', null, null, 2)
        .then(function (response) {
          $scope.translations = response.data.translations
        })
        .catch(function (reason) {
          console.error('Error while getting translations', reason)
        })
    }

    $scope.isLocalhost = function () {
      return window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost'
    }

    $scope.checkTranslation = function (translation) {
      if (
        (translation.nl_NL && translation.nl_NL !== '') &&
            (translation.en_EN && translation.en_EN !== '') &&
            (translation.de_DE && translation.de_DE !== '') &&
            (translation.fr_FR && translation.fr_FR !== '') &&
            (translation.es_ES && translation.es_ES !== '') &&
        (translation.da_DA && translation.da_DA !== '')
      ) {
        return true
      } else {
        return false
      }
    }

    $scope.incompleteTranslationFilter = function (item) {
      if ($scope.filterIncomplete === false) {
        return true
      }
      return !$scope.checkTranslation(item)
    }

    $scope.downloadTranslations = function () {
      $scope.alert = null
      $scope.syncResult = null

      var dlg = dialogs.confirm('Overwrite translations', 'This will overwrite your existing translations. Continue?', { size: 'md' })
      dlg.result.then(function (btn) {
        if (btn === 'yes') {
          $api.post('admin/translations/sync', null, null, 2)
            .then(function (result) {
              $scope.alert = {
                type: 'success',
                msg: 'Translations synced'
              }
              $scope.syncResult = result.data.result
            })
            .catch(function (reason) {
              console.error(reason)
              $scope.alert = {
                type: 'danger',
                msg: reason
              }
            })
        }
      })
        .catch(function (reason) {
          // cancel
        })
    }

    $scope.openTranslationModal = function (translation) {
      var edit = true
      if (!translation) {
        translation = {}
        edit = false
      }
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/admin-translation-modal.html',
        controller: 'adminTranslationModalController',
        size: 'lg',
        resolve: {
          translation: function () {
            return translation
          }
        }
      })

      modalInstance.result.then(function (result) {
        if (edit) {
          $api.post('admin/translations', { translation: result }, null, 2)
            .then(function (result) {
              $scope.getTranslations()
            })
            .catch(function (reason) {
              console.error('error while updating translations', reason)
            })
        } else {
          $api.put('admin/translations', { translation: result }, null, 2)
            .then(function (result) {
              $scope.getTranslations()
            })
            .catch(function (reason) {
              console.error('error while adding new translation', reason)
            })
        }
      }, function () {
        // cancelled
      })
    }
  }])
