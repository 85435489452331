prismanoteApp.controller('retailerOfferController', ['$scope', '$q', '$rootScope', '$api', '$stateParams', '$uibModal', '$state', '$printer', '$pdf', '$language', '$retailer', 'prompt', '$transaction', 'dialogs', '$filter', '$customer',
  function ($scope, $q, $rootScope, $api, $stateParams, $uibModal, $state, $printer, $pdf, $language, $retailer, prompt, $transaction, dialogs, $filter, $customer) {

    $scope.loading = true
    $scope.searchMode = 'exact'
    $scope.offerItem = {}
    $scope.editItem = false
    $scope.offerIndex = null

    $scope.customerName = function(customer) {
      return $customer.makeCustomerName(customer)
    }

    $scope.getTransaction = function () {
      $transaction.getTransaction($stateParams.transactionId, true, false, $rootScope.currentShop._id)
        .then(function (transaction) {
          if (transaction.length && transaction.length > 0) {
            $scope.transaction = transaction[0]
          } else {
            $scope.transaction = transaction
          }

          $rootScope.transactionType = $scope.transaction.type

          if ($scope.transaction) {
            $scope.originalTransaction = JSON.parse(JSON.stringify($scope.transaction))
          }

          $scope.receiptNumbers = []
          // Parse the date again as date to correctly prefil the datepickers
          $scope.transaction.dateCreated = new Date($scope.transaction.dateCreated)
          if ($scope.transaction[$scope.transaction.type] && $scope.transaction[$scope.transaction.type].estimatedDate) {
            $scope.transaction[$scope.transaction.type].estimatedDate = new Date($scope.transaction[$scope.transaction.type].estimatedDate)
          }
          if ($scope.transaction && $scope.transaction.type === 'offer') {
            if($scope.transaction.offer && $scope.transaction.offer.expireDate) {
              $scope.transaction.offer.expireDate = new Date($scope.transaction.offer.expireDate)
            }
          }
          $scope.statusBackup = angular.copy($scope.transaction.status)
          $scope.loading = false
        })
        .catch(function (reason) {
          console.error('Error when fetching offer', reason)
          if (typeof reason === 'object') {
            $scope.alert = {
              type: 'danger',
              msg: $language.translate('UNKOWN_ERROR_OCCURED')
            }
            return
          }
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(reason)
          }

          $scope.loading = false
        })
    }

    $scope.openCustomer = function () {
      $state.go('retailer.customer', {
        id: $scope.transaction.customer._id
      })
    }

    $scope.updateTransaction = function () {
      if ((!$scope.transaction.createdBy || !$scope.transaction.createdBy.name) && $scope.isRemovedAssitedBy) {
        return
      }
      $scope.loading = true
      if (!$scope.transaction.log) {
        $scope.transaction.log = []
      }

      $scope.transaction.shopId = { _id: $scope.transaction.shopId._id }

      $transaction.updateTransaction($scope.transaction, null, true)
        .then(function (result) {
          $scope.loading = false
          $scope.getTransaction()
        })
        .catch(function (reason) {
          $scope.loading = false
          dialogs.error($language.translate('ERROR'), $language.translate('AN_ERROR_OCCURED') + ': ' + reason, { size: 'sm' })
        })
    }

    $scope.back = function () {
      window.history.back()
    }

    $scope.getTotalQuantity = function () {
      if (!$scope.transaction) {
        return
      }
      var total = 0; var counter = 0
      for (var i = 0; i < $scope.transaction.details.length; i++) {
        total = total + $scope.transaction.details[i].quantity
        counter++
        if (counter === $scope.transaction.details.length) {
          return total
        }
      }
    }

    $scope.getItemDiscount = function (item) {
      if (item.discount) {
        if (item.discountPercent) {
          return item.discountValue + '%'
        } else {
          return $filter('currency')(item.discountValue, '\u20AC')
        }
      } else {

      }
    }

    $scope.getTotalPrice = function (discount) {
      if (!$scope.transaction) {
        return
      }
      var total = 0; var counter = 0
      for (var i = 0; i < $scope.transaction.details.length; i++) {
        total += $scope.transaction.details[i].total
        counter++
        if (counter === $scope.transaction.details.length) {
          if (discount) {
            return total - $scope.getDiscount()
          } else {
            return total
          }
        }
      }
    }

    $scope.printOffer = function (type) {
      $scope.mailAlert = null

      if (type == 'string' && $scope.transaction.customer && $scope.transaction.customer.counter) {
        return
      }

      $pdf.downloadPdf('generate-offer', {
        transaction: $scope.transaction,
        shopId: $rootScope.currentShop._id,
        typeDocument: type,
        documentName: 'OFFER',
        deviceId: $rootScope.currentDevice._id
      }, $language.translate('OFFER') + ' ' + $scope.transaction.number + '.pdf',
      type == 'document' ? 'arraybuffer' : 'string')
        .then(function (data) {
          if (type != 'document') {
            $scope.mailAlert = {
              type: 'success',
              msg: $language.translate('MAIL_SEND')
            }
          }
        })
        .catch(function (reason) {
          console.error(reason)
          if (type != 'document') {
            $scope.alert = {
              type: 'danger',
              msg: reason
            }
          }
        })
    }

    $scope.updateOfferStatus = function (status) {
      if (status == 'cancelled') {
        prompt({
          title: $language.translate('CANCEL_OFFER'),
          message: $language.translate('ARE_YOU_SURE_TO_CANCEL_THIS_OFFER')
        }).then(function () {
          return updateStatus()
        })
      } else {
        return updateStatus()
      }

      function updateStatus () {
        $scope.transaction.status = status
        $scope.transaction.log.push({
          date: new Date(),
          user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
          text: $language.translate('CHANGED_STATUS_FROM_TO', { oldStatus: $language.translate($scope.statusBackup.toUpperCase()), newStatus: $language.translate(status.toUpperCase()) }),
          isFor: 'retailerPortal',
          eType: 'retailer'
        })
        $scope.statusBackup = angular.copy(status)
        $scope.updateTransaction()
      }
    }

    $scope.saveItem = function (item) {
      if (item.discount) {
        if (item.discountPercent) {
          item.total = (item.quantity * item.price) - (item.quantity * item.price * (item.discountValue / 100))
        } else {
          item.total = item.quantity * item.price - (item.quantity * item.discountValue)
        }
      } else {
        item.total = item.quantity * item.price
      }

      if (!$scope.editItem) {
        $scope.transaction.details.push(angular.copy(item))
      }

      $scope.offerItem = {}
      $scope.transaction.details[$scope.offerIndex] = item
      $scope.editItem = false
      $scope.offerIndex = null
      $scope.updateTransaction()
    }

    $scope.openItem = function (item, index) {
      if (!item.discount) {
        item.discount = 0
      }
      $scope.offerItem = angular.copy(item)
      $scope.editItem = true
      $scope.offerIndex = index
    }

    $scope.deleteItem = function (index) {
      $scope.cancelItem()
      $scope.transaction.details.splice(index, 1)
      $scope.updateTransaction()
    }

    $scope.cancelItem = function () {
      $scope.offerIndex = null
      $scope.offerItem = null
      $scope.editItem = false
    }

    $scope.findItems = function (value, callback) {
      $scope.hits = []
      if (!value || value.length < 3) {
        return
      }

      if ($scope.searchMode === 'transactions') {
        $transaction.findTransactions(value)
          .then(function (results) {
            $scope.hits = results
          })
          .catch(function (reason) {
            console.error(reason)
          })
        return
      }

      if ($rootScope.currentShop) {
        var data = {
          nameSlug: $rootScope.currentShop.nameSlug,
          searchMode: $scope.searchMode,
          searchText: value,
          shopId: $rootScope.currentShop._id,
          shopProducts: true
        }

        $api.post('webshop-products-search/', data)
          .then(function (response) {
            if (response.status != 200) {
              return
            }

            if (callback && response.data.products.length == 1) {
              return callback(response.data.products[0])
            } else {
              $scope.hits = response.data.products
            }
          })

          .catch(function (reason) {
            console.error(reason)
          })
      }
    }

    $scope.addItem = function (product) {
      $scope.hits = []
      if ($scope.searchMode === 'transactions') {
        $scope.offerItem = {
          name: $language.translate(product.type.toUpperCase()) + ' ' + product.number,
          quantity: 1,
          price: product.price,
          transaction: product._id,
          photo: null,
          taxRate: '21',
          comment: '',
          discount: false
        }
      } else {
        var productPrice = product.price ? product.price * 1.21 : product.suggestedRetailPrice * 1.21
        $scope.offerItem = {
          name: product.brand.name + ' ' + product.variants[0].productNumber,
          ean: product.variants[0].ean,
          quantity: 1,
          price: Math.round(productPrice * 100) / 100,
          productId: product._id,
          discount: product.discount ? product.discount : 0,
          photos: product.images && product.images.length > 0 ? [{
            src: product.images[0].src,
            alt: product.images[0].alt
          }] : null,
          taxRate: '21',
          comment: '',
        }
      }
    }

    $scope.addDiscount = function () {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/retailer-add-discount-modal.html',
        controller: 'retailerAddDiscountModalController',
        keyboard: false,
        resolve: {
          discount: function () {
            return $scope.transaction.discount ? $scope.transaction.discount : null
          }
        }
      })
      modalInstance.result.then(function (result) {
        $scope.transaction.discount = result
        $scope.updateTransaction()
      }, function () {
        $scope.updateTransaction()
      })
    }

    $scope.getDiscount = function () {
      if (!$scope.transaction.discount) {
        return 0
      }

      if ($scope.transaction.discount.percent) {
        return $scope.getTotalPrice(false) * ($scope.transaction.discount.value / 100)
      } else {
        return $scope.transaction.discount.value
      }
    }

    $scope.getDiscountPercent = function () {
      if ($scope.transaction.discount && $scope.transaction.discount.percent) {
        return '(' + $scope.transaction.discount.value + '%)'
      } else {

      }
    }

    $scope.openChangeCustomerModal = function () {
      $customer.openSearchCustomerModal(false, false)
        .then(function (result) {
          $scope.transaction.customer = result
          if (!$scope.transaction.log) $scope.transaction.log = []
          $scope.transaction.log.push({
            date: new Date(),
            user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
            text: 'Customer Changed',
            isFor: 'retailerPortal',
            eType: 'retailer'
          })
          $scope.updateTransaction()
        })
        .catch(function (reason) {
          console.error('REASON', reason)
        })
    }

    $scope.acceptOffer = function () {
      $state.go('retailer.till', {
        customer: $scope.transaction.customer,
        items: $scope.transaction.details,
        transactionId: $scope.transaction._id,
        transactionNumber: $scope.transaction.number,
        refund: false,
        checkout: true
      })
    }

    $scope.changeConceptStatus = function () {
      $scope.transaction.concept = false
      $scope.updateTransaction()
    }


    $scope.copyTransaction = function () {
      $scope.transaction.shopId = $scope.transaction.shopId._id

      if ($scope.transaction.type == 'repair') {
        if ($scope.transaction.repair.estimatedPrice) {
          $scope.transaction.estimatedPrice = $scope.transaction.repair.estimatedPrice.toString()
        }
        $scope.transaction.estimatedDate = $scope.transaction.repair.estimatedDate
        $scope.transaction.category = $scope.transaction.repair.category
        $scope.transaction.material = $scope.transaction.repair.material
        $scope.transaction.email = $scope.transaction.repair.email
        $scope.transaction.actualCosts = $scope.transaction.repair.actualCosts
        $scope.transaction.externalPrice = $scope.transaction.repair.externalPrice
        if ($scope.transaction.repair.repairer) {
          $scope.transaction.repairer = $scope.transaction.repair.repairer._id
        }
      }
      if ($scope.transaction.type == 'special') {
        if ($scope.transaction.special.estimatedPrice) {
          $scope.transaction.estimatedPrice = $scope.transaction.special.estimatedPrice.toString()
        }
        $scope.transaction.estimatedDate = $scope.transaction.special.estimatedDate
        $scope.transaction.actualCosts = $scope.transaction.special.actualCosts
      }
      if ($scope.transaction.supplier) {
        $scope.transaction.company = $scope.transaction.supplier
      }

      $transaction.createTransaction($scope.transaction, null, $scope.transaction.number, true)
        .then(function (trans) {
          $scope.openTransaction(trans._id)
        })
        .catch(function (reason) {
          console.error(reason)
        })
    }

    $scope.getTaxes = function () {
      $api.get('tax/shop', { shopId: $rootScope.currentShop._id })
        .then(function (res) {
          $scope.taxRates = res.data.taxes.rates

          $scope.offerItem.priceVat = $scope.taxRates[0].rate
        })
        .catch(function (reason) {
          console.error('Error while getting taxes for this shop', reason)
        })
    }

  }])
