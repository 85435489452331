
prismanoteApp.controller('brandHomeController', ['$scope', '$rootScope', '$brand', '$language',
  function ($scope, $rootScope, $brand, $language) {
    $rootScope.pageTitle = 'PrismaNote Brand Portal | ' + $language.translate('DASHBOARD')

    $scope.fetchDashboardCounts = function () {
      $brand.getDashboardCounts($rootScope.currentCompany._id).then(function (response) {
        console.log('scuccess: ', response)
        $scope.count = response && response.data && response.data.data ? response.data.data : {}
      }).catch(function (error) {
        console.log('error: ', error)
      })
    }
  }])
