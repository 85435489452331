prismanoteApp.controller('customerPointsModalController', ['$scope', '$uibModalInstance', 'points', 'customer', '$api', '$rootScope',
  function ($scope, $uibModalInstance, points, customer, $api, $rootScope) {

    $scope.currentPage = 1;
    $scope.pageSize = '10';
    $scope.newPoints = null

    $scope.parseHistory = function (his) {
      var pointsHistory = points.history
      $scope.customerPoints = points.spendable
      if(his) {
        pointsHistory = his.history
        $scope.customerPoints = his.spendable
      }
      var parsedHistory = pointsHistory.map(function(el) {
        if(el._id) {
          el.dateCreated = new Date(parseInt(el._id.toString().substring(0, 8), 16) * 1000)
        } else {
          el.dateCreated = new Date()
        }
        return el
      })

      $scope.history = parsedHistory
    }

    $scope.parseHistory()


    $scope.reverseFilter = function (items) {
      return items.slice().reverse()
    }

    $scope.goToPage = function (page) {
      $scope.currentPage = page
    }

    $scope.delete = function (history) {
      $api.delete('customer/points/' + customer + '/' + history.points + '/' + history._id + '/' + $rootScope.currentShop._id)
        .then( function (res) {
          $scope.parseHistory(res.data.points)
          $scope.newPoints = res.data.points
        })
        .catch( function (reason) {
          console.error('error deleting points', reason)
        })
    }

    $scope.close = function () {
      $uibModalInstance.close($scope.newPoints)
    }

    $scope.calculatePointsValue = function () {
      if (!points || !points.spendable) { return 0 }
      var discountPerPoint = $rootScope.currentShop.points.discountPerPoint
      return discountPerPoint * $scope.customerPoints
    }

  }])
