prismanoteApp.controller('shopEditBrandController', ['$scope', '$brand', '$rootScope', '$stateParams',
  function ($scope, $brand, $rootScope, $stateParams) {
    $scope.localdata = {
      isBankDetailValid: false
    }

    $scope.getCompanyShopDetail = function () {
      $scope.alert = undefined
      var body = {
        companyId: $rootScope.currentCompany._id,
        shopId: $stateParams._id
      }
      $brand.getCompanyShopDetail(body).then(function (response) {
        // console.log('response: ', response);
        $scope.shop = response.data
      }).catch(function (error) {
        // console.log('Error while fetching the company shop detail', error);
        $scope.alert = {
          type: 'danger',
          msg: 'Internal Server Error'
        }
      })
    }

    $scope.editShop = function (editShopForm) {
      $scope.alert = undefined;
      var newEditedShop = {};
      console.log('editShopForm: ', editShopForm);
      console.log('$scope.shop: ', $scope.shop);
      console.log('Object.keys(newEditedShop).length: ', Object.keys(newEditedShop).length);
      if (editShopForm.name && !editShopForm.name.$pristine) newEditedShop.name = $scope.shop.name
      if (editShopForm.email && !editShopForm.email.$pristine) newEditedShop.email = $scope.shop.email
      if (editShopForm.website && !editShopForm.website.$pristine) newEditedShop.website = $scope.shop.website
      if (!editShopForm.postalcode.$pristine || !editShopForm.houseNumber.$pristine || !editShopForm.street.$pristine || !editShopForm.city.$pristine) {
        newEditedShop.address = {
          country: $scope.shop.address.country,
          postalCode: $scope.shop.address.postalCode,
          houseNumber: $scope.shop.address.houseNumber,
          houseNumberSuffix: $scope.shop.address.houseNumberSuffix,
          street: $scope.shop.address.street,
          street: $scope.shop.address.street,
          city: $scope.shop.address.city
        }
      }

      if (!editShopForm.landLine.$pristine || !editShopForm.mobilePhone.$pristine || !editShopForm.fax.$pristine) {
        newEditedShop.phone = {
          landLine: $scope.shop.phone.landLine,
          mobilePhone: $scope.shop.phone.mobilePhone,
          fax: $scope.shop.phone.fax
        }
      }

      if (Object.keys(newEditedShop).length || ($scope.shop.bankdetails && $scope.shop.bankdetails.length)) {
        newEditedShop._id = $scope.shop._id
        newEditedShop.shopId = $scope.shop.shopId;
        newEditedShop.bankdetails = $scope.shop.bankdetails;
        console.log('newEditedshop: ', newEditedShop, Object.keys(newEditedShop).length);
        var body = {
          companyId: $rootScope.currentCompany._id,
          shop: newEditedShop
        }
        $brand.editCompanyShopDetail(body).then(function (response) {
          // console.log('response: ', response);
          $scope.alert = {
            type: 'success',
            msg: 'Updated successfully'
          }
        }).catch(function (error) {
          console.log('Error while updating the company shop detail', error)
          $scope.alert = {
            type: 'danger',
            msg: 'Internal Server Error'
          }
        })
      }
    }

    // Start BANK Functions
    $scope.addNewBankForm = function () {
      // Open the form for the add new bank
      $scope.editBank = false
      $scope.addBank = true
      if ($scope.shop.bankdetails) $scope.bankIndex = $scope.shop.bankdetails.length
      $scope.bankdetail = {}
    }

    $scope.pushBank = function () {
      // Add New BANK in company bankdetails
      if (!$scope.shop.bankdetails) $scope.shop.bankdetails = []
      $scope.shop.bankdetails.push($scope.bankdetail)
      $scope.bankdetail = {}
      $scope.addBank = false
    }

    $scope.cancelBank = function () {
      $scope.editBank = false
      $scope.addBank = false
      $scope.bankdetail = {}
    }

    $scope.deleteBank = function (index) {
      $scope.cancelBank()
      $scope.shop.bankdetails.splice(index, 1)
    }

    $scope.openBank = function (index) {
      $scope.bankdetail = $scope.shop.bankdetails[index]
      $scope.bankdetail.isVerified = false
      $scope.bankIndex = index
      $scope.addBank = false
      $scope.editBank = true
    }

    $scope.updateBank = function (index) {
      $scope.shop.bankdetails[index] = $scope.bankdetail
      $scope.editBank = false
    }

    $scope.isBankDetailValid = function () {
      $scope.localdata.isBankDetailValid = false;
      if ($scope.bankdetail.bankName && $scope.bankdetail.bankAccountNumber && $scope.bankdetail.swiftCode &&
        $scope.bankdetail.bicCode && $scope.bankdetail.accountHolder) {
        $scope.localdata.isBankDetailValid = true;
      }

      // console.log('isBankDetailValid: ', $scope.localdata.isBankDetailValid);
    }
    // End Banks Functions
  }])
