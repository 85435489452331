prismanoteApp.controller('cropImageModalController', ['$scope', '$uibModalInstance', 'defaultImage', 'areaType', 'loadImage',
  function ($scope, $uibModalInstance, defaultImage, areaType, loadImage) {
    $scope.myImage = ''
    $scope.myCroppedImage = ''
    $scope.imageBlob = ''
    $scope.fileName = ''
    $scope.defaultImage = defaultImage
    $scope.areaType = areaType ? areaType : 'circle'
    $scope.showFileInput = true;

    if(loadImage){
      $scope.showFileInput = false;
      loadFile(loadImage);
    }
    
    $scope.close = function (data) {
      $uibModalInstance.close(data)
    }

    $scope.save = function () {
      var returnData = $scope.myCroppedImage
      if($scope.areaType == 'rectangle'){
        returnData = {
          fileName: $scope.fileName,
          imageBlob: $scope.imageBlob
        };
      }
      $uibModalInstance.close(returnData)
    }

    $scope.delete = function () {
      $uibModalInstance.close('')
    }

    $scope.handleFileSelect = function (evt) {
      var file = evt.currentTarget.files[0]
      loadFile(file);
    }

    function loadFile(file){
      $scope.fileName = file.name
      var reader = new FileReader()
      reader.onload = function (evt) {
        $scope.$apply(function ($scope) {
          $scope.myImage = evt.target.result
        })
      }
      reader.readAsDataURL(file)
    }
  }])
