prismanoteApp.controller("retailerAddCompanyAsPerBrandModalController",
	['$scope', '$rootScope', 'shopModalData', '$brand', '$brandShopSettings', '$uibModalInstance', '$uibModal',
		function ($scope, $rootScope, shopModalData, $brand, $brandShopSettings, $uibModalInstance, $uibModal) {
			//console.log('SHopModal Data: ', shopModalData);
			$scope.searchCompanyURL1 = window.location.origin + '/api/retailer-companies-search/supplier/' +  $rootScope.currentShop._id + '/';
			$scope.searchCompanyURL2 = window.location.origin + '/api/retailer-companies-search/company/' + $rootScope.currentShop._id + '/';
			//console.log('$scope.searchCompanyURL ', $scope.searchCompanyURL);
			$scope.localdata = {
				editForAlias: false,
				tempAlias: '',
				favSup: '', // used for radio button
				isFavSuppChanged: false, // To check that favourite Supplier changed or not
				lastFavSuppId: '', // Last fav supplier Id which is already there
				newFavSuppId: '' // New changed fav supplier ID
			}
			$scope.allSuppliers = []; // Used for searching the suppliers or repairers
			$scope.allRepairers = []; // Used for searching the repairers
			$scope.supplierSelectedObj = { };

			// Here, we are getting all the companis by grouping the countries
			// which have this brand (Private -> createdby currentshop)
			$scope.getBrandCompaniesForShopSettings = function () {
				//console.log('----------------- getBrandCompaniesForShopSettings!');
				//console.log('$scope.brandCompanies 1', angular.copy($scope.brandCompanies));
				$scope.loading = true;
				$brand.getBrandCompaniesForShopSettings(shopModalData).then(function (response) {
					$scope.brandCompanies = [];
					$scope.loading = false;
					if (response && response.data && response.data.data) {
						var res_body = response.data.data;
						$scope.brand = res_body.brandDetail;
						$scope.brand.originalAlias = $scope.brand.alias || '';
						if ($scope.brand.alias) $scope.localdata.tempAlias = $scope.brand.alias;
						//console.log('res_body.companies: ', res_body.companies);
						//console.log('$scope.brandCompanies: ', angular.copy($scope.brandCompanies));
						$scope.brandCompanies = $brandShopSettings.getCompanyWithCountryName(res_body.companies);
						//console.log('$scope.brand: ', $scope.brand);
						for(i = 0; i < $scope.brandCompanies.length; i++){
							//console.log('--------------- !');
							var companies = $scope.brandCompanies[i].companies;
							for(j = 0; j < companies.length; j++){
								console.log(companies[j].isFavouriteSupplier);
								if(companies[j].isFavouriteSupplier) {
									//console.log('------------------- if!!');
									//console.log('companies[j] ', companies[j]);
									$scope.selectedFavSupplier = companies[j];
									//console.log('$scope.selectedFavSupplier ', $scope.selectedFavSupplier);
								}
							}
						}
						//console.log('$scope.brandCompanies: ', angular.copy($scope.brandCompanies));
					}
				}).catch(function (error) {
					$scope.loading = false;
					console.log('error: ', error);
				});
			}

			// Getting all the companies for searching (For suppliers search only search on supplisers but
			// for repairers have to search in both in suppliers as well repairers)
			// $scope.getAllTheCompaniesAndGroupByType = function () {
			// 	console.log('------------------ getAllTheCompaniesAndGroupByType!!');
			// 	var body = {
			// 		shopId: shopModalData.shopId
			// 	}
			// 	$brandShopSettings.getAllTheCompaniesAndGroupByType(body).then(function (response) {
			// 		$scope.allCompanies = [];
			// 		if (response && response.data && response.data.data) {
			// 			var res_body = response.data.data;
			// 			for (var i = 0; i < res_body.length; i++) {
			// 				if (res_body[i]._id && (res_body[i]._id).toLowerCase() === 'supplier') {
			// 					// console.log(res_body[i]._id.toLowerCase(), 'supplier', res_body[i].companies);
			// 					var supp = $scope.allSuppliers.concat(res_body[i].companies);
			// 					$scope.allSuppliers = supp;
			// 				} else if (res_body[i]._id && (res_body[i]._id).toLowerCase() === 'company') {
			// 					$scope.allRepairers = res_body[i].companies;
			// 				}
			// 			}

			// 			$scope.allCompanies = $scope.allSuppliers.concat($scope.allRepairers);

			// 			// console.log('$scope.allSuppliers: ', $scope.allSuppliers);
			// 			// console.log('$scope.allRepairers: ', $scope.allRepairers);
			// 		}
			// 	}).catch(function (error) {
			// 		console.log('error: res_body ', error);
			// 	});
			// }

			// ------------------- ALIAS CHANGING START ----------

			$scope.changeAliasName = function () {
				$scope.localdata.editForAlias = false;
				$scope.brand.alias = $scope.localdata.tempAlias;
			};

			// $scope.emptyAlias = function () {
			// 	// $scope.localdata.editForAlias = !$scope.localdata.editForAlias;
			// 	$scope.localdata.tempAlias = '';
			// 	// $scope.brand.alias = '';
			// }

			$scope.removeAlias = function () {
				$scope.localdata.tempAlias = '';
				$scope.brand.alias = $scope.brand.originalAlias;
				$scope.localdata.editForAlias = false;
			};

			function getAlias(alias) {
				//console.log('------------------ getAlias!!');
				//console.log('$scope.brand.alias: ', $scope.brand.alias);

				if ((!alias || alias == '') && $scope.brand && ($scope.brand.name === "Kasius" || $scope.brand.nameSlug === "kasius")) {
					var language = $rootScope.language;
					if (language === 'nl') {
						$scope.brand.alias = 'Eigen merk sieraden'
					} else if (language === 'en') {
						$scope.brand.alias = 'Own brand jewellery'
					} else if (language === 'de') {
						$scope.brand.alias = 'Eigenmarkenschmuck'
					} else if (language === 'fr') {
						$scope.brand.alias = 'Bijoux de marque propre'
					} else if (language === 'es') {
						$scope.brand.alias = 'Joyas de marca propia'
					}
				}
				//console.log('$scope.brand.alias: ', $scope.brand.alias);
				return $scope.brand.alias;
			}

			// ------------------- ALIAS CHANGING END ----------

			// Check that Fav supplier is changed or not of given brand
			function isFavSupplierChanged(supplierId) {
				// console.log('-------------- isFavSupplierChanged!!');
				// console.log('supplierId ', supplierId);
				// console.log('$scope.supplierSelec$scope.localdata.lastFavSuppId ', $scope.localdata.lastFavSuppId);
				//console.log('-------------- isFavSupplierChanged!!');
				//console.log('supplierId ', supplierId);
				//console.log('$scope.supplierSelec$scope.localdata.lastFavSuppId ', $scope.localdata.lastFavSuppId);
				$scope.localdata.newFavSuppId = $scope.supplierSelectedObj._id;
				// if (($scope.localdata.lastFavSuppId).toString() !== ($scope.localdata.newFavSuppId).toString()) 
				$scope.localdata.isFavSuppChanged = true;


				// else $scope.localdata.isFavSuppChanged = false;
				// console.log('isFavSupplir Changed: ', $scope.localdata.lastFavSuppId, $scope.localdata.newFavSuppId, $scope.localdata.isFavSuppChanged);
				//console.log('isFavSupplir Changed: ', $scope.localdata.lastFavSuppId, $scope.localdata.newFavSuppId, $scope.localdata.isFavSuppChanged);
			}

			$scope.changeSupplier = function (supplier, isFirstTime) {
				//console.log('----------------- changeSupplier!')
				//console.log('changeSupplier: ', supplier._id, isFirstTime, $scope.localdata.favSup);
				// console.log('$scope.selectedFavSupplier ',angular.copy($scope.selectedFavSupplier));
				$scope.localdata.favSup = supplier._id;
				$scope.selectedFavSupplier = supplier;
				// console.log('$scope.selectedFavSupplier ',angular.copy($scope.selectedFavSupplier));

				changeFlagSameAsSupplierAndIsRepairMySelf();
				changeIsFavSupplierFlag(supplier._id);

				// BELOW IF CONDITION FOR THE PUSH NOTIFICATION FOR THE COMPANY IF SUPPLIER CHANGED
				if (isFirstTime) $scope.localdata.lastFavSuppId = supplier._id;
				isFavSupplierChanged(supplier._id);

			}

			$scope.repairChanged = function (value) {
				$scope.brand.favoriteRepairer = undefined;
				if (value == 'supplier') {
					if ($scope.selectedFavSupplier && $scope.selectedFavSupplier._id) $scope.brand.favoriteRepairer = $scope.selectedFavSupplier;
					$scope.brand.isRepairMySelf = false;
					$scope.brand.sameAsSupplier = true;
				} else {
					$scope.brand.favoriteRepairer = undefined;
					$scope.brand.sameAsSupplier = false;
					$scope.brand.isRepairMySelf = true;
				}
			}

			$scope.searchSuppliers = function (str) {
				return $brandShopSettings.searchSuppliersOrRepairer($scope.allSuppliers, str);
			};

			$scope.searchRepairers = function (str) {
				return $brandShopSettings.searchSuppliersOrRepairer($scope.allCompanies, str);
			};

			$scope.supplierSelected = function (selected) {
				//console.log('----------------- supplierSelected!!'); 
				//console.log('selected ', selected);
				//console.log('$scope.brandCompanies 3', angular.copy($scope.brandCompanies));
				if(selected.title === " ➕ Add company" || selected.title === " ➕ Bedrijf toevoegen" || selected.title === " ➕ Añadir empresa" || selected.title === " ➕ Ajouter une société" || selected.title === " ➕ Firma hinzufügen"){
					$scope.openAddCompanyModal();
				} else {
					//console.log('supplierSelected: ', selected._id);
					var company = selected;
					company.isFavouriteSupplier = true;
					$scope.supplierSelectedObj = company;
					// console.log('$scope.brandCompanies 4.1', angular.copy($scope.brandCompanies));
					// console.log('$scope.brandCompanies 4.1', angular.copy($scope.brandCompanies).length);
					$brandShopSettings.addSelectedCompany($scope.brandCompanies, company);
					// $scope.brandCompanies = $brandShopSettings.getCompanyWithCountryName(res_body.companies);
					// $scope.getBrandCompaniesForShopSettings();
					// console.log('$scope.brandCompanies 4.2', angular.copy($scope.brandCompanies));
					// console.log('$scope.brandCompanies 4.2', angular.copy($scope.brandCompanies).length);
					//console.log('$scope.brandCompanies 4.1', angular.copy($scope.brandCompanies));
					//console.log('$scope.brandCompanies 4.1', angular.copy($scope.brandCompanies).length);
					// $brandShopSettings.addSelectedCompany($scope.brandCompanies, company);
					// $scope.brandCompanies = $brandShopSettings.getCompanyWithCountryName(res_body.companies);
					// $scope.getBrandCompaniesForShopSettings();
					//console.log('$scope.brandCompanies 4.2', angular.copy($scope.brandCompanies));
					//console.log('$scope.brandCompanies 4.2', angular.copy($scope.brandCompanies).length);
					// $scope.selectedFavSupplier = company;
					$scope.selectedFavSupplier = company;
					changeIsFavSupplierFlag($scope.selectedFavSupplier._id)
					changeFlagSameAsSupplierAndIsRepairMySelf();
					isFavSupplierChanged($scope.selectedFavSupplier._id)
					// isFavSupplierChanged(company._id); // Checking that fav suplier changed
				}
			}

			$scope.repairerSelected = function (selected) {
				if(selected.title === " ➕ Add company" || selected.title === " ➕ Bedrijf toevoegen" || selected.title === " ➕ Añadir empresa" || selected.title === " ➕ Ajouter une société" || selected.title === " ➕ Firma hinzufügen"){
					$scope.openAddCompanyModal();
				} else {
					var company = selected;
					//console.log('repairerSelected: ', company);
					$scope.brand.favoriteRepairer = company;
					changeFlagSameAsSupplierAndIsRepairMySelf();
				}
			}

			function changeFlagSameAsSupplierAndIsRepairMySelf() {
				//console.log('------------ changeFlagSameAsSupplierAndIsRepairMySelf!!');
				// For Select flag same as Supplier And isMyRepair
				if ($scope.selectedFavSupplier && $scope.selectedFavSupplier._id) {
					if ($scope.brand.favoriteRepairer && $scope.brand.favoriteRepairer._id && $scope.brand.favoriteRepairer._id == $scope.selectedFavSupplier._id) {
						$scope.brand.sameAsSupplier = true;
					} else if (!shopModalData.isEditingBrnad && (!$scope.brand.favoriteRepairer || !$scope.brand.favoriteRepairer._id)) {
						$scope.brand.favoriteRepairer = $scope.selectedFavSupplier;
						$scope.brand.sameAsSupplier = true;
					} else if (!shopModalData.isEditingBrnad) {
						$scope.brand.favoriteRepairer = $scope.selectedFavSupplier;
						$scope.brand.sameAsSupplier = true;
					}
				} else {
					$scope.brand.sameAsSupplier = false;
				}

				if ($scope.brand.favoriteRepairer && $scope.brand.favoriteRepairer._id) {
					$scope.brand.isRepairMySelf = false;
				} else {
					$scope.brand.isRepairMySelf = true;
				}
			}

			// No use of this still we need to keep this, for future
			function changeIsFavSupplierFlag(supplierId) {
				for (var i = 0; i < $scope.brandCompanies.length; i++) {
					var companiesPerLang = $scope.brandCompanies[i].companies;
					var companiesPerLangLength = companiesPerLang.length;
					for (var j = 0; j < companiesPerLangLength; j++) {
						// console.log('companiesPerLang[j]._id: ', companiesPerLang[j]._id, supplierId);
						if (companiesPerLang[j]._id !== supplierId) {
							companiesPerLang[j].isFavouriteSupplier = false;
						} else {
							companiesPerLang[j].isFavouriteSupplier = true;
						}
					}
				}
			}

			// function changeIsFavSupplierFlag(supplierId) {
			// 	console.log('--------------------- changeIsFavSupplierFlag!');
			// 	console.log('$scope.brandCompanies 5', angular.copy($scope.brandCompanies));
			// 	for (var i = 0; i < $scope.brandCompanies.length; i++) {
			// 		var companiesPerLang = $scope.brandCompanies[i].companies;
			// 		var companiesPerLangLength = $scope.brandCompanies[i].companies.length;
			// 		for (var j = 0; j < companiesPerLangLength; j++) {
			// 			console.log('companiesPerLang[j]._id: ', $scope.brandCompanies[i].companies[j]._id, supplierId);
			// 			if ($scope.brandCompanies[i].companies[j]._id !== supplierId) {
			// 				console.log('------------ false!');
			// 				$scope.brandCompanies[i].companies[j].isFavouriteSupplier = false;
			// 			} else {
			// 					console.log('------------ true!');
			// 				$scope.brandCompanies[i].companies[j].isFavouriteSupplier = true;
			// 			}
			// 		}
			// 	}
			// }

			$scope.cancel = function (message) {
				var value = 'cancel';
				if (message) value = message;
				$uibModalInstance.close(value);
			}

			$scope.openAddCompanyModal = function () {
				$scope.cancel();
				var modalInstance = $uibModal.open({
					templateUrl: '../views/modal/retailer-add-company-modal.html',
					controller: 'retailerAddCompanyModalController',
					size: 'lg',
					resolve: {
						searchValue: function () {
							return '';
						}
					}
				});

				modalInstance.result.then(function (result) {
					console.log('result: ', result);
				}, function (error) {
					console.log('error: ', error);
				})
			}

			$scope.brandSelected = function () {
				console.log('--------------------- brandSelected!!');
				if (shopModalData.isEditingBrnad) { // Editing New Brand
					updateShopBrand();
				} else { // Add New Brand
					addBrandInShop();
				}
			}

			$scope.responseFormatter = function(response){
				if(response.data && response.data.length == 0){
					var addNewComapny = {
						en: { 
							name: " ➕ Add company"
						  },
						  nl: {
							name: " ➕ Bedrijf toevoegen"
						  },
						  es: {
							name: " ➕ Añadir empresa"
						  },
						  fr: {
							name: " ➕ Ajouter une société"
						  },
						  de: {
							name: " ➕ Firma hinzufügen"
						  }
					  };
					response.data.push({company: addNewComapny[$rootScope.language]})
				}
				return response;
			}

			function updateShopBrand() {
				//console.log('Update SHop Brand called');
				//console.log(' ---->  ', $scope.brand.originalAlias, $scope.localdata.tempAlias, $scope.brand.alias);

				$scope.brand.favoriteRepairerId = $scope.brand.favoriteRepairer && $scope.brand.favoriteRepairer._id ? $scope.brand.favoriteRepairer._id : null;
				if ($scope.brand.sameAsSupplier) {
					$scope.brand.favoriteRepairerId = $scope.selectedFavSupplier && $scope.selectedFavSupplier._id ? $scope.selectedFavSupplier._id : null
				} else if ($scope.brand.isRepairMySelf) {
					$scope.brand.favoriteRepairerId = null
				}

				var favoriteSupplierId;
				if($scope.selectedFavSupplier && $scope.selectedFavSupplier._id ){
						favoriteSupplierId = $scope.selectedFavSupplier._id;
				}else if($scope.supplierSelectedObj && $scope.supplierSelectedObj._id){
						favoriteSupplierId = $scope.supplierSelectedObj._id;
				}else{ favoriteSupplierId = null }

				var editBody = {
					brand: {
						_id: $scope.brand._id,
						name: $scope.brand.name,
						nameSlug: $scope.brand.nameSlug,
						images: $scope.brand.images,
						description: $scope.brand.description,
						restricted: $scope.brand.restricted,
						alias: getAlias($scope.localdata.tempAlias),
						favoriteSupplierId: favoriteSupplierId,
						// favoriteSupplierId: $scope.supplierSelectedObj && $scope.supplierSelectedObj._id ? $scope.supplierSelectedObj._id : null,
						// favoriteSupplierId: $scope.selectedFavSupplier && $scope.selectedFavSupplier._id ? $scope.selectedFavSupplier._id : null,
						favoriteRepairerId: $scope.brand.favoriteRepairerId
					},
					shop: {
						_id: $rootScope.currentShop._id,
						name: $rootScope.currentShop.name,
						nameSlug: $rootScope.currentShop.nameSlug,
					},
					isSendPushNotificationToSupplier: false, // Need to check
				}
				// if ($scope.selectedFavSupplier && $scope.selectedFavSupplier._id) {
				// 	editBody.selectedFavSupplier = {
				// 		_id: $scope.selectedFavSupplier._id,
				// 		name: $scope.selectedFavSupplier.name,
				// 		nameSlug: $scope.selectedFavSupplier.nameSlug,
				// 	}
				// }
				if (($scope.supplierSelectedObj && $scope.supplierSelectedObj._id) || ($scope.selectedFavSupplier && $scope.selectedFavSupplier._id)) {
					editBody.selectedFavSupplier = {
						_id: $scope.supplierSelectedObj._id || $scope.selectedFavSupplier._id,
						name: $scope.supplierSelectedObj.name || $scope.selectedFavSupplier.name,
						nameSlug: $scope.supplierSelectedObj.nameSlug || $scope.selectedFavSupplier.nameSlug,
					}
				}

				//console.log('NEW BODY: ', editBody);
				$scope.loading = true;
				$brand.updateBrandInShop(editBody).then(function (response) {
					$scope.loading = false;
					$rootScope.brandLoader = false;
					$scope.cancel('brandupdated');
				}).catch(function (error) {
					$scope.loading = false;
					$scope.error = true;
					$scope.errorMessage = "Internal server error. While update this brand.";
					$rootScope.brandLoader = false;
				});
			}

			function addBrandInShop() {
				$scope.brand.favoriteRepairerId = $scope.brand.favoriteRepairer && $scope.brand.favoriteRepairer._id ? $scope.brand.favoriteRepairer._id : null;
				if ($scope.brand.sameAsSupplier) {
					// console.log('sameasSupplier: ');
					$scope.brand.favoriteRepairerId = $scope.selectedFavSupplier && $scope.selectedFavSupplier._id ? $scope.selectedFavSupplier._id : null
				} else if ($scope.brand.isRepairMySelf) {
					$scope.brand.favoriteRepairerId = null
				}
				var addBody = {
					brand: {
						_id: $scope.brand._id,
						name: $scope.brand.name,
						nameSlug: $scope.brand.nameSlug,
						images: $scope.brand.images,
						description: $scope.brand.description,
						restricted: $scope.brand.restricted,
						alias: getAlias(),
						favoriteSupplierId: $scope.selectedFavSupplier && $scope.selectedFavSupplier._id ? $scope.selectedFavSupplier._id : null,
						favoriteRepairerId: $scope.brand.favoriteRepairerId
					},
					shop: {
						_id: $rootScope.currentShop._id,
						name: $rootScope.currentShop.name,
						nameSlug: $rootScope.currentShop.nameSlug,
					},
					isSendPushNotificationToSupplier: false, // Need to check
				}
				//console.log('$scope.selectedFavSupplier ', $scope.selectedFavSupplier);
				if ($scope.selectedFavSupplier && $scope.selectedFavSupplier._id) {
					addBody.selectedFavSupplier = {
						_id: $scope.selectedFavSupplier._id,
						name: $scope.selectedFavSupplier.name,
						nameSlug: $scope.selectedFavSupplier.nameSlug,
					}
				}
				if ($scope.brand.isPoolArticleBrand) addBody.addAllProductsInShop = true; // If brand is pool article then any only we push prodcuts into shop.products
				//console.log('addBrandInShop: ', addBody);
				$scope.loading = true;
				$brand.addBrandInShop(addBody).then(function (response) {
					$scope.loading = false;
					$rootScope.brandLoader = false;
					$scope.cancel('brandadded');
				}).catch(function (error) {
					$scope.loading = false;
					$scope.error = true;
					$scope.errorMessage = "Internal server error. While update this brand.";
					$rootScope.brandLoader = false;
				});
			}
		}]);
