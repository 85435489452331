var app = prismanoteApp.controller("brandAssortmentController", ['$stateParams', '$scope', '$rootScope', '$uibModal', '$anchorScroll', '$api', '$q', '$language', '$retailer', 'prompt', '$language', '$product', '$state', '$shop', '$csv2json', '$collection', '$company',
    function ($stateParams, $scope, $rootScope, $uibModal, $anchorScroll, $api, $q, $language, $retailer, prompt, $language, $product, $state, $shop, $csv2json, $collection, $company) {

        $rootScope.pageTitle = 'PrismaNote Brand Portal | ' + $language.translate('TRANSACTIONS');

        $scope.filterCounter = 0;

        $scope.data = [];

        $scope.getThumbnailUrl = function(url){
            if(url){
                var index = url.indexOf('/');
                var folder = url.substring(0, index + 1);
                var fileName = url.replace(folder, '');
                return folder + 'prismathumbnail-' + fileName;
            }
        }

        $scope.$watch("filterCounter", function () {
            if ($scope.filterCounter > 7) {
                if ($scope.stockFilter && $scope.productFilter) {
                    if ($scope.stockFilter.discount ||
                        $scope.stockFilter.inStock ||
                        $scope.stockFilter.isBestseller ||
                        $scope.stockFilter.notOnWebShop ||
                        $scope.stockFilter.show ||
                        $scope.productFilter.gender ||
                        $scope.productFilter.collection ||
                        ($scope.productFilter.watch && $scope.productFilter.watch.hasDateFunction ||
                            $scope.productFilter.watch.hasLightFunction ||
                            $scope.productFilter.watch.hasSwissMovement ||
                            $scope.productFilter.watch.indication ||
                            $scope.productFilter.watch.isAntiAllergy ||
                            $scope.productFilter.watch.strap.model) ||
                        $scope.productFilter.brand) {
                        getStockModeProductsCompanyWise($rootScope.currentCompany._id, $scope.stockFilter, $scope.productFilter);
                    } else {
                        console.log("product handling guy How are You");
                        getStockModeProductsCompanyWise($rootScope.currentCompany._id, $scope.stockFilter, $scope.productFilter);
                    }
                }
                else {
                    console.log("No Product filter and stock filters");
                    getStockModeProductsCompanyWise($rootScope.currentCompany._id, $scope.stockFilter, $scope.productFilter);
                }
            }
        })


        $scope.changePage = function (position) {
            if (position == 'bottom') {
                $anchorScroll('paginate-bottom');
            }
        }

        $rootScope.selectdFilter = [];

        $scope.removeFilter = function (index) {
            var tempSelectedFilter = $scope.selectdFilter[index];
            if (tempSelectedFilter.filter == 'shop') {
                $scope.$broadcast('shop_filter_remove', { removeFilter: tempSelectedFilter });
            } else if (tempSelectedFilter.filter == 'category') {
                $scope.$broadcast('category_filter_remove', { removeFilter: tempSelectedFilter });
            } else if (tempSelectedFilter.filter == 'indication') {
                $scope.$broadcast('indication_filter_remove', { removeFilter: tempSelectedFilter });
            } else if (tempSelectedFilter.filter == 'Particulars') {
                $scope.$broadcast('particulars_filter_remove', { removeFilter: tempSelectedFilter });
            } else if (tempSelectedFilter.filter == "strap type") {
                $scope.$broadcast('strap_type_filter_remove', { removeFilter: tempSelectedFilter });
            } else if (tempSelectedFilter.filter == "Gender") {
                $scope.$broadcast('gender_filter_remove', { removeFilter: tempSelectedFilter });
            } else if (tempSelectedFilter.filter == "Brand") {
                $scope.$broadcast('brand_filter_remove', { removeFilter: tempSelectedFilter });
            } else if (tempSelectedFilter.filter == "Supplier") {
                $scope.$broadcast('supplier_filter_remove', { removeFilter: tempSelectedFilter });
            } else if (tempSelectedFilter.filter == "Collection") {
                $scope.$broadcast('client_collection_filter_remove', { removeFilter: tempSelectedFilter });
            }

        }

        $scope.loadStockMode = function (currentShopId) {
            $scope.stockLoad = false;
            $scope.stockFilter = { price: { $gte: 0, $lte: 30000000000 }, inStock: true };
            $scope.filterData = [];
            $scope.mode = 'stock';
            $scope.selectdCollections = [];
            $scope.pageSize = 12;
            $scope.currentPage = 1;
            $scope.productFilter = {
                watch: {
                    strap: {},
                }
            }

            console.log("Here you have check the mode");
            console.log($state.params.mode);
            if ($state && $state.params && $state.params.mode) {
                console.log("Favourite Supplier");
                getAllFavouriteSupplierFromShop($rootScope.currentShop._id);
            } else {
                // getStockModeProductsCompanyWise($rootScope.currentCompany._id, $scope.stockFilter, null);
            }
        }


        function getAllFavouriteSupplierFromShop(currentShopId) {

            console.log(" ------ Get All Favourite Supplier For Shop -----", currentShopId);

            $shop.getAllFavouriteSupplierFromShop(currentShopId)
                .then(function (favouriteSuppliers) {

                })
                .catch(function (errorInGettingFavouriteSupplier) {
                    console.error("Error in getting Favourite Suppliers", errorInGettingFavouriteSupplier);
                })
        }

        function getStockModeProductsCompanyWise(currentCompanyId, stockFilter, productFilter, orderBy) {
            $scope.stockLoad = true;
            $scope.stockProducts = [];
            $scope.mode = 'stock';

            $company.getStockModeProductsCompanyWise(currentCompanyId, stockFilter, $scope.productFilter, $scope.orderBy)
                .then(function (response) {
                    console.log("respose ====>", response);
                    if (!$scope.$$phase) {
                        $scope.$apply(function () {
                            $scope.shopFilters = response.filters;
                            $rootScope.shopFilters = response.filters;
                        });
                    } else {
                        $scope.shopFilters = response.filters;
                        $rootScope.shopFilters = response.filters;
                    }

                    $scope.stockLoad = false;
                    $scope.stockProducts = response.products;
                    $scope.oldProducts = response.products;
                    $scope.totalData = response.totalProducts;

                    if ($rootScope.productId) {
                        console.log(" ******************************** ProductId Form the Edit Page ********************************* ", $rootScope.productId);
                        var index = _.findIndex($scope.stockProducts, function (el) { return el._id === $rootScope.productId; });

                        delete $rootScope.productId;

                        if (index > -1) {
                            var activePage = index / 12;
                            $scope.currentPage = parseInt(activePage) + 1;
                            $scope.stockProducts[index].active = true;
                        } else {
                            $scope.currentPage = 1;
                        }
                    } else {
                        $scope.currentPage = 1;
                    }
                    console.log("product handling guy Check here first the count of the all the data");
                })
                .catch(function (err) {
                    $scope.stockLoad = false;
                    console.log("Error in Stock Mode Product");
                })
        }


        $scope.pageNumbering = function (currentPage, length) {
            var actualPage = currentPage * $scope.pageSize;
            var productCounter = actualPage - $scope.pageSize;
            if (actualPage >= length) actualPage = length;
            return productCounter + '-' + actualPage + ' (' + length + ')';
        }



        $scope.openProductModal = function (product, flag) {
            return $q(function (resolve, reject) {
                var modalInstance = $uibModal.open({
                    templateUrl: '../views/modal/company-product-modal.html',
                    controller: 'companyProductModalController',
                    size: 'lg',
                    resolve: {
                        product: function () {
                            return product;
                        }
                    }
                });

                modalInstance.result
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (reason) {
                        reject(reason);
                    });
            });
        };

        $scope.$on('sort_filter_directive', function (event, sort) {
            console.log("$sort =======>", sort);
            $scope.orderBy = sort;
            getStockModeProductsCompanyWise($rootScope.currentCompany._id, $scope.stockFilter, null, $scope.orderBy);

        })

        $scope.$on('limit_filter_directive', function (event, pageSize) {
            console.log('$limt per pag =======>', pageSize);
            $scope.pageSize = pageSize;
        })

        $scope.$on('product_filter_directive', function (event, params) {
            console.log("Make Shop product Filter", event, params);
            if (params.filter && params.filter.status && params.filter.status == 'localStorage') {
                $scope.filterCounter++;
                $scope.stockFilter = params.filter;
            } else if (params.filter) {
                $scope.stockFilter = params.filter;
                getStockModeProductsCompanyWise($rootScope.currentCompany._id, $scope.stockFilter, $scope.productFilter);
            }

        });

        $scope.$on('particulars_filter_directive', function (event, params) {

            $scope.productFilter.watch['hasLightFunction'] = (params.filter['watch.hasLightFunction']) ? params.filter['watch.hasLightFunction'] : false;
            $scope.productFilter.watch['hasDateFunction'] = (params.filter['watch.hasDateFunction']) ? params.filter['watch.hasDateFunction'] : false;
            $scope.productFilter.watch['hasSwissMovement'] = (params.filter['watch.hasSwissMovement']) ? params.filter['watch.hasSwissMovement'] : false;
            $scope.productFilter.watch['isAntiAllergy'] = (params.filter['watch.isAntiAllergy']) ? params.filter['watch.isAntiAllergy'] : false;
            $scope.productFilter.watch['isNickelFree'] = (params.filter['watch.isNickelFree']) ? params.filter['watch.isNickelFree'] : false;

            if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++;
            else getStockModeProductsCompanyWise($rootScope.currentCompany._id, $scope.stockFilter, $scope.productFilter);
        });

        $scope.$on('gender_filter_directive', function (event, params) {
            if (params.filter.gender) {
                $scope.productFilter.gender = params.filter.gender;
                if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++;
                else getStockModeProductsCompanyWise($rootScope.currentCompany._id, $scope.stockFilter, $scope.productFilter);
            } else {
                delete $scope.productFilter.gender;
                if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++;
                else getStockModeProductsCompanyWise($rootScope.currentCompany._id, $scope.stockFilter, $scope.productFilter);
            }
        });

        // indication_filter_directive data
        $scope.$on('indication_filter_directive', function (event, params) {
            if (params.filter['watch.indication']['$in'].length > 0) {
                $scope.productFilter.watch.indication = params.filter['watch.indication'];
                if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++;
                else getStockModeProductsCompanyWise($rootScope.currentCompany._id, $scope.stockFilter, $scope.productFilter);
            }
            else {
                $scope.productFilter.watch.indication = undefined;
                if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++;
                else getStockModeProductsCompanyWise($rootScope.currentCompany._id, $scope.stockFilter, $scope.productFilter);
            }
        });

        // category_filter_directive data
        $scope.$on('category_filter_directive', function (event, params) {

            console.log("params.filter['category']['$in'].length", params.filter['category']['$in'].length);
            if (params.filter['category']['$in'].length > 0) {
                $scope.productFilter.category = params.filter['category'];
                if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++;
                else getStockModeProductsCompanyWise($rootScope.currentCompany._id, $scope.stockFilter, $scope.productFilter);
            } else {
                console.log("We dont Have Send The category Filter On Server Side");
                if ($scope.productFilter.category) {
                    $scope.productFilter.category = undefined;
                    if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++;
                    else getStockModeProductsCompanyWise($rootScope.currentCompany._id, $scope.stockFilter, $scope.productFilter);
                } else {
                    if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++;
                    else getStockModeProductsCompanyWise($rootScope.currentCompany._id, $scope.stockFilter, $scope.productFilter);
                }
            }
        });

        // strap_model_filter_directive data
        $scope.$on('strap_model_filter_directive', function (event, params) {
            console.log("Params", params.filter['watch.strap.model']['$in'].length);
            if (params.filter['watch.strap.model']['$in'].length > 0) {
                $scope.productFilter.watch.strap.model = params.filter['watch.strap.model'];
                if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++;
                else getStockModeProductsCompanyWise($rootScope.currentCompany._id, $scope.stockFilter, $scope.productFilter);
            } else {
                $scope.productFilter.watch.strap.model = undefined;
                if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++;
                else getStockModeProductsCompanyWise($rootScope.currentCompany._id, $scope.stockFilter, $scope.productFilter);
            }
        });

        // price_filter_directive data
        $scope.$on('price_filter_directive', function (event, params) {
            $scope.stockFilter['price'] = {};

            if (params.great == 0 && params.less == undefined) {
                $scope.stockFilter.price['gte'] = params.great;
            } else if (params.less != undefined) {
                $scope.stockFilter.price['gte'] = params.great;
                $scope.stockFilter.price['lte'] = params.less;
            }
            getStockModeProductsCompanyWise($rootScope.currentCompany._id, $scope.stockFilter, $scope.productFilter);
        });

        $scope.$on('brand_filter_directive', function (event, params) {
            if (params.filter['brand._id'].$in.length > 0) {
                $scope.productFilter['brand'] = params.filter['brand._id'];
                if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++;
                else getStockModeProductsCompanyWise($rootScope.currentCompany._id, $scope.stockFilter, $scope.productFilter);
            } else {
                $scope.productFilter.brand = undefined;
                if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++;
                else getStockModeProductsCompanyWise($rootScope.currentCompany._id, $scope.stockFilter, $scope.productFilter);
            }
        });

        $scope.$on('collection_filter_directive', function (event, params) {

            if (params.filter['collection'].$in.length > 0) {
                $scope.productFilter.collection = params.filter['collection'].$in;
                if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++;
                else getStockModeProductsCompanyWise($rootScope.currentCompany._id, $scope.stockFilter, $scope.productFilter);
            } else {
                $scope.productFilter.collection = undefined;
                if (params.filter.status && params.filter.status == 'localStorage') $scope.filterCounter++;
                else getStockModeProductsCompanyWise($rootScope.currentCompany._id, $scope.stockFilter, $scope.productFilter);
            }

        });

        $scope.loadProductsForTheCSVWithoutFilters = function (shopFilters, productFilter, orderBy) { 
            // $scope.stockLoad = true;
            $scope.stockProducts = [];
            $company.getOwnCompanyProductsForCSVWithoutFilters($rootScope.currentCompany.nameSlug, shopFilters, productFilter, orderBy)
                .then(function (data) {
                    console.log('inside response!');
                    $scope.testcsv(data.productsForCSV, 'STOCK OVER VIEW', 'Test');
                })
                .catch(function (error) {
                    console.error("Error In CSV product data : ");
                    console.error(error);
                })
        }

         $scope.loadProductsForTheCSV = function (shopFilters, productFilter, orderBy) { 
            // $scope.stockLoad = true;
            $scope.stockProducts = [];
            $company.getOwnCompanyProductsForCSV($rootScope.currentCompany.nameSlug, shopFilters, productFilter, orderBy)
                .then(function (data) {
                    console.log('inside response!');
                    $scope.testcsv(data.productsForCSV, 'STOCK OVER VIEW', 'Test');
                })
                .catch(function (error) {
                    console.error("Error In CSV product data : ");
                    console.error(error);
                })
        }

        

        // $scope.loadProductsForTheCSV = function (shopFilters, productFilter, orderBy) {
        // $scope.stockLoad = true;
        // $scope.errMsg = false;
        // var limitCount = 200;
        // $scope.count = parseInt($scope.stockProducts.length/limitCount) + 1;
        // sample(shopFilters, productFilter, orderBy, 0, limitCount);
        // }

    function sample(shopFilters, productFilter, orderBy, pageNumber, limitCount) {
        $company.getOwnCompanyProductsForCSV($rootScope.currentCompany.nameSlug, shopFilters, productFilter, orderBy, pageNumber + 1, limitCount)
            .then(function (data) {
                $scope.data = $scope.data.concat(data.productsForCSV);
                if(pageNumber == $scope.count){
                    console.log('------------------ if!!');
                    $scope.testcsv($scope.data, 'STOCK OVER VIEW', 'Test');
                }else{
                    sample(shopFilters, productFilter, orderBy, pageNumber + 1, limitCount)
                }
            })
            .catch(function (error) {
                console.error("Error In CSV product data : ");
                console.error(error);
            })
     }

        $scope.testcsv = function (a, b, c) {
            console.log('calling inside test csv');
            var jsonForCsv = [];
            var aLength = a.length
            console.log(aLength);
            for (var i = 0; i < aLength; i++) {
                var stockUpDate = '';
                var lastOrderDate = '';
                if (a[i].stockUpDate) {
                    stockUpDate = new Date(a[i].stockUpDate);
                    stockUpDate = stockUpDate.getDate() + '-' + (parseInt(stockUpDate.getMonth()) + 1) + '-' + stockUpDate.getFullYear();
                } else {
                    stockUpDate = '';
                }

                if (a[i].lastOrderDate) {
                    lastOrderDate = new Date(a[i].lastOrderDate);
                    lastOrderDate = lastOrderDate.getDate() + '-' + (parseInt(lastOrderDate.getMonth()) + 1) + '-' + lastOrderDate.getFullYear();
                } else {
                    lastOrderDate = '';
                }
                console.log(a[i]);
                var singleObject = {
                    'shop articleNumber': (a[i].companyProduct.articleNumber) ? a[i].companyProduct.articleNumber : '',
                    'Stock': (a[i].companyProduct.stock) ? a[i].companyProduct.stock : 0,
                    'Min stock': (a[i].companyProduct.minStock) ? a[i].companyProduct.minStock : 0,
                    'Selling price incl VAT': (a[i].companyProduct.sellingPrice) ? (a[i].companyProduct.sellingPrice) : 0,
                    'Selling amount': (a[i].companyProduct.stock && a[i].companyProduct.sellingPrice) ? (a[i].companyProduct.stock * a[i].companyProduct.sellingPrice) : 0,
                    'Purchase amount': (a[i].companyProduct.purchasePrice) ? (a[i].companyProduct.purchasePrice) : 0,
                    'productNumber': a[i].product.productNumber,
                    'Weight gr/st': (a[i].product.weight) ? a[i].product.weight : 0,
                    'Edelst. gewicht krt/st': (a[i].product.totalCaratWeight) ? a[i].product.totalCaratWeight : 0,
                    'Category': (a[i].product.category) ? a[i].product.category : 'OTHER',
                    'Last Purchase Order Date': (lastOrderDate) ? lastOrderDate : '',
                    'Stock Up Date': (stockUpDate) ? stockUpDate : ''
                }

                if (a[i].product.brand) singleObject['Merk'] = (a[i].product.brand) ? a[i].product.brand : '';
                else singleObject['Merk'] = '';

                if (a[i].product[$rootScope.language]) singleObject['Product Name'] = (a[i].product[$rootScope.language].name) ? a[i].product[$rootScope.language].name : a[i].product.en.name;
                else singleObject['Product Name'] = a[i].product.en.name;

                jsonForCsv.push(singleObject);
                console.log(i === (aLength - 1));
                if (i === (aLength - 1)) {
                    $csv2json.JSONToCSVConvertor(jsonForCsv, b, c);
                    $scope.stockLoad = false;
                    $scope.stockProducts = $scope.oldProducts;
                }
            }
        }


        $scope.reloadPage = function () {
            window.location.reload()
        }

        $scope.selectCollection = function (collection) {
            var index = _.findIndex($rootScope.currentShop.collections, function (el) {
                return el._id === collection._id;
            });

            if ($scope.selectdCollections.includes(collection._id)) {
                var indexTemp = $scope.selectdCollections.indexOf(collection._id);
                $scope.selectdCollections.splice(indexTemp, 1);
                $rootScope.currentShop.collections[index].applied = false;
            } else {
                $rootScope.currentShop.collections[index].applied = true;
                $scope.selectdCollections.push(collection._id);
            }
            console.log("$scope.selectdCollections", $scope.selectdCollections);
            $scope.productFilter.collection = $scope.selectdCollections;
            getStockModeProductsCompanyWise($rootScope.currentCompany._id, $scope.stockFilter, $scope.productFilter);

        }

        $rootScope.selectCollectionFromModal = function (collectionId) {
            console.log("CHeck here for the COllections one selcted from delete", collectionId);
            $scope.selectdCollections = [];

            $scope.selectdCollections.push(collectionId);
            console.log("$scope.selectdCollections", $scope.selectdCollections);
            $scope.productFilter.collection = $scope.selectdCollections;
            getStockModeProductsCompanyWise($rootScope.currentCompany._id, $scope.stockFilter, $scope.productFilter);
        }


        $scope.openSetCollections = function () {
            console.log("Open Collections Modal For the setting that all");
            $scope.showLoader = true;
            $company.getCompanyCollections($rootScope.currentCompany._id)
                .then(function (success) {

                    $scope.showLoader = false;

                    $rootScope.companyCollections = success.data.collections;

                    if ($scope.companyCollections.length > 0) {
                        var modalInstance = $uibModal.open({
                            templateUrl: '../views/modal/company-collections-show-options-modal.html',
                            controller: ['$scope', '$uibModalInstance', '$api', '$rootScope', 'prompt', '$collection',
                                function ($scope, $uibModalInstance, $api, $rootScope, prompt, $collection) {

                                    console.log("companyCollections", $rootScope.companyCollections);
                                    $scope.editCollection = false;
                                    $scope.saveCollectionsOptions = function () {
                                        $company.changeCompanyCollectionsShowOptions($rootScope.currentCompany._id, $rootScope.companyCollections)
                                            .then(function (success) {
                                                console.log("Success Data ====> ", success);
                                                $scope.cancel();
                                            })
                                            .catch(function (err) {
                                                console.error("Error in the modify shop collections");
                                            })
                                    }


                                    $scope.editClientGroupsCollection = function (shopId, collection, singleIndex) {
                                        $scope.errorLog = {};
                                        var tempCollection = $rootScope.companyCollections[singleIndex];
                                        $scope.collectionTemp = JSON.parse(JSON.stringify(tempCollection));
                                        $rootScope.companyCollections[singleIndex].edit = true;
                                        $scope.editCollection = true;
                                    }

                                    $scope.cancleUpdate = function (singleIndex) {
                                        delete $rootScope.companyCollections[singleIndex].edit;
                                        $scope.editCollection = false;
                                        $rootScope.companyCollections[singleIndex] = $scope.collectionTemp;
                                    }

                                    $scope.updateCollection = function (companyId, index) {
                                        console.log("index", index);

                                        $collection.updateOwnClientGroupCollection(companyId, $rootScope.companyCollections[index])
                                            .then(function (error, updatedCollection) {
                                                delete $rootScope.companyCollections[index].edit;
                                                $scope.editCollection = false;
                                                console.log("Updated Collection", updatedCollection);
                                            })
                                            .catch(function (error) {
                                                if (error.status == 409) {
                                                    $scope.errorLog = error.data.error;
                                                } else {
                                                    console.error("Error In ", error);
                                                }

                                            })
                                    }

                                    $scope.checkCollectionName = function (name, language) {
                                        console.log("Please Check enterd Name", name);
                                        name = name.toLowerCase();
                                        if (name == 'services') {
                                            $scope.errorLog[language] = true;
                                        } else {
                                            $scope.errorLog[language] = false;

                                        }
                                    }



                                    // Delete Own Client Group Collection
                                    $scope.deleteOwnClientGroupCollection = function (companyId, collectionId) {

                                        $collection.deleteOwnClientGroupCollection(companyId, collectionId)
                                            .then(function (success) {
                                                console.log("Success Data ====> ", success);
                                                // Get All Shop Collections
                                                $company.getCompanyCollections($rootScope.currentCompany._id)
                                                    .then(function (success) {
                                                        $rootScope.companyCollections = success.data.collections;
                                                    })
                                                    .catch(function (error) {
                                                    })
                                            })
                                            .catch(function (err) {
                                                if (err.status == 400 && err.data.status == true) {
                                                    prompt({
                                                        title: "Sorry! You can not delete this Collection",
                                                        message: "This Collection is used in " + err.data.data + ' products! Click on OK and we will redirect you to all the products of this collection. In this way you can replace the product collections to another collection'
                                                    }).then(function () {
                                                        console.log("Clicked on ok", collectionId);
                                                        $rootScope.selectCollectionFromModal(collectionId);
                                                        $uibModalInstance.dismiss('cancel');
                                                    }, function () {
                                                        reject("Cancel");
                                                    })
                                                } else {
                                                    console.error("Error in remove Private Lable Collection", err);
                                                }
                                            })
                                    }

                                    $scope.cancel = function () {
                                        $uibModalInstance.dismiss('cancel');
                                    }
                                }],
                            size: 'lg'
                        });
                    }
                })
                .catch(function (err) {
                    console.error("Error In Finding the Shop Collections", err);
                })
        }


        $scope.deleteStockProduct = function (id) {

            var index = _.findIndex($scope.stockProducts, function (el) {
                return el._id === id;
            });

            if (index < 0) { return; }
            $scope.stockProducts[index].companyProduct.loading = true;


            console.log("$scope.stockProducts[index]", $scope.stockProducts[index]);

            if ($scope.stockProducts && $scope.stockProducts[index] && $scope.stockProducts[index].product && $scope.stockProducts[index].product.brand && $scope.stockProducts[index].product.brand.nameSlug) {
                console.log("product handling guy", $rootScope.currentCompany.brands);
                var brand = $rootScope.currentCompany.brands.find(function (brand) { return brand._id.nameSlug == $scope.stockProducts[index].product.brand.nameSlug }) || null;
                console.log("brand ", brand);
                if (brand && brand._id && brand._id.restricted) {
                    prompt({
                        title: $language.translate('DELETE_PRODUCT') + '?',
                        message: $language.translate('YOU_ARE_OFFICIAL_DEALER_PRODUCT_STAYS_IN_ASSORTMENT')
                    }).then(function () {
                        $company.updateCompanyProductStockAndShow($scope.stockProducts[index]._id, $rootScope.currentCompany._id)
                            .then(function (success) {
                                $scope.stockProducts.splice(index, 1);
                            })
                            .catch(function (error) {
                                console.error(error);
                            })
                    }, function () {
                        reject("Cancel");
                    })
                }
                else {

                    prompt({
                        title: $language.translate('DELETE_PRODUCT') + '?',
                        message: $language.translate('ARE_YOU_SURE_TO_DELETE_THIS_PRODUCT'),
                        buttons: [
                            { label: $language.translate('DELETE_PRODUCT'), primary: true, id: 0 },
                            { label: $language.translate('STOP_SELLING_STOCK_TO_0'), primary: true, id: 1 },
                            { label: $language.translate('CANCEL'), primary: false, cancel: true, id: 2 }
                        ]
                    }).then(function (value) {
                        if (value && value.id == 0) {
                            $company.deleteProductFromCompany($scope.stockProducts[index]._id, $rootScope.currentCompany._id)
                                .then(function (result) {
                                    $scope.stockProducts.splice(index, 1);
                                })
                                .catch(function (error) {
                                    return resolve(error);
                                })
                        } else if (value && value.id == 1) {
                            $company.updateCompanyProductStockAndShow($scope.stockProducts[index]._id, $rootScope.currentCompany._id)
                                .then(function (success) {
                                    $scope.stockProducts.splice(index, 1);
                                })
                                .catch(function (error) {
                                    console.error(error);
                                })
                        } else {
                            console.log("I don't delete this product");
                        }

                    })
                        .catch(function (error) {
                            console.log("Error ====> Delete Product", error);
                            console.error(error);
                            $scope.stockProducts[index].companyProduct.loading = false;
                        });
                }

            } else {
                prompt({
                    title: $language.translate('DELETE_PRODUCT') + '?',
                    message: $language.translate('ARE_YOU_SURE_TO_DELETE_THIS_PRODUCT'),
                    buttons: [
                        { label: $language.translate('DELETE_PRODUCT'), primary: true, id: 0 },
                        { label: $language.translate('STOP_SELLING_STOCK_TO_0'), primary: true, id: 1 },
                        { label: $language.translate('CANCEL'), primary: false, cancel: true, id: 2 }
                    ]
                }).then(function (value) {
                    console.log("Success  from the data of the prompt", value);
                    if (value && value.id == 0) {
                        console.log("product handling guy Please You have to remove this product from shop", $scope.stockProducts[index]);
                        $company.deleteProductFromCompany($scope.stockProducts[index]._id, $rootScope.currentCompany._id)
                            .then(function (result) {
                                console.log("Product is successfully delated", result);
                                $scope.stockProducts.splice(index, 1);
                            })
                            .catch(function (error) {
                                console.error(" Error In delete product from the shop", error);
                            })
                    } else if (value && value.id == 1) {
                        console.log("product handling guy Please you have to only remove the stock and stop to sell this product", $scope.stockProducts[index]);
                        $company.updateCompanyProductStockAndShow($scope.stockProducts[index]._id, $rootScope.currentCompany._id)
                            .then(function (success) {
                                $scope.stockProducts.splice(index, 1);
                            })
                            .catch(function (error) {
                                console.error("Error in updatecompanyProductStockAndShow", error);
                            })
                    } else {
                        console.log("I don't delete this product");
                    }

                })
                    .catch(function (error) {
                        console.log("Error ====> Delete Product", error);
                        console.error(error);
                        $scope.stockProducts[index].companyProduct.loading = false;
                    });
            }

        }

        $scope.searchProduct = function (searchValue) {
            console.log("Search Value", searchValue);
            // Request a query for the search

            $scope.stockLoad = true;
            $scope.stockProducts = [];
            if (searchValue) {
                $company.searchCompanyProducts($rootScope.currentCompany.nameSlug, searchValue)
                    .then(function (resultProducts) {
                        console.log("Search Result Products", resultProducts);
                        $scope.stockProducts = resultProducts;
                        $scope.stockLoad = false;
                    })
                    .catch(function (error) {
                        console.error("Error", error);
                    })
            } else {
                $scope.stockProducts = $scope.oldProducts;
                $scope.stockLoad = false;
            }
        }

        $scope.openPrintLabelModal = function (product) {
            var modalInstance = $uibModal.open({
                templateUrl: '../views/modal/print-label-modal.html',
                controller: 'printLabelModalController',
                resolve: {
                    product: function () {
                        return product;
                    },
                    loadProduct: function(){
                        return true;
                    },
                    labelDescription: function() {
                        return null
                    },
                    empty: function () {
                        return false
                    }
                }
            })

            //we will get the value here
            modalInstance.result.then(function (result) {
                if (result) {
                    //ready
                    $scope.product.labelDescription = result.labelDescription ? result.labelDescription : '';
                }
            }, function () {
                //dismissed
            })
        }

        $scope.showSuggestionsLoop = function () {
            console.log('showSuggestionsLoop called');
            prompt({
              title: $language.translate('CONFIRMATION'),
              message: $language.translate('ARE_YOU_SURE_TO_TO_PUT_EVERYTHING_ONLINE')
            }).then(function (res) {
              console.log('res: ', res);
              if (res && res.cancel) return true;
              console.log('$rootScope.currentCompany._id', $rootScope.currentCompany)
              var body = {
                companyId: $rootScope.currentCompany._id
              }
              console.log('callig company service')
              $company.makeCompanyProudctShowTrue(body).then(function (response) {
                console.log('response', response)
              }).catch(function (error) {
                console.error('makeCompanyProudctShowTrue Error', error)
              })
            }, function () {
              reject('Cancel')
            })
          }


    }])