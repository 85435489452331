prismanoteApp.controller('giftcardLabelsModalController', ['$scope', '$uibModalInstance', '$rootScope', '$api', '$stateParams', '$uibModal', 'printer', '$label', 'prompt','$window',
function ($scope, $uibModalInstance, $rootScope, $api, $stateParams, $uibModal, printer, $label, prompt, $window) {
  
  $scope.printer = printer
  $scope.isPrinting = false

  $scope.close = function (options) {
    $uibModalInstance.close()
  }

  $scope.generateString = function() {
    var result = 'G-'
    for (var i = 0; i < 8; i++ ) {
        result += 'ABCDEFG2345987623459876HKLPQ'.charAt(Math.floor(Math.random() * 'ABCDEFG2345987623459876HKLPQ'.length));
    }
    return result;
  }

  $scope.startPrinting = function(command) {
    $label.printRAW(command)
      .then(function (result) {
        $scope.isPrinting = false
        console.log('result', result)
      })
      .catch(function (reason){
        $scope.isPrinting = false
        console.error('Error sending command to printer', reason)
        toastr.error(reason)
      })
  }

  $scope.generateAndPrint = function(nrOfLabels) {

    $scope.isPrinting = true

    var printCommands = []

    for (var i = 0; i < nrOfLabels; i++ ) {
      var first = $scope.generateString()
      var second = $scope.generateString()
      printCommands.push("^XA^XFE:LAYOUTGL.ZPL^FN1^FWN^FD"+first+"^FS^FN2^FWN^FD"+first+"^FS^FN3^FWN^FD"+second+"^FS^FN4^FWN^FD"+second+"^FS^XZ")
    }

    if(printCommands.length > 0) {
      var command = printCommands.join("")
      if(nrOfLabels > 1) {
        if($window.confirm(String("Weet u zeker dat u "+nrOfLabels+" labels wilt printen?"))) {
          $scope.startPrinting(command)
        } else {
          $scope.isPrinting = false
        }
      } else {
        $scope.startPrinting(command)
      }
    }

  }

  // $scope.enableCommandField = function() {
  //   if($scope.disableCommandField) {
  //     prompt({
	// 			title: "Waarschuwing",
	// 			message: "Het sturen van aangepaste commando kan onvoorziene gevolgen hebben, en we raden u dan ook contact op te nemen met support voor het doorvoeren van wijzigingen."
	// 		  }).then(function (res) {
  //         console.log(res)
	// 			  if(res.primary && res.primary === true) {

  //           $scope.disableCommandField = false
  //           console.log($scope.disableCommandField)

	// 				.catch(function (reason) {
	// 					console.error('Error while closing modal', reason)
	// 				})
	// 			  }
	// 		})
  //   }
  // }

  }])