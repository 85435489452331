prismanoteApp.controller("purchaseOrderProductBrandSelectionModalController", ['$scope', '$uibModalInstance', '$rootScope', '$shop', '$stateParams', '$uibModal', '$state', 'prompt', 'Upload', 'search_value', '$product', '$productConfig', '$brand',
  function ($scope, $uibModalInstance, $rootScope, $shop, $stateParams, $uibModal, $state, prompt, Upload, search_value, $product, $productConfig, $brand) {

    // return;

    // Step 1: Have to get Shop Brands
    // Step 2: Retailer Can select brand for the duplicate product
    // Step 3: Still the product number is not unique then have to add the shop starting digits.
    // Step 4: Make product number based on step -2 and step 3

    $scope.renderModalData = function () {
      console.log("Render Modal Data");

      $scope.product = {
        canEIPN: false,
        insertedProductNumber: search_value,
      }

      if ($rootScope.currentShop && $rootScope.currentShop.nameSlug) {
        $scope.mode = 'shop'
        $scope.getBrandsByShop($rootScope.currentShop.nameSlug);
        makeProductNumber($rootScope.currentShop.startingDigits, null, search_value)
      } else if ($rootScope.currentCompany && $rootScope.currentCompany.nameSlug) {
        $scope.mode = 'company'
        $scope.getCompanyOwnBrands($rootScope.currentCompany._id);
        makeProductNumber($rootScope.currentCompany.startingDigits, null, search_value)
      } else {
        console.log("This modal is open form unauthorise access")
      }
    }




    $scope.getCompanyOwnBrands = function (companyId) {
      // Get Shops All the Brands
      console.log("Please Do request for the all the brands")
      $brand.getCompanyOwnBrands(companyId)
        .then(function (response) {
          console.log(" ****** product handling guy Check this ::::", response);
          var lengthOfData = response.data.length || 0
          if (lengthOfData > 0) {
            $scope.shopBrands = []
            for (var i = 0; i < lengthOfData; i++) {
              $scope.shopBrands[i] = response.data[i].officialBrands;
            }
          } else {
            $scope.shopBrands = [];
          }
        })
        .catch(function (err) {
          console.error("Error while getting products", err);
        });
    }


    $scope.getBrandsByShop = function (shopNameSlug) {
      // Get Shops All the Brands
      console.log("Please Do request for the all the brands")
      $shop.getshopbrandsForProduct(shopNameSlug)
        .then(function (response) {
          console.log(" ****** product handling guy Check this ::::", response);
          if (response.brands.length > 0) {
            $scope.shopBrands = response.brands;
            console.log("Shop Products");
          } else {
            $scope.shopBrands = [];
          }
        })
        .catch(function (err) {
          console.error("Error while getting products", err);
        });
    }

    $scope.brandSelected = function (selected) {
      console.log("selected brand ===>", selected.originalObject);

      if ($rootScope.currentShop) {
        $scope.product.brand = {
          _id: selected.originalObject._id._id,
          name: selected.originalObject._id[$rootScope.language].name,
          startingDigits: (selected.originalObject._id.startingDigits && selected.originalObject._id.startingDigits != 'null' && selected.originalObject._id.startingDigits != 'undefined' && selected.originalObject._id.startingDigits != 'NULL') ? selected.originalObject._id.startingDigits : '',
        }
        makeProductNumber($rootScope.currentShop.startingDigits, $scope.product.brand.startingDigits, $scope.product.insertedProductNumber)
      } else if ($rootScope.currentCompany) {
        $scope.product.brand = {
          _id: selected.originalObject._id,
          name: selected.originalObject[$rootScope.language].name,
          startingDigits: (selected.originalObject.startingDigits && selected.originalObject.startingDigits != 'null' && selected.originalObject.startingDigits != 'undefined' && selected.originalObject.startingDigits != 'NULL') ? selected.originalObject.startingDigits : '',
        }
        makeProductNumber($rootScope.currentCompany.startingDigits, $scope.product.brand.startingDigits, $scope.product.insertedProductNumber)
      } else {
        console.log("user is not here")
      }
    };

    $scope.searchBrands = function (str) {
      var matches = [];


      if ($rootScope.currentShop) {
        $scope.shopBrands.forEach(function (brand) {
          if (brand._id) {
            // console.log("brand._id", brand._id);
            if ((brand._id[$rootScope.language] != undefined && brand._id[$rootScope.language].name != undefined) && ((brand._id[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) || (brand._id[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0))) matches.push(brand);
          }
        });
        return matches;
      } else {
        $scope.shopBrands.forEach(function (brand) {
          if (brand._id) {
            console.log("brand._id", brand);
            if ((brand[$rootScope.language] != undefined && brand[$rootScope.language].name != undefined) && ((brand[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) || (brand[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0))) matches.push(brand);
          }
        });
        return matches;
      }


    }

    function makeProductNumber(shopStarting, brandStrating, insertedProductNumber) {
      // console.group("Make a duplicate Product Number");
      // console.log("shopStarting :::: ", shopStarting);
      // console.log("brandStrating :::: ", brandStrating);
      // console.log("insertedProductNumber :::: ", insertedProductNumber);
      $scope.product.productNumber = '';
      $scope.product.productNumber = shopStarting + '-';
      if (brandStrating) $scope.product.productNumber = $scope.product.productNumber + brandStrating + '-';
      $scope.product.productNumber = $scope.product.productNumber + $scope.product.insertedProductNumber;
      $scope.productNumberIsExistsOrNot($scope.product.productNumber);
      console.log("--------------------> New Product Number :::: ", $scope.product.productNumber);
      // console.groupEnd();
    }

    $scope.productNumberIsExistsOrNot = function (productNumber) {

      $scope.PNvalid = false;
      $scope.PNsort = true;

      if (productNumber) {
        productNumber = $productConfig.retrunValidProductNumber(productNumber);
        if (productNumber && productNumber.length > 2) {
          $scope.PNsort = false;
          // Product Number is valid Or not
          $product.productNumberIsExistsOrNot(productNumber, null)
            .then(function (result) {
              console.log("Response From Server", result);
              if (result.status == true) {
                $scope.PNvalid = true;
                $scope.product.productNumber = productNumber;
              } else {
                $scope.product.productNumber = productNumber;
              }
            })
            .catch(function (error) {
              console.error("Error =>>> ", error);
            })
        }
      }
    }

    $scope.useAsUnofficialProduct = function () {
      console.log("useAsUnofficialProduct ", $scope.product);
      $uibModalInstance.close({
        status: 'unofficial-product',
        product: $scope.product
      });
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    }

    $scope.makeNewProductNumber = function (product) {

      product.insertedProductNumber = product.insertedProductNumber.toUpperCase()


      if (product.insertedProductNumber && product.insertedProductNumber.length > 2) {
        $scope.PNvalid = true

        if ($rootScope.currentShop && $rootScope.currentShop.startingDigits) {
          if (product.brand && product.brand.startingDigits) makeProductNumber($rootScope.currentShop.startingDigits, product.brand.startingDigits, product.insertedProductNumber);
          else makeProductNumber($rootScope.currentShop.startingDigits, null, product.insertedProductNumber);
        } else if ($rootScope.currentCompany && $rootScope.currentCompany.startingDigits) {
          if (product.brand && product.brand.startingDigits) makeProductNumber($rootScope.currentCompany.startingDigits, product.brand.startingDigits, product.insertedProductNumber);
          else makeProductNumber($rootScope.currentCompany.startingDigits, null, product.insertedProductNumber);
        } else {
          $scope.PNvalid = true
          console.log(" It's here about not proper, contact to prismanote admin ")
        }
      } else {
        // $scope.PNsort = true
        $scope.PNvalid = false
      }



    }
  }
]);