prismanoteApp.controller('executeCampaignModalController', ['$scope', 'data', '$api', '$http', 'FileSaver', '$window', 'Upload',
  function ($scope, data, $api, $http, FileSaver, $window, Upload) {
    $scope.campaign = data.campaign
    $scope.user = data.user
    $scope.confirmExecute = false
    $scope.loading = false
    $scope.facebookLoading = {}

    var today = new Date()
    $scope.today = new Date()
    $scope.maxDate = new Date(today.setMonth((today.getMonth() + 6)))
    localStorage.getItem('pageIdFromSetting')

    function findTaskById (taskId) {
      for (var i = 0; i < $scope.user.socialPortal.campaigns.length; i++) {
        if ($scope.user.socialPortal.campaigns[i]._id == $scope.campaign._id) {
          for (var t = 0; t < $scope.user.socialPortal.campaigns[i].tasks.length; t++) {
            if ($scope.user.socialPortal.campaigns[i].tasks[t]._id == taskId) return $scope.user.socialPortal.campaigns[i].tasks[t]
          }
        }
      }
    }

    function findCampaign () {
      for (var i = 0; i < $scope.user.socialPortal.campaigns.length; i++) {
        if ($scope.user.socialPortal.campaigns[i]._id == $scope.campaign._id) return $scope.user.socialPortal.campaigns[i]
      }
    }

    $scope.openDate = function (task) {
      task.dateOpened = true
    }

    $scope.download = function (taskId, index) {
      $scope.loading = false
      var task = findTaskById(taskId)
      $http({
        method: 'GET',
        url: '/api/download-task-files',
        params: {
          campaignId: $scope.campaign._id,
          taskId: taskId,
          portalId: $scope.user.socialPortal._id
        },
        responseType: 'arraybuffer'
      })
        .then(function (response) {
          $scope.loading = false
          var file = new Blob([response.data], { type: 'application/zip' })

          FileSaver.saveAs(file, $scope.slugify(task.nl.name) + '.zip')
          $scope.addLogItem({
            title: 'Bestanden gedownload voor ' + task.nl.name,
            type: 'task',
            taskId: task._id,
            user: $scope.user.firstName + ' ' + $scope.user.lastNamePrefix + ' ' + $scope.user.lastName
          }, true)
        }, function (response) {
          console.error('Error while downloading files', response)
        })

      $scope.executed = {
        task: 'downloaden',
        index: index
      }
    }

    $scope.directOrder = function (taskId, index) {
      var task = findTaskById(taskId)
      $window.open(task.orderLink)

      $scope.executed = {
        task: 'bestellen',
        index: index
      }
    }

    $scope.order = function (taskId, index) {
      $scope.executed = {
        task: 'bestellen',
        index: index
      }
      $scope.planTask(findTask(taskId))
    }

    $scope.confirmTask = function (taskId, taskString) {
      var task = findTaskById(taskId)

      task.completed = true

      $scope.saveSocialPortal()
    }

    $scope.retryTask = function (index, task) {
      if (task == 'downloaden') $scope.download(index)
      else $scope.order(index)
    }

    $scope.planTask = function (task) {
      var campaign = findCampaign()
      var newTask = {
        title: task.nl.name,
        date: task.datePlanned,
        status: 'Open',
        completed: false,
        type: task.type,
        url: task.orderLink,
        taskId: task._id,
        campaignId: campaign._id
      }

      $scope.user.socialPortal.taskList.push(newTask)
      $scope.confirmTask(task._id, 'Bestellen')
      $scope.addLogItem({
        title: newTask.title + ' is als taak aangemaakt en ingepland op ' + newTask.date.toString(),
        type: task.type,
        taskId: task._id,
        user: $scope.user.firstName + ' ' + $scope.user.lastNamePrefix + ' ' + $scope.user.lastName
      }, false)

      $scope.saveSocialPortal()
    }

    // Deprecated
    $scope.saveSocialPortal = function () {
      // $scope.loading = true;
      // $api.put('user-social-portal', { socialPortal: $scope.user.socialPortal })
      //     .then(function (response) {
      //         $scope.loading = false;
      //     })
      //     .catch(function (reason) {
      //         console.error("Error while saving social portal", reason);
      //     })
    }
    $scope.addLogItem = function (item, save) {
      var logItem = {
        title: item.title,
        date: new Date(),
        type: item.type,
        taskId: item.taskId,
        user: item.user,
        campaignId: item.campaignId
      }
      $scope.user.socialPortal.log.push(logItem)

      if (save) {
        $scope.saveSocialPortal()
      }
    }

    $scope.executeCampaign = function (taskId, index) {
      var task = findTaskById(taskId)
      var campaign = findCampaign()

      if (campaign.strategy != 'store-promotion') {
        $scope.sendPromotion(campaign, task, taskId, index)
      } else {
        // upload images and send promotion
        $scope.loading = true
        Upload.upload({
          url: '/api/upload-promotion-images',
          data: {
            files: task.files,
            campaign: JSON.stringify(campaign),
            user: JSON.stringify($scope.user),
            task: JSON.stringify(task)
          }
        }).then(function (res) {
          // Upload is succesfull, add the result to the scope, send the order and save the task
          if (res.data.files.length > 0) {
            task.files = res.data.files
          }

          task.images.splice(0, 1) // removes first item which is the standard picture
          for (var i = 0; i < task.files.length; i++) {
            task.images.push({ src: task.files[i].path, alt: task.files[i].name })
          }
          // adding photo for campaign portal
          campaign.images.splice(0, 1)
          campaign.images.push({ src: 'https://s3-eu-west-1.amazonaws.com/prismanotetest/' + task.files[0].path, alt: task.files[0].name })

          $scope.sendPromotion(campaign, task, taskId, index)
        }, function (res) {
          console.error('Error while uploading', res)
        }, function (evt) {
          // progress
        })
      }
    }

    $scope.sendPromotion = function (campaign, task, taskId, index) {
      $scope.loading = true
      $api.post('send-promotion', { campaign: campaign, user: $scope.user, task: task })
        .then(function (response) {
          $scope.executed = {
            task: 'uitvoeren',
            msg: 'De campagne is doorgestuurd naar onze specialisten. Er wordt zo snel mogelijk contact met u opgenomen',
            type: 'success',
            index: index
          }

          $scope.confirmTask(taskId, 'Uitvoeren')
          $scope.loading = false
          $scope.addLogItem({
            title: task.nl.name + ' is als campagne doorgestuurd naar het PrismaNote team',
            type: 'facebook',
            taskId: task._id,
            user: $scope.user.firstName + ' ' + $scope.user.lastNamePrefix + ' ' + $scope.user.lastName
          }, true)
        })
        .catch(function (reason) {
          console.error('Error while sending promotion', reason)
        })
    }

    $scope.confirm = function (cancel) {
      if (!cancel) {
        $scope.confirmExecute = {
          type: 'info',
          msg: 'Weet u zeker dat u al deze taken wilt uitvoeren?',
          buttons: true
        }
      } else {
        $scope.confirmExecute = null
      }
    }

    $scope.executeAll = function () {
      var campaign = findCampaign()
      for (var i = 0; i < campaign.tasks.length; i++) {
        var task = campaign.tasks[i]
        var index = 0
        if (task.execute) {
          // alle taken doorlopen aan de hand van het type
          if (!task.completed) {
            if (task.type != 'order') {
              if (campaign.update) {
                if (task.type == 'facebook') {
                  // geplande facebook update
                  $scope.postFacebookUpdate(task._id, true, i)
                } else {
                  $scope.planTask(task)
                }
              } else {
                // campagne
                $scope.executeCampaign(task._id, index)
              }
            } else {
              // besteltaak
              $scope.planTask(task)
            }
          }
          index++
        }

        if (i == campaign.tasks.length) {
          $scope.confirmExecute = {
            type: 'success',
            buttons: false,
            msg: 'Alle taken zijn uitgevoerd of ingepland!'
          }
        }
      }
    }

    $scope.checkForOpenTasks = function () {
      var campaign = findCampaign()
      for (var i = 0; i < campaign.tasks.length; i++) {
        if (!campaign.tasks[i].completed) {
          return true
        }
      }
    }

    $scope.checkForExecutableTasks = function () {
      var campaign = findCampaign()
      for (var i = 0; i < campaign.tasks.length; i++) {
        if (campaign.tasks[i].execute) {
          return true
        }
      }
    }

    $scope.slugify = function (text) {
      return text.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w\-]+/g, '') // Remove all non-word chars
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
    }

    $scope.postFacebookUpdate = function (taskId, schedule, index) {
      var task = findTaskById(taskId)
      $scope.facebookLoading.index = index
      localStorage.getItem('pageIdFromSetting')
      if (localStorage.getItem('pageIdFromSetting') == undefined) {
        alert('please select a page from settings')
        $scope.facebookLoading = false
        return
      }
      var data = {
        url: task.images[0].src,

        caption: localStorage.getItem('myDescription'),
        pageId: localStorage.getItem('pageIdFromSetting')
      }
      if (schedule == true) {
        data.date = task.datePlanned
        data.scheduled = true
      }

      $api.post('facebook/post', { postData: data })
        .then(function (response) {
          $scope.facebookResponse = {
            task: 'facebook',
            msg: !schedule ? 'Dit bericht is nu geplaatst op Facebook!' : 'Dit bericht is ingepland op de gekozen datum',
            type: 'success',
            index: index
          }
          $scope.facebookLoading = {}

          if (schedule) {
            $scope.confirmTask(taskId, 'Ingepland')

            $scope.addLogItem({
              title: task.nl.name + ' is als ingepland als Facebookbericht op ' + task.datePlanned.toString(),
              type: 'facebook',
              taskId: task._id,
              user: $scope.user.firstName + ' ' + $scope.user.lastNamePrefix + ' ' + $scope.user.lastName
            }, true)
          } else {
            $scope.confirmTask(taskId, 'Geplaatst')

            $scope.addLogItem({
              title: task.nl.name + ' is geplaatst als Facebookbericht op de ingestelde pagina',
              type: 'facebook',
              taskId: task._id,
              user: $scope.user.firstName + ' ' + $scope.user.lastNamePrefix + ' ' + $scope.user.lastName
            }, true)
          }
        })
        .catch(function (reason) {
          console.error('Error while placing to Facebook', reason)
          $scope.facebookResponse = {
            task: 'facebook',
            msg: 'Fout bij het plaatsen naar Facebook: ' + reason,
            type: 'warning',
            index: index
          }
          $scope.facebookLoading = {}
          $scope.addLogItem({
            title: 'Fout bij versturen naar Facebook voor update ' + task.nl.name + ': ' + reason,
            type: 'facebook',
            taskId: task._id,
            user: $scope.user.firstName + ' ' + $scope.user.lastNamePrefix + ' ' + $scope.user.lastName
          }, true)
        })
    }

    $scope.timepickerOptions = {
      readonlyInput: false,
      showMeridian: false
    }
  }])
