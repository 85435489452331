prismanoteApp.controller('createShopModalController', ['$scope', '$uibModalInstance', '$api',
  function ($scope, $uibModalInstance, $api) {
    $scope.shop = {}

    $scope.closeModal = function () {
      $uibModalInstance.close($scope.shop)
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }
  }])
