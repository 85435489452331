prismanoteApp.controller('adminProductsToDeleteController', ['$scope', '$rootScope', '$product',
  function ($scope, $rootScope, $product) {
    console.log('Have to Show All product Delete Suggstion')
    console.log('product handling guy Check this first')

    $scope.renderPage = function () {
      $scope.loading = true
      // $scope.products = [];
      getDeleteProductsSuggestions()
    }

    $scope.convertDate = function (date) {
      var d = new Date(date)
      return d.toLocaleString()
    }

    function getDeleteProductsSuggestions () {
      $product.getProductWithDeleteSuggestions()
        .then(function (products) {
          console.log('Products', products)
          $scope.products = products.products
          $scope.loading = false
        })
        .catch(function (error) {
          console.log('Error, While Getting Delete Products Suggestions', error)
        })
    }

    // $scope.showToast = function (text) {
    // 	$(document).ready(function () {
    // 		console.log("DOING TOAST");
    // 		$.toast({
    // 			text: text,
    // 			hideAfter: 2000,
    // 			showHideTransition: 'slide',
    // 			hideAfter: true,
    // 			position: 'top-right'
    // 		})

    // 	});
    // }

    // // Array with all currently displayed items in /products
    // $scope.products = [];

    // $scope.selection = [];

    // $scope.productCategory = [];

    // $scope.isVerified = true;

    // $scope.isNotVerified = true;

    // $scope.clickToCopy = function (id) {
    // 	var element = "#id-" + id;
    // 	var $temp = $("<input>");
    // 	$("body").append($temp);
    // 	$temp.val($(element).text()).select();
    // 	document.execCommand("copy");
    // 	$temp.remove();
    // 	$scope.showToast($(element).text() + "<b> copied !</b>")
    // 	setInterval(function () {
    // 		$.toast().reset('all');
    // 	}, 3000)
    // }

    // $scope.limit = 24;

    // $scope.setLimit = function (limit) {
    // 	if (limit != null) {
    // 		$scope.limit = limit;
    // 	} else {
    // 		$scope.limit = 24;
    // 	}
    // }

    // // format kids, male and female booleans into a translated and formatted gender string
    // var formatGender = function (male, female, kids) {
    // 	return $q(function (resolve) {

    // 		if (kids == false) {
    // 			if (male == true && female == true) {
    // 				$translate('GENTS').then(function (gents) {
    // 					$translate('LADIES').then(function (ladies) {
    // 						resolve(gents + ' / ' + ladies);
    // 					});
    // 				});
    // 			} else if (male == true && female == false) {
    // 				$translate('GENTS').then(function (gents) {
    // 					resolve(gents);
    // 				});
    // 			} else if (male == false && female == true) {
    // 				$translate('LADIES').then(function (ladies) {
    // 					resolve(ladies);
    // 				});
    // 			}
    // 		} else {
    // 			if (male == true && female == true) {
    // 				$translate('BOYS').then(function (boys) {
    // 					$translate('GIRLS').then(function (girls) {
    // 						resolve(boys + ' / ' + girls);
    // 					});
    // 				});
    // 			} else if (male == true && female == false) {
    // 				$translate('BOYS').then(function (boys) {
    // 					resolve(boys);
    // 				});
    // 			} else if (male == false && female == true) {
    // 				$translate('GIRLS').then(function (girls) {
    // 					resolve(girls);
    // 				});
    // 			}
    // 		}
    // 	})
    // };

    // // Async function to add/format gender and possibly other additional properties later on
    // var formatProduct = function (product) {
    // 	return $q(function (resolve, reject) {

    // 		formatGender(product.male, product.female, product.kids)

    // 			.then(function (formattedGender) {
    // 				product.gender = formattedGender;
    // 				resolve(product);
    // 			})

    // 			.catch(function (reason) {
    // 				reject(reason);
    // 			});
    // 	});
    // };

    // var getProductCount = function () {
    // 	return $q(function (resolve, reject) {

    // 		$api.getCount('products')

    // 			.then(function (productCount) {
    // 				$scope.productCount = productCount;
    // 				resolve(productCount);
    // 			})

    // 			.catch(function (reason) {
    // 				reject(reason);
    // 			});

    // 	});
    // };

    // // Get new products from the database and add them to the $scope
    // $scope.getProducts = function (params, options) {
    // 	console.log("Get Products Called");
    // 	return $q(function (resolve, reject) {

    // 		$scope.loading = true;

    // 		if (!params) {
    // 			params = {};
    // 		}

    // 		if (!params.filter) {
    // 			params.filter = {};
    // 		}

    // 		var apiRequest = function () {
    // 			params.sort = { dateLastModified: 'desc' };
    // 			params.offset = $scope.products.length;
    // 			params.limit = $scope.limit;

    // 			if ($scope.isVerified) {
    // 				params.filter.isVerified = true;
    // 			}
    // 			if ($scope.isNotVerified) {
    // 				params.filter.isVerified = false;
    // 			}

    // 			if ($scope.isVerified && $scope.isNotVerified) {
    // 				console.log("Is Verified And Not Verified.");
    // 				params.filter.isVerified = "showAll"
    // 			}
    // 			if ($scope.isVerified == false && $scope.isNotVerified == false) {
    // 				console.log("Is Verified And Not Verified.");
    // 				params.filter.isVerified = "showAll";
    // 			}

    // 			// if(typeof params.filter.isVerified !== 'boolean') {
    // 			// 	params.filter.isVerified = false;
    // 			// }
    // 			// params.filter.isVerified = $scope.isVerified;

    // 			if (params.filter.category && params.filter.category.$in) {
    // 				$scope.productCategory = params.filter.category.$in;
    // 			} else {
    // 				$scope.productCategory = [];
    // 			}
    // 			console.log("Params for request", params);
    // 			getProductCount()
    // 				.then(function () {

    // 					console.log("Obtained Counts. Now calling products-to-delete");

    // 					$api.get('product/delete-suggestion', params)
    // 						.then(function (response) {
    // 							console.log("Response obtained: ", response.data);
    // 							$scope.products = [];
    // 							console.log("Entering the loop for formatting Products");
    // 							for (var i = 0; i < response.data.products.length; i++)
    // 								$scope.products.push(response.data.products[i].product);
    // 								console.log("$scope.products", $scope.products)

    // 							// Loop through newly fetched products and add them to the $scope.
    // 							// for(var i = 0; i < response.data.products.length; i++) {
    // 							// 	console.log("Formatting : ",response.data.products[i]);
    // 							// 	formatProduct(response.data.products[i].product)
    // 							// 	.then(function(formattedProduct) {
    // 							// 		console.log("Products = ",formattedProduct);
    // 							// 		$scope.products.push(formattedProduct);
    // 							// 		if($scope.products.length == params.offset + response.data.products.length) {
    // 							// 			$scope.loading = false;
    // 							// 		}
    // 							// 	})

    // 							// 	.catch(function(reason) {
    // 							// 		reject(reason);
    // 							// 	});
    // 							// }
    // 							// resolve();

    // 						})

    // 						.catch(function (reason) {
    // 							$scope.noProductsFound = true;

    // 							reject(reason);
    // 						});
    // 				})
    // 		}

    // 		// Delete the loaded products and load them again with the new apiParams
    // 		if (options != null && typeof options === 'object') {

    // 			if (options.reset === true) {
    // 				$scope.products = [];
    // 			}
    // 		}

    // 		apiRequest();
    // 	});
    // };

    // $scope.getProducts();

    // $scope.openProductModal = function (product) {
    // 	console.log("Opening Modal", product);
    // 	return $q(function (resolve, reject) {
    // 		console.log("Into Function")
    // 		var modalInstance = $uibModal.open({
    // 			templateUrl: '../views/modal/admin-product-modal.html',
    // 			controller: 'adminProductModalController',
    // 			size: 'lg',
    // 			resolve: {
    // 				product: function () {
    // 					return product;
    // 				}
    // 			}
    // 		});

    // 		modalInstance.result

    // 			.then(function (response) {
    // 				console.log("Opened",response)
    // 				resolve(response);
    // 			})

    // 			.catch(function (reason) {
    // 				reject(reason);
    // 			});
    // 	});
    // };
  }])
