prismanoteApp.controller('retailerCampaignsController', ['$scope', '$rootScope', '$stateParams', '$state', '$q', '$retailer', '$api', '$uibModal', 'prompt', '$window', 'campaignFact',
  function ($scope, $rootScope, $stateParams, $state, $q, $retailer, $api, $uibModal, prompt, $window, campaignFact) {
    $scope.awsUrl = $rootScope.awsUrl
    //$scope.facebookId = null
    $scope.activeTab = 0
    $scope.supplierSearch = ''
    $scope.shopCompanies = []
    $scope.language = $rootScope.language
    $scope.defaultColumns = [
      { name: 'Name', isSelected: { c: true } },
      { name: 'Email', isSelected: { c: true } },
      { name: 'Brand', isSelected: { c: true } },
      { name: 'Telephone', isSelected: { c: true } },
      { name: 'Type', isSelected: { c: true } },
      { name: 'Zip code', isSelected: { c: true } },
      { name: 'House number Street', isSelected: { c: true } },
      { name: 'Country', isSelected: { c: true } },
      { name: 'Repair Telephone', isSelected: { c: true } }
    ]
    $scope.viewby = 50
    $scope.sendRequest = false
    $scope.updates = []
    $scope.campaigns = []
    // $scope.$apply();//its not working

    $scope.orderByDate = function (log) {
      var date = new Date(log.date)
      return date
    }
    // $scope.backTOPromotion = function () {
    // 	$scope.activeTab = 4;
    // }

    $scope.sendFbRequest = function () {
      $scope.sendRequest = true
      window.open('https://www.facebook.com/jolmer.vanekeren', '_blank')
    }
    $scope.assignPermission = function () {
      campaignFact.updatePermission().then(function (res) {
      })
    }

    $scope.getRetailerAllSocialCampaigns = function () {
      $api.get('get-retailer-all-social-campaigns').then(function (response) {
        console.log('getRetailerAllSocialCampaigns: ', response.data.data)
        console.log('user: ', $rootScope.user)
        $scope.updates = []
        $scope.campaigns = []
        if (response.data && response.data.data && response.data.data.length) {
          var allCampaigns = response.data.data
          console.log('all lengthL ', allCampaigns.length)
          for (var i = 0; i < allCampaigns.length; i++) {
            if (allCampaigns[i].isUpdate == true) {
              $scope.updates.push(allCampaigns[i])
            } else {
              console.log('yes: ', i)
              $scope.campaigns.push(allCampaigns[i])
            }
          }
        }
      }).catch(function (error) {
        console.log(error)
        console.log('Internal server error while fetching campiagns list')
      })
    }

    $scope.getCampaignWhichRetailerJoin = function () {
      console.log('getCampaignWhichRetailerJoin called')
      $api.get('get-campaign-which-retailer-join').then(function (response) {
        console.log('getCampaignWhichRetailerJoin: ', response)
        $scope.retailerJoinCampaigns = response.data.data
      }).catch(function (error) {
        console.log(error)
        console.log('Internal server error while fetching campiagns list')
      })
    }
    $scope.getCampaignWhichRetailerJoin()
    // Deprecated
    $scope.getSocialPortal = function () {
      // console.log('getSocialPortal $scope.user: 123', $scope.user);
      if ($scope.user && $scope.user._id) {
        // $retailer.getShop().then(function (shop) {
        // 	$rootScope.currentShop = shop;
        $api.get('user-social-portal', { userId: $scope.user._id, shopId: $rootScope.currentShop._id })
          .then(function (response) {
            $scope.user.socialPortal = response.data.result
            console.log('social portal controller resonse: ', response)
            $scope.loading = false
          })
          .catch(function (reason) {
            console.error('Error getting portal', reason)
            $scope.loading = false
            var diff = Math.abs(new Date() - new Date($scope.user.creationDate))
            var minutes = Math.floor(diff / 1000) / 60

            if (minutes <= 5) {
              $scope.alert = {
                type: 'warning',
                msg: 'Klik opnieuw op de link in de e-mail of herlaad de vorige pagina. (Deze pagina kunt u nu sluiten.)'
              }
            } else {
              $scope.alert = {
                type: 'danger',
                msg: 'Er is geen Social Portal gevonden voor deze gebruiker. Neem contact op met support.'
              }
            }
          })
        // }).catch(function (shoper) {
        // 	console.log('error while fetching the shop detail');
        // });
      } else {
        console.log('no user there')
      }
    }

    $scope.getPortalCampaigns = function () {
      // $scope.activeTab = 4;
      $scope.campaigns = new Array()
      $scope.updates = new Array()
      // console.log('getPortalCampaigns $scope.user: ', $scope.user);
      $api.get('portal-campaigns', { portalId: $scope.user.socialPortal._id })
        .then(function (response) {
          for (var i = 0; i < response.data.campaigns.length; i++) {
            var campaign = response.data.campaigns[i]
            if (campaign.update == true) {
              $scope.updates.push(campaign)
            } else {
              $scope.campaigns.push(campaign)
            }
          }
        })
        .catch(function (reason) {
          console.error('Error while getting portal', reason)
        })
    }

    $scope.getFacebookPages = function () {
      $api.get('facebook/get-pages')
        .then(function (response) {
          $scope.pages = response.data.pages
        })
        .catch(function (reason) {
          console.error('Error while getting facebook pages', reason)
        })
    }

    // Deprecated
    // $scope.getOpenTasksCount = function () {
    // 	$scope.openTasks = 0;
    // 	for (var i = 0; i < $scope.user.socialPortal.taskList.length; i++) {
    // 		if (!$scope.user.socialPortal.taskList[i].completed) {
    // 			$scope.openTasks++;
    // 		}
    // 	}
    // }

    $scope.saveUser = function () {
      $api.put('user/' + $scope.user._id, { user: $scope.user })
        .then(function () {

        })

        .catch(function (reason) {
          console.error('error while saving user', reason)
        })
    }

    $scope.setFacebook = function (method, save) {
      if ($scope.user && $scope.user.facebook) {
        if (method == 'wall') {
          $scope.user.facebook.postTo = 'wall'
          if ($scope.user.socialPortal) {
            $scope.addLogItem({
              title: 'Facebook ingesteld op profiel',
              type: 'facebook',
              user: $scope.user.firstName + ' ' + ($scope.user.lastNamePrefix || '') + ' ' + $scope.user.lastName
            }, true)
          }
          if (save) {
            $scope.saveUser()
          }
        } else {
          $scope.user.facebook.postTo = 'page'
          $scope.getFacebookPages()
        }
      } else {
        console.log('no facebook user there')
      }
    }

    $scope.setFacebookPage = function (selectedPage) {
      $scope.user.facebook.pageToken = selectedPage.access_token
      $scope.user.facebook.pageId = selectedPage.id
      $scope.user.facebook.pageName = selectedPage.name
      localStorage.setItem('pageIdFromSetting', selectedPage.id)

      // campaignFact.pageIdFromSetting = selectedPage.id;
      $scope.addLogItem({
        title: 'Facebookpagina ingesteld op ' + selectedPage.name,
        type: 'facebook',
        user: $scope.user.firstName + ' ' + ($scope.user.lastNamePrefix || '') + ' ' + $scope.user.lastName
      }, false)
      $scope.saveUser()
    }

    $scope.saveFacebookSettings = function (facebookId) {
      if ($scope.facebookId == $scope.user.facebook.profileId) {
        // Own profile
        $scope.user.facebook.postTo = 'wall'
      } else {
        // Facebook page
        $scope.user.facebook.pageId = $scope.facebookId
        $scope.user.facebook.postTo = 'page'
        for (var i = 0; i < $scope.pages.length; i++) {
          if ($scope.pages[i].id == $scope.facebookId) {
            $scope.user.facebook.pageToken = $scope.pages[i].access_token
          }
        }
      }

      $api.put('user/' + $scope.user._id, { user: $scope.user })
        .then(function (res) {

        })

        .catch(function (reason) {
          console.error('Error while saving user', reason)
        })
    }

    $scope.testFacebook = function () {
      campaignFact.getFacebookInfo().then(function (res) {
        if (res.status == 200) {
          $scope.testResult = {
            type: 'success',
            msg: 'gebruikers login succesvol getest'
          }
        } else {
          $scope.testResult = {
            type: 'warning',
            msg: 'gebruiker is niet ingelogd'
          }
        }
      })
    }

    // Deprecated
    $scope.openPreviewCampaignModal = function (campaign) {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/preview-campaign-modal.html',
        controller: 'previewCampaignModalController',
        size: 'lg',
        resolve: {
          campaign: function () {
            return campaign
          }
        }
      })

      modalInstance.result.then(function (result) {
        if (result) {
          $scope.user.socialPortal.campaigns.push(campaign)
          $scope.saveSocialPortal()
          $scope.getPortalCampaigns()
          $scope.alert = {
            type: 'success',
            msg: 'De promotie ' + campaign.name + " is toegevoegd bij Mijn promoties. <a href='/retailer/campaigns/" + campaign.nameSlug + "'> Bekijk de campagne</a>"
          }
        }
      }, function () {
        // dismissed
      })
    }

    // Deprecated
    $scope.saveSocialPortal = function () {
      return
      $api.put('user-social-portal', { socialPortal: $scope.user.socialPortal })
        .then(function (response) {
          $rootScope.user.socialPortal = response.data.result
        })
        .catch(function (reason) {
          console.error('Error while saving portal', reason)
        })
    }

    $scope.closeAlert = function () {
      $scope.alert = null
    }

    $scope.getPinterestBoards = function () {
      return

      $api.get('pinterest/get-boards')
        .then(function (response) {
          $scope.boards = response.data.boards
        })
        .catch(function (reason) {
          console.error('Error getting pinterest boards', reason)
        })
    }

    $scope.savePinterestSettings = function () {
      $api.put('user/' + $scope.user._id, { user: $scope.user })
        .then(function (res) {
        })

        .catch(function (reason) {
          console.error('Error while saving pinterest details', reason)
        })
    }

    $scope.testPinterest = function () {
      return
      var pin = {
        note: 'Testbericht vanaf PrismaNote',
        image: 'https://s3-eu-west-1.amazonaws.com/prismanote/notenews/2157f5d1-ef67-4565-8df3-e04354c66269.png'
      }
      $scope.pinterestResponse = null
      $api.post('pinterest/create-pin', { pin: pin })
        .then(function (response) {
          $scope.post_id = response.data.result.post_id
          $scope.pinterestResponse = {
            type: 'success',
            msg: "Bericht geplaatst! <a href='https://facebook.com/" + $scope.post_id + "' target='_blank'>Bekijk het bericht</a>"
          }
        })

        .catch(function (reason) {
          console.error('Error while creating pin', reason)
          $scope.pinterestResponse = {
            type: 'warning',
            msg: 'Fout tijdens plaatsen testbericht: ' + reason
          }
        })
    }

    $scope.completeTask = function (task) {
      if (task.type == 'order') {
        $window.open(task.url)
      }

      prompt({
        title: 'Taak uitvoeren',
        message: 'Is uitvoeren van taak ' + task.title + ' gelukt?'
      }).then(function () {
        task.completed = true
        task.dateCompleted = new Date()

        $scope.addLogItem({
          title: task.title + ' uitgevoerd',
          type: 'task',
          taskId: task._id,
          user: $scope.user.firstName + ' ' + ($scope.user.lastNamePrefix || '') + ' ' + $scope.user.lastName
        }, false)

        $scope.saveSocialPortal()
        // $scope.getOpenTasksCount();
      })
    }

    $scope.markOpen = function (task) {
      task.completed = false
      $scope.addLogItem({
        title: task.title + ' als openstaand gemarkeerd',
        type: 'task',
        taskId: task._id,
        user: $scope.user.firstName + ' ' + ($scope.user.lastNamePrefix || '') + ' ' + $scope.user.lastName
      }, false)
      $scope.saveSocialPortal()
    }

    $scope.addLogItem = function (item, save) {
      var logItem = {
        title: item.title,
        date: new Date(),
        type: item.type,
        taskId: item.taskId,
        user: item.user
      }
      $scope.user.socialPortal.log.push(logItem)

      if (save) {
        $scope.saveSocialPortal()
      }
    }
    $scope.deleteFacebook = function () {
      prompt({
        title: 'Undo Facebook link',
        message: 'Are you sure you want to unlink with Facebook?'
      }).then(function () {
        campaignFact.unLinkFacebook().then(function (res) {
          if (res.data.fbLogout == true) {
            $scope.user.facebook = {}
            $scope.addLogItem({
              title: 'Facebook link removed',
              type: 'facebook',
              user: $scope.user.firstName + ' ' + ($scope.user.lastNamePrefix || '') + ' ' + $scope.user.lastName
            }, true)
            $scope.saveUser()
          }
        })

        // $window.location.reload();
      })
    }

    $scope.openTaskModal = function (task) {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/view-task-modal.html',
        controller: 'viewTaskModalController',
        size: 'lg',
        resolve: {
          task: function () {
            return task
          }
        }
      })

      modalInstance.result.then(function (result) {
        $scope.saveSocialPortal()
        // $scope.getOpenTasksCount();
      }, function () {

      })
    }

    $scope.openCreateCampaignModal = function () {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/create-campaign-modal.html',
        controller: 'createCampaignModalController',
        size: 'lg'
      })

      modalInstance.result.then(function (result) {
        for (var i = result.length - 1; i >= 0; i--) {
          if (array[i] === tasks) {
            array.splice(i, 1)
          }
        }

        // niet invullen maar standaard waarde
        result.strategy = 'store-promotion'
        // result.number = $rootScope.randomNumber(); // edit this because ofcourse this is not logic
        result.nameSlug = $rootScope.slugify(result.name)
        // result.nameSlug = "hoe-genereer-je-een-slug";

        result.update = false
        result.images = []
        var language = $rootScope.language
        var task = {
          type: 'facebook',
          mandatory: true,
          defaultChecked: true,
          images: [],
          fbPromotionSettings: {
            alsoOnInsta: true
          }
        }
        task[language] = {
          name: result.name
        }
        result.tasks = [task]
        var image = {
          src: 'https://prismanotevoorjuweliers.nl/wp-content/uploads/2017/07/prismanote-vliegtuigje.png',
          alt: 'Standard My-Store-Promotion-campaign'
        }
        task.images.push(image)
        result.images.push(image)
        $scope.user.socialPortal.campaigns.push(result)

        $scope.saveSocialPortal()
        $scope.getPortalCampaigns()
      }, function () {

      })
    }

    $scope.searchBrands = function (str) {
      var matches = []
      $scope.brands.forEach(function (brand) {
        if ((brand[$scope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
					(brand[$scope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
          matches.push(brand)
        }
      })
      return matches
    }

    $scope.brandSelected = function (selected) {
      var brand = {
        _id: selected.originalObject._id,
        name: selected.title
      }
      var index = _.findIndex($scope.user.socialPortal.brands, { _id: brand._id })

      if (index < 0) {
        $scope.user.socialPortal.brands.push(brand)
      }

      // check if brand exists on shop to ask if it must be added to their shop too
      if ($scope.user.shops && $scope.user.shops.length > 0) {
        var brandIndex = _.findIndex($scope.currentShop.brands, { _id: brand._id })
        if (brandIndex < 0) {
          prompt({
            title: 'Ook op uw winkel toevoegen?',
            message: 'Het merk is toegevoegd, wilt u deze ook toevoegen op uw winkel (' + $scope.currentShop.name + ') ?'
          }).then(function () {
            $scope.currentShop.brands.push(brand)

            $retailer.setShop($rootScope.selectedShop, $scope.currentShop)
          })
        }
      }

      $scope.saveSocialPortal()
    }

    $scope.getBrands = function () {
      // $api.get('brands')
      $api.get('brands?getAll=true')
        .then(function (response) {
          $scope.brands = response.data.brands
        })
        .catch(function (reason) {
          console.error('Error while getting brands', reason)
        })
    }

    $scope.deleteBrandFromSocialPortal = function (index) {
      var brand = $scope.user.socialPortal.brands[index]
      prompt({
        title: 'Merk verwijderen?',
        message: 'Weet u zeker dat u dit merk wilt verwijderen?'
      }).then(function () {
        $scope.user.socialPortal.brands.splice(index, 1)
        $scope.saveSocialPortal()
        if ($scope.user.shops && $scope.user.shops.length > 0) {
          // check if brand also exists on shop to ask if the brand must be removed there too
          var brandIndex = _.findIndex($scope.currentShop.brands, { _id: brand._id })

          if (brandIndex >= 0) {
            prompt({
              title: 'Ook van winkel verwijderen?',
              message: 'Het merk is verwijderd, wilt u deze ook verwijderen van uw winkel (' + $scope.currentShop.name + ') ?'
            }).then(function () {
              $scope.currentShop.brands.splice(brandIndex, 1)

              $retailer.setShop($rootScope.selectedShop, $scope.currentShop)
            })
          }
        }
      })
    }

    $scope.openAddCompanyModal = function (supplierSearch) {
      console.log('called-------------------------------------')
      try {
        console.log(1, supplierSearch)
        console.log(2)
        if (!supplierSearch) supplierSearch = ''
        console.log('openAddCompanyModal called: ', supplierSearch)
        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/retailer-add-company-modal.html',
          controller: 'retailerAddCompanyModalController',
          size: 'lg',
          resolve: {
            searchValue: function () {
              return supplierSearch
            }
          }
        })

        modalInstance.result.then(function (result) {
          console.log('result**************************', result)
          if (result == 'close') {
            $scope.getShopCompanies()
          }
        }, function (error) {
          console.log('modal open error: ', error)
        })
      } catch (error) {
        console.log('error: ', error)
      }
    }

    $scope.$on('changeSelectedShop', function () {
      $scope.getShopCompanies()
    })

    $rootScope.$on('updateCompanyList', function () {
      $scope.getShopCompanies()
    })

    $scope.getShopCompanies = function () {
      var page = $scope.currentPage + 1
      if ($rootScope.currentShop) {
        $api.get('shop-retailer-companies?limit=' + $scope.itemsPerPage + '&pageNumber=' + page, { shopId: $rootScope.currentShop._id })
          .then(function (response) {
            // console.log('REpsonse data.companies: ', response.data);
            $scope.isResponseCome = true
            if (response && response.data) {
              $scope.shopCompanies = []
              $scope.total = 0
              $scope.shopCompanies = response.data
              $scope.originalShopCompanies = JSON.parse(JSON.stringify($scope.shopCompanies))
              // $scope.total = response.data.totalCompanies;
            }
            // console.log('shopcompanlength: ', $scope.shopCompanies.length);
          }).catch(function (reason) {
            $scope.isResponseCome = true
            console.error('Error getting shop-companies', reason)
          })
      }
    }

    $scope.deleteShopCompany = function (companyid) {
      prompt({
        title: 'Delete company from shop',
        message: 'Are you sure you want to delete this company from shop',
        buttons: [
          { label: $language.translate('YES'), primary: true, id: 0 },
          { label: $language.translate('CANCEL'), primary: false, cancel: true, id: 1 }
        ]
      }).then(function (value) {
        if (value.id === 0) {
          $api.post('company/delete-suggestion', { companyId: companyid, shopId: $rootScope.currentShop._id })
            .then(function (response) {

            })
            .catch(function (reason) {
              console.error('Error deleting suggestion', reason)
            })
        }
      })
    }

    $scope.setItemsPerPage = function (num) {
      $scope.itemsPerPage = parseInt(num)
      $scope.currentPage = 0 // reset to first page
      $scope.getShopCompanies()
    }

    $scope.changeThePage = function (direction) {
      var totalPages = Math.ceil($scope.total / $scope.itemsPerPage)

      if (direction == 'next') {
        var page = $scope.currentPage + 1
        if ((page + 1) <= totalPages) {
          $scope.currentPage = page
          $scope.getShopCompanies()
        } else {
          // do nothing
          // $scope.getShopCompanies();
        }
      } else if (direction == 'previous') {
        var page = $scope.currentPage - 1
        if (page < 0) {
          // do nothing but just refresh the page
          // $scope.getShopCompanies();
        } else {
          $scope.currentPage = page
          $scope.getShopCompanies()
        }
      }
    }

    $scope.setItemsPerPage($scope.viewby)

    $scope.redirectToCompany = function (cmp) {
      console.log('retailer.supplier: ', cmp)
      $rootScope.isCompanySuggestion = cmp.isSuggestion
      $state.go('retailer.supplier', { nameSlug: cmp.nameSlug })
    }

    $scope.export = function () {
      // console.log("wont be working for now");
    }

    $scope.askToCustomizeColumnns = function () {
      // open the modal
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/retailer-customize-company-columns-modal.html',
        controller: 'retailerCustomizeCompanyColumnsController',
        size: 'lg',
        resolve: {
          columns: function () {
            return ($scope.defaultColumns)
          }
        }
      })

      modalInstance.result.then(function (result) {
      }, function () {

      })
    }

    $scope.getTheTDValue = function (action_name, comp) {
      // console.log("product handling guy check the action Name" , action_name ,comp);
      if (action_name == 'Name') {
        return comp.name
      } else if (action_name == 'Email') {
        return comp.email
      } else if (action_name == 'Brand') {
        var brands = comp.brands
        var stringToReturn = ''
        for (var i = 0; i < brands.length; i++) {
          if (i < brands.length - 1) { stringToReturn = stringToReturn + brands[i].name + '|' } else { stringToReturn = stringToReturn + brands[i].name }
        }
        return stringToReturn
      } else if (action_name == 'Type') {
        if (comp.type == 'supplier') return 'Supplier'
        else if (comp.type == 'company') return 'Repairer'
        else return comp.type
      } else if (action_name == 'Telephone') {
        if (comp.phone && comp.phone.landLine && comp.phone.mobilePhone) {
          var phoneString = comp.phone.landLine + ' | ' + comp.phone.mobilePhone
          return phoneString
        }
        return ' '
      } else if (action_name == 'Repair Telephone') {
        if (comp.repairPhone && comp.repairPhone.landLine && comp.repairPhone.mobilePhone) {
          var phoneString = comp.repairPhone.landLine + ' | ' + comp.repairPhone.mobilePhone
          return phoneString
        }
        return ' '
      } else if (action_name == 'Zip code') {
        if (comp.address) {
          var returnString = comp.address.postalCode
          return returnString
        }
        return ' '
      } else if (action_name == 'House number Street') {
        if (comp.address) {
          var ret_string = comp.address.houseNumber + '  ' + comp.address.street
          return ret_string
        }
        return ' '
      } else if (action_name == 'Country') {
        if (comp.address) {
          return comp.address.country
        }
        return ' '
      } else {
        return ' '
      }
    }

    $scope.findCompanies = function (value) {
      if (value) {
        if ($scope.originalShopCompanies && $scope.originalShopCompanies.length && value.length < 3) return
        $scope.shopCompanies = []
        for (var i = 0; i < $scope.originalShopCompanies.length; i++) {
          var company = $scope.originalShopCompanies[i]
          // console.log('value: ', company);
          if (company &&
						company.name && company.name.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0 ||
						company.nameSlug && company.nameSlug.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0 ||
						company.email && company.email.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0 ||
						company.name && company.name.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0
          ) {
            $scope.shopCompanies.push(company)
          }
        }
      } else {
        $scope.shopCompanies = JSON.parse(JSON.stringify($scope.originalShopCompanies))
      }
    }
  }])
