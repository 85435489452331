prismanoteApp.controller('retailerNewsController', ['$scope', '$api', '$stateParams', '$uibModal', '$state', 'prompt', 'Upload', '$retailer', '$rootScope', '$language',
  function ($scope, $api, $stateParams, $uibModal, $state, prompt, Upload, $retailer, $rootScope, $language) {
    $scope.limit = 24
    $scope.skip = 0
    $scope.total = 0
    $scope.news = []

    $scope.getNewsItems = function () {
      $api.get(
        'news',
        {
          sort: {
            creationDate: 'desc'
          },
          limit: $scope.limit,
          offset: $scope.skip
        }
      )
        .then(function (response) {
          if (response && response.data && response.data.result && response.data.result.newsItems && response.data.result.newsItems.length) {
            for (var i = 0; i < response.data.result.newsItems.length; i++) {
              $scope.news.push(response.data.result.newsItems[i])
            }
          }

          if (response && response.data && response.data.result && response.data.result.metaData && response.data.result.metaData.total && response.data.result.metaData.total > 0) {
            $scope.total = response.data.result.metaData.total
          }

          $scope.reloadShop()
        })

        .catch(function (reason) {
          console.error('Error while getting news', reason)
        })
    }

    $scope.loadMore = function () {
      $scope.skip += $scope.limit
      $scope.getNewsItems()
    }

    $scope.closeAlert = function () {
      $scope.alert = 0
    }

    $scope.openPreviewNewsItemModal = function (newsItem) {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/preview-newsItem-modal.html',
        controller: 'previewNewsItemModalController',
        size: 'lg',
        resolve: {
          newsItem: function () {
            return newsItem
          }
        }
      })

      modalInstance.result.then(function (result) {
        if (result) {
          newsItem.newsItemId = newsItem._id

          $api.post('shop/' + $rootScope.currentShop._id + '/news', {
            newsItem: newsItem
          }, null, 2)
            .then(function (response) {
              $state.go('retailer.news-item', { id: response.data.news._id })
            })
            .catch(function (reason) {
              console.error('Error while adding newsItem to shop', reason)
              $scope.alert = {
                type: 'danger',
                msg: reason
              }
            })

          // newsItem['newsItemId'] = newsItem._id;
          // delete newsItem._id;
          // $scope.currentShop.news.push(newsItem);
          // $scope.updateShop();
          // $scope.reloadShop();
          // $scope.activeTab = 1;
        }
      }, function () {
        // dismissed
      })
    }

    $scope.updateShop = function () {
      // In this service we putted loader
      $retailer.setShop($rootScope.selectedShop, $scope.currentShop)
    }

    $scope.reloadShop = function () {
      $retailer.getShop()
        .then(function (shop) {
          $scope.currentShop = shop
        })
    }

    $scope.$on('changeSelectedShop', function () {
      $scope.reloadShop()
      $scope.activeTab = 1
    })

    $scope.activeTab = 1
  }])
