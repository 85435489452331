prismanoteApp.controller('retailerGenerateDayClosureReportModalController', ['$scope', '$uibModalInstance', '$api', '$rootScope', '$language', '$pdf', 'startDate', 'endDate',
  function ($scope, $uibModalInstance, $api, $rootScope, $language, $pdf, startDate, endDate) {
    $scope.dateOptions = {
      minDate: new Date(2020, 6, 1),
      maxDate: new Date(),
      startingDay: 1
    }

    $scope.loading = false
    $scope.dateChanged = false

    $scope.dateSet = startDate && endDate

    if(startDate && moment(startDate).isBefore('2020-07-01')) {
      startDate = new Date(2020,6,1)
      $scope.dateChanged = true
    }

    $scope.dateFilter = {
      startDateOpen: false,
      endDateOpen: false,
      // start: startDate || null,
      // end: endDate || null
      start: startDate ? new Date(moment(startDate).startOf('day')) : null,
      end: endDate ? new Date(moment(endDate).startOf('day')) : null,
    }

    $scope.options = {
      sellsPerCollection: false,
      sellsPerEmployee: false,
      takenMoney: true,
      repairRevenue: true,
      totalPerPaymentMethod: true,
      splitPaymentMethods: true,
      takenGiftcards: false,
      issuedGiftcards: false,
      giftcardBalance: true,
      deposits: true,
      returns: true,
      paymentMethodPerCustomer: false,
      totals: false,
      taxOverview: true,
      taxOverviewExplanation: false,
      showImages: false,
      showProductDetails: false
    }

    $scope.openDate = function (type) {
      $scope.dateFilter[type] = !$scope.dateFilter[type]
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    $scope.generate = function () {
      $scope.alert = null
      var body = {
        startDate: new Date($scope.dateFilter.start),
        endDate: $scope.dateFilter.end ? new Date($scope.dateFilter.end) : new Date(),
        options: $scope.options,
        shopId: $rootScope.currentShop._id,
        language: $rootScope.language
      }
      $scope.loading = true;
      $pdf.downloadPdf('daystate/report', body, 'report.pdf', null, null, null, 1000000).then(function (response) {
        $scope.loading = false
        $scope.result = response.data
      })
        .catch(function (reason) {
          $scope.loading = false
          console.error('Error while generating dayclosure report', reason)
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(reason.data.message)
          }
        })
    }
  }])
