prismanoteApp.controller('brandUserController', ['$scope', '$rootScope', '$api', '$uibModal', '$language', '$stateParams', '$employee',
  function ($scope, $rootScope, $api, $uibModal, $language, $stateParams, $employee) {
    $scope.formData = {
      form: {}
    }
    $scope.userId = $stateParams.userId

    $scope.passwordChanged = false
    $scope.updateLoading = false
    $scope.afterClick = false
    $scope.currentuser = {}

    $scope.loadUser = function (isFromUpdate) {
      $scope.rootScope = new Date().getTime()
      // console.log('userId: ', $scope.userId);
      $api.get('profile/' + $scope.userId, { companyId: $rootScope.currentCompany._id }, null, 2)
        .then(function (response) {
          $scope.currentuser = response.data.user
          if ($scope.currentuser.dateOfBirth) $scope.currentuser.dateOfBirth = new Date($scope.currentuser.dateOfBirth)
          if (isFromUpdate) $employee.changeRootScopeUserDetailOREmployeeDetail($scope.currentuser) // Changeing $rootScope data with updated one
        })
        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.updateUser = function () {
      $scope.alert = null
      $scope.updateLoading = true
      $scope.afterClick = true
      $api.put('user/' + $scope.userId, { user: $scope.currentuser }).then(function (response) {
        $scope.updateLoading = false
        $scope.afterClick = false
        $scope.alert = {
          type: 'success',
          msg: $language.translate('PROFILE_SUCCESSFULLY_SAVED')
        }
        $scope.loadUser(true)
      }).catch(function (reason) {
        console.log('catch error', reason)
        $scope.alert = {
          type: 'danger',
          msg: reason.data.message || 'Internal Server Error'
        }
      })
    }

    $scope.openCropImageModal = function () {
      if (!$scope.currentuser.profileImage) $scope.currentuser.profileImage = ''
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/image-crop-modal.html',
        controller: 'cropImageModalController',
        keyboard: false,
        backdrop: 'static',
        resolve: {
          defaultImage: function () {
            return $scope.currentuser.profileImage.indexOf('https://ui-avatars.com/api') == 0
          },
          loadImage: function(){
            return undefined;
          },
          areaType: function () {
            return 'circle';
          }
        }
      })
      modalInstance.result.then(function (result) {
        if (result == '') {
          // User choosen to delete his photo;
          $scope.currentuser.profileImage = ''
          $scope.updateUser()
          return
        }
        $api.post('profile/' + $scope.userId + '/image', { file: result, companyId: $rootScope.currentCompany._id }, null, 2)
          .then(function (result) {
            $scope.loadUser(true)
          })
          .catch(function (reason) {
            console.error(reason)
          })
      }, function () {
        // dismissed
      })
    }

    $scope.openChangePasswordModel = function () {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/self-change-password-modal.html',
        controller: 'selfChangePasswordModalController'
      })
      modalInstance.result.then(function (result) {
        $scope.passwordChanged = true
      }, function () {
        // dismissed
      })
    }
  }])
