//var { resolve } = require("app-root-path");

prismanoteApp.controller('adminTranslationModalController', ['$scope', '$uibModalInstance', 'translation', '$http', '$q', '$window',
  function ($scope, $uibModalInstance, translation, $http, $q, $window) {
    $scope.translation = {}
    $scope.edit = false
    $scope.apiOnline = false;

    if (translation && translation.keyword) {
      $scope.edit = true
      $scope.translation = angular.copy(translation)
    }

    $scope.saveTranslation = function (form) {
      if (form.$invalid) return
      $uibModalInstance.close($scope.translation)
    }

    $scope.callApi = function (targetLang, text) {
      return $q(function(resolve, reject) {
        return $http({
          method: 'GET',
          url: encodeURI('https://translate.googleapis.com/translate_a/single?client=gtx&sl=en&tl='+String(targetLang.substring(0,2).toLowerCase())+'&dt=t&q='+text),
          cache: false
        }).then(function (response) {
          return resolve(response.data);
        }).catch(function(err) {
            return reject(err)
        });
      })
    }

    $scope.getTranslation = function (targetLang) {

      if(!$scope.translation.en_EN) {
        return;
      }

      $scope.callApi(targetLang, $scope.translation.en_EN).then(function(result) {
        if(result[0] !== '') {
          if(!$scope.translation[targetLang]) {
            $scope.translation[targetLang] = {}
          }
          $scope.translation[targetLang] = result[0][0][0]
        }
      }).catch(function(err) {
        console.error('error callApi',err)
      });
      
    }
    
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }
}])