prismanoteApp.controller('createCampaignModalController', ['$scope', '$uibModalInstance', '$api', '$rootScope',
  function ($scope, $uibModalInstance, $api, $rootScope) {
    $scope.campaign = {}

    $scope.closeModal = function () {
      $uibModalInstance.close($scope.campaign)
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    var getItems = function (type) {
      $api.get(type, { shopId: $rootScope.currentShop._id})

        .then(function (response) {
          $scope[type] = response.data[type]
        })

        .catch(function (reason) {
          console.error('Error while getting ' + type, reason)
        })
    }

    $scope.getBrands = function () {
      return getItems('brands')
    }

    $scope.getCompanies = function () {
      return getItems('companies')
    }

    var searchItems = function (str, type) {
      var matches = []

      $scope[type].forEach(function (item) {
        if (
          (item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
				(item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
          matches.push(item)
        }
      })

      return matches
    }

    $scope.searchBrands = function (str) {
      return searchItems(str, 'brands')
    }

    $scope.searchCompanies = function (str) {
      return searchItems(str, 'companies')
    }

    $scope.brandSelected = function (selected) {
      var brand = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug,
        description: selected.originalObject.description,
        images: selected.originalObject.images
      }

      $scope.campaign.brand = brand
    }

    $scope.companySelected = function (selected) {
      var company = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug,
        description: selected.originalObject.description,
        images: selected.originalObject.images
      }

      $scope.campaign.company = company
    }

    $scope.updateShop = function () {
      $retailer.setShop($rootScope.selectedShop, $rootScope.currentShop)
    }
  }])
