prismanoteApp.controller('toggleOfferModeModalController', ['$scope', '$uibModalInstance',
  function ($scope, $uibModalInstance) {
    $scope.confirm = function () {
      $uibModalInstance.close()
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss()
    }
  }])
