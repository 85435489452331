prismanoteApp.controller('addNewPageInWebsite', ['$scope', 'grapeFactory', '$rootScope', '$uibModalInstance',
   function ($scope, grapeFactory, $rootScope, $uibModalInstance) {
    var factoryData = localStorage.getItem('factory') ? JSON.parse(localStorage.getItem('factory')) : {}
    $scope.cerateNewPage = function (customPageForm, customPageName, customBrandFilter) {
      
      var brand;
      if(customBrandFilter && customBrandFilter._id && customBrandFilter.name && customBrandFilter[$rootScope.language].nameSlug) {
        brand = {
          id: customBrandFilter._id,
          name: customBrandFilter.name,
          nameSlug: customBrandFilter[$rootScope.language].nameSlug
        }
      }
      var factoryData = localStorage.getItem('factory') ? JSON.parse(localStorage.getItem('factory')) : {}
      var websiteCustomFilterDetails;
      if (Object.keys(factoryData).length > 0) {
        var data = {
          websiteName: factoryData.websiteName,
          customPageName: customPageName,
          templateName: factoryData.templateName,
          websiteId: factoryData.websiteId,
          currentShop: $rootScope.currentShop,
          isProductPage: $scope.isProductPage,
          productfilterData: {
            sortBy: $scope.sortBy,
            limit: $scope.limit,
            brand: brand
          }
        }
        if($scope.genderFilter != null) {
          data.productfilterData.gender = $scope.genderFilter;
        }
        if ($scope.categoryFilter != null) {
          data.productfilterData.category = $scope.category.category;
        }

        grapeFactory.getWebsiteDetail($rootScope.currentShop._id, factoryData.websiteId)
          .then(function (websiteData) {
          websiteCustomFilterDetails = websiteData.data.websiteDetail.customPages;
          websiteCustomFilterDetails.push({ filter: data.productfilterData, pageName: data.customPageName, })
          try {
            var response = grapeFactory
            .updateWebsiteName(data.websiteId, data.currentShop._id, { customPages : 
              websiteCustomFilterDetails})
            .then(function (response) {
            })
          } catch(err) {
            console.log(err);
          }
          return grapeFactory
          .postCustomPage(data)
          .then(function (response) {
            $uibModalInstance.close(response.data)
          })
        })
      } else {
        console.error('Something went wrong.')
        $uibModalInstance.close()
      }
    }

    $scope.setLimit = function (limit) {
      $scope.$emit('limit_filter_directive', { limit: limit });
    }

    $scope.$on("limit_filter_directive", function (evt, data) {
      $scope.showLimit = data;
    });

    $scope.$on("sort_filter_directive", function (evt, data) {
      $scope.productSortBy = data;
    });

    $scope.sortProducts = function () {
      if ($scope.sortBy != null && typeof $scope.sortBy === 'object') {
        var sortBy = angular.copy($scope.sortBy);
        $scope.$emit('sort_filter_directive', { sort: sortBy });
      }
    }

    $scope.$on("gender_filter_directive", function (evt, data) {
      $scope.genderFilter = data.gender;
    });

    $scope.setGender = function () {
      if ($scope.gender != null && typeof $scope.gender === 'object') {
        var genderFilter = angular.copy($scope.gender);
        $scope.$emit('gender_filter_directive', genderFilter);
      }
    }

    $scope.$on("category_filter_directive", function (evt, data) {
      $scope.categoryFilter = data.category;
    });

    $scope.setCategory = function () {
      if ($scope.category != null && typeof $scope.category === 'object') {
        var categoryFilter = angular.copy($scope.category);
        $scope.$emit('category_filter_directive', categoryFilter);
      }
    }
  }
])
