prismanoteApp.controller('retailerHomeController', ['$scope', '$rootScope', '$retailer', '$api', '$state', '$language', '$employee', '$data', '$shop', '$device', '$uibModal',
  function ($scope, $rootScope, $retailer, $api, $state, $language, $employee, $data, $shop, $device, $uibModal) {

    $scope.showJSPMAlert = true

    if($rootScope && $rootScope.tillMode) {
      $state.go('retailer.till')
      return
    }

    // $scope.checkJSPMAlert = function () {
    //   if($rootScope.currentShop) {
    //     // var hideJSPMAlert = localStorage.getItem('hideJSPMAlert_' + $rootScope.currentShop._id)
    //     // if(hideJSPMAlert && hideJSPMAlert === 'true') {
    //     //   $scope.showJSPMAlert = false
    //     // }
    //     $scope.JSPM = !$rootScope.currentShop.printNode || $rootScope.currentShop.printNode.enabled === false
    //     console.log('JSPM', $scope.jspm)
    //   }
    // }

    $scope.getShop = function (nameSlug) {
      $retailer.getShop(nameSlug)
        .then(function (shop) {
          $scope.currentShop = shop
          $rootScope.shopFilter = null
          $device.getCurrentDevice()

        })
    }

    $scope.closeJSPMAlert = function () {
      if(!$rootScope.currentShop) {
        return
      }
      localStorage.setItem('hideJSPMAlert_' + $rootScope.currentShop._id, 'true')
      $scope.showJSPMAlert = false
    }

    $scope.jspmDaysRemaining = function() {
      var deadline = moment("2022-01-22")
      var remaining = deadline.diff(moment(), 'days')
      return deadline.isAfter() ? remaining.toString() : "-" //show the remaining days if the date is in the future
    }

    $scope.openEmployeeModal = function () {
      $employee.openEmployeeModal()
    }

    $scope.openCashRegister = function () {
      $state.go('retailer.till')
    }

    $scope.getShopCompaniesByInvitationDate = function() {
      var body = { shopId: $rootScope.currentShop._id }
      $shop.getShopCompaniesByInvitationDate(body).then(function(response) {
        $scope.shopCompaniesByInvitationDate = [];
        if (response && response.data && response.data.data) {
          $scope.shopCompaniesByInvitationDate = response.data.data;
        }
      }).catch(function(error) {
        console.error('getShopCompaniesByInvitationDate: error: ', error);
      });
    }

    $scope.recommendationOrderPage = function(company) {
      $rootScope.recommendationCompany = company;
      $state.go('retailer.recommendation-order', { orderNo: '' });
    }

    $scope.$on('changeSelectedShop', function () {
      $scope.sameProducts = [];
      $rootScope.productCanBeUpdate = [];
      $scope.getShopCompaniesByInvitationDate();
    })

    $scope.getOwnShopUpdatedProducts = function (shopId) {
      $shop.getOwnShopUpdatedProducts(shopId)
        .then(function (response) {
          $scope.sameProducts = response.data
          $rootScope.productCanBeUpdate = JSON.parse(JSON.stringify(response.data))
        })
        .catch(function (error) {
          console.error('Error! ========> Updated Products :', error)
        })
    }

    $rootScope.selectMultiShopCompany = function (company, index) {
    }

    $rootScope.selectMultiShopProduct = function (product, index) {
      $scope.openPairingAssortmentModal(product);
    }

    $scope.openPairingAssortmentModal = function (product) {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/retailer-pairing-assortment.html',
        controller: 'retailerPairingAssortmentModal',
        size: 'lg',
        resolve: {
          item: function () {
            return { nameSlug: product._id, mergeProductnameSlug: 'Not_For_Merge' }
          }
        },
        windowClass: 'sample-pug'
      });

      modalInstance.result.then(function (result) {
        var senddata = {
          selectedIndex: selectedIndex,
          product: product,
          isMerge: true
        }
        $uibModalInstance.close(senddata);
      }, function (error) {
        console.error('openPairingAssortmentModal error: ', error);
      });

    }

    // Print playground
    String.prototype.toBytes = function () {
      var arr = []
      for (var i = 0; i < this.length; i++) {
        arr.push(this[i].charCodeAt(0))
      }
      return arr
    }

    String.prototype.hexEncode = function () {
      var hex, i

      var result = ''
      for (i = 0; i < this.length; i++) {
        hex = this.charCodeAt(i).toString(16)
        result += ('000' + hex).slice(-4)
      }

      return result
    }

    String.prototype.hexDecode = function () {
      var j
      var hexes = this.match(/.{1,4}/g) || []
      var back = ''
      for (j = 0; j < hexes.length; j++) {
        back += String.fromCharCode(parseInt(hexes[j], 16))
      }

      return back
    }

    $scope.convertToRaw = function (text) {
      if (!text || text.length == 0) {
        return
      }
      // return text.toBytes().concat([0x01B, 0x64, 10])
      return text.hexEncode()
    }

    function toHex (str) {
      var result = ''
      for (var i = 0; i < str.length; i++) {
        result += str.charCodeAt(i).toString(16)
      }
      return result
    }

    $scope.convertToArrayBuffer = function (text) {
      if (!text || text.length == 0) {
        return
      }
      // return new Uint8Array($scope.convertToRaw(text)).buffer;
      return toHex(text)
    }

    $scope.changeOrderMode = function() {
      $rootScope.currentShop.orderMode = !$rootScope.currentShop.orderMode;
      var body = {
        shopId: $rootScope.currentShop._id,
        key: 'orderMode',
        value: $rootScope.currentShop.orderMode
      }
      $shop.updateShopDetailsByKey(body).then(function(response) {
      }).catch(function(error) {
        console.error('error called: ', error);
      });
    }

    $scope.getUpdates = function () {
      $api.get('updates', null, null, 2)
        .then(function (res) {
          $scope.updates = res.data.updates
        })
        .catch(function (reason) {
          console.error('Error fetching updates', reason)
        })
    }

  }])
