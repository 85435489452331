prismanoteApp.controller('tillProductModalController', ['$scope', '$uibModalInstance', 'mode', 'items', 'language', '$rootScope',
  function ($scope, $uibModalInstance, mode, items, language, $rootScope) {
    $scope.mode = mode
    $scope.language = language
    $scope.items = items

    $scope.chooseItem = function (item) {
      $scope.close(item)
    }

    $scope.close = function (data) {
      $uibModalInstance.close(data)
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }
  }])
