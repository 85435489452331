prismanoteApp.controller('brandNewServiceItemController', ['$scope', '$api', '$company', '$state', 'FileUploader', '$language', '$rootScope', '$productConfig', 'dialogs',
  function ($scope, $api, $company, $state, FileUploader, $language, $rootScope, $productConfig, dialogs) {
    $scope.searchMode = 'exact'

    var uploader = $scope.uploader = new FileUploader({
      url: 'api/transactions/upload'
    })

    $scope.initalize = function () {
      $scope.transaction = {
        material: 'UNKNOWN',
        category: 'UNKNOWN',
        type: 'repair',
        language: $rootScope.language
      }
      $scope.search_value = {
        text: ''
      }
    }
    $scope.initalize()

    uploader.filters.push({
      name: 'duplicateFilesFilter',
      fn: function (item) {
        var index = _.findIndex($scope.uploader.queue, function (q) {
          return q.file.name == item.name
        })
        return index < 0
      }
    })

    uploader.onProgressAll = function (progress) {
      if (progress < 100) {
        $rootScope.$broadcast('dialogs.wait.progress', { progress: progress })
      } else {
        $rootScope.$broadcast('dialogs.wait.complete')
      }
    }

    uploader.onSuccessItem = function (item, response, status, headers) {
      // Push every single file in an array to store them later on the transaction
      $scope.allFiles.push({
        src: response.file,
        comment: item.formData.comment
      })
    }

    uploader.onCompleteAll = function () {
      // All files are uploaded, now we can merge all details and create the transaction
      complete()
    }

    uploader.onErrorItem = function (fileItem, response, status, headers) {
      $scope.loading = false
      console.info('onErrorItem', fileItem, response, status, headers)
      $scope.alert = {
        type: 'danger',
        msg: response.message
      }
    }

    function complete () {
      uploader.clearQueue()
      $scope.alert = null
      $scope.transaction.repairer = {
        _id: $rootScope.currentCompany._id,
        name: $rootScope.currentCompany.name
      }

      $api.post('brand/repair', {
        transaction: $scope.transaction,
        files: $scope.allFiles,
        companyId: $rootScope.currentCompany._id
      })
        .then(function (response) {
          $scope.alert = {
            type: 'success',
            msg: $language.translate('SERVICE_ITEM_CREATED')
          }
          $state.go('brand.service-item', { id: response.data.result._id })
        })
        .catch(function (reason) {
          console.error(reason)
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(reason)
          }
        })
    }

    $scope.getCompanyShops = function () {   
      if ($rootScope.currentCompany && $rootScope.currentCompany._id) {
        return getCompanyShops()
      } else {
        $company.getCompany()
          .then(function (company) {
            $scope.currentCompany = company
            $rootScope.currentCompany = company
            getCompanyShops()
          })
          .catch(function (reason) {
            console.error('Internal Server Error while fetching company details from rootScope', reason)
          })
      }
    }

    function getCompanyShops () {
      $scope.brands = $rootScope.currentCompany.brands
      $api.get('shops/comapnies/' + $rootScope.currentCompany._id)
        .then(function (response) {
          $scope.shops = response.data.data.map(function (s) {
            delete s.shopDetail.brands
            return s.shopDetail
          })
        })
        .catch(function (reason) {
          console.error('Error while getting shop companies', reason)
        })
    }

    $scope.selectedShop = function (shop) {
      $scope.transaction.shop = shop.originalObject
    }

    $scope.removeShop = function () {
      $scope.transaction.shop = null
    }

    $scope.searchShops = function (str) {
      var matches = []
      if(str) {
        $scope.shops.forEach(function (item) {
          if (item) {
            if (item.name && item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0 ||
                          item.nameSlug && item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) {
              matches.push(item)
            }
          }
        })
        return matches
      }
     
    }

    $scope.findProducts = function (value, searchMode) {
      $scope.hits = []
      if (value && value.length > 0 && $rootScope.currentCompany && $rootScope.currentCompany._id) {
        $scope.search_value.text = $productConfig.retrunValidProductNumber(value)
        value = $scope.search_value.text

        if (value.length < 3) return

        var data = {
          companyId: $rootScope.currentCompany._id,
          searchMode: searchMode,
          searchText: value
        }
        $scope.productNumberStatus = 'default'
        $scope.loading = true
        $company.searchProductForCompany(data)
          .then(function (response) {
            if (!$scope.cancleSearch) {
              $scope.hits = response.data
              $scope.countOfProducts = response.count
              if (response.status != null && response.status != undefined) $scope.productNumberStatus = parseInt(response.status)
            } else {
              $scope.loading = false
              $scope.searchingProduct = false
            }
          })
          .catch(function (error) {
            $scope.loading = false
            $scope.searchingProduct = false
            console.error('Error while getting product from company', error)
          })
      }
    }

    $scope.setSearchMode = function (value, mode) {
      $scope.searchMode = mode
      $scope.findProducts(value, mode)
    }

    $scope.addItem = function (product) {
      $scope.transaction.product = product
      if (product.brand) {
        $scope.selectedBrand(product.brand)
      }

      $scope.hits = []
    }

    $scope.selectedBrand = function (brand) {
      if (brand.originalObject) {
        brand = brand.originalObject
      }

      $scope.transaction.brand = {
        name: brand.name,
        nameSlug: brand.nameSlug,
        _id: typeof brand._id === 'string' ? brand._id : brand._id._id
      }
    }

    $scope.removeBrand = function () {
      $scope.transaction.brand = null
    }

    $scope.searchBrands = function (str) {
      var matches = []
      $scope.brands.forEach(function (item) {
        if (item) {
          if (item.name && item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0 ||
                        item.nameSlug && item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) {
            matches.push(item)
          }
        }
      })
      return matches
    }

    $scope.create = function () {
      $scope.allFiles = []
      if (uploader.queue.length > 0) {
        uploader.uploadAll()
        dialogs.wait($language.translate('UPLOADING'), $language.translate('PLEASE_WAIT_WHILE_THE_PHOTOS_ARE_UPLOADED'), 0)
      } else {
        complete()
      }
    }

    $scope.addCommentToPhoto = function (index) {
      $scope.editComment = index
    }
    $scope.savePhotoComment = function () {
      $scope.editComment = null
    }
  }])
