prismanoteApp.controller('adminShopCompanyDeleteSuggestionsController', ['$scope', '$brand', '$state',
  function ($scope, $brand, $state) {
    console.log('adminShopCompanyDeleteSuggestionsController Companies suggested to be deleted')

    $scope.suggestionFilter = [
      {
        name: 'Shop Delete Suggestions',
        value: true
      },
      {
        name: 'Company Delete Suggestions',
        value: true
      }
    ]

    $scope.getShopCompanyDeleteSuggestion = function () {
      var body = {
        isShopDeleteSuggestion: $scope.suggestionFilter[0].value,
        isCompanyDeleteSuggestion: $scope.suggestionFilter[1].value
      }
      $scope.loading = true
      $brand.getShopCompanyDeleteSuggestion(body).then(function (response) {
        $scope.loading = false
        $scope.allSuggestions = []
        if (response && response.data && response.data.length) $scope.allSuggestions = response.data
        // console.log('$scope.allSuggestions: ', $scope.allSuggestions);
      }).catch(function (error) {
        $scope.loading = false
        console.log('error while fetchinng delete suggestions: ', error)
      })
    }

    $scope.openDetailPage = function (index) {
      var suggestion = $scope.allSuggestions[index]
      if (suggestion.eType === 'shop_suggestion') {
        $state.go('admin.shop', { nameSlug: suggestion.suggester.shopDetail.nameSlug })
      } else {
        $state.go('admin.company', { nameSlug: suggestion.companyDetail.nameSlug })
      }
    }
  }])
