prismanoteApp.controller("shopBrandPurchaseOrderStatsticsController", ['$scope', '$rootScope', '$purchaseOrder', '$language', function ($scope, $rootScope, $purchaseOrder, $language) {
    $scope.loadPage = {
        loading: false
    }
    $scope.shownViews = 'COLLECTION_VIEW';
    $scope.showByUniqueness = 'UNIQUE_PURCHASE_ORDER';
    $scope.showDates = false;
    $scope.period = "week";
    $scope.filter = {}
    $scope.year = moment().year()

    $scope.fetchPurchaseOrderStatistics = function () {

        var _startDate = new Date($scope.filter.startDate);
        var _endDate = new Date($scope.filter.endDate);

        var startDate = {
            year: moment(_startDate).year(),
            month: moment(_startDate).month() + 1,
            date: moment(_startDate).date(),
        }

        var endDate = {
            year: moment(_endDate).year(),
            month: moment(_endDate).month() + 1,
            date: moment(_endDate).date(),
        }

        _startDate = startDate.year + '-' + startDate.month + '-' + startDate.date; 
        _endDate = endDate.year + '-' + endDate.month + '-' + endDate.date;

        $scope.loadPage.loading = true;
        var body = {
            companyId: $rootScope.currentCompany._id,
            startDate: _startDate,
            endDate: _endDate,
            // startDate: $scope.filter.startDate ? new Date($scope.filter.startDate) : '',
            // endDate: $scope.filter.endDate ? new Date($scope.filter.endDate) : '',
            shownViews: $scope.shownViews,
            showByUniqueness: $scope.showByUniqueness // If true then by Unique Shop and false then by Purchasee order number
        }
        $scope.allCollections = [];
        $scope.finalPurchasePrice = undefined;
        $purchaseOrder.purchaseOrderStatistics(body).then(function (response) {
            $scope.loadPage.loading = false;
            if (response && response.data && response.data.data && $scope.shownViews === 'COLLECTION_VIEW') {
                $scope.allCollections = response.data.data.allCollections;
                $scope.finalPurchasePrice = response.data.data.finalPurchasePrice;
            } else {
                $scope.allPurchaseOrders = response.data.data.allPurchaseOrders;
                $scope.finalPurchasePrice = response.data.data.finalPurchasePrice;
            }
        }).catch(function (error) {
            $scope.loadPage.loading = false;
        });
    }

    $scope.setFilter = function (period) {
        $scope.filter.period = period;
        $scope.filter.endDate = new Date(new Date().setHours(23, 59, 59));

        if (period == 'day') {
            $scope.period = "day";
            $scope.filter.startDate = new Date(new Date().setHours(0, 0, 0));
        }
        if (period == 'week') {
            $scope.period = "week";
            $scope.filter.startDate = new Date(new Date(moment().startOf('week')).setHours(0, 0, 0));
        }
        if (period == 'month') {
            $scope.period = "month";
            $scope.filter.startDate = new Date(new Date(moment().startOf('month')).setHours(0, 0, 0));
        }

        if (period == 'year') {
            $scope.period = "year";
            $scope.filter.startDate = new Date(new Date(moment().startOf('year')).setHours(0, 0, 0));
        }
        $scope.fetchPurchaseOrderStatistics();
    }

    $scope.resetFilters = function () {
        $scope.setFilter('day')
    }

    $scope.filterChanged = function (value, time) {
        if (time === 'year') {
            $scope.filter.startDate = moment().year(value).startOf('year').toDate()
            $scope.filter.endDate = moment().year(value).endOf('year').toDate()
        }
        if (time === 'month') {
            $scope.filter.startDate = moment().year($scope.year).month(value).startOf('month').toDate()
            $scope.filter.endDate = moment().year($scope.year).month(value).endOf('month').toDate()
        }
        if (time === 'week') {
            $scope.filter.startDate = moment().year($scope.year).week(value).startOf('week').toDate()
            $scope.filter.endDate = moment().year($scope.year).week(value).endOf('week').toDate()
        }

        if (time === 'date') {
            $scope.filter.period = 'custom'
        }
    }

    $scope.changeView = function (shownViews) {
        if (shownViews == 'COLLECTION_VIEW') {
            $scope.shownViews = 'COLLECTION_VIEW';
        } else if (shownViews == 'SHOP_VIEW') {
            $scope.shownViews = 'SHOP_VIEW';
        }
        $scope.fetchPurchaseOrderStatistics();
    }

    $scope.changeUniqueView = function (showByUniqueness) {
        if (showByUniqueness == 'UNIQUE_PURCHASE_ORDER') {
            $scope.showByUniqueness = 'UNIQUE_PURCHASE_ORDER';
        } else if (showByUniqueness == 'UNIQUE_BY_SHOP') {
            $scope.showByUniqueness = 'UNIQUE_BY_SHOP';
        }
        $scope.fetchPurchaseOrderStatistics();
    }

    $scope.getYears = function () {
        var cYear = moment().year()
        //Define in which year we should start showing years
        var startYear = 2018
        $scope.years = []

        for (var i = startYear; i < cYear + 1; i++) {
            $scope.years.push(i)
        }
    }

    $scope.getMonths = function () {
        $scope.months = [
            {
                id: 0,
                name: $language.translate('JANUARY')
            },
            {
                id: 1,
                name: $language.translate('FEBRUARY'),
            },
            {
                id: 2,
                name: $language.translate('MARCH')
            },
            {
                id: 3,
                name: $language.translate('APRIL')
            },
            {
                id: 4,
                name: $language.translate('MAY')
            },
            {
                id: 5,
                name: $language.translate('JUNE')
            },
            {
                id: 6,
                name: $language.translate('JULY')
            },
            {
                id: 7,
                name: $language.translate('AUGUST')
            },
            {
                id: 8,
                name: $language.translate('SEPTEMBER')
            },
            {
                id: 9,
                name: $language.translate('OCTOBER')
            },
            {
                id: 10,
                name: $language.translate('NOVEMBER')
            },
            {
                id: 11,
                name: $language.translate('DECEMBER')
            }
        ]
    }

    $scope.getWeeks = function () {
        var tWeek = $language.translate('WEEK')
        var weeks = moment().year($scope.year).isoWeeksInYear()

        $scope.weeks = []

        for (var i = 1; i < (weeks + 1); i++) {
            $scope.weeks.push(
                {
                    id: i,
                    name: tWeek + ' ' + i
                }
            )
        }
    }

    $scope.getYears()
    $scope.getMonths()
    $scope.getWeeks();

    $scope.updateByDateRange = function () {
        $scope.filter.startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
        $scope.filter.endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
        $scope.fetchPurchaseOrderStatistics();
        $scope.showDates = true
    };

    $scope.showDate = function (data) {
        $scope.showDates = data
    }

}]);
