prismanoteApp
  .controller('retailerCompareProduct1Controller',
    ['$scope', '$parse', '$rootScope', '$uibModal', '$stateParams', '$state', '$api', '$q', '$translate', '$productConfig', '$diamond', '$product', '$shop', '$notification', 'Upload', '$language', 'prompt',
      function ($scope, $parse, $rootScope, $uibModal, $stateParams, $state, $api, $q, $translate, $productConfig, $diamond, $product, $shop, $notification, Upload, $language, prompt) {
        console.log('New retailerCompareProductController: CALLLED: ', $stateParams.nameSlug)
        console.log('$stateParams.nameSlug', $stateParams.mergeProductnameSlug)

        $rootScope.activeTabCurrentProduct = 0
        $rootScope.activeTabSuggestedProduct = 0
        $scope.dialColors = $productConfig.getDialColors()
        $scope.strapColors = $productConfig.getStarpColors()
        $scope.gemKinds = $diamond.getGemKinds()
        $scope.gemPurities = $diamond.getGemPurities()
        $scope.gemColors = $diamond.getGemColors()
        $scope.gemCuts = $diamond.getGemCuts()
        $scope.oldCollections = {}
        $scope.eanValid = true
        $scope.PNvalid = true
        $scope.diamond = {
          quantity: '',
          gemKind: '',
          gemPurity: {
            code: ''
          },
          gemColor: {
            code: ''
          },
          gemCut: '',
          caratWeight: '',
          suggestedRetailPriceDiamond: ''
        }
        $scope.editDiamond = false
        $scope.addDiamond = false
        $scope.caseColors = $scope.jewelColors = Object.assign($scope.dialColors, $scope.strapColors)

        $scope.addProductImage = function () {
          if (!$scope.images) $scope.images = []
          $scope.images.push({})
        }

        $scope.removeProductImage = function (mode, index) {
          if (mode == 'scope') $scope.images.splice(index, 1)
          else $scope.product.images.splice(index, 1)
        }

        $scope.getProductWithSuggestions = function (shopId, productId) {
          $scope.loading = true
          $scope.containsFilterInfoCurrent = false

          console.log('CurrentShopId', shopId)
          console.log('Product Id', productId)
          $product.getProductWithSuggestionsNotOwnShop(shopId, productId)
            .then(function (success) {
              console.log("$scope.Product ===> From Success Data", success);
              var suggestionIds = success.data[0].suggestions
              delete success.data[0].suggestions

              $scope.oldProdct = Object.assign({}, success.data[0])
              $scope.product = Object.assign({}, success.data[0])

              if ($scope.product.brand && $scope.product.brand.name && $scope.product.brand.isPoolArticleBrand) $scope.disabled = true
              else if ($scope.product.brand && $scope.product.brand.name && !$scope.product.variants[0].productNumberAuto) $scope.PNandBrandDisabled = true

              $scope.setTargetAudience($scope.product.male, $scope.product.female, $scope.product.kids)
              if ($scope.product.images) $scope.image = $scope.product.images
              if ($scope.product.suggestedRetailPrice) $scope.product.suggestedRetailPrice = Number($scope.product.suggestedRetailPrice * (($scope.product.suggestedRetailPriceVat / 100) + 1)).toFixed(2)
              if ($scope.product.category === 'WATCH' && $scope.product.watch) {
                if ($scope.product.watch.dial && $scope.product.watch.dial.color) $scope.dialColor = $scope.dialColors.find(function (color) {
                  return color.key == $scope.product.watch.dial.color
                })
                if ($scope.product.watch.case && $scope.product.watch.case.color) $scope.caseColor = $scope.caseColors.find(function (color) {
                  return color.key == $scope.product.watch.case.color
                })
                if ($scope.product.watch.strap && $scope.product.watch.strap.color) $scope.strapColor = $scope.strapColors.find(function (color) {
                  return color.key == $scope.product.watch.strap.color
                })
              } else if ($scope.product.category === 'STRAP') {
                if ($scope.product.strap && $scope.product.strap.color) $scope.strapColor = $scope.strapColors.find(function (color) {
                  return color.key == $scope.product.strap.color
                })
              } else if ($scope.product.category === 'JEWEL') {
                if ($scope.product.jewel && $scope.product.jewel.color && $scope.product.jewel.color.length && $scope.product.jewel.color.length[0]) $scope.jewelColor = $scope.jewelColors.find(function (color) {
                  return color.key == $scope.product.jewel.color[0]
                })
              }

              if ($scope.product.containsFilterInfo && $scope.product.containsFilterInfo.en || $scope.product.containsFilterInfo.de || $scope.product.containsFilterInfo.nl || $scope.product.containsFilterInfo.fr || $scope.product.containsFilterInfo.es) $scope.containsFilterInfoCurrent = true

              getValidSuggestionFromSuggestionId(suggestionIds)
            })
            .catch(function (error) {
              console.error('Error In Finding getProductWithSuggestionsNotOwnShop !', error)
            })
        }

        function getValidSuggestionFromSuggestionId(suggestionIds) {
          console.log('getValidSuggestionFromSuggestionId', suggestionIds)
          $product.getValidSuggestionFromSuggestionId(suggestionIds)
            .then(function (successData) {
              if (!$scope.containsFilterInfoCurrent) $scope.containsFilterInfoCurrent = checkContanisFilterInforInSuggestions(successData.data)

              $scope.product.suggestions = successData.data
              $scope.loading = false
              console.log('containsFilterInfoCurrent', $scope.containsFilterInfoCurrent)
            })
            .catch(function (error) {
              console.error('Error =>', error)
            })
        }

        function checkContanisFilterInforInSuggestions(suggestions) {
          console.group('Suggestion to check contains filter info')
          var totalSuggestions = suggestions.length || 0
          for (var i = 0; i < totalSuggestions; i++) {
            var suggestion = suggestions[i].item
            if (suggestion.containsFilterInfo && (suggestion.containsFilterInfo.en || suggestion.containsFilterInfo.de || suggestion.containsFilterInfo.es || suggestion.containsFilterInfo.nl || suggestion.containsFilterInfo.fr)) $scope.containsFilterInfoCurrent = true
          }
          console.groupEnd()
          return $scope.containsFilterInfoCurrent
        }

        $scope.convertDate = function (date) {
          // Convert Date
          var d = new Date(date)
          return d.toLocaleString()
        }

        $scope.convertToUpperAndSpace = function (str) {
          // Convert To Upper and Space
          if (str) {
            str = str.toUpperCase()
            str = str.replace(' ', '_')
          }
          return str
        }

        // $scope.suggestionsProduct;

        // var verifyMode = false;
        // var nameSlug;
        $scope.productFindWithMode = function (currentShopId) {
          console.log('Product Is for Primary Or Secondary', currentShopId)
          if ($stateParams.nameSlug && $stateParams.mergeProductnameSlug != 'Not_For_Merge') {
            // For the Merge Product
            console.log('Curret Product is for mearge', $stateParams.nameSlug)
            console.log('mergeProductnameSlug', $stateParams.mergeProductnameSlug)
            $scope.primaryProductId = $stateParams.mergeProductnameSlug
            $scope.secondaryProductId = $stateParams.nameSlug
            $scope.isMerge = true
            $scope.primaryMergeProduct = {}
            $scope.secondaryRetailerModalProduct = {}
            $scope.currentMode = 'merge'
            $scope.showMergeSuccessAlert = false

            console.log('Primary Product Id', $scope.primaryProductId)
            console.log('Secondary Product Id', $scope.secondaryProductId)
            $scope.getProductsForMerge($scope.primaryProductId, $scope.secondaryProductId, currentShopId)
          } else if ($stateParams.nameSlug && $stateParams.mergeProductnameSlug) {
            // For the Update Product

            // $scope.productNameSlug = $stateParams.nameSlug;
            $scope.productId = $stateParams.nameSlug
            $scope.isMerge = false
            $scope.currentMode = 'edit'

            $scope.getProductWithSuggestions($rootScope.currentShop._id, $scope.productId)
          }
        }

        $scope.getTaxForShopForMainProduct = function (currentShopId) {
          $shop.getTaxForShop(currentShopId)
            .then(function (tax) {
              console.log('Getting Tax Rat', tax)
              if (tax && tax.taxes && tax.taxes.rates) {
                $scope.taxes = tax.taxes.rates
              }
            })
            .catch(function (errorInGettingTax) {
              console.error('Error In Getting Tax For Shop', errorInGettingTax)
            })
        }

        $scope.mergeProduct = function () {
          console.log('Keep This Product  =====>', $scope.product)
          console.log('Shop Specific Product details Keep This details in shop ===>', $scope.shopProduct)
          console.log('This ProductB is always in shop ====>', $scope.showProduct)
          console.log('This ProductB shop details ====>', $scope.showShopProduct)

          $scope.loading = true
          if ($scope.product.suggestedRetailPrice) $scope.product.suggestedRetailPrice = $scope.product.suggestedRetailPrice / (($scope.product.priceVat / 100) + 1)
          if ($scope.product.priceVat != null && $scope.product.priceVat != undefined) $scope.product.priceVat = $scope.product.suggestedRetailPriceVat

          $scope.product.privateLableCollections = $scope.showProduct.privateLableCollections

          // START Merging

          var primaryProductId = $scope.product._id
          var secondaryProductIds = $scope.showProduct._id

          var params = {
            shop: { _id: $rootScope.currentShop._id },
            primaryProductId: primaryProductId, // Here Primary means searched product
            secondaryProductIds: secondaryProductIds // secondary means MAIN product
          }

          console.log('---------- Add Product For Mergeing Suggestion ------------')
          // Here Have Api call For The Add Product For Merge Suggestion

          $product.addProductForMergr(params)
            .then(function (success) {
              var prodID = $scope.product._id // Searched Product
              var primaryMergeProductId = $scope.product._id
              var secondaryRetailerModalProductId = $scope.showProduct._id
              var isProductExistInShop = false

              $shop.isProductInShop($rootScope.currentShop._id, secondaryRetailerModalProductId, primaryMergeProductId)
                .then(function (res) {
                  console.log('Res', res)

                  if (res.mayBeShopProduct && res.mayBeShopProduct.length) {
                    isProductExistInShop = true
                    var productToTransfer = res.mayBeShopProduct[0]
                    var productFromTransfer = res.shopProduct[0] // Retailer Product (Open the Modal)
                    var productNumberA = $scope.product.variants[0].productNumber
                    var productNumberB = $scope.showProduct.variants[0].productNumber

                    $scope.openProductTransferConfirmationModal($rootScope.currentShop, productFromTransfer, productToTransfer, productNumberA, productNumberB)
                      .then(function (shopProduct) {
                        console.log('Product Id', $scope.product._id)
                        console.log('ShopProduct details', shopProduct._id)
                        $scope.updateOrAddNewSuggestion($scope.product, shopProduct)
                      })
                      .catch(function (reason) {
                        console.error(' Error here', reason)
                      })
                  } else {
                    var productFromTransfer = res.shopProduct[0] // Retailer Product (Open the Modal)
                    var mytempobj = {
                      _id: $scope.product._id,
                      articleNumber: productFromTransfer.articleNumber,
                      labelDescription: productFromTransfer.labelDescription,
                      ownership: productFromTransfer.ownership,
                      stock: (productFromTransfer.stock) ? productFromTransfer.stock : 0,
                      purchasePrice: (productFromTransfer.purchasePrice) ? productFromTransfer.purchasePrice : 0,
                      price: (productFromTransfer.price) ? productFromTransfer.price : 0,
                      priceVat: (productFromTransfer.priceVat) ? productFromTransfer.priceVat : 21,
                      dropshippingPrice: (productFromTransfer.dropshippingPrice) ? productFromTransfer.dropshippingPrice : 0,
                      discount: (productFromTransfer.discount) ? productFromTransfer.discount : 0,
                      discountOnPercentage: productFromTransfer.discountOnPercentage,
                      show: !!(productFromTransfer.show),
                      isBestseller: !!(productFromTransfer.isBestseller),
                      isFeatured: !!(productFromTransfer.isFeatured),
                      reference: productFromTransfer.reference,
                      hasStock: !!(productFromTransfer.hasStock),
                      supplier: productFromTransfer.supplier,
                      purchaseOrders: productFromTransfer.purchaseOrders,
                      minStock: (productFromTransfer.minStock) ? productFromTransfer.minStock : 0,
                      maxStock: (productFromTransfer.maxStock) ? productFromTransfer.maxStock : 0,
                      entryMethodCustomerValue: (productFromTransfer.entryMethodCustomerValue) ? productFromTransfer.entryMethodCustomerValue : false,
                      delivery: productFromTransfer.delivery,
                      showSuggestion: (productFromTransfer.showSuggestion) ? productFromTransfer.showSuggestion : false
                    }

                    console.log('mytempobj', mytempobj)
                    // $scope.showProduct._id
                    $shop.transferProductData($rootScope.currentShop._id, $scope.showProduct._id, null, $scope.productToTransfer, $rootScope.currentShop.nameSlug)
                      .then(function (data) {
                        $scope.updateOrAddNewSuggestion($scope.product, mytempobj)
                      })
                      .catch(function (error) {
                        console.log('error', error)
                      })
                  }
                })
                .catch(function (error) {
                  console.error('Error in isProductInShop')
                  console.error(error)
                })
            })
            .catch(function (error) {
              console.log('Error to send Request for the merge', error)
              $scope.alert = {
                type: 'danger',
                msg: 'Het lukte niet om uw gegevens op te slaan ' + error
              }
              $scope.upload = false
            })
        }

        $scope.updateOrAddNewSuggestion = function (product, shopProductDetails) {
          console.log('Update Or Add New Suggestion To Product', product)
          var newCollections = {
            brand: product.brandCollections,
            variant: product.variantCollections,
            privateLable: product.privateLableCollections
          }

          var newProductId = product._id
          var newProductNumber = product.variants[0].productNumber

          // newCollections.privateLable.push(show.privateLableCollections);
          delete product.brandCollections
          delete product.variantCollections

          var suggester = {
            id: $rootScope.user._id,
            name: $rootScope.user.firstName + ' ' + $rootScope.user.lastName + ' ' + $rootScope.currentShop.name,
            usertype: $rootScope.user.role,
            currentShopId: $rootScope.currentShop._id
          }

          shopProductDetails._id = product._id

          $shop.updateProduct(suggester, product, $scope.originalProduct, shopProductDetails, $scope.oldCollections, newCollections, $rootScope.currentShop.nameSlug, 'null', false, 'compare-product', $rootScope.currentShop._id, false)
            .then(function (response) {
              $rootScope.productId = product._id
              $scope.loading = false
              $shop.updateProductIdInTransaction($rootScope.currentShop._id, $scope.showProduct._id, newProductId, newProductNumber)
                .then(function (success) {
                  $state.go('retailer.assortment')
                })
                .catch(function (error) {
                  console.error('Error !, While Update All Current Transaction With New Product')
                  console.error(error)
                })
            })
            .catch(function (reason) {
              console.error('Error! : ', reason)
            })
        }

        $scope.setTargetAudience = function (male, female, kids) {
          if (male) {
            if (female) {
              if (kids) $scope.targetAudience = 'KIDS'
              else $scope.targetAudience = 'UNISEX'
            } else {
              if (kids) $scope.targetAudience = 'BOYS'
              else $scope.targetAudience = 'GENTS'
            }
          } else {
            if (female) {
              if (kids) $scope.targetAudience = 'GIRLS'
              else $scope.targetAudience = 'LADIES'
            }
          }

          $scope.setGender($scope.targetAudience)
        }

        $scope.getProductsForMerge = function (primaryProductId, secondaryProductId, currentShopId) {
          $product.getProductsForMerged(primaryProductId, secondaryProductId, currentShopId)
            .then(function (response) {
              console.log('Response ====> ', response.data)
              $scope.product = response.data.primaryProduct
              console.log('Product', $scope.product)
              $scope.originalProduct = JSON.parse(JSON.stringify(response.data.primaryProduct))

              $scope.showProduct = response.data.secondaryProduct
              console.log('Merge Product ==>', $scope.showProduct)
              $scope.showShopProduct = response.data.shopProductSecondary
              console.log('Merge Shop Product ==>', $scope.showShopProduct)

              // console.log("Shop Product ====>" , response);
              if (response.data && response.data.shopProductPrimary) {
                $scope.shopProduct = response.data.shopProductPrimary
                console.log('Shop Product ====>', $scope.shopProduct)
                $scope.originalShopProduct = JSON.parse(JSON.stringify(response.data.shopProductPrimary))
              } else {
                $scope.shopProduct = $scope.showShopProduct
                $scope.shopProduct._id = $scope.product._id
                $scope.originalShopProduct = JSON.parse(JSON.stringify($scope.shopProduct))
              }

              // console.log("Shop Product" , $scope.shopProduct , $scope,originalShopProduct);

              if ($scope.shopProduct && $scope.shopProduct.discount > 0) {
                if ($scope.shopProduct.discountOnPercentage == true) $scope.usePercentage = true
                $scope.onSale = true
                $scope.setDiscount($scope.shopProduct.discount, $scope.shopProduct.discountOnPercentage)
              } else {
                $scope.onSale = false
              }

              if ($scope.product.brand && $scope.product.brand.name && $scope.product.brand.isPoolArticleBrand) {
                $scope.disabled = true
              } else if ($scope.product.brand && $scope.product.brand.name) {
                $scope.PNandBrandDisabled = true
              }

              if ($scope.product.images) $scope.image = $scope.product.images

              if ($scope.product.suggestedRetailPrice) $scope.product.suggestedRetailPrice = Number($scope.product.suggestedRetailPrice * (($scope.product.suggestedRetailPriceVat / 100) + 1)).toFixed(2)
              if ($scope.showProduct.suggestedRetailPrice) $scope.showProduct.suggestedRetailPrice = Number($scope.showProduct.suggestedRetailPrice * (($scope.showProduct.suggestedRetailPriceVat / 100) + 1)).toFixed(2)

              $scope.setTargetAudience($scope.product.male, $scope.product.female, $scope.product.kids)

              if ($scope.product.category === 'WATCH') {
                // console.log("product handling guy Please Check This", typeof ($scope.product.watch.case.color));
                if ($scope.product.watch.dial && $scope.product.watch.dial.color) $scope.dialColor = $scope.dialColors.find(function (color) {
                  return color.key == $scope.product.watch.dial.color
                })

                if ($scope.product.watch.case && $scope.product.watch.case.color) $scope.caseColor = $scope.caseColors.find(function (color) {
                  return color.key == $scope.product.watch.case.color
                })

                if ($scope.product.watch.strap && $scope.product.watch.strap.color) $scope.strapColor = $scope.strapColors.find(function (color) {
                  return color.key == $scope.product.watch.strap.color
                })
              } else if ($scope.product.category === 'STRAP') {
                if ($scope.product.strap && $scope.product.strap.color) $scope.strapColor = $scope.strapColors.find(function (color) {
                  return color.key == $scope.product.strap.color
                })
              } else if ($scope.product.category === 'JEWEL') {
                if ($scope.product.jewel && $scope.product.jewel.color && $scope.product.jewel.color.length && $scope.product.jewel.color.length[0]) $scope.jewelColor = $scope.jewelColors.find(function (color) {
                  return color.key == $scope.product.jewel.color[0]
                })
              }
            })
            .catch(function (error) {
              console.error('Error Finds Product For the Primary and Secondry Product', error)
            })
        }

        $scope.getValueChangedIfSuggestionClick = function (sugvalue, prodfeildname) {
          console.log('Sug Values ====>', sugvalue)
          console.log('prodfeildname ====>', prodfeildname)
          console.log('------------- getValueChangedIfSuggestionClick retailer1!');

          if (prodfeildname == 'product.watch.strap.color') $scope.setStrapColorInitially(sugvalue)
          if (prodfeildname == 'product.watch.case.color') $scope.setCaseColorInitially(sugvalue)
          if (prodfeildname == 'product.watch.dial.color') $scope.setDialColorInitially(sugvalue)
          // Get the model
          var model = $parse(prodfeildname)
          // Assigns a value to it
          model.assign($scope, sugvalue)

          if (prodfeildname == 'product.variants[0].productNumber') {
            console.log('inside if 1!');

            // $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber)

            if ($scope.product.brand) $scope.productOldBrand = JSON.parse(JSON.stringify($scope.product.brand));
            console.log('inside if 2!');
            console.log('$scope.product', $scope.product)
            console.log('$scope.productBrand', $scope.product.brand)
            if ($scope.product.variants && $scope.product.variants.length && $scope.product.variants[0].productNumber) {
              console.log('inside if 3!');
              var index = $scope.product.variants[0].productNumber.indexOf('-');
              console.log("Index of the divider", index);
              console.log(" ***** Offical Product :::: ");
              // Here have only possible the brand stating digit
              if (index > -1) {
                console.log("Here have staring digit applied");
                $scope.product.variants[0].insertedProductNumber = $scope.product.variants[0].productNumber.slice(index + 1);
                console.log("not have staring digit applied");
                if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                  console.log("You can apply new");
                  $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].insertedProductNumber));
                  $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                  $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
                } else {
                  console.log("No new Brands");
                  $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.variants[0].insertedProductNumber));
                  $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
                }
              } else {
                console.log("not have staring digit applied");
                if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                  console.log("You can apply new");
                  $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                  $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].productNumber));
                  $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);

                } else {
                  console.log("No new Brands")
                }
              }
            } else {
              console.log(" Have in else")
            }
          }


          if (prodfeildname == 'product.variants[0].ean'){
            console.log('hello here in ean check', $rootScope.currentShop._id)
            $scope.eanIsExistsOrNot($scope.product.variants[0].ean)
          } 
        }

        $scope.getValueChangedIfSuggestionClickForIndex = function (sugvalue, prodfeildname, index) {
          var finalprodfeildname = prodfeildname.replace('$index', index)
          // console.log("finalprodfeildname", prodfeildname, index);
          // Get the model
          var model = $parse(finalprodfeildname)
          // console.log("old value", $scope[finalprodfeildname]);
          // Assigns a value to it
          model.assign($scope, sugvalue)
          // console.log("new value", $scope[finalprodfeildname]);
        }

        $scope.getValueChangedIfSuggestedAudienceClick = function (suggestedProduct) {
          var sug = $scope.getSexAudience(suggestedProduct)
          $scope.targetAudience = sug
          $scope.setGender($scope.targetAudience)
        }

        $scope.showProductChange = function (obj, parentObj) {
          if (obj) {
            if ($scope.showProduct && $scope.showProduct.suggestedRetailPrice) {
              $scope.showProduct.suggestedRetailPrice = 0
            }

            console.log('Suggestion Change', obj)
            delete obj.collections
            delete obj.collection
            $scope.showProduct = JSON.parse(JSON.stringify(obj))
            $scope.oldCollections = {}
            $scope.oldCollections.brandCollections = parentObj.brandCollections
            $scope.oldCollections.variantCollections = parentObj.variantCollections
            $scope.showProduct.brandCollections = parentObj.brandCollections
            $scope.showProduct.variantCollections = parentObj.variantCollections
            $scope.showProduct.itemIsToMerge = parentObj.itemIsToMerge
            if ($scope.showProduct.suggestedRetailPrice) $scope.showProduct.suggestedRetailPrice = $scope.showProduct.suggestedRetailPrice * (($scope.showProduct.suggestedRetailPriceVat / 100) + 1)

            console.log('suggestion to merge', $scope.showProduct)
          }
        }

        // Set The Values For the Key For Product

        $scope.setDialColor = function (color) {
          $scope.product.watch.dial.color = [color.key]
        }

        $scope.setProductCategory = function (category) {
          console.log('product handling guy Check The category', category)
          if (category == 'WATCH') {
            $scope.product.strap = $scope.product.jewel = {}
            // $scope.product.watch = { case: {}, strap: {}, dial: {} };
          } else if (category == 'STRAP') {
            // $scope.product.watch = $scope.product.jewel = {};
          } else if (category == 'JEWEL') {
            // $scope.product.strap = $scope.product.watch = {};
          }
          $scope.product.category = category
        }

        $scope.setCaseColor = function (color) {
          console.log('color value', color)
          console.log('scope value', $scope.caseColor)
          $scope.product.watch.case.color = [color.key]
        }
        $scope.setCaseColorInitially = function (color) {
          console.log('SET CASE COLOR CALLED FOR COLOR INITIALLY: ', color)
          if (color == null || color == undefined || color == '') return

          color = color.toUpperCase()
          console.log('CASE COLOR SENT FOR INITIALLY SETTING : ', color)
          for (var i = 0; i < $scope.caseColors.length; i++) {
            if ($scope.caseColors[i].key == color) {
              $scope.caseColor = $scope.caseColors[i]
              $scope.setCaseColor(color)
              break
            }
          }
          if (!$scope.caseColor) console.log('NO CASECOLOR MATCHED TO SET INITIALLY')
        }

        $scope.setDialColorInitially = function (color) {
          if (color == null || color == undefined || color == '') return
          for (var i = 0; i < $scope.dialColors.length; i++) {
            if ($scope.dialColors[i].key == color) {
              $scope.dialColor = $scope.dialColors[i]
              $scope.setDialColor(color)
              break
            }
          }
        }

        $scope.setStrapColor = function (color) {
          if ($scope.product.category == 'WATCH') {
            $scope.product.watch.strap.color = [color.key]
          } else if ($scope.product.category == 'STRAP') {
            $scope.product.strap.color = [color.key]
          }
        }

        $scope.setStrapColorInitially = function (color) {
          console.log('SET STRAP COLOR CALLED FOR COLOR INITIALLY: ', color)
          if (color == null || color == undefined || color == '') return

          color = color.toUpperCase()
          for (var i = 0; i < $scope.strapColors.length; i++) {
            if ($scope.strapColors[i].key == color) {
              $scope.strapColor = $scope.strapColors[i]
              $scope.setStrapColor(color)
              break
            }
          }
        }

        // Start Mergeing Functions

        $scope.scheduleToMerge = function () {
          var primaryProduct = $scope.product._id
          var secondaryProduct = $scope.showProduct._id
          console.log('scheduled merge: ', primaryProduct, secondaryProduct)

          $product.getMergedRequestProducts(primaryProduct, secondaryProduct)
            .then(function (success) {
              console.log(' getMergedRequestProducts =====> ', success)
              $scope.showMergeSuccessAlert = true
            })
            .catch(function (error) {
              console.error(' getMergedRequestProducts Error! ====>', error)
            })
        }

        $scope.clearSelectionForMerging = function () {
          $scope.showProduct = ''
          $scope.showMergeSuccessAlert = false
        }

        // $scope.getTheValueChangedForImages = function () {
        // 	$scope.product.images = $scope.showProduct.images;
        // 	$scope.image = $scope.product.images;
        // }

        $scope.$watch('product', function () {
          console.log('PRODUCT OBJECT CHANGED ', $scope.product)
        })
        // FOR COLLECTIONS
        $scope.searchCollections = function (str) {
          // console.log("SEARCH COLLECTION CALLED");
          return searchItems(str, 'collections')
        }

        $scope.getCollections = function () {
          // console.log("GETTING COLLECTIONS");
          return getItems('collections')
        }

        $scope.collectionSelected = function (selected) {
          // console.log("Selected", selected);
          // console.log("Language = ", $scope.language);
          $scope.addNewCollectionForm = false
          if (selected.title === ' ➕ Add New Collection' || selected.title === ' ➕ Kollektion hinzufügen' || selected.title === ' ➕ Voeg nieuwe collectie toe' || selected.title === ' ➕ Añadir colección' || selected.title === ' ➕ Ajouter une collection') {
            console.log('Selected', selected)

            $scope.addNewCollectionForm = true
            $('#collection-' + $rootScope.language + '-name').val($scope.searchStr)
          } else {
            var collection = selected.originalObject

            if (!$scope.product.collections) {
              $scope.product.collections = []
            }
            $scope.product.collections.push(collection)
          }
        }

        var getItems = function (type) {
          $api.get(type, { shopId: $rootScope.currentShop._id })

            .then(function (response) {
              $scope[type] = response.data[type]
            })

            .catch(function (reason) {
              console.log(reason)
            })
        }

        $scope.getBrands = function (shopNameSlug) {
          $shop.getshopbrandsForProduct(shopNameSlug)
            .then(function (response) {
              if (response.brands.length > 0) {
                $scope.brands = response.brands
              } else {
                $scope.brands = []
              }
            })
            .catch(function (err) {
              console.error('Error while getting products', err)
            })
        }

        var searchItems = function (str, type) {
          console.log('Search', str, type)
          var matches = []
          var addNewCollectionItem = {
            en: {
              name: ' ➕ Add New Collection'
            },
            nl: {
              name: ' ➕ Voeg nieuwe collectie toe'
            },
            es: {
              name: ' ➕ Añadir colección'
            },
            fr: {
              name: ' ➕ Ajouter une collection'
            },
            de: {
              name: ' ➕ Kollektion hinzufügen'
            }
          }

          matches.push(addNewCollectionItem)

          $scope[type].forEach(function (item) {
            if (type != 'brands') {
              if (
                (item[$rootScope.language] != undefined && item[$rootScope.language].name != undefined) &&
                (
                  (item[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                  (item[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)
                )
              ) {
                matches.push(item)
              }
            } else {
              if (
                (item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                (item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)
              ) {
                matches.push(item)
              }
            }
          })

          return matches
        }

        $scope.searchBrands = function (str) {
          var matches = []
          $scope.brands.forEach(function (brand) {
            if ((brand._id[$rootScope.language] != undefined && brand._id[$rootScope.language].name != undefined) && ((brand._id[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) || (brand._id[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0))) matches.push(brand)
          })
          return matches
        }

        $scope.brandSelected = function (selected) {
          console.log("selected", selected)

          if ($scope.product.brand) $scope.productOldBrand = JSON.parse(JSON.stringify($scope.product.brand));
          var brand = {
            _id: selected.originalObject._id,
            name: selected.title,
            nameSlug: selected.originalObject.nameSlug,
            description: selected.originalObject.description,
            images: selected.originalObject.images,
            startingDigits: (selected.originalObject._id.startingDigits && selected.originalObject._id.startingDigits != 'null' && selected.originalObject._id.startingDigits != 'undefined' && selected.originalObject._id.startingDigits != 'NULL') ? selected.originalObject._id.startingDigits : ''
          }

          $scope.product.brand = brand
          console.log('$scope.product', $scope.product)
          console.log('$scope.productBrand', $scope.product.brand)
          if ($scope.product.variants && $scope.product.variants.length && $scope.product.variants[0].productNumber) {
            var index = $scope.product.variants[0].productNumber.indexOf('-');
            console.log("Index of the divider", index);
            console.log(" ***** Offical Product :::: ");
            // Here have only possible the brand stating digit
            if (index > -1) {
              console.log("Here have staring digit applied");
              $scope.product.variants[0].insertedProductNumber = $scope.product.variants[0].productNumber.slice(index + 1);
              console.log("not have staring digit applied");
              if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                console.log("You can apply new");
                $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].insertedProductNumber));
                $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
              } else {
                console.log("No new Brands");
                $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.variants[0].insertedProductNumber));
                $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
              }
            } else {
              console.log("not have staring digit applied");
              if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                console.log("You can apply new");
                $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].productNumber));
                $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);

              } else {
                console.log("No new Brands")
                $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
              }
            }
          } else {
            console.log(" Have in else no productNumber ")
          }
        }

        $scope.addProductVariant = function () {
          /* TODO: remove collapse property on save */
          $scope.product.variants.push({
            collapse: true
          })
        }

        $scope.removeProductVariant = function (index) {
          $scope.product.variants.splice(index, 1)
        }
        $scope.customGetTargetAudience = function () {
          // console.log("GETTING TARGET AUDIENCE from scope to native.");
          getTargetAudience()
        }
        var getTargetAudience = function () {
          // console.log("SETTING TARGET AUDIENCE");
          if ($scope.product.male) {
            if ($scope.product.female) {
              if ($scope.product.kids) {
                $scope.targetAudience = 'KIDS'
              } else {
                $scope.targetAudience = 'UNISEX'
              }
            } else {
              if ($scope.product.kids) {
                $scope.targetAudience = 'BOYS'
              } else {
                $scope.targetAudience = 'GENTS'
              }
            }
          } else {
            if ($scope.product.female) {
              if ($scope.product.kids) {
                $scope.targetAudience = 'GIRLS'
              } else {
                $scope.targetAudience = 'LADIES'
              }
            }
          }
        }

        $scope.getSexAudience = function (prod) {
          var targetAudience = ''
          if (prod.male) {
            if (prod.female) {
              if (prod.kids) {
                targetAudience = 'KIDS'
              } else {
                targetAudience = 'UNISEX'
              }
            } else {
              if (prod.kids) {
                targetAudience = 'BOYS'
              } else {
                targetAudience = 'GENTS'
              }
            }
          } else {
            if (prod.female) {
              if (prod.kids) {
                targetAudience = 'GIRLS'
              } else {
                targetAudience = 'LADIES'
              }
            }
          }
          return targetAudience
        }

        $scope.setGender = function (targetAudience) {
          switch (targetAudience) {
            case 'GENTS':
              $scope.product.male = true
              $scope.product.female = false
              $scope.product.kids = false
              break
            case 'LADIES':
              $scope.product.male = false
              $scope.product.female = true
              $scope.product.kids = false
              break
            case 'UNISEX':
              $scope.product.male = true
              $scope.product.female = true
              $scope.product.kids = false
              break
            case 'BOYS':
              $scope.product.male = true
              $scope.product.female = false
              $scope.product.kids = true
              break
            case 'GIRLS':
              $scope.product.male = false
              $scope.product.female = true
              $scope.product.kids = true
              break
            case 'KIDS':
              $scope.product.male = true
              $scope.product.female = true
              $scope.product.kids = true
              console.log('KId man', $scope.product.male)
              console.log('female', $scope.product.female)
              console.log('kid', $scope.product.kids)
              break
          }
        }

        $scope.openProductTransferConfirmationModal = function (shop, productFromTransfer, productToTransfer, productNumberA, productNumberB) {
          return $q(function (resolve, reject) {
            var modalInstance = $uibModal.open({
              templateUrl: '../views/modal/retailer-product-transfer-confirmation-modal.html',
              controller: 'retailerProductTransferConfirmationModalController',
              size: 'lg',
              resolve: {
                product: function () {
                  var product = {}
                  product.shop = shop
                  product.productFromTransfer = productFromTransfer
                  product.productToTransfer = productToTransfer
                  product.PFTPN = productNumberA
                  product.PTTPN = productNumberB
                  return product
                }
              }
            })

            modalInstance.result
              .then(function (response) {
                resolve(response)
              })
              .catch(function (reason) {
                reject(reason)
              })
          })
        }

        // console.log("CURRENT MODE = ", $scope.currentMode);
        $scope.getVersionValues = function (property) {
          if (!$scope.product || !$scope.product.versions || $scope.product.versions.length == 0) return
          var propertyValues = []
          for (var i = 0; i < $scope.product.versions.length; i++) {
            var value = _.get($scope.product.versions[i].modifiedProperties[0], property)
            if (value) {
              propertyValues.push(value)
            }
          }
          return propertyValues
        }

        $scope.addCollection = function (collection) {
          // console.log("Adding Collection : ", collection);
          collection.shopId = $rootScope.currentShop._id;
          $api.post('collections', collection).then(function (success) {
            console.log('Collection added successtully ', success.data)
            $scope.collections.push(success.data.collection)
            var collToBeSelected = {
              originalObject: success.data.collection,
              title: success.data.collection[$rootScope.language].name
            }
            $scope.collectionSelected(collToBeSelected)
            $scope.collection = {}
          }, function (err) {
            console.log('Error adding collection : ', err)
          })
        }

        $scope.toggleAdminMustCheckSwitches = function (adminMustCheck) {
          if (adminMustCheck == true) {
            $scope.isVerified = false
            $scope.adminMustCheck = true
          } else {
            $scope.isVerified = true
            $scope.adminMustCheck = false
          }
        }

        $scope.toggleIsVerifiedSwitches = function (isVerified) {
          if (isVerified == true) {
            $scope.product.isVerified = true
            $scope.adminMustCheck = false
          } else {
            $scope.adminMustCheck = true
            $scope.product.isVerified = false
          }
        }

        // Tab Selection
        $scope.tabSelected = function (index) {
          console.log('check the index', index)
          $rootScope.activeTabSuggestedProduct = index
          $rootScope.activeTabCurrentProduct = index
        }

        // Start Diamond Functions

        $scope.openDiamond = function (index) {
          // Open Main Product Diamond
          $scope.diamond = $scope.product.diamonds[index]
          $scope.diamondIndex = index
          $scope.editDiamond = true
        }

        $scope.openSuggDiamond = function (index) {
          // Open Suggestion Product Diamond

          $scope.suggDiamond = $scope.showProduct.diamonds[index]
          $scope.suggDiamondIndex = index
          $scope.editSuggDiamond = true
        }

        $scope.clearTheDiamond = function () {
          // Clear Diamond
          $scope.diamond = {
            quantity: '',
            gemKind: '',
            gemPurity: {
              code: ''
            },
            gemColor: {
              code: ''
            },
            gemCut: '',
            caratWeight: '',
            suggestedRetailPriceDiamond: ''
          }
          $scope.editDiamond = false
        }

        $scope.cancelNewDiamond = function (diamondIndex) {
          // Cancel New Diamond
          $scope.product.diamonds.splice(diamondIndex, 1)
          $scope.addDiamond = false
          $scope.clearTheDiamond()
        }

        $scope.addNewDiamondForm = function () {
          // Add New Diamond Form
          $scope.editDiamond = false
          $scope.clearTheDiamond()

          if (!$scope.product.diamonds) $scope.product.diamonds = []

          $scope.diamondIndex = $scope.product.diamonds.length
          $scope.addDiamond = true
        }

        $scope.pushDiamond = function (index) {
          // Add New Diamond In diamons array
          if (!$scope.product.diamonds) $scope.product.diamonds = []
          console.log('Diamond is emplty or not', $.isEmptyObject($scope.diamond))
          $scope.product.diamonds[index] = $scope.diamond
          $scope.editDiamond = false
          $scope.addDiamond = false
          $scope.clearTheDiamond()
        }

        $scope.deleteDiamond = function (index) {
          // Remove Diamond From Diamonds array
          $scope.product.diamonds.splice(index, 1)
        }

        $scope.assignValueForDiamond = function (prodIndex, suggIndex, key) {
          if (key != 'gemColor' && key != 'gemPurity') {
            $scope.product.diamonds[prodIndex][key] = $scope.showProduct.diamonds[suggIndex][key]
          } else $scope.product.diamonds[prodIndex][key].code = $scope.showProduct.diamonds[suggIndex][key].code
        }

        // END Diamond Functions

        // Start Get Colors Class Functions

        $scope.getClassForDiamons = function (prodIndex, suggIndex, key) {
          if (key != 'gemColor' && key != 'gemPurity') {
            var sug = $scope.showProduct.diamonds[suggIndex][key]
            var prod = $scope.product.diamonds[prodIndex][key]
          } else {
            var sug = $scope.showProduct.diamonds[suggIndex][key].code
            var prod = $scope.product.diamonds[prodIndex][key].code
          }
          var colorCode
          if ((sug == undefined || sug == '') && (prod == undefined || prod == '')) {
            colorCode = ' '
          } else if (sug == prod) {
            colorCode = ' '
          } else if ((sug == undefined || sug == '') && (prod != undefined && prod != '')) {
            colorCode = 'redColorClass'
          } else if ((prod == undefined || prod == '') && (sug != undefined && sug != '')) {
            colorCode = 'greenColorClass'
          } else if (sug != prod) {
            colorCode = 'orangeColorClass'
          }
          console.log('Color Code', colorCode)
          return colorCode
        }

        $scope.getClassForSuggestion = function (sug, prod) {
          console.log('----------------------- getClassForSuggestion!');
          console.log(sug);
          console.log(prod);
          var colorCode
          if ((sug == undefined || sug == '') && (prod == undefined || prod == '')) {
            console.log('1')
            colorCode = ' '
          } else if (sug == prod) {
            console.log('2')
            colorCode = ' '
          } else if ((sug == undefined || sug == '') && (prod != undefined && prod != '')) {
            console.log('3')
            colorCode = 'redColorClass'
          } else if ((prod == undefined || prod == '') && (sug != undefined && sug != '')) {
            console.log('4')
            colorCode = 'greenColorClass'
          } else if (sug != prod) {
            // console.log('5')
            colorCode = 'orangeColorClass'
          }
          // console.groupEnd();
          return colorCode
        }

        $scope.getClassForSuggestionBoolen = function (sug, prod) {
          var colorCode
          // console.group("Color Class");
          console.log('this boolean', sug, prod)
          if (sug == prod) {
            console.log('2')
            colorCode = ' '
          } else if ((sug == null || sug == undefined) && (prod != null && prod != undefined)) {
            console.log('3')
            colorCode = 'redColorClass'
          } else if ((prod == null || prod == undefined) && (sug != null && sug != undefined)) {
            console.log('4')
            colorCode = 'greenColorClass'
          } else if (sug != prod) {
            console.log('5')
            colorCode = 'orangeColorClass'
          }
          // console.groupEnd();
          return colorCode
        }

        $scope.getClassForSexAudienceSuggestion = function (suggestedProduct) {
          // Get Color For Target Audiaince
          var sug = $scope.getSexAudience(suggestedProduct)
          var prod = $scope.targetAudience
          var colorCode
          if ((sug == undefined || sug == '') && (prod == undefined || prod == '')) {
            colorCode = ' '
          } else if (sug == prod) {
            colorCode = ' '
          } else if ((sug == undefined || sug == '') && (prod != undefined && prod != '')) {
            colorCode = 'redColorClass'
          } else if ((prod == undefined || prod == '') && (sug != undefined && sug != '')) {
            colorCode = 'greenColorClass'
          } else if (sug != prod) {
            colorCode = 'orangeColorClass'
          }
          return colorCode
        }

        $scope.getClassForImages = function (src) {
          // Get Color For the images
          var p_image_length = $scope.product.images.length || 0
          var exists

          if (src && p_image_length) {
            for (var i = 0; i < p_image_length; i++) {
              if ($scope.product.images[i].src && ($scope.product.images[i].src == src)) {
                exists = true
                i = p_image_length
              }
            }
            if (exists) return ''
            else return 'orangeColorClass'
          } else if (src) {
            return 'redColorClass'
          } else {}
        }

        function changeImageIndex(currentIndex, newIndex) {
          var image1 = $scope.image[currentIndex]
          var image2 = $scope.image[newIndex]
          $scope.image[currentIndex] = image2
          $scope.image[newIndex] = image1
        }

        $scope.previousImage = function (currentIndex) {
          changeImageIndex(currentIndex, (currentIndex - 1))
        }

        $scope.nextImage = function (currentIndex) {
          changeImageIndex(currentIndex, (currentIndex + 1))
        }

        $scope.getTheValueChangedForImages = function (index) {
          var newImage = $scope.showProduct.images[index]
          if (_.findIndex($scope.image, function (o) {
              return o.src == newImage.src
            }) < 0) {
            $scope.image.push($scope.showProduct.images[index])
          }
        }

        $scope.getTheCollectionName = function (collection) {
          var collectionName = ''
          if (collection.hasOwnProperty($rootScope.language)) {
            collectionName = collection[$rootScope.language].name
          } else if (collection.hasOwnProperty('en.name')) {
            collectionName = collection.en.name
          } else if (collection.hasOwnProperty('nl.name')) {
            collectionName = collection.nl.name
          } else if (collection.hasOwnProperty('de.name')) {
            collectionName = collection.de.name
          } else if (collection.hasOwnProperty('fr.name')) {
            collectionName = collection.fr.name
          } else if (collection.hasOwnProperty('name') && collection.name != '') {
            collectionName = collection.name
          } else {
            collectionName = 'NO NAME'
          }
          return collectionName
        }

        $scope.updateCollection = function (type, index) {
          if (type == 'variantCollections') {
            var tempCollection = JSON.parse(JSON.stringify($scope.showProduct.variantCollections[index]))
            $scope.product.variantCollections[index] = tempCollection
          } else {
            var tempCollection = JSON.parse(JSON.stringify($scope.showProduct.brandCollections[index]))
            $scope.product.brandCollections[index] = tempCollection
          }
        }

        $scope.updateProduct = function () {
          console.log('$scope.product', $scope.product)

          $scope.loading = true

          console.log('changedStatus', $scope.changedStatus)
          console.log('New Product After Change and Old', $scope.product, $scope.oldProdct)

          if ($scope.product.suggestions) {
            $scope.oldProdct.suggestions = $scope.product.suggestions
          }
          console.log('_.isEqual(one, two); ', _.isEqual($scope.product, $scope.oldProdct))

          if (_.isEqual($scope.product, $scope.oldProdct)) {
            var newNotification = {
              shopId: $rootScope.currentShop._id,
              message: $notification.productComparePageNotification()
            }

            $notification.createShopNotification(newNotification)
              .then(function (success) {
                console.log('Successfully send Notification', success)
              })
              .catch(function (error) {
                console.error('Error :', error)
              })
          }

          if ($scope.product.suggestedRetailPrice) $scope.product.suggestedRetailPrice = $scope.product.suggestedRetailPrice / (($scope.product.suggestedRetailPriceVat / 100) + 1)

          var newCollections = {
            brand: $scope.product.brandCollections,
            variant: $scope.product.variantCollections,
            privateLable: $scope.product.privateLableCollections
          }

          delete $scope.product.brandCollections
          delete $scope.product.variantCollections
          delete $scope.product.privateLableCollections

          var suggester = {
            id: $rootScope.user._id,
            name: $rootScope.user.firstName + ' ' + $rootScope.user.lastName + ' ' + $rootScope.currentShop.name,
            usertype: $rootScope.user.role,
            currentShopId: $rootScope.currentShop._id
          }

          $product.updateProduct($rootScope.currentShop.nameSlug, $scope.product, newCollections, $scope.oldCollections, suggester, $rootScope.currentShop._id)
            .then(function (updatedProduct) {
              console.log('Product Updated', updatedProduct)
              if ($scope.images && $scope.images.length > 0 && $scope.images[0].name) {
                $scope.upload = true
                Upload.upload({
                    url: 'api/product-image-upload',
                    data: {
                      productId: $scope.product._id,
                      files: $scope.images, // file input field
                      shopId: $rootScope.currentShop._id
                    }
                  })
                  .then(function (res) {
                    // $scope.loading = false;
                    console.log("************************************");
                    console.log("************************************");
                    console.log("************************************");
                    // $state.go('retailer.home');
                    $scope.afterUpdate()
                  })
                  .catch(function (error) {
                    $scope.loading = false
                    // $state.go('retailer.home')
                    $scope.afterUpdate()
                    console.error('Error In Update Images', error)
                  })
              } else {
                $scope.loading = false
                $scope.afterUpdate()
                // $state.go('retailer.home')
              }
            })
            .catch(function (error) {
              console.error('Error :!', error)
            })
        }

        $scope.afterUpdate = function () {
          console.log("************************************");
          $scope.loading = false
          console.log("productCanBeUpdate Product Can be update", $rootScope.productCanBeUpdate);
          if ($rootScope.productCanBeUpdate) {
            console.log('state Params', $stateParams.nameSlug)
            console.log('productCanBeUpdate', $rootScope.productCanBeUpdate)
            var index = _.findIndex($rootScope.productCanBeUpdate, function (o) {
              return o._id.toString() == $stateParams.nameSlug.toString()
            })
            console.log('Index', index)
            if (index < 0) {
              // $state.go('retailer.home');
              // We can do new request for the next 10 products
            } else {
              $rootScope.productCanBeUpdate.splice(index, 1)
              console.log('$rootScope.productCanBeUpdate', $rootScope.productCanBeUpdate)
              if ($rootScope.productCanBeUpdate[index]) {
                $state.go('retailer.compare-product', {
                  nameSlug: $rootScope.productCanBeUpdate[index]._id,
                  mergeProductnameSlug: 'Not_For_Merge'
                })
              } else {
                // $state.go('retailer.home');
                getOwnShopUpdatedProducts($rootScope$rootScope.currentShop._id)
                // We can do new request for the next 10 products
              }
            }
          } else {
            // Sorry, User alreday referesh thise page
            // $state.go('retailer.home');
            getOwnShopUpdatedProducts($rootScope.currentShop._id)
          }
        }

        function getOwnShopUpdatedProducts(shopId) {
          $shop.getOwnShopUpdatedProducts(shopId)
            .then(function (response) {
              $rootScope.productCanBeUpdate = JSON.parse(JSON.stringify(response.data))
              if ($rootScope.productCanBeUpdate && $rootScope.productCanBeUpdate.length && $rootScope.productCanBeUpdate[0]) {
                $state.go('retailer.compare-product', {
                  nameSlug: $rootScope.productCanBeUpdate[0]._id,
                  mergeProductnameSlug: 'Not_For_Merge'
                })
              }
            })
            .catch(function (error) {
              console.error('Error! ========> Updated Products :', error)
            })
        }

        $scope.setDiscount = function (discount, usePercentage) {
          // Set Discount
          $scope.shopProduct.discountOnPercentage = usePercentage
          $scope.shopProduct.discount = discount

          if (usePercentage === true) {
            $scope.calculatedDiscount = ($scope.shopProduct.sellingPrice * $scope.shopProduct.discount) / 100
          } else {
            $scope.calculatedDiscount = $scope.shopProduct.discount
          }
        }

        $scope.onSaleChange = function (change) {
          // onSale Changes
          $scope.onSale = change
          console.log("It's called On Sale Change", change)
        }

        $scope.$watch('product', function (newVal, oldVal) {
          $productConfig.productIsValidForUpdate($scope.product, $scope.canUpdate, $scope.images)
            .then(function (canUpdate) {
              $scope.canUpdate = canUpdate
              $scope.changedStatus = true
              console.log('canUpdate', $scope.canUpdate)
            })
            .catch(function (error) {
              $scope.canUpdate = {
                EN: false,
                DE: false,
                NL: false,
                FR: false,
                ES: false
              }
              console.error('Error: Please Contact PrismaNote Admin')
            })
        }, true)

        $scope.$watch('images', function (newVal, oldVal) {
          $productConfig.productIsValidForUpdate($scope.product, $scope.canUpdate, $scope.images)
            .then(function (canUpdate) {
              $scope.canUpdate = canUpdate
              $scope.changedStatus = true
              console.log('canUpdate', $scope.canUpdate)
            })
            .catch(function (error) {
              $scope.canUpdate = {
                EN: false,
                DE: false,
                NL: false,
                FR: false,
                ES: false
              }
              console.error('Error: Please Contact PrismaNote Admin')
            })
        }, true)

        $scope.eanIsExistsOrNot = function (ean, shopId) {
          // Enterd EAN number is valid or not
         console.log('$rootScope.currentShop._id', $rootScope.currentShop._id)
          if (ean.length > 0) {
            $scope.eanValid = false
            $product.eanIsExistsOrNot(ean, $scope.product._id, $rootScope.currentShop._id)
              .then(function (result) {
                if (result.status == true) $scope.eanValid = true
              })
              .catch(function (error) {
                console.error('Error =>>> ', error)
              })
          } else {
            $scope.eanValid = true
          }
        }

        $scope.productNumberIsExistsOrNot = function (productNumber) {
          $scope.PNvalid = false
          $scope.PNsort = true

          if (productNumber) {
            productNumber = $productConfig.retrunValidProductNumber(productNumber)
            if (productNumber && productNumber.length > 2) {
              $scope.PNsort = false
              // Product Number is valid Or not
              $product.productNumberIsExistsOrNot(productNumber, $scope.product._id)
                .then(function (result) {
                  console.log('Response From Server', result)
                  if (result.status == true) {
                    $scope.PNvalid = true
                    $scope.product.variants[0].productNumber = productNumber
                  } else {
                    $scope.product.variants[0].productNumber = productNumber
                  }
                })
                .catch(function (error) {
                  console.error('Error =>>> ', error)
                })
            }
          }
        }

        $scope.sendDeleteSuggestionToAdmin = function () {
          if ($scope.product && $scope.product.brand && $scope.product.brand.nameSlug) {
            var brand = $rootScope.currentShop.brands.find(function (brand) {
              return brand.nameSlug == $scope.product.brand.nameSlug
            }) || null

            if (brand && brand.restricted) {
              prompt({
                title: $language.translate('DELETE_PRODUCT') + '?',
                message: $language.translate('YOU_ARE_OFFICIAL_DEALER_PRODUCT_STAYS_IN_ASSORTMENT')
              }).then(function () {
                // $scope.stockProducts[index].shopProduct.stock = 0;
                // $scope.stockProducts[index].shopProduct.show = false;
                $shop.updateShopProductStockAndShow($scope.product._id, $rootScope.currentShop._id)
                  .then(function (success) {
                    $state.go('retailer.assortment')
                  })
                  .catch(function (error) {
                    console.error('Error in', error)
                  })
              }, function () {
                reject('Cancel')
              })
            } else {
              prompt({
                  title: $language.translate('DELETE_PRODUCT') + '?',
                  message: $language.translate('ARE_YOU_SURE_TO_DELETE_THIS_PRODUCT'),
                  buttons: [{
                      label: $language.translate('DELETE_PRODUCT'),
                      primary: true,
                      id: 0
                    },
                    {
                      label: $language.translate('STOP_SELLING_STOCK_TO_0'),
                      primary: true,
                      id: 1
                    },
                    {
                      label: $language.translate('CANCEL'),
                      primary: false,
                      cancel: true,
                      id: 2
                    }
                  ]
                }).then(function (value) {
                  if (value && value.id == 0) {
                    $shop.deleteProductFromShop($scope.product._id, $rootScope.currentShop._id)
                      .then(function (result) {
                        $state.go('retailer.assortment')
                      })
                      .catch(function (error) {
                        console.error(' Error In delete product from the shop', error)
                      })
                  } else if (value && value.id == 1) {
                    $shop.updateShopProductStockAndShow($scope.product._id, $rootScope.currentShop._id)
                      .then(function (success) {
                        $state.go('retailer.assortment')
                      })
                      .catch(function (error) {
                        console.error('Error in', error)
                      })
                  } else {
                    console.log("I don't delete this product")
                  }
                })
                .catch(function (error) {
                  console.log('Error ====> Delete Product', error)
                })
            }
          } else {
            prompt({
                title: $language.translate('DELETE_PRODUCT') + '?',
                message: $language.translate('ARE_YOU_SURE_TO_DELETE_THIS_PRODUCT'),
                buttons: [{
                    label: $language.translate('DELETE_PRODUCT'),
                    primary: true,
                    id: 0
                  },
                  {
                    label: $language.translate('STOP_SELLING_STOCK_TO_0'),
                    primary: true,
                    id: 1
                  },
                  {
                    label: $language.translate('CANCEL'),
                    primary: false,
                    cancel: true,
                    id: 2
                  }
                ]
              }).then(function (value) {
                if (value && value.id == 0) {
                  $shop.deleteProductFromShop($scope.product._id, $rootScope.currentShop._id)
                    .then(function (result) {
                      $state.go('retailer.assortment')
                    })
                    .catch(function (error) {
                      console.error(' Error In delete product from the shop', error)
                    })
                } else if (value && value.id == 1) {
                  $shop.updateShopProductStockAndShow($scope.product._id, $rootScope.currentShop._id)
                    .then(function (success) {
                      $state.go('retailer.assortment')
                    })
                    .catch(function (error) {
                      console.error('Error in updateShopProductStockAndShow', error)
                    })
                } else {
                  console.log("I don't delete this product")
                }
              })
              .catch(function (error) {
                console.error(error)
              })
          }
        }

        $scope.applySuggestionBrand = function () {

          if ($scope.product.brand) $scope.productOldBrand = JSON.parse(JSON.stringify($scope.product.brand));

          if ($scope.showProduct.brand && $scope.showProduct.brand._id) $scope.product.brand = JSON.parse(JSON.stringify($scope.showProduct.brand))

          console.log('$scope.product', $scope.product)
          console.log('$scope.productBrand', $scope.product.brand)
          if ($scope.product.variants && $scope.product.variants.length && $scope.product.variants[0].productNumber) {
            var index = $scope.product.variants[0].productNumber.indexOf('-');
            console.log("Index of the divider", index);
            console.log(" ***** Offical Product :::: ");
            // Here have only possible the brand stating digit
            if (index > -1) {
              console.log("Here have staring digit applied");
              $scope.product.variants[0].insertedProductNumber = $scope.product.variants[0].productNumber.slice(index + 1);
              console.log("not have staring digit applied");
              if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                console.log("You can apply new");
                $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].insertedProductNumber));
                $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
              } else {
                console.log("No new Brands");
                $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.variants[0].insertedProductNumber));
                $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
              }
            } else {
              console.log("not have staring digit applied");
              if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                console.log("You can apply new");
                $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].productNumber));
                $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);

              } else {
                console.log("No new Brands")
              }
            }
          } else {
            console.log(" Have in else")
          }
        }
      }
    ])