prismanoteApp.controller('adminImportsController', ['$scope', '$rootScope', '$api', '$state', '$stateParams',
  function ($scope, $rootScope, $api, $state, $stateParams) {
    console.log('Admin Import Conrtoller')
    console.log('stateParams', $stateParams)

    if ($stateParams.id != null && $stateParams.id != undefined) {
      console.log($stateParams.id)
      getSingleImportsRecord($stateParams.id)
    }

    $scope.getImportsRecords = function () {
      console.log('this is called')
      var page = $scope.currentPage + 1
      console.log('limit and pageno', $scope.itemsPerPage, page)
      var iso_startDate = $scope.startDate.toISOString()
      var iso_endDate = $scope.endDate.toISOString()
      $api.get('import-records/' + iso_startDate + '/' + iso_endDate + '?limit=' + $scope.itemsPerPage + '&pageNumber=' + page)
        .then(function (response) {
          console.log('response', response.data)
          $scope.importRecords = response.data.ImportRecords
          console.log('import Record', $scope.importRecords)

          // $scope.total = response.data.total;
          // $scope.uniQueUsers = $scope.GetTheUsers();
          // console.log("importRecords", $scope.importRecords);
        })
        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.GetTheUsers = function () {
      var oNew = $scope.importRecords.filter(function (a) {
        if (!this[a.importer.userId.id]) {
          this[a.importer.userId.id] = true
          return true
        }
      }, Object.create(null))
      return oNew
    }

    function getSingleImportsRecord (id) {
      $api.get('import-record/' + id)
        .then(function (response) {
          response.data.ImportRecord.importer.dateOfCreation = new Date(response.data.ImportRecord.importer.dateOfCreation).toUTCString()
          $scope.importRecord = response.data.ImportRecord
          console.log('importRecords', $scope.importRecord)
        })
        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.selectUser = function (userId) {
      console.log('get the user selected', userId)
    }

    $scope.getSingleImportsRecordRoute = function (id) {
      console.log('Check The Id', id)
      $state.go('admin.import', { id: id })
    }

    $scope.deleteImport = function (importId) {
      console.log('ImportId For DELETED All Import Record', importId)
    }

    $scope.reverseImport = function (id) {
      $api.post('reverseImport/' + id)
        .then(function (response) {
          console.log('importRecords', response.data)
        })
        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.startDate = new Date()
    $scope.endDate = new Date()
    $scope.openTheStartCalendar = false
    $scope.openTheEndCalendar = false

    $scope.dateOptions = {
    }

    $scope.checkErr = function (startDate, endDate) {
      $scope.errMessage = ''

      var curDate = new Date()
      var dateMonthsAgo = curDate.setMonth(curDate.getMonth() - 6)
      var dateMonthsAfter = curDate.setMonth(curDate.getMonth() + 6)
      console.log('start date', startDate)
      console.log('end Date', endDate)

      if (new Date(startDate) > new Date(endDate)) {
        console.log('start date', startDate)
        console.log('end Date', endDate)
        console.log('End Date should be greater than start date')
        $scope.errMessage = 'End Date should be greater than start date'
        return false
      }
      // if (new Date(startDate) < dateMonthsAgo) {
      // 	console.log("start date", startDate);
      // 	console.log("end Date", endDate);
      // 	console.log("Start date should not be before today.");
      // 	$scope.errMessage = 'Start date should not be before today.';
      // 	return false;
      // }
      // if (new Date(endDate) < dateMonthsAfter) {
      // 	console.log("start date", startDate);
      // 	console.log("end Date", endDate);
      // 	console.log("Start date should not be before today.");
      // 	$scope.errMessage = 'Start date should not be before today.';
      // 	return false;
      // }
      $scope.getImportsRecords()
    }

    // pagination
    $scope.viewby = 5

    $scope.setItemsPerPage = function (num) {
      $scope.itemsPerPage = parseInt(num)
      $scope.currentPage = 0 // reset to first page
      $scope.checkErr()
    }

    $scope.changeThePage = function (direction) {
      var totalPages = Math.ceil($scope.total / $scope.itemsPerPage)
      // console.log("total pages", totalPages);
      if (direction == 'next') {
        var page = $scope.currentPage + 1
        if ((page + 1) <= totalPages) {
          $scope.currentPage = page
          $scope.checkErr()
        } else {
          // do nothing
          // $scope.checkErr();
        }
      } else if (direction == 'previous') {
        var page = $scope.currentPage - 1
        if (page < 0) {
          // do nothing but just refresh the page
          // $scope.checkErr();
        } else {
          $scope.currentPage = page
          $scope.checkErr()
        }
      }
    }

    $scope.setItemsPerPage($scope.viewby)

    $scope.formatDate = function (date) {
      var mydate = new Date(date)
      var monthNames = [
        'January', 'February', 'March',
        'April', 'May', 'June', 'July',
        'August', 'September', 'October',
        'November', 'December'
      ]

      var day = mydate.getDate()
      var monthIndex = mydate.getMonth()
      var year = mydate.getFullYear()

      return day + ' ' + monthNames[monthIndex] + ' ' + year
    }
  }])
