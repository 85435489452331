prismanoteApp.controller('adminProductController', ['$scope', '$parse', '$rootScope', '$stateParams', '$state', '$api', '$q', '$translate', 'Upload', '$diamond', '$productConfig',
  function ($scope, $parse, $rootScope, $stateParams, $state, $api, $q, $translate, Upload, $diamond, $productConfig) {
    $scope.canUpdate = { EN: false, DE: false, NL: false, FR: false, ES: false }
    var verifyMode = false
    var nameSlug
    $scope.showMergeSuccessAlert = false
    $scope.diamond = { quantity: '', gemKind: '', gemPurity: { code: '' }, gemColor: { code: '' }, gemCut: '', caratWeight: '', suggestedRetailPriceDiamond: '' }

    $scope.tabSelected = function (index) {
      $scope.activeTabSuggestedProduct = index
      if ($scope.activeTabSuggestedProduct == 6) {
        $scope.activeTabCurrentProduct = 0
      } else if ($scope.activeTabSuggestedProduct == 7) {
        $scope.activeTabCurrentProduct = 1
      } else if ($scope.activeTabSuggestedProduct == 8) {
        $scope.activeTabCurrentProduct = 2
      } else if ($scope.activeTabSuggestedProduct == 9) {
        $scope.activeTabCurrentProduct = 3
      } else if ($scope.activeTabSuggestedProduct == 10) {
        $scope.activeTabCurrentProduct = 4
      } else if ($scope.activeTabSuggestedProduct == 11) {
        $scope.activeTabCurrentProduct = 5
      }
    }
    $scope.editDiamond = false
    $scope.addDiamond = true
    $scope.gemKinds = $diamond.getGemKinds()
    $scope.gemPurities = $diamond.getGemPurities()
    $scope.gemColors = $diamond.getGemColors()
    $scope.gemCuts = $diamond.getGemCuts()

    $scope.openDiamond = function (index) {
      console.log('Index Of the diamond', index)
      $scope.diamond = $scope.product.diamonds[index]
      $scope.diamondIndex = index
      $scope.editDiamond = true
    }
    $scope.openSuggDiamond = function (index) {
      console.log('Index Of the diamond', index)
      $scope.suggDiamond = $scope.showProduct.diamonds[index]
      $scope.suggDiamondIndex = index
      $scope.editSuggDiamond = true
    }

    $scope.addNewDiamondForm = function () {
      $scope.diamond = { quantity: '', gemKind: '', gemPurity: { code: '' }, gemColor: { code: '' }, gemCut: '', caratWeight: '', suggestedRetailPriceDiamond: '' }
      if ($scope.product.diamonds) {
        // do nothing
      } else {
        $scope.product.diamonds = []
      }

      $scope.diamondIndex = $scope.product.diamonds.length
      $scope.product.diamonds[$scope.diamondIndex] = $scope.diamond
      $scope.addDiamond = true
    }

    $scope.pushDiamond = function () {
      console.log('New diamond push in diamonds')
      if ($scope.product.diamonds == undefined) {
        $scope.product.diamonds = []
      }

      $scope.product.diamonds.push($scope.diamond)
      console.log('my diamonds list', $scope.product.diamonds)
      $scope.diamond = {}
      console.log('$scope.product.diamonds', $scope.product.diamonds)
    }

    $scope.clearTheDiamond = function () {
      $scope.diamond = { quantity: '', gemKind: '', gemPurity: { code: '' }, gemColor: { code: '' }, gemCut: '', caratWeight: '', suggestedRetailPriceDiamond: '' }
    }

    $scope.cancelNewDiamond = function (diamondIndex) {
      $scope.product.diamonds.splice(diamondIndex, 1)
      console.log('Remove the last index at addinf time')
      console.log('diamonds', $scope.product.diamonds)
      $scope.addDiamond = false
      $scope.diamond = {}
    }

    $scope.deleteDiamond = function (index) {
      console.log('Index Of the diamond', index)
      $scope.product.diamonds.splice(index, 1)
      console.log('diamonds', $scope.product.diamonds)
    }

    $scope.assignValueForDiamond = function (prodIndex, suggIndex, key) {
      if (key != 'gemColor' && key != 'gemPurity') {
        $scope.product.diamonds[prodIndex][key] = $scope.showProduct.diamonds[suggIndex][key]
      } else $scope.product.diamonds[prodIndex][key].code = $scope.showProduct.diamonds[suggIndex][key].code
    }

    $scope.getClassForDiamons = function (prodIndex, suggIndex, key) {
      if (key != 'gemColor' && key != 'gemPurity') {
        var sug = $scope.showProduct.diamonds[suggIndex][key]
        var prod = $scope.product.diamonds[prodIndex][key]
      } else {
        var sug = $scope.showProduct.diamonds[suggIndex][key].code
        var prod = $scope.product.diamonds[prodIndex][key].code
      }
      var colorCode
      if ((sug == undefined || sug == '') && (prod == undefined || prod == '')) {
        colorCode = ' '
      } else if (sug == prod) {
        colorCode = ' '
      } else if ((sug == undefined || sug == '') && (prod != undefined && prod != '')) {
        colorCode = 'redColorClass'
      } else if ((prod == undefined || prod == '') && (sug != undefined && sug != '')) {
        colorCode = 'greenColorClass'
      } else if (sug != prod) {
        colorCode = 'orangeColorClass'
      }
      console.log('Color Code', colorCode)
      return colorCode
    }

    $scope.$watch('showProduct', function (item) {
      console.log('ShowProduct Changed = ', $scope.showProduct)
    })
    $scope.convertDate = function (date) {
      var d = new Date(date)
      return d.toLocaleString()
    }
    $scope.deleteProductFromPrismaNote = function () {
      $api.delete('product/delete-suggestion?productId=' + $scope.product._id)
        .then(function (success) {
          alert('Deletion of the product has been scheduled.')
        }, function (err) {
          alert('There seems to be some error. Please try again later.')
        })
    }

    $scope.convertToUpperAndSpace = function (str) {
      var oldStr = str
      if (str) {
        str = str.toUpperCase()
        str = str.replace(' ', '_')
        console.log(oldStr, 'is converted to  ', str)
      }
      return str
    }
    $scope.getValueChangedIfSuggestionClick = function (sugvalue, prodfeildname) {
      console.log('sugvalue', sugvalue)
      console.log('prodfeildname', prodfeildname)
      if (prodfeildname == 'product.strap.color') $scope.setStrapColorInitially(sugvalue)
      if (prodfeildname == 'product.watch.strap.color') $scope.setStrapColorInitially(sugvalue)
      if (prodfeildname == 'product.watch.case.color') $scope.setCaseColorInitially(sugvalue)
      if (prodfeildname == 'product.watch.dial.color') $scope.setDialColorInitially(sugvalue)
      if (prodfeildname == 'product.jewel.color') $scope.setJewelColorInitially(sugvalue)
      // Get the model
      var model = $parse(prodfeildname)
      console.log('old value', $scope[prodfeildname])
      // Assigns a value to it
      model.assign($scope, sugvalue)
      // console.log("new value", $scope[prodfeildname]);
    }

    $scope.setJewelColor = function (color) {
      console.log(color)
      if (!$scope.product.jewel) $scope.product.jewel = { }
      $scope.product.jewel.color = color.key
      console.log($scope.product.jewel)
    }
    $scope.getValueChangedIfSuggestionClickForIndex = function (sugvalue, prodfeildname, index) {
      var finalprodfeildname = prodfeildname.replace('$index', index)
      // console.log("finalprodfeildname", prodfeildname, index);
      // Get the model
      var model = $parse(finalprodfeildname)
      // console.log("old value", $scope[finalprodfeildname]);
      // Assigns a value to it
      model.assign($scope, sugvalue)
      // console.log("new value", $scope[finalprodfeildname]);
    }

    $scope.customfilter = function (obj) { // this is for testing and thus can remove it later after the issue is tested perfectly
      return typeof obj
    }

    $scope.getClassForSuggestion = function (sug, prod) {
      var colorCode
      if ((sug == undefined || sug == '') && (prod == undefined || prod == '')) {
        colorCode = ' '
      } else if (sug == prod) {
        colorCode = ' '
      } else if ((sug == undefined || sug == '') && (prod != undefined && prod != '')) {
        colorCode = 'redColorClass'
      } else if ((prod == undefined || prod == '') && (sug != undefined && sug != '')) {
        colorCode = 'greenColorClass'
      } else if (sug != prod) {
        colorCode = 'orangeColorClass'
      }
      return colorCode
    }

    $scope.getClassForSexAudienceSuggestion = function (suggestedProduct) {
      var sug = $scope.getSexAudience(suggestedProduct)
      var prod = $scope.targetAudience
      var colorCode
      if ((sug == undefined || sug == '') && (prod == undefined || prod == '')) {
        colorCode = ' '
      } else if (sug == prod) {
        colorCode = ' '
      } else if ((sug == undefined || sug == '') && (prod != undefined && prod != '')) {
        colorCode = 'redColorClass'
      } else if ((prod == undefined || prod == '') && (sug != undefined && sug != '')) {
        colorCode = 'greenColorClass'
      } else if (sug != prod) {
        colorCode = 'orangeColorClass'
      }
      return colorCode
    }

    $scope.getValueChangedIfSuggestedAudienceClick = function (suggestedProduct) {
      var sug = $scope.getSexAudience(suggestedProduct)
      $scope.targetAudience = sug
      $scope.setGender($scope.targetAudience)
    }

    $scope.showProductChange = function (obj, parentObj) {
      if (obj) {
        // console.log("Suggestion Change", obj);
        $scope.showProduct = obj
        $scope.showProduct.itemIsToMerge = parentObj.itemIsToMerge
        console.log('suggestion to merge', obj)
        // console.log("ShowProduct final value = ", $scope.showProduct);
      }
    }

    $scope.scheduleToMerge = function () {
      var primaryProduct = $scope.product._id
      var secondaryProduct = $scope.showProduct._id
      $api.get('products/merge-products/' + primaryProduct + '/' + secondaryProduct + '/')
        .then(function (success) {
          $scope.showMergeSuccessAlert = true
        }, function (err) {
          console.error(err)
        })
    }

    $scope.clearSelectionForMerging = function () {
      $scope.showProduct = ''
      $scope.showMergeSuccessAlert = false
    }

    $scope.getClassForImages = function () {
      var s_image_length = $scope.showProduct.images.length
      var p_image_length = $scope.product.images.length
      var colorCode
      if (s_image_length == 0) {
        colorCode = 'redColorClass'
        return colorCode
      }

      if (p_image_length == 0) {
        colorCode = 'greenColorClass'
        return colorCode
      }

      if (s_image_length != p_image_length) {
        colorCode = 'orangeColorClass'
        return colorCode
      }

      if (s_image_length == p_image_length) {
        if (s_image_length == 1) {
          if ($scope.showProduct.images[0].src == $scope.product.images[0].src) {
            return ' '
          } else {
            colorCode = 'orangeColorClass'
            return colorCode
          }
        } else {
          var same = []
          for (var i = 0; i < s_image_length; i++) {
            for (var j = 0; j < s_image_length; j++) {
              if ($scope.showProduct.images[i].src == $scope.showProduct.images[j].src) {
                if (same.indexOf(src) == -1) {
                  same.push(src)
                }
              }
            }
          }

          if (same.length == s_image_length) {
            return ' '
          } else {
            colorCode = 'orangeColorClass'
            return colorCode
          }
        }
      }
    }

    $scope.getTheValueChangedForImages = function () {
      $scope.product.images = $scope.showProduct.images
      $scope.image = $scope.product.images
    }

    $scope.strapColors = $productConfig.getStarpColors()
    $scope.dialColors = $productConfig.getDialColors()
    $scope.caseColors = $scope.jewelColors = Object.assign($scope.dialColors, $scope.strapColors)

    $scope.$watch('product', function () {
      // console.log("PRODUCT OBJECT CHANGED ", $scope.product);
    })
    // FOR COLLECTIONS
    $scope.searchCollections = function (str) {
      // console.log("SEARCH COLLECTION CALLED");
      return searchItems(str, 'collections')
    }

    $scope.getCollections = function () {
      // console.log("GETTING COLLECTIONS");
      return getItems('collections')
    }

    $scope.collectionSelected = function (selected) {
      // console.log("Selected", selected);
      // console.log("Language = ", $scope.language);
      $scope.addNewCollectionForm = false
      if (selected.title === ' ➕ Add New Collection' || selected.title === ' ➕ Kollektion hinzufügen' || selected.title === ' ➕ Voeg nieuwe collectie toe' || selected.title === ' ➕ Añadir colección' || selected.title === ' ➕ Ajouter une collection') {
        console.log('Selected', selected)

        $scope.addNewCollectionForm = true
        $('#collection-' + $rootScope.language + '-name').val($scope.searchStr)
      } else {
        var collection = selected.originalObject
        // console.log("To be pushed in collections array ", collection);

        if (!$scope.product.collections) {
          $scope.product.collections = []
        }
        $scope.product.collections.push(collection)
        // collection = ;
        // console.log("product", $scope.product.isVerified);
      }
    }

    // $scope.$watch('collectionSelected',function(){
    // 	console.log("COLLECTION SELECTED!!!" , $scope.collectionSelected);
    // 	if($scope.product.collections == undefined || $scope.product.collections.length==0)
    // 		$scope.product.collections = []

    // 	$scope.product.collections.push($scope.collectionSelected.title);
    // 	console.log("PROD COLLECTIONS = ", $scope.product.collections);
    // 	console.log("saveProduct", nameSlug, $scope.product, $stateParams.nameSlug);
    // })

    $scope.setProductCategory = function (category) {
      console.log('product handling guy Check The category', category)
      if (category == 'WATCH') {
        $scope.product.strap = $scope.product.jewel = {}
        $scope.product.watch = { case: {}, strap: {}, dial: {} }
      } else if (category == 'STRAP') {
        $scope.product.watch = $scope.product.jewel = {}
      } else if (category == 'JEWEL') {
        $scope.product.strap = $scope.product.watch = {}
      }
      $scope.product.category = category
    }

    $scope.setCaseColor = function (color) {
      console.log('color value', color)
      console.log('scope value', $scope.caseColor)
      $scope.product.watch.case.color = [color.key]
    }

    $scope.setCaseColorInitially = function (color) {
      console.log('SET CASE COLOR CALLED FOR COLOR INITIALLY: ', color)
      if (color == null || color == undefined || color == '') return

      if (color && Array.isArray(color) && color.length) color = color[0].toUpperCase()
      else color = color.length

      console.log('CASE COLOR SENT FOR INITIALLY SETTING : ', color)
      for (var i = 0; i < $scope.caseColors.length; i++) {
        if ($scope.caseColors[i].key == color) {
          $scope.caseColor = $scope.caseColors[i]
          console.log('MATCH FOUND and the scope value is!', $scope.caseColor)
          $scope.setCaseColor(color)
          // $scope.product.watch.dial.color = color.key;
          break
        } else {
          console.log('CASE COLOR')
          console.log($scope.caseColors[i].key + '!=' + color)
        }
      }
      if (!$scope.caseColor) console.log('NO CASECOLOR MATCHED TO SET INITIALLY')
    }
    $scope.setDialColor = function (color) {
      console.log('SET DIAL COLOR CALLED FOR COLOR : ', color)
      $scope.product.watch.dial.color = [color.key]
    }

    $scope.setDialColorInitially = function (color) {
      console.log('SET DIAL COLOR CALLED FOR COLOR INITIALLY: ', color)

      if (color == null || color == undefined || color == '') return

      if (color && Array.isArray(color) && color.length) color = color[0].toUpperCase()
      else color = color.toUpperCase()

      console.log('COLOR SENT FOR INITIALLY SETTING : ', color)
      for (var i = 0; i < $scope.dialColors.length; i++) {
        console.log($scope.dialColors[i], color)
        if ($scope.dialColors[i].key == color) {
          $scope.dialColor = $scope.dialColors[i]
          console.log('MATCH FOUND !', $scope.dialColor)
          $scope.setDialColor(color)
          // $scope.product.watch.dial.color = color.key;
          break
        } else {
          console.log($scope.dialColors[i].key + '!=' + color)
        }
      }
      if (!$scope.dialColor) console.log('NO COLOR MATCHED TO SET INITIALLY')
    }

    $scope.setStrapColor = function (color) {
      console.log('Color', color)
      if ($scope.product.category == 'WATCH') {
        $scope.product.watch.strap.color = [color.key]
      } else if ($scope.product.category == 'STRAP') {
        $scope.product.strap.color = [color.key]
      }
    }

    $scope.setJewelColorInitially = function (color) {
      console.log(color)
      if (color == null || color == undefined || color == '') return

      console.log(color.length)
      if (color && Array.isArray(color) && color.length) color = color[0].toUpperCase()
      else color = color.toUpperCase()

      console.log(color)
      for (var i = 0; i < $scope.jewelColors.length; i++) {
        console.log($scope.jewelColors[i])
        if ($scope.jewelColors[i].key == color) {
          $scope.jewelColor = $scope.jewelColors[i]
          break
        }
      }
    }
    $scope.setStrapColorInitially = function (color) {
      console.log('SET STRAP COLOR CALLED FOR COLOR INITIALLY: ', color)
      if (color == null || color == undefined || color == '') return

      if (color && Array.isArray(color) && color.length) color = color[0].toUpperCase()
      else color = color.toUpperCase()

      console.log('strap COLOR SENT FOR INITIALLY SETTING : ', color)
      for (var i = 0; i < $scope.strapColors.length; i++) {
        if ($scope.strapColors[i].key == color) {
          $scope.strapColor = $scope.strapColors[i]
          console.log('MATCH FOUND !', $scope.strapColor)
          $scope.setStrapColor(color)
          // $scope.product.watch.dial.color = color.key;
          break
        } else {
          console.log('Strap COLOR')
          console.log($scope.strapColors[i].key + '!=' + color)
        }
      }
      if (!$scope.caseColor) console.log('NO CASECOLOR MATCHED TO SET INITIALLY')
    }

    $scope.caseColors = $scope.jewelColors = Object.assign($scope.dialColors, $scope.strapColors)

    var getItems = function (type) {
      $api.get(type)

        .then(function (response) {
          $scope[type] = response.data[type]
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.getBrands = function () {
      return getItems('brands')
    }

    var searchItems = function (str, type) {
      console.log('Search', str, type)
      var matches = []
      var addNewCollectionItem = {
        en: {
          name: ' ➕ Add New Collection'
        },
        nl: {
          name: ' ➕ Voeg nieuwe collectie toe'
        },
        es: {
          name: ' ➕ Añadir colección'
        },
        fr: {
          name: ' ➕ Ajouter une collection'
        },
        de: {
          name: ' ➕ Kollektion hinzufügen'
        }
      }

      matches.push(addNewCollectionItem)

      $scope[type].forEach(function (item) {
        if (type != 'brands') {
          // 	if(
          // 		(item[$rootScope.language] != undefined)
          // 		&&
          // 		((item[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)
          // 			||
          // 			(item[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0 )
          // 			)
          // 		) {
          // 		matches.push(item);
          // }
          if (
            (item[$rootScope.language] != undefined && item[$rootScope.language].name != undefined) &&
						(
						  (item[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)							||
							(item[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)
						)
          ) {
            matches.push(item)
          }
        } else {
          if (
            (
              item[$rootScope.language] &&
						item[$rootScope.language].name &&
						item[$rootScope.language].nameSlug
            ) &&
					(
					  (
					    item[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0
					  ) ||
						(
						  item[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0
						)
					)
          ) {
            matches.push(item)
          }
        }
      })

      return matches
    }

    $scope.searchBrands = function (str) {
      return searchItems(str, 'brands')
    }

    $scope.brandSelected = function (selected) {
      var brand = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug,
        description: selected.originalObject.description,
        images: selected.originalObject.images
      }

      $scope.product.brand = brand
    }

    $scope.addProductVariant = function () {
      /* TODO: remove collapse property on save */
      $scope.product.variants.push({ collapse: true })
    }

    $scope.removeProductVariant = function (index) {
      $scope.product.variants.splice(index, 1)
    }
    $scope.customGetTargetAudience = function () {
      // console.log("GETTING TARGET AUDIENCE from scope to native.");
      getTargetAudience()
    }
    var getTargetAudience = function () {
      // console.log("SETTING TARGET AUDIENCE");
      if ($scope.product.male) {
        if ($scope.product.female) {
          if ($scope.product.kids) {
            $scope.targetAudience = 'KIDS'
          } else {
            $scope.targetAudience = 'UNISEX'
          }
        } else {
          if ($scope.product.kids) {
            $scope.targetAudience = 'BOYS'
          } else {
            $scope.targetAudience = 'GENTS'
          }
        }
      } else {
        if ($scope.product.female) {
          if ($scope.product.kids) {
            $scope.targetAudience = 'GIRLS'
          } else {
            $scope.targetAudience = 'LADIES'
          }
        }
      }
    }

    $scope.getSexAudience = function (prod) {
      var targetAudience = ''
      if (prod.male) {
        if (prod.female) {
          if (prod.kids) {
            targetAudience = 'KIDS'
          } else {
            targetAudience = 'UNISEX'
          }
        } else {
          if (prod.kids) {
            targetAudience = 'BOYS'
          } else {
            targetAudience = 'GENTS'
          }
        }
      } else {
        if (prod.female) {
          if (prod.kids) {
            targetAudience = 'GIRLS'
          } else {
            targetAudience = 'LADIES'
          }
        }
      }
      return targetAudience
    }

    $scope.setGender = function (targetAudience) {
      switch (targetAudience) {
        case 'GENTS':
          $scope.product.male = true
          $scope.product.female = false
          $scope.product.kids = false
          break
        case 'LADIES':
          $scope.product.male = false
          $scope.product.female = true
          $scope.product.kids = false
          break
        case 'UNISEX':
          $scope.product.male = true
          $scope.product.female = true
          $scope.product.kids = false
          break
        case 'BOYS':
          $scope.product.male = true
          $scope.product.female = false
          $scope.product.kids = true
          break
        case 'GIRLS':
          $scope.product.male = false
          $scope.product.female = true
          $scope.product.kids = true
          break
        case 'KIDS':
          $scope.product.male = true
          $scope.product.female = true
          $scope.product.kids = true
          console.log('KId man', $scope.product.male)
          console.log('female', $scope.product.female)
          console.log('kid', $scope.product.kids)
          break
      }
    }

    $scope.addProductImage = function () {
      $scope.images.push({})
    }

    $scope.removeProductImage = function (mode, index) {
      if (mode == 'scope') {
        $scope.images.splice(index, 1)
      } else {
        $scope.product.images.splice(index, 1)
      }
    }

    $scope.saveProduct = function () {
      console.log('$scope.product', $scope.product)
      console.log('$scope.product.photos', $scope.product.photos)
      var nameSlug = $stateParams.nameSlug

      // return;
      if (nameSlug == null) {
        var product = $scope.product

        for (var i = 0; i < product.variants.length; i++) {
          delete product.variants[i].collapse
        }

        if (product.category == 'WATCH') {
          delete product.strap
          delete product.jewel
        }

        if (product.category == 'STRAP') {
          delete product.watch
          delete product.jewel
        }

        if (product.category == 'JEWEL') {
          delete product.strap
          delete product.watch
        }

        product.isVerified = true

        product.verifyProduct = verifyMode || false

        $api.post('products', product)
          .then(function (response) {
            console.log('RESPONSE: ', response.data.product)
            nameSlug = response.product.nameSlug || response.product.en.nameSlug
            var productId = product._id
            $scope.showSuccess = true
            $scope.successMessage = 'Product added successfully'

            // Image Upload Start
            console.log('IMAGES LENGTH: ', $scope.images.length)
            if ($scope.images.length > 0) {
              console.log('---------------------1------------------------')
              $scope.upload = true
              Upload.upload({
                url: 'api/product-image-upload',
                data: {
                  productId: productId,
                  files: $scope.images // file input field
                }
              })
                .then(function (res) {
                  $scope.alert = {
                    type: 'success',
                    msg: 'Image Uploaded successtully'
                  }
                }, function (res) {
                  console.error('Upload error: ', res.status)
                  $scope.alert = {
                    type: 'danger',
                    msg: 'upload error: ' + res.status
                  }
                }, function (evt) {
                  var progressPercentage = parseInt(100.0 * evt.loaded / evt.total)
                  $scope.uploadProgress = progressPercentage
                })

              $scope.upload = false
            }

            // Image Upload End
          })

          .catch(function (reason) {
            $scope.showAddError = true
            $scope.addErrorMessage = reason.data.message
            console.log(reason.data.message)
            console.error(reason)
          })
      } else {
        /* Don't create a new product twice, but update the product instead. */
        var product = $scope.product

        // product.collections = $scope.collectionSelected.title;
        // console.log("PRODUCT TO BE ADDED  = ", product);
        // console.log("Collections = ",$scope.collectionSelected.title);
        // console.log("In $scope.Product =",$scope.product.collections);
        for (var i = 0; i < product.variants.length; i++) {
          delete product.variants[i].collapse
        }

        if (product.category == 'WATCH') {
          delete product.strap
          delete product.jewel
        }

        if (product.category == 'STRAP') {
          delete product.watch
          delete product.jewel
        }

        if (product.category == 'JEWEL') {
          delete product.strap
          delete product.watch
        }

        product.isVerified = true

        if (verifyMode == true) {
          if (product.category == 'WATCH') {
            console.log('Hey i am inside the watchj category')
            if (product.watch && product.watch.case && product.watch.case.color !== undefined) {
              if (product.watch.case.color[0] == undefined) {
                if ($scope.caseColor) {
                  product.watch.case.color = [$scope.caseColor.key]
                }
              }
            }
            if (product.watch && product.watch.strap && product.watch.strap.color !== undefined) {
              if (product.watch.strap.color[0] == undefined) {
                if ($scope.strapColor) {
                  product.watch.strap.color = [$scope.strapColor.key]
                }
              }
            }
            if (product.watch && product.watch.dial && product.watch.dial.color !== undefined) {
              if (product.watch.dial.color[0] == undefined) {
                product.watch.dial.color = [$scope.dialColor.key]
              }
            }
          }

          $api.put('products/' + nameSlug, product)

            .then(function (response) {
              console.log('res', response)
              console.log('Product updated.')
              $scope.showSuccess = true
              $scope.successMessage = 'Product updated successfully'

              var productId = product._id

              // Image Upload Start
              console.log('IMAGS LENGTH: ', $scope.image.length)
              if ($scope.images.length > 0) {
                console.log('---------------------2------------------------')
                $scope.upload = true
                Upload.upload({
                  url: 'api/product-image-upload',
                  data: {
                    productId: productId,
                    files: $scope.images // file input field
                  }
                })
                  .then(function (res) {
                    console.log('------------SUCCESS: IMAGES-----')
                    $scope.alert = {
                      type: 'success',
                      msg: 'Image Uploaded successtully'
                    }
                  }, function (res) {
                    console.error('Upload error: ', res.status)
                    $scope.alert = {
                      type: 'danger',
                      msg: 'upload error: ' + res.status
                    }
                  }, function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total)
                    $scope.uploadProgress = progressPercentage
                  })

                $scope.upload = false
              }

              // Image Upload End

              // $state.go('admin.products');
            })

            .catch(function (reason) {
              $scope.showAddError = true
              $scope.addErrorMessage = reason.data.message
              console.log(reason.data.message)
              console.error(reason)
            })
        } else {
          $api.put('products/' + nameSlug, product)

            .then(function (response) {
              console.log('res', response)
              console.log('Product updated.')
              $scope.showSuccess = true
              $scope.successMessage = 'Product updated successfully'
              var productId = product._id

              // Image Upload Start
              if ($scope.images.length > 0) {
                console.log('---------------------3------------------------')
                $scope.upload = true
                Upload.upload({
                  url: 'api/product-image-upload',
                  data: {
                    productId: productId,
                    files: $scope.images // file input field
                  }
                })
                  .then(function (res) {
                    $scope.alert = {
                      type: 'success',
                      msg: 'Image Uploaded successtully'
                    }
                  }, function (res) {
                    console.error('Upload error: ', res.status)
                    $scope.alert = {
                      type: 'danger',
                      msg: 'upload error: ' + res.status
                    }
                  }, function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total)
                    $scope.uploadProgress = progressPercentage
                  })

                $scope.upload = false
              }

              // Image Upload End
            })

            .catch(function (reason) {
              $scope.showAddError = true
              $scope.addErrorMessage = reason.data.message
              console.log(reason.data.message)
              console.error(reason)
            })
        }
      }
    }

    if ($stateParams.nameSlug) {
      $scope.images = []
      $scope.currentMode = 'edit'
      $api.get('products/' + $stateParams.nameSlug)
        .then(function (response) {
          verifyMode = true
          $scope.product = response.data.product
          console.log('product details Collections Check', response.data.product.collections)
          if ($scope.product.isVerified == true) $scope.adminMustCheck = false
          if ($scope.product.isVerified == false) $scope.adminMustCheck = true

          getTargetAudience()

          // console.log("SUGGESTIONS : ",$scope.product.suggestions[0]);
          $scope.productCategory = $scope.product.category
          $scope.image = $scope.product.images

          if (!$scope.product.nl) {
            $scope.product.nl = { views: 0 }
          }
          if (!$scope.product.en) {
            $scope.product.en = { views: 0 }
          }
          if (!$scope.product.de) {
            $scope.product.de = { views: 0 }
          }
          if (!$scope.product.fr) {
            $scope.product.fr = { views: 0 }
          }
          if (!$scope.product.es) {
            $scope.product.es = { views: 0 }
          }

          if ($scope.product && $scope.product.watch) {
            if ($scope.product.watch.dial != undefined) {
              $scope.setDialColorInitially($scope.product.watch.dial.color)
            }

            if ($scope.product.watch.case != undefined) {
              $scope.setCaseColorInitially($scope.product.watch.case.color)
            }

            if ($scope.product.watch.strap != undefined) {
              $scope.setStrapColorInitially($scope.product.watch.strap.color)
            }

            if ($scope.product.watch.case) {
              $scope.product.watch.case.material = ($scope.product.watch.case.material) ? $scope.convertToUpperAndSpace($scope.product.watch.case.material) : ''
              $scope.product.watch.case.glassMaterial = $scope.convertToUpperAndSpace($scope.product.watch.case.glassMaterial)
              $scope.product.watch.case.shape = $scope.convertToUpperAndSpace($scope.product.watch.case.shape)
            }

            if ($scope.product.watch.strap) {
              $scope.product.watch.strap.model = $scope.convertToUpperAndSpace($scope.product.watch.strap.model)
              $scope.product.watch.strap.material = $scope.convertToUpperAndSpace($scope.product.watch.strap.material)
            }
          }

          if ($scope.product && $scope.product.jewel) {
            if ($scope.product.jewel.color) {
              $scope.setJewelColorInitially($scope.product.jewel.color)
            }
          }

          if ($scope.product && $scope.product.strap) {
            $scope.setStrapColorInitially($scope.product.strap.color)
          }
        })
        .catch(function (reason) {
          console.log(reason)
        })
    } else {
      $scope.images = []
      $scope.currentMode = 'add'
      $scope.targetAudience = 'MALE'
      $scope.product = {
        variants: [{ collapse: false }],
        brand: {},
        category: 'WATCH',
        views: 0,
        nl: { views: 0 },
        en: { views: 0 },
        de: { views: 0 },
        fr: { views: 0 },
        es: { views: 0 },
        strap: {},
        jewel: {},
        watch: {
          case: {},
          strap: {},
          dial: {}
        },
        weight: '',
        totalCaratWeight: ''
      }
    }

    // console.log("CURRENT MODE = ", $scope.currentMode);
    $scope.getVersionValues = function (property) {
      if (!$scope.product || !$scope.product.versions || $scope.product.versions.length == 0) return
      var propertyValues = []
      for (var i = 0; i < $scope.product.versions.length; i++) {
        var value = _.get($scope.product.versions[i].modifiedProperties[0], property)
        if (value) {
          propertyValues.push(value)
        }
      }
      return propertyValues
    }

    $scope.deleteProduct = function () {
      // console.log($scope.product);
      $api.delete('products', { productId: $scope.product._id })
        .then(function (response) {
          $state.go('admin.products')
        })
        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.addCollection = function (collection) {
      // console.log("Adding Collection : ", collection);
      $api.post('collections', collection).then(function (success) {
        console.log('Collection added successtully ', success.data)
        $scope.collections.push(success.data.collection)
        var collToBeSelected = {
          originalObject: success.data.collection,
          title: success.data.collection[$rootScope.language].name
        }
        $scope.collectionSelected(collToBeSelected)
        $scope.collection = {}
      }, function (err) {
        console.log('Error adding collection : ', err)
      })
    }

    $scope.deleteCollection = function (index) {
      $scope.product.collections.splice(index, 1)
      $api.put('products/' + $scope.product[$rootScope.language].nameSlug, $scope.product)
        .then(function (success) {
          console.log('Product has been updated with new collection.')
        }, function (err) {
          console.error(err)
        })
    }

    $scope.toggleAdminMustCheckSwitches = function (adminMustCheck) {
      if (adminMustCheck == true) {
        $scope.isVerified = false
        $scope.adminMustCheck = true
      } else {
        $scope.isVerified = true
        $scope.adminMustCheck = false
      }
    }

    $scope.toggleIsVerifiedSwitches = function (isVerified) {
      if (isVerified == true) {
        $scope.product.isVerified = true
        $scope.adminMustCheck = false
      } else {
        $scope.adminMustCheck = true
        $scope.product.isVerified = false
      }
    }

    $scope.$watch('product', function (newVal, oldVal) {
          console.log('------------------ productIsValidForUpdate!!');
        	$productConfig.productIsValidForUpdate($scope.product, $scope.canUpdate)
        	.then(function (canUpdate) {
        		$scope.canUpdate = canUpdate
  		      	console.log('canUpdate', $scope.canUpdate)
        	})
        	.catch(function (error) {
        		$scope.canUpdate = { EN: false, DE: false, NL: false, FR: false, ES: false }
        		console.error('Error: Please Contact PrismaNote Admin')
        	})
    	}, true)
  }])
