prismanoteApp.controller(
    'shopBrandController',
    ['$scope', '$rootScope', '$api', '$state', '$shop', '$brand', '$company', 'cookieService', '$uibModal', 
        function ($scope, $rootScope, $api, $state, $shop, $brand, $company, cookieService, $uibModal) {
            $scope.loading = false;
            $scope.shops = [];
            $scope.allShops = [];
            $scope.originalData = [];
            $scope.pageSize = '10'
            $scope.currentPage = 1
            $scope.shopCount = 0
            $scope.originalShopsData = [];
            $scope.originalShopsCount = 0
            $scope.searchquery = '';

            app.directive('ngEnter', function () { //a directive to 'enter key press' in elements with the "ng-enter" attribute
            return function (scope, element, attrs) {  
              element.bind("keydown keypress", function (event) {
                if (event.which === 13) {
                  scope.$apply(function () {
                    scope.$eval(attrs.ngEnter);
                  });
      
                  event.preventDefault();
                }
              });
            };
          })

          $scope.searchingData = function() {
              console.log('search called');
              
              $scope.searching($scope.searchquery)
          }
            $scope.fetchCompanies = function () {
                if ($rootScope.currentCompany && $rootScope.currentCompany._id) {
                    console.log("fetchCompanies called", $rootScope.currentCompany._id);
                    // Now we need to fetch all the shops which contains the this loggedin id
                    getAllShopOfCompany();
                } else {
                    console.log('no company selected')
                    $scope.loading = true;
                    $company.getCompany()
                        .then(function (company) {
                            $scope.loading = false;
                            $scope.currentCompany = company;
                            $rootScope.currentCompany = company;
                            getAllShopOfCompany();
                        })
                        .catch(function (reason) {
                            $scope.loading = false;
                            console.log('Internal Server Error while fetching shop detail');
                        });
                }
            }

            $scope.saveCurrentPage = function (currentPage) {
                if($scope.searchquery) {
                    $scope.searching($scope.searchquery)
                } else {
                    getAllShopOfCompany()
                }
                
                cookieService.setCookie('transactionsCurrentPage=' + currentPage, 1800)
              }

              $scope.savePageSize = function (pageSize) {
                if($scope.searchquery) {
                    $scope.searching($scope.searchquery)
                } else {
                    getAllShopOfCompany()
                }
                cookieService.setCookie('transactionsPageSize=' + pageSize, 31536000)
              }

            function getAllShopOfCompany() {
                $scope.loading = true;
                $api.get('shops/comapnies-data/' + $rootScope.currentCompany._id + '?limit=' + $scope.pageSize + '&pageNumber=' + $scope.currentPage)
                    .then(function (response) {
                        $scope.loading = false;
                        console.log('response: ', response.data.data.data);
                        var ResponseData = response.data.data && response.data.data.data ? response.data.data.data : [];
                        $scope.originalData = ResponseData;
                        $scope.originalShopsData = ResponseData;
                        $scope.originalShopsCount = response.data.data.count;
                        console.log('FINAL DATA: ====> ', ResponseData);
                        $scope.shops = ResponseData;
                        $scope.shopCount = response.data.data.count
                    })
                    .catch(function (reason) {
                        $scope.loading = false;
                        console.log(reason);
                    });
            }

            $scope.searchVal = function(val) {  
                if (val.length == 0) { 
                    $scope.shops = $scope.originalShopsData ;
                    $scope.shopCount = $scope.originalShopsCount;
                  }
            }

            $scope.myFunct = function (keyEvent) {
                console.log('keyEvent',keyEvent.target.value)
                if (keyEvent.which === 13) {
                    $scope.searchquery = keyEvent.target.value;
                    console.log('$scope.searchquery',$scope.searchquery);
                    $scope.searching($scope.searchquery)
                    
                }
            
            }

            $scope.searching = function (value) {
                console.log('search initiated');
                $scope.hits = []
                var searchedData = []
                if (value) {
                 $scope.loading = true;
                  $api.post('shops/search', {
                    search: $scope.searchquery,
                    companyId: $rootScope.currentCompany._id,
                    limit: $scope.pageSize,
                    pageNumber: $scope.currentPage
                  })
                    .then(function (response) {
                        $scope.loading = false;
                        console.log('search response respon',response);
                        
                        var ResponseData = response.data && response.data.data ? response.data.data : [];
                        $scope.originalData = ResponseData;
                        console.log('FINAL DATA: ====> ', ResponseData);
                        $scope.shops = ResponseData;
                        $scope.shopCount = response.data.count
                    })
                    .catch(function (reason) {
                        $scope.loading = false;
                      $log.error('Error while searching customers', reason)
                    })
                }
              }

        /*     $scope.searchData = function (searchedValue) {
                if (!searchedValue || !$scope.shops.length) $scope.shops = $scope.originalData;

                $scope.shops = [];
                // console.log('serachdata called: ', searchedValue);
                $scope.originalData.forEach(function (item) {
                    // console.log('item: ', item);
                    if ((item && item.shopDetail && item.shopDetail.name && item.shopDetail.name.toLowerCase().indexOf(searchedValue.toString().toLowerCase())) >= 0 || searchedInBrandArray(item.shopDetail && item.shopDetail.brands ? item.shopDetail.brands : [], searchedValue)) {
                        $scope.shops.push(item);
                    }
                });
            } */

            $scope.openShopDetail = function (nameSlug) {
                $state.go('brand.shop-detail', { 'nameSlug': nameSlug.nameSlug });
            }

            // Searching in all shop.brands
            function searchedInBrandArray(brands, searchedValue) {
                for (var i = 0; i < brands.length; i++) {
                    if (brands[i].name && brands[i].name.toLowerCase().indexOf(searchedValue.toString().toLowerCase()) >= 0) {
                        return true;
                    }

                    if (brands.length == (i + 1)) {
                        return false;
                    }
                }
            }

            // Searching shops to add in the database, but its exact match on email
            $scope.searchShops = function (str) {
                $scope.retailerEmail = str;
                var matches = [];
                $scope.allShops.forEach(function (item) {
                    if (item && (
                        (item.email && item.email.toString().toLowerCase() === str.toString().toLowerCase())
                    )) {
                        matches.push(item);
                    }
                });
                return matches;
            }

            // Select the shop add to the company.shops array
            $scope.selectTheShop = function (shop) {
                console.log('shop: ', shop);
                $scope.retailerEmail = '';
                if (shop && shop.originalObject && shop.originalObject.email) {
                    console.log('selectTheShop: ', shop.originalObject.email);
                    var body = {
                        companyId: $rootScope.currentCompany._id,
                        shop: {
                            _id: shop.originalObject._id,
                            email: shop.originalObject.email,
                        }
                    }
                    $scope.loading = true;
                    $brand.addShopInCompany(body).then(function (response) {
                        console.log('response: ', response);
                        $scope.loading = false;
                    }).catch(function (error) {
                        console.log('error: ', error);
                        $scope.loading = false;
                    });
                }
            }

            // In case, if shop not found then we can send invitation if needed(Like Campaign, not in scope for now)
            $scope.sendEmail = function () {
                $scope.alert = null;
                // console.log('retailerEmail email: ', $scope.retailerEmail);
                if ($scope.retailerEmail) {
                    $campaign.validateEmail($scope.retailerEmail).then(function (isValidEmail) {
                        console.log('isvallid email: ', $scope.retailerEmail, isValidEmail);
                        if (!isValidEmail) {
                            $scope.alert = {
                                type: 'danger',
                                msg: 'Invalid email address'
                            }
                        } else {
                            // If have to send email then do code here
                        }
                    })
                } else {
                    $scope.alert = {
                        type: 'danger',
                        msg: 'Enter email address'
                    }
                }
            }

            // Remove shop from company
            $scope.removeShopFromTheCompany = function (index) {
                try {
                    console.log('$Index: ', index, $scope.shops[index]);
                    $scope.shops[index].isDisabled = true;

                    var body = {
                        shopId: $scope.shops[index].shopDetail._id,
                        companyId: $rootScope.currentCompany._id
                    }
                    $scope.loading = true;
                    $brand.removeShopFromTheCompany(body).then(function (response) {
                        console.log('response: ', response);
                        $scope.shops.splice(index, 1);
                        $scope.loading = false;
                    }).catch(function (error) {
                        $scope.shops[index].isDisabled = false;
                        console.log('error: ', error);
                        $scope.loading = false;
                    })
                } catch (catcherr) {
                    console.log('catcherrr: ', catcherr);
                }
            }

            $scope.openAddShopModal = function () {
                try {
                    console.log('openAddShopModal called: ');
                    var modalInstance = $uibModal.open({
                        templateUrl: '../views/modal/company-add-shop-modal.html',
                        controller: 'companyAddShopModalController',
                        size: 'lg'
                    });

                    modalInstance.result.then(function (result) {
                        console.log('result**************************', result);
                        if (result.isShop) {
                            console.log('Add shop', result.shop);
                            var body = {
                                companyId: $rootScope.currentCompany._id,
                                shop: {
                                    _id: result.shop._id,
                                    email: result.shop.email,
                                }
                            }
                            $scope.loading = true;
                            $brand.addShopInCompany(body).then(function (response) {
                                console.log('response: ', response);
                                // $scope.shops.push(result.shop);
                                getAllShopOfCompany();
                                $scope.loading = false;
                            }).catch(function (error) {
                                console.log('error: ', error);
                                $scope.loading = false;
                            });
                        } else {
                            console.log('No need to add shop');
                        }
                    }, function (error) {
                        console.log('modal open error: ', error);
                    })
                } catch (error) {
                    console.log('error: ', error);
                }
            }

        }
    ]
)