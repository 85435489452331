prismanoteApp.controller('brandAddShopController', ['$scope', '$rootScope', '$brand', '$state', 'prompt', '$language',
  function ($scope, $rootScope, $brand, $state, prompt, $language) {
    // console.log('brandAddShopController called')
    // console.log('brandAddShopController name: ', $rootScope.shopNameFromAddShopModal)
    $scope.shop = {}
    $scope.shop.private = false
    $scope.shop.type = 'physicalstore'
    $scope.urlPattern = /^(https?:\/\/)?(www\.)?[a-z0-9-]*\.?[a-z0-9-]+\.[a-z0-9-]+(\/[^<>]*)?$/
    if ($rootScope.shopNameFromAddShopModal) {
      $scope.shop.name = $rootScope.shopNameFromAddShopModal
      $rootScope.shopNameFromAddShopModal = undefined
    }

    $scope.setAddress = function () {
      if ($rootScope.currentCompany.address && $rootScope.currentCompany.address.country) {
        $scope.shop.address = {
          country: $rootScope.currentCompany.address.country
        }
      }
    }

    $scope.getBrands = function () {
      $brand.getAllBrands().then(function (response) {
        $scope.brands = response.brands
      }).catch(function (error) {
        console.error('Error while getting brands', reason)
      })
    }

    $scope.searchBrands = function (str) {
      var matches = []
      $scope.brands.forEach(function (brand) {
        if ((brand.en.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
          (brand.en.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
          matches.push(brand)
        }
      })
      return matches
    }

    // $scope.addBrands = function (data) {
    //     var modalInstance = $uibModal.open({
    //         templateUrl: '../views/modal/add-brands-modal.html',
    //         controller: 'addBrandsModalController',
    //         size: 'lg',
    //         resolve: {
    //             data: function () {
    //                 return data;
    //             }
    //         }
    //     });

    //     modalInstance.result.then(function (result) {

    //     }, function (my_return_string) {
    //         console.log("my string", my_return_string);
    //         //dismissed
    //     })
    // };

    $scope.brandSelected = function (selected) {
      var selectedBrand = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug,
        description: selected.originalObject.description,
        images: selected.originalObject.images,
        restricted: selected.originalObject.restricted
      }

      // Checking is alredy added or not
      if (!$scope.shop.brands) $scope.shop.brands = []
      var isBrandAlreadyExistInShop = false
      angular.forEach($scope.shop.brands, function (shopBrand) {
        if (shopBrand._id == selectedBrand._id) {
          isBrandAlreadyExistInShop = true
        };
      })

      if (isBrandAlreadyExistInShop) return

      $scope.shop.brands.push(selectedBrand)
      console.log('$scope.shop.brands: ', $scope.shop.brands)
    }

    $scope.deleteBrand = function (index) {
      prompt({
        title: 'Merk verwijderen?',
        message: 'Weet u zeker dat u dit merk van deze winkel wilt verwijderen?'
      }).then(function () {
        $scope.shop.brands.splice(index, 1)
      })
    }

    $scope.closeAlert = function () {
      $scope.alert = undefined
    }

    // Adding shop into the company
    $scope.createNewShop = function (shop) {
      $scope.alert = undefined
      console.log('createNew SHop called: ', shop) 
      prompt({
        title: $language.translate('ADD_SHOP'),
        message: $language.translate('ARE_YOU_SURE_YOU_ADD_THIS_SHOP') + ' ' + $rootScope.currentCompany.name + '?',
        buttons: [
          { label: $language.translate('YES'), primary: true, id: 0 },
          { label: $language.translate('NO'), primary: false, id: 1 }
        ]
      }).then(function (value) {
        console.log('YES OR NO VALUE: ', value)
        var haveToAddShopIntoCompany = false
        if (value.id === 0) haveToAddShopIntoCompany = true
        shop.haveToAddShopIntoCompany = haveToAddShopIntoCompany
        shop.companyId = $rootScope.currentCompany._id
        $brand.createShop(shop).then(function (response) {
          $state.go('brand.shops')
        }).catch(function (error) {
          console.log('error: ', error)
          if (error.status === 400 && error.data.isAlreayExist && error.data.data) {
            $scope.isAlreayExist = true
            $scope.existingShop = error.data.data
            $scope.alert = {
              type: 'danger',
              msg: error.data.message
            }
          } else {
            $scope.alert = {
              type: 'danger',
              msg: error.data.message
            }
          }
        })
      }).catch(function (modalerr) {
        console.log('modalerr: ', modalerr)
      })
    }

    $scope.addShopToCompany = function (existingShop) {
      var body = {
        companyId: $rootScope.currentCompany._id,
        shop: {
          _id: existingShop._id,
          email: existingShop.email
        }
      }
      $scope.loading = true
      $brand.addShopInCompany(body).then(function (response) {
        // console.log('response: ', response);
        $scope.loading = false
        $state.go('brand.shops')
      }).catch(function (error) {
        console.log('error: ', error)
        $scope.loading = false
      })
    }
  }])
