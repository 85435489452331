prismanoteApp.controller('sendOrder-modalController', ['$scope', '$uibModalInstance', 'order', '$api',
  function ($scope, $uibModalInstance, order, $api) {
    $scope.order = order
    $scope.send = {}

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    $scope.sendOrder = function () {
      $api.post('send-order', { orderId: order._id })
        .then(function (response) {
          $uibModalInstance.close(true)
        })
        .catch(function (reason) {
          console.error(reason)
          $scope.alert = {
            type: 'danger',
            msg: reason
          }
        })
    }
  }
])
