prismanoteApp.controller('textAngularTextUploadModalController', ['$scope', '$rootScope', '$uibModalInstance', 'Upload',
  function ($scope, $rootScope, $uibModalInstance, Upload) {
    $scope.files = []

    $scope.upload = function () {
      $scope.progress = 0
      $scope.uploadInProgress = true

      Upload.upload({
        url: 'api/upload-news-content-image',
        data: {
          file: $scope.files
        }
      }).progress(function (evt) {
        $scope.progress = parseInt(100.0 * evt.loaded / evt.total)
      }).success(function (data) {
        $scope.progress = 0
        $scope.image = $rootScope.awsUrl + data.file
        $scope.uploadInProgress = false
      })
    }

    $scope.insert = function () {
      $uibModalInstance.close($scope.image)
    }
  }])
