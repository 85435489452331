prismanoteApp.controller('goldStockCorrectionModalController', ['$scope', '$uibModalInstance', '$rootScope', '$goldStockService',
  function ($scope, $uibModalInstance, $rootScope, $goldStockService) {
    try {
      $scope.existingGoldStock = 0
      $scope.existingGoldStockValue = 0
      $scope.goldStockHistory = []

      $scope.averageGoldStockValuePerGram = 0
      $scope.suggestedAveragePrice = 0

      $scope.stockCorrection = {
        goldStock: 0,
        goldStockValue: 0,
        correctionMode: 'decrease'
      }
      $scope.alert = {
        type: '',
        msg: ''
      }

      $scope.confirm = function () {
        $uibModalInstance.close(true)
      }

      $scope.fetchGoldStockAndValue = function () {
        $goldStockService.fetchShopGoldStockAndValue($rootScope.currentShop._id)
          .then(function (res) {
            if (res.data && res.data.result) {
              $scope.existingGoldStockValue = res.data.result.goldStockValue ? res.data.result.goldStockValue : 0
              $scope.existingGoldStock = res.data.result.goldStock ? res.data.result.goldStock : 0
              $scope.goldStockHistory = res.data.result.goldStockHistory ? res.data.result.goldStockHistory : []

              if ($scope.existingGoldStock === 0) $scope.averageGoldStockValuePerGram = 0
              else {
                $scope.averageGoldStockValuePerGram = ($scope.existingGoldStockValue / $scope.existingGoldStock).toFixed(2)
              }
            }
          })
          .catch(function (reason) {
          })
      }

      $scope.setMode = function (mode) {
        $scope.correctionMode = mode
      }

      $scope.goldStockCorrection = function () {
        // Check validation
        if ($scope.stockCorrection && (!$scope.stockCorrection.correctionMode || $scope.stockCorrection.correctionMode === '')) {
          $scope.alert.type = 'danger'
          $scope.alert.msg = 'Please select stock correction mode'
        } else if ($scope.stockCorrection && !$scope.stockCorrection.goldStock) {
          $scope.alert.type = 'danger'
          $scope.alert.msg = 'Gold stock must be greater than 0'
        } else if ($scope.stockCorrection && !$scope.stockCorrection.goldStockValue) {
          $scope.alert.type = 'danger'
          $scope.alert.msg = 'Gold stock value must be greater than 0'
        } else {
          $goldStockService.updateShopGoldStockAndValue($rootScope.currentShop._id, $scope.stockCorrection)
            .then(function (res) {
              $scope.confirm()
            })
            .catch(function (reason) {
              $scope.alert = {
                type: 'danger',
                msg: reason.data.message
              }
            })
        }
      }

      $scope.calculateStockValuePerAverageRate = function () {
        $scope.suggestedAveragePrice = $scope.averageGoldStockValuePerGram * $scope.stockCorrection.goldStock
      }
    } catch (e) {
      console.log('Error in catch', e)
    }
  }])
