prismanoteApp.controller('retailerShopPurchaseController', ['$scope', '$q', '$rootScope', '$api', '$stateParams', '$uibModal', '$state', '$printer', '$pdf', '$language', '$retailer', 'prompt', '$transaction', 'dialogs', '$filter', '$customer', '$log', '$thermalPrint', '$card',
  function ($scope, $q, $rootScope, $api, $stateParams, $uibModal, $state, $printer, $pdf, $language, $retailer, prompt, $transaction, dialogs, $filter, $customer, $log, $thermalPrint, $card) {
    $scope.currentPage = 1
    $scope.pageSize = 5
    $scope.maxSize = 3
    $scope.changePaymentsMode = false
    $scope.cards = $card.getCards()
    $scope.payment = {}
    $scope.extraPayments = []
    $scope.multipleTransactions = false
    $scope.originalTotal = 0
    $scope.logOpen = false

    if ($rootScope.tablet || $rootScope.phone) {
      $scope.pageSize = 3
      $scope.maxSize = 9
    }
    $scope.loading = true

    $scope.outstanding = 0
    $scope.disableRefund = false
    $scope.bankPaymentOption = null;
    $scope.bankAmountPaid = true;

    $scope.customerName = function (customer) {
      return $customer.makeCustomerName(customer)
    }

    $scope.paymentMode = function () {
      if($scope.transaction && $scope.transaction.receipt && $scope.transaction.receipt.payments) {
        $scope.bankPaymentOption = null;
        for (var i = 0; i < $scope.transaction.receipt.payments.length; i++) {
          if($scope.transaction.receipt.payments[i].method === 'bankAmount' || $scope.transaction.receipt.payments[i].method === 'insurance') {
            if(!$scope.transaction.receipt.payments[i].bankPaymentAcknowledgement) {
              $scope.bankPaymentOption = 'pending';
            } else {
              $scope.bankPaymentOption = 'paid';
            }
          }
        }
      } else {
        $scope.bankPaymentOption = null;
      }
    }

    $scope.checkDisablePaymentMethod = function () {
      $scope.totalPaid = _.sumBy($scope.transaction.receipt.payments, 'amount')
      $scope.disableChangeMethod = ($scope.totalPaid * ($scope.transaction.transactionTotal < 0 ? -1 : 1)) >= ($scope.transaction.transactionTotal * ($scope.transaction.transactionTotal < 0 ? -1 : 1))
    }

    $scope.changePayments = function () {
      if($scope.changePaymentsMode && $scope.transaction.receipt.payments.length === 0) {
        toastr.error($language.translate('YOU_MUST_ENTER_AT_LEAST_ONE_PAYMENT'))
        return
      }
      $scope.changePaymentsMode = !$scope.changePaymentsMode
    }

    $scope.addPayment = function (payment){
      if(!payment || Object.keys(payment).length < 2 ||
        (payment.method === 'card' && !payment.cardName) ||
        ( (payment.method === 'bankAmount' || payment.method === 'insurance') &&
          (payment.bankConfirmed !== 'confirmed' && payment.bankConfirmed !== 'not_confirmed')
        )) {

        console.error('payment object not valid')
        return
      }
      $transaction.addPayment($scope.transaction._id, payment)
        .then(function(result) {
          toastr.success($language.translate(result))
          $scope.payment = null
          payment = null
          $scope.getTransaction()
        })
        .catch(function (reason) {
          toastr.error($language.translate(reason))
        })
    }

    $scope.removePayment = function (paymentId) {
      $transaction.removePayment($scope.transaction._id, paymentId)
        .then(function (result) {
          toastr.success($language.translate(result))
          $scope.getTransaction()
        })
        .catch(function (reason){
          toastr.error($language.translate(reason))
        })
    }

    $scope.confirmBankPayment = function () {
      var dlg = dialogs.confirm(
        $language.translate('WARNING'),
        $language.translate('DO_YOU_WANT_TO_CONTINUE'),
        {
          size: 'md'
        })
      dlg.result.then(function (btn) {
        if(btn === 'yes') {
          $api.post('update-bank-transaction/', {
            transactionId: $scope.transaction._id,
            shopId: $rootScope.currentShop._id
          })
            .then(function () {
              $scope.getTransaction()
            })
        }

      })

    }

    $scope.getTransaction = function () {
      $transaction.getTransaction($stateParams.transactionId, true, false, $rootScope.currentShop._id)
        .then(function (transaction) {
          if (transaction.length && transaction.length > 0) {
            $scope.transaction = transaction[0]
          } else {
            $scope.transaction = transaction
          }
          if ($scope.transaction) {
            $scope.originalTransaction = JSON.parse(JSON.stringify($scope.transaction))
          }

          $scope.receiptNumbers = []

          $scope.hasPendingPayment = $scope.transaction ? $scope.checkPendingPayment($scope.transaction) : false

          $scope.multipleTransactions = $scope.transaction.transactions && $scope.transaction.transactions.length > 0

          if ($scope.transaction.type === 'refund') {
            $scope.totalChange = 0
            var totalPaidByPaymentMethods = 0
            $scope.payMethods = []
            for (var i = 0; i < $scope.transaction.receipt.payments.length; i++) {
              $scope.payMethods.push({
                _id: $scope.transaction.receipt.payments[i]._id,
                method: $language.translate($scope.transaction.receipt.payments[i].method.toUpperCase()),
                originalMethod: $scope.transaction.receipt.payments[i].method.toUpperCase(),
                amount: $scope.transaction.receipt.payments[i].amount,
                change: $scope.transaction.receipt.payments[i].change,
                giftcardNumber: $scope.transaction.receipt.payments[i].giftcardNumber,
                card: $scope.transaction.receipt.payments[i].method.toUpperCase() === 'CARD',
                cardName: $scope.transaction.receipt.payments[i].cardName ? $scope.transaction.receipt.payments[i].cardName : '',
                paymentHash: $scope.transaction.receipt.payments[i].paymentHash ? true : false
              })
              totalPaidByPaymentMethods += $scope.transaction.receipt.payments[i].method.toUpperCase() !== 'PAYLATER' ? $scope.transaction.receipt.payments[i].amount : 0
            }
            if(totalPaidByPaymentMethods === 0){
              $scope.totalChange = 0
            }else{
              $scope.totalChange = totalPaidByPaymentMethods - $scope.transaction.transactionTotal
            }
          }

          if ($scope.transaction.type === 'shop-purchase') {
            if ($scope.transaction.details && $scope.transaction.details.length > 0) {
              $scope.transaction.totalAmount = 0
              for (var i = 0; i < $scope.transaction.details.length; i++) {
                if ($scope.transaction.details[i].total) {
                  $scope.transaction.totalAmount += $scope.transaction.details[i].total
                } else {
                  $scope.transaction.totalAmount += $scope.transaction.details[i].quantity * $scope.transaction.details[i].price
                }
                if($scope.multipleTransactions) {
                  $scope.originalTotal += $scope.transaction.details[i].originalTotal
                }
              }
            }

            $scope.payMethods = []
            $scope.totalChange = 0
            if (!$scope.transaction.receipt || !$scope.transaction.receipt.payments) {
              return
            }

            var totalPaidByPaymentMethods = 0

            for (var i = 0; i < $scope.transaction.receipt.payments.length; i++) {
              $scope.payMethods.push({
                _id: $scope.transaction.receipt.payments[i]._id,
                method: $language.translate($scope.transaction.receipt.payments[i].method.toUpperCase()),
                originalMethod: $scope.transaction.receipt.payments[i].method.toUpperCase(),
                amount: $scope.transaction.receipt.payments[i].amount,
                change: $scope.transaction.receipt.payments[i].change,
                giftcardNumber: $scope.transaction.receipt.payments[i].giftcardNumber,
                card: $scope.transaction.receipt.payments[i].method.toUpperCase() === 'CARD',
                cardName: $scope.transaction.receipt.payments[i].cardName ? $scope.transaction.receipt.payments[i].cardName : '',
                paymentHash: $scope.transaction.receipt.payments[i].paymentHash ? true : false
              })
              totalPaidByPaymentMethods += $scope.transaction.receipt.payments[i].method.toUpperCase() !== 'PAYLATER' ? $scope.transaction.receipt.payments[i].amount : 0
            }
            var totalAmount = $scope.transaction.transactionTotal < 0 ? $scope.transaction.transactionTotal * -1 : $scope.transaction.transactionTotal
            if(totalPaidByPaymentMethods === 0){
              $scope.totalChange = 0
            }else if(totalPaidByPaymentMethods < 0) {
              $scope.totalChange = totalPaidByPaymentMethods + totalAmount
            } else {
              $scope.totalChange = totalPaidByPaymentMethods - totalAmount
            }

            if ($scope.transaction.transactions && $scope.transaction.transactions.length > 0) {
              var details = $scope.transaction.details

              for (var i = 0; i < $scope.transaction.transactions.length; i++) {
                if ($scope.transaction.transactions[i].type === 'refund') {
                  $scope.refund = $scope.transaction.transactions[i]
                  $scope.disableRefund = true
                }

                //Combine the details of the extra transactions with the details of the current transaction
                for(var d = 0; d < $scope.transaction.transactions[i].details.length; d++) {
                  var transDetail = $scope.transaction.transactions[i].details[d]

                  if(!transDetail.parentTransactionDetailId || transDetail.parentTransactionDetailId === '') {
                    continue
                  }

                  var originalDetailIdx = _.findIndex(details, function (d) {
                    return d._id.toString() ===  transDetail.parentTransactionDetailId.toString()
                  })
                  if (originalDetailIdx < 0) {
                    continue
                  }

                  if(!details[originalDetailIdx].originalDetailTotal) {
                    details[originalDetailIdx].originalDetailTotal = details[originalDetailIdx].total
                  }
                  if(!details[originalDetailIdx].originalDetailPrice) {
                    details[originalDetailIdx].originalDetailPrice = details[originalDetailIdx].price
                  }
                  details[originalDetailIdx].total += transDetail.total
                  //details[originalDetailIdx].price += transDetail.price
                }


                //Combine all payments
                if($scope.transaction.transactions[i].receipt && $scope.transaction.transactions[i].receipt.payments && $scope.transaction.transactions[i].receipt.payments.length > 0) {
                  //Add all payments which are found on the extra transactions to an array
                  for(var p = 0; p < $scope.transaction.transactions[i].receipt.payments.length; p++) {
                    var payment = $scope.transaction.transactions[i].receipt.payments[p]
                    $scope.extraPayments.push({
                      _id: payment._id,
                      method: $language.translate(payment.method.toUpperCase()),
                      originalMethod: payment.method.toUpperCase(),
                      amount: payment.amount,
                      change: payment.change,
                      giftcardNumber: payment.giftcardNumber,
                      card: payment.method.toUpperCase() === 'CARD',
                      cardName: payment.cardName ? payment.cardName : '',
                      transactionNumber: $scope.transaction.transactions[i].number,
                      receiptNumber: $scope.transaction.transactions[i].receipt.number,
                      transactionId: $scope.transaction.transactions[i]._id,
                      transactionType: $scope.transaction.transactions[i].type
                    })
                  }
                }
              }
            }
          }

          if ($scope.transaction.type === 'shop-purchase' || $scope.transaction.type === 'refund') {
            $scope.totalTax = 0
            $scope.taxes = {}
            var detailsLength = $scope.transaction.details.length
            for (var i = 0; i < detailsLength; i++) {
              var detail = $scope.transaction.details[i]

              detail.price = detail.price * (1 + (detail.priceVat / 100))

              $scope.totalTax += $scope.taxPrice(detail)
              if (!$scope.taxes[detail.priceVat]) {
                $scope.taxes[detail.priceVat] = detail.total
              } else {
                $scope.taxes[detail.priceVat] += detail.total
              }

              // Now lower those values with the total discount on the offer (if applied)
              if ((i + 1) === detailsLength && $scope.transaction.discount) {
                var totalDiscount = $scope.getDiscount()
                var totalPrice = $scope.getTotalPrice(false)

                // Now loop through the taxes table and edit the values based on the value, sow more discount will be applied to a rule with an higher amount
                for (var t in $scope.taxes) {
                  var originalRate = $scope.taxes[t]
                  var ratePriceWithDiscount = $scope.taxes[t] * (totalDiscount / totalPrice)
                  $scope.taxes[t] = originalRate - ratePriceWithDiscount
                }
              }
            }
          }

          if ($scope.transaction.type === 'refund') {
            if ($scope.transaction.details && $scope.transaction.details.length > 0) { $scope.refund = $scope.transaction.details[0].transaction }
            $scope.disableRefund = true
          }

          $scope.getOutstandingAmount()

          $scope.loading = false
          $scope.paymentMode()
          $scope.checkDisablePaymentMethod()
          $scope.getPageDefinition()
        })
        .catch(function (reason) {
          console.error('Error while loading transaction', reason)
          if (typeof reason === 'object') {
            $scope.alert = {
              type: 'danger',
              msg: $language.translate('UNKOWN_ERROR_OCCURED')
            }
            return
          }
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(reason)
          }

          $scope.loading = false
        })
    }

    $scope.openCustomer = function () {
      $state.go('retailer.customer', {
        id: $scope.transaction.customer._id
      })
    }

    $scope.findTransactionIdByNumberAndOpen = function (number) {
      var payment = _.find($scope.extraPayments, {transactionNumber : number})
      $scope.openTransaction(payment.transactionId, payment.transactionType, false)
    }

    $scope.updateTransaction = function () {
      if ((!$scope.transaction.createdBy || !$scope.transaction.createdBy.name) && $scope.isRemovedAssitedBy) return
      $scope.loading = true
      if (!$scope.transaction.log) {
        $scope.transaction.log = []
      }
      if ($scope.intakeDateChanged) {
        var log = {
          date: new Date(),
          user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
          text: 'Intake date changed',
          isFor: 'retailerPortal',
          eType: 'retailer'
        }
        $scope.transaction.log.push(log)
      }

      if ($scope.repairDateChanged) {
        var log = {
          date: new Date(),
          user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
          text: 'Repair date changed',
          isFor: 'retailerPortal',
          eType: 'retailer'
        }
        $scope.transaction.log.push(log)
      }

      $scope.transaction.shopId = { _id: $scope.transaction.shopId._id }

      $transaction.updateTransaction($scope.transaction, null, true)
        .then(function (result) {
          $scope.loading = false
          $scope.getTransaction()
        })
        .catch(function (reason) {
          $scope.loading = false
          dialogs.error($language.translate('ERROR'), $language.translate('AN_ERROR_OCCURED') + ': ' + reason, { size: 'sm' })
        })
    }

    $scope.back = function () {
      window.history.back()
    }

    $scope.getItemDiscount = function (item) {
      if (item.discount) {
        if (item.discountPercent) {
          return item.discountValue + '%'
        } else {
          return $filter('currency')(item.discountValue, '\u20AC')
        }
      } else {

      }
    }

    $scope.getTotalPrice = function (discount) {
      if(!$scope.transaction) {
        return
      }
      var total = 0
      var counter = 0
      for (var i = 0; i < $scope.transaction.details.length; i++) {
        total += $scope.transaction.details[i].total
        counter++
        if (counter === $scope.transaction.details.length) {
          if (discount) {
            return total - $scope.getDiscount()
          } else {
            return total
          }
        }
      }
    }

    $scope.taxPrice = function (product) {
      var total = angular.copy(product.total)
      // if (total < 0) {
      //   total = total * -1
      // }

      return total - (total / (1 + (product.priceVat / 100)))
    }

    $scope.getDiscount = function () {
      if (!$scope.transaction.discount) {
        return 0
      }

      if ($scope.transaction.discount.percent) {
        return $scope.getTotalPrice(false) * ($scope.transaction.discount.value / 100)
      } else {
        return $scope.transaction.discount.value
      }
    }

    $scope.refundTransaction = function () {
      $state.go('retailer.till', {
        customer: $scope.transaction.customer,
        items: $scope.transaction.items,
        transactionId: $scope.transaction._id,
        transactionNumber: $scope.transaction.number,
        refund: true
      })
    }

    $scope.getTransactionPrice = function () {
      var counter = 0; var totalAmount = 0
      for (var i = 0; i < $scope.transaction.details.length; i++) {
        totalAmount += $scope.transaction.details[i].total
        counter++
        if (counter === $scope.transaction.details.length) {
          return totalAmount
        }
      }
    }

    $scope.getPrice = function (checkout, callback) {
      if (!$scope.transaction) {
        return
      }
      if (checkout && $scope.outstanding > 0) {
        return callback($scope.outstanding)
      }
      if ($scope.transaction.type === 'repair' || $scope.transaction.type === 'special') {
        if ($scope.transaction[$scope.transaction.type].actualCosts) {
          return callback($scope.transaction[$scope.transaction.type].actualCosts)
        } else if ($scope.transaction[$scope.transaction.type].estimatedPrice) {
          return callback($scope.transaction[$scope.transaction.type].estimatedPrice)
        } else {
          return callback(0)
        }
      } else if ($scope.transaction.type === 'shop-purchase') {
        return callback($scope.getTransactionPrice() * (-1))
      }
    }

    $scope.toggleLog = function () {
      $scope.logOpen = !$scope.logOpen
    }

    $scope.getOutstandingAmount = function () {
      $scope.outstanding = 0
      $scope.getPrice(false, function (price) {
        if (!$scope.payments || $scope.payments.length === 0) {
          $scope.outstanding = price
          return
        }
        var counter = 0; var totalPaid = 0
        for (var i = 0; i < $scope.payments.length; i++) {
          totalPaid += $scope.payments[i].amount - $scope.payments[i].change
          counter++
          if (counter === $scope.payments.length) {
            $scope.outstanding = price - totalPaid
          }
        }
      })
    }

    $scope.fetchPreviousPaidQuantity = function () {
      return $q(function (resolve, reject) {
        var totalPaidQuantity = 0
        if ($scope.transaction.details && $scope.transaction.details.length && $scope.transaction.details[0].productId) {
          var currentSingleOrderProductId = $scope.transaction.details[0].productId._id

          for (var i = 0; i < $scope.transaction.transactions.length; i++) {
            var firstDetail = $scope.transaction.transactions[i].details[0]
            totalPaidQuantity += firstDetail.quantity
          }
        }
        return resolve(totalPaidQuantity)
      })
    }

    $scope.askContinueCheckout = function() {
      $state.go('retailer.till', {
        concept: true,
        transactionId: $scope.transaction._id,
      })
    }

    $scope.checkout = function (refund, deposit, finalCheckout) {
      var items = []
      if (refund) {

        // We gonna refund the whole order, so we take the items of the current transaction and placed them with an negative amount on a new transaction
        var counter = 0
        for (var i = 0; i < $scope.transaction.details.length; i++) {
          var detail = $scope.transaction.details[i]

          var quantity

          if ($scope.transaction.totalEarning > $scope.transaction.transactionTotal) {
            quantity = -($scope.transaction.transactionTotal - $scope.transaction.totalEarning) / ($scope.transaction.details[0].price * (1 + ($scope.transaction.details[0].priceVat / 100)))
          }

          items.push({
            name: $language.translate('REFUND') + ' ' + detail.name,
            quantity: $scope.transaction.totalEarning > $scope.transaction.transactionTotal ? quantity : detail.quantity - detail.stockUpQuantity,
            price: detail.price,
            total: detail.total,
            productId: detail.productId && detail.productId._id ? detail.productId._id : null,
            transaction: $scope.transaction._id,
            discount: false,
            discountValue: 0,
            discountPercent: null,
            photos: detail.photos,
            priceVat: detail.priceVat ? detail.priceVat : 21,
            comment: detail.comment,
            purchasePrice: detail.purchasePrice,
            refunded: detail.refundAmount === detail.total,
            productForRefund: true,
            isRestictForEdit: $scope.transaction.totalEarning > $scope.transaction.transactionTotal,
            refundQuantity: $scope.transaction.totalEarning > $scope.transaction.transactionTotal ? quantity : detail.quantity - detail.stockUpQuantity,
            refundAmount: $scope.transaction.totalEarning > $scope.transaction.transactionTotal ? $scope.transaction.totalEarning - $scope.transaction.transactionTotal : detail.total - detail.refundAmount,
            refundedAmount: detail.refundAmount,
            parentTransactionDetailId: detail._id,
            stockUpQuantity: detail.stockUpQuantity,
            supplier: detail.supplier
          })
          counter++
          if (counter === $scope.transaction.details.length) {

            $state.go('retailer.till', {
              details: $scope.transaction.details,
              items: items,
              transaction: $scope.transaction._id,
              customer: $scope.transaction.customer
            })
          }
        }
      } else {
        var depositAmount = ($scope.transaction.transactionTotal - $scope.transaction.totalEarning)

        if (deposit) {
          depositAmount = depositAmount * (deposit / 100)
        }

        // $scope.getPrice(true, function (price) {
        if (finalCheckout && $scope.transaction.type === 'special') {
          // var items;
          // Need to fetch sum of all shop-purchase order quantity which is done for this transaction
          $scope.fetchPreviousPaidQuantity()
            .then(function (paidQuantity) {
              var itemsData = [{
                name: $scope.transaction.details[0].name,
                quantity: $scope.transaction.details[0].quantity - paidQuantity, // depositAmount / ( $scope.transaction.details[0]['price'] * ( 1 + ($scope.transaction.details[0]['priceVat'] / 100 ) ) ),
                isRestictForEdit: true,
                price: $scope.transaction.details[0].price * (1 + ($scope.transaction.details[0].priceVat / 100)), // deposit ? ($scope.transaction.transactionTotal - $scope.transaction.totalEarning) * (deposit / 100) : ($scope.transaction.transactionTotal - $scope.transaction.totalEarning),
                transaction: $scope.transaction._id,
                discountValue: 0,
                total: depositAmount,
                photo: null,
                priceVat: 21,
                comment: $language.translate('DEPOSIT') + ' ' + $language.translate($scope.transaction.type.toUpperCase()) + ' ' + $scope.transaction.number + '. ' + '\n' + $language.translate('TOTAL_AMOUNT_TO_PAY') + ' \u20AC' + $scope.transaction.details[0].total, // (deposit ? $language.translate('TOTAL_AMOUNT_TO_PAY') + ' \u20AC' + $scope.transaction.transactionTotal : $scope.transaction.comment),
                discount: false,
                parentTransactionDetailId: $scope.transaction.details && $scope.transaction.details[0] && $scope.transaction.details[0]._id ? $scope.transaction.details[0]._id : null,
                productId: $scope.transaction.details && $scope.transaction.details[0] && $scope.transaction.details[0].productId ? $scope.transaction.details[0].productId._id : null,
                // purchasePrice: $scope.transaction.type == 'repair' && $scope.transaction.repair.externalPrice ? $scope.transaction.repair.externalPrice : $scope.transaction.type == 'special' ? 12345 : null,
                purchasePrice: $scope.transaction.details && $scope.transaction.details[0] && $scope.transaction.details[0].purchasePrice ? $scope.transaction.details[0].purchasePrice : null,
                noNeedToReCalculateOfTotal: true
              }]

              $state.go('retailer.till', {
                items: JSON.parse(JSON.stringify(itemsData)),
                transaction: $scope.transaction._id,
                customer: $scope.transaction.customer
              })


            })
            .catch(function (error) {
              console.error('Error fetching previous paid quantity', error)
            })
        } else {
          items = [{
            name: $scope.transaction.details[0].name, // (refund ? $language.translate('REFUND') + ' ' : (deposit ? $language.translate('DEPOSIT') + ' ' : '')) + $language.translate($scope.transaction.type.toUpperCase()) + " " + $scope.transaction.number,
            quantity: depositAmount / ($scope.transaction.details[0].price * (1 + ($scope.transaction.details[0].priceVat / 100))), // 1,
            isRestictForEdit: true,
            price: $scope.transaction.details[0].price * (1 + ($scope.transaction.details[0].priceVat / 100)), // deposit ? ($scope.transaction.transactionTotal - $scope.transaction.totalEarning) * (deposit / 100) : ($scope.transaction.transactionTotal - $scope.transaction.totalEarning),
            transaction: $scope.transaction._id,
            discountValue: 0,
            total: depositAmount,
            photo: null,
            priceVat: 21,
            comment: $language.translate('DEPOSIT') + ' ' + $language.translate($scope.transaction.type.toUpperCase()) + ' ' + $scope.transaction.number + '. ' + '\n' + $language.translate('TOTAL_AMOUNT_TO_PAY') + ' \u20AC' + $scope.transaction.details[0].total, // (deposit ? $language.translate('TOTAL_AMOUNT_TO_PAY') + ' \u20AC' + $scope.transaction.transactionTotal : $scope.transaction.comment),
            discount: false,
            parentTransactionDetailId: $scope.transaction.details && $scope.transaction.details[0] && $scope.transaction.details[0]._id ? $scope.transaction.details[0]._id : null,
            productId: $scope.transaction.details && $scope.transaction.details[0] && $scope.transaction.details[0].productId ? $scope.transaction.details[0].productId._id : null,
            purchasePrice: $scope.transaction.type === 'repair' && $scope.transaction.repair.externalPrice ? $scope.transaction.repair.externalPrice : null
          }]

          $state.go('retailer.till', {
            items: items,
            transaction: $scope.transaction._id,
            customer: $scope.transaction.customer
          })
        }

        // })
      }
    }

    $scope.continueTransaction = function () {
      $state.go('retailer.till', {
        items: $scope.transaction.details,
        transaction: $scope.transaction._id,
        customer: $scope.transaction.customer
      })
    }

    $scope.deleteTransaction = function () {
      var dlg = dialogs.confirm($language.translate('CANCEL'), $language.translate('ARE_YOU_SURE_TO_DELETE_THIS_TRANSACTION'), { size: 'sm' })
      dlg.result.then(function (btn) {
        if (btn === 'yes') {
          $transaction.deleteTransaction($scope.transaction._id, true)
            .then(function (res) {
              $state.go('retailer.transactions')
            })
            .catch(function (reason) {
              console.error(reason)
            })
        }
      })
        .catch(function (reason) {
        })
    }

    $scope.openTransaction = function (id, type, old) {
      $transaction.openTransaction(id, type, old)
    }

    $scope.emailReceipt = function (isWithVat) {
      if (!$scope.transaction.customer || !$scope.transaction.customer.email || $scope.transaction.customer.counter === true) {
        return
      }
      $scope.makeReceipt('string', true, isWithVat)
    }

    $scope.makeReceipt = function (type, skipPrint, isWithVat, combine, printParent) {
      if (type === 'string') {
        $scope.sendingMail = true
        $scope.mailSent = false
      }
      if($scope.transaction.type === 'refund' && (!$scope.transaction.parentTransactionId || ($scope.transaction.parentTransactionId && $scope.transaction.parentTransactionId.type !== 'shop-purchase'))) {
        combine = false
      }

      $scope.getPageDefinition()

      var transactionId = $scope.transaction._id
      if(printParent) {
        transactionId = $scope.transaction.parentTransactionId._id
        combine = false
      }
      if(combine) {
        if($scope.transaction.parentTransactionId && $scope.transaction.parentTransactionId.type === 'shop-purchase') {
          transactionId = $scope.transaction.parentTransactionId._id
        } else if($scope.transaction.transactions && $scope.transaction.transactions.length > 0 && $scope.transaction.transactions[0].type === 'shop-purchase') {
          transactionId = $scope.transaction.transactions[0]._id
        }
      }

      $pdf.downloadPdf($scope.transaction.isInvoice ? 'invoice' : 'generate-shop-receipt', {
          transactionId: transactionId,
          email: $scope.transaction.customer.email,
          shopId: $rootScope.currentShop._id,
          payMethods: $scope.payMethods,
          typeDocument: type,
          documentName: $scope.transactionDoc.type,
          deviceId: $rootScope.currentDevice._id,
          isWithVat: isWithVat,
          shopDetailsPoints: $rootScope.currentShop.points ? $rootScope.currentShop.points : undefined,
          shopUrl: $rootScope.currentShop.proShopUrl ? $rootScope.currentShop.proShopUrl : undefined,
          combine: combine,
          documentId: $scope.transactionDoc.documentId
        }, $scope.transaction.number + '.pdf',
        type === 'document' ? 'arraybuffer' : 'string', skipPrint, skipPrint === false)
        .then(function () {
          if (type !== 'document') {
            $scope.mailAlert = {
              type: 'success',
              msg: $language.translate('MAIL_SEND')
            }
          }
        })
        .catch(function (reason) {
          console.error(reason)
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(reason)
          }
        })
    }

    $scope.makeInvoice = function () {
      var dlg = dialogs.confirm(
        $language.translate('MAKE_INVOICE'),
        $language.translate('THIS_ACTION_CAN_NOT_BE_MADE_UNDONE_ARE_YOU_SURE'),
        {
          size: 'md'
        })
      dlg.result.then(function (btn) {
        if(btn === 'yes') {
          var user = $rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id
          var term = $scope.transaction.paymentTermDays || $scope.transaction.customer.paymentTermDays || 14
          $transaction.updateTransactionToInvoice($scope.transaction._id, user, term)
            .then(function(res) {
              $scope.getTransaction()
            })
            .catch(function (reason){

            })
        }

      })
    }

    $scope.printStorePurchaseReceipt = function (print, isWithVat, combine, parentTransaction) {
      $scope.makeReceipt('document', print === false, isWithVat, combine, parentTransaction)
    }

    $scope.dateCheck = function(date){
      var tDate = new Date(date);
      var dateDiff = (new Date() - tDate)/(1000 * 60);
      if(dateDiff < 10){ return true; }else { return false}
    }

    $scope.changeDate = function(date){
      var data = {
        date: date,
        transactionId: $scope.transaction._id,
        shopId: $rootScope.currentShop._id,
        user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
      }
      if($scope.transaction.transactions && $scope.transaction.transactions.length > 0) {
        data.transactions = $scope.transaction.transactions.map( (function(t) {
          return t._id
        }))
      }
      if($scope.transaction.parentTransactionId && $scope.transaction.parentTransactionId._id) {
        data.transactions.push($scope.transaction.parentTransactionId._id)
      }
      $api.post('transaction-date/changeDate', data, null, 2)
        .then(function () {
        })
        .catch(function (reason) {
        })
    }

    $scope.getPageDefinition = function() {
      var pageDef = ''
      if($scope.transaction && $scope.transaction.isInvoice) {
        pageDef = 'INVOICE'
      } else {
        pageDef = 'TRANSACTION'
      }
      $printer.getPageDefinition(pageDef)
        .then(function(doc) {
          $scope.transactionDoc = doc
          $scope.transactionDoc.enablePrint = false
          $scope.jspm = !$rootScope.currentShop.printNode || $rootScope.currentShop.printNode.enabled === false
          if($scope.jspm) {
            if($scope.transactionDoc.printerName && $scope.transactionDoc.printerName !== '' ) {
              $scope.transactionDoc.enablePrint = true
            }
          } else {
            if($scope.transactionDoc.printNode && $scope.transactionDoc.printNode.printerId) {
              $scope.transactionDoc.enablePrint = true
            }
          }

          //Check also thermalprint settings here
          $scope.thermalPrintEnabled = false
          if($scope.currentDevice.thermalPrinter && $scope.currentDevice.thermalPrinter.enabled) {
            if ($scope.jspm) {
              if ($rootScope.currentDevice.thermalPrinter.enabled) {
                $scope.thermalPrintEnabled = true
              }
            } else {
              if ($rootScope.currentDevice.thermalPrinter.printNode && $rootScope.currentDevice.thermalPrinter.printNode.printerId) {
                $scope.thermalPrintEnabled = true
              }
            }
          }

        })
        .catch(function(reason){
          $log.error('Error while fetching page definition', reason)
        })
    }

    $scope.printThermal = function () {
      if($rootScope.currentShop.newPrinter === true) {
        $thermalPrint.printThermal('shop-receipt',1, $scope.transaction, true, true)
          .then(function () {
            console.info('Shop receipt printed')
          })
          .catch(function (reason) {
            console.error('Error while printing shop receipt', reason)
          })
      } else {
        $printer.checkEnabled()
          .then(function () {
            $printer.printShopReceipt({
              shop: $rootScope.currentShop,
              transaction: $scope.transaction,
              skipVat: true,
              skipDrawer: true
            })
              .then(function (result) {

              })
              .catch(function (reason) {
                console.error('Something went wrong in $printer', reason)
                dialogs.error($language.translate('ERROR'), $language.translate('AN_ERROR_OCCURED') + ': ' + reason, { size: 'sm' })
              })
          })
          .catch(function (reason) {
            console.info(reason)
            dialogs.notify(undefined, $language.translate('PRINTER_IS_NOT_ENABLED'), { size: 'sm' })
          })
      }
    }

    $scope.changeCustomer = function () {
      if($scope.transaction.customer && $scope.transaction.customer.counter === false && $rootScope.currentShop.points && $rootScope.currentShop.points.enabled) {
        //Show confirm dialog to notice user about manually correcting points
        var dlg = dialogs.confirm(
          $language.translate('CONFIRM'),
          $language.translate('CHANGE_CUSTOMER_MANUALLY_CORRECT_POINTS'),
          {
            size: 'sm',
            btnMessages: {
              'DIALOGS_YES': $language.translate('OK'),
              'DIALOGS_NO': $language.translate('CANCEL')
            }
          })
        dlg.result.then(function (btn) {
          if (btn === 'yes') {
            doChange()
          }
        })
          .catch(function (reason) {
          })
      } else {
        doChange()
      }

      function doChange() {
        $customer.openSearchCustomerModal(false, false)
          .then(function (result) {
            var user = $rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id
            $scope.transaction.customer = result
            if (result.paymentTermDays) {
              $scope.transaction.paymentTermDays = result.paymentTermDays
            } else {
              $scope.transaction.paymentTermDays = 14
            }
            $transaction.updateTransactionCustomer($scope.transaction._id, result._id, user, $scope.transaction.paymentTermDays)
          })
          .catch(function (reason) {
            console.error('Error while changing customer on transaction', reason)
          })
      }
    }

    $scope.changePaymentTerm = function (term) {
      var user = $rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id
      $transaction.updateTransactionPaymentTerm($scope.transaction._id, user, term)
    }

    $scope.changePayment = function () {
      var items = $scope.transaction.details.map(function(d) {
        var total = 0
        if($scope.multipleTransactions) {
          total = d.originalTotal - ( (d.originalDetailTotal ? d.originalDetailTotal : d.total) + d.paidLaterAmount)
        } else {
          if(d.total < 0) {
            // total is negative, so do a different calculation for the rest amount
            total = d.originalTotal + ( (d.total * -1) + (d.paidLaterAmount ? d.paidLaterAmount : 0))
          } else {
            total = d.originalTotal - (d.total + (d.paidLaterAmount ? d.paidLaterAmount : 0))
          }
        }
        total = (Math.round(total * 100) / 100).toFixed(2)
        if(d.total < 0 && total > 0) {
          // The total of the detail is negative but the calculated total is positive, so convert that to negative again
          total = total * -1
        }
        var extraTransaction = null
        var transactionId = $scope.transaction.parentTransactionId && $scope.transaction.parentTransactionId._id ? $scope.transaction.parentTransactionId._id : $scope.transaction._id
        if(d.type === 'repair' || d.type === 'special' || d.type === 'order') {
          transactionId = d.transaction && d.transaction._id ? d.transaction._id : d.transaction
          if(d.extraTransaction) {
            extraTransaction = d.extraTransaction
          } else {
            extraTransaction = $scope.transaction._id
          }
        }
        var parentTransactionDetailId = d.parentTransactionDetailId ? d.parentTransactionDetailId : d._id ? d._id : null

        return {
          repairPayment: d.type === 'repair',
          specialPayment: d.type === 'special' || d.type === 'order',
          restPayment: true,
          type: d.type,
          transaction: transactionId, // transactionId of the original transaction, this can be an repair/order
          extraTransaction: extraTransaction, // in case of a repair/order we need this _id also
          quantity: 1,
          price: parseFloat(total),
          total: parseFloat(total),
          priceVat: d.priceVat,
          name: $language.translate('REST_PAYMENT') + ' ' + d.name,
          productId: d.productId && d.productId._id ? d.productId._id : null,
          parentTransactionDetailId: parentTransactionDetailId,
          comment: d.comment ? d.comment : null,
          productCollection: d.productCollection ? d.productCollection: null
        }
      })

      items = items.filter(function (i) {
        return parseFloat(i.total) !== 0 && parseFloat(i.total) !== 0.00
      })

      if(!items || items.length === 0) {
        toastr.error($language.translate('THERE_ARE_NO_ITEMS_LEFT_TO_PAY'))
        return
      }

      var transId = $scope.transaction.parentTransactionId && $scope.transaction.parentTransactionId._id ? $scope.transaction.parentTransactionId._id : $scope.transaction._id

      $state.go('retailer.till', {
        customer: $scope.transaction.customer,
        items: items,
        transactionId: transId ,
        transactionNumber: $scope.transaction.number,
        refund: false,
        checkout: true,
        payLater: true
      })
    }

    $scope.checkRepairSpecialItems = function () {
      if(!$scope.transaction || !$scope.transaction.details || $scope.transaction.details.length === 0) {
        return -1
      }
      var item = _.findIndex($scope.transaction.details, function(detail) {
        return detail.type === 'repair' || detail.type === 'special' || detail.type === 'order'
      })

      return item
    }

    $scope.checkPayLater = function () {
      if (!$scope.transaction || !$scope.transaction.receipt || !$scope.transaction.receipt.payments || $scope.transaction.receipt.payments.length === 0) {
        return false
      }

      var payLater = _.findIndex($scope.transaction.receipt.payments, function (p) {
        return p.method.toUpperCase() === 'PAYLATER' && p.amount > 0
      })

      return payLater >= 0
    }

    $scope.printProductLabel = function (id) {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/print-label-modal.html',
        controller: 'printLabelModalController',
        resolve: {
          loadProduct: function () {
            return true
          },
          product: function () {
            return { _id: id };
          },
          labelDescription:  function () {
            return null
          },
          empty: function () {
            return false
          }
        }
      });
      //we will get the value here
      modalInstance.result.then(function (result) {

      }, function () {
        //dismissed
      })
    }

    $scope.moveTransactionToTrash = function () {
      var dlg = dialogs.confirm(
        $language.translate('WARNING'),
        $language.translate('THIS_AND_ALL_CONNECTED_TRANSACTIONS_WILL_BE_MOVED_TO_TRASH_DO_YOU_WANT_TO_CONTINUE'),
        {
          size: 'md'
        })
      dlg.result.then(function (btn) {
        if(btn === 'yes') {
          var data = {
            transactionId: $scope.transaction._id,
            shopId: $rootScope.currentShop._id,
            user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
            transactions: []
          }
          if($scope.transaction.transactions && $scope.transaction.transactions.length > 0) {
            data.transactions = $scope.transaction.transactions.map( (function(t) {
              return t._id
            }))
          }
          if($scope.transaction.parentTransactionId && $scope.transaction.parentTransactionId._id) {
            data.transactions.push($scope.transaction.parentTransactionId._id)
          }
          $api.post('deleted-transaction/delete', data, null, 2)
            .then(function () {
              $state.go('retailer.transactions')
            })
            .catch(function (reason) {
              console.error('Error while moving transaction to trash', reason)
              toastr.error($language.translate('ERROR_DELETING_TRANSACTION'))
            })
        }
      })
    }

    $scope.checkPendingPayment = function (transaction) {
      var result = false
      if(transaction.receipt && transaction.receipt.payments && transaction.receipt.payments.length > 0) {
        var pending = transaction.receipt.payments.filter(function (p) {
          return p.ccvTransactionPending === true
        })
        if(pending && pending.length > 0) {
          result = true
        }
      }
      return result
    }
  }])
