prismanoteApp.controller('editDeviceModalController', ['$scope', '$uibModalInstance', 'device', '$language', '$device', '$jspm', '$rootScope', 'Upload',
  function ($scope, $uibModalInstance, device, $language, $device, $jspm, $rootScope, Upload) {

    $scope.device = angular.copy(device)
    $scope.printers = []
    $scope.printerBackup = []
    $scope.alert = null
    $scope.data = {}
    $scope.editCert = false

    $scope.jspm = !$rootScope.currentShop.printNode || $rootScope.currentShop.printNode.enabled === false

    $scope.cancel = function () {
      $uibModalInstance.close(false)
    }

    $scope.getPrinters = function () {
      $jspm.getInstalledPrinters(true)
        .then(function (printers) {
          $scope.printers = printers
          $scope.printerBackup = printers
        })
        .catch(function (reason) {
          $jspm.closeConnection()
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(reason)
          }
        })
    }

    $scope.save = function () {
      $device.updateDevice($scope.device)
      .then(function() {
        $uibModalInstance.close({device:$scope.device})
      })
      .catch(function(reason){
        console.error('Error while updating device', reason)
        toastr.error($language.translate(reason))
      })
    }

    $scope.saveCertificate = function () {
      $scope.alert = null
      console.log('certificateFile', $scope.data.certificateFile)
      if(!$scope.data.certificateFile) {
        $scope.alert = {
          type: 'danger',
          msg: $language.translate('NO_FILE_CHOOSEN')
        }
        return
      }
      if($scope.editWorkstationForm.certificateFile.$error.pattern) {
        $scope.alert = {
          type: 'danger',
          msg: $language.translate('INVALID_FILE_CHOOSEN')
        }
        return
      }
      var params = {
        url: 'api/device/certificate',
        data: {
          file: $scope.data.certificateFile,
          currentShopId: $rootScope.currentShop._id,
          deviceId: $scope.device._id
        }
      }

      Upload.upload(params)
        .then(function (res) {
        })
        .catch(function (reason) {
          console.error('Error uploading certificate', reason)
          $scope.alert = {
            type: 'danger',
            msg: reason.message
          }
        })
    }

    $scope.downloadCert = function () {
      $device.downloadCertificate($scope.device.name, $scope.device._id)
    }

    $scope.toggleEditCert = function () {
      $scope.editCert = !$scope.editCert
    }


  }])
