prismanoteApp.controller('retailerTakeMoneyModalController', ['$scope', 'stateId', '$uibModalInstance', '$api', '$rootScope', '$language', 'drawer', '$CashRegistry',
  function ($scope, stateId, $uibModalInstance, $api, $rootScope, $language, drawer, $CashRegistry) {

    $scope.mutation = {
      amount: 0,
      defaultReason: 'CASH_ON_DELIVERY',
      reason: ''
    }
    $scope.drawer = drawer

    if($scope.drawer === true) {
      $scope.mode = 'take'
    } else {
      $scope.mode = null
    }

    $scope.reasons = $CashRegistry.getCashMutationReasons($scope.drawer)

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    $scope.changeMode = function(mode) {
      $scope.mode = mode
    }

    $scope.mutateMoney = function () {
      if (!$scope.buttonDisabled){
        return
      }
      if($scope.drawer) {
        $uibModalInstance.close({
          reason: $scope.defaultReason !== 'OTHER' ? $language.translate($scope.mutation.defaultReason) : $scope.mutation.reason
        })
        return
      } else {
        $scope.alert = null
        var reason = $scope.mutation.defaultReason !== 'OTHER' ? $language.translate($scope.mutation.defaultReason) : $scope.mutation.reason

        if ($scope.mode === 'deposit') {
          $scope.mutation.amount = $scope.mutation.amount * -1
          reason = $language.translate('CASH_DEPOSIT') + ': ' + ($scope.mutation.defaultReason !== 'OTHER' ? $language.translate($scope.mutation.defaultReason) : $scope.mutation.reason)
        }

        $api.post('daystate/take-money', {
          daystate: stateId,
          shopId: $rootScope.currentShop._id,
          amount: $scope.mutation.amount,
          reason: reason
        })
          .then(function (result) {
            $uibModalInstance.close({
              amount: $scope.mutation.amount,
              reason: $scope.defaultReason !== 'OTHER' ? $language.translate($scope.mutation.defaultReason) : $scope.mutation.reason
            })
          })
          .catch(function (reason) {
            console.error('Error while updating daystate', reason)
            $scope.alert = {
              type: 'danger',
              msg: reason
            }
          })
      }
    }

    $scope.buttonDisabled = function () {
      // return true to disable

      if ( $scope.drawer === false && (!$scope.mutation.amount || !$scope.mutation.defaultReason || $scope.mutation.amount === 0)) {
        return true
      }
      if ($scope.mutation.defaultReason === 'OTHER' && (!$scope.mutation.reason || $scope.mutation.reason === '')) {
        return true
      }

      return false
    }
  }])
