prismanoteApp.controller('employeesBrandController', ['$scope', '$rootScope', '$state', '$brand', '$company', '$language',
  function ($scope, $rootScope, $state, $brand, $company, $language) {
    $rootScope.pageTitle = 'PrismaNote Brand Portal | ' + $language.translate('EMPLOYEES')

    $scope.getCompany = function () {
      $company.getCompany()
        .then(function (company) {
          $scope.currentCompany = company
          $brand.getEmployeesOfCompany($scope.currentCompany._id).then(function (response) {
            $scope.employees = response.data.employeeDetail
          }).catch(function (error) {
            console.log('Internal Server Error while fetching the employees detail', error)
          })
        })
        .catch(function (reason) {
          console.log('Internal Server Error while fetching shop detail')
        })
    }

    $scope.editProfilePage = function (employee) {
      if ($rootScope.user && (!$rootScope.user.isEmployee || (($rootScope.user._id).toString() === (employee._id).toString()))) $state.go('brand.myuser', { userId: employee._id })
    }
  }])
