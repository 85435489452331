prismanoteApp.controller('inventoryProcessingModalController', ['$scope', '$uibModalInstance',
  function ($scope, $uibModalInstance) {

    $scope.toggleBtn = false;

    $scope.close = function () {
      $uibModalInstance.close(false)
    }

    $scope.continue = function(){ // continue
      console.log('--- continue!!');
      $uibModalInstance.close('continue')
      $scope.close('continue');
    }

    $scope.restart = function(){ // restart
      console.log('--- restart!!');
      $uibModalInstance.close('restart')
    }
  }
])
