prismanoteApp.controller('removeProductsModalController', ['$scope', '$uibModalInstance', 'brand',
  function ($scope, $uibModalInstance, brand) {
    $scope.brand = brand

    $scope.close = function (options) {
      $uibModalInstance.close(options)
    }

    $scope.cancel = function (reason) {
      $uibModalInstance.dismiss(reason)
    }
  }])
