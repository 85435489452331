prismanoteApp.controller('retailerCustomizeCompanyColumnsController', ['$scope', '$uibModalInstance', 'columns',
  function ($scope, $uibModalInstance, columns) {
    $scope.loader = false

    $scope.mycolumns = columns

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    $scope.closeModal = function () {
      $uibModalInstance.close($scope.mycolumns)
    }

    $scope.toggle = function (col) {
      col.isSelected.c = !col.isSelected.c
    }
  }])
