
prismanoteApp.controller('welcomeBrandController', ['$scope', '$rootScope', '$api', '$uibModal', '$translate', '$wholesaler',
  function ($scope, $rootScope, $api, $uibModal, $translate, $wholesaler) {
    $scope.getShopList = function () {
      $api.get('shops')

        .then(function (response) {
          $scope.loading = false
          $scope.shops = response.data.shops
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    // accordion
    $scope.oneAtATime = true

    $scope.groups = [
      {
        title: 'Dynamic Group Header - 1',
        content: 'Dynamic Group Body - 1'
      },
      {
        title: 'Dynamic Group Header - 2',
        content: 'Dynamic Group Body - 2'
      }
    ]

    $scope.items = ['Item 1', 'Item 2', 'Item 3']

    $scope.addItem = function () {
      var newItemNo = $scope.items.length + 1
      $scope.items.push('Item ' + newItemNo)
    }

    $scope.status = {
      isCustomHeaderOpen: false,
      isFirstOpen: false,
      isFirstDisabled: true
    }

    $scope.translateDiscoverSolutions = {
      nl: 'Ontdek oplossingen',
      en: 'Discover solutions',
      de: 'Lösungen entdecken',
      fr: 'Découvrez les solutions',
      es: 'Descubra soluciones'
    }
    $scope.selectedTestApp = $scope.translateDiscoverSolutions[$rootScope.language]

    $scope.showForm = false

    $scope.openLoginRegisterModal = function (mode) {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/login-modal.html',
        controller: 'loginModalController',
        resolve: {
          mode: function () {
            return mode
          }
        }
      })

      modalInstance.result.then(function (result) {
        $scope.initShoppingCart()
      }, function () {

      })
    }
  }])
