prismanoteApp.controller('retailerFinancialController', ['$scope', '$rootScope', '$retailer', '$api', '$timeout',
  function ($scope, $rootScope, $retailer, $api, $timeout) {
    $scope.loading = {
      outstanding: false,
      payment: false,
      revenue: false,
      expected: false
    }

    $scope.$on('changeSelectedShop', function () {
      $scope.getFinancialDashboard()
    })

    $scope.getFinancialDashboard = function () {
      console.log('This backend function does not exists anymore')
      return
      $scope.loading = {
        outstanding: true,
        payment: true,
        revenue: true,
        expected: true
      }

      $api.get('financial-dashboard', { shopSlug: $rootScope.selectedShop })
        	.then(function (response) {
          var dashboard = response.data.dashboard
          if (dashboard) {
            $scope.outstanding = dashboard.outStandingOrders
            $scope.loading.outstanding = false

            $scope.revenue = dashboard.revenue
            $scope.loading.revenue = false

            $scope.expectedRevenue = dashboard.expectedRevenue
            $scope.loading.expected = false

            $scope.paymentData = dashboard.nextPaymentData
            $scope.loading.payment = false
          }
        })
        .catch(function (reason) {
          console.error('Error while getting financial dashboard', reason)
          $scope.loading = {
            outstanding: false,
            payment: false,
            revenue: false,
            expected: false
          }
          $scope.outstanding = 0
          $scope.revenue = 0
          $scope.expectedRevenue = 0
          $scope.paymentData = null
          $scope.payment = 0
        })
    }
  }])
