prismanoteApp.controller('adminMergeSearchModalController', ['$scope', '$uibModalInstance', '$state', 'product', '$uibModal', '$rootScope', '$product',
    function ($scope, $uibModalInstance, $state, product, $uibModal, $rootScope, $product) {
        $scope.chosenProductId = product._id;
        console.log('----------------------------productproductproductproduct----->', product);
        // $scope.productId = product.product._id;

        console.log('----------------------------> ', product);
        $scope.getProductDetail = function () {
            var body = {
                productId: $scope.chosenProductId
            }
            $product.getProductDetailForAdminMerge(body).then(function (response) {
                console.log('response: ', response);
                $scope.product = response.data.data;
                if ($scope.product && $scope.product.brand && $scope.product.brand.isPoolArticleBrand) {
                    $scope.allowToMerge = false;
                } else {
                    $scope.allowToMerge = true
                }
            }).catch(function (error) {
                console.log('error: ', error);
            });
        }

        $scope.searchProduct = function (searchText) {

            try {
                if (searchText && searchText.length > 3) {
                    $scope.voohits = [];
                    $scope.loading = true
                    var data = {
                        searchText: searchText,
                        mode: 'search',
                        pageNumber: $scope.pageNumber,
                        limit: $scope.limit,
                        chosenProductId: $scope.chosenProductId
                    }
                    $product.getProducts(data).then(function (products) {
                        if (products.products && products.products.length) {
                            var newProducts = products.products;
                            console.log('newProducts: ', newProducts);
                            $scope.voohits = newProducts;
                            // $scope.products = $scope.products.concat(newProducts);
                        }

                        $scope.loading = false
                    }).catch(function (error) {
                        console.error('Error While Getting Products Based Search')
                        console.error(error)
                        $scope.loading = false
                    })
                }
            } catch (e) {
                console.log('searchProduct: ', e);
            }
        }

        if (product && product.productNumber) {
            try {
                $scope.searchValue = product.productNumber;
                $scope.searchProduct($scope.searchValue);
            } catch (e) {
                console.log('E: ', e);
            }
        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel')
        }

        $rootScope.cancelModal = function () {
            $uibModalInstance.dismiss('cancel')
        }

        $scope.adminMergeSuggestion = function (searchedProduct) {
            console.log('adminMergeSuggestion called');
            $scope.cancel();
            return;
            var body = {
                chosenProductId: $scope.product._id,
                searchedProductId: searchedProduct._id
            }

            $state.go('admin.merge-product', body);
            
            
        }

    }])
