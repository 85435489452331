prismanoteApp.controller(
  'shopDetailBrandController',
  ['$scope', '$rootScope', '$api', '$pdf', '$state', '$retailer', '$clientGroup', '$brand', '$purchaseOrder', 'prompt', '$window', '$language', '$uibModal', '$csv2json',
    function ($scope, $rootScope, $api, $pdf, $state, $retailer, $clientGroup, $brand, $purchaseOrder, prompt,$window, $language, $uibModal, $csv2json) {
      $scope.localdata = {
        searchClientGroup: ''
      }
      
      $scope.selectedTab;
      $scope.pageSize = '10'
      $scope.currentPage = 1
      $scope.updateClick = false
      $scope.callStatisticalDataApi = false
      $scope.stocklistLoading = true;
      $scope.stocklist = [];
      $scope.companyBrandDetails = [];
      $scope.tempShopDetails;
      $scope.mergeDataFlag = false
      $scope.showDates = false
      $scope.shopFavouriteSupplier = false
      $scope.disableMonthSelection = false
      $scope.activityData = []
      $scope.filter = {}
    $scope.year = moment().year()
    $scope.month = moment().month()
      $scope.monthList = [
        "JANUARY",
        "FEBRUARY",
        "MARCH",
        "APRIL",
        "MAY",
        "JUNE",
        "JULY",
        "AUGUST",
        "SEPTEMBER",
        "OCTOBER",
        "NOVEMBER",
        "DECEMBER"
      ];
      $scope.getYears = function () {
        var cYear = moment().year()
        //Define in which year we should start showing years
        var startYear = 2018
        $scope.years = []

        for (var i = startYear; i < cYear + 1; i++) {
          $scope.years.push(i)
        }
      }
      $scope.getMonths = function () {
        $scope.months = [
          {
            id: 0,
            name: $language.translate('JANUARY')
          },
          {
            id: 1,
            name: $language.translate('FEBRUARY'),
          },
          {
            id: 2,
            name: $language.translate('MARCH')
          },
          {
            id: 3,
            name: $language.translate('APRIL')
          },
          {
            id: 4,
            name: $language.translate('MAY')
          },
          {
            id: 5,
            name: $language.translate('JUNE')
          },
          {
            id: 6,
            name: $language.translate('JULY')
          },
          {
            id: 7,
            name: $language.translate('AUGUST')
          },
          {
            id: 8,
            name: $language.translate('SEPTEMBER')
          },
          {
            id: 9,
            name: $language.translate('OCTOBER')
          },
          {
            id: 10,
            name: $language.translate('NOVEMBER')
          },
          {
            id: 11,
            name: $language.translate('DECEMBER')
          }
        ]
      }
      $scope.getWeeks = function () {
        console.log('$scope.year::',$scope.year);
        
      var tWeek = $language.translate('WEEK')
      var weeks = moment().year($scope.year).isoWeeksInYear()
    
      $scope.weeks = []
    
      for (var i = 1; i < (weeks +1); i++) {
        $scope.weeks.push(
        {
          id: i,
          name: tWeek + ' ' + i
        }
        )
      }
      }
      $scope.getYears()
      $scope.getMonths()
      $scope.getWeeks()

     

      $scope.saveAngularFilterCurrentPageData = function (currentPage) {
        $scope.currentPage = currentPage;
        console.log('currentPage:@@@@@@@@@@::::::::',currentPage);
        cookieService.setCookie('servicesCurrentPage=' + currentPage, 1800)
      
      }
      
      $scope.saveAngularFilterPageSize = function (pageSize) {
        console.log('pageSize:::::::::',pageSize);
        $scope.pageSize = pageSize;
        cookieService.setCookie('servicesPageSize=' + pageSize, 31536000) 
      }

      $scope.filterChanged = function(value, time, tabSelected){
        console.log('value:: ', value);
        console.log('time:: ', time);
        $scope.timeSelected = time
        $scope.valueReceived = value
        if(time === 'year'){ $scope.year = value; }
        if(time === 'month'){ $scope.month = value; }
				var currentDate = new Date(new Date().setHours(23, 59, 59));
        console.log('currentDate ', currentDate);
        if (time === 'year') {
          $scope.filter.startDate = moment().year(value).startOf('year').toDate()
          $scope.filter.endDate = moment().year(value).endOf('year').toDate()
        }
        if (time === 'month') {
          $scope.filter.startDate = moment().year($scope.year).month(value).startOf('month').toDate()
          $scope.filter.endDate = moment().year($scope.year).month(value).endOf('month').toDate()
          if ($scope.filter.startDate < currentDate && $scope.filter.endDate > currentDate && $scope.filter.startDate < $scope.filter.endDate) {
            $scope.filter.endDate = new Date(new Date().setHours(23, 59, 59));
          }
        }
        if (time === 'week') {
          $scope.filter.startDate = moment().year($scope.year).week(value).startOf('week').toDate()
          $scope.filter.endDate = moment().year($scope.year).week(value).endOf('week').toDate()
        }

        if(time === 'date') {
          $scope.filter.period = 'custom'
        }
        console.log('$scope.filter.startDate%%%%%%',$scope.filter.startDate);
        console.log('$scope.filter.endDate*****',$scope.filter.endDate);
        }

        $scope.setFilter = function(period){
          $scope.filter.period = period;
          $scope.filter.endDate = new Date(new Date().setHours(23, 59, 59));
  
          if(period == 'day'){
            $scope.period = "day";
            $scope.filter.startDate = new Date(new Date().setHours(0, 0, 0));
          }
          if(period == 'week'){
            $scope.period = "week";
            $scope.filter.startDate = new Date(new Date(moment().startOf('week')).setHours(0, 0, 0)); 
          }
          if(period == 'month'){
            $scope.period = "month";
            $scope.filter.startDate = new Date(new Date(moment().startOf('month')).setHours(0, 0, 0));
          }
  
          if(period == 'year'){
            $scope.period = "year";
            $scope.filter.startDate = new Date(new Date(moment().startOf('year')).setHours(0, 0, 0));
          }
          console.log('$scope.filter.startDate#####',$scope.filter.startDate);
          console.log('$scope.filter.endDate$$$',$scope.filter.endDate);
          $scope.tabSelected('PRODUCTS_SOLD');
        }

        $scope.mergeData = function (data) {
          console.log('inside mergedata',data);
          console.log('$rootScope.currentShop._id::',$scope.shopDetail._id);
          console.log('$scope.company._id::',$scope.currentCompanyId);
          $scope.loading = true
          if (data == true) {
            $scope.mergeDataFlag = true
            var startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
            var endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
            $scope.filter.startDate = moment(startDate).format('YYYY-MM-DD')
            $scope.filter.endDate = moment(endDate).format('YYYY-MM-DD')
            var body = {
              shopId: $scope.shopDetail._id,
              companyId: $scope.currentCompanyId,
              startDate: $scope.filter.startDate ? $scope.filter.startDate + 'T00:00:00.000Z' : '',
              endDate: $scope.filter.endDate ? $scope.filter.endDate + 'T23:59:59.000Z' : '',
            }
            $brand.getMergeShopDetailsForBrandPortalWithSelectedTab(body).then(function (response) {
              // console.log('response:::!@#$%^',response);
              /* console.log('$scope.valueReceived',$scope.valueReceived);
              console.log('$scope.timeSelected::',$scope.timeSelected);
              $scope.filterChanged($scope.valueReceived,$scope.timeSelected) */
              // console.log('response.data.data.lastSoldItems:::',response.data.data.lastSoldItems);
              // console.log('condition:::',response.data.data.lastSoldItems && response.data.data.lastSoldItems.trnres.length > 0);
              if (response.data.data.lastSoldItems && response.data.data.lastSoldItems.trnres.length > 0) {
                var tempList = [];
              // tempList = response.data.data.lastSoldItems.trnres
              var totalAmount = 0
              response.data.data.lastSoldItems.trnres.forEach(function (item) {
                // console.log('item:::::',item);
                // console.log('item.productDetail.details.',item.details);
                totalAmount += (Math.ceil(item.details.quantity) * item.details.price * (1 + (item.details.priceVat/100)))
                // console.log('totalAmount::',totalAmount);
                //(Math.ceil(artikel.details.quantity) * artikel.details.price * (1 + (artikel.details.priceVat/100)))
              })

               tempList = [
                {
                  'data': response.data.data.lastSoldItems.trnres,
                  'total': totalAmount,
                  'open': true
                }]
              console.log('tempList::',tempList);
              tempList.forEach(function (item) {
                item.data.sort(function (a, b) {
                  return new Date(b.dateCreated) - new Date(a.dateCreated);
                });
              });
              $scope.loading = false
              var tempSoldItems = tempList
              $scope.lastSoldItems = tempSoldItems[0].data;
              $scope.soldItemTotal = 0
              $scope.soldItemTotal = tempSoldItems[0].total
              console.log('$scope.lastSoldItems::::',$scope.soldItemTotal);
              $scope.filterDataLength = $scope.lastSoldItems.length
              cookieService.getCookie('servicesCurrentPage')
               .then(function (currentPage) {
                 $scope.currentPage = currentPage
               })
               .catch(function () {
                 $scope.currentPage = 1
               })
              } else {
                $scope.updateClick = false
                $scope.loading = false
                $scope.lastSoldItems = [];
              }
              
            })
          } else {
            var startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
            var endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
            $scope.filter.startDate = moment(startDate).format('YYYY-MM-DD')
            $scope.filter.endDate = moment(endDate).format('YYYY-MM-DD')
            $scope.mergeDataFlag = false
            $scope.tabSelected('PRODUCTS_SOLD');
            $scope.filter.startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
            $scope.filter.endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
          }
        }

        $scope.getStockList = function () {  
          $api.post('v2/company/export-shopdetail-stocklist-for-brand-for-selected-tab', {
            shopId: $scope.shopDetail._id,
            companyId: $scope.currentCompanyId,
            selectedTab: 'STOCK_LIST',
            language: $rootScope.language,
            forCsv: true,
            isComingFromSupplier: false
          })
          .then(function(result){
            $scope.stockListCsv(result.data.data, 'SALES REPORT', 'Test', 'Semi-colon')
          })
          .catch(function(err){
            console.log('err ', err);
          })
        }

        $scope.stockListCsv = function (data, b, c, Separator) {
          var jsonForCsv = [];
          for(var i = 0; i < data.length; i++){
            var productNumberWithoutStartingDigits = data[i].productDetail.productNumber;
            if(data[i].productDetail.variants.brandStratingDigits){
              var Slength = data[i].productDetail.variants.brandStratingDigits.length;
              productNumberWithoutStartingDigits = productNumberWithoutStartingDigits.substring(Slength, productNumberWithoutStartingDigits.length);
            }
            var obj = {
              'Product Number': data[i].productDetail.productNumber,
              'Product Number Without Starting Digits': productNumberWithoutStartingDigits,
              'Article Number': data[i].articleNumber,
              'Brand': data[i].productDetail && data[i].productDetail.brand && data[i].productDetail.brand.name || '',
              'Product Name': data[i].productDetail[$rootScope.language].name,
              'Stock': data[i].stock,
              'MinStock': data[i].minStock,
              'ArticleGroup': data[i].productDetail.collection[$rootScope.language].name,
              'Purchase price': (parseFloat(data[i].purchasePrice).toFixed(2)).replace('.', ','),
              'Sales Price incl. VAT': (parseFloat(data[i].priceIncVat).toFixed(2)).replace('.', ','),
              // 'Selling days': data[i].salewrtdays,
              'Ownership': data[i].ownership
            }
            jsonForCsv.push(obj);
    
            if (i === (data.length - 1)) {
              var filename = 'Stocklist ' + $scope.shopDetail.name;
              $csv2json.JSONToCSVConvertor(jsonForCsv, b, c, Separator, filename)
              $scope.loadingCSV = false
            }
          }
        }

        $scope.exportToPdf = function (data) {
          $api.get('shop-name/' + $state.params.nameSlug)
          .then(function (response) {
            var shopDetailedName = response.data.shopDetail.name
            $scope.stockPdflistLoading = true
            $pdf.downloadPdf('v2/company/export-shopdetail-stocklist-for-brand-for-selected-tab', {
              shopId: $scope.shopDetail._id,
              companyId: $scope.currentCompanyId,
              selectedTab: 'STOCK_LIST',
              language: $rootScope.language,
              exportOption: data
            }, shopDetailedName + ' Stocklist.pdf')
            .then(function (data) {
              $scope.stockPdflistLoading = false
             // $scope.loading = false
              /* if ($scope.type !== 'document') {
                $scope.sendingMail = false
                $scope.mailSent = true
                toastr.success($language.translate('MAIL_SENT'))
              } */
            }).catch(function (error) {
              $scope.stockPdflistLoading = false
              console.log('error:@@@@@ ', error)
            //  $scope.stocklist = []
             // $scope.loading = false
             // $scope.stocklistLoading = false
            })
          })
         
    
        }

        $scope.exportSoldProductToPdf = function (data) {
          $scope.loading = true
          var startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
          var endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
          var startDateYMD = moment(startDate).format('YYYY-MM-DD')
          var endDateYMD = moment(endDate).format('YYYY-MM-DD')
          console.log('language:::',$rootScope.language);
          console.log('shop.companyShop.name',$scope.shop.companyShop.name);
          $api.get('shop-name/' + $state.params.nameSlug)
          .then(function (response) {
            var shopDetailedName = response.data.shopDetail.name
            $pdf.downloadPdf('v2/company/export-shopdetail-sold-products-for-selected-tab', {
              shopId: $scope.shopDetail._id,
              companyId: $scope.currentCompanyId,
              selectedTab: 'PRODUCTS_SOLD',
              startDate: startDateYMD ? startDateYMD + 'T00:00:00.000Z' : '',
              endDate: endDateYMD ? endDateYMD + 'T23:59:59.000Z' : '',
              language: $rootScope.language,
              exportOption: data
            }, shopDetailedName +  ' SoldProducts.pdf')
            .then(function  (data) {
              $scope.loading = false
              $scope.updateClick = false
              /* if ($scope.type !== 'document') {
                $scope.sendingMail = false
                $scope.mailSent = true
                toastr.success($language.translate('MAIL_SENT'))
              } */
            }).catch(function (error) {
              console.log('error:@@@@@ ', error)
              $scope.lastSoldItems = []
              $scope.loading = false
              $scope.updateClick = true
            })
      
          })
          
        }

        $scope.testcsvCollection = function (arr, b, c, Separator) {
          var jsonForCsv = [];
          for(var j = 0; j < arr.length; j++){
            var data = arr[j];
            var total = 0;
            for(var i = 0; i < data.length; i++){
              var purchasePrice = data[i].details.purchasePrice || 0;
              var totalPurchasePrice = (parseInt(data[i].quantity) || 0) *  (parseFloat(purchasePrice) || 0);
              purchasePrice = parseFloat(purchasePrice).toFixed(2) || 0;
              var priceIncVat = data[i].productDetail.priceIncVat || 0;
              priceIncVat = parseFloat(priceIncVat).toFixed(2);
              
              total += totalPurchasePrice || 0;
              totalPurchasePrice = totalPurchasePrice.toFixed(2) || 0;

              if($rootScope.language != 'en'){
                purchasePrice = purchasePrice.replace('.', ',');
                priceIncVat = priceIncVat.replace('.', ',');
                totalPurchasePrice = totalPurchasePrice.replace('.',',');
              }
              
              if(i == 0){
                // console.log(data[i]);
                jsonForCsv.push({
                  'Collection Name': (data[i].productDetail && data[i].productDetail.collection && data[i].productDetail.collection[$rootScope.language] && data[i].productDetail.collection[$rootScope.language].name) ? data[i].productDetail.collection[$rootScope.language].name : '',
                  // 'Collection Name': data[i].productDetail.collection[$rootScope.language].name,
                  'Article Number': '',
                  'Product Number': '',
                  'Sold': '',
                  'Purchase price': '',
                  'Vat': '',
                  'Sales Price incl. VAT': '',
                  'Stock': '',
                  'MinStock': '',
                  'Sold on/off': '',
                  'Total purchase price': ''
                })
              }
              var obj = {
                'Collection Name': '',
                'Article Number': data[i].productDetail.shopProduct.articleNumber,
                'Product Number': data[i].productDetail.productNumber,
                'Sold': parseInt(data[i].quantity),
                'Purchase price': purchasePrice,
                'Vat': data[i].details.priceVat,
                'Sales Price incl. VAT': priceIncVat,
                'Stock': data[i].productDetail.shopProduct.stock,
                'MinStock': data[i].productDetail.shopProduct.minStock,
                'Sold on/off': data[i].dateCreated,
                'Total purchase price': totalPurchasePrice
              }
              jsonForCsv.push(obj);
      
              if (i === (data.length - 1)) {
                // console.log('------------- if!');
                total = parseFloat(total).toFixed(2);
                if($rootScope.language != 'en'){
                  total = total.replace('.', ',');
                }
                // for(var k = 0; k < data.length; k++){
                //   total += data[k].productDetail.priceIncVat;
                //   // console.log(' ', total, ' ', data[k].productDetail.priceIncVat);
                // }
                // jsonForCsv.push({})
                jsonForCsv.push({
                  'Collection Name': '',
                  'Article Number': '',
                  'Product Number': '',
                  'Sold': '',
                  'Purchase price': '',
                  'Vat': '',
                  'Sales Price incl. VAT': '',
                  'Stock': '',
                  'MinStock': '',
                  'Sold on/off': '',
                  'Total purchase price': 'Total : ' + total
                })
                jsonForCsv.push({
                  'Collection Name': '',
                  'Article Number': '',
                  'Product Number': '',
                  'Sold': '',
                  'Purchase price': '',
                  'Vat': '',
                  'Sales Price incl. VAT': '',
                  'Stock': '',
                  'MinStock': '',
                  'Sold on/off': '',
                  'Total purchase price': ''
                })
                // console.log(jsonForCsv);
                if(j === (arr.length - 1)){
                  // console.log('final call ', i, ' ', j);
                  $csv2json.JSONToCSVConvertor(jsonForCsv, b, c, Separator)
                  $scope.loadingCSV = false
                }
              }
            }
          }
        }
    
        $scope.getQuantity = function(quantity){
          return parseInt(quantity);
        }
    
        $scope.testcsv = function (data, b, c, Separator) {
          console.log('---------- testcsv!');
          var jsonForCsv = [];
          var total = 0;
          for(var i = 0; i < data.length; i++){
            // console.log('-------- i ', i);
            // console.log(data[i]);
            var purchasePrice = data[i].purchasePrice || 0;
            var priceIncVat = data[i].priceIncVat || 0;
            priceIncVat = parseFloat(priceIncVat).toFixed(2) || 0;
            var totalPP = (parseInt(data[i].sold) || 0) * (parseFloat(purchasePrice).toFixed(2) || 0);
            purchasePrice = parseFloat(purchasePrice).toFixed(2);
            total += totalPP || 0;
            totalPP = totalPP.toFixed(2);
            if($rootScope.language != 'en'){
              purchasePrice = purchasePrice.replace('.', ',');
              priceIncVat = priceIncVat.replace('.', ',');
              totalPP = totalPP.replace('.', ',');
            }
            var collection = '';
            if(data[i] && data[i].collection && data[i].collection[$rootScope.language] && data[i].collection[$rootScope.language].name){
              collection = data[i].collection[$rootScope.language].name;
            }
            var obj = {
              'Article Number': data[i].articleNumber,
              'Product Number': data[i].productNumber,
              'Sold': parseInt(data[i].sold),
              'Purchase price': purchasePrice,
              'Vat': data[i].priceVat,
              'Sales Price incl. VAT': priceIncVat,
              'Stock': data[i].stock,
              'MinStock': data[i].minStock,
              'Sold on/off': data[i].soldOn,
              'Collection Name': collection,
              'Total purchase price': totalPP
            }
            jsonForCsv.push(obj);
    
            if (i === (data.length - 1)) {
              // console.log('------------- if!');
              total = parseFloat(total).toFixed(2);
              if($rootScope.language != 'en'){
                total = total.replace('.', ',');
              }
              // for(var k = 0; k < data.length; k++){
              //   total += parseFloat(data[k].priceIncVat) || 0;
              //   // console.log(' ', total, ' ', data[k].productDetail.priceIncVat);
              // }
              // jsonForCsv.push({})
              jsonForCsv.push({
                'Article Number': '',
                'Product Number': '',
                'Sold': '',
                'Purchase price': '',
                'Vat': '',
                'Sales Price incl. VAT': '',
                'Stock': '',
                'MinStock': '',
                'Sold on/off': '',
                'Collection Name': '',
                'Total purchase price': 'Total : ' + total
              })
              $csv2json.JSONToCSVConvertor(jsonForCsv, b, c, Separator)
              $scope.loadingCSV = false
            }
          }
        }

        $scope.getSaleRecords = function (data){
          console.log('----- getSaleRecords!!');
          $scope.loading = true
          var startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
          var endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
          var startDateYMD = moment(startDate).format('YYYY-MM-DD')
          var endDateYMD = moment(endDate).format('YYYY-MM-DD')
          // console.log('language:::',$rootScope.language);
          $api.post('v2/shop/get-sales-transaction-details', {
            shopId: $scope.shopDetail._id,
            companyId: $scope.currentCompany._id,
            selectedTab: 'PRODUCTS_SOLD',
            startDate: startDateYMD ? startDateYMD + 'T00:00:00.000Z' : '',
            endDate: endDateYMD ? endDateYMD + 'T23:59:59.000Z' : '',
            language: $rootScope.language,
            exportOption: data
          })
          .then(function  (result) {
            $scope.loading = false
            if(data == 'COLLECTION'){
              $scope.testcsvCollection(result.data, 'SALES REPORT', 'Test', 'Semi-colon')
            }else{
              $scope.testcsv(result.data, 'SALES REPORT', 'Test', 'Semi-colon')
            }
          }).catch(function (error) {
            console.log('error:@@@@@ ', error)
            $scope.lastSoldItems = []
            $scope.loading = false
            $scope.updateClick = true
          })
    
        }

        $scope.updateByDateRange = function(){
          $scope.updateClick = true
          $scope.stocklistLoading = true;
          $scope.loading = true
          var startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
          var endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
          $scope.filter.startDate = moment(startDate).format('YYYY-MM-DD')
          $scope.filter.endDate = moment(endDate).format('YYYY-MM-DD')
          if($scope.filter.startDate && $scope.filter.endDate) {
            $scope.tabSelected('PRODUCTS_SOLD');
          } else {
            $scope.stocklistLoading = false;
            $scope.loading = false
          }
          $scope.filter.startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
          $scope.filter.endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
        };

      // function InitializeSocket() {
      //   try {
      //     SocketService.on('company:' + $state.params.nameSlug + $rootScope.currentCompany.nameSlug + ':shop:added:comment:detailpage', function (data) {
      //       if ($scope.shopcompanyremarks && $scope.shopcompanyremarks.remarks && $scope.shopcompanyremarks.remarks.length && $scope.shopcompanyremarks.remarks[0]) {
      //         $scope.shopcompanyremarks.remarks.push(data)
      //       } else {
      //         $scope.shopcompanyremarks = {
      //           remarks: [data]
      //         }
      //       }
      //       $scope.autoScrollcomment()
      //     })
      //   } catch (catcherr) {
      //     console.log('catcherr: ', catcherr)
      //   }
      // }

      $scope.bestSellingProducts = []

      $scope.fetchShopDetailByNameSlug = function () {
        console.log('--------- fetchShopDetailByNameSlug!');
        console.log('$state.params::::::',$state.params, $rootScope.currentCompany._id);
        
        $scope.loading = true;
        $api.get('shops-for-brand/' + $state.params.nameSlug, { companyId: $rootScope.currentCompany._id })
          .then(function (response) {
            $scope.loading = false;
            console.log('FINAL SHOP DETAIL: ====> ', response.data);
            $scope.shopDetail = response.data.shop; // Due to time limit (Made another query in getShopDetailsForBrandPortal, which was removed as it was not used 17-03-2021 as it seemed to be replaced by: getShopDetailsForBrandPortalWithSelectedTab)
            $scope.currentCompanyId = $rootScope.currentCompany._id
            $scope.getComment() // Fetching comment of the shop and company
            $scope.getInternalCommentOfSupplier() // Fetching internal comment of supplier

            $scope.getNoShopInClientGroupForBlock($scope.currentCompanyId, $scope.shopDetail._id)
            $scope.getBestSoldItems()
            $scope.tabSelected('CHAT')
            $scope.getCompanyShopBrandDetails()
            // if ($rootScope.currentCompany.nameSlug) InitializeSocket()
          })
          .catch(function (reason) {
            $scope.loading = false;
            console.log(reason)
          })
      }

      $scope.showDate = function(data) {
        console.log('data::::',data);
        if (data) {
          $scope.month = undefined
          $scope.disableMonthSelection = true
        } else {
          $scope.disableMonthSelection = false
        }
        
        $scope.showDates = data
      }

      $scope.showSupplierSubTab = function(event, index) {
        if(event.$parent && event.$parent.tabset && event.$parent.tabset.active && event.$parent.tabset.active == index){
          return true;
        }
        return false;
      }
      
      /* $scope.saveCurrentPage = function (currentPage) {
        $scope.currentPage = currentPage;
        console.log('currentPage:::::::::',currentPage);
      //  cookieService.setCookie('servicesCurrentPage=' + currentPage, 1800)
        $scope.tabSelected($scope.selectedTab)
      } */

      $scope.saveCurrentPage = function (currentPage) {
        $scope.currentPage = currentPage;
        console.log('currentPage:::::::::',currentPage);
        var startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
        var endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
        $scope.filter.startDate = moment(startDate).format('YYYY-MM-DD')
        $scope.filter.endDate = moment(endDate).format('YYYY-MM-DD')
       // cookieService.setCookie('servicesCurrentPage=' + currentPage, 1800)
        $scope.tabSelected($scope.selectedTab)
        $scope.filter.startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
        $scope.filter.endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
      }

      $scope.savePageSize = function (pageSize) {
        console.log('pageSize::!!!!!!!!!!!!:::::::',pageSize);
        $scope.pageSize = pageSize;
        var startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
        var endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
        $scope.filter.startDate = moment(startDate).format('YYYY-MM-DD')
        $scope.filter.endDate = moment(endDate).format('YYYY-MM-DD')
       // cookieService.setCookie('servicesPageSize=' + pageSize, 31536000) 
        $scope.tabSelected($scope.selectedTab)
        $scope.filter.startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
        $scope.filter.endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
      }

     /*  $scope.savePageSize = function (pageSize) {
        console.log('pageSize:::::::::',pageSize);
        $scope.pageSize = pageSize;
        // cookieService.setCookie('servicesPageSize=' + pageSize, 31536000) 
        $scope.tabSelected($scope.selectedTab)
      } */

      $scope.getCompanyShopBrandDetails= function() {
        console.log('called');
        $scope.companyBrandDetails = []
        var body = {
          shopId: $scope.shopDetail._id,
          companyId: $scope.currentCompanyId
        }
        console.log('body####',body);
        $brand.getCompanyShopBrands(body).then(function (response) {
          console.log('response:::::',response);
          $scope.companyBrandDetails = response.data.data.companyBrands
  
        })
      }

      $scope.openActivity = function (id) {
        $state.go('brand.activity-item', { id: id })
      }

      $scope.tabSelected = function (data) {
        //Broadcast or emit your event here.
        console.log('in tabSelected',data);
        /* 
        var startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
            var endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
            $scope.filter.startDate = moment(startDate).format('YYYY-MM-DD')
            $scope.filter.endDate = moment(endDate).format('YYYY-MM-DD')
        console.log('$scope.startDate:::!!!!',$scope.filter.startDate);
        console.log('$scope.endDate::!!!',$scope.filter.endDate); */
        $scope.selectedTab = data
        var body = {
          shopId: $scope.shopDetail._id,
          companyId: $scope.currentCompanyId,
          selectedTab: $scope.selectedTab,
          limit: $scope.pageSize,
          pageNumber: $scope.currentPage,
          startDate: $scope.filter.startDate ? $scope.filter.startDate + 'T00:00:00.000Z' : '',
          endDate: $scope.filter.endDate ? $scope.filter.endDate + 'T23:59:59.000Z' : '',
        }
        console.log('body:::::::::::::',body);
        console.log('$scope.selectedTab ', $scope.selectedTab);
        if ($scope.selectedTab == "STOCK_LIST" || $scope.selectedTab == "AVAILABILITY" || $scope.selectedTab == "CHAT" || $scope.selectedTab == "PRODUCTS_SOLD") {
         /*  $brand.getShopDetailsForBrandPortalWithSelectedTab(body).then(function (response) {
            console.log('ressssponse:::',response);
          }) */
          $scope.loading = true
          $scope.stocklistLoading = true;
          $brand.getShopDetailsForBrandPortalWithSelectedTab(body).then(function (response) {
            
            $scope.stocklistLoading = false;
            $scope.loading = false
            $scope.stocklist = []
            $scope.availability = [];
            $scope.lastSoldItems = [];
            if (response && response.data && response.data.data) {
              var shopDetails = response.data.data
              console.log('shopDetails:::',shopDetails);
              // SHOP DETAIL
              if (shopDetails.shop) {
                $scope.shop = shopDetails.shop;
                
                console.log('$scope.shop',$scope.shop);
                console.log('$scope.shop.length',$scope.shop._id);
                console.log('shop detail 533: ', $scope.shop);
                if ($scope.shop._id) {
                  $scope.tempShopDetails = $scope.shop
                  $rootScope.currentShop = $scope.shop
                  $rootScope.currentShop["portal"] = "company"
                } else if($scope.tempShopDetails){
                  $scope.shop = $scope.tempShopDetails
                  $rootScope.currentShop = $scope.shop
                  $rootScope.currentShop["portal"] = "company"
                }
              }
  
              // STOCK LIST
              if (shopDetails.stocklist && shopDetails.stocklist.shopres && shopDetails.stocklist.shopres.length) {
                $scope.stocklist = shopDetails.stocklist.shopres
                console.log('$scope.stocklist',$scope.stocklist);
                $scope.stocklistLength = shopDetails.stocklist.count
                console.log('$scope.stocklistLength',$scope.stocklistLength);
              }
              if (!shopDetails.stocklist || !shopDetails.stocklist.shopres || shopDetails.stocklist.shopres.length == 0){
                $scope.stocklistLoading = false;
              }

                // LAST SOLD ITEMS
                if (shopDetails.lastSoldItems && shopDetails.lastSoldItems.trnres && shopDetails.lastSoldItems.trnres.length) {
                  $scope.lastSoldItems = shopDetails.lastSoldItems.trnres
                }
                if ($scope.selectedTab == "STOCK_LIST" || $scope.selectedTab == "PRODUCTS_SOLD") {
                  if (shopDetails.shopData) {
                    $scope.shopFavouriteSupplier = true
                  } else {
                    $scope.shopFavouriteSupplier = false
                  }
                }

                if (shopDetails.stocklist && shopDetails.stocklist.length == 0) {
                  $scope.commonShopBrands = []
                  if (response.data && response.data.brandList && response.data.brandList.shopBrands.length > 0) {
                    $scope.commonShopBrands = response.data.brandList.shopBrands
                    console.log('$scope.commonShopBrands::',$scope.commonShopBrands);
                  }
                }

                if (!shopDetails.stocklist || !shopDetails.stocklist.shopres || shopDetails.stocklist.shopres.length == 0) {
                  $scope.stocklistLoading = false;
                }

                 // AVAILABILITY
              if (shopDetails.availability && shopDetails.availability.length) {
                console.log('shopDetails.availability::::',shopDetails.availability);
                $scope.availability = shopDetails.availability;
                isEmployeeAvailableAnyTime();
              }
  
              // LAST SOLD ITEMS
/*               if (shopDetails.lastSoldItems && shopDetails.lastSoldItems.trnres && shopDetails.lastSoldItems.trnres.length) {
                var tempList = [];
                shopDetails.lastSoldItems.trnres.forEach(function (item) {
                  var date = new Date(item.dateCreated);
                  var year = date.getFullYear();
                  var month = date.getMonth();
                  if(tempList.length == 0){
                    tempList.push(
                      {
                        'year' : year,
                        'month' : month,
                        'data' : [item],
                        'total' : item.productDetail.priceIncVat,
                        'open' : true
                      }
                    );
                  }else{
                    var index = tempList.findIndex(function(obj){ return (obj.year === year && obj.month === month)});
                    if(index != -1){
                      tempList[index].data.push(item);
                      tempList[index].total += item.productDetail.priceIncVat;
                    }else {
                      tempList.push(
                        {
                          'year' : year,
                          'month' : month,
                          'data' : [item],
                          'total' : item.productDetail.priceIncVat,
                          'open' : false
                        }
                      );
                    }
                  }
                })
                tempList.forEach(function (item) {
                  item.data.sort(function(a,b){
                    return new Date(b.dateCreated) - new Date(a.dateCreated);
                  });
                });
                $scope.lastSoldItems = tempList;
                if($scope.lastSoldItems) {
                  $scope.dataLength = shopDetails.lastSoldItems.count
                  if($scope.dataLength > 0) {
                    $scope.updateClick = false
                  } else {
                    $scope.updateClick = true
                  }
                }
                
  
              } */
  
             
                 
             /*  cookieService.getCookie('servicesCurrentPage')
                  .then(function (currentPage) {
                    $scope.currentPage = currentPage
                  })
                  .catch(function () {
                    $scope.currentPage = 1
                  }) */
              
            }
          }).catch(function (error) {
            $scope.stocklistLoading = false;
            $scope.loading = false
            console.log('error: ', error)
          })
        }
        if ($scope.selectedTab == "ACTIVITY") {
          $scope.activityData = []
          $brand.getActivitySelectedTab(body).then(function (response) {
            console.log('activities res::::',response);
            $scope.activityData = response.data.data.activityData
          })
        }
    };

      $scope.getNoShopInClientGroupForBlock = function (company_id, shop_id) {
        // get current company specific all client groups
        console.log('------ getOwnClientGroups ----------- ', company_id, shop_id)
        $clientGroup.getNoShopInClientGroupForBlock(company_id, shop_id)
          .then(function (clientGroups) {
            console.log('client Groups', clientGroups)
            $scope.clientGroups = clientGroups.data
          })
          .catch(function (error) {
            console.error('Error ===>', error)
          })
      }

      $scope.searchClientGroup = function (str) {
        console.log('searchClientGroup --> ');
        console.log('searchClientGroup --> 1: ', str);
        return searchItems(str)
      }

      var searchItems = function (str) {
        console.log('searchClientGroup: ', str, $scope.clientGroups);
        $scope.tempName = str

        var matches = []

        var clientGroups = $scope.clientGroups

        var clientGroupsLength = clientGroups.length

        if (clientGroups && clientGroupsLength > 0) {
          clientGroups.forEach(function (item) {
            if ((item && item.name) && ((item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) || (item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0))) {
              matches.push(item)
            }
          })
        }

        if (matches.length < 1) {
          matches.push({ name: 'Add New Client Group as "' + $scope.tempName + '" !' })
        }
        return matches
      }

      $scope.clientGroupSelected = function (selected) {
        // Selected Client Group
        console.log('-------- clientGroupSelected ---------', selected)
        // Now have to add selected client group in the parent Shop
        if (selected && selected.originalObject && selected.originalObject._id) {
          var clientGroupsId = selected.originalObject._id

          if ($scope.shopsClientGroups && $scope.shopsClientGroups.length && $scope.shopsClientGroups[0]._id) $scope.removeShopFromClientGroup($scope.shop._id, $scope.shopsClientGroups[0]._id)

          $scope.addShopInClientGroup(clientGroupsId, $scope.shop._id)
        } else {
          $state.go('brand.clientgroups')
        }
      }

      $scope.addShopInClientGroup = function (clientGroups_id, shop_id) {
        console.log(' ---------- addShopInClientGroup ----------- ', clientGroups_id, shop_id)

        $clientGroup.addShopInClientGroup(clientGroups_id, shop_id, $rootScope.currentCompany._id)
          .then(function (response) {
            console.log('Response ====>', response)
            $scope.getShopClientGroups(shop_id)
          })
          .catch(function (error) {
            console.error('Error ====>', error)
          })
        // Have to add this shop id to this selected client group
      }

      $scope.getShopClientGroups = function (shop_id) {
        // console.log(" ---------- getShopClientGroups ----------- ", shop_id);
        $clientGroup.getShopClientGroups(shop_id, $rootScope.currentCompany._id).then(function (response) {
          // console.log("get shop client groups", response);
          // Respose as a shop blocking client Groups
          $scope.shopsClientGroups = response && response.data ? response.data : []
        }).catch(function (error) {
          console.error('Error in getting shop blocking client groups', error)
        })
      }

      $scope.removeShopFromClientGroup = function (shopId, clientGroup_id) {
        $clientGroup.removeShopFromClientGroups(shopId, clientGroup_id, $rootScope.currentCompany._id)
          .then(function (response) {
            $scope.getShopClientGroups(shopId)
          })
          .catch(function (error) {
            console.error('error', error)
          })
      }

      $scope.getComment = function () {
        if ($scope.shopDetail && $scope.shopDetail._id && $scope.currentCompanyId) {
          var body = {
            shopId: $scope.shopDetail._id,
            companyId: $scope.currentCompanyId
          }

          $brand.getCommentofCompanyOrShop(body).then(function (response) {
            // console.log('getComment response: ', response);
            $scope.shopcompanyremarks = response.data
          }).catch(function (error) {
            console.log('error: ', error)
          })
        }
      }

      // Getting internal comment of supplier
      $scope.getInternalCommentOfSupplier = function () {
        if ($scope.shopDetail && $scope.shopDetail._id && $scope.currentCompanyId) {
          var body = {
            shopId: $scope.shopDetail._id,
            companyId: $scope.currentCompanyId
          }
          $brand.getInternalCommentOfSupplier(body).then(function (response) {
            $scope.internalComment = ''
            if (response && response.data && response.data.supplierInternalComment && response.data.supplierInternalComment.shops && response.data.supplierInternalComment.shops.internalComment) {
              $scope.internalComment = response.data.supplierInternalComment.shops.internalComment
            }
            if (response && response.data && response.data.shopCompany) {
              console.log('response.data.shopCompany:::::',response.data.shopCompany);
              $scope.callStatisticalDataApi = true
              $scope.shopCurrentCompanyDetail = response.data.shopCompany // Due to high priority issue did it here
            } else {
              $scope.callStatisticalDataApi = false
            }
          }).catch(function (error) {
            console.log('error: ', error)
          })
        }
      }

      function isEmployeeAvailableAnyTime() {
        for (var i = 0; i < $scope.availability.length; i++) {
          $scope.availability[i].isEmployeeAvailableAnyTime = false;
          var userAvailability = $scope.availability[i].availability;
          if (userAvailability && userAvailability.length) {
            for (var j = 0; j < userAvailability.length; j++) {
              if (userAvailability[j].morning || userAvailability[j].afternoon) {
                $scope.availability[i].isEmployeeAvailableAnyTime = true;
                break;
              }
            }
          }
        }
      }

      // Adding a comment so retailer will get notification for the same
      $scope.AddComment = function (comment) {
        if ($scope.shop && $scope.shop._id && $scope.currentCompanyId && comment) {
          // $scope.updateThePurchaseOrder($scope.shop);
          var body = {
            shopId: $scope.shop._id,
            shopSlug: $scope.shop.nameSlug,
            companySlug: $rootScope.currentCompany.nameSlug,
            companyId: $scope.currentCompanyId,
            eType: 'company',
            remark: comment
          }

          console.log('body: ', body)
          $brand.addCommentofCompanyOrShop(body).then(function (response) {
            console.log('response: ', response)
            $scope.currentremark = ''
            $('#currentremark').val('')
            $scope.getComment()
          }).catch(function (error) {
            $scope.currentremark = ''
            $('#currentremark').val('')
            console.log(err)
          })
        }
      }

      $scope.EditCommentMode = function (remark, index) {
        for (var i = 0; i < $scope.shopcompanyremarks.remarks.length; i++) {
          $scope.shopcompanyremarks.remarks[i].isEditMode = false
        }
        $scope.shopcompanyremarks.remarks[index].isEditMode = true
        $scope.editRemarkDetail = remark
      }

      $scope.UpdateComment = function (comment, index) {
        if (comment) {
          $scope.editRemarkDetail.remark = comment
          $scope.shopcompanyremarks.remarks[index].isEditMode = false
          console.log('$scope.editRemarkDetail: ', $scope.editRemarkDetail)
          var body = {
            shopcompanyRemarkId: $scope.shopcompanyremarks._id,
            remarkId: $scope.shopcompanyremarks.remarks[index]._id,
            remark: comment
          }
          console.log('body: ', body)
          $brand.editCommentofCompanyOrShop(body).then(function (response) {
            console.log('response: ', response)
            $scope.currentremark = ''
            $('#currentremark').val('')
            // $scope.getComment();
          }).catch(function (error) {
            $scope.currentremark = ''
            $('#currentremark').val('')
            console.log(error)
          })
        } else {
          $scope.editRemarkDetail = ''
          $scope.shopcompanyremarks.remarks[index].isEditMode = false
        }
      }

      $scope.addInternalCommnetOfSupplier = function (comment) {
        console.log('addInternal Comment of supplier: ', comment, $scope.shop._id, $scope.currentCompanyId)
        if (comment && $scope.shop && $scope.shop._id && $scope.currentCompanyId) {
          var body = {
            shopId: $scope.shop._id,
            companyId: $scope.currentCompanyId,
            internalComment: comment
          }
          console.log('body: ', body)
          $brand.addInternalCommentOfSupplier(body).then(function (response) {
            // console.log('response: ', response);
            $scope.isMessage = true
            $scope.showMessage = 'Comment updated succesfully'
            setTimeout(function () {
              $scope.isMessage = false
            }, 2000)
          }).catch(function (error) {
            $scope.isMessage = true
            $scope.showMessage = 'Something went wrong. Comment not updated'
            console.log(error)
            setTimeout(function () {
              $scope.isMessage = false
            }, 2000)
          })
        }
      }

      // Whenever adding comment to the then we have add new line when pressed (SHIFT + ENTER) and if only enter then add Comment
      $scope.addCommentKeyUp = function (event, currentremark) {
        if (event.keyCode === 13) {
          if (event.shiftKey) return false
          else $scope.AddComment(currentremark)
        }
      }

      $scope.getThePurchaseOrderList = function () {
        var body = {
          shopId: $scope.shop._id,
          shopSlug: $scope.shop.nameSlug,
          companyId: $rootScope.currentCompany._id,
          companySlug: $rootScope.currentCompany.nameSlug
        }
        // console.log('getPurchase ORer list: ', body);
        $purchaseOrder.getPurchseOrderListByShopOfCompanyShopDetail(body).then(function (response) {
          $scope.purchase_orders = response.data;
          var tempList = [];
          $scope.purchase_orders.forEach(function (item) {
            var date = new Date(item.createdDate);
            var year = date.getFullYear();
            var month = date.getMonth();
            if(tempList.length == 0){
              tempList.push(
                {
                  'year' : year,
                  'month' : month,
                  'data' : [item],
                  'total' : item.purchasePriceExVat,
                  'open' : true
                }
              );
            }else{
              var index = tempList.findIndex(function(obj){ return (obj.year === year && obj.month === month)});
              if(index != -1){
                tempList[index].data.push(item);
                tempList[index].total += item.purchasePriceExVat;
              }else {
                tempList.push(
                  {
                    'year' : year,
                    'month' : month,
                    'data' : [item],
                    'total' : item.purchasePriceExVat,
                    'open' : false
                  }
                );
              }
            }
          })
          tempList.forEach(function (item) {
            item.data.sort(function(a,b){
              return new Date(b.createdDate) - new Date(a.createdDate);
            });
          });
          $scope.purchase_orders = tempList;
        }).catch(function (error) {
          console.log('something went wrong while fetching purchase order list')
        })
      }

      // Remove shop from company
      $scope.removeShopFromTheCompany = function () {
        try {
          prompt({
            title: 'Delete the shop from the company',
            message: 'Are you sure you want to delete the shop from the company?',
            buttons: [
              { label: $language.translate('YES'), primary: true, id: 0 },
              { label: $language.translate('CANCEL'), primary: false, cancel: true, id: 1 }
            ]
          }).then(function (value) {
            if (value.id === 0) {
              var body = {
                shopId: $scope.shop._id,
                companyId: $rootScope.currentCompany._id
              }
              $scope.loading = true
              $brand.removeShopFromTheCompany(body).then(function (response) {
                $scope.loading = false
                $state.go('brand.shops')
              }).catch(function (error) {
                console.log('error: ', error)
                $scope.loading = false
              })
            }
          }).catch(function (catcherror) {
            console.log('catcherror: ', catcherror)
          })
        } catch (catcherr) {
          console.log('catcherrr: ', catcherr)
        }
      }

      // addShopCompanyDeleteSuggestion
      $scope.addShopDeleteSuggestion = function () {
        prompt({
          title: 'Suggesting admin to delete this shop',
          message: 'Are you sure you want to suggest admin to delete this shop',
          buttons: [
            { label: $language.translate('YES'), primary: true, id: 0 },
            { label: $language.translate('CANCEL'), primary: false, cancel: true, id: 1 }
          ]
        }).then(function (value) {
          if (value.id === 0) {
            var body = {
              shopId: $scope.shop._id,
              companyId: $scope.currentCompanyId,
              eType: 'shop_suggestion'
            }
            $scope.loading = true
            $brand.addShopCompanyDeleteSuggestion(body).then(function (response) {
              $scope.loading = false
              $state.go('brand.shops')
            }).catch(function (error) {
              console.log('error: ', error)
              $scope.loading = false
            })
          }
        })
      }

      // If shop is using product(BRAND) without dealer aggrement then company can report on it
      $scope.misUseBrand = function (brand) {
        var body = {
          shopId: $scope.shop._id,
          brandId: brand._id,
          favoriteSupplierId: brand.favoriteSupplier._id,
          isMisUse: brand.favoriteSupplier.isMisUse,
          companyId: $rootScope.currentCompany._id
        }
        // console.log('body: ', body);
        $scope.loading = true
        $brand.changeReportMisUseBrand(body).then(function (response) {
          $scope.loading = false
          // console.log('change successfully');
          $scope.alert = {
            type: 'success',
            msg: 'Updated Succesfully'
          }
        }).catch(function (error) {
          console.log('error: ', error)
          $scope.loading = false
          $scope.alert = {
            type: 'danger',
            msg: 'Internal Server Error'
          }
        })
      }

      $scope.autoScrollcomment = function () {
        setTimeout(function () {
          $('#messagewindow').animate({
            scrollTop: $('#messagewindow')[0].scrollHeight
          }, 0)
        }, 200)
      }

      $scope.getPurchaseOrderAndRepairWhichHasRemarks = function () {
        var body = {
          shopId: $scope.shop._id,
          companyId: $rootScope.currentCompany._id
        }
        $brand.getPurchaseOrderAndRepairWhichHasRemarks(body).then(function (response) {
          console.log('getPurchaseOrderAndRepairWhichHasRemarks: ', response)
          $scope.purchaseOrdersAndRepairs = []
          $scope.repairs = []
          if (response && response.data && response.data.data) {
            var data = response.data.data;
            if (data && data.orders && data.orders.length) $scope.purchaseOrdersAndRepairs = data.orders;
            if (data && data.repairs && data.repairs.length) $scope.repairs = data.repairs;
          }
          if (response && response.data && response.data.data && response.data.data.length) $scope.purchaseOrdersAndRepairs = response.data.data
        }).catch(function (error) {
          console.log('error: ', error)
        })
      }

      $scope.purchaseOrRepairOrder = function (order) {
        if (order.type === 'PURCHASE_ORDER') {
          $state.go('brand.purchase-order', { orderNo: order.number })
        } else if (order.type === 'REPAIR') {
          $state.go('brand.service-item', { id: order._id })
        }
      }

      $scope.getBestSoldItems = function (selectedTab, data) {
        console.log('--------- getBestSoldItems!');
        var endDate, startDate;
        if(data && data.endDate){ endDate = data.endDate; }
        if(data && data.startDate){ startDate = data.startDate; }
        if(!endDate) { endDate = new Date(new Date().setHours(23, 59, 59)); }
        if(!startDate) { 
          var date = new Date();
          var value = date.getFullYear();
          startDate = moment().year(value).startOf('year').toDate()
        }
        var filters = {
          suppliers: [],
          language: $rootScope.language,
					collectionWiseSwitch: $scope.collectionWiseSwitch,
          endDate: endDate ? endDate : '',
          startDate: startDate ? startDate : '',
        }
        filters.suppliers.push($rootScope.currentCompany._id)
        console.log('$scope.callStatisticalDataApi',$scope.callStatisticalDataApi);
      

        if($scope.callStatisticalDataApi == true) {
          console.log('$scope.shop._id$scope.shop._id',$scope.shop._id);
          // $scope.loading = true;
          return $api.get('v2/shop/sold-productsnew/' + $scope.shop._id,
            {
              filters: filters,
              companyId: $rootScope.currentCompany._id,
              selectedTab : selectedTab,
              nameSlug: $rootScope.currentCompany.nameSlug
            }
          )
            .then(function (response) {            
              $scope.loading = false;
              if (response && response.data && response.data.result && response.data.result.soldProductsBasedOnVariantNumber) {
                $scope.bestSellingProducts = response.data.result.soldProductsBasedOnVariantNumber
              }
            })
            .catch(function (error) {
              $scope.loading = false;
              console.log('error: ', error)
            })
        }
       
      }

      $scope.redirectToWeb = function (url) {
        var protocol = url.substring(0, 4);
        // console.log('redirectToWeb: ', url, protocol);
        if (protocol.toLowerCase() !== 'http') {
          url = 'http://' + url;
        }
        // console.log('redirectToWeb: ', url, protocol);
        $window.location.href = url;
      }

      $scope.openRetailerInvitationModal = function () {
        console.log('openRetailerInvitationModal called');
        try {
          var modalInstance = $uibModal.open({
            templateUrl: '../views/modal/invite-retailer-for-recommendation-modal.html',
            controller: 'inviteRetailerForRecommendationModalController',
            size: 'lg',
            resolve: {
              shopId: function () {
                return $scope.shop._id;
              }
            }
          });

          modalInstance.result.then(function (result) {
            if (result && result !== 'cancel') {
              inviteRetailerForRecommendationOrder(result);
            }
          }, function (error) {
            console.log('Something went wrong while opening modal 123: ', error);
          });
        } catch (e) {
          console.log('Something went wrong while opening modal: ', e);
        }
      }

      function inviteRetailerForRecommendationOrder(email) {
        var body = {
          email: email,
          language: $rootScope.language || 'en',
          shop: {
            _id: $scope.shop._id,
            name: $scope.shop.name,
            nameSlug: $scope.shop.nameSlug,
          },
          company: {
            _id: $rootScope.currentCompany._id,
            name: $rootScope.currentCompany.name,
            nameSlug: $rootScope.currentCompany.nameSlug,
          }
        }
        console.log('Yes, body: ', body);
        $brand.inviteRetailerForRecommendationOrder(body).then(function (response) {
          console.log('inviteRetailerForRecommendationOrder: response: ', response);
        }).catch(function (error) {
          console.log('Something went wrong while opening modal: ', error);
        });
      }

      $scope.createPurchaseOrder = function () {
        $rootScope.shopDetails = {
          _id: $scope.shopDetail._id,
          name: $scope.shopDetail.name,
          nameSlug: $scope.shopDetail.nameSlug,
          address: $scope.shopDetail.address,
        }
        $api.get('getDefaultPurchaseOrderNo?companyId='+ $rootScope.currentCompany._id).then(function (response) {
          $rootScope.isPurchaseOrderNew = true;
          $state.go('brand.purchase-order', { orderNo: response.data.purchaseOrderNumber })
        }).catch(function (reason) {
          console.error('Error while getting default product number', reason)
        })
      }

      $scope.createRepair = function () {
        console.log('name::', $scope.shopDetail.name);
        console.log('nameSlug::', $scope.shopDetail.nameSlug);
      //  $brand.shopNameAutoFill($scope.shopDetail.name)
        $state.go('brand.new-service', { name: $scope.shopDetail.name })

      }

      $scope.createActivity = function () {
        console.log('name::', $scope.shopDetail.name);
        console.log('nameSlug::', $scope.shopDetail.nameSlug);
      //  $brand.shopNameAutoFill($scope.shopDetail.name)
        $state.go('brand.new-activity', { name: $scope.shopDetail.name })

      }
    }
  ]
)