prismanoteApp.controller('usersController', ['$scope', '$rootScope', '$api', '$stateParams', '$uibModal', '$state', 'prompt', '$admin',
  function ($scope, $rootScope, $api, $stateParams, $uibModal, $state, prompt, $admin) {
    $scope.loading = true
    $scope.totalDisplayed = 20
    $scope.isUpdate = false
    $scope.counts = 0
    $scope.newuser = []
    $scope.dates = {
      date1: false,
      date2: false,
      paydate: false
    }

    $scope.openDate = function (date) {
      $scope.dates[date] = !$scope.dates[date]
    }

    $scope.notActiveuser = function(){
          $scope.users= $scope.newuser
    }

    $scope.getUsers = function () {
      var params = {
        sort: { lastLogin: 'desc' }
      }
      if ($stateParams.userType != '' && typeof $stateParams.userType !== 'undefined') {
        localStorage.setItem('userType', $stateParams.userType)
      }

      $api.get('users', params)

        .then(function (response) {
          $scope.users = []
          $scope.notActivecount = []
          var userData = response.data.users
          var notActivecount1 = response.data.notActivecount;
          var getLocalState = localStorage.getItem('userType')

          if (getLocalState != null && typeof getLocalState !== 'undefined') {
            angular.forEach(userData, function (val) {
              if (val.role == getLocalState) {
                $scope.users.push(val)
              }
            })
          } else {
            angular.forEach(userData, function (val) {
              var isFound = false

              $('#userList').find('li').find('a').each(function () {
                if ($(this).html() != null && val.firstName != null && $(this).html().toLowerCase().split(' ')[0] == val.firstName.toLowerCase()) {
                  val.Online = true
                  $scope.users.push(val)
                  isFound = true
                }
              })

              if (!isFound) {
                $scope.users.push(val)
              }
              if (!isFound && val.loginCount == 0) {
                  $scope.counts++
                  $scope.newuser.push(val)
              }
              $scope.alert = {
                type: 'danger',
                msg: 'There are some users need to be checked today'
            }
              
            })
          }

          $scope.loading = false
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }
    $scope.closeAlert = function () {
      $scope.alert = null
    }

    $scope.resetSelected = function () {
      $scope.currentShop = null
      $scope.currentAddress = null
      $scope.currentPhone = null
      $scope.currentCompany = null
    }

    $scope.loadMore = function () {
      $scope.totalDisplayed += 20
    }

    $scope.loadUser = function () {
      $api.get('user/' + $stateParams.userId)

        .then(function (response) {
          $scope.user = response.data.user
          console.log($scope.user)
          if (!$scope.user.scheduledCalls) {
            $scope.user.scheduledCalls = []
          } else {
            for (var i = 0; i < $scope.user.scheduledCalls.length; i++) {
              $scope.user.scheduledCalls[i].date = new Date($scope.user.scheduledCalls[i].date)
            }
          }

          if ($scope.user.paysFrom) {
            $scope.user.paysFrom = new Date($scope.user.paysFrom)
          }
          $scope.getPhoneNumber()
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.getPhoneNumber = function () {
      if ($scope.user.phone) {
        var phone = $scope.user.phone

        if (phone.mobilePhone) {
          $scope.user.phonenumber = phone.mobilePhone
        } else {
          $scope.user.phonenumber = '+' + phone.countryCode + phone.landLine
        }
      }
    }

    $scope.updateUser = function (redirect) {
      if ($scope.user.firstName && $scope.user.firstName.toLowerCase() === 'null' || $scope.user.lastName && $scope.user.lastName.toLowerCase() === 'null') {
        var body = {
          isCorrect: true,
          message: 'Your firstname or lastname is seems weird. Are you sure to save this name?',
          redirect: redirect
        }
        openConfirmationModal(body)
      } else if (!$scope.user.firstName || !$scope.user.lastName) {
        var body = {
          isCorrect: false,
          message: 'First prefill the firstname and lastname.',
          redirect: redirect
        }
        openConfirmationModal(body)
      } else {
        editUser(redirect)
      }
    }

    function openConfirmationModal (body) {
      prompt({
        title: 'Confirm',
        message: body.message,
        buttons: [{ label: 'OK', primary: true }, { label: 'CANCEL', primary: false, cancel: true }]
      }).then(function (response) {
        // console.log('response: ', response);
        if (response.primary && body.isCorrect) editUser(body.redirect)
      }).catch(function (error) {
        console.log('error: inprompnt: ', error)
      })
    }

    function editUser(redirect) {
      // console.log('edit user');
      delete $scope.user.loginCount
      $api.put('admin-user/' + $stateParams.userId, { user: $scope.user }).then(function (response) {
        if (response.status == 200 && redirect) {
          $state.go('admin.users', { userType: $scope.user.role })
        }
      }).catch(function (reason) {
        $scope.isUpdate = true
      })
    }

    $scope.openResetPasswordModal = function (userId) {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/admin-reset-password-modal.html',
        controller: 'adminResetPasswordModalController',
        resolve: {
          userId: function () {
            return userId
          }
        }
      })
      modalInstance.result.then(function () {
      }, function () {
        // Modal is dismissed
      })
    }

    $scope.openAddress = function (index) {
      $scope.resetSelected()
      $scope.addNewAddress = false
      $scope.currentAddress = $scope.user.address[index]
      $scope.currentAddress.index = index
    }

    $scope.newAddress = function () {
      $scope.resetSelected()
      $scope.addNewAddress = true
    }

    $scope.deleteAddress = function (index) {
      prompt({
        title: 'Adres verwijderen?',
        message: 'Weet u zeker dat u dit adres wilt verwijderen?'
      }).then(function () {
        $scope.user.address.splice(index, 1)
        $scope.resetSelected()
      })
    }

    $scope.pushAddress = function () {
      $scope.user.address.push($scope.currentAddress)
      $scope.addNewAddress = false
      $scope.currentAddress = false
    }

    $scope.cancelNewAddress = function () {
      $scope.addNewAddress = false
      $scope.resetSelected()
    }

    $scope.openShop = function (index) {
      $scope.resetSelected()
      $scope.currentShop = $scope.user.shops[index]
      // console.log($scope.currentShop)
      $scope.currentShop.index = index
    }

    $scope.openCompany = function (index) {
      $scope.resetSelected()
      $scope.currentCompany = $scope.user.companies[index]
      $scope.companies.index = index
    }

    $scope.getShops = function () {
      $scope.shopLoading = true
      var params = {
        filter: {
          isDeleted: 0
        },
        select: '_id name nameSlug type address logoLight logoDark lastAccessed'
      }
      $api.get('shops', params)
        .then(function (response) {
          $scope.shops = response.data.shops

          $scope.shops.forEach(function(shop) {
              var yearCreated = new Date(parseInt(shop._id.toString().substring(0,8),16)*1000);
              if (moment(yearCreated).isBefore('2020-01-01','year') && (!shop.lastAccessed || !shop.lastAccessed.isActive)) {
                shop.name = String(shop.name + " (aangemaakt in "+moment(yearCreated).format('YYYY')+", niet als nieuw gebruiken!)")
              }
          })

          $scope.shopLoading = false
        })
        .catch(function (reason) {
          console.log(reason)
          $scope.shopLoading = false
        })
    }

    $scope.getCompanies = function () {
      $api.get('companies')

        .then(function (response) {
          $scope.companies = response.data.companies
          console.log('companies', $scope.companies)
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.searchShops = function (str) {
      var matches = [];
      $scope.shops.forEach(function (shop) {
        if ((shop.name && shop.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
					(shop.nameSlug && shop.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
          matches.push(shop)
        }
      })
      return matches
    }

    $scope.onSelectedCompany = function (item) {
      var company = {
        _id: item._id,
        name: item.name,
        nameSlug: item.nameSlug
      }

      if (!$scope.user.companies) {
        $scope.user.companies = []
      }

      $scope.user.companies.push(company)
      $scope.currentCompany = $scope.user.companies[$scope.user.companies.length - 1]
      $scope.selectedCompany = {}
    }

    $scope.searchUsers = function (str) {
      var matches = []
      $scope.users.forEach(function (user) {
        if ((user.email.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
					(user.firstName && user.firstName.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
					(user.lastName && user.lastName.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
					(user.username.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)) {
          matches.push(user)
        }
      })
      return matches
    }

    $scope.searchBrands = function (str) {
      var matches = []
      $scope.brands.forEach(function (brand) {
        if ((brand.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
					(brand.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
          matches.push(brand)
        }
      })
      return matches
    }

    $scope.shopSelected = function (selected) {
      // console.log("selected element" , selected);

      var shop = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug,
        type: 'shop',
        logoLight: [
                // selected.originalObject.logoLight.src

        ],
        logoDark: [
              //  selected.originalObject.logoDark.src
        ]

      }
      if(selected.originalObject && selected.originalObject.logoDark && selected.originalObject.logoDark.src){
        shop.logoDark.push(selected.originalObject.logoDark.src);
      }
      if(selected.originalObject && selected.originalObject.logoLight && selected.originalObject.logoLight.src){
        shop.logoLight.push(selected.originalObject.logoLight.src);
      }
      
      if (selected.originalObject.address && selected.originalObject.address.street && selected.originalObject.address.houseNumber && selected.originalObject.address.city) {
        shop.address = {
          street: selected.originalObject.address.street,
          houseNumber: selected.originalObject.address.houseNumber,
          city: selected.originalObject.address.city
        }
      }

      // console.log("Please check shop Details Befor Adding into the Shops" , shop);

      $scope.user.shops.push(shop)
      var shops = $scope.user.shops
      $scope.user.shops = []
      $scope.user.shops = shops
      console.log("Please check all shop in Shops" , $scope.user.shops);

      $scope.currentShop = $scope.user.shops[$scope.user.shops.length - 1]
    }

    $scope.userSelected = function (selected) {
      var user = {
        _id: selected.originalObject._id,
        name: selected.originalObject.firstName + ' ' + (selected.originalObject.lastNamePrefix ? selected.originalObject.lastNamePrefix : '') + ' ' + selected.originalObject.lastName,
        email: selected.originalObject.email
      }

      var index = _.findIndex($scope.user.socialPortal.users, { _id: user._id })
      // console.log("index", index);
      if (index < 0) {
        $scope.user.socialPortal.users.push(user)
      }

      $scope.saveSocialPortal()
    }

    $scope.brandSelected = function (selected) {
      var brand = {
        _id: selected.originalObject._id,
        name: selected.title
      }
      var index = _.findIndex($scope.user.socialPortal.brands, { _id: brand._id })

      if (index < 0) {
        $scope.user.socialPortal.brands.push(brand)
      }

      $scope.saveSocialPortal()
    }

    $scope.deleteShop = function (index) {
      prompt({
        title: 'Winkel verwijderen?',
        message: 'Weet u zeker dat u deze winkel wilt verwijderen?'
      }).then(function () {
        $scope.user.shops.splice(index, 1)
        $scope.resetSelected()
      })
    }

    $scope.deleteCompany = function (index) {
      prompt({
        title: 'Bedrijf verwijderen?',
        message: 'Weet u zeker dat u dit bedrijf van deze gebruiker wilt verwijderen?'
      }).then(function () {
        $scope.user.companies.splice(index, 1)
        $scope.resetSelected()
      })
    }

    $scope.openPhone = function (index) {
      $scope.resetSelected()
      $scope.currentPhone = {}
      var phone = $scope.user.phone[index]
      if (phone.landLine) {
        $scope.currentPhone.phone = phone.landLine
      } else {
        $scope.currentPhone.phone = phone.mobilePhone
      }
      $scope.currentPhone.countryCode = phone.countryCode
      $scope.currentPhone.index = index
      // console.log("currentPhone", $scope.currentPhone);
    }

    $scope.addPhoneNumber = function () {
      $scope.phoneAlert = null
      $api.post('parse-phonenumber', {
        phoneNumber: $scope.currentPhone.phone,
        phoneCountry: $scope.currentPhone.countryCode
      })
        .then(function (response) {
          if ($scope.addNewPhone) {
            $scope.user.phone.push(response.data.phone)
          } else {
            $scope.user.phone[$scope.currentPhone.index] = response.data.phone
          }
          // NIEK
          $scope.resetSelected()
          $scope.updateUser(false)
        })
        .catch(function (reason) {
          console.error('Error while parsing phonenumber', reason)
          $scope.phoneAlert = {
            type: 'danger',
            msg: reason
          }
        })
    }

    $scope.deletePhone = function (index) {
      prompt({
        title: 'Telefoonnummer verwijderen?',
        message: 'Weet u zeker dat u dit telefoonnummer wilt verwijderen?'
      }).then(function () {
        $scope.user.phone.splice(index, 1)
        $scope.resetSelected()
      })
    }

    $scope.newPhone = function (index) {
      $scope.resetSelected()
      $scope.addNewPhone = true
    }

    $scope.pushPhone = function () {
      $scope.user.phone.push($scope.currentPhone)
      $scope.addNewPhone = false
      $scope.currentPhone = false
    }

    $scope.cancelNewPhone = function () {
      $scope.addNewPhone = false
      $scope.resetSelected()
    }

    $scope.getBrands = function () {
      // $api.get('brands')
      $api.get('brands?getAll=true')
        .then(function (response) {
          $scope.brands = response.data.brands
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.getSocialPortal = function () {

      // Deprecated
      // $api.get('user-social-portal', { userId: $stateParams.userId })
      // 	.then(function (response) {
      // 		// console.log("RESPONSE", response);
      // 		$scope.user.socialPortal = response.data.result;
      // 		$scope.loading = false;
      // 		// console.log("sp", $scope.user);
      // 	})
      // 	.catch(function (reason) {
      // 		$scope.loading = false;
      // 		if (reason.indexOf('not found') !== -1) {
      // 			//there is no Social Media Portal for this user, lets create one
      // 			$api.post('user-social-portal', { user: $scope.user })
      // 				.then(function (response) {
      // 					$scope.getSocialPortal();
      // 				})
      // 				.catch(function (reason) {
      // 					console.log("fout bij aanmaken portal", reason);
      // 				})
      // 		}
      // 	})
    }

    $scope.deleteBrandFromSocialPortal = function (index) {
      prompt({
        title: 'Merk verwijderen?',
        message: 'Weet u zeker dat u dit merk wilt verwijderen uit het social media portaal van deze gebruiker?'
      }).then(function () {
        $scope.user.socialPortal.brands.splice(index, 1)
        $scope.saveSocialPortal()
      })
    }
    $scope.upgradeCreditSocialPortal = function (index, credit) {
      $scope.user.socialPortal.brands.splice(index, 1, $scope.user.socialPortal.brands[index])
      console.log('brand log', $scope.user.socialPortal.brands[index])
      $scope.saveSocialPortal()
    }

    $scope.deleteShopFromSocialPortal = function (index) {
      prompt({
        title: 'Shop verwijderen?',
        message: 'Weet u zeker dat u deze winkel wilt verwijderen uit het social media portaal van deze gebruiker?'
      }).then(function () {
        $scope.user.socialPortal.shops.splice(index, 1)
        $scope.saveSocialPortal()
      })
    }

    $scope.deleteCompanyFromSocialPortal = function (index) {
      prompt({
        title: 'Distribiteur verwijderen?',
        message: 'Weet u zeker dat u deze distribiteur wilt verwijderen uit het social media portaal van deze gebruiker?'
      }).then(function () {
        $scope.user.socialPortal.companies.splice(index, 1)
        $scope.saveSocialPortal()
      })
    }

    $scope.deleteUserFromSocialPortal = function (index) {
      prompt({
        title: 'Gebruiker verwijderen?',
        message: 'Weet u zeker dat u deze gebruiker wilt verwijderen uit het social media portaal van deze gebruiker?'
      }).then(function () {
        $scope.user.socialPortal.users.splice(index, 1)
        $scope.saveSocialPortal()
      })
    }

    $scope.addCompanyToSocialPortal = function () {
      var nameSlug = $scope.user.companies[$scope.socialPortalCompany].nameSlug
      $api.get('companies/' + nameSlug)
        .then(function (response) {
          var company = response.data.company
          var newCompany = {
            _id: company._id,
            name: company.name
          }
          if ($scope.user.socialPortal.companies.length == 0) {
            $scope.user.socialPortal.companies.push(newCompany)
          } else {
            var index = _.findIndex($scope.user.socialPortal.companies, { _id: newCompany._id })
            if (index < 0) {
              $scope.user.socialPortal.companies.push(newCompany)
            }
          }

          company.brands.forEach(function (brand) {
            var newBrand = {
              _id: brand._id,
              name: brand.name
            }
            $scope.user.socialPortal.brands.push(newBrand)
            var result = _.map(_.uniqBy($scope.user.socialPortal.brands, '_id'), function (item) {
              return {
                _id: item._id,
                name: item.name
              }
            })
            $scope.user.socialPortal.brands = result
          })
          $scope.saveSocialPortal()
        })
        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.addShopToSocialPortal = function () {
      var nameSlug = $scope.user.shops[$scope.socialPortalShop].nameSlug
      $api.get('shops/' + nameSlug) // Deprecated
        .then(function (response) {
          var shop = response.data.shop
          var newShop = {
            _id: shop._id,
            name: shop.name
          }
          console.log('in shops socila portal', $scope.user)
          // $scope.user.socialPortal={
          // 	brands:[],
          // 	shops:[],
          // 	users:[],
          // 	_id : $scope.user._id
          // };

          var length = $scope.user.socialPortal.shops.length
          if (length == 0) {
            $scope.user.socialPortal.shops.push(newShop)
          } else {
            var index = _.findIndex($scope.user.socialPortal.shops, { _id: newShop._id })
            if (index < 0) {
              $scope.user.socialPortal.shops.push(newShop)
            }
          }
          // var user = {
          // 	_id: $scope.user._id,
          // 	name: $scope.user.firstName + " " + ($scope.user.lastNamePrefix ? $scope.userlastNamePrefix : "") + " " + $scope.user.lastName,
          // 	email: $scope.user.email
          // }

          // var index = _.findIndex($scope.user.socialPortal.users, {'_id': user._id})
          // if(index < 0){
          // 	$scope.user.socialPortal.users.push(user);
          // }

          shop.brands.forEach(function (brand) {
            var newBrand = {
              _id: brand._id,
              name: brand.name
            }
            $scope.user.socialPortal.brands.push(newBrand)
            var result = _.map(_.uniqBy($scope.user.socialPortal.brands, '_id'), function (item) {
              return {
                _id: item._id,
                name: item.name
              }
            })
            $scope.user.socialPortal.brands = result
          })
          $scope.saveSocialPortal()
        })
        .catch(function (reason) {
          console.log(reason)
        })
    }

    // Employee which we dont want to show in the list
    $scope.toggleEmployeeVisibility = function (user) {
      var body = {
        userId: user._id,
        isVisible: user.isVisible
      }
      console.log('toggleEmployeeVisibility called: ', body)
      $admin.toggleEmployeeVisibility(body).then(function (response) {
        console.log('Updated Successfully')
      }).catch(function (error) {
        console.log('somethign went wrong while updating: ', error)
      })
    }

    $scope.saveSocialPortal = function () {
      // Deprecated
      // $api.put('user-social-portal', { socialPortal: $scope.user.socialPortal })
      // 	.then(function (response) {

      // 	})
      // 	.catch(function (reason) {
      // 		console.log(reason);
      // 	})
    }
  }])
