prismanoteApp.controller('connectCompanyFromBrandWithShopModalController', ['$scope', 'connectBrand', '$uibModalInstance',
  function ($scope, connectBrand, $uibModalInstance) {
    $scope.connectBrand = JSON.parse(JSON.stringify(connectBrand))

    console.log('Modal Data', $scope.connectBrand)

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    $scope.confirm = function (connectBrand) {
      console.log(connectBrand.status)
      if (connectBrand.status == 'add') {
        $uibModalInstance.close({ status: 'add', store: connectBrand })
      } else {
        $uibModalInstance.close({ status: 'edit', store: connectBrand })
      }
    }

    $scope.remove = function (connectBrand) {
      $uibModalInstance.close({ status: 'remove', store: connectBrand })
    }
  }])
