prismanoteApp.controller('brandCampaignController', ['$scope', '$rootScope', '$api', '$state', '$stateParams', '$uibModal', 'prompt', 'campaignFact', '$language',
  function ($scope, $rootScope, $api, $state, $stateParams, $uibModal, prompt, campaignFact, $language) {
    $rootScope.pageTitle = 'PrismaNote Brand Portal | ' + $language.translate('CAMPAIGN')

    $scope.getCampaign = function () {
      $api.get('campaigns/' + $stateParams.nameSlug)

        .then(function (response) {
          $scope.campaign = response.data.campaign
          if ($scope.campaign.tasks.length > 0) {
            $scope.activeTask = $scope.campaign.tasks[0].name
          }
          $scope.taskLanguage = $rootScope.language
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    var getItems = function (type) {
      $api.get(type, { companyId: $rootScope.currentCompany._id })

        .then(function (response) {
          $scope[type] = response.data[type]
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.getBrands = function () {
      return getItems('brands')
    }

    $scope.getCompanies = function () {
      return getItems('companies')
    }

    var searchItems = function (str, type) {
      var matches = []

      $scope[type].forEach(function (item) {
        if (
          (item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
				(item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
          matches.push(item)
        }
      })

      return matches
    }

    $scope.searchBrands = function (str) {
      return searchItems(str, 'brands')
    }

    $scope.searchCompanies = function (str) {
      return searchItems(str, 'companies')
    }

    $scope.brandSelected = function (selected) {
      var brand = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug,
        description: selected.originalObject.description,
        images: selected.originalObject.images
      }

      $scope.campaign.brand = brand
    }

    $scope.companySelected = function (selected) {
      var company = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug,
        description: selected.originalObject.description,
        images: selected.originalObject.images
      }

      $scope.campaign.company = company
    }

    $scope.updateCampaign = function (redirect) {
      $api.put('campaigns/' + $stateParams.nameSlug, { campaign: $scope.campaign })

        .then(function (response) {
          if (redirect === true) {
            $state.go('brand.campaigns')
          }
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    /// below code from admin social-portals

    $scope.loading = true
    $scope.totalDisplayed = 200

    $scope.getSocialPortalsList = function () {
      $api.get('social-portals')

        .then(function (response) {
          $scope.loading = false
          // console.log("response.data", response.data);
          $scope.socialPortals = response.data.socialPortals
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.loadMore = function () {
      $scope.totalDisplayed += 200
    }

    $scope.getCampaigns = function () {
      var numberOfCampaigns = 0
      apiOptions = []
      apiOptions.offset = numberOfCampaigns
      apiOptions.limit = 100

      $scope.updates = []
      $scope.campaigns = []

      $api.get('campaigns', apiOptions)

        .then(function (response) {
          // Add campaigns to the $scope.
          // console.log("response campaigns", response);
          var today = new Date().getTime()

          // console.log("today", today);
          var counter = 0
          for (var i = 0; i < response.data.campaigns.length; i++) {
            var campaign = response.data.campaigns[i]

            if (campaign.update == true && new Date(campaign.endDate).getTime() > today) {
              $scope.updates.push(campaign)
            } else {
              $scope.campaigns.push(campaign)
            }
            counter++
            if (counter == response.data.campaigns.length) {
              $scope.getUpdatesOfSocialPortalsBrands()
            }
          }
          // console.log("$scope.updates WAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAh", $scope.updates);
        })
        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.getUpdatesOfSocialPortalsBrands = function () {
      $api.get('social-portals')

        .then(function (response) {
          $scope.loading = false
          console.log('response.data', response.data)
          $scope.socialPortals = response.data.socialPortals
          $scope.socialPortals = $scope.socialPortals.filter(function (val) {
            return val.users[0].email == $scope.user.email
            // return val.users[0].email == "ajeetrathore11@gmail.com";
          })
          var brandList = []
          for (var i = 0; i < $scope.updates.length; i++) {
            // console.log($scope.updates[i].brand.name);
            brandList.push($scope.updates[i].brand.name)
          }

          var updatesCounted = {}
          brandList.forEach(function (x) { updatesCounted[x] = (updatesCounted[x] || 0) + 1 })

          // console.log("updatesCounted", updatesCounted);

          // add brandlist to scope to use this var in matchUpdatesOfSocialPortalsBrandsWithGeneralUpdates()
          $scope.updatesCounted = updatesCounted

          for (var i = 0; i < $scope.socialPortals.length; i++) {
            if (!$scope.socialPortals[i].amountOfUpdates) {
              $scope.socialPortals[i].amountOfUpdates = 0
            }

            for (var j = 0; j < $scope.socialPortals[i].brands.length; j++) {
              var searchTerm = $scope.socialPortals[i].brands[j].name
              var index = -1

              // console.log("updatesCounted", updatesCounted);
              // console.log(Object.getOwnPropertyNames(updatesCounted));

              var arrayOfObjectNames = Object.getOwnPropertyNames(updatesCounted)
              for (var k = 0, len = arrayOfObjectNames.length; k < len; k++) {
                // console.log("we zoeken naar searchTerm", searchTerm);
                // console.log("arrayOfObjectNames[k]", arrayOfObjectNames[k]);
                // console.log("aantal updates [k]", Object.values(updatesCounted)[k]);
                if (arrayOfObjectNames[k] === searchTerm) {
                  index = k
                  var addAmount = Object.values(updatesCounted)[k]
                  // console.log("addAmount", addAmount);
                  // console.log("voor de update status $scope.socialPortals[i].amountOfUpdates", $scope.socialPortals[i].amountOfUpdates);
                  currentAmount = $scope.socialPortals[i].amountOfUpdates
                  $scope.socialPortals[i].amountOfUpdates = currentAmount + addAmount
                  // console.log("Gevonden en Geupdated!", $scope.socialPortals[i].amountOfUpdates);

                  break
                }
              }
            }
          }
          console.log($scope.socialPortals)
        })
        .catch(function (reason) {
          console.log('reason', reason)
        })
    }

    // $scope.verifyUser = function(portal){
    // 	console.log('data of the brand is',portal);
    // }
    // $scope.verified = true;
    var iddata = {
      brandId: $scope.user._id
    }
    campaignFact.isRegistered(iddata).then(function (response) {
      console.log('data for market use', response)
      if (response.data.success == false) {
        $scope.marketingUserData = response.data.error
        $scope.isUserExist = true
        var obj1 = {
          _id: $scope.user._id,
          check: true,
          verify: false,
          revert: false
        }
        campaignFact.updateMarketingUser(obj1).then(function (response) {
          console.log('response.data.data', response.data.data)
          var resp = response.data.data
          if (resp.isVerified == true) {
            $scope.verified = false
          } else {
            $scope.verified = true
          }
        })
      } else {
        $scope.isUserExist = false
      }
    })
    $scope.verifyUser = function (portal, ischecked) {
      if (ischecked == 'verify') {
        var obj = {
          _id: $scope.user._id,
          check: false,
          verify: true,
          revert: false
        }
      } else {
        var obj = {
          _id: $scope.user._id,
          check: false,
          verify: false,
          revert: true
        }
      }
      campaignFact.updateMarketingUser(obj).then(function (response) {
        console.log('response.data.data', response.data.data)
        var resp = response.data.data
        if (resp.isVerified == true) {
          $scope.verified = false
        } else {
          $scope.verified = true
        }
      })
    }

    $scope.matchUpdatesOfSocialPortalsBrandsWithGeneralUpdates = function () {
      // create list of brands and how many brands are found
      var brandListPortals = []
      for (var i = 0; i < $scope.socialPortals.length; i++) {
        for (var j = 0; j < $scope.socialPortals[i].brands.length; j++) {
          brandListPortals.push($scope.socialPortals[i].brands[j].name)
        }
      }

      console.log('brandListPortals', brandListPortals)

      var brandsCounted = {}
      brandListPortals.forEach(function (x) { brandsCounted[x] = (brandsCounted[x] || 0) + 1 })

      console.log('brandsCounted', brandsCounted)
      var brandsCountedList = Object.getOwnPropertyNames(brandsCounted)
      console.log('brandsCountedList', brandsCountedList)

      var brandListGeneral = Object.getOwnPropertyNames($scope.updatesCounted)
      console.log('brandListGeneral', brandListGeneral)

      var newBrandListPortals = []

      for (var k = 0; k < brandListGeneral.length; k++) {
        console.log('brandListGeneral[k]', brandListGeneral[k])

        for (var l = 0; l < brandsCountedList.length; l++) {
          if (brandListGeneral[k] === brandsCountedList[l]) {
            newBrandListPortals.push(brandListGeneral[k])

            break
          }
        }
      }
      console.log('newBrandListPortals', newBrandListPortals)

      for (var m = 0; m < newBrandListPortals.length; m++) {
        var search_term = newBrandListPortals[m]

        var index = brandsCountedList.indexOf(search_term) // <-- Not supported in <IE9
        if (index !== -1) {
          brandsCountedList.splice(index, 1)
        }
      }
      console.log('brandsCountedList zonder Prisma, Cartier en Zinzi', brandsCountedList)

      var brandsCountedNames = Object.getOwnPropertyNames(brandsCounted)
      var brandsCountedValues = Object.values(brandsCounted)
      $scope.topListBrands = []

      for (var n = 0; n < brandsCountedList.length; n++) {
        console.log('brandsCountedList[n]', brandsCountedList[n])

        for (var p = 0; p < brandsCountedNames.length; p++) {
          if (brandsCountedList[n] === brandsCountedNames[p]) {
            console.log('brandsCountedList[n]', brandsCountedList[n])
            console.log('brandsCountedNames[p]', brandsCountedNames[p])
            console.log('brandsCountedList', brandsCountedList)
            var newBrand = {
              name: brandsCountedNames[p],
              amount: brandsCountedValues[p]
            }
            $scope.topListBrands.push(newBrand)
          }
        }
      }

      console.log('$scope.topListBrands', $scope.topListBrands)
    }
  }])
