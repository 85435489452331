prismanoteApp.controller('companyProductStockChangeModalController', ['$scope', '$uibModalInstance', 'data', '$company',
  function ($scope, $uibModalInstance, data, $company) {
    $scope.companyId = data.companyId
    $scope.productId = data.productId
    $scope.status = data.status

    if (data.oldStock != null || data.oldStock !== undefined) $scope.oldStock = $scope.newStock = data.oldStock

    $scope.close = function (shouldModalOpen) {
      $uibModalInstance.close(shouldModalOpen)
    }

    $scope.getProductStockChanges = function (companyId, productId) {
      // Get Product Stock Changes Records
      console.group(' -------- Get Product Stock Changes --------- : ', $scope.companyId, $scope.productId);
      $company.getCompayProductStockChanges($scope.companyId, $scope.productId)
        .then(function (stocks) {
          // Product Stock Changes Records assign to productStockChanges
          $scope.productStockChanges = stocks[0].stockChanges
          if ($scope.status === 'edit' && stocks && stocks.length) {
            $scope.newStock = stocks[0].currentStock
            $scope.oldStock = stocks[0].currentStock
          }
        })
        .catch(function (error) {
          console.error('Error =>> getProductStockChanges', error)
        })
    }

    $scope.saveStock = function (companyId, productId, oldStock, newStock) {
      $company.updateCompanyProductStock(companyId, productId, oldStock, newStock)
        .then(function (success) {
          $scope.close(newStock)
        })
        .catch(function (error) {
          console.error('Error! While Update Company Product Stock', error)
          $scope.close(null)
        })
    }
  }])
  