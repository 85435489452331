prismanoteApp.controller('welcomeFromInvitationLinkController', ['$scope', '$rootScope', '$state', '$stateParams', '$general', '$retailer', '$language',
    function ($scope, $rootScope, $state, $stateParams, $general, $retailer, $language) {
        $scope.registerDetails = {
            form: {},
            // email: $stateParams.email ? $stateParams.email : null
        }
        $scope.logo = "public/prismanote-logo-icon.jpg"
        $scope.localdata = {
            isError: false,
            loading: false,
            chosenEmail: '',
            insertedEmail: '',
            differentEmail: '',
            isSelectEmailStep: true,
            isChooseShopStep: false,
            isRegistrationStep: false,
            isDifferentEmail: false,
            isPasswordInput: false,
            selectedShop: ''
        }
        console.log('welcomeFromInvitationLinkController: called', $stateParams)

        if (!$stateParams.searchlink) {
            $scope.localdata.isError = true;
            $scope.alert = { type: 'danger', msg: 'Invalid Data' };
        } else {
            var body = { searchlink: $stateParams.searchlink }
            $scope.localdata.loading = true;
            $scope.alert = { type: 'info', msg: 'Loading' }
            $general.companyLogo(body).then(function (response) {
                if(response.data.data != '') {
                    $scope.logo = response.data.data
                }
                $general.welcomeInvitationLink(body).then(function (response) {
                    $scope.localdata.loading = false;
                    $scope.alert = undefined;
    
                    if (response && response.data && response.data.data) {
                        console.log('======================>>>>>>> response: ', JSON.stringify(response.data.data));
                        $scope.linkdata = response.data.data;
                        $scope.localdata.insertedEmail = $scope.linkdata.chosenEmail;
    
                        // IF USER ALREADY LOGGED IN JUST SHOW SHOP DIRECTLY, WHICH USER HAS
                        if ($rootScope.user && $rootScope.user.shops && $rootScope.user.shops.length) {
                            $scope.changeTheFlag('isChooseShopStep');
                            $scope.userShops = $rootScope.user.shops;
                        }
                    } else {
                        console.log('Error while fetching data');
                        $scope.alert = { type: 'danger', msg: 'Internal Server Error' }
                    }
                }).catch(function (error) {
                    $scope.localdata.loading = false;
                    $scope.localdata.isError = true;
                    var message = 'Invalid link or something went wrong';
                    if (error && error.data && error.data.message) message = error.data.message;
                    console.log('message: ', message);
                    $scope.alert = { type: 'danger', msg: message }
                });
            })
            // Here, we are checking that link is valid or not and fetching linkdata
           
        }

        $scope.isEmailExistForLoginUser = function () {
            $scope.localdata.loading = true;
            $scope.alert = { type: 'info', msg: 'Loading' }
            var body = { insertedEmail: $scope.localdata.insertedEmail, chosenEmail: $scope.linkdata.chosenEmail }
            // Here, we are checking that link is valid or not
            $general.isEmailExistForLoginUser(body).then(function (response) {
                $scope.localdata.loading = false;
                $scope.alert = undefined;

                console.log('response: isEmailExistForLoginUser', response);
                $scope.userShops = [];
                if (response.status === 203) {
                    console.log('Different account');
                    $scope.changeTheFlag('isPasswordInput');
                } else if (response.status === 207) {
                    // 207: means that, User not found but shop found for the inserted email
                    var shop = response.data.data;
                    $scope.shopByEmail = shop;
                    $scope.changeTheFlag('isRegistrationStep');
                } else if (response.status === 204) {
                    $scope.changeTheFlag('isRegistrationStep');
                } else if (response && response.data && response.data.data && response.data.data.shops && response.data.data.shops.length) {
                    $scope.changeTheFlag('isChooseShopStep');
                    $scope.userShops = response.data.data.shops;
                }
            }).catch(function (Err) {
                console.log('isEmailExistForLoginUser called: ', Err);
                $scope.localdata.loading = false;
                $scope.localdata.isError = true;
                var message = 'Invalid link or something went wrong';
                if (Err) {
                    if (Err.data && Err.data.message) message = Err.data.message;
                    else message = Err;
                }
                console.log('message: ', message);
                $scope.alert = { type: 'danger', msg: message }
            });
        }

        $scope.selectDifferentEmail = function () {
            $scope.changeTheFlag('isChooseShopStep');
            $scope.localdata.insertedEmail = $scope.localdata.differentEmail;
            $scope.isEmailExistForLoginUser();
        }

        $scope.chooseShop = function (shop) {
            $scope.localdata.selectedShop = shop;
            chooseShopAndLogin(shop);
        }

        function fetchShopAndRedirection(shop, companywebsite) {
            $rootScope.selectedShop = $scope.localdata.selectedShop.nameSlug;
            $retailer.getShop($rootScope.selectedShop).then(function (shop) {
                $rootScope.currentShop = shop;
                console.log('Welcome from inviatation link controller 119: ', shop);
                //$rootScope.socketInit()
                $rootScope.openChats = []

                $scope.$broadcast('changeSelectedShop', $rootScope.selectedShop)
                localStorage.setItem('currentShop', $scope.currentShop.nameSlug)
                localStorage.setItem('currentShopName', $scope.currentShop.name)
                localStorage.removeItem('lastLoggedInEmployees') // Removing old localstorage employee if any
                $rootScope.unreadNotifications = shop.unreadNotifications
                $rootScope.unreadMessageNotifications = shop.messageNotification.length

                if ($rootScope.unreadNotifications > 0) {
                    var title = 'PrismaNote'
                    if ($state.$current.data && $state.$current.data.pageTitle) {
                        title = title + ' | ' + $language.translate($state.$current.data.pageTitle)
                    }
                    // title = '(' + $rootScope.unreadNotifications + ') ' + title
                    $rootScope.pageTitle = title
                    // Write also direct to document to show the result directly
                    document.title = title
                }
                redirectBasedOnLink(companywebsite);
            }).catch(function (reason) {
                console.log('reason: reason: ', reason);
            })
        }

        function chooseShopAndLogin(shop) {
            var body = {
                insertedEmail: $scope.localdata.insertedEmail,
                chosenEmail: $scope.linkdata.chosenEmail,
                shop: {
                    _id: $scope.localdata.selectedShop._id,
                    nameSlug: $scope.localdata.selectedShop.nameSlug,
                    name: $scope.localdata.selectedShop.name,
                },
                company: {
                    _id: $scope.linkdata && $scope.linkdata.company ? $scope.linkdata.company._id : undefined
                },
                invitation: {
                    // For, Recommendation
                    isForRetailerRecommendation: $scope.linkdata.isForRetailerRecommendation,
                    // For, Purchase order
                    isForRetailerPurchaseOrder: $scope.linkdata.isForRetailerPurchaseOrder,
                    purchaseOrderNumber: $scope.linkdata.purchaseOrderNumber,
                    // For, Retailer Campaign
                    isForRetailerCampaign: $scope.linkdata.isForRetailerCampaign,
                    campaignId: $scope.linkdata.campaignId,
                }
            }
            $scope.localdata.loading = true;
            $scope.alert = { type: 'info', msg: 'Loading' }
            $general.chooseShopAndLogin(body).then(function (response) {
                $scope.localdata.loading = false;
                $scope.alert = undefined;
                console.log('response.data: ', response);
                if (response && response.data) {
                    var companywebsite = response.data.companywebsite;
                    if (response.data.data) {
                        var responseData = response.data.data;
                        console.log('responseData: ', responseData);
                        $rootScope.user = responseData.user;
                        fetchShopAndRedirection(shop, companywebsite);
                    }
                }
            }).catch(function (error) {
                $scope.localdata.loading = false;
                $scope.localdata.isError = true;
                var message = 'Internal Server Error';
                if (error && error.data && error.data.message) message = error.data.message;
                console.log('message: ', message);
                $scope.alert = { type: 'danger', msg: message }
            });
        }

        $scope.changeTheFlag = function (key) {
            $scope.localdata.isSelectEmailStep = false;
            $scope.localdata.isChooseShopStep = false;
            $scope.localdata.isDifferentEmail = false;
            $scope.localdata.isPasswordInput = false;
            $scope.localdata.isRegistrationStep = false;
            $scope.localdata[key] = true;
        }

        $scope.login = function (redirect) {
            $scope.localdata.loading = true;
            $scope.alert = { type: 'info', msg: 'Loading' }

            var body = {
                username: $scope.localdata.differentEmail.toLowerCase(),
                password: $scope.localdata.password,
                shopSlug: $rootScope.shopSlug,
                invitation: {
                    isForRetailerRecommendation: $scope.linkdata.isForRetailerRecommendation,
                    company: {
                        _id: $scope.linkdata.company._id,
                    },
                    shop: {
                        _id: $scope.linkdata.shop._id
                    }
                }
            }

            $general.login(body).then(function (response) {
                $scope.localdata.loading = false;
                $scope.alert = undefined;

                console.log('response: ', response);
                if (response && response.data && response.data.user) {
                    var user = response.data.user;
                    $rootScope.user = user;
                    $scope.changeTheFlag('isChooseShopStep');
                    $scope.userShops = user.shops;
                }
            }).catch(function (reason) {
                $scope.localdata.loading = false;
                console.log('reason login: ', reason);
                var message = 'Internal Server Error';
                if (reason) {
                    if (reason.data && reason.data.message && reason.status && reason.status == 429) {
                        // Too many requests
                        $scope.alert = { type: 'danger', msg: $language.translate(reason.data.message) }
                    } else {
                        console.log('Come here');
                        $scope.alert = { type: 'danger', msg: $language.translate(reason) }
                    }
                } else {
                    $scope.alert = { type: 'danger', msg: message }
                }
            })
        }

        function redirectBasedOnLink(companywebsite) {
            console.log('redirectBasedOnLink called: ', $scope.linkdata, companywebsite);
            if ($scope.linkdata && $scope.linkdata.isForRetailerRecommendation &&
                $scope.linkdata.companyDetail && $scope.linkdata.companyDetail._id) {
                $rootScope.recommendationCompany = $scope.linkdata.companyDetail;
                // $rootScope.recommendationCompanyId = $scope.linkdata.companyDetail._id;
                var staticUrl = 'http://www.wrsmsieraden.nl';
                if (companywebsite && companywebsite == staticUrl) {
                    window.location.href = staticUrl;
                } else {
                    $state.go('retailer.recommendation-order', { orderNo: '' });
                }
            } else if ($scope.linkdata.isForRetailerPurchaseOrder) {
                console.log('This is for the Purchase order: ', $scope.linkdata, $scope.localdata.selectedShop);
                $state.go('retailer.purchase-order', { orderNo: $scope.linkdata.purchaseOrderNumber })
                return;
                if ($scope.linkdata.shop._id === $scope.localdata.selectedShop._id) {
                } else {
                    $rootScope.showAlertMsgOfPurchaseOrder = true
                    $state.go('retailer.purchase-orders')
                }
            } else if ($scope.linkdata.isForRetailerCampaign && $scope.linkdata.campaignId) {
                $state.go('retailer.paidcampaign', { isCampaignStep: 'join', campaignId: $scope.linkdata.campaignId })
            } else {
                var role = $rootScope.user.role == 'wholesaler' ? 'brand' : $rootScope.user.role
                $state.go(role + '.home')
            }
        }

        $scope.register = function () {
            var body = {
                userRole: 'retailer',
                extraData: {
                    shop: $scope.registerDetails.shop
                },
                user: {
                    password: $scope.registerDetails.password,
                    passwordCompare: $scope.registerDetails.password2,
                    email: $scope.registerDetails.email
                },
                language: $rootScope.language,
                searchlink: $stateParams.searchlink,
                shopByEmail: $scope.shopByEmail // If we get shop with the inserted email then we will use that instead of creating completely new
            }
            console.log('register called: body ', body)
            $scope.localdata.loading = true;
            $scope.alert = { type: 'info', msg: 'Loading' }

            $general.register(body).then(function (response) {
                console.log('response: register: ', response);
                $scope.formSubmitted = true;
                $scope.localdata.loading = false;
                $scope.alert = { type: 'success', msg: $language.translate(response.data.message) }
                if (response.status === 200 && response && response.data && response.data.updatedUser) {
                    var user = response.data.updatedUser;
                    $rootScope.user = user;
                    $scope.userShops = user.shops;
                    $scope.localdata.insertedEmail = body.user.email;
                    $scope.changeTheFlag('isChooseShopStep');
                    $scope.isEmailExistForLoginUser();
                } else if (response.status === 207) {
                    $scope.alert = { type: 'success', msg: $language.translate(response.data.message) }
                }
            }).catch(function (reason) {
                console.error(reason)
                $scope.localdata.loading = false;
                $scope.alert = { type: 'danger', msg: $language.translate(reason.data && reason.data.message ? reason.data.message : reason) }
            })
        }

        $scope.pwdNormal = function () {
            if (!$scope.registerDetails.password) return false
            return $scope.registerDetails.password.match(/[a-z]/m)
        }
        $scope.pwdCapital = function () {
            if (!$scope.registerDetails.password) return
            return $scope.registerDetails.password.match(/[A-Z]/m)
        }
        $scope.pwdSpecial = function () {
            if (!$scope.registerDetails.password) return
            return $scope.registerDetails.password.match(/[^a-zA-Z0-9]/gm)
        }
        $scope.pwdNumber = function () {
            if (!$scope.registerDetails.password) return
            return $scope.registerDetails.password.match(/\d/gm)
        }
        $scope.pwdLength = function () {
            if (!$scope.registerDetails.password) return
            return $scope.registerDetails.password.length > 7
        }

        $scope.togglePassword = function () {
            var e1 = document.getElementById('pnPassword1')
            var e2 = document.getElementById('pnPassword2')
            if (!e1 || !e2) {
                return
            }
            if (e1.type === 'password') {
                e1.type = 'text'
                e2.type = 'text'
                $('.fa-eye').show()
                $('.fa-eye-slash').hide()
            } else {
                e1.type = 'password'
                e2.type = 'password'
                $('.fa-eye').hide()
                $('.fa-eye-slash').show()
            }
        }

    }]);
