prismanoteApp.controller('retailerQuickButtonModalController', ['$scope', '$uibModalInstance', '$rootScope', '$api', '$stateParams', '$uibModal', '$state', 'prompt', 'Upload', 'button', '$shop', '$log',
  function ($scope, $uibModalInstance, $rootScope, $api, $stateParams, $uibModal, $state, prompt, Upload, button, $shop, $log) {

    $scope.button = button
    $scope.loading = false
    $scope.selectedProduct = button
    $scope.allowSave = !!button.shopProduct

    $scope.save = function (empty) {
      if(empty) {
        $uibModalInstance.close({
          empty: true,
        })
      }
      if($scope.allowSave) {
        $uibModalInstance.close($scope.button)
      }
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss();
    }

    $scope.delete = function () {
      $uibModalInstance.close('delete')
    }

    $scope.nameChange = function (val) {
      if(val.length === 0) {
        $scope.button = button
        $scope.allowSave = false;
      }
    }
    $scope.searchProducts = function (searchText) {
      $scope.loading = true
      $shop.getShopProducts(searchText, 'stock')
        .then(function (result) {
          $scope.loading = false
          $scope.products = result
        })
        .catch(function (reason) {
          $scope.loading = false
          $log.error('Error when searching products', reason)
        })
    }

    $scope.makeProductPrice = function (price) {
      return Math.round((price + Number.EPSILON) * 100) / 100
    }

    $scope.clearSearchResult = function () {
      $scope.products = []
      $scope.shopProduct = null
      $scope.allowSave = false;
      $scope.selectedProduct = button
      $scope.button = button
    }

    $scope.makeQuickButtonText = function (text) {
      if(!text) {
        return ''
      }
      if(text.length > 23) {
        return text.substring(0, 23)
      } else {
        return text
      }
    }

    $scope.chooseProduct = function (product) {
      $shop.getProductForCashRegistry($rootScope.currentShop._id, product._id)
        .then(function(product) {
          $scope.button = {
            name: $scope.makeQuickButtonText(product[$rootScope.language].name),
            price: $scope.makeProductPrice(product.price * (1 + ( product.priceVat / 100 ))),
            shopProduct: product._id,
            ean: product.variants[0].ean,
            productNumber: product.variants[0].productNumber
          }
          
            $scope.selectedProduct = {
              name: product[$rootScope.language].name,
              price: $scope.makeProductPrice(product.price * (1 + ( product.priceVat / 100 ))),
              shopProduct: product._id,
              ean: product.variants[0].ean,
              productNumber: product.variants[0].productNumber
            }
            $scope.allowSave = true
            $scope.products = []
        })
        .catch(function (reason) {
          $log.error('Error while fetching product details', reason)
        })
    }

  }])
