prismanoteApp.controller('retailerDeviceModalController', ['$rootScope', '$scope', '$uibModalInstance', '$api', 'devices', '$device',
  function ($rootScope, $scope, $uibModalInstance, $api, devices, $device) {
    $rootScope.deviceModalOpen = true
    $scope.devices = devices

    $scope.setDevice = function (device) {
      $uibModalInstance.close(device)
    }

    $scope.newDevice = function (name) {
      $device.createDevice(name, $rootScope.currentShop._id)
        .then(function(res) {
          $scope.deviceName = null
          $scope.getDevices()
        })
        .catch(function(reason){
          console.error('Error while creating device', reason)
        })
    }

    $scope.getDevices = function () {
      $device.getDevices($rootScope.currentShop._id, null)
        .then(function(devices) {
          $scope.devices = devices
        })
        .catch(function(reason){
          console.error('Error while fetching devices', reason)
        })
    }

}])
