prismanoteApp.controller('transactionUploadFileModalController', ['$scope', '$uibModalInstance', '$rootScope', '$api', '$stateParams', '$uibModal', '$state', 'prompt', 'FileUploader', 'files', '$language', 'hideSlider',
  function ($scope, $uibModalInstance, $rootScope, $api, $stateParams, $uibModal, $state, prompt, FileUploader, files, $language, hideSlider) {
    $scope.showCamera = false
    $scope.streaming = false
    $scope.manualUpload = false
    $scope.active = 0 //slider index
    $scope.hideSlider = hideSlider
    if(files && files.length > 0) {
      $scope.files = files
    } else {
      $scope.files = []
    }

    $scope.uploadedFiles = []

    // #region uploader instance
    $scope.uploader = new FileUploader({
      url: 'api/v2/till/transaction/upload'
    })
    $scope.uploader.filters.push({
      name: 'duplicateFilesFilter',
      fn: function (item) {
        var index = _.findIndex($scope.uploader.queue, function (q) {
          return q.file.name === item.name
        })
        return index < 0
      }
    })
    $scope.uploader.onSuccessItem = function (item, response, status, headers) {
      $scope.uploadedFiles.push({
        name: item.file.name,
        src: response.file
      })
    }
    $scope.uploader.onErrorItem = function (fileItem, response, status, headers) {
      $scope.alert = {
        type: 'danger',
        msg: response.message
      }
    }
    // #endregion

    $scope.photoSettings = {
      width: 400,
      height: 300
    }

    $scope.stopStream = function () {
      if (!$scope.streaming) {
        return
      }
      var video = document.getElementById('video')
      video.style.visibility = 'hidden'
      video.setAttribute('height', 0)
      video.setAttribute('width', 0)
      var stream = video.srcObject
      var tracks = stream.getTracks()
      $scope.streaming = false

      tracks.forEach(function (track) {
        track.stop()
      })
    }

    $scope.startCamera = function (override, constraints) {
      $scope.cameraAlert = null
      $scope.manualUpload = false

      if(typeof override === 'boolean') {
        $scope.showCamera = override
      } else {
        $scope.showCamera = !$scope.showCamera
      }

      if ($scope.showCamera === false) {
        $scope.stopStream()
        return
      }

      if(!constraints) {
        constraints = {
          video: {
            facingMode: {
              exact: 'environment'
            }
          },
          audio: false
        }
      }

      var video = document.getElementById('video')
      video.style.visibility = 'visible'
      var preview = document.getElementById('preview')
      var canvas = document.getElementById('canvas')
      var photo = document.getElementById('photo')

      navigator.mediaDevices.getUserMedia(constraints)
        .then( function (stream) {
            video.srcObject = stream
            video.play()
        })
        .catch(function (reason){
          if(reason.name === 'OverconstrainedError') {
            //Non mobile device can throw an error if there is no backcamera, we need to try to restart the process with all camera's
            $scope.startCamera(true, {
              video: true,
              audio: false
            })
            return
          }
          var msg = $language.translate('ERROR_WHILE_CONNECTING_TO_CAMERA')
          if(reason.name === 'NotAllowedError') {
            msg = $language.translate('PLEASE_ALLOW_ACCESS_TO_YOUR_CAMERA')
          }

          $scope.cameraAlert = {
            type: 'danger',
            msg: msg
          }
          console.error('Error while fetching camera stream', reason)
        })

      video.addEventListener('canplay', function(ev){
        if(!$scope.streaming) {
          $scope.photoSettings.height = video.videoHeight / (video.videoWidth/$scope.photoSettings.width)

          if(isNaN($scope.photoSettings.height)) {
            $scope.photoSettings.height = $scope.photoSettings.width / (4 / 3)
          }

          video.setAttribute('width', $scope.photoSettings.width)
          video.setAttribute('height', $scope.photoSettings.height)
          $scope.streaming = true
        }
      })
    }

    $scope.clearPhoto = function () {
      var canvas = document.getElementById('canvas')
      var context = canvas.getContext('2d')
      context.fillStyle = '#AAA'
      context.fillRect(0, 0, canvas.width, canvas.height);
      $scope.startCamera(true)
    }

    function base64ToBlob(base64, mime) {
      base64 = base64.replace(/^data:image\/(png|jpg);base64,/, "")
      mime = mime || '';
      var sliceSize = 1024;
      var byteChars = window.atob(base64);
      var byteArrays = [];

      for (var offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
        var slice = byteChars.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, {type: mime});
    }

    $scope.takePicture = function () {
      if(!$scope.streaming) {
        $scope.clearPhoto()
        return
      }
      var canvas = document.getElementById('canvas')
      var context = canvas.getContext('2d')

      if($scope.photoSettings.width && $scope.photoSettings.height) {
        canvas.width = $scope.photoSettings.width
        canvas.height = $scope.photoSettings.height

        var video = document.getElementById('video')
        context.drawImage(video, 0, 0, $scope.photoSettings.width, $scope.photoSettings.height)
        var image = canvas.toDataURL('image/png')
        var blobFile = base64ToBlob(image, 'image/png')

        var fileName = 'repair_' + new Date().getTime()+'.png'
        var file = new File([blobFile], fileName, {type: 'image/png'})
        var item = new FileUploader.FileItem($scope.uploader, {name: fileName})
        item._file = file

        $scope.uploader.queue.push(item)
        $scope.uploader.uploadAll()

        $scope.stopStream()
      } else {
        $scope.clearPhoto()
      }
    }

    $scope.upload = function () {
      $scope.startCamera(false)
      $scope.manualUpload = !$scope.manualUpload
    }

    $scope.complete = function () {
      $uibModalInstance.close($scope.uploadedFiles)
    }

    $scope.cancel = function () {
      $scope.stopStream()
      $uibModalInstance.dismiss('cancel')
    }

    $scope.deletePhoto = function (index) {
      $scope.files.splice(index, 1)
    }
}])
