prismanoteApp.controller('companyInvitationController', ['$scope', '$rootScope', '$state', '$stateParams', '$companyInvitation', '$language', '$company', '$general', '$user',
    function ($scope, $rootScope, $state, $stateParams, $companyInvitation, $language, $company, $general, $user) {
        $scope.registerDetails = {
            form: {},
        }
        $scope.logo = "public/prismanote-logo-icon.jpg"
        $scope.localdata = {
            isError: false,
            loading: false,
            chosenEmail: '',
            insertedEmail: '',
            differentEmail: '',
            isSelectEmailStep: true,
            isChooseCompanyStep: false,
            isRegistrationStep: false,
            isDifferentEmail: false,
            isPasswordInput: false,
            selectedCompany: ''
        }
        console.log('companyInvitationController: called', $stateParams)

        if (!$stateParams.searchlink) {
            $scope.localdata.isError = true;
            $scope.alert = { type: 'danger', msg: 'Invalid Data' };
        } else {
            var body = { searchlink: $stateParams.searchlink, companyId: $rootScope.currentCompany._id }
            $scope.localdata.loading = true;
            $scope.alert = { type: 'info', msg: 'Loading' }
            $general.companyLogo(body).then(function (response) {
                if(response.data.data != '') {
                    $scope.logo = response.data.data
                }
                 // Here, we are checking that link is valid or not and fetching linkdata
            $companyInvitation.fetchCompanyInvitationData(body).then(function (response) {
                $scope.localdata.loading = false;
                $scope.alert = undefined;
                console.log('======================>>>>>>> response: ', response);

                if (response && response.data && response.data.data) {
                    $scope.linkdata = response.data.data;
                    $scope.localdata.insertedEmail = $scope.linkdata.chosenEmail;

                    // IF USER ALREADY LOGGED IN JUST SHOW COMPANY DIRECTLY, WHICH USER HAS
                    if ($rootScope.user && $rootScope.user.companies && $rootScope.user.companies.length) {
                        $scope.changeTheFlag('isChooseCompanyStep');
                        $scope.userCompanies = $rootScope.user.companies;
                    }
                } else {
                    console.log('Error while fetching data');
                    $scope.alert = { type: 'danger', msg: 'Internal Server Error' }
                }
            }).catch(function (error) {
                $scope.localdata.loading = false;
                $scope.localdata.isError = true;
                var message = 'Invalid link or something went wrong';
                if (error && error.data && error.data.message) message = error.data.message;
                console.log('message: ', message);
                $scope.alert = { type: 'danger', msg: message }
            });
            }) 
        }

        $scope.isUserAndCompanyAvailableByEmail = function () {
            $scope.localdata.loading = true;
            $scope.alert = { type: 'info', msg: 'Loading' }
            var body = { insertedEmail: $scope.localdata.insertedEmail, chosenEmail: $scope.linkdata.chosenEmail, companyId: $rootScope.currentCompany._id }
            // Here, we are checking that link is valid or not
            $companyInvitation.isUserAndCompanyAvailableByEmail(body).then(function (response) {
                $scope.localdata.loading = false;
                $scope.alert = undefined;

                console.log('response: isUserAndCompanyAvailableByEmail', response);
                $scope.userCompanies = [];
                if (response.status === 203) {
                    console.log('Different account');
                    $scope.changeTheFlag('isPasswordInput');
                } else if (response.status === 207) {
                    // 207: means that, User not found but shop found for the inserted email
                    var company = response.data.data;
                    $scope.companyByEmail = company;
                    $scope.changeTheFlag('isRegistrationStep');
                } else if (response.status === 204) {
                    $scope.changeTheFlag('isRegistrationStep');
                } else if (response && response.data && response.data.data && response.data.data.companies && response.data.data.companies.length) {
                    $scope.changeTheFlag('isChooseCompanyStep');
                    $scope.userCompanies = response.data.data.companies;
                }
            }).catch(function (Err) {
                console.log('isUserAndCompanyAvailableByEmail called: ', Err);
                $scope.localdata.loading = false;
                $scope.localdata.isError = true;
                var message = 'Invalid link or something went wrong';
                if (Err) {
                    if (Err.data && Err.data.message) message = Err.data.message;
                    else message = Err;
                }
                console.log('message: ', message);
                $scope.alert = { type: 'danger', msg: message }
            });
        }

        $scope.chooseCompany = function (company) {
            $scope.localdata.selectedCompany = company;
            chooseCompanyAndLogin(company);
        }

        $scope.selectDifferentEmail = function () {
            $scope.changeTheFlag('changeSelectedCompany');
            $scope.localdata.insertedEmail = $scope.localdata.differentEmail;
            $scope.isUserAndCompanyAvailableByEmail();
        }

        $scope.login = function (redirect) {
            $scope.localdata.loading = true;
            $scope.alert = { type: 'info', msg: 'Loading' }

            var body = {
                username: $scope.localdata.differentEmail.toLowerCase(),
                password: $scope.localdata.password,
                /* companySlug: $rootScope.companySlug,
                invitation: {
                    isForCompany_ShopStatistics: $scope.linkdata.isForCompany_ShopStatistics,
                    company: {
                        _id: $scope.linkdata.company._id,
                    },
                    shop: {
                        _id: $scope.linkdata.shop._id
                    }
                } */
            }

            $general.login(body).then(function (response) {
                $scope.localdata.loading = false;
                $scope.alert = undefined;

                console.log('response: ', response);
                if (response && response.data && response.data.user) {
                    var user = response.data.user;
                    $rootScope.user = user;
                    $scope.changeTheFlag('isChooseCompanyStep');
                    $scope.userCompanies = user.companies;
                }
            }).catch(function (reason) {
                $scope.localdata.loading = false;
                console.log('reason login: ', reason);
                var message = 'Internal Server Error';
                if (reason) {
                    if (reason.data && reason.data.message && reason.status && reason.status == 429) {
                        // Too many requests
                        $scope.alert = { type: 'danger', msg: $language.translate(reason.data.message) }
                    } else {
                        console.log('Come here');
                        $scope.alert = { type: 'danger', msg: $language.translate(reason) }
                    }
                } else {
                    $scope.alert = { type: 'danger', msg: message }
                }
            })
        }

        $scope.register = function () {
            var body = {
                userRole: 'wholesaler',
                extraData: {
                    companyname: $scope.registerDetails.company,
                },
                user: {
                    password: $scope.registerDetails.password,
                    passwordCompare: $scope.registerDetails.password2,
                    email: $scope.registerDetails.email
                },
                language: $rootScope.language,
                searchlink: $stateParams.searchlink,
                companyByEmail: $scope.companyByEmail
            }
            console.log('register called: body ', body);
            $scope.localdata.loading = true;
            $scope.alert = { type: 'info', msg: 'Loading' }

            $user.companyRegisterUser(body).then(function (response) {
                console.log('response: register: ', response);
                $scope.formSubmitted = true;
                $scope.localdata.loading = false;
                $scope.alert = { type: 'success', msg: $language.translate(response.data.message) }
                if (response.status === 200 && response && response.data && response.data.data) {
                    var user = response.data.data;
                    $rootScope.user = user;
                    $scope.userCompanies = user.companies;
                    $scope.localdata.insertedEmail = body.user.email;
                    $scope.changeTheFlag('isChooseCompanyStep');
                    $scope.isUserAndCompanyAvailableByEmail();
                } else if (response.status === 207) {
                    $scope.alert = { type: 'success', msg: $language.translate(response.data.message) }
                }
            }).catch(function (reason) {
                console.error(reason)
                $scope.localdata.loading = false;
                $scope.alert = { type: 'danger', msg: $language.translate(reason.data && reason.data.message ? reason.data.message : reason) }
            })
        }

        function chooseCompanyAndLogin(company) {
            var body = {
                insertedEmail: $scope.localdata.insertedEmail,
                chosenEmail: $scope.linkdata.chosenEmail,
                company: {
                    _id: $scope.localdata.selectedCompany._id,
                    nameSlug: $scope.localdata.selectedCompany.nameSlug,
                    name: $scope.localdata.selectedCompany.name,
                },
                shop: {
                    _id: $scope.linkdata.shop._id,
                    nameSlug: $scope.linkdata.shop.nameSlug,
                    name: $scope.linkdata.shop.name
                },
                invitation: {
                    // For, isForCompany_ShopStatistics
                    isForCompany_ShopStatistics: $scope.linkdata.isForCompany_ShopStatistics,
                    // For Company  purchase order
                    isForCompanyPurchaseorder: $scope.linkdata.isForCompanyPurchaseorder,
                    purchaseOrderNumber: $scope.linkdata.purchaseOrderNumber
                },
                companyId: $rootScope.currentCompany._id
            }
            $scope.localdata.loading = true;
            $scope.alert = { type: 'info', msg: 'Loading' }
            $companyInvitation.chooseCompanyAndLogin(body).then(function (response) {
                $scope.localdata.loading = false;
                $scope.alert = undefined;
                console.log('response.data: ', response);
                if (response && response.data && response.data.data) {
                    var responseData = response.data.data;
                    console.log('responseData: ', responseData);
                    $rootScope.user = responseData.user;
                    fetchCompanyAndRedirection(company);
                }
            }).catch(function (error) {
                $scope.localdata.loading = false;
                $scope.localdata.isError = true;
                var message = 'Internal Server Error';
                if (error && error.data && error.data.message) message = error.data.message;
                console.log('message: ', message);
                $scope.alert = { type: 'danger', msg: message }
            });
        }

        function fetchCompanyAndRedirection(company) {
            var nameSlug = $scope.localdata.selectedCompany.nameSlug;
            $rootScope.selectedCompany = nameSlug;
            $company.getCompany(nameSlug).then(function (company) {
                $rootScope.currentCompany = company
                $rootScope.openChats = []
                //$rootScope.socketInit()
                $scope.$broadcast('changeSelectedCompany', nameSlug)
                localStorage.setItem('currentCompany', $scope.currentCompany.nameSlug)
                $rootScope.unreadMessageNotifications = company.messageNotification && company.messageNotification.length ? company.messageNotification.length : 0
                redirectBasedOnLink();
            }).catch(function (reason) {
                console.log('reason: getCompany', reason)
            })
        }

        $scope.changeTheFlag = function (key) {
            $scope.localdata.isSelectEmailStep = false;
            $scope.localdata.isChooseCompanyStep = false;
            $scope.localdata.isDifferentEmail = false;
            $scope.localdata.isPasswordInput = false;
            $scope.localdata.isRegistrationStep = false;
            $scope.localdata[key] = true;
        }

        $scope.pwdNormal = function () {
            if (!$scope.registerDetails.password) return false
            return $scope.registerDetails.password.match(/[a-z]/m)
        }
        $scope.pwdCapital = function () {
            if (!$scope.registerDetails.password) return
            return $scope.registerDetails.password.match(/[A-Z]/m)
        }
        $scope.pwdSpecial = function () {
            if (!$scope.registerDetails.password) return
            return $scope.registerDetails.password.match(/[^a-zA-Z0-9]/gm)
        }
        $scope.pwdNumber = function () {
            if (!$scope.registerDetails.password) return
            return $scope.registerDetails.password.match(/\d/gm)
        }
        $scope.pwdLength = function () {
            if (!$scope.registerDetails.password) return
            return $scope.registerDetails.password.length > 7
        }

        $scope.togglePassword = function () {
            var e1 = document.getElementById('pnPassword1')
            var e2 = document.getElementById('pnPassword2')
            if (!e1 || !e2) {
                return
            }
            if (e1.type === 'password') {
                e1.type = 'text'
                e2.type = 'text'
                $('.fa-eye').show()
                $('.fa-eye-slash').hide()
            } else {
                e1.type = 'password'
                e2.type = 'password'
                $('.fa-eye').hide()
                $('.fa-eye-slash').show()
            }
        }

        function redirectBasedOnLink() {
            console.log('redirectBasedOnLink called: ', $scope.linkdata);
            if ($scope.linkdata.isForCompany_ShopStatistics) {
                console.log('This is for the PurisForCompany_ShopStatisticschase: ', $scope.linkdata);
                $state.go('brand.shop-detail', { nameSlug: $scope.linkdata.shop.nameSlug })
            } else if ($scope.linkdata.isForCompanyPurchaseorder && $scope.linkdata.purchaseOrderNumber) {
                $state.go('brand.purchase-order', { orderNo: $scope.linkdata.purchaseOrderNumber });
            } else {
                var role = $rootScope.user.role == 'wholesaler' ? 'brand' : $rootScope.user.role
                $state.go(role + '.home')
            }
        }

    }]);
