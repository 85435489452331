prismanoteApp.controller('searchSupplierModalController', ['$scope', '$uibModalInstance', 'companies',
  function ($scope, $uibModalInstance, companies) {
    $scope.companies = companies

    $scope.searchCompanies = function (str) {
      $scope.hits = []
      // console.log('str: ', str, $scope.companies.length);
      $scope.companies.forEach(function (item) {
        // console.log('item: ', item.name)
        if (item && ((item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                    (item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0))) {
          $scope.hits.push(item)
        }
      })
      // console.log('total hits: ', hits.length, hits);
      return $scope.hits
    }

    // code for autocomplete
    $scope.companySelected = function (supplier) {
      console.log('--company selcetd: ', supplier)
      $scope.close(supplier)
    }

    $scope.close = function (data) {
      $uibModalInstance.close(data)
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }
  }])
