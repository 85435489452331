prismanoteApp.controller('customerModalController', ['$scope', '$uibModalInstance', '$state', '$data', '$api', '$customer', '$language', 'customer', 'add', 'dialogs', '$rootScope', 'serviceApp',
    function ($scope, $uibModalInstance, $state, $data, $api, $customer, $language, customer, add, dialogs, $rootScope, serviceApp) {
        $scope.customer = customer;
        $scope.duplicatedCustomers = null;
        $scope.add = add;
        $scope.isSavingCustomer = false
        $scope.isMergingCustomers = false
        $scope.isSearchingForMergeCustomer = false

        $scope.serviceApp = serviceApp
        $scope.shopCountry = $rootScope.currentShop.address && $rootScope.currentShop.address.country ? $rootScope.currentShop.address.country: null;

        $scope.prefixes = $customer.getPrefixes()
        $scope.customDiscount = false

        $scope.saveCustomer = function (noClose) {
            $scope.alert = null;
            $scope.duplicatedCustomers = null;
            $scope.isSavingCustomer = true
            if ($scope.add) {
                $customer.getUniqueEmail($scope.customer)
                  .then(function (email) {
                      $scope.customer.email = email;
                      $customer.createCustomer($scope.customer, !serviceApp)
                        .then(function (result) {
                            $scope.isSavingCustomer = false
                            if(!noClose) {
                                $scope.close(result, 'created');
                            }
                        })
                        .catch(function (reason) {
                            $scope.isSavingCustomer = false
                            if (reason.status && reason.status === 409) {
                                $scope.alert = {
                                    type: 'warning',
                                    msg: $language.translate(reason.data.message)
                                }
                                $scope.duplicatedCustomers = reason.data.customers;
                                return;
                            }
                            $scope.alert = {
                                type: 'danger',
                                msg: reason
                            }
                        })
                  })
            } else {
                if (!$scope.customer.email || $scope.customer.email === '') {
                    if (!$scope.emailBackup) {
                        $customer.getUniqueEmail($scope.customer)
                          .then( function (email) {
                              $scope.customer.email = email
                              updateCustomer()
                          })
                    } else {
                        $scope.customer.email = $scope.emailBackup
                        updateCustomer()
                    }
                } else {
                    updateCustomer()
                }

                function updateCustomer () {
                    $customer.updateCustomer($scope.customer, !serviceApp)
                      .then(function (result) {
                          if (!noClose) {
                              $scope.close(result, 'updated')
                          }
                          $scope.isSavingCustomer = false
                      })
                      .catch(function (reason) {
                          $scope.alert = {
                              type: 'danger',
                              msg: reason
                          }
                          $scope.isSavingCustomer = false
                      })
                }
            }
        }


        if ($scope.customer) {
            if(!$scope.customer.paymentTermDays && $scope.customer.companyName) {
                $scope.customer.paymentTermDays = 14
                $scope.saveCustomer(true)
            }

            if ($scope.customer.dateOfBirth) {
                $scope.customer.dateOfBirth = new Date($scope.customer.dateOfBirth)
            }

            if($scope.customer.email && $scope.customer.email.indexOf('autocreated-') >= 0) {
                $scope.emailBackup = angular.copy($scope.customer.email)
                $scope.customer.email = null
            }

            if ($scope.customer.invoiceAddress){
                $scope.customer.invoiceAddress.zipcode = true;
                if(!$scope.customer.invoiceAddress.country){
                    $scope.customer.invoiceAddress.country = $scope.shopCountry;
                }
            }else{
                $scope.customer.invoiceAddress = { 
                    country: $scope.shopCountry,
                    zipcode: true 
                };
            }
            if ($scope.customer.shippingAddress){
                $scope.customer.shippingAddress.zipcode = true;

                if(!$scope.customer.shippingAddress.country){
                    $scope.customer.shippingAddress.country = $scope.shopCountry
                }
            }else{
                $scope.customer.shippingAddress = { 
                    country: $scope.shopCountry,
                    zipcode: true 
                };
            }
        }else{
            $scope.customer = {
                invoiceAddress: {
                    country: $scope.shopCountry,
                },
                shippingAddress: {
                    country: $scope.shopCountry,
                }
            }
        }

        if($scope.add && $rootScope.currentShop.cashRegister && $rootScope.currentShop.cashRegister.whatsappDefault) {
            if(!$scope.customer.phone) {
                $scope.customer.phone = {}
            }
            $scope.customer.phone.whatsappMobile = true
        }

        $scope.copyAddress = function () {
            $scope.customer.shippingAddress = angular.copy($scope.customer.invoiceAddress);
        }

        $scope.genderChanged = function (gender) {
            $scope.customer.gender = gender;
            $scope.customer.salutation = gender === 'MALE' ? 'MR' : gender === 'FEMALE' ? 'MRS' : null
        }

        $scope.openCustomer = function () {
            $scope.close($scope.duplicatedCustomers[0], 'open');
        }

        $scope.close = function (customer, action) {
            var result = {
                customer: customer,
                action: action
            }
            $uibModalInstance.close(result);
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        }

        $scope.delete = function () {
            var dlg = dialogs.confirm($language.translate('DELETE'), $language.translate('ARE_YOU_SURE_TO_DELETE_THIS_CUSTOMER'), { size: 'sm' })
            dlg.result.then(function (btn) {
                if (btn === 'yes') {
                    $scope.loading = true
                    $customer.deleteCustomer($scope.customer._id, !serviceApp)
                        .then(function (result) {
                            $scope.loading = false
                            
                            $uibModalInstance.close()
                            $state.go('retailer.customers')
                        })
                        .catch(function (reason) {
                            dialogs.error(undefined, reason)
                        })
                }
            })
                .catch(function (reason) {
                    console.error(reason)
                })
        }

        $scope.$watch('customer.invoiceAddress.country', function () {
			      $scope.getAddressInfo('invoiceAddress');
        }, true)
        
        $scope.$watch('customer.shippingAddress.country', function () {
			      $scope.getAddressInfo('shippingAddress');
		    }, true)

        $scope.getAddressInfo = function (type) {
            var address = $scope.customer[type];
            
            if (!address || !address.postalCode || !address.houseNumber || address.postalCode.length < 6 || address.country !== 'NL') {
                delete address.zipcode;
                return;
            }

            address.zipcode = true;

            $api.post('get-address-info', { zipcode: address.postalCode, number: address.houseNumber })
                .then(function (result) {
                    $scope.customer[type].street = result.data.address.street;
                    $scope.customer[type].city = result.data.address.city;
                })
                .catch(function (reason) {
                    address.zipcode = false;
                    console.error("Error while getting data from postalcode", reason)
                })
        }

        $scope.deleteAddress = function (type) {
            $scope.customer[type] = null;
        }

        $scope.avoidSpace = function(event) {
            var k = event ? event.which : window.event.keyCode;
            
            if (k == 32) {
              event.preventDefault();
            }
        }

        function titleCase (phrase) {
            return phrase.toLowerCase().split(' ').map(function(word){
                return word.charAt(0).toUpperCase() + word.slice(1)
            }).join(' ')
        }

        $scope.capitalize = function(value, str) {
            if(!value) {
                return
            }

            $scope.customer[str] = titleCase(value)
        }

        $scope.customerName = function(customer) {
            return $customer.makeCustomerName(customer)
        }

        $scope.selectToMerge = function(index) {
            $scope.selectedCustomerForMerge = $scope.hits[index]
            $scope.selectedCustomerIndex = index
        }

        $scope.searchCustomer = function (value) {
            if(value.length < 2) {
                return
            }
            $scope.isSearchingForMergeCustomer = true

            $scope.hits = []
            $api.post('customers/search', {
                search: value,
                shopId: $rootScope.currentShop._id
            })
              .then(function(response) {
                    $scope.isSearchingForMergeCustomer = false
                  $scope.hits = response.data.customers.filter(function (c) {
                      return c._id !== $scope.customer._id
                  })
                  
              })
              .catch(function(reason){
                  console.error('Error while searching customers')
                  console.error(reason)
                  $scope.isSearchingForMergeCustomer = false
            })
        }

        $scope.merge = function () {
            $scope.mergeCustomer = true
        }

        $scope.cancelMerge = function () {
            $scope.mergeCustomer = false
        }

        $scope.searchFilter = function (item) {
            return item._id.toString() !== $scope.customer._id
        }

        $scope.startMerge = function () {
            $scope.isMergingCustomers = true
            $scope.mergeAlert = null
            $api.post('customers/merge', {
                customerId: $scope.customer._id,
                newCustomerId: $scope.selectedCustomerForMerge._id,
                shopId: $rootScope.currentShop._id
            })
            .then(function (res) {
                $scope.isMergingCustomers = false
                $scope.close($scope.selectedCustomerForMerge, 'merge')
            })
            .catch(function (reason){
                console.error('Error while merging customer', reason)
                $scope.isMergingCustomers = false
                $scope.mergeAlert = {
                    type: 'danger',
                    msg: reason
                }
            })
        }

        $scope.addDiscount = function(value, percent) {
            if(!$scope.customer.discount) {
                $scope.customer.discount = {}
            }
            $scope.customer.discount.value = value
            $scope.customer.discount.percent = percent
        }

        $scope.getDiscount = function(value, percent) {
            return $scope.customer.discount && $scope.customer.discount.value === value && $scope.customer.discount.percent === percent
        }

        $scope.toggleCustomDiscount = function () {
            $scope.customDiscount = !$scope.customDiscount
        }

        $scope.removeDiscount = function() {
            $scope.customer.discount = {}
        }

        $scope.addExtraField = function (form) {
            if(!$scope.customer.extraFields) {
                $scope.customer.extraFields = []
            }
            $scope.customer.extraFields.push({
                name: form.name,
                content: form.content
            })

            $scope.extraField = {}
        }

        $scope.removeExtraField = function (index) {
            $scope.customer.extraFields.splice(index, 1)
        }

    }]);