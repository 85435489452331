prismanoteApp.controller('addCompanyInBrandModalController', ['$scope', '$uibModalInstance', '$uibModal', '$api', '$rootScope', '$q',
  function ($scope, $uibModalInstance, $uibModal, $api, $rootScope, $q) {
    $scope.companies = {}
    $scope.closeModal = function () {
      $uibModalInstance.close($scope.wholesaler)
    }

    $scope.cancel = function () {
      console.log('Close the model ')
      $uibModalInstance.dismiss('cancel')
    }

    $scope.searchValue = ''
    $scope.searchMode = 'exact'

    $scope.findCompanies = function (value, searchMode) {
      $scope.hits = []

      if (value) {
        if (value.length < 3) return
        if ($rootScope.currentShop !== undefined) {
          $api.get('webshop-companies-search/' + $rootScope.currentShop._id + '/' + searchMode + '/' + value, { private: false }, { reset: true })
            .then(function (response) {
              $scope.hits = response.data.companies
            })
            .catch(function (reason) {
              console.error(reason)
            })
        }
      }
    }

    $scope.addCompanyToShop = function (company) {
      $uibModalInstance.dismiss('cancel')
      return $q(function (resolve, reject) {
        if (company.brands && company.brands.length > 0 && company.type === 'supplier') {
          var modalInstance = $uibModal.open({
            templateUrl: '../views/modal/retailer-add-company-brand-confirmation.html',
            controller: 'retailerAddCompanyBrandConfirmationModalController',
            size: 'lg',
            resolve: {
              data: function () {
                return company
              }
            }
          })

          modalInstance.result
            .then(function (response) {
              resolve(response)
            })

            .catch(function (reason) {
              reject(reason)
            })
        } else {
          var body = {
            shopId: $rootScope.currentShop._id,
            companyId: company._id,
            brands: company.brands
          }

          $api.post('shop-companies', body)
            .then(function (response) {
              $rootScope.$emit('updateCompanyList', 'done')
            })
            .catch(function (reason) {
              console.error(reason)
            })
        }
      })
    }
  }])
