prismanoteApp.controller('brandCampaignsController', ['$scope', '$rootScope', '$api', '$brandCampaign', '$company', '$state',
  function ($scope, $rootScope, $api, $brandCampaign, $company, $state) {
    $scope.getCompany = function () {
      $scope.loading = true
      $company.getCompany()
        .then(function (company) {
          console.log('company: ', company)
          $scope.loading = false
          $scope.showError = false
          $scope.currentCompany = company
          $scope.getCampaignsByCompanyId()
        })
        .catch(function (reason) {
          console.log('reason coampangs ', reason)
          $scope.loading = false
          $scope.showError = true
          $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
        })
    }

    $scope.getCampaignsByCompanyId = function () {
      $scope.loading = true
      $brandCampaign.getCampaignsByCompanyId($scope.currentCompany._id).then(function (campaigns) {
        console.log('campaigns: ', campaigns)
        $scope.campaigns = campaigns
        CampaignCountDown()
        $scope.loading = false
      }).catch(function (error) {
        console.log(error)
        $scope.loading = false
        $scope.showError = true
        $scope.errorMessage = error.errorMessage ? error.errorMessage : 'Something went wrong'
      })
    }

    function CampaignCountDown () {
      // console.log('CampaignCountDown fn called: ', $scope.campaigns);
      if ($scope.campaigns.length) {
        for (var i = 0; i < $scope.campaigns.length; i++) {
          // console.log(i, new Date($scope.campaigns[i].startDate).setHours(19, 0, 0));
          // if (!i) timer(new Date($scope.campaigns[i].startDate).setHours(19, 0, 0));
          // if (!i) {
          // console.log('i*****************', (new Date($scope.campaigns[i].startDate).setHours(19, 0, 0)) - new Date().getTime());
          if ($scope.campaigns[i] && $scope.campaigns[i].tasks.length && $scope.campaigns[i].tasks[0].firstInvitationEmail && $scope.campaigns[i].tasks[0].firstInvitationEmail.date) {
            var milliseconds = (new Date($scope.campaigns[i].tasks[0].firstInvitationEmail.date).setHours(19, 0, 0)) - new Date().getTime()
            // console.log('days: ', $scope.campaigns[i]._id, new Date($scope.campaigns[i].tasks[0].firstInvitationEmail.date), new Date().getTime(), milliseconds);
            if (milliseconds <= 0) {
              secondInvitationEmailCountDown()
            } else {
              firstInvitationEmailCountDown()
            }

            function firstInvitationEmailCountDown () {
              var seconds = milliseconds / 1000
              // function timer(i) {
              var days = Math.floor(seconds / (60 * 60 * 24))
              var hoursLeft = Math.floor((seconds) - (days * 86400))
              var hours = Math.floor(hoursLeft / 3600)
              var minutesLeft = Math.floor((hoursLeft) - (hours * 3600))
              var minutes = Math.floor(minutesLeft / 60)
              var remainingSeconds = seconds % 60
              function pad (n) {
                return (n < 10 ? '0' + n : n)
              }
              var curretnTimer = days + ' days, ' + pad(hours) + ':' + pad(minutes) + ':' + pad(remainingSeconds.toFixed(0))
              $scope.campaigns[i].timer = curretnTimer
            }

            function secondInvitationEmailCountDown () {
              if ($scope.campaigns[i] && $scope.campaigns[i].tasks.length && $scope.campaigns[i].tasks[0].secondInvitationEmail && $scope.campaigns[i].tasks[0].secondInvitationEmail.date) {
                var second_milliseconds = (new Date($scope.campaigns[i].tasks[0].secondInvitationEmail.date).setHours(19, 0, 0)) - new Date().getTime()
                if (second_milliseconds > 0) {
                  var seconds = second_milliseconds / 1000
                  var days = Math.floor(seconds / (60 * 60 * 24))
                  var hoursLeft = Math.floor((seconds) - (days * 86400))
                  var hours = Math.floor(hoursLeft / 3600)
                  var minutesLeft = Math.floor((hoursLeft) - (hours * 3600))
                  var minutes = Math.floor(minutesLeft / 60)
                  var remainingSeconds = seconds % 60
                  function pad (n) {
                    return (n < 10 ? '0' + n : n)
                  }
                  var curretnTimer = days + ' days, ' + pad(hours) + ':' + pad(minutes) + ':' + pad(remainingSeconds.toFixed(0))
                  $scope.campaigns[i].timer = curretnTimer
                }
              }
            }
          }

          // console.log(pad(days) + ":" + pad(hours) + ":" + pad(minutes) + ":" + pad(remainingSeconds));
          // seconds--;
          // document.getElementById('countdown').innerHTML = pad(days) + ":" + pad(hours) + ":" + pad(minutes) + ":" + pad(remainingSeconds);
          // if (seconds == 0) {
          // 	clearInterval(countdownTimer);
          // 	document.getElementById('countdown').innerHTML = "Completed";
          // } else {
          // 	seconds--;
          // }
          // }
          // setInterval(function () {
          // 	timer(0);
          // }, 1000)
          // }
        }
      }
    }
  }])
