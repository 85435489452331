prismanoteApp.controller('shopBrandPurchaseOrdersController', ['$scope', '$rootScope', '$state', '$stateParams', '$api', '$purchaseOrder', '$language',
  function ($scope, $rootScope, $state, $stateParams, $api, $purchaseOrder, $language) {
    $rootScope.pageTitle = 'PrismaNote Brand Portal | ' + $language.translate('PURCHASE_ORDERS')
    $scope.purchase_orders = []
    $scope.isLoaded = false
    $scope.total = $scope.purchase_orders.length
    $scope.viewby = 50
    $scope.itemsPerPage = parseInt($scope.viewby)
    $scope.currentPage = 0 // reset to first page
    $scope.statusFilter = 'ALL_ITEMS' // Default show all transactions

    $scope.searchIntoPurchaseOrder = function (value){
      var body = { searchField: value }
      $api.get('company-purchase-orders/' + $rootScope.currentCompany._id + '/' + $scope.statusFilter, body)
          .then(function (response) {
            $scope.isLoaded = true
            $scope.originalOrder = JSON.parse(JSON.stringify(response.data.purchaseOrders))
            $scope.purchase_orders = response.data.purchaseOrders
            $scope.total = response.data.total
            $scope.shop_group = response.data.shop_group
            $scope.loading = false
          })
          .catch(function (reason) {
            $scope.isLoaded = true
            console.error('Error while getting the order list', reason)
            $scope.loading = false
          })
    }

    $scope.$on('changeSelectedCompany', function () {
      if ($rootScope.currentCompany) {
        var currentCompany = $rootScope.currentCompany
        // console.log("Current ", $rootScope.currentCompany.name);
        // console.log('Get Purchase Order List Called 1');
        $scope.getThePurchaseOrderList(currentCompany)
      } else {
        // console.log("$", $rootScope);
        $rootScope.$watch('currentCompany', function () {
          // console.log(" =====>>> dsfjhsadgf", $rootScope.currentCompany);

        })
      }
    })

    $scope.getThePurchaseOrderList = function (currentCompany) {
      // console.log('$scope.shop_group: ', $scope.shop_group);
      // console.log("getThePurchaseOrderList called");
      if (currentCompany) {
        var beforeDate = new Date()
        $scope.loading = true
        // console.log('--------------------purchaser order list: called ------------------');
        var page = $scope.currentPage + 1
        var body = {
          limit: $scope.itemsPerPage,
          pageNumber: page
        }
        var filters = []
        if ($scope.shop_group && $scope.shop_group.length) {
          for (var i = 0; i < $scope.shop_group.length; i++) {
            filters.push($scope.shop_group[i])
          }
          body.shop_filter = { shop_filter: filters }
        }
        $api.get('company-purchase-orders/' + $rootScope.currentCompany._id + '/' + $scope.statusFilter, body)
          .then(function (response) {
            var aftereDate = new Date()
            console.log('response get: ', (aftereDate - beforeDate) / 1000)
            $scope.isLoaded = true
            $scope.originalOrder = JSON.parse(JSON.stringify(response.data.purchaseOrders))
            $scope.purchase_orders = response.data.purchaseOrders
            $scope.total = response.data.total
            $scope.shop_group = response.data.shop_group
            $scope.loading = false
          })
          .catch(function (reason) {
            $scope.isLoaded = true
            console.error('Error while getting the order list', reason)
            $scope.loading = false
          })
      }
    }

    $scope.setItemsPerPage = function (num) {
      $scope.itemsPerPage = parseInt(num)
      $scope.currentPage = 0 // reset to first page
      // console.log('Get Purchase Order List Called 2');
      $scope.getThePurchaseOrderList($rootScope.currentCompany)
    }

    $scope.changeThePage = function (direction) {
      var totalPages = Math.ceil($scope.total / $scope.itemsPerPage)
      // console.log(totalPages);
      if (direction == 'next') {
        var page = $scope.currentPage + 1
        if ((page + 1) <= totalPages) {
          $scope.currentPage = page
          // console.log('Get Purchase Order List Called 3');
          $scope.getThePurchaseOrderList($rootScope.currentCompany)
        }
      } else if (direction == 'previous') {
        var page = $scope.currentPage - 1
        if (page < 0) {
          // do nothing but just refresh the page
          // $scope.getThePurchaseOrderList();
        } else {
          $scope.currentPage = page
          // console.log('Get Purchase Order List Called 4');
          $scope.getThePurchaseOrderList($rootScope.currentCompany)
        }
      }
    }

    $scope.setStatusFilter = function (statusType) {
      $scope.statusFilter = statusType
      // console.log('Get Purchase Order List Called 5');
      $scope.getThePurchaseOrderList($rootScope.currentCompany)
    }

    // // Initializing Purchaser Order List of Company/Supplier
    // $scope.setItemsPerPage($scope.viewby);

    $scope.NewPurchaseOrder = function () {
      $api.get('getDefaultPurchaseOrderNo?companyId=' + $rootScope.currentCompany._id)
        .then(function (response) {
          $rootScope.isPurchaseOrderNew = true
          $state.go('brand.purchase-order', { orderNo: response.data.purchaseOrderNumber })
        })
        .catch(function (reason) {
          console.error('Error while getting default product number', reason)
        })
    }

    $scope.autocreateorder = function() {
      $state.go('brand.autocreated-purchase-order')
    }

    $scope.getPurchaseOrders = function (index) {
      // console.log('Get Purchase Order List Called 6');
      $scope.getThePurchaseOrderList($rootScope.currentCompany)
    }
  }])
