prismanoteApp.controller('addNewPurchaseOrderModalController',
  ['$scope', '$uibModalInstance', 'prompt', '$rootScope', '$state', '$purchaseOrder', '$uibModal', '$language',
    function ($scope, $uibModalInstance, prompt, $rootScope, $state, $purchaseOrder, $uibModal, $language) {
      console.log('addNewPurchaseOrderModalController called')
      $scope.showUploadFile = false;
      $scope.purchaseOrderFile = {}

      $scope.closeModal = function (option) {
        $uibModalInstance.close(option)
      }

      $scope.CreatePurchaseOrder = function (recommendationBased) {
        if(recommendationBased){
          // Uncoment the below code to enable the webshop B2B ordering
          $state.go('retailer.recommendation-order', { orderNo: '' });
          $scope.closeModal();
        }else{
        // Comment the below code to disable the webshop B2B ordering
          $purchaseOrder.getPurchaseOrderNumber($rootScope.currentShop._id).then(function (purchaseOrderNumber) {
            $scope.closeModal({ status: 'create', data: purchaseOrderNumber })
          }).catch(function (error) {
            $scope.closeModal({ status: 'error', data: error })
          })
        }
      }

      $scope.BookPurchaseOrder = function () { 
        $purchaseOrder.getPurchaseOrderNumber($rootScope.currentShop._id).then(function (purchaseOrderNumber) {
          $scope.closeModal({ status: 'already_received', data: purchaseOrderNumber })
        }).catch(function (error) {
          $scope.closeModal({ status: 'error', data: error })
        })
      }

      $scope.UploadEDIFile = function () {
        // $scope.showUploadFile = !$scope.showUploadFile;
        $purchaseOrder.getPurchaseOrderNumber($rootScope.currentShop._id).then(function (purchaseOrderNumber) {
          $scope.closeModal({ status: 'upload_EDI', data: purchaseOrderNumber })
        }).catch(function (error) {
          $scope.closeModal({ status: 'error', data: error })
        })
      }

    $scope.uploadFiles = function (file) {
      if (file && file.length) {

        $scope.purchaseOrderFile = file[0]

        var shop = {
          _id: $rootScope.currentShop._id,
          name: $rootScope.currentShop.name,
          nameSlug: $rootScope.currentShop.nameSlug,
          address: $rootScope.currentShop.address,
          firstCall: true
        }

        var language = $rootScope.language || 'en'

        $scope.loading = true 

        if($scope.purchaseOrderFile && $scope.purchaseOrderFile.name && $scope.purchaseOrderFile.name.indexOf('.dat') !== -1) {
          prompt({
            title: $language.translate('WARNING'),
            danger: $language.translate('MAKE_SURE_YOUR_DAT_FILE_IS_FROM_BERING'),
            warning: $language.translate('MAKE_SURE_YOUR_DAT_FILE_IS_FROM_BERING_2'),
            buttons: [{ label: $language.translate('CONTINUE'), primary: true }, { label: $language.translate('CANCEL'), primary: false, cancel: true }],
            backdrop: 'static'
          }).then(function (response) {
              if (response.primary) {
                $scope.continueUploadFile(shop,language)
              }else {
                $scope.loading = false
                $scope.closeModal(); 
                $state.go('retailer.purchase-orders');
              }
          }).catch(function (error) {
              $scope.loading = false
              $scope.closeModal(); 
              $state.go('retailer.purchase-orders');
          })
        } else {
          $scope.continueUploadFile(shop,language)
        }
      }
    }

	  $scope.continueUploadFile = function(shop,language) {
		$purchaseOrder.uploadFileAndCreatePurchaseOrder($scope.purchaseOrderFile, shop, language).then(function (response) {
            console.log('final response: ', response)

            if(response && response.data && response.data.data && response.data.data.purchaseOrderExists){
              prompt({
                title: $language.translate('WARNING'),
                message: $language.translate('YOU_ALREADY_HAVE_PO_WITH_THIS_NUMBER'),
                backdrop: 'static',
                buttons: [{ label: $language.translate('READ_IN_AGAIN'), primary: true }, { label: $language.translate('CANCEL'), primary: false, cancel: true }]
              }).then(function (response) {
                  if (response.primary) {
                    shop.firstCall = false;
                    $purchaseOrder.uploadFileAndCreatePurchaseOrder($scope.purchaseOrderFile, shop, language).then(function (response) { 
                      $scope.loading = false
                      $rootScope.isPurchaseOrderNew = false
                      if (response && response.data && response.data.data && response.data.data.data) {
                        var purchaseOrderDetail = response.data.data.data
                        $scope.closeModal(purchaseOrderDetail)
                        if (purchaseOrderDetail.purchaseOrders && purchaseOrderDetail.purchaseOrders.length) {
                          OpenPurchaseOrderBrandModal(purchaseOrderDetail)
                        }
                      } else {
                        $scope.alert = {
                          type: 'danger',
                          msg: 'Unsupported file or something went wrong'
                        };
                      }
                    })
                  }else {
                    console.log('--- else!!');
                    $scope.loading = false
                    $scope.closeModal(); 
                    $state.go('retailer.purchase-orders');
                  }
              }).catch(function (error) {
                  console.log('error: inprompnt: ', error)
                  $scope.loading = false
                  $scope.closeModal(); 
                  $state.go('retailer.purchase-orders');
              })
            }else{
              $scope.loading = false
              $rootScope.isPurchaseOrderNew = false
              if (response && response.data && response.data.data && response.data.data.data) {
                var purchaseOrderDetail = response.data.data.data
                $scope.closeModal(purchaseOrderDetail)
                // $state.go('retailer.purchase-orders');
  
                if (purchaseOrderDetail.purchaseOrders && purchaseOrderDetail.purchaseOrders.length) {
                  OpenPurchaseOrderBrandModal(purchaseOrderDetail)
                }
              } else {
                $scope.alert = {
                  type: 'danger',
                  msg: 'Unsupported file or something went wrong'
                }
              }
            }
          }).catch(function (error) {
            console.log(error)
            // $scope.closeModal(error);
            $scope.loading = false
            $rootScope.isPurchaseOrderNew = false
            $scope.alert = {
              type: 'danger',
              msg: error.data && error.data.message ? error.data.message : 'Unsupported file or something went wrong'
            }
          })
	  }


      // Open Modal which has multiple purchase order from the EDI File, and show supplier.brands which has starting digits
      function OpenPurchaseOrderBrandModal(purchaseOrderDetail) {
        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/choose-brand-for-purchase-order-product-modal.html',
          controller: 'chooseBrandForPurchaseOrderProductModalController',
          size: 'lg',
          resolve: {
            purchaseOrderDetail: function () {
              return purchaseOrderDetail
            }
          }
        })

        modalInstance.result.then(function (result) {
          console.log('result: ', result)
          $state.go('retailer.purchase-orders')
        }, function (err) {
          console.log('error: ', err)
        })
      }
    }])
