prismanoteApp.controller('warningMergeCompanyModalController', ['$scope', '$uibModalInstance', '$language', 'data',
  function ($scope, $uibModalInstance, $language, data) {
    $scope.selectedCompany = data.selectedCompany
    $scope.searchedCompany = data.searchedCompany

    $scope.notes = [
      $scope.searchedCompany.name + $language.translate('IS_MY_NEW_BUSINESS_PARTNER_WHOM_I_HAVE_A_BUSINESS_RELATIONSHIP'),
      $language.translate('ASSIGN_ALL_MY_PRODUCTS_WHICH_ARE_NOW_ASSIGNED_TO_') + $scope.selectedCompany.name + $language.translate('TO_THE_NEWLY_SELECTED_COMPANY'),
      $language.translate('ANY_NEW_PURCHASE_ORDERS_ARE_SENT_TO_') + $scope.searchedCompany.name + $language.translate('_OLD_ORDERS_ARE_ASSIGNED_TO_') + $scope.searchedCompany.name + '.',
      $language.translate('ASSIGN_EXISTING_REQUESTS_TO_') + $scope.selectedCompany.name + $language.translate('_TO_THE_SELECTED_COMPANY_') + $scope.searchedCompany.name + '.'
    ]
    $scope.cancel = function () {
      $uibModalInstance.close('cancel')
    }

    $scope.MergeCompany = function () {
      $uibModalInstance.close('mergeCompany')
    }
  }])
