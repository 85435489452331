prismanoteApp.controller('retailerDayclosureController', ['$scope', '$rootScope', '$retailer', '$api', '$state', '$language', '$stateParams', 'dialogs', '$printer', 'FileSaver', '$http', '$card', '$uibModal', '$pdf', '$thermalPrint', '$log',
  function ($scope, $rootScope, $retailer, $api, $state, $language, $stateParams, dialogs, $printer, FileSaver, $http, $card, $uibModal, $pdf, $thermalPrint, $log) {
    $scope.totalCashCounted = 0
    $scope.totalTurnover = 0
    $scope.loadDayState = false
    $scope.cashTurnover = 0
    $scope.takenMoney = 0
    $scope.totalTaken = []
    $scope.showTakenMoney = true
    $scope.alert = null
    $scope.reason = null
    $scope.statesLoading = false
    $scope.printerEnabled = false
    $scope.incomingsCollapsed = true
    $scope.vatTopay = 0;
    $scope.showOldRecords = false;
    $scope.endOfYearMessage = false;

    $scope.allCards = $card.getCards()

    $scope.amounts = [
      [
        {
          amount: 50000,
          quantity: 0
        },
        {
          amount: 20000,
          quantity: 0
        },
        {
          amount: 10000,
          quantity: 0
        },
        {
          amount: 5000,
          quantity: 0
        },
        {
          amount: 2000,
          quantity: 0
        },
        {
          amount: 1000,
          quantity: 0
        },
        {
          amount: 500,
          quantity: 0
        },
      ], [
        {
          amount: 200,
          quantity: 0
        },
        {
          amount: 100,
          quantity: 0
        },
        {
          amount: 50,
          quantity: 0
        },
        {
          amount: 20,
          quantity: 0
        },
        {
          amount: 10,
          quantity: 0
        },
        {
          amount: 5,
          quantity: 0
        }
      ]
    ]

    $scope.$watch('amounts', function (newValue, oldValue) {
      $scope.totalCashCounted = 0
      var totalCounted = 0
      $scope.manuallyCashCounted = 0

      for (var i = 0; i < newValue.length; i++) {
        for (var a = 0; a < newValue[i].length; a++) {
          totalCounted += newValue[i][a].quantity * (newValue[i][a].amount / 100)
          
          var cashCounted = +(Math.round(totalCounted + "e+2")  + "e-2"); //https://stackoverflow.com/a/18358056
          $scope.totalCashCounted = cashCounted
          $scope.manuallyCashCounted = cashCounted
        }
      }
    }, true)

    $scope.editValues = function () {
      $scope.getTotalTurnover()
      $scope.getCashTurnOver()
    }

    $scope.getTotalTurnover = function () {
      if (!$scope.dayState) { return }
      $scope.totalTurnover = 0
      for (var i = 0; i < $scope.dayState.payments.length; i++) {
        if ($scope.dayState.payments[i].method.toUpperCase() === 'CARD') {
          $scope.totalTurnover += $scope.getTotalCards($scope.dayState.payments[i])
        } else if($scope.dayState.payments[i].method.toUpperCase() !== 'PAYLATER') {
          $scope.totalTurnover += $scope.dayState.payments[i].amount
        }
      }
    }

    $scope.getCashTurnOver = function () {
      var index = _.findIndex($scope.payments, { method: 'CASH' })

      if (index >= 0) {
        $scope.cashTurnover = ($scope.payments[index].amount + $scope.dayState.cashInTill) - $scope.takenMoney
      } else {
        $scope.cashTurnover = $scope.dayState.cashInTill - $scope.takenMoney
      }
    }

    function getShop (callback) {
      if (!$rootScope.currentShop) {
        $retailer.getShop()
          .then(function (shop) {
            $rootScope.currentShop = shop
            return callback(shop)
          })
      } else {
        return callback($rootScope.currentShop)
      }
    }

    $scope.$on('changeSelectedShop', function () {
      getShop(function (shop) {
      })
    })

    $scope.addMissingCards = function () {
      var cardPayments = _.findIndex($scope.payments, { method: 'CARD' })
      if (cardPayments >= 0) {
        var cards = $scope.payments[cardPayments].cards
        for (var i = 0; i < $scope.allCards.length; i++) {
          var index = _.findIndex(cards, { cardName: $scope.allCards[i].name })
          if (index < 0) {
            cards.push({
              amount: 0,
              amountBk: 0,
              cardName: $scope.allCards[i].name
            })
          }
        }
      }
    }

    $scope.backupCurrentPaymentTotals = function () {
      for(var i = 0; i < $scope.payments.length; i++) {
        if($scope.payments[i].method.toUpperCase() === 'CARD'){
          $scope.payments[i].amountBk = angular.copy($scope.payments[i].amount)
          for(var c = 0; c < $scope.payments[i].cards.length; c++) {
            $scope.payments[i].cards[c].amountBk = angular.copy($scope.payments[i].cards[c].amount)
          }
        } else{
          $scope.payments[i].amountBk = angular.copy($scope.payments[i].amount)
        }
      }
    }

    $scope.round = function (num) {
      return + (Math.round(num + 'e+2') + 'e-2');
    }

    $scope.getData = function () {
      $scope.loadDayState = true
      $scope.alert = null
      $scope.reason = null

      var date = new Date()
      $scope.endOfYearMessage = (date.getMonth() === 11 && date.getDay() > 16) ? true : false //only show in the last two weeks of december

      getShop(function (shop) {
        $api.get('daystate', {
          shop: shop._id
        })
          .then(function (earnings) {
            $scope.loadDayState = false

            $scope.startDate = earnings.data.startDate
            $scope.earnings = earnings.data.earnings
            $scope.gold = earnings.data.gold
            //$scope.payments = earnings.data.payments
            $scope.payments = earnings.data.payments.map( (function (p){
              if(p.cards && p.cards.length > 0) {
                p.cards = p.cards.map( function (c) {
                  //c.amount = Math.round(( (c.amount + Number.EPSILON )* 100) / 100)
                  c.amount = $scope.round(c.amount)
                  return c
                })
              }
              p.amount = $scope.round(p.amount)
              return p
            }))
            $scope.cards = earnings.data.cards
            $scope.paymentTotal = 0
            $scope.dayState = earnings.data.state
            $scope.collections = earnings.data.collections

            $scope.paymentTotal = _.sumBy($scope.payments, 'amount')
            $scope.taxOwn = 0
            $scope.totalRevenue = 0
            if($scope.earnings && $scope.earnings.taxes) {
              for (var key in $scope.earnings.taxes) {
                $scope.taxOwn += $scope.earnings.taxes[key].vat
                $scope.totalRevenue += $scope.earnings.taxes[key].withVat
              }
            }
            $scope.totalRevenue += ($scope.gold.revenue + $scope.earnings.giftcardBalance)

            var toLeft = localStorage.getItem('dayStateToLeft')
            if (toLeft && parseFloat(toLeft) > 0) {
              $scope.dayState.toLeft = parseFloat(toLeft)
            }else{
              $scope.dayState.toLeft = 0
            }
            var date = new Date('2021-11-30');
            if($scope.startDate && new Date($scope.startDate) < date) {
              $scope.showOldRecords = true;
            }
            getTotalvat();
            $scope.backupCurrentPaymentTotals()
            $scope.addMissingCards()
            $scope.getTotalTurnover()
            $scope.getTakenMoney()
            $scope.getCashTurnOver()
          })
          .catch(function (reason) {
            console.error('Error while getting daystate', reason)
            $scope.loadDayState = false
            $scope.reason = reason
            $scope.alert = {
              type: 'danger',
              msg: $language.translate(reason.toUpperCase())
            }
          })
      })
    }

    $scope.getTotalCards = function (payment) {
      if (!payment.cards || payment.cards.length === 0) {
        return payment.amount
      }

      var total = 0; var counter = 0
      for (var i = 0; i < payment.cards.length; i++) {
        total += payment.cards[i].amount
        counter++
        if (counter === payment.cards.length) {
          return total
        }
      }
    }

    $scope.closeState = function () {
      if ($scope.dayState.skim === 0 && $scope.totalCashCounted === 0) {
        var dlg = dialogs.confirm(
          $language.translate('WARNING'),
          $language.translate('YOU_HAVE_NO_ENTERED_ANY_AMOUNT_THIS_WILL_RESULT_IN_ZERO_CASH_CONTINUE'),
          {
            size: 'md'
          })
        dlg.result.then(function (btn) {
          close()
        }, function (btn) {

        })
      } else {
        close()
      }

      function close () {
        var countedCash = function () {
          if ($scope.totalCashCounted === 0) {
            if ($scope.dayState.skim > 0) {
              return $scope.cashTurnover - $scope.dayState.skim
            } else {
              return 0
            }
          } else {
            return $scope.totalCashCounted
          }
        }

        var revenue = function () {
          var taxes = { }
          for(var key in $scope.earnings.taxes) {
            if ($scope.earnings.taxes.hasOwnProperty(key)) {
              taxes[key] = {
                amountWithVat: $scope.earnings.taxes[key].withVat,
                tax: $scope.earnings.taxes[key].vat
              }
            }
          }
          return taxes
        }

        $api.put('daystate', {
          shop: $rootScope.currentShop._id,
          currentShopId: $rootScope.currentShop._id, //security
          countedCash: countedCash(),
          skim: $scope.dayState.skim,
          _id: $scope.dayState._id,
          payments: $scope.payments,
          difference: $scope.totalCashCounted - $scope.cashTurnover,
          leftover: $scope.totalCashCounted - $scope.dayState.skim,
          income: {
            payLater: $scope.earnings.payLater,
            online: $scope.earnings.webshop,
            revenue: revenue(),
            giftcards: {
              issued: $scope.earnings.issuedGiftcards,
              taken: $scope.earnings.takenGiftcards,
              balance: $scope.earnings.giftcardBalance,
              tax: $scope.earnings.owedTax9
            },
            gold: {
              income: $scope.gold.revenue,
              purchased: $scope.gold.purchases,
              sold: $scope.gold.sells
            },
            totalTax: $scope.earnings.owedTax9 + $scope.taxOwn,
            totalRevenue: $scope.totalRevenue,
            totalEarning: $scope.earnings.totalEarning,
            collections: $scope.collections
          }
        })
          .then(function (result) {
            $scope.openState($scope.dayState._id, true)
            localStorage.setItem('dayStateToLeft', $scope.dayState.toLeft)
          })
          .catch(function (reason) {
            console.error('Error while closing daystate', reason)
          })
      }
    }

    $scope.getTakenMoney = function () {
      if (!$scope.dayState || !$scope.dayState.takenMoney) return

      $scope.takenMoney = 0
      $scope.totalTaken = []

      for (var i = 0; i < $scope.dayState.takenMoney.length; i++) {
        $scope.takenMoney += $scope.dayState.takenMoney[i].amount
        $scope.totalTaken.push($scope.dayState.takenMoney[i])
      }
    }

    $scope.getDayState = function () {
      $scope.reason = null
      $scope.alert = null
      $scope.showTakenMoney = true
      getShop(function (shop) {
        $api.get('daystate/' + $stateParams.stateId, {
          shop: shop._id
        })
          .then(function (state) {
            $scope.dayState = state.data.state
            $scope.taxOwn = 0
            $scope.totalRevenue = 0

            $scope.paymentTotal = _.sumBy($scope.dayState.payments, 'amount')

            if ($scope.dayState && $scope.dayState.income && $scope.dayState.income.revenue && $scope.dayState.income.revenue.length > 0) {
              for(var key in $scope.dayState.income.revenue) {
                $scope.taxOwn += $scope.dayState.income.revenue[key].tax
                $scope.totalRevenue += $scope.dayState.income.revenue[key].amountWithVat
              }
            }

            $scope.getTotalTurnover()
            $scope.getTakenMoney()
          })
          .catch(function (reason) {
            console.error(reason)
            $scope.reason = reason
            $scope.alert = {
              type: 'danger',
              msg: $language.translate(reason.toUpperCase())
            }
          })
      })
    }

    $scope.createState = function () {
      $scope.loadDayState = true
      $scope.alert = null
      $scope.reason = null
      getShop(function (shop) {
        $api.post('daystate', {
          shop: shop._id
        })
          .then(function (state) {
            $scope.loadDayState = false
            $scope.getData()
          })
          .catch(function (reason) {
            $scope.loadDayState = false
            console.error('Error while creating new daystate', reason)
            $scope.reason = reason
            $scope.alert = {
              type: 'danger',
              msg: $language.translate(reason.toUpperCase())
            }
          })
      })
    }

    $scope.getStates = function () {
      $scope.statesLoading = true
      getShop(function (shop) {
        $api.get('daystate/all', {
          shop: shop._id
        })
          .then(function (result) {
            $scope.statesLoading = false
            $scope.states = result.data.states
          })
          .catch(function (reason) {
            $scope.statesLoading = false
            console.error('Error while getting daystates', reason)
          })
      })
    }

    $scope.openState = function (id, newFormat) {
      if(newFormat === false) {
        $state.go('retailer.state-overview-detail-old', { stateId: id })
        return
      }

      $state.go('retailer.state-overview-detail', { stateId: id })
    }

    $scope.printState = function () {
      $scope.printerError = null
      $printer.printDayClosure($scope.dayState)
        .then(function () {
          $scope.printerError = {
            type: 'success',
            msg: $language.translate('STATE_PRINTED')
          }
        })
        .catch(function (reason) {
          console.error('Error while printing daystate', reason)
          $scope.printerError = {
            type: 'danger',
            msg: $language.translate(reason)
          }
        })
    }

    $scope.checkPrinter = function () {
      $scope.printerError = null
      return getShop(function (shop) {
        return $printer.checkEnabled(shop)
          .then(function () {
            $scope.printerEnabled = true
          })
          .catch(function (reason) {
            console.info(reason)
            $scope.printerEnabled = false
            return false
          })
      })
    }

    $scope.makePdf = function (print, labelDescription, withPP) {
      $scope.printerError = null

      $pdf.downloadPdf('daystate/pdf', {
        daystate: $scope.dayState,
        shopId: $rootScope.currentShop._id,
        language: $rootScope.language,
        deviceId: $rootScope.currentDevice._id,
        documentName: 'DAYSTATE',
        labelDescription: labelDescription,
        withPP: withPP
      }, $language.translate('DAY_CLOSURE') + ' ' + moment($scope.dayState.date).format('DD-MM-YYYY') + '.pdf','arraybuffer', print === false, print)
        .then(function (data) {

        })
        .catch(function (reason) {
          console.error('Error while generating receipt', reason)
          $scope.printerError = {
            type: 'danger',
            msg: reason.data.message || reason.data
          }
        })
    }

    $scope.editAmounts = function () {
      $scope.edit = !$scope.edit
    }

    $scope.changeManuallyCounted = function (amount) {
      $scope.totalCashCounted = amount

      for (var i = 0; i < $scope.amounts.length; i++) {
        for (var a = 0; a < $scope.amounts[i].length; a++) {
          $scope.amounts[i][a].quantity = 0
        }
      }
    }

    $scope.$watch('totalCashCounted', function () {
      $scope.calculateSkim()
    })

    $scope.takeMoney = function () {
      var takeMoneyModal = $uibModal.open({
        templateUrl: '../views/modal/retailer-take-money-modal.html',
        controller: 'retailerTakeMoneyModalController',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          stateId: function () {
            return $scope.dayState._id
          },
          drawer: function () {
            return false
          }
        }
      })

      takeMoneyModal.result.then(function (result) {
        $printer.printTakenMoneyReceipt(result.amount, result.reason)
          .then(function (result) {
            console.info('Taken Money receipt printed')
          })
          .catch(function (reason) {
            console.error('Error while printing thermal receipt for taken money', reason)
          })
        $scope.getData()
      }, function () {

      })
    }

    $scope.changeShowTakenMoney = function () {
      $scope.showTakenMoney = !$scope.showTakenMoney
    }

    function getTotalvat(){
      var purchasesTrue = $scope.gold.purchasesTrue || 0;
      var issuedGiftcardsTrue = $scope.earnings.issuedGiftcardsTrue || 0;
      var takenGiftcardsTrue = $scope.earnings.takenGiftcardsTrue || 0;
      var taxToPay = $scope.earnings.taxToPay;
      $scope.vatTopay = (taxToPay || 0) + ((purchasesTrue - (purchasesTrue/1.21)) || 0 ) + ((issuedGiftcardsTrue - (issuedGiftcardsTrue/1.21)) || 0 ) - ((takenGiftcardsTrue - (takenGiftcardsTrue/1.21)) || 0 );
    }

    $scope.openGenerateReportModal = function () {
      var reportModal = $uibModal.open({
        templateUrl: '../views/modal/retailer-generate-dayclosure-report-modal.html',
        controller: 'retailerGenerateDayClosureReportModalController',
        size: 'lg',
        keyboard: false,
        resolve: {
          startDate: function () {
            return null
          },
          endDate: function () {
            return null
          }
        }
      })

      reportModal.result.then(function (result) {

      }, function () {

      })
    }

    $scope.saveComment = function () {
      $scope.commentAlert = null
      $api.post('daystate/comment', {
        shopId: $rootScope.currentShop._id,
        stateId: $scope.dayState._id,
        comment: $scope.dayState.comment
      })
        .then(function (result) {
          $scope.commentAlert = {
            type: 'success',
            msg: $language.translate('COMMENT_SAVED')
          }
        })
        .catch(function (reason) {
          console.error(reason)
          $scope.commentAlert = {
            type: 'danger',
            msg: reason.message || $language.translate('ERROR_WHILE_UPDATING')
          }
        })
    }
    $scope.calculateSkim = function () {
      if (!$scope.dayState) { return }
      if (!$scope.dayState.toLeft || $scope.dayState.toLeft === 0) {
        $scope.dayState.skim = 0
      } else {
        $scope.dayState.skim = $scope.totalCashCounted - $scope.dayState.toLeft
      }
    }

    $scope.openOverview = function () {
      $state.go('retailer.state-overview')
    }

    $scope.openCashDrawer = function () {
      if($rootScope.currentShop.newPrinter === true) {
        $thermalPrint.openDrawer(true, null)
          .then(function () {
            toastr.success($language.translate('CASH_DRAWER_OPENEND'))
          })
          .catch(function (reason) {
            $log.error('Error while opening cash drawer', reason)
            toastr.error($language.translate('ERROR_WHILE_OPENING_DRAWER'))
          })
      } else {
        $printer.openCashDrawer()
          .then(function () {
            toastr.success($language.translate('CASH_DRAWER_OPENEND'))
          })
          .catch(function (reason) {
            $log.error('Error while opening cash drawer', reason)
            toastr.error($language.translate('ERROR_WHILE_OPENING_DRAWER'))
          })
      }
    }

    function loopAmount(data) {
      var result = ''
      var counter = 0
      for(var i =0; data.length; i++) {
        var amount = data[i]
        result += '€ ' + amount.amount / 100 + ': ' + amount.quantity + 'x' + '\n'
        counter++
        if(counter === data.length) {
          return result
        }
      }
    }

    $scope.copyCountedCash = function () {
      var startKey = '[' + $language.translate('COUNTED') + ']'
      var endKey = '[' + $language.translate('END') + ']'
      var text = startKey + '\n'
      text += loopAmount($scope.amounts[0])
      text += loopAmount($scope.amounts[1])
      text += '\n' + $language.translate('TOTAL') + ': €' + $scope.totalCashCounted
      text += '\n' + endKey

      if($scope.dayState.comment && $scope.dayState.comment !== '') {
        var startIdx = $scope.dayState.comment.indexOf(startKey)
        var endIdx = $scope.dayState.comment.indexOf(endKey)

        if(startIdx >=0 && endIdx > 0) {
          var countedText = $scope.dayState.comment.substring(startIdx, (endIdx + endKey.length))
          $scope.dayState.comment = $scope.dayState.comment.replace(countedText, '')
          $scope.dayState.comment +=  text
        } else {
          $scope.dayState.comment += '\n' + text
        }
      } else {
        $scope.dayState.comment = text
      }
    }
  }])
