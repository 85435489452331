prismanoteApp.controller('adminBlocksController', ['$scope', 'dialogs', '$api', '$uibModal',
  function ($scope, dialogs, $api, $uibModal) {
    $scope.getIpBlocks = function () {
      $api.get('admin/rates/ip-blocks', null, null, 2)
        .then(function (response) {
          $scope.ip_blocks = response.data.ip_blocks
        })
        .catch(function (reason) {
          console.error('Error while getting ip blocks', reason)
        })
    }

    $scope.getUsernameBlocks = function () {
      $api.get('admin/rates/username-blocks', null, null, 2)
        .then(function (response) {
          $scope.username_blocks = response.data.username_blocks
        })
        .catch(function (reason) {
          console.error('Error while getting ip blocks', reason)
        })
    }

    $scope.removeIpBlock = function (id) {
      $api.delete('admin/rates/ip-blocks/' + id, null, null, 2)
        .then(function () {
          $scope.getIpBlocks()
        })
        .catch(function (reason) {
          console.error(reason)
        })
    }

    $scope.removeUsernameBlock = function (id) {
      $api.delete('admin/rates/username-blocks/' + id, null, null, 2)
        .then(function () {
          $scope.getUsernameBlocks()
        })
        .catch(function (reason) {
          console.error(reason)
        })
    }
  }])
