prismanoteApp.controller('retailerCompareProductController', ['$scope', '$parse', '$rootScope', '$uibModal', '$stateParams', '$state', '$api', '$q', '$translate',
  function ($scope, $parse, $rootScope, $uibModal, $stateParams, $state, $api, $q, $translate) {
    //
    console.log('retailerCompareProductController: CALLLED: ', $stateParams.nameSlug)
    console.log('$stateParams.nameSlug', $stateParams.mergeProductnameSlug)

    $scope.isMerge = false
    $scope.primaryMergeProduct = {}
    $scope.secondaryRetailerModalProduct = {}

    if ($stateParams.mergeProductnameSlug != 'Not_For_Merge') $scope.isMerge = true

    $scope.suggestionsProduct

    var verifyMode = false
    var nameSlug
    $scope.currentMode = 'edit'
    $scope.showMergeSuccessAlert = false
    $scope.diamond = { quantity: '', gemKind: '', gemPurity: { code: '' }, gemColor: { code: '' }, gemCut: '', caratWeight: '', suggestedRetailPriceDiamond: '' }

    $scope.tabSelected = function (index) {
      $scope.activeTabSuggestedProduct = index
      if ($scope.activeTabSuggestedProduct == 6) {
        $scope.activeTabCurrentProduct = 0
      } else if ($scope.activeTabSuggestedProduct == 7) {
        $scope.activeTabCurrentProduct = 1
      } else if ($scope.activeTabSuggestedProduct == 8) {
        $scope.activeTabCurrentProduct = 2
      } else if ($scope.activeTabSuggestedProduct == 9) {
        $scope.activeTabCurrentProduct = 3
      } else if ($scope.activeTabSuggestedProduct == 10) {
        $scope.activeTabCurrentProduct = 4
      } else if ($scope.activeTabSuggestedProduct == 11) {
        $scope.activeTabCurrentProduct = 5
      }
    }
    $scope.editDiamond = false
    $scope.addDiamond = true
    $scope.gemKinds = [
      { key: 'agate', value: 'agate' },
      { key: 'amazone', value: 'amazone' },
      { key: 'amazonite', value: 'amazonite' },
      { key: 'amber', value: 'amber' },
      { key: 'amethyst', value: 'amethyst' },
      { key: 'aquamarine', value: 'aquamarine' },
      { key: 'aventurine', value: 'aventurine' },
      { key: 'beryll', value: 'beryll' },
      { key: 'bloodcoral', value: 'bloodcoral' },
      { key: 'calcite', value: 'calcite' },
      { key: 'cameo', value: 'cameo' },
      { key: 'carnelean', value: 'carnelean' },
      { key: 'cateye', value: 'cateye' },
      { key: 'ceramic', value: 'ceramic' },
      { key: 'chalcedony', value: 'chalcedony' },
      { key: 'citrine', value: 'citrine' },
      { key: 'corundum', value: 'corundum' },
      { key: 'crystal', value: 'crystal' },
      { key: 'diamond', value: 'diamond' },
      { key: 'emerald', value: 'emerald' },
      { key: 'enamel', value: 'enamel' },
      { key: 'epidote', value: 'epidote' },
      { key: 'firepost', value: 'firepost' },
      { key: 'fluorite', value: 'fluorite' },
      { key: 'garnet', value: 'garnet' },
      { key: 'glass', value: 'glass' },
      { key: 'heliotrope', value: 'heliotrope' },
      { key: 'hematite', value: 'hematite' },
      { key: 'irisquartz', value: 'irisquartz' },
      { key: 'jade', value: 'jade' },
      { key: 'jargon', value: 'jargon' },
      { key: 'jasper', value: 'jasper' },
      { key: 'labradorite', value: 'labradorite' },
      { key: 'lapis', value: 'lapis' },
      { key: 'lazuli', value: 'lazuli' },
      { key: 'lazurite', value: 'lazurite' },
      { key: 'lemonquartz', value: 'lemonquartz' },
      { key: 'madeiracitrine', value: 'madeiracitrine' },
      { key: 'malachite', value: 'malachite' },
      { key: 'moonstone', value: 'moonstone' },
      { key: 'muranoglass', value: 'muranoglass' },
      { key: 'naturalstone', value: 'naturalstone' },
      { key: 'nephrite', value: 'nephrite' },
      { key: 'onyx', value: 'onyx' },
      { key: 'opal', value: 'opal' },
      { key: 'pearl', value: 'pearl' },
      { key: 'pearl akoya', value: 'pearl akoya' },
      { key: 'pearl tahiti', value: 'pearl tahiti' },
      { key: 'pearl sweet water', value: 'pearl sweet water' },
      { key: 'pearl south sea', value: 'pearl south sea' },
      { key: 'pearl synthetical', value: 'pearl synthetical' },
      { key: 'pearl cultive', value: 'pearl cultive' },
      { key: 'peridote', value: 'peridote' },
      { key: 'phosphorite', value: 'phosphorite' },
      { key: 'quartz', value: 'quartz' },
      { key: 'rhinestone', value: 'rhinestone' },
      { key: 'rockcrystal', value: 'rockcrystal' },
      { key: 'rosequartz', value: 'rosequartz' },
      { key: 'ruby', value: 'ruby' },
      { key: 'sapphire', value: 'sapphire' },
      { key: 'slate', value: 'slate' },
      { key: 'smoky quartz', value: 'smoky quartz' },
      { key: 'sodalite', value: 'sodalite' },
      { key: 'spinel', value: 'spinel' },
      { key: 'stratumstone', value: 'stratum stone' },
      { key: 'swarovskicrystal', value: 'swarovskicrystal' },
      { key: 'syntheticstone', value: 'syntheticstone' },
      { key: 'tigereye', value: 'tigereye' },
      { key: 'topaz', value: 'topaz' },
      { key: 'tourmaline', value: 'tourmaline' },
      { key: 'turquoise', value: 'turquoise' },
      { key: 'uvarovite', value: 'uvarovite' },
      { key: 'wildvinite', value: 'wildvinite' },
      { key: 'xylopile', value: 'xylopile' },
      { key: 'zirconia', value: 'zirconia' }
    ]
    $scope.gemPurities = [{ key: 'LC', value: 'LC' }, { key: 'VVS1', value: 'VVS1' }, { key: 'VVS2', value: 'VVS2' }, { key: 'VS1', value: 'VS1' }, { key: 'VS2', value: 'VS2' }, { key: 'SI1', value: 'SI1' }, { key: 'SI2', value: 'SI2' }, { key: 'P1', value: 'P1' }, { key: 'P2', value: 'P2' }, { key: 'P3', value: 'P3' }]
    $scope.gemColors = [
      { value: 'D', keyD: 'D (Finest white+ Jager | colorless)' },
      { value: 'E', key: 'E (Finest white River | colorless)' },
      { value: 'F', key: 'F (Fine white+ Top wesselton | colorless)' },
      { value: 'G', key: 'G (Fine white Top Wesselton | colorless)' },
      { value: 'H', key: 'H (White Wesselton | Near colorless)' },
      { value: 'I', key: 'I (Light tinted white+ Top crystal | Near colorless)' },
      { value: 'J', key: 'J (Light tinted white crystal | Near colorless)' },
      { value: 'K', key: 'K (Tinted white+ Top cape | Near colorless)' },
      { value: 'L', key: 'L (Tinted white Top Cape | Faint yellow)' },
      { value: 'M', key: 'M (Tinted colour Cape | Faint yellow)' },
      { value: 'N', key: 'N (Tinted colour Low Cape | Very Light Yellow)' },
      { value: 'O', key: 'O (Tinted colour Very Light Yellow)' },
      { value: 'P', key: 'P (Tinted colour | Very Light Yellow)' },
      { value: 'Q', key: 'Q (Tinted colour | Very Light Yellow)' },
      { value: 'R', key: 'R (Tinted colour | Very Light Yellow)' },
      { value: 'S', key: 'S (Light Yellow)' },
      { value: 'T', key: 'T (Light Yellow)' },
      { value: 'V', key: 'V (Light Yellow)' },
      { value: 'U', key: 'U (Light Yellow)' },
      { value: 'W', key: 'W (Light Yellow)' },
      { value: 'X', key: 'X (Light Yellow)' },
      { value: 'Y', key: 'Y (Light Yellow)' },
      { value: 'Z', key: 'Z (Light Yellow)' },
      { value: 'P-Z', key: 'P-Z (Tinted colour Light )' },
      { value: 'TRANSPARENT', key: 'TRANSPARENT' },
      { value: 'SILVER', key: 'SILVER' },
      { value: 'GREY', key: 'GREY' },
      { value: 'BLUE', key: 'BLUE' },
      { value: 'WHITE', key: 'WHITE' },
      { value: 'RED', key: 'RED' },
      { value: 'BLACK', key: 'BLACK' },
      { value: 'ROSE_GOLD', key: 'ROSE_GOLD' },
      { value: 'GOLD', key: 'GOLD' },
      { value: 'MOTHER_OF_PEARL', key: 'MOTHER_OF_PEARL' },
      { value: 'BROWN', key: 'BROWN' },
      { value: 'BEIGE', key: 'BEIGE' },
      { value: 'MOTHER_OF_PEARL_COLOURED', key: 'MOTHER_OF_PEARL_COLOURED' },
      { value: 'GOLDEN', key: 'GOLDEN' },
      { value: 'GREEN', key: 'GREEN' },
      { value: 'PINK', key: 'PINK' },
      { value: 'PURPLE', key: 'PURPLE' },
      { value: 'ORANGE', key: 'ORANGE' },
      { value: 'YELLOW', key: 'YELLOW' },
      { value: 'CREME', key: 'CREME' },
      { value: 'TAUPE', key: 'TAUPE' },
      { value: 'BRASS', key: 'BRASS' }
    ]
    $scope.gemCuts = [
      { key: 'asscher', value: 'asscher' },
      { key: 'baguette', value: 'baguette' },
      { key: 'briljant', value: 'briljant' },
      { key: 'buff-top', value: 'buff-top' },
      { key: 'cabochon', value: 'cabochon' },
      { key: 'carre', value: 'carre' },
      { key: 'emerald', value: 'emerald' },
      { key: 'fact', value: 'fact' },
      { key: 'heart', value: 'heart' },
      { key: 'marquise', value: 'marquise' },
      { key: 'mixed', value: 'mixed' },
      { key: 'octagon', value: 'octagon' },
      { key: 'oval fact', value: 'oval fact' },
      { key: 'oval', value: 'oval' },
      { key: 'pear', value: 'pear' },
      { key: 'radiant', value: 'radiant' },
      { key: 'princess', value: 'princess' },
      { key: 'single-cut', value: 'single-cut' },
      { key: 'radiant', value: 'radiant' },
      { key: 'round', value: 'round' },
      { key: 'radiant', value: 'radiant' }
    ]

    $scope.openDiamond = function (index) {
      console.log('Index Of the diamond', index)
      $scope.diamond = $scope.product.diamonds[index]
      $scope.diamondIndex = index
      $scope.editDiamond = true
    }
    $scope.openSuggDiamond = function (index) {
      console.log('Index Of the diamond', index)
      $scope.suggDiamond = $scope.showProduct.diamonds[index]
      $scope.suggDiamondIndex = index
      $scope.editSuggDiamond = true
    }

    $scope.addNewDiamondForm = function () {
      $scope.diamond = { quantity: '', gemKind: '', gemPurity: { code: '' }, gemColor: { code: '' }, gemCut: '', caratWeight: '', suggestedRetailPriceDiamond: '' }
      if ($scope.product.diamonds) {
        // do nothing
      } else {
        $scope.product.diamonds = []
      }

      $scope.diamondIndex = $scope.product.diamonds.length
      $scope.product.diamonds[$scope.diamondIndex] = $scope.diamond
      $scope.addDiamond = true
    }

    $scope.pushDiamond = function () {
      console.log('New diamond push in diamonds')
      if ($scope.product.diamonds == undefined) {
        $scope.product.diamonds = []
      }

      $scope.product.diamonds.push($scope.diamond)
      console.log('my diamonds list', $scope.product.diamonds)
      $scope.diamond = {}
      console.log('$scope.product.diamonds', $scope.product.diamonds)
    }

    $scope.clearTheDiamond = function () {
      $scope.diamond = { quantity: '', gemKind: '', gemPurity: { code: '' }, gemColor: { code: '' }, gemCut: '', caratWeight: '', suggestedRetailPriceDiamond: '' }
    }

    $scope.cancelNewDiamond = function (diamondIndex) {
      $scope.product.diamonds.splice(diamondIndex, 1)
      console.log('Remove the last index at addinf time')
      console.log('diamonds', $scope.product.diamonds)
      $scope.addDiamond = false
      $scope.diamond = {}
    }

    $scope.deleteDiamond = function (index) {
      console.log('Index Of the diamond', index)
      $scope.product.diamonds.splice(index, 1)
      console.log('diamonds', $scope.product.diamonds)
    }

    $scope.assignValueForDiamond = function (prodIndex, suggIndex, key) {
      if (key != 'gemColor' && key != 'gemPurity') {
        $scope.product.diamonds[prodIndex][key] = $scope.showProduct.diamonds[suggIndex][key]
      } else $scope.product.diamonds[prodIndex][key].code = $scope.showProduct.diamonds[suggIndex][key].code
    }

    $scope.getClassForDiamons = function (prodIndex, suggIndex, key) {
      if (key != 'gemColor' && key != 'gemPurity') {
        var sug = $scope.showProduct.diamonds[suggIndex][key]
        var prod = $scope.product.diamonds[prodIndex][key]
      } else {
        var sug = $scope.showProduct.diamonds[suggIndex][key].code
        var prod = $scope.product.diamonds[prodIndex][key].code
      }
      var colorCode
      if ((sug == undefined || sug == '') && (prod == undefined || prod == '')) {
        colorCode = ' '
      } else if (sug == prod) {
        colorCode = ' '
      } else if ((sug == undefined || sug == '') && (prod != undefined && prod != '')) {
        colorCode = 'redColorClass'
      } else if ((prod == undefined || prod == '') && (sug != undefined && sug != '')) {
        colorCode = 'greenColorClass'
      } else if (sug != prod) {
        colorCode = 'orangeColorClass'
      }
      console.log('Color Code', colorCode)
      return colorCode
    }
    // $scope.suggestionChanged = function(obj){
    //	console.log("all the products in suggestion " , $scope.product.suggestions);
    //
    //	console.log("CHANGED!");
    //	console.log('suggested product',obj);
    //	setTimeout(function(){
    //	console.log('test suggestion', $scope.product);
    //	},500)

    //	console.log('hi1');
    // console.log($scope.showProduct.watch)
    // }

    $scope.$watch('showProduct', function (item) {
      console.log('ShowProduct Changed = ', $scope.showProduct)
    })

    $scope.convertDate = function (date) {
      var d = new Date(date)

      // Set Hours -4 for time differance
      // d.setHours( d.getHours() - 4);

      // // d.getFullYear() +'-'+ (d.getMonth()+1) +'-'+d.getDate()+' '+d.getHours()+':'+d.getMinutes()+':'+d.getSeconds();
      // console.log("New Date", d);
      // return d.toDateString() +' '+d.getHours()+':'+d.getMinutes()+':'+d.getSeconds();
      // // return d.toUTCString();
      // var d = new Date(date);
      // return d.toUTCString();

      return d.toLocaleString()
    }

    $scope.deleteProductFromPrismaNote = function () {
      $api.delete('product/delete-suggestion?productId=' + $scope.product._id + '&shopId=' + $rootScope.currentShop._id)
        .then(function (success) {
          alert('Deletion of the product has been scheduled.')
        }, function (err) {
          alert('There seems to be some error. Please try again later.')
        })
    }

    $scope.convertToUpperAndSpace = function (str) {
      var oldStr = str
      if (str) {
        str = str.toUpperCase()
        str = str.replace(' ', '_')
        console.log(oldStr, 'is converted to  ', str)
      }
      return str
    }
    $scope.getValueChangedIfSuggestionClick = function (sugvalue, prodfeildname) {
      console.log("sugvalue", sugvalue);
      console.log("prodfeildname", prodfeildname);

      if (prodfeildname == 'product.watch.strap.color') $scope.setStrapColorInitially(sugvalue)
      if (prodfeildname == 'product.watch.case.color') $scope.setCaseColorInitially(sugvalue)
      if (prodfeildname == 'product.watch.dial.color') $scope.setDialColorInitially(sugvalue)
      // Get the model
      console.log("old value", $scope[prodfeildname]);
      var model = $parse(prodfeildname)
      console.log("new value", $scope[prodfeildname]);
      // Assigns a value to it
      model.assign($scope, sugvalue)
      // console.log("new value", $scope[prodfeildname]);
    }

    $scope.getValueChangedIfSuggestionClickForIndex = function (sugvalue, prodfeildname, index) {
      var finalprodfeildname = prodfeildname.replace('$index', index)
      // console.log("finalprodfeildname", prodfeildname, index);
      // Get the model
      var model = $parse(finalprodfeildname)
      // console.log("old value", $scope[finalprodfeildname]);
      // Assigns a value to it
      model.assign($scope, sugvalue)
      // console.log("new value", $scope[finalprodfeildname]);
    }

    $scope.customfilter = function (obj) { // this is for testing and thus can remove it later after the issue is tested perfectly
      return typeof obj
    }

    $scope.getClassForSuggestion = function (sug, prod) {
      console.log('---- getClassForSuggestion!!');
      console.log(sug);
      console.log(prod);
      var colorCode
      if ((sug == undefined || sug == '') && (prod == undefined || prod == '')) {
        colorCode = ' '
      } else if (sug == prod) {
        colorCode = ' '
      } else if ((sug == undefined || sug == '') && (prod != undefined && prod != '')) {
        colorCode = 'redColorClass'
      } else if ((prod == undefined || prod == '') && (sug != undefined && sug != '')) {
        colorCode = 'greenColorClass'
      } else if (sug != prod) {
        colorCode = 'orangeColorClass'
      }
      console.log(' colorCode ' + colorCode);
      return colorCode
    }

    $scope.getClassForSexAudienceSuggestion = function (suggestedProduct) {
      var sug = $scope.getSexAudience(suggestedProduct)
      var prod = $scope.targetAudience
      var colorCode
      if ((sug == undefined || sug == '') && (prod == undefined || prod == '')) {
        colorCode = ' '
      } else if (sug == prod) {
        colorCode = ' '
      } else if ((sug == undefined || sug == '') && (prod != undefined && prod != '')) {
        colorCode = 'redColorClass'
      } else if ((prod == undefined || prod == '') && (sug != undefined && sug != '')) {
        colorCode = 'greenColorClass'
      } else if (sug != prod) {
        colorCode = 'orangeColorClass'
      }
      return colorCode
    }

    $scope.getValueChangedIfSuggestedAudienceClick = function (suggestedProduct) {
      var sug = $scope.getSexAudience(suggestedProduct)
      $scope.targetAudience = sug
      $scope.setGender($scope.targetAudience)
    }

    $scope.showProductChange = function (obj, parentObj) {
      if (obj) {
        console.log('Suggestion Change', obj)
        $scope.showProduct = obj
        $scope.showProduct.itemIsToMerge = parentObj.itemIsToMerge
        console.log('suggestion to merge', obj)
        // console.log("ShowProduct final value = ", $scope.showProduct);
      }
    }

    $scope.scheduleToMerge = function () {
      var primaryProduct = $scope.product._id
      var secondaryProduct = $scope.showProduct._id
      console.log('scheduled merge: ', primaryProduct, secondaryProduct)
      $api.get('products/merge-products/' + primaryProduct + '/' + secondaryProduct + '/')
        .then(function (success) {
          console.log('SUCCESS SCHEDULETOMERGE')
          $scope.showMergeSuccessAlert = true
        }, function (err) {
          console.error(err)
        })
    }

    $scope.clearSelectionForMerging = function () {
      $scope.showProduct = ''
      $scope.showMergeSuccessAlert = false
    }

    $scope.getClassForImages = function () {
      var s_image_length = $scope.showProduct.images.length
      var p_image_length = $scope.product.images.length
      var colorCode
      if (s_image_length == 0) {
        colorCode = 'redColorClass'
        return colorCode
      }

      if (p_image_length == 0) {
        colorCode = 'greenColorClass'
        return colorCode
      }

      if (s_image_length != p_image_length) {
        colorCode = 'orangeColorClass'
        return colorCode
      }

      if (s_image_length == p_image_length) {
        if (s_image_length == 1) {
          if ($scope.showProduct.images[0].src == $scope.product.images[0].src) {
            return ' '
          } else {
            colorCode = 'orangeColorClass'
            return colorCode
          }
        } else {
          var same = []
          for (var i = 0; i < s_image_length; i++) {
            for (var i = 0; i < s_image_length; i++) {
              if ($scope.showProduct.images[i].src == $scope.showProduct.images[j].src) {
                if (same.indexOf(src) == -1) {
                  same.push(src)
                }
              }
            }
          }

          if (same.length == s_image_length) {
            return ' '
          } else {
            colorCode = 'orangeColorClass'
            return colorCode
          }
        }
      }
    }

    $scope.getTheValueChangedForImages = function () {
      $scope.product.images = $scope.showProduct.images
      $scope.image = $scope.product.images
    }

    $scope.dialColors = [{
      key: 'SILVER',
      hex: '#CACFD2'
    },
    {
      key: 'GREY',
      hex: '#4D5656'
    },
    {
      key: 'BLUE',
      hex: '#2980B9'
    },
    {
      key: 'WHITE',
      hex: '#FFF8DC'
    },
    {
      key: 'RED',
      hex: '#922B21'
    },
    {
      key: 'BLACK',
      hex: '#000000'
    },
    {
      key: 'ROSE_GOLD',
      hex: '#FFA07A'
    },
    {
      key: 'GOLD',
      hex: '#B8860B'
    },
    {
      key: 'MOTHER_OF_PEARL',
      hex: '#BC8F8F'
    },
    {
      key: 'BROWN',
      hex: '#8B4513'
    },
    {
      key: 'BEIGE',
      hex: '#CD853F'
    },
    {
      key: 'MOTHER_OF_PEARL_COLOURED',
      hex: '#BC8F8F'
    },
    {
      key: 'GOLDEN',
      hex: '#B8860B'
    },
    {
      key: 'GREEN',
      hex: '#228B22'
    },
    {
      key: 'PINK',
      hex: '#FF69B4'
    },
    {
      key: 'PURPLE',
      hex: '#663399'
    },
    {
      key: 'ORANGE',
      hex: '#FF5733'
    },
    {
      key: 'YELLOW',
      hex: '#FFD700'
    },
    {
      key: 'CREME',
      hex: '#F5DEB3'
    },
    {
      key: 'TAUPE',
      hex: '#D2B48C'
    },
    {
      key: 'BRASS',
      hex: '#CD853F'
    }]

    $scope.strapColors = [
      {
        key: 'SILVER',
        hex: '#CACFD2'
      },
      {
        key: 'GREY',
        hex: '#4D5656'
      },
      {
        key: 'BLUE',
        hex: '#2980B9'
      },
      {
        key: 'WHITE',
        hex: '#FFF8DC'
      },
      {
        key: 'RED',
        hex: '#922B21'
      },
      {
        key: 'BLACK',
        hex: '#000000'
      },
      {
        key: 'ROSE_GOLD',
        hex: '#FFA07A'
      },
      {
        key: 'GOLD',
        hex: '#B8860B'
      },
      {
        key: 'BROWN',
        hex: '#8B4513'
      },
      {
        key: 'BEIGE',
        hex: '#CD853F'
      },
      {
        key: 'GOLDEN',
        hex: '#B8860B'
      },
      {
        key: 'GREEN',
        hex: '#228B22'
      },
      {
        key: 'PINK',
        hex: '#FF69B4'
      },
      {
        key: 'PURPLE',
        hex: '#663399'
      },
      {
        key: 'ORANGE',
        hex: '#FF5733'
      },
      {
        key: 'YELLOW',
        hex: '#FFD700'
      },
      {
        key: 'CREME',
        hex: '#F5DEB3'
      },
      {
        key: 'TAUPE',
        hex: '#D2B48C'
      },
      {
        key: 'BRASS',
        hex: '#CD853F'
      }
    ]

    $scope.$watch('product', function () {
      console.log('PRODUCT OBJECT CHANGED ', $scope.product)
    })
    // FOR COLLECTIONS
    $scope.searchCollections = function (str) {
      // console.log("SEARCH COLLECTION CALLED");
      return searchItems(str, 'collections')
    }

    $scope.getCollections = function () {
      // console.log("GETTING COLLECTIONS");
      return getItems('collections')
    }

    $scope.collectionSelected = function (selected) {
      // console.log("Selected", selected);
      // console.log("Language = ", $scope.language);
      $scope.addNewCollectionForm = false
      if (selected.title === ' ➕ Add New Collection' || selected.title === ' ➕ Kollektion hinzufügen' || selected.title === ' ➕ Voeg nieuwe collectie toe' || selected.title === ' ➕ Añadir colección' || selected.title === ' ➕ Ajouter une collection') {
        console.log('Selected', selected)

        $scope.addNewCollectionForm = true
        $('#collection-' + $rootScope.language + '-name').val($scope.searchStr)
      } else {
        var collection = selected.originalObject
        // console.log("To be pushed in collections array ", collection);

        if (!$scope.product.collections) {
          $scope.product.collections = []
        }
        $scope.product.collections.push(collection)
        // collection = ;
        // console.log("product", $scope.product.isVerified);
      }
    }

    // $scope.$watch('collectionSelected',function(){
    // 	console.log("COLLECTION SELECTED!!!" , $scope.collectionSelected);
    // 	if($scope.product.collections == undefined || $scope.product.collections.length==0)
    // 		$scope.product.collections = []

    // 	$scope.product.collections.push($scope.collectionSelected.title);
    // 	console.log("PROD COLLECTIONS = ", $scope.product.collections);
    // 	console.log("saveProduct", nameSlug, $scope.product, $stateParams.nameSlug);
    // })

    $scope.setProductCategory = function (category) {
      console.log('product handling guy Check The category', category)
      if (category == 'WATCH') {
        $scope.product.strap = $scope.product.jewel = {}
        $scope.product.watch = { case: {}, strap: {}, dial: {} }
      } else if (category == 'STRAP') {
        $scope.product.watch = $scope.product.jewel = {}
      } else if (category == 'JEWEL') {
        $scope.product.strap = $scope.product.watch = {}
      }
      $scope.product.category = category
    }

    $scope.setCaseColor = function (color) {
      console.log('color value', color)
      console.log('scope value', $scope.caseColor)
      $scope.product.watch.case.color = [color.key]
    }
    $scope.setCaseColorInitially = function (color) {
      console.log('SET CASE COLOR CALLED FOR COLOR INITIALLY: ', color)
      if (color == null || color == undefined || color == '') return

      color = color.toUpperCase()
      console.log('CASE COLOR SENT FOR INITIALLY SETTING : ', color)
      for (var i = 0; i < $scope.caseColors.length; i++) {
        if ($scope.caseColors[i].key == color) {
          $scope.caseColor = $scope.caseColors[i]
          console.log('MATCH FOUND and the scope value is!', $scope.caseColor)
          $scope.setCaseColor(color)
          // $scope.product.watch.dial.color = color.key;
          break
        } else {
          console.log('CASE COLOR')
          console.log($scope.caseColors[i].key + '!=' + color)
        }
      }
      if (!$scope.caseColor) console.log('NO CASECOLOR MATCHED TO SET INITIALLY')
    }
    $scope.setDialColor = function (color) {
      console.log('SET DIAL COLOR CALLED FOR COLOR : ', color)
      $scope.product.watch.dial.color = [color.key]
    }

    $scope.setDialColorInitially = function (color) {
      console.log('SET DIAL COLOR CALLED FOR COLOR INITIALLY: ', color)

      if (color == null || color == undefined || color == '') return
      // color = color.toUpperCase();
      console.log('COLOR SENT FOR INITIALLY SETTING : ', color)
      for (var i = 0; i < $scope.dialColors.length; i++) {
        console.log($scope.dialColors[i], color)
        if ($scope.dialColors[i].key == color) {
          $scope.dialColor = $scope.dialColors[i]
          console.log('MATCH FOUND !', $scope.dialColor)
          $scope.setDialColor(color)
          // $scope.product.watch.dial.color = color.key;
          break
        } else {
          console.log($scope.dialColors[i].key + '!=' + color)
        }
      }
      if (!$scope.dialColor) console.log('NO COLOR MATCHED TO SET INITIALLY')
    }

    $scope.setStrapColor = function (color) {
      if ($scope.product.category == 'WATCH') {
        $scope.product.watch.strap.color = [color.key]
      } else if ($scope.product.category == 'STRAP') {
        $scope.product.strap.color = [color.key]
      }
    }
    $scope.setStrapColorInitially = function (color) {
      console.log('SET STRAP COLOR CALLED FOR COLOR INITIALLY: ', color)
      if (color == null || color == undefined || color == '') return

      color = color.toUpperCase()
      console.log('strap COLOR SENT FOR INITIALLY SETTING : ', color)
      for (var i = 0; i < $scope.strapColors.length; i++) {
        if ($scope.strapColors[i].key == color) {
          $scope.strapColor = $scope.strapColors[i]
          console.log('MATCH FOUND !', $scope.strapColor)
          $scope.setStrapColor(color)
          // $scope.product.watch.dial.color = color.key;
          break
        } else {
          console.log('Strap COLOR')
          console.log($scope.strapColors[i].key + '!=' + color)
        }
      }
      if (!$scope.caseColor) console.log('NO CASECOLOR MATCHED TO SET INITIALLY')
    }
    $scope.caseColors = $scope.jewelColors = Object.assign($scope.dialColors, $scope.strapColors)

    var getItems = function (type) {
      $api.get(type, { shopId: $rootScope.currentShop._id })

        .then(function (response) {
          $scope[type] = response.data[type]
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.getBrands = function () {
      return getItems('brands')
    }

    var searchItems = function (str, type) {
      console.log('Search', str, type)
      var matches = []
      var addNewCollectionItem = {
        en: {
          name: ' ➕ Add New Collection'
        },
        nl: {
          name: ' ➕ Voeg nieuwe collectie toe'
        },
        es: {
          name: ' ➕ Añadir colección'
        },
        fr: {
          name: ' ➕ Ajouter une collection'
        },
        de: {
          name: ' ➕ Kollektion hinzufügen'
        }
      }

      matches.push(addNewCollectionItem)

      $scope[type].forEach(function (item) {
        if (type != 'brands') {
          // 	if(
          // 		(item[$rootScope.language] != undefined)
          // 		&&
          // 		((item[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)
          // 			||
          // 			(item[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0 )
          // 			)
          // 		) {
          // 		matches.push(item);
          // }
          if (
            (item[$rootScope.language] != undefined && item[$rootScope.language].name != undefined) &&
						(
						  (item[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)							||
							(item[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)
						)
          ) {
            matches.push(item)
          }
        } else {
          if (
            (item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)						||
						(item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)
          ) {
            matches.push(item)
          }
        }
      })

      return matches
    }

    $scope.searchBrands = function (str) {
      return searchItems(str, 'brands')
    }

    $scope.brandSelected = function (selected) {
      var brand = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug,
        description: selected.originalObject.description,
        images: selected.originalObject.images
      }

      $scope.product.brand = brand
    }

    $scope.addProductVariant = function () {
      /* TODO: remove collapse property on save */
      $scope.product.variants.push({ collapse: true })
    }

    $scope.removeProductVariant = function (index) {
      $scope.product.variants.splice(index, 1)
    }
    $scope.customGetTargetAudience = function () {
      // console.log("GETTING TARGET AUDIENCE from scope to native.");
      getTargetAudience()
    }
    var getTargetAudience = function () {
      // console.log("SETTING TARGET AUDIENCE");
      if ($scope.product.male) {
        if ($scope.product.female) {
          if ($scope.product.kids) {
            $scope.targetAudience = 'KIDS'
          } else {
            $scope.targetAudience = 'UNISEX'
          }
        } else {
          if ($scope.product.kids) {
            $scope.targetAudience = 'BOYS'
          } else {
            $scope.targetAudience = 'GENTS'
          }
        }
      } else {
        if ($scope.product.female) {
          if ($scope.product.kids) {
            $scope.targetAudience = 'GIRLS'
          } else {
            $scope.targetAudience = 'LADIES'
          }
        }
      }
    }

    $scope.getSexAudience = function (prod) {
      var targetAudience = ''
      if (prod.male) {
        if (prod.female) {
          if (prod.kids) {
            targetAudience = 'KIDS'
          } else {
            targetAudience = 'UNISEX'
          }
        } else {
          if (prod.kids) {
            targetAudience = 'BOYS'
          } else {
            targetAudience = 'GENTS'
          }
        }
      } else {
        if (prod.female) {
          if (prod.kids) {
            targetAudience = 'GIRLS'
          } else {
            targetAudience = 'LADIES'
          }
        }
      }
      return targetAudience
    }

    $scope.setGender = function (targetAudience) {
      switch (targetAudience) {
        case 'GENTS':
          $scope.product.male = true
          $scope.product.female = false
          $scope.product.kids = false
          break
        case 'LADIES':
          $scope.product.male = false
          $scope.product.female = true
          $scope.product.kids = false
          break
        case 'UNISEX':
          $scope.product.male = true
          $scope.product.female = true
          $scope.product.kids = false
          break
        case 'BOYS':
          $scope.product.male = true
          $scope.product.female = false
          $scope.product.kids = true
          break
        case 'GIRLS':
          $scope.product.male = false
          $scope.product.female = true
          $scope.product.kids = true
          break
        case 'KIDS':
          $scope.product.male = true
          $scope.product.female = true
          $scope.product.kids = true
          console.log('KId man', $scope.product.male)
          console.log('female', $scope.product.female)
          console.log('kid', $scope.product.kids)
          break
      }
    }

    $scope.openProductTransferConfirmationModal = function (shop, productFromTransfer, productToTransfer) {
      return $q(function (resolve, reject) {
        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/retailer-product-transfer-confirmation-modal.html',
          controller: 'retailerProductTransferConfirmationModalController',
          size: 'lg',
          resolve: {
            product: function () {
              var product = {}
              product.shop = shop
              product.productFromTransfer = productFromTransfer
              product.productToTransfer = productToTransfer
              return product
            }
          }
        })

        modalInstance.result
          .then(function (response) {
            resolve(response)
          })
          .catch(function (reason) {
            reject(reason)
          })
      })
    }

    $scope.saveProduct = function () {
      // console.log("$scope.product SAVE PRODCUT CLICKED", $scope.product);
      var nameSlug = $stateParams.nameSlug
      // let mergeProductnameSlug = $stateParams.mergeProductnameSlug;
      console.log('nameslug: ', nameSlug)
      if (nameSlug == null) {
        var product = $scope.product
        for (var i = 0; i < product.variants.length; i++) {
          delete product.variants[i].collapse
        }
        if (product.category == 'WATCH') {
          delete product.strap
          delete product.jewel
        }

        if (product.category == 'STRAP') {
          delete product.watch
          delete product.jewel
        }

        if (product.category == 'JEWEL') {
          delete product.strap
          delete product.watch
        }

        product.isVerified = true

        product.verifyProduct = verifyMode || false
        product.shopId = $rootScope.currentShop._id;        

        $api.post('products', product)
          .then(function (response) {
            nameSlug = response.product.nameSlug || response.product.en.nameSlug
          })
          .catch(function (reason) {
            console.error(reason)
          })
      } else {
        /* Don't create a new product twice, but update the product instead. */
        var product = $scope.product

        // product.collections = $scope.collectionSelected.title;
        // console.log("PRODUCT TO BE ADDED  = ", product);
        // console.log("Collections = ",$scope.collectionSelected.title);
        // console.log("In $scope.Product =",$scope.product.collections);
        for (var i = 0; i < product.variants.length; i++) {
          delete product.variants[i].collapse
        }

        if (product.category == 'WATCH') {
          delete product.strap
          delete product.jewel
        }

        if (product.category == 'STRAP') {
          delete product.watch
          delete product.jewel
        }

        if (product.category == 'JEWEL') {
          delete product.strap
          delete product.watch
        }

        product.isVerified = true

        if (verifyMode == true) {
          if (product.category == 'WATCH') {
            if (product.watch != undefined) {
              if (product.watch.case.color !== undefined) {
                if (product.watch.case.color[0] == undefined) {
                  if ($scope.caseColor) {
                    product.watch.case.color = [$scope.caseColor.key]
                  }
                }
              }
              if (product.watch.strap.color !== undefined) {
                if (product.watch.strap.color[0] == undefined) {
                  if ($scope.strapColor) {
                    product.watch.strap.color = [$scope.strapColor.key]
                  }
                }
              }
              if (product.watch.dial && product.watch.dial.color !== undefined) {
                if (product.watch.dial.color[0] == undefined) {
                  product.watch.dial.color = [$scope.dialColor.key]
                }
              }
            }
          }

          $scope.product = product

          if ($stateParams.mergeProductnameSlug != 'Not_For_Merge') {
            // START Merging
            var primaryProductId = $scope.product._id
            var secondaryProductIds = $scope.showProduct._id
            var params = {
              shop: { _id: $rootScope.currentShop._id },
              primaryProductId: primaryProductId, // Here Primary means searched product
              secondaryProductIds: secondaryProductIds // secondary means MAIN product
            }

            $api.put('suggest-merge-product/', params)
              .then(function (success) {
                console.log('SUCCESS SCHEDULETOMERGE')
              }, function (err) {
                console.error(err)
              })
            // END Merging

            $api.update('suggest-update-product', { product: $scope.product, shop: $rootScope.currentShop._id })
              .then(function (success) {
                console.log('YOU GOT THE RESPONSE OF UPDATE PRODUCT')
                uploadImage() // Upload Image if any

                // START Checking if searched product exist or not
                var prodID = $scope.product._id // Searched Product
                var primaryMergeProductId = $scope.primaryMergeProduct._id
                var secondaryRetailerModalProductId = $scope.secondaryRetailerModalProduct._id
                var isProductExistInShop = false
                console.log('primaryMergeProductId: ', primaryMergeProductId)
                console.log('secondaryRetailerModalProductId: ', secondaryRetailerModalProductId)
                if ($rootScope.currentShop.products && $rootScope.currentShop.products.length > 0) {
                  var productIndex = _.findIndex($rootScope.currentShop.products, { _id: primaryMergeProductId })

                  // var productIndex = $rootScope.currentShop.products.findIndex(function (element) { return element._id == prodID; });
                  console.log('product handling guy Here Check the product is exists or not', productIndex, $rootScope.currentShop.products)
                  if (productIndex !== -1) {
                    isProductExistInShop = true
                  }
                }
                // END Checking
                console.log('isProductExistInShop Flag: ', isProductExistInShop)
                var ModalIndex = _.findIndex($rootScope.currentShop.products, { _id: secondaryRetailerModalProductId }) // Retailer Modal Prod Index
                console.log('**********MODAL INDEX: ', ModalIndex)
                console.log('*******SHOP PRODUCTS: ', $rootScope.currentShop.products)
                var productFromTransfer = $rootScope.currentShop.products[ModalIndex] // Retailer Product (Open the Modal)
                if (isProductExistInShop == true || isProductExistInShop == 'true') {
                  var productToTransfer = $rootScope.currentShop.products[productIndex] // Merge Product (Searched Prod)
                  var shop = $rootScope.currentShop
                  console.log('FINAL: ', productFromTransfer, productToTransfer)
                  console.log('****************EXIST OPEN THE MODAL')

                  $scope.openProductTransferConfirmationModal(shop, productFromTransfer, productToTransfer)
                    .then(function (shopProduct) {
                      console.log('INSDIE THE THEN: ', shopProduct)
                      if (shopProduct) {
                        $state.go('retailer.assortment', { modalFor: $scope.primaryMergeProduct })
                      } else {
                        $state.go('retailer.assortment')
                      }
                    })
                    .catch(function (reason) {
                      console.error(reason)
                    })
                } else {
                  // Product Does not exist, Transfer all the data of Modal Prodcut(1500) to Serched Product (270)
                  console.log('*********************PRODUCT DOES NOT EXIST IN CURRENT SHOP*************')

                  var mytempobj = {
                    _id: $scope.primaryMergeProduct._id,
                    ownership: productFromTransfer.ownership,
                    stock: productFromTransfer.stock,
                    purchasePrice: productFromTransfer.purchasePrice,
                    price: productFromTransfer.price,
                    dropshippingPrice: productFromTransfer.dropshippingPrice,
                    discount: productFromTransfer.discount,
                    show: productFromTransfer.show,
                    isBestseller: productFromTransfer.isBestseller,
                    isFeatured: productFromTransfer.isFeatured,
                    reference: productFromTransfer.reference,
                    minStock: productFromTransfer.minStock,
                    dateAdded: productFromTransfer.dateAdded,
                    purchaseOrders: productFromTransfer.purchaseOrders,
                    dateModified: productFromTransfer.dateModified,
                    discountOnPercentage: productFromTransfer.discountOnPercentage
                  }

                  // var prodId = $scope.productFromTransfer._id; // Retailer
                  // var mergeProdId = $scope.productToTransfer._id; // Merge
                  // var productIndex = $scope.shop.products.findIndex(function (element) { return element._id == prodId; });
                  // var productIndexofMergeProdu = $scope.shop.products.findIndex(function (element) { return element._id == mergeProdId; });
                  // console.log(productIndex);
                  // $scope.shop.products.splice(productIndex, 1);
                  // $scope.shop.products.splice(productIndexofMergeProdu, 1);
                  // var index = _.findIndex($scope.shop.products, {_id: $scope.productToTransfer._id });
                  // console.log('TRANSFREED' , index);
                  // $scope.shop.products.splice(index, 1);
                  var SecondIndex = _.findIndex($scope.currentShop.products, { _id: productFromTransfer._id })
                  $scope.currentShop.products.splice(SecondIndex, 1)
                  console.log('INDEX: RETAILER MODAL: ', SecondIndex)
                  console.log('SHOP PRODUCTs: ', $scope.currentShop.products)
                  // return;
                  $scope.currentShop.products.push(mytempobj)

                  var nameSlug = $scope.currentShop.nameSlug
                  $api.put('shops/' + nameSlug, { shop: $scope.currentShop })
                    .then(function (response) {
                      console.log('SHOP UPDATED SUCCESFULLLY', response)
                      $state.go('retailer.assortment')
                    })
                    .catch(function (reason) {
                      console.error(reason)
                    })
                }
              })
              .catch(function (reason) {
                console.error(reason)
                $scope.alert = {
                  type: 'danger',
                  msg: 'Het lukte niet om uw gegevens op te slaan ' + reason
                }
                $scope.upload = false
              })
          } else {
            // it will save edited product of $scope.product which we made changed
            // if this product is availabel in other shop (with price and stock)
            // then it will added as a suggestion otherwise it will direct reflect in product table

            $api.update('suggest-update-product', { product: $scope.product, shop: $rootScope.currentShop._id })
              .then(function (success) {
                uploadImage() // Upload Image if any
                $state.go('retailer.assortment')
              })
              .catch(function (reason) {
                console.error(reason)
                $scope.alert = {
                  type: 'danger',
                  msg: 'Het lukte niet om uw gegevens op te slaan ' + reason
                }
                $scope.upload = false
              })
          }
        } else {
          $api.put('products/' + nameSlug, product)
            .then(function (response) {
              console.log('res', response)
              console.log('Product updated.')
            })
            .catch(function (reason) {
              console.error(reason)
            })
        }

        function uploadImage () {
          // START IMAGE UPLOAD IF ANY
          if ($scope.images && $scope.images.length > 0) {
            $scope.upload = true
            if (success.data.suggestionId) {
              var productId = success.data.suggestionId
              var data = {
                productId: productId,
                files: $scope.images, // file input field
                isUpdate: true
              }
            } else {
              var productId = success.data.productId
              var data = {
                productId: productId,
                files: $scope.images, // file input field
                shopId: $rootScope.currentShop._id
              }
            }
            Upload.upload({
              url: 'api/product-image-upload',
              data: data
            })
              .then(function (res) {
                response.data.product.images = angular.copy(res.data.files.images)
              }, function (res) {
                console.error('Error: ', res.status)
              }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total)
                $scope.uploadProgress = progressPercentage
              })
            $scope.upload = false
            // console.log('STATE GO: IF');
            // $state.go('retailer.assortment', { modalFor: $scope.product });
          }
        }
      }
    }

    if ($stateParams.nameSlug) {
      console.log('$stateParams.nameSlug', $stateParams.nameSlug)
      console.log('$stateParams.mergeProductnameSlug', $stateParams.mergeProductnameSlug)
      $scope.currentMode = 'edit'
      $api.get('products/' + $stateParams.nameSlug)
        .then(function (response) {
          console.log('RESPONSE: ', response)
          verifyMode = true
          $scope.product = response.data.product
          console.log('$scope.product', $scope.product)
          // console.log("product details Collections Check", response.data.product.collections);
          console.log('$stateParams.mergeProductnameSlug', $stateParams.mergeProductnameSlug)

          if ($stateParams.mergeProductnameSlug != 'Not_For_Merge') {
            $api.get('products/' + $stateParams.mergeProductnameSlug)
              .then(function (mergeresponse) {
                $scope.primaryMergeProduct = mergeresponse.data.product
                $scope.secondaryRetailerModalProduct = response.data.product
                // response.data.product.suggestions = mergeresponse.data.product.suggestions;
                console.log('secondatRetailerModal Prod ID: ', response.data.product._id)
                console.log('Primary Merge Prod ID: ', mergeresponse.data.product._id)
                // console.log('MERGE RESPONSE: ', mergeresponse);
                $scope.product = mergeresponse.data.product
                // $scope.suggestionsProduct = mergeresponse.data.product;
                $scope.suggestionsProduct = response.data.product

                // $scope.product.suggestions = mergeresponse.data.product.suggestions;

                console.log('ROoT SHOP ID: ', $rootScope.currentShop._id)
                // console.log('Suggestion: ', $scope.product._id, mergeresponse.data.product._id);
                if (response && response.data &&
									response.data.product &&
									response.data.product.suggestions &&
									response.data.product.suggestions.length) {
                  var suggestionsLength = (response.data.product.suggestions.length) - 1 // Retailer Modal Prod Suggestions
                  console.log('product handling guy Plese check here the length of the product suggestion', suggestionsLength)
                  for (var i = suggestionsLength; i > 0; i--) {
                    // console.log('Suggestion time: ', mergeresponse.data.product.suggestions[i].time);
                    // console.log('Main product time: ', mergeresponse.data.product.lastSuggestedDate);

                    // if(response.data.product.suggestions[i].time != undefined && )
                    if (response.data.product.suggestions[i].time > response.data.product.dateLastModified) {
                      // console.log('BREAK FOR LOOP: Add suggestions', $scope.product.suggestions[i]);
                      $scope.product.suggestions = response.data.product.suggestions[i]
                      $scope.showProduct = response.data.product.suggestions[i].item
                      break
                    }

                    console.log('product handling guy Here Check the showProduct', $scope.showProduct)
                    if (i == 0 && $scope.showProduct == undefined) {
                      console.log('MAIN PRODUCT UP TO DATE: ITS FINAL')
                      $scope.showProduct = response.data.product
                    }
                  }
                } else {
                  console.log('There is no suggestions')
                  $scope.showProduct = response.data.product
                }

                // response.data.product.suggestions = [...mergeresponse.data.product.suggestions];
                common()
              }).catch(function (mergeerr) {
                console.log(mergeerr)
              })
          } else {
            // When we are coming from Retailer home page
            // response.data.product --> which is clicked product from the retailer.home

            var LoginRetailerId = ($rootScope.user._id).toString()
            var tempSuggestion = []
            if (response.data.product.suggestions.length) {
              // Here I am checking that suggestions time > lastmodified and different retailer id
              var suggestionsLengthNoMerge = (response.data.product.suggestions.length) - 1
              for (var i = suggestionsLengthNoMerge; i >= 0; i--) {
                // console.log('response.product.suggestions:', response.data.product.suggestions);
                console.log((response.data.product.suggestions[i].suggester.id))
                console.log('LoginRetailerId: ', LoginRetailerId, response.data.product.suggestions[i].suggester.id)
                if ((response.data.product.suggestions[i].suggester.id).toString() != (LoginRetailerId)) {
                  if (response.data.product.suggestions[i].time > response.data.product.dateLastModified) {
                    console.log('Hello IN DATE COND: ')
                    tempSuggestion.push(response.data.product.suggestions[i])
                  }
                }

                if (i == 0) {
                  tempSuggestion.slice(0, 3) // Above matched condition array of suggestions (Only 3 there will be shown)
                  console.log('tempSuggestion.length: ', tempSuggestion.length)
                  console.log('tempSuggestion: ', tempSuggestion)
                  $scope.product.suggestions = tempSuggestion
                  common()
                }
              }
            } else {
              console.log('SUGGSTION NOT FOUND: ')
              common()
            }

            // common();
          }

          function common () {
            if ($scope.product.isVerified == true) $scope.adminMustCheck = false
            if ($scope.product.isVerified == false) $scope.adminMustCheck = true

            getTargetAudience()

            // console.log("SUGGESTIONS : ",$scope.product.suggestions[0]);
            $scope.productCategory = $scope.product.category
            $scope.image = $scope.product.images

            if (!$scope.product.nl) {
              $scope.product.nl = { views: 0 }
            }
            if (!$scope.product.en) {
              $scope.product.en = { views: 0 }
            }
            if (!$scope.product.de) {
              $scope.product.de = { views: 0 }
            }
            if (!$scope.product.fr) {
              $scope.product.fr = { views: 0 }
            }
            if (!$scope.product.es) {
              $scope.product.es = { views: 0 }
            }

            if ($scope.product.watch.dial != undefined) {
              $scope.setDialColorInitially($scope.product.watch.dial.color)
            }

            if ($scope.product.watch.case != undefined) {
              $scope.setCaseColorInitially($scope.product.watch.case.color)
            }

            if ($scope.product.watch.strap != undefined) {
              $scope.setStrapColorInitially($scope.product.watch.strap.color)
            }

            if ($scope.product.watch && $scope.product.watch.case && $scope.product.watch.case.material) {
              $scope.product.watch.case.material = $scope.convertToUpperAndSpace($scope.product.watch.case.material)
            }

            if ($scope.product.watch && $scope.product.watch.case && $scope.product.watch.case.glassMaterial) {
              $scope.product.watch.case.glassMaterial = $scope.convertToUpperAndSpace($scope.product.watch.case.glassMaterial)
            }

            if ($scope.product.watch && $scope.product.watch.case && $scope.product.watch.case.shape) {
              $scope.product.watch.case.shape = $scope.convertToUpperAndSpace($scope.product.watch.case.shape)
            }

            if ($scope.product.watch && $scope.product.watch.strap && $scope.product.watch.strap.model) {
              $scope.product.watch.strap.model = $scope.convertToUpperAndSpace($scope.product.watch.strap.model)
            }

            if ($scope.product.watch && $scope.product.watch.strap && $scope.product.watch.strap.model) {
              $scope.product.watch.strap.material = $scope.convertToUpperAndSpace($scope.product.watch.strap.material)
            }
          }
        })
        .catch(function (reason) {
          console.log(reason)
        })
    } else {
      $scope.currentMode = 'add'
      $scope.targetAudience = 'MALE'
      $scope.product = {
        variants: [{ collapse: false }],
        brand: {},
        category: 'WATCH',
        views: 0,
        nl: { views: 0 },
        en: { views: 0 },
        de: { views: 0 },
        fr: { views: 0 },
        es: { views: 0 },
        strap: {},
        jewel: {},
        watch: {
          case: {},
          strap: {},
          dial: {}
        },
        weight: '',
        totalCaratWeight: ''
      }
    }

    // console.log("CURRENT MODE = ", $scope.currentMode);
    $scope.getVersionValues = function (property) {
      if (!$scope.product || !$scope.product.versions || $scope.product.versions.length == 0) return
      var propertyValues = []
      for (var i = 0; i < $scope.product.versions.length; i++) {
        var value = _.get($scope.product.versions[i].modifiedProperties[0], property)
        if (value) {
          propertyValues.push(value)
        }
      }
      return propertyValues
    }

    $scope.deleteProduct = function () {
      // console.log($scope.product);
      $api.delete('products', { productId: $scope.product._id, shopId : $rootScope.currentShop._id })
        .then(function (response) {
          $state.go('admin.products')
        })
        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.addCollection = function (collection) {
      // console.log("Adding Collection : ", collection);
      collection.shopId = $rootScope.currentShop._id;
      $api.post('collections', collection).then(function (success) {
        console.log('Collection added successtully ', success.data)
        $scope.collections.push(success.data.collection)
        var collToBeSelected = {
          originalObject: success.data.collection,
          title: success.data.collection[$rootScope.language].name
        }
        $scope.collectionSelected(collToBeSelected)
        $scope.collection = {}
      }, function (err) {
        console.log('Error adding collection : ', err)
      })
    }

    $scope.deleteCollection = function (index) {
      $scope.product.collections.splice(index, 1)
      $api.put('products/' + $scope.product[$rootScope.language].nameSlug, $scope.product)
        .then(function (success) {
          console.log('Product has been updated with new collection.')
        }, function (err) {
          console.error(err)
        })
    }

    $scope.toggleAdminMustCheckSwitches = function (adminMustCheck) {
      if (adminMustCheck == true) {
        $scope.isVerified = false
        $scope.adminMustCheck = true
      } else {
        $scope.isVerified = true
        $scope.adminMustCheck = false
      }
    }

    $scope.toggleIsVerifiedSwitches = function (isVerified) {
      if (isVerified == true) {
        $scope.product.isVerified = true
        $scope.adminMustCheck = false
      } else {
        $scope.adminMustCheck = true
        $scope.product.isVerified = false
      }
    }
  }])

// 58d8d58ffef9b90f21dd5594
// 58d8d58ffef9b90f21dd5594", "58d8d58ffef9b90f21dd55aa

// $api.put('products/' + nameSlug, product)
// 	.then(function (response) {
// 		console.log("res", response);
// 		console.log('Product updated.');
// 		$state.go('admin.products');
// 	})
// 	.catch(function (reason) {
// 		console.error(reason);
// 	})
// return;
// console.log('FINAL PRODUCT: ', $scope.product);
