prismanoteApp.controller('homeLoginController', ['$scope', '$rootScope', '$api', '$language', '$state', '$stateParams', '$uibModal', '$user', '$retailer', '$employee', 'prompt',
  function ($scope, $rootScope, $api, $language, $state, $stateParams, $uibModal, $user, $retailer, $employee, prompt) {
    $scope.initialize = function () {
      $scope.loginDetails = {
        form: {}
      }
    }

    $scope.redirectUser = function (checkTo) {
      $rootScope.setUserInChat()
      if ($stateParams.to && checkTo) {
        $state.go($stateParams.to)
      } else {
        var role = $rootScope.user.role == 'wholesaler' ? 'brand' : $rootScope.user.role
        $state.go(role + '.home')
      }
    }

    if ($rootScope.user) {
      $scope.redirectUser()
    } else {
      $scope.initialize()
    }

    $scope.facebookLogin = function () {
      window.location = window.origin + '/api/auth/facebook'
    }

    $scope.login = function (redirect) {
      var response = grecaptcha.getResponse();
      if (!response || !response.length) {
        prompt({
          title: 'Warning',
          message: 'Please complete the captcha challenge'
        })
        return;
      }
      $scope.alert = null

      if ($scope.loginDetails.form.$invalid) {
        return
      }
      var body = {
        username: $scope.loginDetails.username.toLowerCase(),
        password: $scope.loginDetails.password,
        shopSlug: $rootScope.shopSlug,
        isComingFromAnyInvitationLink: ($rootScope.isFromRecommendationLink || $rootScope.campaignIdFromEmailLink || $rootScope.purchaseOrderNumberFromEmailLink) ? true : false
      }
      if (body.isComingFromAnyInvitationLink) {
        if ($rootScope.isFromRecommendationLink) {
          body.invitation = {
            isFromRecommendationLink: true,
            companyId: $rootScope.recommendationCompanyId,
            shopId: $rootScope.recommendationShopId
          }
        }
      }
      $api.post('login', body).then(function (response) {
        // $rootScope.currentShop = undefined;
        if (response && response.data && !response.data.mustReset && response.data.user) {

          var user = response.data.user;
          $rootScope.user = user;
          if($rootScope.user.isEmployee) {
            $rootScope.currentEmployee = angular.copy($rootScope.user)
          }
          $scope.initialize();
          if (user && user.role == 'retailer' && user.shops && user.shops.length && user.shops[0].nameSlug) {
            localStorage.setItem('currentShop', user.shops[0].nameSlug);
            localStorage.setItem('currentShopName', user.shops[0].name);
          } else if (user && user.role == 'wholesaler' && user.companies && user.companies.length && user.companies[0].nameSlug) {
            localStorage.setItem('currentCompany', user.companies[0].nameSlug)
          }

          $retailer.getShop()
            .then(function (shop) {
              $rootScope.currentShop = shop;
              if(user && user.role && (user.role == "retailer") && $rootScope.currentShop && $rootScope.currentShop.employeeLockSecurity) {
                if($rootScope.checkEmployeeModalNecessity(user)) {
                  $employee.openUnlockModal()
                }
              }
            })
          

          if (body.isComingFromAnyInvitationLink) {
            redirectBasedOnInvitationLink(response);
          } else {
            if (redirect == undefined || redirect === true) {
              $scope.redirectUser(true)
            } else {
              $scope.redirectUser();
            }
          }
          //$employee.openUnlockModal();
        } else {
          if (response.data.mustReset) {
            $scope.alert = {
              type: 'info',
              msg: $language.translate('YOU_MUST_SET_A_NEW_PASSWORD_BEFORE_CONTINUING')
            }
            $scope.data = {
              id: response.data.user._id,
              code: response.data.user.verificationCode,
              force: true
            }
            $scope.setNewPassword = true
          } else {
            $scope.alert = {
              type: 'danger',
              msg: $language.translate('INTERNAL_SERVER_ERROR')
            }
          }
        }
      }).catch(function (reason) {
        console.error('reason hey: ', reason);
        if (reason.data && reason.status && reason.status == 429) {
          // Too many requests
          $scope.alert = {
            type: 'danger',
            msg: reason.data
          }
          return
        }
        $scope.alert = {
          type: 'danger',
          msg: $language.translate(reason)
        }
      })
    }

    function redirectBasedOnInvitationLink(response) {
      if ($rootScope.campaignIdFromEmailLink) {
        $state.go('retailer.paidcampaign', { isCampaignStep: 'join', campaignId: $rootScope.campaignIdFromEmailLink })
      } else if ($rootScope.isFromRecommendationLink) {
        $rootScope.isFromRecommendationLink = undefined;
        recommendationRedirection(response);
      } else if ($rootScope.purchaseOrderNumberFromEmailLink && $rootScope.user.shops && $rootScope.user.shops.length) {
        var isOpenModal = $user.isHaveToOpenChooseShopModal($rootScope.user.shops, $rootScope.purchaseorder.shopDetail)

        if (isOpenModal.isOpen) {
          openTheChooseShopModal()
        } else {
          changeTheShop(isOpenModal.currentShopSlug)
          $state.go('retailer.purchase-order', { orderNo: $rootScope.purchaseOrderNumberFromEmailLink })
          $rootScope.purchaseOrderNumberFromEmailLink = undefined
          $rootScope.purchaseorder = undefined
        }
      } else {
        $scope.redirectUser();
      }
    }

    $scope.togglePassword = function () {
      var e = document.getElementById('pnPassword')
      if (!e) {
        return
      }
      if (e.type === 'password') {
        $('.fa-eye').show()
        $('.fa-eye-slash').hide()
        e.type = 'text'
      } else {
        $('.fa-eye').hide()
        $('.fa-eye-slash').show()
        e.type = 'password'
      }
    }

    $scope.redirectToResetPassword = function () {
      var body = {
        userId: $scope.data.id,
        verificationCode: $scope.data.code
      }
      $api.post('set-new-password-code', body).then(function (response) {
        if (response && response.data && response.data.data) {
          $scope.data.code = response.data.data
          $state.go('layout.resetpassword', $scope.data)
        } else {
          console.error('Internal Server Error');
        }
      }).catch(function (error) {
        console.error('Went wrong: ', error);
      });
    }

    function openTheChooseShopModal() {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/choose-shop-modal.html',
        controller: 'chooseShopModalController',
        size: 'md',
        backdrop: 'static',
        keyboard: false,
        backdropClass: 'unlock-backdrop',
        openedClass: 'unlock-open',
        resolve: {
          shops: function () {
            return $rootScope.user.shops
          }
        }
      })
      modalInstance.result.then(function (shop) {
        var changeShopBody = {
          shop: shop,
          purchaseOrder: {
            number: $rootScope.purchaseOrderNumberFromEmailLink
          }
        }
        $user.changePurchaseOrderShopWhenComingFromInvitationLink(changeShopBody).then(function (shopchangeres) {
          changeTheShop(shop.nameSlug)
          $state.go('retailer.purchase-order', { orderNo: $rootScope.purchaseOrderNumberFromEmailLink })
          $rootScope.purchaseOrderNumberFromEmailLink = undefined
          $rootScope.purchaseorder = undefined
        }).catch(function (shopchnageerr) {
          console.error('shopchnageerr: ', shopchnageerr)
          $state.go('layout.home')
          reject(reason)
        })
      }).catch(function (reason) {
        console.error('reason: ', reason)
        $state.go('layout.home')
        reject(reason)
      })
    }

    function changeTheShop(nameSlug) {
      localStorage.setItem('currentShop', nameSlug)
      $rootScope.selectedShop = nameSlug
      $scope.$broadcast('changeSelectedShop', nameSlug)
    }

    function fetchValidShopSlug(response, givenShopSlug) {
      var nameSlug = givenShopSlug;
      if (response.status === 203) {
        // Loggedin user does not have given shop
        nameSlug = $rootScope.user.shops[0].nameSlug;
      }
      return nameSlug;
    }

    function recommendationRedirection(response) {
      var nameSlug = fetchValidShopSlug(response, $rootScope.recommendationShopSlug);
      $rootScope.selectedShop = nameSlug;
      $retailer.getShop(nameSlug).then(function (shop) {
        $rootScope.currentShop = shop;
        //$rootScope.socketInit()
        $rootScope.openChats = []
        // resetCurrentFilters();

        // $data.loadData(show, force)
        $scope.$broadcast('changeSelectedShop', nameSlug)
        localStorage.setItem('currentShop', $scope.currentShop.nameSlug)
        localStorage.setItem('currentShopName', $scope.currentShop.name)
        localStorage.removeItem('lastLoggedInEmployees') // Removing old localstorage employee if any
        $rootScope.unreadNotifications = shop.unreadNotifications
        $rootScope.unreadMessageNotifications = shop.messageNotification.length

        if ($rootScope.unreadNotifications > 0) {
          var title = 'PrismaNote'
          if ($state.$current.data && $state.$current.data.pageTitle) {
            title = title + ' | ' + $language.translate($state.$current.data.pageTitle)
          }
          // title = '(' + $rootScope.unreadNotifications + ') ' + title
          $rootScope.pageTitle = title
          // Write also direct to document to show the result directly
          document.title = title
        }

        if (response.status === 203) {
          $state.redirectUser(); // As does not have valid shop, so no redirection
        } else {
          $state.go('retailer.recommendation-order', { orderNo: '' });
        }

      }).catch(function (reason) {
        console.error('reason: reason: ', reason);
      })
    }
  }])