prismanoteApp
    .controller("retailerPurchaseOrderController",
        ['$scope', '$rootScope', '$uibModal', '$api', '$stateParams', '$state', '$customerInform', '$retailer', '$http', 'FileSaver', 'prompt', '$purchaseOrder', '$clientGroup', '$interval', '$language', '$prefillCalculationForPurchasOrderAndCashRegister', '$shop', '$productConfig', '$q', 'scanner', '$crypto',
            function ($scope, $rootScope, $uibModal, $api, $stateParams, $state, $customerInform, $retailer, $http, FileSaver, prompt, $purchaseOrder, $clientGroup, $interval, $language, $prefillCalculationForPurchasOrderAndCashRegister, $shop, $productConfig, $q, scanner, $crypto) {
                $scope.localdata = {
                    currentremark: '',
                    loading: false,
                    isProductNumber: false, 
                    currentPage: 1,
                    pageSize: 5,
                    searchPageSize: 30,
                    searchCurrentPage: 1,
                    searchCurrentShopProductPage: 1,
                    showCalculationMargin: true,
                    search_value: '',
                    isRecommendedBtnClicked: false,
                    isEnteringThePage: true,
                    isShowDeletedItem: false,
                    isAnyPurchaseOrderItemDeleted: false,
                    numberpattern: /^-?[0-9]+([\.,][0-9]+)?/,
                    itemIndex: 0,
                    findProductsLoader: false,
                    productNumber: '',
                    ean: '',
                    productNumberSearch: false,
                    eanNumberSearch: false,
                    isCommonBrandRec: false,
                    isLikeEANNumber: false,
                    storageFactor: 0,
                    totalOrderQuantity: 0
                    // urlPattern: /^(https?:\/\/)?(www\.)?[a-z0-9-]*\.?[a-z0-9-]+\.[a-z0-9-]+(\/[^<>]*)?$/
                }
                $scope.searchMoreContent = false
                $scope.searchStarted = false
                $scope.commonBrandSelection = true
                $scope.editData = false
                $scope.showBrands = false
                $scope.companyBrandsDetail = []
                $scope.prefillPurchaseOrderItemData = {};
                $scope.productNumberStatus = 'default';
                $scope.canCompanyEdit = true;
                $scope.isAllCalculationIconGreen = true;
                $scope.order_date = new Date();
                $scope.delivery_date = new Date();
                $scope.companyBrands = [];
                $scope.isShopPrivateLabelCollectionsFetched = false;
                $scope.backorder_delivery_date = new Date();
                $scope.hasSupplier = false;
                $scope.mystatus = 1;
                $scope.searchMode = 'exact';
                $scope.offerItem = {
                    comment: '',
                    quantity: 1
                }
                // $scope.status = ['New', 'Processing', 'Inspection', 'backend', 'Completed'];
                $scope.purchaseOrderid = '';
                $scope.editItem = false;
                $scope.purchase_order = {};
                $scope.purchase_order = {
                    status: [{
                        status: 'New'
                    }]
                }
                $scope.viewby = 5;
                $scope.total = 0;
                $scope.itemsPerPage = 0;
                $scope.localdata.currentPage = 0;
                $scope.entryMethodCustomerValue;
                $scope.mouseover = true;
                $scope.limit = 300;
                $scope.viewAll = true;
                $scope.itemHistory = [];
                $scope.itemHistoryExpectedSize = 0;
                $scope.transactionDetailFlag = true;
                $scope.withoutItemHistoryFlag = true;
                $scope.callPurchaseOrder = true;
                $scope.purchaseOrderItems = {};
                $scope.loaderArray = [];
                $scope.create = false;

                // try {
                //
                //     SocketService.on('shop:' + $stateParams.orderNo + ':company:added:comment:purchaseorder', function (data) {
                //         if (data.isOffResTime) { // When in brandPortal user download the invoice, we need to stop that in retailer portal
                //             $scope.isCompayDownloaded = true;
                //             $scope.purchase_order.invoiceCountDown.isInvoiceGenerated = true;
                //             $scope.purchase_order.invoiceCountDown.isCompanyDownloadedInvoice = true;
                //             $scope.isCountDownFinished = true;
                //             $scope.timer = 'completed';
                //             $scope.canCompanyEdit = false;
                //             if ($scope.purchase_order.companyRemarks && $scope.purchase_order.companyRemarks.status) {
                //                 $scope.purchase_order.companyRemarks.status = 'Completed';
                //             } else {
                //                 $scope.purchase_order.companyRemarks = {
                //                     status: 'Completed'
                //                 }
                //             }
                //         } else {
                //             if ($scope.withoutItemHistory && $scope.withoutItemHistory.remarks && $scope.withoutItemHistory.remarks.remarks && $scope.withoutItemHistory.remarks.remarks.length && $scope.withoutItemHistory.remarks.remarks[0]) {
                //                 $scope.withoutItemHistory.remarks.remarks.push(data);
                //             } else {
                //                 $scope.withoutItemHistory = {
                //                     remarks: {
                //                         remarks: [data]
                //                     }
                //                 }
                //             }
                //             $scope.autoScrollcomment();
                //         }
                //     })
                // } catch (catcherr) {
                //     console.error('catcherr: ', catcherr);
                // }

                app.directive('ngEnter', function () { //a directive to 'enter key press' in elements with the "ng-enter" attribute

                    return function (scope, element, attrs) {

                        element.bind("keydown keypress", function (event) {
                            if (event.which === 13) {
                                scope.$apply(function () {
                                    scope.$eval(attrs.ngEnter);
                                });

                                event.preventDefault();
                            }
                        });
                    };
                })

                window.onbeforeunload = function (event) {
                    return confirm("Confirm refresh");
                };

                function isNumber(input) {
                    if (typeof input === 'number' && input != Infinity) {
                        return true
                    } else { return false }
                }

                $scope.addSetting = function () {
                    $scope.localdata.showCalculationMargin = !$scope.localdata.showCalculationMargin;
                    $scope.entryMethodCustomerValue = !$scope.entryMethodCustomerValue;
                    $scope.lastEntryMethodCustomerValue = $scope.entryMethodCustomerValue;
                    $scope.purchase_order.items[0].entryMethodCustomerValue = $scope.lastEntryMethodCustomerValue;
                    $scope.updateThePurchaseOrder($scope.purchase_order);
                }

                $scope.changeTheStatus = function (isChangingFromtheViewPage) {
                    var fromStatus = 'New';
                    if ($scope.mystatus == 2) fromStatus = 'Processing';
                    else if ($scope.mystatus == 1.5) fromStatus = 'Backorder';
                    else if ($scope.mystatus == 3) fromStatus = 'Inspection';
                    else if ($scope.mystatus == 4) fromStatus = 'Completed';
                    else if ($scope.mystatus == 5) fromStatus = 'Cancelled';
                    if (fromStatus == $scope.purchase_order.status[0].status) return;

                    // updating the status
                    if (isChangingFromtheViewPage) {
                        var logs = [];
                        logs.push(getTheLog("Status changed from " + fromStatus + " to " + $scope.purchase_order.status[0].status, 'retailerPortal'));
                        if ($scope.purchase_order.status[0].status == 'Inspection' || $scope.purchase_order.status[0].status == 'Completed') {
                            logs.push(getTheLog("Notification that order is arrived", 'supplierPortal'));
                        }
                        var statusbody = {
                            purchaseOrderId: $scope.purchase_order._id,
                            status: $scope.purchase_order.status[0].status,
                            oldStatus: fromStatus,
                            log: logs,
                            from: 'retailer',
                            isFirstPurchaseOrder: $scope.currentShopDetail.isFirstPurchaseOrder,
                            shopId: $rootScope.currentShop._id
                        }
                        $purchaseOrder.changeTheStatus(statusbody).then(function (statusChanged) {
                            if ($scope.localdata.isRecommendedBtnClicked) {
                                $state.go('retailer.recommendation-order', {
                                    orderNo: $scope.purchase_order.number
                                });
                                return;
                            }
                            setTheMyStatus();
                            $scope.GetExistingPurchaseOrder($scope.limit);
                        }).catch(function (error) {
                            console.error('error while changing the status: ', error);
                        });
                    }
                }

                $scope.updateDeliveryAddress = function (deliveryAddress) {
                    var modalInstance = $uibModal.open({
                        templateUrl: '../views/modal/edit-delivery-address.html',
                        controller: 'editDeliveryAddressModalController',
                        size: 'lg',
                        resolve: {
                            data: function () {
                                return deliveryAddress;
                            }
                        }
                    });
                    modalInstance.result.then(function (response) {
                        var isValidShop = true;
                        var message = '';
                        if (!response || !response.street || !response.houseNumber || !response.city ||
                            !response.country || !response.postalCode) {
                            isValidShop = false;
                            message = 'Shop address is not proper. First fill it proper then you can edit it';
                        }

                        if (isValidShop) {
                            $scope.invalidShop = false;
                            $scope.deliveryAddress = response;
                            $scope.purchase_order.billingDetails.address = [response];
                            if ($scope.purchase_order.items && $scope.purchase_order.items.length) {
                                var body = {
                                    purchaseOrderId: $scope.purchase_order._id,
                                    type: 'billingAddress',
                                    key: 'address',
                                    value: response,
                                    shopId: $rootScope.currentShop._id
                                }
                                $purchaseOrder.updatePurchaseOrderByKey(body).then(function (addresres) {
                                }).catch(function (error) {
                                    console.error('updatePurchaseOrderByKey address error: ', error);
                                });
                            }
                            resolve(response);
                        } else {
                            $scope.invalidShop = true;
                            $scope.invalidShopMessage = message;
                        }
                    }).catch(function (reason) {
                        reject(reason);
                    });
                }

                $scope.back = function () {
                    if ($scope.purchase_order && $scope.purchase_order.status && $scope.purchase_order.status.length &&
                        $scope.purchase_order.company &&
                        $scope.purchase_order.status[0].status && $scope.purchase_order.status[0].status === 'New') {
                        openProcessPurchaseOrderModal({ isBackButtonOfPurchaseOrder: true });
                    } else {
                        $rootScope.isPurchaseOrderNew = false;
                        $state.go('retailer.purchase-orders', { company: '' })
                    }
                }

                $scope.closeLoader = function () {
                    $scope.cancleSearch = true;
                    $scope.localdata.loading = false;
                    delete $scope.localdata.search_value;
                }

                $scope.myFunct = function (keyEvent, isProductNumber) {
                    $scope.localdata.searchCurrentPage = 1;
                    $scope.localdata.searchCurrentShopProductPage = 1;
                    $scope.shopProductsData = [];
                    $scope.hits = [];
                    $scope.productExactMatch = false;
                    if (keyEvent.target && keyEvent.target.value) $scope.localdata.search_value = (keyEvent.target.value).toUpperCase();
                    if (keyEvent.which === 13) {
                        $scope.searchquery = keyEvent.target.value;
                        $scope.findProducts($scope.searchquery, $scope.searchMode, isProductNumber)
                    }
                }

                $scope.findProducts = function (value, searchMode, isProductNumber, callback) {
                    if (isProductNumber) $scope.localdata.isProductNumber = true;
                    else $scope.localdata.isProductNumber = true;
                    $scope.hits = [];
                    $scope.localdata.search_value = value;
                    $scope.unofficialProduct = undefined;
                    $scope.gotProductsResponse = false;
                    $scope.offerItem.product_id = undefined;
                    $scope.searchStarted = true;
                    $scope.productExactMatch = false;
                    $scope.searchingProduct_secondAPIcall = true;
                    // setTimeout(function () { document.getElementById('search_field_purchaseOrder').focus(); }, 1000);
                    if ($scope.currentShopDetail) {
                        if (value) {
                            $scope.localdata.search_value = $productConfig.retrunValidProductNumber(value);
                            if (isProductNumber) $scope.localdata.productNumber = $scope.localdata.search_value;
                            else $scope.localdata.ean = $scope.localdata.search_value;
                            value = $scope.localdata.search_value;
                            if (value.length < 3) return;
                            $scope.searchingProduct = true;
                            $scope.cancleSearch = false;
                            $scope.productNumberStatus = 'default';
                            var data = {
                                nameSlug: $scope.currentShopDetail.nameSlug,
                                searchMode: searchMode,
                                searchText: value,
                                shopId: $scope.currentShopDetail._id,
                                shopProducts: true,
                                useFor: 'purchase-order',
                                companyId: $scope.selectedCompany._id,
                                shopNameSlug: $scope.selectedCompany.name,
                                BrandIdsList: $scope.BrandIdsList != undefined ? $scope.BrandIdsList : undefined,
                                companyNameSlug: $scope.selectedCompany.nameSlug,
                                language: $rootScope.language,
                                isOnlyByBrand: false,
                                isProductNumber: isProductNumber,
                                offset: $scope.searchCurrentPage - 1,
                                shopProductOffSet: $scope.searchCurrentShopProductPage - 1
                            };

                            console.log('data: ', data);

                            /* shopNameSlug: $scope.selectedCompany.nameSlug,
                            companyNameSlug: $scope.currentShopDetail.nameSlug */
                            $scope.localdata.findProductsLoader = true;
                            findProductForMergeExactDataByOnlyCommonBrands(data); // To fetch the basic data (Not exact data)
                            // setTimeout(function () { document.getElementById('search_field_purchaseOrder').focus(); }, 1000);
                            $api.put('purchase-order-products-search-data/', data)
                                .then(function (response) {
                                    console.log('purchase-order-products-search-data called: ', response);
                                    $scope.gotProductsResponse = true;
                                    $scope.searchingProduct_secondAPIcall = false;
                                    // $scope.localdata.loading = false;
                                    // document.getElementById('search_field_purchaseOrder').focus();
                                    $scope.localdata.findProductsLoader = false;
                                    if (response.status != 200) {
                                        return;
                                    } else {
                                        if (!$scope.cancleSearch) {
                                            if (response.data.data.status != undefined || response.data.data.status != null) $scope.productNumberStatus = parseInt(response.data.data.status);
                                            $scope.hideHits = true;
                                            $scope.searchingProduct = false;
                                            if (callback) return callback(response.data.data.products[0])
                                            var data = response.data.data.products;
                                            var counts = response.data.data.count;
                                            var shopProductsData = response.data.data.shopProducts;
                                            var shopProductCount = response.data.data.shopProductCount;

                                            $scope.totalProducts = counts;
                                            $scope.totalShopProducts = shopProductCount;
                                            $scope.shopProductsData = shopProductsData;
                                            $scope.productExactMatch = response.data.data.productExactMatch;
                                            var exactProduct = response.data.data.exactProduct;
                                            //console.log('Okay, so you are here?');
                                            if ($scope.localdata.eanNumberSearch && $scope.productExactMatch && exactProduct) {
                                                //console.log('Okay, so you are here? IF');
                                                $scope.hits = [exactProduct];
                                            } else {
                                                //console.log('Okay, so you are here? ELSE');
                                                $scope.hits = $purchaseOrder.removeDuplicateProduct($scope.hits, data);
                                                // var concatedArray = $scope.hits.concat(data);
                                                // $scope.hits = concatedArray;
                                            }
                                            $scope.countOfProducts = $scope.countOfProducts + counts;

                                        } else {
                                            $scope.searchingProduct = false;
                                        }
                                    }
                                    //  $scope.searchItem(data)
                                }).catch(function (reason) {
                                    // document.getElementById('search_field_purchaseOrder').focus();
                                    // $scope.localdata.loading = false;
                                    $scope.searchingProduct_secondAPIcall = false;
                                    $scope.localdata.findProductsLoader = false;
                                    $scope.searchingProduct = false;
                                    console.error(reason);
                                });
                        }
                    }
                };

                function findProductForMergeExactDataByOnlyCommonBrands(data) {
                    var senddata = JSON.parse(JSON.stringify(data));
                    senddata.isOnlyByBrand = true;
                    // $scope.searchingProduct_secondAPIcall = true;
                    $api.post('purchase-order-products-search-data-by-onlybrand/', senddata).then(function (response) {
                        // $scope.localdata.loading = false;
                        // document.getElementById('search_field_purchaseOrder').focus();
                        $scope.localdata.findProductsLoader = false;
                        $scope.searchingProduct = false;
                        if (response.status != 200) {
                            return;
                        } else {
                            if (!$scope.cancleSearch) {
                                if (response.data.data.status != undefined || response.data.data.status != null) $scope.productNumberStatus = parseInt(response.data.data.status);
                                $scope.hideHits = true;
                                // $scope.searchingProduct_secondAPIcall = false;
                                // if (callback) return callback(response.data.data.products[0])
                                if (!$scope.hits.length) {
                                    $scope.hits = response.data.data.products;
                                    $scope.countOfProducts = response.data.data.count;
                                }
                                // if (!($scope.hits && $scope.hits.length > 0 && $scope.countOfProducts)) $scope.hideHits = false;
                            } else {
                                // $scope.searchingProduct_secondAPIcall = false;
                            }
                        }
                        //  $scope.searchItem(data)
                    }).catch(function (reason) {
                        // document.getElementById('search_field_purchaseOrder').focus();
                        // $scope.localdata.loading = false;
                        $scope.localdata.findProductsLoader = false;
                        $scope.searchingProduct = false;
                        // $scope.searchingProduct_secondAPIcall = false;
                        console.error(reason);
                    });
                }

                $scope.searchItem = function (data) {
                    $api.put('purchase-order-more-products-search/', data)
                        .then(function (response) {
                            if (response.status != 200) {
                                return;
                            } else {
                                if (!$scope.cancleSearch) {
                                    if (response.data.data.status != undefined || response.data.data.status != null) $scope.productNumberStatus = parseInt(response.data.data.status);
                                    $scope.hideHits = true;
                                    $scope.searchingProduct = false;
                                    if (response.data.data.products && response.data.data.products.length > 0) {
                                        for (var l = 0; l < response.data.data.products.length; l++) {
                                            $scope.hits.push(response.data.data.products[l])
                                        }
                                        //  $scope.countOfProducts += response.data.data.count;
                                    }
                                    // if (!($scope.hits && $scope.hits.length > 0 && $scope.countOfProducts)) $scope.hideHits = false;
                                }
                            }
                        }).catch(function (reason) {
                            // document.getElementById('search_field_purchaseOrder').focus();
                            // $scope.localdata.loading = false;
                            console.error(reason);
                        });
                }

                $scope.searchVal = function (val, isProductNumber) {
                    $scope.productExactMatch = false;
                    $scope.gotProductsResponse = false;
                    $scope.localdata.isLikeEANNumber = false;
                    if (!$scope.localdata.isCommonBrandRec) fetchCommonBrand();
                    if (isProductNumber) {
                        $scope.localdata.productNumberSearch = true;
                        $scope.localdata.eanNumberSearch = false;
                        if ($purchaseOrder.isLikeEANNumber(val)) $scope.localdata.isLikeEANNumber = true;
                    } else {
                        $scope.localdata.eanNumberSearch = true; $scope.localdata.productNumberSearch = false;
                    }
                    if (val.length == 0 && !$scope.localdata.productNumber && !$scope.localdata.ean) {
                        $scope.searchMoreContent = false;
                    } else {
                        $scope.searchMoreContent = true;
                        if (val.length) {
                            $scope.localdata.search_value = (val).toUpperCase(); // $productConfig.retrunValidProductNumber(val);
                            if (isProductNumber) $scope.localdata.productNumber = $scope.localdata.search_value;
                            else $scope.localdata.ean = $scope.localdata.search_value;
                        }
                    }

                }

                $scope.addItem = function (product, callback) {
                    if (product && product.variants && product.variants.length) {
                        if (product.variants[0].productNumber) $scope.localdata.productNumber = $scope.localdata.search_value = product.variants[0].productNumber;
                        if (product.variants[0].ean) $scope.localdata.ean = product.variants[0].ean;
                    }
                    $scope.hits = [];
                    $scope.hideHits = false;
                    $scope.searchMoreContent = false;
                    $scope.offerItem = {
                        quantity: 1
                    };
                    if (product && product._id) {

                        $shop.getPurchaseOrderProduct($scope.currentShopDetail._id, product._id, $scope.selectedCompany._id)
                            .then(function (productDetails) {
                                delete product;
                                var product = productDetails.product;
                                if (productDetails.shopProduct) product['shopProduct'] = productDetails.shopProduct;
                                if (productDetails.companyProduct) product['companyProduct'] = productDetails.companyProduct;

                                $scope.localdata.search_value = product.variants[0].productNumber;
                                $scope.offerItem.comment = ($rootScope.language) ? product[$rootScope.language].name : product.en.name;
                                $scope.offerItem.product_id = product._id;
                                $scope.offerItem.number = product.variants[0].productNumber;
                                $scope.offerItem.name = product.en.name;

                                $scope.prefillPurchaseOrderItemData.suggestedRetailPrice = product.suggestedRetailPrice;
                                $scope.prefillPurchaseOrderItemData.suggestedRetailPriceVat = product.suggestedRetailPriceVat;

                                if (product.companyProduct) {
                                    $scope.prefillPurchaseOrderItemData.isCompanyProduct = true;
                                    $scope.prefillPurchaseOrderItemData.companyProduct = product.companyProduct;
                                } else {
                                    $scope.prefillPurchaseOrderItemData.isCompanyProduct = false;
                                }
                                if (product.shopProduct) {
                                    $scope.prefillPurchaseOrderItemData.isShopProduct = true;
                                    $scope.prefillPurchaseOrderItemData.shopProduct = product.shopProduct;
                                } else {
                                    $scope.prefillPurchaseOrderItemData.isShopProduct = false;
                                }
                                prefillTheValueWhenAddingProduct();
                                if (callback) {
                                    return callback($scope.offerItem);
                                }
                                //-$scope.saveItem($scope.offerItem, $scope.localdata.search_value)
                            })
                            .catch(function (error) {
                                console.error(error);
                            })
                    } else {
                        // This else will not but sometimes not getting proper data from then this will call
                        $scope.prefillPurchaseOrderItemData.isShopProduct = false;
                        $scope.prefillPurchaseOrderItemData.isCompanyProduct = false;
                        prefillTheValueWhenAddingProduct();
                        if (callback) {
                            return callback($scope.offerItem);
                        }
                        //-$scope.saveItem($scope.offerItem, $scope.localdata.search_value)
                    }
                    $scope.offerItem.price = 0;
                    $scope.offerItem.quantity = 1;
                    $scope.offerItem.stock = 0;
                }

                $scope.useAsNew = function () {
                    $scope.hideHits = false;
                    $scope.searchMoreContent = false;
                    $('#offercomment').focus();
                }

                $scope.GetExistingPurchaseOrderOtherDeatails = function (index) {
                    var skip = $scope.itemHistoryExpectedSize;
                    var limit = index + 10 - skip;
                    if (index >= $scope.itemHistoryExpectedSize) {
                        $scope.itemHistoryExpectedSize = skip + limit;
                        // commented for some time
                        // if ($scope.transactionDetailFlag) $scope.getPurchaseOrdreTransactionDetails();
                        // if ($scope.withoutItemHistoryFlag) $scope.getPurchaseOrdreWithoutItemHistory();
                        // $scope.GetExistingPurchaseOrderItemHistory(limit, skip, index);
                        $scope.mouseover = false
                    }
                }

                $scope.viewAllItems = function () {
                    var limit = 1000;
                    $scope.GetExistingPurchaseOrder2(limit);
                    $scope.viewAll = false;
                }

                $scope.GetExistingPurchaseOrder2 = function (limit) {
                    // When we have already purchase order
                    var page = $scope.localdata.currentPage + 1;
                    if ($scope.currentShopDetail) {

                        var ms = new Date().getSeconds();
                        $api.get('purchase-order/' + $stateParams.orderNo + '/' + $scope.currentShopDetail._id + "?shopSlug=" + $scope.currentShopDetail.nameSlug + "&limit=" + limit)
                            .then(function (response) {

                                $scope.hasSupplier = true;
                                var purchaseOrder = response.data.data.purchase_order;

                                var oldData = $scope.purchase_order.items;

                                var purchaseOrderItems = purchaseOrder.items;
                                $scope.purchase_order.items = oldData.concat(purchaseOrderItems);

                                $scope.changeTheStatus();
                                if ($rootScope.isBackOfRecommendation) {
                                    openProcessPurchaseOrderModal();
                                    $rootScope.isBackButtonOfPurchaseOrder = undefined;
                                }

                                if ($scope.purchase_order && $scope.purchase_order.shop && $scope.purchase_order.shop._id && $scope.purchase_order.company && $scope.purchase_order.company._id) {
                                    $scope.getShopClientGroups($scope.purchase_order.shop._id, $scope.purchase_order.company._id); // Getting clien groups of particular shop and company
                                }
                                $scope.purchase_order.lastInvoiceNumber = $scope.purchase_order.invoiceNumber || '';
                                // Updating value of date in view
                                if ($scope.purchase_order.order_date && $scope.purchase_order.delivery_date) {
                                    $scope.order_date = new Date($scope.purchase_order.order_date);
                                    $scope.delivery_date = new Date($scope.purchase_order.delivery_date);
                                }

                                if ($scope.purchase_order.backorder_delivery_date) {
                                    $scope.backorder_delivery_date = new Date($scope.purchase_order.backorder_delivery_date);
                                }

                                if ($scope.purchase_order.companyRemarks && $scope.purchase_order.companyRemarks.delivery_date) {
                                    $scope.purchase_order.companyRemarks.delivery_date = new Date($scope.purchase_order.companyRemarks.delivery_date);
                                }

                                if (($scope.purchase_order.companyRemarks && ($scope.purchase_order.companyRemarks.status == 'Completed' || $scope.purchase_order.companyRemarks.status == 'completed')) || ($scope.purchase_order.status[0].status == 'Cancelled')) {
                                    $scope.canCompanyEdit = false;
                                }

                                checkCalculationIconIsBlackOrGreen();
                                timerForInvoice();
                                // Below for-loop is for checkmark
                                var purchaser_order_length = purchaseOrderItems.length;
                                $scope.localdata.isAnyPurchaseOrderItemDeleted = false;
                                for (var i = 10; i < purchaser_order_length + 10; i++) {
                                    // console.log('currentPurchasrOrderProduct: ', i, purchaseOrderItems.length);
                                    var currentPurchasrOrderProduct = purchaseOrderItems[i].product_id;

                                    $scope.purchase_order.items[i].priceIncVat = (purchaseOrderItems[i].price * (1 + (purchaseOrderItems[i].priceVat / 100))) // To show inc vat in purchase order rows

                                    // CHECKING IF ANY OF ONE ITEM IS DELETED OR NOT
                                    if (!$scope.localdata.isAnyPurchaseOrderItemDeleted && purchaseOrderItems[i].isDeleted) {
                                        $scope.localdata.isAnyPurchaseOrderItemDeleted = true;
                                    }

                                    if (currentPurchasrOrderProduct) {
                                        
                                        // If shop.product.show and products.contaisFilterInfo both are false then switch is disabled
                                        // CHECKING THE SWITCH START
                                        $scope.purchase_order.items[i].isSwitchEnable = false;
                                        if (currentPurchasrOrderProduct.containsFilterInfo && currentPurchasrOrderProduct.containsFilterInfo[$scope.language]) {
                                            $scope.purchase_order.items[i].isSwitchEnable = true;
                                        }

                                        // isSwitchEnable is false then we will check in shop.products.show if its true then will make it enable otherwise its false (befor this we check containsFilterInfo)
                                        if (currentPurchasrOrderProduct.shopProduct && currentPurchasrOrderProduct.shopProduct.show) {
                                            $scope.purchase_order.items[i].isSwitchEnable = true;
                                            $scope.purchase_order.items[i].isWebShop = true;
                                        }
                                        // CHECKING THE SWITCH END
                                    }
                                }
                                $scope.purchaseOrderid = $scope.purchase_order._id;
                                if (response.data && response.data.billingDetails && response.data.billingDetails.length > 0) {
                                    $scope.deliveryAddress = response.data.billingDetails[0].address[0];
                                } else if ($scope.purchase_order.billingDetails && $scope.purchase_order.billingDetails.address && $scope.purchase_order.billingDetails.address.length) {
                                    $scope.deliveryAddress = $scope.purchase_order.billingDetails.address[0];
                                } else {
                                    $scope.purchase_order.billingDetails = {
                                        address: [$scope.currentShopDetail.address]
                                    };
                                    $scope.deliveryAddress = $scope.purchase_order.billingDetails.address[0];
                                }
                                $scope.total = response.totalItems;
                                var companyLength = $scope.companies && $scope.companies.length ? $scope.companies.length : 0;
                                $scope.selectedCompany = {}; // If its from the suggestion then we need to show that name instead of purchase order one
                                for (var i = 0; i < companyLength; i++) {
                                    if ($scope.companies[i] && $scope.companies[i]._id == $scope.purchase_order.company._id) {
                                        $scope.selectedCompany = $scope.companies[i];
                                    }
                                }

                                if (!$scope.selectedCompany || !$scope.selectedCompany.name) {
                                    if (checkIsInternalSupplier()) {
                                        $scope.selectInternalSupplier();
                                    }
                                }

                                $scope.focusIndex = undefined;
                                $scope.bookAllRecLoading = false;
                                $scope.priceCollLoading = false;

                                enableDisableFieldOfInput(false);
                                checkOrderIsCompletedOrNot();
                            })
                            .catch(function (reason) {
                                $scope.priceCollLoading = false;
                                $scope.localdata.loading = false;
                                $scope.bookAllRecLoading = false;
                                enableDisableFieldOfInput(false);

                                if (reason == 'No purchase order found with that order no') {
                                    $state.go('retailer.purchase-orders', { company: '' });
                                    $scope.erromessage = 'Oops! We could not find your purchase order on this purchase number';
                                    $scope.alertdanger = true;
                                } else {
                                    console.error(reason.status);
                                }
                            })
                    } else {
                        $scope.erromessage = 'Please change the language';
                        $scope.alertdanger = true;
                    }
                }

                $rootScope.$on('myEvent', function (event, data) {
                    console.log('-------------- myEvent!!');
                    var productId = data.productId;
                    var newStock = data.newStock;
                    for (i = 0; i < $scope.purchase_order.items.length; i++) {
                        if ($scope.purchase_order.items[i].product_id._id == productId) {
                            $scope.purchase_order.items[i].product_id.shopProduct.stock = newStock;
                            checkCalculationIconIsBlackOrGreen();
                            // checkAllCalculationIconGreenOrNot();
                        }
                    }
                })

                $rootScope.$on('callPurchaseOrder', function (event, data) {
                    console.log('callPurchaseOrder called: ', data, $scope.callPurchaseOrder)
                    $scope.cancelItem();
                    if (data.create) { $scope.create = true; } else { $scope.create = false; }
                    var prodShopId = data.prodShopId;
                    var prodId = data.prodId;
                    // var tempArr = $scope.loaderArray;
                    var index = $scope.loaderArray.indexOf(prodShopId);
                    if (index < 0) $scope.loaderArray.push(prodShopId);
                    // $scope.loaderArray = tempArr;
                    if ($scope.callPurchaseOrder) $scope.GetSingleProductFromPurchaseOrder(data.prodShopId, data.index);
                    $scope.callPurchaseOrder = false;
                })

                $rootScope.$on('mergepurchaseorder', function (event, data) {
                    console.log('mergepurchaseorder: ', data);
                    if (data.isNeedsToCreate) {
                        $scope.GetSingleProductFromPurchaseOrder(data.productId, data.index);
                    } else {
                        $scope.GetSingleProductFromPurchaseOrder(data.productId, data.index);
                        var item = $scope.purchase_order.items[data.index];
                        $scope.newEditProduct(item, data.index);
                    }

                });

                $rootScope.$on('cancelItem', function (event) {
                    $scope.cancelItem();
                })

                $scope.checkLoaderStatus = function (item) {
                    var productId1 = item._id;
                    var productId2;
                    if (item && item.product_id && item.product_id._id) { productId2 = item.product_id._id };

                    for (i = 0; i < $scope.loaderArray.length; i++) {
                        if ($scope.loaderArray[i] == productId1 || $scope.loaderArray[i] == productId2) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }

                $scope.GetSingleProductFromPurchaseOrder = function (productId, index) {
                    console.log('------------- GetSingleProductFromPurchaseOrder!!');
                    if ($scope.currentShopDetail) {
                        var ms = new Date().getSeconds();
                        // remove setTimeout if everything works fine
                        var delayInMilliseconds = 000; // 2 second
                        if (!$scope.create) { delayInMilliseconds = 0 }
                        setTimeout(function () {
                            var orderNum = angular.copy($stateParams.orderNo);
                            if ($rootScope.orderNo) { orderNum = $rootScope.orderNo; }
                            if ((index === 0 || index) && $scope.purchase_order && $scope.purchase_order.items && $scope.purchase_order.items.length) $scope.purchase_order.items[index].loading = true;
                            $api.get('purchase-order-single-product/' + orderNum + '/' + $scope.currentShopDetail._id + '/' + productId + "?shopSlug=" + $scope.currentShopDetail.nameSlug + "&index=" + index)
                                .then(function (response) {
                                    console.log('GetSingleProductFromPurchaseOrder called: ', response);
                                    $rootScope.creatingItem = false;
                                    $scope.callPurchaseOrder = true;
                                    // $scope.localdata.loading = false;
                                    $scope.hasSupplier = true;
                                    var purchaseOrder = response.data.data.purchase_order;
                                    var tempIndex = parseInt(response.data.data.index);
                                    $scope.purchase_order.purchaserOrderHistory = purchaseOrder.purchaserOrderHistory;
                                    $scope.orderNumber = purchaseOrder.number;

                                    var newItems = angular.copy(purchaseOrder.items);
                                    var oldItems = angular.copy($scope.purchase_order.items);

                                    //console.log('tempIndex: ', tempIndex, JSON.parse(JSON.stringify(newItems)), JSON.parse(JSON.stringify(oldItems)));

                                    if (tempIndex > -1 && oldItems && oldItems[tempIndex] && newItems[0]) {
                                        console.log('---------- Yes Index Found!!', tempIndex);
                                        if (newItems[0] && newItems[0].product_id && newItems[0].product_id.length) newItems[0].product_id = newItems[0].product_id[0];
                                        oldItems[tempIndex] = newItems[0];
                                    }

                                    console.log('NOT CHANGED oldItems: ', JSON.parse(JSON.stringify(oldItems)));

                                    $scope.purchase_order.items = angular.copy(oldItems);

                                    var purchaseOrderItems = $scope.purchase_order.items;

                                    setTheMyStatus();
                                    $scope.changeTheStatus();
                                    if ($rootScope.isBackOfRecommendation) {
                                        openProcessPurchaseOrderModal();
                                        $rootScope.isBackButtonOfPurchaseOrder = undefined;
                                    }

                                    if ($scope.purchase_order && $scope.purchase_order.shop && $scope.purchase_order.shop._id && $scope.purchase_order.company && $scope.purchase_order.company._id) {
                                        $scope.getShopClientGroups($scope.purchase_order.shop._id, $scope.purchase_order.company._id); // Getting clien groups of particular shop and company
                                    }
                                    $scope.purchase_order.lastInvoiceNumber = $scope.purchase_order.invoiceNumber || '';
                                    // Updating value of date in view
                                    if ($scope.purchase_order.order_date && $scope.purchase_order.delivery_date) {
                                        $scope.order_date = new Date($scope.purchase_order.order_date);
                                        $scope.delivery_date = new Date($scope.purchase_order.delivery_date);
                                    }

                                    if ($scope.purchase_order.backorder_delivery_date) {
                                        $scope.backorder_delivery_date = new Date($scope.purchase_order.backorder_delivery_date);
                                    }

                                    if ($scope.purchase_order.companyRemarks && $scope.purchase_order.companyRemarks.delivery_date) {
                                        $scope.purchase_order.companyRemarks.delivery_date = new Date($scope.purchase_order.companyRemarks.delivery_date);
                                    }

                                    if (($scope.purchase_order.companyRemarks && ($scope.purchase_order.companyRemarks.status == 'Completed' || $scope.purchase_order.companyRemarks.status == 'completed')) || ($scope.purchase_order.status[0].status == 'Cancelled')) {
                                        $scope.canCompanyEdit = false;

                                    }

                                    checkCalculationIconIsBlackOrGreen();
                                    timerForInvoice();

                                    // Below for-loop is for checkmark
                                    var purchaser_order_length = $scope.purchase_order.items.length;
                                    $scope.localdata.isAnyPurchaseOrderItemDeleted = false;
                                    for (var i = 0; i < purchaser_order_length; i++) {
                                        var currentPurchasrOrderProduct = $scope.purchase_order.items[i].product_id;
                                        $scope.purchase_order.items[i].priceIncVat = parseFloat(($scope.purchase_order.items[i].price * (1 + ($scope.purchase_order.items[i].priceVat / 100))).toFixed(3)) // To show inc vat in purchase order rows

                                        // CHECKING IF ANY OF ONE ITEM IS DELETED OR NOT
                                        if (!$scope.localdata.isAnyPurchaseOrderItemDeleted && $scope.purchase_order.items[i].isDeleted) {
                                            $scope.localdata.isAnyPurchaseOrderItemDeleted = true;
                                        }

                                        if (currentPurchasrOrderProduct) {
                                            // If shop.product.show and products.contaisFilterInfo both are false then switch is disabled
                                            // CHECKING THE SWITCH START
                                            $scope.purchase_order.items[i].isSwitchEnable = false;
                                            if (currentPurchasrOrderProduct.containsFilterInfo && currentPurchasrOrderProduct.containsFilterInfo[$scope.language]) {
                                                $scope.purchase_order.items[i].isSwitchEnable = true;
                                            }

                                            // isSwitchEnable is false then we will check in shop.products.show if its true then will make it enable otherwise its false (befor this we check containsFilterInfo)
                                            if (currentPurchasrOrderProduct.shopProduct && currentPurchasrOrderProduct.shopProduct.show) {
                                                $scope.purchase_order.items[i].isSwitchEnable = true;
                                                $scope.purchase_order.items[i].isWebShop = true;
                                            }
                                            // CHECKING THE SWITCH END
                                        }
                                    }

                                    $scope.purchaseOrderid = $scope.purchase_order._id;
                                    if (response.data && response.data.billingDetails && response.data.billingDetails.length > 0) {
                                        $scope.deliveryAddress = response.data.billingDetails[0].address[0];
                                    } else if ($scope.purchase_order.billingDetails && $scope.purchase_order.billingDetails.address && $scope.purchase_order.billingDetails.address.length) {
                                        $scope.deliveryAddress = $scope.purchase_order.billingDetails.address[0];
                                    } else {
                                        $scope.purchase_order.billingDetails = {
                                            address: [$scope.currentShopDetail.address]
                                        };
                                        $scope.deliveryAddress = $scope.purchase_order.billingDetails.address[0];
                                    }
                                    $scope.total = response.totalItems;
                                    var companyLength = $scope.companies && $scope.companies.length ? $scope.companies.length : 0;
                                    $scope.selectedCompany = {}; // If its from the suggestion then we need to show that name instead of purchase order one
                                    for (var i = 0; i < companyLength; i++) {
                                        if ($scope.companies[i] && $scope.companies[i]._id == $scope.purchase_order.company._id) {
                                            $scope.selectedCompany = $scope.companies[i];
                                        }
                                    }

                                    if (!$scope.selectedCompany || !$scope.selectedCompany.name) {
                                        if (checkIsInternalSupplier()) {
                                            $scope.selectInternalSupplier();
                                        }
                                    }
                                    $scope.orderNumber = purchaseOrder.number;
                                    $scope.focusIndex = undefined;
                                    $scope.bookAllRecLoading = false;
                                    $scope.priceCollLoading = false;
                                    enableDisableFieldOfInput(false);
                                    checkOrderIsCompletedOrNot();
                                    checkIsCancelledFlag();


                                    var index = $scope.loaderArray.indexOf(newItems[0]._id);
                                    if (index > -1) $scope.loaderArray.splice(index, 1);
                                    index = $scope.loaderArray.indexOf(newItems[0].product_id._id);
                                    if (index > -1) $scope.loaderArray.splice(index, 1);

                                    if ((index === 0 || index) && $scope.purchase_order.items.length) $scope.purchase_order.items[index].loading = false;
                                })
                                .catch(function (reason) {

                                    $scope.priceCollLoading = false;
                                    $scope.localdata.loading = false;
                                    $scope.bookAllRecLoading = false;
                                    enableDisableFieldOfInput(false);
                                    if ((index === 0 || index) && $scope.purchase_order.items.length) $scope.purchase_order.items[index].loading = false;
                                    if (reason == 'No purchase order found with that order no') {
                                        // $state.go('retailer.purchase-orders', { company: '' });
                                        $scope.erromessage = 'Oops! We could not find your purchase order on this purchase number';
                                        $scope.alertdanger = true;
                                    } else {
                                        console.error(reason.status);
                                    }
                                })
                        }, delayInMilliseconds);

                    } else {
                        $scope.erromessage = 'Please change the language';
                        $scope.alertdanger = true;
                    }
                }

                // Getting purchaser order(If already there with the purchase order)
                $scope.GetExistingPurchaseOrder = function (limit, isAddingNewProduct) {
                    console.log('--------------- GetExistingPurchaseOrder!!');
                    // When we have already purchase order
                    var page = $scope.localdata.currentPage + 1;
                    if ($scope.currentShopDetail) {

                        // $scope.localdata.loading = true;
                        var ms = new Date().getSeconds();
                        if (isAddingNewProduct && $scope.purchase_order.items && $scope.purchase_order.items.length) {
                            $scope.purchase_order.items[$scope.purchase_order.items.length - 1].isGetOrderLoading = true;
                        }
                        $scope.localdata.isGetOrderLoading = true;
                        $api.get('purchase-order/' + $stateParams.orderNo + '/' + $scope.currentShopDetail._id + "?shopSlug=" + $scope.currentShopDetail.nameSlug + "&limit=" + limit)
                            .then(function (response) {
                                // console.log('$scope.purchaseOrderItems-----------------------------> ', response);
                                // $scope.purchase_order.items.length = 10;
                                $scope.purchaseOrderItems = $scope.purchase_order.items;

                                $rootScope.creatingItem = false;
                                $scope.callPurchaseOrder = true;
                                $scope.localdata.loading = false;
                                $scope.hasSupplier = true;
                                var purchaseOrder = response.data.data.purchase_order;
                                // purchaseOrder.items.length = 30;

                                getCompanyForEditPage(purchaseOrder.company.nameSlug, $rootScope.currentShop._id);
                                $scope.editData = true
                                if (purchaseOrder.company.logoLight && purchaseOrder.company.logoLight.src) {
                                    $rootScope.companyLogo = $rootScope.awsUrl + purchaseOrder.company.logoLight.src;
                                }
                                var items = $scope.purchaseOrderItems;

                                // Commented this to resolved issue after click on received artilces button not able to see received equal to ordered!!
                                // We might need to revert this if we are facing swipe out issue anywhere.

                                //Need to reconfirm this change!
                                //console.log('purchaseOrder.items[0].priceVat ', purchaseOrder.items[0].priceVat);

                                if (items && purchaseOrder.items && purchaseOrder.items.length && purchaseOrder.items.length > 0) {
                                    for (var i = 0; i < purchaseOrder.items.length; i++) {
                                        if (items[i]) {
                                            purchaseOrder.items[i].discount = items[i].discount;
                                            purchaseOrder.items[i].lastPriceVat = items[i].lastPriceVat;
                                            purchaseOrder.items[i].price = items[i].price;

                                            purchaseOrder.items[i].priceIncVat = parseFloat(parseFloat(items[i].priceIncVat).toFixed(3));
                                            //console.log('items[0].priceVat ', items[0].priceVat);
                                            purchaseOrder.items[i].priceVat = items[i].priceVat;
                                            //console.log('purchaseOrder.items[0].priceVat ', purchaseOrder.items[0].priceVat);
                                            purchaseOrder.items[i].purchasePrice = items[i].purchasePrice;
                                            // purchaseOrder.items[i].receivedQuantity = items[i].receivedQuantity;
                                            // purchaseOrder.items[i].quantity = items[i].quantity;
                                            purchaseOrder.items[i].addQuantity = items[i].addQuantity;
                                            purchaseOrder.items[i].storageFactor = items[i].storageFactor;
                                            purchaseOrder.items[i].addreceivedQuantity = items[i].addreceivedQuantity;
                                        }
                                    }
                                }

                                console.log('purchaseOrder.items[0].priceVat ', purchaseOrder.items[0].priceVat);
                                //$scope.itemHistory = response.data.data.itemHistory;
                                //$scope.withoutItemHistory = response.data.data.withoutItemHistory;

                                //Need to consider this if condition in getPurchaseOrdreTransactionDetails() 

                                // if (purchaseOrder.AutoMaticOrManualOrder === 'Automatically') {
                                // $scope.transactionDetail = response.data.data.transactionDetail;
                                // $scope.originalTransactionDetail = JSON.parse(JSON.stringify($scope.transactionDetail));
                                $scope.isTotalAppliedStockAvailable = $customerInform.isTotalAppliedStockAvailable($scope.originalTransactionDetail);
                                // }
                                //var originalWithoutItemHistory = JSON.parse(JSON.stringify($scope.withoutItemHistory));
                                // $scope.logToDisplay = originalWithoutItemHistory.log.filter(function (el) {
                                //     return el.isFor == "retailerPortal";
                                // });
                                $scope.purchase_order = purchaseOrder;
                                if ($scope.entryMethodCustomerValue === $scope.purchase_order.items[0].entryMethodCustomerValue) {
                                    $scope.entryMethodCustomerValue = $scope.purchase_order.items[0].entryMethodCustomerValue;
                                } else {
                                    $scope.entryMethodCustomerValue = !$scope.purchase_order.items[0].entryMethodCustomerValue;
                                }

                                var purchaseOrderItems = $scope.purchase_order.items;
                                setTheMyStatus();
                                $scope.changeTheStatus();
                                if ($rootScope.isBackOfRecommendation) {
                                    openProcessPurchaseOrderModal();
                                    $rootScope.isBackButtonOfPurchaseOrder = undefined;
                                }

                                if ($scope.purchase_order && $scope.purchase_order.shop && $scope.purchase_order.shop._id && $scope.purchase_order.company && $scope.purchase_order.company._id) {
                                    $scope.getShopClientGroups($scope.purchase_order.shop._id, $scope.purchase_order.company._id); // Getting clien groups of particular shop and company
                                }
                                $scope.purchase_order.lastInvoiceNumber = $scope.purchase_order.invoiceNumber || '';
                                // Updating value of date in view
                                if ($scope.purchase_order.order_date && $scope.purchase_order.delivery_date) {
                                    $scope.order_date = new Date($scope.purchase_order.order_date);
                                    $scope.delivery_date = new Date($scope.purchase_order.delivery_date);
                                }

                                if ($scope.purchase_order.backorder_delivery_date) {
                                    $scope.backorder_delivery_date = new Date($scope.purchase_order.backorder_delivery_date);
                                }

                                if ($scope.purchase_order.companyRemarks && $scope.purchase_order.companyRemarks.delivery_date) {
                                    $scope.purchase_order.companyRemarks.delivery_date = new Date($scope.purchase_order.companyRemarks.delivery_date);
                                }

                                if (($scope.purchase_order.companyRemarks && ($scope.purchase_order.companyRemarks.status == 'Completed' || $scope.purchase_order.companyRemarks.status == 'completed')) || ($scope.purchase_order.status[0].status == 'Cancelled')) {
                                    $scope.canCompanyEdit = false;
                                }

                                checkCalculationIconIsBlackOrGreen();
                                timerForInvoice();

                                // Below for-loop is for checkmark
                                var purchaser_order_length = $scope.purchase_order.items.length;
                                $scope.localdata.isAnyPurchaseOrderItemDeleted = false;
                                for (var i = 0; i < purchaser_order_length; i++) {
                                    var currentPurchasrOrderProduct = $scope.purchase_order.items[i].product_id;
                                    $scope.purchase_order.items[i].priceIncVat = parseFloat(($scope.purchase_order.items[i].price * (1 + ($scope.purchase_order.items[i].priceVat / 100))).toFixed(3)) // To show inc vat in purchase order rows

                                    // CHECKING IF ANY OF ONE ITEM IS DELETED OR NOT
                                    if (!$scope.localdata.isAnyPurchaseOrderItemDeleted && $scope.purchase_order.items[i].isDeleted) {
                                        $scope.localdata.isAnyPurchaseOrderItemDeleted = true;
                                    }

                                    if (currentPurchasrOrderProduct) {
                                        // console.log('currentPurchasrOrderProduct called: ', currentPurchasrOrderProduct);
                                        // If shop.product.show and products.contaisFilterInfo both are false then switch is disabled
                                        // CHECKING THE SWITCH START
                                        $scope.purchase_order.items[i].isSwitchEnable = false;
                                        if (currentPurchasrOrderProduct.containsFilterInfo && currentPurchasrOrderProduct.containsFilterInfo[$scope.language]) {
                                            $scope.purchase_order.items[i].isSwitchEnable = true;
                                        }

                                        // isSwitchEnable is false then we will check in shop.products.show if its true then will make it enable otherwise its false (befor this we check containsFilterInfo)
                                        if (currentPurchasrOrderProduct.shopProduct && currentPurchasrOrderProduct.shopProduct.show) {
                                            $scope.purchase_order.items[i].isSwitchEnable = true;
                                            $scope.purchase_order.items[i].isWebShop = true;
                                        }
                                        // CHECKING THE SWITCH END
                                    }
                                }

                                $scope.purchaseOrderid = $scope.purchase_order._id;
                                if (response.data && response.data.billingDetails && response.data.billingDetails.length > 0) {
                                    $scope.deliveryAddress = response.data.billingDetails[0].address[0];
                                } else if ($scope.purchase_order.billingDetails && $scope.purchase_order.billingDetails.address && $scope.purchase_order.billingDetails.address.length) {
                                    $scope.deliveryAddress = $scope.purchase_order.billingDetails.address[0];
                                } else {
                                    $scope.purchase_order.billingDetails = {
                                        address: [$scope.currentShopDetail.address]
                                    };
                                    $scope.deliveryAddress = $scope.purchase_order.billingDetails.address[0];
                                }
                                $scope.total = response.totalItems;

                                var companyLength = $scope.companies && $scope.companies.length ? $scope.companies.length : 0;
                                $scope.selectedCompany = {}; // If its from the suggestion then we need to show that name instead of purchase order one
                                for (var i = 0; i < companyLength; i++) {
                                    if ($scope.companies[i] && $scope.companies[i]._id == $scope.purchase_order.company._id) {
                                        $scope.selectedCompany = $scope.companies[i];
                                    }
                                }

                                if (!$scope.selectedCompany || !$scope.selectedCompany.name) {
                                    if (checkIsInternalSupplier()) {
                                        $scope.selectInternalSupplier();
                                    }
                                }
                                $scope.orderNumber = purchaseOrder.number;
                                $scope.focusIndex = undefined;
                                $scope.bookAllRecLoading = false;
                                $scope.priceCollLoading = false;
                                if ($scope.itemindex === 0 || $scope.itemindex) $scope.purchase_order.items[$scope.itemindex].loading = false;
                                enableDisableFieldOfInput(false);
                                checkOrderIsCompletedOrNot();
                                checkIsCancelledFlag();

                                $scope.localdata.showCalculationMargin = $scope.purchase_order.items[0].entryMethodCustomerValue;

                                $scope.lastEntryMethodCustomerValue = $scope.localdata.showCalculationMargin;
                                $scope.entryMethodCustomerValue = $scope.localdata.showCalculationMargin;
                                if ($scope.purchase_order.items.length) {
                                    $scope.purchase_order.items[$scope.purchase_order.items.length - 1].isGetOrderLoading = false;
                                }
                            })
                            .catch(function (reason) {
                                console.error(reason)
                                $scope.priceCollLoading = false;
                                $scope.localdata.loading = false;
                                $scope.bookAllRecLoading = false;
                                enableDisableFieldOfInput(false);
                                if ($scope.purchase_order.items.length) {
                                    $scope.purchase_order.items[$scope.purchase_order.items.length - 1].isGetOrderLoading = false;
                                }

                                if (reason == 'No purchase order found with that order no') {
                                    $state.go('retailer.purchase-orders', { company: '' });
                                    $scope.erromessage = 'Oops! We could not find your purchase order on this purchase number';
                                    $scope.alertdanger = true;
                                } else {
                                    console.error(reason.status);
                                }
                            })
                    } else {
                        if ($scope.itemindex === 0 || $scope.itemindex) $scope.purchase_order.items[$scope.itemindex].loading = false;
                        $scope.erromessage = 'Please change the language';
                        $scope.alertdanger = true;
                    }
                }

                // Getting purchaser order(If already there with the purchase order)
                $scope.GetExistingPurchaseOrderItemHistory = function (limit, skip, index) {
                    console.log('-------------- GetExistingPurchaseOrderItemHistory!!');
                    $scope.mouseover = false;
                    // When we have already purchase order
                    var page = $scope.localdata.currentPage + 1;
                    if ($scope.currentShopDetail) {
                        var ms = new Date().getSeconds();
                        $api.get('purchase-order-item-history/' + $stateParams.orderNo + '/' + $scope.currentShopDetail._id + "?shopSlug=" + $scope.currentShopDetail.nameSlug + "&limit=" + limit + "&skip=" + skip)
                            .then(function (response) {

                                $scope.hasSupplier = true;
                                $scope.itemHistory = response.data.data.itemHistory;
                                checkCalculationIconIsBlackOrGreen()
                                // var oldData = $scope.itemHistory;
                                // // var purchaseOrderItems = purchaseOrder.items;
                                // $scope.itemHistory = oldData.concat(response.data.data.itemHistory);
                                //$scope.itemHistory[index] = response.data.data.itemHistory[0];
                            })
                            .catch(function (reason) {
                                if (reason == 'No purchase order found with that order no') {
                                    // $state.go('retailer.purchase-orders', { company: '' });
                                    $scope.erromessage = 'Oops! We could not find your purchase order on this purchase number';
                                    $scope.alertdanger = true;
                                } else {
                                    console.error(reason.status);
                                }
                            })
                    } else {
                        $scope.erromessage = 'Please change the language';
                        $scope.alertdanger = true;
                    }
                }

                $scope.getPurchaseOrdreTransactionDetails = function () {
                    // When we have already purchase order
                    var page = $scope.localdata.currentPage + 1;
                    if ($scope.currentShopDetail) {
                        var ms = new Date().getSeconds();
                        $api.get('purchase-order-transaction-details/' + $stateParams.orderNo + '/' + $scope.currentShopDetail._id + "?shopSlug=" + $scope.currentShopDetail.nameSlug)
                            .then(function (response) {
                                $scope.transactionDetail = response.data.data.transactionDetail;
                                $scope.originalTransactionDetail = JSON.parse(JSON.stringify($scope.transactionDetail));
                                $scope.isTotalAppliedStockAvailable = $customerInform.isTotalAppliedStockAvailable($scope.originalTransactionDetail);
                                $scope.transactionDetailFlag = false;
                            })
                            .catch(function (reason) {
                                if (reason == 'No purchase order found with that order no') {
                                    $state.go('retailer.purchase-orders', { company: '' });
                                    $scope.erromessage = 'Oops! We could not find your purchase order on this purchase number';
                                    $scope.alertdanger = true;
                                } else {
                                    console.error(reason);
                                }
                            })
                    } else {
                        $scope.erromessage = 'Please change the language';
                        $scope.alertdanger = true;
                    }
                }

                $scope.getPurchaseOrdreWithoutItemHistory = function () {
                    var page = $scope.localdata.currentPage + 1;
                    if ($scope.currentShopDetail) {
                        var ms = new Date().getSeconds();

                        $api.get('purchase-order-without-item-history/' + $stateParams.orderNo + '/' + $scope.currentShopDetail._id + "?shopSlug=" + $scope.currentShopDetail.nameSlug)
                            .then(function (response) {
                                $scope.withoutItemHistory = response.data.data.withoutItemHistory;
                                var originalWithoutItemHistory = JSON.parse(JSON.stringify($scope.withoutItemHistory));
                                $scope.logToDisplay = originalWithoutItemHistory.log.filter(function (el) {
                                    return el.isFor == "retailerPortal";
                                });
                                $scope.purchase_order.log = (originalWithoutItemHistory.log && originalWithoutItemHistory.log.length) ? originalWithoutItemHistory.log : [];
                                $scope.withoutItemHistoryFlag = false;
                            })
                            .catch(function (reason) {
                                if (reason == 'No purchase order found with that order no') {
                                    $state.go('retailer.purchase-orders', { company: '' });
                                    $scope.erromessage = 'Oops! We could not find your purchase order on this purchase number';
                                    $scope.alertdanger = true;
                                } else {
                                    console.error(reason.status);
                                }
                            })
                    } else {
                        $scope.erromessage = 'Please change the language';
                        $scope.alertdanger = true;
                    }
                }

                function totalValuesCalculation() {
                    var purchaseOrderItems = $scope.purchase_order.items;
                    var purchaseOrderItemsLength = $scope.purchase_order.items ? $scope.purchase_order.items.length : 0;
                    $scope.purchasePriceExVat = 0;
                    $scope.purchasePriceIncVat = 0;
                    for (var j = 0; j < purchaseOrderItemsLength; j++) {
                        $scope.purchase_order.items[j].priceIncVat = parseFloat(($scope.purchase_order.items[j].price * (1 + ($scope.purchase_order.items[j].priceVat / 100))).toFixed(3)); // To show inc vat in purchase order rows
                        purchaseOrderItems[j].isExpandSerialNumber = false;

                        if (purchaseOrderItems[j].serialNumbers && purchaseOrderItems[j].serialNumbers.length &&
                            purchaseOrderItems[j].serialNumbers[0].serialNumber) {
                            purchaseOrderItems[j].isExpandSerialNumber = true;
                        }

                        if (!purchaseOrderItems[j].isDeleted) {
                            $scope.purchasePriceExVat += purchaseOrderItems[j].purchasePrice * purchaseOrderItems[j].quantity;
                            $scope.purchasePriceIncVat += (purchaseOrderItems[j].purchasePrice * purchaseOrderItems[j].quantity * (((purchaseOrderItems[j].priceVat / 100) + 1)));
                        }
                    }
                    $scope.purchasePriceExVat = $scope.purchasePriceExVat.toFixed(3);
                    $scope.purchasePriceIncVat = $scope.purchasePriceIncVat.toFixed(3);
                }

                function checkCalculationIconIsBlackOrGreen() {
                    // Below if code is for the calculation icon green or black
                    var currentShopProducts = $scope.currentShopDetail.products;
                    var currentShopProductsLength = $scope.currentShopDetail.products ? $scope.currentShopDetail.products.length : 0;
                    var purchaseOrderItems = $scope.purchase_order.items;
                    var purchaseOrderItemsLength = $scope.purchase_order.items ? $scope.purchase_order.items.length : 0;
                    if (purchaseOrderItemsLength) {
                        for (var i = 0; i < purchaseOrderItemsLength; i++) {
                            //console.log('Hey i: ', i, purchaseOrderItems[i]);
                            if (purchaseOrderItems[i].quantity !== purchaseOrderItems[i].receivedQuantity) {
                                // if (purchaseOrderItems[i].number == 'KA1307102') {
                                console.log(i, 'Qnt is not same');
                                // }
                                purchaseOrderItems[i].isGreen = false;
                                continue;
                            }
                            // Here if product is not created then calculation icon should be black and exit the current iteration
                            if (!purchaseOrderItems[i].product_id || purchaseOrderItems[i].isDeleted) {
                                purchaseOrderItems[i].isGreen = false;
                                // if (purchaseOrderItems[i].number == 'KA1307102') {
                                    console.log(i, 'Prod not exist or deleted');
                                // }
                                continue;
                            }

                            if (!purchaseOrderItems[i].product_id.shopProduct) {
                                // if (purchaseOrderItems[i].number == 'KA1307102') {
                                console.log(i, 'Not shop product');
                                // }
                                purchaseOrderItems[i].isGreen = false;
                            } else {

                                if ($scope.purchase_order.purchaserOrderHistory && $scope.purchase_order.purchaserOrderHistory.receivedQuantities.length) {
                                    var receivedQuantitiesIds = purchaseOrderItems[i].receivedQuantitiesIds ? purchaseOrderItems[i].receivedQuantitiesIds : [];

                                    var lastrecievedId = receivedQuantitiesIds.length ? receivedQuantitiesIds[receivedQuantitiesIds.length - 1] : undefined;

                                    var purchaserOrderHistoryReceivedQuantities = $scope.purchase_order.purchaserOrderHistory.receivedQuantities;

                                    for (k = 0; k < purchaserOrderHistoryReceivedQuantities.length; k++) {

                                        if (purchaserOrderHistoryReceivedQuantities[k]._id && lastrecievedId &&
                                            (purchaserOrderHistoryReceivedQuantities[k]._id).toString() == (lastrecievedId).toString()) {

                                            if (purchaserOrderHistoryReceivedQuantities[k].isApplied) {
                                                // if (purchaseOrderItems[i].number == 'KA1307102') {
                                                console.log(i, 'Turning green');
                                                // }
                                                purchaseOrderItems[i].isGreen = true;
                                                break;
                                            } else {
                                                // if (purchaseOrderItems[i].number == 'KA1307102') {
                                                console.log(i, 'Not applied');
                                                // }
                                                purchaseOrderItems[i].isGreen = false;
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        // Its for total Of Ex vat and Inc vat
                        $scope.purchasePriceExVat = 0;
                        $scope.purchasePriceIncVat = 0;
                        $scope.localdata.totalOrderQuantity = 0;
                        for (var j = 0; j < purchaseOrderItemsLength; j++) {
                            // console.log(!$scope.purchase_order.items[j].priceVat);
                            // console.log('$scope.purchase_order.items[j].priceVat ', $scope.purchase_order.items[j].priceVat);
                            // Need to confirm this 
                            // if (!$scope.purchase_order.items[j].priceVat) $scope.purchase_order.items[j].priceVat = 21;
                            // console.log('$scope.purchase_order.items[j].priceVat ', $scope.purchase_order.items[j].priceVat);
                            // console.log('$scope.purchase_order.items[j] ', $scope.purchase_order.items[j]);
                            $scope.purchase_order.items[j].priceIncVat = parseFloat(($scope.purchase_order.items[j].price * (1 + ($scope.purchase_order.items[j].priceVat / 100))).toFixed(3)); // To show inc vat in purchase order rows

                            purchaseOrderItems[j].isExpandSerialNumber = false;

                            if (purchaseOrderItems[j].serialNumbers && purchaseOrderItems[j].serialNumbers.length &&
                                purchaseOrderItems[j].serialNumbers[0].serialNumber) {
                                purchaseOrderItems[j].isExpandSerialNumber = true;
                            }

                            if (!purchaseOrderItems[j].isDeleted) {
                                $scope.purchasePriceExVat += purchaseOrderItems[j].purchasePrice * purchaseOrderItems[j].quantity;
                                $scope.purchasePriceIncVat += (purchaseOrderItems[j].purchasePrice * purchaseOrderItems[j].quantity * (((purchaseOrderItems[j].priceVat / 100) + 1)));
                            }
                            $scope.localdata.totalOrderQuantity = $scope.localdata.totalOrderQuantity + $scope.purchase_order.items[j].quantity;
                        }
                        $scope.purchasePriceExVat = $scope.purchasePriceExVat.toFixed(3);
                        $scope.purchasePriceIncVat = $scope.purchasePriceIncVat.toFixed(3);
                        console.log('$scope.localdata.isEnteringThePage ', $scope.localdata.isEnteringThePage);
                        if (!$scope.localdata.isEnteringThePage) checkAllCalculationIconGreenOrNot();
                        // $scope.localdata.isEnteringThePage = false;
                    }
                }

                function checkAllCalculationIconGreenOrNot() {
                    console.log('------------------- checkAllCalculationIconGreenOrNot!!');
                    var purchaseOrderItems = $scope.purchase_order.items;
                    for (var i = 0; i < purchaseOrderItems.length; i++) {
                        if (purchaseOrderItems[i].isGreen) continue;
                        $scope.isAllCalculationIconGreen = false;
                        break;
                    }
                    var currentStatus = $scope.purchase_order.status[0].status;
                    var purchaseOrderType = $scope.purchase_order.AutoMaticOrManualOrder || 'Manual';
                    $scope.localdata.isEnteringThePage = true;
                    if ($scope.isAllCalculationIconGreen && (currentStatus !== 'Completed') && purchaseOrderType !== 'Automatically') {
                        prompt({
                            title: $language.translate('CONFIRMATION'),
                            message: $language.translate('PUT_IN_COMPLETED_STATUS'),
                            buttons: [{
                                label: $language.translate('OK'),
                                primary: true
                            }, {
                                label: $language.translate('CANCEL'),
                                primary: false,
                                cancel: true
                            }]
                        }).then(function (response) {
                            if (response && response.cancel) return;
                            $scope.purchase_order.status[0].status = 'Completed';
                            $scope.changeTheStatus(true);
                        }).catch(function (error) {
                            console.error('error: inprompnt: ', error);
                        });
                    }
                }

                function checkIsCancelledFlag() {
                    var purchaseOrderItems = $scope.purchase_order.items;
                    for (var i = 0; i < purchaseOrderItems.length; i++) {
                        if (purchaseOrderItems[i].quantity != 0 || purchaseOrderItems[i].receivedQuantity != 0) {
                            $scope.purchase_order.isCancelled = false;
                            continue;
                        } else { $scope.purchase_order.isCancelled = true; }
                    }
                }

                function checkOrderIsCompletedOrNot() {
                    var items = $scope.purchase_order.items;
                    // If all Backorder is zero then and only it will completed status of order and Backorder status should be disabled
                    for (var i = 0; i < items.length; i++) {

                        if ($scope.purchase_order.items[i].canBeStockUp != false && !$scope.purchase_order.items[i].isDeleted && (($scope.purchase_order.items[i].quantity) - ($scope.purchase_order.items[i].receivedQuantity) != 0 || !$scope.purchase_order.items[i].isGreen)) {
                            $scope.purchase_order.isNotCompleted = true;
                            break;
                        } else {
                            if (i == items.length - 1) {
                                $scope.purchase_order.isNotCompleted = false;
                            }
                        }
                    }
                }

                // Deprecated
                $scope.updateThePurchaseOrder = function (purchase_order) {
                    if ($scope.selectedCompany && $scope.purchase_order.items.length) {
                        purchase_order.isFirstPurchaseOrder = $scope.currentShopDetail.isFirstPurchaseOrder;
                        $api.put('purchase-orders/' + $scope.currentShopDetail.nameSlug, purchase_order)
                            .then(function (response) {
                                $scope.localdata.loading = false;
                                $scope.cancelItem(); // For removing input field
                                $scope.GetExistingPurchaseOrder($scope.limit); // For getiing private label and all the things
                            })
                            .catch(function (reason) {
                                $scope.localdata.loading = false;
                                console.error("Error when saving the order", reason);
                                $scope.isErrorOccured = false;
                                $scope.errorMsg = (reason.data.message ? reason.data.message : 'Something went wrong, Please try again later');
                                $scope.alert = {
                                    type: 'danger',
                                    msg: reason.data.message
                                }
                            })
                    } else {
                        $scope.isErrorOccured = true;
                        if (!$scope.selectedCompany) $scope.errorMsg = 'Select the company first';
                        if ($scope.purchase_order.status[0].status == 'Completed') $scope.errorMsg = 'You can update the purchase order when your status is completed';
                        if (!$scope.purchase_order.items.length) $scope.errorMsg = 'You need to add product first';
                    }
                }

                $scope.OpenPurchaseOrderModal = function () {
                    var modalInstance = $uibModal.open({
                        templateUrl: '../views/modal/add-new-purchase-order-modal.html',
                        controller: 'addNewPurchaseOrderModalController',
                        size: 'lg',
                    });

                    modalInstance.result.then(function (result) {
                        if (result && result.status === 'create') {
                            console.log('As before create new purchase order');
                        } else if (result && result.status == 'already_received') {
                            $scope.mystatus = 3;
                            $scope.purchase_order.status[0].status = 'Inspection';
                            console.log('Change status to Inspection');
                        } else if (result && result.status == 'upload_EDI') {
                            console.log('Upload EDI FILE');
                        } else {
                            console.log('something went wrong');
                        }
                    }, function (err) {
                        console.error('error: ', err);
                    })
                }

                /**
                 * @param isPurchaseOrderNew: when you clicked new purchase order at time we set it in rootscope to true
                 */
                if ($rootScope.isPurchaseOrderNew) {
                    $scope.hasSupplier = false;
                    $scope.changeTheStatus();
                    $scope.OpenPurchaseOrderModal();
                }

                // Using this function we can update any date so just need to pass and key to update and its value and its type as well for retailer or company
                $scope.updatePurchaseOrderDate = function (date, key) {
                    console.log('date: ', date);
                    var body = {
                        purchaseOrderId: $scope.purchase_order._id,
                        type: 'retailerdate',
                        key: key,
                        value: date,
                        shopId: $rootScope.currentShop._id
                    }
                    $scope.localdata.loading = true;
                    $purchaseOrder.updatePurchaseOrderDate(body).then(function (response) {
                        $scope.localdata.loading = false;
                    }).catch(function (error) {
                        $scope.localdata.loading = false;
                        console.error('something went wrong while updateing  date: ', error);
                    });
                }

                function fetchCommonBrand() {
                    var req = {
                        companyNameSlug: $scope.selectedCompany.nameSlug,
                        shopNameSlug: $scope.currentShopDetail.nameSlug
                    }
                    $scope.localdata.isCommonBrandRec = true;
                    $api.put('common-brands-search/', req).then(function (response) {
                        $scope.localdata.isCommonBrandRec = true;
                        // console.log('CommonBrand Detail: ----------------------------------> ', response.data);
                        if (response && response.data) {
                            $scope.BrandIdsList = response.data.brandId
                            $scope.companyBrandsDetail = response.data.companyBrands
                            $scope.shopBrandsDetail = response.data.shopBrands
                            // console.log('companyBrandsDetail called: ', $scope.BrandIdsList);
                            for (var k = 0; k < $scope.companyBrandsDetail.length; k++) {
                                for (var j = 0; j < $scope.BrandIdsList.length; j++) {
                                    if ($scope.BrandIdsList[j] == $scope.companyBrandsDetail[k]._id) {
                                        $scope.companyBrandsDetail[k]["commonBrand"] = true
                                    }
                                }
                            }
                        }
                        $scope.BrandIdsList = 'Common Brands not present'
                        if ($scope.BrandIdsList.length > 0) {
                            $scope.showBrands = true
                        } else {
                            $scope.showBrands = false
                        }
                    }).catch(function (reason) {
                        $scope.localdata.isCommonBrandRec = true;
                        console.error('Went wrong while fetching the brands');
                    });
                }

                // SocketService.on('brand_added_retailer_shop', function (data) {
                //     console.log('Hey brand_added_retailer_shop?', data);
                //     if (data && data.shopId && $scope.purchase_order && $scope.purchase_order.shop && $scope.purchase_order.shop._id &&
                //         $scope.purchase_order.shop._id == data.shopId) {
                //         fetchCommonBrand();
                //     }
                // });

                // code for autocomplete
                $scope.companySelected = function (selected) {
                    var companyObject = selected.originalObject; // because in api we may have any thing of company as input
                    var validCompanyDetail = $purchaseOrder.isValidCompanyDetail(companyObject);
                    if (validCompanyDetail.isValidCompany) {
                        $scope.companyBrands = []; // we are showing brands based on company
                        $scope.showCompanyBrand = false;
                        $scope.clickedBrand = undefined;
                        $scope.isBrandClicked = false;
                        $scope.invalidSupplier = false;
                        $scope.selectedCompany = companyObject;
                        $scope.purchase_order.sendDetails = {
                            address: $scope.selectedCompany.address,
                            firstName: $scope.selectedCompany.name,
                            phone: $scope.selectedCompany.phone,
                            email: $scope.selectedCompany.email
                        }

                        var req = {
                            companyNameSlug: $scope.selectedCompany.nameSlug,
                            shopNameSlug: $scope.currentShopDetail.nameSlug
                        }
                        $scope.editData = false

                        fetchCommonBrand(req);
                        if ($scope.purchase_order.sendDetails && $scope.purchase_order.sendDetails.phone) {
                            if ($scope.purchase_order.sendDetails.phone.length < 8 || $scope.purchase_order.sendDetails.phone.length > 12) {
                                $scope.purchase_order.sendDetails.mobilePhone = ""
                            }
                        }

                        $scope.hasSupplier = true;
                        if ($scope.purchase_order && $scope.purchase_order.shop && $scope.purchase_order.shop._id && companyObject && companyObject._id) {
                            $scope.getShopClientGroups($scope.purchase_order.shop._id, companyObject._id); // Getting clien groups of particular shop and company
                        }
                        getCompanyForEditPage($scope.selectedCompany.nameSlug, $rootScope.currentShop._id);
                    } else if (companyObject && companyObject.nameSlug) {
                        $scope.invalidSupplierNameSlug = companyObject.nameSlug;
                        $scope.invalidSupplier = true;
                        $scope.invalidSupplierMessage = validCompanyDetail.message;
                    } else {
                        $scope.invalidSupplier = true;
                        $scope.invalidSupplierMessage = validCompanyDetail.message;
                    }
                };


                function getCompanyForEditPage(nameSlug, shopId) {

                    $shop.getCompanyForEditPage(nameSlug, shopId)
                        .then(function (company) {

                            if (company && company.company && company.company.logoLight && company.company.logoLight.src) {
                                $rootScope.companyLogo = $rootScope.awsUrl + company.company.logoLight.src;
                            }
                            $rootScope.currentCompany = company.company
                            $rootScope.currentCompany['hideSupplierSelection'] = true;
                            $scope.isCompanyActiveFromLastWeek = company.isCompanyActiveFromLastWeek !== false
                            $scope.company = company.company

                            var shop_company = company.shopCompany
                            // getServicesOfRetailerAndCompany()

                            if (shop_company.discount) $scope.localdata.discount = shop_company.discount;
                            if (shop_company.shopStatisticsNotification) $scope.localdata.shopStatisticsNotification = shop_company.shopStatisticsNotification;
                            if (shop_company.canCompanySeeShopStatistics) $scope.localdata.canCompanySeeShopStatistics = shop_company.canCompanySeeShopStatistics
                            if (shop_company.entryMethodCustomerValue !== undefined) $scope.localdata.entryMethodCustomerValue = shop_company.entryMethodCustomerValue
                            if (shop_company.purchaseMargin) $scope.localdata.margin = shop_company.purchaseMargin
                            if (shop_company.storageFactor) $scope.localdata.storageFactor = shop_company.storageFactor
                            if (shop_company.tenureTime) {
                                $scope.localdata.tenureTime = shop_company.tenureTime
                                $scope.localdata.lastTenureTime = $scope.localdata.tenureTime // For updating in last automatic purchase order
                            }
                            // $scope.localdata.isAutoOrderEnable = false;
                            if (shop_company.isAutoOrderEnable) $scope.localdata.isAutoOrderEnable = shop_company.isAutoOrderEnable
                            if (shop_company.start_date) $scope.localdata.start_date = new Date(shop_company.start_date)
                            if (shop_company.prefillCompanySettings === false) {
                                $scope.localdata.prefillCompanySettings = false
                            } else {
                                $scope.localdata.prefillCompanySettings = true
                            }
                            var comment = ''
                            if (shop_company.retailerComments && shop_company.retailerComments.length) comment = shop_company.retailerComments[0].comment
                            if ($scope.company.phone && $scope.company.phone.countryCode) $scope.localdata.phoneCountryCode = $scope.company.phone.countryCode
                            if ($scope.company.repairPhone && $scope.company.repairPhone.countryCode) $scope.localdata.repairPhoneCountryCode = $scope.company.repairPhone.countryCode
                            $scope.localdata.mycomment = comment
                        })
                        .catch(function (error) {
                            console.error('Error ! While fetch the company based on nameSlug', error)
                        })
                }

                $scope.changeShopStorageFactor = function () {
                    $scope.localdata.margin = parseFloat(($scope.localdata.storageFactor * 1.21).toFixed(3));
                }

                $scope.changeShopMargin = function () {
                    $scope.localdata.storageFactor = parseFloat(($scope.localdata.margin / 1.21).toFixed(3));
                }

                $scope.addShopCompanySetting = function () {
                    $scope.settingsSaved = false
                    var body = {
                        shopId: $rootScope.currentShop._id,
                        companyid: $scope.company._id,

                        entry_method: $scope.localdata.entryMethodCustomerValue,
                        entryMethodCustomerValue: $scope.localdata.entryMethodCustomerValue,
                        storage_factor: $scope.localdata.storageFactor,
                        purchase_margin: $scope.localdata.margin,
                        standard_discount: $scope.localdata.discount,
                        tenureTime: $scope.localdata.tenureTime,
                        start_date: $scope.localdata.start_date,
                        isAutoOrderEnable: $scope.localdata.isAutoOrderEnable,
                        prefillCompanySettings: $scope.localdata.prefillCompanySettings,
                        canCompanySeeShopStatistics: $scope.localdata.canCompanySeeShopStatistics,
                        isTenureTimeChanged: ($scope.localdata.lastTenureTime !== $scope.localdata.tenureTime) // For updating in last automatic purchase order
                    }

                    $api.post('shop-add-company-settings', body)
                        .then(function (response) {
                            $scope.localdata.lastTenureTime = $scope.localdata.tenureTime
                            $scope.settingsSaved = true
                        })
                        .catch(function (reason) {
                            console.error('Error while saving settings', reason)
                        })
                }

                $scope.AddComment = function (comment) {
                    if ($scope.purchase_order && $scope.purchase_order.items && $scope.purchase_order.items.length && comment) {
                        var body = {
                            purchaseOrderId: $scope.purchase_order._id,
                            shop: $scope.purchase_order.shop,
                            company: $scope.purchase_order.company,
                            purchaseOrderNumber: $scope.purchase_order.number,
                            remarks: {
                                eType: 'retailer',
                                remark: comment,
                                companyId: $scope.purchase_order.company._id
                            }
                        }
                        $purchaseOrder.addComment(body).then(function (response) {
                            $('#currentremark').val('');
                            $scope.GetExistingPurchaseOrder($scope.limit);
                        }).catch(function (err) {
                            $('#currentremark').val('');
                            console.error(err);
                        });
                    }
                }

                $scope.EditCommentMode = function (remark, index) {
                    for (var i = 0; i < $scope.withoutItemHistory.remarks.remarks.length; i++) {
                        $scope.withoutItemHistory.remarks.remarks[i].isEditMode = false;
                    }
                    $scope.withoutItemHistory.remarks.remarks[index].isEditMode = true;
                    $scope.editRemarkDetail = remark;
                }

                $scope.UpdateComment = function (comment, index) {
                    if (comment) {
                        $scope.editRemarkDetail.remark = comment;
                        $scope.withoutItemHistory.remarks.remarks[index].isEditMode = false;
                        var body = {
                            purchaseOrderId: $scope.purchase_order._id,
                            commentId: $scope.editRemarkDetail._id,
                            remark: comment,
                            shopId: $rootScope.currentShop._id
                        }

                        $api.post('edit-comment-retailer-and-company', body)
                            .then(function (response) {
                                $scope.editRemarkDetail = '';
                                $scope.GetExistingPurchaseOrder($scope.limit);
                            })
                            .catch(function (err) {
                                $scope.editRemarkDetail = '';
                                console.error(err);
                            });
                    } else {
                        $scope.editRemarkDetail = '';
                        $scope.withoutItemHistory.remarks.remarks[index].isEditMode = false;
                    }
                }

                // Opening modal to search the supplier
                $scope.editCompany = function () {
                    $purchaseOrder.openSearchSupplierModal($scope.companies).then(function (selectedCompany) {
                        var companyObject = selectedCompany; // because in api we may have any thing of company as input
                        var validCompanyDetail = $purchaseOrder.isValidCompanyDetail(companyObject);

                        if (validCompanyDetail.isValidCompany) {
                            $scope.invalidSupplier = false;
                            $scope.selectedCompany = companyObject;
                            $scope.purchase_order.sendDetails = {
                                address: $scope.selectedCompany.address,
                                firstName: $scope.selectedCompany.name,
                                phone: $scope.selectedCompany.phone,
                                email: $scope.selectedCompany.email
                            }

                            if ($scope.purchase_order.sendDetails && $scope.purchase_order.sendDetails.phone) {
                                if ($scope.purchase_order.sendDetails.phone.length < 8 || $scope.purchase_order.sendDetails.phone.length > 12) {
                                    $scope.purchase_order.sendDetails.mobilePhone = ""
                                }
                            }

                            $scope.hasSupplier = true;
                            // calculateWithShopCompanies($scope.selectedCompany); // DEPRECATED // For Fetching the Entry customer value of the current selected company
                            if ($scope.purchase_order && $scope.purchase_order.items && $scope.purchase_order.items.length) {
                                $scope.purchase_order.company.name = $scope.selectedCompany.name;
                                $scope.purchase_order.company.nameSlug = $scope.selectedCompany.nameSlug;
                                $scope.purchase_order.company._id = $scope.selectedCompany._id;

                                var body = {
                                    purchaseOrderId: $scope.purchase_order._id,
                                    type: 'sendDetailsAndCompany',
                                    key: 'sendDetails',
                                    value: {
                                        sendDetails: $scope.purchase_order.sendDetails,
                                        company: {
                                            _id: $scope.selectedCompany._id,
                                            nameSlug: $scope.selectedCompany.nameSlug,
                                            name: $scope.selectedCompany.name
                                        }
                                    },
                                    shopId: $rootScope.currentShop._id
                                }
                                $purchaseOrder.updatePurchaseOrderByKey(body).then(function (addresres) {
                                }).catch(function (error) {
                                    console.error('updatePurchaseOrderByKey address error: ', error);
                                });
                            }
                        } else {
                            $scope.invalidSupplierNameSlug = selectedCompany.nameSlug;
                            $scope.invalidSupplier = true;
                            $scope.invalidSupplierMessage = validCompanyDetail.message;
                        }
                    }).catch(function (error) {
                        console.error('edit company modal error: ', error);
                    });
                }

                $scope.searchCompanies = function (str) {
                    var matches = [];
                    $scope.companies.forEach(function (item) {
                        if (item && ((item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                            (item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0))) {
                            matches.push(item);
                        }
                    });
                    return matches;
                };

                $scope.showPurchaseOrderSupplier = function () {
                    if (checkIsInternalSupplier()) {
                        $scope.selectInternalSupplier();
                    } else if ($scope.companies.length && $scope.purchase_order.company) {
                        for (var i = 0; i < $scope.companies.length; i++) {
                            if ($scope.companies[i] && (($scope.companies[i].name == $scope.purchase_order.company.name) ||
                                ($scope.companies[i]._id && $scope.purchase_order.company._id && ($scope.companies[i]._id).toString() == ($scope.purchase_order.company._id).toString()))) {
                                $scope.selectedCompany = $scope.companies[i];
                            }
                        }
                    }
                }

                function checkIsInternalSupplier() {
                    var isInternalSupplier = false;
                    if ($scope.purchase_order && $scope.purchase_order.company && $scope.purchase_order.company.name) {
                        var companyname = $scope.purchase_order.company.name.split(' ').slice(-2).join('');
                        if (companyname && companyname === 'internalsupplier') {
                            isInternalSupplier = true;
                        }
                    }
                    return isInternalSupplier;
                }

                $scope.getShopCompanies = function () {
                    $scope.companies = [];
                    if ($scope.currentShopDetail) {
                        // $scope.localdata.loading = true;
                        $api.get('shop-companies-by-latestsuggestion', {
                            shopId: $scope.currentShopDetail._id
                        }).then(function (response) {
                            if (response.status !== 204 && response.data.data.length) { // 204 means company not found
                                $scope.companies = response.data.data;
                            }
                            $scope.deliveryAddress = ($scope.currentShopDetail.address ? $scope.currentShopDetail.address : {});
                            if (!$rootScope.isPurchaseOrderNew) $scope.showPurchaseOrderSupplier(); // If its from the suggestion then we need to show it
                        }).catch(function (reason) {
                            $scope.erromessage = 'Error while getting companies';
                            $scope.alertdanger = true;
                        })
                    } else {
                        $scope.erromessage = 'Please change the language';
                        $scope.alertdanger = true;
                    }
                }

                function getPurchaseOrderDetail(purchaseOrderItem, item) {
                    var purchaseOrder = {
                        purchaseOrderNumber: $stateParams.orderNo,
                        createdAt: new Date(),
                        PurchaseOrderItem: purchaseOrderItem,
                        currentShop: {
                            _id: $scope.currentShopDetail._id,
                            name: $scope.currentShopDetail.name,
                            nameSlug: $scope.currentShopDetail.nameSlug
                        },
                        company: {
                            _id: $scope.selectedCompany._id,
                            name: $scope.selectedCompany.name,
                            nameSlug: $scope.selectedCompany.nameSlug
                        },
                        order_date: $scope.order_date,
                        delivery_date: $scope.delivery_date,
                        status: $scope.purchase_order.status,
                        comment: $scope.purchase_order.comment,
                        isFirstPurchaseOrder: $scope.currentShopDetail.isFirstPurchaseOrder,
                        log: {
                            text: "Purchase order created",
                            userId: $rootScope.user._id,
                            eType: 'retailer',
                            isFor: 'retailerPortal',
                            createdAt: new Date()
                        }
                    }

                    if (purchaseOrder.status[0].status == 'Inspection' || purchaseOrder.status[0].status == 'Backorder') {
                        purchaseOrder.PurchaseOrderItem.ProdChangesWhenInspectionActive.isProductChanged = true;
                        purchaseOrder.PurchaseOrderItem.ProdChangesWhenInspectionActive.updatedQuantity = item.quantity;
                        purchaseOrder.PurchaseOrderItem.ProdChangesWhenInspectionActive.updatedAt = new Date();
                    }

                    purchaseOrder.billingDetails = {};
                    purchaseOrder.sendDetails = {};

                    if (JSON.stringify($scope.deliveryAddress) != JSON.stringify({})) {
                        purchaseOrder.billingDetails.address = $scope.deliveryAddress;
                    } else { // we will have current shop address in the delivery address
                        purchaseOrder.billingDetails.address = $scope.currentShopDetail.address;
                    }

                    purchaseOrder.billingDetails.firstName = $scope.currentShopDetail.name;
                    purchaseOrder.billingDetails.email = $scope.currentShopDetail.email;

                    purchaseOrder.sendDetails.address = $scope.selectedCompany.address;
                    purchaseOrder.sendDetails.firstName = $scope.selectedCompany.name;
                    purchaseOrder.sendDetails.phone = $scope.selectedCompany.phone;

                    if (purchaseOrder.sendDetails && purchaseOrder.sendDetails.phone) {
                        if (purchaseOrder.sendDetails.phone.length < 8 || purchaseOrder.sendDetails.phone.length > 12) {
                            purchaseOrder.sendDetails.mobilePhone = ""
                        }
                    }
                    purchaseOrder.sendDetails.email = $scope.selectedCompany.email; // default email address

                    return purchaseOrder;
                }

                function getPurchaseOrderItem(item) {
                    var itemname = item.name;
                    if (item.comment) {
                        itemname = item.comment;
                    }

                    var itemnumber = $scope.localdata.search_value;
                    if ($scope.localdata.productNumber) itemnumber = $scope.localdata.productNumber;
                    if (item.number) {
                        itemnumber = item.number;
                    }
                    if (!$scope.localdata.ean || !$purchaseOrder.isLikeEANNumber($scope.localdata.ean)) {
                        $scope.localdata.ean = '';
                    }
                    var purchaseOrderItem = {
                        price: $scope.prefillPurchaseOrderItemData.price || 0,
                        purchasePrice: $scope.prefillPurchaseOrderItemData.purchasePrice || 0,
                        purchasePriceCompany: ($scope.prefillPurchaseOrderItemData && $scope.prefillPurchaseOrderItemData.companyProduct && $scope.prefillPurchaseOrderItemData.companyProduct.purchasePrice) ? $scope.prefillPurchaseOrderItemData.companyProduct.purchasePrice : 0,
                        entryMethodCustomerValue: $scope.prefillPurchaseOrderItemData.entryMethodCustomerValue,
                        storageFactor: $scope.prefillPurchaseOrderItemData.storageFactor || 0,
                        priceIncVat: $scope.prefillPurchaseOrderItemData.priceIncVat,

                        product_id: item.product_id,
                        quantity: item.quantity,
                        stock: 0,
                        receivedQuantity: 0,
                        name: itemname,
                        number: itemnumber,
                        ean: $scope.localdata.ean ? $scope.localdata.ean : undefined,
                        discount: { value: $scope.prefillPurchaseOrderItemData.margin || 2 },
                        ProdChangesWhenInspectionActive: {},
                        priceVat: $scope.firstPriceVat || 21,
                        
                        createdAt: new Date(),
                        prefillCollectionName: item.collectionString || ''
                    }
                    $scope.localdata.ean = '';
                    return purchaseOrderItem;
                }

                function isCurrentShopInValid() {
                    if (!$scope.currentShopDetail.address || !$scope.currentShopDetail.address.street || !$scope.currentShopDetail.address.houseNumber || !$scope.currentShopDetail.address.city ||
                        !$scope.currentShopDetail.address.country || !$scope.currentShopDetail.address.postalCode) {
                        $scope.invalidShop = true;
                        $scope.invalidShopMessage = 'Shop address is not proper. First fill it proper then you can edit it';
                        return;
                    } else {
                        $scope.invalidShop = false;
                    }

                    return $scope.invalidShop;
                }

                function createNewPurchaseOrder(item) {
                    var purchaseOrderItem = getPurchaseOrderItem(item);
                    if ($scope.unofficialProduct) {
                        purchaseOrderItem.unofficialProduct = {
                            brand: {
                                _id: $scope.unofficialProduct.brand && $scope.unofficialProduct.brand._id ? $scope.unofficialProduct.brand._id : undefined,
                                appliedStartingDigits: $scope.unofficialProduct.brand && $scope.unofficialProduct.brand.startingDigits ? $scope.unofficialProduct.brand.startingDigits : '',
                            },
                            shopAppliedStartingDigits: $scope.unofficialProduct.shopAppliedStartingDigits || '',
                            insertedProductNumber: $scope.unofficialProduct.insertedProductNumber
                        }
                        purchaseOrderItem.number = $scope.unofficialProduct.productNumber;
                    }
                    if (isCurrentShopInValid()) return; // If invalid the purchase order can't be created
                    var purchaseOrder = getPurchaseOrderDetail(purchaseOrderItem, item);
                    $scope.alert = null;
                    $scope.localdata.loading = true;
                    $api.post('purchase-orders/' + $scope.currentShopDetail.nameSlug, purchaseOrder)
                        .then(function (response) {
                            $scope.localdata.loading = false;
                            $rootScope.isPurchaseOrderNew = false;
                            var newPurcahseOrderNo = response.data.newPurchaseOrder.number;
                            $scope.cancelItem(); // For removing input field
                            if ($stateParams.orderNo != newPurcahseOrderNo) { // Sometimes mutiple user accessing the portal at time they have same orderno so we are checking at backend and changed if needed
                                $state.go('retailer.purchase-order', {
                                    orderNo: newPurcahseOrderNo
                                });
                            } else {
                                $scope.GetExistingPurchaseOrder($scope.limit, true);
                                $scope.purchase_order = response.data.newPurchaseOrder;
                                // $scope.entryMethodCustomerValue = $scope.purchase_order.items[$scope.purchase_order.items.length - 1].entryMethodCustomerValue;
                                $scope.purchaseOrderid = $scope.purchase_order._id;
                            }
                        }).catch(function (reason) {
                            $scope.localdata.loading = false;
                            console.error("Error when saving the order", reason);
                            $scope.isErrorOccured = true;
                            $scope.errorMsg = reason.data.message;
                            $scope.alert = {
                                type: 'danger',
                                msg: reason.data.message
                            }
                        })
                }

                function addProducctToExistingPurchaseOrder(item) {
                    var purchaseOrderItem = getPurchaseOrderItem(item);
                    if ($scope.unofficialProduct) {
                        purchaseOrderItem.unofficialProduct = {
                            brand: {
                                _id: $scope.unofficialProduct.brand && $scope.unofficialProduct.brand._id ? $scope.unofficialProduct.brand._id : undefined,
                                appliedStartingDigits: $scope.unofficialProduct.brand && $scope.unofficialProduct.brand.startingDigits ? $scope.unofficialProduct.brand.startingDigits : '',
                            },
                            shopAppliedStartingDigits: $scope.unofficialProduct.shopAppliedStartingDigits || '',
                            insertedProductNumber: $scope.unofficialProduct.insertedProductNumber
                        }
                        purchaseOrderItem.number = $scope.unofficialProduct.productNumber;
                    }

                    // Checking if product is already exist or not, if yes then increased quantity of the existing purchase order quantity
                    var isProudctAlreadyExist = false;
                    var indexOfAlreadyAddedProduct = 0;
                    for (var i = 0; i < $scope.purchase_order.items.length; i++) {
                        if (item.product_id) {
                            if ($scope.purchase_order.items[i].product_id && $scope.purchase_order.items[i].product_id._id &&
                                ($scope.purchase_order.items[i].product_id._id).toString() === (item.product_id).toString()) {
                                isProudctAlreadyExist = true;
                                indexOfAlreadyAddedProduct = i;
                                break;
                            }
                        }

                        if ($scope.purchase_order.items[i].number == purchaseOrderItem.number) {
                            isProudctAlreadyExist = true;
                            indexOfAlreadyAddedProduct = i;
                            break;
                        }

                        // Removed EAN number, if already there a EAN NUMBER EXIST
                        if (purchaseOrderItem.ean && $scope.purchase_order.items[i].ean && $scope.purchase_order.items[i].ean == purchaseOrderItem.ean) purchaseOrderItem.ean = '';
                    }

                    if (isProudctAlreadyExist) {
                        var updbody = {
                            _id: $scope.purchase_order._id,
                            item: {
                                _id: $scope.purchase_order.items[indexOfAlreadyAddedProduct]._id,
                                addQuantity: purchaseOrderItem.quantity,
                                quantity: $scope.purchase_order.items[indexOfAlreadyAddedProduct].quantity || 0
                            },
                            shopId: $rootScope.currentShop._id,
                            status: $scope.purchase_order.status[0].status,
                        }
                        var tempIndex = 0;
                        for (var i = 0; i < $scope.purchaseOrderItems.length; i++) {
                            console.log('---------- inside loop!!');
                            if ($scope.purchaseOrderItems[i]._id == updbody.item._id) {
                                increasedTheOrderQuantity(updbody, i);
                                // $scope.calculationsOfOrdered(i);
                            }
                        }


                        return;
                        // $scope.errorMsg = 'This product is already on your purchase order. Please use the ‘ordered’ quanitity field to add a quantity';
                        // $scope.isErrorOccured = true;
                        // return;
                    } else {
                        // console.log('NOT ALREAYD EXIST its');
                        if ($scope.purchase_order.status[0].status == 'Inspection' || $scope.purchase_order.status[0].status == 'Backorder') {
                            purchaseOrderItem.ProdChangesWhenInspectionActive.isProductChanged = true;
                            purchaseOrderItem.ProdChangesWhenInspectionActive.updatedQuantity = item.quantity;
                            purchaseOrderItem.ProdChangesWhenInspectionActive.updatedAt = new Date();
                        }
                        $scope.purchase_order.isAddingNewProduct = true;
                        $scope.purchase_order.isCalculationUpdation = true;
                        purchaseOrderItem.createdAt = new Date();
                        $scope.purchase_order.items.push(purchaseOrderItem);
                    }

                    var addBody = {
                        _id: $scope.purchase_order._id,
                        item: purchaseOrderItem,
                        status: $scope.purchase_order.status[0].status,
                        shop: $scope.purchase_order.shop,
                        company: $scope.purchase_order.company,
                        date: $scope.purchase_order.date
                    }
                    $scope.localdata.loading = true;
                    // var index = 0;
                    // if($scope.purchase_order.items){ index = $scope.purchase_order.items.length - 1; }

                    $purchaseOrder.addProducctToExistingPurchaseOrder(addBody).then(function (updres) {
                        $scope.localdata.loading = false;
                        $scope.cancelItem(); // For removing input field
                        //$scope.GetSingleProductFromPurchaseOrder(purchaseOrderItem.product_id, index);
                        $scope.GetExistingPurchaseOrder($scope.limit, true); // For getiing private label and all the things
                    }).catch(function (upderr) {
                        $scope.localdata.loading = false;
                        console.error('Error while updating product: ', upderr.status);
                    });
                }

                function updateExistingProductInPurchaseOrder(item) {
                    var purchaseOrderItem = getPurchaseOrderItem(item);
                    console.log('*************************************************************$scope.index: ', $scope.offerIndex);
                    console.log('item: ', item);
                    console.log('$scope.purchase_order.items[$scope.offerIndex]: ', JSON.parse(JSON.stringify($scope.purchase_order.items[$scope.offerIndex])));

                    var isProductChangedInPurchaseOrder = false;
                    var newProductNumber;
                    var oldProductNumber;
                    if ($scope.purchase_order.items[$scope.offerIndex].product_id && $scope.purchase_order.items[$scope.offerIndex].product_id._id && item.product_id &&
                        ($scope.purchase_order.items[$scope.offerIndex].product_id._id).toString() !== (item.product_id).toString()) {
                        isProductChangedInPurchaseOrder = true;
                        newProductNumber = item.number;
                        oldProductNumber = $scope.purchase_order.items[$scope.offerIndex].number;
                        // console.log('BOTH ID AVAILABLE AND PRODUCT CHANGED: ', isProductChangedInPurchaseOrder, newProductNumber, oldProductNumber);
                    } else if ($scope.purchase_order.items[$scope.offerIndex].product_id && !item.product_id) {
                        isProductChangedInPurchaseOrder = true;
                        newProductNumber = $scope.localdata.search_value;
                        oldProductNumber = $scope.purchase_order.items[$scope.offerIndex].number;
                        // console.log('OLD ID THERE BUT NOT NEW ID: ', isProductChangedInPurchaseOrder, newProductNumber, oldProductNumber);
                    } else if (!$scope.purchase_order.items[$scope.offerIndex].product_id && item.product_id) {
                        isProductChangedInPurchaseOrder = true;
                        newProductNumber = item.number;
                        oldProductNumber = $scope.purchase_order.items[$scope.offerIndex].number;
                        // console.log('NOT OLD ID THERE BUT NEW ID: ', isProductChangedInPurchaseOrder, newProductNumber, oldProductNumber);
                    }

                    if (item.product_id && item.number && item.comment) {
                        purchaseOrderItem.product_id = item.product_id;
                        purchaseOrderItem.number = item.number;
                        purchaseOrderItem.name = item.comment;
                    }

                    purchaseOrderItem._id = $scope.purchase_order.items[$scope.offerIndex]._id;
                    purchaseOrderItem.quantitiesIds = $scope.purchase_order.items[$scope.offerIndex].quantitiesIds;
                    purchaseOrderItem.receivedQuantitiesIds = $scope.purchase_order.items[$scope.offerIndex].receivedQuantitiesIds;
                    purchaseOrderItem.receivedQuantity = $scope.purchase_order.items[$scope.offerIndex].receivedQuantity;
                    purchaseOrderItem.prefillCollectionName = $scope.purchase_order.items[$scope.offerIndex].prefillCollectionName;
                    purchaseOrderItem.quantity = item.quantity;
                    if (!purchaseOrderItem.product_id && $scope.purchase_order.items[$scope.offerIndex].product_id) {
                        purchaseOrderItem.quantity = $scope.purchase_order.items[$scope.offerIndex].quantity;
                        purchaseOrderItem.price = $scope.purchase_order.items[$scope.offerIndex].price;
                        purchaseOrderItem.purchasePrice = $scope.purchase_order.items[$scope.offerIndex].purchasePrice;
                        purchaseOrderItem.product_id = $scope.purchase_order.items[$scope.offerIndex].product_id._id;
                        purchaseOrderItem.stock = $scope.purchase_order.items[$scope.offerIndex].stock;
                        purchaseOrderItem.receivedQuantity = $scope.purchase_order.items[$scope.offerIndex].receivedQuantity;
                        purchaseOrderItem.number = $scope.purchase_order.items[$scope.offerIndex].number;
                        purchaseOrderItem.quantitiesIds = $scope.purchase_order.items[$scope.offerIndex].quantitiesIds;
                        purchaseOrderItem.receivedQuantitiesIds = $scope.purchase_order.items[$scope.offerIndex].receivedQuantitiesIds;
                    }

                    var logs = [];
                    logs.push(getTheLog("You changed the product detail of " + purchaseOrderItem.number, 'retailerPortal'));
                    logs.push(getTheLog("Retailer changed the product detail of " + purchaseOrderItem.number, 'supplierPortal'));

                    if (isProductChangedInPurchaseOrder) {
                        purchaseOrderItem.isProductChangedInpurchaseOrderItem = true;
                        purchaseOrderItem.newProductNumber = newProductNumber;
                        purchaseOrderItem.oldProductNumber = oldProductNumber;
                    }
                    $scope.purchase_order.items[$scope.offerIndex] = purchaseOrderItem;

                    var updBody = {
                        _id: $scope.purchase_order._id,
                        item: purchaseOrderItem,
                        status: $scope.purchase_order.status[0].status,
                        logs: logs,
                        shopId: $rootScope.currentShop._id
                    }
                    console.log('updBody: ', updBody);
                    // return;
                    $scope.localdata.loading = true;
                    $purchaseOrder.updateExistingProductInPurchaseOrder(updBody).then(function (updres) {
                        console.log('Updating existing product done');
                        $scope.localdata.loading = false;
                        $scope.cancelItem(); // For removing input field
                        $scope.GetExistingPurchaseOrder($scope.limit); // For getiing private label and all the things
                    }).catch(function (upderr) {
                        $scope.localdata.loading = false;
                        console.log('Error while updating product: ', upderr);
                    });
                }

                // If product already exist then increased the order qunaitty
                function increasedTheOrderQuantity(updbody, index) {
                    $scope.localdata.loading = true;
                    $purchaseOrder.increasedTheOrderQuantity(updbody).then(function (updres) {
                        $scope.localdata.loading = false;
                        $scope.cancelItem(); // For removing input field
                        $scope.GetSingleProductFromPurchaseOrder(updbody.item._id, index)
                        // $scope.GetExistingPurchaseOrder($scope.limit); // For getiing private label and all the things
                    }).catch(function (upderr) {
                        $scope.localdata.loading = false;
                        console.error('Error while updating product: ', upderr);
                    });
                }

                $scope.saveItem = function (item, search_value) {
                    // $scope.purchase_order.bookAllRecLoadingClicked = false;
                    console.log('------------------------- saveItem!! ', JSON.parse(JSON.stringify(item)), $scope.localdata.ean, $scope.localdata.productNumber);
                    if ($scope.purchase_order._id) $scope.previousButton();
                    console.log("Item", item, search_value);
                    console.log('$scope.purchase_order.items');
                    console.log($scope.purchase_order.items);
                    $scope.purchaseOrderItems = $scope.purchase_order.items;
                    console.log($scope.purchaseOrderItems);

                    if (!item.product_id) prefillTheValueWhenAddingProduct();
                    $scope.localdata.search_value = search_value;
                    if ($scope.selectedCompany) {
                        console.log('------------ if!!');
                        if (!$scope.editItem) {
                            console.log('------------ if if!!');
                            // Going to add completely new product
                            var collectionString = '';
                            if (item.product_id) {
                                console.log('------------ if if if!!');
                                console.log('if: collectionString');
                                var bodyToSend = {
                                    productId: item.product_id,
                                    shopId: $rootScope.currentShop._id,
                                    language: $rootScope.language
                                }
                                $purchaseOrder.getCollectionStringForPurchaseOrderProduct(bodyToSend).then(function (collectinStrRes) {
                                    if (collectinStrRes && collectinStrRes.data && collectinStrRes.data.data) {
                                        collectionString = collectinStrRes.data.data;
                                    }
                                    // console.log('collectinStrRes: ', collectionString);
                                    item.collectionString = collectionString;
                                    if (!$scope.purchaseOrderid) {
                                        createNewPurchaseOrder(item);
                                    } else {
                                        addProducctToExistingPurchaseOrder(item);
                                    }
                                }).catch(function (error) {
                                    console.error('ERROR IN FETCHING COLLETION STRING: ', error);
                                });
                            } else {
                                item.collectionString = collectionString;
                                if (!$scope.purchaseOrderid) {
                                    createNewPurchaseOrder(item);
                                } else {
                                    addProducctToExistingPurchaseOrder(item);
                                }
                            }
                        } else {
                            if ($scope.purchase_order.status[0].status != 'New') {
                                $scope.isErrorOccured = true;
                                $scope.errorMsg = 'You can not update the product in this way. Use the pencils to change the ordered/received quanitity or delete the product';
                            } else {
                                updateExistingProductInPurchaseOrder(item);
                            }
                        }
                        // console.log('$scope.purchaseOrderItems 1');
                        // console.log($scope.purchaseOrderItems);
                        // console.log('$scope.purchase_order.items');
                        // console.log($scope.purchase_order.items);
                        $scope.purchaseOrderItems = $scope.purchase_order.items;
                        // console.log($scope.purchaseOrderItems);
                    } else {
                        $scope.isErrorOccured = true;
                        $scope.errorMsg = 'Select the company first';
                    }
                }

                $scope.AddProduct = function (item) {
                    if (item.unofficialProduct && item.unofficialProduct.companyAppliedStartingDigits) return;
                    var body = {
                        shopId: $rootScope.currentShop._id,
                        productNumber: item.number,
                        purchaseOrderId: $scope.purchase_order._id,
                        purchaseOrderItemId: item._id
                    };
                    $purchaseOrder.isProductExistByProductNumber(body).then(function (response) {
                        if (response && response.data && response.data.data && response.data.data.isProductCreated) {
                            var resDetail = response.data.data;
                            $state.go('retailer.edit-product', { 'orderNumber': $stateParams.orderNo, _id: resDetail.product._id, status: 'view' });
                        } else {
                            $state.go('retailer.add-product', { 'orderNumber': $stateParams.orderNo, 'itemId': item._id });
                        }
                    }).catch(function (error) {
                        console.error('error in checking the productNumber: ', error);
                    });
                }

                $scope.editProduct = function (item) {
                    if (item.unofficialProduct && item.unofficialProduct.companyAppliedStartingDigits) return;
                    $rootScope.OrderNoFromPurchaseOrder = $stateParams.orderNo;
                    // console.log('edit prodcut item: ', item.product_id);
                    console.log(!item.product_id.en);
                    if (!item.product_id.en) {
                        // console.log('GetExistingPurchaseOrder($scope.limit) called: ', 5);
                        $scope.GetExistingPurchaseOrder($scope.limit);
                    } else {
                        console.log(item.product_id._id);
                        $state.go('retailer.edit-product', {
                            'orderNumber': $stateParams.orderNo,
                            _id: item.product_id._id,
                            status: 'view'
                        });
                    }
                }

                $scope.uibProductEditPage = function (item, isCalculator) {
                    if (item.unofficialProduct && item.unofficialProduct.companyAppliedStartingDigits) {
                        return 'Can not add/Edit this product';
                    } else if (isCalculator) {
                        return 'First add all the product details, put order to inspection mode and fill in a received quantity';
                    } else {
                        return '';
                    }
                }

                // input field will be remove
                $scope.cancelItem = function () {
                    $scope.searchStarted = false;
                    $scope.searchMoreContent = false;
                    $scope.searchingProduct = false;
                    $scope.countOfProducts = undefined;
                    $scope.localdata.ean = '';
                    $scope.localdata.productNumber = '';
                    $scope.hits = []
                    $scope.offerIndex = null;
                    $scope.prefillPurchaseOrderItemData = {};
                    $('#search_field_purchaseOrder').val('');
                    $('#offercomment').val('');
                    $scope.localdata.search_value = '';
                    $scope.offerItem = {
                        quantity: 1
                    };
                    $scope.offerItem.quantity = 1;
                    $scope.editItem = false;
                    // console.log('$scope.offeritem: ', $scope.offerItem);
                    if ($scope.HaveToRemovedError != 'no') {
                        $scope.errorMsg = '';
                        $scope.isErrorOccured = false;
                    }
                }

                // DEPRECATED, EDIT FUNCTIONALITY OF THE PRODUCT
                $scope.openItem = function (item, index) {
                    return;
                    // console.log('open item called: ', item.name, index, item);
                    $scope.localdata.search_value = item.number;
                    $('#search_field_purchaseOrder').val(item.number);
                    $scope.offerItem = {
                        quantity: 1
                    };
                    $scope.offerItem.comment = item.name;
                    $scope.offerItem.quantity = item.quantity;
                    $scope.offerItem.product_id = item.product_id && item.product_id._id ? item.product_id._id : undefined;
                    // console.log('real', $scope.offerItem, $scope.offerItem.comment)
                    // $scope.offerItem = angular.copy(item);
                    $scope.editItem = true;
                    $scope.offerIndex = index;
                }

                $scope.openEmailCompanyEmail = function () {
                    $state.go('retailer.purchase-order-mail-to-supplier', {
                        orderNo: $stateParams.orderNo
                    });
                }

                $scope.deleteItem = function (index) {
                    prompt({
                        title: $language.translate('DELETE_RECEIVED_QUANTITY'),
                        message: $language.translate('YOUR_ABOUT_TO_DELETE_ARE_YOU_SURE_TO_DELETE'),
                        buttons: [
                            {
                                label: $language.translate('DELETE'),
                                primary: true
                            },
                            {
                                label: $language.translate('DO_NOT_STOCK_UP'),
                                primary: false,
                                cancel: true
                            }
                        ]
                    }).then(function (response) {
                        console.log('------------ inside if!!: ', response);
                        if (response.primary) {
                            // DELETING PRODUCT
                            var item = $scope.purchase_order.items[index];
                            if ($scope.purchase_order.status[0].status == 'Inspection' || $scope.purchase_order.status[0].status == 'Backorder') {
                                item.ProdChangesWhenInspectionActive.isProductChanged = true;
                            } else if ($scope.purchase_order.status[0].status == 'New') {
                                item.isProductDeletedInNewStatus = true;
                            }
                            item.quantity = 0;
                            item.isDeleted = true;
                            $scope.purchase_order.items[index].isDeleted = true;
                            var deleteBody = {
                                _id: $scope.purchase_order._id,
                                item: item,
                                status: $scope.purchase_order.status[0].status,
                                shopId: $rootScope.currentShop._id
                            }
                            $scope.localdata.loading = true;
                            $scope.priceCollLoading = true;
                            console.log('deleteBody ', deleteBody);
                            $purchaseOrder.deleteProductFromPurchaseOrder(deleteBody).then(function (updres) {
                                console.log('Updating existing product done');
                                $scope.localdata.loading = false;
                                // $scope.purchase_order.items.splice(index, 1);
                                // $scope.purchase_order.items[i].isDeleted = true;
                                $scope.localdata.isShowDeletedItem = true;
                                $scope.localdata.isAnyPurchaseOrderItemDeleted = true;
                                enablePriceCollBtn();
                                $scope.cancelItem(); // For removing input field
                                $scope.GetExistingPurchaseOrder($scope.limit); // For getiing private label and all the things
                            }).catch(function (upderr) {
                                console.log('---------- upderr!! ', upderr);
                                $scope.localdata.loading = false;
                                enablePriceCollBtn();
                                console.log('Error while updating product: ', upderr);
                            });
                        } else {
                            // NOT STOCKING UP
                            var body = {
                                purchaseOrderId: $scope.purchase_order._id,
                                type: 'canBeStockUp',
                                key: 'canBeStockUp',
                                value: {
                                    itemId: $scope.purchase_order.items[index]._id
                                },
                                shopId: $rootScope.currentShop._id
                            }
                            console.log('NOT STOCKING UP: ', body);
                            $purchaseOrder.updatePurchaseOrderByKey(body).then(function (addresres) {
                                $scope.purchase_order.items[index].canBeStockUp = false;
                                checkOrderIsCompletedOrNot();
                            }).catch(function (error) {
                                console.log('updatePurchaseOrderByKey address error: ', error);
                            });
                        }
                    }).catch(function (error) {
                        console.log('error: inprompnt: ', error);
                    });
                }

                function openProcessPurchaseOrderModal(data) {
                    console.log('openProcessPurchaseOrderModal 3');
                    if ($scope.purchase_order && $scope.purchase_order.company &&
                        $scope.purchase_order.status && $scope.purchase_order.status.length &&
                        $scope.purchase_order.status[0].status && $scope.purchase_order.status[0].status === 'New') {
                        prompt({
                            title: $language.translate('PLACE_THE_ORDER'),
                            message: $scope.purchase_order.company.processPurchaseOrder ? $language.translate('DO_YOU_WANT_TO_PLACE_THIS_ORDER') : $language.translate('DO_YOU_WANT_TO_EMAIL_THIS_ORDER'),
                            buttons: [
                                {
                                    label: $language.translate('YES'),
                                    primary: true
                                },
                                {
                                    label: $language.translate('NO'),
                                    primary: false,
                                    cancel: true
                                }
                            ]
                        }).then(function (response) {
                            console.log('response: ', response);
                            if (response && response.primary && $scope.purchase_order.company.processPurchaseOrder) {
                                $scope.purchase_order.status[0].status = 'Processing';
                                $scope.changeTheStatus(true);
                            } else if (response && response.primary && !$scope.purchase_order.company.processPurchaseOrder) {
                                //$scope.purchase_order.status[0].status = 'New';
                                //$scope.changeTheStatus(true);
                                $scope.openEmailCompanyEmail();
                            } else {
                                console.log('Secondary');
                                $rootScope.isBackOfRecommendation = undefined;
                                if (data && data.isBackButtonOfPurchaseOrder) {
                                    $rootScope.isPurchaseOrderNew = false;
                                    console.log('$state.go 9');
                                    $state.go('retailer.purchase-orders', { company: '' })
                                }
                            }
                        }).catch(function (error) {
                            console.log('error: inprompnt: ', error);
                        });
                    }
                }

                $scope.calculationsOfOrdered = function (index) {

                    enableDisableFieldOfInput(true);
                    console.log('--------------- calculationsOfOrdered!!');
                    var item = $scope.purchase_order.items[index];
                    var addQuantity = item.addQuantity;
                    console.log('item ', item);
                    if (item.isDeleted) {
                        // console.log('When its deleted');
                        item.quantity = 0;
                        if (item.addQuantity) item.addQuantity = Math.floor(item.addQuantity);
                        // console.log('item.addQuantity ', item.addQuantity);
                    } else {
                        // console.log('When it is not deleted');
                        // item.addQuantity = (item.addQuantity - item.quantity);
                        if (item.addQuantity) item.addQuantity = Math.floor(item.addQuantity);
                        // console.log('item.addQuantity ', item.addQuantity);
                    }
                    // console.log('item.ProdChangesWhenInspectionActive.updatedQuantity ', item.ProdChangesWhenInspectionActive.updatedQuantity);
                    // console.log('item: ', item);
                    // if(item.ProdChangesWhenInspectionActive.updatedQuantity == 0) item.ProdChangesWhenInspectionActive.updatedQuantity = item.quantity;
                    console.log('item.ProdChangesWhenInspectionActive.updatedQuantity ', item.ProdChangesWhenInspectionActive.updatedQuantity);
                    var isUpdated = false;
                    if ($scope.purchase_order.status[0].status == 'Inspection' && item.isDeleted) {
                        isUpdated = true;
                        item.isDeleted = false;
                        item.quantity = item.receivedQuantity;
                        item.ProdChangesWhenInspectionActive.isEdited = true;
                        item.ProdChangesWhenInspectionActive.updatedAt = new Date();
                        item.ProdChangesWhenInspectionActive.updatedQuantity = item.quantity;
                        // console.log('item.ProdChangesWhenInspectionActive.updatedQuantity ', item.ProdChangesWhenInspectionActive.updatedQuantity);
                        //  item.ProdChangesWhenInspectionActive.updatedQuantity = addQuantity - item.ProdChangesWhenInspectionActive.updatedQuantity;
                    }

                    $scope.purchase_order.items[index].isQuantityEditable = false;
                    // console.log('item.addQuantity ', item.addQuantity);
                    // console.log('item.quantity ', item.quantity);
                    var tempValue = item.addQuantity;
                    // if (item.addQuantity == 0) { item.addQuantity = item.quantity; }
                    console.log('item.quantity ', item.quantity);
                    console.log('tempValue ', tempValue)
                    console.log('item.addQuantity ', item.addQuantity);
                    console.log('item.receivedQuantity ', item.receivedQuantity);

                    if (tempValue >= 0) {
                        // console.log('------------ inside if!');
                        // var totalQuantity = (+item.quantity) + (+item.addQuantity);
                        var totalQuantity = item.addQuantity;
                        // console.log('totalQuantity ', totalQuantity);
                        // Now onwards, minus quantity can be added but its as credited quantity
                        if (totalQuantity >= item.receivedQuantity) {
                            if (totalQuantity >= 0) {
                                // console.log('------------ inside if if!');
                                if (totalQuantity >= item.receivedQuantity) {
                                    // console.log('------------ inside if if if!');
                                    // console.log('item.quantity ', item.quantity);
                                    UpdateTheQuantity(index, item, isUpdated);
                                    // if(item.addQuantity < 0){ 
                                    //     item.addQuantity = addQuantity; 
                                    //     console.log(item.addQuantity);
                                    // }
                                } else {
                                    // console.log('------------ inside if if else!');
                                    // console.log('------------- inside error!!');
                                    // console.log('Ordered should be more than the recieved');
                                    $scope.isErrorOccured = true;
                                    $scope.errorMsg = 'Received should not be more than the ordered quantity. Raise the received quantity first';
                                    // console.log($scope.errorMsg);
                                }
                            } else {
                                // console.log('------------ inside if else!');
                                // $scope.isErrorOccured = true;
                                // $scope.errorMsg = 'Enter proper value';
                                // console.log('hello there: ', totalQuantity, item.receivedQuantity);
                                if (totalQuantity <= item.receivedQuantity) {
                                    // console.log('------------ inside if else if!');
                                    // console.log('item.quantity ', item.quantity);
                                    UpdateTheQuantity(index, item, isUpdated);
                                } else {
                                    // console.log('------------ inside if else else!');
                                    // console.log('------------- inside error!!');
                                    $scope.isErrorOccured = true;
                                    $scope.errorMsg = 'Received should not be less than the ordered quantity. Decrease the received quantity first';
                                    // console.log($scope.errorMsg);
                                }
                            }
                        } else {
                            // console.log('Ordered should be more than the recieved');
                            $scope.isErrorOccured = true;
                            $scope.errorMsg = 'Received should not be more than the ordered quantity. Raise the received quantity first';
                            // console.log($scope.errorMsg);
                        }
                        enableDisableFieldOfInput(false);
                    } else {
                        var totalQuantity = item.addQuantity;
                        if (totalQuantity <= item.receivedQuantity) {
                            console.log('------------ inside if else if 123!');
                            console.log('item.quantity ', item.quantity);
                            UpdateTheQuantity(index, item, isUpdated);
                        } else {
                            console.log('------------ inside if else else 123!');
                            // console.log('------------- inside error!!');
                            $scope.isErrorOccured = true;
                            $scope.errorMsg = 'Received should not be less than the ordered quantity. Decrease the received quantity first';
                            // console.log($scope.errorMsg);
                        }
                    }
                }

                function UpdateTheQuantity(index, item, isUpdated) {
                    console.log('------------------ UpdateTheQuantity!!');
                    console.log('item.quantity  ', item.quantity );
                    $scope.cancelItem();
                    console.log(item.quantity);
                    console.log(item.addQuantity);
                    // var totalQuantity = (+item.quantity) + (+item.addQuantity);
                    var totalQuantity = item.addQuantity;
                    // Start - Other calculation as it is
                    $scope.isErrorOccured = false;
                    $scope.purchase_order.items[index].oldQuantity = item.quantity; // For Backend Old quantity
                    $scope.purchase_order.items[index].quantity = totalQuantity; // Updating view

                    // If all Backorder is zero then and only it will completed status of order and Backorder status should be disabled
                    for (var i = 0; i < $scope.purchase_order.items.length; i++) {
                        $scope.purchase_order.isNotCompleted = false;
                        if (!$scope.purchase_order.items[i].isDeleted && (($scope.purchase_order.items[i].quantity) - ($scope.purchase_order.items[i].receivedQuantity) != 0 || !$scope.purchase_order.items[i].isGreen)) {
                            $scope.purchase_order.isNotCompleted = true;
                            break;
                        }
                        // if (($scope.purchase_order.items[i].quantity) - ($scope.purchase_order.items[i].receivedQuantity) != 0) {
                        //     $scope.purchase_order.isNotCompleted = true;
                        //     break;
                        // }
                    }

                    $scope.offerItem.quantity = item.quantity;

                    // Its for when retailer edit order in inspection mode then we have to show that how much ordered quantity increased or decreased
                    // console.log('--------------------- above Inspection!!');
                    // console.log('item.addQuantity ', item.addQuantity);
                    // console.log('item.quantity ', item.quantity);
                    // console.log('$scope.purchase_order.items[index].oldQuantity ', $scope.purchase_order.items[index].oldQuantity);
                    if (($scope.purchase_order.status[0].status == 'Inspection' || $scope.purchase_order.status[0].status == 'Backorder') && !isUpdated) {
                        // console.log('--------------------- inside Inspection!!');
                        // console.log('item.ProdChangesWhenInspectionActive.updatedQuantity ', item.ProdChangesWhenInspectionActive.updatedQuantity);
                        // console.log('item.addQuantity ', item.addQuantity);
                        item.ProdChangesWhenInspectionActive.isProductChanged = true;
                        item.ProdChangesWhenInspectionActive.updatedAt = new Date();
                        item.ProdChangesWhenInspectionActive.isEdited = true;
                        // item.ProdChangesWhenInspectionActive.updatedQuantity = (+item.ProdChangesWhenInspectionActive.updatedQuantity) + (+(item.addQuantity));
                        item.ProdChangesWhenInspectionActive.updatedQuantity = item.quantity - $scope.purchase_order.items[index].oldQuantity + item.ProdChangesWhenInspectionActive.updatedQuantity;
                    }

                    var updBody = {
                        _id: $scope.purchase_order._id,
                        item: item,
                        status: $scope.purchase_order.status[0].status,
                        shopId: $rootScope.currentShop._id
                    }
                    // $scope.localdata.loading = true;
                    $scope.localdata.itemIndex = index;
                    // console.log('updateQuantityOfPurchaseOrder item: ', item);
                    item.addedQuantity = item.addQuantity;
                    //  item.addQuantity = 0;
                    // console.log('------------------------ updateQuantityOfPurchaseOrder!!');
                    // console.log(JSON.stringify(updBody, null, 2));
                    if (item.addQuantity == 0) { item.addQuantity = item.quantity; }
                    var updBody2 = angular.copy(updBody);
                    // console.log(item.quantity);
                    // if (item.addQuantity < 0) {
                    //     item.addQuantity = addQuantity;
                    //     // console.log(item.addQuantity);
                    // }
                    // console.log(item.quantity);
                    console.log('------------------- printing data!!');
                    console.log('updBody2  ', updBody2);
                    console.log('$scope.purchase_order  ', $scope.purchase_order);
                    checkCalculationIconIsBlackOrGreen();
                    var delayInMilliseconds = 2000; //2 second
                    checkIsCancelledFlag();
                    setTimeout(function () {
                        $purchaseOrder.updateQuantityOfPurchaseOrder(updBody2).then(function (updres) {
                            // console.log('updateQuantityOfPurchaseOrder done');
                            // For removing input field
                            // $scope.GetExistingPurchaseOrder($scope.limit); // For getiing private label and all the things
                        }).catch(function (upderr) {
                            enableDisableFieldOfInput(false);
                            // console.log('Error while updating product: ', upderr);
                        });
                    }, delayInMilliseconds);

                    // End-Other calcualtion as it is
                }

                $scope.calculationsOfReceivedQuantity = function (index) {

                    // $scope.cancelItem();
                    console.log('--------------------------- calculationsOfReceivedQuantity!!');

                    var item = $scope.purchase_order.items[index];
                    // console.log(item);
                    var oldReceivedQuantity = item.receivedQuantity;
                    console.log('item.addreceivedQuantity ', item.addreceivedQuantity);
                    console.log('item.receivedQuantity ', item.receivedQuantity);
                    // item.receivedQuantity = Math.round(item.receivedQuantity);
                    console.log('item.isDeleted ', item.isDeleted);
                    if (item.isDeleted) {
                        // console.log('When its deleted');
                        item.receivedQuantity = 0;
                        if (!item.addreceivedQuantity) item.addreceivedQuantity = Math.floor(item.addreceivedQuantity);
                        // console.log('item.addreceivedQuantity ',item.addreceivedQuantity);
                    } else {
                        // console.log('When its not deleted');
                        // item.addreceivedQuantity = (item.addreceivedQuantity - item.receivedQuantity);
                        if (item.addreceivedQuantity) item.addreceivedQuantity = Math.floor(item.addreceivedQuantity);
                        // console.log('item.addreceivedQuantity ',item.addreceivedQuantity);
                    }

                    $scope.purchase_order.items[index].isRecievedQuantityEditable = false;

                    if ($scope.purchase_order.status[0].status == 'Inspection' && item.isDeleted) {
                        item.isDeleted = false;
                        item.ProdChangesWhenInspectionActive.isEdited = true;
                        item.ProdChangesWhenInspectionActive.updatedAt = new Date();
                    }
                    // console.log('item.quantity: ', item.quantity, item.quantity < (+ item.receivedQuantity) + (+ item.addreceivedQuantity));
                    // console.log('item.quantity ',item.quantity);
                    // console.log('item.receivedQuantity ',item.receivedQuantity);
                    // console.log('item.addreceivedQuantity ',item.addreceivedQuantity);
                    item.oldReceivedQuantity = oldReceivedQuantity;
                    if (item.isDeleted && item.quantity < (+item.receivedQuantity) + (+item.addreceivedQuantity)) {
                        $scope.isErrorOccured = true;
                        $scope.errorMsg = 'Ordered quantity is less than the received amount. So you can not add it when item is deleted!';
                    } else if (item.quantity >= 0) {
                        // console.log('item quantity: in plus: ', item.addreceivedQuantity);
                        if (item.addreceivedQuantity || item.addreceivedQuantity == 0) {
                            // var totalReceivedQuantity = (+item.receivedQuantity) + (+item.addreceivedQuantity);
                            var totalReceivedQuantity = item.addreceivedQuantity;
                            // console.log('totalReceivedQuantity ', totalReceivedQuantity);
                            // console.log('item.quantity ', item.quantity);
                            // console.log('item.totalReceivedQuantity ', totalReceivedQuantity);
                            // console.log(totalReceivedQuantity, item.quantity, totalReceivedQuantity);
                            console.log('------------------------ 1!');
                            console.log(totalReceivedQuantity >= 0 && item.quantity >= totalReceivedQuantity);
                            
                            if(!(totalReceivedQuantity >= 0 && item.quantity >= totalReceivedQuantity)) {
                                $scope.purchase_order.items[index].addQuantity = totalReceivedQuantity;
                                item.addQuantity = totalReceivedQuantity;
                                $scope.calculationsOfOrdered(index);
                            }

                            console.log('------------------------ 2!');
                            console.log(totalReceivedQuantity >= 0 && item.quantity >= totalReceivedQuantity);

                            if (totalReceivedQuantity >= 0 && item.quantity >= totalReceivedQuantity) {
                                console.log('------- if!');
                                UpdateTheRecievedQunatity(index, item);
                            } else {
                                console.log('------- else!');
                                $scope.isErrorOccured = true;
                                $scope.errorMsg = 'Enter proper value';
                            }
                        }
                    } else {
                        // var totalReceivedQuantity = (+item.receivedQuantity) + (+item.addreceivedQuantity);
                        var totalReceivedQuantity = item.addreceivedQuantity;
                        // console.log('item quantity: in minus', item.quantity, item.addreceivedQuantity, totalReceivedQuantity);
                        if (totalReceivedQuantity <= 0) {
                            UpdateTheRecievedQunatity(index, item);
                        } else {
                            $scope.isErrorOccured = true;
                            $scope.errorMsg = 'Enter proper value';
                        }
                    }
                }

                $scope.disabledIncreseBtn = function () {
                    console.log('disabledIncreseBtn: ');
                }

                function UpdateTheRecievedQunatity(index, item) {
                    console.log('---------------- UpdateTheRecievedQunatity!!');
                    // console.log('item.receivedQuantity ', item.receivedQuantity);
                    // console.log('item.addreceivedQuantity ', item.addreceivedQuantity);
                    // var totalReceivedQuantity = (+item.receivedQuantity) + (+item.addreceivedQuantity);
                    var totalReceivedQuantity = item.addreceivedQuantity;
                    // console.log('totalReceivedQuantity ', totalReceivedQuantity);
                    // console.log('item.quantity ', item.quantity);
                    // console.log('hello: Update the recieved quantity: ', item.quantity, item.receivedQuantity, item.addreceivedQuantity, totalReceivedQuantity);
                    $scope.isErrorOccured = false;
                    var haveToChangedOrderQunaity = false;
                    // if (item.quantity > totalReceivedQuantity) {
                    //     haveToChangedOrderQunaity = true;
                    //     console.log('Notice: Ordered quantity was less the the received amount. So we just increased the ordered quanitity!');
                    //     $scope.isErrorOccured = true;
                    //     $scope.HaveToRemovedError = 'no';
                    //     $scope.errorMsg = 'Notice: Ordered quantity was less than the received amount. So we just increased the ordered quanitity!';
                    // }

                    item.oldReceivedQuantity = item.receivedQuantity; // For Backend Old received quantity
                    item.addedReceivedQunaitty = item.addreceivedQuantity;
                    // If all Backorder is zero then and only it will completed status of order and Backorder status should be disabled
                    for (var i = 0; i < $scope.purchase_order.items.length; i++) {
                        $scope.purchase_order.isNotCompleted = false;
                        if (!$scope.purchase_order.items[i].isDeleted && (($scope.purchase_order.items[i].quantity) - ($scope.purchase_order.items[i].receivedQuantity) != 0 || !$scope.purchase_order.items[i].isGreen)) {
                            $scope.purchase_order.isNotCompleted = true;
                            break;
                        }
                    }

                    $scope.offerItem.quantity = item.quantity;
                    item.receivedQuantity = totalReceivedQuantity; // Updating view
                    item.addreceivedQuantity = 0;

                    var updBody = {
                        _id: $scope.purchase_order._id,
                        item: item,
                        status: $scope.purchase_order.status[0].status,
                        haveToChangedOrderQunaity: haveToChangedOrderQunaity,
                        shopId: $rootScope.currentShop._id
                    }
                    var updBody2 = angular.copy(updBody);
                    $scope.localdata.itemIndex = index;
                    // enableDisableFieldOfInput(true);
                    item.addreceivedQuantity = totalReceivedQuantity;
                    checkCalculationIconIsBlackOrGreen();
                    var delayInMilliseconds = 2000; //2 second
                    $scope.priceCollLoading = true;
                    checkIsCancelledFlag();
                    setTimeout(function () {
                    $purchaseOrder.updateReceivedQuantityOfPurchaseOrder(updBody2).then(function (updres) {
                        $scope.localdata.loading = false;
                        enablePriceCollBtn();
                        $scope.cancelItem(); // For removing input field
                        $scope.GetExistingPurchaseOrder($scope.limit); // For getiing private label and all the things
                    }).catch(function (upderr) {
                        enableDisableFieldOfInput(false);
                        enablePriceCollBtn();
                    });
                    }, delayInMilliseconds);
                }

                function enablePriceCollBtn() {
                    setTimeout(function () {
                        $scope.priceCollLoading = false;
                    }, 1000);
                }

                $scope.popover = function (item) {
                    if (item.product_id) {
                        var text = (item.product_id && item.product_id.variants && item.product_id.variants.length && item.product_id.variants[0] && item.product_id.variants[0].productNumber) ? item.product_id.variants[0].productNumber : item.number;
                        if (item.product_id.images && item.product_id.images.length && item.product_id.images[0].src) {
                            var image = $rootScope.awsUrl + item.product_id.images[0].src;
                            // var image = 'https://prismanote.s3.amazonaws.com/general/kassa-retail-juwelier-1.jpg'
                            var alttext = (item.product_id && item.product_id.category) ? item.product_id.category : "No Image found";
                            // return "<img src=" + image + " alt=" + alttext + ">";
                            return "<html> <body> <p> <b>" + text + "</b> </p> <p> <img src=" + image + " alt=" + alttext + "> </p></body> </html>";
                        } else if (text) {
                            return "<html> <body> <p> <b>" + text + "</b> </p> </body> </html>";
                        } else if (item.product_id.category) {
                            return item.product_id.category;
                        }
                    } else {
                        return "<html> <body> <p> <b>" + item.number + "</b> </p> </body> </html>";
                    }
                }

                $scope.popoverName = function (itemname) {
                    return "<html> <body> <p> <b>" + itemname + "</b> </p> </body> </html>";
                }

                // Print the given data
                $scope.ExportToPDF = function (isInvoicePDF, isProFormaInvoice) {
                    if (!isProFormaInvoice && isInvoicePDF) $scope.canCompanyEdit = false;
                    $scope.localdata.loading = true;
                    var encrypted = $crypto.encrypt({
                        shop: $scope.purchase_order.shop,
                        purchaseOrderId: $scope.purchase_order._id,
                        typeDocument: 'document',
                        isInvoicePDF: isInvoicePDF,
                        isProFormaInvoice: isProFormaInvoice,
                        isFromRetailerPortal: true,
                        language: $rootScope.language,
                        deviceId: $rootScope.currentDevice._id
                    })

                    $http({
                        method: "POST",
                        url: '/api/generate-pdf-purchase-order',
                        data: {encrypted: encrypted} ,
                        responseType: 'arraybuffer'
                    })
                        .then(function (response) {
                            $scope.localdata.loading = false;
                            if (!isProFormaInvoice && isInvoicePDF) $scope.purchase_order.companyRemarks.status = 'Completed';
                            var file = new Blob([response.data], {
                                type: 'application/pdf'
                            });
                            var fileName = 'purchaseorder_' + $scope.purchase_order.number + '.pdf';
                            FileSaver.saveAs(file, fileName);
                            $scope.GetExistingPurchaseOrder($scope.limit);
                        })
                        .catch(function (reason) {
                            $scope.localdata.loading = false;
                            $scope.printerr = true;
                            if (!isProFormaInvoice) $scope.canCompanyEdit = true;
                            if (reason && reason.status === 400) {
                                $scope.printerrmessage = 'Vat number of company not found. Please ask to admin that add the vat number. Call: +31 345 631 776';
                            } else {
                                $scope.printerrmessage = 'Something went wrong';
                            }
                            // console.error("Error while generating PDF", reason.status);
                        })
                }

                $scope.openPrintLabelModal = function (selectedProduct, index) {
                    var modalInstance = $uibModal.open({
                        templateUrl: '../views/modal/print-label-modal.html',
                        controller: 'printLabelModalController',
                        resolve: {
                            product: function () {
                                return selectedProduct;
                            },
                            loadProduct: function () {
                                return true
                            },
                            labelDescription: function () {
                                return null
                            },
                            empty: function () {
                                return false
                            }
                        }
                    });

                    //we will get the value here
                    modalInstance.result.then(function (result) {
                        if (result) {

                            if (result == true) {
                                // console.log("Index Of particular product in purchase order", index);
                                $scope.purchase_order.items[index].printed = true;
                            }
                            //ready
                            // $scope.product.labelDescription = result.labelDescription ? result.labelDescription : '';
                        }
                    }, function () {
                        // console.log("SHu k mara bhai");
                        //dismissed
                    })

                }

                $scope.editQuantity = function (item, index) {
                    // console.log('editQuantity: ', item, index);
                    $scope.purchase_order.items[index].isQuantityEditable = true;
                }

                $scope.editReceivedQuantity = function (item, index) {
                    // console.log('editQuantity: ', item, index);
                    $scope.purchase_order.items[index].isRecievedQuantityEditable = true;
                }

                // In tabular form, Uibtooltip of purchase order
                $scope.purchaserOrderHistoryUibToolTip = function (index) {
                    var item = $scope.itemHistory[index];
                    return $purchaseOrder.purchaserOrderHistoryUibToolTip(item);
                }

                // In tabular form, Uibtooltip of serial number
                $scope.serialNumberUibToolTip = function (index, childIndex) {
                    var item = $scope.itemHistory[index].serialNumbers[childIndex];
                    return $purchaseOrder.serialNumberUibToolTip(item);
                }

                // In tabular form, Uibtooltip of Price
                $scope.priceUibToolTip = function (index) {
                    var item = $scope.itemHistory[index];
                    return $purchaseOrder.priceUibToolTip(item);
                }

                // In tabular form, Uibtooltip of PriceVat
                $scope.priceVatUibToolTip = function (index) {
                    var item = $scope.itemHistory[index];
                    return $purchaseOrder.priceVatUibToolTip(item);
                }

                // In tabular form, Uibtooltip of Discount
                $scope.discountUibToolTip = function (index) {
                    var item = $scope.itemHistory[index];
                    return $purchaseOrder.discountUibToolTip(item);
                }

                // In tabular form, Uibtooltip of Invoice
                $scope.invoiceNumberHistoryUibToolTip = function () {
                    var invoiceHistory = $scope.withoutItemHistory && $scope.withoutItemHistory.invoiceNumberHistory ? $scope.withoutItemHistory.invoiceNumberHistory : undefined;
                    return $purchaseOrder.invoiceNumberHistoryUibToolTip(invoiceHistory);
                }

                // In tabular form, Uibtooltip of purchase Price
                $scope.purchasePriceUibToolTip = function (index) {
                    var item = $scope.itemHistory[index];
                    return $purchaseOrder.purchasePriceUibToolTip(item);
                }

                $scope.storageFactorUibToolTip = function (index) {
                    var item = $scope.itemHistory[index];
                    return $purchaseOrder.storageFactorUibToolTip(item);
                }

                $scope.toggleShowStatusOfProduct = function (index, isShow) {
                    // console.log('toggleShowStatusOfProduct called');
                    var body = {
                        shopId: $scope.currentShopDetail._id,
                        productId: $scope.purchase_order.items[index].product_id._id,
                        isShow: isShow
                    }
                    // $scope.purchase_order.items[index].isSwitchEnable = isShow;
                    // $scope.purchase_order.items[index].containsFilterInfo = isShow;
                    $scope.purchase_order.items[index].isWebShop = isShow;
                    // console.log(body, index, $scope.purchase_order.items[index]);
                    $api.post('toggle-show-status-of-product/', body)
                        .then(function (response) {
                            // $scope.purchase_order.items[index].isSwitchEnable = isShow;
                            // $scope.purchase_order.items[index].containsFilterInfo = isShow;
                            $scope.purchase_order.items[index].isWebShop = isShow;
                        }).catch(function (reason) {
                            $scope.isErrorOccured = true;
                            $scope.errorMsg = 'Something went wrong';
                            // $scope.purchase_order.items[index].isSwitchEnable = !isShow;
                            // $scope.purchase_order.items[index].containsFilterInfo = !isShow;
                            $scope.purchase_order.items[index].isWebShop = !isShow;
                        })
                }

                $scope.cancelPurchaseOrder = function () {
                    $api.delete('purchase-order/' + $stateParams.orderNo + '/' + $scope.currentShopDetail._id)
                        .then(function (response) {
                            console.log('response: ', response);
                            console.log('$state.go 1');
                            $state.go('retailer.purchase-orders');
                        }).catch(function (error) {
                            console.log('error: ', error);
                            $scope.isErrorOccured = true;
                            $scope.errorMsg = 'Internal Server Error while deleting order';
                        });
                }

                function AddTheLog(text, isFor) {
                    if (!$scope.purchase_order.log || !$scope.purchase_order.log.length) $scope.purchase_order.log = [];
                    $scope.purchase_order.log.push({
                        text: text,
                        userId: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
                        eType: 'retailer',
                        isFor: isFor,
                        createdAt: new Date()
                    })
                }

                function getTheLog(text, isFor) {
                    if (!$scope.purchase_order.log || !$scope.purchase_order.log.length) $scope.purchase_order.log = [];
                    var log = {
                        text: text,
                        userId: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
                        eType: 'retailer',
                        isFor: isFor,
                        createdAt: new Date()
                    }
                    $scope.purchase_order.log.push(log);
                    return log;
                }

                $scope.getTaxes = function () {
                    $api.get('tax/shop', {
                        shopId: $scope.currentShopDetail._id
                    })
                        .then(function (res) {
                            $scope.taxes = res.data.taxes.rates;
                            // console.log('taxes: ', $scope.taxes);
                            if ($scope.taxes && $scope.taxes.length) {
                                $scope.firstPriceVat = $scope.taxes[0].rate;
                            }
                        })
                        .catch(function (reason) {
                            console.error("Error while getting taxes for this shop", reason)
                        })
                }

                $scope.refreshSerialNumber = function () {
                    console.log('$scope.index: ', $scope.itemIndex);
                    if ($scope.itemIndex !== undefined) {
                        $scope.purchase_order.items[$scope.itemIndex].isEdit = false;
                        $scope.purchase_order.items[$scope.itemIndex].inputSerialNumber = '';
                    }
                }

                $scope.addSerialNumber = function (index) {
                    console.log('add Serial number: ', index);
                    var addedSerialNumber = $scope.purchase_order.items[index].inputSerialNumber;
                    if (addedSerialNumber) {
                        var body = {
                            purchaseOrderId: $scope.purchase_order._id,
                            purchaseOrderItemId: $scope.purchase_order.items[index]._id,
                            productId: $scope.purchase_order.items[index].product_id ? $scope.purchase_order.items[index].product_id._id : undefined,
                            shopId: $scope.currentShopDetail._id,
                            lastSerialNumber: '-',
                            addedSerialNumber: addedSerialNumber,
                            companyId: $scope.purchase_order.company._id,
                            isShow: true,
                            action: 'Added',
                            status: $scope.purchase_order.status[0].status,
                            eType: 'retailer',
                        }
                        console.log('index value: ', body);
                        // $scope.localdata.loading = true;
                        // console.log(7);
                        $scope.localdata.itemIndex = index;
                        enableDisableFieldOfInput(true);
                        $api.post('v2/shop/serialnumber-of-purchaseorder', body)
                            .then(function (res) {
                                $scope.localdata.loading = false;
                                $scope.refreshSerialNumber();
                                // console.log('GetExistingPurchaseOrder($scope.limit) called: ', 7);
                                $scope.GetExistingPurchaseOrder($scope.limit);
                            })
                            .catch(function (reason) {
                                enableDisableFieldOfInput(false);
                                $scope.localdata.loading = false;
                                console.error("Error while changing for this shop", reason)
                            })
                    }
                }

                $scope.updateSerialNumber = function (index) {
                    var addedSerialNumber = $scope.purchase_order.items[$scope.itemIndex].inputSerialNumber;
                    var lastSerialNumberDetail = $scope.purchase_order.items[$scope.itemIndex].serialNumbers[$scope.childIndex];
                    if (addedSerialNumber) {
                        var body = {
                            purchaseOrderId: $scope.purchase_order._id,
                            purchaseOrderItemId: $scope.purchase_order.items[index]._id,
                            productId: $scope.purchase_order.items[index].product_id ? $scope.purchase_order.items[index].product_id._id : undefined,
                            shopId: $scope.currentShopDetail._id,
                            lastSerialNumber: lastSerialNumberDetail.serialNumber,
                            serialNumberId: lastSerialNumberDetail.serialNumberId,
                            addedSerialNumber: addedSerialNumber,
                            companyId: $scope.purchase_order.company._id,
                            isShow: true,
                            action: 'Edited',
                            status: $scope.purchase_order.status[0].status,
                            eType: 'retailer',
                        }
                        console.log('index value: ', body);
                        // $scope.localdata.loading = true;
                        $scope.localdata.itemIndex = index;
                        enableDisableFieldOfInput(true);
                        $api.put('v2/shop/serialnumber-of-purchaseorder', body)
                            .then(function (res) {
                                $scope.localdata.loading = false;
                                $scope.refreshSerialNumber();
                                // console.log('GetExistingPurchaseOrder($scope.limit) called: ', 8);
                                $scope.GetExistingPurchaseOrder($scope.limit);
                            })
                            .catch(function (reason) {
                                enableDisableFieldOfInput(false);
                                $scope.localdata.loading = false;
                                console.error("Error while changing for this shop", reason)
                            })
                    }
                }

                $scope.willUpdateSerialNumber = function (index, childIndex) {
                    console.log('willUpdateSerialNumber called', index, childIndex);
                    console.log('$scope.purchase_order.items[index].serialNumbers: ', $scope.purchase_order.items[index].serialNumbers[childIndex]);
                    $scope.itemIndex = index;
                    $scope.childIndex = childIndex;
                    $scope.purchase_order.items[index].isEdit = true;
                    $scope.purchase_order.items[index].inputSerialNumber = $scope.purchase_order.items[index].serialNumbers[childIndex].serialNumber;
                }

                $scope.deleteSerialNumber = function (index) {
                    console.log('deleteSerialNumber: ', index);
                    // console.log('updateSerilaNumber: ', index);
                    // console.log('value to be updated: ', $scope.purchase_order.items[$scope.itemIndex].inputSerialNumber);
                    // console.log('child data: ', $scope.purchase_order.items[$scope.itemIndex].serialNumbers[$scope.childIndex]);
                    // console.log('add Serial number: ', index);
                    // var addedSerialNumber = $scope.purchase_order.items[$scope.itemIndex].inputSerialNumber;
                    // var lastSerialNumberDetail = $scope.purchase_order.items[$scope.itemIndex].serialNumbers[$scope.childIndex];
                    // console.log('hey: ', $scope.purchase_order.items[index]);
                    var serialNumber = $scope.purchase_order.items[index].serialNumbers[$scope.childIndex];
                    var body = {
                        purchaseOrderId: $scope.purchase_order._id,
                        purchaseOrderItemId: $scope.purchase_order.items[index]._id,
                        productId: $scope.purchase_order.items[index].product_id ? $scope.purchase_order.items[index].product_id._id : undefined,
                        shopId: $scope.currentShopDetail._id,
                        lastSerialNumber: serialNumber.serialNumber,
                        serialNumberId: serialNumber.serialNumberId,
                        addedSerialNumber: serialNumber.serialNumber,
                        companyId: $scope.purchase_order.company._id,
                        isShow: false,
                        action: 'Deleted',
                        status: $scope.purchase_order.status[0].status,
                        eType: 'retailer',
                    }
                    // console.log('index value: ----------------------', body);
                    // $scope.localdata.loading = true;
                    $scope.localdata.itemIndex = index;
                    enableDisableFieldOfInput(true);
                    console.log(9);
                    $api.post('v2/shop/delete-serialnumber-of-purchaseorder', body)
                        .then(function (res) {
                            $scope.localdata.loading = false;
                            $scope.refreshSerialNumber();
                            // console.log('GetExistingPurchaseOrder($scope.limit) called: ', 9);
                            $scope.GetExistingPurchaseOrder($scope.limit);
                        })
                        .catch(function (reason) {
                            enableDisableFieldOfInput(false);
                            $scope.localdata.loading = false;
                            console.error("Error while changing for this shop", reason)
                        })
                }

                $scope.expandSerialNumber = function (index) {
                    console.log('expandSerialNumber called: ', index, $scope.purchase_order.items[index]);
                    $scope.purchase_order.items[index].isExpandSerialNumber = true;
                }

                $scope.addQunatityButton = function (item, index, valueToAdd, isClickedInNewStatus) {
                    $scope.focusIndex = index;
                    // $scope.focusItem = item;
                    // $scope.addQuantityClicked = false;
                    if (isClickedInNewStatus) {
                        $scope.purchase_order.status[0].status = "Inspection";
                        captureTheQuantityWhenChangingStatusNewToAnyOther();
                        $scope.mystatus = 3;
                        $scope.purchase_order.isChangeTheStatus = true; // For sending notificaiton
                    } else {
                        captureTheQuantityWhenChangingStatusNewToAnyOther();
                    }
                    console.log('$scope.purchase_order.status[0].status index: ', index, $scope.purchase_order.status[0].status);
                    $scope.purchase_order.items[index].isRecievedQuantityEditable = true;
                    $scope.purchase_order.items[index].isReadOnly = true;
                    $scope.purchase_order.items[index].addreceivedQuantity = valueToAdd;
                    focusPriceInput();
                }

                // Its for deleting the capture the so we can so it when product is deleted
                function captureTheQuantityWhenChangingStatusNewToAnyOther() {
                    if ($scope.mystatus == 1 && $scope.purchase_order.status[0].status != "New") {
                        console.log('new to other status');
                        var items = $scope.purchase_order.items;
                        for (i = 0; i < items.length; i++) {
                            $scope.purchase_order.items[i].quantityWhenMoveNewToOtherStatus = $scope.purchase_order.items[i].quantity;
                        }
                    }
                }

                $scope.selectInternalSupplier = function (isFromTheViewPage) {
                    $purchaseOrder.getInernalSupplier($scope.currentShopDetail._id).then(function (response) {
                        $scope.invalidSupplier = false;
                        var internalSupplier = (response.data && response.data.data ? response.data.data : response.data);
                        $scope.selectedCompany = internalSupplier;
                        // calculateWithShopCompanies($scope.selectedCompany); // DEPRECATED
                        $scope.purchase_order.sendDetails = {
                            address: internalSupplier.address,
                            firstName: internalSupplier.name,
                            phone: internalSupplier.phone,
                            email: internalSupplier.email
                        }

                        if ($scope.purchase_order.sendDetails && $scope.purchase_order.sendDetails.phone) {
                            if ($scope.purchase_order.sendDetails.phone.length < 8 || $scope.purchase_order.sendDetails.phone.length > 12) {
                                $scope.purchase_order.sendDetails.mobilePhone = ""
                            }
                        }

                        $scope.hasSupplier = true;
                        if (isFromTheViewPage && $scope.purchase_order && $scope.purchase_order.items && $scope.purchase_order.items.length) {
                            $scope.purchase_order.company.name = internalSupplier.name;
                            $scope.purchase_order.company.nameSlug = internalSupplier.nameSlug;
                            $scope.purchase_order.company._id = internalSupplier._id;

                            var body = {
                                purchaseOrderId: $scope.purchase_order._id,
                                type: 'sendDetailsAndCompany',
                                key: 'sendDetails',
                                value: {
                                    sendDetails: $scope.purchase_order.sendDetails,
                                    company: {
                                        _id: $scope.selectedCompany._id,
                                        nameSlug: $scope.selectedCompany.nameSlug,
                                        name: $scope.selectedCompany.name
                                    }
                                },
                                shopId: $rootScope.currentShop._id
                            }
                            $purchaseOrder.updatePurchaseOrderByKey(body).then(function (addresres) {
                                console.log('selectInternalSupplier send details and Company updated successfully');
                            }).catch(function (error) {
                                console.log('updatePurchaseOrderByKey address error: ', error);
                            });
                        }
                    }).catch(function (error) {
                        $scope.localdata.loading = false;
                        console.error("Error while fetching internal supplier", error);
                        $scope.invalidShop = true;
                        $scope.invalidShopMessage = 'Internal server error while fetching the internal supplier';
                    });
                }

                $scope.changeInvoiceNumber = function () {
                    // console.log('lastInvoiceNumber: ', $scope.purchase_order.lastInvoiceNumber);
                    $scope.inverr = false
                    var body = {
                        purchaseOrderId: $scope.purchase_order._id,
                        lastInvoiceNumber: $scope.purchase_order.lastInvoiceNumber || '',
                        newInvoiceNumber: $scope.purchase_order.invoiceNumber,
                        eType: 'retailer',
                        status: $scope.purchase_order.status[0].status,
                        isShow: true
                    }
                    // console.log('changeInvoiceNumber body: ', body);
                    $purchaseOrder.changeInvoiceNumber(body, $rootScope.currentShop._id, undefined).then(function (res) {
                        $scope.localdata.loading = false;
                        // console.log('GetExistingPurchaseOrder($scope.limit) called: ', 10);
                        $scope.GetExistingPurchaseOrder($scope.limit);
                    }).catch(function (err) {
                        // console.log('errr: ', err);
                        $scope.localdata.loading = false;
                        $scope.inverr = true;
                        $scope.invmessage = 'Something went wrong';
                        if (err && err.data && err.data.message) {
                            $scope.invmessage = err.data.message;
                        }
                    })
                }

                function enableDisableFieldOfInput(isUpdating) {
                    if ($scope.purchase_order && $scope.purchase_order.items && $scope.purchase_order.items.length) {
                        $scope.purchase_order.items[$scope.localdata.itemIndex].isUpdating = isUpdating;
                    }
                }

                $scope.changeEAN = function (index, ean) {
                    if (!ean) { $scope.purchase_order.items[index].isInvalidEAN = false; return; }
                    $scope.purchase_order.items[index].isInvalidEAN = true;
                    if ($purchaseOrder.isValidEAN(ean, $scope.purchase_order.items)) {
                        $scope.purchase_order.items[index].isInvalidEAN = false;
                        var body = {
                            purchaseOrderId: $scope.purchase_order._id,
                            itemId: $scope.purchase_order.items[index]._id,
                            ean: ean,
                            shopId: $rootScope.currentShop._id
                        }
                        console.log('changeEAN: ', body);
                        $scope.localdata.itemIndex = index;
                        enableDisableFieldOfInput(true);
                        $purchaseOrder.changeEAN(body).then(function (res) {
                            enableDisableFieldOfInput(false);
                        }).catch(function (err) {
                            enableDisableFieldOfInput(false);
                            console.log('error in chagne EAN: ', err);
                        })
                    }
                }

                $scope.validateEAN = function (evt) {
                    var theEvent = evt || window.event;
                    // console.log('theEvent.type: ', theEvent.type);
                    // Handle paste
                    if (theEvent.type === 'paste') {
                        key = event.clipboardData.getData('text/plain');
                    } else {
                        // Handle key press
                        var key = theEvent.keyCode || theEvent.which;
                        key = String.fromCharCode(key);
                    }
                    var regex = /[0-9]|\./;
                    if (!regex.test(key)) {
                        theEvent.returnValue = false;
                        if (theEvent.preventDefault) theEvent.preventDefault();
                    }
                }

                $scope.changePrice = function (index, priceIncVat, lastPrice, item, isChangingFromViewPage) {
                    console.log('---------------------- inside changePrice changePrice!', lastPrice, item);
                    $scope.cancelItem();
                    console.log('$scope.lastEntryMethodCustomerValue ', $scope.lastEntryMethodCustomerValue);
                    enableDisableFieldOfInput(true);
                    if (!priceIncVat) priceIncVat = 0;
                    var temppriceIncVat = priceIncVat;
                    //-if (typeof temppriceIncVat === 'string') temppriceIncVat = parseFloat(temppriceIncVat.replace(',', '.'));
                    if (typeof priceIncVat === 'string') priceIncVat = parseFloat(priceIncVat.replace(',', '.'));
                    var price = priceIncVat;
                    // convertToFloat(index);
                    // if (typeof priceIncVat === 'string') price = parseFloat(priceIncVat.replace(',', '.'));
                    // item.priceIncVat = priceIncVat = price;                                          
                    // console.log(typeof priceIncVat === 'string', typeof priceIncVat == 'string')
                    // console.log('changePrice" ', priceIncVat, typeof priceIncVat, lastPrice, isChangingFromViewPage);
                    // console.log('item: ', item);
                    // return;
                    // console.log(JSON.stringify($scope.purchase_order.items[index], null, 2));
                    $scope.localdata.itemIndex = index;
                    // item = $purchaseOrder.convertAllIntegerToDecimal(item, 'pricechange');
                    var body = {
                        index: index,
                        priceIncVat: priceIncVat,
                        lastPrice: lastPrice,
                        purchase_order: JSON.parse(JSON.stringify($scope.purchase_order)),
                        eType: 'retailer',
                        isChangingFromViewPage: isChangingFromViewPage ? true : false,
                        lastEntryMethodCustomerValue: $scope.lastEntryMethodCustomerValue ? true : false,
                        isReceivedQuantityChangedAfterFocusingPrice: ($scope.focusIndex !== undefined ? true : false) // Its for focus after send qnt to price
                    }

                    if (body.isReceivedQuantityChangedAfterFocusingPrice) {
                        var bodyReceivedQuantity = {
                            purchaseOrderId: body.purchase_order._id,
                            purchaseOrderItemId: body.purchase_order.items[index]._id,
                            receivedQuantity: item.addreceivedQuantity, // Which we received at that time
                            currentStock: 0,
                            beforeRecieved: item.receivedQuantity,
                            afterReceieved: (+item.receivedQuantity) + (+item.addreceivedQuantity),
                            isApplied: false,
                            createdDate: Date.now(),
                            action: 'Edited',
                            status: body.purchase_order.status[0].status,
                            isReceivedQuantityChangedAfterFocusingPrice: body.isReceivedQuantityChangedAfterFocusingPrice
                        }
                        body.bodyReceivedQuantity = bodyReceivedQuantity;
                    } else {
                        body.bodyReceivedQuantity = {
                            isReceivedQuantityChangedAfterFocusingPrice: body.isReceivedQuantityChangedAfterFocusingPrice
                        }
                    }
                    // if ($scope.focusIndex !== undefined) {
                    // 	$scope.calculationsOfReceivedQuantity(item, index, false);
                    // $scope.localdata.loading = true;

                    // console.log('-------- $purchaseOrder.changePrice!!');
                    // var oldPurchasePrice = $scope.purchase_order.items[index].purchasePrice;

                    // if($scope.entryMethodCustomerValue){
                    //     console.log('-------- Inside if!!');
                    //     console.log(JSON.parse(JSON.stringify($scope.purchase_order.items[index].discount.value)));

                    //     var pPrice = priceIncVat/ JSON.parse(JSON.stringify($scope.purchase_order.items[index].discount.value));
                    //     $scope.purchase_order.items[index].purchasePrice = pPrice;
                    // }

                    // var calculatedValue = (priceIncVat / (1 + ($scope.purchase_order.items[index].priceVat / 100)) / oldPurchasePrice);
                    // console.log('CalculatedValue :', calculatedValue);
                    // $scope.purchase_order.items[index].storageFactor = calculatedValue;
                    // $scope.purchase_order.items[index].price = (priceIncVat /(1 + ($scope.purchase_order.items[index].priceVat / 100)));
                    // item.isUpdating = false;
                    var index = body.index
                    var purchase_order = body.purchase_order
                    var item = JSON.parse(JSON.stringify(purchase_order.items[index]))
                    var lastEntryMethodCustomerValue = body.lastEntryMethodCustomerValue
                    var priceIncVat = body.priceIncVat
                    var lastPrice = body.lastPrice // Ex Vat
                    var isChangingFromViewPage = body.isChangingFromViewPage
                    var price = priceIncVat / ((item.priceVat / 100) + 1) // To save price ex vat
                    // console.log('price ', price);
                    if (price != undefined || price != null && price != lastPrice) {
                        var bodyPrice = $purchaseOrder.getBodyOfChangePrice(body)
                        // console.log('bodyPrice ', bodyPrice);
                        var bodySendQuantity = $purchaseOrder.getBodyOfChangeSendQuantity(body) // For the SEND QNT FROM THE COMPANY
                        // console.log('bodySendQuantity ', bodySendQuantity);
                        if (isChangingFromViewPage) {
                            body.isChangingFromViewPage = false // as only price change not purchasePrice, disccoutn and factor
                            var objectPriceChange = $purchaseOrder.calculateAllValueOnPriceChange(lastEntryMethodCustomerValue, priceIncVat, item) // calculate all changes
                            // console.log('objectPriceChange ', objectPriceChange);
                            body.addedPurchasePrice = objectPriceChange.updatedPurchasePrice
                            body.lastPurchasePrice = item.purchasePrice
                            var bodyPurchaePrice = $purchaseOrder.getBodyOfChangePurchasePrice(body)
                            // console.log('bodyPurchaePrice ', bodyPurchaePrice);

                            body.AddedDiscount = objectPriceChange.updatedMargin
                            body.lastAddedDiscount = item.discount.value
                            var bodyMargin = $purchaseOrder.getBodyOfChangeMargin(body)
                            // console.log('bodyMargin ', bodyMargin);

                            body.addedStorageFactor = objectPriceChange.updatedStorageFactor
                            body.lastStorageFactor = item.storageFactor
                            var bodyStorageFactor = $purchaseOrder.getBodyOfChangeStorageFactor(body)
                            // console.log('bodyStorageFactor ', bodyStorageFactor);
                        }

                        var bodyReceivedQuantity = {
                            isReceivedQuantityChangedAfterFocusingPrice: false
                        }
                        if (body.bodyReceivedQuantity) bodyReceivedQuantity = body.bodyReceivedQuantity

                        var finalbody = {
                            bodyPrice: bodyPrice,
                            bodyPurchaePrice: bodyPurchaePrice,
                            bodyMargin: bodyMargin,
                            bodyStorageFactor: bodyStorageFactor,
                            bodySendQuantity: bodySendQuantity, // For Focus price updatd from company(DEFAULT FALSE)
                            bodyReceivedQuantity: bodyReceivedQuantity, // For Focus price updatd from Retailer(DEFAULT FALSE)
                            shopId: $rootScope.currentShop._id
                        }

                        item.lastAddedPrice = angular.copy(item.price);
                        item.lastPriceIncVat = angular.copy(item.priceIncVat);
                        item.purchasePrice = parseFloat(parseFloat(finalbody.bodyPurchaePrice.addedPurchasePrice).toFixed(3));
                        item.storageFactor = parseFloat(parseFloat(finalbody.bodyStorageFactor.addedStorageFactor).toFixed(3));
                        item.priceIncVat = temppriceIncVat;
                        item.price = (priceIncVat) / (1 + ($scope.purchase_order.items[index].priceVat / 100));
                        item.discount.value = parseFloat(parseFloat(finalbody.bodyMargin.AddedDiscount.value).toFixed(3));
                        $scope.purchase_order.items[index] = JSON.parse(JSON.stringify(item));
                        // totalValuesCalculation();
                        var delayInMilliseconds = 2000; //2 second
                        // convertToFloat(index);
                        $scope.changePriceBody = finalbody;
                        $scope.changePriceBody.lastEntryMethodCustomerValue = $scope.lastEntryMethodCustomerValue;
                        setTimeout(function () {
                            $purchaseOrder.changePrice($scope.changePriceBody).then(function (res) {
                                console.log('-------------- res!!');
                                console.log(res);
                                $scope.purchase_order.items[index].lastPrice = $scope.purchase_order.items[index].price;
                                // $scope.localdata.loading = false;
                                // emptyAllUpdatedData();
                                // console.log('GetExistingPurchaseOrder($scope.limit) called: ', 11);
                                //$scope.GetExistingPurchaseOrder($scope.limit);
                                enableDisableFieldOfInput(false);
                                // $scope.GetExistingPurchaseOrderItemHistory(10, 0, 0);
                            }).catch(function (err) {
                                enableDisableFieldOfInput(false);
                                console.log('error in chagne price: ', err);
                                // $scope.localdata.loading = false;
                                $scope.isErrorOccured = true;
                                $scope.errorMsg = 'Something went wrong';
                            })
                        }, delayInMilliseconds);
                    }
                }

                $scope.changeDiscount = function (index, item, value, lastValue, isSwitch, isChangingFromViewPage) {
                    console.log('----------------------------------- changeDiscount!!');
                    $scope.cancelItem();
                    convertToFloat(index);
                    if (!value) value = 0;
                    var tempValue = angular.copy(value);
                    enableDisableFieldOfInput(true);
                    // tempValue = tempValue.replace(',', '.')
                    // console.log($scope.purchase_order.items[index]);
                    // console.log('---------------------------------------------------------------------------!!');
                    // console.log(JSON.stringify($scope.purchase_order.items[index].purchasePrice, null, 2));
                    // console.log(JSON.stringify($scope.purchase_order.items[index].storageFactor, null, 2));
                    // console.log(JSON.stringify($scope.purchase_order.items[index].priceIncVat, null, 2));
                    // console.log(JSON.stringify($scope.purchase_order.items[index].price, null, 2));

                    $scope.purchase_order.items[index].lastAddedDiscount = angular.copy(item.discount);
                    item.lastAddedDiscount = angular.copy(item.discount);

                    // if($scope.entryMethodCustomerValue){
                    // console.log('-------- Inside if!!');
                    // $scope.purchase_order.items[index].discount.value = value;
                    // item.discount.value = value;
                    // //var price = $scope.purchase_order.items[index].price;
                    // console.log('$scope.purchase_order.items[index].price ', $scope.purchase_order.items[index].price);
                    // console.log('($scope.purchase_order.items[index].price (1 + ($scope.purchase_order.items[index].priceVat / 100))) ', ($scope.purchase_order.items[index].price (1 + ($scope.purchase_order.items[index].priceVat / 100))));
                    // console.log('$scope.purchase_order.items[index].discount.value ', $scope.purchase_order.items[index].discount.value);
                    // var pPrice = (($scope.purchase_order.items[index].price * (1 + ($scope.purchase_order.items[index].priceVat / 100))) / $scope.purchase_order.items[index].discount.value);
                    // console.log('pPrice', pPrice);
                    // $scope.purchase_order.items[index].purchasePrice = pPrice;
                    // // item.lastPurchasePrice = pPrice;
                    // }


                    // //$scope.localdata.loading = true;
                    // //item.isUpdating = false;


                    var margin = value;
                    if (margin && margin.trim() == "") {
                        return;
                    }
                    if (margin && margin.indexOf(',') !== -1) margin = margin.replace(',', '.');
                    if (margin && margin.indexOf('.') != margin.lastIndexOf('.')) {
                        var tempVal = margin.split(/\.(?=[^\.]+$)/);
                        margin = tempVal[0];
                    }
                    if (typeof margin === 'string') margin = parseFloat(margin);
                    value = margin;
                    item.discount.value = margin;

                    $scope.localdata.itemIndex = index;
                    item = $purchaseOrder.convertAllIntegerToDecimal(item, 'marginchange');
                    // console.log('CONVERTED: ', JSON.parse(JSON.stringify(item)));
                    // return;
                    var fromStatus = 'New';
                    if ($scope.mystatus == 2) fromStatus = 'Processing';
                    else if ($scope.mystatus == 1.5) fromStatus = 'Backorder';
                    else if ($scope.mystatus == 3) fromStatus = 'Inspection';
                    else if ($scope.mystatus == 4) fromStatus = 'Completed';
                    else if ($scope.mystatus == 5) fromStatus = 'Cancelled';
                    $scope.purchase_order.status[0].status = fromStatus
                    var body = {
                        index: index,
                        purchase_order: JSON.parse(JSON.stringify($scope.purchase_order)),
                        AddedDiscount: value,
                        lastAddedDiscount: lastValue,
                        eType: 'retailer',
                        isChangingFromViewPage: isChangingFromViewPage ? true : false,
                        lastEntryMethodCustomerValue: $scope.lastEntryMethodCustomerValue ? true : false
                    }

                    var purchase_order = body.purchase_order
                    var item = purchase_order.items[index]
                    var lastEntryMethodCustomerValue = $scope.lastEntryMethodCustomerValue ? true : false
                    var isChangingFromViewPage = isChangingFromViewPage ? true : false
                    var eType = 'retailer'
                    var AddedDiscount = value
                    var lastAddedDiscount = lastValue

                    if (AddedDiscount != undefined || AddedDiscount != null && AddedDiscount != lastAddedDiscount) {
                        var bodyMargin = $purchaseOrder.getBodyOfChangeMargin(body)
                        if (isChangingFromViewPage) {
                            body.isChangingFromViewPage = false // as only MARGIN WILL CHANGE SO OTHER ITS NEEDS TO BE FALSE
                            var objectDiscountChange = $purchaseOrder.calculateAllValueOnMarginChange(lastEntryMethodCustomerValue, AddedDiscount, item) // calculate all changes
                            if (lastEntryMethodCustomerValue) {
                                body.addedPurchasePrice = objectDiscountChange.updatedPurchasePrice
                                body.lastPurchasePrice = item.purchasePrice
                                var bodyPurchaePrice = $purchaseOrder.getBodyOfChangePurchasePrice(body)
                            } else {
                                body.priceIncVat = objectDiscountChange.updatedPrice
                                body.lastPrice = item.price
                                var bodyPrice = $purchaseOrder.getBodyOfChangePrice(body)
                            }

                            body.addedStorageFactor = objectDiscountChange.updatedStorageFactor
                            body.lastStorageFactor = item.storageFactor
                            var bodyStorageFactor = $purchaseOrder.getBodyOfChangeStorageFactor(body)
                        }

                        var finalbody = {
                            bodyMargin: bodyMargin,
                            bodyStorageFactor: bodyStorageFactor,
                            shopId: $rootScope.currentShop._id
                        }
                        if (lastEntryMethodCustomerValue) {
                            finalbody.bodyPurchaePrice = bodyPurchaePrice
                            finalbody.bodyPrice = { lastEntryMethodCustomerValueFromDiscount: false }
                        } else {
                            finalbody.bodyPrice = bodyPrice
                            finalbody.bodyPurchaePrice = { lastEntryMethodCustomerValueFromDiscount: false }
                        }

                        item.purchasePrice = parseFloat(parseFloat(finalbody.bodyPurchaePrice.addedPurchasePrice).toFixed(3));
                        item.storageFactor = parseFloat(parseFloat(finalbody.bodyStorageFactor.addedStorageFactor).toFixed(3));
                        item.discount.value = tempValue;
                        $scope.purchase_order.items[index] = JSON.parse(JSON.stringify(item));
                        // totalValuesCalculation();
                        var delayInMilliseconds = 2000; //2 second
                        $scope.changeDiscountBody = finalbody;
                        $scope.changeDiscountBody.lastEntryMethodCustomerValue = $scope.lastEntryMethodCustomerValue;
                        if (finalbody.bodyMargin.AddedDiscount.value == finalbody.bodyMargin.lastAddedDiscount.value) return;
                        setTimeout(function () {
                            $purchaseOrder.changeDiscount($scope.changeDiscountBody).then(function (res) {
                                // $scope.localdata.loading = false;
                                // $scope.GetExistingPurchaseOrder($scope.limit);
                                enableDisableFieldOfInput(false);
                                // $scope.GetExistingPurchaseOrderItemHistory(10, 0, 0);
                            }).catch(function (err) {
                                enableDisableFieldOfInput(false);
                                console.log('error in chagne marign: ', err);
                                $scope.localdata.loading = false;
                                $scope.isErrorOccured = true;
                                $scope.errorMsg = 'Something went wrong';
                            })
                        }, delayInMilliseconds);
                    }
                }

                function convertToFloat(index) {

                    var pPrice = angular.copy($scope.purchase_order.items[index].purchasePrice);
                    if (typeof pPrice === 'string') $scope.purchase_order.items[index].purchasePrice = parseFloat(pPrice.replace(',', '.'));

                    var discount = angular.copy($scope.purchase_order.items[index].discount.value);
                    if (typeof discount === 'string') $scope.purchase_order.items[index].discount.value = parseFloat(discount.replace(',', '.'));

                    var storageFactor = angular.copy($scope.purchase_order.items[index].storageFactor);
                    if (typeof storageFactor === 'string') $scope.purchase_order.items[index].storageFactor = parseFloat(storageFactor.replace(',', '.'));

                    var priceIncVat = angular.copy($scope.purchase_order.items[index].priceIncVat);
                    if (typeof priceIncVat === 'string') $scope.purchase_order.items[index].priceIncVat = parseFloat(priceIncVat.replace(',', '.'));

                    var price = angular.copy($scope.purchase_order.items[index].price);
                    if (typeof price === 'string') $scope.purchase_order.items[index].price = parseFloat(price.replace(',', '.'));
                }

                $scope.changePriceVat = function (index, item, priceVat, lastPriceVat, isChangingFromViewPage) {
                    console.log('------------------------------- changePriceVat!!');
                    console.log('priceVat ', priceVat);
                    $scope.localdata.itemIndex = index;
                    enableDisableFieldOfInput(true);
                    item = $purchaseOrder.convertAllIntegerToDecimal(item, 'pricevatchange');
                    var body = {
                        index: index,
                        purchase_order: JSON.parse(JSON.stringify($scope.purchase_order)),
                        lastPriceVat: lastPriceVat || 0,
                        addedPriceVat: priceVat,
                        eType: 'retailer',
                        isChangingFromViewPage: isChangingFromViewPage ? true : false,
                        lastEntryMethodCustomerValue: $scope.lastEntryMethodCustomerValue ? true : false
                    }


                    var purchase_order = body.purchase_order
                    var item = purchase_order.items[index]
                    var lastEntryMethodCustomerValue = body.lastEntryMethodCustomerValue
                    var isChangingFromViewPage = body.isChangingFromViewPage
                    var eType = body.eType
                    var lastPriceVat = body.lastPriceVat || 0
                    var addedPriceVat = body.addedPriceVat

                    if (addedPriceVat != undefined || addedPriceVat != null && addedPriceVat != lastPriceVat) {
                        var bodyPriceVat = $purchaseOrder.getBodyOfChangePriceVat(body)

                        if (isChangingFromViewPage) {
                            body.isChangingFromViewPage = false // as only PRICEVAT WILL CHANGE SO OTHER ITS NEEDS TO BE FALSE
                            var objectPriceVatChange = $purchaseOrder.calculateAllValueOnPriceVatChange(lastEntryMethodCustomerValue, addedPriceVat, lastPriceVat, item) // calculate all changes

                            if (lastEntryMethodCustomerValue) {
                                body.priceIncVat = objectPriceVatChange.updatedPrice
                                body.lastPrice = item.price
                                var bodyPrice = $purchaseOrder.getBodyOfChangePrice(body)

                                body.addedStorageFactor = objectPriceVatChange.updatedStorageFactor || 0;
                                body.lastStorageFactor = item.storageFactor || 0;
                                var bodyStorageFactor = $purchaseOrder.getBodyOfChangeStorageFactor(body)
                            } else {
                                body.AddedDiscount = objectPriceVatChange.updatedMargin
                                body.lastAddedDiscount = item.discount.value
                                var bodyMargin = $purchaseOrder.getBodyOfChangeMargin(body)

                                body.priceIncVat = objectPriceVatChange.updatedPriceIncVat
                                body.lastPrice = item.price
                                var bodyPrice = $purchaseOrder.getBodyOfChangePrice(body)

                                body.addedStorageFactor = objectPriceVatChange.updatedStorageFactor
                                body.lastStorageFactor = item.storageFactor
                                var bodyStorageFactor = $purchaseOrder.getBodyOfChangeStorageFactor(body)
                            }
                        }

                        var finalbody = {
                            bodyPriceVat: bodyPriceVat,
                            shopId: $rootScope.currentShop._id
                        }
                        if (lastEntryMethodCustomerValue) {
                            finalbody.bodyPrice = bodyPrice
                            finalbody.bodyStorageFactor = bodyStorageFactor
                            finalbody.bodyMargin = { lastEntryMethodCustomerValueFromPriceVat: false }
                        } else {
                            finalbody.bodyMargin = bodyMargin
                            finalbody.bodyPrice = bodyPrice
                            finalbody.bodyStorageFactor = bodyStorageFactor
                        }

                        item.storageFactor = bodyStorageFactor.addedStorageFactor;
                        // return;
                        $scope.purchase_order.items[index] = JSON.parse(JSON.stringify(item));
                        var delayInMilliseconds = 2000; //2 second
                        $scope.changePriceVatBody = finalbody;
                        $scope.changePriceVatBody.lastEntryMethodCustomerValue = $scope.lastEntryMethodCustomerValue;
                        setTimeout(function () {
                            $purchaseOrder.changePriceVat($scope.changePriceVatBody).then(function (res) {
                                // $scope.localdata.loading = false;
                                enableDisableFieldOfInput(false);
                                // $scope.GetExistingPurchaseOrder($scope.limit);
                                // $scope.GetExistingPurchaseOrderItemHistory(10, 0, 0);
                            }).catch(function (err) {
                                enableDisableFieldOfInput(false);
                                console.log('error in chagne pricevat: ', err);
                                $scope.localdata.loading = false;
                                $scope.isErrorOccured = true;
                                $scope.errorMsg = 'Something went wrong';
                            })
                        }, delayInMilliseconds);
                    }
                }

                $scope.changePurchasePrice = function (index, item, addedPurchasePrice, lastPurchasePrice, isChangingFromViewPage) {
                    console.log('---------------------------- changePurchasePrice!!');

                    $scope.cancelItem();
                    console.log('$scope.lastEntryMethodCustomerValue ', $scope.lastEntryMethodCustomerValue);
                    enableDisableFieldOfInput(true);
                    convertToFloat(index);
                    if (!addedPurchasePrice) addedPurchasePrice = 0;
                    var tempAddedPurchasePrice = angular.copy(addedPurchasePrice);
                    if (typeof tempAddedPurchasePrice === 'string') tempAddedPurchasePrice = parseFloat(tempAddedPurchasePrice.replace(',', '.'));
                    if (!lastPurchasePrice) lastPurchasePrice = 0;
                    if (typeof lastPurchasePrice === 'string') lastPurchasePrice = parseFloat(lastPurchasePrice.replace(',', '.'));
                    console.log('addedPurchasePrice ', angular.copy(addedPurchasePrice));
                    var purchasePrice = addedPurchasePrice;
                    console.log('purchasePrice ', angular.copy(purchasePrice));
                    item.purchasePrice = purchasePrice;
                    console.log('item.purchasePrice ', angular.copy(item.purchasePrice));
                    if (typeof addedPurchasePrice === 'string') purchasePrice = parseFloat(addedPurchasePrice.replace(',', '.'));
                    addedPurchasePrice = purchasePrice;
                    console.log('addedPurchasePrice ', angular.copy(addedPurchasePrice));
                    $scope.localdata.itemIndex = index;
                    item = $purchaseOrder.convertAllIntegerToDecimal(item, 'purchasepricechange');
                    // $scope.localdata.loading = true;
                    // return;

                    // if(!$scope.entryMethodCustomerValue){
                    //     console.log('---------------------------- entryMethodCustomerValue false !!!');
                    //     var multiplier = (($scope.purchase_order.items[index].storageFactor/ 100) * $scope.purchase_order.items[index].priceVat) + $scope.purchase_order.items[index].storageFactor;
                    //     console.log(multiplier);
                    //     $scope.purchase_order.items[index].priceIncVat = $scope.purchase_order.items[index].purchasePrice * multiplier;
                    //     $scope.purchase_order.items[index].purchasePrice = addedPurchasePrice;
                    // }

                    // if($scope.entryMethodCustomerValue){
                    //     console.log('---------------------------- entryMethodCustomerValue true !!!');
                    //     console.log(JSON.parse(JSON.stringify($scope.purchase_order.items[index].discount.value)));

                    //     var newPrice = addedPurchasePrice * JSON.parse(JSON.stringify($scope.purchase_order.items[index].discount.value));
                    //     $scope.purchase_order.items[index].priceIncVat = newPrice;
                    //     $scope.purchase_order.items[index].purchasePrice = addedPurchasePrice;;
                    // }

                    // item.isUpdating = false;
                    console.log($scope.lastEntryMethodCustomerValue);
                    var body = {
                        index: index,
                        addedPurchasePrice: addedPurchasePrice,
                        lastPurchasePrice: lastPurchasePrice,
                        purchase_order: JSON.parse(JSON.stringify($scope.purchase_order)),
                        eType: 'retailer',
                        isChangingFromViewPage: isChangingFromViewPage,
                        lastEntryMethodCustomerValue: $scope.lastEntryMethodCustomerValue,
                        purchaseOrderItemId: item._id
                    }

                    // addedPurchasePrice = addedPurchasePrice / ((purchase_order.items[index].priceVat / 100) + 1); // To save addedPurchasePrice ex vat
                    var index = body.index
                    var purchase_order = body.purchase_order
                    var item = purchase_order.items[index]
                    var lastEntryMethodCustomerValue = body.lastEntryMethodCustomerValue
                    var isChangingFromViewPage = body.isChangingFromViewPage
                    var addedPurchasePrice = body.addedPurchasePrice
                    var lastPurchasePrice = body.lastPurchasePrice
                    var eType = body.eType
                    // addedPurchasePrice = addedPurchasePrice / ((purchase_order.items[index].priceVat / 100) + 1); // To save addedPurchasePrice ex vat

                    if (addedPurchasePrice != undefined || addedPurchasePrice != null && addedPurchasePrice != lastPurchasePrice) {
                        var bodyPurchaePrice = $purchaseOrder.getBodyOfChangePurchasePrice(body)

                        if (isChangingFromViewPage) {
                            body.isChangingFromViewPage = false // as only price change not purchasePrice, disccoutn and factor
                            var objectPurchasePriceChange = $purchaseOrder.calculateAllValueOnPurchasePriceChange(lastEntryMethodCustomerValue, addedPurchasePrice, item) // calculate all changes
                            body.priceIncVat = objectPurchasePriceChange.updatedPrice
                            body.lastPrice = item.price
                            var bodyPrice = $purchaseOrder.getBodyOfChangePrice(body)
                            body.AddedDiscount = objectPurchasePriceChange.updatedMargin
                            body.lastAddedDiscount = item.discount.value
                            var bodyMargin = $purchaseOrder.getBodyOfChangeMargin(body)
                            body.addedStorageFactor = objectPurchasePriceChange.updatedStorageFactor
                            body.lastStorageFactor = item.storageFactor
                            var bodyStorageFactor = $purchaseOrder.getBodyOfChangeStorageFactor(body)
                        }

                        var finalbody = {
                            bodyPurchaePrice: bodyPurchaePrice,
                            bodyPrice: bodyPrice,
                            bodyMargin: bodyMargin,
                            bodyStorageFactor: bodyStorageFactor,
                            shopId: $rootScope.currentShop._id
                        }

                        item.lastPurchasePrice = angular.copy(item.purchasePrice);
                        ///-item.purchasePrice = tempAddedPurchasePrice;
                        item.storageFactor = parseFloat(parseFloat(finalbody.bodyStorageFactor.addedStorageFactor).toFixed(3));
                        item.discount.value = parseFloat(parseFloat(finalbody.bodyMargin.AddedDiscount.value).toFixed(3));
                        item.priceIncVat = parseFloat(parseFloat(finalbody.bodyPrice.priceIncVat.toFixed(3)));
                        item.price = parseFloat(parseFloat((finalbody.bodyPrice.priceIncVat / (1 + ($scope.purchase_order.items[index].priceVat / 100))).toFixed(3)));
                        // totalValuesCalculation();



                        $scope.purchase_order.items[index] = JSON.parse(JSON.stringify(item));




                        var delayInMilliseconds = 2000; //2 second
                        $scope.changePurchasePriceBody = finalbody;
                        $scope.changePurchasePriceBody.lastEntryMethodCustomerValue = $scope.lastEntryMethodCustomerValue;
                        setTimeout(function () {
                            $purchaseOrder.changePurchasePrice($scope.changePurchasePriceBody).then(function (res) {
                                $scope.localdata.loading = false;
                                enableDisableFieldOfInput(false);
                                // console.log('GetExistingPurchaseOrder($scope.limit) called: ', 14);
                                // $scope.GetExistingPurchaseOrder($scope.limit);
                                // $scope.GetExistingPurchaseOrderItemHistory(10, 0, 0);
                            }).catch(function (err) {
                                enableDisableFieldOfInput(false);
                                $scope.localdata.loading = false;
                                $scope.isErrorOccured = true;
                                $scope.errorMsg = 'Something went wrong';
                            })
                        }, delayInMilliseconds);
                    }
                }

                $scope.changeStorageFactor = function (index, item, addedStorageFactor, lastStorageFactor, isChangingFromViewPage) {
                    item.isSaved = false;
                    $scope.cancelItem();

                    convertToFloat(index);
                    enableDisableFieldOfInput(true);
                    if (!addedStorageFactor) addedStorageFactor = 0;
                    var tempStorageFactor = angular.copy(addedStorageFactor);
                    // tempStorageFactor = tempStorageFactor.replace(',', '.')
                    var storageFactor = addedStorageFactor;
                    if (typeof addedStorageFactor === 'string') storageFactor = parseFloat(addedStorageFactor.replace(',', '.'));
                    addedStorageFactor = storageFactor;
                    item.storageFactor = storageFactor;

                    $scope.localdata.itemIndex = index;
                    item = $purchaseOrder.convertAllIntegerToDecimal(item, 'storagefactorchange');
                    var body = {
                        index: index,
                        addedStorageFactor: addedStorageFactor,
                        lastStorageFactor: lastStorageFactor,
                        // confirm below line if any error occurs in this function
                        addedPurchasePrice: item.addedPurchasePrice,
                        purchase_order: JSON.parse(JSON.stringify($scope.purchase_order)),
                        eType: 'retailer',
                        lastEntryMethodCustomerValue: false,
                        isChangingFromViewPage: isChangingFromViewPage
                    }
                    // $scope.localdata.loading = true;
                    // var calculatedfactor =  addedStorageFactor * (1 + (   / 100));
                    // $scope.purchase_order.items[index].priceIncVat = calculatedfactor * $scope.purchase_order.items[index].purchasePrice;
                    var index = body.index
                    var purchase_order = body.purchase_order
                    var item = purchase_order.items[index]
                    var lastEntryMethodCustomerValue = body.lastEntryMethodCustomerValue
                    var isChangingFromViewPage = body.isChangingFromViewPage
                    var addedStorageFactor = body.addedStorageFactor
                    var lastStorageFactor = body.lastStorageFactor
                    var eType = body.eType

                    if (addedStorageFactor != undefined || addedStorageFactor != null && addedStorageFactor != lastStorageFactor) {
                        var bodyStorageFactor = $purchaseOrder.getBodyOfChangeStorageFactor(body)

                        if (isChangingFromViewPage) {
                            body.isChangingFromViewPage = false // as only price change not purchasePrice, disccoutn and factor
                            var objectStorageFactorChange = $purchaseOrder.calculateAllValueOnStorageFactorChange(lastEntryMethodCustomerValue, addedStorageFactor, item) // calculate all changes
                            if (lastEntryMethodCustomerValue) {
                                body.addedPurchasePrice = objectStorageFactorChange.updatedPurchasePrice
                                body.lastPurchasePrice = item.purchasePrice
                                var bodyPurchaePrice = $purchaseOrder.getBodyOfChangePurchasePrice(body)
                            } else {
                                body.priceIncVat = objectStorageFactorChange.updatedPrice
                                body.lastPrice = item.price
                                var bodyPrice = $purchaseOrder.getBodyOfChangePrice(body)
                            }

                            body.AddedDiscount = objectStorageFactorChange.updatedMargin
                            body.lastAddedDiscount = item.discount.value
                            var bodyMargin = $purchaseOrder.getBodyOfChangeMargin(body)
                        }

                        var finalbody = {
                            bodyStorageFactor: bodyStorageFactor,
                            bodyMargin: bodyMargin,
                            shopId: $rootScope.currentShop._id
                        }
                        if (lastEntryMethodCustomerValue) {
                            finalbody.bodyPurchaePrice = bodyPurchaePrice
                            finalbody.bodyPrice = { lastEntryMethodCustomerValueFromStorageFactor: false }
                        } else {
                            finalbody.bodyPrice = bodyPrice
                            finalbody.bodyPurchaePrice = { lastEntryMethodCustomerValueFromStorageFactor: false }
                        }

                        item.lastStorageFactor = angular.copy(item.storageFactor);
                        item.storageFactor = tempStorageFactor;
                        item.discount.value = parseFloat(parseFloat(finalbody.bodyMargin.AddedDiscount.value).toFixed(3));
                        item.priceIncVat = parseFloat(parseFloat(finalbody.bodyPrice.priceIncVat.toFixed(3)));
                        item.price = parseFloat(parseFloat((finalbody.bodyPrice.priceIncVat / (1 + ($scope.purchase_order.items[index].priceVat / 100))).toFixed(3)));
                        $scope.purchase_order.items[index] = JSON.parse(JSON.stringify(item));
                        var delayInMilliseconds = 2000; //2 second
                        $scope.changeStorageFactorBody = finalbody;
                        $scope.changeStorageFactorBody.lastEntryMethodCustomerValue = $scope.lastEntryMethodCustomerValue;
                        setTimeout(function () {
                            $purchaseOrder.changeStorageFactor($scope.changeStorageFactorBody).then(function (res) {
                                $scope.localdata.loading = false;
                                // console.log('GetExistingPurchaseOrder($scope.limit) called: ', 15);
                                //$scope.GetExistingPurchaseOrder($scope.limit);
                                enableDisableFieldOfInput(false);
                                // $scope.GetExistingPurchaseOrderItemHistory(10, 0, 0);
                            }).catch(function (err) {
                                enableDisableFieldOfInput(false);
                                $scope.localdata.loading = false;
                                $scope.isErrorOccured = true;
                                $scope.errorMsg = 'Something went wrong';
                            })
                        }, delayInMilliseconds);
                    }
                }

                /**
                 * ShopId to get Client group
                 * Its for prefilling discount of client group
                 */
                $scope.getShopClientGroups = function (shop_id, companyId) {
                    // console.log(" ---------- getShopClientGroups ----------- ", shop_id, companyId);
                    $clientGroup.getShopClientGroupsByCompany(shop_id, companyId)
                        .then(function (response) {
                            // console.log("get shop client groups", response);
                            if (response && response.data) {
                                $scope.shopsClientGroups = response.data;
                            }
                        })
                        .catch(function (error) {
                            console.error("Error in getting shop blocking client groups", error);
                        })
                }

                $scope.fetchInitialData = function () {

                    console.log('fetching Initial Data');
                    var isExistingPurchaseOrder = $rootScope.isPurchaseOrderNew ? false : true;
                    var shopBody = {
                        shopId: $rootScope.currentShop._id,
                        purchaseOrderNumber: $stateParams.orderNo
                    }
                    $scope.localdata.loading = true;
                    $purchaseOrder.getShopDetailForRetailerPurchaseOrder(shopBody).then(function (shop) {
                        getShopPrivateLabelCollections();
                        console.log('Hello SHOP DETAIL: ', shop.name);
                        $scope.localdata.loading = false;
                        $scope.currentShopDetail = shop;
                        $scope.purchase_order.shop = {
                            _id: shop._id,
                            nameSlug: shop.nameSlug,
                            name: shop.name
                        }
                        // console.log('GetExistingPurchaseOrder($scope.limit) called: ', 16);
                        if (isExistingPurchaseOrder) $scope.GetExistingPurchaseOrder($scope.limit);
                        $scope.getShopCompanies();
                        $scope.getTaxes();
                        if (checkIsInternalSupplier()) $scope.selectInternalSupplier();
                        // if ($scope.selectedCompany) calculateWithShopCompanies($scope.selectedCompany); // DEPRECATED
                        /*  setTimeout(function () {
                             fetchCompanyBrandsForPurchaseOrder();
                         }, 1000); */
                    }).catch(function (shoperr) {
                        console.log('something went wrong while getting shop detail for purchase order: ', shoperr);
                        $scope.localdata.loading = false;
                        // // console.log('GetExistingPurchaseOrder($scope.limit) called: ', 17);
                        // if (isExistingPurchaseOrder) $scope.GetExistingPurchaseOrder($scope.limit);
                    })
                }

                // ------------------------------------FUNCTION FOR ALL CALCULATION START ---------------------------------------
                /**
                 * Prefilling the Price, PurchasePrice, Margin, storageFactor and Entrycustomervalue when adding new product into purchase order
                 * suggestedRetailPrice → purchaseOrderItem.price
                 * company.product.price → purchaseOrderItem.purchasePrice
                 */
                function prefillTheValueWhenAddingProduct() {
                    console.log('PREFILL PRODUCT EXIST IN DB', $scope.prefillPurchaseOrderItemData);
                    var prefillCompanySettings = true;
                    var prefillData = $scope.prefillPurchaseOrderItemData;
                    var shopCompanyDetail = ($scope.selectedCompany && $scope.selectedCompany.shopCompanyDetail ? $scope.selectedCompany.shopCompanyDetail : {});

                    if (shopCompanyDetail.prefillCompanySettings === false) {
                        prefillCompanySettings = false;
                    }

                    // If product.suggestedRetailerPrice and company.product.price are available
                    if (prefillCompanySettings && prefillData && prefillData.companyProduct && prefillData.companyProduct.price && prefillData.suggestedRetailPrice) {
                        $scope.prefillPurchaseOrderItemData = $prefillCalculationForPurchasOrderAndCashRegister.calculateWhenBothPriceAvailableInCompanyProduct(prefillData, $scope.firstPriceVat);
                        console.log('ITS FIRST METHOD');
                        fetchProductDiscountUsingClientGroup();
                    } else if (prefillCompanySettings && $scope.shopsClientGroups) {
                        $scope.prefillPurchaseOrderItemData = $prefillCalculationForPurchasOrderAndCashRegister.calculateWhenClientGroupAvailable(prefillData, $scope.firstPriceVat, $scope.shopsClientGroups);
                        console.log('ITS SECOND METHOD');
                        fetchProductDiscountUsingClientGroup();
                    } else if (prefillData && prefillData.shopProduct && prefillData.shopProduct.price && prefillData.shopProduct.purchasePrice) {
                        $scope.prefillPurchaseOrderItemData = $prefillCalculationForPurchasOrderAndCashRegister.calculateWhenBothPriceAvailableInShopProduct(prefillData, $scope.firstPriceVat);
                        console.log('ITS THIRD METHOD');
                    } else { // Default value
                        console.log('ITS FOURTH METHOD');
                        $scope.prefillPurchaseOrderItemData = $prefillCalculationForPurchasOrderAndCashRegister.calculateUsingShopCompanyDetailForPurchseOrder(prefillData, shopCompanyDetail);
                    }
                }

                function fetchProductDiscountUsingClientGroup() {
                    return $q(function (resolve, reject) {

                        try {
                            console.log($scope.offerItem, $scope.purchase_order, $scope.prefillPurchaseOrderItemData, $scope.shopsClientGroups)
                            $scope.loading = true;
                            var companyId = $scope.purchase_order && $scope.purchase_order.company && $scope.purchase_order.company._id ? $scope.purchase_order.company._id : $scope.selectedCompany && $scope.selectedCompany._id ? $scope.selectedCompany._id : undefined;
                            var body = {
                                productId: $scope.offerItem.product_id,
                                companyId: companyId,
                                purchasePrice: $scope.prefillPurchaseOrderItemData.purchasePrice,
                                clientGroupId: $scope.shopsClientGroups && $scope.shopsClientGroups._id ? $scope.shopsClientGroups._id : undefined,
                                shopId: $rootScope.currentShop._id
                            }

                            $purchaseOrder.fetchProductDiscountUsingClientGroup(body).then(function (response) {
                                $scope.loading = false;
                                if (response > 0) $scope.prefillPurchaseOrderItemData.purchasePrice = response;
                            }).catch(function (error) {
                                $scope.loading = false;
                            });
                        } catch (e) {
                            console.log('catch e: ', e);
                            $scope.loading = false;
                        }
                    })
                }

                function timerForInvoice() {
                    // console.log('--------------------------------------------------> timer for invoice--------------', $scope.purchase_order.invoiceCountDown);
                    if ($scope.purchase_order && $scope.purchase_order.invoiceCountDown && !$scope.purchase_order.invoiceCountDown.isProFormaInvoice &&
                        $scope.purchase_order.invoiceCountDown.endCountDownTime) {
                        var milliseconds = (new Date($scope.purchase_order.invoiceCountDown.endCountDownTime).getTime()) - new Date().getTime();
                        // console.log('milliseconds: ', milliseconds);
                        $scope.isCountDownFinished = false;
                        if (milliseconds >= 0 && !$scope.isCompayDownloaded) {
                            $interval(countDown, 1000);
                        } else {
                            $scope.isCountDownFinished = true;
                            $scope.timer = 'completed';
                        }
                    }
                }

                function countDown() {
                    // console.log('countDown: ', $scope.purchase_order.invoiceCountDown.endCountDownTime);
                    var milliseconds = (new Date($scope.purchase_order.invoiceCountDown.endCountDownTime).getTime()) - new Date().getTime();
                    if (milliseconds >= 0 && !$scope.isCompayDownloaded) {
                        // console.log('if milliseconds: ', milliseconds);
                        var seconds = milliseconds / 1000;
                        var days = Math.floor(seconds / (60 * 60 * 24));
                        var hoursLeft = Math.floor((seconds) - (days * 86400));
                        var hours = Math.floor(hoursLeft / 3600);
                        var minutesLeft = Math.floor((hoursLeft) - (hours * 3600));
                        var minutes = Math.floor(minutesLeft / 60);
                        var remainingSeconds = seconds % 60;

                        function pad(n) {
                            return (n < 10 ? "0" + n : n);
                        }
                        // var curretnTimer = days + " days, " + pad(hours) + ":" + pad(minutes) + ":" + pad(remainingSeconds.toFixed(0));
                        var curretnTimer = pad(hours) + ":" + pad(minutes) + ":" + pad(remainingSeconds.toFixed(0));
                        // console.log('timer: ', curretnTimer);
                        $scope.timer = curretnTimer;
                    } else {
                        $scope.isCountDownFinished = true;
                        $scope.timer = 'completed';
                    }
                }

                function setTheMyStatus() {
                    console.log('setTheMyStatus called: => ', $scope.purchase_order.status[0].status);
                    if (!$scope.purchaseOrder && !$scope.purchase_order) {
                        $scope.mystatus = 1;
                    } else if ($scope.purchase_order.status[0].status == "New") {
                        $scope.mystatus = 1;
                    } else if ($scope.purchase_order.status[0].status == "Processing") {
                        $scope.mystatus = 2;
                    } else if ($scope.purchase_order.status[0].status == "Inspection") {
                        $scope.mystatus = 3;
                    } else if ($scope.purchase_order.status[0].status == "Completed") {
                        $scope.mystatus = 4;
                    } else if ($scope.purchase_order.status[0].status == "Backorder") {
                        $scope.purchase_order.status[0].status = 'Backorder';
                        $scope.mystatus = 1.5;
                    } else if ($scope.purchase_order.status[0].status == "Cancelled") {
                        //-console.log('setTheMyStatus come inside');
                        $scope.mystatus = 5;
                    }
                }

                function focusPriceInput() {
                    if ($scope.focusIndex !== undefined) {
                        angular.element(document).ready(function () {
                            // console.log('call focus: ', $scope.focusIndex, "priceId" + $scope.focusIndex);
                            var focusPriceelement = document.getElementById("priceId" + $scope.focusIndex);
                            // console.log('focusPriceelement: ', focusPriceelement);
                            if (focusPriceelement) {
                                focusPriceelement.focus();
                                focusPriceelement.select();
                            }
                            // var focusRecQntelement = document.getElementById("receivedQuantityId"  + $scope.focusIndex);
                            // console.log('focusRecQntelement: ', focusRecQntelement);
                            // if (focusRecQntelement) focusRecQntelement.readOnly = true;
                        })
                    }
                }

                // Whenever adding comment to the then we have add new line when pressed (SHIFT + ENTER) and if only enter then add Comment
                $scope.addCommentKeyUp = function (event, currentremark) {
                    if (event.keyCode === 13) {
                        if (event.shiftKey) return false;
                        else $scope.AddComment(currentremark);
                    }
                }

                /* function fetchCompanyBrandsForPurchaseOrder(isChanginFromView) {
                    $scope.companyBrands = [];
                    console.log(16, isChanginFromView, $rootScope.currentShop, $scope.selectedCompany);
                    if (isChanginFromView) $scope.localdata.loading = true;
                    var bodyToSend = {
                        shopId: $rootScope.currentShop._id,
                        companyId: $scope.selectedCompany._id
                    }
                    // console.log('fetchCompanyBrandsForPurchaseOrder called', bodyToSend);
                    // console.log('DETIALS: ', $rootScope.currentShop);
                    // console.log('DETIALS: ', $scope.selectedCompany);
                   
                    $purchaseOrder.fetchCompanyBrandsForPurchaseOrder(bodyToSend).then(function (response) {
                        if (isChanginFromView) $scope.showCompanyBrand = true;
                        $scope.companyBrands = response.data.data;
                        console.log('response: ', $scope.companyBrands);
                        $scope.localdata.loading = false;
                    }).catch(function (compbranderr) {
                        console.log("Hello Error: ", compbranderr);
                        $scope.localdata.loading = false;
                    });
                } */

                $scope.onClickCompanyBrand = function (brand) {
                    $scope.clickedBrand = brand;
                    $scope.isBrandClicked = true;
                    //- span(ng-show="brand.isBrandExistInShop") Brand is added to your stop. Our database contains {{brand.productCount}} products of this brand.
                    //- span(ng-show="!brand.isBrandExistInShop") Brand is not added to your shop.
                }

                $scope.purchaseOrderPaid = function (isRetailerConfirmed) {
                    var body = {
                        purchaseOrderId: $scope.purchase_order._id,
                        confirmPayment: {
                            isRetailerConfirmed: isRetailerConfirmed,
                            isCompanyConfirmed: $scope.purchase_order.confirmPayment.isCompanyConfirmed || false
                        },
                        isFromRetailer: true,
                        shopId: $rootScope.currentShop._id
                    }
                    $scope.localdata.loading = true;
                    $purchaseOrder.purchaseOrderPaid(body).then(function (response) {
                        $scope.localdata.loading = false;
                        console.log('Purchase Order paid successfully updated');
                    }).catch(function (error) {
                        $scope.localdata.loading = false;
                        console.log('error: ', error);
                    })
                }

                $scope.bookAllProductAsReceived = function () {
                    console.log('bookAllProductAsReceived called: 1');
                    // $scope.updateThePurchaseOrder($scope.purchase_order).then(function (response){
                    var body = {
                        purchaseOrderId: $scope.purchase_order._id,
                        shopId: $scope.purchase_order.shop._id
                    };
                    $scope.bookAllRecLoading = true;
                    $purchaseOrder.bookAllProductAsReceived(body).then(function (response) {
                        $scope.GetExistingPurchaseOrder($scope.limit);
                        // $scope.purchase_order.bookAllRecLoadingClicked = true;
                    }).catch(function (error) {
                        $scope.bookAllRecLoading = false;
                        console.log('error: ', error);
                    });

                    // prompt({
                    //     // title: 'Confirmation',
                    //     // message: 'It will book all your product ordered qunaitty as received. Are you sure want to do this?',
                    //     // buttons: [{ label: 'YES', primary: true }, { label: 'NO', primary: false, cancel: true }]
                    //     title: $language.translate('CONFIRMATION'),
                    //     message: $language.translate('TITLE_BOOK_ALL_PRODUCT_RECEIVED'),
                    //     buttons: [{
                    //         label: $language.translate('YES'),
                    //         primary: true
                    //     }, {
                    //         label: $language.translate('NO'),
                    //         primary: false,
                    //         cancel: true
                    //     }]
                    // }).then(function (response) {
                    //     if (response.primary) {
                    //         var body = {
                    //             purchaseOrderId: $scope.purchase_order._id,
                    //             shopId: $scope.purchase_order.shop._id
                    //         };
                    //         $scope.bookAllRecLoading = true;
                    //         $purchaseOrder.bookAllProductAsReceived(body).then(function (response) {
                    //             console.log('all received: ', response.data);
                    //             $scope.GetExistingPurchaseOrder($scope.limit);
                    //             console.log('$scope.purchase_order.bookAllRecLoadingClicked ', $scope.purchase_order.bookAllRecLoadingClicked);
                    //             console.log('----------- executed successfullly!!');
                    //             // $scope.purchase_order.bookAllRecLoadingClicked = true;
                    //             console.log('$scope.purchase_order.bookAllRecLoadingClicked ', $scope.purchase_order.bookAllRecLoadingClicked);
                    //         }).catch(function (error) {
                    //             $scope.bookAllRecLoading = false;
                    //             console.log('error: ', error);
                    //         });
                    //     }
                    // }).catch(function (error) {
                    //     console.log('error: inprompnt: ', error);
                    // });
                }

                /**
                 * Making an array of transaction based on purchase.items.transactions
                 * So convertiong items.transaction to transactions array like transaction.products(items) array
                 */
                $scope.openInformCustomerModal = function () {
                    $scope.transactionDetail = JSON.parse(JSON.stringify($scope.originalTransactionDetail));
                    console.log('openInformCustomerModal called: ', $scope.transactionDetail);
                    $scope.customerInformErr = false;
                    if ($scope.transactionDetail && $scope.transactionDetail.length && $scope.purchase_order && $scope.purchase_order.items && $scope.purchase_order.items.length) {

                        var purchaseOrderItems = $scope.purchase_order.items;
                        var transactionDetail = $scope.transactionDetail;
                        var transactionDetailLength = transactionDetail.length;
                        $scope.convertedTransactions = [];
                        // console.log('transactionDetailLength: ', transactionDetailLength);

                        DeductReceivedQuantity();
                        // Deduct the recieved Quantity which already informed to customer
                        function DeductReceivedQuantity() {
                            for (var i = 0; i < transactionDetailLength; i++) {
                                for (var j = 0; j < transactionDetail[i].transactionDetail.length; j++) {
                                    if (transactionDetail[i].transactionDetail[j].status === 'completed' || transactionDetail[i].transactionDetail[j].status === 'delivered') {
                                        transactionDetail[i].totalAppliedStock = transactionDetail[i].totalAppliedStock - transactionDetail[i].transactionDetail[j].details.quantity;
                                    }
                                }
                            }
                        }

                        for (var i = 0; i < transactionDetailLength; i++) {

                            var item = {
                                _id: transactionDetail[i]._id, // Item id
                                product_id: transactionDetail[i].product_id, // Item productId
                                name: transactionDetail[i].name, // Item productId
                                number: transactionDetail[i].number, // Item number
                                receivedQuantity: transactionDetail[i].receivedQuantity, // Item receivedQuantity
                                totalAppliedStock: transactionDetail[i].totalAppliedStock,
                                quantity: transactionDetail[i].quantity, // Item quantity,
                                isShopProduct: transactionDetail[i].isShopProduct
                            }

                            // console.log('i: ', i);
                            for (var j = 0; j < transactionDetail[i].transactionDetail.length; j++) {
                                // console.log('j: ', j);
                                if (transactionDetail[i] && transactionDetail[i].transactionDetail && transactionDetail[i].transactionDetail[j] && transactionDetail[i].transactionDetail[j]._id) {
                                    if (!$scope.convertedTransactions.length) {
                                        var detail = transactionDetail[i].transactionDetail[j].details;
                                        detail.totalAppliedStock = item.totalAppliedStock;
                                        detail.receivedQuantity = item.receivedQuantity;
                                        detail.isShopProduct = item.isShopProduct;
                                        var currentTransaction = {
                                            _id: transactionDetail[i].transactionDetail[j]._id, // Transaction Id
                                            number: transactionDetail[i].transactionDetail[j].number, // Transaction number
                                            totalEarning: transactionDetail[i].transactionDetail[j].totalEarning, // Transaction total earning
                                            transactionTotal: transactionDetail[i].transactionDetail[j].transactionTotal, // Transaction total amount
                                            status: transactionDetail[i].transactionDetail[j].status, // Transaction status
                                            customer: transactionDetail[i].transactionDetail[j].customer, // Transaction customer id if any
                                            customerDetail: transactionDetail[i].transactionDetail[j].customerDetail, // Transaction customerdetrail if any
                                            details: [detail], // Transaction detail array (For now only one item always but in future its array)
                                            items: [item], // particular item obejct as its first so did like this
                                        }

                                        $scope.convertedTransactions.push(currentTransaction);
                                    } else {
                                        // console.log('heyId: ', i, j, transactionDetail[i].transactionDetail)
                                        // If transactions id already in the convertedTransactoinArray then we have to push that product(item) into that
                                        var currentTransactionId = (transactionDetail[i].transactionDetail[j]._id).toString();
                                        var isExistInCheckBoxArray = false;
                                        for (var k = 0; k < $scope.convertedTransactions.length; k++) {
                                            if (currentTransactionId === ($scope.convertedTransactions[k]._id).toString()) {
                                                isExistInCheckBoxArray = true;
                                                $scope.convertedTransactions[k].items.push(item);
                                            }
                                        }

                                        if (!isExistInCheckBoxArray) {
                                            var detail = transactionDetail[i].transactionDetail[j].details;
                                            detail.totalAppliedStock = item.totalAppliedStock;
                                            detail.receivedQuantity = item.receivedQuantity;
                                            detail.isShopProduct = item.isShopProduct;
                                            var currentTransaction = {
                                                _id: transactionDetail[i].transactionDetail[j]._id, // Transaction Id
                                                number: transactionDetail[i].transactionDetail[j].number, // Transaction number
                                                totalEarning: transactionDetail[i].transactionDetail[j].totalEarning, // Transaction total earning
                                                transactionTotal: transactionDetail[i].transactionDetail[j].transactionTotal, // Transaction total amount
                                                status: transactionDetail[i].transactionDetail[j].status, // Transaction status
                                                customer: transactionDetail[i].transactionDetail[j].customer, // Transaction customer id if any
                                                customerDetail: transactionDetail[i].transactionDetail[j].customerDetail, // Transaction customerdetrail if any
                                                details: [detail], // Transaction detail array (For now only one item always but in future its array)
                                                items: [item], // particular item obejct as its first so did like this
                                            }

                                            $scope.convertedTransactions.push(currentTransaction);
                                        }
                                    }
                                }
                            }
                        }

                        console.log('-----------------------BEFORE DOING CALC: ', $scope.convertedTransactions);
                        // return;
                        checkingEnableDisableFlagBasedOnReceivedQuantityOfTransactions();

                        function checkingEnableDisableFlagBasedOnReceivedQuantityOfTransactions() {
                            var convertedTransactionsLength = $scope.convertedTransactions.length;
                            makeDefaultItemsAvailableReceivedQuantity();
                            for (var i = 0; i < convertedTransactionsLength; i++) {
                                var convertedTransactionsDetails = $scope.convertedTransactions[i].details;
                                var convertedTransactionsDetailsLength = $scope.convertedTransactions[i].details.length;
                                for (var j = 0; j < convertedTransactionsDetailsLength; j++) {
                                    if ($scope.convertedTransactions[i].details[j].productId) {
                                        // console.log('All make default: ', transactionDetail);
                                        // continue;
                                        for (var k = 0; k < transactionDetailLength; k++) {
                                            if (transactionDetail[k].product_id && (transactionDetail[k].product_id).toString() === ($scope.convertedTransactions[i].details[j].productId).toString()) {
                                                var transactionDetailQuantity = $scope.convertedTransactions[i].details[j].quantity;
                                                // if ($scope.convertedTransactions[i].details[j].isCustomerInformed) {
                                                if ($scope.convertedTransactions[i].status === 'completed' || $scope.convertedTransactions[i].status === 'delivered') {
                                                    // transactionDetail[k].availablerReceivedQuantity = transactionDetail[k].availablerReceivedQuantity - transactionDetailQuantity;
                                                    $scope.convertedTransactions[i].details[j].canBeDeliver = transactionDetailQuantity;
                                                    $scope.convertedTransactions[i].details[j].canNotBeDeliver = 0;
                                                    $scope.convertedTransactions[i].details[j].canFullyDeliver = true;
                                                    $scope.convertedTransactions[i].details[j].canPartiallyDeliver = false;
                                                    $scope.convertedTransactions[i].details[j].canCancel = false;
                                                } else {
                                                    console.log('trasnactions available rec qnt: ', transactionDetail[k].availablerReceivedQuantity, transactionDetailQuantity);
                                                    if (transactionDetail[k].availablerReceivedQuantity) {
                                                        // console.log(k, transactionDetail[k].availablerReceivedQuantity, transactionDetailQuantity);
                                                        if (transactionDetail[k].availablerReceivedQuantity >= transactionDetailQuantity) {
                                                            // console.log(k, 'INSIDE IF: ', transactionDetail[k].availablerReceivedQuantity - transactionDetailQuantity);
                                                            transactionDetail[k].availablerReceivedQuantity = transactionDetail[k].availablerReceivedQuantity - transactionDetailQuantity;
                                                            $scope.convertedTransactions[i].details[j].canBeDeliver = transactionDetailQuantity;
                                                            $scope.convertedTransactions[i].details[j].canNotBeDeliver = 0;
                                                            $scope.convertedTransactions[i].details[j].canFullyDeliver = true;
                                                            $scope.convertedTransactions[i].details[j].canPartiallyDeliver = false;
                                                            $scope.convertedTransactions[i].details[j].canCancel = false;
                                                        } else {
                                                            // console.log(k, 'INSIDE ELSE: ', transactionDetail[k].availablerReceivedQuantity);
                                                            $scope.convertedTransactions[i].details[j].canBeDeliver = transactionDetail[k].availablerReceivedQuantity;
                                                            $scope.convertedTransactions[i].details[j].canNotBeDeliver = transactionDetailQuantity - transactionDetail[k].availablerReceivedQuantity;
                                                            transactionDetail[k].availablerReceivedQuantity = 0;
                                                            $scope.convertedTransactions[i].details[j].canFullyDeliver = false;
                                                            $scope.convertedTransactions[i].details[j].canPartiallyDeliver = true;
                                                            $scope.convertedTransactions[i].details[j].canCancel = false;
                                                        }
                                                    } else {
                                                        // console.log(k, 'OUTSIDE ELSE: ', transactionDetail[k].availablerReceivedQuantity);
                                                        $scope.convertedTransactions[i].details[j].canBeDeliver = 0;
                                                        $scope.convertedTransactions[i].details[j].canNotBeDeliver = transactionDetailQuantity;
                                                        $scope.convertedTransactions[i].details[j].canFullyDeliver = false;
                                                        $scope.convertedTransactions[i].details[j].canPartiallyDeliver = false;
                                                        $scope.convertedTransactions[i].details[j].canCancel = true;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        function makeDefaultItemsAvailableReceivedQuantity() {
                            for (var k = 0; k < transactionDetailLength; k++) {
                                transactionDetail[k].availablerReceivedQuantity = transactionDetail[k].totalAppliedStock;
                            }
                        }

                        // console.log('convertedTransactions: ', $scope.convertedTransactions);

                        var modalInstance = $uibModal.open({
                            templateUrl: '../views/modal/confirm-order-completed-deduct-stock-modal.html',
                            controller: 'confirmOrderIntoCompletedAndDeductStockModalController',
                            size: 'lg',
                            resolve: {
                                convertedTransactionsRec: function () {
                                    return $scope.convertedTransactions;
                                },
                                transactionDetailRec: function () {
                                    return $scope.transactionDetail;
                                },
                                purchaseOrderDetail: function () {
                                    return {
                                        _id: $scope.purchase_order._id
                                    }
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {
                            console.log('result: ', result);
                            if (result === 'cancel' || result === 'full' || result === 'partial') $scope.GetExistingPurchaseOrder($scope.limit);
                        }, function (err) {
                            console.log(err);
                        })
                    }
                }

                $scope.autoScrollcomment = function () {
                    setTimeout(function () {
                        $('#messagewindow').animate({
                            scrollTop: $('#messagewindow')[0].scrollHeight
                        }, 0);
                    }, 200)
                }

                // If number is more than some 2 digit then ng-value not working so we did this
                $scope.convertToDecimal = function (item, type, lastType, convertType) {
                    if (item[type]) {
                        if (convertType === 'PRICE') {
                            item.lastPrice = item.price;
                            if (item['priceIncVat']) item['priceIncVat'] = (+item['priceIncVat'].toFixed(2));
                        } else {
                            item[lastType] = item[type];
                            // console.log('hello convertDecinal:', type,lastType, item[type], typeof item[type], typeof item[type].toFixed(2))
                            if (item[type]) {
                                item[type] = (+item[type].toFixed(2));
                            }
                        }
                    }
                }

                $scope.PriceAndCollectionAdded = function () {
                    console.log('----------- PriceAndCollectionAdded!!');
                    var body = {
                        purchaseOrderId: $scope.purchase_order._id,
                        shopId: $rootScope.currentShop._id
                    }
                    $scope.priceCollLoading = true;
                    $purchaseOrder.updateAllProductWithCollectionInPurchaseOrder(body).then(function (response) {
                        $scope.fetchInitialData();
                        $scope.localdata.isEnteringThePage = false;
                        checkCalculationIconIsBlackOrGreen();
                        $scope.localdata.isPriceAndCollBtnPressed = true;
                    }).catch(function (error) {
                        $scope.priceCollLoading = false;
                        console.log('error: ', error);
                    });
                }

                $scope.openStockCorrectionModal = function () {
                    console.log('---openStockCorrectionModal!');
                    return $q(function (resolve, reject) {
                        var data = {
                            'shopId': $rootScope.currentShop._id,
                            'orderNumber': $scope.orderNumber
                        };

                        var modalInstance = $uibModal.open({
                            templateUrl: '../views/modal/retailer-purchase-order-stock-correction-modal.html',
                            controller: 'retailerPurchaseOrderStockCorrectionsController',
                            size: 'lg',
                            resolve: {
                                data: function () {
                                    return data;
                                }
                            },
                            windowClass: 'sample-pug2'
                        });
        
                        modalInstance.result
                        .then(function (response) {
                                console.log("Response", response);
                                if (response != undefined && response != null) $state.reload();
                                resolve(response);
                            })
                        .catch(function (reason) {
                            console.log("Here", reason);
                            reject(reason);
                        });
                    });
                }

                $scope.openPrintAllLabelsModal = function () {
                    var data = {
                        purchaseOrderId: $scope.purchase_order._id,
                        shop: {
                            _id: $scope.purchase_order.shop._id,
                            nameSlug: $scope.purchase_order.shop.nameSlug,
                        }
                    }

                    var modalInstance = $uibModal.open({
                        templateUrl: '../views/modal/print-all-label-modal.html',
                        controller: 'printAllLabelModalController',
                        resolve: {
                            data: function () {
                                return data;
                            },
                            products: function () {
                                return null
                            }
                        }
                    });

                    modalInstance.result.then(function (result) {
                        console.log('Success: ', result);
                    }, function (error) {
                        console.log("dismissed: ", error);
                    })
                }

                $scope.recommendationOrderPage = function () {
                    if ($rootScope.isPurchaseOrderNew && $scope.selectedCompany) {
                        $rootScope.recommendationCompany = $scope.selectedCompany;
                        // $rootScope.recommendationCompanyId = $scope.selectedCompany._id;
                        $state.go('retailer.recommendation-order', { orderNo: '' });
                    } else if ($scope.purchase_order.number) {
                        $state.go('retailer.recommendation-order', { orderNo: $scope.purchase_order.number });
                    }

                    // $scope.localdata.isRecommendedBtnClicked = false;
                    // if ($scope.purchase_order.status[0].status !== "Processing") {
                    //     $state.go('retailer.recommendation-order', {
                    //         orderNo: $scope.purchase_order.number
                    //     });
                    // } else {
                    //     $scope.localdata.isRecommendedBtnClicked = true;
                    //     $scope.purchase_order.status[0].status = 'Inspection';
                    //     $scope.changeTheStatus(true);
                    // }
                }

                $scope.requestForProductImages = function () {
                    // var isEmailSentForProdImages = $scope.purchase_order.isEmailSentForProdImages;
                    // var isAllProductHasImages = true;
                    // var showPurchaseOrderRequest = true;
                    // return false;
                    $state.go('retailer.purchase-order-mail-req-prod-images', {
                        orderNo: $stateParams.orderNo
                    });
                    // $state.go('retailer.purchase-order-mail-to-supplier', { orderNo: $stateParams.orderNo });
                }

                function getShopPrivateLabelCollections() {
                    console.log('--------------------------------------------------------> getShopPrivateLabelCollections: ');
                    $shop.getOwnShopsPrivateLableCollections($rootScope.currentShop._id)
                        .then(function (results) {
                            console.log('--------------------------------------------------------> shopCollections: ', results.length);
                            $scope.isShopPrivateLabelCollectionsFetched = true;
                            $scope.shopCollections = results;
                        })
                        .catch(function (reason) {
                            console.error(reason)
                        })
                }

                // #endregion General scope functions

                // #region Barcode support
                $scope.$on('scanner-ready', function (event, args) {
                    $scope.localdata.ean = args.parsedCode;
                    $scope.localdata.eanNumberSearch = true;
                    $scope.findProducts(args.parsedCode, $scope.searchMode, false, function (product) {
                        if (!product || typeof product != 'object' || (typeof product == 'object' && Object.keys(product).length == 0)) {
                            $scope.$apply();
                            return;
                        }

                        $scope.addItem(product, function (offerItem) {
                            $scope.saveItem(offerItem, $scope.searchMode)
                        });
                    })
                })
                // #endregion Barcode support

                $scope.$on('change_collection_purchaseorder', function (event, data) {
                    var itemindex = data.itemIndex;
                    if (data.isAdded == 204) {
                        console.log('Invalid data');
                        toastr.error($language.translate('PLEASE_ENTER_PROPER_DATA'));
                    } else {
                        console.log('Hey COLLECTION CHANGED REALLY?', itemindex);
                        $scope.purchase_order.items[itemindex].loading = true;
                        $scope.itemindex = itemindex;
                        $scope.fetchInitialData();
                        $scope.localdata.isPriceAndCollBtnPressed = true;
                    }
                });

                $scope.openChooseBrandForNewProduct = function (search_value) {
                    $scope.searchMoreContent = false;
                    console.log("Open Brand selection modal");
                    return $q(function (resolve, reject) {
                        var modalInstance = $uibModal.open({
                            controller: 'purchaseOrderProductBrandSelectionModalController',
                            templateUrl: '../views/modal/purchaseOrder-product-brand-selection-modal.html',
                            size: 'lg',
                            resolve: {
                                search_value: function () {
                                    return search_value;
                                }
                            }
                        });

                        modalInstance.result
                            .then(function (response) {
                                response.product.shopAppliedStartingDigits = $rootScope.currentShop.startingDigits;
                                $scope.hits = [];
                                $scope.hideHits = false;
                                $scope.localdata.search_value = response.product.productNumber
                                $scope.unofficialProduct = response.product;
                                $('#offercomment').focus();
                            })
                            .catch(function (reason) {
                                console.log('error:   ', reason);
                                reject(reason);
                            });
                    });
                }

                $scope.newEditProduct = function (item, index) {
                    console.log('-----------------------------------------> newEditProduct', index);
                    $stateParams.sample = 'data';
                    console.log(item.unofficialProduct && item.unofficialProduct.companyAppliedStartingDigits);
                    if (item.unofficialProduct && item.unofficialProduct.companyAppliedStartingDigits) return;
                    $scope.currentIndex = index;
                    if (item && item.product_id && item.product_id._id) {
                        $scope._id = item.product_id._id;
                        $scope.sampleItem = item;
                        $scope.itemId = undefined;
                        var tempItem = angular.copy(item);
                        tempItem.index = index;
                        $scope.openModal(tempItem, index)
                    } else {
                        console.log('---------------------------------------------else!!');
                        console.log('item ', item);
                        var newItem = item;
                        for (var i = 0; i < $scope.purchase_order.items.length; i++) {
                            if ($scope.purchase_order.items[i].number == item.number) {
                                if ($scope.purchase_order.items[i].product_id._id) {
                                    console.log('----------------------------- assign ne value to item!');
                                    newItem = $scope.purchase_order.items[i];
                                    break;
                                }
                            }
                        }
                        $scope.newEditProduct(newItem, index);
                        addProducctToExistingPurchaseOrder(item);
                        $scope.purchase_order.items.splice(index, 1)
                    }
                }

                $scope.newAddProduct = function (item, index) {
                    if (item.unofficialProduct && item.unofficialProduct.companyAppliedStartingDigits) return;
                    console.log('AddProduct product: ---> ', item, index);
                    $scope.currentIndex = index;
                    // var body = {
                    //     shopId: $rootScope.currentShop._id,
                    //     productNumber: item.number,
                    //     purchaseOrderId: $scope.purchase_order._id,
                    //     purchaseOrderItemId: item._id
                    // };
                    // $purchaseOrder.isProductExistByProductNumber(body).then(function (response) {
                    //     console.log('response: ', response);
                    //     if (response && response.data && response.data.data && response.data.data.isProductCreated) {
                    //         var resDetail = response.data.data;
                    //         $state.go('retailer.edit-product', { 'orderNumber': $stateParams.orderNo, _id: resDetail.product._id, status: 'view' });
                    //     } else {
                    //         $state.go('retailer.add-product', { 'orderNumber': $stateParams.orderNo, 'itemId': item._id });
                    //     }
                    // }).catch(function (error) {
                    //     console.log('error in checking the productNumber: ', error);
                    // });

                    var body = {
                        shopId: $rootScope.currentShop._id,
                        productNumber: item.number,
                        purchaseOrderId: $scope.purchase_order._id,
                        purchaseOrderItemId: item._id
                    };
                    $purchaseOrder.isProductExistByProductNumber(body).then(function (response) {
                        console.log('response: ', response);
                        console.log(response && response.data && response.data.data && response.data.data.isProductCreated);
                        if (response && response.data && response.data.data && response.data.data.isProductCreated) {
                            // var resDetail = response.data.data;
                            // $state.go('retailer.edit-product', { 'orderNumber': $stateParams.orderNo, _id: resDetail.product._id, status: 'view' });
                            $scope.newEditProduct(item, index);
                        } else {
                            $scope._id = undefined;
                            $scope.itemId = item._id;
                            console.log('$scope._id ', $scope._id);
                            console.log('$scope.itemId ', $scope.itemId);
                            item.index = index;
                            $scope.sampleItem = item;
                            $scope.openModal(item, index)
                        }
                    });
                }

                $scope.openModal = function (product, index) {
                    //console.log('---------------------------------  printing item!!', product);
                    // return;
                    var modalInstance = $uibModal.open({
                        templateUrl: '../views/retailer1/product.html',
                        controller: 'retailerProduct1Controller',
                        size: 'lg',
                        resolve: {
                            item: function () {
                                product = product;
                                product.extraData = {
                                    _id: $scope._id,
                                    itemId: $scope.itemId,
                                    index: index,
                                    tempItemId: $scope.purchase_order.items[index]._id
                                };
                                if ($scope.orderNumber) {
                                    product.extraData.orderNumber = $scope.orderNumber;
                                    $rootScope.orderNo = $scope.orderNumber;
                                }
                                product.isFromPurchaseOrder = true;
                                return product
                            }
                        },
                        windowClass: 'sample-pug'
                    });

                    modalInstance.result.then(function (response) {
                        $rootScope.orderNo = undefined;
                    }).catch(function (reason) {
                        $rootScope.orderNo = undefined;
                    });
                    $scope.cancelItem();
                }

                $scope.previousButton = function () {
                    console.log('------------------ previousButton!!');
                    if ($scope.purchase_order.stepThree) {
                        $scope.purchase_order.stepThree = false;
                    } else {
                        if (!$scope.purchase_order.stepThree && $scope.purchase_order.bookAllRecLoadingClicked) {
                            $scope.purchase_order.bookAllRecLoadingClicked = false;
                        }
                    }
                    $purchaseOrder.updateStepThreeFlag($scope.purchase_order._id, $scope.purchase_order.shop._id, false).then(function (response) {
                        console.log('response');
                        console.log(response);
                    }).catch(function (error) {
                        console.log('error');
                        console.log(error);
                    });
                }

                $scope.nextButton = function () {
                    console.log('------------------ nextButton!!');
                    if (!$scope.purchase_order.stepThree) {
                        $scope.purchase_order.stepThree = true;
                        $purchaseOrder.updateStepThreeFlag($scope.purchase_order._id, $scope.purchase_order.shop._id, true).then(function (response) {
                            console.log('response');
                            console.log(response);
                        }).catch(function (error) {
                            console.log('error');
                            console.log(error);
                        });
                    }
                    // if(!$scope.purchase_order.stepThree && $scope.purchase_order.bookAllRecLoadingClicked){
                    //     $scope.purchase_order.bookAllRecLoadingClicked = !$scope.purchase_order.bookAllRecLoadingClicked;
                    // }
                    console.log('$scope.purchase_order.stepThree ', $scope.purchase_order.stepThree);
                }

                $scope.getRoundValue = function (value) {
                    return parseFloat(parseFloat(value).toFixed(2));
                }

                $scope.editProductNumberPrompt = function (index) {
                    console.log('-------- editProductNumberPrompt!!');
                    // prompt({
                    //     title: $language.translate('CONFIRMATION'),
                    //     message: $language.translate('PLEASE_DELETE_FIRST_THEN_ADD_IT'),
                    //     buttons: [{
                    //         label: $language.translate('OK'),
                    //         primary: true
                    //     }]
                    // }).then(function (response) {
                    //     console.log('response: ', response);
                    // }).catch(function (error) {
                    //     console.log('error: inprompnt: ', error);
                    // });
                    
                    var productData =  $scope.purchase_order.items[index];
                    var product = angular.copy(productData);

                    var modalInstance = $uibModal.open({
                        templateUrl: '../views/modal/purchase-order-product-details-update-modal.html',
                        controller: 'purchaseOrderProductDetailsUpdateController',
                        keyboard: false,
                        backdrop: 'static',
                        resolve: {  
                            product: function(){
                                return product;
                            }
                        }
                      })
                
                      modalInstance.result.then(function (product) {;
                        if (product) {
                            var productId = product._id;
                            for(var i = 0; i < $scope.purchase_order.items.length; i++){
                                if($scope.purchase_order.items[i]._id.toString() == productId.toString()){
                                    var oldProduct = angular.copy($scope.purchase_order.items[i]);
                                    if(JSON.stringify(oldProduct) != JSON.stringify(product)){
                                        $scope.purchase_order.items[i] = product;
                                        $scope.updateThePurchaseOrder($scope.purchase_order);
                                        var shopId = $rootScope.currentShop._id;
                                        $scope.updateLabelDescription(shopId, product);
                                    }
                                }
                            }
                        }
                      }, function () {
                        //dismissed
                      })
                }

                $scope.updateLabelDescription = function(shopId, product){
                    var data = {
                        productId: product.product_id._id,
                        labelDescription: product.name,
                        shopId: shopId
                    }
                    $api.post('shop/product-labelDescription-update', data)
                        .then(function(response){
                        })
                        .catch(function (reason) {
                            console.error(reason)
                        })

                }

                $scope.clickToCopy = function (storageFactor) {
                    var value = document.getElementById(storageFactor).value;

                    // create temp element
                    var copyElement = document.createElement("span");
                    copyElement.appendChild(document.createTextNode(value));
                    copyElement.id = 'tempCopyToClipboard';
                    angular.element(document.body.append(copyElement));
                    console.log('share: ', document.getElementById('#tempCopyToClipboard'));

                    // select the text
                    var range = document.createRange();
                    range.selectNode(copyElement);
                    window.getSelection().removeAllRanges();
                    window.getSelection().addRange(range);

                    // copy & cleanup
                    document.execCommand('copy');
                    window.getSelection().removeAllRanges();
                    copyElement.remove();
                }

                $scope.changePage = function (position, searchCurrentPage) {
                    console.log('changePage: ', searchCurrentPage);
                    $scope.searchCurrentPage = searchCurrentPage;
                    $scope.findProducts($scope.localdata.search_value, $scope.searchMode, $scope.localdata.isProductNumber)
                    
                }

                $scope.changeShopProductPage = function (position, searchCurrentShopProductPage) {
                    console.log('changePage: ', searchCurrentShopProductPage);
                    $scope.searchCurrentShopProductPage = searchCurrentShopProductPage;
                    $scope.findProducts($scope.localdata.search_value, $scope.searchMode, $scope.localdata.isProductNumber)
                }
            }
        ]);