prismanoteApp.controller('languageSelectionForWebsite', ['$scope', 'shopId', 'websiteId', '$uibModalInstance', 'grapeFactory',
  function ($scope, shopId, websiteId, $uibModalInstance, grapeFactory) {
    $scope.enSelected = false
    $scope.nlSelected = false
    $scope.esSelected = false
    $scope.deSelected = false
    $scope.frSelected = false
    $scope.defaultLanguage = 'nl'

    $scope.alert = {
      type: '',
      message: ''
    }

    $scope.availableLanguages = [
      {
        title: 'English',
        shortName: 'en',
        flagIcon: 'https://prismanote.s3-eu-west-1.amazonaws.com/websitebuilder-default-templates/common-images/country-flags/en.png',
        isSelected: false
      },
      {
        title: 'Dutch',
        shortName: 'nl',
        flagIcon: 'https://prismanote.s3-eu-west-1.amazonaws.com/websitebuilder-default-templates/common-images/country-flags/nl.png',
        isSelected: false
      },
      {
        title: 'French',
        shortName: 'fr',
        flagIcon: 'https://prismanote.s3-eu-west-1.amazonaws.com/websitebuilder-default-templates/common-images/country-flags/fr.png',
        isSelected: false
      },
      {
        title: 'German',
        shortName: 'de',
        flagIcon: 'https://prismanote.s3-eu-west-1.amazonaws.com/websitebuilder-default-templates/common-images/country-flags/de.png',
        isSelected: false
      },
      {
        title: 'Spanish',
        shortName: 'es',
        flagIcon: 'https://prismanote.s3-eu-west-1.amazonaws.com/websitebuilder-default-templates/common-images/country-flags/es.png',
        isSelected: false
      }
    ]

    $scope.selectedLanguages = []

    $scope.getWebsiteDetails = function () {
      return grapeFactory.getWebsiteDetail(shopId, websiteId)
        .then(function (response) {
          $scope.websiteDetail = response.data.websiteDetail
          $scope.selectedLanguages = $scope.websiteDetail.availableLanguagesForTranslation && $scope.websiteDetail.availableLanguagesForTranslation.length ? $scope.websiteDetail.availableLanguagesForTranslation : []
          $scope.defaultLanguage = $scope.websiteDetail.defaultLanguage

          if ($scope.selectedLanguages.length) {
            for (var i = 0; i < $scope.selectedLanguages.length; i++) {
              if ($scope.selectedLanguages[i] === 'en') {
                $scope.enSelected = true
              } else if ($scope.selectedLanguages[i] === 'nl') {
                $scope.nlSelected = true
              } else if ($scope.selectedLanguages[i] === 'es') {
                $scope.esSelected = true
              } else if ($scope.selectedLanguages[i] === 'de') {
                $scope.deSelected = true
              } else if ($scope.selectedLanguages[i] === 'fr') {
                $scope.frSelected = true
              }
            }
          }
        })
        .catch(function (error) {
          $scope.alert = {
            type: 'danger',
            message: error.data.message
          }
        })
    }

    $scope.setDefaultLanguage = function (language) {
      $scope.defaultLanguage = language
    }

    $scope.changeSelectedLanguage = function (language) {
      console.log('language', language)
    }

    $scope.customDomainOk = function () {
      var request = {
        availableLanguagesForTranslation: [],
        defaultLanguage: $scope.defaultLanguage
      }

      if ($scope.enSelected) {
        request.availableLanguagesForTranslation.push('en')
      }

      if ($scope.nlSelected) {
        request.availableLanguagesForTranslation.push('nl')
      }
      if ($scope.esSelected) {
        request.availableLanguagesForTranslation.push('es')
      }
      if ($scope.deSelected) {
        request.availableLanguagesForTranslation.push('de')
      }
      if ($scope.frSelected) {
        request.availableLanguagesForTranslation.push('fr')
      }

      grapeFactory
        .updateLanguagePreference(websiteId, shopId, request)
        .then(function () {
          $uibModalInstance.close(true)
        })
        .catch(function (error) {
          $uibModalInstance.close(true)
          $scope.alert.type = 'danger'
          $scope.alert.msg = error.data.message || 'Something went wrong. Please try again'
        })
    }

    $scope.closeAlert = function () {
      $scope.alert = {
        type: '',
        msg: ''
      }
    }
  }
])
