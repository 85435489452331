prismanoteApp.controller('adminTaxesController', ['$scope', '$rootScope', '$api', '$stateParams', '$uibModal', '$state', 'prompt', 'Upload', '$timeout',
  function ($scope, $rootScope, $api, $stateParams, $uibModal, $state, prompt, Upload, $timeout) {
    $scope.rates = {
      stateRate: {}
    }

    $scope.getTaxes = function () {
      $api.get('tax/all')
        .then(function (res) {
          $scope.taxes = res.data.taxes
        })
        .catch(function (reason) {
          console.error('REASON', reason)
        })
    }

    $scope.newTax = function () {
      $state.go('admin.tax')
    }

    $scope.initRate = function () {
      $scope.getCountriesAndStates()

      if ($stateParams.id) {
        $scope.new = false

        $api.get('tax', { id: $stateParams.id })
          .then(function (res) {
            $scope.tax = res.data
          })
          .catch(function (reason) {
            console.error(reason)
          })
      } else {
        $scope.new = true
        $scope.tax = {}
      }
    }

    $scope.getCountriesAndStates = function () {
      $api.get('countries')
        .then(function (res) {
          $scope.countries = res.data.countries
        })
        .catch(function (reason) {
          console.error(reason)
        })
    }

    $scope.setCountry = function (country) {
      if (country.provinces && country.provinces.length > 0) {
        $scope.tax.states = country.provinces.map(function (v) {
          return {
            name: v.name,
            short: v.short,
            taxes: []
          }
        })
      }
      $scope.tax.country = country.alpha2
    }

    $scope.addRate = function (rate) {
      $scope.warning = null
      if (!$scope.tax.rates) {
        $scope.tax.rates = []
      }
      var index = _.findIndex($scope.tax.rates, { rate: rate.rate })
      if (index != -1) {
        $scope.warning = 'THIS_RATE_ALREADY_EXISTS'
      } else {
        $scope.tax.rates.push(rate)
        $scope.newRate = null
        if (!$scope.new) {
          $scope.saveTaxRate()
        }
      }
    }

    $scope.deleteTax = function (index) {
      $scope.tax.rates.splice(index, 1)
      if (!$scope.new) {
        $scope.saveTaxRate()
      }
    }

    $scope.addStateRate = function (rate, stateIndex) {
      rate.warning = null
      if (!$scope.tax.states[stateIndex].rates) {
        $scope.tax.states[stateIndex].rates = []
        var index = -1
      } else {
        var index = _.findIndex($scope.tax.states[stateIndex].rates, { rate: rate.rate })
      }

      if (index != -1) {
        rate.warning = 'THIS_RATE_ALREADY_EXISTS'
      } else {
        $scope.tax.states[stateIndex].rates.push(rate)
        $scope.rates.stateRate = {}
        rate = {}
        if (!$scope.new) {
          $scope.saveTaxRate()
        }
      }
    }

    $scope.deleteStateRate = function (index, index2) {
      $scope.tax.states[index2].rates.splice(index, 1)
      $scope.rates.stateRate = {}
      if (!$scope.new) {
        $scope.saveTaxRate()
      }
    }

    $scope.saveTaxRate = function () {
      if ($scope.new) {
        $api.post('tax', { tax: $scope.tax })
          .then(function (res) {
            $state.go('admin.taxes')
          })
          .catch(function (reason) {
            console.error(reason)
          })
      } else {
        $api.put('tax', { tax: $scope.tax })
          .then(function (res) {
          })
          .catch(function (reason) {
            console.error(reason)
          })
      }
    }
  }])
