prismanoteApp.controller('adminShopPurchaseController', ['$scope', '$q', '$rootScope', '$api', '$stateParams', '$state', '$language', '$transaction', 'dialogs', '$filter', '$customer',
  function ($scope, $q, $rootScope, $api, $stateParams, $state, $language, $transaction, dialogs, $filter, $customer) {
    $scope.currentPage = 1
    $scope.pageSize = 5
    $scope.maxSize = 10

    if ($rootScope.tablet || $rootScope.phone) {
      $scope.pageSize = 3
      $scope.maxSize = 9
    }
    $scope.loading = true

    $scope.outstanding = 0
    $scope.disableRefund = false
    $scope.bankPaymentOption = null;
    $scope.bankAmountPaid = true;

    $scope.customerName = function(customer) {
      return $customer.makeCustomerName(customer)
    }

    $scope.paymentMode = function () {
      if($scope.transaction && $scope.transaction.receipt && $scope.transaction.receipt.payments) {
        $scope.bankPaymentOption = null;
        for (var i = 0; i < $scope.transaction.receipt.payments.length; i++) {
          if($scope.transaction.receipt.payments[i].method === 'bankAmount') {
            if($scope.transaction.receipt.payments[i].bankPaymentAcknowledgement === false) {
              $scope.bankPaymentOption = 'pending';
            } else {
              $scope.bankPaymentOption = 'paid';
            }
          }
        }
      } else {
        $scope.bankPaymentOption = null;
      }
    }

    $scope.confirmBankPayment = function () {
      var dlg = dialogs.confirm(
        $language.translate('WARNING'),
        $language.translate('DO_YOU_WANT_TO_CONTINUE'),
        {
          size: 'md'
        })
      dlg.result.then(function (btn) {
        if(btn === 'yes') {
          $api.post('update-bank-transaction/', {
            transactionId: $scope.transaction._id
          })
            .then(function (response) {
              $scope.getTransaction()
            })
        }

      })

    }

    $scope.getTransaction = function () {
      $transaction.getTransaction($stateParams.transactionId, true, false, null)
        .then(function (transaction) {
          if (transaction.length && transaction.length > 0) {
            $scope.transaction = transaction[0]

          } else {
            $scope.transaction = transaction
          }
          if ($scope.transaction) {
            $scope.originalTransaction = JSON.parse(JSON.stringify($scope.transaction))
          }

          $scope.receiptNumbers = []

          if ($scope.transaction.type === 'refund') {
            $scope.totalChange = 0
            var totalPaidByPaymentMethods = 0
            $scope.payMethods = []
            for (var i = 0; i < $scope.transaction.receipt.payments.length; i++) {
              $scope.payMethods.push({
                method: $language.translate($scope.transaction.receipt.payments[i].method.toUpperCase()),
                originalMethod: $scope.transaction.receipt.payments[i].method.toUpperCase(),
                amount: $scope.transaction.receipt.payments[i].amount,
                change: $scope.transaction.receipt.payments[i].change,
                giftcardNumber: $scope.transaction.receipt.payments[i].giftcardNumber,
                card: $scope.transaction.receipt.payments[i].method.toUpperCase() === 'CARD',
                cardName: $scope.transaction.receipt.payments[i].cardName ? $scope.transaction.receipt.payments[i].cardName : ''
              })
              totalPaidByPaymentMethods += $scope.transaction.receipt.payments[i].method.toUpperCase() !== 'PAYLATER' ? $scope.transaction.receipt.payments[i].amount : 0
            }
            if(totalPaidByPaymentMethods === 0){
              $scope.totalChange = 0
            }else{
              $scope.totalChange = totalPaidByPaymentMethods - $scope.transaction.transactionTotal
            }
          }

          if ($scope.transaction.type === 'shop-purchase') {
            if ($scope.transaction.details && $scope.transaction.details.length > 0) {
              $scope.transaction.totalAmount = 0
              for (var i = 0; i < $scope.transaction.details.length; i++) {
                if ($scope.transaction.details[i].total) {
                  $scope.transaction.totalAmount += $scope.transaction.details[i].total
                } else {
                  $scope.transaction.totalAmount += $scope.transaction.details[i].quantity * $scope.transaction.details[i].price
                }
              }
            }

            $scope.payMethods = []
            $scope.totalChange = 0
            if (!$scope.transaction.receipt || !$scope.transaction.receipt.payments) {
              return
            }

            var totalPaidByPaymentMethods = 0

            for (var i = 0; i < $scope.transaction.receipt.payments.length; i++) {
              $scope.payMethods.push({
                method: $language.translate($scope.transaction.receipt.payments[i].method.toUpperCase()),
                originalMethod: $scope.transaction.receipt.payments[i].method.toUpperCase(),
                amount: $scope.transaction.receipt.payments[i].amount,
                change: $scope.transaction.receipt.payments[i].change,
                giftcardNumber: $scope.transaction.receipt.payments[i].giftcardNumber,
                card: $scope.transaction.receipt.payments[i].method.toUpperCase() === 'CARD',
                cardName: $scope.transaction.receipt.payments[i].cardName ? $scope.transaction.receipt.payments[i].cardName : ''
              })
              totalPaidByPaymentMethods += $scope.transaction.receipt.payments[i].method.toUpperCase() !== 'PAYLATER' ? $scope.transaction.receipt.payments[i].amount : 0
            }
            var totalAmount = $scope.transaction.transactionTotal < 0 ? $scope.transaction.transactionTotal * -1 : $scope.transaction.transactionTotal
            if(totalPaidByPaymentMethods === 0){
              $scope.totalChange = 0
            }else if(totalPaidByPaymentMethods < 0) {
              $scope.totalChange = totalPaidByPaymentMethods + totalAmount
            } else {
              $scope.totalChange = totalPaidByPaymentMethods - totalAmount
            }

            if ($scope.transaction.transactions && $scope.transaction.transactions.length > 0) {
              for (var i = 0; i < $scope.transaction.transactions.length; i++) {
                if ($scope.transaction.transactions[i].type === 'refund') {
                  $scope.refund = $scope.transaction.transactions[i]
                  $scope.disableRefund = true
                }
              }
            }
          }

          if ($scope.transaction.type === 'shop-purchase' || $scope.transaction.type === 'refund') {
            $scope.totalTax = 0
            $scope.taxes = {}
            var detailsLength = $scope.transaction.details.length
            for (var i = 0; i < detailsLength; i++) {
              var detail = $scope.transaction.details[i]

              detail.price = detail.price * (1 + (detail.priceVat / 100))

              $scope.totalTax += $scope.taxPrice(detail)
              if (!$scope.taxes[detail.priceVat]) {
                $scope.taxes[detail.priceVat] = detail.total
              } else {
                $scope.taxes[detail.priceVat] += detail.total
              }

              // Now lower those values with the total discount on the offer (if applied)
              if ((i + 1) === detailsLength && $scope.transaction.discount) {
                var totalDiscount = $scope.getDiscount()
                var totalPrice = $scope.getTotalPrice(false)

                // Now loop through the taxes table and edit the values based on the value, sow more discount will be applied to a rule with an higher amount
                for (var t in $scope.taxes) {
                  var originalRate = $scope.taxes[t]
                  var ratePriceWithDiscount = $scope.taxes[t] * (totalDiscount / totalPrice)
                  $scope.taxes[t] = originalRate - ratePriceWithDiscount
                }
              }
            }
          }

          if ($scope.transaction.type === 'refund') {
            if ($scope.transaction.details && $scope.transaction.details.length > 0) { $scope.refund = $scope.transaction.details[0].transaction }
            $scope.disableRefund = true
          }

          $scope.getOutstandingAmount()

          $scope.loading = false
          $scope.paymentMode()
        })
        .catch(function (reason) {
          console.error('Error while loading transaction', reason)
          if (typeof reason === 'object') {
            $scope.alert = {
              type: 'danger',
              msg: $language.translate('UNKOWN_ERROR_OCCURED')
            }
            return
          }
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(reason)
          }

          $scope.loading = false
        })
    }

    $scope.sumPaid = function () {
      return _.sumBy($scope.transaction.receipt.payments, function (payment) {
        return payment.amount - payment.change
      })
    }

    $scope.openUser = function (id) {
      $state.go('admin.user', {
        userId: id
      })
    }

    $scope.openShop = function (nameSlug) {
      $state.go('admin.shop', {
        nameSlug: nameSlug
      })
    }

    $scope.updateTransaction = function () {
      if ((!$scope.transaction.createdBy || !$scope.transaction.createdBy.name) && $scope.isRemovedAssitedBy) return
      $scope.loading = true
      if (!$scope.transaction.log) $scope.transaction.log = []
      if ($scope.intakeDateChanged) {
        var log = {
          date: new Date(),
          user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
          text: 'Intake date changed',
          isFor: 'retailerPortal',
          eType: 'retailer'
        }
        $scope.transaction.log.push(log)
      }

      if ($scope.repairDateChanged) {
        var log = {
          date: new Date(),
          user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
          text: 'Repair date changed',
          isFor: 'retailerPortal',
          eType: 'retailer'
        }
        $scope.transaction.log.push(log)
      }

      $scope.transaction.shopId = { _id: $scope.transaction.shopId._id }

      $transaction.updateTransaction($scope.transaction, null, true)
        .then(function (result) {
          $scope.loading = false
          $scope.getTransaction()
        })
        .catch(function (reason) {
          $scope.loading = false
          dialogs.error($language.translate('ERROR'), $language.translate('AN_ERROR_OCCURED') + ': ' + reason, { size: 'sm' })
        })
    }

    $scope.getItemDiscount = function (item) {
      if (item.discount) {
        if (item.discountPercent) {
          return item.discountValue + '%'
        } else {
          return $filter('currency')(item.discountValue, '\u20AC')
        }
      } else {

      }
    }

    $scope.getTotalPrice = function (discount) {
      if(!$scope.transaction) {
        return
      }
      var total = 0
      var counter = 0
      for (var i = 0; i < $scope.transaction.details.length; i++) {
        total += $scope.transaction.details[i].total
        counter++
        if (counter === $scope.transaction.details.length) {
          if (discount) {
            return total - $scope.getDiscount()
          } else {
            return total
          }
        }
      }
    }

    $scope.taxPrice = function (product) {
      var total = angular.copy(product.total)
      if (total < 0) {
        total = total * -1
      }

      return total - (total / (1 + (product.priceVat / 100)))
    }

    $scope.getDiscount = function () {
      if (!$scope.transaction.discount) {
        return 0
      }

      if ($scope.transaction.discount.percent) {
        return $scope.getTotalPrice(false) * ($scope.transaction.discount.value / 100)
      } else {
        return $scope.transaction.discount.value
      }
    }

    $scope.getTransactionPrice = function () {
      var counter = 0; var totalAmount = 0
      for (var i = 0; i < $scope.transaction.details.length; i++) {
        totalAmount += $scope.transaction.details[i].total
        counter++
        if (counter === $scope.transaction.details.length) {
          return totalAmount
        }
      }
    }

    $scope.getPrice = function (checkout, callback) {
      if (!$scope.transaction) {
        return
      }
      if (checkout && $scope.outstanding > 0) {
        return callback($scope.outstanding)
      }
      if ($scope.transaction.type === 'repair' || $scope.transaction.type === 'special') {
        if ($scope.transaction[$scope.transaction.type].actualCosts) {
          return callback($scope.transaction[$scope.transaction.type].actualCosts)
        } else if ($scope.transaction[$scope.transaction.type].estimatedPrice) {
          return callback($scope.transaction[$scope.transaction.type].estimatedPrice)
        } else {
          return callback(0)
        }
      } else if ($scope.transaction.type === 'shop-purchase') {
        return callback($scope.getTransactionPrice() * (-1))
      }
    }

    $scope.getOutstandingAmount = function () {
      $scope.outstanding = 0
      $scope.getPrice(false, function (price) {
        if (!$scope.payments || $scope.payments.length === 0) {
          $scope.outstanding = price
          return
        }
        var counter = 0; var totalPaid = 0
        for (var i = 0; i < $scope.payments.length; i++) {
          totalPaid += $scope.payments[i].amount - $scope.payments[i].change
          counter++
          if (counter === $scope.payments.length) {
            $scope.outstanding = price - totalPaid
          }
        }
      })
    }

    $scope.fetchPreviousPaidQuantity = function () {
      return $q(function (resolve, reject) {
        var totalPaidQuantity = 0
        if ($scope.transaction.details && $scope.transaction.details.length && $scope.transaction.details[0].productId) {
          var currentSingleOrderProductId = $scope.transaction.details[0].productId._id

          for (var i = 0; i < $scope.transaction.transactions.length; i++) {
            var firstDetail = $scope.transaction.transactions[i].details[0]
            totalPaidQuantity += firstDetail.quantity
          }
        }
        return resolve(totalPaidQuantity)
      })
    }

    $scope.deleteTransaction = function () {
        $transaction.deleteTransaction($scope.transaction._id, true, true)
          .then(function (res) {
            $scope.transaction = null
            $scope.payMethods = null
            $scope.totalTax = null
            $scope.alert = {
              type: 'success',
              msg: 'Transaction deleted'
            }
          })
          .catch(function (reason) {
            console.error(reason)
          })
    }

    $scope.changePayment = function () {
      var totalPaylaterAmount = $scope.getPayLaterAmount()
      $state.go('retailer.till', {
        customer: $scope.transaction.customer,
        items: [{
          restPayment: true,
          type: 'product',
          transaction: $scope.transaction._id,
          quantity: 1,
          price: totalPaylaterAmount,
          total: totalPaylaterAmount,
          priceVat: $scope.transaction.details[0].priceVat,
          name: $language.translate('REST_PAYMENT') + ' ' + $scope.transaction.details[0].name,
          productId: $scope.transaction.details[0].productId ? $scope.transaction.details[0].productId : null
        }],
        transactionId: $scope.transaction._id,
        transactionNumber: $scope.transaction.number,
        refund: false,
        checkout: true,
        payLater: true
      })

      //$state.go('retailer.checkout.start', {transactionId: $scope.transaction._id, payLater: true, payLaterAmount: $scope.getPayLaterAmount()})
    }

    $scope.checkPayLater = function () {
      if (!$scope.transaction || !$scope.transaction.receipt || !$scope.transaction.receipt.payments || $scope.transaction.receipt.payments.length === 0) {
        return false
      }

      var payLater = _.findIndex($scope.transaction.receipt.payments, function (p) {
        return p.method.toUpperCase() === 'PAYLATER' && p.amount > 0
      })

      return payLater >= 0
    }

    $scope.getPayLaterAmount = function () {
      var totalPayLater = 0
      var counter = 0
      for (var i = 0 ; $scope.transaction.receipt.payments.length; i++){
        if ($scope.transaction.receipt.payments[i].method.toUpperCase() === 'PAYLATER' && $scope.transaction.receipt.payments[i].amount > 0) {
          totalPayLater += $scope.transaction.receipt.payments[i].amount
        }
        counter++
        if(counter === $scope.transaction.receipt.payments.length) {
          return totalPayLater
        }
      }
    }
  }])
