prismanoteApp.controller('reArrangeMultiShopsModalController', ['$scope', 'connectedShops', '$uibModalInstance',
  function ($scope, connectedShops, $uibModalInstance) {
    $scope.connectStores = JSON.parse(JSON.stringify(connectedShops))

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    $scope.confirm = function () {
      console.log('Updated Stores ', $scope.connectStores)
      $uibModalInstance.close({ status: 'edit', stores: $scope.connectStores })
    }

    $scope.remove = function () {
      $uibModalInstance.close({ status: 'remove', stores: [] })
    }

    function changeImageIndex (currentIndex, newIndex) {
      var image1 = $scope.connectStores[currentIndex]
      var image2 = $scope.connectStores[newIndex]
      $scope.connectStores[currentIndex] = image2
      $scope.connectStores[newIndex] = image1
    }

    $scope.previousShop = function (currentIndex) {
      changeImageIndex(currentIndex, (currentIndex - 1))
    }

    $scope.nextShop = function (currentIndex) {
      changeImageIndex(currentIndex, (currentIndex + 1))
    }
  }])
