prismanoteApp.controller('resetPasswordModalController', ['$scope', '$uibModalInstance', '$api', 'userId',
  function ($scope, $uibModalInstance, $api, userId) {
    $scope.userId = userId

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    $scope.close = function () {
      $uibModalInstance.close()
    }

    $scope.closeAlert = function () {
      $scope.alert = null
    }

    $scope.save = function () {
      $api.post('resetpassword', { userId: $scope.userId, password: $scope.password })

        .then(function () {
          $scope.password = undefined
          $scope.close()
        })

        .catch(function (reason) {
          console.error('Error wile resetting password', reason)
          $scope.alert = {
            type: 'danger',
            msg: reason // || response.statusText
          }
        })
    }
  }])
