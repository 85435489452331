prismanoteApp.controller('authController', ['$scope', '$rootScope', '$uibModal', '$stateParams', '$api', '$http', '$state', '$location', '$auth',
  function ($scope, $rootScope, $uibModal, $stateParams, $api, $http, $state, $location, $auth) {
    $scope.error = false

    $scope.openLoginModal = function (mode, redirect, data) {
      if (!mode || mode == '' || $rootScope.user) {
        var mode = 'login'
      }
      if (redirect && redirect == 'reload') {
        var reloadState = true
        redirect = false
      } else if (!redirect) {
        if (!redirect) {
          redirect = false
        }
      }

      if (!data) {
        data = null
      }

      var tab
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/login-modal.html',
        controller: 'loginModalController',
        keyboard: false,
        backdrop: 'static',
        resolve: {
          mode: function () {
            return mode
          },
          tab: function () {
            return tab
          },
          redirect: function () {
            return redirect
          },
          data: function () {
            return data
          }
        }
      })
      modalInstance.result.then(function () {
        if ($stateParams.to) {
          $state.go($stateParams.to)
        } else if (reloadState) {
          $state.reload()
        }
      }, function () {
        // Modal is dismissed
      })
    }

    $scope.goToLogin = function () {
      $scope.logout(false)
      $state.go('layout.login');
      return;
      if ($stateParams.to) {
        $state.go('layout.login', { to: $stateParams.to })
      } else if (reloadState) {
        $state.go('layout.login')
      }
    }

    // OBSOLETE
    $scope.activateAccount = function () {
      return
      // $api.post('activate-user', {id: $stateParams.id, code: $stateParams.code})

      // 	.then(function(response) {
      // 	})

      // 	.catch(function(reason) {
      // 	});
      if ($location.search() && $location.search().shopname) {
        $rootScope.isAPIAlreadyCalled = true
        var shopname = $location.search().shopname
        var body = {
          userId: $stateParams.id,
          code: $stateParams.code,
          shopname: shopname
        }
        if ($location.search().campaign) {
          body.campaignId = $location.search().campaign
        }
        $auth.isEmailExistThenLoggedIn(body).then(function (response) {
          $scope.message = 'User is activated'

          // For Automatic Login
          $rootScope.user = response.data
          $rootScope.guest = false

          var role = response.data.role == 'wholesaler' ? 'brand' : response.data.role
          if (role == 'retailer' && body.campaignId) {
            $state.go('retailer.paidcampaign', { isCampaignStep: 'join', campaignId: body.campaignId })
          } else {
            $state.go(role + '.home')
          }
        }).catch(function (error) {
          console.error('Error: ', response)
          $scope.message = response.data.message || response.statusText
          $scope.error = true
        })
      } else if (!$rootScope.isAPIAlreadyCalled) {
        $http.post('/api/activate-user', { id: $stateParams.id, code: $stateParams.code })
          .then(function successCallback (response) {
            $scope.message = 'User is activated'

            // For Automatic Login
            // var user = response.data.user;
            // $rootScope.user = user;
            // $rootScope.guest = false;
            // var role = user.role == 'wholesaler' ? 'brand' : user.role;
            // $state.go(role + ".home");
            $rootScope.user = response.data.user
            localStorage.setItem('currentUserName', $rootScope.user.firstName + ($rootScope.user.lastNamePrefix ? ' ' + $rootScope.user.lastNamePrefix : '') + ' ' + $rootScope.user.lastName)
            $rootScope.guest = false

            var role = response.data.user.role == 'wholesaler' ? 'brand' : response.data.user.role
            $state.go(role + '.home')
          }, function errorCallback (response) {
            console.error('Error: ', response)
            $scope.message = response.data.message || response.statusText
            $scope.error = true
          })
      }
    }

    $scope.resetPassword = function (activate) {
      $scope.alert = null
      if ($scope.resetPasswordForm.$invalid) {
        $scope.alert = {
          type: 'danger',
          msg: 'Controleer invoer'
        }
        return
      }
      var isValidPassword = passwordValidation($scope.data.password, $scope.data.confirmPassword)
      if (isValidPassword.isValid) {
        $scope.data.id = $stateParams.id
        $scope.data.code = $stateParams.code
        $scope.data.activate = activate
        $api.post('change-password', { data: $scope.data })
          .then(function (response) {
            $scope.alert = {
              type: 'success',
              msg: response.data.message
            }
          })
          .catch(function (reason) {
            $scope.alert = {
              type: 'danger',
              msg: reason.data.message
            }
          })
      } else {
        $scope.alert = {
          type: 'danger',
          msg: isValidPassword.message
        }
      }
    }

    $scope.closeAlert = function () {
      $scope.alert = null
    }

    $scope.logout = function (redirect) {
      if (!redirect) {
        redirect = false
      }
      $api.post('logout', { id: $rootScope.user._id, shopId: $rootScope.currentShop._id }).then(function () {
        $scope.alert = null
        $rootScope.user = null
        $rootScope.openChats = []
        $rootScope.currentEmployee = undefined
        $rootScope.currentShop = undefined;
        $rootScope.currentShop.nameSlug = undefined;
        localStorage.removeItem('currentShop')
        localStorage.removeItem('currentShopName');
        localStorage.removeItem('isEnableEmployeeLogin')
        localStorage.removeItem('lastLoggedInEmployees')
        window.fcWidget.user.clear().then(function(){
          console.info('User cleared from chat')
        }, function(reason){
          console.info('User not cleared from chat', reason)
        })
        $state.reload();
        if (redirect) {
          $state.go('layout.logout')
        }
      }).catch(function (reason) {
        console.error(reason)
      })
    }

    $scope.openLoginRegisterModal = function (mode) {
      var tab, data
      if ($scope.activeTab) {
        tab = $scope.activeTab
      } else if (!$scope.activeTab && $scope.selectedTestApp != 'Ontdek oplossingen') {
        // tab 0 is default 'null'
        tab = 5
      }
      var redirect = true
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/login-modal.html',
        controller: 'loginModalController',
        keyboard: false,
        backdrop: 'static',
        resolve: {
          mode: function () {
            return mode
          },
          tab: function () {
            return tab
          },
          redirect: function () {
            return redirect
          },
          data: function () {
            return data
          }
        }
      })
      modalInstance.result.then(function (result) {
        $scope.initShoppingCart()
      }, function () {

      })
    }

    // Checking password is strong or not and valid or not
    function passwordValidation (originalPassword, comparedPassword) {
      if (!originalPassword || !comparedPassword) {
        return { isValid: false, message: 'PASSWORD_AND_CONFIRM_ARE_REQUIRED' }
      }

      if (originalPassword.length < 8) {
        return { isValid: false, message: 'YOUR_PASSWORD_LENGTH_SHOULD_BE_AT_LEAST_8_CHARACTERS_LONG' }
      }

      if (originalPassword !== comparedPassword) {
        return { isValid: false, message: 'PASSWORD_ARE_CONFIRM_ARE_NOT_EQUAL' }
      }

      // Password contains Lower, Uper and Digits or not
      var variations = {
        digits: /\d/.test(originalPassword),
        lower: /[a-z]/.test(originalPassword),
        upper: /[A-Z]/.test(originalPassword),
        nonWords: /\W/.test(originalPassword)
      }
      var isVariation = true

      for (var check in variations) {
        if (!variations[check]) {
          isVariation = false
          break
        }
      }
      if (!isVariation) {
        return { isValid: false, message: 'YOUR_PASSWORD_SHOULD_CONTAINS_AT_LEAST_ONE_UPPERCASE_LOWERCASE_NUMBER_AND_SPECIAL_CHARACTER' }
      } else {
        return { isValid: true, message: 'PASSWORD_IS_VALID' }
      }
    }

    $scope.goBack = function () {
      // window.history.back()
      $state.go('retailer.home')
    }
  }])
