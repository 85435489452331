prismanoteApp.controller('grapeHomeController', ['$scope', '$rootScope', '$state', 'factory', 'grapeFactory', 'Upload', '$uibModal', function ($scope, $rootScope, $state, factory, grapeFactory, Upload, $uibModal) {
  
  console.log('Home Controller');

  $scope.wildCards = []
  $scope.errorMessage = ''
  $scope.websiteRemark = ''
  $scope.loading = false
  $scope.alert = {
    type: '',
    msg: ''
  }

  $scope.getWebsites = function (id) {
    return grapeFactory
      .getShopSpecificWebsites(id)
      .then(function (response) {
        if (response && response.data && response.data.websites && response.data.websites.length) {
          $scope.availableWebsites = response.data.websites
        } else {
          $scope.availableWebsites = []
        }
        // $scope.wildCards = response.data;
      })
      .catch(function (error) {
        $scope.alert.type = 'danger'
        $scope.alert.msg = error.data.message || 'Something went wrong. Please try again'
      })
  }

  $scope.closeAlert = function () {
    $scope.alert = {
      type: '',
      msg: ''
    }
  }

  $scope.selectTemplate = function (websiteId) {
    localStorage.setItem('activeEditingWebsiteId', websiteId)
    $state.go('retailer.buildertemplate', { websiteId: websiteId })
  }

  $scope.changeWebsiteRemark = function (websiteId, remark, index) {
    if ($scope.availableWebsites[index].websiteRemark !== remark) {
      $scope.loading = true
      return grapeFactory.updateWebsiteRemark(websiteId, $rootScope.currentShop._id, { websiteRemark: remark })
        .then(function (response) {
          $scope.alert = {
            type: 'success',
            msg: response.data.message
          }
          $scope.availableWebsites[index].websiteRemark = remark
          $scope.loading = false
        })
        .catch(function (error) {
          $scope.alert = {
            type: 'danger',
            msg: error.data.message
          }
          $scope.loading = false
        })
    } else {

    }
  }

  // $scope.cruise = cruises;
  $scope.limit = 4

  $scope.loadMore = function () {
    var increamented = $scope.limit + 4
    $scope.limit = increamented > $scope.availableWebsites.length ? $scope.availableWebsites.length : increamented
  }

  $scope.uploadlogoWebsiteName = function (file) {
    var websiteName = $scope.websiteName;
    var format = /[\/]/;
    if(format.test(websiteName)) {
      websiteName = websiteName.replace(/\s+/g, '').replace(/\//g, '-').toLowerCase()
    }
    $scope.loading = true
    var postData = {
      websiteName: websiteName,
      websiteRemark: $scope.websiteRemark
    }
    if (file) {
      file = Upload.rename(file, Date.now() + '.jpg')
      postData.file = file
    }

    return grapeFactory.createWebsite($rootScope.currentShop._id, postData)
      .then(function (response) {
        localStorage.setItem('activeEditingWebsiteId', response.data.result.websiteDetails._id)
        $state.go('retailer.buildertemplate', { websiteId: response.data.result.websiteDetails._id })
      })
      .catch(function (err) {
        console.log('inside error')
        $scope.alert = {
          type: 'danger',
          msg: err.data.message
        }
        $scope.loading = false
      })
  }

  $scope.wildCardViewFunc = function (wildcard) {
    factory.websiteName = wildcard.websiteNameSlug
    factory.pageName = 'index'
    factory.templateName = wildcard.activeTemplate
    factory.websiteOriginalName = wildcard.websiteName
    factory.websiteLogo = wildcard.logo
    factory.retailer = wildcard.shopId
    factory.websiteId = wildcard._id

    var obj = {}
    obj.websiteName = factory.websiteName
    obj.pageName = factory.pageName
    obj.templateName = factory.templateName
    console.log(obj)
    localStorage.setItem('websiteInfo', JSON.stringify(obj))
    localStorage.setItem('factory', JSON.stringify(factory))

    $state.go('buildereditor', { websiteId: wildcard._id, shopId: $rootScope.currentShop._id })
  }

  $scope.removeWebsite = function (singleWebsite, index) {
    // Take confirmation from user for delete
    var modalInstance = $uibModal.open({
      templateUrl: '../views/modal/confirm-modal.html',
      controller: 'confirmModalController',
      resolve: {
        modalHeader: function () {
          return !singleWebsite.attachedDomain ? 'Confirmation' : 'Attention'
        },
        modalMessage: function () {
          return !singleWebsite.attachedDomain ? "Are you sure you want to delete this website? It's not possible to restore it. Click on 'Cancel' if you don't want to delete your website" : "This website is attache with domain so can't delete it."
        },
        okAndCancelBothAction: function () {
          return !singleWebsite.attachedDomain
        }
      }
    })

    return modalInstance
      .result
      .then(function (result) {
        if (result && !singleWebsite.attachedDomain) {
          $scope.loading = true
          return grapeFactory.removeWebsite($rootScope.currentShop._id, singleWebsite._id)
        } else {
          return ''
        }
      })
      .then(function (response) {
        if (response !== '') {
          $scope.availableWebsites.splice(index, 1)
          $scope.loading = false
          $scope.alert = {
            type: 'success',
            msg: result.data.message
          }
        }
      })
      .catch(function (error) {
        $scope.loading = false
        $scope.alert = {
          type: 'danger',
          msg: error.data.message
        }
      })
  }

  // Remove domain from particular website
  $scope.removeDomain = function (wildCard, index) {
    try {
      // Take confirmation from user for delete
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/confirm-modal.html',
        controller: 'confirmModalController',
        resolve: {
          modalHeader: function () {
            return 'Confirmation'
          },
          modalMessage: function () {
            return 'Are you sure you want to remove domain from this website'
          },
          okAndCancelBothAction: function () {
            return true
          }
        }
      })

      modalInstance
        .result
        .then(function (result) {
          if (result) {
            return grapeFactory.removeDomain1($rootScope.currentShop._id, wildCard.config.websiteName, wildCard.config.domainDetails.domain)
          } else {
            return ''
          }
        })
        .then(function (response) {
          console.log('response', response)
          if (response !== '') {
            $scope.alert.type = 'success'
            $scope.alert.msg = response.data.message
            $scope.getWebsites($rootScope.currentShop._id)
          }
        })
        .catch(function (e) {
          console.log('error ', e)
        })
    } catch (e) {
      console.log('error in catch', e)
    }
  }

  $scope.GetFileName = function () {
    var fileInput = document.getElementById('logoFile')
    $scope.fileName = fileInput.value.split(/(\\|\/)/g).pop()
  }

  // Copy website
  $scope.copyWebsite = function (websiteId) {
    $scope.loading = true
    return grapeFactory.copyWebsite(websiteId, $rootScope.currentShop._id)
      .then(function (response) {
        $scope.alert = {
          type: 'success',
          msg: response.data.message
        }

        $scope.availableWebsites.push(response.data.result.websiteDetail)
        $scope.loading = false
      })
      .catch(function (error) {
        $scope.alert = {
          type: 'danger',
          msg: error.data.message
        }
        $scope.loading = false
      })
  }

  $scope.updateWebsiteName = function (website, index, data) {
    $('.fa-check').toggle();
    $('.fa-pencil-alt').toggle();
    $('.editWebName').toggle();
    $('.webNameTitle').toggle();
    if(data.action == 'update') {
      var data = {
        websiteName: website.websiteName
      }
      return grapeFactory.updateWebsiteName(website._id, website.shopId, data)
      .then(function (response) {
        $scope.alert = {
          type: 'success',
          msg: response.message
        }
      })
      .catch(function (error) {
        $scope.alert = {
          type: 'danger',
          msg: response.error
        }
      })
    }
}
}])
prismanoteApp.config(['$qProvider', function ($qProvider) {
  $qProvider.errorOnUnhandledRejections(false)
}])
