prismanoteApp.controller('adminNewsItemController', ['$scope', '$rootScope', '$api', '$state', 'Upload', '$stateParams', 'prompt', '$news',
  function ($scope, $rootScope, $api, $state, Upload, $stateParams, prompt, $news) {
    $scope.newsItem = {
      brand: {},
      author: {
        name: $rootScope && $rootScope.user ? ($rootScope.user.firstName + ($rootScope.user.lastNamePrefix ? ' ' + $rootScope.user.lastNamePrefix : '') + ' ' + $rootScope.user.lastName) : ''
      },
      isPublished: true,
      publicationDate: new Date()
    }

    $scope.getNewsItem = function () {
      $api.get('news/' + $stateParams.nameSlug)

        .then(function (response) {
          $scope.newsItem = response.data.newsItem
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    var getItems = function (type) {
      $api.get(type)

        .then(function (response) {
          $scope[type] = response.data[type]
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.getBrands = function () {
      return getItems('brands')
    }

    $scope.getCompanies = function () {
      return getItems('companies')
    }

    var searchItems = function (str, type) {
      var matches = []

      $scope[type].forEach(function (item) {
        if (
          (item.name && item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
					(item.nameSlug && item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
          matches.push(item)
        }
      })

      return matches
    }

    $scope.searchBrands = function (str) {
      return searchItems(str, 'brands')
    }

    $scope.searchCompanies = function (str) {
      return searchItems(str, 'companies')
    }

    $scope.brandSelected = function (selected) {
      var brand = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug,
        description: selected.originalObject.description,
        images: selected.originalObject.images
      }

      $scope.newsItem.brand = brand
    }

    $scope.companySelected = function (selected) {
      var company = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug,
        description: selected.originalObject.description,
        images: selected.originalObject.images
      }

      $scope.newsItem.company = company
    }

    $scope.updateNewsItem = function () {
      $news.newsValidation($scope.newsItem).then(function (validNews) {
        $scope.uploadOtherPhoto()
        $api.put('news/' + $stateParams.nameSlug, { newsItem: $scope.newsItem })
          .then(function () {
            $state.go('admin.news')
          })
          .catch(function (reason) {
            console.log(reason)
          })
      }).catch(function (InvalidNewsErr) {
        console.log('InvalidNewsErr: ', InvalidNewsErr)
        $scope.alert = {
          type: 'danger',
          msg: InvalidNewsErr.message
        }
      })
    }

    $scope.uploadOtherPhoto = function () {
      if ($scope.newsItem.photo) {
        $scope.upload = true
        Upload.upload({
          url: 'api/news/news-photo-upload',
          data: {
            newsItemId: $scope.newsItem._id,
            file: $scope.newsItem.photo // file input field
          }
        })
          .then(function (res) {
            // wanneer uploaden gelukt
            $scope.newsItem = null
            $scope.alert = {
              type: 'success',
              msg: 'Uw gegevens zijn verzonden!'
            }
          }, function (res) {
            // wanneer uploaden mislukt
            console.log('Error: ', res.status)
            $scope.alert = {
              type: 'danger',
              msg: 'upload error: ' + res.status
            }
          }, function (evt) {
            // tijdens upload
            // var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            // $scope.uploadProgress = progressPercentage;
          })
        $scope.upload = false
      }
    }

    $scope.removeNewsItem = function () {
      prompt({
        title: 'Bericht verwijderen?',
        message: 'Weet u zeker dat u dit bericht wilt verwijderen?'
      }).then(function () {
        $api.delete('news/' + $stateParams.nameSlug)

          .then(function () {
          })

          .catch(function (reason) {
            console.log(reason)
            $scope.alert = {
              type: 'danger',
              msg: reason
            }
          })
        if (!$scope.alert) {
          $state.go('admin.news')
        }
      })
    }

    $scope.addNewsItem = function () {
      $news.newsValidation($scope.newsItem).then(function (validNews) {
        $api.post('news/', { newsItem: $scope.newsItem })
          .then(function (response) {
            if ($scope.newsItem.photo) {
              // Only upload when there is something to upload
              $scope.upload = true
              Upload.upload({
                url: 'api/news/news-photo-upload',
                data: {
                  newsItemId: response.data.newsItem._id,
                  file: $scope.newsItem.photo
                }
              })
                .then(function (res) {
                  $scope.alert = {
                    type: 'success',
                    msg: 'Uw gegevens zijn verzonden!'
                  }
                  $state.go('admin.news')
                }, function (res) {
                  console.log('Error: ', res.status)
                  $scope.alert = {
                    type: 'danger',
                    msg: 'upload error: ' + res.status
                  }
                }, function (evt) {
                  // tijdens upload
                  var progressPercentage = parseInt(100.0 * evt.loaded / evt.total)
                  $scope.uploadProgress = progressPercentage
                })
              $scope.upload = false
            } else {
              $scope.alert = {
                type: 'success',
                msg: 'Uw gegevens zijn verzonden!'
              }
              $state.go('admin.news')
            }
          })
          .catch(function (reason) {
            console.log(reason)
            $scope.alert = {
              type: 'danger',
              msg: 'Het lukte niet om uw gegevens op te slaan ' + reason
            }
            $scope.upload = false
          })
      }).catch(function (InvalidNewsErr) {
        console.log('InvalidNewsErr: ', InvalidNewsErr)
        $scope.alert = {
          type: 'danger',
          msg: InvalidNewsErr.message
        }
      })
    }

    $scope.closeAlert = function () {
      $scope.alert = 0
    }
  }])
