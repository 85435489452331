prismanoteApp.controller('adminResetPasswordModalController', ['$scope', 'userId', '$api', '$uibModalInstance',
  function ($scope, userId, $api, $uibModalInstance) {
    $scope.mustResetPassword = true

    $scope.changePassword = function () {
      $api.post('reset-password', {
        password: $scope.password,
        userId: userId,
        mustReset: $scope.mustResetPassword
      })
        .then(function (response) {
          $uibModalInstance.close()
        })
        .catch(function (reason) {
          console.error(reason)
          $scope.alert = {
            type: 'danger',
            msg: reason
          }
        })
    }

    $scope.generatePassword = function () {
      $scope.password = makePassword()

      function makePassword () {
        var text = ''
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_=/?,€;:|'

        for (var i = 0; i < 10; i++) { text += possible.charAt(Math.floor(Math.random() * possible.length)) }

        return text
      }
    }
  }
])
