prismanoteApp.controller('retailerController', ['$scope', '$state', '$rootScope', '$retailer', '$data', '$language', '$retailerFilters', '$notification', '$chat', '$device',
  function ($scope, $state, $rootScope, $retailer, $data, $language, $retailerFilters, $notification, $chat, $device) {
    $rootScope.shopSaved = false

    $scope.closeAlert = function () {
      $rootScope.shopSaved = false
    }

    // function resetCurrentFilters () {
    //   $retailerFilters.removeCategoryFilter()
    //   $retailerFilters.removeGenderFilter()
    //   $retailerFilters.removeParticularsFilter()
    //   $retailerFilters.removeProductFilter()
    //   $retailerFilters.removeIndicationFilter()
    //   $retailerFilters.removeStrapTypeFilterFilter()
    //   $retailerFilters.removeSupplierFilter()
    //   $retailerFilters.removeCollectionFilter()
    //   $retailerFilters.removeBrandFilter()
    // }

    $scope.changeDevice = function () {
      $device.setFirstDevice()
    }

    $scope.getShop = function (nameSlug, force, show, isShopChange) {
      if (!force) {
        force = false
      } else {
        $rootScope.currentShop = {}
      }
      if (!show) {
        show = false
      }

      $rootScope.selectedShop = nameSlug

      $retailer.getShop(nameSlug)
        .then(function (shop) {
          $rootScope.currentShop = shop
          //$rootScope.socketInit()
          $rootScope.openChats = []

          $scope.$broadcast('changeSelectedShop', nameSlug)
          localStorage.setItem('currentShop', $scope.currentShop.nameSlug)
          localStorage.setItem('currentShopName', $scope.currentShop.name)
          $device.getCurrentDevice()
          if (isShopChange) localStorage.removeItem('lastLoggedInEmployees') // Removing old localstorage employee if any
          $rootScope.unreadNotifications = shop.unreadNotifications
          $rootScope.unreadMessageNotifications = shop.messageNotification.length

          if ($rootScope.unreadNotifications > 0) {
            var title = 'PrismaNote'
            if ($state.$current.data && $state.$current.data.pageTitle) {
              title = title + ' | ' + $language.translate($state.$current.data.pageTitle)
            }
            // title = '(' + $rootScope.unreadNotifications + ') ' + title
            $rootScope.pageTitle = title
            // Write also direct to document to show the result directly
            document.title = title
          }
        })
        .catch(function (reason) {
          console.error(reason)
        })
    }

    // check if there is an shop in de localstorage to fetch, otherwhise get the first shop from the user
    if ($rootScope.user && $rootScope.user.role === 'retailer' && $rootScope.user.shops && $rootScope.user.shops.length > 0) {
      var savedSlug = localStorage.getItem('currentShop')
      var isShopExist = false;
      if (savedSlug) {
        for (var i = 0; i < $rootScope.user.shops.length; i++) {
          if ($rootScope.user.shops[i] && $rootScope.user.shops[i].nameSlug === savedSlug) {
            isShopExist = true;
            break;
          }
        }
      }
      if (!isShopExist) {
        savedSlug = $rootScope.user.shops[0].nameSlug;
      }
      $scope.getShop(savedSlug || $rootScope.user.shops[0].nameSlug)
    }

    $scope.sendTestNotification = function () {
      $notification.sendTestNotification()
    }

    $scope.shopChangeToolTip = function () {
      var canChange = $rootScope.checkChangeShopAllowed()
      var message = ''
      if (!canChange) {
        message = 'If you have rights to change the shop then go to the dashboard first'
      }
      return message ? '<html> <body> <p> ' + message + '</p> </body> </html>' : ''
    }

    $scope.showLock = function () {
      var isEnableEmployeeLoginForThisDevice = localStorage.getItem('isEnableEmployeeLogin')
      isEnableEmployeeLoginForThisDevice = !!((isEnableEmployeeLoginForThisDevice === 'true' || isEnableEmployeeLoginForThisDevice === true))

      return !!((isEnableEmployeeLoginForThisDevice && $rootScope.currentShop.employeeLockTime))
    }

    $scope.openChatWindow = function (notification) {
      if ($chat.isChatAlreadyOpen(notification.remarkId)) {
        return
      }

      var body = {
        eType: notification.eType,
        remarkId: notification.remarkId
      }
      $chat.getChatDetail(body).then(function (response) {
        if (response && response.data && response.data.data) {
          var chatresponse = response.data.data
          if (chatresponse.remarks && chatresponse.remarks.length && chatresponse.details) {
            $chat.addChatIntoRootScope(chatresponse)
          }
        }
      }).catch(function (error) {
        console.error('Error while fetching the chat detail ', error)
      })
    }

    $scope.menucss50 = {
      'pointer-events': 'none',
      opacity: 0.5
    }

    $scope.menucss70 = {
      'pointer-events': 'none',
      opacity: 0.5
    }

    $scope.menucss80 = {
      'pointer-events': 'none',
      opacity: 0.5
    }
  }])
