prismanoteApp.controller('retailerStatisticsControllerNew', ['$scope', '$rootScope', '$api', '$language', '$retailer', '$q',
  function ($scope, $rootScope, $api, $language, $retailer, $q) {

    // 		$scope.period = "week";

    //         $scope.filter = {
    //             startDate: new Date(new Date(moment().subtract(1, 'week')).setHours(0, 0, 0)),
    //             endDate: new Date(new Date().setHours(23, 59, 59))
    //         };

    //         $scope.loading = true;

    //         $scope.chartMode = 'turnover';
    //         $scope.statisticsData = {};

    //         $scope.suppliers = {};
    //         $scope.employees = [];

    //         $scope.selectedGroups = [];
    //         $scope.selectedSuppliers = [];
    //         $scope.selectedEmployees = [];

    //         $scope.totalTurnover = 0;
    //         $scope.totalVat = 0;
    //         $scope.turnOverWithoutVat = 0;
    //         $scope.grossProfit = 0;
    //         $scope.totalNumberOfOrders = 0;

    //         $scope.fetchStatistics = function () {
    //             $scope.loading = true;

    //             $scope.filter['suppliers'] = $scope.selectedSuppliers.map(function(key) { return key._id; });
    //             $scope.filter['collections'] = $scope.selectedGroups.map(function(key) { return key._id; });
    //             $scope.filter['employees'] = $scope.selectedEmployees.map(function(key) { return key._id; });

    //             if ($rootScope.currentShop && $rootScope.currentShop._id) {
    //                 $api
    //                 .get('shop/'+ $rootScope.currentShop._id + '/statistics', {
    //                     filters: $scope.filter,
    //                     language: $rootScope.language
    //                 }, null, 2)
    //                 .then(function(res) {
    //                     $scope.statisticsData = res.data.result;

    //                     $scope.totalNumberOfOrders = $scope.statisticsData.transactions.shopPurchase.paid +
    //                         $scope.statisticsData.transactions.services.paid -
    //                         $scope.statisticsData.transactions.shopPurchase.refund -
    //                         $scope.statisticsData.transactions.services.refund;

    //                     $scope.totalTurnover = $scope.statisticsData.transactions.shopPurchase.totalEarning +
    //                         $scope.statisticsData.transactions.services.totalEarning +
    //                         $scope.statisticsData.transactions.shopPurchase.totalRefundAmount +
    //                         $scope.statisticsData.transactions.services.totalRefundAmount;

    //                     $scope.totalVat = $scope.statisticsData.transactions.shopPurchase.totalVat +
    //                         $scope.statisticsData.transactions.services.totalVat +
    //                         $scope.statisticsData.transactions.services.refundedTotalVat +
    //                         $scope.statisticsData.transactions.shopPurchase.refundedTotalVat;

    //                     $scope.grossProfit = $scope.statisticsData.transactions.shopPurchase.grossProfitExcludingVat +
    //                         $scope.statisticsData.transactions.services.grossProfitExcludingVat +
    //                         $scope.statisticsData.transactions.services.refundedGrossProfitExcludingVat +
    //                         $scope.statisticsData.transactions.shopPurchase.refundedGrossProfitExcludingVat;

    //                     $scope.totalDiscount = $scope.statisticsData.transactions.shopPurchase.totalDiscount +
    //                         $scope.statisticsData.transactions.services.totalDiscount;

    //                     $scope.totalRefundAmount = $scope.statisticsData.transactions.shopPurchase.totalRefundAmount +
    //                         $scope.statisticsData.transactions.services.totalRefundAmount;

    //                     // set suppliers
    //                     $scope.suppliers = $scope.statisticsData.suppliers.map(function (key) {
    //                         if ($scope.filter['suppliers'].indexOf(key._id) >= 0) {
    //                             key['ticked'] = true;
    //                         }
    //                         return key
    //                     });

    //                     // set collections
    //                     $scope.groups = $scope.statisticsData.articleGroupCollection.map(function (key) {
    //                         if ($scope.filter['collections'].indexOf(key._id) >= 0) {
    //                             key['ticked'] = true;
    //                         } else {
    //                             key['ticked'] = false;
    //                         }

    //                         return key;
    //                     });

    //                     // set employees
    //                     $scope.employees = $scope.statisticsData.employees.map(function (key) {
    //                         console.log("key ===========>", key);
    //                         if ($scope.filter['employees'].indexOf(key._id) >= 0) {
    //                             key['ticked'] = true;
    //                         } else {
    //                             key['ticked'] = false;
    //                         }

    //                         return key;
    //                     });

    //                     $scope.buildChart($scope.statisticsData.chart, $scope.chartMode);

    //                     $scope.loading = false;

    //                 })
    //                 .catch(function(err) {
    //                     $scope.loading = false;
    //                     console.log("err", err);
    //                 })
    //             }
    //         };

    //         $scope.updateByDateRange = function(){
    //             $scope.filter.startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
    //             $scope.filter.endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
    //             $scope.fetchStatistics();
    //         };

    //         $scope.setFilter = function(period){
    //             $scope.filter.period = period;
    //             $scope.filter.endDate = new Date(new Date().setHours(23, 59, 59));

    //             if(period == 'day'){
    //                 $scope.period = "day";
    //                 $scope.filter.startDate = new Date(new Date().setHours(0, 0, 0));
    //             }
    //             if(period == 'week'){
    //                 $scope.period = "week";
    //                 $scope.filter.startDate = new Date(new Date(moment().subtract(1, 'week')).setHours(0, 0, 0));
    //             }
    //             if(period == 'month'){
    //                 $scope.period = "month";
    //                 $scope.filter.startDate = new Date(new Date(moment().subtract(1, 'month')).setHours(0, 0, 0));
    //             }

    //             if(period == 'year'){
    //                 $scope.period = "year";
    //                 $scope.filter.startDate = new Date(new Date(moment().subtract(1, 'year')).setHours(0, 0, 0));
    //             }
    //             $scope.fetchStatistics();
    //         }

    //         $scope.getShop = function(){
    //             return $q(function (resolve, reject) {
    //                 if($rootScope.currentShop){
    //                     return resolve($rootScope.currentShop)
    //                 }else{
    //                     $retailer.getShop()
    //                     .then(function(shop) {
    //                         return resolve(shop)
    //                     })
    //                     .catch(function(reason){
    //                         return reject(reason);
    //                     })
    //                 }
    //             })
    //         }

    //         $scope.supplierClose = function() {
    //             $scope.fetchStatistics();
    //         };

    //         $scope.toggleChart = function(){
    //             if($scope.chartMode == 'turnover'){
    //                 $scope.chartMode = 'profit';
    //             }else{
    //                 $scope.chartMode = 'turnover';
    //             }
    //             $scope.buildChart($scope.statisticsData.chart, $scope.chartMode)
    //         }

    //         $scope.buildChart = function(data, mode){
    //             console.log("data", data);
    //             console.log("mode", mode);

    //             var ctx = document.getElementById('chart').getContext('2d');
    //             if($scope.chartInstance){
    //                 $scope.chartInstance.destroy();
    //             }
    //             $scope.chartInstance = new Chart(ctx, {
    //                 type: 'line',

    //                 // The data for our dataset
    //                 data: {
    //                     labels: data.labels,
    //                     datasets: data[mode]
    //                 },

    //                 // Configuration options go here
    //                 options: {
    //                     responsive: true,
    //                     title: {
    //                         display: true,
    //                         text: (mode == 'turnover' ? $language.translate('TURNOVER') : $language.translate('GROSS_PROFIT') + '(Ex. VAT)') + ' ' + $language.translate('PER_ARTICLEGROUP')
    //                     },
    //                     tooltips: {
    //                         mode: 'index'
    //                     },
    //                     hover: {
    //                         mode: 'index'
    //                     },
    //                     scales: {
    //                         yAxes: [{
    //                             stacked: true
    //                         }]
    //                     },
    //                     line: {
    //                         stepped: true
    //                     }
    //                 }
    //             })
    //         };

    //         $scope.articleGroupClose = function() {
    //             $scope.fetchStatistics();
    //             console.log("$scope.groups", $scope.groups);
    //         };

    //         $scope.employeeClose = function() {
    //             console.log("$scope.selectedEmployees", $scope.selectedEmployees);
    //             $scope.fetchStatistics();
    //         };

    //         $scope.$on('changeSelectedShop', function () {
    // 			$retailer.getShop()
    // 			.then(function (shop) {
    // 				$rootScope.currentShop = shop;
    //                 $scope.fetchStatistics();
    // 			})
    //         });

    //         /*

    //         //End fake data

    //         $employee.getEmployees().then(function (response) {
    //             response.forEach(function(emp){
    //                 $scope.employees.push({
    //                     name: emp.firstName + ' ' + (emp.lastNamePrefix ? emp.lastNamePrefix + ' ' : '') + emp.lastName,
    //                     _id: emp._id,
    //                     active: false
    //                 })
    //             })
    //         }).catch(function (error) {
    //         });

    //         $scope.getStatistics = function(filter){
    //             $scope.alert = null;
    //             $scope.loading = true;
    //             $scope.getShop()
    //             .then(function(shop) {
    //                 if(filter === false){
    //                     $scope.filter.period = null;
    //                 }
    //                 $scope.filter.employees = $scope.selectedEmployees;
    //                 $scope.filter.collections = $scope.selectedGroups;
    //                 $scope.filter.suppliers = $scope.selectedSuppliers;

    //                 $api.get('shop/'+ shop._id + '/statistics', {
    //                     filters: $scope.filter,
    //                     language: $rootScope.language
    //                 }, null, 2)
    //                 .then(function(res){
    //                     $scope.totals = res.data.totals;
    //                     $scope.date = res.data.date;
    //                     $scope.sellTypes = res.data.sellTypes;

    //                     $scope.outstanding = res.data.statistics.outstanding;
    //                     $scope.sells = res.data.statistics.sells;
    //                     $scope.articleGroups = res.data.statistics.articleGroups;

    //                     $scope.chart = res.data.chart;

    //                     $scope.areaData = $scope.chart.dataArrayTurnover;
    //                     $scope.yKeys = $scope.chart.yKeys;
    //                     $scope.labelNames = $scope.chart.labelNames;
    //                     $scope.loading = false;

    //                     $scope.buildChart($scope.chart, $scope.chartMode);

    //                 })
    //                 .catch(function(reason){
    //                     $scope.loading = false;
    //                     console.error("Error while loading statistics", reason.data ? reason.data : reason)
    //                     $scope.alert = {
    //                         type: 'danger',
    //                         msg: $language.translate(reason.data ? reason.data : reason)
    //                     }
    //                 })
    //             })
    //         }

    //         // $scope.setFilter('week');
    // */
  }])
