prismanoteApp.controller('retailerSelectTransactionItemsModalController', ['$scope', '$uibModalInstance', 'transaction', '$language',
  function ($scope, $uibModalInstance, transaction, $language) {

    $scope.roundPrice = function (price) {
      //Round the price's to 2 decimals, but check the third decimal for correct roundings
      //see https://stackoverflow.com/a/11832950/4548006
      return Math.round((price + Number.EPSILON) * 100) / 100
    }

    $scope.calculateProductPrice = function(item, original, tax) {

      if (item.type === 'points' || item.type === 'giftcard') {
        if(original) {
          return item.originalPrice
        } else {
          if( tax) {
            return item.price * (1 + (item.priceVat === null ? 21 : item.priceVat) / 100)
          } else {
            return item.price
          }
        }
      }

      var price = item.price
      if( item.typeDetail === 'rest_payment'){
        if(item.priceCalculated) {
          price = item.priceCalculated
          return price
        } else {
          if(item.type === 'repair' || item.type === 'order') {
            price = item.originalTotal - item.total
          } else {
            if (tax) {
              price = (item.originalPrice * (1 + (item.priceVat / 100))) - item.total
            } else {
              price = item.originalPrice - item.total
            }
          }
          price = $scope.roundPrice(price)
          item.priceCalculated = price
          return price
        }
      }
      if( item.type === 'repair' || item.type === 'order') {
        price = item.originalTotal - item.total
      }

      if (tax) {
        price = item.price * (1 + (item.priceVat === null ? 21 : item.priceVat) / 100)
      }
      if (item.total > ( (item.price * (1 + (item.priceVat / 100) ) * item.quantity))) {
        price = item.total
      } else if (item.price > item.total) {
        price = item.total
      }
      if(item.discountPercent) {
        return price - (item.quantity * price * (item.discountValue / 100))
      }
      if (item.discountValue) {
        return price - (item.quantity * item.discountValue)
      }
      return $scope.roundPrice(price)
    }

    if (transaction) {
      $scope.transaction = transaction

      if ($scope.transaction.details) {
        if ($scope.transaction.details.length === 1) {
          $scope.transaction.details[0].checked = true
        }
        for (var i = 0; i < $scope.transaction.details.length; i++) {
          if( ($scope.transaction.details[i].total < $scope.transaction.details[i].price) || ($scope.transaction.details[i].price === 0) ||
            $scope.transaction.details[i].type === 'repair' || $scope.transaction.details[i].type === 'order' &&
            ($scope.transaction.details[i].type !== 'points' || $scope.transaction.details[i].type === 'giftcard' )) {
            $scope.transaction.details[i].typeDetail = 'rest_payment'
            $scope.transaction.details[i].checked = true
          } else {
            $scope.transaction.details[i].typeDetail = 'refund'
          }

          if($scope.transaction.details[i].quantity === undefined) {
            $scope.transaction.details[i].quantity = 1
          }
          if($scope.transaction.details[i].typeDetail === 'rest_payment') {
            $scope.transaction.details[i].quantity = $scope.transaction.details[i].originalQuantity
          }
          var price = $scope.calculateProductPrice($scope.transaction.details[i], false, true)
          $scope.transaction.details[i].price = angular.copy(price)
          $scope.transaction.details[i].originalPrice = $scope.transaction.details[i].originalPrice ? $scope.transaction.details[i].originalPrice : $scope.transaction.details[i].price
          $scope.transaction.details[i].originalPurchasePrice = $scope.transaction.details[i].originalPurchasePrice ? $scope.transaction.details[i].originalPurchasePrice : $scope.transaction.details[i].purchasePrice
        }
      }

      if($scope.transaction.type === 'webshop' && $scope.transaction.shippingCosts > 0) {
        $scope.transaction.details.push({
          name: $language.translate('SHIPPING_COSTS'),
          quantity: 1,
          originalQuantity: 1,
          price: $scope.transaction.shippingCosts,
          originalPrice: $scope.transaction.shippingCosts,
          checked: true,
          shipping: true,
          typeDetail: 'refund'
        })
      }

    }

    $scope.selectAll = function (value) {
      for (var i = 0; i < $scope.transaction.details.length; i++) {
        $scope.transaction.details[i].checked = value
        if(!$scope.transaction.details[i].quantity) {
          $scope.transaction.details[i].quantity = 1
          $scope.transaction.details[i].originalQuantity = 1
        }
        if(!$scope.transaction.details[i].price) {
          $scope.transaction.details[i].price = $scope.transaction.details[i].originalPrice * $scope.transaction.details[i].quantity
        }
        $scope.transaction.details[i].price = $scope.calculateProductPrice($scope.transaction.details[i], false, false)
      }
    }

    $scope.checkQuantity = function(detail) {
      if(!detail.quantity) {
        detail.quantity = 1
        detail.originalQuantity = 1
      }
      if(!detail.price) {
        detail.price = detail.originalPrice * (1 + (detail.priceVat / 100))
      }
      detail.price = $scope.calculateProductPrice(detail, false, false)
    }

    $scope.changeDetailType = function(detail, type) {
      detail.typeDetail = type
      detail.price = $scope.calculateProductPrice(detail, false, false)
    }

    $scope.totalCheckedValue = function () {
      var checked = $scope.transaction.details.filter(function (d) { return d.checked && d.quantity !== 0 })

      var totalAmount = 0

      if (checked && checked.length > 0) {
        var counter = 0
        for (var i = 0; i < checked.length; i++) {
          totalAmount += $scope.calculateProductPrice(checked[i], false, false)
          counter++
          if (counter === checked.length) {
            return totalAmount
          }
        }
      } else {
        return totalAmount
      }
    }

    $scope.close = function () {
      var details = $scope.transaction.details.filter(function (d) { return d.checked && ( (d.type === 'product' && d.quantity > 0) || d.type !== 'product' ) })
      $uibModalInstance.close({ details: details, transactionId: $scope.transaction._id, originalTransaction: $scope.transaction })
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss()
    }
  }])
