prismanoteApp.controller('retailerWeborderController', ['$q', 'Lightbox', '$state', '$filter', '$pdf', '$stateParams', '$scope', '$rootScope', '$uibModal', '$api', '$language', '$http', 'FileSaver', 'Blob', '$retailer', 'dialogs', '$log', '$transaction', '$printer', '$customer', '$thermalPrint',
  function ($q, Lightbox, $state, $filter, $pdf, $stateParams, $scope, $rootScope, $uibModal, $api, $language, $http, FileSaver, Blob, $retailer, dialogs, $log, $transaction, $printer, $customer, $thermalPrint) {
    $scope.transaction = {}
    $scope.errorMessage = ''
    $scope.loading = true
    $scope.orderStatusIndication = 0
    $scope.availableOptionsForUpdateStatus = []
    $scope.isValidForUpdateStaus = false
    $scope.refundAmount = 0
    $scope.totalVAT = 0
    $scope.totalAmountforCurrentShop = 0
    $scope.totalOfProductsWithShipping = 0
    $scope.shippingTrackURL = ''
    $scope.initial = true
    $scope.printEnabled = false

    $scope.sending = {
      carrier: '',
      trackTraceCode: '',
      shippingTrackURL: ''
    }

    $scope.statusNameForRetailer = {
      pending_payment: $language.translate('PENDING_PAYMENT'),
      canceled: $language.translate('CANCELLED'),
      cancelled: $language.translate('CANCELLED'),
      Cancelled: $language.translate('CANCELLED'),
      refund: $language.translate('REFUNDED'),
      partial_refund: $language.translate('PARTIAL_REFUNDED'),
      exchanged: $language.translate('EXCHANGED'),
      processing: $language.translate('PROCESSING')
    }

    $scope.notValidStatusForUpdate = ['canceled', 'cancelled', 'Cancelled', 'refund','exchanged']

    // Transaction status
    $scope.transactionsStatusOption = [
      {
        value: 'pending_payment',
        text: $language.translate('PENDING_PAYMENTS')
      },
      {
        value: 'new',
        text: $language.translate('NEW')
      },
      {
        value: 'cancelled',
        text: $language.translate('CANCELLED')
      },
      {
        value: 'completed',
        text: $language.translate('COMPLETED')
      },
      {
        value: 'refund',
        text: $language.translate('REFUND')
      }
    ]


    // Fetch transaction details
    $scope.getTransactionDetails = function () {
      $scope.loading = true

      $retailer.getShop()
        .then(function (shop) {
          $rootScope.currentShop = shop

          $scope.jspm = !shop.printNode || shop.printNode.enabled === false

          $api.get('webshop-transaction/' + $stateParams.transactionId + '/' + $rootScope.currentShop._id)
            .then(function (response) {
              if (response && response.data && response.data.result && response.data.result.transaction) {
                $scope.transaction = response.data.result.transaction
                $scope.transaction.details = $scope.transaction.details.filter(function (detail) {
                  return detail.type != 'giftWrap' && detail.type != 'engraving';
                });
                $scope.refundAmount = $scope.transaction.orderTotal
              } else {
                $scope.errorMessage = response.data.message
              }

              $scope.initial = true
              $scope.checkOrderStatusIndication($scope.transaction.status)
              $scope.checkForAvailableStatus($scope.transaction.status)

              $scope.loading = false

              $scope.thermalPrintEnabled = false
              if($scope.currentDevice.thermalPrinter && $scope.currentDevice.thermalPrinter.enabled) {
                if ($rootScope.currentDevice.thermalPrinter.printNode && $rootScope.currentDevice.thermalPrinter.printNode.printerId) {
                  $scope.thermalPrintEnabled = true
                }
              }
            })
            .catch(function (reason) {
              $scope.errorMessage = reason
              $scope.loading = false
            })
        })
    }

    // Check status indication index for retailer to this particular transaction
    $scope.checkOrderStatusIndication = function (status) {
      if($scope.transaction.type === 'product-reserve'){
        if (status === 'pending_payment') {
          $scope.orderStatusIndication = 1
        } else if (status === 'new') {
          $scope.orderStatusIndication = 2
        } else if(status === 'processing'){
          $scope.orderStatusIndication = 3
        } else if (status === 'completed') {
          $scope.orderStatusIndication = 4
        } else if (status === 'canceled') {
          $scope.orderStatusIndication = 5
        } else {
          $scope.orderStatusIndication = 0
        }
      } else {
        if (status === 'pending_payment') {
          $scope.orderStatusIndication = 1
        } else if (status === 'new') {
          $scope.orderStatusIndication = 2
        } else if (status === 'completed') {
          $scope.orderStatusIndication = 3
        } else if (status === 'refund') {
          $scope.orderStatusIndication = 4
        } else if (status === 'exchanged') {
          $scope.orderStatusIndication = 4
        } else if (status === 'canceled') {
          $scope.orderStatusIndication = 5
        } else {
          $scope.orderStatusIndication = 0
        }
      }
    }

    // Show image into lightbox
    $scope.openLightbox = function (images) {
      Lightbox.openModal(images, 0)
    }

    // Get discount per item
    $scope.getItemDiscount = function (item) {
      if (item.discount) {
        if (item.discountPercent) {
          return item.discountValue + '%'
        } else {
          return $filter('currency')(item.discountValue, '€')
        }
      } else {

      }
    }

    // Get available options for the retailer to update this particular transaction's status
    $scope.checkForAvailableStatus = function (status) {
      if (status === 'pending_payment' || status === 'refund' || status === 'canceled' || status === 'partial_refund') {
        $scope.availableOptionsForUpdateStatus = []
      }

      if (status === 'new') {
        if($scope.transaction.type === 'product-reserve'){
          $scope.availableOptionsForUpdateStatus =[
            {
              value: 'new',
              text: $language.translate('NEW'),
              disabled: true
            },
            {
              value: 'processing',
              text: $language.translate('PROCESSING')
            },
            {
              value: 'completing',
              text: $language.translate('COMPLETED')
            },
            {
              value: 'cancelled',
              text: $language.translate('CANCELLED')
            }
          ]
        } else {
          $scope.availableOptionsForUpdateStatus =[
            {
              value: 'new',
              text: $language.translate('NEW'),
              disabled: true
            },
            {
              value: 'processing',
              text: $language.translate('PROCESSING')
            },
            {
              value: 'completing',
              text: $language.translate('COMPLETED')
            },
            {
              value: 'refunding',
              text: $language.translate('REFUND')
            }
          ]
        }
      }

      if(status === 'processing'){
        $scope.availableOptionsForUpdateStatus = [
          {
            value: 'new',
            text: $language.translate('NEW')
          },
          {
            value: 'processing',
            text: $language.translate('PROCESSING'),
            disabled: true
          },
          {
            value: 'completing',
            text: $language.translate('COMPLETED')
          },
          {
            value: 'cancelled',
            text: $language.translate('CANCELLED')
          }
        ]

        if($scope.transaction.type === 'product-reserve'){
          $scope.availableOptionsForUpdateStatus.push({
            value: 'cancelled',
            text: $language.translate('CANCELLED')
          });
        } else {
          $scope.availableOptionsForUpdateStatus.push({
            value: 'refunding',
            text: $language.translate('REFUND')
          });
        }
      }
      if (status === 'completed') {
        $scope.transaction.status = 'completing'
        if($scope.transaction.type === 'product-reserve'){
          $scope.availableOptionsForUpdateStatus = [
            {
              value: 'new',
              text: $language.translate('NEW')
            },
            {
              value: 'processing',
              text: $language.translate('PROCESSING')
            },
            {
              value: 'completing',
              text: $language.translate('COMPLETED'),
              disabled: true
            },
            {
              value: 'cancelled',
              text: $language.translate('CANCELLED')
            }
          ]
        } else {
          $scope.availableOptionsForUpdateStatus = [
            {
              value: 'new',
              text: $language.translate('NEW')
            },
            {
              value: 'completing',
              text: $language.translate('COMPLETED'),
              disabled: true
            },
            {
              value: 'refunding',
              text: $language.translate('REFUND')
            }
          ]
        }
      }
    }

    // check for validitity to update status
    $scope.checkForValidityToUpdateStatus = function (status) {
      if ($scope.notValidStatusForUpdate.includes(status) === false) {
        $scope.isValidForUpdateStaus = true
        $scope.checkForAvailableStatus(status)
      }
    }

    // Update status of transaction
    $scope.updateTransactionStatus = function (status) {
      $scope.initial = false
      if(status && $scope.transaction.type === 'product-reserve'){
        var updateStatus = status === 'completing' ? 'completed' : (status === 'cancelled' ? 'canceled' : status);
        $scope.updateReservationStatus(updateStatus);
      }else {
        if (status === 'new') {
          // We directly need to call api for update status
          $api.put('webshop-transaction/status', {
            transactionId: $stateParams.transactionId,
            shopId: $rootScope.currentShop._id,
            status: status
          })
            .then(function (response) {
              $scope.checkOrderStatusIndication(status)
              $scope.checkForAvailableStatus(status)
            })
            .catch(function (error) {
              $scope.errorMessage = error.data.message
            })
        } else if (status === 'refunding') {
          return $scope.submitForRefund()
        } else if (status === 'processing') {
          $scope.transaction.status = 'processing'
          $scope.transaction.customer = $scope.transaction.customer._id
          $transaction.updateTransactionStatus($scope.transaction._id, 'processing', {
            shopId: $rootScope.currentShop._id,
            user: ($rootScope.currentEmployee ? $rootScope.currentEmployee : null)
          })
            .then( function () {
              $scope.getTransactionDetails()
            })
            .catch( function(reason){
              toastr.error(reason)
              console.error(reason)
            })
        }
      }
    }

    // Refund amount
    $scope.submitForRefund = function () {
      return $q(function (resolve, reject) {
        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/webshop-refund-modal.html',
          controller: 'webshopRefundModalController',
          size: 'xlg',
          resolve: {
            transactionDetails: function () {
              return $scope.transaction
            }
          }
        })
        modalInstance.result
          .then(function (response) {
            $scope.getTransactionDetails()
          })
          .catch(function (reason) {
            $scope.getTransactionDetails()
          })
      })
    }

    // Count total vat/BTW
    $scope.countTotalVAT = function () {
      // Shipping costs and coupon discounts are not saved on every transactionDetail but they have influence on the VAT which is calculated per detail
      var shippingCostPerItem
      var couponDiscountPerItem
      if($scope.transaction.shippingCosts){
        shippingCostPerItem = $scope.transaction.shippingCosts / $scope.transaction.details.length
      }else{
        shippingCostPerItem = 0
      }
      if($scope.transaction.usedCouponAmount){
        couponDiscountPerItem = ($scope.transaction.usedCouponAmount / $scope.transaction.details.length) * -1
      }else{
        couponDiscountPerItem = 0
      }
      
      for (var i = 0; i < $scope.transaction.details.length; i++) {
        if($scope.transaction.details[i].type !== 'giftWrap' && $scope.transaction.details[i].type !== 'engraving'){
          var total = $scope.transaction.details[i].total + shippingCostPerItem + couponDiscountPerItem
          var priceWithVat = total / (1 + ($scope.transaction.details[i].priceVat / 100))
          $scope.totalVAT += (total - priceWithVat)
          // $scope.totalVAT += ((total * $scope.transaction.details[i].priceVat) / 100)
          $scope.totalAmountforCurrentShop += $scope.transaction.details[i].total
          $scope.totalOfProductsWithShipping += $scope.transaction.details[i].total + shippingCostPerItem
          if($scope.transaction.details[i].extraService && $scope.transaction.details[i].extraService.length > 0){
            for (var s = 0; s < $scope.transaction.details[i].extraService.length; s++) {
              $scope.totalAmountforCurrentShop += $scope.transaction.details[i].extraService[s].price
              $scope.totalOfProductsWithShipping += $scope.transaction.details[i].extraService[s].price
            }
          }
        }
      }
    }

    $scope.getPageDefinition = function() {
      $printer.getPageDefinition('WEBSHOP')
        .then(function(doc) {
          $scope.transactionDoc = doc
          if($scope.jspm) {
            $scope.printEnabled = $scope.transactionDoc.printerName && $scope.transactionDoc.printerName !== ''
          } else {
            $scope.printEnabled = $scope.transactionDoc.printNode && $scope.transactionDoc.printNode.printerId
          }

        })
        .catch(function(reason){
          $log.error('Error while fetching page definition', reason)
        })
    }

    $scope.printReceiptDocument = function (skipPrint) {
      $api.get('shops/' + $rootScope.currentShop.nameSlug)
        .then(function (response) {
          $scope.shopAdd = response.data.shop

          $pdf.downloadPdf('generate-webshop-receipt', {
            transactionId: $scope.transaction._id,
            shopId: $rootScope.currentShop._id,
            payMethods: [],
            typeDocument: 'document',
            webshopTransaction: true,
            documentName: 'WEBSHOP',
            parentTransactionId: $scope.transaction.parentTransactionId,
            deviceId: $rootScope.currentDevice._id
          },
            $language.translate('WEBORDER') + '_' + $scope.transaction.number + '.pdf',
            'arraybuffer',
            skipPrint
          )
            .then(function (data) {

            })
            .catch(function (reason) {

            })
        }).catch(function (reason) {
          $log.error('Error while getting shop', reason)
        })
    }

    $scope.submitForComplete = function (shippingTrackURL, method) {
      $scope.errorMessage = ''
      $scope.transaction.shippingTrackURL = shippingTrackURL

      // We directly need to call api for update status
      $api.put('webshop-transaction/status', {
        transactionId: $stateParams.transactionId,
        shopId: $rootScope.currentShop._id,
        status: 'completed',
        shippingTrackURL: shippingTrackURL,
        method: method
      })
        .then(function (response) {
          $scope.transaction.status = 'completed'
          $scope.checkOrderStatusIndication('completed')
          $scope.checkForAvailableStatus('completed')
        })
        .catch(function (error) {
          $scope.errorMessage = error.data.message
        })
    }

    $scope.openCustomer = function () {
      $state.go('retailer.customer', {
        id: $scope.transaction.customer._id
      })
    }

    $scope.AddComment = function (comment) {
      if (comment) {
        var body = {
          transactionId: $scope.transaction._id,
          // shopSlug: $scope.transaction.shopId.nameSlug,
          // companySlug: $scope.transaction.supplier.nameSlug,
          remarks: {
            eType: 'retailer',
            remark: comment,
            companyId: $scope.transaction.supplier,
            shopId: $rootScope.currentShop._id
          },
          shopId: $rootScope.currentShop._id
        }

        $api.post('add-comment-service-retailer-and-company', body)
          .then(function (response) {
            $scope.currentremark = ''
            $scope.getTransactionDetails()
          })
          .catch(function (err) {
            $scope.currentremark = ''
          })
      }
    }

    $scope.EditCommentMode = function (remark, index) {
      for (var i = 0; i < $scope.transaction.remarks.remarks.length; i++) {
        $scope.transaction.remarks.remarks[i].isEditMode = false
      }
      $scope.transaction.remarks.remarks[index].isEditMode = true
      $scope.editRemarkDetail = remark
    }

    $scope.UpdateComment = function (comment, index) {
      if (comment) {
        $scope.editRemarkDetail.remark = comment
        $scope.transaction.remarks.remarks[index].isEditMode = false
        var body = {
          transactionId: $scope.transaction._id,
          commentId: $scope.editRemarkDetail._id,
          remark: comment,
          shopId: $rootScope.currentShop._id
        }

        $api.post('edit-comment-service-retailer-and-company', body)
          .then(function (response) {
            $scope.editRemarkDetail = ''
            $scope.getTransactionDetails()
          })
          .catch(function (err) {
            $scope.editRemarkDetail = ''
          })
      } else {
        $scope.editRemarkDetail = ''
        $scope.transaction.remarks.remarks[index].isEditMode = false
      }
    }

    // Whenever adding comment to the then we have add new line when pressed (SHIFT + ENTER) and if only enter then add Comment
    $scope.addCommentKeyUp = function (event, currentremark) {
      if (event.keyCode === 13) {
        if (event.shiftKey) return false
        else $scope.AddComment(currentremark)
      }
    }

    $scope.autoScrollcomment = function () {
      setTimeout(function () {
        $('#messagewindow').animate({ scrollTop: $('#messagewindow')[0].scrollHeight }, 0)
      }, 200)
    }

    $scope.deleteTransaction = function() {
      var dlg = dialogs.confirm($language.translate('DELETE'), $language.translate('ARE_YOU_SURE_TO_DELETE_THIS_ORDER'), { size: 'sm' })
      dlg.result
        .then(function (btn) {
          if (btn === 'yes') {
            $transaction.deleteTransaction($scope.transaction._id, false, false)
              .then(function () {
                $state.go('retailer.orders')
              })
              .catch(function(reason) {
                $log.error('Error while deleting weborder', reason)
              })
          }
        })
        .catch(function (reason) {
          $log.error(reason)
        })
    }

    $scope.cancelTransaction = function () {
      var dlg = dialogs.confirm($language.translate('CANCEL'), $language.translate('ARE_YOU_SURE_TO_CANCEL_THIS_ORDER'), { size: 'sm' })
      dlg.result
        .then(function (btn) {
          if (btn === 'yes') {
            $scope.transaction.status = 'cancelled'
            $transaction.cancelTransaction($scope.transaction._id)
              .then(function () {
                $scope.getTransactionDetails()
              })
              .catch(function(reason) {
                $log.error('Error while deleting weborder', reason)
              })
          }
        })
        .catch(function (reason) {
          $log.error(reason)
        })
    }

    $scope.makeTrackTraceUrl = function (code, carrier) {
      if(!code || !carrier) {
        $scope.sending.shippingTrackURL = ''
        return
      }

      if (carrier === 'POSTNL') {
        $scope.sending.shippingTrackURL = 'https://www.internationalparceltracking.com/Main.aspx#/track/' + code + '/' + $scope.transaction.shippingAddress.country + '/' + $scope.transaction.shippingAddress.postalCode
      }

      if (carrier === 'DHL') {
        $scope.sending.shippingTrackURL = 'https://dhlparcel.nl/nl/consument/volg-je-pakket?tc='+ code +'&pc='+ $scope.transaction.shippingAddress.postalCode +'&lc=nl-NL'
      }
      if(carrier === 'MIKROPAKKET') {
        $scope.sending.shippingTrackURL = 'https://www.mikropakket.nl/pakket-status?switch-radio=on&Pakketnr='+ code +'&Referentie=&Postcode=' + $scope.transaction.shippingAddress.postalCode
      }

      if(carrier === 'BPOST') {
        $scope.sending.shippingTrackURL = 'https://track.bpost.cloud/btr/web/#/search?itemCode='+ code +'&lang=nl&postalCode=' + $scope.transaction.shippingAddress.postalCode
      }

      if (carrier === 'DPD') {
        var url = 'https://tracking.dpd.de/status/'
        var country = $scope.transaction.shippingAddress.country
        if (country !== 'NL') {
          country = 'en_' + country
        } else {
          country = 'nl_' + country
        }
        url += country + '/parcel/' + code
        $scope.sending.shippingTrackURL = url
      }
    }

    $scope.openTrackTrace = function (url) {
      window.open(url, '_blank')
    }

    $scope.printThermal = function () {
      $thermalPrint.printThermal('webshop',1, $scope.transaction, true, true)
        .then(function () {
          console.info('Shop receipt printed')
        })
        .catch(function (reason) {
          console.error('Error while printing shop receipt', reason)
        })
    }

    $scope.changeCustomer = function () {
      if($scope.transaction.customer && $scope.transaction.customer.counter === false && $rootScope.currentShop.points && $rootScope.currentShop.points.enabled) {
        //Show confirm dialog to notice user about manually correcting points
        var dlg = dialogs.confirm(
          $language.translate('CONFIRM'),
          $language.translate('CHANGE_CUSTOMER_MANUALLY_CORRECT_POINTS'),
          {
            size: 'sm',
            btnMessages: {
              'DIALOGS_YES': $language.translate('OK'),
              'DIALOGS_NO': $language.translate('CANCEL')
            }
          })
        dlg.result.then(function (btn) {
          if (btn === 'yes') {
            doChange()
          }
        })
        .catch(function (reason) {
        })
      } else {
        doChange()
      }

      function doChange() {
        $customer.openSearchCustomerModal(false, false)
          .then(function (result) {
            var user = $rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id
            $scope.transaction.customer = result
            if(result.paymentTermDays) {
              $scope.transaction.paymentTermDays = result.paymentTermDays
            } else {
              $scope.transaction.paymentTermDays = 14
            }
            $transaction.updateTransactionCustomer($scope.transaction._id, result._id, user, $scope.transaction.paymentTermDays)
              .then(function () {
                $scope.getTransactionDetails()
              })
              .catch(function (reason){
                $log.error('Error while updating customer on transaction', reason)
              })
          })
          .catch(function (reason) {
            $log.error('Error while changing customer on transaction', reason)
          })
      }

    }

    $scope.mailToCustomer = function() {
      $scope.transaction.customer.firstName = $scope.transaction.firstName;
      $scope.transaction.customer.lastName = $scope.transaction.lastName;
      return $q(function (resolve, reject) {
        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/customer-send-email-modal.html',
          controller: 'customerSendEmailModalController',
          keyboard: false,
          backdrop: 'static',
          size: 'lg',
          resolve: {
            customer: function () {
              return $scope.transaction.customer
            },
            email: function(){
              return {
                isFrom: 'reservation',
                productName: $scope.transaction.details[0].name
              }
            }
          }
        })
        modalInstance.result.then(function (res) {
          var status = res ? 'completed' : 'canceled';
          $scope.updateReservationStatus(status);
          toastr.success($language.translate('MAIL_SEND'))
          return resolve()
        }, function () {
          return reject()
        })
      })
      // $customer.openCustomerEmailModal($scope.transaction.customer)
      //   .then(function() {
      //     toastr.success($language.translate('MAIL_SEND'))
      //   })
    }

    $scope.returnWeborder = function () {
      $state.go('retailer.till', {
        customer: $scope.transaction.customer,
        items: $scope.transaction.items,
        transactionId: $scope.transaction._id,
        transactionNumber: $scope.transaction.number,
        refund: true
      })
    }

    $scope.updateReservationStatus = function(status){
      $scope.errorMessage = ''
      $scope.transaction.shippingTrackURL = ''

      // We directly need to call api for update status
      $api.put('webshop-transaction/status', {
        transactionId: $stateParams.transactionId,
        shopId: $rootScope.currentShop._id,
        status: status,
        shippingTrackURL: '',
        method: ''
      })
        .then(function (response) {
          $scope.transaction.status = status == 'completed' ? 'completing' : (status == 'canceled' ? 'cancelled' : status)
          $scope.checkOrderStatusIndication(status)
          // $scope.checkForAvailableStatus($scope.transaction.status)
        })
        .catch(function (error) {
          $scope.errorMessage = error.data.message
        })
    }

  }])
