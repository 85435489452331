prismanoteApp.controller('retailerChangeQuickbuttonOrderModalController', ['$scope', 'quickButtons', '$uibModalInstance', '$language',
  function ($scope, quickButtons, $uibModalInstance, $language) {
    $scope.quickButtons = quickButtons
    $scope.empty = $language.translate('EMPTY_BUTTON')

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    $scope.confirm = function () {
      $uibModalInstance.close({ buttons: $scope.quickButtons })
    }

    $scope.getButtonName = function(button) {
      if(!button.name) {
        return $scope.empty
      } else {
        return button.name
      }
    }

    $scope.remove = function () {
      $uibModalInstance.close({ status: 'remove', stores: [] })
    }

    function changeButtonIndex (currentIndex, newIndex) {
      var currentIdx = $scope.quickButtons[currentIndex]
      var newIdx = $scope.quickButtons[newIndex]
      $scope.quickButtons[currentIndex] = newIdx
      $scope.quickButtons[newIndex] = currentIdx
    }

    $scope.previousButton = function (currentIndex) {
      changeButtonIndex(currentIndex, (currentIndex - 1))
    }

    $scope.nextButton = function (currentIndex) {
      changeButtonIndex(currentIndex, (currentIndex + 1))
    }
  }])
