prismanoteApp.controller('printAllLabelBulkChangeModalController', ['$scope', '$uibModalInstance', '$rootScope', 'data', '$api', 'FileSaver', '$http', '$shop', '$purchaseOrder', '$label', '$q', 'products', '$crypto',
  function ($scope, $uibModalInstance, $rootScope, data, $api, FileSaver, $http, $shop, $purchaseOrder, $label, $q, products, $crypto) {
    $scope.localdata = {
      isValidData: false,
      isError: false,
      isAnyQuantityExist: false
    }

    $scope.getLabels = function(){
      if (!$rootScope.currentShop || !$rootScope.currentShop.newLabel || $rootScope.currentShop.newLabel === false) {
        return
      }

      $label.getLabels($rootScope.currentShop._id, null, false)
        .then(function (templates) {
          $scope.labels = templates
          if($scope.labels && $scope.labels.length > 0) {
            $scope.selectedLabel = $scope.labels[0]
          }
          for (var i = 0; i < $scope.products.length; i++) {
            $scope.products[i].quantity = $scope.products[i].purchaseOrderItem.receivedQuantity
          }
        })
        .catch(function (reason){
          console.error('error when fetching labels', reason)
        })
    }

    $scope.getThePrintLabelDataOfPurchaseOrder = function () {
      var body = {
        // purchaseOrderId: data.purchaseOrderId,
        products: data.products,
        shopId: data.shop._id,
        shopSlug: data.shop.nameSlug
      }
      $api.post('v2/retailer/printlabel-data-for-bulk-change', body)
      .then(function (response) {
        if (response && response.data && response.data.data) {
          console.log('response.data.data ', response.data.data);
          $scope.products = response.data.data.products || []
          if (!$rootScope.currentShop || !$rootScope.currentShop.newLabel || $rootScope.currentShop.newLabel === false) {
            $scope.availableLabels = response.data.data.shopLabels
          } else {
            $scope.getLabels()
          }

          if ($scope.products && $scope.products.length && $scope.availableLabels && $scope.availableLabels.length) {
            $scope.localdata.isValidData = true
            if (!$rootScope.currentShop || !$rootScope.currentShop.newLabel || $rootScope.currentShop.newLabel === false) {
              $scope.getProductWiseLabel($scope.availableLabels[0])
            }

            $scope.checkAllQuantityAvailable()
          }

          console.log('$scope.products ', $scope.products);
        }
      }).catch(function (error) {
        console.error('error: ', error)
      })
    }

    if(products && products.length > 0) {
      $scope.productIds = products.map(function(item){return item.productId})
      $scope.productQty = products.map(function(item){return item.quantity})
      $scope.products = []
      //get extra product data
      var counter = 0
      for(var i = 0; i < products.length; i++){
        $api.get('shop/own-shop-product-by-nameSlug/' + $scope.productIds[i] + '/' + $rootScope.currentShop.nameSlug)
          .then(function (response) {
            var product = response.data
            product.purchaseOrderItem = {
              product_id: response.data.product._id,
              name: response.data.product[$rootScope.language].name,
              receivedQuantity: $scope.productQty[counter],
              number: response.data.product.variants[0].productNumber
            }
            product.quantity = $scope.productQty[counter]
            counter++
            $scope.products.push(product)
          })
          .catch(function(reason){
            console.error('Error while fetching product details', reason)
          })
      }
      $scope.getLabels()
    } else {
      $scope.getThePrintLabelDataOfPurchaseOrder()
    }

    $scope.gemKinds = [
      { key: 'AM', value: 'amethyst' },
      { key: 'AQ', value: 'aquamarine' },
      { key: 'AV', value: 'aventurine' },
      { key: 'CA', value: 'carnelean' },
      { key: 'CI', value: 'citrine' },
      { key: 'MC', value: 'madeira citrine' },
      { key: 'DI', value: 'diamond' },
      { key: 'EM', value: 'emerald' },
      { key: 'GR', value: 'garnet' },
      { key: 'HE', value: 'hematite' },
      { key: 'JA', value: 'jade' },
      { key: 'LB', value: 'labradorite' },
      { key: 'LA', value: 'lapis' },
      { key: 'LZ', value: 'lazuli' },
      { key: 'MS', value: 'moonstone' },
      { key: 'ON', value: 'onyx' },
      { key: 'OP', value: 'opal' },
      { key: 'PL', value: 'pearl' },
      { key: 'PA', value: 'pearl akoya' },
      { key: 'PT', value: 'pearl tahiti' },
      { key: 'PW', value: 'pearl sweet water' },
      { key: 'PS', value: 'pearl south sea' },
      { key: 'PY', value: 'pearl synthetical' },
      { key: 'PC', value: 'pearl cultive' },
      { key: 'PE', value: 'peridote' },
      { key: 'QU', value: 'quartz' },
      { key: 'RC', value: 'rockcrystal' },
      { key: 'RQ', value: 'rosequartz' },
      { key: 'RH', value: 'ruby' },
      { key: 'SA', value: 'sapphire' },
      { key: 'SQ', value: 'smoky quartz' },
      { key: 'SP', value: 'spinel' },
      { key: 'TP', value: 'topaz' },
      { key: 'TR', value: 'tourmaline' },
      { key: 'TQ', value: 'turquoise' },
      { key: 'ZI', value: 'zirconia' },
      { key: 'CR', value: 'crystal' },
      { key: 'ST', value: 'stratum stone' },
      { key: 'SC', value: 'swarovski crystal' },
      { key: 'SY', value: 'synthetic stone' },
      { key: 'EN', value: 'enamel' },
      { key: 'CM', value: 'cameo' },
      { key: 'BC', value: 'blood coral' },
      { key: 'AG', value: 'agate' },
      { key: 'AZ', value: 'amazone' },
      { key: 'AZN', value: 'amazonite' },
      { key: 'AB', value: 'amber' },
      { key: 'RS', value: 'rhinestone' },
      { key: 'BR', value: 'beryll' },
      { key: 'CC', value: 'calcite' },
      { key: 'CE', value: 'cat eay' },
      { key: 'CH', value: 'chalcedony' },
      { key: 'LQ', value: 'lemon quartz' },
      { key: 'EP', value: 'epidote' },
      { key: 'FL', value: 'fluorite' },
      { key: 'GL', value: 'glass' },
      { key: 'HT', value: 'heliotrope' },
      { key: 'IQ', value: 'iris quartz' },
      { key: 'JP', value: 'jasper' },
      { key: 'CS', value: 'ceramics' },
      { key: 'CD', value: 'corundum' },
      { key: 'LT', value: 'lazurite' },
      { key: 'ML', value: 'malachite' },
      { key: 'MR', value: 'murano glass' },
      { key: 'NS', value: 'natural stone' },
      { key: 'NP', value: 'nephrite' },
      { key: 'SD', value: 'sodalite' },
      { key: 'TE', value: 'tiger eye' },
      { key: 'UV', value: 'uvarovite' },
      { key: 'WV', value: 'wildvinite' },
      { key: 'XS', value: 'xylopile' },
      { key: 'JG', value: 'jargon' },
      { key: 'FP', value: 'fire post' },
      { key: 'SL', value: 'slate' },
      { key: 'PH', value: 'phosphorite' }
    ]

    function getShortNameFromName (name) {
      var result = ''
      for (var i = 0; i < $scope.gemKinds.length; i++) {
        if ($scope.gemKinds[i].value == name) {
          result = $scope.gemKinds[i].key
          break
        } else {
          continue
        }
      }
      return result
    }

    function getDiamondInfo () {
      if ($scope.product && $scope.product.diamonds && $scope.product.diamonds.length > 0 && $scope.product.diamonds[0]) {
        var diamond = $scope.product.diamonds[0]
        var gemKindSN = getShortNameFromName(diamond.gemKind)
        var diamondInfo = ''
        if (gemKindSN) diamondInfo += gemKindSN + ','
        if (diamond.gemPurity && diamond.gemPurity.code) diamondInfo += diamond.gemPurity.code + ','
        
        if (diamond.gemColor && diamond.gemColor.code) {
          if(String(diamond.gemColor.code).length === 1) {
            diamondInfo += diamond.gemColor.code + ','
          }
        }

        if (diamond.caratWeight) diamondInfo += diamond.caratWeight
        return diamondInfo
      } else {
        return null
      }
    }

    function getCollectionInfo (details) {
      if (!details || !$scope.product || !$scope.product.collections || $scope.product.collections.length == 0) {
        return []
      }
      var result = []; var counter = 0
      for (var i = 0; i < $scope.product.collections.length; i++) {
        var collection = $scope.product.collections[i]

        if (collection[details]) {
          result.push(collection)
        }

        counter++
        if (counter == $scope.product.collections.length) {
          return result
        }
      }
    }

    function getProductValue (value) {
      var name = false
      if (value == 'name') {
        name = true
        value = $rootScope.language + '.' + value
      } else if (value == 'brand.name') {
        // We need to check if their is an alias
        if (!$scope.product.brand) {
          return
        }
        var brand = _.get($scope.product, 'brand')
        if (brand.alias) {
          return brand.alias
        }
        if (brand.name) {
          return brand.name
        }
        return ''
      }
      var productValue = _.get($scope.product, value)
      if (value === 'lastPurchaseOrderDeliveryDate' && productValue !== null && typeof productValue !== 'undefined') {
        return new Date(productValue).toISOString()
      }
      if (productValue) {
        return productValue
      } else {
        if (name) {
          return _.get($scope.product, 'name')
        }
        return ''
      }
    }

    function getReplaceValue (name) {
      var placeholder = _.find($rootScope.labelPlaceholders, { name: name })

      if (placeholder) {
        if (placeholder.value == 'diamondinfo') {
          var value = getDiamondInfo()
        } else if (placeholder.value == 'ownCollection') {
          var collection = getCollectionInfo('privateLabel')

          if (collection && collection[0]) {
            var value = collection[0][$rootScope.language].name
          }
        } else if (placeholder.value == 'variantsCollection') {
          var collection = getCollectionInfo('variantsCollection')

          if (collection && collection[0]) {
            var value = collection[0][$rootScope.language].name
          }
        } else if (placeholder.value == 'brandCollection1') {
          var collection = getCollectionInfo('usedByBrand')

          if (collection && collection[0]) {
            var value = collection[0][$rootScope.language].name
          }
        } else if (placeholder.value == 'brandCollection2') {
          var collection = getCollectionInfo('usedByBrand')

          if (collection && collection[1]) {
            var value = collection[1][$rootScope.language].name
          }
        } else if (placeholder.value == 'weight') {
          var value = getProductValue(placeholder.value)
          if(value) {
            value += 'g'
          }
        } else {
          var value = getProductValue(placeholder.value)
        }

        if (!value) {
          return ''
        }

        return value
      } else {
        return ''
      }
    }

    function replaceValuesOnArray (array) {
      var counter = 0
      for (var i = 0; i < array.length; i++) {
        if (!array[i] || array[i].length == 0 || array[i] == '') {
          array[i] = ' '
          counter++
        } else {
          var results = array[i].match(/%%\w*%%/ig)

          if (results && results.length > 0) {
            for (var r = 0; r < results.length; r++) {
              var value = getReplaceValue(results[r])
              if (value != '') {
                if (results[r] == '%%SELLING_PRICE%%') {
                  var price = parseFloat(value.toString().replace(',', '.'))
                  priceValue = '\u20AC ' + price.toFixed(2).replace('.', ',')
                  var newValue = array[i].replace(new RegExp(results[r], 'g'), priceValue)
                } else {
                  var newValue = array[i].replace(new RegExp(results[r], 'g'), value)
                }
                if (results[r] != '%%EAN%%') {
                  newValue = $scope.calculateLabelLength(newValue)
                }
                array[i] = newValue
              } else {
                array[i] = ''
              }
            }
          }
          counter++
        }
        if (counter == array.length) {
          return array
        }
      }
    }

    $scope.changeLabelType = function (label) {
      $scope.label = angular.copy(label)
      $scope.label.quantity = 1
      // return;
      $scope.label.fields.left = replaceValuesOnArray($scope.label.fields.left)
      $scope.label.fields.right = replaceValuesOnArray($scope.label.fields.right)
      if ($scope.label.dataField) {
        $scope.label.fields.data = getReplaceValue($scope.label.fields.data)
      }
    }

    $scope.getProductWiseLabel = function (label) {
      $scope.selectedLabel = label
      $scope.label = angular.copy(label)
      for (var i = 0; i < $scope.products.length; i++) {
        if ($scope.products[i] && $scope.products[i].product) {
          $scope.products[i].label = angular.copy(label)

          // MAKING PRODUCT START
          var productDetail = $scope.products[i]
          $scope.product = Object.assign({}, productDetail.product, productDetail.shopProduct, productDetail.collections)
          if (productDetail && productDetail.shopProduct) {
            $scope.product.sellingPrice = productDetail.shopProduct.price * ((productDetail.shopProduct.priceVat / 100) + 1)
          }
          $scope.product.brand = productDetail.brand
          $scope.product.supplier = productDetail.supplier
          // MAKING PRODUCT END

          $scope.products[i].label.fields.left = replaceValuesOnArray($scope.products[i].label.fields.left)
          $scope.products[i].label.fields.right = replaceValuesOnArray($scope.products[i].label.fields.right)
          if ($scope.products[i].label.dataField) {
            $scope.products[i].label.fields.data = getReplaceValue($scope.products[i].label.fields.data)
          }
          $scope.products[i].label.quantity = $scope.products[i].purchaseOrderItem.receivedQuantity
        } else {
          $scope.products[i].isDisabled = true
          $scope.products[i].label = {}
          $scope.products[i].label.quantity = $scope.products[i].purchaseOrderItem.receivedQuantity
        }
      }
    }

    $scope.print = function (index) {
      var ecrypted = $crypto.encrypt({label: $scope.products[index].label})

      $http({
        method: 'POST',
        url: '/api/download-label',
        data: {
          encrypted: encrypted
        },
        responseType: 'arraybuffer'
      }).then(function (response) {
        var file = new Blob([response.data], { type: 'text/plain' })
        var filename = new Date().getTime()
        FileSaver.saveAs(file, filename + '.pne')
        $scope.products[index].label.quantity = 0
        // $scope.close(true);
      }, function (response) {
        console.error('Error while generating label', response)
      })
    }

    $scope.printAll = function () {
      $scope.localdata.isError = false
      var allLabels = []
      for (var i = 0; i < $scope.products.length; i++) {
        if ($scope.products[i] && $scope.products[i].product && $scope.products[i].label.quantity) {
          allLabels.push($scope.products[i].label)
        }
      }

      var encrypted = $crypto.encrypt({labels: allLabels})

      $http({
        method: 'POST',
        url: '/api/download-multiple-label',
        data: {
          encrypted: encrypted
        },
        responseType: 'arraybuffer'
      }).then(function (response) {
        var file = new Blob([response.data], { type: 'text/plain' })
        var filename = new Date().getTime()
        FileSaver.saveAs(file, filename + '.pne')
        $scope.close(true)
      }, function (response) {
        $scope.localdata.isError = true
        console.error('Error while generating label', response)
      })
    }

    if ($scope.product && $scope.product.stock > 0) {
      $scope.inStock = true
    } else {
      $scope.inStock = false
    }

    $scope.close = function (data) {
      if (data == true) {
        $uibModalInstance.close(true)
      } else {
        $uibModalInstance.close(false)
      }
    }

    $scope.getNumber = function (number) {
      return new Array(number)
    }

    $scope.calculateLabelLength = function (text, points) {
      var capitalPoint = 138.4615
      var smallPoint = 100
      var capitalSpecial = ['@', '#', '$', '^', '€', '&', '%', '<', '>', '?']
      var smallSpecial = ['!', '*', '(', ')', '|', '\\', '/', '{', '}', '-', '+', '_', '.', ',', ':', ';', '[', ']']

      if (!points) {
        points = 1715
      }
      var totalPoints = 0
      var resultString = ''

      for (var i = 0; i < text.length; i++) {
        var letter = text.charAt(i)

        // Test if we still can add some chars
        if ((totalPoints + capitalPoint > points) && (totalPoints + smallPoint > points)) {
          return resultString
        }

        if (capitalSpecial.indexOf(letter) >= 0 && letter != ' ') {
          totalPoints += capitalPoint
          resultString += letter
        } else if (smallSpecial.indexOf(letter) >= 0 && letter != ' ') {
          totalPoints += smallPoint
          resultString += letter
        } else if (letter == letter.toUpperCase() && letter != ' ') {
          // Capital letters
          totalPoints += capitalPoint
          resultString += letter
        } else if (letter == letter.toLowerCase() || letter == ' ') {
          // Small letters and spaces
          totalPoints += smallPoint
          resultString += letter
        } else if (!isNaN(letter) && letter != ' ') {
          // Numbers
          totalPoints += smallPoint
          resultString += letter
        }

        if (i + 1 == text.length) {
          // Text doesn't need to be shorten
          return resultString
        }
      }
    }

    $scope.productPrintUIB = function (productQnt) {
      if (productQnt > 0) return ''
      else return 'Please increase the quantity first then and only you can print the label'
      // else return "<html> <body> <p> <b> No history found </b> </p> </body> </html>";
    }

    $scope.printAllUIB = function () {
      if ($scope.localdata.isAnyQuantityExist) return ''
      else return 'Please increase the any one quantity of the product then and only you can print the label'
    }

    $scope.checkAllQuantityAvailable = function () {
      $scope.localdata.isAnyQuantityExist = false
      for (var i = 0; i < $scope.products.length; i++) {
        if ($scope.products[i].label && $scope.products[i].label.quantity > 0) {
          $scope.localdata.isAnyQuantityExist = true
          break
        }
      }
    }

    $scope.getProductData = function(product) {
      var productData = Object.assign({}, product.product, product.shopProduct, product.collections)
      if(productData && product.shopProduct) {
        productData.sellingPrice = product.shopProduct.price * ((product.shopProduct.priceVat / 100) + 1)
      }
      productData.brand = product.brand
      productData.supplier = product.supplier
      productData.lastPurchaseOrderDeliveryDate = product.lastPurchaseOrderDeliveryDate
      productData.collections = product.collections
      // if(product.quantity) {
      //   productData.quantity = product.quantity
      // }
      // console.log('product.shopProduct.stock ', product.shopProduct.stock)
      if(product.shopProduct && product.shopProduct.stock > -1) {
        // console.log('--- if!');
        productData.quantity = product.shopProduct.stock;
      }
      // console.log('productData ', productData);
      return $label.makeDataObjectForProduct(productData)
    }

    $scope.newLabelChanged = function (label) {
      $scope.selectedLabel = label
    }

    $scope.bulkPrint = function () {
      var commands = []
      var counter = 0;
      angular.copy($scope.products).forEach(function(product) {
        var data = $scope.getProductData(angular.copy(product))
        var template = angular.copy(angular.copy($scope.selectedLabel))
        var label = new ZebraLabel(template)

        var layout = template.layout_name && (template.layout_name.length > 1 && typeof template.layout_name === 'string')
        var printCommand = label.generateCommand(template, data , $label.checkTemplateDate(template.dateCreated), layout)

        var extraLabel = 0;
        if (product && product.purchaseOrderItem && product.purchaseOrderItem.extraLabel) {
          extraLabel = Number(product.purchaseOrderItem.extraLabel)
        }

        //only print the label when there's a quantity specified
        // console.log('data ', data);
        // console.log('data.quantity ', data.quantity);
        if (data.quantity && data.quantity != 0) {
          commands.push({
            command: printCommand,
            quantity: ((data.quantity ? data.quantity : 1) + extraLabel),
          })
        }
        
        counter++
        if(counter === $scope.products.length) {
          $label.bulkPrintLabels(commands).then(function () {
          }).catch(function (reason) {
            console.error('Error while bulkprinting', reason)
          })
        }
      })

    }

  }])
