prismanoteApp.controller('confirmOrderIntoCompletedAndDeductStockModalController', ['$scope', '$uibModalInstance', '$rootScope', '$state', '$purchaseOrder', 'convertedTransactionsRec', 'transactionDetailRec', '$customerInform', 'purchaseOrderDetail',
  function ($scope, $uibModalInstance, $rootScope, $state, $purchaseOrder, convertedTransactionsRec, transactionDetailRec, $customerInform, purchaseOrderDetail) {
    console.log('convertedTransactionsRec in MODAL: ', convertedTransactionsRec)
    $scope.convertedTransactions = []
    $scope.transactionDetail = []
    if (convertedTransactionsRec && convertedTransactionsRec.length) $scope.convertedTransactions = convertedTransactionsRec
    if (transactionDetailRec && transactionDetailRec.length) $scope.transactionDetail = transactionDetailRec

    $scope.changeQuantity = function (convertedTransactionIndex, convertedTransactionDetailIndex) {
      console.log('change send quantity: ', convertedTransactionIndex, convertedTransactionDetailIndex)
      var currentTransaction = $scope.convertedTransactions[convertedTransactionIndex]
      var currentTransactionDetail = currentTransaction.details[convertedTransactionDetailIndex]
      console.log('details: ', currentTransaction, currentTransactionDetail)
      console.log(currentTransactionDetail.totalAppliedStock <= currentTransactionDetail.canBeDeliver, currentTransactionDetail.totalAppliedStock, currentTransactionDetail.canBeDeliver)
      if (currentTransactionDetail.totalAppliedStock < currentTransactionDetail.canBeDeliver || currentTransactionDetail.canBeDeliver < 0 ||
                currentTransactionDetail.quantity < currentTransactionDetail.canBeDeliver) {
        $scope.convertedTransactions[convertedTransactionIndex].details[convertedTransactionDetailIndex].canBeDeliver = currentTransactionDetail.lastcanBeDeliver
      } else {
        // recalculateAvailableReceivedQuantity();
        checkingEnableDisableFlagBasedOnReceivedQuantityOfTransactions()
        function recalculateAvailableReceivedQuantity () {
          var transactionDetailLength = $scope.transactionDetail.length
          var newAvailableQuantity = currentTransactionDetail.totalAppliedStock - currentTransactionDetail.canBeDeliver
          console.log('else:', currentTransactionDetail.totalAppliedStock, currentTransactionDetail.canBeDeliver, newAvailableQuantity)
          for (var k = 0; k < transactionDetailLength; k++) {
            // console.log(k, $scope.transactionDetail[k].product_id, currentTransactionDetail.productId);
            if ($scope.transactionDetail[k].product_id && ($scope.transactionDetail[k].product_id).toString() === (currentTransactionDetail.productId).toString()) {
              $scope.transactionDetail[k].availablerReceivedQuantity = newAvailableQuantity
              console.log('makedefault: ', k, $scope.transactionDetail[k].availablerReceivedQuantity)
            }
          }
        }

        function checkingEnableDisableFlagBasedOnReceivedQuantityOfTransactions () {
          var convertedTransactionsLength = $scope.convertedTransactions.length
          var transactionDetail = $scope.transactionDetail
          var transactionDetailLength = transactionDetail.length
          recalculateAvailableReceivedQuantity()
          for (var i = 0; i < convertedTransactionsLength; i++) {
            var convertedTransactionsDetailsLength = $scope.convertedTransactions[i].details.length
            for (var j = 0; j < convertedTransactionsDetailsLength; j++) {
              if ($scope.convertedTransactions[i].details[j].productId) {
                // console.log('All make default: ', transactionDetail);
                // continue;
                for (var k = 0; k < transactionDetailLength; k++) {
                  if (transactionDetail[k].product_id && (transactionDetail[k].product_id).toString() === ($scope.convertedTransactions[i].details[j].productId).toString() &&
                                        (currentTransactionDetail.productId).toString() === ($scope.convertedTransactions[i].details[j].productId).toString()) {
                    // if ((currentTransactionDetail.productId).toString() === ($scope.convertedTransactions[i].details[j].productId).toString()) {
                    var transactionDetailQuantity = $scope.convertedTransactions[i].details[j].quantity
                    // console.log('trasnactions available rec qnt: ', transactionDetail[k].availablerReceivedQuantity, transactionDetailQuantity);
                    // console.log('MATCHING CONDITION: ', i, convertedTransactionIndex, i === convertedTransactionIndex);
                    if ($scope.convertedTransactions[i].status === 'completed' || $scope.convertedTransactions[i].status === 'delivered') {
                      $scope.convertedTransactions[i].details[j].canBeDeliver = transactionDetailQuantity
                      $scope.convertedTransactions[i].details[j].canNotBeDeliver = 0
                      $scope.convertedTransactions[i].details[j].canFullyDeliver = true
                      $scope.convertedTransactions[i].details[j].canPartiallyDeliver = false
                      $scope.convertedTransactions[i].details[j].canCancel = false
                    } else if ($scope.convertedTransactions[i].status === 'cancelled') {
                      $scope.convertedTransactions[i].details[j].canBeDeliver = 0
                      $scope.convertedTransactions[i].details[j].canNotBeDeliver = transactionDetailQuantity
                      $scope.convertedTransactions[i].details[j].canFullyDeliver = false
                      $scope.convertedTransactions[i].details[j].canPartiallyDeliver = false
                      $scope.convertedTransactions[i].details[j].canCancel = true
                    } else {
                      if (i === convertedTransactionIndex) {
                        console.log('MAIN IF: ', i)
                        var canBeDeliver = $scope.convertedTransactions[convertedTransactionIndex].details[convertedTransactionDetailIndex].canBeDeliver
                        if (canBeDeliver === transactionDetailQuantity) {
                          console.log('IF INSIDE IF')
                          // console.log(k, 'INSIDE IF: ', transactionDetail[k].availablerReceivedQuantity - transactionDetailQuantity);
                          transactionDetail[k].availablerReceivedQuantity = currentTransactionDetail.totalAppliedStock - currentTransactionDetail.canBeDeliver
                          // $scope.convertedTransactions[i].details[j].canBeDeliver = transactionDetailQuantity; // Already update from input tag
                          $scope.convertedTransactions[i].details[j].canNotBeDeliver = 0
                          $scope.convertedTransactions[i].details[j].canFullyDeliver = true
                          $scope.convertedTransactions[i].details[j].canPartiallyDeliver = false
                          $scope.convertedTransactions[i].details[j].canCancel = false
                        } else if (canBeDeliver > 0) {
                          console.log('ELSE INSIDE IF')
                          // console.log(k, transactionDetail[k].availablerReceivedQuantity);
                          // $scope.convertedTransactions[i].details[j].canBeDeliver = transactionDetail[k].availablerReceivedQuantity; // Already update from input tag
                          $scope.convertedTransactions[i].details[j].canNotBeDeliver = transactionDetailQuantity - canBeDeliver
                          transactionDetail[k].availablerReceivedQuantity = currentTransactionDetail.totalAppliedStock - currentTransactionDetail.canBeDeliver
                          // transactionDetail[k].availablerReceivedQuantity = transactionDetail[k].availablerReceivedQuantity - currentTransactionDetail.canBeDeliver;
                          // transactionDetail[k].availablerReceivedQuantity = transactionDetailQuantity - transactionDetail[k].availablerReceivedQuantity;
                          $scope.convertedTransactions[i].details[j].canFullyDeliver = false
                          $scope.convertedTransactions[i].details[j].canPartiallyDeliver = true
                          $scope.convertedTransactions[i].details[j].canCancel = false
                        } else {
                          // transactionDetail[k].availablerReceivedQuantity = 0;
                          $scope.convertedTransactions[i].details[j].canBeDeliver = 0
                          $scope.convertedTransactions[i].details[j].canNotBeDeliver = transactionDetailQuantity
                          $scope.convertedTransactions[i].details[j].canFullyDeliver = false
                          $scope.convertedTransactions[i].details[j].canPartiallyDeliver = false
                          $scope.convertedTransactions[i].details[j].canCancel = true
                        }
                      } else {
                        console.log('MAIN ELSE: ', i)
                        if (transactionDetail[k].availablerReceivedQuantity > 0) {
                          console.log('IF INSIDE ELSE')
                          console.log(k, transactionDetail[k].availablerReceivedQuantity, transactionDetailQuantity)
                          if (transactionDetail[k].availablerReceivedQuantity >= transactionDetailQuantity) {
                            console.log('IF INSIDE IF INSIDE ELSE: ', transactionDetail[k].availablerReceivedQuantity, transactionDetailQuantity)
                            // console.log(k, 'INSIDE IF: ', transactionDetail[k].availablerReceivedQuantity - transactionDetailQuantity);
                            transactionDetail[k].availablerReceivedQuantity = transactionDetail[k].availablerReceivedQuantity - transactionDetailQuantity
                            $scope.convertedTransactions[i].details[j].canBeDeliver = transactionDetailQuantity
                            $scope.convertedTransactions[i].details[j].canNotBeDeliver = 0
                            $scope.convertedTransactions[i].details[j].canFullyDeliver = true
                            $scope.convertedTransactions[i].details[j].canPartiallyDeliver = false
                            $scope.convertedTransactions[i].details[j].canCancel = false
                          } else {
                            console.log('ELSE INSIDE IF INSIDE IF')
                            // console.log(k, 'INSIDE ELSE: ', transactionDetail[k].availablerReceivedQuantity);
                            $scope.convertedTransactions[i].details[j].canBeDeliver = transactionDetail[k].availablerReceivedQuantity
                            $scope.convertedTransactions[i].details[j].canNotBeDeliver = transactionDetailQuantity - transactionDetail[k].availablerReceivedQuantity
                            transactionDetail[k].availablerReceivedQuantity = 0
                            $scope.convertedTransactions[i].details[j].canFullyDeliver = false
                            $scope.convertedTransactions[i].details[j].canPartiallyDeliver = true
                            $scope.convertedTransactions[i].details[j].canCancel = false
                          }
                        } else {
                          console.log('ELSE INSIDE ELSE')
                          // console.log(k, 'OUTSIDE ELSE: ', transactionDetail[k].availablerReceivedQuantity);
                          transactionDetail[k].availablerReceivedQuantity = 0
                          $scope.convertedTransactions[i].details[j].canBeDeliver = 0
                          $scope.convertedTransactions[i].details[j].canNotBeDeliver = transactionDetailQuantity
                          $scope.convertedTransactions[i].details[j].canFullyDeliver = false
                          $scope.convertedTransactions[i].details[j].canPartiallyDeliver = false
                          $scope.convertedTransactions[i].details[j].canCancel = true
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Full -> price, totalTransaction
    // Partially -> price, totalTransaction, quantity and reserved qnt
    // Cancel -> reserved qnt, status

    // When Retailer tells to customer that your order can be full deliver
    // we need to update price, total and totalTransaction and status as completed and send email
    $scope.fullDeliverFromPurchaseOrder = function (parentIndex, detailIndex) {
      // console.log('INDEXES: ', parentIndex, detailIndex);
      var transaction = $scope.convertedTransactions[parentIndex]
      var transactionDetail = transaction.details[detailIndex]
      // console.log('details: ', transaction, transactionDetail);
      var body = {
        transactionId: transaction._id,
        transactionDetailId: transactionDetail._id,
        transactionDetailPriceExVat: (transactionDetail.priceIncVat) / (1 + (transactionDetail.priceVat / 100)),
        transactionDetailTotal: (transactionDetail.priceIncVat * transactionDetail.quantity).toFixed(2),
        transactionTotal: (transactionDetail.priceIncVat * transactionDetail.quantity).toFixed(2),
        transactionQuantity: transactionDetail.quantity,
        shopId: $rootScope.currentShop._id
      }
      console.log('body: ', body)
      $customerInform.fullDeliverFromPurchaseOrder(body).then(function (response) {
        $scope.convertedTransactions[parentIndex].status = 'completed'
        $uibModalInstance.close('full')
      }).catch(function (error) {
        console.log('something went wrong while upading full Deliver: ', error)
      })
    }

    // When Retailer tells to customer that your order can be partial deliver
    // we need to update price and totalTransaction, detail.qnt and status as completed and reserve qnt and orderquntity of items(originalTransQnt - deliverQnt) and send email
    $scope.partialDeliverFromPurchaseOrder = function (parentIndex, detailIndex) {
      console.log('INDEXES: ', parentIndex, detailIndex)
      var transaction = $scope.convertedTransactions[parentIndex]
      var transactionDetail = transaction.details[detailIndex]
      var transactionItem = transaction.items[detailIndex]
      console.log('details: ', transaction, transactionDetail)
      var body = {
        transactionId: transaction._id,
        transactionDetailId: transactionDetail._id,
        transactionDetailPriceExVat: (transactionDetail.priceIncVat) / (1 + (transactionDetail.priceVat / 100)),
        transactionDetailTotal: (transactionDetail.priceIncVat * transactionDetail.canBeDeliver).toFixed(2),
        transactionTotal: (transactionDetail.priceIncVat * transactionDetail.canBeDeliver).toFixed(2),
        transactionQuantity: transactionDetail.canBeDeliver,
        shopId: $rootScope.currentShop._id,
        deductReserveQuantity: transactionDetail.canNotBeDeliver,
        productId: transactionDetail.productId,
        purchaseOrderId: purchaseOrderDetail._id,
        purchaseOrderItemId: transactionItem._id
      }
      console.log('body: ', body)
      $customerInform.partialDeliverFromPurchaseOrder(body).then(function (response) {
        $scope.convertedTransactions[parentIndex].status = 'completed'
        $uibModalInstance.close('partial')
      }).catch(function (error) {
        console.log('something went wrong while upadting partial Deliver: ', error)
      })
    }

    // When Retailer tells to customer that your order is cancel
    // we need to update detai.price and totalTransaction, detail.qnt and detail.total and status as completed and reserve qnt and orderquntity of items(originalTransQnt - deliverQnt)
    $scope.cancelTransactionFromPurchaseOrder = function (parentIndex, detailIndex) {
      console.log('INDEXES: ', parentIndex, detailIndex)
      var transaction = $scope.convertedTransactions[parentIndex]
      var transactionDetail = transaction.details[detailIndex]
      var transactionItem = transaction.items[detailIndex]
      console.log('details: ', transaction, transactionDetail)
      var body = {
        transactionId: transaction._id,
        transactionDetailId: transactionDetail._id,
        transactionDetailPriceExVat: (transactionDetail.priceIncVat) / (1 + (transactionDetail.priceVat / 100)),
        transactionDetailTotal: (transactionDetail.priceIncVat * transactionDetail.canBeDeliver).toFixed(2),
        transactionTotal: (transactionDetail.priceIncVat * transactionDetail.canBeDeliver).toFixed(2),
        transactionQuantity: transactionDetail.canBeDeliver,
        shopId: $rootScope.currentShop._id,
        deductReserveQuantity: transactionDetail.canNotBeDeliver,
        productId: transactionDetail.productId,
        purchaseOrderId: purchaseOrderDetail._id,
        purchaseOrderItemId: transactionItem._id
      }
      console.log('body: ', body)
      $customerInform.cancelTransactionsFromPurchaseOrder(body).then(function (response) {
        $scope.convertedTransactions[parentIndex].status = 'completed'
        $uibModalInstance.close('cancel')
      }).catch(function (error) {
        console.log('something went wrong while upadting cancel Deliver: ', error)
      })
    }

    // // When Retailer tells to customer that your order is cancel
    // // We need to update the reservedQnt and status as cancel
    // $scope.cancelTransactionFromPurchaseOrderOld = function (parentIndex, detailIndex) {
    //     console.log('INDEXES: ', parentIndex, detailIndex);
    //     var transaction = $scope.convertedTransactions[parentIndex];
    //     var transactionDetail = transaction.details[detailIndex];
    //     console.log('details: ', transaction, transactionDetail);
    //     var body = {
    //         transactionId: transaction._id,
    //         shopId: $rootScope.currentShop._id,
    //         transactionQuantity: transactionDetail.quantity,
    //         productId: transactionDetail.productId,
    //     }
    //     console.log('body: ', body);
    //     $customerInform.cancelTransactionsFromPurchaseOrder(body).then(function (response) {
    //         $scope.convertedTransactions[parentIndex].status = 'cancelled';
    //         $uibModalInstance.close('cancel');
    //     }).catch(function (error) {
    //         console.log('something went wrong while upadting cancel Deliver: ', error);
    //     });
    // }

    $scope.cancel = function () {
      $uibModalInstance.close('default')
    }
  }])
