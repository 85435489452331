    prismanoteApp.controller('adminBrandProductsStartingDigitsController', ['$scope', '$shop', '$rootScope', '$stateParams', '$brand', '$productConfig', '$state', '$uibModal', 'prompt', '$product',
      function ($scope, $shop, $rootScope, $stateParams, $brand, $productConfig, $state, $uibModal, prompt, $product) {
        $scope.renderPage = function () {
          console.log("Starting Digits Brand's Product")
          $scope.brandNameSlug = $stateParams.nameSlug || null
          // $scope.newStartingDigit = ''
          $scope.unAccess = false
          $scope.withOldStartingDigits = []
          $scope.withOldStartingDigitsSuggestion = []
          $scope.notUse = []
          $scope.notUseSuggestion = []
          $scope.notUseAuto = []
          $scope.searchValue = {}
          $scope.loading = false
          $scope.checkBox = false
          $scope.searchText = {
            newStartingDigit: ''
          }
          getBrandsProductAuto($scope.brandNameSlug)
          getBrandProducts($scope.brandNameSlug)
          getBrandProductSuggestions($scope.brandNameSlug)
        }

        function getBrandProducts(brandNameSlug) {
          if (brandNameSlug) {
            $scope.loading = true
            $brand.getBrandsProductForAdmin(brandNameSlug)
            .then(function (data) {
              var sortedData = _.orderBy(data.products, ['productNumber'], ['asc'])
              $scope.products = sortedData || []
              $scope.brand = data.brand
              $scope.loading = false
            })
            .catch(function (error) {
              $scope.loading = false
              console.error('Error While Getting Brand Products')
            })
          } else {
            $scope.unAccess = true
          }
        }

        function getBrandsProductAuto(brandNameSlug) {
          if (brandNameSlug) {
            $scope.loading = true
            $brand.getBrandsProductAutoForAdmin(brandNameSlug)
            .then(function (data) {
              var sortedData = _.orderBy(data.products, ['productNumber'], ['asc'])
              $scope.productsAuto = sortedData || []
              $scope.loading = false
            })
            .catch(function (error) {
              $scope.loading = false
              console.error('Error While Getting Brand Products')
            })
          } else {
            $scope.unAccess = true
          }
        }


        function getBrandProductSuggestions(brandNameSlug) {
          if (brandNameSlug) {
            $scope.loading = true
            $brand.getBrandProductSuggestions(brandNameSlug)
            .then(function (data) {
              var sortedData = _.orderBy(data.data, ['productNumber'], ['asc'])
              $scope.productSuggestions = sortedData || []
              $scope.loading = false
            })
            .catch(function (error) {
              $scope.loading = false
              console.error('Error While Getting Brand Product Suggestions')
            })
          } else {
            $scope.unAccess = true
          }
        }

        $scope.addOldStartingDigits = function (searchText, products) {
          console.log('------------------- addOldStartingDigits!!');
          console.log('searchText', searchText, searchText.length)
          console.log('products', products, products.length)
          if (searchText && searchText.length && products && products.length) {
            $scope.withOldStartingDigits.push({
              startingDigit: searchText,
              products: products,
              keep: true
            })

            var data = _.differenceBy($scope.products, products, 'productNumber')
            $scope.products = _.orderBy(data, ['productNumber'], ['asc'])

            $scope.searchValue = {
              text: ''
            }
          } else {
            $scope.searchValue = {
              text: ''
            }
          }
        }


        $scope.addOldStartingDigitsSuggestion = function (searchText, products) {
          
          console.log('searchText addOldStartingDigitsSuggestion', searchText, searchText.length)
          console.log('products addOldStartingDigitsSuggestion', products)
          console.log("products.length", products.length);
          if (searchText && searchText.length && products && products.length) {
            if (!$scope.withOldStartingDigitsSuggestion) $scope.withOldStartingDigitsSuggestion = [];

            $scope.withOldStartingDigitsSuggestion.push({
              startingDigit: searchText,
              products: products,
              keep: true
            })

            var data = _.differenceBy($scope.productSuggestions, products, 'productNumber')
            $scope.productSuggestions = _.orderBy(data, ['productNumber'], ['asc'])

            $scope.searchValue = {
              text: ''
            }

            console.log("$scope.productSuggestions", $scope.productSuggestions);
            console.log("withOldStartingDigitsSuggestion", $scope.withOldStartingDigitsSuggestion);

          } else {
            console.log("Else in the code");
            $scope.searchValue = {
              text: ''
            }
          }
        }

        $scope.removeStaringDigits = function (index) {
          $scope.products = $scope.products.concat($scope.withOldStartingDigits[index].products)
          $scope.withOldStartingDigits.splice(index, 1)
        }

        $scope.removeStaringDigitsSuggestions = function (index) {
          console.log($scope.withOldStartingDigitsSuggestion)
          var data = $scope.productSuggestions.concat($scope.withOldStartingDigitsSuggestion[index].products)
          $scope.productSuggestions = _.orderBy(data, ['productNumber'], ['asc'])
          $scope.withOldStartingDigitsSuggestion.splice(index, 1)
        }


        $scope.removeFromProducts = function (index) {
          $scope.notUse.push($scope.products[index])
          $scope.products.splice(index, 1)
        }

        $scope.removeFromProductSuggestions = function (index) {
          $scope.notUseSuggestion.push($scope.productSuggestions[index])
          $scope.productSuggestions.splice(index, 1)
        }

        $scope.addInProducts = function (firstIndex, secondIndex) {
          $scope.products.push($scope.withOldStartingDigits[firstIndex])
          $scope.products = _.orderBy($scope.products, ['productNumber'], ['asc'])
          $scope.withOldStartingDigits[firstIndex].products.splice(secondIndex, 1)
          if (!$scope.withOldStartingDigits[firstIndex].products.length) $scope.withOldStartingDigits.splice(firstIndex, 1)
        }

      $scope.addInProductSuggestions = function (firstIndex, secondIndex) {

        $scope.productSuggestions.push($scope.withOldStartingDigitsSuggestion[firstIndex].products[secondIndex]);
        $scope.productSuggestions = _.orderBy($scope.productSuggestions, ['productNumber'], ['asc'])
        $scope.withOldStartingDigitsSuggestion[firstIndex].products.splice(secondIndex, 1)
        if (!$scope.withOldStartingDigitsSuggestion[firstIndex].products.length) $scope.withOldStartingDigitsSuggestion.splice(firstIndex, 1)
      }

    $scope.notUseThisProductSuggestion = function (firstIndex, secondIndex) {
      $scope.notUseSuggestion.push($scope.withOldStartingDigitsSuggestion[firstIndex].products[secondIndex])
      $scope.withOldStartingDigitsSuggestion[firstIndex].products.splice(secondIndex, 1)
    }

    $scope.notUseThisProduct = function (firstIndex, secondIndex) {
      $scope.notUse.push($scope.withOldStartingDigits[firstIndex].products[secondIndex])
      $scope.withOldStartingDigits[firstIndex].products.splice(secondIndex, 1)
    }

    $scope.addInProductsOrOldStaringDigitsProducts = function (index) {
      var oldSDLength = ($scope.withOldStartingDigits && $scope.withOldStartingDigits.length) ? $scope.withOldStartingDigits.length : 0



      if(oldSDLength){
        for (var i = 0; i < oldSDLength; i++) {
          if ($scope.notUse[index].productNumber.substring(0, $scope.withOldStartingDigits[i].startingDigit.length) == $scope.withOldStartingDigits[i].startingDigit) {
            $scope.withOldStartingDigits[i].products.push($scope.notUse[index])
            $scope.notUse.splice(index, 1)
            i = oldSDLength
          }

          if (i == (oldSDLength - 1)) {
            $scope.products.push($scope.notUse[index])
            $scope.notUse.splice(index, 1)
          }
        }           
      }
      else{
        $scope.products.push($scope.notUse[index])
        $scope.notUse.splice(index, 1)
      }


      $scope.products = _.orderBy($scope.products, ['productNumber'], ['asc'])
    }

    $scope.addInProductsOrOldStaringDigitsProductSuggestions = function (index) {
      var oldSDLength = ($scope.withOldStartingDigitsSuggestion && $scope.withOldStartingDigitsSuggestion.length) ? $scope.withOldStartingDigitsSuggestion.length : 0


      if(oldSDLength){
        for (var i = 0; i < oldSDLength; i++) {
          if ($scope.notUseSuggestion[index].productNumber.substring(0, $scope.withOldStartingDigitsSuggestion[i].startingDigit.length) == $scope.withOldStartingDigitsSuggestion[i].startingDigit) {
            $scope.withOldStartingDigitsSuggestion[i].products.push($scope.notUseSuggestion[index])
            $scope.notUseSuggestion.splice(index, 1)
            i = oldSDLength
          }

          if (i == (oldSDLength - 1)) {
            $scope.productSuggestions.push($scope.notUseSuggestion[index])
            $scope.notUseSuggestion.splice(index, 1)
          }
        }            
      }
      else{
        $scope.productSuggestions.push($scope.notUseSuggestion[index])
        $scope.notUseSuggestion.splice(index, 1)
      }

      $scope.productSuggestions = _.orderBy($scope.productSuggestions, ['productNumber'], ['asc'])


    }

    // Work in progress!!
    $scope.checkPattern = function (newStartingDigit){
      console.log('----------------------- checkPattern!!');
      console.log('newStartingDigit  ', newStartingDigit);
      console.log("$scope.searchText.newStartingDigit", $scope.searchText.newStartingDigit);
      //var match = attrs.ngOptions.match(NG_OPTIONS_REGEXP)
      //var NG_OPTIONS_REGEXP = /^\s*([\s\S]+?)(?:\s+as\s+([\s\S]+?))?(?:\s+group\s+by\s+([\s\S]+?))?\s+for\s+(?:([\$\w][\$\w]*)|(?:\(\s*([\$\w][\$\w]*)\s*,\s*([\$\w][\$\w]*)\s*\)))\s+in\s+([\s\S]+?)(?:\s+track\s+by\s+([\s\S]+?))?$/
      return true
    }

    $scope.sample = function (data) {
      console.log('----------------------- sample!!');
      console.log('data  ', data);
    }

    $scope.buttonConditions = function (data) {
      console.log('----------------------- buttonConditions!!');
      console.log('buttonConditions  ', data);
      $scope.checkBox = !$scope.checkBox;
    }

    $scope.makeValidGroups = function (newStartingDigit) {

      console.log("searchText.newStartingDigit", $scope)

      console.log("$scope.searchText.newStartingDigit", $scope.searchText.newStartingDigit);


          // console.log("searchText.newStartingDigit", $scope.searchText.newStartingDigit)

          var confirmProducts = []
          var confirmProductSuggestions = []
          var confirmProductAuto = []

          console.log("Make a valid Group")

          // $scope.loading = true
          if ($scope.products && $scope.products.length) confirmProducts.push({
            oldStartingDigits: null,
            newStartingDigit: newStartingDigit,
            products: $scope.products,
            brandNameSlug: $scope.brandNameSlug
          })

            console.log("Confirm Products 1111 :::: ", confirmProducts)

            var oldSDLength = $scope.withOldStartingDigits.length

            for (var i = 0; i < oldSDLength; i++) {
              // if ($scope.withOldStartingDigits[i].keep && ($scope.withOldStartingDigits[i].startingDigit != newStartingDigit)) {
                confirmProducts.push({
                  oldStartingDigits: $scope.withOldStartingDigits[i].startingDigit,
                  newStartingDigit: newStartingDigit,
                  products: $scope.withOldStartingDigits[i].products,
                  brandNameSlug: $scope.brandNameSlug
                })
              // }
            }

            console.log("Confirm Products 2222 :::: ", confirmProducts)
            console.log('$scope.brandNameSlug ', $scope.brandNameSlug);

            if ($scope.productSuggestions && $scope.productSuggestions.length) confirmProductSuggestions.push({
              oldStartingDigits: null,
              newStartingDigit: newStartingDigit,
              products: $scope.productSuggestions,
              brandNameSlug: $scope.brandNameSlug
            })

              console.log("Confirm ProductSuggestion 1111 :::: ", confirmProducts)
              // console.log(product.brandStratingDigits);
              // console.log($scope.withOldStartingDigitsSuggestion);

              var oldSSDLength = $scope.withOldStartingDigitsSuggestion.length
              console.log('$scope.brandNameSlug ', $scope.brandNameSlug);

              for (var i = 0; i < oldSSDLength; i++) {
                // if ($scope.withOldStartingDigitsSuggestion[i].keep && ($scope.withOldStartingDigitsSuggestion[i].startingDigit != newStartingDigit)) {
                  confirmProductSuggestions.push({
                    oldStartingDigits: $scope.withOldStartingDigitsSuggestion[i].startingDigit,
                    newStartingDigit: newStartingDigit,
                    products: $scope.withOldStartingDigitsSuggestion[i].products,
                    brandNameSlug: $scope.brandNameSlug
                  })
                // }
              }

              console.log("Confirm ProductSuggestions 2222 :::: ", confirmProducts)


            if ($scope.productsAuto && $scope.productsAuto.length) confirmProductAuto.push({
              oldStartingDigits: null,
              newStartingDigit: newStartingDigit,
              products: $scope.productsAuto,
              brandNameSlug: $scope.brandNameSlug
            })

              console.log("Confirm ProductSuggestion 1111 :::: ", confirmProductAuto)

              var oldASDLength = ($scope.withOldStartingDigitsAuto && $scope.withOldStartingDigitsAuto.length) ? $scope.withOldStartingDigitsAuto.length : 0

              for (var i = 0; i < oldASDLength; i++) {
                if ($scope.withOldStartingDigitsAuto[i].keep && ($scope.withOldStartingDigitsAuto[i].startingDigit != newStartingDigit)) {
                  confirmProductAuto.push({
                    oldStartingDigits: $scope.withOldStartingDigitsAuto[i].startingDigit,
                    newStartingDigit: newStartingDigit,
                    products: $scope.withOldStartingDigitsAuto[i].products,
                    brandNameSlug: $scope.brandNameSlug
                  })
                }
              }
          console.log(" Hello Please check this confirmProducts " , confirmProducts)
          console.log(" Hello Please check this confirmProductSuggestions " , confirmProductSuggestions)
          console.log(" Hello Please check this brandNameSlug id", $scope.brandNameSlug)
          console.log(" Hello Please check this confirmProductAuto ", confirmProductAuto)

          applyNewStartingDigitInProducts(confirmProducts, confirmProductSuggestions, $scope.brandNameSlug, confirmProductAuto)
        }

        $scope.chnage = function (newStartingDigit) {
          $scope.searchText.newStartingDigit = (newStartingDigit) ? $productConfig.retrunValidProductNumber(newStartingDigit) : ''
          console.log("$scope.searchText.newStartingDigit", $scope.searchText.newStartingDigit);
        }

        function applyNewStartingDigitInProducts(confirmProducts, confirmProductSuggestions, brandNameSlug, confirmProductAuto) {
          if (brandNameSlug) {
            var data = {
              confirmProducts: confirmProducts || [],
              confirmProductSuggestions: confirmProductSuggestions || [],
              confirmProductAuto: confirmProductAuto || [],
              nameSlug: brandNameSlug
            }

            console.log("Hey product handling guy Check here SOME data")
            // console.log(JSON.stringify(data, null, 2))

            $brand.applyNewStartingDigitInSelectedProducts(data)
            .then(function (success) {
              console.log('Success')
              $scope.loading = false
              $state.go('admin.brand', {
                nameSlug: $scope.brand[$rootScope.language].nameSlug
              })
            })
            .catch(function (error) {
              console.error('Error in Apply New Staring Digits')
            })
          } else {
            $scope.loading = false
            console.log("You can't apply new staring digits")
          }
        }

        $scope.filterFn = function (product) {
          // Do some tests
          if ($scope.searchValue.text) {
            if (!($scope.searchValue && $scope.searchValue.text)) return true
              if (product.productNumber.startsWith($scope.searchValue.text.toLowerCase())) return true
                if (product.productNumber.startsWith($scope.searchValue.text.toUpperCase())) return true
                  else return false
                } else {
                  return true
                }
              }


              $scope.addOldStartingDigitsAuto = function (searchText, products) {
                console.log('searchText addOldStartingDigitsAuto', searchText, searchText.length)
                console.log('products addOldStartingDigitsAuto', products)
                console.log("products.length", products.length);
                if (searchText && searchText.length && products && products.length) {
                  if (!$scope.withOldStartingDigitsAuto) $scope.withOldStartingDigitsAuto = [];

                  $scope.withOldStartingDigitsAuto.push({
                    startingDigit: searchText,
                    products: products,
                    keep: true
                  })


                  console.log("productsAuto ", $scope.productsAuto)

                  var data = _.differenceBy($scope.productsAuto, products, 'productNumber')
                  
                  console.log(" data" , data)
                  $scope.productsAuto = _.orderBy(data, ['productNumber'], ['asc'])
                  $scope.searchValue = {
                    text: ''
                  }
                  console.log("$scope.productSuggestions", $scope.productsAuto);
                  console.log("withOldStartingDigitsAuto", $scope.withOldStartingDigitsAuto);

                } else {
                  console.log("Else in the code");
                  $scope.searchValue = {
                    text: ''
                  }
                }
              }

              $scope.removeStaringDigitsAuto = function (index) {
                console.log($scope.withOldStartingDigitsAuto)
                var data = $scope.productsAuto.concat($scope.withOldStartingDigitsAuto[index].products)
                $scope.productsAuto = _.orderBy(data, ['productNumber'], ['asc'])
                $scope.withOldStartingDigitsAuto.splice(index, 1)
              }

              $scope.addInProductAuto = function (firstIndex, secondIndex) {

                $scope.productsAuto.push($scope.withOldStartingDigitsAuto[firstIndex].products[secondIndex]);
                $scope.productsAuto = _.orderBy($scope.productsAuto, ['productNumber'], ['asc'])
                $scope.withOldStartingDigitsAuto[firstIndex].products.splice(secondIndex, 1)
                if (!$scope.withOldStartingDigitsAuto[firstIndex].products.length) $scope.withOldStartingDigitsAuto.splice(firstIndex, 1)
              }

            $scope.removeFromProductAuto = function (index) {
              $scope.notUseAuto.push($scope.productsAuto[index])
              $scope.productsAuto.splice(index, 1)
            }


            $scope.addInProductsOrOldStaringDigitsProductAuto = function (index) {
              var oldSDLength = ($scope.withOldStartingDigitsAuto && $scope.withOldStartingDigitsAuto.length) ? $scope.withOldStartingDigitsAuto.length : 0

              if(oldSDLength){

                for (var i = 0; i < oldSDLength; i++) {
                  if ($scope.notUseAuto[index].productNumber.substring(0, $scope.withOldStartingDigitsAuto[i].startingDigit.length) == $scope.withOldStartingDigitsSuggestion[i].startingDigit) {
                    $scope.withOldStartingDigitsAuto[i].products.push($scope.notUseAuto[index])
                    $scope.notUseAuto.splice(index, 1)
                    i = oldSDLength
                  }

                  if (i == (oldSDLength - 1)) {
                    $scope.productsAuto.push($scope.notUseAuto[index])
                    $scope.notUseAuto.splice(index, 1)
                  }
                }
              }else{
                console.log(" product handling guy Check this here have to also remove ")
                $scope.productsAuto.push($scope.notUseAuto[index])
                $scope.notUseAuto.splice(index, 1)
              }
              $scope.productsAuto = _.orderBy($scope.productsAuto, ['productNumber'], ['asc'])
            }

            $scope.filterFn2 = function (product) {

              if ($scope.searchValue.text) {
                console.log(" product handling guy check this here", $scope.searchValue.text)
                console.log(" product handling guy check this ", product)
                if (product.brandStratingDigits.startsWith($scope.searchValue.text.toLowerCase())) return true
                  else if (product.brandStratingDigits.startsWith($scope.searchValue.text.toUpperCase())) return true
                    else return false
                  }
                else{
                  return true  
                }
              }

        $scope.openAdminMergeSearchModal = function ($index) {
          console.log('$index: ', $index);
          var product = $scope.products[$index];
          console.log(product);
          var shopBody = {
            productId: product._id
          }
          $shop.isAnyShopProduct(shopBody).then(function (sres) {
            var isAnyShopProduct = sres.data.isAnyShopProduct;
            console.log('sres: 123', isAnyShopProduct, sres.data);
            if (isAnyShopProduct) {
              $scope.products[$index].isClicked = true;
              var modalInstance = $uibModal.open({
                templateUrl: '../views/modal/admin-merge-search-modal.html',
                controller: 'adminMergeSearchModalController',
                size: 'lg',
                resolve: {
                  product: function () {
                    return product
                  }
                }
              })

              modalInstance.result.then(function (response) {
                console.log('openAdminMergeSearchModal response: ', response);
                resolve(response)
              }).catch(function (reason) {
                console.log('--------------------REAOSON: ', reason);
                reject(reason)
              })
            } else {
              console.log('Not in any shop');
              prompt({
                title: 'Confirmation?',
                message: 'Brand is not used in any shop so removing it.'
              }).then(function (res) {
                // console.log('res: Modal', res);
                if (res.cancel) return true;
                var brandBody = {
                  productId: product._id
                }
                $product.removeBrandFromProduct(brandBody).then(function (bres) {
                  console.log('Brand Removed Successfully');
                  $scope.products.splice($index, 1);
                }).catch(function (error) {
                  console.log('Brand remove error: ', error);
                });
              });
            }

          }).catch(function (serr) {
            console.log('serr: ', serr);
            reject(serr);
          });
        }

      }
    ])

    // EAN number is always as 13 digist