prismanoteApp.controller('purchaseOrderMailReqProdImagesController', ['$scope', '$rootScope', '$stateParams', '$language', '$uibModal', '$api', '$translate', '$http', 'FileSaver', 'Blob', '$retailer', 'prompt', 'campaignFact', '$purchaseOrder',
  function ($scope, $rootScope, $stateParams, $language, $uibModal, $api, $translate, $http, FileSaver, Blob, $retailer, prompt, campaignFact, $purchaseOrder) {
    console.log('purchaseOrderMailReqProdImagesController')

    $scope.success_message = false
    $scope.$on('changeSelectedShop', function () {
      $scope.getParticularPurchaseOrder()
    })

    $scope.getParticularPurchaseOrder = function () {
      if ($rootScope.currentShop) {
        $scope.loading = true
        $api.get('purchase-order-for-mail/' + $stateParams.orderNo + '/' + $rootScope.currentShop._id)
          .then(function (response) {
            $scope.loading = false
            console.log('response.data', JSON.parse(JSON.stringify(response.data)))
            $scope.currentShopName = $rootScope.currentShop.name
            $scope.purchase_order = response.data
            $scope.youtubeLink = 'www.google.com'
            $scope.purchase_order.email = {
              toemail: response.data.company.email,
              // subject: 'New order for ' + response.data.company.name,
              subject: $language.translate('REQUEST_OF_PRODUCT_PHOTOS'),
              // body: $language.translate("DEAR_SIR_MADAM_OF_") + response.data.company.name + '.<br> Retailer Remark: ' + response.data.lastCommentofRetailer.remark + '<br>' + $language.translate("WE_WOULD_LIKE_TO_ORDER_THANKS_FOR_SMOOTH_DELIVERY"),
              // body: $language.translate('EXPLANATION_OF_REQUEST_IMAGES') + ' <br> <a href="https://' + $scope.youtubeLink + '">' + $language.translate('HOW_TO_UPLOAD') + '</a>'
              body: $language.translate('EXPLANATION_OF_REQUEST_IMAGES')
            }
            // console.log("$scope.purchase_order", $scope.purchase_order)
          })
          .catch(function (reason) {
            $scope.loading = false
            // console.error("REASON", reason);
          })
      } else {
        console.log('currentshop not defined')
      }
    }

    $scope.sendTheMail = function () {
      // var sendFeedBackButtonText = $language.translate('CLICK_ON_THIS_LINK_TO_GIVE_US_FEEDBACK');
      // var sendFeedBack = "https://prismanote.com/brand/purchase-order/" + $scope.purchase_order.number;
      // var request_body = {
      //     tomail: $scope.purchase_order.email.toemail,
      //     subject: $scope.purchase_order.email.subject,
      //     purchase_order: $scope.purchase_order,
      //     shop: {
      //         email: $rootScope.currentShop.email
      //     },
      //     sendFeedBackLink: sendFeedBack,
      //     sendFeedBackButtonText: sendFeedBackButtonText,
      //     language: $scope.language || 'en'
      // }
      var request_body = {
        tomail: $scope.purchase_order.email.toemail,
        language: $scope.language || 'en',
        subject: $scope.purchase_order.email.subject,
        description: $scope.purchase_order.email.body,
        purchase_order: {
          _id: $scope.purchase_order._id,
          number: $scope.purchase_order.number,
          shop: {
            email: $rootScope.currentShop.email,
            _id: $scope.purchase_order.shop._id,
            name: $scope.purchase_order.shop.name,
            nameSlug: $scope.purchase_order.shop.nameSlug
          },
          company: $scope.purchase_order.company
        }
      }
      $scope.loading = true
      $purchaseOrder.mailToSupplierForReqProdImages(request_body).then(function (response) {
        $scope.loading = false
        $scope.success_message = true
        $scope.isEmailAlreadySent = true
      }).catch(function (error) {
        $scope.loading = false
        console.error('Error while sending purchase order', reason)
      })
    }

    // $scope.sendTheMail = function () {
    //     // will call the backend function to send the email
    //     // var myemailbody = document.getElementById('my-purchase-order-body');
    //     var sendFeedBackButtonText = $language.translate('CLICK_ON_THIS_LINK_TO_GIVE_US_FEEDBACK');
    //     // console.log('Hello langauar: ', languaaer);
    //     var sendFeedBack = "https://prismanote.com/brand/purchase-order/" + $scope.purchase_order.number;
    //     var request_body = {
    //         tomail: $scope.purchase_order.email.toemail,
    //         subject: $scope.purchase_order.email.subject,
    //         purchase_order: $scope.purchase_order,
    //         shop: $rootScope.currentShop,
    //         sendFeedBackLink: sendFeedBack,
    //         sendFeedBackButtonText: sendFeedBackButtonText,
    //         language: $scope.language
    //     }
    //     $scope.loading = true;

    //     $api.post('send-purchase-orders-in-mail', request_body)
    //         .then(function (response) {
    //             $scope.loading = false;
    //             $scope.success_message = true;
    //             $scope.isEmailAlreadySent = true;
    //         })
    //         .catch(function (reason) {
    //             $scope.loading = false;
    //             console.error("Error while sending purchase order", reason);
    //         })
    // }

    $scope.setMailLanguage = function (language) {
      $scope.language = language
      $language = language
      // console.log(language);
      $rootScope.changeLanguage(language)
    }
  }])
