prismanoteApp.controller('adminCampaignsController', ['$scope', '$rootScope', '$stateParams', '$state', '$q', '$retailer', '$api', '$uibModal', 'prompt', '$window',
  function ($scope, $rootScope, $stateParams, $state, $q, $retailer, $api, $uibModal, prompt, $window) {
    $scope.awsUrl = $rootScope.awsUrl
    // Default options for fetching new items: 12 items with featured items first, sorted by views.
    var defaultApiOptions = { sort: { startDate: 'desc' }, limit: 12 }

    // currently active API request params/options. Set to default options initially, may be changed or reset to default options later.
    var apiOptions = defaultApiOptions

    if (localStorage.getItem('activeTab')) {
      $scope.activeTab = JSON.parse(localStorage.getItem('activeTab'))
      if ($scope.activeTab == 0) {
        localStorage.removeItem('activeTab')
      }
    }
    // General setter to change the Api HTTP request options/params. Some wrapper functions are available to make the code more intuitive.
    var setApiOptions = function (options, type, append) {
      if (typeof options === 'object') {
        if (typeof append !== 'boolean' || append === false) {
          apiOptions[type] = options
        } else {
          apiOptions[type] = _.extend(apiOptions[type], options)
        }
      } else if (options == null) {
        apiOptions = options
      }
      $scope.products = new Array()
      $scope.getProducts()
    }

    $scope.campaigns = new Array()
    $scope.updates = new Array()
    $scope.getTabValue = function (type) {
      JSON.stringify(localStorage.setItem('activeTab', type))
    }

    $scope.getCampaigns = function () {
      $api.get('newcampaigns')
        .then(function (response) {
          // Add campaigns to the $scope
          var campaigns = response.data.data
          for (var i = 0; i < campaigns.length; i++) {
            if (campaigns[i].isUpdate) {
              $scope.updates.push(campaigns[i])
            } else {
              $scope.campaigns.push(campaigns[i])
            }
          }
          CampaignCountDown()
          // console.log('response :', $scope.updates.length, JSON.stringify($scope.campaigns[0], null, 2));
        })
        .catch(function (reason) {
          console.log(reason)
        })
    }

    function CampaignCountDown () {
      // console.log('CampaignCountDown fn called: ', $scope.campaigns);
      if ($scope.campaigns.length) {
        for (var i = 0; i < $scope.campaigns.length; i++) {
          // console.log(i, new Date($scope.campaigns[i].startDate).setHours(19, 0, 0));
          // if (!i) timer(new Date($scope.campaigns[i].startDate).setHours(19, 0, 0));
          // if (!i) {
          // console.log('i*****************', (new Date($scope.campaigns[i].startDate).setHours(19, 0, 0)) - new Date().getTime());
          if ($scope.campaigns[i] && $scope.campaigns[i].tasks.length && $scope.campaigns[i].tasks[0].firstInvitationEmail && $scope.campaigns[i].tasks[0].firstInvitationEmail.date) {
            var milliseconds = (new Date($scope.campaigns[i].tasks[0].firstInvitationEmail.date).setHours(19, 0, 0)) - new Date().getTime()
            // console.log('days: ', $scope.campaigns[i]._id, new Date($scope.campaigns[i].tasks[0].firstInvitationEmail.date), new Date().getTime(), milliseconds);
            if (milliseconds <= 0) {
              secondInvitationEmailCountDown()
            } else {
              firstInvitationEmailCountDown()
            }

            function firstInvitationEmailCountDown () {
              var seconds = milliseconds / 1000
              // function timer(i) {
              var days = Math.floor(seconds / (60 * 60 * 24))
              var hoursLeft = Math.floor((seconds) - (days * 86400))
              var hours = Math.floor(hoursLeft / 3600)
              var minutesLeft = Math.floor((hoursLeft) - (hours * 3600))
              var minutes = Math.floor(minutesLeft / 60)
              var remainingSeconds = seconds % 60
              function pad (n) {
                return (n < 10 ? '0' + n : n)
              }
              var curretnTimer = days + ' days, ' + pad(hours) + ':' + pad(minutes) + ':' + pad(remainingSeconds.toFixed(0))
              $scope.campaigns[i].timer = curretnTimer
            }

            function secondInvitationEmailCountDown () {
              if ($scope.campaigns[i] && $scope.campaigns[i].tasks.length && $scope.campaigns[i].tasks[0].secondInvitationEmail && $scope.campaigns[i].tasks[0].secondInvitationEmail.date) {
                var second_milliseconds = (new Date($scope.campaigns[i].tasks[0].secondInvitationEmail.date).setHours(19, 0, 0)) - new Date().getTime()
                if (second_milliseconds > 0) {
                  var seconds = second_milliseconds / 1000
                  var days = Math.floor(seconds / (60 * 60 * 24))
                  var hoursLeft = Math.floor((seconds) - (days * 86400))
                  var hours = Math.floor(hoursLeft / 3600)
                  var minutesLeft = Math.floor((hoursLeft) - (hours * 3600))
                  var minutes = Math.floor(minutesLeft / 60)
                  var remainingSeconds = seconds % 60
                  function pad (n) {
                    return (n < 10 ? '0' + n : n)
                  }
                  var curretnTimer = days + ' days, ' + pad(hours) + ':' + pad(minutes) + ':' + pad(remainingSeconds.toFixed(0))
                  $scope.campaigns[i].timer = curretnTimer
                }
              }
            }
          }

          // console.log(pad(days) + ":" + pad(hours) + ":" + pad(minutes) + ":" + pad(remainingSeconds));
          // seconds--;
          // document.getElementById('countdown').innerHTML = pad(days) + ":" + pad(hours) + ":" + pad(minutes) + ":" + pad(remainingSeconds);
          // if (seconds == 0) {
          // 	clearInterval(countdownTimer);
          // 	document.getElementById('countdown').innerHTML = "Completed";
          // } else {
          // 	seconds--;
          // }
          // }
          // setInterval(function () {
          // 	timer(0);
          // }, 1000)
          // }
        }
      }
    }

    // Old Deprecated
    // $scope.getCampaigns = function () {
    // 	var numberOfCampaigns = $scope.campaigns.length;
    // 	apiOptions.offset = numberOfCampaigns;
    // 	apiOptions.limit = 100;
    // 	$api.get('campaigns', apiOptions)
    // 		.then(function (response) {
    // 			// Add campaigns to the $scope
    // 			for (var i = 0; i < response.data.campaigns.length; i++) {
    // 				var campaign = response.data.campaigns[i];
    // 				if (campaign.update) {
    // 					$scope.updates.push(campaign);
    // 				} else {
    // 					$scope.campaigns.push(campaign);
    // 				}
    // 			}
    // 			console.log('response :', $scope.updates.length, JSON.stringify($scope.campaigns[0], null, 2));
    // 		})
    // 		.catch(function (reason) {
    // 			console.log(reason);
    // 		})
    // };

    $scope.getNumber = function (num) {
      return new Array(num)
    }
    // paste from retailer portal 1-11-2017
    $scope.facebookId = null

    $scope.orderByDate = function (log) {
      var date = new Date(log.date)
      return date
    }

    $scope.getOpenTasksCount = function () {
      $scope.openTasks = 0
      for (var i = 0; i < $scope.user.socialPortal.taskList.length; i++) {
        if (!$scope.user.socialPortal.taskList[i].completed) {
          $scope.openTasks++
        }
      }
    }

    $scope.openPreviewCampaignModal = function (campaign) {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/preview-campaign-modal.html',
        controller: 'previewCampaignModalController',
        size: 'lg',
        resolve: {
          campaign: function () {
            return campaign
          }
        }
      })

      modalInstance.result.then(function (result) {
        if (result) {
          $scope.user.socialPortal.campaigns.push(campaign)

          $scope.saveSocialPortal()
          $scope.getPortalCampaigns()

          $scope.alert = {
            type: 'success',
            msg: 'De promotie ' + campaign.name + " is toegevoegd bij Mijn promoties. <a href='/retailer/campaigns/" + campaign.nameSlug + "'> Bekijk de campagne</a>"
          }
        }
      }, function () {
        // dismissed
      })
    }

    $scope.completeTask = function (task) {
      if (task.type == 'order') {
        $window.open(task.url)
      }

      prompt({
        title: 'Taak uitvoeren',
        message: 'Is uitvoeren van taak ' + task.title + ' gelukt?'
      }).then(function () {
        task.completed = true
        task.dateCompleted = new Date()

        $scope.addLogItem({
          title: task.title + ' uitgevoerd',
          type: 'task',
          taskId: task._id,
          user: $scope.user.firstName + ' ' + ($scope.user.lastNamePrefix || '') + ' ' + $scope.user.lastName
        }, false)

        $scope.saveSocialPortal()
        $scope.getOpenTasksCount()
      })
    }

    $scope.markOpen = function (task) {
      task.completed = false
      $scope.addLogItem({
        title: task.title + ' als openstaand gemarkeerd',
        type: 'task',
        taskId: task._id,
        user: $scope.user.firstName + ' ' + ($scope.user.lastNamePrefix || '') + ' ' + $scope.user.lastName
      }, false)
      $scope.saveSocialPortal()
    }

    $scope.addLogItem = function (item, save) {
      var logItem = {
        title: item.title,
        date: new Date(),
        type: item.type,
        taskId: item.taskId,
        user: item.user
      }
      $scope.user.socialPortal.log.push(logItem)

      if (save) {
        $scope.saveSocialPortal()
      }
    }

    $scope.openTaskModal = function (task) {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/view-task-modal.html',
        controller: 'viewTaskModalController',
        size: 'lg',
        resolve: {
          task: function () {
            return task
          }
        }
      })

      modalInstance.result.then(function (result) {
        $scope.saveSocialPortal()
        $scope.getOpenTasksCount()
      }, function () {

      })
    }

    $scope.openCreateCampaignModal = function () {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/create-campaign-modal.html',
        controller: 'createCampaignModalController',
        size: 'lg'
      })

      modalInstance.result.then(function (result) {
        console.log('MODALRESULT', result)

        for (var i = result.length - 1; i >= 0; i--) {
          if (array[i] === tasks) {
            array.splice(i, 1)
          }
        }

        // niet invullen maar standaard waarde
        result.strategy = 'store-promotion'
        // result.number = $rootScope.randomNumber(); // edit this because ofcourse this is not logic
        result.nameSlug = $rootScope.slugify(result.name)
        // result.nameSlug = "hoe-genereer-je-een-slug";

        result.update = false
        result.images = []

        var task = {
          type: 'facebook',
          mandatory: true,
          defaultChecked: true,
          images: [],
          fbPromotionSettings: {
            alsoOnInsta: true
          },
          nl: {
            name: result.name
          }
        }
        result.tasks = [task]
        var image = {
          src: 'https://prismanotevoorjuweliers.nl/wp-content/uploads/2017/07/prismanote-vliegtuigje.png',
          alt: 'Standard My-Store-Promotion-campaign'
        }
        task.images.push(image)
        result.images.push(image)

        console.log('Tasks', result)
        $scope.user.socialPortal.campaigns.push(result)

        $scope.saveSocialPortal()
        $scope.getPortalCampaigns()
      }, function () {

      })
    }
  }])
