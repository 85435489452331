prismanoteApp.controller("retailerShopProductShowController", ['$scope', '$uibModalInstance', 'data', '$shop', '$productConfig',
    function ($scope, $uibModalInstance, data, $shop, $productConfig) {

        console.log("********* retailer shop product **********");
        console.log("Data", data);
        $scope.data = data;

        $scope.showStatus = function (status, shopId, productId) {
            console.log("status, shopId, productId", status, shopId, productId);
            $shop.updateShopProductShow(shopId, productId, status)
                .then(function (success) {
                    $scope.cancel();
                })
                .catch(function (error) {
                    console.error("Error" , error);
                })
            // $scope.cancel();
        }

        $scope.cancel = function () {
            console.log("Close the retailer product modal opening modal");
            $uibModalInstance.dismiss('cancel');
        }

        setTimeout(function () {
            $scope.cancel()
        }, 6000);

    }
]);