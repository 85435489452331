prismanoteApp.controller('brandMarketingUserController', ['$scope', '$rootScope', '$api', '$stateParams', '$uibModal', '$state', 'prompt', '$wholesaler', 'campaignFact', 'Upload',
  function ($scope, $rootScope, $api, $stateParams, $uibModal, $state, prompt, $wholesaler, campaignFact, Upload) {
    $scope.alertdanger = false
    $scope.alertsuccess = false
    $scope.updateUser = function (user) {
      user.brandId = $scope.user._id
      if ($scope.files != undefined) {
        Upload.upload({
          url: '/api/upload-marketing-user-image',
          data: {
            files: $scope.files
          }
        }).then(function (res) {
          console.log('response drom file upload ...', res)
          if (res.status == 200) {
            $scope.pathImage = 'https://prismanotetest.s3.amazonaws.com/' + res.data.files.path
            campaignFact.isRegistered(user).then(function (response) {
              console.log('registered or not', response)
              if (response.data.success == false) {
                $scope.saveMessage = response.data.error
                $scope.alertsuccess = false
                $scope.alertdanger = true
              } else {
                user.isMarketingUser = true
                user.marketingUserImage = $scope.pathImage
                console.log('$scope.pathImage', user)
                $api.post('register', {
                  user: user,
                  userRole: 'retailer'
                })
                  .then(function (response) {
                    if (response.status == 200 && response.data.email) {
                      $scope.saveMessage = 'marketing user successfully registered'
                      $scope.alertsuccess = true
                      $scope.alertdanger = false
                      setTimeout(function () {
                        var iddata = {
                          brandId: $scope.user._id
                        }
                        campaignFact.isRegistered(iddata).then(function (response) {
                          console.log('data for market use', response)
                          if (response.data.success == false) {
                            $scope.isUserExist = true
                            $scope.marketingUserData = response.data.error
                          } else {
                            $scope.isUserExist = false
                          }
                        })
                      }, 2000)
                    } else {
                      $scope.saveMessage = 'marketing user not registered'
                      $scope.alertdanger = true
                      $scope.alertsuccess = false
                      $scope.isUserExist = false
                    }
                  }, function (err) {
                    $scope.saveMessage = err
                    $scope.alertdanger = true
                    $scope.alertsuccess = false
                  })
              }
            })
          }
        })
      } else {
        console.log('please select an image')
      }
    }
    $scope.selectFiles = function (file) {
      $scope.files = file
    }

    var iddata = {
      brandId: $scope.user._id
    }
    $scope.isUserExist = false
    campaignFact.isRegistered(iddata).then(function (response) {
      if (response.data.success == false) {
        $scope.isUserExist = true
        $scope.marketingUserData = response.data.error
      } else {
        $scope.isUserExist = false
      }
    })
    $scope.removeUser = function (id) {
      prompt({
        title: 'Marketing verwijderen Gebruiker',
        message: 'Weet u zeker dat u deze marketinggebruiker wilt verwijderen?'
      }).then(function () {
        campaignFact.removeMarketingUser(id).then(function (response) {
          console.log('remove user', response)
          $scope.isUserExist = false
        })
      })
    }
  }])
