prismanoteApp.controller('retailerSerialNumberSelectionController', ['$scope', '$uibModalInstance', 'data', '$rootScope',
function ($scope, $uibModalInstance, data, $rootScope) {
	$scope.serialNumbers = []
	
	if (data.length) $scope.serialNumbers = data;
	
	$scope.chosenSerialNumber = {};

	$scope.chooseSerialNumber = function(obj) {
		$scope.chosenSerialNumber = obj;
		$uibModalInstance.close($scope.chosenSerialNumber);
	}

	$scope.dismiss = function () {
		$uibModalInstance.dismiss()
	}

	$scope.continue_wo_selection = function () {
		$uibModalInstance.close($scope.chosenSerialNumber);
	}
}])