prismanoteApp.controller('connectNewStoreWithShopModalController', ['$scope', 'connectStore', '$uibModalInstance',
  function ($scope, connectStore, $uibModalInstance) {
    $scope.connectStore = connectStore

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    $scope.updateFlags = function (flag, status) {
      console.log('----------- updateFlags!!');
      console.log('flag ', flag);
      console.log('status ', status);
      // console.log('$scope.connectStore.webShop ', $scope.connectStore.webShop);
      // console.log('$scope.connectStore.customers ', $scope.connectStore.customers);

      // if(flag == 'webShop') { $scope.connectStore.webShop = !$scope.connectStore.webShop; }
      // if(flag == 'customers') { $scope.connectStore.customers = !$scope.connectStore.customers; }
      $uibModalInstance.close({ status: 'update', store: $scope.connectStore })

      console.log('$scope.connectStore.webShop ', $scope.connectStore.webShop);
      console.log('$scope.connectStore.customers ', $scope.connectStore.customers);

    }

    $scope.confirm = function () {
      console.log('----------- confirm!!');
      $uibModalInstance.close({ status: 'add', store: $scope.connectStore })
    }

    $scope.remove = function () {
      console.log('----------- remove!!');
      $uibModalInstance.close({ status: 'remove', store: $scope.connectStore })
    }
  }])
