prismanoteApp
  .controller('compareProductController',
    ['$scope', '$rootScope', '$stateParams', '$productConfig', '$diamond', '$product', '$brand', '$parse', '$tax', 'Upload', '$state', '$admin', 'prompt', '$language',
      function ($scope, $rootScope, $stateParams, $productConfig, $diamond, $product, $brand, $parse, $tax, Upload, $state, $admin, prompt, $language) {
        console.log(" 'ADMIN' Edit Product Or Compare Page")

        $scope.getClassForDiamons = function (prodIndex, suggIndex, key) {
          if (key != 'gemColor' && key != 'gemPurity') {
            var sug = $scope.showProduct.diamonds[suggIndex][key]
            var prod = $scope.product.diamonds[prodIndex][key]
          } else {
            var sug = $scope.showProduct.diamonds[suggIndex][key].code
            var prod = $scope.product.diamonds[prodIndex][key].code
          }
          var colorCode

          if ((sug == undefined || sug == '') && (prod == undefined || prod == '')) colorCode = ' '
          else if (sug == prod) colorCode = ' '
          else if ((sug == undefined || sug == '') && (prod != undefined && prod != '')) colorCode = 'redColorClass'
          else if ((prod == undefined || prod == '') && (sug != undefined && sug != '')) colorCode = 'greenColorClass'
          else if (sug != prod) colorCode = 'orangeColorClass'
          return colorCode
        }

        $scope.getClassForSuggestion = function (sug, prod) {
          var colorCode
          if ((sug == undefined || sug == '') && (prod == undefined || prod == '')) {
            colorCode = ' '
          } else if (sug == prod) {
            colorCode = ' '
          } else if ((sug == undefined || sug == '') && (prod != undefined && prod != '')) {
            colorCode = 'redColorClass'
          } else if ((prod == undefined || prod == '') && (sug != undefined && sug != '')) {
            colorCode = 'greenColorClass'
          } else if (sug != prod) {
            colorCode = 'orangeColorClass'
          }
          return colorCode
        }

        $scope.getClassForSuggestionBoolen = function (sug, prod) {
          var colorCode
          if (sug == prod) colorCode = ' '
          else if ((sug == null || sug == undefined) && (prod != null && prod != undefined)) colorCode = 'redColorClass'
          else if ((prod == null || prod == undefined) && (sug != null && sug != undefined)) colorCode = 'greenColorClass'
          else if (sug != prod) colorCode = 'orangeColorClass'
          return colorCode
        }

        $scope.getClassForSexAudienceSuggestion = function (suggestedProduct) {
          // Get Color For Target Audiaince
          var sug = $scope.getSexAudience(suggestedProduct)
          var prod = $scope.targetAudience
          var colorCode
          if ((sug == undefined || sug == '') && (prod == undefined || prod == '')) {
            colorCode = ' '
          } else if (sug == prod) {
            colorCode = ' '
          } else if ((sug == undefined || sug == '') && (prod != undefined && prod != '')) {
            colorCode = 'redColorClass'
          } else if ((prod == undefined || prod == '') && (sug != undefined && sug != '')) {
            colorCode = 'greenColorClass'
          } else if (sug != prod) {
            colorCode = 'orangeColorClass'
          }
          return colorCode
        }

        $scope.getSexAudience = function (prod) {
          var targetAudience = ''
          if (prod.male) {
            if (prod.female) {
              if (prod.kids) {
                targetAudience = 'KIDS'
              } else {
                targetAudience = 'UNISEX'
              }
            } else {
              if (prod.kids) {
                targetAudience = 'BOYS'
              } else {
                targetAudience = 'GENTS'
              }
            }
          } else {
            if (prod.female) {
              if (prod.kids) {
                targetAudience = 'GIRLS'
              } else {
                targetAudience = 'LADIES'
              }
            }
          }
          return targetAudience
        }

        $scope.setGender = function (targetAudience) {
          switch (targetAudience) {
            case 'GENTS':
              $scope.product.male = true
              $scope.product.female = false
              $scope.product.kids = false
              break
            case 'LADIES':
              $scope.product.male = false
              $scope.product.female = true
              $scope.product.kids = false
              break
            case 'UNISEX':
              $scope.product.male = true
              $scope.product.female = true
              $scope.product.kids = false
              break
            case 'BOYS':
              $scope.product.male = true
              $scope.product.female = false
              $scope.product.kids = true
              break
            case 'GIRLS':
              $scope.product.male = false
              $scope.product.female = true
              $scope.product.kids = true
              break
            case 'KIDS':
              $scope.product.male = true
              $scope.product.female = true
              $scope.product.kids = true
              console.log('KId man', $scope.product.male)
              console.log('female', $scope.product.female)
              console.log('kid', $scope.product.kids)
              break
          }
        }

        $scope.setTargetAudience = function (male, female, kids) {
          if (male) {
            if (female) {
              if (kids) $scope.targetAudience = 'KIDS'
              else $scope.targetAudience = 'UNISEX'
            } else {
              if (kids) $scope.targetAudience = 'BOYS'
              else $scope.targetAudience = 'GENTS'
            }
          } else {
            if (female) {
              if (kids) $scope.targetAudience = 'GIRLS'
              else $scope.targetAudience = 'LADIES'
            }
          }
          $scope.setGender($scope.targetAudience)
        }

        // // Start Diamond Functions

        $scope.openDiamond = function (index) {
          // Open Main Product Diamond
          $scope.diamond = $scope.product.diamonds[index]
          $scope.diamondIndex = index
          $scope.editDiamond = true
        }

        $scope.openSuggDiamond = function (index) {
          // Open Suggestion Product Diamond

          $scope.suggDiamond = $scope.showProduct.diamonds[index]
          $scope.suggDiamondIndex = index
          $scope.editSuggDiamond = true
        }

        $scope.clearTheDiamond = function () {
          // Clear Diamond
          $scope.diamond = {
            quantity: '',
            gemKind: '',
            gemPurity: {
              code: ''
            },
            gemColor: {
              code: ''
            },
            gemCut: '',
            caratWeight: '',
            suggestedRetailPriceDiamond: ''
          }
          $scope.editDiamond = false
        }

        $scope.cancelNewDiamond = function (diamondIndex) {
          // Cancel New Diamond
          $scope.product.diamonds.splice(diamondIndex, 1)
          $scope.addDiamond = false
          $scope.clearTheDiamond()
        }

        $scope.addNewDiamondForm = function () {
          // Add New Diamond Form
          $scope.editDiamond = false
          $scope.clearTheDiamond()

          if (!$scope.product.diamonds) $scope.product.diamonds = []

          $scope.diamondIndex = $scope.product.diamonds.length
          $scope.addDiamond = true
        }

        $scope.pushDiamond = function (index) {
          // Add New Diamond In diamons array
          if (!$scope.product.diamonds) $scope.product.diamonds = []
          console.log('Diamond is emplty or not', $.isEmptyObject($scope.diamond))
          $scope.product.diamonds[index] = $scope.diamond
          $scope.editDiamond = false
          $scope.addDiamond = false
          $scope.clearTheDiamond()
        }

        $scope.deleteDiamond = function (index) {
          // Remove Diamond From Diamonds array
          $scope.product.diamonds.splice(index, 1)
        }

        $scope.assignValueForDiamond = function (prodIndex, suggIndex, key) {
          if (key != 'gemColor' && key != 'gemPurity') {
            $scope.product.diamonds[prodIndex][key] = $scope.showProduct.diamonds[suggIndex][key]
          } else $scope.product.diamonds[prodIndex][key].code = $scope.showProduct.diamonds[suggIndex][key].code
        }

        // END Diamond Functions

        $scope.getValueChangedIfSuggestedAudienceClick = function (suggestedProduct) {
          var sug = $scope.getSexAudience(suggestedProduct)
          $scope.targetAudience = sug
          $scope.setGender($scope.targetAudience)
        }

        $scope.showProductChange = function (obj, parentObj) {
          if (obj) {
            console.log('Suggestion Change', obj)
            $scope.showProduct = JSON.parse(JSON.stringify(obj))
            $scope.showProduct.suggestedRetailPrice = ($scope.showProduct.suggestedRetailPrice * (($scope.showProduct.suggestedRetailPriceVat / 100) + 1)) || undefined
            console.log('suggestion to merge', $scope.showProduct)
          }
        }

        $scope.convertDate = function (date) {
          var d = new Date(date)
          return d.toLocaleString()
        }

        $scope.setProductCategory = function (category) {
          $scope.product.category = category
        }

        function renderProduct() {
          console.group('Render Product')
          $scope.productNameSlug = $stateParams.nameSlug
          $scope.dialColors = $productConfig.getDialColors()
          $scope.strapColors = $productConfig.getStarpColors()
          $scope.gemKinds = $diamond.getGemKinds()
          $scope.gemPurities = $diamond.getGemPurities()
          $scope.gemColors = $diamond.getGemColors()
          $scope.gemCuts = $diamond.getGemCuts()
          $scope.eanValid = true
          $scope.PNvalid = true
          $scope.containsFilterInfoCurrent = false
          $scope.diamond = {
            quantity: '',
            gemKind: '',
            gemPurity: {
              code: ''
            },
            gemColor: {
              code: ''
            },
            gemCut: '',
            caratWeight: '',
            suggestedRetailPriceDiamond: ''
          }
          $scope.editDiamond = false
          $scope.addDiamond = false
          $scope.caseColors = $scope.jewelColors = Object.assign($scope.dialColors, $scope.strapColors)
          getProduct($scope.productNameSlug)
        }

        function getProduct(nameSlug) {
          var data = {
            nameSlug: nameSlug,
            isAdmin: true
          }

          $product.getProductWithSuggestions(data)
            .then(function (response) {
              console.log('Product Based on Name Slug', JSON.parse(JSON.stringify(response)))
              response = response.product[0]
              $scope.product = response.product
              // console.log('Old', $scope.product.suggestedRetailPrice)
              // console.log('New', $scope.product.suggestedRetailPrice)

              $scope.product.suggestedRetailPrice = Number($scope.product.suggestedRetailPrice)
              $scope.product.suggestedRetailPrice = ($scope.product.suggestedRetailPrice * (($scope.product.suggestedRetailPriceVat / 100) + 1))

              if (response.suggestions && response.suggestions.length && response.suggestions[0]._id) $scope.suggestions = response.suggestions

              if ($scope.product.brand && $scope.product.brand.name && $scope.product.brand.isPoolArticleBrand) $scope.disabled = true
              else if ($scope.product.brand && $scope.product.brand.name && !$scope.product.variants[0].productNumberAuto) $scope.PNandBrandDisabled = true
              if ($scope.product.images) $scope.image = $scope.product.images

              if ($scope.product.category === 'WATCH') {
                if ($scope.product.watch.dial && $scope.product.watch.dial.color) $scope.dialColor = $scope.dialColors.find(function (color) {
                  return color.key == $scope.product.watch.dial.color
                })
                if ($scope.product.watch.case && $scope.product.watch.case.color) $scope.caseColor = $scope.caseColors.find(function (color) {
                  return color.key == $scope.product.watch.case.color
                })
                if ($scope.product.watch.strap && $scope.product.watch.strap.color) $scope.strapColor = $scope.strapColors.find(function (color) {
                  return color.key == $scope.product.watch.strap.color
                })
              } else if ($scope.product.category === 'STRAP') {
                if ($scope.product.strap && $scope.product.strap.color) $scope.strapColor = $scope.strapColors.find(function (color) {
                  return color.key == $scope.product.strap.color
                })
              } else if ($scope.product.category === 'JEWEL') {
                if ($scope.product.jewel && $scope.product.jewel.color) $scope.jewelColor = $scope.jewelColors.find(function (color) {
                  return color.key == $scope.product.jewel.color
                })
              }

              $scope.oldProdct = JSON.parse(JSON.stringify($scope.product))

              $scope.setTargetAudience($scope.product.male, $scope.product.female, $scope.product.kids)

              if ($scope.product.containsFilterInfo && ($scope.product.containsFilterInfo.en || $scope.product.containsFilterInfo.de || $scope.product.containsFilterInfo.fr || $scope.product.containsFilterInfo.nl || $scope.product.containsFilterInfo.es)) $scope.containsFilterInfoCurrent = true

              if (!$scope.containsFilterInfoCurrent) {
                for (var i = 0; i < $scope.suggestions.length; i++) {
                  var suggestion = $scope.suggestions[i]
                  if (suggestion && suggestion.containsFilterInfo && (suggestion.containsFilterInfo.en || suggestion.containsFilterInfo.de || suggestion.containsFilterInfo.nl || suggestion.containsFilterInfo.en || suggestion.containsFilterInfo.fr)) $scope.containsFilterInfoCurrent = true
                }
              }

              // console.log('Current Contanis Filter info', $scope.containsFilterInfoCurrent)
              // console.groupEnd()
            })
            .catch(function (error) {
              console.error('Error! While Getting product', error)
              console.groupEnd()
            })
        }

        renderProduct()

        $scope.convertToUpperAndSpace = function (str) {
          // Convert To Upper and Space
          if (str) {
            str = str.toUpperCase()
            str = str.replace(' ', '_')
          }
          return str
        }

        $scope.getTaxForAdmin = function () {
          $tax.getTaxForAdmin()
            .then(function (tax) {
              console.log(tax)
              $scope.taxes = tax.data.rates
            })
            .catch(function (error) {
              console.error('Error in getting Tax Rate')
              console.error(error)
            })
        }

        $scope.getValueChangedIfSuggestionClick = function (sugvalue, prodfeildname) {
          console.log('Sug Values ====>', sugvalue)
          console.log('prodfeildname ====>', prodfeildname)

          if (prodfeildname == 'product.watch.strap.color') $scope.setStrapColorInitially(sugvalue)
          if (prodfeildname == 'product.watch.case.color') $scope.setCaseColorInitially(sugvalue)
          if (prodfeildname == 'product.watch.dial.color') $scope.setDialColorInitially(sugvalue)
          if (prodfeildname == 'product.jewel.color') $scope.setJewelColorInitially(sugvalue)
          // Get the model
          var model = $parse(prodfeildname)
          // Assigns a value to it
          model.assign($scope, sugvalue)
          if (prodfeildname == 'product.variants[0].productNumber') {
            if ($scope.product.brand) $scope.productOldBrand = JSON.parse(JSON.stringify($scope.product.brand));
            console.log('$scope.product', $scope.product)
            console.log('$scope.productBrand', $scope.product.brand)
            if ($scope.product.variants && $scope.product.variants.length && $scope.product.variants[0].productNumber) {
              var index = $scope.product.variants[0].productNumber.indexOf('-');
              console.log("Index of the divider", index);
              console.log(" ***** Offical Product :::: ");
              // Here have only possible the brand stating digit
              if (index > -1) {
                console.log("Here have staring digit applied");
                $scope.product.variants[0].insertedProductNumber = $scope.product.variants[0].productNumber.slice(index + 1);
                console.log("not have staring digit applied");
                if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                  console.log("You can apply new");
                  $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].insertedProductNumber));
                  $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                  $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
                } else {
                  console.log("No new Brands");
                  $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.variants[0].insertedProductNumber));
                  $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
                }
              } else {
                console.log("not have staring digit applied");
                if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                  console.log("You can apply new");
                  $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                  $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].productNumber));
                  $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
                } else {
                  console.log("No new Brands")
                  $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);        
                }
              }
            } else {
              console.log(" Have in else")
            }
          }

          if (prodfeildname == 'product.variants[0].ean') $scope.eanIsExistsOrNot($scope.product.variants[0].ean)
        }

        $scope.getValueChangedIfSuggestionClickForIndex = function (sugvalue, prodfeildname, index) {
          var finalprodfeildname = prodfeildname.replace('$index', index)
          // console.log("finalprodfeildname", prodfeildname, index);
          // Get the model
          var model = $parse(finalprodfeildname)
          // console.log("old value", $scope[finalprodfeildname]);
          // Assigns a value to it
          model.assign($scope, sugvalue)
          // console.log("new value", $scope[finalprodfeildname]);
        }

        // // Set The Values For the Key For Product

        $scope.setDialColor = function (color) {
          $scope.product.watch.dial.color = color.key
        }

        $scope.setCaseColor = function (color) {
          console.log('color value', color)
          console.log('scope value', $scope.caseColor)
          $scope.product.watch.case.color = color.key
        }

        $scope.setCaseColorInitially = function (color) {
          console.log('SET CASE COLOR CALLED FOR COLOR INITIALLY: ', color)
          if (color == null || color == undefined || color == '') return

          color = color.toUpperCase()
          console.log('CASE COLOR SENT FOR INITIALLY SETTING : ', color)
          for (var i = 0; i < $scope.caseColors.length; i++) {
            if ($scope.caseColors[i].key == color) {
              $scope.caseColor = $scope.caseColors[i]
              $scope.setCaseColor(color)
              break
            }
          }
          if (!$scope.caseColor) console.log('NO CASECOLOR MATCHED TO SET INITIALLY')
        }

        $scope.setDialColorInitially = function (color) {
          if (color == null || color == undefined || color == '') return
          for (var i = 0; i < $scope.dialColors.length; i++) {
            if ($scope.dialColors[i].key == color) {
              $scope.dialColor = $scope.dialColors[i]
              $scope.setDialColor(color)
              break
            }
          }
        }

        $scope.setJewelColorInitially = function (color) {
          if (color == null || color == undefined || color == '') return
          for (var i = 0; i < $scope.jewelColors.length; i++) {
            if ($scope.jewelColors[i].key == color) {
              $scope.jewelColor = $scope.jewelColors[i]
              $scope.setJewelColor(color)
              break
            }
          }
        }

        $scope.setJewelColor = function (color) {
          if ($scope.product.category == 'JEWEL') {
            if (!$scope.product.jewel) $scope.product.jewel = {}
            $scope.product.jewel.color = color.key
          }
        }

        $scope.setStrapColor = function (color) {
          if ($scope.product.category == 'WATCH') {
            if (!$scope.product.watch.strap) $scope.product.watch.strap = {}
            console.log('setStrapColor: ', color, color.key);
            $scope.product.watch.strap.color = color.key;
          } else if ($scope.product.category == 'STRAP') {
            $scope.product.strap.color = color.key
          }
        }

        $scope.setStrapColorInitially = function (color) {
          console.log('SET STRAP COLOR CALLED FOR COLOR INITIALLY: ', color)
          if (color == null || color == undefined || color == '') return

          color = color.toUpperCase()
          for (var i = 0; i < $scope.strapColors.length; i++) {
            if ($scope.strapColors[i].key == color) {
              if ($scope.product.category == 'WATCH') {
                $scope.strapColor = $scope.strapColors[i]
              } else if ($scope.product.category == 'STRAP') {
                $scope.onlyStrapColor = $scope.strapColors[i];
              }
              $scope.setStrapColor(color)
              break
            }
          }
        }

        $scope.searchBrands = function (str) {
          var matches = []
          $scope.brands.forEach(function (brand) {
            console.log('brand._id', brand)
            if ((brand[$rootScope.language] != undefined && brand[$rootScope.language].name != undefined) && ((brand[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) || (brand[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0))) matches.push(brand)
          })
          return matches
        }

        $scope.brandSelected = function (selected) {


          if ($scope.product.brand) $scope.productOldBrand = JSON.parse(JSON.stringify($scope.product.brand));

          var brand = {
            _id: selected.originalObject._id,
            name: selected.title,
            nameSlug: selected.originalObject.en.nameSlug,
            description: selected.originalObject.description,
            images: selected.originalObject.images,
            isPoolArticleBrand: selected.originalObject.isPoolArticleBrand,
            startingDigits: (selected.originalObject.startingDigits && selected.originalObject.startingDigits != 'null' && selected.originalObject.startingDigits != 'undefined' && selected.originalObject.startingDigits != 'NULL') ? selected.originalObject.startingDigits : ''
          }

          $scope.product.brand = brand
          console.log('$scope.productBrand', $scope.product.brand)
          if ($scope.product.variants && $scope.product.variants.length && $scope.product.variants[0].productNumber) {
            var index = $scope.product.variants[0].productNumber.indexOf('-');
            console.log("Index of the divider", index);
            console.log(" ***** Offical Product :::: ");
            // Here have only possible the brand stating digit
            if (index > -1) {
              console.log("Here have staring digit applied");
              $scope.product.variants[0].insertedProductNumber = $scope.product.variants[0].productNumber.slice(index + 1);
              console.log("not have staring digit applied");
              if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                console.log("You can apply new");
                $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].insertedProductNumber));
                $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
              } else {
                console.log("No new Brands");
                $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.variants[0].insertedProductNumber));
                $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
              }
            } else {
              console.log("not have staring digit applied");
              if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                console.log("You can apply new");
                $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].productNumber));
                $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);

              } else {
                console.log("No new Brands")
              }
            }
          } else {
            console.log(" Have in else")
          }
        }

        // // Tab Selection
        $scope.tabSelected = function (index) {
          console.log('check the index', index)
          $rootScope.activeTabSuggestedProduct = index
          $rootScope.activeTabCurrentProduct = index
        }

        $scope.getClassForImages = function (src) {
          // Get Color For the images
          var p_image_length = $scope.product.images.length || 0
          var exists

          if (src && p_image_length) {
            for (var i = 0; i < p_image_length; i++) {
              if ($scope.product.images[i].src && ($scope.product.images[i].src == src)) {
                exists = true
                i = p_image_length
              }
            }
            if (exists) return ''
            else return 'orangeColorClass'
          } else if (src) {
            return 'redColorClass'
          } else {}
        }

        function changeImageIndex(currentIndex, newIndex) {
          var image1 = $scope.image[currentIndex]
          var image2 = $scope.image[newIndex]
          $scope.image[currentIndex] = image2
          $scope.image[newIndex] = image1
        }

        $scope.previousImage = function (currentIndex) {
          changeImageIndex(currentIndex, (currentIndex - 1))
        }

        $scope.nextImage = function (currentIndex) {
          changeImageIndex(currentIndex, (currentIndex + 1))
        }

        $scope.getTheValueChangedForImages = function (index) {
          var newImage = $scope.showProduct.images[index]
          if (_.findIndex($scope.image, function (o) {
              return o.src == newImage.src
            }) < 0) {
            $scope.image.push($scope.showProduct.images[index])
          }
        }

        $scope.getTheCollectionName = function (collection) {
          var collectionName = ''
          if (collection.hasOwnProperty($rootScope.language)) {
            collectionName = collection[$rootScope.language].name
          } else if (collection.hasOwnProperty('en.name')) {
            collectionName = collection.en.name
          } else if (collection.hasOwnProperty('nl.name')) {
            collectionName = collection.nl.name
          } else if (collection.hasOwnProperty('de.name')) {
            collectionName = collection.de.name
          } else if (collection.hasOwnProperty('fr.name')) {
            collectionName = collection.fr.name
          } else if (collection.hasOwnProperty('name') && collection.name != '') {
            collectionName = collection.name
          } else {
            collectionName = 'NO NAME'
          }
          return collectionName
        }

        $scope.getBrands = function () {
          console.log('Get Prisma Note Brands')
          $brand.getAllBrands()
            .then(function (brands) {
              console.log('Brands =>', brands)
              $scope.brands = brands.brands
              console.log('Brands =>', $scope.brands)
            })
            .catch(function (error) {
              console.error('Error in Getting Brands', error)
            })
        }

        $scope.updateCollection = function (type, index) {
          if (type == 'variantCollections') {
            var tempCollection = JSON.parse(JSON.stringify($scope.showProduct.variantCollections[index]))
            $scope.product.variantCollections[index] = tempCollection
          } else {
            var tempCollection = JSON.parse(JSON.stringify($scope.showProduct.brandCollections[index]))
            $scope.product.brandCollections[index] = tempCollection
          }
        }

        $scope.addProductImage = function () {
          if (!$scope.images) $scope.images = []
          $scope.images.push({})
        }

        $scope.removeProductImage = function (mode, index) {
          if (mode == 'scope') $scope.images.splice(index, 1)
          else $scope.product.images.splice(index, 1)
        }

        $scope.updateProduct = function () {
          console.group('Update Product')

          // if($scope.product.suggestedRetailPrice != undefined && $scope.product.suggestedRetailPrice != null && $scope.product.suggestedRetailPrice > 0){
          // 	$scope.product.suggestedRetailPrice = $scope.product.suggestedRetailPrice / (($scope.product.suggestedRetailPriceVat / 100) + 1);
          // }
          console.log('$scope.product', $scope.product)
          // var newProduct = JSON.parse(JSON.stringify($scope.product));

          // function diff(obj1, obj2) {
          // 	var result = {};
          // 	if (Object.is(obj1, obj2)) {
          // 		return undefined;
          // 	}
          // 	if (!obj2 || typeof obj2 !== 'object') {
          // 		return obj2;
          // 	}
          // 	Object.keys(obj1 || {}).concat(Object.keys(obj2 || {})).forEach(function (key) {

          // 		if (key != '$$hashKey') {
          // 			if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
          // 				if (obj2[key] != undefined) result[key] = obj2[key];
          // 			}

          // 			if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
          // 				var value = diff(obj1[key], obj2[key]);
          // 				if (value !== undefined) result[key] = value;
          // 			}
          // 		}

          // 	});

          // 	return result;
          // }

          // function isEmpty(obj) {
          // 	for (var key in obj) {
          // 		if (obj.hasOwnProperty(key)) return false;
          // 	}
          // 	return true;
          // }

          // var diffObject = diff($scope.oldProdct, newProduct);

          // console.log("DiffObject", diffObject);
          // var tempResult = getOnlyChangedValue(diffObject);

          // function getOnlyChangedValue(diffObject) {
          // 	var newResult = {};
          // 	Object.keys(diffObject).forEach(function (key) {
          // 		if (diffObject[key] != null && diffObject[key] != undefined && !isEmpty(diffObject[key])) {
          // 			if (typeof (diffObject[key]) == 'object') {
          // 				var newData = getOnlyChangedValue(diffObject[key]);
          // 				if (newData) newResult[key] = newData;
          // 			}
          // 			else {
          // 				newResult[key] = diffObject[key];
          // 			}
          // 		}
          // 	})

          // 	if (isEmpty(newResult)) return null;
          // 	else return newResult;
          // }

          // if (tempResult) {
          // 	console.log("Use This data for update in product", tempResult);
          console.log('Yes Now You can update Product')
          console.log('$scope.product')
          $product.updateProductByAdmin($scope.product)
            .then(function (updatedProduct) {
              console.log('Product Updated Successfully')
              console.log('images', $scope.images)
              if ($scope.images && $scope.images.length > 0 && $scope.images[0].name) {
                $scope.upload = true
                Upload.upload({
                    url: 'api/product-image-upload',
                    data: {
                      productId: $scope.product._id,
                      files: $scope.images // file input field
                    }
                  })
                  .then(function (res) {
                    $state.go('admin.products')
                  })
                  .catch(function (err) {
                    $scope.alert = {
                      type: 'danger',
                      msg: 'upload error: ' + err
                    }
                  })
              } else {
                $state.go('admin.products')
              }
            })
            .catch(function (error) {
              console.error('Error! While Update Product By Admin', error)
            })
          // } else {
          // 	console.log("Please Change the Single Value");
          // }
        }

        $scope.$watch('images', function (newVal, oldVal) {
          $productConfig.productIsValidForUpdate($scope.product, $scope.canUpdate, $scope.images)
            .then(function (canUpdate) {
              $scope.canUpdate = canUpdate
              $scope.changedStatus = true
              console.log('canUpdate', $scope.canUpdate)
            })
            .catch(function (error) {
              $scope.canUpdate = {
                EN: false,
                DE: false,
                NL: false,
                FR: false,
                ES: false
              }
              console.error('Error: Please Contact PrismaNote Admin')
            })
        }, true)

        $scope.$watch('product', function (newVal, oldVal) {
          console.log('productIsValidForUpdate');

          $productConfig.productIsValidForUpdate($scope.product, $scope.canUpdate, $scope.images)
            .then(function (canUpdate) {
              $scope.canUpdate = canUpdate
              $scope.changedStatus = true
              console.log('canUpdate', $scope.canUpdate)
            })
            .catch(function (error) {
              $scope.canUpdate = {
                EN: false,
                DE: false,
                NL: false,
                FR: false,
                ES: false
              }
              console.error('Error: Please Contact PrismaNote Admin')
            })
        }, true)

        $scope.removeProductBrand = function () {
          if ($scope.product.brand) $scope.productOldBrand = JSON.parse(JSON.stringify($scope.product.brand));

          $scope.product.brand = {}
          if ($scope.product.variants && $scope.product.variants.length && $scope.product.variants[0].productNumber) {
            var index = $scope.product.variants[0].productNumber.indexOf('-');
            console.log("Index of the divider", index);
            console.log(" ***** Offical Product :::: ");
            // Here have only possible the brand stating digit
            if (index > -1) {
              console.log("Here have staring digit applied");
              $scope.product.variants[0].insertedProductNumber = $scope.product.variants[0].productNumber.slice(index + 1);
              console.log("not have staring digit applied");
              if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                console.log("You can apply new");
                $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].insertedProductNumber));
                $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
              } else {
                console.log("No new Brands");
                $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.variants[0].insertedProductNumber));
                $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
              }
            } else {
              console.log("not have staring digit applied");
              if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                console.log("You can apply new");
                $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].productNumber));
                $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);

              } else {
                console.log("No new Brands")
              }
            }
          } else {
            console.log(" Have in else")
          }
        }

        $scope.removeProduct = function (productId) {
          prompt({
              title: $language.translate('DELETE_PRODUCT') + '?',
              message: "Are you sure you want to remove this product from 'PRISMANOTE'? The product will be removed in night time so your change will be effected for all retailers next morning.",
              buttons: [{
                  label: $language.translate('DELETE_PRODUCT'),
                  primary: true,
                  id: 0
                },
                {
                  label: $language.translate('CANCEL'),
                  primary: false,
                  cancel: true,
                  id: 2
                }
              ]
            })
            .then(function (value) {
              if (value.id === 0) {
                $admin.removeProductByAdmin({
                    productId: productId
                  })
                  .then(function (reomved) {
                    $state.go('admin.products')
                  })
                  .catch(function (error) {
                    console.error('Error While Delete Product')
                    console.log(error)
                  })
              }
            })
            .catch(function (error) {})
        }

        $scope.eanIsExistsOrNot = function (ean) {
          // Enterd EAN number is valid or not
          if (ean && ean.length > 0) {
            $scope.eanValid = false
            $product.eanIsExistsOrNot(ean, $scope.product._id)
              .then(function (result) {
                if (result.status == true) $scope.eanValid = true
              })
              .catch(function (error) {
                console.error('Error =>>> ', error)
              })
          } else {
            $scope.eanValid = true
          }
        }

        $scope.productNumberIsExistsOrNot = function (productNumber) {
          $scope.PNvalid = false
          $scope.PNsort = true

          if (productNumber) {
            productNumber = $productConfig.retrunValidProductNumber(productNumber)
            if (productNumber && productNumber.length > 2) {
              $scope.PNsort = false
              // Product Number is valid Or not
              $product.productNumberIsExistsOrNot(productNumber, $scope.product._id)
                .then(function (result) {
                  console.log('Response From Server', result)
                  if (result.status == true) {
                    $scope.PNvalid = true
                    $scope.product.variants[0].productNumber = productNumber
                  } else {
                    $scope.product.variants[0].productNumber = productNumber
                  }
                })
                .catch(function (error) {
                  console.error('Error =>>> ', error)
                })
            }
          }
        }

        $scope.applySuggestionBrand = function () {
          if ($scope.product.brand) $scope.productOldBrand = JSON.parse(JSON.stringify($scope.product.brand));

          if ($scope.showProduct.brand && $scope.showProduct.brand._id) $scope.product.brand = JSON.parse(JSON.stringify($scope.showProduct.brand))

          console.log('$scope.product', $scope.product)
          console.log('$scope.productBrand', $scope.product.brand)
          if ($scope.product.variants && $scope.product.variants.length && $scope.product.variants[0].productNumber) {
            var index = $scope.product.variants[0].productNumber.indexOf('-');
            console.log("Index of the divider", index);
            console.log(" ***** Offical Product :::: ");
            // Here have only possible the brand stating digit
            if (index > -1) {
              console.log("Here have staring digit applied");
              $scope.product.variants[0].insertedProductNumber = $scope.product.variants[0].productNumber.slice(index + 1);
              console.log("not have staring digit applied");
              if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                console.log("You can apply new");
                $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].insertedProductNumber));
                $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
              } else {
                console.log("No new Brands");
                $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.variants[0].insertedProductNumber));
                $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);
              }
            } else {
              console.log("not have staring digit applied");
              if ($scope.product.brand.startingDigits && $scope.product.brand.startingDigits.length) {
                console.log("You can apply new");
                $scope.product.variants[0].brandStratingDigits = $scope.product.brand.startingDigits
                $scope.product.variants[0].productNumber = JSON.parse(JSON.stringify($scope.product.brand.startingDigits + '-' + $scope.product.variants[0].productNumber));
                $scope.productNumberIsExistsOrNot($scope.product.variants[0].productNumber);

              } else {
                console.log("No new Brands")
              }
            }
          } else {
            console.log(" Have in else")
          }
        }
        
      }
    ])