prismanoteApp.controller('shopStatisticsCompanyModalController', ['$scope', 'modalData', '$uibModalInstance', '$brand', '$rootScope',
    function ($scope, modalData, $uibModalInstance, $brand, $rootScope) {
        console.log('modalData: ', modalData.shopStatisticsNotification);
        $scope.localdata = {
            // email: modalData.shopStatisticsNotification.email ? modalData.shopStatisticsNotification.email : undefined,
            end_date: modalData.shopStatisticsNotification.end_date ? new Date(modalData.shopStatisticsNotification.end_date) : undefined,
            interval: modalData.shopStatisticsNotification.interval && modalData.shopStatisticsNotification.interval.nNumber ? modalData.shopStatisticsNotification.interval.nNumber : undefined,
            // interval: 1,
            endDateOptions: {
                minDate: new Date(),
                // minDate: new Date((+new Date() + 2592000000)), 30 days
                // dateDisabled: disabled
            },
            email: '',
            isSelected: false,
            isEmailFetched: false,
            isCustomEmailAdded: false,
            // enum: ['Year', 'One-month', 'Two-months', 'Three-months', 'Four-months', 'Five-months', 'Half-year'],
            intervals: [
                {
                    nNumber: 1,
                    sString: 'One-month'
                },
                {
                    nNumber: 2,
                    sString: 'Two-months'
                },
                {
                    nNumber: 3,
                    sString: 'Three-months'
                },
                {
                    nNumber: 4,
                    sString: 'Four-months'
                },
                {
                    nNumber: 5,
                    sString: 'Five-months'
                },
                {
                    nNumber: 6,
                    sString: 'Half-year'
                },
                {
                    nNumber: 12,
                    sString: 'Year'
                },
            ]
        }

        console.log('interval: ', $scope.localdata.interval);
        // console.log('shopStatisticsCompanyModalController called: ', $scope.localdata);

        $scope.getCompanyEmailAndThereUserEmailAddress = function () {
            $brand.getCompanyEmailAndThereUserEmailAddress(modalData).then(function (response) {
                $scope.localdata.isEmailFetched = true;
                $scope.emails = [];
                if (response && response.data && response.data.data && response.data.data.length) {
                    $scope.emails = response.data.data;
                    $scope.AlloriginalEmails = JSON.parse(JSON.stringify($scope.emails));
                }
                console.log('$scope.emails: ', $scope.emails);
            }).catch(function (error) {
                $scope.localdata.isEmailFetched = true;
                console.log('error: ', error);
            });
        }

        $scope.companySeeSettings = function () {
            var body = {
                shop: {
                    _id: $rootScope.currentShop._id,
                    name: $rootScope.currentShop.name,
                    nameSlug: $rootScope.currentShop.nameSlug,
                },
                companyId: modalData.companyId,
                email: $scope.localdata.email.originalEmail,
                end_date: $scope.localdata.end_date,
                language: $rootScope.language || 'en',
                interval: getIntervalObject($scope.localdata.interval)
            }
            console.log('companySeeSettings body: ', body);
            $brand.updateShopStatisticsSettingsForNotification(body).then(function (response) {
                $scope.close('updated Successfully');
            }).catch(function (error) {
                console.log('error: ', error);
            });
        }

        function getIntervalObject(intervalNumber) {
            // $scope.localdata.interval;
            if (intervalNumber === 1) {
                return {
                    nNumber: 1,
                    sString: 'One-month'
                }
            } else if (intervalNumber === 2) {
                return {
                    nNumber: 2,
                    sString: 'Two-months'
                }
            } else if (intervalNumber === 3) {
                return {
                    nNumber: 3,
                    sString: 'Three-months'
                }
            } else if (intervalNumber === 4) {
                return {
                    nNumber: 4,
                    sString: 'Four-months'
                }
            } else if (intervalNumber === 5) {
                return {
                    nNumber: 5,
                    sString: 'Five-months'
                }
            } else if (intervalNumber === 6) {
                return {
                    nNumber: 6,
                    sString: 'Half-year'
                }
            } else {
                return {
                    nNumber: 12,
                    sString: 'Year'
                }
            }
        }

        $scope.hideEmployeeEmailAddress = function (email) {
            // console.log('hideEmployeeEmailAddress: ', email);
            if (!email.isCompanyEmail && email.email && !email.isCustomEmail) {
                var last4Char = email.email.slice(-4);
                var first3Char = email.email.substring(0, 3);
                email.email = first3Char + last4Char.padStart(email.email.length - 3, '*');
            } else {
                email.email = email.email ? email.email : '';
            }
            return (email.fullName || ' ') + ' (' + (email.email || ' ') + ')' + (email.isCustomEmail ? ' Custom Email ' : '');
        }

        $scope.close = function (data) {
            var message = 'cancel';
            if (!data) data = message;
            $uibModalInstance.close(data);
        }

        $scope.getEmails = function (search) {
            // console.log('search: ', search);
            // var allEmails = $scope.AlloriginalEmails.slice();

            // console.log('allEmails: ', allEmails);
            if (search) {
                // $scope.emails.unshift({ email: search, originalEmail: search, isCompanyEmail: false, isCustomEmail: true });
                var isFound = false;
                // $scope.emails.filter(function (item) {
                //     if (!item.isCustomEmail) return;
                // });
                // $scope.emails = JSON.parse(JSON.stringify($scope.AlloriginalEmails));
                $scope.localdata.isCustomEmailAdded = false;
                for (var i = 0; i < $scope.AlloriginalEmails.length; i++) {
                    // console.log(i, $scope.emails[i].originalEmail);
                    if ($scope.emails[i] && $scope.emails[i].originalEmail && $scope.emails[i].originalEmail.toLowerCase().indexOf(search.toString().toLowerCase()) >= 0) {
                        isFound = true;
                        break;
                    }
                }

                if (!isFound && validateEmail(search)) {
                    $scope.localdata.isCustomEmailAdded = true;
                    $scope.emails = JSON.parse(JSON.stringify($scope.AlloriginalEmails));
                    // console.log('not found');
                    $scope.emails.unshift({ email: search, originalEmail: search, isCompanyEmail: false, isCustomEmail: true });
                }
            }
            //  else if ($scope.localdata.isCustomEmailAdded) {
            //     $scope.localdata.isCustomEmailAdded = false;
            //     $scope.emails = JSON.parse(JSON.stringify($scope.AlloriginalEmails));
            // }

            return $scope.emails;
        }

        $scope.changeTheStaus = function () {
            console.log($scope.localdata.interval);
        }

        function validateEmail(email) {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        }
    }]);
