prismanoteApp.controller('brandOrdersController', ['$scope', '$rootScope', '$api', '$retailer', '$state',
  function ($scope, $rootScope, $api, $retailer, $state) {
    $scope.loading = true
    $scope.transactions = []

    // Pagination
    $scope.pageLimit = 10
    $scope.currentPage = 1
    $scope.totalRecords = 0
    $scope.pagePerLimitOptions = [
      {
        value: 10,
        text: '10'
      },
      {
        value: 25,
        text: '25'
      },
      {
        value: 50,
        text: '50'
      },
      {
        value: 100,
        text: '100'
      }
    ]

    // filter
    $scope.activeTransactionStatus = 'all'
    $scope.transactionsStatusOption = [
      {
        value: 'all',
        text: 'All Items'
      },
      {
        value: 'pending_payment',
        text: 'Pending Payments'
      },
      {
        value: 'new',
        text: 'New'
      },
      {
        value: 'canceled',
        text: 'Cancled'
      },
      {
        value: 'failed',
        text: 'Failed'
      },
      {
        value: 'expired',
        text: 'Expired'
      },
      {
        value: 'completed',
        text: 'Completed'
      },
      {
        value: 'refund',
        text: 'Refund'
      }
      /* {
				value: "delivered ",
				text: "Delivered "
			},
			{
				value: "return_request",
				text: "Return Request"
			},
			{
				value: "return",
				text: "Return"
			},
			{
				value: "return_denined ",
				text: "Return Denied"
			} */
    ]

    $scope.filter = {
      status: 'all',
      search: '',
      limit: $scope.pageLimit,
      skip: ($scope.currentPage - 1) * $scope.pageLimit
    }

    $scope.searchString = ''

    // Load all initial and filtered transactions
    $scope.loadAllTransactions = function (filter) {
      $scope.loading = true
      $scope.transactions = []
      $api.get('brand/webshop-orders/' + $rootScope.currentCompany._id, filter)
        .then(function (response) {
          if (response && response.data && response.data.result && response.data.result.transactions && response.data.result.transactions.length) {
            $scope.transactions = response.data.result.transactions
          }

          if (response && response.data && response.data.result && response.data.result.metadata && response.data.result.metadata.total) {
            $scope.totalRecords = response.data.result.metadata.total
          }

          $scope.loading = false
        })
        .catch(function (reason) {
          console.error('Error while getting the webshop transactions list', reason)
          $scope.loading = false
        })
    }

    // Refresh transactions while user will click on refresh button
    $scope.refreshTransaction = function () {
      $scope.pageLimit = 10
      $scope.currentPage = 1
      $scope.activeTransactionStatus = 'all'

      $scope.filter = {
        status: 'all',
        search: '',
        limit: $scope.pageLimit,
        skip: ($scope.currentPage - 1) * $scope.pageLimit
      }

      $scope.loadAllTransactions(JSON.parse(JSON.stringify($scope.filter)))
    }

    // Filter transaction based on status
 		$scope.filterTransactionStatus = function (status) {
      $scope.activeTransactionStatus = status
      $scope.pageLimit = 10
      $scope.currentPage = 1

      $scope.filter = {
        status: status,
        search: '',
        limit: $scope.pageLimit,
        skip: ($scope.currentPage - 1) * $scope.pageLimit
      }

      $scope.loadAllTransactions(JSON.parse(JSON.stringify($scope.filter)))
    }

    // Filter transaction based on search some words
    $scope.searchTransaction = function (searchString) {
      $scope.pageLimit = 10
      $scope.currentPage = 1
      $scope.activeTransactionStatus = 'all'

      $scope.filter = {
        status: 'all',
        search: searchString,
        limit: $scope.pageLimit,
        skip: ($scope.currentPage - 1) * $scope.pageLimit
      }

      $scope.loadAllTransactions(JSON.parse(JSON.stringify($scope.filter)))
    }

    // Change page limit
    $scope.changePagelimit = function (limit) {
      $scope.filter.limit = limit
      $scope.filter.skip = 0

      $scope.pageLimit = limit
      $scope.currentPage = 1

      $scope.loadAllTransactions(JSON.parse(JSON.stringify($scope.filter)))
    }

    $scope.pageChanged = function (currentPage) {
      $scope.currentPage = currentPage
      $scope.filter.skip = ($scope.currentPage - 1) * $scope.pageLimit

      $scope.loadAllTransactions($scope.filter)
    }

    $scope.redirectToDetails = function (transactionId) {
      $state.go('brand.orderDetails', { transactionId: transactionId })
    }

    // $scope.$on('changeSelectedShop', function () {
    //   $retailer.getShop()
    //     .then(function (shop) {
    //       $rootScope.currentCompany = shop
    //       $scope.pageLimit = 10
    //       $scope.currentPage = 1
    //       $scope.totalRecords = 0

    //       $scope.filter = {
    //         status: 'all',
    //         search: '',
    //         limit: $scope.pageLimit,
    //         skip: ($scope.currentPage - 1) * $scope.pageLimit
    //       }
    //       $scope.loadAllTransactions($scope.filter)
    //     })
    // })
    /* $scope.downloadPackageSlip = function (order) {
			//Dit doen we even met $htpp omdat de $api service vooralsnog geen responseType ondersteund.
			$http({
				method: 'POST',
				url: '/api/package-slip',
				data: {
					orderId: order._id
				},
				responseType: 'arraybuffer'
			})
				.then(function (response) {
					var file = new Blob([response.data], { type: 'application/pdf' });
					FileSaver.saveAs(file, "Order " + order.number + ".pdf");
				}, function (response) {
					console.error("Error while generating package slip", response);
				})
		}

		$scope.openReturnOrderModal = function (order) {
			var modalInstance = $uibModal.open({
				templateUrl: '../views/modal/return-order-modal.html',
				controller: 'returnOrder-modalController',
				resolve: {
					order: function () {
						return order;
					}
				}
			});

			modalInstance.result.then(function (result) {
				if (result) {
					//ready
				}
			}, function () {
				//dismissed
			})
		};

		$scope.openSendOrderModal = function (order) {
			var modalInstance = $uibModal.open({
				templateUrl: '../views/modal/send-order-modal.html',
				controller: 'sendOrder-modalController',
				resolve: {
					order: function () {
						return order;
					}
				}
			});

			modalInstance.result.then(function (result) {
				if (result) {
					// $scope.changeOrderFilter();
				}
			}, function () {
				//Modal is dismissed
			});
		}

		$retailer.getShop()
			.then(function (shop) {
				$scope.currentShop = shop;
				$scope.loadOrders('Paid');
				$scope.activeTab = 0;

			})
			.catch(function (reason) {
				console.error("Error while getting the shop", reason);
			});

		$scope.$on('changeSelectedShop', function () {
			$retailer.getShop()
				.then(function (shop) {
					$scope.currentShop = shop;
					$scope.loadOrders('Paid');
					$scope.activeTab = 0;

				})
				.catch(function (reason) {
					console.error("Error while getting the shop", reason);
				});
		});

		$scope.cancelOrder = function (order) {
			//TODO: Create a model where the retailer should enter an reason for the cancellation
			$scope.alert = null;
			prompt({
				title: 'Order annuleren',
				message: 'Weet u zeker dat u deze order wilt annuleren?'
			}).then(function () {
				$api.post('cancel-order', { orderNumber: order.number, shopRequest: true })
					.then(function (response) {
						$scope.alert = {
							type: 'success',
							msg: "Order cancelled"
						}
					})
					.catch(function (reason) {
						console.error("Error when cancelling the order", reason);
						$scope.alert = {
							type: 'danger',
							msg: "Error while cancelling the order: " + reason
						}
					})
			});
		}

		$scope.closeAlert = function () {
			$scope.alert = null;
		} */
  }])
