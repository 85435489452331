prismanoteApp.controller('createCompanyModalController', ['$scope', '$uibModalInstance', '$api',
  function ($scope, $uibModalInstance, $api) {
    $scope.alert = null
    $scope.company = {}
    $scope.company.type = 'company'// by default
    $scope.company.private = false
    $scope.namepattern = /^[a-zA-Z0-9.]+$/

    $scope.closeModal = function () {
      $uibModalInstance.close($scope.company)
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    $scope.getBrands = function () {
      // $api.get('brands')
      $api.get('brands?getAll=true')
        .then(function (response) {
          $scope.brands = response.data.brands
        })

        .catch(function (reason) {
          console.error('Error while getting brands', reason)
        })
    }

    $scope.searchBrands = function (str) {
      var matches = []
      $scope.brands.forEach(function (brand) {
        if ((brand.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                    (brand.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
          matches.push(brand)
        }
      })
      return matches
    }

    $scope.addBrands = function (data) {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/add-brands-modal.html',
        controller: 'addBrandsModalController',
        size: 'lg',
        resolve: {
          data: function () {
            return data
          }
        }
      })

      modalInstance.result.then(function (result) {

      }, function () {
        // dismissed
      })
    }

    $scope.brandSelected = function (selected) {
      var brand = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug,
        description: selected.originalObject.description,
        images: selected.originalObject.images,
        restricted: selected.originalObject.restricted
      }
      var existing = false

      angular.forEach($scope.company.brands, function (val) {
        if (val._id == brand._id) {
          existing = true
        }
      })
      if (existing == true) {
        return
      }
      if ($scope.company.brands === undefined) {
        $scope.company.brands = []
        $scope.company.brands.push(brand)
      } else {
        $scope.company.brands.push(brand)
      }
    }

    $scope.closeAlert = function () {
      $scope.alert = null
    }

    $scope.addCompany = function (company) {
      var alertMessage = ''
      if (company.name && company.email && company.website) {
        $scope.alert = null
        $scope.closeModal()
      } else {
        if (company.name == undefined || company.name == '') {
          alertMessage = alertMessage + ' ' + 'Please enter company name.'
        }
        if (company.email == undefined || company.email == '') {
          alertMessage = alertMessage + ' ' + 'Please enter company email.'
        }
        if (company.website == undefined || company.website == '') {
          alertMessage = alertMessage + ' ' + 'Please enter company website'
        }
        $scope.alert = {
          msg: alertMessage
        }
        // do nothing but notify client to add these required fields
      }
    }
  }])
