prismanoteApp.controller('retailerRecommendationOrderControllerNEW',
    ['$scope', '$rootScope', '$stateParams', '$recommendationOrder', 'prompt', '$state', 'cookieService', '$productConfig', '$api', '$purchaseOrder',
        function ($scope, $rootScope, $stateParams, $recommendationOrder, prompt, $state, cookieService, $productConfig, $api, $purchaseOrder) {

            // console.log('---------------------------$rootScope.recommendationCompany-------------------------------------------------------------------------', $rootScope.recommendationCompany);

            // General data storing
            $scope.localdata = {
                isCompanyProduct: true, // If true then we are showing the Company product in a view or else we will show the Brand Products
                purchaseOrderNumber: $stateParams.orderNo || '', // Based on this, we are showing in View
                hasSupplier: false, // Does this catalog already having the supplier or not, if not then add it by searching
                purchase_order: {}, // for new purchase order
                recommendationCompany: $rootScope.recommendationCompany, // When coming from the Purchase order
                isToggledClicked: false, // While we do toggle from the VIEW
                searchCurrentPage: 1
            }

            // For Company View data storing
            $scope.companydata = {
                loading: false,
                search_value: '',  // When searching on the Company Product Search,
                isSearchLoader: false, // When user is searching for the products
                isSearchingProducts: false,
                offsetForPagination: 1,
                showLoadMore: false,
                searchedProducts: {
                    isSearchResponse: false,
                    products: [],
                    count: 0
                },
                products: [] // Products which we are selected and going to add in purchase order
            }

            // For Brand View data storing
            $scope.branddata = {
                loading: false,
                search_value: '',  // When searching on the Company Product Search,
                isSearchLoader: false, // When user is searching for the products
                isSearchingProducts: false,
                offsetForPagination: 1,
                showLoadMore: false,
                searchedProducts: {
                    isSearchResponse: false,
                    products: [],
                    count: 0
                },
                products: [], // Products which we are selected and going to add in purchase order
                brandRecommendationOrder: {}
            }

            $scope.filterDataVal = false
            $rootScope.recommendationCompany = undefined; // If available then doing undefind (COMING FROM PURCHASE ORDER)

            // Company Products filter data
            try {
                // $scope.company_filter = JSON.parse(JSON.stringify($recommendationOrder.getDefaultFilterValue()));
                // $scope.company_customFilter = JSON.parse(JSON.stringify($recommendationOrder.getFiltersForCustomFilter()));
                // $scope.company_filter = JSON.parse(JSON.stringify(Object.assign($scope.company_filter, $scope.company_customFilter)));
                $scope.company_searchData = null;
                // $scope.company_filter.limit = 30
                $scope.company_pageSize = '30'
                // $scope.company_filter.offset = 0;
                $scope.company_totalProductsData = 0;
                $scope.company_totalProductsDataShowFlag = false;
                $scope.company_currentPage = 1;
            } catch (e) {
                console.log(e);
            }

            // Brand Products filter data
            try {
                // $scope.brand_filter = {};
                // $scope.brand_filter = JSON.parse(JSON.stringify($recommendationOrder.getDefaultFilterValue()));
                // $scope.brand_customFilter = JSON.parse(JSON.stringify($recommendationOrder.getFiltersForCustomFilter()));
                // $scope.brand_filter = JSON.parse(JSON.stringify(Object.assign($scope.company_filter, $scope.company_customFilter)));
                $scope.brand_searchData = null;
                // $scope.brand_filter.limit = 30
                $scope.brand_pageSize = '30';
                // $scope.brand_filter.offset = 0;
                $scope.brand_totalProductsData = 0;
                $scope.brand_totalProductsDataShowFlag = false;
                $scope.brand_currentPage = 1;
            } catch (e) {
                console.log(e);
            }

            function setTheCompanyVerified(companyDetail) {
                console.log('Is it coming here? ', $scope.localdata.isToggledClicked, companyDetail);
                if ($scope.localdata.isToggledClicked) return;
                if (companyDetail && companyDetail.processPurchaseOrder && (typeof ($rootScope.isCompanyProduct) == 'undefined' || $rootScope.isCompanyProduct)) {
                    $scope.localdata.isCompanyProduct = true;
                    console.log('$scope.localdata.isCompanyProduct 1', $scope.localdata.isCompanyProduct);
                    $rootScope.isCompanyProduct = true;
                } else {
                    $scope.localdata.isCompanyProduct = false;
                    console.log('$scope.localdata.isCompanyProduct 2', $scope.localdata.isCompanyProduct);
                    $rootScope.isCompanyProduct = false;
                }
            }

            // GET SHOP COMPANIES FOR SELECTING THE SUPPLIER WHILE CREATING NEW PURCHASE ORDER
            $scope.getShopCompanies = function () {
                // console.log('getShopCompanies: ', $scope.currentShop);
                var body = { shopId: $scope.currentShop._id };
                $recommendationOrder.getShopCompanies(body).then(function (response) {
                    $scope.shopCompanies = [];
                    if (response && response.status !== 204 && response.data && response.data.data && response.data.data.length) {
                        $scope.shopCompanies = response.data.data;
                        // console.log('$scope.shopCompanies: ', $scope.shopCompanies);
                    }
                }).catch(function (error) {
                    console.log('Error while getting companies: ', reason);
                });
            }

            // If Purchase order number is available then we are going to find the purchase order details
            $scope.getThePurchaseOrder = function (purchaseOrderNumber) {
                console.log('-------------------------------------->>>> getThePurchaseOrder: ', purchaseOrderNumber, $rootScope.currentShop);
                var body = {
                    purchaseOrderNumber: purchaseOrderNumber,
                    shopId: $rootScope.currentShop._id
                }
                $recommendationOrder.getPurchaseOrderDetail(body).then(function (response) {
                    // console.log('getThePurchaseOrder called: ', response);

                    if (response && response.data && response.data.data) {
                        if (!$scope.recommendationOrder) {
                            $scope.recommendationOrder = {
                                purchase_order: response.data.data
                            }
                            return;
                        }

                        $scope.recommendationOrder.purchase_order = response.data.data;
                    }
                }).catch(function (error) {
                    console.log('getThePurchaseOrder error: ', error);
                });
            }

            $scope.backToPurchaseOrder = function () {
                $rootScope.isBackOfRecommendation = true;
                $state.go('retailer.purchase-order', { orderNo: $scope.localdata.purchaseOrderNumber });
            }

            // Deleting the purchase order's item if products is more than one(1) else we will show the warning!
            $scope.deletePurchaseOrderProduct = function (index) {
                var purchaseOrder = $scope.recommendationOrder.purchase_order
                if (purchaseOrder.items.length === 1) {
                    prompt({
                        title: 'Warning!',
                        message: 'You have only one purchase order product. If you want to delete then go the purchase order page',
                        buttons: [{ label: 'Go to the Purchase order', primary: true }, { label: 'CANCEL', primary: false, cancel: true }]
                    }).then(function (response) {
                        // console.log('response: ', response)
                        if (response.primary) {
                            $state.go('retailer.purchase-order', { orderNo: $stateParams.orderNo })
                        }
                    }).catch(function (error) {
                        console.log('error: inprompnt: ', error)
                    })
                } else {
                    // console.log('Hey Delete Purchase Order Product')
                    var body = {
                        purchaseOrderId: purchaseOrder._id,
                        status: purchaseOrder.status[0].status,
                        itemId: purchaseOrder.items[index]._id,
                        shopId: $rootScope.currentShop._id
                    }
                    $scope.companydata.loading = true;
                    $recommendationOrder.deletePurchaseOrderProduct(body).then(function (response) {
                        $scope.companydata.loading = false;
                        // $scope.recommendationOrder.purchase_order.items.splice(index, 1);
                        $scope.getThePurchaseOrder($scope.localdata.purchaseOrderNumber)
                    }).catch(function (error) {
                        $scope.companydata.loading = false;
                        console.log('error: ', error)
                    })
                }
            }

            $scope.saveCurrentPage = function (currentPage) {
                cookieService.setCookie('servicesCurrentPage=' + currentPage, 1800)
            }

            // Opening modal to search the supplier
            $scope.editCompany = function () {
                $purchaseOrder.openSearchSupplierModal($scope.shopCompanies).then(function (selectedCompany) {
                    // console.log('===================111===========> selcetedcomany: ', selectedCompany);
                    var companyObject = selectedCompany; // because in api we may have any thing of company as input
                    // console.log('companyObject: ', companyObject);
                    var isValidCompany = true;
                    var message = '';
                    if (!companyObject.address || !companyObject.address.street || !companyObject.address.houseNumber || !companyObject.address.city ||
                        !companyObject.address.country || !companyObject.address.postalCode) {
                        isValidCompany = false;
                        message = 'Supplier address is not proper. First fill it proper then you can edit it';
                    }

                    if (!companyObject.phone || !companyObject.phone.landLine) {
                        isValidCompany = false;
                        message = 'Company does not have a phone number (fixed/landline)';
                    }

                    if (!companyObject.email) {
                        isValidCompany = false;
                        message = 'Company does not have proper email address';
                    }

                    if (isValidCompany) {
                        $scope.invalidSupplier = false;
                        $scope.selectedCompany = companyObject;
                        $scope.recommendationOrder.purchase_order.companyDetail = companyObject;
                        setTheCompanyVerified($scope.recommendationOrder.purchase_order.companyDetail);
                        $scope.localdata.purchase_order.sendDetails = {
                            address: $scope.selectedCompany.address,
                            firstName: $scope.selectedCompany.name,
                            phone: $scope.selectedCompany.phone,
                            email: $scope.selectedCompany.email
                        }

                        if ($scope.localdata.purchase_order.sendDetails && $scope.localdata.purchase_order.sendDetails.phone) {
                            if ($scope.localdata.purchase_order.sendDetails.phone.length < 8 || $scope.localdata.purchase_order.sendDetails.phone.length > 12) {
                                $scope.localdata.purchase_order.sendDetails.mobilePhone = ""
                            }
                        }

                        $scope.localdata.hasSupplier = true;

                        $scope.resetFilter();
                    } else {
                        $scope.invalidSupplierNameSlug = selectedCompany.nameSlug;
                        $scope.invalidSupplier = true;
                        $scope.invalidSupplierMessage = message;
                    }
                }).catch(function (error) {
                    console.log('edit company modal error: ', error);
                });
            }

            // It's not needed to fetch when fetching products, but we can fetch this in background
            $scope.getCollectionsFilterForRecommendation = function (companyId) {

                if (companyId || $stateParams.orderNo) {
                    var body = {
                        companyId: companyId,
                        purchaseOrderNumber: $stateParams.orderNo,
                        isBrandProduct: !$scope.localdata.isCompanyProduct
                    }
                    if ($scope.localdata.isCompanyProduct) body.shopId = $rootScope.currentShop._id;

                    // console.log('-------------------------------------------------5555555555555555555555555555555555555--------------------------getCollectionsFilterForRecommendation called: ', body);
                    $recommendationOrder.getCollectionsFilterForRecommendation(body).then(function (response) {
                        // console.info('--------------------------------8888888888888888888888888------------------: ', response.data);
                        if (response && response.data && response.data.data) {
                            var collectionFilters = response.data.data;

                            // When user do refresh at time we need to keep this filter as it is
                            var localStorageFilter = localStorage.getItem('brandrecommendationFilter');
                            var parsedLocalStorageFilter;
                            // if (localStorageFilter && localStorageFilter != 'undefined') {
                            //     console.log('localStorageFilter4: ', typeof localStorageFilter, JSON.parse(localStorageFilter));
                            //     parsedLocalStorageFilter = JSON.parse(localStorageFilter);
                            //     $scope.brand_filter = JSON.parse(JSON.stringify(parsedLocalStorageFilter));
                            // }

                            // CLIENT GROUP COLLECTIONS START
                            var clientGroupCollections = collectionFilters.clientGroupCollections;
                            if (!$scope.brand_filter) $scope.brand_filter = {};
                            $scope.brand_filter.clientGroupCollections = clientGroupCollections && clientGroupCollections.length ? clientGroupCollections : [];

                            // For If already selected collection then we need to make it cheked marked true
                            if (parsedLocalStorageFilter && parsedLocalStorageFilter.selectedclientGroupCollections && parsedLocalStorageFilter.selectedclientGroupCollections.length) {
                                var clientGroups = $scope.brand_filter.clientGroupCollections;
                                var clientGroupsLength = clientGroups.length;
                                var selectedColl = parsedLocalStorageFilter.selectedclientGroupCollections;
                                // console.log('clientGroups selectedColl called: ', selectedColl, clientGroups);
                                for (i = 0; i < selectedColl.length; i++) {
                                    for (j = 0; j < clientGroupsLength; j++) {
                                        if (selectedColl[i] && clientGroups[j] && clientGroups[j]._id &&
                                            selectedColl[i] === clientGroups[j]._id) {
                                            clientGroups[j].isSelected = true;
                                        }
                                    }
                                }

                            }
                            // CLIENT GROUP COLLECTIONS END
                        }
                    }).catch(function (error) {
                        console.log('getCollectionsFilterForRecommendation error: ', error)
                    });
                }
            }

            $scope.getTheCompanyProducts = function (undefined, isToggled) {
                try {
                    console.info('============================> getTheCompanyProducts called: ', $scope.localdata.recommendationCompany);
                    $scope.company_totalProductsData = 0;
                    // When user do refresh at the time we need to keep this filter as it is
                    var localStorageFilter = localStorage.getItem('recommendationFilter');
                    var parsedLocalStorageFilter;
                    // if (localStorageFilter && localStorageFilter != 'undefined') {
                    //     // console.log('localStorageFilter3: ', typeof localStorageFilter, localStorageFilter, localStorageFilter == 'undefined', typeof localStorageFilter == undefined);
                    //     // console.log('localStorageFilter3: ', typeof localStorageFilter, localStorageFilter, JSON.parse(localStorageFilter));
                    //     parsedLocalStorageFilter = JSON.parse(localStorageFilter);
                    //     $scope.company_filter = JSON.parse(JSON.stringify(parsedLocalStorageFilter));
                    // }
                    if (!$scope.company_filter) $scope.company_filter = { offset: 0, limit: 30 };

                    if ($scope.companydata.offsetForPagination === 1) {
                        $scope.company_filter.offset = 0; // If Refreshing the page then we taking 1st page always
                    } else {
                        $scope.company_filter.offset = $scope.companydata.offsetForPagination - 1;
                    }

                    // var companyId = $scope.localdata.recommendationCompany && $scope.localdata.recommendationCompany._id ? $scope.localdata.recommendationCompany._id : undefined;
                    var companyId = $scope.localdata.recommendationCompany && $scope.localdata.recommendationCompany._id ? $scope.localdata.recommendationCompany._id : undefined;
                    console.log('------------------------------------------------companyId 1: ', companyId, $scope.localdata.recommendationCompany, $scope.selectedCompany);
                    if ($scope.selectedCompany && $scope.selectedCompany._id) {
                        companyId = $scope.selectedCompany._id
                        console.log('------------------------------------------------companyId 2: ', companyId, $scope.selectedCompany);
                    }

                    console.log('------------------------------------------------companyId: ', companyId);

                    var body = {
                        purchaseOrderNumber: $stateParams.orderNo,
                        shopId: $rootScope.currentShop._id,
                        company_filter: JSON.parse(JSON.stringify($scope.company_filter)),
                        companyId: companyId,
                        language: $rootScope.language,
                        sortBasedOn: $scope.selectedCompany && $scope.selectedCompany.sortBasedOn ? $scope.selectedCompany.sortBasedOn : undefined
                    }
                    if (body.company_filter) {

                        // console.info('============================> getTheCompanyProducts called: ', body.company_filter);
                        if (body.company_filter.strapWidths) body.company_filter.strapWidths = body.company_filter.strapWidths.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.width });
                        if (body.company_filter.jewelTypes) body.company_filter.jewelTypes = body.company_filter.jewelTypes.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                        if (body.company_filter.strapModels) body.company_filter.strapModels = body.company_filter.strapModels.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.model });
                        if (body.company_filter.jewelMaterials && body.company_filter.jewelMaterials != undefined) {
                            body.company_filter.jewelMaterials = body.company_filter.jewelMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                        }
                        if (body.company_filter.diamondMaterials && body.company_filter.diamondMaterials != undefined) {
                            body.company_filter.diamondMaterials = body.company_filter.diamondMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                        }
                        if (body.company_filter.watchMaterials) {
                            body.company_filter.watchMaterials = body.company_filter.watchMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                        }

                        if (body.company_filter.watchCaseMaterials) {
                            body.company_filter.watchCaseMaterials = body.company_filter.watchCaseMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.material });
                        }
                        if (body.company_filter && body.company_filter.watchStrapMaterails) {
                            body.company_filter.watchStrapMaterails = body.company_filter.watchStrapMaterails.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.material });
                        }
                        if (body.company_filter.watchTypes) {
                            body.company_filter.watchTypes = body.company_filter.watchTypes.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                        }
                        if (body.company_filter.watchIndications) {
                            body.company_filter.watchIndications = body.company_filter.watchIndications.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.indication });
                        }
                        body.company_filter.suppliers = undefined;
                        // console.log('BODY FILTER: ', body.company_filter);
                        // body.filter.selectedFilters = undefined; // We don't need to send
                        $scope.companydata.loading = true;
                        body.filter = body.company_filter;
                        body.company_filter = undefined;
                    }

                    // console.log('response.data.data.productDetail::::==================================> 123: ', JSON.parse(JSON.stringify(body.filter, null, 2)));

                    $recommendationOrder.getCompanyProductsRecommendation(body).then(function (response) {
                        $scope.companydata.loading = false;
                        // console.log('==================getCompanyProductsRecommendation===11111=============>:', response);
                        if (response && response.data && response.data.data) {
                            // PRODUCTS AND PURCHASE ORDER RESPONSE
                            if (response.data.data.productDetail) {
                                $scope.recommendationOrder = { products: response.data.data.productDetail.products, purchase_order: response.data.data.purchase_order, sortingData: response.data.data.sortingOrderData };
                                // console.log('RESPONSE: retailerRecommendationOrderController: ', $scope.recommendationOrder);
                                $scope.sortingOrderValue = $scope.recommendationOrder.sortingData.sortBasedOnSortingNumber
                                if ($scope.recommendationOrder.sortingData.sortBasedOnSortingNumber == "companyProductSortingNumber") {
                                    for (var k = 0; k < $scope.recommendationOrder.products.products.length; k++) {
                                        var soldAmount = 0;
                                        soldAmount = $scope.recommendationOrder.products.products[k].products.sortingNumber ? $scope.recommendationOrder.products.products[k].products.sortingNumber - 500000000000 : 0
                                        var topMostSold = "soldAmount";
                                        if (soldAmount >= 10) {
                                            $scope.recommendationOrder.products.products[k].products[topMostSold] = "Zeer goed verkocht";
                                        } else if (soldAmount > 3 && soldAmount < 10) {
                                            $scope.recommendationOrder.products.products[k].products[topMostSold] = "Goed verkocht"
                                        } else {
                                            $scope.recommendationOrder.products.products[k].products[topMostSold] = null
                                        }
                                        if($scope.recommendationOrder && $scope.recommendationOrder.purchase_order && $scope.recommendationOrder.purchase_order.items && $scope.recommendationOrder.purchase_order.items.length > 0){
                                            $scope.recommendationOrder.purchase_order.items.filter(function (item) {
                                                if(item.product_id == $scope.recommendationOrder.products.products[k].products.productDetail._id){
                                                    $scope.recommendationOrder.products.products[k].products['quantity'] = item.quantity
                                                    $scope.recommendationOrder.products.products[k].products['selected'] = true
                                                }
                                            })
                                        }
                                    }
                                } else if ($scope.recommendationOrder.sortingData.sortBasedOnSortingNumber == "productSortingNumber") {
                                    for (var k = 0; k < $scope.recommendationOrder.products.products.length; k++) {
                                        var soldAmount = 0;
                                        soldAmount = $scope.recommendationOrder.products.products[k].products.productDetail.sortingNumber ? $scope.recommendationOrder.products.products[k].products.productDetail.sortingNumber - 500000000000 : 0
                                        var topMostSold = "soldAmount";
                                        if (soldAmount >= 10) {
                                            $scope.recommendationOrder.products.products[k].products[topMostSold] = "Zeer goed verkocht";
                                        } else if (soldAmount > 3 && soldAmount < 10) {
                                            $scope.recommendationOrder.products.products[k].products[topMostSold] = "Goed verkocht"
                                        } else {
                                            $scope.recommendationOrder.products.products[k].products[topMostSold] = null
                                        }
                                    }
                                }
                                $scope.recommendationOrder.recommendationOrderFilters = response.data.data.recommendationOrderFilters.map(function (f) {
                                    if (f.orderFilterCategoryName === '' || !f.orderFilterCategoryName) {
                                        f.orderFilterCategoryName = 'NO_GROUP'
                                    }
                                    return f
                                }); // When purchase order already not created


                                // WHEN CREATING NEW PURCHASE ORDER
                                if (!$scope.recommendationOrder.purchase_order) {
                                    $scope.recommendationOrder.purchase_order = { companyDetail: $scope.selectedCompany };
                                    console.log('-------------------------------------isToggledClicked-------------------------', $scope.localdata.isToggledClicked);
                                    setTheCompanyVerified($scope.recommendationOrder.purchase_order.companyDetail);
                                }

                                $scope.recommendationOrder.recommendationOrderFilters = response.data.data.recommendationOrderFilters; // When purchase order already not created
                                // console.log('$scope.recommendationOrder HEYY: ', $scope.recommendationOrder);

                                var purchaseOrder = $scope.recommendationOrder.purchase_order;

                                
                                if (purchaseOrder) {
                                    if (purchaseOrder.companyDetail && purchaseOrder.companyDetail._id) {
                                        // console.log('$scope.localdata.hasSupplier: ', $scope.localdata.hasSupplier);
                                        $scope.localdata.hasSupplier = true;
                                    }
                                    
                                    // When purchase order already created then we are removing the shop which we got from the PURCHASE ORDER
                                    if (purchaseOrder.number) $scope.localdata.recommendationCompany = undefined;
                                } else if (!purchaseOrder && $scope.localdata.recommendationCompany) {
                                    console.log('---------------------purchaseOrder-----1----\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\--------------: ');
                                    $scope.recommendationOrder = {
                                        purchase_order: {
                                            companyDetail: $scope.localdata.recommendationCompany,
                                        }
                                    }
                                }

                                if (purchaseOrder.companyDetail && purchaseOrder.companyDetail._id) {
                                    $scope.getCollectionsFilterForRecommendation(purchaseOrder.companyDetail._id);
                                }
                                var totalProducts = $scope.recommendationOrder.products.metadata[0].total;
                                if ($scope.company_filter.limit < totalProducts && (($scope.company_filter.offset * $scope.company_filter.limit) + $scope.company_filter.limit) < totalProducts) {
                                    $scope.companydata.showLoadMore = true;
                                } else {
                                    $scope.companydata.showLoadMore = false;
                                }
                                cookieService.getCookie('servicesCurrentPage')
                                    .then(function (currentPage) {
                                        $scope.company_currentPage = currentPage
                                    })
                                    .catch(function () {
                                        $scope.company_currentPage = 1
                                    })
                                window.scrollTo(0, 0);
                            }

                            // SHOW SUPPLIER FILTER
                            if ($scope.recommendationOrder && $scope.recommendationOrder.purchase_order &&
                                $scope.recommendationOrder.purchase_order.companyDetail && $scope.recommendationOrder.purchase_order.companyDetail._id) {
                                var companyDetail = $scope.recommendationOrder.purchase_order.companyDetail;
                                setTheCompanyVerified(companyDetail, isToggled);
                                $scope.company_filter.suppliers = [{
                                    _id: companyDetail._id,
                                    name: companyDetail.name,
                                    nameSlug: companyDetail.nameSlug,
                                    isSelected: true,
                                }]
                            }

                            // if (response.data.data.fitlerDetail) {
                            //     // BRANDS
                            //     var brands = response.data.data.fitlerDetail.brands;
                            //     if (!$scope.company_filter.brands.length) $scope.company_filter.brands = brands && brands.length ? brands : [];
                            // }

                            // IF PURCHASE ORDER CREATED AND SEARCHING AT THAT TIME, DUE TO THAT SEACH FOCUS LOST
                            if ($rootScope.recommendationOrderSearchValue) {
                                $scope.companydata.search_value = $rootScope.recommendationOrderSearchValue;
                                $rootScope.recommendationOrderSearchValue = undefined;
                                $scope.searchProductsForCompanyRecommnedation();
                            }
                        }
                        if ($scope.searchData != null) {
                            $scope.companydata.search_value = $scope.searchData;
                            $scope.searchProductsForCompanyRecommnedation('filter');
                        } else {
                            $scope.searchData = null
                        }
                    }).catch(function (error) {
                        $scope.companydata.loading = false;
                        console.log('error: ', error)
                    });

                } catch (e) {
                    console.log('----------> ', e);
                }
            }

            // code for autocomplete
            $scope.companySelected = function (selected, isSearchRequire) {
                // console.log('--company selcetd: 123 ===================111=======================> ', selected);
                var companyObject = selected.originalObject; // because in api we may have any thing of company as input
                var isValidCompany = true;
                var message = '';
                if (!companyObject.address || !companyObject.address.street || !companyObject.address.houseNumber || !companyObject.address.city ||
                    !companyObject.address.country || !companyObject.address.postalCode) {
                    isValidCompany = false;
                    message = 'Company address is not proper. First fill it proper then you can edit it';
                }

                if (!companyObject.phone || !companyObject.phone.landLine) {
                    isValidCompany = false;
                    message = 'Company does not have a phone number (fixed/landline)';
                }

                if (!companyObject.email) {
                    isValidCompany = false;
                    message = 'Company does not have proper email address';
                }

                if (isValidCompany) {
                    $scope.localdata.recommendationCompany = companyObject; // BECAUSE USER NOT COMING FROM THE PURCHASE ORDER BUT COMING FROM THE DASHBOARD
                    $scope.invalidSupplier = false;
                    $scope.selectedCompany = companyObject;
                    console.log('---------------------purchaseOrder-----2----\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\--------------: ');
                    $scope.recommendationOrder = {
                        purchase_order: {
                            companyDetail: companyObject,
                            // shop: {
                            //   _id: $rootScope.currentShop._id,
                            //   name: $rootScope.currentShop.name,
                            //   nameSlug: $rootScope.currentShop.nameSlug,
                            // }
                        }
                    }
                    $scope.localdata.purchase_order.sendDetails = {
                        address: $scope.selectedCompany.address,
                        firstName: $scope.selectedCompany.name,
                        phone: $scope.selectedCompany.phone,
                        email: $scope.selectedCompany.email
                    }

                    if ($scope.localdata.purchase_order.sendDetails && $scope.localdata.purchase_order.sendDetails.phone) {
                        if ($scope.localdata.purchase_order.sendDetails.phone.length < 8 || $scope.localdata.purchase_order.sendDetails.phone.length > 12) {
                            $scope.localdata.purchase_order.sendDetails.mobilePhone = ""
                        }
                    }

                    $scope.localdata.hasSupplier = true;
                    if (isSearchRequire) {
                        $scope.getCompanyBRANDProductsRecommendation(true);
                        $scope.getDynamicRecommendationFilter(true);

                        $scope.getTheCompanyProducts(true);
                        $scope.getDynamicRecommendationFilter(false);
                    }
                    // if ($scope.localdata.purchase_order && $scope.localdata.purchase_order.shop && $scope.localdata.purchase_order.shop._id && companyObject && companyObject._id) {
                    //   $scope.getShopClientGroups($scope.localdata.purchase_order.shop._id, companyObject._id); // Getting clien groups of particular shop and company
                    // }
                } else if (companyObject && companyObject.nameSlug) {
                    $scope.invalidSupplierNameSlug = companyObject.nameSlug;
                    $scope.invalidSupplier = true;
                    $scope.invalidSupplierMessage = message;
                } else {
                    $scope.invalidSupplier = true;
                    $scope.invalidSupplierMessage = message;
                }
            };

            $scope.companySearchedDone = function (selected) {
                $scope.companySelected(selected, true);
            }

            if ($scope.localdata.recommendationCompany && $scope.localdata.recommendationCompany._id) {
                $scope.getShopCompanies();
                $scope.companySelected({ originalObject: $scope.localdata.recommendationCompany });
            }

            // WHEN COMING FROM THE INVITATION LINK
            $scope.fetchCompanyDetail = function () {
                console.info('=====================111====================> fetchCompanyDetail: respnse: 1');
                if ($rootScope.recommendationCompany && recommendationCompany._id) {
                    $scope.getShopCompanies();
                    var companyBody = { _id: $rootScope.recommendationCompany._id, shopId: $rootScope.currentShop._id }
                    $recommendationOrder.fetchCompanyDetail(companyBody).then(function (response) {
                        $rootScope.recommendationCompany = undefined;
                        console.info('======================111========================> fetchCompanyDetail: respnse: ', response);
                        if (response && response.data && response.data.data) {
                            $scope.localdata.hasSupplier = true;
                            $scope.selectedCompany = response.data.data;
                            $scope.companySelected({ originalObject: $scope.selectedCompany });
                        }
                    }).catch(function (error) {
                        console.log('Error while getting companies: ', error);
                        $state.go('retailer.home');
                        $rootScope.recommendationCompany = undefined;
                    });
                }
            }

            $scope.searchProductsForCompanyRecommnedation = function (data) {
                try {
                    // console.log('searchProductsForCompanyRecommnedation called: ');
                    $scope.companydata.isSearchingProducts = true;
                    $scope.filterDataVal = false;
                    // document.getElementById('search_field_purchaseOrder').focus();
                    $rootScope.recommendationOrderSearchValue = $scope.companydata.search_value;
                    if ($scope.recommendationOrder && $scope.recommendationOrder.purchase_order) {
                        if ((!$scope.recommendationOrder.purchase_order.company || !$scope.recommendationOrder.purchase_order.company._id) &&
                            ($scope.recommendationOrder.purchase_order.companyDetail && $scope.recommendationOrder.purchase_order.companyDetail._id)) {
                            $scope.recommendationOrder.purchase_order.company = $scope.recommendationOrder.purchase_order.companyDetail;
                            setTheCompanyVerified($scope.recommendationOrder.purchase_order.companyDetail);
                        }
                        // console.log('$scope.purchase_order.company: ', $scope.recommendationOrder.purchase_order);
                        if ($scope.recommendationOrder.purchase_order.company && $scope.recommendationOrder.purchase_order.company._id) {
                            if ($scope.companydata.search_value) {
                                $scope.companydata.search_value = $productConfig.retrunValidProductNumber($scope.companydata.search_value)
                                // value = $scope.companydata.search_value;
                                // console.log($scope.companydata.search_value)
                                if ($scope.companydata.search_value.length < 3) return;

                                if (!$scope.companydata.searchedProducts.isSearchResponse) {
                                    $scope.company_filter.limit = 30;
                                    $scope.company_filter.offset = 0;
                                    $scope.companydata.offsetForPagination = 1;
                                    $scope.company_currentPage = 1;
                                }
                                var data = {
                                    searchText: $scope.companydata.search_value,
                                    companyId: $scope.recommendationOrder.purchase_order.company._id,
                                    shopId: $scope.currentShop._id,
                                    limit: $scope.company_filter.limit,
                                    offset: $scope.company_filter.offset,
                                    language: $rootScope.language,
                                    sortBasedOn: $scope.recommendationOrder.purchase_order.company.sortBasedOn,
                                    dataLimit: data == 'filter' ? 'filter' : null,
                                }

                                // $scope.companydata.loading = true;
                                $scope.companydata.isSearchLoader = true;
                                // document.getElementById('search_field_purchaseOrder').focus();
                                $recommendationOrder.searchProductsForCompanyRecommnedation(data).then(function (response) {
                                    $scope.companydata.isSearchLoader = false;
                                    $scope.companydata.searchedProducts.isSearchResponse = true;
                                    console.info('----------------->>  searchProductsForCompanyRecommnedation called: ', response.data.data);
                                    if (response && response.data && response.data.data && response.data.data.products && response.data.data.products.length) {
                                        var resData = response.data.data
                                        var filterValues = localStorage.getItem('recommendationFilter');
                                        var filData = JSON.parse(filterValues)
                                        var categaoryFilter = false;

                                        if (data.dataLimit == 'filter') {
                                            if (filData && filData.category != null) {
                                                categaoryFilter = true
                                                if (filData.category && (filData.category.watch == true || filData.category.jewel == true || filData.category.strap == true || filData.category.others == true)) {
                                                    $scope.filterDataVal = true
                                                }

                                                if ($scope.filterDataVal == true) {
                                                    var filtered_data = []

                                                    if (filData.category.watch == true) {
                                                        filtered_data = resData.products.filter(function (data) {
                                                            return ((data.products.productDetail.category == 'WATCH'));
                                                        });
                                                    }
                                                    if (filData.category.jewel == true) {
                                                        if (filtered_data.length == 0) {
                                                            filtered_data = resData.products.filter(function (data) {
                                                                return ((data.products.productDetail.category == 'JEWEL'));
                                                            });
                                                        }

                                                    }
                                                    if (filData.category.strap == true) {
                                                        if (filtered_data.length == 0) {
                                                            filtered_data = resData.products.filter(function (data) {
                                                                return ((data.products.productDetail.category == 'STRAP'));
                                                            });
                                                        }

                                                    }
                                                    if (filData.category.others == true) {
                                                        if (filtered_data.length == 0) {
                                                            filtered_data = resData.products.filter(function (data) {
                                                                return ((data.products.productDetail.category == 'OTHERS'));
                                                            });
                                                        }
                                                    }
                                                    $scope.recommendationOrder.products = filtered_data
                                                }
                                            }
                                        }

                                        if ($scope.recommendationOrder && $scope.recommendationOrder.purchase_order && $scope.recommendationOrder.purchase_order.items && $scope.recommendationOrder.purchase_order.items.length > 0) {
                                            //if ($scope.recommendationOrder.products && $scope.recommendationOrder.products.length) {
                                                $scope.recommendationOrder.products.map(function (product) {
                                                    $scope.recommendationOrder.purchase_order.items.filter(function (item) {
                                                        if (item.product_id == product.products.productDetail._id) {
                                                            product.products['quantity'] = item.quantity
                                                            product.products['selected'] = true
                                                        }
                                                    })
                                                    return product
                                                })
                                            //}
                                        }
                                        $scope.totalProductsDataShowFlag = false;
                                        if ($scope.filterDataVal == false && categaoryFilter == false) {

                                            $scope.recommendationOrder.products = resData
                                            var totalProducts = $scope.recommendationOrder.products.metadata[0].total
                                            $scope.company_filter.limit = 30
                                        } else if (categaoryFilter == true && $scope.filterDataVal == true) {
                                            $scope.recommendationOrder.products.products = $scope.recommendationOrder.products
                                            $scope.totalProductsDataShowFlag = true
                                            $scope.company_totalProductsData = $scope.recommendationOrder.products.length
                                            //  $scope.company_filter.limit = $scope.recommendationOrder.products.length
                                        }

                                        /* $scope.recommendationOrder.products = resData
                                        var totalProducts = $scope.recommendationOrder.products.metadata[0].total */
                                        if ($scope.sortingOrderValue == "companyProductSortingNumber") {
                                            for (var k = 0; k < $scope.recommendationOrder.products.products.length; k++) {
                                                var soldAmount = 0;
                                                soldAmount = $scope.recommendationOrder.products.products[k].products.sortingNumber ? $scope.recommendationOrder.products.products[k].products.sortingNumber - 500000000000 : 0
                                                var topMostSold = "soldAmount";
                                                if (soldAmount >= 10) {
                                                    $scope.recommendationOrder.products.products[k].products[topMostSold] = "Zeer goed verkocht";
                                                } else if (soldAmount > 3 && soldAmount < 10) {
                                                    $scope.recommendationOrder.products.products[k].products[topMostSold] = "Goed verkocht"
                                                } else {
                                                    $scope.recommendationOrder.products.products[k].products[topMostSold] = null
                                                }
                                            }
                                        } else if ($scope.sortingOrderValue == "productSortingNumber") {
                                            for (var k = 0; k < $scope.recommendationOrder.products.products.length; k++) {
                                                var soldAmount = 0;
                                                soldAmount = $scope.recommendationOrder.products.products[k].products.productDetail.sortingNumber ? $scope.recommendationOrder.products.products[k].products.productDetail.sortingNumber - 500000000000 : 0
                                                var topMostSold = "soldAmount";
                                                if (soldAmount >= 10) {
                                                    $scope.recommendationOrder.products.products[k].products[topMostSold] = "Zeer goed verkocht";
                                                } else if (soldAmount > 3 && soldAmount < 10) {
                                                    $scope.recommendationOrder.products.products[k].products[topMostSold] = "Goed verkocht"
                                                } else {
                                                    $scope.recommendationOrder.products.products[k].products[topMostSold] = null
                                                }
                                            }
                                        }

                                        if ($scope.company_filter.limit < totalProducts && (($scope.company_filter.offset * $scope.company_filter.limit) + $scope.company_filter.limit) < totalProducts) {
                                            $scope.companydata.showLoadMore = true
                                        } else {
                                            $scope.companydata.showLoadMore = false
                                        }
                                        window.scrollTo(0, 0)
                                    } else {
                                        $scope.companydata.showLoadMore = false;
                                        $scope.recommendationOrder.products = {
                                            products: [],
                                            metadata: [{
                                                total: 0
                                            }]
                                        };
                                    }
                                }).catch(function (error) {
                                    $scope.companydata.isSearchLoader = false;
                                    console.log('error: ', error);
                                })
                            } else {
                                $scope.getTheCompanyProducts();
                                console.log('No search 3');
                            }
                        } else {
                            console.log('No search  2');
                        }
                    } else {
                        console.log('No search 1');
                    }
                } catch (e) {
                    console.log('Search Product: ', e);
                }
            }

            $scope.addTheProduct = function (item) {
                console.log('-------------------------------------------------------------- addTheProduct!!');

                console.log('$scope.localdata.purchaseOrderNumber', $scope.localdata.purchaseOrderNumber, item);
                if ($scope.localdata.purchaseOrderNumber) {
                    addProductToExistingPurchaseOrder(item);
                } else {
                    createNewPurchaseOrder(item);
                }
            }

            $scope.addTheProductFromModal = function (item) {
                console.log('addTheProductFromModal called: ', item);
                item.products = {
                    productDetail: {
                        _id: item._id
                    }
                }
                if ($scope.localdata.purchaseOrderNumber) {
                    addProductToExistingPurchaseOrder(item);
                } else {
                    createNewPurchaseOrder(item);
                }
            }

            function addProductToExistingPurchaseOrder(item) {
                item.products.selected = true;
                item.products.quantity = 1;

                var isAlreadyAdded = false;
                for (var i = 0; i < $scope.companydata.products.length; i++) {
                    if (item && item.products && item.products._id && $scope.localdata && $scope.companydata.products && $scope.companydata.products[i] &&
                        $scope.companydata.products[i]._id && (item.products._id).toString() === ($scope.companydata.products[i]._id).toString()) {
                        isAlreadyAdded = true;
                        break;
                    }
                }

                if (isAlreadyAdded) return;

                $scope.companydata.products.push(item.products);

                var body = {
                    shopId: $rootScope.currentShop._id,
                    purchaseOrder: {
                        _id: $scope.recommendationOrder.purchase_order._id
                    },
                    shop: {
                        _id: $scope.recommendationOrder.purchase_order.shop._id
                    },
                    company: {
                        _id: $scope.recommendationOrder.purchase_order.company._id
                    },
                    isFrom: 'retailer',
                    products: [{
                        _id: item.products.productDetail._id,
                        quantity: item.products.quantity
                    }]
                }

                $scope.companydata.loading = true;
                $recommendationOrder.addProductToPurchaseOrderFromRecommendation(body).then(function (response) {
                    $scope.companydata.loading = false;
                    if (response && response.data && response.data.data) {
                        // console.log('addProductToPurchaseOrderFromRecommendation created: ', response.data.data)
                        $scope.recommendationOrder.purchase_order = response.data.data;
                        setTheCompanyVerified($scope.recommendationOrder.purchase_order.companyDetail);
                    }
                }).catch(function (error) {
                    $scope.companydata.loading = false;
                    console.log('error: ', error)
                });
            }

            function createNewPurchaseOrder(item) {
                // console.log('------------------- createNewPurchaseOrder!!');
                var purchaseOrder = {
                    shop: {
                        _id: $rootScope.currentShop._id,
                        name: $rootScope.currentShop.name,
                        nameSlug: $rootScope.currentShop.nameSlug,
                    },
                    company: {
                        _id: $scope.selectedCompany._id,
                        name: $scope.selectedCompany.name,
                        nameSlug: $scope.selectedCompany.nameSlug,
                    },
                    billingDetails: {
                        address: [$rootScope.currentShop.address],
                        email: $rootScope.currentShop.email,
                        phone: [$rootScope.currentShop.phone]
                    },
                    sendDetails: {
                        address: [$scope.selectedCompany.address],
                        email: $scope.selectedCompany.email
                    },
                    isFrom: 'retailer',
                    products: [{
                        _id: item.products.productDetail._id,
                        quantity: 1
                    }]
                }
                // console.log('createNewPurchaseOrder called: ', purchaseOrder);
                $recommendationOrder.createNewPurchaseOrderUsingRecommendation(purchaseOrder).then(function (response) {
                    // console.log('response: ', response);
                    if (response && response.data && response.data.data) {
                        $state.go('retailer.recommendation-order', { orderNo: response.data.data });
                    }
                }).catch(function (error) {
                    console.log('error: ', error);
                });
            }

            function emptyTheSelectedProduct() {
                $scope.companydata.products = []
                var products = $scope.recommendationOrder.products.products
                products.map(function (el) {
                    if (el && el.products && el.products.selected) {
                        el.products.selected = false
                    }
                })
            }

            $scope.changePage = function () {
                console.log('---------------------changePage------------------------', $scope.companydata);
                emptyTheSelectedProduct();
                $scope.company_filter.offset = $scope.companydata.offsetForPagination - 1; // Pagiantion count from the 0 (CURRENT PAGE) but In query we are doing from 1(CURRENT PAGE)
                if ($scope.companydata && $scope.companydata.searchedProducts && $scope.companydata.searchedProducts.isSearchResponse) {
                    $scope.searchProductsForCompanyRecommnedation();
                } else {
                    console.log('Hello in else');
                    $scope.company_filter.clientGroupCollections = [];
                    $scope.company_filter.privateLabelCollections = [];
                    localStorage.setItem('recommendationFilter', JSON.stringify($scope.company_filter));
                    $scope.getTheCompanyProducts();
                }
            }

            $scope.removeSearchResults = function () {
                if (!$scope.companydata.isSearchingProducts) return;
                $scope.companydata.isSearchingProducts = false;
                $scope.companydata.searchedProducts.isSearchResponse = false;
                $rootScope.recommendationOrderSearchValue = undefined; // To prevent focus losing when purchase order created
                $scope.companydata.search_value = '';
                $scope.company_filter.limit = 30;
                $scope.company_filter.offset = 0;
                $scope.company_currentPage = 1;
                $scope.companydata.offsetForPagination = 1;
                $scope.searchData = undefined;
                $scope.getTheCompanyProducts();
            }

            // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // ///////////////////////////////////////////////////////////////////////// BRAND PRODUCTS ///////////////////////////////////////////////////////////////////////////////

            function getTheSelectedFilter(filter) {
                if (filter) {
                    // console.log('getTheSelectedFilter before:', JSON.parse(JSON.stringify(filter)));
                    if (filter.category && filter.category.length) filter.category = filter.category.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.category });
                    if (filter.category && !filter.category.length) filter.category = undefined;

                    if (filter.gender && filter.gender.length) filter.gender = filter.gender.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.gender });
                    if (filter.gender && !filter.gender.length) filter.gender = undefined;

                    if (filter.strapWidths && filter.strapWidths.length) filter.strapWidths = filter.strapWidths.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.width });
                    if (filter.strapWidths && !filter.strapWidths.length) filter.strapWidths = undefined;

                    if (filter.jewelTypes && filter.jewelTypes.length) filter.jewelTypes = filter.jewelTypes.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                    if (filter.jewelTypes && !filter.jewelTypes.length) filter.jewelTypes = undefined;

                    if (filter.strapModels && filter.strapModels.length) filter.strapModels = filter.strapModels.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.model });
                    if (filter.strapModels && !filter.strapModels.length) filter.strapModels = undefined;

                    if (filter.brands && filter.brands.length) filter.brands = filter.brands.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el._id });
                    if (filter.brands && !filter.brands.length) filter.brands = undefined;


                    if (filter && filter.clientGroupCollections && !filter.clientGroupCollections.length) filter.clientGroupCollections = undefined;
                    if (filter && filter.privateLabelCollections && !filter.privateLabelCollections.length) filter.privateLabelCollections = undefined;

                    // if (filter.jewelMaterials != undefined) {
                    //     filter.jewelMaterials = filter.jewelMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                    // }
                    // if (filter.diamondMaterials != undefined) {
                    //     filter.diamondMaterials = filter.diamondMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                    // }
                    // if (filter.watchMaterials) {
                    //     filter.watchMaterials = filter.watchMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                    // }

                    // if (filter.watchCaseMaterials) {
                    //     filter.watchCaseMaterials = filter.watchCaseMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.material });
                    // }
                    // if (filter && filter.watchStrapMaterails) {
                    //     filter.watchStrapMaterails = filter.watchStrapMaterails.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.material });
                    // }
                    // if (filter.watchTypes) {
                    //     filter.watchTypes = filter.watchTypes.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                    // }
                    // if (filter.watchIndications) {
                    //     filter.watchIndications = filter.watchIndications.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.indication });
                    // }
                    filter.suppliers = undefined;
                }
                // console.log('getTheSelectedFilter after:', filter);
                return filter;
            }

            $scope.getDynamicRecommendationFilter = function (isBrandProduct, filter) {
                // console.log('======getDynamicRecommendationFilter===================================HELLO 12333=========================>:', filter);
                var companyId = $scope.localdata.recommendationCompany && $scope.localdata.recommendationCompany._id ? $scope.localdata.recommendationCompany._id : undefined;
                if ($scope.selectedCompany && $scope.selectedCompany._id) companyId = $scope.selectedCompany._id;
                var body = {
                    companyId: companyId,
                    shopId: $rootScope.currentShop._id,
                    purchaseOrderNumber: $stateParams.orderNo,
                    isBrandProduct: isBrandProduct,
                    filter: filter
                }
                $scope.branddata.filterloading = true;
                $recommendationOrder.getDynamicRecommendationFilter(body).then(function (response) {
                    $scope.branddata.filterloading = false;
                    // console.log('======getDynamicRecommendationFilter===4444556============================================================================>:', response.data);
                    response.data.isDynamicFilter = true;
                    response.data.isBrandProduct = isBrandProduct;
                    if (isBrandProduct) {
                        $scope.brand_filter = response.data;
                        $scope.brand_filter.offset = 0;
                        $scope.brand_filter.limit = 30;
                    } else {
                        // console.log('getDynamicRecommendationFilter in ELSE PART');
                        $scope.company_filter = response.data;
                        $scope.company_filter.offset = 0;
                        $scope.company_filter.limit = 30;
                    }
                    // $scope.branddata.brandRecommendationOrder.filters = response.data;
                    // $scope.branddata.loading = false;
                    // response.data.filters.isBrandProduct = true;
                    // $scope.branddata.brandRecommendationOrder = { products: response.data.brandProducts, totalProducts: response.data.totalProducts, filters: response.data.filters }
                }).catch(function (error) {
                    $scope.branddata.filterloading = false;
                    console.log('getDynamicRecommendationFilter called error: ', error);
                });
            }

            $scope.getCompanyBRANDProductsRecommendation = function (isInitial) {
                // getCompanyBRANDProductsRecommendation
                try {
                    // console.info('============================> getCompanyBRANDProductsRecommendation called: ', $scope.brand_filter);
                    $scope.brand_totalProductsData = 0;
                    // When user do refresh at the time we need to keep this filter as it is
                    var localStorageFilter = localStorage.getItem('brandrecommendationFilter');
                    var parsedLocalStorageFilter;
                    // if (localStorageFilter && localStorageFilter != 'undefined') {
                    //     console.log('localStorageFilter3: ', typeof localStorageFilter, JSON.parse(localStorageFilter));
                    //     parsedLocalStorageFilter = JSON.parse(localStorageFilter);
                    //     $scope.brand_filter = JSON.parse(JSON.stringify(parsedLocalStorageFilter));
                    // }
                    if (!$scope.brand_filter) $scope.brand_filter = { offset: 0, limit: 30 };

                    if ($scope.branddata.offsetForPagination === 1) $scope.brand_filter.offset = 0; // If Refreshing the page then we taking 1st page always
                    else $scope.brand_filter.offset = $scope.branddata.offsetForPagination - 1;
                    var companyId = $scope.localdata.recommendationCompany && $scope.localdata.recommendationCompany._id ? $scope.localdata.recommendationCompany._id : undefined;
                    if ($scope.selectedCompany && $scope.selectedCompany._id) {
                        companyId = $scope.selectedCompany._id
                    }

                    var body = {
                        purchaseOrderNumber: $stateParams.orderNo,
                        shopId: $rootScope.currentShop._id,
                        brand_filter: JSON.parse(JSON.stringify($scope.brand_filter)),
                        companyId: companyId,
                        language: $rootScope.language,
                        sortBasedOn: $scope.selectedCompany && $scope.selectedCompany.sortBasedOn ? $scope.selectedCompany.sortBasedOn : undefined
                    }
                    // if (isInitial) { // means calling first time
                    //     $scope.getCollectionsFilterForRecommendation(companyId);
                    // }
                    // $scope.getDynamicRecommendationFilter(true, getTheSelectedFilter(JSON.parse(JSON.stringify(body.brand_filter))));
                    if (body.brand_filter) {
                        if (body.brand_filter.strapWidths) body.brand_filter.strapWidths = body.brand_filter.strapWidths.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.width });
                        if (body.brand_filter.jewelTypes) body.brand_filter.jewelTypes = body.brand_filter.jewelTypes.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                        if (body.brand_filter.strapModels) body.brand_filter.strapModels = body.brand_filter.strapModels.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.model });

                        if (body.brand_filter.jewelMaterials != undefined) {
                            body.brand_filter.jewelMaterials = body.brand_filter.jewelMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                        }
                        if (body.brand_filter.diamondMaterials != undefined) {
                            body.brand_filter.diamondMaterials = body.brand_filter.diamondMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                        }
                        if (body.brand_filter.watchMaterials) {
                            body.brand_filter.watchMaterials = body.brand_filter.watchMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                        }

                        if (body.brand_filter.watchCaseMaterials) {
                            body.brand_filter.watchCaseMaterials = body.brand_filter.watchCaseMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.material });
                        }
                        if (body.brand_filter && body.brand_filter.watchStrapMaterails) {
                            body.brand_filter.watchStrapMaterails = body.brand_filter.watchStrapMaterails.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.material });
                        }
                        if (body.brand_filter.watchTypes) {
                            body.brand_filter.watchTypes = body.brand_filter.watchTypes.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
                        }
                        if (body.brand_filter.watchIndications) {
                            body.brand_filter.watchIndications = body.brand_filter.watchIndications.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.indication });
                        }
                        body.brand_filter.suppliers = undefined;
                        // body.filter.selectedFilters = undefined; // We don't need to send
                        body.filter = body.brand_filter;
                        body.brand_filter = undefined;
                    }

                    console.log('response.data.data.productDetail::::==================================> 123: ', body);

                    $scope.branddata.loading = true;
                    $recommendationOrder.getCompanyBRANDProductsRecommendation(body).then(function (response) {
                        $scope.branddata.loading = false;
                        // response.data.filters.isBrandProduct = true;
                        $scope.branddata.brandRecommendationOrder.products = response.data.brandProducts;
                        $scope.branddata.brandRecommendationOrder.totalProducts = response.data.totalProducts;

                        if($scope.recommendationOrder && $scope.recommendationOrder.purchase_order && $scope.recommendationOrder.purchase_order.items && $scope.recommendationOrder.purchase_order.items.length > 0){
                            $scope.branddata.brandRecommendationOrder.products.map(function (product) {
                                $scope.recommendationOrder.purchase_order.items.filter(function (item) {
                                    if(item.product_id == product.products.productDetail._id){
                                        product.products['quantity'] = item.quantity
                                        product.products['selected'] = true
                                    }
                                })
                                return product 
                            })
                        }
                        // console.log('======getCompanyBRANDProductsRecommendation===4444556=========================>:');
                        // if (response && response.data && response.data.data) {
                        //     // PRODUCTS AND PURCHASE ORDER RESPONSE
                        //     if (response.data.data.productDetail) {
                        //         $scope.recommendationOrder = { products: response.data.data.productDetail.products, purchase_order: response.data.data.purchase_order, sortingData: response.data.data.sortingOrderData };
                        //         // console.log('RESPONSE: retailerRecommendationOrderController: ', $scope.recommendationOrder);
                        //         $scope.sortingOrderValue = $scope.recommendationOrder.sortingData.sortBasedOnSortingNumber
                        //         if ($scope.recommendationOrder.sortingData.sortBasedOnSortingNumber == "companyProductSortingNumber") {
                        //             for (var k = 0; k < $scope.recommendationOrder.products.products.length; k++) {
                        //                 var soldAmount = 0;
                        //                 soldAmount = $scope.recommendationOrder.products.products[k].products.sortingNumber ? $scope.recommendationOrder.products.products[k].products.sortingNumber - 500000000000 : 0
                        //                 var topMostSold = "soldAmount";
                        //                 if (soldAmount >= 10) {
                        //                     $scope.recommendationOrder.products.products[k].products[topMostSold] = "Zeer goed verkocht";
                        //                 } else if (soldAmount > 3 && soldAmount < 10) {
                        //                     $scope.recommendationOrder.products.products[k].products[topMostSold] = "Goed verkocht"
                        //                 } else {
                        //                     $scope.recommendationOrder.products.products[k].products[topMostSold] = null
                        //                 }
                        //             }
                        //         } else if ($scope.recommendationOrder.sortingData.sortBasedOnSortingNumber == "productSortingNumber") {
                        //             for (var k = 0; k < $scope.recommendationOrder.products.products.length; k++) {
                        //                 var soldAmount = 0;
                        //                 soldAmount = $scope.recommendationOrder.products.products[k].products.productDetail.sortingNumber ? $scope.recommendationOrder.products.products[k].products.productDetail.sortingNumber - 500000000000 : 0
                        //                 var topMostSold = "soldAmount";
                        //                 if (soldAmount >= 10) {
                        //                     $scope.recommendationOrder.products.products[k].products[topMostSold] = "Zeer goed verkocht";
                        //                 } else if (soldAmount > 3 && soldAmount < 10) {
                        //                     $scope.recommendationOrder.products.products[k].products[topMostSold] = "Goed verkocht"
                        //                 } else {
                        //                     $scope.recommendationOrder.products.products[k].products[topMostSold] = null
                        //                 }
                        //             }
                        //         }
                        //         $scope.recommendationOrder.recommendationOrderFilters = response.data.data.recommendationOrderFilters.map(function (f) {
                        //             if (f.orderFilterCategoryName === '' || !f.orderFilterCategoryName) {
                        //                 f.orderFilterCategoryName = 'NO_GROUP'
                        //             }
                        //             return f
                        //         }); // When purchase order already not created

                        //         // WHEN CREATING NEW PURCHASE ORDER
                        //         if (!$scope.recommendationOrder.purchase_order) {
                        //             $scope.recommendationOrder.purchase_order = { companyDetail: $scope.selectedCompany };
                        //         }

                        //         $scope.recommendationOrder.recommendationOrderFilters = response.data.data.recommendationOrderFilters; // When purchase order already not created
                        //         // console.log('$scope.recommendationOrder HEYY: ', $scope.recommendationOrder);

                        //         var purchaseOrder = $scope.recommendationOrder.purchase_order;
                        //         if (purchaseOrder) {
                        //             if (purchaseOrder.companyDetail && purchaseOrder.companyDetail._id) {
                        //                 console.log('$scope.localdata.hasSupplier: ', $scope.localdata.hasSupplier);
                        //                 $scope.localdata.hasSupplier = true;
                        //             }

                        //             // When purchase order already created then we are removing the shop which we got from the PURCHASE ORDER
                        //             if (purchaseOrder.number) $scope.localdata.recommendationCompany = undefined;
                        //         } else if (!purchaseOrder && $scope.localdata.recommendationCompany) {
                        //             $scope.recommendationOrder = {
                        //                 purchase_order: {
                        //                     companyDetail: $scope.localdata.recommendationCompany,
                        //                 }
                        //             }
                        //         }

                        //         if (purchaseOrder.companyDetail && purchaseOrder.companyDetail._id) {
                        //             $scope.getCollectionsFilterForRecommendation(purchaseOrder.companyDetail._id);
                        //         }
                        //         var totalProducts = $scope.recommendationOrder.products.metadata[0].total;
                        //         if ($scope.brand_filter.limit < totalProducts && (($scope.brand_filter.offset * $scope.brand_filter.limit) + $scope.brand_filter.limit) < totalProducts) {
                        //             $scope.branddata.showLoadMore = true;
                        //         } else {
                        //             $scope.branddata.showLoadMore = false;
                        //         }
                        //         cookieService.getCookie('servicesCurrentPage')
                        //             .then(function (currentPage) {
                        //                 $scope.company_currentPage = currentPage
                        //             })
                        //             .catch(function () {
                        //                 $scope.company_currentPage = 1
                        //             })
                        //         window.scrollTo(0, 0);
                        //     }

                        //     // SHOW SUPPLIER FILTER
                        //     if ($scope.recommendationOrder && $scope.recommendationOrder.purchase_order &&
                        //         $scope.recommendationOrder.purchase_order.companyDetail && $scope.recommendationOrder.purchase_order.companyDetail._id) {
                        //         var companyDetail = $scope.recommendationOrder.purchase_order.companyDetail;
                        //         console.log('companyDetail: ', companyDetail);
                        //         $scope.brand_filter.suppliers = [{
                        //             _id: companyDetail._id,
                        //             name: companyDetail.name,
                        //             nameSlug: companyDetail.nameSlug,
                        //             isSelected: true,
                        //         }]
                        //     }

                        //     if (response.data.data.fitlerDetail) {
                        //         // BRANDS
                        //         var brands = response.data.data.fitlerDetail.brands;
                        //         if (!$scope.brand_filter.brands.length) $scope.brand_filter.brands = brands && brands.length ? brands : [];
                        //     }

                        //     // IF PURCHASE ORDER CREATED AND SEARCHING AT THAT TIME, DUE TO THAT SEACH FOCUS LOST
                        //     if ($rootScope.recommendationOrderSearchValue) {
                        //         $scope.branddata.search_value = $rootScope.recommendationOrderSearchValue;
                        //         $rootScope.recommendationOrderSearchValue = undefined;
                        //         $scope.searchProductsForCompanyRecommnedation();
                        //     }
                        // }
                        // if ($scope.searchData != null) {
                        //     $scope.branddata.search_value = $scope.searchData;
                        //     $scope.searchProductsForCompanyRecommnedation('filter');
                        // } else {
                        //     $scope.searchData = null
                        // }
                    }).catch(function (error) {
                        $scope.branddata.loading = false;
                        if (error == 'No products found') {
                            $scope.branddata.brandRecommendationOrder.products = [];
                            $scope.branddata.brandRecommendationOrder.totalProducts = 0;
                        }
                    });

                } catch (e) {
                    console.log('----------> ', e);
                }
            }

            $scope.toggleProductMode = function (value) {
                // console.log('toggleProductMode: ', value);
                $scope.localdata.isToggledClicked = true;
                if (value && (typeof ($rootScope.isCompanyProduct) == 'undefined' || !$scope.localdata.isCompanyProduct)) {
                    $rootScope.isCompanyProduct = true;
                } else if ($scope.localdata.isCompanyProduct) {
                    $rootScope.isCompanyProduct = false;
                }
                $scope.localdata.isCompanyProduct = value;
                console.log('$scope.localdata.isCompanyProduct 4', $scope.localdata.isCompanyProduct);
            }

            $scope.getTheProductsDetailForBrand = function () {
                $scope.getCompanyBRANDProductsRecommendation(true);
                $scope.getDynamicRecommendationFilter(true);
            }

            $scope.getTheProductsDetailForCompany = function () {
                console.log('----------------------------------------------> getTheProductsDetailForCompany called: ', $scope.localdata.isToggledClicked);
                $scope.getTheCompanyProducts(true);
                $scope.getDynamicRecommendationFilter(false);
                $scope.localdata.isToggled = false;
            }

            function BrandemptyTheSelectedProduct() {
                $scope.branddata.products = []
                var products = $scope.branddata.brandRecommendationOrder.products
                products.map(function (el) {
                    if (el && el.products && el.products.selected) {
                        el.products.selected = false
                    }
                })
            }

            $scope.BRANDchangePage = function () {
                // console.log('---------------------------------------BRANDchangePage------------------------: ', $scope.brand_filter.offset, $scope.branddata.offsetForPagination);
                BrandemptyTheSelectedProduct();
                $scope.brand_filter.offset = $scope.branddata.offsetForPagination - 1; // Pagiantion count from the 0 (CURRENT PAGE) but In query we are doing from 1(CURRENT PAGE)
                if ($scope.branddata.searchedProducts.isSearchResponse) {
                    $scope.searchProductsForCompanyRecommnedation();
                } else {
                    $scope.brand_filter.clientGroupCollections = [];
                    $scope.brand_filter.privateLabelCollections = [];
                    localStorage.setItem('brandrecommendationFilter', JSON.stringify($scope.brand_filter));
                    $scope.getCompanyBRANDProductsRecommendation();
                }
            }

            $scope.resetFilter = function () {
                localStorage.removeItem('recommendationFilter');
                $scope.company_filter = JSON.parse(JSON.stringify($recommendationOrder.getDefaultFilterValue()));
                $scope.getDynamicRecommendationFilter(false);
                $scope.getTheCompanyProducts();
            }

            $scope.brandResetFilter = function () {
                localStorage.removeItem('brandrecommendationFilter');
                $scope.brand_filter = JSON.parse(JSON.stringify($recommendationOrder.getDefaultFilterValue()));
                $scope.getDynamicRecommendationFilter(true);
                $scope.getCompanyBRANDProductsRecommendation(true);
            }

            function setFilter(filter) {
                $scope.companydata.search_value = '';
                filter.clientGroupCollections = [];
                filter.privateLabelCollections = [];
                localStorage.setItem('recommendationFilter', JSON.stringify(filter));
                $scope.getTheCompanyProducts();
            }

            function brandSetFilter(filter) {
                $scope.branddata.search_value = '';
                filter.clientGroupCollections = [];
                filter.privateLabelCollections = [];
                localStorage.setItem('brandrecommendationFilter', JSON.stringify(filter));
                $scope.getCompanyBRANDProductsRecommendation();
            }

            $scope.$on('recommendation_filter_directive', function (event, filter) {
                // console.log('recommendation_filter_directive---------------------------> ', filter);
                if (filter.isBrandProduct) {
                    brandSetFilter(filter);
                } else {
                    setFilter(filter);
                }
            });


            $scope.removeFilter = function (isCompanyProduct, filtertype, key, index) {
                if (isCompanyProduct) {
                    $scope.company_filter = $recommendationOrder.brandRemoveFilter($scope.company_filter, filtertype, key, index);
                    $scope.$emit('recommendation_filter_directive', $scope.company_filter);
                } else {
                    $scope.brand_filter = $recommendationOrder.brandRemoveFilter($scope.brand_filter, filtertype, key, index);
                    $scope.$emit('recommendation_filter_directive', $scope.brand_filter);
                }
            }

            $scope.searchCompanies = function (str) {
                // console.log('called searchCompanies: ', str, $scope.shopCompanies);
                return $recommendationOrder.searchCompanies(str, $scope.shopCompanies);
            };

            $scope.findProducts = function (value, searchMode, isProductNumber) {
                if (isProductNumber) $scope.localdata.isProductNumber = true;
                else $scope.localdata.isProductNumber = true;
                $scope.hits = [];
                $scope.localdata.search_value = value;
                $scope.unofficialProduct = undefined;
                $scope.gotProductsResponse = false;
                $scope.searchStarted = true;
                $scope.productExactMatch = false;

                var companyId = $scope.localdata.recommendationCompany && $scope.localdata.recommendationCompany._id ? $scope.localdata.recommendationCompany._id : undefined;
                var companyNameSlug = $scope.localdata.recommendationCompany && $scope.localdata.recommendationCompany.nameSlug ? $scope.localdata.recommendationCompany.nameSlug : undefined;
                console.log('------------------------------------------------findProducts 1: ', companyId, $scope.localdata.recommendationCompany, $scope.selectedCompany, $scope.recommendationOrder.purchase_order);
                if ($scope.selectedCompany && $scope.selectedCompany._id) {
                    companyId = $scope.selectedCompany._id
                    companyNameSlug = $scope.selectedCompany.nameSlug;
                } else if ($scope.recommendationOrder && $scope.recommendationOrder.purchase_order && $scope.recommendationOrder.purchase_order.company &&
                    $scope.recommendationOrder.purchase_order.company._id) {
                    companyId = $scope.recommendationOrder.purchase_order.company._id;
                    companyNameSlug = $scope.recommendationOrder.purchase_order.company.nameSlug;
                }

                console.log('------------------------------------------------findProducts 2: ', companyId, companyNameSlug);
                if ($rootScope.currentShop && $rootScope.currentShop._id && companyId) {
                    $scope.localdata.search_value = $productConfig.retrunValidProductNumber(value);
                    if (isProductNumber) $scope.localdata.productNumber = $scope.localdata.search_value;
                    else $scope.localdata.ean = $scope.localdata.search_value;
                    value = $scope.localdata.search_value;
                    if (value && value.length >= 3) {
                        $scope.searchingProduct = true;
                        $scope.cancleSearch = false;
                        $scope.productNumberStatus = 'default';
                        var data = {
                            nameSlug: $rootScope.currentShop.nameSlug,
                            searchMode: 'exact',
                            searchText: value,
                            shopId: $rootScope.currentShop._id,
                            shopProducts: true,
                            useFor: 'purchase-order',
                            companyId: companyId,
                            shopNameSlug: $scope.selectedCompany && $scope.selectedCompany.name ? $scope.selectedCompany.name : undefined,
                            BrandIdsList: $scope.BrandIdsList != undefined ? $scope.BrandIdsList : undefined,
                            companyNameSlug: companyNameSlug,
                            language: $rootScope.language,
                            isOnlyByBrand: false,
                            isProductNumber: isProductNumber,
                            offset: $scope.searchCurrentPage - 1,
                            shopProductOffSet: $scope.searchCurrentShopProductPage - 1
                        };

                        console.log('data: ', data);

                        $scope.localdata.findProductsLoader = true;
                        // findProductForMergeExactDataByOnlyCommonBrands(data); // To fetch the basic data (Not exact data)
                        $api.put('purchase-order-products-search-data/', data)
                            .then(function (response) {
                                console.log('purchase-order-products-search-data called: ', response);
                                $scope.gotProductsResponse = true;
                                // $scope.localdata.loading = false;
                                // document.getElementById('search_field_purchaseOrder').focus();
                                $scope.localdata.findProductsLoader = false;
                                if (response.status != 200) {
                                    return;
                                } else {
                                    $scope.searchingProduct = false;
                                    if (!$scope.cancleSearch) {
                                        if (response.data.data.status != undefined || response.data.data.status != null) $scope.productNumberStatus = parseInt(response.data.data.status);
                                        $scope.hideHits = true;
                                        var data = response.data.data.products;
                                        var counts = response.data.data.count;
                                        var shopProductsData = response.data.data.shopProducts;
                                        var shopProductCount = response.data.data.shopProductCount;

                                        $scope.totalProducts = counts;
                                        $scope.totalShopProducts = shopProductCount;
                                        $scope.shopProductsData = shopProductsData;
                                        $scope.productExactMatch = response.data.data.productExactMatch;
                                        var exactProduct = response.data.data.exactProduct;
                                        console.log('Okay, so you are here?: ', data, counts, shopProductsData, shopProductCount);
                                        if ($scope.localdata.eanNumberSearch && $scope.productExactMatch && exactProduct) {
                                            $scope.hits = [exactProduct];
                                            console.log('Okay, so you are here? IF: ', $scope.hits);
                                        } else {
                                            $scope.hits = $purchaseOrder.removeDuplicateProduct($scope.hits, data);
                                            console.log('Okay, so you are here? ELSE: ', $scope.hits);
                                        }
                                        $scope.countOfProducts = $scope.countOfProducts + counts;

                                    }
                                }
                            }).catch(function (reason) {
                                // document.getElementById('search_field_purchaseOrder').focus();
                                // $scope.localdata.loading = false;
                                $scope.localdata.findProductsLoader = false;
                                $scope.searchingProduct = false;
                                console.error(reason);
                            });
                    }
                } else {
                    console.log('Either Company Detail or Shop Detail not available: ', $rootScope.currentShop, $scope.selectedCompany);
                }
            };

            $scope.searchProductsForCompanyBRANDRecommnedation = function (data) {
                try {
                    console.log('searchProductsForCompanyBRANDRecommnedation called: ', $scope.branddata.search_value);
                    console.log('$scope.recommendationOrder.purchase_order: ', $scope.recommendationOrder);
                    $scope.findProducts($scope.branddata.search_value, 'exact', true);
                    $scope.branddata.isSearchingProducts = true;
                    $scope.filterDataVal = false;
                    // document.getElementById('search_field_purchaseOrder').focus();
                    $rootScope.recommendationOrderSearchValue = $scope.branddata.search_value;
                    if ($scope.recommendationOrder && $scope.recommendationOrder.purchase_order) {
                        if ((!$scope.recommendationOrder.purchase_order.company || !$scope.recommendationOrder.purchase_order.company._id) &&
                            ($scope.recommendationOrder.purchase_order.companyDetail && $scope.recommendationOrder.purchase_order.companyDetail._id)) {
                            $scope.recommendationOrder.purchase_order.company = $scope.recommendationOrder.purchase_order.companyDetail;
                            setTheCompanyVerified($scope.recommendationOrder.purchase_order.companyDetail);
                        }
                        // console.log('$scope.purchase_order.company: ', $scope.recommendationOrder.purchase_order);
                        if ($scope.recommendationOrder.purchase_order.company && $scope.recommendationOrder.purchase_order.company._id) {
                            if ($scope.branddata.search_value) {
                                $scope.branddata.search_value = $productConfig.retrunValidProductNumber($scope.branddata.search_value)
                                // value = $scope.branddata.search_value;
                                // console.log($scope.branddata.search_value)
                                if ($scope.branddata.search_value.length < 3) return

                                if (!$scope.branddata.searchedProducts.isSearchResponse) {
                                    $scope.company_filter.limit = 30
                                    $scope.company_filter.offset = 0
                                    $scope.branddata.offsetForPagination = 1;
                                    $scope.company_currentPage = 1;
                                }
                                var data = {
                                    searchText: $scope.branddata.search_value,
                                    companyId: $scope.recommendationOrder.purchase_order.company._id,
                                    shopId: $scope.currentShop._id,
                                    limit: $scope.company_filter.limit,
                                    offset: $scope.company_filter.offset,
                                    language: $rootScope.language,
                                    sortBasedOn: $scope.recommendationOrder.purchase_order.company.sortBasedOn,
                                    dataLimit: data == 'filter' ? 'filter' : null,
                                }

                                // $scope.branddata.loading = true;
                                $scope.branddata.isSearchLoader = true;
                                // document.getElementById('search_field_purchaseOrder').focus();
                                $recommendationOrder.searchProductsForCompanyRecommnedation(data).then(function (response) {
                                    console.info('searchProductsForCompanyRecommnedation called: ', response.data.data.products);
                                    $scope.branddata.isSearchLoader = false;
                                    $scope.branddata.searchedProducts.isSearchResponse = true;
                                    // console.log('search_field_purchaseOrder: ', document.getElementById('search_field_purchaseOrder'));
                                    // document.getElementById('search_field_purchaseOrder').focus();
                                    if (response && response.data && response.data.data && response.data.data.products && response.data.data.products.length) {
                                        for (var i = 0; i < response.data.data.products.length; i++) {
                                            var prod = response.data.data.products[i];
                                            if (prod && prod.products && prod.products.productDetail && prod.products.productDetail.images &&
                                                prod.products.productDetail.images.length) {
                                                    prod.products.productDetail.images = prod.products.productDetail.images[0].src;
                                                }
                                        }
                                        $scope.branddata.brandRecommendationOrder.products = response.data.data.products;
                                        $scope.branddata.brandRecommendationOrder.totalProducts = response.data.data.products.length;
                                        if($scope.recommendationOrder && $scope.recommendationOrder.purchase_order && $scope.recommendationOrder.purchase_order.items && $scope.recommendationOrder.purchase_order.items.length > 0){
                                            $scope.branddata.brandRecommendationOrder.products.map(function (product) {
                                                $scope.recommendationOrder.purchase_order.items.filter(function (item) {
                                                    if(item.product_id == product.products.productDetail._id){
                                                        product.products['quantity'] = item.quantity
                                                        product.products['selected'] = true
                                                    }
                                                })
                                                return product 
                                            })
                                        }
                                    } else {
                                        $scope.branddata.showLoadMore = false;
                                        $scope.branddata.brandRecommendationOrder = {
                                            products: [],
                                            totalProducts: 0
                                        };
                                    }
                                }).catch(function (error) {
                                    $scope.branddata.isSearchLoader = false;
                                    console.log('error: ', error);
                                })
                            } else {
                                console.log('No search 3');
                                $scope.brand_filter = undefined;
                                $scope.filter = undefined;
                                $scope.getCompanyBRANDProductsRecommendation();
                            }
                        } else {
                            console.log('No search  2');
                        }
                    } else {
                        console.log('No search 1');
                    }
                } catch (e) {
                    console.log('Search Product: ', e);
                }
            }

            // $scope.changePage = function (position, searchCurrentPage) {
            //     console.log('---------------------------------------change page');
            //     console.log('changePage: ', position, searchCurrentPage);
            //     $scope.searchCurrentPage = searchCurrentPage;
            //     // $scope.getTheCompanyProducts();
            //     $scope.getTheProductsDetailForCompany();
            //     // $scope.findProducts($scope.localdata.search_value, $scope.searchMode, $scope.localdata.isProductNumber);
            // }

            $scope.changeShopProductPage = function (position, searchCurrentShopProductPage) {
                $scope.searchCurrentShopProductPage = searchCurrentShopProductPage;
                $scope.findProducts($scope.localdata.search_value, $scope.searchMode, $scope.localdata.isProductNumber);
            }

            $scope.brand_removeSearchResults = function () {
                // console.log('branddata: ', $scope.branddata.isSearchingProducts);
                // if (!$scope.branddata.isSearchingProducts) return;
                $scope.branddata.isSearchingProducts = false;
                $scope.branddata.searchedProducts.isSearchResponse = false;
                $rootScope.recommendationOrderSearchValue = undefined; // To prevent focus losing when purchase order created
                $scope.branddata.search_value = '';
                $scope.brand_filter.limit = 30;
                $scope.brand_filter.offset = 0;
                $scope.brand_currentPage = 1;
                $scope.branddata.offsetForPagination = 1;
                $scope.searchData = undefined;
                $scope.searchProductsForCompanyBRANDRecommnedation();
            }

        }
    ])
