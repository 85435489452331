prismanoteApp.controller('retailerProductModalNewController', ['$scope', '$uibModalInstance', '$state', 'product', '$uibModal', '$rootScope', '$shop',
  function ($scope, $uibModalInstance, $state, product, $uibModal, $rootScope, $shop) {
    $scope.productId = product.product._id;
    var selectedIndex = product.selectedIndex; // selectedIndex means which product is selected from the assortment page
    console.log('-----------retailerProductModalNewControllerretailerProductModalNewControllerretailerProductModalNewController-----------------> ', product); 
    // if (product && product.isComingFromPurchaseOrder && product.isComingFromPurchaseOrder) {
    //   $scope.localdata = { 
    //     isComingFromPurchaseOrder: product.isComingFromPurchaseOrder
    //   }
    // }
    $scope.getOwnShopProduct = function (productId, shopNameSlug) {
      $shop.getOwnShopProduct(productId, shopNameSlug)
        .then(function (success) {
          $scope.product = success.product
          $scope.product._id = success.product._id
          $scope.shopProduct = success.shopProduct
          $scope.productBrands = success.brand
          $scope.productCollections = success.collections;
          $scope.product.searchedProduct = {
              selectedIndex: selectedIndex,
              product: product.product,
              isMerge: true
          };
          //-$scope.product.isComingFromPurchaseOrder = $scope.localdata.isComingFromPurchaseOrder;

          if ($scope.productBrands) {
            if ($scope.productBrands.isPoolArticleBrand)$scope.allowToMerge = false
            else $scope.allowToMerge = true
          } else {
            $scope.allowToMerge = true
          }

          if (!$scope.shopProduct.sellingPrice) {
            $scope.shopProduct.sellingPrice = $scope.shopProduct.price
            $scope.shopProduct.price = $scope.shopProduct.price * (($scope.shopProduct.priceVat / 100) + 1)
          }
        })

        .catch(function (error) {
          console.log('err', error)
        })
    }

    $scope.getTheCollectionName = function (collection) {
      var collectionName = ''
      if (collection.hasOwnProperty($rootScope.language)) { collectionName = collection[$rootScope.language].name } else if (collection.hasOwnProperty('en.name')) { collectionName = collection.en.name } else if (collection.hasOwnProperty('nl.name')) { collectionName = collection.nl.name } else if (collection.hasOwnProperty('de.name')) { collectionName = collection.de.name } else if (collection.hasOwnProperty('fr.name')) { collectionName = collection.fr.name } else if (collection.hasOwnProperty('name') && collection.name != '') { collectionName = collection.name } else { collectionName = 'NO NAME' }
      return collectionName
    }

    $scope.openPrintLabelModal = function () {
      var product = $scope.product
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/print-label-modal.html',
        controller: 'printLabelModalController',
        resolve: {
          product: function () {
            return product
          },
          loadProduct: function () {
            return true
          },
          labelDescription: function() {
            return null
          },
          empty: function () {
            return false
          }
        }
      })
      // we will get the value here
      modalInstance.result.then(function (result) {
        if (result) {
          // ready
          $scope.product.labelDescription = result.labelDescription ? result.labelDescription : ''
        }
      }, function () {
        // dismissed
      })
    }

    // if ($scope.shopProduct.discount != null) {
    //     if ($scope.shopProduct.discount > 0) {
    //         $scope.onSale = true;
    //         if ($scope.shopProduct && $scope.shopProduct.discountOnPercentage) {
    //             $scope.calculatedDiscount = ($scope.shopProduct.sellingPrice * $scope.shopProduct.discount) / 100;
    //             console.log("Discount on % ====> " , $scope.calculatedDiscount);
    //         } else {
    //             $scope.calculatedDiscount = $scope.shopProduct.discount;
    //             console.log("Discount on ====> " , $scope.calculatedDiscount);

    //         }
    //     } else {
    //         $scope.shopProduct.discount = 0;
    //         $scope.onSale = false;
    //     }
    // } else {
    //     $scope.shopProduct.discount = 0;
    //     $scope.onSale = false;
    // }

    $scope.cancel = function () {
      // console.log('Close the retailer product modal opening modal')
      $uibModalInstance.dismiss('cancel')
    }

    $rootScope.cancelModal = function () {
      // console.log('Close the retailer product modal opening modal')
      $uibModalInstance.dismiss('cancel')
    }

    $scope.edit = function (product) {
      // console.log('Product', product)
      $state.go('retailer.edit-product', { orderNumber: 'null', _id: product._id })
      $scope.cancel()
    }

    $scope.suggested = function (product) {
      // $uibModalInstance.dismiss('cancel')
      // Before It was opening the COMPARE PAGE but now we will open the MODAL
      // $state.go('retailer.compare-product', { nameSlug: $scope.product1._id, mergeProductnameSlug: product._id })
      $scope.openPairingAssortmentModal(product);
    }

    $scope.openPairingAssortmentModal = function (product) {
      console.log('---------------------------------  Retailer Product Modal New Controller 2222 !!');
      // console.log(product);
      var searchedProduct = {
        selectedIndex: selectedIndex,
        product: product.product,
        isMerge: true
      }
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/retailer-pairing-assortment.html',
        controller: 'retailerPairingAssortmentModal',
        size: 'lg',
        resolve: {
          item: function () {
            return { nameSlug: $scope.product1._id, mergeProductnameSlug: product._id, searchedProduct: searchedProduct }
          }
        },
        windowClass: 'sample-pug'
      });

      modalInstance.result.then(function (result) {
        // console.log('openPairingAssortmentModal called: 222222 ', result);
        $uibModalInstance.close();
      }, function (error) {
        console.log('openPairingAssortmentModal called: ', error);
      });
    }

  }])
