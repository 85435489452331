prismanoteApp.controller('employeeLoginModalController', ['$scope', '$uibModalInstance', '$employee', '$rootScope', '$state', '$api', '$uibModalStack',
  function ($scope, $uibModalInstance, $employee, $rootScope, $state, $api, $uibModalStack) {
    $scope.ep = {}
    $scope.isAddOtherAccount = false

    $scope.getEmployees = function () {
      $employee.getEmployees()
        .then(function (list) {
          $scope.employees = list
          // $scope.employees.push($rootScope.user); // As root user also can be loggedin as an pin and initials
          if ($rootScope.focusEmployeeId) checkFocusEmployee(); // Its for the focusing employee which is clicked from the employee list page in retailer portal
          else defaultFocus();
        }).catch(function (reason) {
          console.error(reason)
        })
    }

    $scope.login = function () {
      $scope.alert = null
      $employee.loginEmployee($scope.ep)
        .then(function (employee) {
          $scope.close(employee)
        }).catch(function (reason) {
          $scope.ep.pincode = ''
          $scope.alert = {
            type: 'danger',
            msg: reason
          }
        })
    }

    $scope.setInitialsOfEmployee = function (index, employee, type) {
      try {
        $scope.alert = null
        if(employee && employee.initials && employee._id){
          $scope.ep.initials = employee.initials
          $scope.ep._id = employee._id;
        }
        if (!employee.hasRequiredPin) {
          $scope.ep.initials = employee.initials
          $scope.ep._id = employee._id;
          $scope.login()
        } else {
          $scope.isAddOtherAccount = false
          closeAllPastLoggedIn(employee)
          closeAllShopEmployees(employee)
          if ($scope.employees && $scope.employees.length) {
            for (var i = 0; i < $scope.employees.length; i++) {
              // console.log($scope.employees[i]._id, employee._id);
              if ($scope.employees[i] && $scope.employees[i]._id && employee && employee._id && (($scope.employees[i]._id).toString() === (employee._id).toString())) {
                if (!employee.showPinInput) employee.showPinInput = true
                $scope.ep.initials = $scope.employees[i].initials
                $scope.ep.pincode = '';
                $scope.ep._id = $scope.employees[i]._id;
                setTimeout(function () {
                  if (type === 'pastemp') {
                    // console.log('hey: ', 'pincodePastEmp' + index);
                    var element = document.getElementById('pincodePastEmp' + index)
                    // console.log('elemtnt past: ', element);
                    if (element) element.focus()
                  } else {
                    // console.log('hey: ', 'pincodeShopEmp' + index);
                    var element = document.getElementById('pincodeShopEmp' + index)
                    // console.log('elemtnt shop: ', element);
                    if (element) element.focus()
                  }
                }, 500)
              }
            }
          }
        }
      } catch (catherr) {
        console.log(catherr)
      }
    }

    $scope.getLastLoggedInUser = function () {
      var getLastLoggedInEmployees = localStorage.getItem('lastLoggedInEmployees')
      // console.log(typeof getLastLoggedInEmployees, getLastLoggedInEmployees);
      if (getLastLoggedInEmployees) {
        // console.log('IF');
        getLastLoggedInEmployees = JSON.parse(getLastLoggedInEmployees)
      } else {
        // console.log('ELSE');
        getLastLoggedInEmployees = []
      }
      $scope.pastLoggedInEmployees = getLastLoggedInEmployees
    }

    $scope.close = function (ep) {
      $uibModalInstance.close(ep) // It was not worked when refreshing page
    }

    $scope.addOtherAccount = function (isAddOtherAccount) {
      // console.log('addOthe account called: ', isAddOtherAccount);
      // IF ANY OTHER INPUT IS OPEN THEN DOING CLOSE IT
      closeAllPastLoggedIn()
      closeAllShopEmployees()
      $scope.ep = {}
      $scope.isAddOtherAccount = !isAddOtherAccount

      if ($scope.isAddOtherAccount) {
        setTimeout(function () {
          var element = document.getElementById('initialsID')
          // console.log('elemtnt shop: ', element);
          if (element) element.focus()
        }, 500)
      }
    }

    $scope.disbaledTheAutoLock = function () {
      try {
        // if ($rootScope.currentEmployee) {
        // console.log('disbaledTheAutoLock', $rootScope.currentEmployee);
        $employee.setSession($rootScope.currentEmployee, false).then(function (response) {
          // console.log('INSIDE COME HERE');
          $employee.lockEmployee()
          if ($rootScope.currentEmployee) $rootScope.currentEmployee = null
          localStorage.setItem('isEnableEmployeeLogin', false)

          $api.post('logout', { id: $rootScope.user._id }).then(function () {
            // console.log('logout successfully');
            $rootScope.user = null
            $rootScope.openChats = []
            $rootScope.currentEmployee = undefined
            localStorage.removeItem('currentShop')
            localStorage.removeItem('currentShopName');
            localStorage.removeItem('isEnableEmployeeLogin')
            localStorage.removeItem('lastLoggedInEmployees')
            // $uibModalInstance.dismiss(); // It was not worked when refreshing page
            $uibModalStack.dismissAll() // dismiss all opened modal
            // var top = $uibModalStack.getTop();
            // console.log('top: ', top);
            // if (top) {
            //     console.log('top.key: ', top.key);
            //     $uibModalStack.dismiss(top.key);
            // }
            $state.reload();
            $state.go('layout.login')
          }).catch(function (reason) {
            console.log(reason)
          })
        }).catch(function (error) {
          console.log('somethng went wrong while disabling autolock: ', error)
        })
      } catch (catherr) {
        console.log('somethng went wrong while disabling autolock: ', catherr)
      }
    }

    // Its for the focusing employee which is clicked from the employee list page in retailer portal
    function checkFocusEmployee() {
      try {
        $scope.alert = null
        // console.log('Current Past loggedIn employees: ', index, employee);
        // console.log('ALL SHOP EMPLOYEE: ', $scope.employees);
        var valueOfI = undefined;
        if ($scope.pastLoggedInEmployees && $scope.pastLoggedInEmployees.length) $scope.showLoggedInUser = true
        for (var i = 0; i < $scope.employees.length; i++) {
          if ($scope.employees[i] && $scope.employees[i]._id && $rootScope.focusEmployeeId && ($scope.employees[i]._id).toString() === ($rootScope.focusEmployeeId).toString()) {
            valueOfI = i;
            $scope.employees[i].showPinInput = true
            $scope.ep.initials = $scope.employees[i].initials
            $scope.ep.pincode = '';
            $scope.ep._id = $scope.employees[i]._id;
            setTimeout(function () {
              // console.log(valueOfI, 'hey: ', 'pincodeShopEmp' + valueOfI);
              var element = document.getElementById('pincodeShopEmp' + valueOfI)
              // console.log('elemtnt shop: ', element);
              if (element) element.focus().setSelectionRange(0, 15)
            }, 500)
          }
        }
        $rootScope.focusEmployeeId = undefined

        if (valueOfI == undefined) {
          defaultFocus();
        }
      } catch (catherr) {
        console.log(catherr)
      }
    }

    // If not already focus there, then select default one
    function defaultFocus() {
      // console.log('defaultFocus: ', $scope.showLoggedInUser, $scope.pastLoggedInEmployees, $scope.employees);
      if ($scope.showLoggedInUser) {
        //console.log('showLoggedInUser shown pincodeShopEmp');
        if ($scope.employees && $scope.employees.length) {
          $scope.employees[0].showPinInput = true;
          $scope.ep.initials = $scope.employees[0].initials;
          $scope.ep.pincode = '';
          $scope.ep._id = $scope.employees[0]._id;
          setTimeout(function () {
            // console.log(valueOfI, 'hey: ', 'pincodeShopEmp' + valueOfI);
            var element = document.getElementById('pincodeShopEmp' + 0)
            // console.log('elemtnt shop: ', element);
            if (element) element.focus().setSelectionRange(0, 15)
          }, 500);
        }
      } else {
        //console.log('pastLoggedInEmployees shown pincodePastEmp');
        if ($scope.pastLoggedInEmployees && $scope.pastLoggedInEmployees.length) {
          $scope.pastLoggedInEmployees[0].showPinInput = true;
          $scope.ep.initials = $scope.pastLoggedInEmployees[0].initials;
          $scope.ep.pincode = '';
          $scope.ep._id = $scope.pastLoggedInEmployees[0]._id;
          setTimeout(function () {
            // console.log(valueOfI, 'hey: ', 'pincodeShopEmp' + valueOfI);
            var element = document.getElementById('pincodePastEmp' + 0)
            // console.log('elemtnt shop: ', element);
            if (element) element.focus().setSelectionRange(0, 15)
          }, 500);
        }
      }
    }

    function closeAllPastLoggedIn(employee) {
      if ($scope.pastLoggedInEmployees && $scope.pastLoggedInEmployees.length) {
        for (var i = 0; i < $scope.pastLoggedInEmployees.length; i++) {
          if (employee && employee._id && $scope.pastLoggedInEmployees[i] && $scope.pastLoggedInEmployees[i]._id &&
            ($scope.pastLoggedInEmployees[i]._id).toString() === (employee._id).toString()) continue
          $scope.pastLoggedInEmployees[i].showPinInput = false
        }
      }
    }

    function closeAllShopEmployees(employee) {
      if ($scope.employees && $scope.employees.length) {
        for (var i = 0; i < $scope.employees.length; i++) {
          if (employee && employee._id && $scope.employees[i] && $scope.employees[i]._id &&
            ($scope.employees[i]._id).toString() === (employee._id).toString()) continue
          $scope.employees[i].showPinInput = false
        }
      }
    }
  }])
