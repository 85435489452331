prismanoteApp.controller('retailerAddBulkChangeController', ['$scope', '$rootScope', '$api', '$uibModal', 'prompt',
  function ($scope, $rootScope, $api, $uibModal, prompt) {

    $scope.shopProducts = [];
    $scope.bulkUpdateDiscount = false;
    $scope.bulkUpdatePrice = false;
    $scope.discount = 0;
    $scope.discountOnPercentage = false;
    $scope.showButton = false;
    $scope.showApplyButton = false;
    $scope.price = 0;
    $scope.loading = false;
    $scope.pageLimit = 100;
    $scope.pageNumber = 1;
    $scope.onApplySuggestedPrice = false;
    $scope.manualChange = false;

    $scope.localdata = {
      isArrowArticle: true,
      isArrowProductNumber: true
  }

    $scope.$on('privateLabelCollectionsForBulkPage', function (event, data) {
      // console.log('-------- privateLabelCollectionsForBulkPage!!');
      // var articleGroupId = data.collection.privateLabelCollections[0]._id;
      var articleGroupId = (data && data.collection[0] && data.collection[0]._id) ? data.collection[0]._id : undefined;
      $scope.articleGroupId = articleGroupId;
      $scope.getShopProductsForBulkUpdate();
    })

    $scope.openPrintAllLabelsModal = function () {
      console.log('------------ openPrintAllLabelsModal!');
      var arr = [];
      for(var i = 0; i < $scope.shopProducts.length; i++){
        arr.push($scope.shopProducts[i]._id);
      }
      var data = {
          products: arr,
          shop: {
              _id: $scope.currentShop._id,
              nameSlug: $scope.currentShop.nameSlug,
          }
      }

      var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/print-all-label-modal-bulk-change.html',
          controller: 'printAllLabelBulkChangeModalController',
          resolve: {
              data: function () {
                  return data;
              },
              products: function () {
                  return null
              }
          }
      });

      modalInstance.result.then(function (result) {
          console.log('Success: ', result);
      }, function (error) {
          console.log("dismissed: ", error);
      })
    }


    $scope.changePageLimit = function(number){
      console.log('------- changePageLimit!!');
      console.log('number ', number);
      $scope.pageLimit = number;
      $scope.getShopProductsForBulkUpdate($scope.pageNumber);
    }

    $scope.changePage = function(flag){
      console.log('------- changePage!!');
      console.log('flag ', flag);
      if(flag == 'previous'){ $scope.pageNumber = $scope.pageNumber - 1; }
      if(flag == 'next'){ $scope.pageNumber = $scope.pageNumber + 1; }
      $scope.getShopProductsForBulkUpdate($scope.pageNumber);
    }

    $scope.useSuggestedRetailPrice = function(value){
      $scope.onApplySuggestedPrice = value;
      $scope.showButton = true;
      $scope.showApplyButton = true;
    }

    $scope.getShopProductsForBulkUpdate = function (pageNumber, sort) {
      if (!pageNumber) { pageNumber = 1; }
      $scope.loading = true;
      $scope.shopProducts = [];
      var body = {
        articleGroupId: $scope.articleGroupId,
        pageNumber: pageNumber,
        pageLimit: $scope.pageLimit,
        sort: sort
      }
      $api.get('shop/get-shopProducts-for-bulk-update/' + $rootScope.currentShop._id, body)
        .then(function (response) {
          $scope.loading = false
        if (response.data && response.data.products && response.data.products.length) {
          $scope.shopProducts = response.data.products;
          console.log('showing button now after reloading agian!!!')
          $scope.showButton = true;
          console.log('$scope.shopProducts called: ', $scope.shopProducts);
        }else{
          $scope.shopProducts = [];
        }
      })
      .catch(function (reason) {
        console.error('Error while getting the product list', reason)
        $scope.loading = false
      })
    }

    $scope.AddNewPriceManually = function(value, index){
      $scope.shopProducts[index].sellingPrice = value;
      $scope.shopProducts[index].price =  value /(1 + $scope.shopProducts[index].priceVat/100);
      // console.log('$scope.shopProducts[index].price ', $scope.shopProducts[index].price);
    }

    $scope.AddNewDiscountManually = function(value, index){
      $scope.shopProducts[index].discount = value;
    }

    $scope.AddNewStockManually = function(value, index){
      $scope.shopProducts[index].minStock = value;
    }

    $scope.changeValuesManually = function(value){
      $scope.manualChange = !value;
      if($scope.manualChange) $scope.showButton = true;
    }

    $scope.applyScopeChange = function (){
      console.log('--------------- applyScopeChange!!');
      // prompt({
      //   title: 'CHANGING PRICES',
      //   message: 'You are about to change the prices of ' + $scope.shopProducts.length + ' products. Are you sure?',
      //   buttons: [{
      //       label: 'Yes, I`m sure.',
      //       primary: true,
      //       id: 1
      //     },
      //     {
      //       label: 'No',
      //       primary: false,
      //       cancel: true,
      //       id: 0
      //     }
      //   ]
      // }).then(function (value) {
      //   console.log('value ', value);
      //   if (value && value.id) {
          for(var i = 0; i < $scope.shopProducts.length; i++){
            if($scope.onApplySuggestedPrice && $scope.shopProducts[i].suggestedRetailPrice){
              $scope.shopProducts[i].price = $scope.shopProducts[i].suggestedRetailPrice;
            }else{
              $scope.shopProducts[i].price = ($scope.shopProducts[i].sellingPrice + ( $scope.shopProducts[i].sellingPrice * $scope.price/100)) / (1 + $scope.shopProducts[i].priceVat/100);
            }
        // if(($scope.discount != $scope.shopProducts[i].discount) && $scope.bulkUpdateDiscount){ $scope.shopProducts[i].discount = $scope.discount; }        
        // if(($scope.discountOnPercentage != $scope.shopProducts[i].discountOnPercentage) && $scope.bulkUpdateDiscount){ $scope.shopProducts[i].discountOnPercentage = $scope.discountOnPercentage; }
          }
          $scope.showApplyButton = false;
      //   } else {
      //     console.log('I don`t want to apply these changes.');
      //   }
      // })
      // .catch(function (error) {
      //   console.log('Error ====> Apply changes ', error)
      // })
    }

    $scope.setDiscountForAll = function(flag, value){
      console.log('--------------- setDiscountForAll!!');
      if(flag == 'discount'){ 
        $scope.discount = value; $scope.bulkUpdateDiscount = true; 
        for(var i = 0; i < $scope.shopProducts.length; i++){
          $scope.shopProducts[i].discount = $scope.discount;
        }
      }
      if(flag == 'discountOnPercentage'){ 
        $scope.discountOnPercentage = value; $scope.bulkUpdateDiscount = true; 
        for(var i = 0; i < $scope.shopProducts.length; i++){
          $scope.shopProducts[i].discountOnPercentage = $scope.discountOnPercentage;
        }
      }
      if(flag == 'price'){ $scope.price = value; $scope.bulkUpdatePrice = true; }
      $scope.showButton = true;
      $scope.showApplyButton = true;
      // console.log('$scope.price ', $scope.price);
      // console.log('$scope.discount ', $scope.discount);
      // console.log('$scope.discountOnPercentage ', $scope.discountOnPercentage);
    }

    $scope.bulkUpdate = function(){
      console.log('---------- bulkUpdate!!');
      prompt({
        title: 'CHANGING PRICES',
        message: 'You are about to change the prices of ' + $scope.shopProducts.length + ' products. Are you sure?',
        buttons: [{
            label: 'Yes, I`m sure.',
            primary: true,
            id: 1
          },
          {
            label: 'No',
            primary: false,
            cancel: true,
            id: 0
          }
        ]
      }).then(function (value) {
        console.log('value ', value);
        if (value && value.id) {
          var ids = [];
          for(var i = 0; i < $scope.shopProducts.length; i++){
            ids.push($scope.shopProducts[i]._id);
          }
          var body = { ids: ids, data: angular.copy($scope.shopProducts) };
          if($scope.bulkUpdateDiscount == true){
            body.discountOnPercentage = $scope.discountOnPercentage;
            body.discount = $scope.discount;
          }
          if($scope.bulkUpdatePrice == true){
            body.price = $scope.price;
          }
          $scope.showButton = false;
          $api.post('shop/shopProducts-bulk-update/' + $rootScope.currentShop._id, body)
          .then(function (response) {
            $scope.getShopProductsForBulkUpdate();
          })
          .catch(function (reason) {
            $scope.showButton = true;
            console.error('Error while getting the product list', reason)
          })
        } else {
          console.log('I don`t want to apply these changes.');
        }
      })
      .catch(function (error) {
        console.log('Error ====> Apply changes ', error)
      })
    }

    $scope.sortTheProduct = function (isArticleNumberSort, isArrow) {
      console.log('sortTheProduct: ', isArticleNumberSort, isArrow, $scope.localdata.isArrowArticle, $scope.localdata.isArrowProductNumber)
      if (isArticleNumberSort) {
        $scope.localdata.isArrowArticle = !isArrow;
      } else {
        $scope.localdata.isArrowProductNumber = !isArrow;
      }

      var sort = {
        isArticleNumberSort: isArticleNumberSort,
        sort: !isArrow
      }
      $scope.getShopProductsForBulkUpdate('', sort);

    }

  }
])
