prismanoteApp.controller('webTemplateController', ['$scope', '$rootScope', 'factory', '$state', 'grapeFactory', 'dudaService', '$stateParams', '$retailer', function ($scope, $rootScope, factory, $state, grapeFactory, dudaService, $stateParams, $retailer) {
  $scope.editorHide = false
  $scope.loading = false
  $scope.isValidWebsite = true
  $scope.apiTemplateList = []
  $scope.alert = {
    type: '',
    msg: ''
  }

  $scope.loadTemplates = function () {
    $scope.loading = true
    return dudaService.getTemplateList()
      .then(function (response) {
        var listedItems = response.data
        listedItems = listedItems.filter(function (item) { 
          return (typeof(item.base_site_name) != 'undefined' ); 
        }); 
        $scope.apiTemplateList = listedItems;
        $scope.loading = false
      })
      .catch(function (error) {
        $scope.alert = {
          type: 'danger',
          msg: 'Error while loading templates'
        }
        $scope.loading = false
      })
  }

  $scope.templateLoader = function(template){
    $scope.loading = true
    var data ={
      templateId : template.template_id,
      shopId : $rootScope.currentShop._id
    }
    return dudaService.postCreateSite(data)
      .then(function (response) {
        if(response.status && response.status == 200){
          var url = response.data.SSOLink.url;
          var siteName = response.data.siteData.site_name;
          var tempUrl = url.split(".com/home");
          window.open(tempUrl[0] + '.com/home/site/' + siteName + tempUrl[1], '_blank');
        }
        $scope.loading = false;
        $state.go('retailer.web-builder-home-new');
      })
      .catch(function (error) {
        $scope.alert = {
          type: 'danger',
          msg: 'Error while loading templates'
        }
        $scope.loading = false
      })
  }

  $scope.goBack = function () {
    return $state.go('retailer.web-builder-home-new')
  }
}]).config(['$qProvider', function ($qProvider) {
  $qProvider.errorOnUnhandledRejections(false)
}])
