prismanoteApp.controller('retailerPurchaseOrdersController', ['$scope', '$rootScope', '$state', '$stateParams', '$api', '$uibModal',
  function ($scope, $rootScope, $state, $stateParams, $api, $uibModal) {
    $scope.purchase_orders = []
    $scope.total = $scope.purchase_orders.length
    $scope.isLoaded = false
    $scope.viewby = 50
    $scope.itemsPerPage = parseInt($scope.viewby)
    $scope.currentPage = 0 // reset to first page
    $scope.statusFilter = 'ALL_ITEMS' // Default show all purchase orders
    $scope.$on('changeSelectedShop', function () {
      console.log('getThePurchaseOrderList called 1')
      $scope.getThePurchaseOrderList()
    })

    // $scope.$on('refreshThePurchaseOrderList', function () {
    // 	console.log('REFRESHED THE PURCHASE ORDER');
    // 	$scope.getThePurchaseOrderList();
    // });

    if ($rootScope.showAlertMsgOfPurchaseOrder) {
      $rootScope.showAlertMsgOfPurchaseOrder = false
      setTimeout(function () {
        $scope.alert = undefined
      }, 3000)
      $scope.alert = {
        type: 'danger',
        msg: 'You do not have access of the purchase order. Change your shop to access the Purchase order.'
      }
    }

    $(".btn_body").click(function() {
      $(this).find('i').toggleClass('fa-caret-up fa-caret-down');
      if ($(".btn_body").not(this).find("i").hasClass("fa-caret-down")) {
        $(".btn_body").not(this).find("i").toggleClass('fa-caret-up fa-caret-down');
      }
    });
  
    $scope.NewPurchaseOrder = function () {
      console.log('------- NewPurchaseOrder!!');
      console.log('$rootScope.currentShop ', $rootScope.currentShop.name);
      var shopId = $rootScope.currentShop._id;
      console.log('shopId ', shopId);
      $api.get('getDefaultPurchaseOrderNo?shopId=' + shopId)
        .then(function (response) {
          console.log('------- getDefaultPurchaseOrderNo!!');
          console.log(response);
          $rootScope.isPurchaseOrderNew = true
          $rootScope.orderNo = response.data.purchaseOrderNumber;
          $state.go('retailer.purchase-order', { orderNo: response.data.purchaseOrderNumber })
        })
        .catch(function (reason) {
          console.error('Error while getting default product number', reason)
        })
    }

    $scope.getThePurchaseOrderList = function (searchField) {
      console.log('MAIN getThePurchaseOrderList called')
      var beforeDate = new Date().getTime()
      $scope.loading = true
      var page = $scope.currentPage + 1
      if ($stateParams.company == ' ') {
        $api.get('purchase-orders/' + $rootScope.currentShop.nameSlug + '?limit=' + $scope.itemsPerPage + '&pageNumber=' + page + '&statusFilter=' + $scope.statusFilter + '&searchField=' + searchField)
          .then(function (response) {
            var afterDate = new Date().getTime()
            console.log('response time: ', (afterDate - beforeDate) / 1000)
            $scope.isLoaded = true
            $scope.purchase_orders = response.data.purchaseOrders
            $scope.total = response.data.total
            purchaseOrdeCountDown()
            $scope.loading = false
          })
          .catch(function (reason) {
            $scope.isLoaded = true
            console.error('Error while getting the order list', reason)
            $scope.loading = false
          })
      } else {
        $api.get('purchase-orders/' + $rootScope.currentShop.nameSlug + '?limit=' + $scope.itemsPerPage + '&pageNumber=' + page + '&company=' + $stateParams.company + '&statusFilter=' + $scope.statusFilter + '&searchField=' + searchField)
          .then(function (response) {
            var afterDate = new Date().getTime()
            console.log('response time: ', (afterDate - beforeDate) / 1000)
            $scope.purchase_orders = response.data.purchaseOrders
            $scope.total = response.data.total
            purchaseOrdeCountDown()
            $scope.loading = false
          })
          .catch(function (reason) {
            console.error('Error while getting the orders', reason)
            $scope.loading = false
          })
      }
    }

    $scope.searchIntoPurchaseOrder = function (value){
      $scope.getThePurchaseOrderList(value);
  }

    $scope.setStatusFilter = function (statusType) {
      $scope.statusFilter = statusType;
      console.log('Get Purchase Order List Called 5: ', $scope.statusFilter);
      $scope.getThePurchaseOrderList();
    }

    function purchaseOrdeCountDown() {
      // console.log('purchaseOrdeCountDown fn called: ', $scope.purchase_orders.length);
      if ($scope.purchase_orders.length) {
        for (var i = 0; i < $scope.purchase_orders.length; i++) {
          if ($scope.purchase_orders[i] && $scope.purchase_orders[i].isCreatedByCompany && $scope.purchase_orders[i].invoiceCountDown && !$scope.purchase_orders[i].invoiceCountDown.isProFormaInvoice &&
            $scope.purchase_orders[i].invoiceCountDown.endCountDownTime) {
            var milliseconds = (new Date($scope.purchase_orders[i].invoiceCountDown.endCountDownTime).getTime()) - new Date().getTime()
            $scope.purchase_orders[i].isCountDownFinished = false
            if (milliseconds >= 0) {
              var seconds = milliseconds / 1000
              var days = Math.floor(seconds / (60 * 60 * 24))
              var hoursLeft = Math.floor((seconds) - (days * 86400))
              var hours = Math.floor(hoursLeft / 3600)
              var minutesLeft = Math.floor((hoursLeft) - (hours * 3600))
              var minutes = Math.floor(minutesLeft / 60)
              var remainingSeconds = seconds % 60
              function pad(n) {
                return (n < 10 ? '0' + n : n)
              }
              var curretnTimer = pad(hours) + ':' + pad(minutes)
              // console.log('timer: ', curretnTimer);
              $scope.purchase_orders[i].timer = curretnTimer
            }
          }
        }
      }
    }

    $scope.setItemsPerPage = function (num) {
      console.log('getThePurchaseOrderList called 2')
      $scope.itemsPerPage = parseInt(num)
      $scope.currentPage = 0 // reset to first page
      $scope.getThePurchaseOrderList()
    }

    $scope.changeThePage = function (direction) {
      var totalPages = Math.ceil($scope.total / $scope.itemsPerPage)
      if (direction == 'next') {
        var page = $scope.currentPage + 1
        if ((page + 1) <= totalPages) {
          $scope.currentPage = page
          console.log('getThePurchaseOrderList called 3')
          $scope.getThePurchaseOrderList()
        }
      } else if (direction == 'previous') {
        var page = $scope.currentPage - 1
        if (page < 0) {
          // do nothing but just refresh the page
          // $scope.getThePurchaseOrderList();
        } else {
          $scope.currentPage = page
          console.log('getThePurchaseOrderList called 4')
          $scope.getThePurchaseOrderList()
        }
      }
    }

    // $scope.setItemsPerPage($scope.viewby);

    $scope.createNewOrderAutoMatically = function () {
      $state.go('retailer.autocreated-purchase-order')
    }
  }])
