prismanoteApp.controller('retailerCustomersController', ['$scope', '$rootScope','$q', '$uibModal', 'dialogs', '$http', '$state', '$filter', '$language', '$data', '$customer', '$api', '$log', '$retailer', 'cookieService', '$crypto',
  function ($scope, $rootScope, $q, $uibModal, dialogs, $http, $state, $filter, $language, $data, $customer, $api, $log, $retailer, cookieService, $crypto) {
    $scope.pageSize = '10'
    $scope.customers = []
    $scope.statusFilter = 'ALL_ITEMS'
    cookieService.getCookie('servicesCurrentPage')
      .then(function (currentPage) {
        $scope.currentPage = currentPage
        $scope.saveCurrentPage(currentPage)
      })
      .catch(function () {
        $scope.currentPage = 1
      })
    if ($scope.currentPage == undefined) {
      $scope.currentPage = 1
    }
    $scope.customersMainData = []
    $scope.searchMoreContent = false
    $scope.searchDetails = false;
    $scope.searchedMainData = []
    $scope.sortBy = function (propertyName, reverse) {
      $scope.reverse = reverse || (($scope.propertyName === propertyName) ? !$scope.reverse : false)
      $scope.propertyName = propertyName
    }

    app.directive('ngEnter', function () { //a directive to 'enter key press' in elements with the "ng-enter" attribute

        return function (scope, element, attrs) {

            element.bind("keydown keypress", function (event) {
                if (event.which === 13) {
                    scope.$apply(function () {
                        scope.$eval(attrs.ngEnter);
                    });

                    event.preventDefault();
                }
            });
        };
    })

    $scope.searchVal = function(val) {
      if(val.length == 0) {
        $scope.customers = $scope.customersMainData;
        $scope.searchqueryAngular = undefined
        $scope.searchMoreContent = false
        $scope.searchedMainData = []
      }
    }

    $scope.showSearchBySerialNumber = function (){
      var shopArr = [
        '5a0af3d4f6bdc126443d159d',
        '5a0af3fbf6bdc126443d159f',
        '5a0af41ef6bdc126443d15a1',
        '5a0af444f6bdc126443d15a3',
        '5a0af52af6bdc126443d3eaf'
      ];
      if(shopArr.indexOf($rootScope.currentShop._id) > -1) return true;
      else return false;
    }

    $scope.searchBySerialNumber = function (value) {
      if(!value) return;
      $scope.hits = []
      var searchedData = []

      $api.post('customers/searchBySerialNumber', {
        search: value,
        shopId: $rootScope.currentShop._id
      })
        .then(function (response) {
          if(response.data.customers.length > 0){
            for (var k = 0; k < response.data.customers.length; k++) {
              searchedData.push(response.data.customers[k])
             }
             if(searchedData.length == response.data.customers.length) {
              $scope.customers = searchedData;
              $scope.searchedMainData = $scope.customers;
              $scope.searchMoreContent = true
             }else {
              $scope.searchMoreContent = false
             }
          } else {
            $scope.searchMoreContent = false
            $scope.customers = searchedData;
            $scope.searchedMainData = []
          }
        })
        .catch(function (reason) {
          $log.error('Error while searching customers', reason)
        })
    }

    $scope.searching = function (value) {
      $scope.hits = []
      var searchedData = []
      if (value) {

        $api.post('customers/search', {
          search: value,
          shopId: $rootScope.currentShop._id
        })
          .then(function (response) {
            if(response.data.customers.length > 0){
              for (var k = 0; k < response.data.customers.length; k++) {
                searchedData.push(response.data.customers[k])
               }
               if(searchedData.length == response.data.customers.length) {
                $scope.customers = searchedData;
                $scope.searchedMainData = $scope.customers;
                $scope.searchMoreContent = true
               }else {
                $scope.searchMoreContent = false
               }
              
            } else {
              $scope.searchMoreContent = false
              $scope.customers = searchedData;
              $scope.searchedMainData = []
            }
            
          })
          .catch(function (reason) {
            $log.error('Error while searching customers', reason)
          })
      }
    }

    $scope.customerName = function(customer) {
      return $customer.makeCustomerName(customer)
    }

    $scope.getCustomers = function (force) {
      $scope.loading = true
      $scope.customers = []
      $scope.customersMainData = []
      $retailer.getShop()
        .then(function (shop) {
          $api.get('all-customers/' + shop._id + '?limit=' + $scope.pageSize + '&pageNumber=' + $scope.currentPage + '&statusFilter=' + $scope.statusFilter)
            .then(function (response) {
              var customerData  = response.data.customers.result              
              $scope.customers = customerData;
              $scope.customersMainData = customerData;
              $scope.dataLength = response.data.customers.total
              $scope.loading = false
              $scope.originalCustomerList = JSON.parse(JSON.stringify($scope.customers))
              $scope.sortBy('dateLastModified', true)
              cookieService.getCookie('servicesCurrentPage')
                .then(function (currentPage) {
                  $scope.currentPage = currentPage
                })
                .catch(function () {
                  $scope.currentPage = 1
                })
            })
            .catch(function (reason) {
              $log.error('Error while fetching customers', reason)
              $scope.loading = false;
            })
        })
        .catch(function (reason) {
          $log.error('Error while fetching shop', reason)
        })
    }


    $(".btn_body").click(function() {
      $(this).find('span').toggleClass('fa-caret-up fa-caret-down');
      if ($(".btn_body").not(this).find("i").hasClass("fa-caret-down")) {
        $(".btn_body").not(this).find("i").toggleClass('fa-caret-up fa-caret-down');
      }
    });

    $scope.openCustomer = function (id) {
      $state.go('retailer.customer', {
        id: id
      })
    }
    $scope.savePageSize = function (pageSize) {
      cookieService.setCookie('servicesPageSize=' + pageSize, 31536000) 
      $scope.getCustomers(true)
    }

    $scope.saveCurrentPage = function (currentPage) {
      $scope.getCustomers(true)
      cookieService.setCookie('servicesCurrentPage=' + currentPage, 1800)
    }


    $scope.numberOfPages = function () {
      var data = $scope.getData()
      if (!data || data.length == 0) { return }

      return Math.ceil(data.length / $scope.pageSize)
    }

    $scope.getData = function () {
      if (!$scope.marketingCustomers || $scope.marketingCustomers.length == 0) {
        return
      }
      return $filter('filter')($scope.marketingCustomers, $scope.searchquery)
    }

    $scope.getNumber = function (number) {
      return new Array(number)
    }

    $scope.goToPage = function (page) {
      $scope.currentPage = page
    }

    $scope.$on('changeSelectedShop', function () {
      $scope.getCustomers(true)
    })

    $scope.openCollectionModal = function () {
      return $q(function (resolve, reject) {
        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/retailer-customer-csv-export-filter-modal.html',
          controller: 'retailerCustomerCSVExportFilterController',
          size: 'lg',
          resolve: {
            product: function () {
              return {};
            }
          }
        });

        modalInstance.result
          .then(function (response) {
            if (response) {
              /* $api.post('collection-products', data)
                .then(function (response) {
                  $scope.products = response.data.products
                }) */
            }
          })
          .catch(function (reason) {
            console.error('modal error', reason);
            reject(reason);
          });
      });
    }


    $scope.exportCSV = function () {
      var encrypted = $crypto.encrypt({ shopId: $rootScope.currentShop._id })
      $http({
        method: 'POST',
        url: 'api/customers/export',
        data: {encrypted: encrypted},
        responseType: 'string'
      })
        .then(function (response) {
          var file = new Blob([response.data], { type: 'text/csv' })
          saveAs(file, 'customers.csv')
        }, function (response) {
          console.error('Error while exporting customers', response)
          dialogs.error(undefined, $language.translate('ERROR_WHILE_DOWNLOADING_DOCUMENT'), { size: 'sm' })
        })
    }

    $scope.reloadPage = function () {
      $scope.getCustomers(true)
    }

    $scope.openNewCustomerModal = function () {
      $customer.openCustomerModal(null, true, false)
        .then(function (result) {
          if (result.action === 'open') {
            $scope.openCustomer(result.customer._id)
          } else {
            $scope.getCustomers(true)
          }
        })
        .catch(function (reason) {
          console.error('REASON', reason)
        })
    }

    $scope.searchCustomer = function (value) {
      if (!value) $scope.customers = JSON.parse(JSON.stringify($scope.searchedMainData))
      $scope.customers = []
      $scope.searchedMainData.forEach(function (customer) {
        var invoicePostal = (customer.invoiceAddress && customer.invoiceAddress.postalCode ? customer.invoiceAddress.postalCode : '')
        var invoiceHouseNumber = (customer.invoiceAddress && customer.invoiceAddress.houseNumber ? customer.invoiceAddress.houseNumber : '')
        var invoiceHouseNumberSuffix = (customer.invoiceAddress && customer.invoiceAddress.houseNumberSuffix ? customer.invoiceAddress.houseNumberSuffix : '')
        var invoiceSearch = invoicePostal + ' ' + invoiceHouseNumber + invoiceHouseNumberSuffix

        var shippingPostal = (customer.shippingAddress && customer.shippingAddress.postalCode ? customer.shippingAddress.postalCode : '')
        var shippingHouseNumber = (customer.shippingAddress && customer.shippingAddress.houseNumber ? customer.shippingAddress.houseNumber : '')
        var shippingHouseNumberSuffix = (customer.shippingAddress && customer.shippingAddress.houseNumberSuffix ? customer.shippingAddress.houseNumberSuffix : '')
        var shippingSearch = shippingPostal + ' ' + shippingHouseNumber + shippingHouseNumberSuffix
        
        if (
          (customer.firstName && customer.firstName.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.lastName && customer.lastName.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.email && customer.email.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.searchZip1 && customer.searchZip1.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.searchZip2 && customer.searchZip2.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.searchAddress1 && customer.searchAddress1.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.searchAddress2 && customer.searchAddress2.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.invoiceAddress && customer.invoiceAddress.attn && customer.invoiceAddress.attn.firstName && customer.invoiceAddress.attn.firstName.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.invoiceAddress && customer.invoiceAddress.attn && customer.invoiceAddress.attn.lastNamePrefix && customer.invoiceAddress.attn.lastNamePrefix !== '' && customer.invoiceAddress.attn.lastNamePrefix.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.invoiceAddress && customer.invoiceAddress.attn && customer.invoiceAddress.attn.lastName && customer.invoiceAddress.attn.lastName.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.invoiceAddress && customer.invoiceAddress.street && customer.invoiceAddress.street.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.invoiceAddress && customer.invoiceAddress.postalCode && customer.invoiceAddress.postalCode.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.invoiceAddress && customer.invoiceAddress.city && customer.invoiceAddress.city.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.invoiceAddress && customer.invoiceAddress.country && customer.invoiceAddress.country.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.invoiceAddress && customer.invoiceAddress.state && customer.invoiceAddress.state.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.shippingAddress && customer.shippingAddress.attn && customer.shippingAddress.attn.firstName && customer.shippingAddress.attn.firstName.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.shippingAddress && customer.shippingAddress.attn && customer.shippingAddress.attn.lastNamePrefix && customer.shippingAddress.attn.lastNamePrefix.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.shippingAddress && customer.shippingAddress.attn && customer.shippingAddress.attn.lastName && customer.shippingAddress.attn.lastName.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.shippingAddress && customer.shippingAddress.street && customer.shippingAddress.street.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.shippingAddress && customer.shippingAddress.postalCode && customer.shippingAddress.postalCode.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.shippingAddress && customer.shippingAddress.city && customer.shippingAddress.city.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.shippingAddress && customer.shippingAddress.country && customer.shippingAddress.country.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(customer.shippingAddress && customer.shippingAddress.state && customer.shippingAddress.state.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
          // (customer.clientId && customer.clientId.toString().indexOf(value.toString()) >= 0) ||
					(invoiceSearch.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
					(shippingSearch.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0) ||
          (customer.companyName && customer.companyName.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0)
        ) {
          $scope.customers.push(customer)
        }
      })
    }
  }])