prismanoteApp.controller('retailerShopProductCollectionsController', ['$scope', '$uibModalInstance', 'product', '$uibModal' , '$q', '$shop', '$api', '$productConfig',
  function ($scope, $uibModalInstance, product, $uibModal, $q, $shop, $api, $productConfig) {
    console.log('********* retailer shop product **********')
    console.log('product collectios called', product)
    $scope.updatedProducts = []
    $scope.collections = []
    $scope.products = product
    console.log('$scope.products::::@@@@',$scope.products);

    function changeImageIndex(input, currentIndex, newIndex) {
      console.log('currentIndex::',currentIndex,'newIndex',newIndex);
      var temp = input[currentIndex];
      input[currentIndex] = input[newIndex];
      input[newIndex] = temp;
      $scope.updatedProducts = input
  }

  $scope.openCollectionProducts = function (productId) {
    console.log('productId::::::',productId);
    var data = {
      collectionId: productId
    }
    $api.post('collection-products', data)
      .then(function (response) {
       console.log('response:::',response);
       $scope.productsData = response.data.products
       $scope.openProductModal($scope.productsData)
      })
      .catch(function (reason) {
        $log.error('Error while fetching collection data', reason)
        $scope.loading = false
      })
  }

  $scope.getAllCollections = function () {
    $api.post('all-collections-details')
    .then(function (response) {
     console.log('response all collections:::',response);
     $scope.collections = response.data.collections
    /*  $scope.productsData = response.data.products
     $scope.openProductModal($scope.productsData) */
    })
    .catch(function (reason) {
      $log.error('Error while fetching collection data', reason)
      $scope.loading = false
    })
  }

  $scope.openProductModal = function (products) {
    // console.log("Product", product)
    console.log("Hey Product Model CHeck this",products);
    return $q(function (resolve, reject) {
      var data = {
        'products': products,
        'modal': 'collections'
      };
      console.log('data::',data);
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/retailer-shop-product-sorting-modal.html',
        controller: 'retailerShopProductSortingController',
        size: 'lg',
        resolve: {
          product: function () {
            return data;
          }
        }
      });

      modalInstance.result
        .then(function (response) {
          console.log("Response After Close Modal", response);
          if (response) {
            console.log('Model response');
            /* $api.post('collection-products', data)
              .then(function (response) {
                $scope.products = response.data.products
              }) */
          }
        })
        .catch(function (reason) {
          console.log("Here", reason);
          reject(reason);
        });
    });
  }

    $scope.previousImage = function (currentIndex) {
      console.log('called', currentIndex);
      changeImageIndex($scope.products, currentIndex, (currentIndex - 1));
    }

  $scope.nextImage = function (currentIndex) {
    console.log('called next',currentIndex);
      changeImageIndex($scope.products, currentIndex, (currentIndex + 1));
  }

  $scope.close = function () {
    $uibModalInstance.close(true)
  }
  }])
