prismanoteApp.controller('retailerAskToMergeCompanyModalController', ['$scope', '$uibModalInstance', '$uibModal', '$api', '$rootScope', 'data', '$state', '$shop',
  function ($scope, $uibModalInstance, $uibModal, $api, $rootScope, data, $state, $shop) {
    $scope.company = data

    console.log("retailerAskToMergeCompanyModalController", $scope.company);

    $scope.closeModal = function () {
      $uibModalInstance.close($scope.wholesaler)
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    $scope.searchValue = ''
    $scope.searchMode = 'exact'

    $scope.findCompanies = function (value, searchMode) {
      $scope.hits = []
      var params = {}
      if (value) {
        if (value.length < 3) return
        if ($rootScope.currentShop != undefined) {
          var body = {
            searchText: value,
            searchMode: searchMode,
            shopId: $rootScope.currentShop._id,
            type: ''
          }
          $api.get('webshop-companies-search-by-suggestion', body)
            .then(function (response) {
              console.log('ebshop-companies-search-by-suggestio: ', response.data.data)
              if (response.data && response.data.data && response.data.data.length) {
                for (var i = 0; i < response.data.data.length; i++) {
                  console.error(response.data.data[i]._id);
                  if ((response.data.data[i]._id).toString() != ($scope.company._id).toString()) $scope.hits.push(response.data.data[i])
                }
              }
            })
            .catch(function (reason) {
              console.error(reason)
            })
        }
      }
    }

    // In Old functionality, have to ask to admin that merge the company but now onwards retailer can do it self.
    $scope.MergeCompany = function (company) {
      $uibModalInstance.dismiss('cancel')
      if (!$scope.company) return
      // console.log('selectedCompany: ', $scope.company);
      // console.log('searchedCompany: ', company);
      $scope.selectedCompany = $scope.company
      $scope.searchedCompany = company
      // console.log('isSearchedCompanyAlreadyInShop(): ', isSearchedCompanyAlreadyInShop());
      var data = {
        selectedCompany: {
          _id: $scope.company._id,
          name: $scope.company.name,
          nameSlug: $scope.company.nameSlug
        },
        searchedCompany: {
          _id: company._id,
          name: company.name,
          nameSlug: company.nameSlug
        }
      }

      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/warning-merge-company-modal.html',
        controller: 'warningMergeCompanyModalController',
        size: 'lg',
        resolve: {
          data: function () {
            return data
          }
        }
      })

      modalInstance.result.then(function (result) {
        if (result == 'mergeCompany') MergeCompanTheCompany()
      }, function (err) {
        console.log('something went wrong in modal closed')
      })
    }

    function MergeCompanTheCompany () {
      console.log('MergeCompanTheCompany callled:')
      var body = {
        selectedCompany: $scope.selectedCompany,
        searchedCompany: $scope.searchedCompany,
        shop: {
          _id: $rootScope.currentShop._id,
          nameSlug: $rootScope.currentShop.nameSlug,
          email: $rootScope.currentShop.email,
          name: $rootScope.currentShop.name,
          companies: $scope.companies
        }
      }
      if (!body.selectedCompany || !body.searchedCompany || !body.selectedCompany._id || !body.selectedCompany.nameSlug || !body.searchedCompany._id || !body.searchedCompany.nameSlug || !body.shop._id ||
				!body.shop.nameSlug || !body.shop.name) {
        console.log('Invalid data')
        return
      }

      $api.post('retailer-merge-company/', body)
        .then(function (response) {
          $state.go('retailer.suppliers')
        })
        .catch(function (reason) {
          console.error(reason.data.message)
        })
    }

    function getCompanies () {
      try {
        $scope.companies = []
        $shop.getOwnShopCompanies($rootScope.currentShop._id)
          .then(function (companies) {
            $scope.companies = companies
          })
          .catch(function (error) {
            console.error('Error! While getting Shop Companies')
            console.error(error)
          })
      } catch (catcherr) {
        console.log('catch error: ', catcherr)
      }
    }

    getCompanies()

    // When so companies found then user can create new company with that
    $scope.addNewCompany = function () {
      console.log('addnew Company called')
      $scope.cancel()
      $rootScope.companyNameFromAddCompModal = $scope.searchValue
      $scope.searchValue = ''
      $state.go('retailer.add-company')
    }
  }])
