prismanoteApp.controller('retailerPaidCampaignController', ['$scope', '$rootScope', '$stateParams', '$state', '$retailer', '$api', '$campaign',
  function ($scope, $rootScope, $stateParams, $state, $retailer, $api, $campaign) {
    console.log('retailerPaidCampaignController called: ', $rootScope.currentShop, $stateParams)
    $scope.isCampaignStep = $stateParams.isCampaignStep
    $scope.awsUrl = $rootScope.awsUrl
    $scope.alertdanger = false
    // $scope.campaignViewedByRetailer = function () {
    //     $scope.loading = true;
    //     $campaign.campaignViewedByRetailer($stateParams.campaignId, $scope.currentShop._id).then(function (response) {
    //         console.log(response);
    //         $scope.loading = false;
    //         $scope.showError = false;
    //     }).catch(function (error) {
    //         console.log(error);
    //         $scope.loading = false;
    //         // $scope.showError = true;
    //         // $scope.errorMessage = error.data.message ? error.data.message : 'Something went wrong while joining campaign detail';
    //     });
    // }

    // if (!$rootScope.currentShop || !$rootScope.currentShop._id) {
    //     $retailer.getShop()
    //         .then(function (shop) {
    //             $scope.currentShop = shop;
    //             $scope.showError = false;
    //             checkShopIsValidOrNot();
    //             $scope.campaignViewedByRetailer();
    //         }).catch(function (error) {
    //             $scope.loading = false;
    //             $scope.showError = true;
    //             $scope.errorMessage = 'Something went wrong while fetching the shop Detail';
    //         });
    // } else {
    //     $scope.currentShop = $rootScope.currentShop;
    //     checkShopIsValidOrNot();
    //     $scope.campaignViewedByRetailer();
    // }

    $scope.getShop = function () {
      $scope.loading = true
      $retailer.getShop()
        .then(function (shop) {
          $scope.currentShop = shop
          $scope.showError = false
          checkShopIsValidOrNot()
          var body = { shopId: shop._id }
          $campaign.getCampaignById($stateParams.campaignId, body).then(function (campaign) {
            $scope.showError = false
            $scope.campaign = campaign
            $scope.campaign.joinAllCampaigns = false
            if ($scope.campaign && $scope.campaign.currentShopDetail && $scope.campaign.currentShopDetail.brands &&
                            $scope.campaign.currentShopDetail.brands.favoriteSupplier && $scope.campaign.currentShopDetail.brands.favoriteSupplier.joinAllCampaigns) {
              $scope.campaign.joinAllCampaigns = true
              // console.log('$scopo.ecap: ', $scope.campaign.currentShopDetail)
            }
            showRetailerFacebookResuls(shop._id)
            $campaign.campaignViewedByRetailer($stateParams.campaignId, $scope.currentShop._id).then(function (response) {
              // console.log(response);
              $scope.campaign.isJoined = response.isJoined
              console.log(campaign)
              $scope.loading = false
              $scope.showError = false
            }).catch(function (error) {
              console.log(error)
              $scope.loading = false
              $scope.showError = true
              $scope.errorMessage = error.data.message ? error.data.message : 'Something went wrong while joining campaign detail'
            })
          }).catch(function (error) {
            console.log(error)
            $scope.loading = false
            $scope.showError = true
            $scope.errorMessage = 'Something went wrong while fetching the campaign Detail'
          })
        }).catch(function (error) {
          $scope.loading = false
          $scope.showError = true
          $scope.errorMessage = 'Something went wrong while fetching the shop Detail'
        })
    }

    function showRetailerFacebookResuls (currentShopId) {
      $scope.countView = 0
      $scope.countClicked = 0
      // console.log('shopId: ', currentShopId);
      // console.log('$scope.campaign.tasks[0].resultsShop: ', $scope.campaign);
      if ($scope.campaign && $scope.campaign.tasks.length && $scope.campaign.tasks[0].resultShop && $scope.campaign.tasks[0].resultShop.length) {
        // console.log('shopId:0 ', currentShopId);
        for (var i = 0; i < $scope.campaign.tasks[0].resultShop.length; i++) {
          if ($scope.campaign.tasks[0].resultShop[i] && $scope.campaign.tasks[0].resultShop[i].shopId && ($scope.campaign.tasks[0].resultShop[i].shopId).toString() == (currentShopId).toString()) {
            // console.log('inside');
            $scope.countView = $scope.campaign.tasks[0].resultShop[i].countView
            $scope.countClicked = $scope.campaign.tasks[0].resultShop[i].countClicked
          }
        }
      }
    }
    // $scope.getCampaignById = function () {
    //     $scope.loading = true;
    //     $campaign.getCampaignById($stateParams.campaignId).then(function (campaign) {
    //         $scope.loading = false;
    //         $scope.showError = false;
    //         $scope.campaign = campaign;
    //         console.log(campaign);
    //     }).catch(function (error) {
    //         $scope.loading = false;
    //         $scope.showError = true;
    //         $scope.errorMessage = 'Something went wrong while fetching the campaign Detail';
    //     });
    // }

    $scope.joinTheCampaign = function (index) {
      $scope.loading = true
      $scope.campaign.isJoined = true
      console.log(index, $scope.campaign.usershops[index])
      console.log('$scope.campaign._id, $scope.campaign.tasks[0]._id, $scope.campaign.usershops[index]._id: ', $scope.campaign._id, $scope.campaign.tasks[0]._id, $scope.campaign.usershops[index]._id)
      $campaign.joinTheCampaign($scope.campaign._id, $scope.campaign.tasks[0]._id, $scope.campaign.usershops[index]._id).then(function (response) {
        $scope.loading = false
        $scope.showError = false
        $retailer.getShop($scope.campaign.usershops[index].nameSlug).then(function (shop) {
          $scope.currentShop = shop
          $rootScope.currentShop = shop
          $rootScope.selectedShop = shop
          $scope.showError = false
          $scope.isCampaignStep = 'confirm'
          $state.go('retailer.paidcampaign', { isCampaignStep: 'confirm', campaignId: $scope.campaign._id })
        }).catch(function (error) {
          console.log('error: ---> ', error)
          $scope.showError = true
          $scope.errorMessage = error.data.message ? error.data.message : 'Something went wrong while joining campaign detail'
        })
      }).catch(function (error) {
        // console.log(error.status, error.data.message);
        console.log('error: ---> 1', error)
        $scope.loading = false
        if (error.status == 400 && error.data.message == 'You already joined the campaign') {
          $scope.isCampaignStep = 'confirm'
          $state.go('retailer.paidcampaign', { isCampaignStep: 'confirm', campaignId: $scope.campaign._id })
        } else {
          $scope.campaign.isJoined = false
          $scope.showError = true
          $scope.errorMessage = error.data.message ? error.data.message : 'Something went wrong while joining campaign detail'
        }
      })
    }

    $scope.checkSettings = function () {
      $rootScope.isCheckSettings = true
      $state.go('retailer.myshop')
    }

    function checkShopIsValidOrNot () {
      if (!$scope.currentShop || !$scope.currentShop.address || !$scope.currentShop.address.city || !$scope.currentShop.address.country || !$scope.currentShop.address.houseNumber ||
                !$scope.currentShop.address.postalCode || !$scope.currentShop.address.street || !$scope.currentShop.proShopUrl || !$scope.currentShop.geoloc ||
                !$scope.currentShop.geoloc.coordinates || !$scope.currentShop.geoloc.coordinates.length || !$scope.currentShop.phone || !$scope.currentShop.phone.landLine) {
        $scope.alertdanger = true
        $scope.alertmessage = 'URL or Address or Coordinates or landLine number are not saved! Your URL and Address should be saved proper on your shop. Checking your settings is required to join this campaign. Please check your settings in 1 min'
      }
    }

    $scope.changeBrandCampaignSubcription = function (campaign) {
      var body = {
        shopId: $rootScope.currentShop._id,
        brandId: campaign.brandId,
        joinAllCampaigns: campaign.joinAllCampaigns,
        companyId: campaign.companyId
      }
      // console.log('campaign: ', body);
      $campaign.changeBrandCampaignSubcription(body).then(function (response) {
        console.log('response: ', response)
      }).catch(function (error) {
        console.log('error: ', error)
      })
    }
  }])
