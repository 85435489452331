prismanoteApp.controller('retailerProductTransferConfirmationModalController', ['$scope', '$uibModalInstance', 'product', '$rootScope', '$shop',
  function ($scope, $uibModalInstance, product, $rootScope, $shop) {
    console.log('productFromTransfer', product.productFromTransfer)
    $scope.shop = product.shop
    $scope.productToTransfer = product.productToTransfer
    $scope.productFromTransfer = product.productFromTransfer
    $scope.PFTPN = product.PFTPN
    $scope.PTTPN = product.PTTPN
    $scope.newStock = (product.productToTransfer.stock) ? product.productToTransfer.stock : 0
    console.log('$scope.productToTransfer.newStock', $scope.productToTransfer.newStock)
    console.log('Product To Transfer', $scope.productToTransfer)
    console.log('Product From Transfer', $scope.productFromTransfer)
    // console.log("product inside the modal ==>", product.shop);

    $scope.transfer = function (isTransfer, newStock) {
      console.log('CLICKED: IS TRANSFER: ', isTransfer)

      console.log('new Stock', newStock)

      if (isTransfer == 'true') {
        console.log('productFromTransfer', $scope.productFromTransfer)
        console.log('productToTransfer', $scope.productToTransfer)
        var productFromTransfer = JSON.parse(JSON.stringify($scope.productFromTransfer))
        var productToTransfer = JSON.parse(JSON.stringify($scope.productToTransfer))
        var mytempobj = {
          _id: productFromTransfer._id,
          articleNumber: productToTransfer.articleNumber,
          labelDescription: productToTransfer.labelDescription,
          ownership: productToTransfer.ownership,
          stock: (newStock) || 0,
          purchasePrice: (productToTransfer.purchasePrice) ? productToTransfer.purchasePrice : 0,
          price: (productToTransfer.price) ? productToTransfer.price : 0,
          priceVat: (productToTransfer.priceVat) ? productToTransfer.priceVat : 21,
          dropshippingPrice: (productToTransfer.dropshippingPrice) ? productToTransfer.dropshippingPrice : 0,
          discount: (productToTransfer.discount) ? productToTransfer.discount : 0,
          discountOnPercentage: productToTransfer.discountOnPercentage,
          show: !!(productToTransfer.show),
          isBestseller: !!(productToTransfer.isBestseller),
          isFeatured: !!(productToTransfer.isFeatured),
          dateAdded: productToTransfer.dateAdded,
          dateModified: productToTransfer.dateModified,
          reference: productToTransfer.reference,
          hasStock: !!(productToTransfer.hasStock),
          supplier: productToTransfer.supplier,
          purchaseOrders: productToTransfer.purchaseOrders,
          minStock: (productToTransfer.minStock) ? productToTransfer.minStock : 0,
          maxStock: (productToTransfer.maxStock) ? productToTransfer.maxStock : 0,
          entryMethodCustomerValue: (productToTransfer.entryMethodCustomerValue) ? productToTransfer.entryMethodCustomerValue : false,
          delivery: productToTransfer.delivery,
          showSuggestion: (productToTransfer.showSuggestion) ? productToTransfer.productFromTransfer : false
        }

        var prodId = productFromTransfer._id // Retailer
        var mergeProdId = productToTransfer._id // Merge

        console.log('Product Id From ', prodId)
        console.log('Transfer id ', mergeProdId)
        console.log(' My Temp Object', mytempobj)

        console.log('Product Have to Update in shop Based', mytempobj)

        $shop.transferProductData($rootScope.currentShop._id, mergeProdId, prodId, mytempobj, $rootScope.currentShop.nameSlug)
          .then(function (data) {
            console.log('Have to Transfrer Product', data)
            $scope.close(mytempobj)
          })
          .catch(function (error) {
            console.log('error', error)
          })
      } else {
        var prodId = $scope.productFromTransfer._id
        // $scope.productToTransfer._id = prodId;
 				$scope.productFromTransfer.stock = $scope.newStock

        $shop.transferProductData($rootScope.currentShop._id, $scope.productToTransfer._id, null, $scope.productToTransfer, $rootScope.currentShop.nameSlug)
          .then(function (data) {
            console.log('Have to Transfrer Product', data)
            $scope.close($scope.productFromTransfer)
          })
          .catch(function (error) {
            console.log('error', error)
          })
      }
    }

    $scope.close = function (shouldModalOpen) {
      console.log('Close modal', shouldModalOpen)
      $uibModalInstance.close(shouldModalOpen)
    }
  }])
