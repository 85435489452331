prismanoteApp.controller('retailerJspmController', ['$scope', '$rootScope', '$retailer', '$api', '$state', '$language', '$employee', '$data', '$shop', '$device', '$uibModal',
  function ($scope, $rootScope, $retailer, $api, $state, $language, $employee, $data, $shop, $device, $uibModal) {

  $scope.showVersionMessage = function () {
    $scope.alert = null
    if($rootScope.currentShop) {
      if($rootScope.currentShop.jspmVersion === 2) {
        $scope.alert = {
          type: 'danger',
          msg: $language.translate('JSPM_CURRENTVERSION_2')
        }
      }
      else if($rootScope.currentShop.jspmVersion === 3) {
        $scope.alert = {
          type: 'info',
          msg: $language.translate('JSPM_CURRENTVERSION_3')
        }
      }
      else if($rootScope.currentShop.jspmVersion === 4) {
        $scope.alert = {
          type: 'success',
          msg: $language.translate('JSPM_CURRENTVERSION_4')
        }
      }
    }
  }

  $scope.showVersionMessage()

  $scope.getJSPMVersionInfo = function () {
    $scope.jspmInfo = {}
    $api.get('scripts/jspm/version', null, null, 'kassa')
      .then(function (data) {
        $scope.jspmInfo = data.data
      })
      .catch(function (reason){
        toastr.error($language.translate('ERROR_CHECKING_JSPM_VERSION'))
        console.error('reason', reason)
      })
  }

  $scope.download = function () {
    var a = document.createElement('a')
    a.href = 'https://jspm.prismanote.com/test.pdf'
    a.target = '_blank'
    a.setAttribute('download', 'PrismaNote JSPM Update helper')
    a.click()
  }

  $scope.$on('changeSelectedShop', function () {
    $scope.showVersionMessage()
  })

  $scope.updateJspmVersion = function () {
    $api.post('shop/jspm-version', {
      shopId: $rootScope.currentShop._id
    }).then(function (result) {
      $rootScope.currentShop.jspmVersion = 4
      $scope.showVersionMessage()
    })
      .catch(function (reason){
        console.error(reason)
        toastr.error($language.translate('ERROR_DURING_UPDATE'))
      })
  }

}])
