prismanoteApp.controller('emailCompanyController', ['$scope', '$rootScope', 'transaction', '$api', '$uibModalInstance', '$language', '$filter', 'dialogs', '$location',
  function ($scope, $rootScope, transaction, $api, $uibModalInstance, $language, $filter, dialogs, $location) {
    $scope.transaction = transaction
    $scope.sendPhoto = false

    $scope.supplier = {}
    if($scope.transaction.repair && $scope.transaction.repair.repairer) {
      $scope.supplier = $scope.transaction.repair.repairer
    } else if($scope.transaction.supplier) {
      $scope.supplier = $scope.transaction.supplier
    }
    console.log('supploer', $scope.supplier)
    $scope.language = $rootScope.language

    var photoUrls = function () {
      if ($scope.transaction.details[0].photos.length === 0) {
        return ''
      }
      var result = ''; var counter = 0
      for (var i = 0; i < $scope.transaction.details[0].photos.length; i++) {
        var photo = $scope.transaction.details[0].photos[i]
        var text = '<a href="' + photo.src + '">' + $language.translate('PHOTO') + ' ' + parseInt(i + 1).toString() + '</a><br>'
        if (photo.comment) {
          text = text + '<i>' + photo.comment + '</i><br>'
        }
        result = result + text

        counter++
        if (counter === $scope.transaction.details[0].photos.length) {
          return result
        }
      }
    }

    $scope.makeEmail = function () {
      var baseurl = $location.$$protocol + '://' + $location.$$host + '/'
      if ($location.$$host === 'localhost') {
        baseurl = $location.$$protocol + '://' + $location.$$host + ':3000/'
      }
      var url = baseurl + 'brand/transaction/' + $scope.transaction._id
      if ($scope.transaction.type === 'special' && $scope.transaction.details && $scope.transaction.details.length &&
                $scope.transaction.details[0].purchaseOrderId && $scope.transaction.details[0].purchaseOrderId.number) {
        url = baseurl + 'brand/purchase-order/' + $scope.transaction.details[0].purchaseOrderId.number
      }
      if(!$scope.transaction[$scope.transaction.type].email) {
        $scope.transaction[$scope.transaction.type].email = {
          supplier: {}
        }
      }
      if(!$scope.transaction[$scope.transaction.type].email.supplier) {
        $scope.transaction[$scope.transaction.type].email.supplier = {}
      }
      try {
        if ($scope.language === 'nl') {
          var feeBackUrl = function () {
            return '<br><a style="color: #4ab69c" href="' + url + '">' + 'Accordeer hier de afleverdatum en de kosten.' + '</a><br>'
          }
          $scope.transaction[$scope.transaction.type].email.supplier.subject = 'Serviceaanvraag van ' + $rootScope.currentShop.name
          $scope.transaction[$scope.transaction.type].email.supplier.body = 'Beste ' + $scope.supplier.name + ',<br> \
            Via deze weg melden wij een serviceverzoek bij u aan: <br><br> \
            Product: ' + $scope.transaction.details[0].name + ' <br> \
            Productnummer: ' + $scope.transaction.details[0].productNumber + ' <br> \
            EAN: ' + ($scope.transaction.details[0].productId ? $scope.transaction.details[0].productId.variants[0].ean : 'Onbekend') + '<br>\
            ' + ($scope.transaction.comment ? $scope.transaction.comment + '<br>' : '') + '\
            ' + ($scope.transaction.type == 'REPAIR' && $scope.transaction.repair.externalPrice && transaction.repair.externalPrice > 0 ? 'Zoals overeengekomen zijn de kosten voor dit verzoek ' + $filter('currency')($scope.transaction.repair.externalPrice, '') + ' exclusief BTW <br>' : '') + '\
            ' + ($scope.transaction[$scope.transaction.type].email.photos ? "Met onderstaande URL\'s kunt u de foto's downloaden die bij dit verzoek horen:<br>" + photoUrls() : '') + '\
            ' + feeBackUrl() + '\
            <br>Wij blijven graag op de hoogte van dit serviceverzoek. Wilt u ons berichten als de verwachte einddatum <strong>' + $filter('date')($scope.transaction[$scope.transaction.type].estimatedDate, 'dd-MM-yyyy') + '</strong> niet gehaald kan worden?<br> \
            Met vriendelijke groet,<br>\
            ' + $rootScope.currentShop.name + ''
        } else if ($scope.language == 'fr') {
          var feeBackUrl = function () {
            return '<br><a style="color: #4ab69c" href="' + url + '">' + 'Veuillez préciser ici la date de livraison et les coûts.' + '</a><br>'
          }
          $scope.transaction[$scope.transaction.type].email.supplier.subject = 'Demande de service de ' + $rootScope.currentShop.name
          $scope.transaction[$scope.transaction.type].email.supplier.body = 'Meilleur ' + $scope.supplier.name + ',<br> \
            Par la présente, nous enregistrons ce produit auprès de vous pour une demande de service: <br><br> \
            Produit: ' + $scope.transaction.details[0].name + ' <br> \
            Numéro de produit: ' + $scope.transaction.details[0].productNumber + ' <br> \
            EAN: ' + ($scope.transaction.details[0].productId ? $scope.transaction.details[0].productId.variants[0].ean : 'Inconnu') + '<br>\
            ' + ($scope.transaction.comment ? $scope.transaction.comment + '<br>' : '') + '\
            ' + ($scope.transaction.type == 'REPAIR' && $scope.transaction.repair.externalPrice && transaction.repair.externalPrice > 0 ? 'Comme convenu, les coûts de cette demande ' + $filter('currency')($scope.transaction.repair.externalPrice, '') + ' sont hors TVA <br>' : '') + '\
            ' + ($scope.transaction[$scope.transaction.type].email.photos ? 'Avec les URLs ci-dessous vous pouvez télécharger les images qui appartiennent à cette demande:' + photoUrls() : '') + '\
            ' + feeBackUrl() + '\
            <br>Nous aimerions être tenus au courant de cette demande de service. Souhaitez-vous nous informer si la date de fin prévue <strong>' + $filter('date')($scope.transaction[$scope.transaction.type].estimatedDate, 'dd-MM-yyyy') + '</strong> ne peut être respectée ?<br> \
            Avec mes meilleures salutations,<br>\
            ' + $rootScope.currentShop.name + ''
        } else if ($scope.language == 'es') {
          var feeBackUrl = function () {
            return '<br><a style="color: #4ab69c" href="' + url + '">' + 'Por favor, especifique aquí la fecha de entrega y los costes.' + '</a><br>'
          }
          $scope.transaction[$scope.transaction.type].email.supplier.subject = 'Solicitud de servicio de ' + $rootScope.currentShop.name
          $scope.transaction[$scope.transaction.type].email.supplier.body = 'Mejor ' + $scope.supplier.name + ',<br> \
            Por la presente registramos este producto con usted para su solicitud : <br><br> \
            Product: ' + $scope.transaction.details[0].name + ' <br> \
            Número de producto: ' + $scope.transaction.details[0].productNumber + ' <br> \
            EAN: ' + ($scope.transaction.details[0].productId ? $scope.transaction.details[0].productId.variants[0].ean : 'Onbekend') + '<br>\
            ' + ($scope.transaction.comment ? $scope.transaction.comment + '<br>' : '') + '\
            ' + ($scope.transaction.type == 'REPAIR' && $scope.transaction.repair.externalPrice && transaction.repair.externalPrice > 0 ? 'Según lo acordado, los costes de esta solicitud son ' + $filter('currency')($scope.transaction.repair.externalPrice, '') + 'sin IVA<br>' : '') + '\
            ' + ($scope.transaction[$scope.transaction.type].email.photos ? 'Con las siguientes URLs puedes descargar las fotos que pertenecen a esta solicitud:<br>' + photoUrls() : '') + '\
            ' + feeBackUrl() + '\
            <br>Nos gustaría que nos mantuvieran informados de esta solicitud de servicio. ¿Le gustaría informarnos si la fecha de finalización prevista <strong>' + $filter('date')($scope.transaction[$scope.transaction.type].estimatedDate, 'dd-MM-yyyy') + '</strong> no puede cumplirse?<br> \
            Con un cordial saludo,<br>\
            ' + $rootScope.currentShop.name + ''
        } else if ($scope.language == 'de') {
          var feeBackUrl = function () {
            return '<br><a style="color: #4ab69c" href="' + url + '">' + 'Bitte geben Sie hier den Liefertermin und die Kosten an.' + '</a><br>'
          }
          $scope.transaction[$scope.transaction.type].email.supplier.subject = 'Serviceanfrage von ' + $rootScope.currentShop.name
          $scope.transaction[$scope.transaction.type].email.supplier.body = 'Sehr geehrte Damen und Herren von ' + $scope.supplier.name + ',<br> \
            Hiermit registrieren wir dieses Produkt bei Ihnen zur Serviceanfrage : <br><br> \
            Produkt: ' + $scope.transaction.details[0].name + ' <br> \
            Produktnummer: ' + $scope.transaction.details[0].productNumber + ' <br> \
            EAN: ' + ($scope.transaction.details[0].productId ? $scope.transaction.details[0].productId.variants[0].ean : 'unbekannt') + '<br>\
            ' + ($scope.transaction.comment ? $scope.transaction.comment + '<br>' : '') + '\
            ' + ($scope.transaction.type == 'REPAIR' && $scope.transaction.repair.externalPrice && transaction.repair.externalPrice > 0 ? 'Wie vereinbart, sind die Kosten für diese Serviceanfrage ' + $filter('currency')($scope.transaction.repair.externalPrice, '') + ' ohne Mehrwertsteuer <br>' : '') + '\
            ' + ($scope.transaction[$scope.transaction.type].email.photos ? 'Mit den untenstehenden URLs können Sie die Bilder, die zu dieser Serviceanfrage gehören, herunterladen:<br>' + photoUrls() : '') + '\
            ' + feeBackUrl() + '\
            <br>Wir möchten über diese Serviceanfrage auf dem Laufenden gehalten werden. Möchten Sie uns mitteilen, wenn das erwartete Enddatum <strong>' + $filter('date')($scope.transaction[$scope.transaction.type].estimatedDate, 'dd-MM-yyyy') + '</strong> nicht erreicht werden kann?<br> \
            Mit freundlichen Grüßen,<br>\
            ' + $rootScope.currentShop.name + ''
        } else {
          var feeBackUrl = function () {
            return '<br><a style="color: #4ab69c" href="' + url + '">' + 'Please specify the delivery date and costs here.' + '</a><br>'
          }
          $scope.transaction[$scope.transaction.type].email.supplier.subject = 'Service request from ' + $rootScope.currentShop.name
          $scope.transaction[$scope.transaction.type].email.supplier.body = 'Dear ' + $scope.supplier.name + ',<br> \
            Via this way we register a service request: <br><br> \
            Product: ' + $scope.transaction.details[0].name + ' <br> \
            Productnumber: ' + $scope.transaction.details[0].productNumber + ' <br> \
            EAN: ' + ($scope.transaction.details[0].productId ? $scope.transaction.details[0].productId.variants[0].ean : 'Unknown') + '<br>\
            ' + ($scope.transaction.comment ? $scope.transaction.comment + '<br>' : '') + '\
            ' + ($scope.transaction.type == 'REPAIR' && $scope.transaction.repair.externalPrice && transaction.repair.externalPrice > 0 ? 'As agreed the costs for this request are ' + $filter('currency')($scope.transaction.repair.externalPrice, '') + ' without taxes<br>' : '') + '\
            ' + ($scope.transaction[$scope.transaction.type].email.photos ? "You can download the photo\'s that are part of this request using those links:<br>" + photoUrls() : '') + '\
            ' + feeBackUrl() + '\
            <br>We would like to be kept informed of this service request. Would you like to inform us if the expected end date <strong>' + $filter('date')($scope.transaction[$scope.transaction.type].estimatedDate, 'dd-MM-yyyy') + '</strong> cannot be met?<br> \
            With kind regards,<br>\
            ' + $rootScope.currentShop.name + ''
        }
      } catch (err) {
        console.error('Error in make email', err)
      }
    }

    $scope.setMailLanguage = function (language) {
      if (language) {
        $scope.language = language
      }
      $scope.makeEmail()
    }

    if ($scope.supplier) {
      if (!$scope.transaction[$scope.transaction.type].email) {
        $scope.transaction[$scope.transaction.type].email = {}

        if (!$scope.transaction[$scope.transaction.type].email.supplier) {
          $scope.transaction[$scope.transaction.type].email.supplier = {
            to: $scope.supplier.email
          }
        }
      } else {
        $scope.transaction[$scope.transaction.type].email.supplier.to = $scope.supplier.email
      }
      var country = $scope.supplier.address && $scope.supplier.address.country ? $scope.supplier.country : 'nl'
      $scope.setMailLanguage(country)
    }

    $scope.sendMail = function () {
      $scope.transaction[$scope.transaction.type].email.supplier.date = new Date()
      $scope.transaction[$scope.transaction.type].email.supplier.from = '"' + $rootScope.currentShop.name + '"' + '<' + $rootScope.currentShop.email + '>'
      $api.post('transactions/send-repairer-mail', { email: $scope.transaction[$scope.transaction.type].email.supplier, shopId: $rootScope.currentShop._id })
        .then(function (result) {
          $scope.save()
        })
        .catch(function (reason) {
          if (reason === 'NOT_VERIFIED') {
            dialogs.error($language.translate('EMAILADDRESS_NOT_VERIFIED'), $language.translate('YOU_CANT_SEND_EMAILS_BEFORE_VERIFYING_YOUR_ADDRESS'), { size: 'sm' })
          } else {
            dialogs.error(undefined, reason)
          }
        })
    }

    $scope.save = function () {
      if($scope.transaction.type === 'repair') {
        if (!$scope.transaction[$scope.transaction.type].email.supplier.status) {
          $scope.transaction[$scope.transaction.type].email.supplier.status = {}
        }
        $scope.transaction[$scope.transaction.type].email.supplier.status = {
          send: true,
          dateSend: new Date()
        }
      }

      $uibModalInstance.close($scope.transaction)
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss()
    }
  }])
