prismanoteApp.controller('sendRawCommandsModalController', ['$scope', '$uibModalInstance', '$rootScope', '$api', '$stateParams', '$uibModal', 'printer', '$label', 'prompt',
function ($scope, $uibModalInstance, $rootScope, $api, $stateParams, $uibModal, printer, $label, prompt) {
  
  $scope.printer = printer

  $scope.disableCommandField = true

  $scope.data = {
    command: null
  }

  $scope.close = function (options) {
    $uibModalInstance.close()
  }

  $scope.prefillCommand = function (command) {
    $scope.data.command = command
  }

  $scope.empty = function () {
    $scope.data.command = null
  }

  $scope.enableCommandField = function() {
    if($scope.disableCommandField) {
      prompt({
				title: "Waarschuwing",
				message: "Het sturen van aangepaste commando kan onvoorziene gevolgen hebben, en we raden u dan ook contact op te nemen met support voor het doorvoeren van wijzigingen."
			  }).then(function (res) {
          console.log(res)
				  if(res.primary && res.primary === true) {

            $scope.disableCommandField = false
            console.log($scope.disableCommandField)

					.catch(function (reason) {
						console.error('Error while closing modal', reason)
					})
				  }
			})
    }
  }

  $scope.send = function (command) {
    $label.printRAW(command)
      .then(function (result) {
        console.log('result', result)
      })
      .catch(function (reason){
        console.error('Error sending command to printer', reason)
        toastr.error(reason)
      })
  }



  }])
