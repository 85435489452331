// PN-4265
var app = prismanoteApp.controller('retailerInventoryCollectionController', ['$stateParams', '$scope', '$rootScope', '$uibModal', '$anchorScroll', '$api', '$q', '$translate', '$retailer', 'prompt', '$language', '$product', '$state', '$shop', '$csv2json', '$collection', '$retailerFilters', '$inventory','scanner',
  function ($stateParams, $scope, $rootScope, $uibModal, $anchorScroll, $api, $q, $translate, $retailer, prompt, $language, $product, $state, $shop, $csv2json, $collection, $retailerFilters, $inventory, scanner) {
    $scope.scannerEnabled = false
    $scope.autoAddProducts = true
    $scope.searchFieldIsFocused = false
    $scope.lastCountedArticle = {}
  
    $scope.renderPage = function (shopId) {
      $scope.countedProducts = [];
      $scope.loading = true;
      getShopCollectionWiseProduct(shopId, $stateParams.collection)
      // Get Shop Collections which have products in the shop

    }

    $scope.disableAutoAddProducts = function () {
      $scope.autoAddProducts = !$scope.autoAddProducts
    }

    $scope.setSearchFieldIsFocused = function(v) {
      $scope.searchFieldIsFocused = v
    }

    $scope.$on('scanner-started', function () {
      $scope.scannerEnabled = true
    })

    $scope.$on('scanner-ready', function (event, args) {
      if(!$scope.searchFieldIsFocused) {
        afterBarcodeScane(String(args.parsedCode));
      } else {
        console.error('searchfield is focused, ng-enter will be used.')
      }
    })

    // Enable the older one code
    function getShopCollectionWiseProduct(shopId, collectionId) {
      $shop.getShopCollectionWiseProduct(shopId, collectionId).then(function (response) {
        $scope.collection = $stateParams.collection || null;
        $scope.collectionName = response.data[0].collection;
        if (response && response.data && response.data.length && response.data[0].collection && response.data[0].collection._id) {
          $scope.products = response.data[0].shopProduct || [];
          $scope.countedProducts = response.data[0].countedProducts || [];
        }
        $scope.loading = false;
        calculateTotalCounts();
      }).catch(function (reason) {
        $scope.loading = false;
      })
    }

    function calculateTotalCounts() {
      var products = angular.copy($scope.products)
      var countedProducts = angular.copy($scope.countedProducts)
      $scope.productsTotalCount = 0;
      $scope.countedProductsTotalCount = 0;
      for(i = 0; i < products.length; i++){
        $scope.productsTotalCount += products[i].inventoryProduct.invProduct.count;
      }
      for(i = 0; i < countedProducts.length; i++){
        $scope.countedProductsTotalCount += countedProducts[i].inventoryProduct.invProduct.count;
      }
    }


    $scope.add = function (product) {
      var index = $scope.products.findIndex(function(p){ return p._id == product._id});
      $scope.loading = true;
      if($scope.products[index] && $scope.products[index].count == undefined){
        $scope.products[index].count = 0
      }
      $scope.products[index].count = $scope.products[index].count + 1;
      $scope.products[index].inventoryProduct.invProduct.count += 1;
      // $scope.products[index].inventoryProduct.invProduct.count = $scope.products[index].count
      // $scope.countedProducts[index].inventoryProduct.invProduct.count = $scope.products[index].count

      toastr.success('Artikel geteld: ' + $scope.products[index].productNumber )

      if ($scope.products[index].count > -1 && $scope.products[index].count >= $scope.products[index].stock) {
        $scope.products[index].valid = true
        $scope.countedProducts.push($scope.products[index])
        $scope.products.splice(index, 1)
        $scope.loading = false;
        $inventory.updateProductCount($rootScope.currentShop._id, $stateParams.collection, product._id, product)
        .then(function (products) {
          getShopCollectionWiseProduct($rootScope.currentShop._id, $stateParams.collection)
        })
        .catch(function (error) {
          console.error('Error update product count 1', error)
        })
      } else {
        $inventory.updateProductCount($rootScope.currentShop._id, $stateParams.collection, product._id, product)
        .then(function (products) {
          getShopCollectionWiseProduct($rootScope.currentShop._id, $stateParams.collection)
        })
        .catch(function (error) {
          console.error('Error update product count 2', error)
        })
        $scope.loading = false;
      }
      if (!$scope.$$phase) {
        $scope.$apply(function () {
          $scope.countedProducts = $scope.countedProducts;
          $scope.products = $scope.products
        })
      } else {
        $scope.countedProducts = $scope.countedProducts;
        $scope.products = $scope.products

      }
      calculateTotalCounts();

    }

    $scope.findProductBasedSearchValue = function (searchValue) {
      $scope.stockLoad = true
      $scope.stockProducts = []
      if (searchValue && searchValue.length > 2) {
        $scope.isPerformingSearch = true
        $scope.searchMode = true
        $shop.searchStockProductsInventory($rootScope.currentShop._id, searchValue)
          .then(function (products) {
            $scope.isPerformingSearch = false
            if(products.length > 0) {
              if(products.length === 1 && $scope.autoAddProducts) { //add to inventory immediately
                $scope.addToInventory(products[0]);
              } else {
                $scope.stockProducts = products
                $scope.totalProducts = $scope.stockProducts.length
                $scope.stockLoad = false
                $scope.showProducts = true
              }
            } else {
              toastr.error('Geen resultaten voor ' + searchValue);
            }
            // $scope.addToInventory($scope.stockProducts[0]);
          })
          .catch(function (error) {
            $scope.isPerformingSearch = false
            console.error('Error search stock products inventory', error)
          })
      } else {
        $scope.searchMode = false
        if ($scope.oldProducts && $scope.oldProducts.length) {
          $scope.stockProducts = $scope.oldProducts
          $scope.stockLoad = false
          // delete $scope.oldProducts;
        }
      }
    }

    $scope.addToInventory = function (product) {
      $scope.showProducts = false;

      var productBody = {
        productId: product.product._id,
        privateLableCollections: product.collections,
        productNumber: product.product.variants[0].productNumber,
        articleNumber: product.shopProduct.articleNumber,
        labelDescription: product.shopProduct.labelDescription,
        purchasePrice: product.shopProduct.purchasePrice,
        sellingPrice: product.shopProduct.price ? Number(product.shopProduct.price * ((product.shopProduct.priceVat / 100) + 1)).toFixed(2) : 0,
        count: 1,
        stock: product.shopProduct.stock
      }
      var body = {
        productBody: productBody,
        countedValue: product.shopProduct.purchasePrice * 1,
        totalCount: 1,
        shopId: $rootScope.currentShop._id
      }

      $inventory.addToInventory(body)
        .then(function (products) {
          $scope.countedProducts = [];
          $scope.lastCountedArticle = body
          toastr.success('Artikel geteld: ' + productBody.productNumber)
          getShopCollectionWiseProduct($rootScope.currentShop._id, $stateParams.collection)
        })
        .catch(function (error) {
          console.error('Error add to inventory', error)
        })
    }

    $scope.closeModal = function () {
      $scope.showProducts = false;
    }

    $scope.minus = function (product) {
      $scope.loading = true;
      var index = $scope.products.findIndex(function(p){ return p._id == product._id});
      if ($scope.products[index].inventoryProduct.invProduct.count > 0) {
        $scope.products[index].inventoryProduct.invProduct.count -= 1;
        $inventory.decreaseProductCount($rootScope.currentShop._id, $stateParams.collection, product._id, product)
        .then(function (products) {
          getShopCollectionWiseProduct($rootScope.currentShop._id, $stateParams.collection)
        })
        .catch(function (error) {
          console.error('Error decrease product count 1', error)
        })
        $scope.products[index].count = $scope.products[index].count - 1;
        $scope.products[index].valid = true;
      }
      $scope.loading = false;
      calculateTotalCounts();
    }

    $scope.minusCountedProduct = function (product) {
      var index = $scope.countedProducts.findIndex(function(p){ return p._id == product._id});
      $scope.countedProducts[index].count = $scope.countedProducts[index].inventoryProduct.invProduct.count - 1;
      $scope.countedProducts[index].inventoryProduct.invProduct.count = $scope.countedProducts[index].count;

      $inventory.decreaseProductCount($rootScope.currentShop._id, $stateParams.collection, $scope.countedProducts[index]._id, $scope.countedProducts[index])
      .then(function (products) {
        getShopCollectionWiseProduct($rootScope.currentShop._id, $stateParams.collection)
      })
      .catch(function (error) {
        console.error('Error decrease product count 2', error)
      })
      $scope.loading = true;
      // $scope.countedProducts[index].count = $scope.countedProducts[index].count - 1;
      if ($scope.countedProducts[index].count < $scope.countedProducts[index].stock) {
        $scope.countedProducts[index].valid = true;
        $scope.products.push(angular.copy($scope.countedProducts[index]));
        $scope.countedProducts.splice(index, 1);
        $scope.loading = false;
      } else {
        $scope.loading = false;
      }
      calculateTotalCounts();
    }

    $scope.addCountedProduct = function (product) {
      var index = $scope.countedProducts.findIndex(function(p){ return p._id == product._id});
      $scope.loading = true;
      if($scope.countedProducts[index] && $scope.countedProducts[index].count == undefined){
        $scope.countedProducts[index].count = 0
      }

      $scope.countedProducts[index].count = $scope.countedProducts[index].inventoryProduct.invProduct.count + 1;

      $scope.countedProducts[index].inventoryProduct.invProduct.count = $scope.countedProducts[index].count
      toastr.success('Artikel geteld: ' + product.productNumber )

      $inventory.updateProductCount($rootScope.currentShop._id, $stateParams.collection, $scope.countedProducts[index]._id, product)
      .then(function (products) {
        getShopCollectionWiseProduct($rootScope.currentShop._id, $stateParams.collection)
      })
      .catch(function (error) {
        console.error('Error update product count', error)
      })
      $scope.loading = true;
      $scope.countedProducts[index].count = JSON.parse(JSON.stringify($scope.countedProducts[index].count + 1));
      $scope.countedProducts[index].valid = true;
      $scope.loading = false;
      if (!$scope.$$phase) {
        $scope.$apply(function () {
          $scope.countedProducts = $scope.countedProducts;
        })
      } else {
        $scope.countedProducts = $scope.countedProducts;
      }
      calculateTotalCounts();

    }

    $scope.changeCountofProduct = function (product) {
      $scope.loading = true;
      var index = $scope.products.findIndex(function(p){ return p._id == product._id});
      if ($scope.products[index].count > -1 && $scope.products[index].count < 10001) {
        if ($scope.products[index].count < $scope.products[index].stock) {
          $scope.loading = false;
        } else {
          $scope.countedProducts.push(JSON.parse(JSON.stringify($scope.products[index])));
          $scope.products.splice(index, 1);
          $scope.loading = false;
        }
      } else {
        $scope.loading = false;
      }
    }

    $scope.changeCountedProductCount = function (index) {
      $scope.loading = true;
      if ($scope.countedProducts[index].count < $scope.countedProducts[index].stock) {
        $scope.products.push(JSON.parse(JSON.stringify($scope.countedProducts[index])));
        $scope.countedProducts.splice(index, 1);
      } else {
        $scope.countedProducts[index].valid = true;
      }
      $scope.loading = false;
    }

    $scope.applyNewStock = function () {
      prompt({
				title: 'Weet u zeker het zeker?',
				message: 'Weet u zeker dat u de voorraad van deze producten wilt wijzigen naar het aantal dat in de kolom "Getelde voorraad" staat?\nLet op: alle niet getelde producten worden op voorraad 0 gezet.'
			  }).then(function (res) {
				  if(res.primary && res.primary === true) {
            $scope.loading = true;
            $scope.validNewStock = true;
            $scope.errorMessage = false;
            var countedProductsLength = $scope.countedProducts.length || 0;
            for (var index = 0; index < countedProductsLength; index++) {
              if ($scope.countedProducts[index].count == undefined) $scope.validNewStock = false;
            }
      
            var productsLength = $scope.products.length || 0;
            for (var index = 0; index < productsLength; index++) {
              if ($scope.products[index].count == undefined) $scope.validNewStock = false;
            }
      
            if ($scope.validNewStock) {
              applyNewStockInShop($scope.products, $scope.countedProducts);
            } else {
              $scope.errorMessage = true; $scope.loading = true;
              $scope.validNewStock = true;
              $scope.errorMessage = false;
              var countedProductsLength = $scope.countedProducts.length || 0;
              for (var index = 0; index < countedProductsLength; index++) {
                if ($scope.countedProducts[index].count == undefined) $scope.validNewStock = false;
              }
        
              var productsLength = $scope.products.length || 0;
              for (var index = 0; index < productsLength; index++) {
                if ($scope.products[index].count == undefined) $scope.validNewStock = false;
              }
        
              if ($scope.validNewStock) {
                applyNewStockInShop($scope.products, $scope.countedProducts);
              } else {
                $scope.errorMessage = true;
                $scope.loading = false;
              }
              $scope.loading = false;
            }
				  }
			})
    }

    $scope.removeProduct = function (product) {
      var index = $scope.products.findIndex(function (p) { return p._id == product._id });
      $scope.products.splice(index, 1);
    }

    function applyNewStockInShop(unCountedProducts, countedProducts) {
      var dataForReq = {};
      dataForReq.shopId = $rootScope.currentShop._id
      dataForReq.unCountedProducts = unCountedProducts || []
      dataForReq.countedProducts = countedProducts || []
      dataForReq.collectionId = $stateParams.collection
      dataForReq.reason = 'Inventory'
      // Have to do api call now with this data

      // $shop.applyNewStockInShopBasedOnCollection(dataForReq)
      $inventory.applyNewStockInShopBasedOnCollection(dataForReq)
        .then(function (success) {
          $scope.loading = false;
          $state.go('retailer.inventories')
        })
        .catch(function (error) {
          $scope.loading = false;
        })
    }

    // $scope.findProductBasedSearchValue = function(search){
    //   $scope.showProducts = true;
    // }

    // var barcode = false
    // var mobileBarcode = false
    // var scannedBarcode = ''

    // function KeyPress(e) {
    //   var evtobj = e || window.event
    //   if ($rootScope.mobile || $rootScope.tablet) {
    //     if (evtobj.keyCode == 0 || evtobj.keyCode == 27) {
    //       scannedBarcode = ''
    //       mobileBarcode = true
    //       barcode = true
    //       return
    //     }
    //   }

    //   if (mobileBarcode && (evtobj.keyCode == 48 || evtobj.keyCode == 50 || evtobj.keyCode == 0 || evtobj.keyCode == 27)) {
    //     return
    //   }

    //   if ((evtobj.keyCode == 66 && evtobj.ctrlKey) || (evtobj.altKey && (evtobj.keyCode == 96 || evtobj.keyCode == 98))) {
    //     scannedBarcode = ''
    //     evtobj.stopPropagation()
    //     evtobj.preventDefault()
    //     evtobj.cancelBubble = true
    //     barcode = true
    //     return
    //   }

    //   if (barcode) {
    //     if (evtobj.keyCode == 13) {
    //       barcode = false
    //       evtobj.cancelBubble = false
    //       afterBarcodeScane(scannedBarcode);
    //       return

    //     } else {
    //       if (evtobj.keyCode === 173 || evtobj.keyCode === 189) {
    //         var fromChar = "-"
    //       } else {
    //         var fromChar = String.fromCharCode(evtobj.keyCode);
    //       }
    //       scannedBarcode = scannedBarcode + fromChar

    //     }
    //   }
    // }

    // document.onkeydown = KeyPress
    // #endregion Barcode support
    var findProductBasedSearchValue = ""
    function afterBarcodeScane(scannedBarcode) {

      if(!Boolean(String(scannedBarcode).charAt(0).match(/^[A-Za-z0-9]*$/))) {
        scannedBarcode = scannedBarcode.substring(1);
      }

      $scope.showProducts = false
      var index = {};

      index['ProductEan'] = _.findIndex($scope.products, function (o) {
        return o.ean == scannedBarcode;
      });

      index['ProductArticleNumber'] = _.findIndex($scope.products, function (o) {
        return o.articleNumber == scannedBarcode;
      });

      index['CountedProductEan'] = _.findIndex($scope.countedProducts, function (o) {
        return o.ean == scannedBarcode;
      });

      index['CountedProductArticleNumber'] = _.findIndex($scope.countedProducts, function (o) {
        return o.articleNumber == scannedBarcode
      });
      if (index.ProductEan > -1) {
        $scope.add($scope.products[index.ProductEan])
      } else if (index.ProductArticleNumber > -1) {
        $scope.add($scope.products[index.ProductArticleNumber])
      } else if (index.CountedProductEan > -1) {
        $scope.addCountedProduct($scope.countedProducts[index.CountedProductEan])
      } else if (index.CountedProductArticleNumber > -1) {
        $scope.addCountedProduct($scope.countedProducts[index.CountedProductArticleNumber])
      } else {
          $scope.findProductBasedSearchValue(scannedBarcode)
          $('html, body').animate({ scrollTop: 0 }, 'fast');
          toastr.warning("This product is not in this collection. We searched for " + scannedBarcode + " please select the best result. You can also find search this product in the assortment page.");

        // Have here possible scan productNumber 
        // Product is in shop then retailer can change the private collection of the product 
      }
    }
  }
])
app.filter('reverse', function () {
  return function (items) {
    return items.slice().reverse();
  };
});