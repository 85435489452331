prismanoteApp.controller('retailerUserController', ['$scope', '$rootScope', '$api', '$uibModal', '$language', '$employee', '$state', '$user',
  function ($scope, $rootScope, $api, $uibModal, $language, $employee, $state, $user) {
    $scope.formData = {
      form: {}
    }

    $scope.passwordChanged = false
    $scope.localdata = {
      otp: ''
    }
    $scope.updateLoading = false
    $scope.afterClick = false
    $scope.loadUser = function (isFromUpdate) {
      if ($state.params && $state.params.userId) {
        getUser($state.params.userId, isFromUpdate)
        $scope.paramsUserId = $state.params.userId
      } else {
        getUser($rootScope.user._id, isFromUpdate)
      }
    }

    function getUser(userId, isFromUpdate) {
      $rootScope.timestamp = new Date().getTime()
      $api.get('profile/' + userId, { shopId: $rootScope.currentShop._id }, null, 2).then(function (response) {
        $scope.currentuser = response.data.user
        $scope.loadedEmail = response.data.user.email;
        $scope.currentuser.pin2 = $scope.currentuser.pincode
        if (!$scope.currentuser.function) $scope.currentuser.function = 'sales'
        if ($scope.currentuser.dateOfBirth) $scope.currentuser.dateOfBirth = new Date($scope.currentuser.dateOfBirth)

        if (isFromUpdate) $employee.changeRootScopeUserDetailOREmployeeDetail($scope.currentuser) // Changeing $rootScope data with updated one

        if ($scope.currentuser.pincode && $scope.currentuser && !isFromUpdate && ($scope.currentuser.admin || !$scope.currentuser.isEmployee)) {
          var body = {
            originalPin: $scope.currentuser.pincode,
            confirmPin: '',
            type: 'retailer'
          }
          $employee.openConfirmPinModal(body).then(function (modalres) {
          }).catch(function (modalerr) {
          })
        }
      }).catch(function (reason) {
      })
    }

    function getEmployees() {
      $employee.getEmployees(true)
        .then(function (list) {
        }).catch(function (reason) {
          console.error(reason)
        })
    }

    $scope.updateUser = function () {
      $scope.isEmailSent = false;
      $scope.otp = '';
      var isRightsSelected = false
      for (var i = 0; i < $scope.currentuser.shopRights.length; i++) {
        if ($scope.currentuser.shopRights[i].enabled) {
          isRightsSelected = true
          break
        }
      }

      if (isRightsSelected || $scope.currentuser.admin || !$scope.currentuser.isEmployee) {
        if ($scope.currentuser.lastEmail !== $scope.currentuser.email) { // Because for employee email address is not required, but once they add, they can not change
          $scope.currentuser.isAddingEmail = true // This condition is checking that email address must be an unique
        }
        if (!$scope.currentuser.enabled && ($scope.currentuser.enabled !== $scope.currentuser.lastEnabled)) {
          $employee.removeEmployeeFromLocalStorage($scope.currentuser)
            .catch(function (catherr) {
              console.error(catherr)
            })
        }
        var userId = $scope.paramsUserId || $rootScope.user._id
        if ($scope.currentuser.email !== $scope.loadedEmail) {
          var emailBody = {
            email: $scope.currentuser.email,
            language: $rootScope.language,
            userId: $scope.currentuser._id,
            shopId: $rootScope.currentShop._id
          }
          $user.emailVerificationOTP(emailBody).then(function (response) {
            $scope.isEmailSent = true;
          }).catch(function (error) {
            console.error('Error: emailVerificationOTP: ', error);
          });
          return;
        }
        // return;
        $scope.alert = null
        $scope.updateLoading = true
        $scope.afterClick = true
        $api.put('user/' + userId, { user: $scope.currentuser })
          .then(function (response) {
            if ($scope.currentuser.isAddingEmail) {
              $scope.currentuser.lastEmail = $scope.currentuser.email
            }
            $scope.updateLoading = false
            $scope.afterClick = false
            $employee.updateLocalStorage($scope.currentuser)
            getEmployees();
            $scope.loadUser(true)
            $scope.alert = {
              type: 'success',
              msg: $language.translate('PROFILE_SUCCESSFULLY_SAVED')
            }

          })

          .catch(function (reason) {
            $scope.updateLoading = false
            $scope.afterClick = false
            $scope.alert = {
              type: 'danger',
              msg: reason && reason.data && reason.data.message ? reason.data.message : 'Internal Server Error'
            }
          })
      } else {
        $scope.updateLoading = false
        $scope.afterClick = false
        $scope.alert = {
          type: 'danger',
          msg: $language.translate('SELECT_AT_LEAST_ONE_SHOPRIGHT')
        }
      }
    }

    $scope.updateEmail = function () {
      var userId = $scope.paramsUserId || $rootScope.user._id
      $api.put('user/' + userId, {
        user: $scope.currentuser,
        isEmailChanged: true,
        verificationCode: $scope.localdata.otp
      })
        .then(function (response) {
          if ($scope.currentuser.isAddingEmail) {
            $scope.currentuser.lastEmail = $scope.currentuser.email
          }
          $scope.updateLoading = false
          $scope.afterClick = false
          $scope.isEmailSent = false;
          $scope.alert = {
            type: 'success',
            msg: $language.translate('PROFILE_SUCCESSFULLY_SAVED')
          }
          $employee.updateLocalStorage($scope.currentuser)
          $scope.loadUser(true)
        })

        .catch(function (reason) {
          $scope.updateLoading = false
          $scope.afterClick = false
          $scope.alert = {
            type: 'danger',
            msg: reason && reason.data && reason.data.message ? reason.data.message : 'Internal Server Error'
          }
        })
    }

    $scope.openCropImageModal = function () {
      if (!$scope.currentuser.profileImage) $scope.currentuser.profileImage = ''
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/image-crop-modal.html',
        controller: 'cropImageModalController',
        keyboard: false,
        backdrop: 'static',
        resolve: {
          defaultImage: function () {
            return $scope.currentuser.profileImage.indexOf('https://ui-avatars.com/api') == 0
          },
          loadImage: function(){
            return undefined;
          },
          areaType: function () {
            return 'circle';
          }
        }
      })
      modalInstance.result.then(function (result) {
        if (result == '') {
          // User choosen to delete his photo;
          $scope.currentuser.profileImage = ''
          $scope.updateUser()
          return
        }
        var userId = $scope.paramsUserId || $rootScope.user._id
        $api.post('profile/' + userId + '/image', { file: result, shopId: $rootScope.currentShop._id }, null, 2)
          .then(function (result) {
            $scope.loadUser(true)
          })
          .catch(function (reason) {
            console.error(reason)
          })
      }, function () {
        // dismissed
      })
    }

    $scope.openChangePasswordModel = function () {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/self-change-password-modal.html',
        controller: 'selfChangePasswordModalController'
      })
      modalInstance.result.then(function (result) {
        $scope.passwordChanged = true
      }, function () {
        // dismissed
      })
    }

    $scope.pinRequired = function () {
      $scope.currentuser.pincode = ''
      $scope.currentuser.pin2 = ''
    }

    $scope.rightsChanged = function (right) {
      if(!right) {
        return
      }
      var requiredRightsForServiceApp = [
        'MANAGE_CUSTOMERS',
        // 'MANAGE_SUPPLIERS',
        'MANAGE_TRANSACTIONS_OFFERS',
        'MANAGE_REPAIRS_SPECIAL',
        'MANAGE_WEBSHOP_ORDER',
        //'MANAGE_DAYSTATE'
      ]

      if(right.name === 'USE_SERVICE_APP' && right.enabled) {
        for(var i =0; i < requiredRightsForServiceApp.length; i++) {
          var idx = _.findIndex($scope.currentuser.shopRights, {name: requiredRightsForServiceApp[i]})
          if(idx >= 0) {
            $scope.currentuser.shopRights[idx].enabled = true
          }
        }
      }else if(requiredRightsForServiceApp.indexOf(right.name) >= 0 && right.enabled === false) {
        var idx = _.findIndex($scope.currentuser.shopRights, {name: 'USE_SERVICE_APP'})
        if(idx >= 0) {
          $scope.currentuser.shopRights[idx].enabled = false
        }
      }
    }
  }])
