prismanoteApp.controller('sendModuleMailController', ['$scope', '$uibModalInstance', '$language', '$api', 'shopId',
  function ($scope, $uibModalInstance, $language, $api, shopId) {
    $scope.reason = {
      reason: 'AT_OWN_REQUEST',
      otherReason: '',
      rawReason: 'AT_OWN_REQUEST'
    }

    $scope.sendMail = function () {
      $scope.reason.rawReason = angular.copy($scope.reason.reason)
      var translatedReason = $language.translate($scope.reason.reason)
      $scope.reason.reason = translatedReason

      $api.post('complete-upgrade', {
        shopId: shopId,
        reason: $scope.reason
      })
        .then(function (response) {
          $scope.close()
        })
        .catch(function (reason) {
          $scope.error = {
            type: 'danger',
            msg: reason
          }
        })
    }

    $scope.onlySave = function () {
      $scope.close()
    }

    $scope.close = function () {
      $uibModalInstance.close()
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }
  }])
