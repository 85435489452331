prismanoteApp.controller('brandBrandsController', ['$scope', '$rootScope', '$api', '$stateParams', '$uibModal', '$state', 'prompt', 'Upload',
  function ($scope, $rootScope, $api, $stateParams, $uibModal, $state, prompt, Upload) {
    $scope.loading = true
    $scope.totalDisplayed = 20
    $scope.upload = false

    $scope.getShopList = function () {
      // $api.get('brands')
      $api.get('brands?getAll=true')
        .then(function (response) {
          $scope.loading = false
          $scope.shops = response.data.shops
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.resetSelected = function () {
      $scope.currentSocialMedia = null
      $scope.currentHome = null
      $scope.currentSlide = null
    }

    $scope.loadMore = function () {
      $scope.totalDisplayed += 20
    }

    $scope.loadBrand = function () {
      $api.get('brands/' + $stateParams.nameSlug + '?shopId=' + $rootScope.currentShop._id + '&companyId' + $rootScope.currentCompany._id)

        .then(function (response) {
          $scope.brand = response.data.brand
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.updateBrand = function (redirect) {
      console.log('[updateBrand controller]')
      console.log('$stateParams.nameSlug', $stateParams.nameSlug)
      console.log('$scope.brand', $scope.brand)
      $api.put('brands/' + $stateParams.nameSlug, { brand: $scope.brand, shopId: $rootScope.currentShop._id, companyId: $rootScope.currentCompany._id })

        .then(function () {
          if (redirect) {
            $state.go('admin.users')
          }
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.openCreateBrandModal = function () {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/create-brand-modal.html',
        controller: 'createBrandModalController',
        size: 'lg'
      })

      modalInstance.result.then(function (result) {
        console.log('MODALRESULT', result)

        for (var i = result.length - 1; i >= 0; i--) {
          if (array[i] === tasks) {
            array.splice(i, 1)
          }
        }

        result.nameSlug = $rootScope.slugify(result.name)

        result.isPublished = false

        result.address

        $api.post('brands', { brand: result })

          .then(function (response) {
            $state.go('admin.brands')
          })

          .catch(function (reason) {
            console.log(reason)
          })
      }, function () {

      })
    }

    $scope.imageUpload = function (index) {
      console.log($scope.brand)
      console.log('$scope.brand.imageThird', $scope.brand.imageThird)

      var brandImage = $scope.brand.images[index].imageFirst

      // if(orderNumber == "first"){
      //     var brandImage = $scope.brand.imageFirst
      // }else if(orderNumber == "second"){
      //     var brandImage = $scope.brand.imageSecond
      // }else{
      //     var brandImage = $scope.brand.imageThird
      // }
      console.log('brandImage', brandImage)
      if (brandImage) {
        $scope.upload = true
        Upload.upload({
          url: 'api/brand/uploadimage',
          data: {
            brandId: $scope.brand._id,
            file: brandImage
          }
        })
          .then(function (res) {
            console.log('res.data.file', res.data.file)
            image = {
              src: res.data.file,
              alt: brandImage.name
            }
            console.log('image', image)
            $scope.brand.images.splice(index, 1, image)
            console.log('$scope.brand.images', $scope.brand.images)
            brandImage = null
            $scope.upload = false
            $scope.updateBrand(false)
          }, function (res) {
            console.log('Error: ', res)
          }, function (evt) {
            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total)
            $scope.uploadProgress = progressPercentage
            console.log('Progress: ' + progressPercentage + '%' + evt.config.data)
          })
      } else {
        $scope.updateBrand(false)
      }
    }

    $scope.addImage = function () {
      var newImage = {
        src: '',
        alt: ''
      }
      $scope.brand.images.push(newImage)
    }

    $scope.deleteImage = function (index) {
      $scope.brand.images.splice(index, 1)
    }
  }])
