prismanoteApp.controller('socialPortalsController', ['$scope', '$rootScope', '$api', '$stateParams', '$uibModal', '$state', 'prompt',
  function ($scope, $rootScope, $api, $stateParams, $uibModal, $state, prompt) {
    $scope.loading = true
    $scope.totalDisplayed = 200
    // console.log("hello social portals controller");

    $scope.getSocialPortalsList = function () {
      $api.get('social-portals')

        .then(function (response) {
          $scope.loading = false
          console.log('response.data', response.data)
          $scope.socialPortals = response.data.socialPortals
        })

        .catch(function (reason) {
          console.log('catch error', reason)
        })
    }

    $scope.loadMore = function () {
      $scope.totalDisplayed += 200
    }

    $scope.getCampaigns = function () {
      var numberOfCampaigns = 0
      apiOptions = []
      apiOptions.offset = numberOfCampaigns
      apiOptions.limit = 100

      $scope.updates = []
      $scope.campaigns = []

      $api.get('campaigns', apiOptions)

        .then(function (response) {
          // Add campaigns to the $scope.
          // console.log("response campaigns", response);
          var today = new Date().getTime()

          // console.log("today", today);
          var counter = 0
          for (var i = 0; i < response.data.campaigns.length; i++) {
            var campaign = response.data.campaigns[i]

            if (campaign.update == true && new Date(campaign.endDate).getTime() > today) {
              $scope.updates.push(campaign)
            } else {
              $scope.campaigns.push(campaign)
            }
            counter++
            if (counter == response.data.campaigns.length) {
              $scope.getUpdatesOfSocialPortalsBrands()
            }
          }
          // console.log("$scope.updates WAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAh", $scope.updates);
        })
        .catch(function (reason) {
          console.log('catch error', reason)
        })
    }

    $scope.getUpdatesOfSocialPortalsBrands = function () {
      $api.get('social-portals')

        .then(function (response) {
          $scope.loading = false
          $scope.socialPortals = response.data.socialPortals

          var brandList = []
          for (var i = 0; i < $scope.updates.length; i++) {
            // console.log($scope.updates[i].brand.name);
            brandList.push($scope.updates[i].brand.name)
          }

          var updatesCounted = {}
          brandList.forEach(function (x) { updatesCounted[x] = (updatesCounted[x] || 0) + 1 })

          // console.log("updatesCounted", updatesCounted);

          // add brandlist to scope to use this var in matchUpdatesOfSocialPortalsBrandsWithGeneralUpdates()
          $scope.updatesCounted = updatesCounted

          for (var i = 0; i < $scope.socialPortals.length; i++) {
            if (!$scope.socialPortals[i].amountOfUpdates) {
              $scope.socialPortals[i].amountOfUpdates = 0
            }

            for (var j = 0; j < $scope.socialPortals[i].brands.length; j++) {
              var searchTerm = $scope.socialPortals[i].brands[j].name
              var index = -1

              // console.log("updatesCounted", updatesCounted);
              // console.log(Object.getOwnPropertyNames(updatesCounted));

              var arrayOfObjectNames = Object.getOwnPropertyNames(updatesCounted)
              for (var k = 0, len = arrayOfObjectNames.length; k < len; k++) {
                // console.log("we zoeken naar searchTerm", searchTerm);
                // console.log("arrayOfObjectNames[k]", arrayOfObjectNames[k]);
                // console.log("aantal updates [k]", Object.values(updatesCounted)[k]);
                if (arrayOfObjectNames[k] === searchTerm) {
                  index = k
                  var addAmount = Object.values(updatesCounted)[k]
                  // console.log("addAmount", addAmount);
                  // console.log("voor de update status $scope.socialPortals[i].amountOfUpdates", $scope.socialPortals[i].amountOfUpdates);
                  currentAmount = $scope.socialPortals[i].amountOfUpdates
                  $scope.socialPortals[i].amountOfUpdates = currentAmount + addAmount
                  // console.log("Gevonden en Geupdated!", $scope.socialPortals[i].amountOfUpdates);

                  break
                }
              }
            }
          }

          $scope.socialPortals.forEach(function (element) {
            element.users.forEach(function (val) {
              if (val.name.indexOf('undefined') != -1) {
                var str = val.name.replace('undefined', '')
                val.name = str
              }
            })
          })
        })
        .catch(function (reason) {
          console.log('catch error', reason)
        })
    }

    $scope.matchUpdatesOfSocialPortalsBrandsWithGeneralUpdates = function () {
      // create list of brands and how many brands are found
      var brandListPortals = []
      for (var i = 0; i < $scope.socialPortals.length; i++) {
        for (var j = 0; j < $scope.socialPortals[i].brands.length; j++) {
          brandListPortals.push($scope.socialPortals[i].brands[j].name)
        }
      }

      // console.log("brandListPortals", brandListPortals);

      var brandsCounted = {}
      brandListPortals.forEach(function (x) { brandsCounted[x] = (brandsCounted[x] || 0) + 1 })

      // console.log("brandsCounted", brandsCounted);
      var brandsCountedList = Object.getOwnPropertyNames(brandsCounted)
      // console.log("brandsCountedList", brandsCountedList);

      var brandListGeneral = Object.getOwnPropertyNames($scope.updatesCounted)
      // console.log("brandListGeneral", brandListGeneral);

      var newBrandListPortals = []

      for (var k = 0; k < brandListGeneral.length; k++) {
        // console.log("brandListGeneral[k]", brandListGeneral[k]);

        for (var l = 0; l < brandsCountedList.length; l++) {
          if (brandListGeneral[k] === brandsCountedList[l]) {
            newBrandListPortals.push(brandListGeneral[k])

            break
          }
        }
      }
      // console.log("newBrandListPortals", newBrandListPortals);

      for (var m = 0; m < newBrandListPortals.length; m++) {
        var search_term = newBrandListPortals[m]

        var index = brandsCountedList.indexOf(search_term) // <-- Not supported in <IE9
        if (index !== -1) {
          brandsCountedList.splice(index, 1)
        }
      }
      // console.log("brandsCountedList zonder Prisma, Cartier en Zinzi", brandsCountedList);

      var brandsCountedNames = Object.getOwnPropertyNames(brandsCounted)
      var brandsCountedValues = Object.values(brandsCounted)
      $scope.topListBrands = []

      for (var n = 0; n < brandsCountedList.length; n++) {
        // console.log("brandsCountedList[n]", brandsCountedList[n]);

        for (var p = 0; p < brandsCountedNames.length; p++) {
          if (brandsCountedList[n] === brandsCountedNames[p]) {
            // console.log("brandsCountedList[n]", brandsCountedList[n]);
            // console.log("brandsCountedNames[p]", brandsCountedNames[p]);
            // console.log("brandsCountedList", brandsCountedList);
            var newBrand = {
              name: brandsCountedNames[p],
              amount: brandsCountedValues[p]
            }
            $scope.topListBrands.push(newBrand)
          }
        }
      }

      // console.log("$scope.topListBrands", $scope.topListBrands);
    }
  }])
