prismanoteApp.controller("addBrandsModalController", ['$scope', '$uibModalInstance', '$rootScope', '$api', '$stateParams', '$uibModal', '$state', 'prompt', 'Upload', 'data',
    function ($scope, $uibModalInstance, $rootScope, $api, $stateParams, $uibModal, $state, prompt, Upload, data) {
        $scope.dropAvailable = false;
        // $scope.brand = {};
        $scope.brand = {
            en: {
                name: ''
            },
            nl: {
                name: ''
            },
            de: {
                name: ''
            },
            fr: {
                name: ''
            },
            es: {
                name: ''
            },
            website: 'http://'
        }
        $scope.brandsName = [];
        $scope.brand.images = [];
        var flag = 0;
        $scope.kv = {};
        assignNewBrandError = false;
        $scope.enableImageFunction = true;
        $scope.tempName = '';

        $scope.user = $rootScope.user;
        $scope.language = $rootScope.language;

        $scope.close = function (options) {
            $uibModalInstance.close(options);
        }

        $scope.cancel = function (reason) {
            $uibModalInstance.dismiss(reason);
        }

        $scope.loading = true;
        $scope.totalDisplayed = 20;
        $scope.upload = false;

        $scope.getBrandList = function () {
            // $api.get('brands')
            $api.get('brands?getAll=true')
                .then(function (response) {
                    $scope.loading = false;
                    $scope.brands = response.data.brands;
                })

                .catch(function (reason) {
                    console.error("Error while getting brands", reason);
                });
        }

        $scope.addBrand = function (brand) {
            if (flag == 0) {
                $scope.brand.manufacturer = $rootScope.currentShop.name;
                $scope.brand.shopId = $rootScope.currentShop._id;
                $scope.brand.privateLabel = true;

                $api.post('addBrands', { brand: $scope.brand, shopId: $rootScope.currentShop._id})
                    .then(function (response) {
                        console.log('Brand Added Successfully: ', response);
                        $scope.assignNewBrandError = false;
                        $scope.kv = response.data.brand;
                        $scope.enableImageFunction = false;
                        $uibModalInstance.close({ status: 'addBrandToShop', brand: $scope.kv });
                    })
                    .catch(function (err) {
                        $scope.errorLog = err.data.error;
                        console.log('Error while adding new brand: ', err);
                        if (err == 'Please add atleast EN language name') {
                            $scope.assignNewBrandError = true;
                            $scope.errorMsg = "Please, add atleast one language name";
                        } else if (err == "Brand is already exists") {
                            $scope.assignNewBrandError = true;
                            $scope.errorMsg = "Brand's name is already exists";
                        }
                    });
            }
      }
    // $scope.updateBrand = function (addBrandToShop) {
    //   $scope.brand._id = $scope.kv._id
    //   var images = $scope.kv.images.filter(function (i) {
    //     return i.src
    //   })
    //   $scope.brand.images = images
    //   $scope.kv.images = images

    //   $api.put('brands/' + $stateParams.nameSlug, { brand: $scope.kv, shopId: $rootScope.currentShop._id, companyId: $rootScope.currentCompany._id  })
    //     .then(function (res) {
    //       if (res.status === 200) {
    //         if (addBrandToShop) {
    //           $uibModalInstance.close({ status: addBrandToShop, brand: $scope.kv })
    //         } else {
    //           $uibModalInstance.dismiss()
    //         }
    //       }
    //     }, function (err) {
    //       console.error('Error while updating brand', err)
    //     })
    // }

    $scope.addImage = function (image) {
      var newImage = {
        src: '',
        alt: ''
      }
      $scope.brand.images.push(newImage)
      $scope.kv.images.push(newImage)
    }

    $scope.deleteImage = function (index) {
      $scope.brand.images.splice(index, 1)
    }

    $scope.changeBrandName = function (value) {
      $scope.errorMessage = false
      $scope.errorLog = {
        en: false,
        nl: false,
        de: false,
        fr: false,
        es: false
      }
      $scope.brand.en.name = value
      $scope.brand.de.name = value
      $scope.brand.nl.name = value
      $scope.brand.fr.name = value
      $scope.brand.es.name = value
    }
  }])
