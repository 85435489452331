prismanoteApp.controller('brandWebshopOrderDetailsController', ['$q', 'Lightbox', '$state', '$filter', '$pdf', '$stateParams', '$scope', '$rootScope', '$uibModal', '$api', '$language', '$http', 'FileSaver', 'Blob', '$retailer', 'prompt', 'campaignFact',
  function ($q, Lightbox, $state, $filter, $pdf, $stateParams, $scope, $rootScope, $uibModal, $api, $language, $http, FileSaver, Blob, $retailer, prompt, campaignFact) {
    $scope.transaction = {}
    $scope.errorMessage = ''
    $scope.loading = true
    $scope.orderStatusIndication = 0
    $scope.availableOptionsForUpdateStatus = []
    $scope.isValidForUpdateStaus = false
    $scope.refundAmount = 0
    $scope.totalVAT = 0
    $scope.shippingTrackURL = ''
    $scope.initial = true

    $scope.statusNameForRetailer = {
      pending_payment: $language.translate('PENDING_PAYMENT'),
      canceled: $language.translate('CANCELLED'),
      cancelled: $language.translate('CANCELLED'),
      Cancelled: $language.translate('CANCELLED'),
      refund: $language.translate('REFUNDED'),
      partial_refund: $language.translate('PARTIAL_REFUNDED')
    }

    $scope.notValidStatusForUpdate = ['canceled', 'cancelled', 'Cancelled', 'refund']

    // Transaction status
    $scope.transactionsStatusOption = [
      {
        value: 'pending_payment',
        text: $language.translate('PENDING_PAYMENTS')
      },
      {
        value: 'new',
        text: $language.translate('NEW')
      },
      {
        value: 'cancelled',
        text: $language.translate('CANCELLED')
      },
      {
        value: 'completed',
        text: $language.translate('COMPLETED')
      },
      {
        value: 'refund',
        text: $language.translate('REFUND')
      }
    ]

    // Fetch transaction details
    $scope.getTransactionDetails = function () {
      $scope.loading = true

      // Here is bug i guess not sure about that. But when first time page is load at that time we not get rootScopt.currentShop. So i add below condition. Don't hesitate when you see :D
      if ($rootScope.currentCompany && $rootScope.currentCompany._id) {
        $api.get('brand/webshop-transaction/' + $stateParams.transactionId + '/' + $rootScope.currentCompany._id)
          .then(function (response) {
            if (response && response.data && response.data.result && response.data.result.transaction) {
              $scope.transaction = response.data.result.transaction
              $scope.refundAmount = $scope.transaction.orderTotal
            } else {
              $scope.errorMessage = response.data.message
            }

            $scope.initial = true
            $scope.checkOrderStatusIndication($scope.transaction.status)
            $scope.checkForAvailableStatus($scope.transaction.status)

            $scope.loading = false
          })
          .catch(function (reason) {
            $scope.errorMessage = reason
            $scope.loading = false
          })
      }
    }

    // Check status indication index for retailer to this particular transaction
    $scope.checkOrderStatusIndication = function (status) {
      console.log('status into checkOrderStatusIndication', status)
      if (status === 'pending_payment') {
        $scope.orderStatusIndication = 1
      } else if (status === 'new') {
        $scope.orderStatusIndication = 2
      } else if (status === 'completed') {
        $scope.orderStatusIndication = 3
      } else if (status === 'refund') {
        $scope.orderStatusIndication = 4
      } else if (status === 'canceled') {
        $scope.orderStatusIndication = 5
      } else {
        $scope.orderStatusIndication = 0
      }
    }

    // Show image into lightbox
    $scope.openLightbox = function (images) {
      Lightbox.openModal(images, 0)
    }

    // Get discount per item
    $scope.getItemDiscount = function (item) {
      if (item.discount) {
        if (item.discountPercent) {
          return item.discountValue + '%'
        } else {
          return $filter('currency')(item.discountValue, '€')
        }
      } else {

      }
    }

    // Get available options for the retailer to update this particular transaction's status
    $scope.checkForAvailableStatus = function (status) {
      if (status === 'pending_payment' || status === 'refund' || status === 'canceled' || status === 'partial_refund') {
        $scope.availableOptionsForUpdateStatus = []
      }

      if (status === 'new') {
        $scope.availableOptionsForUpdateStatus = [
          {
            value: 'new',
            text: $language.translate('NEW'),
            disabled: true
          },
          {
            value: 'completing',
            text: $language.translate('COMPLETED')
          },
          {
            value: 'refunding',
            text: $language.translate('REFUND')
          }
        ]
      }

      if (status === 'completed') {
        $scope.transaction.status = 'completing'
        $scope.availableOptionsForUpdateStatus = [
          {
            value: 'new',
            text: $language.translate('NEW')
          },
          {
            value: 'completing',
            text: $language.translate('COMPLETED'),
            disabled: true
          },
          {
            value: 'refunding',
            text: $language.translate('REFUND')
          }
        ]
      }
      console.log('$scope.availableOptionsForUpdateStatus', $scope.availableOptionsForUpdateStatus)
    }

    // check for validitity to update status
    $scope.checkForValidityToUpdateStatus = function (status) {
      if ($scope.notValidStatusForUpdate.includes(status) === false) {
        $scope.isValidForUpdateStaus = true
        $scope.checkForAvailableStatus(status)
      }
    }

    // Update status of transaction
    $scope.updateTransactionStatus = function (status) {
      $scope.initial = false
      var shopId = $scope.transaction.shopDetails._id
      if (status === 'new') {
        // We directly need to call api for update status
        $api.put('webshop-transaction/status', {
          transactionId: $stateParams.transactionId,
          shopId: shopId,
          status: status
        })
          .then(function (response) {
            $scope.checkOrderStatusIndication(status)
            $scope.checkForAvailableStatus(status)
          })
          .catch(function (error) {
            console.log('error', error)
            $scope.errorMessage = error.data.message
          })
      } else if (status === 'refunding') {
        console.log('Status is refunding')
        return $scope.submitForRefund()
      } else if (status === 'completing') {

      }
    }

    // Refund amount
    $scope.submitForRefund = function () {
      return $q(function (resolve, reject) {
        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/webshop-refund-modal.html',
          controller: 'webshopRefundModalController',
          size: 'xlg',
          resolve: {
            transactionDetails: function () {
              return $scope.transaction
            }
          }
        })

        modalInstance.result
          .then(function (response) {
            console.log('Opened', response)
            $scope.getTransactionDetails()
          })
          .catch(function (reason) {
            $scope.getTransactionDetails()
          })
      })
      /* $scope.errorMessage = "";
            var needToStockPutBack = confirm("Do you want to stock back?");

            if (refundAmount > $scope.transaction.orderTotal) {
                $scope.errorMessage = "Sorry you can't refund more that transaction total. Transaction total is: " + $scope.transaction.orderTotal;
            } else {
                // We directly need to call api for update status
                $api.put('webshop-transaction/status', {
                    transactionId: $stateParams.transactionId,
                    shopId: $rootScope.currentShop._id,
                    status: 'refund',
                    refundAmount: refundAmount,
                    stockBack: needToStockPutBack
                })
                    .then(function (response) {
                        $scope.checkOrderStatusIndication('refund');
                        $scope.checkForAvailableStatus('refund');
                        $scope.transaction.status = 'refund';
                    })
                    .catch(function (error) {
                        console.log("error", error);
                        $scope.errorMessage = error.data.message;
                    })
            } */
    }

    // Count total vat/BTW
    $scope.countTotalVAT = function () {
      for (var i = 0; i < $scope.transaction.details.length; i++) {
        $scope.totalVAT += (($scope.transaction.details[i].price * $scope.transaction.details[i].priceVat) / 100)
      }
    }

    $scope.printReceiptDocument = function (mode) {
      var shopNameSlug = $scope.transaction.shopDetails.nameSlug
      var shopId = $scope.transaction.shopDetails._id

      var sendBackAddressDetails = {
        name: $rootScope.currentCompany.name,
        address: $rootScope.currentCompany.address,
        phone: $rootScope.currentCompany.phone,
        email: $rootScope.currentCompany.email
      }
      
      $api.get('shops-for-brand/' + shopNameSlug)
        .then(function (response) {
          $scope.shopAdd = response.data.shop

          $pdf.downloadPdf('generate-shop-receipt', {
            transactionId: $scope.transaction._id,
            shopId: shopId,
            payMethods: [],
            typeDocument: 'document',
            webshopTransaction: true,
            sendBackAddressDetails: sendBackAddressDetails
          }, $scope.transaction.number + '.pdf')
            .then(function (data) {
              if (mode !== 'document') {
                $scope.mailAlert = {
                  type: 'success',
                  msg: $language.translate('MAIL_SEND')
                }
              }
            })
            .catch(function (reason) {
              console.error(reason)
              if (mode !== 'document') {
                $scope.alert = {
                  type: 'danger',
                  msg: reason
                }
              }
            })
        }).catch(function (reason) {
          console.log(456)
          console.error('Error while getting shop', reason)
        })
    }

    $scope.submitForComplete = function (shippingTrackURL, method) {
      $scope.errorMessage = ''
      var shopId = $scope.transaction.shopDetails._id
      // We directly need to call api for update status
      $api.put('webshop-transaction/status', {
        transactionId: $stateParams.transactionId,
        shopId: shopId,
        status: 'completed',
        shippingTrackURL: shippingTrackURL,
        method: method
      })
        .then(function (response) {
          $scope.transaction.status = 'completed'
          $scope.checkOrderStatusIndication('completed')
          $scope.checkForAvailableStatus('completed')
        })
        .catch(function (error) {
          console.log('error', error)
          $scope.errorMessage = error.data.message
        })
    }

    // $scope.openCustomer = function () {
    //   $state.go('retailer.customer', {
    //     id: $scope.transaction.customer._id
    //   })
    // }

    $scope.AddComment = function (comment) {
      if (comment) {
        var body = {
          transactionId: $scope.transaction._id,
          // shopSlug: $scope.transaction.shopId.nameSlug,
          // companySlug: $scope.transaction.supplier.nameSlug,
          remarks: {
            eType: 'company',
            remark: comment,
            companyId: $scope.transaction.supplier
          },
          companyId: $rootScope.currentCompany._id
        }

        $api.post('add-comment-service-retailer-and-company', body)
          .then(function (response) {
            $scope.currentremark = ''
            $scope.getTransactionDetails()
          })
          .catch(function (err) {
            console.log(err)
            $scope.currentremark = ''
          })
      }
    }

    $scope.EditCommentMode = function (remark, index) {
      for (var i = 0; i < $scope.transaction.remarks.remarks.length; i++) {
        $scope.transaction.remarks.remarks[i].isEditMode = false
      }
      $scope.transaction.remarks.remarks[index].isEditMode = true
      $scope.editRemarkDetail = remark
    }

    $scope.UpdateComment = function (comment, index) {
      console.log('UpdateComment: ', comment)
      if (comment) {
        $scope.editRemarkDetail.remark = comment
        $scope.transaction.remarks.remarks[index].isEditMode = false
        // console.log('$scope.editRemarkDetail: ', $scope.editRemarkDetail);
        var body = {
          transactionId: $scope.transaction._id,
          commentId: $scope.editRemarkDetail._id,
          remark: comment,
          companyId: $rootScope.currentCompany._id
        }

        $api.post('edit-comment-service-retailer-and-company', body)
          .then(function (response) {
            $scope.editRemarkDetail = ''
            $scope.getTransactionDetails()
          })
          .catch(function (err) {
            $scope.editRemarkDetail = ''
            console.log(err)
          })
      } else {
        $scope.editRemarkDetail = ''
        $scope.transaction.remarks.remarks[index].isEditMode = false
      }
    }

    // Whenever adding comment to the then we have add new line when pressed (SHIFT + ENTER) and if only enter then add Comment
    $scope.addCommentKeyUp = function (event, currentremark) {
      if (event.keyCode === 13) {
        if (event.shiftKey) return false
        else $scope.AddComment(currentremark)
      }
    }

    $scope.autoScrollcomment = function () {
      setTimeout(function () {
        $('#messagewindow').animate({ scrollTop: $('#messagewindow')[0].scrollHeight }, 0)
      }, 200)
    }
  }])
