prismanoteApp.controller('chooseTillModalController', ['$scope', '$uibModalInstance', 'newTransaction', 'forceNewTill',
  function ($scope, $uibModalInstance, newTransaction, forceNewTill) {

    $scope.newTransaction = newTransaction
    $scope.forceNewTill = forceNewTill

    $scope.close = function (mode) {
      if($scope.saveTillChoose) {
        var tillMode = mode ? 'new' : 'current'
        localStorage.setItem('tillMode', tillMode)
      }
      $uibModalInstance.close(mode)
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }
  }])
