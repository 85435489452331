prismanoteApp.controller('adminProductMergeController', ['$scope', '$product',
    function ($scope, $product) {
        console.log('adminProductMergeController called');

        $scope.fetchAdminMergeRecords = function () {
            $scope.loading = true;
            $scope.adminProductMerge = [];
            $product.fetchAdminMergeRecords().then(function (data) {
                $scope.loading = false;
                if (data && data.data && data.data.data && data.data.data.length) {
                    var response = data.data.data;
                    console.log('Hello response: ', response);
                    $scope.adminProductMerge = response;
                }
            }).catch(function (error) {
                $scope.loading = false;
                console.error('Error While Getting Admin product merge records', error);
            })
        }
    }
])

