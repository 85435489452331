prismanoteApp.controller('confirmModalController', ['$scope', '$uibModalInstance', 'modalHeader', 'modalMessage', 'okAndCancelBothAction',
  function ($scope, $uibModalInstance, modalHeader, modalMessage, okAndCancelBothAction) {
    $scope.modalHeader = modalHeader
    $scope.modalMessage = modalMessage
    $scope.okAndCancelBothAction = okAndCancelBothAction

    $scope.cancel = function () {
      $uibModalInstance.close(false)
    }

    $scope.confirm = function () {
      $uibModalInstance.close(true)
    }
  }])
