prismanoteApp.controller('retailerRecommendationOrderController',
  ['$scope', '$rootScope', '$stateParams', '$recommendationOrder', 'prompt', '$state', 'cookieService','$productConfig', '$api', '$purchaseOrder',
    function ($scope, $rootScope, $stateParams, $recommendationOrder, prompt, $state, cookieService, $productConfig, $api, $purchaseOrder) {
      $scope.data = {
        offsetForPagination: 1,
        showLoadMore: false,
        products: [], // Products which we are selected and going to add in purchase order
        totalSelectedQuantity: 0,
        totalProductsPrice: 0,
        loading: false,
        searchMode: 'exact',
        search_value: '',
        isShowMoreFilter: false,
        searchedProducts: {
          isSearchResponse: false,
          products: [],
          count: 0
        },
        purchaseOrderNumber: $stateParams.orderNo || '',
        // FOR NEW PURCHASE ORDER
        purchase_order: {},
        hasSupplier: false,
        recommendationCompany: $rootScope.recommendationCompany,
        isSearchingProducts: false,
        isSearchLoader: false
      }
      $scope.filterDataVal = false
      $rootScope.recommendationCompany = undefined; // If available then doing undefind (COMING FROM PURCHASE ORDER)
      $scope.sortingOrderValue;
      
      $scope.filter = JSON.parse(JSON.stringify($recommendationOrder.getDefaultFilterValue()));
      $scope.customFilter = JSON.parse(JSON.stringify($recommendationOrder.getFiltersForCustomFilter()));
      $scope.filter = JSON.parse(JSON.stringify(Object.assign($scope.filter, $scope.customFilter)));
      $scope.searchData = null;
      $scope.filter.limit = 30
      $scope.pageSize = '30'
      $scope.filter.offset = 0;
      $scope.totalProductsData = 0;
      $scope.totalProductsDataShowFlag = false
      $scope.currentPage = 1
      // GET SHOP COMPANIES FOR SELECTING THE SUPPLIER WHILE CREATING NEW PURCHASE ORDER
      $scope.getShopCompanies = function () {
        console.log('getShopCompanies: ', $scope.currentShop);
        var body = { shopId: $scope.currentShop._id };
        $recommendationOrder.getShopCompanies(body).then(function (response) {
          $scope.shopCompanies = [];
          if (response && response.status !== 204 && response.data && response.data.data && response.data.data.length) {
            $scope.shopCompanies = response.data.data;
            // console.log('$scope.shopCompanies: ', $scope.shopCompanies);
          }
        }).catch(function (error) {
          console.log('Error while getting companies: ', reason);
        });
      }

      $scope.savePageSize = function (pageSize) {
        cookieService.setCookie('transactionsPageSize=' + pageSize, 31536000)
      }

      $scope.saveCurrentPage = function (currentPage) {
        
        cookieService.setCookie('servicesCurrentPage=' + currentPage, 1800)
      } 

      // code for autocomplete
      $scope.companySelected = function (selected) {
        console.log('========================111==================> --company selcetd: 123', selected);
        var companyObject = selected.originalObject; // because in api we may have any thing of company as input
        var isValidCompany = true;
        var message = '';
        if (!companyObject.address || !companyObject.address.street || !companyObject.address.houseNumber || !companyObject.address.city ||
          !companyObject.address.country || !companyObject.address.postalCode) {
          isValidCompany = false;
          message = 'Company address is not proper. First fill it proper then you can edit it';
        }

        if (!companyObject.phone || !companyObject.phone.landLine) {
          isValidCompany = false;
          message = 'Company does not have a phone number (fixed/landline)';
        }

        if (!companyObject.email) {
          isValidCompany = false;
          message = 'Company does not have proper email address';
        }

        if (isValidCompany) {
          $scope.data.recommendationCompany = companyObject; // BECAUSE USER NOT COMING FROM THE PURCHASE ORDER BUT COMING FROM THE DASHBOARD
          $scope.invalidSupplier = false;
          $scope.selectedCompany = companyObject;
          $scope.recommendationOrder = {
            purchase_order: {
              companyDetail: companyObject,
              // shop: {
              //   _id: $rootScope.currentShop._id,
              //   name: $rootScope.currentShop.name,
              //   nameSlug: $rootScope.currentShop.nameSlug,
              // }
            }
          }
          $scope.data.purchase_order.sendDetails = {
            address: $scope.selectedCompany.address,
            firstName: $scope.selectedCompany.name,
            phone: $scope.selectedCompany.phone,
            email: $scope.selectedCompany.email
          }

          if ($scope.data.purchase_order.sendDetails && $scope.data.purchase_order.sendDetails.phone) {
            if ($scope.data.purchase_order.sendDetails.phone.length < 8 || $scope.data.purchase_order.sendDetails.phone.length > 12) {
              $scope.data.purchase_order.sendDetails.mobilePhone = ""
            }
          }

          $scope.data.hasSupplier = true;
          // if ($scope.data.purchase_order && $scope.data.purchase_order.shop && $scope.data.purchase_order.shop._id && companyObject && companyObject._id) {
          //   $scope.getShopClientGroups($scope.data.purchase_order.shop._id, companyObject._id); // Getting clien groups of particular shop and company
          // }
        } else if (companyObject && companyObject.nameSlug) {
          $scope.invalidSupplierNameSlug = companyObject.nameSlug;
          $scope.invalidSupplier = true;
          $scope.invalidSupplierMessage = message;
        } else {
          $scope.invalidSupplier = true;
          $scope.invalidSupplierMessage = message;
        }
      };

      if ($scope.data.recommendationCompany && $scope.data.recommendationCompany._id) {
        $scope.getShopCompanies();
        $scope.companySelected({ originalObject: $scope.data.recommendationCompany });
      }

      // WHEN COMING FROM THE INVITATION LINK
      $scope.fetchCompanyDetail = function () {
        console.info(' ========================111==================> fetchCompanyDetail: respnse: 1');
        if ($rootScope.recommendationCompany && recommendationCompany._id) {
          $scope.getShopCompanies();
          var companyBody = { _id: $rootScope.recommendationCompany._id, shopId: $rootScope.currentShop._id }
          $recommendationOrder.fetchCompanyDetail(companyBody).then(function (response) {
            $rootScope.recommendationCompany = undefined;
            console.info('fetchCompanyDetail: respnse: ', response);
            if (response && response.data && response.data.data) {
              $scope.data.hasSupplier = true;
              $scope.selectedCompany = response.data.data;
              $scope.companySelected({ originalObject: $scope.selectedCompany });
            }
          }).catch(function (error) {
            console.log('Error while getting companies: ', error);
            $state.go('retailer.home');
            $rootScope.recommendationCompany = undefined;
          });
        }
      }

      // $scope.filter.isFilterFromCompany = false; // As we are using the same EMIT event

      $scope.getRetailerRecommendationPurchaseOrder = function () {
        $scope.totalProductsData = 0;
        // When user do refresh at time we need to keep this filter as it is
        var localStorageFilter = localStorage.getItem('recommendationFilter');
        var parsedLocalStorageFilter;
        if (localStorageFilter) {
          console.log('localStorageFilter3: ', typeof localStorageFilter, JSON.parse(localStorageFilter));
          parsedLocalStorageFilter = JSON.parse(localStorageFilter);
          $scope.filter = JSON.parse(JSON.stringify(parsedLocalStorageFilter));
        }
        if ($scope.data.offsetForPagination === 1) $scope.filter.offset = 0; // If Refreshing the page then we taking 1st page always

        var companyId = $scope.data.recommendationCompany;
        if ($scope.selectedCompany && $scope.selectedCompany._id) {
          companyId = $scope.selectedCompany._id
        }
        var body = {
          purchaseOrderNumber: $stateParams.orderNo,
          shopId: $rootScope.currentShop._id,
          filter: JSON.parse(JSON.stringify($scope.filter)),
          companyId: companyId,
          language: $rootScope.language,
          sortBasedOn: $scope.selectedCompany && $scope.selectedCompany.sortBasedOn ? $scope.selectedCompany.sortBasedOn : undefined
        }
        body.filter.strapWidths = body.filter.strapWidths.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.width });
        body.filter.jewelTypes = body.filter.jewelTypes.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
        body.filter.strapModels = body.filter.strapModels.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.model });
        if (body.filter.jewelMaterials != undefined) {
        body.filter.jewelMaterials = body.filter.jewelMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
        }
        if (body.filter.diamondMaterials != undefined) {
          body.filter.diamondMaterials = body.filter.diamondMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
        }
        if (body.filter.watchMaterials) {
          body.filter.watchMaterials = body.filter.watchMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
        }
        
        if (body.filter.watchCaseMaterials) {
          body.filter.watchCaseMaterials = body.filter.watchCaseMaterials.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.material });
        }
        if (body.filter.watchStrapMaterails) {
          body.filter.watchStrapMaterails = body.filter.watchStrapMaterails.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.material });
        }
        if (body.filter.watchTypes) {
          body.filter.watchTypes = body.filter.watchTypes.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.type });
        }
        if (body.filter.watchIndications) {
          body.filter.watchIndications = body.filter.watchIndications.filter(function (el) { if (el.isSelected) return el; }).map(function (el) { return el.indication });
        }
        body.filter.suppliers = undefined;
         console.log('BODY FILTER: ', body.filter);
        // body.filter.selectedFilters = undefined; // We don't need to send
        $scope.data.loading = true;
        $recommendationOrder.getRetailerRecommendationPurchaseOrder(body).then(function (response) {
          $scope.data.loading = false;
          console.log('response.data.data.productDetail:::::',response.data.data.productDetail);
          if (response && response.data && response.data.data) {
            // PRODUCTS AND PURCHASE ORDER RESPONSE
            if (response.data.data.productDetail) {
              $scope.recommendationOrder = { products: response.data.data.productDetail.products, purchase_order: response.data.data.purchase_order, sortingData: response.data.data.sortingOrderData };
              // console.log('RESPONSE: retailerRecommendationOrderController: ', $scope.recommendationOrder);
              $scope.sortingOrderValue = $scope.recommendationOrder.sortingData.sortBasedOnSortingNumber
              if($scope.recommendationOrder.sortingData.sortBasedOnSortingNumber == "companyProductSortingNumber") {
                for (var k = 0; k < $scope.recommendationOrder.products.products.length; k++) {
                  var soldAmount = 0;
                  soldAmount = $scope.recommendationOrder.products.products[k].products.sortingNumber ? $scope.recommendationOrder.products.products[k].products.sortingNumber - 500000000000 : 0
                  var topMostSold = "soldAmount";
                  if (soldAmount >= 10) {
                    $scope.recommendationOrder.products.products[k].products[topMostSold] = "Zeer goed verkocht";
                  } else if (soldAmount > 3 && soldAmount < 10) {
                    $scope.recommendationOrder.products.products[k].products[topMostSold] = "Goed verkocht"
                  } else {
                    $scope.recommendationOrder.products.products[k].products[topMostSold] = null
                  }
                }
              } else if($scope.recommendationOrder.sortingData.sortBasedOnSortingNumber == "productSortingNumber") {
                for (var k = 0; k < $scope.recommendationOrder.products.products.length; k++) {
                  var soldAmount = 0;
                  soldAmount = $scope.recommendationOrder.products.products[k].products.productDetail.sortingNumber ? $scope.recommendationOrder.products.products[k].products.productDetail.sortingNumber - 500000000000 : 0
                  var topMostSold = "soldAmount";
                  if (soldAmount >= 10) {
                    $scope.recommendationOrder.products.products[k].products[topMostSold] = "Zeer goed verkocht";
                  } else if (soldAmount > 3 && soldAmount < 10) {
                    $scope.recommendationOrder.products.products[k].products[topMostSold] = "Goed verkocht"
                  } else {
                    $scope.recommendationOrder.products.products[k].products[topMostSold] = null
                  }
                }
              }
              $scope.recommendationOrder.recommendationOrderFilters = response.data.data.recommendationOrderFilters.map(function(f) {
                if(f.orderFilterCategoryName === '' || !f.orderFilterCategoryName) {
                  f.orderFilterCategoryName = 'NO_GROUP'
                }
                return f
              }); // When purchase order already not created
              

              // WHEN CREATING NEW PURCHASE ORDER
              if (!$scope.recommendationOrder.purchase_order) {
                $scope.recommendationOrder.purchase_order = { companyDetail: $scope.selectedCompany };
              }

              $scope.recommendationOrder.recommendationOrderFilters = response.data.data.recommendationOrderFilters; // When purchase order already not created
              // console.log('$scope.recommendationOrder HEYY: ', $scope.recommendationOrder);

              var purchaseOrder = $scope.recommendationOrder.purchase_order;
              if (purchaseOrder) {
                if (purchaseOrder.companyDetail && purchaseOrder.companyDetail._id) {
                  console.log('$scope.data.hasSupplier: ', $scope.data.hasSupplier);
                  $scope.data.hasSupplier = true;
                }

                // When purchase order already created then we are removing the shop which we got from the PURCHASE ORDER
                if (purchaseOrder.number) $scope.data.recommendationCompany = undefined;
              } else if (!purchaseOrder && $scope.data.recommendationCompany) {
                $scope.recommendationOrder = {
                  purchase_order: {
                    companyDetail: $scope.data.recommendationCompany,
                  }
                }
              }

              if (purchaseOrder.companyDetail && purchaseOrder.companyDetail._id) {
                $scope.getCollectionsFilterForRecommendation(purchaseOrder.companyDetail._id);
              }
              var totalProducts = $scope.recommendationOrder.products.metadata[0].total;
              if ($scope.filter.limit < totalProducts && (($scope.filter.offset * $scope.filter.limit) + $scope.filter.limit) < totalProducts) {
                $scope.data.showLoadMore = true;
              } else {
                $scope.data.showLoadMore = false;
              }
              cookieService.getCookie('servicesCurrentPage')
                .then(function (currentPage) {
                  $scope.currentPage = currentPage
                })
                .catch(function () {
                  $scope.currentPage = 1
                })
              window.scrollTo(0, 0);
            }

            // SHOW SUPPLIER FILTER
            if ($scope.recommendationOrder && $scope.recommendationOrder.purchase_order &&
              $scope.recommendationOrder.purchase_order.companyDetail && $scope.recommendationOrder.purchase_order.companyDetail._id) {
              var companyDetail = $scope.recommendationOrder.purchase_order.companyDetail;
              console.log('companyDetail: ', companyDetail);
              $scope.filter.suppliers = [{
                _id: companyDetail._id,
                name: companyDetail.name,
                nameSlug: companyDetail.nameSlug,
                isSelected: true,
              }]
            }

            if (response.data.data.fitlerDetail) {
              // BRANDS
              var brands = response.data.data.fitlerDetail.brands;
              if (!$scope.filter.brands.length) $scope.filter.brands = brands && brands.length ? brands : [];
            }

            // IF PURCHASE ORDER CREATED AND SEARCHING AT THAT TIME, DUE TO THAT SEACH FOCUS LOST
            if ($rootScope.recommendationOrderSearchValue) {
              $scope.data.search_value = $rootScope.recommendationOrderSearchValue;
              $rootScope.recommendationOrderSearchValue = undefined;
              $scope.searchProductsForCompanyRecommnedation();
            }
          }
          if($scope.searchData != null) {
            $scope.data.search_value = $scope.searchData;
            $scope.searchProductsForCompanyRecommnedation('filter');
          } else {
            $scope.searchData = null
          }
        }).catch(function (error) {
          $scope.data.loading = false;
          console.log('error: ', error)
        });
      }

      // It's not needed to fetch when fetching products, but we can fetch this in background
      $scope.getCollectionsFilterForRecommendation = function (companyId, isMoreFilter) {

        console.log('getCollectionsFilterForRecommendation called: ', companyId);
        if (companyId && $rootScope.currentShop._id) {
          var body = {
            shopId: $rootScope.currentShop._id,
            companyId: companyId
          }

          $recommendationOrder.getCollectionsFilterForRecommendation(body).then(function (response) {
            if (response && response.data && response.data.data) {
              var collectionFilters = response.data.data;
              /*  
               console.log('RESPONSE: getCollectionsFilterForRecommendation: ', collectionFilters);
               if (!$scope.filter.privateLabelCollections.length) {
                 $scope.filter.privateLabelCollections = collectionFilters.privateLabelCollections;
               }
 
               if (!$scope.filter.clientGroupCollections.length) {
                 $scope.filter.clientGroupCollections = collectionFilters.clientGroupCollections;
               } */

              // When user do refresh at time we need to keep this filter as it is
              var localStorageFilter = localStorage.getItem('recommendationFilter');
              var parsedLocalStorageFilter;
              if (localStorageFilter) {
                console.log('localStorageFilter4: ', typeof localStorageFilter, JSON.parse(localStorageFilter));
                parsedLocalStorageFilter = JSON.parse(localStorageFilter);
                $scope.filter = JSON.parse(JSON.stringify(parsedLocalStorageFilter));
              }

              // ARTICLE GROUP (PRIVATE LABLE) START
              var privateLabelCollections = collectionFilters.privateLabelCollections;
              $scope.filter.privateLabelCollections = privateLabelCollections && privateLabelCollections.length ? privateLabelCollections : [];

              // For If already selected collection then we need to make it cheked marked true
              if (parsedLocalStorageFilter && parsedLocalStorageFilter.selectedPrivateLabelCollections && parsedLocalStorageFilter.selectedPrivateLabelCollections.length) {
                var articleGroups = $scope.filter.privateLabelCollections;
                var articleGroupsLength = articleGroups.length;
                var selectedColl = parsedLocalStorageFilter.selectedPrivateLabelCollections;
                // console.log('articleGroups selectedColl called: ', selectedColl);
                for (i = 0; i < selectedColl.length; i++) {
                  for (j = 0; j < articleGroupsLength; j++) {
                    if (selectedColl[i] && articleGroups[j] && articleGroups[j]._id &&
                      selectedColl[i] === articleGroups[j]._id) {
                      articleGroups[j].isSelected = true;
                    }
                  }
                }

                // $scope.filter.privateLabelCollections = articleGroups;
              }
              // ARTICLE GROUP (PRIVATE LABLE)  END

              // CLIENT GROUP COLLECTIONS START
              var clientGroupCollections = response.data.data.clientGroupCollections;
              $scope.filter.clientGroupCollections = clientGroupCollections && clientGroupCollections.length ? clientGroupCollections : [];

              // For If already selected collection then we need to make it cheked marked true
              if (parsedLocalStorageFilter && parsedLocalStorageFilter.selectedclientGroupCollections && parsedLocalStorageFilter.selectedclientGroupCollections.length) {
                var clientGroups = $scope.filter.clientGroupCollections;
                var clientGroupsLength = clientGroups.length;
                var selectedColl = parsedLocalStorageFilter.selectedclientGroupCollections;
                // console.log('clientGroups selectedColl called: ', selectedColl, clientGroups);
                for (i = 0; i < selectedColl.length; i++) {
                  for (j = 0; j < clientGroupsLength; j++) {
                    if (selectedColl[i] && clientGroups[j] && clientGroups[j]._id &&
                      selectedColl[i] === clientGroups[j]._id) {
                      clientGroups[j].isSelected = true;
                    }
                  }
                }

                // $scope.filter.privateLabelCollections = articleGroups;
              }
              // CLIENT GROUP COLLECTIONS END
            }
          }).catch(function (error) {
            console.log('getCollectionsFilterForRecommendation error: ', error)
          });
        }
      }

      $scope.loadMore = function () {
        $scope.filter.offset += 1
        console.log('$scope.data.searchedProducts.isSearchResponse: ', $scope.data.searchedProducts.isSearchResponse)
        if ($scope.data.searchedProducts.isSearchResponse) {
          $scope.searchProductsForCompanyRecommnedation()
        } else {
          $scope.getRetailerRecommendationPurchaseOrder()
        }
      }

      $scope.addTheProduct = function (item) {
        console.log('-------------------------------------------------------------- addTheProduct!!');
        console.log('$scope.data.purchaseOrderNumber', $scope.data.purchaseOrderNumber);
        if ($scope.data.purchaseOrderNumber) {
          addProductToExistingPurchaseOrder(item);
        } else {
          createNewPurchaseOrder(item);
        }
      }

      function addProductToExistingPurchaseOrder(item) {
        item.products.selected = true;
        item.products.quantity = 1;
        // console.log('item: ', item.products);

        var isAlreadyAdded = false;
        for (var i = 0; i < $scope.data.products.length; i++) {
          console.log('i: ', i, $scope.data.products[i], item.products._id);
          if (item && item.products && item.products._id && $scope.data && $scope.data.products && $scope.data.products[i] &&
            $scope.data.products[i]._id && (item.products._id).toString() === ($scope.data.products[i]._id).toString()) {
            isAlreadyAdded = true;
            break;
          }
        }

        if (isAlreadyAdded) return;

        $scope.data.products.push(item.products);

        var body = {
          shopId: $rootScope.currentShop._id,
          purchaseOrder: {
            _id: $scope.recommendationOrder.purchase_order._id
          },
          shop: {
            _id: $scope.recommendationOrder.purchase_order.shop._id
          },
          company: {
            _id: $scope.recommendationOrder.purchase_order.company._id
          },
          isFrom: 'retailer',
          products: [{
            _id: item.products.productDetail._id,
            quantity: item.products.quantity
          }]
        }

        $scope.data.loading = true;
        $recommendationOrder.addProductToPurchaseOrderFromRecommendation(body).then(function (response) {
          $scope.data.loading = false;
          if (response && response.data && response.data.data) {
            console.log('addProductToPurchaseOrderFromRecommendation created: ', response.data.data)
            $scope.recommendationOrder.purchase_order = response.data.data;
          } else {
            console.log('Something went wrong while adding product')
          }
        }).catch(function (error) {
          $scope.data.loading = false;
          console.log('error: ', error)
        });
      }

      function createNewPurchaseOrder(item) {
        console.log('------------------- createNewPurchaseOrder!!');
        var purchaseOrder = {
          shop: {
            _id: $rootScope.currentShop._id,
            name: $rootScope.currentShop.name,
            nameSlug: $rootScope.currentShop.nameSlug,
          },
          company: {
            _id: $scope.selectedCompany._id,
            name: $scope.selectedCompany.name,
            nameSlug: $scope.selectedCompany.nameSlug,
          },
          billingDetails: {
            address: [$rootScope.currentShop.address],
            email: $rootScope.currentShop.email,
            phone: [$rootScope.currentShop.phone]
          },
          sendDetails: {
            address: [$scope.selectedCompany.address],
            email: $scope.selectedCompany.email
          },
          isFrom: 'retailer',
          products: [{
            _id: item.products.productDetail._id,
            quantity: 1
          }]
        }
        console.log('createNewPurchaseOrder called: ', purchaseOrder);
        $recommendationOrder.createNewPurchaseOrderUsingRecommendation(purchaseOrder).then(function (response) {
          console.log('response: ', response);
          if (response && response.data && response.data.data) {
            $state.go('retailer.recommendation-order', { orderNo: response.data.data });
          }
        }).catch(function (error) {
          console.log('error: ', error);
        });
      }

      $scope.recommendationCustomGroupFilter = function(item) {
        return item.orderFilterCategoryName !== 'NO_GROUP' && item.orderFilterCategoryName !== ''
      }

      $scope.orderFilterCustomNameFilter = function(item) {
        return item.orderFilterCategoryName === 'NO_GROUP' || !item.orderFilterCategoryName || item.orderFilterCategoryName === ''
      }

      // DEPRECATED
      $scope.selectTheProduct = function (item) {
        // console.log('$scope.recommendationOrder', JSON.parse(JSON.stringify($scope.recommendationOrder.products.products)));
        item.products.selected = !item.products.selected
        if (item.products.selected) {
          item.products.quantity = 1
          $scope.data.products.push(item.products)
        } else {
          for (var i = 0; i < $scope.data.products.length; i++) {
            // console.log(i, item.products._id, $scope.data.products[i]._id, item.products)
            if ((item.products._id).toString() === ($scope.data.products[i]._id).toString()) {
              $scope.data.products.splice(i, 1)
            }
          }
        }

        calculateTheQunatityAndPrice()
        // console.log('CURRENT SELECTED PRODUCTS: ', $scope.data.products);
      }

      $scope.changeSelectedProductQuantity = function (product) {
        // if (product.quantity <= 0 || !product.quantity) product.quantity = 1;
        // console.log('product: ', product.quantity);
        // console.log('CURRENT SELECTED PRODUCTS: ', $scope.data.products);
        // if (product.quantity) product.selected = true;
        // else product.selected = false;
        calculateTheQunatityAndPrice()
      }

      function calculateTheQunatityAndPrice() {
        $scope.data.totalSelectedQuantity = 0
        $scope.data.totalProductsPrice = 0
        for (var i = 0; i < $scope.data.products.length; i++) {
          $scope.data.totalSelectedQuantity += $scope.data.products[i].quantity || 0
          $scope.data.totalProductsPrice += (($scope.data.products[i].productDetail.suggestedRetailPriceIncVat) * $scope.data.products[i].quantity) || 0
        }
      }

      $scope.addProductToPurchaseOrder = function () {
        $scope.data.products.map(function (elm, index) {
          if (!elm.quantity) {
            $scope.data.products[index].selected = false
            $scope.data.products.splice(index, 1)
          }
        })

        var body = {
          shopId: $rootScope.currentShop._id,
          purchaseOrder: {
            _id: $scope.recommendationOrder.purchase_order._id
          },
          shop: {
            _id: $scope.recommendationOrder.purchase_order.shop._id
          },
          company: {
            _id: $scope.recommendationOrder.purchase_order.company._id
          },
          isFrom: 'retailer',
          products: $scope.data.products.map(function (el) {
            return {
              _id: el.productDetail._id,
              quantity: el.quantity
            }
          })
        }

        $scope.data.loading = true;
        $recommendationOrder.addProductToPurchaseOrderFromRecommendation(body).then(function (response) {
          $scope.data.loading = false;
          emptyTheSelectedProduct()
          if (response && response.data && response.data.data) {
            console.log('addProductToPurchaseOrderFromRecommendation created: ', response.data.data)
            $scope.recommendationOrder.purchase_order = response.data.data
          } else {
            console.log('Something went wrong while adding product')
          }
        }).catch(function (error) {
          $scope.data.loading = false;
          emptyTheSelectedProduct();
          console.log('error: ', error)
        })
      }

      $scope.deletePurchaseOrderProduct = function (index) {
        var purchaseOrder = $scope.recommendationOrder.purchase_order
        if (purchaseOrder.items.length === 1) {
          prompt({
            title: 'Warning!',
            message: 'You have only one purchase order product. If you want to delete then go the purchase order page',
            buttons: [{ label: 'Go to the Purchase order', primary: true }, { label: 'CANCEL', primary: false, cancel: true }]
          }).then(function (response) {
            console.log('response: ', response)
            if (response.primary) {
              $state.go('retailer.purchase-order', { orderNo: $stateParams.orderNo })
            }
          }).catch(function (error) {
            console.log('error: inprompnt: ', error)
          })
        } else {
          // console.log('Hey Delete Purchase Order Product')
          var body = {
            purchaseOrderId: purchaseOrder._id,
            status: purchaseOrder.status[0].status,
            itemId: purchaseOrder.items[index]._id,
            shopId: $rootScope.currentShop._id
          }
          $scope.data.loading = true;
          $recommendationOrder.deletePurchaseOrderProduct(body).then(function (response) {
            $scope.data.loading = false;
            // $scope.recommendationOrder.purchase_order.items.splice(index, 1);
            $scope.getRetailerRecommendationPurchaseOrder()
          }).catch(function (error) {
            $scope.data.loading = false;
            console.log('error: ', error)
          })
        }
      }

      $scope.changePurchaseOrderProductQunatity = function (index) {
        // console.log('index: ', index);
        var item = $scope.recommendationOrder.purchase_order.items[index]
        // console.log('Hello there: ', item.quantity, item.lastQuantity, item._id, $scope.recommendationOrder.purchase_order);
        var body = {
          purchaseOrderId: $scope.recommendationOrder.purchase_order._id,
          purchaseOrderItemId: item._id,
          newQuantity: item.quantity,
          lastQuantity: item.lastQuantity,
          shopId: $rootScope.currentShop._id
        }
        $scope.data.loading = true;
        $recommendationOrder.changeQuantityOfPurchaseOrder(body).then(function (response) {
          $scope.data.loading = false;
          item.quantity = body.newQuantity
          item.lastQuantity = body.newQuantity
        }).catch(function (error) {
          $scope.data.loading = false;
          console.log('error: ', error)
        })
      }

      $scope.setSearchMode = function (mode) {
        console.log($scope.data.searchMode, mode)
        $scope.data.searchMode = mode
      }

      // NOW, WE ARE USING THE SAME AS BRAND RECOMMENDATION SEARCH
      $scope.searchProductsForRetailerRecommnedation = function () {
        if ($rootScope.currentShop._id) {
          if ($scope.data.search_value) {
            $scope.data.search_value = $productConfig.retrunValidProductNumber($scope.data.search_value)
            if ($scope.data.search_value.length < 3) return;

            if (!$scope.data.searchedProducts.isSearchResponse) {
              $scope.filter.limit = 30;
              $scope.filter.offset = 0;
              $scope.data.offsetForPagination = 1;
            }
            var data = {
              searchText: $scope.data.search_value,
              shopId: $rootScope.currentShop._id,
              limit: $scope.filter.limit,
              offset: $scope.filter.offset
            }

            $scope.data.loading = true;
            $recommendationOrder.searchProductsForRetailerRecommnedation(data).then(function (response) {
              $scope.data.loading = false;
              $scope.data.searchedProducts.isSearchResponse = true
              if (response && response.data && response.data.data && response.data.data.products && response.data.data.products.length) {
                var resData = response.data.data
                $scope.recommendationOrder.products = resData
                var totalProducts = $scope.recommendationOrder.products.metadata[0].total
                console.log('$scope.recommendationOrder.products',$scope.recommendationOrder.products);
                if ($scope.sortingOrderValue == "companyProductSortingNumber") {
                  for (var k = 0; k < $scope.recommendationOrder.products.products.length; k++) {
                    var soldAmount = 0;
                    soldAmount = $scope.recommendationOrder.products.products[k].products.sortingNumber ? $scope.recommendationOrder.products.products[k].products.sortingNumber - 500000000000 : 0
                    var topMostSold = "soldAmount";
                    if (soldAmount >= 10) {
                      $scope.recommendationOrder.products.products[k].products[topMostSold] = "Zeer goed verkocht";
                    } else if (soldAmount > 3 && soldAmount < 10) {
                      $scope.recommendationOrder.products.products[k].products[topMostSold] = "Goed verkocht"
                    } else {
                      $scope.recommendationOrder.products.products[k].products[topMostSold] = null
                    }
                  }
                } else if ($scope.sortingOrderValue == "productSortingNumber") {
                  for (var k = 0; k < $scope.recommendationOrder.products.products.length; k++) {
                    var soldAmount = 0;
                    soldAmount = $scope.recommendationOrder.products.products[k].products.productDetail.sortingNumber ? $scope.recommendationOrder.products.products[k].products.productDetail.sortingNumber - 500000000000 : 0
                    var topMostSold = "soldAmount";
                    if (soldAmount >= 10) {
                      $scope.recommendationOrder.products.products[k].products[topMostSold] = "Zeer goed verkocht";
                    } else if (soldAmount > 3 && soldAmount < 10) {
                      $scope.recommendationOrder.products.products[k].products[topMostSold] = "Goed verkocht"
                    } else {
                      $scope.recommendationOrder.products.products[k].products[topMostSold] = null
                    }
                  }
                }
                console.log('filter.limit:::',filter.limit);
                if ($scope.filter.limit < totalProducts && (($scope.filter.offset * $scope.filter.limit) + $scope.filter.limit) < totalProducts) {
                  $scope.data.showLoadMore = true
                } else {
                  $scope.data.showLoadMore = false
                }
                window.scrollTo(0, 0)
              } else {
                $scope.data.showLoadMore = false;
                $scope.recommendationOrder.products = {
                  products: [],
                  metadata: [{
                    total: 0
                  }]
                };
              }
            }).catch(function (error) {
              $scope.data.loading = false;
              console.log('error: ', error)
            })
          } else {
            $scope.data.searchedProducts.isSearchResponse = false
            $scope.filter.limit = 30;
            $scope.filter.offset = 0;
            $scope.data.offsetForPagination = 1;
            $scope.getRetailerRecommendationPurchaseOrder();
          }
        }
      }

      $scope.removeSearchResults = function () {
        if (!$scope.data.isSearchingProducts) return;
        $scope.data.isSearchingProducts = false;
        $scope.data.searchedProducts.isSearchResponse = false;
        $rootScope.recommendationOrderSearchValue = undefined; // To prevent focus losing when purchase order created
        $scope.data.search_value = '';
        $scope.filter.limit = 30;
        $scope.filter.offset = 0;
        $scope.currentPage = 1;
        $scope.data.offsetForPagination = 1;
        $scope.searchData = undefined
        $scope.getRetailerRecommendationPurchaseOrder()
      }

      $scope.searchProductsForCompanyRecommnedation = function (data) {
       console.log('searchProductsForCompanyRecommnedation called: ', $scope.data.search_value, $scope.recommendationOrder);
        $scope.data.isSearchingProducts = true;
        $scope.filterDataVal = false
        // document.getElementById('search_field_purchaseOrder').focus();
        $rootScope.recommendationOrderSearchValue = $scope.data.search_value;
        if ($scope.recommendationOrder && $scope.recommendationOrder.purchase_order) {
          if ((!$scope.recommendationOrder.purchase_order.company || !$scope.recommendationOrder.purchase_order.company._id) &&
            ($scope.recommendationOrder.purchase_order.companyDetail && $scope.recommendationOrder.purchase_order.companyDetail._id)) {
            $scope.recommendationOrder.purchase_order.company = $scope.recommendationOrder.purchase_order.companyDetail;
          }
          if ($scope.recommendationOrder.purchase_order.company && $scope.recommendationOrder.purchase_order.company._id) {
            if ($scope.data.search_value) {
              $scope.data.search_value = $productConfig.retrunValidProductNumber($scope.data.search_value)
              // value = $scope.data.search_value;
              // console.log($scope.data.search_value)
              if ($scope.data.search_value.length < 3) return

              if (!$scope.data.searchedProducts.isSearchResponse) {
                $scope.filter.limit = 30
                $scope.filter.offset = 0
                $scope.data.offsetForPagination = 1;
                $scope.currentPage = 1;
              }
              var data = {
                searchText: $scope.data.search_value,
                companyId: $scope.recommendationOrder.purchase_order.company._id,
                shopId: $scope.currentShop._id,
                limit: $scope.filter.limit,
                offset: $scope.filter.offset,
                language: $rootScope.language,
                sortBasedOn: $scope.recommendationOrder.purchase_order.company.sortBasedOn,
                dataLimit: data == 'filter' ? 'filter' : null,
              }

              // $scope.data.loading = true;
              $scope.data.isSearchLoader = true;
              // document.getElementById('search_field_purchaseOrder').focus();
              $recommendationOrder.searchProductsForCompanyRecommnedation(data).then(function (response) {
                $scope.data.isSearchLoader = false;
                $scope.data.searchedProducts.isSearchResponse = true;
                // console.log('search_field_purchaseOrder: ', document.getElementById('search_field_purchaseOrder'));
                // document.getElementById('search_field_purchaseOrder').focus();
                if (response && response.data && response.data.data && response.data.data.products && response.data.data.products.length) {
                  var resData = response.data.data
                  var filterValues = localStorage.getItem('recommendationFilter');
                  var filData = JSON.parse(filterValues)
                 var categaoryFilter = false;

                  if(data.dataLimit == 'filter') {
                  if(filData && filData.category != null){
                    categaoryFilter = true
                    if (filData.category && (filData.category.watch == true || filData.category.jewel == true || filData.category.strap == true || filData.category.others == true)) {
                      $scope.filterDataVal = true
                    }

                    if ($scope.filterDataVal == true) {
                      var filtered_data = []

                      if (filData.category.watch == true) {
                        filtered_data = resData.products.filter(function (data) {
                          return ((data.products.productDetail.category == 'WATCH'));
                        });
                      }
                      if (filData.category.jewel == true) {
                        if(filtered_data.length == 0) {
                          filtered_data = resData.products.filter(function (data) {
                            return ((data.products.productDetail.category == 'JEWEL'));
                          });
                        }
                       
                      }
                      if (filData.category.strap == true) {
                        if(filtered_data.length == 0) {
                          filtered_data = resData.products.filter(function (data) {
                            return ((data.products.productDetail.category == 'STRAP'));
                          });
                        }
                        
                      }
                      if (filData.category.others == true) {
                        if(filtered_data.length == 0) {
                          filtered_data = resData.products.filter(function (data) {
                            return ((data.products.productDetail.category == 'OTHERS'));
                          });
                        }
                      }
                      $scope.recommendationOrder.products = filtered_data
                    }
                  }
                  }

                  $scope.totalProductsDataShowFlag = false;
                  if ($scope.filterDataVal == false && categaoryFilter == false) {

                    $scope.recommendationOrder.products = resData
                    var totalProducts = $scope.recommendationOrder.products.metadata[0].total
                    $scope.filter.limit = 30
                  } else if(categaoryFilter == true && $scope.filterDataVal == true){
                    $scope.recommendationOrder.products.products = $scope.recommendationOrder.products
                    $scope.totalProductsDataShowFlag = true
                    $scope.totalProductsData = $scope.recommendationOrder.products.length
                  //  $scope.filter.limit = $scope.recommendationOrder.products.length
                  }

                  /* $scope.recommendationOrder.products = resData
                  var totalProducts = $scope.recommendationOrder.products.metadata[0].total */
                  if ($scope.sortingOrderValue == "companyProductSortingNumber") {
                  for (var k = 0; k < $scope.recommendationOrder.products.products.length; k++) {
                    var soldAmount = 0;
                    soldAmount = $scope.recommendationOrder.products.products[k].products.sortingNumber ? $scope.recommendationOrder.products.products[k].products.sortingNumber - 500000000000 : 0
                    var topMostSold = "soldAmount";
                    if (soldAmount >= 10) {
                      $scope.recommendationOrder.products.products[k].products[topMostSold] = "Zeer goed verkocht";
                    } else if (soldAmount > 3 && soldAmount < 10) {
                      $scope.recommendationOrder.products.products[k].products[topMostSold] = "Goed verkocht"
                    } else {
                      $scope.recommendationOrder.products.products[k].products[topMostSold] = null
                    }
                  }
                } else if($scope.sortingOrderValue == "productSortingNumber") {
                  for (var k = 0; k < $scope.recommendationOrder.products.products.length; k++) {
                    var soldAmount = 0;
                    soldAmount = $scope.recommendationOrder.products.products[k].products.productDetail.sortingNumber ? $scope.recommendationOrder.products.products[k].products.productDetail.sortingNumber - 500000000000 : 0
                    var topMostSold = "soldAmount";
                    if (soldAmount >= 10) {
                      $scope.recommendationOrder.products.products[k].products[topMostSold] = "Zeer goed verkocht";
                    } else if (soldAmount > 3 && soldAmount < 10) {
                      $scope.recommendationOrder.products.products[k].products[topMostSold] = "Goed verkocht"
                    } else {
                      $scope.recommendationOrder.products.products[k].products[topMostSold] = null
                    }
                  }
                }

                  if ($scope.filter.limit < totalProducts && (($scope.filter.offset * $scope.filter.limit) + $scope.filter.limit) < totalProducts) {
                    $scope.data.showLoadMore = true
                  } else {
                    $scope.data.showLoadMore = false
                  }
                  window.scrollTo(0, 0)
                } else {
                  $scope.data.showLoadMore = false;
                  $scope.recommendationOrder.products = {
                    products: [],
                    metadata: [{
                      total: 0
                    }]
                  };
                }
              }).catch(function (error) {
                $scope.data.isSearchLoader = false;
                console.log('error: ', error);
              })
            }
          }
        }
      }

      $scope.backToPurchaseOrder = function () {
        $rootScope.isBackOfRecommendation = true;
        $state.go('retailer.purchase-order', { orderNo: $scope.recommendationOrder.purchase_order.number });
        // ui-sref="retailer.purchase-order({orderNo: recommendationOrder.purchase_order.number})"r
      }

      function emptyTheSelectedProduct() {
        $scope.data.products = []
        var products = $scope.recommendationOrder.products.products
        products.map(function (el) {
          if (el && el.products && el.products.selected) {
            el.products.selected = false
          }
        })
      }

      $scope.changePage = function () {
        emptyTheSelectedProduct();
        $scope.filter.offset = $scope.data.offsetForPagination - 1; // Pagiantion count from the 0 (CURRENT PAGE) but In query we are doing from 1(CURRENT PAGE)
        if ($scope.data.searchedProducts.isSearchResponse) {
          $scope.searchProductsForCompanyRecommnedation();
        } else {
          $scope.filter.clientGroupCollections = [];
          $scope.filter.privateLabelCollections = [];
          localStorage.setItem('recommendationFilter', JSON.stringify($scope.filter));
          $scope.getRetailerRecommendationPurchaseOrder();
        }
      }

      $scope.resetFilter = function () {
        localStorage.removeItem('recommendationFilter');
        $scope.filter = JSON.parse(JSON.stringify($recommendationOrder.getDefaultFilterValue()));
        $scope.getRetailerRecommendationPurchaseOrder();
      }

      function setFilter(filter) {
        $scope.data.search_value = '';
        filter.clientGroupCollections = [];
        filter.privateLabelCollections = [];
        localStorage.setItem('recommendationFilter', JSON.stringify(filter));
        $scope.getRetailerRecommendationPurchaseOrder();
      }

      $scope.$on('recommendation_filter_directive', function (event, filter) {
        setFilter(filter);
      });

      $scope.searchCompanies = function (str) {
        console.log('$scope.searchCompanies: ', str, $scope.shopCompanies);
        return $recommendationOrder.searchCompanies(str, $scope.shopCompanies);
      };

      // Opening modal to search the supplier
      $scope.editCompany = function () {
        console.log('========================111==================> editCompany called: ');
        $purchaseOrder.openSearchSupplierModal($scope.shopCompanies).then(function (selectedCompany) {
          console.log('===========================================> >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>. selcetedcomany: ', selectedCompany);
          // var companyObject = selectedCompany; // because in api we may have any thing of company as input
          // console.log('companyObject: ', companyObject);
          var isValidCompany = true;
          var message = '';
          if (!companyObject.address || !companyObject.address.street || !companyObject.address.houseNumber || !companyObject.address.city ||
            !companyObject.address.country || !companyObject.address.postalCode) {
            isValidCompany = false;
            message = 'Supplier address is not proper. First fill it proper then you can edit it';
          }

          if (!companyObject.phone || !companyObject.phone.landLine) {
            isValidCompany = false;
            message = 'Company does not have a phone number (fixed/landline)';
          }

          if (!companyObject.email) {
            isValidCompany = false;
            message = 'Company does not have proper email address';
          }

          if (isValidCompany) {
            $scope.invalidSupplier = false;
            $scope.selectedCompany = companyObject;
            $scope.recommendationOrder.purchase_order.companyDetail = companyObject;
            $scope.data.purchase_order.sendDetails = {
              address: $scope.selectedCompany.address,
              firstName: $scope.selectedCompany.name,
              phone: $scope.selectedCompany.phone,
              email: $scope.selectedCompany.email
            }

            if ($scope.data.purchase_order.sendDetails && $scope.data.purchase_order.sendDetails.phone) {
              if ($scope.data.purchase_order.sendDetails.phone.length < 8 || $scope.data.purchase_order.sendDetails.phone.length > 12) {
                $scope.data.purchase_order.sendDetails.mobilePhone = ""
              }
            }

            $scope.data.hasSupplier = true;

            $scope.resetFilter();
          } else {
            $scope.invalidSupplierNameSlug = selectedCompany.nameSlug;
            $scope.invalidSupplier = true;
            $scope.invalidSupplierMessage = message;
          }
        }).catch(function (error) {
          console.log('edit company modal error: ', error);
        });
      }

      $scope.removeFilter = function (filtertype, key, index) {
        $scope.filter = $recommendationOrder.removeFilter($scope.filter, filtertype, key, index);
        $scope.$emit('recommendation_filter_directive', $scope.filter);
      }

      $scope.fetchShowAdvisoryPrice = function () {
        var showAdvisoryPriceForRecommendation = localStorage.getItem('showAdvisoryPriceForRecommendation');
        if (showAdvisoryPriceForRecommendation == true || showAdvisoryPriceForRecommendation == 'true') {
          showAdvisoryPriceForRecommendation = true;
        } else {
          showAdvisoryPriceForRecommendation = false;
        }

        $rootScope.showAdvisoryPriceForRecommendation = showAdvisoryPriceForRecommendation;
      }

      $scope.setCustomFilter = function (item) {
        console.log('setCustomFilter called: ', item);
        if (item.searchQuery && item.searchQuery.isSearch) {
          $scope.data.search_value = item.searchQuery.searchValue;
          $scope.searchProductsForCompanyRecommnedation();
        } else {
          $scope.searchData = item.searchQuery.searchValue;
          item.filter._id = item._id
          if(item.orderFilterCategoryName) {
            item.filter.orderFilterCategoryName = item.orderFilterCategoryName
          }
          console.log('item.filter##@@@@@@!!!!!',item.filter);
          setFilter(item.filter);
        }
      }

    }
  ])
