prismanoteApp.controller('brandNewCampaignController', ['$scope', '$rootScope', '$location', '$api', '$wholesaler', '$uibModal', 'prompt', 'Upload', '$document', '$state', 'campaignFact', 'constantFact', '$interval',
  function ($scope, $rootScope, $location, $api, $wholesaler, $uibModal, prompt, Upload, $document, $state, campaignFact, constantFact, $interval) {
    $scope.user = {}
    $scope.campaign = {}
    $scope.urlPattern = /^(https?:\/\/)?[www]*\.[a-z0-9-]*\.?[a-z0-9-]+\.[a-z0-9-]+(\/[^<>]*)?$/
    $scope.emailPattern = /^\S+@(([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6})$/

    campaignFact.getAdminData().then(function (res) {
      if (res.error) {
        console.log('admin data not get')
      } else {
        // console.log('imgNameArrayaaa', res, '$rootScope.language', $rootScope.language);
        $scope.user = {
          accountId: res.accountId,
          access_token: res.access_token
        }
        $rootScope.adminmetaData = res
      }
    })

    $scope.getWholesalerBrands = function () {
      if ($scope.currentWholesaler && $scope.currentWholesaler.brands) {
        $scope.campaign.brand = null
        $scope.brands = $scope.currentWholesaler.brands
      } else {
        getItems('brands')
      }
    }

    $scope.fbPageFunc = function (data) {
      $rootScope.campaignPreview = []
      for (var j = 0; j < $rootScope.user.facebook.facebookPages.length; j++) {
        if ($rootScope.user.facebook.facebookPages[j].name == data.fbPromotionSettings.pageName) {
          $rootScope.fbPageId = $rootScope.user.facebook.facebookPages[j].id
        }
      }
      var acntidd = $rootScope.adminmetaData.accountId
      var accToken = $rootScope.adminmetaData.access_token
      campaignFact.readAdCampaign(acntidd, accToken).then(function (res) {
        if (res.error) {
          console.log('read adcampaign gets error')
        } else {
          $scope.campData = res.data
          if (res.data && $scope.campData.length > 0) {
            $scope.campData.forEach(function (val) {
              campaignFact.campaignInsightss(val.id, accToken).then(function (insight) {
                if (insight.error) {
                  console.log('campaign insights api gets error...')
                } else {
                  console.log('res campaign insights', val, insight)
                  if (insight.data && insight.data.length > 0) {
                    insight.data[0].name = val.name
                    insight.data[0].status = val.effective_status
                    $rootScope.campaignPreview.push(insight.data[0])
                  } else {
                    var obj = {}
                    obj = {
                      name: val.name,
                      status: val.effective_status,
                      clicks: 0,
                      cost_per_unique_click: 0,
                      cpm: 0,
                      ctr: 0,
                      frequency: 0,
                      impressions: 0,
                      reach: 0,
                      spend: 0
                    }
                    $rootScope.campaignPreview.push(obj)
                  }
                }
              })
            })
          }
        }
      })
    }
    $scope.fbPageArray = []
    for (var i = 0; i < $rootScope.user.facebook.facebookPages.length; i++) {
      $scope.fbPageArray.push($rootScope.user.facebook.facebookPages[i].name)
    }
    $scope.campaign = {
      emailReceivers: {
        added: [],
        suggested: [],
        suggestedMinusDeleted: [],
        mailSuggested: true,
        isRestricted: false
      },
      tasks: []
    }

    $scope.selectedEmailReceiversOption = 'Geavanceerd (Bepalen wie de campagne wel/niet mag zien)'

    $scope.orderTaskInfo = {
      type: 'order',
      mandatory: false
    }
    $scope.orderTaskAdded = false

    $scope.facebookTaskInfo = {
      type: 'facebook',
      mandatory: true,
      nl: {}
    }
    $scope.facebookTask2Info = {
      type: 'facebook',
      mandatory: true,
      nl: {}
    }
    $scope.facebookTaskImages = []
    $scope.facebookTask2Images = []

    $scope.today = new Date()
    $scope.tomorrow = new Date((+new Date() + 86400000))
    $scope.todayPlusTreeDays = new Date((+new Date() + 8640000000))
    $scope.todayPlusHunderdDays = new Date((+new Date() + 8640000000))

    if (campaignFact.updateCampaign.name != undefined) {
      $scope.campaign = campaignFact.updateCampaign
      $scope.facebookTaskInfo.name = campaignFact.updateCampaign.name
    }
    $scope.$watch('campaign', function (newData, oldData) {
      campaignFact.updateCampaign = newData
    })

    $scope.startDateOptions = {
      minDate: $scope.tomorrow,
      maxDate: $scope.campaign.endDate,
      dateDisabled: disabled
    }
    $scope.getStartDate = function (campaign) {
      var todayDate = new Date(campaign.startDate)
      todayDate.setDate(todayDate.getDate() + 1)
      $scope.startTime = campaign.startDate
    }
    function disabled (data) {
      var date = data.date
      var mode = data.mode
      return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6)
    }

    $scope.promotionStartDateEquals = function () {
      $scope.facebookTaskInfo.fbPromotionSettings.promotionStartDate = $scope.campaign.startDate
      var milliseconds = $scope.campaign.startDate.getTime()
      var daysAfter = milliseconds + 1296000000
      var dateFormat = new Date(daysAfter)
      $scope.facebookTaskInfo.fbPromotionSettings.promotionEndDate = $scope.campaign.endDate
      // $scope.facebookTaskInfo.fbPromotionSettings.promotionEndDate = dateFormat;
    }
    $scope.promotionStartDateEqualsRetargeting = function () {
      $scope.facebookTask2Info.fbPromotionSettings.promotionStartDate = $scope.facebookTaskInfo.fbPromotionSettings.promotionEndDate
      var milliseconds = $scope.facebookTaskInfo.fbPromotionSettings.promotionEndDate.getTime()
      var daysAfter = milliseconds + 432000000
      var dateFormat = new Date(daysAfter)
      $scope.facebookTask2Info.fbPromotionSettings.promotionEndDate = dateFormat
    }
    // orderdate
    $scope.orderDatePlanned = function () {
      $scope.orderTaskInfo.datePlanned = $scope.facebookTaskInfo.fbPromotionSettings.promotionStartDate
    }

    $scope.totalBudget = 0
    // difference between day's
    $scope.diffDays = function () {
      var oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
      var firstDate = $scope.facebookTaskInfo.fbPromotionSettings.promotionStartDate
      var secondDate = $scope.facebookTaskInfo.fbPromotionSettings.promotionEndDate

      var diffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)))

      console.log(diffDays)
      $scope.totalBudget = diffDays * $scope.facebookTaskInfo.fbPromotionSettings.budget
    }

    $scope.diffDaysRetargeting = function () {
      var oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
      var firstDate = $scope.facebookTask2Info.fbPromotionSettings.promotionStartDate
      var secondDate = $scope.facebookTask2Info.fbPromotionSettings.promotionEndDate

      var diffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)))

      $scope.totalBudget = diffDays * $scope.facebookTask2Info.fbPromotionSettings.budget
    }

    $scope.targetSearch = 0

    $scope.goToStep = function (type, step) {
      console.info('targetSearch1', $scope[type], $scope.targetSearch)
      console.log('goToStep', type, step)
      $scope.$parent[type] = step
    }

    $scope.$on('changeSelectedCompany', function () {
      $company.getCompany()
        .then(function (company) {
          $scope.currentCompany = company
          $scope.getCompanyBrands()
        })
        .catch(function (reason) {
          console.log(reason)
        })
    })

    $scope.getCompanyBrands = function () {
      if ($scope.currentCompany && $scope.currentCompany.brands) {
        $scope.campaign.brand = null
        $scope.brands = $scope.currentCompany.brands
      } else {
        getItems('brands')
      }
    }

    $scope.campaignStrategy = function (strategy) {
      $scope.campaign.strategy = strategy
      $scope.campaign.update = false
    }

    $scope.campaignUpdate = function () {
      $scope.campaign.strategy = ''
      $scope.campaign.update = true
    }

    $scope.openNewTaskModal = function (task) {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/new-task-modal.html',
        controller: 'newTaskModalController',
        size: 'lg'
      })

      modalInstance.result.then(function (task) {
        console.log('TASK', task)
      }, function () {
        // dismissed
      })
    }

    var getItems = function (type) {
      $api.get(type, { companyId: $rootScope.currentCompany._id })
        .then(function (response) {
          $scope[type] = response.data[type]
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    // $scope.getBrands = function() {
    // 	return getItems('brands');
    // };

    $scope.getCompanies = function () {
      return getItems('companies')
    }

    var searchItems = function (str, type) {
      var matches = []

      $scope[type].forEach(function (item) {
        if (
          (item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
					(item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
          matches.push(item)
        }
      })

      return matches
    }

    $scope.searchBrands = function (str) {
      return searchItems(str, 'brands')
    }

    $scope.searchCompanies = function (str) {
      return searchItems(str, 'companies')
    }

    $scope.brandSelected = function (selected) {
      var brand = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug,
        description: selected.originalObject.description,
        images: selected.originalObject.images
      }

      $scope.campaign.brand = brand
    }

    $scope.companySelected = function (selected) {
      var company = {
        _id: selected.originalObject._id,
        name: selected.title,
        nameSlug: selected.originalObject.nameSlug,
        description: selected.originalObject.description,
        images: selected.originalObject.images
      }

      $scope.newsItem.company = company
    }

    // - functions for login
    $scope.forgotmode = false
    $scope.registermode = false
    $scope.alert = null
    $scope.registerComplete = false // alleen true als registreren gelukt is

    $scope.loginOnPage = function () {
      console.log('Login', $scope.email, $scope.pass)
      $api.post('login', { username: $scope.email, password: $scope.pass })

        .then(function (response) {
          $scope.alert = null
          $rootScope.user = response.data
          $scope.username = undefined
          $scope.password = undefined
        })

        .catch(function (reason) {
          console.log(reason)
          $scope.alert = {
            type: 'danger',
            msg: reason // || response.statusText
          }
        })
    }

    $scope.forgot = function () {
      $scope.alert = null
      $api.post('send-password-reset-link', {
        email: $scope.username,
        language: $rootScope.language
      })
        .then(function (response) {
          $scope.alert = {
            type: 'success',
            msg: response.data.message
          }
        })
        .catch(function (reason) {
          $scope.alert = {
            type: 'danger',
            msg: reason
          }
        })
    }

    $scope.register = function (isValid) {
      if (isValid) {
        $api.post('register', { user: $scope.newUser })
          .then(function (response) {
            $scope.alert = null
            $scope.registerComplete = true
          })
          .catch(function (reason) {
            console.log('error', reason)
            $scope.alert = {
              type: 'danger',
              msg: reason
            }
          })
      }
    }

    $scope.logout = function () {
      $api.post('logout', { id: $rootScope.user._id }).then(function () {
        $scope.alert = null
        $rootScope.user = null
        $rootScope.openChats = []
        $rootScope.currentEmployee = undefined
        $rootScope.currentCompany = undefined;
        localStorage.removeItem('currentShop')
        localStorage.removeItem('currentShopName');
        localStorage.removeItem('isEnableEmployeeLogin')
        localStorage.removeItem('lastLoggedInEmployees')
        $state.go('layout.logout')
        $state.reload();
      }).catch(function (reason) {
        console.log(reason)
      })
    }

    // Determine start date
    $scope.StartDate = new Date()
    $scope.orderDate = new Date()
    $scope.params = {
      datetime: null
    }
    /* 		if($scope.campaign.company.name == null){
					$scope.campaign.company.name = $scope.user.distributors[0].name
				}
			}
			console.log("result", $scope.campaign.company.name) */
    $scope.progressBar = 0
    $scope.hideDesignOptions = true

    $scope.hideDesignOptionsOnFalse = function () {
      $scope.hideDesignOptions = true
      $scope.$parent.$parent.targetSearch = 0
    }
    $scope.hideDesignOptionsOnTrue = function () {
      $scope.hideDesignOptions = false
    }

    // navigation
    $scope.chosenStrategy = 'notChosen'
    $scope.toTab0 = function () {
      $scope.chosenStrategy = $scope.campaign.strategy
      if ($scope.campaign.update == true) {
        $scope.chosenUpdate = true
      };
      $scope.activeTab = 0
      $scope.progressBar = 2

      $scope.valueUpdate = '1/5'
      $scope.valueTargetSearch = '1/9'
      $scope.valueLocalPlump = '1/11'
    }
    $scope.toTab1 = function () {
      $scope.saveChosenStrategy()

      $scope.valueUpdate = '2/5'
      $scope.valueTargetSearch = '2/9'
      $scope.valueLocalPlump = '2/11'
    }

    $scope.saveChosenStrategy = function () {
      if ($scope.chosenStrategy == 'notChosen' || $scope.campaign.strategy == $scope.chosenStrategy && $scope.campaign.update == $scope.chosenUpdate) {
        // -|| $scope.campaign.update == $scope.chosenUpdate
        $scope.chosenStrategy = $scope.campaign.strategy
        // $scope.chosenUpdate = $scope.campaign.update;
        $scope.activeTab = 1
        $scope.progressBar = 10
      } else {
        var newVal = prompt({
          title: 'Another strategy',
          message: 'You have changed a previously chosen strategy. Are you sure you want to change strategy? Previously entered info will be lost.'
        }).then(function () {
          console.log('newVal', newVal)
          if (newVal == undefined) { // annuleren  = strategy wijzigen naar wat het eerder was
            // -
          } else {
            if ($scope.chosenUpdate == true) {
              $scope.chosenUpdate = $scope.campaign.update
            } else {
              $scope.chosenStrategy = $scope.campaign.strategy
            }
            $scope.activeTab = 1
            $scope.progressBar = 10
          }
        })
      }
      $scope.facebookTaskInfo.nl.name = $scope.campaign.name
      $scope.valueTargetSearch = '3/9'
      $scope.valueLocalPlump = '3/11'
    }

    $scope.toTab2 = function () {
      $scope.activeTab = 2
      $scope.promotionStartDateEquals()
      // $scope.diffDays();
      $scope.progressBar = 30
      $scope.facebookTaskInfo.nl.description = $scope.campaign.description
      $scope.valueTargetSearch = '3/9'
      $scope.valueLocalPlump = '3/11'
    }
    $scope.toTab3 = function () {
      $scope.activeTab = 3
      $scope.valueLocalPlump = '4/11'
    }
    $scope.toTab4 = function () {
      $scope.promotionStartDateEqualsRetargeting()
      $scope.diffDaysRetargeting()
      $scope.facebookTask2Info.fbPromotionSettings.targetGroup = $scope.facebookTaskInfo.fbPromotionSettings.targetGroup + ' en die de eerste add langer dan 2 seconden bekeken hebben'
      $scope.activeTab = 4

      $scope.valueLocalPlump = '5/11'
    }

    $scope.toTab5 = function () {
      $scope.activeTab = 5
      $scope.progressBar = 50
      $scope.orderDatePlanned()

      $scope.valueUpdate = '3/5'
      $scope.valueTargetSearch = '4/9'
      $scope.valueLocalPlump = '6/11'
    }
    $scope.toTab6 = function () {
      $scope.activeTab = 6
      $scope.progressBar = 70

      $scope.valueTargetSearch = '5/9'
      $scope.valueLocalPlump = '7/11'
    }
    $scope.toTab7 = function () {
      $scope.activeTab = 7
      $scope.progressBar = 90

      $scope.valueUpdate = '4/5'
      $scope.valueTargetSearch = '6/9'
      $scope.valueLocalPlump = '8/11'
    }
    $scope.toTab8 = function () {
      $scope.activeTab = 8
      $scope.progressBar = 93

      $scope.campaign.tasks = []

      // upload images
      $scope.loading = true
      // console.log($scope.orderTaskAdded);
      if ($scope.orderTaskAdded == true) {
        $scope.campaign.tasks.push($scope.orderTaskInfo)
      }

      Upload.upload({
        url: '/api/upload-promotion-images',
        data: {
          files: $scope.facebookTaskImages
          // 'campaign': JSON.stringify(campaign),
          // 'user': JSON.stringify($scope.user),
          // 'task': JSON.stringify(task)
        }
      }).then(function (res) {
        // Upload is succesfull, add the result to the scope, send the order and save the task
        if (res.data.files.length < 1) {
          // foutmelding tonen
          return
        }
        $scope.facebookTaskInfo.images = []
        res.data.files.forEach(function (data) {
          $scope.facebookTaskInfo.images.push({ alt: data.name, src: data.path })
        })
        Upload.upload({
          url: '/api/upload-promotion-images',
          data: {
            files: $scope.facebookTaskImages
            // 'campaign': JSON.stringify(campaign),
            // 'user': JSON.stringify($scope.user),
            // 'task': JSON.stringify(task)
          }
        }).then(function (res) {
          // Upload is succesfull, add the result to the scope, send the order and save the task
          if (res.data.files.length < 1) {
            // foutmelding tonen
            return
          }
          $scope.facebookTaskInfo.images = []
          res.data.files.forEach(function (data) {
            $scope.facebookTaskInfo.images.push({ alt: data.name, src: data.path })
          })

          // - prepare Email
          // res.data.files[0].src;
          $scope.data = {
            campaign: $scope.campaign,
            imageLinkPreview: $scope.facebookTaskInfo.images[0].src
          }

          $scope.campaign.tasks.push($scope.facebookTaskInfo)

          // - images of second promotion task (local plump)
          Upload.upload({
            url: '/api/upload-promotion-images',
            data: {
              files: $scope.facebookTask2Images
              // 'campaign': JSON.stringify(campaign),
              // 'user': JSON.stringify($scope.user),
              // 'task': JSON.stringify(task)
            }
          }).then(function (res) {
            // Upload is succesfull, add the result to the scope, send the order and save the task
            if (res.data.files.length < 1) {
              // foutmelding tonen
              return
            }
            $scope.facebookTask2Info.images = []
            res.data.files.forEach(function (data) {
              $scope.facebookTask2Info.images.push({ alt: data.name, src: data.path })
            })
            Upload.upload({
              url: '/api/upload-promotion-images',
              data: {
                files: $scope.facebookTask2Images
                // 'campaign': JSON.stringify(campaign),
                // 'user': JSON.stringify($scope.user),
                // 'task': JSON.stringify(task)
              }
            }).then(function (res) {
              // Upload is succesfull, add the result to the scope, send the order and save the task
              if (res.data.files.length < 1) {
                // foutmelding tonen
                return
              }
              $scope.facebookTask2Info.images = []
              res.data.files.forEach(function (data) {
                $scope.facebookTask2Info.images.push({ alt: data.name, src: data.path })
              })

              // - prepare Email
              // res.data.files[0].src;
              $scope.data = {
                campaign: $scope.campaign,
                imageLinkPreview: $scope.facebookTask2Info.images[0].src
              }

              // - end of uploading second promotion task (local-plump)
              $scope.campaign.tasks.push($scope.facebookTask2Info)
            })
          })
          $scope.loading = true
          console.log('hello world', $scope.data)
          $api.post('preview-email-brand-new-campaign', $scope.data)
            .then(function (response) {
              $scope.loading = false
              $scope.body = response.data.html
            })
            .catch(function (reason) {
              $scope.loading = false
              console.log(reason)
            })
        }, function (res) {
          console.log('ERR', res)
        }, function (evt) {
          // progress
        })
        // $scope.imageLinkPreview = $scope.facebookTaskInfo.images[0].src;
      })

      $scope.valueTargetSearch = '7/9'
      $scope.valueLocalPlump = '9/11'
    }
    $scope.toTab9 = function () {
      $scope.campaign.emailColor = $scope.rgbPicker.color
      $scope.progressBar = 98
      $scope.activeTab = 9

      $scope.valueTargetSearch = '8/9'
      $scope.valueLocalPlump = '10/11'
    }
    $scope.toTab10 = function () {
      // -validate inputted emailadresses valid and match them with our DB
      if ($scope.validated == true) {
        $scope.activeTab = 10
        $scope.progressBar = 100
      } else {
        prompt({
          title: 'Emailadresssen niet gevalideerd',
          message: 'Voer minimaal één emailadres in en valideer emailadressen voordat u verder gaat.'
        })
      }
      $scope.valueTargetSearch = '9/9'
      $scope.valueLocalPlump = '11/11'
    }
    $scope.toTab11 = function () {
      $scope.progressBar = 98
      $scope.activeTab = 11
      $scope.campaign.tasks = []

      // upload images
      $scope.loading = true
      console.log($scope.orderTaskAdded)
      if ($scope.orderTaskAdded == true) {
        $scope.campaign.tasks.push($scope.orderTaskInfo)
      }

      Upload.upload({
        url: '/api/upload-promotion-images',
        data: {
          files: $scope.facebookTaskImages
          // 'campaign': JSON.stringify(campaign),
          // 'user': JSON.stringify($scope.user),
          // 'task': JSON.stringify(task)
        }
      }).then(function (res) {
        // Upload is succesfull, add the result to the scope, send the order and save the task
        if (res.data.files.length < 1) {
          // foutmelding tonen
          return
        }
        $scope.facebookTaskInfo.images = []
        res.data.files.forEach(function (data) {
          $scope.facebookTaskInfo.images.push({ alt: data.name, src: data.path })
        })
        Upload.upload({
          url: '/api/upload-promotion-images',
          data: {
            files: $scope.facebookTaskImages
            // 'campaign': JSON.stringify(campaign),
            // 'user': JSON.stringify($scope.user),
            // 'task': JSON.stringify(task)
          }
        }).then(function (res) {
          // Upload is succesfull, add the result to the scope, send the order and save the task
          if (res.data.files.length < 1) {
            // foutmelding tonen
            return
          }
          $scope.facebookTaskInfo.images = []
          res.data.files.forEach(function (data) {
            $scope.facebookTaskInfo.images.push({ alt: data.name, src: data.path })
          })

          // - prepare Email
          // res.data.files[0].src;
          $scope.data = {
            campaign: $scope.campaign,
            imageLinkPreview: $scope.facebookTaskInfo.images[0].src
          }

          $scope.campaign.tasks.push($scope.facebookTaskInfo)

          // - images of second promotion task (local plump)
          Upload.upload({
            url: '/api/upload-promotion-images',
            data: {
              files: $scope.facebookTask2Images
              // 'campaign': JSON.stringify(campaign),
              // 'user': JSON.stringify($scope.user),
              // 'task': JSON.stringify(task)
            }
          }).then(function (res) {
            // Upload is succesfull, add the result to the scope, send the order and save the task
            if (res.data.files.length < 1) {
              // foutmelding tonen
              return
            }
            $scope.facebookTask2Info.images = []
            res.data.files.forEach(function (data) {
              $scope.facebookTask2Info.images.push({ alt: data.name, src: data.path })
            })
            Upload.upload({
              url: '/api/upload-promotion-images',
              data: {
                files: $scope.facebookTask2Images
                // 'campaign': JSON.stringify(campaign),
                // 'user': JSON.stringify($scope.user),
                // 'task': JSON.stringify(task)
              }
            }).then(function (res) {
              // Upload is succesfull, add the result to the scope, send the order and save the task
              if (res.data.files.length < 1) {
                // foutmelding tonen
                return
              }
              $scope.facebookTask2Info.images = []
              res.data.files.forEach(function (data) {
                $scope.facebookTask2Info.images.push({ alt: data.name, src: data.path })
              })

              // - prepare Email
              // res.data.files[0].src;
              $scope.data = {
                campaign: $scope.campaign,
                imageLinkPreview: $scope.facebookTask2Info.images[0].src
              }

              // - end of uploading second promotion task (local-plump)
              $scope.campaign.tasks.push($scope.facebookTask2Info)
            })
          })
          $scope.loading = true
          console.log('hello world', $scope.data)
          $api.post('preview-email-brand-new-campaign', $scope.data)
            .then(function (response) {
              $scope.loading = false
              $scope.body = response.data.html
            })
            .catch(function (reason) {
              $scope.loading = false
              console.log(reason)
            })
        }, function (res) {
          console.log('ERR', res)
        }, function (evt) {
          // progress
        })
        // $scope.imageLinkPreview = $scope.facebookTaskInfo.images[0].src;
      })
      $scope.valueUpdate = '5/5'
    }
    $scope.toTab12 = function () {
      $scope.progressBar = 98
      $scope.activeTab = 3
    }
    $scope.fbCampPurpose = ['POST_ENGAGEMENT', 'EVENT_RESPONSES', 'LEAD_GENERATION', 'BRAND_AWARENESS', 'REACH', 'TRAFFIC', 'APP_INSTALLS', 'PAGE_LIKES']
    $scope.CallToAction = ['LEARN_MORE', 'DOWNLOAD', 'CALL_NOW', 'BOOK_NOW', 'GET_DIRECTIONS', 'CONTACT_US', 'GET_SHOWTIMES', 'NO_BUTTON']
    var searchItems = function (str, type) {
      var matches = []
      $scope.date = false
      $scope[type].forEach(function (con) {
        if (
          (con.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)) {
          matches.push(con)
        }
      })
      return matches
    }
    $scope.getCountryInit = function () {
      $scope.countryList = []
      $scope.regionList = []
      $scope.cityList = []
      campaignFact.getCountry().then(function (res) {
        if (res.error) {
          console.log('get country api gets error....', res)
        } else {
          if (res !== '') {
            $scope.countryList = []
            res.data.forEach(function (val, index) {
              if ($scope.countryList.indexOf(val.name) == -1) { $scope.countryList.push(angular.copy(val)) }
            })
          } else {
            console.log('data is not found')
          }
        }
      }, function (err) {
        console.log('error in country')
      })
    }
    $scope.searchCountry = function (str, countryList) {
      return searchItems(str, 'countryList')
    }
    $scope.searchRegion = function (str, regionList) {
      return searchItems(str, 'regionList')
    }
    $scope.searchCity = function (str, cityList) {
      var objCity = {
        regionID: $scope.region_key,
        query: str
      }
      campaignFact.getCity(objCity).then(function (res) {
        $scope.getCountry = false
        $scope.cityList = []
        if (res !== '') {
          res.data.forEach(function (val, index) {
            $scope.cityList.push(val)
          })
        } else {
          console.log('data is not found')
        }
      })
      return searchItems(str, 'cityList')
    }
    $scope.interestList = []
    $scope.searchInterest = function (str, interestList) {
      var objInterest = {
        query: str
      }
      campaignFact.getAdInterest(objInterest).then(function (res) {
        $scope.getCountry = false
        if (res !== '') {
          $scope.interestList = []
          res.data.forEach(function (val, index) {
            if ($scope.interestList.indexOf(val.name) == -1) {
              $scope.interestList.push(angular.copy(val))
            } else {
              console.log('false interest list')
            }
          })
        } else {
          console.log('data is not found')
        }
      })
      return searchItems(str, 'interestList')
    }
    $scope.countrySelected = function (data, err) {
      $scope.desc = data.description
      $rootScope.selectedCountryName = data.description.name
      $scope.supportsRegion = data.description.supports_region
      $scope.supportsCity = data.description.supports_city
      $scope.countryCODE = data.description.country_code
      $scope.date = false
      if ($scope.supportsRegion == true && $scope.supportsCity == true) {
        campaignFact.getRegion($scope.countryCODE).then(function (res) {
          $scope.getCountry = false
          $scope.regionList = []
          if (res !== '') {
            res.data.forEach(function (val, index) {
              $scope.regionList.push(val)
            })
            $scope.disableRegion = false
          } else {
            console.log('data is not found')
          }
        }, function (err) {
          console.log(err)
        })
      } else if ($scope.supportsRegion == true && $scope.supportsCity == false) {
        campaignFact.getRegion($scope.countryCODE).then(function (res) {
          $scope.getCountry = false
          $scope.regionList = []
          if (res !== '') {
            res.data.forEach(function (val, index) {
              $scope.regionList.push(val)
            })
          } else {
            console.log('data is not found')
          }
        }, function (err) {
          console.log(err)
        })
        $scope.disableRegion = false
      } else if ($scope.supportsRegion == false && $scope.supportsCity == true) {

      }
    }
    $scope.regionSelected = function (selected, err) {
      if (selected != undefined) {
        $rootScope.selectedRegionName = selected.description.name
        $scope.region_key = selected.description.key
        $scope.disableCity = false
      } else {
        console.log('region is not selected')
      }
    }
    $scope.citySelected = function (selected, err) {
      if (selected != undefined) {
        $rootScope.selectedCityName = selected.description.name
        $scope.city_key = selected.description.key
      } else {
        console.log('city is not selected')
      }
    }
    $scope.deleteChips = function (index) {
      $scope.selectedInterestData.splice(index, 1)
      $scope.facebookTaskInfo.fbPromotionSettings.interestId = $scope.selectedInterestData
      $scope.interestsSelect.splice(index, 1)
    }
    $scope.selectedInterestData = []
    $rootScope.interestId = []
    $scope.interestsSelect = []
    $scope.interestSelected = function (selected, err) {
      if (selected != undefined) {
        if ($scope.selectedInterestData.indexOf(selected.description.name) == -1) {
          var interestObj = {
            interestId: selected.description.id,
            interestName: selected.description.name,
            audience_size: selected.description.audience_size,
            topic: selected.description.topic
          }
          $scope.interestsSelect.push(interestObj)
          $rootScope.selectedInterestName = selected.description.name
          $rootScope.interestId.push(selected.description.id)
          $scope.selectedInterestData.push(selected.description.name)
          $scope.facebookTaskInfo.fbPromotionSettings.interestId = $scope.selectedInterestData
        } else {
          console.log('already added', $scope.selectedInterestData)
        }
        // $rootScope.selectedInterestName = selected.description.name;
        // $rootScope.interestId.push(selected.description.id);
        // $scope.selectedInterestData.push(selected.description);
      } else {
        console.log('interest is not selected')
      }
    }
    $scope.previewModelFunc = function (flag) {
      $rootScope.editFbModel = flag
      if (flag == false) {
        $rootScope.selectedCountryName = 'Netherlands'
        $rootScope.age_min = $scope.minEdt
        $rootScope.age_max = $scope.maxEdt
        $rootScope.genderPreview = $scope.adsetGender == '1' ? 'Male' : 'Female'
        $rootScope.diffDays = $scope.days
        $rootScope.budgetPreview = $scope.edtBudgetLifetime
      }
      $rootScope.campaign = {
        name: $scope.campaign.name,
        _id: $scope.campaign._id
      }
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/preview-new-campaign-modal.html',
        controller: 'retailerCampaignController',
        size: 'lg'
      })
    }
    $scope.storeSpinner = false
    $scope.storePreview = false
    $scope.fbPromotionFunc = function (data) {
      console.log('data campaign', data)
      if ($scope.storePreview == true) {
        $scope.storePreview = false
      }
      var date1 = data.fbPromotionSettings.promotionStartDate
      var date2 = data.fbPromotionSettings.promotionEndDate
      var timeDiff = Math.abs(date2.getTime() - date1.getTime())
      $rootScope.diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
      if ($rootScope.diffDays == 0 || $rootScope.diffDays < 0) {
        alert(constantFact.alertMsg[1].msg)
      } else {
        $rootScope.budgetPreview = data.fbPromotionSettings.budget
      }
      console.log('facebookTaskInfo', data)
      $scope.fbPromotion = data.fbPromotionSettings
      // $rootScope.budgetPreview = data.fbPromotionSettings.budget;
      var campData = {}
      $scope.storeSpinner = true
      if (!data.nl.description) {
        $scope.storeSpinner = false
        return alert(constantFact.alertMsg[5].msg)
      } else {
        $scope.imageDesc = data.nl.description
      }
      if (!data.nl.name && !data.fbPromotionSettings.goal) {
        $scope.storeSpinner = false
        return alert(constantFact.alertMsg[9].msg)
      } else {
        campData = {
          name: data.nl.name,
          status: 'PAUSED',
          objective: data.fbPromotionSettings.goal
        }
        var adSetsData = {
          lifetime_budget: parseInt(data.fbPromotionSettings.budget * 100 * $rootScope.diffDays),
          start_time: data.fbPromotionSettings.promotionStartDate,
          end_time: data.fbPromotionSettings.promotionEndDate,
          campaign_id: $scope.campaignID,
          bid_amount: data.fbPromotionSettings.budget * 100,
          billing_event: 'IMPRESSIONS',
          // 'promoted_object': {
          // 	'page_id': $rootScope.fbPageId
          // },
          optimization_goal: 'REACH',
          targeting: {
            age_max: data.fbPromotionSettings.ageMax,
            age_min: data.fbPromotionSettings.ageMin,
            genders: [data.fbPromotionSettings.gender],
            interests: $rootScope.interestId
          },
          status: 'PAUSED'
        }
        if ($scope.allgender == true) {
          delete adSetsData.targeting.gender
        }
        adSetsData.name = $rootScope.selectedCountryName + '-' + $rootScope.age_max
        adSetsData.targeting.geo_locations = {
          countries: [],
          regions: [],
          cities: [
            {
              key: $scope.city_key,
              radius: data.fbPromotionSettings.distance,
              distance_unit: 'mile'
            }
          ]
        }
        $rootScope.customLocationView = true
        $scope.adGeoLocation = adSetsData.targeting.geo_locations

        campaignFact.uploadImg($scope.fd, $scope.user.accountId).then(function (res) {
          if (res.status == 200) {
            console.log('res images', res)
            for (var i = 0; i < imgNameArray.length; i++) {
              if (res.data.images[imgNameArray[i]] != undefined) {
                $scope.imgMetaData = res.data.images[imgNameArray[i]]
              }
            }
            $scope.campaignCreateFunc(campData, adSetsData)
          } else {
            $scope.storeSpinner = false
            alert(res.data.error.error_user_msg)
          }
        }, function (err) {
          $scope.storeSpinner = false
          console.log(err)
        })
      }
    }
    $scope.campaignCreateFunc = function (campData, adSetsData) {
      console.log('campData', campData)
      campaignFact.postCampData(campData).then(function (res) {
        if (res.error) {
          $scope.storeSpinner = false
          $scope.storePreview = false
          alert(res.error.error_user_msg)
        } else {
          adSetsData.campaign_id = res.id
          $scope.campaignID = res.id
          console.log('campaignID', $scope.campaignID)
          $scope.createAdsetFunc(adSetsData)
        }
      }, function (error) {
        console.log('error exist', error)
      })
    }
    $scope.createAdsetFunc = function (adSetsData) {
      campaignFact.postAdsetData(adSetsData).then(function (res) {
        if (res.error) {
          $scope.storeSpinner = false
          $scope.storePreview = false
          campaignFact.deleteFbCampaign($scope.campaignID, $scope.user.access_token).then(function (res) {
            if (res.status == 200) {
              console.log('deleted ..', res)
            } else {
              $scope.storeSpinner = false
              alert(res.data.error.error_user_msg)
            }
          }, function (err) {
            $scope.storeSpinner = false
            console.log('error is', err)
          })
          alert(res.error.error_user_msg)
        } else {
          var adSetResponse = res
          console.log('adSetResponse', adSetResponse)
          $scope.createData(res, adSetsData)
        }
      }, function (err) {
        console.log(err)
      })
    }
    $scope.createData = function (adset, adSetsData) {
      var adSet = adset
      var imgHash = $scope.imgMetaData.hash
      var imgUrl = $scope.fbPromotion.siteUrl
      var creativeData = {
        name: 'Sample Creative',
        instagram_actor_id: $scope.instagramBid,
        object_story_spec: {
          link_data: {
            name: $scope.fbPromotion.headline,
            description: $scope.fbPromotion.newsFeed,
            call_to_action: { type: $scope.fbPromotion.callToAction, value: { link: $scope.fbPromotion.siteUrl } },
            image_hash: imgHash,
            link: imgUrl,
            message: $scope.imageDesc
          },
          page_id: $rootScope.fbPageId
          // "page_id": $rootScope.user.facebook.pageId,
        },
        access_token: $scope.user.access_token
      }
      $scope.adCreativeFunc(creativeData, adSet, adSetsData)
    }
    $scope.adCreativeFunc = function (creativeData, adSet, adSetsData) {
      campaignFact.getAdcreative(creativeData).then(function (res) {
        if (res.error) {
          $scope.storeSpinner = false
          $scope.storePreview = false
          campaignFact.deleteFbCampaign($scope.campaignID, $scope.user.access_token).then(function (res) {
            if (res.status == 200) {
              console.log('deleted ..', res)
            } else {
              $scope.storeSpinner = false
              alert(res.data.error.error_user_msg)
            }
          }, function (err) {
            $scope.storeSpinner = false
            console.log('error is', err)
          })
          alert(res.error.error_user_msg)
        } else {
          console.log('getAdcreative', res)
          var ad_set_id = adSet.id
          var creative_id = res.id

          $scope.adssDataobj = {
            name: 'My Ad',
            adset_id: ad_set_id,
            creative: { creative_id: creative_id },
            status: 'PAUSED',
            access_token: $scope.user.access_token
          }
          localStorage.setItem('adssData', JSON.stringify($scope.adssDataobj))
          // campaignFact.getAd($scope.adssData).then(function (res) {
          // 	if (res.error) {
          // 		alert(res.error.message);
          // 	} else {
          // 		console.log('res AD:', res);
          // 	}
          // });
          $scope.adCreatePrevDesktopFunc(res.id, $scope.user.access_token)
          $scope.adCreatePrevMobileFunc(res.id, $scope.user.access_token, adSetsData)
        }
      }, function (err) {
        console.log(err)
      })
    }
    $scope.adCreatePrevDesktopFunc = function (id, accessToken) {
      campaignFact.getadCreativePrevDesktop(id, accessToken).then(function (desktop) {
        if (desktop.error) {
          $scope.storeSpinner = false
          $scope.storePreview = false
          campaignFact.deleteFbCampaign($scope.campaignID, $scope.user.access_token).then(function (res) {
            if (res.status == 200) {
              console.log('deleted ..', res)
            } else {
              $scope.storeSpinner = false
              alert(res.data.error.error_user_msg)
            }
          }, function (err) {
            $scope.storeSpinner = false
            console.log('error is', err)
          })
          alert(desktop.error.message)
        } else {
          $rootScope.desktopPreview = desktop.data['0'].body
        }
      }, function (err) {
        console.log('error is', err)
      })
      campaignFact.getadPrevRightColumn(id, accessToken).then(function (column) {
        if (column.error) {
          $scope.storeSpinner = false
          $scope.storePreview = false
          alert(column.error.message)
          campaignFact.deleteFbCampaign($scope.campaignID, $scope.user.access_token).then(function (res) {
            if (res.status == 200) {
              console.log('deleted ..', res)
            } else {
              $scope.storeSpinner = false
              alert(res.data.error.error_user_msg)
            }
          }, function (err) {
            $scope.storeSpinner = false
            console.log('error is', err)
          })
        } else {
          $rootScope.columnPreview = column.data['0'].body
        }
      })
      if ($scope.fbPromotion.alsoOnInstagram == false || $scope.fbPromotion.alsoOnInstagram == undefined) {
        console.log('instagram preview hide')
        return true
      } else {
        campaignFact.getadPrevInstagram(id, accessToken).then(function (insta) {
          if (insta.error) {
            $scope.storeSpinner = false
            $scope.storePreview = false
            alert(insta.error.message)
            campaignFact.deleteFbCampaign($scope.campaignID, $scope.user.access_token).then(function (res) {
              if (res.status == 200) {
                console.log('deleted ..', res)
              } else {
                $scope.storeSpinner = false
                alert(res.data.error.error_user_msg)
              }
            }, function (err) {
              $scope.storeSpinner = false
              console.log('error is', err)
            })
          } else {
            $rootScope.isInstagramOrNot = campaignFact.isinstagramPrev
            $rootScope.instagramPreview = insta.data['0'].body
          }
        })
      }
    }
    $scope.adCreatePrevMobileFunc = function (id, accessToken, adSetsData) {
      console.log('prev mobile calld', adSetsData)
      campaignFact.getadCreativePrevMobile(id, accessToken).then(function (mobile) {
        if (mobile.error) {
          console.log('error in mobile preview')
          $scope.storeSpinner = false
          $scope.storePreview = false
          campaignFact.deleteFbCampaign($scope.campaignID, $scope.user.access_token).then(function (res) {
            if (res.status == 200) {
              console.log('deleted ..', res)
            } else {
              $scope.storeSpinner = false
              alert(res.data.error.error_user_msg)
            }
          }, function (err) {
            $scope.storeSpinner = false
            console.log('error is', err)
          })
          alert(mobile.error.message)
        } else {
          console.log(' mobile preview', mobile)
          $rootScope.mobilePreview = mobile.data['0'].body
          var dataReach = {
            adGeoLocation: adSetsData.targeting.geo_locations,
            ageMin: adSetsData.targeting.age_min,
            ageMax: adSetsData.targeting.age_max,
            adsetGender: Number(adSetsData.targeting.genders[0]),
            daily_budget: Number(adSetsData.bid_amount) / 100,
            interests: $rootScope.interestId
          }
          console.log('reachdataa', dataReach)
          $scope.getReachFunc(dataReach)
          $scope.storeSpinner = false
          $scope.previewDisabled = false
        }
      }, function (err) {
        console.log(err)
      })
    }
    $scope.getReachFunc = function (dataReach) {
      console.log('dataReach', dataReach)
      campaignFact.getAdReach(dataReach).then(function (adReach) {
        if (adReach.error) {
          $scope.storeSpinner = false
          $scope.storePreview = false
          campaignFact.deleteFbCampaign($scope.campaignID, $scope.user.access_token).then(function (res) {
            if (res.status == 200) {
              console.log('deleted ..', res)
            } else {
              $scope.storeSpinner = false
              alert(res.data.error.error_user_msg)
            }
          }, function (err) {
            $scope.storeSpinner = false
            console.log('error is', err)
          })
          alert(constantFact.alertMsg[12].msg)
        } else {
          console.log('adReach response', adReach)
          $scope.storeSpinner = false
          $scope.storePreview = true
          $rootScope.reachUsers = adReach.data.users
        }
      }, function (err) {
        console.log(err)
      })
    }
    $scope.openImgModal = function (index) {
      $scope.cropImgIndex = index
      $scope.myCropImg = $scope.myImage
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/crop-image-modal.html',
        controller: 'retailerCampaignController',
        size: 'lg',
        scope: $scope
      })
    }
    $scope.backTOPrevFunc = function () {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/preview-new-campaign-modal.html',
        controller: 'retailerCampaignController',
        size: 'lg',
        scope: $scope
      })
    }
    $scope.viewResultFunc = function () {
      var modalInstance = $uibModal.open({
        templateUrl: '../views/modal/preview-result-fb-modal.html',
        controller: 'retailerCampaignController',
        size: 'lg',
        scope: $scope
      })
    }
    $scope.switchToggle = function (toggle) {
      if (toggle.fbPromotionSettings.alsoOnInstagram == true) {
        if ($rootScope.fbPageId) {
          campaignFact.getPageToken($rootScope.fbPageId, $rootScope.adminmetaData.access_token).then(function (response) {
            $scope.pageToken = response.data.access_token
            campaignFact.getInstaAccount($rootScope.fbPageId, $scope.pageToken).then(function (resp) {
              if (resp.instagram_accounts) {
                console.log('instagram exist', resp.instagram_accounts.data[0].id)
                // localStorage.setItem('insta', JSON.stringify(true));
                $scope.instagramBid = resp.instagram_accounts.data[0].id
                campaignFact.isinstagramPrev = true
              } else {
                campaignFact.isinstagramPrev = false
                toggle.fbPromotionSettings.alsoOnInstagram = false
                alert(constantFact.alertMsg[10].msg)
              }
            })
          }, function (err) {
            console.log(err)
          })
        } else {
          alert(constantFact.alertMsg[0].msg)
          toggle.fbPromotionSettings.alsoOnInstagram = false
        }
      } else {
        console.log('toggle', toggle.fbPromotionSettings.alsoOnInstagram)
      }
    }
    $scope.doneImgModel = function (index, cropped) {
      $scope.myImage[index] = cropped
    }
    $scope.modalLikeFunc = function (modalData) {
      campaignFact.getPageToken($rootScope.fbPageId, $rootScope.adminmetaData.access_token).then(function (response) {
        if (response.status == 200) {
          console.log('pageTokem', response.data.access_token)
          $scope.page_token = response.data.access_token
          var objLike = {
            metric: 'page_fans',
            access_token: $scope.page_token,
            date_preset: modalData
          }
          campaignFact.getPageLikesFunc(objLike).then(function (resp) {
            console.log('resp page likes', resp.data[0].values)
            var arrChart = resp.data[0].values.filter(function (val) {
              return val.value != undefined
            })
            console.log('>>>>>>>>', arrChart, arrChart.length)
            $rootScope.prevPAgeLike = arrChart[arrChart.length - 1].value
            var chartDataArray = []
            for (var i = 0; i < arrChart.length; i++) {
              chartDataArray.push([Date.UTC(new Date(arrChart[i].end_time).getYear(), new Date(arrChart[i].end_time).getMonth(), new Date(arrChart[i].end_time).getDate()), arrChart[i].value])
            }
            $rootScope.arrChart = chartDataArray
            console.log('>>>>>>>>', chartDataArray)
            forChart()
          })
        } else {
          alert(constantFact.alertMsg[0].msg)
        }
      }, function (err) { console.log(err) })
    }
    $scope.initLikeFunc = function () {
      campaignFact.getPageToken($rootScope.fbPageId, $rootScope.adminmetaData.access_token).then(function (response) {
        if (response.status == 200) {
          console.log('pageTokem', response.data.access_token)
          $rootScope.page_token = response.data.access_token
          var objLike = {
            metric: 'page_fans',
            access_token: $rootScope.page_token,
            date_preset: 'this_month'
          }
          campaignFact.getPageLikesFunc(objLike).then(function (resp) {
            console.log('resp page likes', resp.data[0].values)
            var arrChart = resp.data[0].values.filter(function (val) {
              return val.value != undefined
            })
            console.log('>>>>>>>>', arrChart, arrChart.length)
            $rootScope.prevPAgeLike = arrChart[arrChart.length - 1].value
            var chartDataArray = []
            for (var i = 0; i < arrChart.length; i++) {
              chartDataArray.push([Date.UTC(new Date(arrChart[i].end_time).getYear(), new Date(arrChart[i].end_time).getMonth(), new Date(arrChart[i].end_time).getDate()), arrChart[i].value])
            }
            $rootScope.arrChart = chartDataArray
            console.log('>>>>>>>>', chartDataArray)
            forChart()
          })
        } else {
          // alert('Please Select a Facebook Page');
          alert(constantFact.alertMsg[0].msg)
        }
      })
    }
    // setTimeout(forChart, 1000);
    function forChart () {
      Highcharts.chart('chartContriner1', {
        chart: {
          type: 'spline'
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: { // don't display the dummy year
            month: '%e. %b',
            year: '%b'
          }
        },
        yAxis: {
          min: 0
        },
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: '{point.x:%e. %b}: {point.y:.2f} likes'
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: true
            }
          }
        },
        legend: {
          align: 'left',
          verticalAlign: 'top',
          x: 0,
          y: 0
        },
        series: [{
          name: 'Page Likes',
          data: $rootScope.arrChart,
          lineWidth: 1,
          lineColor: '#3b5998',
          marker: {
            fillColor: '#FFFFFF',
            lineColor: '#3b5998',
            lineWidth: 3
          }
        }]
      })
    }

    $scope.cancelOrderTask = function () {
      $scope.orderTaskInfo.nl.description = ''
      $scope.orderTaskInfo.orderLink = ''
      $scope.orderTaskInfo.mandatory = ''

      $scope.orderTaskAdded = false
    }

    $scope.orderTaskAddedFunction = function () {
      $scope.orderTaskAdded = true
    }

    $scope.cancelContribution = function () {
      $scope.facebookTaskInfo.contribution.percent = 0
      $scope.facebookTaskInfo.contribution.maxAmount = 0
      // cancel task of second promotion (local-plump)
      $scope.facebookTask2Info.contribution.percent = 0
      $scope.facebookTask2Info.contribution.maxAmount = 0
    }

    $scope.paymentInfoShow = false
    // console.log($scope.paymentInfoShow);
    $scope.checkboxPaymentInfo = function () {
      if (paymentInfoShow == true) {
        $scope.paymentInfoShow = false
      } else {
        $scope.paymentInfoShow = true
      }
      console.log('paymentInfoShow', $scope.paymentInfoShow)
    }

    // $scope.initPromotionTaskDate = function(task){
    // 	task.fbPromotionSettings.promotionStartDate = new Date(task.fbPromotionSettings.promotionStartDate);
    // 	task.fbPromotionSettings.promotionEndDate = new Date(task.fbPromotionSettings.promotionEndDate);
    // }

    // $scope.initUpdateTaskDate = function(task){
    // 	task.datePlanned = new Date(task.datePlanned)
    // }
    $scope.isSelectMoreImg = function (len) {
      if (len == 8) {
        alert('YOu can only select maximum 8 Images')
        return true
      } else {
        return false
      }
    }

    $scope.deleteFile = function (index) {
      $scope.facebookTaskImages.splice(index, 1)
      imgNameArray.splice(index, 1)
      $scope.myImage.splice(index, 1)
    }
    var imgNameArray = []
    $scope.myImage = []
    // $rootScope.selectImage = true;
    $scope.selectFiles = function (files, evt) {
      // $rootScope.selectImage = true;
      $scope.facebookTaskImages = files
      var fileread = new FileReader()
      var img = new Image()
      $scope.fd = new FormData()

      fileread.onload = function (load) {
        img.src = load.target.result
        $scope.myImage.push(load.target.result)
        $scope.$apply()
        img.onload = function () {
          $scope.loadImgHeight = this.height
          $scope.loadImgWidth = this.width
          if ($scope.loadImgWidth >= 500 && $scope.loadImgHeight >= 262) {
          } else {
            $scope.facebookTaskImages.splice($scope.facebookTaskImages.length - 1, 1)
            $scope.myImage.splice($scope.myImage.length - 1, 1)
            alert(constantFact.alertMsg[3].msg)
          }
        }
      }
      if (evt.target.files == undefined) {
        fileread.readAsDataURL(files[files.length - 1])
      } else {
        fileread.readAsDataURL(evt.target.files[0])
      }
      angular.forEach(files, function (file) {
        imgNameArray.push(angular.copy(file.name))
        $scope.fd.append('file', file)
      })

      $scope.fd.append('access_token', $scope.user.access_token)
      if ($scope.facebookTaskImages.length == 1) {
        $scope.facebookTaskInfo.fbPromotionSettings.presentation = 'image'
      } else {
        $scope.facebookTaskInfo.fbPromotionSettings.presentation = 'carrousel'
      }
      // $scope.facebookTaskInfo.fbPromotionSettings = $scope.selectedOption
      $scope.facebookTaskInfo.fbPromotionSettings.perDayOrSpread = 'perDay'
      // console.log('$scope.facebookTaskImages', $scope.facebookTaskImages, "imgNameArray", imgNameArray);;
    }

    $scope.selectFiles2 = function (files) {
      $scope.facebookTask2Images = files
      // console.log("files", files)
      // console.log("$scope.facebookTask2Images", $scope.facebookTask2Images)
      if ($scope.facebookTask2Images.length == 1) {
        $scope.facebookTask2Info.fbPromotionSettings.presentation = 'image'
      } else {
        $scope.facebookTask2Info.fbPromotionSettings.presentation = 'carrousel'
      }
      // $scope.facebookTaskInfo.fbPromotionSettings = $scope.selectedOption
      $scope.facebookTask2Info.fbPromotionSettings.perDayOrSpread = 'perDay'
    }

    $scope.emailAdressesChanged = function () {
      $scope.validated = false
    }

    // -validate inputted emailadresses
    $scope.validated = false
    $scope.validateEmailReceivers = function (emails) {
      $scope.campaign.emailReceivers.added = []
      $scope.campaign.emailReceivers.suggested = []

      // validate emailadresses if there are any
      if (emails != undefined) {
        function validateEmail (email) {
          var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
          return re.test(email)
        }

        emails = emails.split(';')

        var invalidEmails = []

        for (i = 0; i < emails.length; i++) {
          if (!validateEmail(emails[i].trim())) {
            invalidEmails.push(emails[i].trim())
          } else {
            $scope.campaign.emailReceivers.added.push({ email: emails[i].trim() })
          }
        }
        // Alert message if emails are invalid
        if (invalidEmails != '') {
          alert('Invalid emails: ' + invalidEmails)
          $scope.validated = false
        } else {
          $scope.validated = true
        }
      }

      // post valid adresses to server
      $api.post('match-emailaddr-with-shops', $scope.campaign)

        .then(function (response) {
          $scope.loading = false
          console.log(response)
          var suggestedShops = response.data.shops
          if ($scope.campaign.emailReceivers.mailSuggested == true) {
            for (i = 0; i < suggestedShops.length; i++) {
              if (suggestedShops[i].email) {
                $scope.campaign.emailReceivers.suggested.push({ email: suggestedShops[i].email, send: true })
              }
            }
          }
          // else{
          // 	for(i = 0; i < suggestedShops.length; i++){
          // 		if(suggestedShops[i].email){
          // 			$scope.campaign.emailReceivers.suggested.push({email: suggestedShops[i].email, send: false})
          // 		}
          // 	}
          // }
          console.log($scope.campaign.emailReceivers.added)
          $scope.shops = suggestedShops
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.validateOnlyAdded = function (emails) {
      $scope.campaign.emailReceivers.added = []

      // validate emailadresses if there are any
      if (emails != undefined) {
        function validateEmail (email) {
          var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
          return re.test(email)
        }

        emails = emails.split(';')

        var invalidEmails = []

        for (i = 0; i < emails.length; i++) {
          if (!validateEmail(emails[i].trim())) {
            invalidEmails.push(emails[i].trim())
          } else {
            $scope.campaign.emailReceivers.added.push({ email: emails[i].trim() })
          }
        }
        // Alert message if emails are invalid
        if (invalidEmails != '') {
          alert('Invalid emails: ' + invalidEmails)
          $scope.validated = false
        } else {
          $scope.validated = true
        }
      }
    }

    $scope.idSelectedShop = null
    $scope.setSelected = function (idSelectedShop) {
      $scope.idSelectedShop = idSelectedShop
      console.log(idSelectedShop)

      var receivers = $scope.campaign.emailReceivers.suggested
      var indexRec = receivers.indexOf(idSelectedShop)
      if (indexRec > -1) {
        receivers.splice(indexRec, 1)
      }
    }
    $scope.deleteShopEmaillist = function (index) {
      prompt({
        title: 'Shop verwijderen?',
        message: 'Weet u zeker dat u geen campagne verzoek wilt sturen naar deze winkel?'
      }).then(function () {
        console.log('deleteShopEmaillist', $scope.shops, $scope.campaign.emailReceivers)
        $scope.shops.splice(index, 1)
        $scope.campaign.emailReceivers.suggested[index].send = false
      })
    }

    $scope.sentCampaign = function () { // d
      $scope.campaign.nameSlug = $rootScope.slugify($scope.campaign.name)
      if ($scope.interestsSelect.length > 0) {
        $scope.campaign.tasks[0].fbPromotionSettings.interests = $scope.interestsSelect
      }
      $scope.campaign.images = []
      if ($scope.campaign.tasks[0].type == 'order') {
        if ($scope.campaign.tasks[1].images[0].src.indexOf('https://prismanotetest.s3.amazonaws.com') == -1) {
          var imageSecondTask = {
            src: $rootScope.awsUrl + $scope.campaign.tasks[1].images[0].src,
            alt: 'Campaign picture'
          }
        } else {
          var imageSecondTask = {
            src: $scope.campaign.tasks[1].images[0].src,
            alt: 'Campaign picture'
          }
        }
        $scope.campaign.images.push(imageSecondTask)
        $scope.campaign.tasks[0].fbPromotionSettings.description = $scope.campaign.description
      } else {
        for (var i = 0; i < $scope.campaign.tasks[0].images.length; i++) {
          if ($scope.campaign.tasks[0].images[i].src.indexOf('https://prismanotetest.s3.amazonaws.com') == -1) {
            var imageFirstTask = {
              src: $rootScope.awsUrl + $scope.campaign.tasks[0].images[i].src,
              alt: 'Campaign picture'
            }
          } else {
            var imageFirstTask = {
              src: $scope.campaign.tasks[0].images[i].src,
              alt: 'Campaign picture'
            }
          }

          $scope.campaign.images.push(imageFirstTask)
        }
        $scope.campaign.tasks[0].fbPromotionSettings.description = $scope.campaign.description
      }

      var dateNow = Date.now()
      $scope.campaign.creationDate = dateNow

      console.log($scope.campaign.creationDate)

      $api.post('campaigns', $scope.campaign)
        .then(function (response) {
          console.log(response)
          $scope.alert = {
            type: 'success',
            msg: 'Gelukt! Deze campagne is doorstuurd naar het PrismaNote team en zal binnen 24 uur worden geverifieerd. Hiervan ontvangt u een email op: info@youremailadress.com'
          }
          location.reload()
        })
        .catch(function (reason) {
          console.log(reason)
        })

      // - end old upload function
    }

    $scope.rgbPicker = {
      color: ''
    }

    $scope.$on('colorpicker-selected', function () {
      // get all divs whose id begins with 'color'
      var divs = document.querySelectorAll('[id^="color"]');
      [].forEach.call(divs, function (div) {
        // loop through all found divs and change the background color
        div.style['background-color'] = $scope.rgbPicker.color
      })
    })
  }])
