prismanoteApp.controller('loginModalController', ['$scope', '$rootScope', '$uibModalInstance', '$api', '$language', 'mode', 'tab', 'redirect', 'data', '$state', '$employee',
  function ($scope, $rootScope, $uibModalInstance, $api, $language, mode, tab, redirect, data, $state, $employee) {
    $scope.forgotmode = false
    $scope.registermode = false
    $scope.requestmode = true
    $scope.alert = null
    $scope.registerComplete = false // only true as the user succesfully registrered
    $scope.interest = {}
    $scope.verify = true

    $scope.mode = mode

    if ($scope.mode) {
      if ($scope.mode == 'register') {
        $scope.registermode = true
        // $scope.requestmode = false;
        $scope.requestmode = true

        $scope.newUser = {
          email: data && data.username ? data.username : null
        }
        // auto verify accounts only possible when the modal is directly opened in register mode
        if (data && (typeof data.verify === 'undefined' || data.verify == true)) {
          $scope.verify = true
        } else if (data && data.verify == false) {
          $scope.verify = false
          if (data.role) $scope.userRole = data.role
        }
      } else if ($scope.mode == 'forgot') {
        $scope.forgotmode = true
      } else if ($scope.mode == 'login') {
        $scope.forgotmode = false
        $scope.registermode = false
        $scope.requestmode = false

        $scope.username = data && data.username ? data.username : null
      } else if ($scope.mode == 'request') {
        $scope.requestmode = true
        $scope.registermode = true
        $scope.forgotmode = false
        $scope.today = new Date()
        $scope.interest.cs = { openend: false }
        if (tab) {
          $scope.interest[tab] = true
        }
      }
    } else {
      $scope.requestmode = false
      $scope.registermode = false
      $scope.forgotmode = false
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    function completeLogin (user, redirect) {
      $rootScope.user = user
      $rootScope.guest = false
      delete $scope.username
      delete $scope.password
      $scope.close()
      if ($rootScope.campaignIdFromEmailLink) {
        $state.go('retailer.paidcampaign', { isCampaignStep: 'join', campaignId: $rootScope.campaignIdFromEmailLink })
      } else if ($rootScope.isFromRecommendationLink) {
        $state.go('retailer.recommendation-order', { orderNo: '' });
        $rootScope.isFromRecommendationLink = undefined;
      } else if ($rootScope.purchaseOrderNumberFromEmailLink) {
        // If shop is different then they don't have access of that particular purchase order, so will show the alert message in the retailer-purchase-orders list
        // var currentShopSlug = localStorage.getItem('currentShop');
        // console.log('$rootScope.purchaseorder: ', $rootScope.purchaseorder.shopDetail.nameSlug, currentShopSlug);
        // if ($rootScope.purchaseorder && $rootScope.purchaseorder.shopDetail && currentShopSlug && currentShopSlug !== $rootScope.purchaseorder.shopDetail.nameSlug) {
        // 	$rootScope.showAlertMsgOfPurchaseOrder = true;
        // 	$state.go('retailer.purchase-orders');
        // } else {
        // 	$state.go('retailer.purchase-order', { orderNo:  $rootScope.purchaseOrderNumberFromEmailLink });
        // }
      } else {
        if (redirect) {
          var role = user.role == 'wholesaler' ? 'brand' : user.role
          $state.go(role + '.home')
        }
      }
    }

    $scope.errExist = false
    $scope.login = function () {
      $scope.alert = null
      $scope.loading = true
      $api.post('login', { username: $scope.username.toLowerCase(), password: $scope.password })
      .then(function (response) {
          $scope.loading = false

          if (response.data.mustReset) {
            $scope.alert = {
              type: 'info',
              msg: $language.translate('YOU_MUST_SET_A_NEW_PASSWORD_BEFORE_CONTINUING')
            }
            $scope.setNewPassword = true
          } else {
            completeLogin(response.data, redirect)
          }
        })
        .catch(function (reason) {
          $scope.loading = false
          console.error(reason)
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(reason)
          }
        })
    }

    $scope.setPassword = function () {
      if ($scope.newPassword1 != $scope.newPassword2) {
        $scope.loading = false
        return $scope.alert = {
          type: 'danger',
          msg: $language.translate('PASSWORD_ARE_CONFIRM_ARE_NOT_EQUAL')
        }
      } else {
        var isValidPassword = passwordValidation($scope.newPassword1, $scope.newPassword1)
        if (isValidPassword.isValid) {
          $scope.alert = null
          $scope.loading = true
          $api.post('set-new-password', {
            username: $scope.username,
            newPassword1: $scope.newPassword1,
            newPassword2: $scope.newPassword2
          })
            .then(function (response) {
              $scope.loading = false
              if (response.data.success && response.data.user) {
                completeLogin(response.data.user, redirect)
              }
            })
            .catch(function (reason) {
              $scope.loading = false
              console.error(reason)
              $scope.alert = {
                type: 'danger',
                msg: $language.translate(reason)
              }
            })
        } else {
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(isValidPassword.message)
          }
        }
      }
    }

    $scope.closeAlert = function () {
      $scope.alert = null
    }

    $scope.forgot = function () {
      $scope.alert = null
      $api.post('send-password-reset-link', {
        email: $scope.username,
        language: $rootScope.language
      })
        .then(function (response) {
          $scope.alert = {
            type: 'success',
            msg: response.data.message
          }
        })
        .catch(function (reason) {
          $scope.alert = {
            type: 'danger',
            msg: reason
          }
        })
    }

    $scope.close = function () {
      $uibModalInstance.close(mode)
    }

    $scope.register = function (isValid, request) {
      $scope.alert = null
      if (isValid) {
        if (!$scope.terms || $scope.terms === false) {
          $scope.alert = {
            type: 'danger',
            msg: $language.translate('YOU_MUST_ACCEPT_OUR_TERMS')
          }
        }
        var isValidPassword = passwordValidation($scope.newUser.password, $scope.newUser.passwordCompare)
        if (isValidPassword.isValid) {
          $scope.alert = null
          var body = {
            user: $scope.newUser,
            request: request,
            extraData: request ? $scope.interest : null,
            verify: $scope.verify,
            userRole: $scope.userRole ? $scope.userRole : null,
            isComingFromCampaign: !!$rootScope.campaignIdFromEmailLink,
            campaignIdFromEmailLink: $rootScope.campaignIdFromEmailLink,
            isComingFromPurchaseOrder: {
              iComingFromLink: !!$rootScope.purchaseOrder,
              purchaseOrder: $rootScope.purchaseOrder
            },
            language: $rootScope.language
          }
          $api.post('register', body)
            .then(function (response) {
              $scope.alert = null
              // console.log('$rootScope.campaignIdFromEmailLink: ', $rootScope.campaignIdFromEmailLink);
              $scope.showMessage = true
              $scope.successessage = response.data.message
              if (response.data.user) {
                // $rootScope.user = response.data.user
                if ($rootScope.campaignIdFromEmailLink) $scope.close()
              } else if (response.data.updatedUser) {
                // $rootScope.user = response.data.updatedUser;
                if ($rootScope.campaignIdFromEmailLink) $scope.close()
              }
              $scope.registerComplete = true
            })
            .catch(function (reason) {
              $scope.alert = {
                type: 'info',
                msg: $language.translate(reason.data.message)
              }
            })
        } else {
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(isValidPassword.message)
          }
        }
      }
    }

    // Checking password is strong or not and valid or not
    function passwordValidation (originalPassword, comparedPassword) {
      if (!originalPassword || !comparedPassword) {
        return { isValid: false, message: 'PASSWORD_AND_CONFIRM_ARE_REQUIRED' }
      }

      if (originalPassword.length < 8 || originalPassword.length > 60) {
        return { isValid: false, message: 'YOUR_PASSWORD_LENGTH_SHOULD_BE_AT_LEAST_8_CHARACTERS_LONG' }
      }

      if (originalPassword !== comparedPassword) {
        return { isValid: false, message: 'PASSWORD_ARE_CONFIRM_ARE_NOT_EQUAL' }
      }

      // Password contains Lower, Uper and Digits or not
      var variations = {
        digits: /\d/.test(originalPassword),
        lower: /[a-z]/.test(originalPassword),
        upper: /[A-Z]/.test(originalPassword),
        nonWords: /\W/.test(originalPassword)
      }
      var isVariation = true

      for (var check in variations) {
        if (!variations[check]) {
          isVariation = false
          break
        }
      }

      if (!isVariation) {
        return { isValid: false, message: 'YOUR_PASSWORD_SHOULD_CONTAINS_AT_LEAST_ONE_UPPERCASE_LOWERCASE_NUMBER_AND_SPECIAL_CHARACTER' }
      } else {
        return { isValid: true, message: 'PASSWORD_IS_VALID' }
      }
    }

    $scope.logout = function () {
      var obj;
      if($rootScope.user.role == 'retailer'){
        obj = { id: $rootScope.user._id, shopId: $rootScope.currentShop._id };
      }else if($rootScope.user.role == 'wholesaler'){
        obj = { id: $rootScope.user._id, companyId : $rootScope.currentCompany._id }
      }else{
        obj = { id: $rootScope.user._id }
      }
      $api.post('logout', obj).then(function () {
        $scope.alert = null
        $rootScope.user = null
        $rootScope.openChats = []
        $rootScope.currentEmployee = undefined
        $rootScope.currentShop = undefined;
        localStorage.removeItem('currentShop')
        localStorage.removeItem('currentShopName');
        localStorage.removeItem('isEnableEmployeeLogin')
        localStorage.removeItem('lastLoggedInEmployees')
        window.fcWidget.user.clear().then(function(){
          console.info('User cleared from chat')
        }, function(reason){
          console.info('User not cleared from chat', reason)
        })
        $scope.close()
        $state.reload();
        $state.go('layout.logout')
        $employee.lockEmployee()
      }).catch(function (reason) {
        console.error(reason)
      })
    }

    $scope.openEmployeeModal = function () {
      $scope.cancel()
      $employee.openEmployeeModal()
    }
  }])
