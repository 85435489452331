prismanoteApp.controller('welcomeRetailerController', ['$scope', '$rootScope', '$location', '$api', '$state', '$stateParams', '$retailer',
  function ($scope, $rootScope, $location, $api, $state, $stateParams, $retailer) {
    console.log('welcomeRetailerController: called', $stateParams)
    // $scope.loading = true;
    $scope.addToShop = false
    $scope.readyToGo = false
    $rootScope.isAPIFired = false

    $scope.loadPage = function () {
      $api.post('load-retailer-welcome', { hashedData: $stateParams.hashedData })
        .then(function (response) {
          console.log('welcome retailer response: ', response)
          $scope.loading = false
          $scope.data = response.data.data;
          if (response.data.campaign) {
            $scope.campaign = response.data.campaign
            $scope.loading = false
            $rootScope.campaignIdFromEmailLink = $scope.data.campaign
            $rootScope.invitationEmail = $scope.data.email
            // console.log('welcome retailer: ', $rootScope.campaignIdFromEmailLink);
            // 207 - Not logged in
            // 203 - Different account
            // 200 - Perfect

            if (response.status == 207) {
              console.log('207')
              // $state.go('layout.home');

              $scope.accessDenied = true
              $scope.username = $scope.data.email
              $scope.newUser = {
                email: $scope.data.email
              }
            } else if (response.status == 203) {
              // if ($scope.user && $scope.user.role != 'retailer') {
              //     $state.transitionTo("layout.access-denied", { to: 'layout.welcome-retailer' });
              // } else {
              //     $scope.loading = false;
              //     $scope.differentUser = true;
              // }
              console.log('203')
              // $state.go('layout.home');
              $state.go('retailer.paidcampaign', { isCampaignStep: 'join', campaignId: $scope.data.campaign }) // TEsting
            } else {
              console.log('200')
              $state.go('retailer.paidcampaign', { isCampaignStep: 'join', campaignId: $scope.data.campaign })
            }
            // if (response.status == 207) {
            //     $scope.loading = false;
            //     $scope.accessDenied = true;
            //     $scope.username = $scope.data.email;
            //     $scope.newUser = {
            //         email: $scope.data.email
            //     }
            //     //not logged in, we are done yet
            // } else if (response.status == 203) {
            //     if ($scope.user && $scope.user.role != 'retailer') {
            //         $state.transitionTo("layout.access-denied", { to: 'layout.welcome-retailer' });
            //     } else {
            //         $scope.loading = false;
            //         $scope.differentUser = true;
            //     }
            // } else {
            //     //200 OK
            //     $scope.checkBeforeExecute();
            //     $scope.addToShop = true;
            //     $scope.loading = false;
            // }
          } else if (response.data.purchaseorder) {
            /**
             * 1. FOR USER EMAIL ADDRESS SELECT FROM THE COMPANY PURCHASE ORDER
             *    A. If not loggedin(207) and after loggedIn that user has access of the  purchase order's shop then grant access.
             *    B. If not loggedin(207) and after loggedIn that user has does access of the purchase order's shop then do not grant access.
             *    C. If loggedIn(200) and have access of the purchase order's shop then redirect to the purchase order
             *    D. If loggedIn(203) and does not have access of the shop then do not redirect to the purchase order
             */
            $scope.loading = false
            $rootScope.purchaseorder = response.data.purchaseorder
            $rootScope.purchaseOrderNumberFromEmailLink = $scope.data.purchaseOrderNumber
            $rootScope.invitationEmail = $scope.data.email
            console.log('welcome retailer: ', $rootScope.purchaseOrderNumberFromEmailLink)
            if (response.status == 207) {
              console.log('207')
              $scope.accessDenied = true
              $scope.username = $scope.data.email
              $scope.newUser = {
                email: $scope.data.email
              }
              $state.go('layout.login')
            } else if (response.status == 203) {
              console.log('203')
              // $state.go('layout.home');
              $state.go('retailer.purchase-orders')
            } else {
              console.log('200')
              // If shop is different then they don't have access of that particular purchase order, so will show the alert message in the retailer-purchase-orders list
              var currentShopSlug = localStorage.getItem('currentShop')
              if (!currentShopSlug) {
                currentShopSlug = $rootScope.user && $rootScope.user.shops && $rootScope.user.shops.length ? $rootScope.user.shops[0].nameSlug : undefined
              }
              // console.log('$rootScope.purchaseorder: ', $rootScope.purchaseorder.shopDetail.nameSlug, currentShopSlug);
              if ($rootScope.purchaseorder && $rootScope.purchaseorder.shopDetail && currentShopSlug !== $rootScope.purchaseorder.shopDetail.nameSlug) {
                $rootScope.showAlertMsgOfPurchaseOrder = true
                $state.go('retailer.purchase-orders')
              } else {
                $state.go('retailer.purchase-order', { orderNo: $scope.data.purchaseOrderNumber })
              }
            }
          } else if (response && response.data && response.data.data && response.data.data.isFromRecommendationLink &&
            response.data.data.companyId && response.data.data.shopId) {
              console.log('HELLO FOR EMAIL: ', response.data);
            // $rootScope.recommendationCompanyId = response.data.data.companyId;
            $rootScope.recommendationShopId = response.data.data.shopId;
            $rootScope.recommendationEmail = response.data.data.email;
            $rootScope.isFromRecommendationLink = true;

            if (response.status == 207) {
              console.log('207: NOT LOGGED IN');
              $rootScope.recommendationShopSlug = response.data.data.shopSlug;
              $state.go('layout.login');
            } else {
              console.log('welcome retailer isFromRecommendationLink: ', response.data.data, $rootScope.currentShop, $rootScope.selectedShop);
              var nameSlug = response.data.data.shopSlug;
              console.log('nameSlug: ', nameSlug);
              $rootScope.selectedShop = nameSlug;
              $retailer.getShop(nameSlug).then(function (shop) {
                console.log('Welcome reatiler 127: ', shop);
                $rootScope.currentShop = shop;
                //$rootScope.socketInit()
                $rootScope.openChats = []
                // resetCurrentFilters();

                // $data.loadData(show, force)
                $scope.$broadcast('changeSelectedShop', nameSlug)
                localStorage.setItem('currentShop', $scope.currentShop.nameSlug)
                localStorage.removeItem('lastLoggedInEmployees') // Removing old localstorage employee if any
                $rootScope.unreadNotifications = shop.unreadNotifications
                $rootScope.unreadMessageNotifications = shop.messageNotification.length

                if ($rootScope.unreadNotifications > 0) {
                  var title = 'PrismaNote'
                  if ($state.$current.data && $state.$current.data.pageTitle) {
                    title = title + ' | ' + $language.translate($state.$current.data.pageTitle)
                  }
                  // title = '(' + $rootScope.unreadNotifications + ') ' + title
                  $rootScope.pageTitle = title
                  // Write also direct to document to show the result directly
                  document.title = title
                }
                $state.go('retailer.recommendation-order', { orderNo: '' });
              }).catch(function (reason) {
                console.log('reason: reason: ', reason);
              })
            }
          }
        }).catch(function (reason) {
          $scope.loading = false
          $rootScope.welcome_alert = {
            type: 'danger',
            msg: reason && reason.data && reason.data.message ? reason.data.message : 'Internal Server Error'
          }
          setTimeout(function () {
            $rootScope.welcome_alert = undefined
          }, 2000)
          // console.log('reason: 123', $scope.alert, reason);
        })
    }

    if (!$rootScope.isAPIFired) {
      setTimeout(function () {
        if (!$rootScope.isAPIFired) {
          console.log('$rootScope.isAPIFired: ', $rootScope.isAPIFired)
          $rootScope.isAPIFired = true
          $scope.loadPage()
        }
      }, 500)
    }

    $scope.checkBeforeExecute = function () {
      if ($scope.user.facebook && $scope.user.facebook.postTo == 'page') {
        $scope.execute()
      } else {
        if ($scope.user.facebook && $scope.user.facebook.profileId) {
          $scope.getFacebookPages()
        }
      }
    }

    $scope.continue = function () {
      $scope.addToShop = true
      $scope.differentUser = false
      $scope.checkBeforeExecute()
      $scope.readyToGo = true
    }

    $scope.getFacebookPages = function () {
      if ($scope.user && $scope.user.facebook && $scope.user.facebook.profileId) {
        $api.get('facebook/get-pages')

          .then(function (response) {
            $scope.pages = response.data.pages
          })
          .catch(function (reason) {
            console.log(reason)
          })
      }
    }

    $scope.setFacebookPage = function (selectedPage) {
      $scope.user.facebook.postTo = 'page'
      $scope.user.facebook.pageToken = selectedPage.access_token
      $scope.user.facebook.pageId = selectedPage.id
      $scope.user.facebook.pageName = selectedPage.name

      $scope.saveUser()
      $scope.readyToGo = true
    }

    $scope.saveUser = function () {
      $api.put('user/' + $scope.user._id, { user: $scope.user })
        .then(function () {

        })
        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.execute = function () {
      console.log('execute')
      $api.post('add-campaign-to-shop', { campaign: $scope.campaign._id })
        .then(function (response) {
          console.log('ex res', response)
          $state.go('retailer.campaign', { nameSlug: $scope.campaign.nameSlug })
        })
        .catch(function (reason) {
          console.log('ex reas', reason)
          if (reason.status == 206) {
            $state.go('retailer.campaign', { nameSlug: $scope.campaign.nameSlug })
          }
          $scope.alert = {
            type: 'warning',
            msg: reason
          }
          $scope.readyToGo = false
        })
    }
  }])
