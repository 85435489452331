prismanoteApp.controller('companyRegisterController',
    ['$scope', '$rootScope', '$stateParams', '$user', '$language', '$state',
        function ($scope, $rootScope, $stateParams, $user, $language, $state) {

            // // When USER Click on the verification link from email
            // if ($stateParams && $stateParams.id && $stateParams.code) {
            //     console.log('StateParams: ', $stateParams);
            //     $user.verifyUser($stateParams).then(function (response) {
            //         console.log('User verified successfully');
            //     }).catch(function (error) {
            //         console.log('User is not verified: ', error);
            //     });
            // }

            function initialize() {
                $scope.registerDetails = {
                    form: {},
                    email: $stateParams.email ? $stateParams.email : null
                }
                $scope.activateDetails = {
                    form: {}
                }
                $scope.formSubmitted = false
            }

            function redirectUser() {
                var role = $rootScope.user.role == 'wholesaler' ? 'brand' : $rootScope.user.role
                $state.go(role + '.home');
            }

            if ($rootScope.user) {
                redirectUser();
            } else {
                initialize();
                if ($stateParams.email) {
                    document.getElementById('pnPassword1').focus();
                }
            }

            $scope.togglePassword = function () {
                var e1 = document.getElementById('pnPassword1');
                var e2 = document.getElementById('pnPassword2');
                if (!e1 || !e2) return;
                if (e1.type === 'password') {
                    e1.type = 'text';
                    e2.type = 'text';
                    $('.fa-eye').show();
                    $('.fa-eye-slash').hide();
                } else {
                    e1.type = 'password';
                    e2.type = 'password';
                    $('.fa-eye').hide();
                    $('.fa-eye-slash').show();
                }
            }

            $scope.pwdNormal = function () {
                if (!$scope.registerDetails.password) return
                return $scope.registerDetails.password.match(/[a-z]/m)
            }

            $scope.pwdCapital = function () {
                if (!$scope.registerDetails.password) return
                return $scope.registerDetails.password.match(/[A-Z]/m)
            }

            $scope.pwdSpecial = function () {
                if (!$scope.registerDetails.password) return
                return $scope.registerDetails.password.match(/[^a-zA-Z0-9]/gm)
            }

            $scope.pwdNumber = function () {
                if (!$scope.registerDetails.password) return
                return $scope.registerDetails.password.match(/\d/gm)
            }

            $scope.pwdLength = function () {
                if (!$scope.registerDetails.password) return
                return $scope.registerDetails.password.length > 7
            }

            $scope.register = function () {
                $scope.alert = null;
                $scope.formSubmitted = false;
                if ($scope.registerDetails.form.$invalid) return;

                if (!$scope.pwdNormal() || !$scope.pwdCapital() || !$scope.pwdSpecial() || !$scope.pwdNumber() || !$scope.pwdLength()) {
                    $scope.alert = {
                        type: 'danger',
                        msg: $language.translate('PASSWORD_DOES_NOT_MEET_REQUIREMENTS')
                    }
                    return;
                } else if ($scope.registerDetails.password != $scope.registerDetails.password2) {
                    $scope.alert = {
                        type: 'danger',
                        msg: $language.translate('MESSAGE_NOTMATCHINGPASSWORD_REGISTER_FORM')
                    }
                    return;
                } else {
                    var body = {
                        userRole: 'wholesaler',
                        extraData: {
                            companyname: $scope.registerDetails.companyname,
                        },
                        parsedData: $rootScope.parsedData,
                        user: {
                            password: $scope.registerDetails.password,
                            passwordCompare: $scope.registerDetails.password2,
                            email: $scope.registerDetails.email
                        },
                        language: $rootScope.language
                    }

                    console.log('BODY: ', body);
                    $user.companyRegisterUser(body).then(function (response) {
                        if ($rootScope.invitationEmail) {
                            $rootScope.invitationEmail = undefined;
                            console.log('response: ', response);
                            console.log('$rootScope.parsedData called: ', JSON.parse(JSON.stringify($rootScope.parsedData)));
                            if ($rootScope.parsedData) {
                                var linkedata = JSON.parse(JSON.stringify($rootScope.parsedData));
                                console.log('$rootScope.parsedData: 4');
                                $rootScope.parsedData = undefined;
                                console.log('linkedata: ', linkedata);
                                if (linkedata.isShopDetail === true || linkedata.isShopDetail === 'true') {
                                    $rootScope.user = response.data.data;
                                    $state.go('brand.shop-detail', { nameSlug: linkedata.shopSlug });
                                } else if (linkedata.isPurchaseOrder === true || linkedata.isPurchaseOrder === 'true') {
                                    $rootScope.user = response.data.data;
                                    console.log('linkeddata isPurchaseOrder: ', linkedata.isPurchaseOrder, $rootScope.user);
                                    $state.go('brand.purchase-order', { orderNo: linkedata.purchaseOrderNumber })
                                } else {
                                    $scope.formSubmitted = true;
                                    $scope.alert = { type: 'success', msg: $language.translate(response.data.message) }
                                }
                            }
                        } else {
                            $scope.formSubmitted = true;
                            $scope.alert = { type: 'success', msg: $language.translate(response.data.message) }
                        }
                    }).catch(function (error) {
                        console.log('register error: ', error);
                        $scope.alert = { type: 'danger', msg: $language.translate(error.data ? error.data.message : error) }
                    });
                }
            }
        }
    ])
