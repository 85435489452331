prismanoteApp.controller('retailerGoldPurchaseController', ['$scope', '$q', '$rootScope', '$api', '$stateParams', '$uibModal', '$state', '$printer', '$pdf', '$language', '$retailer', 'prompt', 'Lightbox', '$transaction', 'dialogs', '$filter', '$customer', '$employee', '$shop', '$jspm',
  function ($scope, $q, $rootScope, $api, $stateParams, $uibModal, $state, $printer, $pdf, $language, $retailer, prompt, Lightbox, $transaction, dialogs, $filter, $customer, $employee, $shop, $jspm) {

    $scope.getTransaction = function () {
      $transaction.getTransaction($stateParams.transactionId, true, false, $rootScope.currentShop._id)
        .then(function (transaction) {
          if (transaction.length && transaction.length > 0) {
            $scope.transaction = transaction[0]
          } else {
            $scope.transaction = transaction
          }
          if ($scope.transaction) {
            $scope.originalTransaction = JSON.parse(JSON.stringify($scope.transaction))
          }

          $scope.intakeDateChanged = false
          $scope.repairDateChanged = false

          $rootScope.transactionType = $scope.transaction.type
          // Check the ID of this transaction with the stateParams,
          // if they are not the same, look into the transaction to get the right transaction and modify the object
          if ($scope.transaction._id === $stateParams.transactionId) {
            loadTransaction()
          } else {
            // Loop through the transaction.transactions to get the right transaction and to rebuild the object
            var originalTransaction = angular.copy($scope.transaction)
            for (var i = 0; i < originalTransaction.transactions.length; i++) {
              if (originalTransaction.transactions[i]._id === $stateParams.transactionId) {
                // Found the right transaction
                var newTransaction = angular.copy(originalTransaction.transactions[i])
                delete originalTransaction.transactions[i]
                newTransaction.transactions = new Array(originalTransaction)
                $scope.transaction = newTransaction
                return loadTransaction()
              }
            }
          }

          if ($scope.transaction) {
            $scope.originalTransaction = JSON.parse(JSON.stringify($scope.transaction))
          }

          function loadTransaction () {
            $scope.receiptNumbers = []
            // Parse the date again as date to correctly prefil the datepickers
            $scope.transaction.dateCreated = new Date($scope.transaction.dateCreated)
            if ($scope.transaction[$scope.transaction.type] && $scope.transaction[$scope.transaction.type].estimatedDate) {
              $scope.transaction[$scope.transaction.type].estimatedDate = new Date($scope.transaction[$scope.transaction.type].estimatedDate)
            }
            if ($scope.transaction.type === 'offer') {
              $scope.transaction.offer.expireDate = new Date($scope.transaction.offer.expireDate)
            }

            if ($scope.transaction.status === 'processing') {
              if ($scope.transaction.repair.repairerType === 'in-house') {
                if (!$scope.transaction.repair.repairer) {
                  $scope.isRepairMySelf = true
                }
              }
            }

            if ($scope.transaction.type === 'refund') {
              $scope.totalChange = 0
              var totalPaidByPaymentMethods = 0
              $scope.payMethods = []
              for (var i = 0; i < $scope.transaction.receipt.payments.length; i++) {
                $scope.payMethods.push({
                  method: $language.translate($scope.transaction.receipt.payments[i].method.toUpperCase()),
                  originalMethod: $scope.transaction.receipt.payments[i].method.toUpperCase(),
                  amount: $scope.transaction.receipt.payments[i].amount,
                  change: $scope.transaction.receipt.payments[i].change,
                  giftcardNumber: $scope.transaction.receipt.payments[i].giftcardNumber,
                  card: $scope.transaction.receipt.payments[i].method.toUpperCase() === 'CARD',
                  cardName: $scope.transaction.receipt.payments[i].cardName ? $scope.transaction.receipt.payments[i].cardName : ''
                })
                totalPaidByPaymentMethods += $scope.transaction.receipt.payments[i].amount
              }
              $scope.totalChange = totalPaidByPaymentMethods - $scope.transaction.transactionTotal
            }

            if ($scope.transaction.type === 'shop-purchase') {
              if ($scope.transaction.details && $scope.transaction.details.length > 0) {
                $scope.transaction.totalAmount = 0
                for (var i = 0; i < $scope.transaction.details.length; i++) {
                  if ($scope.transaction.details[i].total) {
                    $scope.transaction.totalAmount += $scope.transaction.details[i].total
                  } else {
                    $scope.transaction.totalAmount += $scope.transaction.details[i].quantity * $scope.transaction.details[i].price
                  }
                }
              }

              $scope.payMethods = []
              $scope.totalChange = 0
              if (!$scope.transaction.receipt || !$scope.transaction.receipt.payments) {
                return
              }

              var totalPaidByPaymentMethods = 0

              for (var i = 0; i < $scope.transaction.receipt.payments.length; i++) {
                $scope.payMethods.push({
                  method: $language.translate($scope.transaction.receipt.payments[i].method.toUpperCase()),
                  originalMethod: $scope.transaction.receipt.payments[i].method.toUpperCase(),
                  amount: $scope.transaction.receipt.payments[i].amount,
                  change: $scope.transaction.receipt.payments[i].change,
                  giftcardNumber: $scope.transaction.receipt.payments[i].giftcardNumber,
                  card: $scope.transaction.receipt.payments[i].method.toUpperCase() == 'CARD',
                  cardName: $scope.transaction.receipt.payments[i].cardName ? $scope.transaction.receipt.payments[i].cardName : ''
                })
                totalPaidByPaymentMethods += $scope.transaction.receipt.payments[i].amount
              }
              var totalAmount = $scope.transaction.transactionTotal < 0 ? $scope.transaction.transactionTotal * -1 : $scope.transaction.transactionTotal
              $scope.totalChange = totalPaidByPaymentMethods - totalAmount

              if ($scope.transaction.transactions && $scope.transaction.transactions.length > 0) {
                for (var i = 0; i < $scope.transaction.transactions.length; i++) {
                  if ($scope.transaction.transactions[i].type == 'refund') {
                    $scope.refund = $scope.transaction.transactions[i]
                    $scope.disableRefund = true
                  }
                }
              }
            }

            if ($scope.transaction.type === 'shop-purchase' || $scope.transaction.type === 'offer' || $scope.transaction.type === 'refund') {
              $scope.totalTax = 0
              $scope.taxes = {}
              var detailsLength = $scope.transaction.details.length
              for (var i = 0; i < detailsLength; i++) {
                var detail = $scope.transaction.details[i]

                $scope.totalTax += $scope.taxPrice(detail)
                if (!$scope.taxes[detail.priceVat]) {
                  $scope.taxes[detail.priceVat] = detail.total
                } else {
                  $scope.taxes[detail.priceVat] += detail.total
                }

                // Now lower those values with the total discount on the offer (if applied)
                if ((i + 1) === detailsLength && $scope.transaction.discount) {
                  var totalDiscount = $scope.getDiscount()
                  var totalPrice = $scope.getTotalPrice(false)

                  // Now loop through the taxes table and edit the values based on the value, sow more discount will be applied to a rule with an higher amount
                  for (var t in $scope.taxes) {
                    var originalRate = $scope.taxes[t]
                    var ratePriceWithDiscount = $scope.taxes[t] * (totalDiscount / totalPrice)
                    $scope.taxes[t] = originalRate - ratePriceWithDiscount
                  }
                }
              }
            }

            if ($scope.transaction.type !== 'shop-purchase' || $scope.transaction.type !== 'warranty' || $scope.transaction.type === 'webshop') {
              if ($scope.transaction.transactions) {
                $scope.payments = []
                $scope.prepaid = 0
                for (var a = 0; a < $scope.transaction.transactions.length; a++) {
                  $scope.receiptNumbers.push({
                    number: $scope.transaction.transactions[a].number,
                    _id: $scope.transaction.transactions[a]._id,
                    date: $scope.transaction.transactions[a].dateCreated
                  })

                  for (b = 0; b < $scope.transaction.transactions[a].receipt.payments.length; b++) {
                    var payment = $scope.transaction.transactions[a].receipt.payments[b]
                    $scope.payments.push({
                      amount: payment.amount,
                      method: payment.method,
                      date: payment.date,
                      deposit: payment.deposit,
                      change: payment.change
                    })
                    if (payment.deposit) {
                      if (!payment.change) {
                        payment.change = 0
                      }
                      $scope.prepaid += (payment.amount - payment.change)
                    }
                  }
                }
              }
            }

            if ($scope.transaction.type === 'refund') {
              if ($scope.transaction.details && $scope.transaction.details.length > 0) { $scope.refund = $scope.transaction.details[0].transaction }
              $scope.disableRefund = true
            }

            $scope.statusBackup = angular.copy($scope.transaction.status)
            $scope.getServiceStatus($scope.transaction.status)
            $scope.getOutstandingAmount()
          }

          $scope.loading = false
        })
        .catch(function (reason) {
          console.error('reason', reason)
          if (typeof reason === 'object') {
            $scope.alert = {
              type: 'danger',
              msg: $language.translate('UNKOWN_ERROR_OCCURED')
            }
            return
          }
          $scope.alert = {
            type: 'danger',
            msg: $language.translate(reason)
          }

          $scope.loading = false
        })
    }

    $scope.getServiceStatus = function (status) {
      if (status === 'new' || status === 'offer' || status === 'to-repair') {
        $scope.serviceStatus = 0
      }else if (status === 'processing') {
        $scope.serviceStatus = 1
      }
      else if (status === 'inspection') {
        $scope.serviceStatus = 2
      }
      else if (status === 'completed') {
        $scope.serviceStatus = 3
      }
      else if (status === 'delivered') {
        $scope.serviceStatus = 4
      }
      else if (status === 'cancelled') {
        $scope.serviceStatus = 5
      }
    }

    $scope.getOutstandingAmount = function () {
      $scope.outstanding = 0
      $scope.getPrice(false, function (price) {
        if (!$scope.payments || $scope.payments.length === 0) {
          $scope.outstanding = price
          return
        }
        var counter = 0; var totalPaid = 0
        for (var i = 0; i < $scope.payments.length; i++) {
          totalPaid += $scope.payments[i].amount - $scope.payments[i].change
          counter++
          if (counter === $scope.payments.length) {
            $scope.outstanding = price - totalPaid
          }
        }
      })
    }

    $scope.getPrice = function (checkout, callback) {
      if (!$scope.transaction) {
        return
      }
      if (checkout && $scope.outstanding > 0) {
        return callback($scope.outstanding)
      }
      if ($scope.transaction.type === 'repair' || $scope.transaction.type === 'special') {
        if ($scope.transaction[$scope.transaction.type].actualCosts) {
          return callback($scope.transaction[$scope.transaction.type].actualCosts)
        } else if ($scope.transaction[$scope.transaction.type].estimatedPrice) {
          return callback($scope.transaction[$scope.transaction.type].estimatedPrice)
        } else {
          return callback(0)
        }
      } else if ($scope.transaction.type === 'shop-purchase') {
        return callback($scope.getTransactionPrice() * (-1))
      }
    }
  }])
