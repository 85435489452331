prismanoteApp.controller('retailerRegistrationProcessController', ['$scope', '$rootScope', 'Upload', '$shop', '$state', '$language',
  function ($scope, $rootScope, Upload, $shop, $state, $language) {
    console.log('why are you calling?');
    doFocusOnPostalCode();
    $rootScope.isRegistrationProcess = true;
    $rootScope.shouldMenuCssApply = 'menucss50';
    $scope.data = {
      showAddress: true,
      contactForm: false,
      brandFrom: false,
      newLogo: {},
      urlPattern: /^(https?:\/\/)?(www\.)?[A-Za-z0-9-]*\.?[A-Za-z0-9-]+\.[A-Za-z0-9-]+(\/[^<>]*)?$/,
      uploadError: {},
      shopData: {},
      notShowManual: false,
      videos: [
        {
          url: 'https://info.prismanote.com/winkelgegevens/',
          text: $language.translate('IMPORTANT_SETTINGS')
        },
        {
          url: 'https://info.prismanote.com/nieuw-product-toevoegen/',
          text: $language.translate('ADD_PRODUCTS')
        },
        {
          url: 'https://info.prismanote.com/afrekenen/',
          text: $language.translate('USING_THE_CASH_REGISTER')
        },
        {
          url: 'https://info.prismanote.com/website-maken/',
          text: $language.translate('BUILD_WEBSITE')
        }
      ]
    }

    $scope.data.notShowManual = (localStorage.getItem('notShowManual') === true || localStorage.getItem('notShowManual') === 'true') ? true : false;

    $scope.addAddress = function () {
      console.log('currnetShopAddres: ', $rootScope.currentShop.address)
      $scope.data.showAddress = false;
      $scope.data.contactForm = true;
      $rootScope.shouldMenuCssApply = 'menucss70'
    }

    $scope.goToDashborad = function () {
      $rootScope.isRegistrationProcess = false;
      $rootScope.shouldMenuCssApply = undefined;
      $state.reload()
      $state.go('retailer.home');
    }

    $scope.showManual = function () {
      if ($scope.data.notShowManual) {
        $scope.goToDashborad();
      } else {
        $scope.data.brandFrom = false;
        $scope.data.showManual = true;
      }
    }

    $scope.updateAddressAndContact = function () {
      if (!$rootScope.currentShop.address.country) $rootScope.currentShop.address.country = 'NL'
      $rootScope.currentShop.address.attn = {
        firstName: $rootScope.user.firstName,
        lastNamePrefix: $rootScope.user.lastNamePrefix,
        lastName: $rootScope.user.lastName
      }
      $scope.data.shopData = {
        _id: $rootScope.currentShop._id,
        address: $rootScope.currentShop.address,
        email: $rootScope.currentShop.email,
        proShopUrl: $rootScope.currentShop.proShopUrl,
        phone: $rootScope.currentShop.phone
      }
      if ($scope.data.newLogo.name) {
        $scope.logoUpload()
      } else {
        $scope.updateShopDetails()
      }
    }

    $scope.updateShopDetails = function () {
      console.log('updateShopDetails: ', $scope.data.shopData)
      $shop.updateShopDetails($scope.data.shopData).then(function (updated) {
        console.log(' Updated Shop Details ', updated)
        $scope.data.contactForm = false;
        $scope.data.brandFrom = true;
        $rootScope.shouldMenuCssApply = 'menucss80'
      }).catch(function (error) {
        console.error('Error In Update Shop Setting', error)
      })
    }

    $scope.logoUpload = function () {
      $scope.data.uploadError = {}
      Upload.upload({
        url: 'api/shop/uploadlogo',
        data: {
          shopId: $rootScope.currentShop._id,
          file: $scope.data.newLogo
        }
      }).then(function (res) {
        $rootScope.currentShop.logoLight = { src: res.data.file }
        $rootScope.currentShop.logoDark = { src: res.data.file }
        $scope.data.shopData.logoLight = $rootScope.currentShop.logoLight
        $scope.data.shopData.logoDark = $rootScope.currentShop.logoDark
        $scope.data.newLogo = {}
        $scope.updateShopDetails()
      }, function (res) {
        $scope.data.uploadError = {
          type: 'danger',
          msg: res.data || res.data.message
        }
      }, function (evt) {
        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total)
        $scope.uploadProgress = progressPercentage
      })
    }

    function doFocusOnPostalCode() {
      var element = document.getElementById('inv_postal')
      console.log('elemtnt past: ', element)
      if (element) element.focus()
    }

    $scope.getAddressInfo = function () {
      // console.log('getAddressInfo called: ');
      // console.log('address: ', $rootScope.currentShop.address);
      if (!$rootScope.currentShop || !$rootScope.currentShop.address) return;
      var address = $rootScope.currentShop.address;

      if (!address || address.country != 'NL' || !address.postalCode || !address.houseNumber || address.postalCode.length < 6) {
        delete address.zipcode;
        return;
      } else {
        address.zipcode = true;

        var body = {
          zipcode: address.postalCode,
          number: address.houseNumber
        }
        $shop.getAddressInfo(body).then(function (result) {
          if (result && result.data && result.data) {
            // console.log('result: ----------------->', result.data);
            $rootScope.currentShop.address.street = result.data.address.street;
            $rootScope.currentShop.address.city = result.data.address.city;
          }
          // $retailer.setShop($rootScope.selectedShop, $rootScope.currentShop);
        }).catch(function (error) {
          console.log('error: ', error);
          address.zipcode = false;
        });
      }
    }

    $scope.makeWebsiteLowerCase = function () {
      if ($rootScope.currentShop.proShopUrl) $rootScope.currentShop.proShopUrl = $rootScope.currentShop.proShopUrl.toLowerCase();
    }

    $scope.toggleManualShow = function () {
      if ($scope.data.notShowManual) {
        // console.log('Not Show manual');
        localStorage.setItem('notShowManual', true);
      } else {
        // console.log('Show manual');
        localStorage.setItem('notShowManual', false);
      }
    }

  }])
