prismanoteApp.controller('attachDomainToWebsite', ['$scope', 'grapeFactory', '$rootScope', '$uibModalInstance',
  function ($scope, grapeFactory, $rootScope, $uibModalInstance) {
    $scope.customDomainName = ''
    $scope.alert = {
      type: '',
      msg: ''
    }

    $scope.customDomainOk = function (customDomainName) {
      var factoryData = localStorage.getItem('factory') ? JSON.parse(localStorage.getItem('factory')) : {}

      if (!customDomainName || customDomainName.trim() === '') {
        $scope.alert.type = 'danger'
        $scope.alert.msg = 'Please enter valid domain name'
        return
      }

      if (Object.keys(factoryData).length > 0) {
        var data = {
          domainName: customDomainName
        }

        grapeFactory
          .addNewDomain(data, $rootScope.currentShop._id, factoryData.websiteId)
          .then(function (response) {
            $uibModalInstance.close(true)
          })
          .catch(function (error) {
            $scope.alert = {
              type: 'danger',
              msg: error || 'Something went wrong. Please try again'
            }
          })
      } else {
        $scope.alert = {
          type: 'danger',
          msg: 'Something went wrong'
        }
      }
    }

    $scope.closeAlert = function () {
      $scope.alert = {
        type: '',
        msg: ''
      }
    }
  }
])
