prismanoteApp.controller('retailerAutoCreatedPurchaseOrderController', ['$scope', '$rootScope', '$uibModal', '$api', '$stateParams', '$state', '$q', '$translate', '$retailer', '$shop', '$language',
  function ($scope, $rootScope, $uibModal, $api, $stateParams, $state, $q, $translate, $retailer, $shop, $language) {
    $scope.shopProducts = []
    $scope.purchaserOrdersWithNewStatus = []
    $scope.filter = {
      startDate : new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(0, 0, 0),
      endDate : new Date(new Date().setHours(23, 59, 59))
    }
    $scope.year = moment().year()
    $scope.month = moment().month()
    $scope.bestSellArr = [];

    // Getting Shop.products with minstock is higher than the stock
    $scope.getNewOrderAutoMatically = function () {
      if ($rootScope.currentShop) {
        $scope.loading = true
        $api.get('get-purchase-order-automatically/' + $rootScope.currentShop._id)
          .then(function (response) {
            $scope.shopProducts = response.data
            // console.log('getNewOrderAutoMatically: ', $scope.shopProducts.length, $scope.shopProducts)
            // console.log(' $scope.totalProducts ',  $scope.totalProducts);
            for(var key in $scope.shopProducts){ $scope.shopProducts[key].isExpand = false; }
            $scope.loading = false
            $scope.findThePurchaseOrderWithNewStatus()
          })
          .catch(function (reason) {
            console.error('Error while getting the order list', reason)
            $scope.loading = false
          })
      }
    }

    // Finding purchase order which status is 'New' and asking user to he wants to add or not to purchase order
    $scope.findThePurchaseOrderWithNewStatus = function () {
      if ($rootScope.currentShop) {
        $scope.loading = true
        $api.get('find-purchase-order-with-new-status/' + $rootScope.currentShop.nameSlug)
          .then(function (response) {
            if (response.data.length) {
              $scope.purchaserOrdersWithNewStatus = response.data
              AddThePurchaseOrderWithNewStatus($scope.purchaserOrdersWithNewStatus)
            }
            $scope.loading = false
          })
          .catch(function (reason) {
            console.error('Error while getting the order list', reason)
            $scope.loading = false
          })
      }
    }

    $scope.back = function () {
      $state.go('retailer.purchase-orders', { company: '' })
    }

    // When popover on the prouduct image if there otherwise shows category
    $scope.popover = function (item) {
      // console.log('--- popover ', item);
      if(item.productDetails && !item.productDetail){ item.productDetail = item.productDetails; }
      // console.log('item: ', item.productDetail.images);
      if (item.productDetail) {
        if (item.productDetail.images && item.productDetail.images.length && item.productDetail.images[0].src) {
          var image = $rootScope.awsUrl + item.productDetail.images[0].src
          return '<img src=' + image + " alt='No Image found'>"
        } else if (item.productDetail.category) {
          return item.productDetail.category
        }
      }
    }

    function AddThePurchaseOrderWithNewStatus (purchaserOrdersWithNewStatus) {
      console.log('AddThePurchaseOrderWithNewStatus: called: ', purchaserOrdersWithNewStatus)
      var lenghtOfPurchaseOrder = purchaserOrdersWithNewStatus.length
      var lenghtOfShopProducts = $scope.shopProducts.length
      for (var i = 0; i < lenghtOfPurchaseOrder; i++) {
        for (var j = 0; j < lenghtOfShopProducts; j++) {
          // console.log(purchaserOrdersWithNewStatus[i]._id.supplier, $scope.shopProducts[j].Name);
          if (purchaserOrdersWithNewStatus[i]._id.supplier == $scope.shopProducts[j].Name) {
            console.log('Found same supplier')
            $scope.shopProducts[j].purchaseDetail = purchaserOrdersWithNewStatus[i].purchaseDetail
          }
        }
      }

      console.log('Update GET SHOPPRODUCTS: ', $scope.shopProducts)
    }

    // When reloading page, might there is no $rootScope.currentShop so its possible to fetch the shop
    if (!$rootScope.currentShop) {
      // console.log('HELLO SHOP IS NOT THERE');
      $retailer.getShop()
        .then(function (shop) {
          console.log('changelselectedSHop called: ', shop);
          $rootScope.currentShop = shop
          $scope.getNewOrderAutoMatically()
        })
        .catch(function (reason) {
          console.error(reason)
        })
    } else {
      // console.log('HELLO SHOP IS THERE');
      $scope.getNewOrderAutoMatically()
    }

    // When its checked then products of the purchase ordre pushed into the shop.products
    $scope.checkBoxClicked = function (item, indexofShopProudct) {
      // console.log('checkbox not checked: ', indexofShopProudct, item);
      var itemsLength = item.detail.length
      if (item.enabled) {
        // console.log('Checked mark')

        // This array is for the removing purchase order no which existing in newly created purchase order
        if (!$scope.shopProducts[indexofShopProudct].removedPurchaseOrderIdArray) $scope.shopProducts[indexofShopProudct].removedPurchaseOrderIdArray = []
        $scope.shopProducts[indexofShopProudct].removedPurchaseOrderIdArray.push(item._id)

        for (var i = 0; i < itemsLength; i++) {
          var productDetail = item.detail[i].item
          productDetail.prodNumber = item.detail[i].productDetail.variants[0].productNumber
          productDetail._id = productDetail.product_id
          // console.log(productDetail)
          $scope.shopProducts[indexofShopProudct].Detail.push({ productDetail: productDetail })
        }
        // console.log('PUSHED SHOP PRODUCTS: ', $scope.shopProducts[indexofShopProudct]);
      } else {
        var shopProductsLength = $scope.shopProducts[indexofShopProudct].Detail.length
        // console.log('Checked not mark: ', shopProductsLength);
        for (var j = 0; j < itemsLength; j++) {
          for (var i = 0; i < shopProductsLength; i++) {
            // console.log('isFInd: ', item.detail[j].productDetail._id == $scope.shopProducts[indexofShopProudct].Detail[i].productDetail._id);
            // console.log('checkingid: ', item.detail[j].productDetail._id, $scope.shopProducts[indexofShopProudct].Detail[i].productDetail._id);
            if (item.detail[j].productDetail._id == $scope.shopProducts[indexofShopProudct].Detail[i].productDetail._id) {
              $scope.shopProducts[indexofShopProudct].Detail.splice(i, 1)
              --shopProductsLength
            }
          }
        }
      }
    }

    // Creating purchase order using shop.products and purchase order (will create new purchase order with given data and will removed the purchaser orderno with given data)
    $scope.CreatePurchaseOrderFromTheShopProduct = function (indexofShopProudct) {
      console.log('CreatePurchaseOrderFromTheShopProduct: ', indexofShopProudct, $scope.shopProducts)
      var products = $scope.shopProducts[indexofShopProudct]

      console.log(products) // Its company name
      // console.log(products.purchaseDetail[0].company.name)
      var createPurchaseOrder = {
        shop: {
          noShop: false,
          name: $rootScope.currentShop.name,
          nameSlug: $rootScope.currentShop.nameSlug,
          _id: $rootScope.currentShop._id
        },
        company: {
          noCompany: false,
          name: products.Name || (products.purchaseDetail && products.purchaseDetail.length && products.purchaseDetail[0] && products.purchaseDetail[0].company.name),
          nameSlug: (products.purchaseDetail && products.purchaseDetail.length && products.purchaseDetail[0] && products.purchaseDetail[0].company.nameSlug ? products.purchaseDetail[0].company.nameSlug : undefined),
          _id: (products.purchaseDetail && products.purchaseDetail.length && products.purchaseDetail[0] && products.purchaseDetail[0].company._id ? products.purchaseDetail[0].company._id : undefined)
        },
        billingDetails: {
          address: $rootScope.currentShop.address,
          firstName: $rootScope.currentShop.name,
          email: $rootScope.currentShop.email
        },
        status: [
          {
            status: 'New'
          }
        ],
        items: []
      }

      for (var i = 0; i < products.Detail.length; i++) {
        if (products.Detail[i].products) {
          // This product is from the shop.products
          var quantity = 0;
          if(products.Detail[i].products.calculatedStock){
            quantity = products.Detail[i].products.minStock - products.Detail[i].products.calculatedStock;
          }else{
            quantity = products.Detail[i].products.minStock - products.Detail[i].products.stock;
          }
          if(!quantity && (quantity != 0)) { quantity = 1 };
          createPurchaseOrder.items.push({
            product_id: products.Detail[i].products._id,
            number: products.Detail[i].productDetail.productNumber[0],
            name: products.Detail[i].productDetail.name,
            price: 0,
            quantity: quantity,
            receivedQuantity: 0,
            stock: 0
          })
        } else {
          // This product is from the purchase order product
          createPurchaseOrder.items.push(products.Detail[i].productDetail)
        }
      }

      var body = {
        createPurchaseOrder: createPurchaseOrder,
        removedPurchaseOrderIdArray: products.removedPurchaseOrderIdArray,
        shopId: $rootScope.currentShop._id
      }

      // console.log('body: ', body)
      $api.post('create-purchaser-order-automatically/', body)
        .then(function (response) {
          console.log($scope.shopProducts[indexofShopProudct].Name)
          $scope.shopProducts[indexofShopProudct].alreadycreated = true
          $scope.shopProducts[indexofShopProudct].number = response.data.number;
          $scope.shopProducts[indexofShopProudct].alertShow = true;
        }).catch(function (reason) {
          console.error('Error when saving the order', reason)
        })
    }

    $scope.recommendationOrderPage = function (index) {
      $state.go('retailer.recommendation-order', { orderNo: $scope.shopProducts[index].number });
    }  

    $scope.closeQuickButtonAlert = function (index) {
      console.log('-------- closeQuickButtonAlert!!');
      console.log('index ', index)
      $scope.shopProducts[index].alertShow = false;
    }  

    $scope.addToPurchaseOrder = function (product, parentIndex, index, flag) {
      console.log('----------------- addToPurchaseOrder!!');
      console.log('product ', product);
      $scope.shopProducts[parentIndex].bestSellingProducts[index].toPurchaseOrder = !$scope.shopProducts[parentIndex].bestSellingProducts[index].toPurchaseOrder;

      var obj = {
        productDetail: {
          _id: product.productId,
          productNumber: [product.variantNumber],
          name: product.en.name,
          images: product.productDetail.images ? product.productDetail.images : []
        },
        products: {
          _id: product.productId,
          articleNumber: product.articleNumber,
          minStock: product.minStock,
          labelDescription: product.labelDescription
        }
      };
      var alreadyExists = false;
      if(flag){
        for(var i = 0; i < $scope.shopProducts[parentIndex].Detail.length; i++){
          if(($scope.shopProducts[parentIndex].Detail[i].productDetail._id.toString() === product.productId.toString()) ||
            ($scope.shopProducts[parentIndex].Detail[i].products._id.toString() === product.productId.toString())) {
              $scope.RemoveProduct(i, parentIndex);
              alreadyExists = true;
          }
        }
      }else{
        // if(!alreadyExists){ 
          $scope.shopProducts[parentIndex].Detail.push(obj); 
        // }
      }
    }

    $scope.changeMinStock = function(minStock, flag, index, parentIndex){
      console.log('------- changeMinStock!!');
      if(flag == 'Add'){
        $scope.shopProducts[parentIndex].Detail[index].products.minStock += 1;
      }else{
        $scope.shopProducts[parentIndex].Detail[index].products.minStock -= 1;
      }
      minStock = $scope.shopProducts[parentIndex].Detail[index].products.minStock;
      var productId = $scope.shopProducts[parentIndex].Detail[index].productDetail._id;
      var shopId = $rootScope.currentShop._id;

      $shop.updateShopProductMinStock(productId, shopId, null, minStock) 
        .then(function (success) {
          console.debug('--------- !!');
          console.debug(success)
        })
        .catch(function (error) {
            console.error('Error in', error)
        })
    }

    $scope.getYears = function () {
      var cYear = moment().year()
      //Define in which year we should start showing years
      var startYear = 2018
      $scope.years = []

      for (var i = startYear; i < cYear + 1; i++) {
        $scope.years.push(i)
      }
    }
    $scope.getMonths = function () {
      $scope.months = [
        {
          id: 0,
          name: $language.translate('JANUARY')
        },
        {
          id: 1,
          name: $language.translate('FEBRUARY'),
        },
        {
          id: 2,
          name: $language.translate('MARCH')
        },
        {
          id: 3,
          name: $language.translate('APRIL')
        },
        {
          id: 4,
          name: $language.translate('MAY')
        },
        {
          id: 5,
          name: $language.translate('JUNE')
        },
        {
          id: 6,
          name: $language.translate('JULY')
        },
        {
          id: 7,
          name: $language.translate('AUGUST')
        },
        {
          id: 8,
          name: $language.translate('SEPTEMBER')
        },
        {
          id: 9,
          name: $language.translate('OCTOBER')
        },
        {
          id: 10,
          name: $language.translate('NOVEMBER')
        },
        {
          id: 11,
          name: $language.translate('DECEMBER')
        }
      ]
    }
    $scope.getWeeks = function () {
      console.log('$scope.year::',$scope.year);
      
    var tWeek = $language.translate('WEEK')
    var weeks = moment().year($scope.year).isoWeeksInYear()
  
    $scope.weeks = []
  
    for (var i = 1; i < (weeks +1); i++) {
      $scope.weeks.push(
      {
        id: i,
        name: tWeek + ' ' + i
      }
      )
    }
    }
    $scope.getYears()
    $scope.getMonths()
    $scope.getWeeks()

    function removeDuplicatesProductsFromBestSellingProducts(index) {
      console.log('-------- removeDuplicatesProductsFromBestSellingProducts!!');
      // console.log('bestSellingProducts ', $scope.shopProducts[index].bestSellingProducts.length)
      // console.log('Detail ', $scope.shopProducts[index].Detail.length)

      for(var i = 0; i < $scope.shopProducts[index].Detail.length; i++){
        for(var j = 0; j < $scope.shopProducts[index].bestSellingProducts.length; j++){
          // console.log('------- !!', 'i ', + i, 'j ', + j);
          // console.log($scope.shopProducts[index].Detail[i]);
          // console.log($scope.shopProducts[index].Detail[j].productDetail._id);

          // console.log($scope.shopProducts[index].bestSellingProducts[j]);
          // console.log('');
          // console.log($scope.shopProducts[index].bestSellingProducts[i].productDetails._id);
          if($scope.shopProducts[index].bestSellingProducts[j] && $scope.shopProducts[index].bestSellingProducts[j].productDetails && $scope.shopProducts[index].bestSellingProducts[j].productDetails._id &&
            $scope.shopProducts[index].Detail[i] && $scope.shopProducts[index].Detail[i].productDetail && $scope.shopProducts[index].Detail[i].productDetail._id){
              if($scope.shopProducts[index].Detail[i].productDetail._id.toString() == $scope.shopProducts[index].bestSellingProducts[j].productDetails._id.toString()){
                // console.log('--------- iff!!');
                // console.log($scope.shopProducts[index].Detail[i].productDetail._id);
                // console.log($scope.shopProducts[index].bestSellingProducts[j].productDetails._id);
                // console.log('');
                $scope.shopProducts[index].bestSellingProducts.splice(j, 1)
              }
          }
        }
      }
    }

    $scope.getBestSoldItems = function (shopId ,index, index2) {
      console.log('------------- getBestSoldItems 1!!');
      console.log('index ', index);
      console.log('index2 ', index2);
      console.log('shopId ', shopId);
      var endDate = $scope.filter.endDate; 
      var startDate = $scope.filter.startDate;
      // console.log($scope.shopProducts[index]);
      // console.log($scope.shopProducts[index].Detail);
      // console.log($scope.shopProducts[index].Detail[0].products);
      // console.log($scope.shopProducts[index].Detail[0].products.supplier);
      // console.log($scope.shopProducts[index].Detail[0].products.supplier.nameSlug);
      if($scope.shopProducts && $scope.shopProducts[index] && $scope.shopProducts[index].bestSellingProducts){
        $scope.shopProducts[index].bestSellingProducts = [];
      }
      if($scope.bestSellArr && $scope.bestSellArr[index] && $scope.bestSellArr[index].bestSellingProducts){
        $scope.bestSellArr[index].bestSellingProducts;
      }

      if(!endDate) { endDate = new Date(new Date().setHours(23, 59, 59)); } 
      if(!startDate) { 
        var date = new Date();
        // var value = date.getFullYear();
        var startDate = moment().year(2010).startOf('year').toDate()
      }
      console.log($scope.filter);
      var filters = {
        suppliers: [],
        // language: $rootScope.language,
        // collectionWiseSwitch: $scope.collectionWiseSwitch,
        endDate: endDate ? endDate : '',
        startDate: startDate ? startDate : '',
      }
      console.log(filters);
      // filters.suppliers.push($scope.company._id)
      // console.log('$scope.company._id ', $scope.company._id);

      $scope.loadingBestSoldItems = true;
      return $api.get('v2/shop/sold-productsnew/' + $rootScope.currentShop._id,
        {
          filters: filters,
          companyId: $scope.shopProducts[index].supplier._id,
          nameSlug: $scope.shopProducts[index].supplier.nameSlug
        }
      )
        .then(function (response) {
          $scope.loadingBestSoldItems = false;
          if (response && response.data && response.data.result && response.data.result.soldProductsBasedOnVariantNumber) {
            $scope.bestSellArr[index] = { };
            $scope.shopProducts[index].bestSellingProducts = response.data.result.soldProductsBasedOnVariantNumber;
            $scope.bestSellArr[index].bestSellingProducts = response.data.result.soldProductsBasedOnVariantNumber;
            $scope.shopProducts[index].hide = false;
            for(var i = 0; i < $scope.shopProducts[index].bestSellingProducts.length; i++){
              $scope.shopProducts[index].bestSellingProducts[i].toPurchaseOrder = false;
            }
            // $scope.bestSellingProducts = response.data.result.soldProductsBasedOnVariantNumber
            // console.log('$scope.bestSellingProducts count', $scope.bestSellingProducts.length);
            // console.log('$scope.bestSellingProducts ', $scope.bestSellingProducts);
            // console.log('$scope.bestSellArr ', $scope.bestSellArr[index]);
            // console.log('$scope.shopProducts ', $scope.shopProducts[index]);
            removeDuplicatesProductsFromBestSellingProducts(index);
          }
        })
        .catch(function (error) {
          $scope.loadingBestSoldItems = false;
          console.log('error: ', error)
        })
    } 

    // $scope.showProductPopup = function (item, index, parentIndex) {
    //   $scope.shopProducts[parentIndex].bestSellingProducts[index].popoverIsVisible = true;
    // }

    // $scope.hideProductPopup = function (item, index, parentIndex) {
    //   $scope.shopProducts[parentIndex].bestSellingProducts[index].popoverIsVisible = false;
    // }

    $scope.hideBestSellingProducts = function (index) {
      $scope.shopProducts[index].hide = !$scope.shopProducts[index].hide;
    }

    $scope.setFilter = function(period){
      console.debug('------------ setFilter!');
      console.log('period ', period)
      $scope.filter.period = period;
      $scope.filter.endDate = new Date(new Date().setHours(23, 59, 59));

      if(period == 'day'){
        $scope.period = "day";
        $scope.filter.startDate = new Date(new Date().setHours(0, 0, 0));
      }
      if(period == 'week'){
        $scope.period = "week";
        $scope.filter.startDate = new Date(new Date(moment().startOf('week')).setHours(0, 0, 0)); 
      }
      if(period == 'month'){
        $scope.period = "month";
        $scope.filter.startDate = new Date(new Date(moment().startOf('month')).setHours(0, 0, 0));
      }

      if(period == 'year'){
        $scope.period = "year";
        $scope.filter.startDate = new Date(new Date(moment().startOf('year')).setHours(0, 0, 0));
      }
      // $scope.tabSelected('PRODUCTS_SOLD');
    }

    $scope.showDate = function(data) {
      $scope.showDate = function(data) {
        console.log('data::::',data);
        if (data) {
          $scope.month = undefined
          $scope.disableMonthSelection = true
        } else {
          $scope.disableMonthSelection = false
        }
        
        $scope.showDates = data
        }
    }

    $scope.filterChanged = function(value, time){
      $scope.timeSelected = time
      $scope.valueReceived = value
      var currentDate = new Date(new Date().setHours(23, 59, 59));

      if (time === 'year') {
        console.log('year: ', $scope.year, value);
        $scope.filter.startDate = moment().year(value).startOf('year').toDate()
        $scope.filter.endDate = moment().year(value).endOf('year').toDate();
        $scope.year = value
      }
      if (time === 'month') {
        console.log('startDate: ', $scope.year, value, moment().year($scope.year).month(value).startOf('month').toDate());
        console.log('endDate 1: ', $scope.year, value, moment().year($scope.year).month(value).endOf('month').toDate());
        $scope.filter.startDate = moment().year($scope.year).month(value).startOf('month').toDate()
        $scope.filter.endDate = moment().year($scope.year).month(value).endOf('month').toDate()
        if ($scope.filter.startDate < currentDate && $scope.filter.endDate > currentDate && $scope.filter.startDate < $scope.filter.endDate) {
          $scope.filter.endDate = new Date(new Date().setHours(23, 59, 59));
        }
      }
      if (time === 'week') {
        $scope.filter.startDate = moment().year($scope.year).week(value).startOf('week').toDate()
        $scope.filter.endDate = moment().year($scope.year).week(value).endOf('week').toDate()
      }

      if(time === 'date') {
        $scope.filter.period = 'custom'
      }
    }

    $scope.RemoveProduct = function (productIndex, supplierIndex, item) {
      // console.log('shopproducts: ', $scope.shopProducts[supplierIndex], $scope.shopProducts[supplierIndex].Detail[productIndex]);
      if(item && $scope.shopProducts[supplierIndex].bestSellingProducts){
        var productId = item.productDetail._id;
        for(var i = 0; i < $scope.shopProducts[supplierIndex].bestSellingProducts.length; i++){
          if($scope.shopProducts[supplierIndex].bestSellingProducts[i].productId.toString() === productId.toString()){
            $scope.shopProducts[supplierIndex].bestSellingProducts[i].toPurchaseOrder = !$scope.shopProducts[supplierIndex].bestSellingProducts[i].toPurchaseOrder;
          }
        }
      }
      $scope.shopProducts[supplierIndex].Detail.splice(productIndex, 1)
    }
  }])
