prismanoteApp.controller('brandsController', ['$scope', '$rootScope', '$location', '$api', function ($scope, $rootScope, $location, $api) {
  $rootScope.breadcrumb = ['Merken']
  $scope.totalDisplayed = 24
  $scope.offset = 0
  $scope.searchString = ''
  $scope.moreRecords = true

  $scope.brands = []
  $scope.enabled = true
  $scope.language = $rootScope.language

  $scope.isVerified = true ;
  $scope.isNotVerified = true;
  // $api.get('brands', {'filter': {'isFeatured': true}, 'sort': {'name': 'asc'}, 'limit': 24})

  // 	.then(function(response) {
  // 		$scope.brands = response.data.brands;
  // 	})

  // 	.catch(function(reason) {
  // 		console.log(reason);
  // 	});

  $scope.verificationsChanged = function () {
    console.log('---------- verificationsChanged!');
   
    var status = 'showAll';
    if ($scope.isNotVerified === $scope.isVerified ) {
      status = 'showAll'
    } else {
      if($scope.isNotVerified){ status = false; }
      if($scope.isVerified){ status = true; }
    }

    $scope.getBrandList(status, '')
  }

  $scope.getBrandList = function (verified, search) {
    var filter = {
      offset: $scope.offset,
      limit: $scope.totalDisplayed
    }

    if ((!verified || verified) && verified !== undefined) {
      if($scope.isNotVerified !== $scope.isVerified) { filter.filter = { isVerified: verified } } else { 
        filter.filter = { };
      }
    }
    filter.search = search || ''
    filter.language = $scope.language && $scope.language !== '' && $scope.language !== undefined ? $scope.language : 'en'
    getbrands(angular.copy(filter));
  }

  function getbrands(data) {
    console.log('----------------- getbrands!!');
    console.log(data);
    var tempData = data;
    if($scope.isNotVerified === $scope.isVerified) { tempData.filter = { } }
    console.log(angular.copy(tempData));
    $api.get('admin/brands', angular.copy(tempData))
      .then(function (response) {
        $scope.brands = []
        if (response.data.totalCount && response.data.totalCount > 0) {
          if (($scope.totalDisplayed * ($scope.offset === 0 ? 1 : $scope.offset)) >= response.data.totalCount) {
            $scope.moreRecords = false
          } else {
            $scope.moreRecords = true
          }
        } else {
          $scope.moreRecords = false
        }
        // $scope.loading = false;
        $scope.brands = response.data.data
      })
      .catch(function (reason) {
        console.log(reason)
      })
  }

  console.log('inside Brands length', $scope.brands.length)

  $scope.resetSelected = function () {
    $scope.currentSocialMedia = null
    $scope.currentHome = null
    $scope.currentSlide = null
  }

  $scope.loadMore = function () {
    $scope.offset += 1
    $scope.getBrandList($scope.enabled, $scope.searchString)
  }

  $scope.changeCallback = function () {
    $scope.totalDisplayed = 20
    $scope.offset = 0
    $scope.getBrandList($scope.enabled, $scope.searchString)
  }

  $scope.search = function (searchString) {
    $scope.totalDisplayed = 20
    $scope.offset = 0
    $scope.getBrandList($scope.enabled, searchString)
  }

  $scope.loadBrand = function () {
    $api.get('brands/' + $stateParams.nameSlug + '?shopId=' + $rootScope.currentShop._id)
      .then(function (response) {
        $scope.brand = response.data.brand
        // - set hidden search input filter to name of the brand
        $scope.search.brand.name = $scope.brand.name
      })

      .catch(function (reason) {
        console.log(reason)
      })
  }

  $scope.updateBrand = function (redirect) {
    console.log('[updateBrand controller]')
    console.log('$stateParams.nameSlug', $stateParams.nameSlug)
    console.log('$scope.brand', $scope.brand)
    $api.put('brands/' + $stateParams.nameSlug, { brand: $scope.brand, shopId: $rootScope.currentShop._id, companyId: $rootScope.currentCompany._id })

      .then(function () {
        if (redirect) {
          $state.go('admin.users')
        }
      })

      .catch(function (reason) {
        console.log(reason)
      })
  }
}])
