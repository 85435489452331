prismanoteApp.controller('grapeTemplateController', ['$scope', '$rootScope', 'factory', '$state', 'grapeFactory', '$stateParams', '$retailer', function ($scope, $rootScope, factory, $state, grapeFactory, $stateParams, $retailer) {
  $scope.editorHide = false
  $scope.loading = false
  $scope.isValidWebsite = true
  $scope.alert = {
    type: '',
    msg: ''
  }

  $scope.checkWebsite = function () {
    $scope.loading = true
    if ($rootScope.currentShop && $rootScope.currentShop._id) {
      return grapeFactory.getWebsiteDetail($rootScope.currentShop._id, $stateParams.websiteId)
        .then(function (websiteData) {
          // Here we need to check for lock editing, already template selected.
          var websiteDetail = websiteData.data.websiteDetail

          // Checking for lock for edit or not
          if (websiteDetail && websiteDetail.isLocked) {
            return $state.go('retailer.web-builder-home')
          }

          // Check this website have already template selected
          if (websiteDetail && websiteDetail.activeTemplate !== '') {
            factory.templateName = websiteDetail.activeTemplate
            factory.pageName = 'index'
            factory.websiteName = websiteDetail.websiteNameSlug
            factory.retailer = websiteDetail.shopId
            factory.websiteOriginalName = websiteDetail.websiteName
            factory.websiteId = websiteDetail._id

            var obj = {}
            obj.websiteName = websiteDetail.websiteName
            obj.pageName = factory.pageName
            obj.templateName = factory.templateName
            obj.websiteOriginalName = factory.websiteOriginalName

            localStorage.setItem('websiteInfo', JSON.stringify(obj))
            localStorage.setItem('factory', JSON.stringify(factory))

            $state.go('buildereditor', { websiteId: $stateParams.websiteId, shopId: websiteDetail.shopId })
            return
          }

          $scope.loading = false
          $scope.isValidWebsite = true
        })
        .catch(function (error) {
          $scope.loading = false
          $scope.isValidWebsite = false

          $scope.alert = {
            type: 'danger',
            message: error.data.message
          }
        })
    }
  }

  $scope.closeAlert = function () {
    $scope.alert = {
      type: '',
      msg: ''
    }
  }

  $scope.templateLoader = function (templateName, pageName) {
    if ($scope.isValidWebsite) {
      return grapeFactory.templateSelection($rootScope.currentShop._id, $stateParams.websiteId, { templateName: templateName, language: $rootScope.language ? $rootScope.language : 'en' })
        .then(function (response) {
          var websiteDetail = response.data.result.websiteDetail

          factory.templateName = templateName
          factory.pageName = pageName
          factory.websiteName = websiteDetail.websiteNameSlug
          factory.retailer = websiteDetail.shopId
          factory.websiteOriginalName = websiteDetail.websiteName
          factory.websiteId = websiteDetail._id

          var obj = {}
          obj.websiteName = websiteDetail.websiteName
          obj.pageName = factory.pageName
          obj.templateName = factory.templateName
          obj.websiteOriginalName = factory.websiteOriginalName

          localStorage.setItem('websiteInfo', JSON.stringify(obj))
          localStorage.setItem('factory', JSON.stringify(factory))

          $state.go('buildereditor', { websiteId: websiteDetail._id, shopId: websiteDetail.shopId })
        })
        .catch(function (error) {
          $scope.alert = {
            type: 'danger',
            message: error.data.message
          }
        })
    } else {
      $scope.alert = {
        type: 'danger',
        message: 'Something went wrong'
      }
    }
  }

  $scope.$on('changeSelectedShop', function () {
    $retailer.getShop()
      .then(function (shop) {
        console.log('template controller 108: ', shop);
        $rootScope.currentShop = shop
        $scope.checkWebsite()
      })
  })

  $scope.goBack = function () {
    return $state.go('retailer.web-builder-home')
  }
}]).config(['$qProvider', function ($qProvider) {
  $qProvider.errorOnUnhandledRejections(false)
}])
