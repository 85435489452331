prismanoteApp.controller('adminCompaniesToMergeController', ['$scope', '$rootScope', '$stateParams', '$api', '$q', '$translate', '$http',
  function ($scope, $rootScope, $stateParams, $api, $q, $translate, $http) {
    $scope.showToast = function (text) {
      $(document).ready(function () {
        console.log('DOING TOAST')
        $.toast({
          text: text,
          hideAfter: 2000,
          showHideTransition: 'slide',
          hideAfter: true,
          position: 'top-right'
        })
      })
    }
    // Array with all currently displayed items in /products
    $scope.companies = []

    $scope.selection = []

    $scope.isVerified = true

    $scope.isNotVerified = true

    $scope.clickToCopy = function (id) {
      var element = '#id-' + id
      var $temp = $('<input>')
      $('body').append($temp)
      $temp.val($(element).text()).select()
      document.execCommand('copy')
      $temp.remove()
      $scope.showToast($(element).text() + '<b> copied !</b>')
      setInterval(function () {
        $.toast().reset('all')
      }, 3000)
    }

    $scope.doMergeCompanies = function (obj) {
      console.log('Companies To merge = ', obj)
      $api.get('companies/merge-compaines/' + obj.primary + '/' + obj.duplicate + '/')
        .then(function (success) {
          $.toast({
            heading: 'Success',
            icon: 'success',
            text: 'Companies Merged ! Deletion of duplicate Company Pending',
            position: 'top-right',
            hideAfter: 5000,
            showHideTransition: 'fade'
          })
          console.log(success)
        }, function (err) {
          $.toast({
            heading: 'Error',
            text: 'There seems to be some error. Please try again later',
            position: 'top-right',
            showHideTransition: 'fade',
            icon: 'error'
          })
          console.error(err)
        })
      console.log('Exiting doMerge')
    }
    $scope.searchChanged = function (searchParam) {
      if (searchParam !== '') {
        console.log('Making Request for Search Param = ', searchParam)
        // $api.get("products/search/"+searchParam)
        // .then(function(success){
        // 	var prods = success.data;
        // 	console.log("Response Received ",prods);
        // 	$scope.products = [];
        // 	for(var i = 0; i < prods.length; i++){
        // 		$scope.products.push(prods[i]);
        // 		console.log(prods[i]);
        // 	}
        // 	console.log($scope.products);
        // },function(message,err){
        // 		$scope.showToast("There was some error searching the products. Please contact the Administrator.");

        // });
      } else {
        $scope.getProducts()
        console.log('Search Params Empty')
      }
    }

    $scope.verificationsChanged = function () {
      var params = {
        filter: {
          isVerified: false
        }
      }

      $scope.getProducts(params)
    }

    $scope.selectItem = function (nameSlug, index) {
      var indexOf = $scope.selection.indexOf(nameSlug)
      if (indexOf != -1) {
        $scope.selection.splice(indexOf, 1)
      } else {
        $scope.selection.push(nameSlug)
      }
      $scope.products[index].selected = !$scope.products[index].selected
    }

    $scope.limit = 24

    $scope.setLimit = function (limit) {
      if (limit != null) {
        $scope.limit = limit
      } else {
        $scope.limit = 24
      }
    }

    var getCompanyCount = function () {
      return $q(function (resolve, reject) {

        // $api.getCount('products')

        // .then(function(productCount) {
        // 	$scope.productCount = productCount;
        // 	resolve(productCount);
        // })

        // .catch(function(reason) {
        // 	reject(reason);
        // });
      })
    }

    // Get new products from the database and add them to the $scope
    $scope.getCompanies = function (params, options) {
      return $q(function (resolve, reject) {
        $scope.loading = true

        if (!params) {
          params = {}
        }

        if (!params.filter) {
          params.filter = {}
        }

        var apiRequest = function () {
          params.sort = { dateLastModified: 'desc' }
          params.offset = $scope.products.length
          params.limit = $scope.limit

          if ($scope.isVerified) {
            params.filter.isVerified = true
          }
          if ($scope.isNotVerified) {
            params.filter.isVerified = false
          }

          if ($scope.isVerified && $scope.isNotVerified) {
            console.log('Is Verified And Not Verified.')
            params.filter.isVerified = 'showAll'
          }
          if ($scope.isVerified == false && $scope.isNotVerified == false) {
            console.log('Is Verified And Not Verified.')
            params.filter.isVerified = 'showAll'
          }

          // if(typeof params.filter.isVerified !== 'boolean') {
          // 	params.filter.isVerified = false;
          // }
          // params.filter.isVerified = $scope.isVerified;

          if (params.filter.category && params.filter.category.$in) {
            $scope.productCategory = params.filter.category.$in
          } else {
            $scope.productCategory = []
          }

          getCompanyCount()

            .then(function () {
              console.log(params)

              // $api.get('products', params)

              // .then(function(response) {
              // 	$scope.products = [];
              // 			// Loop through newly fetched products and add them to the $scope.
              // 			for(var i = 0; i < response.data.products.length; i++) {

              // 				formatProduct(response.data.products[i])

              // 				.then(function(formattedProduct) {
              // 					console.log("Products = ",formattedProduct);
              // 					$scope.products.push(formattedProduct);

              // 					if($scope.products.length == params.offset + response.data.products.length) {
              // 						$scope.loading = false;
              // 					}
              // 				})

              // 				.catch(function(reason) {
              // 					reject(reason);
              // 				});
              // 			}
              // 			resolve();

              // 		})

              // .catch(function(reason) {
              // 	$scope.noProductsFound = true;

              // 	reject(reason);
              // });
            })
        }

        // Delete the loaded products and load them again with the new apiParams
        if (options != null && typeof options === 'object') {
          if (options.reset === true) {
            $scope.products = []
          }
        }

        apiRequest()
      })
    }

    $scope.getProducts()
  }])
