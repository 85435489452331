prismanoteApp.controller('retailerTillController', ['$scope', '$uibModal', '$rootScope', '$stateParams', '$api', '$language', '$customer', '$transaction', 'dialogs', '$state', '$filter', '$retailer', '$q', '$shop', '$CashRegistry', '$employee', '$log', '$giftcard', 'scanner', '$card', '$translate', '$clientGroup', '$prefillCalculationForPurchasOrderAndCashRegister', '$printer', '$device', '$productConfig', '$thermalPrint', '$http', '$q',
  function ($scope, $uibModal, $rootScope, $stateParams, $api, $language, $customer, $transaction, dialogs, $state, $filter, $retailer, $q, $shop, $CashRegistry, $employee, $log, $giftcard, scanner, $card, $translate, $clientGroup, $prefillCalculationForPurchasOrderAndCashRegister, $printer, $device, $productConfig, $thermalPrint, $http, $q) {
    // #region Set variables and global functions

    if ($stateParams.token) localStorage.setItem('authorization_new', $stateParams.token);

    $scope.localdata = {
      productSearch: '',
      productSearch2: '',
      currentPage: 1,
      pageSize: 5,
      searchPageSize: 30,
      searchCurrentPage: 1,
      searchCurrentShopProductPage: 1,
    }
    $scope.hideDiscount = false
    $scope.transaction = {
      details: []
    }
    $scope.payments = {}
    $scope.currentIdx = null
    $scope.newProduct = {}
    $scope.searchMode = 'stock'

    $scope.offerMode = false
    $scope.externalGiftcards = []
    $scope.partialPayments = []
    $scope.pointsAdded = false;
    $scope.pointsEnabled = true;
    $scope.popupflag = false;
    $scope.loading = {
      pool: false,
      collection: false,
      products: false
    }
    $scope.paymentMode = false;
    $scope.barcode = false;
    $scope.showContactWarning = true;
    $scope.isNewPlatform = false;
    $scope.dayState = {}
    $scope.giftcardModalOpened = false;

    angular.element(document).ready(function () {
      setFocus('searchField')
    })

    $scope.$watch('currentIdx', function () {
      if ($scope.transaction && $scope.transaction.details && $scope.transaction.details.length > 1 && $scope.currentIdx === null) {
        $scope.toggleItem($scope.transaction.details.length - 1)
      }
    })

    // No magic numbers; default tax defined as a constant.
    var DEFAULT_TAX = 21

    function getShop(callback) {
      if (!$rootScope.currentShop) {
        $retailer.getShop()
          .then(function (shop) {
            $rootScope.currentShop = shop
            $scope.hideDiscount = shop.cashRegister.hideDiscount
            return callback(shop)
          })
      } else {
        $scope.hideDiscount = $rootScope.currentShop.cashRegister.hideDiscount
        return callback($rootScope.currentShop)
      }
    }

    function selectInput(elem) {
      elem.focus()
      elem.select()
    }

    $scope.switchDiscount = function () {
      $scope.hideDiscount = !$scope.hideDiscount
    }

    $scope.getTaxes = function () {
      if ($scope.taxes) {
        // No need to call the api again
        $scope.defaultTax = $scope.taxes && $scope.taxes.length > 0 ? $scope.taxes[0].rate : DEFAULT_TAX
        return
      }

      getShop(function (shop) {
        $api.get('tax/shop', { shopId: shop._id })
          .then(function (res) {
            $scope.taxes = res.data.taxes.rates
            $scope.defaultTax = $scope.taxes && $scope.taxes.length > 0 ? $scope.taxes[0].rate : DEFAULT_TAX
          })
          .catch(function (reason) {
            $log.error('Error while getting taxes for this shop', reason)
          })
      })
    }

    $scope.getConceptTransactions = function () {
      $scope.openTransactions = [];
      $scope.openTransactionsInDangerZone = false
      getShop(function (shop) {
        $api.post('transactions/concept', {
          shopId: shop._id,
          user: $scope.getCurrentUser()
        })
          .then(function (response) {
            if (response.data && response.data.transactions && response.data.transactions.length > 0) {
              $scope.openTransactions = angular.copy(response.data.transactions)
              var rightNow = moment()
              $scope.openTransactions.map(function(t) {
                var created = moment(t.dateCreated)

                t.daysParked = rightNow.diff(created, 'days')
                t.daysParkedLabel = created.fromNow();

                if(t.daysParked > 5) {
                  $scope.openTransactionsInDangerZone = true
                }
                return t
              });
            }
          })
          .catch(function (reason) {
            $log.error('Error while getting concept transactions', reason)
          })
      })
    }

    $scope.checkPointsEndDate = function () {
      getShop(function (shop) {
        if (shop.points && shop.points.endDate) {
          if (moment($rootScope.currentShop.points.endDate).isBefore(moment().startOf('day'))) {
            $scope.pointsEnabled = false
          }
        }
      })
    }

    $scope.parseCustomer = function () {
      $scope.shippingAddress = null
      $scope.invoiceAddress = null

      if ($scope.customer.shippingAddress && $scope.customer.shippingAddress.street && $scope.customer.shippingAddress.houseNumber && $scope.customer.shippingAddress.postalCode && $scope.customer.shippingAddress.city) {
        $scope.shippingAddress = $scope.customer.shippingAddress.street + ' ' + ($scope.customer.shippingAddress.houseNumber ? $scope.customer.shippingAddress.houseNumber : '') + ($scope.customer.shippingAddress.houseNumberSuffix ? $scope.customer.shippingAddress.houseNumberSuffix + ' ' : ' ') + $scope.customer.shippingAddress.postalCode + ' ' + $scope.customer.shippingAddress.city
      }
      if ($scope.customer.invoiceAddress && $scope.customer.invoiceAddress.street && $scope.customer.invoiceAddress.houseNumber && $scope.customer.invoiceAddress.postalCode && $scope.customer.invoiceAddress.city) {
        var invoiceAddress = $scope.customer.invoiceAddress.street + ' ' + ($scope.customer.invoiceAddress.houseNumber ? $scope.customer.invoiceAddress.houseNumber : '') + ($scope.customer.invoiceAddress.houseNumberSuffix ? $scope.customer.invoiceAddress.houseNumberSuffix + ' ' : ' ') + $scope.customer.invoiceAddress.postalCode + ' ' + $scope.customer.invoiceAddress.city
        if (!$scope.shippingAddress || $scope.shippingAddress.toLowerCase() !== invoiceAddress.toLowerCase()) {
          $scope.invoiceAddress = invoiceAddress
        }
      }
    }

    $scope.getTransaction = function (number, giftcardOnly, barcode) {
      return $q(function (resolve, reject) {
        if($scope.barcode === true && barcode !== true) {
          return
        }
        $api.get('transaction/find-for-barcode', { transactionNumber: number, shopId: $rootScope.currentShop._id, giftcard: giftcardOnly })
          .then(function (result) {
            if (giftcardOnly && result.status === 204) {
              toastr.error($language.translate('NO_GIFTCARD_FOUND'))
              return
            }
            if (result.data.transaction) {
              var transaction = result.data.transactionDetails

              if(!$scope.customer) {
                $scope.customer = transaction.customer
                $scope.parseCustomer()
              }

              if (giftcardOnly && transaction.type !== 'giftcard') {
                toastr.error($language.translate('THIS_IS_NOT_A_VALID_GIFTCARD'))
                return
              }

              if (transaction.type === 'shop-purchase' || transaction.type === 'webshop') {
                //----------------- REMOVE THIS CODE AFTER PRICES ARE CONVERTED BY THE JOLMER SCRIPT -----START////
                var endDate = new Date('2020-05-18T19:00:00Z');
                var creationDate = new Date(transaction.dateCreated);
                if (endDate < creationDate) {
                  var convertTempPrice = false
                } else {
                  var convertTempPrice = true
                }
                if (transaction.details && convertTempPrice) {
                  for (i = 0; i < transaction.details.length; i++) {
                    transaction.details[i].price = transaction.details[i].price / (1 + (transaction.details[i].priceVat / 100))
                  }
                }

                //----------------- REMOVE THIS CODE AFTER PRICES ARE CONVERTED BY THE JOLMER SCRIPT -----END////

                if (transaction.details) {
                  for (i = 0; i < transaction.details.length; i++) {
                    if (transaction.details[i].discount == true) {
                      transaction.details[i].discountValue = 0,
                        transaction.details[i].discount = false,
                        transaction.details[i].discountPercent = false
                    }
                    if (transaction.details[i].quantity < 0) {
                      transaction.details[i].quantity = (transaction.details[i].quantity * -1)
                    }
                  }
                }

                var refundItemsModal = $uibModal.open({
                  templateUrl: '../views/modal/retailer-select-transaction-items-refund-modal.html',
                  controller: 'retailerSelectTransactionItemsModalController',
                  size: 'lg',
                  resolve: {
                    transaction: function () {
                      return transaction
                    }
                  }
                })

                refundItemsModal.result.then(function (result) {
                  if (result.details && result.details.length > 0) {
                    var restPaymentCount = result.details.filter( function(d) { return d.typeDetail === 'rest_payment'}).length
                    if(restPaymentCount > 0) {
                      $scope.transaction.parentTransactionId = result.transactionId
                    }
                    var originalTransaction = result.originalTransaction

                    for (var i = 0; i < result.details.length; i++) {
                      var points = null
                      if(result.details[i].type === 'points') {
                        var splitted = result.details[i].comment.split(' ')
                        points = parseFloat(splitted[0])
                      }
                      if(result.details[i].typeDetail === 'rest_payment') {
                        var d = result.details[i]

                        var extraTransaction = null
                        var transactionId = originalTransaction.parentTransactionId && originalTransaction.parentTransactionId._id ? originalTransaction.parentTransactionId._id : originalTransaction._id
                        if(d.type === 'repair' || d.type === 'special' || d.type === 'order') {
                          transactionId = d.transaction && d.transaction._id ? d.transaction._id : d.transaction
                          if(d.extraTransaction) {
                            extraTransaction = d.extraTransaction
                          } else {
                            extraTransaction = $scope.transaction._id
                          }
                        }
                        var parentTransactionDetailId = d.parentTransactionDetailId ? d.parentTransactionDetailId : d._id ? d._id : null

                        var total = d.originalTotal - (d.total + (d.paidLaterAmount ? d.paidLaterAmount : 0))
                        total = (Math.round(total * 100) / 100).toFixed(2)
                        if(d.total < 0 && total > 0) {
                          // The total of the detail is negative but the calculated total is positive, so convert that to negative again
                          total = total * -1
                        }

                        $scope.addItemToTransaction({
                          repairPayment: d.type === 'repair',
                          specialPayment: d.type === 'special' || d.type === 'order',
                          restPayment: true,
                          transaction: transactionId,
                          extraTransaction: extraTransaction,
                          name: $language.translate('REST_PAYMENT') + ' ' + result.details[i].name,
                          price: parseFloat(total),
                          total: parseFloat(total),
                          parentTransactionDetailId: parentTransactionDetailId,
                          purchasePrice: result.details[i].purchasePrice,
                          refundAmount: $scope.roundPrice(result.details[i].price) * result.details[i].quantity * -1,
                          quantity: result.details[i].quantity,
                          originalQuantity: result.details[i].originalQuantity,
                          priceVat: $scope.defaultTax,
                          discount: result.details[i].discount,
                          discountPercent: result.details[i].discountPercent,
                          discountValue: result.details[i].discountValue,
                          type: d.type,
                          pointsQuantity: points,
                          productId: result.details[i].productId && result.details[i].productId._id ? result.details[i].productId._id : null,
                          totalCaratWeight: result.details[i].totalCaratWeight ? result.details[i].totalCaratWeight : 0
                        })
                      } else {
                        $scope.addItemToTransaction({
                          name: $language.translate('REFUND') + ' ' + result.details[i].name,
                          price: $scope.roundPrice(result.details[i].price),
                          purchasePrice: result.details[i].purchasePrice,
                          stockUpQuantity: result.details[i].quantity * -1,
                          refundAmount: $scope.roundPrice(result.details[i].price) * result.details[i].quantity * -1,
                          quantity: result.details[i].quantity * -1,
                          originalQuantity: result.details[i].originalQuantity * -1,
                          priceVat: $scope.defaultTax,
                          discount: result.details[i].discount,
                          discountPercent: result.details[i].discountPercent,
                          discountValue: result.details[i].discountValue,
                          type: result.details[i].type === 'points' ? 'refund-points' : 'refund',
                          pointsQuantity: points,
                          total: $scope.getItemTotalPrice(result.details[i]),
                          transactionId: result.transactionId,
                          productId: result.details[i].productId && result.details[i].productId._id ? result.details[i].productId._id : null,
                          productNumber: result.details[i].productNumber ? result.details[i].productNumber : null,
                          supplier: result.details[i].supplier ? result.details[i].supplier : null,
                          totalCaratWeight: result.details[i].totalCaratWeight ? result.details[i].totalCaratWeight : 0
                        })
                      }

                      $scope.toggleItem($scope.transaction.details.length - 1)
                    }
                  }
                }, function () {
                })
              } else {

                var price = 0

                if(transaction.type === 'giftcard') {
                  price = transaction.transactionTotal - transaction.giftCardReedemAmount
                  if (transaction.transactionTotal - transaction.giftCardReedemAmount <= 0) {
                    toastr.error($language.translate('THIS_GIFTCARD_DOES_NOT_HAVE_ANY_VALUE'))
                    return
                  }
                  if(transaction.status !== 'paid') {
                    toastr.error($language.translate('THIS_GIFTCARD_IS_NOT_PAID'))
                    return
                  }
                } else if (transaction.type === 'repair' || transaction.type === 'special') {
                  price = transaction.transactionTotal - transaction.totalEarning
                } else {
                  price = transaction.transactionTotal
                }
                if(transaction.type !== 'repair' && transaction.type !== 'special') {
                  price = price * -1
                }

                var article = {
                  name: (transaction.type === 'giftcard' ? $language.translate('HANDING_IN') + ' ' : '') + $language.translate(transaction.type.toUpperCase()) + ' ' + (transaction.type === 'giftcard' ? transaction.special.repairNumber : transaction.number),
                  comment: (transaction.type === 'giftcard' ? $language.translate('RETURNED_GIFTCARD') : transaction.type === 'repair' || transaction.type === 'special' && transaction.comment ? transaction.comment : ''),
                  price: price,
                  quantity: 1,
                  priceVat: $scope.defaultTax,
                  type: transaction.type,
                  total: price,
                  transactionId: transaction._id,
                  originalPrice: price,
                  lastPrice: price,
                }

                if(transaction.type === 'repair' || transaction.type === 'special' || transaction.type === 'order') {
                  var extraTransaction = transaction._id
                  var transactionId = transaction.details && transaction.details[0] && transaction.details[0].transaction ? transaction.details[0].transaction : transaction.details[0]._id
                  if(transaction.details[0].extraTransaction) {
                    extraTransaction = transaction.details[0].extraTransaction
                  }
                  var parentTransactionDetailId = transaction.details[0].parentTransactionDetaildId ? transaction.details[0].parentTransactionDetaildId : transaction.details[0]._id ? transaction.details[0]._id : null

                  article.productId = transaction.details[0].productId
                  article.parentTransactionDetaildId = parentTransactionDetailId
                  article.transaction = transaction._id
                  article.extraTransaction = extraTransaction
                  if(transaction.type === 'repair') {
                    article.repairPayment = true
                    if(transaction.repair) {
                      if(transaction.repair.category) {
                        article.category = transaction.repair.category
                      }
                      if(transaction.repair.material) {
                        article.material = transaction.repair.material
                      }
                      if(transaction.repair.estimatedDate) {
                        article.estimatedDate = new Date(transaction.repair.estimatedDate)
                      }
                    }

                  } else {
                    article.specialPayment = true
                    article.type = 'order'
                  }
                }

                $scope.addItemToTransaction(article)
              }
            }
          })
          .catch(function (reason) {
            $log.error('error while fetching transaction', reason)
          })
      })
    }

    $scope.checkGiftcardValue = function (item) {
      if(item.price < item.originalPrice || item.price > 0) {
        toastr.error($language.translate('INVALID_GIFTCARD_VALUE'))
        item.price = item.lastPrice
        return
      }
      item.lastPrice = item.price
    }

    $scope.checkSerialNumberManagement = function(item){
      if($rootScope.currentShop.serialNumberManagement){
        $api.get('shop/search-serial-number/' + $rootScope.currentShop._id + '/' + item.productId)
        .then(function (response) {
          if(response.data.length){
            var giftcardModal = $uibModal.open({
              templateUrl: '../views/modal/retailer-serial-number-selection-modal.html',
              controller: 'retailerSerialNumberSelectionController',
              size: 'lg',
              backdrop: 'static',
              keyboard: false,
              resolve: {
                data: function () {
                  if (true) {
                    return response.data
                  } else {
                    return null
                  }
                }
              }
            })
      
            giftcardModal.result.then(function (result) {
              item.serialNumber = result.serialNumber;
              item.serialNumberId = result._id
              $scope.addItemToTransaction(item);
            }, function () {
            }) 
          }else{
            $scope.addItemToTransaction(item);
          }
        }).catch(function (reason) {
          consol.error(reason)
        })
      }else{
        $scope.addItemToTransaction(item);
      }
    }

    $scope.addItemToTransaction = function (item) {
      if(item.type === 'giftcard') {
        var index = _.findIndex($scope.transaction.details, { name: item.name, type: item.type, price: item.price })
        if (index >= 0 && item.transactionId === $scope.transaction.details[index].transactionId) {
          // Prevent adding the same giftcard multiple times
          toastr.warning($language.translate('THIS_GIFTCARD_IS_ALREADY_ADDED'))
          return
        }
      }

      if(item.type === 'repair' ) {
        if(!item.material || item.material === '') {
          item.material = 'UNKNOWN'
        }
        if(!item.category || item.category === '') {
          item.category = 'UNKNOWN'
        }
      }
      $scope.transaction.details.push(item)
    }

    $scope.redeemGiftcard = function (number) {
      number = number.replace('G-', '')
      $scope.getTransaction(number, true)
      $scope.giftcardNumber = null
    }

    $scope.redeemHelmondGiftcard = function (giftcardNumber) {
      //Miga Gifts

      var article = {
        name: 'Helmond giftcard ' + giftcardNumber,
        price: 0,
        margin: 0,
        purchasePrice: 0,
        quantity: 1,
        discount: false,
        priceVat: $scope.defaultTax,
        total: 0,
        type: 'helmond-giftcard',
        cardNumber: giftcardNumber,
      }
      $scope.addItemToTransaction(article)
    }

    $scope.roundPrice = function (price) {
      //Round the price's to 2 decimals, but check the third decimal for correct roundings
      //see https://stackoverflow.com/a/11832950/4548006
      return Math.round((price + Number.EPSILON) * 100) / 100
    }

    $scope.getItemTotalPrice = function (item) {
      if (item.type === 'points' || item.type === 'giftcard') {
        return item.price
      }
      var price = item.price
      if (item.discountPercent) {
        return (item.quantity * price) - (item.quantity * price * (item.discountValue / 100))
      }
      if (item.discountValue) {
        return item.quantity * price - (item.quantity * item.discountValue)
      }

      return $scope.roundPrice(item.quantity * price) * (item.type === 'gold-purchase' ? -1 : 1)
    }

    function setFocus(field) {
      setTimeout(function () {
        document.getElementById(field).focus()
      }, 500)
    }

    $scope.openTransaction = function (transaction, disabled) {
      if (disabled) { return }
      $scope.transaction = transaction
      $scope.transaction.details.forEach(function (d) {
        if(d.type !== 'giftcard') {
          d.price = d.price * (1 + (d.priceVat / 100))
          d.estimatedDate = d.estimatedDate ? new Date(d.estimatedDate.toString()) : null
        }
      })
      $scope.transactionSaved = true
      $scope.customer = transaction.customer
      $scope.parseCustomer()
    }
    // #endregion Set variables and global functions

    $scope.fetchInitialdata = function () {
      $scope.isNewPlatform = $rootScope.currentShop && $rootScope.currentShop.newPlatform && $rootScope.currentShop.newPlatform.isNewPlatform ? true : false;
      // #region Code for controller loads
      $scope.getTaxes();
      $scope.getConceptTransactions();
      $scope.checkPointsEndDate()
      if ($rootScope.currentShop && $rootScope.currentShop.cashRegister.showLockModal && !$stateParams.refund) {
        if($rootScope.checkEmployeeModalNecessity($rootScope.user)) {
          $employee.openUnlockModal()
        }
      }
      //  else if ($stateParams.refund && $stateParams.transactionNumber) {
      //   $scope.getTransaction($stateParams.transactionNumber)
      // }
    }

    $employee.getEmployees()
      .then(function (employees) {
        $scope.employees = employees.filter(function (e) {
          if (e.enabled && e.initials && ((e.hasRequiredPin && e.pincode) || !e.hasRequiredPin)) {
            if (e.admin || e.employeeCanBeAFirstUser) {
              return true;
            }
            if (e.shopRights && e.shopRights.length) {
              for (var i = 0; i < e.shopRights.length; i++) {
                if (e.shopRights[i].name == "USE_SERVICE_APP" && e.shopRights[i].enabled) {
                  return true;
                }
              }
            }
          }
        })
      })
      .catch(function (reason) {
        $log.error('Error while getting employees', reason)
      })

    if ($rootScope.currentShop && $rootScope.currentShop.cashRegister.showLockModal && !$stateParams.refund) {
      if($rootScope.checkEmployeeModalNecessity($rootScope.user)) {
        $employee.openUnlockModal()
      }
    } else if ($stateParams.refund && $stateParams.transactionNumber) {
      $scope.getTransaction($stateParams.transactionNumber)
    }

    if ($stateParams.customer) {
      $scope.customer = $stateParams.customer
      $scope.transaction.customer = $scope.customer
      $scope.parseCustomer()
    }

    if ($stateParams.checkout && $stateParams.items) {
      $scope.transaction.details = angular.copy($stateParams.items)
    }

    if ($stateParams.checkout && $stateParams.payLater) {
      $scope.payLater = true
    }

    if ($stateParams.concept) {
      $transaction
        .getTransaction($stateParams.transactionId)
        .then(function (transaction) {
          $scope.openTransaction(transaction)
          $stateParams.transactionId = null
        })
        .catch(function (reason) {
          dialogs.error(undefined, reason)
        })
    }


    // #endregion Code for controller loads

    // #region General scope functions

    $scope.getDayState = function (callback) {
      $scope.loadDayState = true
      $scope.dayStateAlert = null
      $scope.reason = null
      $scope.dayStateWarning = false
      getShop(function (shop) {
        $api.get('daystate/check', {
          shop: shop._id
        })
          .then(function (result) {
            if(!$rootScope.currentDevice) {
              $device.getCurrentDevice()
            }

            $scope.dayStateWarning = !result.data.result
            if($scope.dayStateWarning) {
              var hideValue = localStorage.getItem('hideDayStateWarning')
              if(hideValue && hideValue !== '') {
                var date = moment(parseFloat(hideValue))
                var today = moment()
                var isSameDay = moment(date).isSame(today, 'day')
                $scope.dayStateWarning = !isSameDay
              }
            }

            if($scope.dayStateWarning) {
              $scope.dayState = {
                date: result.data.date,
                webshop: result.data.webshop
              }
            }
            $scope.loadDayState = false
            if(callback) {
              return callback(null)
            }
          })
          .catch(function (reason) {
            $log.error('Error while checking daystate', reason)
            $scope.dayStateAlert = {
              reason: reason
            }
            $scope.loadDayState = false
            if(callback) {
              return callback(reason)
            }
          })
      })
    }

    $scope.createState = function () {
      $scope.loadDayState = true
      $scope.alert = null
      $scope.reason = null
      getShop(function (shop) {
        $api.post('daystate', {
          shop: shop._id
        })
          .then(function (state) {
            $scope.loadDayState = false
            $scope.getDayState()
          })
          .catch(function (reason) {
            $scope.loadDayState = false
            $log.error('Error while creating new daystate', reason)
            $scope.reason = reason
            $scope.alert = {
              type: 'danger',
              msg: $language.translate(reason.toUpperCase())
            }
          })
      })
    }

    $scope.getItems = function (mode) {
      return $q(function (resolve, reject) {
        $scope.loading[mode] = true
        if (mode === 'pool') {
          if (!$scope.poolArticles || $scope.poolArticles.length === 0) {
            $CashRegistry.getPoolArticlesSortedOnSells()
              .then(function (items) {
                $scope.poolArticles = items
                $scope.loading[mode] = false
                return resolve($scope.poolArticles)
              })
              .catch(function (reason) {
                $log.error('Error while getting pool articles', reason)
              })
          } else {
            $scope.loading[mode] = false
            return resolve($scope.poolArticles)
          }
        } else if (mode === 'collection') {
          if (!$scope.collections || $scope.collections.length === 0) {
            $shop.getShopsCollections($rootScope.currentShop.nameSlug)
              .then(function (success) {
                var reduced = success.shopCollections.reduce(function (filtered, item) {
                  if (!item.variantsCollection && item.showInTill) {
                    filtered.push({
                      name: item[$rootScope.language].name,
                      price: item.defaultPrice
                    })
                  }
                  return filtered
                }, [])
                $scope.collections = reduced
                $scope.loading[mode] = false
                return resolve($scope.collections)
              })
              .catch(function (error) {
                $scope.loading[mode] = false
                $log.error('error in finding shop collection', error)
              })
          } else {
            $scope.loading[mode] = false
            return resolve($scope.collections)
          }
        }
      })
    }

    $scope.openModal = function (mode) {
      $scope.getItems(mode)
        .then(function (items) {
          var modalInstance = $uibModal.open({
            templateUrl: '../views/modal/till-product-modal.html',
            controller: 'tillProductModalController',
            size: 'lg',
            resolve: {
              mode: function () {
                return mode
              },
              items: function () {
                return items
              },
              language: function () {
                return $rootScope.language
              }
            }
          })
          modalInstance.result.then(function (res) {
            var article = {}
            if (mode === 'pool') {
              $scope.addProduct(res, 'pool')
            } else {
              article = {
                name: res.name,
                price: res.price ? res.price : 0,
                margin: 2,
                purchasePrice: res.price / 2,
                quantity: 1,
                discount: false,
                priceVat: $scope.defaultTax,
                total: res.price ? res.price * (1 + ($scope.defaultTax / 100)) : 0,
                type: 'collection'
              }
              $scope.addItemToTransaction(article)
              $scope.toggleItem($scope.transaction.details.length - 1)
            }
          }, function (reason) {
            if (reason !== 'backdrop click' && reason !== 'cancel') {
              $log.error('Error in product modal', reason)
            }
          })
        })
        .catch(function (reason) {
          $log.error('Error while getting items', reason)
        })
    }

    $scope.openSearchCustomerModal = function () {
      $rootScope.checkModule('PACKAGE_CUSTOMER_MANAGEMENT', true, true)
        .then(function () {
          $customer.openSearchCustomerModal(true, true)
            .then(function (result) {
              if(result.customer && result.originalTransaction) {
                $scope.customer = result.customer
                $scope.getTransaction(result.originalTransaction.number, false, false)

              } else {
                $scope.customer = result

              }
              $scope.transaction.customer = $scope.customer
              $scope.parseCustomer()
              $scope.showContactWarning = true
            })
            .catch(function (reason) {
              if (reason !== 'backdrop click' || reason !== 'cancel') {
                $log.error('Error in search customer modal', reason)
              }
            })
        })
    }

    $scope.removeCustomer = function () {
      $scope.customer = null
      $scope.transaction.customer = null
    }

    $scope.changeEmployee = function (employee) {
      if (employee.hasRequiredPin) {
        $rootScope.focusEmployeeId = employee._id
        $employee.openUnlockModal()
      } else {
        var ep = {
          _id: employee._id,
          initials: employee.initials,
          pincode: employee.pincode && employee.pincode !== '' ? employee.pincode : null,
          hasRequiredPin: employee.hasRequiredPin
        }
        $employee.loginEmployee(ep)
          .then(function (employee) {
            $rootScope.currentEmployee = employee
          }).catch(function (reason) {
            $log.error('Error while changing employee', reason)
          })
      }
    }

    $scope.newTransaction = function () {
      $scope.transaction = {
        details: []
      }
      $scope.transactionSaved = false
      $scope.customer = null
      $scope.getConceptTransactions()
    }

    $scope.makeTransactionObject = function (concept) {
      return $q(function (resolve, reject) {
        var transaction = angular.copy($scope.transaction)

        transaction.language = $rootScope.language
        transaction.type = $scope.offerMode ? 'offer' : 'shop-purchase'
        transaction.shopId = $rootScope.currentShop._id.toString()
        transaction.createdBy = {
          _id: $rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id,
          name: $rootScope.currentEmployee ? $rootScope.currentEmployee.firstName : $rootScope.user.firstName
        }
        transaction.customer = $scope.customer ? {
          _id: $scope.customer._id,
          counter: $scope.customer.counter
        } : null
        transaction.concept = concept
        transaction.parentTransactionId = $scope.transaction.parentTransactionId ? $scope.transaction.parentTransactionId : $stateParams.transactionId ? $stateParams.transactionId : null

        for (var i = 0; i < transaction.details.length; i++) {
          transaction.details[i].total = $scope.getItemTotalPrice(transaction.details[i])
        }

        transaction.log = $scope.transaction.log && $scope.transaction.log.length > 0 ? $scope.transaction.log : []

        delete transaction.__v
        return resolve(transaction)
      })
    }

    $scope.getCurrentUser = function () {
      return $rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id
    }

    $scope.parkTransaction = function () {
      $scope.makeTransactionObject(true)
        .then(function (transaction) {
          $api.post('till/transaction/park', {
            transaction: transaction,
            user: $scope.getCurrentUser()
          }, null, 2)
            .then(function () {
              $scope.getConceptTransactions()
              $scope.newTransaction()
            })
            .catch(function (reason) {
              $log.error('Error when saving transaction', reason)
            })
        })
    }

    $scope.deleteTransaction = function (id) {
      $transaction.deleteTransaction(id)
        .then(function () {
          $scope.newTransaction()
          $scope.getConceptTransactions()
        })
        .catch(function (reason) {
          $log.error('Error while removing transaction', reason)
        })
    }

    $scope.useCustomInput = function (name) {
      var productType = getProductType()
      var article = {
        name: name,
        price: 0,
        margin: 2,
        quantity: 1,
        discount: false,
        discountPercent: 0,
        discountValue: 0,
        photo: null,
        priceVat: DEFAULT_TAX,
        total: 0,
        productNumber: null,
        purchasePrice: 0,
        ean: null,
        isStockProduct: false,
        productId: null,
        stock: null,
        hasStock: false,
        comment: '',
        supplier: null,
        estimatedDateOpen: false,
        estimatedDate: $rootScope.currentShop.cashRegister.disableEstimateDate === false && (productType === 'order' || productType === 'repair') ? new Date(moment().add(1, 'w')) : null,
        type: productType,
        repairPayment: false,
        specialPayment: false,
        status: productType === 'repair' ? 'to-repair' : null,
        entryMethodCustomerValue: true
      }

      $scope.addItemToTransaction(article)
      $scope.toggleItem($scope.transaction.details.length - 1)
      $scope.clearProductsSearch()
    }

    function fetchGetProductsByShopBrandsForOrderSearch(searchText, mode) {
      $scope.loading.productsByShopBrands = true;
      var language = $rootScope.language || 'nl';
      if ($scope.products.length) return;

      var objectdata = { language: language, isOnlyByBrand: true, isNewApprocah: $scope.isNewApprocah, offset: $scope.searchCurrentPage - 1, shopProductOffSet: $scope.searchCurrentShopProductPage - 1 }
      $shop.getShopProducts(searchText, mode, objectdata).then(function (result) {
        $scope.loading.productsByShopBrands = false;
        if (result && result.count && !$scope.productCount) {
          $scope.productsdata = result.products;
          $scope.productCount = result.count;
        }
      }).catch(function (reason) {
        $scope.loading.productsByShopBrands = false
        $log.error('Error when searching products 4', reason)
      })
    }

    function removeDuplicateProduct(hits, data) {

      var finalData = [];
      if (data && data.length) {
          for (var i = 0; i < data.length; i++) {
              if (!finalData.length) finalData.push(data[i]);
              for (var k = 0; k < finalData.length; k++) {
                  if (finalData[k]._id === data[i]._id) {
                    break;
                  }
                  if (k == (finalData.length - 1)) {
                      finalData.push(data[i]);
                  }
              }
          }
      }

      if (hits && hits.length && finalData && finalData.length) {
        for (var j = 0; j < finalData.length; j++) {
          for (var l = 0; l < hits.length; l++) {
            if (hits[l]._id && finalData[j]._id && hits[l]._id === finalData[j]._id) {
              hits[l].isDuplicated = true;
            }
          }
        }
      }

      
      var temphits = hits.filter(function (el) {
          return !el.isDuplicated;
      });

      return finalData.concat(temphits);
  }

    $scope.orderSearchNewApproach = function (searchText, mode, barcode) {
      $scope.isNewApprocah = true;
      if (mode === 'order') {
        fetchGetProductsByShopBrandsForOrderSearch(searchText, mode);
      }
      $scope.loading.products = true;
      var language = $rootScope.language || 'nl';
      var objectdata = { language: language, isOnlyByBrand: false, isNewApprocah: $scope.isNewApprocah, offset: $scope.searchCurrentPage - 1, shopProductOffSet: $scope.searchCurrentShopProductPage - 1 }
      $shop.getShopProducts(searchText, mode, objectdata).then(function (result) {
        $scope.loading.products = false;

        var shopProductsData = result.shopProducts; // From Shop Producct collecction
        var shopProductCount = result.shopProductCount; // From Shop Product collection count
        var products = result.products; // Fron Proudct collection
        var count = result.count; // From Product collection count

        var productsdata = products;
        var productCount = count;
        $scope.ShopProductCount = shopProductCount;
        $scope.shopProductsData = shopProductsData;

        $scope.productsdata = removeDuplicateProduct($scope.productsdata, productsdata);
        $scope.productCount = $scope.productsdata.length;

        if (barcode) {
          if (shopProductsData.length > 0) {
            if($rootScope.currentShop.multiShop) {

            }
            $scope.addProduct(shopProductsData[0])
          } else {
            // No results found, the code can be a giftcard
            $scope.getTransaction(searchText)
          }
        }
 
      }).catch(function (reason) {
        $scope.loading.products = false
        $log.error('Error when searching products 1', reason)
      })
    }

    function fetchGetProductsByShopBrands(searchText, mode) {
      $scope.loading.productsByShopBrands = true;
      var language = $rootScope.language || 'nl';
      if ($scope.products.length) return;
      $shop.getShopProducts(searchText, mode, { language: language, isOnlyByBrand: true }).then(function (result) {
        $scope.loading.productsByShopBrands = false;
        $scope.products = result;
      }).catch(function (reason) {
        $scope.loading.productsByShopBrands = false
        $log.error('Error when searching products 4', reason)
      })
    }

    $scope.searchProductOLD = function (searchText, mode, barcode) {
      if (mode === 'order') {
        fetchGetProductsByShopBrands(searchText, mode);
      }
      $scope.loading.products = true;
      var language = $rootScope.language || 'nl';
      $shop.getShopProducts(searchText, mode, { language: language, isOnlyByBrand: false }).then(function (result) {
        $scope.loading.products = false;
        if (barcode) {
          if(result.length === 1) {
            $scope.addProduct(result[0])
          } else if(result.length > 1) {
            //If shop has multishops and there are more results, we need to show the results
            //So, still select the first one if there are no mulitshops and return
            if(!$rootScope.currentShop.multiShops || !$rootScope.currentShop.multiShops.length === 0) {
              $scope.addProduct(result[0])
              return
            }
          } else {
            // No results found, the code can be a giftcard
            $scope.getTransaction(searchText)
          }
        }
        var concatArray = $scope.products.concat(result);
        $scope.products = concatArray;
      }).catch(function (reason) {
        $scope.loading.products = false
        $log.error('Error when searching products 1', reason)
      })
    }

    // Here, We are having two function, one is for old search function and one is for the new search function
    $scope.searchProducts = function (searchText, mode, barcode) {
      if ($scope.barcode === true && barcode !== true) {
        $scope.localdata.productSearch = ''
        return
      }
      $scope.products = [];
      $scope.productsdata = [];
      $scope.productCount = 0;
      $scope.shopProductsData = [];
      $scope.shopProductCount = 0;
      var transactionRepairRegex = new RegExp(/[TR]-[\d]{4,}-[\d]{6}-[\d]{4}/gm)
      var giftcardRegex = new RegExp(/[G]-.+/gm)
      var isTransactionOrRepairSpecial = transactionRepairRegex.test(searchText)
      var searchTextForisGiftcard = searchText.substring(0, 2);
      var isGiftcard = giftcardRegex.test(searchTextForisGiftcard);
      searchText = $scope.localdata.productSearch = $productConfig.retrunValidProductNumber(searchText);

      if (isTransactionOrRepairSpecial || isGiftcard) {
        // Transaction or special/repair or giftcard barcode
        scanner.parseBarcode(searchText)
          .then(function (parsedText) {
            $scope.getTransaction(parsedText.parsedCode, isGiftcard)
            $scope.clearProductsSearch()
            return
          })
          .catch(function (reason) {
            $log.error('Error while parsing text to barcode', reason)
          })
      }
      if (searchText && searchText.length > 2 &&
        // search because im meddy with three digits
        (!((searchText.substring(0, 2) === '00' && searchText.length < 4) ||// true (meddy) false for other product true
          (searchText.substring(0, 3) === '000' && searchText.length < 5) ||// true (meddy) false for other product true
          (searchText.substring(0, 4) === '0000' && searchText.length < 6) ||// true (meddy) false for other product true
          (searchText.substring(0, 5) === '00000' && searchText.length < 7)))) { // search because im not meddy
        if (mode == 'order') {
          $scope.orderSearchNewApproach(searchText, mode, barcode);
        } else {
          $scope.searchProductOLD(searchText, mode, barcode);
        }
        // if ($scope.searchProductOLD == true) {
        //   // Existing one(OLD ONE) search function
        // } else {
        //   // NEW search function same as NEW purchase order search function
        //   $scope.searchProductNEW(searchText, mode, barcode);
        // }
        // if (mode === 'order') {
        //   fetchGetProductsByShopBrands(searchText, mode);
        // }
        // $scope.loading.products = true;
        // var language = $rootScope.language || 'nl';
        // $shop.getShopProducts(searchText, mode, { language: language, isOnlyByBrand: false, isNewApprocah: true }).then(function (result) {
        //   $scope.loading.products = false;
        //   if (barcode) {
        //     if (result.length > 0) {
        //       $scope.addProduct(result[0])
        //       return
        //     } else {
        //       // No results found, the code can be a giftcard
        //       $scope.getTransaction(searchText)
        //     }
        //   }
        //   var concatArray = $scope.products.concat(result);
        //   $scope.products = concatArray;
        // }).catch(function (reason) {
        //   $scope.loading.products = false
        //   $log.error('Error when searching products 1', reason)
        // })
      }
    }

    $scope.clearProductsSearch = function () {
      $scope.products = []
      $scope.localdata.productSearch = null
    }

    function getProductType() {
      if ($scope.searchMode === 'stock' || $scope.searchMode === 'offer') {
        return 'product'
      }
      return $scope.searchMode
    }

    $scope.initialPriceRelatedCalculatioinForOrderMode = function (product) {
      return $q(function (resolve, reject) {
        $clientGroup.getShopClientGroupsByCompany($rootScope.currentShop._id, product.company._id)
          .then(function (clientGroupData) {
            $scope.shopsClientGroups = clientGroupData && clientGroupData.data ? clientGroupData.data : null

            var prefillCompanySettings = true

            if (product && product.company && product.company.prefillCompanySettings === false) {
              prefillCompanySettings = false
            }
            // If product.suggestedRetailerPrice and company.product.price are available
            if (prefillCompanySettings && product.prefillOrderItemData && product.prefillOrderItemData.companyProduct && product.prefillOrderItemData.companyProduct.price && product.prefillOrderItemData.suggestedRetailPrice) {
              product.prefillOrderItemData.isNeedToCalculateDiscount = true;
              return resolve($prefillCalculationForPurchasOrderAndCashRegister.calculateWhenBothPriceAvailableInCompanyProduct(product.prefillOrderItemData, product.priceVat))
            } else if (prefillCompanySettings && $scope.shopsClientGroups) { // If client group is available then calculation based on that
              $scope.prefillOrderItemData.isNeedToCalculateDiscount = true;
              return resolve($prefillCalculationForPurchasOrderAndCashRegister.calculateWhenClientGroupAvailable($scope.prefillOrderItemData, $scope.firstPriceVat, $scope.shopsClientGroups))
            } else if ($scope.prefillOrderItemData && $scope.prefillOrderItemData.shopProduct && $scope.prefillOrderItemData.shopProduct.price && $scope.prefillOrderItemData.shopProduct.purchasePrice) {
              return resolve($prefillCalculationForPurchasOrderAndCashRegister.calculateWhenBothPriceAvailableInShopProduct($scope.prefillOrderItemData, $scope.firstPriceVat))
            } else { // Default value
              return resolve($prefillCalculationForPurchasOrderAndCashRegister.calculateUsingShopCompanyDetailForPurchseOrder($scope.prefillOrderItemData, $scope.transaction.company))
            }
          })
          .catch(function (err) {
            return reject(err)
          })
      })
    }

    $scope.fetchBrandSupplierFromPopUp = function (product) {
      return $q(function (resolve, reject) {
        //Get the data for the modal
        $scope.getCompaniesPromise()
          .then(function (companies) {
            $scope.getShopBrandsPromise()
              .then(function (brands) {
                var modalInstance = $uibModal.open({
                  templateUrl: '../../views/modal/fetch-supplier-brand-for-cash-register-modal-controller.html',
                  controller: 'fetchBrandSupplierForCashRegisterController',
                  size: 'lg',
                  resolve: {
                    company: function () {
                      return product.company ? product.company : null
                    },
                    brand: function () {
                      return product.brand._id ? product.brand : null
                    },
                    supplierArray: function () {
                      return companies
                    },
                    shopBrands: function () {
                      return brands
                    }
                  }
                })

                modalInstance.result.then(function (res) {
                  if (res && Object.keys(res).length > 0) {
                    // Store supplier and brand from here only. then we will return positive response
                    product.company = res.company
                    product.brand = res.brand
                    return resolve(res)
                  } else {
                    return reject()
                  }
                }, function (reason) {
                  return reject(reason)
                })
              })
              .catch(function (reason) {
                $log.error('Error while getting shopBrands for fetchBrandSupplierFromPopup')
              })
          })
          .catch(function (reason) {
            $log.error('Error while getting companies for fetchBrandSupplierFromPopup')
          })
      })
    }

    function fetchedBusinessProductDetails(product, type, customPrice, stockCheck) {
      var businessProductDetail = JSON.parse(JSON.stringify(product));
      console.log('businessProductDetail: ', businessProductDetail);
      $scope.localdata.productSearch = '';
      var shopId = product.shopId ? product.shopId : $rootScope.currentShop._id
      var body = {
        _id: product._id,
        shopId: shopId,
        iBusinessId: $rootScope.currentShop.newPlatform.iBusinessId,
        iArticleGroupId: businessProductDetail.iArticleGroupId,
        // sOrganization: $rootScope.currentShop.newPlatform.sOrganization,
        iOrganizationId: $rootScope.currentShop.newPlatform.iOrganizationId,
        iSupplierId: businessProductDetail.iSupplierId
      }
      console.log('business product: ', body);
      $rootScope.currentShop.newPlatform.iArticleGroupId = businessProductDetail.iArticleGroupId;
      $rootScope.currentShop.newPlatform.iSupplierId = businessProductDetail.iSupplierId;
      $shop.getBusinessProduct(body).then(function (result) {
        $scope.clearProductsSearch();
        if (result && result.data && result.data.data) {
          var product = result.data.data;
          $CashRegistry.makeProductTextAndGetStock(product).then(function (info) {
            var productType = type ? type : getProductType()
            var suggestedRetailPrice = product.suggestedRetailPrice ? product.suggestedRetailPrice : 0
            var suggestedRetailPriceVat = product.suggestedRetailPriceVat ? product.suggestedRetailPriceVat : 0
            var priceVat = DEFAULT_TAX
            var productPrice = 0
            var purchasePrice = product.purchasePrice ? product.purchasePrice : 0
            var margin = product.margin ? product.margin : product.purchasePrice ? ((product.price * (1 + (product.priceVat / 100))) / product.purchasePrice) : 0
            product.orderDiscount = false
            if (productType === 'order' && product.discount > 0) {
              //order product with discount
              //set the price with discount as regular price, dont set any discount here because that's giving problems
              var price = product.price * (1 + (product.priceVat / 100))
              if (product.discountOnPercentage) {
                productPrice = $scope.roundPrice(price - (price * (product.discount / 100)))
              } else {
                productPrice = $scope.roundPrice(price - product.discount)
              }
              priceVat = product.priceVat
              product.discount = 0
              product.discountPercent = false
              product.discountOnPercentage = false
              //Set to true to show message in till about price change
              product.orderDiscount = true
            } else if (customPrice) {
              productPrice = customPrice
              if (product.priceVat) {
                priceVat = product.priceVat
              }
            } else if (parseFloat(product.price) >= 0 && product.priceVat >= 0) {
              productPrice = $scope.roundPrice(product.price * (1 + (product.priceVat / 100)))
              priceVat = product.priceVat
            } else if (suggestedRetailPrice > 0 && suggestedRetailPriceVat >= 0) {
              productPrice = $scope.roundPrice(suggestedRetailPrice * (1 + (suggestedRetailPriceVat / 100)))
              priceVat = suggestedRetailPriceVat
            }

            if (productType === 'repair') {
              productPrice = 0
              margin = 2
            }

            var purchaseDetails = {}
            if (product.purchaseDetails) {
              purchaseDetails = product.purchaseDetails
            }

            var prefillOrderItemData = {}

            prefillOrderItemData.suggestedRetailPrice = product.suggestedRetailPrice ? product.suggestedRetailPrice : 0
            prefillOrderItemData.suggestedRetailPriceVat = product.suggestedRetailPriceVat ? product.suggestedRetailPriceVat : 0

            if (product.isCompanyProduct && product.companyProduct && Object.keys(product.companyProduct).length > 0) {
              prefillOrderItemData.isCompanyProduct = true
              prefillOrderItemData.companyProduct = product.companyProduct
            } else {
              prefillOrderItemData.isCompanyProduct = false
            }

            if (product.isShopProduct) {
              prefillOrderItemData.isShopProduct = true
              prefillOrderItemData.shopProduct = {
                purchasePrice: product.purchasePrice,
                price: product.price,
                priceVat: product.priceVat,
                entryMethodCustomerValue: !(typeof product.entryMethodCustomerValue === 'boolean' && product.entryMethodCustomerValue === false)
              }
            } else {
              prefillOrderItemData.isShopProduct = false
              product.isShopProduct = false
            }

            purchaseDetails.isShopProduct = product.isShopProduct ? product.isShopProduct : false
            purchaseDetails.hasSupplier = !!((product.shopProduct && product.shopProduct.supplier && product.shopProduct.supplier._id) || (product.purchaseDetails && product.purchaseDetails.supplierDetails && product.purchaseDetails.supplierDetails._id))

            var hasStock = product.hasStock
            if ((product.brandData && product.brandData.isPoolArticleBrand) || stockCheck === false) {
              hasStock = false
            }

            var article = {
              name: info.name,
              price: productPrice,
              suggestedRetailPrice: suggestedRetailPrice,
              suggestedRetailPriceVat: suggestedRetailPriceVat,
              margin: $scope.roundPrice(margin),
              quantity: 1,
              discount: product.discount > 0,
              discountPercent: product.discountOnPercentage,
              discountValue: product.discount,
              photos: product.images && product.images.length > 0 && product.images[0] ? {
                src: product.images[0].src ? product.images[0].src : '',
                alt: product.images[0].alt ? product.images[0].alt : ''
              } : null,
              priceVat: priceVat,
              total: product.price ? $scope.roundPrice(product.price * (1 + (product.priceVat ? product.priceVat : DEFAULT_TAX) / 100)) : 0,
              productNumber: product.articleNumber,
              productNumberSupplier: product.variants && product.variants.length > 0 ? product.variants[0].productNumber : null,
              purchasePrice: purchasePrice,
              ean: product.variants[0].ean,
              isStockProduct: !!(product && product.shopProduct && product.shopProduct.stock && product.shopProduct.stock > 0),
              productId: product._id,
              stock: info.stock,
              hasStock: hasStock,
              comment: info.diamondInfo,
              entryMethodCustomerValue: !(typeof product.entryMethodCustomerValue === 'boolean' && product.entryMethodCustomerValue === false),
              supplier: product.purchaseDetails && product.purchaseDetails.supplierDetails && product.purchaseDetails.supplierDetails._id ? product.purchaseDetails.supplierDetails._id : product.supplier ? product.supplier : null,
              estimatedDateOpen: false,
              estimatedDate: $rootScope.currentShop.cashRegister.disableEstimateDate === false && (productType === 'order' || productType === 'repair') ? new Date(moment().add(1, 'w')) : null,
              type: productType,
              repairPayment: false,
              specialPayment: false,
              status: productType === 'repair' ? 'to-repair' : null,
              purchaseDetails: purchaseDetails,
              prefillOrderItemData: prefillOrderItemData,
              shopId: product.shopId,
              orderDiscount: product.orderDiscount,
              collections: product.collections,
              businessDetail: {
                iBusinessProductId: businessProductDetail._id,
                iArticleGroupId: businessProductDetail.iArticleGroupId,
                iSupplierId: businessProductDetail.iSupplierId,
              },
              totalCaratWeight: product.totalCaratWeight ? product.totalCaratWeight : 0
            }
            $scope.clearProductsSearch();

            $scope.addItemToTransaction(article)
            $scope.toggleItem($scope.transaction.details.length - 1)
          }).catch(function (caerror) {
            console.error('error adding item', caerror);
          });
        }
      }).catch(function (reason) {
        console.error('fetchedBusinessProductDetails reason: ', reason);
      })
    }

    $scope.addProduct = function (product, type, customPrice, stockCheck) {
      if ($scope.isNewPlatform) {
        fetchedBusinessProductDetails(product, type, customPrice, stockCheck);
        return;
      }
      var serialNumber = product.serialNumber || '';
      $scope.localdata.productSearch = '';
      var shopId = product.shopId ? product.shopId : $rootScope.currentShop._id
      $shop.getProductForCashRegistry(shopId, product._id)
        .then(function (product) {
          $CashRegistry.makeProductTextAndGetStock(product)
            .then(function (info) {
              var productType = type ? type : getProductType()
              var suggestedRetailPrice = product.suggestedRetailPrice ? product.suggestedRetailPrice : 0
              var suggestedRetailPriceVat = product.suggestedRetailPriceVat ? product.suggestedRetailPriceVat : 0
              var priceVat = DEFAULT_TAX
              var productPrice = 0
              var purchasePrice = product.purchasePrice ? product.purchasePrice : 0
              var margin = product.margin ? product.margin : product.purchasePrice ? ((product.price * (1 + (product.priceVat / 100))) / product.purchasePrice) : 0
              product.orderDiscount = false
              if(productType === 'order' && product.discount > 0) {
                //order product with discount
                //set the price with discount as regular price, dont set any discount here because that's giving problems
                var price = product.price * (1 + (product.priceVat / 100))
                if(product.discountOnPercentage) {
                  productPrice = $scope.roundPrice(price - (price * (product.discount / 100)))
                } else {
                  productPrice = $scope.roundPrice(price - product.discount)
                }
                priceVat = product.priceVat
                product.discount = 0
                product.discountPercent = false
                product.discountOnPercentage = false
                //Set to true to show message in till about price change
                product.orderDiscount = true
              } else if (customPrice) {
                productPrice = customPrice
                if(product.priceVat) {
                  priceVat = product.priceVat
                }
              } else if (parseFloat(product.price) >=0 && product.priceVat >= 0) {
                productPrice = $scope.roundPrice(product.price * (1 + (product.priceVat / 100)))
                priceVat = product.priceVat
              } else if (suggestedRetailPrice > 0 && suggestedRetailPriceVat >= 0) {
                productPrice = $scope.roundPrice(suggestedRetailPrice * (1 + (suggestedRetailPriceVat / 100)))
                priceVat = suggestedRetailPriceVat
              }

              if (productType === 'repair') {
                productPrice = 0
                margin = 2
              }

              var purchaseDetails = {}
              if (product.purchaseDetails) {
                purchaseDetails = product.purchaseDetails
              }

              var prefillOrderItemData = {}

              prefillOrderItemData.suggestedRetailPrice = product.suggestedRetailPrice ? product.suggestedRetailPrice : 0
              prefillOrderItemData.suggestedRetailPriceVat = product.suggestedRetailPriceVat ? product.suggestedRetailPriceVat : 0

              if (product.isCompanyProduct && product.companyProduct && Object.keys(product.companyProduct).length > 0) {
                prefillOrderItemData.isCompanyProduct = true
                prefillOrderItemData.companyProduct = product.companyProduct
              } else {
                prefillOrderItemData.isCompanyProduct = false
              }

              if (product.isShopProduct) {
                prefillOrderItemData.isShopProduct = true
                prefillOrderItemData.shopProduct = {
                  purchasePrice: product.purchasePrice,
                  price: product.price,
                  priceVat: product.priceVat,
                  entryMethodCustomerValue: !(typeof product.entryMethodCustomerValue === 'boolean' && product.entryMethodCustomerValue === false)
                }
              } else {
                prefillOrderItemData.isShopProduct = false
                product.isShopProduct = false
              }

              purchaseDetails.isShopProduct = product.isShopProduct ? product.isShopProduct : false
              purchaseDetails.hasSupplier = !!((product.shopProduct && product.shopProduct.supplier && product.shopProduct.supplier._id) || (product.purchaseDetails && product.purchaseDetails.supplierDetails && product.purchaseDetails.supplierDetails._id))

              var hasStock = product.hasStock
              if( (product.brandData && product.brandData.isPoolArticleBrand) || stockCheck === false) {
                hasStock = false
              }

              var article = {
                name: info.name,
                price: productPrice,
                suggestedRetailPrice: suggestedRetailPrice,
                suggestedRetailPriceVat: suggestedRetailPriceVat,
                margin: $scope.roundPrice(margin),
                quantity: 1,
                discount: product.discount > 0,
                discountPercent: product.discountOnPercentage,
                discountValue: product.discount,
                photos: product.images && product.images.length > 0 ? {
                  src: product.images[0].src,
                  alt: product.images[0].alt
                } : null,
                priceVat: priceVat,
                total: product.price ? $scope.roundPrice(product.price * (1 + (product.priceVat ? product.priceVat : DEFAULT_TAX) / 100)) : 0,
                productNumber: product.articleNumber,
                serialNumber: serialNumber,
                productNumberSupplier: product.variants && product.variants.length > 0 ? product.variants[0].productNumber : null,
                purchasePrice: purchasePrice,
                ean: product.variants[0].ean,
                isStockProduct: !!(product && product.shopProduct && product.shopProduct.stock && product.shopProduct.stock > 0),
                productId: product._id,
                stock: info.stock,
                hasStock: hasStock,
                comment: info.diamondInfo,
                entryMethodCustomerValue: !(typeof product.entryMethodCustomerValue === 'boolean' && product.entryMethodCustomerValue === false),
                supplier: product.purchaseDetails && product.purchaseDetails.supplierDetails && product.purchaseDetails.supplierDetails._id ? product.purchaseDetails.supplierDetails._id : null,
                estimatedDateOpen: false,
                estimatedDate: $rootScope.currentShop.cashRegister.disableEstimateDate === false && (productType === 'order' || productType === 'repair') ? new Date(moment().add(1, 'w')) : null,
                type: productType,
                repairPayment: false,
                specialPayment: false,
                status: productType === 'repair' ? 'to-repair' : null,
                purchaseDetails: purchaseDetails,
                prefillOrderItemData: prefillOrderItemData,
                shopId: product.shopId,
                orderDiscount: product.orderDiscount,
                totalCaratWeight: product.totalCaratWeight ? product.totalCaratWeight : 0
              }
              $scope.clearProductsSearch()
              if ($scope.searchMode === 'order') {
                if (!product.brand || !product.company) {
                  return $scope.fetchBrandSupplierFromPopUp(product)
                    .then(function (details) {
                      article.purchaseDetails = details.purchaseDetails
                      article.brand = details.brand
                      article.company = details.company

                      $scope.initialPriceRelatedCalculatioinForOrderMode(article)
                        .then(function (priceCalculation) {
                          article.margin = priceCalculation.margin ? priceCalculation.margin : 0
                          article.storageFactor = priceCalculation.storageFactor ? priceCalculation.storageFactor : 0
                          article.entryMethodCustomerValue = !(typeof priceCalculation.entryMethodCustomerValue === 'boolean' && priceCalculation.entryMethodCustomerValue === false)
                          article.purchasePrice = priceCalculation.purchasePrice ? priceCalculation.purchasePrice : article.price / article.margin
                          $scope.addItemToTransaction(article)
                          $scope.toggleItem($scope.transaction.details.length - 1)
                        })
                        .catch(function (reason) {
                          $scope.addItemToTransaction(article)
                          $scope.toggleItem($scope.transaction.details.length - 1)
                          $log.error('Something went wrong when price calculation for order searchMode after fetchBrandSupplierFromPopUp', reason)
                        })
                    })
                    .catch(function (reason) {
                      $log.error('Something went wrong when price fetchBrandSupplierFromPopUp', reason)
                    })
                } else {
                  $scope.initialPriceRelatedCalculatioinForOrderMode(article)
                    .then(function (priceCalculation) {
                      article.margin = priceCalculation.margin ? priceCalculation.margin : 0
                      article.storageFactor = priceCalculation.storageFactor ? priceCalculation.storageFactor : 0
                      article.entryMethodCustomerValue = !(typeof priceCalculation.entryMethodCustomerValue === 'boolean' && priceCalculation.entryMethodCustomerValue === false)
                      $scope.addItemToTransaction(article)
                      $scope.toggleItem($scope.transaction.details.length - 1)
                    })
                    .catch(function (reason) {
                      $log.error('Something went wrong when price calculation for order searchMode', reason)
                    })
                }
                if (product.brand && product.brand._id && product.brand.name) {
                  $scope.selectedBrand(product.brand)
                }
                if (product.purchaseDetails && product.purchaseDetails.supplierDetails && product.purchaseDetails.supplierDetails._id && product.purchaseDetails.supplierDetails.name) {
                  $scope.selectedCompany(product.purchaseDetails.supplierDetails)
                }
              } else {
                $scope.checkSerialNumberManagement(article)
                $scope.toggleItem($scope.transaction.details.length - 1)
              }
            })
            .catch(function (reason) {
              $log.error('Error while getting productname', reason)
            })
        })
        .catch(function (reason) {
          $log.error('Error while fetching more product details', reason)
        })
    }

    /**
     * Get totals of items and do some calculations based on the type of products
     * @param mode
     * @returns {number|*}
     */
    $scope.getTotals = function (mode) {
      var totalPrice = 0
      var totalQuantity = 0
      var counter = 0
      $scope.types = {
        types: [],
        totalUnique: 0
      }
      $scope.productsTotal = 0
      $scope.repairSpecialTotal = 0
      if (!$scope.transaction.details || $scope.transaction.details.length === 0) {
        return 0
      }
      for (var i = 0; i < $scope.transaction.details.length; i++) {

        var type = $scope.transaction.details[i].type
        var itemPrice = $scope.transaction.details[i].payLater === true ? 0 : $scope.getItemTotalPrice($scope.transaction.details[i])
        if(!$scope.transaction.details[i].payLater || $scope.transaction.details[i].payLater === false) {
          if(type === 'repair' || type === 'order') {
            $scope.repairSpecialTotal += $scope.transaction.details[i].total
          }
          //if(type !== 'refund') {
            //var total = $scope.transaction.details[i].total * (type === 'gold-purchase' ? -1 : 1)
            $scope.productsTotal += itemPrice
          //}
        }

        if(type !== 'points' && type !== 'giftcard' && type !== 'text' && type !== 'empty') {
          var idx = _.findIndex($scope.types.types, { type: type })
          if (idx >= 0) {
            $scope.types.types[idx].count++
            $scope.types.types[idx].totalAmount += itemPrice
          } else {
            $scope.types.types.push({
              type: type,
              count: 1,
              totalAmount: itemPrice
            })
            $scope.types.totalUnique += 1
          }
        }

        totalPrice += itemPrice
        totalQuantity += $scope.transaction.details[i].quantity

        counter++
        if (counter === $scope.transaction.details.length) {
          //$scope.productsTotal -= $scope.repairSpecialTotal
          if (mode === 'price') {
            return $scope.roundPrice(totalPrice)
          }
          if (mode === 'quantity') {
            return totalQuantity
          }
        }
      }
    }

    $scope.changePrefill = function (changeField, index) {
      var item = $scope.transaction.details[index]
      if (item.type === 'product' || item.type === 'order' || item.type === 'repair' || item.type === 'pool' || item.type === 'collection' || item.type === 'gold-sell') {
        if (changeField === 'price') {
          if(item.type === 'gold-sell' && item.purchasePrice) {
            item.storageFactor = (item.price - (1 + item.priceVat / 100)) / item.purchasePrice
          } else {
            if ((item.type === 'collection' || item.type === 'repair' || item.type === 'order' || item.entryMethodCustomerValue) && item.margin) {
              item.purchasePrice = item.price / item.margin
            } else {
              item.purchasePrice = (item.price / (1 + (item.priceVat / 100))) / item.storageFactor
            }
          }
        }

        if (changeField === 'purchasePrice' || changeField === 'vat') {
          if ((item.type === 'collection' || item.type === 'repair' || item.type === 'gold-sell' || item.entryMethodCustomerValue ) && item.price) {
            item.margin = item.price / item.purchasePrice
          } else if (item.entryMethodCustomerValue || item.type === 'gold-sell' && item.storageFactor) {
            item.price = (item.purchasePrice * item.storageFactor) * (1 + (item.priceVat / 100))
          }
        }

        if (changeField === 'margin') {
          if ((item.type === 'collection' || item.type === 'repair' || item.type === 'gold-sell' || item.entryMethodCustomerValue) && item.margin) {
            item.purchasePrice = item.price / item.margin
          }
        }

        if (changeField === 'storageFactor') {
          if ((item.type === 'collection' || item.type === 'repair' || item.type === 'gold-sell' || item.entryMethodCustomerValue) && item.storageFactor) {
            item.price = (item.purchasePrice * item.storageFactor) * (1 + (item.priceVat / 100))
          }
        }
      }
    }

    $scope.toggleItem = function (index) {
      for (var i = 0; i < $scope.transaction.details.length; i++) {
        if (i === index) { continue }
        $scope.transaction.details[i].open = false
      }

      $scope.transaction.details[index].open = !$scope.transaction.details[index].open
      // To trigger the $watch
      // if (index === $scope.currentIdx) {
      //   $scope.currentIdx = null
      // } else {
      $scope.currentIdx = index
      //}
    }

    $scope.calculateTotal = function (item, changeField, index, orderDiscount) {
      if(orderDiscount) {
        item.orderDiscount = false
      }
      item.total = item.quantity * item.price

      $scope.resetPayments()
      $scope.changePrefill(changeField, index);
    }

    $scope.toggleCustomDiscount = function (index) {
      if (!$scope.transaction.details[index].customDiscount) {
        $scope.transaction.details[index].customDiscount = true
        return
      }
      $scope.transaction.details[index].customDiscount = !$scope.transaction.details[index].customDiscount
    }

    $scope.getDiscountValue = function (item) {
      if (item.discount && item.discountValue && item.discountValue > 0) {
        if (item.discountPercent) {
          return item.discountValue + '%'
        } else {
          return $filter('currency')(item.discountValue, '€')
        }
      } else {
        return '-'
      }
    }

    $scope.addDiscount = function (discount, percent, index) {
      var item = $scope.transaction.details[index]
      if ((percent && discount > 100) || (!percent && discount > item.price)) {
        $scope.transaction.details[index].txtDiscountValue = null
        $scope.transaction.details[index].discountValue = null
        toastr.error($language.translate('DISCOUNT_CANT_BE_HIGHER_THAN_PRODUCTPRICE'))
        return
      }
      $scope.resetPayments()
      $scope.transaction.details[index].discount = true
      $scope.transaction.details[index].discountValue = discount
      $scope.transaction.details[index].discountPercent = percent
      $scope.transaction.details[index].total = $scope.getItemTotalPrice($scope.transaction.details[index])
    }

    $scope.getDiscount = function (discount, percent, item) {
      if (item.discount && item.discountValue && item.discountValue > 0) {
        return item.discountValue === discount && item.discountPercent === percent
      } else {
        return false
      }
    }

    $scope.removeDiscount = function (item)  {
      if(item.discount === false) {
        //Close discount option only when there is no discount (anymore) on the selected item
        $scope.hideDiscount = true
      }
      item.discount = false
      item.discountValue = null
      item.discountPercent = false

    }

    $scope.noVat = function (index) {
      var item = $scope.transaction.details[index]
      var priceWithoutVat = item.price / (1 + (item.priceVat / 100))
      var price = Math.round(((priceWithoutVat + Number.EPSILON) * 100)) / 100
      $scope.transaction.details[index].price = price
      $scope.transaction.details[index].priceVat = 0
      $scope.transaction.details[index].vatRemoved = true
    }

    $scope.openGenerateGiftcardModal = function (generateForGoldPurchase, amount) {
      $scope.giftcardModalOpened = true;
      var giftcardModal = $uibModal.open({
        templateUrl: '../views/modal/retailer-generate-giftcard-modal.html',
        controller: 'generateGiftcardModalController',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          transaction: function () {
            $scope.transaction.totalAmount = $scope.totalAmount
            return $scope.transaction
          },
          taxes: function () {
            return $scope.taxes
          },
          printGiftcard: function () {
            return true
          },
          amount: function () {
            return amount ? $scope.getTotals('price') : null
          }
        }
      })

      giftcardModal.closed.then(function(result){
        $scope.giftcardModalOpened = false;
      });

      giftcardModal.result.then(function (result) {
        var article = {
          name: generateForGoldPurchase ? $language.translate('GIFTCARD_FOR_GOLDPURCHASE') : $language.translate('PAYMENT_FOR_GIFTCARD'),
          priceVat: result.taxRate,
          quantity: 1,
          price: result.amount,
          giftCardTransaction: result.giftCardId,
          isNewProduct: false,
          isRestictForEdit: true,
          type: 'giftcard-sell',
          total: result.amount,
          vatPaid: result.vatPaid,
          comment: result.number
        }

        $scope.addItemToTransaction((article))
      }, function () {

      })
    }

    $scope.showSearchBySerialNumber = function (){
      var shopArr = [
        '5a0af3d4f6bdc126443d159d',
        '5a0af3fbf6bdc126443d159f',
        '5a0af41ef6bdc126443d15a1',
        '5a0af444f6bdc126443d15a3',
        '5a0af52af6bdc126443d3eaf'
      ];
      if(shopArr.indexOf($rootScope.currentShop._id) > -1) return true;
      else return false;
    }

    $scope.searchBySerialNumber = function (keyEvent, productSearch, searchMode, barcode){
      $scope.products = [];
      $scope.loading.products = true;

      $api.get('shop/search-product-by-serial-number/' + $rootScope.currentShop._id + '/' + productSearch)
      .then(function (response) {
        $scope.products = response.data.data || [];
        $scope.loading.products = false;
      }).catch(function (reason) {
        $scope.loading.products = false;
      })
    }

    $scope.openSearchTransactionsModal = function () {
      var giftcardModal = $uibModal.open({
        templateUrl: '../views/modal/retailer-search-transactions-modal.html',
        controller: 'searchTransactionsModal',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          customer: function () {
            if ($scope.transaction.customer) {
              return $scope.transaction.customer
            } else {
              return null
            }
          }
        }
      })

      giftcardModal.result.then(function (result) {
        $scope.getTransaction(result.transaction.number, false)

        // var article = {
        //   name: generateForGoldPurchase ? $language.translate('GIFTCARD_FOR_GOLDPURCHASE') : $language.translate('PAYMENT_FOR_GIFTCARD'),
        //   priceVat: result.taxRate,
        //   quantity: 1,
        //   price: result.amount,
        //   giftCardTransaction: result.giftCardId,
        //   isNewProduct: false,
        //   isRestictForEdit: true,
        //   type: 'giftcard-sell',
        //   total: result.amount
        // }
        //
        // $scope.addItemToTransaction((article))
      }, function () {

      })
    }

    $scope.openToggleOfferModeModal = function (mode) {
      var toggleOfferModeModal = $uibModal.open({
        templateUrl: '../views/modal/retailer-toggle-offer-mode-modal.html',
        controller: 'toggleOfferModeModalController',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          offerMode: function () {
            return $scope.offerMode
          }
        }
      })

      toggleOfferModeModal.result
        .then(function () {
          $scope.transaction.details = []
          $scope.changeMode($scope.offerMode ? 'offer' : mode ? mode : 'stock')
        })
        .catch(function () {
          // Cancel switching to/from offer mode.
        })
    }

    $scope.changeMode = function (mode) {
      // if (($scope.offerMode || mode === 'offer') && $scope.transaction.details.length > 0) {
      //   $scope.openToggleOfferModeModal(mode)
      // }
      if(mode === 'offer') {
        toastr.warning($language.translate('OFFER_MODE_IS_TEMP_DISABLED'))
        return
      }
      $scope.searchMode = mode
      $scope.offerMode = mode === 'offer'
      $scope.clearProductsSearch()
      if (mode === 'repair') {
        $CashRegistry.getRepairBagNumber('repair')
          .then(function (bagNumber) {
            var article = {
              name: $language.translate('REPAIR'),
              price: 0,
              margin: 2,
              quantity: 1,
              discount: false,
              priceVat: $scope.defaultTax,
              total: 0,
              estimatedDateOpen: false,
              estimatedDate: $rootScope.currentShop.cashRegister.disableEstimateDate === false ? new Date(moment().add(1, 'w')) : null,
              type: 'repair',
              repairPayment: false,
              specialPayment: false,
              status: 'to-repair',
              payLater: true,
            }

            if($rootScope.currentShop.cashRegister.enableEstimateDateSpecial) {

              var createdBeforeDay = $rootScope.currentShop.cashRegister.estimateDateSpecialEndDay ? parseInt($rootScope.currentShop.cashRegister.estimateDateSpecialEndDay) : 4
              var readyInWeeks = $rootScope.currentShop.cashRegister.estimateDateSpecialReadyInWeeks ? parseInt($rootScope.currentShop.cashRegister.estimateDateSpecialReadyInWeeks) : 2
              var elseReadyInWeeks = $rootScope.currentShop.cashRegister.estimateDateSpecialReadyInWeeksAlt ? parseInt($rootScope.currentShop.cashRegister.estimateDateSpecialReadyInWeeksAlt) : 3
              
              //this will pick the next saturday in 2 or 3 weeks, based on what day of the week it is (adding an extra week after wednesday)
              var weeksToBeAdded = (moment().isoWeekday() < createdBeforeDay) ? readyInWeeks : elseReadyInWeeks; 

              article.estimatedDate = new Date(moment().add(weeksToBeAdded, 'weeks').isoWeekday(6));
            }
            
            if(bagNumber && bagNumber > 0) {
              article.bagNumber = bagNumber
            }
            $scope.addItemToTransaction(article)
            $scope.toggleItem($scope.transaction.details.length - 1)
            setFocus('orderComment-' + ($scope.transaction.details.length - 1).toString())
          })
          .catch(function (reason){
            toastr.error($language.translate(reason))
          })
      }
      if(mode === 'stock' || mode === 'order') {
        setFocus('searchField')
      }
    }

    $scope.deleteItem = function (index, points) {
      if (points) {
        $scope.customer.points.spendable += $scope.transaction.pointsRedeemded
        $scope.transaction.pointsRedeemded = 0
        $scope.pointsAdded = false
      }
      $scope.transaction.details.splice(index, 1)
    }

    $scope.startCardPayment = function (transaction, amount) {
      return $q(function (resolve, reject) {
        $card.startPinTransaction(transaction, amount)
          .then(function (result) {
            if(result === null || result.success === false) {
              //Payment is cancelled, save as concept
              $scope.resetPayments()
              $scope.updateConceptStatus(true, transaction._id)
                .then(function () {
                  $log.info('transaction changed to concept')
                  $scope.getConceptTransactions()
                  $transaction.getTransaction(transaction._id, false, true, $rootScope.currentShop._id)
                    .then(function (transaction) {
                      $scope.openTransaction(transaction)
                    })
                    .catch(function (reason) {
                      $log.error('Error while opening concept transaction', reason)
                    })
                })
                .catch(function (reason) {
                  $log.error('error while updating concept status', reason)
                  return
                })
              return reject($language.translate('PAYMENT_FAILED'))
            }
            if (result.success) {
              $api.post('till/transaction/payment', {
                transactionId: transaction._id,
                payment: result,
                method: 'manual',
                paidAmount: result.paidAmount ? result.paidAmount : $scope.payments.pin,
                transactionTotal: transaction.transactionTotal,
                shopId: $rootScope.currentShop._id,
                user: $scope.getCurrentUser()
              }, null, 2)
                .then(function (response) {
                  return resolve({ cardResult: result, paymentResult: response })
                })
                .catch(function (reason) {
                  return reject(reason)
                })
            } else if (result.result.approved === '1') { // Pay.nl payment
              $api.post('till/transaction/payment', {
                transactionId: transaction._id,
                payment: result,
                shopId: $rootScope.currentShop._id,
                method: 'pay',
                paidAmount: result.paidAmount ? result.paidAmount : $scope.payments.pin,
                user: $scope.getCurrentUser()
              }, null, 2)
                .then(function (response) {
                  return resolve({ cardResult: result, paymentResult: response })
                })
                .catch(function (reason) {
                  return reject(reason)
                })
            }else if(result.result.ccvTransactionPending === false) { // CCV
              //Payment is already added in the webhook, so we can directly continue here
              return resolve({cardResult: result, paymentResult: null})
            } else {
              // Unkown error occured, reopen cash register
              $state.go('retailer.till', { transactionId: transaction._id, concept: true})
            }
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    $scope.removePartialPayment = function (index) {
      $scope.partialPayments.splice(index, 1)
    }

    $scope.saveTransaction = function (payMethod) {
      $scope.getDayState(function (error) {
        if(error) {
          $scope.parkTransaction()
          return
        }
        $scope.paymentMode = true
        if (payMethod === 'pin' && (
          !$rootScope.currentDevice.pinTerminal && (
            (!$rootScope.currentShop.cashRegister.terminal.provider || $rootScope.currentShop.cashRegister.terminal.provider === '') ||
            (!$rootScope.currentDevice.pinTerminal.provider || $rootScope.currentDevice.pinTerminal.provider === '')
          )
        )) {
          dialogs.error(undefined, $language.translate('PLEASE_CHECK_TERMINAL_SETTINGS_IN_SHOP_PREFERENCES'))
          $scope.paymentMode = false
          return
        }

        var totalPrice = $scope.getTotals('price')

        if (payMethod && $scope.payments[payMethod] > 0) {
          var enteredAmount = $scope.payments[payMethod]
          $scope.resetPayments()
          $scope.payments[payMethod] = enteredAmount
        }
        if ($scope.partialPayments && $scope.partialPayments.length > 0) {
          if (!$scope.transaction.receipt) {
            $scope.transaction.receipt = {
              payments: []
            }
          }
          if (!$scope.transaction.receipt.payments) {
            $scope.transaction.receipt.payments = []
          }

          for (var i = 0; i < $scope.partialPayments.length; i++) {
            $scope.transaction.receipt.payments.push($scope.partialPayments[i])
          }
        }

        if ($scope.getTotalEntered() === 0 && !payMethod) {
          if (totalPrice > 0 && $scope.offerMode === false) {
            var dlg = dialogs.confirm($language.translate('CONFIRM'), $language.translate('ARE_YOU_SURE_TO_FULL_PAY_LATER'), { size: 'sm' })
            dlg.result.then(function (btn) {
              if (btn === 'yes') {
                payMethod = 'paylater'
                continueSaveTransaction()
              } else {
                $scope.paymentMode = false
                return
              }
            })
              .catch(function (reason) {
                $scope.paymentMode = false
                return
              })
          } else {
            payMethod = 'paylater'
            continueSaveTransaction()
          }
        } else {
          continueSaveTransaction()
        }

        function continueSaveTransaction () {
          if (payMethod && (!$scope.payments || !$scope.payments[payMethod] || $scope.payments[payMethod] === 0)) {
            var total = totalPrice
            if ($scope.payments.giftcard && $scope.payments.giftcard) {
              total = totalPrice - $scope.payments.giftcard
            } else if ($scope.partialPayments && $scope.partialPayments.length > 0) {
              var totalPartial = _.sumBy($scope.partialPayments, 'amount')
              total = totalPrice - totalPartial
            } else {
              $scope.resetPayments()
            }

            $log.info('we are assuming that the full amount is paid by', payMethod, total)
            $scope.payments[payMethod] = $scope.roundPrice(total)
          }

          if (totalPrice < 0) {
            // Total is negative, so we need to convert all entered prices to negative too (if they are positive)
            for (var payment in $scope.payments) {
              if ($scope.payments[payment] > 0) {
                $scope.payments[payment] = $scope.payments[payment] * -1
              }
            }
          }

          //Make an concept transaction, changed it after payment is confirmed
          $scope.makeTransactionObject(true)
            .then(function (transaction) {
              $api.post('till/transaction/', {
                deviceId: $rootScope.currentDevice._id,
                transaction: angular.copy(transaction),
                payments: $scope.payments,
                giftcards: $scope.externalGiftcards,
                user: $scope.getCurrentUser(),
                newPlatform: $rootScope.currentShop.newPlatform,
              }, null, 2)
                .then(function (response) {
                  var transaction = response.data.transaction
                  toastr.success($language.translate('TRANSACTION_CREATED'))

                  // use payMethod variable to redirect the right page or show pay modal
                  if (payMethod && payMethod === 'pin') {
                    var amount = $scope.payments[payMethod]
                    $scope.startCardPayment(transaction, amount)
                      .then(function (result) {
                        var merchantReceipt = false
                        if(result.cardResult.merchantReceipt) {
                          merchantReceipt = true
                        }
                        if (result.cardResult.partialPayment) {
                          $log.info('partial payment received, updating payments')
                          $api.delete('till/transaction/payment/' + transaction._id + '/1/CARD/1?shopId=' + $rootScope.currentShop._id, null, null, 2)
                            .then(function () {
                              $scope.updateConceptStatus(true, transaction._id)
                                .then(function () {
                                  $log.info('transaction changed to concept')
                                  $scope.getConceptTransactions()
                                  $transaction.getTransaction(transaction._id, false, true, $rootScope.currentShop._id)
                                    .then(function (transaction) {
                                      $scope.openTransaction(transaction)
                                      $scope.paymentMode = false
                                      if ($scope.transaction.receipt && $scope.transaction.receipt.payments && $scope.transaction.receipt.payments.length > 0) {
                                        $scope.transaction.receipt.payments = []
                                      }
                                      $scope.partialPayments.push(
                                        {
                                          method: 'CARD',
                                          cardName: result.cardResult.card,
                                          amount: result.cardResult.paidAmount,
                                        }
                                      )
                                      for (var key in $scope.payments) {
                                        if ($scope.payments[key] > 0 && key !== 'pin') {
                                          $scope.partialPayments.push({
                                            method: key.toUpperCase(),
                                            amount: $scope.payments[key]
                                          })
                                        }
                                      }
                                      $scope.resetPayments()
                                    })
                                    .catch(function (reason) {
                                      $log.error('Error while opening concept transaction', reason)
                                    })
                                })
                                .catch(function (reason) {
                                  $log.error('error while updating concept status', reason)
                                  return
                                })

                            })
                            .catch(function (reason) {
                              $scope.paymentMode = false
                              $log.error('error while removing old card payment from transaction', reason)
                              dialogs.error(undefined, reason.data)
                            })
                        } else {
                          $scope.redirectAfterPayment(transaction._id, merchantReceipt)
                        }
                      })
                      .catch(function (reason) {
                        $scope.paymentMode = false
                        if (reason.success === false) {
                          $log.warn('Payment is failed, redirecting to checkout start page to try again')
                          $scope.updateConceptStatus(true, transaction._id)
                            .then(function () {
                              $log.info('transaction changed to concept')
                              $scope.getConceptTransactions()
                              $transaction.getTransaction(transaction._id, false, true, $rootScope.currentShop._id)
                                .then(function (transaction) {
                                  $scope.openTransaction(transaction)
                                })
                              // $state.go('retailer.checkout.start', {
                              //   transactionId: transaction._id,
                              //   deposit: $stateParams.deposit,
                              //   details: transaction.details,
                              //   payLater: true,
                              //   payLaterAmount: $scope.getTotals('price')
                              // })
                            })
                            .catch(function (reason) {
                              $scope.paymentMode = false
                              return
                            })
                          return
                        } else {
                          dialogs.error(undefined, reason.data ? reason.data : reason)
                          return
                        }
                      })
                  } else {
                    // no payment option provided, so we need to check all the values and probably start a card payment if pin is used
                    if ($scope.payments.pin) {
                      $scope.startCardPayment(transaction, $scope.payments.pin)
                        .then(function (result) {
                          if (result.cardResult.partialPayment) {
                            $log.info('partial payment received, updating payments')
                            $api.delete('till/transaction/payment/' + transaction._id + '/1/CARD/1?shopId=' + $rootScope.currentShop._id, null, null, 2)
                              .then(function () {
                                $scope.updateConceptStatus(true, transaction._id)
                                  .then(function () {
                                    $log.info('transaction changed to concept')
                                    $scope.getConceptTransactions()
                                    $transaction.getTransaction(transaction._id, false, true, $rootScope.currentShop._id)
                                      .then(function (transaction) {
                                        $scope.openTransaction(transaction)
                                        $scope.paymentMode = false
                                        if ($scope.transaction.receipt && $scope.transaction.receipt.payments && $scope.transaction.receipt.payments.length > 0) {
                                          $scope.transaction.receipt.payments = []
                                        }
                                        $scope.partialPayments.push(
                                          {
                                            method: 'CARD',
                                            cardName: result.cardResult.card,
                                            amount: result.cardResult.paidAmount,
                                          }
                                        )
                                        for (var key in $scope.payments) {
                                          if ($scope.payments[key] > 0 && key !== 'pin') {
                                            $scope.partialPayments.push({
                                              method: key.toUpperCase(),
                                              amount: $scope.payments[key]
                                            })
                                          }
                                        }
                                        $scope.resetPayments()
                                      })
                                      .catch(function (reason) {
                                        $log.error('Error while opening concept transaction', reason)
                                      })
                                  })
                              })
                              .catch(function (reason) {
                                $scope.paymentMode = false
                                $log.error('error while removing old card payment from transaction', reason)
                                dialogs.error(undefined, reason.data)
                              })
                          } else {
                            $scope.lastPaymentCheck(transaction)
                          }
                        })
                        .catch(function (reason) {
                          $scope.paymentMode = false
                          if (reason.success === false) {
                            $log.warn('card payment not confirmed')
                          } else {
                            $log.error('Error while confirming cardpayment', reason)
                            dialogs.error(undefined, reason.data)
                          }
                          $scope.updateConceptStatus(true, transaction._id)
                            .then(function () {
                              $scope.deleteCashPayment(transaction._id)
                              $log.info('transaction changed to concept')
                              $scope.getConceptTransactions()
                              $transaction.getTransaction(transaction._id, false, true, $rootScope.currentShop._id)
                                .then(function (transaction) {
                                  $scope.openTransaction(transaction)
                                })
                                .catch(function (reason) {
                                  $log.error('Error while opening concept transaction', reason)
                                })
                            })
                            .catch(function (reason) {
                              $log.error('error while updating concept status', reason)
                              return
                            })
                        })
                    } else {
                      $scope.lastPaymentCheck(transaction)
                    }
                  }
                })
                .catch(function (reason) {
                  $log.error('Error while creating transaction', reason)
                  $scope.paymentMode = false
                  var translations = $translate.instant(['ERROR_WHILE_REDEEMING_EXTERNAL_GIFTCARDS', 'GIFTCARD_WAS_NOT_ACCEPTED', 'REASON'])
                  var messages = [translations.ERROR_WHILE_REDEEMING_EXTERNAL_GIFTCARDS]
                  messages.push(reason.data.map(function (e) {
                    return translations.GIFTCARD_WAS_NOT_ACCEPTED +
                      '. ' +
                      e.card.type +
                      ' ' +
                      e.card.cardNumber +
                      ' (' +
                      e.card.amount +
                      '). ' +
                      translations.REASON +
                      ': ' +
                      e.error
                  }))
                  dialogs.error(undefined, messages.join('. '))
                })
            })
            .catch(function (reason) {
              $log.error('Error while preparing transaction for saving', reason)
            })
        }
      })
    }

    $scope.checkAndAskPaymentConfirmation = function (transactionId, pinFailed) {
      return $q(function (resolve, reject) {
        if (!$rootScope.currentShop.cashRegister.confirmPayments || (!$scope.payments || !$scope.payments.cash || $scope.payments.cash === 0)) {
          return $scope.updateConceptStatus(false, transactionId)
            .then(function () {
              return resolve()
            })
            .catch(function (reason) {
              $scope.paymentMode = false
              return reject(reason)
            })
        }
        if ($scope.payments.cash && $scope.payments.pin && !pinFailed) {
          $scope.updateConceptStatus(false, transactionId)
            .then(function () {
              return resolve()
            })
            .catch(function (reason) {
              $scope.paymentMode = false
              return reject(reason)
            })
        } else {
          var dlg = dialogs.confirm($language.translate('CONFIRM'), $language.translate('IS_THE_PAYMENT_COMPLETED'), { size: 'sm' })
          dlg.result.then(function (btn) {
            if (btn === 'yes') {
              $scope.updateConceptStatus(false, transactionId)
                .then(function () {
                  return resolve()
                })
                .catch(function (reason) {
                  $scope.paymentMode = false
                  return reject(reason)
                })
            }
          })
            .catch(function (reason) {
              $scope.paymentMode = false
              $log.error(reason === 'no' ? 'Payment not confirmed' : reason)
              $scope.deleteCashPayment(transactionId)
                .then(function() {
                  $scope.getConceptTransactions()
                  $transaction.getTransaction(transactionId, false, true, $rootScope.currentShop._id)
                    .then(function (transaction) {
                      $scope.openTransaction(transaction)
                    })
                    .catch(function (reason) {
                      $log.error('Error while opening concept transaction', reason)
                    })
                })
                .catch(function (reason){
                  $log.error('Error while deleting payment because payment was failed:', reason)
                })
              return reject(reason)
            })
        }
      })
    }

    $scope.deleteCashPayment = function (transactionId) {
      return $q(function (resolve, reject) {
        $api.delete('till/transaction/payment/' + transactionId + '/1/cash/1?shopId=' + $rootScope.currentShop._id, null, null, 2)
          .then(function () {
            return resolve()
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    $scope.updateConceptStatus = function (status, transactionId) {
      return $q(function (resolve, reject) {
        $api.post('till/transaction/concept', {
          transactionId: transactionId,
          status: status,
          shopId: $rootScope.currentShop._id,
          user: $scope.getCurrentUser()
        }, null, 2)
          .then(function (response) {
            return resolve()
          })
          .catch(function (reason) {
            $log.error("error while updating transaction concept status", reason)
            return reject(reason.data)
          })
      })
    }

    $scope.lastPaymentCheck = function (transaction) {

      var totalPrice = $scope.getTotals('price')
      var totalEntered = $scope.getTotalEntered()
      var restPayment = totalPrice - totalEntered
      var transactionId = transaction._id

      var restPaymentToPositiveVal = Math.abs(restPayment)
      var restPaymentIsLessThanACent = (restPaymentToPositiveVal > 0 && restPaymentToPositiveVal < 1) ? Math.floor(Math.log10(restPaymentToPositiveVal)) < -2 : false

      if (totalEntered == -0 && totalPrice == 0 && (restPayment == 0 || restPaymentIsLessThanACent == true)) {
        $scope.redirectAfterPayment(transactionId)
      } else if ( totalEntered === totalPrice || restPayment < 0) {
        $scope.checkAndAskPaymentConfirmation(transactionId)
          .then(function () {
            $scope.redirectAfterPayment(transactionId)
          })
          .catch(function (reason) {
            if (reason !== 'no' && reason !== 'backdrop click') {
              dialogs.error(undefined, $language.translate(reason))
            }
          })
      } else {
        // partial payment, fill the rest with payLater
        $scope.checkAndAskPaymentConfirmation(transactionId)
          .then(function () {
            $api.post('till/transaction/payment', {
              transactionId: transactionId,
              method: 'payLater',
              paidAmount: restPayment,
              user: $scope.getCurrentUser(),
              shopId: $rootScope.currentShop._id
            }, null, 2)
              .then(function (response) {
                $scope.redirectAfterPayment(transactionId)
              })
              .catch(function (reason) {
                $log.error('Error while creating transaction', reason)
                dialogs.error(undefined, reason.data)
              })
          })
          .catch(function (reason) {
            if (reason !== 'no' && reason !== 'backdrop click') {
              dialogs.error(undefined, $language.translate(reason))
            }
          })
      }
    }

    $scope.redirectAfterPayment = function (id, merchantReceipt) {
      var payLater = $stateParams.payLater && typeof $stateParams.payLater === 'boolean'
      $state.go('retailer.checkout.completed', { transactionId: id, paymentStatus: null, printReceipt: true, payLater: payLater, merchantReceipt: merchantReceipt })
    }

    $scope.resetPayments = function () {
      delete $scope.payments.pin
      delete $scope.payments.cash
      delete $scope.payments.bankAmount
      delete $scope.payments.insurance
      $scope.giftcardNumber = null
      if (!$scope.payments.pin) {
        $scope.payments = { pin: null }
      }
      if (!$scope.payments.cash) {
        $scope.payments = { cash: null }
      }
      if (!$scope.payments.bankAmount) {
        $scope.payments = { bankAmount: null }
      }
      if (!$scope.payments.insurance) {
        $scope.payments = { insurance: null }
      }
    }

    $scope.addGiftcard = function () {
      $giftcard.openExternalGiftcardsModal($scope.getTotals('price'))
        .then(function (result) {
          $scope.externalGiftcards.push(result)
          $scope.payments.giftcard = _.sumBy($scope.externalGiftcards, function (g) { return g.amount })
        })
        .catch(function (reason) {
          if (reason !== 'dismissed') {
            $log.error('Error while adding giftcard', reason)
            dialogs.error(undefined, reason)
          }
        })
    }

    $scope.removeExternalGiftcard = function (idx) {
      $scope.externalGiftcards.splice(idx, 1)
    }

    $scope.fieldsDisabled = function (extended, paylater) {
      var disabled = !$scope.transaction.details || $scope.transaction.details.length === 0 || $scope.offerMode || (extended && $scope.getTotals('price') === 0)
      if ($scope.transaction.details.length === 1 && $scope.transaction.details[0].type === 'repair' && ($scope.transaction.details[0].payLater === false && paylater === false)) {
        return true
      }

      if (disabled && extended) {
        if (!$scope.payments.pin) {
          $scope.payments = { pin: null }
        } else {
          $scope.payments.pin = null
        }
        $scope.payments.giftcard = null
        $scope.payments.bankAmount = null
        $scope.payments.insurance = null
      }
      if (!disabled && paylater) {
        return $stateParams.checkout
      } else {
        return disabled
      }
    }

    $scope.calculatePointsValue = function (points) {
      if (!points || !$rootScope.currentShop) { return 0 }
      var discountPerPoint = $rootScope.currentShop.points.discountPerPoint
      return discountPerPoint * points
    }

    $scope.calculateMaxPoints = function () {
      var totalPrice = $scope.getTotals('price')
      var discountPerPoint = $rootScope.currentShop.points.discountPerPoint

      var maxPoints = Math.round((totalPrice / discountPerPoint) * 100) / 100

      if(maxPoints > $scope.customer.points.spendable) {
        maxPoints = $scope.customer.points.spendable
      }

      return maxPoints
    }


    $scope.redeem = function () {
      if (!$scope.transaction.redeemPoints || $scope.transaction.redeemPoints === 0) {
        return
      }

      var articleVat = DEFAULT_TAX

      if ($scope.transaction.details && $scope.transaction.details.length > 0) {
        //Determine the right tax value for the points (always get the highest value on the transaction)
        var taxes = _.sortBy($scope.transaction.details.map(function (d) {
          return { vat: d.priceVat }
        }), 'vat')

        if (taxes && taxes.length > 0) {
          articleVat = taxes[0].vat
        }
      }
      var productPrice = Math.round(($scope.calculatePointsValue($scope.transaction.redeemPoints) * -1) * 100) / 100
      var totalPrice = $scope.getTotals('price')

      var priceCheck = _.inRange((productPrice *-1), (totalPrice - 1), (totalPrice + 1))

      if((productPrice * -1) > totalPrice && priceCheck === false) {
        toastr.error($language.translate('DISCOUNT_CANT_BE_HIGHER_THAN_PRODUCTPRICE'))
        return
      }
      var article = {
        name: $language.translate('REDEEM_POINTS'),
        price: productPrice,
        quantity: 1,
        discount: false,
        discountValue: 0,
        priceVat: articleVat,
        total: productPrice,
        type: 'points',
        comment: $scope.transaction.redeemPoints + ' ' + $language.translate('POINTS_REDEEMED')
      }
      $scope.addItemToTransaction(article)

      $scope.customer.points.spendable -= $scope.transaction.redeemPoints
      $scope.transaction.pointsRedeemded = $scope.transaction.redeemPoints
      $scope.transaction.redeemPoints = 0
      $scope.pointsAdded = true
    }

    $scope.redeemAllPoints = function () {
      $scope.transaction.redeemPoints = $scope.calculateMaxPoints()
      $scope.redeem()
    }

    $scope.checkPointsEndDate = function () {
      if ($rootScope.currentShop.points && $rootScope.currentShop.points.endDate) {
        if (moment($rootScope.currentShop.points.endDate).isBefore(moment().startOf('day'))) {
          $scope.pointsEnabled = false
        }
      }
    }

    $scope.getTotalEntered = function () {
      var result = 0

      if($scope.partialPayments && $scope.partialPayments.length > 0) {
        var partialPayments = _.sumBy($scope.partialPayments, 'amount')
        result += partialPayments
      }

      if ($scope.payments && Object.keys($scope.payments).length > 0) {
        if ($scope.payments.pin && $scope.payments.pin > 0) {
          result += $scope.payments.pin
        }
        if ($scope.payments.cash && $scope.payments.cash > 0) {
          result += $scope.payments.cash
        }
        if ($scope.payments.giftcard && $scope.payments.giftcard > 0) {
          result += $scope.payments.giftcard
        } else {
          if ($scope.externalGiftcards && $scope.externalGiftcards.length > 0) {
            var giftcards = _.sumBy($scope.externalGiftcards, function (g) { return g.amount })
            result += giftcards
          }
        }
        if ($scope.payments.bankAmount && $scope.payments.bankAmount > 0) {
          result += $scope.payments.bankAmount
        }
        if ($scope.payments.insurance && $scope.payments.insurance > 0) {
          result += $scope.payments.insurance
        }

        return result * ($scope.getTotals('price') > 0 ? 1 : -1)
      } else {
        return result
      }
    }

    $scope.checkCompleteButton = function () {
      // return true for advance payment
      if ($scope.offerMode) {
        return false
      }
      var total = $scope.getTotals('price')
      var totalEntered = $scope.getTotalEntered()
      return total > 0 && (totalEntered < total || totalEntered === 0)
    }

    $scope.showQuickButtonAlert = true

    $scope.checkQuickButtonAlert = function () {
      var storage = localStorage.getItem('tillQuickButtonAlert')
      if (storage === 'false') {
        $scope.showQuickButtonAlert = false
      }
    }
    $scope.checkQuickButtonAlert()

    $scope.closeQuickButtonAlert = function () {
      localStorage.setItem('tillQuickButtonAlert', false)
      $scope.showQuickButtonAlert = false
    }

    $scope.checkQuickButton = function (button) {
      if ($rootScope.currentShop && $rootScope.currentShop.cashRegister.quickButtons && $rootScope.currentShop.cashRegister.quickButtons.controls && $rootScope.currentShop.cashRegister.quickButtons.controls.length > 0) {
        return $rootScope.currentShop.cashRegister.quickButtons.controls[button] && Object.keys($rootScope.currentShop.cashRegister.quickButtons.controls[button]).length > 1
      }
    }

    $scope.makeQuickButtonText = function (text) {
      if(text.length > 23) {
        return text.substring(0, 23)
      } else {
        return text
      }
    }

    $scope.addQuickProduct = function (button) {
      var buttonData = $rootScope.currentShop.cashRegister.quickButtons.controls[button]
      if (buttonData.shopProduct) {
        $scope.addProduct({ _id: buttonData.shopProduct}, null, buttonData.price, false)
        return
      }
      var article = {
        name: buttonData.name,
        price: buttonData.price,
        quantity: 1,
        discount: false,
        discountPercent: 0,
        discountValue: 0,
        photo: null,
        priceVat: $scope.defaultTax,
        total: 0,
        productNumber: null,
        purchasePrice: 0,
        ean: null,
        isStockProduct: false,
        productId: button.shopProduct ? button.shopProduct : null,
        stock: null,
        hasStock: false,
        comment: '',
        supplier: null,
        estimatedDateOpen: false,
        estimatedDate: null,
        type: 'product',
        repairPayment: false,
        specialPayment: false,
        status: null
      }

      $scope.addItemToTransaction(article, 'quickButton')
      $scope.toggleItem($scope.transaction.details.length - 1)
      $scope.clearProductsSearch()
    }

    $scope.addGoldPurchase = function () {
      var article = {
        name: $language.translate('GOLD_PURCHASE'),
        price: 0,
        quantity: 1,
        discount: false,
        discountPercent: 0,
        discountValue: 0,
        photo: null,
        priceVat: 0,
        total: 0,
        productNumber: null,
        purchasePrice: 0,
        ean: null,
        isStockProduct: false,
        productId: null,
        stock: null,
        hasStock: false,
        comment: '',
        supplier: null,
        estimatedDateOpen: false,
        estimatedDate: null,
        type: 'gold-purchase',
        repairPayment: false,
        specialPayment: false,
        status: null,
        isExchange: $rootScope.currentShop.financial.taxGold
      }

      $scope.addItemToTransaction(article)
      $scope.toggleItem($scope.transaction.details.length - 1)
      $scope.clearProductsSearch()
    }

    $scope.addGoldSell = function () {
      var article = {
        name: $language.translate('GOLD_SELL'),
        price: 0,
        quantity: 1,
        discount: false,
        discountPercent: 0,
        discountValue: 0,
        photo: null,
        priceVat: 0,
        total: 0,
        productNumber: null,
        purchasePrice: 0,
        ean: null,
        isStockProduct: false,
        productId: null,
        stock: null,
        hasStock: false,
        comment: '',
        supplier: null,
        estimatedDateOpen: false,
        estimatedDate: null,
        type: 'gold-sell',
        repairPayment: false,
        specialPayment: false,
        status: null,
        storageFactor: 1.25,
        showMarginAndPurchase: true,
      }

      $scope.addItemToTransaction(article)
      $scope.toggleItem($scope.transaction.details.length - 1)
      $scope.clearProductsSearch()
    }

    $scope.savePaymentButtonEnabled = function () {
      //return false to enable
      if ($scope.fieldsDisabled() && !$scope.offerMode) {
        //Disable when other fields/buttons are also disabled
        return true
      }
      if (!$scope.transaction.details || $scope.transaction.details.length === 0) {
        // No items entered
        return true
      }

      if($scope.types.totalUnique > 1 && $scope.getTotalEntered() > 0 && $scope.getTotalEntered() < $scope.productsTotal) {
        return true
      }

      if ($scope.paymentMode) {
        //Button is clicked
        return true
      }

      if ($scope.getTotals('price') < 0 && $scope.getTotalEntered() !== $scope.getTotals('price') && !$scope.offerMode){
        // Disable when total price is lower then zero (refund) and total entered amount is not equal to that price
        return true
      }
    }

    $scope.openCashDrawer = function () {
      if($rootScope.currentShop.newPrinter === true) {
        $thermalPrint.openDrawer(true)
          .then(function () {
            toastr.success($language.translate('CASH_DRAWER_OPENEND'))
          })
          .catch(function (reason) {
            $log.error('Error while opening cash drawer', reason)
            toastr.error(reason)
          })
      } else {
        $printer.openCashDrawer()
          .then(function () {
            toastr.success($language.translate('CASH_DRAWER_OPENEND'))
          })
          .catch(function (reason) {
            $log.error('Error while opening cash drawer', reason)
            toastr.error($language.translate('ERROR_WHILE_OPENING_DRAWER'))
          })
      }
    }

    $scope.openCustomer = function(id) {
      var url = window.location.origin + '/retailer/customer/' + id
      var win = window.open(url)
      win.focus()
    }

    $scope.openRepair = function(id) {
      var url = window.location.origin + '/retailer/repair/' + id
      var win = window.open(url)
      win.focus()
    }

    $scope.addTextLine = function (empty) {
      $scope.addItemToTransaction({
        type: empty ? 'empty' : 'text',
        name: null,
        price: null,
        total: null,
        comment: null,
        quantity: null
      })
      $scope.toggleItem($scope.transaction.details.length - 1)
    }


    // #region Get, Search and delete brands functions
    $scope.getShopBrands = function () {
      if ($scope.shopBrands && $scope.shopBrands.length > 0) {
        return
      }
      $scope.shopBrands = []
      $shop.getBrandsByShop($rootScope.currentShop.nameSlug)
        .then(function (brands) {
          $scope.shopBrands = brands.brands
        })
        .catch(function (error) {
          $log.error('Error while getting shopbrands', error)
        })
    }

    $scope.getShopBrandsPromise = function () {
      return $q(function (resolve, reject) {
        if ($scope.shopBrands && $scope.shopBrands.length > 1) {
          return resolve($scope.shopBrands)
        }
        $shop.getBrandsByShop($rootScope.currentShop.nameSlug)
          .then(function (brands) {
            $scope.shopBrands = brands.brands
            return resolve($scope.shopBrands)
          })
          .catch(function (error) {
            $log.error('Error while getting shopbrands in getShopBrandsPromise', error)
            return reject(error)
          })


      })
    }

    $scope.searchBrands = function (str) {
      var matches = []
      $scope.shopBrands.forEach(function (item) {
        if (
          item._id &&
          Object.keys(item._id).length > 0 &&
          (
            item._id[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0
          ) ||
          (
            item._id.nameSlug &&
            item._id.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0
          )
        ) {
          matches.push(item)
        }
      })

      return matches
    }
    $scope.removeBrand = function (index) {
      $scope.transaction.details[index].brand = null
    }
    $scope.selectedBrand = function (selected) {
      if (selected) {
        if (selected.originalObject) {
          selected = selected.originalObject
        }
        $scope.transaction.details[$scope.currentIdx].brand = {
          _id: selected._id,
          name: selected.name || selected._id[$rootScope.language].name
        }
        $scope.transaction.details[$scope.currentIdx].company = null
        if (
          selected.favoriteSupplier &&
          selected.favoriteSupplier._id &&
          selected.favoriteSupplier._id._id
        ) {
          $scope.selectedCompany(selected.favoriteSupplier._id)
        }
      }
    }
    // #endregion Get, Search and delete brands functions

    // #region Get, Search and delete companies functions
    $scope.selectedCompany = function (selected) {
      if (selected) {
        if (selected.originalObject) {
          selected = selected.originalObject
        }
        $scope.transaction.details[$scope.currentIdx].company = {
          _id: selected._id,
          name: selected.name
        }
      }
    }
    $scope.selectedRepairer = function (selected) {
      if (selected) {
        if (selected.originalObject) {
          selected = selected.originalObject
        }
        $scope.transaction.details[$scope.currentIdx].repairer = {
          _id: selected._id,
          name: selected.name
        }
      }
    }
    $scope.removeCompany = function (index) {
      $scope.transaction.details[index].company = null
    }
    $scope.removeRepairer = function (index) {
      $scope.transaction.details[index].repairer = null
    }
    $scope.getCompanies = function () {
      if ($scope.companies && $scope.companies.length > 0) {
        return
      }
      $scope.companies = []
      $shop.getOwnShopCompanies($rootScope.currentShop._id, true)
        .then(function (companies) {
          $scope.companies = companies
        })
        .catch(function (error) {
          $log.error('Error! While getting Shop Companies')
          $log.error(error)
        })
    }

    $scope.getCompaniesPromise = function () {
      return $q(function (resolve, reject) {
        if ($scope.companies && $scope.companies.length > 1) {
          return resolve($scope.companies)
        }
        $shop.getOwnShopCompanies($rootScope.currentShop._id)
          .then(function (companies) {
            $scope.companies = companies
            return resolve($scope.companies)
          })
          .catch(function (error) {
            $log.error('Error when getting shop companies in getCompaniesPromise', error)
            return reject(error)
          })
      })
    }
    // #endregion Get, Search and delete companies functions

    // #region Get, Search and delete employees functions

    $scope.searchEmployees = function (str) {
      var matches = []
      $scope.employees.forEach(function (item) {
        if(item) {
          var name = (item.firstName ? item.firstName + ' ' : '') + (item.lastNamePrefix ? item.lastNamePrefix + ' ' : '') + (item.lastName ? item.lastName : '')
          item.name = name
          if (name && name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) {
            matches.push(item)
          }
        }
      })
      return matches
    }

    $scope.removeEmployee = function (index) {
      $scope.transaction.details[index].employee = null
    }

    $scope.selectedEmployee = function (selected) {
      var originalSelected = angular.copy(selected)
      if (selected) {
        if (selected.originalObject) {
          selected = angular.copy(selected.originalObject)
        }
        var name = originalSelected.title ? originalSelected.title : (selected.firstName ? selected.firstName + ' ' : '') + (selected.lastNamePrefix ? selected.lastNamePrefix + ' ' : '') + (selected.lastName ? selected.lastName : '')
        $scope.transaction.details[$scope.currentIdx].employee = {
          _id: selected._id,
          name: name
        }
      }
    }
    // #endregion Get, Search and delete employees functions

    $scope.openDatePickerForDetail = function (index, type) {
      if (!$scope.transaction.details[index][type]) {
        $scope.transaction.details[index][type] = false
      }
      $scope.transaction.details[index][type] = !$scope.transaction.details[index][type]
    }

    $scope.clearEstimatedDate = function (index) {
      $scope.transaction.details[index].estimatedDate = null
    }

    $scope.changeItemStatus = function (status, index) {
      $scope.transaction.details[index].status = status
    }

    $scope.openPhotoUploadModal = function (index) {
      $transaction.openUploadFilesModal($scope.transaction.details[index], false)
        .then(function (files) {
          $scope.transaction.details[index].photos = $scope.transaction.details[index].photos.concat(files)
        })
        .catch(function (reason){
          $log.error('error in uploadFileModal', reason)
        })
    }

    // #endregion General scope functions

    // #region Barcode support

    $scope.$on('scanner-started', function () {
      $scope.barcode = true
    })

    $scope.$on('scanner-ready', function (event, args) {
      if (args.type === 'other') {
        $scope.searchProducts(args.parsedCode, 'stock', true)
        $scope.barcode = false
      }
      if (args.type === 'transaction' || args.type === 'repair' || args.type === 'giftcard') {

        if(args.type === 'giftcard' && $scope.giftcardModalOpened === true) {
          $scope.barcode = false
          return
        } else {
          $scope.getTransaction(args.parsedCode, false, true)
          .then( function () {
            $scope.barcode = false
          })
          .catch(function () {
            $scope.barcode = false
          })
        }
      }
    })
    // #endregion Barcode support

    $scope.changePage = function (position, searchCurrentPage) {
      $scope.searchCurrentPage = searchCurrentPage;
      $scope.orderSearchNewApproach($scope.localdata.productSearch, 'order', undefined)
  }

    $scope.changeShopProductPage = function (position, searchCurrentShopProductPage) {
      $scope.searchCurrentShopProductPage = searchCurrentShopProductPage;
      $scope.orderSearchNewApproach($scope.localdata.productSearch, 'order', undefined)
    }

    function findFromBusinessProducts() {

      if (!$rootScope.currentShop.newPlatform || !$rootScope.currentShop.newPlatform.iBusinessId || //!$rootScope.currentShop.newPlatform.sOrganization
        !$rootScope.currentShop.newPlatform.iOrganizationId) {
          console.error('Improper data');
          return;
        }
      var body = {
        iBusinessId: $rootScope.currentShop.newPlatform.iBusinessId,
        iOrganizationId: $rootScope.currentShop.newPlatform.iOrganizationId,
        shopId: $rootScope.currentShop._id,
        skip: 0,
        limit: 10,
        sortBy: '',
        sortOrder: '',
        searchValue: $scope.localdata.productSearch,
        oFilterBy: {
          oStatic: {},
          oDynamic: {}
        }
      }
      $shop.getBusinessProducts(body).then(function (result) {
        if (result && result.data && result.data.data && result.data.data.length) {
          $scope.products = result.data.data;
        }
      }).catch(function (reason) {
        console.error('getBusinessProducts reason: ', reason);
      })
    }

    $scope.tillSearch = function (keyEvent, productSearch, searchMode, barcode) {
      $scope.localdata.searchCurrentPage = 1;
      $scope.localdata.searchCurrentShopProductPage = 1;
      $scope.shopProductsData = [];
      $scope.productsdata = [];
      if (keyEvent.target && keyEvent.target.value) {
        $scope.localdata.productSearch = (keyEvent.target.value).toUpperCase();
      }
      if ($scope.isNewPlatform && keyEvent.which === 13) {
        findFromBusinessProducts();
      } else if (keyEvent.which === 13) {
        $scope.searchquery = keyEvent.target.value;
        $scope.searchProducts(productSearch, searchMode, barcode)
      }
    }

    $scope.getCustomerDiscount = function () {
      if($scope.customer && $scope.customer.discount && $scope.customer.discount.value > 0) {
        if($scope.customer.discount.percent === false) {
          return '€ ' + $scope.customer.discount.value
        } else {
          return $scope.customer.discount.value + '%'
        }
      } else {
        return ''
      }
    }

    $scope.addCustomerDiscount = function () {
      if(!$scope.customer || !$scope.customer.discount || !$scope.customer.discount.value) {
        return
      }
      for(var i =0; i < $scope.transaction.details.length; i++) {
        var detail = $scope.transaction.details[i]
        if(detail.type === 'product' || detail.type === 'pool' || detail.type === 'collection') {
          detail.customDiscount = true
          detail.txtDiscountValue = $scope.customer.discount.value
          $scope.addDiscount($scope.customer.discount.value, $scope.customer.discount.percent, i)
        }
      }
    }

    $scope.checkRepairTitle = function(detail) {
      var title = detail.name

      title = title.replace(' ', '')

      if(!title || title === '' || title.length === 0) {
        detail.name = $language.translate('REPAIR')
      }
    }

    $scope.closeDayStateAlert = function () {
      $scope.dayStateWarning = null
      localStorage.setItem('hideDayStateWarning', Date.now())
    }

    $scope.closeContactWarning = function () {
      $scope.showContactWarning = false
    }

  }])
