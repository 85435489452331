prismanoteApp.controller('wholesalerController', ['$scope', '$state', '$rootScope', '$wholesaler', '$location', 'campaignFact',
  function ($scope, $state, $rootScope, $wholesaler, $location, campaignFact) {
    $rootScope.wholesalerSaved = false

    $scope.closeAlert = function () {
      $rootScope.wholesalerSaved = false
    }

    $scope.getWholesaler = function (nameSlug) {
      $rootScope.selectedWholesaler = nameSlug

      $wholesaler.getWholesaler(nameSlug)

        .then(function (shop) {
          $scope.$broadcast('changeSelectedWholesaler', nameSlug)
          $rootScope.currentWholesaler = shop
          campaignFact.currentWholesaler = shop
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }
    if ($rootScope.user && $rootScope.user.role == 'wholesaler') {
      $scope.selectedShop = $rootScope.user.companies[0].nameSlug

      if ($rootScope.user.companies[0]) {
        $scope.getWholesaler($rootScope.user.companies[0].nameSlug, { event: false })
      }
    }
  }])
