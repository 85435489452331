prismanoteApp.controller('chooseShopModalController', ['$scope', '$uibModalInstance', 'shops',
  function ($scope, $uibModalInstance, shops) {
    $scope.shops = shops
    console.log('CHOOSE SHOPS: ', $scope.shops)

    $scope.chooseShop = function (shop) {
      console.log('shop: ', shop)
      $uibModalInstance.close(shop)
    }
  }
])
