prismanoteApp.controller('adminProductStartingDigitUpdateController', ['$scope', '$rootScope', '$api', 'Upload', '$q', '$timeout',
  function ($scope, $rootScope, $api, Upload, $q, $timeout) {
    $scope.step = []
    $scope.step[1] = true
    $scope.percent = 0

    $scope.back = function (step) {
      goToStep(step)
    }

    function goToStep (step) {
      $scope.alert = null

      angular.forEach($scope.step, function (value, key) {
        $scope.step[key] = false
      })

      $scope.step[step] = true

      if (step == 1) {
        $scope.percent = 0
      } else if (step == 2) {
        $scope.percent = 33
      } else if (step == 3) {
        $scope.percent = 66
      } else if (step == 4) {
        $scope.percent = 100
      }
    }

    $scope.completeStep1 = function () {
      $scope.loading = true
      uploadFile({
        importFile: $scope.importFile,
        delimiter: $scope.delimiter
      }, 'api/import/product-number-starting-digit-update')
        .then(function (response) {
          console.log('=====parsing done=======', response)
        })
        .catch(function (reason) {
          console.log(reason)
          $scope.alert = {
            type: 'danger',
            msg: 'upload error: ' + reason.data.message
          }
        })
    }

    function uploadFile (data, url) {
      console.log('==>', data)
      return $q(function (resolve, reject) {
        Upload.upload({
          url: url,
          data: {
            delimiter: data.delimiter,
            cat: data.category,
            file: data.importFile,
            product: data.product ? data.product : null,
            column: data.column ? data.column : null,
            customData: data.customData ? data.customData : null,
            lines: data.lines ? data.lines : null
          }
        })
          .then(function (res) {
            console.log('in response from fun', res)
            $timeout(function () {
              resolve(res)
            })
          }, function (res) {
          // wanneer uploaden mislukt
            console.error('Error: ', res.status)
            reject(res)
          }, function (evt) {

          })
      })
    }
  }])
