prismanoteApp.controller('adminCompanyModalController', ['$scope', '$uibModalInstance', 'company', '$api',
  function ($scope, $uibModalInstance, company, $api) {
    $scope.company = company
    $scope.space = '  '
    $scope.deleteCompanyFromShop = function (company) {
      $api.delete('company/delete-suggestion?companyId=' + company._id)
        .then(function (response) {
          $scope.cancel()
        })
        .catch(function (reason) {
          console.error('Error while deleting company', reason)
        })
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel')
    }

    $scope.close = function () {
      $uibModalInstance.close($scope.company)
    }

    $scope.saveProduct = function () {
      $scope.close()
    }

    $scope.deleteBrand = function (index) {
      $scope.company.brands.splice(index, 1)
    }
  }])
