prismanoteApp.controller('adminProductCollectionsController', ['$scope', '$rootScope', '$api', '$stateParams', '$uibModal', '$state', 'prompt', 'Upload', '$http', 'FileSaver',
  function ($scope, $rootScope, $api, $stateParams, $uibModal, $state, prompt, Upload, $http, FileSaver) {
    $scope.loading = false
    $scope.isVerified = 'all'

    // Verification Filter
    $scope.verificationFilter = function (x) {
      return ($scope.isVerified == 'all') ? true : x.isVerified == ($scope.isVerified === 'true')
    }

    $scope.getCollections = function () {
      $scope.loading = true
      $api.get('collections')

        .then(function (response) {
          $scope.collections = response.data.collections
          $scope.loading = false
        })

        .catch(function (reason) {
          $scope.loading = false
          console.log(reason)
        })
    }

    $scope.closeAlert = function () {
      $scope.alert = null
    }

    $scope.getCollection = function () {
      var filter = { _id: $stateParams.id }
      $api.get('collections/', { filter: filter })

        .then(function (response) {
          $scope.collection = response.data.collections[0]
          if (!$scope.collection.isVerified) {
            $scope.alert = {
              type: 'warning',
              msg: 'Deze collectie is nog niet goedgekeurd'
            }
          }
        })

        .catch(function (reason) {
          console.log(reason)
        })
    }

    $scope.searchBrands = function (str) {
      var matches = []

      $scope.brands.forEach(function (item) {
        if (
          (item.name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
					(item.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
          matches.push(item)
        }
      })

      return matches
    }

    $scope.brandSelected = function (selected) {
      $scope.selectedBrand = selected.title
      $scope.brandSlug = selected.originalObject.nameSlug
    }

    $scope.addCategory = function (cat) {
      $scope.collection.categories.push(cat)
    }

    $scope.deleteCategory = function (index) {
      $scope.collection.categories.splice(index, 1)
    }

    $scope.cancel = function () {
      $state.go('admin.product-collections')
    }

    $scope.saveCollection = function () {
      console.log('collection to be save', $scope.collection)
      $api.put('collections', { collection: $scope.collection })
        .then(function (response) {
          $state.go('admin.product-collections')
        })
        .catch(function (reason) {
          console.log(reason)
          $scope.alert = {
            type: 'danger',
            msg: reason
          }
        })
    }

    $scope.deleteCollection = function () {
      $api.delete('delete-collection/' + $scope.collection._id)

      $state.go('admin.product-collections')
    }

    // need to do changes here for collection's variant base

    $scope.myFunction = function () {
      document.getElementById('myDropdown').classList.toggle('show')
    }

    $scope.filterFunction = function () {
      var input, filter, ul, li, a, i
      input = document.getElementById('myInput')
      filter = input.value.toUpperCase()
      div = document.getElementById('myDropdown')
      a = div.getElementsByTagName('a')
      for (i = 0; i < a.length; i++) {
        if (a[i].innerHTML.toUpperCase().indexOf(filter) > -1) {
          a[i].style.display = ''
        } else {
          a[i].style.display = 'none'
        }
      }
    }
    // predefine variant base options
    $scope.variantBaseOptions = [
      'product.diamonds',
      'product.totalCaratWeight',
      'product.size',
      'product.weight',
      'Case.size',
      'Case.shape',
      'Case.depth',
      'Case.material',
      'Case.color',
      'Case.glassMaterial',
      'Dial.color',
      'Dial.pattern',
      'Dial.print',
      'Dial.index',
      'Strap.model',
      'Strap.width',
      'Strap.material',
      'Strap.color',
      'Strap.print',
      'Strap.studs',
      'Strap.clasp',
      'Strap.wristPermimeter',
      'Jewel.material',
      'Jewel.color',
      'Jewel.type',
      'Jewel.height',
      'Jewel.width',
      'Jewel.depth',
      'Jewel.diameter',
      'Jewel.weight',
      'Jewel.chain',
      'Jewel.clasp',
      'Jewel.shape',
      'Jewel.gloss',
      'Watch.type',
      'Watch.indication',
      'Watch.hasSwissMovement',
      'Watch.hasDateFunction',
      'Watch.waterproofLevel',
      'Watch.isNickelFree',
      'Watch.isAntiAllergy',
      'Watch.hasLightFunction',
      'Watch.isSmartwatch',
      'Watch.smartWatchFunctions'
    ]// default values for variant base

    $scope.addToVariantBaseArray = function (item) {
      if ($scope.collection.variantBase) {
        // do nothing
      } else {
        $scope.collection.variantBase = []
      }

      if ($scope.collection.variantBase.length < 2) {
        // check if value already in the array
        if (!checkIfValueAlreadyInArray($scope.collection.variantBase, item)) { // if not in array
          // push to the variantBase array
          $scope.collection.variantBase.push(item)
        } else { // else
          for (var i = 0; i < $scope.collection.variantBase.length; i++) {
            if ($scope.collection.variantBase[i] == item) {
              $scope.collection.variantBase.splice(i, 1)
            };
          }
        }
      } else if ($scope.collection.variantBase.length == 2) {
        // remove the first element i.e remove 0th element
        $scope.collection.variantBase.splice(0, 1)
        // call this function again
        $scope.addToVariantBaseArray(item)
      }
    }

    function checkIfValueAlreadyInArray (array, item) {
      for (var i = 0; i < array.length; i++) {
        if (array[i] == item) {
          return true
        };
      }
      return false
    }

    $scope.getRequiredClass = function (item) {
      for (var i = 0; i < $scope.collection.variantBase.length; i++) {
        if ($scope.collection.variantBase[i] == item) {
          return 'selectedVariantBase'// for now will change the class
        }
      }
    }
  }])
