prismanoteApp.controller('brandActivityController', ['$scope', '$api', '$company', '$state', '$q', '$language', '$rootScope',
  function ($scope, $api, $company, $state, $q, $language, $rootScope) {
    $scope.loading = false
    $scope.activities = []
    $scope.currentPage = 1
    $scope.pageSize = '10'
    $scope.statusFilter = 'ALL_ITEMS' // Default show all transactions
    $scope.upcomingActivities = false;
    $scope.oldActivities = false;

    // $scope.getCompany()

    function getCompany() {
      return $q(function (resolve, reject) {
        $company.getCompany()
          .then(function (company) {
            return resolve(company)
          })
          .catch(function (reason) {
            return reject(reason)
          })
      })
    }

    $scope.$on('changeSelectedCompany', function () {
      $scope.getActivities()
    })

    $scope.getShops = function (){
      getCompany()
        .then(function (company) {
          $api.get('get-filter-shops-for-activities?companyId=' + company._id)
            .then(function (response) {
              $scope.shop_group = response.data.shops;
            })
            .catch(function (reason) {
              $scope.loading = false
              console.error(reason)
            })
        })
    }

    $scope.getEmployees = function (){
      getCompany()
        .then(function (company) {
          $api.get('brand/company-employees/' + company._id)
            .then(function (response) {
              $scope.users = [];
              var userData = response.data.data.employeeDetail;
              var loopTill = userData.length;
              for(var i = 0; i < loopTill; i++){
                if(userData[i] && userData[i].firstName && userData[i].lastName){
                  userData[i].enabled = false;
                  $scope.users.push(userData[i]);
                }
                if(i == (loopTill - 1)){
                  $rootScope.user.enabled = false;
                  $scope.users.push($rootScope.user);
                }
              }
            })
            .catch(function (reason) {
              $scope.loading = false
              console.error(reason)
            })
        })
    }

    $scope.getBackgroundColor = function(){
      console.log('------- getBackgroundColor!!');
      console.log('background-color: #D3D3D3');
      return 'background-color: #D3D3D3';
    }

    $scope.getActivities = function () {
      getCompany()
        .then(function (company) {
          var data ={
            companyId: company._id
          }
          var filters = []
          var users = [];
          if($scope.users && $scope.users.length){
            for(var i = 0; i < $scope.users.length; i++){
              if($scope.users[i].enabled) users.push($scope.users[i]._id);
            }
          }
          if ($scope.shop_group && $scope.shop_group.length) {
            for (var i = 0; i < $scope.shop_group.length; i++) {
              if($scope.shop_group[i].enabled) filters.push($scope.shop_group[i])
            }
            data.shop_filter = { shop_filter: filters }
          }
          data.employeeFilter = { employees: users }
          $scope.loading = true

          data.upcomingOrOldActivities = 'both';
          if($scope.upcomingActivities && !$scope.oldActivities){
            data.upcomingOrOldActivities = 'upcoming';
          }
          if(!$scope.upcomingActivities && $scope.oldActivities){
            data.upcomingOrOldActivities = 'old';
          }

          $api.post('get-all-activities',data )
            .then(function (response) {
              console.log('response:::',response.data);
              $scope.loading = false
              $scope.activities = response.data
              $scope.getEmployees();
              $scope.getShops();
            })
            .catch(function (reason) {
              $scope.loading = false
              console.error('------- 11', reason)
            })
        })
        .catch(function (reason) {
          console.error('------- 22', reason)
          $scope.loading = false
        })
    }

    $scope.openActivity = function (id) {
      $state.go('brand.activity-item', { id: id })
    }

    $scope.setUpcoming = function () {
      $scope.upcomingActivities = !$scope.upcomingActivities;
      if($scope.upcomingActivities){ $scope.oldActivities = false; }
      $scope.getActivities();
    }

    $scope.setLastCreated = function () {
      $scope.oldActivities = !$scope.oldActivities;
      if($scope.oldActivities){ $scope.upcomingActivities = false; }
      $scope.getActivities();
    }

    /* $scope.setStatusFilter = function (filter) {
      $scope.statusFilter = filter
    } */

    /* $scope.filterItemStatus = function (item) {
      var filter = $scope.statusFilter.toLowerCase()

      if (filter == 'all_items') {
        return true
      }
      return item.status == filter
    } */
  }])
